import { css } from '@emotion/react';
import React, { FunctionComponent, ReactElement, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import NvPopover from 'shared/components/nv-popover';
import { black, gray2, hexToRgbaString, primary, teal } from 'styles/global_defaults/colors';
import {
  notDesktop,
  screenSmMax,
  screenMdMin,
  desktop,
  screenMdMax,
  screen4KMax,
} from 'styles/global_defaults/media-queries';
import {
  doubleSpacing,
  halfSpacing,
  standardSpacing,
  tripleSpacing,
  threeQuartersSpacing,
  extraLargeSpacing,
  largeSpacing,
} from 'styles/global_defaults/scaffolding';
import { useAppDispatch } from 'redux/store';
import { clearNewNotification, setFilteredComments } from 'redux/actions/video-practice';

/**
 * Component to slide between the submission of users
 * each slide contaion list of submissions
 * and a preview of next or previous slide is displayed on the action buttons
 */

export enum SliderAction {
  NEXT = 'next',
  PREV = 'prev',
}

enum ArrowType {
  LEFT = 'left',
  RIGHT = 'right',
}

type ArrowIconProps = {
  icon: ArrowType
  action?: () => void
  iconClassName?: string
  preview?: string
  isDisabled?: boolean
  iconSize?: string
  showPopover?: boolean
  popoverContent?: ReactElement
  dataQa?: string
};

type UserSubmissionSliderProps = {
  onPrev?: () => void
  onNext?: () => void
  isPrev?: boolean
  isNext?: boolean
  prevPreview?: string
  nextPreview?: string
  showPopover?: boolean
  popoverContent?: ReactElement
  dataQaNext?: string
  dataQaPrev?: string
};

const ARROW_ICON_ZINDEX = 10;
const PREVIEW_OVERLAY_WIDTH = 135;

const buttonStyles = (
  icon: ArrowType,
  preview: string,
  isDisabled: boolean,
) => css`
  position: fixed;
  top: 50%;
  z-index: 1;
  ${notDesktop(css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${doubleSpacing}px;
    height: ${doubleSpacing}px;
    background: ${hexToRgbaString(gray2, 0.8)};
  `)}
  ${isDisabled && 'pointer-events: none;'}

  ${desktop(css`
    ${arrowPosition(icon)}
    &:hover {
      top: calc(50% - 34px);
      animation-duration: 300ms;
      animation-name: slider-btn-hover;
      ${arrowPosition(icon)}
      height: ${2 * extraLargeSpacing}px;
      width: ${PREVIEW_OVERLAY_WIDTH}px;
      background-color: ${hexToRgbaString(teal, 0.5)};
      &::before {
        position: absolute;
        content: "";
        bottom: ${halfSpacing}px;
        ${`${icon === ArrowType.LEFT ? 'right' : 'left'}: ${halfSpacing}px;`}
        height: ${2 * doubleSpacing}px;
        width: ${2 * doubleSpacing}px;
        ${preview && `background-image: url('${preview}'); background-size: cover; background-position: center`}
        ${!preview && `background: ${hexToRgbaString(black, 0.3)}`}
      }
      &::after {
        position: absolute;
        content: "";
        ${`${icon === ArrowType.LEFT ? 'right' : 'left'}: ${PREVIEW_OVERLAY_WIDTH}px;`}
        bottom: 0;
        width: 0;
        height: 0;
        border-top: ${extraLargeSpacing}px solid transparent;
        ${`border-${icon === ArrowType.LEFT ? 'right' : 'left'}: ${extraLargeSpacing}px solid ${hexToRgbaString(teal, 0.5)};`}
        border-bottom: ${extraLargeSpacing}px solid transparent;
      }
      ${icon === ArrowType.LEFT ? `
        .icon-arrow-left {
          position: absolute;
          bottom: ${largeSpacing}px;
          left: 0;
          z-index: ${ARROW_ICON_ZINDEX};
        }
      ` : `
        .icon-arrow-right {
          position: absolute;
          bottom: ${largeSpacing}px;
          right: 0;
          z-index: ${ARROW_ICON_ZINDEX};
        }
      `}

      .play-button {
        position: absolute;
        display: flex;
        bottom: ${threeQuartersSpacing}px;
        ${icon === ArrowType.LEFT ? `left: ${extraLargeSpacing}px;` : `left: ${threeQuartersSpacing}px;`}
      }
    }
  `)}
  ${notDesktop(css`
      ${icon}: 0;
  `)}
  .play-button {
    display: none;
    height: ${standardSpacing}px;
    width: ${standardSpacing}px;
    background-color: ${primary};
    border-radius: ${halfSpacing}px;
    .icon {
      transform: rotate(-90deg);
    }
  }

  @keyframes slider-btn-hover {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
const arrowPosition = (dir) => css`
  @media (min-width: ${screen4KMax}px) {
    ${dir}: 20%
  }
  @media (min-width: ${screenMdMax}px) and (max-width: ${screen4KMax}px) {
    ${dir}: 180px;
  }
  @media (min-width: ${screenMdMin}px) and (max-width: ${screenMdMax}px) {
    ${dir}: 140px;
  }
`;

const styles = css`
  /**
   * Width calculated as the difference between
   * total width and sum of horizontal margins
   * where width is {2 * tripleSpacing}
   */

  .slider {
    width: calc(100% - ${4 * tripleSpacing}px);
    opacity: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .slider.active {
    opacity: 1;
    animation-duration: 500ms;
  }
  .slider.active.next {
    animation-name: active-slider-next;
  }
  .slider.active.prev {
    animation-name: active-slider-prev;
  }
  .slider.last-active {
    animation-duration: 500ms;
  }
  .slider.last-active.next {
    animation-name: last-active-slider-next;
  }
  .slider.last-active.prev {
    animation-name: last-active-slider-prev;
  }

  ${notDesktop(css`
    .slider {
      width: calc(100% - ${doubleSpacing}px);
    }

    .slider.active.next {
      animation-name: active-slider-next-tab;
    }
    .slider.active.prev {
      animation-name: active-slider-prev-tab;
    }
    .slider.last-active.next {
      animation-name: last-active-slider-next-tab;
    }
    .slider.last-active.prev {
      animation-name: last-active-slider-prev-tab;
    }
  `)}

  @keyframes last-active-slider-next-tab {
    0% {
      opacity: 1;
      margin-left: ${standardSpacing}px;
    }
    100% {
      opacity: 0;
      margin-left: 0;
    }
  }
  @keyframes last-active-slider-prev-tab {
    0% {
      opacity: 1;
      margin-right: ${standardSpacing}px;
    }
    100% {
      opacity: 0;
      margin-right: 0;
    }
  }

  @keyframes active-slider-next-tab {
    0% {
      opacity: 0.1;
      margin-right: 0;
    }
    100% {
      opacity: 1;
      margin-right: ${standardSpacing}px;
    }
  }

  @keyframes active-slider-prev-tab {
    0% {
      opacity: 0.1;
      margin-left: 0;
    }
    100% {
      opacity: 1;
      margin-left: ${standardSpacing}px;
    }
  }

  @keyframes last-active-slider-next {
    0% {
      opacity: 1;
      margin-left: ${2 * tripleSpacing}px;
    }
    100% {
      opacity: 0;
      margin-left: ${halfSpacing}px;
    }
  }
  @keyframes last-active-slider-prev {
    0% {
      opacity: 1;
      margin-right: ${2 * tripleSpacing}px;
    }
    100% {
      opacity: 0;
      margin-right: ${halfSpacing}px;
    }
  }

  @keyframes active-slider-next {
    0% {
      opacity: 0.1;
      margin-right: ${halfSpacing}px;
    }
    100% {
      opacity: 1;
      margin-right: ${2 * tripleSpacing}px;
    }
  }

  @keyframes active-slider-prev {
    0% {
      opacity: 0.1;
      margin-left: ${halfSpacing}px;
    }
    100% {
      opacity: 1;
      margin-left: ${2 * tripleSpacing}px;
    }
  }
`;

const ArrowIcon = ({
  icon,
  action,
  iconClassName,
  preview,
  isDisabled,
  iconSize,
  showPopover,
  popoverContent,
  dataQa,
}: ArrowIconProps) => (

  <div css={buttonStyles(icon, preview, isDisabled)}>
    <NvPopover
      enabled={showPopover && !!popoverContent}
      content={popoverContent}
      showOnHover={!!popoverContent && showPopover}
      placement='top'
      preventOverflow
      rootClose
    >
      <React.Fragment>
        <NvIcon
          size={iconSize}
          icon={`arrow-${icon}`}
          className={iconClassName}
          onClick={!isDisabled ? action : null}
          altLabel={icon === ArrowType.LEFT ? t.SHARED.PREVIOUS() : t.SHARED.NEXT()}
          data-qa={dataQa}
        />
        <div className='play-button align-self-end justify-content-center align-items-center text-white'>
          <NvIcon icon='dropdown-arrow' size='xss-smallest' />
        </div>
      </React.Fragment>
    </NvPopover>
  </div>
);

export const UserSubmissionSlider: FunctionComponent<UserSubmissionSliderProps> = ({
  children,
  onPrev,
  onNext,
  isPrev,
  isNext,
  prevPreview,
  nextPreview,
  showPopover,
  popoverContent,
  dataQaNext,
  dataQaPrev,
}) => {
  const [action, setAction] = useState(null);
  const [showArrows, setShowArrows] = useState(false);
  const sliderRef = useRef<HTMLDivElement>();
  const dispatch = useAppDispatch();

  const { newNotification } = useSelector((state) => state.app.practiceRoom.params);

  const toggleAction = () => {
    setTimeout(() => {
      setAction(null);
    }, 1000);
  };

  const isNotDesktop = useMediaQuery({
    query: `(max-width: ${screenSmMax}px)`,
  });

  const onSlide = (sliderAction: SliderAction) => {
    if (newNotification?.commentId) {
      dispatch(setFilteredComments({
        submissionId: newNotification?.submissionId,
        filteredComments: [],
      }));
      dispatch(clearNewNotification({}));
    }
    if (sliderAction === SliderAction.NEXT) {
      onNext?.();
    }
    if (sliderAction === SliderAction.PREV) {
      onPrev?.();
    }
    setAction(sliderAction);
    toggleAction();
  };

  let activeClass = 'text-gray-2';
  let disabledClass = 'text-gray-5';
  if (isNotDesktop) {
    activeClass = 'text-white';
    disabledClass = 'text-white';
  }

  /** Positioning the arrows
   * The width of the practice overview modal is diffrent for practice room and practice feedback,
   * Also, the styles are only for desktops > 1024px
   */

  useEffect(() => {
    /**
     * sliderRef may not be available on first render.
     * The arrows won't position properly without sliderRef`,
     * setting state when it becomes available will cause a render,
     * and display arrows in proper position
     */
    setTimeout(() => {
      if (sliderRef.current) {
        setShowArrows(true);
      }
    }, 500);

    return () => {
      setShowArrows(false);
    };
  }, []);

  return (
    <div css={styles} ref={sliderRef}>
      {showArrows && (
        <ArrowIcon
          icon={ArrowType.LEFT}
          iconClassName={isPrev ? activeClass : disabledClass}
          preview={prevPreview}
          action={() => onSlide(SliderAction.PREV)}
          isDisabled={!isPrev}
          iconSize={isNotDesktop ? 'medium' : 'large'}
          dataQa={dataQaPrev}
        />
      )}
      <div className='mx-auto'>
        <div className={`slider last-active ${action}`} />
        <div className={`slider active ${action}`}>
          { children }
        </div>
        <div className={`slider last-active ${action}`} />
      </div>
      {showArrows && (
        <ArrowIcon
          icon={ArrowType.RIGHT}
          iconClassName={isNext ? activeClass : disabledClass}
          preview={nextPreview}
          action={() => onSlide(SliderAction.NEXT)}
          isDisabled={!isNext}
          iconSize={isNotDesktop ? 'medium' : 'large'}
          showPopover={showPopover}
          popoverContent={popoverContent}
          dataQa={dataQaNext}
        />
      )}
    </div>
  );
};

export default UserSubmissionSlider;
