import React, { Dispatch, ReactElement, ReactNode, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { CourseAliases } from 'redux/schemas/models/course';
import { getCourseAliases } from 'redux/selectors/course';
import { setShowPracticeFeedbackModal } from 'redux/actions/video-practice-feedback';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import ClickableContainer from 'components/clickable-container';
import NvPopover from 'shared/components/nv-popover';

const VPF_INSTRUCTION_MODAL_WIDTH = 700;
const VPF_INSTRUCTION_MODAL_HEIGHT = 324;

type FeedbackInstructionProps = {
  title: string
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

type PopoverProps = {
  enabled: boolean
  show: boolean
  children: ReactElement
};

export const FeedbackInstructionPopover = ({
  title,
  setShowModal,
  enabled,
  show,
  children,
}: FeedbackInstructionProps & PopoverProps) => (
  <NvPopover
    enabled={enabled}
    show={show}
    preventOverflow
    flip
    content={(
      <div className='d-flex flex-column justify-content-center align-items-center text-small'>
        <div className='text-warning mb-2'>
          {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.INSTRUCTION.POPOVER_TITLE()}
        </div>
        <div>
          {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.INSTRUCTION.POPOVER_DESC(title)}
        </div>
        <ClickableContainer
          className='text-primary align-self-start'
          onClick={() => setShowModal(true)}
        >
          {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.INSTRUCTION.SEE_INSTRUCTIONS()}
        </ClickableContainer>
      </div>
    )}
    placement='top-end'
  >
    {children}
  </NvPopover>

);

export const FeedbackInstructionModal = ({
  title,
  setShowModal,
  showModal,
}: FeedbackInstructionProps & { showModal: boolean }) => {
  const aliases: CourseAliases = useSelector((state) => getCourseAliases(state));

  const dispatch = useAppDispatch();

  return (
    <NvModal
      show={showModal}
      type={ModalType.FIXED}
      width={VPF_INSTRUCTION_MODAL_WIDTH}
      height={VPF_INSTRUCTION_MODAL_HEIGHT}
      doubleModal
      body={(
        <div className='d-flex flex-column p-4 align-items-center overflow-hidden'>
          <ClickableContainer
            className='text-primary'
            onClick={() => {
              setShowModal(false);
              dispatch(setShowPracticeFeedbackModal(false));
            }}
          >
            {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.INSTRUCTION.VIEW_IN_LECTURE({
              ...aliases.lectureAliases,
            })}
          </ClickableContainer>
          <div className='my-4 gray-1'>
            {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.FEEDBACK_DESCRIPTION({
              ...aliases.courseAliases, ...aliases.learnersAliases,
            })}
          </div>
          <Button
            variant='primary mt-2'
            onClick={() => setShowModal(false)}
          >
            {t.NOVOED.CLOSE()}
          </Button>
        </div>
      )}
      header={title}
      onClose={() => setShowModal(false)}
    />
  );
};
