import uuid from 'react-uuid';
import { useRef } from 'react';

/**
 * create uuid that stays the same for the lifespan of the component
 * @param prefix a string to prefix to the generated uuid
 */
/* @ngInject */
export default function useComponentId(prefix: string = ''): string {
  const componentIdRef = useRef(null);

  if (componentIdRef.current === null) {
    componentIdRef.current = `${prefix}${uuid()}`;
  }

  return componentIdRef.current;
}
