/* @ngInject */
export default function AuthenticationInterceptor(

  $rootScope,
  $q,
) {
  return {
    responseError(response) {
      if (response.status === 401) {
        $rootScope.$broadcast('unauthorized', response);
      }
      return $q.reject(response);
    },
  };
}
