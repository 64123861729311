import { css } from '@emotion/react';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';

import { AngularContext, AngularServicesContext } from 'react-app';
import { CourseTimelineContext } from 'timelines/components/course_home/course-timeline';
import useAngularExpression from 'shared/hooks/use-angular-expression';
import { useAppDispatch } from 'redux/store';
import { CombinedCourse, RootState } from 'redux/schemas';
import { Enrollment } from 'redux/schemas/models/my-account';
import { getCourseAliases, getCurrentCourse } from 'redux/selectors/course';
import { CompletionCriteriaType } from 'redux/schemas/app/timeline';
import { resetPortableCompletionPanelData, selectPortableCompletionPanelTab } from 'redux/actions/timeline';

import prodPathReplace from 'shared/prod-path-rewrite';
import { gray6, gray7, success } from 'styles/global_defaults/colors';
import {
  cardIconSize, doubleSpacing, halfSpacing, largeSpacing, quarterSpacing, standardSpacing,
  threeQuartersSpacing, tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import useCompletionCriteria from '../use-completion-criteria';
import PanelGauges from './panel-gauges';
import PanelContent from './panel-content';

const l1NavHeight = tripleSpacing - 5; // 5px for the top course/institution border

enum UserCourseCompletionStatus {
  NOT_COMPLETED = 0,
  AUTO = 1,
  MANUAL = 2,
}

const usePortableCompletionHeader = () => {
  const { isLearnerProgressModal, courseCompletion } = useContext(CourseTimelineContext) || {};

  // Completion and SOA
  const { injectServices } = useContext(AngularContext);
  const [$state, CourseHomeManager] = injectServices(['$state', 'CourseHomeManager']);
  const showSoa: boolean = $state.current.name === 'course-home'
    && CourseHomeManager?.courseHome?.showStatementOfAccomplishment;
  const { isCompleted: isRequirementsCompleted } = useCompletionCriteria();

  const currentCourse: CombinedCourse = useSelector(getCurrentCourse);
  const userCourse: Enrollment = useSelector((state: RootState) => state.models.enrollments[currentCourse.userCourse]);
  const { completionStatus: currentUserCompletionStatus } = userCourse; // 0 - Not completed, 1 - Auto, 2 - Manual

  let isCourseCompleted: boolean = !!currentUserCompletionStatus;
  let courseCompletionStatus: UserCourseCompletionStatus = currentUserCompletionStatus;
  let courseCompletionProgress: string = null;

  if (isLearnerProgressModal) {
    isCourseCompleted = courseCompletion.status > 0 || courseCompletion.progress === 'completed';
    courseCompletionStatus = courseCompletion.status;
    courseCompletionProgress = courseCompletion.progress;
  }

  return {
    showSoa,
    isRequirementsCompleted,
    isCourseCompleted,
    courseCompletionStatus,
    courseCompletionProgress,
    currentCourse,
  };
};

const PortableCompletionTitle = () => {
  const { isLearnerProgressModal, isFullTimeline } = useContext(CourseTimelineContext) || {};

  const {
    showSoa,
    isRequirementsCompleted,
    courseCompletionStatus,
    courseCompletionProgress,
    currentCourse,
  } = usePortableCompletionHeader();

  const { enabledFactorCount } = useCompletionCriteria();

  // Progress text should visible in lecture page whether tasks is completed or not
  // But it is not displayed in course-home
  const showProgress = !showSoa // Show SOA as per course home response
    // Requirements is completed or this is happening in lecture page
    && (!isRequirementsCompleted || !isFullTimeline)
    // Course completion is not completed
    && courseCompletionProgress !== 'completed'
    // Course status is not manual. If it is manually completed, no need to display
    // this title
    && courseCompletionStatus !== UserCourseCompletionStatus.MANUAL
    // In Archive mode this is not displayed. But if it is learner progress modal
    // it will display even if the course is in archive mode
    && (!currentCourse.inArchiveMode || isLearnerProgressModal);

  return (
    <React.Fragment>
      {showProgress && (
        <div className={`pb-3 text-center ${isFullTimeline && enabledFactorCount > 1 ? 'card-subtitle' : 'card-title'}`}>
          { t.COMPLETION_CRITERIA.PROGRESS_TOWARDS_COURSE_COMPLETION() }
        </div>
      )}
    </React.Fragment>
  );
};

const PortableCompletionSOA = () => {
  const { isLearnerProgressModal } = useContext(CourseTimelineContext) || {};

  const {
    showSoa,
    isRequirementsCompleted,
    isCourseCompleted,
    currentCourse,
  } = usePortableCompletionHeader();

  const { courseAliases } = useSelector((state) => getCourseAliases(state));
  const userCourse = useSelector((state) => state.models.enrollments[currentCourse?.userCourse]);

  const catalogId = currentCourse?.catalogId;
  const userId = userCourse?.userId;
  const { injectServices } = useContext(AngularContext);
  const [$state, CourseHomeManager] = injectServices(['$state', 'CourseHomeManager']);

  let selectCase = 'both';
  let credlyLink = '';
  let soaLink = '';
  const soaText = t.COURSE_ADMIN_MEGA_MENU.STATEMENT_OF_ACCOMPLISHMENT.LABEL();
  const credlyBadgeEnabled = isCourseCompleted && !!(CourseHomeManager?.courseHome?.credlyBadge);

  if (credlyBadgeEnabled) {
    selectCase = 'credly';
    credlyLink = CourseHomeManager?.courseHome?.credlyBadge;
  }

  if (showSoa) {
    selectCase = credlyBadgeEnabled ? 'both' : 'soa';
    soaLink = $state.href('statement-modal', { catalogId, userId });
  }

  const showCertificate = (showSoa || !!credlyBadgeEnabled) && !isLearnerProgressModal;

  // For Learner progress modal, we will display the portable completion anyway
  const isArchivedCourse = currentCourse.inArchiveMode && !isLearnerProgressModal;

  return (
    <React.Fragment>
      { (showSoa || isCourseCompleted || isRequirementsCompleted) && (
        <div className='pt-2 d-flex align-items-center row congrats-container'>
          <div className='col-md-4'>
            <img
              className='congrats-image'
              src={prodPathReplace('images/congratulations.png')}
              alt='Certification of Completion Badge'
            />
          </div>
          <div className='col-md-8 ml-2 d-flex flex-column'>
            <div className='page-subtitle'>{t.COURSE_HOME.STATEMENT_OF_ACCOMPLISHMENT.HEADER()}</div>
            <div className='blue-links mt-2'>
              {
                (!!showCertificate) && (
                  <div>
                    {t.COURSE_HOME.STATEMENT_OF_ACCOMPLISHMENT.VIEWABLE_BODY({
                      selectCase,
                      credlyLink,
                      soaLink,
                      courseAlias: courseAliases.courseAlias,
                      soaText,
                    })}
                  </div>
                )
              }
              {
                (!showCertificate && (isRequirementsCompleted || isCourseCompleted)) && (
                  <div>
                    {t.COMPLETION_CRITERIA.FINISHED_ALL_REQUIREMENTS({
                      courseAlias: courseAliases.CourseAlias,
                    })}
                  </div>
                )
              }
              {
                (isArchivedCourse) && (
                  <div className='mt-2'>
                    {t.COURSE_HOME.ARCHIVE_MODE.BODY({
                      courseName: currentCourse.name,
                      courseAlias: courseAliases.courseAlias,
                    })}
                  </div>
                )
              }
            </div>
          </div>
        </div>
      )}
      {/* Archive mode and shown if learner hasn't completed course */}
      {(isArchivedCourse && !showSoa && !isCourseCompleted) && (
        <div className='archived-not-completed section d-flex'>
          <i className='icon icon-success' />
          <div className='d-flex align-items-center ml-4'>{t.COURSE_HOME.ARCHIVE_MODE.BODY({
            courseName: currentCourse.name,
            courseAlias: courseAliases.courseAlias,
          })}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const loaderStyles = css`
  padding-bottom: ${standardSpacing}px;

  @keyframes shineAnimation {
    0% {
      background-position: 0% 50%;
    }

    100% {
      background-position: -100% 50%;
    }
  }

  .loading {
    background: linear-gradient(90deg, ${gray6} 35%, ${gray7} 45%, ${gray6} 55%);
    background-size: 600% 600%;
    animation: shineAnimation 1.5s infinite;
  }

  .title-bar {
    width: 60%;
    height: ${halfSpacing}px;
    margin-bottom: ${doubleSpacing}px;
    margin-left: 20%;
  }

  .placeholder-bar {
    width: 100%;
    height: ${quarterSpacing}px;
    margin: ${threeQuartersSpacing}px auto;
  }

  .short-bar {
    margin-left: 0;
    margin-right: 0;
    width: 80%;
  }
`;

const CompletionLoader = () => (
  <div css={loaderStyles}>
    <div className='loading title-bar' />
    <div className='loading placeholder-bar' />
    <div className='loading placeholder-bar' />
    <div className='loading placeholder-bar short-bar' />
  </div>
);

const headerStyles = (isFullTimeline) => css`
  &.completion-criteria-panel-header {
    background: linear-gradient(to right, rgba(247,251,232, 0.3), rgba(220, 253, 253, 0.3));
    border-bottom: 1px solid ${gray6};
    padding: ${threeQuartersSpacing}px ${3 * standardSpacing}px ${largeSpacing}px;

    .archived-not-completed {
      .icon-success {
        font-size: ${doubleSpacing}px;
        color: ${success}
      }
    }

    .congrats-container {
      height: 90px;
    }
    ${isFullTimeline && css`
      .congrats-container {
        height: 110px;
        padding-bottom: ${standardSpacing}px;
      }
    `}

    .congrats-image {
      height: ${cardIconSize}px;
      width: ${cardIconSize}px;
      float: right;
    }
  }
`;

export const PortableCompletionHeader = () => {
  const { isLearnerProgressModal, isFullTimeline } = useContext(CourseTimelineContext) || {};

  const { currentCourse } = usePortableCompletionHeader();

  const isCourseCompletionProgressLoading = useSelector((state: RootState) => state.app.timeline.isCourseCompletionProgressLoading);

  const showGauges = isLearnerProgressModal || !currentCourse.inArchiveMode;

  return (
    <div css={headerStyles(isFullTimeline)} className='completion-criteria-panel-header'>
      <div className='body-text-wrapper'>
        {isCourseCompletionProgressLoading
          ? (
            <CompletionLoader />
          )
          : (
            <React.Fragment>
              <PortableCompletionTitle />
              <PortableCompletionSOA />
              {showGauges && (
                <PanelGauges />
              )}
            </React.Fragment>
          )}
      </div>
    </div>
  );
};

const styles = css`
  background-color: rgba(58,66,77,.1);
  border-top: 1px solid #e4e7ec;
  height: calc(100vh - ${l1NavHeight}px);
  top: ${l1NavHeight}px;
  left: 0px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 1011;

  .completion-criteria-panel-wrapper {
    background-color: #fff;
  }
`;

const PortableCompletionPanel = () => {
  const dispatch = useAppDispatch();

  const portableCompletionPanelSelectedTab: CompletionCriteriaType = useSelector((state: RootState) => state.app.timeline.portableCompletionPanelSelectedTab);
  const currentCourse: CombinedCourse = useSelector(getCurrentCourse);
  const catalogId = currentCourse?.catalogId;

  useEffect(() => () => {
    // Reset the tab selected
    dispatch(selectPortableCompletionPanelTab(null));
    // Clear all the data
    dispatch(resetPortableCompletionPanelData(catalogId));
  }, [dispatch, catalogId]);

  return (
    <div css={styles}>
      <div className='completion-criteria-panel-wrapper'>
        <PortableCompletionHeader />
        {portableCompletionPanelSelectedTab && (
          <PanelContent />
        )}
      </div>
    </div>
  );
};

export default PortableCompletionPanel;
