import MentorDashboard from '../components/mentor-dashboard';

/* @ngInject */
export default function MentorDashboardController(
  $scope,
  CurrentUserManager,
  OrgLevelProfileManager,
) {
  $scope.MentorDashboard = MentorDashboard;
  $scope.CurrentUserManager = CurrentUserManager;
  $scope.OrgLevelProfileManager = OrgLevelProfileManager;
}
