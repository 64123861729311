import MessageFormat from 'messageformat';
import MfParser from 'messageformat-parser';
import { isString } from 'util';
import _, { isArray } from 'underscore';
import { config } from '../config/config.json';

// Used to type check the nodes and leaves of the translations tree processed by traverseTree()
type CacheNode = Record<string, any> | CacheLeaf;
type CacheLeaf = () => globalThis.Function;

// These types are for values output by the messageformat-parser when it parses a translation string from our .json files
// Taken from https://github.com/messageformat/messageformat/tree/master/packages/parser, with minimal changes to make it compile in TS
type Token = string | Argument | Plural | Select | Function;

type Argument = {
  type: 'argument',
  arg: Identifier
};

type Plural = {
  type: 'plural' | 'selectordinal',
  arg: Identifier,
  offset: number,
  cases: PluralCase[]
};

type Select = {
  type: 'select',
  arg: Identifier,
  cases: SelectCase[]
};

type Function = {
  type: 'function',
  arg: Identifier,
  key: Identifier,
  param: {
    tokens: any[]
  } | null
};

type PluralCase = {
  key: string
  tokens: (Token | Octothorpe)[]
};

type SelectCase = {
  key: Identifier,
  tokens: any[]
};

type Octothorpe = {
  type: 'octothorpe'
};

type Identifier = string;

type Param = {
  name: string,
  type: string,
};

/** Gets a string representing the TypeScript type to use for a translation parameter in the output TypeScript function */
function typeStringForParam(param: Exclude<Token, string>) {
  switch (param.type) {
    case 'argument':
      // Params containing the word 'count' are assumed to be numbers
      return param.arg.toLowerCase().indexOf('count') !== -1 ? 'number' : 'string';
    case 'function':
      return 'any';
    case 'plural':
      return 'number';
    case 'select':
      return 'string';
    case 'selectordinal':
      return 'number';
    default:
      return '';
  }
}

/** Gets param tokens that are nested inside of plural, selectordinal, or select functions
 * This is recursive but we probably only go one level deep since our translation strings aren't that complex. */
function getNestedTokens(tokens: (Token | Octothorpe)[]) {
  let returnTokens = [];
  for (let i = 0; i < tokens.length; i += 1) {
    const token = tokens[i];

    if (isArray(token) || typeof token === 'string' || token.type === 'octothorpe') {
      // eslint-disable-next-line no-continue
      continue;
    }

    returnTokens.push(token);
    if (token.type === 'plural' || token.type === 'selectordinal' || token.type === 'select') {
      // eslint-disable-next-line no-loop-func
      token.cases.forEach(c => {
        returnTokens = returnTokens.concat(getNestedTokens(c.tokens));
      });
    }
  }

  return returnTokens;
}

/** Creates a TypeScript function for the given translation string. The input translation strings are the same as they are
 * in our .yml translation files: they are in MessageFormat syntax (https://messageformat.github.io/messageformat/page-guide)
 * The output of this is a string representation of the new TS function, which later gets written to disk. */
function createFunction(originalLanguageCode: string, langCode: string, translationString: string, isRuntime: boolean): string | CacheLeaf {
  if (!isRuntime) {
    return compileFunction(originalLanguageCode, langCode, translationString, false);
  }

  // If this is being used at runtime, then do not compile the translation functions immediately. Instead, return a function that will
  // compile them the first time it is run, and then returns that compiled value every other time
  let value: globalThis.Function = null;
  const compileOnce = (...args) => {
    const locale = String(langCode);
    if (!value) {
      let compiledFunc = compileFunction(originalLanguageCode, locale, translationString, isRuntime);

      if (compiledFunc.indexOf('<') !== -1) {
        if (compiledFunc.includes('{ return ')) {
          // Normal Case
          compiledFunc = compiledFunc.replace('{ return ', '{ return createElement(\'span\', { dangerouslySetInnerHTML: { __html: ');
        } else if (compiledFunc.includes('} return ')) {
          // FixedAliasLanguages Case, where 'if (...) {param = object.param}' is added before the return.
          compiledFunc = compiledFunc.replace('} return ', '} return createElement(\'span\', { dangerouslySetInnerHTML: { __html: ');
        }
        compiledFunc = compiledFunc.replace('; }', '} }); }');
      }

      // eslint-disable-next-line
      value = Function(`return ${compiledFunc}`)();
      return value(...args);
    }

    return value(...args);
  };

  return compileOnce;
}

function compileFunction(originalLanguageCode: string, locale: string, translationString: string, isRuntime?: boolean): string {
  /* The compiled source of a JavaScript translation string
  * Example: for the translation DASHBOARD.DUE_IN_HOURS:
  * input is `Due in {deadline} hours`
  * output from compile() is `function(d) { return 'Due in ' + d.deadline + ' hours'; }`
  *
  * The remainder of this createTsFunction is transforming that into a TS function like
  * `function(deadline: string) { return "Due in " + deadline + " hours"; }` */
  let compiledFunc: string = '';
  try {
    const mf = mfForLocale[locale];
    compiledFunc = mf.compile(translationString).toString();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Failed compiling:', translationString);
    throw e;
  }

  /** Ensures that params are uniquely named because the MF parser will sometimes
  * parse duplicate params out of translations like `END_DATE_BEFORE_RELEASE_DATE` */
  const paramNames: Set<string> = new Set();
  const params: Param[] = [];

  /* We use the messageformat parser directly to get a list of the translation parameters. These are returned as 'Token' objects
  * per the type declarations at the top of this file */
  const tokens: Token[] = MfParser.parse(translationString);
  // Some params are nested in select and selectordinal MessageFormat functions
  const allTokens: Token[] = getNestedTokens(tokens);

  for (let i = 0; i < allTokens.length; i += 1) {
    const token = allTokens[i];
    if (!isString(token) && !paramNames.has(token.arg)) {
      // Completely ignore TS transforming functions with params containing the word 'alias'. This lets us pass in objects as params
      // to these functions just as we do today in AngularJS
      if (token.arg.toLowerCase().indexOf('alias') !== -1) {
        return compiledFunc;
      }

      paramNames.add(token.arg);
      params.push({
        name: token.arg,
        type: typeStringForParam(token),
      });
    }
  }

  // Abort translating functions with a params related to the `USERS_LIST` translation, which itself
  // involves string replacements in the gulp script. It's complex to support and not worth the effort.
  if (paramNames.has('subject0FirstName') || paramNames.has('subjectCount')) {
    return compiledFunc;
  }

  /** Sort param names alphabetically */
  const sortNames = (p1: Param, p2: Param) => {
    if (p1.name < p2.name) {
      return -1;
    }

    if (p1.name > p2.name) {
      return 1;
    }

    return 0;
  };

  // Replace the function signature
  let paramString = '';
  if (isRuntime) {
    paramString = params.sort(sortNames).map(p => `${p.name}`).join(', ');
  } else {
    paramString = params.sort(sortNames).map(p => `${p.name}: ${p.type}`).join(', ');
  }

  compiledFunc = compiledFunc.replace('(d)', `(${paramString})`);
  const isFixedAliasLanguage = config.fixedAliasLanguages.includes(originalLanguageCode);
  // Remove the `d.` prefix on param usages in the translation func
  params.forEach((p) => {
    compiledFunc = compiledFunc.replace(new RegExp(`d.${p.name}`, 'g'), p.name);
    // If the parameter is just an object with values inside with the word 'alias',
    // but those 'alias' are not required in the final text (fixedAliasLanguages cases like pl_PL),
    // the function will take the values from the object given.
    if (isFixedAliasLanguage) {
      // First param name will be the object name
      const firstParamName = params[0].name;
      compiledFunc = compiledFunc.replace('return', `if ( typeof ${firstParamName} === 'object') {${p.name} = ${firstParamName}.${p.name};} return`);
    }
  });

  return compiledFunc;
}

const mfForLocale: Record<string, MessageFormat> = {};

/** Traverses down the nodes of the translations object from our JSON file and transforms the translation string "leaves" into
 * TypeScript functions */
function traverseTree(originalLanguageCode: string, langCode: string, transNode: Record<string, any>, isRuntime?: boolean): CacheNode {
  const newNode: CacheNode = {};
  const keys = _.keys(transNode);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const val = transNode[key];

    // Recursively call this function if the current node isn't a leaf (with a translation string as its value)
    if (typeof val === 'string') {
      newNode[key] = createFunction(originalLanguageCode, langCode, val, isRuntime);
    } else {
      newNode[key] = traverseTree(originalLanguageCode, langCode, val, isRuntime);
    }
  }

  return newNode;
}

/** Initializes the message format compiler with the given locale code string */
function initMessageFormat(code: string) {
  mfForLocale[code] = new MessageFormat(code, {
    customFormatters: {
      // TODO: Add a custom formatter to support JSX params
    },
  });
}

/** Generic TS source code header in our translation files, with imports that reference the correct pluralization function for
 * the needed locale */
function tsHeader(codePart: string) {
  return `/* eslint-disable */
import { number, plural, select } from 'messageformat-runtime';
import { ${codePart} } from 'messageformat-runtime/lib/plurals';

export default`;
}

/** Recursive function that creates formatted TypeScript source code for the transformed translation functions */
function tsOutput(translationObject: Object | string, indent?: number): string {
  // Base case when the current object is a translation function and not an object
  if (typeof translationObject === 'string') {
    return `${translationObject},`;
  }

  let out = '{\n';

  _.keys(translationObject).forEach(k => {
    const innerString = tsOutput(translationObject[k], indent + 2);
    let objectKey = k;
    // Wrap property names that are invalid TS object identifiers in string
    // quotes to avoid a syntax error
    if (k.indexOf('%') !== -1 || k.indexOf('(') !== -1 || k.indexOf('-') !== -1 || k.match(/^\d/)) {
      objectKey = `'${k}'`;
    }
    out += `${' '.repeat(indent + 2)}${objectKey}: ${innerString}\n`;
  });

  out += indent > 0 ? `${' '.repeat(indent)}},\n` : '};';

  return out;
}

/** Processes a translations .json file and outputs typescript code */
/* @ngInject */
export function createTsTranslationsString(originalLanguageCode: string, mfLanguageCode: string, json: Record<string, any>): string {
  /** Get the first part of the code name, like `en`. Message format expects just this part when specifying the locale */
  initMessageFormat(mfLanguageCode);

  // Get the .json translations file and then transform every translation string in to a string of TypeScript function
  // source code.
  let translationsJson = json;

  translationsJson = traverseTree(originalLanguageCode, mfLanguageCode, translationsJson, false);
  let tsCode = '';

  tsCode += tsHeader(mfLanguageCode);
  tsCode += tsOutput(translationsJson, 0);

  return tsCode;
}

/** Processes a translations .json file and a JS object used by the new tech stack runtime */
/* @ngInject */
export default function createRuntimeTranslationFuncs(originalLanguageCode: string, mfLanguageCode: string, json: Record<string, any>): Record<string, any> {
  initMessageFormat(mfLanguageCode);
  let translationsJson = json;
  translationsJson = traverseTree(originalLanguageCode, mfLanguageCode, translationsJson, true);
  return translationsJson;
}
