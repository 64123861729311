/* @ngInject */
export default function UserAuthentication(
  $state,
  $window,
  CurrentUserManager,
  StateManager,
  RailsRoutes,
  ReportsManager,
  config,
  _,
) {
  const _this = {
    stateAttemptedBeforeAuthentication: null,
    paramsForStateAttemptedBeforeAuthentication: null,
    verifyAuthenticated,
    setLastAttempted,
    beginAuthentication,
    getSSOSignInUrl,
    redirectAfterAuthentication,
    redirectToDashboard,
  };

  function verifyAuthenticated() {
    return CurrentUserManager.reinitialize();
  }

  function setLastAttempted() {
    if (!StateManager.lastStateAttempted.name.includes('users.authentications')) {
      _this.stateAttemptedBeforeAuthentication = StateManager.lastStateAttempted;
      _this.paramsForStateAttemptedBeforeAuthentication = StateManager.paramsForLastStateAttempted;
    }
  }

  function beginAuthentication() {
    const isJourney = StateManager.lastStateAttempted.name === 'learning-journey-home';

    let params = StateManager.paramsForLastStateAttempted || {};

    if (params.catalogId) {
      params = { ...params, isJourney };
    }

    $state.go('users.authentications.sign-in', params);
  }

  function getSSOSignInUrl(providerName) {
    const mobileLogin = window.location.href.includes(mobileLogin) ? 'mobile_login=true' : '';
    const { catalogId } = StateManager;
    const urlBeforeAuthentication = getUrlBeforeAuthentication();
    let ssoUrl = `//${config.app.dummyHost}/saml/sso?provider=${providerName}&${mobileLogin}`;

    if (catalogId) {
      const urlParams = new URLSearchParams(window.location.href);
      ssoUrl = `${ssoUrl}&catalog_id=${catalogId}`;
      const urlCsod = urlParams.get('after_auth_path');
      const subdomainCsod = urlParams.get('subdomain');
      if (urlCsod != null) {
        ssoUrl = `${ssoUrl}&after_auth_path=${encodeURIComponent(`${urlCsod}&subdomain=${subdomainCsod}`)}`;
      }
    } else if (urlBeforeAuthentication) {
      ssoUrl = `${ssoUrl}&after_auth_path=${encodeURIComponent(urlBeforeAuthentication)}`;
    }

    return ssoUrl;
  }

  function getUrlBeforeAuthentication(returnToUrl = '') {
    if (returnToUrl) return returnToUrl;
    const { origin, pathname } = document.location;
    const url = `${origin}${pathname}`;
    if (_this.stateAttemptedBeforeAuthentication) {
      const params = $state.href(_this.stateAttemptedBeforeAuthentication, _this.paramsForStateAttemptedBeforeAuthentication || {});
      return `${url}${params}`;
    }
    return url;
  }

  function redirectAfterAuthentication(returnToUrl) {
    ReportsManager.reinitialize();

    if (_this.stateAttemptedBeforeAuthentication) {
      $state.go(_this.stateAttemptedBeforeAuthentication, _this.paramsForStateAttemptedBeforeAuthentication || {});
    } else if (returnToUrl?.length > 0) {
      $window.location.href = returnToUrl;
    } else {
      redirectToDashboard();
    }
  }

  function redirectToDashboard() {
    if ($state.params.catalogId && !CurrentUserManager.currentInstitution?.isInstitutionalAdmin) {
      let userCourse;
      const course = _.find(CurrentUserManager.courses, (courseObj) => courseObj.catalogId === $state.params.catalogId);

      if (course) {
        userCourse = CurrentUserManager.courseIdToUserCourseHash[course.id];
      }

      if (course?.isProgram) {
        $state.go('program-home', { catalogId: $state.params.catalogId });
      } else {
        $state.go('course-home', { catalogId: $state.params.catalogId });
      }
    } else {
      $state.go('root');
    }
  }

  return _this;
}
