/* eslint-disable global-require */
/* eslint-disable no-useless-escape */
import papa from 'papaparse';
import validationConstants from './constants-shared';

/* @ngInject */
export default function constants(app) {
  app
    .constant('_', window._)
    .constant('Pusher', window.Pusher)
    .constant('jwplayer', window.jwplayer)
    .constant('humps', window.humps)
    .constant('BigNumber', window.BigNumber.BigNumber)
    .constant('Papa', papa)
    .constant('maxTimeout', 2147483647)
    .constant('moment', require('moment-timezone'))
    .constant('validationConstants', {
      // eslint-disable-next-line max-len
      URL_REGEX: validationConstants.URL_REGEX.toString(),
      NAME_REGEX: '(.){1,50}$',
      DEGREED_TAG_REGEX: /^[ \-&\w]+$/,
      // https://emailregex.com/
      EMAIL_REGEX: validationConstants.EMAIL_REGEX,
      DECIMAL_NUMBER_REGEX: '^-?[\$]?[0-9,]*[\.]?[0-9,]*[\$]?$',
      POSITIVE_DECIMAL_NUMBER_REGEX: '^[0-9,]*[\.]?[0-9,]*$',
      POSITIVE_INTEGER: '^[1-9][0-9]*$',
      PASSWORD_REGEX: '(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{3,}',
      HEX_COLOR_REGEX: '^#[0-9a-fA-F]{6}$',
      NUMBER_MIN: '-9999999999999999.9999',
      NUMBER_MAX: '9999999999999999.9999',
      CATALOG_ID: '[a-zA-Z0-9_\-]+',
    });
}
