import t from 'react-translate';
import { map } from 'underscore';
import { css } from '@emotion/react';

import { NovoAIItemType } from 'redux/schemas/models/lecture-page';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import AIItem from './item';

const styles = css`
  & {
    gap: ${standardSpacing}px;
  }
`;

const List = () => (
  <div className='d-flex flex-column' css={styles}>
    <div className='page-title text-center'>
      {t.LECTURE_PAGES.LHS.NOVO_AI.ITEMS_HEADER()}
    </div>
    {map(NovoAIItemType, (type) => (
      <AIItem type={type} key={type} />
    ))}
  </div>
);

export default List;
