/* @ngInject */
export default function UserManagementResources(
  $resource,
) {
  return $resource(
    '/:catalogId/user_management.json',
    { catalogId: '@catalogId' },
    {
      orgSearch: { method: 'POST', url: '/:catalogId/search/organization_users.json', cancellable: true },
      searchCourseUsers: { method: 'POST', url: '/:catalogId/search/course_users.json', cancellable: true },
      modifyAccessCloseDate: { method: 'POST', url: '/:catalogId/user_courses/modify_access_close_date' },
      addManuallyAwardedPoints: { method: 'POST', url: '/:catalogId/awarded_points' },
      updateManuallyAwardedPoints: { method: 'PUT', url: '/:catalogId/awarded_points/:pointObjectId' },
      deleteManuallyAwardedPoints: { method: 'DELETE', url: '/:catalogId/awarded_points/:pointObjectId' },
      updateCourseUsers: { method: 'PUT', url: '/:catalogId/user_management/update_course_users.json' },
      toggleBanned: { method: 'GET', url: '/:catalogId/user_management/toggle_banned/:userId' },
      enrolledCounts: { method: 'GET', url: '/:catalogId/user_management/get_course_users_counts.json' },
      toggleCommunitySearchVisibility: { method: 'POST', url: '/:catalogId/user_management/toggle_community_search_visiblity' },
      bulkUnenroll: { method: 'POST', url: '/:catalogId/user_management/unenroll.json' },
      sendWelcome: { method: 'POST', url: '/:catalogId/send_welcome.json' },
    },
  );
}
