import { css } from '@emotion/react';
import { useContext, useEffect, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import t from 'react-translate';
import { useSelector } from 'react-redux';

// Contexts
import { CommunicationFormContext, UseCommunicationMethods } from 'communications/course_communications/hooks/use-communication-form';

// Schemas
import { RootState } from 'redux/schemas';

// Selectors
import { getRolesTitles } from 'redux/selectors/course-communications';

// Styles
import { boldFontWeight } from 'styles/global_defaults/fonts';
import { gray4, gray6, primary } from 'styles/global_defaults/colors';
import { standardSpacing } from 'styles/global_defaults/scaffolding';

// Components
import Button from 'react-bootstrap/Button';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import NvTextArea from 'shared/components/inputs/nv-text-area';
import { NvFroala } from 'froala/components/nv-froala';
import { FroalaViewMode, UploadType, RelatedActivity, RteTagValue } from 'froala/helpers/nv-froala-constants';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import SaveDraft from 'communications/course_communications/components/communication-modal/save_draft/save-draft';
import CommunicationItemPreview from 'communications/course_communications/components/communication-item/communication-item-preview';
import EmailHeader from 'communications/course_communications/components/communication-modal/email-header';
import ExtraTermsInfo from 'communications/course_communications/components/communication-modal/extra-terms-info';
import { config } from '../../../../../config/config.json';

const styles = css`
  .modal-body-title {
    border-bottom: 1px solid ${gray4};
    text-align: center;
  }

  .notification {
    .notification-header {
      font-weight: ${boldFontWeight};
      border-top: 2px solid ${gray6};
      border-bottom: 1px solid ${gray6};
      display: flex;
      align-items: center;

      .notification-limits {
        color: ${primary};
        flex: 1;
        text-align: right;
      }
    }

    .notification-input {
      visibility: hidden;
      height: 0;
    }

    &.include-notification {
      .notification-input {
        visibility: visible;
        height: auto;
        margin-top: ${standardSpacing}px;
      }
    }
  }

  .actions {
    .buttons {
      display: flex;
      margin-top: 60px;
      justify-content: center;
    }
  }
`;

const StepTwo = () => {
  const { formData, updateFormData, setLastValidStep,
    prevStep, nextStep, saveAsDraft, isDirty, draftSubmittedAt,
    getCommunicationDraft,
  } = useContext<UseCommunicationMethods>(CommunicationFormContext);

  const validationSchema = yup.object().shape({
    emailSubject: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .min(1, t.VALIDATION.MIN('1'))
      .max(255, t.VALIDATION.MAX('255')),
    emailBody: yup.string()
      .required(t.VALIDATION.REQUIRED()),
    includePushNotification: yup.boolean(),
    pushNotificationText: yup.string().nullable()
      .when('includePushNotification', {
        is: true,
        then: yup.string()
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1'))
          .max(255, t.VALIDATION.MAX('255')),
      }),
  });

  const rhfMethods = useForm({
    mode: 'all',
    shouldUnregister: true,
    defaultValues: formData,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, formState, watch } = rhfMethods;

  // Watch all fields in this form. If a change occured
  // refresh the communicationFormData
  const [
    emailBody, emailSubject, includePushNotification, pushNotificationText,
  ] = watch(['emailBody', 'emailSubject', 'includePushNotification', 'pushNotificationText']);

  useEffect(() => {
    updateFormData({
      emailBody,
      emailSubject,
      includePushNotification,
      pushNotificationText,
    });
  }, [
    emailBody,
    emailSubject,
    includePushNotification,
    pushNotificationText,
    updateFormData,
  ]);

  // If include push notification is selected, focus the input
  const ref = useRef<HTMLTextAreaElement>();
  useEffect(() => {
    if (includePushNotification) {
      setTimeout(() => {
        ref.current.focus();
      });
    }
  }, [includePushNotification]);

  // If formState is valid, update lastValidStep
  useEffect(() => {
    setLastValidStep(formState.isValid ? null : 1);
  }, [formState.isValid, setLastValidStep]);

  const communicationDraft = getCommunicationDraft();

  const rolesFilterTitles: any = useSelector((state: RootState) => getRolesTitles(state, communicationDraft?.filters?.courseRolesList));

  const relatedActivitiesForRteTags: RelatedActivity[] = [
    ...(communicationDraft?.ownerType
      ? [{ id: communicationDraft.ownerId, type: communicationDraft.ownerType }] : []),
    ...(communicationDraft && communicationDraft?.event?.hasCompleted
      ? [{ ...communicationDraft.event.hasCompleted }] : []),
    ...(communicationDraft && communicationDraft?.filters?.hasCompleted
      ? [{ ...communicationDraft.filters.hasCompleted }] : []),
    ...(communicationDraft && communicationDraft?.filters?.hasNotCompleted
      ? [{ ...communicationDraft.filters.hasNotCompleted }] : []),
  ];

  return (
    <FormProvider {...rhfMethods}>
      <form onSubmit={handleSubmit(nextStep)}>
        <div css={styles}>
          <div className='modal-body-title page-title pb-4 mt-2 mb-5'>
            {t.COURSE_COMMUNICATIONS.TRIGGERS.TITLE.STEP_2()}
          </div>
          <CommunicationItemPreview
            communication={communicationDraft}
            rolesFilterTitles={rolesFilterTitles}
            showOwnerActivity
          />
          <div className='email mt-5 mb-4'>
            <EmailHeader communicationDraft={communicationDraft} />
            <div className='mt-4 mb-4'>
              <NvTextInput
                withForm
                name='emailSubject'
                autoComplete='off'
                inputClassName='text-large-regular'
                placeholder={t.COURSE_COMMUNICATIONS.EMAIL.SUBJECT_PLACEHOLDER()}
                required
              />
            </div>
            <div className=''>
              <NvFroala
                preset={FroalaViewMode.NORMAL}
                withCodeView
                withInsertTag
                withForm
                name='emailBody'
                placeholder={t.COURSE_COMMUNICATIONS.EMAIL.BODY_PLACEHOLDER()}
                uploadType={UploadType.IMAGE_ONLY}
                relatedActivities={relatedActivitiesForRteTags}
                extraTags={[RteTagValue.INSERT_PASSWORD_SENTENCE]}
                fileExtensions={config.files.rte.images.common}
              />
            </div>
          </div>
          <div className={`notification mt-5 mb-4 ${includePushNotification ? 'include-notification' : ''}`}>
            <div className='notification-header py-4 px-0'>
              <NvCheckbox
                withForm
                name='includePushNotification'
                label={t.COURSE_COMMUNICATIONS.PUSH_NOTIFICATION.LABEL()}
                labelClassName='page-title-small font-weight-bold'
              />
            </div>
            <div className='notification-input'>
              <NvTextArea
                withForm
                ref={ref}
                name='pushNotificationText'
                ariaLabel={t.COURSE_COMMUNICATIONS.PUSH_NOTIFICATION.ARIA_LABEL()}
                placeholder={t.COURSE_COMMUNICATIONS.PUSH_NOTIFICATION.PLACEHOLDER()}
                errorOnTouching={false}
              />
            </div>
          </div>
          <ExtraTermsInfo />
          <div className='actions pb-7'>
            <div className='buttons'>
              <Button onClick={prevStep} className='go-back mr-2' variant='secondary'>{t.FORM.BACK()}</Button>
              <Button type='submit' disabled={!formState.isValid}>{t.COURSE_COMMUNICATIONS.TRIGGERS.CREATE.NEXT_BUTTON.STEP_2()}</Button>
            </div>
            <SaveDraft
              onClick={saveAsDraft}
              changesMade={formState.isValid && isDirty}
              draftSaved={draftSubmittedAt}
              isDraft={communicationDraft?.submitted === false}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default StepTwo;

