import { css } from '@emotion/react';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import _ from 'underscore';

// Contexts
import { useTagsState, useTagsDispatch } from 'froala/hooks/use-tags-history';

// selectors
import { isCourseSelfPaced, getCourseAliases, getCourseActivities, getCurrentCourse } from 'redux/selectors/course';
import { getKeyForActivityType } from 'redux/selectors/activity';

// schemas
import { RootState } from 'redux/schemas';
import { ActivityType } from 'redux/schemas/models/activity';
import { CourseActivities } from 'redux/schemas/api/course-communications';
import { LecturePageType } from 'redux/schemas/models/lecture-page';

// constants
import { RteTag, RteTagTypes, RelatedActivity, RteTagValue } from 'froala/helpers/nv-froala-constants';


// styles
import { threeQuartersSpacing, halfSpacing, standardSpacing, quarterSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { gray3, primary } from 'styles/global_defaults/colors';
import { textSmallFontSize } from 'styles/global_defaults/fonts';

// components
import { convertRelatedActivityToRteTag, getDefaultTags, getExtraTags } from 'froala/helpers/nv-froala-tag-functions';
import { FeedbackCategory } from 'redux/schemas/models/course-communication';
import { FroalaTagSelectorCheckbox, onItemChanged } from './froala-tag-selector-checkbox';
import { FroalaCreateNewTagModal } from './froala-create-new-tag-modal';


type FroalaTagsModalProps = {
  closeModal(tags: { [key: string]: RteTag }): void,
  relatedActivities: RelatedActivity[],
  extraTags?: RteTagValue[],
};

const styles = css`
  .tag-list-container {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin: ${quarterSpacing}px ${halfSpacing}px ${quarterSpacing}px 0px;
      display: inline-flex;

      .pick-new {
        font-size: ${textSmallFontSize}px;
        color: ${primary};
        margin-left: ${halfSpacing}px;
      }
    }
  }

  .tag-heading {
    color: ${gray3};
    font-size: ${textSmallFontSize}px;
    margin-left: ${halfSpacing}px;
  }

  hr {
    margin-top: ${threeQuartersSpacing}px;
    margin-bottom: ${standardSpacing}px;
  }

  .button-row {
    margin-top: ${tripleSpacing}px;
    margin-bottom: ${standardSpacing}px;
  }
`;

function getSuggestedTagsFromRelatedActivities(relatedActivities: RelatedActivity[], courseActivities: CourseActivities): { [key: string]: RteTag } {
  let suggestedTags: { [key: string]: RteTag } = {};
  if (relatedActivities) {
    // Parse Suggested Tags
    if (relatedActivities) {
      _.each(relatedActivities, (activity: RelatedActivity) => {
        let keyForActivityType = getKeyForActivityType(activity.type);
        let courseActivity = courseActivities[keyForActivityType];
        let activityDetails = _.find((courseActivity || [] as any), (item: any) => (item.id === activity.id));
        if (!activityDetails && activity.type === ActivityType.VIDEO_PRACTICE_SKILLS_FEEDBACK) {
          keyForActivityType = getKeyForActivityType(activity.type, FeedbackCategory.VIDEO_PRACTICE_SKILLS_FEEDBACK);
          courseActivity = courseActivities[keyForActivityType];
          activityDetails = _.find((courseActivity || [] as any), (item: any) => (item.id === activity.id));
        }

        const newUrlTag: RteTag = convertRelatedActivityToRteTag(activity, RteTagTypes.URL, false, activityDetails?.isFeedbackPublic, activityDetails?.category);
        suggestedTags = {
          ...suggestedTags,
          [newUrlTag.tagValue]: newUrlTag,
        };
        if (activity.type === ActivityType.LIVE_SESSION
          || activity.type === LecturePageType.LECTURE
          || (_.has(activityDetails, 'dueDate') && activityDetails.dueDate)) {
          const newDateTag: RteTag = convertRelatedActivityToRteTag(activity, RteTagTypes.DATE, false, activityDetails?.isFeedbackPublic, activityDetails?.category);
          suggestedTags = {
            ...suggestedTags,
            [newDateTag.tagValue]: newDateTag,
          };
        }
      });
    }
  }
  return suggestedTags;
}


export const FroalaTagsModal = ({
  closeModal,
  relatedActivities,
  extraTags,
}: FroalaTagsModalProps) => {
  const addToTagsHistory = useTagsDispatch();
  const tagsHistory = useTagsState();

  const aliases = useSelector((state) => getCourseAliases(state));
  const courseActivities: CourseActivities = useSelector((state) => getCourseActivities(state));

  const isSelfPaced = useSelector<RootState, boolean>((state) => isCourseSelfPaced(state));
  const [displayPickNewLink, setDisplayPickAnotherLink] = useState<boolean>();
  const [displayPickNewDate, setDisplayPickNewDate] = useState<boolean>();
  const { isJourney } = useSelector((state) => getCurrentCourse(state));
  const [tags, setTags] = useState<{ [key: string]: RteTag }>({
    ...(getDefaultTags(isSelfPaced, aliases, isJourney)),
    ...(getSuggestedTagsFromRelatedActivities(relatedActivities, courseActivities)),
    ...(getExtraTags(extraTags)),
    ...(_.reduce((tagsHistory || []), (prevTags, newTag) => ({ ...prevTags, [newTag.tagValue]: newTag }), {})),
  });

  const onChange: onItemChanged = (tag, checked) => {
    setTags({
      ...tags,
      [tag.tagValue]: {
        ...tag,
        selected: !!checked,
      },
    });
  };

  const submitTags = () => {
    closeModal(tags);
  };

  const addNewTag = (tag: RteTag) => {
    setTags({
      ...tags,
      [tag.tagValue]: tag,
    });
    addToTagsHistory({ ...tag, selected: false });
  };

  const tagsGroupByType = _.groupBy(_.values(tags), 'type');
  return (
    <div css={styles}>
      <div>
        <label className='tag-heading'>{t.FROALA.INSERT_TAGS.NAMES_HEADING()}</label>
        <ul className='tag-list-container'>
          { tagsGroupByType[RteTagTypes.NAME].map(tag => (
            <li key={tag.tagValue}>
              <FroalaTagSelectorCheckbox tag={tag} onChange={onChange} />
            </li>
          )) }
        </ul>
      </div>
      <hr />
      <div>
        <label className='tag-heading'>{t.FROALA.INSERT_TAGS.URL_HEADING()}</label>
        <ul className='tag-list-container'>
          { tagsGroupByType[RteTagTypes.URL].map(tag => (
            <li key={tag.tagValue}>
              <FroalaTagSelectorCheckbox tag={tag} onChange={onChange} />
            </li>
          )) }
          { !displayPickNewLink && (
            <li key='pick-new-link'>
              <a className='pick-new' onClick={() => setDisplayPickAnotherLink(true)}>{isJourney ? t.FROALA.INSERT_TAGS.PICK_ANOTHER_COURSE() : t.FROALA.INSERT_TAGS.PICK_ANOTHER_LINK()}</a>
            </li>
          )}
        </ul>
        { displayPickNewLink && (
          <FroalaCreateNewTagModal
            onClose={() => setDisplayPickAnotherLink(false)}
            onAdd={addNewTag}
            tags={tags}
            rteTagType={RteTagTypes.URL}
            isJourney={isJourney}
          />
        )}
      </div>
      <hr />
      <div>
        <label className='tag-heading'>{t.FROALA.INSERT_TAGS.DATES_HEADING()}</label>
        <ul className='tag-list-container'>
          { tagsGroupByType[RteTagTypes.DATE].map(tag => (tag.entityType === 'LiveSession' && tag.category !== FeedbackCategory.LIVE_EVENT_SESSION ? null : (
            <li key={tag.tagValue}>
              <FroalaTagSelectorCheckbox tag={tag} onChange={onChange} />
            </li>
          ))) }
          { !displayPickNewDate && (
            <li key='pick-new-date'>
              <a className='pick-new' onClick={() => setDisplayPickNewDate(true)}>{ isJourney ? t.FROALA.INSERT_TAGS.PICK_COURSE_RELATED_DATES() : t.FROALA.INSERT_TAGS.PICK_ANOTHER_DATE()}</a>
            </li>
          )}
        </ul>
        { displayPickNewDate && (
          <FroalaCreateNewTagModal
            onClose={() => setDisplayPickNewDate(false)}
            onAdd={addNewTag}
            tags={tags}
            rteTagType={RteTagTypes.DATE}
            isJourney={isJourney}
          />
        )}
      </div>
      {tagsGroupByType[RteTagTypes.ACCESS_INSTRUCTION]?.length > 0 && (
        <React.Fragment>
          <hr />
          <div>
            <label className='tag-heading'>{t.FROALA.INSERT_TAGS.ACCESS_INSTRUCTION_HEADING()}</label>
            <ul className='tag-list-container'>
              { tagsGroupByType[RteTagTypes.ACCESS_INSTRUCTION]?.map(tag => (
                <li key={tag.tagValue}>
                  <FroalaTagSelectorCheckbox tag={tag} onChange={onChange} />
                </li>
              )) }
            </ul>
          </div>
        </React.Fragment>
      )}
      <div className='button-row bs4-row'>
        <div className='mx-auto'>
          <Button type='button' size='sm' variant='primary' onClick={submitTags}>{t.FORM.DONE()}</Button>
        </div>
      </div>
    </div>
  );
};

export default FroalaTagsModal;
