/* @ngInject */
export default function InstitutionEditEmailTemplateCtrl(

  InstitutionsManager,
  InstitutionsResource,
  $state,
  $uibModal,
  _,
) {
  const vm = this;

  vm.institution = InstitutionsManager.institution;
  vm.saveEmailTemplate = saveEmailTemplate;
  vm.cancelEditTemplate = cancelEditTemplate;
  vm.showEmailPreview = showEmailPreview;

  let lastSavedInstitution;

  initialize();

  function initialize() {
    lastSavedInstitution = _.clone(vm.institution);
  }

  function saveEmailTemplate() {
    const attributes = _.pick(vm.institution, ['emailHeader', 'emailHeaderStyle']);

    InstitutionsResource.updateEmailTemplate(
      { id: vm.institution.id },
      attributes,
    ).$promise.then(() => {
      $state.go('institution-email-template');
    });
  }

  function cancelEditTemplate() {
    InstitutionsManager.institution = lastSavedInstitution;
  }

  function showEmailPreview() {
    $uibModal.open({
      controller: 'InstitutionEmailTemplateCtrl',
      controllerAs: 'vm',
      templateUrl: 'institutions/templates/email-preview-modal.html',
    });
  }
}
