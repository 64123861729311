/* @ngInject */
export default function InteroperabilityRoutes(
  InstitutionsManager,
  config,
  $state,
) {
  const HASH = '/#!/';
  const OE_HASH_BANG = '/oe/#!/';

  /** Returns specific state params as query strings, if present, to be appended to the interop route strings below
   * @param {bool} queryParamsStart Whether or not the return string is the beginning of the query params part of a url
  */
  function preserveParams(queryParamsStart) {
    const params = [];

    if ($state.params.mobileapp) {
      params.push(`mobileapp=${$state.params.mobileapp}`);
    }

    return (queryParamsStart ? '?' : '&') + params.join('&');
  }

  function getHostName(course) {
    return [config.app.protocol, '://', course?.institution?.host || InstitutionsManager.institution.host].join('');
  }

  return {
    downloadAiccUrl(course) {
      return [getHostName(course), '/', course.catalogId, '/download_aicc', preserveParams(true)].join('');
    },
    courseHomePath(course) {
      return [getHostName(course), HASH, 'courses/', course.catalogId, '/home', preserveParams(true)].join('');
    },
    journeyHomePath(course) {
      return [getHostName(course), HASH, 'journeys/', course.catalogId, '/home', preserveParams(true)].join('');
    },
    journeyHomePathFromId(course, journeyId) {
      return [getHostName(course), HASH, 'journeys/', journeyId, '/home', preserveParams(true)].join('');
    },
    userPath(userId) {
      return `${'/users/'}${userId}${preserveParams(true)}`;
    },
    userInCoursePath(course, userId) {
      return [getHostName(course), HASH, 'courses/', course.catalogId, '/users/', userId, preserveParams(true)].join('');
    },
    newTransactionPath(course) {
      return [getHostName(course), '/', course.catalogId, '/transactions/new', preserveParams(true)].join('');
    },

    /* Course Mentees */
    myMentees(course, userId) {
      return [getHostName(course), HASH, 'courses/', course.catalogId, '/mentees/User/', userId, '?filter=total&sort=progress&sortOrder=desc', preserveParams(true)].join('');
    },

    /* Lecture Pages */
    lecturePath(course, lectureId) {
      return [getHostName(course), HASH, 'courses/', course.catalogId, '/lecture_pages/', lectureId, preserveParams(true)].join('');
    },
    lectureActivityPath(course, lectureId, lectureActivityId) {
      return [getHostName(course), HASH, 'courses/', course.catalogId, '/lecture_pages/', lectureId, '?lectureActivityId=', lectureActivityId, preserveParams()].join('');
    },

    /* Exercises */
    exerciseSubmissionsIndexPath(course, exerciseId) {
      return [getHostName(course), HASH, 'courses/', course.catalogId, '/submission-gallery?exerciseId=', exerciseId, preserveParams()].join('');
    },
    evaluationsPath(course, lecturePageId, evaluationId) {
      return [getHostName(course), HASH, 'courses/', course.catalogId, '/lecture_pages/', lecturePageId, '?lectureActivityId=', evaluationId, preserveParams()].join('');
    },
    receivedPeerEvaluationsPath(course, exerciseId, reportId) {
      return [getHostName(course), HASH, 'courses/', course.catalogId, '/reports/', reportId, '/evaluation-results', preserveParams(true)].join('');
    },
    submissionPath(course, submissionType, submissionId, focusCommentId, galleryMode, replyId, userId) {
      if (userId) {
        return [getHostName(course), HASH, 'courses/', course.catalogId, '/reports/', submissionId, '?galleryMode=', galleryMode, '&userId=', userId, preserveParams()].join('');
      }
      return [getHostName(course), HASH, 'courses/', course.catalogId, '/reports/', submissionId, '?galleryMode=', galleryMode, '&commentId=', focusCommentId, '&replyId=', replyId, preserveParams()].join('');
    },
    videoQuizEditPath(course, ownerId) {
      return [getHostName(course), '/', course.catalogId, '/question_sets/new', '?owner_id=', ownerId, '&owner_type=lecture_videos&type=video_quizzes', preserveParams()].join('');
    },

    /* Team */
    teamWorkspacePath(course, teamId, isMentorFeedbackTab) {
      return [getHostName(course), HASH, 'courses/', course.catalogId, '/teams/', teamId, '/workspace', preserveParams(true)].join('');
    },
    teamWorkspacePostPath(course, teamId, postId) {
      return [getHostName(course), HASH, 'courses/', course.catalogId, '/teams/', teamId, '/workspace/posts/', postId, preserveParams(true)].join('');
    },
    teamWorkspaceCommentPath(course, teamId, postId, commentId) {
      return [getHostName(course), HASH, 'courses/', course.catalogId, '/teams/', teamId, '/workspace/posts/', postId, '/comments/', commentId, preserveParams(true)].join('');
    },
    teamWorkspaceReplyPath(course, teamId, postId, commentId, replyId) {
      return [getHostName(course), HASH, 'courses/', course.catalogId, '/teams/', teamId, '/workspace/posts/', postId, '/comments/', commentId, '/replies/',
        replyId, preserveParams(true)].join('');
    },
    teamWorkspaceMeetingAvailabilityPollResponsePath(course, teamId, meetingId) {
      return [getHostName(course), '/', course.catalogId, OE_HASH_BANG, 'teams/', teamId, '/workspace/meetings/', meetingId, '/availabilityPollResponses/new',
        preserveParams(true)].join('');
    },
    teamProfilePath(course, teamId, blogPostId) {
      return [getHostName(course), HASH, 'courses/', course.catalogId, '/teams/', teamId, preserveParams(true)].join('');
    },

    /* Discussions */
    discussionsPath(course) {
      return [getHostName(course), HASH, 'courses/', course.catalogId, '/dicussions', preserveParams(true)].join('');
    },
    isolatedPostPath(course, topicId, postId) {
      return [getHostName(course), HASH, 'courses/', course.catalogId, '/discussions/topics/', topicId, '/posts/', postId, preserveParams(true)].join('');
    },
    isolatedCommentPath(course, topicId, postId, commentId) {
      return [getHostName(course), HASH, 'courses/', course.catalogId, '/discussions/topics/', topicId, '/posts/', postId, '/comments/', commentId, preserveParams(true)].join('');
    },
    isolatedReplyPath(course, topicId, postId, commentId, replyId) {
      return [getHostName(course), HASH, 'courses/', course.catalogId, '/discussions/topics/', topicId, '/posts/', postId, '/comments/', commentId,
        '/replies/', replyId, preserveParams(true)].join('');
    },
    editQuizPath(course, questionSetId) {
      return [getHostName(course), '/', course.catalogId, '/question_sets/', questionSetId, '/edit', preserveParams(true)].join('');
    },
    statementOfAccomplishmentPath(course, statementUserId) {
      if (statementUserId) {
        return [getHostName(course), '/', course.catalogId, '/statement_template?user_id=', statementUserId, '&download=true', preserveParams()].join('');
      }
      return [getHostName(course), '/', course.catalogId, '/my_statement', preserveParams(true)].join('');
    },
    conversationsInCoursePath(course) {
      return [getHostName(course), '/', course.catalogId, OE_HASH_BANG, 'conversations', preserveParams(true)].join('');
    },
    conversationsIsolatedInCoursePath(course, conversationId) {
      return [getHostName(course), '/', course.catalogId, OE_HASH_BANG, 'conversations/', conversationId, preserveParams(true)].join('');
    },
    activityPath(course, activity, isAdmin) {
      if (activity.type === 'LecturePage') {
        return this.lecturePath(course, activity.id);
      }
      return this.lectureActivityPath(course, activity.lecturePageId, activity.lectureComponentId);
    },
    editCourseFlyerUrl(course) {
      if (course.isProgram) {
        return `${getHostName(course)}/${course.catalogId}?edit=true${preserveParams()}`;
      }
      return `${getHostName(course)}/${course.catalogId}/dashboard?tab=flyer_page${preserveParams()}`;
    },
    editTeachingTeamUrl(course) {
      return `${getHostName(course)}/${course.catalogId}/dashboard?tab=teaching_team${preserveParams()}`;
    },
    analyticsPath(course, section) {
      return [getHostName(course), '/', course.catalogId, OE_HASH_BANG, 'dashboard/analytics#', section].join('');
    },
    configurationsPath(course) {
      return [getHostName(course), `/${course.catalogId}/dashboard?tab=configurations${preserveParams()}`].join();
    },
    getPracticeRoomSubmissionCommentPath(scenario, comment) {
      return `${getHostName()}/practice-room-modal/${scenario.id}?comment_id=${comment.id}`;
    },
  };
}
