import { css } from '@emotion/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, range } from 'underscore';

import t from 'react-translate';
import { CombinedInstitution, RootState } from 'redux/schemas';
import { RecordingFormat, SubmissionPrivacySetting, ScenarioReusabilitySetting, VideoPracticeScenario } from 'redux/schemas/models/video-practice';
import { CourseAliases } from 'redux/schemas/models/course';
import { getCourseAliases } from 'redux/selectors/course';
import { NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';
import NvFormDropdown from 'shared/components/inputs/nv-form-dropdown';
import NvRadioButton from 'shared/components/inputs/nv-radio-button';
import NvFroala from 'froala/components/nv-froala';
import { FroalaViewMode, UploadType, ToolbarOptions } from 'froala/helpers/nv-froala-constants';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvIcon from 'shared/components/nv-icon';
import { useAppDispatch } from 'redux/store';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';

import NvPopover from 'shared/components/nv-popover';
import { primary } from 'styles/global_defaults/colors';
import { SanitizationLevel } from 'froala/helpers/sanitizer';
import NvSwitch from 'shared/components/inputs/nv-switch';

import { getCurrentInstitution } from 'redux/selectors/institutions';
import VideoPracticeScenarioCard from './video-practice-scenario-card';
import VideoPracticePrompt from './video-practice-prompt';
import { VideoPracticeOptionsModalContext, CreateVideoPracticeOptionForm } from './video-practice-options-modal';
import InsightsKeyPhrases from './insight-key-phrases';
import { config } from '../../../../config/pendo.config.json';

type CreateVideoPracticeOptionProps = {
  onSubmit: (e: any) => void;
  onCancel: (e: any) => void;
  formData: CreateVideoPracticeOptionForm & VideoPracticeScenario;
};

type InfoIconProps = {
  text: string;
};

const InfoIcon = ({ text }: InfoIconProps) => (
  <NvPopover
    showOnHover
    placement='top'
    content={<div>{text}</div>}
    className='d-flex align-items-center ml-2'
  >
    <NvIcon icon='info' size='xss-smallest' />
  </NvPopover>
);

const CreateVideoPracticeOption = ({
  onSubmit,
  onCancel,
  formData,
}: CreateVideoPracticeOptionProps) => {
  const styles = css`
    .dropdown-container {
      width: 120px;
    }

    .icon-info {
      color: ${primary};
      cursor: pointer;
    }

    .button-bar {
      margin-top: 60px;
      margin-bottom: 40px;
    }


  `;

  const containerRef = useRef<HTMLDivElement>();

  const dispatch = useAppDispatch();

  const { editScenario, setTitle, closeModal } = useContext(VideoPracticeOptionsModalContext);
  const aliases: CourseAliases = useSelector((state: RootState) => getCourseAliases(state));
  const institution: CombinedInstitution = useSelector((state: RootState) => getCurrentInstitution(state));

  const validationSchema = yup.object().shape({
    title: yup.string().required().trim(),
    recordingLimit: yup.object().shape({
      min: yup.mixed().required(),
      sec: yup.mixed().required(),
    }),
    recordingFormat: yup.mixed<RecordingFormat>(),
    privacy: yup.mixed<SubmissionPrivacySetting>(),
    reusable: editScenario ? yup.mixed<ScenarioReusabilitySetting>() : yup.mixed<ScenarioReusabilitySetting>().required(),
    insightCriterion: yup.object().nullable(),
    enableRequestReview: yup.boolean().nullable(),
  });

  const methods = useForm<CreateVideoPracticeOptionForm>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    defaultValues: formData,
  });

  const { formState, register, watch, handleSubmit, getValues, setValue, setError, clearErrors } = methods;
  const { isValid, isDirty, errors } = formState;

  const [phrasesToCover, setPhrasesToCover] = useState<string[]>([]);
  const [phrasesToAvoid, setPhrasesToAvoid] = useState<string[]>([]);

  const recordingMins = (watch('recordingLimit.min') as NvDropdownTextItem).value;
  const recordingSecs = (watch('recordingLimit.sec') as NvDropdownTextItem).value;
  const insightCriterion = (watch('insightCriterion'));
  const isInsightsEnabled = (watch('isInsightsEnabled'));
  const enableRequestReview = (watch('enableRequestReview'));

  const isZeroMins = recordingMins <= 0;
  const isMoreThan10 = recordingMins >= 10;
  const isZeroSecs = recordingSecs <= 0;

  const minuteOptions: NvDropdownTextItem[] = range(11).map(i => ({
    type: 'text',
    id: i,
    value: i,
    text: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORDING_LIMIT.MINS_TEXT(i),
  }));

  const secOptions: NvDropdownTextItem[] = range(4).map(i => ({
    type: 'text',
    id: i,
    value: i * 15,
    text: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORDING_LIMIT.SECS_TEXT(i * 15),
    disabled: (i === 0 && isZeroMins) || (i !== 0 && isMoreThan10),
  }));

  useEffect(() => {
    // If 0 mins is selected, set seconds to 15ß
    if (isZeroMins && isZeroSecs) {
      setValue('recordingLimit.sec', secOptions[1]);
    }
  }, [isZeroMins, isZeroSecs]);

  useEffect(() => {
    setValue('insightCriterion', {
      keyphrasesToAvoid: phrasesToAvoid,
      keyphrasesToCover: phrasesToCover,
    }, { shouldDirty: isInsightsEnabled, shouldValidate: false });
  }, [phrasesToCover, phrasesToAvoid, isInsightsEnabled]);

  useEffect(() => {
    // if 10 mins is selected, set seconds to 0
    if (isMoreThan10) {
      setValue('recordingLimit.sec', secOptions[0]);
    }
  }, [isMoreThan10]);

  useEffect(() => {
    if (editScenario) {
      setTitle(t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.EDIT.PRACTICE_SCENARIO());
    } else {
      setTitle(t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.CREATE_PRACTICE_SCENARIO.DESCRIPTION());
    }
  }, [setTitle, editScenario]);

  useEffect(() => {
    register('recordingFormat');
  }, [register]);
  useEffect(() => {
    register('privacy');
  }, [register]);
  useEffect(() => {
    register('reusable');
  }, [register]);
  useEffect(() => {
    register('textPrompt');
  }, [register]);

  const [recordingFormat] = watch(['recordingFormat']);
  const [privacy] = watch(['privacy']);
  const [reusable] = watch(['reusable']);
  const [textPrompt] = watch(['textPrompt']);
  const [videoPrompt] = watch(['videoPrompt']); // note: videoPrompt is registered in the VideoPracticePrompt component

  const cancel = (e) => {
    if (editScenario) {
      closeModal();
    } else {
      onCancel(getValues());
    }
  };

  const submit = (data, e) => {
    if (formData.numCoursesUsedIn + formData.numCollectionsUsedIn > 1) {
      const bodyText: JSX.Element = (
        <div>
          {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.EDIT.CONFIRMATION(aliases.courseAliases)}
          <div className='text-left text-medium font-weight-bold ml-6 pl-6 mt-4'>
            <ul className='ml-4 pl-4 mb-0'>
              {formData.coursesUsedIn.map((course, idx) => (
                <li key={course.catalogId}>{`${course.name} (${course.catalogId})`}</li>
              ))}
              {formData.collectionsUsedIn.map((collection) => (
                <li key={collection.catalogId}>{`${collection.name} (Collection)`}</li>
              ))}
            </ul>
            {formData.numCoursesUsedIn - formData.coursesUsedIn.length > 0 && <div className='ml-4 pl-4'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.VIEW_PRACTICE_SCENARIO.PLUS_X_MORE_COURSES({ ...aliases.courseAliases, num: formData.numCoursesUsedIn - formData.coursesUsedIn.length })}</div>}
            {formData.numCollectionsUsedIn - formData.collectionsUsedIn.length > 0 && <div className='ml-4 pl-4'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.VIEW_PRACTICE_SCENARIO.PLUS_X_MORE_COLLECTIONS((formData.numCollectionsUsedIn - formData.collectionsUsedIn.length).toString())}</div>}
          </div>
        </div>
      );
      dispatch(openConfirmationDialog({
        title: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.LEAVE.ARE_YOU_SURE(),
        bodyText,
        confirmText: t.FORM.YES_SURE(),
        onConfirm: () => {
          onSubmit(data);
        },
      }));
    } else {
      onSubmit(data);
    }
  };
  const textPromptValidation = (text) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = text;
    if (tempElement.innerText.trim() === 'undefined') {
      return false;
    }

    return !!tempElement.innerText.trim();
  };
  const isDisabled = !isValid
    || (!isDirty && videoPrompt === formData.videoPrompt) // videoPrompt is not part of formState, so we check if it's changed manually
    || !(textPromptValidation(textPrompt) || videoPrompt) // At least one of videoPrompt and textPrompt is required
    || !isEmpty(errors);

  const updateButtonText = (reusable === ScenarioReusabilitySetting.NOT_REUSABLE)
    ? t.FORM.UPDATE()
    : t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.EDIT.UPDATE_FOR_ALL();

  const onErrorCallback = (error: any, key: 'insightCriterion.keyphrasesToCover' | 'insightCriterion.keyphrasesToAvoid') => (error
    ? setError(key, { type: 'validate', message: error })
    : clearErrors(key));

  return (
    <FormProvider {...methods}>
      <form className='video-practice-form mx-4 my-2' onSubmit={handleSubmit(submit)}>
        <div css={styles} ref={containerRef}>
          <NvTextInput
            withForm
            required
            name='title'
            maxLength={255}
            autoComplete='off'
            popoverPlacement='bottom'
            placeholder={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.CREATE_PRACTICE_SCENARIO.TITLE_PLACEHOLDER()}
          />
          <div className='font-weight-bold mt-5 mb-2'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.CREATE_PRACTICE_SCENARIO.ADD_PROMPT_DESCRIPTION({ ...aliases.learnersAliases })}</div>
          <NvFroala
            withForm
            sanitizationLevel={SanitizationLevel.SIMPLE}
            preset={FroalaViewMode.NORMAL}
            uploadType={UploadType.NONE}
            toolbarButtons={[
              ToolbarOptions.BOLD,
              ToolbarOptions.ITALIC,
              ToolbarOptions.UNDERLINE,
              ToolbarOptions.FORMAT_UL,
              ToolbarOptions.FORMAT_OL,
            ]}
            name='textPrompt'
            className='mb-5'
            placeholder={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.CREATE_PRACTICE_SCENARIO.PROMPT_PLACEHOLDER()}
          />
          <VideoPracticePrompt file={formData.videoPrompt} />
          <div className='font-weight-bold mt-4 mb-2'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORDING_LIMIT.DESCRIPTION()}</div>
          <div className='d-flex'>
            <div className='dropdown-container mr-1'>
              <NvFormDropdown
                isSmallSize
                name='recordingLimit.min'
                items={minuteOptions}
                titleClass='text-medium'
              />
            </div>
            <div className='dropdown-container'>
              <NvFormDropdown
                isSmallSize
                name='recordingLimit.sec'
                items={secOptions}
                titleClass='text-medium'
              />
            </div>
          </div>
          <div>
            <div className='d-flex mt-5'>
              <div className='font-weight-bold'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.ALLOWED_SUBMISSION_TYPE_SETTING.TITLE()}</div>
              <InfoIcon text={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.ALLOWED_SUBMISSION_TYPE_SETTING.TITLE_HELP_TEXT()} />
            </div>
            <div className='d-flex'>
              <NvRadioButton
                withForm
                name='recordingFormat'
                value={RecordingFormat.VIDEO}
                label={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.ALLOWED_SUBMISSION_TYPE_SETTING.VIDEO()}
                disabled={editScenario}
              />
            </div>
            <div className='d-flex'>
              <NvRadioButton
                withForm
                name='recordingFormat'
                value={RecordingFormat.AUDIO}
                label={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.ALLOWED_SUBMISSION_TYPE_SETTING.AUDIO()}
                disabled={editScenario}
              />
            </div>
          </div>
          {institution.speechToTextEnabled && (
            <div>
              <div className='font-weight-bold mt-5'>
                {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.INSIGHTS.TITLE()}
              </div>
              <div className='d-flex my-4'>
                <NvSwitch
                  withForm
                  className='mr-2'
                  name='isInsightsEnabled'
                  data-qa='automated-feedback-toggle-scenario-insights'
                  pendo-tag-name={config.pendo.practice.toggleInsights}
                />
                <div className='gray-1'>
                  {isInsightsEnabled
                    ? t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.INSIGHTS.ENABLED()
                    : t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.INSIGHTS.NOT_ENABLED()}
                </div>
              </div>
              <div className={`${isInsightsEnabled ? 'p-4 bg-gray-7' : 'd-none'}`}>
                <div className='gray-1 text-body mb-4'>
                  {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.INSIGHTS.DESCRIPTION(aliases.learnersAliases)}
                </div>
                <div className='gray-2 mb-4'>
                  {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.INSIGHTS.NOTE()}
                </div>
                <div className='font-weight-bolder gray-1 mb-4'>
                  {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.INSIGHTS.KEY_PHRASES_TO_MENTION()}
                </div>
                <InsightsKeyPhrases
                  onChange={(phrases) => setPhrasesToCover(phrases)}
                  isDisabled={phrasesToCover.length + phrasesToAvoid.length >= 25}
                  keyPhrases={insightCriterion?.keyphrasesToCover ?? []}
                  onError={(error) => onErrorCallback(error, 'insightCriterion.keyphrasesToCover')}
                  dataQa='to-cover'
                />
                <div className='font-weight-bolder gray-1 my-4'>
                  {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.INSIGHTS.KEY_PHRASES_TO_AVOID()}
                </div>
                <InsightsKeyPhrases
                  onChange={(phrases) => setPhrasesToAvoid(phrases)}
                  isDisabled={phrasesToCover.length + phrasesToAvoid.length >= 25}
                  keyPhrases={insightCriterion?.keyphrasesToAvoid ?? []}
                  onError={(error) => onErrorCallback(error, 'insightCriterion.keyphrasesToAvoid')}
                  dataQa='to-avoid'
                />
              </div>
            </div>
          )}
          {/* REQUEST REVIEW SWITCH */}
          <div>
            <div className='font-weight-bold mt-5'>
              {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.REQUEST_REVIEWS.TITLE()}
            </div>
            <div className='d-flex my-4'>
              <NvSwitch
                withForm
                className='mr-2'
                name='enableRequestReview'
                data-qa='automated-feedback-toggle-request-reviews'
                pendo-tag-name={config.pendo.practice.toggleRequestReviews}
              />
              <div className='gray-1'>
                {enableRequestReview
                  ? t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.REQUEST_REVIEWS.ENABLED()
                  : t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.REQUEST_REVIEWS.NOT_ENABLED()}
              </div>
            </div>
            <div
              className={`${
                enableRequestReview ? 'p-4 bg-gray-7' : 'd-none'
              }`}
            >
              <div className='gray-1 text-body'>
                {isInsightsEnabled && !!institution?.skillTags?.length ? (
                  <>
                    {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.REQUEST_REVIEWS.NOTE({ ...aliases.learnersAliases, ...aliases.courseAliases })}
                    {' '}<strong>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.REQUEST_REVIEWS.NOTE_FOR_REQUIREMENTS()}</strong>
                  </>
                ) : (
                  t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.REQUEST_REVIEWS.WARNING_NOTE()
                )}
              </div>
            </div>
          </div>

          <div>
            <div className='font-weight-bold mt-5'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SUBMISSION_PRIVACY_SETTING.DESCRIPTION()}</div>
            <div className='d-flex'>
              <NvRadioButton
                withForm
                name='privacy'
                value={SubmissionPrivacySetting.SHARED}
                label={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SUBMISSION_PRIVACY_SETTING.SHARED()}
              />
              <InfoIcon text={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SUBMISSION_PRIVACY_SETTING.SHARED_TOOLTIP({ ...aliases.learnersAliases, ...aliases.courseAliases })} />
            </div>
            {editScenario && privacy === SubmissionPrivacySetting.SHARED && formData.privacy !== SubmissionPrivacySetting.SHARED
              && <div className='font-weight-bold warning ml-5'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.EDIT.SHARED_WARNING({ ...aliases.learnersAliases, ...aliases.teachingTeamAliases })}</div>}
            <div className='d-flex'>
              <NvRadioButton
                withForm
                name='privacy'
                value={SubmissionPrivacySetting.RESTRICTED}
                label={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SUBMISSION_PRIVACY_SETTING.RESTRICTED(aliases.teachingTeamAliases)}
              />
              <InfoIcon text={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SUBMISSION_PRIVACY_SETTING.RESTRICTED_TOOLTIP({ ...aliases.learnersAliases, ...aliases.courseAliases })} />
            </div>
            {editScenario && privacy === SubmissionPrivacySetting.RESTRICTED && formData.privacy !== SubmissionPrivacySetting.RESTRICTED
              && <div className='font-weight-bold warning ml-5'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.EDIT.PRIVATE_WARNING(aliases.learnersAliases)}</div>}
          </div>
          {editScenario
            ? <VideoPracticeScenarioCard scenario={{ ...formData, privacy: formData.privacy, reusable: formData.reusable }} detailedView editScenario />
            : (
              <div>
                <div className='font-weight-bold mt-4'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SCENARIO_REUSABILITY_SETTING.DESCRIPTION()}</div>
                <div className='d-flex'>
                  <NvRadioButton
                    withForm
                    name='reusable'
                    value={ScenarioReusabilitySetting.REUSABLE}
                    label={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SCENARIO_REUSABILITY_SETTING.REUSABLE(aliases.courseAliases)}
                  />
                  <InfoIcon text={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SCENARIO_REUSABILITY_SETTING.REUSABLE_TOOLTIP({ ...aliases.learnersAliases, ...aliases.courseAliases })} />
                </div>
                <div className='d-flex'>
                  <NvRadioButton
                    withForm
                    name='reusable'
                    value={ScenarioReusabilitySetting.NOT_REUSABLE}
                    label={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SCENARIO_REUSABILITY_SETTING.NOT_REUSABLE(aliases.courseAliases)}
                  />
                  <InfoIcon text={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SCENARIO_REUSABILITY_SETTING.NOT_REUSABLE_TOOLTIP()} />
                </div>
                <div>
                  {reusable === ScenarioReusabilitySetting.NOT_REUSABLE && formData.reusable !== ScenarioReusabilitySetting.NOT_REUSABLE
                    && <div className='ml-5'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SCENARIO_REUSABILITY_SETTING.WARNING_MESSAGE(aliases.lectureAliases)}</div>}
                </div>
              </div>
            )}
          <div className='button-bar'>
            <Button variant='secondary' onClick={cancel}>{t.FORM.CANCEL()}</Button>
            <Button type='submit' disabled={isDisabled} data-qa={recordingFormat === RecordingFormat.AUDIO ? 'create-audio-practice-scenario' : 'create-video-practice-scenario'}>
              {editScenario ? updateButtonText : t.FORM.CREATE()}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default CreateVideoPracticeOption;
