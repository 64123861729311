import { css } from '@emotion/react';
import ClickableContainer from 'components/clickable-container';
import React, { useContext } from 'react';
import { AngularServicesContext } from 'react-app';
import { black, gray6 } from 'styles/global_defaults/colors';
import { semiBoldFontWeight, textSmallFontSize } from 'styles/global_defaults/fonts';
import { doubleSpacing, halfSpacing, largeSpacing } from 'styles/global_defaults/scaffolding';

const FlyoutGroupTeamCard = ({ index, name, image, catalogId, teamId }) => {
  const defaultImage = '/assets/brand/temp.png';
  const styles = css`
    display:flex;
    width: 360px;
    height:65px;
    align-items:center;
    padding-left:${largeSpacing}px;

    &:hover{
      background-color: ${gray6};
      .name{
      color: ${black};
      }

    }
    .image{
      width: ${doubleSpacing}px;
      height: ${doubleSpacing}px;
      ${image !== defaultImage
        && `background-image: url('${image}')`};
        background-size:cover;
    }
    .name{
      margin-left: ${halfSpacing}px;
      font-size:${textSmallFontSize}px;
      font-weight: ${semiBoldFontWeight};
      max-width:250px;
    }
  `;

  const { $state } = useContext(AngularServicesContext);

  const goToTeamWorkspace = () => $state.go('team-workspace', { catalogId, teamId });

  return (
    <ClickableContainer
      css={styles}
      className='group-team-card'
      onClick={goToTeamWorkspace}
      data-qa={`flyout-teams-groups-card-${index}`}
    >
      <div className='image' />
      <div
        className='name two-line-ellipsis'
        data-qa={`flyout-teams-groups-card-name-${index}`}
      >
        {name}
      </div>
    </ClickableContainer>
  );
};

export default FlyoutGroupTeamCard;
