import React from 'react';
import store from 'redux/store';
import { setPreventLecturePageNavigation } from 'redux/actions/lecture-pages';
import { PreventNavigationReason } from 'redux/schemas/models/lecture-page';

const usePreventLecturePageNavigation = (getShouldPrevent: () => boolean) => {
  const shouldPrevent = getShouldPrevent();

  React.useEffect(() => {
    store.dispatch(setPreventLecturePageNavigation(shouldPrevent ? PreventNavigationReason.SAVING_IN_PROGRESS : null));
  }, [shouldPrevent]);

  React.useEffect(() => () => {
    store.dispatch(setPreventLecturePageNavigation(null));
  }, []);
};

export const useAngularPreventLecturePageNavigation = (scope: angular.IScope, getShouldPrevent: () => boolean) => {
  let firstTime = true;

  scope.$watch(getShouldPrevent, (newValue) => {
    if (!firstTime) {
      store.dispatch(setPreventLecturePageNavigation(newValue ? PreventNavigationReason.SAVING_IN_PROGRESS : null));
    } else {
      firstTime = false;
    }
  });

  scope.$on('$destroy', () => {
    store.dispatch(setPreventLecturePageNavigation(null));
  });
};

export default usePreventLecturePageNavigation;
