/* @ngInject */
export default function InformalFeedbackModalController(
  $uibModalInstance,
  currentExercise,
  currentPeerEvaluation,
  $sce,
) {
  const vm = this;
  vm.currentExercise = currentExercise;
  vm.currentPeerEvaluation = currentPeerEvaluation;
  vm.getTrustedDescription = getTrustedDescription;

  function getTrustedDescription() {
    return $sce.trustAsHtml(vm.currentPeerEvaluation.description);
  }
}
