import React, { useContext } from 'react';

import { useSelector } from 'react-redux';
import { AngularServicesContext, ExposedAngularServices } from 'react-app';
import ResponsivelyEmbeddedAngularHTML from '../responsively-embedded-angular-html';
import { CommentRowContext } from './comment-row';
import { DiscussionTranslation } from 'redux/schemas';
import {
  getCommentTranslationById,
  getReplyTranslationById
} from 'redux/selectors/discussion-translations';

type CommentPostProps = {
  isReply: boolean;
  showTranslatedComment: boolean;
};

const CommentPost = ({ isReply, showTranslatedComment }: CommentPostProps) => {
  const { comment } = useContext(CommentRowContext);
  const angularServices: ExposedAngularServices = useContext(
    AngularServicesContext
  );

  const selectItemById = isReply
    ? getReplyTranslationById
    : getCommentTranslationById;
  const { body: bodyTranslation }: DiscussionTranslation =
    useSelector(selectItemById(comment.id)) || {};

  return (
    <div className="text-body">
      <ResponsivelyEmbeddedAngularHTML
        template={showTranslatedComment ? bodyTranslation : comment.body}
        angularServices={angularServices}
      />
    </div>
  );
};

export default CommentPost;
