import { ExternalToolWebLinkTypes } from 'redux/schemas/models/external-tool';
import t from '../../../react-translate';
import store, { cloneDeepSerializable } from '../../../redux/store';
import { updateLectureComponentFromAngular } from '../../../redux/actions/lecture-components';

/* @ngInject */
export default function ExternalToolLectureComponentModel(
  _,
  $translate,
  config,
  nvUtil,
  CurrentCourseManager,
  LecturePagesResources,
  LectureComponentBaseModel,
  $timeout,
) {
  const I18N_ASPECT_RATIO_PREFIX = 'LECTURE_PAGES.COMPONENTS.ATTACHMENT_LIST.EMBEDDING_STYLE.';
  const I18N_ACTIVITY_PREFIX = 'LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.';

  const ACTIVITY_TO_ICON = {
    quiz: 'quiz',
    survey: 'survey',
    assignment: 'assignments',
    feedback: 'evaluation',
    video: 'video',
    reading: 'read',
    discussion: 'conversations',
    audio: 'audio',
  };

  const webLinkType = 'non_embedded_web_link';

  class ExternalToolLectureComponent extends LectureComponentBaseModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      const defaults = {
        type: 'ExternalToolLectureComponent',
        externalTool: {},
        isActivity: true,
        canBeCopied: true,
        canEditBasics: true,
      };

      super(_.extend({}, defaults, attributes), isSample, useDefaults);

      if (_.isEmpty(this.initialExternalTool)) {
        this.initialExternalTool = _.clone(this.externalTool);
      }

      /**
       * This key is used to verify the activity change dropdown is opened from
       * the todo toggle icon or activity change menu.
       */
      this.isOpenedActivityListFromEditMenu = false;
    }

    resetActivityDraft() {
      this.externalTool = _.clone(this.initialExternalTool);
    }

    getPayload() {
      return {
        externalTool: _.pick(this.externalTool, [
          'id', 'toolType', 'name', 'consumerKey', 'consumerSecret', 'url',
          'gradePassback', 'sendEmail', 'aspectRatio', 'isTodo', 'activityType',
          'progress', 'displayText', 'isResizing', 'autocompleteScorm',
        ]),
      };
    }

    setUrlUpdateCallback(callback) {
      this.__urlUpdateCallback = callback;
    }

    urlParams() {
      return _.extend(super.urlParams(), { lectureComponentType: 'external_tool_lecture_component' });
    }

    updateAspectRatio(aspectRatio) {
      if (aspectRatio === 'manual') {
        this.externalTool.isResizing = true;
        return this.save();
      }
      this.externalTool.isResizing = false;
      this.externalTool.aspectRatio = aspectRatio;
      return this.save();
    }

    isTodo() {
      return this.externalTool.isTodo;
    }

    shouldShowFullscreenToggle() {
      return !this.uploadInProgress
        && this.allowFullScreen
        && this.externalTool.launchUrl
        && !this.externalTool.importFailed
        && !this.externalTool.importInProgress
        && !this.externalTool.isResizing;
    }

    shouldShowEmbedLink() {
      return this.isNotTodoOrPointed()
      && !this.externalTool.importInProgress
      && !this.externalTool.importFailed
      && this.externalTool.toolType !== ExternalToolWebLinkTypes.WEB_LINK;
    }

    isWebLink() {
      return this.externalTool.toolType === webLinkType;
    }

    isNotTodoOrPointed() {
      return !(this.externalTool.isTodo || this.externalTool.pointsConfiguration);
    }

    updateActivityTypeAsTodo(activityType) {
      this.externalTool.isTodo = true;
      this.externalTool.activityType = activityType;

      if (_.isEmpty(this.externalTool.displayText)) {
        this.externalTool.displayText = this.externalTool.name;
      }

      return this.save();
    }

    updateActivityType(activityType) {
      this.externalTool.activityType = activityType;
      return this.save().then(() => {
        this.isOpenedActivityListFromEditMenu = false;
      });
    }

    updateActivityProgress() {
      this.externalTool.progress = 'in_progress';
    }

    disableToDo() {
      this.externalTool.isTodo = !this.externalTool.isTodo;

      // Clearing the title because we are not keeping the existing title
      // when disabling Todo.
      this.externalTool.displayText = null;
      return this.save();
    }

    isRequiredForCompletion() {
      return this.externalTool.isRequiredForCompletion;
    }

    getIconClass() {
      return `icon-${ACTIVITY_TO_ICON[this.externalTool.activityType]}`;
    }

    getStatus() {
      return this.externalTool.progress;
    }

    isCompleted() {
      return this.externalTool.progress === 'completed';
    }

    currentTotalPoints() {
      return nvUtil.getCurrentTotalPoints(this.externalTool.totalPoints, this.releaseDate, CurrentCourseManager.course.isDecayEnabled());
    }

    markDone() {
      this.externalTool.progress = 'completed';

      if (this.currentTotalPoints()) {
        this.externalTool.pointsReceived = this.currentTotalPoints();
      }

      return LecturePagesResources.markExternalToolAsComplete({ catalogId: this.catalogId, id: this.externalTool.id }, '').$promise
        .then((resource) => {
          _.extend(this.externalTool, resource.result);
          store.dispatch(updateLectureComponentFromAngular({ externalTool: this.externalTool }));
          return this;
        });
    }

    activitySelectDropdownOptions(sharedProps) {
      return {
        items: [
          {
            type: 'header',
            title: t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.PICK(),
          },
          ...ExternalToolLectureComponent.ACTIVITY_TYPES.map(activityType => ({
            type: 'text',
            text: $translate.instant(activityType.translateKey, {
              assignmentAlias: CurrentCourseManager.course.assignmentName.capitalizedSingularized,
            }),
            iconClass: activityType.iconClass,
            callback: () => {
              $timeout(() => {
                sharedProps?.extraOptions?.forceHideActivities();
              });
              this.setLecturePageSharedProps(this.lecturePageSharedProps(sharedProps));

              this[this.isOpenedActivityListFromEditMenu ? 'updateActivityType' : 'updateActivityTypeAsTodo'](activityType.name);
            },
            selected: activityType.name === this.externalTool.activityType,
          })),
        ],
        showActive: false,
      };
    }

    checkProgress() {
      if (this.currentTotalPoints()) {
        this.externalTool.pointsReceived = this.currentTotalPoints();
      }

      return LecturePagesResources.checkProgress({ catalogId: this.catalogId, id: this.externalTool.id }, '').$promise
        .then((resource) => {
          _.extend(this.externalTool, resource.result);
          return this;
        });
    }

    checkSCORMProgress() {
      if (this.currentTotalPoints()) {
        this.externalTool.pointsReceived = this.currentTotalPoints();
      }

      return LecturePagesResources.checkSCORMProgress({ catalogId: this.catalogId, externalToolId: this.externalTool.id }, '').$promise
        .then((resource) => {
          _.extend(this.externalTool, resource.result);
          return this;
        });
    }
  }

  ExternalToolLectureComponent.ASPECT_RATIOS = [{ name: '4:3', translateKey: `${I18N_ASPECT_RATIO_PREFIX}4BY3` },
    { name: '16:9', translateKey: `${I18N_ASPECT_RATIO_PREFIX}16BY9` },
    { name: '1:1', translateKey: `${I18N_ASPECT_RATIO_PREFIX}1BY1` },
    { name: '21:9', translateKey: `${I18N_ASPECT_RATIO_PREFIX}21BY9` },
    { name: 'a4', translateKey: `${I18N_ASPECT_RATIO_PREFIX}A4` },
    { name: 'letter', translateKey: `${I18N_ASPECT_RATIO_PREFIX}LETTER` },
    { name: 'manual', translateKey: `${I18N_ASPECT_RATIO_PREFIX}MANUALLY_SET_SIZE`, dataQa: config.pendo.manuallySizeThirdPartyEmbed.manuallySetSize }];

  ExternalToolLectureComponent.ACTIVITY_TYPES = [{ name: 'quiz', iconClass: ACTIVITY_TO_ICON.quiz, translateKey: `${I18N_ACTIVITY_PREFIX}QUIZ` },
    { name: 'survey', iconClass: ACTIVITY_TO_ICON.survey, translateKey: `${I18N_ACTIVITY_PREFIX}SURVEY` },
    { name: 'assignment', iconClass: ACTIVITY_TO_ICON.assignment, translateKey: `${I18N_ACTIVITY_PREFIX}ASSIGNMENT` },
    { name: 'feedback', iconClass: ACTIVITY_TO_ICON.feedback, translateKey: `${I18N_ACTIVITY_PREFIX}FEEDBACK` },
    { name: 'video', iconClass: ACTIVITY_TO_ICON.video, translateKey: `${I18N_ACTIVITY_PREFIX}VIDEO` },
    { name: 'reading', iconClass: ACTIVITY_TO_ICON.reading, translateKey: `${I18N_ACTIVITY_PREFIX}READING` },
    { name: 'discussion', iconClass: ACTIVITY_TO_ICON.discussion, translateKey: `${I18N_ACTIVITY_PREFIX}DISCUSSION` },
    { name: 'audio', iconClass: ACTIVITY_TO_ICON.audio, translateKey: `${I18N_ACTIVITY_PREFIX}AUDIO` }];

  return ExternalToolLectureComponent;
}
