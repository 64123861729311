import { css } from '@emotion/react';
import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { each } from 'underscore';
import { aliases, getTranslateKey } from 'shared/services/i18n-utils';

import { deleteCourseRole, getCourseRole, getCourseRoles } from 'redux/actions/org-level-roles';
import { RootState } from 'redux/schemas';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { CourseRole, PermissionOptions, RolePermission } from 'redux/schemas/models/org-level-roles';
import { CourseRolesNormalized } from 'redux/schemas/api/org-level-roles';
import { getCurrentInstitution } from 'redux/selectors/institutions';

import NvIcon from 'shared/components/nv-icon';
import NvPopover from 'shared/components/nv-popover';
import NvResponsiveTable, { NvTableRowProps } from 'shared/components/nv-responsive-table';
import { gray4, primary } from 'styles/global_defaults/colors';
import { createGridStyles, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { RolesService } from 'institutions/services/roles-service';
import CourseRoleInput from './course-role-input';
import LoadingRow, { ActionsDropdown, tableStyles } from './shared/table-components';

const styles = css`
  .tab-action-panel {
    color: ${primary};
    &.disabled {
      color: ${gray4};
      pointer-events: none;
    }
  }
  .roles-desc {
    max-width: 800px;
  }
  .roles-table {
    /**
     * 280 = top-nav height(60) + tab header height(60)
     *  + tab-action-header height(60) + table margin(30)
     *  + role-desc (40) + role-desc margin (30)
     */
    height: calc(100vh - 280px);

    .nv-responsive-table {
      ${tableStyles}
    }
  }
`;

const rowStyles = css`
  &.roles-row {
    min-height: ${tripleSpacing}px;
    .actions {
      padding-left: ${tripleSpacing}px;
    }
  }
  &.input-row {
    height: ${tripleSpacing}px;
  }
`;

export const permissionInfo = (helpText) => (
  <div>
    <span>
      {t.INSTITUTIONS.ROLES[`${getTranslateKey(helpText)}`](aliases)}
    </span>
    <a
      href='https://help.novoed.com/hc/en-us/articles/360027159751'
      target='_blank'
      rel='noreferrer'
      className='text-primary pl-1'
    >
      {t.INSTITUTIONS.ROLES.VIEW_DETAILS()}
    </a>
  </div>
);

export const PermissionItem = (props: { permission: RolePermission }) => {
  const [showPopover, setShowPopover] = useState(false);

  return (
    <div className='d-flex align-items-center'>
      <span key={props.permission.type} className='mr-1'>
        {props.permission?.name
          ? t.INSTITUTIONS.ROLES[`${getTranslateKey(props.permission.name)}`](aliases)
          : ''}
      </span>
      {props.permission?.helpText && (
        <div
          onMouseEnter={() => setShowPopover(true)}
          onMouseLeave={() => setShowPopover(false)}
        >
          <NvPopover
            content={permissionInfo(props.permission.helpText)}
            placement='top'
            preventOverflow={false}
            className='py-1'
            offset={5}
            show={showPopover}
          >
            <div>
              <NvIcon icon='info' size='xss-smallest' className='color-primary' />
            </div>
          </NvPopover>
        </div>
      )}
    </div>
  );
};

const CourseRoles = () => {
  const institution = useSelector((state) => getCurrentInstitution(state));
  const storedCourseRolesData = (state: RootState) => state.models.roles as CourseRolesNormalized;

  const [showRoleInputRow, setShowAdminInputRow] = useState(false);
  const [editingId, setEditingId] = useState<number>();
  const [addedNewRoleId, setAddedNewRoleId] = useState<[number]>();
  const nameRef = useRef(null);
  const dispatch = useAppDispatch();

  const tableColumns = [
    {
      name: t.INSTITUTIONS.ROLES.COURSE_ROLE({ CourseAlias: 'Course' }),
      className: 'name-cell',
      gridWidth: '30%',
    },
    {
      name: t.INSTITUTIONS.ROLES.TEXT_ON_BADGE(),
      className: 'badge-cell',
      gridWidth: '30%',
      headerClassName: 'pl-2',
    },
    {
      name: t.INSTITUTIONS.ROLES.PERMISSIONS(),
      className: 'permission-cell',
      gridWidth: '30%',
      headerClassName: 'pl-2',
    },
    {
      name: t.INSTITUTIONS.ROLES.ACTIONS(),
      className: 'actions-cell',
      gridWidth: '10%',
      headerClassName: 'justify-content-end pr-0',
    },
  ];

  useEffect(() => {
    if (showRoleInputRow && nameRef?.current) {
      nameRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      nameRef.current.focus({ preventScroll: true });
    }
  }, [showRoleInputRow]);

  const onEdit = (roleId: number) => {
    setEditingId(roleId);
    setShowAdminInputRow(false);
  };

  const onDelete = (role: CourseRole) => {
    dispatch(getCourseRole({
      institutionId: institution.id,
      roleId: role.id,
    })).then(response => {
      if (response.payload) {
        const { isUsed } = (response.payload as CourseRole);
        if (isUsed) {
          let description = '';

          if (RolesService.isMentor(role)) {
            description = t.INSTITUTIONS.ROLES.REMOVE_ROLE_MENTOR(role.name);
          } else {
            description = t.INSTITUTIONS.ROLES.REMOVE_ROLE_WARNING();
          }

          dispatch(openConfirmationDialog({
            title: t.INSTITUTIONS.ROLES.COURSE_ROLE_ASSIGNED(role.name),
            bodyText: description,
            confirmText: t.DASHBOARD.CONFIRMATION.YES_SURE(),
            cancelText: t.FORM.CANCEL(),
            onConfirm: () => dispatch(deleteCourseRole({
              institutionId: institution.id,
              roleId: role.id,
            })),
          }));
        } else {
          dispatch(deleteCourseRole({
            institutionId: institution.id,
            roleId: role.id,
          }));
        }
      }
    });
  };

  const getPermissionOptions = (permissions?: RolePermission[]) => {
    const options: { [key: string]: boolean } = {};
    each(PermissionOptions, option => {
      options[option] = false;
    });
    if (permissions) {
      each(permissions, permission => {
        options[permission.apiName] = true;
      });
    }

    return options;
  };

  const courseRoleRow = (rowProps: NvTableRowProps<CourseRole>) => {
    const { data: role, rowIndex } = rowProps;

    return (
      <div
        className='roles-row d-flex border-bottom border-gray-5 py-2 align-items-center text-regular text-black'
        style={createGridStyles(1, rowIndex, tableColumns.length + 1, rowIndex + 1)}
        css={rowStyles}
      >
        {editingId === role.id ? (
          <CourseRoleInput
            closeForm={() => setEditingId(null)}
            institutionId={institution.id}
            roleId={role.id}
            formData={{
              name: role.name,
              menteeName: role.menteeName,
              badge: role.badge,
              permissions: getPermissionOptions(role.permissions),
            }}
          />
        ) : (
          <React.Fragment>
            <div
              className='font-weight-bolder'
              style={{ width: '30%' }}
            >
              {role.name}
              {role.menteeName && (
                <span className='text-small font-weight-bold text-gray-2 ml-1'>
                  ({role.menteeName})
                </span>
              )}
            </div>
            <div style={{ width: '30%' }} className='pl-2'>
              {role.badge}
            </div>
            <div style={{ width: '30%' }} className='pb-1 pl-2'>
              {role?.permissions?.map(permission => (
                permission && (
                <PermissionItem key={permission.value} permission={permission} />
                )
              ))}
            </div>
            <div
              className='actions text-primary pr-2'
              style={{ width: '10%' }}
            >
              <ActionsDropdown
                editText={t.USER_MANAGEMENT.EDIT_COURSE_ROLE({ ...aliases })}
                onEdit={() => onEdit(role.id)}
                onDelete={() => onDelete(role)}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  const roleInputRow = (rowProps: { rowIndex: number }) => (
    <div
      className='input-row d-flex border-bottom border-gray-5 py-2 align-items-center text-regular text-black'
      style={createGridStyles(1, rowProps.rowIndex, tableColumns.length + 1)}
      css={rowStyles}
    >
      <CourseRoleInput
        closeForm={() => setShowAdminInputRow(false)}
        addedCallback={(id) => setAddedNewRoleId([id])}
        institutionId={institution.id}
        formData={{ permissions: getPermissionOptions() }}
        nameRef={nameRef}
      />
    </div>
  );

  return (
    <div css={styles}>
      <div className='tab-action-header'>
        <div className={`tab-action-panel text-medium ${showRoleInputRow ? 'disabled' : ''}`}>
          <button
            type='button'
            className='d-flex align-items-center border-0 bg-transparent mr-4'
            onClick={() => { setShowAdminInputRow(true); setEditingId(null); }}
          >
            <NvIcon size='smallest' icon='add' className='pr-2 d-inline-block ' />
            {t.INSTITUTIONS.ROLES.NEW_COURSE_ROLE()}
          </button>
        </div>
      </div>
      <div className='roles-desc d-flex mx-auto my-5'>
        {t.INSTITUTIONS.ROLES.MANAGE_COURSE_ROLES_DESC()}
      </div>
      <div className='roles-table mt-5'>
        <NvResponsiveTable<CourseRole>
          columns={tableColumns}
          fetchData={getCourseRoles}
          fetchParams={{ institutionId: institution.id }}
          pagedFetchParams={{}}
          rowComponent={courseRoleRow as FunctionComponent}
          rowProps={{}}
          loadingComponent={(rawProps) => LoadingRow(rawProps, tableColumns.length + 1)}
          dataKey='id'
          cacheDataKey='id'
          cacheLookup={storedCourseRolesData}
          hideClearSearch
          clearSearch={() => {}}
          style={createGridStyles(1, 3)}
          noResultsInTable
          noResultsIcon=''
          noResultsText={t.INSTITUTIONS.ROLES.NO_COURSE_ADDED_YET()}
          extraRowComponent={showRoleInputRow ? roleInputRow : null}
          hoverDisabled
          extraDataKeys={addedNewRoleId}
        />
      </div>
    </div>
  );
};

export default CourseRoles;
