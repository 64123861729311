import { css } from '@emotion/react';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  halfSpacing, standardSpacing,
} from 'styles/global_defaults/scaffolding';
import {
  NvDropdown, NvDropdownTextItem, NvDropdownButtonStyle, NvDropdownAlign,
} from 'shared/components/inputs/nv-dropdown';
import moment from 'moment';
import { RootState } from 'redux/schemas';
import {
  getPeriodStartDate, getPeriodEndDate, getCurrentOrgCreationDate, noContractPeriodDefined, LicenseInformation, getLicenseInformation,
} from 'redux/selectors/licenses';
import {
  getCoursesLogins,
  getMonthlyEnrollments,
  getEnrollmentsForPeriod,
} from 'redux/actions/license';
import {
  EnrollmentForPeriod,
  EnrollmentPeriod,
  ExportTypes,
} from 'redux/schemas/models/license';
import { MonthlyEnrollmentsResponse } from 'redux/schemas/api/license';
import t from 'react-translate';
import NvDatepicker, { DatePickerType } from 'shared/components/inputs/nv-datepicker';
import { AngularServicesContext } from 'react-app';

import { useForm, FormProvider } from 'react-hook-form';
import NvRadioButton from 'shared/components/inputs/nv-radio-button';
import ExportButton from './export-button';

type StateProps = {
  licenseDataLoaded: boolean,
  // Whether or not a license period exists at all
  licensePeriodDefined: boolean,
  periodStartDate: moment.Moment,
  periodEndDate: moment.Moment,
  currentInstitutionId: number,
  orgCreationDate: moment.Moment,
  licensesUsed: number,
  // TODO: This total login and monthly enrollment info is specific to the currently selected date period.
  // We should probably record that date period in the redux state instead of keeping it local
  totalLogins: number,
  monthlyEnrollments: MonthlyEnrollmentsResponse,
  monthlyEnrollmentsLoaded: boolean,
  // Flat values for a single period instead of multiple as used in bar charts
  flatEnrollmentValues: EnrollmentForPeriod,
  flatEnrollmentValuesLoaded: boolean,
};

type OwnProps = {
  /** Callback that updates the current date period the the selection made in this panel */
  setPeriod: (startDate: moment.Moment, endDate: moment.Moment, periodType: EnrollmentPeriod, text: string) => void,
};

type DispatchProps = {
  getCoursesLogins,
  getMonthlyEnrollments,
  getEnrollmentsForPeriod,
};

interface PeriodDropdownItem extends NvDropdownTextItem {
  periodType: EnrollmentPeriod,
}

export const textForPeriod = (period: EnrollmentPeriod, date?: moment.Moment): string => {
  switch (period) {
    case EnrollmentPeriod.CURRENT_CONTRACT_PERIOD: return t.INSTITUTIONS.LICENSES.DASHBOARD.CURRENT_CONTRACT_PERIOD();
    case EnrollmentPeriod.SINCE_ORG_CREATION: return date ? t.INSTITUTIONS.LICENSES.DASHBOARD.ALL_DATA_SINCE(date.format('MM/DD/YYYY')) : '';
    case EnrollmentPeriod.PAST_12_MONTHS: return t.INSTITUTIONS.LICENSES.DASHBOARD.FROM_PAST_MONTHS(12);
    case EnrollmentPeriod.PAST_6_MONTHS: return t.INSTITUTIONS.LICENSES.DASHBOARD.FROM_PAST_MONTHS(6);
    case EnrollmentPeriod.PAST_30_DAYS: return t.INSTITUTIONS.LICENSES.DASHBOARD.FROM_PAST_DAYS(30);
    case EnrollmentPeriod.CUSTOM_DATE_RANGE: return t.INSTITUTIONS.LICENSES.DASHBOARD.WITHIN_A_DATE_RANGE();
    default: return '';
  }
};

// See https://novoed.atlassian.net/browse/NOV-61727. Range should be from beginning of the month 1 year ago, to
// the end of the current month
const adjustedDatesForMonthRange = (originalPeriodType: EnrollmentPeriod): [moment.Moment, moment.Moment] => {
  switch (originalPeriodType) {
    case EnrollmentPeriod.PAST_12_MONTHS: {
      const startDate = moment().subtract(11, 'months').startOf('month');
      const endDate = adjustDateToUTC(moment().endOf('month'));
      return [startDate, endDate];
    }
    case EnrollmentPeriod.PAST_6_MONTHS: {
      const startDate = moment().subtract(5, 'months').startOf('month');
      const endDate = adjustDateToUTC(moment().endOf('month'));
      return [startDate, endDate];
    }
    default:
      return [null, null];
  }
};

/**
 * NOTE: This is currently disabled to make no adjustment for UTC. The below behavior was initially desired
 * but the team has agreed to not normalize values on the frontend and listen for customer feedback before
 * implementing.
 *
 * Please remove in the future (after 01/23/2020)
 *
 * This adjusts a moment object to subtract out it's UTC offset. Generally, the frontend handles dates in the user's local
 * timezone and sends request data as UTC, and the backend only does calculations based on UTC. However, the backend needs to
 * aggregate data based on the user's month in local time.
 * This is a hack and likely needs adjusted after we devise a strategy for how to bin these months while respecting the local timezone
 * https://novoed.atlassian.net/browse/NOV-61862
 */
const adjustDateToUTC = (date: moment.Moment): moment.Moment => date; // date.add(date.utcOffset(), 'minutes');

export const defaultPeriod = adjustedDatesForMonthRange(EnrollmentPeriod.PAST_6_MONTHS);
export const defaultPeriodType = EnrollmentPeriod.PAST_6_MONTHS;

/**
 * The 'Enrollment' section of the License Dashboard page.
 * Features a dropdown for selecting date ranges, a 'total vs logged in' radial chart, and a
 * 'total vs new' chart. 'Total vs new' is a radial chart when the date range is 1 month or less,
 * and a bar chart otherwise
 */
const EnrollmentPanel = (props: StateProps & DispatchProps & OwnProps) => {
  const { $uibModal } = useContext(AngularServicesContext);
  const style = css`

    .nv-dropdown-container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width:292px;
      margin: ${halfSpacing}px auto 0 auto;
    }

    .options-container{
      width: 350px;
      margin:${standardSpacing}px auto ${standardSpacing}px auto;
      .options{
        display: flex:
        flex-direction: columns;
      }
    }

    .nv-dropdown, .date-selection-row {
      margin-top: ${halfSpacing}px;
    }

    .date-selection-row {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-bottom: ${standardSpacing}px;

      .react-datepicker-wrapper {
        width: 100px;
      }
    }
  `;
  const licensedata: LicenseInformation = useSelector(getLicenseInformation);
  // The default start and end dates for the period. Defaults to the past 12 months if
  // undefined
  const [pStart, pEnd] = defaultPeriod;

  // State that tracks the current period start and end date selection
  const [periodStart, setPeriodStart] = useState(pStart);
  const [periodEnd, setPeriodEnd] = useState(pEnd);
  // Drafts for the start and end dates used by the custom date range selection option
  const [periodStartDraft, setPeriodStartDraft] = useState(props.periodStartDate);
  const [periodEndDraft, setPeriodEndDraft] = useState(props.periodEndDate);

  // Which type of period is selected in the dropdown
  const [periodType, setPeriodType] = useState(defaultPeriodType);

  // Shows/hides the date range selection UI
  const [dateSelectionVisible, setDateSelectionVisible] = useState(false);
  const [startDate, setStartDate] = useState(defaultPeriod[0]);
  const [endDate, setEndDate] = useState(defaultPeriod[1]);
  const [periodText, setPeriodText] = useState(t.INSTITUTIONS.LICENSES.DASHBOARD.CURRENT_CONTRACT_PERIOD());
  const [exportType, setExportType] = useState(ExportTypes.NONE);
  // A callback for updating the period used in the EnrollmentPanel since that's where
  // the current date period is updated
  const setPeriod = (newPeriodStart: moment.Moment, newPeriodEnd: moment.Moment, newPeriodType: EnrollmentPeriod, text: string) => {
    setStartDate(newPeriodStart);
    setEndDate(newPeriodEnd);
    setPeriodText(text);
    setPeriodType(newPeriodType);
  };
  // Keeps drafts and non-drafts in sync
  const updatePeriod = (startDate: moment.Moment, endDate: moment.Moment, enrollmentPeriod: EnrollmentPeriod) => {
    const newStartDate = startDate.startOf('day');
    const newEndDate = adjustDateToUTC(endDate.endOf('day'));

    setPeriodStartDraft(newStartDate);
    setPeriodEndDraft(newEndDate);
    setPeriodStart(newStartDate);
    setPeriodEnd(newEndDate);
    setPeriodType(enrollmentPeriod);

    const textDate = enrollmentPeriod === EnrollmentPeriod.SINCE_ORG_CREATION ? props.orgCreationDate : null;
    setPeriod(newStartDate, newEndDate, enrollmentPeriod, textForPeriod(enrollmentPeriod, textDate));
  };

  // Date range dropdown text & callbacks
  const createDropdownItems = (startDraft: moment.Moment, endDraft: moment.Moment): PeriodDropdownItem[] => [
    {
      type: 'text',
      text: textForPeriod(EnrollmentPeriod.CURRENT_CONTRACT_PERIOD),
      periodType: EnrollmentPeriod.CURRENT_CONTRACT_PERIOD,
      callback: (i) => {
        setDateSelectionVisible(false);
        selectPeriod(licensedata.currentPeriodStartedAt, licensedata.currentPeriodEndedAt, i);
      },
    },
    {
      type: 'text',
      text: textForPeriod(EnrollmentPeriod.PAST_12_MONTHS),
      periodType: EnrollmentPeriod.PAST_12_MONTHS,
      callback: (i) => {
        const [startDate, endDate] = adjustedDatesForMonthRange(EnrollmentPeriod.PAST_12_MONTHS);
        setDateSelectionVisible(false);
        selectPeriod(startDate, endDate, i);
      },
    },
    {
      type: 'text',
      text: textForPeriod(EnrollmentPeriod.PAST_6_MONTHS),
      periodType: EnrollmentPeriod.PAST_6_MONTHS,
      callback: (i) => {
        const [startDate, endDate] = adjustedDatesForMonthRange(EnrollmentPeriod.PAST_6_MONTHS);
        setDateSelectionVisible(false);
        selectPeriod(startDate, endDate, i);
      },
    },
    {
      type: 'text',
      text: textForPeriod(EnrollmentPeriod.PAST_30_DAYS),
      periodType: EnrollmentPeriod.PAST_30_DAYS,
      callback: (i) => {
        setDateSelectionVisible(false);
        selectPeriod(moment().subtract(30, 'days'), moment(), i);
      },
    },
    {
      type: 'text',
      text: textForPeriod(EnrollmentPeriod.SINCE_ORG_CREATION, props.orgCreationDate),
      periodType: EnrollmentPeriod.SINCE_ORG_CREATION,
      callback: (i) => { setDateSelectionVisible(false); selectPeriod(props.orgCreationDate, moment(), i); },
    },
    {
      type: 'text',
      text: textForPeriod(EnrollmentPeriod.CUSTOM_DATE_RANGE),
      periodType: EnrollmentPeriod.CUSTOM_DATE_RANGE,
      callback: (i) => {
        setDateSelectionVisible(true);
        selectPeriod(startDraft, endDraft, i);
      },
    },
  ];

  let dropdownData = createDropdownItems(periodStartDraft, periodEndDraft);

  // Remove the 'Current Contract Period' item when no license period exists since it's
  // nonsensical in that case

  if (!licensedata.currentPeriodStartedAt) {
    dropdownData = dropdownData.slice(1);
  }

  const defaultDropdownIndex = dropdownData.findIndex(d => d.periodType === defaultPeriodType);

  // Called whenever the date period is changed
  const selectPeriod = (startDate: moment.Moment, endDate: moment.Moment, dropdownItem: PeriodDropdownItem) => {
    updatePeriod(startDate, endDate, dropdownItem.periodType);
  };
  // as we refactored this section, added this useEffect to update the dates when using custom range.
  useEffect(() => {
    if (periodType === EnrollmentPeriod.CUSTOM_DATE_RANGE) {
      updateWithSelectedDates();
    }
  }, [periodStartDraft, periodEndDraft]);

  useEffect(() => {
    updatePeriod(periodStart, periodEnd, periodType);
  }, [periodStart.format(), periodEnd.format(), props.licenseDataLoaded]);

  // Sets the default period and type upon page load after data license data finishes loading
  useEffect(() => {
    if (!props.licenseDataLoaded) {
      return;
    }

    updatePeriod(defaultPeriod[0], defaultPeriod[1], defaultPeriodType);
  }, [props.licenseDataLoaded]);

  const methods = useForm({ mode: 'onChange', shouldUnregister: true });
  const updateWithSelectedDates = () => selectPeriod(periodStartDraft, periodEndDraft,
    {
      type: 'text',
      text: `From ${periodStartDraft.format('MM/DD/YYYY')} to ${periodEndDraft.format('MM/DD/YYYY')}`,
      periodType: EnrollmentPeriod.CUSTOM_DATE_RANGE,
    });
  return (
    <div css={style} className='mx-auto'>
      <div className='options-container'>
        <div className='options'>
          <span> {t.INSTITUTIONS.LICENSES.DASHBOARD.PLEASE_SELECT()}</span>
          <NvRadioButton
            value='user-data'
            name='userdata'
            className='mt-4'
            label={t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.USER_DATA.NAME()}
            checked={exportType === ExportTypes.ORGANIZATION_USERS}
            labelClassName=''
            onChange={() => setExportType(ExportTypes.ORGANIZATION_USERS)}
          />
          <NvRadioButton
            value='enrollment-data'
            name='enrollmentdata'
            className='mt-4'
            label={t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.ENROLLMENT_DATA.NAME()}
            labelClassName=''
            checked={exportType === ExportTypes.ORGANIZATION_ENROLLMENT}
            onChange={() => setExportType(ExportTypes.ORGANIZATION_ENROLLMENT)}
          />

          <div className='mt-4'>
            <NvDropdown
              buttonStyle={NvDropdownButtonStyle.FORM_SMALL}
              titleClass='mr-6'
              items={dropdownData}
              align={NvDropdownAlign.CENTER}
              initialIndex={defaultDropdownIndex}
              showSelectedIndicator
              minWidth={250}
            />
          </div>

          { dateSelectionVisible && (
          <FormProvider {...methods}>
            <div className='date-selection-row'>
              <NvDatepicker
                name='startDate'
                type={DatePickerType.DATE}
                value={moment(periodStartDraft)}
                onChange={(val) => { setPeriodStartDraft(moment(val)); }}
                max={moment(periodEndDraft).subtract(1, 'day')}
                placement='top-start'
              />
              <span className='font-weight-bold condensed px-2'>TO</span>
              <NvDatepicker
                name='startDate'
                type={DatePickerType.DATE}
                value={moment(periodEndDraft)}
                onChange={(val) => { setPeriodEndDraft(moment(val)); }}
                min={moment(periodStartDraft).add(1, 'day')}
                placement='top-start'
              />

            </div>
          </FormProvider>
          )}
        </div>
        <ExportButton
          exportType={exportType}
          periodType={periodType}
          periodStart={periodStart}
          periodEnd={periodEnd}
        />
      </div>
    </div>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state, ownProps) => ({
    licenseDataLoaded: state.models.license.loaded,
    licensePeriodDefined: !noContractPeriodDefined(state),
    periodStartDate: getPeriodStartDate(state),
    periodEndDate: getPeriodEndDate(state),
    currentInstitutionId: state.app.currentInstitutionId,
    licensesUsed: state.models.totalEnrollments,
    orgCreationDate: getCurrentOrgCreationDate(state) ?? moment(),
    ...ownProps,
  }),
  {
    getCoursesLogins,
    getMonthlyEnrollments,
    getEnrollmentsForPeriod,
  },
)(EnrollmentPanel);

