/* @ngInject */
export default function StatementsResources(

  $resource,
) {
  return $resource(
    '/:catalogId/teams/:id.json',
    {},
    {
      getStatementForUser: { method: 'GET', url: '/:catalogId/statement_template.json?user_id=:userId&download=true' },
      makePublic: { method: 'GET', url: '/:catalogId/statement_template/share' },
    },
  );
}
