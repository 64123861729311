/* eslint-disable import/prefer-default-export */
import { RootState } from 'redux/schemas';
import { getCurrentCourse } from 'redux/selectors/course';

export const getOrgProfileField = (
  state: RootState,
  orgProfileFieldId: number,
) => state.models.orgProfileFields[orgProfileFieldId.toString()];

export const getCourseProfileQuestion = (
  state: RootState,
  profileQuestionId: number,
) => state.models.courseProfileQuestions[profileQuestionId.toString()];

export const getCourseProfileQuestions = (
  state: RootState,
) => {
  const currentCourse = getCurrentCourse(state);

  return currentCourse.courseProfileQuestions ?? {};
};
