import { css } from '@emotion/react';
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { isEmpty, reduce } from 'underscore';
import t from 'react-translate';
import { useSelector } from 'react-redux';

// Actions
import { useAppDispatch } from 'redux/store';
import { getMenteesForMentor, getMentorDashboardSummary } from 'redux/actions/org-mentors';

// Schemas
import { RootState } from 'redux/schemas';
import { MyAccount } from 'redux/schemas/models/my-account';

// Selectors
import { getRoleAliases } from 'redux/selectors/institutions';
import { getCurrentUser } from 'redux/selectors/users';
import { getMentorDashboard } from 'redux/selectors/org-mentors';

// Styles
import {
  halfSpacing,
  standardSpacing,
  threeQuartersSpacing,
  largeSpacing,
  doubleSpacing,
  tripleSpacing,
  extraLargeSpacing,
} from 'styles/global_defaults/scaffolding';
import { handheld, notDesktop } from 'styles/global_defaults/media-queries';

// Components
import EmptyList from 'shared/components/empty-list';
import { MentorDashboard as DashboardModal, FilterType, FilterKeys } from 'redux/schemas/models/org-mentors';
import LoadingPlaceholder from 'communications/course_communications/components/loading-placeholder';
import NvLoadingPlaceholder,
{ AvatarSize, LineSize, PlaceholderType, Shape } from 'shared/components/nv-loading-placeholder';
import ClickableContainer from 'components/clickable-container';

// Hooks
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';

import DashboardFilterBox from './dashboard-filter-box';
import MenteeRow from './mentor-dashboard/mentee-row';
import ConnectedLiveSessionLectureComponent from 'timelines/components/lecture_components/live-session';
/**
 * Mentor Dashboard
 */

type MentorDashboardProps = {
  // The user data from our Angular.js app's CurrentUserManager.user
  currentUserData: any,
};

const styles = (moreThanOneTab: boolean) => css`
  background: linear-gradient(315.42deg, rgba(220, 253, 253, 0.3) 0%, rgba(247, 251, 232, 0.3) 99.28%), #FFFFFF;

  .filter-box-container {
    max-height: 210px;
    position: absolute;
    background: inherit;
    transition: all 0.5s;
    z-index: 1;

    ${handheld(css`
      overflow-x: auto;
      justify-content: flex-start;
    `)};

    .filter-box-wrapper {
      margin: ${largeSpacing}px ${threeQuartersSpacing}px ${doubleSpacing}px;
      transition: all 0.5s;
      cursor: pointer;
    }

    &.compact {
      opacity: 0.95;
      max-height: 110px;
      transition: all 0.5s;

      .filter-box-wrapper {
        margin:${standardSpacing}px ${threeQuartersSpacing}px ${standardSpacing}px;
        transition: all 0.5s;
      }
    }
  }

  .tab-content {
    /* Tab height is view port height - institution header height - tab header height */
    height: calc(100vh - 60px - 82px);
    padding-top: ${moreThanOneTab ? 210 : doubleSpacing}px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .empty-list-wrapper {
    padding-top: ${tripleSpacing}px;
  }

  .loading-state {
      max-width: 800px;
      padding: ${doubleSpacing}px 0;

    .loading:first-of-type {
      margin-top: 0;
    }
  }


  .mentee-table {
    margin: 0 auto;
    max-width: 1000px;

    .table-row, .table-header {
      display: -ms-grid;
      display: grid;

      -ms-grid-columns: 30% 30% 15% calc(25% - ${standardSpacing}px) ${standardSpacing}px;
      grid-template-columns: 30% 30% 15% calc(25% - ${standardSpacing}px) ${standardSpacing}px;

      .name-cell, .enrollment-cell, .last-active-cell, .notes-cell, .more-cell {
        display: flex;
        align-items: center;
      }

      .name-cell {
        -ms-grid-column: 1;
        grid-column: 1;

        .name-text {
          /* Name text width is 100% - (avatar size(40px) + spacing(20px)) */
          width: calc(100% - 60px);
        }
        padding-right: ${standardSpacing}px;
      }

      .enrollment-cell {
        -ms-grid-column: 2;
        grid-column: 2;
      }

      .last-active-cell {
        -ms-grid-column: 3;
        grid-column: 3;
        padding-left: ${standardSpacing}px;
      }

      .notes-cell {
        -ms-grid-column: 4;
        grid-column: 4;
        padding-left: ${halfSpacing}px;
        padding-right: ${standardSpacing}px;
      }

      .more-cell {
        -ms-grid-column: 5;
        grid-column: 5;
      }

      ${handheld(css`
        &.table-header {
          display: none;
        }

        -ms-grid-columns: calc(100% - ${standardSpacing}px) ${standardSpacing}px;
        grid-template-columns: calc(100% - ${standardSpacing}px) ${standardSpacing}px;

        .name-cell {
          -ms-grid-column: 1;
          grid-column: 1;
        }

        .enrollment-cell {
          -ms-grid-column: 1;
          grid-column: 1;
          -ms-grid-row: 2;
          grid-row: 2;
          padding-left: ${extraLargeSpacing}px;
        }

        .last-active-cell {
          -ms-grid-column: 1;
          grid-column: 1;
          -ms-grid-row: 3;
          grid-row: 3;
          padding-left: ${extraLargeSpacing}px;
          padding-top: ${halfSpacing}px;
          justify-content: start;
        }

        .notes-cell {
          -ms-grid-column: 1;
          grid-column: 1;
          -ms-grid-row: 4;
          grid-row: 4;
          padding-left: ${extraLargeSpacing}px;
          padding-top: ${halfSpacing}px;
        }

        .more-cell {
          -ms-grid-column: 2;
          grid-column: 2;
          -ms-grid-row: 1;
          grid-row: 1;
        }
      `)};
    }

    ${notDesktop(css`
      padding: 0 ${standardSpacing}px;
    `)};
  }
`;

const MenteeLoadingPlaceholder = () => (
  <div className='table-row py-2'>
    <div className='name-cell'>
      <div className='mb-2'>
        <NvLoadingPlaceholder
          type={PlaceholderType.AVATAR}
          size={AvatarSize.MEDIUM}
          shape={Shape.ROUND}
        />
      </div>
      <div className='name-text px-2'>
        <NvLoadingPlaceholder type={PlaceholderType.LINE} size={LineSize.FULL} />
      </div>
    </div>
    <div className='enrollment-cell'>
      <NvLoadingPlaceholder type={PlaceholderType.LINE} size={LineSize.FULL} />
    </div>
    <div className='last-active-cell'>
      <NvLoadingPlaceholder type={PlaceholderType.LINE} size={LineSize.SHORT} />
    </div>
    <div className='notes-cell'>
      <NvLoadingPlaceholder type={PlaceholderType.LINE} size={LineSize.SHORT} />
    </div>
  </div>
);

const MenteesLoadingWrapper = (props: {
  children: any,
  isLoaded: boolean,
  isMoreLoading?: boolean
}) => (
  <React.Fragment>
    { !props.isLoaded
      ? <MenteeLoadingPlaceholder />
      : (
        <React.Fragment>
          { props.children }
          {/* After the page once loaded
            * loading placeholder displayed
            * in the bottom on each page loading
            */}
          {props.isMoreLoading
          && <MenteeLoadingPlaceholder />}
        </React.Fragment>
      )}
  </React.Fragment>
);

const MentorDashboard = ({ currentUserData }: MentorDashboardProps) => {
  const [filter, setFilter] = useState(FilterKeys.TOTAL_MENTEES);
  const dispatch = useAppDispatch();
  const currentInstitutionId = useSelector((state) => state.app.currentInstitutionId);
  const roleAliases = useSelector(getRoleAliases);
  const currentUser = useSelector<RootState, MyAccount>(getCurrentUser);
  const {
    isMenteesLoading, isSummaryLoading, mentees: menteeIds,
    filterCounts, nextPage, onceLoaded, totalMenteesCount,
  } = useSelector<RootState, DashboardModal>((state) => getMentorDashboard(state));

  const [pageNo, setPageNo] = useState(nextPage);
  const totalFilterCount = reduce(filterCounts, (total, count) => total + count, 0);
  const moreThanOneTab = Object.keys(filterCounts).filter(item => filterCounts[item] > 0).length > 1;

  const filterBoxDetails = [
    { filterType: FilterType.TOTAL_MENTEES,
      count: filterCounts?.[FilterType.TOTAL_MENTEES],
      key: FilterKeys.TOTAL_MENTEES,
    },
    { filterType: FilterType.NEVER_LOGGED_IN,
      count: filterCounts?.[FilterType.NEVER_LOGGED_IN],
      key: FilterKeys.NEVER_LOGGED_IN,
    },
    { filterType: FilterType.MISSED_DEADLINE,
      count: filterCounts?.[FilterType.MISSED_DEADLINE],
      key: FilterKeys.MISSED_DEADLINE,
    },
    { filterType: FilterType.NOT_ACTIVE,
      count: filterCounts?.[FilterType.NOT_ACTIVE],
      key: FilterKeys.NOT_ACTIVE,
    },
    { filterType: FilterType.RECENTLY_COMPLETED,
      count: filterCounts?.[FilterType.RECENTLY_COMPLETED],
      key: FilterKeys.RECENTLY_COMPLETED,
    },
  ];

  const parentRef = useRef<HTMLInputElement>(null);
  const [isCompactMode, setCompactMode] = useState<boolean>(false);

  const scrollPadding = 20;
  const isScrolledFromHeader = useInfiniteScroll(
    parentRef?.current,
    scrollPadding,
    false,
  );

  useEffect(() => {
    if (parentRef?.current) {
      setCompactMode(isScrolledFromHeader);
    }
  }, [parentRef, isScrolledFromHeader, setCompactMode]);

  const getMentees = useCallback(() => {
    if (currentUser?.id && currentInstitutionId) {
      dispatch(getMenteesForMentor({
        mentorUserId: currentUser.id,
        institutionId: currentInstitutionId,
        filter,
        pageNumber: pageNo,
      }));
    }
  }, [currentInstitutionId, currentUser?.id, dispatch, filter, pageNo]);

  const getDashboardSummary = useCallback(() => {
    if (currentUser?.id && currentInstitutionId) {
      dispatch(getMentorDashboardSummary({
        mentorUserId: currentUser.id,
        institutionId: currentInstitutionId,
      }));
    }
  }, [currentInstitutionId, currentUser?.id, dispatch]);

  const changeFilter = (filterKey: FilterKeys) => {
    if (!isMenteesLoading) {
      setFilter(filterKey);
      setPageNo(1);
    }
  };

  const isPageBottom = useInfiniteScroll(parentRef?.current, scrollPadding);

  useEffect(() => {
    if (isPageBottom
      && nextPage
      && !isMenteesLoading
    ) {
      setPageNo(nextPage);
    }
  }, [isPageBottom, nextPage, isMenteesLoading]);

  useEffect(() => {
    if (!isSummaryLoading && totalFilterCount > 0) {
      getMentees();
    }
  }, [getMentees, pageNo, isSummaryLoading, totalFilterCount]);

  useEffect(() => {
    getDashboardSummary();
  }, [getDashboardSummary]);

  return (
    <div css={styles(moreThanOneTab)}>
      {isSummaryLoading ? (
        <div className='loading-state mx-auto'>
          {/* TODO-OLM: Move this component to Shared */}
          <LoadingPlaceholder />
        </div>
      ) : (
        <React.Fragment>
          {totalFilterCount === 0 && !isSummaryLoading ? (
            <div className='empty-list-wrapper bg-white'>
              <EmptyList
                icon='g-mentors'
                description={t.INSTITUTIONS.ORG_MENTORS.EMPTY_MENTOR_DASHBOARD({ MenteesAlias: roleAliases?.mentee.capitalizedPluralized })}
              />
            </div>
          ) : (
            <React.Fragment>
              <div
                className={`d-flex w-100 justify-content-md-center filter-box-container ${isCompactMode ? 'compact' : ''}`}
              >
                {moreThanOneTab && filterBoxDetails.map(filterBox => (
                  filterBox.count > 0 && (
                    <ClickableContainer
                      className='filter-box-wrapper'
                      key={filterBox.filterType}
                      onClick={() => changeFilter(filterBox.key)}
                    >
                      <DashboardFilterBox
                        filterType={filterBox.filterType}
                        count={filterBox.count}
                        isSelected={filterBox.key === filter}
                        scrolledUp={isCompactMode}
                      />
                    </ClickableContainer>
                  )
                ))}
              </div>
              <div
                className={`tab-content ${isCompactMode ? 'compact' : ''}`}
                ref={parentRef}
              >
                <div className='mentee-table pb-5'>
                  <div className='text-small text-gray-2 border-bottom border-gray-5 pb-2 table-header'>
                    <div className='name-cell'>{t.COURSES.DASHBOARD_TABLE.OFFERING_NAME()}</div>
                    <div className='enrollment-cell'>{t.INSTITUTIONS.LICENSES.DASHBOARD.ENROLLMENT()}</div>
                    <div className='last-active-cell'>{t.INSTITUTIONS.ROLES.LAST_ACTIVE()}</div>
                    <div className='notes-cell'>{t.MENTOR_DASHBOARD.TABLE_HEADER_NOTES()}</div>
                  </div>
                  <MenteesLoadingWrapper isLoaded={onceLoaded} isMoreLoading={isMenteesLoading && pageNo > 1}>
                    {menteeIds.map(menteeId => (
                      <MenteeRow
                        key={menteeId.toString()}
                        menteeId={menteeId}
                      />
                    ))}
                  </MenteesLoadingWrapper>
                  {menteeIds.length === totalMenteesCount
                  && (
                    <div className='text-gray-2 pt-2'>
                      {t.INSTITUTIONS.ORG_MENTORS.ALL_LOADED()}
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default MentorDashboard;
