/* @ngInject */
export default function NvModalPagePagination() {
  return {
    restrict: 'A',
    scope: {
      prevLinkUrl: '=',
      nextLinkUrl: '=',
      pendoTagNext: '@?',
      pendoTagPrev: '@?',
    },
    templateUrl: 'layouts/templates/nv-modal-page-pagination.html',
  };
}
