import { css } from '@emotion/react';
import AppStylesProvider from 'components/bs4-theme-provider';
import { render, unmountComponentAtNode } from 'react-dom';
import { Provider } from 'react-redux';
import store from 'redux/store';
import NvConfirmationOverlay from 'shared/components/nv-confirmation-overlay';

import RecordingOverlay from 'recording/components/recording-overlay';
import { hasGetUserMedia, hasMediaRecorder, requestMediaStream, MediaSourceType } from 'recording/services/media-stream';

/* @ngInject */
export default function nvFileUpload(
  _,
  config,
  $translate,
  AlertMessages,
  ConfirmationOverlays,
) {
  return {
    // pass in ngModel when using video/audio recorder
    require: '?ngModel',
    scope: {
      type: '@',
      // pass in file when using file picker
      file: '=',
      name: '@',
      form: '@',
      onChange: '&',
      onError: '&',
      maxSize: '@?',
      hideMaxSize: '=?',
      customText: '@?',
      showInfoText: '=?',
      uploadClasses: '=?',
      restrictFileTypes: '=?',
      ngDisabled: '=?',
      includeRecording: '=?',
    },
    link(scope, element, attr, ngModel) {
      scope._ = _;
      scope.filePatterns = null;
      scope.hasGetUserMedia = hasGetUserMedia();
      scope.hasMediaRecorder = hasMediaRecorder();

      scope.allUploadableExtensions = config.files.allUploadableExtensions;

      let mediaStream;

      scope.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      scope.validMaxSize = function () {
        return scope.maxSize && parseInt(scope.maxSize, 10);
      };

      scope.onChangeFile = function ($file, $invalidFiles) {
        let valid = true;
        if ($invalidFiles?.length) {
          if (scope.onError) scope.onError();
          if ($invalidFiles[0].$error === 'maxSize') {
            AlertMessages.error('FORM.OOPS', 'FILE_UPLOAD.SIZE_EXCEEDED_ALERT', {}, { sizeLimit: $invalidFiles[0].$errorParam });
          } else {
            AlertMessages.error('FORM.OOPS', 'FILE_UPLOAD.NOT_SUPPORTED_ALERT');
          }
        } else if ($file) {
          $file.$maxSize = scope.maxSize;

          if ($file.name.includes('.')) {
            const extension = _.last($file.name.split('.')).toLowerCase();
            if (_.indexOf(scope.allUploadableExtensions, `.${extension}`) === -1
              || (scope.restrictFileTypes && scope.filePatterns && _.indexOf(scope.filePatterns.split(','), config.files.extensionToMimeType[`.${extension}`]) === -1)) {
              setInvalidExtension();
            }
          } else {
            setInvalidExtension();
          }

          if (valid) {
            scope?.onChange({ $file });
          } else if (scope.onError) scope.onError();
        }

        function setInvalidExtension() {
          valid = false;
          $file.$error = 'invalidExtension';
          $file.$errorParam = true;
          $file.$validFileTypesKey = scope.recommendedFileTypesKey;
        }
      };

      initialize();

      function initialize() {
        if (scope.type === 'document') {
          scope.uploadTextTranslateKey = 'FILE_UPLOAD.UPLOAD_DOCUMENT';
          scope.recommendedFileTypesKey = '';
          if (scope.restrictFileTypes) {
            scope.filePatterns = config.files.documents;
          }
        } else if (scope.type === 'presentation') {
          scope.uploadTextTranslateKey = 'FILE_UPLOAD.UPLOAD_PRESENTATION';
          scope.recommendedFileTypesKey = 'FILE_UPLOAD.UPLOAD_PRESENTATION_FILE_TYPES';
          if (scope.restrictFileTypes) {
            scope.filePatterns = config.files.presentations;
          }
        } else if (scope.type === 'image') {
          scope.uploadTextTranslateKey = 'FILE_UPLOAD.UPLOAD_IMAGE';
          scope.recommendedFileTypesKey = 'FILE_UPLOAD.UPLOAD_IMAGE_FILE_TYPES';
          if (scope.restrictFileTypes) {
            scope.filePatterns = config.files.images;
          }
        } else if (scope.type === 'cover_photo') {
          scope.uploadTextTranslateKey = 'COURSES.FORM.HELP_TEXT.COVER_PHOTO';
          scope.recommendedFileTypesKey = 'FILE_UPLOAD.UPLOAD_COVER_PHOTO_FILE_TYPES';
          if (scope.restrictFileTypes) {
            scope.filePatterns = config.files.images;
          }
        } else if (scope.type === 'spreadsheet') {
          scope.uploadTextTranslateKey = 'FILE_UPLOAD.UPLOAD_SPREADSHEET';
          scope.recommendedFileTypesKey = 'FILE_UPLOAD.UPLOAD_SPREADSHEET_FILE_TYPES';
          if (scope.restrictFileTypes) {
            scope.filePatterns = config.files.excels;
          }
        } else if (scope.type === 'video') {
          scope.uploadTextTranslateKey = 'FILE_UPLOAD.UPLOAD_VIDEO';
          scope.recommendedFileTypesKey = 'FILE_UPLOAD.UPLOAD_VIDEO_FILE_TYPES';
          if (scope.restrictFileTypes) {
            scope.filePatterns = config.files.videos;
          }
        } else if (scope.type === 'audio') {
          scope.uploadTextTranslateKey = 'FILE_UPLOAD.UPLOAD_AUDIO';
          scope.recommendedFileTypesKey = 'FILE_UPLOAD.UPLOAD_AUDIO_FILE_TYPES';
          if (scope.restrictFileTypes) {
            scope.filePatterns = config.files.audios;
          }
        } else if (scope.type === 'subtitles') {
          scope.uploadTextTranslateKey = 'FILE_UPLOAD.UPLOAD_SUBTITLES';
          scope.recommendedFileTypesKey = 'FILE_UPLOAD.UPLOAD_SUBTITLES_FILE_TYPES';
          if (scope.restrictFileTypes) {
            scope.filePatterns = config.files.subtitles;
          }
        } else if (scope.type === 'favicon') {
          scope.uploadTextTranslateKey = 'FILE_UPLOAD.UPLOAD_FAVICON';
          scope.recommendedFileTypesKey = 'FILE_UPLOAD.UPLOAD_FAVICON_FILE_TYPES';
          if (scope.restrictFileTypes) {
            scope.filePatterns = config.files.favicons;
          }
        } else if (scope.type === 'multimedia') {
          scope.uploadTextTranslateKey = 'FILE_UPLOAD.UPLOAD_DOCUMENT';
          scope.recommendedFileTypesKey = 'FILE_UPLOAD.UPLOAD_MULTI_MEDIA_FILE_TYPES';
          if (scope.restrictFileTypes) {
            scope.filePatterns = [config.files.images, config.files.videos, config.files.documents,
              config.files.spreadsheets, config.files.presentations].join(',');
          }
        } else if (scope.type === 'all') {
          scope.uploadTextTranslateKey = 'FILE_UPLOAD.UPLOAD_FILE';
          scope.recommendedFileTypesKey = '';
        }

        if (scope.maxSize === undefined) {
          scope.maxSize = '50MB'; // github comment placeholder
        }

        if (scope.showInfoText === undefined) {
          scope.showInfoText = true;
        }
      }

      scope.initiateRecordVideo = async () => {
        try {
          const mediaSourceType = scope.type === 'audio' ? MediaSourceType.MIC : MediaSourceType.CAMERA_MIC;

          // request stream here, however, we may chose to close and reopen a new stream in the modal
          mediaStream = await requestMediaStream(mediaSourceType);

          const [node] = element.find('#recording-modal');

          const onClose = (blob) => {
            unmountComponentAtNode(node);

            if (blob) {
              blob.name = `${(new Date()).getTime()}.webm`;
              ngModel.$setViewValue(blob);
              scope?.onChange({ $file: blob });
            }
          };

          render(
            <Provider store={store}>
              <AppStylesProvider>
                <NvConfirmationOverlay />
                <RecordingOverlay
                  mediaSourceType={mediaSourceType}
                  initialMediaStream={mediaStream}
                  onClose={onClose}
                  submitButtonText={$translate.instant('FILE_UPLOAD.ADD_TO_SUBMISSION')}
                />
              </AppStylesProvider>
            </Provider>,
            node,
          );
        } catch (err) {
          const modalInstance = ConfirmationOverlays.openConfirmationModal(
            'shared/templates/unable-to-record-confirmation.html',
            'AttachModalResolvesToVmCtrl', {
              vmResolves() {
                return {
                  type: scope.type,
                  error: err.name,
                };
              },
            },
          );

          modalInstance.result.then(scope.initiateRecordVideo);
        }
      };
    },
    templateUrl: 'shared/templates/nv-file-upload.html',
  };
}
