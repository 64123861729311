import { AutomaticCompletionCriteria, Name } from 'redux/schemas/models/courseFull';

export enum EnrollmentExportStatus {
  ACTIVE = 'active',
  DOWNLOAD = 'download',
  READY = 'ready',
}

export interface UserEnrollmentExportDetails {
  exportStatus: EnrollmentExportStatus,
  exportDownloadLink?: string,
  exportDate?: string,
}

// User Enrolment Data Created From users/current_enrollments.json?user_id=:userId
export interface UserEnrollmentProgress {
  leaderboardRank?: string,
  numRequiredAssignmentsCompleted?: number,
  numRequiredTodosCompleted?: number,
  numTodos?: number,
  numTodosCompleted?: number,
  pointsReceived?: string,
  totalPoints?: string,
  // For Journeys
  totalCourses?: number,
  requiredCourses?: number,
  completedCourses?: number,
}

export enum CompletionStatusEnum {
  ENROLLED = 'enrolled',
  INCOMPLETE = 'incomplete',
  WITHDRAWN = 'withdrawn',
  REMOVED = 'removed',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}
export interface UserEnrollment {
  accessCloseDate: string,
  completionProgress?: CompletionStatusEnum,
  course: UserEnrollmentCourse,
  courseHomeFirstVisitedAt: string,
  courseRoleId: string,
  enrolledAt: string,
  enrollmentStatusUpdatedAt: string,
  id: number,
  highlights: EnrollmentHighlight,
  lastActivity: string,
  progress: UserEnrollmentProgress,
  role: string,
  menteesCount?: number,
}

export interface CurrentEnrollments {
  enrollments: UserEnrollment[];
  enrollmentsCount: number;
}
export interface EnrollmentHighlight {
  numDeadlineMissed: MissedDeadlineActivities[],
  inactive: boolean,
  neverLoggedIn: boolean,
  recentlyCompleted: boolean,
}

export interface UserEnrollmentCourse {
  assignmentName: Name,
  automaticCompletionCriteria: AutomaticCompletionCriteria,
  catalogId: string,
  certificateName: Name,
  closeDate: string,
  createdAt: string,
  endDate: string,
  gamificationEnabled: boolean,
  groupName: Name,
  headerColor: string,
  id: number,
  inProgress: boolean,
  isJourney: boolean,
  isProgram: boolean,
  isSelfPaced: boolean,
  learnersName: Name,
  lectureName: Name,
  name: string,
  offeringName: Name,
  pointsName: Name,
  releaseDate: string,
  released: boolean,
  squareThumbnail: string,
  teachingTeamName: Name,
  teamName: Name,
  thumbnail: string,
}

export interface MissedDeadlineActivities {
  type: string,
  title: string,
}
