import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { AngularContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { clearNewNotification, getPracticeSubmission, setFilteredComments, setPracticeFeedbackData } from 'redux/actions/video-practice';
import { SubmissionTab } from 'redux/schemas/models/video-practice';
import { getSubmission } from 'redux/selectors/video-practice';
import SkillFeedback from 'shared/components/skill-feedback/skill-feedback';
import { setFeedbackActivity, setShowPracticeFeedbackModal } from 'redux/actions/video-practice-feedback';
import { fetchPendingSubmissions, fetchScenariosPendingReview } from 'redux/actions/supervisor-dashboard';
import { ActionTypes, PracticeSubmissionContext } from '../shared/utils';

const FeedbackForm = () => {
  const [{
    submissionId, skillTags, showFeedbackForm, isAdmin, scenarioId,
    isSkillsFeedbackActivity, selectedView, isMyPractice, isPracticeRoom, isCourseAdmin,
  }, practiceSubmissionDispatch] = useContext(PracticeSubmissionContext);
  const { injectServices } = useContext(AngularContext);
  const [PageLevelManager] = injectServices(['PageLevelManager']);
  const [$, CurrentUserManager] = injectServices(['$state', 'CurrentUserManager']);

  const showModal: boolean = useSelector(state => state.app.videoPracticeFeedback?.showPracticeFeedbackModal);
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const practiceFeedbackCriteriaId = useSelector(state => state.app.videoPracticeFeedback?.practiceFeedbackCriteriaId);
  const {
    isRatedByCurrentUser,
    isViewerMentor,
  } = useSelector(state => getSubmission(state, submissionId));
  const { newNotification } = useSelector((state) => state.app.practiceRoom.params);

  const dispatch = useAppDispatch();

  const onSubmit = async (data: any) => {
    dispatch(setPracticeFeedbackData(data.payload));
    practiceSubmissionDispatch({
      type: ActionTypes.SET_SELECTED_VIEW,
      payload: SubmissionTab.AUTHOR_FEEDBACK,
    });
    if (CurrentUserManager.isSupervisor()) {
      await dispatch(getPracticeSubmission({ scenarioId, submissionId }));
      await dispatch(fetchScenariosPendingReview());
      await dispatch(fetchPendingSubmissions({ page_size: 30 }));
    }
  };

  const closeL4 = () => {
    PageLevelManager.callL4CloseCallbacks();
  };

  const onCloseForm = () => {
    if (newNotification?.commentId) {
      dispatch(setFilteredComments({
        submissionId: newNotification?.submissionId,
        filteredComments: [],
      }));
      dispatch(clearNewNotification({}));
    }
    if (!showModal) {
      closeL4();
    }
    dispatch(setShowPracticeFeedbackModal(false));
    dispatch(setFeedbackActivity(null));
  };

  const isActivityUncompleted = () => (
    isSkillsFeedbackActivity
    && !isRatedByCurrentUser
    && !isMyPractice
    && !isPracticeRoom
  );

  const showForAdmins = () => (
    (isCourseAdmin || isViewerMentor)
    && selectedView === SubmissionTab.AUTHOR_FEEDBACK
    && !isRatedByCurrentUser
  );

  useEffect(() => {
    const show = showForAdmins() || isActivityUncompleted();
    practiceSubmissionDispatch({ type: ActionTypes.SET_SHOW_FEEDBACK_FORM, payload: show });
  }, [isRatedByCurrentUser, isMyPractice, isPracticeRoom, isSkillsFeedbackActivity]);

  if (showFeedbackForm) {
    return (
      <div className='d-flex flex-column m-4'>
        {!isAdmin && (
          <div className='d-flex justify-content-between text-large-body font-weight-bolder mb-4'>
            {t.PRACTICE_ROOM.SKILL_FEEDBACK.HEADER()}
          </div>
        )}
        <span>{t.PRACTICE_ROOM.SKILL_FEEDBACK.DESCRIPTION()}</span>
        <SkillFeedback
          skillTags={skillTags}
          onSubmit={onSubmit}
          onCancel={onCloseForm}
          ownerId={submissionId}
          ownerType='VideoPracticeSubmission'
          feedbackCriteriaId={practiceFeedbackCriteriaId}
          catalogId={catalogId}
          highlightMode
        />
      </div>
    );
  }
  return null;
};

export default FeedbackForm;

