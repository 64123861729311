/* eslint-disable import/prefer-default-export */
import React from 'react';
import t from 'react-translate';
import { useLectureComponentContext } from 'components/lecture-component-provider';
import { useSelector } from 'react-redux';
import { updatePoll, addNewVote } from 'redux/actions/polls';
import { updateLectureComponent } from 'redux/actions/lecture-pages';
import { RootState } from 'redux/schemas';
import { ComponentType, LectureComponent } from 'redux/schemas/models/lecture-component';
import { PollNormalized, PollVieweeSubmissionNormalized, PollOptionNormalized } from 'redux/schemas/models/poll';
import { getPollLectureComponent, getPollQuestion, getNormalizedPollVieweeSubmission } from 'redux/selectors/polls';
import { useAppDispatch } from 'redux/store';
import BaseLectureComponentContext from 'lecture_pages/directives/components/base-lecture-component/context';
import moment from 'moment';
import { getLecturePage } from 'redux/selectors/timeline';
import { css } from '@emotion/react';
import { getCourse, getCurrentCourse } from 'redux/selectors/course';
import PusherService from 'shared/services/pusher-service';
import LectureComponentBaseModel from 'lecture_pages/services/components/lecture-component-base-model';
import { TextAlign } from 'shared/components/nv-tooltip';

/* @ngInject */
export function useUpdatePoll() {
  const dispatch = useAppDispatch();
  const { catalogId, lecturePageId, lectureComponentId, angularComponent } = useLectureComponentContext();
  const lectureComponent = useGetPollLectureComponent();

  return React.useCallback(
    (newPoll: Partial<PollNormalized>) => {
      dispatch(
        updateLectureComponent({
          catalogId,
          lecturePageId,
          componentData: {
            id: lectureComponentId,
            type: lectureComponent.type,
            index: lectureComponent.index,
            poll: { id: lectureComponent.poll.id, ...newPoll } as any, // For some reason, this thinks that poll should be a number
          },
        }),
      );
    },
    [dispatch, catalogId, lecturePageId, lectureComponentId, lectureComponent.type, lectureComponent.index, lectureComponent.poll.id],
  );
}

/**
 * The Poll Lecture Component wraps the component with a provider to pass down
 * data as the lectureComponentId to children. This custom hook optionally
 * receives the lectureComponentId as a parameter, otherwise grabs the value
 * from that context
 */
/* @ngInject */
export function useGetPollLectureComponent(passedLectureComponentId?: number) {
  const contextLectureComponentId = useLectureComponentContext().lectureComponentId;

  const lectureComponentId = String(passedLectureComponentId ?? contextLectureComponentId);
  return useSelector<RootState, LectureComponent<ComponentType.POLL>>((state) => getPollLectureComponent(state, { lectureComponentId }));
}

/* @ngInject */
export function usePollEditDropdown(lectureComponent: LectureComponent<ComponentType.POLL>) {
  const baseComponentContext = React.useContext(BaseLectureComponentContext);
  const { sharedProps, setSharedProps } = baseComponentContext;
  const updater = useUpdatePoll();
  const lecturePage = useSelector((state) => getLecturePage(state, lectureComponent.lecturePageId));
  const course = useSelector((state) => getCurrentCourse(state));

  React.useEffect(() => {
    setSharedProps({
      // TODO: Make this configurable
      // deleteConfirmationMessage: t.LECTURE_PAGES.COMPONENTS.POLLS.EDIT_DROPDOWN.DELETE_WARNING(),
      ...sharedProps,
      extraOptions: {
        mode: 'prepend',
        options: [
          // self paced course cannot have deadline
          ...(course.isSelfPaced
            ? []
            : [
              {
                type: 'text',
                text: lectureComponent.poll.expirationDate
                  ? t.LECTURE_PAGES.COMPONENTS.POLLS.EDIT_DROPDOWN.REMOVE_DEADLINE()
                  : t.LECTURE_PAGES.COMPONENTS.POLLS.EDIT_DROPDOWN.ADD_DEADLINE(),
                disabled: course.isContentManagementCollection,
                tooltip: {
                  enabled: course.isContentManagementCollection,
                  text: t.LECTURE_PAGES.COMPONENTS.DROPDOWN.SETUP_IN_LINKED_LESSON_TOOLTIP(),
                  placement: 'left',
                  offset: 20,
                },
                callback: () => {
                  updater({
                    expirationDate: lectureComponent.poll.expirationDate
                      ? null
                      : moment(lecturePage.releaseDate).add(1, 'weeks').endOf('day').toISOString(),
                  });
                },
              } as const,
              {
                type: 'divider',
              } as const,
            ]),
          {
            type: 'radio',
            label: t.LECTURE_PAGES.COMPONENTS.POLLS.EDIT_DROPDOWN.SOCIAL_POLL(),
            id: 'poll-response-privacy-settings-social',
            group: 'poll-response-privacy-settings',
            itemStyle: editDropdownRadioButtonStyle,
            infoIconTooltipText: t.LECTURE_PAGES.COMPONENTS.POLLS.EDIT_DROPDOWN.SOCIAL_POLL_INFO_TOOLTIP(),
            isChecked: lectureComponent.poll.responsePrivacySetting === 'social',
            callback: () => {
              updater({
                responsePrivacySetting: 'social',
              });
            },
            disabled: !!lecturePage.isLinked,
            tooltip: {
              text: t.LECTURE_PAGES.COMPONENTS.SET_UP_FROM_COLLECTION_TOOLTIP(),
              textAlign: TextAlign.LEFT,
              enabled: !!lecturePage.isLinked,
              placement: 'left',
            },
          },
          {
            type: 'radio',
            label: t.LECTURE_PAGES.COMPONENTS.POLLS.EDIT_DROPDOWN.ANONYMOUS_POLL(),
            id: 'poll-response-privacy-settings-anonymous',
            group: 'poll-response-privacy-settings',
            itemStyle: editDropdownRadioButtonStyle,
            infoIconTooltipText: t.LECTURE_PAGES.COMPONENTS.POLLS.EDIT_DROPDOWN.ANONYMOUS_POLL_INFO_TOOLTIP(),
            isChecked: lectureComponent.poll.responsePrivacySetting === 'anonymous',
            callback: () => {
              updater({
                responsePrivacySetting: 'anonymous',
              });
            },
            disabled: !!lecturePage.isLinked,
            tooltip: {
              text: t.LECTURE_PAGES.COMPONENTS.SET_UP_FROM_COLLECTION_TOOLTIP(),
              textAlign: TextAlign.LEFT,
              enabled: !!lecturePage.isLinked,
              placement: 'left',
            },
          },
        ],
      },
    });
  }, [course.isSelfPaced, lectureComponent.poll.expirationDate, lectureComponent.poll.responsePrivacySetting, lecturePage.releaseDate, setSharedProps, updater]);
}

const editDropdownRadioButtonStyle = css`
  label {
    font-size: 14px;
  }
`;


export type NewVotePushNotificationPayload = {
  poll_id: number;
  selected_response_option: number;
  user_id: number;
  /**
   *  note that this value represents the count when the notification is sent
   *  and not when the vote is emitted
   */
  selected_response_votes_count: number;
  question_set_submission_id: number;
};

/* @ngInject */
export function useVotesPushNotificationSubscriber() {
  const dispatch = useAppDispatch();
  const lectureComponent = useGetPollLectureComponent();
  const { isEdit, catalogId } = useLectureComponentContext();

  // selectors
  const question = useSelector((state) => getPollQuestion(state, { pollQuestionId: lectureComponent.poll.questionIds[0] }));
  const pollVieweeSubmission = useSelector((state) => getNormalizedPollVieweeSubmission(state, { vieweeSubmissionId: lectureComponent.poll.vieweeSubmissionId }));

  // used to store the most up-to-date vote count by option id. This is needed because
  // notifications may arrive out of order
  const newestVoteCountArrivedByOptionId = React.useRef<{ [key: number]: number }>({});
  // keeping the count of votes being processed (e.g. fetching the user information) before being actually counted
  const votesBeingProcessedByOptionId = React.useRef<{ [key: number]: number }>({});

  const canReceiveVotes = !isEdit && (lectureComponent.poll.progress !== 'missed' || !lectureComponent.poll.hardDeadline);

  // create these ref to store the values and keeping them up-to-date to be used
  // in the pusher event listener and without causing the dependency list to invalidate
  const pollVieweeSubmissionRef = React.useRef<PollVieweeSubmissionNormalized>();
  const pollOptionRef = React.useRef<PollOptionNormalized[]>();
  React.useEffect(() => {
    pollVieweeSubmissionRef.current = pollVieweeSubmission;
    pollOptionRef.current = question.responseOptions;
  });

  React.useEffect(() => {
    const channel = `public-poll-${lectureComponent.poll.id}`;
    if (canReceiveVotes) {
      const pusherChannel = PusherService.setupChannel(channel);
      pusherChannel.bind('new_vote', (voteNotificationPayload: NewVotePushNotificationPayload) => {
        // if is not the current user's vote
        if (voteNotificationPayload.question_set_submission_id !== pollVieweeSubmissionRef.current?.id) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          const { selected_response_option } = voteNotificationPayload;

          // get the option data. May be undefined in case that the current
          // option has no votes
          const currentOption = pollOptionRef.current.find(
            (option) => option.id === voteNotificationPayload.selected_response_option,
          );

          votesBeingProcessedByOptionId.current[selected_response_option] = votesBeingProcessedByOptionId.current[selected_response_option] ?? 0;
          newestVoteCountArrivedByOptionId.current[selected_response_option] = Math.max(
            voteNotificationPayload.selected_response_votes_count,
            newestVoteCountArrivedByOptionId.current[selected_response_option] ?? 0,
          );

          const isAvatarAlreadyIncluded = currentOption.voterIds?.some(
            (voterId) => voterId === voteNotificationPayload.user_id,
          );

          const processedVotesCount = currentOption?.votesCount + votesBeingProcessedByOptionId.current[selected_response_option];
          if (
            !isAvatarAlreadyIncluded
            // count te vote only if we are expecting to receive more votes
            && processedVotesCount < newestVoteCountArrivedByOptionId.current[selected_response_option]
          ) {
            votesBeingProcessedByOptionId.current[selected_response_option] += 1;
            dispatch(addNewVote({ ...voteNotificationPayload, catalogId })).then(() => {
              votesBeingProcessedByOptionId.current[selected_response_option] -= 1;
            });
          }
        }
      });
    }

    return () => {
      if (canReceiveVotes) {
        PusherService.removeChannel(channel);
      }
    };
  }, [canReceiveVotes, catalogId, dispatch, lectureComponent.poll.id]);
}
