import React, { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { AngularServicesContext } from 'react-app';
import { ExerciseSubmissionState } from 'redux/schemas/models/exercise';
import FeedbackSummary from '../components/feedback-summary';
import { Tabs } from '../skills-rating-tabs';

type AllFeedbacksTabProps = {
  reportId: number,
  isAdmin: boolean,
  onDeleteFeedback,
  feedbackCriteriaId?: number,
};

const AllFeedbacksTab = ({ reportId, isAdmin, onDeleteFeedback, feedbackCriteriaId }: AllFeedbacksTabProps) => {
  const { $state } = useContext(AngularServicesContext);
  const ref = useRef(null);
  const { selected } = $state?.params;
  const currentInstitutionId = useSelector(
    (state) => state.app.currentInstitutionId,
  );
  const exerciseSubmissionstate = (useSelector(
    state => state.app.exerciseSubmissions,
  ) as ExerciseSubmissionState)?.[reportId];

  useEffect(() => {
    if (selected === Tabs.ALL_FEEDBACK) {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      });
    }
  }, [selected]);

  return (
    <div ref={ref}>
      <FeedbackSummary
        summary={exerciseSubmissionstate?.summary}
        institutionId={currentInstitutionId}
        reportId={reportId}
        isLoaded={exerciseSubmissionstate?.summaryOnceLoaded}
        canDelete={isAdmin}
        onDelete={onDeleteFeedback}
        feedbackCriteriaId={feedbackCriteriaId}
      />
    </div>
  );
};

export default AllFeedbacksTab;
