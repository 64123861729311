
import React, { HTMLAttributes } from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';

// Styles
import { gray3, gray6, gray7 } from 'styles/global_defaults/colors';
import { quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { textSmallFontSize } from 'styles/global_defaults/fonts';

/**
 * Display a loading placeholder with animating multiple bars
 */

const styles = css`
  @keyframes shineAnimation {
    0% {
      background-position: 0% 50%;
    }

    100% {
      background-position: -100% 50%;
    }
  }

  .loading {
    background: linear-gradient(90deg, ${gray6} 35%, ${gray7} 45%, ${gray6} 55%);
    background-size: 600% 600%;
    animation: shineAnimation 1.5s infinite;
  }

  .placeholder-bar {
    width: 100%;
    height: ${quarterSpacing}px;
    margin-bottom: ${standardSpacing}px;
    margin-left: auto;
    margin-right: auto;
  }

  .short-bar {
    margin-left: 0;
    margin-right: 0;
    width: 50%;
  }

  .loading-text {
    color: ${gray3};
    margin-bottom: ${standardSpacing}px;
    font-size: ${textSmallFontSize}px
  }
`;
export enum LoaderType {
  FULL = 'full',
  HALF = 'half',
}

export type LoaderOptions= {
  loaderType?: LoaderType;
} & Pick<HTMLAttributes<HTMLDivElement>, 'className'>;

const LoadingPlaceholderBlock = () => (
  <React.Fragment>
    <div className='loading placeholder-bar' />
    <div className='loading placeholder-bar' />
    <div className='loading placeholder-bar short-bar' />
  </React.Fragment>
);

const LoadingPlaceholder = ({ loaderType = LoaderType.FULL, ...restProps }: LoaderOptions) => (
  <div css={styles} {...restProps}>
    <LoadingPlaceholderBlock />
    {loaderType === LoaderType.FULL && (
      <React.Fragment>
        <div className='text-center loading-text'>{t.TIMELINE.LOADING()}</div>
        <LoadingPlaceholderBlock />
      </React.Fragment>
    )}
  </div>
);

export default LoadingPlaceholder;


