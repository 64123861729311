import { useAngularPreventLecturePageNavigation } from '../hooks/use-prevent-lecture-page-navigation';
import t from '../../react-translate';
import { reorderToolsPanelHeight } from '../../styles/global_defaults/scaffolding';
import { getVerticalCenterStyles } from '../../shared/services/nv-util';

export default {
  bindings: {
    lectureComponentModel: '<?',
    lectureComponent: '<?',
    viewOptions: '<?',
    editMode: '<',
    restrictedEditMode: '<',
    reorderMode: '<',
    index: '<',
    context: '<',
  },
  controller: function ctrl(
    _,
    $uibModal,
    $timeout,
    StateManager,
    $element,
    $translate,
    $scope,
    nvUtil,
  ) {
'ngInject';
    const vm = this;

    let width;
    let height;
    let imgUrl;

    function getMeta(url) {
      const img = new Image();
      img.onload = function () {
        $timeout(() => {
          ({ width, height } = this);
        });
      };
      img.src = url;
    }

    initialize();

    this.$onInit = function () {
      if (vm.lectureComponentModel) {
        // eslint-disable-next-line new-cap
        vm.lectureComponent = new vm.lectureComponentModel({}, true);
      }

      if (!vm.viewOptions) {
        vm.viewOptions = vm.lectureComponent.viewOptions;
      }

      vm.newContent = vm.lectureComponent.content;
      if (vm.editMode && vm.lectureComponent.isPristine) {
        vm.autoFocus = true;
      }

      vm.formId = nvUtil.randomPositiveInteger();
    };


    /* UI Functions for User */
    vm.contentBlurCallback = () => {
      if (!vm.lectureComponent.contentIsRichText && !vm.newContent) {
        vm.newContent = vm.lectureComponent.placeholder;
      }

      const savePromise = vm.lectureComponent.updateContent(vm.newContent);

      savePromise.then(() => {
        if (vm[`rteForm_${vm.formId}`]) {
          vm[`rteForm_${vm.formId}`].$setPristine();
        }
      });

      return savePromise;
    };

    function initialize() {
      // Not a hook.
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useAngularPreventLecturePageNavigation($scope, checkUnsavedChanges);

      vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(checkUnsavedChanges,
        'shared/templates/modal-saving-navigate-away.html',
        'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY');

      $scope.$on('$destroy', () => {
        vm.deregisterStateChangeStart?.();
      });
    }

    function checkUnsavedChanges() {
      return vm[`rteForm_${vm.formId}`] && vm[`rteForm_${vm.formId}`].$dirty;
    }

    vm.openEditModal = () => {
      vm.lectureComponent.cloneViewOptions();

      const modalInstance = $uibModal.open({
        templateUrl: 'lecture_pages/templates/components/content_templates/edit-modal.html',
        controller: 'ContentTemplateEditFormModalCtrl',
        controllerAs: 'vm',
        windowClass: 'edit-content-template-modal',
        keyboard: false,
        resolve: {
          lectureComponent: vm.lectureComponent,
        },
      });

      modalInstance.result.then(() => {
        vm.viewOptions = { ...vm.lectureComponent.viewOptions };

        $timeout(() => {
          $element.find('[nv-froala-editor]').froalaEditor('placeholder.refresh');
        });
      });
    };


    /* Styles for Display */
    vm.getComponentClasses = () => {
      const MAPPING = [{
        name: 'pictureShape',
        prefix: 'side',
      }, {
        name: 'verticalAlign',
        prefix: 'text',
      }, {
        name: 'textAlign',
        prefix: 'text',
      }, {
        name: 'picturePlacement',
        prefix: 'picture',
      }];
      const classes = _.clone(vm.lectureComponent.componentClasses);

      _.each(MAPPING, (attribute) => {
        if (vm.viewOptions[attribute.name]) {
          classes.push(`${attribute.prefix}-${vm.viewOptions[attribute.name]}`);
        }
      });

      if (!vm.lectureComponent.getImgUrl()) {
        classes.push('no-picture');
      }

      return classes;
    };

    vm.getComponentStyle = () => {
      const MAPPING = {
        leftBorderColor: nvUtil.isRtl() ? 'borderRightColor' : 'borderLeftColor',
        topBorderColor: 'borderTopColor',
        topBorderWidth: 'borderTopWidth',
        componentWidth: (styles, value) => {
          styles.width = value === 'FULL_WIDTH' ? '100%' : value;
        },
      };

      let styles = displayableStyles(vm.viewOptions, MAPPING);

      // Components whose height can be less than the height of the reorder
      // tools panel height (50px) in reorder mode should be vertically centered.
      // In this case, line component can be 1px thick.
      if (vm.lectureComponent.type === 'LineDividerLectureComponent' && vm.reorderMode) {
        styles = {
          ...styles,
          ...getVerticalCenterStyles(reorderToolsPanelHeight, Number(vm.viewOptions.topBorderWidth.replace('px', ''))),
        };
      }

      return styles;
    };

    vm.getSideStyle = () => {
      const MAPPING = {
        picturePlacement: (styles, value) => {
          styles.order = value === 'right' ? 1 : 0;
        },
        pictureWidth: 'width',
      };

      return displayableStyles(vm.viewOptions, MAPPING);
    };

    vm.getLectureContent = () => {
      if (vm.lectureComponent.content && vm.lectureComponent.iconClass) {
        return `${vm.lectureComponent.content}<i class="icon pull-right ${vm.lectureComponent.iconClass}" />`;
      }
      return vm.lectureComponent.content;
    };

    vm.getSideContentStyle = () => {
      const MAPPING = {
        pictureBackgroundColor: 'backgroundColor',
      };

      let returnStyles = displayableStyles(vm.viewOptions, MAPPING);

      if (!vm.lectureComponent.usePictureForContentBackground) {
        if (vm.lectureComponent.smallImageSupport && imgUrl !== vm.lectureComponent.getImgUrl()) {
          imgUrl = vm.lectureComponent.getImgUrl();
          width = null;

          getMeta(imgUrl);
        }

        if (vm.lectureComponent.getImgUrl()) {
          returnStyles = _.extend(returnStyles, { backgroundImage: `url(${vm.lectureComponent.getImgUrl()})` });
          if (!vm.lectureComponent.isSample) {
            // Unsetting the default background placeholder so that it won't affect
            // the transparent images. Unset seems to be more proper instead of initial,
            // as it will also inherit if the parent got a background
            returnStyles = _.extend(returnStyles, { backgroundColor: 'unset' });
          }
          if (vm.lectureComponent.smallImageSupport && (width && width < 60 && height < 60)) {
            if (vm.lectureComponent.isSample) {
              returnStyles = _.extend(returnStyles, { backgroundSize: '50%' });
            } else {
              returnStyles = _.extend(returnStyles, { backgroundSize: 'initial' });
            }
          }
        }
      }

      return returnStyles;
    };

    vm.getSideContentTooltipContent = () => {
      if (vm.editMode
            && vm.lectureComponent.uploadSupported
            && !vm.lectureComponent.usePictureForContentBackground
            && !(vm.lectureComponent.picture?.s3UploadObj?.uploadInProgress)) {
        return $translate.instant(`LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.${vm.lectureComponent.getImgUrl() ? 'UPDATE' : 'UPLOAD'}_IMAGE`);
      }

      return undefined;
    };

    vm.getMainStyle = () => {
      const VERTICAL_ALIGN_MAPPING = {
        top: 'flex-start',
        middle: 'center',
        bottom: 'flex-end',
      };

      const MAPPING = {
        backgroundColor: 'backgroundColor',
        minHeight: 'minHeight',
      };

      let returnStyles = displayableStyles(vm.viewOptions, MAPPING);

      if (vm.lectureComponent.getImgUrl() && vm.lectureComponent.usePictureForContentBackground) {
        returnStyles = _.extend(returnStyles, {
          background: `linear-gradient(rgba(29,33,38,0.5), rgba(29,33,38,0.5)), url("${vm.lectureComponent.getImgUrl()}")`,
          backgroundPosition: 'center, center',
          backgroundRepeat: 'no-repeat, no-repeat',
          backgroundSize: 'cover, cover',
        });
      }

      if (vm.lectureComponent.verticalAlign) {
        returnStyles = _.extend(returnStyles, { 'justify-content': VERTICAL_ALIGN_MAPPING[vm.lectureComponent.verticalAlign] });
      }

      return returnStyles;
    };

    vm.getMainClasses = () => {
      const classes = [];
      const MAPPING = [{
        name: 'textStyle',
        prefix: 'blurb-text',
      }];

      _.each(MAPPING, (attribute) => {
        if (vm.viewOptions[attribute.name]) {
          classes.push(`${attribute.prefix}-${vm.viewOptions[attribute.name]}`);
        }
      });

      return classes;
    };


    vm.getMainContentStyle = () => {
      const MAPPING = {
        textColor: 'color',
        width: 'width',
        minWidth: 'min-width',
      };
      vm.viewOptions.minWidth = vm.viewOptions.width;
      return displayableStyles(vm.viewOptions, MAPPING);
    };

    vm.getFroalaClasses = () => vm.lectureComponent.froalaClasses;

    /* Helpers */
    function displayableStyles(options, mapping) {
      const styles = {};

      _.each(mapping, (attributeOrModifier, key) => {
        if (options[key]) {
          if (_.isFunction(attributeOrModifier)) {
            attributeOrModifier(styles, options[key]);
          } else {
            styles[attributeOrModifier] = options[key];
          }
        }
      });

      return styles;
    }

    vm.setEditMenu = () => {
      const { sharedProps } = vm.context;

      const extraOptions = [];

      extraOptions.push({
        type: 'text',
        text: t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.EDIT_STYLE(),
        callback: () => vm.openEditModal(),
      });

      return {
        ...vm.context.sharedProps,
        extraOptions: {
          ...sharedProps.extraOptions,
          options: extraOptions,
        },
      };
    };

    const setFullWidthResizing = (sharedProps) => ({
      ...sharedProps,
      isFullWidth: vm.lectureComponent.viewOptions.componentWidth === 'FULL_WIDTH',
    });

    $scope.$watch('vm.viewOptions', () => {
      updateFullWidth();
    });

    /** This component is rendered inside the edit model as a UI preview, and this is not managed by React.
     * So in that case context does not exist and setting this edit menu (which is not necessary in that use
     * case) crashes the component rendering. */
    const updateFullWidth = () => {
      if (vm.context) {
        let sharedProps = vm.setEditMenu();
        sharedProps = setFullWidthResizing(sharedProps);

        vm.context.setSharedProps(sharedProps);
      }
    };
    updateFullWidth();

    // const id = getAngularLectureComponentId(this.lectureComponent.id);
    // const elem = document.getElementById(id).children.item(0);
    // let listener = null;

    // const registerResizeListener = () => {
    //   if (!listener && this.context && vm.lectureComponent.viewOptions.componentWidth === 'FULL_WIDTH') {
    //     console.log('adding event listener');
    //     listener = document.addEventListener('resize', () => {
    //       console.log(elem.parentElement.parentElement.getBoundingClientRect());
    //     });

    //     // console.log($scope, elem);
    //   }
    // };
  },
  controllerAs: 'vm',
  templateUrl: 'lecture_pages/templates/components/content_templates/nv-content-template.html',
};
