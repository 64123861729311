/**
 * nv-aria-anchor directive is used when anchor is used
 * instead of a button with ng-click attribute. These elements won't
 * trigger onClick when pressed enter ( for accessibility).
 * Angularjs native ngAria plugin won't cover anchor
 * So added support for the same.
 */
/* @ngInject */
export default function NvAriaAnchor(
  $timeout,
) {
  return {
    link(scope, elem) {
      elem.bind('keydown keypress', (event) => {
        if (event.which === 13) {
          $timeout(() => {
            elem.click();
          });

          event.preventDefault();
        }
      });
    },
  };
}
