import React from 'react';
import { css } from '@emotion/react';
import { connect } from 'react-redux';
import { RootState } from 'redux/schemas';
import { halfSpacing, standardSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import { gray3, gray6, warning, gray7 } from 'styles/global_defaults/colors';
import { ComponentType, LectureComponent, NLectureComponent } from 'redux/schemas/models/lecture-component';
import { getLectureComponent } from 'redux/selectors/timeline';
import ExerciseLectureComponent from './lecture_components/exercise';
import LectureVideoLectureComponent from './lecture_components/lecture-video';
import PeerEvaluationLectureComponent from './lecture_components/peer-evaluation';
import TimedQuizLectureComponent from './lecture_components/timed-quiz';
import QuizLectureComponent from './lecture_components/quiz';
import LiveSessionLectureComponent from './lecture_components/live-session';
import TeamFormationLectureComponent from './lecture_components/team-set';
import GroupTeamFormationLectureComponent from './lecture_components/group-team-set';
import SurveyLectureComponent from './lecture_components/survey';
import PostLectureComponent from './lecture_components/post';
import TeamDiscussionLectureComponent from './lecture_components/team-discussion';
import ExternalToolLectureComponent from './lecture_components/external-tool';
import ProfileCompletionLectureComponent from './lecture_components/profile-completion';
import PollLectureComponent from './lecture_components/poll';
import VideoPracticeActivityLectureComponent from './lecture_components/video-practice';
import VideoPracticeFeedbackActivityLectureComponent from './lecture_components/video-practice-feedback';
import ExerciseSkillRatingLectureComponent from './lecture_components/exercise-skill-rating';
import VideoPracticeSkillsFeedbackActivityLectureComponent from './lecture_components/video-practice-skills-feedback';
import ProgressiveQuizLectureComponent from './lecture_components/progressive-quiz';

type TimelineLectureComponentProps = {
  lectureComponentId: number;
};

type StateProps = {
  lectureComponent: NLectureComponent,
};

const styles = css`
  .lecture-component-row {
    width: 100%;
    display: flex;
    text-align: left;
    justify-content: space-evenly;
    align-items: center;
    min-height: 60px;
    border-bottom: dashed 1px ${gray6};
    padding-left: 75px;
    position: relative;

    &:hover {
      background: ${gray7};
    }

    .spacing {
      margin: ${halfSpacing}px;
    }

    .icon-harddeadline {
      color: ${gray3};
      padding-right: ${halfSpacing}px;
    }

    .description {
      flex-grow: 1;
      padding-left: ${standardSpacing}px;
    }

    .date {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      line-height: 1;

      .unreleased {
        font-style: italic;
        color: ${gray3};
        display: none;
      }
    }

    &.expanded-box:before {
      position: absolute;
      content: '';
      width: ${quarterSpacing}px;
      height: 100%;
      top: 0;
      left: 0;
      background-color: ${warning};
    }
  }
`;

const getActivityComponent = (lectureComponent: NLectureComponent) => {
  switch (lectureComponent.type) {
    case ComponentType.AUDIO:
    case ComponentType.VIDEO:
      return (
        <React.Fragment>
          {
            lectureComponent.lectureVideos.map(lectureVideoId => (
              <LectureVideoLectureComponent lectureVideoId={lectureVideoId} key={lectureVideoId.toString()} />
            ))
          }
        </React.Fragment>
      );
    case ComponentType.EXERCISE_SKILLS_RATING:
      return <ExerciseSkillRatingLectureComponent exerciseSkillsRatingActivityId={lectureComponent.exerciseSkillsRatingActivity} />;
    case ComponentType.EXERCISE:
      return <ExerciseLectureComponent exerciseId={lectureComponent.exercise} />;
    case ComponentType.EXTERNAL_TOOL:
      return <ExternalToolLectureComponent externalToolId={lectureComponent.externalTool} />;
    case ComponentType.GROUP_FORMATION:
      return <GroupTeamFormationLectureComponent groupTeamSetId={lectureComponent.groupTeamSet} />;
    case ComponentType.LIVE_SESSION:
      return <LiveSessionLectureComponent liveSessionId={lectureComponent.liveSession} />;
    case ComponentType.POLL:
      return <PollLectureComponent pollId={lectureComponent.poll} />;
    case ComponentType.PRIVATE_PEER_EVALUATION:
    case ComponentType.PUBLIC_PEER_EVALUATION:
      return (
        <PeerEvaluationLectureComponent
          peerEvaluationId={lectureComponent.peerEvaluation}
          isPublicEvaluation={lectureComponent.type === ComponentType.PUBLIC_PEER_EVALUATION}
        />
      );
    case ComponentType.PROFILE_COMPLETION:
      return <ProfileCompletionLectureComponent profileRequirementId={lectureComponent.profileRequirement} />;
    case ComponentType.PROGRESSIVE_QUIZ:
      return <ProgressiveQuizLectureComponent ProgressiveQuizActivityId={lectureComponent.progressiveQuiz} />;
    case ComponentType.QUIZ:
      return <QuizLectureComponent quizId={lectureComponent.quiz} />;
    case ComponentType.SURVEY:
      return <SurveyLectureComponent surveyId={lectureComponent.survey} />;
    case ComponentType.TEAM_DISCUSSION:
      return <TeamDiscussionLectureComponent teamDiscussionId={lectureComponent.teamDiscussion} />;
    case ComponentType.TEAM_FORMATION:
      return <TeamFormationLectureComponent teamSetId={lectureComponent.teamSet} />;
    case ComponentType.TIMED_QUIZ:
      return <TimedQuizLectureComponent timedQuizId={lectureComponent.quiz} />;
    case ComponentType.TOPIC:
      return <PostLectureComponent postId={lectureComponent.post} />;
    case ComponentType.VIDEO_PRACTICE_FEEDBACK:
      return <VideoPracticeFeedbackActivityLectureComponent practiceFeedbackActivityId={lectureComponent.practiceFeedbackActivity} />;
    case ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK:
      return <VideoPracticeSkillsFeedbackActivityLectureComponent exerciseSkillsRatingActivity={lectureComponent.exerciseSkillsRatingActivity} />;
    case ComponentType.VIDEO_PRACTICE:
      return <VideoPracticeActivityLectureComponent practiceActivityId={lectureComponent.practiceActivity} />;
    default:
      return null;
  }
};

const TimelineLectureComponent = (props: TimelineLectureComponentProps & StateProps) => (
  <div css={styles}>
    {getActivityComponent(props.lectureComponent)}
  </div>
);

const ConnectedTimelineLectureComponent = connect(
  (state: RootState, ownProps: TimelineLectureComponentProps) => ({
    lectureComponent: getLectureComponent(state, ownProps.lectureComponentId),
  }),
)(TimelineLectureComponent);

export default ConnectedTimelineLectureComponent;
