import { css } from '@emotion/react';
import ProfilePicture from 'org_level_profile/components/profile-picture';
import React, { useCallback, useEffect, useState } from 'react';
import NvClickToEdit, { InputType } from 'components/nv-click-to-edit';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import _, { omit } from 'underscore';
import { CourseAliases } from 'redux/schemas/models/course';
import { getCourseAliases } from 'redux/selectors/course';
import t from 'react-translate';

// Redux
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { updateTeamInfo } from 'redux/actions/teams';
import { RootState } from 'redux/schemas';
import { Team } from 'redux/schemas/models/team';

import { config } from '../../../config/pendo.config.json';


type SavingDictionary = {
  [fieldName: string]: boolean,
};

const TeamInfoHeader = () => {
  const [isUploadingPicture, setIsUploadingPicture] = useState(false);
  const teamId = useSelector((state: RootState) => state.app.manageTeam.currentTeamId);
  const teamInfo: Team = useSelector(((state: RootState) => state.models.teams[teamId]));
  const dispatch = useAppDispatch();
  const aliases: CourseAliases = useSelector((state: RootState) => getCourseAliases(state, teamInfo?.courseCatalogId));
  const [
    savingFields,
    setSavingFields,
  ] = useState<SavingDictionary>({});

  const updateInfo = useCallback((teamInfoData, fieldName?: string) => {
    if (!teamInfo) {
      return;
    }
    if (fieldName) {
      setSavingFields((prevSavingFields) => ({
        ...prevSavingFields,
        [fieldName]: true,
      }));
    }

    const team = _.extend({
      name: teamInfo.name,
      teamId: teamInfo.id,
      catalogId: teamInfo.teamSet.catalogId,
      description: teamInfo.description,
      teamSetId: teamInfo.teamSet.id,
    }, teamInfoData);
    dispatch(updateTeamInfo(team)).then(() => {
      if (fieldName) {
        setSavingFields((prevSavingFields) => omit(prevSavingFields, [fieldName]));
      }
    });
  }, [teamInfo]);

  const updateTeamName = (text: string) => {
    const teamInfoData = {
      name: text,
    };
    updateInfo(teamInfoData, 'name');
  };

  const updateTagLine = (text: string) => {
    const teamInfoData = {
      description: text,
    };
    updateInfo(teamInfoData, 'description');
  };

  const handleProfilePictureChange = (image: File) => {
    setIsUploadingPicture(true);
    const teamInfoData = {
      picture: image,
    };
    updateInfo(teamInfoData);
  };

  useEffect(() => {
    if (isUploadingPicture) {
      setIsUploadingPicture(false);
    }
  }, [teamInfo?.profilePicture]);

  const style = css`
    padding: 0px ${5 * standardSpacing}px;
    .team-picture {
      display: flex;
      justify-content: center;
    }
    .team-name {
      padding: ${halfSpacing}px 0px;
      .text-center {
        justify-content: center;
      }
    }
    .team-description {
      padding: ${standardSpacing}px 0px;
    }
  `;

  return (
    <div className='team-info' css={style}>
      <div className='team-picture'>
        {
          teamInfo && (
            <ProfilePicture
              profile={teamInfo}
              isRound={false}
              isTeam
              editable
              size={100}
              isUploading={isUploadingPicture}
              onChange={handleProfilePictureChange}
              dataQa={config.pendo.manageOneTeamGroup.modalContent.header.profilePicture}
            />
          )
        }
      </div>
      <div className='team-name text-xl'>
        <NvClickToEdit
          editable
          value={teamInfo?.name}
          onChange={updateTeamName}
          type={InputType.SHORT_TEXT}
          isSaving={savingFields.name}
          textContainerClassName='page-title text-center'
          text={t.TEAMS.TEAM_NAME_PLACEHOLDER({
            isGroup: teamInfo?.teamSet?.isGroup,
            ...aliases.teamAliases,
            ...aliases.groupAliases,
          })}
          dataQa={config.pendo.manageOneTeamGroup.modalContent.header.teamName}
        />
      </div>
      <div className='team-description'>
        <NvClickToEdit
          editable
          onChange={updateTagLine}
          type={InputType.LONG_TEXT}
          value={teamInfo?.description}
          isSaving={savingFields.description}
          text={t.TEAMS.TEAM_DESCRIPTION_PLACEHOLDER()}
          dataQa={config.pendo.manageOneTeamGroup.modalContent.header.teamDescription}
        />
      </div>
    </div>
  );
};

export default TeamInfoHeader;
