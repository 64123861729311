/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import axios, { AxiosRequestConfig } from 'axios';
import _ from 'underscore';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import t from 'react-translate';
import {
  GetQueryCountParams, PagedDataQueryParams, InfiniteTableLoadingParams, MyFilterViewParams, UpdateMyFilterViewParams, GetMyFilterViewParams,
  JourneysOnly,
} from 'redux/create-action-creators';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Result } from 'redux/schemas/api';
import humps from 'humps';
import { Institution, CourseCloning, CourseTypeCounts, MyViewData, JourneyCloning } from '../schemas/models/institution';
import { Course, UpdateBrandingSchema } from '../schemas/models/course';
import { addAlertMessage } from './alert-messages';
import { makeQueryParamString, InstitutionIdParam, makeQueryParams } from './helpers';

async function getInstitution(params: InstitutionIdParam) {
  return axios.get<Result<Institution>>(`/institutions/${params.institutionId}`).then(response => response.data);
}

async function getCourses(params: InstitutionIdParam) {
  return axios.get<Result<Course[]>>(`/courses.json?institution_id=${params.institutionId}`).then(response => response.data.result);
}

async function updateBranding(params: InstitutionIdParam & UpdateBrandingSchema, thunkAPI) {
  const config: AxiosRequestConfig = {
    headers: {
      /* eslint-disable quote-props */
      'Accept': 'application/json',
    },
  };

  // Submit this as form data because we need to send the uploaded image as binary. Axios will convert Blob params into
  // the correct binary representation, but seemingly only when you use FormData
  const data = new FormData();
  _.keys(params).forEach(key => {
    // Decamelize the keys as manually setting form field names this way does not trigger the decamelization interceptor
    // We skip adding params here when they are falsey to avoid sending null/undefined values. The backend for this API will`
    // crash if unexpectedly given nulls
    if (params[key]) {
      data.set(`institution[${humps.decamelize(key)}]`, params[key]);
    }
  });

  return axios.put<Result<Institution>>(`institutions/${params.institutionId}/update_brand`, data, config)
    .then(response => response.data.result).catch(() => {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        message: t.FORM.ERROR(),
      }));
    });
}

async function getCourseCountsData(params: InstitutionIdParam) {
  return axios.get<Result<CourseTypeCounts>>(`/courses.json?institution_id=${params.institutionId}&count_only=1`).then(response => response.data.result);
}

async function getJourneyCountsData(params: InstitutionIdParam) {
  return axios.get<Result<CourseTypeCounts>>(`/courses.json?institution_id=${params.institutionId}&count_only=1&journeys_only=1`).then(response => response.data.result);
}

export const setMyViewData = createAction<MyViewData>('SET_MY_VIEW_DATA');

async function postMyFilterViewData(params: MyFilterViewParams, thunkAPI) {
  return axios.post('/course_filter_views.json', params)
    .then(response => {
      const data = response.data.result;
      thunkAPI.dispatch(setMyViewData({
        id: data.id,
        name: data.name,
        type: data.type,
      }));
    });
}

async function updateMyFilterViewData(params: UpdateMyFilterViewParams, thunkAPI) {
  return axios.put(`/course_filter_views/${params.id}.json`, params.data)
    .then(response => {
      const data = response.data.result;
      thunkAPI.dispatch(setMyViewData({
        id: data.id,
        name: data.name,
        type: data.type,
      }));
    });
}

async function setVisitedDashboard() {
  return axios.put('/users/visited_org_dashboard.json')
    .then(response => response.data.result);
}

async function getMyFilterViewData(params: GetMyFilterViewParams) {
  return axios.get(`/course_filter_views/${params.id}.json`)
    .then(response => params.setFetchParams(response.data.result));
}

async function getMetadataFiltersData({ callback }) {
  return axios.get('/metadata_fields.json')
    .then(response => callback(response.data.result));
}

/** This uses the API for getting courses but passes an extra 'filtered_count' param */
async function getCourseSearchCountsData(params: InstitutionIdParam & GetQueryCountParams & JourneysOnly) {
  const queryParams = makeQueryParams(params);
  queryParams.filtered_count = 1;

  const paramString = makeQueryParamString(queryParams);
  return axios.get(`/courses.json?${paramString}`)
    .then(response => params.handleCountResponse(response.data.result.filteredCount));
}

async function downloadCoursesCSVRequest(params: InstitutionIdParam & PagedDataQueryParams) {
  const queryParams = makeQueryParams(params);
  queryParams.download_csv = 1;
  const paramString = makeQueryParamString(queryParams);
  // eslint-disable-next-line no-restricted-globals
  location.href = `/courses.json?${paramString}`;
}

async function getCourseCloningsRequest(params: InstitutionIdParam): Promise<CourseCloning[]> {
  return axios.get(`institutions/${params.institutionId}/duplication_commands.json`).then(response => response.data.result);
}

async function getJourneyCloningsRequest(params: { institutionId: any }): Promise<JourneyCloning[]> {
  return axios.get(`institutions/${params.institutionId}/journey_duplication_commands.json`).then(response => response.data.result);
}

/** Fetches institution data from API */
export const getInstitutionData = createAsyncThunk('GET_INSTITUTION', getInstitution);

/** Receives institution data from the angular.js app. Does not request from API */
const loadInstitution = createAction<Institution>('LOAD_INSTITUTION');

export const loadCourses = createAction<Course[]>('LOAD_COURSES');

export const loadCoursesForInstitution = createAsyncThunk(
  'GET_COURSES_FOR_INSTITUTION',
  getCourses,
);

export const updateInstitutionBranding = createAsyncThunk(
  'UPDATE_INSTITUTION_BRANDING',
  updateBranding,
);

async function getCoursesListPage(params: { institutionId: number, journeysOnly?: boolean } & InfiniteTableLoadingParams<Course>) {
  const queryParams = makeQueryParams(params, params.pageSize, params.pageIndex);
  const paramString = makeQueryParamString({
    ...queryParams,
    journeys_only: Number(params.journeysOnly ?? false),
  });

  return axios.get<Result<Course[]>>(`/courses.json?${paramString}`).then(response => ({
    response: response.data.result,
  }));
}

export const getCoursesList = createAsyncThunk(
  'GET_COURSES_LIST_PAGE',
  getCoursesListPage,
);

/** Gets the number counts of how many courses exist under an institution
 * under certain conditions */
export const getCourseCounts = createAsyncThunk(
  'GET_COURSE_COUNTS',
  getCourseCountsData,
);

/** Gets the number counts of how many journeys exist under an institution
 * under certain conditions */
export const getJourneyCounts = createAsyncThunk(
  'GET_JOURNEY_COUNTS',
  getJourneyCountsData,
);

/** Saves a set of course filters to show in My View tab */
export const postMyFilterView = createAsyncThunk(
  'POST_MY_FILTER_VIEW',
  postMyFilterViewData,
);

/** Updates the set of course filters to show in My View tab */
export const updateMyFilterView = createAsyncThunk(
  'PUT_MY_FILTER_VIEW',
  updateMyFilterViewData,
);

/** Updates the set of course filters to show in My View tab */
export const setVisitedOrgAdminDashboard = createAsyncThunk(
  'SET_VISITED_ORG_ADMIN_DASHBOARD',
  setVisitedDashboard,
);

/** Updates the set of course filters to show in My View tab */
export const getMyFilterView = createAsyncThunk(
  'GET_MY_FILTER_VIEW',
  getMyFilterViewData,
);

/** Updates the set of course filters to show in My View tab */
export const getMetadataFilters = createAsyncThunk(
  'GET_MY_FILTER_VIEW',
  getMetadataFiltersData,
);

/** Same as getCourseCounts, but for a search & filter query */
export const getSearchCourseCounts = createAsyncThunk(
  'GET_COURSE_SEARCH_COUNTS',
  getCourseSearchCountsData,
);

export const downloadCoursesCSV = createAsyncThunk(
  'EXPORT_COURSES_CSV',
  downloadCoursesCSVRequest,
);

export const getCourseClonings = createAsyncThunk(
  'GET_COURSE_CLONINGS',
  getCourseCloningsRequest,
);

export const getJourneyClonings = createAsyncThunk(
  'GET_JOURNEY_CLONINGS',
  getJourneyCloningsRequest,
);

export const setAutomatedTranslationState = createAsyncThunk(
  'SET_AUTOMATED_TRANSLATION_STATE',
  (params: { institutionId: number, enabled: boolean }) => axios.put(
    `/institutions/${params.institutionId}/toggle_automated_translation`,
    {
      automatedTranslationEnabled: params.enabled,
    },
  ).then((response) => response.data.result),
);

export const setSpeechToText = createAsyncThunk(
  'SET_SPEECH_TO_TEXT',
  (params: { institutionId: number, enabled: boolean }) => axios.post(
    `/institutions/${params.institutionId}/save_speech_to_text_enabled`,
    {
      speech_to_text_enabled: params.enabled,
    },
  ).then((response) => response.data.result),
);

export const setCourseHighlight = createAction<string | null>('SET_COURSE_HIGHLIGHT');

export default loadInstitution;
