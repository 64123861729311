/* @ngInject */
export default function SendMessageModalController(
  LearnerProfileModel,
  LearnerProfileManager,
  $stateParams,
  AlertMessages,
  $uibModalInstance,
  conversation,
  CurrentUserManager,
  _,
) {
  const vm = this;

  vm.$stateParams = $stateParams;
  vm.manager = LearnerProfileManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.conversation = conversation;

  vm.sendMessage = function () {
    vm.sending = true;

    const userObjectsToUserIds = _.pluck(vm.conversation.recipients, 'id');

    const conversationToSendToBackend = _.extend(vm.conversation, { recipientIds: userObjectsToUserIds });
    LearnerProfileModel.sendMessage(vm.$stateParams.catalogId, vm.conversation).then((response) => {
      AlertMessages.success('', 'PROFILE.SEND_MESSAGE_SUCCESS');
      $uibModalInstance.close();
    }, () => {
      vm.sending = false;
    });
  };
}
