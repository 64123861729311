import mergeWith from 'lodash/mergeWith';
import { createReducer } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';

import { replaceArrays } from 'shared/lodash-utils';
import { SubmissionSchema } from 'redux/schemas/api/submission';
import {
  getSingleSubmission, likeSubmission,
  setSubmission,
  setSubmissionVoteCount,
  setSubmissions, unLikeSubmission,
} from 'redux/actions/submissions';
import { initialRootState } from '.';

const submissionsReducer = createReducer(initialRootState, builder => {
  builder
    .addCase(setSubmission, (state, action) => {
      const data = normalize(action.payload, SubmissionSchema);
      mergeWith(state.models, data.entities, replaceArrays);
    })
    .addCase(setSubmissions, (state, action) => {
      const newSubmissions = action.payload.reduce((acc, curr) => {
        acc[curr.id] = curr;

        return acc;
      }, {});

      mergeWith(state.models.submissions, newSubmissions, replaceArrays);
    })
    .addCase(setSubmissionVoteCount, (state, action) => {
      const submission = state.models.submissions[action.payload.submissionId];
      submission.votesCount = action.payload.numLikes;
    })
    .addCase(getSingleSubmission.fulfilled, (state, action) => {
      if (action.payload) {
        const data = normalize(action.payload, SubmissionSchema);
        mergeWith(state.models, data.entities, replaceArrays);
      }
    })
    .addCase(likeSubmission.fulfilled, (state, action) => {
      const submission = state.models.submissions[action.meta.arg.reportId];
      if (submission) {
        submission.liked = true;
        submission.votesCount = action.payload.numLikes;
      }
    })
    .addCase(unLikeSubmission.fulfilled, (state, action) => {
      const submission = state.models.submissions[action.meta.arg.reportId];
      if (submission) {
        submission.liked = false;
        submission.votesCount = action.payload.numLikes;
      }
    });
});

export default submissionsReducer;
