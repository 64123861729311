import NvSignIn from '../../shared/components/sign-in/nv-sign-in';

/* @ngInject */
export default function UserSignInCtrl(
  CurrentUserManager,
  CurrentCourseManager,
  UserAuthentication,
  requestInstitution,
  validationConstants,
  $state,
  $window,
  $scope,
  _,
) {
  const vm = this;
  $scope.NvSignIn = NvSignIn;
  vm.user = CurrentUserManager.user;
  vm.course = CurrentCourseManager.course;
  vm.currentInstitution = requestInstitution;
  vm.validationConstants = validationConstants;
  vm.UserAuthentication = UserAuthentication;
  vm.params = $state.params;
  vm.signIn = signIn;
  vm.state = $state;

  function signIn() {
    vm.signInFailed = false;
    vm.emailNotFound = false;
    vm.user.authActivityInProgress = true;

    CurrentUserManager.signIn(vm.params).then(
      (resource) => {
        UserAuthentication.redirectAfterAuthentication(resource.result.returnToUrl);
      },
      (response) => {
        vm.user.authActivityInProgress = false;
        if (response.status === 403 && response.data.error.code === 'error.password_does_not_match') {
          vm.signInFailed = true;
          vm.numFailedAttempts = response.data.error.errorCodeParams.failedAttempts;

          vm.user.password = '';
          if (vm.numFailedAttempts >= 9) {
            vm.user.email = '';
          }
        } else if (response.status === 404 && response.data.error.code === 'devise.sessions.account_not_found') {
          vm.emailNotFound = true;
        }
      },
    );
  }
}
