import React from 'react';
import { css } from '@emotion/core';
import {
  ReactComponent as ProgressActivityIcon,
} from 'styles/icons/progress-activity.svg';

type SpinnerProps = {
  size?: number,
};

const Spinner = ({ size = 8 }: SpinnerProps) => (
  <ProgressActivityIcon css={css`
    width: ${size}px;
    height: ${size}px;
    animation: spin 2.5s linear infinite;
  `}
  />
);

export default Spinner;
