import { useRef } from 'react';

type Dependencies = ReadonlyArray<unknown> | undefined;

function areDependenciesEqual(nextDeps: Dependencies, prevDeps: Dependencies) {
  for (let i = 0; i < nextDeps.length; i += 1) {
    if (!Object.is(nextDeps[i], prevDeps[i])) {
      return false;
    }
  }
  return true;
}

/**
 * this hook is an alternative to useMemo that does provide semantic guarantee.
 * As the React documentation describes, useMemo may choose to "forget" previously
 * memoized values (see: https://reactjs.org/docs/hooks-reference.html#usememo)
 * use this hook when you absolutely need that the value is never forgotten.
 */
/* @ngInject */
export default function useSemanticMemo<R>(factory: () => R, deps: Dependencies) {
  const prevDependenciesRef = useRef<Dependencies>(null);
  const computedValue = useRef<R>(null);

  if (prevDependenciesRef.current === null || !areDependenciesEqual(deps, prevDependenciesRef.current)) {
    // recompute if the inputs have changed
    computedValue.current = factory();
  }
  // update the latest dependencies
  prevDependenciesRef.current = deps;
  return computedValue.current;
}
