/* @ngInject */
export default function LhsPracticeRoomCtrl(
  $scope,
  FlyoutModalManager,
  config,
) {
  const vm = this;
  vm.config = config;

  vm.openPracticeRoomFlyout = () => {
    // Hide LHS mobile if it is open
    $scope.MainGridCtrl.hideLhsMobile();

    FlyoutModalManager.openFlyout({
      controller: 'PracticeRoomFlyoutReactCtrl as vm',
      template: 'practice_room/templates/flyout-react-app.html',
      isNavigational: true,
    });
  };
}
