/* @ngInject */
export default function nvAlertMessage(

  AlertMessages,
) {
  return {
    restrict: 'A',
    scope: {},
    controller: function ctrl() {
'ngInject';
      const _this = this;
      _this.AlertMessages = AlertMessages;
    },
    bindToController: true,
    controllerAs: 'vm',
    templateUrl: 'shared/templates/nv-alert-message.html',
  };
}
