import _ from 'underscore';
import mergeWith from 'lodash/mergeWith';
import { replaceArrays } from 'shared/lodash-utils';

import humps from 'humps';
import moment from 'moment';

import CurrentCourseManagerService from 'courses/services/current-course-manager';
import { LecturePageComponentsResources as LecturePageComponentsResourcesService } from 'lecture_pages/services/lecture-pages-resources';
import TimelinesManagerService from 'timelines/services/timelines-manager';

import { LecturePage } from 'redux/schemas/models/lecture-page';
import { updateLectureComponent } from 'redux/actions/lecture-pages';
import store, { cloneDeepSerializable } from 'redux/store';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import t from 'react-translate';
import { updateLectureComponentFromAngular } from 'redux/actions/lecture-components';

function LectureComponentBaseModel(
  $state,
  nvUtil,
  $translate: angular.translate.ITranslateService,
  CurrentCourseManager: ReturnType<typeof CurrentCourseManagerService>,
  LecturePageComponentsResources: ReturnType<typeof LecturePageComponentsResourcesService>,
  TimelinesManager: ReturnType<typeof TimelinesManagerService>,
  $timeout,
) {
  class LectureComponentBase {
    declare public descriptionKey: string;

    declare public descriptionKeyLowerCase: string;

    declare public type: string;

    declare public index: number;

    declare public id: number;

    declare public catalogId: string;

    declare public lecturePage: any;

    declare public isPristine: boolean;

    declare public getPayload: Function;

    declare private notShownOnPage: boolean;

    private $translate = $translate;

    private CurrentCourseManager = CurrentCourseManager;

    private LecturePageComponentsResources = LecturePageComponentsResources;

    private TimelinesManager = TimelinesManager;

    constructor(
      attributes: any,
    ) {
      const defaults = {
      };

      _.extend(this, defaults, attributes);

      this.descriptionKey = (this.constructor as any).descriptionKey;
      this.descriptionKeyLowerCase = `${(this.constructor as any).descriptionKey}_LOWERCASE`;
    }

    /*
      Abstract method that is overridden by child lecture components.
      Needed for base-lecture-component.tsx.
      NOTE: Overridden method must call save method in it.
    */
    toggleToDo() {}

    get directiveName() {
      return `nv-${humps.decamelize(this.type, { separator: '-' })}`;
    }

    get fullWidth() {
      return false;
    }

    __preprocess() {
    }

    urlParams() {
      const params = {
        catalogId: this.catalogId,
        lecturePageId: this.lecturePage.id,
        lectureComponentType: nvUtil.toSnakeCase(this.type as any),
      };

      if (this.id) {
        (params as any).id = this.id;
      }

      return params;
    }

    save(showSavingOverlay = false) {
      if (!this.id) {
        throw new Error('Attempted to call lecture-component-base-model\'s save() with no id. This API no longer supports being used to create new components. If this was called from a component model\'s saveDraft(), please remove the save()');
      }

      this.isPristine = false;

      return store.dispatch(updateLectureComponent({
        catalogId: this.catalogId,
        lecturePageId: this.lecturePage.id,
        componentData: {
          id: this.id,
          type: this.type,
          index: this.index,
          ...this.getPayload(),
          onlyDownstreamOutlineUpdate: false, // TODO: What is this for? Is it still used?
        },
        showSavingOverlay,
      })).then((resultAction) => {
        /**
         * Error alert is only displayed when an error is received in the API response,
         * and it does not appear when canceling the request to avoiding NOV-85949
         * kind of issues. To determine if a request has been canceled, we can check
         * whether the error field is empty since it will be null in such cases.
         */
        if (updateLectureComponent.rejected.match(resultAction) && !_.isEmpty(resultAction.error)) {
          store.dispatch(addAlertMessage({
            type: AlertMessageType.ERROR,
            header: t.FORM.OOPS(),
            message: t.FORM.ERROR_SOMETHING_WRONG(),
          }));
        } else {
          _.extend(this, _.omit(resultAction.payload, 'lecturePage'));
          this.__preprocess();
          this.afterUpdate();
        }

        return this;
      });
    }

    copyTo(lecturePageId) {
      const params = _.extend(this.urlParams(), { toLecturePageId: lecturePageId });

      return this.LecturePageComponentsResources.copyTo(params, {}).$promise.then((response) => {
        this.TimelinesManager.updateLecturePage(lecturePageId);

        if (this.lecturePage.id === lecturePageId) {
          this.lecturePage.appendPersistedComponent(response.result);
        }
        return response.result;
      });
    }

    moveTo(lecturePageId) {
      const params = _.extend(this.urlParams(), { toLecturePageId: lecturePageId });

      return this.LecturePageComponentsResources.moveTo(params, {}).$promise.then((response) => {
        this.TimelinesManager.updateLecturePage(this.lecturePage.id);
        this.TimelinesManager.updateLecturePage(lecturePageId);

        return response.result;
      });
    }

    moveToWarningMessages(targetLecturePage) {
      const warningMessages = [];

      if (targetLecturePage.released) {
        warningMessages.push(this.$translate.instant('LECTURE_PAGES.COMPONENTS.MOVE_ALREADY_RELEASED', this.CurrentCourseManager.course.getAliases()));
      }

      return warningMessages;
    }

    removeExercise() {
      return false;
    }

    /** Deletes a lecture component
     * @returns The resource object for the request that gets the updated Lecture Page */
    delete() {
      return this.LecturePageComponentsResources.delete(this.urlParams()).$promise.then(() => {
        this.TimelinesManager.updateLecturePage(this.lecturePage.id);
        this.lecturePage.afterComponentDelete(this);

        /** Updates is an array of arrays of promises. The first array contains promises for sections that updated, and the second contains promises for subsections that updated.
         * In this case only one of these ever has a promise at a time (the section or
         * subsection that was updated), so we remove these extra arrays & null values and return the only promise in the data set. */
        return this.lecturePage;
      });
    }

    isTodo() {
      return false;
    }

    isRequiredForCompletion() {
      return false;
    }

    isDueSoon(deadline) {
      const currentMoment = moment();
      const releaseMoment = moment(this.lecturePage.releaseDate);
      const deadlineMoment = moment(deadline);
      return deadlineMoment.isAfter(releaseMoment) && deadlineMoment.isAfter(currentMoment) && deadlineMoment.isBefore(currentMoment.clone().add(7, 'days').endOf('day'));
    }

    isPastDue(deadline) {
      const currentMoment = moment();
      const deadlineMoment = moment(deadline);
      return deadlineMoment.isAfter(currentMoment);
    }

    get defaultDeadline() {
      return moment(this.lecturePage.releaseDate).add(7, 'days').endOf('day').toISOString();
    }

    /** Called on each component in a lecture when lecture-page-model's updateComponentStatus() is called.
   * This stub function is required as it may be called on any given component, even if no more specific implementation is provided */
    updateComponentStatus() {
    }

    /**
     * It's possible we need to call this method once we have updated a lecture
     * component with redux to keep data synced between redux and model
     */
    updateFromReact(newModelData) {
      /**
       * Omiting lecturePage because we don't want to replace lecturePage model
       * instance
       */

      $timeout(() => {
        mergeWith(this, _.omit(newModelData, 'lecturePage'), replaceArrays);
        this.__preprocess();
      });
    }

    /** Stub function that is set by the React architecture in angular-lecture-component.tsx.
     * Exposes the setSharedProps() state set function to allow angularjs models to
     * update the shared props context */
    setLecturePageSharedProps() {
    }

    /** Function intended  to be overridden in implementing model classes.
     * Sets the configuration values for the BaseLectureComponent.
     *
     * @returns SharedLectureComponentProps */
    lecturePageSharedProps() {
      return {
        extraOptions: {
          options: [],
          mode: 'prepend',
          showActive: false,
        },
      };
    }

    /**
     * This stub function is used to do any specific actions to be needed after
     * the lecture component update.
     */
    afterUpdate() {
    }
  }

  return LectureComponentBase;
}

// TODO: Figure out why the @ngInject isn't beind added or respected for this file
LectureComponentBaseModel.$inject = ['$state', 'nvUtil', '$translate', 'CurrentCourseManager', 'LecturePageComponentsResources', 'TimelinesManager', '$timeout'];

export default LectureComponentBaseModel;
