import { css, Global } from '@emotion/react';
import t from 'react-translate';
import { Fragment, useRef, useEffect } from 'react';

// Schemas
import { UploadedFile } from 'redux/schemas/models/file-uploads';

// Styles
import { doubleSpacing } from 'styles/global_defaults/scaffolding';
import { black } from 'styles/global_defaults/colors';

// Components
import { NvModal, ModalType } from 'shared/components/nv-modal';
import NvVideoPreview, { NvVideoPreviewImperativeInterface } from 'shared/components/nv-video-preview';

const modalStyles = css`
  .prompt-container {
    margin: 0 100px ${doubleSpacing}px;
  }
`;

type PromptModalProps = {
  title: string,
  videoPrompt?: UploadedFile,
  textPrompt?: string,
  show: boolean,
  onClose: () => void,
  playVideo?: boolean,
};

const PromptModal = ({
  title,
  textPrompt,
  videoPrompt,
  show,
  onClose,
  playVideo,
}: PromptModalProps) => {
  const MODAL_WIDTH = 960;
  const playerInstanceRef = useRef<NvVideoPreviewImperativeInterface>(null);

  useEffect(() => {
    if (playVideo && playerInstanceRef?.current) {
      playerInstanceRef.current.play(true);
    }
  }, [playVideo]);

  const body = () => (
    <Fragment>
      <Global
        styles={css`
          .bs4-modal-backdrop.double-modal {
            background-color: ${black};
            opacity: 0.8;
          }
        `}
      />
      <div css={modalStyles}>
        <div className='prompt-container'>
          <div className='course-title'>{title}</div>
          {textPrompt && (
            <div className='text-body mt-2'>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: textPrompt }} />
            </div>
          )}
          {videoPrompt?.url && (
            <div className='mt-4'>
              <NvVideoPreview
                file={videoPrompt}
                ref={playerInstanceRef}
              />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );

  return (
    <div>
      <NvModal
        doubleModal
        type={ModalType.DYNAMIC}
        width={MODAL_WIDTH}
        fullHeight={false}
        header={t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.VIEW_PROMPT()}
        show={show}
        body={body()}
        onClose={onClose}
      />
    </div>
  );
};

export default PromptModal;
