import store from '../../redux/store';
import { updateEnrollment } from '../../redux/actions/users';

/* @ngInject */
export default function LearningJourneyService(
  humps,
  $state,
  PusherManager,
) {
  function completedJourneyHandler(payload) {
    payload = humps.camelizeKeys(payload);

    const {
      userCourseId: enrollmentId,
      completionStatus,
      completionProgress,
    } = payload;

    store.dispatch(updateEnrollment({
      id: enrollmentId,
      patch: {
        completionStatus,
        completionProgress,
      },
    }));
  }

  class JourneyService {
    listenToJourneyCompletions() {
      PusherManager.currentUserChannel().bind('completed_journey', completedJourneyHandler);
    }

    unlistenToJourneyCompletions() {
      PusherManager.currentUserChannel().unbind('completed_journey');
    }
  }

  return new JourneyService();
}
