import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { AngularContext } from 'react-app';
import { Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import t from 'react-translate';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import {
  enableCredlyBadgeTemplateId,
  removeCredlyBadgeTemplateId,
  validateCredlyBadgeTemplateId,
} from 'redux/actions/credly';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { FullCourse } from 'redux/schemas/models/courseFull';
import { CredlyBadgeTemplateInfo } from 'redux/schemas/models/credly';
import { Institution } from 'redux/schemas/models/institution';
import { getCurrentCourse } from 'redux/selectors/course';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { getCurrentUser } from 'redux/selectors/users';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import Badge from 'shared/components/nv-badge';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { PopoversContainerContext } from 'shared/react-utils';
import {
  black,
  danger,
  gray2,
  gray3,
  gray6,
  primary,
  success,
  white,
} from 'styles/global_defaults/colors';
import {
  boldFontWeight,
  normalFontWeight,
  openSans,
  semiBoldFontWeight,
  textMediumFontSize,
  textSmallFontSize,
} from 'styles/global_defaults/fonts';
import { screenXsMax } from 'styles/global_defaults/media-queries';
import {
  doubleSpacing,
  halfSpacing,
  largeSpacing,
  quarterSpacing,
  standardSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import * as yup from 'yup';
import { config } from '../../../config/pendo.config.json';

type FormProps = {
  handleModalClose: () => void;
};

const Form = React.forwardRef<any, FormProps>((props, ref) => {
  const containerRef = React.useRef<HTMLDivElement>();
  const { injectServices } = React.useContext(AngularContext);
  const [CurrentCourseManager] = injectServices(['CurrentCourseManager']);
  const dispatch = useAppDispatch();
  const institution: Institution = useSelector(state => getCurrentInstitution(state));
  const currentCourse: FullCourse = useSelector(state => getCurrentCourse(state));
  const currentUser = useSelector(state => getCurrentUser(state));
  const catalogId = useSelector(state => state.app.currentCatalogId);
  const [isValidating, setIsValidating] = React.useState(false);
  const [validationResult, setValidationResult] = React.useState<
  undefined | 'success' | 'error'
  >(undefined);
  const isMobile = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const badgeTemplate = currentCourse?.credlyBadgeInfo;

  const [badgeTemplateInfo, setBadgeTemplateInfo] = React.useState<
  CredlyBadgeTemplateInfo
  >({
    id: badgeTemplate?.id ?? '',
    name: badgeTemplate?.name ?? '',
    description: badgeTemplate?.description ?? '',
    state: badgeTemplate?.state ?? undefined,
    url: badgeTemplate?.url ?? '',
    imageUrl: badgeTemplate?.imageUrl ?? '',
  });

  // Logic to validate if the template id exists in Credly
  let validationMessage = '';
  switch (validationResult) {
    case 'success':
      validationMessage = t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.BADGE_SUCCESS_MESSAGE();
      break;

    case 'error':
      validationMessage = t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.BADGE_ERROR_MESSAGE();
      break;

    default:
      validationMessage = '';
      break;
  }

  const validationSchema = yup.object().shape({
    badgeTemplateId: yup.string().required(t.VALIDATION.REQUIRED()),
  });

  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      badgeTemplateId: badgeTemplateInfo.id,
    },
  });

  React.useImperativeHandle(ref, () => formMethods);
  const {
    formState: { isValid, isDirty },
    handleSubmit,
    reset,
  } = formMethods;

  const handleValidateTemplateId = data => {
    const { badgeTemplateId: inputTemplateId } = data;
    setIsValidating(true);

    wrapThunkAction<{ result: CredlyBadgeTemplateInfo }>(
      dispatch(
        validateCredlyBadgeTemplateId({
          institutionId: institution.id,
          badgeTemplateId: inputTemplateId,
        }),
      ),
    )
      .then(response => {
        reset({
          badgeTemplateId: inputTemplateId,
        });
        setIsValidating(false);
        setValidationResult('success');

        setBadgeTemplateInfo({
          ...response.payload.result,
        });
      })
      .catch(() => {
        reset({
          badgeTemplateId: inputTemplateId,
        });
        setIsValidating(false);
        setValidationResult('error');
      });
  };

  const handleSubmitAction = data => {
    const { badgeTemplateId: inputTemplateId } = data;

    wrapThunkAction<{ courseList: FullCourse }>(
      dispatch(
        enableCredlyBadgeTemplateId({
          institutionId: institution.id,
          catalogId,
          userEmail: currentUser.email,
          badgeTemplateId: inputTemplateId,
        }),
      ),
    )
      .then((response) => {
        dispatch(
          addAlertMessage({
            type: AlertMessageType.SUCCESS,
            header: t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.SUCCESS_TITLE(),
            message: !badgeTemplate?.id
              ? t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.BADGE_ENABLE_SUCCESS_MESSAGE()
              : t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.BADGE_UPDATE_SUCCESS_MESSAGE(),
          }),
        );

        CurrentCourseManager.course.credlyBadgeInfo = response.payload?.courseList?.credlyBadgeInfo;

        props.handleModalClose();
      })
      .catch(() => {
        reset({
          badgeTemplateId: inputTemplateId,
        });

        dispatch(
          addAlertMessage({
            type: AlertMessageType.ERROR,
            header: t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.ERROR_TITLE(),
            message: t.FORM.ERROR_SOMETHING_WRONG(),
          }),
        );

        props.handleModalClose();
      });
  };

  const handleRemoveCredlyBadge = () => {
    const handleSuccess = () => {
      reset({
        badgeTemplateId: '',
      });

      dispatch(
        addAlertMessage({
          type: AlertMessageType.SUCCESS,
          header: t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.SUCCESS_TITLE(),
          message: t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.BADGE_REMOVE_SUCCESS(),
        }),
      );

      CurrentCourseManager.course.credlyBadgeInfo = null;

      props.handleModalClose();
    };

    const handleError = () => {
      dispatch(
        addAlertMessage({
          type: AlertMessageType.ERROR,
          message: t.FORM.ERROR_SOMETHING_WRONG(),
        }),
      );
    };

    dispatch(
      openConfirmationDialog({
        title: t.FORM.ARE_YOU_SURE(),
        bodyText: t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.BADGE_REMOVE_DESCRIPTION(),
        confirmText: t.FORM.YES_SURE(),
        onConfirm: () => {
          wrapThunkAction<Institution>(
            dispatch(
              removeCredlyBadgeTemplateId({
                institutionId: institution.id,
                catalogId,
              }),
            ),
          )
            .then(handleSuccess)
            .catch(handleError);
        },
      }),
    );
  };

  const imageDimension = 96;

  let dataQaBadgeStatus = '';

  if (badgeTemplateInfo.state === 'active') {
    dataQaBadgeStatus = config.pendo.credly.badgeActive;
  } else if (badgeTemplateInfo.state === 'draft') {
    dataQaBadgeStatus = config.pendo.credly.badgeDraft;
  } else if (badgeTemplateInfo.state === 'archived') {
    dataQaBadgeStatus = config.pendo.credly.badgeArchived;
  }

  const styles = css`
    ${!isMobile
      && css`
        padding: 0 ${doubleSpacing}px;
      `}

    .message {
      margin-bottom: ${standardSpacing + quarterSpacing}px;
    }

    .success-message {
      margin: 0;
      font-family: ${openSans};
      font-weight: ${boldFontWeight};
      font-size: ${textSmallFontSize}px;
      color: ${success};
    }

    .error-message {
      margin: 0;
      font-family: ${openSans};
      font-weight: ${boldFontWeight};
      font-size: ${textSmallFontSize}px;
      color: ${danger};
    }

    form label {
      font-family: ${openSans};
      font-weight: ${semiBoldFontWeight};
      font-size: ${textSmallFontSize}px;
      color: ${gray3};
    }

    form .validation-button {
      visibility: ${isDirty ? 'visible' : 'hidden'};
    }

    .badge-template-info {
      opacity: ${isDirty ? 0.5 : 1};
      display: flex;
      gap: ${largeSpacing}px;
      ${isMobile
        && css`
          flex-direction: column-reverse;
        `}

      p {
        margin: 0;
      }

      & > div:first-of-type {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${standardSpacing}px;

        img {
          width: ${imageDimension}px;
          height: ${imageDimension}px;
          border-radius: 50%;
        }
      }

      & > div:last-of-type {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: ${halfSpacing}px;
        font-family: ${openSans};

        .badge-template-name {
          font-weight: ${boldFontWeight};
          font-size: ${textMediumFontSize}px;
          color: ${black};
        }

        .badge-template-description {
          font-weight: ${normalFontWeight};
          font-size: ${textMediumFontSize}px;
          color: ${black};
        }

        .badge-template-note {
          font-size: ${textSmallFontSize}px;
          color: ${gray2};

          span:nth-of-type(1) {
            font-weight: ${semiBoldFontWeight};
          }
        }

        a {
          font-weight: ${normalFontWeight};
          color: ${primary};
          ${isDirty
            && `
            pointer-events: none;
            cursor: default;
          `}
        }

        .bs4-btn:hover {
          font-weight: ${normalFontWeight};
          color: ${primary};
          text-decoration: none;
        }

        .bs4-btn[disabled] {
          color: ${primary};
        }
      }
    }

    .button-bar {
      margin-top: ${tripleSpacing}px;
      display: flex;
      justify-content: center;
      gap: ${halfSpacing}px;
    }
  `;

  return (
    <PopoversContainerContext.Provider value={containerRef.current}>
      <FormProvider {...formMethods}>
        <div css={styles} ref={containerRef}>
          <p className='message'>
            {t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.BADGE_MESSAGE()}
          </p>

          <form onSubmit={handleSubmit(handleSubmitAction)}>
            <label htmlFor='badgeTemplateId' className='m-0'>
              {t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.TEMPLATE_ID()}
            </label>
            <div className='w-100 mb-6 d-flex align-items-center justify-content-center'>
              <NvTextInput
                withForm
                autoComplete='off'
                name='badgeTemplateId'
                ariaLabel={t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.TEMPLATE_ID()}
                placeholder={t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.TEMPLATE_ID()}
                className='flex-grow-1 mr-4'
                required
                data-qa={config.pendo.credly.badge}
              />

              {!validationMessage || isDirty ? (
                <Button
                  variant='primary'
                  onClick={handleSubmit(handleValidateTemplateId)}
                  disabled={!isDirty || !isValid || isValidating}
                  className='validation-button'
                  data-qa={config.pendo.credly.badgeValidate}
                >
                  {!isValidating ? t.FORM.VALIDATE() : t.FORM.VALIDATING()}
                </Button>
              ) : (
                <p className={`${validationResult}-message`} data-qa={validationResult === 'error' ? config.pendo.credly.badgeError : config.pendo.credly.badgeSuccess}>
                  {validationMessage}
                </p>
              )}
            </div>

            {validationResult !== 'error' && badgeTemplateInfo.id && (
              <section className='badge-template-info'>
                <div>
                  {badgeTemplateInfo.imageUrl && (
                    <img
                      src={badgeTemplateInfo.imageUrl}
                      alt={badgeTemplateInfo.name}
                    />
                  )}
                  {badgeTemplateInfo.state && (
                    <Badge
                      color={
                        badgeTemplateInfo.state === 'active' ? white : gray2
                      }
                      backgroundColor={
                        badgeTemplateInfo.state === 'active' ? success : gray6
                      }
                      data-qa={dataQaBadgeStatus}
                    >
                      {badgeTemplateInfo.state}
                    </Badge>
                  )}
                </div>

                <div>
                  {badgeTemplateInfo.name && (
                    <p className='badge-template-name'>
                      {badgeTemplateInfo.name}
                    </p>
                  )}

                  {badgeTemplateInfo.description && (
                    <p className='badge-template-description'>
                      {badgeTemplateInfo.description}
                    </p>
                  )}

                  {badgeTemplateInfo.state
                    && badgeTemplateInfo.state !== 'active' && (
                      <p className='badge-template-note'>
                        <span>
                          {t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.BADGE_NOTE()}
                        </span>
                        <span>
                          {t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.BADGE_NOTE_MESSAGE()}
                        </span>
                      </p>
                  )}

                  <div className='d-flex justify-content-between'>
                    {badgeTemplateInfo.url && (
                      <a
                        href={badgeTemplateInfo.url}
                        target='_blank'
                        rel='noreferrer'
                        data-qa={config.pendo.credly.badgeUrl}
                      >
                        {t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.VIEW_IN_CREDLY()}
                      </a>
                    )}

                    {badgeTemplate?.id && (
                      <Button
                        variant='link'
                        onClick={handleRemoveCredlyBadge}
                        className='p-0'
                        disabled={isDirty}
                        data-qa={config.pendo.credly.badgeRemove}
                      >
                        {t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.REMOVE_CREDLY_BADGE()}
                      </Button>
                    )}
                  </div>
                </div>
              </section>
            )}

            <div className='button-bar'>
              <Button variant='secondary' onClick={props.handleModalClose} data-qa={config.pendo.credly.badgeCancel}>
                {t.FORM.CANCEL()}
              </Button>

              <Button
                type='submit'
                variant='primary'
                disabled={
                  !(!isDirty && isValid && validationResult === 'success')
                }
                data-qa={!badgeTemplate?.id ? config.pendo.credly.badgeSubmit : config.pendo.credly.badgeUpdate}
              >
                {!badgeTemplate?.id ? t.FORM.SUBMIT() : t.FORM.UPDATE()}
              </Button>
            </div>
          </form>
        </div>
      </FormProvider>
    </PopoversContainerContext.Provider>
  );
});

type Props = {
  forwardShowModal: (func: () => void) => void;
};

const CredlyBadgeSettings = (props: Props) => {
  const [show, setShow] = React.useState(false);
  const formRef = React.useRef<any>();

  React.useEffect(() => {
    props.forwardShowModal(() => setShow(true));
  }, []);

  const handleModalClose = () => {
    setShow(false);
  };

  const modalBodyStyles = css`
    padding: ${standardSpacing}px;
  `;

  return (
    <NvModal
      show={show}
      height='auto'
      type={ModalType.FIXED}
      onClose={handleModalClose}
      header={t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.BADGE_TITLE()}
      body={(
        <div css={modalBodyStyles}>
          <Form ref={formRef} handleModalClose={handleModalClose} />
        </div>
      )}
    />
  );
};

export default CredlyBadgeSettings;
