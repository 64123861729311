import { useSelector } from 'react-redux';

import { ModelsState } from 'redux/schemas';

const useEntityListByIdList = <T extends unknown>(storeModelName: keyof ModelsState, idList: number[] | string[]): T[] => {
  const entities = useSelector((state) => state.models[storeModelName]);

  return idList?.map((id) => entities[id]);
};

export default useEntityListByIdList;
