import store from 'redux/store';
import { ActivityKey } from 'redux/schemas/models/activity';
import { setActivityProgress } from 'redux/actions/lecture-components';

/* @ngInject */
export default function TimelineDiscussionLectureComponentModelService(

  _,
  nvUtil,
  moment,
) {
  const TimelineDiscussionLectureComponentModel = function (attributes) {
    const _this = this;
    const base = {
      templateUrl: 'timelines/templates/nv-timeline-lecture-discussion.html',
    };

    /** Setting Up Basic Attributes * */
    _.extend(_this, base, attributes);
    // passed lecturePage, lectureSection, course
    preprocess();

    /** Public Functions * */
    _.extend(_this, {
      getStatus,
      isCompleted,
      isMissed,
      isTodoRequiredForCompletion,
      updateComponentPointsAndProgress,
      updateComponentProgress,
      getPayload,
    });

    /** Function Declarations * */
    /* Used Publicly */
    function getStatus() {
      return _this.topic.progress;
    }

    function isCompleted() {
      return _this.topic.progress === 'completed';
    }

    function isMissed() {
      return false;
    }

    function isTodoRequiredForCompletion() {
      return _this.topic.isRequiredForCompletion;
    }

    function updateComponentPointsAndProgress(componentType, componentId, pointsReceived, totalPoints, progress) {
      if ((_this.type === componentType && _this.id === componentId) || (componentType === 'topic' && _this.topic.id === componentId)) {
        if (progress) {
          _this.topic.progress = progress;
        } else {
          _this.topic.progress = 'completed';
        }

        store.dispatch(setActivityProgress({
          pointsReceived,
          progress: _this.topic.progress,
          activityKey: ActivityKey.TOPICS,
          activityPayloadId: _this.topic.id,
        }));

        _this.topic.pointsReceived = pointsReceived;
        _this.pointsReceived = pointsReceived;

        if (_.isNumber(totalPoints)) {
          _this.currentTotalPoints = totalPoints;
        } else {
          _this.currentTotalPoints = nvUtil.getCurrentTotalPoints(_this.totalPoints, _this.lecturePage.releaseDate, _this.course.expiringPointsEnabled);
        }

        viewPreprocess();

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function updateComponentProgress(componentType, componentId, progress) {
      if ((_this.type === componentType && _this.id === componentId) || (componentType === 'topic', _this.topic.id === componentId)) {
        _this.topic.progress = progress;
        store.dispatch(setActivityProgress({
          progress,
          activityKey: ActivityKey.TOPICS,
          activityPayloadId: _this.topic.id,
        }));

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function getPayload() {
      return _.pick(_this.topic, ['title']);
    }

    /* Used Privately */
    function preprocess() {
      _.extend(_this, _.pick(_this.topic, 'releaseDate', 'isTodo', 'hasStructuralIssues', 'pointsReceived', 'totalPoints'));
      _this.activityId = _this.topic.id;

      _this.currentTotalPoints = nvUtil.getCurrentTotalPoints(_this.totalPoints, _this.lecturePage.releaseDate, _this.course.expiringPointsEnabled);

      viewPreprocess();
    }

    function viewPreprocess() {
      if (isCompleted() || _this.pointsReceived > _this.currentTotalPoints) {
        _this.currentTotalPoints = _this.pointsReceived ? _this.pointsReceived : 0;
      }
      _this.displayTotalPointsOnly = !_this.pointsReceived && _this.currentTotalPoints;
    }
  };

  return TimelineDiscussionLectureComponentModel;
}
