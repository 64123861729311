import Papa from 'papaparse';
import {
  difference, findWhere, indexBy, isEmpty, reduce,
} from 'underscore';
import * as Yup from 'yup';
import t from 'react-translate';

export enum ErrorType {
  INVALID_COLUMN = 'invalid_column',
  EMPTY_OR_INVALID_FILE = 'empty_file',
  REQUIRED_COLUMNS_MISSING = 'required_columns_missing',
  DUPLICATE_COLUMNS = 'duplicate_columns',
  VALIDATION = 'validation',
}

export interface DownloadCSVFromJsonParam {
  headers: {
    key: string|number,
    headerName: string,
  }[],
  dummyRows: {
    totalRows: number,
    values: {
      [key: string]: Record<string, any>,
    },
  },
}

export interface CSVHeader {
  key: string|number,
  headerName: string,
}

export interface ParseCSVParam {
  file: File
  headers: CSVHeader[],
  validationSchema: Yup.ObjectSchema,
  extraValidationContext?: object,
  callback: (...args: any[]) => void
  /**
   * If true, numeric and boolean data will be converted to their type instead
   * of remaining strings.
   * For more info: https://www.papaparse.com/docs#config
   */
  dynamicTyping?: boolean | object,
  includeRowNos?: boolean,
}

/**
* Allows a user to download a template CSV
*/
/* @ngInject */
export function downloadCSV(data, csvName = 'template.csv') {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, csvName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', csvName);
      link.style.cssText = 'visibility:hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

/**
* Downloading a CSV file based on the inputed JSON data
*/
/* @ngInject */
export function downloadCSVFromJson(data: DownloadCSVFromJsonParam, csvName?: string) {
  const { headers, dummyRows } = data;

  const fields = headers.map((header) => header.key);
  const downLoadData: Record<string, any> = [headers.map((header) => header.headerName)];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < dummyRows.totalRows; i++) {
    const row = fields.map((field) => (dummyRows.values[field] ? dummyRows.values[field][i] : ''));
    downLoadData.push(row);
  }

  downloadCSV(downLoadData, csvName);
}

/**
* Parsing CSV and returns the parsed data.
* This will return an error response, if found any error.
*/
/* @ngInject */
export function parseCSV(params: ParseCSVParam) {
  const {
    file,
    headers,
    validationSchema,
    callback,
    extraValidationContext = {},
    dynamicTyping = false,
    includeRowNos,
  } = params;
  const errors = [];

  // Converting the header names to corresponding haeder keys.
  // And checking invalid header value checking.
  const transformHeader = (header, i) => {
    const headerData = findWhere(headers, { headerName: header });

    if (isEmpty(header) || isEmpty(headerData)) {
      const errorExist = findWhere(errors, { type: ErrorType.INVALID_COLUMN });
      // Setting column name as undefined if header field as empty
      const column = !isEmpty(header) ? header : t.VALIDATION.UNDEFINED_COLUMN_NAME();
      if (!isEmpty(errorExist)) {
        errorExist.columns.push(column);
      } else {
        errors.push({
          type: ErrorType.INVALID_COLUMN,
          columns: [column],
        });
      }
      return ErrorType.INVALID_COLUMN;
    }

    return headerData.key;
  };

  // Checking validations after parsing
  const onParsingComplete = (results) => {
    // Checking the parsed data is empty or has any error on parsing
    if (isEmpty(results.data || results.errors.length > 0)) {
      return callback({
        errors: [
          {
            type: ErrorType.EMPTY_OR_INVALID_FILE,
          },
        ],
      });
    }

    // Listing the required column keys
    const requiredColumns = reduce(validationSchema.fields, (validFields: string[], field: any, key: string) => {
      if (field._exclusive.required) {
        validFields.push(key);
      }
      return validFields;
    }, []);

    // Returns error if the file has missed any required fields.
    const missedColumns = difference(requiredColumns, results.meta.fields);
    if (!isEmpty(missedColumns)) {
      const indexedHeaders = indexBy(headers, 'key');
      const missedColumnNames = missedColumns.map((col) => indexedHeaders[col].headerName);
      return callback({
        errors: [
          {
            type: ErrorType.REQUIRED_COLUMNS_MISSING,
            columns: missedColumnNames,
          },
        ],
      });
    }

    // Return the errors if already found error in headers
    if (errors.length) {
      return callback({ errors });
    }

    // Checking the file has any duplicate columns
    // Ruturns the columns numbers with this error
    const uniqueFields = new Set(results.meta.fields);
    if (uniqueFields.size !== results.meta.fields.length) {
      const duplicateColumns = results.meta.fields.filter((field) => !uniqueFields.delete(field));
      const columns = Array.from(new Set(duplicateColumns));

      return callback({
        errors: [
          {
            type: ErrorType.DUPLICATE_COLUMNS,
            columns,
          },
        ],
      });
    }

    const validRows = [];
    results.data.forEach(async (row, rNo) => {
      const rowNo = rNo + 1;
      await validationSchema.validate(row, { abortEarly: false, context: extraValidationContext })
        .then(() => {
          if (includeRowNos) {
            validRows.push({ ...row, rowNo });
          } else {
            validRows.push(row);
          }
        })
        .catch((validationErrors) => {
          validationErrors.inner.forEach((err, i) => {
            errors.push({
              type: ErrorType.VALIDATION,
              row: rowNo,
              column: results.meta.fields.indexOf(err.path) + 1,
              columnKey: err.path,
              method: err.type,
              message: err.message,
            });
          });
        });

      if (rowNo === results.data.length) {
        return onValidationComplete();
      }

      return true;
    });

    const onValidationComplete = () => {
      if (errors.length > 0) {
        return callback({
          errors,
          data: validRows,
          rawData: results.data,
          fields: results?.meta?.fields,
        });
      }

      return callback({
        errors: [],
        data: results.data,
        rawData: results.data,
        fields: results?.meta?.fields,
      });
    };

    return true;
  };

  Papa.parse(file, {
    delimiter: ',',
    header: true,
    dynamicTyping,
    complete: onParsingComplete,
    skipEmptyLines: true,
    transformHeader,
  });
}
