import { css } from '@emotion/react';
import React, { forwardRef, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { PracticeRoomTab, VideoPracticeScenario, VideoPracticeSubmission } from 'redux/schemas/models/video-practice';
import { forceTwoDigits } from 'recording/components/stopwatch';
import NvIcon from 'shared/components/nv-icon';
import { doubleSpacing, halfSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { hexToRgbaString, gray4, gray6, black, teal, warning, primary, purple, gray2, white } from 'styles/global_defaults/colors';
import { makeQueryString } from 'shared/hooks/use-query';
import { AngularContext, AngularServicesContext } from 'react-app';
import { getCurrentUser } from 'redux/selectors/users';
import ClickableContainer from 'components/clickable-container';
import { NvUserAvatar } from 'components/nv-user-avatar';
import { Button } from 'react-bootstrap';
import { showRequestReviewCTAConfig } from 'shared/utils';
import { textMediumFontSize } from 'styles/global_defaults/fonts';
import { ReviewStatus } from 'practice_room/components/shared/submission/request-review-cta';
import { useAppDispatch } from 'redux/store';
import { getComments, getPracticeSubmission, getScenario, requestReviewPracticeSubmission } from 'redux/actions/video-practice';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { RootState } from 'redux/schemas';
import NvTooltip from 'shared/components/nv-tooltip';
import { config } from '../../../../config/pendo.config.json';

/**
 * Video practice gallery card component used in mini-gallery and practice room.
 * Supports custom title prop to display over top of card
 */

type VideoPracticeGalleryCardProps = VideoPracticeSubmission & {
  header?: string;
  pendoTagName?: string;
  title?: string;
  onClick?: (submissionid: number, scenarioId: number) => void
  isFeedbackGallery?: boolean
  dataQa?: string;
  dataQaPendo?: string;
  isAudio?: boolean
  onClickReviewSubmission?: () => void
};

const VideoPracticeGalleryCard = forwardRef<HTMLInputElement, VideoPracticeGalleryCardProps>((props, ref) => {
  const {
    id,
    videoPracticeScenarioId,
    videoFile,
    user,
    completedAt,
    isTeamMember,
    featured,
    header,
    pendoTagName,
    title,
    onClick,
    hasReviewed,
    isRatedByCurrentUser,
    isFeedbackGallery,
    dataQa,
    dataQaPendo,
    isAudio,
    onClickReviewSubmission,
  } = props;

  const styles = css`
    .clickable {
      width: 240px;
      min-width: 208px;
      max-width: 240px;
      height: 240px;
      -webkit-box-shadow: 0px 10px 30px 0px ${gray6};
      box-shadow: 0px 10px 30px 0px ${gray6};
      background-color: white;
    }

    /* Make the practice-cta only visible in case  styles container is hovered */
    .practice-cta {
      z-index: 3;
      position: absolute;
      width: 100%;
      transition: opacity 0.2s linear;

      button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: ${textMediumFontSize}px;
        padding: ${halfSpacing}px 14px;
        opacity: 1;
        border-radius: 0;
        height: 40px;
      }
      .review-tag-active {
        background-color: ${warning};
        color: ${white};
        border: 0;

        &:hover {
          background-color: ${warning}e5;
        }
      }
    }

    .header {
      top: -20px;
      left: 0;
    }

    .video-length {
      color: white;
      left: 10px;
      top: 10px;
      z-index: 1;
      border-radius: 5px;
      background-color: ${hexToRgbaString(black, 0.4)};
      padding: 2px 5px;
    }

    .thumbnail-image {
      height: 50%;
      overflow: hidden;
      position: relative;

      .image {
        filter: blur(5px);
        background-image: url(${videoFile.thumbnailUrl || user.profilePicture});
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .middle-mask {
        background-color: ${black};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 70%;
      }

      .top-mask {
        background-image: linear-gradient(to right, ${purple}, ${primary});
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 30%;
      }

      .audio-waveform {
        top: 35px;

        .audio-bar {
          background-color: ${white};
          width: 2px;
          margin-right: 1px;
        }
      }
    }

    .feedback-check {
      left: 0;
      top: 0;
      z-index: 2;
      height: ${doubleSpacing}px;
      background-color: ${gray2};
      opacity: 80%;
    }

    .featured {
      background-color: ${warning};
      width: 40px;
      height: 20px;
      top: 0;
      right: 0;

      .icon-highlight {
        color: white;
      }
    }

    .gallery-card-body {
      position: absolute;
      bottom: 0;
      padding-bottom: ${standardSpacing}px;
      z-index: 0;
      top: 20%;

      .avatar {
        width: 90px;
        height: 90px;
      }

      .user-initials {
        height: 90px                                                           ;
        width: 90px;
        font-size: 45px;
        background-color: ${gray4};
        color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .rest-of-body {
        width: 100%;
        white-space: nowrap;

        .user-name {
          .card-title {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .is-team-member {
            background-color: ${hexToRgbaString(teal, 0.2)};
            border-radius: 50%;
            width: 18px;
            height: 18px;

            .icon-community {
              color: ${teal};
            }
          }
        }

        .social-row {
          display: flex;
          justify-content: center;

          .social-item {
            display: flex;
            align-items: center;

            &:not(:last-child) {
              margin-right: ${doubleSpacing}px;
            }

            .icon {
              margin-right: ${quarterSpacing}px;
            }
          }
        }
      }
    }

    .view-practice, .feedback-check-expanded {
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      visibility: hidden;
      white-space: nowrap;
      text-transform: uppercase;
    }

    .view-practice {
      top: 50%;
      border: 1px solid white;
      border-radius: 24px;
    }
    .feedback-check-expanded {
      top: ${standardSpacing}px;
    }

    &:hover {
      cursor: pointer;

      .thumbnail-image {
        height: 100%;
        transition: height .2s linear;

        .image {
          filter: blur(0);
          opacity: 70%;
        }
      }

      .video-length {
        animation: fadeOut 0.2s linear forwards;
      }

      .rest-of-body {
        opacity: 0;
      }

      .avatar, .user-initials {
        animation: fadeOut 0.2s forwards;
      }

      .feedback-check {
        animation: fadeOut 0.2s forwards;
      }

      .view-practice, .feedback-check-expanded {
        visibility: visible;
        transition: visibility .2s ease;
      }

      @keyframes fadeOut {
        to {
          opacity: 0;
        }
      }
    }
  `;

  const minutesDisplay = (forceTwoDigits(Math.floor(videoFile.length / 60)));
  const secondsDisplay = (forceTwoDigits(videoFile.length % 60));

  const [isLoadingRequestReview, setIsLoadingRequestReview] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { $state } = useContext(AngularServicesContext);
  const selectedTab = useSelector((state) => state.app.practiceRoom.params.selectedTab);
  const history = useHistory();
  const currentUser = useSelector((state) => getCurrentUser(state));
  const { injectServices } = useContext(AngularContext);
  const [_, CurrentUserManager] = injectServices(['$state', 'CurrentUserManager']);
  const practiceSubmission = useSelector((state) => state.models.practiceSubmissions[id]);
  const scenario = useSelector<RootState, VideoPracticeScenario>((state) => state.models.practiceScenarios?.[practiceSubmission?.scenario?.id]);
  const numLikes = practiceSubmission?.numLikes ?? props.numLikes;
  const numComments = practiceSubmission?.numComments ?? props.numComments;
  const numViews = practiceSubmission?.numViews ?? props.numViews;

  const scenarioId = practiceSubmission?.scenario?.id;
  const { reviewStatus, id: submissionId } = practiceSubmission ?? {};

  const viewPractice = () => {
    if (videoPracticeScenarioId) {
      // If this card is displayed inside practice room and got history, use
      // history.push. Or use $state.go to change angular state
      let selected = selectedTab ?? PracticeRoomTab.GALLERY;
      if (user?.id === currentUser.id) {
        selected = PracticeRoomTab.MY_PRACTICE;
      } else if (featured) {
        selected = PracticeRoomTab.FEATURED;
      }

      if ($state.includes('practice-room-modal')
        && history) {
        // when user click on a tile from the gallery tab of practice room
        history.push(makeQueryString({
          user: user?.id,
          submission: id,
          selected: selectedTab ?? PracticeRoomTab.GALLERY,
        }));
      } else {
        // when user click on the tile from mini gallery of a video practice activity in lecture page
        $state.go('practice-room-modal', {
          scenarioId: videoPracticeScenarioId,
          user: user?.id,
          submission: id,
          selected,
          galleryMode: !($state.current.name === 'org-level-profile-modal'
            || $state.current.name === 'org-level-profile-page'
          ),
        });
      }
    }
  };

  const dateText = moment().diff(moment(completedAt), 'days') > 30
    ? moment(completedAt).format('L') : moment(completedAt).fromNow();

  // This is a bunch of (sort of) random data used to create an audio waveform image.
  // The numbers represent the height of the bars.
  const audioWaveformData = [18, 5, 18, 50, 2, 23, 45, 6, 19, 26, 45, 33, 18, 50, 2, 25, 14, 19, 26, 45, 18, 5, 18, 50, 2, 23, 45, 6, 19, 26, 45, 33, 18, 43, 2, 25, 14, 19, 26, 45, 18, 5, 18, 9, 2, 8, 45, 6, 19, 12, 45, 33, 18, 50, 2, 25, 14, 19, 26, 45, 18, 5, 18, 50, 2, 23, 45, 26, 19, 38, 45, 33, 18, 7, 9, 25, 14, 19, 26, 45];
  const hasMentors = CurrentUserManager?.currentUserCourse?.mentors?.length > 0;
  const reviewCTAConfig = scenario?.enableRequestReview && hasMentors && scenario?.skillTaggingIds?.length > 0 && onClickReviewSubmission && currentUser.id === props.user.id && showRequestReviewCTAConfig(practiceSubmission.reviewStatus);
  const onClickRequestReview = () => {
    setIsLoadingRequestReview(true);
    dispatch(requestReviewPracticeSubmission({ submissionId, scenarioId }))
      .then(async () => {
        await dispatch(getPracticeSubmission({ scenarioId, submissionId })).then(() => {
          dispatch(
            addAlertMessage({
              type: AlertMessageType.SUCCESS,
              header: t.PRACTICE_ROOM.INSIGHTS.REQUEST_REVIEW.REQUEST_SUCCESS_TITLE(),
              message: t.PRACTICE_ROOM.INSIGHTS.REQUEST_REVIEW.REQUEST_SUCCESS_DESCRIPTION(),
            }),
          );
        });
        await dispatch(getComments({ submissionId, page: 1 }));
      })
      .finally(() => {
        setIsLoadingRequestReview(false);
      });
  };

  useEffect(() => {
    if (!scenario && practiceSubmission?.scenario?.id) {
      dispatch(getScenario({ scenarioId: practiceSubmission.scenario.id }));
    }
  }, []);

  const onClickCTA = () => {
    if (reviewStatus === ReviewStatus.NO_APPROVAL) {
      onClickRequestReview();
    }
    if (reviewStatus === ReviewStatus.REVIEWED) {
      onClickReviewSubmission();
    }
  };

  return (
    <div className='position-relative' css={styles}>
      {reviewCTAConfig && (
        <div className='practice-cta'>
          <NvTooltip
            text={reviewCTAConfig.tooltip}
            placement='top'
          >
            <Button
              variant='primary'
              size='lg'
              disabled={reviewCTAConfig.disabled || isLoadingRequestReview}
              className={`d-flex align-items-center ${reviewCTAConfig.className}`}
              onClick={onClickCTA}
              pendo-tag-name={config.pendo.practice.requestReviewsCta}
              aria-label={reviewCTAConfig.label}
            >
              <div className='icon icon-course-mentees mr-2' />
              {reviewCTAConfig.label}
            </Button>
          </NvTooltip>
        </div>
      )}
      <ClickableContainer
        ref={ref}
        onClick={() => (onClick ? onClick(id, videoPracticeScenarioId) : viewPractice())}
        pendo-tag-name={pendoTagName}
        data-qa={dataQa}
        data-qa-pendo={dataQaPendo}
        className='clickable gallery-card d-flex flex-column align-items-center position-relative'
      >

        <div className='header position-absolute label text-gray-1'>{header}</div>
        <div className='thumbnail-image w-100 position-relative'>
          {isFeedbackGallery && (hasReviewed || isRatedByCurrentUser) && (
            <React.Fragment>
              <div className='feedback-check d-flex justify-content-center align-items-center position-absolute text-white w-100'>
                <NvIcon icon='check' size='medium' />
              </div>
              <div className='feedback-check-expanded d-flex justify-content-center align-items-center position-absolute text-white w-100'>
                <NvIcon icon='check' size='smallest' />
                <div className='text-xs font-weight-bolder ml-1'>
                  {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.FEEDBACK_SUBMITTED()}
                </div>
              </div>
            </React.Fragment>
          )}
          <div className='image w-100 h-100' />
          <div className='middle-mask position-absolute' />
          <div className='top-mask position-absolute' />
          {isAudio && (
            <div className='audio-waveform position-absolute d-flex align-items-center'>
              {audioWaveformData.map((height) => (
                <div className='audio-bar' style={{ height }} />
              ))}
            </div>
          )}
          <div className='video-length d-flex align-items-center position-absolute'>
            <NvIcon icon={`${isAudio ? 'audio-practice-on-cards' : 'video-practice-on-cards'} mr-1`} size='xss-smallest' />
            <div className='text-xs ml-1'>{minutesDisplay}:{secondsDisplay}</div>
          </div>
        </div>
        {featured && (
          <div className='featured d-flex align-items-center justify-content-center position-absolute'>
            <NvIcon icon='highlight' size='xss-smallest' />
          </div>
        )}
        <div className='gallery-card-body d-flex flex-column align-items-center w-100'>
          <NvUserAvatar
            borderType='round'
            size='xl'
            user={user}
            tooltipEnabled={false}
          />
          <div className='rest-of-body d-flex flex-column align-items-center'>
            <div className='px-4 user-name d-flex align-items-center justify-content-center w-100'>
              <div className='card-title'>
                {title || user.fullName}
              </div>
              {isTeamMember && (
                <div className='is-team-member d-flex align-items-center justify-content-center ml-1 flex-shrink-0'>
                  <NvIcon icon='community' size='xss-smallest' />
                </div>
              )}
            </div>
            <div className='page-title-xxs text-gray-2 mb-2'>{dateText}</div>
            <div className='social-row d-flex justify-content-between'>
              <SocialItem name='likes' count={numLikes} icon='like' />
              <SocialItem name='comments' count={numComments} icon='comments' />
              <SocialItem name='views' count={numViews} icon='view' />
            </div>
          </div>
        </div>
        <div
          className='view-practice p-2 d-flex align-items-center position-absolute text-white'
          pendo-tag-name={config.pendo.practice.openPracticeGalleryCard}
        >
          <NvIcon icon={`${isAudio ? 'audio-practice-on-cards' : 'video-practice-on-cards'} mr-1`} size='xss-smallest' />
          <div className='text-xs mr-2 font-weight-bold'>{minutesDisplay}:{secondsDisplay}</div>
          <div className='text-xs'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.VIEW_PRACTICE()}</div>
        </div>
      </ClickableContainer>
    </div>
  );
});

const SocialItem = ({ count, name, icon }) => (
  <React.Fragment>
    {count > 0 && (
      <span className={`${name} social-item`}>
        <NvIcon size='xss-smallest' icon={icon} />
        {count}
      </span>
    )}
  </React.Fragment>
);

export default VideoPracticeGalleryCard;

