import { css } from '@emotion/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import NvDropdown, { NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import NvIcon from 'shared/components/nv-icon';
import { getFlatCourseAliases } from 'redux/selectors/course';
import NvFilePicker from 'shared/components/nv-filepicker';
import { S3NameSpaces } from 'shared/services/s3-upload-factory';
import useUploadFile from 'shared/hooks/use-upload-file';
import { useAppDispatch } from 'redux/store';
import { NLecturePage } from 'redux/schemas/models/lecture-page';
import { setLectureHeaderIsSaving, updateLecturePage } from 'redux/actions/lecture-pages';
import { hideAddUI } from 'lecture_pages/templates/components/nv-add-component';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import ColorPickerPopover, { SetBackgroundColorTarget } from 'components/color-picker-popover';
import LectureHeaderColorPicker from './lecture-header-color-picker';
import { LecturePageEditContentParams, useLecturePageMainPanel } from '../lecture-page-content';

type Props = {
  visible: boolean,
} & LecturePageEditContentParams;

export const LectureContentEditHeaderButton = (props: Props) => {
  const {
    visible,
    catalogId,
    lecturePage,
    setIsHoverOnHeader,
    saveLecturePageData,
  } = props;

  const lecturePageMainPanel = useLecturePageMainPanel();

  const dispatch = useAppDispatch();
  const params = useLecturePageParams();

  const courseAliases = useSelector(state => getFlatCourseAliases(state, catalogId));
  const { showTitle } = lecturePage;
  const [isEditingColor, setIsEditingColor] = useState(false);

  const { uploadFiles, isUploading, filesUploading } = useUploadFile();

  const styles = css`
    display: ${visible ? 'block' : 'none'};

    .icon-edit {
      font-size: 14px;
    }

    .color-picker-panel {
      position: absolute;
      left: 0;
      top: 0;
    }
  `;

  const onImagePicked = useCallback(async (files: File[]) => {
    // Display the 'Saving... Please wait' UI
    dispatch(setLectureHeaderIsSaving(true));

    const [novoEdFile] = await uploadFiles(files, S3NameSpaces.COVER_IMAGES);

    saveLecturePageData({
      coverImage: {
        fileName: novoEdFile.name,
        fileSize: novoEdFile.size,
        fileType: novoEdFile.type,
        uniqueId: novoEdFile.uniqueId,
      },
      viewOptions: {
        ...lecturePage.viewOptions,
        // Delete the background color set since this is mutually exclusive with background photos
        backgroundColor: null,
      },
      releaseDate: props.lecturePage.releaseDate,
    }).then((response: any) => {
      // TODO: This probably deserves more investigation
      /* This mimics what the Angularjs app does when uploading a new photo: waits until the initial
      photo upload request finishes, and then fires a 2nd request to update the lecture page data with the
      same response object as returned in the first request. We do not get the coverImageUrl after the first
      requrest because the save to S3 happens asynchronously (I believe), whereas it seems to always be available
      after this second request.

      Let's test & confirm this, but surely there's a better way? */
      const lp = response.payload as NLecturePage;
      dispatch(updateLecturePage({
        catalogId: response.meta.arg.catalogId,
        lecturePageId: lp.id,
        lecturePage: lp,
      })).finally(() => {
        dispatch(setLectureHeaderIsSaving(false));
      });
    }).catch(() => {
      dispatch(setLectureHeaderIsSaving(false));
    });
  }, [dispatch, props.lecturePage.releaseDate, props.lecturePage.viewOptions, saveLecturePageData, uploadFiles]);

  /** Provided seperate header image picking function for collection because for course two update calls are dispatching
   *  but collection two update call are not needed.
   */
  const onImagePickedForCollection = useCallback(async (files: File[]) => {
    dispatch(setLectureHeaderIsSaving(true));
    const [novoEdFile] = await uploadFiles(files, S3NameSpaces.COVER_IMAGES);
    dispatch(updateLecturePage({
      ...params,
      lecturePage: {
        coverImageFileName: novoEdFile.name,
        coverImageFileSize: novoEdFile.size,
        coverImageFileType: novoEdFile.type,
        coverImageUniqueId: novoEdFile.uniqueId,
        viewOptions: {
          ...props.lecturePage.viewOptions,
          // Delete the background color set since this is mutually exclusive with background photos
          backgroundColor: null,
        },
      } })).then(() => {
      dispatch(setLectureHeaderIsSaving(false));
    });
  }, [dispatch, params, props.lecturePage.viewOptions, uploadFiles]);

  useEffect(() => {
    if (!visible) {
      setIsEditingColor(false);
    }
  }, [visible]);

  const getItems = useCallback((newShowTitle: boolean): NvDropdownOption[] => {
    if (newShowTitle) {
      const items = [
        { type: 'text', text: t.LECTURE_PAGES.ADMIN.HEADER.EDIT_HEADER.COLOR(), callback: () => setIsEditingColor(true) },
        {
          type: 'text',
          text: t.LECTURE_PAGES.ADMIN.HEADER.EDIT_HEADER.HIDE_HEADER(courseAliases),
          callback: () => { saveLecturePageData({ showTitle: false }); setIsHoverOnHeader(false); },
        },
      ];
      const courseItems = [];

      courseItems.push(
        {
          type: 'custom',
          customItem: (
            <NvFilePicker
              accept={['image/*']}
              onChange={params.currentCourseIsCollection ? (files) => onImagePickedForCollection(files) : (files) => onImagePicked(files)}
            >
              <div className='bs4-dropdown-item'>{ t.LECTURE_PAGES.ADMIN.HEADER.EDIT_HEADER.IMAGE() }</div>
            </NvFilePicker>
          ),
        },
      );

      return [
        ...items,
        ...courseItems,
      ];
    }
    return [
      {
        type: 'text',
        text: t.LECTURE_PAGES.ADMIN.HEADER.EDIT_HEADER.DISPLAY_HEADER(courseAliases),
        callback: () => saveLecturePageData({ showTitle: true }),
      },
    ];
  }, [courseAliases, onImagePicked, onImagePickedForCollection, params.currentCourseIsCollection, saveLecturePageData, setIsHoverOnHeader]);

  const [items, setItems] = useState<NvDropdownOption[]>(getItems(showTitle));

  useEffect(() => {
    setItems(getItems(showTitle));
  }, [showTitle]);

  const ref = useRef(null);

  return (
    <div css={styles} ref={ref}>
      {isEditingColor ? (
        <ColorPickerPopover<{
          backgroundColor: string;
        }>
          placement='bottom'
          container={lecturePageMainPanel}
          colorValues={{
            backgroundColor: (() => {
              const initialColor = lecturePage.viewOptions.backgroundColor;
              if (!initialColor) {
                return '#ffffff';
              }

              return initialColor.startsWith('#') ? initialColor : `#${initialColor}`;
            })(),
          }}
          onChange={(section, color) => {
            saveLecturePageData({
              // Reset the cover image whenever saving a background color since these two modes are mutually exclusive
              coverImageUrl: null,
              coverImageFileName: null,
              coverImageFileSize: null,
              coverImageFileType: null,
              coverImageFileUniqueId: null,
              viewOptions: {
                ...lecturePage.viewOptions,
                backgroundColor: color,
              },
            });
          }}
          sections={[{
            name: 'backgroundColor',
            title: t.LECTURE_PAGES.COMPONENTS.HEADER.BACKGROUND_COLOR(),
          }]}
          show={isEditingColor}
          onToggle={() => setIsEditingColor(false)}
        >
          <SetBackgroundColorTarget />
        </ColorPickerPopover>
      ) : (
        <NvDropdown
          buttonStyle={NvDropdownButtonStyle.CUSTOM}
          items={items}
          customTarget={() => (
            <div className='d-flex align-items-center ml-2'>
              <NvIcon icon='edit' size='sm' />
              <div className='ml-2'>{t.LECTURE_PAGES.ADMIN.HEADER.EDIT_HEADER.BUTTON()}</div>
            </div>
          )}
          // Disabling when the upload occurs causes the dropdown to close. Otherwise the NvFilePicker seems
          // to steal the click event and causes it to remain open
          disabled={isUploading || isEditingColor}
          offset={10}
          minWidth={220}
          onToggle={(showList) => {
            if (showList) {
              hideAddUI(dispatch);
            }
          }}
        />
      )}
    </div>
  );
};

export default LectureContentEditHeaderButton;
