import { css } from '@emotion/react';
import React, { useContext } from 'react';
import { AngularServicesContext } from 'react-app';
import { handheld } from 'styles/global_defaults/media-queries';
import { flyoutWidth, standardSpacing } from 'styles/global_defaults/scaffolding';
import FlyoutHeader from './flyout-header';

const FlyoutContainer = ({ children, title }) => {
  const { FlyoutModalManager } = useContext(AngularServicesContext);

  const styles = css`
    display: flex;
    flex-direction: column;

    width: ${flyoutWidth}px;

    ${handheld(css`
      width: 100vw;
    `)};

    .content {
      flex-grow: 1;

      .loading-wrapper {
        padding: ${standardSpacing}px;
      }
    }
  `;

  return (
    <div css={styles}>
      <FlyoutHeader title={title} onClose={FlyoutModalManager?.closeFlyout} />
      <div className='content'>
        {children}
      </div>
    </div>
  );
};

export default FlyoutContainer;
