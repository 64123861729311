import React from 'react';
import t from 'react-translate';

// Angular
import { AngularContext, AngularServicesContext } from 'react-app';

// Form
import * as yup from 'yup';
import { ref } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

// Redux
import { useAppDispatch } from 'redux/store';
import { doSignUp } from 'redux/actions/sign-up';
import { SignUpParams } from 'redux/schemas/api/sign-up';

// Components
import { Button } from 'react-bootstrap';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvRecaptcha from './nv-recaptcha';
import OrDivider from '../sign-in/nv-or-divider';
import OauthProviders from '../sign-in/nv-oauth-providers';

type FormType = {
  firstName: string,
  lastName: string,
  email: string,
  confirmEmail: string,
  password: string,
  recaptcha: string,
};

const NvSignUp = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const { injectServices } = React.useContext(AngularContext);
  const { $state, CurrentUserManager } = React.useContext(AngularServicesContext);

  const [UserAuthentication] = injectServices(['UserAuthentication']);

  const validationSchemaRef = React.useRef(yup.object().shape({
    firstName: yup.string()
      .min(1, t.VALIDATION.REQUIRED()),
    lastName: yup.string()
      .min(1, t.VALIDATION.REQUIRED()),
    email: yup.string()
      .min(1, t.VALIDATION.REQUIRED())
      .email(t.VALIDATION.EMAIL()),
    confirmEmail: yup.string()
      .min(1, t.VALIDATION.REQUIRED())
      .email(t.VALIDATION.EMAIL())
      .when('email', {
        is: (val: string) => val !== '',
        then: schema => schema.required().oneOf([ref('email')], t.VALIDATION.EMAIL_MATCH()),
      }),
    password: yup.string()
      .min(8, t.VALIDATION.REQUIRED())
      .matches(
        /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{3,}/,
        t.USERS.REGISTRATION.PASSWORD_EXTRA_INFO(),
      ),
    recaptcha: yup.string()
      .min(1, t.VALIDATION.REQUIRED()),
  }));

  const methods = useForm<FormType>({
    mode: 'all',
    shouldUnregister: true,
    resolver: yupResolver(validationSchemaRef.current),
  });

  const { handleSubmit, formState } = methods;

  const onSubmit = (fields: FormType) => {
    const { catalogId } = $state;
    const { firstName, lastName, email, password, recaptcha } = fields;
    const user: SignUpParams['user'] = { firstName, lastName, email, password };
    const mentor = $state.mentor === 'true';
    const params: SignUpParams = { recaptchaResponse: recaptcha, user, catalogId, mentor };
    setIsLoading(true);
    dispatch(doSignUp(params)).then((action) => {
      const { payload } = action;
      CurrentUserManager.setNewUser(payload);
      UserAuthentication.redirectAfterAuthentication();
    }).catch((error) => {
      const { code } = error;
      if (code === 'login.errors.user_already_exists') {
        const stateParams = { ...$state.params };
        stateParams.alreadyJoined = true;
        $state.go('users.authentications.sign-in', stateParams);
      }
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <React.Fragment>
      {/* Styles come from a SCSS file: app/styles/modules/users/_authentications.scss */}
      <div className='authentications-overlay'>
        <div className='authentications-body'>
          <div className='authentications-header-text'>
            {t.USERS.REGISTRATION.SIGN_UP()}
          </div>
          <div className='registration-form'>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <SignUpFields />
                <div className='label d-flex justify-content-start gray-2 mb-5'>{t.USERS.REGISTRATION.PASSWORD_EXTRA_INFO()}</div>
                <NvRecaptcha name='recaptcha' className='mb-2' />
                <Button
                  className='mb-2 mt-1'
                  disabled={!formState.isValid || isLoading}
                  type='submit'
                  data-qa='sign-up'
                >
                  {t.USERS.REGISTRATION.SIGN_UP()}
                </Button>
                <TermsOfService className='mb-5 pb-1' />
              </form>
            </FormProvider>
            <OrDivider className='pb-3' />
            <SignInExistingAccount />
            <OauthProviders />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const SignInExistingAccount = () => {
  const { $state } = React.useContext(AngularServicesContext);

  return (
    <a
      className='bs4-btn bs4-btn-secondary w-100 mb-5'
      href={$state.href('users.authentications.sign-in')}
      data-qa='sign-in-link'
    >
      {t.USERS.REGISTRATION.SIGN_IN_WITH_EXISTING_ACCOUNT()}
    </a>
  );
};

const SignUpFields = () => (
  <>
    <NvTextInput
      required
      withForm
      withLabel
      name='firstName'
      className='mb-3'
      placeholder={t.USERS.REGISTRATION.FIRST_NAME()}
      data-qa='first-name'
    />
    <NvTextInput
      required
      withForm
      withLabel
      name='lastName'
      className='mb-3'
      placeholder={t.USERS.REGISTRATION.LAST_NAME()}
      data-qa='last-name'
    />
    <NvTextInput
      required
      withForm
      withLabel
      name='email'
      className='mb-3'
      placeholder={t.USERS.REGISTRATION.EMAIL_ADDRESS()}
      data-qa='email'
    />
    <NvTextInput
      required
      withForm
      withLabel
      name='confirmEmail'
      autoComplete='off'
      className='mb-3'
      placeholder={t.USERS.REGISTRATION.CONFIRM_EMAIL_ADDRESS()}
      data-qa='confirm-email'
    />
    <NvTextInput
      required
      withForm
      withLabel
      name='password'
      type='password'
      className='mb-1'
      placeholder={t.USERS.REGISTRATION.PASSWORD()}
      data-qa='password'
    />
  </>
);

type TermsOfServiceProps = {
  className?: string;
};

const TermsOfService = ({ className = '' }: TermsOfServiceProps) => (
  <div className={`terms-of-service-link ${className}`}>
    {t.USERS.REGISTRATION.BY_SIGNING_UP_YOU_AGREE_TO()}
    <a
      className='blue-link'
      href='https://novoed.com/terms-of-service'
      target='_blank'
      rel='noreferrer'
      data-qa='terms-of-service'
    >
      {t.USERS.REGISTRATION.TERMS_OF_SERVICE()}
    </a>
    {t.JOIN.TWO.AND()}
    <a
      className='blue-link'
      href='https://novoed.com/privacy-policy/'
      target='_blank'
      rel='noreferrer'
      data-qa='privacy-policy'
    >
      {t.LHS.ACCOUNT_MENU.PRIVACY_POLICY.TITLE()}
    </a>
  </div>
);

export default NvSignUp;
