/* @ngInject */
export default function OrgL3HeaderCtrl(
  $state,
  InstitutionsManager,
) {
  const vm = this;

  vm.$state = $state;
  vm.InstitutionsManager = InstitutionsManager;
}
