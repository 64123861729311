import { checkLectureComponentProgress, markLectureComponentAsDone, updateActivityProgress } from 'redux/actions/external-tool';
import { createReducer } from '@reduxjs/toolkit';
import { initialRootState } from '.';


export default createReducer(initialRootState, builder => {
  builder
    .addCase(markLectureComponentAsDone.fulfilled, (state, action) => {
      const externalTool = { ...action.meta.arg.externalTool, ...action.payload.result };
      state.models.externalTools[action.meta.arg.id] = { ...state.models.externalTools[action.meta.arg.id], ...externalTool };
      action.meta.arg.cb?.(state.models.externalTools[action.meta.arg.id]);
    })
    .addCase(checkLectureComponentProgress.fulfilled, (state, action) => {
      if (action.payload.result) {
        const externalTool = { ...action.meta.arg.externalTool, ...action.payload.result, progress: action.payload.result.status };
        state.models.externalTools[action.meta.arg.id] = { ...state.models.externalTools[action.meta.arg.id], ...externalTool };
        const isCheckingProgress = true;
        action.meta.arg.cb?.(state.models.externalTools[action.meta.arg.id], isCheckingProgress);
      }
    })
    .addCase(updateActivityProgress, (state, action) => {
      state.models.externalTools[action.payload.id].progress = action.payload.progress;
    });
});

