import { css } from '@emotion/react';
import React, { useEffect, useRef, useState } from 'react';
import uuid from 'react-uuid';
import { Controller } from 'react-hook-form';
import { gray2, yellow } from 'styles/global_defaults/colors';
import { debounce } from 'underscore';
import NvIcon from './nv-icon';
import NvTooltip from './nv-tooltip';

const styles = css`
  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .user-rating {
    pointer-events: none
  }

  .icon-highlight {
    color: ${yellow};
  }

  .icon-half-star {
    color: ${yellow};
  }

  .icon-hovered {
    color: ${yellow};
    opacity: .3;
  }

  .icon-points {
    color: ${gray2};
  }
`;

type StarRatingProps = {
  name?: string
  readonly?: boolean
  withForm?: boolean
  ratedValue?: string | number
  onChange?: (e: any) => void
  onHover?: (e: any) => void
  starSize: string
  tooltipText?: string
  tooltipIndex?: number
};

const NvStarRating = ({
  name,
  readonly,
  withForm,
  ratedValue,
  onChange: onPropsChange,
  onHover,
  starSize,
  tooltipText,
  tooltipIndex,
}: StarRatingProps) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const btnRef = useRef<HTMLButtonElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleRateChange = (val: number) => {
    const selectedRating = rating === val ? 0 : val;
    setRating(selectedRating);
    setHover(0);
    onPropsChange?.(selectedRating);
  };
  const value = Number(ratedValue);

  const handleMouseEvent = debounce((index: number) => {
    setHover(index);
    onHover?.(index);
    btnRef.current?.focus();
  }, 50);

  /**
   * The mouse leave not triggering as expected when moving the mouse quickly
   * over the element. When the mouse leaves a wrapper element, the hover state
   * is cleared if it has value
   */
  useEffect(() => {
    setTimeout(() => {
      if (hover && !wrapperRef?.current?.matches(':hover')) {
        setHover(0);
        onHover?.(0);
      }
    }, 50);
  }, [hover, onHover]);

  return (
    <div
      className='d-flex'
      css={styles}
      ref={wrapperRef}
    >
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          readonly ? (
            <button
              type='button'
              className='d-flex user-rating'
              key={uuid()}
            >
              {!Number.isInteger(value) && Math.ceil(value) === index ? (
                <NvIcon
                  icon='half-star'
                  size={starSize}
                />
              ) : (
                <NvIcon
                  icon={index <= value ? 'highlight' : 'points'}
                  size={starSize}
                />
              )}
            </button>
          ) : (
            <NvTooltip
              placement='top'
              enabled={!!tooltipText && tooltipIndex === index}
              text={tooltipText}
              key={uuid()}
            >
              <div>
                <React.Fragment>
                  {withForm ? (
                    <Controller
                      name={name}
                      render={({ field: { onChange } }) => {
                        const handleChange = (selectedValue) => {
                          const selectedRating = rating === selectedValue ? 0 : selectedValue;
                          setRating(selectedRating);
                          setHover(0);
                          onChange(selectedRating);
                          onPropsChange?.(selectedRating);
                        };

                        return (
                          <button
                            type='button'
                            onClick={() => handleChange(index)}
                            onMouseEnter={() => handleMouseEvent(index)}
                            onMouseLeave={() => handleMouseEvent(0)}
                            ref={btnRef}
                          >
                            <NvIcon
                              icon={index <= (hover || rating) ? 'highlight' : 'points'}
                              size={starSize}
                              className={`${index <= rating ? 'icon-rated' : ''} ${index <= hover ? 'icon-hovered' : ''}`}
                            />
                          </button>
                        );
                      }}
                    />
                  ) : (
                    <button
                      type='button'
                      onClick={() => handleRateChange(index)}
                      onMouseEnter={() => handleMouseEvent(index)}
                      onMouseLeave={() => handleMouseEvent(0)}
                      ref={btnRef}
                    >
                      <NvIcon
                        icon={index <= (hover || rating) ? 'highlight' : 'points'}
                        size={starSize}
                        className={`${index <= rating ? 'icon-rated' : ''} ${index <= hover ? 'icon-hovered' : ''}`}
                      />
                    </button>
                  )}
                </React.Fragment>
              </div>
            </NvTooltip>
          )
        );
      })}
    </div>
  );
};

export default NvStarRating;
