import { css } from '@emotion/react';
import { kebabCase } from 'lodash';
import { black } from 'styles/global_defaults/colors';
import {
  semiBoldFontWeight,
  textSmallFontSize,
} from 'styles/global_defaults/fonts';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { LhsMenuItem, LhsMenuItemProps } from './lhs-menu-item';

export type LhsSectionMenuItem = LhsMenuItemProps & { show: boolean };

export type LhsSectionProps = {
  id: string;
  borderTop: boolean;
  menuItems: Array<LhsSectionMenuItem>;
};

export const LhsMenuSection = ({
  id,
  borderTop,
  menuItems,
}: LhsSectionProps) => {
  const stylesBase = css`
    font-size: ${textSmallFontSize}px;
    font-weight: ${semiBoldFontWeight};

    &.borderTop {
      padding: ${halfSpacing}px 0;
      border-top: 1px solid ${black};
    }
  `;

  return (
    <div
      key={id}
      css={stylesBase}
      className={`${borderTop ? 'borderTop' : ''}`}
    >
      {menuItems.map(({ label, icon, highlighted, expanded, onClick, dataQa }) => (
        <LhsMenuItem
          key={kebabCase(`lhs-item-${label}`)}
          label={label}
          icon={icon}
          highlighted={highlighted}
          expanded={expanded}
          onClick={onClick}
          dataQa={dataQa}
        />
      ))}
    </div>
  );
};

export default LhsMenuSection;
