import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { getBrand } from 'redux/selectors/institutions';
import { boldFontWeight, openSansCondensed, textExtraLargeFontSize, textLargeFontSize } from 'styles/global_defaults/fonts';
import { handheld } from 'styles/global_defaults/media-queries';
import { largeSpacing, standardSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';

const ModalHeader = () => {
  const { name, logo, brandColor, brandBarFontColor } = useSelector(getBrand);

  const styles = css`
    width: 100%;
    padding: ${standardSpacing}px;
    background: ${brandColor};
    display: flex;
    flex-direction: 'row';
    align-items: center;
    gap: ${standardSpacing}px;

    ${handheld(css`
      padding-left: ${threeQuartersSpacing}px;
    `)};

    img {
      width: auto;
      height: ${largeSpacing}px;
    }

    .title {
      color: ${brandBarFontColor};
      font-family: ${openSansCondensed};
      font-size: ${textLargeFontSize * 2}px;
      font-weight: ${boldFontWeight};

      ${handheld(css`
        font-size: ${textExtraLargeFontSize}px;
      `)};
    }
  `;

  return (
    <section css={styles}>
      {logo && <img src={logo} alt={`${name} logo`} />}
      <span className='title'>
        {t.LEARNING_CATALOG.TITLE()}
      </span>
    </section>
  );
};

export default ModalHeader;
