/* @ngInject */
export default function TeamModelService(

  Upload,
  MembershipRequestModel,
  TeamMembershipModel,
  TeamMembershipsResource,
  TeamsResources,
  humps,
  _,
  TodoBaseModel,
  moment,
  $state,
  ReportModel,
) {
  const TeamModel = function (attributes) {
    const _this = this;

    _.extend(_this, attributes);

    _this.submit = submit;
    _this.createOrUpdateTeamWithPicture = createOrUpdateTeamWithPicture;
    _this.newMembershipRequest = newMembershipRequest;
    _this.withdrawMembershipRequest = withdrawMembershipRequest;
    _this.requestMembers = requestMembers;
    _this.addMembers = addMembers;
    _this.removeTeamMember = removeTeamMember;
    _this.resetCeo = resetCeo;
    _this.makeTeamMemberAdmin = makeTeamMemberAdmin;
    _this.removeTeamMemberAdmin = removeTeamMemberAdmin;
    _this.adminSendMessageToTeam = adminSendMessageToTeam;
    _this.deleteTeam = deleteTeam;
    _this.isPersisted = isPersisted;
    _this.createdBy = createdBy;
    _this.lastActivityInWords = lastActivityInWords;
    _this.enableProgressDashboard = enableProgressDashboard;
    _this.disableProgressDashboard = disableProgressDashboard;

    _this.saving = false;
    _this.requestingMembers = false;

    _this.getTodos = getTodos;
    _this.addMeeting = addMeeting;
    _this.removeMeeting = removeMeeting;
    _this.getDrafts = getDrafts;
    _this.getSubmissions = getSubmissions;
    _this.addMember = addMember;
    _this.removeMember = removeMember;
    _this.updateMemberByUserId = updateMemberByUserId;
    _this.copyAttributes = copyAttributes;

    initialize();

    function initialize() {
      preprocess();
      initializeModels();

      sortMembers();
      sortDrafts();
    }

    function preprocess() {
      if (_this.teamSet?.profileQuestions) {
        _.each(_this.teamSet.profileQuestions, (profileQuestion) => {
          const profileInfo = _.find(_this.profileInfos, (info) => info.profileQuestionId === profileQuestion.id);
          profileQuestion.answer = profileInfo ? profileInfo.answer : null;
        });
      }
    }

    function sort(listName, attributeName) {
      if (_this[listName]) {
        _this[listName].sort((item1, item2) => {
          if (item1[attributeName] === item2[attributeName]) {
            return 0;
          } if (item1[attributeName] == null) {
            return 1;
          } if (item2[attributeName] == null) {
            return -1;
          } if (moment(item1[attributeName]).isBefore(moment(item2[attributeName]))) {
            return 1;
          }
          return -1;
        });
      }
    }

    function sortMembers() {
      sort('teamMembers', 'visitedWorkspaceAt');
    }

    function sortDrafts() {
      sort('drafts', 'lastSavedAt');
    }

    function initializeModels() {
      if (_this.currentAccountMembershipRequest) {
        _this.currentAccountMembershipRequest = new MembershipRequestModel(_this.currentAccountMembershipRequest);
      }

      if (_this.currentAccountTeamMember) {
        _this.currentAccountTeamMember = new TeamMembershipModel(_this.currentAccountTeamMember);
      }

      _this.teamMembers = _.map(_this.teamMembers, (teamMember) => new TeamMembershipModel(teamMember));

      if (_this.submissionsViewableByClass) {
        _.each(_this.submissionsViewableByClass, ReportModel.normalizeSubmissionData);
      }
    }

    function submit() {
      if (_this.isPersisted()) {
        return _this.createOrUpdateTeamWithPicture(`/${$state.params.catalogId}/teams/${_this.id}`, 'PUT');
      }
      return _this.createOrUpdateTeamWithPicture(`/${$state.params.catalogId}/teams`, 'POST');
    }

    function createOrUpdateTeamWithPicture(requestUrl, requestType) {
      _this.saving = true;

      return Upload.upload({
        url: requestUrl,
        method: requestType,
        fields: processFormData(),
      }).then((response) => {
        _.extend(_this, response.data.result);
        preprocess();
        _this.saving = false;
      }, (response) => {
        _this.errors = [{ message: 'There was a problem saving your team. Try again later!' }];
        _this.saving = false;
      }, (evt) => {

      });
    }

    function newMembershipRequest(user) {
      _this.currentAccountMembershipRequest = new MembershipRequestModel({ teamId: _this.id, userId: user.id });
    }

    function withdrawMembershipRequest() {
      _this.currentAccountMembershipRequest.withdraw().then(() => {
        _this.currentAccountMembershipRequest = null;
      });
    }

    function requestMembers(catalogId) {
      _this.requestingMembers = true;

      return TeamMembershipsResource.query(
        { catalogId, teamId: _this.id },
      ).$promise.then((resource) => {
        _this.requestingMembers = false;
        _this.teamMembers = _.map(resource.result, (teamMember) => new TeamMembershipModel(teamMember));

        sortMembers();

        return _this.teamMembers;
      });
    }

    function addMembers(catalogId, members) {
      return TeamsResources.addMembers(
        { catalogId, teamId: _this.id },
        { teamMembers: members },
      ).$promise.then(() => {
        _this.requestMembers(catalogId);
        sortMembers();
      });
    }

    function removeTeamMember(catalogId, member) {
      return member.fire({
        catalogId,
        teamId: _this.id,
      }).then(() => {
        if (_this.ceo && member.user.id === _this.ceo.id) {
          _this.resetCeo();
        }
        const memberIndex = _.indexOf(_this.teamMembers, member);
        _this.teamMembers.splice(memberIndex, 1);
        _this.numApprovedMembers -= 1;
      });
    }

    function resetCeo() {
      _this.ceo = null;
    }

    function makeTeamMemberAdmin(catalogId, member) {
      return member.makeAdmin({
        catalogId,
        teamId: _this.id,
        isTeam: _this.teamSet.isTeam,
      }).then(() => {
        if (_this.teamSet.isTeam) {
          _.each(_this.teamMembers, (teamMember) => {
            if (teamMember.id !== member.id) {
              teamMember.isAdmin = false;
            }
          });

          _this.currentAccountTeamMember.isAdmin = (_this.currentAccountTeamMember?.id === member.id);
        }
      });
    }

    function removeTeamMemberAdmin(teamMemberId) {
      const index = _.findIndex(_this.teamMembers, (teamMember) => teamMember.id === teamMemberId);

      if (index > -1) {
        _this.teamMembers[index].isAdmin = false;
      }

      if (_this.currentAccountTeamMember.id === teamMemberId) {
        _this.currentAccountTeamMember.isAdmin = false;
      }
    }

    function adminSendMessageToTeam(catalogId, message) {
      return TeamsResources.adminSendMessageToTeam(
        { catalogId, id: _this.id },
        { message },
      ).$promise;
    }

    function isPersisted() {
      return !!_this.id;
    }

    function createdBy(user) {
      return _this.ceo?.id === user.id;
    }

    function lastActivityInWords() {
      // TODO: workspace
      return 'Activity adding in workspace project';
    }

    function deleteTeam(catalogId) {
      return TeamsResources.delete({ catalogId, id: _this.id }).$promise;
    }

    function enableProgressDashboard(catalogId) {
      return TeamsResources.enableProgressDashboard({ catalogId, id: _this.id }).$promise;
    }

    function disableProgressDashboard(catalogId) {
      return TeamsResources.disableProgressDashboard({ catalogId, id: _this.id }).$promise;
    }

    function processFormData() {
      let requestFields = { team: _.pick(_this, ['name', 'description', 'profileInfos']) };
      if (!requestFields.team.description) {
        requestFields.team.description = '';
      }
      requestFields.team.teamSetId = _this.teamSet.id;

      requestFields = humps.decamelizeKeys(requestFields);
      requestFields.team.picture = _this.picture;
      requestFields.team.privacy = _this.isOpen ? 'open' : 'closed';

      if (_this.teamSet?.profileQuestions) {
        requestFields.team_profile_info = {};
        _.each(_this.teamSet.profileQuestions, (profileQuestion) => {
          if (profileQuestion.answer) {
            requestFields.team_profile_info[profileQuestion.id] = profileQuestion.answer;
          }
        });
      }

      return requestFields;
    }

    /* workspace */
    function addMember(teamMemberId) {
      return TeamsResources.getMember({ catalogId: _this.catalogId, teamMemberId }).$promise
        .then((response) => {
          if (!_.findWhere(_this.teamMembers, { id: teamMemberId })) {
            const teamMember = new TeamMembershipModel(response.result);
            _this.teamMembers.push(teamMember);
            _this.numApprovedMembers += 1;

            sortMembers();
          }
        });
    }

    function removeMember(teamMemberId) {
      const index = _.findIndex(_this.teamMembers, (teamMember) => teamMember.id === teamMemberId);

      if (index > -1) {
        _this.teamMembers.splice(index, 1);
        _this.numApprovedMembers -= 1;
      }

      if (_this.currentAccountTeamMember.id === teamMemberId) {
        _this.currentAccountTeamMember = null;
      }
    }

    function updateMemberByUserId(userId) {
      return TeamsResources.getMemberByUserId({
        catalogId: _this.courseCatalogId, teamId: _this.id, userId, attrs: 'list',
      }).$promise
        .then((response) => {
          const teamMember = new TeamMembershipModel(response.result);
          _this.teamMembers = _.without(_this.teamMembers, _.find(_this.teamMembers, (teamMemberObject) => teamMemberObject.user.id === userId));
          _this.teamMembers.unshift(teamMember);
        });
    }

    function getTodos() {
      return TeamsResources.getTodos({ catalogId: _this.courseCatalogId, teamId: _this.id }).$promise
        .then((response) => {
          _this.todos = _.map(response.result, (todo) => new TodoBaseModel(todo));
        });
    }

    function addMeeting(meeting) {
      const isNewMeeting = !_.find(_this.todos, (todo) => todo.meetingTime && todo.id === meeting.id);

      if (isNewMeeting) {
        _this.todos.unshift(new TodoBaseModel(meeting));
      }
    }

    function removeMeeting(meetingId) {
      const index = _.findIndex(_this.todos, (todo) => todo.isMeeting() && todo.id === meetingId);

      if (index > -1) {
        _this.todos.splice(index, 1);
      }
    }

    function getDrafts() {
      return TeamsResources.getDrafts({ catalogId: _this.courseCatalogId, teamId: _this.id }).$promise
        .then((response) => {
          _this.drafts = response.result;

          sortDrafts();
        });
    }

    function getSubmissions() {
      return TeamsResources.getSubmissions({ catalogId: _this.courseCatalogId, teamId: _this.id }).$promise
        .then((response) => {
          _this.submissions = _.map(response.result, (submission) => new ReportModel(_.extend(ReportModel.normalizeSubmissionData(submission), { catalogId: submission.exercise?.catalogId })));
        });
    }

    function copyAttributes(team) {
      _.each(team, (value, key) => {
        if (!_.isFunction(value)) {
          _this[key] = value;
        }
      });
    }
  };

  TeamModel.get = function (catalogId, teamId, verbose) {
    return TeamsResources.get({ catalogId, id: teamId, verbose: (verbose === true) }).$promise
      .then((response) => new TeamModel(_.extend(response.result, { catalogId, submissions: null })));
  };


  return TeamModel;
}
