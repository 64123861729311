/* @ngInject */
export default function NvTodoRow(
  CurrentUserManager,
  TimezonesService,
  moment,
) {
  return {
    scope: {
      todo: '=',
      hideCourseThumbnail: '=?',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
      let deadlineMoment;
      const currentMoment = moment();

      if (vm.todo.isMeeting()) {
        vm.effectiveDeadline = vm.todo.meetingTime;
        const timeZoneString = TimezonesService.correctSpelling(CurrentUserManager.user.timeZone || moment.tz.guess());
        const timeZoneKey = TimezonesService.timezones.find((t) => t.longName === timeZoneString)?.translationKey;
        vm.currentTimeZone = timeZoneKey ? `TIMEZONE.${timeZoneKey}` : timeZoneString;
      } else if (vm.todo.isSurveyDeliverable() || vm.todo.isQuizDeliverable() || vm.todo.isTimedQuizDeliverable() || vm.todo.isPollDeliverable()) {
        // Use exprirationDate instead of deadline
        vm.effectiveDeadline = vm.todo.expirationDate;
      } else if (vm.todo.extendedDeadline) {
        vm.effectiveDeadline = vm.todo.extendedDeadline;
      } else {
        vm.effectiveDeadline = vm.todo.deadline;
      }


      if (vm.effectiveDeadline) {
        deadlineMoment = moment(vm.effectiveDeadline);
        vm.isDeadlineToday = deadlineMoment.isSame(currentMoment, 'days');
        vm.isDueSoon = deadlineMoment.isAfter(currentMoment) && deadlineMoment.isBefore(currentMoment.clone().add(7, 'days').endOf('day'));
      }
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'dashboard/templates/nv-todo-row.html',
  };
}
