import { css, SerializedStyles } from '@emotion/react';
import React, { useState, useContext, useRef, useEffect } from 'react';
import { NvTableRowProps, NvTableCellProps } from 'shared/components/nv-responsive-table';
import { createGridStyles, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { Institution } from 'redux/schemas/models/institution';
import { Course } from 'redux/schemas/models/course';
import { NvPopover } from 'shared/components/nv-popover';
import { NvTeamAvatar } from 'components/nv-team-avatar';
import moment from 'moment';
import t from 'react-translate';
import { black, gray5, primary, success, gray6 } from 'styles/global_defaults/colors';
import { Team } from 'redux/schemas/models/team';
import { NvModal, ModalType } from 'shared/components/nv-modal';
import { NvTooltip } from 'shared/components/nv-tooltip';
import { AngularServicesContext } from 'react-app';
import { embedAngularTemplate } from 'shared/embed-angular';
import RecentPostsModal from './recent-posts-modal';
import TeamRowOptions from './team-row-options';
import { config } from '../../../config/pendo.config.json';

export type TeamRowExtraProps = {
  institution: Institution,
  course: Course,
  isTeam: boolean,
  hasAssignments: boolean,
};

export type TeamCellProps = NvTableCellProps<Team, {}, TeamRowExtraProps> & {
  team: Team
  /* TODO: This might need to be pulled out somewhere */
  optionsCellRef?: React.MutableRefObject<HTMLDivElement>,
};

const commonStyles = css`
  display: flex;
  align-items: center;

  .icon-team, .icon-conversations, .icon-gallery {
    margin-right: ${quarterSpacing}px;
  }

  /* TODO: Put somewhere else? */
  height: 70px;

  .hover-box {
    display: flex;
    position: relative;
    align-items: center;
    pointer-events: all;
    height: 100%;
    width: 80px;
    padding-left: 10px;

    &:hover {
      background-color: ${gray6};
    }

    & > .position-absolute {
      left: ${standardSpacing}px;
    }
  }
`;

const nameStyles = css`
  overflow: hidden;
  ${commonStyles};
`;

const lastActivityStyles = css`
  .action {
    color: ${black};
    &:hover {
      color: ${primary};
      cursor: pointer;
    }
  }
  ${commonStyles};
`;

const lastAdminVisitedStyles = css`
  ${commonStyles};
`;

const recentPostsStyles = css`
  .clickable:hover {
    cursor: pointer;
  }
  ${commonStyles};
`;

const recentActiveMembersStyles = css`
  a {
    color: inherit;
  }
  ${commonStyles};
`;

const teamAssignmentsStyles = css`
  .clickable:hover {
    cursor: pointer;
  }
  .not-clickable:hover {
    cursor: default;
  }
  ${commonStyles};
`;

const optionsStyles = css`
  ${commonStyles};
`;

const formatDate = (date: string | Date): string => (moment().diff(date) > 0 ? moment(date).fromNow() : moment().fromNow());

const getActivity = (activity: string, assignmentAlias?: string, teamAlias?: string): string => {
  switch (activity) {
    case 'submitted_assignment':
      return t.TEAMS.PROFILE.ACTIVITY.RECENT_ACTIVITY_SUBMITTED_ASSIGNMENT({ assignmentAlias, teamAlias });
    case 'created_new_post':
      return t.TEAMS.PROFILE.ACTIVITY.RECENT_ACTIVITY_CREATED_NEW_POST();
    case 'shared_submission':
      return t.TEAMS.PROFILE.ACTIVITY.RECENT_ACTIVITY_SHARED_SUBMISSION();
    case 'created_meeting':
      return t.TEAMS.PROFILE.ACTIVITY.RECENT_ACTIVITY_CREATED_MEETING();
    case 'uploaded_document':
      return t.TEAMS.PROFILE.ACTIVITY.RECENT_ACTIVITY_UPLOADED_DOCUMENT();
    case 'commented_submission':
      return t.TEAMS.PROFILE.ACTIVITY.RECENT_ACTIVITY_COMMENTED_SUBMISSION();
    case 'made_comment':
      return t.TEAMS.PROFILE.ACTIVITY.RECENT_ACTIVITY_MADE_COMMENT();
    case 'wrote_to_each_other':
      return t.TEAMS.PROFILE.ACTIVITY.RECENT_ACTIVITY_WROTE_TO_EACH_OTHER();
    default:
      return '';
  }
};

/**
 * NovoEd-customized wrapper around a react-bootstrap Dropdown component.
 */
export const TeamRow = (props: NvTableRowProps<Team, {}, TeamRowExtraProps>) => {
  const cells: [(props: TeamCellProps) => JSX.Element, SerializedStyles][] = [
    [NameCell, nameStyles],
    [LastActivityCell, lastActivityStyles],
    [LastAdminVisitedCell, lastAdminVisitedStyles],
    [RecentPostsCell, recentPostsStyles],
    [RecentActiveMembersCell, recentActiveMembersStyles],
  ];
  if (props.extraProps.hasAssignments && props.extraProps.isTeam) {
    cells.push([TeamAssignmentsCell, teamAssignmentsStyles]);
  }
  cells.push([OptionsCell, optionsStyles]);

  return (
    <React.Fragment>
      {
        cells.map((cell, i) => {
          const cellProps: TeamCellProps = {
            ...props,
            team: props.data,
            // TODO: This is all duplicated from course-row.tsx
            reactKey: `${props.rowIndex}-${i + 1}`,
            serializedStyles: cell[1],
            divProps: {
              style: createGridStyles(i + 1, props.rowIndex, i + 2, props.rowIndex + 1),
            },
            // disabled: isDisabled,
            // optionsCellRef: optionCellRef,
          };
          const CellComponent = cell[0];
          // eslint-disable-next-line react/no-array-index-key
          return <CellComponent {...cellProps} key={i} />;
        })
      }
    </React.Fragment>
  );
};

// TODO: This evolved into basically just being a ternary. Consider removing
const colorClass = (count: number, normalColor: string, zeroColor: string): string => (count === 0 ? zeroColor : normalColor);

// TODO: We currently are required to add these css class names ending in -cell for things like borders to be applied
// from NvResponsive table
const NameCell = (props: TeamCellProps) => (
  <div className='name-cell font-weight-bolder text-medium' css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
    <div className='w-100'>
      <NvTooltip text={t.TEAM_FACILITATION.CLICK_TO_VISIT_WORKSPACE(props.extraProps.isTeam ? props.extraProps.course.teamName.singularizedTitleized : props.extraProps.course.groupName.singularizedTitleized)}>
        <div className='mw-100 d-inline-flex'>
          {/* display: inline-flex is needed for IE */}
          <NvTeamAvatar course={props.extraProps.course} team={props.team} institution={props.extraProps.institution} />
        </div>
      </NvTooltip>
    </div>
  </div>
);

const LastActivityCell = (props: TeamCellProps) => {
  const [showPostModal, setShowPostModal] = useState(false);
  let cellContent = null;
  if (!props.team.mostRecentActivity?.happenedAt) {
    cellContent = <div className='text-danger font-weight-bold'>{t.USER_MANAGEMENT.NEVER_ACTIVE()}</div>;
  } else if (props.team.mostRecentActivity.action === 'submitted_assignment') {
    const { $state } = useContext(AngularServicesContext);
    cellContent = (
      <div>
        <NvTooltip text={t.TEAM_FACILITATION.LAST_ACTIVTY.CLICK_TO_VIEW_SUBMISSION()}>
          <div
            onClick={() => $state.go('individual-submission-modal', {
              catalogId: props.extraProps.course.catalogId,
              reportId: props.team.mostRecentActivity.activityId,
            })}
            className='font-weight-bold action'
          >
            {getActivity(
              props.team.mostRecentActivity.action,
              props.extraProps.course.assignmentName.downcasedSingularized,
              props.extraProps.course.teamName.downcasedSingularized,
            )}
          </div>
        </NvTooltip>
        <div className='text-gray-2'>{formatDate(props.team.mostRecentActivity.happenedAt)}</div>
      </div>
    );
  } else {
    let postId = props.team.mostRecentActivity.activityId;
    let commentId;
    let replyId;
    if (props.team.mostRecentActivity.activityType === 'Post') { // This is a value from the backend, so 'Post' actually refers to a comment
      ({ ownerId: postId, activityId: commentId } = props.team.mostRecentActivity);
    } else if (props.team.mostRecentActivity.activityType === 'Comment') { // This is a value from the backend, so 'Comment' actually refers to a reply
      ({ ownerOwnerId: postId, ownerId: commentId, activityId: replyId } = props.team.mostRecentActivity);
    }
    cellContent = (
      <div>
        <NvModal
          type={ModalType.FULL}
          header={t.TEAM_FACILITATION.RECENT_POSTS.MODAL_TITLE(props.extraProps.isTeam ? props.extraProps.course.teamName.singularizedTitleized : props.extraProps.course.groupName.singularizedTitleized)}
          body={<RecentPostsModal teamId={props.team.id} postId={postId} commentId={commentId} replyId={replyId} />}
          show={showPostModal}
          enforceFocus={false}
          onClose={() => setShowPostModal(false)}
        />
        <NvTooltip text={t.TEAM_FACILITATION.LAST_ACTIVTY.CLICK_TO_VIEW_POST()}>
          <div
            className='font-weight-bold action'
            onClick={() => setShowPostModal(true)}
          >
            {getActivity(
              props.team.mostRecentActivity.action,
              props.extraProps.course.assignmentName.downcasedSingularized,
              props.extraProps.course.teamName.downcasedSingularized,
            )}
          </div>
        </NvTooltip>
        <div className='text-gray-2'>{formatDate(props.team.mostRecentActivity.happenedAt)}</div>
      </div>
    );
  }
  return (
    <div className='last-activity-cell' css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      {cellContent}
    </div>
  );
};

const LastAdminVisitedCell = (props: TeamCellProps) => (
  <div className='last-admin-visited-cell' css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
    <div>
      <div className='text-black font-weight-bold'>{props.team.lastAdminVisited?.fullName}</div>
      <div className='text-gray-2'>{props.team.lastAdminVisited && formatDate(props.team.lastAdminVisited.happenedAt)}</div>
    </div>
  </div>
);

const RecentPostsCell = (props: TeamCellProps) => {
  const [showPostModal, setShowPostModal] = useState(false);

  return (
    <div className='recent-posts-cell' css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      {props.team.topicsCount ? (
        <React.Fragment>
          <NvModal
            type={ModalType.FULL}
            header={t.TEAM_FACILITATION.RECENT_POSTS.MODAL_TITLE(props.extraProps.isTeam ? props.extraProps.course.teamName.singularizedTitleized : props.extraProps.course.groupName.singularizedTitleized)}
            body={<RecentPostsModal teamId={props.team.id} />}
            show={showPostModal}
            enforceFocus={false}
            onClose={() => setShowPostModal(false)}
          />
          <div className='h-100 clickable' onClick={() => setShowPostModal(true)}>
            <NvPopover placement='top' className='h-100' content={RecentPostsPopover(props)} showOnHover>
              <div className='hover-box'>
                <React.Fragment>
                  <div className='icon icon-smallest icon-conversations' />
                  <div>
                    <span className={`font-weight-bolder text-medium clickable ${colorClass(props.team.numPostInThePastSevenDay, 'text-primary', 'text-danger')}`}>
                      {props.team.numPostInThePastSevenDay}
                    </span>
                    /
                    {props.team.topicsCount}
                  </div>
                </React.Fragment>
              </div>
            </NvPopover>
          </div>
        </React.Fragment>
      ) : ''}
    </div>
  );
};

const RecentPostsPopover = (props: TeamCellProps) => (
  <div>
    <div className='mb-3'>{t.TEAM_FACILITATION.RECENT_POSTS.TOTAL_POSTS(props.team.topicsCount)}</div>
    <div className='mb-3'>{t.TEAM_FACILITATION.RECENT_POSTS.POSTED_IN_PAST_7_DAYS(props.team.numPostInThePastSevenDay)}</div>
    <div className='text-gray-4'>{t.TEAM_FACILITATION.RECENT_POSTS.CLICK_TO_VIEW()}</div>
  </div>
);

const RecentActiveMembersCell = (props: TeamCellProps) => {
  const { $state } = useContext(AngularServicesContext);
  return (
    <div className='recent-active-members-cell' css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      {props.team.totalMembers && props.team.mostRecentActivity?.happenedAt ? (
        <NvPopover placement='top' className='h-100' content={RecentActiveMembersPopover(props)} showOnHover>
          <a href={$state?.href('team-workspace', { catalogId: props.extraProps.course.catalogId, teamId: props.team.id })}>
            <div className='hover-box'>
              <div className='position-absolute'>
                <div className='d-flex align-items-center'>
                  <div className='icon icon-smallest icon-team' />
                  <div>
                    <span className={`font-weight-bolder text-medium ${colorClass(props.team.totalRecentActiveMembers, 'text-primary', 'text-danger')}`}>
                      {props.team.totalRecentActiveMembers}
                    </span>
                    /
                    {props.team.totalMembers}
                  </div>
                </div>
                <MiniProgressBar count={props.team.totalRecentActiveMembers} total={props.team.totalMembers} color={primary} />
              </div>
            </div>
          </a>
        </NvPopover>
      ) : ''}
    </div>
  );
};

const RecentActiveMembersPopover = (props: TeamCellProps) => (
  <div>
    <div className='mb-3'>{t.TEAM_FACILITATION.RECENT_ACTIVE_MEMBERS.TOTAL_MEMBERS(props.team.totalMembers)}</div>
    <div className='mb-3'>{t.TEAM_FACILITATION.RECENT_ACTIVE_MEMBERS.ACTIVE_IN_PAST_7_DAYS(props.team.totalRecentActiveMembers)}</div>
    <div className='text-gray-4'>{t.TEAM_FACILITATION.RECENT_ACTIVE_MEMBERS.CLICK_TO_VIEW(props.extraProps.isTeam ? props.extraProps.course.teamName.downcasedSingularized : props.extraProps.course.groupName.downcasedSingularized)}</div>
  </div>
);

const TeamAssignmentsCell = (props: TeamCellProps) => {
  const submissionCount = props.team.assignments?.reduce((a, c) => a + (c.lastSubmittedAt !== null ? 1 : 0), 0);
  const [showAssignments, setShowAssignments] = useState(false);
  const postsRef = useRef(null);
  const angularServices = useContext(AngularServicesContext);

  useEffect(() => {
    if (showAssignments) {
      angularServices.$scope.assignments = props.team.assignments.filter(assignment => assignment.id);
      angularServices.$scope.hideRef = () => setShowAssignments(false);
      embedAngularTemplate('team_workspace/templates/team-recent-assignments.html', postsRef, angularServices);
    }
  }, [showAssignments]);

  return (
    <div className='team-assignments-cell' css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      {props.team.assignments && props.team.mostRecentActivity?.happenedAt ? (
        <NvPopover placement='top' preventOverflow flip className='h-100' content={TeamAssignmentsPopover({ ...props, submissionCount })} showOnHover>
          <div onClick={() => submissionCount && setShowAssignments(true)} className={`hover-box ${submissionCount ? 'clickable' : 'not-clickable'}`}>
            <div className='position-absolute'>
              <div className='d-flex align-items-center'>
                {showAssignments ? <div ref={postsRef} /> : null}
                <div className='icon icon-smallest icon-gallery' />
                <div>
                  <span className={`font-weight-bolder text-medium ${colorClass(submissionCount, 'text-success', 'text-black')}`}>
                    {submissionCount}
                  </span>
                  /
                  {props.team.assignments.length}
                </div>
              </div>
              <MiniProgressBar count={submissionCount} total={props.team.assignments.length} color={success} />
            </div>
          </div>
        </NvPopover>
      ) : ''}
    </div>
  );
};

const TeamAssignmentsPopover = (props: TeamCellProps & { submissionCount: number }) => {
  const assignmentRows = [];

  for (let i = 0; i < Math.min(props.team.assignments.length, 3); i += 1) {
    const submittedOn = moment(props.team.assignments[i].lastSubmittedAt);
    assignmentRows.push(
      <div className='mb-3' key={`ar-${i}`}>
        <div>{props.team.assignments[i].title}</div>
        <div className={submittedOn.isValid() ? '' : 'text-gray-4'}>
          { submittedOn.isValid() ? t.TEAM_FACILITATION.TEAM_ASSIGNMENTS.SUBMITTED_ON(submittedOn.format('MM/DD/YYYY')) : t.TEAM_FACILITATION.TEAM_ASSIGNMENTS.NOT_SUBMITTED() }
        </div>
      </div>,
    );
  }

  return (
    <div>
      {assignmentRows}
      {props.submissionCount ? <div className='text-gray-4' key='click-to-view'>{t.TEAM_FACILITATION.TEAM_ASSIGNMENTS.CLICK_TO_VIEW(props.submissionCount)}</div> : null}
    </div>
  );
};

const MiniProgressBar = (props: { count: number, total: number, color: string }) => {
  const styles = css`
    /* We use abs positioning to prevent adjusting the location of the icion & count / total text that sits above this */
    position: absolute;
    display: flex;
    left: -10px;
    right: -10px;
    bottom: -7px;

    .bar {
      height: 3px;
    }

    .bkg-bar {
      background-color: ${gray5};
    }
  `;

  const maxWidth = 60;
  const fillWidth = (props.count / props.total) * maxWidth;

  return (
    <div css={styles}>
      <div className='bar' style={{ backgroundColor: props.color, width: `${fillWidth}px` }} />
      <div className='bar bkg-bar' style={{ width: `${maxWidth - fillWidth}px` }} />
    </div>
  );
};

const OptionsCell = (props: TeamCellProps) => (
  <TeamRowOptions cellProps={props} key={props.reactKey} dataQa={config.pendo.manageOneTeamGroup.options.button} dataQaId={`team-${props.data.id}`} />
);

export default TeamRow;
