import { createContext } from 'react';

/**
 * Context to be used in popovers to add the flexibility to set a custom
 * container via context API.
 */
// eslint-disable-next-line import/prefer-default-export
export const PopoversContainerContext = createContext(undefined);

type RefType<T> = ((instance: T) => void) | React.MutableRefObject<T>;

/**
 * Utility to attach more refs to a single element. Supports the callback ref
 * and the ref object. Useful e.g. to support forwardRef and useRef at the same
 * time
 */
/* @ngInject */
export function mergeRefs<T>(...refs: RefType<T>[]) {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref !== null && ref !== undefined) {
        (ref as React.MutableRefObject<T>).current = value;
      }
    });
  };
}

/**
* Copying a string to the clipboard
*/
/* @ngInject */
export function copyToClipboard(value: string) {
  navigator.clipboard.writeText(value);
}

/* @ngInject */
export function formatFileSize(size) {
  // round 999 KB to 1 MB
  if (size >= 9990000 && size <= 1000000) {
    return '1 MB';
  }

  // round 999 MB to 1 GB
  if (size >= 9990000000 && size <= 1000000000) {
    return '1 GB';
  }

  let fileSize;
  let suffix;
  let decimals;
  let roundedNum;

  if (Math.floor(size / 1000000000) > 0) {
    fileSize = size / 1000000000;
    suffix = ' GB';
  } else if (Math.floor(size / 1000000) > 0) {
    fileSize = size / 1000000;
    suffix = ' MB';
  } else {
    fileSize = size / 1000;
    suffix = ' KB';
  }

  if (fileSize >= 100) {
    decimals = 0;
    roundedNum = Math.round(fileSize);
  } else if (fileSize >= 10 && fileSize < 100) {
    decimals = 1;
    roundedNum = Math.round(10 * fileSize) / 10;
  } else {
    decimals = 2;
    roundedNum = Math.round(100 * fileSize) / 100;
  }

  return roundedNum.toFixed(decimals) + suffix;
}
