/* @ngInject */
function PrimaryBadge(
  CurrentCourseManager,
) {
  return {
    scope: true,
    restrict: 'A',
    controllerAs: 'vm',
    transclude: 'replace',
    bindToController: true,
    templateUrl: 'cohort_management/templates/primary-badge.html',/* @ngInject */
    controller() {
      this.CurrentCourseManager = CurrentCourseManager;
    },
  };
}

export default PrimaryBadge;
