import { css } from '@emotion/react';
import { useExitLecturePageMode, useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { hideAddUI as hideNewComponentAddUI } from 'lecture_pages/templates/components/nv-add-component';
import React, { useContext } from 'react';
import { AngularServicesContext } from 'react-app';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { resetLectureComponentReorder, updateLectureComponentOrder } from 'redux/actions/lecture-components';
import { setLecturePageModeIsSwitching } from 'redux/actions/lecture-pages';
import { RootState } from 'redux/schemas';
import { getCurrentCourse, getFlatCourseAliases } from 'redux/selectors/course';
import { useAppDispatch } from 'redux/store';
import { hexToRgbaString } from 'styles/global_defaults/colors';
import { lectureTopPanelZIndex } from 'styles/global_defaults/scaffolding';
import { LecturePageMode } from '.';

/** The transparent black bar panel that shows buttons for saving changes and exiting the Reorder and
 * Exit lecture page modes. Does not display in View mode. */
export const LecturePageTopPanel = () => {
  const styles = css`
    z-index: ${lectureTopPanelZIndex};
    position: absolute;
    width: 100%;
    height: 60px;
    top: -60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${hexToRgbaString('#000000', 0.5)};
  `;

  const params = useLecturePageParams();

  const { $state } = useContext(AngularServicesContext);
  const dispatch = useAppDispatch();
  const currentCourse = useSelector(state => getCurrentCourse(state));
  const lecturePage = useSelector(state => state.models.lecturePages[params.lecturePageId]);
  const preventPageNavigation = useSelector((state: RootState) => state.app.lecturePage.preventPageNavigation);
  const isModeSwitching = useSelector((state: RootState) => state.app.lecturePage.isModeSwitching);

  const courseAliases = useSelector(state => getFlatCourseAliases(state, params.catalogId));

  const exitMode = useExitLecturePageMode();

  // TODO: Should this logic be added to the exitMode hook directly?
  const doExitMode = async (saveReorder: boolean = false) => {
    if (params.mode === LecturePageMode.REORDER) {
      if (saveReorder) {
        // hideNewComponentAddUI(dispatch);
        // Save component reorderings if necessary
        return dispatch(updateLectureComponentOrder({
          catalogId: params.catalogId,
          lecturePageId: lecturePage.id,
          lectureComponents: lecturePage.lectureComponents,
        })).then(() => exitMode());
      }

      // hideNewComponentAddUI(dispatch);
      exitMode();
      dispatch(setLecturePageModeIsSwitching(false));
      return null;
    }

    // hideNewComponentAddUI(dispatch);
    exitMode();

    return null;
  };

  const exitToPreviousMode = (saveReorder: boolean = false) => {
    dispatch(setLecturePageModeIsSwitching(true));
    doExitMode(saveReorder).then(() => {
      dispatch(setLecturePageModeIsSwitching(false));
    });
  };

  /** Navigate to the course outline (course home page) in edit mode */
  const outlineHref = $state.href(
    currentCourse.isProgram ? 'program-timelines-edit' : 'course-home-edit',
    { catalogId: params.catalogId },
  );

  return (
    <div css={styles}>
      {(params.mode === LecturePageMode.EDIT
        || params.mode === LecturePageMode.LINKED_EDIT) && (
        <React.Fragment>
          {/* TODO: Add navigation to Course Outline Edit (Angularjs page) */}
          <Button
            variant='secondary'
            className='bs4-night mr-2'
            href={outlineHref}
            data-qa='go-to-course-outline-edit-button'
          >
            {t.LECTURE_PAGES.GO_TO_OUTLINE(courseAliases)}
          </Button>

          <Button
            variant='primary'
            onClick={() => exitToPreviousMode()}
            data-qa='exit-edit-mode-button'
          >
            {t.LECTURE_PAGES.ADMIN.TOP_HEADER.EXIT_EDIT()}
          </Button>
        </React.Fragment>
      )}
      {(params.mode === LecturePageMode.RESTRICTED_EDIT) && (
        <Button
          variant='primary'
          onClick={() => exitToPreviousMode()}
          data-qa='exit-admin-mode-button'
        >
          {t.LECTURE_PAGES.ADMIN.TOP_HEADER.EXIT_ADMIN()}
        </Button>
      )}

      {params.mode === LecturePageMode.REORDER && (
        <React.Fragment>
          <Button
            variant='secondary'
            className='bs4-night mr-2'
            onClick={() => exitToPreviousMode(false)}
            data-qa='cancel-reorder-mode-button'
          >
            {t.FORM.CANCEL()}
          </Button>
          <Button
            variant='primary'
            disabled={!preventPageNavigation || isModeSwitching}
            onClick={() => exitToPreviousMode(true)}
            data-qa='exit-reorder-mode-button'
          >
            {t.LECTURE_PAGES.ADMIN.TOP_HEADER.EXIT_REORDER()}
          </Button>
        </React.Fragment>
      )}
    </div>
  );
};

export default LecturePageTopPanel;
