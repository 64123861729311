import React, { useCallback, useContext } from 'react';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import { AngularServicesContext } from 'react-app';

import { getRoleAliases } from 'redux/selectors/institutions';
import { getMentorDetails } from 'redux/selectors/org-mentors';

import { useAppDispatch } from 'redux/store';
import { addInstitutionMentee, searchOrganizationUsers, deleteInstitutionMentee } from 'redux/actions/org-mentors';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';

import { AlertMessageType } from 'redux/schemas/app/alert-message';

import NvUserAvatar from 'components/nv-user-avatar';
import NvIcon from 'shared/components/nv-icon';

import UserListItem, { UserItemProps } from './user-list-item';
import UserAddAndSearch, { UserAddProps, ManualAddFormProps } from './user-add-and-search';

export enum MenteeModalTypes {
  ADD = 'add',
  VIEW = 'view',
}

interface AddViewMenteeModalProps {
  mentorId: number,
  type: MenteeModalTypes,
  changeModalType: (type: MenteeModalTypes) => void,
}

const getInitials = (FirstName: string, LastName: string) => `${FirstName?.[0]?.toUpperCase() ?? ''}${LastName?.[0]?.toUpperCase() ?? ''}`;

const AddViewMenteeModal = (props: AddViewMenteeModalProps) => {
  const dispatch = useAppDispatch();
  const currentInstitutionId = useSelector((state) => state.app.currentInstitutionId);
  const mentor = useSelector((state) => getMentorDetails(state, props.mentorId));
  const roleAliases = useSelector(getRoleAliases);
  const { $state } = useContext(AngularServicesContext) || {};

  const onAddNewMentee = useCallback((user: UserAddProps): Promise<any> => new Promise((resolve) => {
    dispatch(addInstitutionMentee({
      data: {
        user_id: mentor.user.id,
        overwrite: false,
        mentees: [
          {
            firstName: (user as ManualAddFormProps)?.firstName,
            lastName: (user as ManualAddFormProps)?.lastName,
            email: user.email,
          },
        ],
      },
      institutionId: currentInstitutionId,
    })).then((response: any) => {
      if (response.error) {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.FORM.ERROR_SOMETHING_WRONG(),
        }));
      } else {
        if (response?.payload?.result?.created?.length === 1) {
          dispatch(addAlertMessage({
            type: AlertMessageType.SUCCESS,
            header: t.FORM.SUCCESS_BANG(),
            message: t.INSTITUTIONS.ORG_MENTORS.ADD_MENTEE_SUCCESS({
              name: response.payload.result.created[0]?.mentee?.fullName || '',
              menteeAlias: roleAliases.mentee.downcasedSingularized,
            }),
          }));
        } else if (response?.payload?.result?.existing?.length === 1) {
          dispatch(addAlertMessage({
            type: AlertMessageType.WARNING,
            message: t.INSTITUTIONS.ORG_MENTORS.ADD_MENTEE_ALREADY_EXISTS({
              name: response.payload.result.existing[0]?.mentee?.fullName || '',
              menteeAlias: roleAliases.mentee.downcasedSingularized,
            }),
          }));
        } else {
          dispatch(addAlertMessage({
            type: AlertMessageType.ERROR,
            header: t.FORM.OOPS(),
            message: t.FORM.ERROR_SOMETHING_WRONG(),
          }));
        }
        resolve(user);
      }
    });
  }), [currentInstitutionId, dispatch, mentor.user.id, roleAliases]);

  const onSearchNonMentors = useCallback((query: string, pageNo: number, perPage: number): Promise<UserItemProps[]> => new Promise((resolve) => {
    dispatch(searchOrganizationUsers({
      page: pageNo,
      pageSize: perPage,
      queryTerm: query,
      notAssignedTo: mentor.user.id,
      institutionLearners: true,
    })).then((response) => {
      resolve(response.payload.result.users);
    });
  }), [dispatch, mentor.user.id]);

  const deleteMentee = (item) => new Promise((resolve) => {
    dispatch(openConfirmationDialog({
      title: t.INSTITUTIONS.ORG_MENTORS.REMOVE_MENTEE_TITLE(
        { MenteeTitleAlias: roleAliases.mentee.singularizedTitleized },
      ),
      bodyText: t.INSTITUTIONS.ORG_MENTORS.REMOVE_MENTEE_DESC(
        `${item.mentee.firstName} ${item.mentee.lastName}`,
        `${mentor.user.firstName} ${mentor.user.lastName}`,
      ),
      confirmText: t.FORM.YES_SURE(),
      cancelText: t.FORM.CANCEL(),
      icon: 'warning',
      onConfirm: () => {
        onDelete(item);
        resolve(true);
      },
      onCancel: () => {
        resolve(true);
      },
    }));
  });

  const onDelete = (item) => {
    dispatch(deleteInstitutionMentee({
      institutionId: currentInstitutionId,
      institutionMentoringId: item.id,
      institutionMentorId: item.institutionMentor.id,
    }));
  };

  return (
    <div className='py-2 px-6'>
      <div className='d-flex flex-column align-items-center'>
        <NvUserAvatar
          borderType='round'
          size='lg'
          user={{
            ...mentor.user,
            fullName: `${mentor.user.firstName} ${mentor.user.lastName}`,
            roles: null,
            initials: getInitials(mentor.user.firstName, mentor.user.lastName),
            userNameForURL: `${mentor.user.firstName} ${mentor.user.lastName}`,
          }}
          directToOrgLevelProfile
          openInNewTab
        />
        <span className='py-1 font-weight-bolder'>
          <a
            href={$state?.href('org-level-profile-modal', { userId: mentor.user.id })}
            target='_blank'
            rel='noreferrer'
          >
            {`${mentor.user.firstName} ${mentor.user.lastName}`}
          </a>
        </span>
        <button
          type='button'
          className={`border-0 bg-transparent font-weight-bold ${mentor.mentees.length > 0 ? 'color-primary' : 'gray-3'}`}
          onClick={() => props.changeModalType(MenteeModalTypes.VIEW)}
        >
          {`${mentor.mentees.length} ${mentor.mentees.length !== 1 ? roleAliases.mentee.capitalizedPluralized : roleAliases.mentee.capitalizedSingularized}`}
        </button>
        { props.type === MenteeModalTypes.VIEW ? (
          <button
            type='button'
            className='d-flex align-items-center border-0 bg-transparent color-primary py-4'
            onClick={() => props.changeModalType(MenteeModalTypes.ADD)}
          >
            <NvIcon size='smallest' icon='add' className='pr-1 d-inline-block ' />
            {t.INSTITUTIONS.ORG_MENTORS.ADD_MENTEE({ MenteeAlias: roleAliases.mentee.capitalizedPluralized })}
          </button>
        ) : '' }
      </div>
      { props.type === MenteeModalTypes.ADD ? (
        <UserAddAndSearch
          manualAddLabel={t.INSTITUTIONS.ORG_MENTORS.MANUALLY_ADD_MENTEE()}
          searchLabel={t.INSTITUTIONS.ORG_MENTORS.MANUALLY_ADD_SEARCH_LABEL()}
          searchPlaceholder={t.INSTITUTIONS.ORG_MENTORS.SEARCH_PLACEHOLDER()}
          usersFoundTitle={t.INSTITUTIONS.ORG_MENTORS.FOUND_USERS_WHILE_SEARCH()}
          onAdd={onAddNewMentee}
          onSearch={onSearchNonMentors}
        />
      ) : (
        <>
          {mentor.mentees.map((item) => (
            <UserListItem
              key={item.id}
              user={{
                ...item.mentee,
                email: item.mentee.email,
              }}
              actionTooltip={t.FORM.DELETE()}
              actionIcon='trash'
              onAction={() => deleteMentee(item)}
            />
          ))}
          { mentor.mentees.length > 0
            && <div className='d-flex align-items-center gray-2 pt-2 text-small'>{t.SEARCH.ALL_LOADED()}</div>}
        </>
      ) }
    </div>
  );
};

export default AddViewMenteeModal;
