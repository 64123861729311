export default {
  bindings: {
  },
  controller: function ctrl(
    CurrentUserManager,
    TeamWorkspaceManager,
    AlertMessages,
  ) {
'ngInject';
    const _this = this;

    this.$onInit = function () {
      this.CurrentUserManager = CurrentUserManager;
      this.currentTeamCourse = this.CurrentUserManager.getCurrentCourse();
      this.editMode = false;
      this.submitting = false;
    };

    this.isNewPostAvailable = function () {
      return !this.editMode && !this.currentTeamCourse.inArchiveMode;
    };

    this.createWorkspacePost = function ($event) {
      _this.submitting = true;
      TeamWorkspaceManager.createTextPost($event.workspacePost, $event.mentionedIds).then(() => {
        _this.editMode = false;
        _this.submitting = false;
      }).catch(
        (error) => {
          AlertMessages.error('', 'FORM.ERROR');
        },
      );
    };
  },
  controllerAs: 'vm',
  templateUrl: 'team_workspace/templates/team-workspace-new-post.html',
};
