import React,
{ useState, useRef, MutableRefObject, KeyboardEvent, MouseEvent, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import t from 'react-translate';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Button } from 'react-bootstrap';

import { searchHasMore } from 'redux/selectors/org-mentors';
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';
import NvIcon from 'shared/components/nv-icon';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import UserListItem, { UserItemProps } from './user-list-item';

export interface ManualAddFormProps {
  id: number,
  firstName: string,
  lastName: string,
  email: string,
}

interface SearchAndAddFormProps {
  id: number,
  email: string,
}

export type UserAddProps = ManualAddFormProps | SearchAndAddFormProps;

type UserAddAndSearchProps = {
  manualAddLabel: string,
  searchLabel: string,
  searchPlaceholder: string,
  usersFoundTitle: string,
  onAdd: (param: ManualAddFormProps | SearchAndAddFormProps) => Promise<ManualAddFormProps>,
  onSearch: (searchTerm: string, pageNo?: number, perPage?: number) => Promise<UserItemProps[]>,
};

const UserAddAndSearch = ({
  manualAddLabel,
  searchLabel,
  searchPlaceholder,
  usersFoundTitle,
  onAdd,
  onSearch,
}: UserAddAndSearchProps) => {
  const [searchKey, setSearchKey] = useState('');
  const [manuallyAdding, setManuallyAdding] = useState(false);
  const [searchedList, setSearchedList] = useState<UserItemProps[]>([]);
  const [searching, setSearching] = useState(false);
  const [searchListLoaded, setSearchListLoaded] = useState(false);
  const searchRef = useRef<HTMLInputElement | HTMLTextAreaElement>();

  const hasMore = useSelector((state) => searchHasMore(state));
  const [pageNo, setPageNo] = useState(1);

  const modalBody = document.getElementsByClassName('bs4-modal-body')[0] as HTMLDivElement;
  const PER_PAGE = 30;
  const SCROLL_PADDING = 20;
  const isScrolledToBottom = useInfiniteScroll(
    modalBody,
    SCROLL_PADDING,
  );

  const validationSchema = yup.object().shape({
    firstName: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .max(255, t.VALIDATION.MAX_LENGTH('255')),
    lastName: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .max(255, t.VALIDATION.MAX_LENGTH('255')),
    email: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .email(),
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset, formState } = methods;

  // For infinite scrolling
  useEffect(() => {
    if (modalBody && isScrolledToBottom && hasMore && !searching) {
      setSearching(true);
      setPageNo(pageNo + 1);
    }
  }, [modalBody, isScrolledToBottom, hasMore, pageNo, searching]);

  // To do the search api on change of params
  useEffect(() => {
    if (searching) {
      onSearch(searchKey, pageNo, PER_PAGE)
        .then((response) => {
          setSearching(false);
          setSearchListLoaded(true);
          setSearchedList((prevList) => ([...prevList, ...response]));
        });
    }
  }, [searching, searchKey, pageNo, PER_PAGE, onSearch]);

  const onSubmit = (formData: ManualAddFormProps) => {
    setManuallyAdding(true);
    onAdd({
      id: -1,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
    }).then((result) => {
      reset();
      setManuallyAdding(false);
    });
  };

  const handleInputChange = (event) => {
    setSearchKey(event.target.value);
  };

  const searchUsers = (
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement> | MouseEvent,
  ) => {
    if ((event as KeyboardEvent).key === 'Enter' || event.type === 'click') {
      setSearching(true);
      setPageNo(1);
      setSearchedList([]);
      setSearchListLoaded(false);
    }
  };

  const clearSearch = () => {
    if (searchRef.current) {
      searchRef.current.value = '';
    }
    setSearchListLoaded(false);
    setSearchKey('');
  };

  const addUserFromSearch = (user: UserItemProps) => new Promise((resolve) => {
    setManuallyAdding(true);
    onAdd({
      id: user.id,
      email: user.email,
    }).then((response) => {
      setManuallyAdding(false);
      setSearchedList((prev) => prev.filter((item) => item.id !== response.id));
      resolve(true);
    });
  });

  return (
    <div>
      <p className='py-2'>{manualAddLabel}</p>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='manual-form d-flex border-bottom border-gray-4 pb-5'>
            <div className='bs4-col-3 pl-0 pr-1'>
              <NvTextInput
                required
                withForm
                name='firstName'
                placeholder={t.USERS.REGISTRATION.FIRST_NAME()}
              />
            </div>
            <div className='bs4-col-3 px-1'>
              <NvTextInput
                required
                withForm
                name='lastName'
                placeholder={t.USERS.REGISTRATION.LAST_NAME()}
              />
            </div>
            <div className='bs4-col-5 px-1'>
              <NvTextInput
                required
                withForm
                name='email'
                placeholder={t.USERS.REGISTRATION.EMAIL_ADDRESS()}
              />
            </div>
            <div className='bs4-col-1 px-1'>
              <Button
                type='submit'
                disabled={!formState.isDirty || !formState.isValid || manuallyAdding}
              >
                {manuallyAdding ? t.FORM.ADDING() : t.FORM.ADD()}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
      <p className='pt-5 pb-4 mb-0'>{searchLabel}</p>
      <div className='d-flex align-items-center border-bottom border-gray-4 pb-2'>
        <NvIcon
          size='small'
          icon='search'
          onClick={searchUsers}
        />
        <NvTextInput
          className='flex-grow-1'
          onChange={handleInputChange}
          value={searchKey}
          ref={searchRef as MutableRefObject<HTMLInputElement>}
          inputClassName='border-0 bg-transparent'
          onKeyDown={searchUsers}
          name='search'
          placeholder={searchPlaceholder}
        />
        {searchKey ? (
          <button
            type='button'
            className='border-0 bg-transparent text-small color-primary'
            onClick={clearSearch}
          >
            {t.SHARED.CLEAR()}
          </button>
        ) : ''}
      </div>
      <div className='searchResults'>
        {searchListLoaded && searchedList.length > 0 ? (
          <div className='py-2'>
            <div className='d-flex align-items-center py-2'>{usersFoundTitle}</div>
            {searchedList.map((user) => (
              <UserListItem
                key={user.id}
                user={user}
                actionTooltip={t.FORM.ADD()}
                actionIcon='add'
                onAction={() => addUserFromSearch(user)}
              />
            ))}
          </div>
        ) : ''}
        {searchListLoaded && !hasMore && searchedList.length > 0 ? (
          <div className='d-flex align-items-center gray-2 text-small'>{t.SEARCH.ALL_LOADED()}</div>
        ) : ''}
      </div>
      {searching ? (
        <div className='d-flex align-items-center my-2'>
          <NvIcon size='smallest' icon='refresh' className='color-primary pr-2' />
          <span>{t.USER_MANAGEMENT.MANUAL_ADD_MODAL.LOADING_RESULTS()}</span>
        </div>
      ) : (
        <React.Fragment>
          {(searchListLoaded && searchedList.length === 0) && (
          <div className='d-flex align-items-center my-2'>
            <NvIcon size='smallest' icon='warning' className='text-danger pr-2' />
            <span>{t.USER_MANAGEMENT.MANUAL_ADD_MODAL.NO_RESULTS()}</span>
          </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default UserAddAndSearch;
