/* @ngInject */
export default function AnnouncementsForCoursePreviewCtrl(

  $scope,
  $uibModal,
  AnnouncementsManager,
  CurrentUserManager,
  PusherManager,
  PusherChannels,
  moment,
  humps,
  config,
  $element,
  nvUtil,
) {
  const vm = this;

  vm.config = config;
  vm.AnnouncementsManager = AnnouncementsManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.course = AnnouncementsManager.currentCourse();
  vm.moment = moment;
  vm.numUnreadAnnouncementsAbove = 0;
  vm.loadingAnnouncementsAbove = false;
  vm.loadAnnouncementsAbove = loadAnnouncementsAbove;
  vm.showAnnouncementModal = showAnnouncementModal;
  vm.resetAnnouncements = resetAnnouncements;

  $scope.$on('$destroy', () => {
    PusherManager.channelsHash[PusherChannels.announcementsChannel].unbind('new_announcement', newAnnouncementEventHandler);
  });

  initialize();

  function initialize() {
    PusherManager
      .setupChannel(PusherChannels.announcementsChannel)
      .bind('new_announcement', newAnnouncementEventHandler);
  }

  function loadAnnouncementsAbove() {
    vm.numUnreadAnnouncementsAbove = 0;
    vm.loadingAnnouncementsAbove = true;

    AnnouncementsManager.reload().then(() => {
      vm.loadingAnnouncementsAbove = false;
    });
  }

  function showAnnouncementModal(announcement) {
    $uibModal.open({
      templateUrl: 'announcements/templates/announcements-modal.html',
      controller: 'AnnouncementsModalCtrl as vm',
      windowClass: 'announcements-modal',
      resolve: {
        announcementId: announcement?.id,
        numUnreadAnnouncementsAbove: vm.numUnreadAnnouncementsAbove,
      },
    });

    AnnouncementsManager.markAnnouncementsAsReadForCourse();
  }

  // Private Functions
  function newAnnouncementEventHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);

    if (vm.course.id === pusherData.courseId) {
      vm.numUnreadAnnouncementsAbove += 1;
    }
  }

  function resetAnnouncements() {
    AnnouncementsManager.resetAnnouncements();

    if ($element[0]?.parentNode) {
      nvUtil.focusFirstElement($element[0]?.parentNode);
    }
  }
}
