import { css } from '@emotion/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import moment from 'moment';
import { useAppDispatch } from 'redux/store';
import { filterBookmarks, unhighlightBookmark } from 'redux/actions/bookmarks';
import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import ClickableContainer from 'components/clickable-container';
import { primary, gray3, gray6 } from 'styles/global_defaults/colors';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { getCurrentCourse, getUserEnrolledCourses } from 'redux/selectors/course';
import NvDropdown, { NvDropdownAlign, NvDropdownButtonStyle, NvDropdownOption, NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';
import { getCurrentUser } from 'redux/selectors/users';
import { CombinedCourse, RootState } from 'redux/schemas';
import { getHighlightedBookmark, getNewCreatedBookmark } from 'redux/selectors/bookmarks';
import useMountEffect from 'shared/hooks/use-mount-effect';
import { boldFontWeight } from 'styles/global_defaults/fonts';
import { AngularServicesContext } from 'react-app';
import { config } from '../../../config/pendo.config.json';

type BookmarksHeaderProps = {
  addNewBookmark: () => void;
  onClose: () => void;
  onChange?: () => void;
  containerRef: React.MutableRefObject<HTMLDivElement>;
};

const BookmarksHeader = ({
  addNewBookmark,
  onClose,
  onChange,
  containerRef,
}: BookmarksHeaderProps) => {
  const dispatch = useAppDispatch();

  const initialDropdownSet: NvDropdownOption[] = [{
    type: 'text',
    text: t.LHS.BOOKMARKS.ALL_BOOKMARKS(),
    callback: () => onSelectCourse(null),
  }];

  const currentUser = useSelector(getCurrentUser);
  const currentCourse = useSelector(getCurrentCourse);
  const enrolledCourses: CombinedCourse[] = useSelector((state => getUserEnrolledCourses(state, currentUser.id)));
  const { filter, highlighted } = useSelector(((state: RootState) => state.app.bookmarks));
  const highlightedBookmark = useSelector(getHighlightedBookmark);
  const newCreatedBookmark = useSelector(getNewCreatedBookmark);
  const { $state, CurrentPermissionsManager } = React.useContext(AngularServicesContext);

  const [initialIndex, setInitialIndex] = useState(0);
  const [dropdownItems, setDropdownItems] = useState<NvDropdownOption[]>(initialDropdownSet);

  const setFilter = useCallback((catalogId?: string) => {
    dispatch(filterBookmarks({
      filter: catalogId,
      shouldUnsetCurrentData: true,
    }));
    onChange?.();
  }, [dispatch, onChange]);

  const onSelectCourse = useCallback((catalogId?: string) => {
    setFilter(catalogId);
  }, [setFilter]);

  useEffect(() => {
    const activeCourses: CombinedCourse[] = [];
    const closedCourses: CombinedCourse[] = [];

    const coursesWithoutJourneys = enrolledCourses.filter((offering) => !offering.isJourney);

    if (coursesWithoutJourneys.length) {
      coursesWithoutJourneys.forEach(course => {
        const isCourseFilterEnabled = CurrentPermissionsManager.hasCourseAdminPermissions() ? true : course.released;

        if ((!course.closeDate || moment(course.closeDate) > moment()) && isCourseFilterEnabled) {
          activeCourses.push(course);
        } else if (isCourseFilterEnabled) {
          closedCourses.push(course);
        }
      });

      let items: NvDropdownOption[] = initialDropdownSet;

      if (activeCourses.length) {
        items = items.concat([
          {
            type: 'divider',
          },
          {
            type: 'header',
            title: t.LHS.BOOKMARKS.ACTIVE_COURSES(),
          },
        ]);

        activeCourses.forEach(course => {
          items.push({
            type: 'text',
            text: course.name,
            value: course.catalogId,
            textClassName: 'ellipsis',
            callback: () => onSelectCourse(course.catalogId),
          });
        });
      }

      if (closedCourses.length) {
        items = items.concat([
          {
            type: 'divider',
          },
          {
            type: 'header',
            title: t.LHS.BOOKMARKS.CLOSED_COURSES(),
          },
        ]);

        closedCourses.forEach(course => {
          items.push({
            type: 'text',
            text: course.name,
            value: course.catalogId,
            textClassName: 'ellipsis',
            callback: () => onSelectCourse(course.catalogId),
          });
        });
      }
      setDropdownItems(items);
    }
  }, [enrolledCourses, setDropdownItems, onSelectCourse]);

  useMountEffect(() => {
    let newFilter;
    let courseFilter;

    if (highlightedBookmark) {
      courseFilter = highlightedBookmark.catalogId;
    } else if (newCreatedBookmark) {
      courseFilter = newCreatedBookmark.catalogId;
    } else if (currentCourse && $state.includes('course')) {
      courseFilter = currentCourse.catalogId;
    }

    if (courseFilter && enrolledCourses.some((course) => course.catalogId === courseFilter)) {
      newFilter = courseFilter;
    }

    if (newFilter) {
      dispatch(filterBookmarks({
        filter: newFilter,
        shouldUnsetCurrentData: false,
      }));
    }

    return () => {
      dispatch(filterBookmarks({ filter: null, shouldUnsetCurrentData: true }));
    };
  });

  useEffect(() => {
    const courseIndex = dropdownItems?.findIndex(item => {
      const { value } = (item as NvDropdownTextItem);
      if (value) {
        return value === filter;
      }
      return false;
    });

    if (courseIndex > 0) {
      setInitialIndex(courseIndex);
    }
  }, [dropdownItems, filter]);

  const styles = css`
    padding: ${standardSpacing}px;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    border-bottom: 1px solid ${gray6};

    .center-section {
      flex: 1;
      min-width: 0;
      display: flex;
      justify-content: center;

      .nv-dropdown {
        width: 100%;

        .bs4-dropdown {
          .custom-filter-target {
            flex: 1;
            min-width: 0;
            justify-content: center;
          }
        }
      }
    }

    .highlighted-state {
      flex: 1;
      min-width: 0;
      color: ${primary};
      display: flex;
      align-items: center;
      font-weight: ${boldFontWeight};

      .icon-arrow-left {
        margin-right: ${standardSpacing}px;
      }
    }

    .new-note {
      margin-right: 80px;
    }

    .icon-new-note {
      color: ${primary};
    }

    .close-flyout {
      margin-left: ${highlighted ? standardSpacing : 80}px;
    }

    .icon-close {
      color: ${gray3};

      &:hover {
        color: ${primary};
      }
    }
  `;

  const closeFlyout = () => {
    onClose();
  };

  const unhighlight = () => {
    dispatch(unhighlightBookmark());
  };

  return (
    <div className='header' css={styles}>
      {highlighted ? (
        <ClickableContainer className='highlighted-state text-large-regular' onClick={unhighlight}>
          <NvIcon icon='arrow-left' size='small' />
          <div className='ellipsis'>{t.LHS.BOOKMARKS.SHOW_ALL()}</div>
        </ClickableContainer>
      ) : (
        <React.Fragment>
          <NvTooltip
            text={t.LHS.BOOKMARKS.CREATE_NEW_NOTE()}
            placement='right'
          >
            <ClickableContainer
              className='new-note'
              onClick={addNewBookmark}
              aria-label={t.LHS.BOOKMARKS.CREATE_NEW_NOTE()}
              aria-describedby='disabled'
              pendo-tag-name={config.pendo.bookmarks.createNote}
            >
              <NvIcon size='small' icon='new-note' />
            </ClickableContainer>
          </NvTooltip>
          <div className='center-section'>
            {dropdownItems.length > 1 ? (
              <NvDropdown
                useMaxHeightModifier
                container={containerRef.current}
                showSelectedIndicator
                initialIndex={initialIndex}
                menuClassName='filters-menu'
                items={dropdownItems}
                buttonStyle={NvDropdownButtonStyle.CUSTOM}
                align={NvDropdownAlign.CENTER}
                customTarget={({ selectedItem }) => (
                  <div
                    pendo-tag-name={config.pendo.bookmarks.bookmarksSelectCourse}
                    className='course-subtitle condensed-dropdown custom-filter-target'
                  >
                    <div
                      className='ellipsis'
                      aria-live='polite'
                    >
                      {selectedItem.text}
                    </div>
                    <div className='icon text-xs icon-dropdown-arrow pl-2' />
                  </div>
                )}
              />
            ) : (
              <div
                className='course-subtitle ellipsis'
                aria-live='polite'
              >
                {t.LHS.BOOKMARKS.ALL_BOOKMARKS()}
              </div>
            )}
          </div>
        </React.Fragment>
      )}
      <ClickableContainer
        onClick={closeFlyout}
        className='close-flyout'
        aria-label={t.NOVOED.CLOSE()}
      >
        <NvIcon size='small' icon='close' />
      </ClickableContainer>
    </div>
  );
};

export default BookmarksHeader;
