/* @ngInject */
export default function NvCourseAccessBadge(
  CurrentUserManager,
  moment,
) {
  return {
    restrict: 'A',
    scope: {
      course: '=',
      remainingDaysOnly: '=?',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
      const currentUserCourse = CurrentUserManager.courseIdToUserCourseHash[vm.course.id] || {};
      const releaseDateMoment = moment(vm.course.officialReleaseDate);
      const endDateMoment = moment(vm.course.endDate);
      const currentMoment = moment();
      vm.remainingAccessInDays = null;
      if (currentUserCourse && currentUserCourse?.accessCloseDate && moment(currentUserCourse?.accessCloseDate) > currentMoment) {
        vm.remainingAccessInDays = Math.ceil((moment(currentUserCourse?.accessCloseDate) - currentMoment) / (3600 * 24 * 1000));
      }
      vm.isSelfPacedCourseWithTimeLimit = vm.course.isSelfPaced && vm.course.enrollmentLimitInDays;
      vm.courseStarted = releaseDateMoment && currentMoment > releaseDateMoment;
      vm.moment = moment;
      vm.accessCloseDate = currentUserCourse?.accessCloseDate;
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'dashboard/templates/nv-course-access-badge.html',
  };
}
