import React from 'react';
import { css } from '@emotion/core';

export type Props = {
  animate?: boolean;
  onAnimationEnd: () => void;
};

export const QuizAttempts = (props: Props) => {
  const { animate, onAnimationEnd } = props;

  const styles = animate ? css`
    .main {
      animation: quiz-attempts-draw 1.8s ease-in-out forwards;
      stroke-dasharray: 463.99859619140625;
      stroke-dashoffset: 463.99859619140625;
    }

    .left {
      animation: quiz-attempts-draw 0.2s ease-out forwards;
      animation-delay: 1.8s;
      stroke-dasharray: 7.071067810058594;
      stroke-dashoffset: 7.071067810058594;
    }

    .right {
      animation: quiz-attempts-draw 0.2s ease-out forwards;
      animation-delay: 1.8s;
      stroke-dasharray: 7.071067810058594;
      stroke-dashoffset: 7.071067810058594;
    }

    @keyframes quiz-attempts-draw {
      to {
        stroke-dashoffset: 0;
      }
    }
  ` : undefined;

  return (
    <svg width='239' height='85' viewBox='0 0 239 85' fill='none' xmlns='http://www.w3.org/2000/svg' css={styles}>
      <rect
        x='2'
        y='2'
        width='57.9937'
        height='67.9954'
        rx='1'
        fill='white'
        stroke='#748499'
        strokeWidth='2'
      />
      <path
        d='M38.4977 40.0313L35.8744 39.3285L37.7992 32.1464L38.1409 30.9732L38.5291 29.6941C37.9766 30.0132 37.5972 30.2179 37.391 30.3082L35.6576 31.0722L34.8158 29.1543L39.6676 27.0419L41.824 27.6196L38.4977 40.0313Z'
        fill='#748499'
      />
      <path
        d='M31.9729 31.6145C31.5816 33.0747 30.9929 34.2303 30.2067 35.0812C29.4278 35.928 28.476 36.4464 27.3514 36.6365L29.4456 40.7735L26.0752 39.8705L24.5462 36.4764L24.3509 36.4241C22.4492 35.9146 21.1369 34.9716 20.4139 33.5951C19.6909 32.2185 19.6062 30.4973 20.1599 28.4316C20.7135 26.3658 21.6453 24.926 22.9553 24.1123C24.2724 23.2944 25.8847 23.141 27.792 23.652C29.6994 24.163 31.0096 25.1024 31.7228 26.4703C32.4417 27.8397 32.525 29.5544 31.9729 31.6145ZM22.9144 29.1877C22.5428 30.5743 22.5262 31.6891 22.8645 32.5319C23.2027 33.3747 23.8954 33.9363 24.9425 34.2168C27.0423 34.7794 28.466 33.6655 29.2138 30.8753C29.9631 28.0795 29.2935 26.4018 27.205 25.8423C26.158 25.5618 25.2736 25.704 24.552 26.2689C23.8319 26.8282 23.2861 27.8011 22.9144 29.1877Z'
        fill='#748499'
      />
      <path
        d='M60.9975 39.0039H238V84L30 84V50'
        stroke='#FA6625'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        className='main'
      />
      <rect
        x='161.983'
        y='2'
        width='57.9937'
        height='67.9954'
        rx='1'
        fill='white'
        stroke='#748499'
        strokeWidth='2'
      />
      <rect
        x='81.9917'
        y='2'
        width='57.9937'
        height='67.9954'
        rx='1'
        fill='white'
        stroke='#748499'
        strokeWidth='2'
      />
      <path
        d='M120.469 40.6188L111.792 38.2943L112.281 36.4691L116.241 34.1542C117.417 33.4562 118.195 32.964 118.575 32.6776C118.957 32.3855 119.251 32.1005 119.459 31.8226C119.667 31.5447 119.816 31.2387 119.905 30.9048C120.039 30.4068 120 29.9989 119.788 29.6812C119.581 29.3651 119.249 29.1456 118.79 29.0228C118.309 28.8939 117.813 28.8792 117.301 28.9786C116.789 29.078 116.232 29.2655 115.63 29.5411L114.657 27.4696C115.408 27.1127 116.013 26.8805 116.472 26.7731C116.932 26.6657 117.414 26.6191 117.92 26.6332C118.427 26.6418 118.975 26.7249 119.564 26.8826C120.339 27.0903 120.986 27.4152 121.505 27.8574C122.023 28.2996 122.378 28.8194 122.57 29.4167C122.762 30.014 122.77 30.6409 122.594 31.2974C122.441 31.8691 122.195 32.3794 121.856 32.8286C121.524 33.2735 121.076 33.7023 120.51 34.115C119.95 34.5291 119.008 35.0804 117.683 35.7688L115.684 36.8438L115.652 36.9627L121.06 38.4115L120.469 40.6188Z'
        fill='#748499'
      />
      <path
        d='M111.754 31.6145C111.362 33.0747 110.774 34.2303 109.988 35.0812C109.209 35.928 108.257 36.4464 107.132 36.6365L109.226 40.7735L105.856 39.8705L104.327 36.4764L104.132 36.4241C102.23 35.9146 100.918 34.9716 100.195 33.5951C99.4717 32.2185 99.387 30.4973 99.9406 28.4316C100.494 26.3658 101.426 24.926 102.736 24.1123C104.053 23.2944 105.665 23.141 107.573 23.652C109.48 24.163 110.79 25.1024 111.504 26.4703C112.222 27.8397 112.306 29.5544 111.754 31.6145ZM102.695 29.1877C102.324 30.5743 102.307 31.6891 102.645 32.5319C102.984 33.3747 103.676 33.9363 104.723 34.2168C106.823 34.7794 108.247 33.6655 108.995 30.8753C109.744 28.0795 109.074 26.4018 106.986 25.8423C105.939 25.5618 105.054 25.704 104.333 26.2689C103.613 26.8282 103.067 27.8011 102.695 29.1877Z'
        fill='#748499'
      />
      <path
        d='M202.559 30.8536C202.351 31.6289 201.939 32.2254 201.324 32.6428C200.709 33.0603 199.949 33.2572 199.046 33.2335L199.032 33.2844C200 33.6773 200.677 34.1861 201.061 34.8108C201.448 35.4299 201.531 36.1497 201.311 36.9704C200.991 38.1646 200.308 38.9796 199.263 39.4155C198.22 39.8456 196.895 39.8454 195.287 39.4148C193.94 39.0539 192.806 38.5104 191.884 37.7843L192.483 35.5516C192.898 35.9239 193.375 36.2637 193.911 36.5712C194.447 36.8787 194.998 37.1082 195.564 37.2598C196.43 37.4918 197.109 37.516 197.601 37.3324C198.093 37.1488 198.427 36.7315 198.601 36.0807C198.757 35.4977 198.63 35.0209 198.22 34.6502C197.81 34.2794 197.084 33.9546 196.043 33.6756L195.1 33.4231L195.64 31.4111L196.599 31.6681C197.561 31.9259 198.296 31.9894 198.804 31.8586C199.32 31.7237 199.659 31.3507 199.823 30.7394C200.075 29.7999 199.612 29.1725 198.435 28.8571C198.028 28.7479 197.593 28.7044 197.132 28.7265C196.677 28.7501 196.147 28.8599 195.543 29.0558L194.813 26.9223C196.164 26.4106 197.623 26.3647 199.191 26.7848C200.475 27.1289 201.419 27.6607 202.021 28.38C202.628 29.1009 202.808 29.9254 202.559 30.8536Z'
        fill='#748499'
      />
      <path
        d='M191.745 31.6145C191.354 33.0747 190.765 34.2303 189.979 35.0812C189.2 35.928 188.248 36.4464 187.124 36.6365L189.218 40.7735L185.848 39.8705L184.319 36.4764L184.123 36.4241C182.222 35.9146 180.909 34.9716 180.186 33.5951C179.463 32.2185 179.379 30.4973 179.932 28.4316C180.486 26.3658 181.418 24.926 182.728 24.1123C184.045 23.2944 185.657 23.141 187.564 23.652C189.472 24.163 190.782 25.1024 191.495 26.4703C192.214 27.8397 192.298 29.5544 191.745 31.6145ZM182.687 29.1877C182.315 30.5743 182.299 31.6891 182.637 32.5319C182.975 33.3747 183.668 33.9363 184.715 34.2168C186.815 34.7794 188.238 33.6655 188.986 30.8753C189.736 28.0795 189.066 26.4018 186.977 25.8423C185.93 25.5618 185.046 25.704 184.324 26.2689C183.604 26.8282 183.059 27.8011 182.687 29.1877Z'
        fill='#748499'
      />
      <path
        d='M30 49L25 54'
        stroke='#FA6625'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        className='left'
      />
      <path
        d='M30 49L35 54'
        stroke='#FA6625'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        className='right'
        onAnimationEnd={(e) => {
          if (e.animationName === 'quiz-attempts-draw') {
            onAnimationEnd();
          }
        }}
      />
    </svg>
  );
};

export const QuestionAttempts = (props: Props) => {
  const { animate, onAnimationEnd } = props;

  const styles = animate ? css`
    .main {
      animation: question-attempts-draw 1.8s ease-in-out forwards;
      stroke-dasharray: 235.00149536132812;
      stroke-dashoffset: 235.00149536132812;
    }

    .left {
      animation: question-attempts-draw 0.2s ease-out forwards;
      animation-delay: 1.8s;
      stroke-dasharray: 7.071067810058594;
      stroke-dashoffset: 7.071067810058594;
    }

    .right {
      animation: question-attempts-draw 0.2s ease-out forwards;
      animation-delay: 1.8s;
      stroke-dasharray: 7.071067810058594;
      stroke-dashoffset: 7.071067810058594;
    }

    @keyframes question-attempts-draw {
      to {
        stroke-dashoffset: 0;
      }
    }
  ` : undefined;

  return (
    <svg
      width='238'
      height='85'
      viewBox='0 0 238 85'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      css={styles}
    >
      <g clipPath='url(#clip0_3225_36470)'>
        <path
          d='M1 2C1 1.44772 1.44772 1 2 1H58C58.5523 1 59 1.44772 59 2V68C59 68.5523 58.5523 69 58 69H2C1.44772 69 1 68.5523 1 68V2Z'
          fill='white'
          stroke='#748499'
          strokeWidth='2'
        />
        <rect
          x='179'
          y='1'
          width='58'
          height='68'
          rx='1'
          fill='white'
          stroke='#748499'
          strokeWidth='2'
        />
        <path
          d='M217.46 39.5826L208.78 37.2701L209.272 35.4455L213.237 33.1275C214.415 32.4286 215.194 31.9358 215.574 31.6491C215.957 31.3568 216.252 31.0717 216.46 30.7937C216.669 30.5157 216.818 30.2098 216.908 29.876C217.042 29.3782 217.003 28.9707 216.791 28.6535C216.585 28.3378 216.253 28.1188 215.794 27.9966C215.313 27.8684 214.816 27.8543 214.304 27.9543C213.791 28.0542 213.234 28.2423 212.631 28.5184L211.66 26.4494C212.411 26.0919 213.017 25.8591 213.477 25.7512C213.937 25.6434 214.42 25.5962 214.926 25.6098C215.434 25.6177 215.982 25.7001 216.571 25.857C217.347 26.0636 217.994 26.3876 218.512 26.8289C219.03 27.2701 219.385 27.7891 219.576 28.3858C219.767 28.9825 219.774 29.6089 219.598 30.2652C219.444 30.8366 219.197 31.3469 218.857 31.7962C218.525 32.2412 218.076 32.6703 217.509 33.0833C216.948 33.4978 216.005 34.0498 214.678 34.7393L212.677 35.816L212.645 35.9348L218.055 37.3762L217.46 39.5826Z'
          fill='#748499'
        />
        <path
          d='M208.75 30.594C208.356 32.0537 207.766 33.2091 206.978 34.0604C206.198 34.9076 205.245 35.4268 204.119 35.618L206.21 39.7497L202.838 38.8513L201.312 35.4614L201.117 35.4093C199.214 34.9025 197.902 33.9617 197.18 32.5869C196.459 31.2122 196.376 29.4923 196.932 27.4273C197.489 25.3622 198.423 23.9224 199.735 23.1077C201.054 22.2888 202.667 22.1335 204.575 22.6419C206.483 23.1503 207.794 24.0875 208.506 25.4536C209.223 26.8212 209.305 28.5347 208.75 30.594ZM199.688 28.1796C199.314 29.5657 199.296 30.6797 199.634 31.5215C199.971 32.3634 200.664 32.9238 201.711 33.2029C203.812 33.7626 205.238 32.6478 205.99 29.8586C206.743 27.0638 206.075 25.388 203.985 24.8314C202.938 24.5523 202.053 24.6955 201.33 25.2609C200.609 25.8206 200.061 26.7935 199.688 28.1796Z'
          fill='#748499'
        />
        <path
          d='M60 38H122L122 84.0008H30L30 49.0001'
          stroke='#FA6625'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='main'
        />
        <rect
          x='93'
          y='1'
          width='58'
          height='68'
          rx='1'
          fill='white'
          stroke='#748499'
          strokeWidth='2'
        />
        <path
          d='M129.48 38.9987L126.856 38.2995L128.791 31.1201L129.134 29.9473L129.524 28.6687C128.971 28.9881 128.591 29.1931 128.385 29.2837L126.649 30.0492L125.809 28.1336L130.667 26.0169L132.824 26.5917L129.48 38.9987Z'
          fill='#748499'
        />
        <path
          d='M122.961 30.594C122.568 32.0537 121.977 33.2091 121.19 34.0604C120.409 34.9076 119.456 35.4268 118.33 35.618L120.421 39.7497L117.05 38.8513L115.524 35.4614L115.328 35.4093C113.426 34.9025 112.114 33.9617 111.392 32.5869C110.67 31.2122 110.587 29.4923 111.144 27.4273C111.7 25.3622 112.634 23.9224 113.946 23.1077C115.265 22.2888 116.879 22.1335 118.787 22.6419C120.695 23.1503 122.005 24.0875 122.717 25.4536C123.435 26.8212 123.516 28.5347 122.961 30.594ZM113.899 28.1796C113.526 29.5657 113.508 30.6797 113.845 31.5215C114.183 32.3634 114.875 32.9238 115.923 33.2029C118.023 33.7626 119.449 32.6478 120.201 29.8586C120.954 27.0638 120.286 25.388 118.197 24.8314C117.149 24.5523 116.264 24.6955 115.542 25.2609C114.82 25.8206 114.273 26.7935 113.899 28.1796Z'
          fill='#748499'
        />
        <path
          d='M37.4804 38.9987L34.8562 38.2995L36.7908 31.1201L37.1342 29.9473L37.5242 28.6687C36.9709 28.9881 36.591 29.1931 36.3845 29.2837L34.649 30.0492L33.8089 28.1336L38.6666 26.0169L40.8238 26.5917L37.4804 38.9987Z'
          fill='#748499'
        />
        <path
          d='M30.9612 30.594C30.5678 32.0537 29.9773 33.2091 29.1896 34.0604C28.4091 34.9076 27.456 35.4268 26.3304 35.618L28.4211 39.7497L25.0495 38.8513L23.5235 35.4614L23.3282 35.4093C21.4258 34.9025 20.1137 33.9617 19.3918 32.5869C18.67 31.2122 18.5873 29.4923 19.1438 27.4273C19.7002 25.3622 20.6344 23.9224 21.9462 23.1077C23.2653 22.2888 24.8788 22.1335 26.7869 22.6419C28.6949 23.1503 30.0049 24.0875 30.717 25.4536C31.4347 26.8212 31.5161 28.5347 30.9612 30.594ZM21.8993 28.1796C21.5258 29.5657 21.5078 30.6797 21.8453 31.5215C22.1828 32.3634 22.8753 32.9238 23.9228 33.2029C26.0233 33.7626 27.4494 32.6478 28.201 29.8586C28.9541 27.0638 28.2861 25.388 26.1969 24.8314C25.1494 24.5523 24.2643 24.6955 23.5415 25.2609C22.8203 25.8206 22.2729 26.7935 21.8993 28.1796Z'
          fill='#748499'
        />
        <path
          d='M177.707 38.7071C178.098 38.3166 178.098 37.6834 177.707 37.2929L171.343 30.9289C170.953 30.5384 170.319 30.5384 169.929 30.9289C169.538 31.3195 169.538 31.9526 169.929 32.3431L175.586 38L169.929 43.6569C169.538 44.0474 169.538 44.6805 169.929 45.0711C170.319 45.4616 170.953 45.4616 171.343 45.0711L177.707 38.7071ZM152 37C151.448 37 151 37.4477 151 38C151 38.5523 151.448 39 152 39L152 37ZM152 39L154.083 39L154.083 37L152 37L152 39ZM158.25 39L162.417 39L162.417 37L158.25 37L158.25 39ZM166.583 39L170.75 39L170.75 37L166.583 37L166.583 39ZM174.917 39L177 39L177 37L174.917 37L174.917 39Z'
          fill='#748499'
        />
        <path
          d='M30 49L25 54'
          stroke='#FA6625'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='left'
        />
        <path
          d='M30 49L35 54'
          stroke='#FA6625'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='right'
          onAnimationEnd={(e) => {
            if (e.animationName === 'question-attempts-draw') {
              onAnimationEnd();
            }
          }}
        />
      </g>
      <defs>
        <clipPath id='clip0_3225_36470'>
          <rect width='238' height='85' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
