import store from 'redux/store';
import { ActivityKey } from 'redux/schemas/models/activity';
import { setActivityProgress } from 'redux/actions/lecture-components';

/* @ngInject */
export default function TimelineLectureVideoModelService(

  _,
  nvUtil,
  LectureVideoResources,
) {
  const TimelineLectureVideoModel = function (attributes) {
    const _this = this;
    const base = {
      templateUrl: 'timelines/templates/nv-timeline-lecture-video.html',
    };

    /** Setting Up Basic Attributes * */
    _.extend(_this, base, attributes);
    // passed lecturePage, lectureSection, course
    preprocess();

    /** Public Functions * */
    _.extend(_this, {
      getStatus,
      isCompleted,
      isMissed,
      isTodoRequiredForCompletion,
      updateComponentPointsAndProgress,
      updateComponentProgress,
      getPayload,
      saveTitleOnBlur,
    });

    /** Function Declarations * */
    /* Used Publicly */
    function getStatus() {
      return _this.progress;
    }

    function isCompleted() {
      return _this.progress === 'completed';
    }

    function isMissed() {
      return false;
    }

    function isTodoRequiredForCompletion() {
      return _this.isRequiredForCompletion;
    }

    function updateComponentPointsAndProgress(componentType, componentId, pointsReceived, totalPoints) {
      if (componentType === 'lecture_video' && _this.id === componentId) {
        _this.progress = 'completed';

        store.dispatch(setActivityProgress({
          pointsReceived,
          progress: _this.progress,
          activityPayloadId: _this.id,
          activityKey: ActivityKey.LECTURE_VIDEOS,
        }));

        _this.pointsReceived = pointsReceived;
        if (_.isNumber(totalPoints)) {
          _this.currentTotalPoints = totalPoints;
        }

        viewProprocess();

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function updateComponentProgress(componentType, componentId, progress) {
      if (componentType === 'lecture_video' && _this.id === componentId) {
        _this.progress = progress;

        store.dispatch(setActivityProgress({
          progress,
          activityPayloadId: _this.id,
          activityKey: ActivityKey.LECTURE_VIDEOS,
        }));

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function getPayload() {
      return _.pick(_this, ['title']);
    }

    /* Used Privately */
    function preprocess() {
      _this.title = _this.title || _this.video.name;
      _this.currentTotalPoints = nvUtil.getCurrentTotalPoints(_this.totalPoints, _this.lecturePage.releaseDate, _this.course.expiringPointsEnabled);
      viewProprocess();
    }

    function viewProprocess() {
      if (isCompleted() || _this.pointsReceived > _this.currentTotalPoints) {
        _this.currentTotalPoints = _this.pointsReceived ? _this.pointsReceived : 0;
      }

      _this.displayTotalPointsOnly = !_this.pointsReceived && _this.currentTotalPoints;
    }

    function saveTitleOnBlur() {
      return LectureVideoResources.update({ catalogId: _this.videoListLectureComponent.catalogId, videoId: _this.videoId },
        { video: { name: _this.title } }).$promise;
    }
  };

  return TimelineLectureVideoModel;
}
