/* @ngInject */
export default function UsersResources($resource) {
  return $resource(
    '/users/:userId.json',
    { userId: '@userId' },
    {
      currentUser: { method: 'GET', url: '/my_account.json' },
      getUser: { method: 'GET', url: '/users/user/:userId.json' },
      signIn: { method: 'POST', url: '/users/sign_in.json' },
      signOut: { method: 'GET', url: '/users/sign_out.json' },
      signUp: { method: 'POST', url: '/users.json' },
      searchFilters: { method: 'GET', url: '/:catalogId/search/filters/users' },
      search: { method: 'POST', url: '/:catalogId/search/users.json', cancellable: true },
      updatePlatformLanguage: { method: 'PUT', url: '/profiles/update_platform_language.json' },
      updateTimelineDisplay: { method: 'POST', url: '/users/update_is_timeline_expanded.json' },
      updateHasViewedRteFte: { method: 'PUT', url: '/users/update_has_viewed_rte_fte.json' },
      updateHasViewedI18nFte: { method: 'PUT', url: '/users/update_has_viewed_i18n_fte.json' },
      markTrackableAsRead: { method: 'POST', url: '/legal_trackables/:id/mark_read.json' },
      saveRecentlyUsedColors: { method: 'PUT', url: '/users/update_recently_used_colors.json' },
      markDowntimeAlertAsRead: { method: 'PUT', url: '/users/update_downtime_reviewed.json' },
      currentEnrollments: { method: 'POST', url: '/users/current_enrollments.json' },
      currentMemberships: { method: 'GET', url: '/users/current_memberships.json' },
      getLoginProviders: { method: 'GET', url: '/providers.json' },
    },
  );
}
