import { css } from '@emotion/react';
import { useState, useEffect, forwardRef, useImperativeHandle, useCallback, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import t from 'react-translate';
import { useMediaQuery } from 'react-responsive';

import { useAppDispatch } from 'redux/store';
import { hasGetDisplayMedia, MediaSourceType, requestMediaStream, stopMediaStreams } from 'recording/services/media-stream';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import AudioStreamPreview from 'recording/components/audio-stream-preview';
import VideoStreamPreview from 'recording/components/video-stream-preview';
import VideoAudioVisualizer from 'recording/components/video-audio-visualizer';
import {
  RecordingFormat,
  VideoPracticeOption,
  VideoPracticeScenario,
  VideoPracticeType,
} from 'redux/schemas/models/video-practice';
import NvIcon from 'shared/components/nv-icon';

import { hexToRgbaString, gray1, gray4, gray5, gray3 } from 'styles/global_defaults/colors';
import { doubleSpacing, halfSpacing, largeSpacing, standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { handheld, screenXsMax } from 'styles/global_defaults/media-queries';
import { getTimeLimits, getTotalTime, roundToMinutes } from './video-practice-utils';
import ScreenSharePreCheckMessage, { PrecheckType } from './screen-share-precheck-message';

type GetReadyConfirmationOverlayProps = {
  practiceType: VideoPracticeType;
  scenario: VideoPracticeScenario;
  onCancel: () => void;
  onReady: (e: any) => void;
};

const GetReadyConfirmationOverlay = ({
  practiceType,
  scenario,
  onCancel,
  onReady,
}: GetReadyConfirmationOverlayProps) => {
  const styles = (isCameraAndMicAllowed) => css`
    background-color: ${hexToRgbaString(gray1, 0.95)};
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .mic-check {
      margin: ${doubleSpacing}px;
      color: white;
    }

    .icon-info {
      color: white;
    }

    .page-title {
      max-width: 800px;
    }

    .background {
      background-color: ${gray1};
      width: 719px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: ${tripleSpacing}px;
      padding-bottom: ${(!isCameraAndMicAllowed || scenario.recordingFormat === RecordingFormat.AUDIO) ? 60 : 0}px;

      .video-preview {
        padding-right: ${standardSpacing}px;
        padding-left: ${standardSpacing}px;
      }

      .please-check {
        margin-bottom: ${largeSpacing}px;
      }

      .webcam-view {
        background-color: ${gray5};
        width: 322px;
        height: 210px;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon-media-practice {
          color: ${gray3};
        }
      }

      .webcam-check {
        margin-top: ${halfSpacing}px;
      }

      .precheck {
        min-width: 500px;
        opacity: 0.8;
      }
    }
    .button-container {
      display: flex;
      margin-top: ${tripleSpacing}px;
      margin-bottom: ${doubleSpacing}px;

      button:first-of-type {
        margin-right: ${halfSpacing}px;
      }
    }

    ${handheld(css`
      .mic-check {
        margin: ${standardSpacing}px
        margin-top: ${doubleSpacing}px;
        color: ${gray4};
      }

      .background {
        padding-top: ${practiceType === VideoPracticeOption.WHEN_READY ? standardSpacing : 0}px;
        width: 100%;

        .please-check, .webcam-check {
          margin: ${standardSpacing}px;
        }
        .precheck {
          min-width: 100%;
        }
      }

      .button-container {
        flex-direction: column;
        width: 100%;
        padding: ${standardSpacing}px;
      }
    `)};
  `;

  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const dispatch = useAppDispatch();

  const [mediaStream, setMediaStream] = useState<MediaStream>();
  const [preCheckState, setPreCheckState] = useState(PrecheckType.TEST_PERMISSION);
  const [isCameraAndMicAllowed, setIsCameraAndMicAllowed] = useState(false);

  /**
   * This state is to restart the camera stream if the screenshare stream got
   * stopped. But it doesn't need to restart if this an actual component
   * destroy in case of a cancel or close
   */
  const isMounted = useRef(false);

  const { timeToView, timeToRecord, timeToReview } = getTimeLimits(scenario);
  const timeToViewRounded = roundToMinutes(timeToView);
  const timeToRecordRounded = roundToMinutes(timeToRecord);
  const timeToReviewRounded = roundToMinutes(timeToReview);
  const totalTimeRounded = getTotalTime(timeToViewRounded, timeToRecordRounded, timeToReviewRounded);

  let title = t.FILE_UPLOAD.MIC_AND_CAMERA_CHECK();
  if (scenario.recordingFormat === RecordingFormat.AUDIO) {
    title = t.FILE_UPLOAD.MIC_CHECK();
  }
  if (practiceType === VideoPracticeOption.ON_SPOT) {
    title = t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.TOTAL_TIME_BREAKDOWN(timeToRecordRounded, timeToReviewRounded, totalTimeRounded, timeToViewRounded);
  }

  const screenShareSupported = hasGetDisplayMedia();

  const getMediaStream = useCallback((isScreenShareMode = false) => {
    setMediaStream(null);
    let mediaType = isScreenShareMode ? MediaSourceType.SCREEN_MIC : MediaSourceType.CAMERA_MIC;
    if (scenario.recordingFormat === RecordingFormat.AUDIO) {
      mediaType = MediaSourceType.MIC;
    }
    requestMediaStream(mediaType)
      .then((stream: MediaStream) => {
        setMediaStream(stream);

        if (isScreenShareMode) {
          setPreCheckState(PrecheckType.SUCCESS);

          window.stopScreenStreamCallback = () => {
            window.stopScreenStreamCallback = null;

            if (isMounted.current) {
              setMediaStream(null);
              setPreCheckState(PrecheckType.TEST_PERMISSION);
              getMediaStream();
            }
          };
        } else {
          setIsCameraAndMicAllowed(true);
        }
      })
      .catch(error => {
        if (isScreenShareMode) {
          if (error.type === 'audio' && error.name === 'NotFoundError') {
            dispatch(openConfirmationDialog({
              confirmButtonVariant: 'primary',
              title: t.FILE_UPLOAD.CANNOT_RECORD.TITLE(),
              bodyText: t.FILE_UPLOAD.CANNOT_RECORD.AUDIO_NOT_FOUND(),
              confirmText: t.FORM.CONTINUE(),
              onConfirm: () => getMediaStream(isScreenShareMode),
              onCancel: () => getMediaStream(),
            }));
          } else if (error.type === 'audio' && error.name === 'NotAllowedError') {
            dispatch(openConfirmationDialog({
              confirmButtonVariant: 'primary',
              title: t.FILE_UPLOAD.CANNOT_RECORD.TITLE(),
              bodyText: t.FILE_UPLOAD.CANNOT_RECORD.AUDIO_NOT_ALLOWED(),
              confirmText: t.FORM.CONTINUE(),
              onConfirm: () => getMediaStream(isScreenShareMode),
              onCancel: () => getMediaStream(),
            }));
          } else if (error.name === 'NotAllowedError') {
            setPreCheckState(PrecheckType.NO_PERMISSION);
            dispatch(openConfirmationDialog({
              title: t.FILE_UPLOAD.CANNOT_RECORD.TITLE(),
              bodyText: t.FILE_UPLOAD.CANNOT_RECORD.SCREEN_NOT_ALLOWED(),
              confirmText: t.FORM.CONTINUE(),
              onConfirm: () => getMediaStream(isScreenShareMode),
              onCancel: () => getMediaStream(),
            }));
          }
        } else if (error.name === 'NotFoundError') {
          setIsCameraAndMicAllowed(false);
          dispatch(openConfirmationDialog({
            title: t.FILE_UPLOAD.CANNOT_RECORD.TITLE(),
            bodyText: t.FILE_UPLOAD.CANNOT_RECORD.VIDEO_NOT_FOUND(),
            confirmText: t.FORM.CONTINUE(),
            onConfirm: () => getMediaStream(),
            onCancel: () => onCancel(),
          }));
        } else if (error.name === 'NotAllowedError') {
          setIsCameraAndMicAllowed(false);
          dispatch(openConfirmationDialog({
            title: t.FILE_UPLOAD.CANNOT_RECORD.TITLE(),
            bodyText: t.FILE_UPLOAD.CANNOT_RECORD.VIDEO_NOT_ALLOWED(),
            confirmText: t.FORM.CONTINUE(),
            onConfirm: () => getMediaStream(),
            onCancel: () => onCancel(),
          }));
        }
      });
  }, [dispatch, onCancel, scenario.recordingFormat]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getMediaStream();
  }, [getMediaStream]);

  useEffect(() => () => {
    stopMediaStreams(mediaStream);
  }, [mediaStream]);

  useEffect(() => {
    if (!screenShareSupported) {
      setPreCheckState(PrecheckType.NOT_SUPPORTED);
    }
  }, [screenShareSupported]);

  return (
    <div css={styles(isCameraAndMicAllowed)}>
      <NvIcon icon='info' size='large' className='mt-5' />
      <div className={`mic-check ${isHandheld ? 'text-small' : 'page-title'} text-center`}>
        {title}
      </div>
      <div className='background'>
        {practiceType === VideoPracticeOption.ON_SPOT && (
          <div className='text-regular text-gray-4 text-center please-check'>{scenario.recordingFormat === RecordingFormat.AUDIO ? t.FILE_UPLOAD.MIC_CHECK() : t.FILE_UPLOAD.MIC_AND_CAMERA_CHECK()}</div>
        )}
        {mediaStream ? (
          <div className='video-preview'>
            {scenario.recordingFormat === RecordingFormat.AUDIO
              ? <VideoAudioVisualizer mediaStream={mediaStream} horizontal />
              : <VideoStreamPreview showAudioVisualizer mediaStream={mediaStream} width={322} />}
          </div>
        ) : (
          <div className='webcam-view'>
            <NvIcon icon='media-practice' size='larger' />
          </div>
        )}
        {scenario.recordingFormat !== RecordingFormat.AUDIO && <div className='text-small text-gray-3 text-center webcam-check'>{t.FILE_UPLOAD.DO_YOU_SEE_WEBCAM_VIEW()}</div>}
        {isCameraAndMicAllowed && scenario.recordingFormat !== RecordingFormat.AUDIO && (
          <ScreenSharePreCheckMessage
            preCheckState={preCheckState}
            getMediaStream={getMediaStream}
          />
        )}
      </div>
      {!isHandheld && (
        <div className='button-container mt-7'>
          <Button variant='secondary' className='night' onClick={onCancel}>{t.FORM.CANCEL()}</Button>
          <Button disabled={!mediaStream} onClick={onReady}>{t.FORM.YES_READY()}</Button>
        </div>
      )}
      {isHandheld && (
        <div className='button-container'>
          <Button block variant='secondary' className='night' onClick={onCancel}>{t.FORM.CANCEL()}</Button>
          <Button block disabled={!mediaStream} onClick={onReady} className='mt-2'>{t.FORM.YES_READY()}</Button>
        </div>
      )}
    </div>
  );
};

export default GetReadyConfirmationOverlay;
