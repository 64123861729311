import { css } from '@emotion/react';
import {
  useState,
  useImperativeHandle,
  forwardRef,
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
} from 'react';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { useMediaQuery } from 'react-responsive';
import useMountEffect from 'shared/hooks/use-mount-effect';
import useTimer from 'recording/hooks/use-timer';
import NvVideoPreview from 'shared/components/nv-video-preview';
import {
  VideoPracticeActivity, VideoPracticeOption,
  VideoPracticeScenario, VideoPracticeType,
} from 'redux/schemas/models/video-practice';
import { doubleSpacing, largeSpacing, quarterSpacing, standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { black, hexToRgbaString } from 'styles/global_defaults/colors';
import { handheld, largeDesktop, notHandheld, screenXsMax } from 'styles/global_defaults/media-queries';

import PracticeActivityProgress from './practice-activity-progress';
import VideoPracticeTitle from './video-practice-title';
import CountdownTimer from './countdown-timer';
import { getTimeLimits, roundToMinutes } from './video-practice-utils';
import { config } from '../../../../config/pendo.config.json';

type ViewPracticeActivityProps = {
  practiceType: VideoPracticeType;
  scenario: VideoPracticeScenario;
  activity?: VideoPracticeActivity;
  modalStepIndex: number;
  onNextStep: () => void;
  take: number;
  videoPromptViewed: boolean;
  setVideoPromptViewed: Dispatch<SetStateAction<boolean>>;
};

const Prompt = ({ scenario, onlyVideo, onPlay }) => (onlyVideo ? (
  <div className='video-wrapper overflow-hidden'>
    <NvVideoPreview file={scenario.videoPrompt} onPlay={onPlay} />
  </div>
) : (
  <div className='prompt'>
    {/* eslint-disable-next-line react/no-danger */}
    <div className='text-prompt' dangerouslySetInnerHTML={{ __html: scenario.textPrompt }} />
    {scenario.videoPrompt && (
      <div className='video-wrapper'>
        <NvVideoPreview file={scenario.videoPrompt} onPlay={onPlay} />
      </div>
    )}
  </div>
));

const MemoizedPrompt = memo(Prompt);

const ViewPracticeActivity = forwardRef<any, ViewPracticeActivityProps>(({
  practiceType,
  scenario,
  activity,
  modalStepIndex,
  onNextStep,
  take,
  videoPromptViewed,
  setVideoPromptViewed,
}, ref) => {
  const styles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${hexToRgbaString(black, 0.95)};
    flex: 1;
    ${largeDesktop(css`
      overflow: hidden;
    `)}

    .practice-activity-progress {
      margin-top: 60px;
      margin-bottom: 50px;
    }

    .course-title-small {
      margin-bottom: ${largeSpacing}px;
      margin-left: ${standardSpacing}px;
      margin-right: ${standardSpacing}px;
    }

    .details {
      display: flex;
      justify-content: space-between;
      margin-bottom: ${quarterSpacing}px;
      width: 720px;

      .video-practice-title {
        margin-right: ${practiceType === VideoPracticeOption.ON_SPOT ? '100px' : 'initial'};
      }
    }

    .prompt {
      overflow-y: auto;
      background-color: ${black};
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;

      ${notHandheld(css`
        width: 960px;
        max-height: 480px;
        overflow-y: auto;
        flex: 1;
      `)};
    }

    .text-prompt {
      width: 720px;
      color: white;
      margin-top: ${standardSpacing}px;
      margin-bottom: ${largeSpacing}px;
    }

    .video-wrapper {
      width: 720px;
      max-width: 100%;
      .nv-video-preview, .clearfix, .jwplayer-container, .jwplayer {
        height: 100% !important;
      }
    }

    .start-recording {
      margin-top: ${tripleSpacing}px;
      margin-bottom: ${doubleSpacing}px;
    }

    ${handheld(css`
      .practice-activity-progress {
        margin-bottom: 0;
      }

      .details {
        width: 100%;
        padding-right: ${standardSpacing}px;
        padding-left: ${standardSpacing}px;

        .video-practice-title {
          margin-right: ${standardSpacing}px;
        }
      }

      .prompt {
        width: 100%;
        padding: ${standardSpacing}px;

        .text-prompt {
          width: 100%;
          margin-top: 0;
        }

        .video-wrapper {
          width: 100%;
        }
      }

      .button-container {
        width: 100%;
        padding-right: ${standardSpacing}px;
        padding-left: ${standardSpacing}px;
        margin-bottom: ${doubleSpacing}px;
      }
    `)};
  `;

  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const { timeToView } = getTimeLimits(scenario);
  const timeToViewRounded = roundToMinutes(timeToView);

  const [paused, setPaused] = useState<boolean>(false);

  const [viewCountdown, startTimer, pauseTimer, resumeTimer] = useTimer();

  const pause = () => {
    pauseTimer();
    setPaused(true);
  };

  const resume = () => {
    resumeTimer();
    setPaused(false);
  };

  const onPlay = useCallback(() => {
    setVideoPromptViewed(true);
  }, []);

  useImperativeHandle(ref, () => ({
    pause,
    resume,
  }));

  useMountEffect(() => {
    if (practiceType === VideoPracticeOption.ON_SPOT) {
      startTimer(timeToView, onNextStep);
    }
  });

  const onlyVideo = scenario.videoPrompt && !scenario.textPrompt;

  const title = practiceType === VideoPracticeOption.ON_SPOT
    ? t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.VIEW.TITLE.ON_SPOT(timeToViewRounded)
    : t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.VIEW.TITLE.WHEN_READY();

  return (
    <div css={styles}>
      <PracticeActivityProgress modalStepIndex={modalStepIndex} />
      <div className='course-title-small text-white text-center'>{title}</div>
      <div className='details'>
        <VideoPracticeTitle
          practiceType={practiceType}
          activityTitle={scenario.title}
          activity={activity}
          take={take}
        />
        {practiceType === VideoPracticeOption.ON_SPOT && (
          <CountdownTimer countdownRemainingSeconds={viewCountdown} paused={paused} />
        )}
      </div>
      <MemoizedPrompt scenario={scenario} onlyVideo={onlyVideo} onPlay={onPlay} />
      <div className='button-container'>
        <Button
          block={isHandheld}
          className='start-recording'
          onClick={onNextStep}
          pendo-tag-name={config.pendo.practice.startRecording}
          data-qa={`${config.pendo.practiceActivity.learner.startRecording}_${scenario.recordingFormat}`}
          disabled={scenario.videoPrompt && !videoPromptViewed}
        >
          {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.VIEW.START_RECORDING()}
        </Button>
      </div>
    </div>
  );
});

export default ViewPracticeActivity;
