import React from 'react';
import * as Yup from 'yup';
import { css } from '@emotion/core';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DeepPartial } from 'utility-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import t from 'react-translate';
import { AngularContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import NvIcon from 'shared/components/nv-icon';
import { gray5 } from 'styles/global_defaults/colors';
import { getFlatCourseAliases } from 'redux/selectors/course';
import { ModalWorkflow } from 'lecture_pages/components/workflows';
import { FormAttemptsSetting } from 'quizzes/components/attempts-setting';
import QuizTypeCard, { QuizType } from 'quizzes/components/quiz-type-card';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { QuestionAttempts, QuizAttempts } from 'quizzes/components/quiz-animations';
import { extraLargeSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import AngularModalContent from 'lecture_pages/components/workflows/angular-modal-content';
import { ComponentTrueType, ComponentType, LectureComponent } from 'redux/schemas/models/lecture-component';
import { ModalWorkflowContext, ModalWorkflowHandlerProps } from 'lecture_pages/components/workflows/modal-workflow';
import { config } from '../../../config/pendo.config.json';

enum ModalCase {
  TYPE = 'type',
  LEGACY = 'legacy',
  PROGRESSIVE = 'progressive',
}

type OnConfirm = (
  lectureComponent?: DeepPartial<LectureComponent>,
  skipSave?: boolean,
  customType?: ComponentTrueType,
  customWorkflow?: DeepPartial<ModalWorkflow<ComponentTrueType>>
) => Promise<void>;

type QuizTypeSelectionModalContentProps = {
  onAllQuestionViewSelect: () => void,
  onSingleQuestionViewSelect: () => void,
};

export const QuizTypeSelectionModalContent = (props: QuizTypeSelectionModalContentProps) => {
  const { onAllQuestionViewSelect, onSingleQuestionViewSelect } = props;

  const styles = css`
    .quiz-type-option {
      width: 200px;
      height: 200px;
      background-color: ${gray5};
      margin-bottom: ${threeQuartersSpacing}px;
    }
  `;

  return (
    <div css={styles} className='pl-5 pr-5 pt-1'>
      <p className='mb-6 text-regular gray-2'>{t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.ADD_QUIZ_DESCRIPTION()}</p>
      <div className='d-flex flex-row justify-content-between'>
        <QuizTypeCard
          type={QuizType.CLASSIC}
          onSelect={onAllQuestionViewSelect}
          dataQa={config.pendo.lectureEdit.legacyQuiz}
        />
        <QuizTypeCard
          type={QuizType.PROGRESSIVE}
          onSelect={onSingleQuestionViewSelect}
          dataQa={config.pendo.lectureEdit.progressiveQuiz}
        />
      </div>
    </div>
  );
};

type Props = {
  onCancel: () => void,
  onConfirm: OnConfirm,
  forwardOnModalClose: (fn: (e: Event, closeModal: Function) => void) => void,
} & ModalWorkflowHandlerProps;

const QuizModal = (props: Props) => {
  const { mode, forwardOnModalClose, lectureComponent, onCancel, onConfirm, ...rest } = props;

  const isLegacyQuiz = lectureComponent.type === 'QuizLectureComponent';

  const editStep = isLegacyQuiz ? ModalCase.LEGACY : ModalCase.PROGRESSIVE;

  const [step, setStep] = React.useState<ModalCase>(mode === 'new' ? ModalCase.TYPE : editStep);
  const { settings, setSettings } = React.useContext(ModalWorkflowContext);

  React.useLayoutEffect(() => {
    if (step === ModalCase.TYPE) {
      setSettings({
        ...settings,
        modalWidth: 800,
        title: () => t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.SELECT_QUIZ_TYPE(),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  if (step === ModalCase.TYPE) {
    const handleLegacyQuiz = () => {
      setSettings({
        ...settings,
        modalWidth: undefined,
        title: () => t.LECTURE_PAGES.COMPONENTS.QUIZ.MODAL.TITLE.ADD(),
      });
      setStep(ModalCase.LEGACY);
    };

    const openProgressiveQuizModal = () => {
      setSettings({
        ...settings,
        modalWidth: 800,
        title: () => t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.QUIZ_BASICS(),
      });
      setStep(ModalCase.PROGRESSIVE);
    };

    return (
      <QuizTypeSelectionModalContent
        onAllQuestionViewSelect={handleLegacyQuiz}
        onSingleQuestionViewSelect={openProgressiveQuizModal}
      />
    );
  }

  if (step === ModalCase.LEGACY) {
    return (
      <AngularModalContent
        {...rest}
        mode={mode}
        onCancel={onCancel}
        lectureComponent={lectureComponent}
        onConfirm={((lc?: LectureComponent) => onConfirm(lc, false, ComponentType.QUIZ, {
          adminNavigation: (catalogId, iLc: LectureComponent<ComponentType.QUIZ>) => `/${catalogId}/question_sets/${iLc.quiz.id}/edit`,
        }))}
        workflow={{
          ...rest.workflow,
          initialSettings: {
            ...rest.workflow.initialSettings,
            angularConfig: {
              templateUrl: 'lecture_pages/templates/components/quiz-edit-modal.html',
              formName: 'editQuizForm',
            },
          },
        }}
      />
    );
  }

  return (
    <ProgressiveQuizModal
      onCancel={onCancel}
      onConfirm={onConfirm}
      forwardOnModalClose={forwardOnModalClose}
    />
  );
};

type ProgressiveQuizModalProps = {
  onCancel: () => void;
  onConfirm: OnConfirm;
  isEdit?: boolean,
  initialValues?: ProgressiveQuizFormValues;
  forwardOnModalClose: (fn: (e: Event, closeModal: Function) => void) => void,
};

type ProgressiveQuizFormValues = {
  quiz: number;
  question: number;
};

const validationSchema = Yup.object().shape({
  quiz: Yup.number(),
  question: Yup.number(),
});

export const ProgressiveQuizModal = (props: ProgressiveQuizModalProps) => {
  const {
    onCancel,
    onConfirm,
    initialValues,
    isEdit = false,
    forwardOnModalClose,
  } = props;

  const dispatch = useAppDispatch();
  const courseAliases = useSelector(getFlatCourseAliases);

  const { injectServices } = React.useContext(AngularContext);
  const [StateManager] = injectServices(['StateManager']);

  const forwardOnModalCloseRef = React.useRef(forwardOnModalClose);
  forwardOnModalCloseRef.current = forwardOnModalClose;

  const [animate, setAnimate] = React.useState<'quiz' | 'question'>('quiz');

  const form = useForm<ProgressiveQuizFormValues>({
    mode: 'onChange',
    defaultValues: initialValues ?? {
      quiz: null,
      question: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const { formState, handleSubmit, watch } = form;

  const { isValid, isDirty } = formState;

  React.useEffect(() => StateManager.registerStateChangeStart(
    () => isDirty,
    'shared/templates/modal-navigate-away.html',
    'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY',
  ),
  [isDirty, StateManager]);

  React.useEffect(() => {
    forwardOnModalCloseRef.current((e: Event, closeModal: Function) => {
      if (isDirty) {
        e.preventDefault();

        dispatch(openConfirmationDialog({
          onConfirm: () => closeModal(),
          cancelText: t.FORM.CANCEL(),
          confirmText: t.FORM.YES_SURE(),
          title: t.FORM.UNSAVED_CHANGES.CLOSE_WINDOW.TITLE(),
          bodyText: t.FORM.UNSAVED_CHANGES.CLOSE_WINDOW.DESCRIPTION(),
        }));
      }
    });
  }, [isDirty, dispatch]);

  const styles = css`
    .button-bar {
      margin-top: ${extraLargeSpacing}px;
    }
  `;

  const handleSaveQuiz: SubmitHandler<ProgressiveQuizFormValues> = ({ quiz, question }) => {
    onConfirm({
      progressiveQuiz: {
        maximumAttempts: quiz,
        questionMaximumAttempts: question,
      },
    }, false, ComponentType.PROGRESSIVE_QUIZ);
  };

  const questionAttemptsFormValue = watch('question');

  const showReducingAttemptsWarning = questionAttemptsFormValue < initialValues?.question;

  return (
    <FormProvider {...form}>
      <div css={styles} className='pl-2 pr-2 pb-4'>
        <div className='text-medium mb-6 gray-2'>
          {t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.MODAL_DESCRIPTION(courseAliases)}
        </div>
        <div>
          <FormAttemptsSetting
            name='quiz'
            canBeUnlimited
            className='mb-8'
            animate={animate === 'quiz'}
            AnimationComponent={QuizAttempts}
            optionTranslation={t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.N_QUIZ_ATTEMPTS}
            placeholder={t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.SELECT_QUIZ_ATTEMPTS()}
            description={t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.QUIZ_ATTEMPT_DESCRIPTION(courseAliases)}
            dataQa={config.pendo.activities.progressiveQuiz.basicsModal.quizAttempt}
            onAnimationEnd={() => {
              setTimeout(() => {
                setAnimate('question');
              }, 500);
            }}
          />
          <FormAttemptsSetting
            name='question'
            animate={animate === 'question'}
            AnimationComponent={QuestionAttempts}
            info={t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.QUESTION_ATTEMPT_INFO()}
            optionTranslation={t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.N_QUESTION_ATTEMPTS}
            placeholder={t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.SELECT_QUESTION_ATTEMPTS()}
            description={t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.QUESTION_ATTEMPT_DESCRIPTION(courseAliases)}
            dataQa={config.pendo.activities.progressiveQuiz.basicsModal.questionAttempt}
            onAnimationEnd={() => {
              setTimeout(() => {
                setAnimate('quiz');
              }, 500);
            }}
            renderAfter={() => {
              if (showReducingAttemptsWarning) {
                return (
                  <div className='d-flex align-items-center pt-2'>
                    <NvIcon icon='warning' size='small' className='mr-3 text-danger' />
                    <div className='text-small text-danger'>{t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.REDUCING_QUESTION_ATTEMPTS_WARNING(courseAliases)}</div>
                  </div>
                );
              }

              return null;
            }}
          />
        </div>
        <div className='button-bar'>
          <Button
            className='mt-2'
            onClick={onCancel}
            variant='secondary'
            data-qa={config.pendo.activities.progressiveQuiz.basicsModal.cancel}
          >
            {t.FORM.CANCEL()}
          </Button>
          <Button
            className='mt-2'
            disabled={!isValid || !isDirty}
            onClick={handleSubmit(handleSaveQuiz)}
            data-qa={config.pendo.activities.progressiveQuiz.basicsModal[isEdit ? 'update' : 'create']}
          >
            {isEdit ? t.FORM.UPDATE() : t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.CREATE_QUIZ()}
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

export default QuizModal;
