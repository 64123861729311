/* @ngInject */
export default function GroupDirectoryMainController(
  GroupDirectoryManager,
  $stateParams,
  $window,
  $state,
  CurrentUserManager,
  CurrentCourseManager,
  RailsRoutes,
  $uibModal,
  TeamManager,
  TimelinesManager,
  ReactTimelineService,
  $timeout,
) {
  const vm = this;

  vm.currentUserManager = CurrentUserManager;
  vm.currentCourseManager = CurrentCourseManager;
  vm.manager = GroupDirectoryManager;
  vm.railsRoutes = RailsRoutes;
  vm.openCreateGroupModal = openCreateGroupModal;
  vm.RailsRoutes = RailsRoutes;

  if (GroupDirectoryManager.initialized) {
    GroupDirectoryManager.resetData();
  }

  GroupDirectoryManager.initialize({ catalogId: $stateParams.catalogId, dropdownFilter: $stateParams.groupFilter, context: 'Groups' });

  GroupDirectoryManager.fetchProfileQuestionFilters().then(GroupDirectoryManager.doSearch());

  function openCreateGroupModal() {
    return $uibModal.open({
      templateUrl: 'teams/templates/new-team-form.html',
      controller: 'TeamFormModalCtrl',
      controllerAs: 'vm',
      resolve: {
        modalOptions() {
          return {
            headerKey: 'TEAM_FORMATION.CREATE_YOUR_TEAM',
            confirmTextKey: 'FORM.CREATE',
            confirmInProgressTextKey: 'FORM.CREATING',
            model: {
              team: TeamManager.newTeam(vm.manager.teamSet),
              currentCourse: vm.currentCourseManager.course,
            },
            afterConfirm(createdGroup) {
              CurrentUserManager.requestCurrentUser();

              if (createdGroup.pointsReceived) {
                ReactTimelineService.updateTimeline(createdGroup.teamSet.lecturePageId);
                TimelinesManager.updateComponentPointsAndProgress(createdGroup.teamSet.lecturePageId, 'team_set', createdGroup.teamSet.id,
                  createdGroup.pointsReceived);
                $timeout(() => {
                  $uibModal.open({
                    templateUrl: 'shared/templates/points-modal.html',
                    windowClass: 'points-modal',
                    controller: 'PointsModalCtrl as vm',
                    resolve: {
                      pointsReceived: createdGroup.pointsReceived,
                      leaderboardPoints: createdGroup.leaderboardPoints,
                      leaderboardRank: createdGroup.leaderboardRank,
                      priorLeaderboardRank: createdGroup.priorLeaderboardRank,
                      extras: null,
                    },
                  });
                });
              } else {
                ReactTimelineService.updateTimeline(createdGroup.teamSet.lecturePageId);
                TimelinesManager.updateComponentProgress(createdGroup.teamSet.lecturePageId, 'team_set', createdGroup.teamSet.id, 'completed');
              }

              $state.go('team-profile-modal', { teamId: createdGroup.id, catalogId: $stateParams.catalogId });
            },
          };
        },
      },
    });
  }
}
