/* @ngInject */
export default function NvCompletedCourseCard(
  CurrentUserManager,
  InteroperabilityRoutes,
) {
  return {
    scope: {
      completedCourse: '=',
      roleInfo: '=?',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;

      vm.CurrentUserManager = CurrentUserManager;
      vm.InteroperabilityRoutes = InteroperabilityRoutes;

      // accessibility helper for labels
      vm.getCompletedCourseNameLabel = function (completedCourse) {
        return `completed-course-name-${completedCourse.catalogId}`;
      };
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'dashboard/templates/nv-completed-course-card.html',
  };
}
