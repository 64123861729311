/* @ngInject */
export default function ExerciseSkillsRatingLectureComponentModel(
  _,
  nvUtil,
  moment,
  $translate,
  $window,
  config,
  LectureComponentBaseModel,
  EvaluationsTaskModel,
  CurrentCourseManager,
  RailsRoutes,
) {
  class ExerciseSkillsRatingLectureComponent extends LectureComponentBaseModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      const defaults = {
        type: 'ExerciseSkillsRatingLectureComponent',
        editFormTemplateUrl: 'lecture_pages/templates/components/feedback-edit-modal.html',
        editFormController: 'FeedbackEditFormModalCtrl',
        skillsRating: {
          title: '',
          description: `<div class="froala-style-regular">${$translate.instant('LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.FEEDBACK_DESCRIPTION', {
            learnersAlias: CurrentCourseManager.course.learnersName.downcasedPluralized,
            courseAlias: CurrentCourseManager.course.offeringName.downcasedSingularized,
          })}</div>`,
          activityId: null,
          requiredToComplete: 5,
          isTodo: false,
          activityType: 'Exercise',
        },
        updateExercisesAfterCreationDeletion: true,
        isActivity: true,
        canBeCopied: true,
      };

      super(_.extend({}, defaults, attributes));

      this.__preprocess();

      if (useDefaults) {
        this.createDraft();
      }
    }

    get directiveName() {
      return 'nv-peer-evaluation-lecture-component';
    }

    __preprocess() {
      this.skillsRating = _.extend(this.skillsRating, { catalogId: this.catalogId });
      this.scheduledCommunicationsCount = this.skillsRating?.scheduledCommunicationsCount;

      this.skillsRating.expiringPointsPerPeerReview = nvUtil.getCurrentTotalPoints(
        this.skillsRating.pointsPerPeerReview,
        this.skillsRating.releaseDate,
        CurrentCourseManager.course.isDecayEnabled(),
      );
    }

    /* Admin Functions */
    setRealComponent(component) {
      this.realComponent = component;
    }

    createDraft() {
      this.feedbackDraft = _.clone(this.skillsRating);
    }

    getPayload() {
      const skillsRating = _.pick(this.skillsRating, [
        'id', 'title', 'isTodo', 'expirationDate',
        'hardDeadline', 'description', 'activityType',
      ]);
      skillsRating.activityId = this.skillsRating.exerciseId;
      skillsRating.requiredToComplete = +this.skillsRating.targetGoal;
      return { skillsRating };
    }

    saveDraft() {
      _.extend(this.skillsRating, this.feedbackDraft);
    }
  }

  ExerciseSkillsRatingLectureComponent.showModalBeforeCreate = true;

  return ExerciseSkillsRatingLectureComponent;
}
