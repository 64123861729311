import store from '../../../redux/store';
import { setTeamSet } from '../../../redux/actions/teams';

/* @ngInject */
export default function TeamFormationLectureComponentModel(
  _,
  nvUtil,
  moment,
  $translate,

  CurrentCourseManager,
  CurrentUserManager,
  TimelinesManager,
  LectureComponentBaseModel,
  TeamSetModel,
  config,
) {
  const LOCAL_STATUS_MAPPING = {
    joined_a_team: 'completed',
    created_a_team: 'completed',
  };

  class TeamFormationLectureComponent extends LectureComponentBaseModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      const defaults = {
        type: 'TeamFormationLectureComponent',
        editFormTemplateUrl: 'lecture_pages/templates/components/team-formation-edit-modal.html',
        formName: 'editTeamFormationTypeForm',
        teamSet: {
          type: 'team',
          isCourseLong: true,
          formedByStudents: null,
          teamSizeLimit: 10,
          isTodo: false,
          teamAlias: CurrentCourseManager.course.teamName.capitalizedPluralized,
        },
        isActivity: true,
      };
      super(_.extend({}, defaults, attributes));

      this.__preprocess();

      if (useDefaults) {
        this.createDraft();
      }
    }

    __preprocess() {
      this.teamSet = new TeamSetModel(this.teamSet);
      this.scheduledCommunicationsCount = this.teamSet.scheduledCommunicationsCount;
    }

    getStatus() {
      return LOCAL_STATUS_MAPPING[this.teamSet.progress] ? LOCAL_STATUS_MAPPING[this.teamSet.progress] : this.teamSet.progress;
    }

    currentTotalPoints() {
      return nvUtil.getCurrentTotalPoints(this.teamSet.totalPoints, this.releaseDate, CurrentCourseManager.course.isDecayEnabled());
    }

    leaveTeam(teamId) {
      this.teamSet.leaveTeam(teamId);
    }

    updateTeam(team) {
      this.teamSet.updateTeam(team);
    }

    updateComponentStatus(componentType, componentId, data) {
      if (componentType === 'leave_team') {
        this.leaveTeam(componentId);
      } else if (componentType === 'update_team') {
        this.updateTeam(data.team);
      }
    }

    /* Admin Functions */
    get isActivity() {
      return this.teamSet.formedByStudents;
    }

    createDraft() {
      this.teamSetDraft = _.pick(this.teamSet, ['type', 'formedByStudents', 'teamSizeLimit', 'teamAlias', 'isTodo', 'title', 'description', 'deadline']);

      if (_.isObject(this.teamSet.name)) {
        this.teamSetDraft.teamAlias = this.teamSet.name.downcasedPluralized;
      }
    }

    getPayload() {
      // TODO: Apparently this component receives aliases for the word "team" as a teamSet.name property. But,
      // we send this property back to the backend during updates and at that time it needs to be a string and not
      // the object of aliases, or else we'll accidentally save the aliases.
      // Is there a good reason to send this back at all? Seems extremely brittle
      const teamSet = _.pick(this.teamSet, ['id', 'type', 'formedByStudents', 'teamSizeLimit', 'teamAlias', 'isTodo', 'title', 'description', 'deadline']);

      if (_.isObject(teamSet.name) && !this.teamSet.teamAlias) {
        teamSet.teamAlias = teamSet.name.downcasedPluralized;
      }

      return {
        teamSet,
      };
    }

    save(...args) {
      return super.save(...args).then((res) => {
        this.__preprocess();

        if (this.teamSet.isTeam) {
          CurrentCourseManager.course.hasCourseLongTeams = true;
          CurrentCourseManager.course.courseLongTeamSet = this.teamSet;
          CurrentCourseManager.course.courseLongTeamSet.lectureComponentId = this.id;
          CurrentCourseManager.course.teamName = this.teamSet.name;
        }

        const currentEnrollment = CurrentUserManager.user.enrollments.find((enrollment) => enrollment.course.id === CurrentCourseManager.course.id);
        currentEnrollment.course[this.teamSet.isTeam ? 'teamName' : 'groupName'] = this.teamSet.name;

        const { id, name, isTeam, isGroup, formedByStudents, profileQuestions, isCourseLong, teamSizeLimit, hasProgressDashboard } = this.teamSet;
        const teamSet = {
          id,
          name,
          isTeam,
          isGroup,
          formedByStudents,
          profileQuestions,
          isCourseLong,
          teamSizeLimit,
          hasProgressDashboard,
          lecturePageId: res.lecturePageId,
        };
        store.dispatch(setTeamSet({ teamSet }));

        return this;
      });
    }

    n;

    saveDraft() {
      _.extend(this.teamSet, this.teamSetDraft);
    }

    get deleteWarningKey() {
      return 'LECTURE_PAGES.COMPONENTS.TEAM_FORMATION.DELETION_DESCRIPTION';
    }

    delete() {
      return super.delete().then(() => {
        // Remove team discussions in same lecture page
        const teamDiscussionsInLecturePage = this.lecturePage.lectureComponents.filter(lc => lc.type === 'TeamDiscussionLectureComponent');
        if (teamDiscussionsInLecturePage.length) {
          teamDiscussionsInLecturePage.forEach(teamDiscussion => {
            this.lecturePage.afterComponentDelete(teamDiscussion);
          });
        }

        if (this.teamSet.isTeam) {
          CurrentCourseManager.course.hasCourseLongTeams = false;
          CurrentCourseManager.course.courseLongTeamSet = null;
        }
      });
    }

    isTodo() {
      return this.teamSet.isTodo;
    }

    toggleToDo() {
      this.teamSet.isTodo = !this.teamSet.isTodo;
      this.save();
    }

    isRequiredForCompletion() {
      return this.teamSet.isRequiredForCompletion;
    }

    hasDeadline() {
      return !!this.teamSet.deadline;
    }

    addDefaultDeadline() {
      this.teamSet.deadline = this.defaultDeadline;
      this.save();
    }

    removeDeadline() {
      this.teamSet.deadline = null;
      this.save();
    }

    get deadline() {
      return this.teamSet.deadline;
    }

    isDueSoon() {
      return super.isDueSoon(this.teamSet.deadline);
    }

    moveToWarningMessages(targetLecturePage) {
      const warningMessages = super.moveToWarningMessages(targetLecturePage);

      if (this.teamSet.isCourseLong) {
        const targetLecturePageMoment = moment(targetLecturePage);
        const count = _.size(_.filter(this.teamSet.usedBy, (exercise) => moment(exercise.releaseDate) < targetLecturePageMoment));

        if (count) {
          warningMessages.push($translate.instant('LECTURE_PAGES.COMPONENTS.TEAM_FORMATION.MOVE',
            _.extend({ count }, CurrentCourseManager.course.getAliases())));
        }
      }

      return warningMessages;
    }
  }

  TeamFormationLectureComponent.iconClass = 'icon-team';
  TeamFormationLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.TEAM_FORMATION.DESCRIPTION';
  TeamFormationLectureComponent.unavailabilityDescriptionKey = 'LECTURE_PAGES.COMPONENTS.TEAM_FORMATION.UNAVAILABLE';
  TeamFormationLectureComponent.showModalBeforeCreate = true;
  TeamFormationLectureComponent.pendoTagName = config.pendo.lectureEdit.teamFormation;

  return TeamFormationLectureComponent;
}
