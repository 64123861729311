/* @ngInject */
export default function PeerReviewsBaseController(
  $state,
  $stateParams,
  CurrentCourseManager,
  CurrentUserManager,
  EvaluationsTaskManager,
  PageLevelManager,
  ReportsManager,
  InteroperabilityRoutes,
  ExercisesManager,
  $translate,
) {
  const vm = this;

  vm.manager = EvaluationsTaskManager;
  vm.ExercisesManager = ExercisesManager;
  vm.ReportsManager = ReportsManager;
  vm.PageLevelManager = PageLevelManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.InteroperabilityRoutes = InteroperabilityRoutes;
  vm.$stateParams = $stateParams;

  vm.subtitleSref = 'individual-submission-modal({ catalogId: vm.$stateParams.catalogId, reportId: vm.$stateParams.reportId })';

  vm.subtitleTextKey = 'EVALUATIONS.YOUR_SUBMISSION';

  if ($state.includes('evaluation-detailed-results-modal') || $state.includes('evaluation-detailed-results-page')) {
    vm.pageHeader = 'EVALUATIONS.EVALUATIONS';
    vm.showDetailed = true;
  } else if ($state.includes('evaluation-results-page') || $state.includes('evaluation-results-modal')) {
    vm.pageHeader = 'EVALUATIONS.FEEDBACK';
  } else if ($state.includes('evaluation-result-page') || $state.includes('evaluation-result-modal')) {
    vm.pageHeader = 'EVALUATIONS.FEEDBACK';
  }
}
