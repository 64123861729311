import { useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';
import NvVideoPreview from 'shared/components/nv-video-preview';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import { VideoPracticeScenario } from 'redux/schemas/models/video-practice';
import VideoPracticeScenarioCard from './video-practice-scenario-card';
import { VideoPracticeOptionsModalContext } from './video-practice-options-modal';

type SelectVideoPracticeOptionProps = {
  onCancel: (e: any) => void;
  onSubmit: (e: any) => void;
  scenario: VideoPracticeScenario;
};

const ViewPracticeScenarioOption = ({
  onCancel,
  onSubmit,
  scenario,
}: SelectVideoPracticeOptionProps) => {
  const angularServices = useContext(AngularServicesContext);
  const { catalogId, setTitle } = useContext(VideoPracticeOptionsModalContext);

  useEffect(() => {
    setTitle(t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.VIEW_PRACTICE_SCENARIO.TITLE());
  }, [setTitle]);

  return (
    <div>
      <div className='text-xl  font-weight-bold mt-4'>{scenario.title}</div>
      {scenario.textPrompt && <div className='my-3'><ResponsivelyEmbeddedAngularHTML template={scenario.textPrompt} angularServices={angularServices} /></div>}
      {scenario.videoPrompt && <NvVideoPreview file={scenario.videoPrompt} />}
      <div className='my-3'>
        <span className='font-weight-bold'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORDING_LIMIT.DESCRIPTION()}</span>
        <span className='ml-1'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORDING_LIMIT.MINS_TEXT(Math.floor(scenario.recordingLimit / 60))}</span>
        <span className='ml-1'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORDING_LIMIT.SECS_TEXT(scenario.recordingLimit % 60)}</span>
      </div>
      <VideoPracticeScenarioCard scenario={scenario} detailedView showSubmissionPrivacySetting showInsights />
      <div className='button-bar'>
        <Button variant='secondary' onClick={() => onCancel({})}>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.VIEW_PRACTICE_SCENARIO.BACK_TO_LIST()}</Button>
        <Button variant='primary' onClick={() => onSubmit(scenario)}>
          {scenario.reusable === 'reusable' || scenario.createdInCourse?.catalogId === catalogId
            ? t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.ADD_TO_MY_LESSON()
            : t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.DUPLICATE_SCENARIO_AND_ADD()}
        </Button>
      </div>
    </div>
  );
};


export default ViewPracticeScenarioOption;
