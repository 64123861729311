
import { css } from '@emotion/react';
import { useState, useReducer, useEffect, useContext, Fragment } from 'react';
import t from 'react-translate';

// contexts
import { AngularServicesContext } from 'react-app';
import {
  initialState,
  reducer,
  CommunicationDispatch,
  CommunicationAction,
  TriggerType,
  // CommunicationAction,
} from 'communications/course_communications/contexts/communication-context';

// styles
import { gray6 } from 'styles/global_defaults/colors';
import { createGridStyles } from 'styles/global_defaults/scaffolding';

// Actions
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getCourseWelcomeEmail } from 'redux/actions/course-communications';
import { getJourney } from 'redux/selectors/learning-journeys';
import { fetchJourney } from 'redux/actions/learning-journeys';

// components
import { NvTab, NvResponsiveTabsDisplayType } from 'shared/components/nv-responsive-tabs';
import { NvResponsiveTabsRow } from 'shared/components/nv-responsive-tabs-row';
import AutomatedCommunications from 'communications/journey_communications/components/automated-communications';
import DraftCommunications from 'communications/course_communications/components/draft-communications';
import CommunicationModal from 'communications/course_communications/components/communication-modal/communication-modal';
import ScheduledCommunications from 'communications/course_communications/components/scheduled-communications';
import { setCurrentCatalogId, unsetCurrentCatalogId } from 'redux/actions/courses';
import { Button } from 'react-bootstrap';
import NvIcon from 'shared/components/nv-icon';
import { CommunicationType } from 'redux/schemas/models/course-communication';

enum Tab {
  AUTOMATED = 'automated',
  SCHEDULED = 'scheduled',
  DRAFTS = 'drafts',
}

const styles = css`
  .tab-search-row {
    border-bottom: 1px solid ${gray6};

    .page-title {
      font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
    }

    .bs4-dropdown .bs4-dropdown-menu .bs4-dropdown-item {
      white-space: nowrap;
    }
  }

  .tab-content {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    /* Journey header (60px) + Tab header (60px) + Border bottom (1px) */
    height: calc(100vh - 121px);
  }

  .bs4-btn:focus {
    outline: none;
    box-shadow: none;
  }

  .container {
    max-width: 800px;
  }

  .clear-btn {
    cursor: pointer;
  }
`;

const JourneyCommunications = (props) => {
  const { $state } = useContext(AngularServicesContext);
  const appDispatch = useAppDispatch();
  const { $location } = useContext(AngularServicesContext);
  const [activeTab, setActiveTab] = useState(Tab.AUTOMATED);
  const tabParam = $location?.search()?.tab as Tab;
  const { catalogId } = $state.params;
  const currentJourney = useSelector((state) => getJourney(state, catalogId) || {});

  const [State, dispatch] = useReducer(reducer, initialState);

  // Unset the catalogId only when unmounting
  useEffect(() => () => {
    appDispatch(unsetCurrentCatalogId());
  }, []);

  useEffect(() => {
    if (currentJourney?.catalogId) {
      appDispatch(setCurrentCatalogId({
        catalogId: currentJourney.catalogId,
        id: currentJourney.id,
      }));
    }
  }, [currentJourney]);

  useEffect(() => {
    // If welcomeEmailEnabled doesn't have a value (i.e. null or undefined),
    // then ask for the Journey info to get the data needed.
    // Also to get the collection list we need the collectionIds.
    // If they are not present, we need to get them.
    if (typeof currentJourney.welcomeEmailEnabled !== 'boolean'
    || !currentJourney.collectionIds) {
      appDispatch(
        fetchJourney({ catalogId }),
      );
    } else if (currentJourney.welcomeEmailEnabled) {
      // If welcomeEmailEnabled has a value and is true, then get the
      // welcome email info.
      appDispatch(getCourseWelcomeEmail({ catalogId: currentJourney.catalogId }));
    }
  }, [currentJourney.welcomeEmailEnabled, currentJourney.collectionIds]);

  useEffect(() => {
    if (Object.values(Tab).includes(tabParam)) {
      setActiveTab(tabParam);
    }
  }, [tabParam]);

  const tabs: NvTab[] = [
    {
      text: t.JOURNEY_COMMUNICATIONS.TABS.AUTOMATED(),
      onClick: () => setActiveTab(Tab.AUTOMATED),
    },
    {
      text: t.JOURNEY_COMMUNICATIONS.TABS.SCHEDULED(),
      onClick: () => setActiveTab(Tab.SCHEDULED),
    }, {
      text: t.JOURNEY_COMMUNICATIONS.TABS.DRAFTS(),
      onClick: () => setActiveTab(Tab.DRAFTS),
    },
  ];

  const showModal = (type: CommunicationType) => {
    dispatch({
      type: CommunicationAction.SHOW_MODAL,
      triggerType: TriggerType.CREATE,
      communicationType: type,
      activityType: null,
      activityId: null,
      lecturePageId: null,
      communicationId: null,
    });
  };

  const getManualButtons = () => {
    const manualButtons = [];
    switch (activeTab) {
      case Tab.SCHEDULED:
        manualButtons.push(
          <Button className='d-flex align-items-center text-medium light py-2' onClick={() => showModal(CommunicationType.MANUAL_EMAIL)}>
            <NvIcon size='smallest' icon='create-new-post' />
            <span className='px-1'>{t.JOURNEY_COMMUNICATIONS.SCHEDULED.CREATE_NEW()}</span>
          </Button>,
        );
        break;
      case Tab.AUTOMATED:
        manualButtons.push(
          <Button className='d-flex align-items-center text-medium light py-2' onClick={() => showModal(CommunicationType.AUTOMATED_JOURNEY_EMAIL)}>
            <NvIcon size='smallest' icon='create-new-post' />
            <span className='px-1'>{t.JOURNEY_COMMUNICATIONS.AUTOMATED.CREATE_NEW()}</span>
          </Button>,
        );
        break;
      default:
        break;
    }
    return manualButtons;
  };

  return (
    <CommunicationDispatch.Provider value={{ dispatch, State }}>
      <div css={styles}>
        {
          typeof currentJourney.welcomeEmailEnabled === 'boolean'
          && (
            <Fragment>
              <CommunicationModal catalogId={catalogId} />
              <div className='tab-search-row'>
                <NvResponsiveTabsRow
                  style={createGridStyles(1, 2)}
                  defaultTabs={tabs}
                  tabType={NvResponsiveTabsDisplayType.TEXT_ONLY}
                  iconBtns={getManualButtons()}
                  revertActiveTab={tabParam && Object.values(Tab).indexOf(tabParam)}
                />
              </div>
              <div className='tab-content pb-5' id='communications-tab-content'>
                <div className='container'>
                  {activeTab === Tab.AUTOMATED && <AutomatedCommunications welcomeEmailEnabled={currentJourney.welcomeEmailEnabled} />}
                  {activeTab === Tab.SCHEDULED && <ScheduledCommunications />}
                  {activeTab === Tab.DRAFTS && <DraftCommunications />}
                </div>
              </div>
            </Fragment>
          )
        }
      </div>
    </CommunicationDispatch.Provider>
  );
};

export default JourneyCommunications;
