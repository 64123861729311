/* @ngInject */
export default function BulkEditDeadlineController(
  $stateParams,
  $uibModalInstance,
  CurrentCourseManager,
  TimelineModel,
  section,
  moment,
  _,
  ConfirmationOverlays,
) {
  const vm = this;
  vm.section = section;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.moment = moment;
  vm.edited = false;
  vm.default = {
    deadline: null,
    hardDeadline: false,
  };
  vm.activities = [];

  initialize();

  function initialize() {
    let currLecturePage; let currLectureActivities; let
      groupByLectureId;

    if (section.type === 'LecturePage') {
      if (!vm.section.lectureActivities) {
        vm.section.getActivities().then((result) => {
          preprocessActivities();
        });
      } else {
        preprocessActivities();
      }
    } else {
      TimelineModel.getActivitiesInSection($stateParams.catalogId, vm.section.id).then((result) => {
        vm.section.lectureActivities = [];
        _.each(_.groupBy(result, 'lecturePageId'), (lectureActivities, lecturePageId) => {
          currLecturePage = _.findWhere(vm.section.getAllLecturePages(), { id: parseInt(lecturePageId, 10) });
          currLectureActivities = currLecturePage.setupComponents(lectureActivities);
          vm.section.lectureActivities = vm.section.lectureActivities.concat(currLectureActivities);
        });

        preprocessActivities();
      });
    }
  }

  function preprocessActivities() {
    vm.showHardDeadlineOption = false;
    let currDeadline;

    vm.activities = _.filter(vm.section.lectureActivities, (activity) => activity.deadline || activity.expirationDate);

    // Set default deadline to last deadline among activities
    _.each(vm.activities, (activity) => {
      vm.showHardDeadlineOption = vm.showHardDeadlineOption || !!activity.canHaveHardDeadline;
      currDeadline = activity.deadline || activity.expirationDate;
      vm.default.deadline = !vm.default.deadline || vm.default.deadline < currDeadline ? currDeadline : vm.default.deadline;
    });
  }

  vm.getActivityDisplayDeadline = (item) => {
    let deadline;

    if (vm.edited) {
      ({ deadline } = vm.default);
    } else {
      deadline = item.expirationDate ? item.expirationDate : item.deadline;
    }

    return moment(deadline).format('MOMENT.MONTH_DAY_YEAR_TIME_IN_FULL');
  };

  vm.setEdited = function () {
    vm.edited = true;
  };

  vm.submit = function () {
    // handles bot lecture page and lecture sections
    const isChangingBeforeCurrentDueDate = _.some(vm.section.lectureActivities, (la) => moment().isBefore(moment(la.deadline)));
    const isScheduledCommunicationExistInAnyActivity = _.some(vm.section.lectureActivities, (la) => la.scheduledCommunicationsCount);

    const showWarning = isChangingBeforeCurrentDueDate && isScheduledCommunicationExistInAnyActivity;
    if (showWarning) {
      const modalInstance = ConfirmationOverlays.openConfirmationModal('lecture_pages/templates/due-date-change-warning-overlay.html');

      modalInstance.result.then(() => {
        updateDeadlineDates();
      });
    } else {
      updateDeadlineDates();
    }
  };

  function updateDeadlineDates() {
    vm.section.bulkUpdateActivityDeadline(vm.default.deadline, vm.default.hardDeadline).then($uibModalInstance.close);
  }
}
