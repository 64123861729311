/* @ngInject */
export default function ReportsManager(
  _,
  $state,
  $stateParams,
  nvUtil,
  $q,

  $uibModal,
  PageLevelManager,

  CurrentCourseManager,
  CurrentUserManager,
  CurrentPermissionsManager,

  ExercisesResources,
  ExercisesManager,
  ExerciseModel,
  EvaluationsTaskModel,
  ReportsResources,
  ReportModel,
  AlertMessages,
) {
  const ALL_EXERCISES_OPTION = {
    titleKey: 'EXERCISES.ALL_ASSIGNMENTS',
    id: 0,
    alwaysEnabled: true,
  };

  const DEFAULT_SORT_OPTIONS = [{
    displayName: 'EXERCISES.TRENDING',
    value: 'trending',
  }, {
    displayName: 'EXERCISES.MOST_RECENT',
    value: 'created',
  }];

  const SORT_OPTION_MOST_LIKED = {
    displayName: 'EXERCISES.MOST_LIKED',
    value: 'likes',
  };

  const DEFAULT_FILTER_OPTIONS = [{
    displayName: 'EXERCISES.ALL_SUBMISSIONS',
    value: null,
  }, {
    displayName: 'EXERCISES.SUBMISSIONS_COMMENTED_ON',
    value: 'my_comments',
  }, {
    displayName: 'EXERCISES.SUBMISSIONS_NOT_COMMENTED_ON',
    value: 'not_my_comments',
  }];

  const FILTER_OPTION_MY_LIKES = {
    displayName: 'EXERCISES.SUBMISSIONS_I_LIKED',
    value: 'my_likes',
  };

  const SUBMISSION_APPROVAL_DASHBOARD = 'SUBMISSION_APPROVAL_DASHBOARD';
  const SUBMISSION_GALLERY = 'SUBMISSION_GALLERY';

  const PAGE_SIZE = 18;
  let pageNumber = 1;

  const cannotApproveAnySubmissions = false;
  let previousSearchRequest;

  const _this = {
    // constants
    SUBMISSION_APPROVAL_DASHBOARD,
    SUBMISSION_GALLERY,

    // data
    isInitialized: false,


    // filter, sort, search data
    reportsCatalogId: null, // should only be used for reports not for individual submissions
    reports: null,

    sortOptions: null,
    filterOptions: null,
    selectedFilter: null,
    displayQueryTerm: null, // query from user
    queryTerm: null,
    searchMode: false,

    errorState: null,
    canRequestNextPage: true,
    hasQueryTermSearchResults: false,
    loadingResults: false,

    currentReportCourse: null, // used for individual submission: check if user has access
    currentReport: null, // individual sumission view

    // submission approval
    coursesNeedingApproval: null,
    selectedCourse: null,

    // functions
    initialize,
    reinitialize,

    // filter specific
    selectExerciseFilter,
    selectSort,
    selectFilterOption,
    toggleSearchMode,
    handleSearchInput,
    clearSearchTerm,
    countFilters,
    filtersAreDefault,

    // approval
    selectCourseForApproval,

    // actual search
    doSearch,
    loadAdditionalResults,
    resetErrorState,

    // single submission view
    getSingleSubmission,
    canCurrentUserEvaluate,
    updateSelectedExercise,
    getPreviousReport,
    getNextReport,

    markObsolete,
    getApprovalExercises,
  };

  // currently only time we do search & set sorts/filters are for submission gallery
  // this should only be used for submission gallery & submission approval etc
  function initialize(attributes = {}) {
    // possible atttributes
    const ATTRIBUTE_DEFAULTS = {
      scope: null, // currently only supports 'SUBMISSION_APPROVAL_DASHBOARD' & 'SUBMISSION_GALLERY'
      reportsCatalogId: null, //
      filterOption: null, //
      selectedExercise: null, //
      reports: null, // if submissions list already sent from lecture page, e.g. public evals or submissions discovery
    };

    const isFullAttributesMatch = _.every(_.keys(ATTRIBUTE_DEFAULTS), (key) => attributes[key] === _this[key]);

    if (!_this.isInitialized || !isFullAttributesMatch) {
      _this.reports = [];

      _.extend(_this, ATTRIBUTE_DEFAULTS, attributes);

      if (!_this.selectedExercise && !$stateParams.exerciseId) {
        _this.selectedExercise = ALL_EXERCISES_OPTION;
      }

      _this.sortOptions = DEFAULT_SORT_OPTIONS;
      _this.filterOptions = DEFAULT_FILTER_OPTIONS;
      pageNumber = 1;
      _this.canRequestNextPage = true;

      if (_this.scope === SUBMISSION_APPROVAL_DASHBOARD) {
        _this.performingInitialSearch = true;
        doSearch();
        _this.performingInitialSearch = false;
      } else if (_this.scope === SUBMISSION_GALLERY) {
        ExerciseModel.getGalleryEnabledExercises(_this.reportsCatalogId).then((exerciseList) => {
          _this.allExercises = exerciseList || [];
          _this.allExercises.unshift(ALL_EXERCISES_OPTION);

          updateLikesFilterAndSortOptions();

          setFilterDefaults();

          // override default exercise option if passed in as an attribute
          if ($stateParams.exerciseId) {
            _this.selectedExercise = _.findWhere(_this.allExercises, { id: $stateParams.exerciseId });

            // if the exercise param is bad/not found, pretend like the gallery is empty
            if (!_this.selectedExercise) {
              _this.errorState = 'empty';
            }
          }

          _this.performingInitialSearch = true;
          doSearch();
          _this.performingInitialSearch = false;
        });
      }

      _this.isInitialized = true;
      clearSearchTerm(false); // Reset the search in case we're re-visiting from another page
    }
  }

  function reinitialize() {
    _this.currentReportCourse = null;
    _this.currentReport = null;
    _this.reports = [];
  }


  /** Filter/Sort Functions - Start * */
  function updateLikesFilterAndSortOptions() {
    const isLikesEnabled = calculateIsLikesEnabled();

    /* Update Sort Options to include/exclude 'Most Liked' */
    const indexOfExistingLikeSort = _.findIndex(_this.sortOptions, { value: 'likes' });
    let itemExists = indexOfExistingLikeSort !== -1;

    // delete if exists and likes are disabled
    if (itemExists && !isLikesEnabled) {
      _this.sortOptions.splice(indexOfExistingLikeSort, 1);
      // add if does not exist and likes are enabled
    } else if (!itemExists && isLikesEnabled) {
      _this.sortOptions.splice(_this.sortOptions.length - 1, 0, SORT_OPTION_MOST_LIKED);
    }

    /* Update Filter Options to include/exclude 'Sumibssions I Liked' */
    const indexOfExistingLikesFilter = _.findIndex(_this.filterOptions, { value: 'my_likes' });
    itemExists = indexOfExistingLikesFilter !== -1;

    if (itemExists && !isLikesEnabled) {
      _this.filterOptions.splice(indexOfExistingLikesFilter, 1);
    } else if (!itemExists && isLikesEnabled) {
      _this.filterOptions.splice(_this.filterOptions.length - 1, 0, FILTER_OPTION_MY_LIKES);
    }
  }

  function setSearchModeSortValues() {
    if (_this.searchMode && !_.findWhere(_this.sortOptions, { value: 'relevance' })) {
      _this.sortOptions.unshift({
        displayName: 'EXERCISES.MOST_RELEVANT',
        value: 'relevance',
      });

      _this.selectSort('relevance');
    } else if (!_this.searchMode) {
      const relevanceSortIndex = _.findIndex(_this.sortOptions, { value: 'relevance' });

      if (relevanceSortIndex > -1) {
        _this.sortOptions.splice(relevanceSortIndex, 1);
        _this.selectSort(_this.sortOptions[0].value);
      }
    }
  }

  function countFilters() {
    return (_this.selectedExercise?.id !== 0 ? 1 : 0) + (_this.selectedFilter?.value !== null ? 1 : 0);
  }

  function setFilterDefaults() {
    _this.sortOrder = _.findWhere(_this.sortOptions, { value: 'created' });
    _this.selectedExercise = ALL_EXERCISES_OPTION;
    _this.selectedFilter = _.first(_this.filterOptions);
    _this.selectedCourse = null;
    pageNumber = 1;
    _this.canRequestNextPage = true;
  }

  function selectExerciseFilter(exercise) {
    _this.selectedExercise = exercise;
    _this.loadingResults = true;

    if (_this.scope === SUBMISSION_GALLERY) {
      _this.selectedEvaluationsTask = new EvaluationsTaskModel(_.extend(_this.selectedExercise.customQuestions, { catalogId: exercise.catalogId }));
      _this.selectedEvaluationsTask.expiringPointsPerPeerReview = nvUtil.getCurrentTotalPoints(
        _this.selectedEvaluationsTask.pointsPerPeerReview, _this.selectedEvaluationsTask.releaseDate, CurrentCourseManager.course.isDecayEnabled(),
      );
    }

    $state.go($state.$current, { exerciseId: exercise.id, reload: false }, { notify: false });
    if (_this.scope === SUBMISSION_GALLERY) {
      updateLikesFilterAndSortOptions();
    }
    doSearch();
  }

  function selectSort(newValue) {
    _this.sortOrder = _.findWhere(_this.sortOptions, { value: newValue });
    doSearch();
  }

  function selectFilterOption(filterOption) {
    _this.selectedFilter = filterOption;
    $state.go($state.$current, { filterOption: filterOption.value, reload: false }, { notify: false });
    doSearch();
  }

  function toggleSearchMode(boolVal) {
    _this.searchMode = boolVal;
  }

  function handleSearchInput() {
    // reset page number if input is reset to empty
    if (!_this.queryTerm || !_this.queryTerm.length) {
      pageNumber = 1;
    }
    _this.reports = [];
    doSearch();
  }

  function clearSearchTerm(shouldPerformSearch = true) {
    _this.queryTerm = null;
    _this.displayQueryTerm = null;
    _this.hasQueryTermSearchResults = false;
    resetErrorState(shouldPerformSearch);
  }

  function filtersAreDefault() {
    if (_this.scope === SUBMISSION_APPROVAL_DASHBOARD) {
      return !_this.queryTerm && !_this.selectedCourse && _this.selectedExercise === ALL_EXERCISES_OPTION;
    } if (_this.scope === SUBMISSION_GALLERY) {
      if (_this.sortOrder) {
        return _this.sortOrder.value === 'created' && _this.selectedExercise.id === 0 && _this.selectedFilter.value === null;
      }
    }

    return true;
  }
  /** Filter/Sort Functions - End * */


  /** Submission Approval - Start * */
  function getApprovalExercises(refreshDataIfAllowed = false) {
    const deferred = $q.defer();

    if (cannotApproveAnySubmissions) {
      deferred.reject();
    } else if (!_this.coursesNeedingApprovalSummary || refreshDataIfAllowed) {
      ExercisesResources.exercisesForSubmissionApproval({}, {}).$promise
        .then((response) => {
          _this.coursesNeedingApproval = [];
          _this.coursesNeedingApprovalSummary = {
            courseCount: 0,
            submissionCount: 0,
          };

          if (!response.result) {
            // cannotApproveAnySubmissions = true; commenting out because backend returns nothing if you have nothing to approve but still have the rights to do so
            deferred.reject();
            return;
          }

          const courseIdToApprovalExercises = _.groupBy(response.result, (exercise) => exercise.courseId);

          _.each(courseIdToApprovalExercises, (approvalExercises, courseId) => {
            const course = _.pick(CurrentUserManager.coursesHash[courseId], ['id', 'name', 'catalogId']);

            course.approvalExercises = [ALL_EXERCISES_OPTION, ...approvalExercises];

            const courseSubmissionCount = _.reduce(approvalExercises, (memo, approvalExercise) => memo + approvalExercise.pendingSubmissionsCount, 0);

            if (courseSubmissionCount) {
              _this.coursesNeedingApprovalSummary.courseCount += 1;
              _this.coursesNeedingApprovalSummary.submissionCount += courseSubmissionCount;
            }

            _this.coursesNeedingApproval.push(course);
          });

          _this.coursesNeedingApproval.sort((a, b) => a.name.localeCompare(b.name));

          if (_this.coursesNeedingApproval.length === 1) {
            _this.selectedCourse = _.first(_this.coursesNeedingApproval);
          }

          deferred.resolve(_this.coursesNeedingApproval);
        });
    } else {
      if (_this.coursesNeedingApproval?.length === 1) {
        _this.selectedCourse = _.first(_this.coursesNeedingApproval);
      }

      deferred.resolve(_this.coursesNeedingApproval);
    }

    return deferred.promise;
  }

  function selectCourseForApproval(courseNeedingApproval) {
    _this.selectedCourse = courseNeedingApproval;
    _this.selectedExercise = ALL_EXERCISES_OPTION;

    doSearch();
  }
  /** Submission Approval - End * */


  /** Actual Search - Start * */
  /* Set data in current manager after backend fetch */
  function __transformReport(submission) {
    const report = new ReportModel(_.extend(ReportModel.normalizeSubmissionData(submission), {
      catalogId: (submission.exercise?.catalogId)
         || _this.reportsCatalogId,
    }));

    report.exercise = report.exercise && new ExerciseModel(report.exercise);

    return report;
  }

  function __setReport(submission) {
    _this.currentReport = __transformReport(submission);
    const indexOfExisting = _.findIndex(_this.reports, (report) => report.id === submission.id);

    if (indexOfExisting > -1) {
      _this.reports.splice(indexOfExisting, 1, _this.currentReport);
    }
  }

  function __setReports(rawReports = [], appendBool = false) {
    if (!appendBool) {
      _this.reports = [];
    }
    _.each(rawReports, (submission) => {
      if (!_.findWhere(_this.reports, { id: submission.id })) {
        _this.reports.push(__transformReport(submission));
      }
    });

    // if we have results and query term exists, display the header for search results
    if (rawReports?.length && _this.queryTerm?.length) {
      _this.hasQueryTermSearchResults = true;
    }

    if (_this.scope === SUBMISSION_APPROVAL_DASHBOARD) {
      _this.canRequestNextPage = rawReports.length === PAGE_SIZE && _this.reports.length < _this.submissionPendingApprovalCount;
    } else if (_this.scope === SUBMISSION_GALLERY) {
      _this.canRequestNextPage = rawReports.length === PAGE_SIZE;
    }

    if (_this.reports.length) {
      _this.errorState = null;
    }
  }

  function doSearch(shouldAppendResults = false) {
    _this.loadingResults = true;

    if (!shouldAppendResults) {
      _this.reports = [];
      pageNumber = 1;

      if (previousSearchRequest) {
        previousSearchRequest.$cancelRequest();
      }
    }

    _this.queryTerm = _this.displayQueryTerm;

    if (_this.queryTerm?.length) {
      setSearchModeSortValues();
    }

    if (_this.scope === SUBMISSION_APPROVAL_DASHBOARD) {
      const searchPayload = {
        pageSize: PAGE_SIZE,
        page: pageNumber,
      };

      if (_this.queryTerm) {
        searchPayload.queryTerm = _this.queryTerm;
      }

      if (_this.selectedCourse) {
        searchPayload.courseList = [_this.selectedCourse.id];
      }

      if (_this.selectedExercise !== ALL_EXERCISES_OPTION) {
        searchPayload.exerciseList = [_this.selectedExercise.id];
      }

      previousSearchRequest = ReportsResources.searchSubmissionsForApproval({}, searchPayload,
        (response) => {
          if (_this.filtersAreDefault() && !_this.queryTerm && pageNumber === 1) {
            _this.errorState = 'empty'; // empty gallery denotes no submissions yet
          } else if (pageNumber === 1) {
            _this.errorState = 'noResults'; // no results while searching/filtering
          }

          if (response.result) {
            _this.submissionPendingApprovalCount = response.result.count;
            __setReports(response.result.reports, shouldAppendResults);
          }

          _this.loadingResults = false;
        }, (errorResponse) => {
          if (errorResponse.data?.error?.code === 'error.service_unavailable') {
            _this.errorState = 'searchUnavailable';
          }

          _this.loadingResults = false;

          if (errorResponse.status === 403) {
            if (!CurrentUserManager.hasLoggedIn()) {
              $state.go('users.authentications.sign-in');
            } else {
              $state.go('dashboard');
            }
          }
        });
    } else if (_this.scope === SUBMISSION_GALLERY) {
      previousSearchRequest = ReportsResources.searchSubmissions({
        catalogId: _this.reportsCatalogId,
        exerciseId: _this.selectedExercise.id || null,
        order: _this.sortOrder.value,
        filter: _this.selectedFilter.value,
        pageSize: PAGE_SIZE,
        page: pageNumber,
        queryTerm: _this.queryTerm,
      }, (resourceObj) => {
        if (_this.filtersAreDefault() && !_this.queryTerm && pageNumber === 1) {
          _this.errorState = 'empty'; // empty gallery denotes no submissions yet
        } else if (pageNumber === 1) {
          _this.errorState = 'noResults'; // no results while searching/filtering
        }

        if (resourceObj.result) {
          __setReports(resourceObj.result, shouldAppendResults);
        }

        _this.loadingResults = false;
      }, (errorResponse) => {
        if (errorResponse.data?.error?.code === 'error.service_unavailable') {
          _this.errorState = 'searchUnavailable';
        }

        _this.loadingResults = false;

        if (errorResponse.status === 403) {
          if (!CurrentUserManager.hasLoggedIn()) {
            $state.go('users.authentications.sign-in', { catalogId: _this.reportsCatalogId });
          } else if (CurrentUserManager.hasEnrolledInCourse(_this.currentReportCourse)) {
            $state.go('course-home', { catalogId: _this.reportsCatalogId });
          } else {
            $state.go('course-flyer', { catalogId: _this.reportsCatalogId });
          }
        }
      });
    }
  }

  function loadAdditionalResults() {
    if (_this.reports?.length && !_this.loadingResults) {
      pageNumber += 1;
      doSearch(true);
    }
  }

  function resetErrorState(shouldPerformSearch = true) {
    _this.errorState = null;
    setFilterDefaults();
    setSearchModeSortValues();

    if (shouldPerformSearch) {
      doSearch();
    }
  }
  /** Actual Search - End * */


  /** Single Submission View - Start * */
  function getSingleSubmission(catalogId, reportId) {
    return ReportsResources.getSingleSubmission({
      catalogId,
      reportId,
    }, (resource) => {
      __setReport(resource.result);
      setCurrentCourse(catalogId);

      if (CurrentUserManager.hasEnrolledInCourse(_this.currentReportCourse)) {
        // fetch whether you can edit the submission or not
        ReportsResources.canEditSubmission({ catalogId, reportId }).$promise.then((response) => {
          _this.currentReport.canEdit = response.result;
        });
      } else {
        _this.currentReport.canEdit = false;
      }
    }, (error) => {
      if (!CurrentUserManager.hasLoggedIn()) {
        return $state.go('users.authentications.sign-in', { catalogId });
      }

      let requestCourse = null;

      if (CurrentUserManager.coursesHashByCatalogId[catalogId]) {
        requestCourse = CurrentUserManager.coursesHashByCatalogId[catalogId];
      } else {
        requestCourse = CurrentCourseManager.course;
      }

      const afterError = () => {
        if ($state.current.name === 'individual-submission-modal') {
          PageLevelManager.callL4CloseCallbacks();
        } else if (CurrentUserManager.hasEnrolledInCourse(requestCourse)) {
          $state.go('course-home', { catalogId });
        } else {
          $state.go('course-flyer', { catalogId });
        }
      };

      if (error.status === 500) {
        AlertMessages.error('FORM.OOPS', 'FORM.ERROR_SOMETHING_WRONG');
        afterError();
      } else {
        $uibModal.open({
          backdropClass: 'modal-overlay-backdrop',
          templateUrl: 'team_workspace/templates/submission-permissions-error-overlay.html',
          windowClass: 'modal-overlay',
        }).closed.then(afterError);
      }
    }).$promise;
  }

  function canCurrentUserEvaluate() {
    return (CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isTeachingAssistant())
      && _this.currentReport
      && _this.currentReport.exercise
      && _this.currentReport.exercise.canEvaluate
      && !_this.currentReport.isPublicFeedbackOn;
  }

  function updateSelectedExercise() {
    if ($stateParams.exerciseId && $stateParams.exerciseId !== 0) {
      _this.loadingResults = true;
      ExercisesManager.getExercise(_this.reportsCatalogId, $stateParams.exerciseId).then(() => {
        _this.selectedExercise = ExercisesManager.currentExercise;
        _this.selectedEvaluationsTask = new EvaluationsTaskModel(_.extend(_this.selectedExercise.customQuestions, { catalogId: _this.reportsCatalogId }));
        _this.selectedEvaluationsTask.expiringPointsPerPeerReview = nvUtil.getCurrentTotalPoints(
          _this.selectedEvaluationsTask.pointsPerPeerReview, _this.selectedEvaluationsTask.releaseDate, CurrentCourseManager.course.isDecayEnabled(),
        );

        _this.loadingResults = false;
      });
    }
  }

  function getPreviousReport() {
    if (!_this.currentReport || !_this.reports) {
      return null;
    }

    const index = _.findIndex(_this.reports, { id: _this.currentReport.id }) - 1;

    if (index < 0) {
      return _this.reports[_this.reports.length - 1];
    }
    return _this.reports[index];
  }

  function getNextReport() {
    if (!_this.currentReport || !_this.reports) {
      return null;
    }

    const index = _.findIndex(_this.reports, { id: _this.currentReport.id }) + 1;

    if (index >= _this.reports.length) {
      return _this.reports[0];
    }
    return _this.reports[index];
  }

  function markObsolete(reportId) {
    if (_this.currentReport?.id === reportId) {
      _this.currentReport = null;
    }
  }
  /** Single Submission View - End * */

  /* Private Helpers */
  function setCurrentCourse(catalogId) {
    if (CurrentUserManager.hasLoggedIn() && CurrentUserManager.coursesHashByCatalogId[catalogId]) {
      _this.currentReportCourse = CurrentUserManager.coursesHashByCatalogId[catalogId];
    } else {
      _this.currentReportCourse = CurrentCourseManager.course;
    }
  }

  function calculateIsLikesEnabled() {
    if (!_this.selectedExercise || _this.selectedExercise.id === 0) {
      // all assignments
      return _.any(_this.allExercises, (report) => report.isVotable);
    }
    // assignment filter
    return _this.selectedExercise.isVotable;
  }

  return _this;
}
