import React, { useEffect, useState } from 'react';

/**
 * Hook for detecting a top scroll within an element
 * @param elementRef DOM element to detect the top scroll
 * @returns the boolean indicating that there is a top scroll
 */
export const useScrollTop = (elementRef: HTMLElement): boolean => {
  const [isTopScrolling, setIsTopScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = (event: Event): void => {
      setIsTopScrolling(elementRef.scrollTop > 0);
    };

    if (elementRef) {
      elementRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (elementRef) {
        elementRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [elementRef]);

  return isTopScrolling;
};

/**
 * Hook for detecting a left scroll within an element
 * @param elementRef DOM element to detect the left scroll
 * @returns the boolean indicating that there is a left scroll
 */
export const useScrollLeft = (elementRef: HTMLElement): boolean => {
  const [isLeftScrolling, setIsLeftScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = (event: Event): void => {
      setIsLeftScrolling(elementRef.scrollLeft > 0);
    };

    if (elementRef) {
      elementRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (elementRef) {
        elementRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [elementRef]);

  return isLeftScrolling;
};

export default useScrollTop;
