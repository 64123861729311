/* @ngInject */
export default function LearnerProfileManager(
  _,
  UserModel,
  LearnerProfileModel,
  CurrentUserManager,
  TeamManager,
  CurrentCourseManager,
  ConfirmationOverlays,
  PostModel,
  CommentModel,
  ReplyModel,
  $stateParams,
  ReportModel,
  MigratedFields,
) {
  const manager = {
    getProfile,
    getCourseProfile,
    getCourseProfileQuestions,
    getData,
    isVieweeAdmin,
    isViewerTeamLead,
    isViewerGroupAdmin,
    processVieweeMemberships,
    processViewerMemberships,
    calculateCommonGroups,
    inviteMember,
    uninviteMember,

    // data
    editableData: {},
    demandedUserId: null,
    programProfileQuestionCount: 0,
    courseProfileQuestionCount: 0,
    accountLevelOrgSettings: null,
  };

  function getProfile(userId) {
    manager.demandedUserId = userId;

    return LearnerProfileModel.getProfile(userId).then((profile) => {
      manager.profileUser = new UserModel(profile.data);
      manager.isViewingSelfProfile = (CurrentUserManager.user.id === manager.profileUser.id);

      getData(manager.profileUser.id);
      setupEditableData(manager.profileUser, '');
    });
  }


  function getCourseProfile(userId, catalogId) {
    manager.demandedUserId = userId;

    return LearnerProfileModel.getCourseProfile(userId, catalogId).then((profile) => {
      manager.profileUser = new UserModel(profile.data.user);
      manager.isViewingSelfProfile = (CurrentUserManager.user.id === manager.profileUser.id);

      // Specific to course profile
      manager.profile = profile.data; // Used for role and latestActivity
      manager.meta = profile.meta;

      getData(manager.profileUser.id, catalogId);
      setupEditableData(manager.profileUser, catalogId);
    });
  }

  function getData(userId, catalogId) {
    // Setup what we already have
    manager.memberships = manager.profileUser.memberships;
    manager.courses = manager.profileUser.enrollments.filter(({ course }) => course.released && (course.usedFor === 0));

    // Different for Novoed and Course profile
    getSubmissions(userId, catalogId);
    getActivityMetrics(userId, catalogId);
    getCertificates(userId);

    if (catalogId) {
      getPrivateSubmissions(userId, catalogId);
      getViewerMemberships(userId, catalogId);
      getDiscussionContributions(catalogId, userId);
      getCourseProfileQuestions(catalogId, userId);
    }
  }


  function setupEditableData(data, catalogId) {
    manager.editableData = {
      catalogId,
      user: {},
      questionResponse: {},
    };

    manager.editableData.user = {
      headline: data.headline,
      firstName: data.firstName,
      lastName: data.lastName,
      location: data.displayLocation,
      bio: data.bio,
    };

    const {
      profileSettings,
      isInstitutionalAdmin,
    } = (CurrentCourseManager.course?.institution) || {};

    const { demandedUserId } = manager;
    const { user: currentUser } = CurrentUserManager;
    const isSelfUser = currentUser.id === demandedUserId;
    manager.accountLevelOrgSettings = profileSettings?.accountLevel;
    const isCurrentUserOrgAdmin = isInstitutionalAdmin || currentUser.admin;

    manager.editableData.profileDetails = data.profileDetails.filter(
      (profileDetail) => {
        if (isSelfUser) {
          if (isCurrentUserOrgAdmin) {
            return true;
          }

          return !profileDetail.isHidden;
        }

        if (isCurrentUserOrgAdmin) {
          return profileDetail.answer;
        }

        return profileDetail.answer && !profileDetail.isHidden;
      },
    ).map(
      (profileDetail) => {
        const { question, migratedField } = profileDetail;

        const displayName = (migratedField && !question) ? MigratedFields.getDisplayName(migratedField) : question;

        return ({
          ...profileDetail,
          question: displayName,
        });
      },
    );
  }


  function getSubmissions(userId, catalogId) {
    manager.submissions = [];
    manager.allSubmissions = [];
    return LearnerProfileModel.getSubmissions({ userId, catalogId }).then((submissions) => {
      manager.submissions = submissions;
      manager.allSubmissions = manager.submissions; // used to show private submissions later

      _.each(manager.submissions, ReportModel.normalizeSubmissionData);
    });
  }

  function getPrivateSubmissions(userId, catalogId) {
    manager.privateSubmissions = [];
    return LearnerProfileModel.getPrivateSubmissions({ userId, catalogId }).then((submissions) => {
      manager.privateSubmissions = submissions;

      _.each(manager.privateSubmissions, ReportModel.normalizeSubmissionData);
    });
  }

  function getActivityMetrics(userId, catalogId) {
    manager.activityMetrics = null;
    return LearnerProfileModel.getActivityMetrics({ userId, catalogId }).then((metrics) => {
      manager.activityMetrics = metrics;
    });
  }


  function getViewerMemberships(userId, catalogId) {
    manager.viewerTeamMemberships = [];
    return LearnerProfileModel.getViewerMemberships(userId, catalogId).then((viewerMemberships) => {
      manager.viewerTeamMemberships = viewerMemberships;

      processVieweeMemberships();
      processViewerMemberships();
    });
  }

  function getCertificates(userId) {
    manager.certificates = [];
    return LearnerProfileModel.getCertificates(userId).then((certificates) => {
      manager.certificates = certificates;
    });
  }

  function getDiscussionContributions(catalogId, userId) {
    manager.discussion = [];
    return LearnerProfileModel.getDiscussionContributions({ catalogId, userId }).then((discussions) => {
      manager.discussion = [];

      _.each(discussions, (discussion) => {
        manager.discussion.push(new PostModel(_.extend(discussion, { catalogId: discussion.course.catalogId })));

        _.each(discussion.posts, (post) => {
          _.last(manager.discussion).comments.push(
            new CommentModel(_.extend(post, { catalogId: discussion.course.catalogId, owner: discussion, replyCount: post.commentsCount })),
          );

          _.each(post.comments, (comment) => {
            _.last(_.last(manager.discussion).comments).replies.push(
              new ReplyModel(_.extend(comment, { catalogId: discussion.course.catalogId, owner: post, replyCount: post.commentsCount })),
            );
          });
        });
      });
    });
  }

  function getCourseProfileQuestions(catalogId, userId) {
    if (manager.editableData) {
      manager.editableData.questionResponse = {};
    }
    return LearnerProfileModel.getCourseProfileQuestions({ catalogId, userId }).then((courseQuestions) => {
      manager.editableData.questionResponse.answers = courseQuestions;

      _.each(courseQuestions, (question) => {
        if (question.course?.isProgram) {
          manager.programProfileQuestionCount += 1;
        } else {
          manager.courseProfileQuestionCount += 1;
        }
      });
    });
  }

  // User actions
  // Viewee
  function isVieweeAdmin() {
    // TODO: Double check this, seems wrong
    return manager.profileUser.isAdmin;
  }

  // Viewer
  function isViewerTeamLead() {
    const found = _.find(TeamManager.memberships, (membership) => membership.isAdmin && !membership.team.teamSet.isGroup);

    if (found) {
      return true;
    }

    return null;
  }

  function isViewerGroupAdmin() {
    const found = _.find(TeamManager.memberships, (membership) => membership.isAdmin && membership.team.teamSet.isGroup && (membership.team.courseCatalogId === $stateParams.catalogId));

    if (found) {
      return true;
    }

    return null;
  }

  function processVieweeMemberships() {
    manager.profileUser.teams = _.filter(manager.profileUser.memberships, (membership) => !membership.team.teamSet.isGroup && membership.team.teamSet.isCourseLong);
    manager.profileUser.groups = _.filter(manager.profileUser.memberships, (membership) => membership.team.teamSet.isGroup);
  }


  function processViewerMemberships() {
    manager.viewer = {
      teams: [],
      groups: [],
    };

    manager.viewer.teams = _.filter(manager.viewerTeamMemberships, (membership) => !membership.team.teamSet.isGroup && membership.team.teamSet.isCourseLong);
    manager.viewer.groups = _.filter(manager.viewerTeamMemberships, (membership) => membership.team.teamSet.isGroup && membership.canViewerInvite);

    calculateCommonGroups();
  }


  // Groups common between viewer and viewee
  function calculateCommonGroups() {
    manager.commonGroups = {
      invitedAlready: 0,
      count: 0,
    };

    _.each(manager.viewer.groups, (viewerMembership) => {
      _.each(manager.profileUser.groups, (vieweeMembership) => {
        if (viewerMembership.team.id === vieweeMembership.team.id) {
          manager.commonGroups.count += 1;
          viewerMembership.isVieweeMember = true; // To use in invite-group-member
        }
      });

      if (viewerMembership.invitedAlready) {
        manager.commonGroups.invitedAlready += 1;
        viewerMembership.vieweeInvited = true; // To use in invite-group-member
      }
    });
  }


  // Inviting and uninviting members
  function inviteMember(teamId, type) {
    LearnerProfileModel.inviteMember($stateParams.catalogId, teamId, manager.profileUser.id).then((response) => {
      updateOnInvite(teamId, type);
    });
  }

  function uninviteMember(teamId, type) {
    const modalInstance = ConfirmationOverlays.openConfirmationModal('learner_profiles/templates/modal-uninvite-confirmation.html');
    modalInstance.result.then(() => {
      LearnerProfileModel.uninviteMember($stateParams.catalogId, teamId, manager.profileUser.id).then((response) => {
        updateOnUninvite(teamId, type);
      });
    });
  }


  function updateOnInvite(teamId, type) {
    if (type === 'team') {
      manager.viewer.teams[0].invitedAlready = true;
    } else if (type === 'group') {
      const updatedGroup = _.find(manager.viewer.groups, (group) => group.team.id === teamId);

      updatedGroup.vieweeInvited = true;
      manager.commonGroups.invitedAlready += 1;
    }
  }

  function updateOnUninvite(teamId, type) {
    if (type === 'team') {
      manager.viewer.teams[0].invitedAlready = false;
    } else if (type === 'group') {
      const updatedGroup = _.find(manager.viewer.groups, (group) => group.team.id === teamId);

      updatedGroup.vieweeInvited = false;
      manager.commonGroups.invitedAlready -= 1;
    }
  }

  return manager;
}
