import { ExternalToolWebLinkTypes } from 'redux/schemas/models/external-tool';

// eslint-disable-next-line max-params
/* @ngInject */
export default function TimelineLecturePageModelService(
  _,
  $q,
  nvUtil,
  S3UploadFactory,
  S3NameSpaces,
  moment,
  LecturePagesResources,
  TimelinesResources,
  LecturePageBaseModel,
  TimelinePeerReviewLectureComponentModel,
  TimelineExerciseLectureComponent,
  TimelineExerciseSkillsRatingLectureComponent,
  TimelineQuizLectureComponentModel,
  TimelineTimedQuizLectureComponentModel,
  TimelineProgressiveQuizLectureComponentModel,
  TimelineSurveyLectureComponentModel,
  TimelineDiscussionLectureComponentModel,
  TimelineVideoListLectureComponentModel,
  TimelineLiveSessionLectureComponentModel,
  TimelineExternalToolLectureComponentModel,
  TimelineTeamFormationLectureComponentModel,
  TimelineGroupFormationLectureComponentModel,
  TimelineProfileCompletionLectureComponentModel,
  TimelinePollLectureComponentModel,
  TimelineVideoPracticeLectureComponentModel,
  TimelinePublicPracticeFeedbackCriteriaLectureComponentModel,
  $timeout,
  maxTimeout,
) {
  const LECTURE_COMPONENT_TYPE_TO_MODELS = {
    ExerciseLectureComponent: TimelineExerciseLectureComponent,
    ExerciseSkillsRatingLectureComponent: TimelineExerciseSkillsRatingLectureComponent,
    QuizLectureComponent: TimelineQuizLectureComponentModel,
    TimedQuizLectureComponent: TimelineTimedQuizLectureComponentModel,
    ProgressiveQuizLectureComponent: TimelineProgressiveQuizLectureComponentModel,
    LiveSessionLectureComponent: TimelineLiveSessionLectureComponentModel,
    ExternalToolLectureComponent: TimelineExternalToolLectureComponentModel,
    SurveyLectureComponent: TimelineSurveyLectureComponentModel,
    PublicPeerEvaluationLectureComponent: TimelinePeerReviewLectureComponentModel,
    PrivatePeerEvaluationLectureComponent: TimelinePeerReviewLectureComponentModel,
    DiscussionLectureComponent: TimelineDiscussionLectureComponentModel,
    TeamDiscussionLectureComponent: TimelineDiscussionLectureComponentModel, // Team discussion should follow same format as course discussion
    TeamFormationLectureComponent: TimelineTeamFormationLectureComponentModel,
    GroupFormationLectureComponent: TimelineGroupFormationLectureComponentModel,
    VideoListLectureComponent: TimelineVideoListLectureComponentModel, // returns a list of TimelineLectureVideoModels,
    AudioListLectureComponent: TimelineVideoListLectureComponentModel,
    ProfileCompletionLectureComponent: TimelineProfileCompletionLectureComponentModel,
    PollLectureComponent: TimelinePollLectureComponentModel,
    VideoPracticeLectureComponent: TimelineVideoPracticeLectureComponentModel,
    PeerEvaluationLectureComponent: TimelinePeerReviewLectureComponentModel,
    PublicPracticeFeedbackCriteriaLectureComponent: TimelinePublicPracticeFeedbackCriteriaLectureComponentModel,
    VideoPracticeSkillsRatingLectureComponent: TimelinePublicPracticeFeedbackCriteriaLectureComponentModel,
  };

  class TimelineLecturePageModel extends LecturePageBaseModel {
    constructor(attributes, lectureSection, course, isAdmin, releasedCallback) {
      const base = {
        cardViewTemplateUrl: 'timelines/templates/nv-timeline-lecture-page-card-view.html',
        templateUrl: 'timelines/templates/nv-timeline-lecture-page.html',
        isAdmin,
        releasedCallback,
      };

      /** Setting Up Basic Attributes * */
      super(_.extend({}, base, attributes));

      _.extend(this, {
        allPointItems: null,
        catalogId: course.catalogId,
        lectureSection,
        course,
      });

      this.__preprocess();
    }

    __preprocess() {
      this.isCurrentModule = false;
      this.showTitle = this.showTitle === undefined ? true : this.showTitle;
      this.isReleased = moment(this.releaseDate) < moment();

      if (!this.isReleased) {
        this.setReleaseTimer();
      }

      if (this.lectureComponents) {
        this.lectureComponents = _.compact(_.map(this.lectureComponents, (lectureComponent) => (this.__transformLectureComponent(lectureComponent))));
      }

      this.preprocessViewData(true);
    }


    __transformLectureComponent(lectureComponent) {
      let newComponent;

      if (LECTURE_COMPONENT_TYPE_TO_MODELS[lectureComponent.type]) {
        newComponent = new LECTURE_COMPONENT_TYPE_TO_MODELS[lectureComponent.type](_.extend(lectureComponent, this.__defaultComponentAttributes));
      }

      return newComponent;
    }

    get __defaultComponentAttributes() {
      return {
        catalogId: this.catalogId,
        releaseDate: this.releaseDate,
        isReleased: this.isReleased,
        lecturePage: this,
        lectureSection: this.lectureSection,
        course: this.course,
      };
    }

    /** Declarations * */
    getAllComponents() {
      const items = _.flatten(_.map(this.lectureComponents, (lectureComponent) => {
        if (_.isFunction(lectureComponent.getAllItems)) { // this is only for video
          return lectureComponent.getAllItems();
        }
        return lectureComponent;
      }));

      return items;
    }

    /* Shared Public Functions */
    /* Todo View */
    getAllTodoItems() {
      return _.filter(this.getAllComponents(), (item) => item.isTodo);
    }

    /* Points View */
    getAllPointsReceivedItems() {
      return _.filter(this.getAllComponents(), (item) => item.pointsReceived);
    }

    showPointsDenominator() {
      return this.currentTotalPoints > this.currentPointsReceived;
    }

    /* Lecture Page View */
    resetCurrent() {
      this.isCurrentModule = false;
      this.current = false;
    }

    setViewed() {
      this.viewed = true;

      this.preprocessSelfAndParentPointData();
    }

    markUnread(lecturePageId) {
      if (this.id === lecturePageId) {
        this.viewed = false;
      }

      this.preprocessSelfAndParentPointData();
    }

    removeComponent(lecturePageId, componentType, componentId) {
      const index = _.findIndex(this.lectureComponents, { id: componentId });

      this.lectureComponents.splice(index, 1);

      _.each(this.lectureComponents, (lectureComponent, lcIndex) => {
        lectureComponent.index = lcIndex;
      });

      this.preprocessViewData(true);
    }

    updateComponentPointsAndProgress(lecturePageId, componentType, componentId, pointsReceived, totalPoints, progress) {
      // ignore lecturePageId for now, we don't pass this for peer reviews
      _.each(this.allPointItems, (item) => {
        item.updateComponentPointsAndProgress(componentType, componentId, pointsReceived, totalPoints, progress);
      });
    }

    updateComponentProgress(lecturePageId, componentType, componentId, progress) {
      // ignore lecturePageId for now, we don't pass this for peer reviews
      _.each(this.allPointItems, (lectureComponent) => {
        lectureComponent.updateComponentProgress(componentType, componentId, progress);
      });
    }

    updateLecturePageAttribute(lecturePageId, attribute, value) {
      if (lecturePageId === this.id) {
        this[attribute] = value;
        if (attribute?.includes('releaseDate')) {
          this.releasedUpdate();
        }
      }
    }

    updateLecturePage(lecturePageId, userId) {
      if (lecturePageId === this.id) {
        return this.update(userId)
          .then((resource) => {
            this.lectureSection.updateSubsectionCompletionDetails?.();
            this.lectureSection.timeline.preprocessViewData();
            return resource;
          });
      }

      return null;
    }

    updateReleaseDate() {
      const payload = _.pick(this, 'releaseDate');
      return LecturePagesResources.updateLecturePageReleaseDate({ catalogId: this.catalogId, id: this.id }, payload)
        .$promise.then((response) => {
          this.releasedUpdate();

          const serverLecturePage = response.result.updatedObject;
          const depedentLecturePages = response.result.dependentUpdates;


          if (serverLecturePage.hasStructuralIssues != null) {
            this.hasStructuralIssues = serverLecturePage.hasStructuralIssues;
            this.hasTimelineIssues = serverLecturePage.hasTimelineIssues;
          }

          this.lectureSection.timeline.updateDependentActivitiesReleaseDate(this);

          this.lectureSection.timeline.updateLecturePagesWarnings(depedentLecturePages);

          return serverLecturePage;
        });
    }

    updateDependentActivitiesReleaseDate(changedlecturePage) {
      _.each(this.lectureActivities, (activity) => {
        if (activity.updateDependentActivitiesReleaseDate) {
          activity.updateDependentActivitiesReleaseDate(changedlecturePage);
        }
      });
    }

    saveCardViewImage(file) {
      const deferred = $q.defer();

      S3UploadFactory.uploadToS3(file, S3NameSpaces.CARD_VIEW_IMAGES)
        .then((resp) => {
          const s3FileData = resp.config.data.file;
          this.cardViewImage = {
            fileName: s3FileData.name,
            fileSize: s3FileData.size,
            fileType: s3FileData.type,
            uniqueId: s3FileData.uniqueId,
          };
          this.saveBasics(false).then((serverLecturePage) => {
            this.cardViewImageUrl = serverLecturePage.cardViewImageUrl;
            deferred.resolve(this);
          });
        });

      return deferred.promise;
    }

    /* Internal Functions */
    isCompleted() {
      return this.getProgress() === 'completed';
    }

    isViewed() {
      return !!this.viewed;
    }

    getCardViewStyles() {
      return {
        'background-image': this.cardViewImageUrl ? `url(${this.cardViewImageUrl})` : null,
        'background-color': !this.cardViewImageUrl && this.viewOptions ? this.viewOptions.backgroundColor : null,
      };
    }

    preprocessSelfAndParentPointData() {
      this.preprocessViewData(false);
      this.lectureSection.updateSubsectionCompletionDetails?.();
    }

    preprocessViewData(hasNewItems = true) {
      // only does calculation client-side when lecture components are available
      // otherwise pointsReceived, totalPoints, completed, started, viewed will be passed by the lecture page provide backend calculated values
      if (this.lectureComponents) {
        if (hasNewItems) {
          this.allPointItems = this.getAllComponents();
        }
        this.currentPointsReceived = this.calculatePointsReceived(this.allPointItems);
        this.currentTotalPoints = this.calculateTotalPoints(this.allPointItems);

        this.progress = this.getProgress();
      } else {
        this.currentPointsReceived = this.pointsReceived;
        this.currentTotalPoints = this.totalPoints && this.totalPoints[0]; // no decay, so just take the first item

        if (this.completed) {
          this.progress = 'completed';
        } else if (this.started || this.viewed) {
          this.progress = 'in_progress';
        } else {
          this.progress = 'not_started';
        }
      }
    }

    setWarningForLecture() {
      this.lessonAppearsBeforePrereq = true;
    }

    resetPrereq() {
      this.lessonAppearsBeforePrereq = false;
      this.prereqWarningSelf = false;
      this.prereqWarningItems = [];
    }

    setWarningForItem(item) {
      this.prereqWarningItems = this.prereqWarningItems?.length ? this.prereqWarningItems.push(item) : [item];
    }

    setPrereqWarningForSelf() {
      this.prereqWarningSelf = true;
    }


    isFullyCompleted() {
      if (this.lectureComponents) {
        const componentsCompleted = _.every(this.getAllComponents(), (item) => {
          if (item.isTodo) {
            return item.isCompleted() || (item.isApproved && item.isApproved());
          }
          return true;
        });

        return componentsCompleted && this.viewed;
      }

      return this.completed && this.viewed;
    }

    cancelTimeouts() {
      if (this.$timeoutPromise) {
        $timeout.cancel(this.$timeoutPromise);
      }
    }

    isPrereqCompleted(prereq) {
      if (prereq.type === 'LecturePage' && prereq.id === this.id) {
        return this.isCompleted();
      }
      return _.some(this.getAllComponents(), (item) => _.isFunction(item.isPrereqCompleted) && item.isPrereqCompleted(prereq));
    }

    releasedUpdate() {
      this.isReleased = moment(this.releaseDate) < moment();

      if (this.isReleased) {
        if (!this.isAdmin) {
          // TODO: this is not hookedup since outline edit release
          this.update()
            .then(this.releasedCallback);
          // this will update lectureSection && lecturePage before preprocessing
          // preprocess is called in the callback
        }
      } else {
        this.setReleaseTimer();
      }
    }

    setReleaseTimer() {
      let seconds = moment(this.releaseDate) - moment();

      if (seconds < 0) {
        return;
      }

      if (seconds > maxTimeout) {
        seconds = maxTimeout;
      }

      this.$timeoutPromise = $timeout(() => {
        this.releasedUpdate();
      }, seconds);
    }

    update() {
      return TimelinesResources.getLecturePage({
        catalogId: this.course.catalogId,
        userId: this.lectureSection.timeline.userId,
        id: this.id,
        isEditMode: true,
      }, (resource) => {
        _.extend(this, resource.result, this.base, {
          lectureSection: this.lectureSection,
          course: this.course,
        });

        this.__preprocess();
      }).$promise;
    }

    /* Helpers */
    getProgress() {
      let items = this.allPointItems;

      if (!this.isViewed()) {
        return 'not_started';
      } if (items.length === 0) {
        return 'completed';
      }
      items = _.filter(items, this.shouldCompleteItem);

      if (_.every(items, (item) => (item.isCompleted() || (item.isApproved && item.isApproved())))) {
        return 'completed';
      }
      return 'in_progress';
    }

    shouldCompleteItem(item) {
      if (item.externalTool?.toolType === ExternalToolWebLinkTypes.WEB_LINK) {
        return item.externalTool.isTodo || item.externalTool.pointsConfiguration?.points;
      }
      return item.isTodo;
    }

    calculatePointsReceived(items) {
      return _.reduce(items, (memo, item) => {
        if (item.pointsReceived) {
          return item.pointsReceived + memo;
        }
        return memo;
      }, 0);
    }

    calculateTotalPoints(items) {
      return _.reduce(items, (memo, item) => {
        if (item.currentTotalPoints) {
          return item.currentTotalPoints + memo;
        }

        return memo;
      }, 0);
    }

    getActivities() {
      return TimelinesResources.getActivities({ catalogId: this.catalogId, id: this.id }, {}).$promise
        .then((response) => {
          this.lectureActivities = _.map(response.result, (lectureComponent) => (this.__transformLectureComponent(lectureComponent)));
        });
    }

    setupComponents(lectureActivities) {
      this.lectureActivities = _.map(lectureActivities, (lectureComponent) => (this.__transformLectureComponent(lectureComponent)));
      return this.lectureActivities;
    }

    updateLectureComponent(lectureComponentType, lectureComponentId) {
      const currComponent = _.find(this.lectureActivities, (lc) => lc.id === lectureComponentId);
      const urlParams = {
        catalogId: this.catalogId,
        lecturePageId: this.id,
        lectureComponentType: nvUtil.toSnakeCase(lectureComponentType),
        lectureComponentId,
      };
      const payload = currComponent.getPayload();
      if (!payload.deadline && payload.expirationDate) { payload.deadline = payload.expirationDate; }

      return TimelinesResources.updateLectureComponent(urlParams,
        { lectureComponent: _.extend(payload, { onlyDownstreamOutlineUpdate: true }) }).$promise.then((response) => {
        this.updateHadDeadline();
        return response;
      });
    }

    updateHadDeadline() {
      if (this.lectureActivities) {
        this.hasDeadlines = _.any(this.lectureActivities, (lectureActivity) => lectureActivity.hasDeadline?.());
      }
    }

    setActivityDeadlines(newDeadline, hardDeadline) {
      _.each(this.lectureActivities, (activity) => {
        if (activity.deadline) {
          activity.deadline = newDeadline;
        }

        if (activity.expirationDate) {
          activity.expirationDate = newDeadline;
        }

        if (activity.hardDeadline != null) {
          activity.hardDeadline = hardDeadline;
        }
      });
    }

    bulkUpdateActivityDeadline(newDeadline, hardDeadline) {
      return TimelinesResources.bulkUpdateActivityDeadline({ catalogId: this.catalogId, lecturePageId: this.id },
        { deadline: newDeadline, hardDeadline }).$promise.then((response) => {
        if (response.result.hasStructuralIssues != null) {
          this.hasStructuralIssues = response.result.hasStructuralIssues;
          this.hasTimelineIssues = response.result.hasTimelineIssues;
        }
        this.setActivityDeadlines(newDeadline, hardDeadline);
        return response;
      });
    }
  }

  TimelineLecturePageModel.get = (course, userId, lecturePageId, lectureSection, isAdmin, releasedCallback) => TimelinesResources.getLecturePage({
    catalogId: course.catalogId,
    userId,
    id: lecturePageId,
  }).$promise.then((response) => new TimelineLecturePageModel(response.result, lectureSection, course, isAdmin, releasedCallback));

  TimelineLecturePageModel.createLecturePage = (catalogId, referenceLecturePageId) => {
    const lecturePage = {
      lecturePageIdToCreateUnder: referenceLecturePageId,
      releaseDate: moment().add(1, 'months').endOf('day').toISOString(),
    };

    return TimelinesResources.createLecturePage({ catalogId }, { lecturePage }).$promise
      .then((response) => response.result);
  };

  return TimelineLecturePageModel;
}
