/* @ngInject */
export default function AuthenticationsCtrl(

  $state,
  $stateParams,
  CurrentCourseManager,
  UserAuthentication,
  config,
  _,
  InstitutionsManager,
  requestInstitution,
  $timeout,
  $location,
) {
  const vm = this;

  vm.state = $state;
  vm.location = $location;
  vm.course = CurrentCourseManager.course;
  vm.backdropUrl = null;
  vm.samlProvider = null;
  vm.checkSamlLogin = checkSamlLogin;
  vm.oauthUrl = oauthUrl;
  vm.shouldShowImage = shouldShowImage;
  vm.currentInstitution = null;
  vm.UserAuthentication = UserAuthentication;

  // debugging IE favicon issue
  vm.InstitutionsManager = InstitutionsManager;
  vm.updateFavicon = vm.InstitutionsManager.updateFavicon;

  vm.currentInstitution = requestInstitution;
  $('.loading-screen').hide();

  function checkSamlLogin(email) {
    if (email) {
      vm.samlProvider = null;
      _.each(_.keys(config.samlProviders), (providerKey) => {
        const provider = config.samlProviders[providerKey];
        if (email.includes(provider.emailDomain)) {
          vm.samlProvider = provider;
        }
      });
    }
  }

  function oauthUrl(provider) {
    const params = _.extend(_.clone($state.params), $stateParams);
    let returnTo;
    if (UserAuthentication.stateAttemptedBeforeAuthentication) {
      returnTo = $state.href(UserAuthentication.stateAttemptedBeforeAuthentication, UserAuthentication.paramsForStateAttemptedBeforeAuthentication);
    } else {
      returnTo = 'nil';
    }

    let originPlanOrMentor = '';
    if (params.plan) {
      originPlanOrMentor = 'plan';
    } else if (params.mentor) {
      originPlanOrMentor = 'mentor';
    }

    const origin = `${params.catalogId || ''}~~`
        // referral token
        + 'nil~~'
        // http referer
        + `nil~~${
        // return_to
          returnTo}~~`
        // location_hash (legacy)
        + `nil~~${
        // plan or mentor (status)
          originPlanOrMentor}~~`
        // ga (legacy)
        + 'nil';

    let effectiveHost = config.app.dummyHost;
    /**
     * Origin is possible to be null in certain cases.
     * See: https://html.spec.whatwg.org/multipage/origin.html#ascii-serialisation-of-an-origin list item #1
     * See: https://html.spec.whatwg.org/multipage/origin.html#concept-origin-opaque
     */
    if (!!window.location.origin?.includes('novoed.com') !== true) {
      effectiveHost = window.location.host;
    }

    return `${config.app.protocol}://${effectiveHost}/omniauth_post?provider=${provider}&origin=${encodeURIComponent(origin)}`;
  }

  function shouldShowImage() {
    return !vm.InstitutionsManager.institution;
  }
}
