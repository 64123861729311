import React from 'react';
import { AngularContext } from 'react-app';

import OfferingBrandingHeader from 'offerings/components/offering-branding-header';

const CourseHomeHeader = () => {
  const { injectServices } = React.useContext(AngularContext);
  const [{ course }] = injectServices(['CurrentCourseManager']);

  return (
    <OfferingBrandingHeader
      hideTopSection
      showParentProgram
      transparentBackground
      catalogId={course?.catalogId}
      value={{
        name: course?.name ?? '',
        brandColor: course?.headerColor,
        fontColor: course?.nameFontColor,
        logo: course?.logo ? {
          url: course.logo.url,
          size: course.logoSize,
        } : null,
        background: course?.headerBackground ? {
          url: course.headerBackground,
        } : null,
      }}
    />
  );
};

export default CourseHomeHeader;
