/* @ngInject */
export default function MenteesManager(
  _,
  humps,
  $state,
  $stateParams,

  CurrentUserManager,
  CurrentCourseManager,

  ExercisesResources,
  MenteesResources,
  ReportsManager,
) {
  const NEEDS_APPROVAL_FILTER = 'needsApproval';
  const NEEDS_COMMENT_FILTER = 'needsComment';
  const NEEDS_PRIVATE_FEEDBACK_FILTER = 'needsPrivateFeedback';
  const DEFAULT_FILTER = NEEDS_APPROVAL_FILTER;

  const DEFAULT_SORT = 'progress';
  const DEFAULT_SORT_ORDER = 'desc';

  const DEFAULT_PAGE_SIZE = 20;

  const DEFAULT_FILTER_AND_SORT_BASE = {
    sort: DEFAULT_SORT,
    sortOrder: DEFAULT_SORT_ORDER,
  };

  const DEFAULT_TAB_VISIBILITY = {
    hideForTeams: false,
  };

  const DEFAULT_FILTERS_AND_SORTS = {
    total: _.extend({}, DEFAULT_FILTER_AND_SORT_BASE, DEFAULT_TAB_VISIBILITY, { displayNameKey: 'MENTEES.TABS.TOTAL' }),
    needsApproval: _.extend({}, DEFAULT_FILTER_AND_SORT_BASE, DEFAULT_TAB_VISIBILITY,
      { displayNameKey: 'MENTEES.TABS.NEEDS_APPROVAL', hideForTeams: true }),
    needsPrivateFeedback: _.extend({}, DEFAULT_FILTER_AND_SORT_BASE, DEFAULT_TAB_VISIBILITY,
      { displayNameKey: 'MENTEES.TABS.NEEDS_PRIVATE_FEEDBACK', hideForTeams: true }),
    needsComment: _.extend({}, DEFAULT_FILTER_AND_SORT_BASE, DEFAULT_TAB_VISIBILITY, { displayNameKey: 'MENTEES.TABS.NEEDS_COMMENTS' }),
    loggedIn: _.extend({}, DEFAULT_FILTER_AND_SORT_BASE, DEFAULT_TAB_VISIBILITY, { displayNameKey: 'MENTEES.TABS.LOGGED_IN' }),
    notLoggedIn: _.extend({}, DEFAULT_FILTER_AND_SORT_BASE, DEFAULT_TAB_VISIBILITY, { displayNameKey: 'MENTEES.TABS.NOT_LOGGED_IN' }),
    completed: _.extend({}, DEFAULT_FILTER_AND_SORT_BASE, DEFAULT_TAB_VISIBILITY, { displayNameKey: 'MENTEES.TABS.COMPLETED' }),
  };

  const savedSortsAndFilters = DEFAULT_FILTERS_AND_SORTS;

  const RESET_DATA = {
    // data
    initialized: false,
    hasFetchedExercises: false,

    filter: DEFAULT_FILTER,
    sort: null,
    sortOrder: DEFAULT_SORT_ORDER, // options: ['desc', 'asc']
    featureableExercises: [],
    exerciseFilter: null, // manager's internal exercise object

    filterCounts: {}, // counters of mentees for each type of filter
    mentees: [], // list of all mentees for the current search. i.e. results
    pageNumber: 1,

  };

  const manager = _.extend({
    fetchingResults: false,
    hasMoreToLoad: true,

    filterTabNames: _.map(DEFAULT_FILTERS_AND_SORTS, (value, key) => ({
      internalName: key,
      displayNameKey: value.displayNameKey,
      hideForTeams: !!value.hideForTeams,
    })),

    // functions
    initialize,
    resetData,
    getFeatureableExercises,
    onFilterChange,
    onSortChange,
    onExerciseChange,
    setSort,
    setSortOrder,
    loadAdditionalResults,
    onReportComment,
    onReportReviewed,
  }, RESET_DATA);

  let lastSearchRequest;
  let lastSearchRequestParams = {};

  /* Initialization - Start */
  function initialize(attributes) {
    _.extend(this, attributes);
    __setFilterDefaults();

    __getFilterCounts().then((filterCounts) => {
      manager.initialized = true;

      if (!CurrentCourseManager.course.completionCriteria) {
        CurrentCourseManager.getCourseCompletionCriteria();
      }

      if ((filterCounts.needsApproval === 0 && manager.filter === NEEDS_APPROVAL_FILTER)
            || (filterCounts.needsComment === 0 && manager.filter === NEEDS_COMMENT_FILTER)
            || (filterCounts.needsPrivateFeedback === 0 && manager.filter === NEEDS_PRIVATE_FEEDBACK_FILTER)) {
        manager.onFilterChange('total');
      } else {
        __doSearch();
      }
    });
  }

  function resetData() {
    _.extend(manager, RESET_DATA);
  }

  function getFeatureableExercises() {
    return ExercisesResources.getMentorCommentableExercises({ catalogId: manager.catalogId }).$promise.then((response) => {
      manager.featureableExercises = response.result;

      __setExerciseDefaults();

      manager.hasFetchedExercises = true;

      return manager.exerciseFilter;
    });
  }

  function __setFilterDefaults() {
    if (manager.filter === null) {
      manager.filter = DEFAULT_FILTER;
    }

    if (!manager.sort) {
      __setSortFromDefault();
    }

    if (!manager.sortOrder) {
      __setSortOrderFromDefault();
    }
  }

  function __setExerciseDefaults() {
    if (manager.filter !== NEEDS_COMMENT_FILTER && manager.filter !== NEEDS_APPROVAL_FILTER && manager.filter !== NEEDS_PRIVATE_FEEDBACK_FILTER) {
      manager.exerciseFilter = _.last(manager.featureableExercises);
    } else {
      manager.exerciseFilter = null;
    }
  }

  function __getFilterCounts() {
    return MenteesResources.getFilterCounts({ catalogId: manager.catalogId, ownerId: $stateParams.ownerId, ownerType: $stateParams.ownerType }).$promise.then((response) => {
      manager.filterCounts = response.result;

      return manager.filterCounts;
    });
  }
  /* Initialization - End */

  /* Filtering, Sort, Search - Start */
  function onFilterChange(filter) {
    manager.filter = filter;

    __setSortFromDefault();
    __setSortOrderFromDefault();
    __setExerciseFilterFromDefault();
    __goToState();
  }

  function onSortChange(sort) {
    // if sort is already selected, toggle sort order
    if (manager.sort === sort) {
      __toggleSortOrder();
    } else {
      manager.setSort(sort);
    }
    __setSortOrderFromDefault();

    __goToState();
  }

  function onExerciseChange(exercise) {
    manager.exerciseFilter = exercise;

    // save currently selected exercise to saved sorts
    savedSortsAndFilters[manager.filter].exerciseFilter = exercise;

    // exercise filter is weird because it is a purely frontend change; however, reports need to be filtered by this exercise id
    __setReportData(manager.mentees, false);

    __goToState();
  }

  function setSort(sort) {
    manager.sort = sort;

    // write the new sort value to the saved defaults
    savedSortsAndFilters[manager.filter].sort = sort;
  }

  function setSortOrder(sortOrder) {
    manager.sortOrder = sortOrder;

    // write the new sort value to the saved defaults
    savedSortsAndFilters[manager.filter].sortOrder = sortOrder;
  }

  function __toggleSortOrder() {
    const sortsForCurrentFilter = savedSortsAndFilters[manager.filter];

    if (sortsForCurrentFilter.sortOrder === DEFAULT_SORT_ORDER) {
      sortsForCurrentFilter.sortOrder = 'asc';
    } else {
      sortsForCurrentFilter.sortOrder = DEFAULT_SORT_ORDER;
    }
  }

  function __setSortFromDefault() {
    manager.sort = savedSortsAndFilters[manager.filter].sort;
  }

  function __setSortOrderFromDefault() {
    manager.sortOrder = savedSortsAndFilters[manager.filter].sortOrder;
  }

  function __setExerciseFilterFromDefault() {
    const sortsForCurrentFilter = savedSortsAndFilters[manager.filter];

    if (sortsForCurrentFilter.exerciseFilter) {
      manager.exerciseFilter = sortsForCurrentFilter.exerciseFilter;
    } else {
      __setExerciseDefaults();
    }
  }

  function __goToState() {
    const searchParams = {
      filter: manager.filter,
      sort: manager.sort,
      sortOrder: manager.sortOrder,
    };

    $state.go('mentees', _.extend(searchParams, {
      exerciseId: manager.exerciseFilter ? manager.exerciseFilter.id : null,
    }), {
      notify: false,
      location: 'replace',
    });

    if (_.allKeys(lastSearchRequestParams).length === 0
          || (_.allKeys(lastSearchRequestParams).length && !_.isMatch(searchParams, lastSearchRequestParams))) {
      __doSearch();
    }
  }

  function __doSearch(boolAppend = false) {
    manager.fetchingResults = true;
    manager.hasMoreToLoad = true;

    if (!boolAppend) {
      if (manager.mentees.length) {
        manager.mentees = [];
      }

      if (lastSearchRequest) {
        lastSearchRequest.$cancelRequest();
      }
      manager.pageNumber = 1;
    }
    if (manager.initialized) {
      lastSearchRequest = MenteesResources.getMentees({
        catalogId: manager.catalogId,
        mentorUserId: CurrentUserManager.user.id,
        filter: manager.filter ? humps.decamelize(manager.filter) : null,
        sort: manager.sort,
        order: manager.sortOrder,
        page: manager.pageNumber,
        pageSize: DEFAULT_PAGE_SIZE,
        ownerId: $stateParams.ownerId,
        ownerType: $stateParams.ownerType,
      }, (resourceObj) => {
        manager.fetchingResults = false;
        manager.hasMoreToLoad = resourceObj.result.nextPage !== null;
        lastSearchRequestParams = {
          filter: manager.filter,
          sort: manager.sort,
          sortOrder: manager.sortOrder,
          ownerId: $stateParams.ownerId,
          ownerType: $stateParams.ownerType,
        };

        if (boolAppend) {
          manager.mentees = manager.mentees.concat(resourceObj.result.menteeUserCourses);
        } else {
          manager.mentees = resourceObj.result.menteeUserCourses;
        }

        __setReportData(boolAppend ? resourceObj.result.menteeUserCourses : manager.mentees, boolAppend);
      });
    }
  }

  function __setReportData(mentees, boolAppend) {
    const submissions = [];

    _.each(mentees, (userCourse) => {
      if (userCourse?.submittings?.length) {
        const submissionsHash = {};
        const orderedSubmittings = _.sortBy(userCourse.submittings, (submitting) => submitting.exerciseId);
        _.each(orderedSubmittings, (submission) => {
          if (!manager.exerciseFilter || submission.exerciseId === manager.exerciseFilter.id) {
            const report = {
              id: submission.submittableId,
              exerciseId: submission.exerciseId,
              commented: submission.commented,
              updatedAt: submission.updatedAt,
              createdAt: submission.createdAt,
              needsReview: submission.needsReview,
              needsPrivateFeedback: submission.needsPrivateFeedback,
              requiresPrivateFeedback: submission.requiresPrivateFeedback,
              requiresApproval: submission.requiresApproval,
            };

            if (manager.filter === NEEDS_APPROVAL_FILTER) {
              if (report.needsReview) {
                submissions.push(report);
              }
            } else if (manager.filter === NEEDS_PRIVATE_FEEDBACK_FILTER) {
              if (report.needsPrivateFeedback) {
                submissions.push(report);
              }
            } else {
              submissions.push(report);
            }

            // submissionsHash: exerciseId -> array of report objects
            if (!submissionsHash[submission.exerciseId]) {
              submissionsHash[submission.exerciseId] = [report];
            } else {
              submissionsHash[submission.exerciseId].push(report);
            }
          }
        });

        userCourse.submissionsHash = submissionsHash;
      }
    });

    if (boolAppend) {
      ReportsManager.reports = submissions?.length > 0 ? ReportsManager.reports.concat(submissions) : [];
    } else {
      ReportsManager.initialize({
        reportsCatalogId: manager.catalogId,
        reports: submissions,
      });
    }
  }

  function loadAdditionalResults() {
    if (!manager.fetchingResults && manager.initialized) {
      manager.pageNumber += 1;
      __doSearch(true);
    }
  }
  /* Filtering, Sort, Search - End */

  function onReportComment(changedUsersOptions) {
    if (changedUsersOptions.action === 'create') {
      if (manager.filterCounts[NEEDS_COMMENT_FILTER] > 0 && changedUsersOptions.mentees !== null && changedUsersOptions.mentees.length) {
        manager.filterCounts[NEEDS_COMMENT_FILTER] -= changedUsersOptions.mentees.length;
      }

      if (manager.filter === NEEDS_COMMENT_FILTER && changedUsersOptions.mentees.length) {
        manager.mentees = _.difference(manager.mentees, changedUsersOptions.mentees);
      }

      // should the tab switch to total if needsComment is 0?
    } else if (changedUsersOptions.mentees !== null && changedUsersOptions.mentees.length) {
      manager.filterCounts[NEEDS_COMMENT_FILTER] += changedUsersOptions.mentees.length;

      if (manager.filter === NEEDS_COMMENT_FILTER) {
        manager.mentees = _.uniq(manager.mentees.concat(changedUsersOptions.mentees), false, (a, b) => a.id === b.id);
      }
    }
  }

  function onReportReviewed(changedUsers) {
    if (manager.filterCounts[NEEDS_APPROVAL_FILTER] > 0 && changedUsers !== null && changedUsers.length) {
      manager.filterCounts[NEEDS_APPROVAL_FILTER] -= changedUsers.length;
    }

    if (manager.filter === NEEDS_APPROVAL_FILTER && changedUsers !== null && changedUsers.length) {
      manager.mentees = _.difference(manager.mentees, changedUsers);
    }
  }

  return manager;
}
