import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Result } from 'redux/schemas/api';

async function fetchCalculatedDates({courseId, dates}) {
  const response = await axios.get<Result<any>>(`courses/${courseId}/clone_dates_summary.json?dates=[${dates.join()}]`);
  return { response: response?.data?.result?.courseCloneDatesSummary?.dates };
}

export const getCalculatedDates = createAsyncThunk(
  'FETCH_CALCULATED_DATES',
  fetchCalculatedDates,
);
