/* @ngInject */
export default function ConfigureDegreedModalCtrl(

  $uibModalInstance,
  AlertMessages,
  InstitutionsManager,
  config,
) {
  const vm = this;

  vm.InstitutionsManager = InstitutionsManager;
  vm.currentClientID = InstitutionsManager.institution.degreedSettings?.apiKey ?? '';
  vm.currentSecret = InstitutionsManager.institution.degreedSettings?.apiSecret ?? '';
  vm.showErrorMessage = false;
  vm.syncClosedRegistrations = InstitutionsManager.institution.degreedSettings?.syncCloseEnrollment ?? false;
  vm.config = config;

  vm.closeModal = () => {
    vm.currentClientID = InstitutionsManager.institution.degreedSettings ? InstitutionsManager.institution.degreedSettings.apiKey : '';
    vm.currentSecret = InstitutionsManager.institution.degreedSettings ? InstitutionsManager.institution.degreedSettings.apiSecret : '';
    $uibModalInstance.dismiss('cancel');
  };

  vm.save = () => {
    vm.savingAPI = true;
    vm.showErrorMessage = false;

    InstitutionsManager.saveDegreedSettings(vm.currentClientID, vm.currentSecret, vm.syncClosedRegistrations)
      .then((isValid) => {
        vm.savingAPI = false;
        if (isValid) {
          vm.degreedForm.$setPristine();
          vm.closeModal();
          AlertMessages.success('FORM.SUCCESS_BANG', 'INSTITUTIONS.ADVANCED_SETTINGS.DEGREED_INTEGRATION.SUCCESS');
        } else {
          vm.showErrorMessage = true;
        }
      });
  };
}
