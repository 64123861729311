/* @ngInject */
export default function NumberQuestionService(

  _,
  $translate,
) {
  const TYPES = {
    CORRECT_WITH_MARGIN: 'CorrectWithMargin',
    RANGE_OF_CORRECT: 'RangeOfCorrect',
  };

  const NumberQuestion = function (attributes) {
    const _this = this;
    const base = {
      response: null,
      templateUrl: 'quizzes/templates/questions/nv-number-question.html',
      displayState: null,
    };

    let correctAnswer;
    let correctAnswerKey;
    let correctAnswerValues;

    /** Setting Up Basic Attributes * */
    _.extend(_this, base);
    const responseOptions = attributes.responseOptions.map(option => ({ ...option }));
    _.extend(_this, { ...attributes, responseOptions });

    calculateAnswer();

    /** Public Functions * */
    _this.isUnAnswered = isUnAnswered;
    _this.responsePayload = responsePayload;
    _this.setResponse = setResponse;
    _this.resetResponse = resetResponse;
    _this.updateResponseOptions = updateResponseOptions;
    _this.onInputFocus = onInputFocus;
    _this.onInputBlur = onInputBlur;

    _this.getCorrectAnswer = function () {
      return correctAnswer;
    };

    _this.getCorrectAnswerKey = function () {
      return correctAnswerKey;
    };

    _this.getCorrectAnswerValues = function () {
      return correctAnswerValues;
    };

    _this.getResponse = function () {
      return _this.currentResponse;
    };

    /** Private Functions * */
    function calculateAnswer() {
      const [responseOption] = _this.responseOptions;
      const topOfRange = parseFloat(responseOption.topOfRange);
      const bottomOfRange = parseFloat(responseOption.bottomOfRange);

      if (responseOption.optionContent === TYPES.CORRECT_WITH_MARGIN) {
        const marginError = (topOfRange - bottomOfRange) / 2.0;
        correctAnswer = bottomOfRange + marginError;
      }

      if (responseOption.optionContent === TYPES.RANGE_OF_CORRECT) {
        correctAnswerKey = 'QUIZZES.CORRECT_ANSWER_RANGE';
        correctAnswerValues = {
          bottomOfRange,
          topOfRange,
        };
      }
    }

    function isUnAnswered() {
      return !_this.responseOptions[0].readOnly && !_this.response;
    }

    function responsePayload() {
      // backend current expects a string
      if (_this.response != null) {
        return _this.response.toString();
      }
      return null;
    }

    function updateResponseOptions(question) {
      _this.responseOptions = question.responseOptions;
      calculateAnswer();
    }

    function setResponse(response) {
      _this.currentResponse = response;
      _this.response = response.response;
    }

    function resetResponse() {
      _this.response = null;
    }

    function onInputFocus() {
      _this.isFocused = true;
    }

    function onInputBlur() {
      _this.isFocused = false;
    }
  };

  return NumberQuestion;
}
