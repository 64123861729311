/* @ngInject */
export default function ConversationsManager(
  ConversationsResource,
  ConversationModel,
  CurrentUserManager,
  _,
  $timeout,
  $q,
  OfflineManager,
) {
  const _this = {
    conversations: null,
    numUnreadMessages: null,
    requestingConversations: false,
    moreConversationsToLoad: true,
    initialize,
    requestConversations,
  };

  $timeout(() => {
    _this.initialize();
  });

  let mailbox;
  let query;

  OfflineManager.registerReturnOnlineCallback(initialize);

  function initialize(options) {
    options = options || {};

    mailbox = options.mailbox || 'inbox';
    query = options.query || '';
    _this.moreConversationsToLoad = true;

    _this.conversations = [];
    if (CurrentUserManager.hasLoggedIn()) {
      return _this.requestConversations();
    }
    return $q.when();
  }

  function requestConversations() {
    _this.requestingConversations = true;

    return ConversationsResource.getConversations({
      mailbox,
      search: query,
      lastConversationId: lastConversation() ? lastConversation().id : '',
    }).then((response) => {
      _this.requestingConversations = false;

      if (response.data.conversations.length > 0) {
        _.each(response.data.conversations, (conversation) => {
          _this.conversations.push(new ConversationModel(conversation));
        });
      }
      _this.moreConversationsToLoad = response.data.moreToLoad;
      _this.numUnreadMessages = response.data.numUnreadMessages;
    });
  }

  function lastConversation() {
    if (_this.conversations.length > 0) {
      return _this.conversations[_this.conversations.length - 1];
    }

    return null;
  }

  return _this;
}
