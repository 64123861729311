import { css } from '@emotion/react';
import { ReactElement } from 'react';
import ClickableContainer from 'components/clickable-container';
import NvIcon from './nv-icon';

const doubleModalZIndex = 7050;

export enum ModalType {
  RIGHT = 'right',
  LEFT = 'left',
}

export enum ModalTheme {
  DEFAULT = '',
  LIGHT = 'modal-light',
}

/* Comment from Nathan (will remove): I think the design below is good, although it is a little hard to immediately
 * see exactly which properties are supported for each modal type.
 * For now, I have disabled the component styles in this file because I'm not sure how they interact w/ the SCSS styles
 * I have restored. I will try to test and merge these soon */

interface BaseModal {
  children: ReactElement,
  onClose?: () => void,
  doubleModal?: boolean, // this is an escape hatch - react bootstrap does not support 2 modals at once, this is only used by RTE
  enforceFocus?: boolean,
}

// Standard Modals are ones that look like the default bootstrap modals - dark background & centered content with light background\
// https://projects.invisionapp.com/d/main#/console/5847843/421848984/preview
interface StandardFlyoutModal extends BaseModal {
  header: string,
  theme?: ModalTheme.DEFAULT | ModalTheme.LIGHT
  backdrop?: true | false | 'static' // this is directly provided to Modal backdrop attribute
}

interface RightFlyoutModal extends StandardFlyoutModal {
  type: ModalType.RIGHT
  width: number
  height: number | string
}

interface LeftFlyoutModal extends StandardFlyoutModal {
  type: ModalType.LEFT
  width: number
  height: number | string
}

export type NvFlyoutModalProps = RightFlyoutModal | LeftFlyoutModal;

const defaultProps = {
  width: 720,
  height: 500,
  fullHeight: true,
  backdrop: 'static',
  doubleModal: false, // this is an escape hatch - react bootstrap does not support 2 modals at once, this is only used by RTE
  theme: ModalTheme.DEFAULT,
  enforceFocus: true,
};

export const NvFlyoutModal = (props: NvFlyoutModalProps) => {
  const styles = css`
    .flyout-content {
      min-width: ${props.width}px;
      width: ${props.width}px;
      &.flyout-right {
        right: 0px;
        left: unset;
        .flyout-close-button {
          right: 0px;
        }
      }
      &.flyout-left {
        left: 0px;
        right: unset;
        .flyout-close-button {
          left: 0px;
        }
      }
    }
    .flyout-close-button {
      position: fixed;
      padding: 20px;
      top: 0px;
      z-index: 1003;
    }
  `;

  return (
    <div className='flyout-modal ng-scope' css={styles}>
      <div className='flyout-backdrop' />
      <div className={`flyout-content flyout-${props.type}`} role='dialog'>
        <div className='flyout-close-button'>
          <ClickableContainer
            onClick={() => { props.onClose?.(); }}
            className='text-gray-2'
          >
            <NvIcon size='small' icon='close' />
          </ClickableContainer>
        </div>
        {props.children}
      </div>
    </div>
  );
};

NvFlyoutModal.defaultProps = defaultProps;

export default NvFlyoutModal;
