/* @ngInject */
export default function LeavePracticeActivityController(
  _,
  vmResolves,
  $uibModalInstance,
) {
  const vm = this;
  _.extend(vm, vmResolves);

  vm.expiredTimeNoTakes = vm.noTimeAndNoTakes();

  if (vm.practiceType === 'when_ready') {
    vm.bodyTextKey = 'LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.LEAVE.WHEN_READY';
  } else {
    vm.bodyTextKey = 'LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.LEAVE.ON_SPOT';
  }

  vm.closeModal = () => {
    vm.resume();
    $uibModalInstance.dismiss();
  };
}
