import { css } from '@emotion/react';
import t from 'react-translate';
import LoadingPlaceholder, { LoaderOptions } from 'shared/components/loading-placeholder';
import { gray2, gray4, gray5 } from 'styles/global_defaults/colors';
import { textMediumFontSize, boldFontWeight } from 'styles/global_defaults/fonts';
import { doubleSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';

export const LoadingBar = ({ loaderType }: LoaderOptions) => {
  const loaderStyles = css`
    &.loader-container{
      margin:${doubleSpacing}px;
      order:3;
    }
  `;

  return (
    <div css={loaderStyles} className='loader-container'>
      <LoadingPlaceholder loaderType={loaderType} />
    </div>
  );
};

export const AllLoaded = () => {
  const loadedStyles = css`
    &.all-loaded{
      margin:${standardSpacing}px;
      order:3;
      color: ${gray2};
    }
  `;

  return (
    <div
      css={loadedStyles}
      className='all-loaded'
    >
      {t.LHS.ALL_LOADED()}
    </div>
  );
};

export const EmptyContent = (props: { text: string }) => {
  const styles = css`
    &.no-content{
      display: flex;
      flex-direction: column;
      margin-top: ${doubleSpacing}px;
      width: 100%;
      align-items: center;
      justify-content: center;

    .icon-course{
      color: ${gray5};
      font-size: 60px;
    }
    .description{
      color: ${gray4};
      font-size: ${textMediumFontSize}px;
      font-weight: ${boldFontWeight};
      margin-top: ${standardSpacing}px;
      }
    }
  `;

  return (
    <div css={styles} className='no-content'>
      <div className='icon icon-large icon-course' />
      <div className='description'>{props.text}</div>
    </div>
  );
};

