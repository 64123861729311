/* @ngInject */
export default function CurrentCourseManager(
  $q,
  $stateParams,
  CourseModel,
  CourseRolesManager,
  CoursesResource,
  CurrentPermissionsManager,
  CurrentUserManager,
  InstitutionsManager,
  PusherManager,
  UserCourseModel,
  UserManagementResources,
  UserModel,
  _,
  moment,
) {
  const manager = {
    course: null,
    currentCatalogId: null,
    enroll,
    getCourseCompletionCriteria,
    getCriteriaCount,
    getEnrolledUserCounts,
    getMetaTags,
    reenroll,
    requestCourse,
    requestCourseDomainInfo,
    requestCurrentCoursePromise: null,
    resetCourse,
    searchUsers,
    setCommunitySearchVisibility,
    setCourse,
    setUserCourse,
    updateCourseBasics,
    getPendoProperties,
    setWelcomeEmail,
    shouldShowCohortManagementIndicators,
  };

  // Count of required criteria to calculate progress bar widths
  const CRITERIA_COUNT = {
    3: 'triple',
    2: 'double',
    1: 'single',
  };
  const CAN_ACCESS_CUSTOM_DOWNLOAD = [];

  function requestCourse(catalogId, shouldSetCourse, retryCount, isJourney, journeyId) {
    // Tracks the # of attempts to re-request the course data in the event that it fails. 5 is an arbitrary # of
    // attempts chosen; isn't based on any hard data
    const currentRetry = !retryCount ? 5 : retryCount - 1;
    if (manager.requestCurrentCoursePromise && manager.currentCatalogId === catalogId) {
      return manager.requestCurrentCoursePromise;
    }

    manager.currentCatalogId = catalogId;

    const deferred = $q.defer();

    const methodName = isJourney ? 'getAsJourney' : (journeyId ? 'getFromJourney' : 'get');

    CoursesResource[methodName]({ catalogId, journeyId }).$promise.then(
      (resource) => {
        manager.rawCourseData = resource.result; // for passing to redux
        const course = new CourseModel(resource.result);

        if (!course.institution) {
          if (InstitutionsManager?.institution) {
            course.institution = InstitutionsManager.institution;
          }
        }

        if (resource.result?.institution?.calendarSettings) {
          if (!InstitutionsManager.institution) InstitutionsManager.institution = {};
          InstitutionsManager.institution.calendarSettings = resource.result?.institution?.calendarSettings;
        }

        course.inArchiveMode = course.inArchiveMode || (course.userCourse?.accessInArchiveMode);

        if (shouldSetCourse) {
          setCourse(course);
        }
        deferred.resolve(course);
        manager.requestCurrentCoursePromise = null;
        if (CurrentUserManager.user) {
          CurrentUserManager.initializePendo(course);
        }

        // This is used determine whether the full course data is loaded to the currentUserManager course hashes.
        // When course hashes are updated from myaccount api fullCourseLoaded  will be false
        // and this will get set to true when full course data is loaded.
        course.fullCourseLoaded = true;
        CurrentUserManager.addCourse(course, true);

        return course;
      },
      (error) => {
        // Attempt requesting the course data again should the previous request fail and is not an intended rejection by backend
        if (currentRetry > 0 && (error.status < 400 || error.status >= 500)) {
          manager.requestCurrentCoursePromise = null;
          const newPromise = manager.requestCourse(catalogId, shouldSetCourse, currentRetry);
          deferred.resolve(newPromise);
          return;
        }

        // Give up and reset the course data after our attempt chances are used up
        if (shouldSetCourse) {
          manager.resetCourse();
          CurrentUserManager.resetCurrentUserCourse(null);
        }

        // We reject our promise because we usually have a mechanism to redirect the user
        deferred.reject(error);
        manager.requestCurrentCoursePromise = null;

        // If we have an error that is not intended by the backend, inform Sentry
        if (error.status < 400 || error.status >= 500) {
          throw new Error(`Unable to retrieve course with catalogId = ${catalogId}; error status = ${error.status}`);
        }
      },
    );

    manager.requestCurrentCoursePromise = deferred.promise;
    return deferred.promise;
  }

  function requestCourseDomainInfo(catalogId) {
    return CoursesResource.getDomainInfo({ catalogId }).$promise;
  }

  function resetCourse() {
    manager.course = null;
    manager.rawCourseData = null;
  }

  function setCourse(course) {
    manager.course = course;
    PusherManager.initializeCourseChannel(course.id);
    CurrentPermissionsManager.setCurrentCourse(course);
    CurrentUserManager.resetCurrentUserCourse(course.userCourse);
    CourseRolesManager.loadCourseRoles(course.institutionId);
  }

  function getPendoProperties() {
    if (!manager.course) {
      return {};
    }
    return _.extend(CurrentPermissionsManager.getPendoProperties(), manager.course.getPendoProperties());
  }

  function getCriteriaCount() {
    if (manager.completionCriteria) {
      manager.requiredCriteria = _.filter([manager.completionCriteria.requiredPoints,
        manager.completionCriteria.requiredAssignmentsCount, manager.completionCriteria.totalRequiredTodosCount], (count) => count !== 0);
    }

    return manager.requiredCriteria?.length ? CRITERIA_COUNT[manager.requiredCriteria.length] : 0;
  }


  function getCourseCompletionCriteria() {
    return manager.course.loadCourseCompletionCriteria().$promise;
  }

  function updateCourseBasics(catalogId, patch) {
    const payload = {
      course: {
        institutionId: manager.course.institutionId,
        catalogId,
        ...patch,
      },
    };

    return CoursesResource.updateCourseBasics({ id: manager.course.id }, payload).$promise.then((resolved) => {
      manager.course.updateFromReact(patch);

      return resolved;
    });
  }

  function getMetaTags() {
    return {
      'og:title': `${manager.course?.name}`,
      'og:description': `${manager.course?.executiveSummary}`,
      'og:image': `${manager.course?.promoPicture}`,
    };
  }

  function setUserCourse(userCourse) {
    manager.course.userCourse = new UserCourseModel(userCourse);
  }

  function enroll(referralToken) {
    return manager.course.enroll(referralToken)
      .then((userCourse) => {
        userCourse.catalogId = manager.course.catalogId;
        manager.setUserCourse(userCourse);
        manager.course.inArchiveMode = userCourse.accessInArchiveMode;

        CurrentUserManager.addCourse(manager.course);
        CurrentUserManager.resetCurrentUserCourse(userCourse);
        CurrentUserManager.addUserCourse(userCourse);
        CurrentUserManager.user.enrollments.push(userCourse);
      });
  }

  function reenroll() {
    return manager.course.reenroll()
      .then((userCourse) => {
        manager.setUserCourse(userCourse);
        manager.course.inArchiveMode = userCourse.accessInArchiveMode;

        CurrentUserManager.addCourse(manager.course);
        CurrentUserManager.resetCurrentUserCourse(userCourse);
        CurrentUserManager.addUserCourse(userCourse);
        CurrentUserManager.user.enrollments.push(userCourse);
      });
  }


  function searchUsers(includeLearners, includeAdmins, includeMentors, page, pageSize, queryTerm, filterByRole, notAssignedTo) {
    return UserManagementResources.searchCourseUsers({
      catalogId: manager.course.catalogId,
    }, {
      page,
      pageSize,
      queryTerm,
      courseAdmins: includeAdmins,
      courseLearners: includeLearners,
      courseMentors: includeMentors,
      filterByRole,
      notAssignedTo,
    })
      .$promise.then((response) => {
        const users = _.map(response.result.users, (r) => {
        // "Lift up" certain properties onto the user object so they're more conveniently accessible in search results
        // tables.
          if (r.user.roles.courseRoleId) {
            r.user.courseRole = CourseRolesManager.roles[r.user.roles.courseRoleId];
          }

          if (r.lastActivity) {
            r.user.lastActive = moment(r.lastActivity).format('L');
          }

          if (r.menteesCount) {
            r.user.menteesCount = r.menteesCount;
          }

          r.user.invisibleToSearch = r.invisibleToSearch;
          r.user.completionStatus = r.completionStatus;
          r.user.accessCloseDate = r.accessCloseDate;
          r.user.hasBeenEnrolledToMultipleCourses = r.hasBeenEnrolledToMultipleCourses;

          return new UserModel(r.user);
        });

        return {
          users,
          count: response.result.count,
        };
      });
  }

  function getEnrolledUserCounts() {
    return UserManagementResources.enrolledCounts({ catalogId: manager.course.catalogId }).$promise;
  }

  /** Set whether a user is hidden or shown in the Teaching Team Directory
   * @param {number} userId */
  function setCommunitySearchVisibility(userId, isInvisibleToSearch) {
    return UserManagementResources.toggleCommunitySearchVisibility({
      catalogId: manager.course.catalogId,
    }, {
      userId,
      invisibleToSearch: isInvisibleToSearch,
    }).$promise;
  }

  // Set the welcomeEmailEnabled field course from the react side
  function setWelcomeEmail(isEnabled) {
    manager.course.welcomeEmailEnabled = isEnabled;
  }

  function shouldShowCohortManagementIndicators() {
    return manager.course?.isPrimary && (
      CurrentPermissionsManager.hasOrgAdminPermissions()
      || CurrentPermissionsManager.hasCourseManagerPermissions()
      || CurrentPermissionsManager.hasCourseAdminPermissions()
    );
  }

  return manager;
}
