import { css } from '@emotion/react';
import React from 'react';
import NvUserAvatar from 'components/nv-user-avatar';
import moment from 'moment';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';
import { useSelector } from 'react-redux';
import NvStarRating from 'shared/components/nv-star-rating';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import { getSkillTag } from 'redux/selectors/skills-feedback';
import ClickableContainer from 'components/clickable-container';
import NvIcon from 'shared/components/nv-icon';

type ViewFeedbackProps = {
  ratingId: number
  isAuthor?: boolean
  onDeleteFeedback?: (id: number) => void
  canDelete?: boolean
};
const styles = css`
  .delete-feedback {
    opacity: 0;
  }
  &:hover, &:focus-within {
    .delete-feedback {
      opacity: 1;
    }
  }
`;

const ViewFeedback = ({
  ratingId,
  isAuthor = false,
  onDeleteFeedback,
  canDelete,
}: ViewFeedbackProps) => {
  const skillRating = useSelector(state => state.models.skillRatings[ratingId]);
  const skillTag = useSelector(state => getSkillTag(state, skillRating?.skillTaggingId));
  const currentUserId = useSelector(state => state.app.currentUserId);

  const angularServices = React.useContext(AngularServicesContext);

  let ratingRounded = Number(skillRating?.rating) % 1 !== 0 ? Number(skillRating?.rating).toFixed(1) : skillRating?.rating;
  ratingRounded = (Number(ratingRounded) * 100) / 100; // To convert 3.0 to 3

  return (
    skillRating ? (
      <div className='d-flex mb-2' css={styles}>
        {!isAuthor && skillRating?.user && (
          <div className='d-none d-md-block'>
            <NvUserAvatar
              borderType='round'
              size='md'
              user={skillRating.user}
              displayName
              className='mx-4 mb-1 gray-2 text-small'
              directToOrgLevelProfile
              displayRoleBadge
            />
          </div>
        )}
        <div className='d-flex flex-column w-100 ml-2'>
          {!isAuthor && (
            <div className='d-flex d-md-none mb-4 justify-content-between'>
              { skillRating?.user && (
                <NvUserAvatar
                  displayName
                  inlineName
                  displayRoleBadge={false}
                  alignNameRight
                  user={skillRating?.user}
                  size='sm'
                  borderType='round'
                  directToOrgLevelProfile
                />
              )}
              <div className='d-flex align-items-center'>
                <span className='text-yellow'>
                  <NvIcon
                    icon='highlight'
                    size='smallest'
                  />
                </span>
                <div className='text-body-large gray-2 ml-1'>{ratingRounded}</div>
              </div>
            </div>
          )}
          <div className={`${!isAuthor ? 'd-none d-md-flex ' : ''} justify-content-between mb-2`}>
            <div className='d-flex'>
              <div className='text-medium font-weight-bolder gray-2 mr-2'>{skillTag?.name}</div>
              <NvStarRating readonly ratedValue={skillRating?.rating} starSize='smallest' />
            </div>
          </div>
          <ResponsivelyEmbeddedAngularHTML
            className='text-body'
            angularServices={angularServices}
            template={skillRating?.body}
          />
          <div className='w-100 d-flex justify-content-between'>
            <div>
              {onDeleteFeedback && (canDelete || (currentUserId === skillRating?.user?.id)) && (
                <ClickableContainer
                  className='delete-feedback d-flex gray-3 align-items-center'
                  onClick={() => onDeleteFeedback(skillRating?.user?.id)}
                >
                  <NvIcon icon='trash' size='xss-smallest' className='mr-1' />
                  {t.NOVOED.DELETE()}
                </ClickableContainer>
              )}
            </div>
            {!isAuthor && (
              <div className='text-date gray-2'>{moment(skillRating?.createdAt).format('lll')}</div>
            )}
          </div>
        </div>
      </div>
    ) : <div />
  );
};

export default ViewFeedback;
