import { css } from '@emotion/react';
import React, { useEffect } from 'react';
import t from 'react-translate';
import { getAllFeedback, getSummary } from 'redux/actions/skills-feedback';
import { Summary } from 'redux/schemas/app/skills-feedback';
import { useAppDispatch } from 'redux/store';
import LoadingWrapper, { LoaderType } from 'shared/components/loading-wrapper';
import RatingSummary, { FEEDBACK_PAGE_SIZE } from 'shared/components/skill-feedback/rating-summary';
import { gray4 } from 'styles/global_defaults/colors';
import { standardSpacing } from 'styles/global_defaults/scaffolding';

type FeedbackSummaryProps = {
  summary: Summary[]
  institutionId: number
  reportId: number
  isLoaded: boolean
  canDelete: boolean
  feedbackCriteriaId?: number
  onDelete?: () => void
};

const FeedbackSummary = ({
  summary,
  institutionId,
  reportId,
  isLoaded,
  canDelete,
  feedbackCriteriaId,
  onDelete,
}: FeedbackSummaryProps) => {
  const styles = css`
    .tag-badge {
      background: white !important;
      border: solid 1px ${gray4} !important;
      border-radius: ${standardSpacing}px !important;
    }
  `;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSummary({
      institutionId,
      ownerId: reportId,
      ownerType: 'Report',
    }));
  }, [dispatch, institutionId, reportId]);

  return (
    <LoadingWrapper
      isLoaded={isLoaded}
      loaderType={LoaderType.PLACEHOLDER}
      className='w-100'
    >
      {summary?.length > 0 && (
        <div css={styles} className='p-4'>
          {summary?.map(skill => (
            <RatingSummary
              key={skill.skillTagId}
              skillTagId={skill.skillTagId}
              skillRating={skill.avg}
              totalRatings={skill.totalRatings}
              submissionId={reportId}
              onGetSkillsFeedback={(afterId) => dispatch(getAllFeedback({
                institutionId,
                ownerId: reportId,
                ownerType: 'Report',
                skillTaggingId: skill.skillTaggingId,
                afterId,
                displayOwnFeedback: false,
                pageSize: FEEDBACK_PAGE_SIZE,
                feedbackCriteriaId,
              }))}
              skillTaggingId={skill.skillTaggingId}
              onDeleteFeedback={onDelete}
              canDelete={canDelete}
              ownerKey='exerciseSubmissions'
            />
          ))}
          <div className='text-small gray-3 ml-2 mt-6 '>
            {t.SKILLS_FEEDBACK.FEEDBACK_VISIBILITY.ALL()}
          </div>
        </div>
      )}
    </LoadingWrapper>
  );
};

export default FeedbackSummary;
