/* @ngInject */
export default function NvShowOnFocus(
) {
  return {
    link(scope, element, attrs) {
      let lastInputElement;

      scope.$watch(
        () => getInputElement().length, () => {
          const thisInputElement = getInputElement();
          element.hide();

          if (lastInputElement) {
            lastInputElement.unbind('focus.nvShowOnFocus');
            lastInputElement.unbind('blur.nvShowOnFocus');
          }

          thisInputElement.on('focus.nvShowOnFocus', () => {
            element.show();
          });

          thisInputElement.on('blur.nvShowOnFocus', () => {
            element.hide();
          });

          lastInputElement = thisInputElement;
        },
      );

      function getInputElement() {
        return $(`[name=${attrs.nvShowOnFocus}]`);
      }
    },
  };
}
