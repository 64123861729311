/* @ngInject */
export default function StatementBaseCtrl(
  PageLevelManager,
  ExercisesManager,
  $stateParams,
  nvCurrentPage,
  CurrentUserManager,
  CurrentCourseManager,
  $controller,
  $scope,
) {
  const vm = angular.extend(this, $controller('L3HeaderCtrl', { $scope }));

  vm.$stateParams = $stateParams;
  vm.PageLevelManager = PageLevelManager;
  vm.ExercisesManager = ExercisesManager;
  vm.nvCurrentPage = nvCurrentPage;
  vm.CurrentUserManager = CurrentUserManager;
  vm.course = vm.CurrentUserManager.coursesHashByCatalogId[$stateParams.catalogId] || CurrentCourseManager.course;

  vm.subtitleText = vm.course.certificateName.singularizedTitleized;
}
