/* @ngInject */
export default function NotificationsCtrl(
  nvUtil,
  NotificationsManager,
  CurrentUserManager,
  CurrentCourseManager,
  InteroperabilityRoutes,
  config,
  $state,
  $element,
) {
  const vm = this;
  vm.$state = $state;
  vm.config = config;
  vm.manager = NotificationsManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.InteroperabilityRoutes = InteroperabilityRoutes;

  vm.hasMultipleSubjectsOrProvidedAttribute = function (notification, attribute) {
    // currently cleanup is not happening so to fail as gracefully as possible we will just return true so it goes to the parent link
    if (!notification.subjects.length) {
      return true;
    }

    // normal case
    return !!(notification.subjects.length > 1 || notification.subjects[0][attribute].length > 1);
  };

  vm.getSubmissionPath = function (course, submissionType, submissionId, focusCommentId, galleryMode, replyId, userId) {
    return InteroperabilityRoutes.submissionPath(course, nvUtil.toSnakeCase(submissionType), submissionId, focusCommentId, galleryMode, replyId, userId);
  };

  vm.loadCourseNotifications = function loadCourseNotifications(course) {
    vm.manager.setCourse(course);
    vm.manager.practiceRoomNotificationsShown = false;
    if ($element[0]?.parentNode) {
      setTimeout(() => {
        const defaultSelector = $element[0].parentNode.querySelector('.course-row') ?? null;
        nvUtil.focusFirstElement($element[0]?.parentNode, defaultSelector);
      });
    }
  };

  vm.displayCourseList = function displayCourseList() {
    vm.manager.setCourse();
    if ($element[0]?.parentNode) {
      nvUtil.focusFirstElement($element[0]?.parentNode);
    }
  };

  vm.togglePracticeNotifications = function () {
    vm.manager.practiceRoomNotificationsShown = !vm.manager.practiceRoomNotificationsShown;
  };

  vm.toggleCurrentCourseNotifications = function () {
    if (vm.manager.currentCourse) {
      vm.manager.currentCourseNotificationsShown = !vm.manager.currentCourseNotificationsShown;
    }
  };
}
