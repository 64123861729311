/* eslint-disable max-classes-per-file */
import { primary, yellow } from 'styles/global_defaults/colors';

/* @ngInject */
export default function StyledLinkLectureComponentModel(
  _,
  LectureComponentBaseModel,
  $translate,
  config,
) {
  class StyledLinkLectureComponent extends LectureComponentBaseModel {
    constructor(attributes) {
      const defaults = {
        canBeCopied: true,
      };

      super({ ...defaults, ...attributes });
    }

    getPayload() {
      return _.pick(this, ['index', 'header', 'content', 'view_options']);
    }

    saveDraft() {
      let urlChanged;
      if (this.content !== this.styledLinkDraft.content) {
        urlChanged = true;
      }

      _.extend(this, this.styledLinkDraft);

      if (this.viewOptions.styleType === 'card') {
        this.header = '';
      }
    }
  }

  StyledLinkLectureComponent.showModalBeforeCreate = true;
  StyledLinkLectureComponent.type = 'StyledLinkLectureComponent';
  StyledLinkLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.STYLED_LINK.DESCRIPTION';

  return StyledLinkLectureComponent;
}
