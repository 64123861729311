/* @ngInject */
export default function LShowExerciseHeaderCtrl(
  ExercisesManager,
  InteroperabilityRoutes,
  CurrentUserManager,
  $controller,
  $scope,
) {
  const vm = angular.extend(this, $controller('L3HeaderCtrl', { $scope }));

  vm.manager = ExercisesManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.ExercisesManager = ExercisesManager;
  vm.InteroperabilityRoutes = InteroperabilityRoutes;

  vm.subtitleSref = 'lecture-page({ id: vm.ExercisesManager.currentExercise.lecturePage.id })';
  vm.subtitleText = ExercisesManager.currentExercise.lecturePage.title;
}
