/* @ngInject */
export default function TeamProfilePaginationController(
  $stateParams,
  $state,
  TeamDirectoryManager,
  TeamManager,
  _,
) {
  const vm = this;

  vm.TeamDirectoryManager = TeamDirectoryManager;
  vm.TeamManager = TeamManager;
  vm.$stateParams = $stateParams;

  vm.getPrevious = getPrevious;
  vm.getNext = getNext;

  function getPrevious() {
    if (!$stateParams.galleryMode) {
      return undefined;
    }


    if (!vm.TeamManager.currentTeam) {
      return null;
    }

    const prev = vm.TeamDirectoryManager.teams[(_.findIndex(vm.TeamDirectoryManager.teams, { id: vm.TeamManager.currentTeam.id }) - 1)];

    if (!prev) {
      return null;
    }

    return $state.href('team-profile-modal',
      {
        catalogId: $stateParams.catalogId,
        teamId: prev ? prev.id : null,
      });
  }

  function getNext() {
    if (!$stateParams.galleryMode) {
      return undefined;
    }

    if (!vm.TeamManager.currentTeam) {
      return null;
    }

    const next = vm.TeamDirectoryManager.teams[(_.findIndex(vm.TeamDirectoryManager.teams, { id: vm.TeamManager.currentTeam.id }) + 1)];

    if (!next) {
      return null;
    }
    return $state.href('team-profile-modal',
      {
        catalogId: $stateParams.catalogId,
        teamId: next ? next.id : null,
      });
  }
}
