import React from 'react';
import { css } from '@emotion/react';
import { Controller, FieldError } from 'react-hook-form';

import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import { boldFontWeight, openSans } from 'styles/global_defaults/fonts';
import { gray2, primary, success, warning } from 'styles/global_defaults/colors';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvSelect, { SelectOption } from 'shared/components/inputs/nv-select';
import { doubleSpacing, halfSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import NvTooltip from 'shared/components/nv-tooltip';
import { config } from '../../../config/pendo.config.json';

export type Value = {
  value: CompletionCriteria,
  meta: number | string | null, // For AT_LEAST_X_COURSES value
};

type Props = {
  value: Value,
  onChange: (newValue: Value) => void,
  className?: string,
  inputError?: FieldError,
};

export enum CompletionCriteria {
  ALL_IN_ORDER = 0,
  ALL_IN_ANY_ORDER = 1,
  AT_LEAST_X_COURSES = 2,
  OPTIONAL = 3,
}

export enum CollectionsCompletionCriteria {
  ALL_IN_ORDER = 0,
  ALL_IN_ANY_ORDER = 1,
}


const CompletionCriteriaDropdown = (props: Props) => {
  const {
    value,
    onChange,
    className,
    inputError,
  } = props;

  const options: SelectOption[] = [
    {
      label: t.LEARNING_JOURNEYS.DETAILS.COMPLETION_CRITERIA_OPTIONS.ALL_IN_ORDER(),
      value: CompletionCriteria.ALL_IN_ORDER,
      dataQa: config.pendo.learningJourneys.completionCriteria.allInOrder,
    },
    {
      label: t.LEARNING_JOURNEYS.DETAILS.COMPLETION_CRITERIA_OPTIONS.ALL_IN_ANY_ORDER(),
      value: CompletionCriteria.ALL_IN_ANY_ORDER,
      dataQa: config.pendo.learningJourneys.completionCriteria.anyOrder,
    },
    {
      label: t.LEARNING_JOURNEYS.DETAILS.COMPLETION_CRITERIA_OPTIONS.AT_LEAST_X_COURSES(),
      value: CompletionCriteria.AT_LEAST_X_COURSES,
      dataQa: config.pendo.learningJourneys.completionCriteria.atLeastX,
    },
    {
      label: t.LEARNING_JOURNEYS.DETAILS.COMPLETION_CRITERIA_OPTIONS.OPTIONAL(),
      labelExtraInfo: (
        <NvTooltip text={t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.OPTIONAL_INFO_TOOLTIP()}>
          <NvIcon icon='info' size='xss-smallest' className='color-primary px-2' />
        </NvTooltip>
      ),
      value: CompletionCriteria.OPTIONAL,
      dataQa: config.pendo.learningJourneys.completionCriteria.optional,
    },
  ];

  const styles = css`
    display: flex;
    align-items: center;
    height: ${doubleSpacing}px;

    & > span {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }

    .input {
      width: ${tripleSpacing}px;
      margin: 0 ${halfSpacing}px;

      input {
        color: ${gray2};
      }
    }

    & > .icon {
      transform: rotate(90deg);
      margin-left: ${halfSpacing}px;
    }

    & > span, input {
      font-family: ${openSans};
    }

    & > span, & > .icon {
      color: ${primary};
    }
  `;

  const handleSelectChange = (newValue) => {
    let meta = null;

    if (newValue === CompletionCriteria.AT_LEAST_X_COURSES) {
      meta = '';
    }

    onChange({
      meta,
      value: newValue,
    });
  };

  const handleInputChange = (event) => {
    const numberValue = Number(event.target.value);

    onChange({
      value: value.value,
      meta: numberValue || '',
    });
  };

  // Preventing the input to increase/decrease the number value when scrolling
  // over it
  const handleInputWheel = (event) => event.target.blur();

  return (
    <NvSelect
      align='center'
      options={options}
      value={value.value}
      className={className}
      onChange={handleSelectChange}
      renderToggle={() => {
        const renderContent = () => {
          const handleTextInputClick = (event) => event.stopPropagation();

          switch (value.value) {
            case CompletionCriteria.ALL_IN_ORDER:
              return <span>{t.LEARNING_JOURNEYS.DETAILS.COMPLETION_CRITERIA_OPTIONS.ALL_IN_ORDER()}</span>;
            case CompletionCriteria.ALL_IN_ANY_ORDER:
              return <span>{t.LEARNING_JOURNEYS.DETAILS.COMPLETION_CRITERIA_OPTIONS.ALL_IN_ANY_ORDER()}</span>;
            case CompletionCriteria.AT_LEAST_X_COURSES:
              return (
                <React.Fragment>
                  <span>{t.LEARNING_JOURNEYS.DETAILS.CUSTOM_TOGGLE.COMPLETE_AT_LEAST()}</span>
                  <NvTextInput
                    min={1}
                    required
                    type='number'
                    className='input'
                    value={value.meta}
                    errorOnTouching={false}
                    error={inputError as any}
                    onChange={handleInputChange}
                    onWheel={handleInputWheel}
                    onClick={handleTextInputClick}
                  />
                  <span>{t.NOVOED.COURSES()}</span>
                </React.Fragment>
              );
            case CompletionCriteria.OPTIONAL:
              return <span>{t.LEARNING_JOURNEYS.DETAILS.COMPLETION_CRITERIA_OPTIONS.OPTIONAL()}</span>;
            default: throw new Error('Unhandled value type');
          }
        };

        return (
          <div css={styles}>
            {renderContent()}
            <NvIcon
              icon='play'
              size='xss-smallest'
            />
          </div>
        );
      }}
    />
  );
};

type CompletionCriteriaLabelProps = {
  completionCriteria: Value,
  className?: string,
  isCollectionCompleted: boolean,
};

/* @ngInject */
export function CompletionCriteriaLabel({ className, completionCriteria, isCollectionCompleted }: CompletionCriteriaLabelProps) {
  const { value, meta } = completionCriteria;

  const getRightFontColor = () => {
    if (value === CompletionCriteria.OPTIONAL) {
      return gray2;
    }
    if (isCollectionCompleted) {
      return success;
    }
    return warning;
  };

  const styles = css`
    span {
      color: ${getRightFontColor()};
      font-weight: ${boldFontWeight};
    }
  `;

  const renderLabel = () => {
    const translator = isCollectionCompleted
      ? t.LEARNING_JOURNEYS.DETAILS.COMPLETED_CRITERIA_OPTIONS
      : t.LEARNING_JOURNEYS.DETAILS.COMPLETION_CRITERIA_OPTIONS;
    switch (value) {
      case CompletionCriteria.ALL_IN_ORDER:
        return <span>{translator.ALL_IN_ORDER()}</span>;
      case CompletionCriteria.ALL_IN_ANY_ORDER:
        return <span>{translator.ALL_IN_ANY_ORDER()}</span>;
      case CompletionCriteria.AT_LEAST_X_COURSES:
        return (
          <React.Fragment>
            <span>
              {
                isCollectionCompleted
                  ? t.LEARNING_JOURNEYS.DETAILS.COMPLETED_CRITERIA_OPTIONS.AT_LEAST()
                  : t.LEARNING_JOURNEYS.DETAILS.CUSTOM_TOGGLE.COMPLETE_AT_LEAST()
              }
            </span>
            <span>{` ${meta} `}</span>
          </React.Fragment>
        );
      case CompletionCriteria.OPTIONAL:
        return <span>{translator.OPTIONAL()}</span>;
      default: throw new Error('Unhandled value type');
    }
  };

  return (
    <div className={`collection-completion-criteria${className ? ` ${className}` : ''}`} css={styles}>
      {renderLabel()}
    </div>
  );
}


type FormCompletionCriteriaDropdownProps = Omit<Props, 'value' | 'onChange'> & {
  name: string,
  defaultValue?: Value,
};

export const FormCompletionCriteriaDropdown = (props: FormCompletionCriteriaDropdownProps) => {
  const {
    name,
    defaultValue,
    ...restProps
  } = props;

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ field: { value, onChange } }) => (
        <CompletionCriteriaDropdown
          {...restProps}
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
};

export default CompletionCriteriaDropdown;
