import { css } from '@emotion/react';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

// Schemas
import { HasLoggedInFilterType } from 'redux/schemas/models/course-communication';

// Components
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import NvRadioButton from 'shared/components/inputs/nv-radio-button';
import NvTextInput from 'shared/components/inputs/nv-text-input';

import { optionsConflictWithHasNotLoggedIn } from './logged-in';

// Styles
import baseStyles from './base-styles';

type NumberInputProps = {
  name: string,
  checkboxName?: string,
  // If there is an event value, then this is considered as a event
  // If not, then this is a filter
  eventValue?: string,
  defaultLabel: string,
  inputLabel: string,
  inputWidth?: number,
  ariaLabel?: string,
  info?: string,
  // puts a note under the main label
  note?: string,
  // If one of the following fields is selected, disable this
  disableOn?: string[],
};

const styles = (name: string, width: number = 80) => css`
  &.${name} {
    ${baseStyles};
    &.has-note {
      display: block;
    }
    .content .nv-text-input {
      width: ${width}px;
    }
    .info {
      align-self: center;
    }
  }
`;

/**
 * A generic filter/event row, with a checkbox/radio for communication form
 * If this is checked/enabled, then an input number field will be displayed
 * in appropriate place in label.
 */
const NumberInput = ({
  name,
  checkboxName,
  eventValue,
  defaultLabel,
  inputLabel,
  inputWidth,
  ariaLabel,
  info,
  note,
  disableOn,
}: NumberInputProps) => {
  const ref = useRef<HTMLInputElement>();

  // If its an event, watch the value of 'event' and if its equal to
  // the eventValue given, then this is enabled
  // If its a filter, watch the value of the checkbox and if its checked
  // then this is enabled
  const { watch, setValue } = useFormContext();
  const fieldValue = eventValue ? watch('event') : watch(checkboxName);
  const isEnabled = eventValue ? fieldValue === eventValue : fieldValue;

  // Disable this filter if the dependent field is selected
  // OR has not logged in since course release date or start date selected
  const [hasLoggedInChecked, hasLoggedIn] = watch(['hasLoggedInChecked', 'hasLoggedIn']);
  let dependentFieldChecked;

  if (disableOn?.length > 0) {
    dependentFieldChecked = disableOn.find(filter => watch(filter));
  }

  const isFilterDisabled: boolean = dependentFieldChecked || (hasLoggedInChecked
    && optionsConflictWithHasNotLoggedIn.includes(checkboxName)
      && (hasLoggedIn?.value === HasLoggedInFilterType.HAS_NOT_SINCE_START_DATE
        || hasLoggedIn?.value === HasLoggedInFilterType.HAS_NOT_SINCE_RELEASE_DATE));

  const [prefix, suffix] = inputLabel.split('INPUT');

  const onCheckboxToggle = (e) => {
    const { checked } = e.target;

    if (checked && ref) {
      setTimeout(() => {
        ref.current.focus();
      });
    }
  };

  const onRadioSelect = () => {
    if (ref) {
      setTimeout(() => {
        ref.current.focus();
      });
    }
  };

  useEffect(() => {
    /**
     * The value of this field becomes NaN, and the form will become inValid
     * So changing it to null when the field becomes unchecked
     * https://novoed.atlassian.net/browse/NOV-79125
     * https://novoed.atlassian.net/browse/NOV-76458
     */
    if (!isEnabled) {
      setValue(name, null, { shouldDirty: true, shouldValidate: true });
    }
  }, [isEnabled, name, setValue]);

  return (
    <div css={styles(name, inputWidth)} className={`${name}${isEnabled ? ' on' : ''}${isFilterDisabled ? ' disabled' : ''}${note && isEnabled ? ' has-note' : ''}`}>
      <div className='d-flex w-100'>
        <div className='checkbox-wrapper'>
          {eventValue ? (
            <NvRadioButton
              withForm
              value={eventValue}
              name='event'
              className='w-100'
              label={!isEnabled && defaultLabel}
              labelClassName='text-large-regular pl-6'
              onChange={onRadioSelect}
            />
          ) : (
            <NvCheckbox
              withForm
              name={checkboxName}
              className='w-100'
              label={!isEnabled && defaultLabel}
              labelClassName='text-large-regular pl-6'
              onChange={onCheckboxToggle}
              disabled={isFilterDisabled}
            />
          )}
        </div>
        <div className='content text-large-regular'>
          {prefix}
          <NvTextInput
            required
            withForm
            type='number'
            autoComplete='off'
            name={name}
            ariaLabel={ariaLabel}
            inputClassName='text-large-regular'
            ref={ref}
          />
          {suffix}
        </div>
        {info && <div className='info text-small text-gray-2 font-weight-bold'>{info}</div>}
      </div>
      {note && isEnabled && <div className='note text-small text-gray-2 font-weight-bold py-1'>{note}</div>}
    </div>
  );
};

NumberInput.defaultProps = {
  checkboxName: null,
  eventValue: null,
  ariaLabel: '',
  info: '',
};

export default NumberInput;


