import React, { useState } from 'react';

// Components
import MultipleLiveSessions from './multiple-live-sessions';
import { RecordingEvent, UploadEvents } from './live-session-card/live-session-card';

type LiveSessionLectureComponentProps = {
  lectureComponent: any
  isEdit: boolean
  onSaveExpectations: (e: any) => void
  register: (sessionId: number) => void
  joinNow: (sessionId: number) => void
  unregister: (sessionId: number) => void
  uploadAttendeeList: (sessionId: number) => void
  uploadRecording: (sessionId: number, events: UploadEvents) => void
  editRecording: (sessionId: number, file: any, onSuccess: (recording: any) => void) => void
  removeRecording: (sessionId: number, onSuccess: () => void) => void
  delete: (sessionId: number) => void
  recordingEventCallback: (
    event: RecordingEvent,
    second: number,
    totalLength: number,
    lecturePageId: number,
    lectureComponentId: number,
    sessionId: number,
  ) => void
};

const LiveSessionLectureComponent = (props: LiveSessionLectureComponentProps) => {
  const {
    lectureComponent,
    isEdit,
    onSaveExpectations,
    register,
    joinNow,
    unregister,
    uploadAttendeeList,
    uploadRecording,
    editRecording,
    removeRecording,
    delete: deleteSession,
    recordingEventCallback,
  } = props;

  const { liveSession } = lectureComponent;
  const { sessions: initialSessions } = liveSession;
  const [sessions, setSessions] = useState(initialSessions);

  const onDeleteSession = (sessionId) => {
    const newSessions = sessions.filter(session => session.id !== sessionId);
    setSessions(newSessions);
  };

  return (
    <MultipleLiveSessions
      sessions={sessions}
      mainDescription={liveSession.mainDescription}
      isEdit={isEdit}
      onSaveExpectations={onSaveExpectations}
      register={register}
      joinNow={joinNow}
      unregister={unregister}
      uploadAttendeeList={uploadAttendeeList}
      uploadRecording={uploadRecording}
      editRecording={editRecording}
      removeRecording={removeRecording}
      delete={deleteSession}
      onSuccessDeletion={onDeleteSession}
      recordingEventCallback={recordingEventCallback}
    />
  );
};

export default LiveSessionLectureComponent;
