import React, { useContext } from 'react';
import { AngularServicesContext } from 'react-app';

import CourseTimeline, { CourseTimelineContext } from './course_home/course-timeline';

const PointsBreakDownModal = () => {
  const { $scope } = useContext(AngularServicesContext);

  return (
    <CourseTimelineContext.Provider
      value={{
        vieweeId: $scope.userId,
        showOutline: $scope.showOutline ?? true,
        defaultTab: $scope.selectedTab,
        courseCompletion: {
          status: $scope.courseCompletionStatus,
          progress: $scope.courseCompletionProgress,
        },
        isLearnerProgressModal: true,
        isFullTimeline: true,
      }}
    >
      <CourseTimeline />
    </CourseTimelineContext.Provider>
  );
};

export default PointsBreakDownModal;
