export default {
  bindings: {
    tabs: '<',
    selectedTabIndex: '=?',
    // Callback function fired after a tab has been clicked.
    // Takes one argument: @type {string} - the name of the state switched to
    onTabChanged: '&?',
    // If this is true, the responsive tabs UI won't be a dropdown if there's
    // only one tab
    singleEntryNoDropdown: '=?',
  },
  controllerAs: 'vm',
  controller: function ctrl($scope, $state, _, $translate) {
'ngInject';
    const vm = this;
    if (vm.selectedTabIndex >= 0 && vm.tabs.length) {
      vm.activeTabIndex = vm.selectedTabIndex;
      vm.selectedTab = vm.tabs[vm.activeTabIndex];
      vm.selectedTab.isSelected = true;
    }

    // Track whether the Angular state has just changed before deselecting a tab.
    // Used to prevent changing the selected tab prematurely in case the tab click
    // causes a dialog to preempt the state change.
    let stateChanged = false;

    function selectTab(stateName) {
      const tabIndex = _.findIndex(vm.tabs, (x) => x.state === stateName);

      if (tabIndex === -1) {
        return;
      }

      vm.activeTabIndex = tabIndex;
      vm.selectedTab = vm.tabs[vm.activeTabIndex];
      vm.selectedTab.isSelected = true;

      if (vm.onTabChanged) {
        vm.onTabChanged()(stateName);
      }
    }

    const stateListener = $scope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
      if (vm.selectedTab) {
        vm.selectedTab.isSelected = false;
      }
      selectTab(toState.name);
      stateChanged = true;
    });

    $scope.$on('$destroy', stateListener);

    // Select the current tab to ensure it's set after a page refresh
    selectTab($state.current.name);

    vm.tabDeselected = function ($event) {
      if ($event && !stateChanged) {
        $event.preventDefault();
      }

      stateChanged = false;
    };

    vm.tabsHaveLongText = function () {
      let characterCount = 0;
      vm.tabs.forEach(tab => {
        // Counting the total of characters of every tab name
        const text = $translate.instant(tab.name, tab.translateValues);
        characterCount += text.length;
      });
      // If the total is bigger than 80, the it will add the class "long-text"
      // Changing the style only of the dashboard resposive tab to make the tabs fit.
      return characterCount > 80;
    };

    return vm;
  },
  templateUrl: 'shared/templates/nv-responsive-tabs.html',
};
