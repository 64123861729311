/* @ngInject */
export default function NvNumberConversion(
  validationConstants,
  BigNumber,
) {
  return {
    require: 'ngModel',
    priority: 1,
    link($scope, $element, $attrs, ngModelCtrl) {
      const patt = new RegExp(validationConstants.DECIMAL_NUMBER_REGEX);

      // view -> model
      function parser(value) {
        const strippedVal = value.replace(/,|\$/g, '');

        if (value === '' || strippedVal === '') {
          ngModelCtrl.$setValidity('pattern', true);
          return null;
        } if (value && patt.test(value)) {
          const parseVal = new BigNumber(strippedVal);
          if (!parseVal.isNaN()) {
            ngModelCtrl.$setValidity('pattern', true);
            return parseVal;
          }
        }

        ngModelCtrl.$setValidity('pattern', false);
        return undefined;
      }
      ngModelCtrl.$parsers.unshift(parser);

      // model -> view
      // function formatter(value) {
      //   if (value) {
      //   }
      // }
      // ngModelCtrl.$formatters.push(formatter);
    },
  };
}
