import { css } from '@emotion/react';
import ClickableContainer from 'components/clickable-container';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { hasRatings } from 'redux/selectors/skills-feedback';
import NvConfirmationPopover from 'shared/components/nv-confirmation-popover';
import NvPopover from 'shared/components/nv-popover';
import { gray5, gray7 } from 'styles/global_defaults/colors';
import { textSmallFontSize } from 'styles/global_defaults/fonts';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { config } from '../../../config/pendo.config.json';

const styles = css`
  align-items:center;
  border:1px solid ${gray5};
  border-radius:20px;
  background-color:${gray7};
  display:flex;
  font-size: ${textSmallFontSize}px;
  height:21px;
  margin:5px;
  max-width:250px;
  padding: 0px 10px;
  .name{
    margin-right:10px;
  }
  .close-btn{
    .icon-close{
      font-size:10px;
    }
  }
`;

const overlayStyles = css`
  bottom: -${halfSpacing}px !important;
`;

const SkillItem = ({ onDelete, tag }) => {
  const [showModal, setShowModal] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const tagHasRatings = useSelector(state => hasRatings(state, tag.id));

  const handleDelete = () => {
    if (tagHasRatings) {
      setDeleting(true);
    } else {
      onDelete(tag.id);
    }
  };
  return (
    <NvPopover
      enabled
      show={isDeleting}
      overlayStyles={overlayStyles}
      content={(
        <NvConfirmationPopover
          onCancel={() => setDeleting(false)}
          onConfirm={() => onDelete(tag.id)}
          header={t.INSTITUTIONS.SKILL_TAGS.DELETE_TITLE()}
          message={t.INSTITUTIONS.SKILL_TAGS.DELETE_CONFIRMATION.COURSE()}
          cancelBtnText={t.NOVOED.CANCEL()}
          confirmBtnText={t.NOVOED.DELETE()}
        />
      )}
      placement='top'
      rootClose
      onHide={() => setDeleting(false)}
      className='d-inline-flex mw-100'
      preventOverflow
    >
      <div css={styles}>
        <span className='name ellipsis'>
          {tag.name}
        </span>
        <ClickableContainer
          onClick={handleDelete}
          className='close-btn'
          pendo-tag-name={config.pendo.skillTags.removeSkill}
        >
          <i className='icon-close' />
        </ClickableContainer>
      </div>
    </NvPopover>
  );
};
export default SkillItem;
