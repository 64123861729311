import React from 'react';
import t from 'react-translate';

// Redux
import { SignInErrors, UserLogin } from 'redux/schemas/models/sign-in';

type Props = {
  payload: any,
  maxAttempts: number,
  onSuccess: (payload: UserLogin) => void,
  onError?: (error: string) => void,
  onAccountLocked: () => void,
};

const SignInError = (props: Props) => {
  const { payload, maxAttempts, onSuccess, onError, onAccountLocked } = props;

  const [accountLocked, setAccountLocked] = React.useState<boolean>(false);
  const [emailNotFound, setEmailNotFound] = React.useState<boolean>(false);
  const [failed, setFailed] = React.useState<boolean>(false);
  const [failedAttempts, setFailedAttempts] = React.useState<number>(0);
  const [serverError, setServerError] = React.useState<boolean>(false);

  const errors = [{
    id: 'server-error',
    show: serverError,
    message: t.FORM.ERROR_SOMETHING_WRONG_SHORT(),
  }, {
    id: 'sign-in-failed',
    show: failed && failedAttempts <= 5,
    message: t.USERS.REGISTRATION.ERRORS.INCORRECT_PASSWORD_LEVEL_1(),
  }, {
    id: 'sign-in-failed-remaining-attempts',
    show: failed && failedAttempts > 5 && failedAttempts < maxAttempts - 1,
    message: t.USERS.REGISTRATION.ERRORS.INCORRECT_PASSWORD_LEVEL_2((maxAttempts - failedAttempts).toString()),
  }, {
    id: 'last-attempt',
    show: failed && failedAttempts === maxAttempts - 1,
    message: t.USERS.REGISTRATION.ERRORS.INCORRECT_PASSWORD_LEVEL_3(),
  }, {
    id: 'account-locked',
    show: failed && accountLocked,
    message: t.USERS.REGISTRATION.ERRORS.ACCOUNT_LOCKED(),
  }, {
    id: 'email-not-found',
    show: emailNotFound,
    message: t.USERS.REGISTRATION.ERRORS.INCORRECT_PASSWORD_LEVEL_1(),
  }];

  React.useEffect(() => {
    if (payload) {
      setServerError(false);
      setEmailNotFound(false);
      setFailed(false);
      if (payload.status === 500) {
        setServerError(true);
        onError?.(payload.data?.error?.error_code_params);
      } else if (payload.data?.error) {
        const { code, error_code_params: errorCodeParams = {} } = payload.data.error;
        setFailedAttempts(errorCodeParams.failed_attempts ?? 0);
        if (code === SignInErrors.emailNotFound) {
          setEmailNotFound(true);
        } else {
          setFailed(true);
        }
        onError?.(code);
      } else {
        onSuccess(payload as UserLogin);
      }
    }
  }, [onError, onSuccess, payload]);

  React.useEffect(() => {
    if (failedAttempts === maxAttempts) {
      setAccountLocked(true);
      onAccountLocked();
    }
  }, [failedAttempts, maxAttempts, onAccountLocked]);

  return (
    <>
      {errors.map(({ id, show, message }) => (
        <ErrorComponent
          key={id}
          show={show}
          errorMessage={message}
        />
      ))}
    </>
  );
};

type ErrorComponentType = {
  show: boolean,
  errorMessage: string,
};

const ErrorComponent = ({ show, errorMessage }: ErrorComponentType) => (
  <>{show && <p className='error'>{errorMessage}</p>}</>
);

export default SignInError;
