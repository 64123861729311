import React, { useContext } from 'react';
import t from 'react-translate';
import AdvancedSettingsSwitch from 'institutions/components/advanced-settings-switch';
import { AngularServicesContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import AutomatedTranslationSwitch from 'institutions/components/automated-translation-switch';
import { wrapThunkAction } from 'redux/utils';
import { setSpeechToText } from 'redux/actions/institutions';
import { useSelector } from 'react-redux';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { config } from '../../../../../config/pendo.config.json';
import SectionContent from '../section-content';
import GenerativeAiCapabilities from './generative-ai-capabilities';

const AiHub = () => {
  const dispatch = useAppDispatch();
  const { InstitutionsManager } = useContext(AngularServicesContext);
  const currentInstitution = useSelector(getCurrentInstitution);

  // Handling the speech to text setting
  const switchSpeechToText = (value) => {
    wrapThunkAction(
      dispatch(
        setSpeechToText({
          institutionId: currentInstitution.id,
          enabled: value,
        }),
      ),
    ).then(() => {
      InstitutionsManager.institution.updateFromReact({
        speechToTextEnabled: value,
      });
    }).catch(() => {
      InstitutionsManager.institution.updateFromReact({
        speechToTextEnabled: !value,
      });
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.OOPS(),
        message: t.FORM.ERROR_SOMETHING_WRONG(),
      }));
    });
  };

  return (
    <div>
      <div className='border-bottom border-gray-5 py-6'>
        <GenerativeAiCapabilities />
      </div>
      {InstitutionsManager.institution.allowAutomatedTranslation && (
        <div className='border-bottom border-gray-5 py-6'>
          <SectionContent
            header={t.INSTITUTIONS.ADVANCED_SETTINGS.AUTOMATED_TRANSLATION.TITLE()}
            description={[t.INSTITUTIONS.ADVANCED_SETTINGS.AUTOMATED_TRANSLATION.DESCRIPTION()]}
            showCTAButton={false}
            extras={(
              <div className='d-flex align-self-start'>
                <AutomatedTranslationSwitch />
              </div>
            )}
          />
        </div>
      )}
      <div className='border-bottom border-gray-5 py-6'>
        <SectionContent
          header={t.INSTITUTIONS.ADVANCED_SETTINGS.SPEECH_TO_TEXT_CAPABILITIES.TITLE()}
          description={[t.INSTITUTIONS.ADVANCED_SETTINGS.SPEECH_TO_TEXT_CAPABILITIES.DESCRIPTION()]}
          showCTAButton={false}
          extras={(
            <div className='d-flex align-self-start'>
              <AdvancedSettingsSwitch
                value='vm.InstitutionsManager.institution.speechToTextEnabled'
                onChange={switchSpeechToText}
                pendoTagName={config.pendo.speechToTextCapabilities.switch}
                dataQa={config.pendo.speechToTextCapabilities.switch}
              />
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default AiHub;
