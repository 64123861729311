/* @ngInject */
export default function NvShowAllToggle(
  $rootScope,
) {
  return {
    restrict: 'A',
    scope: {
      minShown: '=',
      currShown: '=',
      maxShown: '=',
      triggerOnShowLess: '&?',
      triggerOnShowMore: '&?',
      disableShowAll: '=?',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
    },
    replace: true,
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'dashboard/templates/nv-show-all-toggle.html',
  };
}
