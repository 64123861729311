import React from 'react';
import { jsx } from '@emotion/react';

import { primary } from 'styles/global_defaults/colors';
import CollapsibleRowMenu, {
  CollapsibleRowMenuButton,
  CollapsibleRowMenuDivider,
} from 'components/collapsible-row-menu';

export type Props = {
  canMoveUp: boolean,
  canMoveDown: boolean,
  onDelete: () => void,
  onMoveUp: () => void,
  onMoveDown: () => void,
  tooltipText?: string,
  rowMenuColor?: string,
};

const ReordererMenu = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <CollapsibleRowMenu
    ref={ref}
    color={primary}
    className='menu-button'
    tooltipText={props.tooltipText}
    rowMenuColor={props.rowMenuColor}
  >
    <CollapsibleRowMenuButton icon='trash' onClick={props.onDelete} />
    <CollapsibleRowMenuDivider />
    <CollapsibleRowMenuButton
      icon='move-up'
      onClick={props.onMoveUp}
      disabled={!props.canMoveUp}
    />
    <CollapsibleRowMenuButton
      icon='move-down'
      onClick={props.onMoveDown}
      disabled={!props.canMoveDown}
    />
  </CollapsibleRowMenu>
));

export default ReordererMenu;
