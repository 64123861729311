import React from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import t from 'react-translate';
import { AngularContext } from 'react-app';
import NvTooltip from 'shared/components/nv-tooltip';
import { Course } from 'redux/schemas/models/course';
import {
  ReactComponent as CohortBadgeIcon,
} from 'styles/icons/cohort-badge.svg';
import {
  ReactComponent as PrimaryBadgeIcon,
} from 'styles/icons/primary-badge.svg';

type Props = {
  course: Course,
  className?: string,
};

const BADGE_SIZE = 22;

const styles = css`
  top: -3px;
  position: absolute;
  left: -${BADGE_SIZE / 2}px;
`;

const Badge = (props: Props) => {
  const { course, className } = props;
  const { isPrimary, isCohort, userCourse } = course;

  const { injectServices } = React.useContext(AngularContext);
  const Icon = isPrimary ? PrimaryBadgeIcon : CohortBadgeIcon;
  const currentEnrollment = useSelector((state) => state.models.enrollments[userCourse]);
  const [CurrentPermissionsManager] = injectServices(['CurrentPermissionsManager']);

  if ((isPrimary || isCohort) && (
    CurrentPermissionsManager.hasOrgAdminPermissions()
    || CurrentPermissionsManager.hasCourseManagerPermissions()
    || (currentEnrollment
      ? currentEnrollment.isCourseAdmin
      // Allowing to show badge in the case where enrollment is not retrieved.
      // Anyways, backend sends null for users who are not supposed to see the
      // badge.
      : true)
  )) {
    return (
      <NvTooltip text={isPrimary ? t.COHORT_MANAGEMENT.PRIMARY() : t.COHORT_MANAGEMENT.COHORT()}>
        <Icon
          css={styles}
          width={BADGE_SIZE}
          height={BADGE_SIZE}
          className={className}
        />
      </NvTooltip>
    );
  }

  return null;
};

export default Badge;
