/* @ngInject */
export default function GroupFormationLectureComponentModel(
  _,

  TeamFormationLectureComponentModel,
  CurrentCourseManager,
  CurrentUserManager,
  config,
) {
  class GroupFormationLectureComponent extends TeamFormationLectureComponentModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      const defaults = {
        type: 'GroupFormationLectureComponent',
        editFormTemplateUrl: 'lecture_pages/templates/components/group-formation-edit-modal.html',
        formName: 'editGroupFormationTypeForm',
        teamSet: {
          type: 'group',
          formedByStudents: null,
          teamSizeLimit: 10,
          teamAlias: CurrentCourseManager.course.groupName.capitalizedPluralized,
          isTodo: false,
        },
        isActivity: true,
        shouldHideTodo: true,
      };
      super(_.extend({}, defaults, attributes), isSample, useDefaults);
    }

    get directiveName() {
      return 'nv-team-formation-lecture-component';
    }

    save(...args) {
      return super.save(...args).then(() => {
        this.__preprocess();

        CurrentCourseManager.course.hasGroups = true;
        CurrentCourseManager.course.groupTeamSet = this.teamSet;
        CurrentCourseManager.course.groupTeamSet.lectureComponentId = this.id;
        CurrentCourseManager.course.groupName = this.teamSet.name;

        return this;
      });
    }

    get deleteWarningKey() {
      return 'LECTURE_PAGES.COMPONENTS.GROUP_FORMATION.DELETION_DESCRIPTION';
    }

    delete() {
      return super.delete().then(() => {
        CurrentCourseManager.course.hasGroups = false;
        CurrentCourseManager.course.groupTeamSet = null;
      });
    }
  }

  GroupFormationLectureComponent.iconClass = 'icon-groups';
  GroupFormationLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.GROUP_FORMATION.DESCRIPTION';
  GroupFormationLectureComponent.unavailabilityDescriptionKey = 'LECTURE_PAGES.COMPONENTS.GROUP_FORMATION.UNAVAILABLE';
  GroupFormationLectureComponent.showModalBeforeCreate = true;
  GroupFormationLectureComponent.pendoTagName = config.pendo.lectureEdit.groupFormation;

  return GroupFormationLectureComponent;
}
