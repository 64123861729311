/* @ngInject */
export default function NvInputColorPicker(
  $parse,
  $timeout,
) {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: true,
    link(scope, element, attrs, ngModelCtrl) {
      scope.colors = $parse(attrs.colors)(scope);
      scope.colorNames = $parse(attrs.colorNames)(scope);
      scope.selectColor = selectColor;

      function selectColor(color) {
        ngModelCtrl.$setViewValue(color);
      }
    },
    templateUrl: 'shared/templates/nv-input-color-picker.html',
  };
}
