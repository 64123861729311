/**
 * Directive that should be used as dropdown item, it guarantees accessiblity
 * and focusability.
 */
/* @ngInject */
export default function NvDropdownItem() {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    // - Template is forced to be an anchor element based on source code:
    // https://github.com/angular-ui/bootstrap/blob/1.3.3/src/dropdown/dropdown.js#L152
    // - Using tabindex as -1 since dropdown items should only be focusable
    // through "up" and "down" keys.
    // - Using "nv-aria-anchor" directive to make anchor navigation work by
    // pressing Enter.
    template: '<a tabindex="-1" nv-aria-anchor ng-transclude></a>',
    link($scope, $elem, $attrs) {
      let anchorHref;

      $elem.bind('click', handleAnchorClick);

      $scope.$on('$destroy', () => {
        $elem.unbind('click', handleAnchorClick);
      });

      $scope.$watch(() => $attrs.href, (newValue) => {
        anchorHref = newValue;
      });

      $scope.$watch(() => $attrs.disabled, (newValue) => {
        if (newValue) {
          $elem.removeAttr('href');
        } else {
          // If element has no href attribute provided we are going to set a
          // default value in order to keep the anchor element focusable
          // (because if no href attribute is specified the anchor is not
          // focusable and therefore not accessible through "up" and "down" keys
          // in the dropdown.
          $elem.attr('href', anchorHref ?? '');
        }
      });

      function handleAnchorClick($event) {
        // Prevent navigation if the href is the default one.
        if (anchorHref === undefined) {
          $event.preventDefault();
        }
      }
    },
  };
}
