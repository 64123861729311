/* @ngInject */
export default function SurveyLectureComponentModel(
  _,
  moment,
  $translate,
  nvUtil,
  CurrentUserManager,
  CurrentCourseManager,
  QuizModel,
  QuizLectureComponentModel,
  config,
) {
  class SurveyLectureComponent extends QuizLectureComponentModel {
    constructor(attributes) {
      const defaults = {
        type: 'SurveyLectureComponent',
        editFormTemplateUrl: null,
        quiz: {
          title: '',
          expirationDate: null,
          isTodo: false,
          hardDeadline: false,
        },
        isActivity: true,
      };
      super(_.extend({}, defaults, attributes));
    }

    __preprocess() {
      this.quiz = new QuizModel(_.extend(this.survey, { catalogId: this.catalogId }));
      this.scheduledCommunicationsCount = this.quiz.scheduledCommunicationsCount;

      // No need to save responses here
      if (this.quiz.submission.responses) {
        this.quiz.setResponses(this.quiz.submission.responses);
        this.shouldShowFeedback = true;
      }
    }

    /* Admin Function */
    getPayload() {
      return {
        survey: _.pick(this.quiz, ['id', 'title', 'expirationDate', 'isTodo', 'hardDeadline']),
      };
    }

    moveToWarningMessages(targetLecturePage) {
      let translateKey;

      if ((this.lecturePage.released && !targetLecturePage.released) && this.quiz.expirationDate && moment(this.quiz.expirationDate) < moment(targetLecturePage.releaseDate)) {
        translateKey = 'LECTURE_PAGES.COMPONENTS.SURVEY.MOVE.UNRELEASE_AND_DEADLINE';
      } else if (this.lecturePage.released && !targetLecturePage.released) {
        translateKey = 'LECTURE_PAGES.COMPONENTS.SURVEY.MOVE.UNRELEASE';
      } else if (this.quiz.expirationDate && moment(this.quiz.expirationDate) < moment(targetLecturePage.releaseDate)) {
        translateKey = 'LECTURE_PAGES.COMPONENTS.SURVEY.MOVE.DEADLINE';
      }

      if (translateKey) {
        return [$translate.instant(translateKey, { lectureAlias: CurrentCourseManager.course.lectureName.downcasedSingularized })];
      }
      return null;
    }

    currentTotalPoints() {
      return nvUtil.getCurrentTotalPoints(this.quiz.totalPoints, this.releaseDate, CurrentCourseManager.course.isDecayEnabled());
    }
  }

  SurveyLectureComponent.iconClass = 'icon-survey';
  SurveyLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.SURVEY.DESCRIPTION';
  SurveyLectureComponent.showModalBeforeCreate = false;
  SurveyLectureComponent.pendoTagName = config.pendo.lectureEdit.survey;

  return SurveyLectureComponent;
}
