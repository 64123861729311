const LOCAL_STORAGE_KEY = 'viewedCoursesModal';

const getViewedCourseKey = (catalogId, userId) => (`${catalogId}-${userId}`);

const getViewedCourses = () => JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

// Identify whether the user has seen the course completion status modal at the end of the course by querying the local storage
export const isCourseModalViewed = (enrollment, catalogId) => {
  const viewedCourseKey = enrollment && getViewedCourseKey(catalogId, enrollment?.userId);
  const viewedCourses = getViewedCourses();

  return Boolean(viewedCourseKey && viewedCourses && viewedCourses[viewedCourseKey]);
};

// Persistence of user status to identify that the user has already seen the course completion status modal by saving the course key in local storage
export const setCourseModalViewed = (enrollment, catalogId) => {
  const viewedCourseKey = enrollment && getViewedCourseKey(catalogId, enrollment?.userId);
  const viewedCourses = getViewedCourses();

  const newViewedCourseValue = {
    ...(viewedCourses || {}),
    [viewedCourseKey]: true,
  };

  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newViewedCourseValue));
};

export default isCourseModalViewed;
