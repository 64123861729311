import React from 'react';
import moment from 'moment';

import { connect, useSelector } from 'react-redux';
import t from 'react-translate';

// schemas
import { RootState } from 'redux/schemas';
import { ActivityType } from 'redux/schemas/models/activity';
import { ComponentKeyPluralized } from 'redux/schemas/models/lecture-component';

// actions
import { setActivityExpandedInAutomatedCommunications } from 'redux/actions/course-communications';

// selectors
import { isActivityExpandedInAutomatedCommunications } from 'redux/selectors/course-communications';

// components
import { NvTooltip } from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import NvStackedTab from 'shared/components/nv-stacked-tab';

import CommunicationCreateNewDropdown from 'communications/course_communications/components/communication-create-new-dropdown';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';
import { getPoll, hasPollGotCommunicationError } from 'redux/selectors/polls';
import { PollNormalized } from 'redux/schemas/models/poll';

type PollLectureComponentProps = {
  pollId: number;
};

type StateProps = {
  poll: PollNormalized;
  hasCommunicationError: boolean;
};

const mapDispatchToProps = {
  setExpanded: setActivityExpandedInAutomatedCommunications,
};

const PollLectureComponent = (props: PollLectureComponentProps & StateProps & typeof mapDispatchToProps) => {
  const isExpanded = useSelector<RootState, boolean>((state) => isActivityExpandedInAutomatedCommunications(
    state,
    ComponentKeyPluralized.POLL,
    props.pollId,
  ));

  return (
    <React.Fragment>
      <div className={`lecture-component-row ${isExpanded && props.poll?.communicationsCount > 0 && 'expanded-box'}`}>
        <NvIcon icon='polls' size='smallest' />
        <div className='description'>{props.poll?.title}</div>

        {props.poll?.hardDeadline && (
          <NvTooltip text={t.TIMELINE.HARD_DEADLINE()} placement='top'>
            <div className='spacing'>
              <NvIcon icon='harddeadline' size='smallest' />
            </div>
          </NvTooltip>
        )}
        <div className='date'>
          {props.poll?.expirationDate
            && t.LECTURE_PAGES.COMPONENTS.DUE_DATE.STUDENT_VIEW(moment(props.poll.expirationDate).format('LLL'))}
        </div>
        <div className=''>
          <CommunicationCreateNewDropdown activityType={ActivityType.POLL} activityId={props.pollId} />
        </div>
        {props.poll?.communicationsCount > 0 && (
          <NvStackedTab
            count={props.poll.communicationsCount}
            hasError={props.hasCommunicationError}
            isExpanded={isExpanded}
            errorMessage={t.COURSE_COMMUNICATIONS.WARNINGS.ACTIVITY_COMMUNICATION()}
            onClick={() => props.setExpanded({
              type: ComponentKeyPluralized.POLL,
              id: props.pollId,
              isExpanded: !isExpanded,
            })}
          />
        )}
      </div>
      {
        // Communications
        isExpanded
          && props.poll?.communications
          && props.poll.communications.map((communicationId) => (
            <CommunicationItem key={communicationId.toString()} communicationId={communicationId} />
          ))
      }
    </React.Fragment>
  );
};

const ConnectedPollLectureComponent = connect(
  (state: RootState, ownProps: PollLectureComponentProps) => ({
    poll: getPoll(state, ownProps),
    hasCommunicationError: hasPollGotCommunicationError(state, ownProps),
  }),
  mapDispatchToProps,
)(PollLectureComponent);

export default ConnectedPollLectureComponent;
