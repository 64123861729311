import { css } from '@emotion/react';
import { kebabCase } from 'lodash';
import Dropdown from 'react-bootstrap/Dropdown';
import NvTooltip, { TextAlign } from 'shared/components/nv-tooltip';
import { getSanitizedStyles } from 'shared/utils';
import { gray1, primary } from 'styles/global_defaults/colors';
import {
  normalFontWeight,
  semiBoldFontWeight,
  textMediumFontSize,
  textSmallFontSize,
  textSmallLineHeight,
} from 'styles/global_defaults/fonts';

export type CategoryItemProps = {
  singleCategory?: boolean;
  show: boolean;
  label: string;
  tooltip?: string;
  link?: string;
  targetBlank?: boolean;
  callback?: (...args: any[]) => void;
};

export const NvCategoryItem = ({
  singleCategory,
  label,
  link,
  targetBlank,
  tooltip,
  callback,
}: CategoryItemProps) => {
  const styles = css`
    width: fit-content;
    padding: 0;
    color: ${gray1};
    font-size: ${singleCategory ? textMediumFontSize : textSmallFontSize}px;
    font-weight: ${singleCategory ? normalFontWeight : semiBoldFontWeight};
    line-height: ${textSmallLineHeight}px;
    white-space: initial;

    &:hover {
      color: ${primary};
      background-color: transparent;
    }
  `;

  const stylesButton = css`
    padding: 0;
    text-align: left;
    border: none;
    background: none;
  `;

  return (
    <NvTooltip
      text={tooltip}
      placement='auto-start'
      textAlign={TextAlign.LEFT}
      enabled={!!tooltip?.length}
      data-qa={kebabCase(`menu-item-tooltip-${label}`)}
    >
      {!link ? (
        <Dropdown.Item
          as='button'
          onClick={callback}
          css={getSanitizedStyles([styles, stylesButton])}
          data-qa={kebabCase(`menu-item-${label}`)}
        >
          {label}
        </Dropdown.Item>
      ) : (
        <Dropdown.Item
          href={link}
          target={targetBlank ? '_blank' : ''}
          css={styles}
          data-qa={kebabCase(`menu-item-${label}`)}
        >
          {label}
        </Dropdown.Item>
      )}
    </NvTooltip>
  );
};
