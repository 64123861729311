import { css } from '@emotion/react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import Button from 'react-bootstrap/Button';

import { AngularServicesContext } from 'react-app';

import { primary, gray6, gray7, warning } from 'styles/global_defaults/colors';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { notHandheld, handheld } from 'styles/global_defaults/media-queries';

import { getCourseAliases } from 'redux/selectors/course';

import { TeamDiscussionContext } from './team-discussion';

const NoTeam = () => {
  const styles = css`
    .no-team {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: ${gray7};
      padding: ${standardSpacing}px;
      border-left: 1px solid ${gray6};
      border-right: 1px solid ${gray6};
      border-bottom: 1px solid ${gray6};
      border-top: 1px solid ${warning};

      ${notHandheld(css`
        margin-left: 90px;
      `)};

      ${handheld(css`
        flex-direction: column;
      `)};

      &-text span a {
        color: ${primary};
      }
    }

    .join-discussion {
      white-space: nowrap;

      ${handheld(css`
        margin-top: ${standardSpacing}px;
      `)};
    }
  `;

  const { postId } = useContext(TeamDiscussionContext);
  const { $state } = useContext(AngularServicesContext);

  const post = useSelector((state) => state.models.posts[postId]);
  const aliases = useSelector((state) => getCourseAliases(state));
  // this is to unblock https://novoed.atlassian.net/browse/NOV-66505 proper redux loading needed
  // const teamSet = useSelector((state: RootState) => state.models.teamSets[post.teamSet.id]);
  const { teamSet } = post;

  const teamFormationLink = $state.href('lecture-page', { id: post.teamSet?.lecturePageId, lectureActivityId: post.teamSet?.lectureComponentId });

  let noTeamText = t.TEAM_FORMATION.NO_TEAM_UNRELEASED({ ...aliases.teamAliases });

  if (post.released && teamSet?.formedByStudents) {
    noTeamText = t.TEAM_FORMATION.NO_TEAM_RELEASED_FORMED_BY_STUDENTS({ ...aliases.assignmentAliases, teamFormationLink, ...aliases.teamAliases });
  } else if (post.released) {
    noTeamText = t.TEAM_FORMATION.NO_TEAM_RELEASED_INSTRUCTOR_FORMED({ ...aliases.assignmentAliases, teamFormationLink, ...aliases.teamAliases });
  }

  return (
    <div className='no-team' css={styles}>
      <div className='no-team-text'>{noTeamText}</div>
      <Button className='join-discussion' disabled variant='primary'>{t.LECTURE_PAGES.COMPONENTS.TEAM_DISCUSSION.JOIN_DISCUSSION()}</Button>
    </div>
  );
};

export default NoTeam;
