/* @ngInject */
export default function PollLectureComponentModel(_, LectureComponentBaseModel, $translate, config) {
  class PollLectureComponent extends LectureComponentBaseModel {
    constructor(attributes) {
      const defaults = {
        canBeCopied: true,
        type: 'PollLectureComponent',
        poll: {
          title: $translate.instant('LECTURE_PAGES.COMPONENTS.POLLS.DEFAULT_TITLE'),
        },
      };

      super({ ...defaults, ...attributes });
    }

    getPayload() {
      return _.pick(this, ['index', 'poll']);
    }

    toggleToDo() {
      this.poll.isTodo = !this.poll.isTodo;
      this.save();
    }

    get deadline() {
      return this.poll.expirationDate;
    }
  }

  PollLectureComponent.iconClass = 'icon-polls';
  PollLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.POLLS.DESCRIPTION';
  PollLectureComponent.type = 'PollLectureComponent';
  PollLectureComponent.pendoTagName = config.pendo.lectureEdit.polls;

  return PollLectureComponent;
}
