/* @ngInject */
export default function NvStatementAndCertificateCard(
  RailsRoutes,
  InteroperabilityRoutes,
  CurrentUserManager,
  $state,
  config,
) {
  return {
    scope: {
      certificate: '=',
      link: '=',
      user: '<',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
      vm.railsRoutes = RailsRoutes;
      vm.InteroperabilityRoutes = InteroperabilityRoutes;
      vm.CurrentUserManager = CurrentUserManager;
      vm.config = config;

      vm.getStatementUrl = (catalogId, userId) => $state.href(
        vm.certificate.isJourney ? 'journey-statement-modal' : 'statement-modal',
        { catalogId, userId },
      );
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'dashboard/templates/nv-statement-and-certificate-card.html',
  };
}
