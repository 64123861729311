(function (factory) {
  factory(window.jQuery);
}(($) => {
  const isFirefox = /firefox/i.test(window.navigator.userAgent);

  const correctEditorSelectionElement = (selectionElement) => {
    // Froala editor in firefox gives an incorrect selection element so here
    // correcting it.
    if (isFirefox && selectionElement.tagName === 'BR') {
      return document.getSelection().anchorNode.parentElement;
    }

    return selectionElement;
  };

  $.FE.PLUGINS.typedLists = function (editor) {
    const NORMAL_SPACE = ' ';
    const NON_BREAKING_SPACE = ' ';
    const bulletListPattern = ['-', '*'];
    const numberedListPattern = ['1.'];

    const patterns = {
      bullet: bulletListPattern,
      number: numberedListPattern,
    };

    let lastPressedKey = null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    function _init() {
      if (editor.opts.multiLine) {
        editor.events.on('keydown', (e) => {
          const { key } = e;
          lastPressedKey = key;
        });

        editor.events.on('input', () => {
          const selection = editor.selection.text();
          const selectionElement = correctEditorSelectionElement(editor.selection.element());
          let childNodesLength = selectionElement.childNodes.length;

          // Froala editor in firefox includes a BR element at the end of
          // every paragraph so here compensating the length.
          if (isFirefox) {
            childNodesLength = Array.from(selectionElement.childNodes).filter((node) => (node.tagName ? node.tagName !== 'BR' : true)).length;
          }

          if (selectionElement.tagName !== 'LI'
            && childNodesLength === 1
            && !selection
          ) {
            const innerValue = selectionElement.firstChild.nodeValue;
            const pattern = Object.entries(patterns).find(
              ([objKey, value]) => {
                const posibilities = [];

                value.forEach((each) => {
                  // We check for normal space for firefox case, since for other
                  // browsers we get a non breaking space (I guess that's why in
                  // firefox we see a BR element added at the end of each
                  // paragraph)
                  posibilities.push(`${each}${NORMAL_SPACE}`);
                  posibilities.push(`${each}${NON_BREAKING_SPACE}`);
                });

                return posibilities.some((posibility) => posibility === innerValue);
              },
            );

            if (lastPressedKey === ' ' && pattern) {
              editor.lists.format(pattern[0] === 'number' ? 'OL' : 'UL');
              const element = correctEditorSelectionElement(editor.selection.element());
              element.firstChild.nodeValue = '';

              // A BR element is needed as list child so that cursor positions
              // correctly.
              if (
                // Firefox already has a BR so skipping that case.
                !isFirefox
                && !$(element).find('br').length) {
                element.appendChild(document.createElement('br'));
              }
            }
          }
        });
      }
    }

    return {
      _init,
    };
  };

  $.FE.DefineIcon('bulletList', { NAME: 'format-bulletlist' });
  $.FE.DefineIcon('numberList', { NAME: 'format-numberedlist' });

  function getContainer(element, tagName, before, def) {
    while (element.tagName !== tagName && element !== def) {
      element = element.parentElement;

      if (before !== null && element.tagName === before) {
        return undefined;
      }
    }

    if (element !== def) {
      return element;
    }

    return undefined;
  }

  $.FE.RegisterCommand('bulletList', {
    title: 'Bulleted List',
    plugin: 'typedLists',
    refresh(e) {
      let bulletListActive = false;

      const element = this.selection.element();

      if (element) {
        const li = getContainer(element, 'LI', null, this.$el.get(0));

        if (li) {
          const ul = getContainer(li, 'UL', 'OL', this.$el.get(0));
          if (ul) {
            bulletListActive = true;
          }
        }
      }

      e.toggleClass('fr-active', bulletListActive).attr('aria-pressed', bulletListActive);
    },
    callback() {
      this.lists.format('UL');
    },
  });

  $.FE.RegisterCommand('numberList', {
    title: 'Numbered List',
    plugin: 'typedLists',
    refresh(e) {
      let numberListActive = false;

      const element = this.selection.element();

      if (element) {
        const li = getContainer(element, 'LI', null, this.$el.get(0));

        if (li) {
          const ol = getContainer(li, 'OL', 'UL', this.$el.get(0));
          if (ol) {
            numberListActive = true;
          }
        }
      }

      e.toggleClass('fr-active', numberListActive).attr('aria-pressed', numberListActive);
    },
    callback() {
      this.lists.format('OL');
    },
  });
}));
