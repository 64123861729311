/* @ngInject */
export default function A(
  $location,
  AnchorModifier,
) {
  return {
    restrict: 'E',
    link(scope, elem, attrs) {
      const findDomain = function (hostname) {
        let domain = hostname;

        if (hostname) {
          const parts = hostname.split('.');
          if (parts.length > 2) {
            domain = parts.slice(1, parts.length).join('.');
          }
        }
        return domain;
      };

      const [a] = elem;
      const aDomain = findDomain(a.hostname);
      const locationDomain = findDomain($location.host());

      /* Bump External Links to new tab */
      // href: '#' will become the current url with '#' appended
      // ui-sref: href will become propery url with current host
      if (aDomain && locationDomain && aDomain !== locationDomain) {
        a.target = '_blank';

        if (elem.attr('data-ga-outbound-tracking')) {
          elem.click(() => {
            const url = elem.attr('href');
            if (window.ga) {
              window.ga.getAll().forEach((tracker) => {
                window.ga(`${tracker.get('name')}.send`, 'event', 'outbound', 'click', url);
              });
            }
          });
        }
      }

      /* Update user anchor scroll to use angular anchor scroll */
      AnchorModifier.fixShortcutAnchor(elem);
    },
  };
}
