/* @ngInject */
export default function RequestPasswordResetCtrl(
  PasswordsResource,
  UsersResources,
  validationConstants,
  CurrentCourseManager,
  CurrentUserManager,
  UserAuthentication,
  InstitutionsManager,
  config,
  $state,
  $window,
  $timeout,
  vcRecaptchaService,
) {
  const vm = this;
  let widgetId;

  vm.CurrentUserManager = CurrentUserManager;
  vm.email = '';
  vm.validationConstants = validationConstants;
  vm.params = $state.params;
  vm.course = CurrentCourseManager.course;
  vm.requestPasswordReset = requestPasswordReset;
  vm.config = config;
  vm.loadingProviders = false;
  vm.isPasswordUser = false;
  vm.isSSOEnabled = InstitutionsManager.institution?.ssoLogin;

  vm.onWidgetCreate = (_widgetId) => {
    vm.widgetId = _widgetId;
  };

  vm.shouldShowRequest = () => (
    (vm.isPasswordUser || !vm.isSSOEnabled) && !vm.loadingProviders
  );

  vm.shouldShowCheckBtn = () => (
    vm.isSSOEnabled && !vm.isPasswordUser
  );

  vm.onCheckEmail = (e) => {
    e.preventDefault();
    const params = { email: vm.email };
    const success = (resource) => {
      const { provider, withPassword } = resource.result;
      if (provider && !withPassword) {
        $window.location = UserAuthentication.getSSOSignInUrl(provider);
      } else {
        $timeout(() => {
          vm.loadingProviders = false;
          vm.isPasswordUser = true;
        }, 1000);
      }
    };
    const fail = () => { vm.loadingProviders = false; };
    vm.loadingProviders = true;
    UsersResources.getLoginProviders(params, success, fail);
  };

  function requestPasswordReset() {
    vm.error = false;
    vm.emailNotFound = false;
    vm.recaptchaError = false;
    vm.requestingPasswordReset = true;

    const catalogId = CurrentCourseManager.course ? CurrentCourseManager.course.catalogId : undefined;
    PasswordsResource.save(
      {
        user: { email: vm.email },
        catalogId,
        recaptchaResponse: vm.recaptchaResponse,
      },
      (resource) => {
        vm.requestingPasswordReset = false;
        $state.go('users.authentications.confirm-request-password-reset-email', vm.params);
      },
      (resource) => {
        vm.requestingPasswordReset = false;
        vm.error = true;
        vcRecaptchaService.reload(widgetId);

        if (resource.data.error.code === 'login.errors.recaptcha') {
          vm.recaptchaError = true;
        } else if (resource.status === 404) {
          vm.emailNotFound = true;
        }
      },
    );
  }
}
