/* @ngInject */
export default function DiscussionsIndexController(
  CurrentUserManager,
  DiscussionsManager,
  CurrentCourseManager,
  CurrentPermissionsManager,
  MentionablesModel,
  _,
  $stateParams,
  $state,
  $timeout,
  $q,
  $filter,
  $scope,
  nvCurrentPage,
) {
  const vm = this;
  let getAllTopicsPromise = null;
  const loadPostsDeferred = $q.defer();

  vm.currentUserManager = CurrentUserManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.manager = DiscussionsManager;
  vm.$filter = $filter;
  vm.canAddNewTopic = CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isCourseBuilder();
  vm.pusherCourseId = CurrentCourseManager.course.id;

  vm.postsForUIScroll = {
    get(index, count, success) {
      const lastIndex = index + count - 1;

      if (!vm.manager.currentPosts || vm.manager.currentPosts.length === 0) {
        return loadPostsDeferred.promise.then(() => {
          success(vm.manager.getUIScrollItems(index, count));
        });
      } if (lastIndex > vm.manager.lastScrollIndex && vm.manager.hasMorePostsToLoad) {
        return vm.manager.loadMorePosts().then(() => {
          success(vm.manager.getUIScrollItems(index, count));
        });
      }
      success(vm.manager.getUIScrollItems(index, count));

      return null;
    },
  };

  if (!DiscussionsManager.catalogId
          || $stateParams.catalogId !== DiscussionsManager.catalogId
          || !CurrentCourseManager.course.teachingTeamMembers
          || !DiscussionsManager.allTopics.length) {
    DiscussionsManager.initialize({ catalogId: $stateParams.catalogId });
    getAllTopicsPromise = DiscussionsManager.getAllTopics();
  } else {
    getAllTopicsPromise = $q.when(DiscussionsManager.allTopics);
  }

  DiscussionsManager.context = 'index';
  DiscussionsManager.loading = true;

  getAllTopicsPromise.then(() => {
    const selectedTopic = DiscussionsManager.setCurrentTopicId($stateParams.topicId);

    DiscussionsManager.setSortOrder($stateParams.sortOrder);
    DiscussionsManager.setFilterOption($stateParams.filterOption);
    DiscussionsManager.loadPosts().then(() => {
      loadPostsDeferred.resolve();

      // if in a topic filter, mark as read here so that a page refresh will not show the "new" badges
      if ((selectedTopic.id > 0 && !CurrentUserManager.isAdmin() && selectedTopic.getNumNewReleasedPosts() > 0)
              || (selectedTopic.id > 0 && CurrentUserManager.isAdmin() && selectedTopic.getNumNewPosts() > 0)) {
        // mark those posts as read on the backend, but don't update the frontend yet
        selectedTopic.markAsRead();
      }

      CurrentCourseManager.course.getTeachingTeamMembers();
      DiscussionsManager.initializeMentions();
    });

    DiscussionsManager.initializePusherEvents(vm.pusherCourseId);
  });

  $scope.$on('$destroy', () => {
    DiscussionsManager.unsubscribeFromPusherEvents(vm.pusherCourseId);
    DiscussionsManager.resetSearchParams();
  });

  DiscussionsManager.resetData();
}
