import { css } from '@emotion/react';
import { findWhere, isEmpty } from 'underscore';
import React, { useState, useEffect, useContext, useRef, useCallback, forwardRef, MutableRefObject, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { FroalaViewMode, UploadType, ToolbarOptions } from 'froala/helpers/nv-froala-constants';
import { NvFroala } from 'froala/components/nv-froala';
import useClickOutside from 'shared/hooks/use-click-outside';
import { Bookmark, BookmarkType, CourseObjectBookmark, DiscussionBookmarkComponent } from 'redux/schemas/models/bookmark';
import { deleteBookmark, focusBookmark, updateBookmark } from 'redux/actions/bookmarks';
import NvIcon from 'shared/components/nv-icon';
import { gray6, gray7, hexToRgbaString, gray1, primary } from 'styles/global_defaults/colors';
import { largeSpacing, halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { headerLineHeight } from 'styles/global_defaults/fonts';
import ClickableContainer from 'components/clickable-container';
import { useAppDispatch } from 'redux/store';
import { RootState } from 'redux/schemas';
import { handheld } from 'styles/global_defaults/media-queries';
import { AngularServicesContext } from 'react-app';
import { SanitizationLevel } from 'froala/helpers/sanitizer';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import DeleteBookmark from './delete-bookmark';
import BookmarkSnippet from './bookmark-snippet';
import NoteTag from './note-tag';
import { config } from '../../../config/pendo.config.json';

const PARAGRAPH_PADDING = 10;
const OVERFLOW_HEIGHT = (headerLineHeight + PARAGRAPH_PADDING) * 3;

type SingleBookmarkProps = {
  bookmark?: Bookmark;
  index?: number;
};

const SingleBookmark = forwardRef<any, SingleBookmarkProps>(({
  bookmark,
  index,
}, ref) => {
  const dispatch = useAppDispatch();
  const deleteDialogRef = React.useRef<HTMLDivElement>();

  const { $scope } = useContext(AngularServicesContext);

  const froalaRef = useRef<any>(null);
  const bookmarkRef = useRef<any>(null);
  const bookmarkContentRef = useRef<any>(null);

  const isFocused = useSelector((state: RootState) => state.app.bookmarks.focusNew === bookmark.id);

  const [isEditing, setIsEditing] = useState(isFocused);
  const [froalaContent, setFroalaContent] = useState(bookmark.note);
  const [toDelete, setToDelete] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const angularServices = React.useContext(AngularServicesContext);

  const isHighlighted = isFocused && bookmark.type !== BookmarkType.INSTITUTION && bookmark.type !== BookmarkType.COURSE;
  const isNote = bookmark.type === BookmarkType.INSTITUTION || bookmark.type === BookmarkType.COURSE;
  const showToggle = isOverflow && froalaContent && !isEditing;

  useImperativeHandle(ref, () => ({
    unsavedChanges: () => unsavedChanges,
  }));

  useEffect(() => {
    if (bookmark?.note) {
      setFroalaContent(bookmark.note);
    }
  }, [bookmark]);

  useEffect(() => {
    if (isFocused) {
      froalaRef.current?.focus();
    }
  }, [isFocused]);

  useEffect(() => {
    if (bookmarkContentRef.current && !isEditing) {
      const { height } = bookmarkContentRef.current.getBoundingClientRect();
      if ((height - PARAGRAPH_PADDING) > OVERFLOW_HEIGHT) {
        setIsOverflow(true);
      } else {
        setIsOverflow(false);
      }
    }
  }, [isEditing]);

  const checkUnsavedChanges = (e?) => {
    const content = e !== undefined ? e : froalaContent;

    if (isEmpty(bookmark.note) && isEmpty(content)) {
      return false;
    }
    return bookmark.note !== content;
  };

  useClickOutside(bookmarkContentRef, () => {
    if (isEditing && !unsavedChanges) {
      setIsEditing(false);
      onBlur();
    }
  });

  const onChange = (e) => {
    setFroalaContent(e);
    const changes = checkUnsavedChanges(e);
    setUnsavedChanges(changes);
  };

  const onBlur = () => {
    dispatch(focusBookmark(null));
  };

  const discardChanges = () => {
    setUnsavedChanges(false);
    setFroalaContent(bookmark.note);
    setIsEditing(false);
  };

  const onSave = () => {
    setIsEditing(false);
    setUnsavedChanges(false);

    dispatch(updateBookmark({
      ...bookmark,
      note: froalaContent,
    })).then(() => {
      bookmarkRef.current?.scrollIntoView({ behavior: 'smooth' });
    });
  };

  const onClickNote = () => {
    setIsEditing(true);
    if (isOverflow && !showMore) {
      setShowMore(true);
    }
    setTimeout(() => {
      froalaRef.current?.focus();
    });
  };

  const onConfirmDelete = () => {
    dispatch(deleteBookmark({
      id: bookmark.id,
    })).then(afterDelete);
  };

  const onDelete = () => {
    setToDelete(true);
  };

  useEffect(() => {
    if (toDelete) {
      deleteDialogRef.current.focus();
    }
  }, [toDelete]);

  const toggleShowMore = () => {
    setShowMore(more => !more);
  };

  const afterDelete = () => {
    const { component, type } = bookmark as CourseObjectBookmark;
    if (component) {
      if (type === BookmarkType.SUBMISSION) {
        if ($scope.ReportsManager.currentReport.id === component.id) {
          $scope.ReportsManager.currentReport.bookmarkId = null;
        }
      } else {
        const comp = component as DiscussionBookmarkComponent;
        const { owner, submission } = comp;
        const isSubmission = !!submission || owner?.type === 'report';

        if (!isSubmission) {
          switch (bookmark.type) {
            case BookmarkType.TOPIC: {
              const post = findWhere($scope.DiscussionsManager?.currentPosts, { id: comp.id });
              if (post) {
                post.bookmarkId = null;
              }
              break;
            }
            case BookmarkType.POST: {
              const post = findWhere($scope.DiscussionsManager?.currentPosts, { id: comp.topic.id });
              if (post) {
                const comment = findWhere(post.comments, { id: comp.id });
                if (comment) {
                  comment.bookmarkId = null;
                }
              }
              break;
            }
            case BookmarkType.COMMENT: {
              const post = findWhere($scope.DiscussionsManager?.currentPosts, { id: comp.topic.id });
              if (post) {
                const comment = findWhere(post.comments, { id: comp.commentableId });
                if (comment) {
                  const reply = findWhere(comment.replies, { id: comp.id });
                  if (reply) {
                    reply.bookmarkId = null;
                  }
                }
              }
              break;
            }
            default:
              break;
          }
        }
      }
    }
  };

  const styles = css`
    border-bottom: 2px solid ${gray6};
    display: block;
    position: relative;
    box-sizing: border-box;

    &.highlighted {
      background-color: ${gray7};
    }

    .overflow-mask {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      color: ${primary};
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      padding-right: ${largeSpacing}px;

      .gradient {
        height: 65px;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        ${(isOverflow && !showMore) && css`
          background: linear-gradient(180deg, transparent 0%, white 40%);
        `}
      }

      .show-more {
        position: absolute;
        height: ${largeSpacing}px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        margin-bottom: 20px;

        ${isHover && css`
          opacity: 20%;
        `};
      }
    }

    .delete-mask, .unsaved-changes-mask {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${hexToRgbaString(gray1, 0.95)};
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .delete-bookmark, .unsaved-changes-header {
        color: white !important; // override bootstrap text color styling
        margin-bottom: ${halfSpacing}px;
        text-align: center;
      }

      .button-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .cancel, .discard {
          margin-right: ${halfSpacing}px;
        }
      }
    }

    .add-your-thoughts {
      display: flex;
      margin-top: ${largeSpacing}px;
      margin-left: 80px;
      color: ${gray1};

      .icon-create-new-post {
        color: ${gray6};
      }

      &:hover {
        color: ${primary};

        .icon-create-new-post {
          color: ${primary};
        }
      }

      ${handheld(css`
        margin-left: 40px;
      `)};
    }

    .nv-froala-origami {
      .fr-element {
        min-height: 75px !important;
      }
    }

    .bookmark-container {
      min-height: 130px;

      .bookmark-wrapper {
        flex: 1;
        padding-top: ${standardSpacing}px;
        padding-bottom: ${(isOverflow && showMore && !isEditing) ? 50 : largeSpacing}px;

        .note-tag {
          margin-left: 75px;
          margin-bottom: ${standardSpacing}px;
        }

        .bookmark-content {
          flex: 1;
          line-height: ${headerLineHeight}px;
          margin-left: 80px;
          margin-right: 80px;
           ${(isOverflow && !showMore && !isEditing) ? css`
            max-height: 110px;
            overflow: hidden;
          ` : css`
            max-height: 100%;
          `};
          position: relative;

          ${handheld(css`
            margin-left: 40px;
          `)};


          .bookmark-froala {
            padding-bottom: ${halfSpacing}px;
          }

          .bookmark-actions {
            display: flex;
            justify-content: flex-end;

            .cancel {
              margin-right: ${halfSpacing}px;
            }
          }
        }
      }
    }
  `;

  let bookmarkContent;

  if (isEditing) {
    bookmarkContent = (
      <div className='bookmark-container'>
        <div className='bookmark-wrapper'>
          <BookmarkSnippet bookmark={bookmark as CourseObjectBookmark} />
          {isNote && <NoteTag />}
          <div className='bookmark-content editing'>
            <NvFroala
              className='bookmark-froala'
              preset={FroalaViewMode.INLINE}
              sanitizationLevel={SanitizationLevel.BASIC}
              toolbarButtons={[
                ToolbarOptions.COLOR,
                ToolbarOptions.BOLD,
                ToolbarOptions.ITALIC,
                ToolbarOptions.UNDERLINE,
                ToolbarOptions.FORMAT_UL,
                ToolbarOptions.FORMAT_OL,
                ToolbarOptions.INSERT_LINK,
              ]}
              uploadType={UploadType.NONE}
              placeholder={t.FROALA.TEXT_PLACEHOLDER()}
              value={froalaContent}
              onChange={onChange}
              ref={froalaRef}
            />
            <div className='bookmark-actions'>
              <Button
                variant='secondary'
                size='sm'
                className='cancel'
                disabled={!froalaContent}
                onClick={discardChanges}
                aria-label={t.FORM.CANCEL()}
              >
                {t.FORM.CANCEL()}
              </Button>
              <Button
                onClick={onSave}
                variant='primary'
                disabled={!unsavedChanges || !froalaContent}
                size='sm'
                aria-label={t.FORM.SAVE()}
              >
                {t.FORM.SAVE()}
              </Button>
            </div>
          </div>
        </div>
        <DeleteBookmark
          onDelete={onDelete}
          isHighlighted={isHighlighted}
          isHover={isHover}
          setIsHover={setIsHover}
          index={index}
        />
      </div>
    );
  } else if (froalaContent) {
    bookmarkContent = (
      <div className='bookmark-container'>
        <div className='bookmark-wrapper'>
          <BookmarkSnippet bookmark={bookmark as CourseObjectBookmark} />
          {isNote && <NoteTag />}
          <ClickableContainer className='bookmark-content rich-text' onClick={onClickNote} data-qa={config.pendo.bookmarks.noteText}>
            {/* eslint-disable-next-line react/no-danger */}
            <ResponsivelyEmbeddedAngularHTML
              template={froalaContent}
              ref={bookmarkContentRef}
              angularServices={angularServices}
              onCompile={() => {
                $(bookmarkContentRef.current).find('a').each(function () {
                  const anchor = $(this);

                  anchor.on('click', (e) => e.stopPropagation());
                });
              }}
            />
          </ClickableContainer>
        </div>
        <DeleteBookmark
          onDelete={onDelete}
          isHighlighted={isHighlighted}
          isHover={isHover}
          setIsHover={setIsHover}
          index={index}
        />
      </div>
    );
  } else {
    bookmarkContent = (
      <div className='bookmark-container'>
        <div className='bookmark-wrapper'>
          <BookmarkSnippet bookmark={bookmark as CourseObjectBookmark} />
          {isNote && <NoteTag />}
          <ClickableContainer className='add-your-thoughts' onClick={onClickNote}>
            <NvIcon icon='create-new-post' size='smallest' className='text-gray-4' />
            <div className='ml-2 text-regular'>{t.LHS.BOOKMARKS.ADD_YOUR_THOUGHTS()}</div>
          </ClickableContainer>
        </div>
        <DeleteBookmark
          onDelete={onDelete}
          isHighlighted={isHighlighted}
          isHover={isHover}
          setIsHover={setIsHover}
          index={index}
        />
      </div>
    );
  }

  return (
    <div css={styles} id={`bookmark-${bookmark.id}`} className={isHighlighted ? 'highlighted' : ''} ref={bookmarkRef}>
      {toDelete && (
        <div
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          ref={deleteDialogRef}
          role='dialog'
          className='delete-mask'
          aria-labelledby='delete-bookmark'
        >
          <div className='delete-bookmark text-body' id='delete-bookmark'>
            {isNote ? t.LHS.BOOKMARKS.DELETE_NOTE() : t.LHS.BOOKMARKS.DELETE_BOOKMARK()}
          </div>
          <div className='button-container'>
            <Button
              variant='secondary'
              className='bs4-night cancel'
              onClick={() => setToDelete(false)}
            >
              {t.FORM.CANCEL()}
            </Button>
            <Button
              variant='danger'
              className='bs4-night'
              onClick={onConfirmDelete}
            >
              {t.FORM.DELETE()}
            </Button>
          </div>
        </div>
      )}
      {bookmarkContent}
      {showToggle && (
        <div className='overflow-mask'>
          <div onClick={onClickNote} className='gradient' />
          <ClickableContainer
            onClick={toggleShowMore}
            className='text-regular text-primary show-more mb-2'
          >
            {showMore ? t.LHS.BOOKMARKS.SHOW_LESS() : t.LHS.BOOKMARKS.SHOW_MORE()}
            <NvIcon icon={showMore ? 'arrow-up' : 'arrow-down'} size='smallest' className='ml-2' />
          </ClickableContainer>
        </div>
      )}
    </div>
  );
});

export default SingleBookmark;
