/* eslint-disable react/require-default-props */
import { css } from '@emotion/react';
import { OverlayTrigger, OverlayTriggerProps, Tooltip, TooltipProps } from 'react-bootstrap';
import React, { forwardRef, useEffect, useContext, useMemo } from 'react';
import { Placement } from 'react-bootstrap/Overlay';
import { isTouchDevice } from 'styles/global_defaults/media-queries';
import { PopoversContainerContext } from 'shared/react-utils';
import nvUtil from '../services/nv-util';

const tooltipMargin = 4;
const tooltipBorderRadius = 2;

export enum TextAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify',
  INITIAL = 'initial',
  INHERIT = 'inherit',
}

type NvTooltipProps = {
  children: any
  text: string,
  /** Whether popper.js should attempt to prevent overflowing the popover outside its container. Defaults to true */
  preventOverflow?: boolean
  enabled?: boolean,
  placement?: Placement,
  offset?: number,
  textAlign?: TextAlign,

  // forward these props to OverlayTrigger
  onToggle?: OverlayTriggerProps['onToggle'];
  show?: OverlayTriggerProps['show'];

  zIndex?: number;
  maxWidth?: number;
  className?: string;
};

/**
 * Switches the placement accordingingly if the current direction is rtl
 */
export const getActualPlacement = (placement: Placement): Placement => {
  let [primaryPlacement, secondaryPlacement] = placement?.split('-') || [];

  if (primaryPlacement) {
    if (nvUtil.isRtl()) {
      if (primaryPlacement === 'left') {
        primaryPlacement = 'right';
      } else if (primaryPlacement === 'right') {
        primaryPlacement = 'left';
      } else if (primaryPlacement === 'top' || primaryPlacement === 'bottom') {
        if (secondaryPlacement) {
          if (secondaryPlacement === 'start') {
            secondaryPlacement = 'end';
          } else if (secondaryPlacement === 'end') {
            secondaryPlacement = 'start';
          }
        }
      }
    }

    return [primaryPlacement, secondaryPlacement].filter(Boolean).join('-') as Placement;
  }

  return undefined;
};

/**
 * NovoEd-customized wrapper around a react-bootstrap Dropdown component.
 */
export const NvTooltip = (props: NvTooltipProps) => {
  const {
    zIndex,
    placement,
    enabled = true,
    preventOverflow = true,
    textAlign = TextAlign.CENTER,
    maxWidth,
    ...rest
  } = props;

  const contextContainer = useContext(PopoversContainerContext);

  const styles = css`
    ${zIndex !== undefined && css`
        z-index: ${zIndex};
    `};

    .bs4-tooltip-inner {
      text-align: ${textAlign};

      ${maxWidth && css`
        max-width: ${maxWidth}px;
      `};
    }
  `;

  const actualPlacement = useMemo(() => getActualPlacement(placement), [placement]);

  return (
    <React.Fragment>
      {enabled ? (
        <OverlayTrigger
          show={props.show}
          onToggle={props.onToggle}
          /* Only display on hover for non-touch devices. Failing to do this can cause tooltips to "stick" being displayed after a touch event on mobile devices */
          trigger={isTouchDevice() ? 'focus' : ['hover', 'focus']}
          placement={actualPlacement || 'top'}
          container={contextContainer}
          popperConfig={{
            modifiers: [
              {
                name: 'hide',
                enabled: preventOverflow,
              }, {
                name: 'preventOverflow',
                enabled: preventOverflow,
              },
              {
                name: 'offset',
                options: {
                  offset: () => [0, (props.offset ?? 0) + tooltipMargin],
                },
              },
              {
                name: 'arrow',
                options: {
                  padding: tooltipBorderRadius,
                },
              },
            ],
          }}
          overlay={<TooltipOverlay id='tooltip' css={styles} {...rest}>{props.text}</TooltipOverlay>}
        >
          {props.children}
        </OverlayTrigger>
      ) : props.children}
    </React.Fragment>
  );
};

const TooltipOverlay = forwardRef<TooltipProps, any>(
  ({ popper, children, ...props }, ref) => {
    // https://react-bootstrap.github.io/components/overlays/#overlays-dynamic-updates
    // Call the overlay's update function to dynamically update the tooltip's position when its text changes
    useEffect(() => {
      popper.scheduleUpdate();
    }, [children, popper]);
    return (
      <Tooltip ref={ref} {...props}>
        {children}
      </Tooltip>
    );
  },
);

export default NvTooltip;
