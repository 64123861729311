import { jsx, css } from '@emotion/react';
import { ProgressBar } from 'react-bootstrap';
import { FileUploading } from 'shared/hooks/use-upload-file';
import t from 'react-translate';
import { gray2, gray4, primary } from 'styles/global_defaults/colors';
import { quarterSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { semiBoldFontWeight, textSmallFontSize } from 'styles/global_defaults/fonts';

export type NvFileUploadProgressProps = {
  hasUploadPercentage: boolean;
  uploadInProgress: boolean;
  fileUploading: FileUploading;
  abortUpload?: (e: any) => void;
  showFileName?: boolean;
  className?: string;
};

const NvFileUploadProgress = ({
  hasUploadPercentage,
  uploadInProgress,
  fileUploading,
  abortUpload,
  showFileName = true,
  className,
}: NvFileUploadProgressProps) => {
  const styles = css`
    border: 1px dashed ${gray4};
    box-sizing: content-box;
    padding: ${threeQuartersSpacing}px;

    .file-name {
      color: ${gray2};
      font-weight: ${semiBoldFontWeight};
      margin-top: ${quarterSpacing}px;
      font-size: ${textSmallFontSize}px;
    }

    .cancel {
      color: ${primary};
      cursor: pointer;
      font-size: ${textSmallFontSize}px;
    }
  `;

  const now = hasUploadPercentage ? (fileUploading?.uploadPercentage ?? fileUploading?.progress) : 100;

  return (
    <div css={styles} className={className}>
      <ProgressBar animated={!hasUploadPercentage} now={now} />
      {uploadInProgress ? (
        <div className='upload-text text-center'>
          <div className='file-name'>{(showFileName && fileUploading?.file?.name) ? fileUploading.file.name : t.FILE_UPLOAD.UPLOADING()}</div>
          {' '}
          {abortUpload && <div className='cancel' onClick={abortUpload}>{t.FORM.CANCEL()}</div>}
        </div>
      ) : (
        <div className='upload-text text-center'>
          <div className='file-name'>{t.FORM.SAVING()}</div>
        </div>
      )}
    </div>

  );
};

export default NvFileUploadProgress;
