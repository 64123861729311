import { RootState } from 'redux/schemas';
import { RolesService } from 'institutions/services/roles-service';
import { Role, RoleResourceType } from 'redux/schemas/models/role';

export const getCourseRoles = (state: RootState): Role[] => Object.values(state.models.roles)
  .filter(role => role.resourceType !== RoleResourceType.COLLECTION);

export const getCollectionRoles = (state: RootState): Role[] => Object.values(state.models.roles)
  .filter(role => role.resourceType === RoleResourceType.COLLECTION);

export const getLearnerRoles = (state: RootState): Role[] => getCourseRoles(state).filter(role => RolesService.isLearner(role));
