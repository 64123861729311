import { css } from '@emotion/react';
import { PagedDataQueryParams } from 'redux/create-action-creators';
import t from 'react-translate';
import _ from 'underscore';
import { standardSpacing, largeSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import React, { CSSProperties } from 'react';
import { Button } from 'react-bootstrap';

export type NvNoResultsProps = {
  fetchParams?: PagedDataQueryParams
  action: () => void,
  clearText?: string,
  noResultsText?: string,
  noResultsTextComponent?: JSX.Element,
  noResultsIcon?: string,
  noResultsIconSize?: string,
  noResultsClearTextDataQA?: string,
  hideClearSearch?: boolean,
  style?: CSSProperties,
};

const styles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${standardSpacing * 2}px;

  .icon:not(.icon-smallest) {
    font-size: 100px;
    margin-bottom: ${largeSpacing}px;
  }

  .clear-text {
    cursor: pointer;
    margin-top: ${quarterSpacing}px;
  }
`;

/**
 * NovoEd-customized wrapper around a react-bootstrap Dropdown component.
 */
export const NvNoResults = (props: NvNoResultsProps) => {
  let clearText = '';

  // Set the text to either 'Clear Search', 'Clear Filters', or 'Clear Filter', as appropriate
  if (props.fetchParams?.searchQuery?.length) {
    clearText = t.SEARCH.CLEAR();
  } else if (_.keys(props.fetchParams?.filters).length > 1) {
    clearText = t.SEARCH.CLEAR_FILTERS();
  } else if (_.keys(props.fetchParams?.filters).length === 1) {
    clearText = t.SEARCH.CLEAR_FILTER();
  }
  const noResultComponent = (
    <React.Fragment>
      {props.noResultsTextComponent ?? (
        <span className='no-results-text text-gray-3 font-weight-bold'>
          {props.noResultsText ?? t.SEARCH.NO_RESULTS_FOUND()}
        </span>
      )}
    </React.Fragment>
  );

  return (
    <div css={styles} style={props.style} className='no-results-panel'>
      <div className={`icon icon-${props.noResultsIconSize ?? 'medium'} icon-${props.noResultsIcon ?? 'course'} text-gray-5`} />
      {noResultComponent}
      {!props.hideClearSearch && (
        <Button
          variant='link'
          onClick={() => props.action()}
          className='clear-text'
          data-qa={props.noResultsClearTextDataQA}
        >
          {props.clearText ?? clearText}
        </Button>
      )}
    </div>
  );
};

export default NvNoResults;
