/* @ngInject */
export default function NvCourseTitleLink(
  nvCurrentPage,
  TimelinesManager,
  LecturePageManager,
) {
  return {
    restrict: 'A',
    templateUrl: 'shared/templates/nv-course-title-link.html',
    controller: function ctrl($scope, $element, $attrs) {
'ngInject';
      const vm = this;
      vm.nvCurrentPage = nvCurrentPage;
      vm.isEditOrReorderMode = () => TimelinesManager.isEditMode()
        || TimelinesManager.isReorderMode()
        || LecturePageManager.isEditMode()
        || LecturePageManager.isReorderMode();
    },
    controllerAs: 'courseTitleVm',
    bindToController: true,

  };
}
