import { css } from '@emotion/react';
import ClickableContainer from 'components/clickable-container';
import React, { useEffect, useState } from 'react';
import t from 'react-translate';
import { SkillTag } from 'redux/schemas/models/skill-tag';
import NvRoundButton from 'shared/components/nv-round-button';
import NvTooltip from 'shared/components/nv-tooltip';
import { textSmallFontSize } from 'styles/global_defaults/fonts';
import SkillsListAutocomplete from './skills-list-autocomplete';
import { config } from '../../../config/pendo.config.json';

export enum SkillButtonType{
  Normal,
  Compact,
}
type SkillButtonProps ={
  type: SkillButtonType,
  onAddSkill: (id) => void,
  disabled?: boolean,
  tags: SkillTag[]
};
const styles = css`
&.nv-icon-button{
  &.small-size{
    align-items:center;
    display:flex;
    font-size:${textSmallFontSize}px;
    height:21px;
    padding:3px 10px 3px 10px;
    &.normal{
      min-width:92px;
    }
    .icon-create-new-post{
      &.normal{
        margin-right:10px;

      }
    }
  }
}`;
const SkillsButtonField = ({ tags, type, onAddSkill, disabled }: SkillButtonProps) => {
  const [editing, setEditing] = useState(false);
  const [showTooltip, setShowToolTip] = useState(false);
  const label = type === SkillButtonType.Compact || disabled ? '' : t.INSTITUTIONS.SKILL_TAGS.ADD_SKILL_BUTTON();
  useEffect(() => {
    setEditing(false);
  }, [tags]);
  const onClick = () => {
    setEditing(true);
  };
  const onCancel = () => {
    setEditing(false);
  };
  const onMouseEnter = (e) => {
    setShowToolTip(disabled);
  };

  const onMouseLeave = (e) => {
    setShowToolTip(false);
  };
  const buttonComponent = (
    <NvRoundButton
      css={styles}
      icon={`icon-create-new-post ${type === SkillButtonType.Normal ? 'normal' : ''}`}
      label={label}
      onClick={() => onClick()}
      className={`small-size ${type === SkillButtonType.Normal && !disabled ? 'normal' : 'compact'}`}
      disabled={disabled}
      pendoTag={type === SkillButtonType.Normal ? config.pendo.skillTags.addToComponent : config.pendo.skillTags.addAnotherToComponent}
      ariaLabel={label}
    />
  );
  return (
    <>
      {disabled && (
      <NvTooltip show={showTooltip} text={t.INSTITUTIONS.SKILL_TAGS.NO_PERMISSIONS()}>
        <ClickableContainer
          onMouseEnter={(e) => onMouseEnter(e)}
          onMouseLeave={(e) => onMouseLeave(e)}
          onClick={() => setShowToolTip(!showTooltip)}
        >{buttonComponent}
        </ClickableContainer>
      </NvTooltip>
      )}
      {!editing && !disabled && buttonComponent}
      {editing && (
        <SkillsListAutocomplete tags={tags} onCancel={onCancel} onAddSkill={onAddSkill} />
      )}
    </>

  );
};
export default SkillsButtonField;
