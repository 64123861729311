import { config } from '../../../../config/pendo.config.json';

// Styles
import { css } from '@emotion/react';
import ClickableContainer from 'components/clickable-container';
import t from 'react-translate';

// Components
import NvIcon from 'shared/components/nv-icon';

type SkillViewTitleProps = {
  isExpanded: boolean;
  skillTagsCount: number;
  setIsExpanded: (isExpanded: boolean) => void;
};

const SkillViewTitle = (props: SkillViewTitleProps) => {
  const { isExpanded, skillTagsCount, setIsExpanded } = props;
  const styles = css`
    .icon-dropdown-arrow {
      transform: rotate(${isExpanded ? '180deg' : '0deg'});
    }
  `;

  return (
    <ClickableContainer
      className='d-flex align-items-center justify-content-center'
      css={styles}
      onClick={() => setIsExpanded(!isExpanded)}
      data-qa={isExpanded ? config.pendo.skillTags.skillView.collapseSkills : config.pendo.skillTags.skillView.expandSkills}
    >
      <NvIcon icon='skill' size='small' className='mr-2 bold' />
      <div className='text-regular bold'>{t.INSTITUTIONS.SKILL_TAGS.SKILL_VIEW_FOR_LEARNERS.PRACTICING(skillTagsCount)}</div>
      <NvIcon
        icon='dropdown-arrow'
        size='xss-smallest'
        className='ml-2 bold'
      />
    </ClickableContainer>
  );
};

export default SkillViewTitle;
