/* @ngInject */
export default function TeachingTeamDirectoryManager(

  LearnerDirectoryManager,
) {
  const manager = LearnerDirectoryManager;
  const LEARNER_DIRECTORY_PAGE_SIZE = 15;

  manager.dropdownFilters = ['teachingTeam'];

  return manager;
}
