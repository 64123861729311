import { css } from '@emotion/react';
import humps from 'humps';
import { useSelector } from 'react-redux';
import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import t from 'react-translate';
import { DeepPartial } from 'utility-types';

import PusherService from 'shared/services/pusher-service';
import { useAppDispatch, cloneDeepSerializable } from 'redux/store';
import { CombinedCourse, RootState } from 'redux/schemas';
import { Post } from 'redux/schemas/models/post';
import { updateLectureComponent } from 'redux/actions/lecture-pages';
import { AngularServicesContext } from 'react-app';
import { ComponentType, LectureComponent, NLectureComponent } from 'redux/schemas/models/lecture-component';
import { addComment, editComment, removeComment, voteComment } from 'redux/actions/comments';
import { addReply, editReply, removeReply, voteReply } from 'redux/actions/replies';
import { getCurrentUser } from 'redux/selectors/users';

import { TeamDiscussionLectureComponent } from 'redux/schemas/models/team-discussion';
import LectureComponentHeaderInput from 'shared/components/lecture-component-header-input';
import ActivityTitle from 'shared/components/activity/activity-title';
import ActivityPoints from 'shared/components/activity/activity-points';
import ActivityStatus from 'shared/components/activity/activity-status';

import { halfSpacing } from 'styles/global_defaults/scaffolding';

import { SharedLectureComponentProps } from 'lecture_pages/directives/components/base-lecture-component';
import BaseLectureComponentContext from 'lecture_pages/directives/components/base-lecture-component/context';

import { getCourse, getCurrentCourse } from 'redux/selectors/course';
import SkillsTags from 'skill_tags/components/skill-tags';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { MyAccount } from 'redux/schemas/models/my-account';
import PostContainer from './post-container';
import { LectureComponentProps, LecturePageMode } from '..';

export enum DiscussionEventsMap {
  NEW_COMMENT = 'new_post',
  DELETE_COMMENT = 'delete_post',
  UPDATE_COMMENT = 'update_post',
  LIKE_COMMENT = 'post_vote',

  NEW_REPLY = 'new_comment',
  DELETE_REPLY = 'delete_comment',
  UPDATE_REPLY = 'update_comment',
  LIKE_REPLY = 'comment_vote',
}

interface CommentEvent {
  commentsCount: number;
  lectureComponentId: number;
  lecturePageId: number;
  postId: number;
  postsCount: number;
  topicId: number;
  userId: number;
  numLikes?: number;
}

interface ReplyEvent {
  commentsCount: number;
  lectureComponentId: number;
  lecturePageId: number;
  postId: number;
  postsCount: number;
  topicId: number;
  userId: number;
  numLikes?: number;
  commentId: number;
}
interface TeamDiscussionContextProps {
  catalogId: string;
  postId: number;
  mode: LecturePageMode;
  angularComponent,
  currentLecture,
  lectureComponentId,
}

type TeamDiscussionLectureComponentType = LectureComponent<ComponentType.TEAM_DISCUSSION>;
type ComponentPatch = DeepPartial<TeamDiscussionLectureComponentType>;

export const TeamDiscussionContext = createContext<TeamDiscussionContextProps>(null);

const TeamDiscussion = (props: LectureComponentProps) => {
  const styles = css`
  display: flex;
    align-items: center;
    flex-direction: column;

    .lecture-header {
      margin-top: ${halfSpacing}px;
      width: 100%;
    }

    .status {
      margin-bottom: ${halfSpacing}px;
    }
  `;

  const params = useLecturePageParams();
  const dispatch = useAppDispatch();

  const { $state, $uibModal } = useContext(AngularServicesContext);
  const { sharedProps, setSharedProps } = useContext(BaseLectureComponentContext);

  const lectureComponent = useSelector((state: RootState) => state.models.lectureComponents[props.lectureComponent.id]) as NLectureComponent<ComponentType.TEAM_DISCUSSION>;
  // const { postId } = lectureComponent; //  || {};
  const post = useSelector<RootState, Post>((state: RootState) => state.models.posts[lectureComponent.post]);
  const currentCourse = useSelector<RootState, CombinedCourse>((state) => getCourse(state, $state.params.catalogId));
  const currentUser = useSelector<RootState, MyAccount>(getCurrentUser);
  const catalogId = useSelector((state) => state.app.currentCatalogId);

  const [title, setTitle] = useState<string>(post?.title);

  const saveComponent = useCallback((componentDataPatch: ComponentPatch) => {
    const componentDataUpdate = {
      catalogId: params.catalogId,
      lecturePageId: props.currentLecture.id,
      componentData: {
        id: lectureComponent.id,
        type: lectureComponent.type,
        index: lectureComponent.index,
        ...componentDataPatch,
      },
    };

    dispatch(updateLectureComponent(componentDataUpdate));
  }, [dispatch, props.currentLecture, lectureComponent, params.catalogId]);

  const openEditModal = useCallback(() => {
    // TODO: Broken, there is no equivalent in new arch
    // (props.angularComponent as any).createDraft();
    // const modalInstance = $uibModal.open({
    //   templateUrl: 'lecture_pages/templates/components/discussion-modal.html',
    //   controller: 'DiscussionFormModalCtrl',
    //   controllerAs: 'vm',
    //   windowClass: 'discussions-modal',
    //   resolve: {
    //     vmResolves: extend({
    //       // lectureComponent: props.angularComponent,
    //     }),
    //   },
    // });

    // modalInstance.result.then((res) => {
    //   dispatch(openSavingOverlay());
    //   // TODO: Fix; we don't need to clone deep this b/c it's not an angularjs model (nor do we in this
    //   // react arch project )
    //   // saveComponent({ topic: cloneDeepSerializable(res) }).then(() => dispatch(closeSavingOverlay()));
    // });
  }, [$uibModal, dispatch, saveComponent]);

  useEffect(() => {
    setTitle(post?.title);
  }, [post]);

  useEffect(() => {
    setSharedProps({
      ...sharedProps,
      extraOptions: {
        mode: 'prepend',
        renderOnMount: true,
        options: [
          sharedProps?.extraOptions?.getEditOption?.(
            t.LECTURE_PAGES.COMPONENTS.DISCUSSION.EDIT_BASICS(),
          ),
        ],
      },
    });
  }, [setSharedProps]);

  useEffect(() => {
    if (!post.team) return;

    const pusherChannel = PusherService.setupChannel(post.team.pusherChannelName);

    pusherChannel.bind(DiscussionEventsMap.NEW_COMMENT, (data) => {
      const camelizedData = humps.camelizeKeys(data) as unknown as CommentEvent;

      if (camelizedData.topicId === post.id && currentUser.id !== camelizedData.userId) {
        dispatch(addComment({
          commentId: camelizedData.postId,
          postId: camelizedData.topicId,
          userId: camelizedData.userId,
          catalogId,
        }));
      }
    });

    pusherChannel.bind(DiscussionEventsMap.DELETE_COMMENT, (data) => {
      const camelizedData = humps.camelizeKeys(data) as unknown as CommentEvent;

      if (camelizedData.topicId === post.id && currentUser.id !== camelizedData.userId) {
        dispatch(removeComment({
          catalogId,
          commentId: camelizedData.postId,
          postId: camelizedData.topicId,
          userId: camelizedData.userId,
        }));
      }
    });

    pusherChannel.bind(DiscussionEventsMap.UPDATE_COMMENT, (data) => {
      const camelizedData = humps.camelizeKeys(data) as unknown as CommentEvent;

      if (camelizedData.topicId === post.id && currentUser.id !== camelizedData.userId) {
        dispatch(editComment({
          commentId: camelizedData.postId,
          catalogId,
        }));
      }
    });

    pusherChannel.bind(DiscussionEventsMap.LIKE_COMMENT, (data) => {
      const camelizedData = humps.camelizeKeys(data) as unknown as CommentEvent;

      if (camelizedData.topicId === post.id) {
        dispatch(voteComment({
          commentId: camelizedData.postId,
          numLikes: camelizedData.numLikes,
          catalogId,
        }));
      }
    });

    pusherChannel.bind(DiscussionEventsMap.NEW_REPLY, (data) => {
      const camelizedData = humps.camelizeKeys(data) as unknown as ReplyEvent;

      if (camelizedData.topicId === post.id && currentUser.id !== camelizedData.userId) {
        dispatch(addReply({
          replyId: camelizedData.commentId,
          postId: camelizedData.topicId,
          commentId: camelizedData.postId,
          userId: camelizedData.userId,
          catalogId,
        }));
      }
    });

    pusherChannel.bind(DiscussionEventsMap.DELETE_REPLY, (data) => {
      const camelizedData = humps.camelizeKeys(data) as unknown as ReplyEvent;

      if (camelizedData.topicId === post.id && currentUser.id !== camelizedData.userId) {
        dispatch(removeReply({
          replyId: camelizedData.commentId,
          commentId: camelizedData.postId,
          postId: camelizedData.topicId,
          userId: camelizedData.userId,
          catalogId,
        }));
      }
    });

    pusherChannel.bind(DiscussionEventsMap.UPDATE_REPLY, (data) => {
      const camelizedData = humps.camelizeKeys(data) as unknown as ReplyEvent;

      if (camelizedData.topicId === post.id && currentUser.id !== camelizedData.userId) {
        dispatch(editReply({
          replyId: camelizedData.commentId,
          catalogId,
        }));
      }
    });

    pusherChannel.bind(DiscussionEventsMap.LIKE_REPLY, (data) => {
      const camelizedData = humps.camelizeKeys(data) as unknown as ReplyEvent;

      if (camelizedData.topicId === post.id) {
        dispatch(voteReply({
          replyId: camelizedData.commentId,
          numLikes: camelizedData.numLikes,
          catalogId,
        }));
      }
    });
  }, [catalogId, dispatch, post.id, currentUser.id, post.team]);


  const handleTitleBlur = () => saveComponent({ topic: { title } });

  const handleTitleInputChange = (newTitle: string) => {
    setTitle(newTitle);
  };

  // TO-DO: Remove this. If postId is null, this component should never render
  if (!lectureComponent.post) return null;

  const isEditMode = props.mode === LecturePageMode.EDIT;

  return (
    /** TODO: This is broken due to angularComponent */
    <TeamDiscussionContext.Provider value={{
      ...props,
      catalogId: params.catalogId,
      mode: props.mode,
      currentLecture: props.currentLecture,
      angularComponent: null,
      postId: lectureComponent.post,
      lectureComponentId: props.lectureComponent.id,
    }}
    >
      <div id={`lecture-component-${lectureComponent?.id}`}>
        {(post.isTodo || post.pointsConfiguration) && (
          <div css={styles}>
            <div className='status'>
              <ActivityStatus icon='conversations' status={post.progress} />
            </div>
            <ActivityTitle
              status={post.progress}
              statusesTexts={{
                not_started: t.LECTURE_PAGES.COMPONENTS.DISCUSSION.DISCUSS(),
                completed: t.GAMIFICATION.PARTICIPATED(),
              }}
            />
            <div className='lecture-header'>
              <LectureComponentHeaderInput
                value={title}
                editable={isEditMode}
                onBlur={handleTitleBlur}
                onChange={handleTitleInputChange}
                required
              />
            </div>
            {post.pointsConfiguration && (
              <div className='points'>
                <ActivityPoints
                  pointsReceived={post.pointsReceived}
                  totalPoints={post.pointsConfiguration.points}
                  label={post.progress === 'completed' ? t.GAMIFICATION.PARTICIPATED() : t.GAMIFICATION.PARTICIPATE()}
                />
              </div>
            )}
          </div>
        )}
        <PostContainer />
      </div>
    </TeamDiscussionContext.Provider>
  );
};

export default TeamDiscussion;
