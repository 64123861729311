import React, { useState } from 'react';
import t from 'react-translate';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';

import { sendMessage } from 'redux/actions/users';
import { addAlertMessage } from 'redux/actions/alert-messages';

import { getCurrentCourse } from 'redux/selectors/course';

import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { CommunityCompletionUser } from 'redux/schemas/models/user';

import NvFroala from 'froala/components/nv-froala';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import NvTextInput from '../inputs/nv-text-input';

type SendMessageModalProps = {
  recipient: CommunityCompletionUser;
  closeModal: () => void,
};

export const SendMessageModal = (props: SendMessageModalProps) => {
  const { recipient, closeModal } = props;
  const currentCourse = useSelector(getCurrentCourse);
  const dispatch = useAppDispatch();

  const [isSending, setIsSending] = useState<boolean>(false);

  const validationSchema = yup.object().shape({
    subject: yup.string().required(t.VALIDATION.REQUIRED()),
    body: yup.string().required(t.VALIDATION.REQUIRED()),
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      subject: '',
      body: '',
    },
    shouldUnregister: true,
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, formState } = methods;
  const { isDirty, isValid } = formState;

  const onSubmit = (data, e) => {
    setIsSending(true);

    const conversationData = {
      catalogId: currentCourse.catalogId,
      message: data,
      recipientIds: [recipient.id],
      recipientType: 'user',
      recipients: [recipient],
    };

    dispatch(sendMessage(conversationData)).then(() => {
      dispatch(addAlertMessage({
        type: AlertMessageType.SUCCESS,
        header: t.FORM.SUCCESS_BANG(),
        message: t.PROFILE.SEND_MESSAGE_SUCCESS(),
      }));

      setIsSending(false);
      closeModal();
    });
  };

  return (
    <div>
      <div className='text-small text-gray-2'>
        {t.PROFILE.COURSE_NAME({ courseAlias: currentCourse.offeringName.singularizedTitleized })}
      </div>
      <div className='label bg-gray-4 py-1 px-2 mt-1 mb-4'>
        {currentCourse.name}
      </div>
      <div className='text-small text-gray-2'>{t.PROFILE.TO()}</div>
      <div className='label bg-gray-4 py-1 px-2 mt-1 mb-4'>
        {`${recipient.firstName} ${recipient.lastName}`}
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <NvTextInput
            withForm
            name='subject'
            autoComplete='off'
            className='mt-4 mb-6'
            placeholder={t.TEAMS.PROFILE.MESSAGE.SUBJECT()}
          />
          <NvFroala
            withForm
            name='body'
            placeholder={t.TEAMS.PROFILE.MESSAGE.MESSAGE()}
            className='mb-5'
          />
          <div className='d-flex justify-content-center mt-5'>
            <Button
              disabled={!isDirty || !isValid || isSending}
              type='submit'
            >
              {t.FORM.SEND()}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default SendMessageModal;


