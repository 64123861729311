import { AxiosResponse } from 'axios';

export enum ExportTypes {
  NONE = 'none',
  ORGANIZATION_ENROLLMENT = 'organization_enrollment',
  ORGANIZATION_USERS = 'organization_users',
  USER_ENROLLMENT = 'user_enrollment',
  COURSE_ENROLLMENT = 'course_enrollment',
}

export enum EnrollmentPeriod {
  CURRENT_CONTRACT_PERIOD = 'current_contract',
  SINCE_ORG_CREATION = 'creation_time',
  PAST_12_MONTHS = 'past_12_month',
  PAST_6_MONTHS = 'past_6_month',
  PAST_30_DAYS = 'past_30_days',
  CUSTOM_DATE_RANGE = 'date_range',
}

export enum ExportStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export interface LicenseData {
  currentPeriodStartedAt: Date;
  currentPeriodEndedAt: Date;
  noSeats: number;
  gracePeriod: number;
  unenrollGracePeriod: number;
  notes: string;
}

export interface SeatsUsageData {
  seatUsage: number,
}

export type LicenseState = LicenseData & SeatsUsageData & {
  // Whether this data has finished loading
  // TODO: Consider whether or not this is a good pattern
  loaded?: boolean;
  exportDetails?: ExportDetails;
  exportFileList?: ExportFile[];
  courseEnrollmentDetails?: CourseEnrollmentDetails;
};

export interface LicenseDashboardState {
  allCoursesLoaded: boolean,
  monthlyEnrollmentsLoaded: boolean,
  enrollmentsForPeriodLoaded: boolean,
}

export interface CourseRankingState {
  // TODO: These keys are course IDs. Is there any intelligent way to hook
  // them up to our model with normalizr?
  top10Courses: { [courseId: string]: Top10Course };
}

// Same as MonthlyEnrollment
export interface EnrollmentForPeriod {
  totalEnrollments: number;
  newEnrollments: number;
}

export interface MonthlyEnrollment {
  totalEnrollment: number;
  newEnrollment: number;
}

export interface Top10Course {
  courseEnrollments: number;
  courseLogins: number;
}

export type ExportDetails = {
  [keys in ExportTypes]?: {
    [keys in EnrollmentPeriod]?: {
      reportStatus: ExportStatus;
    }
  }
};

export type CourseEnrollmentDetails = Record<number, CourseDetails>;

export interface CourseDetails {
  reportStatus: ExportStatus;
  downloadFile: {
    completedAt: string;
    url: string;
  }
}

export interface ExportFile {
  period: any;
  reportStatus?: ExportStatus;
  exportType: string;
  url: string;
  completedAt: string;
  startedAt: string;
  endedAt: string;
  generatedBy: string;
}
