/* @ngInject */
export default function NvSelectDropdown(
  $uibModal,
  $window,
  nvUtil,
  _,
) {
  return {
    scope: {
      dropdownData: '=',
      hiddenField: '=?',
      updateRecordCount: '&?',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
      vm.nvUtil = nvUtil;

      setSelection(this.dropdownData);

      vm.toggleSelection = function (obj, key) {
        obj[key] = true;

        _.each(obj, (value, index) => {
          if (index !== key && index !== vm.hiddenField) {
            obj[index] = false;
          }
        });

        setSelection(this.dropdownData);
      };

      function setSelection(obj) {
        let keys; let
          selected;

        if (vm.updateRecordCount) {
          vm.updateRecordCount(); // Number displayed in role-bubble
        }
        if (obj) {
          keys = Object.keys(obj);
          selected = keys.filter((key) => obj[key] === true);

          vm.selected = selected[0];
        }
      }
    },
    replace: true,
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'custom_downloads/templates/nv-select-dropdown.html',
  };
}
