import { css } from '@emotion/react';
import ProfilePicture from 'org_level_profile/components/profile-picture';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AngularContext } from 'react-app';
import t from 'react-translate';
// Redux
import { useSelector } from 'react-redux';
import { getCourseAliases } from 'redux/selectors/course';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { Team } from 'redux/schemas/models/team';
import { moveTeamMembers, getPossibleFutureTeams } from 'redux/actions/teams';
import { useAppDispatch } from 'redux/store';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { addAlertMessage } from 'redux/actions/alert-messages';
// Styling
import { halfSpacing, standardSpacing, largeSpacing, tripleSpacing, quarterSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
// Components
import { Button } from 'react-bootstrap';
import NvAutocompleteBase from 'shared/components/nv-autocomplete-base';
import { Input, LoadingIndicator, SearchResultItem } from './autocomplete-aditional-components';
// Config
import { config } from '../../../config/pendo.config.json';

const MoveMemberModal = ({ member, teamInfo, closeModal, isLastMember }) => {
  const { injectServices } = useContext(AngularContext);
  const [PageLevelManager] = injectServices(['PageLevelManager']);
  const aliases = useSelector((state) => getCourseAliases(state, teamInfo.teamSet.catalogId));
  const [futureTeam, setFutureTeam] = useState(null);
  const [saving, setSaving] = useState(false);
  const dispatch = useAppDispatch();
  const style = css`
    padding: 0px ${2 * standardSpacing}px;
    .team-picture {
      display: flex;
      justify-content: center;
    }
    .team-name {
      padding: ${halfSpacing}px 0px ${2 * largeSpacing}px 0px;
      text-align: center;
    }
    .modal-description {
      padding: ${halfSpacing}px 0px;
    }
    .new-members-list {
      padding-top: ${threeQuartersSpacing}px;
    }
    .buttons {
      text-align: center;
      button {
        margin: ${tripleSpacing}px ${quarterSpacing}px;
      }
    }
  `;

  const closeL4 = () => {
    PageLevelManager.callL4CloseCallbacks();
  };

  const moveMember = () => {
    setSaving(true);
    const memberName = member.user.fullName;
    const teamName = futureTeam.name;
    const isLastGroupMember = isLastMember;
    if (isLastGroupMember) {
      closeL4();
    }
    const handleConfirm = () => dispatch(
      moveTeamMembers({
        catalogId: teamInfo.courseCatalogId,
        teamId: futureTeam.id,
        userIds: [member.user.id],
        currentTeamId: teamInfo.id,
        isTeam: !teamInfo.teamSet?.isGroup,
      }),
    );
    handleConfirm().then((response: any) => {
      if (!isLastGroupMember) {
        setSaving(false);
      }
      if (response.error) {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.FORM.ERROR_SOMETHING_WRONG(),
        }));
      } else {
        dispatch(addAlertMessage({
          type: AlertMessageType.SUCCESS,
          header: t.FORM.SUCCESS_BANG(),
          message: t.TEAMS.MANAGE_TEAM_GROUP.MODAL.MOVING.SUCCESS_MOVE_MEMBER_MESSAGE(memberName, teamName),
        }));
      }
      closeModal();
    });
  };

  const showAlertMessage = () => {
    dispatch(openConfirmationDialog({
      title: t.TEAMS.MANAGE_TEAM_GROUP.MODAL.MOVING.WARNING_MESSAGE_TITLE(member.user.fullName, futureTeam.name),
      bodyText: t.TEAMS.MANAGE_TEAM_GROUP.MODAL.MOVING.WARNING_MESSAGE_DESCRIPTION(member.user.fullName, teamInfo.name, futureTeam.name),
      confirmText: t.TEAMS.MANAGE_TEAM_GROUP.MODAL.REMOVING.CONFIRM(),
      onConfirm: () => moveMember(),
      cancelDataQa: config.pendo.manageOneTeamGroup.modalContent.body.memberList.editMenu.menuOptions.moveMemberConfirmationModal.cancelMoveConfirmationModal,
      confirmDataQa: config.pendo.manageOneTeamGroup.modalContent.body.memberList.editMenu.menuOptions.moveMemberConfirmationModal.confirmMoveConfirmationModal,
    }));
  };

  const fetchPredictions = useCallback((hint: string) => {
    if (hint) {
      return dispatch(getPossibleFutureTeams({
        catalogId: teamInfo.courseCatalogId,
        teamId: teamInfo.id,
        teamSetId: teamInfo.teamSet.id,
        text: hint,
        // Getting only the first 6 elements.
        pageSize: 6,
        pageNumber: 1,
      })).then(action => action.payload);
    }

    return null;
  }, [dispatch, teamInfo]);

  return (
    <div className='team-info' css={style}>
      <div className='team-picture'>
        <ProfilePicture
          profile={member.user}
          size={100}
        />
      </div>
      <div className='team-name text-medium bold'>
        {member.user.fullName}
      </div>
      <div className='modal-description'>
        {t.TEAMS.MANAGE_TEAM_GROUP.MODAL.MOVING.DESCRIPTION({ name: member.user.fullName, isTeam: !teamInfo.teamSet?.isGroup, ...aliases.teamAliases, ...aliases.groupAliases })}
      </div>
      <div className='search'>
        <NvAutocompleteBase<Team>
          onChange={(team) => {
            setFutureTeam(team);
          }}
          fetchPredictions={fetchPredictions}
          keyExtractor={(team) => team.id}
          resultsContainerClassName='autocomplete-results-container'
          renderLoadingIndicator={() => <LoadingIndicator />}
          renderNoResults={() => (
            <SearchResultItem
              text={t.TEAMS.MANAGE_TEAM_GROUP.MODAL.MOVING.RESULTS_NOT_FOUND()}
              isValidResult={false}
            />
          )}
          renderInput={(inputProps) => (
            <Input
              value={futureTeam?.name}
              placeholder={t.TEAMS.MANAGE_TEAM_GROUP.MODAL.MOVING.PLACEHOLDER({ isTeam: !teamInfo.teamSet?.isGroup, ...aliases.teamAliases, ...aliases.groupAliases })}
              data-qa={config.pendo.manageOneTeamGroup.modalContent.body.memberList.editMenu.menuOptions.moveMemberModal.newTeamSearchBar}
              {...inputProps}
            />
          )}
          renderResultItem={(resultItemProps, team) => (
            <SearchResultItem
              text={team.name}
              {...resultItemProps}
            />
          )}
          renderValueSelected={(resultItemProps, _) => (
            <SearchResultItem
              isToggle
              text={futureTeam.name}
              data-qa={config.pendo.manageOneTeamGroup.modalContent.body.memberList.editMenu.menuOptions.moveMemberModal.newTeamSearchBar}
              {...resultItemProps}
            />
          )}
        />
      </div>
      <div className='buttons'>
        <Button
          variant='secondary'
          onClick={() => { closeModal(); }}
          data-qa={config.pendo.manageOneTeamGroup.modalContent.body.memberList.editMenu.menuOptions.moveMemberModal.cancelMove}
        >
          {t.TEAMS.MANAGE_TEAM_GROUP.MODAL.MOVING.CANCEL()}
        </Button>
        <Button
          variant='primary'
          disabled={!futureTeam || saving}
          onClick={() => { showAlertMessage(); }}
          data-qa={config.pendo.manageOneTeamGroup.modalContent.body.memberList.editMenu.menuOptions.moveMemberModal.confirmMove}
        >
          {t.TEAMS.MANAGE_TEAM_GROUP.MODAL.MOVING.MOVE_TO_TEAM({ isTeam: !teamInfo.teamSet.isGroup, ...aliases.teamAliases, ...aliases.groupAliases })}
        </Button>
      </div>
    </div>
  );
};

export default MoveMemberModal;
