import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/core';
import t from 'react-translate';
import { Button } from 'react-bootstrap';
import { AngularContext } from 'react-app';

import { LoadingSpinnerType } from 'shared/components/loading-spinner';
import LoadingWrapper, { LoaderType } from 'shared/components/loading-wrapper';
import { getSubmission, getScenario } from 'redux/selectors/video-practice';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { useAppDispatch } from 'redux/store';
import { generateInsights, getComments, getPracticeSubmission, requestReviewPracticeSubmission, setShowPracticeAgainModal } from 'redux/actions/video-practice';
import { RootState } from 'redux/schemas';
import {
  hasGetUserMedia,
  hasMediaRecorder,
  isSafari,
} from 'recording/services/media-stream';
import { getCurrentUser } from 'redux/selectors/users';
import { doubleSpacing } from 'styles/global_defaults/scaffolding';
import { useQuery } from 'shared/hooks/use-query';
import prodPathReplace from 'shared/prod-path-rewrite';
import { PracticeRoomTab, VideoPracticeScenario } from 'redux/schemas/models/video-practice';
import { PracticeSubmissionContext } from '../utils';
import FillerWords from './filler-words/filler-words';
import KeyPhrases from './key-phrases/key-phrases';
import { config } from '../../../../../config/pendo.config.json';
import { ReviewStatus } from '../submission/request-review-cta';

const styles = css`
  .loading-image {
    display: block;
    height: 200px;
    margin: ${doubleSpacing}px auto;
  }
`;

const InsightsTab = () => {
  const [{ submissionId, scenarioId, isMyPractice, skillTags }] = useContext(PracticeSubmissionContext);
  const { injectServices } = useContext(AngularContext);
  const [$, CurrentUserManager] = injectServices(['$state', 'CurrentUserManager']);
  const {
    transcriptionInsightsStatus,
    fillerInsightStatus,
    reviewStatus,
    isViewerMentor,
  } = useSelector((state) => getSubmission(state, submissionId));
  const scenario = useSelector<RootState, VideoPracticeScenario>((state) => getScenario(state, scenarioId));
  const browserNotSupported = isSafari || !hasGetUserMedia() || !hasMediaRecorder();
  const dispatch = useAppDispatch();
  const query = useQuery();
  const user = useSelector(getCurrentUser);
  const [isLoadingRequestReview, setIsLoadingRequestReview] = React.useState<boolean>(false);

  const queryUserId = query?.user;
  const hasFeedbackBy = !!query?.feedbackBy;
  const hasMentors = CurrentUserManager?.currentUserCourse?.mentors?.length > 0 || scenario.hasMentorsInCoursesUsedIn;
  const showPracticeAgainCTA = (query.selected === PracticeRoomTab.MY_PRACTICE || Number(queryUserId) === user.id);

  const showRequestReviewCTA = scenario?.enableRequestReview && skillTags.length > 0 && (Number(queryUserId) === user.id || hasFeedbackBy || query.selected === PracticeRoomTab.MY_PRACTICE) && hasMentors;

  // Control the CTA label and disabled state based on the review status
  const showRequestReviewCTAConfig = useMemo(() => {
    if (reviewStatus === ReviewStatus.REQUESTED) {
      return {
        label: t.PRACTICE_ROOM.INSIGHTS.REQUEST_REVIEW.PENDING_REVIEW_CTA(),
        disabled: true,
      };
    }
    return {
      label: t.PRACTICE_ROOM.INSIGHTS.REQUEST_REVIEW.SUBMIT_REVIEW_CTA(),
      disabled: false,
    };
  }, [reviewStatus]);

  const onClickRequestReview = () => {
    setIsLoadingRequestReview(true);
    dispatch(requestReviewPracticeSubmission({ submissionId, scenarioId }))
      .then(async () => {
        await dispatch(getPracticeSubmission({ scenarioId, submissionId })).then(() => {
          dispatch(
            addAlertMessage({
              type: AlertMessageType.SUCCESS,
              header: t.PRACTICE_ROOM.INSIGHTS.REQUEST_REVIEW.REQUEST_SUCCESS_TITLE(),
              message: t.PRACTICE_ROOM.INSIGHTS.REQUEST_REVIEW.REQUEST_SUCCESS_DESCRIPTION(),
            }),
          );
        });
        await dispatch(getComments({ submissionId, page: 1 }));
      })
      .finally(() => {
        setIsLoadingRequestReview(false);
      });
  };

  const onPracticeAgain = () => {
    if (browserNotSupported) {
      return;
    }
    dispatch(setShowPracticeAgainModal({ show: true }));
  };

  return (
    <div css={styles}>
      {/* Display CTA to generate insights if both insights are in `not_started` state */}
      {transcriptionInsightsStatus === 'not_started' ? (
        (isMyPractice || isViewerMentor) && (
          <div className='d-flex flex-column text-gray-1 text-small'>
            {t.PRACTICE_ROOM.INSIGHTS.GENERATE_INSIGHTS.INFO()}
            <Button
              variant='primary'
              className='align-self-center mt-6 text-small'
              onClick={() => dispatch(generateInsights({ submissionId, scenarioId }))}
              data-qa={config.pendo.practice.generateInsights}
            >
              {t.PRACTICE_ROOM.INSIGHTS.GENERATE_INSIGHTS.CTA()}
            </Button>
          </div>
        )
      ) : (
      /**
       * Display the loading spinner till the status changes to `completed` in case of keyphrases(transcription),
       * and for filler words both `not_started` and `completed` states are loaded states,
       * and display CTA for `not_started`
       */
        <LoadingWrapper
          loaderType={LoaderType.SPINNER}
          spinnerType={LoadingSpinnerType.CIRCLE}
          isLoaded={transcriptionInsightsStatus === 'completed' || fillerInsightStatus === 'completed'}
          className='insights-loader d-flex flex-column align-items-center'
          loadingInfo={(
            <div className='page-title-small gray-2 mt-6'>
              {t.PRACTICE_ROOM.INSIGHTS.LOADING()}
            </div>
          )}
          loadingInfoNoRender
        >
          <KeyPhrases />
          <div className={`${showPracticeAgainCTA ? 'border-bottom border-gray-4' : ''}`}>
            {fillerInsightStatus !== 'in_progress' && (
              <div className='pb-6'>
                <FillerWords />
              </div>
            )}
            {fillerInsightStatus === 'in_progress' && (
              <div className='pt-6'>
                <p className='text-large-regular text-center font-weight-bolder text-gray-2 animated-paragraph'>{t.INSTANT_INSIGHT.DETERMINE_WORDS()}</p>
                <img
                  className='loading-image'
                  src={prodPathReplace('images/filler-words.gif')}
                  alt={t.INSTANT_INSIGHT.DETERMINE_WORDS()}
                />
              </div>
            )}
          </div>
          <div className='gray-1 text-small pt-6 text-center'>
            {t.PRACTICE_ROOM.INSIGHTS.DESCRIPTION()}
          </div>
          <div className='d-flex justify-content-center my-5'>
            {showPracticeAgainCTA && (
              <Button
                variant={showRequestReviewCTA && reviewStatus !== ReviewStatus.REVIEWED ? 'secondary' : 'primary'}
                size='lg'
                className='d-flex align-items-center'
                onClick={onPracticeAgain}
                pendo-tag-name={config.pendo.practice.practiceAgain}
                aria-label={t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.PRACTICE_AGAIN()}
              >
                <div className='icon icon-media-practice mr-2' />
                {t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.PRACTICE_AGAIN()}
              </Button>
            )}
            {showRequestReviewCTA && reviewStatus !== ReviewStatus.REVIEWED && (
              <Button
                variant='primary'
                size='lg'
                disabled={showRequestReviewCTAConfig.disabled || isLoadingRequestReview}
                className='d-flex align-items-center ml-4'
                onClick={onClickRequestReview}
                pendo-tag-name={config.pendo.practice.requestReviewsCta}
                aria-label={showRequestReviewCTAConfig.label}
              >
                <div className='icon icon-course-mentees mr-2' />
                {showRequestReviewCTAConfig.label}
              </Button>
            )}
          </div>
        </LoadingWrapper>
      )}
    </div>
  );
};
export default InsightsTab;
