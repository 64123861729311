import React from 'react';
import { css } from '@emotion/react';

import t from 'react-translate';
import NvSwitch from 'shared/components/inputs/nv-switch';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { config } from '../../../config/pendo.config.json';

type Props = {
  initialValue: boolean,
  onChange: (newChecked: boolean) => void,
  isJourney?: boolean,
};

const AutomatedTranslationSwitch = (props: Props) => {
  const { onChange, initialValue, isJourney = false } = props;

  const mountedRef = React.useRef(false);
  const [value, setValue] = React.useState(initialValue);
  const onChangeRef = React.useRef<(checked: boolean) => void>();
  onChangeRef.current = onChange;

  React.useEffect(() => {
    if (mountedRef.current) {
      onChangeRef.current(value);
    } else {
      mountedRef.current = true;
    }
  }, [value]);

  const styles = css`
    & > * {
      vertical-align: middle;
    }

    .nv-switch {
      margin-right: ${halfSpacing}px;
    }
  `;

  return (
    <React.Fragment>
      <div css={styles}>
        <NvSwitch
          enabledIcon='gen-ai'
          checked={value}
          onChange={setValue}
          className='nv-switch'
          data-qa={
            isJourney
              ? config.pendo.automatedTranslation.journeyAutomatedTranslationSwitch
              : config.pendo.automatedTranslation.courseAutomatedTranslationSwitch
          }
        />
        <span className='text-body'>
          {
            isJourney
              ? t.LEARNING_JOURNEYS.DASHBOARD.LANGUAGE_MODAL.AUTOMATED_TRANSLATION_SWITCH_LABEL()
              : t.COURSE_HOME.LANGUAGE.AUTOMATED_TRANSLATION_SWITCH_LABEL()
          }
        </span>
      </div>
    </React.Fragment>
  );
};

export default AutomatedTranslationSwitch;
