import { useEffect, useCallback } from 'react';

const useNavigationHandle = (
  displayAlert = true,
  onBeforeAlert = () => {},
  onCancelUnload = () => {},
  onUnload = () => {},
) => {
  const alertUser = useCallback((e) => {
    onBeforeAlert?.();
    e.preventDefault();
    e.returnValue = '';

    setTimeout(() => {
      onCancelUnload?.();
    }, 100);
  }, [onCancelUnload, onBeforeAlert]);

  useEffect(() => {
    if (displayAlert) {
      window.addEventListener('beforeunload', alertUser);
    }

    if (onUnload) {
      window.addEventListener('unload', onUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('unload', onUnload);
    };
  }, [displayAlert, onUnload, alertUser]);
};

export default useNavigationHandle;
