import { css, jsx } from '@emotion/react';
import React, { useEffect, useMemo } from 'react';
import t from 'react-translate';
import { NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';
import { Button } from 'react-bootstrap';
import NvIcon from 'shared/components/nv-icon';
import NvFormDropdown from 'shared/components/inputs/nv-form-dropdown';
import { Controller, FieldValues, useFieldArray, useFormContext } from 'react-hook-form';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import { black, gray5, primary } from 'styles/global_defaults/colors';
import { halfSpacing, largeSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { OrgProfileField } from 'redux/schemas/models/account-fields';

type EntitlementFormRowProps = {
  index: number,
  profileSettings: OrgProfileField[],
  onRemove(): void,
  entitlementField: any,
};

const styles = css`
  .divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: ${largeSpacing}px;

    &::before, &::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid ${gray5};
    }

    .content {
      margin: 0 ${standardSpacing}px;
      color: ${black};
    }
  }

  .entitlement-details-row {
    display: flex;
    align-items: flex-start;
    margin: ${standardSpacing}px 0;

    .left {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 260px;

      .remove-entitlement {
        padding: 0;
        padding-right: 8px;
      }

      .remove-entitlement-dropdown {
        color: ${primary};
      }
      .entitlement-dropdown {
        width: 240px;
      }

      label {
        padding-top: 14px;
      }
    }

    .right {
      padding-left: ${halfSpacing}px;

      .entitlement-value-row {
        display: flex;
        align-items: center;

        .entitlement-value {
          width: 300px;
        }

        .remove-entitlement-value {
          padding: 0;
          padding-left: ${halfSpacing}px;
          border: 0;
        }
      }

      .add-entitlement-value {
        padding: 0;
        padding-top: ${quarterSpacing}px;
        >* {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
`;

const EntitlementFormRow = ({
  profileSettings,
  index,
  onRemove,
  entitlementField,
}: EntitlementFormRowProps) => {
  const { control, watch, trigger } = useFormContext();

  const { fields: valueFields, remove, append } = useFieldArray<FieldValues, 'entitlements.0.values', 'value' | 'keyName'>({
    control,
    name: `entitlements.${index}.values` as 'entitlements.0.values',
    keyName: 'keyName',
  });

  const watchedEntitlements = watch('entitlements');

  const availableProfileSettingOptions = useMemo(() => {
    const usedProfileSettingIds = watchedEntitlements.map(watchedEntitlement => watchedEntitlement.profileSetting?.id);

    return profileSettings.filter((ps) => (!usedProfileSettingIds.includes(ps.id) || ps.id === watchedEntitlements[index]?.profileSetting?.id)).map((ps) => ({
      id: ps.id,
      text: ps.name,
      value: ps.id,
      type: 'text',
    })) as NvDropdownTextItem[];
  }, [index, profileSettings, watchedEntitlements]);

  // manual trigger of validation because useFieldArray fields don't trigger validation
  // https://github.com/react-hook-form/react-hook-form/issues/2369
  // unfortunately this shows errors for non dirty fields
  useEffect(() => {
    trigger(`entitlements.${index}.values`);
  }, [index, trigger, valueFields]);

  const addValueHandler = () => {
    append({
      value: '',
    });
  };

  return (
    <div css={styles}>
      {index > 0 && (
        <div className='divider'>
          <div className='content label'>{t.COURSES.FORM.ENTITLEMENTS.MODAL.ADD_DIVIDER_TEXT()}</div>
        </div>
      )}
      {/* Without the controller, the data gets wiped out on removing a row */}
      <Controller
        name={`entitlements.${index}.id`}
        defaultValue={entitlementField.id || ''}
        render={({ field }) => <input type='hidden' {...field} />}
      />

      {/* Unfortunately, grid wont' work here with the need for dynamic number of rows & IE11 support */}
      {valueFields.map((valueField, valueFieldIndex) => (
        <div className='entitlement-details-row' key={valueField.keyName}>
          {valueFieldIndex === 0
            ? (
              <div className='left'>
                <Button onClick={onRemove} variant='link' className='remove-entitlement'>
                  <NvIcon icon='minus' size='xss-smallest' />
                </Button>
                <NvFormDropdown
                  name={`entitlements.${index}.profileSetting`}
                  items={availableProfileSettingOptions}
                  title={t.COURSES.FORM.ENTITLEMENTS.MODAL.SELECT_PROFILE_FIELD()}
                  className='entitlement-dropdown'
                  withFormDefaultValue={entitlementField.profileSetting}
                  isSmallSize
                />
              </div>
            )
            : (
              <div className='left'>
                <label>{t.COURSES.FORM.ENTITLEMENTS.MODAL.OR_LABEL()}</label>
              </div>
            )}
          <div className='right'>
            <div className='entitlement-value-row'>
              <NvTextInput
                className='entitlement-value'
                required
                withForm
                defaultValue={valueField.value}
                name={`entitlements.${index}.values.${valueFieldIndex}.value`}
                placeholder={t.COURSES.FORM.ENTITLEMENTS.MODAL.INPUT_ENTITLEMENT_VALUE()}
              />
              <Button onClick={() => remove(valueFieldIndex)} variant='link' className='remove-entitlement-value' disabled={valueFields.length <= 1}>
                <NvIcon icon='minus' size='xss-smallest' />
              </Button>
            </div>
            {valueFieldIndex === valueFields.length - 1 && (
              <Button onClick={addValueHandler} variant='link' className='add-entitlement-value'>
                <NvIcon icon='add' size='xss-smallest' />
                {' '}
                {t.COURSES.FORM.ENTITLEMENTS.MODAL.ADD_ENTITLEMENT_VALUE()}
              </Button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default EntitlementFormRow;
