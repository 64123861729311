/* @ngInject */
export default function MenteesPageBaseController(
  _,
  $timeout,
  $translate,
  $stateParams,
  MenteesManager,
  CurrentCourseManager,

  $scope,
) {
  const _this = this;

  this.$stateParams = $stateParams;
  this.MenteesManager = MenteesManager;
  this.CurrentCourseManager = CurrentCourseManager;

  // for some reason this can't be defined before the promise resolves or else the radio button will not be checked when the value is null specifically
  // this.pendingExerciseFilter = null;
  this.flattenedSorts = [
    {
      displayName: 'MENTEES.SORT_BY.STUDENT_A_TO_Z',
      name: 'nameDescending',
      data: {
        sort: 'name',
        sortOrder: 'desc',
      },
    }, {
      displayName: 'MENTEES.SORT_BY.STUDENT_Z_TO_A',
      name: 'nameAscending',
      data: {
        sort: 'name',
        sortOrder: 'asc',
      },
    }, {
      displayName: 'MENTEES.SORT_BY.PROGRESS_BY_DESCENDING',
      name: 'progressDescending',
      data: {
        sort: 'progress',
        sortOrder: 'desc',
      },
    }, {
      displayName: 'MENTEES.SORT_BY.PROGRESS_BY_ASCENDING',
      name: 'progressAscending',
      data: {
        sort: 'progress',
        sortOrder: 'asc',
      },
    },
  ];

  MenteesManager.initialize(_.pick($stateParams, ['catalogId', 'exerciseId', 'filter', 'sort', 'sortOrder', 'ownerId', 'ownerType']));

  MenteesManager.getFeatureableExercises().then((selectedExercise) => {
    // timeout is required so the ng-model will sync with the view value when set programmatically
    $timeout(() => {
      _this.pendingExerciseFilter = selectedExercise;
    });
  });

  // initialize ng-model
  setPendingSort();

  $scope.$on('$destroy', () => {
    MenteesManager.resetData();
  });

  this.selectFilterOption = function (filter) {
    MenteesManager.onFilterChange(filter);
    setPendingSort();
    this.pendingExerciseFilter = MenteesManager.exerciseFilter;
  };

  this.selectExerciseFilter = function (exercise) {
    this.pendingExerciseFilter = exercise;
    MenteesManager.onExerciseChange(exercise);
  };

  this.selectSortOption = function (sort) {
    MenteesManager.onSortChange(sort);
    this.pendingSort = {
      sort: MenteesManager.sort,
      sortOrder: MenteesManager.sortOrder,
    };
  };

  this.onTabClick = function (tab) {
    this.selectFilterOption(tab);
  };

  this.onHandheldFilterClose = function (isOpen) {
    if (!isOpen) {
      MenteesManager.setSort(this.pendingSort.data.sort);
      MenteesManager.setSortOrder(this.pendingSort.data.sortOrder);
      MenteesManager.onExerciseChange(this.pendingExerciseFilter);
    }
  };

  function setPendingSort() {
    _this.pendingSort = _.find(_this.flattenedSorts, (flattenedSortOption) => flattenedSortOption.data.sort === MenteesManager.sort && flattenedSortOption.data.sortOrder === MenteesManager.sortOrder);
  }
}
