import React, { useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { pick, contains, every } from 'underscore';
import { useAppDispatch } from 'redux/store';
import { RootState } from 'redux/schemas';
import { likeComment, undoLikeComment, getCommentLikers, updateComment } from 'redux/actions/video-practice';
import { PracticeSubmissionComment, PracticeRoomTab } from 'redux/schemas/models/video-practice';
import NvCommentRow from 'shared/components/nv-comment-row';
import { getScenario } from 'redux/selectors/video-practice';
import { Post } from 'redux/schemas/api/video-practice';
import PracticeCommentForm from './practice-comment-form';
import { PracticeSubmissionContext } from '../utils';

type PracticeCommentProps = {
  commentId: number
  onUpdate?: (comment: any) => Promise<boolean>
  onDelete?: (isUserFirstReview: boolean) => void
  onTimestampClick: () => void
};

const PracticeCommentRow = ({
  commentId,
  onUpdate,
  onDelete,
  onTimestampClick,
}: PracticeCommentProps) => {
  const [{
    submissionId, scenarioId, submissionIds,
  }] = useContext(PracticeSubmissionContext);

  const comment = useSelector<RootState, PracticeSubmissionComment>((state) => state.models.comments[commentId]);
  const practiceFeedbackCriteriaId = useSelector(state => state.app.videoPracticeFeedback?.practiceFeedbackCriteriaId);
  const {
    myPracticeVisitedAt, hasCourseAdmin,
  } = useSelector((state) => getScenario(state, scenarioId));
  const videoPracticeSubmissions = useSelector((state) => state.app.videoPracticeSubmissions);
  const scenarioSubmissions = pick(videoPracticeSubmissions, (value, key) => contains(submissionIds, parseInt(key, 10)));
  const allSubmissionCommentsLoaded = every(scenarioSubmissions, (submission) => submission.commentsOnceLoaded === true);
  const filteredComments = useSelector((state) => state.app.practiceRoom.filteredComments?.[submissionId]);
  const { selectedTab } = useSelector((state) => state.app.practiceRoom.params);

  const [isNewReply, setNewReply] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const dispatch = useAppDispatch();

  const onUpdateComment = useCallback(async (data: Post) => {
    const isUpdated = await onUpdate(data);
    if (isUpdated) {
      setIsEditing(false);
      return true;
    }
    return false;
  }, [onUpdate]);

  if (isEditing) {
    return (
      <PracticeCommentForm
        isEdit
        content={comment.body}
        onCancel={() => setIsEditing(false)}
        onUpdate={onUpdateComment}
      />
    );
  }

  return (
    comment && (
      <div>
        <NvCommentRow
          key={commentId}
          commentId={commentId}
          isHighlighted={filteredComments?.includes(commentId)}
          // Filtered comments only contain one comment, so play it on load
          playOnLoad={filteredComments?.includes(commentId) && filteredComments.length === 1}
          onLike={() => dispatch(likeComment({ commentId, practiceFeedbackCriteriaId }))}
          onUndoLike={() => dispatch(undoLikeComment({ commentId, practiceFeedbackCriteriaId }))}
          getLikersInfo={() => dispatch(getCommentLikers({ commentId }))}
          canDelete={hasCourseAdmin}
          onDelete={onDelete}
          onTimestampClick={onTimestampClick}
          lastVisitedAt={selectedTab === PracticeRoomTab.MY_PRACTICE ? myPracticeVisitedAt : ''}
          allSubmissionCommentsLoaded={allSubmissionCommentsLoaded}
          onReply={() => setNewReply(true)}
          onEdit={() => setIsEditing(true)}
        />
        {isNewReply && (
          <PracticeCommentForm
            isReply
            mentionedUser={comment.user}
            afterSubmit={() => setNewReply(false)}
            onCancel={() => setNewReply(false)}
          />
        )}
      </div>
    ));
};

export default PracticeCommentRow;
