import { css } from '@emotion/react';
import ClickableContainer from 'components/clickable-container';
import NvIcon from 'shared/components/nv-icon';
import { gray6, gray7 } from 'styles/global_defaults/colors';

/** Special video practice gallery card used at end of gallery for extra info
 * "Go to practice room & explore more" (clickable - leads to practice room)
 * "Please come back later to see more submissions"
*/

type VideoPracticeSpecialGalleryCardProps = {
  title: string;
  onClick?: (e: any) => void;
  pendoTagName?: string;
  header?: string;
};

const VideoPracticeSpecialGalleryCard = ({
  title,
  onClick,
  pendoTagName,
  header,
}: VideoPracticeSpecialGalleryCardProps) => {
  const styles = css`
    width: 240px;
    min-width: 208px;
    max-width: 240px;
    height: 240px;
    -webkit-box-shadow: 0px 10px 30px 0px ${gray6};
    box-shadow: 0px 10px 30px 0px ${gray6};
    background-color: ${gray7};
    cursor: default;
    ${onClick && css`cursor: pointer;`}

    .header {
      top: -20px;
      left: 0;
    }
  `;

  return (
    <ClickableContainer
      css={styles}
      onClick={onClick}
      className='p-4 d-flex flex-column justify-content-center align-items-center position-relative'
      pendo-tag-name={pendoTagName}
    >
      <div className='header position-absolute label text-gray-1'>{header}</div>
      <NvIcon icon='media-practice' size='large' className={`${onClick ? 'text-primary' : 'text-gray-3'}`} />
      <div className={`m-2 text-small text-center ${onClick ? 'text-primary' : 'text-gray-2'}`}>{title}</div>
    </ClickableContainer>
  );
};

export default VideoPracticeSpecialGalleryCard;
