import t from 'react-translate';
import prodPathReplace from 'shared/prod-path-rewrite';

// Styles
import { css } from '@emotion/react';
import { headerFontSize, headerLargeLineHeight, semiBoldFontWeight } from 'styles/global_defaults/fonts';
import { animationSize, quarterSpacing } from 'styles/global_defaults/scaffolding';
import { gray8, headerColor } from 'styles/global_defaults/colors';
import { SkillTag } from 'redux/schemas/models/skill-tag';

const styles = css`
  .image-badge {
    height: ${animationSize}px;
    width: ${animationSize}px;
  }
  .practiced-skills {
    font-size: ${headerFontSize}px;
    color: ${headerColor};
    line-height: ${headerLargeLineHeight}px;
  }
  .skill-tags {
    .skill-tag-name {
      font-weight: ${semiBoldFontWeight};
      .separator {
        display: inline-block;
        width: ${quarterSpacing}px;
        height: ${quarterSpacing}px;
        background-color: ${gray8};
        border-radius: 50%;
        margin: 3px ${quarterSpacing}px;
      }
    }
  }
`;

type NvPracticedSkillsProps = {
  skillTags: SkillTag[];
};

const NvPracticedSkills = ({ skillTags }: NvPracticedSkillsProps) => {
  const orderedSkillTags = skillTags?.sort((skillA, skillB) => skillA.name.localeCompare(skillB.name));
  const tags = orderedSkillTags?.map((skillTag, index) => (
    <span
      key={skillTag.id}
      className='skill-tag-name'
    >
      {skillTag.name}
      {index !== skillTags.length - 1 && (
        <div className='separator' />
      )}
    </span>
  ));
  return (
    <div css={styles}>
      <img
        className='image-badge mb-3'
        src={prodPathReplace('images/skills-practiced.gif')}
        alt={t.LEARNING_JOURNEYS.COURSE_HOME.MODAL.CONGRATULATIONS()}
      />
      <div className='good-job page-title'>
        {t.INSTITUTIONS.SKILL_TAGS.SKILL_VIEW_FOR_LEARNERS.GOOD_JOB()}
      </div>
      <div className='practiced-skills bold mb-2'>
        {t.INSTITUTIONS.SKILL_TAGS.SKILL_VIEW_FOR_LEARNERS.PRACTICED_SKILLS(tags.length)}
      </div>
      <div className='skill-tags'>{tags}</div>
    </div>
  );
};

export default NvPracticedSkills;
