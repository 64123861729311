import { css } from '@emotion/react';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import t from 'react-translate';
import { tripleSpacing } from 'styles/global_defaults/scaffolding';
import { each, isEmpty, pluck } from 'underscore';
import moment from 'moment';
import { AngularServicesContext } from 'react-app';

// redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { CourseAliases } from 'redux/schemas/models/course';
import { getCourseAliases } from 'redux/selectors/course';
import { fetchCollectionsList } from 'redux/actions/collections';
import { linkCollectionLessonsToCourse } from 'redux/actions/lecture-pages';

// components
import { Button } from 'react-bootstrap';
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';
import LoadingPlaceholder, { LoaderType } from 'shared/components/loading-placeholder';
import { collectionSortKeys } from 'content-library/components/collection-list';
import { LecturePageMode } from 'lecture_pages/components';
import { useLecturePageLink, useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { LinkLessonsModalProps } from './link-lessons-modal';
import CollectionRow from './collection-row';

export type Props = Omit<LinkLessonsModalProps, 'show'>;

const styles = css`
  .content {
    // 370px = Modal padding(100) + Modal header(60) + Description(70) + Button with spacing(140)
    max-height: calc(100vh - 370px);
    overflow-y: auto;
  }

  .button-container {
    margin-top: ${tripleSpacing}px;
  }
`;

export const LinkLessonsContext = createContext(null);
export const PAGE_SIZE = 20;

const LinkLessonsModalBody = (props: Props) => {
  const aliases: CourseAliases = useSelector((state) => getCourseAliases(state));
  const hasMore = useSelector((state) => state.app.collection.hasMoreInCollectionSearch);
  const params = useLecturePageParams();
  const lectureSections = useSelector(state => state.models.lectureSections);

  const lectureLink = useLecturePageLink();
  const dispatch = useAppDispatch();
  const { TimelinesManager } = useContext(AngularServicesContext);

  const [saving, setSaving] = useState<boolean>(false);
  const [selectedLessonIds, setSelectedLessonIds] = useState<number[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [collectionCatalogIds, setCollectionCatalogIds] = useState<string[]>([]);

  const scrollingContentRef = useRef<HTMLDivElement>(null);
  const SCROLL_PADDING = 20;
  const isScrolledToBottom = useInfiniteScroll(
    scrollingContentRef.current,
    SCROLL_PADDING,
  );

  // For infinite scrolling
  useEffect(() => {
    if (isScrolledToBottom && hasMore && !loading) {
      setLoading(true);
      setPageNo(pageNo + 1);
    }
  }, [hasMore, isScrolledToBottom, pageNo, loading]);

  const onFetchCollections = useCallback((): Promise<any> => new Promise((resolve) => {
    const payload = {
      pageIndex: pageNo,
      pageSize: PAGE_SIZE,
      sorting: [`${collectionSortKeys[0]}&order=asc`],
      includeEmpty: false,
    };

    dispatch(fetchCollectionsList(payload)).then((res) => {
      if (!isEmpty(res?.error)) {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.ERROR_SOMETHING_WRONG(),
        }));
        resolve([]);
      }

      resolve(pluck(res.payload.response ?? [], 'catalogId'));
    });
  }), [dispatch, pageNo]);

  useEffect(() => {
    if (loading) {
      onFetchCollections()
        .then((response) => {
          setLoading(false);
          setIsLoaded(true);
          setCollectionCatalogIds((prevList) => ([...prevList, ...response ?? []]));
        });
    }
  }, [onFetchCollections, loading]);

  const onLessonCheckboxToggle = (e) => {
    const { value, checked } = e.target;
    const CurrentIds = [...selectedLessonIds];

    if (checked) {
      CurrentIds.push(+value);
    } else {
      const index = CurrentIds.indexOf(+value);
      CurrentIds.splice(index, 1);
    }

    setSelectedLessonIds(CurrentIds);
  };

  const onSubmit = () => {
    setSaving(true);
    const lecturePages = [];
    const releaseDate = moment().add(1, 'months').endOf('day').toISOString();
    const linkedLectureSection = params.lecturePageId ? lectureSections[props.sectionId] : TimelinesManager.getSection(props.sectionId);
    const startingIndex = linkedLectureSection.lecturePages.length;

    each((selectedLessonIds), (id, i) => {
      lecturePages.push({
        id,
        index: startingIndex + i,
        releaseDate,
      });
    });

    const payload = {
      courseId: props.courseId,
      lectureSectionId: props.sectionId,
      lecturePages,
      startingIndex,
    };

    dispatch(linkCollectionLessonsToCourse(payload)).then((response) => {
      if (isEmpty(response?.error)) {
        if (params.lecturePageId) {
          lectureLink({
            ...params.lecturePageParams,
            mode: LecturePageMode.EDIT,
            lecturePageId: response.payload.filter((link) => link.sourceId === selectedLessonIds[0])[0].targetId,
          });
        } else {
          TimelinesManager.linkCollectionLessonsToCourse(response.payload, props.sectionId);
          props.onClose();
        }

        dispatch(addAlertMessage({
          type: AlertMessageType.SUCCESS,
          message: t.TIMELINE.LESSON_LINK_MODAL.SUCCESS_ALERT.MESSAGE({ ...aliases.lectureAliases, ...aliases.courseAliases }),
        }));
      } else {
        props.onClose();
      }
    });
  };

  return (
    <LinkLessonsContext.Provider value={{ selectedLessonIds, onLessonCheckboxToggle }}>
      <div className='px-6 pt-4 pb-6' css={styles}>
        <div className='d-flex text-gray-2 text-regular pt-1 pb-4'>
          {t.TIMELINE.LESSON_LINK_MODAL.DESCRIPTION({ ...aliases.lectureAliases, ...aliases.courseAliases })}
        </div>
        <div className='content' ref={scrollingContentRef}>
          {collectionCatalogIds?.map((catalogId) => (
            <CollectionRow
              key={catalogId}
              catalogId={catalogId}
            />
          ))}
          {loading && (
            <LoadingPlaceholder loaderType={LoaderType.HALF} className='p-4' />
          )}
          {isLoaded && !hasMore && collectionCatalogIds.length > 0 && (
            <div className='d-flex align-items-center gray-2 text-small mt-2'>
              {t.SEARCH.ALL_LOADED()}
            </div>
          )}
        </div>
        <div className='button-container d-flex justify-content-center'>
          <Button
            variant='secondary'
            className='font-weight-bold'
            onClick={props.onClose}
          >
            {t.FORM.CANCEL()}
          </Button>
          <Button
            className='ml-2 font-weight-bold'
            variant='primary'
            onClick={onSubmit}
            disabled={selectedLessonIds.length === 0 || saving}
          >
            {t.FORM[saving ? 'ADDING' : 'ADD']()}
          </Button>
        </div>
      </div>
    </LinkLessonsContext.Provider>
  );
};

export default LinkLessonsModalBody;

