import ClickableContainer from 'components/clickable-container';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { getSkillTag, ratingsSorted } from 'redux/selectors/skills-feedback';
import NvIcon from 'shared/components/nv-icon';
import NvLoadingPlaceholder, { PlaceholderType, LineSize, LineHeight } from 'shared/components/nv-loading-placeholder';
import NvStarRating from 'shared/components/nv-star-rating';
import NvTooltip from 'shared/components/nv-tooltip';
import { last, isFinite } from 'underscore';

import ViewFeedback from './view-feedback';

export interface RatingSummaryProps {
  submissionId: number
  totalRatings: number
  skillRating: number
  skillTagId: number
  skillTaggingId: number
  onGetSkillsFeedback: (afterId?: number) => void
  onDeleteFeedback: (id: number) => void
  canDelete?: boolean
  ownerKey: string
}
export const FEEDBACK_PAGE_SIZE = 5;

const RatingSummary = ({
  submissionId,
  skillTaggingId,
  totalRatings,
  skillRating,
  onGetSkillsFeedback,
  skillTagId,
  onDeleteFeedback,
  canDelete,
  ownerKey,
}: RatingSummaryProps) => {
  const skillTag = useSelector(state => getSkillTag(state, skillTaggingId));
  const skillRatings = useSelector(state => state.app[ownerKey]?.[submissionId]?.skillTaggings?.[skillTaggingId]);
  // sorted ids in order to display the recent on top always.
  const ratings = useSelector(state => ratingsSorted(state, ownerKey, submissionId, skillTaggingId));

  const [showFeedback, setShowFeedback] = useState(false);
  const [viewLess, setViewLess] = useState(false);

  useEffect(() => {
    if (showFeedback && !skillRatings?.loading
      && (
        (!skillRatings?.onceLoaded && totalRatings > 0)
        || (skillRatings?.onceLoaded && totalRatings !== skillRatings?.ratings?.length)
      )
    ) {
      onGetSkillsFeedback();
    }
  }, [showFeedback]);

  const onViewMore = () => {
    if (skillRatings?.ratings.length === skillRatings.totalRecords) {
      setViewLess(false);
    } else {
      onGetSkillsFeedback(last(ratings));
    }
  };
  const ratingsToDisplay = viewLess ? ratings?.slice(0, FEEDBACK_PAGE_SIZE) : ratings;
  let ratingRounded = Number(skillRating) % 1 !== 0 ? Number(skillRating).toFixed(1) : skillRating;
  ratingRounded = (Number(ratingRounded) * 100) / 100; // To convert 3.0 to 3

  return (
    <div className='d-flex flex-column'>
      <div className='d-none d-md-flex justify-content-between mb-4'>
        <div className='tag-badge bg-gray-7 py-2 px-3 text-black text-small font-weight-bold ellipsis'>
          {skillTag?.name}
        </div>
        <div className='d-flex align-items-center'>
          <div className='text-body-large gray-2 mr-4'>
            {t.PRACTICE_ROOM.SKILL_FEEDBACK.TOTAL_RATINGS(totalRatings)}
          </div>
          <ClickableContainer
            onClick={() => {
              setShowFeedback(show => !show);
            }}
          >
            <NvTooltip
              text={t.PRACTICE_ROOM.SKILL_FEEDBACK.RATING_TOOLTIP(ratingRounded.toString(), '5')}
              placement='top'
            >
              <div>
                <NvStarRating readonly ratedValue={ratingRounded} starSize='medium' />
              </div>
            </NvTooltip>
            <NvIcon
              size='xss-smallest'
              icon={showFeedback ? 'arrow-up' : 'arrow-down'}
              className={`${!totalRatings ? 'invisible' : ''} d-flex align-items-center ml-4 gray-2`}
            />
          </ClickableContainer>
        </div>
      </div>
      <div className='d-md-none d-flex mb-4 flex-column'>
        <div className='w-100 d-flex justify-content-between align-items-center mb-2'>
          <NvTooltip
            text={skillTag?.name}
            preventOverflow={false}
          >
            <div className='tag-badge bg-gray-7 py-2 px-3 text-black text-small font-weight-bold ellipsis'>
              {skillTag?.name}
            </div>
          </NvTooltip>
          <div>
            <ClickableContainer
              onClick={() => {
                setShowFeedback(show => !show);
              }}
            >
              <NvIcon
                size='xss-smallest'
                icon={showFeedback ? 'arrow-up' : 'arrow-down'}
                className={`${!totalRatings ? 'invisible' : ''} d-flex align-items-center ml-4 gray-2`}
              />
            </ClickableContainer>
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <ClickableContainer
            onClick={() => {
              setShowFeedback(show => !show);
            }}
            className='d-flex align-items-center'
          >
            <span className='text-yellow'>
              <NvIcon
                icon='highlight'
                size='smallest'
              />
            </span>
            <div className='text-body-large gray-2 ml-1'>{ratingRounded.toString()}</div>
          </ClickableContainer>
          <div className='text-body-large gray-2 ml-4'>
            {t.PRACTICE_ROOM.SKILL_FEEDBACK.TOTAL_RATINGS(totalRatings)}
          </div>
        </div>
      </div>
      {showFeedback && (
        <div className='mb-6'>
          {skillRatings?.ratings?.length > 0 && (
            <div>
              {ratingsToDisplay?.map(rating => (
                <ViewFeedback
                  key={rating}
                  ratingId={rating}
                  onDeleteFeedback={onDeleteFeedback}
                  canDelete={canDelete}
                />
              ))}
              {((skillRatings?.onceLoaded
                && skillRatings?.hasMore
                && !skillRatings.loading
              ) || viewLess) && (
                <ClickableContainer
                  className='text-primary justify-content-center mb-6'
                  onClick={() => onViewMore()}
                >{t.SHARED.VIEW_MORE()}
                </ClickableContainer>
              )}
              {!skillRatings?.loading
              && ratingsToDisplay.length > FEEDBACK_PAGE_SIZE
              && skillRatings?.totalRecords === skillRatings?.ratings?.length
              && (
                <ClickableContainer
                  className='text-primary justify-content-center mb-6'
                  onClick={() => setViewLess(true)}
                >{t.SHARED.VIEW_LESS()}
                </ClickableContainer>
              )}
            </div>
          )}
          {skillRatings?.loading && (
            <React.Fragment>
              <NvLoadingPlaceholder type={PlaceholderType.LINE} size={LineSize.FULL} height={LineHeight.QUARTER_SPACING} />
              <NvLoadingPlaceholder type={PlaceholderType.LINE} size={LineSize.FULL} height={LineHeight.QUARTER_SPACING} />
              <NvLoadingPlaceholder type={PlaceholderType.LINE} size={LineSize.MEDIUM} height={LineHeight.QUARTER_SPACING} />
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default RatingSummary;
