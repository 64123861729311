import React from 'react';

const OfferingCardProgressContext = React.createContext(null);

type Props = {
  progress: string,
  children: React.ReactNode,
};

const OfferingCardProgessContextProvider = (props: Props) => (
  <OfferingCardProgressContext.Provider value={props.progress}>
    {props.children}
  </OfferingCardProgressContext.Provider>
);

export const useOfferingCardProgressConext = () => React.useContext(OfferingCardProgressContext);

export default OfferingCardProgessContextProvider;
