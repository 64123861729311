/* eslint-disable no-plusplus */
import React from 'react';
import * as yup from 'yup';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { AngularServicesContext } from 'react-app';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import NvIcon from 'shared/components/nv-icon';
import { PopoversContainerContext } from 'shared/react-utils';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { saveOrgLevelMetadataSettings } from 'redux/actions/org-level-metadata';
import { getMetadataFilters } from 'redux/actions/institutions';
import {
  getFormOrgLevelMetadataSettings as orgLevelMetadataSettingsSelector,
} from 'redux/selectors/org-level-metadata';
import { Container, Draggable } from 'react-smooth-dnd';
import { NvAddItemForm } from 'shared/components/nv-add-item';
import {
  threeQuartersSpacing,
  largeSpacing,
  tripleSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import {
  FormOrgLevelMetadataSettings,
  MetadataFieldType,
  MetadataValueType,
} from 'redux/schemas/models/org-level-metadata-settings';
import MetadataSection from './metadata-section';
import { config } from '../../../config/pendo.config.json';

type FormProps = {
  onCancel: () => void,
};

type MetadataFieldListProps = {
  metadataFields: MetadataFieldType[];
  addMetadataValue: (metadata: string, value: string) => boolean,
  deleteMetadataSection: (metadata: string) => void,
  deleteMetadataValue: (metadata: string, value: string) => void,
  markMetadataAsRequired: (metadata: string, required: boolean) => void,
  setEditingMetadataName: (metadataId: number, status: boolean) => void,
  updateMetadataName: (oldName: string, newName: string) => boolean,
  updateMetadataFieldIndex: (metadataFields: MetadataFieldType[]) => void,
  existingMetadataField: (metadata: string) => MetadataFieldType,
  existingMetadataValue: (metadata: string, value: string) => MetadataValueType,
};

/**
 * Org Level Metadata settings form
 */
const Form = React.forwardRef<any, FormProps>((props, ref) => {
  // These are the only fields that need validation for this configuration modal
  const validationSchemaRef = React.useRef(yup.object().shape({
    metadataFields: yup.array().of(
      yup.object({
        id: yup.number(),
        index: yup.number(),
        name: yup.string(),
        required: yup.boolean(),
        metadataValues: yup.array().of(
          yup.object({
            id: yup.number(),
            value: yup.string(),
          }),
        ).min(1).required(t.VALIDATION.REQUIRED()),
      }),
    ),
  }));
  const dispatch = useAppDispatch();
  const containerRef = React.useRef<HTMLDivElement>();
  const { $state, $scope } = React.useContext(AngularServicesContext);
  const orgLevelMetadataSettings = useSelector(orgLevelMetadataSettingsSelector);
  const [metadataFieldFormOpen, setMetadataFieldFormOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [editingMetadataNames, setEditingMetadataNames] = React.useState([]);

  const updateFields = (result) => {
    reset({ metadataFields: [...result] });
  };

  React.useEffect(() => {
    dispatch(getMetadataFilters({ callback: updateFields }));
  }, []);

  const setEditingMetadataName = (metadataId: number, state: boolean) => {
    if (state) {
      setEditingMetadataNames([...editingMetadataNames, metadataId]);
    } else {
      setEditingMetadataNames(editingMetadataNames.filter(id => id !== metadataId));
    }
  };

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchemaRef.current),
    defaultValues: {
      metadataFields: orgLevelMetadataSettings.metadataFields.sort((a, b) => a.index - b.index),
    },
  });

  React.useImperativeHandle(ref, () => form);

  const {
    reset,
    control,
    setValue,
    formState,
    getValues,
    handleSubmit,
  } = form;

  const styles = css`
    padding: 0 60px;
    .message {
      margin-bottom: ${largeSpacing}px;
    }

    .add-field {
      display: flex;
      align-items: center;
      .add-field-icon {
        padding-right: ${threeQuartersSpacing}px;
      }
    }

    .button-bar {
      margin-top: ${tripleSpacing}px;
    }

    .metadata-field-form .add-form .form-container {
      .edit-input {
        width:100%;
      }
      .nv-text-input {
        width: 100%;
      }
    }
  `;

  const handleAddMetadataField = () => {
    setMetadataFieldFormOpen(!metadataFieldFormOpen);
  };

  const orderMetadataIndexes = (metadataFields: MetadataFieldType[]) => {
    metadataFields.forEach((metadataField, index) => {
      metadataField.index = index;
    });
    return metadataFields;
  };

  const handleUpdateClick: SubmitHandler<FormOrgLevelMetadataSettings> = (formData) => {
    setSaving(true);
    const handleConfirm = () => dispatch(
      saveOrgLevelMetadataSettings({
        institutionId: $state.params.institutionId,
        form: {
          metadataFields: formData.metadataFields,
        },
      }),
    ).then((response: any) => {
      setSaving(false);
      if (response.error) {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.FORM.ERROR_SOMETHING_WRONG(),
        }));
      } else {
        reset();
        dispatch(addAlertMessage({
          type: AlertMessageType.SUCCESS,
          header: t.FORM.SUCCESS_BANG(),
          message: t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SUCCESS_MESSAGE(),
        }));
        props.onCancel();
      }
    });
    handleConfirm();
  };

  const { errors, isValid, isDirty } = formState;

  React.useEffect(() => {
    const deRegisterStateChangeStart = $scope.StateManager.registerStateChangeStart(
      () => isDirty,
      'shared/templates/modal-navigate-away-unsaved-changes.html',
      'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY_CHANGES',
    );

    return () => {
      deRegisterStateChangeStart();
    };
  }, [$scope.StateManager, isDirty]);

  const deleteMetadataSection = (metadataName: string) => {
    const { metadataFields } = getValues();
    let actualIndex = -1;
    for (let fieldIndex = 0; fieldIndex < metadataFields.length; fieldIndex++) {
      if (metadataFields[fieldIndex].name === metadataName) {
        actualIndex = fieldIndex;
      }
    }
    metadataFields.splice(actualIndex, 1);
    updateMetadataFieldIndex(metadataFields);
  };

  const existingMetadataField = (metadata: string) => {
    const { metadataFields } = getValues();
    return metadataFields.find(field => (field.name === metadata && !!field.id));
  };

  const existingMetadataValue = (metadata: string, value: string) => {
    const foundField = existingMetadataField(metadata);
    if (foundField && !!value) {
      return foundField.metadataValues.find(element => (element.value === value && !!element.id));
    }
    return null;
  };

  const deleteMetadataValue = (metadata: string, value: string) => {
    const { metadataFields } = getValues();
    const metadataElement = metadataFields.find((element) => (element.name === metadata));
    const values = metadataElement?.metadataValues?.filter(element => (element.value !== value));
    metadataElement.metadataValues = values;
    setValue(
      'metadataFields',
      [...metadataFields],
      { shouldValidate: true, shouldDirty: true },
    );
  };

  const addMetadataValue = (metadata: string, value: string) => {
    value = value.trim();
    const { metadataFields } = getValues();
    const metadataElement = metadataFields.find((element) => (element.name === metadata));
    const existingValue = metadataElement?.metadataValues?.find((element) => (element.value.toLowerCase() === value.toLowerCase()));
    if (existingValue || value === '') {
      return false;
    }
    metadataElement.metadataValues = [...metadataElement.metadataValues, { value }];
    setValue(
      'metadataFields',
      [...metadataFields],
      { shouldValidate: true, shouldDirty: true },
    );
    return true;
  };

  const addMetadataFieldEvent = (metadata: string) => {
    metadata = metadata.trim();
    const { metadataFields } = getValues();
    const existingMetadata = metadataFields.find((element) => (element.name.toLowerCase() === metadata.toLowerCase()));
    if (existingMetadata || metadata === '') {
      return false;
    }
    const newMetadata = {
      name: metadata,
      required: false,
      index: metadataFields.length,
      metadataValues: [],
    };
    setMetadataFieldFormOpen(false);
    setValue(
      'metadataFields',
      [...metadataFields, newMetadata],
      { shouldValidate: true, shouldDirty: true },
    );
    return true;
  };

  const markMetadataAsRequired = (metadata: string, required: boolean) => {
    const { metadataFields } = getValues();
    const existingMetadata = metadataFields.find((element) => (element.name === metadata));
    if (existingMetadata) {
      existingMetadata.required = required;
    }
    setValue(
      'metadataFields',
      [...metadataFields],
      { shouldValidate: true, shouldDirty: true },
    );
  };

  const updateMetadataName = (oldName: string, newName: string) => {
    newName = newName.trim();
    if (newName === '') {
      return false;
    }
    if (oldName === newName) {
      return true;
    }
    const { metadataFields } = getValues();
    const existingMetadata = metadataFields.find((element) => (element.name === oldName));
    const existingNewMetadata = metadataFields.find((element) => (element.name === newName));
    if (!existingMetadata || existingNewMetadata) {
      return false;
    }
    existingMetadata.name = newName;
    setMetadataFieldFormOpen(false);
    setValue(
      'metadataFields',
      [...metadataFields],
      { shouldValidate: true, shouldDirty: true },
    );
    return true;
  };

  const updateMetadataFieldIndex = (metadataFields) => {
    orderMetadataIndexes(metadataFields);
    setValue(
      'metadataFields',
      [...metadataFields],
      { shouldValidate: true, shouldDirty: true },
    );
  };

  const { metadataFields = [] } = getValues();
  return (
    <PopoversContainerContext.Provider value={containerRef.current}>
      <div css={styles} ref={containerRef}>
        <div className='text-body message'>
          {t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.MESSAGE()}
        </div>
        <Controller
          name='metadataFields'
          control={control}
          render={() => (
            <div className='list-of-metadata'>
              <MetadataFieldList
                addMetadataValue={addMetadataValue}
                deleteMetadataSection={deleteMetadataSection}
                deleteMetadataValue={deleteMetadataValue}
                markMetadataAsRequired={markMetadataAsRequired}
                metadataFields={metadataFields}
                setEditingMetadataName={setEditingMetadataName}
                updateMetadataName={updateMetadataName}
                updateMetadataFieldIndex={updateMetadataFieldIndex}
                existingMetadataField={existingMetadataField}
                existingMetadataValue={existingMetadataValue}
              />
            </div>
          )}
        />
        {metadataFieldFormOpen && (
          <div className='metadata-field-form'>
            <span className='text-gray-3 text-small'>{t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.ADD_FIELD_LABEL()}</span>
            <NvAddItemForm
              errorMessage={{ message: t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.EXISTING_METADATA_NAME() }}
              onSave={(value) => addMetadataFieldEvent(value)}
              saveBtnText={t.FORM.ADD()}
              onCancel={() => setMetadataFieldFormOpen(false)}
              placeholder={t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.TYPE_HERE()}
            />
          </div>
        )}
        <button
          type='button'
          onClick={handleAddMetadataField}
          className='btn btn-link hovered text-regular add-field'
        >
          <NvIcon icon='add' size='small' className='add-field-icon' />
          {' '}
          {t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.ADD_FIELD()}
        </button>
        <div className='button-bar'>
          <Button onClick={props.onCancel} variant='secondary'>
            {t.FORM.CANCEL()}
          </Button>
          <Button
            disabled={!isDirty || !isValid || saving || editingMetadataNames.length > 0}
            onClick={handleSubmit(handleUpdateClick)}
            pendo-tag-name={config.pendo.settings.metadataSave}
          >
            {saving ? t.FORM.SAVING() : t.FORM.SAVE()}
          </Button>
        </div>
      </div>
    </PopoversContainerContext.Provider>
  );
});

const MetadataFieldList = (props: MetadataFieldListProps) => {
  const [items, setItems] = React.useState(props.metadataFields);

  React.useEffect(() => {
    if (!similarFields(items, props.metadataFields)) {
      setItems(props.metadataFields);
    }
  }, [props.metadataFields]);

  const editingMetadataName = (metadataId: number, status: boolean) => {
    props.setEditingMetadataName(metadataId, status);
  };

  const applyDrag = (arr, dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if ((removedIndex === null && addedIndex === null) || removedIndex === addedIndex) return arr;
    const result = [...arr];
    let itemToAdd = payload;
    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }
    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
    props.updateMetadataFieldIndex(result);
    return result;
  };

  const similarFields = (oldItems, newItems) => {
    let equal = oldItems.length === newItems.lengh;
    if (equal) {
      for (let index = 0; index < oldItems.length && equal; index++) {
        equal = equal && (oldItems[index].name === newItems[index].name);
      }
    }
    return equal;
  };

  return (
    <div>
      <div className='simple-page'>
        <Container dragHandleSelector='.drag-handle' onDrop={e => setItems(applyDrag(items, e))}>
          {items.map((metadata) => (
            <Draggable key={metadata.id}>
              <MetadataSection
                metadata={metadata}
                addMetadataValue={props.addMetadataValue}
                deleteMetadataSection={props.deleteMetadataSection}
                deleteMetadataValue={props.deleteMetadataValue}
                editingMetadataName={editingMetadataName}
                markMetadataAsRequired={props.markMetadataAsRequired}
                updateMetadataName={props.updateMetadataName}
                dragHandleSelectorClassName='drag-handle'
                existingMetadataField={props.existingMetadataField}
                existingMetadataValue={props.existingMetadataValue}
              />
            </Draggable>
          ))}
        </Container>
      </div>
    </div>
  );
};


type Props = {
  forwardShowModal: (func: () => void) => void,
};

const OrgLevelMetadataSettings = (props: Props) => {
  const dispatch = useAppDispatch();
  const formRef = React.useRef<any>();
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    props.forwardShowModal(() => setShow(true));
  }, []);

  const styles = css`
    padding: ${standardSpacing}px;
  `;

  const handleModalClose = () => {
    const close = () => setShow(false);

    if (formRef.current.formState.isDirty) {
      dispatch(openConfirmationDialog({
        onConfirm: close,
        cancelText: t.FORM.CANCEL(),
        confirmText: t.FORM.DISCARD_CHANGES(),
        title: t.FORM.UNSAVED_CHANGES.NAVIGATE_AWAY_CHANGES(),
      }));
    } else {
      close();
    }
  };

  return (
    <NvModal
      fullHeight={false}
      show={show}
      width={800}
      type={ModalType.DYNAMIC}
      onClose={handleModalClose}
      header={t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.TITLE()}
      body={(
        <div css={styles}>
          <Form ref={formRef} onCancel={handleModalClose} />
        </div>
      )}
    />
  );
};

export default OrgLevelMetadataSettings;
