const TABLE_PAGE_SIZE = 30;

export default {
  bindings: {
    // Array of state params to be observed on state change that trigger search updates
    queryParams: '<',
    // Bool, whether or not to use the word 'search' in results header text copy
    useSearchCopy: '<?',
    // Function called to query for table data when angular-ui-scroll decides more should be requested.
    // Parameters are (pageIndex: number, pageSize: number)
    loadFunc: '&',
    // Whether to show a 'clear serach' style link in the search results header
    showCta: '<?',
    // Function to call to clear the search input
    clearSearch: '&',
    // A string for the query to be observed for putting the search query
    // in the results header
    queryForHeader: '<?',
    // Translation key for 'search loading' state
    searchLoadingHeaderKey: '@?',
    // Translation key for the 'loading' state outside of search
    nonSearchLoadingHeaderKey: '@?',
  },
  replace: true,
  transclude: {
    'result-row': 'resultRow', // template for a single row in the results data table
    'loading-row': '?loadingRow', // template for the loading state of a row in the results data table
  },
  controller: function ctrl(
    _,
    $uibPosition,
    $scope,
    $element,
    $stateParams,
    nvCurrentPage,
  ) {
'ngInject';
    // Tracks progress of initial load
    this.isLoading = true;
    // Tracks progress of subsequent data loads.
    this.isLoadingMore = false;
    this.resultsCount = 0;
    this.data = [];

    $scope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
      // Reload the data set if the state change includes one of the watched query params
      if (_.some(toParams, (param) => this.queryParams.includes(param)) && nvCurrentPage) {
        // Set via the `adapter` attribute on ui-scroll in the template
        this.uiScrollAdapter.reload();
      }
    });

    /**
     * @param {number} index - This is 1-based
     * @param {number} count
     * @param {function(Array):void} success */
    const loadData = (index, count, success) => {
      let startIndex = index - 1;
      let endIndex = startIndex + count;

      if (startIndex < 0) {
        startIndex = 0;
      }

      if (endIndex < 0) {
        endIndex = 0;
      }

      if (endIndex <= this.data.length) {
        success(this.data.slice(startIndex, endIndex));
        return;
      }

      const pageIndex = Math.ceil(endIndex / TABLE_PAGE_SIZE);

      this.isLoadingMore = true;

      this.loadFunc()(pageIndex, TABLE_PAGE_SIZE).then((result) => {
        this.resultsCount = result.count;
        this.isLoading = false;
        this.isLoadingMore = false;

        // TODO: This cannot rely on 'result.users' existing if this component is to be general purpose
        this.data.splice((pageIndex - 1) * TABLE_PAGE_SIZE, result.users.length, ...result.users);
        success(this.data.slice(startIndex, endIndex));
      });
    };

    this.getDataForInfiniteScroll = {
      get: loadData.bind(this),
    };

    this.getQuery = () => $stateParams[this.queryForHeader];
  },
  controllerAs: 'vm',
  templateUrl: 'shared/templates/nv-search-panel.html',
};
