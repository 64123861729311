import React from 'react';

import moment from 'moment';

import { useSelector } from 'react-redux';
import t from 'react-translate';

// schemas
import { RootState } from 'redux/schemas';
import { ActivityType } from 'redux/schemas/models/activity';
import { ComponentKeyPluralized } from 'redux/schemas/models/lecture-component';

// actions
import { setActivityExpandedInAutomatedCommunications } from 'redux/actions/course-communications';

// selectors
import { isActivityExpandedInAutomatedCommunications } from 'redux/selectors/course-communications';

// components
import NvIcon from 'shared/components/nv-icon';
import NvStackedTab from 'shared/components/nv-stacked-tab';

import CommunicationCreateNewDropdown from 'communications/course_communications/components/communication-create-new-dropdown';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';
import { useAppDispatch } from 'redux/store';
import NvTooltip from 'shared/components/nv-tooltip';
import { VideoPracticeFeedbackActivity } from 'redux/schemas/models/video-practice-feedback';
import { hasVideoPracticeSkillsFeedbackGotCommunicationError } from 'redux/selectors/timeline';
import { FeedbackCategory } from 'redux/schemas/models/course-communication';

type VideoPracticeSkillsFeedbackLectureComponentProps = {
  exerciseSkillsRatingActivity: number,
};

const VideoPracticeSkillsFeedbackActivityLectureComponent = (props: VideoPracticeSkillsFeedbackLectureComponentProps) => {
  const dispatch = useAppDispatch();

  const isExpanded = useSelector<RootState, boolean>((state) => isActivityExpandedInAutomatedCommunications(state, ComponentKeyPluralized.VIDEO_PRACTICE_FEEDBACK, props.exerciseSkillsRatingActivity));
  const skillsFeedbackActivity = useSelector<RootState, VideoPracticeFeedbackActivity>((state) => state.models.exerciseSkillsRatingActivities[props.exerciseSkillsRatingActivity]);

  const hasCommunicationError = useSelector<RootState, boolean>((state) => hasVideoPracticeSkillsFeedbackGotCommunicationError(state, props.exerciseSkillsRatingActivity));

  return (
    <React.Fragment>
      <div className={`lecture-component-row ${isExpanded && skillsFeedbackActivity?.communicationsCount > 0 && 'expanded-box'}`}>
        <NvIcon icon='skills-feedback-activity' size='smallest' />
        <div className='description'>{skillsFeedbackActivity?.title}</div>
        {skillsFeedbackActivity?.hasStructuralIssues && (
          <div className='text-danger spacing'>
            <NvIcon icon='warning' size='xss-smallest' />
          </div>
        )}
        {skillsFeedbackActivity?.hardDeadline && (
          <NvTooltip text={t.TIMELINE.HARD_DEADLINE()} placement='top'>
            <div className='spacing'>
              <NvIcon icon='harddeadline' size='smallest' />
            </div>
          </NvTooltip>
        )}
        {skillsFeedbackActivity?.deadline && (
          <div className='date'>
            {t.LECTURE_PAGES.COMPONENTS.DUE_DATE.STUDENT_VIEW(moment(skillsFeedbackActivity.deadline).format('LLL'))}
          </div>
        )}
        <CommunicationCreateNewDropdown
          activityType={ActivityType.VIDEO_PRACTICE_SKILLS_FEEDBACK}
          activityId={props.exerciseSkillsRatingActivity}
          category={FeedbackCategory.VIDEO_PRACTICE_SKILLS_FEEDBACK}
        />
        {skillsFeedbackActivity?.communicationsCount > 0 && (
          <NvStackedTab
            count={skillsFeedbackActivity.communicationsCount}
            hasError={hasCommunicationError}
            isExpanded={isExpanded}
            errorMessage={t.COURSE_COMMUNICATIONS.WARNINGS.ACTIVITY_COMMUNICATION()}
            onClick={() => dispatch(setActivityExpandedInAutomatedCommunications({
              type: ComponentKeyPluralized.VIDEO_PRACTICE_FEEDBACK,
              id: props.exerciseSkillsRatingActivity,
              isExpanded: !isExpanded,
            }))}
          />
        )}
      </div>
      {
        // Communications
        isExpanded && skillsFeedbackActivity?.communications && skillsFeedbackActivity.communications?.map(communicationId => (
          <CommunicationItem key={communicationId.toString()} communicationId={communicationId} />
        ))
      }
    </React.Fragment>
  );
};

export default VideoPracticeSkillsFeedbackActivityLectureComponent;
