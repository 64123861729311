/* @ngInject */
export default function TimezonesService($translate, moment) {
  const _this = this;
  /**
     * A static list of all our supeported timezones. This matches the list in Rails'
     * ActiveSupport::TimeZone::MAPPING (https://api.rubyonrails.org/classes/ActiveSupport/TimeZone.html)
     * but has translationKey's that reference entries in our translations files.
     */
  _this.timezones = [
    {
      shortName: 'International Date Line West',
      longName: 'Etc/GMT+12',
      translationKey: 'INTERNATIONAL_DATE_LINE_WEST',
    },
    {
      shortName: 'Midway Island',
      longName: 'Pacific/Midway',
      translationKey: 'MIDWAY_ISLAND',
    },
    {
      shortName: 'American Samoa',
      longName: 'Pacific/Pago_Pago',
      translationKey: 'AMERICAN_SAMOA',
    },
    {
      shortName: 'Hawaii',
      longName: 'Pacific/Honolulu',
      translationKey: 'HAWAII',
    },
    {
      shortName: 'Alaska',
      longName: 'America/Juneau',
      translationKey: 'ALASKA',
    },
    {
      shortName: 'Pacific Time (US & Canada)',
      longName: 'America/Los_Angeles',
      translationKey: 'PACIFIC_TIME_(US_&_CANADA)',
    },
    {
      shortName: 'Tijuana',
      longName: 'America/Tijuana',
      translationKey: 'TIJUANA',
    },
    {
      shortName: 'Mountain Time (US & Canada)',
      longName: 'America/Denver',
      translationKey: 'MOUNTAIN_TIME_(US_&_CANADA)',
    },
    {
      shortName: 'Arizona',
      longName: 'America/Phoenix',
      translationKey: 'ARIZONA',
    },
    {
      shortName: 'Chihuahua',
      longName: 'America/Chihuahua',
      translationKey: 'CHIHUAHUA',
    },
    {
      shortName: 'Mazatlan',
      longName: 'America/Mazatlan',
      translationKey: 'MAZATLAN',
    },
    {
      shortName: 'Central Time (US & Canada)',
      longName: 'America/Chicago',
      translationKey: 'CENTRAL_TIME_(US_&_CANADA)',
    },
    {
      shortName: 'Saskatchewan',
      longName: 'America/Regina',
      translationKey: 'SASKATCHEWAN',
    },
    {
      shortName: 'Guadalajara',
      longName: 'America/Mexico_City',
      translationKey: 'GUADALAJARA',
    },
    {
      shortName: 'Mexico City',
      longName: 'America/Mexico_City',
      translationKey: 'MEXICO_CITY',
    },
    {
      shortName: 'Monterrey',
      longName: 'America/Monterrey',
      translationKey: 'MONTERREY',
    },
    {
      shortName: 'Central America',
      longName: 'America/Guatemala',
      translationKey: 'CENTRAL_AMERICA',
    },
    {
      shortName: 'Eastern Time (US & Canada)',
      longName: 'America/New_York',
      translationKey: 'EASTERN_TIME_(US_&_CANADA)',
    },
    {
      shortName: 'Indiana (East)',
      longName: 'America/Indiana/Indianapolis',
      translationKey: 'INDIANA_(EAST)',
    },
    {
      shortName: 'Bogota',
      longName: 'America/Bogota',
      translationKey: 'BOGOTA',
    },
    {
      shortName: 'Lima',
      longName: 'America/Lima',
      translationKey: 'LIMA',
    },
    {
      shortName: 'Quito',
      longName: 'America/Lima',
      translationKey: 'QUITO',
    },
    {
      shortName: 'Atlantic Time (Canada)',
      longName: 'America/Halifax',
      translationKey: 'ATLANTIC_TIME_(CANADA)',
    },
    {
      shortName: 'Caracas',
      longName: 'America/Caracas',
      translationKey: 'CARACAS',
    },
    {
      shortName: 'La Paz',
      longName: 'America/La_Paz',
      translationKey: 'LA_PAZ',
    },
    {
      shortName: 'Santiago',
      longName: 'America/Santiago',
      translationKey: 'SANTIAGO',
    },
    {
      shortName: 'Newfoundland',
      longName: 'America/St_Johns',
      translationKey: 'NEWFOUNDLAND',
    },
    {
      shortName: 'Brasilia',
      longName: 'America/Sao_Paulo',
      translationKey: 'BRASILIA',
    },
    {
      shortName: 'Buenos Aires',
      longName: 'America/Argentina/Buenos_Aires',
      translationKey: 'BUENOS_AIRES',
    },
    {
      shortName: 'Montevideo',
      longName: 'America/Montevideo',
      translationKey: 'MONTEVIDEO',
    },
    {
      shortName: 'Georgetown',
      longName: 'America/Guyana',
      translationKey: 'GEORGETOWN',
    },
    {
      shortName: 'Greenland',
      longName: 'America/Godthab',
      translationKey: 'GREENLAND',
    },
    {
      shortName: 'Mid-Atlantic',
      longName: 'Atlantic/South_Georgia',
      translationKey: 'MID-ATLANTIC',
    },
    {
      shortName: 'Azores',
      longName: 'Atlantic/Azores',
      translationKey: 'AZORES',
    },
    {
      shortName: 'Cape Verde Is.',
      longName: 'Atlantic/Cape_Verde',
      translationKey: 'CAPE_VERDE_IS',
    },
    {
      shortName: 'Dublin',
      longName: 'Europe/Dublin',
      translationKey: 'DUBLIN',
    },
    {
      shortName: 'Edinburgh',
      longName: 'Europe/London',
      translationKey: 'EDINBURGH',
    },
    {
      shortName: 'Lisbon',
      longName: 'Europe/Lisbon',
      translationKey: 'LISBON',
    },
    {
      shortName: 'London',
      longName: 'Europe/London',
      translationKey: 'LONDON',
    },
    {
      shortName: 'Casablanca',
      longName: 'Africa/Casablanca',
      translationKey: 'CASABLANCA',
    },
    {
      shortName: 'Monrovia',
      longName: 'Africa/Monrovia',
      translationKey: 'MONROVIA',
    },
    {
      shortName: 'UTC',
      longName: 'Etc/UTC',
      translationKey: 'UTC',
    },
    {
      shortName: 'Belgrade',
      longName: 'Europe/Belgrade',
      translationKey: 'BELGRADE',
    },
    {
      shortName: 'Bratislava',
      longName: 'Europe/Bratislava',
      translationKey: 'BRATISLAVA',
    },
    {
      shortName: 'Budapest',
      longName: 'Europe/Budapest',
      translationKey: 'BUDAPEST',
    },
    {
      shortName: 'Ljubljana',
      longName: 'Europe/Ljubljana',
      translationKey: 'LJUBLJANA',
    },
    {
      shortName: 'Prague',
      longName: 'Europe/Prague',
      translationKey: 'PRAGUE',
    },
    {
      shortName: 'Sarajevo',
      longName: 'Europe/Sarajevo',
      translationKey: 'SARAJEVO',
    },
    {
      shortName: 'Skopje',
      longName: 'Europe/Skopje',
      translationKey: 'SKOPJE',
    },
    {
      shortName: 'Warsaw',
      longName: 'Europe/Warsaw',
      translationKey: 'WARSAW',
    },
    {
      shortName: 'Zagreb',
      longName: 'Europe/Zagreb',
      translationKey: 'ZAGREB',
    },
    {
      shortName: 'Brussels',
      longName: 'Europe/Brussels',
      translationKey: 'BRUSSELS',
    },
    {
      shortName: 'Copenhagen',
      longName: 'Europe/Copenhagen',
      translationKey: 'COPENHAGEN',
    },
    {
      shortName: 'Madrid',
      longName: 'Europe/Madrid',
      translationKey: 'MADRID',
    },
    {
      shortName: 'Paris',
      longName: 'Europe/Paris',
      translationKey: 'PARIS',
    },
    {
      shortName: 'Amsterdam',
      longName: 'Europe/Amsterdam',
      translationKey: 'AMSTERDAM',
    },
    {
      shortName: 'Berlin',
      longName: 'Europe/Berlin',
      translationKey: 'BERLIN',
    },
    {
      shortName: 'Bern',
      longName: 'Europe/Berlin',
      translationKey: 'BERN',
    },
    {
      shortName: 'Rome',
      longName: 'Europe/Rome',
      translationKey: 'ROME',
    },
    {
      shortName: 'Stockholm',
      longName: 'Europe/Stockholm',
      translationKey: 'STOCKHOLM',
    },
    {
      shortName: 'Vienna',
      longName: 'Europe/Vienna',
      translationKey: 'VIENNA',
    },
    {
      shortName: 'West Central Africa',
      longName: 'Africa/Algiers',
      translationKey: 'WEST_CENTRAL_AFRICA',
    },
    {
      shortName: 'Bucharest',
      longName: 'Europe/Bucharest',
      translationKey: 'BUCHAREST',
    },
    {
      shortName: 'Cairo',
      longName: 'Africa/Cairo',
      translationKey: 'CAIRO',
    },
    {
      shortName: 'Helsinki',
      longName: 'Europe/Helsinki',
      translationKey: 'HELSINKI',
    },
    {
      shortName: 'Kyiv',
      longName: 'Europe/Kiev',
      translationKey: 'KYIV',
    },
    {
      shortName: 'Riga',
      longName: 'Europe/Riga',
      translationKey: 'RIGA',
    },
    {
      shortName: 'Sofia',
      longName: 'Europe/Sofia',
      translationKey: 'SOFIA',
    },
    {
      shortName: 'Tallinn',
      longName: 'Europe/Tallinn',
      translationKey: 'TALLINN',
    },
    {
      shortName: 'Vilnius',
      longName: 'Europe/Vilnius',
      translationKey: 'VILNIUS',
    },
    {
      shortName: 'Athens',
      longName: 'Europe/Athens',
      translationKey: 'ATHENS',
    },
    {
      shortName: 'Istanbul',
      longName: 'Europe/Istanbul',
      translationKey: 'ISTANBUL',
    },
    {
      shortName: 'Minsk',
      longName: 'Europe/Minsk',
      translationKey: 'MINSK',
    },
    {
      shortName: 'Jerusalem',
      longName: 'Asia/Jerusalem',
      translationKey: 'JERUSALEM',
    },
    {
      shortName: 'Harare',
      longName: 'Africa/Harare',
      translationKey: 'HARARE',
    },
    {
      shortName: 'Pretoria',
      longName: 'Africa/Johannesburg',
      translationKey: 'PRETORIA',
    },
    {
      shortName: 'Kaliningrad',
      longName: 'Europe/Kaliningrad',
      translationKey: 'KALININGRAD',
    },
    {
      shortName: 'Moscow',
      longName: 'Europe/Moscow',
      translationKey: 'MOSCOW',
    },
    {
      shortName: 'St. Petersburg',
      longName: 'Europe/Moscow',
      translationKey: 'ST_PETERSBURG',
    },
    {
      shortName: 'Volgograd',
      longName: 'Europe/Volgograd',
      translationKey: 'VOLGOGRAD',
    },
    {
      shortName: 'Samara',
      longName: 'Europe/Samara',
      translationKey: 'SAMARA',
    },
    {
      shortName: 'Kuwait',
      longName: 'Asia/Kuwait',
      translationKey: 'KUWAIT',
    },
    {
      shortName: 'Riyadh',
      longName: 'Asia/Riyadh',
      translationKey: 'RIYADH',
    },
    {
      shortName: 'Nairobi',
      longName: 'Africa/Nairobi',
      translationKey: 'NAIROBI',
    },
    {
      shortName: 'Baghdad',
      longName: 'Asia/Baghdad',
      translationKey: 'BAGHDAD',
    },
    {
      shortName: 'Tehran',
      longName: 'Asia/Tehran',
      translationKey: 'TEHRAN',
    },
    {
      shortName: 'Abu Dhabi',
      longName: 'Asia/Muscat',
      translationKey: 'ABU_DHABI',
    },
    {
      shortName: 'Muscat',
      longName: 'Asia/Muscat',
      translationKey: 'MUSCAT',
    },
    {
      shortName: 'Baku',
      longName: 'Asia/Baku',
      translationKey: 'BAKU',
    },
    {
      shortName: 'Tbilisi',
      longName: 'Asia/Tbilisi',
      translationKey: 'TBILISI',
    },
    {
      shortName: 'Yerevan',
      longName: 'Asia/Yerevan',
      translationKey: 'YEREVAN',
    },
    {
      shortName: 'Kabul',
      longName: 'Asia/Kabul',
      translationKey: 'KABUL',
    },
    {
      shortName: 'Ekaterinburg',
      longName: 'Asia/Yekaterinburg',
      translationKey: 'EKATERINBURG',
    },
    {
      shortName: 'Islamabad',
      longName: 'Asia/Karachi',
      translationKey: 'ISLAMABAD',
    },
    {
      shortName: 'Karachi',
      longName: 'Asia/Karachi',
      translationKey: 'KARACHI',
    },
    {
      shortName: 'Tashkent',
      longName: 'Asia/Tashkent',
      translationKey: 'TASHKENT',
    },
    {
      shortName: 'Chennai',
      longName: 'Asia/Kolkata',
      translationKey: 'CHENNAI',
    },
    {
      shortName: 'Kolkata',
      longName: 'Asia/Kolkata',
      translationKey: 'KOLKATA',
    },
    {
      shortName: 'Mumbai',
      longName: 'Asia/Kolkata',
      translationKey: 'MUMBAI',
    },
    {
      shortName: 'New Delhi',
      longName: 'Asia/Kolkata',
      translationKey: 'NEW_DELHI',
    },
    {
      shortName: 'Kathmandu',
      longName: 'Asia/Kathmandu',
      translationKey: 'KATHMANDU',
    },
    {
      shortName: 'Astana',
      longName: 'Asia/Dhaka',
      translationKey: 'ASTANA',
    },
    {
      shortName: 'Dhaka',
      longName: 'Asia/Dhaka',
      translationKey: 'DHAKA',
    },
    {
      shortName: 'Sri Jayawardenepura',
      longName: 'Asia/Colombo',
      translationKey: 'SRI_JAYAWARDENEPURA',
    },
    {
      shortName: 'Almaty',
      longName: 'Asia/Almaty',
      translationKey: 'ALMATY',
    },
    {
      shortName: 'Novosibirsk',
      longName: 'Asia/Novosibirsk',
      translationKey: 'NOVOSIBIRSK',
    },
    {
      shortName: 'Rangoon',
      longName: 'Asia/Rangoon',
      translationKey: 'RANGOON',
    },
    {
      shortName: 'Bangkok',
      longName: 'Asia/Bangkok',
      translationKey: 'BANGKOK',
    },
    {
      shortName: 'Hanoi',
      longName: 'Asia/Bangkok',
      translationKey: 'HANOI',
    },
    {
      shortName: 'Jakarta',
      longName: 'Asia/Jakarta',
      translationKey: 'JAKARTA',
    },
    {
      shortName: 'Krasnoyarsk',
      longName: 'Asia/Krasnoyarsk',
      translationKey: 'KRASNOYARSK',
    },
    {
      shortName: 'Beijing',
      longName: 'Asia/Shanghai',
      translationKey: 'BEIJING',
    },
    {
      shortName: 'Chongqing',
      longName: 'Asia/Chongqing',
      translationKey: 'CHONGQING',
    },
    {
      shortName: 'Hong Kong',
      longName: 'Asia/Hong_Kong',
      translationKey: 'HONG_KONG',
    },
    {
      shortName: 'Urumqi',
      longName: 'Asia/Urumqi',
      translationKey: 'URUMQI',
    },
    {
      shortName: 'Kuala Lumpur',
      longName: 'Asia/Kuala_Lumpur',
      translationKey: 'KUALA_LUMPUR',
    },
    {
      shortName: 'Singapore',
      longName: 'Asia/Singapore',
      translationKey: 'SINGAPORE',
    },
    {
      shortName: 'Taipei',
      longName: 'Asia/Taipei',
      translationKey: 'TAIPEI',
    },
    {
      shortName: 'Perth',
      longName: 'Australia/Perth',
      translationKey: 'PERTH',
    },
    {
      shortName: 'Irkutsk',
      longName: 'Asia/Irkutsk',
      translationKey: 'IRKUTSK',
    },
    {
      shortName: 'Ulaanbaatar',
      longName: 'Asia/Ulaanbaatar',
      translationKey: 'ULAANBAATAR',
    },
    {
      shortName: 'Seoul',
      longName: 'Asia/Seoul',
      translationKey: 'SEOUL',
    },
    {
      shortName: 'Osaka',
      longName: 'Asia/Tokyo',
      translationKey: 'OSAKA',
    },
    {
      shortName: 'Sapporo',
      longName: 'Asia/Tokyo',
      translationKey: 'SAPPORO',
    },
    {
      shortName: 'Tokyo',
      longName: 'Asia/Tokyo',
      translationKey: 'TOKYO',
    },
    {
      shortName: 'Yakutsk',
      longName: 'Asia/Yakutsk',
      translationKey: 'YAKUTSK',
    },
    {
      shortName: 'Darwin',
      longName: 'Australia/Darwin',
      translationKey: 'DARWIN',
    },
    {
      shortName: 'Adelaide',
      longName: 'Australia/Adelaide',
      translationKey: 'ADELAIDE',
    },
    {
      shortName: 'Canberra',
      longName: 'Australia/Melbourne',
      translationKey: 'CANBERRA',
    },
    {
      shortName: 'Melbourne',
      longName: 'Australia/Melbourne',
      translationKey: 'MELBOURNE',
    },
    {
      shortName: 'Sydney',
      longName: 'Australia/Sydney',
      translationKey: 'SYDNEY',
    },
    {
      shortName: 'Brisbane',
      longName: 'Australia/Brisbane',
      translationKey: 'BRISBANE',
    },
    {
      shortName: 'Hobart',
      longName: 'Australia/Hobart',
      translationKey: 'HOBART',
    },
    {
      shortName: 'Vladivostok',
      longName: 'Asia/Vladivostok',
      translationKey: 'VLADIVOSTOK',
    },
    {
      shortName: 'Guam',
      longName: 'Pacific/Guam',
      translationKey: 'GUAM',
    },
    {
      shortName: 'Port Moresby',
      longName: 'Pacific/Port_Moresby',
      translationKey: 'PORT_MORESBY',
    },
    {
      shortName: 'Magadan',
      longName: 'Asia/Magadan',
      translationKey: 'MAGADAN',
    },
    {
      shortName: 'Srednekolymsk',
      longName: 'Asia/Srednekolymsk',
      translationKey: 'SREDNEKOLYMSK',
    },
    {
      shortName: 'Solomon Is.',
      longName: 'Pacific/Guadalcanal',
      translationKey: 'SOLOMON_IS',
    },
    {
      shortName: 'New Caledonia',
      longName: 'Pacific/Noumea',
      translationKey: 'NEW_CALEDONIA',
    },
    {
      shortName: 'Fiji',
      longName: 'Pacific/Fiji',
      translationKey: 'FIJI',
    },
    {
      shortName: 'Kamchatka',
      longName: 'Asia/Kamchatka',
      translationKey: 'KAMCHATKA',
    },
    {
      shortName: 'Marshall Is.',
      longName: 'Pacific/Majuro',
      translationKey: 'MARSHALL_IS',
    },
    {
      shortName: 'Auckland',
      longName: 'Pacific/Auckland',
      translationKey: 'AUCKLAND',
    },
    {
      shortName: 'Wellington',
      longName: 'Pacific/Auckland',
      translationKey: 'WELLINGTON',
    },
    {
      shortName: 'Nuku\'alofa',
      longName: 'Pacific/Tongatapu',
      translationKey: 'NUKUALOFA',
    },
    {
      shortName: 'Tokelau Is.',
      longName: 'Pacific/Fakaofo',
      translationKey: 'TOKELAU_IS',
    },
    {
      shortName: 'Chatham Is.',
      longName: 'Pacific/Chatham',
      translationKey: 'CHATHAM_IS',
    },
    {
      shortName: 'Samoa',
      longName: 'Pacific/Apia',
      translationKey: 'SAMOA',
    },
  ];

  _this.timezones.forEach((tz) => {
    // Calculate the offset with moment-timezone, formatted like '(UTC-05:00)'
    // Note that `tz.longName` creates a valid moment.js object while `tz.shortName` does not.
    tz.offset = moment.tz(tz.longName).format('(UTCZ)');
  });

  /**
   * This is a function to correct mistakes with moment.js's spelling of various timezones.
   * For all of these, both the "incorrect" and the "correct" spelling are valid as moment timezones
   * meaning that we could be provided with the wrong timezone by moment.tz.guess,
   * so we need to correct the spelling and then can look up the correct zone.
   */
  _this.correctSpelling = function (zoneName) {
    switch (zoneName) {
      // These are corrections for values that came from Rails
      case 'America/Buenos_Aires':
        return 'America/Argentina/Buenos_Aires';
      case 'America/Ensenada':
        return 'America/Tijuana';
      case 'America/Fort_Wayne':
        return 'America/Indiana/Indianapolis';
      case 'America/Indianapolis':
        return 'America/Indiana/Indianapolis';
      case 'America/Santa_Isabel':
        return 'America/Tijuana';
      case 'America/Shiprock':
        return 'America/Denver';
      case 'Antarctica/South_Pole':
        return 'Pacific/Auckland';
      case 'Asia/Calcutta':
        return 'Asia/Kolkata';
      case 'Asia/Chongqing':
        return 'Asia/Shanghai';
      case 'Asia/Chungking':
        return 'Asia/Shanghai';
      case 'Asia/Dacca':
        return 'Asia/Dhaka';
      case 'Asia/Harbin':
        return 'Asia/Shanghai';
      case 'Asia/Istanbul':
        return 'Europe/Istanbul';
      case 'Asia/Kashgar':
        return 'Asia/Urumqi';
      case 'Asia/Katmandu':
        return 'Asia/Kathmandu';
      case 'Asia/Tel_Aviv':
        return 'Asia/Jerusalem';
      case 'Asia/Ulan_Bator':
        return 'Asia/Ulaanbaatar';
      case 'Asia/Yangon':
        return 'Asia/Rangoon';
      case 'Australia/ACT':
        return 'Australia/Sydney';
      case 'Australia/Canberra':
        return 'Australia/Sydney';
      case 'Australia/NSW':
        return 'Australia/Sydney';
      case 'Australia/North':
        return 'Australia/Darwin';
      case 'Australia/Queensland':
        return 'Australia/Brisbane';
      case 'Australia/South':
        return 'Australia/Adelaide';
      case 'Australia/Tasmania':
        return 'Australia/Hobart';
      case 'Australia/Victoria':
        return 'Australia/Melbourne';
      case 'Australia/West':
        return 'Australia/Perth';
      case 'Brazil/East':
        return 'America/Sao_Paulo';
      case 'Canada/Atlantic':
        return 'America/Halifax';
      case 'Canada/Newfoundland':
        return 'America/St_Johns';
      case 'Canada/Saskatchewan':
        return 'America/Regina';
      case 'Chile/Continental':
        return 'America/Santiago';
      case 'Egypt':
        return 'Africa/Cairo';
      case 'Eire':
        return 'Europe/Dublin';
      case 'Etc/Universal':
        return 'Etc/UTC';
      case 'Etc/Zulu':
        return 'Etc/UTC';
      case 'Europe/Belfast':
        return 'Europe/London';
      case 'GB':
        return 'Europe/London';
      case 'GB-Eire':
        return 'Europe/London';
      case 'Hongkong':
        return 'Asia/Hong_Kong';
      case 'Iran':
        return 'Asia/Tehran';
      case 'Israel':
        return 'Asia/Jerusalem';
      case 'Japan':
        return 'Asia/Tokyo';
      case 'Mexico/BajaNorte':
        return 'America/Tijuana';
      case 'Mexico/BajaSur':
        return 'America/Mazatlan';
      case 'Mexico/General':
        return 'America/Mexico_City';
      case 'NZ':
        return 'Pacific/Auckland';
      case 'NZ-CHAT':
        return 'Pacific/Chatham';
      case 'Navajo':
        return 'America/Denver';
      case 'PRC':
        return 'Asia/Shanghai';
      case 'Pacific/Johnston':
        return 'Pacific/Honolulu';
      case 'Pacific/Samoa':
        return 'Pacific/Pago_Pago';
      case 'Poland':
        return 'Europe/Warsaw';
      case 'Portugal':
        return 'Europe/Lisbon';
      case 'ROC':
        return 'Asia/Taipei';
      case 'ROK':
        return 'Asia/Seoul';
      case 'Singapore':
        return 'Asia/Singapore';
      case 'Turkey':
        return 'Europe/Istanbul';
      case 'US/Arizona':
        return 'America/Phoenix';
      case 'US/Central':
        return 'America/Chicago';
      case 'US/East-Indiana':
        return 'America/Indiana/Indianapolis';
      case 'US/Eastern':
        return 'America/New_York';
      case 'US/Hawaii':
        return 'Pacific/Honolulu';
      case 'US/Mountain':
        return 'America/Denver';
      case 'US/Pacific':
        return 'America/Los_Angeles';
      case 'US/Samoa':
        return 'Pacific/Pago_Pago';
      case 'UTC':
        return 'Etc/UTC';
      case 'Universal':
        return 'Etc/UTC';
      case 'W-SU':
        return 'Europe/Moscow';
      case 'Zulu':
        return 'Etc/UTC';

      default:
        return zoneName;
    }
  };

  return _this;
}
