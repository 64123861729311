import setupMocks from 'shared/axios/axios-mocks';
import SampleMocks from './sample';
import InstitutionDashboardMocks from './institution-dashboard';
import SkillTagsMocks from './skill-tags';
import CourseCompletionStatusMocks from './course-completion-status';
import CoursesMocks from './courses';
import CredlyMocks from './credly';
import MyMenteesMocks from './my-mentees';
import InstitutionMocks from './institution';
import setupCustomMocks from './custom';
import BasicEnrollmentMocks from './basic-enrollments';
import TeamsAndGroupsMocks from './teams-and-groups';
import CommunicationsMocks from './communications';
import DiscoveryMocks from './discovery';
import CommentMocks from './comment';
// This method would be called only if gulp is run with the --usemocks parameter
// and if the current environmemt is DEV.
// Add your own mocks by creating a new ts file under the mocks folder,
// exporting your mock array and adding it to the mocksList array.
// more information: https://github.com/ctimmerm/axios-mock-adapter
// Note: Mocking functionality only available when using axios. (fetch and $http calls won't be intercepted)
const initMocks = () => {
  const mocksList = [
    InstitutionDashboardMocks,
    SampleMocks,
    SkillTagsMocks,
    CourseCompletionStatusMocks,
    CoursesMocks,
    CredlyMocks,
    MyMenteesMocks,
    InstitutionMocks,
    BasicEnrollmentMocks,
    TeamsAndGroupsMocks,
    CommunicationsMocks,
    DiscoveryMocks,
    CommentMocks
  ];
  // flatten all array definitions and call setupmocks
  setupMocks([].concat(...mocksList));
  setupCustomMocks();
};
export default initMocks;
