/* @ngInject */
export default function NvExpandableSearchBar(
  $parse,
  $timeout,
  $translate,
  _,
) {
  return {
    restrict: 'A',
    scope: {
      onEnter: '&',
      onSearch: '&',
      onClose: '&',
      placeholder: '@',
      tooltip: '@?',
      popoverPosition: '@?',
      shouldBeClosed: '=?',
      initialQuery: '=?',
      size: '@?', // Either 'xs' for the smallest size or 'sm' for a small size. Defaults to 'xs'
      queryModel: '=?',
    },
    link(scope, element, attrs) {
      let initialQuery = '';
      scope.popover = attrs.popover;

      if (scope.initialQuery) {
        // eslint-disable-next-line prefer-destructuring
        initialQuery = scope.initialQuery;
      }

      if (!scope.popoverPosition) {
        scope.popoverPosition = 'top';
      }

      scope.open = open;
      scope.close = close;

      // Default to course search tooltip
      if (!scope.tooltip) {
        scope.tooltip = $translate.instant('COURSES.SEARCH.TITLE');
      }

      if (!scope.tooltip) {
        scope.tooltip = 'xs';
      }

      initialize();

      scope.doSearch = (query) => {
        element.find('input').blur();
        scope.onSearch(query);
      };

      function initialize() {
        scope.queryModel = initialQuery;
        if (Object.prototype.hasOwnProperty.call(scope, 'shouldBeClosed')) {
          scope.isOpened = !scope.shouldBeClosed;
        }

        scope.$watch('shouldBeClosed', (shouldBeClosed) => {
          if (shouldBeClosed) {
            scope.queryModel = initialQuery;
            scope.isOpened = !shouldBeClosed;
          }
        });
      }

      function open() {
        scope.isOpened = true;
        scope.onEnter();

        $timeout(() => {
          element.find('input.search-input').focus();
        });
      }

      function close() {
        scope.isOpened = false;
        scope.onClose();
      }
    },
    templateUrl: 'shared/templates/nv-expandable-search-bar.html',
    controllerAs: 'vm',
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
      vm.popoverPosition = $scope.popoverPosition;

      return vm;
    },
  };
}
