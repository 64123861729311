import { css } from '@emotion/react';
import t from 'react-translate';
import { useContext } from 'react';
import { connect, useSelector } from 'react-redux';


// schemas
import { CommunicationType } from 'redux/schemas/models/course-communication';
import { ActivityType, MinimalActivity } from 'redux/schemas/models/activity';

// contexts
import { CommunicationDispatch, CommunicationAction, TriggerType } from 'communications/course_communications/contexts/communication-context';
import { TimelineLecturePageContext } from 'timelines/components/timeline-lecture-page';
import { RootState } from 'redux/schemas';

// selectors
import { getActivity } from 'redux/selectors/timeline';

// components
import { NvTooltip } from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import { NvDropdown, NvDropdownButtonStyle, NvDropdownAlign, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';

// styles
import { primary } from 'styles/global_defaults/colors';
import { standardSpacing } from 'styles/global_defaults/scaffolding';

// Target area is larger than than the small icon size
const TARGET_AREA_PADDING = standardSpacing;

const styles = css`
  color: ${primary};
  cursor: pointer;

  .options-btn {
    /* TODO : Don't have the increased target area at the top of the icon */
    /* to fix the tooltip flickering issue on hover. Fix this gracefully. */
    padding: 0 ${TARGET_AREA_PADDING}px ${TARGET_AREA_PADDING}px;
    margin-top: ${TARGET_AREA_PADDING}px;
  }

  &.disabled {
    .options-btn {
      opacity: 0.3;
    }
  }
`;

type CommunicationCreateNewDropdownProps = {
  activityType: ActivityType
  activityId: number
  disabledText?: string
  category?: string
};

const CommunicationCreateNewDropdown = (props: CommunicationCreateNewDropdownProps) => {
  const { dispatch } = useContext(CommunicationDispatch);
  const lecturePageContext = useContext(TimelineLecturePageContext);

  const activity = useSelector<RootState, MinimalActivity>((state) => getActivity(state, props.activityId, props.activityType));

  const onClick = (type: CommunicationType) => {
    dispatch({
      type: CommunicationAction.SHOW_MODAL,
      triggerType: TriggerType.CREATE,
      communicationType: type,
      activityType: props.activityType,
      activityId: props.activityId,
      category: props.category,
      lecturePageId: lecturePageContext?.lecturePageId,
    });
  };

  // If there is a disabled text, then it need to be shown as tooltip with the disabled
  // icon or the default `click to create` verbiage
  const toolTipText = props.disabledText || t.COURSE_COMMUNICATIONS.TOOLTIP.CREATE();

  const hasActivityDueDate = !!activity?.dueDate;
  const dropDownItems: NvDropdownOption[] = [
    { type: 'header', title: t.COURSE_COMMUNICATIONS.CREATE_DROPDOWN.ADD_TRIGGER() },
    {
      type: 'text',
      text: t.COURSE_COMMUNICATIONS.CREATE_DROPDOWN.TO_THOSE_WHO_COMPLETED(),
      callback: () => onClick(CommunicationType.COMPLETED),
    },
    {
      type: 'text',
      text: t.COURSE_COMMUNICATIONS.CREATE_DROPDOWN.TO_THOSE_WHO_DID_NOT_COMPLETE(),
      callback: () => onClick(CommunicationType.NOT_COMPLETED),
    },
  ];

  if (hasActivityDueDate) {
    dropDownItems.push(
      { type: 'divider', class: 'mb-2' },
      { type: 'header', title: t.COURSE_COMMUNICATIONS.CREATE_DROPDOWN.SCHEDULE() },
      {
        type: 'text',
        text: t.COURSE_COMMUNICATIONS.CREATE_DROPDOWN.NEW_EMAIL_BASED_ON_DUE_DATE(),
        callback: () => onClick(CommunicationType.DUE_DATE_EMAIL),
      },
      {
        type: 'text',
        text: t.COURSE_COMMUNICATIONS.CREATE_DROPDOWN.NEW_ANNOUNCEMENT_BASED_ON_DUE_DATE(),
        callback: () => onClick(CommunicationType.DUE_DATE_ANNOUNCEMENT),
      },
    );
  }

  return (
    <a css={styles} className={props.disabledText ? 'disabled' : ''}>
      <NvDropdown
        buttonStyle={NvDropdownButtonStyle.CUSTOM}
        items={dropDownItems}
        align={NvDropdownAlign.RIGHT}
        offset={-TARGET_AREA_PADDING / 2} // Icon has a target area padding of 20px
        customTarget={() => (
          <NvTooltip text={toolTipText}>
            <div className='options-btn'>
              <NvIcon size='xss-smallest' icon='add-square' />
            </div>
          </NvTooltip>
        )}
        disabled={!!props.disabledText}
      />
    </a>
  );
};

export default CommunicationCreateNewDropdown;
