import React from 'react';
import { css } from '@emotion/react';

import NvIcon from 'shared/components/nv-icon';
import { mergeRefs } from 'shared/react-utils';
import useFocusEvent from 'shared/hooks/use-focus-event';
import useClickOutside from 'shared/hooks/use-click-outside';
import ClickableContainer from 'components/clickable-container';
import {
  black,
  gray2,
  white,
  primary,
  warning,
} from 'styles/global_defaults/colors';
import {
  halfSpacing,
  quarterSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';

const MIN = 1;
const MAX = 999;

type Props = {
  value?: number,
  className?: string,
  onChange?: (newValue: number) => void,
  onBlur?: React.ComponentProps<'input'>['onBlur'],
  onFocus?: React.ComponentProps<'input'>['onFocus'],
};

const Input = React.forwardRef<HTMLInputElement | HTMLButtonElement, Props>((props, ref) => {
  const {
    onBlur,
    onFocus,
    className,
    value: propsValue,
    onChange: propsOnChange,
  } = props;

  const onBlurRef = React.useRef(onBlur);
  onBlurRef.current = onBlur;
  const onFocusRef = React.useRef(onFocus);
  onFocusRef.current = onFocus;
  const clickedOutsideRef = React.useRef(false);
  const closedWithKeyboardRef = React.useRef(false);
  const inputRef = React.useRef<HTMLInputElement>();
  const targetRef = React.useRef<HTMLButtonElement>();
  const containerRef = React.useRef<HTMLDivElement>();
  const [isEditing, setIsEditing] = React.useState(false);
  const [shouldApplyChange, setShouldApplyChange] = React.useState(false);
  const [value, originalSetValue] = React.useState<string>(propsValue.toString());

  const valueRef = React.useRef(value);
  valueRef.current = value;

  useClickOutside(containerRef, () => {
    clickedOutsideRef.current = true;
  }, []);

  const onChange = (newValue: number) => {
    if (newValue !== propsValue) {
      propsOnChange?.(newValue);
    }
  };

  const applyChange = () => {
    if (value) {
      onChange(Number(value));
    } else {
      setValue(propsValue.toString());
    }
  };

  const applyChangeRef = React.useRef(applyChange);
  applyChangeRef.current = applyChange;

  const focusInHandler = (e) => {
    onFocusRef.current?.(e);
  };

  const focusOutHandler = (e) => {
    if ((e.relatedTarget ? !containerRef.current.contains(e.relatedTarget) : clickedOutsideRef.current)) {
      clickedOutsideRef.current = false;
      onBlurRef.current?.(e);

      applyChangeRef.current();
    }
  };

  useFocusEvent(containerRef, 'focus', focusInHandler, []);
  useFocusEvent(containerRef, 'blur', focusOutHandler, []);

  const setInputSelectionRange = (start, end) => {
    // This is a hacky way to make sure we can set text selection on input, it
    // doesn't work for number inputs so we make it text, then setselection,
    // then set back to number type.
    inputRef.current.type = 'text';
    inputRef.current.setSelectionRange(start, end);
    inputRef.current.type = 'number';
  };

  const setInputSelectionRangeRef = React.useRef(setInputSelectionRange);
  setInputSelectionRangeRef.current = setInputSelectionRange;

  React.useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
      setInputSelectionRangeRef.current(0, valueRef.current.length);
    } else if (closedWithKeyboardRef.current) {
      targetRef.current.focus();
      closedWithKeyboardRef.current = false;
    }
  }, [isEditing]);

  React.useEffect(() => {
    if (shouldApplyChange) {
      applyChangeRef.current();
      setShouldApplyChange(false);
    }
  }, [shouldApplyChange]);

  const setValue = (newValue: string) => {
    let actualValue = newValue;

    if (newValue) {
      if (Number.isNaN(Number(newValue))) {
        actualValue = MIN.toString();
      } else {
        actualValue = Math.min(MAX, Math.max(MIN, Number(newValue))).toString();
      }
    }

    originalSetValue(actualValue);
  };

  const styles = css`
    padding-left: 6px;
    padding-right: 2px;
    border-radius: 4px;
    background-color: ${black};
    height: ${standardSpacing + halfSpacing}px;

    .input-target, input {
      border: 0;
      padding: 0;
      outline: 0;
      margin-right: 2px;
      height: ${standardSpacing}px;
      width: ${standardSpacing + quarterSpacing}px;
    }

    .input-target {
      color: ${white};
      border-radius: 2px;
      background-color: transparent;

      &:hover {
        background-color: ${gray2};
      }
    }

    input {
      color: ${black};
    }

    .px {
      margin-right: 4px;
    }

    .arrows-container {
      overflow: hidden;
      border-radius: 4px;

      .arrow-container {
        width: 22px;
        height: 12px;
        outline-color: ${warning};
        background-color: ${primary};

        &:first-child {
          margin-bottom: 2px;
        }

        .icon {
          color: ${white};
          font-size: ${halfSpacing}px;
        }
      }
    }
  `;

  const handleInputBlur = () => {
    // For some reason input stores its last selection range internally, so if
    // user selects all the content, then blurs and then focus, the selection
    // gets restored, we are setting the selection range this way to place the
    // cursor at the end everytime we focus.
    setInputSelectionRange(value.length, value.length);

    // Timeout necessary to let the blur event propagate succesfully, because
    // if we unmount it, it doesn't propagate.
    setTimeout(() => {
      setIsEditing(false);
    });
  };

  const increase = () => {
    if (value) {
      setValue((Number(value) + 10).toString());
    } else {
      setValue((propsValue + 10).toString());
    }
  };

  const decrease = () => {
    if (value) {
      setValue((Number(value) - 10).toString());
    } else {
      setValue((propsValue - 10).toString());
    }
  };

  const handleContainerClick = (e) => {
    // Discard clicks caused by pressing enter key
    if (e.detail !== 0) {
      if (isEditing) {
        inputRef.current.focus();
      } else {
        targetRef.current.focus();
      }
    }
  };

  const handleInputChange = (e) => {
    if (isEditing) {
      setValue(e.target.value);
    } else {
      e.preventDefault();
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }

    if (isEditing) {
      if (e.key === 'Enter') {
        applyChange();
        setIsEditing(false);
        closedWithKeyboardRef.current = true;
      } else if (e.key === 'ArrowUp') {
        increase();
      } else if (e.key === 'ArrowDown') {
        decrease();
      }
    } else if (e.key === 'Enter') {
      if (Number(value) !== propsValue) {
        onChange(Number(value));
      } else {
        setIsEditing(true);
      }
    }
  };

  return (
    <div
      css={styles}
      ref={containerRef}
      onClick={handleContainerClick}
      className={`d-flex align-items-center ${className ?? ''}`}
    >
      {isEditing ? (
        <input
          min={MIN}
          max={MAX}
          type='number'
          value={value}
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          ref={mergeRefs(ref, inputRef)}
          onKeyDown={handleInputKeyDown}
          className='text-regular text-center'
        />
      ) : (
        <button
          type='button'
          ref={mergeRefs(ref, targetRef)}
          onClick={() => setIsEditing(true)}
          className='input-target text-regular text-center'
        >
          {value}
        </button>
      )}
      <span className='text-regular gray-2 px'>px</span>
      <div className='d-flex flex-column arrows-container'>
        <ClickableContainer
          onClick={() => {
            increase();
            setShouldApplyChange(true);
          }}
          className='d-flex justify-content-center align-items-center arrow-container'
        >
          <NvIcon icon='arrow-up' size='' />
        </ClickableContainer>
        <ClickableContainer
          onClick={() => {
            decrease();
            setShouldApplyChange(true);
          }}
          className='d-flex justify-content-center align-items-center arrow-container'
        >
          <NvIcon icon='arrow-down' size='' />
        </ClickableContainer>
      </div>
    </div>
  );
});

export default Input;
