import React from 'react';
import t from 'react-translate';

import { NvUploadedFileProps } from '.';
import useUploadedFile from './hooks/use-uploaded-file';
import NvResponsiveBoxPreview from './nv-box-responsive-preview';
import { BOX_FILE_PREVIEW_SIZE_LIMIT } from './nv-box-embed';

const NvBoxFileModalPreview = ({ file }: Pick<NvUploadedFileProps, 'file'>) => {
  const { isFileAvailableToDownload } = useUploadedFile();
  const isFileDownloadable = isFileAvailableToDownload(file);

  const downloadLink = isFileDownloadable && (
    <a
      className='blue-link'
      href={file.url}
      target='_blank'
      rel='noreferrer'
    >
      {t.FILE_UPLOAD.VIEW_FILE()}
    </a>
  );

  return (
    <React.Fragment>
      {(file.size < BOX_FILE_PREVIEW_SIZE_LIMIT) ? (
        <React.Fragment>
          {file.boxDocumentId ? (
            <NvResponsiveBoxPreview file={file} />
          ) : (
            <div className='text-center'>
              {file.boxUploadable ? (
                <p>{t.FILE_UPLOAD[`UPLOAD_IN_PROGRESS${!isFileDownloadable ? '_NOT_DOWNLOADABLE' : ''}`]()}</p>
              ) : (
                <p>{t.FILE_UPLOAD.NO_PREVIEW()}</p>
              )}
              {downloadLink}
            </div>
          )}
        </React.Fragment>
      ) : (
        <div className='text-center'>
          <p>{t.FILE_UPLOAD.NO_PREVIEW_LARGER_FILES()}</p>
          {downloadLink}
        </div>
      )}
    </React.Fragment>
  );
};

export default NvBoxFileModalPreview;
