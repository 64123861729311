import { css } from '@emotion/react';
import {
  halfSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import { CategoryHeaderProps, NvCategoryHeader } from './nv-category-header';
import { CategoryItemProps, NvCategoryItem } from './nv-category-item';

export type CategoryProps = CategoryHeaderProps & {
  singleCategory?: boolean;
  show: boolean;
  items: CategoryItemProps[];
};

export const NvCategory = ({
  singleCategory,
  show,
  items,
  ...categoryHeaderProps
}: CategoryProps) => {
  const styles = css`
    padding-right: ${singleCategory ? '0' : `${halfSpacing}px`};
    width: 100%;
    min-width: 120px;
    max-width: 150px;
    display: flex;
    flex-direction: column;
    gap: ${standardSpacing}px;
  `;

  return (
    <div css={styles}>
      {!singleCategory && <NvCategoryHeader {...categoryHeaderProps} />}

      {items.map(item => (
        <NvCategoryItem key={item.label} singleCategory={singleCategory} {...item} />
      ))}
    </div>
  );
};
