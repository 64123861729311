
import { getColorBetweenColorsByPercentage } from 'styles/global_defaults/colors';

/* @ngInject */
export default function NvCriteriaProgressBar(
  CurrentCourseManager,
  nvUtil,
  $timeout,
) {
  return {
    scope: {
      currentValue: '=',
      maxValue: '=',
      isCompleted: '=',
      mobileView: '=',
      type: '=?',
      activeColor: '=?',
      context: '=?',
      course: '=?',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
      vm.CurrentCourseManager = CurrentCourseManager;
      vm.isRtl = nvUtil.isRtl();
      vm.barStyles = barStyles;
      vm.startProgressBarAnimation = false;

      configureProgressBar();

      function configureProgressBar() {
        vm.fraction = (vm.currentValue / vm.maxValue).toFixed(2);
        vm.barStyles = barStyles;

        if (vm.fraction >= 1) {
          vm.fraction = 1;
        }
        vm.startColor = getColorBetweenColorsByPercentage('#fff', vm.activeColor, 0.4);
        vm.endColor = vm.activeColor;
      }

      function barStyles() {
        if (vm.fraction === 1) {
          return {
            width: '100%',
            backgroundColor: vm.activeColor,
          };
        }
        return {
          width: `${vm.startProgressBarAnimation ? 100 * vm.fraction : 0}%`,
          backgroundColor: vm.endColor,
          background: `linear-gradient(90deg, ${vm.startColor} 0%, ${vm.endColor} 100%)`,
        };
      }

      // Start progress bar on the next tick so animation will work
      $timeout(() => {
        vm.startProgressBarAnimation = true;
      }, 0);

      /**
       * Watch for changes for max and current Value
       */
      $scope.$watchCollection(
        () => [
          vm.currentValue,
          vm.maxValue,
        ],
        (newValues, oldValues) => {
          if (newValues && newValues !== oldValues) {
            configureProgressBar();
          }
        },
      );
    },
    replace: true,
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'course_home/templates/nv-criteria-progress-bar.html',
  };
}
