import React from 'react';
import { jsx, css } from '@emotion/react';
import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import NvDatepicker, { DatePickerType } from 'shared/components/inputs/nv-datepicker';
import moment, { Moment } from 'moment';
import NvPopover from 'shared/components/nv-popover';
import { halfSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import { getCourse, getCurrentCourse } from 'redux/selectors/course';
import { useSelector } from 'react-redux';
import NvTooltip from 'shared/components/nv-tooltip';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import { debounce } from 'underscore';
import { getLecturePage } from 'redux/selectors/timeline';
import { config } from '../../config/pendo.config.json';

import ClickableContainer from './clickable-container';

interface LectureComponentDeadlineProps {
  isEdit: boolean;
  expirationDate: string;
  catalogId: string;
  lecturePageId: number;
  hasHardDeadlines: boolean;
  onHardDeadlineChange(value: boolean): void;
  onDeadlineChange(value: Moment): void;
  componentName: string;
  isDeadlineEditable: boolean;
}

/* @ngInject */
export default function LectureComponentDeadline(props: LectureComponentDeadlineProps) {
  const course = useSelector((state) => getCurrentCourse(state));
  const lecturePage = useSelector((state) => getLecturePage(state, props.lecturePageId));
  const [isInputFocused, setIsInputFocused] = React.useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);

  const isEditing = isCalendarOpen || isInputFocused;

  const momentNow = moment();
  const deadLineMoment = moment(props.expirationDate);
  const releaseDateMoment = moment(lecturePage.releaseDate);

  const isDeadlineBeforeRelease = deadLineMoment.isBefore(releaseDateMoment);
  const isDueSoon = deadLineMoment.isAfter(momentNow) && deadLineMoment.isBefore(momentNow.clone().add(7, 'days').endOf('day'));

  return (
    <div className='d-flex justify-content-center align-items-center'>
      {props.isEdit && isDeadlineBeforeRelease && (
        <NvPopover
          content={(
            <div>
              <div className='text-center color-alert'>{t.FORM.WARNING()}</div>
              <div
                css={css`
                  margin-top: ${quarterSpacing}px;
                `}
              >
                {t.LECTURE_PAGES.COMPONENTS.RELEASE_DEADLINE_CONFLICT({
                  lectureAlias: course.lectureName.downcasedSingularized,
                })}
              </div>
            </div>
          )}
          showOnHover
          placement='top'
        >
          <NvIcon
            css={css`
              margin-right: ${quarterSpacing}px;
            `}
            icon='warning'
            size='smallest'
            className='color-alert'
          />
        </NvPopover>
      )}
      {isEditing ? (
        <React.Fragment>
          <NvDatepicker
            usePortal
            name={`${props.componentName}-deadline`}
            type={DatePickerType.DATETIME}
            value={moment(props.expirationDate)}
            onChange={debounce((val) => {
              props.onDeadlineChange(val);
            }, 0)}
            placement='top-start'
            autoFocus
            onCalendarClose={() => {
              // this setState needs to be wrapped by the setTimeout to run it
              // asynchronously so that it won't collapse the input before it
              // triggers the onFocus event
              setTimeout(() => {
                setIsCalendarOpen(false);
              }, 0);
            }}
            onCalendarOpen={() => {
              // this setState needs to be wrapped by the setTimeout to run it
              // asynchronously and match the close event preventing a race
              // conditions when the date picker is toggle quickly
              setTimeout(() => {
                setIsCalendarOpen(true);
              }, 0);
            }}
            onFocus={() => {
              setIsInputFocused(true);
            }}
            onBlur={() => {
              setIsInputFocused(false);
            }}
          />
          <div
            className='react-datepicker-ignore-onclickoutside'
            onMouseDown={(e) => {
              e.preventDefault();
            }}
          >
            <NvCheckbox
              name={`${props.componentName}-hard-deadline`}
              checked={props.hasHardDeadlines}
              onChange={(e) => {
                props.onHardDeadlineChange(e.target.checked);
              }}
              css={css`
                margin: 0 ${halfSpacing}px;
              `}
              label={t.LECTURE_PAGES.COMPONENTS.QUIZ.HARD_DEADLINE()}
            />
          </div>
          <NvTooltip
            text={t.LECTURE_PAGES.COMPONENTS.DUE_DATE.HARD_DEADLINE_DESCRIPTION({
              learnerAlias: course.learnersName.downcasedPluralized,
            })}
          >
            <div>
              <NvIcon icon='info' size='smallest' className='color-primary' />
            </div>
          </NvTooltip>
        </React.Fragment>
      ) : (
        props.expirationDate && (
          <NvTooltip text={t.LECTURE_PAGES.COMPONENTS.DUE_DATE.EDIT_POPOVER()} enabled={props.isDeadlineEditable}>
            <ClickableContainer
              layoutOnly={!props.isDeadlineEditable}
              onClick={() => {
                // toggles the edit mode. Will cause the input to render and
                // automatically focuses because of the autofocus prop
                setIsInputFocused(true);
              }}
              className={`inline-block ${isDueSoon ? 'color-alert' : 'gray-2'}`}
              css={css`
                font-weight: 600;
                font-size: 12px;
              `}
              data-qa={config.pendo.lecturePage.deadline}
            >
              {t.LECTURE_PAGES.COMPONENTS.DUE_DATE.STUDENT_VIEW(moment(props.expirationDate).format('lll'))}
            </ClickableContainer>
          </NvTooltip>
        )
      )}
    </div>
  );
}
