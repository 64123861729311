import React from 'react';
import axios from 'axios';

export enum TabName {
  TODOS = 'todos',
  ARCHIVED = 'archived',
  DRAFTS = 'activeDrafts',
  COMPLETED = 'completed',
  DISCOVERY = 'discovery',
  IN_PROGRESS = 'inProgress',
  SUBMISSIONS = 'submissions',
}

const initialSummary = {
  [TabName.IN_PROGRESS]: {
    totalCount: 0,
    closingSoonCount: 0,
  },
  [TabName.DISCOVERY]: {
    totalCount: 0,
  },
  [TabName.DRAFTS]: {
    totalCount: 0,
    dueSoonCount: 0,
  },
  [TabName.TODOS]: {
    totalCount: 0,
  },
  [TabName.SUBMISSIONS]: {
    totalCount: 0,
    revisionNeededCount: 0,
    pendingApprovalCount: 0,
    recentlyApprovedCount: 0,
  },
  [TabName.COMPLETED]: {
    totalCount: 0,
    withCertificateCount: 0,
  },
  [TabName.ARCHIVED]: {
    totalCount: 0,
  },
};

type Summary = typeof initialSummary;

const useDashboardSummary = () => {
  const [isSummaryLoading, setIsSummaryLoading] = React.useState(false);
  const [currentSummary, setCurrentSummary] = React.useState<Summary>(initialSummary);

  const loadSummaryRef = React.useRef(() => {
    setIsSummaryLoading(true);
    setCurrentSummary(initialSummary);

    return axios.get('/users/dashboard_summary.json').then((response) => {
      const { result: summary } = response.data;

      setIsSummaryLoading(false);
      setCurrentSummary(summary);

      return summary;
    });
  });

  return {
    currentSummary,
    isSummaryLoading,
    loadSummary: loadSummaryRef.current,
  };
};

export default useDashboardSummary;
