import LectureComponentModalCtrl from 'lecture_pages/controllers/lecture-component-modal-controller';
import React, { useContext, useCallback } from 'react';
import { AngularServicesContext } from 'react-app';
import { ComponentTrueType, ComponentType, LectureComponent } from 'redux/schemas/models/lecture-component';
import { embedAngularTemplate } from 'shared/embed-angular';
import { useSelector } from 'react-redux';
import { getCurrentCourse } from 'redux/selectors/course';
import { DeepPartial } from 'utility-types';
import { NovoAIItemType } from 'redux/schemas/models/lecture-page';
import { ModalWorkflowContext, ModalWorkflowHandlerProps } from './modal-workflow';
import { loadAngularLectureComponentModel } from '../content-area/angular-lecture-component';
import LecturePageContext from '../lecture-page-context';
import { ModalWorkflow } from '.';

type AngularModalContentProps = {
  /** TODO: This is incorrect. See the comments around lectureComponentDraft in modal-workflow.tsx */
  lectureComponent?: ModalWorkflowHandlerProps['lectureComponent'],
  // Adding afterCreate function that is in the lecture component model.
  onConfirm: (
    lectureComponent?: LectureComponent,
    skipSave?: boolean,
    customType?: ComponentTrueType,
    customWorkflow?: DeepPartial<ModalWorkflow<ComponentTrueType>>
  ) => Promise<void>,
  onCancel: () => void,
} & Omit<ModalWorkflowHandlerProps, 'lectureComponent'>;

const AngularModalContent: React.FC<AngularModalContentProps> = ({
  workflow,
  mode,
  lectureComponent,
  onConfirm,
  onCancel,
}) => {
  const { currentCatalogId } = useSelector(state => state.app);
  const currentCourse = useSelector(getCurrentCourse);
  const angularServices = useContext(AngularServicesContext);
  const workflowCtx = useContext(ModalWorkflowContext);
  const lecturePageCtx = useContext(LecturePageContext);
  const { angularConfig } = workflow.initialSettings;

  const angularRef = useCallback(node => {
    if (node && angularConfig) {
      const { controllerFunction = LectureComponentModalCtrl, templateUrl } = angularConfig;
      const courseManager: any = angularServices.$injector.get('CurrentCourseManager');

      if (mode === 'new' && lectureComponent.type === ComponentType.TOPIC && lectureComponent.aiOrigin !== NovoAIItemType.DISCUSSION) {
        // Quick hack to support making a modal with the "AbstractDiscussionLectureComponent" type before the user chooses between Course Discussion and Team Discussion. The trueType paramenter will keep the original ComponentType.TOPIC value
        (lectureComponent.type as any) = 'AbstractDiscussionLectureComponent';

        // Updating property hasCourseLongTeamSet from redux, just in case the next component is Team Discussion with Team Formation included
        courseManager.course.hasCourseLongTeamSet = currentCourse.hasCourseLongTeamSet;
      }

      let angularComponentModel: InstanceType<ReturnType<typeof loadAngularLectureComponentModel>> = null;

      // Either grab the existing angular lecture component if editing or create a new one if we're making an new component
      if (mode === 'new') {
        angularComponentModel = new (loadAngularLectureComponentModel(angularServices.$injector, lectureComponent as any))(lectureComponent, false, true);
        angularComponentModel.catalogId = currentCatalogId;
      } else {
        angularComponentModel = angularServices.$scope[`lectureComponent${lectureComponent.id}`];
      }

      // An object containing extra inejectable dependencies that the component modals expect. These are any items that Angularjs can't look up via service name and are normally provided by the $uibModal.open() call. Adapted from lecture-components-helper.js
      const extraControllerDeps = {
        vmResolves: {
          formName: angularConfig.formName,
          CurrentCourseManager: courseManager,
          validationConstants: angularServices.$injector.get('validationConstants'),
          lectureComponent: angularComponentModel,
          workflowCtx,
          lecturePageCtx,
        },

        $uibModalInstance: {
          close: (closeWithoutCreate: boolean, options: any) => closeModal(closeWithoutCreate, options),
          dismiss: () => signalModalClosing(true),
        },
      };

      const modalScope = embedAngularTemplate(templateUrl, { current: node }, angularServices, controllerFunction, extraControllerDeps, true);

      /** `closeWithoutCreate` allows angular modals to be closed without
       * triggering a new component creation/update. Used by modals that need to only add
       * a new component after a file upload is finished */
      const closeModal = (closeWithoutCreate?: boolean, options?: any) => {
        signalModalClosed();
        // The asignment of realComponent has been created in order to support the AbstractDiscussionLectureComponent special case that is present in the creation of Course Discussion and Team Discussion. It has been written here because this is the step before starting the "saving" information process.
        // CustomType is also assigned here for that reason. This customType variable helps to "override" the value 'type' in the payload for the endpoint calling, because the original value is taken from the component selection in the left side panel and there is no simple way to chage it without modifiying the new architecture.
        // The private evaluation also chooses the component type on the creation modal. So used the real component solution for that also
        let customType = null;
        if (
          modalScope.vm.lectureComponent.type === 'AbstractDiscussionLectureComponent'
          || (mode === 'new'
          && (modalScope.vm.lectureComponent.type === ComponentType.PUBLIC_PEER_EVALUATION
            || modalScope.vm.lectureComponent.type === ComponentType.PRIVATE_PEER_EVALUATION
            || modalScope.vm.lectureComponent.type === ComponentType.EXERCISE_SKILLS_RATING
            || modalScope.vm.lectureComponent.type === ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK
          ))
        ) {
          modalScope.vm.lectureComponent = modalScope.vm.lectureComponent.realComponent;
          customType = modalScope.vm.lectureComponent.type;
        }

        // Many lecture component models use a draft object to hold temporary changes to the component's data made during edits to the modal. Previously, that saveDraft() would itself trigger the API request to create/update the model, but this has been removed
        modalScope.vm.lectureComponent.saveDraft?.();

        // Fire the lecture component save callback in the next digest cycle. This gives this modal time to close via the 'modal.content.closed' event handler in modal-workflow.tsx before this occurs. Failing to wait here will cause us to do an Admin 1.0 page navigation (if configured) before the modal is closed, meaning the modal's edit form is still open and set dirty. In that situation a "do you want to leave this page?" dialog appears, incorrectly
        angularServices.$timeout(() => {
          modalScope.vm.lectureComponent.saveDraft?.();

          onConfirm({
            ...modalScope.vm.lectureComponent.getPayload(true),
          }, closeWithoutCreate, customType).then(() => {
            /** Historically, components would often call save() at the end of their
             * saveDraft() calls, which would then itself call __preprocess() in the promise
             * result.
             *
             * I'm not finding a great spot to put that logic, so for now it's going here. */
            if (!closeWithoutCreate) {
              modalScope.vm.lectureComponent.__preprocess();
              options?.onSuccess?.();
            }
          });
        });
      };

      const dismissModal = () => {
        signalModalClosed();
        onCancel();

        // Reset the component back to the initial value
        modalScope.vm.lectureComponent = lectureComponent;
        modalScope.vm.lectureComponent?.createDraft?.();
        modalScope.vm.lectureComponent?.resetActivityDraft?.();
      };

      // Manually broadcast (signal down the scope hierarchy) this $uibModal closing event
      const signalModalClosing = (isDismiss: boolean) => {
        const event = modalScope.$broadcast('modal.closing');

        if (!event.defaultPrevented) {
          if (isDismiss) {
            dismissModal();
          } else {
            closeModal();
          }
        }
      };

      const signalModalClosed = () => {
        modalScope.$emit('modal.content.closed');
        // Manually trigger destroying the modal; cleanup occurs via a scope.$on('$destroy... inside lecture-component-modal-controller.js. Failing to schedule $destroy in the next $digest cycle via $timeout causes an error during $broadcast
        angularServices.$timeout(() => {
          modalScope.$destroy();
        });
      };

      // Set Angularjs $scope values to provide an API for closing the modal, saving the new component, etc. Prior to this React rewrite these actions where handled by the $uibModalInstance, but we can't inject that as it's created by $uibModal which we don't use here
      modalScope.$dismiss = () => signalModalClosing(true);

      // This doesn't seem to be used anywhere
      modalScope.$close = () => signalModalClosing(false);

      // Listen to an event from ModalWorkflowHandler signalling the React modal's X button was clicked
      modalScope.$on('react.modal.closing', modalScope.$dismiss);

      // Update the modal view based on any calcs that occur during controller initialization
      modalScope.$digest();
    }
  }, []);

  return <div ref={angularRef} />;
};

export default AngularModalContent;
