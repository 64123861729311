import { createReducer } from '@reduxjs/toolkit';
import { setCourseAccessModalProps, resetCourseAccessModalProps } from 'redux/actions/course-access-modal';
import { initialRootState } from '.';

export const courseAccessModalInitialState = {
  catalogId: '',
};

export default createReducer(initialRootState, builder => {
  builder
    .addCase(setCourseAccessModalProps, (state, action) => {
      state.app.courseAccessModalProps = action.payload;
    })
    .addCase(resetCourseAccessModalProps, (state, action) => {
      state.app.courseAccessModalProps = courseAccessModalInitialState;
    });
});
