import moment from 'moment';
import { createSelector } from 'reselect';
import { Enrollment } from 'redux/schemas/models/my-account';
import { RootState } from 'redux/schemas';
import { Course } from 'redux/schemas/models/course';
import { compareHexColors } from 'styles/global_defaults/colors';
import { getCoursesArray } from './course';
import { getCurrentUserEnrollments } from './users';

export const getJourney = (state: RootState, catalogId: string) => state.models.courses[catalogId];

export const getCurrentJourney = (state: RootState) => state.models.courses[state.app.currentCatalogId] ?? null;

export const getCurrentUserJourneys = createSelector(
  getCoursesArray,
  state => state.models.users[state.app.currentUserId]?.enrollmentIds ?? [],
  state => state.models.enrollments,
  (courses, enrollmentIds, enrollments) => {
    const userEnrollments = enrollmentIds.map(id => enrollments[id]);
    return courses.filter(({ id, isJourney }) => userEnrollments.find(({ courseId }) => id === courseId) && isJourney);
  },
);

export const getJourneyCollections = createSelector(
  (state: RootState, journeyCatalogId: string) => state.models.courses[journeyCatalogId]?.collectionIds ?? [],
  (state) => state.models.journeyCollections,
  (collectionIds, journeyCollections) => collectionIds.map(
    collectionId => journeyCollections[collectionId],
  ),
);

export const getCurrentJourneyCollections = createSelector(
  getCurrentJourney,
  (state) => state.models.journeyCollections,
  (state) => state.models.courses,
  (currentJourney, journeyCollections, courses) => {
    const { collectionIds } = courses[currentJourney.catalogId];
    return collectionIds ? collectionIds.map(collectionId => journeyCollections[collectionId]) : [];
  },
);

export const getInProgressCoursesFromJourneyCatalogId = createSelector(
  (state: RootState, journeyCatalogId: string) => getJourneyCollections(state, journeyCatalogId),
  getCoursesArray,
  getCurrentUserEnrollments,
  (journeyCollections, courses, enrollments) => {
    const courseIds: number[] = journeyCollections.reduce((ids, collection) => (collection.courseIds ? ids.concat(collection.courseIds) : ids), []);
    const journeyCoursesAndEnrollments = courseIds.map<[Course, Enrollment]>(courseId => {
      const journeyCourse: Course = courses.find((course: Course) => course.id === courseId);
      const journeyEnrollment: Enrollment = enrollments.find(enrollment => enrollment.courseId === journeyCourse.id);

      return [journeyCourse, journeyEnrollment];
    }).filter(([course]) => Boolean(course));

    return journeyCoursesAndEnrollments
      .filter(([course, enrollment]) => {
        const isOfferingCloseDatePast = moment(course.closeDate).isBefore(moment());

        return enrollment && !enrollment.completionStatus && !isOfferingCloseDatePast;
      })
      .sort(([courseA, enrollmentA], [courseB, enrollmentB]) => moment(enrollmentB.enrolledAt).diff(moment(enrollmentA.enrolledAt)))
      .map(([course]) => course);
  },
);

export const getEnrollmentsOfJourneyCourses = createSelector(
  (state: RootState, journeyCatalogId: string) => getJourneyCollections(state, journeyCatalogId),
  getCurrentUserEnrollments,
  (journeyCollections, enrollments) => {
    const courseIds: number[] = journeyCollections.reduce((ids, collection) => (collection.courseIds ? ids.concat(collection.courseIds) : ids), []);
    return courseIds.map(courseId => enrollments.find(enrollment => enrollment.courseId === courseId)).filter(Boolean);
  },
);

export const getInProgressCoursesAndJourneysFromIds = createSelector(
  (state: RootState, courseIds: string[]) => courseIds,
  state => state.models.courses,
  (courseIds, courses) => {
    const allCourses: Course[] = courseIds.map(courseId => courses[courseId]);
    const coursesAndPrograms = allCourses.filter(course => !course.isJourney).reverse();
    const journeys = allCourses.filter(course => course.isJourney).reverse();

    return [coursesAndPrograms, journeys];
  },
);

export const getVisitedLearningJourney = createSelector(
  (state: RootState) => state.app.visitedLearningJourney,
  (journey) => ({
    ...journey,
    sameColors: journey?.catalogId ? compareHexColors(journey?.nameFontColor ?? '#fff', journey?.headerColor ?? '') : null,
  }),
);
