import React, { CSSProperties, useContext } from 'react';
import moment from 'moment';
import t from 'react-translate';
import { css } from '@emotion/react';
import { AngularServicesContext } from 'react-app';

import { Submission } from 'redux/schemas/models/submissions';
import { User } from 'redux/schemas/models/my-account';

import BaseCard, { CARD_WIDTH } from 'offerings/components/base-card';
import NvIcon from 'shared/components/nv-icon';
import { NvUserAvatar } from 'components/nv-user-avatar';
import { timeSince } from 'shared/utils';

import { threeQuartersSpacing, standardSpacing, cardIconSize, tripleSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { openSans, openSansCondensed, semiBoldFontWeight, textLargeFontSize, textMediumFontSize, textMediumLineHeight, textSmallFontSize } from 'styles/global_defaults/fonts';
import { danger, gray2, primary } from 'styles/global_defaults/colors';

import ProgressIcon from '../../../dashboard/components/progress-icon';

const styles = css`
  display: block;
  max-width: ${CARD_WIDTH}px;

  &:hover {
    color: unset;
  }

  .submission-draft-card {
    width: 100%;
    background-color: white;

    &:hover {
      border: 1px solid ${primary};
    }

    .card-header {
      display: flex;
      height: auto;
      justify-content: center;
      padding: ${threeQuartersSpacing}px ${standardSpacing}px;

      .backdrop-layer {
        position: relative;
      }

      .card-title {
        text-align: center;
        font-size: ${textMediumFontSize}px;
        line-height: ${textMediumLineHeight}px;
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ${halfSpacing}px;

      .draft-icon {
        width: ${cardIconSize}px;
        height: ${cardIconSize}px;
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: ${tripleSpacing}px;
        padding: ${halfSpacing}px ${threeQuartersSpacing}px;
        font-size: ${textLargeFontSize}px;
        font-family: ${openSansCondensed};
      }

      .last-updated {
        font-size: ${textSmallFontSize}px;
        font-family: ${openSans};
        color: ${gray2};
      }
    }

    .extra {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: ${halfSpacing}px;

      .due-date {
        font-size: ${textSmallFontSize}px;
        font-weight: ${semiBoldFontWeight};
        justify-self: start;

        &.due-soon {
          color: ${danger};
        }
      }

      .avatar {
        justify-self: end;
      }
    }
  }
`;

type NvSubmissionDraftCardProps = {
  submission: Submission;
  style: CSSProperties;
  className?: string;
};

const NvSubmissionDraftCard = React.forwardRef<HTMLAnchorElement, NvSubmissionDraftCardProps>((props, ref) => {
  const { submission, style, className } = props;
  const { $state } = useContext(AngularServicesContext);


  const deadline = submission.exercise.deadline && moment(submission.exercise.deadline);
  const deadlineFormatted = deadline?.format('DD/MM [at] LT');
  const currentMoment = moment();

  const showDueSoon = deadline
    && deadline > currentMoment
    && deadline < moment().add(7, 'days');

  const renderHeader = () => <div className='card-title'>{submission.exercise.title}</div>;

  const renderContent = () => (
    <React.Fragment>
      <div className='draft-icon'>
        <ProgressIcon>
          <NvIcon icon='drafts' size='medium-larger' />
        </ProgressIcon>
      </div>
      <div className='title'>
        {submission.title}
      </div>
      <div className='last-updated'>
        {timeSince(submission.lastSavedAt)}
      </div>
    </React.Fragment>
  );

  const renderExtra = () => (
    <div className='extra'>
      <div className={`due-date ${showDueSoon ? 'due-soon' : ''}`}>
        {deadline && `${t.DASHBOARD.DUE()} ${deadlineFormatted}`}
      </div>
      <div className='avatar'>
        <NvUserAvatar
          size='sm'
          borderType='round'
          user={submission.owner as User}
        />
      </div>
    </div>
  );

  return (
    <a
      ref={ref}
      css={styles}
      style={style}
      href={$state.href('lecture-page', {
        catalogId: submission.exercise.catalogId,
        id: submission.exercise.lecturePageId,
        lectureActivityId: submission.exercise.lectureComponentId,
      })}
    >
      <BaseCard
        layoutOnly
        className={`submission-draft-card ${className}`}
        renderHeader={renderHeader}
        renderContent={renderContent}
        renderExtraContent={renderExtra}
      />
    </a>
  );
});

export default NvSubmissionDraftCard;
