import * as Sentry from '@sentry/browser';
import t from '../../../react-translate';

import store from '../../../redux/store';
import { updateLectureComponentFromAngular } from '../../../redux/actions/lecture-components';

/* @ngInject */
export default function ExerciseLectureComponentModelService(
  _,
  moment,
  $timeout,
  $translate,
  $window,
  nvUtil,
  RailsRoutes,
  config,
  CurrentCourseManager,
  CurrentUserManager,
  ExerciseModel,
  ExercisesResources,
  ReportModel,
  LectureComponentBaseModel,
  $state,
) {
  class ExerciseLectureComponentModel extends LectureComponentBaseModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      const { revise, reportId } = $state.params;
      const defaults = {
        type: 'ExerciseLectureComponent',
        editFormTemplateUrl: 'lecture_pages/templates/components/exercise-edit-modal.html',
        editFormController: 'ExerciseEditFormModalCtrl',
        exercise: {
          title: CurrentCourseManager.course.assignmentName.capitalizedSingularized,
          description: '',
          deadline: null,
          hardDeadline: false,
          privacySetting: ExerciseModel.DEFAULT_PRIVACY_SETTING_OPTIONS,
          teamType: null,
          teamSizeLimit: 10,
          isTodo: false,
          name: CurrentCourseManager.course.teamName.capitalizedPluralized,
          formedByStudents: null,
        },
        updateExercisesAfterCreationDeletion: true,
        isActivity: true,
        canBeCopied: true,
      };
      super(_.extend({}, defaults, attributes));

      this.__preprocess();

      if (useDefaults) {
        this.createDraft();
      }
      if (revise) {
        this.revise(reportId);
      }
    }

    __checkCurrentReportPrivacyMismatch() {
      if (this.currentReport) {
        this.currentReport.__originalExercisePrivacySetting = this.exercise.privacySetting;
        this.currentReport.__originalExercisePrivacyOptions = this.exercise.privacyOptions;
        this.currentReport.__originalReportPrivacySetting = this.currentReport.privacySetting;

        if (!this.currentReport.privacySetting
            || (this.currentReport.privacySetting === 'shared_with_instructor' && _.contains(['sharable_with_class_only', 'sharable_with_public_only'], this.exercise.privacySetting))) {
          Sentry.configureScope((scope) => {
            scope.setExtras({
              user_id: CurrentUserManager.user.id,
              exercise_id: this.exercise.id,
              exercise_privacy_setting: this.exercise.privacySetting,
              exercise_privacy_options: this.exercise.privacyOptions,
              exercise_original_data: this.exercise.__originalData,
              lecture_page_original_data: this.lecturePage?.__originalLpData,

              report_id: this.currentReport.id || 'new report',
              privacy_setting: this.currentReport.privacySetting,
            });
          });

          Sentry.captureException(new Error('Submission Privacy: Initial - Privacy Mismatch'));
        }
      }
    }

    __preprocess() {
      if (!_.isEmpty(this.currentReport)) {
        this.currentReport = new ReportModel(this.currentReport);
      }

      this.exercise.__originalData = JSON.stringify(this.exercise);
      this.exercise = new ExerciseModel(_.extend(this.exercise, { catalogId: this.catalogId }));
      this.scheduledCommunicationsCount = this.exercise.scheduledCommunicationsCount;

      if (_.first(this.exercise.unsubmittedSubmissions)) {
        this.currentReport = _.first(this.exercise.unsubmittedSubmissions);
        this.editingExercise = true;

        this.__checkCurrentReportPrivacyMismatch();
      } else if (_.isEmpty(this.exercise.submissions) && this.exercise.template?.sections.length) {
        this.startNewReport();
      }

      $timeout(() => {
        angular.element(window).trigger('checkInView');
      });
    }

    /* Student Functions */
    startNewReport() {
      ReportModel.newReport(this.exercise).then((report) => {
        this.currentReport = report;
        this.editingExercise = true;
      });

      this.__checkCurrentReportPrivacyMismatch();
    }

    revise(reportId) {
      const report = _.findWhere(this.exercise.submissions, { id: reportId });

      if (report) {
        this.currentReport = report;
        this.editingExercise = true;

        // TODO: Re-enable these two lines
        // this.currentReportCopy = angular.copy(this.currentReport);

        // this.__checkCurrentReportPrivacyMismatch();
      }
    }

    getStatus() {
      return this.exercise.progress;
    }

    isCompleted() {
      return this.getStatus() === 'completed';
    }

    currentTotalPoints() {
      return nvUtil.getCurrentTotalPoints(this.exercise.totalPoints, this.releaseDate, CurrentCourseManager.course.isDecayEnabled());
    }

    /* Admin Functions */
    createDraft(roles = []) {
      this.exerciseDraft = _.clone(this.exercise);

      this.exerciseDraft.adminReviewers = [];
      this.exerciseDraft.relationshipReviewers = [];

      _.each(roles, (role) => {
        const reviewer = _.findWhere(this.exerciseDraft.reviewers, { id: role.id });
        const roleToAdd = _.extend({ isEnabled: !!(reviewer?.isEnabled) }, role);

        if (role.isMentor()) {
          this.exerciseDraft.relationshipReviewers.push(roleToAdd);
        } else {
          this.exerciseDraft.adminReviewers.push(roleToAdd);
        }
      });
    }

    save(...args) {
      if (!this.exercise.title) {
        this.exercise.title = $translate.instant('LECTURE_PAGES.COMPONENTS.UNTITLED');
      }

      return super.save(...args).then(() => this);
    }

    getPayload() {
      return {
        exercise: _.pick(this.exercise, ['id', 'title', 'description', 'deadline', 'hardDeadline', 'privacySetting', 'teamType', 'formedByStudents', 'isTodo', 'teamSizeLimit', 'name']),
      };
    }

    getSavingMessage() {
      if (this.addingTeamFormationExercise) {
        return $translate.instant('LECTURE_PAGES.COMPONENTS.EXERCISE.TEAM_FORMATION_AND_ASSIGNMENT_ADDING', CurrentCourseManager.course.getAliases());
      }

      return null;
    }

    saveDraft() {
      if (this.exerciseDraft.teamType === 'course_long'
            && (!this.exercise.teamSet || !this.exercise.teamSet.isCourseLong)
            && !CurrentCourseManager.course.courseLongTeamSet) {
        this.addingTeamFormationExercise = true;
      } else {
        this.addingTeamFormationExercise = false;
      }
      _.extend(this.exercise, this.exerciseDraft);
    }

    saveAdvancedOptions() {
      Object.assign(this.exercise, this.exerciseDraft);

      return ExercisesResources.editAdvancedOptions({ catalogId: this.catalogId, id: this.exercise.id }, {
        exercise: {
          numberSub: this.exercise.numberSub,
          isVotable: this.exercise.isVotable,
          submissionsViewableBeforeDeadline: this.exercise.submissionsViewableBeforeDeadline,
          promptShareSubmission: this.exercise.promptShareSubmission,
        },
      }).$promise.then((response) => {
        store.dispatch(updateLectureComponentFromAngular({
          exercise: { numberSub: this.exercise.numberSub, ...response.result.exercise },
        }));
        this.__preprocess();
      });
    }

    get showCreateConfirmation() {
      return this.addingTeamFormationExercise;
    }

    get createConfirmationTemplate() {
      return 'lecture_pages/templates/components/exercise-creation-confirmation-overlay.html';
    }

    afterCreate(catalogId) {
      $window.location = RailsRoutes.editSubmissionFormatPath(catalogId, this.exercise.id);
    }

    moveToWarningMessages(targetLecturePage) {
      const warningMessages = super.moveToWarningMessages(targetLecturePage);

      if (this.lecturePage.released && !targetLecturePage.released) {
        warningMessages.push($translate.instant('LECTURE_PAGES.COMPONENTS.EXERCISE.MOVE.UNRELEASE', CurrentCourseManager.course.getAliases()));
      }

      if (this.exercise.deadline && moment(this.exercise.deadline) < moment(targetLecturePage.releaseDate)) {
        warningMessages.push($translate.instant('LECTURE_PAGES.COMPONENTS.EXERCISE.MOVE.DEADLINE', CurrentCourseManager.course.getAliases()));
      }

      if (this.exercise.teamSet?.isCourseLong && moment(targetLecturePage.releaseDate) < moment(this.exercise.teamSet.releaseDate)) {
        warningMessages.push($translate.instant('LECTURE_PAGES.COMPONENTS.EXERCISE.MOVE.COURSE_LONG_TEAM', CurrentCourseManager.course.getAliases()));
      }

      return warningMessages;
    }

    isTodo() {
      return this.exercise.isTodo;
    }

    toggleToDo() {
      this.exercise.isTodo = !this.exercise.isTodo;
      this.save();
    }

    hasDeadline() {
      return !!this.exercise.deadline;
    }

    addDefaultDeadline() {
      this.exercise.deadline = this.defaultDeadline;
      return this.save();
    }

    removeDeadline() {
      this.exercise.deadline = null;
      return this.save();
    }

    get deadline() {
      return this.exercise.deadline;
    }

    isDueSoon() {
      return super.isDueSoon(this.exercise.deadline);
    }

    isRequiredForCompletion() {
      return this.exercise.isRequiredForCompletion;
    }

    teamFormationReleasedAfterAndUnreleased() {
      if (this.exercise.teamSet) {
        const teamSetReleaseMoment = moment(this.exercise.teamSet.releaseDate);
        return moment() < teamSetReleaseMoment && moment(this.lecturePage.releaseDate) < teamSetReleaseMoment;
      }

      return false;
    }

    saveSubmissionReviewers() {
      return this.exercise.saveSubmissionReviewers(this.exerciseDraft);
    }

    updateComponentStatus(componentType, componentId, data) {
      if (componentType === 'exercise_approval' && componentId === this.exercise.id) {
        this.updateReport(data.report);
      }
    }

    updateReport(report) {
      if (this.exercise.id === report.exercise.id && this.exercise.approvalRequired) {
        this.exercise.progress = report.exercise.progress;
        this.exercise.pointsReceived = report.exercise.pointsReceived;
        const existingReport = _.findWhere(this.exercise.submissions, { id: report.id });

        _.extend(existingReport, _.pick(report, 'submittingObject', 'reviews', 'editable'));
        existingReport.hasViewed = true; // when it's updated from elsewhere, don't show points modal

        if (this.currentReportCopy) {
          _.extend(this.currentReportCopy, _.pick(report, 'submittingObject', 'reviews', 'editable'));
          this.currentReportCopy.hasViewed = true;
        }
      }
    }

    updateReportSection(report, reportSectionId) {
      if (this.exercise.id !== report.exercise.id) {
        return false;
      }

      const existingReport = _.findWhere(this.exercise.submissions, { id: report.id });

      if (!existingReport.currentRevision) {
        return false;
      }

      const existingSectionIndex = _.findIndex(existingReport.currentRevision.sections, (section) => section.reportSection?.id === reportSectionId);

      if (existingSectionIndex < 0) {
        return false;
      }

      const updatedSection = _.findWhere(report.currentRevision.sections, { id: reportSectionId });

      _.extend(existingReport.currentRevision.sections[existingSectionIndex].reportSection, _.pick(updatedSection, 'file', 'uniqueId', 'originalUrl', 'response'));

      if (this.currentReportCopy) {
        _.extend(this.currentReportCopy.currentRevision.sections[existingSectionIndex].reportSection, _.pick(updatedSection, 'file', 'uniqueId', 'originalUrl', 'response'));
      }

      return true;
    }
  }

  ExerciseLectureComponentModel.iconClass = 'icon-assignments';
  ExerciseLectureComponentModel.descriptionKey = 'LECTURE_PAGES.COMPONENTS.EXERCISE.DESCRIPTION';
  ExerciseLectureComponentModel.showModalBeforeCreate = true;
  ExerciseLectureComponentModel.pendoTagName = config.pendo.lectureEdit.assignment;

  return ExerciseLectureComponentModel;
}
