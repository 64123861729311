/* @ngInject */
export default function LearnerDirectoryMainController(
  CurrentUserManager,
  LearnerDirectoryManager,
  $stateParams,
  CurrentCourseManager,
  InteroperabilityRoutes,
  $rootScope,
  config,
) {
  const vm = this;

  vm.currentUserManager = CurrentUserManager;
  vm.manager = LearnerDirectoryManager;
  vm.currentCourseManager = CurrentCourseManager;
  vm.interoperabilityRoutes = InteroperabilityRoutes;
  vm.config = config;

  vm.getShowLocation = (userId) => {
    const userInfo = vm.manager.users.find((eachUserInfo) => eachUserInfo.user.id === userId);

    return (
      !CurrentCourseManager.course.institution.profileSettings.accountLevel.location.isHidden
        && !!userInfo.user.displayLocation
    );
  };


  if (LearnerDirectoryManager.initialized && LearnerDirectoryManager.resetOnLoad !== false) {
    LearnerDirectoryManager.resetData();
  }

  LearnerDirectoryManager.initialize({
    catalogId: $stateParams.catalogId,
    dropdownFilter: $stateParams.learnerFilter,
    context: null,
  });

  LearnerDirectoryManager.fetchProfileQuestionFilters().then(LearnerDirectoryManager.doSearch());

  $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
    if (
      LearnerDirectoryManager.initialized
        && fromState.name === 'learner-directory'
        && toState.name === 'learner-directory'
        && toParams.catalogId === fromParams.catalogId) {
      LearnerDirectoryManager.resetOnLoad = false;
    }
  });
}
