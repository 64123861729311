import * as Sentry from '@sentry/browser';
import { ViewOptions, ComponentType } from 'redux/schemas/models/lecture-component';
import { MutableRefObject, CSSProperties } from 'react';

/**
 * This file contains util functions that drive image-lecture-component.tsx
 */

// Redeclarations of ViewOptions types from app\redux\schemas\models\lecture-component.ts for brevity
export type ImageWidth = ViewOptions[ComponentType.IMAGE]['width'];
export type ImageRotation = ViewOptions[ComponentType.IMAGE]['rotation'];
export type ImageAlignment = ViewOptions[ComponentType.IMAGE]['alignment'];
export type ImageCropping = ViewOptions[ComponentType.IMAGE]['cropping'];

/** Maps image alignment to appropriat CSS flex alignment string */
export const flexAlignments: Record<ImageAlignment, string> = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

/** Dynamically calculates the display width of the image for the current screen resolution
 * and width setting */
export const calculateImageWidth = (
  /** A container holding the ImageComponentImage to be displayed */
  containerRef: MutableRefObject<HTMLDivElement>,
  /** A ref to some parent element of the containerRef element, used exclusively for
   * stretching even wider for 'full' width images */
  contentAreaRef: MutableRefObject<HTMLDivElement>,
  imageWidth: ImageWidth,
  setWidth: (newWidth: number) => void,
): number | null => {
  if (!containerRef?.current || !contentAreaRef?.current) {
    return null;
  }

  const containerWidth = containerRef.current.getBoundingClientRect().width;
  let newWidth = 0;

  switch (imageWidth) {
    case '100%':
      newWidth = containerWidth;
      break;
    case '75%':
      newWidth = containerWidth * 0.75;
      break;
    case '50%':
      newWidth = containerWidth * 0.5;
      break;
    case 'full':
      // To establish the full image width, we have to take the width of the element that actually has the width of the available space which is the container of the lecture components
      try {
        const lectureComponentsContainer = contentAreaRef.current.closest('.lecture-components-list');
        newWidth = lectureComponentsContainer.getBoundingClientRect().width;
      } catch (error) {
        Sentry.captureException(error);
      }
      break;
    default:
      throw new Error(`Tried to calculate width on image with width = ${imageWidth}`);
  }

  setWidth(newWidth);
  return newWidth;
};

export const getBaseWidthForRotation = (width: number, height: number, imageCropping: ImageCropping, isRotatedSideways: boolean): number => {
  if (isRotatedSideways) {
    return imageCropping?.height || height;
  }
  return imageCropping?.width || width;
};

/** Calculates the height of the crop panel that masks the image. The crop panel height should be scaled so that
 * it is the same height ratio as the crop region.
 * @param imageBaseWidth The "real" width of the image before any transformations
 * @param imageBaseWidth The "real" height of the image before any transformations
 * @param calculatedImageWidth The width in pixels that the image will appear on the screen, after all adjustments
 * for width setting, resizes to fit in content area, etc
 * @param imageCropping Current image cropping from view options
 */
export const calculateImageHeight = (
  imageBaseWidth: number,
  imageBaseHeight: number,
  calculatedImageWidth: number,
  isSideways: boolean,
  imageCropping?: ImageCropping,
): number => {
  // Determine the aspect ratio by which to scale the image. Use the crop data if present, otherwise default to the
  // source image's aspect ratio

  if (isSideways) {
    // The height on the screen (up and down, not in image space) of the image before scaling, respecting
    // crop
    const unscaledHeight = imageCropping?.width ?? imageBaseWidth;
    return (calculatedImageWidth / (imageCropping?.height ?? imageBaseHeight)) * unscaledHeight;
    // return (calculatedImageWidth / imageBaseHeight) * imageBaseWidth;
  }

  if (!(imageCropping?.width)) {
    const widthScale = calculatedImageWidth / imageBaseWidth;
    return imageBaseHeight * widthScale;
  }

  const widthScale = calculatedImageWidth / imageCropping.width;
  return imageCropping.height * widthScale;
};

/** Adjusts crop coordinates & dimensions given for a crop region in non-rotated image into the appropriate
 * coords & dimensions for a given rotated image. */
export const correctCropForRotation = (crop: ImageCropping, imageWidth: number, imageHeight: number, rotation: number): ImageCropping => {
  let newCrop: Partial<ImageCropping> = {
    width: crop.width,
    height: crop.height,
  };

  if (rotation === 0) {
    newCrop = crop;
  } else if (rotation === -90) {
    newCrop.left = crop.top;
    newCrop.top = imageWidth - crop.left - crop.width;
    newCrop.width = crop.height;
    newCrop.height = crop.width;
  } else if (rotation === -180) {
    newCrop.left = imageWidth - crop.left - crop.width;
    newCrop.top = imageHeight - crop.top - crop.height;
  } else if (rotation === -270) {
    newCrop.left = imageHeight - crop.top - crop.height;
    newCrop.top = crop.left;
    newCrop.width = crop.height;
    newCrop.height = crop.width;
  }

  return newCrop as ImageCropping;
};

/** Reverses the calculates done to crop coords put through correctCropForRotation() */
export const rotatedCropToUnrotated = (rotatedCrop: ImageCropping, imageWidth: number, imageHeight: number, rotation: number): ImageCropping => {
  let unrotatedCrop: Partial<ImageCropping> = {
    width: rotatedCrop.width,
    height: rotatedCrop.height,
  };

  if (rotation === 0) {
    unrotatedCrop = rotatedCrop;
  } else if (rotation === -90) {
    unrotatedCrop.top = rotatedCrop.left;
    unrotatedCrop.left = imageHeight - rotatedCrop.height - rotatedCrop.top;
    unrotatedCrop.height = rotatedCrop.width;
    unrotatedCrop.width = rotatedCrop.height;
  } else if (rotation === -180) {
    unrotatedCrop.left = imageWidth - rotatedCrop.width - rotatedCrop.left;
    unrotatedCrop.top = imageHeight - rotatedCrop.height - rotatedCrop.top;
  } else if (rotation === -270) {
    unrotatedCrop.top = imageWidth - rotatedCrop.width - rotatedCrop.left;
    unrotatedCrop.left = rotatedCrop.top;
    unrotatedCrop.height = rotatedCrop.width;
    unrotatedCrop.width = rotatedCrop.height;
  }

  return unrotatedCrop as ImageCropping;
};

/** Converts from our image cropping data format to react-crop's */
export const imageCroppingToReactCrop = (imageCropping: ImageCropping): ReactCrop.Crop => ({
  x: imageCropping.left,
  y: imageCropping.top,
  width: imageCropping.width,
  height: imageCropping.height,
});

/** Does the inverse of imageCroppingToReactCrop() */
export const reactCropToImageCropping = (reactCrop: ReactCrop.Crop): ImageCropping => ({
  left: reactCrop.x,
  top: reactCrop.y,
  width: reactCrop.width,
  height: reactCrop.height,
});

/** Sequences the widths together so that clicking the width button will show the next
 * setting in sequence */
export const widthCycling: Record<ImageWidth, ImageWidth> = {
  '100%': 'full',
  full: '75%',
  '75%': '50%',
  '50%': '100%',
};

/** See widthCycling */
export const alignmentCycling: Record<ImageAlignment, ImageAlignment> = {
  left: 'center',
  center: 'right',
  right: 'left',
};

/** Relates image alignments to the icon font string for their button */
export const alignmentToIcon: Record<ImageAlignment, string> = {
  // This icon name is actually typo'd as listed here in the icon file
  center: 'icon-admin-image-middel',
  left: 'icon-admin-image-left',
  right: 'icon-admin-image-right',
};

// Contains all the information required to calculate an image's
// render data via calculateImageDisplay();
export type ImageDisplayInputs = {
  imageAlignment: ImageAlignment,
  imageBaseWidth: number,
  imageBaseHeight: number,
  imageCropping: ImageCropping,
  imageRotation: ImageRotation,
  imageIsRotatedSideways: boolean,
  calculatedImageWidth: number,
  /** This is a random number which is set purely for the purpose of manually forcing the image component to re-render.
   * It's a bit of a hack but I couldn't find a better approach at making changes in the Angularjs (specifically the timeline panel) trigger
   * a ui update */
  renderId?: number,
};

type ImageComponentDisplay = {
  transformOrigin: string,
  /** An amount to translate the rotated image so it sits in the top left of the image element's layout space */
  rotationOffset: string,
  /** An amount to translate the rotated image after the rotationOffset and rotation transforms, to make it
   * rest at the correct alignment position */
  alignmentOffset: string,
  /** Used to manually set the width/height on the crop div and img elements */
  cropContainerStyle: CSSProperties,
  imgStyle: CSSProperties,
  /** A CSS transform to account for the current crop dimensions/position */
  cropTranslate: string,
};

/** Does the heavy lifing for calulating the data needed for rendering the ImageLectureComponent image */
export const calculateImageDisplay = (
  imgRef: React.MutableRefObject<HTMLImageElement>,
  displayInputs: ImageDisplayInputs,
  imageUrl: string,
): ImageComponentDisplay => {
  const imageRect = imgRef?.current?.getBoundingClientRect();

  const {
    imageAlignment,
    imageBaseWidth,
    imageBaseHeight,
    imageCropping,
    imageRotation,
    imageIsRotatedSideways,
    calculatedImageWidth,
  } = displayInputs;

  const { rotationOffset, transformOrigin } = getTransformOrigin(imageRotation);
  const { alignmentOffset } = getAlignmentOffset(
    imageAlignment,
    imageRotation,
    imageIsRotatedSideways,
    imageRect,
  );

  const { widthScale, heightScale, scaledWidth, scaledHeight, calculatedImageHeight } = getScaledDimensions(
    imageBaseWidth,
    imageBaseHeight,
    imageCropping,
    calculatedImageWidth,
    imageIsRotatedSideways,
  );

  const hasCrop = imageCropping !== null && !Number.isNaN(imageCropping.top) && imageCropping.top !== null;

  /** Adjust the crop values for the current rotation. Used below in calculated the offsets for translating the img tag beneath
   * the crop div. */
  const rotatedCrop = (imageRect && hasCrop) ? correctCropForRotation(imageCropping, imageBaseWidth, imageBaseHeight, imageRotation) : imageCropping;

  /** The calculated width is null when the container has yet to render */
  const { cropTranslate, cropContainerStyle, imgStyle } = (imageUrl && calculatedImageWidth) ? processCropData(
    calculatedImageWidth,
    calculatedImageHeight,
    scaledWidth,
    scaledHeight,
    imageRotation,
    imageIsRotatedSideways,
    hasCrop,
    rotatedCrop,
    widthScale,
    heightScale,
    imageBaseWidth,
    imageBaseHeight,
  ) : {
    cropTranslate: '',
    cropContainerStyle: { width: '100px', height: '100px' },
    imgStyle: { width: '100px', height: '100px' },
  };

  return {
    alignmentOffset,
    cropContainerStyle,
    cropTranslate,
    imgStyle,
    transformOrigin,
    rotationOffset,
  };
};

/** Get the CSS string for the <img>'s transform-origin */
const getTransformOrigin = (imageRotation: ImageRotation): Pick<ImageComponentDisplay, 'rotationOffset' | 'transformOrigin'> => {
  if (imageRotation === -270) {
    return {
      rotationOffset: 'translateY(-100%)',
      transformOrigin: 'left bottom',
    };
  } if (imageRotation === -90) {
    return {
      rotationOffset: 'translateX(-100%)',
      transformOrigin: 'top right',
    };
  }

  return {
    rotationOffset: '',
    transformOrigin: 'center',
  };
};

/** Get the CSS transfomr offset to account for the selected image alignment */
const getAlignmentOffset = (imageAlignment: ImageAlignment, imageRotation: ImageRotation, imageIsRotatedSideways: boolean, imageRect: DOMRect): Pick<ImageComponentDisplay, 'alignmentOffset'> => {
  let alignmentOffset = '';

  // Calculate a translation offset for the image based on the selected alignment
  if (imageAlignment !== 'left' && imageIsRotatedSideways && imageRect) {
    let offset = Math.abs(imageRect.width - imageRect.height);

    if (imageAlignment === 'center') {
      offset /= 2;
    }

    offset = 0;

    alignmentOffset = `translateY(${imageRotation === -90 ? offset : -offset}px)`;
  }

  return {
    alignmentOffset,
  };
};

/** Calculates data describing how the rendered image is scaled relative it's original size, used
 * in subsequent calcs */
const getScaledDimensions = (imageBaseWidth: number, imageBaseHeight: number, imageCropping: ImageCropping, calculatedImageWidth: number, imageIsRotatedSideways: boolean) => {
  // Determine the scaling multipliers we need to use to adjust coordinates by due to how much
  // the image is zoomed in to show the correct crop region
  // Basically treat these as "X times zoom" units
  let widthScale = 1;
  let heightScale = 1;

  // TODO: I've changed the purpose of widthScale now; it is now a scaling factor applied to the
  // visible width dimension, not the width of the image (dependson rotation). Rework

  if (imageBaseWidth !== 0 && imageCropping?.width) {
    if (!imageIsRotatedSideways) {
      widthScale = imageBaseWidth / imageCropping?.width;
    } else {
      // widthScale = imageBaseHeight / imageCropping?.height;
      widthScale = imageBaseHeight / imageCropping?.height;
      heightScale = imageBaseWidth / imageCropping?.width;
    }
  }

  // if (imageBaseHeight !== 0 && imageCropping?.height) {
  //   heightScale = imageBaseHeight / imageCropping?.height;
  // }

  // This is horizontal "stretch factor" for how distored the image will be along the horizontal axis
  const scaledWidth = widthScale * calculatedImageWidth;

  // The actual image height derived from the current rotation + the above
  const calculatedImageHeight = calculateImageHeight(imageBaseWidth, imageBaseHeight, calculatedImageWidth, imageIsRotatedSideways, imageCropping);

  // Vertical "stretch factor"
  // const scaledHeight = calculatedImageHeight; // heightScale * calculatedImageHeight;
  const scaledHeight = heightScale * calculatedImageHeight;

  return {
    scaledWidth,
    scaledHeight,
    widthScale,
    heightScale,
    calculatedImageHeight,
  };
};

/** Determines the correct <img> and container div width/height to respect the currently selecte crop, and
 * the CSS transform to offset the image for the crop location */
const processCropData = (
  calculatedImageWidth: number,
  calculatedImageHeight: number,
  scaledWidth: number,
  scaledHeight: number,
  imageRotation: ImageRotation,
  imageIsRotatedSideways: boolean,
  hasCrop: boolean,
  rotatedCrop: ImageCropping,
  widthScale: number,
  heightScale: number,
  imageBaseWidth: number,
  imageBaseHeight: number,
): Pick<ImageComponentDisplay, 'cropContainerStyle' | 'cropTranslate' | 'imgStyle'> => {
  let cropTranslate = '';
  let cropContainerStyle: CSSProperties = {};
  let imgStyle: CSSProperties = {};

  const widthString = `${calculatedImageWidth}px`;
  const heightString = `${calculatedImageHeight}px`;
  const scaledWidthString = `${scaledWidth}px`;
  const scaledHeightString = `${scaledHeight}px`;

  if (imageIsRotatedSideways) {
    cropContainerStyle.width = widthString;
    imgStyle.width = scaledHeightString;
    cropContainerStyle.height = scaledHeightString;
    imgStyle.height = scaledWidthString;

    if (hasCrop) {
      // The offset is the differenc between where the top left cropping coordinate *should* be, vs where it is when the image is scaled up and centered;

      // 110, -450
      // const leftOffset = rotatedCrop.left * widthScale; // * (calculatedImageWidth / imageBaseWidth);
      // const topOffset = rotatedCrop.top * widthScale; // * (calculatedImageWidth / imageBaseWidth);
      let leftOffset = 0 + rotatedCrop.top * widthScale * (calculatedImageWidth / imageBaseHeight);
      let topOffset = -rotatedCrop.left * widthScale * (calculatedImageWidth / imageBaseHeight);

      if (imageRotation === -270) {
        leftOffset = -leftOffset;
        topOffset = -topOffset;
      }

      cropContainerStyle = {
        height: calculatedImageHeight,
        width: widthString,
      };
      cropTranslate = `translate(${leftOffset}px, ${topOffset}px)`;
      imgStyle = {
        width: scaledHeightString,
        height: scaledWidthString,
      };
    }
  } else {
    cropContainerStyle.width = widthString;
    imgStyle.width = scaledWidthString;
    cropContainerStyle.height = heightString;
    // imgStyle.height = scaledHeightString;

    if (hasCrop) {
      // The offset is the differenc between where the top left cropping coordinate *should* be, vs where it is when the image is scaled up and centered;
      // (calculatedImageWidth / imageBaseWidth) effectively reverse calculates the image width setting
      // ie: 100%, 75%, or 50%
      // TODO: Pass in that width setting instead of doing this
      let leftOffset = -rotatedCrop.left * widthScale * (calculatedImageWidth / imageBaseWidth);
      let topOffset = -rotatedCrop.top * widthScale * (calculatedImageWidth / imageBaseWidth);

      if (imageRotation === -180) {
        leftOffset = -leftOffset;
        topOffset = -topOffset;
      }

      cropContainerStyle = {
        width: widthString,
        height: scaledHeightString,
      };
      cropTranslate = `translate(${leftOffset}px, ${topOffset}px)`;
      imgStyle = {
        width: scaledWidthString,
        // height: scaledHeightString,
      };
    }
  }

  return {
    cropContainerStyle,
    cropTranslate,
    imgStyle,
  };
};
