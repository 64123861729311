export type LeftPanelView = 'outline' | 'add_top_level' | 'add_content' | 'novo_ai';

export enum CommunityCompletionRowType {
  LECTURE = 'lecture',
  LECTURE_VIDEO = 'lectureVideo',
  SUBMISSION = 'submission',
  QUIZ = 'quiz',
  SURVEY = 'survey',
  EVALUATION = 'evaluation',
}
