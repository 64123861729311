import { css } from '@emotion/react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { first, isEmpty } from 'underscore';
import t from 'react-translate';

import { useAppDispatch } from 'redux/store';
import { RootState } from 'redux/schemas';
import { Comment } from 'redux/schemas/models/comment';
import { fetchReplies } from 'redux/actions/replies';

import ClickableContainer from 'components/clickable-container';
import { standardSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { handheld } from 'styles/global_defaults/media-queries';
import { BaseUser } from 'redux/schemas/models/my-account';
import { createFroalaMentionableString } from 'froala/helpers/tribute-options';
import CommentRow from './comment-row';
import CommentForm from './comment-form';
import { CommentsContainerContext } from './comments-container';
import LoadingWrapper, { LoaderType } from '../loading-wrapper';

type CommentSectionProps = {
  commentId: number,
};

type CommentSectionContextValues = {
  onClickOnReplyIcon?: (replyUser: BaseUser) => void,
  onClickOnReplyCount?: (isFromReply: boolean) => void,
};

export const CommentSectionContext = React.createContext<CommentSectionContextValues>(null);

const CommentSection = ({ commentId }: CommentSectionProps) => {
  const { ownerType, ownerId, catalogId } = useContext(CommentsContainerContext);
  const styles = css`
    .actions-wrapper {
      top: -${halfSpacing}px;
    }
    .replies-wrapper {
      margin-left: 80px;
      ${handheld(css`
        margin-left: ${standardSpacing}px;
      `)};
    }
  `;

  const dispatch = useAppDispatch();
  const commentFormRef = useRef(null);
  const [showReplies, setShowReplies] = useState(true);
  const [newReply, setNewReply] = useState(false);
  const [isReplyOfReply, setIsReplyOfReply] = useState(false);
  const [replyContent, setReplyContent] = useState('');
  const [loadingInitialReplies, setLoadingInitialReplies] = useState(false);
  const [loadingPreviousReplies, setLoadingPreviousReplies] = useState(false);

  const comment: Comment = useSelector((state: RootState) => state.models.comments[commentId]);

  const getReplies = useCallback((param?: { beforeId?: number; }) => {
    const payload = {
      ...param,
      catalogId,
      commentId,
      pageSize: 3,
    };
    return dispatch(fetchReplies(payload));
  }, [catalogId, commentId, dispatch]);

  useEffect(() => {
    if (!comment.repliesFetched && comment.repliesCount > 0) {
      setLoadingInitialReplies(true);
      getReplies().then(() => setLoadingInitialReplies(false));
    }
  }, [comment.repliesCount, comment.repliesFetched, getReplies]);

  const onLoadPreviousReplies = () => {
    setLoadingPreviousReplies(true);
    getReplies({ beforeId: first(comment.replyIds) }).then(() => {
      setLoadingPreviousReplies(false);
    });
  };

  const onClickOnReplyIcon = useCallback((replyUser?: BaseUser) => {
    const isReply = !isEmpty(replyUser);
    const contet = isReply ? createFroalaMentionableString(replyUser) : '';

    setIsReplyOfReply(isReply);
    setReplyContent(contet);
    setNewReply(true);

    setTimeout(() => {
      commentFormRef?.current?.focus();
    });
  }, []);

  const onClickOnReplyCount = useCallback((isFromReply: boolean) => {
    setShowReplies(!showReplies);
  }, [showReplies]);

  return (
    <CommentSectionContext.Provider
      value={{
        onClickOnReplyIcon,
        onClickOnReplyCount,
      }}
    >
      <div css={styles} className='d-flex flex-column'>
        <CommentRow
          commentId={commentId}
        />
        <div className='replies-wrapper'>
          {comment.additionalRepliesBeforeCount > 0 && (
            <LoadingWrapper
              isLoaded={!loadingPreviousReplies}
              loaderType={LoaderType.PLACEHOLDER}
            >
              <ClickableContainer
                className='text-small text-primary mb-5 font-weight-bold'
                onClick={onLoadPreviousReplies}
              >
                {comment.additionalNewRepliesBeforeCount > 0
                  ? t.DISCUSSIONS.LOAD_PREVIOUS_REPLIES_WITH_NEW(comment.additionalRepliesBeforeCount, comment.additionalNewRepliesBeforeCount)
                  : t.DISCUSSIONS.LOAD_PREVIOUS_REPLIES(comment.additionalRepliesBeforeCount)}
              </ClickableContainer>
            </LoadingWrapper>
          )}
          <LoadingWrapper
            isLoaded={!loadingInitialReplies}
            loaderType={LoaderType.PLACEHOLDER}
          >
            {showReplies && (comment?.replyIds || []).map(replyId => (
              <CommentRow
                key={`comment-reply-${replyId}`}
                commentId={replyId}
                parentCommentId={commentId}
              />
            ))}
          </LoadingWrapper>
          {newReply && (
            <CommentForm
              ref={commentFormRef}
              catalogId={catalogId}
              ownerType={ownerType}
              ownerId={ownerId}
              value={replyContent}
              parentCommentId={commentId}
              isReply={isReplyOfReply}
              onComment={() => setNewReply(false)}
              onCancel={() => setNewReply(false)}
            />
          )}
        </div>
      </div>
    </CommentSectionContext.Provider>
  );
};

export default CommentSection;
