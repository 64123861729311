/* @ngInject */
export default function FlaggingModelService(
  _,
) {
  const FlaggingModel = function (attributes) {
    const _this = this;

    // initial values
    _this.flagged = false;

    _.extend(this, attributes);
  };

  return FlaggingModel;
}
