import store from 'redux/store';
import { ActivityKey } from 'redux/schemas/models/activity';
import { setActivityProgress } from 'redux/actions/lecture-components';

/* @ngInject */
export default function TimelineSurveyLectureComponentModelService(

  _,
  moment,
  nvUtil,
) {
  const TimelineSurveyLectureComponentModel = function (attributes) {
    const currentMoment = moment();
    let deadlineMoment;
    const _this = this;
    const base = {
      templateUrl: 'timelines/templates/nv-timeline-lecture-survey.html',
      canHaveHardDeadline: true,
    };

    /** Setting Up Basic Attributes * */
    _.extend(_this, base, attributes);
    // passed lecturePage, lectureSection, course
    preprocess();

    /** Public Functions * */
    _.extend(_this, {
      isCompleted,
      isMissed,
      isTodoRequiredForCompletion,
      updateComponentPointsAndProgress,
      updateComponentProgress,
      isPrereqCompleted,
      getPayload,
      hasDeadline,
      setDeadline,
    });

    /** Function Declarations * */
    /* Used Publicly */
    function isCompleted() {
      return _this.survey.progress === 'completed';
    }

    function isMissed() {
      return _this.survey.progress === 'missed';
    }

    function isTodoRequiredForCompletion() {
      return _this.survey.isRequiredForCompletion;
    }

    function updateComponentPointsAndProgress(componentType, componentId, pointsReceived, totalPoints) {
      if ((_this.type === componentType && _this.id === componentId) || (componentType === 'survey' && _this.survey.id === componentId)) {
        _this.survey.progress = 'completed';
        store.dispatch(setActivityProgress({
          pointsReceived,
          progress: _this.survey.progress,
          activityKey: ActivityKey.SURVEYS,
          activityPayloadId: _this.survey.id,
        }));

        _this.survey.pointsReceived = pointsReceived;
        _this.pointsReceived = pointsReceived;
        if (_.isNumber(totalPoints)) {
          _this.currentTotalPoints = totalPoints;
        }

        viewPreprocess();

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function updateComponentProgress(componentType, componentId, progress) {
      if ((_this.type === componentType && _this.id === componentId) || (componentType === 'survey', _this.survey.id === componentId)) {
        _this.survey.progress = progress;
        store.dispatch(setActivityProgress({
          progress,
          activityKey: ActivityKey.SURVEYS,
          activityPayloadId: _this.survey.id,
        }));

        viewPreprocess();

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function isPrereqCompleted(prereq) {
      if ((prereq.type === _this.type && prereq.id === _this.id) || (prereq.type === 'Survey' && prereq.id === _this.survey.id)) {
        return isCompleted();
      }
      return false;
    }

    function getPayload() {
      return _.pick(_this, ['title', 'expirationDate', 'hardDeadline']);
    }

    function hasDeadline() {
      return !!this.expirationDate;
    }

    function setDeadline(newDeadline) {
      this.expirationDate = newDeadline;
    }

    /* Used Privately */
    function preprocess() {
      _.extend(_this, _.pick(_this.survey, 'title', 'hardDeadline', 'expired', 'isTodo', 'hasStructuralIssues',
        'expirationDate', 'pointsReceived', 'totalPoints', 'scheduledCommunicationsCount'));
      _this.activityId = _this.survey.id;
      _this.isExpired = moment(_this.expirationDate) < moment();
      deadlineMoment = moment(_this.expirationDate);

      _this.currentTotalPoints = nvUtil.getCurrentTotalPoints(_this.survey.totalPoints, _this.lecturePage.releaseDate, _this.course.expiringPointsEnabled);

      viewPreprocess();
    }

    function viewPreprocess() {
      _this.showDueSoon = showDueSoon();

      if (isCompleted() || _this.pointsReceived > _this.currentTotalPoints) {
        _this.currentTotalPoints = _this.pointsReceived ? _this.pointsReceived : 0;
      }
      _this.displayTotalPointsOnly = !_this.pointsReceived && _this.currentTotalPoints;
    }

    function showDueSoon() {
      if (_this.expirationDate) {
        return !isCompleted()
            && deadlineMoment > currentMoment
            && deadlineMoment < moment().add(7, 'days');
      }
      return false;
    }
  };

  return TimelineSurveyLectureComponentModel;
}
