import { css } from '@emotion/react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';

// Contexts
import { CommunicationFormContext, UseCommunicationMethods } from 'communications/course_communications/hooks/use-communication-form';

// Selectors
import { getRolesTitles } from 'redux/selectors/course-communications';
import { getCourse, getCurrentCourse } from 'redux/selectors/course';

// Styles
import { gray4 } from 'styles/global_defaults/colors';
import { standardSpacing } from 'styles/global_defaults/scaffolding';

// Components
import CommunicationItemPreview from 'communications/course_communications/components/communication-item/communication-item-preview';
import SamplePushNotification from 'communications/course_communications/components/communication-modal/sample_push_notification/sample-push-notification';

const styles = css`
  .notification-header {
    padding: ${standardSpacing}px 0;
    border-top: 2px solid black;
    border-bottom: 1px solid ${gray4};
    display: flex;
    align-items: center;
  }
`;

const PushNotificationPreview = () => {
  const { formData, getCommunicationDraft } = useContext<UseCommunicationMethods>(CommunicationFormContext);
  const communicationDraft = getCommunicationDraft();

  const rolesFilterTitles = useSelector((state) => getRolesTitles(state, communicationDraft?.filters?.courseRolesList));
  const currentCourse = useSelector((state) => getCurrentCourse(state));

  return (
    <div>
      <CommunicationItemPreview
        communication={communicationDraft}
        rolesFilterTitles={rolesFilterTitles}
        showOwnerActivity
      />
      <div className='mt-4' css={styles}>
        <div className='notification-header'>
          <div className='page-title-small font-weight-bolder'>{t.COURSE_COMMUNICATIONS.PUSH_NOTIFICATION.TITLE()}</div>
        </div>
        <SamplePushNotification notification={{
          title: currentCourse.name,
          bodyText: formData.emailSubject ? `${t.COURSE_COMMUNICATIONS.ANNOUNCEMENT.FORM.ANNOUNCEMENT()} ${formData.emailSubject}` : '',
        }}
        />
        <div className='text-small text-gray-2 mt-1'>{t.COURSE_COMMUNICATIONS.PUSH_NOTIFICATION.POSTSCRIPT()}</div>
      </div>
    </div>
  );
};


export default PushNotificationPreview;
