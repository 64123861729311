/* @ngInject */
export default function CustomDownloadsController(
  $state,
  $stateParams,
  CustomDownloadsManager,
  CurrentCourseManager,
  CurrentUserManager,
  $uibModal,
  InteroperabilityRoutes,
  ConfirmationOverlays,
  humps,
  AlertMessages,
  PusherManager,
  $timeout,
  _,
) {
  const vm = this;
  vm.CustomDownloadsManager = CustomDownloadsManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.InteroperabilityRoutes = InteroperabilityRoutes;
  vm.$stateParams = $stateParams;

  vm.defaultMin = 6;

  vm.shownDownloads = { num: vm.defaultMin };
  vm.shownContentDownloads = { num: vm.defaultMin };

  if (!CurrentUserManager.hasEnrolledInCourse(CurrentCourseManager.course)) {
    $state.go('course-flyer', { catalogId: $stateParams.catalogId });
  } else if (CurrentUserManager.isAdmin()) {
    initialize();
  } else {
    $state.go('course-home', { catalogId: vm.$stateParams.catalogId });
  }

  function initialize() {
    vm.CustomDownloadsManager.getCustomReports(vm.$stateParams.catalogId);

    PusherManager.courseChannel(vm.CurrentCourseManager.course.id).bind('custom_report_generated', reportGenerationHandler);
    PusherManager.courseChannel(vm.CurrentCourseManager.course.id).bind('custom_report_failed', reportGenerationHandler);
  }

  function reportGenerationHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);

    if (pusherData.courseId === vm.CurrentCourseManager.course.id) {
      if (pusherData.reportType === 'content') {
        _.each(vm.CustomDownloadsManager.data.contentDownload, (data) => {
          if (data.reportId === pusherData.reportId) {
            data.generatedAt = pusherData.generatedAt;
            data.reportStatus = pusherData.reportFileStatus;
            data.url = pusherData.reportFileUrl;
          }
        });
      } else {
        _.each(vm.CustomDownloadsManager.data.dataDownload, (data) => {
          if (data.reportId === pusherData.reportId) {
            data.generatedAt = pusherData.generatedAt;
            data.reportStatus = pusherData.reportFileStatus;
            data.deleteOlderReports = false;
          }
        });
      }
    }
  }

  vm.createReport = function (reportId) {
    // for new, reportId is null
    const modal = $uibModal.open({
      templateUrl: 'custom_downloads/templates/create-reports-modal.html',
      controller: 'CreateReportsCtrl as vm',
      windowClass: 'full-screen-modal-handheld full-screen-modal-tablet create-reports-modal custom-downloads',
      resolve: {
        reportId: null,
        reportPreviousStatus: null,
      },
    }).closed.then(() => {
      vm.CustomDownloadsManager.getUpdatedCustomReport(vm.$stateParams.catalogId, null, 'dataDownload');
    });
  };

  vm.createContentDownload = function () {
    const modal = $uibModal.open({
      templateUrl: 'custom_downloads/templates/create-content-downloads-modal.html',
      controller: 'CreateContentDownloadCtrl as vm',
      windowClass: 'full-screen-modal-handheld full-screen-modal-tablet create-content-downloads-modal custom-downloads',
    }).closed.then(() => {
      vm.CustomDownloadsManager.getUpdatedCustomReport(vm.$stateParams.catalogId, null, 'contentDownload');
    });
  };

  vm.bootstrapReactApp = () => {
    if ($stateParams.sectionId) {
      $timeout(() => {
        // sectionId is fed from url, need to check existence
        const $section = $(`#${$stateParams.sectionId}`);

        if ($section.length) {
          $('#main-content').scrollToElementAnimated($section, null, 400);
        }
      });
    }
  };

  vm.bootstrapReactApp();
}
