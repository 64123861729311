/* @ngInject */
export default function VideoPracticeSkillsRatingLectureComponentModel(
  _,
  moment,
  nvUtil,
  $translate,
  CurrentCourseManager,
  LectureComponentBaseModel,
) {
  class VideoPracticeSkillsRatingLectureComponent extends LectureComponentBaseModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      const defaults = {
        type: 'VideoPracticeSkillsRatingLectureComponent',
        editFormTemplateUrl: 'lecture_pages/templates/components/video-practice-evaluation-modal.html',
        editFormController: 'VideoPracticeEvaluationCtrl',
        skillsRating: {
          title: '',
          description: `<div class="froala-style-regular">${$translate.instant('LECTURE_PAGES.COMPONENTS.PRIVATE_PEER_EVALUATION.FEEDBACK_DESCRIPTION', {
            learnersAlias: CurrentCourseManager.course.learnersName.downcasedPluralized,
            courseAlias: CurrentCourseManager.course.offeringName.downcasedSingularized,
            teachingTeamAlias: CurrentCourseManager.course.teachingTeamName.downcasedSingularized,
          })}</div>`,
          activityId: null,
          requiredToComplete: 5,
          isTodo: false,
          activityType: 'Exercise',
        },
        updateExercisesAfterCreationDeletion: true,
        isActivity: true,
        canBeCopied: true,
      };

      super(_.extend({}, defaults, attributes));

      // this.__preprocess();

      if (useDefaults) {
        this.createDraft();
      }
    }

    get directiveName() {
      return 'nv-peer-evaluation-lecture-component';
    }

    __preprocess() {
      this.skillsRating = _.extend(this.skillsRating, { catalogId: this.catalogId });
      this.scheduledCommunicationsCount = this.skillsRating?.scheduledCommunicationsCount;

      this.skillsRating.expiringPointsPerPeerReview = nvUtil.getCurrentTotalPoints(
        this.skillsRating.pointsPerPeerReview,
        this.skillsRating.releaseDate,
        CurrentCourseManager.course.isDecayEnabled(),
      );
    }

    /* Admin Functions */
    setRealComponent(component) {
      this.realComponent = component;
    }

    createDraft() {
      this.feedbackDraft = _.clone(this.skillsRating);
    }

    getPayload() {
      return { skillsRating: this.skillsRating };
    }

    saveDraft() {
      _.extend(this.skillsRating, this.feedbackDraft);
    }

    moveToWarningMessages(targetLecturePage) {
      const warningMessages = super.moveToWarningMessages(targetLecturePage);
      if (this.lecturePage.released && !targetLecturePage.released) {
        warningMessages.push($translate.instant('LECTURE_PAGES.COMPONENTS.EXERCISE.MOVE.UNRELEASE', CurrentCourseManager.course.getAliases()));
      }
      if (this.skillsRating.deadline && moment(this.skillsRating.deadline) < moment(targetLecturePage.releaseDate)) {
        warningMessages.push($translate.instant('LECTURE_PAGES.COMPONENTS.EXERCISE.MOVE.DEADLINE', CurrentCourseManager.course.getAliases()));
      }
      if (this.skillsRating.teamSet?.isCourseLong && moment(targetLecturePage.releaseDate) < moment(this.skillsRating.teamSet.releaseDate)) {
        warningMessages.push($translate.instant('LECTURE_PAGES.COMPONENTS.EXERCISE.MOVE.COURSE_LONG_TEAM', CurrentCourseManager.course.getAliases()));
      }
      return warningMessages;
    }
  }

  VideoPracticeSkillsRatingLectureComponent.showModalBeforeCreate = true;
  VideoPracticeSkillsRatingLectureComponent.iconClass = 'icon-skills-feedback-activity';

  return VideoPracticeSkillsRatingLectureComponent;
}
