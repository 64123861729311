/* eslint-disable no-bitwise */
export const primary = '#0E72ED'; // NovoEd blue
export const success = '#59A315'; // NovoEd green
export const teal = '#97ced4';
export const warning = '#FA6625'; // NovoEd orange
export const danger = '#EB0C3B'; // NovoEd red


// https://projects.invisionapp.com/d/main?origin=v7#/console/5847843/362068640/inspect?scrollOffset=8322 says this should be
// The novoed-yellow has been updated to #fdce00
export const yellow = '#fdce00';
export const white = '#ffffff';

export const blue5 = '#f4fafd';
export const lightBlue = '#d8f0fa';
export const lightGreen = '#e1f1d3';
export const lightOrange = '#ffe2d5';
export const lightYellow = '#fff6d0';
export const info = '#eaf7f7'; // NovoEd Light teal
export const kelp = '#03a569'; // NovoEd Kelp, a different green
export const highTide = '#0b4dc2'; // NovoEd Hightide , a darkish blue
export const lowTide = '#93DAE1'; // NovoEd Lowtide
export const purple = '#6597FF'; // purple

export const shade = '#06152D';

export const logo = '#467b8c'; // NovoEd darkquoise (old)

export const black = '#1d2126';
export const gray1 = '#3a424d';
export const gray2 = '#576373';
export const gray3 = '#748499';
export const gray4 = '#acb5c2';
export const gray5 = '#d4dae1';
export const gray6 = '#e4e7ec';
export const gray7 = '#f7f8fa';
export const gray8 = '#979797';

export const headerColor = '#449243';

export const genAIGradient = 'linear-gradient(48.41deg, #009AC0 0%, #00CCBC 60.68%, #60F3AB 100%);';

/**
 * Extracts decimal components from a hex color string.
 * Supports both #ffffff and #fff syntax
 * @param hex HEX color string starting with #
 */
/* @ngInject */
export function hexToRgb(hex: string) {
  // Removing # character
  let cleanedHex = hex.replace('#', '').toLowerCase();

  // Validation check for hexadecimals with 4 or 5 characters that are the same, get the first 3 characters
  cleanedHex = cleanedHex.match(/^([a-f\d])\1*$/i) ? cleanedHex.slice(0, 3) : cleanedHex;

  if (cleanedHex.length < 6) {
    // Duplicates each character if it's a shorthand #fff
    cleanedHex = cleanedHex.replace(/./g, '$&$&');
  }

  const colorValue = parseInt(cleanedHex, 16);

  // Performing the right shifts and bitwise AND operations to extract the RGB values from the decimal color value
  return {
    r: colorValue >> 16,
    g: (colorValue >> 8) & 255,
    b: colorValue & 255,
  };
}

/* @ngInject */
export function hexToRgbaString(hex: string, alpha?: number) {
  const rgbObj = hexToRgb(hex);
  return `rgba(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b}, ${alpha ?? '255'})`;
}

/**
 * estimates the color brightness
 * based in https://awik.io/determine-color-bright-dark-using-javascript/
 */
/* @ngInject */
export function getColorBrightness(color: string) {
  const { r, g, b } = hexToRgb(color);

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  return hsp;
}

/**
 * validates that is a hex color including shorthand versions
 */
export const colorRegex = /^#(([\da-f]){3}){1,2}$/i;

/**
 * Find a color between two colors
 * @param color1 Start Color
 * @param color2 End Color
 * @param fraction Point Value ( 0 - 1)
 */
/* @ngInject */
export function getColorBetweenColorsByPercentage(color1: string, color2: string, fraction: number) {
  const rgbObj1 = hexToRgb(color1);
  const rgbObj2 = hexToRgb(color2);

  return rgbToHex(
    Math.round(rgbObj1.r + fraction * (rgbObj2.r - rgbObj1.r)),
    Math.round(rgbObj1.g + fraction * (rgbObj2.g - rgbObj1.g)),
    Math.round(rgbObj1.b + fraction * (rgbObj2.b - rgbObj1.b)),
  );
}

/**
 * Covert the value for each color( Red, Green, Blue) to respective hex value
 * @param c color number 0-255
 */
function convertColorComponentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

/**
 * Convert RGB color component to Hex
 * @param r Red Component
 * @param g Green Component
 * @param b Blue Component
 */
/* @ngInject */
export function rgbToHex(r: number, g: number, b: number) {
  return `#${convertColorComponentToHex(r)}${convertColorComponentToHex(g)}${convertColorComponentToHex(b)}`;
}

/**
 * Check the given string is a valid hex value
 */
/* @ngInject */
export function isValidHex(hexString: string) {
  return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(hexString);
}

/**
 * Function to compare two colors
 * @param color1 hex color
 * @param color2 hex color
 */
/* @ngInject */
export function compareHexColors(color1, color2) {
  // Convert colors to RGB format
  const rgbColor1 = hexToRgb(color1);
  const rgbColor2 = hexToRgb(color2);

  // Compare RGB values of the two colors
  return (
    rgbColor1?.r === rgbColor2?.r
    && rgbColor1?.g === rgbColor2?.g
    && rgbColor1?.b === rgbColor2?.b
  );
}
