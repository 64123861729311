import cloneDeep from 'lodash/cloneDeep';

import { PermissionTypes } from 'institutions/services/roles-service';

/* @ngInject */
export default function EmailPreferencesController(
  _,
  $scope,
  $translate,
  CurrentUserManager,
  RolesService,
  StateManager,
) {
  const vm = this;
  vm.isSaving = false;
  vm.pristine = true;

  // Fake 'name' and 'id' values for the All Courses drop down list item
  const ALL_COURSES_NAME = $translate.instant('ACCOUNT_SETTINGS.EMAIL.ALL_COURSES');
  const ALL_COURSES_ID = -1;

  // Names for each email preference category that match the names expected by the preferences update endpoint. Do not change
  // without updating the endpoint.
  const CONFIGURATION_CATEGORIES = [
    // Practice
    'practice_comment',
    'practice_mentions',
    'practice_featured',
    'skills_feedback',
    // Instruction
    'announcements',
    'submission_evaluations',
    'submission_approval',
    // Mentions and Direct Communication
    'discussion_mentions',
    'conversations',
    // My Teams & Groups
    'team_workspace_activities',
    // Teams, Groups... Following
    'followings_discussions',
    'followings_comments',
    // Invitations & Requests
    'invitations_team',
    'invitations_group',
    'requests_groups_and_teams',
    // Daily Digest
    'digests',
    // Admin
    'completion_digests',
    'submission_approval_admin',
  ];

  // Categories from CONFIGURATION_CATEGORIES that are only able to be set on the global level. These are rendered with a special notice in the UI
  // and disabled when a specific course is selected in the drop down
  const GLOBAL_CATEGORIES = [
    // Practice
    'practice_comment',
    'practice_mentions',
    'practice_featured',
    'skills_feedback',
    // Admin
    'submission_approval_admin',
  ];

  // Categories from CONFIGURATION_CATEGORIES that are only applicable to
  // courses
  const COURSE_ONLY_CATEGORIES = CONFIGURATION_CATEGORIES.filter(
    (category) => !GLOBAL_CATEGORIES.includes(category),
  );

  function getApplicableCategories(courseId) {
    const isAllCourses = courseId === ALL_COURSES_ID;

    return isAllCourses
      ? CONFIGURATION_CATEGORIES
      : COURSE_ONLY_CATEGORIES;
  }

  // The current user's courses + an 'All Courses' dummy item to be the first entry in the dropdown
  vm.courses = CurrentUserManager.courses;
  vm.courses = [{ name: ALL_COURSES_NAME, id: ALL_COURSES_ID }, ...vm.courses];
  vm.adminCourseIds = [];
  vm.submissionApprovalCourseIds = [];

  _.forEach(vm.courses, (c) => {
    if (c.id !== ALL_COURSES_ID) {
      if (CurrentUserManager.courseIdToUserCourseHash[c.id].isCourseAdmin) {
        vm.adminCourseIds.push(c.id);
      }

      // TODO: Note from Nathan (review w/ Bryan): This is the only place I'm aware of that we check for someone's permissions on a course and avoid
      // checking if they're a NovoEd or org admin.
      // Reviewing this more, I'm also not even convinced that we need to differentiate admin/non-admin categories beyond checking perms to hide/show.
      const { permission } = CurrentUserManager.courseIdToUserCourseHash[c.id];
      if (RolesService.hasPermission(permission, PermissionTypes.INSTRUCTOR)
            || RolesService.hasPermission(permission, PermissionTypes.TEACHER_ASSISTANT)
            || RolesService.hasPermission(permission, PermissionTypes.MENTOR)) {
        vm.submissionApprovalCourseIds.push(c.id);
      }
    }
  });

  // Select the course
  if (CurrentUserManager.getEmailPreferenceCatalogId()) {
    vm.selectedCourse = vm.courses[_.findIndex(vm.courses, (c) => c.catalogId === CurrentUserManager.getEmailPreferenceCatalogId())];
  } else {
    vm.selectedCourse = vm.courses[0];
  }

  // Holds the enable & indeterminate (partially checked) states for the email preference checkboxes for all courses.
  // This is a mapping of course IDs to checkbox values. See createChecksModel()
  vm.checks = {};
  vm.selectedChecks = null; // The checks model for the current course. Should reference a specific course in vm.checks

  // A list of strings from CONFIGURATION_CATEGORIES that describes which notifications are 'unsubscribed' across all courses
  vm.globalUnsubscriptions = [];
  // A mapping of course ids to lists where each list is a set of CONFIGURATION_CATEGORIES describing the unsubbed notifications for that course.
  vm.courseUnsubscriptions = {};
  vm.allSelected = false; // 'Select All' checkbox model

  /**
     * Initializes vm.checks to look like:
     * {
     *    1: {
     *      'announcements': {
     *        'checked': true,
     *        'some': false,
     *      },
     *      ... // Other CONFIGURATION_CATEGORIES
     *    },
     *    ... // Other course IDs
     * }
     */
  function createChecksModel() {
    vm.courses.forEach((course) => {
      vm.checks[course.id] = {};
      getApplicableCategories(course.id).forEach((category) => {
        const props = {
          checked: true,
          some: false,
        };

        // Add an 'enabled' count for the ALL_COURSES items so we can count the # of courses w/ this notif enabled
        if (course.id === ALL_COURSES_ID) {
          props.uncheckedCount = 0;
          props.countString = '';
        }

        vm.checks[course.id][category] = props;
      });
    });

    vm.selectedChecks = vm.checks[vm.selectedCourse.id];
  }

  function createFormCopy() {
    vm.globalUnsubscriptionsClone = cloneDeep(vm.globalUnsubscriptions);
    vm.courseUnsubscriptionsClone = cloneDeep(vm.courseUnsubscriptions);
  }

  function applyCopyToForm() {
    vm.globalUnsubscriptions = vm.globalUnsubscriptionsClone;
    vm.courseUnsubscriptions = vm.courseUnsubscriptionsClone;
  }

  /**
     * Load the email preferences for this user from the server into the local data model
     */
  function loadUnsubscriptions() {
    CurrentUserManager.user.getEmailUnsubscriptions().then((data) => {
      // Assume the global unsubscriptions are at index 0.
      vm.globalUnsubscriptions = data.result[0].unsubscriptions;
      vm.courseUnsubscriptions = {};
      for (let i = 1; i < data.result.length; i += 1) {
        vm.courseUnsubscriptions[data.result[i].id] = data.result[i].unsubscriptions;
      }

      createFormCopy();

      updatePreferenceCheckboxes();
      updateSelectAll();
    });
  }

  /**
     * Update the vm.checks model for the checkboxes based on the unsubscriptions lists previously pulled from API
     */
  function updatePreferenceCheckboxes() {
    vm.courses.forEach((course) => {
      const courseId = course.id;

      getApplicableCategories(courseId).forEach((category) => {
        const checkObj = vm.checks[courseId][category];

        // 'All Courses' checkboxes
        if (courseId === ALL_COURSES_ID) {
          if (vm.courses.length === 1) {
            return;
          }

          if (COURSE_ONLY_CATEGORIES.includes(category)) {
            if (isAdminCategory(category)) {
              checkObj.uncheckedCount = _.reduce(vm.courses, (a, c, i) => (_.contains(vm.adminCourseIds, c.id) && _.contains(vm.courseUnsubscriptions[c.id], category) ? a + 1 : a), 0);
            } else {
              checkObj.uncheckedCount = _.values(vm.courseUnsubscriptions).reduce((a, unsubs) => (_.contains(unsubs, category) ? a + 1 : a), 0);
            }
            // Create the 'x of y courses enabled' string
            let numberEnabled;
            let total;

            // Calculate 'y' differently for admin categories
            if (isAdminCategory(category)) { // Admin
              numberEnabled = vm.adminCourseIds.length - checkObj.uncheckedCount;
              total = vm.adminCourseIds.length;
            } else { // Non-admin
              numberEnabled = (vm.courses.length - 1) - checkObj.uncheckedCount;
              total = vm.courses.length - 1;
            }

            checkObj.countString = vm.shouldShowEnabledCount(category)
              ? $translate.instant('ACCOUNT_SETTINGS.EMAIL.ENABLED_COUNT', {
                numberEnabled,
                total,
              }) : '';

            setCheckboxIndeterminateState(checkObj, category);
          } else {
            checkObj.checked = !vm.globalUnsubscriptions.includes(category);
          }
        } else { // Course-specific checkboxes
          const unsubs = vm.courseUnsubscriptions[courseId];

          checkObj.checked = !_.contains(unsubs, category);
          checkObj.some = false;
        }
      });
    });
  }

  function updateSelectAll() {
    const isAllCourses = vm.selectedCourse.id === ALL_COURSES_ID;

    // Include 'submission_approval_admin' for course case since we should be
    // able to toggle globally and it's not course specific.
    const notAllSelected = [
      ...getApplicableCategories(vm.selectedCourse.id),
      ...(!isAllCourses ? ['submission_approval_admin'] : []),
    ].filter((category) => {
      if (isAdminCategory(category)) {
        return vm.shouldShowAdminPrefs();
      }

      if (category === 'submission_approval_admin') {
        return vm.shouldShowSubmissionApproval();
      }

      return true;
    }).some((c) => {
      if (c === 'submission_approval_admin') {
        return !vm.checks[ALL_COURSES_ID][c].checked;
      }

      return !(vm.selectedChecks[c].checked || vm.selectedChecks[c].some);
    });

    if (notAllSelected) {
      vm.allSelected = false;
    } else {
      vm.allSelected = true;
    }
  }

  /** Set 'checked' and 'some' on this checkbox based on the # of courses that have this notification unchecked. */
  function setCheckboxIndeterminateState(checkObj, category) {
    // The three checkbox states of checed, unchecked, and indeterminate "-" are achieved as follows:
    // unchecked: .checked and .some are both false
    // checked: .checked is true, .some is false
    // indeterminate: .checked is false, .some is true
    if (isAdminCategory(category)) {
      checkObj.some = checkObj.uncheckedCount > 0 && checkObj.uncheckedCount < (vm.adminCourseIds.length);
    } else if (category === 'submission_approval_admin') {
      checkObj.some = false;
    } else {
      checkObj.some = checkObj.uncheckedCount > 0 && checkObj.uncheckedCount < (vm.courses.length - 1); // Subtract 1 due to the dummy 'All Courses' entry
    }

    checkObj.checked = checkObj.uncheckedCount === 0;
  }

  /**
     * Create a list of unsubscriptions from the current checked/unchecked/indeterminate states of the checkboxes.
     * Basically the inverse of updatePreferenceCheckboxes(), used to make lists to send back to API to update the preferences.
     */
  function updateUnsubsFromCheckboxes() {
    const applicableCategories = getApplicableCategories(vm.selectedCourse.id).filter((category) => COURSE_ONLY_CATEGORIES.includes(category));

    const enabledNotifs = applicableCategories.filter((c) => vm.selectedChecks[c].checked);
    // Don't make a global unsubscription if at least some course is still subscribed
    const disabledNotifs = applicableCategories.filter((c) => !vm.selectedChecks[c].checked && !vm.selectedChecks[c].some);

    function updateUnsubscriptions(courseId) {
      let unsubs = vm.courseUnsubscriptions[courseId];
      unsubs = _.difference(unsubs, enabledNotifs); // Re-enable all the notifs for this course that are 'checked' in the global list
      unsubs = _.union(disabledNotifs, unsubs); // Disable all the notifs 'unchecked' in the global list
      vm.courseUnsubscriptions[courseId] = unsubs;
    }

    if (vm.selectedCourse.id === ALL_COURSES_ID) {
      vm.courses.forEach((course) => {
        const isAllCourses = course.id === ALL_COURSES_ID;

        if (isAllCourses) {
          vm.globalUnsubscriptions = GLOBAL_CATEGORIES.filter((category) => !vm.checks[course.id][category].checked);
        } else {
          updateUnsubscriptions(course.id, enabledNotifs, disabledNotifs);
        }
      });
    } else {
      vm.globalUnsubscriptions = GLOBAL_CATEGORIES.filter((category) => !vm.checks[ALL_COURSES_ID][category].checked);
      updateUnsubscriptions(vm.selectedCourse.id, enabledNotifs, disabledNotifs);
    }
  }

  function savePreferences() {
    const data = {
      global: {
        email_categories: vm.globalUnsubscriptions,
      },
    };

    vm.courses.forEach((course) => {
      if (course.id !== ALL_COURSES_ID) {
        data[course.id] = {
          email_categories: vm.courseUnsubscriptions[course.id],
        };
      }
    });

    vm.isSaving = true;
    return CurrentUserManager.user.updateEmailUnsubscriptions(data).finally(() => {
      vm.pristine = true;
      vm.isSaving = false;
    });
  }

  function isAdminCategory(category) {
    return _.contains(['completion_digests'], category);
  }

  vm.courseSelected = (course) => {
    vm.selectedCourse = course;
    vm.selectedChecks = vm.checks[vm.selectedCourse.id];

    CurrentUserManager.setEmailPreferenceCatalogId(vm.selectedCourse.catalogId ? vm.selectedCourse.catalogId : null);

    updateSelectAll();
  };

  vm.updateChecksModel = () => {
    updateUnsubsFromCheckboxes();
    updatePreferenceCheckboxes();
    updateSelectAll();
    vm.pristine = false;
  };

  vm.handleCancel = () => {
    applyCopyToForm();

    updatePreferenceCheckboxes();

    createFormCopy();

    updateSelectAll();

    vm.pristine = true;
  };

  vm.handleSave = () => {
    savePreferences().then(() => {
      createFormCopy();
    });
  };

  vm.selectAll = () => {
    const isAllCourses = vm.selectedCourse.id === ALL_COURSES_ID;

    // Include 'submission_approval_admin' for course case since we should be
    // able to toggle globally and it's not course specific.
    [
      ...getApplicableCategories(vm.selectedCourse.id),
      ...(!isAllCourses ? ['submission_approval_admin'] : []),
    ].forEach((c) => {
      if (c === 'submission_approval_admin') {
        vm.checks[ALL_COURSES_ID][c].checked = vm.allSelected;
      } else {
        vm.selectedChecks[c].checked = vm.allSelected;
      }
    });

    vm.updateChecksModel();
  };

  vm.shouldShowEnabledCount = (category) => {
    if (vm.selectedChecks[category]) {
      if (isAdminCategory(category)) {
        return vm.selectedChecks[category].uncheckedCount !== 0 && vm.selectedChecks[category].uncheckedCount !== (vm.adminCourseIds.length);
      } if (category === 'submission_approval_admin') {
        return vm.selectedChecks[category].uncheckedCount !== 0 && vm.selectedChecks[category].uncheckedCount !== (vm.submissionApprovalCourseIds.length);
      } if (GLOBAL_CATEGORIES.includes(category)) { // No need to show ({numberEnabled} of {total} courses enabled), as they are not course specific
        return false;
      }
      return vm.selectedChecks[category].uncheckedCount !== 0 && vm.selectedChecks[category].uncheckedCount !== (vm.courses.length - 1);
    }

    return false;
  };

  vm.shouldShowAdminPrefs = () => {
    if (vm.selectedCourse.id === ALL_COURSES_ID) {
      return vm.adminCourseIds.length > 0;
    }
    return _.contains(vm.adminCourseIds, vm.selectedCourse.id);
  };

  vm.shouldShowSubmissionApproval = () => {
    if (vm.selectedCourse.id === ALL_COURSES_ID) {
      return vm.submissionApprovalCourseIds.length > 0;
    }
    return _.contains(vm.submissionApprovalCourseIds, vm.selectedCourse.id);
  };

  function initialize() {
    createChecksModel();
    loadUnsubscriptions();

    const deregisterUnsavedChanges = StateManager.registerStateChangeStart(
      () => !vm.pristine,
      'shared/templates/modal-navigate-away.html',
      'FORM.NAVIGATE_AWAY.CLOSE_WINDOW',
    );
    const deregisterSaving = StateManager.registerStateChangeStart(
      () => vm.isSaving,
      'shared/templates/modal-navigate-away-unsaved-changes.html',
      'FORM.UNSAVED_CHANGES.CLOSE_WINDOW',
    );

    $scope.$on('$destroy', () => {
      deregisterSaving();
      deregisterUnsavedChanges();
    });
  }

  initialize();

  vm.tabConfig = StateManager.accountSettingsTabs.find(tab => tab.state === 'email-preferences');

  return vm;
}
