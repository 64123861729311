/* @ngInject */
export default function AdminAddMembersModalCtrl(

  vmResolves,
  TeamManager,
  $state,
  _,
) {
  const vm = this;
  _.extend(vm, vmResolves);

  vm.numShown = 10;
  vm.selectedMember = null;
  vm.newMembers = [];
  vm.addMember = addMember;
  vm.removeMember = removeMember;

  function addMember(item, model) {
    if (!vm.newMembers.includes(vm.selectedMember)) {
      vm.newMembers.push(vm.selectedMember);
    }
    vm.selectedMember = null;
  }

  function removeMember(member) {
    vm.newMembers.splice(_.indexOf(vm.newMembers, member), 1);
  }
}
