/** @jsx */
import { css, jsx } from '@emotion/react';
import React, { useState } from 'react';

import NvIcon from 'shared/components/nv-icon';
import {
  standardSpacing,
  modalOverlayZIndex,
  doubleSpacing,
} from 'styles/global_defaults/scaffolding';
import { gray2, gray3 } from 'styles/global_defaults/colors';
import {
  handheld,
  tablet,
} from 'styles/global_defaults/media-queries';
import ClickableContainer from 'components/clickable-container';


/**
 * Modal Pagination Component
 */

type NvModalPaginationProps = {
  children: any,
  onNext?: () => void,
  onPrev?: () => void,
  show?: boolean,
};

const NvModalPagination = ({ show = true, onNext, onPrev, children }: NvModalPaginationProps) => {
  const elementRef = React.useRef<HTMLDivElement>(undefined);
  const [direction, setDirection] = useState<'ltr' | 'rtl'>('rtl');
  const [animating, setAnimating] = useState(false);

  const goBack = () => {
    setDirection('ltr');
    onPrev();
  };

  const goNext = () => {
    setDirection('rtl');
    onNext();
  };

  const styles = css`

  .pagination-buttons {
    color: white;
    position: absolute;
    top: calc(45vh - ${standardSpacing}px ); //height / 2

    i {
      position: fixed;
    }

    ${handheld(css`
      background-color: ${gray2};
      opacity: 0.5;
      z-index: ${modalOverlayZIndex};
      width: ${doubleSpacing}px;
      height: ${doubleSpacing}px;
    `)};

    ${tablet(css`
      position: fixed;
      background-color: ${gray2};
      opacity: 0.8;
      z-index: ${modalOverlayZIndex};
      width: ${doubleSpacing}px;
      height: ${doubleSpacing}px;

    `)};
  }

  .prev {
    left: ${-doubleSpacing}px;

    ${handheld(css`
    left: 0;
    `)};

    ${tablet(css`
      left: 0px;
    `)};
  }

  .next {
    right: ${-doubleSpacing}px;

    ${handheld(css`
      right: 0;
    `)};

    ${tablet(css`
      right: 0px;
    `)};
  }

  .disabled {
    color: ${gray3};
  }
  `;


  return (
    <div css={styles}>
      {show && (
        <ClickableContainer
          className={`pagination-buttons prev d-flex align-items-center justify-content-center ${!onPrev ? 'disabled' : ''}`}
          onClick={onPrev ? goBack : null}
        >
          <NvIcon className='d-block d-md-none p-2' size='small' icon='arrow-left' />
          <NvIcon className='d-none d-md-block p-5' size='medium' icon='arrow-left' />
        </ClickableContainer>
      )}
      {children}
      {show && (
        <ClickableContainer
          className={`pagination-buttons next d-flex align-items-center justify-content-center ${!onNext ? 'disabled' : ''}`}
          onClick={onNext ? goNext : null}
        >
          <NvIcon className='d-block d-md-none p-2' size='small' icon='arrow-right' />
          <NvIcon className='d-none d-md-block p-5' size='medium' icon='arrow-right' />
        </ClickableContainer>
      )}
    </div>
  );
};

export default NvModalPagination;
