import React from 'react';
import single from 'webpack-hmr-singleton';

export const translationsLoaded = single(
  module,
  'translationsReady',
  () => ({ val: false }),
);

const useTranslationsLoaded = () => {
  const [transLoaded, setTransLoaded] = React.useState(false);

  React.useEffect(() => {
    // The number of seconds to wait before throwing an error that the translations file was not loaded
    const timeoutDuration = 10;
    // How often to check for the translations
    const checkInterval = 100;
    let attempts = 0;

    // The translations file is not always immediately available when we load a react-app due to it being requested by
    // angular_translate_init.js and then handed over to react-translate.js
    // This checks if the translations file is loaded and then continues normal rendering when it is.
    const intervalId = setInterval(() => {
      if (!transLoaded && translationsLoaded.val) {
        setTransLoaded(translationsLoaded.val);
        clearInterval(intervalId);
      } else {
        attempts += 1;
        if (attempts === ((timeoutDuration * 1000) / checkInterval)) {
          throw new Error(`Unable to load translations file when rendering React app after ${timeoutDuration} seconds`);
        }
      }
    }, checkInterval);
  }, []);

  return transLoaded;
};

export default useTranslationsLoaded;
