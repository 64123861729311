/* @ngInject */
export default function ExerciseModelService(

  _,
  $stateParams,
  $sce,
  nvUtil,
  moment,
  S3UploadFactory,
  S3NameSpaces,
  config,

  ExercisesResources,
  CurrentCourseManager,
  CurrentUserManager,
  ReportModel,
) {
  const NOT_STARTED = 'not_started';
  const IN_PROGRESS = 'in_progress';
  const COMPLETED = 'completed';
  const MISSED = 'missed';

  // - Submission Approval Specific
  const APPROVAL_REQUIRED = 'approval_needed';
  const PENDING_APPROVAL = 'pending';
  const REJECTED = 'rejected';
  const REJECTED_AND_MISSED = 'rejected_and_missed';
  const APPROVED = 'approved';

  const SHARABLE_WITH_INSTRUCTOR = 'sharable_with_instructor';
  const SHARABLE_WITH_INSTRUCTOR_AND_TEAM = 'sharable_with_instructor_and_team';
  const SHARABLE_WITH_INSTRUCTOR_OR_CLASS = 'sharable_with_instructor_or_class';
  const SHARABLE_WITH_CLASS_ONLY = 'sharable_with_class_only';
  const SHARABLE_WITH_PUBLIC = 'sharable_with_public';
  const SHARABLE_WITH_PUBLIC_ONLY = 'sharable_with_public_only';

  class ExerciseModel {
    static get(catalogId, id, language) {
      const request = ExercisesResources.get({ catalogId, id, language: language ?? undefined });
      request.$promise = request.$promise
        .then((resource) => new ExerciseModel(_.extend(resource.result, { catalogId })));

      return request;
    }

    static getAllExercisesForCourse(catalogId) {
      return ExercisesResources.getAllExercises({ catalogId }).$promise
        .then((response) => _.map(response.result, (exercise) => new ExerciseModel(exercise)));
    }

    static getGalleryEnabledExercises(catalogId) {
      return ExercisesResources.getGalleryEnabledExercises({ catalogId }).$promise
        .then((response) => _.map(_.values(response.result), (exercise) => new ExerciseModel(exercise)));
    }

    constructor(attributes) {
      _.extend(this, attributes);
      this.__preprocess();
    }

    /* Private Functions */
    __preprocess() {
      // We can treat the extended deadline as the deadline
      this.deadline = this.extendedDeadline || this.deadline;

      this.__deadlineMoment = moment(this.deadline);
      this.__releaseMoment = moment(this.releaseDate);

      this.submissions = _.map(this.submissions, (submission) => {
        const report = new ReportModel(submission);
        report.exercise = this;
        report.currentRevision = { ...report.currentRevision, sections: report.mergeSectionData(this.template.sections) };

        return report;
      });

      this.unsubmittedSubmissions = _.map(this.unsubmittedSubmissions, (submission) => {
        const report = new ReportModel(submission);
        report.exercise = this;
        report.currentRevision = { ...report.currentRevision, sections: report.mergeSectionData(this.template.sections) };

        return report;
      });

      if (this.customQuestions) {
        this.trustedEvaluationCriteria = _.map(this.customQuestions.questionTemplates, (criterion) => ({
          question: $sce.trustAsHtml(criterion.question),
          rubric: $sce.trustAsHtml(criterion.rubric),
        }));
      }

      this.__updatePrivacyOptions();
    }

    requestTemplate(catalogId, reportId) {
      return ExercisesResources.getTemplate({
        catalogId: catalogId || CurrentCourseManager.course.catalogId,
        id: reportId || this.id,
      }).$promise.then((resource) => {
        this.template = resource.result;
        return this.template;
      });
    }

    dateFormat() {
      if (this.__deadlineMoment.isSame(moment(), 'year')) {
        return 'MOMENT.MONTH_DAY_AT_TIME';
      }
      return 'MOMENT.MONTH_DAY_COMMA_YEAR_AT_TIME';
    }

    /* Submission Type */
    get isTeamSubmission() {
      return !!this.teamSet;
    }

    /* Status */
    getStatus() {
      return this.progress;
    }

    isReleased() {
      return moment() > this.__releaseMoment;
    }

    isNotStarted() {
      return this.progress === NOT_STARTED;
    }

    isInProgress() {
      return this.progress === IN_PROGRESS;
    }

    isCompleted() {
      return this.progress === COMPLETED;
    }

    isMissed() {
      return this.progress === MISSED;
    }

    // Submission Approval Specific
    isNotStartedAndApprovalRequired() {
      return this.progress === APPROVAL_REQUIRED;
    }

    isPendingApproval() {
      return this.progress === PENDING_APPROVAL;
    }

    isRejectedCanRevise() {
      return this.progress === REJECTED;
    }

    isRejectedCannotRevise() {
      return this.progress === REJECTED_AND_MISSED;
    }

    isApproved() {
      return this.progress === APPROVED;
    }

    /* Team Formation */
    hasCurrentTeam() {
      return this.teamSet && this.currentTeam;
    }

    needsTeam() {
      return this.teamSet && !this.assignmentTeam && !this.currentTeam;
    }

    needsInstructorFormedTeam() {
      return this.needsTeam() && !this.teamSet.formedByStudents;
    }

    needsStudentFormedTeam() {
      return this.needsTeam() && this.teamSet.formedByStudents;
    }

    /* Quiz Specific - cleanup needed */
    // isQuizDeliverable()
    // isExternalDeliverable()
    // quizLabelTranslateKey()
    // quizLabelTranslateValue()
    // maxPossibleScore()
    // submissionScore()
    // hasFullScore()
    // hasQuizAttemptsRemaining()
    // quizAttemptsCount()
    // canStillReceiveQuizPoints()
    // getLaunchUrl()

    currentTotalPoints() {
      if (this.hardDeadline && moment().diff(this.__deadlineMoment, 'days') > 0) {
        return nvUtil.getCurrentTotalPoints(this.totalPoints, this.releaseDate, CurrentCourseManager.course.isDecayEnabled(), true);
      }
      return nvUtil.getCurrentTotalPoints(this.totalPoints, this.releaseDate, CurrentCourseManager.course.isDecayEnabled());
    }


    canEditOrResume() {
      if (this.prerequisite && !CurrentUserManager.isAdmin()) {
        return false;
      }

      return this.canEdit() || this.canResume();
    }

    canEdit() {
      if (this.submissions.length) {
        if (this.submissions[0]) {
          return this.submissions[0].editable;
        } if (this.submissions) {
          // Lecture quiz
          return this.submissions.editable;
        }
      } else {
        return false;
      }

      return null;
    }

    canResume() {
      if (this.unsubmittedSubmissions.length) {
        return this.unsubmittedSubmissions[0].editable;
      }
      return false;
    }

    /* Admin Functions */
    uploadAttachements($files) {
      if (!this.attachments) {
        this.attachments = [];
      }

      _.each($files, (file) => {
        const s3UploadObject = S3UploadFactory.uploadToS3(file, S3NameSpaces.ATTACHMENTS, true);

        this.attachments.push(s3UploadObject);
        s3UploadObject.setAbortCallback(() => {
          s3UploadObject.cancelled = true;
          this.attachments = _.without(this.attachments, s3UploadObject);
        });

        s3UploadObject.promise.then((resp) => {
          const s3FileData = resp.config.data.file;
          const attachment = {
            filename: s3FileData.name,
            filesize: s3FileData.size,
            filetype: s3FileData.type,
            uniqueId: s3FileData.uniqueId,
          };

          ExercisesResources.saveAttachment({ catalogId: this.catalogId, id: this.id }, attachment).$promise.then((response) => {
            const resultAttachment = response.result;

            this.attachments = _.without(this.attachments, s3UploadObject);
            if (s3UploadObject.cancelled) {
              this.deleteAttachment(resultAttachment);
            } else {
              this.attachments.push(resultAttachment);
            }
          });
        });
      });
    }

    deleteAttachment(attachment) {
      return ExercisesResources.deleteAttachment({ catalogId: this.catalogId, id: attachment.id }, {}).$promise.then(() => {
        this.attachments = _.without(this.attachments, attachment);
      });
    }

    __updatePrivacyOptions() {
      // - not all uses for exercise has privacySetting provided
      if (!this.privacySetting) {
        this.privacyOptions = [];
        return;
      } if (this.privacySetting === SHARABLE_WITH_INSTRUCTOR) {
        this.privacyOptions = ['shared_with_instructor'];
      } else if (this.privacySetting === SHARABLE_WITH_INSTRUCTOR_AND_TEAM) {
        this.privacyOptions = ['shared_with_instructor_and_team'];
      } else if (this.privacySetting === SHARABLE_WITH_INSTRUCTOR_OR_CLASS) {
        this.privacyOptions = ['shared_with_instructor_and_team', 'shared_with_class'];
      } else if (this.privacySetting === SHARABLE_WITH_CLASS_ONLY) {
        this.privacyOptions = ['shared_with_class'];
      } else if (this.privacySetting === SHARABLE_WITH_PUBLIC) {
        this.privacyOptions = ['shared_with_instructor_and_team', 'shared_with_class', 'shared_with_public'];
      } else if (this.privacySetting === SHARABLE_WITH_PUBLIC_ONLY) {
        this.privacyOptions = ['shared_with_public'];
      }
    }

    getSubmissionReviewers() {
      return ExercisesResources.getSubmissionReviewers(
        { catalogId: this.catalogId, id: this.id },
      ).$promise.then((response) => {
        this.reviewers = response.result.reviewers;
      });
    }

    saveSubmissionReviewers(exerciseDraft) {
      this.approvalRequired = exerciseDraft.approvalRequired;
      this.reviewers = exerciseDraft.adminReviewers.concat(exerciseDraft.relationshipReviewers);

      if (this.approvalRequired) {
        return ExercisesResources.enableSubmissionReviewers(
          { catalogId: this.catalogId, id: this.id },
          {
            reviewers: _.map(this.reviewers, (reviewer) => _.pick(reviewer, ['id', 'name', 'isEnabled'])),
          },
        ).$promise.then((response) => {
        });
      }

      return ExercisesResources.disableSubmissionReviewers(
        { catalogId: this.catalogId, id: this.id },
        {},
      ).$promise.then((response) => {
      });
    }

    getCurrentDeadline() {
      let { deadline } = this;

      if (!_.isEmpty(this.extendedDeadlineDetails)) {
        const momentObject = this.extendedDeadlineDetails.map((extended) => (moment(extended.extendedTo)));
        const sortedDeadlineDetails = momentObject.sort((a, b) => a.diff(b));
        deadline = sortedDeadlineDetails[sortedDeadlineDetails.length - 1];
      }
      return deadline;
    }
  }

  ExerciseModel.DEFAULT_PRIVACY_SETTING_OPTIONS = SHARABLE_WITH_CLASS_ONLY;

  ExerciseModel.getPrivacySettingsForCourse = () => (CurrentCourseManager.course.publiclySharedFlyer ? ExerciseModel.PUBLIC_PRIVACY_SETTING_OPTIONS : ExerciseModel.PRIVATE_PRIVACY_SETTING_OPTIONS);
  ExerciseModel.PUBLIC_PRIVACY_SETTING_OPTIONS = [SHARABLE_WITH_INSTRUCTOR, SHARABLE_WITH_INSTRUCTOR_OR_CLASS, SHARABLE_WITH_CLASS_ONLY, SHARABLE_WITH_PUBLIC, SHARABLE_WITH_PUBLIC_ONLY];
  ExerciseModel.PRIVATE_PRIVACY_SETTING_OPTIONS = [SHARABLE_WITH_INSTRUCTOR, SHARABLE_WITH_INSTRUCTOR_OR_CLASS, SHARABLE_WITH_CLASS_ONLY];


  ExerciseModel.maxFileSize = `${config.files.mediumFileSizeLimit}MB`;
  ExerciseModel.allowedFileTypes = [config.files.presentations, config.files.documents, config.files.pdfs,
    config.files.audios, config.files.videos, config.files.images, config.files.allUploadableExtensions.join(',')].join(',');


  return ExerciseModel;
}
