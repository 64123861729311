/* @ngInject */
export default function TopicModelService(

  TopicsResources,
  _,
  $sce,
  $uibModal,
) {
  const TopicModel = function (attributes) {
    const _this = this;

    _.extend(_this, attributes);

    /** Public Functions * */
    _.extend(_this, {
      remove,
      hide,
      lock,
      unlock,
      follow,
      unfollow,
      markAsRead,
      getPostsCount,
      getReleasedPostsCount,
      getNumNewPosts,
      getNumNewReleasedPosts,
      trustDescriptionHtml,

      incrementPostCount,
      incrementNewPostCount,
      decrementPostCount,
      decrementNewPostCount,

      incrementReleasedPostCount,
      decrementReleasedPostCount,

      incrementNewReleasedPostCount,
      decrementNewReleasedPostCount,

      setPostCount,
      setNewPostCount,
      setReleasedPostCount,
      setNewReleasedPostCount,
    });


    /** Private Functions * */
    function remove() {
      const removeTopicConfirmationModal = $uibModal.open({
        backdropClass: 'modal-overlay-backdrop',
        templateUrl: 'discussions/templates/nv-delete-topic-confirmation-overlay.html',
        windowClass: 'modal-overlay',
        controller: function ctrl($scope) {
'ngInject';
          $scope.hasPosts = _this.getPostsCount() > 0;
        },
      });

      return removeTopicConfirmationModal.result.then(() => TopicsResources.delete({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise);
    }

    function hide() {

    }

    function lock() {
      return TopicsResources.lock({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise.then(() => {
        _this.locked = true;
      });
    }


    function unlock() {
      return TopicsResources.unlock({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise.then(() => {
        _this.locked = false;
      });
    }

    function follow() {
      return TopicsResources.follow({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise.then(() => {
        _this.followedByCurrentUser = true;
      });
    }

    function unfollow() {
      return TopicsResources.unfollow({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise.then(() => {
        _this.followedByCurrentUser = false;
      });
    }

    function markAsRead() {
      return TopicsResources.markRead({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise;
    }

    function getPostsCount() {
      return _this.topicsCount; // backend terms don't match frontend terms
    }

    function getReleasedPostsCount() {
      return _this.topicsReleasedCount; // backend terms don't match frontend terms
    }

    function getNumNewPosts() {
      return _this.numNewTopics; // backend terms don't match frontend terms
    }

    function getNumNewReleasedPosts() {
      return _this.numNewReleasedTopics; // backend terms don't match frontend terms
    }

    function incrementPostCount() {
      _this.topicsCount += 1;
    }

    function decrementPostCount() {
      _this.topicsCount -= 1;
    }

    function setPostCount(value) {
      _this.topicsCount = value;
    }

    function incrementNewPostCount() {
      _this.numNewTopics += 1;
    }

    function decrementNewPostCount() {
      _this.numNewTopics -= 1;
    }

    function setNewPostCount(value) {
      _this.numNewTopics = value;
    }

    function incrementReleasedPostCount() {
      _this.topicsReleasedCount += 1;
    }

    function decrementReleasedPostCount() {
      _this.topicsReleasedCount -= 1;
    }

    function setReleasedPostCount(value) {
      _this.topicsReleasedCount = value;
    }

    function incrementNewReleasedPostCount() {
      _this.numNewReleasedTopics += 1;
    }

    function decrementNewReleasedPostCount() {
      _this.numNewReleasedTopics -= 1;
    }

    function setNewReleasedPostCount(value) {
      _this.numNewReleasedTopics = value;
    }

    function trustDescriptionHtml() {
      return $sce.trustAsHtml(_this.description);
    }
  };

  TopicModel.getAllTopicsForCourse = function (params) {
    return TopicsResources.getTopics(params).$promise
      .then((resource) => _.map(resource.result, (topic) => new TopicModel(topic)));
  };

  TopicModel.getTopic = function (catalogId, topicId) {
    return TopicsResources.get({ catalogId, id: topicId }).$promise.then((resource) => new TopicModel(resource.result));
  };

  TopicModel.create = function (params) {
    return TopicsResources.save({
      catalogId: params.catalogId,
    }, {
      forum: {
        title: params.title,
        description: params.description,
        releaseDate: params.releaseDate,
        afterPosition: params.insertAfter,
      },
    }).$promise;
  };

  TopicModel.update = function (params) {
    return TopicsResources.update({
      catalogId: params.catalogId,
      id: params.id,
    }, {
      forum: {
        title: params.title,
        description: params.description,
        releaseDate: params.releaseDate,
        afterPosition: params.insertAfter,
      },
      forumId: params.id,
    }).$promise;
  };

  return TopicModel;
}
