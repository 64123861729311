import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { css, Global } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { AngularServicesContext } from 'react-app';
import { setSearchInSelectedOptionIdx } from 'redux/reducers/content-search';
import ContentSearchPanelBody from './content-search-panel-body';
import { contentSearchContext, contentSearchReducer } from './content-search-utils';
import ContentSearchBar from './content-search-bar';

const queryClient = new QueryClient();

const resultContainerStyle = css`
  flex: 1;
  position: relative;
`;

const containerStyle = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

/* @ngInject */
export default function ContentSearch() {
  const lastContentSearchString = useSelector((state) => state.app.lastContentSearchString);
  const angularServices = React.useContext(AngularServicesContext);
  const dispatch = useDispatch();

  React.useEffect(() => {
    // if we are searching from the the course dashboard, initialize the "search-in" dropdown to "all courses"
    if (!angularServices.$state.params.catalogId) {
      dispatch(setSearchInSelectedOptionIdx(1));
    }
  }, [angularServices.$state.params.catalogId, dispatch]);

  const contentSearchStore = React.useReducer(contentSearchReducer, {
    wasSubmitted: false,
    inputValue: lastContentSearchString,
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Global
        styles={css`
          .flyout-content {
            /* .flyout-content sets a min-width but we need a fixed width instead */
            width: 50%;
          }

          #content-search-react-app {
            /* we need to propagate down the height explicitly so that we can apply overflow: hidden; in a child */
            height: 100%;

            /* bootstrapReact creates this extra div */
            & > div {
              height: 100%;
            }
          }
        `}
      />
      <contentSearchContext.Provider value={contentSearchStore}>
        <div css={containerStyle}>
          <ContentSearchBar />
          <div css={resultContainerStyle}>
            <ContentSearchPanelBody
              searchString={
                lastContentSearchString.trim() /* trimming here so we don't the value of the input but we search with the value trimmed */
              }
            />
          </div>
        </div>
      </contentSearchContext.Provider>
    </QueryClientProvider>
  );
}
