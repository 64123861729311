/* @ngInject */
export default function CourseHomeResources(

  $resource,
) {
  return $resource(
    '/:catalogId/home.json',
    { catalogId: '@catalogId' },
    {
      getCommunity: { method: 'GET', url: '/:catalogId/community_chatter' },
      getRecentCourseActivity: { method: 'GET', url: '/:catalogId/recent_activity' },
      getTrendingSubmissions: { method: 'GET', url: '/:catalogId/home/trending_submissions' },
    },
  );
}
