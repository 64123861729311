
import { css } from '@emotion/react';
import { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import t from 'react-translate';

// Schemas
import { RootState } from 'redux/schemas';
import { Communication, ItemState } from 'redux/schemas/models/course-communication';

// Selectors
import { getCommunication, getItemState } from 'redux/selectors/course-communications';

// Actions
import { sendCommunicationEmail } from 'redux/actions/course-communications';

// Styles
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { primary, gray4 } from 'styles/global_defaults/colors';
import { semiBoldFontWeight } from 'styles/global_defaults/fonts';

type EmailHeaderProps = {
  communicationId?: number,
  communicationDraft?: Omit<Communication, 'id'>
  catalogId?: string,
};

type StateProps = {
  communication: Communication,
  itemState: ItemState
};

const mapDispatchToProps = {
  sendCommunicationEmail,
};

const styles = (isEnabled: boolean, isEmailSending: boolean) => css`
  padding: ${standardSpacing}px 0;
  border-top: 2px solid black;
  border-bottom: 1px solid ${gray4};
  display: flex;
  justify-content: space-between;

  .email-test {
    color: ${isEnabled ? primary : gray4};
    cursor: ${isEnabled ? 'pointer' : 'auto'};
    display: flex;
    align-items: center;
    font-weight: ${isEmailSending ? 'normal' : semiBoldFontWeight};
  }
`;

const EmailHeader = (props: EmailHeaderProps & StateProps & typeof mapDispatchToProps) => {
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const communication = props.communication ?? props.communicationDraft;
  const [isEmailSending, setIsEmailSending] = useState(false);

  const catalogIdToUse = props.catalogId ?? catalogId;

  const sendEmail = async () => {
    setIsEmailSending(true);
    if (communication) {
      await props.sendCommunicationEmail({
        catalogId: catalogIdToUse,
        communication,
      });

      // Toggle back to normal state once the promise got resolved
      setIsEmailSending(false);
    }
  };

  const isEnabled = !!(communication.communication?.subject && communication.communication?.body) && !isEmailSending;
  const sendEmailText = isEmailSending
    ? t.COURSE_COMMUNICATIONS.SENDING()
    : t.COURSE_COMMUNICATIONS.SAMPLE_EMAIL.SEND_TEST_EMAIL();
  const showSendEmailText = props.itemState !== ItemState.SENT && props.itemState !== ItemState.ERROR;

  return (
    <div css={styles(isEnabled, isEmailSending)}>
      <div className='email-title page-title-small font-weight-bolder'>{t.COURSE_COMMUNICATIONS.SAMPLE_EMAIL.TITLE()}</div>
      {showSendEmailText && (
        <div
          className='text-small email-test'
          onClick={() => isEnabled && sendEmail()}
        >
          {sendEmailText}
        </div>
      )}
    </div>
  );
};

const ConnectedEmailHeader = connect<StateProps, typeof mapDispatchToProps, EmailHeaderProps, RootState>(
  (state: RootState, ownProps: EmailHeaderProps) => ({
    communication: getCommunication(state, ownProps.communicationId),
    itemState: getItemState(state, ownProps.communicationId),
  }),
  mapDispatchToProps as any,
)(EmailHeader);

export default ConnectedEmailHeader;
