import t from 'react-translate';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { css } from '@emotion/react';

import { RootState } from 'redux/schemas';
import { gray1 } from 'styles/global_defaults/colors';
import { standardSpacing, modalOverlayZIndex } from 'styles/global_defaults/scaffolding';
import { getModalManager } from './nv-modal';

const styles = (isFullPage) => css`
  top: 0;
  left: 0;
  width: 100${isFullPage ? 'vw' : '%'};
  color: white;
  height: 100${isFullPage ? 'vh' : '%'};
  display: flex;
  position: ${isFullPage ? 'fixed' : 'relative'};
  text-align: center;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  z-index: ${isFullPage ? modalOverlayZIndex : 'inherit'};
  background-color: ${gray1}f2; /* f2 = .95 */

  padding-top: 20vh;
  padding-left: ${standardSpacing * 2}px;
  padding-right: ${standardSpacing * 2}px;

  .title-text {
    max-width: 800px;
    padding-top: ${standardSpacing}px;
  }
`;

type Props = {
  isShown: boolean,
  savingText: string,
  /**
   * According to this isFullPage, the loader will appear at the top of the
   * page or on the parent element.
   */
  isFullPage?: boolean,
};

/**
 * This component is an overlay and its purpose is to let user know that its
 * lecture component is being saved and that process is happening in background.
 * This update pattern applies to all those components where we should not allow
 * the user proceed unless the data is persisted and applies to the core
 * configurations of the component.
 */
export const NvSavingOverlay = (props: Props) => {
  const { isShown, isFullPage = true } = props;

  const loader = (
    <div css={styles(isFullPage)}>
      <div className='icon icon-large icon-refresh spin' />
      <div className='title-text page-title'>
        {props.savingText ?? t.LECTURE_PAGES.ADMIN.HEADER.EDIT_HEADER.SAVING()}
      </div>
    </div>
  );

  return (
    isShown && (
      isFullPage ? (
        <Modal show animation={false} manager={getModalManager()}>
          {loader}
        </Modal>
      ) : loader
    )
  );
};

const ConnectedNvSavingOverlay = connect<Props, {}, {}, RootState>(
  (state) => ({
    isShown: state.app.showSavingOverlay,
    savingText: state.app.savingOverlayText,
  }),
)(NvSavingOverlay);

export default ConnectedNvSavingOverlay;
