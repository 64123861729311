import { css } from '@emotion/react';
import React from 'react';

import LoadingPlaceholder from './loading-placeholder';
import LoadingSpinner, { LoadingSpinnerType } from './loading-spinner';

export enum LoaderType {
  SPINNER,
  PLACEHOLDER,
}

type Props = {
  children: any,
  isLoaded: boolean,
  className?: string,
  loaderType?: LoaderType,
  /**
   * Whether the content should be rendered even though the loading UI is
   * visible. true by default.
   */
  keepRendered?: boolean,
  spinnerType?: LoadingSpinnerType
  loadingInfo?: JSX.Element
  loadingInfoNoRender?: boolean
};

/**
 * A wrapping component used to hide a child component until a condition is met. Displays a loading spinner
 * until the condition is met.
 */
const LoadingWrapper = (props: Props) => {
  const {
    children,
    isLoaded,
    className,
    loaderType,
    keepRendered = true,
    spinnerType,
    loadingInfo,
    loadingInfoNoRender,
  } = props;
  // Float the spinner in the middle of the content
  const styles = css`
    display: ${loaderType === LoaderType.PLACEHOLDER ? 'block' : 'flex'};
    align-self: center;
    position: relative;

    .spinner {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  `;
  return (
    <React.Fragment>
      { !isLoaded ? (
        <div css={styles} className={`loading-wrapper${className ? ` ${className}` : ''}`}>
          { loaderType === LoaderType.PLACEHOLDER
            ? <LoadingPlaceholder />
            : <LoadingSpinner type={spinnerType} /> }
          {keepRendered && !loadingInfoNoRender && (
            // Use visibility: hidden so the content still sizes itself before
            // it's ready to display prevents the content shifting when loading
            // is complete.
            <React.Fragment>
              {loadingInfo && loadingInfo}
              <div css={css`visibility: hidden;`}>
                { children }
              </div>
            </React.Fragment>
          )}
          {loadingInfoNoRender && loadingInfo && (
            <React.Fragment>
              {loadingInfo}
            </React.Fragment>
          )}
        </div>
      ) : children }
    </React.Fragment>
  );
};

export default LoadingWrapper;
