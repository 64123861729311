import React from 'react';
import { jsx } from '@emotion/react';
import LectureComponentBaseModel from 'lecture_pages/services/components/lecture-component-base-model';

// make sure to wrap your component and override these values
const lectureComponentContext = React.createContext({
  lectureComponentId: -1,
  lecturePageId: -1,
  catalogId: '',
  isEdit: false,
  angularComponent: null,
  isDeadlineEditable: false,
});

interface LectureComponentProviderProps {
  lectureComponentId: number;
  lecturePageId: number;
  catalogId: string;
  isEdit: boolean;
  angularComponent: InstanceType<ReturnType<typeof LectureComponentBaseModel>>;
  isDeadlineEditable?: boolean;
}

/* @ngInject */
export default function LectureComponentProvider(props: React.PropsWithChildren<LectureComponentProviderProps>) {
  const value = React.useMemo(
    () => ({
      lectureComponentId: props.lectureComponentId,
      lecturePageId: props.lecturePageId,
      catalogId: props.catalogId,
      isEdit: props.isEdit,
      angularComponent: props.angularComponent,
      isDeadlineEditable: props.isDeadlineEditable,
    }),
    [props.catalogId, props.isEdit, props.lectureComponentId, props.lecturePageId, props.angularComponent, props.isDeadlineEditable],
  );

  return <lectureComponentContext.Provider value={value}>{props.children}</lectureComponentContext.Provider>;
}

/* @ngInject */
export function useLectureComponentContext() {
  return React.useContext(lectureComponentContext);
}

