import { SharedLectureComponentProps } from 'lecture_pages/directives/components/base-lecture-component';
import React, { useEffect, useRef, useState } from 'react';
import NvDropdown, { NvDropdownButtonStyle, NvDropdownAlign } from 'shared/components/inputs/nv-dropdown';
import useClickOutside from 'shared/hooks/use-click-outside';

type Props = {
  sharedProps: SharedLectureComponentProps,
  show?: boolean,
  children?: any,
  align?: NvDropdownAlign,
  menuClassName?: string,
  buttonStyle?: NvDropdownButtonStyle,
  tooltip?: string,
  initialIndex?: number,
  onCollapse?: (e: any) => void,
};

const defaultProps = {
  buttonStyle: NvDropdownButtonStyle.CUSTOM,
  align: NvDropdownAlign.RIGHT,
  manuClassName: 'todo-options-dropdown-menu',
};

const ActivityTypesDropdown = (props: Props) => {
  const {
    sharedProps,
    show = true,
    children,
    tooltip,
    initialIndex,
    buttonStyle = defaultProps.buttonStyle,
    menuClassName = defaultProps.manuClassName,
    align = defaultProps.align,
    onCollapse: hide,
  } = props;

  const { todoOptions = { items: [] } } = sharedProps;
  const containerRef = useRef<HTMLDivElement>();

  const onCollapse = (e) => {
    hide?.(e);
  };

  useClickOutside(containerRef, onCollapse);

  return (
    <div ref={containerRef}>
      <NvDropdown
        {...todoOptions}
        showSelectedIndicator
        show={show}
        buttonStyle={buttonStyle}
        menuClassName={menuClassName}
        align={align}
        customTarget={() => <div>{children}</div>}
        tooltip={tooltip}
        initialIndex={initialIndex}
      />
    </div>
  );
};

export default ActivityTypesDropdown;
