/* eslint-disable react/require-default-props */

import { SerializedStyles, css } from '@emotion/react';
import { Collapse } from 'react-bootstrap';
import NvFroala from 'froala/components/nv-froala';
import { FroalaViewMode } from 'froala/helpers/nv-froala-constants';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { AngularServicesContext } from 'react-app';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import React from 'react';
import { useLecturePageFromParams } from 'lecture_pages/hooks/lecture-routing';
import { LecturePageMode } from '..';
import { normalizeHTMLInput, normalizeHTMLOutput } from './accordion-utils';

interface RtContentProps {
  content: string;
  expanded: boolean;
  mode: LecturePageMode;
  onContentChange(newContent: string): void;
  containerCss?: SerializedStyles;
}

const ContainerBaseStyle = css`
  padding: ${standardSpacing}px;
`;

/* @ngInject */
export default function SectionContent(props: RtContentProps) {
  const [value, setValue] = React.useState<string>(
    normalizeHTMLInput(props.content, '<p class="froala-style-regular"><br /></p>'),
  );
  const angularServices = React.useContext(AngularServicesContext);
  const lecturePage = useLecturePageFromParams();
  let content = <ResponsivelyEmbeddedAngularHTML template={props.content} angularServices={angularServices} />;
  if (props.mode === LecturePageMode.EDIT) {
    content = (
      <NvFroala
        editorClass='rich-text'
        immediateReactModelUpdate
        scrollableContainer='.main-panel-scrollable'
        value={value}
        onChange={(html) => {
          setValue(html);
        }}
        preset={FroalaViewMode.NORMAL}
        onBlur={() => {
          props.onContentChange(normalizeHTMLOutput(value));
        }}
      />
    );
  }

  return (
    // set mountOnEnter as a workaround for media content that loads in iframes
    // since it need to measure the space but won't get the right values if the
    // container is collapsed
    <Collapse in={props.expanded} mountOnEnter>
      <div>
        {/* DIV to prevent Cumulative Layout Shift (https://web.dev/cls) for the collapse component */}
        <div
          className='rich-text'
          css={css`
            ${ContainerBaseStyle};
            ${props.containerCss};
          `}
        >
          {content}
        </div>
      </div>
    </Collapse>
  );
}
