import { css } from '@emotion/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { CourseRegistrationType } from 'redux/schemas/models/course';
import NvRadioButton from 'shared/components/inputs/nv-radio-button';
import NvIcon from 'shared/components/nv-icon';
import { gray4, gray6, gray7, white } from 'styles/global_defaults/colors';
import { doubleSpacing, extraLargeSpacing, halfSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import NvDropdown, { NvDropdownButtonStyle, NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import { CloneCourseContext, CloningType, MediaRegistrationConfiguration, cloneCourseContextType } from '../hooks/use-clone-course-form';
import { CombinedInstitution, RootState } from 'redux/schemas';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import ClickableContainer from 'components/clickable-container';

import { config } from '../../../../../config/config.json';

const MediaAndRegistration = () => {
  const currentInstitution = useSelector<RootState, CombinedInstitution>(getCurrentInstitution);
  const courseRegistrationOptions = useMemo(() => (currentInstitution?.courseEnrollmentTypes.map(enrollment => (
    parseInt(enrollment)
  ))), []) ?? [];
  const cloneCourseFormMethods = useContext<cloneCourseContextType>(CloneCourseContext);
  const {
    addMediaAndRegistrationToForm,
    formData,
    cloningType,
  } = cloneCourseFormMethods;

  const [configuration, setConfiguration] = useState<MediaRegistrationConfiguration>({
    registrationType: formData.registrationType,
    copyAudioVideo: formData.copyAudioVideo,
    openInJourney: formData.openInJourney,
  });
  const [collapsed, setCollapsed] = useState(false);
  const mediaAndRegistrationStyle = css`
    margin-top: ${standardSpacing}px;
    border: solid 1px ${gray6};
    border-right-style: none;
    border-left-style: none;
    .info {
      overflow: hidden;
      max-height: 100%;
      padding: ${standardSpacing}px ${extraLargeSpacing}px ${doubleSpacing}px ${extraLargeSpacing}px;
      &.collapsed {
        max-height: 0px;
        padding: 0px;
      }

      .registration-type {
        padding-right: ${standardSpacing}px;
        .title {
          width: 290px;
        }
        .nv-dropdown {
          width: 290px;
          position: absolute;
        }
        .checkbox {
          padding: ${halfSpacing}px 0px;
          margin-top: 42px; // height of the dropdown menu
        }
      }
      .media-settings {
        .icon {
          padding-right: ${halfSpacing}px;
        }
        .media-option {
          cursor: pointer;
          padding: 0px ${halfSpacing}px ${halfSpacing}px;
          border-radius: 5px;
          border: solid 1px ${white};
          &:hover {
            background-color: ${gray7};
            border-color: ${gray7};
          }
          &.selected {
            border-color: ${gray4};
          }
        }
      }
    }
    .collapsible {
      padding: ${quarterSpacing}px ${standardSpacing}px;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: ${gray7}
      }
      .froala-style-medium {
        padding: 0px ${standardSpacing}px;
      }
    }
  `;

  useEffect(() => {
    addMediaAndRegistrationToForm(configuration);
  }, [configuration]);

  const registrationOptions: NvDropdownTextItem[] = courseRegistrationOptions.map(registrationType => ({
      type: 'text',
      text: t.COURSES.REGISTRATION_TYPE[`TYPE_${registrationType}`](),
      callback: () => {
        setConfiguration(
          {
            ...configuration,
            registrationType,
          },
        );
      },
      disabled: registrationType !== CourseRegistrationType.CLOSED_ENROLLMENT && cloningType === CloningType.PRIMARY_COURSE,
      tooltip: {
        text: t.COURSES.CLONE.STEPS.STEP_ONE.MEDIA_AND_REGISTRATION_TYPE.REGISTRATION_TYPE.NOT_PRIMARY_OPTIONS_TOOLTIP(),
        enabled: registrationType !== CourseRegistrationType.CLOSED_ENROLLMENT && cloningType === CloningType.PRIMARY_COURSE,
        placement: 'top',
      }
  }));

  return (
    <div className='media-and-registration' css={mediaAndRegistrationStyle}>
      <div className='collapsible d-flex' onClick={(e) => { setCollapsed(!collapsed); }}>
        <NvIcon size='xss-smallest' icon={collapsed ? 'arrow-right' : 'arrow-down'} />
        <div className='froala-style-medium font-weight-bolder'>
          { t.COURSES.CLONE.STEPS.STEP_ONE.MEDIA_AND_REGISTRATION_TYPE.TITLE() }
        </div>
      </div>
      <div className={`info d-flex ${collapsed ? 'collapsed' : ''}`}>
        <div className='registration-type'>
          <div className='text-gray-2 title'>
            { t.COURSES.CLONE.STEPS.STEP_ONE.MEDIA_AND_REGISTRATION_TYPE.REGISTRATION_TYPE.TITLE() }
          </div>
          {
            !collapsed &&
            <NvDropdown
              items={registrationOptions}
              initialIndex={courseRegistrationOptions.findIndex(option => option === configuration.registrationType)}
              buttonStyle={NvDropdownButtonStyle.FORM_SMALL}
              titleClass='text-gray-1'
              toggleDataQa={config.pendo.cloningModal.registrationType}
            />
          }
          {
            (configuration.registrationType === CourseRegistrationType.CLOSED_ENROLLMENT
              || (configuration.registrationType === CourseRegistrationType.OPEN_BASED_ON_ENTITLEMENTS
                && cloningType !== CloningType.PRIMARY_COURSE)
            )
            && (
              <NvCheckbox
                name='openInJourney'
                label={t.COURSES.CLONE.STEPS.STEP_ONE.MEDIA_AND_REGISTRATION_TYPE.REGISTRATION_TYPE.OPEN_IN_JOURNEY()}
                className='checkbox'
                defaultChecked={configuration.openInJourney}
                onChange={(event) => setConfiguration(
                  {
                    ...configuration,
                    openInJourney: event.currentTarget.checked,
                  },
                )}
              />
            )
          }
        </div>
        <div className='media-settings'>
          <div className='text-gray-2'>
            { t.COURSES.CLONE.STEPS.STEP_ONE.MEDIA_AND_REGISTRATION_TYPE.MEDIA_SETTINGS.TITLE() }
          </div>
          <ClickableContainer
            className={`media-option d-block mb-2 ${!configuration.copyAudioVideo ? 'selected' : ''}`}
            onClick={(event) => setConfiguration(
              {
                ...configuration,
                copyAudioVideo: false,
              },
            )}
          >
            <NvRadioButton
              value=''
              name='copyAudioVideo'
              label={t.COURSES.CLONE.STEPS.STEP_ONE.MEDIA_AND_REGISTRATION_TYPE.MEDIA_SETTINGS.SHARE_AUDIO_AND_VIDEO.LABEL()}
              labelIcon='share'
              labelIconClassName='text-gray-4'
              checked={!configuration.copyAudioVideo}
              data-qa={config.pendo.cloningModal.mediaSharing}
            />
            <div className='text-small text-gray-2 pl-5'>
              {t.COURSES.CLONE.STEPS.STEP_ONE.MEDIA_AND_REGISTRATION_TYPE.MEDIA_SETTINGS.SHARE_AUDIO_AND_VIDEO.DESCRIPTION()}
            </div>
          </ClickableContainer>
          <ClickableContainer
            className={`media-option d-block ${configuration.copyAudioVideo ? 'selected' : ''}`}
            onClick={(event) => setConfiguration(
              {
                ...configuration,
                copyAudioVideo: true,
              },
            )}
          >
            <NvRadioButton
              value='true'
              name='copyAudioVideo'
              label={t.COURSES.CLONE.STEPS.STEP_ONE.MEDIA_AND_REGISTRATION_TYPE.MEDIA_SETTINGS.COPY_AUDIO_AND_VIDEO.LABEL()}
              labelIcon='duplicate'
              labelIconClassName='text-gray-4'
              checked={configuration.copyAudioVideo}
              data-qa={config.pendo.cloningModal.mediaCopying}
            />
            <div className='text-small text-gray-2 pl-5'>
              {t.COURSES.CLONE.STEPS.STEP_ONE.MEDIA_AND_REGISTRATION_TYPE.MEDIA_SETTINGS.COPY_AUDIO_AND_VIDEO.DESCRIPTION()}
            </div>
          </ClickableContainer>
        </div>
      </div>
    </div>
  );
};

export default MediaAndRegistration;
