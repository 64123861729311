import React, { useEffect, useContext } from 'react';
import { css } from '@emotion/react';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { RootState } from 'redux/schemas';
import { getCollectionFolder } from 'redux/selectors/collections';
import { fetchFolder } from 'redux/actions/collections';
import { CollectionFolder } from 'redux/schemas/models/collections';

// Styles
import { doubleSpacing } from 'styles/global_defaults/scaffolding';

// Components
import NvRadioButton from 'shared/components/inputs/nv-radio-button';
import NvIcon from 'shared/components/nv-icon';

import { folderLessonSortKeys } from 'content-library/components/collection-home/folder-lecture-list';
import { useOnClickExpand } from './collection-row';
import { AddToCollectionModalContext } from './add-to-collection-modal-body';

type CollectionFolderProps = {
  folderId: number;
  collectionName: string;
};

const CollectionRowFolder = (props: CollectionFolderProps) => {
  const { folderId, collectionName } = props;
  const { selectedFolderId, setSelectedFolderId, setSelectedCollectionName } = useContext(AddToCollectionModalContext);
  const currentfolder: CollectionFolder = useSelector((state: RootState) => getCollectionFolder(state, folderId));

  const dispatch = useAppDispatch();
  const { isExpanded, setIsLoading } = useOnClickExpand();

  useEffect(() => {
    if (isExpanded) {
      dispatch(fetchFolder({
        folderId,
        pageIndex: 1,
        sorting: [`${folderLessonSortKeys[0]}&order=asc`],
      })).then(() => {
        setIsLoading(false);
      });
    }
  }, [dispatch, folderId, isExpanded, setIsLoading]);

  const onSelectFolder = (e) => {
    setSelectedFolderId(e.currentTarget.value);
    setSelectedCollectionName(collectionName);
  };

  return (
    <div className='ml-5'>
      <NvRadioButton
        name='folder'
        value={currentfolder.id}
        label={currentfolder.title}
        className='d-flex'
        labelClassName='d-inline-block mw-100 text-regular font-weight-bolder ellipsis'
        onChange={onSelectFolder}
        checked={currentfolder.id === +selectedFolderId}
        labelIcon='admin-folder'
        labelIconSize='small'
        labelIconClassName='ml-2 text-gray-3 pr-2'
      />
    </div>
  );
};

export default CollectionRowFolder;
