/**
 * Service to make the focus change to work properly on loading custom elements
 * like Modals, Sticky Headers, Flyouts etc
 *
 * Copied from Angular Bootstrap's UIBMODALSTACK
 */

/* @ngInject */
export default function ModalFocusService(
  _,
  $document,
  $rootScope,
  $timeout,
) {
  // Modal focus behavior
  const tabableSelector = 'a[href], area[href], input:not([disabled]), '
    + 'button:not([disabled]),select:not([disabled]), textarea:not([disabled]), '
    + 'iframe, object, embed, *[tabindex], *[contenteditable=true]';

  class ModalFocusManager {
    /**
     * Sets up the eventHandler on construct
     * @param eventHandler Keydown event handler
     * @param $element Is optional on construct, can be given on triggering focus
     */
    constructor(eventHandler, $element = null) {
      this.eventHandler = eventHandler;
      this.$element = $element;

      $document.on('keydown', this.eventHandler);

      $rootScope.$on('$destroy', () => {
        $document.off('keydown', this.eventHandler);
      });
    }

    // Focus the first element in $element
    triggerFocus(selector, $element = null) {
      if ($element) {
        this.$element = $element;
      }

      if (this.$element) {
        if (!($document[0].activeElement && this.$element[0].contains($document[0].activeElement))) {
          const inputWithAutofocus = this.$element[0].querySelector('[autofocus]');
          /**
             * Auto-focusing of a freshly-opened modal element causes any child elements
             * with the autofocus attribute to lose focus. This is an issue on touch
             * based devices which will show and then hide the onscreen keyboard.
             * Attempts to refocus the autofocus element via JavaScript will not reopen
             * the onscreen keyboard. Fixed by updated the focusing logic to only autofocus
             * the modal element if the modal does not contain an autofocus element.
          */
          if (inputWithAutofocus) {
            inputWithAutofocus.focus();
          } else {
            // THIS DIFFERS FROM UI BOOTSTRAP:
            $timeout(() => {
              $(selector).trigger('focus');
            });
          }
        }
      }
    }

    loadFocusElementList() {
      if (!this.$element) {
        return [];
      }
      const elements = this.$element[0].querySelectorAll(tabableSelector);
      return elements
        ? _.filter(elements, (element) => $(element).css('display') !== 'none') : elements;
    }

    focusFirstFocusableElement(list) {
      if (list.length > 0) {
        list[0].focus();
        return true;
      }
      return false;
    }

    focusLastFocusableElement(list) {
      if (list.length > 0) {
        list[list.length - 1].focus();
        return true;
      }
      return false;
    }

    isModalFocused(evt) {
      if (evt) {
        return (evt.target || evt.srcElement) === this.$element[0];
      }
      return false;
    }

    isFocusInFirstItem(evt, list) {
      if (list.length > 0) {
        return (evt.target || evt.srcElement) === list[0];
      }
      return false;
    }

    isFocusInLastItem(evt, list) {
      if (list.length > 0) {
        return (evt.target || evt.srcElement) === list[list.length - 1];
      }
      return false;
    }
  }

  return ModalFocusManager;
}
