import { NLecturePage } from 'redux/schemas/models/lecture-page';
import Singleton from 'shared/services/singleton';

// TODO: This seems like crazy overkill for sharing lecture page updates to the angularjs app
// (in the few cases where that's necessary). Let's reconsider this, and also document
class LecturePageRootSingleton extends Singleton {
  private _currentLecture: NLecturePage = null;

  get currentLecture() {
    return this._currentLecture;
  }

  set currentLecture(newLecture: NLecturePage) {
    this._currentLecture = newLecture;

    Object.values(this._currentLectureUpdatedCBs).forEach(cb => cb());
  }

  private _currentLectureUpdatedCBs: Record<string, () => void> = {};

  public registerLectureUpdatedCB = (uuid: string, cb: () => void) => {
    this._currentLectureUpdatedCBs[uuid] = cb;
  };

  public unregisterLectureUpdatedCB = (uuid: string) => {
    delete this._currentLectureUpdatedCBs[uuid];
  };
}

export default LecturePageRootSingleton.Instance(LecturePageRootSingleton);
