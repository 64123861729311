import LearnerDashboard from '../components/learner-dashboard';

/* @ngInject */
export default function DashboardController(
  $scope,
  ReportsManager,
  ConfirmationOverlays,
) {
  $scope.LearnerDashboard = LearnerDashboard;
  $scope.ConfirmationOverlays = ConfirmationOverlays;

  ReportsManager.getApprovalExercises(true);
}
