/* @ngInject */
export default function CourseHomeRhsBaseController(
  $scope,
  CourseHomeRhsManager,
  CurrentCourseManager,
  config,

  /* debugging only! */
  CurrentUserManager,
) {
  const vm = this;

  vm.config = config;

  vm.manager = CourseHomeRhsManager;
  vm.currentCourseManager = CurrentCourseManager;
  vm.CurrentUserManager = CurrentUserManager;

  return this;
}
