import React from 'react';
import * as yup from 'yup';
import uuid from 'react-uuid';
import { omit, pick } from 'lodash';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { AngularServicesContext } from 'react-app';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import NvIcon from 'shared/components/nv-icon';
import { FormReorderer } from 'components/reorderer';
import { InputType } from 'components/nv-click-to-edit';
import { PopoversContainerContext } from 'shared/react-utils';
import { Institution } from 'redux/schemas/models/institution';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { gray3, gray5, warning } from 'styles/global_defaults/colors';
import { FormOrgProfileField } from 'redux/schemas/models/account-fields';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { saveOrgLevelProfileSettings } from 'redux/actions/org-level-profile';
import { getName, getPlaceholder, getInputType } from 'org_level_profile/utils';
import {
  getFormOrgLevelProfileSettings as orgLevelProfileSettingsSelector,
} from 'redux/selectors/org-level-profile';
import {
  halfSpacing,
  largeSpacing,
  tripleSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import FieldModifier, {
  FormFieldModifier,
} from 'org_level_profile/components/field-modifier';
import {
  FormOrgLevelProfileSettings,
} from 'redux/schemas/models/org-level-profile-settings';

type FormProps = {
  onCancel: () => void,
};

/**
 * Org Level Profile settings form
 */
const Form = React.forwardRef<any, FormProps>((props, ref) => {
  // These are the only fields that need validation for this configuration modal
  const validationSchemaRef = React.useRef(yup.object().shape({
    orgLevel: yup.array().of(
      yup.object({
        isNew: yup.boolean(),
        migratedField: yup.string().ensure(),
        inputType: yup.string().ensure().when('isNew', {
          is: true,
          otherwise: yup.string(),
          then: yup.string().oneOf([InputType.DROPDOWN, InputType.SHORT_TEXT, InputType.LONG_TEXT]),
        }),
        name: yup.string().ensure().when(
          'migratedField',
          (migratedField, schema) => (
            migratedField
              ? schema
              : schema
                .min(1, t.VALIDATION.REQUIRED())
                .max(255, t.VALIDATION.MAX_LENGTH('255'))
          ),
        ),
        selectOptions: yup.string().ensure().when('inputType', {
          is: 'dropdown',
          otherwise: yup.string(),
          then: yup.string().min(1, t.VALIDATION.REQUIRED()),
        }),
      }),
    ),
  }));

  const dispatch = useAppDispatch();
  const containerRef = React.useRef<HTMLDivElement>();
  const orgLevelProfileSettings = useSelector(orgLevelProfileSettingsSelector);
  const {
    $state,
    $scope,
    InstitutionsManager,
  } = React.useContext(AngularServicesContext);

  const getAccountLevelField = (
    fieldIdentifier: string,
  ): FormOrgProfileField => {
    const {
      isHidden,
      isIntegrated,
    } = orgLevelProfileSettings.accountLevel[fieldIdentifier];

    return {
      isHidden,
      id: uuid(),
      isIntegrated,
      isDisabled: false,
      isNew: false,
      picture: null,
      selectOptions: '',
      isCsvManaged: false,
      migratedField: null,
      name: getName(fieldIdentifier),
      inputType: getInputType(fieldIdentifier),
      viewOptions: {
        iconColor: warning,
        icon: fieldIdentifier === 'location' ? 'location' : null,
      },
    };
  };

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchemaRef.current),
    defaultValues: {
      orgLevel: orgLevelProfileSettings.orgLevel,
      accountLevel: {
        bio: getAccountLevelField('bio'),
        headline: getAccountLevelField('headline'),
        location: getAccountLevelField('location'),
      },
    },
  });

  React.useImperativeHandle(ref, () => form);

  const {
    reset,
    control,
    setValue,
    formState,
    getValues,
    handleSubmit,
  } = form;

  const styles = css`
    .message {
      margin-bottom: ${largeSpacing}px;
    }

    .section-header {
      padding-bottom: ${halfSpacing}px;
      border-bottom: 1px solid ${gray5};

      .label {
        color: ${gray3};
      }
    }

    .field-item {
      border-bottom: 1px solid ${gray5};
    }

    .account-level-fields {
      margin-bottom: ${largeSpacing}px;
    }

    .org-level-fields {
      margin-bottom: ${halfSpacing}px;
    }

    .add-field {
      .add-field-icon {
        display: inline-block;
      }
    }

    .button-bar {
      margin-top: ${tripleSpacing}px;
    }
  `;

  const handleAddField = () => {
    const orgLevelFields = [
      ...getValues().orgLevel,
      {
        name: '',
        id: uuid(),
        isNew: true,
        picture: null,
        inputType: null,
        isHidden: false,
        isDisabled: false,
        selectOptions: '',
        isCsvManaged: false,
        isIntegrated: false,
        migratedField: null,
        viewOptions: {
          icon: null,
          iconColor: null,
        },
      },
    ];

    setValue(
      'orgLevel',
      orgLevelFields,
      { shouldValidate: true, shouldDirty: true },
    );
  };

  const handleUpdateClick: SubmitHandler<FormOrgLevelProfileSettings> = (formData) => {
    const { orgLevel, accountLevel } = formData;
    const accountRelevantFields = ['isHidden'];
    const orgIrrelevantFields = ['isNew', 'migratedField', 'isIntegrated'];

    const handleConfirm = () => wrapThunkAction<Institution>(dispatch(
      saveOrgLevelProfileSettings({
        institutionId: $state.params.institutionId,
        form: {
          orgLevel: orgLevel.map((field, index) => ({
            ...omit(
              field,
              field.isNew
                ? orgIrrelevantFields.concat(['id'])
                : orgIrrelevantFields,
            ),
            index,
            // TODO: This might be broken. After adding the @types/lodash package, a type checking error
            // trhows if you remove this `as any` because the expression evaluates into a string[], not a string
            selectOptions: field.selectOptions.split(',').map(
              (option) => option.trim(),
            ).filter(Boolean) as any,
          })),
          accountLevel: {
            bio: pick(accountLevel.bio, accountRelevantFields),
            headline: pick(accountLevel.headline, accountRelevantFields),
            location: pick(accountLevel.location, accountRelevantFields),
          },
        },
      }),
    )).then((action) => {
      InstitutionsManager.institution.updateFromReact({
        profileSettings: action.payload.profileSettings,
      });

      reset();
      props.onCancel();
    });

    dispatch(openConfirmationDialog({
      onConfirm: handleConfirm,
      cancelText: t.FORM.CANCEL(),
      confirmText: t.FORM.YES_SURE(),
      title: t.INSTITUTIONS.ADVANCED_SETTINGS.ORG_LEVEL_PROFILE.ALERT_OVERLAY.TITLE(),
      bodyText: t.INSTITUTIONS.ADVANCED_SETTINGS.ORG_LEVEL_PROFILE.ALERT_OVERLAY.DESCRIPTION(),
    }));
  };

  const { errors, isValid, isDirty } = formState;

  React.useEffect(() => $scope.StateManager.registerStateChangeStart(
    () => isDirty,
    'shared/templates/modal-navigate-away.html',
    'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY',
  ), [isDirty]);

  const getIsAddButtonDisabled = () => {
    const values = getValues();

    if (Object.keys(values).length) {
      const fields = values.orgLevel;

      return !!fields.find((field, index) => {
        if (errors.orgLevel?.[index]) {
          return field.isNew && Object.keys(errors.orgLevel[index]).length;
        }

        return false;
      });
    }

    return false;
  };

  return (
    <PopoversContainerContext.Provider value={containerRef.current}>
      <FormProvider {...form}>
        <div css={styles} ref={containerRef}>
          <div className='text-body message'>
            {t.INSTITUTIONS.ADVANCED_SETTINGS.ORG_LEVEL_PROFILE.SETTINGS_MODAL.MESSAGE()}
          </div>
          <div className='d-flex section-header'>
            <div className='label'>
              {t.INSTITUTIONS.ADVANCED_SETTINGS.ORG_LEVEL_PROFILE.SETTINGS_MODAL.ACCOUNT_LEVEL_INFO()}
              {' '}
              <span className='italic'>
                {t.INSTITUTIONS.ADVANCED_SETTINGS.ORG_LEVEL_PROFILE.SETTINGS_MODAL.NOT_EDITABLE()}
              </span>
            </div>
          </div>
          <div className='account-level-fields'>
            <FormFieldModifier
              hideIcon
              control={control}
              isEditable={false}
              className='field-item'
              name='accountLevel.headline'
            />
            <FormFieldModifier
              hideIcon
              control={control}
              isEditable={false}
              className='field-item'
              name='accountLevel.bio'
            />
            <FormFieldModifier
              control={control}
              isEditable={false}
              className='field-item'
              name='accountLevel.location'
            />
          </div>
          <div className='d-flex section-header'>
            <div className='label' style={{ flex: 2 }}>
              {t.INSTITUTIONS.ADVANCED_SETTINGS.ORG_LEVEL_PROFILE.SETTINGS_MODAL.FIELD_NAME()}
            </div>
            <div className='label' style={{ flex: 1 }}>
              {t.INSTITUTIONS.ADVANCED_SETTINGS.ORG_LEVEL_PROFILE.SETTINGS_MODAL.INPUT_TYPE()}
            </div>
          </div>
          <div className='org-level-fields'>
            <FormReorderer<FormOrgProfileField>
              name='orgLevel'
              keyExtractor={(orgLevelField) => orgLevelField.id}
              renderItem={({
                item,
                index,
                moveUp,
                moveDown,
                canMoveUp,
                canMoveDown,
              }) => {
                const handleDelete = () => {
                  const orgLevelFields = [...getValues().orgLevel];
                  orgLevelFields.splice(index, 1);

                  setValue(
                    'orgLevel',
                    orgLevelFields,
                    { shouldValidate: true, shouldDirty: true },
                  );
                };

                const handleChange = (field) => {
                  const orgLevelFields = [
                    ...getValues().orgLevel,
                  ];

                  orgLevelFields[index] = field;

                  setValue(
                    'orgLevel',
                    orgLevelFields,
                    { shouldValidate: true, shouldDirty: true },
                  );
                };

                const fieldErrors = errors?.orgLevel?.[index];

                return (
                  <FieldModifier
                    field={item}
                    onMoveUp={moveUp}
                    isNew={item.isNew}
                    canMoveUp={canMoveUp}
                    onMoveDown={moveDown}
                    className='field-item'
                    onChange={handleChange}
                    onDelete={handleDelete}
                    canMoveDown={canMoveDown}
                    popoverContainer={containerRef}
                    nameError={fieldErrors?.name as any}
                    placeholder={getPlaceholder(item.migratedField)}
                    selectOptionsError={fieldErrors?.selectOptions as any}
                  />
                );
              }}
            />
          </div>
          <button
            type='button'
            onClick={handleAddField}
            disabled={getIsAddButtonDisabled()}
            className='btn btn-link hovered text-regular add-field'
          >
            <NvIcon icon='add' size='xss-smallest' className='add-field-icon' />
            {' '}
            {t.INSTITUTIONS.ADVANCED_SETTINGS.ORG_LEVEL_PROFILE.SETTINGS_MODAL.ADD_FIELD()}
          </button>
          <div className='button-bar'>
            <Button onClick={props.onCancel} variant='secondary'>
              {t.FORM.CANCEL()}
            </Button>
            <Button
              disabled={!isValid || !isDirty}
              onClick={handleSubmit(handleUpdateClick)}
            >
              {t.FORM.UPDATE()}
            </Button>
          </div>
        </div>
      </FormProvider>
    </PopoversContainerContext.Provider>
  );
});

type Props = {
  forwardShowModal: (func: () => void) => void,
};

const OrgLevelProfileSettings = (props: Props) => {
  const dispatch = useAppDispatch();
  const formRef = React.useRef<any>();
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    props.forwardShowModal(() => setShow(true));
  }, []);

  /**
   * Modal body currently has 20px, with this it sums 40px (what mocks defined).
   */
  const styles = css`
    padding: ${standardSpacing}px;
  `;

  const handleModalClose = () => {
    const close = () => setShow(false);

    if (formRef.current.formState.isDirty) {
      dispatch(openConfirmationDialog({
        onConfirm: close,
        cancelText: t.FORM.CANCEL(),
        confirmText: t.FORM.YES_SURE(),
        title: t.FORM.UNSAVED_CHANGES.CLOSE_WINDOW.TITLE(),
        bodyText: t.FORM.UNSAVED_CHANGES.CLOSE_WINDOW.DESCRIPTION(),
      }));
    } else {
      close();
    }
  };


  return (
    <NvModal
      show={show}
      width={800}
      height={766}
      type={ModalType.FIXED}
      onClose={handleModalClose}
      header={t.INSTITUTIONS.ADVANCED_SETTINGS.ORG_LEVEL_PROFILE.SETTINGS_MODAL.TITLE()}
      body={(
        <div css={styles}>
          <Form ref={formRef} onCancel={handleModalClose} />
        </div>
      )}
    />

  );
};

export default OrgLevelProfileSettings;
