/* @ngInject */
export default function LeaderboardModel(

  _,
  $q,
  LeaderboardResources,
  $sce,
) {
  const Leaderboard = function (attributes) {
    const _this = this;
    _.extend(_this, attributes);
  };

  Leaderboard.getLeaderboard = function (catalogId) {
    return LeaderboardResources.getLeaderboard({ catalogId }).$promise
      .then((resource) => resource.result);
  };

  return Leaderboard;
}
