/* @ngInject */
export default function StyleGuideFroalaCtrl(

  MentionablesModel,
  humps,
  _,
  $uibModal,
) {
  const vm = this;

  vm.MentionablesModel = MentionablesModel;
  /* eslint-disable */
    var users = {'1204772':{'id':1204772,'first_name':'Teacher','last_name':'1','profile_picture':'https://d2d6mu5qcvgbk5.cloudfront.net/thumb/da977e311ab281fecf5be9d50293a28eda31d964.png?1484002484'},'1283497':{'id':1283497,'first_name':'Teacher','last_name':'2','profile_picture':'https://d2d6mu5qcvgbk5.cloudfront.net/thumb/f57505621bd7fddfb34ab1b873f8077d0c92d322.jpg?1483124221'},'1222081':{'id':1222081,'first_name':'Teacher','last_name':'3','profile_picture':'https://d2d6mu5qcvgbk5.cloudfront.net/thumb/c73d1d7fb3b293a5cef93a014f2872051e67c604.jpg?1480466055'},'1219074':{'id':1219074,'first_name':'Test','last_name':'6Student','profile_picture':null},'1219071':{'id':1219071,'first_name':'Test','last_name':'5Student','profile_picture':null},'1219066':{'id':1219066,'first_name':'Tester','last_name':'4Student4','profile_picture':null}};
    users = humps.camelizeKeys(users);
    MentionablesModel.setMentionableUsers(_.toArray(users));
    /* eslint-enable */

  vm.sampleTextFilled = `<p>
      <strong><span data-redactor-tag="span">SCENARIO 2: OVERWHELMED BY POSSIBILITIES</span></strong>
    </p>
    <hr>
    <p style="margin-left: 20px">
    At your next check-in, your team seems to have unlocked a creative burst of  inspiration. They have tons of ideas.
    The energy is great – like a kid in a candy store – and there are so many creative possibilities. But now the team is having a hard time getting from what the solution
      <em>could be</em> to what it <em>should be</em>. Will you guide your team to
      <strong>converge</strong> or <strong>diverge</strong>? Why?
    </p>
    <p>
      <strong><span data-redactor-tag="span">SCENARIO 2: OVERWHELMED BY POSSIBILITIES</span></strong>
    </p>
    <hr>
    <p style="margin-left: 20px">
    At your next check-in, your team seems to have unlocked a creative burst of  inspiration. They have tons of ideas.
    The energy is great – like a kid in a candy store – and there are so many creative possibilities. But now the team is having a hard time getting from what the solution
      <em>could be</em> to what it <em>should be</em>. Will you guide your team to
      <strong>converge</strong> or <strong>diverge</strong>? Why?
    </p>`;
  vm.sampleTextFilled = '<p class="no-paragraph-class" style="text-align: center"><span style="color: rgb(79, 152, 12)">Post1</span></p>';
  vm.sampleTextFilled = `<p><img src="http://novoed2qa2.purple.novoed.com/embeddings/111228"
      alt="http://novoed2qa2.purple.novoed.com/embeddings/111228"><br></p><p>post (with jpeg) description text.</p><p><br></p>`;

  $('.loading-screen').hide();
}
