let isPointsModalShowing = false;
const pointsModalQueue = [];

/* @ngInject */
export default function LectureComponentBaseController(
  _,
  moment,
  $q,
  $state,
  $stateParams,
  $uibModal,
  nvUtil,
  RailsRoutes,

  CurrentUserManager,
  CurrentCourseManager,
  LectureComponentsHelper,
  ConfirmationOverlays,
) {
  _.extend(this, {
    moment,
    $stateParams,
    $state,
    nvUtil,
    RailsRoutes,

    CurrentCourseManager,
    CurrentUserManager,

    formId: nvUtil.randomPositiveInteger(),

    saveTitleOnBlur,
    toggleDeadline,
    showPointsModal,
    editBasics,
  });


  function saveTitleOnBlur() {
    this.lectureComponent.save();
  }

  function toggleDeadline() {
    if (this.lectureComponent.hasDeadline()) {
      if (this.lectureComponent.scheduledCommunicationsCount > 0) {
        ConfirmationOverlays.openConfirmationModal('lecture_pages/templates/due-date-change-warning-overlay.html',
          'AttachModalResolvesToVmCtrl', {
            vmResolves: () => ({
              scheduledCommunicationsCount: this.lectureComponent.scheduledCommunicationsCount,
              isRemoving: true,
            }),
          }).result.then(() => this.lectureComponent.removeDeadline());
      } else {
        this.lectureComponent.removeDeadline();
      }
    } else {
      this.lectureComponent.addDefaultDeadline();
    }
  }

  function showPointsModal(pointsResponse, extras) {
    const deferred = $q.defer();

    function _processPointsModalQueue() {
      const func = pointsModalQueue.shift();

      func?.();
    }

    function _showPointsModal() {
      isPointsModalShowing = true;
      const uibModalInstance = $uibModal.open({
        templateUrl: 'shared/templates/points-modal.html',
        windowClass: 'points-modal',
        controller: 'PointsModalCtrl as vm',
        resolve: {
          pointsReceived: pointsResponse.pointsReceived,
          leaderboardPoints: pointsResponse.leaderboardPoints,
          leaderboardRank: pointsResponse.leaderboardRank,
          priorLeaderboardRank: pointsResponse.priorLeaderboardRank,
          extras,
        },
      });

      uibModalInstance.closed.then(() => {
        isPointsModalShowing = false;
        deferred.resolve();

        _processPointsModalQueue();
      });
    }

    if (isPointsModalShowing) {
      pointsModalQueue.push(_showPointsModal);
    } else {
      _showPointsModal();
    }

    return deferred.promise;
  }

  function editBasics() {
    this.lectureComponent.createDraft();

    const modalInstance = LectureComponentsHelper.showEditModal(this.lectureComponent);

    modalInstance.result.then(() => {
      const closeModal = LectureComponentsHelper.showSavingOverlay(this.lectureComponent);

      this.lectureComponent.saveDraft().then(closeModal, closeModal);
    });
  }
}
