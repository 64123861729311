import { css } from '@emotion/react';
import t from 'react-translate';
import React, { useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';

// Schemas
import {
  SubmissionTab,
} from 'redux/schemas/models/video-practice';
import { CombinedCourse, RootState } from 'redux/schemas';
import { SkillTag } from 'redux/schemas/models/skill-tag';

// Selectors
import {
  getPracticeFeedbackActivity,
  getScenario, getSubmission,
  getIsSkillsFeedbackActivity,
} from 'redux/selectors/video-practice';
import { getCurrentUserId } from 'redux/selectors/timeline';
import { getCurrentCourse } from 'redux/selectors/course';

// Styles
import {
  halfSpacing,
  largeSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';

// Components
import { NvVideoPreviewImperativeInterface } from 'shared/components/nv-video-preview';
import { useQuery } from 'shared/hooks/use-query';
import { ActionTypes, PracticeSubmissionContext, PracticeSubmissionReducer, useUpdateTimeline } from './utils';
import SubmissionHeader from './submission/submission-header';
import SubmissionContainer from './submission/submission-container';
import FeedbackForm from '../feedbacks/feedback-form';
import PracticeCommentForm from './submission/practice-comment-form';
import SubmissionTabs from './submission/submission-tabs';

type VideoPracticeSubmissionProps = {
  submissionId: number
  skillTags?: SkillTag[]
  isPracticeRoom?: boolean
  isPracticeFeedback?: boolean
  scenarioId?: number
  submissionIds?: number[]
  lectureComponentId?: number
  isNext?: boolean
  selectedViewProp?: SubmissionTab
  isSupervisorReview?: boolean
  onNext?: () => void
  onFirst?: () => void
  onClose?: () => void
};

const styles = (isPracticeRoom: boolean, isAdmin: boolean) => css`
  .submission-action-option {
    .icon {
      margin-top: 2px;
    }
  }

  .tag-badge {
    border-radius: ${standardSpacing}px;
  }
  .name-container .first-name, .last-name {
    font-size: 12px;
  }
  .own-feedback-container {
    .own-feedback {
      opacity: 0;
    }
    &:hover, &:focus-within {
      .own-feedback {
        opacity: ${(isPracticeRoom && !isAdmin) ? 0 : 1};
      }
    }
  }
  .insights-loader {
    svg {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }
  .insight-count {
    height: 140px;
    min-width: 140px;
  }
  .phrase {
    width: fit-content;
    border-radius: ${threeQuartersSpacing}px;
    padding: 3px ${halfSpacing}px;
  }
  .practice-again-section {
    padding: ${largeSpacing}px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const Submission = (props: {
  submissionId: number;
  isAdmin: boolean;
}) => {
  const [state, practiceSubmissionDispatch] = useContext(PracticeSubmissionContext);

  useEffect(() => {
    practiceSubmissionDispatch({
      type: ActionTypes.SET_SUBMISSION,
      payload: props.submissionId,
    });
  }, [practiceSubmissionDispatch, props.submissionId]);

  return (
    <>
      <SubmissionHeader />
      <SubmissionContainer />
      {!props.isAdmin && (
        <FeedbackForm />
      )}
      <PracticeCommentForm
        timestampInfo={t.PRACTICE_ROOM.COMMENTS.TIMESTAMP_POPOVER()}
      />
      <SubmissionTabs />
    </>
  );
};

const PracticeSubmission = ({
  submissionId,
  skillTags,
  isPracticeRoom = true,
  isPracticeFeedback,
  submissionIds,
  selectedViewProp,
  isSupervisorReview,
  ...restProps
}: VideoPracticeSubmissionProps) => {
  const scenarioParam = useSelector((state) => state.app.practiceRoom.params.scenarioId);
  const scenarioId = scenarioParam ?? restProps.scenarioId;
  const practicesubmission = useSelector((state) => getSubmission(state, submissionId));
  const currentUserId = useSelector(getCurrentUserId);
  const {
    user,
    isRatedByCurrentUser,
  } = practicesubmission;
  const {
    isPracticeAdmin,
  } = useSelector((state) => getScenario(state, scenarioId));
  const isSkillsFeedbackActivity = useSelector(state => getIsSkillsFeedbackActivity(
    state, { lectureComponentId: restProps.lectureComponentId },
  ));
  const practiceFeedbackActivity = useSelector(state => getPracticeFeedbackActivity(
    state, { lectureComponentId: restProps.lectureComponentId },
  ));
  const currentCourse: CombinedCourse = useSelector((state: RootState) => getCurrentCourse(state));
  const currentEnrollment = useSelector((state: RootState) => state.models.enrollments[currentCourse?.userCourse]) ?? {};

  const practiceSubmissionRef = useRef(null);
  const videoRef = useRef(null);
  const playerInstanceRef = useRef<NvVideoPreviewImperativeInterface>(null);

  const isMyPractice = user?.id === currentUserId;
  const hideTabs = isSkillsFeedbackActivity && !isRatedByCurrentUser;
  const { isCourseAdmin = false } = currentEnrollment;
  const isAdmin = isCourseAdmin || isPracticeAdmin;
  const query = useQuery();
  const isAuthorFeedback = query.submissionView === SubmissionTab.AUTHOR_FEEDBACK;
  const redirectToAuthorFeedback = isAuthorFeedback && isAdmin;

  const timelineUpdateCallback = () => {
    if (isSkillsFeedbackActivity) {
      if (practiceFeedbackActivity?.progress === 'completed') {
        restProps?.onClose();
      } else if (practiceFeedbackActivity?.progress === 'in_progress') {
        if (restProps.isNext) restProps?.onNext();
        else restProps?.onFirst();
      }
    }
  };
  useUpdateTimeline(practiceFeedbackActivity, isSkillsFeedbackActivity, isPracticeFeedback, timelineUpdateCallback);

  const selectedView = useMemo(() => {
    if (selectedViewProp) {
      return selectedViewProp;
    }
    if (isSkillsFeedbackActivity || redirectToAuthorFeedback) {
      return SubmissionTab.AUTHOR_FEEDBACK;
    }
    return SubmissionTab.COMMENTS;
  }, [isSkillsFeedbackActivity, redirectToAuthorFeedback, selectedViewProp]);

  const practiceSubmissionStore = useReducer(PracticeSubmissionReducer, {
    submissionId,
    scenarioId,
    lectureComponentId: restProps.lectureComponentId,
    submissionIds,
    isPracticeRoom,
    skillTags,
    isCourseAdmin,
    isPracticeFeedback,
    isMyPractice,
    isSkillsFeedbackActivity,
    practiceSubmissionRefs: {
      playerInstanceRef,
      videoRef,
    },
    showCommentButton: !hideTabs || isAdmin,
    selectedView,
    isAdmin,
    showFeedbackForm: !isRatedByCurrentUser && !isMyPractice,
  });

  useEffect(() => {
    if (isSupervisorReview && scenarioId) {
      practiceSubmissionStore[1]({
        type: ActionTypes.SET_GENERAL_ATTRIBUTES,
        payload: {
          scenarioId,
          submissionId,
          skillTags,
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenarioId, isSupervisorReview, submissionId, skillTags]);

  return (
    <PracticeSubmissionContext.Provider value={practiceSubmissionStore}>
      <div
        css={styles(isPracticeRoom, isAdmin)}
        className='d-flex flex-column w-100'
        ref={practiceSubmissionRef}
      >
        {practicesubmission && (
          <Submission submissionId={submissionId} isAdmin={isAdmin} />
        )}
      </div>
    </PracticeSubmissionContext.Provider>
  );
};

export default PracticeSubmission;
