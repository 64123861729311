import { LecturePageMode } from 'lecture_pages/components';
import { LecturePageUrlParams, linkTolecturePage } from 'lecture_pages/components/lecture-page-routes';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from 'redux/schemas';
import { createSelector } from 'reselect';

type LecturePageLocationState = {
  previousMode: LecturePageMode,
};

export const getLecturePageParams = createSelector<RootState, string, number, LecturePageMode, number, boolean, any>(
  (state) => state.app.currentCatalogId,
  (state) => state.app.lecturePage.currentLectureId,
  (state) => state.app.lecturePage.mode,
  (state) => state.app.lecturePage.targetActivityId,
  (state) => state.models.courses[state.app.currentCatalogId]?.isContentManagementCollection,
  (catalogId, lecturePageId, mode, activityId, currentCourseIsCollection) => ({
    catalogId,
    lecturePageId,
    mode,
    activityId,
    currentCourseIsCollection,
  }),
);

/** Grabs the page route params from their storage properties in the Redux store.
 * We transfer these values to Redux so that only a Redux state change will trigger component re-rendering.
 * Having components use these directly will cause them to re-render before new data is loaded in the
 * lecture-page-container, breaking the Lecture Page app. */
export const useLecturePageParams = () => useSelector(state => getLecturePageParams(state));

export const getLecturePagefromParam = (state: RootState, params: LecturePageUrlParams, destinationLecturePageId?: number) => {
  const lecturePageId = destinationLecturePageId && destinationLecturePageId === state.app.destinationLecturePage?.id
    ? destinationLecturePageId : params.lecturePageId;
  return state.models.lecturePages[lecturePageId];
};

export const useLecturePageFromParams = (destinationLecturePageId?: number) => useSelector(state => getLecturePagefromParam(state, getLecturePageParams(state), destinationLecturePageId));
export const getDestinationLecturePage = (state: RootState) => (state.models.lecturePages[state.app.destinationLecturePage?.id]);

/** Calls useLocation() with the lecture page state generic */
export const useLecturePageLocation = () => useLocation<LecturePageLocationState>();

/** Returns a callback to be used to navigate between lecture pages, configurable to different page modes
 * savePreviousMode can be supplied to remember the current page mode, which can be returned to by calling
 * the callback from useExitLecturePageMode */
export const useLecturePageLink = () => {
  const history = useHistory<LecturePageLocationState>();
  const params = useLecturePageParams();

  const navigate = useCallback((newParams: Partial<LecturePageUrlParams>, savePreviousMode: boolean = false) => {
    history.push(linkTolecturePage({
      ...params,
      ...newParams,
    }), savePreviousMode ? {
      previousMode: params.mode,
    } : undefined);
  }, [history, params]);

  return navigate;
};

/** Creates a convenience callback for changing page modes (but no other params). Is a shorthand for using
 * useLecturePageLocation */
export const useEnterLecturePageMode = () => {
  const lectureLink = useLecturePageLink();

  return useCallback((newMode: LecturePageMode, savePreviousMode: boolean = false) => lectureLink({ mode: newMode }, savePreviousMode), [lectureLink]);
};

/** Creates a convenience callback for 'exiting' the current lecture page mode, as a shorthand for using useLecturePageLocation
 * 'exiting' causes the user to return to the previously saved mode, or View mode by default if none were saved */
export const useExitLecturePageMode = () => {
  const enterMode = useEnterLecturePageMode();
  const location = useLecturePageLocation();

  return useCallback(() => enterMode(location.state?.previousMode ?? LecturePageMode.VIEW), [enterMode, location.state?.previousMode]);
};
