import { isNotDesktop } from 'styles/global_defaults/media-queries';
import { LhsMenuContainer } from '../../lhs/components/lhs-menu-container';

/* @ngInject */
export default function NvLhs(
  config,
  $rootScope,
  $location,
  $document,
  nvUtil,
  $http,
) {
  return {
    restrict: 'A',
    scope: {
      isExpanded: '=',
      toggleHandler: '=',
      gridCtrl: '=',
    },
    link(scope, elem) {
      scope.MainGridCtrl = scope.gridCtrl;
      scope.config = config;
      scope.LhsMenuContainer = LhsMenuContainer;

      // Requesting Scenarios to determine whether to display Practice Room button
      if (!scope.MainGridCtrl.practiceRoomRequested) {
        scope.MainGridCtrl.practiceRoomRequested = true;
        $http.get('/scenarios/flyout.json').then((response) => {
          if (response?.data?.result?.scenarios?.length > 0) {
            scope.MainGridCtrl.practiceRoomEnabled = true;
          }
        });
      }

      // BEGIN ACCESSIBILITY FIXES COPIED FROM ANGULAR BOOTRAP'S UIBMODALSTACK and
      // Modified for lhs
      const focusFirstFocusableElement = function (list) {
        if (list.length > 0) {
          list[0].focus();
          return true;
        }
        return false;
      };

      const focusLastFocusableElement = function (list) {
        if (list.length > 0) {
          list[list.length - 1].focus();
          return true;
        }
        return false;
      };

      const isParentFocused = function (evt) {
        if (evt) {
          return (evt.target || evt.srcElement) === elem[0];
        }
        return false;
      };

      const isFocusInFirstItem = function (evt, list) {
        if (list.length > 0) {
          return (evt.target || evt.srcElement) === list[0];
        }
        return false;
      };

      const isFocusInLastItem = function (evt, list) {
        if (list.length > 0) {
          return (evt.target || evt.srcElement) === list[list.length - 1];
        }
        return false;
      };

      $document.on('keydown', keydownListener);

      $rootScope.$on('$destroy', () => {
        $document.off('keydown', keydownListener);
      });

      function keydownListener(evt) {
        if (evt.isDefaultPrevented()) {
          return evt;
        }
        if (isNotDesktop() && scope.MainGridCtrl.isLhsExpandedMobile) {
          switch (evt.which) {
            case 9: {
              const list = nvUtil.getFocusableElements(elem[0]);
              let focusChanged = false;
              if (evt.shiftKey) {
                if (isFocusInFirstItem(evt, list) || isParentFocused(evt)) {
                  focusChanged = focusLastFocusableElement(list);
                }
              } else if (isFocusInLastItem(evt, list)) {
                focusChanged = focusFirstFocusableElement(list);
              }

              if (focusChanged) {
                evt.preventDefault();
                evt.stopPropagation();
              }

              break;
            }
            default:
              break;
          }
        }
        return null;
      }
    },
    templateUrl: 'layouts/templates/lhs.html',
  };
}
