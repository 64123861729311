import React from 'react';
import { css, jsx } from '@emotion/react';

import { gray6, gray7 } from 'styles/global_defaults/colors';
import { borderStyle } from 'shared/components/nv-responsive-table';
import {
  halfSpacing,
  tripleSpacing,
  standardSpacing,
  createGridStyles,
} from 'styles/global_defaults/scaffolding';

const loadingRowStyles = css`
  height: ${standardSpacing * 4}px;
  display: flex;
  align-items: center;
  border-bottom: ${borderStyle};

  @keyframes shineAnimation {
    0% {
      background-position: 0% 50%;
    }

    100% {
      background-position: -100% 50%;
    }
  }

  .avatar, .bar {
    background: linear-gradient(90deg, ${gray6} 35%, ${gray7} 45%, ${gray6} 55%);
    background-size: 600% 600%;
    animation: shineAnimation 1.5s infinite;
  }

  .avatar {
    width: ${tripleSpacing}px;
    height: ${tripleSpacing}px;
    margin-top: ${halfSpacing}px;
    margin-bottom: ${halfSpacing}px;
    margin-left: ${standardSpacing}px;
  }

  .bar-panel {
    flex-grow: 1;
    margin-left: ${halfSpacing}px;
  }

  .bar {
    height: 4px;
    border-radius: 4px;
  }

  .bar:first-of-type {
    width: 50%;
    margin-bottom: ${standardSpacing}px;
  }

  .bar:last-of-type {
    width: 30%;
  }
`;

const LoadingRow = (props: {
  rowIndex: number,
  hideAvatar?: boolean,
}) => {
  const {
    rowIndex,
    hideAvatar = false,
  } = props;

  return (
    <div style={createGridStyles(1, rowIndex, 7)} css={loadingRowStyles}>
      {props.hideAvatar}
      {!hideAvatar && (
        <div className='avatar' />
      )}
      <div className='bar-panel'>
        <div className='bar' />
        <div className='bar' />
      </div>
    </div>
  );
};

export default LoadingRow;
