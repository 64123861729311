import * as Sentry from '@sentry/browser';

/* @ngInject */
export default function CopyToOfferingModalCtrl(
  _,
  moment,
  LecturePageModel,
  TimelineModel,
  CurrentCourseManager,
  CurrentUserManager,
  PusherManager,
  PusherChannels,
  AlertMessages,
  $uibModalInstance,
  course,
  content,
  type,
  updateCopyStatus,
) {
  class CopyToOfferingModalController {
    constructor() {
      this.LecturePageModel = LecturePageModel;
      this.TimelineModel = TimelineModel;
      this.CurrentCourseManager = CurrentCourseManager;
      this.CurrentUserManager = CurrentUserManager;
      this.PusherManager = PusherManager;

      this.course = course; // CourseModel
      this.content = content;
      this.type = type;
      this.updateCopyStatus = updateCopyStatus;
      this.canFetchTimeline = true;

      this.fetchTimeline();
      const courseChannel = PusherManager.setupChannel(PusherChannels.courseChannel(this.course.id));
      courseChannel.bind('timeline-update', this.fetchTimeline.bind(this));
    }

    fetchTimeline() {
      this.timeline = null; // TimelineModel
      this.noContent = false;
      this.errorsFetchingTimeline = false;
      this.loading = true;
      this.TimelineModel.getResourceAndPromise(this.course.catalogId, CurrentUserManager.user.id, CurrentUserManager.isAdmin(), this.course).promise.then((res) => {
        this.timeline = res;
        if (!this.timeline.lectureSections.length && !this.timeline.lectureSubsections.length) {
          this.noContent = true;
        }
      })
        .catch((error) => {
          this.errorsFetchingTimeline = true;
          Sentry.configureScope((scope) => {
            scope.setExtras({
              catalog_id: this.course.catalogId,
              user_id: CurrentUserManager.user.id,
            });
          });

          Sentry.captureException(error);
        })
        .finally(() => { this.loading = false; });
    }

    getModalHeader() {
      return `TIMELINE.PICK_LOCATION.${this.type}`;
    }

    getModalDescription() {
      return `TIMELINE.DESCRIPTION.${this.type}`;
    }

    isTypeAllowed(allowedValues) {
      return _.contains(allowedValues, this.type);
    }

    // dashed line is clickable if copying a lesson or copying a subsection
    // beneath the last lesson of the previous subsection
    dashedLineClickable(lessons, index) {
      return this.type === 'LESSON' || (this.type === 'SUBSECTION' && lessons.length - 1 === index);
    }

    // solid line is clickable if copying a lesson or copying a subsection
    // beneath a subsection with no lecture pages
    solidLineClickable(subsection) {
      return this.type === 'LESSON' || (this.type === 'SUBSECTION' && !subsection.lecturePages.length);
    }

    // first section of course with no sectionless subsections
    firstSection($first) {
      return $first && !this.timeline.lectureSubsections.length;
    }

    // first subsection with no subsectionless lessons above it
    firstSubsection(subsection, parent) {
      return this.type === 'SUBSECTION' && subsection.index === 0 && !parent.lecturePages.length;
    }

    // Logic for finding the corresponding index of a dashed line
    getLecturePageIndex(source, index, withoutSubsection) {
      if (this.type === 'LESSON') {
        return index + 1;
      }

      if (withoutSubsection) {
        return 0;
      }

      return source.index + 1;
    }

    copyTo(...rest) {
      const [fn, target] = this.getCopyMethod(...rest);

      this.updateCopyStatus('LOADING');
      fn(CurrentCourseManager.course.catalogId, this.content.id, target)
        .then(({ result }) => {
          if (result.partial) {
            AlertMessages.error('', 'TIMELINE.COPY_PARTIAL_SUCCESS', {}, {
              contentName: this.content.title,
              courseName: this.course.name,
              courseAlias: this.course.offeringName.downcasedSingularized,
            });
          } else if (result.background) {
            AlertMessages.info('', 'TIMELINE.COPY_BACKGROUND', {}, {
              contentName: this.content.title,
              courseName: this.course.name,
              courseAlias: this.course.offeringName.downcasedSingularized,
            });
          } else {
            AlertMessages.success('', 'TIMELINE.COPY_SUCCESS', {}, {
              contentName: this.content.title,
              courseName: this.course.name,
              courseAlias: this.course.offeringName.downcasedSingularized,
            }, 5000);
          }
        })
        .catch((err) => {
          AlertMessages.error('', 'TIMELINE.COPY_FAILURE', {}, {
            contentName: this.content.title,
            courseName: this.course.name,
          });
        })
        .finally(() => {
          CurrentUserManager.setLastCopyToOffering({ lastCopyToOffering: this.course.catalogId });
          this.updateCopyStatus(null);
        });

      $uibModalInstance.dismiss();
    }

    getCopyMethod(index, parent, targetType, newCategory, lectureSections = []) {
      const nextToSectionId = lectureSections[index]?.id;
      let parentId = null;
      if (parent) {
        parentId = parent.id;
      }

      const { catalogId } = this.course;

      if (type === 'SECTION' || type === 'SUBSECTION') {
        const target = {
          destination: {
            catalogId,
            index,
            parentId,
            new: newCategory,
            releaseDate: moment().add(1, 'months').endOf('day').toISOString(),
            nextToSectionId,
          },
        };

        return [TimelineModel.copyLectureSection, target];
      }

      const target = {
        destination: {
          catalogId,
          index,
          parentId,
          new: newCategory,
          type: newCategory ? targetType : null,
          releaseDate: moment().add(1, 'months').endOf('day').toISOString(),
        },
      };

      return [TimelineModel.copyLecturePage, target];
    }
  }

  return new CopyToOfferingModalController();
}
