import React, { useState } from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';
// color sizes and fonts
import { doubleSpacing, halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import NvTooltip from 'shared/components/nv-tooltip';
import NvDropdown, { NvDropdownAlign, NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { danger, gray6, gray7 } from 'styles/global_defaults/colors';
import NvPopover from 'shared/components/nv-popover';
import NVConfirmationPopup from '../../shared/components/nv-confirmation-popover';
import { config } from '../../../config/pendo.config.json';

type SkillItemProps ={
  name: string,
  showConfirmation: boolean,
  onDelete: () => void,
  highlight: boolean,
};
const styles = css`
  &.skill-item{
    height:${doubleSpacing}px;
    border-radius: ${standardSpacing}px;
    background-color: ${gray7};

    &.inline-item{
      display:inline-flex;
      min-width:0;
      max-width:100%;
    }
    &.recently-added{
      animation: highlight 4s ease;
    }
    @keyframes highlight {
      from {background-color: ${gray6};}
      50%{
        background-color: ${gray6};
      }
      to {background-color: ${gray7};}
    }
    .skill-label{
      overflow:hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:hover{
      background-color:${gray6};
    }
    .icon{
      transform: rotate(90deg);
    }
    .icon-item{
      margin-left:${halfSpacing}px;
    }
    .delete-text{
      color:${danger};
    }

  }
`;
const SkillItemAdmin = (props: SkillItemProps) => {
  const [isDeleting, setDeleting] = useState(false);
  const dropDownItems: NvDropdownOption[] = [
    {
      type: 'text',
      text: t.INSTITUTIONS.SKILL_TAGS.DELETE(),
      callback: () => handleDelete(),
      class: 'delete-text',
      pendoTag: config.pendo.skillTags.deleteSkill,
    },
  ];
  const handleDelete = () => {
    if (props.showConfirmation) {
      setDeleting(true);
    } else {
      props.onDelete();
    }
  };

  const deleteItem = () => {
    props.onDelete();
    setDeleting(false);
  };

  const cancelDelete = () => {
    setDeleting(false);
  };
  return (
    <NvPopover
      enabled
      show={isDeleting}
      content={(
        <NVConfirmationPopup
          onCancel={cancelDelete}
          onConfirm={deleteItem}
          header={t.INSTITUTIONS.SKILL_TAGS.DELETE_TITLE()}
          message={t.INSTITUTIONS.SKILL_TAGS.DELETE_CONFIRMATION.INSTITUTION()}
          cancelBtnText={t.NOVOED.CANCEL()}
          confirmBtnText={t.NOVOED.DELETE()}
        />
)}
      placement='top'
      rootClose
      onHide={(e) => cancelDelete()}
      className='d-inline-flex mw-100'
      preventOverflow
    >
      <div css={styles} className={`skill-item inline-item align-items-center px-3 py-2 ${props.highlight ? 'recently-added' : ''}`}>
        <div className='skill-label'>
          {props.name}
        </div>
        <NvTooltip text={t.INSTITUTIONS.SKILL_TAGS.OPTIONS()} placement='top' key='options-btn'>
          <NvDropdown
            key='options-btn'
            title={t.COURSE_COMMUNICATIONS.SCHEDULED.CREATE_NEW()}
            buttonStyle={NvDropdownButtonStyle.ICON}
            items={dropDownItems}
            align={NvDropdownAlign.RIGHT}
            iconClass='icon-item icon-in-progress'
            toggleDataQa={config.pendo.skillTags.options}
          />

        </NvTooltip>
      </div>
    </NvPopover>
  );
};

export default SkillItemAdmin;
