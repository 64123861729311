export default {
  bindings: {
    onTabSelect: '&?',
    selectedTabName: '<',
  },
  transclude: true,
  controllerAs: 'tabListCtrl',
  controller: function ctrl(
    _,
  ) {
'ngInject';
    function addTab(tab) {
      const existingTab = _.findWhere(this.tabs, { internalName: tab.internalName });
      if (!existingTab) {
        this.tabs.push(tab);
      }

      if (this.selectedTabName && tab.internalName.toUpperCase() === this.selectedTabName.toUpperCase()) {
        this.selectedTab = tab;
        tab.selected = true;
      } else if (!this.selectedTabName && this.tabs.length === 1) {
        this.selectedTab = tab; // select the first tab by default
        tab.selected = true;
      }
    }

    function selectTab(selectedTab) {
      _.each(this.tabs, (tab) => {
        tab.selected = (tab.internalName.toUpperCase() === selectedTab.internalName.toUpperCase());
      });

      this.selectedTab = selectedTab;

      const tabName = this.selectedTab.internalName;

      if (this.onTabSelect) {
        this.onTabSelect({ tab: tabName });
      }
    }

    this.$onInit = function () {
      this.tabs = [];
      this.selectedTab = null;
      this.addTab = addTab;
      this.selectTab = selectTab;
    };

    this.$onChanges = function (changes) {
      if (changes.selectedTabName && !changes.selectedTabName.isFirstChange()) {
        this.selectTab(_.findWhere(this.tabs, { internalName: changes.selectedTabName.currentValue }));
      }
    };
  },
  templateUrl: 'shared/templates/nv-dashboard-filter-tab-list.html',

};
