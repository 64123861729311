import { css } from '@emotion/react';
import NvUserAvatar from 'components/nv-user-avatar';
import React, { useEffect, useRef, useState } from 'react';
import t from 'react-translate';
// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { RootState } from 'redux/schemas';
import { Team, TeamMember } from 'redux/schemas/models/team';
import { getCourseAliases } from 'redux/selectors/course';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { deleteGroupAdmin, deleteTeamAdmin, deleteTeamMember, getTeamMembers, makeGroupAdmin, makeTeamAdmin } from 'redux/actions/teams';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
// Hooks
import useDebouncedEffect from 'shared/hooks/use-debounced-effect';
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';
// Styling
import { gray6 } from 'styles/global_defaults/colors';
import { textXSFontSize } from 'styles/global_defaults/fonts';
import { halfSpacing, quarterSpacing, standardSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
// Utils
import { getDateText } from 'shared/utils';
// Components
import { Button } from 'react-bootstrap';
import NvDropdown, { NvDropdownAlign, NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { NvSearchBar } from 'shared/components/nv-search-bar';
import NvTooltip from 'shared/components/nv-tooltip';
import NvModal, { ModalType, BackdropType } from 'shared/components/nv-modal';
import NvLoadingPlaceholder, { LineSize, PlaceholderType } from 'shared/components/nv-loading-placeholder';
import LastMemberModalBody from './lastMemberModalBody';
import MoveMemberModal from './move-member-modal';
import AddMemberModal from './add-members-modal';
// Config
import { config } from '../../../config/pendo.config.json';

const INITIAL_INDEX = -1;
const PAGE_SIZE = 20;
const INITIAL_PAGE = 1;
const TeamInfoBody = () => {
  const teamId = useSelector((state: RootState) => state.app.manageTeam.currentTeamId);
  const teamInfo: Team = useSelector(((state: RootState) => state.models.teams[teamId]));
  const [shownIndex, setShownIndex] = useState(INITIAL_INDEX);
  const [filterText, setFilterText] = useState(null);
  const [showAddMembersModal, setShowAddMembersModal] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState(null);
  const [memberToMove, setMemberToMove] = useState(null);
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);
  const [isLoading, setIsloading] = useState(false);

  const aliases = useSelector((state) => getCourseAliases(state, teamInfo?.courseCatalogId));
  const wordForTeam = teamInfo?.teamSet?.name?.capitalizedSingularized;
  const numMembers = teamInfo?.numApprovedMembers;
  const dispatch = useAppDispatch();

  const makeLead = (member) => {
    let handleMakeLead;
    if (teamInfo.teamSet.isGroup) {
      handleMakeLead = () => dispatch(makeGroupAdmin({ teamId: teamInfo.id, catalogId: teamInfo.teamSet.catalogId, teamMemberId: member.id }));
    } else {
      handleMakeLead = () => dispatch(makeTeamAdmin({ teamId: teamInfo.id, catalogId: teamInfo.teamSet.catalogId, teamMemberId: member.id }));
    }
    handleMakeLead().then((response: any) => {
      if (response.error) {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.FORM.ERROR_SOMETHING_WRONG(),
        }));
      } else {
        dispatch(addAlertMessage({
          type: AlertMessageType.SUCCESS,
          header: t.FORM.SUCCESS_BANG(),
          message: t.TEAMS.MANAGE_TEAM_GROUP.MEMBER_DROPDOWN_OPTIONS.SUCCESS_MAKE_TEAM_LEAD({ name: member.user.fullName, isTeam: !teamInfo.teamSet?.isGroup, ...aliases.teamAliases, ...aliases.groupAliases }),
        }));
      }
    });
  };

  const removeLead = (member) => {
    let handleRemoveLead;
    if (teamInfo.teamSet.isGroup) {
      handleRemoveLead = () => dispatch(deleteGroupAdmin({ teamId: teamInfo.id, catalogId: teamInfo.teamSet.catalogId, teamMemberId: member.id }));
    } else {
      handleRemoveLead = () => dispatch(deleteTeamAdmin({ teamId: teamInfo.id, catalogId: teamInfo.teamSet.catalogId, teamMemberId: member.id }));
    }
    handleRemoveLead().then((response: any) => {
      if (response.error) {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.FORM.ERROR_SOMETHING_WRONG(),
        }));
      } else {
        dispatch(addAlertMessage({
          type: AlertMessageType.SUCCESS,
          header: t.FORM.SUCCESS_BANG(),
          message: t.TEAMS.MANAGE_TEAM_GROUP.MEMBER_DROPDOWN_OPTIONS.SUCCESS_REMOVING_TEAM_LEAD({ name: member.user.fullName, isTeam: !teamInfo.teamSet?.isGroup, ...aliases.teamAliases, ...aliases.groupAliases }),
        }));
      }
    });
  };

  const showMoveModal = (member) => {
    setMemberToMove(member);
  };

  const showRemoveModal = (member: TeamMember) => {
    const isLastMember = teamInfo.totalMembers === 1 && teamInfo.teamMembers[0].id === member.id;
    const assignmentWithSubmissions = teamInfo.assignments?.find(assignment => !!assignment.lastSubmittedAt);
    if (isLastMember && !assignmentWithSubmissions) {
      setMemberToRemove(member);
    } else {
      dispatch(openConfirmationDialog({
        title: t.TEAMS.MANAGE_TEAM_GROUP.MODAL.REMOVING.TITLE(member.user.fullName, teamInfo.name),
        bodyText: t.TEAMS.MANAGE_TEAM_GROUP.MODAL.REMOVING.DESCRIPTION({ isTeam: !teamInfo.teamSet.isGroup, ...aliases.teamAliases, ...aliases.groupAliases }),
        confirmText: t.TEAMS.MANAGE_TEAM_GROUP.MODAL.REMOVING.CONFIRM(),
        onConfirm: () => {
          dispatch(deleteTeamMember({ teamId: teamInfo.id, catalogId: teamInfo.teamSet.catalogId, teamMemberId: member.id }))
            .then((response: any) => {
              if (response.error) {
                dispatch(addAlertMessage({
                  type: AlertMessageType.ERROR,
                  header: t.FORM.OOPS(),
                  message: t.FORM.ERROR_SOMETHING_WRONG(),
                }));
              } else {
                dispatch(addAlertMessage({
                  type: AlertMessageType.SUCCESS,
                  header: t.FORM.SUCCESS_BANG(),
                  message: t.TEAMS.MANAGE_TEAM_GROUP.MEMBER_DROPDOWN_OPTIONS.SUCCESS_REMOVING_TEAM_MEMBER(member.user.fullName, teamInfo.name),
                }));
              }
            });
        },
        cancelDataQa: config.pendo.manageOneTeamGroup.modalContent.body.memberList.editMenu.menuOptions.deleteMemberConfirmationModal.cancelDeleteConfirmationModal,
        confirmDataQa: config.pendo.manageOneTeamGroup.modalContent.body.memberList.editMenu.menuOptions.deleteMemberConfirmationModal.confirmDeleteConfirmationModal,
      }));
    }
  };

  const getOptions = (member) => {
    const defaultItems: NvDropdownOption[] = [];
    if (member.isAdmin) {
      defaultItems.push(
        {
          type: 'text',
          text: t.TEAMS.MANAGE_TEAM_GROUP.MEMBER_DROPDOWN_OPTIONS.REMOVE_LEAD({ ...aliases.teamAliases, ...aliases.groupAliases, isTeam: !teamInfo.teamSet?.isGroup }),
          callback: () => { removeLead(member); },
          dataQa: config.pendo.manageOneTeamGroup.modalContent.body.memberList.editMenu.menuOptions.makeRemoveLeadToggle,
        },
      );
    } else {
      defaultItems.push(
        {
          type: 'text',
          text: t.TEAMS.MANAGE_TEAM_GROUP.MEMBER_DROPDOWN_OPTIONS.MAKE_LEAD({ ...aliases.teamAliases, ...aliases.groupAliases, isTeam: !teamInfo.teamSet?.isGroup }),
          callback: () => { makeLead(member); },
          dataQa: config.pendo.manageOneTeamGroup.modalContent.body.memberList.editMenu.menuOptions.makeRemoveLeadToggle,
        },
      );
    }
    defaultItems.push(
      {
        type: 'text',
        text: t.TEAMS.MANAGE_TEAM_GROUP.MEMBER_DROPDOWN_OPTIONS.MOVE_TO_DIFFERENT_TEAM({ ...aliases.teamAliases, ...aliases.groupAliases, isTeam: !teamInfo.teamSet?.isGroup }),
        callback: () => { showMoveModal(member); },
        dataQa: config.pendo.manageOneTeamGroup.modalContent.body.memberList.editMenu.menuOptions.moveMemberButton,
      },
      { type: 'divider' },
      {
        type: 'text',
        text: t.TEAMS.MANAGE_TEAM_GROUP.MEMBER_DROPDOWN_OPTIONS.REMOVE_FROM_DIFFERENT_TEAM({ ...aliases.teamAliases, ...aliases.groupAliases, isTeam: !teamInfo.teamSet?.isGroup }),
        class: 'text-danger',
        callback: () => { showRemoveModal(member); },
        dataQa: config.pendo.manageOneTeamGroup.modalContent.body.memberList.editMenu.menuOptions.deleteMemberButton,
      },
    );

    return defaultItems;
  };

  const scrollingElement: HTMLElement = document.getElementById('modal-content-wrapper');
  const isPageBottom = useInfiniteScroll(scrollingElement, 40);
  const hasMore = teamInfo?.numApprovedMembers > teamInfo?.teamMembers?.length;

  useEffect(() => {
    if (isPageBottom && hasMore) {
      setIsloading(true);
      setCurrentPage(currentPage + 1);
    }
  }, [isPageBottom]);

  useEffect(() => {
    if (currentPage !== INITIAL_PAGE) {
      dispatch(getTeamMembers({
        catalogId: teamInfo.teamSet.catalogId,
        teamId: teamInfo.id,
        text: filterText,
        pageSize: PAGE_SIZE,
        pageNumber: currentPage,
      })).then(() => setIsloading(false));
    }
  }, [currentPage]);

  const toggleDropdown = (index: number) => {
    if (index === shownIndex) {
      setShownIndex(INITIAL_INDEX);
    } else {
      setShownIndex(index);
    }
  };

  useDebouncedEffect(() => {
    if (filterText !== null && teamInfo?.teamSet?.catalogId) {
      dispatch(getTeamMembers({
        catalogId: teamInfo.teamSet.catalogId,
        teamId: teamInfo.id,
        text: filterText,
        pageSize: PAGE_SIZE,
        pageNumber: INITIAL_PAGE,
      }));
      setCurrentPage(INITIAL_PAGE);
    }
  }, 500, [filterText]);

  const style = css`
    padding: 0px ${5 * standardSpacing}px;
    .search-section {
      padding: ${halfSpacing}px 0px;
      .search-bar {
        border: 1px solid ${gray6};
        padding: 7px 13px;
        input {
          width: 100%;
        }
      }
    }
    .members-table {
      padding-top: ${standardSpacing}px;
      .main-hr {
        padding-bottom: ${quarterSpacing / 2}px;
      }
      .user-name-cell {
        width: 40%;
      }
      .email-cell {
        width: 35%;
      }
      .last-active-cell {
        width: 18%;
        .content {
          max-width: 120px;
        }
      }
      .edit-cell {
        width: 7%;
        text-align: right;
        text-align: -webkit-right;
        .content {
          max-width: 60px;
        }
      }
      .table-headers {
        display: flex;
      }
      .member-info {
        display: flex;
        align-items: center;
        .role-badge {
          width: 100%;
          max-width: 80px;
          height: fit-content;
          text-align: center;
          font-weight: bold;
          font-size: ${textXSFontSize}px;
          border-radius: 10px;
          padding: 4px ${quarterSpacing}px;
          display: inline-block;
          -webkit-font-smoothing: antialiased;
        }
        .user-name-cell {
          display: flex;
          align-items: center;
          padding-right: ${threeQuartersSpacing}px;
          .user-name {
            padding: 0px ${halfSpacing}px;
          }
        }
        .email-cell {
          padding-right: ${threeQuartersSpacing}px;}
        .bs4-dropdown {
          float: right;
          .icon-edit {
            padding: ${halfSpacing}px;
          }
        }
      }
    }
  `;
  return (
    <React.Fragment>
      <div className='team-members-section' css={style}>
        <div className='subheader'>
          <div className='count color-positive'>{numMembers}</div>
          <div className='course-subtitle inline-block'>
            {t.TEAMS.PROFILE.TEAM_MEMBERS.TITLE(numMembers, wordForTeam)}
          </div>
        </div>
        <div className='search-section row'>
          <div className='col-xs-6'>
            <div
              className='search-bar'
              data-qa={config.pendo.manageOneTeamGroup.modalContent.body.memberSearchBar}
            >
              <NvSearchBar
                maxLength={255}
                autoFocus
                className='text-regular'
                onSearch={() => {}}
                onChange={(e) => {
                  setFilterText(e.target.value);
                }}
                value={filterText}
                placeholder={t.TEAMS.MANAGE_TEAM_GROUP.SEARCH_MEMBER()}
              />
            </div>
          </div>
          <div className='add-member col-xs-6 text-right'>
            <Button
              variant='secondary'
              onClick={() => { setShowAddMembersModal(true); }}
              data-qa={config.pendo.manageOneTeamGroup.modalContent.body.addMemberButton}
            >
              {t.TEAMS.MANAGE_TEAM_GROUP.ADD_MEMBERS()}
            </Button>
          </div>
        </div>
        <div className='members-table'>
          <div className='table-headers'>
            <div className='user-name-cell text-small gray-2'>
              {t.TEAMS.MANAGE_TEAM_GROUP.MEMBERS_TABLE.NAME()}
            </div>
            <div className='email-cell text-small gray-2'>
              {t.TEAMS.MANAGE_TEAM_GROUP.MEMBERS_TABLE.EMAIL()}
            </div>
            <div className='last-active-cell text-small gray-2'>
              <div className='content'>
                {t.TEAMS.MANAGE_TEAM_GROUP.MEMBERS_TABLE.LAST_WORKSPACE_VISIT()}
              </div>
            </div>
            <div className='edit-cell text-small gray-2'>
              <div className='content'>
                {t.TEAMS.MANAGE_TEAM_GROUP.MEMBERS_TABLE.ACTIONS()}
              </div>
            </div>
          </div>
          <hr className='main-hr' />
          <div className='members-list table'>
            {
              teamInfo?.teamMembers?.map((member, index) => (
                <React.Fragment key={member.id}>
                  <div className='member-info' data-qa={config.pendo.manageOneTeamGroup.modalContent.body.memberList.memberInfo} data-qa-user-id={member.id}>
                    <div className='user-name-cell text-regular'>
                      <div className='user-avatar-container'>
                        <NvUserAvatar
                          directToProfile
                          size='md'
                          user={member.user}
                          borderType='round'
                          dataQa={config.pendo.manageOneTeamGroup.modalContent.body.memberList.profileMember}
                        />
                      </div>
                      <div className='user-name font-weight-bolder w-100'>
                        {member.user.fullName}
                      </div>
                      {
                        member.isAdmin && (
                          <div className='role-badge bg-color-positive'>
                            {
                              t.TEAMS.MANAGE_TEAM_GROUP.TEAM_LEAD({ ...aliases.teamAliases, ...aliases.groupAliases, isTeam: !teamInfo.teamSet?.isGroup })
                            }
                          </div>
                        )
                      }
                    </div>
                    <div className='email-cell text-regular'>
                      {member.user.email}
                    </div>
                    <div className='last-active-cell text-regular'>
                      <div className='content'>
                        {getDateText(member.visitedWorkspaceAt)}
                      </div>
                    </div>
                    <div className='edit-cell text-regular'>
                      <div className='content'>
                        <NvDropdown
                          show={shownIndex === index}
                          onToggle={() => { toggleDropdown(index); }}
                          buttonStyle={NvDropdownButtonStyle.CUSTOM}
                          items={getOptions(member)}
                          menuClassName='pt-0'
                          align={NvDropdownAlign.RIGHT}
                          useMaxHeightModifier
                          customTarget={() => (
                            <NvTooltip text={t.LECTURE_PAGES.COMPONENTS.EDIT_OPTIONS()}>
                              <div className='icon icon-xss-smallest icon-edit text-primary' />
                            </NvTooltip>
                          )}
                          maxSizeInterceptor={(maxSize) => {
                            const footerVisible = document.querySelector('.lecture-page-footer');
                            return maxSize - (footerVisible ? 60 : 0);
                          }}
                          toggleDataQa={config.pendo.manageOneTeamGroup.modalContent.body.memberList.editMenu.toggleButton}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                </React.Fragment>
              ))
            }
            {
              isLoading && (
                <React.Fragment>
                  <div className='member-info'>
                    <div className='user-name-cell text-regular'>
                      <NvLoadingPlaceholder type={PlaceholderType.LINE} size={LineSize.FULL} />
                    </div>
                    <div className='email-cell text-regular'>
                      <NvLoadingPlaceholder type={PlaceholderType.LINE} size={LineSize.FULL} />
                    </div>
                    <div className='last-active-cell text-regular'>
                      <div className='content'>
                        <NvLoadingPlaceholder type={PlaceholderType.LINE} size={LineSize.FULL} />
                      </div>
                    </div>
                    <div className='edit-cell text-regular'>
                      <div className='content'>
                        <NvLoadingPlaceholder type={PlaceholderType.LINE} size={LineSize.FULL} />
                      </div>
                    </div>
                  </div>
                  <hr />
                </React.Fragment>
              )
            }
            {
              !hasMore && (
                <div className='d-flex align-items-center gray-2 pt-2 text-small'>{t.SEARCH.ALL_LOADED()}</div>
              )
            }
          </div>
        </div>
      </div>
      {/* Modal for adding people to the current team */}
      <NvModal
        doubleModal
        backdropType={BackdropType.DARK}
        fullHeight={false}
        type={ModalType.DYNAMIC}
        width={720}
        height={500}
        header={t.TEAMS.MANAGE_TEAM_GROUP.MODAL.ADDING.TITLE()}
        body={<AddMemberModal teamInfo={teamInfo} closeModal={() => setShowAddMembersModal(false)} />}
        show={showAddMembersModal}
        onClose={() => setShowAddMembersModal(false)}
        closeDataQa={config.pendo.manageOneTeamGroup.modalContent.close}
      />
      {/* Modal for moving a member to another team */}
      <NvModal
        doubleModal
        backdropType={BackdropType.DARK}
        fullHeight={false}
        type={ModalType.DYNAMIC}
        width={720}
        height={500}
        header={t.TEAMS.MANAGE_TEAM_GROUP.MODAL.MOVING.TITLE({ ...aliases.teamAliases, ...aliases.groupAliases, isTeam: !teamInfo?.teamSet?.isGroup })}
        body={<MoveMemberModal member={memberToMove} teamInfo={teamInfo} closeModal={() => setMemberToMove(null)} isLastMember={numMembers === 1} />}
        show={!!memberToMove && !!teamInfo}
        onClose={() => setMemberToMove(null)}
        closeDataQa={config.pendo.manageOneTeamGroup.modalContent.close}
      />
      {/* Modal when deleting the last member */}
      <NvModal
        doubleModal
        backdropType={BackdropType.DARK}
        type={ModalType.NO_DIALOG}
        body={(
          <LastMemberModalBody
            memberToRemove={memberToRemove}
            teamInfo={teamInfo}
            cleanMemberToRemove={() => { setMemberToRemove(null); }}
          />
        )}
        show={!!memberToRemove}
        onClose={() => setMemberToRemove(null)}
        closeDataQa={config.pendo.manageOneTeamGroup.modalContent.close}
      />
    </React.Fragment>
  );
};

export default TeamInfoBody;
