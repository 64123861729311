import React, { useContext } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import t from 'react-translate';

import { AngularServicesContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { CompletionCriteriaType } from 'redux/schemas/app/timeline';
import { selectPortableCompletionPanelTab } from 'redux/actions/timeline';
import { getCourseAliases } from 'redux/selectors/course';

import ClickableContainer from 'components/clickable-container';
import { RootState } from 'redux/schemas';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import NvProgressGauge, { ProgressGaugeSize, ProgressGaugeType } from 'shared/components/nv-progress-gauge';
import { success, warning, primary } from 'styles/global_defaults/colors';
import CriteriaProgressBar, { CriteriaProgressBarContext } from '../criteria-progress-bar';
import useCompletionCriteria from '../use-completion-criteria';

type PanelGaugeConfig = {
  type: CompletionCriteriaType,
  required: number,
  completed: number,
  icon: string,
  color: string,
  colorClass: string,
  message: string,
};

const PanelGauges = () => {
  const dispatch = useAppDispatch();
  const aliases = useSelector((state) => getCourseAliases(state));

  const { $state } = useContext(AngularServicesContext);
  const isLecturePage = $state.current.name === 'lecture-page';

  const portableCompletionPanelSelectedTab: CompletionCriteriaType = useSelector((state: RootState) => state.app.timeline.portableCompletionPanelSelectedTab);
  const { loadingTimelineTodos } = useSelector((state: RootState) => state.app.timeline.todos);
  const {
    requiredPoints,
    requiredAssignmentsCount,
    totalRequiredTodosCount,

    pointsReceived,
    todoAssignmentsCompleted,
    requiredTodosCompleted,

    enabledFactorCount,
  } = useCompletionCriteria();

  const displayRadialGraph = enabledFactorCount > 1;

  const selectTab = (selected: CompletionCriteriaType) => {
    dispatch(
      selectPortableCompletionPanelTab(
        portableCompletionPanelSelectedTab === selected
          ? null
          : selected,
      ),
    );
  };

  const gauges: { [key: string]: PanelGaugeConfig } = {
    points: {
      type: CompletionCriteriaType.POINTS,
      required: requiredPoints,
      completed: pointsReceived,
      icon: 'highlight',
      color: success,
      colorClass: 'text-success',
      message: t.COMPLETION_CRITERIA.EARN_POINTS({
        requiredPoints,
        pointsAlias: aliases.pointsAliases.PointsAlias,
      }),
    },
    assignments: {
      type: CompletionCriteriaType.ASSIGNMENTS,
      required: requiredAssignmentsCount,
      completed: todoAssignmentsCompleted,
      icon: 'assignments',
      color: warning,
      colorClass: 'text-warning',
      message: t.COMPLETION_CRITERIA.COMPLETE_ASSIGNMENTS({
        requiredAssignmentsCount,
        assignmentAliasSingularized: aliases.assignmentAliases.AssignmentAlias,
        assignmentAliasPluralized: aliases.assignmentAliases.AssignmentsAlias,
      }),
    },
    todo: {
      type: CompletionCriteriaType.TODOS,
      required: totalRequiredTodosCount,
      completed: requiredTodosCompleted,
      icon: 'success',
      color: primary,
      colorClass: 'text-primary',
      message: t.COMPLETION_CRITERIA.COMPLETE_REQUIRED_TODOS(totalRequiredTodosCount),
    },
  };

  const styles = css`
    ${displayRadialGraph && css`
      justify-content: space-evenly;

      // To make this similar to the angular js version
      &:before, &:after {
        dispay: table;
        content: '';
      }

      ${enabledFactorCount === 3 && css`
        & > div {
          flex: 1;
          justify-content: center;
        }
      `}
    `}

    .panel-progress-bar {
      .panel-progress-message {
        width: 80%;
      }
    }

    .panel-progress-radial.dim {
      opacity: 0.5;
    }

    .panel-progress-radial, .panel-progress-bar {
      .arrow-up {
        transform: rotate(180deg);
      }
    }

    .panel-progress-radial > .label {
      text-align: center;
    }

    .center-gauge-content {
      background-color: #f9fefc !important;
    }
  `;

  return (
    <div css={styles} className={`d-flex ${displayRadialGraph && isLecturePage ? 'mt-5' : ''}`}>
      {['points', 'assignments', 'todo'].map((criteria) => (
        <React.Fragment key={`panel-gauge-${criteria}`}>
          {gauges[criteria].required > 0 && (
            <NvTooltip
              text={
                portableCompletionPanelSelectedTab === gauges[criteria].type
                  ? t.COMPLETION_CRITERIA.CLICK_TO_CLOSE()
                  : t.COMPLETION_CRITERIA.CLICK_FOR_DETAILS()
              }
            >
              <ClickableContainer
                // Preventing to switch between All Todos and Required Todos views
                // during the todos are loading
                disabled={loadingTimelineTodos && gauges[criteria].type === CompletionCriteriaType.TODOS}
                onClick={() => selectTab(gauges[criteria].type)}
                className={`${!displayRadialGraph ? 'w-100' : ''}`}
              >
                {displayRadialGraph && (
                  <div
                    className={`${!!portableCompletionPanelSelectedTab && portableCompletionPanelSelectedTab !== gauges[criteria].type ? 'dim' : ''} panel-progress-radial d-flex align-items-center flex-column px-2`}
                  >
                    <NvProgressGauge
                      maxValue={gauges[criteria].required}
                      currentValue={gauges[criteria].completed}
                      size={ProgressGaugeSize.LARGE}
                      displayType={ProgressGaugeType.ACTUAL_WITH_ICON}
                      icon={gauges[criteria].icon}
                      activeColor={gauges[criteria].color}
                      selected={portableCompletionPanelSelectedTab === gauges[criteria].type}
                    />
                    <span className='label text-gray-1 mt-2'>{gauges[criteria].message}</span>
                    <NvIcon
                      icon='dropdown-arrow'
                      size='xs-smallest'
                      className={`mt-1 ${portableCompletionPanelSelectedTab === gauges[criteria].type ? `${gauges[criteria].colorClass} arrow-up` : 'text-gray-4'}`}
                    />
                  </div>
                )}
                {!displayRadialGraph && (
                  <div className='panel-progress-bar w-100 d-flex justify-content-center align-items-center flex-column'>
                    <CriteriaProgressBar
                      maxValue={gauges[criteria].required}
                      currentValue={gauges[criteria].completed}
                      activeColor={gauges[criteria].color}
                      type={gauges[criteria].type}
                      context={CriteriaProgressBarContext.PANEL}
                    />
                    <div className='panel-progress-message d-flex align-items-center mt-1'>
                      <span className='label text-gray-1 mr-1'>{gauges[criteria].message}</span>
                      <NvIcon
                        icon='dropdown-arrow'
                        size='xs-smallest'
                        className={portableCompletionPanelSelectedTab === gauges[criteria].type ? `${gauges[criteria].colorClass} arrow-up` : 'text-gray-4'}
                      />
                    </div>
                  </div>
                )}
              </ClickableContainer>
            </NvTooltip>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default PanelGauges;
