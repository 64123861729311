import { SyncingStatus } from 'redux/schemas/models/lecture-page';

/* @ngInject */
export default function NvTimelineWrapperLecturePage(
  CurrentCourseManager,
  TimelinesManager,
) {
  return {
    scope: {
      wrapperLecturePage: '=',
      decayEnabled: '=',
      gamificationEnabled: '=',
      collapseTimelineFunction: '&?',
      showCardViewOnlyForCurrent: '=',
      customState: '=?',
      copyToStatus: '<?',
      showAddToCollectionModal: '&',
      hasCollectionsToConvert: '=?',
    },
    controller: function ctrl($scope, $element, $attrs) {
'ngInject';
      const vm = this;
      vm.CurrentCourseManager = CurrentCourseManager;
      vm.TimelinesManager = TimelinesManager;
      vm.showProgress = true;

      vm.onDrop = (srcList, srcIndex, targetList, targetIndex) => {
        if (srcList !== targetList || srcIndex !== targetIndex) {
          targetList.splice(targetIndex, 0, srcList[srcIndex]);
          if (srcList === targetList && targetIndex <= srcIndex) {
            srcIndex += 1;
          }
          srcList.splice(srcIndex, 1);

          TimelinesManager.currentTimeline.reorderDirty = true;
        }

        return true;
      };

      vm.isLinkingItem = (item) => item.isLinked && [SyncingStatus.SYNC_IN_PROGRESS, SyncingStatus.LINK_IN_PROGRESS].includes(item.syncingStatus);
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'timelines/templates/nv-timeline-wrapper-lecture-page.html',
  };
}
