/* @ngInject */
export default function StateManagerService(
  _,
  $state,
  $window,
  $uibModal,
  $rootScope,
  $translate,
  ConfirmationOverlays,
) {
  const StateManager = {
    lastStateAttempted: null,
    paramsForLastStateAttempted: null,

    lastStateEntered: null,
    previousStatesEntered: [],
    previousParamsEntered: [],
    paramsForLastStateEntered: null,
    catalogId: null,
    lastNonStickyState: null,
    unsavedChangesCheckBeforeLeavingListeners: [],
    reactListeners: {},
  };

  StateManager.pushEnteredState = (state, params) => {
    if (StateManager.lastStateEntered) {
      StateManager.previousStatesEntered.push(StateManager.lastStateEntered);
      StateManager.previousParamsEntered.push(StateManager.paramsForLastStateEntered);
    }

    StateManager.lastStateEntered = state;
    StateManager.paramsForLastStateEntered = params;
    StateManager.catalogId = params.catalogId;

    StateManager.notifyReactListeners();
  };

  StateManager.registerReactListener = (id, listener) => {
    StateManager.reactListeners[id] = listener;

    return () => delete StateManager.reactListeners[id];
  };

  StateManager.notifyReactListeners = () => {
    Object.values(StateManager.reactListeners).forEach((listener) => listener());
  };

  StateManager.registerStateChangeStart = (
    checkUnsavedChanges,
    templateUrl,
    windowUnloadConfirmationMessage,
    modalInstanceCtrl,
    confirmationModalData,
    onConfirmNavigation,
    beforeWarningCallback,
  ) => {
    const entry = {
      checkUnsavedChanges,
      templateUrl,
      windowUnloadConfirmationMessage,
      modalInstanceCtrl,
      confirmationModalData,
      onConfirmNavigation,
      beforeWarningCallback,
      state: $state.current,
    };

    StateManager.unsavedChangesCheckBeforeLeavingListeners.push(entry);

    return () => {
      const indexOfEntry = StateManager.unsavedChangesCheckBeforeLeavingListeners.findIndex((currentEntry) => currentEntry === entry);

      if (indexOfEntry !== -1) {
        StateManager.unsavedChangesCheckBeforeLeavingListeners.splice(indexOfEntry, 1);
      }
    };
  };

  StateManager.initUnsavedChangesCheckBeforeLeaving = () => {
    /**
     * Sticky States allows two or more trees of states to run concurrently
     * along side each other, so our 3.5 and 4 pages levels are sticky states
     * and those render over an existing state without removing the view of
     * the state we were before navigating to it.
     */
    const stickyStatesLevels = [3.5, 4];

    function getIsStickyState(state) {
      return stickyStatesLevels.includes(state.data?.level);
    }

    /**
     * Determines whether we are navigating to a sticky state that will render
     * over the current state.
     */
    function getIsStickyStateInitializing(fromState, toState) {
      const isNavigatingToStickyState = getIsStickyState(toState)
      // This does the trick to determine whether we are "initializing" a sticky
      // state. Only if we come from a lower level page.
        && fromState.data?.level < toState.data?.level;

      return isNavigatingToStickyState;
    }

    /**
     * Returns an array of the sticky states that are being closed
     */
    function getClosingStickyStates(fromState, toState) {
      const isToStateSticky = getIsStickyState(toState);
      const isFromStateSticky = getIsStickyState(fromState);

      if (isFromStateSticky) {
        const indexOfToStickyStateLevel = stickyStatesLevels.findIndex((stickyState) => stickyState === toState.data?.level);
        const indexOfFromStickyStateLevel = stickyStatesLevels.findIndex((stickyState) => stickyState === fromState.data?.level);

        let startingIndex = 0;

        if (isToStateSticky) {
          startingIndex = indexOfToStickyStateLevel + 1;
        }

        return stickyStatesLevels.slice(startingIndex, indexOfFromStickyStateLevel + 1);
      }

      return [];
    }

    function getIsSameState(fromState, fromParams, toState, toParams) {
      return fromState.name === toState.name && _.isMatch(fromParams, toParams);
    }

    function getFirstEntryWithUnsavedChanges(listeners) {
      let firstEntryWithUnsavedChanges;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < listeners.length; i++) {
        const currentEntry = listeners[i];
        if (currentEntry.checkUnsavedChanges()) {
          firstEntryWithUnsavedChanges = currentEntry;
          break;
        }
      }

      return firstEntryWithUnsavedChanges;
    }

    function getAffectedEntriesWhileTransitioningState(fromState, fromParams, toState, toParams) {
      const isToStateSticky = getIsStickyState(toState);
      const isFromStateSticky = getIsStickyState(fromState);
      const closingStickyStates = getClosingStickyStates(fromState, toState);
      const isStickyStateInitializing = getIsStickyStateInitializing(fromState, toState);

      const isNonStickyNavigation = !isFromStateSticky && !isToStateSticky;

      const affectedListeners = StateManager.unsavedChangesCheckBeforeLeavingListeners.filter(
        (currentEntry) => {
          switch (true) {
            case isNonStickyNavigation:
              return true;
            case isStickyStateInitializing:
              return false;
            case !closingStickyStates.length:
              return stickyStatesLevels.includes(currentEntry.state.data?.level);
            case !!closingStickyStates.length: {
              const closedStickyStatesContainsCurrentEntry = closingStickyStates.includes(currentEntry.state.data?.level);
              let isSimplyClosingStickyState = false;

              if (StateManager.lastNonStickyState) {
                const { state, params } = StateManager.lastNonStickyState;
                isSimplyClosingStickyState = getIsSameState(state, params, toState, toParams);
              }

              if (isToStateSticky || isSimplyClosingStickyState) {
                return closedStickyStatesContainsCurrentEntry;
              }

              return closedStickyStatesContainsCurrentEntry || (currentEntry.state.data?.level < stickyStatesLevels[0]);
            }
            default: return false;
          }
        },
      );

      return affectedListeners;
    }

    $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
      if (toState.data?.level < stickyStatesLevels[0]) {
        StateManager.lastNonStickyState = {
          state: toState,
          params: toParams,
        };
      }
    });

    $rootScope.$on('$stateChangeStartWithPromiseReplacement', (event, toState, toParams, fromState, fromParams, setResolvePromise) => {
      if (!toParams.forceLoad) {
        const affectedListeners = getAffectedEntriesWhileTransitioningState(fromState, fromParams, toState, toParams);

        const firstEntryWithUnsavedChanges = getFirstEntryWithUnsavedChanges(affectedListeners);

        if (firstEntryWithUnsavedChanges) {
          const {
            templateUrl,
            modalInstanceCtrl,
            confirmationModalData,
            beforeWarningCallback,
          } = firstEntryWithUnsavedChanges;

          beforeWarningCallback?.();

          const modalInstance = ConfirmationOverlays.openConfirmationModal(templateUrl, modalInstanceCtrl, confirmationModalData);

          setResolvePromise(modalInstance.result.then((resolved) => {
            affectedListeners.forEach((currentEntry) => {
              currentEntry.onConfirmNavigation?.();
            });

            return resolved;
          }));
        }
      }
    });

    function beforeUnloadHandler(e) {
      const firstEntryWithUnsavedChanges = getFirstEntryWithUnsavedChanges(StateManager.unsavedChangesCheckBeforeLeavingListeners);

      if (firstEntryWithUnsavedChanges) {
        const { windowUnloadConfirmationMessage } = firstEntryWithUnsavedChanges;

        (e || window.event).returnValue = windowUnloadConfirmationMessage; // Gecko + IE
        return $translate.instant(windowUnloadConfirmationMessage); // Webkit, Safari, Chrome etc.
      }

      // Prevent a confirm dialog form appearing
      return undefined;
    }

    angular.element($window).on('beforeunload', beforeUnloadHandler);
  };

  return StateManager;
}
