import { getLoginProviders, doSignIn, doValidateDomain } from 'redux/actions/sign-in';
import { createReducer } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { userLoginSchema } from 'redux/schemas/api/sign-in';
import { initialRootState } from '.';

export default createReducer(initialRootState, builder => {
  builder
    .addCase(getLoginProviders.pending, (state) => {
      state.app.loginProviders.isLoading = true;
    })
    .addCase(getLoginProviders.fulfilled, (state, action) => {
      state.app.loginProviders.isLoading = false;
      state.app.loginProviders.hasBeenLoaded = true;
      state.app.loginProviders.withPassword = action.payload?.withPassword;
    })
    .addCase(doSignIn.fulfilled, (state, action) => {
      const { payload }: any = action;
      if (payload?.user) {
        const currentUserId = payload.user.id;
        const user = normalize(action.payload, userLoginSchema);
        state.app.currentUserId = currentUserId;
        Object.assign(state.models.users, (state.models.users || {}), { ...user.entities.user });
      }
    })
    .addCase(doValidateDomain.fulfilled, (state) => {
      state.app.redirecting = true;
    });
});
