import { useEffect, useState } from 'react';
import { getAnalyticsToken } from 'redux/actions/analytics';
import { AnalyticsDashBoardType } from 'redux/schemas/app/analytics';
import { useAppDispatch } from 'redux/store';

const useLoadToken = (props) => {
  const { type, catalogId, institutionId, pageIdKey} = props;
  const [tokenData, setTokenData] = useState(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (type === AnalyticsDashBoardType.LEARNING_JOURNEY) {
      dispatch(getAnalyticsToken({ journeyCatalogId: catalogId, institutionId, type })).then((data) => setTokenData(data.payload));
    }
    if (type === AnalyticsDashBoardType.INSTITUTION) {
      dispatch(getAnalyticsToken({ institutionId, type })).then((data) => setTokenData(data.payload));
    }
    if (type === AnalyticsDashBoardType.COURSE || type === AnalyticsDashBoardType.COHORTS) {
      dispatch(getAnalyticsToken({ institutionId, catalogId, type })).then((data) => setTokenData(data.payload));
    }
    if (type === AnalyticsDashBoardType.LICENSE) {
      dispatch(getAnalyticsToken({ institutionId, type, pageIdKey })).then((data) => setTokenData(data.payload));
    }
  }, []);
  return tokenData;
};
export default useLoadToken;
