import React, { useCallback, useEffect, useState } from 'react';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import useWindowResize from 'shared/hooks/use-window-resize';
import { first, isEmpty } from 'underscore';
import { css } from '@emotion/react';

import { getCurrentUser } from 'redux/selectors/users';
import { CommunityCompletionUser } from 'redux/schemas/models/user';
import { CommunityCompletionRowType } from 'redux/schemas/app/lecture-page';

import {
  isHandheld, isSmallDesktop, isNotDesktop,
} from 'styles/global_defaults/media-queries';

import ClickableContainer from 'components/clickable-container';
import NvUserAvatar from 'components/nv-user-avatar';
import { popoverZIndex, tripleSpacing } from 'styles/global_defaults/scaffolding';
import NvPopover from '../nv-popover';
import NvModal, { ModalType } from '../nv-modal';
import SendMessageModal from './send-message-modal';
import CompletionUserPopover from './completion-user-popover';

enum UsersLimit {
  HANDHELD = 2,
  TABLET_MIN = 5,
  TABLET_MAX = 6,
  SMALL_DESKTOP_MIN = 7,
  SMALL_DESKTOP_MAX = 8,
  DESKTOP = 10,
}

type NvCommunityCompletionRowProps = {
  activityType: CommunityCompletionRowType;
  users: CommunityCompletionUser[];
  numUsersWhoCompleted: number;
};
const styles = css`
  & {
    margin-bottom: ${tripleSpacing}px;
  };
`;

const popoverStyles = css`
  z-index: ${popoverZIndex};
`;

export const NvCommunityCompletionRow = (props: NvCommunityCompletionRowProps) => {
  const { activityType, users, numUsersWhoCompleted } = props;

  const [usersShowLimit, setUsersShowLimit] = useState<number>(0);
  const [showPopoverUserId, setShowPopoverUserId] = useState<number>(null);
  const [sendMessageUser, setSendMessageUser] = useState<CommunityCompletionUser>(null);

  const currentUser = useSelector(getCurrentUser);

  const setCurrentScreenUsersShowLimit = useCallback(() => {
    if (isHandheld()) {
      setUsersShowLimit(UsersLimit.HANDHELD);
    } else if (isNotDesktop()) {
      setUsersShowLimit(numUsersWhoCompleted === UsersLimit.TABLET_MAX
        ? UsersLimit.TABLET_MAX : UsersLimit.TABLET_MIN);
    } else if (isSmallDesktop()) {
      setUsersShowLimit(numUsersWhoCompleted === UsersLimit.SMALL_DESKTOP_MAX
        ? UsersLimit.SMALL_DESKTOP_MAX : UsersLimit.SMALL_DESKTOP_MIN);
    } else {
      setUsersShowLimit(UsersLimit.DESKTOP);
    }
  }, [numUsersWhoCompleted]);

  useEffect(() => {
    setCurrentScreenUsersShowLimit();
  }, [setCurrentScreenUsersShowLimit]);

  useWindowResize(() => {
    setCurrentScreenUsersShowLimit();
  }, 10);

  const getLabelFromActivityType = () => {
    if (activityType === CommunityCompletionRowType.LECTURE) {
      return t.SELF_PACED.VISITED_RECENTLY();
    } if (activityType === CommunityCompletionRowType.LECTURE_VIDEO) {
      return t.SELF_PACED.WATCHED_RECENTLY();
    } if (activityType === CommunityCompletionRowType.QUIZ
      || activityType === CommunityCompletionRowType.SURVEY) {
      return t.SELF_PACED.COMPLETED_RECENTLY();
    } if (activityType === CommunityCompletionRowType.SUBMISSION) {
      return t.SELF_PACED.COMPLETED_RECENTLY();
    } if (activityType === CommunityCompletionRowType.EVALUATION) {
      return t.SELF_PACED.COMPLETED_RECENTLY();
    }

    return '';
  };

  return (
    <React.Fragment>
      <div
        className='border-top border-bottom border-gray-5 mt-5 py-2'
        css={styles}
      >
        <div className='text-small text-gray-2 text-center mb-4'>
          {getLabelFromActivityType()}:
        </div>
        <div className='d-flex justify-content-center align-items-center mb-2'>
          {first(users, usersShowLimit).map((user) => (
            <NvPopover
              key={user.id}
              placement='top'
              show={showPopoverUserId === user.id}
              rootClose
              onHide={() => setShowPopoverUserId(null)}
              content={(
                <CompletionUserPopover
                  user={user}
                  canSendMessage={user.id !== currentUser?.id}
                  isSubmission={activityType === CommunityCompletionRowType.SUBMISSION}
                  openSendMessageModal={(recipientUser) => {
                    setShowPopoverUserId(null);
                    setSendMessageUser(recipientUser);
                  }}
                />
              )}
              overlayStyles={popoverStyles}
            >
              <ClickableContainer
                onClick={() => {
                  /**
                   * Sometimes the new popover can't able to open when there is
                   * another popover already opened due to the popover onHide callback.
                   * So used setTimeout to avoid this problem.
                   */
                  setTimeout(() => setShowPopoverUserId(user.id));
                }}
              >
                <NvUserAvatar
                  className='ml-4'
                  tooltipEnabled={false}
                  directToProfile={false}
                  displayRoleBadge={false}
                  user={user}
                  size='sm'
                  borderType='round'
                />
              </ClickableContainer>
            </NvPopover>
          ))}
          {numUsersWhoCompleted > usersShowLimit && (
            <span className='text-gray-3 text-center ml-4'>
              +{numUsersWhoCompleted - usersShowLimit}
            </span>
          )}
        </div>
      </div>

      <NvModal
        type={ModalType.DYNAMIC}
        header={t.PROFILE.SEND_A_MESSAGE()}
        show={!isEmpty(sendMessageUser)}
        onClose={() => setSendMessageUser(null)}
        fullHeight={false}
        body={(
          <SendMessageModal
            recipient={sendMessageUser}
            closeModal={() => setSendMessageUser(null)}
          />
        )}
      />
    </React.Fragment>
  );
};

export default NvCommunityCompletionRow;
