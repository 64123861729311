import { TextAlign } from 'shared/components/nv-tooltip';
import t from '../../../react-translate';

export default {
  bindings: {
    lectureComponent: '<',
    editMode: '<',
    restrictedEditMode: '<',
    reorderMode: '<',
    linkedEditMode: '<',
    index: '<',
    context: '<',
  },
  controller: function ctrl(
    $controller,
    $uibModal,
    ConfirmationOverlays,
    CountdownTimerModel,
    CurrentCourseManager,
    CurrentPermissionsManager,
    LectureComponentsHelper,
    RailsRoutes,
    config,
    $scope,
    $timeout,
    $state,
  ) {
'ngInject';
    angular.extend(this, $controller('LectureComponentBaseCtrl'));

    const vm = this;

    vm.config = config;
    vm.isContentManagementCollection = CurrentCourseManager?.course?.isContentManagementCollection ?? false;
    const { contentManagementCollection, contentManagementLecturePage } = vm.lectureComponent.lecturePage;

    vm.launchTimedQuizModal = (adminPreview = false) => {
      vm.lectureComponent.quiz.windowId = vm.lectureComponent.quiz.windowId || null;
      vm.lectureComponent.quiz.actionState = 'activate-window';

      const modal = $uibModal.open({
        templateUrl: 'quizzes/templates/modal-timed-quiz-main.html',
        controller: 'TimedQuizCtrl as vm',
        windowClass: 'full-screen-modal-handheld full-screen-modal-tablet quiz-new timed-quiz admin-preview',
        resolve: {
          questionSetId: vm.lectureComponent.quiz.id,
          quiz: vm.lectureComponent.quiz,
          adminPreview,
          lectureComponent: vm.lectureComponent,
        },
      });
    };

    vm.confirmStartOfExam = () => {
      const { quiz } = vm.lectureComponent;

      if (quiz.hardDeadline) {
        if (CurrentCourseManager.course.isSelfPaced && !quiz.startedExamAt) {
          quiz.calculatedTimeRemaining = quiz.timeRemaining;
        } else {
          quiz.calculatedTimeRemaining = quiz.calculateTimeRemaining(quiz.startedExamAt, quiz.timeLimit, quiz.timeInterval, quiz.hardDeadline && quiz.expirationDate);
        }
        if (quiz.duration && quiz.calculatedTimeRemaining && quiz.calculatedTimeRemaining < quiz.duration && quiz.timeInterval) {
          quiz.countdownTimer = new CountdownTimerModel({
            duration: quiz.duration,
            timeRemaining: quiz.calculatedTimeRemaining,
            timeInterval: quiz.timeInterval,
          });
        }
      }

      const timerHours = vm.lectureComponent.quiz.countdownTimer.time.hours;
      // these are timer as of when overlay is opened, they don't update
      const timerMinutes = vm.lectureComponent.quiz.countdownTimer.time.minutes;

      // eslint-disable-next-line no-shadow
      const modalInstanceCtrl = ['$scope', 'quiz', 'timerHours', 'timerMinutes', ($scope, quiz, timerHours, timerMinutes) => {
        $scope.quiz = quiz;
        $scope.timerHours = timerHours;
        $scope.timerMinutes = timerMinutes;
      }];

      const modalInstance = ConfirmationOverlays.openConfirmationModal(
        'quizzes/templates/timed_quiz/start-timed-quiz-confirmation-overlay.html',
        modalInstanceCtrl,
        {
          quiz,
          timerHours,
          timerMinutes,
        },
      );
      modalInstance.result.then(() => {
        vm.launchTimedQuizModal();
      });
    };

    vm.launchFeedbackModal = () => {
      const modal = $uibModal.open({
        templateUrl: 'quizzes/templates/modal-timed-quiz-feedback.html',
        controller: 'TimedQuizFeedbackCtrl as vm',
        windowClass: 'full-screen-modal-handheld full-screen-modal-tablet timed-quiz quiz-feedback',
        resolve: {
          questionSetId: vm.lectureComponent.quiz.id,
          quiz: vm.lectureComponent.quiz,
        },
      });
    };

    /* Admin Actions */
    vm.editBasics = () => {
      vm.lectureComponent.createDraft();

      const modalInstance = LectureComponentsHelper.showEditModal(vm.lectureComponent);

      modalInstance.result.then(() => {
        const closeModal = LectureComponentsHelper.showSavingOverlay();

        vm.lectureComponent.saveDraft().then(closeModal, closeModal);
      });
    };

    vm.previewQuestions = () => {
      vm.launchTimedQuizModal(true);
    };

    vm.setEditMenu = () => {
      const { sharedProps } = this.context;
      const extraOptions = [];

      extraOptions.push(sharedProps.extraOptions.getEditOption(), {
        type: 'link',
        link: RailsRoutes.editQuestionSetPath(vm.$stateParams.catalogId, vm.lectureComponent.quiz.id),
        text: t.LECTURE_PAGES.COMPONENTS.QUIZ.DROPDOWN.EDIT_QUESTIONS(),
        disabled: !!vm.lectureComponent.lecturePage.isLinked,
        tooltip: {
          text: t.LECTURE_PAGES.COMPONENTS.SET_UP_FROM_COLLECTION_TOOLTIP(),
          textAlign: TextAlign.LEFT,
          enabled: !!vm.lectureComponent.lecturePage.isLinked,
          placement: 'left',
          offset: 20,
        },
      }, {
        type: 'text',
        text: t.LECTURE_PAGES.COMPONENTS.TIMED_QUIZ.DROPDOWN.PREVIEW(),
        callback: () => vm.previewQuestions(),
      });

      if (!vm.CurrentCourseManager.course.isSelfPaced) {
        extraOptions.push({
          type: 'text',
          text: vm.lectureComponent.hasDeadline() ? t.LECTURE_PAGES.COMPONENTS.QUIZ.DROPDOWN.REMOVE_DEADLINE() : t.LECTURE_PAGES.COMPONENTS.QUIZ.DROPDOWN.ADD_DEADLINE(),
          callback: () => {
            vm.toggleDeadline();
            vm.setEditMenu();
          },
          disabled: vm.isContentManagementCollection,
          tooltip: {
            enabled: vm.isContentManagementCollection,
            text: t.LECTURE_PAGES.COMPONENTS.DROPDOWN.SETUP_IN_LINKED_LESSON_TOOLTIP(),
            placement: 'left',
            offset: 20,
          },
        });
      }

      sharedProps.extraOptions = {
        ...sharedProps.extraOptions,
        renderOnMount: true,
        ...{
          options: extraOptions,
        },
      };
    };

    vm.setEditMenu();

    vm.editBasics = () => {
      $timeout(() => {
        vm.context.sharedProps.openBasicModal();
      });
    };

    vm.hasBuilderPermissionInCollection = () => (contentManagementCollection.userCourse
      && CurrentPermissionsManager.hasCollectionBuilderPermissions(contentManagementCollection.userCourse));

    vm.goToCollectionLesson = () => {
      const url = $state.href('lecture-page-edit', {
        catalogId: contentManagementCollection.catalogId,
        id: contentManagementLecturePage.id,
        lectureActivityId: vm.lectureComponent.cloneParentId,
      });

      window.open(url, '_blank');
    };

    $scope.$watch('!vm.CurrentCourseManager.course.isSelfPaced', () => vm.setEditMenu());

    $scope.$on('$destroy', () => {
      if (vm.lectureComponent.quiz?.countdownTimer) {
        vm.lectureComponent.quiz.countdownTimer.stopTimer();
      }
    });
  },
  controllerAs: 'vm',
  templateUrl: 'lecture_pages/templates/components/nv-timed-quiz-lecture-component.html',
};
