import createCachedSelector from 're-reselect';
import { useSelector } from 'react-redux';
import { ModelsState, RootState } from 'redux/schemas';
import { NormalizedTimeline } from 'redux/schemas/models/timeline';
import _, { each } from 'underscore';
import { ActivityType } from 'redux/schemas/models/activity';
import { NLecturePage } from 'redux/schemas/models/lecture-page';
import { lectureStatusSelector } from 'redux/selectors/lecture-page';
import { CourseOutlineItem, OutlineItemType } from './course-outline-item';
import { getActivityIdsFromLecturePageId } from '../timeline';

/**
 * WILL BE REMOVING THIS AND use-course-outline.tsx WILL BE USED
 * THIS IS USED FOR COURSE BUILDER ROLES ONLY SO THAT IT WILL DEPEND ON THE
 * BULKY course_timeline.json AND POINTS & PROGRESS IS CALCULATED IN THE
 * FRONTEND INSTEAD FOR BACKEND. THIS TICKET ONLY IMPACTS FOR LEARNERS -
 * https://novoed.atlassian.net/browse/NOV-80252
 * THE SCOPE WILL INCREASE IF WE HANDLE THE NEW API IN EDIT MODE, SO FOR NOW
 * THE COURSE BUILDER WON'T BE AFFECTED AND WILL BE PRESENTED WITH THE DATA
 * LOADED USING THE BULKY API. THIS FILE WILL BE REMOVED WHEN WE HANDLE THE
 * COURSE BUILDER TOO AND INTRODUCE THE OPTIMIZED API IN THE EDIT MODE TOO.
 *
 * OO-TODO - Remove this file
 */

type OutlineLecturePages = { [id: string]: NLecturePage & { achievableTotalPoints: number } };

/** Creates a flat description of a course's sections, subsections, and lesson pages the correct
 order per how they they should appear in the LHS timeline navigation. Also used by the move-to
 component submenu and other UIs that show an "outline" of the course. */
const computeOutline = (
  lectureSections: ModelsState['lectureSections'],
  // Note that this param currently causes this function to re-fire every time we change a lecture page.
  // This is because we load more lecture page data, thus changing this part of our model state
  lecturePages: OutlineLecturePages,
  timeline: NormalizedTimeline,
) => {
  const outline: CourseOutlineItem[] = [];

  if (!timeline) {
    return [];
  }

  const createSectionLecturePageItems = (sectionId: number, sectionType: 'section' | 'subsection') => {
    const items = [];
    const section = lectureSections[sectionId];
    let sectionPointsAccrued = 0;
    let sectionTotalPoints = 0;
    let sectionAchievableTotalPoints = 0;
    let lecturesAreReleased = false;

    section.lecturePages.forEach(lectureId => {
      const lecturePage = lecturePages[lectureId];

      // TODO: Is there a separate prop for manually awarded points?
      const lecturePointsAccrued = lecturePage.pointsReceived;
      // Total points is an array of length 2 due to an old feature which caused points to "decay", where
      // point values would decrease over time. The 0th index point value is the only one we should use
      // going forward
      const [lectureTotalPoints] = lecturePage.totalPoints ?? [0];

      items.push({
        type: 'lecture',
        name: lecturePage.title,
        id: lectureId,
        section: sectionId,
        sectionType,
        pointsAccrued: lecturePointsAccrued,
        totalPoints: lectureTotalPoints,
        completed: lecturePage.completed,
        achievableTotalPoints: lecturePage.achievableTotalPoints,
        isLinked: lecturePage.isLinked,
      });

      sectionTotalPoints += lectureTotalPoints;
      sectionPointsAccrued += lecturePointsAccrued;
      sectionAchievableTotalPoints += lecturePage.achievableTotalPoints;
      lecturesAreReleased = lecturesAreReleased || lecturePage.released;
    });

    return {
      items,
      totalPoints: sectionTotalPoints,
      pointsAccrued: sectionPointsAccrued,
      currentTotalPoints: sectionAchievableTotalPoints,
      lecturesAreReleased,
    };
  };

  const addSectionToOutline = (sectionId: number, type: 'section' | 'subsection') => {
    const lectureSection = lectureSections[sectionId];

    const lecturePageItems = createSectionLecturePageItems(sectionId, type);
    const sectionOutlineItem = {
      type,
      name: lectureSection.title,
      id: sectionId,
      pointsAccrued: type === 'subsection' ? lecturePageItems.pointsAccrued : undefined,
      totalPoints: type === 'subsection' ? lecturePageItems.totalPoints : undefined,
      achievableTotalPoints: type === 'subsection' ? lecturePageItems.currentTotalPoints : undefined,
      allLecturePagesCompleted: lecturePageItems.items.length > 0 ? _.every(lecturePageItems.items, (item) => item.completed) : false,
      lecturesAreReleased: lecturePageItems.lecturesAreReleased,
    };

    outline.push(sectionOutlineItem);
    outline.push(...lecturePageItems.items);

    return sectionOutlineItem;
  };

  timeline.lectureSubsections.forEach(lssId => addSectionToOutline(lssId, 'subsection'));

  timeline.lectureSections.forEach(lId => {
    const sectionOutlineItem = addSectionToOutline(lId, 'section');
    lectureSections[lId].lectureSubsections.forEach(lssId => {
      const subsectionOutlineItem = addSectionToOutline(lssId, 'subsection');
      // This tracks sections who have any descendants who are lectures that are released. Learners should only see & interact with sections/subsections/lessons
      // that are released or who have released descendants.
      sectionOutlineItem.lecturesAreReleased = sectionOutlineItem.lecturesAreReleased || subsectionOutlineItem.lecturesAreReleased;
    });
  });

  return outline;
};

const getLectureAchievedPoints = (state: RootState, lecturePageId: number) => {
  const activities = getActivityIdsFromLecturePageId(state, lecturePageId);

  return _.reduce(activities, (total, activityIds: number[], activityType: ActivityType) => (
    total + _.reduce(activityIds, (memo, activityId) => {
      const activity = state.models[activityType][activityId];
      return memo + (activity?.pointsReceived ?? 0);
    }, 0)
  ), 0);
};

const getLectureAchievableTotalPoints = (state: RootState, lecturePageId: number) => {
  const activities = getActivityIdsFromLecturePageId(state, lecturePageId);

  return _.reduce(activities, (total, activityIds: number[], activityType: ActivityType) => (
    total + _.reduce(activityIds, (memo, activityId) => {
      const activity = state.models[activityType][activityId];
      return memo + (activity?.totalPoints?.[0] ?? 0);
    }, 0)
  ), 0);
};

const getLecturePageswithAchievablePoints = (state: RootState) => {
  const { lecturePages } = state.models;
  const LPWithAchievablePoints: OutlineLecturePages = {};
  each(lecturePages, (lp) => {
    LPWithAchievablePoints[lp.id] = {
      ...lp,
      pointsReceived: getLectureAchievedPoints(state, lp.id),
      achievableTotalPoints: !_.isEmpty(lp.totalPoints) ? getLectureAchievableTotalPoints(state, lp.id) : 0,
      completed: lectureStatusSelector(state, lp.id) === 'completed',
    };
  });

  return LPWithAchievablePoints;
};

const getTimeline = (state: RootState, catalogId: string) => state.models.timelines[catalogId];
const getLectureSections = (state: RootState) => state.models.lectureSections;


/** Creates a selector to compute & store the course outline created via computeOutline(). This is achieved
 * via the re-reselect library as one of the input parameters (catalogId) is a non-Redux state value, which
 * necessitates reselect using multiple caches (see https://github.com/toomuchdesign/re-reselect#re-reselect for
 * more info) */
const courseOutlineSelector = createCachedSelector(
  getLecturePageswithAchievablePoints,
  getTimeline,
  getLectureSections,
  (lecturePages, timeline, lectureSections) => computeOutline(lectureSections, lecturePages, timeline),
)(
  (state, catalogId) => catalogId,
);

export type CourseOutlineNotOptimized = CourseOutlineItem[];

/** Calculates and returns an object describing the "outline" (sometimes referred to as a "timeline")for this course.
 * This is a computed version of the data returned by /course_timeline.json and is the primary way
 * that we describe the ordering of sections, subsections, & lectures in our application. */
export const useCourseOutlineNotOptimized = (catalogId: string): CourseOutlineNotOptimized => useSelector(state => courseOutlineSelector(state, catalogId));
