/* eslint-disable react/no-danger */
import React, { useContext } from 'react';
import t from 'react-translate';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ProgressBar } from 'react-bootstrap';
import { css } from '@emotion/react';
import Truncate from 'react-truncate';

import { Course } from 'redux/schemas/models/course';
import { getEnrollmentsOfJourneyCourses, getInProgressCoursesFromJourneyCatalogId } from 'redux/selectors/learning-journeys';

import { AngularServicesContext } from 'react-app';

import NvIcon from 'shared/components/nv-icon';
import NvSubmissionGallery from 'shared/components/submission/nv-submission-gallery';

import { mobile } from 'styles/global_defaults/media-queries';
import { gray2, gray6, primary } from 'styles/global_defaults/colors';
import { doubleSpacing, halfSpacing, quarterSpacing, standardSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { boldFontWeight, lightFontWeight, openSans, openSansCondensed, semiBoldFontWeight, textExtraLargeFontSize, textLargeLineHeight, textMediumFontSize, textSmallFontSize } from 'styles/global_defaults/fonts';

import {
  ReactComponent as EmptyJourneyCardIcon,
} from 'styles/icons/empty-journey.svg';

import { SanitizationLevel, sanitize } from 'froala/helpers/sanitizer';

import { VisitedLearningJourneySchema } from 'redux/schemas/app/learning-journey';
import OfferingCard from './offering-card';

const styles = css`
  display: flex;
  flex-direction: column;
  gap: ${standardSpacing}px;
  padding: ${standardSpacing}px;
  border-radius: ${halfSpacing}px;
  box-shadow: 0px ${halfSpacing}px ${standardSpacing}px ${gray6};
  font-family: ${openSans};
  overflow: hidden;

  .text-xl-condensed {
    font-family: ${openSansCondensed};
    font-size: ${textExtraLargeFontSize}px;
  }

  .text-sm-normal-semibold {
    font-family: ${openSans};
    font-size: ${textSmallFontSize}px;
    font-weight: ${semiBoldFontWeight};
  }

  .journey-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${mobile(css`
      flex-direction: column;
      align-items: flex-start;
      gap: ${standardSpacing}px;
    `)}

    .icon-title {
      display: flex;
      align-items: center;
      gap: ${halfSpacing}px;

      i {
        color: ${primary};
      }

      .title {
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-weight: ${lightFontWeight};

        .journey-dates {
          color: ${gray2};
        }
      }
    }

    .progress-info {
      width: 200px;
      min-width: 200px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: ${halfSpacing}px;

      ${mobile(css`
        align-items: flex-start;
      `)}

      .bs4-progress {
        width: 100%;
        height: ${quarterSpacing}px;
      }

      .progress-text {
        color: ${gray2};
        font-size: ${textSmallFontSize}px;
      }
    }
  }

  .journey-card-content {
    .empty-journey {
      display: flex;
      flex-direction: column;
      align-items: center;

      .header-title {
        margin-top: ${standardSpacing}px;
        font-weight: ${boldFontWeight};
        text-align: center;
      }
    }

    .journey-description {
      font-size: 16px;
      line-height: ${textLargeLineHeight}px;
      letter-spacing: 0px;
      text-align: left;
    }
  }

  .journey-card-footer {
    display: flex;
    justify-content: center;
    padding: ${standardSpacing}px;

    a {
      border-radius: ${threeQuartersSpacing}px;
    }
  }

  .nv-submission-gallery {
    overflow: unset;

    .carousel-next {
      top: 50%;
      height: ${doubleSpacing}px;
      transform: translate(50%,-50%);
    }

    .carousel-prev {
      top: 50%;
      height: ${doubleSpacing}px;
      transform: translate(-50%,-50%);
    }
  }
`;

type JourneyCardProps = {
  journey: Course;
};

function JourneyCard({ journey }: JourneyCardProps) {
  const angularServices = useContext(AngularServicesContext);

  const inProgressCourses = useSelector(state => getInProgressCoursesFromJourneyCatalogId(state, journey.catalogId));
  const journeyCoursesEnrollments = useSelector(state => getEnrollmentsOfJourneyCourses(state, journey.catalogId));

  const goToJourneyHomeUrl = angularServices.$state.href('learning-journey-home', {
    catalogId: journey.catalogId,
  });

  const releaseDate = journey.releaseDate && moment(journey.releaseDate);
  const releaseDateFormated = releaseDate?.format('L');
  const closeDate = journey.closeDate && moment(journey.closeDate);
  const closeDateFormated = closeDate?.format('L');
  const releaseAndCloseDate = releaseDate && closeDate;
  const displayReleaseAndCloseDate = `${releaseDateFormated} to ${closeDateFormated}`;
  const displayReleaseDateOrNothing = releaseDate ? t.OFFERINGS.CARD.START_DATE(releaseDateFormated) : '';

  const coursesCompleted = journey.completionStats.collections.reduce((completedAcc, { completed, required }) => completedAcc + (completed >= required ? required : completed), 0);
  const totalRequiredCourses = journey.completionStats.required;
  const isJourneyCompleted = coursesCompleted > 0 && coursesCompleted === totalRequiredCourses;
  const isJourneyInProgress = coursesCompleted > 0 && coursesCompleted < totalRequiredCourses;
  const journeyInfo: VisitedLearningJourneySchema = {
    id: journey?.id,
    catalogId: journey?.catalogId,
    name: journey?.name,
    releaseDate: journey?.releaseDate,
    closeDate: journey?.closeDate,
    headerColor: journey?.headerColor,
    nameFontColor: journey?.nameFontColor,
    headerBackground: journey?.headerBackground,
    completionStats: journey?.completionStats,
  };

  const sanitizedDescription = journey.classDescription && sanitize(journey.classDescription, SanitizationLevel.NO_TAGS);
  const isSanitizedEmpty = !(sanitizedDescription?.trim().length > 0);

  const renderDescription = journey.classDescription && !isSanitizedEmpty ? (
    <div className='journey-description'>
      <Truncate
        lines={4}
        ellipsis='...'
        trimWhitespace
      >
        <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
      </Truncate>
    </div>
  ) : (
    <div className='empty-journey'>
      <EmptyJourneyCardIcon width={220} height={60} />
      <div className='header-title text-xl-condensed'>
        {t.OFFERINGS.CARD.VISIT_JOURNEY_HOME_TO_GET_STARTED()}
      </div>
    </div>
  );

  const renderDescriptionOrContinueMessage = isJourneyInProgress
    ? (
      <div className='empty-journey'>
        <EmptyJourneyCardIcon width={220} height={60} />
        <div className='header-title text-xl-condensed'>
          {t.OFFERINGS.CARD.GOOD_PROGRESS_SO_FAR_N_MORE_TO_GO(totalRequiredCourses - coursesCompleted)}
        </div>
      </div>
    ) : renderDescription;

  const journeyCardContentStyles = css`
    ${inProgressCourses.length === 0 && !isJourneyCompleted && !isJourneyInProgress && `
      padding: 0 ${doubleSpacing}px;
    `}
  `;

  return (
    <div css={styles}>
      <div className='journey-card-header'>
        <div className='icon-title'>
          <NvIcon icon='path' size='large' />
          <div className='title text-xl-condensed'>
            <a href={goToJourneyHomeUrl}>{journey.name}</a>
            <div className='journey-dates text-sm-normal-semibold'>
              {releaseAndCloseDate
                ? displayReleaseAndCloseDate
                : displayReleaseDateOrNothing}
            </div>
          </div>
        </div>
        <div className='progress-info'>
          <ProgressBar variant='success' now={coursesCompleted} max={totalRequiredCourses} />
          <div className='progress-text'>
            {t.OFFERINGS.CARD.X_OUT_OF_Y_COMPLETED(coursesCompleted, totalRequiredCourses)}
          </div>
        </div>
      </div>
      {!isJourneyCompleted && (
        <div className='journey-card-content' css={journeyCardContentStyles}>
          {inProgressCourses.length > 0 ? (
            <NvSubmissionGallery spacing={standardSpacing}>
              {inProgressCourses.map(course => (
                <OfferingCard
                  offering={course}
                  journey={journeyInfo}
                  key={course.id}
                />
              ))}
            </NvSubmissionGallery>
          ) : renderDescriptionOrContinueMessage}
        </div>
      )}
      <div className='journey-card-footer'>
        <a className='bs4-btn bs4-btn-primary' href={goToJourneyHomeUrl}>
          {journeyCoursesEnrollments.length === 0
            ? t.OFFERINGS.CARD.START_YOUR_JOURNEY_NOW()
            : t.OFFERINGS.CARD.VIEW_JOURNEY_HOME()}
        </a>
      </div>
    </div>
  );
}

export default JourneyCard;
