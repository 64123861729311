import { css } from '@emotion/react';
import React, { useContext, useState } from 'react';
import { Accordion, AccordionCollapse, AccordionContext, Card, useAccordionToggle } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import t from 'react-translate';
import { setBottomBarVisibility, setLeftPanelView } from 'redux/actions/lecture-pages';
import {
  AccordionSectionType, ComponentTrueType, ComponentType,
  RichTextType, StyledLinkType,
} from 'redux/schemas/models/lecture-component';
import { kebabCase } from 'lodash';
import { useAppDispatch } from 'redux/store';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import prodPathReplace from 'shared/prod-path-rewrite';
import { black } from 'styles/global_defaults/colors';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import useErrorBoundary from 'use-error-boundary';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import getComponentMetadata from '../data';
import ConditionalWorkflow from '../workflows/conditional-workflow';
import HeaderComponentContentPreview from './content-previews/header-component-content-preview';
import TextWithImageContentPreview from './content-previews/text-with-image-content-preview';
import createNewComponent, { AddComponentCallback } from './create-new-component';
import StyledLinksContentPreview from './content-previews/styled-link-content-preview';
import AccordionContentPreview from './content-previews/accordion-content-preview';

/** A union type representing how each button in this panel can either be one that creates a new component,
 * or an accordion panel that itself has new-component buttons */
export type ComponentBarButton = (AddComponentBarButton | AddComponentAccordionPanel) & {
  title: string,
  tooltip: string,
};

/** A button which creates a new component */
export type AddComponentBarButton = {
  type: 'add'
  icon: string,
  componentType: ComponentTrueType,
};

/** A button which expands/collapses in an accordion style and contains buttons for creating components */
export type AddComponentAccordionPanel = {
  type: 'panel',
  /** A function component for rendering a UI on the left side of this panel's header, before the title */
  customIconArea?: (props: {
    /** Whether the accordion is open */
    isOpen: boolean,
  }) => JSX.Element,
  items: AddComponentAccordionItem[],
};

/** A child item of an AddComponentAccordionPanel */
export type AddComponentAccordionItem = {
  /** The function for rendering the accordion item bar button. Should typically use the function component for
   * the actual lecture component to be created, but can use emotion styles to do style overrides */
  render: () => JSX.Element,
  componentType: ComponentTrueType,
  tooltip: string,
};

/** Creates the bar buttons for this left-panel experience. Is a function since translations are not available
 * until after page load */
const createBarButtons = (): ComponentBarButton[] => [
  {
    type: 'add',
    icon: 'spacer',
    componentType: ComponentType.WHITE_SPACE,
    title: t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.WHITE_SPACE_LABEL(),
    tooltip: t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.WHITE_SPACE(),
  },
  {
    type: 'add',
    icon: 'admin-rich-text',
    componentType: RichTextType.SIMPLE,
    title: t.LECTURE_PAGES.COMPONENTS.TEXT.DESCRIPTION(),
    tooltip: t.LECTURE_PAGES.COMPONENTS.TEXT.TOOLTIP(),
  },
  {
    type: 'add',
    icon: 'images',
    componentType: ComponentType.IMAGE,
    title: t.LECTURE_PAGES.COMPONENTS.IMAGE.DESCRIPTION(),
    tooltip: t.LECTURE_PAGES.COMPONENTS.IMAGE.TOOLTIP(),
  },
  {
    type: 'add',
    icon: 'files',
    componentType: ComponentType.ATTACHMENT,
    title: t.LECTURE_PAGES.COMPONENTS.ATTACHMENT_LIST.DESCRIPTION(),
    tooltip: t.LECTURE_PAGES.COMPONENTS.ATTACHMENT_LIST.TOOLTIP(),
  },
  {
    type: 'panel',
    customIconArea: (props) => (
      <div css={css`
      border-left: 5px solid ${props.isOpen ? 'white' : 'black'};
      height: 20px;
    `}
      />
    ),
    title: t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.HEADER_SAMPLE_CONTENT(),
    tooltip: t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.HEADER_LIST(),
    items: [
      {
        componentType: ComponentType.HEADER_STYLE1,
        render: () => <HeaderComponentContentPreview type={ComponentType.HEADER_STYLE1} />,
        tooltip: t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.HEADER(),
      },
      {
        componentType: ComponentType.HEADER_STYLE2,
        render: () => <HeaderComponentContentPreview type={ComponentType.HEADER_STYLE2} />,
        tooltip: t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.HEADER_TAB(),
      },
      {
        componentType: ComponentType.HEADER_STYLE3,
        render: () => <HeaderComponentContentPreview type={ComponentType.HEADER_STYLE3} />,
        tooltip: t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.HEADER_IMAGE(),
      },
    ],
  },
  {
    type: 'panel',
    // TODO: alt text for this
    customIconArea: () => (
      <img
        src={prodPathReplace('images/content_template_sample.jpg')}
        alt=''
        css={css`
        height: 20px;
        width: 20px;
        border-radius: 100%;
        max-width: 100%;
      `}
      />
    ),
    title: t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.ACCORDION_LABEL.BLURB(),
    tooltip: t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.BLURB_IMAGE_LIST(),
    items: [
      {
        componentType: ComponentType.TEXT_WITH_IMAGE_SIDE,
        render: () => <TextWithImageContentPreview type={ComponentType.TEXT_WITH_IMAGE_SIDE} index={1} />,
        tooltip: t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.BLURB_SIDE_IMAGE(),
      },
      {
        componentType: ComponentType.TEXT_WITH_IMAGE_BKG,
        render: () => <TextWithImageContentPreview type={ComponentType.TEXT_WITH_IMAGE_BKG} index={2} />,
        tooltip: t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.BLURB_BACKGROUND_IMAGE(),
      },
      {
        componentType: ComponentType.TEXT_WITH_IMAGE_TOP,
        render: () => <TextWithImageContentPreview type={ComponentType.TEXT_WITH_IMAGE_TOP} index={3} />,
        tooltip: t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.BLURB_TOP_IMAGE(),
      },
    ],
  },
  {
    type: 'panel',
    customIconArea: (props) => (
      <NvIcon icon='add' size='smallest' />
    ),
    title: t.LECTURE_PAGES.COMPONENTS.ACCORDION.DESCRIPTION(),
    tooltip: t.LECTURE_PAGES.COMPONENTS.ACCORDION.TOOLTIP.LIST_EXPAND(),
    items: [
      {
        componentType: AccordionSectionType.STYLE_1,
        render: () => (
          <AccordionContentPreview
            style={AccordionSectionType.STYLE_1}
            type='more_less'
          />
        ),
        tooltip: t.LECTURE_PAGES.COMPONENTS.ACCORDION.TOOLTIP.INSERT_ACCORDION_STYLE_1(),
      },
      {
        componentType: AccordionSectionType.STYLE_2,
        render: () => (
          <AccordionContentPreview
            style={AccordionSectionType.STYLE_2}
            type='more_less_circled'
          />
        ),
        tooltip: t.LECTURE_PAGES.COMPONENTS.ACCORDION.TOOLTIP.INSERT_ACCORDION_STYLE_2(),
      },
      {
        componentType: AccordionSectionType.STYLE_3,
        render: () => (
          <AccordionContentPreview
            style={AccordionSectionType.STYLE_3}
            type='decimal'
          />
        ),
        tooltip: t.LECTURE_PAGES.COMPONENTS.ACCORDION.TOOLTIP.INSERT_ACCORDION_STYLE_3(),
      },
      {
        componentType: AccordionSectionType.STYLE_4,
        render: () => (
          <AccordionContentPreview
            style={AccordionSectionType.STYLE_4}
            type='alphabetical'
          />
        ),
        tooltip: t.LECTURE_PAGES.COMPONENTS.ACCORDION.TOOLTIP.INSERT_ACCORDION_STYLE_4(),
      },
      {
        componentType: AccordionSectionType.STYLE_5,
        render: () => (
          <AccordionContentPreview
            style={AccordionSectionType.STYLE_5}
            type='custom_symbol'
          />
        ),
        tooltip: t.LECTURE_PAGES.COMPONENTS.ACCORDION.TOOLTIP.INSERT_ACCORDION_STYLE_5(),
      },
    ],
  },
  {
    type: 'panel',
    customIconArea: (props) => (
      <NvIcon icon='format-makelink' size='smallest' />
    ),
    title: t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.DESCRIPTION(),
    tooltip: t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.STYLED_LINK_LIST(),
    items: [
      {
        componentType: StyledLinkType.BUTTON,
        render: () => <StyledLinksContentPreview type={StyledLinkType.BUTTON} />,
        tooltip: t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.BUTTON_STYLED_LINK(),
      },
      {
        componentType: StyledLinkType.CARD,
        render: () => <StyledLinksContentPreview type={StyledLinkType.CARD} />,
        tooltip: t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.CARD_STYLED_LINK(),
      },
    ],
  },
  {
    type: 'add',
    icon: 'dash',
    componentType: ComponentType.LINE_DIVIDER,
    title: t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LINE_LABEL(),
    tooltip: t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.LINE(),
  },
  {
    type: 'add',
    icon: 'html',
    componentType: RichTextType.FULL,
    title: t.LECTURE_PAGES.COMPONENTS.HTML.DESCRIPTION(),
    tooltip: t.LECTURE_PAGES.COMPONENTS.HTML.TOOLTIP(),
  },
];

/** Similar to AddComponentTopLevel; contains UX for new component add buttons, and also for accordion containers
 * for those buttons, specific to the "content" subview on the left panel */
export const AddContentComponent = () => {
  const styles = css`
    .back-button, .bar-button, .add-component-btn {
      cursor: pointer;
    }

    .accordion-panel {
      transition: flex 0.3s ease-out;
    }
  `;

  const { ErrorBoundary, didCatch, error } = useErrorBoundary();

  const dispatch = useAppDispatch();
  const history = useHistory();
  const params = useLecturePageParams();
  const [barButtons] = useState(createBarButtons());
  const [openedAccordionKey, setOpenedAccordionKey] = useState(null);
  const newComponentIndex = useSelector(state => state.app.lecturePage.newComponentIndex);

  const exitContentSubview = () => {
    dispatch(setLeftPanelView('add_top_level'));
  };

  // Dispatches creating a new component with the backend
  const createLectureComponent: AddComponentCallback = (componentType, metadata, modelData) => {
    dispatch(setLeftPanelView('outline'));
    dispatch(setBottomBarVisibility(true));

    return createNewComponent(
      newComponentIndex,
      params.catalogId,
      params.lecturePageId,
      componentType,
      metadata,
      dispatch,
      history,
      modelData,
    );
  };

  // TODO: this is lot of logic for this component; let's see if we can split out the
  // accordion/non-accordion versions into separate implementations but with some code reuse
  return (
    <React.Fragment>
      <div css={styles} className='w-100 pl-4 pr-4'>
        {/* Header area containing the back navigation button */}
        <div className='d-flex border-bottom border-gray-5 pt-3 pb-3 position-relative'>
          <div className='back-button d-flex align-items-center text-primary position-absolute' onClick={() => exitContentSubview()}>
            <NvIcon icon='collapse' size='sm' />
            <div className='ml-2'>{t.FORM.BACK()}</div>
          </div>
          <div className='mx-auto'>{t.LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION()}</div>
        </div>
        {/* Enumeration of bar buttons & accordion panels */}
        <div className='pt-4'>
          {barButtons.map((barButton, i) => {
            const key = barButton.title;

            if (barButton.type === 'add') {
              return (
                <div className='mt-4 mb-6' key={key}>
                  <AddContentHeader barButton={barButton} addComponent={createLectureComponent} />
                </div>
              );
            }

            return (
              <div className='mt-4 mb-6' key={key}>
                <AddContentAccordion
                  barButton={barButton}
                  addComponent={createLectureComponent}
                  activeKey={openedAccordionKey}
                  setActiveKey={setOpenedAccordionKey}
                />
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

type AddContentAccordionProps = {
  barButton: ComponentBarButton,
  addComponent: AddComponentCallback,
  activeKey: string,
  setActiveKey: (key: string) => void
};

export const AddContentAccordion = (props: AddContentAccordionProps) => {
  const { barButton, activeKey, setActiveKey } = props;

  const onHeaderClick = (key: string) => {
    setActiveKey(activeKey !== key ? key : null);
  };

  const [hoveredButton, setHoveredButton] = useState(null);
  return (
    <Accordion key={barButton.title} activeKey={activeKey}>
      <AddContentHeader
        barButton={barButton}
        eventKey={barButton.title}
        callback={onHeaderClick}
      />
      <AccordionCollapse eventKey={barButton.title}>
        <Card.Body>
          <div className='accordion-panel border border-primary bg-white pt-5 pl-4 pr-4 d-flex flex-column'>
            {barButton.type === 'panel' && barButton.items.map(addComponentButton => {
              const metadata = getComponentMetadata(addComponentButton.componentType);

              const onClick = (e) => props.addComponent(addComponentButton.componentType, metadata);
              return (
                <NvTooltip
                  key={addComponentButton.componentType}
                  text={addComponentButton.tooltip}
                  show={hoveredButton === addComponentButton.componentType}
                >
                  <div>
                    <ConditionalWorkflow
                      mode='new'
                      metadata={metadata}
                      save={(lectureComponent) => props.addComponent(addComponentButton.componentType, metadata, lectureComponent)}
                      componentType={addComponentButton.componentType}
                    >
                      <div
                        className='add-component-btn mb-5'
                        onClick={metadata?.workflow.type === 'default' ? onClick : undefined}
                        pendo-tag-name={metadata.pendoTagName ?? undefined}
                        onMouseEnter={() => setHoveredButton(addComponentButton.componentType)}
                        onMouseLeave={() => setHoveredButton(null)}
                        data-qa={`lhs-${kebabCase(addComponentButton.componentType)}-add-button`}
                      >
                        {addComponentButton.render()}
                      </div>
                    </ConditionalWorkflow>
                  </div>
                </NvTooltip>
              );
            })}
          </div>
        </Card.Body>
      </AccordionCollapse>
    </Accordion>
  );
};

type AddContentHeaderProps = {
  barButton: ComponentBarButton,
  eventKey?: string,
  callback?: (eventKey: string) => void,
  addComponent?: AddComponentCallback,
};

export const AddContentHeader = (props: AddContentHeaderProps) => {
  const { barButton, eventKey } = props;
  const currentEventKey = useContext(AccordionContext);

  // See https://react-bootstrap.github.io/components/accordion/#custom-toggle-with-expansion-awareness for documentation on expansion-aware
  // accordion controls
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => props.callback && props.callback(eventKey),
  );

  const metadata = props.barButton.type === 'add' ? getComponentMetadata(props.barButton.componentType) : undefined;
  const isAccordionOpen = props.barButton.type === 'panel' && currentEventKey === eventKey;
  const pendoTagName = metadata?.pendoTagName;

  const onClick = (e) => {
    if (barButton.type === 'panel') {
      decoratedOnClick(e);
    } else {
      props.addComponent(barButton.componentType, metadata);
    }
  };

  return (
    <NvTooltip text={isAccordionOpen ? t.COURSE_HOME.COLLAPSE() : barButton.tooltip}>
      <div>
        <ConditionalWorkflow
          mode='new'
          metadata={metadata}
          save={(lectureComponent) => props.barButton.type === 'add' && props.addComponent(props.barButton.componentType, metadata, lectureComponent)}
          componentType={props.barButton.type === 'add' && props.barButton.componentType}
        >
          <div
            className={`bar-button w-100 d-flex align-items-center p-2 ${isAccordionOpen ? 'bg-primary' : 'bg-gray-6'}`}
            onClick={(barButton.type === 'panel' || metadata?.workflow.type === 'default') ? onClick : undefined}
            pendo-tag-name={pendoTagName}
            data-qa={kebabCase(
              props.barButton.type === 'add'
                ? `lhs-${props.barButton.componentType}-add-button`
                : `lhs-${props.barButton.title}-accordion-panel`,
            )}
          >
            {barButton.type === 'panel'
              && (
                <div className='pr-2'>
                  {barButton.customIconArea({ isOpen: isAccordionOpen })}
                </div>
              )}
            <div className='course-title-xs'>{barButton.title}</div>
            <div className='ml-auto'>
              {barButton.type === 'add' && <NvIcon icon={barButton.icon} size='sm' />}
              {barButton.type === 'panel' && <NvIcon icon={isAccordionOpen ? 'arrow-up' : 'arrow-down'} size='sm' />}
            </div>
          </div>
        </ConditionalWorkflow>
      </div>
    </NvTooltip>
  );
};

export default AddContentComponent;

