/* @ngInject */
export default function RichTextLectureComponentModel(
  _,
  $q,

  LectureComponentBaseModel,
  config,
) {
  class RichTextLectureComponent extends LectureComponentBaseModel {
    constructor(attributes) {
      const defaults = {
        type: 'RichTextLectureComponent',
        content: '',
        isActivity: false,
        canBeCopied: true,
      };
      super(_.extend({}, defaults, attributes));
    }

    updateContent(newContent) {
      if (newContent !== this.content) {
        this.content = newContent;
        return this.save();
      }
      return $q.when();
    }

    getPayload() {
      return _.pick(this, ['content', 'index']);
    }
  }

  RichTextLectureComponent.iconClass = 'icon-html';
  RichTextLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.HTML.DESCRIPTION';
  RichTextLectureComponent.pendoTagName = config.pendo.lectureEdit.richText;
  RichTextLectureComponent.isContentComponent = true;
  RichTextLectureComponent.toolTipKey = 'LECTURE_PAGES.COMPONENTS.HTML.TOOLTIP';

  return RichTextLectureComponent;
}
