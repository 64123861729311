import React from 'react';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';

//Redux
import { useSelector } from 'react-redux';
import { updateEdCastIntegration, enableEdCastIntegration } from 'redux/actions/edcast';
import { RootState } from 'redux/schemas';
import { DevCredentials, EdCastPayload, LxpCredentials } from 'redux/schemas/models/edcast';
import { Institution } from 'redux/schemas/models/institution';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';

// Form
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

// Pendo
import { config } from '../../../config/pendo.config.json';

// Components
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { PopoversContainerContext } from 'shared/react-utils';
import { Button } from 'react-bootstrap';

// Styles
import { css } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';
import { gray2, gray3 } from 'styles/global_defaults/colors';
import {
  openSans,
  semiBoldFontWeight,
  textSmallFontSize,
} from 'styles/global_defaults/fonts';
import { screenXsMax } from 'styles/global_defaults/media-queries';
import {
  halfSpacing,
  quarterSpacing,
  standardSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';

type FormFieldProps = {
  label: string,
  name: string,
  dataQa: string,
  noMargin?: boolean,
  disabled?: boolean,
  type?: string,
};

const oauthForm: FormFieldProps[][] = [[
  { label: 'CLIENT_ID', name: 'clientId', dataQa: 'integrationClientId', noMargin: true },
  { label: 'CLIENT_SECRET', name: 'clientSecret', dataQa: 'integrationClientSecret', type: 'password' },
], [
  { label: 'LMS_HOST', name: 'hostUrl', dataQa: 'integrationLmsHost', noMargin: true },
]];

const developerForm: FormFieldProps[][] = [[
  { label: 'API_KEY', name: 'apiKey', noMargin: true, dataQa: 'integrationApiKey' },
  { label: 'API_SECRET', name: 'apiSecret', dataQa: 'integrationApiSecret', type: 'password' },
], [
  { label: 'EDCAST_USER', name: 'email', dataQa: 'integrationEdcastUser', noMargin: true },
  { label: 'EDCAST_URL', name: 'edcastUrl', dataQa: 'integrationEdcastUrl' },
]];

const lxpFields = ['clientId', 'clientSecret', 'hostUrl'];
const devFields = ['apiKey', 'apiSecret', 'email', 'edcastUrl'];

const FormField = (props: FormFieldProps) => {
  const {
    label,
    name,
    dataQa,
    noMargin = false,
    disabled,
    type = 'text',
  } = props;

  let disabledClass = '';

  const styles = css`
    ${disabled && css`
      .input-required {
        .bs4-input-group-text {
          background-color: #e9ecef;
        }
      }
    `}
  `;

  React.useEffect(() => {
    disabledClass = disabled ? 'input-disabled' : '';
  }, [disabled]);

  return (
    <div css={styles} className={`col-sm-6 ${noMargin ? 'pl-0 pr-4' : 'pl-4 pr-0'} ${disabledClass}`}>
      <label htmlFor={name} className='m-0'>
        {label}
      </label>
      <NvTextInput
        withForm
        required
        name={name}
        data-qa={dataQa}
        ariaLabel={label}
        autoComplete='off'
        placeholder={label}
        disabled={disabled}
        type={type}
      />
    </div>
  );
};

type FormWrapperType = {
  form: FormFieldProps[][],
  tags: any,
  labels: any,
  disabled?: boolean,
  hideDescription?: boolean,
};

const FormWrapper = ({
  form,
  tags,
  labels,
  disabled,
  hideDescription,
}: FormWrapperType) => (
  <>
    <p className='message bold mb-2 pt-4'>{labels.TITLE()}</p>
    {!hideDescription && <p className='text-regular mb-4'>{labels.MESSAGE()}</p>}
    {form.map((items) => (
      <div className='d-flex mb-4'>
        {items.map((item) => (
          <FormField
            key={item.name}
            name={item.name}
            disabled={disabled}
            dataQa={tags[item.dataQa]}
            label={labels[item.label]()}
            type={item.type}
            noMargin={item.noMargin}
          />
        ))}
      </div>
    ))}
  </>
);

type FormProps = {
  handleModalClose: () => void;
};

const getItems = (data, keys) => {
  const list = {};
  keys.forEach(key => { list[key] = data[key]; });
  return list;
};

const Form = React.forwardRef<any, FormProps>(({ handleModalClose }, ref) => {
  const containerRef = React.useRef<HTMLDivElement>();
  const dispatch = useAppDispatch();
  const institution: Institution = useSelector(state => getCurrentInstitution(state));
  const { InstitutionsManager } = React.useContext(AngularServicesContext);
  const isMobile = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const hasEdcastIntegration = institution?.edcastIntegrationEnabled ?? false;
  const loading = useSelector((state: RootState) => state.app.edCastConfig.isLoading);

  const validationSchema = yup.object().shape({
    apiKey: yup.string().required(t.VALIDATION.REQUIRED()),
    apiSecret: yup.string().required(t.VALIDATION.REQUIRED()),
    email: yup.string().required(t.VALIDATION.REQUIRED()),
    edcastUrl: yup.string().required(t.VALIDATION.REQUIRED()),
    clientId: yup.string().required(t.VALIDATION.REQUIRED()),
    clientSecret: yup.string().required(t.VALIDATION.REQUIRED()),
    hostUrl: yup.string().required(t.VALIDATION.REQUIRED()),
  });

  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...institution?.edcastConfig?.devCredentials,
      ...institution?.edcastConfig?.lxpCredentials,
    },
  });

  React.useImperativeHandle(ref, () => formMethods);
  const {
    formState: { isValid, isDirty },
    handleSubmit,
  } = formMethods;

  const handleEnableClick = (data: LxpCredentials & DevCredentials) => {
    const lxpCredentials = getItems(data, lxpFields) as LxpCredentials;
    const devCredentials = getItems(data, devFields) as DevCredentials;
    const configMethod = hasEdcastIntegration ? updateEdCastIntegration : enableEdCastIntegration;
    const params = { institutionId: institution.id, lxpCredentials, devCredentials };

    wrapThunkAction<EdCastPayload>(dispatch(configMethod(params))).then((response) => {
      InstitutionsManager.institution.updateFromReact({
        ...response.payload?.institutionList,
      });
      handleModalClose();
    }).catch(() => { });
  };

  const styles = css`
    ${!isMobile && css`padding: 0;`}

    .message {
      margin-bottom: ${standardSpacing + quarterSpacing}px;
      color: ${gray2};
    }

    form label {
      font-family: ${openSans};
      font-weight: ${semiBoldFontWeight};
      font-size: ${textSmallFontSize}px;
      color: ${gray3};
    }

    .button-bar {
      margin-top: ${tripleSpacing}px;
      display: flex;
      justify-content: center;
      gap: ${halfSpacing}px;
    }
  `;

  const buttonText = !hasEdcastIntegration
    ? t.INSTITUTIONS.ADVANCED_SETTINGS.EDCAST.SETTINGS_MODAL.ENABLE()
    : t.INSTITUTIONS.ADVANCED_SETTINGS.EDCAST.SETTINGS_MODAL.UPDATE();

  return (
    <PopoversContainerContext.Provider value={containerRef.current}>
      <FormProvider {...formMethods}>
        <div css={styles} ref={containerRef}>
          <form onSubmit={handleSubmit(handleEnableClick)}>
            <FormWrapper
              form={oauthForm}
              disabled={hasEdcastIntegration}
              tags={config.pendo.edcast.oauth}
              hideDescription={hasEdcastIntegration}
              labels={t.INSTITUTIONS.ADVANCED_SETTINGS.EDCAST.SETTINGS_MODAL.OAUTH2}
            />
            <FormWrapper
              form={developerForm}
              disabled={hasEdcastIntegration}
              tags={config.pendo.edcast.developer}
              hideDescription={hasEdcastIntegration}
              labels={t.INSTITUTIONS.ADVANCED_SETTINGS.EDCAST.SETTINGS_MODAL.DEVELOPER}
            />
            {!hasEdcastIntegration && (
              <div className='button-bar'>
                <Button variant='secondary' onClick={handleModalClose} data-qa={config.pendo.edcast.integrationCancel}>
                  {t.FORM.CANCEL()}
                </Button>

                <Button
                  type='submit'
                  variant='primary'
                  disabled={!isDirty || !isValid || loading}
                  data-qa={!hasEdcastIntegration ? config.pendo.edcast.integrationEnable : config.pendo.edcast.integrationUpdate}
                >
                  {buttonText}
                </Button>
              </div>
            )}
          </form>
        </div>
      </FormProvider>
    </PopoversContainerContext.Provider>
  );
});

type Props = {
  show: boolean
  onClose: () => void
};

const EdcastSettings = (props: Props) => {
  const [show, setShow] = React.useState(props.show);
  const formRef = React.useRef<any>();

  React.useEffect(() => {
    if (props.show) {
      setShow(true);
    }
  }, [props.show]);

  const handleModalClose = () => {
    setShow(false);
    props.onClose();
  };

  const modalBodyStyles = css`
    padding: ${standardSpacing}px;
    padding-top: 0;
  `;

  return (
    <NvModal
      show={show}
      height='auto'
      type={ModalType.FIXED}
      onClose={handleModalClose}
      header={t.INSTITUTIONS.ADVANCED_SETTINGS.EDCAST.TITLE()}
      body={(
        <div css={modalBodyStyles}>
          <Form ref={formRef} handleModalClose={handleModalClose} />
        </div>
      )}
      data-qa={config.pendo.edcast.integrationModal}
    />
  );
};

export default EdcastSettings;
