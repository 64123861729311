import { Reply } from './reply';
import { Owner, User, BaseUser } from './my-account';

export interface Comment {
  replyIds: number[];
  repliesFetched: boolean;
  body: string;
  commented: boolean;
  repliesCount: number;
  createdAt: string;
  externalDocument: null;
  flagged: boolean;
  followed: true;
  highlighted: boolean;
  id: number;
  isPublicFeedback: null;
  isRichText: true;
  leaderboardPoints: number;
  leaderboardRank: number;
  liked: boolean;
  likers: User[];
  likersFetched: boolean;
  owner: Owner;
  peerEvaluation: null;
  pointsReceived: number;
  priorLeaderboardRank: number;
  title: null;
  type: string;
  user: User;
  votesCount: number
  additionalRepliesBeforeCount: number;
  additionalRepliesAfterCount: number;
  additionalNewRepliesBeforeCount: number;
  newForViewer?: boolean;
  isFirstContribution?: boolean;
  bookmarkId?: number;
}

export interface CommentsNormalized {
  [id: string]: Comment;
}

export const isComment = (component: Reply | Comment): component is Comment => (component as Comment).replyIds !== undefined;
