/* eslint-disable no-bitwise */
/* @ngInject */
export default function NvTimelineItem(
  TimelinesManager,
  CurrentCourseManager,
  CurrentUserManager,
  moment,
  nvUtil,
  _,
  ConfirmationOverlays,
) {
  return {
    scope: {
      item: '=',
      decayEnabled: '=?',
      gamificationEnabled: '=?',
      collapseTimelineFunction: '&?',
      cardView: '=?',
      showProgress: '=',
      showWarning: '=?',
      itemsWithPrereqWarning: '=?',
    },
    controller: function ctrl($scope, $element, $attrs) {
'ngInject';
      const vm = this;
      vm.TimelinesManager = TimelinesManager;
      vm.CurrentCourseManager = CurrentCourseManager;
      vm.CurrentUserManager = CurrentUserManager;
      vm.formId = nvUtil.randomPositiveInteger();

      vm.saveComponentOnBlur = function () {
        vm.item.lecturePage.updateLectureComponent(vm.item.type, vm.item.id);
      };

      vm.hasDeadlineAttribute = function () {
        let hasDeadlineAttr = _.has(vm.item, 'expirationDate') || _.has(vm.item, 'deadline');
        if (vm.item.quiz) {
          hasDeadlineAttr &= vm.item.quiz.graded; // not a practice quiz;
        } else if (vm.item.teamSet) {
          hasDeadlineAttr &= vm.item.teamSet.formedByStudents && !vm.item.teamSet.isGroup; // team formation formed by students and not a group formation
        }
        return hasDeadlineAttr;
      };

      vm.isDeadlineBeforeRelease = function () {
        const releaseDateMoment = moment(vm.item.lecturePage.releaseDate);
        return (vm.item.expirationDate && moment(vm.item.expirationDate) < releaseDateMoment)
            || (vm.item.deadline && moment(vm.item.deadline) < releaseDateMoment);
      };

      vm.toggleDeadline = function () {
        if (vm.item.hasDeadline()) {
          if (vm.item.scheduledCommunicationsCount > 0) {
            ConfirmationOverlays.openConfirmationModal('lecture_pages/templates/due-date-change-warning-overlay.html',
              'AttachModalResolvesToVmCtrl', {
                vmResolves: () => ({
                  scheduledCommunicationsCount: vm.item.scheduledCommunicationsCount,
                  isRemoving: true,
                }),
              }).result.then(() => { vm.removeDeadline(); vm.saveComponentOnBlur(); });
          } else {
            vm.removeDeadline();
          }
        } else {
          vm.addDefaultDeadline();
        }
        vm.saveComponentOnBlur();
      };

      vm.addDefaultDeadline = function () {
        this.item.setDeadline(moment(vm.item.lecturePage.releaseDate).add(1, 'weeks').endOf('day').toISOString());
      };

      vm.removeDeadline = function () {
        this.item.setDeadline(null);
      };

      vm.showPrereqWarning = function () {
        return vm.showPrereqWarning && vm.itemsWithPrereqWarning && vm.itemsWithPrereqWarning.indexOf(vm.item.activityId) !== -1;
      };
    },
    controllerAs: 'vm',
    bindToController: true,
    template: '<div ng-include="vm.item.templateUrl"></div>',
  };
}
