import { css } from '@emotion/react';
import ClickableContainer from 'components/clickable-container';
import { useContext } from 'react';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';

import { CourseObjectBookmark, ImageBookmarkComponent } from 'redux/schemas/models/bookmark';
import { headerLineHeight } from 'styles/global_defaults/fonts';
import { doubleSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';

type ImageSnippetProps = {
  bookmark: CourseObjectBookmark
};

const ImageSnippet = ({
  bookmark: { catalogId, lecturePageId, lectureComponentId, component },
}: ImageSnippetProps) => {
  const { $state, FlyoutModalManager } = useContext(AngularServicesContext);

  const {
    picture,
    lecturePageName,
    courseName,
    expired,
    viewOptions,
  } = component as ImageBookmarkComponent;

  const onClickSnippet = () => {
    if (expired) { return; }

    FlyoutModalManager?.closeFlyout();
    $state.go('lecture-page', { catalogId, id: lecturePageId, lectureActivityId: lectureComponentId });
  };

  const styles = css`
  &.image-snippet {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .text-wrapper {
      padding: ${standardSpacing}px;
      padding-left: ${doubleSpacing}px;
      min-width: 0;

      .title {
        line-height: ${headerLineHeight}px;
      }

      .subtitle {
        display: flex;

        .lecture-page-name, .course-name {
          max-width: 50%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .middot {
          margin-left: ${quarterSpacing}px;
          margin-right: ${quarterSpacing}px;
        }
      }
    }


    .image-container {
      flex-shrink: 0;
      object-fit: cover;
      overflow: hidden;
      height: 80px;
      width: 110px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      img {
        transform: rotate(${viewOptions.rotation}deg);
        height: 100%;
        object-fit: cover;
      }
    }
  }
`;

  return (
    <ClickableContainer className='image-snippet' css={styles} onClick={onClickSnippet}>
      <div className='text-wrapper'>
        <div className='title text-body font-weight-bold'>{t.LHS.BOOKMARKS.SNIPPET_TITLE.IMAGE()}</div>
        <div className='subtitle text-gray-2 text-small'>
          <div className='lecture-page-name'>{lecturePageName}</div>
          <div className='middot'>&middot;</div>
          <div className='course-name'>{courseName}</div>
        </div>
      </div>
      <div className='image-container'>
        <img src={picture.cdnUrl} alt='' />
      </div>
    </ClickableContainer>
  );
};

export default ImageSnippet;
