import { createSelector } from 'reselect';
import { RootState } from 'redux/schemas';
import { VideoPracticeSubmission } from 'redux/schemas/models/video-practice';
import { SliderAction } from 'practice_room/components/shared/user-submission-slider';
import { filter } from 'lodash';
import { ComponentType } from 'redux/schemas/models/lecture-component';

type FeedbackMappingType = {
  [key: string]: {
    activitiesKey: string,
    activityKey: string,
  }
};

export const feedbackMapping: FeedbackMappingType = {
  [ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK]: {
    activitiesKey: 'exerciseSkillsRatingActivities',
    activityKey: 'exerciseSkillsRatingActivity',
  },
  [ComponentType.VIDEO_PRACTICE_FEEDBACK]: {
    activitiesKey: 'practiceFeedbackActivities',
    activityKey: 'practiceFeedbackActivity',
  },
};

const getPracticeActivitiesIdsAvailableForfeedback = (state: RootState) => (
  state.models.courses[state.app.currentCatalogId].practiceActivitiesAvailableForFeedback ?? []
);

const practiceActivities = (state: RootState) => state.models.practiceActivities;

const practiceSubmissions = (state: RootState) => (
  { ...state.models.practiceSubmissions, ...state.models.submissions }
);

const feedbackGalleryIds = (state: RootState, practiceFeedbackActivityId?: number) => {
  const id = practiceFeedbackActivityId ?? state.app.videoPracticeFeedback.practiceFeedbackCriteriaId;
  const feedbackActivitites = { ...state.models.practiceFeedbackActivities, ...state.models.exerciseSkillsRatingActivities };
  const galleryIds = feedbackActivitites[id]?.feedbackGalleryIds ?? [];
  return galleryIds;
};

export const getPracticeActivitiesAvailable = createSelector(
  [getPracticeActivitiesIdsAvailableForfeedback, practiceActivities],
  (ids, activities) => (ids || []).map(id => activities[id]),
);

export const getFeedbackGallerySubmissions = createSelector(
  [
    (state, practiceFeedbackActivityId) => feedbackGalleryIds(state, practiceFeedbackActivityId),
    practiceSubmissions,
  ], (ids, submissions) => (ids || []).map(id => submissions[id]),
);

export const nextAvailableSubmission = createSelector(
  [
    practiceSubmissions,
    (state, submissionId) => feedbackGalleryIds(state).findIndex(id => id === Number(submissionId)),
    (state) => feedbackGalleryIds(state),
  ], (submissions, index, ids) => {
    let length = ids?.length;
    let nextSubmissionId = ids?.[index + 1];
    while (length !== 0 && submissions?.[nextSubmissionId]?.isRatedByCurrentUser) {
      nextSubmissionId = ids?.[nextSubmissionId + 1];
      if (nextSubmissionId === undefined) nextSubmissionId = ids?.[0];
      length -= 1;
    }

    return submissions?.[nextSubmissionId] ?? null;
  },
);

export const nextAndPrevSubmissions = createSelector(
  [
    practiceSubmissions,
    (state, submissionId) => feedbackGalleryIds(state).findIndex(id => id === Number(submissionId)),
    (state) => feedbackGalleryIds(state),
  ], (submissions, index, ids) => {
    const nextPrev: { [key in SliderAction]: VideoPracticeSubmission } = {
      next: null,
      prev: null,
    };
    const nextSubmissionId = ids?.[index + 1];
    const prevSubmissionId = ids?.[index - 1];

    nextPrev[SliderAction.NEXT] = submissions?.[nextSubmissionId] ?? null;
    nextPrev[SliderAction.PREV] = submissions?.[prevSubmissionId] ?? null;

    return nextPrev;
  },
);

export const getFirstSubmission = createSelector(
  [practiceSubmissions, (state) => feedbackGalleryIds(state)],
  (submissions, ids) => submissions[ids?.[0]],
);

// Get all the feedback activities of the activity and return lecturePageId
export const getLecturePageIdsOfFeedbackActivities = (state: RootState, practiceActivityId) => filter(
  { ...state.models.practiceFeedbackActivities, ...state.models.exerciseSkillsRatingActivities },
  { activityId: practiceActivityId },
).map(feedbackActivity => feedbackActivity.lecturePageId);


export const getNextAndPrevPendingSubmissions = (state: RootState, currentId: number, scenarioId: number) => {
  const { pendingSubmissions } = state.app.supervisorDashboard;
  const array = pendingSubmissions ? Object.values(pendingSubmissions) : [];
  const filteredArray = array.filter(submission => (scenarioId ? submission.scenario.id === scenarioId : submission));
  const currentIndex = filteredArray.findIndex(submission => submission.id === currentId);
  return {
    nextPendingSubmission: filteredArray?.[currentIndex + 1] ? filteredArray[currentIndex + 1] : null,
    prevPendingSubmission: filteredArray?.[currentIndex - 1] ? filteredArray[currentIndex - 1] : null,
  };
};
