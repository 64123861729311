import React, { createContext, useEffect, useState } from 'react';
import t from 'react-translate';
import { isEmpty } from 'underscore';

import { useSelector } from 'react-redux';
import { QuizAIQuestion } from 'redux/schemas/models/quiz';

import { Button } from 'react-bootstrap';
import ClickableContainer from 'components/clickable-container';
import { getNovoAIProperties } from 'redux/selectors/lecture-page';
import { LoaderType } from 'shared/components/loading-placeholder';
import QuestionsHeader from './questions-header';
import QuestionCard from './questions-card';
import QuestionsLoader from './ai-questions-modal-loader';

import useQuizAiQuestions from './hooks/use-quiz-ai-questions';
import { config } from '../../../../config/config.json';

type InitialStateType = {
  selectedQuestionIds: string[]
  onQuestionClick: (questionId: string, selected: boolean) => void
};

const initialState: InitialStateType = {
  selectedQuestionIds: [],
  onQuestionClick: () => {},
};

export const AiQuestionsModalContext = createContext(initialState);

type AIQuestionsModalProps = {
  onClose: () => void,
  onSubmit: (questions: QuizAIQuestion[]) => void,
};

const AiQuestionsModal = (props: AIQuestionsModalProps) => {
  const {
    isLoadingQuestion,
    questionsToShow,
    totalQuestionsCount,
    getMoreQuestions,
    hasError,
  } = useQuizAiQuestions();

  const questions = questionsToShow();
  const hasMoreQuestions = totalQuestionsCount !== questions.length;
  const remainingQuestionsCount = totalQuestionsCount - questions.length;

  const novoAIProperties = useSelector(getNovoAIProperties);
  const [selectedQuestionIds, setSelectedQuestionIds] = useState<string[]>([]);

  const isEditing = !isEmpty(novoAIProperties?.lectureComponentToReplace);

  useEffect(() => {
    if (hasError) {
      props?.onClose();
    }
  }, [hasError, props]);

  const onQuestionClick = (value, selected) => {
    const currentIds = [...selectedQuestionIds];

    if (selected) {
      currentIds.push(value);
    } else {
      const index = currentIds.indexOf(value);
      currentIds.splice(index, 1);
    }

    setSelectedQuestionIds(currentIds);
  };

  const onSave = () => {
    const selectedQuestions = questions
      .filter((q) => selectedQuestionIds.includes(q.id));

    props.onSubmit(selectedQuestions);
  };

  return (
    <AiQuestionsModalContext.Provider
      value={{ selectedQuestionIds, onQuestionClick }}
    >
      <div>
        {totalQuestionsCount === 0 ? (
          <QuestionsLoader />
        ) : (
          <React.Fragment>
            <QuestionsHeader
              totalCount={totalQuestionsCount}
              displayQuestionCount={questions.length}
              isLoading={isLoadingQuestion}
            />
            {questions.length > 0 && questions.map((question, i) => (
              <QuestionCard
                question={question}
                questionNo={i + 1}
                key={question.id}
              />
            ))}
            {isLoadingQuestion ? (
              <QuestionsLoader loaderType={LoaderType.HALF} />
            ) : (
              <ClickableContainer
                className={`text-${hasMoreQuestions ? 'primary' : 'gray-3'} pb-2`}
                onClick={getMoreQuestions}
                disabled={!hasMoreQuestions}
                data-qa={config.pendo.novoAi.quiz.nextQuestions}
              >
                {hasMoreQuestions
                  ? t.LECTURE_PAGES.COMPONENTS.QUIZ.AI_QUESTIONS.MORE_SHOW(remainingQuestionsCount < 5 ? remainingQuestionsCount : 5)
                  : t.LECTURE_PAGES.COMPONENTS.QUIZ.AI_QUESTIONS.NO_MORE_QUESTIONS()}
              </ClickableContainer>
            )}
            <div className='d-flex justify-content-center pt-7 pb-6'>
              <Button
                className='mr-2'
                variant='secondary'
                onClick={() => {
                  props?.onClose();
                }}
                data-qa={config.pendo.novoAi.quiz.cancel}
              >
                {t.FORM.CANCEL()}
              </Button>
              <Button
                disabled={selectedQuestionIds.length === 0}
                onClick={onSave}
                data-qa={config.pendo.novoAi.quiz[isEditing ? 'updateQuiz' : 'createQuiz']}
              >
                {t.LECTURE_PAGES.COMPONENTS.QUIZ.AI_QUESTIONS[isEditing ? 'ADD_TO_QUIZ' : 'CREATE_QUIZ']()}
              </Button>
            </div>
          </React.Fragment>
        )}
      </div>
    </AiQuestionsModalContext.Provider>
  );
};

export default AiQuestionsModal;
