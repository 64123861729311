import { BaseUser, User } from './my-account';

export enum UserRole {
  ADMIN = 'Admin',
  MENTOR = 'Mentor',
  LEARNER = 'Learner',
}

export enum NameAndEmailFormSource {
  COURSE,
  ORG_ADMIN,
  CONTENT_MANAGEMENT_COLLECTION,
}

export interface TimelineUser {
  firstName: string;
  lastName: string;
  fullName: string;
  userNameForURL: string;
  initials: string;
}

export interface OrgLevelUser extends User {
  email: string;
  enrolledCoursesCount: number;
  externalId: string;
  canRemoveFromInstitution?: boolean;
}

interface OrgAdminUser extends User {
  email: string
}

export interface OrgAdmin {
  id: number
  adminRole: string
  institutionId: number
  user: OrgAdminUser
}

export interface CommunityCompletionUser extends BaseUser {
  reportId?: number
}
