import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import t from 'react-translate';

// Components
import NvFormDropdown from 'shared/components/inputs/nv-form-dropdown';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import { NvDropdownOption } from 'shared/components/inputs/nv-dropdown';

// Styles
import { info, gray2, gray4, gray7, gray6 } from 'styles/global_defaults/colors';
import { halfSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { textLargeFontSize } from 'styles/global_defaults/fonts';


type OneSelectorProps = {
  checkboxName: string,
  name: string,
  eventValue?: string,
  dependentName?: string,
  label: string,
  items?: NvDropdownOption[],
  disableOn?: string,
};

const styles = (name) => css`
  &.${name} {
    background-color: ${gray7};
    border-radius: 10px;
    min-height: ${tripleSpacing}px;
    .selected-values {
      display: inline-block;
      margin-left: ${halfSpacing}px;
    }

    .dropdowns {
      visibility: hidden;
      display: flex;
      align-self: center;
      width: 0px;
      .options {
        flex: 5;
        .title {
          font-size: ${textLargeFontSize}px;
        }
      }
    }

    .info {
      flex: 1 0 auto;
      text-align: right;
      color: ${gray2};
      vertical-align: middle;
      line-height: 24px;
    }

    &.on {
      background-color: ${info};

      .dropdowns {
        width: 100%;
        visibility: visible;
      }
    }

    &.disabled {
      background-color: ${gray6};
      display: flex;

      .info {
        color: ${gray4};
        display: inherit;
        justify-content: flex-end;
        align-items: center;
      }

      .dropdowns {
        display: none;
      }
    }
  }
`;

/**
 * A generic component for course/collections.
 */

const OneSelector = ({
  checkboxName,
  name,
  eventValue,
  dependentName,
  label,
  items,
  disableOn,
}: OneSelectorProps) => {
  // The current activity need to be omitted only in the case for triggers
  const { setValue, watch } = useFormContext();

  const optionAlreadySelected = watch(dependentName);
  const fieldValue = eventValue ? watch('event') : watch(checkboxName);
  const isEnabled = eventValue ? fieldValue === eventValue : fieldValue;
  const [localItems, setLocalItems] = useState([...items]);

  // Checks if the option was selected in the "OneSelector" with the name obtained
  // in the property "dependentName".
  // In case it's selected in the other selector, that option won't be available in the current one.
  useEffect(() => {
    setLocalItems(localItems.map(item => {
      const newItem = { ...item };
      if (newItem.type === 'text') {
        if (newItem.value === optionAlreadySelected?.value) {
          newItem.disabled = true;
        } else {
          newItem.disabled = false;
        }
      }
      return newItem;
    }));
  }, [optionAlreadySelected]);

  let dependentFieldChecked;
  if (disableOn) {
    dependentFieldChecked = watch(disableOn);
  }

  // When the checkbox is unselected, the value of the dropdown menu should be cleaned too.
  useEffect(() => {
    if (!fieldValue) {
      setValue(name, null, { shouldValidate: true, shouldDirty: true });
    }
  }, [fieldValue]);

  return (
    <div css={styles(name)} className={`d-flex px-4 py-2 mb-4 ${name} ${isEnabled ? 'on' : ''} ${dependentFieldChecked && 'disabled'}`}>
      <div className='d-flex w-100 mr-2 checkbox-wrapper'>
        <NvCheckbox
          withForm
          name={checkboxName}
          label={label}
          labelClassName='text-large-regular pl-6 mr-2 w-100'
          className='w-100 align-self-center'
          disabled={dependentFieldChecked}
        />
        <div className='dropdowns'>
          <NvFormDropdown
            maxWidth100
            name={name}
            className='options ml-2'
            items={localItems}
            title={t.JOURNEY_COMMUNICATIONS.SELECTOR.PLEASE_SELECT()}
          />
        </div>
      </div>
    </div>
  );
};

OneSelector.defaultProps = {
  eventValue: null,
  dependentName: '',
};

export default OneSelector;
