/* @ngInject */
export default function NvPointsDecayPopover(
  moment,
  nvUtil,
  CurrentCourseManager,
  _,
) {
  return {
    scope: {
      decayEnabled: '=',
      releaseDate: '=',
      isCompleted: '=?',
      totalPoints: '=',
      pointsAfterExpiration: '=?',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
      vm.moment = moment;
      vm.nvUtil = nvUtil;
      vm.CurrentCourseManager = CurrentCourseManager;

      let completedMoment;
      const releaseMoment = moment(this.releaseDate);
      const currentMoment = moment();

      vm.releasedThisWeek = currentMoment.diff(releaseMoment, 'days') < 7;
      vm.releasedLastWeek = currentMoment.diff(releaseMoment, 'days') >= 7 && currentMoment.diff(releaseMoment, 'days') < 14;

      if (!vm.pointsAfterExpiration) {
        vm.pointsAfterExpiration = 0;
      }
    },
    controllerAs: 'vm',
    bindToController: true,
    transclude: true,
    templateUrl: 'shared/templates/nv-points-decay-popover.html',
  };
}
