import { schema } from 'normalizr';
import { AxiosResponse } from 'axios';

import { ActivityType, ActivityKey, MinimalActivity } from 'redux/schemas/models/activity';
import {
  Communication,
  CommunicationFilter,
  CommunicationType,
  FilterType,
  OwnerType,
  Recipient,
  ScheduledForType,
  ScheduledTimeLineLoadingType,
} from 'redux/schemas/models/course-communication';
import { TriggerType } from 'communications/course_communications/contexts/communication-context';
import { ExerciseSkillsRatingActivitiesNormalized, MinimalExerciseSkillsRatingActivity } from 'redux/schemas/models/exercise-skills-rating';
import { LecturePage, LecturePageKey, LecturePagesNormalized, MinimalLecturePage } from 'redux/schemas/models/lecture-page';
import { MinimalExercise, TimelineExercisesNormalized } from 'redux/schemas/models/exercise';
import { MinimalExternalTool, TimelineExternalToolsNormalized } from 'redux/schemas/models/external-tool';
import { MinimalGroupTeamSet, TimelineGroupTeamSetsNormalized } from 'redux/schemas/models/group-team-set';
import { MinimalLectureVideo, TimelineLectureVideosNormalized } from 'redux/schemas/models/lecture-video';
import { MinimalLiveSession, TimelineLiveSessionsNormalized } from 'redux/schemas/models/live-session';
import { MinimalPeerEvaluation, TimelinePeerEvaluationsNormalized } from 'redux/schemas/models/peer-evaluation';
import { MinimalPoll, PollsNormalizedDictionary } from 'redux/schemas/models/poll';
import { MinimalPost, TimelinePostsNormalized } from 'redux/schemas/models/timeline-post';
import { MinimalProfileRequirement, ProfileRequirementsNormalized } from 'redux/schemas/models/profile-requirement';
import { MinimalQuiz, TimelineQuizzesNormalized } from 'redux/schemas/models/quiz';
import { MinimalSurvey, TimelineSurveysNormalized } from 'redux/schemas/models/survey';
import { MinimalTeamDiscussion, TeamDiscussionsNormalized } from 'redux/schemas/models/team-discussion';
import { MinimalTeamSet, TimelineTeamSetsNormalized } from 'redux/schemas/models/team-set';
import { MinimalTimedQuiz, TimelineTimedQuizzesNormalized } from 'redux/schemas/models/timed-quiz';
import { MinimalVideoPracticeFeedbackActivity, VideoPracticeFeedbackActivitiesNormalized } from '../models/video-practice-feedback';
import { VideoPracticeActivitiesNormalized, MinimalVideoPracticeActivity } from '../models/video-practice';
import { MinimalVideoPracticeSkillsRating, VideoPracticeSkillsRatingNormalized } from '../models/video-practice-skills-feedback';
import { MinimalProgressiveQuizActivity, ProgressiveQuizzesNormalized } from '../models/progressive-quiz';

const CommunicationSchema = new schema.Entity<Communication>('communications');
export const CommunicationListSchema = new schema.Array(CommunicationSchema);

export type FetchCourseActivitiesParams = {
  catalogId: string,
};

export interface CourseActivitiesResponse {
  [ActivityKey.CUSTOM_QUESTIONS]: MinimalPeerEvaluation[]
  [ActivityKey.EXERCISE_SKILLS_RATING]: MinimalExerciseSkillsRatingActivity[]
  [ActivityKey.EXERCISES]: MinimalExercise[]
  [ActivityKey.EXTERNAL_TOOLS]: MinimalExternalTool[]
  [ActivityKey.GROUP_TEAM_SET]: MinimalGroupTeamSet[]
  [ActivityKey.LECTURE_VIDEOS]: MinimalLectureVideo[]
  [ActivityKey.LIVE_SESSION]: MinimalLiveSession[]
  [ActivityKey.POLLS]: MinimalPoll[]
  [ActivityKey.PROFILE_REQUIREMENT]: MinimalProfileRequirement[]
  [ActivityKey.PROGRESSIVE_QUIZZES]: MinimalProgressiveQuizActivity[]
  [ActivityKey.QUIZZES]: MinimalQuiz[]
  [ActivityKey.SURVEYS]: MinimalSurvey[]
  [ActivityKey.TEAM_SET]: MinimalTeamSet[]
  [ActivityKey.TIMED_QUIZZES]: MinimalTimedQuiz[]
  [ActivityKey.TOPICS]: MinimalPost[]
  [ActivityKey.VIDEO_PRACTICE_FEEDBACK]: MinimalVideoPracticeFeedbackActivity[]
  [ActivityKey.VIDEO_PRACTICE_SKILLS_FEEDBACK]: MinimalExerciseSkillsRatingActivity[]
  [ActivityKey.VIDEO_PRACTICES]: MinimalVideoPracticeActivity[]
  totalPoints: number
}

export type BaseCourseActivities = {
  [key in keyof typeof ActivityKey]?: MinimalActivity
};

export interface CourseActivities extends BaseCourseActivities {
  [ActivityKey.CUSTOM_QUESTIONS]?: MinimalPeerEvaluation[]
  [ActivityKey.EXERCISE_SKILLS_RATING]?: MinimalExerciseSkillsRatingActivity[]
  [ActivityKey.EXERCISES]?: MinimalExercise[]
  [ActivityKey.EXTERNAL_TOOLS]?: MinimalExternalTool[]
  [ActivityKey.GROUP_TEAM_SET]?: MinimalGroupTeamSet[]
  [ActivityKey.LECTURE_VIDEOS]?: MinimalLectureVideo[]
  [ActivityKey.LIVE_SESSION]?: MinimalLiveSession[]
  [ActivityKey.POLLS]?: MinimalPoll[]
  [ActivityKey.PROFILE_REQUIREMENT]?: MinimalProfileRequirement[]
  [ActivityKey.PROGRESSIVE_QUIZZES]?: MinimalProgressiveQuizActivity[]
  [ActivityKey.QUIZZES]?: MinimalQuiz[]
  [ActivityKey.SURVEYS]?: MinimalSurvey[]
  [ActivityKey.TEAM_DISCUSSION]?: MinimalTeamDiscussion[]
  [ActivityKey.TEAM_SET]?: MinimalTeamSet[]
  [ActivityKey.TIMED_QUIZZES]?: MinimalTimedQuiz[]
  [ActivityKey.TOPICS]?: MinimalPost[]
  [ActivityKey.VIDEO_PRACTICE_FEEDBACK]?: MinimalVideoPracticeFeedbackActivity[]
  [ActivityKey.VIDEO_PRACTICE_SKILLS_FEEDBACK]?: MinimalExerciseSkillsRatingActivity[]
  [ActivityKey.VIDEO_PRACTICES]?: MinimalVideoPracticeActivity[]
}

export const CourseActivitiesSchema = new schema.Object({
  [ActivityKey.CUSTOM_QUESTIONS]: new schema.Array(new schema.Entity<MinimalPeerEvaluation>('peerEvaluations', {})),
  [ActivityKey.EXERCISE_SKILLS_RATING]: new schema.Array(new schema.Entity<MinimalExerciseSkillsRatingActivity>('exerciseSkillsRatingActivities', {})),
  [ActivityKey.EXERCISES]: new schema.Array(new schema.Entity<MinimalExercise>('exercises', {})),
  [ActivityKey.EXTERNAL_TOOLS]: new schema.Array(new schema.Entity<MinimalExternalTool>('externalTools', {})),
  [ActivityKey.GROUP_TEAM_SET]: new schema.Array(new schema.Entity<MinimalGroupTeamSet>('groupTeamSets', {})),
  [ActivityKey.LECTURE_VIDEOS]: new schema.Array(new schema.Entity<MinimalLectureVideo>('lectureVideos', {})),
  [ActivityKey.LIVE_SESSION]: new schema.Array(new schema.Entity<MinimalLiveSession>('liveSessions', {})),
  [ActivityKey.POLLS]: new schema.Array(new schema.Entity<MinimalPoll>('polls', {})),
  [ActivityKey.PROFILE_REQUIREMENT]: new schema.Array(new schema.Entity<MinimalProfileRequirement>('profileRequirements', {})),
  [ActivityKey.PROGRESSIVE_QUIZZES]: new schema.Array(new schema.Entity<MinimalProgressiveQuizActivity>('progressiveQuizzes', {})),
  [ActivityKey.QUIZZES]: new schema.Array(new schema.Entity<MinimalQuiz>('quizzes', {})),
  [ActivityKey.SURVEYS]: new schema.Array(new schema.Entity<MinimalSurvey>('surveys', {})),
  [ActivityKey.TEAM_DISCUSSION]: new schema.Array(new schema.Entity<MinimalTeamDiscussion>('teamDiscussions', {})),
  [ActivityKey.TEAM_SET]: new schema.Array(new schema.Entity<MinimalTeamSet>('teamSets', {})),
  [ActivityKey.TIMED_QUIZZES]: new schema.Array(new schema.Entity<MinimalTimedQuiz>('timedQuizzes', {})),
  [ActivityKey.TOPICS]: new schema.Array(new schema.Entity<MinimalPost>('posts', {})),
  [ActivityKey.VIDEO_PRACTICE_FEEDBACK]: new schema.Array(new schema.Entity<MinimalVideoPracticeFeedbackActivity>('practiceFeedbackActivities', {})),
  [ActivityKey.VIDEO_PRACTICE_SKILLS_FEEDBACK]: new schema.Array(new schema.Entity<MinimalVideoPracticeSkillsRating>('videoPracticeSkillsRating', {})),
  [ActivityKey.VIDEO_PRACTICES]: new schema.Array(new schema.Entity<MinimalVideoPracticeActivity>('practiceActivities', {})),
});

export interface CourseActivitiesEntities {
  [ActivityKey.CUSTOM_QUESTIONS]?: TimelinePeerEvaluationsNormalized
  [ActivityKey.EXERCISE_SKILLS_RATING]?: ExerciseSkillsRatingActivitiesNormalized
  [ActivityKey.EXERCISES]?: TimelineExercisesNormalized
  [ActivityKey.EXTERNAL_TOOLS]?: TimelineExternalToolsNormalized
  [ActivityKey.GROUP_TEAM_SET]?: TimelineGroupTeamSetsNormalized
  [ActivityKey.LECTURE_VIDEOS]?: TimelineLectureVideosNormalized
  [ActivityKey.LIVE_SESSION]?: TimelineLiveSessionsNormalized
  [ActivityKey.POLLS]?: PollsNormalizedDictionary
  [ActivityKey.PROFILE_REQUIREMENT]?: ProfileRequirementsNormalized
  [ActivityKey.PROGRESSIVE_QUIZZES]?: ProgressiveQuizzesNormalized
  [ActivityKey.QUIZZES]?: TimelineQuizzesNormalized
  [ActivityKey.SURVEYS]?: TimelineSurveysNormalized
  [ActivityKey.TEAM_DISCUSSION]?: TeamDiscussionsNormalized
  [ActivityKey.TEAM_SET]?: TimelineTeamSetsNormalized
  [ActivityKey.TIMED_QUIZZES]?: TimelineTimedQuizzesNormalized
  [ActivityKey.TOPICS]?: TimelinePostsNormalized
  [ActivityKey.VIDEO_PRACTICE_FEEDBACK]?: VideoPracticeFeedbackActivitiesNormalized
  [ActivityKey.VIDEO_PRACTICE_SKILLS_FEEDBACK]?: VideoPracticeSkillsRatingNormalized
  [ActivityKey.VIDEO_PRACTICES]?: VideoPracticeActivitiesNormalized
}

export interface CourseActivitiesNormalizedResult {
  [ActivityKey.CUSTOM_QUESTIONS]: number[]
  [ActivityKey.EXERCISE_SKILLS_RATING]: number[]
  [ActivityKey.EXERCISES]: number[]
  [ActivityKey.EXTERNAL_TOOLS]: number[]
  [ActivityKey.GROUP_TEAM_SET]: number[]
  [ActivityKey.LECTURE_VIDEOS]: number[]
  [ActivityKey.LIVE_SESSION]: number[]
  [ActivityKey.POLLS]: number[]
  [ActivityKey.PROFILE_REQUIREMENT]: number[]
  [ActivityKey.QUIZZES]: number[]
  [ActivityKey.SURVEYS]: number[]
  [ActivityKey.TEAM_DISCUSSION]: number[]
  [ActivityKey.TEAM_SET]: number[]
  [ActivityKey.TIMED_QUIZZES]: number[]
  [ActivityKey.TOPICS]: number[]
  [ActivityKey.VIDEO_PRACTICE_FEEDBACK]: number[]
  [ActivityKey.VIDEO_PRACTICES]: number[]
}

export type FetchLecturePageCommunicationsParams = {
  catalogId: string,
  lecturePageId: number,
  showCommunications: boolean | undefined,
};

export interface LecturePageCommunicationsResponse {
  lecturePages: LecturePage
}

interface SendEmailParams {
  subject: string
  body: string
}

export interface BaseCommunicationParams {
  catalogId: string
  communicationType: CommunicationType
  submitted: boolean
  ownerId: number
  ownerType: OwnerType
  scheduledFor?: ScheduledForType
  xDays?: number
  recipients: Recipient[]
  communication: SendEmailParams
  enablePushNotification: boolean
  pushNotificationBody?: string
  filters?: CommunicationFilter
  callback?: (res: any) => void
  event?: CommunicationFilter
  lecturePageId?: number
  triggerType?: TriggerType
  isJourney?: boolean
}

export interface UpdateCommunicationParams extends BaseCommunicationParams {
  communicationId: number
}

export interface CreateCommunicationParams extends BaseCommunicationParams {}

export type CommunicationParams = UpdateCommunicationParams | CreateCommunicationParams;

export type DeleteCommunicationParams = {
  catalogId: string,
  communicationId: number,
  lecturePageId: number,
  communicationType?: CommunicationType,
};

export interface DeleteCommunicationResponse {
  result: boolean,
}

export interface GetEmailPreviewParams {
  catalogId: string
  email: {
    subject: string
    body: string
  }
  callback(requestPromise: Promise<AxiosResponse<any>>): void,
}

export type FetchDraftCommunicationsParams = {
  catalogId: string,
};

export type FetchScheduledDueDatesParams = {
  catalogId: string,
};

export interface ScheduledDueDatesResponse {
  [ActivityKey.CUSTOM_QUESTIONS]: MinimalPeerEvaluation[]
  [ActivityKey.EXERCISE_SKILLS_RATING]: MinimalExerciseSkillsRatingActivity[]
  [ActivityKey.EXERCISES]: MinimalExercise[]
  [ActivityKey.EXTERNAL_TOOLS]: MinimalExternalTool[]
  [ActivityKey.GROUP_TEAM_SET]: MinimalGroupTeamSet[]
  [ActivityKey.LECTURE_VIDEOS]: MinimalLectureVideo[]
  [ActivityKey.LIVE_SESSION]: MinimalLiveSession[]
  [ActivityKey.POLLS]: MinimalPoll[]
  [ActivityKey.QUIZZES]: MinimalQuiz[]
  [ActivityKey.SURVEYS]: MinimalSurvey[]
  [ActivityKey.TEAM_SET]: MinimalTeamSet[]
  [ActivityKey.TIMED_QUIZZES]: MinimalTimedQuiz[]
  [ActivityKey.TOPICS]: MinimalPost[]
  [LecturePageKey.LECTURE]: MinimalLecturePage[]
}

export const ScheduledDueDatesSchema = new schema.Object({
  [ActivityKey.CUSTOM_QUESTIONS]: new schema.Array(new schema.Entity<MinimalPeerEvaluation>('peerEvaluations', {})),
  [ActivityKey.EXERCISE_SKILLS_RATING]: new schema.Array(new schema.Entity<MinimalExercise>('exercises', {})),
  [ActivityKey.EXERCISES]: new schema.Array(new schema.Entity<MinimalExerciseSkillsRatingActivity>('exerciseSkillsRatingActivities', {})),
  [ActivityKey.EXTERNAL_TOOLS]: new schema.Array(new schema.Entity<MinimalExternalTool>('externalTools', {})),
  [ActivityKey.GROUP_TEAM_SET]: new schema.Array(new schema.Entity<MinimalGroupTeamSet>('groupTeamSets', {})),
  [ActivityKey.LECTURE_VIDEOS]: new schema.Array(new schema.Entity<MinimalLectureVideo>('lectureVideos', {})),
  [ActivityKey.LIVE_SESSION]: new schema.Array(new schema.Entity<MinimalLiveSession>('liveSessions', {})),
  [ActivityKey.POLLS]: new schema.Array(new schema.Entity<MinimalPoll>('polls', {})),
  [ActivityKey.QUIZZES]: new schema.Array(new schema.Entity<MinimalQuiz>('quizzes', {})),
  [ActivityKey.SURVEYS]: new schema.Array(new schema.Entity<MinimalSurvey>('surveys', {})),
  [ActivityKey.TEAM_SET]: new schema.Array(new schema.Entity<MinimalTeamSet>('teamSets', {})),
  [ActivityKey.TIMED_QUIZZES]: new schema.Array(new schema.Entity<MinimalTimedQuiz>('timedQuizzes', {})),
  [ActivityKey.TOPICS]: new schema.Array(new schema.Entity<MinimalPost>('posts', {})),
  [LecturePageKey.LECTURE]: new schema.Array(new schema.Entity<MinimalLecturePage>('lecturePages', {})),
});

export interface ScheduledDueDatesEntities {
  [ActivityKey.CUSTOM_QUESTIONS]?: TimelinePeerEvaluationsNormalized
  [ActivityKey.EXERCISE_SKILLS_RATING]?: ExerciseSkillsRatingActivitiesNormalized
  [ActivityKey.EXERCISES]?: TimelineExercisesNormalized
  [ActivityKey.EXTERNAL_TOOLS]?: TimelineExternalToolsNormalized
  [ActivityKey.GROUP_TEAM_SET]?: TimelineGroupTeamSetsNormalized
  [ActivityKey.LECTURE_VIDEOS]?: TimelineLectureVideosNormalized
  [ActivityKey.LIVE_SESSION]?: TimelineLiveSessionsNormalized
  [ActivityKey.POLLS]?: PollsNormalizedDictionary
  [ActivityKey.QUIZZES]?: TimelineQuizzesNormalized
  [ActivityKey.SURVEYS]?: TimelineSurveysNormalized
  [ActivityKey.TEAM_SET]?: TimelineTeamSetsNormalized
  [ActivityKey.TIMED_QUIZZES]?: TimelineTimedQuizzesNormalized
  [ActivityKey.TOPICS]?: TimelinePostsNormalized
  [LecturePageKey.LECTURE]?: LecturePagesNormalized
}

export interface ScheduledDueDatesNormalizedResult {
  [ActivityKey.CUSTOM_QUESTIONS]: number[]
  [ActivityKey.EXERCISE_SKILLS_RATING]: number[]
  [ActivityKey.EXERCISES]: number[]
  [ActivityKey.EXTERNAL_TOOLS]: number[]
  [ActivityKey.GROUP_TEAM_SET]: number[]
  [ActivityKey.LECTURE_VIDEOS]: number[]
  [ActivityKey.LIVE_SESSION]: number[]
  [ActivityKey.POLLS]: number[]
  [ActivityKey.QUIZZES]: number[]
  [ActivityKey.SURVEYS]: number[]
  [ActivityKey.TEAM_SET]: number[]
  [ActivityKey.TIMED_QUIZZES]: number[]
  [ActivityKey.TOPICS]: number[]
  [LecturePageKey.LECTURE]: number[]
}

export type FetchScheduledCommunicationsParams = {
  catalogId: string,
  startTime: string,
  display: ScheduledTimeLineLoadingType,
  page: number,
  showCommunications: boolean,
};

export type FetchAutomatedCommunicationsParams = {
  catalogId: string,
  id: number,
};

export interface ScheduledCommunicationsResponse {
  communications: Communication[],
  count: number,
  perPage: number,
}

export type FetchFilteredCommunicationsParams = {
  catalogId: string,
  filterBy: FilterType,
  page: number,
};

export interface FilteredCommunicationsResponse {
  communications: Communication[],
  count: number,
  perPage: number,
}

export type HidePastScheduledCommunicationsParams = {
  startTime: string,
};
