/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prefer-destructuring */
import React from 'react';
import axios from 'axios';
import t from 'react-translate';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { css } from '@emotion/react';
import { gray2, gray5, lightYellow } from 'styles/global_defaults/colors';
import {
  doubleSpacing,
  halfSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import { useDispatch, useSelector } from 'react-redux';
import { useOnScrollEndReach } from 'shared/hooks/use-infinite-scroll';
import { AngularServicesContext } from 'react-app';
import AhoCorasick from 'libs/aho-corasick';

import { setSearchInSelectedOptionIdx } from 'redux/reducers/content-search';
import SearchInSelect from './search-in-select';
import RecentSearch from './recent-search';
import {
  AcTries,
  filterSnippets,
  getFieldsScore,
  highlightMatches,
  SearchResultData,
  TextField,
  truncateFirstSnippet,
} from './content-search-utils';

const PAGE_SIZE = 30;

interface ResultPage {
  count: number;
  lecturePages: SearchResultData[];
}

export const topLabelStyle = css`
  font-size: 12px;
  font-weight: 600;
  color: ${gray2};
  margin-bottom: ${standardSpacing}px;
  display: inline-block;
`;

const resultPlaceholderContainerStyle = css`
  display: flex;
  flex-direction: column;

  .bar {
    height: ${quarterSpacing}px;
    background-color: ${gray5};
    margin-bottom: ${threeQuartersSpacing}px;
  }

  .bar:last-child {
    margin-bottom: 0;
  }

  .bar.full {
    width: 100%;
  }

  .bar.half {
    width: 50%;
  }
`;

const scrollableContainerStyle = css`
  padding: ${standardSpacing}px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
`;

const resultContainerStyle = css`
  display: flex;
  flex-direction: column;
  border-color: ${gray5};
  border-style: solid;
  border-width: 1px 0 0 0;
  padding: ${standardSpacing}px ${halfSpacing}px;

  &:last-child {
    border-bottom-width: 1px;
  }

  .result-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .highlight {
      background: ${lightYellow};
    }
  }

  .title {
    font-weight: 400;
    color: ${gray2};
    font-size: 12px;
  }

  .result-info {
    margin-bottom: ${halfSpacing}px;

    i {
      display: inline;
      margin: 0 7px;
      vertical-align: middle;
    }

    img {
      width: ${standardSpacing}px;
      height: ${standardSpacing}px;
      margin-right: ${halfSpacing}px;
    }
  }
`;

const endOfResultTextStyle = css`
  font-size: 12px;
  font-weight: 400;
  color: ${gray2};
`;

const notFoundContainerStyle = css`
  padding-top: ${doubleSpacing}px;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 14px;
  }
`;

interface ContentSearchBodyProps {
  searchString: string;
}

/* @ngInject */
export default function ContentSearchPanelBody(props: ContentSearchBodyProps) {
  const angularServices = React.useContext(AngularServicesContext);
  const currentCourseId = useSelector((state) => state.app.currentCourseId);
  const searchInSelectedOptionIdx = useSelector((state) => state.app.searchInSelectedOptionIdx);
  const dispatch = useDispatch();

  const courseToSearchIn = currentCourseId && searchInSelectedOptionIdx === 0 ? currentCourseId : null;

  const scrollableContainerRef = React.useRef<HTMLDivElement>();

  const searchIsValid = props.searchString !== '';
  return (
    <div ref={scrollableContainerRef} css={scrollableContainerStyle}>
      {angularServices.$state.params.catalogId && currentCourseId && (
        <>
          <span className='mb-2' css={topLabelStyle}>
            {t.LHS.CONTENT_SEARCH.I_WANT_TO_SEARCH_IN()}
          </span>
          <div className='mb-4'>
            <SearchInSelect
              selectedOptionIndex={searchInSelectedOptionIdx}
              onSelectOption={(id) => {
                dispatch(setSearchInSelectedOptionIdx(id));
              }}
              catalogId={angularServices.$state.params.catalogId.toLowerCase()}
            />
          </div>
        </>
      )}
      {searchIsValid && (
        <ContentSearchResultList
          key={`${props.searchString}-${courseToSearchIn}`}
          searchString={props.searchString}
          courseToSearchIn={courseToSearchIn}
          scrollableContainerRef={scrollableContainerRef}
        />
      )}
      <RecentSearch
        css={css`
          /**
           * hide the recent search UI when the a list of results is being shown so that
           * the data for this component is fetched in the background before displaying
           */
          display: ${searchIsValid && 'none'};
        `}
      />
    </div>
  );
}

interface ContentSearchResultListProps {
  searchString: string;
  courseToSearchIn: number | null;
  scrollableContainerRef: React.MutableRefObject<HTMLDivElement>;
}

function ContentSearchResultList(props: ContentSearchResultListProps) {
  const queryClient = useQueryClient();
  const [resultListWidth, setResultListWidth] = React.useState(0);

  const acTries = React.useMemo(() => {
    const lowercaseSearch = props.searchString.toLowerCase();
    // split by special characters used by the search engine including spaces. Keywords with less than 3 chars are ignored
    const keywords = lowercaseSearch.split(/([ '()|\-!@~"/^$\\><&=?])/).filter((keyword) => keyword.length >= 3);
    const acKeywords = new AhoCorasick(keywords);
    const acSearch = new AhoCorasick([lowercaseSearch]);
    return { acKeywords, acSearch };
  }, [props.searchString]);

  const { fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, data } = useInfiniteQuery(
    ['content_search', props.searchString, props.courseToSearchIn],
    ({ pageParam = 0 }) => axios
      .post('content/search.json', null, {
        params: {
          query_term: props.searchString,
          page_size: PAGE_SIZE,
          page: pageParam + 1, // convert from 0 based index
          ...(props.courseToSearchIn != null && { course_id: props.courseToSearchIn }),
        },
      })
      .then((response) => response.data.result as ResultPage),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => (lastPage.count > PAGE_SIZE * allPages.length ? allPages.length : undefined),
      onSuccess: () => {
        queryClient.invalidateQueries('recent-content-search');
      },
    },
  );

  useOnScrollEndReach(
    props.scrollableContainerRef,
    () => {
      if (!isFetching && hasNextPage) {
        fetchNextPage();
      }
    },
    {
      padding: 50,
      disabled: isFetching,
    },
  );

  if (isFetching && !isFetchingNextPage) {
    return (
      <>
        <span css={topLabelStyle}>{t.LHS.CONTENT_SEARCH.LOADING_RESULT_TEXT()}</span>
        <SearchResultPlaceholder />
      </>
    );
  }
  const resultCount = (data.pages as any).lastItem.count;
  const isEndReached = data.pages.length * PAGE_SIZE >= resultCount;

  if (resultCount === 0) {
    return (
      <div css={notFoundContainerStyle}>
        <i className='icon icon-read icon-ultra-large gray-5' />
        <span className='gray-3 mt-5 bold'>{t.LHS.CONTENT_SEARCH.RESULTS_NOT_FOUND()}</span>
      </div>
    );
  }

  // flatten nested arrays
  const results = [].concat(...data.pages.map((page) => page.lecturePages)) as SearchResultData[];

  return (
    <>
      <span css={topLabelStyle}>{t.LHS.CONTENT_SEARCH.SEARCH_RESULTS_TOP_LABEL(resultCount)}</span>
      <div
        ref={(ref) => {
          if (ref) {
            setResultListWidth(ref.getBoundingClientRect().width);
          }
        }}
      >
        {results.map((result) => (
          <SearchResult
            acTries={acTries}
            key={result.id}
            resultData={result}
            includeCourse={!props.courseToSearchIn}
            containerWidth={resultListWidth}
          />
        ))}
        {isFetchingNextPage && <SearchResultPlaceholder className='mt-1' />}
      </div>
      {isEndReached && (
        <div className='mt-3' css={endOfResultTextStyle}>
          {t.LHS.CONTENT_SEARCH.END_OF_RESULTS()}
        </div>
      )}
    </>
  );
}

function SearchResultPlaceholder(props: React.ComponentProps<'div'>) {
  return (
    <div css={resultPlaceholderContainerStyle} {...props}>
      <div className='bar full' />
      <div className='bar full' />
      <div className='bar half' />
    </div>
  );
}

function SearchResult(props: {
  resultData: SearchResultData;
  includeCourse: boolean;
  containerWidth: number;
  acTries: AcTries;
}) {
  const defaultImgStyle = css`
    width: ${standardSpacing}px;
    height: ${standardSpacing}px;
    margin-right: ${halfSpacing}px;
    background-color: ${props.resultData.course.headerColor}
  `;
  const angularServices = React.useContext(AngularServicesContext);
  // weight all the fields
  const { snippetsByField, scoreByField, matchResultsByField } = React.useMemo(
    () => getFieldsScore(props.acTries, props.resultData),
    [props.acTries, props.resultData],
  );

  let highestScore = -Infinity;
  let highestScoreField: TextField;

  scoreByField.forEach((score, key) => {
    if (score > highestScore) {
      highestScore = score;
      highestScoreField = key;
    }
  });

  // fallback. If the search failed on the FE side
  if (highestScore === 0) {
    highestScoreField = 'title';
  }

  const matchedTextHighlighted = React.useMemo(() => {
    let matchingResults = matchResultsByField.get(highestScoreField);
    let snippets = snippetsByField.get(highestScoreField);

    // this will prevent a crash for filtering and truncating results in case that no match was found
    if (highestScore > 0) {
      [matchingResults, snippets] = filterSnippets(matchingResults, snippets);
      [matchingResults, snippets] = truncateFirstSnippet(matchingResults, snippets, props.containerWidth);
    }

    return highlightMatches(matchingResults, snippets);
  }, [highestScore, highestScoreField, matchResultsByField, props.containerWidth, snippetsByField]);

  return (
    <div css={resultContainerStyle}>
      <div className='result-info d-flex'>
        {props.includeCourse && (
          <>
            {props.resultData.course.thumbnail !== '/assets/brand/temp.png' ? (
              <img src={props.resultData.course.thumbnail} alt='' />
            ) : (<div css={defaultImgStyle} />)}

            <span className='title'>{props.resultData.course.name}</span>
            <i className='icon icon-xss-smallest icon-arrow-right gray-3' />
          </>
        )}

        <span className='title'>{props.resultData.title}</span>
      </div>
      <a
        className='result-text'
        href={angularServices.$state.href('lecture-page', {
          catalogId: props.resultData.course.catalogId,
          id: props.resultData.id,
        })}
      >
        {matchedTextHighlighted}
      </a>
    </div>
  );
}
