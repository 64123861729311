import React, { useContext } from 'react';
import t from 'react-translate';

// Angular
import { AngularServicesContext } from 'react-app';

// Styles
import { css } from '@emotion/react';

// Form
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

// Redux
import { useAppDispatch } from 'redux/store';
import { doSignIn } from 'redux/actions/sign-in';
import { LoginParams } from 'redux/schemas/api/sign-in';
import { UserLogin } from 'redux/schemas/models/sign-in';
import { Course } from 'redux/schemas/models/course';

// Components
import { Button } from 'react-bootstrap';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import OrDivider from './nv-or-divider';
import ForgotPassword from './nv-forgot-password';
import OauthProviders from './nv-oauth-providers';
import SignInError from './nv-sign-in-error';
import NvHeaderText from './nv-header-text';

type Props = {
  canCreateNewAccount?: boolean,
  maxAttempts: number,
  alreadyJoined: boolean,
  course: Course,
  onSuccess: (payload: UserLogin) => void,
  onError?: (error: string) => void,
};

const NoSSOSignIn = (props: Props) => {
  const {
    canCreateNewAccount = false,
    maxAttempts,
    alreadyJoined,
    course,
    onSuccess,
    onError,
  } = props;
  const dispatch = useAppDispatch();

  const [response, setResponse] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [accountLocked, setAccountLocked] = React.useState<boolean>(false);

  const validationSchemaRef = React.useRef(yup.object().shape({
    email: yup.string()
      .min(1, t.VALIDATION.REQUIRED())
      .email(t.VALIDATION.EMAIL()),
    password: yup.string()
      .min(1, t.VALIDATION.REQUIRED()),
  }));

  const methods = useForm({
    mode: 'onChange',
    shouldUnregister: true,
    resolver: yupResolver(validationSchemaRef.current),
  });

  const { handleSubmit, formState } = methods;

  const submit = ({ email, password }) => {
    const user: LoginParams = { email, password };
    setIsLoading(true);
    dispatch(doSignIn(user)).then(({ payload }) => {
      setResponse(payload);
      if (payload?.data?.error || payload?.status === 500) setIsLoading(false);
    });
  };

  const onSuccessLogin = (payload) => {
    setAccountLocked(false);
    onSuccess(payload);
  };

  const styles = css`
    .authentications-header-text {
      margin-bottom: 25px;
    }
    form, .or-divider {
      margin-bottom: 40px;
    }
  `;

  return (
    <React.Fragment>
      <div css={styles} className='authentications-overlay'>
        <div className='authentications-body'>
          <NvHeaderText alreadyJoined={alreadyJoined} course={course} />
          <SignInError
            payload={response}
            maxAttempts={maxAttempts}
            onSuccess={onSuccessLogin}
            onError={onError}
            onAccountLocked={() => setAccountLocked(true)}
          />
          <div className='registration-form'>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(submit)}>
                <NvTextInput
                  required
                  withForm
                  withLabel
                  name='email'
                  className='mb-3'
                  placeholder={t.USERS.REGISTRATION.EMAIL_ADDRESS()}
                  data-qa='email'
                />
                <NvTextInput
                  required
                  withForm
                  withLabel
                  name='password'
                  type='password'
                  placeholder={t.USERS.REGISTRATION.PASSWORD()}
                  data-qa='password'
                />
                <ForgotPassword />
                <Button
                  className='mb-0'
                  disabled={!formState.isValid || accountLocked || isLoading}
                  type='submit'
                  data-qa='sign-in'
                >
                  {isLoading ? t.USERS.REGISTRATION.SIGNING_IN() : t.USERS.REGISTRATION.SIGN_IN()}
                </Button>
              </form>
            </FormProvider>
            <OrDivider />
            <CreateNewAccount show={canCreateNewAccount} />
            <OauthProviders />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

type CreateNewAccountProps = {
  show: boolean,
};

const CreateNewAccount = ({ show }: CreateNewAccountProps) => {
  const { $state } = useContext(AngularServicesContext) || {};
  return (
    show
    && (
      <a
        className='bs4-btn bs4-btn-secondary w-100'
        href={$state.href('users.authentications.sign-up', $state.params)}
        data-qa='create-account'
      >
        {t.USERS.REGISTRATION.CREATE_ACCOUNT()}
      </a>
    )
  );
};

export default NoSSOSignIn;
