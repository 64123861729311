import { css } from '@emotion/react';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import t from 'react-translate';
import LoadingRow from 'shared/components/loading-row';
import { NvResponsiveTable, NvResponsiveTableColumn, ColumnSortings, CacheLookup } from 'shared/components/nv-responsive-table';
import { useScrollLeft } from 'shared/hooks/use-scroll';
import { getLearnerProgressList, getCourseOutlineSections } from 'redux/actions/learner-progress';
import { setActiveOptionMenu } from 'redux/actions/optionsMenu';
import { CompletionTypeFilters, LearnerProgress, SectionSubsection } from 'redux/schemas/models/learner-progress';
import { PagedDataQueryParams } from 'redux/create-action-creators';
import { getCurrentCourse, getCourseAliases } from 'redux/selectors/course';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { RootState, CombinedCourse } from 'redux/schemas';
import { Course } from 'redux/schemas/models/course';
import { useAppDispatch } from 'redux/store';
import { white, gray6, gray7, hexToRgbaString, info, black, teal, gray5 } from 'styles/global_defaults/colors';
import { boldFontWeight, semiBoldFontWeight } from 'styles/global_defaults/fonts';
import { halfSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { NvSearchBar } from 'shared/components/nv-search-bar';
import { NvPopover } from 'shared/components/nv-popover';
import NvTooltip, { TextAlign } from 'shared/components/nv-tooltip';
import CustomizationFlyoutModal from './customization-flyout-modal';
import LearnerProgressTabs, { LearnerProgressTotals, TabTypes } from './learner-progress-tabs';
import LearnerRow from './learner-row';
import { config } from '../../../config/pendo.config.json';


const initialFetchParams: PagedDataQueryParams = {
  searchQuery: '',
  sorting: ['first_name_asc'],
};

const LearnerProgressDashboard = () => {
  const dispatch = useAppDispatch();

  const currentCourse = useSelector<RootState, CombinedCourse>((state) => getCurrentCourse(state)) as Course;
  const institution = useSelector((state) => getCurrentInstitution(state));
  const aliases = useSelector((state) => getCourseAliases(state));
  const allSections: SectionSubsection[] = useSelector((state) => state.models.courseOutlineSections);
  const totals: LearnerProgressTotals = useSelector((state) => state.app.learnerProgressDashboard.learnerCounts);
  const [columnSortings, setColumnSortings] = useState<ColumnSortings>({ 0: true }); // asc sort the Name column by default
  const [pagedFetchParams, setPagedFetchParams] = useState<PagedDataQueryParams & { sectionIds?: number[] }>(initialFetchParams);
  const [selectedSectionsList, setSelectedSectionsList] = useState<SectionSubsection[]>([]);
  const [showCustomizationFlyout, setShowCustomizationFlyout] = useState<boolean>(false);
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
  const [isSearchInvalid, setIsSearchInvalid] = useState<boolean>(false);
  const [isSearchbarReset, setIsSearchBarReset] = useState<boolean>(false);
  const [countCurrentTab, setCountCurrentTab] = useState<number>(0);

  const totalByType = {
    [TabTypes.TOTAL]: totals.totalLearners,
    [TabTypes.COMPLETED]: totals.completed,
    [TabTypes.INACTIVE_IN_LAST_WEEK]: totals.inactiveInPastSevenDays,
    [TabTypes.IN_PROGRESS]: totals.inProgress,
    [TabTypes.MISSED_DEADLINE]: totals.missedDeadlines,
    [TabTypes.NEVER_ACTIVE]: totals.neverActive,
  };

  const MAX_NUMBER_ROWS_SORTABLE = 1500;

  const styles = css`
    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 1fr;
    height: calc(100vh - 60px);
    .search-progress{
      border-bottom:1px solid ${gray5};
      height:61px;
      margin-bottom: ${isSearchActive ? standardSpacing : 0}px;
      .search-button{
        padding: ${standardSpacing}px;
      }
      .clear-button{
        padding: ${standardSpacing}px;
        margin-left: ${standardSpacing}px;
      }
      .search-progress{
        width:100%;
      }
    }

    .nv-responsive-table {
      .grid {
        min-width: fit-content;
      }

      .header-cell {
        background-color: ${info} !important;
        padding-top: ${standardSpacing}px !important;
        padding-bottom: ${standardSpacing}px !important;
        font-weight: ${boldFontWeight};

        .section-column-header {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          .section-header {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .icon {
          opacity: 1 !important;
          padding-left: ${quarterSpacing}px !important;
        }
        &.disabled{
          .icon {
            opacity: 0.5 !important;
          }
        }

      }

      .learner-name-column-header-cell {
        position: sticky;
        left: 0;
        z-index: 20 !important;
      }

      .last-active-date-column-header-cell {
        position: sticky;
        left: 230px;
        z-index: 20 !important;
      }

      .progress-cell-column-header-cell {
        position: sticky;
        left: 370px;
        z-index: 20 !important;
      }

      .options-column-header-cell {
        position: sticky;
        right: 0;
        padding: 0 !important;
        color: ${black};
        background: ${hexToRgbaString(teal, 0.50)} !important;
        cursor: pointer !important;

        .customize-table {
          display: none;
          width: max-content;
          position: absolute;
          // the width of the header cell
          right: 36px;
          background: ${teal} !important;
          height: 100%;
          top: 0;
          align-items: center;
          font-weight: ${semiBoldFontWeight};
          padding-left: ${halfSpacing}px;

          &:hover {
            display: flex;
          }
        }

        &:hover {
          background: ${teal} !important;

          .customize-table {
            display: flex;
          }
        }
      }

      div[class*='-cell'] {
        background-color: ${white};

        &:hover {
          background: ${gray7};
          .show-on-row-hover {
            visibility: visible;
          }
        }
      }
    }
  `;

  const hoverStyles = css`
    & {
      background: ${gray6} !important;
    }
  `;
  // can't overwrite styles of arrow in the popover, so doing this for now.
  const popoverStyles = css`
    .arrow{
      left: -65% !important;
    }
  `;

  const onTotalCountChange = (total: number) => {
    setCountCurrentTab(total);
  };
  const getStoreLearnerProgressData: CacheLookup<LearnerProgress> = (state: RootState) => {
    if (pagedFetchParams.filters?.filter === TabTypes.COMPLETED) {
      return Object.fromEntries(Object.values(state.models.learnerProgresses)
        .filter(lp => lp.completionStatus === CompletionTypeFilters.AUTO_COMPLETED || lp.completionStatus === CompletionTypeFilters.MANUALLY_COMPLETED)
        .map(({ id }) => [id, state.models.learnerProgresses[id]]));
    }
    if (pagedFetchParams.filters?.filter === TabTypes.IN_PROGRESS) {
      return Object.fromEntries(Object.values(state.models.learnerProgresses)
        .filter(lp => lp.completionStatus === CompletionTypeFilters.NOT_COMPLETED)
        .map(({ id }) => [id, state.models.learnerProgresses[id]]));
    }
    return state.models.learnerProgresses;
  };

  const learnerProgressIDs = useSelector((state) => {
    if (pagedFetchParams.filters?.filter === TabTypes.COMPLETED) {
      return Object.values(state.models.learnerProgresses)
        .filter((lp: LearnerProgress) => lp.completionStatus === CompletionTypeFilters.AUTO_COMPLETED || lp.completionStatus === CompletionTypeFilters.MANUALLY_COMPLETED)
        .map(({ id }: LearnerProgress) => id);
    }
    if (pagedFetchParams.filters?.filter === TabTypes.IN_PROGRESS) {
      return Object.values(state.models.learnerProgresses)
        .filter((lp: LearnerProgress) => (lp.completionStatus === CompletionTypeFilters.NOT_COMPLETED) && lp.lastActivity)
        .map(({ id }: LearnerProgress) => id);
    }
    return state.models.learnerProgresses;
  });
  useEffect(() => {
    setCountCurrentTab(totalByType[TabTypes.TOTAL]);
  }, [totals.totalLearners, isSearchActive]);

  useEffect(() => {
    dispatch(getCourseOutlineSections({ catalogId: currentCourse.catalogId }));
  }, []);

  useEffect(() => {
    const selectedSectionIds = JSON.parse(localStorage.getItem(`learner-progress-dashboard-${currentCourse.catalogId}-section-ids`));
    const sections = [];

    allSections.forEach((section) => {
      if (section.hasLessonChildren) {
        sections.push({ ...section, sortingIndex: section.index });
      }
      sections.push(...section.lectureSubsections.map((subsec) => {
        // We are using this sorting index, because the index of a subsection is relative to its parent section.
        // Meaning the first subsection in section 5 will have an index of 0, so sorting won't work properly.
        // This formula is to give all of the subsections an index which is greater than the parent section index,
        // but less than any next section's index.
        // As an example, if the fourth section (having an index of 3) has three subsections, they'll have
        // indices of 3.25, 3.5, and 3.75
        const sortingIndex = section.index + (subsec.index + 1) / (section.lectureSubsections.length + 1);
        return { ...subsec, parentTitle: section.title, sortingIndex };
      }));
    });

    if (selectedSectionIds) {
      const filteredSections = sections.filter(section => selectedSectionIds.includes(section.id));
      filteredSections.sort((sec1, sec2) => sec1.sortingIndex - sec2.sortingIndex);
      setSelectedSectionsList(filteredSections);
    } else {
      sections.sort((secA, secB) => moment(secB.releaseDate).diff(moment(secA.releaseDate)));
      setSelectedSectionsList(sections.slice(0, 5).sort((sec1, sec2) => sec1.sortingIndex - sec2.sortingIndex));
    }
  }, [allSections, currentCourse.catalogId]);

  const isScrollingHorizontally = useScrollLeft(document.getElementsByClassName('nv-responsive-table').item(0) as HTMLElement);

  let scrollStyles = css``;
  if (isScrollingHorizontally) {
    if (currentCourse.automatedCompletionsEnabled || currentCourse.gamificationEnabled) {
      scrollStyles = css`
        .progress-cell:after, .progress-cell-column-header-cell:after {
          content: '';
          position: absolute;
          height: 100%;
          width: 50px;
          left: 180px; /* This is the width of the Progress Column */
          background: linear-gradient(90deg, rgba(0, 0, 0, 0.05) -10%, transparent 12.5%);
        }
      `;
    } else {
      scrollStyles = css`
        .last-active-date-cell:after, .last-active-date-column-header-cell:after {
          content: '';
          position: absolute;
          height: 100%;
          width: 50px;
          left: 140px; /* This is the width of the Last Active Column */
          background: linear-gradient(90deg, rgba(0, 0, 0, 0.05) -10%, transparent 12.5%);
        }
      `;
    }
  }

  const sortKeys = [
    { asc: 'first_name_asc', desc: 'first_name_desc' },
    { asc: 'last_active_asc', desc: 'last_active_desc' },
  ];

  if (currentCourse.automatedCompletionsEnabled || currentCourse.gamificationEnabled) {
    // This is tracks the Progress column, which is not sortable
    // These values are inaccessible, we're just putting them here so that the array indices align with the columns
    sortKeys.push({ asc: '', desc: '' });
  }

  if (selectedSectionsList.length) {
    selectedSectionsList.forEach((section) => {
      sortKeys.push({ asc: `section_${section.id}_asc`, desc: `section_${section.id}_desc` });
    });
  }

  const columns: NvResponsiveTableColumn[] = [
    {
      name: t.LEARNER_PROGRESS.COLUMN_HEADERS.LEARNER_NAME({ ...aliases.learnersAliases }),
      className: 'learner-name-cell',
      sortable: true,
      gridWidth: '230px',
      headerClassName: 'learner-name-column-header-cell',
      dataQa: columnSortings[0] ? config.pendo.learnerProgress.sorting.learnerNameDesc : config.pendo.learnerProgress.sorting.learnerNameAsc,
    },
    {
      name: t.LEARNER_PROGRESS.COLUMN_HEADERS.LAST_ACTIVE_DATE(),
      className: 'last-active-date-cell',
      sortable: true,
      gridWidth: '140px',
      headerClassName: 'last-active-date-column-header-cell',
      dataQa: columnSortings[1] ? config.pendo.learnerProgress.sorting.lastActiveDateDesc : config.pendo.learnerProgress.sorting.lastActiveDateAsc,
    },
  ];

  if (currentCourse.automatedCompletionsEnabled || currentCourse.gamificationEnabled) {
    columns.push({
      name: t.LEARNER_PROGRESS.COLUMN_HEADERS.PROGRESS(),
      className: 'progress-cell',
      sortable: false,
      gridWidth: '180px',
      headerClassName: 'progress-cell-column-header-cell',
    });
  }

  // Here, add in other columns for sections/subsections,
  // once they are ready
  if (selectedSectionsList.length) {
    const SectionColumnHeader = ({ section }) => {
      const headerRef = useRef(null);
      const parentTitleRef = useRef(null);
      const [hasOverflow, setHasOverflow] = useState(false);

      useEffect(() => {
        setHasOverflow((headerRef.current?.scrollWidth > headerRef.current?.clientWidth) || (parentTitleRef.current?.scrollWidth > parentTitleRef.current?.clientWidth));
      }, [headerRef, parentTitleRef]);

      const tooltipText = section.parentTitle ? `${section.parentTitle}\n${section.title}` : section.title;

      return (
        <div className='w-100'>
          <NvTooltip enabled={hasOverflow} text={tooltipText} textAlign={TextAlign.LEFT} css={css`white-space: pre-line;`}>
            <div>
              <div className='section-column-header'>
                {section.parentTitle && <div className='section-header mb-1' ref={parentTitleRef}>{section.parentTitle}</div>}
                <div className='section-header' ref={headerRef}>{section.title}</div>
              </div>
            </div>
          </NvTooltip>
        </div>
      );
    };

    selectedSectionsList.forEach((section, index) => {
      const columnIndex = index + (currentCourse.automatedCompletionsEnabled || currentCourse.gamificationEnabled ? 3 : 2);
      columns.push({
        name: `colname-${section.id}`,
        content: <SectionColumnHeader section={section} />,
        className: `section-${section.id}-cell`,
        sortable: true,
        disabled: countCurrentTab >= MAX_NUMBER_ROWS_SORTABLE,
        headerTooltip: countCurrentTab >= MAX_NUMBER_ROWS_SORTABLE ? t.LEARNER_PROGRESS.COLUMN_HEADERS.PROGRESS_DISABLED(MAX_NUMBER_ROWS_SORTABLE.toString()) : null,
        gridWidth: 'minmax(120px, 280px)',
        dataQa: columnSortings[columnIndex] ? config.pendo.learnerProgress.sorting.sectionDesc : config.pendo.learnerProgress.sorting.sectionAsc,
      });
    });
  }

  columns.push(
    {
      // This only functions to allow the row to extend the whole length of the screen
      name: '',
      className: 'spacing-cell',
      sortable: false,
      gridWidth: '1fr',
    },
    {
      name: '',
      content: (
        <div className='customize-table-button d-flex w-100 h-100 align-items-center justify-content-center' onClick={() => setShowCustomizationFlyout(true)}>
          <div className='customize-table' data-qa={config.pendo.learnerProgress.customizationFlyout.customizeThisTable} onClick={() => setShowCustomizationFlyout(true)}>
            <div>
              {t.LEARNER_PROGRESS.COLUMN_HEADERS.CUSTOMIZE_THIS_TABLE()}
            </div>
          </div>
          <div className='icon icon-smallest icon-edit' />
        </div>
      ),
      className: 'options-cell',
      sortable: false,
      gridWidth: '36px',
      headerClassName: 'options-column-header-cell',
    },
  );

  const selectTab = (tabType: TabTypes) => {
    setCountCurrentTab(totalByType[tabType]);
    dispatch(setActiveOptionMenu({ menuItem: null, catalogId: currentCourse.catalogId }));

    setPagedFetchParams({
      ...pagedFetchParams,
      filters: {
        ...pagedFetchParams.filters,
        filter: tabType,
      },
    });
  };

  const onHeaderSortingClicked = (colIndex: number) => {
    const newSorting = !columnSortings[colIndex];
    const newSortKey = sortKeys[colIndex][newSorting ? 'asc' : 'desc'];

    const sectionIds = selectedSectionsList.map(section => section.id);

    setColumnSortings({ [colIndex]: newSorting });
    setPagedFetchParams({
      ...pagedFetchParams,
      sorting: [newSortKey],
      sectionIds,
      filters: {
        ...pagedFetchParams.filters,
        filterSections: sectionIds.join(),
      },
    });
  };

  const updateSectionFilters = (formData) => {
    const sections = [];
    Object.values(formData).forEach((section: SectionSubsection) => {
      if (section.hasLessonChildren && section.checked) {
        sections.push({ ...section });
      }
      Object.values(section.lectureSubsections).forEach((subSection) => {
        if (subSection.checked) {
          sections.push({ ...subSection, parentTitle: section.title });
        }
      });
    });

    sections.sort((sec1, sec2) => sec1.index - sec2.index);
    const sectionIds = sections.map(section => section.id);
    localStorage.setItem(`learner-progress-dashboard-${currentCourse.catalogId}-section-ids`, JSON.stringify(sectionIds));

    setSelectedSectionsList(sections);

    setPagedFetchParams({
      ...pagedFetchParams,
      sectionIds,
      filters: {
        ...pagedFetchParams.filters,
        filterSections: sectionIds.join(),
      },
    });
  };

  const onSearch = (value) => {
    if (value?.length < 3) {
      setIsSearchInvalid(true);
      return;
    }
    setIsSearchInvalid(false);
    setIsSearchActive(true);
    setPagedFetchParams({
      ...pagedFetchParams,
      searchQuery: value,
      filters: {
        ...pagedFetchParams.filters,
        filter: TabTypes.TOTAL,
      },
    });
  };
  const resetInvalidSearch = () => {
    if (isSearchInvalid) {
      setIsSearchInvalid(false);
    }
    setIsSearchBarReset(false);
  };
  const cellStyleNames = columns.map(col => col.className);
  const cellStyles = [];
  for (let i = 0; i < cellStyleNames.length; i += 1) {
    const styleName = `.${cellStyleNames[i]}`;

    // select next siblings ...
    for (let j = i + 1; j < cellStyleNames.length; j += 1) {
      let siblingSelector = '';
      if (i < cellStyleNames.length - 1) {
        siblingSelector = ` + .${cellStyleNames.slice(i + 1, j).join(' + .')}`;
      }
      cellStyles.push(css`
        ${styleName}:hover:not(.disabled)${siblingSelector} {
          background: ${gray7};
          .show-on-row-hover {
            visibility: visible;
          }
        }
    `);
    }

    // select previous siblings
    for (let j = i + 1; j <= cellStyleNames.length; j += 1) {
      const list = cellStyleNames.slice(i + 1, j).join(' + .');
      if (list) {
        cellStyles.push(css`
          ${styleName}:has(+ .${list}:hover) {
            background: ${gray7};
            .show-on-row-hover {
              visibility: visible;
            }
          }
        `);
      }
    }
  }

  const rowStyles = cellStyles.map(s => s.styles).join('\n');

  return (
    <div css={css` ${styles} ${rowStyles} ${scrollStyles}`}>
      <NvPopover
        show={isSearchInvalid}
        content={(
          <div>
            <div className='text-center color-alert'>{t.FORM.WARNING()}</div>
            <div
              css={css`
                margin-top: ${quarterSpacing}px;
              `}
            >
              {t.LHS.CONTENT_SEARCH.INVALID_SEARCH()}
            </div>
          </div>
        )}
        placement='bottom-start'
        offset={-10}
        overlayStyles={popoverStyles}
      >
        <NvSearchBar
          className='search-progress'
          onSearch={onSearch}
          onSearchButtonClick={onSearch}
          searchButtonEnabled
          placeholder={t.LEARNER_PROGRESS.SEARCH_BY()}
          clearButtonEnabled
          autoHideClearbutton
          onKeyDown={resetInvalidSearch}
          isReset={isSearchbarReset}
        />
      </NvPopover>
      {!isSearchActive && (
      <LearnerProgressTabs
        totals={totals}
        selectTab={selectTab}
      />
      )}
      <NvResponsiveTable<LearnerProgress>
        columns={columns}
        columnSortings={columnSortings}
        onSortClicked={onHeaderSortingClicked}
        fetchData={getLearnerProgressList}
        fetchParams={{ catalogId: currentCourse.catalogId }}
        pagedFetchParams={pagedFetchParams}
        rowComponent={LearnerRow as FunctionComponent} // The hot() is necessitating this 'as'
        rowProps={{ institution, course: currentCourse, selectedSectionsList }}
        loadingComponent={LoadingRow}
        dataKey='id'
        cacheDataKey='id'
        extraDataKeys={learnerProgressIDs}
        cacheLookup={getStoreLearnerProgressData}
        hoverDisabled
        noResultsInTable={!isSearchActive}
        noResultsIcon='profile'
        noResultsText={t.SEARCH.NO_RESULTS_FOUND()}
        hoverStyles={hoverStyles}
        clearSearch={() => {
          setIsSearchBarReset(true);
          setIsSearchActive(false);
          setPagedFetchParams(initialFetchParams);
        }}
        hideClearSearch={!isSearchActive}
        showSearchSummaryHeader
        encodeSearchQuery
        onTotalCountChange={onTotalCountChange}
      />
      <CustomizationFlyoutModal
        showModal={showCustomizationFlyout}
        catalogId={currentCourse.catalogId}
        onClose={() => setShowCustomizationFlyout(false)}
        onSubmit={updateSectionFilters}
        allSections={allSections}
      />
    </div>
  );
};

export default LearnerProgressDashboard;
