import { MockDefinition, MockTypes } from '../axios/axios-mocks';

const CommentMocks: MockDefinition[] = [
  {
    type: MockTypes.POST,
    url: '/novoed-test-03/posts/4/translate',
    status: 200,
    response: {
      result: {
        id: 4,
        body:
          '<p class="froala-style-regular" dir="ltr">This is my first comment as learner one in a team discussion!</p>',
      },
      message: 'Successfully completed the request'
    }
  }
];
export default CommentMocks;
