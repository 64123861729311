import React from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import { AngularServicesContext } from 'react-app';
import { getCurrentUser } from 'redux/selectors/users';
import { FullCourse } from 'redux/schemas/models/courseFull';
import { hexToRgbaString } from 'styles/global_defaults/colors';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { OfferingContext } from 'offerings/components/offering-card';
import {
  openSans,
  boldFontWeight,
  textMediumFontSize,
  textMediumLineHeight,
} from 'styles/global_defaults/fonts';
import { useAppDispatch } from 'redux/store';
import { setShowCourseCompletionStatusModal } from 'redux/actions/courses';

type Props = {
  className?: string,
  style?: React.CSSProperties,
};

const CompletedStatusOverlay = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    style,
    className,
  } = props;

  const dispatch = useAppDispatch();
  const currentUser = useSelector(getCurrentUser);
  const offering = React.useContext(OfferingContext);
  const { $state } = React.useContext(AngularServicesContext);

  const styles = css`
    color: #fff;
    padding: ${halfSpacing}px;

    & > div {
      width: 100%;
      height: 100%;
      text-align: center;
      border: 1px solid ${hexToRgbaString('#fff', 0.6)};
    }

    .icon {
      margin-bottom: ${halfSpacing}px;
    }

    .info {
      color: #fff;
      font-family: ${openSans}px;
      font-weight: ${boldFontWeight};
      font-size: ${textMediumFontSize}px;
      line-height: ${textMediumLineHeight}px;
      max-width: 98%;
    }
  `;

  const hasCertificate = (offering as unknown as FullCourse).soaCertificateEnabled;

  const handleCertificateOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();

    // If user was viewing the course completion status modal, we need to close it
    dispatch(setShowCourseCompletionStatusModal(false));

    window.location.href = $state.href(offering.isJourney ? 'journey-statement-modal' : 'statement-modal', {
      userId: currentUser.id,
      catalogId: offering.catalogId,
    });
  };

  return (
    <div
      ref={ref}
      css={styles}
      style={style}
      className={className}
    >
      <div className='d-flex flex-column justify-content-center align-items-center'>
        {hasCertificate ? (
          <a
            className='info'
            onClick={handleCertificateOnClick}
          >
            <NvIcon
              icon='badge'
              size='medium'
            />
            <span className='two-line-ellipsis'>
              {t.OFFERINGS.CARD.VIEW_CERTIFICATE(offering?.certificateName?.singularizedTitleized ?? t.OFFERINGS.CARD.CERTIFICATE())}
            </span>
          </a>
        ) : (
          <div className='info'>
            {t.OFFERINGS.CARD.CONGRATULATIONS()}
          </div>
        )}
      </div>
    </div>
  );
});

export default CompletedStatusOverlay;
