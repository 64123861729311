/* @ngInject */
export default function NvDataDownloadsCard(
  moment,
  $uibModal,
  $window,
  CustomDownloadsModel,
  CustomDownloadsManager,
  $stateParams,
  ConfirmationOverlays,
  AlertMessages,
  _,
) {
  return {
    scope: {
      downloadInfo: '=',
      lastVisitedAt: '=?',
      type: '=?',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
      vm.moment = moment;
      vm.$stateParams = $stateParams;
      vm.manager = CustomDownloadsManager;

      vm.isNew = function () {
        return vm.downloadInfo.isDuplicate || (vm.lastVisitedAt < vm.downloadInfo.reportTimestamp && vm.downloadInfo.reportTimestamp <= vm.downloadInfo.createdAt);
      };

      vm.isUpdated = function () {
        return vm.lastVisitedAt < vm.downloadInfo.reportTimestamp && vm.downloadInfo.reportTimestamp > vm.downloadInfo.createdAt;
      };

      vm.viewDownloadList = function ($event) {
        const modal = $uibModal.open({
          templateUrl: 'custom_downloads/templates/data-downloads-list-modal.html',
          controller: 'DataDownloadsListCtrl as vm',
          windowClass: 'full-screen-modal-handheld full-screen-modal-tablet downloads-list-modal custom-downloads',
          resolve: {
            downloadInfo: vm.downloadInfo,
          },
        }).closed.then(() => {
          CustomDownloadsManager.getUpdatedCustomReport(vm.$stateParams.catalogId, vm.downloadInfo.reportId, vm.type);
        });

        $event.stopPropagation();
      };

      vm.viewDownloadDetails = function () {
        if (vm.type === 'dataDownload') {
          const modal = $uibModal.open({
            templateUrl: 'custom_downloads/templates/downloads-details-modal.html',
            controller: 'DownloadDetailsCtrl as vm',
            windowClass: 'full-screen-modal-handheld full-screen-modal-tablet download-details-modal custom-downloads',
            resolve: {
              downloadInfo: vm.downloadInfo,
            },
          });
        }
      };

      vm.editReport = function () {
        const modal = $uibModal.open({
          templateUrl: 'custom_downloads/templates/create-reports-modal.html',
          controller: 'CreateReportsCtrl as vm',
          windowClass: 'full-screen-modal-handheld full-screen-modal-tablet create-reports-modal custom-downloads',
          resolve: {
            reportId: vm.downloadInfo.reportId,
            reportPreviousStatus: vm.downloadInfo.reportPrevStatus,
          },
        }).result.then((action) => {
          CustomDownloadsManager.getUpdatedCustomReport(vm.$stateParams.catalogId, action.type === 'new' ? null : vm.downloadInfo.reportId, vm.type, !action.preserveOlderReports);
        });
      };

      vm.duplicateReport = function (reportId) {
        // Insert copy right before card being duplicated
        const index = _.findIndex(vm.manager.data.dataDownload, { reportId: vm.downloadInfo.reportId });

        vm.manager.data.dataDownload.splice(index, 0, {
          isDuplicatePlaceholder: true,
        });

        CustomDownloadsManager.duplicateReport(vm.$stateParams.catalogId, { id: vm.downloadInfo.reportId }).then((response) => {
          // Replace placeholder card with real data
          vm.manager.data.dataDownload[index] = response;
          vm.manager.data.dataDownload[index].reportId = response.id;
          vm.manager.data.dataDownload[index].isDuplicate = true;

          if (vm.type === 'dataDownload' && vm.manager.shownDataDownloads.num > 6) {
            vm.manager.shownDataDownloads.num += 1;
          }
        }, () => {
          AlertMessages.error('', 'FORM.ERROR');
        });
      };

      vm.deleteReport = function () {
        const modalInstance = ConfirmationOverlays.openConfirmationModal('custom_downloads/templates/modal-delete-data-report-confirmation.html');

        modalInstance.result.then(() => {
          CustomDownloadsManager.deleteReport(vm.$stateParams.catalogId, { id: vm.downloadInfo.reportId }).then(() => {
            vm.manager.data.dataDownload.splice(_.findIndex(vm.manager.data.dataDownload, { reportId: vm.downloadInfo.reportId }), 1);
            // only decrement if Show All is clicked i.e. when shownDataDownloads > 6
            if (vm.type === 'dataDownload' && vm.manager.shownDataDownloads.num > 6) {
              vm.manager.shownDataDownloads.num -= 1;
            } else if (vm.manager.shownContentDownloads.num > 6) {
              vm.manager.shownContentDownloads.num -= 1;
            }
          }, () => {
            AlertMessages.error('', 'FORM.ERROR');
          });
        });
      };

      vm.generateReport = function ($event) {
        $event.stopPropagation();
        CustomDownloadsModel.generateReport(vm.$stateParams.catalogId, vm.downloadInfo.reportId).then(() => {
          vm.downloadInfo.reportStatus = 1;
        });
      };


      // Content Download
      vm.generateContent = function () {
        CustomDownloadsModel.generateContentReport(vm.$stateParams.catalogId, vm.downloadInfo.reportId).then(() => {
          vm.downloadInfo.reportStatus = 1;
        });
      };

      vm.deleteContentReport = function () {
        const modalInstance = ConfirmationOverlays.openConfirmationModal('custom_downloads/templates/modal-delete-content-report-confirmation.html');

        modalInstance.result.then(() => {
          CustomDownloadsManager.deleteContentReport(vm.$stateParams.catalogId, { id: vm.downloadInfo.reportId }).then(() => {
            vm.manager.data.contentDownload.splice(_.findIndex(vm.manager.data.contentDownload, { reportId: vm.downloadInfo.reportId }), 1);
          }, () => {
            AlertMessages.error('', 'FORM.ERROR');
          });
        });
      };
    },
    replace: true,
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'custom_downloads/templates/nv-data-downloads-card.html',
  };
}
