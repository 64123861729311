/* @ngInject */
export default function QuizzesResources(

  $resource,
) {
  return $resource(
    '/:catalogId/question_sets/:id.json',
    {},
    {
      getQuestions: { method: 'GET', url: '/:catalogId/question_sets/:questionSetId/questions.json' },
      createQss: { method: 'POST', url: '/:catalogId/question_set_submissions.json' },
      updateQss: { method: 'PUT', url: '/:catalogId/question_set_submissions/:questionSetSubmissionId.json' },
      gradeQuestion: { method: 'POST', url: '/:catalogId/video_list_lecture_components/:videoListId/lecture_videos/:lectureVideoId/grade_question.json' },
      createTimedQss: { method: 'POST', url: '/:catalogId/question_set_submissions/start_exam/:questionSetId.json' },
      updateTimedQss: { method: 'PUT', url: '/:catalogId/question_set_submissions/:questionSetSubmissionId.json' },
      getSubmissions: { method: 'GET', url: '/:catalogId/question_set_submissions/attempts/:userId.json' },
      resetAttempts: { method: 'POST', url: '/:catalogId/question_set_submissions/reset_attempt.json' },
    },
  );
}
