import React from 'react';

import moment from 'moment';

import { connect, useSelector } from 'react-redux';
import t from 'react-translate';

// schemas
import { RootState } from 'redux/schemas';
import { ActivityType } from 'redux/schemas/models/activity';
import { NTimelineTimedQuiz } from 'redux/schemas/models/timed-quiz';
import { ComponentKeyPluralized } from 'redux/schemas/models/lecture-component';

// actions
import { setActivityExpandedInAutomatedCommunications } from 'redux/actions/course-communications';

// selectors
import { getTimedQuiz, hasTimedQuizGotCommunicationError } from 'redux/selectors/timeline';
import { isActivityExpandedInAutomatedCommunications } from 'redux/selectors/course-communications';

// components
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';

import CommunicationCreateNewDropdown from 'communications/course_communications/components/communication-create-new-dropdown';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';
import NvStackedTab from 'shared/components/nv-stacked-tab';

type TimedQuizLectureComponentProps = {
  timedQuizId: number
};

type StateProps = {
  timedQuiz: NTimelineTimedQuiz,
  hasCommunicationError: boolean,
};

const mapDispatchToProps = {
  setExpanded: setActivityExpandedInAutomatedCommunications,
};

const TimedQuizLectureComponent = (props: TimedQuizLectureComponentProps & StateProps & typeof mapDispatchToProps) => {
  const isExpanded = useSelector<RootState, boolean>((state) => isActivityExpandedInAutomatedCommunications(state, ComponentKeyPluralized.TIMED_QUIZ, props.timedQuizId));

  return (
    <React.Fragment>
      <div className={`lecture-component-row ${isExpanded && props.timedQuiz?.communicationsCount > 0 && 'expanded-box'}`}>
        <NvIcon icon='timedexam' size='smallest' />
        <div className='description'>{props.timedQuiz?.title}</div>
        {props.timedQuiz?.hasStructuralIssues && (
          <div className='text-danger spacing'>
            <NvIcon icon='warning' size='xss-smallest' />
          </div>
        )}
        {props.timedQuiz?.hardDeadline && (
          <NvTooltip text={t.TIMELINE.HARD_DEADLINE()} placement='top'>
            <div className='spacing'>
              <NvIcon icon='harddeadline' size='smallest' />
            </div>
          </NvTooltip>
        )}
        <div className='date'>
          {props.timedQuiz?.expirationDate && t.LECTURE_PAGES.COMPONENTS.DUE_DATE.STUDENT_VIEW(moment(props.timedQuiz.expirationDate).format('LLL'))}
        </div>
        <div className=''>
          <CommunicationCreateNewDropdown activityType={ActivityType.TIMED_EXAM} activityId={props.timedQuizId} />
        </div>
        { props.timedQuiz?.communicationsCount > 0 && (
          <NvStackedTab
            count={props.timedQuiz.communicationsCount}
            hasError={props.hasCommunicationError}
            errorMessage={t.COURSE_COMMUNICATIONS.WARNINGS.ACTIVITY_COMMUNICATION()}
            isExpanded={isExpanded}
            onClick={() => props.setExpanded({
              type: ComponentKeyPluralized.TIMED_QUIZ,
              id: props.timedQuizId,
              isExpanded: !isExpanded,
            })}
          />
        )}
      </div>
      {
        // Communications
        isExpanded && props.timedQuiz?.communications && props.timedQuiz.communications.map(communicationId => (
          <CommunicationItem key={communicationId.toString()} communicationId={communicationId} />
        ))
      }
    </React.Fragment>
  );
};

const ConnectedTimedQuizLectureComponent = connect(
  (state: RootState, ownProps: TimedQuizLectureComponentProps) => ({
    timedQuiz: getTimedQuiz(state, ownProps.timedQuizId),
    hasCommunicationError: hasTimedQuizGotCommunicationError(state, ownProps.timedQuizId),
  }),
  mapDispatchToProps,
)(TimedQuizLectureComponent);

export default ConnectedTimedQuizLectureComponent;
