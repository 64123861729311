import { gray4, success, warning, primary } from 'styles/global_defaults/colors';

export default {
  bindings: {
    course: '<',
    userCourse: '<?',
    completionCriteria: '=',
    criteriaCount: '=',
    isCompleted: '<',
  },
  controller: function ctrl(
    config,
  ) {
'ngInject';
    const vm = this;

    vm.config = config;
    vm.displayRadialGraph = vm.criteriaCount !== 'single';

    vm.defaultColor = gray4;
    vm.pointColor = success;
    vm.assignmentColor = warning;
    vm.todoColor = primary;

    vm.COMPLETIONSTATUS = {
      AUTO: 1,
      MANUAL: 2,
    };

    return vm;
  },
  controllerAs: 'vm',
  templateUrl: 'timelines/templates/nv-completion-criteria-header.html',
};
