import getComponentMetadata from 'lecture_pages/components/data';
import { reduce } from 'lodash';
import createCachedSelector from 're-reselect';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { RootState } from 'redux/schemas';
import { Estimation, NLecturePage } from 'redux/schemas/models/lecture-page';
import { LectureSection } from 'redux/schemas/models/lecture-section';
import { find } from 'underscore';
import { getCourseAliases } from './course';
import { getIsTodo, getLectureComponentActivityProgress } from './lecture-components';
import { CourseOutline } from './course-outline/use-course-outline';
import { CourseOutlineItem } from './course-outline/course-outline-item';

export type LecturePageStatus = 'not-started' | 'in-progress' | 'completed';

export const lecturePageSelector = (state: RootState, lecturePageId: number) => state.models.lecturePages[lecturePageId];

const lectureComponentsSelector = (state: RootState, lecturePageId: number) => state.models.lecturePages[lecturePageId]?.lectureComponents?.map(lcId => state.models.lectureComponents[lcId]) ?? [];

export const lectureStatusSelector = createCachedSelector(
  (state) => state,
  lecturePageSelector,
  lectureComponentsSelector,
  (state, lecturePage) => (state.app.lecturePage.isTimelineContentLoaded
    && !state.models.courses[lecturePage?.course?.catalogId]?.isContentManagementCollection
    ? computeLectureStatus(state, lecturePage) : 'not-started'),
)(
  (state, lecturePageId) => lecturePageId,
);

// TODO: What are the pros/cons of making this a hook & putting useSelector in this file vs just exporting the selector?
const useLectureStatus = (lecturePageId: number) => useSelector(state => lectureStatusSelector(state, lecturePageId));
export default useLectureStatus;

const computeLectureStatus = (state: RootState, lecturePage: NLecturePage): LecturePageStatus => {
  // Early exit if the outline lecture component data is not available
  if (!state.app.lecturePage.isTimelineContentLoaded) {
    return 'not-started';
  }

  if (lecturePage.completed) {
    return 'completed';
  }

  if (lecturePage.started) {
    return 'in-progress';
  }

  return 'not-started';
};

/** Gets the correct translation for an estimate read-out, like "4 hours read" */
export const getEstimateReadout = (estimate: Estimation) => {
  if (!estimate) {
    return '';
  }

  const { estimatedEffort, estimatedEffortMeasure, estimatedEffortType } = estimate;

  switch (estimatedEffortMeasure) {
    case 'Day':
      switch (estimatedEffortType) {
        case 'Listen':
          return t.TIME_ESTIMATES.DAY_LISTEN(estimatedEffort);
        case 'Read':
          return t.TIME_ESTIMATES.DAY_READ(estimatedEffort);
        case 'Watch':
          return t.TIME_ESTIMATES.DAY_WATCH(estimatedEffort);
        case 'Work':
          return t.TIME_ESTIMATES.DAY_WORK(estimatedEffort);
        default: return '';
      }
    case 'Hour':
      switch (estimatedEffortType) {
        case 'Listen':
          return t.TIME_ESTIMATES.HOUR_LISTEN(estimatedEffort);
        case 'Read':
          return t.TIME_ESTIMATES.HOUR_READ(estimatedEffort);
        case 'Watch':
          return t.TIME_ESTIMATES.HOUR_WATCH(estimatedEffort);
        case 'Work':
          return t.TIME_ESTIMATES.HOUR_WORK(estimatedEffort);
        default: return '';
      }
    case 'Min':
      switch (estimatedEffortType) {
        case 'Listen':
          return t.TIME_ESTIMATES.MIN_LISTEN(estimatedEffort);
        case 'Read':
          return t.TIME_ESTIMATES.MIN_READ(estimatedEffort);
        case 'Watch':
          return t.TIME_ESTIMATES.MIN_WATCH(estimatedEffort);
        case 'Work':
          return t.TIME_ESTIMATES.MIN_WORK(estimatedEffort);
        default: return '';
      }
    default: return '';
  }
};

export type CannotBeCopiedComponentsMeta = {
  lectureComponentId: number;
  label: string;
};

export const getCannotBeCopiedComponentsMeta = (state: RootState, lecturePageId: number) => {
  const { lectureComponents } = state.models.lecturePages[lecturePageId] ?? {};

  return reduce<number, CannotBeCopiedComponentsMeta[]>(lectureComponents, (acc, lectureComponentId) => {
    const lectureComponent = state.models.lectureComponents[lectureComponentId];

    if (lectureComponent) {
      const metaData = getComponentMetadata(lectureComponent.trueType);

      if (metaData.copyDisabled) {
        const courseAliases = getCourseAliases(state);
        acc.push({
          lectureComponentId,
          label: metaData.description({
            ...courseAliases.groupAliases,
            ...courseAliases.teamAliases,
          }),
        });
      }
    }
    return acc;
  }, []);
};

/** Gets the lecture in the timeline "nearest" to this one. This is the destination lecture
 * after deleting the current lecture or when otherwise needing a fallback */
export const getNearestLecture = (courseOutline: CourseOutline, lectureId: number): CourseOutlineItem => {
  let nearestLecture = getNextLecture(courseOutline, lectureId);

  if (!nearestLecture) {
    nearestLecture = getPreviousLecture(courseOutline, lectureId);
  }

  return nearestLecture;
};

/** Gets the first lecture in the outline following (below in timeline, next in sequence) this one.
 * Returns null if none present. */
export const getNextLecture = (courseOutline: CourseOutline, lectureId: number): CourseOutlineItem => {
  if (!courseOutline || !courseOutline.length) {
    return null;
  }

  const currentLectureIndex = courseOutline.findIndex(item => item.type === 'lecture' && item.id === lectureId);

  let nextLectureIndex = currentLectureIndex + 1;
  let nextLecture = null;

  // Search forward for next lecture
  while (!nextLecture && nextLectureIndex < courseOutline.length) {
    if (courseOutline[nextLectureIndex].type === 'lecture') {
      nextLecture = courseOutline[nextLectureIndex];
    }

    nextLectureIndex += 1;
  }

  return nextLecture;
};

/** Gets the first lecture in the outline preceding (above in timeline, previous in sequence) this one
 * Returns null if none present. */
export const getPreviousLecture = (courseOutline: CourseOutline, lectureId: number): CourseOutlineItem => {
  if (!courseOutline || !courseOutline.length) {
    return null;
  }

  const currentLectureIndex = courseOutline.findIndex(item => item.type === 'lecture' && item.id === lectureId);

  let nextLectureIndex = currentLectureIndex - 1;
  let nextLecture = null;

  // Search backward for next lecture
  while (!nextLecture && nextLectureIndex >= 0) {
    if (courseOutline[nextLectureIndex].type === 'lecture') {
      nextLecture = courseOutline[nextLectureIndex];
    }

    nextLectureIndex -= 1;
  }

  return nextLecture;
};

export const getCurrentLecture = (state: RootState) => state.models.lecturePages[state.app.lecturePage.currentLectureId];

export const getCurrentLectureSection = (state: RootState, type?: LectureSection['type']) => {
  const currentSectionId = state.models.lecturePages[state.app.lecturePage.currentLectureId]?.lectureSectionId;
  const section = state.models.lectureSections[currentSectionId];

  if (type === 'LectureSection' && section?.type === 'LectureSubsection') {
    return find(state.models.lectureSections, (sec) => sec.type === 'LectureSection' && sec.lectureSubsections.includes(currentSectionId));
  }

  return (!type || section?.type === type) ? section : null;
};

export const getNovoAIProperties = (state: RootState) => state.app.lecturePage.novoAI;
