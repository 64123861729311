import t from '../../../react-translate';

export default {
  bindings: {
    lectureComponent: '<',
    editMode: '<',
    restrictedEditMode: '<',
    reorderMode: '<',
    index: '<',
    context: '<',
  },
  controller: function ctrl(
    $controller,
    $state,
    $timeout,
    $scope,
    nvUtil,
    LectureComponentsHelper,
    CurrentPermissionsManager,
    ScrollFocusConnectorFactory,
    config,
    $rootScope,
  ) {
'ngInject';
    angular.extend(this, $controller('LectureComponentBaseCtrl'));
    const vm = this;
    let lastSavedDescription;
    vm.config = config;

    vm.canViewLockedEval = CurrentPermissionsManager.isConfigAndRegistrationRole() || CurrentPermissionsManager.isInstructor()
        || CurrentPermissionsManager.isTeachingAssistant() || CurrentPermissionsManager.isCourseBuilder();

    vm.$onInit = () => {
      lastSavedDescription = vm.lectureComponent.evaluationsTask.description;
    };

    /* Admin Actions */
    vm.editBasics = () => {
      $timeout(() => {
        vm.context.sharedProps.openBasicModal();
      });
    };

    vm.descriptionBlurCallback = () => {
      if (lastSavedDescription !== vm.lectureComponent.evaluationsTask.description) {
        lastSavedDescription = vm.lectureComponent.evaluationsTask.description;
        vm.lectureComponent.save();
      }
    };

    vm.setEditMenu = () => {
      const { sharedProps, setSharedProps } = this.context;
      const extraOptions = [];

      extraOptions.push(sharedProps.extraOptions.getEditOption());

      if (!vm.lectureComponent.evaluationsTask.isFeedbackPublic && vm.lectureComponent.evaluationsTask.requiredExercise) {
        extraOptions.push({
          type: 'link',
          text: t.LECTURE_PAGES.COMPONENTS.PRIVATE_PEER_EVALUATION.DROPDOWN.EDIT_EVALUATION_CRITERIA(),
          link: vm.RailsRoutes.editPeerEvaluationsPath(vm.$stateParams.catalogId, vm.lectureComponent.evaluationsTask.requiredExercise.id),
        });
      }

      if (!vm.lectureComponent.evaluationsTask.requiredExercise) {
        extraOptions.push({
          type: 'header',
          title: t.LECTURE_PAGES.COMPONENTS.PRIVATE_PEER_EVALUATION.DROPDOWN.EDIT_EVALUATION_CRITERIA(),
        });
      }

      if (!vm.CurrentCourseManager.course.isSelfPaced) {
        extraOptions.push({
          type: 'text',
          text: vm.lectureComponent.hasDeadline() ? t.LECTURE_PAGES.COMPONENTS.QUIZ.DROPDOWN.REMOVE_DEADLINE() : t.LECTURE_PAGES.COMPONENTS.QUIZ.DROPDOWN.ADD_DEADLINE(),
          callback: () => {
            vm.toggleDeadline();
            vm.setEditMenu();
          },
        });
      }

      if (vm.lectureComponent.evaluationsTask.requiredExercise) {
        extraOptions.push({
          type: 'link',
          text: t.LECTURE_PAGES.COMPONENTS.FEEDBACK_SHARED.VIEW_RELATED_ASSIGNMENT(vm.CurrentCourseManager.course.getAliases()),
          link: $state.href('lecture-page-edit', { id: vm.lectureComponent.evaluationsTask.requiredExercise.lecturePageId, lectureActivityId: vm.lectureComponent.evaluationsTask.requiredExercise.lectureComponentId }),
        });
      }

      setSharedProps({
        ...sharedProps,
        isFullWidth: true,
        extraOptions: {
          ...sharedProps.extraOptions,
          renderOnMount: true,
          ...{ options: extraOptions },
        },
      });
    };

    vm.goToExercise = () => {
      const { requiredExercise } = vm.lectureComponent.evaluationsTask;

      if (
        $state.params.id === requiredExercise.lecturePageId
        && $state.params.lectureActivityId === requiredExercise.lectureComponentId
      ) {
        const targetComponent = $(`#lecture-component-${requiredExercise.lectureComponentId}`);
        ScrollFocusConnectorFactory.scrollTo(null, null, { elem: targetComponent });
      } else {
        const stateParams = {
          id: vm.lectureComponent.evaluationsTask.requiredExercise.lecturePageId,
          lectureActivityId: vm.lectureComponent.evaluationsTask.requiredExercise.lectureComponentId,
        };

        // As same as 'framed-froala-controller.js - vm.submit()', doing an angular-ui-router transition via $state.go() doesn't seem to update the template, so we're using the same alternative through 'document.location.hash'
        const lecturePageUrl = vm.editMode ? 'lecture-page-edit' : 'lecture-page';
        document.location.hash = $state.href(lecturePageUrl, stateParams);
      }
    };

    $scope.$watch('vm.editMode', () => {
      if (vm.editMode) {
        vm.carouselSubmissions = [];
        vm.submissions = [];
      } else {
        vm.carouselSubmissions = vm.lectureComponent.evaluationsTask.carouselSubmissions;
        vm.submissions = vm.lectureComponent.evaluationsTask.evaluations;
      }
    });

    vm.setEditMenu();

    $scope.$watch('vm.lectureComponent.evaluationsTask.requiredExercise.lectureComponentId', () => vm.setEditMenu());

    $scope.$watch('vm.lectureComponent.peerEvaluation.exerciseId', () => {
      if (
        vm.editMode
        && vm.lectureComponent.peerEvaluation?.exerciseId !== vm.lectureComponent.feedbackDraft?.exerciseId
      ) {
        vm.lectureComponent.createDraft?.();
      }
    });

    $rootScope.$on('evaluationExerciseCompleted', (e, exerciseId) => {
      if (exerciseId === vm.lectureComponent.peerEvaluation.exerciseId) {
        vm.lectureComponent.evaluationsTask.hasSubmittedRequirement = true;
      }
    });
  },
  controllerAs: 'vm',
  templateUrl: 'lecture_pages/templates/components/nv-peer-evaluation-lecture-component.html',
};
