import { useEffect, MutableRefObject, useRef } from 'react';

/** Detects whether the mouse is clicked outside of a given element ref */
const useClickOutside = (ref: MutableRefObject<HTMLElement>, cb: (event?: MouseEvent) => void, deps?: any[]) => {
  const depArr = deps ?? [];
  const callbackRef = useRef<typeof cb>();

  useEffect(() => {
    callbackRef.current = cb;
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (event.type === 'touchstart') {
        document.removeEventListener('mousedown', handleClickOutside);
      } else if (event.type === 'mousedown') {
        document.removeEventListener('touchstart', handleClickOutside);
      }

      if (ref.current && !ref.current.contains(event.target)) {
        callbackRef.current(event);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ...depArr]);
};

export default useClickOutside;
