export const MANUAL_ADD_MODES = {
  COURSE_USER_ADD: 0,
  MENTEE_ADD: 1,
};

export const getMenteeName = (mentor) => mentor.roles.roles.menteeName.pluralizedTitleized;

/* @ngInject */
export default function ManualAddUserModalCtrl(
  CurrentCourseManager,
  mentor,
  onUserAdded,
) {
  class ManualAddUserModalController {
    constructor() {
      this.CurrentCourseManager = CurrentCourseManager;
      this.mentor = mentor;
      this.onUserAdded = onUserAdded;
      this.MODES = MANUAL_ADD_MODES;
      this.mode = this.mentor ? this.MODES.MENTEE_ADD : this.MODES.COURSE_USER_ADD;
      this.getMenteeName = () => getMenteeName(this.mentor);
    }
  }

  return new ManualAddUserModalController();
}
