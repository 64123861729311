/* @ngInject */
export default function QuizFormBaseController(
  $stateParams,
  PageLevelManager,
  ExercisesManager,
  QuizzesManager,
  nvCurrentPage,
  $controller,
  $scope,
) {
  const vm = angular.extend(this, $controller('L3HeaderCtrl', { $scope }));

  vm.$stateParams = $stateParams;
  vm.PageLevelManager = PageLevelManager;
  vm.ExercisesManager = ExercisesManager;
  vm.nvCurrentPage = nvCurrentPage;
  vm.manager = QuizzesManager;

  vm.subtitleSref = 'exercise-show({ exerciseId: vm.ExercisesManager.currentExercise.id })';
  if (ExercisesManager.currentExercise) {
    vm.subtitleText = ExercisesManager.currentExercise.title;
  }
}
