import { schema, normalize } from 'normalizr';
import { VideoPracticeSubmission } from '../models/video-practice';
import { BasicMentee } from '../models/course';

const videoPracticeSubmissionSchema = new schema.Entity<VideoPracticeSubmission>('videoPracticeSubmissions', {}, {
  idAttribute: 'id',
});

const videoPracticeSubmissionsArraySchema = [videoPracticeSubmissionSchema];

const supervisorCoursesSchema = new schema.Entity<BasicMentee>(
  'courses',
  {},
  {
    idAttribute: 'id',
  },
);

const supervisorCoursesArraySchema = [supervisorCoursesSchema];

const supervisorCoursesNormalizedData = (response: BasicMentee[]) => normalize<
BasicMentee,
{ courses: { [key: string]: BasicMentee } }
>(response, supervisorCoursesArraySchema);

export { videoPracticeSubmissionsArraySchema, supervisorCoursesNormalizedData };
