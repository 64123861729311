import store from 'redux/store';
import { ActivityKey } from 'redux/schemas/models/activity';
import { setActivityProgress } from 'redux/actions/lecture-components';

/* @ngInject */
export default function TimelinePollLectureComponentModelService(_, nvUtil, moment) {
  const TimelinePollLectureComponentModel = function (attributes) {
    const _this = this;
    const currentMoment = moment();
    let deadlineMoment;
    const base = {
      templateUrl: 'timelines/templates/nv-timeline-lecture-poll.html',
      canHaveHardDeadline: true,
    };

    /** Setting Up Basic Attributes * */
    _.extend(_this, base, attributes);
    // passed lecturePage, lectureSection, course
    preprocess();

    /** Public Functions * */
    _.extend(_this, {
      getStatus,
      isCompleted,
      isMissed,
      isTodoRequiredForCompletion,
      updateComponentPointsAndProgress,
      updateComponentProgress,
      isPrereqCompleted,
      getPayload,
      hasDeadline,
      setDeadline,
    });

    // some controllers like the bulk-edit-deadline-controller reads/writes to these
    // properties properties but the actual value is not there so we define "proxies"
    Object.defineProperties(_this, {
      expirationDate: {
        set: (value) => {
          setDeadline(value);
        },
        get: () => _this.poll.expirationDate,
      },

      title: {
        set: (value) => {
          _this.poll.title = value;
        },
        get: () => _this.poll.title,
      },

      hardDeadline: {
        set: (value) => {
          _this.poll.hardDeadline = value;
        },
        get: () => _this.poll.hardDeadline,
      },
    });

    /** Function Declarations * */
    /* Used Publicly */
    function getStatus() {
      return _this.poll.progress;
    }

    function isCompleted() {
      return _this.poll.progress === 'completed';
    }

    function isMissed() {
      return _this.poll.progress === 'missed';
    }

    function isTodoRequiredForCompletion() {
      return _this.poll.isRequiredForCompletion;
    }

    function updateComponentPointsAndProgress(componentType, componentId, pointsReceived, totalPoints) {
      if (
        (_this.type === componentType && _this.id === componentId)
        || (componentType === 'poll' && _this.poll.id === componentId)
      ) {
        _this.poll.progress = 'completed';

        store.dispatch(setActivityProgress({
          pointsReceived,
          progress: _this.poll.progress,
          activityKey: ActivityKey.POLLS,
          activityPayloadId: _this.poll.id,
        }));

        _this.poll.pointsReceived = pointsReceived;
        _this.pointsReceived = pointsReceived;

        if (_.isNumber(totalPoints)) {
          _this.currentTotalPoints = totalPoints;
        }

        viewPreprocess();

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function updateComponentProgress(componentType, componentId, progress) {
      if (
        (_this.type === componentType && _this.id === componentId)
        || (componentType === 'poll', _this.poll.id === componentId)
      ) {
        _this.poll.progress = progress;
        store.dispatch(setActivityProgress({
          progress,
          activityKey: ActivityKey.POLLS,
          activityPayloadId: _this.poll.id,
        }));

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function isPrereqCompleted(prereq) {
      if (
        (prereq.type === _this.type && prereq.id === _this.id)
        || (prereq.type === 'Poll' && prereq.id === _this.poll.id)
      ) {
        return isCompleted();
      }
      return false;
    }

    function getPayload() {
      return _.pick(this.poll, ['title', 'expirationDate', 'isTodo', 'hardDeadline']);
    }

    function hasDeadline() {
      return !!this.poll.expirationDate;
    }

    function setDeadline(newDeadline) {
      _this.poll.expirationDate = newDeadline;
    }

    function isDeadlineBeforeLesson() {
      return _this.poll.deadlineMoment < _this.lecturePage.releaseDate;
    }

    /* Used Privately */
    function preprocess() {
      _.extend(
        _this,
        _.pick(
          _this.poll,
          'isTodo',
          'hasStructuralIssues',
          'expired',
          'pointsReceived',
          'totalPoints',
          'scheduledCommunicationsCount',
        ),
      );
      _this.activityId = _this.poll.id;
      deadlineMoment = moment(_this.poll.expirationDate);

      // current total points takes into account if the the user can score more
      // so if the user has maxed out attempts, the total points will be the same as the pointsReceived
      _this.currentTotalPoints = nvUtil.getCurrentTotalPoints(
        _this.totalPoints,
        _this.lecturePage.releaseDate,
        _this.course.expiringPointsEnabled,
      );

      viewPreprocess();
    }

    function viewPreprocess() {
      _this.showDueSoon = showDueSoon();

      if (_this.pointsReceived > _this.currentTotalPoints) {
        _this.currentTotalPoints = _this.pointsReceived ? _this.pointsReceived : 0;
      }
      _this.displayTotalPointsOnly = !_this.pointsReceived && _this.currentTotalPoints;
    }

    function showDueSoon() {
      if (_this.poll.expirationDate) {
        return !isCompleted() && deadlineMoment > currentMoment && deadlineMoment < moment().add(7, 'days');
      }
      return false;
    }
  };

  return TimelinePollLectureComponentModel;
}
