import React from 'react';
import { css } from '@emotion/react';
import NvIcon, { NvIconProps } from 'shared/components/nv-icon';

const styles = css`
  &.icon {
    background: -webkit-linear-gradient(48.41deg, #60F3AB 0%, #00CCBC 45%, #009AC0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const GradientAiIcon = React.forwardRef<HTMLElement, NvIconProps>((props, ref) => (
  <NvIcon {...props} ref={ref} css={styles} />
));

export default GradientAiIcon;
