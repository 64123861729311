import React, { FunctionComponent, useContext } from 'react';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';

// redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/schemas';
import { getLinkedCourses } from 'redux/selectors/collections';

// components
import ClickableContainer from 'components/clickable-container';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { config } from '../../../../config/config.json';
import { NvBasicTable, BasicTableColumn, ColumnType, SortType } from '../../../shared/components/nv-basic-table';

interface LinkedCourseModalProps {
  lessonId: number
  showLinkedCourseModel: boolean
  closeLinkedCourseModel: VoidFunction
}

type CourseProps ={
  courseName: string
  courseCatalogId: string
  linkedLecturePageId: number
};

const CourseRow = (props: CourseProps) => {
  const { $state } = useContext(AngularServicesContext);

  const redirectToLesson = () => {
    window.open($state.href('lecture-page', { catalogId: props.courseCatalogId, id: props.linkedLecturePageId }), '_blank');
  };

  return (
    <React.Fragment>
      <div className='text-regular ellipsis'>{props.courseName}</div>
      <div className='text-gray-2 pl-5 ellipsis'>{props.courseCatalogId}</div>
      <ClickableContainer
        onClick={redirectToLesson}
        data-qa={config.pendo.contentManagement.redirectToLinkedLesson}
      >
        <div className='text-primary pl-5'>
          {t.INSTITUTIONS.CONTENT_LIBRARY.LINKED_COURSE_MODAL.VIEW_LESSON()}
        </div>
      </ClickableContainer>
    </React.Fragment>
  );
};

const LinkedCourseModal = (props: LinkedCourseModalProps) => {
  const linkedCourses = useSelector((state: RootState) => getLinkedCourses(state, props.lessonId));
  const cols: BasicTableColumn[] = [
    {
      key: 'courseName',
      name: t.INSTITUTIONS.CONTENT_LIBRARY.LINKED_COURSE_MODAL.BELONGS_TO_COURSE(),
      className: 'column',
      sortable: false,
      gridWidth: '2.5fr',
      type: ColumnType.STRING,
    },
    {
      key: 'courseCatalogId',
      name: t.INSTITUTIONS.CONTENT_LIBRARY.LINKED_COURSE_MODAL.CATALOGUE_ID(),
      className: 'column-centered pl-5',
      sortable: false,
      gridWidth: '1.8fr',
      type: ColumnType.STRING,
    },
    {
      key: 'ViewInCourse',
      name: t.INSTITUTIONS.CONTENT_LIBRARY.LINKED_COURSE_MODAL.VIEW_IN_COURSE(),
      className: 'column-centered pl-5',
      sortable: false,
      gridWidth: '1fr',
      type: ColumnType.NUMBER,
    },
  ];
  linkedCourses.forEach((eachCourse, index) => {
    eachCourse.id = index;
    eachCourse.viewInCourse = 'View Lesson';
  });

  return (
    <NvModal
      show={props.showLinkedCourseModel}
      width={800}
      header={t.INSTITUTIONS.CONTENT_LIBRARY.LINKED_COURSE_MODAL.HEADER()}
      type={ModalType.DYNAMIC}
      onClose={props.closeLinkedCourseModel}
      bodyClassName='pt-6 px-6 pb-4'
      body={(
        <div>
          <div className='title w-100 text-body'>
            {t.INSTITUTIONS.CONTENT_LIBRARY.LINKED_COURSE_MODAL.BODY_TEXT()}
          </div>
          <NvBasicTable<JSX.Element, CourseProps>
            columns={cols}
            tabledata={linkedCourses}
            rowComponent={CourseRow as FunctionComponent}
            rowClass='pt-4 pb-4'
            defaultSort={{ name: 'name', type: SortType.INACTIVE }}
          />
        </div>
    )}
    />
  );
};

export default LinkedCourseModal;
