/* @ngInject */
export default function CourseRolesManagerService(
  _,
  $q,
  $translate,
  CourseRoleModel,
  CourseRolesResource,
  RolesService,
) {
  class CourseRolesManager {
    constructor() {
      this.RolesService = RolesService;
      this.roles = {};
      this.requestCourseRolesPromise = null;

      // A 'dummy' role used to signify no roles selected
      this.noRolesRole = {
        id: 0, // The API often treats ID 0 as 'Learner', meaning the user has no special course role
        nameKey: 'USER_MANAGEMENT.NO_ROLE',
        badge: '',
        permissions: [],
      };
    }

    /** Load the course roles for the given institution. Only reloads if a new institution ID is given than what was used
       in the previous load, or if 'forceReload' is set true. */
    loadCourseRoles(institutionId, forceReload) {
      const vm = this;
      if (this.requestCourseRolesPromise) {
        return this.requestCourseRolesPromise;
      }
      if (vm.institutionId !== institutionId || forceReload) {
        vm.roles = {};
        this.requestCourseRolesPromise = CourseRolesResource.get({ institutionId }).$promise.then((response) => {
          _.forEach(response.result, (role) => {
            vm.roles[role.id] = new CourseRoleModel(role);
          });

          return vm.roles;
        }).catch((e) => {
          // Reset the promise so the next call to loadCourseRoles() will re-attempt the request
          this.requestCourseRolesPromise = null;
          return vm.roles;
        });

        return this.requestCourseRolesPromise;
      }
      return $q.resolve(vm.roles);
    }

    addCourseRole(institutionId, roleName, badge, permissions, menteeName = null) {
      const permissionOptions = this.RolesService.makePermissionOptions(permissions);

      return CourseRolesResource.save({ institutionId }, {
        institutionId,
        name: roleName,
        badge,
        permissionOptions,
        menteeName,
      }).$promise.then((response) => {
        const courseRole = new CourseRoleModel(response.result);
        return courseRole;
      });
    }

    getCourseRole(institutionId, roleId) {
      return CourseRolesResource.getRole({
        institutionId,
        roleId,
      }).$promise;
    }

    deleteCourseRole(institutionId, roleId) {
      return CourseRolesResource.delete({
        institutionId,
        id: roleId,
      }).$promise;
    }
  }

  return new CourseRolesManager();
}
