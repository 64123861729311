/* @ngInject */
export default function SubmissionApprovalResource(
  $resource,
) {
  return $resource(
    '/users/todos.json',
    {},
    {
      getCurrentLearning: { method: 'GET', url: '/users/current_learning.json' },
    },
  );
}
