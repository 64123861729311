/* @ngInject */
export default function CreateContentDownloadController(
  $stateParams,
  $translate,
  $uibModalInstance,
  AlertMessages,
  CurrentCourseManager,
  CurrentUserManager,
  CustomDownloadsManager,
  CustomDownloadsModel,
  _,
) {
  const vm = this;

  vm.CustomDownloadsManager = CustomDownloadsManager;
  vm.discussionsContentDownloadCreated = CustomDownloadsManager.data.contentDownload.some((dl) => dl.reportTitle === 'Discussions');
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.teamActivityContentDownloadCreated = CustomDownloadsManager.data.contentDownload.some((dl) => (
    dl.reportTitle === $translate.instant('CUSTOM_DOWNLOADS.ACTIVITIES.TEAM_WORKSPACE_ACTIVITY', {
      aliasedTeamName: vm.CurrentCourseManager.course.teamName.singularizedTitleized,
    })
  ));
  vm.$stateParams = $stateParams;
  vm.CurrentUserManager = CurrentUserManager;
  vm.groupActivityContentDownloadCreated = vm.CustomDownloadsManager.data.contentDownload.some((dl) => (
    dl.reportTitle === $translate.instant('CUSTOM_DOWNLOADS.ACTIVITIES.GROUP_WORKSPACE_ACTIVITY', {
      aliasedGroupName: vm.CurrentCourseManager.course.groupName.singularizedTitleized,
    })
  ));
  vm.contentDownloadForm = null;
  vm.contentOption = null;
  vm.activityId = null;

  initialize();

  function initialize() {
    return CustomDownloadsModel.getNewContentDownload($stateParams.catalogId).then((data) => {
      vm.data = data;
      vm.data.reportType = 'content';
      vm.data.courseId = vm.CurrentCourseManager.course.id;
      vm.data.contentOptions = {
        assignment: 'Submissions',
        assignment_skills_rating: 'Assignment Skills Feedback',
        feedback: 'Assignment Feedback',
        discussions: 'Discussion Content',
        team_workspace_activity: $translate.instant('CUSTOM_DOWNLOADS.ACTIVITIES.TEAM_WORKSPACE_ACTIVITY', {
          aliasedTeamName: vm.CurrentCourseManager.course.teamName.capitalizedPluralized,
        }),
        group_workspace_activity: $translate.instant('CUSTOM_DOWNLOADS.ACTIVITIES.GROUP_WORKSPACE_ACTIVITY', {
          aliasedGroupName: vm.CurrentCourseManager.course.groupName.capitalizedPluralized,
        }),
        feedback_criteria: 'Practice Feedback',
        practice_video_skills_rating: 'Practice Skills Feedback Activity',
      };
    });
  }

  vm.submitCustomReport = function () {
    vm.data.contentOption = vm.contentOption;

    if (vm.contentOption === 'feedback') {
      vm.data.feedback = {
        [vm.activityId]: vm.data.feedback[vm.activityId],
      };
      vm.data.exerciseId = vm.activityId;

      vm.data.title = vm.data.feedback[vm.activityId][0];
      vm.data.feedback[vm.activityId][1] = true;
    } else if (vm.contentOption === 'assignment') {
      vm.data.assignments = {
        [vm.activityId]: vm.data.assignments[vm.activityId],
      };
      vm.data.exerciseId = vm.activityId;

      vm.data.title = vm.data.assignments[vm.activityId][0];
      vm.data.assignments[vm.activityId][1] = true;
    } else if (vm.contentOption === 'assignment_skills_rating') {
      vm.data.assignmentSkillsRating = {
        [vm.activityId]: vm.data.assignmentSkillsRating[vm.activityId],
      };
      vm.data.feedbackCriteriaId = vm.activityId;

      vm.data.title = vm.data.assignmentSkillsRating[vm.activityId][0];
      vm.data.assignmentSkillsRating[vm.activityId][1] = true;
      vm.data = _.omit(vm.data, 'activityId');
    } else if (vm.contentOption === 'feedback_criteria') {
      vm.data.feedbackCriteria = {
        [vm.activityId]: vm.data.feedbackCriteria[vm.activityId],
      };
      vm.data.feedbackCriteriaId = vm.activityId;

      vm.data.title = vm.data.feedbackCriteria[vm.activityId][0];
      vm.data.feedbackCriteria[vm.activityId][1] = true;
      vm.data = _.omit(vm.data, 'activityId');
    } else if (vm.contentOption === 'practice_video_skills_rating') {
      vm.data.practiceVideoSkillsRating = {
        [vm.activityId]: vm.data.practiceVideoSkillsRating[vm.activityId],
      };
      vm.data.feedbackCriteriaId = vm.activityId;

      vm.data.title = vm.data.practiceVideoSkillsRating[vm.activityId][0];
      vm.data.practiceVideoSkillsRating[vm.activityId][1] = true;
      vm.data = _.omit(vm.data, 'activityId');
    }
    CustomDownloadsModel.createContentDownload($stateParams.catalogId, {
      customReports: _.omit(vm.data, 'id', 'contentOptions'),
    }).then(() => {
      $uibModalInstance.close();
      AlertMessages.success('CUSTOM_DOWNLOADS.CREATE_REPORT.CONTENT_DOWNLOAD_SUCCESS_TITLE', 'CUSTOM_DOWNLOADS.CREATE_REPORT.SUCCESS_MESSAGE', {}, {}, 20000);
    });
  };
}
