import { denormalize } from 'normalizr';
import { RootState } from 'redux/schemas';
import { LectureComponentSchema } from 'redux/schemas/api/lecture-components';
import { MinimalActivity, feInnerPayloadKey, InnerPayload } from 'redux/schemas/models/activity';
import { ComponentType, LectureComponent, NLectureComponent } from 'redux/schemas/models/lecture-component';
import { createSelector } from 'reselect';
import countBy from 'lodash/countBy';
import isEmpty from 'lodash/isEmpty';
import { AutoGeneratedStatus } from 'redux/schemas/models/video';

export const getLectureComponent = (state: RootState, lectureComponentId: number) => (state.models.lectureComponents[lectureComponentId?.toString()] ?? state.app.lecturePage.temporaryComponents[lectureComponentId]);

// TODO: Much of this file should likely go inside selectors/activity.ts and the contents of that file should be moved elsewhere

// If canBeActivityCheck is true, we won't compute the result of whether
// specific components are activities given their configurations and we will
// return a value that says whether that component can surely or potentially be
// an activity.
export const getIsActivity = (state: RootState, lectureComponentId: number, canBeActivityCheck = false) => {
  const lectureComponent = getLectureComponent(state, lectureComponentId);

  const potentialActivityComponentTypes = [ComponentType.PROFILE_COMPLETION, ComponentType.TEAM_FORMATION];

  if (canBeActivityCheck && potentialActivityComponentTypes.includes(lectureComponent.type)) {
    return true;
  }

  switch (lectureComponent.type) {
    case ComponentType.AUDIO:
    case ComponentType.EXERCISE:
    case ComponentType.EXTERNAL_TOOL:
    case ComponentType.POLL:
    case ComponentType.PRIVATE_PEER_EVALUATION:
    case ComponentType.PUBLIC_PEER_EVALUATION:
    case ComponentType.QUIZ:
    case ComponentType.PROGRESSIVE_QUIZ:
    case ComponentType.SURVEY:
    case ComponentType.TEAM_DISCUSSION:
    case ComponentType.TIMED_QUIZ:
    case ComponentType.VIDEO:
    case ComponentType.VIDEO_PRACTICE:
    case ComponentType.TOPIC:
    case ComponentType.LIVE_SESSION:
    case ComponentType.VIDEO_PRACTICE_FEEDBACK:
    case ComponentType.EXERCISE_SKILLS_RATING:
    case ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK:
      return true;
    case ComponentType.TEAM_FORMATION: {
      const teamSet = state.models.teamSets[lectureComponent.teamSet];
      return teamSet.formedByStudents;
    }
    case ComponentType.PROFILE_COMPLETION: {
      const profileRequirement = state.models.profileRequirements[lectureComponent.profileRequirement];

      const {
        orgFields,
        novoedQuestions,
        courseProfileQuestionIds,
      } = profileRequirement;
      const realOrgFields = orgFields?.map(
        (orgFieldId) => state.models.orgProfileFields[orgFieldId],
      );

      const courseProfileQuestions = courseProfileQuestionIds?.map(
        (courseProfileQuestionId) => state.models.courseProfileQuestions[courseProfileQuestionId],
      );

      const existRequiredNovoedQuestions = !!novoedQuestions?.length;

      const existRequiredOrgFields = realOrgFields?.some(
        (orgField) => orgField.isRequired,
      );

      const existRequiredCourseProfileQuestions = courseProfileQuestions?.some(
        (courseProfileQuestion) => courseProfileQuestion.isRequired,
      );

      return existRequiredNovoedQuestions
        || existRequiredOrgFields
        || existRequiredCourseProfileQuestions;
    }
    /** This is called out as a special-case because group formation was listed as an activity
     * in the older Angularjs architecture. But, it was special-cased
     * to hide the to-do icon. I don't think any other behavior is attached
     * to being an activity, so we're making it not an activity in React
     * until any issue is found related */
    case ComponentType.GROUP_FORMATION:
      return false;
    default:
      return false;
  }
};

export const getCanBeAnActivity = (state: RootState, lectureComponentId: number) => getIsActivity(state, lectureComponentId, true);

export const getIsRequiredForCompletion = (state: RootState, lectureComponentId: number) => {
  const payload = getPayload(state, lectureComponentId);
  const component = getLectureComponent(state, lectureComponentId);

  /**
   * Similar to isTodo, need to check isRequiredForCompletion for each video in
   * video and audio list
   */
  if (component.trueType === ComponentType.VIDEO
    || component.trueType === ComponentType.AUDIO) {
    return !isEmpty(payload)
      && (payload as InnerPayload<ComponentType.VIDEO | ComponentType.AUDIO>).some(lv => lv?.isRequiredForCompletion);
  }

  // Note that we don't have type completion for this. Many of our
  // components do not have this property and will intentionally return
  // false-y
  return (payload as any)?.isRequiredForCompletion;
};


/** Get the payload object for a given lecture component ID. 'Payloads' are data objects nested under a lecture component
 * that contains info specific to that component type which are not view related. Exercise components have an 'exercise' prop,
 * video components have a lectureVideos prop, etc. */
export const getPayload = <C extends ComponentType = any>(state: RootState, lectureComponentId: number): InnerPayload<C> => {
  const lectureComponent = getLectureComponent(state, lectureComponentId);

  const denormedLC: LectureComponent<C> = denormalize(lectureComponent, LectureComponentSchema, state.models);

  if (!getCanBeAnActivity(state, lectureComponentId)) {
    return null;
  }

  if (denormedLC.type in feInnerPayloadKey) {
    return denormedLC[feInnerPayloadKey[denormedLC.type]];
  }

  return null;
};

export const getIsTodo = (state: RootState, lectureComponentId: number) => {
  const payload = getPayload(state, lectureComponentId);
  const component = getLectureComponent(state, lectureComponentId);

  if (!getCanBeAnActivity(state, lectureComponentId)) {
    return false;
  }

  /** Video and Audio components are strange; isTodo status is set by setting an isTodo prop
   * on the lecture component when speaking to the API, but the backend reports isTodo status
   * by setting this prop on each of the *videos*, not the lecture component */
  if (component.trueType === ComponentType.VIDEO || component.trueType === ComponentType.AUDIO) {
    return !isEmpty(payload) && (payload as InnerPayload<ComponentType.VIDEO>).every(lv => lv?.isTodo);
  }

  // TODO: We don't have any typings to say that 'payloads' can be activities which must have a isTodo prop
  // Note that some activities can have null payloads, such as peer evals
  return (payload as MinimalActivity)?.isTodo ?? false;
};

export const getDeadline = (state: RootState, lectureComponentId: number) => {
  const lc = getLectureComponent(state, lectureComponentId);

  // A helper function allow using `payload()` below so we can infer the correct payload return type
  // Definitely room for improvement here
  const payload = <C extends ComponentType>(_lc: NLectureComponent<C>) => getPayload<C>(state, _lc.id);

  switch (lc.type) {
    case ComponentType.EXERCISE: {
      return payload(lc).deadline;
    }
    case ComponentType.POLL:
      return payload(lc).expirationDate;
    // TODO: These are broken at the moment. Private peer eval models do a __preprocess that somehow unpacks
    // some data including these deadline props, but I'm not sure how
    // case ComponentType.PRIVATE_PEER_EVALUATION:
    //   return payload(lc).peerEvaluation.deadline;
    // case ComponentType.PUBLIC_PEER_EVALUATION:
    //   return payload(lc).peerEvaluation.deadline;
    case ComponentType.SURVEY:
      return payload(lc).expirationDate;
    case ComponentType.QUIZ:
      return payload(lc).expirationDate;
    case ComponentType.TIMED_QUIZ:
      return payload(lc).expirationDate;
    case ComponentType.TEAM_FORMATION:
      return payload(lc)?.deadline;
    default:
      return null;
  }
};

const statusMapping = {
  not_started: 'not_started',
  approval_needed: 'not_started',
  join: 'not_started',

  started: 'in_progress',
  in_progress: 'in_progress',
  pending: 'in_progress',
  rejected: 'in_progress',

  missed: 'missed',
  rejected_and_missed: 'missed',

  completed: 'completed',
  approved: 'completed',
  above_completed: 'completed',

  // - team/group
  created_a_team: 'completed',
  joined_a_team: 'completed',

  // live session
  attended_manual: 'completed',
  attended_auto: 'completed',
  watched_recording: 'completed',
};

export const getLectureComponentActivityProgress = createSelector(
  getPayload,
  (state, lectureComponentId) => state.models.lectureComponents[lectureComponentId].trueType,
  (lectureComponentPayload, lectureComponentTrueType) => {
    const activityStatus = getActivityStatus(lectureComponentTrueType, lectureComponentPayload);

    return activityStatus && statusMapping[activityStatus]
      ? statusMapping[activityStatus]
      : activityStatus;
  },
);

const getActivityStatus = (lectureComponentTrueType, lectureComponentPayload) => {
  const NOT_STARTED = 'not_started';
  const IN_PROGRESS = 'in_progress';
  const COMPLETED = 'completed';

  switch (lectureComponentTrueType) {
    case ComponentType.AUDIO:
    case ComponentType.VIDEO: {
      const statusCount = countBy(lectureComponentPayload, (payloadList) => payloadList.progress);

      if (statusCount[COMPLETED] === lectureComponentPayload.length) {
        return COMPLETED;
      }
      if (statusCount[NOT_STARTED] === lectureComponentPayload.length) {
        return NOT_STARTED;
      }
      return IN_PROGRESS;
    }
    case ComponentType.LIVE_SESSION: {
      return lectureComponentPayload.attendeeInfo?.status;
    }
    default:
      return lectureComponentPayload.progress;
  }
};

export const getAutoGenerateProcess = (state: RootState, componentType: ComponentType) => state.app.autoGenerateProcess[componentType];

export const getAutoGeneratedInfo = (state: RootState) => state.models.video[state.app.currentVideoId]?.autoGenerated ?? {};

// Checking if any of the current lecture video has auto-generation progress in process
export const getAutoGenerationInProgress = (state: RootState, lecturePageId: number) => {
  const statusToValidate: AutoGeneratedStatus[] = ['in_progress', 'regenerating'];

  const lecturePage = state.models.lecturePages[lecturePageId];
  const videoLectureComponents = lecturePage?.lectureComponents?.map((lectureComponentId) => state.models.lectureComponents[lectureComponentId]).filter(lectureComponent => lectureComponent.type === ComponentType.VIDEO);

  if (videoLectureComponents?.length > 0) {
    const lectureVideoIds = [];
    videoLectureComponents.forEach((videoLectureComponent: NLectureComponent<ComponentType.VIDEO>) => {
      lectureVideoIds.push(...videoLectureComponent.lectureVideos);
    });

    const lectureVideos = [];
    lectureVideoIds.forEach(lectureVideoId => {
      if (state.models.lectureVideos[lectureVideoId]?.video) {
        lectureVideos.push(state.models.video[state.models.lectureVideos[lectureVideoId]?.video]);
      }
    });

    return lectureVideos.some(video => {
      let transcriptIsInProgress = false;
      let captionIsInProgress = false;
      if (video.autoGenerateTranscript && video.autoGenerated?.transcript) {
        transcriptIsInProgress = !video.autoGenerated.transcript?.errorCode && statusToValidate.includes(video.autoGenerated.transcript?.status);
      }
      if (video.autoGenerateCaption && video.autoGenerated?.caption) {
        captionIsInProgress = !video.autoGenerated.caption?.errorCode && statusToValidate.includes(video.autoGenerated.caption?.status);
      }

      return transcriptIsInProgress || captionIsInProgress;
    });
  }

  return false;
};
