import React, { useCallback, useContext, useEffect, useState } from 'react';
import t from 'react-translate';
import { without } from 'underscore';
import { useSelector } from 'react-redux';
import { camelCase } from 'lodash';

import { AngularContext, AngularServicesContext } from 'react-app';
import { Button } from 'react-bootstrap';
import { getCurrentUserId } from 'redux/selectors/users';

import SectionContent from '../section-content';
import { DisableProps } from './integrations';
import { config } from '../../../../../config/pendo.config.json';

const zoomOauthDisableArticleLink = 'https://help.novoed.com/hc/en-us/articles/360061031512';

type ZooomIntegrationProps = {
  onDisable?: (props: DisableProps) => void
  isLecturePage?: boolean
  authenticatedUser?: { email: string, id: number }
  integrationType?: string
};

const ZoomIntegration = (props: ZooomIntegrationProps) => {
  const { $uibModal, RailsRoutes, $location, CurrentPermissionsManager } = useContext(AngularServicesContext);
  const { injectServices, $scope } = useContext(AngularContext);
  const [InstitutionsManager] = injectServices(['InstitutionsManager']);
  const [source, setLiveSessionSettings] = useState($scope.$eval('vm.InstitutionsManager.institution.liveSessionSettings.source'));
  const [hasIntegration, setHasIntegration] = useState(false);
  const [integrationType, setIntegrationType] = useState('');

  const currentUserId = useSelector(getCurrentUserId);
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const checkIntegrationType = (type) => source?.includes(type);

  const integrationSettings = InstitutionsManager.institution.liveSessionSettings?.[camelCase(integrationType)];
  // The InstitutionsManager may not be available when calling from lecture page, so using props
  const authenticatedUser = integrationSettings?.authenticatedUser || props.authenticatedUser;

  useEffect(() => {
    $scope.$watch('vm.InstitutionsManager.institution.liveSessionSettings.source', (newValue) => {
      setLiveSessionSettings(newValue);
    });
  }, [$scope]);

  useEffect(() => {
    const integrationTypes = InstitutionsManager.INTEGRATION_TYPES;

    const zoomAccountLevel = checkIntegrationType(integrationTypes.ZOOM_ACCOUNT_LEVEL);
    const zoomUserLevel = checkIntegrationType(integrationTypes.ZOOM_USER_LEVEL);

    if (props.integrationType) {
      setIntegrationType(props.integrationType);
    } else if (zoomAccountLevel) {
      setIntegrationType(integrationTypes.ZOOM_ACCOUNT_LEVEL);
    } else if (zoomUserLevel) {
      setIntegrationType(integrationTypes.ZOOM_USER_LEVEL);
    } else {
      setIntegrationType(null);
    }

    // Set hasIntegration based on if any integration type matches
    setHasIntegration(zoomAccountLevel || zoomUserLevel);
  }, [source]);

  const configureZoom = () => {
    $uibModal.open({
      templateUrl: 'institutions/templates/configure-zoom-modal.html',
      controller: 'ConfigureZoomModalCtrl as vm',
      resolve: {
      },
    });
  };

  const disableZoom = () => {
    props.onDisable({
      action: () => InstitutionsManager.disableLiveSessionSettings(integrationType),
      onSuccess: () => {
        InstitutionsManager.institution.updateFromReact({
          liveSessionSettings: {
            apiKey: '',
            apiSecret: '',
            source: without(
              InstitutionsManager.institution.liveSessionSettings.source,
              integrationType,
            ),
          },
          zoomEnabled: false,
        });
        setHasIntegration(false);
      },
      successMessage: t.INSTITUTIONS.ADVANCED_SETTINGS.DISABLE_ZOOM_SUCCESS(),
      title: t.INSTITUTIONS.ADVANCED_SETTINGS.DISABLE_INTEGRATION(),
      bodyText: t.INSTITUTIONS.ADVANCED_SETTINGS.DISABLE_ZOOM_DESCRIPTION(),
      confirmDataQa: config.pendo.settings.zoom.disableConfirm,
      cancelDataQa: config.pendo.settings.zoom.disableCancel,
    });
  };

  const zoomDescription = integrationType === 'zoom_account_oauth'
    ? t.INSTITUTIONS.ADVANCED_SETTINGS.ZOOM_ACCOUNT_OAUTH_DESCRIPTION(zoomOauthDisableArticleLink)
    : t.INSTITUTIONS.ADVANCED_SETTINGS.ZOOM_INTEGRATION_DESCRIPTION();

  const authenticateAccountZoomOauth = () => {
    if (integrationType === 'zoom_account_oauth') {
      window.location.href = RailsRoutes.accountZoomOauthUrl(InstitutionsManager.institution.id);
    }
    if (integrationType === 'zoom_oauth') {
      const refererUrl = `${$location.protocol()}://${$location.host()}/#!${$location.path()}${$location.hash()}`;
      window.location.href = RailsRoutes.zoomOAuthInstallationUrl(catalogId, '', refererUrl);
    }
  };

  const getZoomReAuthenticate = () => (
    <div className='d-flex flex-column align-items-center justify-content-center border-top border-danger bg-gray-7 py-2'>
      {props.isLecturePage && (
        <div className='text-small font-weight-bolder mb-2'>
          {t.INSTITUTIONS.ADVANCED_SETTINGS.ZOOM_INVALID.ADMIN_VIEW()}
        </div>
      )}
      {authenticatedUser && (
        <div className='text-small mb-2'>
          {integrationType === 'zoom_account_oauth' || props.integrationType === 'zoom_account_oauth'
            ? t.INSTITUTIONS.ADVANCED_SETTINGS.ZOOM_INVALID.ACCOUNT_OAUTH_MESSSAGE(authenticatedUser.accountEmail)
            : t.INSTITUTIONS.ADVANCED_SETTINGS.ZOOM_INVALID.OAUTH_MESSAGE(`${authenticatedUser.firstName} ${authenticatedUser.lastName}`)}
        </div>
      )}
      {/* Display re-Auth button only for the authenticated user */}
      {currentUserId === authenticatedUser?.id && (
        <Button
          variant='primary'
          onClick={authenticateAccountZoomOauth}
        >
          {t.INSTITUTIONS.ADVANCED_SETTINGS.ZOOM_INVALID.BUTTON()}
        </Button>
      )}
    </div>
  );
  /**  Utilising the same component for reauthenticate and integartion,
   *  so using the prop, `isLecturePage` to differentiate from where it has been called
   */

  if (!props.isLecturePage) {
    return (
      <SectionContent
        header={t.INSTITUTIONS.ADVANCED_SETTINGS.ZOOM_INTEGRATION_TITLE()}
        description={[zoomDescription]}
        showCTAButton={!hasIntegration}
        ctaText={t.INSTITUTIONS.ADVANCED_SETTINGS.CONFIGURE()}
        onAction={() => (hasIntegration ? disableZoom() : configureZoom())}
        buttonVariant={hasIntegration ? 'secondary' : 'primary'}
        dataQa={config.pendo.settings.zoom.cta}
        extras={(
          hasIntegration
          && (
          <div className='w-100'>
            {integrationSettings?.authenticatedStatus === 'not_authenticated'
            && integrationType === 'zoom_account_oauth'
            && getZoomReAuthenticate()}
            <div className='button-bar mt-6'>
              <Button
                variant='secondary'
                onClick={disableZoom}
                data-qa={config.pendo.settings.zoom.cta}
              >
                {t.INSTITUTIONS.ADVANCED_SETTINGS.DISABLE_ZOOM()}
              </Button>
            </div>
          </div>
          )
        )}
      />
    );
  }
  if (props.isLecturePage) {
    return (
      getZoomReAuthenticate()
    );
  }
  return null;
};

export default ZoomIntegration;
