/* @ngInject */
export default function NvTextFieldPrefix() {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      const inputElement = element.next('input');

      element.addClass('nv-text-field-prefix input-group-addon');
      element.html(attrs.nvTextFieldPrefix);

      inputElement.focus(() => {
        inputElement.css('border-left', 'none');
        element.addClass('prefix-focus');
      });

      inputElement.blur(() => {
        element.removeClass('prefix-focus');
      });
    },
  };
}
