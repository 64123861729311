import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getMentionableUsers } from 'redux/actions/video-practice';
import { BaseUser } from 'redux/schemas/models/my-account';
import { getMentionableUsersForSubmission } from 'redux/selectors/video-practice';
import NvCommentForm from 'shared/components/nv-comment-form';
import { SubmissionTab } from 'redux/schemas/models/video-practice';
import RecordVideoComment from 'practice_room/components/submission/record-video-comment';
import { ActionTypes, PracticeSubmissionContext, useSubmitComment } from '../utils';

type PracticeCommentProps = {
  isEdit?: boolean
  isReply?: boolean
  content?: string
  mentionedUser?: BaseUser
  timestampInfo?: string
  afterSubmit?: () => void
  onUpdate?: (comment: any) => Promise<boolean>
  onCancel?: () => void
};

const PracticeCommentForm = ({
  isEdit,
  isReply,
  content,
  mentionedUser,
  timestampInfo,
  onUpdate,
  afterSubmit,
  onCancel,
}: PracticeCommentProps) => {
  const [
    {
      submissionId,
      scenarioId,
      showRecordVideoComment,
      selectedView,
      currentTimestamp,
      isPracticeFeedback,
      showCommentInputRow,
    },
    practiceSubmissionDispatch,
  ] = useContext(PracticeSubmissionContext);

  const mentionableUsers = useSelector((state) => getMentionableUsersForSubmission(state, { submissionId }));
  const {
    mentionables,
  } = useSelector((state) => state.app.videoPracticeSubmissions[submissionId]) ?? {};

  const dispatch = useAppDispatch();

  const onClose = () => {
    practiceSubmissionDispatch({ type: ActionTypes.ON_CLOSE_COMMENT_FORM });
    if (selectedView !== SubmissionTab.COMMENTS) {
      practiceSubmissionDispatch({ type: ActionTypes.SET_SELECTED_VIEW, payload: SubmissionTab.COMMENTS });
    }
    afterSubmit?.();
  };

  const { onSubmit } = useSubmitComment(submissionId, isPracticeFeedback, onClose);

  const loadMentionableUsers = useCallback(() => {
    if (!mentionables?.isLoading && !mentionables?.onceLoaded) {
      dispatch(getMentionableUsers({ scenarioId, submissionId }));
    }
  }, [mentionables?.isLoading, mentionables?.onceLoaded, dispatch, scenarioId, submissionId]);

  if (showRecordVideoComment) {
    return (
      <RecordVideoComment
        onClose={onClose}
        onSubmit={onSubmit}
      />
    );
  }
  if (showCommentInputRow || isEdit || isReply) {
    return (
      <NvCommentForm
        onSubmit={(data) => (isEdit ? onUpdate(data) : onSubmit(data))}
        onCancel={() => { onClose(); onCancel?.(); }}
        timestamp={currentTimestamp as number}
        timestampInfo={timestampInfo}
        mentionableUsers={mentionableUsers}
        loadMentionableUsers={loadMentionableUsers}
        isReply={isReply}
        isEdit={isEdit}
        content={content}
        mentionedUser={mentionedUser}
      />
    );
  }
  return null;
};

export default PracticeCommentForm;
