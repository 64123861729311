import { removeMarkedFile } from 'lecture_pages/components/workflows/file-workflow';
import { isTemporaryLectureComponent } from 'redux/schemas/models/lecture-component';
import store from 'redux/store';
import { clearTemporaryComponents } from 'redux/actions/lecture-components';

/* @ngInject */
export default function AudioListLectureComponentModel(
  $filter,
  $q,
  $uibModal,
  ConfirmationOverlays,
  CurrentCourseManager,
  LectureComponentBaseModel,
  LectureVideoListResources,
  LectureVideoModel,
  LectureVideoResources,
  S3NameSpaces,
  S3UploadFactory,
  _,
  config,
  moment,
  nvUtil,
  ReactLecturePageContext,
) {
  const NOT_STARTED = 'not_started';
  const IN_PROGRESS = 'in_progress';
  const COMPLETED = 'completed';

  class AudioListLectureComponent extends LectureComponentBaseModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      const defaults = {
        type: 'AudioListLectureComponent',
        editFormTemplateUrl: 'lecture_pages/templates/components/audio-list-edit-modal.html',
        editFormController: 'VideoListEditFormModalCtrl',
        videoUploads: [],
        lectureVideos: [],
        componentTodo: false,
        isActivity: true,
        canBeCopied: true,
      };
      super(_.extend({}, defaults, attributes));

      this.__preprocess();
    }

    get directiveName() {
      return 'nv-audio-list-lecture-component';
    }

    __preprocess() {
      let videoLength;
      this.libraryLoaded = false;
      this.libraryVideos = [];

      this.totalPoints = [0, 0];
      this.pointsReceived = 0;
      this.videoWatchTime = 0;
      this.componentTodo = false;

      if (this.lectureVideos.length) {
        this.lectureVideos = _.map(this.lectureVideos, (lectureVideo, index) => new LectureVideoModel(lectureVideo, this.catalogId, this, index));

        this.currentLectureVideo = this.lectureVideos[0];
        if (!this.content) {
          this.content = this.lectureVideos[0] ? this.lectureVideos[0].title : '';
          this.defaultTitle = true;
        }
        _.each(this.lectureVideos, (lv) => {
          videoLength = parseInt(lv.videoLengthInMinutes(), 10);
          this.videoWatchTime += (videoLength === 0) ? 1 : videoLength;
          if (lv.isTodo) {
            this.componentTodo = true;
          }
          if (lv.totalPoints) {
            this.totalPoints[0] += lv.totalPoints[0];
            this.totalPoints[1] += lv.totalPoints[1];
          }
          if (lv.pointsReceived) {
            this.pointsReceived += lv.pointsReceived;
          }
        });
      }

      this.resetNewLectureVideos();
      this.updateStatus();
      this.reorderMode = false;
    }

    updatePointsReceived() {
      this.pointsReceived = _.reduce(this.lectureVideos, (points, lv) => {
        if (lv.pointsReceived) {
          return points + lv.pointsReceived;
        }
        return points;
      }, 0);
    }


    updateStatus() {
      const statusCount = _.countBy(this.lectureVideos, (lectureVideo) => lectureVideo.progress);

      if (statusCount[COMPLETED] === this.lectureVideos.length) {
        this.progress = COMPLETED;
      } else if (statusCount[NOT_STARTED] === this.lectureVideos.length) {
        this.progress = NOT_STARTED;
      } else {
        this.progress = IN_PROGRESS;
      }
    }

    isCompleted() {
      return this.progress === COMPLETED;
    }

    isInProgress() {
      return this.progress === IN_PROGRESS;
    }

    getStatus() {
      return this.progress;
    }

    currentTotalPoints() {
      return nvUtil.getCurrentTotalPoints(this.totalPoints, this.releaseDate, CurrentCourseManager.course.isDecayEnabled());
    }

    // This is here only because it's called in a lecture helper
    createDraft() {
      this.lectureVideos = _.clone(this.lectureVideos);
    }

    saveDraft() {
      // This used to call save(), which is no longer valid in the React architecture
    }

    resetNewLectureVideos() {
      this.newLectureVideos = [];
    }

    removeComponent() {
      this.lecturePage.afterComponentDelete(this);
    }

    // Like much of this file, this is a 1-1 copy of the same function in video-list-lecture-component-model.js
    uploadFiles(files, createNewComponent) {
      const promises = [];

      _.each(files, (file, index) => {
        promises.push(this.__uploadToS3(file, index));
      });

      let componentPromise = null;

      $q.all(promises).then(() => {
        if (this.videoUploads.length) {
          this.resetNewLectureVideos();

          _.each(this.videoUploads, (s3UploadObj, index) => {
            this.newLectureVideos.push({
              video: _.pick(s3UploadObj, ['name', 'uniqueId', 'fileName']),
              index,
            });
          });
        }
      }).then(() => {
        if (createNewComponent) {
          const payLoad = this.getPayload();
          if (payLoad.lectureVideos.length > 0) {
            componentPromise = createNewComponent?.({
              index: this.index,
              ...payLoad,
            });
          } else {
            // Nothing to do
            componentPromise = $q.resolve();
          }
        } else {
          componentPromise = this.save();
        }

        return componentPromise;
      }).then(() => {
        this.videoUploads = [];
        this.__preprocess();
      });
    }

    getNextVideo() {
      if (this.currentLectureVideo) {
        return this.lectureVideos[this.currentLectureVideo.index + 1];
      }

      return undefined;
    }

    /* Admin Functions */
    __uploadToS3(file, index) {
      const deferred = $q.defer();
      const uploadToS3Object = S3UploadFactory.uploadToS3(file, S3NameSpaces.VIDEOS, true);
      this.videoUploads[index] = uploadToS3Object;

      uploadToS3Object.promise.then((resp) => {
        const s3FileData = resp.config.data.file;

        _.extend(uploadToS3Object, {
          fileName: s3FileData.name,
          fileSize: s3FileData.size,
          fileType: s3FileData.type,
          uniqueId: s3FileData.uniqueId,
        });

        deferred.resolve(uploadToS3Object);
      }, () => {
        deferred.resolve();
      });

      return deferred.promise;
    }

    abortUpload(index) {
      this.videoUploads[index].abort();
      this.videoUploads.splice(index, 1);

      // Remove the file for index
      removeMarkedFile(ReactLecturePageContext, this.id, index);

      // delete component if empty after cancel
      if (!this.videoUploads.length
        && !this.lectureVideos.length
        && isTemporaryLectureComponent({
          id: this.id,
        })) {
        store.dispatch(clearTemporaryComponents());
      }
    }

    getPayload() {
      const payload = {
        index: this.index,
        type: this.type,
        content: this.defaultTitle ? null : this.content,
        isTodo: this.componentTodo,
        lectureVideos: [],
      };

      let combined;
      if (!_.isEmpty(this.lectureVideos)) {
        combined = [];
        this.lectureVideos.concat(this.newLectureVideos).forEach((item) => {
          // When we add new lecture videos, they can come from two places: direct uploads
          // from the user's computer, or from the library. If they are direct uploads, then
          // they won't have an id, and we want to add all of them. If they came from the
          // library, then they will have an id, and we need to make sure that we don't add
          // duplicates (see https://novoed.atlassian.net/browse/NOV-83430).
          if (!item.video.id || !combined.some(val => val.video.id === item.video.id)) {
            combined.push(item);
          }
        });
      } else {
        combined = this.newLectureVideos;
      }

      this.resetNewLectureVideos();

      if (combined) {
        _.each(combined, (lv, index) => {
          const audio = { video: lv.video, index };
          if (lv.id) {
            audio.id = lv.id;
          }
          payload.lectureVideos.push(audio);
        });
      }

      return payload;
    }

    isTodo() {
      return this.componentTodo;
    }

    toggleToDo() {
      this.componentTodo = !this.componentTodo;
      this.save().then(() => {
        this.__preprocess();
      });
    }

    isRequiredForCompletion() {
      return _.any(this.lectureVideos, (lv) => lv.isRequiredForCompletion);
    }

    isReleased() {
      const releaseMoment = moment(this.lectureVideos.releaseDate);
      const currentMoment = moment();
      return currentMoment > releaseMoment;
    }

    // Audio library operations
    getLibraryVideos(catalogId) {
      return LectureVideoListResources.getLibraryVideos({
        catalogId: this.catalogId,
      }).$promise.then((response) => {
        this.libraryVideos = _.filter(response.result, (obj) => obj.type === 'Audio');
        this.libraryLoaded = true;
        _.each(this.libraryVideos, (lv) => {
          lv.length = $filter('number')(lv.length / 60, 0);
        });
      });
    }

    addFromLibrary(video) {
      let index;
      if (video.selected) {
        video.selected = false;
        video.justUnselected = true;
        index = _.findIndex(this.lectureVideos, { id: video.id });
        this.newLectureVideos.splice(index, 1);
      } else {
        video.selected = true; // to show checkmark on video
        index = this.lectureVideos.length;
        this.newLectureVideos.push({ video: { id: video.id }, index });
      }
    }

    deleteVideo(video) {
      const screen = `lecture_pages/templates/components/delete-${video.numCoursesUsing > 1 ? 'shared-audio-video' : 'audio'}-confirmation-overlay.html`;
      const modalOverlay = ConfirmationOverlays.openConfirmationModal(screen,
        'AttachModalResolvesToVmCtrl', {
          vmResolves() {
            return {
              course: CurrentCourseManager.course,
              isAudio: true,
              numCoursesUsing: video.numCoursesUsing,
              box1: false,
              box2: false,
            };
          },
        });
      modalOverlay.result.then(() => {
        LectureVideoResources.delete({ catalogId: this.catalogId, videoId: video.id }).$promise.then((response) => {
          this.libraryVideos.splice(_.findIndex(this.libraryVideos, { id: video.id }), 1);
          this.lectureVideos.splice(_.findIndex(this.lectureVideos, { id: video.id }), 1);
        });
      });
    }

    editIndividualVideo(lectureVideo) {
      const modal = $uibModal.open({
        templateUrl: 'lecture_pages/templates/components/individual-audio-edit-modal.html',
        controller: 'IndividualVideoEditCtrl as vm',
        windowClass: 'full-screen-modal-handheld full-screen-modal-tablet large-modal individual-video-edit-modal',
        resolve: {
          lectureVideo,
          liveSessionId: null,
          recording: null,
          onSuccessfulEdit: () => null,
        },
      }).closed.then(() => {
        this.__preprocess(); // to update video thumbnail, name etc.
      });
    }

    deleteVideoFromComponent(videoId) {
      const index = _.findIndex(this.lectureVideos, (lv) => (lv.video.id === videoId));
      if (index > -1) {
        this.lectureVideos.splice(index, 1);
        if (index === 0) {
          this.currentLectureVideo = _.first(this.lectureVideos);
        }
        return this.save().then(() => {
          this.__preprocess();
        });
      }

      return undefined;
    }

    get deleteyKey() {
      return this.lectureVideos.length > 1 && 'LECTURE_PAGES.COMPONENTS.VIDEO_LIST.DELETE';
    }
  }

  AudioListLectureComponent.get = (catalogId, id) => LectureVideoListResources.get({
    catalogId,
    id,
  }).$promise.then((response) => new AudioListLectureComponent(_.extend(response.result, { catalogId })));


  AudioListLectureComponent.iconClass = 'icon-audio';
  AudioListLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.AUDIO_LIST.DESCRIPTION';
  AudioListLectureComponent.shownOnPageOnCreate = true;
  AudioListLectureComponent.showModalBeforeCreate = true;
  AudioListLectureComponent.allowedFileTypes = config.files.allAudioExtensions;
  AudioListLectureComponent.maxVideoFileSize = `${config.files.videoFileSizeLimitGb}GB`;
  AudioListLectureComponent.pendoTagName = config.pendo.lectureEdit.audio;

  return AudioListLectureComponent;
}
