/* @ngInject */
export default function PeerEvaluationLectureComponentModel(
  _,
  moment,
  $translate,
  $window,
  nvUtil,

  LectureComponentBaseModel,
  EvaluationsTaskModel,
  CurrentCourseManager,
  ReportModel,
  RailsRoutes,
) {
  class PeerEvaluationLectureComponent extends LectureComponentBaseModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      const defaults = {
        type: 'PeerEvaluationLectureComponent',
        editFormTemplateUrl: 'lecture_pages/templates/components/feedback-edit-modal.html',
        editFormController: 'FeedbackEditFormModalCtrl',
        peerEvaluation: {
          isFeedbackPublic: true,
          title: '',
          description: `<div class="froala-style-regular">${$translate.instant('LECTURE_PAGES.COMPONENTS.PUBLIC_PEER_EVALUATION.FEEDBACK_DESCRIPTION', {
            learnersAlias: CurrentCourseManager.course.learnersName.downcasedPluralized,
            courseAlias: CurrentCourseManager.course.offeringName.downcasedSingularized,
            teachingTeamAlias: CurrentCourseManager.course.teachingTeamName.downcasedSingularized,
          })}</div>`,
          exerciseId: null,
          targetGoal: 5,
          isTodo: false,
        },
        updateExercisesAfterCreationDeletion: true,
        isActivity: true,
        canBeCopied: true,
      };
      super(_.extend({}, defaults, attributes));

      this.__preprocess();

      if (useDefaults) {
        this.createDraft();
      }
    }

    get directiveName() {
      return 'nv-peer-evaluation-lecture-component';
    }

    get fullWidth() {
      return true;
    }

    __preprocess() {
      const evaluationsTask = new EvaluationsTaskModel(_.extend(this.peerEvaluation, { catalogId: this.catalogId }));

      this.evaluationsTask = evaluationsTask;

      this.evaluationsTask.expiringPointsPerPeerReview = nvUtil.getCurrentTotalPoints(
        this.evaluationsTask.pointsPerPeerReview,
        this.evaluationsTask.releaseDate,
        CurrentCourseManager.course.isDecayEnabled(),
      );
      this.scheduledCommunicationsCount = this.peerEvaluation?.scheduledCommunicationsCount;


      if (this.evaluationsTask.isFeedbackPublic) {
        _.each(this.evaluationsTask.evaluations, ReportModel.normalizeSubmissionData);
        // prevent slick carousel from breaking when ReportsManager tries to update this
        this.evaluationsTask.carouselSubmissions = angular.copy(this.evaluationsTask.evaluations);

        _.each(this.evaluationsTask.carouselSubmissions, (submission) => {
          submission.exercise = this.evaluationsTask.requiredExercise;
        });

        // To add extra card at end of submissions
        const carouselAddition = {
          isPlaceholder: true,
          lowSubmissionCount: undefined,
        };

        if (this.evaluationsTask.carouselSubmissions.length) {
          if (this.evaluationsTask.carouselSubmissions.length < this.evaluationsTask.totalSubmissionsForPublicReview) {
            // If more submissions exist in gallery
            carouselAddition.lowSubmissionCount = false;
            this.evaluationsTask.carouselSubmissions.push(carouselAddition);
          } else if (this.evaluationsTask.carouselSubmissions.length < this.evaluationsTask.targetGoal) {
            // Not enough submissions to show
            carouselAddition.lowSubmissionCount = true;
            this.evaluationsTask.carouselSubmissions.push(carouselAddition);
          }
        }
      } else {
        this.evaluationsTask.isFeedbackPrivate = true;
      }
    }

    updateComponentStatus(componentType, componentId, data) {
      if (componentType === 'exercise_peer_review' && componentId === this.evaluationsTask.id) {
        let owner;

        _.extend(this.evaluationsTask, data.peerEvaluation);
        if (data.owner instanceof ReportModel) {
          ({ owner } = data);
        } else if (data.owner.owner instanceof ReportModel) {
          ({ owner } = data.owner);
        }
        const report = _.findWhere(this.evaluationsTask.carouselSubmissions, { id: owner.id });
        if (report) {
          report.gavePublicFeedback = true;
        }
      }
    }

    setRealComponent(component) {
      this.realComponent = component;
    }

    /* Admin Functions */
    createDraft() {
      this.feedbackDraft = _.clone(this.peerEvaluation);
    }

    getPayload() {
      return {
        peerEvaluation: _.pick(this.evaluationsTask, ['exerciseId', 'targetGoal', 'title', 'description', 'isTodo', 'expirationDate', 'hardDeadline']),
      };
    }

    saveDraft() {
      _.extend(this.evaluationsTask, this.feedbackDraft);
    }

    afterCreate(catalogId) {
      if (this.evaluationsTask.isFeedbackPrivate) {
        $window.location = RailsRoutes.editPeerEvaluationsPath(catalogId, this.evaluationsTask.exerciseId);
      }
    }

    moveToWarningMessages(targetLecturePage) {
      const warningMessages = super.moveToWarningMessages(targetLecturePage);

      if (this.evaluationsTask.requiredExercise
          && moment() < moment(this.evaluationsTask.requiredExercise.releaseDate)
          && !targetLecturePage.released
          && (moment(this.evaluationsTask.requiredExercise.releaseDate) > moment(targetLecturePage)
            || (moment(this.evaluationsTask.requiredExercise.deadline) > moment(targetLecturePage) && !this.evaluationsTask.requiredExercise.submissionsViewableBeforeDeadline)
          )
      ) {
        warningMessages.push($translate.instant('LECTURE_PAGES.COMPONENTS.FEEDBACK_SHARED.MOVE_WARNING', CurrentCourseManager.course.getAliases()));
      }

      return warningMessages;
    }

    removeExercise(exercise) {
      if (this.evaluationsTask.requiredExercise?.id === exercise.id) {
        this.evaluationsTask.requiredExercise = null;
      }

      this.peerEvaluation.exercise = null;
      this.peerEvaluation.exerciseId = null;
      this.peerEvaluation.requiredExercise = null;
    }

    hasDeadline() {
      return !!this.evaluationsTask.expirationDate;
    }

    addDefaultDeadline() {
      this.evaluationsTask.expirationDate = this.defaultDeadline;
      this.save();
    }

    removeDeadline() {
      this.evaluationsTask.expirationDate = null;
      this.save();
    }

    get deadline() {
      return this.evaluationsTask.deadline;
    }

    isTodo() {
      return this.evaluationsTask.isTodo;
    }

    toggleToDo() {
      this.evaluationsTask.isTodo = !this.evaluationsTask.isTodo;
      this.save();
    }

    isRequiredForCompletion() {
      return this.evaluationsTask.isRequiredForCompletion;
    }

    isReleased() {
      const releaseMoment = moment(this.evaluationsTask.releaseDate);
      const currentMoment = moment();
      return currentMoment > releaseMoment;
    }

    isDueSoon() {
      return super.isDueSoon(this.evaluationsTask.expirationDate);
    }
  }

  PeerEvaluationLectureComponent.iconClass = 'icon-comments';
  PeerEvaluationLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.DESCRIPTION';
  PeerEvaluationLectureComponent.unavailabilityDescriptionKey = 'LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.UNAVAILABLE';
  PeerEvaluationLectureComponent.showModalBeforeCreate = true;

  return PeerEvaluationLectureComponent;
}
