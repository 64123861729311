import { css } from '@emotion/react';
import React, { useContext } from 'react';
import { SanitizationLevel, sanitize } from 'froala/helpers/sanitizer';
import { AngularServicesContext } from 'react-app';
import { extend } from 'underscore';

import t from 'react-translate';
import { CombinedInstitution, RootState } from 'redux/schemas';
import { Course } from 'redux/schemas/models/course';
import ClickableContainer from 'components/clickable-container';
import { NvUserAvatar } from 'components/nv-user-avatar';
import { NvAvatar, NvAvatarDefaultBkg } from 'components/nv-avatar';
import { CourseObjectBookmark, DiscussionBookmarkComponent, TeamDiscussionBookmarkComponent } from 'redux/schemas/models/bookmark';
import { boldFontWeight, headerLineHeight, textSmallLineHeight, textXSFontSize } from 'styles/global_defaults/fonts';
import { standardSpacing, doubleSpacing, halfSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import { useSelector } from 'react-redux';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { gray2 } from 'styles/global_defaults/colors';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';

type DiscussionSnippetProps = {
  bookmark: CourseObjectBookmark
};

const DiscussionSnippet = ({
  bookmark: { catalogId, component, lecturePageId, lectureComponentId },
}: DiscussionSnippetProps) => {
  const styles = css`
    &.discussion-snippet {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${standardSpacing}px;
      padding-left: ${doubleSpacing}px;

      .text-wrapper {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: hidden;
        margin-right: ${standardSpacing}px;
        flex: 1;

        .title {
          line-height: ${headerLineHeight}px;
        }

        .snippet {
          margin-bottom: ${halfSpacing}px;
        }

        .subtitle {
          display: flex;
          font-weight: ${boldFontWeight};
          color: ${gray2};
          font-size: ${textXSFontSize}px;

          .lecture-page-name, .course-name {
            max-width: 50%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .middot {
            margin-left: ${quarterSpacing}px;
            margin-right: ${quarterSpacing}px;
          }
        }
      }
    }
  `;

  const angularServices = useContext(AngularServicesContext);
  const { $state, FlyoutModalManager } = angularServices;

  const {
    id,
    body,
    title,
    lecturePageName,
    courseName,
    user,
    forum,
    expired,
  } = component as DiscussionBookmarkComponent;

  const { team } = (component as TeamDiscussionBookmarkComponent);
  const sanitizedBody = sanitize(body, SanitizationLevel.BOOKMARK_SNIPPET);
  const header = team ? title : forum?.title;

  const currentInstitution = useSelector<RootState, CombinedInstitution>(getCurrentInstitution);
  const course = useSelector<RootState, Course>((state) => state.models.courses[catalogId]);
  const avatarColor = course?.headerColor ?? currentInstitution.brandColor;

  const onClickSnippet = () => {
    if (expired) { return; }

    FlyoutModalManager?.closeFlyout();

    const linkParams = {
      catalogId,
    };

    if (team) {
      extend(linkParams, {
        teamId: team.id,
        postId: id,
      });

      $state.go('team-workspace-post-direct-link', linkParams);
    } else {
      const goToLecturePage = !!lecturePageId;

      if (goToLecturePage) {
        extend(linkParams, {
          id: lecturePageId,
          lectureActivityId: lectureComponentId,
        });
      } else {
        extend(linkParams, {
          topicId: forum.id,
          postId: id,
        });
      }

      $state.go(goToLecturePage ? 'lecture-page' : 'post-direct-link', linkParams);
    }
  };

  const isTeamWorkspacePost = !!team && !lecturePageId && !lectureComponentId;

  return (
    <ClickableContainer className='discussion-snippet' css={styles} onClick={onClickSnippet}>
      <div className='text-wrapper'>
        <div className='title font-weight-bold'>
          {isTeamWorkspacePost
            ? t.LHS.BOOKMARKS.SNIPPET_TITLE.TEAM_WORKSPACE_POST(team?.teamSet?.isGroup?.toString())
            : header}
        </div>
        <ResponsivelyEmbeddedAngularHTML
          template={sanitizedBody}
          className='snippet text-gray-2 text-xs'
          angularServices={angularServices}
        />
        <div className='subtitle'>
          {lecturePageName && (
            <>
              <div className='lecture-page-name'>{lecturePageName}</div>
              <div className='middot'>&middot;</div>
            </>
          )}
          <div className='course-name'>{courseName}</div>
        </div>
      </div>
      {team ? (
        <NvAvatar imageUrl={team.profilePicture} size='md' borderType='square' defaultBkg={{ type: NvAvatarDefaultBkg.SOLID_COLOR, color: avatarColor }} />
      ) : (
        <NvUserAvatar
          tooltipEnabled={false}
          directToProfile={false}
          displayRoleBadge={false}
          user={user}
          size='md'
          borderType='round'
        />
      )}
    </ClickableContainer>
  );
};

export default DiscussionSnippet;
