import NvRouter from 'shared/components/nv-router';
import InstitutionDashboardHome from './institution-dashboard-home';
import OrgLevelUserManagement from './org-user-management/org-level-user-management';

type RouteItem = {
  path: string,
  component: any,
  exact?: boolean,
  strict?: boolean,
};

const routes: RouteItem[] = [
  {
    path: '/institutions/:id/roles/:tab',
    component: OrgLevelUserManagement,
  },
  {
    path: '/institutions/:id/',
    component: InstitutionDashboardHome,
    exact: false,
  },
];

const RouterRoot = () => (
  <NvRouter
    basename='/#!'
    routes={routes}
  />
);

export default RouterRoot;
