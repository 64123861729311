import { css } from '@emotion/react';
import { useState, Fragment, useEffect } from 'react';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { getCourseAliases } from 'redux/selectors/course';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { gray3, gray5 } from 'styles/global_defaults/colors';
import NvIcon from 'shared/components/nv-icon';
import { useAppDispatch } from 'redux/store';
import { bulkUpdateTeams, getAllUsersForCSV } from 'redux/actions/teams';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { getChangesToTeams, getValidTeamIds, getValidUsers } from 'redux/selectors/team';
import NvFilePicker from 'shared/components/nv-filepicker';
import { ErrorType, parseCSV } from 'shared/csv-utils';
import ClickableContainer from 'components/clickable-container';
import * as yup from 'yup';
import { config } from '../../../config/pendo.config.json';

type BulkUpload = {
  isTeam: boolean,
  isUpdate: boolean,
  teamSetId: number,
  downloadCSV: () => void,
  onClose: () => void,
};

const BulkUploadModal = ({
  isTeam,
  isUpdate,
  teamSetId,
  downloadCSV,
  onClose,
}: BulkUpload) => {
  const styles = css`
    .w-90 {
      width: 90px;
    }
    .w-115 {
      width: 115px;
    }
    .horizontal-line {
      margin-top: ${halfSpacing}px;
      margin-bottom: ${halfSpacing}px;
      border-top: 1px solid ${gray5};
      &.darker {
        border-top: 1px solid ${gray3};
      }
    }
  `;

  const headers = isTeam
    ? [
      { key: 'teamId', headerName: 'Team ID' },
      { key: 'teamName', headerName: 'Team Name' },
      { key: 'firstName', headerName: 'First Name' },
      { key: 'lastName', headerName: 'Last Name' },
      { key: 'email', headerName: 'Email' },
      { key: 'isTeamLead', headerName: 'Is Team Lead? (Yes/No)' },
    ] : [
      { key: 'groupId', headerName: 'Group ID' },
      { key: 'groupName', headerName: 'Group Name' },
      { key: 'isOpen', headerName: 'Is Open? (Yes/No)' },
      { key: 'firstName', headerName: 'First Name' },
      { key: 'lastName', headerName: 'Last Name' },
      { key: 'email', headerName: 'Email' },
      { key: 'isAdmin', headerName: 'Is Admin? (Yes/No)' },
    ];


  const dispatch = useAppDispatch();
  const [page, setPage] = useState<1 | 2>(1);
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [blockingError, setBlockingError] = useState(null);
  const [teamsWithErrors, setTeamsWithErrors] = useState([]);
  const [learners, setLearners] = useState([]);
  const [allData, setAllData] = useState([]);

  const aliases = useSelector((state) => getCourseAliases(state));
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const validIds = useSelector((state) => getValidTeamIds(state, isTeam));
  const validUsers = useSelector((state) => getValidUsers(state, isTeam));
  const changes = useSelector(state => getChangesToTeams(state, isTeam, learners));

  const teamValidationSchema = yup.object().shape({
    teamId: yup.string().oneOf(validIds, t.TEAM_FACILITATION.BULK_UPLOAD.ERROR_MESSAGE('Team ID')).required(),
    teamName: yup.mixed().nullable().required(t.VALIDATION.IS_A_REQUIRED_FIELD('Team Name')).when('teamId', {
      is: (val) => !!Number(val) || val === 'CREATE NEW',
      then: yup.string().required(t.VALIDATION.IS_A_REQUIRED_FIELD('Team Name')),
    }),
    firstName: yup.string().required(t.VALIDATION.IS_A_REQUIRED_FIELD('First Name')),
    lastName: yup.string().required(t.VALIDATION.IS_A_REQUIRED_FIELD('Last Name')),
    email: yup.string().transform((value, originalValue) => {
      return value.toLowerCase();
    }).required(t.VALIDATION.IS_A_REQUIRED_FIELD('Email')).oneOf(validUsers, t.TEAM_FACILITATION.BULK_UPLOAD.ERROR_MESSAGE('Email')).email(),
    isTeamLead: yup.mixed().nullable().required(t.VALIDATION.IS_A_REQUIRED_FIELD('Is Team Lead')).when('teamId', {
      is: (val) => !!Number(val) || val === 'CREATE NEW',
      then: yup.string().lowercase().oneOf(['yes', 'no'], t.TEAM_FACILITATION.BULK_UPLOAD.BLOCKING_ERROR.NOT_VALID_MESSAGE('Is Team Lead', 'yes, no')).required(t.VALIDATION.IS_A_REQUIRED_FIELD('Is Team Lead')),
    }),
  });

  const groupValidationSchema = yup.object().shape({
    groupId: yup.string().oneOf(validIds, t.TEAM_FACILITATION.BULK_UPLOAD.ERROR_MESSAGE('Group ID')).required(),
    groupName: yup.mixed().nullable().required(t.VALIDATION.IS_A_REQUIRED_FIELD('Group Name')).when('groupId', {
      is: (val) => !!Number(val) || val === 'CREATE NEW',
      then: yup.string().required(t.VALIDATION.IS_A_REQUIRED_FIELD('Group Name')),
    }),
    isOpen: yup.mixed().nullable().required(t.VALIDATION.IS_A_REQUIRED_FIELD('Is Open')).when('groupId', {
      is: (val) => !!Number(val) || val === 'CREATE NEW',
      then: yup.string().lowercase().oneOf(['yes', 'no'], t.TEAM_FACILITATION.BULK_UPLOAD.BLOCKING_ERROR.NOT_VALID_MESSAGE('Is Open', 'yes, no')).required(t.VALIDATION.IS_A_REQUIRED_FIELD('Is Open')),
    }),
    firstName: yup.string().required(t.VALIDATION.IS_A_REQUIRED_FIELD('First Name')),
    lastName: yup.string().required(t.VALIDATION.IS_A_REQUIRED_FIELD('Last Name')),

    email: yup.string().transform((value, originalValue) => {
      return value.toLowerCase();
    }).oneOf(validUsers, t.TEAM_FACILITATION.BULK_UPLOAD.ERROR_MESSAGE('Email')).required(t.VALIDATION.IS_A_REQUIRED_FIELD('Email')).email(),
    isAdmin: yup.mixed().nullable().required(t.VALIDATION.IS_A_REQUIRED_FIELD('Is Admin')).when('groupId', {
      is: (val) => !!Number(val) || val === 'CREATE NEW',
      then: yup.string().lowercase().oneOf(['yes', 'no'], t.TEAM_FACILITATION.BULK_UPLOAD.BLOCKING_ERROR.NOT_VALID_MESSAGE('Is Admin', 'yes, no')).required(t.VALIDATION.IS_A_REQUIRED_FIELD('Is Admin')),
    }),
  });

  useEffect(() => {
    if (changes.newTeams?.length || changes.teamsToDelete?.length || changes.teamsToUpdate?.length) {
      setPage(2);
    } else if (learners.length) {
      setPage(1);
      setBlockingError({ type: 'no_changes_found', textBlack: true });
      setLearners([]);
    }
  }, [changes.newTeams, changes.teamsToDelete, changes.teamsToUpdate, learners.length]);

  useEffect(() => {
    if (isDownloading) {
      downloadCSV();
      setIsDownloading(false);
    }
  }, [isDownloading]);

  const onFileError = (err) => {
    console.log('err:', err);
  };

  const onCSVSelected = (files: File[]) => {
    const [file] = files;

    if (!file) {
      return;
    }

    parseCSV({
      headers,
      file,
      validationSchema: isTeam ? teamValidationSchema : groupValidationSchema,
      callback: parseCallback,
      includeRowNos: true,
    });
  };

  const parseCallback = (response) => {
    setBlockingError(null);
    setTeamsWithErrors([]);

    const blockingErrorIndex = response.errors.findIndex((err) => err.type !== ErrorType.VALIDATION);
    if (blockingErrorIndex !== -1) {
      setBlockingError(response.errors[blockingErrorIndex]);
      setLearners([]);
      setPage(1);
      return;
    }

    if (!response.data.length) {
      setBlockingError({ type: 'no_valid_rows' });
      setLearners([]);
      setPage(1);
      return;
    }

    setTeamsWithErrors(response.errors.map(error => ({ ...error, firstName: response.rawData[error.row - 1].firstName, lastName: response.rawData[error.row - 1].lastName })));
    setLearners(response.data);
    setAllData(response.rawData.map((row, index) => ({ ...row, rowNo: index + 1 })));
  };

  const submit = async () => {
    setIsSubmitting(true);
    const teamCount = changes.newTeams.length + changes.teamsToDelete.length + changes.teamsToUpdate.length;
    const translationValues = { isTeam, ...aliases.groupAliases, ...aliases.teamAliases, teamCount };
    const invalidRows = teamsWithErrors.map(team => ({ rowNo: team.row, errorMessage: team.message, firstName: team.firstName, lastName: team.lastName }));
    await dispatch(bulkUpdateTeams({
      catalogId,
      teamSetId,
      headers: headers.map(header => header.headerName),
      learners: allData,
      invalidRows,
      isUpdate,
      translationValues,
    }));
    dispatch(getAllUsersForCSV({ catalogId, teamSetId, isTeam, getTeamUsers: isUpdate }));
    onClose();
  };

  return (
    <div className='h-100 text-body p-4 d-flex flex-column align-items-center justify-content-space-between' css={styles}>
      {page === 1
        ? (
          <Fragment>
            {blockingError ? (
              <Fragment>
                <div className={`w-100 ${blockingError.textBlack ? '' : 'text-danger'} mb-4`}>{t.TEAM_FACILITATION.BULK_UPLOAD.BLOCKING_ERROR[blockingError.type.toUpperCase()]()}</div>
                {blockingError.columns?.map((col) => <div className='w-100' key={col}>{t.TEAM_FACILITATION.BULK_UPLOAD.COLUMN()} <span className='font-weight-bolder'>{headers.find((header) => header.key === col)?.headerName ?? col}</span><div className='horizontal-line' /></div>)}
                <div className='w-100 mt-4'>{blockingError.type === 'no_valid_rows' && t.TEAM_FACILITATION.BULK_UPLOAD.BLOCKING_ERROR.CANT_FIND_USERS()}{t.TEAM_FACILITATION.BULK_UPLOAD.BLOCKING_ERROR.CHECK_YOUR_CSV()}</div>
              </Fragment>
            ) : (
              <div>{isUpdate
                ? t.TEAM_FACILITATION.BULK_UPLOAD.UPDATE_DESCRIPTION({ ...aliases.groupAliases, ...aliases.teamAliases, isTeam })
                : t.TEAM_FACILITATION.BULK_UPLOAD.CREATE_DESCRIPTION({ ...aliases.learnersAliases })}
              </div>
            )}
            <Button variant='secondary' disabled={isDownloading} className='mt-6' onClick={() => setIsDownloading(true)} data-qa={config.pendo.manageAllTeamsGroups.bulkUpdateModal.downloadTeamsGroupsTemplate}>{isDownloading ? t.FILE_UPLOAD.DOWNLOADING() : t.FILE_UPLOAD.DOWNLOAD()}</Button>
            {instructionsVisible ? (
              <Fragment>
                <div className='w-100 mt-6 font-weight-bold'>{t.TEAM_FACILITATION.BULK_UPLOAD.INSTRUCTIONS.TITLE()}</div>
                <ul>
                  {isUpdate
                    ? (
                      <Fragment>
                        <li>{t.TEAM_FACILITATION.BULK_UPLOAD.INSTRUCTIONS.EXISTING_TEAMS({ ...aliases.groupAliases, ...aliases.teamAliases, isTeam, teamId: isTeam ? 'Team ID' : 'Group ID' })}</li>
                        <li>{t.TEAM_FACILITATION.BULK_UPLOAD.INSTRUCTIONS.TO_ASSIGN_TO_EXISTING({ ...aliases.learnersAliases, ...aliases.groupAliases, ...aliases.teamAliases, isTeam, teamId: isTeam ? 'Team ID' : 'Group ID', teamName: isTeam ? 'Team Name' : 'Group Name' })}</li>
                        <li>{t.TEAM_FACILITATION.BULK_UPLOAD.INSTRUCTIONS.TO_REMOVE({ ...aliases.learnersAliases, ...aliases.groupAliases, ...aliases.teamAliases, isTeam, teamId: isTeam ? 'Team ID' : 'Group ID', noTeam: isTeam ? 'NO TEAM' : 'NO GROUP' })}</li>
                        <li>{t.TEAM_FACILITATION.BULK_UPLOAD.INSTRUCTIONS.TO_ASSIGN_TO_NEW({ ...aliases.learnersAliases, ...aliases.groupAliases, ...aliases.teamAliases, isTeam, createNew: 'CREATE NEW', teamId: isTeam ? 'Team ID' : 'Group ID' })}</li>
                        <li>{t.TEAM_FACILITATION.BULK_UPLOAD.INSTRUCTIONS.PROVIDE_INFORMATION({ ...aliases.groupAliases, ...aliases.teamAliases, isTeam })}</li>
                      </Fragment>
                    )
                    : (
                      <Fragment>
                        <li>{t.TEAM_FACILITATION.BULK_UPLOAD.INSTRUCTIONS.TO_CREATE({ ...aliases.learnersAliases, ...aliases.groupAliases, ...aliases.teamAliases, isTeam, createNew: 'CREATE NEW', teamId: isTeam ? 'Team ID' : 'Group ID' })}</li>
                        <li>{t.TEAM_FACILITATION.BULK_UPLOAD.INSTRUCTIONS.PROVIDE_NAME(isTeam ? 'Team Name' : 'Group Name')}</li>
                      </Fragment>
                    )}
                </ul>
                <div className='w-100 mt-3'>{t.TEAM_FACILITATION.BULK_UPLOAD.INSTRUCTIONS.REFER_TO_HELPDESK(isTeam ? 'https://help.novoed.com/hc/en-us/articles/214632183' : 'https://help.novoed.com/hc/en-us/articles/214632043')}</div>
                <ClickableContainer className='mt-4 text-primary' onClick={() => setInstructionsVisible(false)} data-qa={config.pendo.manageAllTeamsGroups.bulkUpdateModal.hideBulkUpdateInstructions}>{t.TEAM_FACILITATION.BULK_UPLOAD.HIDE_INSTRUCTIONS()}</ClickableContainer>
              </Fragment>
            ) : (
              <ClickableContainer className='mt-4 text-primary' onClick={() => setInstructionsVisible(true)} data-qa={config.pendo.manageAllTeamsGroups.bulkUpdateModal.seeBulkUpdateInstructions}>{t.TEAM_FACILITATION.BULK_UPLOAD.SEE_INSTRUCTIONS()}</ClickableContainer>
            )}
            <div className='horizontal-line darker w-100 mt-6' />
            <div className='w-100 mt-6'>{t.TEAM_FACILITATION.BULK_UPLOAD.PLEASE_SELECT_CSV()}</div>
            <NvFilePicker
              multiple={false}
              accept={['.csv', 'text/csv']}
              onChange={(e) => onCSVSelected(e)}
              onSelectError={onFileError}
            >
              <Button className='mt-6' data-qa={config.pendo.manageAllTeamsGroups.bulkUpdateModal.uploadTeamsGroupsCsv}>{t.FORM.UPLOAD_CSV()}</Button>
            </NvFilePicker>
          </Fragment>
        )
        : (
          <div className='w-100'>
            {!!teamsWithErrors.length && (
              <div className='mb-6'>
                <div className='mb-6'>{t.TEAM_FACILITATION.BULK_UPLOAD.ERRORS_FOUND_DESCRIPTION()}</div>
                <div className='d-flex'>
                  <NvIcon icon='warning' size='medium' className='color-alert d-inline-block mr-2' />
                  <div className='font-weight-bolder'>{t.TEAM_FACILITATION.BULK_UPLOAD.TEAMS_WITH_ERRORS({ ...aliases.teamAliases, ...aliases.groupAliases, isTeam })}</div>
                </div>
                {teamsWithErrors.map((error) => (
                  <div className='my-2'>
                    <span className='d-inline-block w-90'>{t.TEAM_FACILITATION.BULK_UPLOAD.ROW(error.row)}</span>
                    <span className='d-inline-block w-115'>{t.TEAM_FACILITATION.BULK_UPLOAD.COLUMN()} {error.column}</span>
                    <span className='text-danger'>{error.message}</span>
                    <div className='horizontal-line' />
                  </div>
                ))}
              </div>
            )}
            {(changes.newTeams.length && !changes.teamsToDelete.length && !changes.teamsToUpdate.length) ? (
              <div className='mb-6'>{t.TEAM_FACILITATION.BULK_UPLOAD.NEW_TEAMS_FOUND_DESCRIPTION({ ...aliases.teamAliases, ...aliases.groupAliases, isTeam, newTeamsCount: changes.newTeams.length })}</div>
            ) : (
              <div className='mb-6'>{t.TEAM_FACILITATION.BULK_UPLOAD.CHANGES_FOUND_DESCRIPTION()}</div>
            )}
            {!!changes.newTeams.length && (
              <Fragment>
                <div className='d-flex mt-4'>
                  <NvIcon icon='invitations' size='medium' className='color-primary d-inline-block mr-2' />
                  <div className='font-weight-bolder'>{t.TEAM_FACILITATION.BULK_UPLOAD.NEW_TEAMS_FOUND({ ...aliases.teamAliases, ...aliases.groupAliases, isTeam })}</div>
                </div>
                {changes.newTeams.map(({ teamName, totalMembers, teamLeadName }) => (
                  <div className='my-2'>
                    {t.TEAM_FACILITATION.BULK_UPLOAD.NEW_TEAM_ROW({ ...aliases.teamAliases, ...aliases.groupAliases, isTeam, teamName, totalMembers, hasTeamLead: !!teamLeadName, teamLeadName })}
                    <div className='horizontal-line' />
                  </div>
                ))}
              </Fragment>
            )}
            {!!changes.teamsToDelete.length && (
              <Fragment>
                <div className='d-flex mt-4'>
                  <NvIcon icon='invitations' size='medium' className='color-primary d-inline-block mr-2' />
                  <div className='font-weight-bolder'>{t.TEAM_FACILITATION.BULK_UPLOAD.TEAMS_TO_DELETE({ ...aliases.teamAliases, ...aliases.groupAliases, isTeam })}</div>
                </div>
                {changes.teamsToDelete.map((team) => <div className='my-2'>{t.TEAM_FACILITATION.BULK_UPLOAD.TEAM_DELETE_ROW(team.teamName)}<div className='horizontal-line' /></div>)}
              </Fragment>
            )}
            {!!changes.teamsToUpdate.length && (
              <Fragment>
                <div className='d-flex mt-4'>
                  <NvIcon icon='invitations' size='medium' className='color-primary d-inline-block mr-2' />
                  <div className='font-weight-bolder'>{t.TEAM_FACILITATION.BULK_UPLOAD.TEAMS_TO_UPDATE({ ...aliases.teamAliases, ...aliases.groupAliases, isTeam })}</div>
                </div>
                {changes.teamsToUpdate.map(({ teamName, membersAdded, membersRemoved, listOfAdmins }) => (
                  <div className='my-2'>
                    <span className='font-weight-bolder'>{teamName}: </span>
                    {membersAdded ? <span>{t.TEAM_FACILITATION.BULK_UPLOAD.ADD_MEMBERS(membersAdded)}</span> : null}
                    {(membersAdded && (membersRemoved || listOfAdmins)) ? <span>, </span> : null}
                    {membersRemoved ? <span>{t.TEAM_FACILITATION.BULK_UPLOAD.REMOVE_MEMBERS(membersRemoved)}</span> : null}
                    {(membersRemoved && listOfAdmins) ? <span>, </span> : null}
                    {listOfAdmins ? <span>{t.TEAM_FACILITATION.BULK_UPLOAD.TEAM_LEADS({ ...aliases.teamAliases, ...aliases.groupAliases, isTeam, listOfAdmins: listOfAdmins.join(', '), hasAdmins: !!listOfAdmins?.length })}</span> : null}
                    <div className='horizontal-line' />
                  </div>
                ))}
              </Fragment>
            )}
            <div className='d-flex justify-content-center mt-7'>
              <div className='mr-2'>
                <NvFilePicker
                  multiple={false}
                  accept={['.csv', 'text/csv']}
                  onChange={(e) => onCSVSelected(e)}
                  onSelectError={onFileError}
                >
                  <Button variant='secondary' data-qa={config.pendo.manageAllTeamsGroups.bulkUpdateModal.uploadNewTeamsGroupsCsv}>{t.FORM.UPLOAD_NEW_CSV()}</Button>
                </NvFilePicker>
              </div>
              <Button disabled={isSubmitting} onClick={() => submit()} data-qa={config.pendo.manageAllTeamsGroups.bulkUpdateModal.confirmTeamsGroupsCsvUpload}>{t.FORM.CONFIRM()}</Button>
            </div>
          </div>
        )}
    </div>
  );
};

export default BulkUploadModal;
