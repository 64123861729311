/* @ngInject */
export default function InvitationsAndRequestsResources($resource) {
  return $resource(
    '/users/:userId/team_membership_notifications.json',
    {
      catalogId: '@catalogId',
      userId: '@userId',
      invitationAndRequestId: '@invitationAndRequestId',
    }, {
      acceptMembershipRequest: { method: 'GET', url: '/:catalogId/teams/:teamId/membership_requests/:invitationAndRequestId/approve' },
      rejectMembershipRequest: { method: 'GET', url: '/:catalogId/teams/:teamId/membership_requests/:invitationAndRequestId/reject' },
      cancelMembershipRequest: { method: 'GET', url: '/:catalogId/teams/:teamId/membership_requests/:invitationAndRequestId/cancel' },
      acceptMembershipInvitation: { method: 'GET', url: '/:catalogId/team_members/approve/:teamId' },
      rejectMembershipInvitation: { method: 'GET', url: '/:catalogId/team_members/reject/:teamId' },
      acceptMentorshipInvitation: { method: 'GET', url: '/:catalogId/mentorships/approve_invitation/:invitationAndRequestId' },
      rejectMentorshipInvitation: { method: 'GET', url: '/:catalogId/mentorships/reject_invitation/:invitationAndRequestId' },
    },
  );
}
