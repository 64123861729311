/* @ngInject */
export default function CreateFormPresetController(
  $uibModalInstance,
  TimelinesManager,
  CurrentCourseManager,
) {
  const vm = this;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.PRESET = {
    SECTION_SUBSECTION_LESSON: 'three_layer',
    SUBSECTION_LESSON: 'two_layer_collapse',
    SECTION_LESSON: 'two_layer_expand',
  };

  vm.createFromPreset = function (presetType) {
    TimelinesManager.createFromPreset(presetType);
    $uibModalInstance.close();
  };
}
