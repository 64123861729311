import { css } from '@emotion/react';
import React, { createContext, useCallback, useEffect, useRef, useState, useContext } from 'react';
import t from 'react-translate';
import { tripleSpacing } from 'styles/global_defaults/scaffolding';
import { isEmpty, pluck } from 'underscore';
import { AngularServicesContext } from 'react-app';

// redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { fetchCollectionsList } from 'redux/actions/collections';
import { convertToCollectionLesson } from 'redux/actions/lecture-pages';
import { SyncingStatus } from 'redux/schemas/models/lecture-page';
import { getCourseAliases } from 'redux/selectors/course';
import { RootState } from 'redux/schemas';
import { CourseAliases } from 'redux/schemas/models/course';

// components
import { Button } from 'react-bootstrap';
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';
import LoadingPlaceholder, { LoaderType } from 'shared/components/loading-placeholder';
import { collectionSortKeys } from 'content-library/components/collection-list';
import CollectionRow from './collection-row';
import { PAGE_SIZE } from '../course-lesson-link/link-lessons-modal-body';

type AddToCollectionModalBodyProps = {
  lessonId: number
  onClose: VoidFunction
};

const styles = css`
  .content {
    // 370px = Modal padding(100) + Modal header(60) + Description(70) + Button with spacing(140)
    max-height: calc(100vh - 370px);
    overflow-y: auto;
  }
  .button-container {
    margin-top: ${tripleSpacing}px;
  }
`;
type InitialStateType = {
  selectedFolderId: number
  setSelectedFolderId: Function
  selectedCollectionName: string
  setSelectedCollectionName: Function
};

const initialState: InitialStateType = {
  selectedFolderId: null,
  setSelectedFolderId: (selectedFolderId: number) => {},
  selectedCollectionName: '',
  setSelectedCollectionName: (name: string) => {},
};
export const AddToCollectionModalContext = createContext(initialState);

const AddToCollectionModalBody = (props: AddToCollectionModalBodyProps) => {
  const hasMore = useSelector((state) => state.app.collection.hasMoreInCollectionSearch);
  const aliases: CourseAliases = useSelector((state: RootState) => getCourseAliases(state));

  const dispatch = useAppDispatch();

  const [saving, setSaving] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [collectionCatalogIds, setCollectionCatalogIds] = useState<string[]>([]);
  const [selectedFolderId, setSelectedFolderId] = useState(initialState.selectedFolderId);
  const [selectedCollectionName, setSelectedCollectionName] = useState(initialState.selectedCollectionName);

  const { TimelinesManager, $timeout } = useContext(AngularServicesContext);
  const scrollingContentRef = useRef<HTMLDivElement>(null);
  const SCROLL_PADDING = 20;
  const isScrolledToBottom = useInfiniteScroll(
    scrollingContentRef.current,
    SCROLL_PADDING,
  );

  // For infinite scrolling
  useEffect(() => {
    if (isScrolledToBottom && hasMore && !loading) {
      setLoading(true);
      setPageNo(pageNo + 1);
    }
  }, [hasMore, isScrolledToBottom, pageNo, loading]);

  const onFetchCollections = useCallback((): Promise<any> => new Promise((resolve) => {
    const payload = {
      pageIndex: pageNo,
      pageSize: PAGE_SIZE,
      sorting: [`${collectionSortKeys[0]}&order=asc`],
      includeBlank: false,
      editableOnly: true,
    };

    dispatch(fetchCollectionsList(payload)).then((res) => {
      if (!isEmpty(res?.error)) {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.ERROR_SOMETHING_WRONG(),
        }));
        resolve([]);
      }

      resolve(pluck(res.payload.response ?? [], 'catalogId'));
    });
  }), [dispatch, pageNo]);

  useEffect(() => {
    if (loading) {
      onFetchCollections()
        .then((response) => {
          setLoading(false);
          setIsLoaded(true);
          setCollectionCatalogIds((prevList) => ([...prevList, ...response ?? []]));
        });
    }
  }, [onFetchCollections, loading]);

  const onSubmit = () => {
    setSaving(true);

    const payload = {
      lecturePageId: props.lessonId,
      folderId: selectedFolderId,
    };

    dispatch(convertToCollectionLesson(payload)).then((response) => {
      if (isEmpty(response?.error)) {
        TimelinesManager.updateLecturePageAttribute(props.lessonId, 'isLinked', true);
        TimelinesManager.updateLecturePageAttribute(props.lessonId, 'syncingStatus', SyncingStatus.CONVERT_IN_PROGRESS);

        $timeout(() => {
          dispatch(addAlertMessage({
            type: AlertMessageType.SUCCESS,
            message: t.TIMELINE.ADD_TO_COLLECTION_MODAL.SUCCESS_ALERT_MSG(selectedCollectionName, response.payload.title),
          }));
        });
      }

      props.onClose();
    });
  };

  return (
    <AddToCollectionModalContext.Provider
      value={{ selectedFolderId, setSelectedFolderId, selectedCollectionName, setSelectedCollectionName }}
    >
      <div css={styles} className='mx-6 mt-4'>
        <p>{t.TIMELINE.ADD_TO_COLLECTION_MODAL.DESCRIPTION(aliases.lectureAliases)}</p>
        <br />
        <div className='content' ref={scrollingContentRef}>
          {collectionCatalogIds?.map((catalogId) => (
            <CollectionRow
              key={catalogId}
              catalogId={catalogId}
            />
          ))}
          {loading && (
            <LoadingPlaceholder loaderType={LoaderType.HALF} className='p-4' />
          )}
          {isLoaded && !hasMore && collectionCatalogIds.length > 0 && (
            <div className='d-flex align-items-center gray-2 text-small mt-2'>
              {t.SEARCH.ALL_LOADED()}
            </div>
          )}
        </div>
        <div className='button-container d-flex justify-content-center mb-6'>
          <Button
            variant='secondary'
            className='font-weight-bold'
            onClick={props.onClose}
          >
            {t.FORM.CANCEL()}
          </Button>
          <Button
            className='ml-2 font-weight-bold'
            variant='primary'
            onClick={onSubmit}
            disabled={!selectedFolderId || saving}
          >
            {t.FORM[saving ? 'ADDING' : 'ADD']()}
          </Button>
        </div>

      </div>
    </AddToCollectionModalContext.Provider>
  );
};
export default AddToCollectionModalBody;
