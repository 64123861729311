import { css } from '@emotion/react';
import { useState } from 'react';

import t from 'react-translate';
import { standardSpacing, largeSpacing, quarterSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { warning, danger, gray4 } from 'styles/global_defaults/colors';
import NvIcon from 'shared/components/nv-icon';
import { NvTooltip } from 'shared/components/nv-tooltip';
import { NvPopover } from './nv-popover';

type NvStackedTabProps = {
  count: number,
  isStacked?: boolean,
  hasError?: boolean,
  errorMessage?: string,
  onClick?: Function,
  isExpanded?: boolean,
};


const styles = (hasError: boolean, isExpanded: boolean) => {
  let bgColor = isExpanded ? gray4 : warning;
  bgColor = hasError ? danger : bgColor;
  const width = 45;

  return css`
    outline: none;
    border: none;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

    .error {
      color: ${danger};
    }

    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${width}px;
      height: ${largeSpacing}px;
      background-color: ${bgColor};
      color: white;
      border-top-right-radius: ${standardSpacing}px;
      border-bottom-right-radius: ${standardSpacing}px;
      position: absolute;
      transform: translateZ(0);
      top: ${halfSpacing}px;
      right:  -${width}px;

      &.stacked {
        position: absolute;
        top: ${halfSpacing}px;
        right: -${width}px;
        z-index: 1;

        &:before {
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          top: ${quarterSpacing}px;
          left: 0px;
          border-top-right-radius: ${standardSpacing}px;
          border-bottom-right-radius: ${standardSpacing}px;
          background-color: ${bgColor};
          opacity: .6;
          z-index: -1;
        }

        &:after {
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          top: ${halfSpacing}px;
          left: 0px;
          border-top-right-radius: ${standardSpacing}px;
          border-bottom-right-radius: ${standardSpacing}px;
          background-color: ${bgColor};
          opacity: .4;
          z-index: -1;
        }
      }

      .error {
        position: absolute;
        top: -14px;
        left: 32%;
        color: ${danger};
        .icon {
          margin: 0;
        }
      }
    }
  `;
};

const warningPopover = (errorMessage: string) => (
  <div>
    <div className='text-center text-danger bold'>{ t.FORM.WARNING() }</div>
    <p className='mt-2 mb-0'>{ errorMessage }</p>
  </div>
);

const NvStackedTab = (props: NvStackedTabProps) => {
  const [showWarning, setShowWarning] = useState(false);

  return (
    <button type='button' css={styles(props.hasError, props.isExpanded)} className='text-small' onClick={() => props.onClick()}>
      {!props.hasError && (
        <NvTooltip text={t.COURSE_COMMUNICATIONS.TOOLTIP.SHOW_TRIGGER()} placement='top'>
          <div className={`tab ${props.isStacked && 'stacked'}`}>
            {props.count}
          </div>
        </NvTooltip>
      )}
      {props.hasError && (
        <div
          className={`tab ${props.isStacked && 'stacked'}`}
          onMouseEnter={() => setShowWarning(true)}
          onMouseLeave={() => setShowWarning(false)}
        >
          { props.hasError && (
            <NvPopover
              content={warningPopover(props.errorMessage)}
              placement='top'
              preventOverflow
              showOnHover={false}
              show={showWarning}
              offset={40}
            >
              <div className='error'>
                <NvIcon icon='warning' size='xss-smallest' />
              </div>
            </NvPopover>
          )}
          {props.count}
        </div>
      )}
    </button>
  );
};

export default NvStackedTab;
