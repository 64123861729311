import React from 'react';
import { css } from '@emotion/react';
import { Controller } from 'react-hook-form';

import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import NvClickToEdit from 'components/nv-click-to-edit';
import { getPlaceholder } from 'org_level_profile/utils';
import { black, gray3 } from 'styles/global_defaults/colors';
import { boldFontWeight } from 'styles/global_defaults/fonts';
import { OrgProfileField } from 'redux/schemas/models/account-fields';
import {
  halfSpacing,
  quarterSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';

export enum Variant {
  SIMPLE = 'SIMPLE',
  ORG_LEVEL_FIELD = 'ORG_LEVEL_FIELD',
}

type Props = {
  variant: Variant,
  isSaving: boolean,
  field: OrgProfileField,
  onChange: (newField: OrgProfileField) => void,
  className?: string,
  editable?: boolean,
  readonly?: boolean,
  readonlyWarning?: string,
  textContainerClassName?: string,
};

const EditableOrgLevelField = (props: Props) => {
  const {
    field,
    variant,
    onChange,
    readonly,
    className,
    editable = true,
    readonlyWarning,
    isSaving = false,
    textContainerClassName,
  } = props;

  const getDisplayQuestion = () => {
    if (field.migratedField && !field.question) {
      return getPlaceholder(field.migratedField);
    }

    return field.question;
  };

  const displayQuestion = getDisplayQuestion();

  const isEditable = (field.isIntegrated || field.isCsvManaged) ? false : editable;

  const getTextContainerStyles = () => {
    if (variant === Variant.ORG_LEVEL_FIELD) {
      if (field.isIntegrated) {
        return css`
          & > .field-value {
            color: ${gray3};
            font-style: italic;
            font-weight: ${field.isHidden ? 'normal' : boldFontWeight};
          }
        `;
      }

      if (field.isHidden) {
        return css`
          & > .field-value, & > .hidden-label {
            color: ${gray3};
            font-style: italic;
          }
        `;
      }

      return css`
        & > .field-value {
          color: ${black};
          font-weight: ${boldFontWeight};
        }
      `;
    }

    if (field.isHidden) {
      return css`
        color: ${gray3};
        font-style: italic;
      `;
    }

    return null;
  };

  const styles = css`
    margin-bottom: ${halfSpacing}px;
    ${variant === Variant.ORG_LEVEL_FIELD && css`
      display: flex;
    `};

    &:last-child {
      margin-bottom: 0;
    }

    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: ${quarterSpacing}px;
      color: ${field.viewOptions.iconColor};
      width: ${standardSpacing + quarterSpacing}px;
      height: ${standardSpacing + quarterSpacing}px;

      .field-picture {
        width: 16px;
        height: 16px;
        background-size: cover;
        background-image: ${field.picture ? `url(${field.picture.cdnUrl})` : ''};
      }
    }

    .editable-field {
      ${variant === Variant.ORG_LEVEL_FIELD ? css`
        flex: 1;
      ` : css`
        display: block;
      `};

      .click-to-edit-text-container {
        ${getTextContainerStyles()};
      }
    }
  `;

  const handleChange = (newValue) => {
    onChange({
      ...field,
      answer: newValue,
    });
  };

  const tooltipText = (field.isIntegrated || field.isCsvManaged)
    ? t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.NOT_EDITABLE()
    : t.FORM.CLICK_TO_EDIT();

  return (
    <div css={styles} className={className}>
      {variant === Variant.ORG_LEVEL_FIELD && (
        <div className='icon-container'>
          {field.picture && (
            <div className='field-picture' />
          )}
          {field.viewOptions.icon && (
            <NvIcon
              size='smallest'
              icon={field.viewOptions.icon}
            />
          )}
        </div>
      )}
      <NvClickToEdit
        isSaving={isSaving}
        readonly={readonly}
        value={field.answer}
        editable={isEditable}
        text={displayQuestion}
        type={field.inputType}
        onChange={handleChange}
        className='editable-field'
        readonlyWarning={readonlyWarning}
        selectOptions={field.selectOptions}
        replaceText={variant === Variant.SIMPLE}
        tooltipText={editable ? tooltipText : undefined}
        tooltipPlacement={variant === Variant.ORG_LEVEL_FIELD ? 'top-start' : 'top'}
        textContainerClassName={`click-to-edit-text-container${textContainerClassName ? ` ${textContainerClassName}` : ''}`}
        renderAfterText={() => field.isHidden && (
          <span className='ml-2 hidden-label'>
            {t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.HIDDEN()}
          </span>
        )}
      />
    </div>
  );
};

type FormProps = {
  name: string,
};

export const FormEditableOrgLevelField = (props: Omit<Props, 'field'> & FormProps) => {
  const {
    name,
    onChange: propsOnChange,
    ...restProps
  } = props;

  return (
    <Controller
      name={name}
      render={(data) => {
        const { value, onChange } = data.field;

        const handleChange = (newField) => {
          onChange(newField);

          propsOnChange(newField);
        };

        return (
          <EditableOrgLevelField
            {...restProps}
            field={value}
            onChange={handleChange}
          />
        );
      }}
    />
  );
};

export default EditableOrgLevelField;
