import React from 'react';
import { css } from '@emotion/react';
import { FileUpload } from 'redux/schemas/models/file-uploads';

const pictureContainerStyle = css`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 50%;
`;

interface PictureDimensions {
  width: number;
  height: number;
}
interface Style5Picture {
  picture: FileUpload;
  thumbnailUrl: string | null;
}

/**
 * This component will display the picture with the right background-size
 * depending on the picture size. In case that the backend doesn't return the
 * picture dimension we need to measure it first. Unfortunately, we can only
 * measure it once it loaded completely and we can only display the picture once
 * the dimensions are known
 */
/* @ngInject */
export default function Style5Picture(props) {
  const [pictureDimensions, setPictureDimensions] = React.useState<PictureDimensions>(null);
  React.useEffect(() => {
    const img = new Image();
    let canceled = false;

    img.onload = function () {
      if (!canceled) {
        setPictureDimensions({
          height: img.naturalHeight,
          width: img.naturalWidth,
        });
      }
    };
    img.src = props.picture.cdnUrl;

    return () => {
      canceled = true;
    };
  }, [props.picture]);

  // use the backend dimensions if present or use the calculated instead
  const currentDimensions = props.picture.width
    ? { width: props.picture.width, height: props.picture.height }
    : pictureDimensions;

  if (!currentDimensions) {
    return null;
  }

  return (
    <div
      css={css`
    ${pictureContainerStyle};
    background-image: url("${props.thumbnailUrl || props.picture.cdnUrl}");
    background-size: ${currentDimensions.width > 65 || currentDimensions.height > 65 ? 'cover' : 'auto'};
  `}
    />
  );
}
