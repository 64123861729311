import React from 'react';
import uuid from 'react-uuid';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';

import { AngularServicesContext } from 'react-app';

const relevantPropertiesFromStateManager = [
  'previousStatesEntered',
  'previousParamsEntered',
];

const useAngularStateManager = () => {
  const { $scope: {
    StateManager,
  } } = React.useContext(AngularServicesContext);

  if (!StateManager) {
    throw new Error('StateManager service should be attached to the angular $scope of the react app in order to make useAngularStateManager hook work.');
  }

  const getReactVersionOfStateManager = React.useCallback(
    () => cloneDeep(pick(StateManager, relevantPropertiesFromStateManager)),
    [StateManager],
  );

  const [stateManagerData, setStateManagerData] = React.useState(getReactVersionOfStateManager());

  React.useEffect(() => StateManager.registerReactListener(uuid(), () => {
    setStateManagerData(getReactVersionOfStateManager());
  }), [StateManager, getReactVersionOfStateManager]);

  return stateManagerData;
};

export default useAngularStateManager;
