/* @ngInject */
export default function CustomDownloadsManager(

  _,
  CustomDownloadsModel,
) {
  const manager = {
    getCustomReports,
    getUpdatedCustomReport,
    duplicateReport,
    deleteReport,
    deleteContentReport,
  };

  function getCustomReports(catalogId) {
    return CustomDownloadsModel.getCustomReports(catalogId).then((data) => {
      manager.data = data;

      manager.shownDataDownloads = { num: 6 };
      manager.shownContentDownloads = { num: 6 };
    });
  }

  function getUpdatedCustomReport(catalogId, reportId, type, deleteOlderReports) {
    return CustomDownloadsModel.getCustomReports(catalogId).then((newData) => {
      let existingReportIndex;
      let newReportIndex;

      newData = type === 'dataDownload' ? newData.dataDownload : newData.contentDownload;
      const existingData = type === 'dataDownload' ? manager.data.dataDownload : manager.data.contentDownload;

      if (reportId !== null) {
        // existing report that needs updating
        existingReportIndex = _.findIndex(existingData, { reportId });
        newReportIndex = _.findIndex(newData, { reportId });
        existingData[existingReportIndex] = newData[newReportIndex];
        existingData[existingReportIndex].deleteOlderReports = deleteOlderReports;
      } else {
        // new report to be inserted
        if (!existingData.length) {
          newReportIndex = 0;
        } else {
          newReportIndex = _.findIndex(newData, (data, index) => existingData[index].reportId !== data.reportId);
        }

        if (newReportIndex > -1 && newData.length) {
          existingData.splice(newReportIndex, 0, newData[newReportIndex]);
          if (type === 'dataDownload') {
            manager.data.dataDownload = existingData;
            // only increment if Show All is clicked i.e. when shownDataDownloads > 6
            if (manager.shownDataDownloads.num > 6) {
              manager.shownDataDownloads.num += 1;
            }
            manager.shownDataDownloads.deleteOlderReports = deleteOlderReports;
          } else {
            manager.data.contentDownload = existingData;
            if (manager.shownContentDownloads.num > 6) {
              manager.shownContentDownloads.num += 1;
            }
          }
        }
      }
    });
  }

  function duplicateReport(catalogId, payload) {
    return CustomDownloadsModel.duplicateReport(catalogId, payload);
  }

  function deleteReport(catalogId, payload) {
    return CustomDownloadsModel.deleteReport(catalogId, payload);
  }

  function deleteContentReport(catalogId, payload) {
    return CustomDownloadsModel.deleteContentReport(catalogId, payload);
  }

  return manager;
}
