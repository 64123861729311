/* @ngInject */
export default function LectureVideoResources(

  $resource,
) {
  return $resource(
    '/:catalogId/video_list_lecture_components/:videoListId/lecture_videos/:id',
    {},
    {
      query: { method: 'GET', isArray: false },
      watchEvent: { method: 'GET', url: '/:catalogId/video_list_lecture_components/:videoListId/lecture_videos/:id/watch_action' },
      update: { method: 'PUT', url: '/:catalogId/videos/:videoId' },
      delete: { method: 'DELETE', url: '/:catalogId/videos/:videoId' },
    },
  );
}
