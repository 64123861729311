/* @ngInject */
export default function TopicsResources(

  $resource,
) {
  return $resource(
    '/:catalogId/forums/:id',
    { },
    {
      // index actions
      getTopics: { method: 'GET', url: '/:catalogId/forums' },

      // single topic actions
      update: { method: 'PUT' },
      follow: { method: 'POST', url: '/:catalogId/forums/:id/follow' },
      unfollow: { method: 'POST', url: '/:catalogId/forums/:id/unfollow' },
      markRead: { method: 'POST', url: '/:catalogId/forums/:id/mark_read' },
      lock: { method: 'POST', url: '/:catalogId/forums/:id/lock' },
      unlock: { method: 'POST', url: '/:catalogId/forums/:id/unlock' },

      // single topic attribute actions
      postsCount: { method: 'GET', url: '/:catalogId/forums/:id/topics_count' },
      postsReleasedCount: { method: 'GET', url: '/:catalogId/forums/:id/topics_released_count' },
      numNewPosts: { method: 'GET', url: '/:catalogId/forums/:id/num_new_topics' },
      numNewReleasedPosts: { method: 'GET', url: '/:catalogId/forums/:id/num_new_released_topics' },
    },
  );
}
