/* @ngInject */
export default function MessageModel(

  _,
) {
  function Message(attributes) {
    const _this = this;
    _.extend(_this, attributes);
  }

  return Message;
}
