/* @ngInject */
export default function NvGoogleLocationAutocomplete(
  config,
  $timeout,
  $translate,
) {
  return {
    restrict: 'A',
    scope: {
      name: '=',
      required: '=',
      readonly: '=',
      triggerSave: '&',
      locationModel: '=',
      readonlyWarning: '=',
    },
    controller: function ctrl($scope, $element, $attrs) {
'ngInject';
      const VALIDATION_LOCATION_MESSAGE = 'VALIDATION.LOCATION';
      const options = {
        types: ['(cities)'],
      };

      const vm = this;
      vm.touchedInput = false;
      vm.focusedInput = false;
      vm.pressedEnterKey = false;
      vm.invalid = !vm.locationModel;
      vm.selectedValidAddress = true;
      vm.tempValue = vm.locationModel;

      window.initialize = function () {
        vm.locationAutocomplete();
      };

      vm.locationAutocomplete = function () {
        const autocomplete = new google.maps.places.Autocomplete(
          $element.find('input').get(0),
          options,
        );

        google.maps.event.addListener(autocomplete, 'place_changed', () => {
          if (!vm.pressedEnterKey) {
            $timeout(() => {
              vm.selectedValidAddress = true;
              const place = autocomplete.getPlace();
              vm.tempValue = place.formatted_address;

              save();
            });
          }
        });
      };

      if (window.google) {
        vm.locationAutocomplete();
      } else {
        const googleScript = document.createElement('script');
        googleScript.id = 'google_script';
        googleScript.type = 'text/javascript';
        googleScript.src = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&callback=initialize&key=${config.google.key}`;
        googleScript.setAttribute('defer', '');
        document.body.appendChild(googleScript);
      }

      vm.handleBlur = function ($event) {
        touchInput();

        if (vm.selectedValidAddress) {
          if (vm.required) {
            if (vm.tempValue) {
              save();
            }
          } else {
            save();
          }
        }
      };

      vm.handleKeydown = function ($event) {
        $event.stopPropagation();

        if ($event && $event.keyCode === 13) {
          touchInput();
          vm.pressedEnterKey = true;

          if (vm.selectedValidAddress) {
            save();
          }
        } else {
          vm.pressedEnterKey = false;
        }
      };

      vm.handleFocus = function () {
        vm.focusedInput = true;
      };

      vm.getPopoverIsOpen = function () {
        const nonReadOnlyIsOpen = (
          vm.selectedValidAddress === false
          || (vm.required && !vm.tempValue)
        ) && vm.touchedInput;

        return vm.readonly ? vm.focusedInput : nonReadOnlyIsOpen;
      };

      vm.getPopoverMessage = function () {
        const requiredPopoverMessage = vm.tempValue ? VALIDATION_LOCATION_MESSAGE : 'VALIDATION.REQUIRED';

        const nonReadonlyMessage = vm.required ? requiredPopoverMessage : VALIDATION_LOCATION_MESSAGE;

        return vm.readonly ? vm.readonlyWarning : $translate.instant(nonReadonlyMessage);
      };

      function save() {
        vm.locationModel = vm.tempValue;

        $timeout(() => {
          vm.triggerSave();
        }, 500);
      }

      function touchInput() {
        vm.focusedInput = false;

        $timeout(() => {
          vm.touchedInput = true;
        }, 500);
      }
    },
    link(scope, $element, attrs, ctrl) {
      const input = $element.find('input');
      input.val(ctrl.locationModel);

      input.on('input', ($event) => {
        $timeout(() => {
          const { value } = $event.target;

          ctrl.tempValue = value;

          // if Google Maps API js library fails to load, allow user to enter any input
          ctrl.selectedValidAddress = window.google ? !value : true;
          if (ctrl.required) {
            if (value) {
              ctrl.invalid = false;
            } else if (window.google) {
              ctrl.invalid = true;
            }
          }
        });
      });
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'learner_profiles/templates/nv-google-location-autocomplete.html',
  };
}
