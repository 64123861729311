import React from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';
import NvDropdown, { NvDropdownTextItem, NvDropdownButtonStyle } from 'shared/components/inputs/nv-dropdown';
import { getCourseAliases } from 'redux/selectors/course';
import { useSelector } from 'react-redux';
import { CourseAliases } from 'redux/schemas/models/course';
import { SearchInSelectOptionIndexes } from 'redux/reducers/content-search';

interface SearchInSelectProps {
  onSelectOption(index: SearchInSelectOptionIndexes): void;
  selectedOptionIndex: number;
  catalogId: string;
}
/* @ngInject */
export default function SearchInSelect(props: SearchInSelectProps) {
  const aliases: CourseAliases = useSelector((state) => getCourseAliases(state));

  const options = [
    {
      id: 1,
      type: 'text',
      text: t.LHS.CONTENT_SEARCH.CURRENT_COURSE({ CourseAlias: aliases.courseAliases?.CourseAlias }),
    } as const,
    { id: 2, type: 'text', text: t.LHS.CONTENT_SEARCH.ALL_COURSES() } as const,
  ] as const;
  const items = options.map(
    (item, idx) => ({ ...item, callback: () => props.onSelectOption(idx as SearchInSelectOptionIndexes) } as NvDropdownTextItem),
  );

  return (
    <div
      css={css`
        .nv-dropdown {
          display: inline-flex;
        }
      `}
    >
      <NvDropdown
        pill
        showSelectedIndicator
        items={items}
        buttonStyle={NvDropdownButtonStyle.FORM_SMALL}
        title={items[props.selectedOptionIndex].text}
        initialIndex={props.selectedOptionIndex}
      />
    </div>
  );
}
