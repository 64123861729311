/* @ngInject */
export default function BlurbSideImageLectureComponentModel(
  _,
  $translate,
  ContentTemplateBaseModel,
  nvUtil,
  config,
) {
  class BlurbSideImageLectureComponent extends ContentTemplateBaseModel {
    constructor(attributes, ...args) {
      const defaults = {
        type: 'BlurbSideImageLectureComponent',
        componentClasses: ['blurb', 'side-image'],
        hasContent: true,
        contentIsRichText: true,
        uploadSupported: true,
      };

      super(_.extend({}, defaults, attributes), ...args);
    }
  }

  BlurbSideImageLectureComponent.FORM_DATA = [
    [{
      labelKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.COMPONENT_WIDTH',
      type: 'DROPDOWN',
      optionsKey: 'COMPONENT_WIDTHS',
      modelAttribute: 'componentWidth',
    }, {
      labelKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.IMAGE_WIDTH',
      type: 'DROPDOWN',
      optionsKey: 'IMAGE_WIDTHS',
      modelAttribute: 'pictureWidth',
    }],
    [{
      labelKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.IMAGE_STYLE',
      type: 'SELECTION',
      optionsKey: 'IMAGE_STYLES',
      modelAttribute: 'pictureShape',
    }, {
      labelKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.IMAGE_PLACEMENT',
      type: 'SELECTION',
      optionsKey: 'IMAGE_ORDERS',
      modelAttribute: 'picturePlacement',
    }, {
      labelKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.TEXT_ALIGNMENT',
      type: 'SELECTION',
      optionsKey: 'TEXT_VERTICAL_POSITIONS',
      modelAttribute: 'verticalAlign',
    }],
    [{
      labelKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.IMAGE_ALT',
      type: 'FREEFORM_OPTIONAL',
      modelAttribute: 'pictureAlt',
    }],
  ];

  BlurbSideImageLectureComponent.COMPONENT_WIDTHS = _.map(['100%', '80%', '60%', '50%'], (value) => ({ labelKey: BlurbSideImageLectureComponent.getDropdownKey(value), value: `${value}` }));

  BlurbSideImageLectureComponent.IMAGE_WIDTHS = _.map(['10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%'], (value) => ({ labelKey: BlurbSideImageLectureComponent.getDropdownKey(value), value: `${value}` }));

  BlurbSideImageLectureComponent.IMAGE_STYLES = _.map(['square', 'original', 'circle'], (value) => ({ css: `shape ${value}`, value: `${value}`, tooltipKey: `LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.SHAPE.${value.toUpperCase()}` }));

  BlurbSideImageLectureComponent.IMAGE_ORDERS = _.map(['left', 'right'], (value) => {
    let translationKey = value;

    if (nvUtil.isRtl()) {
      translationKey = value === 'left' ? 'right' : 'left';
    }

    return {
      css: `icon icon-admin-image-${value}`,
      value: `${value}`,
      tooltipKey: `LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.ALIGN.${translationKey.toUpperCase()}`,
    };
  });

  BlurbSideImageLectureComponent.TEXT_VERTICAL_POSITIONS = _.map(['top', 'middle', 'bottom'], (value) => ({ css: `icon icon-admin-text-${value}`, value: `${value}`, tooltipKey: `LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.ALIGN.${value.toUpperCase()}` }));
  BlurbSideImageLectureComponent.pendoTagName = config.pendo.lectureEdit.pictureSideText;
  BlurbSideImageLectureComponent.toolTipKey = 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.BLURB_SIDE_IMAGE';

  return BlurbSideImageLectureComponent;
}
