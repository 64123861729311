/* @ngInject */
export default function GrantLateSubmissionPermitCtrl(
  _,
  moment,
  $scope,
  $state,
  CurrentCourseManager,
  ExerciseModel,
  ExercisesManager,
  CurrentUserManager,
  AlertMessages,
) {
  class GrantLateSubmissionPermitController {
    constructor() {
      const { exercises, header, user, closeModal, origin } = $scope;

      this.exercises = exercises;
      this.header = header;
      this.user = user;
      this.closeModal = closeModal;
      this.origin = origin;

      // Accepts the "origin" key to determine the modal request origin
      // Now origin considers the values "team" and "user"

      // Convert to exercise model and set accordingly
      this.exercises = this.exercises.map((exerciseObj) => {
        // Exercise model already got a getter method
        const { isTeamSubmission } = exerciseObj;

        const exercise = new ExerciseModel(exerciseObj);
        const currentDeadline = exercise.getCurrentDeadline();
        exercise.currentDeadline = currentDeadline;
        exercise.initialCurrentDeadline = currentDeadline;
        exercise.teamSubmission = isTeamSubmission;
        return exercise;
      });

      this.CurrentCourseManager = CurrentCourseManager;
      if ($state.params?.catalogId && $state.params.catalogId !== CurrentCourseManager?.course?.catalogId) {
        this.course = CurrentUserManager.getCurrentCourse();
      } else {
        this.course = CurrentCourseManager.course;
      }

      this.moment = moment;
      this.originEntity = this.origin === 'team' ? this.team : this.user;
      this.invalidDates = {};
    }

    isSubmittedExercise(exercise) {
      return (exercise.isCompleted() || exercise.isApproved() || exercise.isPendingApproval());
    }

    isNotSubmittedExercise(exercise) {
      return (exercise.isRejectedCanRevise()
        || exercise.isInProgress()
        || exercise.isNotStartedAndApprovalRequired()
        || exercise.isNotStarted());
    }

    isMissedExercise(exercise) {
      return (exercise.isMissed() || exercise.isRejectedCannotRevise());
    }

    cancelPermit() {
      this.closeModal();
    }

    editExercise(exercise) {
      this.editingId = exercise.id;
    }

    isExerciseEditing(exercise) {
      return this.editingId === exercise.id;
    }

    checkExerciseChanged(exercise) {
      return !moment(exercise.currentDeadline).isSame(exercise.initialCurrentDeadline);
    }

    isDatePassed(date) {
      return this.moment().isAfter(date);
    }

    dueDateChange(exercise) {
      if (this.moment(exercise.currentDeadline).isSameOrBefore(exercise.initialCurrentDeadline)) {
        this.invalidDates[exercise.id] = true;
      } else {
        delete this.invalidDates[exercise.id];
      }

      if (exercise.teamSubmission) {
        this.isTeamSubmission = exercise.teamSubmission;
      }
      this.setSubmitStatus();
      this.setTeamSubmissionStatus();
    }

    onGrantPermit() {
      const originIdKey = `${this.origin}Id`;
      const extensions = Object.assign(
        {},
        ...this.exercises.filter((exercise) => this.checkExerciseChanged(exercise))
          .map((exercise) => ({ [exercise.id]: exercise.currentDeadline })),
      );
      const updatedObject = { extensions, [originIdKey]: this.originEntity.id };

      return ExercisesManager.updateExercisesWithHardDeadline(this.course.catalogId, updatedObject)
        .then((response) => {
          const name = this.origin === 'team' ? this.originEntity.name : this.originEntity.fullName;

          this.closeModal();
          AlertMessages.success('FORM.SUCCESS_BANG', 'EXERCISES.GRANT_LATE_SUBMISSION_PERMIT.EXERCISE_EXTENDED_SUCCESS', {}, { name });
          return response.result;
        }).catch(() => {
          AlertMessages.error('FORM.OOPS', 'FORM.ERROR_SOMETHING_WRONG');
        });
    }

    resetExercise(exercise) {
      this.invalidDates = _.omit(this.invalidDates, exercise.id);
      this.editingId = null;
      exercise.currentDeadline = exercise.initialCurrentDeadline;

      this.setSubmitStatus();
      this.setTeamSubmissionStatus();
    }

    setSubmitStatus() {
      this.submitStatus = false;

      if (_.isEmpty(this.invalidDates)) {
        this.submitStatus = _.some(this.exercises, (exercise) => this.checkExerciseChanged(exercise));
      }
    }

    setTeamSubmissionStatus() {
      this.teamSubmissionStatus = false;

      if (_.isEmpty(this.invalidDates)) {
        this.teamSubmissionStatus = _.some(this.exercises, (exercise) => (this.checkExerciseChanged(exercise) && exercise.teamSubmission));
      }
    }
  }

  return new GrantLateSubmissionPermitController();
}
