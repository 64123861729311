import React from 'react';

import store from 'redux/store';
import { loadLecturePage } from 'redux/actions/lecture-pages';
import { getSkillTags } from 'redux/actions/skill-tags';
import { getAvailablePracticeActivities } from 'redux/actions/video-practice-feedback';

import { LecturePageMode } from '../components/index';

/* @ngInject */
export default function LectureContentBaseController(
  _,
  ConfirmationOverlays,
  $state,
  $stateParams,
  nvUtil,
  CurrentUserManager,
  CurrentCourseManager,
  LecturePageManager,
  TimelinesManager,
  $timeout,
  StateManager,
  CurrentPermissionsManager,
  ComponentActionToService,
  FlyoutModalManager,
) {
  const vm = this;

  vm.$stateParams = $stateParams;
  vm.nvUtil = nvUtil;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.LecturePageManager = LecturePageManager;

  vm.canViewAdminCog = CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isCourseBuilder() || CurrentPermissionsManager.isConfigAndRegistrationRole();

  vm.isLearner = () => CurrentPermissionsManager.isLearner();
  vm.getHeaderStyle = () => {
    const style = {};

    if (vm.LecturePageManager.currentLecture.showTitle) {
      style.color = LecturePageManager.currentLecture.viewOptions.titleColor;

      if (LecturePageManager.currentLecture.coverImageUrl) {
        if (LecturePageManager.currentLecture.whiteMask()) {
          style.background = `linear-gradient(rgba(256,256,256,0.1), rgba(256,256,256,0.1)), url("${LecturePageManager.currentLecture.coverImageUrl}")`;
        } else {
          style.background = `linear-gradient(rgba(29,33,38,0.1), rgba(29,33,38,0.1)), url("${LecturePageManager.currentLecture.coverImageUrl}")`;
        }
        style['background-position'] = 'center, center';
        style['background-repeat'] = 'no-repeat, no-repeat';
        style['background-size'] = 'cover, cover';
      } else {
        style['background-color'] = vm.backgroundColorDraft || LecturePageManager.currentLecture.viewOptions.backgroundColor;
      }
    }

    return style;
  };

  vm.getColorTextClass = () => {
    const WHITE = '#FFF';
    let className = '';
    if (vm.LecturePageManager.currentLecture.showTitle) {
      const color = LecturePageManager.currentLecture.viewOptions.titleColor;
      if (color === WHITE) {
        className = 'white-text';
      }
    }
    return className;
  };

  vm.createLecturePage = () => {
    LecturePageManager.createLecturePage(LecturePageManager.currentLecture.id).then((lecturePage) => {
      $state.go('lecture-page-edit', { id: lecturePage.id, lectureActivityId: null, groupFormed: null });
      if (!CurrentUserManager.user.isTimelineExpanded) {
        LecturePageManager.toggleTimelineVisibleDesktop();
      }
    });
  };

  vm.duplicateCurrentLecturePage = () => {
    const lectureComponentDescriptions = LecturePageManager.currentLecture.uncopiableLectureComponents();

    LecturePageManager.currentLecture.duplicateLecturePage('false').then((result) => {
      TimelinesManager.insertLecturePageAfter(LecturePageManager.currentLecture.id, result);
      $state.go('lecture-page-edit', { id: result.id, lectureActivityId: null, groupFormed: null });

      if (!CurrentUserManager.user.isTimelineExpanded) {
        LecturePageManager.toggleTimelineVisibleDesktop();
      }

      $timeout(() => {
        if (lectureComponentDescriptions?.length) {
          ConfirmationOverlays.openConfirmationModal('lecture_pages/templates/copy-confirmation-overlay.html',
            'AttachModalResolvesToVmCtrl', {
              vmResolves() {
                return {
                  lectureComponentDescriptions,
                  lectureComponent: vm.lectureComponent,
                  CurrentCourseManager,
                };
              },
            });
        }
      });
    });
  };

  vm.deleteLecturePage = () => {
    const current = LecturePageManager.currentLecture.id;
    ConfirmationOverlays.openConfirmationModal('lecture_pages/templates/delete-confirmation-overlay.html',
      'AttachModalResolvesToVmCtrl', {
        vmResolves() {
          return {
            CurrentCourseManager,
            lecturePage: TimelinesManager.currentTimeline.currentLecturePage || LecturePageManager.currentLecture,
          };
        },
      })
      .result.then(() => {
        LecturePageManager.getLecturePages().then(() => {
          const nextLecture = LecturePageManager.getLectureAfter(current);
          const previousLecture = LecturePageManager.getLectureBefore(current);

          if (nextLecture) {
            $state.go($state.current.name, { id: nextLecture.id, lectureActivityId: null, groupFormed: null });
          } else if (previousLecture) {
            $state.go($state.current.name, { id: previousLecture.id, lectureActivityId: null, groupFormed: null });
          } else {
            $state.go('course-home');
          }
        });

        LecturePageManager.currentLecture.deleteLecturePage().then(() => {
          TimelinesManager.removeLecturePage(current);
        });
      });
  };

  // $scope has to be passed in since it's not available in this controller (cannot be injected, gives an error)
  vm.bootstrapReactApp = ($scope, onLectureContentReady) => {
    $scope.StateManager = StateManager;
    $scope.LecturePageManager = LecturePageManager;

    /** @type LecturePageMode */
    let lecturePageMode = null;

    if (LecturePageManager.isViewMode()) {
      lecturePageMode = LecturePageMode.VIEW;
    } else if (LecturePageManager.isEditMode()) {
      lecturePageMode = LecturePageMode.EDIT;
    } else if (LecturePageManager.isRestrictedEditMode()) {
      lecturePageMode = LecturePageMode.RESTRICTED_EDIT;
    } else if (LecturePageManager.isReorderMode()) {
      lecturePageMode = LecturePageMode.REORDER;
    }

    const currentLectureData = LecturePageManager.currentLecture.getModelData();

    const ReactComponents = () => (
      React.createElement(ReactLectureComponents, {
        onComponentsReady: onLectureContentReady,
        currentLecture: currentLectureData,
        showComponentLibrary: LecturePageManager.showComponentLibrary,
        mode: lecturePageMode,
        angularLectureComponents: LecturePageManager.currentLecture.lectureComponents,
      })
    );

    // Load data into the store here to avoid having to passin the Angularjs app's currentLecture object as
    // a prop, as it causes the page to rerender many times while being changed.
    store.dispatch(loadLecturePage(currentLectureData));
    // get updated with thr available practice activities in the course
    if (LecturePageManager.isEditMode()) {
      store.dispatch(getAvailablePracticeActivities({ catalogId: vm.$stateParams.catalogId }));
    }
    const state = store.getState();
    if (!state.app.skillTag.hasBeenLoaded) {
      store.dispatch(getSkillTags({ institutionId: CurrentUserManager.currentInstitution.id }));
    }

    // we run this check because bootstrapReact will try to mount the component in .react-app
    // that renders conditionally with this check as well (see lecture-page.jade). Without this
    // check, React will throw when .react-app doesn't exist
    if (!LecturePageManager.currentLecture.prerequisite || vm.canViewLockedContent) {
      $scope.LectureComponents = ReactComponents;
    }
  };
}
