import mergeWith from 'lodash/mergeWith';
import { createReducer } from '@reduxjs/toolkit';
import { schema, normalize } from 'normalizr';

import { setTodos } from 'redux/actions/todos';
import { replaceArrays } from 'shared/lodash-utils';
import { TodoSchema } from 'redux/schemas/api/todos';
import { initialRootState } from '.';

const TodosListSchema = new schema.Array(TodoSchema);

export default createReducer(initialRootState, (builder) => {
  builder.addCase(setTodos, (state, action) => {
    const normalized = normalize(action.payload, TodosListSchema);

    mergeWith(state.models, normalized.entities, replaceArrays);
  });
});
