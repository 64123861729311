/* @ngInject */
export default function LearnerProfileBaseController(
  $stateParams,
  LearnerProfileManager,
  CurrentCourseManager,
) {
  const vm = this;

  vm.manager = LearnerProfileManager;
  vm.$stateParams = $stateParams;
  vm.currentCourseManager = CurrentCourseManager;
}
