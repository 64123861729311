import { CelebrationType, NvCelebrationModalProps } from './nv-celebration-modal';
import NvPointsAnimation from './nv-points-animation';
import NvPracticedSkills from './nv-practiced-skills';

type CelebrationBodyProps = NvCelebrationModalProps & { type: CelebrationType };

const NvCelebrationBody = (props: CelebrationBodyProps) => {
  if (props.type === CelebrationType.POINTS) {
    return <NvPointsAnimation {...props} />;
  }
  if (props.type === CelebrationType.SKILLS) {
    return <NvPracticedSkills skillTags={props.extras?.skillTags} />;
  }
  return null;
};

export default NvCelebrationBody;
