
import AppStylesProvider from 'components/bs4-theme-provider';
import { render } from 'react-dom';
import { ComponentType } from 'redux/schemas/models/lecture-component';
import _ from 'underscore';
import LhsAccordionComponent from './components/lhs-accordion-component';

import LhsStyledComponent from './components/lhs-styled-link-component';

// Accordion Project
export default {
  bindings: {
    computedId: '<',
    componentModel: '<',
  },
  controller: function ctrl() {
'ngInject';
    setTimeout(() => {
      render(
        <AppStylesProvider>
          {getLHSComponentForLectureComponent(this.componentModel)}
        </AppStylesProvider>,
        document.getElementById(this.computedId),
      );
    });
  },
  controllerAs: 'vm',
  template: '<div ng-attr-id="{{vm.computedId}}" />',
};

/** TODO: This doesn't seem to be used anywhere. probably unnecessary/redundant with the components in
 * app/lecture_pages/components/left-panel/content-previews/
 */
function getLHSComponentForLectureComponent(componentModel) {
  switch (componentModel.type as ComponentType) {
    case ComponentType.ACCORDION:
      return <LhsAccordionComponent model={componentModel} />;
    case ComponentType.STYLED_LINK:
      return <LhsStyledComponent lectureComponent={componentModel} />;
    default:
      throw new Error(`No implementation exists for rendering a ${componentModel.type} component in the LHS`);
  }
}
