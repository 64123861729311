export default {
  bindings: {
    users: '<',
    activityType: '@', // options: 'lecture', 'lectureVideo', 'submission', 'quiz', 'survey'
    numUsersWhoCompleted: '<',
  },
  controller: function ctrl(
    _,
    CurrentUserManager,
    CurrentCourseManager,
    $exceptionHandler,
    $uibModal,
    $element,
  ) {
'ngInject';
    this.$onInit = function () {
      if (this.activityType === 'submission') {
        // need to process the data
        this.users = _.map(this.users, (submitterInfo) => _.extend(submitterInfo.submitter, { reportId: submitterInfo.submittableId }));
      }
      this.shouldDisplay = checkNumUsers(this.users);
      this.currentlySelectedUser = null;
      this.CurrentUserManager = CurrentUserManager;
      this.CurrentCourseManager = CurrentCourseManager;
    };

    this.$onChanges = function (changes) {
      if (changes.users && !changes.users.isFirstChange()) {
        this.users = changes.users.currentValue;

        if (this.activityType === 'submission') {
          // need to process the data
          this.users = _.map(this.users, (submitterInfo) => _.extend(submitterInfo.submitter, { reportId: submitterInfo.submittableId }));
        }

        this.shouldDisplay = checkNumUsers(this.users);
      }
    };

    this.onClick = function (user) {
      this.currentlySelectedUser = user;
    };

    this.getLabelFromActivityType = function () {
      if (this.activityType === 'lecture') {
        return 'SELF_PACED.VISITED_RECENTLY';
      } if (this.activityType === 'lectureVideo') {
        return 'SELF_PACED.WATCHED_RECENTLY';
      } if (this.activityType === 'quiz' || this.activityType === 'survey') {
        return 'SELF_PACED.COMPLETED_RECENTLY';
      } if (this.activityType === 'submission') {
        return 'SELF_PACED.COMPLETED_RECENTLY';
      } if (this.activityType === 'evaluation') {
        return 'SELF_PACED.COMPLETED_RECENTLY';
      }


      // default: error

      $exceptionHandler('activity type not recognized, you specified: ', this.activityType);
      return '';
    };

    // warning: not DRY, same code exists in learner-profile-controller.js
    this.launchMessageModal = function () {
      this.conversation = {
        message: {
          body: '',
          subject: '',
        },
        recipients: [this.currentlySelectedUser],
        recipientType: 'user',
      };

      $uibModal.open({
        templateUrl: 'learner_profiles/templates/send-message-modal.html',
        windowClass: 'send-message-modal',
        controller: 'SendMessageModalCtrl as vm',
        resolve: {
          conversation: this.conversation,
        },
      });
    };


    function checkNumUsers(users) {
      return users && !!users.length && users.length > 1;
    }
  },
  controllerAs: 'vm',
  templateUrl: 'shared/templates/nv-community-completion-row.html',
};
