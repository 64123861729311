import axios from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import t from 'react-translate';
import {
  FetchPracticeCommentsResponse,
  PostCommentParams,
  UpdateCommentParams,
  FetchFlyoutScenariosRequest,
  FetchFlyoutScenariosResponse,
  FetchVideoPracticeSubmissionsRequest,
  FetchVideoPracticeSubmissionsResponse,
  PracticeSubmissionActionParams,
  VoteUpResponse,
  FetchScenarioResponse,
  FetchScenarioRequest,
  StartVideoPracticeSubmissionRequest,
  StartVideoPracticeSubmissionResponse,
  CompleteVideoPracticeSubmissionRequest,
  DeleteCommentParams,
  PracticeRoomStateParams,
  SearchScenariosRequest,
  SearchScenariosResponse,
  EditScenarioRequest,
  ToggleFeaturePracticeSubmissionRequest,
  LeaveVideoPracticeSubmission,
  SetFilteredCommentsParams,
  UsersSubmissionsRequest,
  PostVideoHasViewedResponse,
  OrgProfileSubmissionsRequest,
  DeletePracticeSubmissionRequest,
} from 'redux/schemas/api/video-practice';

import { Result } from 'redux/schemas/api';
import { PracticeSubmissionComment, VideoPracticeSubmission } from 'redux/schemas/models/video-practice';
import { BaseUser, User } from 'redux/schemas/models/my-account';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { addAlertMessage } from './alert-messages';
import { makeQueryParams, makeQueryParamString } from './helpers';
import { updatePracticeFeedbackPointsAndProgress } from './video-practice-feedback';

export const getFlyoutScenarios = createAsyncThunk<FetchFlyoutScenariosResponse, FetchFlyoutScenariosRequest>(
  'FETCH_FLYOUT_SCENARIOS',
  async (params) => {
    const response = await axios.get<Result<FetchFlyoutScenariosResponse>>('scenarios/flyout.json', { params });
    return response.data.result;
  },
);

export const getScenario = createAsyncThunk<FetchScenarioResponse, FetchScenarioRequest>(
  'FETCH_SCENARIO',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get<Result<FetchScenarioResponse>>(`scenarios/${params.scenarioId}.json`);
      return response.data.result;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setPracticeRoomState = createAction<PracticeRoomStateParams>(
  'SET_PRACTICE_ROOM_STATE',
);

export const setFilteredComments = createAction<SetFilteredCommentsParams>(
  'SET_PRACTICE_FILTERED_COMMENTS',
);

export const editScenario = createAsyncThunk<FetchScenarioResponse, EditScenarioRequest>(
  'EDIT_SCENARIO',
  async (params) => {
    const response = await axios.put<Result<FetchScenarioResponse>>(`/scenarios/${params.scenario.id}`, params.scenario);
    return response.data.result;
  },
);

export const searchScenarios = createAsyncThunk<SearchScenariosResponse, SearchScenariosRequest>(
  'SEARCH_SCENARIOS',
  async (params) => {
    const queryParams = makeQueryParams(params, undefined, params.pageIndex);
    const paramString = makeQueryParamString(queryParams);

    const response = await axios.get<Result<SearchScenariosResponse>>(`/scenarios?${paramString}`);
    params.callback?.(response.data.result);
    return response.data.result;
  },
);

export const getVideoPracticeSubmissions = createAsyncThunk<FetchVideoPracticeSubmissionsResponse, FetchVideoPracticeSubmissionsRequest>(
  'FETCH_VIDEO_PRACTICE_SUBMISSIONS',
  async (params) => {
    const { scenarioId, miniGallery, userId, activityId, ...queryParams } = params;
    const paramString = makeQueryParamString({ activityId, userId });

    const response = await axios.get<Result<FetchVideoPracticeSubmissionsResponse>>(
      `/scenarios/${scenarioId}/video_practice_submissions${miniGallery ? '/minigallery' : ''}.json?${paramString}`, {
        params: queryParams,
      },
    );
    return response.data.result;
  },
);

export const likeVideoPracticeSubmission = createAsyncThunk(
  'LIKE_VIDEO_PRACTICE_SUBMISSION',
  async (params: PracticeSubmissionActionParams) => {
    const { scenarioId, submissionId, practiceFeedbackCriteriaId } = params;
    const paramString = makeQueryParamString({ feedbackCriteriaId: practiceFeedbackCriteriaId });
    const response = await axios.post<Result<VoteUpResponse>>(
      `scenarios/${scenarioId}/video_practice_submissions/${submissionId}/voteup.json?${paramString}`,
    );
    return response.data.result;
  },
);

export const undoLikeVideoPracticeSubmission = createAsyncThunk(
  'UNDO_LIKE_VIDEO_PRACTICE_SUBMISSION',
  async (params: PracticeSubmissionActionParams) => {
    const { scenarioId, submissionId, practiceFeedbackCriteriaId } = params;
    const paramString = makeQueryParamString({ feedbackCriteriaId: practiceFeedbackCriteriaId });
    const response = await axios.post<Result<VoteUpResponse>>(
      `scenarios/${scenarioId}/video_practice_submissions/${submissionId}/votedown.json?${paramString}`,
    );
    return response.data.result;
  },
);

export const getVideoPracticeSubmissionLikers = createAsyncThunk(
  'FETCH_VIDEO_PRACTICE_SUBMISSION_LIKERS',
  async (params: PracticeSubmissionActionParams) => {
    const { scenarioId, submissionId } = params;

    const response = await axios.get<Result<User[]>>(
      `scenarios/${scenarioId}/video_practice_submissions/${submissionId}/voters.json`,
    );
    return response.data.result;
  },
);

export const postVideoHasViewed = createAsyncThunk(
  'POST_VIDEO_PRACTICE_VIDEO_VIEWED',
  async (params: PracticeSubmissionActionParams) => {
    const { scenarioId, submissionId } = params;

    const response = await axios.post<Result<PostVideoHasViewedResponse>>(
      `scenarios/${scenarioId}/video_practice_submissions/${submissionId}/track_view.json`,
    );
    return response.data.result;
  },
);

export const clearNewNotification = createAction<PracticeRoomStateParams>(
  'CLEAR_NEW_NOTIFICATION',
);
export const getComments = createAsyncThunk(
  'FETCH_PRACTICE_COMMENTS',
  async (params: { submissionId: number, practiceFeedbackCriteriaId?: number, page?: number }) => {
    const paramString = params.practiceFeedbackCriteriaId ? makeQueryParamString({ feedbackCriteriaId: params.practiceFeedbackCriteriaId }) : '';
    const response = await axios.get<Result<FetchPracticeCommentsResponse>>(`video_practice_submission/${params.submissionId}/posts.json?${paramString}&page=${params.page}`);
    return response.data.result;
  },
);

export const getComment = createAsyncThunk(
  'FETCH_PRACTICE_COMMENT',
  async (params: { submissionId: number, commentId: number }) => {
    const response = await axios.get(`video_practice_submission/${params.submissionId}/posts/${params.commentId}.json`);
    return response.data.result;
  },
);

export const postComment = createAsyncThunk(
  'POST_VIDEO_PRACTICE_COMMENT',
  async ({ submissionId, practiceFeedbackCriteriaId, ...params }: PostCommentParams, thunkAPI) => {
    const paramString = makeQueryParamString({ feedbackCriteriaId: practiceFeedbackCriteriaId });
    const response = await axios.post<Result<PracticeSubmissionComment>>(`video_practice_submission/${submissionId}/posts.json?${paramString}`, params);
    return response.data.result;
  },
);

export const updateComment = createAsyncThunk(
  'UPDATE_VIDEO_PRACTICE_COMMENT',
  async ({ practiceFeedbackCriteriaId, commentId, post }: UpdateCommentParams, thunkAPI) => {
    try {
      const paramString = makeQueryParamString({ feedbackCriteriaId: practiceFeedbackCriteriaId });
      const response = await axios.put<Result<PracticeSubmissionComment>>(`posts/${commentId}.json?${paramString}`, { post });
      return response.data.result;
    } catch (error) {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR(),
      }));
    }
    return null;
  },
);

export const deleteComment = createAsyncThunk(
  'DELETE_VIDEO_PRACTICE_COMMENT',
  async ({
    practiceFeedbackCriteriaId, commentId, isUserFirstReview, submissionId,
  }: DeleteCommentParams, thunkAPI) => {
    try {
      const paramString = makeQueryParamString({ feedbackCriteriaId: practiceFeedbackCriteriaId });
      const response = await axios.delete<Result<PracticeSubmissionComment>>(`posts/${commentId}.json?${paramString}`);
      if (isUserFirstReview) {
        // fetch the comments to update feedback related data
        thunkAPI.dispatch(getComments({ submissionId, practiceFeedbackCriteriaId }));
        // Update feedback activity
        thunkAPI.dispatch(updatePracticeFeedbackPointsAndProgress(response.data.result.publicFeedback));
      }
      return response.data.result;
    } catch (error) {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR(),
      }));
    }
    return false;
  },
);

export const likeComment = createAsyncThunk(
  'LIKE_PRACTICE_SUBMISSION_COMMENT',
  async (params: { commentId: number, practiceFeedbackCriteriaId?: number }) => {
    const paramString = params.practiceFeedbackCriteriaId
      ? makeQueryParamString({ feedbackCriteriaId: params.practiceFeedbackCriteriaId }) : '';
    const response = await axios.post<Result<VoteUpResponse>>(`posts/${params.commentId}/voteup.json?${paramString}`);
    return response.data.result;
  },
);

export const undoLikeComment = createAsyncThunk(
  'UNDO_LIKE_PRACTICE_SUBMISSION_COMMENT',
  async (params: { commentId: number, practiceFeedbackCriteriaId?: number }) => {
    const paramString = params.practiceFeedbackCriteriaId
      ? makeQueryParamString({ feedbackCriteriaId: params.practiceFeedbackCriteriaId }) : '';
    const response = await axios.post<Result<VoteUpResponse>>(`posts/${params.commentId}/votedown.json?${paramString}`);
    return response.data.result;
  },
);

export const getCommentLikers = createAsyncThunk(
  'FETCH_PRACTICE_COMMENT_LIKERS',
  async (params: { commentId: number }) => {
    const response = await axios.get<Result<User[]>>(`posts/${params.commentId}/voters.json`);
    return response.data.result;
  },
);

export const startVideoPracticeSubmission = createAsyncThunk<StartVideoPracticeSubmissionResponse, StartVideoPracticeSubmissionRequest>(
  'START_PRACTICE_SUBMISSION',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const { scenarioId, catalogId, activityId } = params;
      const response = await axios.post(`/scenarios/${scenarioId}/video_practice_submissions.json`, {
        catalogId,
        activityId,
      });
      return response.data.result;
    } catch (error) {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.OOPS(),
        message: t.FORM.ERROR_SOMETHING_WRONG(),
      }));
      return rejectWithValue(error);
    }
  },
);

export const completeVideoPracticeSubmission = createAsyncThunk<VideoPracticeSubmission, CompleteVideoPracticeSubmissionRequest>(
  'COMPLETE_PRACTICE_SUBMISSION',
  async (params, { dispatch, rejectWithValue }) => {
    const { scenarioId, activityId, submissionId, videoFile } = params;
    try {
      const response = await axios.patch(`/scenarios/${scenarioId}/video_practice_submissions/${submissionId}.json`, {
        activityId,
        videoFile,
      });
      return response.data.result;
    } catch (error) {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR_SOMETHING_WRONG(),
      }));
      return rejectWithValue(error);
    }
  },
);

export const toggleFeatureSubmission = createAsyncThunk(
  'TOGGLE_FEATURE_PRACTICE_SUBMISSION',
  async (params: ToggleFeaturePracticeSubmissionRequest, { dispatch, rejectWithValue }) => {
    const { scenarioId, submissionId, fullName, ...toggleParams } = params;
    try {
      const response = await axios.patch<Result<boolean>>(`scenarios/${scenarioId}/video_practice_submissions/${submissionId}.json`, toggleParams);
      const successMessage = toggleParams.featured
        ? t.PRACTICE_ROOM.SUBMISSION.FEATURE.FEATURE_SUCCESS_MSG(fullName)
        : t.PRACTICE_ROOM.SUBMISSION.FEATURE.STOP_FEATURE_SUCCESS_MSG(fullName);
      dispatch(addAlertMessage({
        type: AlertMessageType.SUCCESS,
        header: t.FORM.SUCCESS_BANG(),
        message: successMessage,
      }));
      return response.data.result;
    } catch (error) {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR(),
      }));
      return rejectWithValue(error);
    }
  },
);

export const deleteSubmission = createAsyncThunk(
  'DELETE_PRACTICE_VIDEO_SUBMISSION',
  async (params: DeletePracticeSubmissionRequest, { dispatch, rejectWithValue }) => {
    const { scenarioId, submissionId } = params;
    try {
      const response = await axios.delete<Result<boolean>>(`scenarios/${scenarioId}/video_practice_submissions/${submissionId}.json`);
      dispatch(addAlertMessage({
        type: AlertMessageType.SUCCESS,
        header: t.FORM.SUCCESS_BANG(),
        message: t.PRACTICE_ROOM.SUBMISSION.DELETE.SUCCESS(),
      }));
      return response.data.result;
    } catch (error) {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR(),
      }));
      return rejectWithValue(error);
    }
  },
);

export const getSubmissionsForUsers = createAsyncThunk<boolean, UsersSubmissionsRequest>(

  'GET_PRACTICE_SUBMISSIONS_FOR_USERS',

  async (params, thunkAPI) => {
    const { scenarioId, userIds } = params;
    const promises = userIds?.map(userId => thunkAPI.dispatch(getVideoPracticeSubmissions({ scenarioId, userId })));
    return await Promise.all(promises).then(res => true).catch(err => false);
  },
);

export const getMentionableUsers = createAsyncThunk(
  'FETCH_MENTIONABLE_USERS',
  async (params: { scenarioId: number, submissionId }) => {
    const response = await axios.get<Result<BaseUser[]>>(`practice_room/${params.scenarioId}/mentionable_users.json`, {
      params: {
        submission: params.submissionId,
      },
    });
    return response.data.result;
  },
);

export const leaveVideoPracticeSubmission = createAction<LeaveVideoPracticeSubmission>('LEAVE_PRACTICE_SUBMISSION');

export const removeHighlight = createAction<{ commentId: number }>('REMOVE_HIGHLIGHT');

export const getProfileSubmissions = createAsyncThunk(
  'FETCH_VIDEO_PRACTICES_FOR_PROFILE',
  async (params: OrgProfileSubmissionsRequest) => {
    const { page, userId } = params;
    const response = await axios.get<Result<FetchVideoPracticeSubmissionsResponse>>(`/video_practice_submissions/${userId}.json`, { params: { page } });
    return response.data.result;
  },
);

export const resetPracticeRoomSubmissionData = createAction(
  'RESET_PRACTICE_ROOM_SUBMISSION_DATA',
);

export const getPracticeSubmission = createAsyncThunk(
  'FETCH_PRACTICE_SUBMISSION',
  async (params: { submissionId: number, scenarioId: number, feedbackCriteriaId?: number }) => {
    const { submissionId, scenarioId, feedbackCriteriaId } = params;
    const paramString = feedbackCriteriaId ? makeQueryParamString({ feedbackCriteriaId }) : '';
    const response = await axios.get<Result<VideoPracticeSubmission>>(`/scenarios/${scenarioId}/video_practice_submissions/${submissionId}.json?${paramString}`);
    return response.data.result;
  },
);

export const resetPracticeComments = createAction<{ submissionId: number }>('RESET_PRACTICE_COMMENTS');

export const generateInsights = createAsyncThunk(
  'GENERATE_INSIGHTS',
  async (params: { submissionId: number, scenarioId: number, generateFillerWords?: boolean }, { dispatch, rejectWithValue }) => {
    const { submissionId, scenarioId } = params;
    try {
      const response = await axios.post<Result<boolean>>(`/scenarios/${scenarioId}/video_practice_submissions/${submissionId}/generate_insights`);
      dispatch(addAlertMessage({
        type: AlertMessageType.SUCCESS,
        header: t.FORM.SUCCESS_BANG(),
        message: t.PRACTICE_ROOM.INSIGHTS.GENERATE_INSIGHTS.SUCCESS(),
      }));
      return response.data.result;
    } catch (error) {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.OOPS(),
        message: t.FORM.SOMETHING_WRONG(),
      }));
      return rejectWithValue(error);
    }
  },
);

export const setPracticeFeedbackData = createAction<PracticeSubmissionComment>('SET_PRACTICE_FEEDBACK_DATA');

export const requestReviewPracticeSubmission = createAsyncThunk(
  'REQUEST_REVIEW_PRACTICE_SUBMISSION',
  async (params: PracticeSubmissionActionParams) => {
    const { scenarioId, submissionId } = params;
    const response = await axios.post<Result<VoteUpResponse>>(
      `scenarios/${scenarioId}/video_practice_submissions/${submissionId}/request_review.json`,
    );
    return response.data.result;
  },
);

export const setShowPracticeAgainModal = createAction<{ show: boolean }>('SET_SHOW_PRACTICE_AGAIN_MODAL');