import { RolesService } from 'institutions/services/roles-service';
import { schema } from 'normalizr';
import { CourseRole, OrgAdminRoles } from 'redux/schemas/models/org-level-roles';
import { OrgAdmin, OrgLevelUser } from '../models/user';
import { OrgAdminsNormalized, OrgLevelUserNormalized } from './user';

export interface OrgUserEntities {
  orgLevelUsers: OrgLevelUserNormalized,
}

export interface OrgAdminEntitties {
  orgAdmins: OrgAdminsNormalized
}

export interface CourseRolesNormalized {
  [id: string]: CourseRole
}

export interface CourseRolesEntities {
  courseRoles: CourseRolesNormalized
}

const orgLevelUserSchema = new schema.Entity<OrgLevelUser>('orgLevelUsers');
export const orgLevelUsersSchema = new schema.Array(orgLevelUserSchema);

export const orgAdminSchema = new schema.Entity<OrgAdmin>('orgAdmins');
export const orgAdminListSchema = new schema.Array(orgAdminSchema);

export const courseRolesSchema = new schema.Entity<CourseRole>('courseRoles');
export const courseRolesListSchema = new schema.Array(courseRolesSchema);
courseRolesSchema._processStrategy = (value) => ({
  ...value,
  permissions: value.permission ? RolesService.getVisiblePermissions(value.permission) : [],
});

export interface SearchOrgUsersResponse {
  count: number;
  users: OrgLevelUser[];
}

export interface SearchOrgAdminsResponse {
  count: number;
  orgAdmins: OrgAdmin[];
}

export interface OrgAdminParams {
  institutionId: number
  adminRole: OrgAdminRoles
  user: {
    admin: boolean,
    email: string,
    firstName: string,
    lastName: string,
  }
}

export interface OrgAdminUpdateParams {
  adminId: number
  institutionId: number
  adminRole: OrgAdminRoles
}

export interface CourseRolesParams {
  badge: string
  institutionId: number
  name: string
  menteeName?: string
  permissionOptions: { [key: string]: [boolean, string] }
}

export interface CourseRolesUpdateParams extends CourseRolesParams {
  roleId: number
}

export interface AddMultipleUsersParams {
  institutionId: number
  users: {
    firstName: string,
    lastName: string,
    email: string,
    profileAnswers: {
      [key: string]: string
    }
  }[]
  filterEmptyCells: boolean
  addNewUser: boolean
  sendWelcomeEmail: boolean
  profileSettings: {
    [key: string]: string
  }
}
interface NotFoundUser extends OrgLevelUser {
  errors: string[]
}

export interface BulkUploadErrorUser extends OrgLevelUser {
  profileErrors: {
    code: string,
    columnName: string
  }[]
}

export interface LargerBulkUploadResponse {
  threshold: boolean
}

export interface SmallerBulkUploadResponse {
  existingUsersUpdated: number;
  newUsersAdded: number;
  notFoundUsers: NotFoundUser[];
  profilesNotUpdated: BulkUploadErrorUser[];
  profilesUpdatedCompletely: OrgLevelUser[];
  profilesUpdatedPartially: BulkUploadErrorUser[];
}
