import React, { useContext, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'redux/store';
import { RootState } from 'redux/schemas';
import { MyAccount } from 'redux/schemas/models/my-account';
import { getCurrentUser } from 'redux/selectors/users';
import { voteDownReply, voteUpReply } from 'redux/actions/replies';
import { getCommentLikersInfo, voteDownComment, voteUpComment } from 'redux/actions/comments';
import useClickOutside from 'shared/hooks/use-click-outside';
import { gray4, primary } from 'styles/global_defaults/colors';
import { PopoversContainerContext } from 'shared/react-utils';

import NvUserAvatar from 'components/nv-user-avatar';
import { config } from '../../../../config/pendo.config.json';
import NvIcon from '../nv-icon';
import NvTooltip from '../nv-tooltip';
import NvPopover from '../nv-popover';
import { CommentRowContext } from './comment-row';
import { CommentsContainerContext } from './comments-container';

const CommentLikes = () => {
  const { catalogId } = useContext(CommentsContainerContext);
  const { comment, isReply } = useContext(CommentRowContext);

  const styles = css`
    button {
      border: none;
      background: none;
      outline: none;
      padding-left: 0;
    }

    &.disabled {
      .icon {
        color: ${gray4};
      }
    }

    &:not(.disabled) {
      &:hover {
        cursor: pointer;
      }

      &.active {
        .icon-like, .likes-count {
          color: ${primary};
        }
      }

      .icon-like:hover {
        color: ${primary};
      }
    }
  `;

  const dispatch = useAppDispatch();

  const currentUser = useSelector<RootState, MyAccount>(getCurrentUser);

  const isCurrentUser = currentUser?.id === comment?.user.id;

  const ref = useRef(null);
  const containerRef = useRef(null);

  const [showPopover, setShowPopover] = useState(false);

  const openPopover = () => {
    setShowPopover(true);
    if (!comment.likersFetched) {
      dispatch(getCommentLikersInfo({
        catalogId,
        commentId: comment.id,
        page: 1,
      }));
    }
  };

  useClickOutside(ref, () => {
    setShowPopover(false);
  });

  const likerRows = useMemo(() => {
    const rows = [];
    let row = [];
    comment.likers.forEach((user, i) => {
      row.push(user);
      if ((i + 1) % 3 === 0) {
        rows.push(row);
        row = [];
      }
    });

    if (row.length) {
      rows.push(row);
    }

    return rows;
  }, [comment.likers]);

  const popoverContent = (
    <div className='likers-popover' ref={ref}>
      {likerRows.map(row => (
        <div className='likers-popover-row'>
          {row.map((user, i) => (
            <NvUserAvatar
              className={`${i === 0 ? 'first' : ''}`}
              user={user}
              size='sm'
              borderType='round'
              displayName
            />
          ))}
        </div>
      ))}
    </div>
  );

  const toggleLike = () => {
    if (comment.liked) {
      dispatch(isReply
        ? voteDownReply({
          catalogId,
          replyId: comment.id,
        })
        : voteDownComment({
          catalogId,
          commentId: comment.id,
        }));
    } else {
      dispatch(isReply
        ? voteUpReply({
          catalogId,
          replyId: comment.id,
        })
        : voteUpComment({
          catalogId,
          commentId: comment.id,
        }));
    }
  };

  return (
    <PopoversContainerContext.Provider value={containerRef.current}>
      <div
        css={styles}
        className={`d-flex align-items-center ${comment.liked ? 'active' : ''} ${isCurrentUser ? 'disabled' : ''}`}
        ref={containerRef}
      >
        <NvTooltip
          text={comment.liked ? t.DISCUSSIONS.UNLIKE() : t.DISCUSSIONS.LIKE()}
          placement='bottom'
          enabled={!isCurrentUser}
        >
          <button
            type='button'
            disabled={isCurrentUser}
            onClick={toggleLike}
            data-qa={config.pendo.discussionAnywhere.likeComment}
            aria-label={comment.liked ? t.DISCUSSIONS.UNLIKE() : t.DISCUSSIONS.LIKE()}
          >
            <NvIcon size='smallest' icon='like' className='gray-3' />
          </button>
        </NvTooltip>
        {comment.votesCount > 0 && (
          <NvPopover content={popoverContent} placement='top' show={showPopover}>
            <div className='likes-count btn-link' onClick={openPopover}>
              {comment.votesCount}
            </div>
          </NvPopover>
        )}
      </div>
    </PopoversContainerContext.Provider>
  );
};

export default CommentLikes;
