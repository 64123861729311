/* @ngInject */
export default function LhsCoursesCtrl(

  CurrentUserManager,
  CurrentCourseManager,
  InteroperabilityRoutes,
  TeamWorkspaceManager,
  PusherManager,
  PusherChannels,
  $filter,
  humps,
  $timeout,
  _,
  config,
) {
  const vm = this;

  vm.config = config;
  vm.CurrentUserManager = CurrentUserManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.InteroperabilityRoutes = InteroperabilityRoutes;
  // vm.coursesOrderByPredicate = ['-inProgress', '!endDate', '-endDate', '!releaseDate', '-releaseDate', '!createdAt', '-createdAt', '-id'];
  vm.defaultNumCoursesShown = 10;
  vm.isCourseExpanded = false;
  vm.getTooltipText = getTooltipText;
  vm.expandCourses = expandCourses;
  vm.collapseCourses = collapseCourses;
  vm.TeamWorkspaceManager = TeamWorkspaceManager;

  initialize();

  function initialize() {
    // Nothing to initialize here for now
  }

  function getTooltipText(course) {
    if (course.isProgram) {
      return `${course.name} <span class="gray-4">${course.offeringName.singularizedTitleized}</span>`;
    }
    return course.name;
  }

  function expandCourses() {
    $timeout(() => {
      vm.isCourseExpanded = true;
    }, 1000);
  }

  function collapseCourses() {
    $timeout(() => {
      vm.isCourseExpanded = false;
    }, 1000);
  }
}
