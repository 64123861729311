import React from 'react';
import { css } from '@emotion/react';

import t from 'react-translate';
import { AngularContext } from 'react-app';
import NvIcon from 'shared/components/nv-icon';
import { Course } from 'redux/schemas/models/course';
import NvTooltip from 'shared/components/nv-tooltip';
import { gray3, warning } from 'styles/global_defaults/colors';

type Props = {
  course: Course,
  className: string,
};

const Icon = (props: Props) => {
  const { course, className } = props;
  const { isPrimary, isCohort } = course;
  const { injectServices } = React.useContext(AngularContext);
  const [CurrentPermissionsManager] = injectServices(['CurrentPermissionsManager']);

  const styles = css`
    color: ${isPrimary ? warning : gray3};
  `;

  if ((isPrimary || isCohort) && (CurrentPermissionsManager.hasOrgAdminPermissions() || CurrentPermissionsManager.hasCourseManagerPermissions())) {
    return (
      <NvTooltip text={isPrimary ? t.COHORT_MANAGEMENT.PRIMARY() : t.COHORT_MANAGEMENT.COHORT()}>
        <NvIcon
          css={styles}
          size='xss-smallest'
          className={className}
          icon={isPrimary ? 'badge-primary' : 'badge-cohort'}
        />
      </NvTooltip>
    );
  }

  return null;
};

export default Icon;
