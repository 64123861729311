import uuid from 'react-uuid';
import store from 'redux/store';
import CompletionCriteriaHeader from '../../timelines/components/completion_criteria/l1-header';
import LecturePageRootSingleton from '../services/lecture-page-root';
import { JourneyBadge } from '../../course_home/components/journey-badge';

/* @ngInject */
export default function LectureLightHeaderCtrl(
  $scope,
  $timeout,
) {
  const vm = this;
  $scope.JourneyBadge = JourneyBadge;
  $scope.CompletionCriteriaHeader = CompletionCriteriaHeader;
  const lectureUpdateUUID = uuid();
  const state = store.getState();

  vm.currentCourseIsCollection = state.models.courses[state.app.currentCatalogId]?.isContentManagementCollection;

  LecturePageRootSingleton.registerLectureUpdatedCB(lectureUpdateUUID, () => {
    $timeout(() => {
      vm.currentLecture = LecturePageRootSingleton.currentLecture;
    });
  });

  $scope.$on('$destroy', () => {
    LecturePageRootSingleton.unregisterLectureUpdatedCB(lectureUpdateUUID);
  });
}
