/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';

import ReactAngularColorPicker from 'shared/directives/nv-color-picker/component';

/* @ngInject */
/* @ngInject */
export default function NvColorPicker(_, $timeout) {
  return {
    scope: {
      ngModel: '=',
      showColor: '=?',
      required: '=?',
      form: '=?',
      name: '@?',
    },
    require: ['nvColorPicker', 'ngModel'],
    controller: ['$scope', function ($scope) {
      const vm = this;

      let setReactColor;

      $scope.ReactAngularColorPicker = () => {
        const [color, setColor] = React.useState(vm.ngModel);

        setReactColor = setColor;

        const handleChange = (newColor: string) => {
          setColor(newColor);
          vm.ngModelCtrl.$setViewValue(newColor);
        };

        return (
          <ReactAngularColorPicker ctrl={vm} color={color} onChange={handleChange} />
        );
      };

      $scope.$watch('vm.ngModel', (newColor) => setReactColor?.(newColor));
    }],
    link: (scope, element, attrs, ctrls) => {
      const [ctrl, ngModelCtrl] = ctrls;
      ctrl.ngModelCtrl = ngModelCtrl;
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'shared/directives/nv-color-picker/template.html',
  };
}
