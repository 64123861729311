import React, { useContext, useEffect } from 'react';
import { RouteProps } from 'react-router-dom';

// Hooks
import useTranslationsLoaded from 'shared/hooks/use-translations-loaded';

// Actions
import { useAppDispatch } from 'redux/store';
import { resetPracticeRoomSubmissionData, setFilteredComments, setPracticeRoomState } from 'redux/actions/video-practice';

// Contexts
import { AngularServicesContext } from 'react-app';

// Components
import NvRouter from 'shared/components/nv-router';
import ContentWrapper from './content-wrapper';

/**
 * Base component to display a Practice Scenario Overview.
 */

const routes: RouteProps[] = [
  {
    path: '/',
    component: ContentWrapper,
  },
];

const PracticeRoom = () => {
  const dispatch = useAppDispatch();
  const { $state } = useContext(AngularServicesContext);
  const { scenarioId } = $state?.params;

  useEffect(() => () => {
    dispatch(setPracticeRoomState({
      scenarioId: null,
      selectedTab: null,
      userId: null,
      submissionId: null,
      selectedSubmissionTab: null,
      feedbackBy: null,
    }));

    dispatch(setFilteredComments({
      submissionId: null,
      filteredComments: [],
    }));

    dispatch(resetPracticeRoomSubmissionData());
  }, [dispatch]);

  const setInteractedWith = () => {
    dispatch(setPracticeRoomState({
      userInteractedWith: true,
    }));
  };

  useEffect(() => {
    window.addEventListener('scroll click', setInteractedWith);

    return () => {
      window.removeEventListener('scroll click', setInteractedWith);
    };
  });


  const translationsLoaded = useTranslationsLoaded();

  if (!translationsLoaded) {
    return null;
  }

  return (
    <NvRouter
      basename={`/#!/practice-room/${scenarioId}`}
      routes={routes}
    />
  );
};

export default PracticeRoom;
