import moment from 'moment';
import React, { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { AngularServicesContext } from 'react-app';
import t from 'react-translate';

import { SkillRating, SkillTag } from 'redux/schemas/models/skill-tag';
import { getSubmissionRatingsByUser } from 'redux/selectors/skills-feedback';

import ClickableContainer from 'components/clickable-container';
import NvUserAvatar from 'components/nv-user-avatar';
import ResponsivelyEmbeddedAngularHTML from '../responsively-embedded-angular-html';
import NvStarRating from '../nv-star-rating';
import NvIcon from '../nv-icon';
import LoadingWrapper, { LoaderType } from '../loading-wrapper';

type Rating = SkillRating & { skillTag: SkillTag };

type DisplaySkillRatingProps = {
  ratingIds: number[]
  onToggle: () => void
  isLoaded: boolean
};

const DisplaySkillRating = ({
  ratingIds,
  onToggle,
  isLoaded,
}: DisplaySkillRatingProps) => {
  const angularServices = useContext(AngularServicesContext);
  const ratings: Rating[] = useSelector((state) => getSubmissionRatingsByUser(state, ratingIds));
  const feedbackByUserRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (feedbackByUserRef?.current && ratings.length > 0) {
        feedbackByUserRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 1000);
  }, [ratings.length]);

  return (
    <LoadingWrapper isLoaded={isLoaded} loaderType={LoaderType.PLACEHOLDER}>
      <div>
        <ClickableContainer
          onClick={onToggle}
          className='d-flex text-primary align-items-center'
        >
          <NvIcon icon='collapse' size='small' className='mr-2' />
          {t.PRACTICE_ROOM.SKILL_FEEDBACK.VIEW_ALL_FEEDBACK()}
        </ClickableContainer>
        <div className='d-flex mt-4'>
          {ratings[0]?.user && (
            <div className='d-none d-md-block'>
              <NvUserAvatar
                borderType='round'
                size='md'
                user={ratings[0].user}
                displayName
                className='mx-4 mb-1 gray-2 text-small'
                directToOrgLevelProfile
                displayRoleBadge
              />
            </div>
          )}
          <div className='w-100'>
            {ratings[0]?.user && (
              <div className='d-block d-md-none mb-4'>
                <NvUserAvatar
                  displayName
                  inlineName
                  displayRoleBadge={false}
                  alignNameRight
                  user={ratings[0].user}
                  size='sm'
                  borderType='round'
                  directToOrgLevelProfile
                  className='mx-4 mb-1 gray-2 text-small'
                />
              </div>
            )}
            {ratings?.map(skillRating => (
              <div key={`skill-rating-${skillRating.id}`} className='d-flex flex-column w-100 ml-2'>
                <div className='d-flex justify-content-between mb-2'>
                  <div className='d-flex'>
                    <div className='text-medium font-weight-bolder gray-2 mr-2'>
                      {skillRating.skillTag?.name}
                    </div>
                    <NvStarRating
                      readonly
                      ratedValue={skillRating.rating}
                      starSize='smallest'
                    />
                  </div>
                </div>
                <ResponsivelyEmbeddedAngularHTML
                  className='text-body'
                  angularServices={angularServices}
                  template={skillRating?.body}
                />
              </div>
            ))}
            {ratings[0]?.createdAt && (
              <div className='text-date gray-2 text-right'>
                {moment(ratings[0].createdAt).format('lll')}
              </div>
            )}
            <div className='text-small gray-3 mt-6 '>
              {t.SKILLS_FEEDBACK.FEEDBACK_VISIBILITY.ALL()}
            </div>
          </div>
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default DisplaySkillRating;
