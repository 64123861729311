import React from 'react';
import { css } from '@emotion/react';
import { useFormContext, UseFormRegisterReturn } from 'react-hook-form';
import { mergeRefs } from 'shared/react-utils';
import { iconSmallSize } from 'styles/global_defaults/icons';
import { textSmallFontSize } from 'styles/global_defaults/fonts';
import { gray1, gray2, gray4, gray7 } from 'styles/global_defaults/colors';
import {
  halfSpacing,
  largeSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';

type NativeInputProps = React.ComponentProps<'input'>;

/**
 * Props we don't need to inherit from native input props.
 */
type OmittedNativeInputProps =
  // Omitting id because we are passing id as the value of the "name" prop.
  | 'id'
  // Omitting type since this is forced to be a "checkbox" one.
  | 'type'
  // Omitting because it's now "inputClassName".
  | 'className'
  // Omitting overridden props as well so we can actually override them.
  | keyof OverriddenProps;

/**
 * Props that we want to inherit from the native input props.
 */
// Just omitting the ones we don't need.
type NativeInputPropsInherited = Omit<NativeInputProps, OmittedNativeInputProps>;

// Overriding properties to only make them required (since in
// React.ComponentProps<'input'> they're optional "?")
type OverriddenProps = {
  name: string, // Overriding input name since it's required for elements ids
};

/**
 * Props is the combination of:
 * 1: Our overridden props of the native input.
 * 2: Our "additional" props which is any prop that is not from the native input
 * props.
 * 3. Our inherited props that are directly passed to the input element via
 * "restProps"
 */
type Props = NativeInputPropsInherited & OverriddenProps & {
  // Text of the checkbox.
  label: string,
  // "aria-label" attribute value of the input element.
  ariaLabel?: string,
  // Class name of the container element.
  className?: string,
  // Whether we want to use this component in a react-hook-form form.
  // NOTE: Requires "FormProvider" parent wrapper to work.
  withForm?: boolean,
  // Class name of the label element.
  labelClassName?: string,
  // Whether the checkbox is in indeterminate state.
  indeterminate?: boolean,
  // Class name of the input element.
  inputClassName?: string,
  // data-qa string
  dataQa?: string,
};

/**
 * NovoEd checkbox component.
 * This component can be used as a controlled and uncontrolled component as well
 * as it can be hooked into a react-hook-form form if you pass the "withForm"
 * prop as "true".
 * If you use withForm make sure:
 *   - You don't pass the "checked" prop or you'll be forcing the value of the
 *     checkbox to be the one you are declaring even though it's connected to a
 *     react-hook-form form already.
 *   - You pass the "name" prop as well to make react-hook-form field registry
 *     process work.
 */
const NvCheckbox = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    // Native input props:
    name,
    onBlur,
    checked,
    disabled,
    onChange,
    // Additional props:
    label,
    ariaLabel,
    className,
    inputClassName,
    labelClassName,
    withForm = false,
    indeterminate = false,
    dataQa,
    ...restProps // Native input only props
  } = props;

  const { register } = useFormContext() || {};
  const checkboxRef = React.useRef<HTMLInputElement>();

  let extraProps = {};
  let reactHookFormRefHandler;
  let reactHookFormProps: Partial<UseFormRegisterReturn> = {};

  if (withForm) {
    const { ref: registerRef, ...restRegisterProps } = register(name);

    reactHookFormRefHandler = registerRef;
    reactHookFormProps = restRegisterProps;
  } else {
    extraProps = {
      checked,
    };
  }

  const styles = css`
    .nv-checkbox-label {
      position: relative;
      padding-left: ${largeSpacing}px;
      display: flex;
      align-items: center;
      line-height: 1.5em;

      &:before {
        display: inline-block;
        max-width: 100%;
        width: ${iconSmallSize}px;
        height: ${iconSmallSize}px;
        border: 1px solid ${gray4};
        background-color: ${gray7};
        content: '';
        border-radius: 5px;
        vertical-align: text-bottom;
        margin-left: -${standardSpacing}px;
        margin-right: ${halfSpacing}px;
        cursor: pointer;
        position: absolute;
        left: ${standardSpacing}px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    input {
      opacity: 0;
      position: absolute;
      &:checked + label:before {
        font-family: 'novoed-icons';
        color: ${gray1};
        border: 1px solid ${gray2};
        font-size: ${textSmallFontSize}px;
        line-height: 18px;
        content: "\\e029"; /* icon-check */
        text-align: center;
      }

      &.indeterminate + label:before {
        font-family: 'novoed-icons';
        color: ${gray1};
        border: 1px solid ${gray2};
        font-size: ${textSmallFontSize}px;
        line-height: 18px;
        content: "\\e037"; /* icon-dash */
        text-align: center;
      }

      &[disabled] {
        cursor: default;

        & + label {
          opacity: 0.5;

          &:before {
            opacity: 0.5;
            cursor: default;
          }
        }

        &.indeterminate + label, &:checked + label {
          opacity: 1;
        }
      }
    }
  `;

  const handleBlur = (e) => {
    onBlur?.(e);
    (reactHookFormProps as UseFormRegisterReturn).onBlur?.(e);
  };

  const handleChange = (e) => {
    onChange?.(e);
    (reactHookFormProps as UseFormRegisterReturn).onChange?.(e);
  };

  const inputClassNames = [
    indeterminate && 'indeterminate',
    inputClassName,
  ].filter(Boolean).join(' ') || undefined;

  return (
    <div css={styles} className={className}>
      <input
        {...restProps}
        {...extraProps}
        id={name}
        name={name}
        type='checkbox'
        disabled={disabled}
        onBlur={handleBlur}
        aria-label={ariaLabel}
        data-qa={dataQa}
        onChange={handleChange}
        className={inputClassNames}
        ref={mergeRefs(
          ...(
            withForm
              ? [ref, checkboxRef, reactHookFormRefHandler]
              : [ref, checkboxRef]
          ),
        )}
      />
      <label
        htmlFor={name}
        className={`nv-checkbox-label ${labelClassName ?? ''}`}
      >
        {label}
      </label>
    </div>
  );
});

export default NvCheckbox;
