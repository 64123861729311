import prodPathReplace from 'shared/prod-path-rewrite';
import { probablySupportsVideoType } from 'recording/services/media-visualizer-helper';

/* @ngInject */
export default function nvUploadedFile(
  $sce,
  $uibModal,
  $window,
  _,
  config,
  nvUtil,
  $timeout,
  CurrentCourseManager,
) {
  const embeddingStyles = {
    '4:3': 'embed-responsive-4by3',
    '1:1': 'embed-responsive-1by1',
    '21:9': 'embed-responsive-21by9',
    '16:9': 'embed-responsive-16by9',
    a4: 'embed-responsive-a4',
    letter: 'embed-responsive-letter',
  };

  return {
    scope: {
      file: '=',
      editable: '=?',
      displayInline: '=?',
      displayInlineStrict: '=?',
      displayNameOnly: '=?',
      fileName: '=?',
      downloadAlwaysVisible: '=?',
      onDelete: '&?',
      clickHandler: '&?',
      originalUrl: '=?',
      onFileLoaded: '&?',
      hideDownloadButton: '=?',
      bookmarkId: '=?',
      createBookmark: '&?',
      highlightBookmark: '&?',
    },
    controller: function ctrl($scope, $element, $attrs) {
'ngInject';
      const vm = this;

      _.extend(vm, {
        isContentManagementCollection: CurrentCourseManager.course?.isContentManagementCollection ?? false,
        showConfirmation: false,
        isVideoNotPlayable: false,
        fileHovered: false,

        normalizeFileType,
        getImageSrc,
        showDeletionConfirmation,
        confirmDeletion,
        cancelDeletion,
        handleFilePreviewClick,
        formatFileSize,
        canProbablyPreview,
        onVideoError,
        downloadFile,
        isFileAvailableToDownload,
      });

      function launchPreviewModal() {
        $uibModal.open({
          templateUrl: 'shared/templates/nv-file-upload-preview-modal.html',
          windowClass: 'file-preview-modal large-modal tall-modal full-screen-modal-handheld full-screen-modal-tablet',
          controller: 'AttachModalResolvesToVmCtrl as vm',
          resolve: {
            vmResolves: () => ({
              file: vm.file,
              fileType: vm.fileType,
              isVisualMedia: vm.isVisualMedia,
              downloadFile: vm.downloadFile,
              isFileAvailableToDownload: vm.isFileAvailableToDownload,
              bookmarkId: vm.bookmarkId,
              createBookmark: vm.createBookmark,
              highlightBookmark: vm.highlightBookmark,
              isContentManagementCollection: vm.isContentManagementCollection,
            }),
          },
        });
      }

      function normalizeFileType(fileType) {
        if (vm.fileType === 'image' || _.includes(config.files.images.split(','), fileType)) {
          return 'image';
        } if (_.includes(config.files.videos.split(','), fileType)) {
          return 'video';
        } if (_.includes(config.files.audios.split(','), fileType)) {
          return 'audio';
        } if (_.includes(config.files.pdfs.split(','), fileType)) {
          return 'pdf';
        } if (_.includes(config.files.documents.split(','), fileType)) {
          return 'document';
        } if (_.includes(config.files.excels.split(','), fileType)) {
          return 'spreadsheet';
        } if (_.includes(config.files.presentations.split(','), fileType)) {
          return 'presentation';
        } if (_.includes(config.files.zips.split(','), fileType)) {
          return 'zip';
        }
        return 'other';
      }

      function getImageSrc(type) {
        // NOTE: have to return the full path string so gulp will be able to process properly
        switch (type) {
          case 'pdf': {
            return prodPathReplace('images/file-pdf.png');
          }
          case 'image': {
            return prodPathReplace('images/file-image.png');
          }
          case 'video': {
            return prodPathReplace('images/file-video.png');
          }
          case 'audio': {
            return prodPathReplace('images/file-audio.png');
          }
          case 'document': {
            return prodPathReplace('images/file-doc.png');
          }
          case 'spreadsheet': {
            return prodPathReplace('images/file-excel.png');
          }
          case 'presentation': {
            return prodPathReplace('images/file-powerpoint.png');
          }
          case 'externaldocument': {
            return prodPathReplace('images/file-externaltools.png');
          }
          case 'zip': {
            return prodPathReplace('images/file-zip.png');
          }
          case 'other': {
            return prodPathReplace('images/file-other.png');
          }
          default: {
            return null;
          }
        }
      }

      function showDeletionConfirmation() {
        vm.showConfirmation = true;
      }

      function confirmDeletion() {
        vm.showConfirmation = false;
        vm.onDelete({ $file: vm.file });
      }

      function cancelDeletion() {
        vm.showConfirmation = false;
      }

      function handleFilePreviewClick($event) {
        $event.stopPropagation();

        if (vm.clickHandler) {
          vm.clickHandler({ $file: vm.file });
        }

        if (vm.isExternalDoc) {
          $event.stopPropagation();
          $window.open(vm.file.url, '_blank');
        } else {
          launchPreviewModal();
        }
      }

      function downloadFile() {
        if (vm.file?.url) {
          window.location.href = vm.file.url;
        }
      }

      function isFileAvailableToDownload() {
        return (typeof vm.file.isDownloadable === 'undefined' || vm.file.isDownloadable === null) ? true : vm.file.isDownloadable;
      }

      function formatFileSize(size) {
        return nvUtil.formatFileSize(size);
      }

      // we don't know for sure if a file can be played for sure, but the browser tells us if support is likely
      function canProbablyPreview() {
        return (vm.file && !vm.file.url) // legacy code: assumes that if it is a File(or maybe Blob), then it is playable
          || probablySupportsVideoType(vm.file.mimeType || vm.file.type);
      }

      function onVideoError($event) {
        if ($event.currentTarget.error.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
          vm.isVideoNotPlayable = true;
        }
      }
    },
    link(scope, element, attr, vm) {
      scope.$watchGroup(['vm.file.typeFromExtension', 'vm.file.url', 'vm.displayInline', 'vm.file.embeddingStyle'], updateDisplaySettings);

      function notifyFileLoad() {
        $timeout(() => {
          if (vm.isVisualMedia) {
            switch (vm.fileType) {
              case 'image': {
                const image = element.find('img.preview');
                image.on('load', vm.onFileLoaded);
                break;
              }
              case 'video': {
                const video = element.find('video.preview');
                video.on('loadeddata', vm.onFileLoaded);
                break;
              }
              default: if (vm.file.url) vm.onFileLoaded();
            }
          } else if (vm.fileUrl) vm.onFileLoaded();
        });
      }

      function updateDisplaySettings() {
        if (!vm.displayNameOnly) {
          vm.isExternalDoc = vm.file.type === 'externaldocument' || vm.file.source === 'googleDrive';

          // get the file type from provided type or from mimeType
          vm.fileType = vm.isExternalDoc ? vm.file.documentType : (vm.file.type || vm.file.typeFromExtension);

          vm.fileType = vm.fileType?.toLowerCase();
          vm.fileType = vm.fileType.split(';')[0]; // ignore codecs since it may be included

          vm.fileType = vm.normalizeFileType(vm.fileType);

          vm.responsiveEmbeddingStyle = embeddingStyles[vm.file.embeddingStyle] || embeddingStyles['16:9'];

          vm.isVisualMedia = vm.fileType === 'image' || vm.fileType === 'video' || vm.fileType === 'audio';
          vm.shouldDisplayInline = vm.displayInline || (!vm.displayInlineStrict && !vm.isExternalDoc && vm.isVisualMedia);

          if (vm.isVisualMedia || !vm.file.googleViewUrl) {
            if (vm.isExternalDoc && vm.file.documentUrl) {
              vm.fileUrl = $sce.trustAsResourceUrl(vm.file.documentUrl);
            } else {
              vm.fileUrl = $sce.trustAsResourceUrl(vm.file.url);
            }
          } else {
            vm.fileUrl = $sce.trustAsResourceUrl(vm.file.googleViewUrl);
          }

          vm.isVideoNotPlayable = false;
        }

        if (vm.onFileLoaded) notifyFileLoad();
      }
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'shared/templates/nv-uploaded-file.html',
  };
}
