/* @ngInject */
export default function InvitationsAndRequestsCtrl(
  InvitationsAndRequestsManager,
  CurrentUserManager,
  CurrentCourseManager,
  InteroperabilityRoutes,
  config,
  $element,
  nvUtil,
) {
  const vm = this;

  vm.config = config;
  vm.manager = InvitationsAndRequestsManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.InteroperabilityRoutes = InteroperabilityRoutes;
  vm.loadCourseInvitations = loadCourseInvitations;
  vm.displayCourseList = loadCourseList;

  vm.openPopover = openPopover;
  vm.closePopover = closePopover;

  function openPopover() {
    vm.popoverOpen = true;
  }

  function closePopover() {
    vm.popoverOpen = false;
  }

  function loadCourseInvitations(course) {
    vm.manager.setCourse(course);
    if ($element[0]?.parentNode) {
      nvUtil.focusFirstElement($element[0]?.parentNode);
    }
  }
  function loadCourseList() {
    vm.manager.setCourse();
    if ($element[0]?.parentNode) {
      nvUtil.focusFirstElement($element[0]?.parentNode);
    }
  }
}
