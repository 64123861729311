import { css } from '@emotion/react';
import { useEffect, useRef } from 'react';
import { random } from 'underscore';
import t from 'react-translate';

// Styles
import { handheld, isHandheld, isNotDesktop, tablet } from 'styles/global_defaults/media-queries';
import { doubleSpacing } from 'styles/global_defaults/scaffolding';

// Components
import NvIcon from 'shared/components/nv-icon';
import Star from './star';

/**
 * A component used in Practice Room to display the stats with stars animation
 */

type StatWithStarsProps = {
  icon: string,
  translateKey: string,
  count: number,
  colorClass: string,
};

const styles = css`
  position: relative;

  .icon-wrapper {
    position: relative;
    width: ${doubleSpacing * 2}px;
    height: ${doubleSpacing * 2}px;

    ${tablet(css`
      width: ${doubleSpacing}px;
      height: ${doubleSpacing}px;
    `)};

    ${handheld(css`
      width: auto;
      height: auto;
    `)}

    &:after {
      content: "";
      border-radius: 50%;
      background: linear-gradient(
        231.08deg,
        #00ca9d 0%,
        #aeefaa 68.67%,
        #fbffc1 100%
      );
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.2;

      ${handheld(css`
        display: none;
      `)};
    };
  };

  .course-title-small, .icon {
    /* To appear above stars */
    z-index: 1;
  }

  /* Styles associated with each star */
  .star {
    position: absolute;
    top: 0px;
    opacity: 0;
    font-size: 0px; /* To position svg correctly */

    svg {
      transform: translateZ(0px);
    }

    .close-star {
      transform: rotate(45deg);
    };
  };
`;

// Insert stars to the element given
const insertStars = (element) => {
  for (let i = 0; i < random(6, 10); i += 1) {
    // eslint-disable-next-line no-new
    new Star(element);
  }
};

const StatWithStars = ({ icon, translateKey, count, colorClass }: StatWithStarsProps) => {
  const thisElement = useRef(null);

  let iconSize = 'medium-large';
  let text = t.PRACTICE_ROOM.STATS[translateKey](count);
  if (isHandheld()) {
    iconSize = 'smallest';
    text = count;
  } else if (isNotDesktop()) {
    iconSize = 'small';
  }

  useEffect(() => {
    setTimeout(() => {
      if (thisElement.current.offsetParent === null) {
        return;
      }
      insertStars(thisElement.current);
    });
  }, []);

  return count && (
    <div
      css={styles}
      className={`star-item d-flex align-items-center ${colorClass}`}
      ref={thisElement}
      onMouseEnter={() => insertStars(thisElement.current)}
    >
      <div className='icon-wrapper d-flex justify-content-center align-items-center'>
        <NvIcon icon={icon} size={iconSize} />
      </div>
      <div className='course-title-small px-2'>
        {text}
      </div>
    </div>
  );
};

export default StatWithStars;
