/* @ngInject */
export default function NvNewTimelineItem(
) {
  return {
    scope: {
      item: '=',
      decayEnabled: '=?',
      gamificationEnabled: '=?',
      collapseTimelineFunction: '&?',
      cardView: '=?',
      showProgress: '=',
      customState: '=?',

    },
    controller: function ctrl($scope, $element, $attrs) {
'ngInject';
      const vm = this;
    },
    controllerAs: 'vm',
    bindToController: true,
    template: '<div ng-include="vm.item.newTemplateUrl"></div>',
  };
}
