import { every } from 'underscore';

// TODO: move to different file
const EXEMPT_URLS = ['s3.amazonaws.com'];
const EXEMPT_PATTERNS = ['/origami/languages/', '/assets/.+/languages/'];

const getLocation = function (href) {
  const anchor = document.createElement('a');
  anchor.href = href;
  return anchor;
};

/* @ngInject */
export function shouldChangeCase(url) {
  let noMatch = every(EXEMPT_URLS, (exemptUrl) => {
    const parsedUrl = getLocation(url);
    if (parsedUrl.hostname.toLocaleLowerCase().includes(exemptUrl)) {
      return false;
    }

    return true;
  });

  if (noMatch) {
    noMatch = every(EXEMPT_PATTERNS, (exemptPattern) => {
      if (url.match(exemptPattern)) {
        return false;
      }

      return true;
    });
  }

  return !!noMatch;
}

/* @ngInject */
export default function ChangeCaseInterceptor(
  $q,
  humps,
) {
  return {
    request(config) {
      if (shouldChangeCase(config.url)) {
        if (config.params) {
          config.params = humps.decamelizeKeys(config.params);
        }

        if (config.data) {
          config.data = humps.decamelizeKeys(config.data);
        }
      }

      return config;
    },
    response(response) {
      if (shouldChangeCase(response.config.url)) {
        if (response.data) {
          response.data = humps.camelizeKeys(response.data);
        }
      }

      return response;
    },
    responseError(rejection) {
      if (shouldChangeCase(rejection.config.url)) {
        if (rejection.data) {
          rejection.data = humps.camelizeKeys(rejection.data);
        }
      }

      return $q.reject(rejection);
    },
  };
}
