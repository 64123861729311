/* @ngInject */
export default function NvSetFocus(
  $timeout,
  $parse,
  nvUtil,
) {
  return {
    restrict: 'A',
    link($scope, $element, $attrs) {
      if ($parse($attrs.nvSetFocus)($scope)) {
        $timeout(() => {
          $element.focus();
        });
      }
    },
  };
}
