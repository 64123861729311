/* @ngInject */
export default function ExercisesManager(

  _,
  $state,
  $stateParams,

  CurrentUserManager,
  CurrentPermissionsManager,
  CurrentCourseManager,

  ExerciseModel,
  ExercisesResources,
) {
  let currentExerciseRequest;
  const manager = {
    // functions
    getExercise,
    getAllExercisesForCourse,
    updateExercisesWithHardDeadline,

    // data:
    currentExercise: null,

    canViewLockedContent: CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isTeachingAssistant()
       || CurrentPermissionsManager.isCourseBuilder() || CurrentPermissionsManager.isConfigAndRegistrationRole(),
    canEditPrequisites: CurrentPermissionsManager.isCourseBuilder(),
  };


  function getExercise(catalogId, id) {
    if (currentExerciseRequest) {
      currentExerciseRequest.$cancelRequest();
    }

    const requestCourse = CurrentUserManager.coursesHashByCatalogId[catalogId] ? CurrentUserManager.coursesHashByCatalogId[catalogId] : CurrentCourseManager.course;

    currentExerciseRequest = ExerciseModel.get(catalogId, id, CurrentUserManager.user.translationPreferenceLanguage);

    currentExerciseRequest.$promise.then((exercise) => {
      manager.shouldReload = false;
      manager.currentExercise = exercise;
    }, () => {
      if (!CurrentUserManager.hasLoggedIn()) {
        $state.go('users.authentications.sign-in', { catalogId: $stateParams.catalogId });
      } else if (CurrentUserManager.hasEnrolledInCourse(requestCourse)) {
        $state.go('course-home', { catalogId: $stateParams.catalogId });
      } else {
        $state.go('course-flyer', { catalogId: $stateParams.catalogId });
      }
    });

    return currentExerciseRequest.$promise;
  }

  function getAllExercisesForCourse(catalogId) {
    return ExerciseModel.getAllExercisesForCourse(catalogId).then((exerciseList) => exerciseList);
  }

  function updateExercisesWithHardDeadline(catalogId, params) {
    return ExercisesResources.updateExercisesWithHardDeadline({ catalogId }, params).$promise;
  }

  return manager;
}
