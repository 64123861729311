/* @ngInject */
export default function UploadLiveSessionRecordingModalCtrl(

  $q,
  $uibModalInstance,
  AlertMessages,
  S3NameSpaces,
  S3UploadFactory,
  _,
  config,
  manualUploadRecording,
  setEditMenu,
  setRecording,
  onSuccess,
  onFileLoaded,
  onFileSelected,
  onCloseModal,
) {
  class UploadLiveSessionRecordingModalController {
    constructor() {
      this.recording = null;
      this.manualUploadRecording = manualUploadRecording;
      this.setEditMenu = setEditMenu;
      this.setRecording = setRecording;
      this.allowedFileTypes = config.files.allVideoExtensions;
      this.onSuccess = onSuccess;
      this.onFileLoaded = onFileLoaded;
      this.onFileSelected = onFileSelected;
      this.onCloseModal = onCloseModal;
    }

    uploadFiles([file], [invalidFile]) {
      if (invalidFile?.$error === 'maxSize') {
        AlertMessages.error('FORM.OOPS', 'FILE_UPLOAD.SIZE_EXCEEDED_ALERT', {}, { sizeLimit: invalidFile.$errorParam });
      } else if (file) {
        $uibModalInstance.dismiss();

        const deferred = $q.defer();
        const uploadToS3Object = S3UploadFactory.uploadToS3(file, S3NameSpaces.ATTACHMENTS, true);
        this.setRecording?.(uploadToS3Object);
        this.onFileSelected?.(uploadToS3Object);

        uploadToS3Object.promise.then((resp) => {
          const s3FileData = resp.config.data.file;

          Object.assign(uploadToS3Object, {
            fileName: s3FileData.name,
            fileSize: s3FileData.size,
            fileType: s3FileData.type,
            uniqueId: s3FileData.uniqueId,
          });

          deferred.resolve(uploadToS3Object);
          this.onFileLoaded?.(uploadToS3Object);

          this.manualUploadRecording(uploadToS3Object.uniqueId, uploadToS3Object.fileName).then(() => {
            this.setEditMenu();
            this.onSuccess?.(uploadToS3Object);
          });
        }, () => {
          deferred.resolve();
        });
      }
    }

    closeModal() {
      $uibModalInstance.dismiss();
      this.onCloseModal?.();
    }
  }

  return new UploadLiveSessionRecordingModalController();
}
