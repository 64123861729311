import { RootState } from 'redux/schemas';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import {
  OrgLevelProfileSettings,
  FormOrgLevelProfileSettings,
} from 'redux/schemas/models/org-level-profile-settings';

// eslint-disable-next-line import/prefer-default-export
export const getOrgLevelProfileSettings = (
  state: RootState,
): OrgLevelProfileSettings => {
  const currentInstitution = getCurrentInstitution(state);

  return currentInstitution.profileSettings;
};

export const getFormOrgLevelProfileSettings = (
  state: RootState,
): FormOrgLevelProfileSettings => {
  const settings = getOrgLevelProfileSettings(state);

  return {
    ...settings,
    orgLevel: settings.orgLevel.map((orgLevelField) => ({
      ...orgLevelField,
      isNew: false,
      selectOptions: orgLevelField.selectOptions.join(', '),
    })),
  };
};
