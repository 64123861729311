import { css } from '@emotion/react';
import React, { useContext } from 'react';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';

import ClickableContainer from 'components/clickable-container';
import { AttachmentBookmarkComponent, CourseObjectBookmark } from 'redux/schemas/models/bookmark';
import { doubleSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { config } from '../../../../config/pendo.config.json';


type AttachmentSnippetProps = {
  bookmark: CourseObjectBookmark
};

const AttachmentSnippet = ({
  bookmark: { catalogId, lecturePageId, lectureComponentId, component },
}: AttachmentSnippetProps) => {
  const styles = css`
    &.attachment-snippet {
      padding: ${standardSpacing}px ${doubleSpacing}px;
      flex-direction: column;

      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .subtitle {
        display: flex;

        .lecture-page-name, .course-name {
          max-width: 50%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .middot {
          margin-left: ${quarterSpacing}px;
          margin-right: ${quarterSpacing}px;
        }
      }
    }
  `;

  const { $state, FlyoutModalManager } = useContext(AngularServicesContext);

  const {
    file,
    lecturePageName,
    courseName,
    expired = true,
  } = component as AttachmentBookmarkComponent;

  const onClickSnippet = () => {
    if (expired) { return; }

    FlyoutModalManager?.closeFlyout();
    $state.go('lecture-page', { catalogId, id: lecturePageId, lectureActivityId: lectureComponentId });
  };

  return (
    <ClickableContainer className='attachment-snippet' css={styles} onClick={onClickSnippet} data-qa={config.pendo.bookmarks.attachmentSnippet}>
      <div className='name text-body font-weight-bold'>{file.name}</div>
      <div className='subtitle text-gray-2 text-small'>
        <div className='lecture-page-name'>{lecturePageName}</div>
        <div className='middot'>&middot;</div>
        <div className='course-name'>{courseName}</div>
      </div>
    </ClickableContainer>
  );
};

export default AttachmentSnippet;
