
import { AngularServicesContext } from 'react-app';
import { useRef, useEffect, useContext } from 'react';
import { embedAngularTemplate } from 'shared/embed-angular';

type StateProps = {
  teamId: number,
  postId?: number,
  commentId?: number,
  replyId?: number,
};

const RecentPostsModal = (props: StateProps) => {
  const postsRef = useRef(null);
  const angularServices = useContext(AngularServicesContext);

  useEffect(() => {
    angularServices.$scope.teamId = props.teamId;
    angularServices.$scope.postId = props.postId;
    angularServices.$scope.commentId = props.commentId;
    angularServices.$scope.replyId = props.replyId;
    embedAngularTemplate('team_workspace/templates/team-recent-posts.html', postsRef, angularServices);
  }, []);

  return (
    <div>
      <div ref={postsRef} />
    </div>
  );
};

export default RecentPostsModal;
