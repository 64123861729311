import React, { useCallback, useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { find, isEmpty } from 'underscore';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { AngularServicesContext } from 'react-app';
import { useTimelineService } from 'timelines/services/react-timeline-service';
import {
  setFilteredComments, getComments, updateComment, deleteComment, clearNewNotification,
} from 'redux/actions/video-practice';
import { DeleteCommentParams, Post, UpdateCommentParams } from 'redux/schemas/api/video-practice';
import { getBadges, getPracticeFeedbackActivity, getSubmission, getSubmissionComments } from 'redux/selectors/video-practice';

import ClickableContainer from 'components/clickable-container';
import LoadingWrapper, { LoaderType } from 'shared/components/loading-wrapper';
import { INITIAL_COMMENTS_PER_PAGE, PracticeSubmissionContext, useFilterComments } from './shared/utils';
import PracticeCommentRow from './shared/submission/practice-comment-row';

const styles = css`
  .view-more {
    cursor: pointer;
  }
`;

const ViewMore = (props: {
  commentsLeft: number,
  onViewMore: () => void,
  isLoaded: boolean
}) => (
  <React.Fragment>
    {props.commentsLeft > 0 && (
    <ClickableContainer
      className='view-more text-regular text-primary my-4'
      onClick={props.onViewMore}
    >
      {t.PRACTICE_ROOM.COMMENTS.VIEW_MORE(props.commentsLeft)}
    </ClickableContainer>
    )}
    <LoadingWrapper
      isLoaded={props.isLoaded}
      loaderType={LoaderType.PLACEHOLDER}
    >
      <div />
    </LoadingWrapper>
  </React.Fragment>
);

const CommentsTab = () => {
  const [{
    submissionId,
    lectureComponentId,
    isMyPractice,
    isPracticeFeedback,
  }] = useContext(PracticeSubmissionContext);
  const { TimelinesManager, $scope } = useContext(AngularServicesContext);

  const {
    submissionComments, numComments,
  } = useSelector((state) => getSubmission(state, submissionId));
  const {
    commentsOnceLoaded,
  } = useSelector((state) => state.app.videoPracticeSubmissions[submissionId]) ?? {};
  const filteredComments = useSelector((state) => state.app.practiceRoom.filteredComments?.[submissionId]);
  const { newNotification } = useSelector((state) => state.app.practiceRoom.params);

  const practiceFeedbackCriteriaId = useSelector(state => state.app.videoPracticeFeedback?.practiceFeedbackCriteriaId);
  const practiceFeedbackActivity = useSelector(state => getPracticeFeedbackActivity(
    state, { lectureComponentId },
  ));
  const currentLectureId = useSelector(state => state.app.lecturePage?.currentLectureId);
  const badges = useSelector((state) => getBadges(state, submissionId));
  const notificationComment = useSelector((state) => state.models.comments?.[newNotification?.commentId]) ?? null;
  const comments = useSelector((state) => getSubmissionComments(state, submissionComments));

  const [viewMore, setViewMore] = useState(false);
  const [moreCommentsLoaded, setMoreCommentsLoaded] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [isNotifiedCommentsSet, setIsNotifiedComments] = useState(false);

  const totalComments = submissionComments?.length;
  // Slice the last five comments to display recent five comments
  const slicingIndex = (totalComments - INITIAL_COMMENTS_PER_PAGE) >= 0
    ? (totalComments - INITIAL_COMMENTS_PER_PAGE) : 0;
  let displayComments = viewMore ? submissionComments
    : submissionComments?.slice(
      slicingIndex,
      totalComments,
    ); // To display the recent comments initially
  const commentsLeft = numComments - displayComments?.length;
  const isCommentsFiltered = !isEmpty(filteredComments);
  if (isCommentsFiltered) {
    displayComments = submissionComments?.filter((commentId) => filteredComments.includes(commentId));
  }
  const nextPage = pageNo + 1;

  const dispatch = useAppDispatch();
  const timelineService = useTimelineService();
  const { filterCommentsOfTime } = useFilterComments();

  useEffect(() => {
    if (!isNotifiedCommentsSet
      && comments?.length
      && newNotification?.submissionId
      && newNotification?.commentId
      && notificationComment
    ) {
      setIsNotifiedComments(true);

      dispatch(setFilteredComments({
        submissionId: newNotification.submissionId,
        filteredComments: [notificationComment.id],
      }));
    }
  }, [dispatch, comments, newNotification, notificationComment, isNotifiedCommentsSet, setIsNotifiedComments]);

  const displayMoreComments = useCallback(() => {
    if (!viewMore && pageNo === 1) {
      setViewMore(true);
    } else {
      setMoreCommentsLoaded(false);
      dispatch(getComments({ submissionId, page: nextPage })).then(() => {
        setPageNo(pageNo + 1);
        setMoreCommentsLoaded(true);
      });
    }
  }, [dispatch, nextPage, pageNo, submissionId, viewMore]);

  const clearFilterComments = useCallback(() => {
    dispatch(setFilteredComments({
      submissionId,
      filteredComments: [],
    }));
    dispatch(getComments({ submissionId, page: 1 }));
  }, [dispatch, submissionId]);

  const onUpdateComment = useCallback(async (commentId: number, comment: Post) => {
    const data: UpdateCommentParams = { commentId, post: comment };
    if (practiceFeedbackCriteriaId) {
      data.practiceFeedbackCriteriaId = practiceFeedbackCriteriaId;
    }
    const { payload } = await dispatch(updateComment(data));
    if (!payload) {
      return false;
    }
    return true;
  }, [dispatch, practiceFeedbackCriteriaId]);

  const onDeleteComment = useCallback(async (commentId: number, isUserFirstReview?: boolean) => {
    const params: DeleteCommentParams = { commentId, submissionId, isUserFirstReview };

    if (practiceFeedbackCriteriaId) {
      params.practiceFeedbackCriteriaId = practiceFeedbackCriteriaId;
    }
    const { payload } = await dispatch(deleteComment(params));
    if (newNotification?.commentId === commentId) {
      clearFilterComments();
      dispatch(clearNewNotification({}));
    }

    if (isUserFirstReview && payload?.publicFeedback) {
      timelineService.updateTimeline(practiceFeedbackActivity?.lecturePageId ?? currentLectureId);
      TimelinesManager.updateComponentPointsAndProgress(
        practiceFeedbackActivity?.lecturePageId ?? currentLectureId,
        'practice_feedback_criteria',
        practiceFeedbackActivity?.id ?? payload.publicFeedback.id,
        payload.publicFeedback.pointsReceived,
        payload.leaderboardPoints,
        payload.publicFeedback.progress,
      );
      // To trigger a render in the angular component so that points update immediately
      $scope.$apply();
    }
  }, [dispatch, practiceFeedbackCriteriaId, isPracticeFeedback, submissionId]);

  const onTimestampClick = useCallback((commentId: number) => {
    const badge = find(badges, (b) => b.meta.extras.commentIds.includes(commentId));
    if (badge) {
      filterCommentsOfTime(badge);
    }
  }, [badges, filterCommentsOfTime]);

  if (!totalComments) {
    return (
      <LoadingWrapper
        isLoaded={commentsOnceLoaded}
        loaderType={LoaderType.PLACEHOLDER}
      >
        <div
          className='d-flex justify-content-center text-medium gray-2 mt-5'
        >
          {isMyPractice
            ? t.PRACTICE_ROOM.COMMENTS.NO_COMMENTS.MY_PRACTICE()
            : t.PRACTICE_ROOM.COMMENTS.NO_COMMENTS.DEFAULT()}
        </div>
      </LoadingWrapper>
    );
  }

  return (
    <LoadingWrapper
      isLoaded={commentsOnceLoaded}
      loaderType={LoaderType.PLACEHOLDER}
    >
      {totalComments > 0 && (
        <React.Fragment css={styles}>
          {isCommentsFiltered && totalComments > filteredComments.length ? (
            (
              <ClickableContainer
                className='view-more text-regular text-primary my-4'
                onClick={clearFilterComments}
              >
                {t.PRACTICE_ROOM.COMMENTS.ALL_COMMENTS()}
              </ClickableContainer>
            )
          ) : (
            <ViewMore
              commentsLeft={commentsLeft}
              onViewMore={displayMoreComments}
              isLoaded={moreCommentsLoaded}
            />
          )}
          {displayComments.map(commentId => (
            <PracticeCommentRow
              key={commentId}
              commentId={commentId}
              onDelete={(isUserFirstReview) => onDeleteComment(commentId, isUserFirstReview)}
              onTimestampClick={() => onTimestampClick(commentId)}
              onUpdate={(data) => onUpdateComment(commentId, data)}
            />
          ))}
        </React.Fragment>
      )}
    </LoadingWrapper>
  );
};

export default CommentsTab;
