import axios from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateCollectionRequest, CreateCollectionResponse, CreateFolderRequest,
  CreateLecturePageRequest, FetchFolderResponse, FetchCollectionResponse, UpdateFolderRequest, DeleteFolderRequest,
} from 'redux/schemas/api/collections';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import t from 'react-translate';
import { Result } from 'redux/schemas/api';
import { InfiniteTableLoadingParams } from 'redux/create-action-creators';
import { CollectionCourse, CollectionFolder } from 'redux/schemas/models/collections';
import { CollectionLecturePage } from 'redux/schemas/models/lecture-page';
import { isEmpty } from 'underscore';
import { addAlertMessage } from './alert-messages';
import { makeQueryParamString, makeQueryParams } from './helpers';

export const setFolderExpandedStatus = createAction<{ folderId: number, value: boolean }>('SET_FOLDER_EXPANDED_STATUS');
export const setHasMoreInCollectionSearch = createAction<boolean>('SET_HAS_MORE_IN_COLLECTION_SEARCH');
export const setHighlightedCollectionId = createAction<string>('SET_HIGHLIGHTED_COLLECTION_ID');
export const setHighlightedLessonId = createAction<number>('SET_HIGHLIGHTED_LESSON_ID');
export const setNewFolderId = createAction<number>('SET_NEW_FOLDER_ID');
export const setScrollableCollectionLessonId = createAction<number>('SET_NEW_COLLECTION_LESSON_ID');
export const unsetCollectionCanLinkAndConvert = createAction('UNSET_COLLECTION_CAN_LINK_AND_CONVERT');

export const fetchCollectionsList = createAsyncThunk(
  'FETCH_COLLECTIONS_LIST',
  async (params: { includeEmpty?: boolean, includeBlank?: boolean, editableOnly?: boolean } & InfiniteTableLoadingParams<CollectionCourse>, { dispatch, rejectWithValue }) => {
    const queryParams = makeQueryParams({}, params.pageSize, params.pageIndex);
    let paramString = makeQueryParamString({ ...queryParams, includeEmpty: params.includeEmpty, includeBlank: params.includeBlank, editableOnly: params.editableOnly });

    if (!isEmpty(params.sorting)) {
      paramString += `&sort_by=${params.sorting.join(',')}`;
    }

    return (axios.get<Result<FetchCollectionResponse>>(`/content_management/collections.json?${paramString}`)
      .then(response => ({
        response: response.data.result.collections,
        totalCount: response.data.result.collectionsCount,
      }))
      .catch((e) => {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.ERROR_SOMETHING_WRONG(),
        }));

        return rejectWithValue(e.response.data.error);
      })
    );
  },
);

export const createCollection = createAsyncThunk<CreateCollectionResponse, CreateCollectionRequest>(
  'CREATE_COLLECTION',
  async (params: CreateCollectionRequest, { dispatch, rejectWithValue }) => {
    const payload = {
      collection: { ...params },
    };

    return axios.post('/content_management/collections.json', payload).then(
      (response) => response.data.result,
    ).catch((e) => {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR_SOMETHING_WRONG(),
      }));

      return rejectWithValue(e.response.data.error);
    });
  },
);

export const fetchCollection = createAsyncThunk<any, string>(
  'FETCH_COLLECTION',
  async (id) => {
    const response = await axios.get(`/content_management/collections/${id}.json`);
    return response.data.result;
  },
);

export const createFolder = createAsyncThunk<CollectionFolder, CreateFolderRequest>(
  'CREATE_COLLECTION_FOLDER',
  async (params: CreateFolderRequest, { dispatch, rejectWithValue }) => {
    const payload = {
      folder: { ...params },
    };

    return axios.post('/content_management/folders.json', payload).then(
      (response) => response.data.result,
    ).catch((e) => {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR_SOMETHING_WRONG(),
      }));

      return rejectWithValue(e.response.data.error);
    });
  },
);

export const fetchFolder = createAsyncThunk(
  'FETCH_COLLECTION_FOLDER',
  async (params: { folderId: number } & InfiniteTableLoadingParams<CollectionFolder>, { dispatch, rejectWithValue }) => (
    axios.get(`/content_management/folders/${params.folderId}.json?`))
    .then(response => ({
      response: response.data.result.lecturePages,
      folder: response.data.result,
    }))
    .catch((e) => {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR_SOMETHING_WRONG(),
      }));

      return rejectWithValue(e.response.data.error);
    }),
);

export const updateFolder = createAsyncThunk<CollectionFolder, UpdateFolderRequest>(
  'UPDATE_COLLECTION_FOLDER',
  async (params: UpdateFolderRequest, { dispatch, rejectWithValue }) => {
    const payload = {
      folder: { ...params },
    };

    return axios.put(`/content_management/folders/${params.folderId}.json`, payload).then(
      (response) => response.data.result,
    ).catch((e) => {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR_SOMETHING_WRONG(),
      }));

      return rejectWithValue(e.response.data.error);
    });
  },
);

export const deleteFolder = createAsyncThunk<CollectionFolder, DeleteFolderRequest>(
  'DELETE_COLLECTION_FOLDER',
  async (params: DeleteFolderRequest, { dispatch, rejectWithValue }) => {
    const payload = {
      folder: { ...params },
    };

    return axios.delete(`/content_management/folders/${params.folderId}.json`).then(
      (response) => response.data.result,
    ).catch((e) => {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR_SOMETHING_WRONG(),
      }));

      return rejectWithValue(e.response.data.error);
    });
  },
);

export const createLecturePage = createAsyncThunk<CollectionLecturePage, CreateLecturePageRequest>(
  'CREATE_COLLECTION_LECTURE_PAGE',
  async (params: CreateLecturePageRequest, { dispatch, rejectWithValue }) => {
    const payload = {
      lecturePage: { ...params },
    };

    return axios.post('/content_management/lecture_pages.json', payload).then(
      (response) => response.data.result,
    ).catch((e) => {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR_SOMETHING_WRONG(),
      }));

      return rejectWithValue(e.response.data.error);
    });
  },
);

export const getCollectionLecturePage = createAsyncThunk(
  'FETCH_COLLECTION_LECTURE_PAGE',
  async (params: { lecturePageId: number }, { dispatch, rejectWithValue }) => (
    axios.get(`/content_management/lecture_pages/${params.lecturePageId}.json`)
      .then(response => response.data.result)
      .catch((e) => {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.ERROR_SOMETHING_WRONG(),
        }));

        return rejectWithValue(e.response.data.error);
      })),
);

export const syncLecturePage = createAsyncThunk(
  'SYNC_LECTURE_PAGE',
  async (params: { lecturePageId: number }, { dispatch, rejectWithValue }) => axios.post(`content_management/lecture_pages/${params.lecturePageId}/sync.json`, {}).then(
    (response) => response.data.result,
  ).catch((e) => {
    dispatch(addAlertMessage({
      type: AlertMessageType.ERROR,
      header: t.FORM.ERROR_SOMETHING_WRONG(),
    }));

    return rejectWithValue(e.response.data.error);
  }),
);


export const editCollection = createAsyncThunk<CreateCollectionResponse, CreateCollectionRequest>(
  'EDIT_COLLECTION',
  async (params: CreateCollectionRequest, { dispatch, rejectWithValue }) => {
    const payload = {
      collection: { ...params },
    };

    return axios.put(`/content_management/collections/${params.collectionId}.json`, payload).then(
      (response) => response.data.result,
    ).catch((e) => {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR_SOMETHING_WRONG(),
      }));

      return rejectWithValue(e.response.data.error);
    });
  },
);
export const deleteCollection = createAsyncThunk(
  'DELETE_COLLECTION',
  async (params: { id: number, catalogId: string }, { dispatch, rejectWithValue }) => (
    axios.delete(`/content_management/collections/${params.id}.json`)
      .then(response => params.catalogId)
      .catch((e) => {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.ERROR_SOMETHING_WRONG(),
        }));

        return rejectWithValue(e.response.data.error);
      })),
);
export const canConvertToCollectionLecture = createAsyncThunk(
  'CAN_CONVERT_TO_COLLECTION_LECTURE',
  async (params: { id: number }, { dispatch, rejectWithValue }) => (
    axios.get(`/content_management/lecture_pages/${params.id}/can_convert.json`)
      .then((response) => (response.data))
      .catch((e) => {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.ERROR_SOMETHING_WRONG(),
        }));

        return rejectWithValue(e.response.data.error);
      })),
);

export const getCanLinkCollectionLesson = createAsyncThunk(
  'GET_CAN_LINK_COLLECTION_LESSON',
  async (params, { dispatch, rejectWithValue }) => (
    axios.get('/content_management/collections/can_link.json')
      .then((response) => (response.data))
      .catch((e) => {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.ERROR_SOMETHING_WRONG(),
        }));

        return rejectWithValue(e.response.data.error);
      })),
);

export const getCanConvertToCollectionLesson = createAsyncThunk(
  'GET_CAN_CONVERT_TO_COLLECTION_LESSON',
  async (params, { dispatch, rejectWithValue }) => (
    axios.get('/content_management/collections/can_convert.json')
      .then((response) => (response.data))
      .catch((e) => {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.ERROR_SOMETHING_WRONG(),
        }));

        return rejectWithValue(e.response.data.error);
      })),
);

export const reorderFolder = createAsyncThunk(
  'REORDER_FOLDER',
  async (params: { folders: number[], catalogId: string, folderId: number, addedIndex: number }, { dispatch, rejectWithValue }) => {
    const payload = {
      index: params.addedIndex,
    };
    return (
      axios.put(`/content_management/folders/${params.folderId}/reorder_folder.json`, payload)
        .then((response) => (response.data))
        .catch((e) => {
          dispatch(addAlertMessage({
            type: AlertMessageType.ERROR,
            header: t.FORM.ERROR_SOMETHING_WRONG(),
          }));

          return rejectWithValue(e.response.data.error);
        }));
  },
);

export const reorderLesson = createAsyncThunk(
  'REORDER_LESSON',
  async (params: { destinationFolderId: number, sourceFolderId: number, lessonId: number, addedIndex: number, removedIndex: number }, { dispatch, rejectWithValue }) => {
    const payload = {
      lectureSectionId: params.destinationFolderId,
      lecturePage: {
        index: params.addedIndex,
      },
    };
    return (
      axios.put(`/content_management/lecture_pages/${params.lessonId}/reorder_and_lesson_transfer.json`, payload)
        .then((response) => (response.data))
        .catch((e) => {
          dispatch(addAlertMessage({
            type: AlertMessageType.ERROR,
            header: t.FORM.ERROR_SOMETHING_WRONG(),
          }));

          return rejectWithValue(e.response.data.error);
        }));
  },
);

