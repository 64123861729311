/* @ngInject */
export default function TeamProfileCtrl(
  $element,
  $q,
  $rootScope,
  $scope,
  $state,
  $timeout,
  $uibModal,
  AlertMessages,
  ConfirmationOverlays,
  CurrentUserManager,
  PageLevelManager,
  TeamManager,
  TeamWorkspaceManager,
  _,
  config,
) {
  const vm = this;

  vm.config = config;
  $scope.ProfileCtrl = vm;

  vm.manager = TeamManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.course = CurrentUserManager.getCurrentCourse();
  vm.$state = $state;

  vm.showMoreMembers = showMoreMembers;
  vm.focusFirstMember = focusFirstMember;
  vm.translateValuesForTeam = translateValuesForTeam;

  vm.initialize = initialize;
  vm.teamProfileLoaded = false;

  initialize();

  $rootScope.$on('reinitialize-team-profile-controller', () => {
    initialize();
  });

  $scope.$on('$destroy', () => {
    /**
     * When destroy is called, state param teamId will be changed.
     * So Only updating if both  team-workspace and teamManager team id matched
     */
    if (TeamWorkspaceManager.currentTeam?.id === TeamManager.currentTeam.id) {
      TeamWorkspaceManager.currentTeam.copyAttributes(TeamManager.currentTeam);
    }
  });

  function initialize() {
    vm.teamProfileLoaded = false;
    vm.shownSubmissions = { num: 3, defaultNum: 3 };
    vm.defaultNumShownMembers = 6;
    vm.numShownMembers = vm.defaultNumShownMembers;
    vm.editInfo = { isEditable: false };
    const promises = [];

    promises.push(CurrentUserManager.requestAndUpdateCourse($state.params.catalogId));
    promises.push(TeamManager.requestTeamForProfile($state.params.catalogId, $state.params.teamId));
    return $q.all(promises).then(() => {
      vm.teamProfileLoaded = true;
      if (TeamWorkspaceManager.currentTeam?.id === $state.params.teamId) {
        TeamWorkspaceManager.currentTeam.copyAttributes(TeamManager.currentTeam);
      }

      vm.shownSubmissions.num = Math.min(vm.shownSubmissions.num, vm.manager.currentTeam.submissionsViewableByClass.length);
      vm.numShownMembers = Math.min(vm.numShownMembers, vm.manager.currentTeam.numApprovedMembers);

      if (vm.manager.currentTeam.currentAccountTeamMember) {
        if (vm.manager.currentTeam.teamSet.isTeam && vm.manager.currentTeam.currentAccountTeamMember.approved) {
          vm.editInfo.isEditable = true;
        } else if (!vm.manager.currentTeam.teamSet.isTeam && vm.manager.currentTeam.currentAccountTeamMember.isAdmin) {
          vm.editInfo.isEditable = true;
        }
      }
      if ($state.params.contact) {
        sendMessage();
      }
    }, (error) => {
      ConfirmationOverlays.openConfirmationModal('team_workspace/templates/team-profile-not-exist-error-overlay.html')
        .result.then(() => {
          PageLevelManager.callL4CloseCallbacks();
        });
    });
  }

  function focusFirstMember() {
    $timeout(() => {
      $element.find('.focusable-member').first().focus();
    });
  }

  function showMoreMembers() {
    if (vm.manager.currentTeam.teamMembers.length < vm.manager.currentTeam.numApprovedMembers) {
      vm.manager.currentTeam.requestMembers($state.params.catalogId).then(() => {
        vm.numShownMembers = vm.manager.currentTeam.teamMembers.length;
        vm.focusFirstMember();
      });
    } else {
      vm.numShownMembers = vm.manager.currentTeam.teamMembers.length;
      vm.focusFirstMember();
    }
  }

  function translateValuesForTeam() {
    return {
      teamName: vm.manager.currentTeam.name,
      teamNameForCurrentUser: (vm.manager.currentTeam.teamSet.currentAccountTeam ? vm.manager.currentTeam.teamSet.currentAccountTeam.name : ''),
      wordForTeamSingularizedTitleized: vm.manager.currentTeam.teamSet.name.singularizedTitleized,
      wordForTeamPluralizedTitleized: vm.manager.currentTeam.teamSet.name.pluralizedTitleized,
      wordForTeamDowncasedSingularized: vm.manager.currentTeam.teamSet.name.downcasedSingularized,
      wordForTeamDowncasedPluralized: vm.manager.currentTeam.teamSet.name.downcasedPluralized,
    };
  }

  function sendMessage() {
    const message = {};

    vm.manager.currentTeam.requestMembers($state.params.catalogId).then(() => {
      $uibModal.open({
        templateUrl: 'teams/templates/profile/send-message-to-team-modal.html',
        controller: 'AttachModalResolvesToVmCtrl as vm',
        resolve: {
          vmResolves() {
            return {
              course: vm.course,
              team: vm.manager.currentTeam,
              message,
              numRecipientsShown: 6,
            };
          },
        },
      }).result.then(() => {
        vm.manager.currentTeam.adminSendMessageToTeam($state.params.catalogId, message).then(() => {
          AlertMessages.success('', 'TEAMS.PROFILE.MESSAGE.MESSAGE_SENT', null, { wordForTeam: vm.manager.currentTeam.teamSet.name.downcasedSingularized });
        });
      });
    });
  }
}
