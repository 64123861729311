import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';

// Redux
import { useSelector } from 'react-redux';
import { CollectionCourse, CollectionFolder } from 'redux/schemas/models/collections';
import { useAppDispatch } from 'redux/store';
import { fetchCollection, fetchFolder } from 'redux/actions/collections';
import { getCourse } from 'redux/selectors/course';
import { getCollectionFolder } from 'redux/selectors/collections';
import { RootState } from 'redux/schemas';
import { getLecturePage } from 'redux/selectors/timeline';

// Styles
import { gray5, primary } from 'styles/global_defaults/colors';

// Components
import NvIcon from 'shared/components/nv-icon';
import ClickableContainer from 'components/clickable-container';
import NvRouterLink from 'nv-router/nv-link';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import LoadingPlaceholder, { LoaderType } from 'shared/components/loading-placeholder';
import { linkTolecturePage } from '../lecture-page-routes';
import { config } from '../../../../config/config.json';

export type Props = {
  catalogId: string
};

const CollectionOutlineNavigation = (props: Props) => {
  const { catalogId } = props;
  const collection: CollectionCourse = useSelector((state) => getCourse(state, catalogId));
  const { folders } = collection;

  const [isCollectionLoading, setIsCollectionLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCollection(String(collection.id))).then(() => {
      setIsCollectionLoading(false);
    });
  }, [collection.id, dispatch]);

  return (
    <div
      className='d-flex flex-column w-100'
    >
      {(isCollectionLoading && (!folders || folders === null)) ? (
        <LoadingPlaceholder loaderType={LoaderType.HALF} className='p-2' />
      ) : (
        folders?.length > 0 && folders.map((folderId) => (
          <OutlineFolder folderId={folderId} key={folderId} />
        )))}
    </div>
  );
};

const OutlineFolder = (props: { folderId: number }) => {
  const styles = css`
    :hover {
      color: ${primary};
      cursor: pointer;
    }

    .subsection-title {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `;
  const { folderId } = props;
  const params = useLecturePageParams();
  const dispatch = useAppDispatch();

  const currentfolder: CollectionFolder = useSelector((state: RootState) => getCollectionFolder(state, folderId));
  const lecturePage = useSelector((state) => getLecturePage(state, params.lecturePageId));

  const { lecturePages } = currentfolder;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isLoadingFolder, setIsLoadingFolder] = useState<boolean>(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isExpanded) {
      setIsLoadingFolder(true);

      dispatch(fetchFolder({ folderId, pageIndex: 1 })).then(() => {
        setIsLoadingFolder(false);
      });
    }
  }, [dispatch, folderId, isExpanded, setIsLoadingFolder]);

  useEffect(() => {
    if (folderId === lecturePage?.folderId) {
      setIsExpanded(true);
    }
  }, [folderId, lecturePage?.folderId]);

  return (
    <div className='d-flex flex-column'>
      <ClickableContainer
        css={styles}
        className='d-flex align-items-center course-title-xs pl-4 pt-2 pb-2 border-bottom border-gray-6'
        onClick={toggleExpanded}
        data-qa={!isExpanded ? config.pendo.contentManagement.expandFolder : config.pendo.contentManagement.contractFolder}
        data-qa-id={!isExpanded ? `${config.pendo.contentManagement.expandFolder}_${currentfolder?.id}` : `${config.pendo.contentManagement.contractFolder}_${currentfolder?.id}`}
      >
        <div className='d-flex align-items-baseline w-100 text-truncate'>
          <span className='subsection-title pr-1'>
            {currentfolder.title}
          </span>
        </div>
        <div className='ml-auto pr-2'>
          <NvIcon
            icon={isExpanded ? 'arrow-up' : 'arrow-down'}
            size='xss'
          />
        </div>
      </ClickableContainer>
      {isExpanded && (
        (isLoadingFolder && (!lecturePages || lecturePages === null)) ? (
          <LoadingPlaceholder loaderType={LoaderType.HALF} className='p-2' />
        ) : (
          lecturePages?.length > 0 && lecturePages.map((lecturePageId) => (
            <OutlineLesson lecturePageId={lecturePageId} key={lecturePageId} />
          ))))}
    </div>
  );
};

const OutlineLesson = (props: { lecturePageId: number }) => {
  const styles = css`
    :hover {
      color: ${primary};
      cursor: pointer;
    }

    & {
      border-bottom: 1px dashed ${gray5};
    }
  `;

  const { lecturePageId } = props;
  const lecturePage = useSelector((state) => getLecturePage(state, lecturePageId));
  const params = useLecturePageParams();

  return (
    <NvRouterLink
      to={linkTolecturePage({
        ...params,
        lecturePageId,
      })}
      data-qa={config.pendo.contentManagement.openCollectionLesson}
      data-qa-id={`${config.pendo.contentManagement.openCollectionLesson}-${lecturePageId}`}
      id={`timeline-lecture-page-${lecturePage.id}`}
    >
      <div
        className={`lesson-item d-flex align-items-center w-100 ${params.lecturePageId === lecturePageId && 'bg-gray-6'}`}
        css={styles}
      >
        <NvIcon
          icon='read'
          size='smallest'
          className='text-gray-3 p-4'
        />
        <div className='text-regular pr-4'>{lecturePage.title}</div>
      </div>
    </NvRouterLink>
  );
};

export default CollectionOutlineNavigation;
