import React from 'react';

import { TextMeasurer, TextMeasurerTextStyles } from 'shared/utils';

const useTextMeasurer = (textStyles: TextMeasurerTextStyles) => {
  const textMeasurerRef = React.useRef(new TextMeasurer(textStyles));
  const measureTextRef = React.useRef((text) => textMeasurerRef.current.measureText(text));

  return measureTextRef.current;
};

export default useTextMeasurer;
