/* @ngInject */
export default function TimedQuizLectureComponentModel(
  _,
  $translate,
  moment,
  $state,
  $stateParams,
  $interval,
  nvUtil,
  TimelinesManager,
  ReactTimelineService,
  CurrentCourseManager,
  QuizModel,
  CountdownTimerModel,
  LectureComponentBaseModel,
  QuizLectureComponentModel,
  config,
) {
  class TimedQuizLectureComponent extends QuizLectureComponentModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      const defaults = {
        type: 'TimedQuizLectureComponent',
        editFormTemplateUrl: 'lecture_pages/templates/components/timed-quiz-edit-modal.html',
        editFormController: 'TimedQuizEditFormModalCtrl',
        formName: 'editTimedQuizForm',
        quiz: {
          title: '',
          expirationDate: null,
          hardDeadline: true,
          releaseResultsAt: null,
          timeLimit: null,
          timeInterval: 'Minutes',
          isTodo: false,
        },
        isActivity: true,
      };
      super(_.extend({}, defaults, attributes), isSample, useDefaults);
    }

    __preprocess() {
      super.__preprocess();

      if (this.quiz.expirationDate) {
        this.__deadlineMoment = moment(this.quiz.expirationDate);
      }
      this.__currentMoment = moment();
      this.scheduledCommunicationsCount = this.quiz.scheduledCommunicationsCount;
      this.quiz.isResultReleased = moment().isSameOrAfter(this.quiz.releaseResultsAt);

      if (CurrentCourseManager.course.isSelfPaced && !this.quiz.startedExamAt) {
        this.quiz.calculatedTimeRemaining = this.quiz.timeRemaining;
      } else {
        this.quiz.calculatedTimeRemaining = this.quiz.calculateTimeRemaining(this.quiz.startedExamAt,
          this.quiz.timeLimit,
          this.quiz.timeInterval,
          this.quiz.hardDeadline && this.quiz.expirationDate);
      }

      if (this.quiz.duration && this.quiz.calculatedTimeRemaining && this.quiz.timeInterval) {
        this.quiz.countdownTimer = new CountdownTimerModel({
          duration: this.quiz.duration,
          timeRemaining: this.quiz.calculatedTimeRemaining,
          timeInterval: this.quiz.timeInterval,
        });
      }
    }

    /* Student Functions */
    dateFormat() {
      if (this.__deadlineMoment?.isSame(this.__currentMoment, 'year')) {
        return 'MOMENT.MONTH_DAY_AT_TIME';
      }
      return 'MOMENT.MONTH_DAY_COMMA_YEAR_AT_TIME';
    }

    isCompletedForPoints() {
      return this.isCompleted() && this.isResultReleased();
    }

    isMissed() {
      return this.quiz.progress === 'missed' || (this.timeUp() && !this.isCompleted());
    }

    inProgress() {
      return this.quiz.progress === 'in_progress';
    }

    deadlinePassed() {
      return this.quiz.expirationDate && this.__deadlineMoment < moment();
    }

    hardDeadlinePassed() {
      return this.quiz.hardDeadline && this.quiz.expirationDate && this.__deadlineMoment < moment();
    }

    isResultReleased() {
      return this.quiz.isResultReleased;
    }

    timeUp() {
      const isTimeUp = this.inProgress() && !this.quiz.countdownTimer.time.hours
        && !this.quiz.countdownTimer.time.minutes
        && !this.quiz.countdownTimer.time.seconds;

      return isTimeUp;
    }

    minCorrectAnswers() {
      return this.quiz.pointsConfiguration ? Math.ceil(this.quiz.pointsConfiguration.threshold * this.quiz.answerableQuestionsCount) : 0;
    }

    formattedReleaseResultsAt() {
      return moment(this.quiz.releaseResultsAt).format('MOMENT.MONTH_DAY_YEAR_TIME');
    }

    canShowTotalPoints() {
      return (this.isMissed() || this.isCompleted()) && this.quiz.pointsReceived < this.currentTotalPoints();
    }

    /* Admin Functions */
    getPayload() {
      return {
        quiz: _.pick(this.quiz, ['id', 'title', 'expirationDate', 'hardDeadline', 'releaseResultsAt', 'timeLimit', 'timeInterval', 'isTodo']),
      };
    }

    moveToWarningMessages(targetLecturePage) {
      const warningMessages = super.moveToWarningMessages(targetLecturePage, true);
      if (this.lecturePage.released && !targetLecturePage.released) {
        warningMessages.push($translate.instant('LECTURE_PAGES.COMPONENTS.TIMED_QUIZ.MOVE.UNRELEASE', CurrentCourseManager.course.getAliases()));
      }

      if (this.quiz.expirationDate && moment(this.quiz.expirationDate) < moment(targetLecturePage.releaseDate)) {
        warningMessages.push($translate.instant('LECTURE_PAGES.COMPONENTS.TIMED_QUIZ.MOVE.DEADLINE', CurrentCourseManager.course.getAliases()));
      }

      return warningMessages;
    }
  }

  TimedQuizLectureComponent.iconClass = 'icon-timedexam';
  TimedQuizLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.TIMED_QUIZ.DESCRIPTION';
  TimedQuizLectureComponent.pendoTagName = config.pendo.lectureEdit.timedExam;

  return TimedQuizLectureComponent;
}
