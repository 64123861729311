/* eslint-disable import/prefer-default-export */
import axios, { AxiosResponse } from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { omit } from 'underscore';
import t from 'react-translate';
import {
  RemoveNewCommentId,
  FetchCommentsRequest,
  FetchCommentsResponse,
  CreateCommentRequest,
  DeleteCommentRequest,
  UpdateCommentRequest,
  LikeCommentRequest,
  LikeResponse,
  AddCommentRequest,
  AddCommentResponse,
  EditCommentRequest,
  EditCommentResponse,
  RemoveComment,
  VoteComment,
  GetCommentLikersInfoRequest,
  HighlightCommentRequest,
  UnHighlightCommentRequest,
  FlagCommentRequest,
  TranslateCommentRequest,
} from 'redux/schemas/api/comment';
import { fetchSinglePost } from 'redux/actions/posts';
import { Result } from 'redux/schemas/api';
import { Comment } from 'redux/schemas/models/comment';
import { User } from 'redux/schemas/models/my-account';
import { makeQueryParamString } from 'redux/actions/helpers';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { addAlertMessage } from './alert-messages';

const COMMENTS_PAGE_SIZE = 5;

const getSingleComment = (catalogId, commentId) => axios.get<Result<Comment>>(`${catalogId}/posts/${commentId}`);

export const removeNewCommentId = createAction<RemoveNewCommentId>('REMOVE_NEW_COMMENT_ID');

export const getCommentLikersInfo = createAsyncThunk<User[], GetCommentLikersInfoRequest>(
  'COMMENT_LIKERS_INFO',
  async (params) => {
    const response = await axios.get(`${params.catalogId}/posts/${params.commentId}/voters?page=${params.page}`);
    return response.data.result;
  },
);

export const addComment = createAsyncThunk<AddCommentResponse, AddCommentRequest>(
  'ADD_COMMENT',
  async (params, thunkAPI) => {
    const { catalogId, postId, commentId } = params;
    const res = await getSingleComment(catalogId, commentId);
    if (postId) {
      thunkAPI.dispatch(fetchSinglePost({ catalogId, postId, includeUserBar: true }));
    }
    return {
      ...omit(params, 'commentId'),
      comment: res.data.result,
    };
  },
);

export const editComment = createAsyncThunk<EditCommentResponse, EditCommentRequest>(
  'EDIT_COMMENT',
  async (params) => {
    const { catalogId, commentId } = params;
    const res = await getSingleComment(catalogId, commentId);
    return { comment: res.data.result };
  },
);

export const removeComment = createAsyncThunk<RemoveComment, RemoveComment>(
  'REMOVE_COMMENT',
  async (params, thunkAPI) => {
    const { catalogId, postId } = params;
    if (postId) {
      thunkAPI.dispatch(fetchSinglePost({ catalogId, postId, includeUserBar: true }));
    }
    return params;
  },
);

export const voteComment = createAsyncThunk<VoteComment, VoteComment>(
  'LIKE_COMMENT',
  async (params, thunkAPI) => {
    const { catalogId, commentId } = params;
    thunkAPI.dispatch(getCommentLikersInfo({
      catalogId,
      commentId,
      page: 1,
    }));
    return params;
  },
);

export const fetchComments = createAsyncThunk<FetchCommentsResponse, FetchCommentsRequest>(
  'FETCH_COMMENTS',
  async (params) => {
    const queryString = makeQueryParamString(
      omit({
        ...params,
        pageSize: params.pageSize ?? COMMENTS_PAGE_SIZE,
        requestedFrom: 'lecture_page',
      },
      ['catalogId', 'postId']),
    );
    const response = await axios.get(`${params.catalogId}/topics/${params.postId}/posts?${queryString}`);
    return response.data.result;
  },
);

export const createComment = createAsyncThunk<Comment, CreateCommentRequest>(
  'CREATE_COMMENT',
  async (params, thunkAPI) => {
    const { catalogId, postId, ownerId, ownerType, callback } = params;

    try {
      let response: AxiosResponse<{ result: Comment }>;
      if (postId) {
        // Then this is a comment in a discussion
        response = await axios.post(`${catalogId}/topics/${postId}/posts`, { post: params });
        thunkAPI.dispatch(fetchSinglePost({ catalogId, postId, callback, includeUserBar: true }));
      } else {
        const post = omit(params, ['catalogId', 'ownerId', 'ownerType']);
        response = await axios.post(`${catalogId}/posts`, {
          ownerId,
          ownerType,
          post,
        });
      }

      return response.data.result;
    } catch (error) {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.OOPS(),
        message: t.FORM.ERROR_SOMETHING_WRONG(),
      }));
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const deleteComment = createAsyncThunk<Comment, DeleteCommentRequest>(
  'DELETE_COMMENT',
  async (params, thunkAPI) => {
    const { catalogId, postId, callback } = params;
    const response = await axios.delete(`${params.catalogId}/posts/${params.commentId}`);
    if (postId) {
      thunkAPI.dispatch(fetchSinglePost({ catalogId, postId, callback, includeUserBar: true }));
    }
    return response.data.result;
  },
);

export const updateComment = createAsyncThunk<Comment, UpdateCommentRequest>(
  'UPDATE_COMMENT',
  async (params) => {
    const post = omit(params, ['catalogId', 'commentId']);
    const response = await axios.put(`${params.catalogId}/posts/${params.commentId}`, { post });
    return response.data.result;
  },
);

export const voteUpComment = createAsyncThunk<LikeResponse, LikeCommentRequest>(
  'VOTE_UP_COMMENT',
  async (params) => {
    const response = await axios.post(`${params.catalogId}/posts/${params.commentId}/voteup`, {});
    return response.data.result;
  },
);

export const voteDownComment = createAsyncThunk<LikeResponse, LikeCommentRequest>(
  'VOTE_DOWN_COMMENT',
  async (params) => {
    const response = await axios.post(`${params.catalogId}/posts/${params.commentId}/votedown`, {});
    return response.data.result;
  },
);

export const highlightComment = createAsyncThunk<boolean, HighlightCommentRequest>(
  'HIGHLIGHT_COMMENT',
  async (params) => {
    const response = await axios.get(`${params.catalogId}/posts/${params.commentId}/highlight`, {});
    return response.data.result;
  },
);

export const unHighlightComment = createAsyncThunk<boolean, UnHighlightCommentRequest>(
  'UNHIGHLIGHT_COMMENT',
  async (params) => {
    const response = await axios.get(`${params.catalogId}/posts/${params.commentId}/unhighlight`, {});
    return response.data.result;
  },
);

export const flagComment = createAsyncThunk<boolean, FlagCommentRequest>(
  'FLAG_COMMENT',
  async (params) => {
    const response = await axios.post(`${params.catalogId}/flaggings?flag=abuse&flaggable_id=${params.commentId}&flaggable_type=Post`, {});
    return response.data.result;
  },
);

export const getCommentsForSubmission = createAsyncThunk<FetchCommentsResponse, FetchCommentsRequest>(
  'FETCH_SUBMISSION_COMMENTS',
  async (params) => {
    const { catalogId, ...rest } = params;
    const queryString = makeQueryParamString(rest);
    const response = await axios.get(`${params.catalogId}/posts?${queryString}`);
    return response.data.result;
  },
);

export const translateComment = createAsyncThunk<Comment, TranslateCommentRequest>(
  'TRANSLATE_DISCUSSION_COMMENT',
  async ({ catalogId, commentId, language }, thunkAPI) => {
    try {
      const response = await axios.post(`${catalogId}/posts/${commentId}/translate`, { language });
      return response.data.result;
    } catch(error) {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.DISCUSSIONS_AUTO_TRANSLATION.ERROR(),
        message: t.DISCUSSIONS_AUTO_TRANSLATION.RETRY()
      }))
      return thunkAPI.rejectWithValue(error)
    }
  }
)
