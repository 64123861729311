/* @ngInject */
export default function NvMax(
  _,
  BigNumber,
) {
  return {
    require: 'ngModel',
    link(scope, elem, attr, ngModel) {
      // For DOM -> model validation
      ngModel.$parsers.push((value) => {
        if (value != null) {
          const maxVal = new BigNumber(attr.max);
          value = new BigNumber(value);
          const valid = value.lessThanOrEqualTo(maxVal);

          ngModel.$setValidity('max', valid);
        } else {
          ngModel.$setValidity('max', true);
        }

        return value;
      });

      // //For model -> DOM validation
      // ngModel.$formatters.push(function(value) {
      //   var valid = value >= -5;
      //   ngModel.$setValidity('max', valid);

      //   return value;
      // });
    },
  };
}
