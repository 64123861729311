import React, { useContext, useEffect, useState } from 'react';
import { AngularContext } from 'react-app';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import CredlySettings from 'institutions/components/credly-settings';
import { disableOrgLevelCredlySettings } from 'redux/actions/credly';
import { config } from '../../../../../config/pendo.config.json';
import SectionContent from '../section-content';
import { DisableProps } from './integrations';

const CredlyIntegration = (props: { onDisable: (props: DisableProps) => void }) => {
  const { injectServices, $scope } = useContext(AngularContext);
  const [InstitutionsManager] = injectServices(['InstitutionsManager']);
  const [hasEnabled, setHasEnabled] = useState($scope.$eval('vm.InstitutionsManager.institution.isCredlyEnabled'));
  const [showConfigModal, setShowConfigModal] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    $scope.$watch('vm.InstitutionsManager.institution.isCredlyEnabled', (newValue) => {
      setHasEnabled(newValue);
    });
  }, [$scope]);

  const disableCredly = () => {
    props.onDisable({
      action: () => wrapThunkAction(
        dispatch(
          disableOrgLevelCredlySettings({
            institutionId: InstitutionsManager.institution.id,
          }),
        ),
      ),
      onSuccess: (response) => InstitutionsManager.institution.updateFromReact({
        ...(response?.payload as any).institutionList,
      }),
      title: t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.DISABLE(),
      bodyText: t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.DISABLE_DESCRIPTION(),
      successMessage: t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.DISABLE_SUCCESS(),
      confirmDataQa: config.pendo.credly.disableConfirm,
      cancelDataQa: config.pendo.credly.disableCancel,
    });
  };

  return (
    <React.Fragment>
      <SectionContent
        header={t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.TITLE()}
        description={[t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.DESCRIPTION()]}
        showCTAButton={!hasEnabled}
        ctaText={t.INSTITUTIONS.ADVANCED_SETTINGS.CONFIGURE()}
        onAction={() => setShowConfigModal(true)}
        dataQa={config.pendo.credly.integrationConfigure}
        extras={hasEnabled && (
        <div className='button-bar mt-6'>
          <Button
            variant='secondary'
            onClick={disableCredly}
            data-qa={config.pendo.credly.integrationDisable}
          >
            {t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.DISABLE()}
          </Button>
          <Button
            variant='primary'
            onClick={() => setShowConfigModal(true)}
            data-qa={config.pendo.credly.integrationEdit}
          >
            {t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.EDIT()}
          </Button>
        </div>
        )}
      />
      <CredlySettings
        show={showConfigModal}
        onClose={() => setShowConfigModal(false)}
      />
    </React.Fragment>
  );
};

export default CredlyIntegration;
