import { gray4 } from 'styles/global_defaults/colors';

/* @ngInject */
export default function BlurbBackgroundImageLectureComponentModel(
  _,
  $translate,
  ContentTemplateBaseModel,
  nvUtil,
  config,
) {
  class BlurbBackgroundImageLectureComponent extends ContentTemplateBaseModel {
    constructor(attributes, ...args) {
      const defaults = {
        type: 'BlurbBackgroundImageLectureComponent',
        componentClasses: ['blurb', 'background-image'],
        froalaClasses: ['no-background'],
        usePictureForContentBackground: true,
        hasContent: true,
        contentIsRichText: true,
        uploadSupported: true,
        placeholder: $translate.instant('LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.BURB_BACKGROUND_IMAGE.PLACEHOLDER'),
      };

      super(_.extend({}, defaults, attributes), ...args);
    }

    get fullWidth() {
      return this.viewOptions.componentWidth === 'FULL_WIDTH';
    }
  }


  BlurbBackgroundImageLectureComponent.FORM_DATA = [
    [{
      labelKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.COMPONENT_WIDTH',
      type: 'DROPDOWN',
      optionsKey: 'COMPONENT_WIDTHS',
      modelAttribute: 'componentWidth',
    }, {
      labelKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.TEXT_WIDTH',
      type: 'DROPDOWN',
      optionsKey: 'TEXT_WIDTHS',
      modelAttribute: 'width',
    }, {
      labelKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.MINIMUM_HEIGHT',
      type: 'FREEFORM_PIXELS',
      modelAttribute: 'minHeight',
    }],
    [{
      labelKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.TEXT_BACKGROUND',
      type: 'COLOR',
      modelAttribute: 'backgroundColor',
    },
    {
      labelKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.TEXT_ALIGNMENT',
      type: 'SELECTION',
      optionsKey: 'TEXT_VERTICAL_POSITIONS',
      modelAttribute: 'verticalAlign',
    },
    ],
  ];

  BlurbBackgroundImageLectureComponent.COMPONENT_WIDTHS = _.map(['FULL_WIDTH', '100%', '80%', '60%', '50%'], (value) => ({ labelKey: BlurbBackgroundImageLectureComponent.getDropdownKey(value), value: `${value}` }));

  BlurbBackgroundImageLectureComponent.TEXT_WIDTHS = _.map(['100%', '90%', '80%', '70%', '60%', '50%'], (value) => ({ labelKey: BlurbBackgroundImageLectureComponent.getDropdownKey(value), value: `${value}` }));

  BlurbBackgroundImageLectureComponent.TEXT_STYLES = _.map(['title', 'small-title', 'subtitle', 'big-text', 'medium-text', 'regular-text', 'small-text'], (value) => ({
    labelKey: BlurbBackgroundImageLectureComponent.getDropdownKey(value),
    css: `blurb-text-${value}`,
    value: `${value}`,
  }));

  BlurbBackgroundImageLectureComponent.TEXT_HORIZONTAL_POSITIONS = _.map(['left', 'center', 'right'], (value) => {
    let translationKey = value;

    if (nvUtil.isRtl() && value !== 'center') {
      translationKey = value === 'left' ? 'right' : 'left';
    }

    return {
      css: `icon icon-admin-image-${value === 'center' ? 'middel' : value}`,
      value: `${value}`,
      tooltipKey: `LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.ALIGN.${translationKey.toUpperCase()}`,
    };
  });

  BlurbBackgroundImageLectureComponent.TEXT_VERTICAL_POSITIONS = _.map(['top', 'middle', 'bottom'], (value) => ({ css: `icon icon-admin-text-${value}`, value: `${value}`, tooltipKey: `LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.ALIGN.${value.toUpperCase()}` }));
  BlurbBackgroundImageLectureComponent.pendoTagName = config.pendo.lectureEdit.pictureBackgroundText;
  BlurbBackgroundImageLectureComponent.toolTipKey = 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.BLURB_BACKGROUND_IMAGE';

  return BlurbBackgroundImageLectureComponent;
}
