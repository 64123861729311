import React from 'react';
import t from 'react-translate';

// Form
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

// Redux
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { getLoginProviders } from 'redux/actions/sign-in';
import { LoginProvider, LoginProviderData } from 'redux/schemas/models/sign-in';
import { Course } from 'redux/schemas/models/course';

// Components
import { Button } from 'react-bootstrap';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import OrDivider from './nv-or-divider';
import NvHeaderText from './nv-header-text';

type Props = {
  email: string,
  alreadyJoined: boolean,
  course: Course,
  onSuccess: (data: LoginProviderData) => void,
  onSSOClick: () => void,
  onError?: () => void,
};

const EmailAddressSignIn = (props: Props) => {
  const {
    email: emailAddress = '',
    alreadyJoined,
    course,
    onSuccess,
    onSSOClick,
    onError,
  } = props;
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = React.useState(false);

  const validationSchemaRef = React.useRef(yup.object().shape({
    email: yup.string()
      .min(1, t.VALIDATION.REQUIRED())
      .email(t.VALIDATION.EMAIL()),
  }));

  const methods = useForm({
    mode: 'onChange',
    shouldUnregister: true,
    defaultValues: { email: emailAddress },
    resolver: yupResolver(validationSchemaRef.current),
  });

  const { handleSubmit, formState } = methods;

  const onSubmit = ({ email }) => {
    setIsLoading(true);
    wrapThunkAction(dispatch(getLoginProviders({ email }))).then((action: any) => {
      const { payload }: { payload: LoginProvider } = action;
      onSuccess({ ...payload, email });
    }).catch(() => {
      onError?.();
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <React.Fragment>
      <div className='authentications-overlay'>
        <div className='authentications-body'>
          <NvHeaderText alreadyJoined={alreadyJoined} course={course} />
          <div className='registration-form'>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <NvTextInput
                  required
                  withForm
                  withLabel
                  name='email'
                  className='mb-5'
                  placeholder={t.USERS.REGISTRATION.EMAIL_ADDRESS()}
                  data-qa='email'
                />
                <Button
                  className='mb-5'
                  disabled={!formState.isValid || isLoading}
                  type='submit'
                  data-qa='next'
                >
                  {t.SHARED.NEXT()}
                </Button>
              </form>
            </FormProvider>
            <OrDivider />
            <Button
              variant='secondary'
              className='mt-5'
              data-qa='sso-login'
              onClick={onSSOClick}
            >
              {t.SHARED.LOG_IN_WITH_SSO()}
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmailAddressSignIn;
