/* eslint-disable react/no-danger */
import { css } from '@emotion/react';


import t from 'react-translate';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { textMediumFontSize, boldFontWeight } from 'styles/global_defaults/fonts';
import { gray5, gray7 } from 'styles/global_defaults/colors';
import prodPathReplace from '../../../../../shared/prod-path-rewrite';

type SamplePushNotificationProps = {
  notification: { title: string, bodyText: string}
};

const IOS_CHARACTER_LIMIT = 255;

export const SamplePushNotification = ({
  notification: {
    title = '',
    bodyText = '',
  },
}: SamplePushNotificationProps) => {
  const styles = css`
    &.sample-push-notification {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${gray7};
      padding-top: ${standardSpacing}px;
      padding-bottom: ${standardSpacing}px;

      .ios-view {
        background-image: url(${prodPathReplace('images/ios-background.png')});
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 667px;
        width: 375px;

        .dialog {
          width: 90%;
          border-radius: 10px;

          .dialog-header {
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            padding: ${halfSpacing}px;
            display: flex;
            background-color: rgba(255, 255, 255, 0.90);

            img {
              width: 20px;
              height: 20px;
            }

            .company-name {
              margin-left: ${halfSpacing}px;
            }

            .now {
              flex: 1;
              text-align: end;
            }
          }

          .dialog-body {
            background-color: rgba(255, 255, 255, 0.80);
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            padding: ${halfSpacing}px;
            font-size: ${textMediumFontSize}px;
            max-height: 500px;

            .dialog-title {
              font-weight: ${boldFontWeight};
            }
          }
        }
      }
    }
  `;

  const titleLength = title.length;
  const textLength = bodyText.length;

  let text = bodyText;
  if (textLength + titleLength > IOS_CHARACTER_LIMIT) {
    text = `${bodyText.substr(0, IOS_CHARACTER_LIMIT - titleLength)}...`;
  }

  return (
    <div css={styles} className='sample-push-notification'>
      <div className='ios-view'>
        <div className='dialog'>
          <div className='dialog-header'>
            <img src={prodPathReplace('images/logo-novoed-20@2x.png')} alt={t.COURSE_COMMUNICATIONS.SAMPLE_NOTIFICATION.NOVOED_LOGO()} />
            <div className='company-name'>{t.COURSE_COMMUNICATIONS.SAMPLE_NOTIFICATION.NOVOED()}</div>
            <div className='now'>{t.COURSE_COMMUNICATIONS.SAMPLE_NOTIFICATION.NOW()}</div>
          </div>
          <div className='dialog-body'>
            <div className='dialog-title' dangerouslySetInnerHTML={{ __html: title }} />
            <div className='dialog-text' dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SamplePushNotification;
