/* @ngInject */
export default function DashboardModelService(

  _,
  moment,

  DashboardResources,
  ReportsResources,
) {
  const EXERCISE = 'Exercise';
  const QUIZ = 'Quiz';
  const TIMED_EXAM = 'TimedExam';
  const CUSTOM_QUESTIONS = 'CustomQuestions';
  const SURVEY = 'Survey';
  const TEAM_SET = 'TeamSet';
  const LECTURE_PAGE = 'LecturePage';
  const COURSE = 'Course';
  const POLL = 'Poll';
  const VIDEO_PRACTICE_ACTIVITY = 'VideoPracticeActivity';

  const IN_PROGRESS = 'in_progress';
  const COMPLETED = 'completed';

  // - Submission Approval Specific
  const PENDING_APPROVAL = 'pending';
  const REJECTED = 'rejected';
  const REJECTED_AND_MISSED = 'rejected_and_missed';
  const APPROVED = 'approved';

  class DashboardModel {
    constructor(attributes) {
      _.extend(this, attributes);
    }

    isVideoPracticeActivityDeliverable() {
      return this.activityType === VIDEO_PRACTICE_ACTIVITY;
    }

    isReportDeliverable() {
      return this.activityType === EXERCISE;
    }

    isQuizDeliverable() {
      return this.type === QUIZ;
    }

    isPollDeliverable() {
      return this.type === POLL;
    }

    isTimedQuizDeliverable() {
      return this.type === TIMED_EXAM;
    }

    isPeerEvaluation() {
      return this.activityType === CUSTOM_QUESTIONS;
    }

    isSurveyDeliverable() {
      return this.type === SURVEY;
    }

    isTeamDeliverable() {
      return this.type === TEAM_SET;
    }

    isMeeting() {
      return this.meetingTime !== undefined;
    }

    isLecture() {
      return this.type === LECTURE_PAGE;
    }

    isCourse() {
      return this.type === COURSE;
    }

    isInProgress() {
      return this.activityStarted || this.started || this.startedExamAt;
    }

    getStatus() {
      if (this.isInProgress()) {
        return IN_PROGRESS;
      }

      return null;
    }

    // Exercise - Submission Approval Specific
    __exerciseIsMissed() {
      if (this.exercise.hardDeadline) {
        return moment(this.exercise.extendedDeadline || this.exercise.deadline) < moment();
      }
      return false;
    }

    isPendingApproval() {
      // dashboard model is used to represent too many cases, in this case isTodo represents a Exercise, otherwise Report
      if (this.isTodo) {
        return this.progress === PENDING_APPROVAL;
      }
      return !!this.submitting && this.submitting.submissionStatus === PENDING_APPROVAL;
    }

    isRejectedCanRevise() {
      if (this.isTodo) {
        return this.progress === REJECTED;
      }
      return !!this.submitting && this.submitting.submissionStatus === REJECTED && !this.__exerciseIsMissed();
    }

    isRejectedCannotRevise() {
      return !!this.submitting && this.submitting.submissionStatus === REJECTED && this.__exerciseIsMissed();
    }

    isCompleted() {
      return !!this.submitting && this.submitting.submissionStatus === COMPLETED;
    }

    isApproved() {
      return !!this.submitting && this.submitting.submissionStatus === APPROVED;
    }

    approvalStatusKey() {
      if (this.isPendingApproval()) {
        return 'SUBMISSION_APPROVAL.SUBMISSION_STATUS.PENDING';
      } if (this.isRejectedCanRevise()) {
        return 'SUBMISSION_APPROVAL.SUBMISSION_STATUS.REJECTED';
      } if (this.isRejectedCannotRevise()) {
        return 'SUBMISSION_APPROVAL.SUBMISSION_STATUS.REJECTED_PAST';
      } if (this.isApproved()) {
        return 'SUBMISSION_APPROVAL.SUBMISSION_STATUS.APPROVED';
      }

      return null;
    }
  }

  DashboardModel.getCurrentLearning = () => DashboardResources.getCurrentLearning().$promise.then((resource) => _.map(resource.result, (lecture) => new DashboardModel(lecture)));

  DashboardModel.getApprovalSubmissions = () => {
    const request = ReportsResources.searchSubmissionsForApproval({}, {
      pageSize: 20,
      page: 1,
    });

    request.$promise = request.$promise.then((resource) => ({
      count: resource.result.count,
      reports: _.map(resource.result.reports, (submission) => new DashboardModel(submission)),
    }), (response) => ({ count: 0, reports: [] }));

    return request;
  };

  /* eslint-disable arrow-body-style */
  DashboardModel.getTodos = () => {
    return DashboardResources.getTodos().$promise.then((resource) => {
      return _.map(resource.result, (todo) => {
        // isTodo is to differentiate because we get different data. really everything should not be dashboard models
        return new DashboardModel(_.extend(todo, { isTodo: true }));
      });
    });
  };
  /* eslint-enable arrow-body-style */

  DashboardModel.getDiscoveryCourses = () => DashboardResources.getDiscoveryCourses().$promise.then((resource) => _.map(resource.result, (currentCourse) => new DashboardModel(currentCourse)));

  DashboardModel.getCurrentCourses = () => DashboardResources.getCurrentCourses().$promise.then((resource) => _.map(resource.result, (currentCourse) => new DashboardModel(currentCourse)));

  DashboardModel.getCompletedCourses = () => DashboardResources.getCompletedCourses().$promise.then((resource) => _.map(resource.result, (completedCourse) => new DashboardModel(completedCourse)));

  DashboardModel.getCertificates = () => DashboardResources.getCertificates().$promise.then((resource) => _.map(resource.result, (certificate) => new DashboardModel(certificate)));

  DashboardModel.getSubmissions = () => DashboardResources.getSubmissions().$promise.then((resource) => _.map(resource.result, (submission) => new DashboardModel(submission)));

  DashboardModel.getActiveDrafts = () => DashboardResources.getActiveDrafts().$promise.then((resource) => _.map(resource.result, (activeDraft) => new DashboardModel(activeDraft)));

  return DashboardModel;
}
