import { mergeWith } from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import { replaceArrays } from 'shared/lodash-utils';
import { addCourseRoles, addMultipleUsers, addOrgAdmin, deleteCourseRole,
  deleteOrgAdmin, deleteOrgUser, getCourseRoles, getOrgAdmins, searchOrgUsers,
  updateCourseRole, updateOrgAdmin,
} from 'redux/actions/org-level-roles';
import { normalize } from 'normalizr';
import { CourseRole } from 'redux/schemas/models/org-level-roles';
import { OrgAdmin, OrgLevelUser } from 'redux/schemas/models/user';
import { OrgAdminEntitties, orgAdminListSchema, OrgUserEntities, courseRolesSchema,
  orgLevelUsersSchema, CourseRolesEntities, courseRolesListSchema, SmallerBulkUploadResponse, orgAdminSchema, CourseRolesNormalized,
} from 'redux/schemas/api/org-level-roles';
import { OrgAdminsNormalized } from 'redux/schemas/api/user';
import { initialRootState } from './index';

export default createReducer(initialRootState, builder => {
  builder
    .addCase(searchOrgUsers.fulfilled, (state, action) => {
      const data = normalize<OrgLevelUser, OrgUserEntities>(
        action.payload.response,
        orgLevelUsersSchema,
      );
      mergeWith(state.models.users, data.entities.orgLevelUsers, replaceArrays);
    })
    .addCase(deleteOrgUser.fulfilled, (state, action) => {
      delete state.models.users[action.meta.arg.userId];
    })
    .addCase(getOrgAdmins.fulfilled, (state, action) => {
      const data = normalize<OrgAdmin, OrgAdminEntitties>(
        action.payload.response,
        orgAdminListSchema,
      );
      Object.assign(state.models.orgAdmins, data.entities.orgAdmins);
    })
    .addCase(addOrgAdmin.fulfilled, (state, action) => {
      if (action.payload) {
        const data = normalize<OrgAdmin, OrgAdminsNormalized>(action.payload, orgAdminSchema);
        mergeWith(state.models.orgAdmins, data.entities.orgAdmins, replaceArrays);
      }
    })
    .addCase(deleteOrgAdmin.fulfilled, (state, action) => {
      delete state.models.orgAdmins[action.meta.arg.adminId];
    })
    .addCase(getCourseRoles.fulfilled, (state, action) => {
      const data = normalize<CourseRole, CourseRolesEntities>(
        action.payload.response,
        courseRolesListSchema,
      );
      mergeWith(state.models.roles, data.entities.courseRoles, replaceArrays);
    })
    .addCase(deleteCourseRole.fulfilled, (state, action) => {
      delete state.models.roles[action.meta.arg.roleId];
    })
    .addCase(addCourseRoles.fulfilled, (state, action) => {
      if (action.payload) {
        const data = normalize<CourseRole, CourseRolesNormalized>(action.payload, courseRolesSchema);
        mergeWith(state.models.roles, data.entities.courseRoles, replaceArrays);
      }
    })
    .addCase(updateCourseRole.fulfilled, (state, action) => {
      if (action.payload) {
        const data = normalize(action.payload, courseRolesSchema);
        Object.assign(state.models.roles, data.entities.courseRoles);
      }
    })
    .addCase(updateOrgAdmin.fulfilled, (state, action) => {
      if (action.payload) {
        state.models.orgAdmins[action.meta.arg.adminId].adminRole = action.meta.arg.adminRole;
      }
    })
    .addCase(addMultipleUsers.fulfilled, (state, action) => {
      const users = (action.payload as SmallerBulkUploadResponse)?.profilesUpdatedCompletely || [];
      const data = normalize<OrgLevelUser, OrgUserEntities>(users, orgLevelUsersSchema);
      mergeWith(state.models.users, data.entities.orgLevelUsers, replaceArrays);
    });
});
