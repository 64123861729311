import { jsx, css } from '@emotion/react';
import t from 'react-translate';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { gray6 } from 'styles/global_defaults/colors';

type VideoPreviewUnavailableProps = {
  downloadUrl: string;
};

const VideoPreviewUnavailable = ({
  downloadUrl,
}: VideoPreviewUnavailableProps) => {
  const styles = css`
    height: 450px;
    width: 100%;
    background-color: ${gray6};
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .page-title {
      margin-bottom: ${standardSpacing}px;
      font-weight: 400;
    }
  `;

  return (
    <div css={styles} className='unavailable'>
      <div className='page-title text-gray-3'>{t.VIDEO.PREVIEW_NOT_AVAILABLE()}</div>
      {downloadUrl && (
        <a download target='_blank' rel='noreferrer' className='color-primary bold' href={downloadUrl}>{t.LECTURE_VIDEO.DOWNLOAD_ORIGINAL()}</a>
      )}
    </div>
  );
};

export default VideoPreviewUnavailable;
