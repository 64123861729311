import { useContext, useState } from 'react';
import { AngularServicesContext } from 'react-app';
import { find, isEmpty } from 'underscore';
import t from 'react-translate';
import { css } from '@emotion/react';

// Redux
import { User } from 'redux/schemas/models/my-account';
import { CourseRole } from 'redux/schemas/models/org-level-roles';

// styles
import { tripleSpacing } from 'styles/global_defaults/scaffolding';

// Components
import NvDropdown, { NvDropdownButtonStyle, NvDropdownCustomItem } from 'shared/components/inputs/nv-dropdown';
import NvUserAvatar from 'components/nv-user-avatar';
import { Button } from 'react-bootstrap';
import { RolesService } from 'institutions/services/roles-service';
import { CollectionRoleItem, UserFormData } from './add-user-modal';

interface CollectionUserListItemsProps {
  collectionRoles: CourseRole[]
  user: User
  onSubmit: (formData: UserFormData) => Promise<void>,
}
const getInitials = (FirstName: string, LastName: string) => `${FirstName?.[0]?.toUpperCase() ?? ''}${LastName?.[0]?.toUpperCase() ?? ''}`;
const CollectionUserListItems = (props: CollectionUserListItemsProps) => {
  const { user, collectionRoles } = props;
  const viewerRole = find(collectionRoles, (role) => isEmpty(role.permissions));
  const adminRole = find(collectionRoles, (role) => RolesService.isCollectionAdminRole(role));

  const [saving, setSaving] = useState<boolean>(false);
  const [userCourseRoleId, setUserCourseRoleId] = useState<number>(user.isOrgManager ? adminRole.id : viewerRole?.id);

  const getRoleItemTitle = (): string => find(collectionRoles, (role) => role.id === userCourseRoleId)?.name;
  const { $state } = useContext(AngularServicesContext) || {};

  const styles = css`
    .avatar-cell {
      width: ${tripleSpacing}px
    }
    .name-cell, .external-cell {
      width: 100px
    }
    .email-cell {
      width: 160px
    }
    .role-cell {
      width: 180px
    }
  `;

  const roles = user.isOrgManager ? [adminRole] : collectionRoles;
  const collectionRoleItems: NvDropdownCustomItem[] = roles.map(role => ({
    id: role.id,
    type: 'custom',
    customItem: <CollectionRoleItem role={role} onClick={setUserCourseRoleId} />,
  }));

  const onAdd = () => {
    setSaving(true);
    const formData: UserFormData = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      courseRoleId: userCourseRoleId.toString(),
    };

    props.onSubmit(formData).then(() => {
      setSaving(false);
    });
  };
  return (
    <div css={styles} key={props.user.id}>
      <div className='d-flex align-items-center border-gtay-6 border-bottom py-3'>
        <div className='avatar-cell'>
          <NvUserAvatar
            directToOrgLevelProfile
            openInNewTab
            borderType='round'
            size='md'
            user={{
              admin: false,
              ...props.user,
              fullName: props.user.fullName ?? `${props.user.firstName} ${props.user.lastName}`,
              roles: props.user.roles,
              initials: props.user.initials ?? getInitials(props.user.firstName, props.user.lastName),
              userNameForURL: props.user.userNameForURL ?? `${props.user.firstName}-${props.user.lastName}`,
            }}
          />
        </div>
        <div className='name-cell pl-2 font-weight-bolder'>
          <a
            href={$state?.href('org-level-profile-modal', { userId: props.user.id })}
            target='_blank'
            rel='noreferrer'
          >
            {`${props.user.firstName} ${props.user.lastName}`}
          </a>
        </div>
        <div className='external-cell pl-2'>{props.user.externalId}
        </div>
        <div className='email-cell pl-2'>{props.user.email}
        </div>
        <div className='role-cell pl-2'>
          <NvDropdown
            items={collectionRoleItems}
            buttonStyle={NvDropdownButtonStyle.FORM}
            title={getRoleItemTitle()}
            tooltip={user.isOrgManager ? t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.MANAGE_ACCESS_MODAL.DISABLED_ROLE_TOOLTIP() : ''}
            disabled={user.isOrgManager}
          />
        </div>
        <div className='pl-2'>
          <Button
            variant='primary'
            disabled={saving}
            onClick={onAdd}
          >
            {saving ? t.FORM.ADDING() : t.FORM.ADD()}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CollectionUserListItems;
