/* @ngInject */
export default function AnnouncementsModalCtrl(

  $scope,
  $uibModalInstance,
  AnnouncementsManager,
  PusherManager,
  PusherChannels,
  announcementId,
  humps,
  numUnreadAnnouncementsAbove,
) {
  const vm = this;

  vm.AnnouncementsManager = AnnouncementsManager;
  vm.announcementId = announcementId;
  vm.announcementsCopy = angular.copy(AnnouncementsManager.announcements);
  vm.course = AnnouncementsManager.currentCourse();
  vm.numTotalAnnouncementsForCourse = vm.AnnouncementsManager.announcements.length;
  vm.numUnreadAnnouncementsForCourse = vm.AnnouncementsManager.courseIdToNumUnreadAnnouncements[vm.course.id] ? vm.AnnouncementsManager.courseIdToNumUnreadAnnouncements[vm.course.id] : 0;
  vm.numUnreadAnnouncementsAbove = numUnreadAnnouncementsAbove;
  vm.loadingAnnouncementsAbove = false;
  vm.loadAnnouncementsAbove = loadAnnouncementsAbove;
  vm.closeModal = closeModal;

  $scope.$on('$destroy', () => {
    PusherManager.channelsHash[PusherChannels.announcementsChannel].unbind('new_announcement', newAnnouncementEventHandler);
  });

  initialize();

  function initialize() {
    PusherManager
      .setupChannel(PusherChannels.announcementsChannel)
      .bind('new_announcement', newAnnouncementEventHandler);
  }

  function loadAnnouncementsAbove() {
    vm.numUnreadAnnouncementsAbove = 0;
    vm.loadingAnnouncementsAbove = true;

    AnnouncementsManager.reload().then(() => {
      vm.loadingAnnouncementsAbove = false;
      vm.numTotalAnnouncementsForCourse = vm.AnnouncementsManager.announcements.length;
      vm.numUnreadAnnouncementsForCourse = vm.AnnouncementsManager.courseIdToNumUnreadAnnouncements[vm.course.id] ? vm.AnnouncementsManager.courseIdToNumUnreadAnnouncements[vm.course.id] : 0;
      vm.announcementsCopy = angular.copy(AnnouncementsManager.announcements);
      AnnouncementsManager.markAnnouncementsAsReadForCourse();
    });
  }

  function closeModal() {
    $uibModalInstance.dismiss('cancel');
  }

  // Private Functions
  function newAnnouncementEventHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);

    if (vm.course.id === pusherData.courseId) {
      vm.numUnreadAnnouncementsAbove += 1;
      vm.numUnreadAnnouncementsForCourse += 1;
    }
  }
}
