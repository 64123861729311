import React from 'react';
import { useSelector } from 'react-redux';
import { ClassNames, css } from '@emotion/react';

import { useAppDispatch } from 'redux/store';
import { mergeRefs } from 'shared/react-utils';
import { Course } from 'redux/schemas/models/course';
import { setCourseHighlight } from 'redux/actions/institutions';
import { quarterSpacing } from 'styles/global_defaults/scaffolding';
import { getCourseAccessModalProps } from 'redux/selectors/course-access-modal';
import useScrollElementVisibility from 'shared/hooks/use-scroll-element-visibility';
import {
  warning,
  lightOrange,
  hexToRgbaString,
} from 'styles/global_defaults/colors';

type Props = {
  data: Course,
  children: React.ReactElement,
  onMouseEnter?: () => void,
  onMouseLeave?: () => void,
};

const CourseRowBackground = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    data,
    children,
    ...restProps
  } = props;

  const dispatch = useAppDispatch();
  const backgroundRef = React.useRef();
  const isElementVisible = useScrollElementVisibility(backgroundRef, 100);
  const { courseHighlight } = useSelector((state) => state.app.institutionDashboard);
  const { catalogId: isCourseAccessModalOpen } = useSelector(getCourseAccessModalProps);

  const isHighlighted = courseHighlight === data.catalogId;

  React.useEffect(() => {
    if (isHighlighted && isElementVisible && !isCourseAccessModalOpen) {
      setTimeout(() => {
        dispatch(setCourseHighlight(null));
      }, 3000);
    }
  }, [dispatch, isHighlighted, isCourseAccessModalOpen, isElementVisible]);


  return (
    <ClassNames>
      {({ css: innerCss }) => (
        React.cloneElement(children, {
          ref: mergeRefs(ref, backgroundRef),
          className: [
            children.props.className,
            isHighlighted && 'highlight',
            innerCss`
              ${css`
                border-left: ${quarterSpacing}px solid transparent;
                transition: background-color 1s, border-left-color 1s;

                &.highlight {
                  border-left-color: ${warning};
                  background-color: ${hexToRgbaString(lightOrange, 0.2)};
                }
              `}
            `,
          ].filter(Boolean).join(' '),
          ...restProps,
        })
      )}
    </ClassNames>
  );
});

export default CourseRowBackground;
