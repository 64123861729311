
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import t from 'react-translate';
import moment from 'moment';

// Contexts
import { RootState } from 'redux/schemas';
import { CommunicationDispatch, TriggerType } from 'communications/course_communications/contexts/communication-context';

// Schemas
import { CommunicationCategory, ScheduledForType, ViewMode, ItemState } from 'redux/schemas/models/course-communication';

// Selectors
import { getItemState } from 'redux/selectors/course-communications';

// Components
import AnnouncementForm from './announcements/announcement-form';
import EmailPreview from './announcements/email-preview';
import PushNotificationPreview from './announcements/push-notification';
import CommunicationForm from './communication-form';

const ScheduledAnnouncement = () => {
  const { State } = useContext(CommunicationDispatch);
  const itemState = useSelector<RootState, ItemState>((state) => getItemState(state, State.communicationId));
  const isEditSentAnnouncement = (State.communicationCategory === CommunicationCategory.SCHEDULED_ANNOUNCEMENT
    && State.triggerType === TriggerType.EDIT
    && itemState === ItemState.SENT);

  const validationSchema = yup.object().shape({
    scheduledFor: yup.string().nullable(),
    xDays: yup.number().nullable()
      .when('scheduledFor', {
        is: ScheduledForType.AFTER_DATE,
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      })
      .when('scheduledFor', {
        is: ScheduledForType.BEFORE_DATE,
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      }),
    scheduledOn: yup.date().nullable()
      .when('scheduledFor', {
        is: ScheduledForType.SCHEDULED,
        then: yup.date()
          .transform(function (value, originalValue) {
            if (originalValue?._isAMomentObject) {
              return originalValue.toDate();
            }
            return value;
          })
          .typeError(t.VALIDATION.DATE())
          .required(t.VALIDATION.REQUIRED())
          .test(
            'isFutureTime',
            t.VALIDATION.SELECT_FUTURE_TIME(),
            (scheduledOn) => (moment(scheduledOn).isAfter(moment()) || isEditSentAnnouncement),
          ),
      }),
    emailSubject: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .min(1, t.VALIDATION.MIN('1'))
      .max(255, t.VALIDATION.MAX('255')),
    emailBody: yup.string()
      .required(t.VALIDATION.REQUIRED()),
    hasEmail: yup.boolean().nullable(),
    adminUserId: yup.number().required(),
  });

  return (
    <CommunicationForm validationSchema={validationSchema}>
      <AnnouncementForm />
      {State.viewMode === ViewMode.EMAIL_PREVIEW && (
        <EmailPreview />
      )}
      {State.viewMode === ViewMode.NOTIFICATION_PREVIEW && (
        <PushNotificationPreview />
      )}
    </CommunicationForm>
  );
};


export default ScheduledAnnouncement;
