import { schema } from 'normalizr';
import { SkillTag } from '../models/skill-tag';

export interface SkillTagsNormalized {
  [id: string]: SkillTag
}

export interface ISkillTagEntities {
  skilltags: SkillTagsNormalized,
}

const skillTagsSchema = new schema.Entity<SkillTag>('skilltags');
export const skilltagsSchema = new schema.Array(skillTagsSchema);

export interface SkillTagsRequest {
  catalogId: string;
  teamId: number;
}

export interface SkillTagsResponse {
  skilltags: SkillTagsNormalized;
}
