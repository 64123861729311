import React, { useContext } from 'react';

import moment from 'moment';

import { connect, useSelector } from 'react-redux';
import t from 'react-translate';

// schemas
import { RootState } from 'redux/schemas';
import { ComponentKeyPluralized } from 'redux/schemas/models/lecture-component';
import { ActivityType } from 'redux/schemas/models/activity';
import { NTimelineSurvey } from 'redux/schemas/models/survey';

// selectors
import { getSurvey, hasSurveyGotCommunicationError } from 'redux/selectors/timeline';
import { isActivityExpandedInAutomatedCommunications } from 'redux/selectors/course-communications';

// actions
import { setActivityExpandedInAutomatedCommunications } from 'redux/actions/course-communications';

// components
import { NvTooltip } from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';

import CommunicationCreateNewDropdown from 'communications/course_communications/components/communication-create-new-dropdown';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';
import NvStackedTab from 'shared/components/nv-stacked-tab';


type SurveyLectureComponentProps = {
  surveyId: number
};

type StateProps = {
  survey: NTimelineSurvey,
  hasCommunicationError: boolean,
};

const mapDispatchToProps = {
  setExpanded: setActivityExpandedInAutomatedCommunications,
};

const SurveyLectureComponent = (props: SurveyLectureComponentProps & StateProps & typeof mapDispatchToProps) => {
  const isExpanded = useSelector<RootState, boolean>((state) => isActivityExpandedInAutomatedCommunications(state, ComponentKeyPluralized.SURVEY, props.surveyId));

  return (
    <React.Fragment>
      <div className={`lecture-component-row ${isExpanded && props.survey?.communicationsCount > 0 && 'expanded-box'}`}>
        <NvIcon icon='survey' size='smallest' />
        <div className='description'>{props.survey?.title}</div>
        {props.survey?.hasStructuralIssues && (
          <div className='text-danger spacing'>
            <NvIcon icon='warning' size='xss-smallest' />
          </div>
        )}
        {props.survey?.hardDeadline && (
          <NvTooltip text={t.TIMELINE.HARD_DEADLINE()} placement='top'>
            <div className='spacing'>
              <NvIcon icon='harddeadline' size='smallest' />
            </div>
          </NvTooltip>
        )}
        <div className='date'>
          {props.survey?.expirationDate && t.LECTURE_PAGES.COMPONENTS.DUE_DATE.STUDENT_VIEW(moment(props.survey.expirationDate).format('LLL'))}
        </div>
        <div className=''>
          <CommunicationCreateNewDropdown activityType={ActivityType.SURVEY} activityId={props.surveyId} />
        </div>
        { props.survey?.communicationsCount > 0 && (
          <NvStackedTab
            count={props.survey.communicationsCount}
            hasError={props.hasCommunicationError}
            isExpanded={isExpanded}
            errorMessage={t.COURSE_COMMUNICATIONS.WARNINGS.ACTIVITY_COMMUNICATION()}
            onClick={() => props.setExpanded({
              type: ComponentKeyPluralized.SURVEY,
              id: props.surveyId,
              isExpanded: !isExpanded,
            })}
          />
        )}
      </div>
      {
        // Communications
        isExpanded && props.survey?.communications && props.survey.communications.map(communicationId => (
          <CommunicationItem key={communicationId.toString()} communicationId={communicationId} />
        ))
      }
    </React.Fragment>
  );
};

const ConnectedSurveyLectureComponent = connect(
  (state: RootState, ownProps: SurveyLectureComponentProps) => ({
    survey: getSurvey(state, ownProps.surveyId),
    hasCommunicationError: hasSurveyGotCommunicationError(state, ownProps.surveyId),
  }),
  mapDispatchToProps,
)(SurveyLectureComponent);

export default ConnectedSurveyLectureComponent;
