/* eslint-disable react/no-unused-prop-types */
import React, { useRef, useState } from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';
import * as yup from 'yup';
// components
import Button from 'react-bootstrap/Button';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvRoundButton from 'shared/components/nv-round-button';
// sizes, colors and fonts
import { halfSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { semiBoldFontWeight } from 'styles/global_defaults/fonts';
import validationConstants from 'shared/services/constants-shared';

// constants
const MAX_LENGTH = 255;

// types
type AddItemProps = {
  icon?: string,
  buttonLabel?: string,
  showConfirmation?: boolean,
  confirmationMessage? : (val: string) => string,
  onSave: (value: any) => Promise<any> | boolean,
  errorMessage: { message: string },
  placeholder?: string,
  closeOnSave?: boolean,
  saveBtnText?: string,
  value?: string,
  errorOnTouching?: boolean,
  maxLength?: number
  pendoTag? : string
};
type AddItemFormProps = {
  onCancel: Function,
  saveBtnText?: string,
};

// styles
const styles = css`
  &.add-item-container {
    width: fit-content;
  }
`;
const formstyles = css`
  &.add-form {
    min-height: 62px;
    .form-container {
      display: flex;
      align-items: center;
      .add-input {
        width: 300px;
        margin-right: ${halfSpacing}px;
      }
      .button {
        margin-right: ${halfSpacing}px;
        padding: 3px ${halfSpacing}px;
      }
    }
    .confirmation{
      font-weight: ${semiBoldFontWeight};
      margin-top: ${quarterSpacing}px;
    }
  }
`;

export const NvAddItemForm = (props: AddItemProps & AddItemFormProps) => {
  const { saveBtnText = t.FORM.SAVE(), value = '' } = props;
  const [formValue, setFormValue] = useState(value);
  const [error, setError] = useState(null);
  const [savedValue, setSavedValue] = useState(null);
  const inputRef = React.useRef<HTMLInputElement>();
  const validationSchema = yup.string()
    .required(t.VALIDATION.REQUIRED())
    .min(2, t.VALIDATION.MIN_LENGTH('2'));
    // TODO Isael. Uncomment the next code or delete it when BE is ready.
    // .matches(validationConstants.SKILL_TAG_REGEX, {
    //   message: t.VALIDATION.FORBIDDEN_CHARACTERS,
    // });
  const onChange = (val) => {
    setFormValue(val);
    if (error) {
      setError(null);
    }
    if (savedValue) {
      setSavedValue(null);
    }
  };

  const onBlur = () => {
    doValidate();
  };

  const handleInputKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && formValue) {
      doValidateAndSave();
    }
  };

  const doValidateAndSave = () => doValidate(true);

  const doValidate = (isSaving: boolean = false) => {
    validationSchema.validate(formValue).then(() => {
      if (isSaving) {
        doSave();
      }
    })
      .catch((err) => {
        setError(err);
      });
  };

  const doSave = () => {
    inputRef.current.focus();
    const result = props.onSave(formValue);
    Promise.resolve(result).then((success) => {
      if (success) {
        setSavedValue(formValue);
        setFormValue('');
      } else {
        setSavedValue(null);
        setError(props.errorMessage);
      }
    });
  };
  return (
    <div css={formstyles} className='add-form'>
      <div className='form-container'>
        <NvTextInput
          ref={inputRef}
          value={formValue}
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={handleInputKeyDown}
          autoFocus
          placeholder={props.placeholder}
          required
          error={error}
          className='add-input edit-input'
          maxLength={props.maxLength || MAX_LENGTH}
          showErrorOnEnter
          errorOnTouching={props.errorOnTouching}
          onBlur={(e) => onBlur()}
        />
        <Button disabled={!formValue || error} className='button save text-small' onClick={(e) => doValidateAndSave()}>{saveBtnText}</Button>
        <Button className='button cancel text-small' variant='secondary' onClick={(e) => props.onCancel()}>{t.FORM.CANCEL()}</Button>
      </div>
      {savedValue && props.showConfirmation && (
        <div className='confirmation text-small text-success'>
          {props.confirmationMessage(savedValue)}
        </div>
      )}
    </div>
  );
};

const NvAddItem = (props: AddItemProps) => {
  const [isExpanded, setExpanded] = useState(false);
  const doSave = (value) => {
    const result = props.onSave(value);
    // close the form if closeOnSave was set and onSave returns true (no validation errors)
    if (props.closeOnSave && result) {
      setExpanded(false);
    }
    return result;
  };
  return (
    <div css={styles} className='add-item-container'>
      {!isExpanded && (
      <NvRoundButton
        icon={props.icon}
        label={props.buttonLabel}
        onClick={() => setExpanded(true)}
        pendoTag={props.pendoTag}
      />
      )}
      {isExpanded && (
      <NvAddItemForm
        errorMessage={props.errorMessage}
        showConfirmation={props.showConfirmation}
        onSave={(value) => doSave(value)}
        onCancel={() => setExpanded(false)}
        confirmationMessage={props.confirmationMessage}
        placeholder={props.placeholder}
        saveBtnText={props.saveBtnText}
        errorOnTouching={props.errorOnTouching}
        maxLength={props.maxLength}
      />
      )}
    </div>
  );
};

export default NvAddItem;
