/* @ngInject */
export default function NvLectureComponent(
  $compile,
) {
  return {
    scope: {
      lectureComponent: '=',
      markReadBackendAsRead: '&',
      editMode: '=',
      restrictedEditMode: '=',
      reorderMode: '=',
      linkedEditMode: '=',
      index: '=',
      context: '<',
      onContentReady: '<',
    },
    link(scope, element) {
      if (scope?.lectureComponent?.directiveName) {
        element.append($compile(`<${scope.lectureComponent.directiveName}
            lecture-component='lectureComponent'
            mark-read-backend-as-read='markReadBackendAsRead()'
            edit-mode='editMode'
            linked-edit-mode='linkedEditMode'
            restricted-edit-mode='restrictedEditMode'
            reorder-mode='reorderMode'
            index='index'
            context='context'
            on-content-ready='onContentReady'>
          </${scope.lectureComponent.directiveName}>`)(scope));
      }
    },
  };
}
