import moment, { unitOfTime } from 'moment';

type SessionStatesProps = {
  startTime: string;
  duration: any;
  timezone?: string;
  unit?: unitOfTime.DurationConstructor;
  startsSoon?: {
    timeBeforeEvent?: number;
    unit?: unitOfTime.DurationConstructor;
  }
};

const useSessionStates = (props: SessionStatesProps) => {
  const {
    startTime,
    duration,
    timezone = 'UTC',
    unit = 'minutes',
    startsSoon: soon = {
      timeBeforeEvent: 10,
      unit: 'minutes',
    },
  } = props;

  const momentStartTime: moment.Moment = moment(startTime);
  const endTime: moment.Moment = moment(momentStartTime).add(duration, unit);
  const timeBefore: moment.Moment = moment(momentStartTime).subtract(soon.timeBeforeEvent, soon.unit);

  const inProgress = moment().isBetween(momentStartTime, endTime);
  const startsSoon = moment().isBetween(timeBefore, momentStartTime);
  const ended = moment().isAfter(endTime);
  const upcoming = moment().isBefore(timeBefore);

  return {
    inProgress,
    startsSoon,
    ended,
    upcoming,
  };
};

export default useSessionStates;
