import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { useInView } from 'react-intersection-observer';

import { useAppDispatch } from 'redux/store';
import { RootState } from 'redux/schemas';
import { getTimelineTodosList, resetTimelineTodo } from 'redux/actions/timeline';
import { TimelineActivity } from 'redux/schemas/models/activity';

import NvIcon from 'shared/components/nv-icon';
import ClickableContainer from 'components/clickable-container';
import LoadingPlaceholder, { LoaderType } from 'shared/components/loading-placeholder';
import TimelineActivityRow from 'timelines/components/timeline-row/timeline-activity-row';
import { CourseTimelineContext } from 'timelines/components/course_home/course-timeline';
import useCompletionCriteria from '../../use-completion-criteria';

type TodosProps = {
  // Whether to display all todos or only display ones needed for completion
  onlyForCompletion?: boolean,
  onViewAllTodos?: () => void,
};

const Todos = ({ onlyForCompletion = true, onViewAllTodos }: TodosProps) => {
  const dispatch = useAppDispatch();
  const { vieweeId } = useContext(CourseTimelineContext) || {};

  const catalogId = useSelector((state: RootState) => state.app.currentCatalogId);
  const {
    loadingTimelineTodos, hasMore,
    lastLoadedPage, lastLoadedMode,
  } = useSelector((state: RootState) => state.app.timeline.todos);
  const todos: TimelineActivity[] = useSelector((state: RootState) => state.models.timelines[catalogId]?.todoActivities);

  const { ref: endRef, inView } = useInView();

  const {
    totalRequiredTodosCount,
    unreleasedTodosCount,
  } = useCompletionCriteria();

  useEffect(() => {
    const requestedMode = onlyForCompletion ? 'required' : 'all';
    if (requestedMode !== lastLoadedMode) {
      dispatch(resetTimelineTodo({ catalogId }));
    }
  }, [dispatch, catalogId, onlyForCompletion, lastLoadedMode]);

  useEffect(() => {
    if (inView && !loadingTimelineTodos && hasMore) {
      // fetch the timeline todos
      dispatch(getTimelineTodosList({
        catalogId,
        page: lastLoadedPage + 1,
        onlyForCompletion,
        vieweeId,
      }));
    }
  }, [
    dispatch, catalogId, inView, hasMore, loadingTimelineTodos,
    lastLoadedPage, onlyForCompletion, vieweeId,
  ]);

  const unReleasedCount = !hasMore && totalRequiredTodosCount - todos.length;

  if (!loadingTimelineTodos
    // The following line is important. Need to display No Todos only if
    // the todos is defined (or an empty array). If todos is null, then the
    // latter logic has to work, where the Empty span on bottom is displayed
    // and thus the todos request is send.
    && !!todos
    && todos.length === 0
  ) {
    return (
      <div className='body-text-wrapper'>
        <div className='mt-6 text-center'>
          <NvIcon icon='assignments' size='ultra-large' className='gray-5' />
          <div className='text-regular bold gray-4 mt-4'>
            {t.TIMELINE.NO_TODOS()}
          </div>
        </div>
      </div>
    );
  }

  // /course_outline/todos.json?page=1
  return (
    <div className='body-text-wrapper'>
      <div className='mt-6 text-center card-title'>
        { onlyForCompletion
          ? t.COMPLETION_CRITERIA.COMPLETE_X_REQUIRED_TODOS(totalRequiredTodosCount)
          : t.COMPLETION_CRITERIA.ALL_TODOS()}
      </div>
      {!!onViewAllTodos && (
        <ClickableContainer
          className='mt-2 mb-5 justify-content-center color-primary'
          onClick={onViewAllTodos}
        >
          {t.COMPLETION_CRITERIA.VIEW_ALL_TODOS()}
        </ClickableContainer>
      )}
      <div>
        {todos?.map((todo) => (
          <TimelineActivityRow
            key={`todo-${todo.lectureComponentId}-${todo.id}`}
            activity={todo}
          />
        ))}
        {loadingTimelineTodos && (
          <div className='pt-4'>
            <LoadingPlaceholder loaderType={LoaderType.HALF} />
          </div>
        )}
        {unreleasedTodosCount > 0
          && !hasMore
          && unReleasedCount > 0 && (
            <div className='unreleased-todos py-2'>
              <span className='title muted'>
                {t.COMPLETION_CRITERIA.X_UNRELEASED_TODOS(
                  unReleasedCount,
                )}
              </span>
            </div>
        )}
        <span ref={endRef} />
      </div>
    </div>
  );
};

export default Todos;
