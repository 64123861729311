import { css } from '@emotion/react';
import React, { useState, useContext, Fragment } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import NvPopover from 'shared/components/nv-popover';
import { RootState } from 'redux/schemas';
import { CourseAliases } from 'redux/schemas/models/course';
import { getCourseAliases } from 'redux/selectors/course';
import moment from 'moment';
import { AngularServicesContext } from 'react-app';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { RecordingFormat, VideoPracticeScenario } from 'redux/schemas/models/video-practice';
import InsightsDetails from './insights-details';

type VideoPracticeScenarioCardProps = {
  scenario: VideoPracticeScenario;
  showSubmissionPrivacySetting?: boolean;
  detailedView?: boolean;
  editScenario?: boolean;
  showInsights?: boolean;
  showInsightsKeys?: boolean;
};

type RedirectionProps = {
  catalogId: string;
  displayText: string;
  className?: string;
  collectionId?: number;
};

const VideoPracticeScenarioCard = ({
  scenario,
  showSubmissionPrivacySetting,
  detailedView,
  editScenario,
  showInsights = false,
  showInsightsKeys,
}: VideoPracticeScenarioCardProps) => {
  const styles = css`
    ${detailedView && css`
      padding: ${standardSpacing};
    `}

    .cursor-pointer {
      cursor: pointer;
    }
  `;

  const { $state } = useContext(AngularServicesContext);
  const aliases: CourseAliases = useSelector((state: RootState) => getCourseAliases(state));

  const [showCourses, setShowCourses] = useState(false);

  const isUsedforOneCourse = scenario.numCoursesUsedIn === 1 && scenario.numCollectionsUsedIn === 0;
  const isUsedforOneCollection = scenario.numCoursesUsedIn === 0 && scenario.numCollectionsUsedIn === 1;
  const isUsedforMultipleCourses = scenario.numCoursesUsedIn > 1 && scenario.numCollectionsUsedIn === 0;
  const isUsedforMultipleCollections = scenario.numCoursesUsedIn === 0 && scenario.numCollectionsUsedIn > 1;
  const isUsedforBothCourseAndCollection = scenario.numCoursesUsedIn >= 1 && scenario.numCollectionsUsedIn >= 1;
  const showCourseList = isUsedforMultipleCourses || isUsedforMultipleCollections || isUsedforBothCourseAndCollection;

  const RedirectToCourseHome = ({ catalogId, displayText, className }: RedirectionProps) => (
    <a
      href={$state.href('course-home', { catalogId })}
      target='_blank'
      rel='noreferrer'
      className={`text-primary ${className}`}
    >
      {displayText}
    </a>
  );

  const RedirectToCollectionHome = ({ catalogId, displayText, collectionId, className }: RedirectionProps) => (
    <a
      href={$state.href('content-library-collection-home', { collectionId, catalogId })}
      target='_blank'
      rel='noreferrer'
      className={`text-primary ${className}`}
    >
      {displayText}
    </a>
  );

  const getCourseConnectingText = () => {
    if (isUsedforOneCourse || isUsedforOneCollection) {
      return t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.USED_ONLY_FOR();
    }
    return t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.USED_IN();
  };

  const getCourseCollectionText = () => {
    const courseText = t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.NUM_COURSES({ ...aliases.courseAliases, courseNum: scenario.numCoursesUsedIn });
    const collectionText = t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.NUM_COLLECTIONS(scenario.numCollectionsUsedIn.toString());
    if (isUsedforOneCourse) {
      return (
        <RedirectToCourseHome
          catalogId={scenario.coursesUsedIn[0].catalogId}
          displayText={scenario.coursesUsedIn[0].name}
        />
      );
    }
    if (isUsedforOneCollection) {
      return (
        <RedirectToCollectionHome
          collectionId={scenario.collectionsUsedIn[0].id}
          catalogId={scenario.collectionsUsedIn[0].catalogId}
          displayText={scenario.collectionsUsedIn[0].name}
        />
      );
    }
    if (isUsedforMultipleCourses) {
      return courseText;
    }
    if (isUsedforMultipleCollections) {
      return collectionText;
    }
    return courseText + t.JOIN.TWO.AND() + collectionText;
  };

  const getLectureCreatedOnDate = (isCatalogId) => (isCatalogId
    ? t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.CREATED_ON_DATE_IN(moment(scenario.createdAt).format('MM/DD/YYYY'))
    : t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.CREATED_ON_DATE(moment(scenario.createdAt).format('MM/DD/YYYY')));

  const isCreatedInCollection = scenario.createdInCourse === null && scenario.createdInCollection != null;

  return (
    <div className='my-4' css={styles}>
      <div className='my-2 d-flex'>
        <i className='icon icon-smallest icon-new-course warning d-inline mr-2' />
        <div className='d-inline'>
          {isCreatedInCollection ? (
            <Fragment>
              {getLectureCreatedOnDate(scenario.createdInCollection?.catalogId)}
              <RedirectToCollectionHome
                collectionId={scenario.createdInCollection.id}
                catalogId={scenario.createdInCollection.catalogId}
                displayText={scenario.createdInCollection.name}
                className='font-weight-bold ml-1'
              />
              <span className='text-small text-gray-3 ml-1'>
                {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.COLLECTION()}
              </span>
            </Fragment>
          ) : (
            <Fragment>
              {getLectureCreatedOnDate(scenario.createdInCourse?.catalogId)}
              <RedirectToCourseHome
                catalogId={scenario.createdInCourse?.catalogId}
                displayText={scenario.createdInCourse?.name}
                className='font-weight-bold ml-1'
              />
              <span className='text-small text-gray-3 ml-1'>
                {scenario.createdInCourse?.catalogId}
              </span>
            </Fragment>
          )}
        </div>
      </div>
      <div className='my-2 d-flex'>
        <i className='icon icon-smallest icon-course warning d-inline mr-2' />
        <div className='d-inline'>
          {getCourseConnectingText()}
          <span
            onClick={() => showCourseList && setShowCourses(show => !show)}
            className='cursor-pointer text-primary font-weight-bold ml-1'
          >
            {getCourseCollectionText()}
          </span>
          <span className='text-small text-gray-3 ml-1'>
            {isUsedforOneCollection && `(${t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.COLLECTION()})`}
            {(isUsedforOneCourse && scenario.createdInCourse?.catalogId) && `(${scenario.createdInCourse?.catalogId})`}
          </span>
          {showCourses && (
            <Fragment>
              <ul className='mt-1 mb-0'>
                {scenario.coursesUsedIn.map((course) => (
                  <li key={course?.catalogId} className='ml-2 mb-1 text-small'>
                    <RedirectToCourseHome
                      catalogId={course?.catalogId}
                      displayText={course.name}
                    />
                    <span className='ml-1'>({course.catalogId})</span>
                  </li>
                ))}
              </ul>
              <ul className='mt-1 mb-0'>
                {scenario.collectionsUsedIn.map((collection) => (
                  <li key={collection?.catalogId} className='ml-2 mb-1 text-small'>
                    <RedirectToCollectionHome
                      collectionId={collection?.id}
                      catalogId={collection?.catalogId}
                      displayText={collection?.name}
                    />
                    <span className='ml-1'>({t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.COLLECTION()})</span>
                  </li>
                ))}
              </ul>
              {scenario.numCoursesUsedIn - scenario.coursesUsedIn.length > 0 && <div className='ml-5 pl-1 text-small'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.VIEW_PRACTICE_SCENARIO.PLUS_X_MORE_COURSES({ ...aliases.courseAliases, num: scenario.numCoursesUsedIn - scenario.coursesUsedIn.length })}</div>}
            </Fragment>
          )}
        </div>
      </div>
      <div className='my-2 d-flex'>
        <i className='icon icon-smallest icon-media-practice warning d-inline mr-2' />
        <div>
          <div className='d-inline'>{scenario.recordingFormat === RecordingFormat.AUDIO
            ? t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.ALLOWED_SUBMISSION_TYPE_SETTING.SUBMISSION_TYPE_AUDIO()
            : t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.ALLOWED_SUBMISSION_TYPE_SETTING.SUBMISSION_TYPE_VIDEO()}
          </div>
          <NvPopover
            showOnHover
            placement='top'
            className='d-inline ml-1 align-bottom'
            content={<div>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.ALLOWED_SUBMISSION_TYPE_SETTING.HELP_TEXT()}</div>}
          >
            <i className='icon icon-xss-smallest icon-info text-primary d-inline' aria-hidden='true' />
          </NvPopover>
        </div>
      </div>
      {showSubmissionPrivacySetting && (
        <div className='my-2 d-flex'>
          <i className='icon icon-smallest icon-share warning d-inline mr-2' />
          <div>
            <div className='d-inline'>
              {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SUBMISSION_PRIVACY_SETTING[scenario.privacy.toUpperCase()]({ ...aliases.teachingTeamAliases, ...aliases.learnersAliases })}
            </div>
            <NvPopover
              showOnHover
              placement='top'
              className='d-inline ml-1 align-bottom'
              content={scenario.privacy === 'shared'
                ? <div>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SUBMISSION_PRIVACY_SETTING.SHARED_TOOLTIP({ ...aliases.learnersAliases, ...aliases.courseAliases })}</div>
                : <div>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SUBMISSION_PRIVACY_SETTING.RESTRICTED_TOOLTIP({ ...aliases.learnersAliases, ...aliases.courseAliases })}</div>}
            >
              <i className='icon icon-xss-smallest icon-info text-primary d-inline' aria-hidden='true' />
            </NvPopover>
          </div>
        </div>
      )}
      <div className='my-2 d-flex'>
        <i className={`icon icon-smallest icon-${scenario.reusable === 'reusable' ? 'sharable' : 'duplicate'} warning d-inline mr-2`} />
        <div>
          <div className='d-inline'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SCENARIO_REUSABILITY_SETTING[scenario.reusable.toUpperCase()]({ ...aliases.courseAliases })}</div>
          <NvPopover
            showOnHover
            placement='top'
            className='d-inline ml-1 align-bottom'
            content={scenario.reusable === 'reusable'
              ? <div>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SCENARIO_REUSABILITY_SETTING.REUSABLE_TOOLTIP({ ...aliases.learnersAliases, ...aliases.courseAliases })}</div>
              : <div>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SCENARIO_REUSABILITY_SETTING.NOT_REUSABLE_TOOLTIP()}</div>}
          >
            <i className='icon icon-xss-smallest icon-info text-primary d-inline' aria-hidden='true' />
          </NvPopover>
        </div>
      </div>
      {/* REQUEST REVIEWS SECTION */}
      {!editScenario && (
        <div className='my-2 d-flex'>
          <i className='icon icon-smallest icon-course-mentees warning d-inline mr-2' />
          <div>
            <div className='d-inline'>
              {scenario.enableRequestReview
                ? t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.REQUEST_REVIEWS.ENABLED_PRACTICE_OVERVIEW()
                : t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.REQUEST_REVIEWS.DISABLE_REQUEST_REVIEW()}
            </div>
          </div>
        </div>
      )}
      {showInsights && <InsightsDetails scenario={scenario} showInsightsKeys={showInsightsKeys} />}
    </div>
  );
};

export default VideoPracticeScenarioCard;
