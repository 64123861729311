import React from 'react';
import Badge from 'shared/components/nv-badge';

// Styles
import { css } from '@emotion/react';
import { black, gray2, gray6, white } from 'styles/global_defaults/colors';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { ModalType, NvModalProps } from './nv-modal';

type SkillViewModalProps = {
  header: string,
  skillTags: any[],
};

type SkillViewModal = ({ header, skillTags }: SkillViewModalProps) => NvModalProps;

export const getModalSettingsForSkillView: SkillViewModal = ({ header, skillTags }) => ({
  header,
  type: ModalType.DYNAMIC,
  body: <NvSkillsView skillTags={skillTags} theme={NvSkillViewTheme.DARK} />,
  width: 600,
  fullHeight: false,
});

export enum NvSkillViewTheme {
  LIGHT = 'light',
  DARK = 'dark',
};

const themes = {
  [NvSkillViewTheme.LIGHT]: {
    backgroundColor: white,
    borderColor: gray2,
    color: black,
  },
  [NvSkillViewTheme.DARK]: {
    backgroundColor: gray6,
    borderColor: gray6,
    color: black,
  },
};

type NvSkillViewProps = {
  skillTags: { id: number, name: string }[],
  theme?: NvSkillViewTheme,
};

const NvSkillsView = (props: NvSkillViewProps) => {
  const { skillTags, theme = NvSkillViewTheme.LIGHT } = props;
  const { backgroundColor, borderColor, color } = themes[theme];

  const styles = css`
    gap: ${halfSpacing}px;
    flex-wrap: wrap;
    span {
      overflow: hidden;
      white-space: nowrap;
    }
  `;

  return (
    <div className='d-flex justify-content-center' css={styles}>
      {skillTags?.map(({ id, name }) => (
        <Badge
          key={id}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          color={color}
        >
          {name}
        </Badge>
      ))}
    </div>
  );
}

export default NvSkillsView;
