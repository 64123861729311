/* @ngInject */
export default function MathjaxLoader(

  $q,
  $timeout,
) {
  let promise;

  return {
    load() {
      if (!promise) {
        const deferred = $q.defer();

        $.getScript('//cdn.rawgit.com/mathjax/MathJax/2.7.1/MathJax.js').done(() => {
          deferred.resolve();
        });

        ({ promise } = deferred);
      }

      return promise;
    },
    mathjaxize($element) {
      this.load().then(() => {
        $timeout(() => {
          if (window.MathJax) {
            window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, $element[0]]);
            window.MathJax.Hub.Queue(() => {
              $element.css('visibility', 'visible');
            });
          }
        });
      });
    },
  };
}
