import { css } from '@emotion/react';
import { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import t from 'react-translate';
import { NvModalProps } from 'shared/components/nv-modal';
import _ from 'underscore';
import BookmarkIcon from 'bookmarks/components/bookmark-icon';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { BookmarkProps } from 'redux/schemas/models/bookmark';
import { gray4, primary } from 'styles/global_defaults/colors';
import ImageComponentImage, { ImageComponentImageProps } from './image-component-image';

type ImageViewModalProps = Pick<NvModalProps, 'show' | 'onClose'> & ImageComponentImageProps & BookmarkProps;

export const ImageViewModal = (props: ImageViewModalProps) => {
  const [containerRef, setContainerRef] = useState(null);
  const container = useCallback(node => {
    if (props.show) {
      setContainerRef(node);
    }
  }, [props.show]);

  if (!props.show) {
    return null;
  }

  const styles = css`
    position: absolute;
    top: 0;
    left: 0;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    background-color: white;
    /** TODO : This component was thrown together very quickly to support the ImageLectureComponent release.
    We need to be more mindful about these z-indices and have them defined in a central file somewhere. */
    z-index: 10000;

    .bookmark-icon {
      margin-right: ${standardSpacing}px;
    }

    .top-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: ${gray4};
      color: white;
      height: 35px;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 100;

      .back-button {
        display: flex;
        align-items: center;
        padding: ${halfSpacing}px;
        background-color:unset;
        border:none;
        color: white;
        &:hover {
          cursor: pointer;
          color: ${primary};
        }
      }

      .bookmark-icon:hover {
        color: ${primary};
      }
    }

    .img-component-container {
      margin-top: 35px;
    }
  `;

  // Force this to appear on top of all other content.
  // This is a bit of hack for sake of time. Long-term we should support this UI in a more reusable format
  return ReactDOM.createPortal(
    <div className='react-app'>
      <div css={styles}>
        <div className='top-bar'>
          <button type='button' className='back-button' onClick={() => props.onClose()} aria-label={t.LECTURE_PAGES.COMPONENTS.SCORM.EXIT_FULL_SCREEN()}>
            <span className='icon icon-xss-smallest icon-collapse pr-2' aria-hidden='true' />
            <span className='text-small'>{t.LECTURE_PAGES.COMPONENTS.SCORM.EXIT_FULL_SCREEN()}</span>
          </button>
          <BookmarkIcon
            size='smallest'
            bookmarked={!!props.bookmarkId}
            onCreate={props.onCreateBookmark}
            onHighlight={props.onHighlightBookmark}
            colors={{
              default: 'white',
              bookmarked: 'white',
            }}
          />
        </div>
        <div className='img-component-container w-100' ref={container}>
          <ImageComponentImage
            bookmarkHidden
            {...props}
            fitToContainer
            maxImageWidth={800}
            sizeContainerRef={{ current: containerRef }}
          />
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default ImageViewModal;
