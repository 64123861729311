import { css } from '@emotion/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import t from 'react-translate';

// Components
import LoadingWrapper, { LoaderType } from 'shared/components/loading-wrapper';
import PracticeSubmission from 'practice_room/components/shared/practice-submission';
import usePrevious from 'shared/hooks/use-previous';

import { RootState } from 'redux/schemas';
import { SubmissionTab, VideoPracticeScenario, VideoPracticeSubmission } from 'redux/schemas/models/video-practice';
import { getSubmission } from 'redux/selectors/video-practice';
import { getFirstSubmission, getNextAndPrevPendingSubmissions, nextAndPrevSubmissions, nextAvailableSubmission } from 'redux/selectors/video-practice-feedback';
import { getComments, getPracticeSubmission, getScenario, resetPracticeComments } from 'redux/actions/video-practice';
import { setShowFeedbackInstruction } from 'redux/actions/video-practice-feedback';

import { SubmissionPreview, SubmittedUser } from 'practice_room/components/submission-overview/submission-overview';
import VideoPracticeOverview from 'practice_room/components/scenario-overview/video-practice-overview';
import SubmissionSlider from 'practice_room/components/shared/user-submission-slider';
import { handheld, smallDesktop } from 'styles/global_defaults/media-queries';
import { extraLargeSpacing } from 'styles/global_defaults/scaffolding';
import { getSkillTagsFromTaggings, sortSkillTags } from 'redux/selectors/skills-feedback';
import { ComponentType, LectureComponent } from 'redux/schemas/models/lecture-component';
import _ from 'underscore';
import { config } from '../../../../../config/pendo.config.json';

const styles = css`
  &.modal-page {
    ${smallDesktop(css`
    width: calc(100% - 2 * 120px) !important;
    left: 120px !important;
  `)};
  }
  .overview-container {
    max-width: 800px;
    min-height: 600px;
  }
  .header {
    width: 100%;
    max-height: 165px !important;
    top: 0px !important;
  }
  .comment {
    /*rtl:ignore*/
    right: ${2 * extraLargeSpacing}px;
    ${handheld(css`
      right: 5%;
    `)}
  }
`;

interface PracticeFeedbackOverviewProps {
  submissionId: number
  scenarioId: number
  lectureComponentId?: number
  isSkillsFeedbackActivity?: boolean
  practiceActivity?: any
  onClose?: () => void
  isSupervisorReview?: boolean
  selectedViewProp?: SubmissionTab
  filterByScenarioId?: number
}

const PracticeFeedbackOverview = ({
  submissionId,
  scenarioId,
  lectureComponentId,
  isSkillsFeedbackActivity,
  practiceActivity,
  onClose,
  isSupervisorReview,
  selectedViewProp,
  filterByScenarioId,
}: PracticeFeedbackOverviewProps) => {
  const [selectedSubmissionId, setSelectedSubmissionId] = useState(submissionId);
  const [selectedScenarioId, setSelectedScenarioId] = useState(scenarioId);

  const scenario = useSelector<RootState, VideoPracticeScenario>(state => state.models.practiceScenarios[selectedScenarioId]);
  const submission = useSelector<RootState, VideoPracticeSubmission>(state => getSubmission(state, selectedSubmissionId));
  const { nextPendingSubmission, prevPendingSubmission } = useSelector<RootState, SubmissionPreview>((state) => getNextAndPrevPendingSubmissions(state, selectedSubmissionId, filterByScenarioId));
  const { next, prev } = useSelector<RootState, SubmissionPreview>(
    state => nextAndPrevSubmissions(state, selectedSubmissionId),
  );
  const nextAvailable = useSelector(state => nextAvailableSubmission(state, selectedSubmissionId));
  const firstSubmission = useSelector(state => getFirstSubmission(state));

  const prevSubmission = prev || prevPendingSubmission;
  const nextSubmission = next || nextPendingSubmission;

  const showFeedbackInstruction = useSelector<RootState, boolean>(
    state => state.app.videoPracticeFeedback.showFeedbackInstruction,
  );
  const practiceFeedbackCriteriaId = useSelector<RootState, boolean>(
    state => state.app.videoPracticeFeedback.practiceFeedbackCriteriaId,
  );
  const skillTaggings = useSelector<RootState, any>(state => state.models.skillTaggings) ?? {};
  const lectureComponent: LectureComponent = useSelector((state: RootState) => state.models.lectureComponents[lectureComponentId]);
  const skillTags = useSelector((state: RootState) => (
    practiceActivity?.scenario?.skillTaggings
      ? sortSkillTags(practiceActivity?.scenario?.skillTaggings?.map(skillTagging => skillTagging.skillTag)) ?? []
      : getSkillTagsFromTaggings(state, scenario?.skillTaggingIds ?? [])
  ));

  const practiceFeedbackRef = useRef();
  const isPracticeFeedback = isSupervisorReview ? false : lectureComponent?.type !== ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK;

  const dispatch = useAppDispatch();

  const prevSubmissionId = usePrevious(selectedSubmissionId);

  useEffect(() => {
    if (selectedSubmissionId && selectedScenarioId) {
      if (!submission) {
        dispatch(getPracticeSubmission({
          submissionId: selectedSubmissionId,
          scenarioId: selectedScenarioId,
          feedbackCriteriaId: isSkillsFeedbackActivity ? practiceFeedbackCriteriaId : undefined,
        }));
      }
    }
  }, [dispatch, selectedScenarioId, selectedSubmissionId, submission]);

  useEffect(() => {
    const scenarioSkillTaggings = scenario?.skillTaggingIds;
    const checkIfUpdated = () => {
      const skillTaggingIds = Object.values(skillTaggings).map(({ id }) => id);
      return scenarioSkillTaggings.every((id) => skillTaggingIds.includes(id));
    };
    const areSkillTaggingsUpdated = scenarioSkillTaggings?.length ? checkIfUpdated() : true;

    if (!scenario
      || (scenario?.skillTaggingIds?.length && !skillTags.length)
      || (_.isEmpty(skillTaggings) && isSkillsFeedbackActivity)
      || !areSkillTaggingsUpdated
    ) {
      dispatch(getScenario({ scenarioId: selectedScenarioId }));
    }
  }, [dispatch, scenario, selectedScenarioId]);

  useEffect(() => {
    if (((selectedSubmissionId && practiceFeedbackCriteriaId) || (isSupervisorReview && selectedSubmissionId))) {
      dispatch(getComments({ submissionId: selectedSubmissionId, practiceFeedbackCriteriaId, page: 1 }));
    }
  }, [dispatch, practiceFeedbackCriteriaId, selectedSubmissionId]);

  useEffect(() => {
    // Reset comments on exiting submission view, to get latest data
    if (prevSubmissionId) {
      dispatch(resetPracticeComments({ submissionId: prevSubmissionId }));
    }
  }, [dispatch, prevSubmissionId]);

  const onSlide = useCallback((id: number, newScenarioId: number) => {
    setSelectedSubmissionId(id);
    setSelectedScenarioId(newScenarioId);
    if (showFeedbackInstruction && selectedSubmissionId) {
      dispatch(setShowFeedbackInstruction({ show: false }));
    }
  }, [dispatch, selectedSubmissionId, showFeedbackInstruction]);

  return (
    <div
      className='bg-white modal-page'
      css={styles}
      ref={practiceFeedbackRef}
    >
      <LoadingWrapper
        isLoaded={!!scenario && !!submission}
        loaderType={LoaderType.PLACEHOLDER}
        className='overview-container w-100 mx-auto my-6'
        keepRendered={false}
      >
        <VideoPracticeOverview isPracticeFeedback scenarioId={selectedScenarioId}>
          <SubmissionSlider
            isNext={!!nextSubmission}
            isPrev={!!prevSubmission}
            onNext={() => onSlide(nextSubmission.id, nextSubmission?.scenario?.id)}
            onPrev={() => onSlide(prevSubmission.id, prevSubmission?.scenario?.id)}
            prevPreview={prevSubmission?.videoFile?.thumbnailUrl ?? ''}
            nextPreview={nextSubmission?.videoFile?.thumbnailUrl ?? ''}
            showPopover={!!nextSubmission}
            popoverContent={(
              <div className='text-small'>
                {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.INSTRUCTION.NEXT()}
              </div>
            )}
            dataQaNext={config.pendo.practiceFeedback.nextSubmission}
            dataQaPrev={config.pendo.practiceFeedback.prevSubmission}
          >
            <div className='overview-container d-flex justify-content-center mb-6 mx-auto'>
              {submission && (
                <div className='d-flex flex-column w-100'>
                  <div className='border-bottom border-gray-4 py-4 mb-5'>
                    <SubmittedUser
                      user={submission?.user}
                      isTeamMember={submission.isTeamMember}
                      teamName={submission.teamName}
                    />
                  </div>
                  <PracticeSubmission
                    submissionId={selectedSubmissionId}
                    isPracticeRoom={false}
                    isPracticeFeedback={isPracticeFeedback}
                    scenarioId={selectedScenarioId}
                    skillTags={skillTags}
                    lectureComponentId={lectureComponentId}
                    onClose={onClose}
                    isNext={!!nextSubmission}
                    onNext={() => onSlide(nextAvailable?.id, nextAvailable?.scenario?.id)}
                    onFirst={() => onSlide(firstSubmission?.id, firstSubmission?.scenario?.id)}
                    selectedViewProp={selectedViewProp}
                    isSupervisorReview={isSupervisorReview}
                  />
                </div>
              )}
            </div>
          </SubmissionSlider>
        </VideoPracticeOverview>
      </LoadingWrapper>
    </div>
  );
};

export default PracticeFeedbackOverview;
