import angular from 'angular';
import { BookmarkType } from 'redux/schemas/models/bookmark';
import NvSwitch from 'shared/components/inputs/nv-switch';
import t from '../../../react-translate';

export default {
  bindings: {
    lectureComponent: '<',
    editMode: '<',
    restrictedEditMode: '<',
    reorderMode: '<',
    index: '<',
    context: '<',
  },
  controller: function ctrl(
    _,
    BookmarkModel,
    $controller,
    $translate,
    $stateParams,
    config,
  ) {
'ngInject';
    angular.extend(this, $controller('LectureComponentBaseCtrl'));
    const vm = this;

    // Checks if component is not already embeded on lecture page and if file type
    // is not one of disallowed file types for embedding
    vm.embedOnLecturePage = () => {
      let result = !vm.lectureComponent.viewOptions.embeded;

      if (vm.lectureComponent.attachment?.fileType) {
        result = result && !_.contains([config.files.zips], vm.lectureComponent.attachment.fileType);
      }

      if (vm.lectureComponent.attachment?.file?.type) {
        result = result && !_.contains([config.files.zips], vm.lectureComponent.attachment.file.type);
      }

      return result;
    };

    vm.openInLightBox = () => vm.lectureComponent.viewOptions.embeded;

    vm.hasEmbeddingStyleOptions = () => vm.lectureComponent.hasEmbeddingStyleOptions();

    vm.dropdownHasChildren = () => vm.embedOnLecturePage() || vm.openInLightBox() || vm.hasEmbeddingStyleOptions();

    vm.createBookmark = () => {
      BookmarkModel.createBookmark({
        type: BookmarkType.ATTACHMENT,
        catalogId: $stateParams.catalogId,
        componentId: vm.lectureComponent.attachment.id,
      })
        .then(bookmark => {
          vm.lectureComponent.attachment.bookmarkId = bookmark.id;
        });
    };

    vm.highlightBookmark = () => {
      BookmarkModel.highlightBookmark(vm.lectureComponent.attachment.bookmarkId);
    };

    /**
     * divider, and header dropdown items don't get ignored in the dropdown
     * selected index calculation. So we have to offset this value by 4 to
     * account for those.
     */
    vm.getAspectRatioListActiveIndex = () => (vm.hasEmbeddingStyleOptions()
      ? vm.lectureComponent.constructor.embeddingStyleOptions.findIndex(
        option => option.name === vm.lectureComponent.attachment.file.embeddingStyle,
      ) + 4 : 0
    );

    vm.setEditMenu = () => {
      if (!vm.lectureComponent.attachment) {
        return;
      }

      const { sharedProps } = this.context;
      const extraOptions = [];

      if (vm.embedOnLecturePage() || vm.openInLightBox()) {
        extraOptions.push({
          type: 'custom',
          customItem: (
            <div className='d-flex justify-content-between align-items-center py-1 px-3 text-small'>
              <span>
                {t.LECTURE_PAGES.COMPONENTS.ATTACHMENT_LIST.EMBED_ON_LECTURE_PAGE(
                  { lectureAlias: vm.CurrentCourseManager.course.lectureName.capitalizedSingularized },
                )}
              </span>

              <NvSwitch
                checked={vm.lectureComponent.viewOptions.embeded}
                onClick={(e) => {
                  vm.lectureComponent.toggleEmbedOnLecturePage(e);
                  vm.setEditMenu();
                }}
              />
            </div>
          ),
        },
        {
          type: 'custom',
          customItem: (
            <div className='d-flex justify-content-between align-items-center py-1 px-3 text-small'>
              <span>
                {t.LECTURE_PAGES.COMPONENTS.ATTACHMENT_LIST.ALLOW_FILE_DOWNLOAD()}
              </span>

              <NvSwitch
                checked={vm.lectureComponent.attachment.file.isDownloadable}
                onClick={(e) => {
                  vm.lectureComponent.toggleDownloadableStatus(e);
                  vm.setEditMenu();
                }}
              />
            </div>
          ),
        });
      }

      if (vm.hasEmbeddingStyleOptions()) {
        extraOptions.push(
          { type: 'divider' },
          {
            type: 'header',
            title: t.LECTURE_PAGES.COMPONENTS.ATTACHMENT_LIST.EMBEDDING_STYLE.HEADER(),
          },
        );

        vm.lectureComponent.constructor.embeddingStyleOptions.forEach(elementStyle => {
          if (vm.lectureComponent.isEmbeddingStyleAvailable(elementStyle.name)) {
            extraOptions.push({
              type: 'text',
              callback: () => {
                vm.lectureComponent.updateEmbeddingStyle(elementStyle.name);
                vm.setEditMenu();
              },
              text: $translate.instant(elementStyle.translateKey),
            });
          }
        });
      }

      sharedProps.extraOptions = {
        ...sharedProps.extraOptions,
        ...{
          options: extraOptions,
          showActive: true,
          initialSelectedIndex: vm.getAspectRatioListActiveIndex(),

        },
      };
    };

    vm.setEditMenu();
  },
  controllerAs: 'vm',
  templateUrl: 'lecture_pages/templates/components/nv-attachment-lecture-component.html',
};
