import store from 'redux/store';
import { ActivityKey } from 'redux/schemas/models/activity';
import { setActivityProgress } from 'redux/actions/lecture-components';

/* @ngInject */
export default function TimelineExerciseSkillsRatingLectureComponentModelService(
  _,
  moment,
  nvUtil,
) {
  const NOT_STARTED = 'not_started';
  const IN_PROGRESS = 'in_progress';
  const COMPLETED = 'completed';
  const MISSED = 'missed';

  class TimelineExerciseSkillsRatingLectureComponent {
    constructor(attributes) {
      const base = {
        templateUrl: 'timelines/templates/nv-timeline-lecture-exercise-skills-rating.html',
        canHaveHardDeadline: true,
      };

      /** Setting Up Basic Attributes * */
      _.extend(this, base, attributes);
      // passed lecturePage, lectureSection, course
      this.preprocess();
    }

    /** Function Declarations * */
    /* Used Publicly */
    getStatus() {
      return this.skillsRating.progress;
    }

    isInProgress() {
      return this.skillsRating.progress === IN_PROGRESS;
    }

    isCompleted() {
      return this.skillsRating.progress === COMPLETED;
    }

    isMissed() {
      return this.skillsRating.progress === MISSED;
    }

    isTodoRequiredForCompletion() {
      return this.skillsRating.isRequiredForCompletion;
    }

    isPrereqCompleted(prereq) {
      if (prereq.type === 'SkillsRatingFeedbackCriteria' && prereq.id === this.skillsRating.id) {
        return this.isCompleted();
      }
      return false;
    }

    updateComponentPointsAndProgress(componentType, componentId, pointsReceived, totalPoints, progress) {
      if ((this.type === componentType && this.id === componentId)
        || (componentType === 'exercise_skills_rating' && this.skillsRating.id === componentId)
      ) {
        this.skillsRating.progress = progress ?? 'completed';

        store.dispatch(setActivityProgress({
          pointsReceived,
          progress: this.skillsRating.progress,
          activityKey: ActivityKey.VIDEO_PRACTICE_FEEDBACK,
          activityPayloadId: this.skillsRating.id,
        }));

        this.skillsRating.pointsReceived = pointsReceived;
        this.pointsReceived = pointsReceived;

        if (_.isNumber(totalPoints)) {
          this.currentTotalPoints = totalPoints;
        } else {
          this.currentTotalPoints = nvUtil.getCurrentTotalPoints(this.totalPoints, this.lecturePage.releaseDate, this.course.expiringPointsEnabled);
        }

        this.viewPreprocess();

        this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    updateComponentProgress(componentType, componentId, progress) {
      if ((this.type === componentType && this.id === componentId) || (componentType === 'exercise_skills_rating' && this.skillsRating.id === componentId)) {
        this.skillsRating.progress = progress;
        store.dispatch(setActivityProgress({
          progress,
          activityKey: ActivityKey.VIDEO_PRACTICE_FEEDBACK,
          activityPayloadId: this.skillsRating.id,
        }));
        this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    getPayload() {
      return _.pick(this, ['title', 'deadline', 'hardDeadline']);
    }

    hasDeadline() {
      return !!this.skillsRating.deadline;
    }

    setDeadline(newDeadline) {
      this.skillsRating.deadline = newDeadline;
      this.deadline = newDeadline;
    }

    isDeadlineBeforeLesson() {
      return this.skillsRating.deadline < this.lecturePage.releaseDate;
    }

    /* Used Privately */
    preprocess() {
      if (!this.skillsRating) {
        return;
      }

      if (this.skillsRating?.deadline) {
        this.deadlineMoment = moment(this.skillsRating.deadline);
      }

      _.extend(this, _.pick(this.skillsRating, 'title', 'deadline', 'hardDeadline', 'isTodo', 'hasStructuralIssues',
        'expirationDate', 'expired', 'pointsReceived', 'totalPoints', 'progress', 'scheduledCommunicationsCount'));
      this.activityId = this.skillsRating.id;

      this.currentTotalPoints = nvUtil.getCurrentTotalPoints(this.skillsRating.totalPoints, this.skillsRating.releaseDate,
        this.course.expiringPointsEnabled, this.skillsRating.expired);

      this.viewPreprocess();
    }

    viewPreprocess() {
      this.showDueSoon = this.showDueSoon();

      this.displayTotalPointsOnly = !this.pointsReceived && this.currentTotalPoints && !this.isMissed();
    }

    showDueSoon() {
      if (this.skillsRating.deadline) {
        const currentMoment = moment();
        return (this.getStatus() === 'in_progress' || this.getStatus() === 'not_started')
            && moment(this.lecturePage.releaseDate) < currentMoment
            && this.deadlineMoment > currentMoment
            && this.deadlineMoment < moment().add(7, 'days');
      }
      return false;
    }

    showWarning() {
      return this.skillsRating.exercise && moment(this.lecturePage.releaseDate) < moment(this.skillsRating.exercise.releaseDate);
    }

    showPointsDenominator() {
      return this.currentTotalPoints > this.skillsRating.pointsReceived && !this.isCompleted() && !this.isMissed();
    }

    updateDependentActivitiesReleaseDate(changedLecturePage) {
      if (this.skillsRating.exercise?.lecturePageId === changedLecturePage.id) {
        this.skillsRating.exercise.releaseDate = changedLecturePage.releaseDate;
      }
    }
  }

  return TimelineExerciseSkillsRatingLectureComponent;
}
