import t from 'react-translate';
import { InputType } from 'components/nv-click-to-edit';
import { MigratedFields } from 'redux/schemas/models/account-fields';
import en_US from '../../languages/en_US';

/**
 * Returns default field name according to account level field identifier
 * provided.
 */
// eslint-disable-next-line consistent-return
export const getName = (fieldIdentifier: string) => {
  switch (fieldIdentifier) {
    case 'bio':
      return t.PROFILE.BIO();
    case 'headline':
      return t.PROFILE.HEADLINE();
    case 'location':
      return t.PROFILE.BIO_QUESTIONS.LOCATION();
    default: break;
  }
};

/**
 * Returns default input type according to account level field identifier
 * provided.
 */
// eslint-disable-next-line consistent-return
export const getInputType = (fieldIdentifier: string) => {
  switch (fieldIdentifier) {
    case 'bio':
      return InputType.LONG_TEXT;
    case 'headline':
      return InputType.SHORT_TEXT;
    case 'location':
      return InputType.LOCATION;
    default: break;
  }
};

/**
 * Returns default field name according to migrated field provided.
 * It's called placeholder because it's being used as placeholder in Org Level
 * Profile configuration modal.
 * @param {boolean} shouldTranslate - Translate to all supported languages if true,
 * otherwise only return the English text. eg: Org level user upload only uses English
 * content on the CSV template header - https://novoed.atlassian.net/browse/NOV-73428
 */
export const getPlaceholder = (migratedField: string | null, shouldTranslate = true) => {
  if (migratedField) {
    const lang = shouldTranslate ? t : en_US;
    switch (migratedField) {
      case MigratedFields.WORK:
        return lang.ORG_LEVEL_PROFILE.ORG_FIELDS_DEFAULT_TEXTS.WORK();
      case MigratedFields.EDUCATION:
        return lang.ORG_LEVEL_PROFILE.ORG_FIELDS_DEFAULT_TEXTS.EDUCATION();
      case MigratedFields.JOB_TITLE:
        return lang.ORG_LEVEL_PROFILE.ORG_FIELDS_DEFAULT_TEXTS.JOB_TITLE();
      default: break;
    }
  }

  return null;
};
