/* @ngInject */
export default function NvRatioMinHeight($timeout, $parse, _) {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      scope.$watch(
        () => (element.width() * 9) / 16,
        (newValue, oldValue) => {
          element.css('min-height', newValue);
        },
      );
    },
  };
}
