import { useLocation } from 'react-router-dom';

// A custom hook that builds on useLocation to parse
// the query string from the current url
/* @ngInject */
export function useQuery() {
  const params: any = {};

  try {
    const urlParams = new URLSearchParams(useLocation().search);
    urlParams.forEach((value, key) => {
      params[key] = value;
    });
  } catch (err) {
    // console.log(err);
    return params;
  }
  return params;
}

/* @ngInject */
export function makeQueryString(object: { [key: string]: any }) {
  Object.keys(object).forEach((k) => object[k] == null && delete object[k]);
  return `?${new URLSearchParams(object).toString()}`;
}
