import React from 'react';

const useDebouncedEffect = (effect, delay, deps) => {
  const timeoutRef = React.useRef<any>();
  const effectRef = React.useRef(effect);

  React.useEffect(() => {
    effectRef.current = effect;
  });

  React.useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => effectRef.current(), delay);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, ...deps]);
};

export default useDebouncedEffect;
