import axios from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { Todo } from 'redux/schemas/models/todos';

export const setTodos = createAction<Todo[]>('SET_TODOS');

export const getCurrentUserTodos = createAsyncThunk(
  'GET_CURRENT_USER_TODOS',
  async (arg, thunkAPI) => {
    const todos = (await axios.get('/users/todos.json')).data.result;

    thunkAPI.dispatch(setTodos(todos));

    return todos.map((todo) => `${todo.id}-${todo.type}`);
  },
);
