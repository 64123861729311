/* @ngInject */
export default function NvPixelOnly(
) {
  return {
    require: 'ngModel',
    link(scope, elem, attr, ngModel) {
      if (attr.nvPixelOnly === 'true') {
        ngModel.$parsers.push((value) => {
          if (/^\d+$/.test(value)) {
            value = `${value}px`;
          }

          return value;
        });

        ngModel.$validators.validPx = function (modelValue, viewValue) {
          const value = modelValue || viewValue;
          let isValid = false;

          if (ngModel.$isEmpty(value)) {
            isValid = true;
          } else if (/^\d+px$/.test(value)) {
            isValid = true;
          } else {
            isValid = false;
          }

          return isValid;
        };
      }
    },
  };
}
