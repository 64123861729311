/**
 * For points listing, the UI differs from the timeline-activity-row
 */

import React, { useContext } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { AngularServicesContext } from 'react-app';
import t from 'react-translate';

import { RootState } from 'redux/schemas';
import { useAppDispatch } from 'redux/store';
import { resetShowPortableCompletionPanel } from 'redux/actions/timeline';
import { AwardedPoint, TimelineActivity } from 'redux/schemas/models/activity';

import { gray5, primary } from 'styles/global_defaults/colors';
import { doubleSpacing, halfSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import ClickableContainer from 'components/clickable-container';
import NvIcon from 'shared/components/nv-icon';
import { config } from '../../../../config/pendo.config.json';

type TimelinePointProps = {
  activity?: TimelineActivity,
  awarded?: AwardedPoint,
};

const ActivityRow = ({ activity }: { activity: TimelineActivity }) => {
  const styles = css`
    border-bottom: 1px dashed ${gray5};
    gap: ${doubleSpacing}px;
    min-height: 60px;

    &:hover {
      .activity-title-text {
        color: ${primary};
      }
    }

    .points-row-activity-points {
      gap: ${quarterSpacing}px;

      .activity-partial-points {
        text-wrap: nowrap;
      }
    }
  `;

  const dispatch = useAppDispatch();
  const catalogId = useSelector((state: RootState) => state.app.currentCatalogId);

  const { $state } = useContext(AngularServicesContext);

  const goToLectureActivity = () => {
    dispatch(resetShowPortableCompletionPanel());
    $state.go('lecture-page', {
      catalogId,
      id: activity.lecturePageId,
      lectureActivityId: activity.lectureComponentId,
    });
  };

  let pointsDisplay = (
    <span className='label text-success'>
      {activity.pointsReceived}
    </span>
  );

  if (activity.totalPoints && activity.pointsReceived < activity.totalPoints[0]) {
    // That means this is partial point gained
    pointsDisplay = (
      <div className='activity-partial-points'>
        <span className='label text-success'>
          {activity.pointsReceived}
        </span>
        <span className='text-xsmall text-gray-2'>
          {`/${activity.totalPoints[0]}`}
        </span>
      </div>
    );
  }

  return (
    <ClickableContainer
      css={styles}
      className='activity-item d-flex align-items-center justify-content-between px-4 py-2'
      onClick={goToLectureActivity}
      data-qa={config.pendo.outline.timeline.pointRow}
    >
      <div className='activity-title-text text-regular'>
        {activity.title}
      </div>
      <div className='points-row-activity-points d-flex align-items-center'>
        <NvIcon
          icon='highlight'
          size='xss-smallest'
          className='text-success'
        />
        {pointsDisplay}
      </div>
    </ClickableContainer>
  );
};

const AwardedRow = ({ awarded }: { awarded: AwardedPoint }) => {
  const styles = css`
    border-bottom: 1px dashed ${gray5};
    min-height: 60px;

    .awarded-row-description {
      gap: ${halfSpacing}px;
    }

    .awarded-row-points {
      gap: ${quarterSpacing}px;
    }
  `;

  return (
    <div css={styles} className='awarded-row-item d-flex align-items-center justify-content-between px-4 py-2'>
      <div className='awarded-row-description d-flex align-items-center'>
        {awarded.reason && (
          <div className='awarded-reason text-regular'>
            {awarded.reason}
          </div>
        )}
        {awarded.issuer && (
          <div className='awarded-issuer text-regular'>
            {t.TIMELINE.AWARDED_BY(`${awarded.issuer.firstName} ${awarded.issuer.lastName}`)}
          </div>
        )}
        <div className='text-regular gray-2'>
          {moment(awarded.issuedDate).format('l')}
        </div>
      </div>
      <div className='awarded-row-points d-flex'>
        <NvIcon
          icon='highlight'
          size='xss-smallest'
          className='text-success'
        />
        <span className='label text-success'>
          {awarded.receivedPoints}
        </span>
      </div>
    </div>
  );
};

const TimelinePointRow = ({
  activity,
  awarded,
}: TimelinePointProps) => (
  <React.Fragment>
    {activity
      ? <ActivityRow activity={activity} />
      : <AwardedRow awarded={awarded} />}
  </React.Fragment>
);

export default TimelinePointRow;
