import { css } from '@emotion/react';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { textLargeFontSize } from 'styles/global_defaults/fonts';
import { halfSpacing, quarterSpacing, standardSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import Badge from 'cohort_management/components/badge';
import { Course } from 'redux/schemas/models/course';
import t from 'react-translate';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvPopover from 'shared/components/nv-popover';
import NvIcon from 'shared/components/nv-icon';
import NvDatePicker, { DatePickerType } from 'shared/components/inputs/nv-datepicker';
import moment from 'moment';

import { CloneCourseContext, CloningType, cloneCourseContextType } from '../hooks/use-clone-course-form';

import { config } from '../../../../../config/config.json';
import ClickableContainer from 'components/clickable-container';
import validationConstants from 'shared/services/constants-shared';
import { FieldError } from 'react-hook-form';
import { getCoursesArray } from 'redux/selectors/course';

const MAX_LENGTH = 255;

enum Errors {
  REQUIRED = 1,
  FORMAT = 2,
  DUPLICATED = 3,
};

enum InputTypes {
  CATALOG_ID = 1,
  COURSE_NAME = 2,
};

const CourseForm = ({
  courseForm,
  cloningType,
  updateCourse,
  index,
  removeCohort,
  showRemoveButton,
  courseFormList,
}) => {
  const cloneCourseFormMethods = useContext<cloneCourseContextType>(CloneCourseContext);
  const {
    existentCourses,
    addErrorCourseIndex,
    removeErrorCourseIndex,
  } = cloneCourseFormMethods;
  const currentCourses = useSelector(getCoursesArray);
  const [showDatePopover, setShowDatePopover] = useState(false);
  const [errorInName, setErrorInName] = useState(null);
  const [errorIncatalogId, setErrorInCatalogId] = useState(null);
  const textIndex = `${index + 1}`;
  const style = css`
    position: relative;
    margin-top: ${standardSpacing}px;
    padding: ${standardSpacing}px;
    &.with-badge {
      padding-top: 27px;
    }
    .cohort-management-badge {
      top: 0px;
      left: 18px;
    }
    .title {
      align-items: center;
      padding-bottom: ${threeQuartersSpacing}px;
      .number {
        width: ${standardSpacing}px;
        height: ${standardSpacing}px;
        text-align: center;
        border-radius: 50%;
        margin-right: ${halfSpacing}px;
      }
      .condensed {
        font-size: ${textLargeFontSize}px;
      }
    }
    .form-data {
      .bs4-form-control:focus {
        z-index: 0;
      }
      .new-catalog-id {
        padding: 0px ${standardSpacing}px;
      }
      .new-start-date {
        position: relative;

        .label {
          top: 0;
          left: 0;
          position: absolute;
        }
        .start-date {
          width: 100%;
          position: absolute;
          top: ${threeQuartersSpacing}px;
          .react-datepicker-wrapper {
            width: 100%;
          }
        }
        .popover-wrapper {
          align-self: center;
          .icon {
            padding: 0px ${quarterSpacing}px;
          }
        }
      }
    }
    .delete-cohort {
      justify-content: right;
      .delete-button{
        align-items: center;
        padding: ${quarterSpacing}px ${halfSpacing}px;
        margin-top: ${halfSpacing}px;
        span {
          padding-left: ${quarterSpacing}px;
        }
      }
    }
  `;

  const popoverStyles = css`
    max-width: unset;
    span {
      display: block;
      width: 260px;
    }
    .link {
      padding-top: ${quarterSpacing}px;
    }
  `;

  useEffect(() => {
    if (!errorInName && !errorIncatalogId) {
      removeErrorCourseIndex(index);
    }
  }, [errorInName, errorIncatalogId]);

  useEffect(() => {
    const existentCourse = existentCourses.includes(courseForm.catalogId);
    if (existentCourse && existentCourses.length > currentCourses.length) {
      setError(InputTypes.CATALOG_ID, Errors.DUPLICATED);
      addErrorCourseIndex(index);
    }
  }, [existentCourses]);

  const getTitle = () => {
    let title = '';
    switch (cloningType) {
      case CloningType.COHORT_COURSE:
        title = t.COURSES.CLONE.FORM_TITLE.COHORT(textIndex);
        break;
      case CloningType.PRIMARY_COURSE:
        title = t.COURSES.CLONE.FORM_TITLE.PRIMARY();
        break;
      case CloningType.NORMAL_COURSE:
      default:
        title = t.COURSES.CLONE.FORM_TITLE.NORMAL();
    }
    return title;
  };

  const setError = (type, error) => {
    let message;
    switch(error) {
      case Errors.REQUIRED:
        message = t.COURSES.FORM.ERRORS.EMPTY_FIELDS();
        break;
      case Errors.FORMAT:
        message = type === InputTypes.CATALOG_ID ? t.COURSES.FORM.VALIDATION_TEXT.CATALOG_ID_PATTERN() : null;
        break;
      case Errors.DUPLICATED:
        message = type === InputTypes.CATALOG_ID ? t.COURSES.FORM.ERRORS.CATALOG_ID_TAKEN() : null;
        break;
      default:
        message = null;
    }

    const fieldError = message ? { message } as FieldError : null;
    if (type === InputTypes.CATALOG_ID) {
      setErrorInCatalogId(fieldError);
    }
    if (type === InputTypes.COURSE_NAME) {
      setErrorInName(fieldError);
    }
  }

  const validateInput = (type, value) => {
    const regex = validationConstants.CATALOG_ID_REGEX;
    let errorType = null;

    if (!value.length) {
      errorType = Errors.REQUIRED;
    } else if (type === InputTypes.CATALOG_ID) {
      if (!regex.test(value)) {
        errorType = Errors.FORMAT;
      }
      if (!errorType) {
        const existentCourse = existentCourses.includes(value);
        const anyNewCohortRepeated = cloningType === CloningType.COHORT_COURSE
          ? courseFormList?.find((cohort, cohortIndex) => index!== cohortIndex && cohort.catalogId === value)
          : null;
        if (existentCourse || anyNewCohortRepeated) {
          errorType = Errors.DUPLICATED;
        }
      }
    }

    if (errorType) {
      setError(type, errorType);
      addErrorCourseIndex(index);
    } else {
      setError(type, null);
    }
    if (type === InputTypes.CATALOG_ID) {
      updateCourse(index, {
        ...courseForm,
        catalogId: value,
      });
    } else {
      updateCourse(index, {
        ...courseForm,
        name: value,
      });
    }
  };

  const withBadgeClass = cloningType !== CloningType.NORMAL_COURSE ? 'with-badge' : '';

  return (
    <div
      css={style}
      className={`bg-gray-7 ${withBadgeClass}`}
      onMouseLeave={() => {
        setShowDatePopover(false);
      }}
    >
      <Badge
        className='cohort-management-badge'
        course={{
          isCohort: cloningType === CloningType.COHORT_COURSE,
          isPrimary: cloningType === CloningType.PRIMARY_COURSE,
        } as Course}
      />
      <div className='title d-flex'>
        {
          cloningType === CloningType.COHORT_COURSE
          && <div className='number bg-gray-2 text-white font-weight-bolder'>{textIndex}</div>
        }
        <div className='condensed'>
          {getTitle()}
        </div>
      </div>
      <div className='form-data d-flex'>
        <div className='new-name w-100'>
          <NvTextInput
            withLabel
            required
            maxLength={MAX_LENGTH}
            value={courseForm.name}
            name={cloningType === CloningType.COHORT_COURSE ? `newCohortName${textIndex}` : 'newCourseName'}
            placeholder={t.COURSES.CLONE.STEPS.STEP_ONE.FORM.NEW_NAME()}
            data-qa={config.pendo.cloningModal.name}
            onChange={(event) => validateInput(InputTypes.COURSE_NAME, event.currentTarget.value)}
            error={errorInName}
            preventPopoverOverflow
          />
        </div>
        <div className='new-catalog-id w-100'>
          <NvTextInput
            withLabel
            required
            autoFocus
            maxLength={MAX_LENGTH}
            value={courseForm.catalogId}
            name={cloningType === CloningType.COHORT_COURSE ? `newCohortCatalogId${textIndex}` : 'newCourseCatalogId'}
            placeholder={t.COURSES.CLONE.STEPS.STEP_ONE.FORM.NEW_CATALOG_ID()}
            data-qa={config.pendo.cloningModal.catalogId}
            onChange={(event) => validateInput(InputTypes.CATALOG_ID, event.currentTarget.value)}
            onBlur={(e) => validateInput(InputTypes.CATALOG_ID, courseForm.catalogId)}
            error={errorIncatalogId}
            errorOnTouching={false}
            forceShowErrorState
            preventPopoverOverflow
          />
        </div>
        <div className='new-start-date w-100'>
          <div className='label text-gray-3 d-flex'>
            {t.COURSES.CLONE.STEPS.STEP_ONE.FORM.NEW_START_DATE()}
            <NvPopover
              placement='top'
              show={showDatePopover}
              overlayStyles={popoverStyles}
              preventOverflow
              content={(
                <span>
                  {t.COURSES.CLONE.STEPS.STEP_ONE.FORM.TOOLTIP.TEXT()}
                  <div className='link'>
                    <a
                      href='https://help.novoed.com/hc/en-us/articles/360000180003-Cloning-Courses'
                      className='text-primary'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {t.COURSES.CLONE.STEPS.STEP_ONE.FORM.TOOLTIP.LINK()}
                    </a>
                  </div>
                </span>
              )}
            >
              <div className='info-dates'>
                <NvIcon icon='info' size='xss-smallest' className='text-primary' onMouseEnter={() => { setShowDatePopover(true); }} />
              </div>
            </NvPopover>
          </div>
          <NvDatePicker
            required
            fixed
            placement='bottom'
            value={moment(courseForm.officialReleaseDate)}
            name={cloningType === CloningType.COHORT_COURSE ? `newCohortStartDate${textIndex}` : 'newCourseStartDate'}
            type={DatePickerType.DATETIME}
            className='start-date'
            placeholder={t.FORM.SELECT_DATE_TIME()}
            dataQa={config.pendo.cloningModal.startDate}
            onChange={(time) => {
              updateCourse(index, {
                ...courseForm,
                officialReleaseDate: moment(time, 'L LT').toISOString(),
              });
            }}
          />
        </div>
      </div>
      {
        showRemoveButton &&
        (
          <div className='delete-cohort text-gray-2 d-flex text-small'>
            <ClickableContainer
              onClick={(e)=> removeCohort(index)}
              className='delete-button'
            >
                <NvIcon icon='trash' size='xss-smallest' />
                <span>
                  {t.COURSES.CLONE.STEPS.STEP_ONE.FORM.DELETE_COHORT()}
                </span>

            </ClickableContainer>
          </div>
        )
      }
    </div>
  );
};

export default CourseForm;
