import React from 'react';
import debounce from 'lodash/debounce';

import {
  getScrollParent,
  getOffsetTopRelativeToSpecificParent,
} from 'shared/utils';

const useScrollElementVisibility = (
  elementRef: React.MutableRefObject<HTMLElement>,
  debounceDuration = 0,
) => {
  const [isElementVisible, setIsElementVisible] = React.useState(false);

  React.useEffect(() => {
    const scrollContainer = getScrollParent(elementRef.current);

    let listener = () => {
      const offsetTop = getOffsetTopRelativeToSpecificParent(elementRef.current, scrollContainer);
      const elementVisible = offsetTop > scrollContainer.scrollTop && ((offsetTop + elementRef.current.offsetHeight) < (scrollContainer.scrollTop + scrollContainer.offsetHeight));

      setIsElementVisible(elementVisible);
    };

    if (debounceDuration) {
      listener = debounce(listener, debounceDuration);
    }

    listener();
    scrollContainer.addEventListener('scroll', listener);

    return () => {
      scrollContainer.removeEventListener('scroll', listener);
    };
  }, [elementRef, debounceDuration]);

  return isElementVisible;
};

export default useScrollElementVisibility;
