import { pick } from 'underscore';
import axios, { AxiosResponse } from 'axios';
import { MutableRefObject, useCallback, useRef, useState } from 'react';
import { RootState } from 'redux/schemas';
import { useSelector } from 'react-redux';
import humps from 'humps';
import uuid from 'react-uuid';

import { getCurrentInstitution } from 'redux/selectors/institutions';
import { getCurrentUser } from 'redux/selectors/users';
import { Institution } from 'redux/schemas/models/courseFull';
import { MyAccount } from 'redux/schemas/models/my-account';


import PusherService from 'shared/services/pusher-service';
import { UploadableFileList, UploadableFile } from 'froala/helpers/nv-froala-constants';

import { config } from '../../../config/config.json';

const S3Url = `${config.s3.protocol}://s3.amazonaws.com/${config.s3.bucket}/`;
const NovoEdUploadUrl = '/s3_file_upload/upload_file';


interface UploadToNovoEdResponse {
  key: string,
  s3Url: string,
  uniqueId: string,
  url?: string;
  downloadUrl?: string;
  hasBeenTranscoded?: boolean;
  token?: string;
}

export interface NovoEdFile {
  name: string,
  size: number,
  type: string,
  uniqueId: string,
  url?: string;
  downloadUrl?: string;
}

export interface FileUploading {
  file: File,
  novoEdFile?: NovoEdFile,
  isUploading: boolean,
  uploadPercentage?: number,
  abort?: () => void,
  progress?: number,
  uploadInProgress?: boolean,
}

export interface FilesUploading {
  [id: string]: FileUploading
}

export const convertBlobToFile = (myBlob: Blob): File => {
  const myFile = new File([myBlob], `${(new Date()).getTime()}.webm`, {
    type: myBlob.type,
  });

  return myFile;
};

/**
 * uploads the file to Amazon S3, first calling NovoEd backend to get the relevant fields
 * @param file browser file object
 * @param nameSpace
 * @param onUploadProgress passed to axios onUploadProgress
 * s3 responds with a single string that is actually XML
 */
async function uploadToS3(file: File, s3Namespace: string, onUploadProgress: (progressEvent: ProgressEvent) => void, abortUploadRef: MutableRefObject<any>, currentUser: MyAccount): Promise<AxiosResponse<UploadToNovoEdResponse>> {
  const { CancelToken } = axios;

  const cancelToken = new CancelToken((c) => {
    if (abortUploadRef) {
      abortUploadRef.current = c;
    }
  });

  const uploadFields = await axios.put(
    '/s3_direct_upload/upload_fields.json', {
      fileName: file.name,
      s3Namespace,
    },
    {
      cancelToken,
    },
  ).then((response) => response.data);

  const formData = new FormData();
  const data = {
    key: uploadFields.key,
    AWSAccessKeyId: uploadFields.aWSAccessKeyId,
    acl: uploadFields.acl,
    policy: uploadFields.policy,
    signature: uploadFields.signature,
    'Content-Type': file.type,
    'X-Requested-With': uploadFields.xRequestedWith,
    utf8: '✓',
    success_action_status: uploadFields.successActionStatus,
    file,
  };
  Object.keys(data).forEach(key => formData.append(key, data[key]));

  const axiosConfig = {
    onUploadProgress,
    cancelToken,
  };

  return axios.post(S3Url, formData, axiosConfig)
    .then((response: AxiosResponse<UploadToNovoEdResponse>) => {
      response.data = pick(uploadFields, 'key', 's3Url', 'uniqueId', 'url', 'downloadUrl');
      return response;
    });
}

/**
 * some institutions do not allow uploads to s3 directly, in that case do a upload to NovoEd who then uploads the file to s3
 * @param file browser file object
 * @param nameSpace
 * @param onUploadProgress currently this is not used because progress is not passed back
 */
function uploadToNovoEd(file: UploadableFile, nameSpace: string, onUploadProgress: (progressEvent: ProgressEvent) => void, abortUploadRef: MutableRefObject<any>, currentUser: MyAccount): Promise<AxiosResponse<UploadToNovoEdResponse>> {
  const { CancelToken } = axios;
  const token = uuid();
  const formData = new FormData();
  const data = {
    file,
    s3_namespace: nameSpace,
    token,
  };
  Object.keys(data).forEach(key => formData.append(key, data[key]));

  // NOV-67687 Indirect file upload to wait for pusher event during failure
  // This is an additional fallback mechanism to catch failed upload ( >= 2GB )
  // Here we are uploading directly to novoed servers and then move to s3. So upload request may timeout while
  // we move the file to S3 due to no-activity.
  // Here we have enabled an additional file upload notification via pusher and return the
  // file upload success promise when we receive the pusher notification
  // A random generated token is added to match and resolve respective callbacks
  return new Promise((resolve, reject) => {
    const callback = (pusherResponse) => {
      const fileUploadResponse = humps.camelizeKeys(pusherResponse) as unknown as UploadToNovoEdResponse;
      if (token && fileUploadResponse?.token === token) {
        if (fileUploadResponse.uniqueId) {
          resolve({
            status: 200,
            statusText: 'OK',
            headers: {},
            config: null,
            data: fileUploadResponse,
          });
          PusherService.currentUserChannel().unbind('file_uploaded', callback);
        }
      }
    };
    const identifier = currentUser.anonymizedIdentifier.substr(0, 10);
    PusherService.setupUserChannel(identifier);

    PusherService.currentUserChannel().bind('file_uploaded', callback);
    return axios.post(NovoEdUploadUrl, formData, {
      onUploadProgress,
      cancelToken: new CancelToken((c) => {
        if (abortUploadRef) {
          abortUploadRef.current = c;
        }
      }),
    }).then((response: AxiosResponse<UploadToNovoEdResponse>) => {
      PusherService.currentUserChannel().unbind('file_uploaded', callback);
      resolve(response);
    }).catch(error => {
      reject(error);
    });
  });
}

function newFileUploading(currentFilesUploading: FilesUploading, index: number, newAttributes: Partial<FileUploading>) {
  return {
    ...currentFilesUploading,
    ...{
      [index]: {
        ...currentFilesUploading[index],
        ...newAttributes,
      },
    },
  };
}

/**
 * React hook for uploading an files and getting the upload progress
 */
/* @ngInject */
export default function useUploadFile() {
  const institution = useSelector<RootState, Institution>(getCurrentInstitution);
  const currentUser = useSelector<RootState, MyAccount>(getCurrentUser);

  const [isUploading, setIsUploading] = useState<boolean>(false);
  // if we do a direct upload to NovoEd, we don't get uploaded percentage
  const [hasUploadPercentage, setHasUploadPercentage] = useState<boolean>(false);
  // list of files being uploaded
  const [filesUploading, setFilesUploading] = useState<FilesUploading>({});

  const abortUploadRef = useRef(null);

  const onUploadProgress = (progressEvent: ProgressEvent, index: number) => {
    setFilesUploading((currentFilesUploading) => (newFileUploading(currentFilesUploading, index, {
      uploadPercentage: Math.round((progressEvent.loaded / progressEvent.total) * 100),
    })));
  };
  /**
   * Uploads an array of files
   * @param nameSpace s3 namespace
   */
  const uploadFiles = useCallback(async (filesList: UploadableFileList, nameSpace: string): Promise<NovoEdFile[]> => {
    let index = 0;
    const files = Array.from(filesList);
    setFilesUploading({});
    const promises: Promise<NovoEdFile>[] = [];

    let uploadMethod;
    if (institution?.directUpload === true) {
      setHasUploadPercentage(false);
      uploadMethod = uploadToNovoEd;
    } else {
      setHasUploadPercentage(true);
      uploadMethod = uploadToS3;
    }

    setIsUploading(true);
    files.forEach((file) => {
      const currentIndex = index;

      setFilesUploading((currentFilesUploading) => newFileUploading(currentFilesUploading, currentIndex, {
        file: file as File,
        isUploading: true,
      }));

      const promise: Promise<NovoEdFile> = uploadMethod(file, nameSpace, (progressEvent: ProgressEvent) => { onUploadProgress(progressEvent, currentIndex); }, abortUploadRef, currentUser)
        .then((response: AxiosResponse<UploadToNovoEdResponse>) => {
          const novoEdFile: NovoEdFile = {
            name: file.name,
            size: file.size,
            type: file.type,
            uniqueId: response.data.uniqueId,
            url: response.data.url,
            downloadUrl: response.data.downloadUrl,
          };

          setFilesUploading((currentFilesUploading) => newFileUploading(currentFilesUploading, currentIndex, { novoEdFile }));

          return novoEdFile;
        })
        .finally(() => {
          setFilesUploading((currentFilesUploading) => newFileUploading(currentFilesUploading, currentIndex, {
            isUploading: false,
            uploadPercentage: 0,
          }));
        });

      promises.push(promise);
      index += 1;
    });

    return Promise.all(promises)
      .then((allResults) => {
        setIsUploading(false);
        return allResults;
      })
      .catch(error => {
        setIsUploading(false);
        return [null];
      });
  }, [currentUser, institution?.directUpload]);

  return { uploadFiles, isUploading, hasUploadPercentage, filesUploading, abortUpload: abortUploadRef.current };
}
