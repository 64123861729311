import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { getPointsConfiguration } from 'redux/selectors/points-configurations';
import ActivityPoints from 'shared/components/activity/activity-points';
import { halfSpacing } from 'styles/global_defaults/scaffolding';

interface PollHeaderPointsConfigurationProps {
  pointsConfigurationId: number;
  pointsReceived: number;
}

/* @ngInject */
export default function PollHeaderPointsConfiguration(props: PollHeaderPointsConfigurationProps) {
  const pointsConfiguration = useSelector((state) => getPointsConfiguration(state, props.pointsConfigurationId));
  return (
    pointsConfiguration
    && !!pointsConfiguration.points && (
      <div
        className='activity-points'
        css={css`
          margin-bottom: ${halfSpacing}px;
        `}
      >
        <ActivityPoints pointsReceived={props.pointsReceived} totalPoints={pointsConfiguration.points} />
      </div>
    )
  );
}
