/* @ngInject */
export default function NvProgressIcon() {
  const STATUS_MAPPING = {
    not_started: 'none',
    approval_needed: 'none',
    join: 'none',

    started: 'inProgress',
    in_progress: 'inProgress',
    pending: 'inProgress',
    rejected: 'inProgress',

    completed: 'completed',
    approved: 'completed',

    missed: 'missed',
    rejected_and_missed: 'missed',

    // - team/group
    created_a_team: 'completed',
    joined_a_team: 'completed',
    attended_manual: 'completed',
    attended_auto: 'completed',
    watched_recording: 'completed',
  };
  return {
    restrict: 'A',
    replace: true,
    transclude: true,
    scope: {
      progressStatus: '=',
    },
    link(scope, elem, attrs) {
      scope.getProgressStatus = function () {
        return STATUS_MAPPING[scope.progressStatus] ? STATUS_MAPPING[scope.progressStatus] : scope.progressStatus;
      };
    },
    templateUrl: 'shared/templates/nv-progress-icon.html',
  };
}
