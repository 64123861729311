export default [
  function () {
    return {
      scope: {
        nvInitiateCallback: '&',
      },
      link(scope, elem, attr) {
        scope.nvInitiateCallback();
      },
    };
  },
];
