import { createReducer } from '@reduxjs/toolkit';
import { setCurrentPlayingVideoId } from 'redux/actions/video';
import { initialRootState } from '.';

export default createReducer(initialRootState, builder => {
  builder
    .addCase(setCurrentPlayingVideoId, (state, action) => {
      state.app.currentPlayingVideoPlayerId = action.payload;
    });
});
