import { css } from '@emotion/react';
import React from 'react';

// Styles
import {
  doubleSpacing, standardSpacing,
} from 'styles/global_defaults/scaffolding';
import {
  handheld, tablet, desktop,
} from 'styles/global_defaults/media-queries';

// Components
import NvGalleryCardPlaceholder from 'shared/components/nv-gallery-card-placeholder';

/**
 * A wrapping component used to hide a child component until a condition is met.
 * Displays a loading placeholder until the condition is met.
 */

const styles = () => css`
  // Float the loading placeholder above the content
  position: relative;

  .loading-placeholder > *:not(:first-of-type) {
    ${desktop(css`
      margin-left: ${doubleSpacing}px;
    `)};
    ${tablet(css`
      margin-left: ${standardSpacing}px;
    `)};
    ${handheld(css`
      display: none !important; // To override display property in handheld devices.
    `)};
  }
`;

const TabLoadingPlaceholder = () => (
  <div className='d-flex loading-placeholder'>
    <NvGalleryCardPlaceholder />
    <NvGalleryCardPlaceholder />
    <NvGalleryCardPlaceholder />
  </div>
);

const TabLoadingWrapper = (props: {
  children: any,
  isLoaded: boolean,
  isMoreLoading?: boolean
}) => (
  <React.Fragment>
    { !props.isLoaded ? (
      <div css={styles}>
        <TabLoadingPlaceholder />
        {/* Use visibility: hidden style so the content still sizes
          * itself before it's ready to display prevents
          * the content shifting when loading is complete
          */}
        <div css={css`visibility: hidden;`}>
          { props.children }
        </div>
      </div>
    ) : (
      <React.Fragment>
        { props.children }
        {/* After the page once loaded
          * loading placeholder displayed
          * in the bottom on each page loading
          */}
        {props.isMoreLoading && (
          <div css={styles}>
            <TabLoadingPlaceholder />
          </div>
        )}
      </React.Fragment>
    )}
  </React.Fragment>
);

export default TabLoadingWrapper;
