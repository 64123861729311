import store from 'redux/store';
import { ActivityKey } from 'redux/schemas/models/activity';
import { setActivityProgress } from 'redux/actions/lecture-components';

/* @ngInject */
export default function TimelineProfileCompletionLectureComponentModelService(
  _,
  nvUtil,
) {
  class TimelineProfileCompletionLectureComponentModel {
    constructor(attributes) {
      const base = {
        templateUrl: 'timelines/templates/nv-timeline-lecture-profile-completion.html',
      };

      _.extend(this, base, attributes);

      this.__preprocess();
    }

    __preprocess() {
      /**
       * Assigning profileRequirement properties to model
       */
      _.extend(this, _.pick(
        this.profileRequirement,
        ['isTodo', 'releaseDate', 'totalPoints', 'pointsReceived'],
      ));
      this.activityId = this.profileRequirement.id;

      this.currentTotalPoints = nvUtil.getCurrentTotalPoints(this.totalPoints);

      this.__viewPreprocess();
    }

    __viewPreprocess() {
      if (this.isCompleted() || this.pointsReceived > this.currentTotalPoints) {
        this.currentTotalPoints = this.pointsReceived || 0;
      }
      this.displayTotalPointsOnly = !this.pointsReceived && !!this.currentTotalPoints;
    }

    getStatus() {
      return this.profileRequirement.progress;
    }

    isCompleted() {
      return this.profileRequirement.progress === 'completed';
    }

    isInProgress() {
      return this.profileRequirement.progress === 'in_progress';
    }

    isMissed() {
      return false;
    }

    hasDeadline() {
      return false;
    }

    isTodoRequiredForCompletion() {
      return this.profileRequirement.isRequiredForCompletion;
    }

    getPayload() {
      return _.pick(this.profileRequirement, ['title']);
    }

    /**
     * The following update methods don't take care of any other property
     * because they are not relevant for timeline purposes, so that's why
     * progress and pointsReceived are the only updated ones.
     */

    updateComponentPointsAndProgress(componentType, componentId, pointsReceived, totalPoints, progress) {
      if (componentType === this.type && componentId === this.id) {
        this.profileRequirement.progress = progress;
        this.profileRequirement.pointsReceived = pointsReceived;

        store.dispatch(setActivityProgress({
          pointsReceived,
          progress: this.profileRequirement.progress,
          activityKey: ActivityKey.PROFILE_REQUIREMENT,
          activityPayloadId: this.profileRequirement.id,
        }));

        this.__preprocess();
        this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    updateComponentProgress(componentType, componentId, progress) {
      if (componentType === this.type && componentId === this.id) {
        this.profileRequirement.progress = progress;
        store.dispatch(setActivityProgress({
          progress,
          activityKey: ActivityKey.PROFILE_REQUIREMENT,
          activityPayloadId: this.profileRequirement.id,
        }));

        this.__preprocess();
        this.lecturePage.preprocessSelfAndParentPointData();
      }
    }
  }

  return TimelineProfileCompletionLectureComponentModel;
}
