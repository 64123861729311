import { css } from '@emotion/react';

import { useContext, useRef, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { object } from 'underscore';
import t from 'react-translate';

// Schemas
import { RootState } from 'redux/schemas';
import { ItemState, Communication } from 'redux/schemas/models/course-communication';

// Contexts
import { CommunicationDispatch, CommunicationAction, TriggerType } from 'communications/course_communications/contexts/communication-context';

// Selectors
import { getCommunication, getItemState, getLastCreatedCourseCommunicationId } from 'redux/selectors/course-communications';
import { isConfirmationDialogShown } from 'redux/selectors/confirmation-dialogs';

// Styles
import { quarterSpacing } from 'styles/global_defaults/scaffolding';
import { boldFontWeight } from 'styles/global_defaults/fonts';
import { info, teal, warning, primary, danger, black, gray2, gray3, gray4, gray6, gray7 } from 'styles/global_defaults/colors';

// Components
import { NvTooltip } from 'shared/components/nv-tooltip';
import CommunicationTitleRow from './communication-title-row';
import CommunicationTriggerRow from './communication-trigger-row';
import CommunicationFilterRow from './communication-filter-row';

type CommunicationItemProps = {
  communicationId: number,
  showOwnerActivity?: boolean,
  isPreview?: boolean,
  showLateralColorBar?: boolean,
};

type StateProps = {
  communication: Communication,
  itemState: ItemState,
  isConfirmationOverlayShown: boolean,
};

export type ItemFlavor = {
  barBg: string,
  badgeBg: string,
  boxBg: string,
  fontColor: string,
  fontStyle: string,
  iconColor: string,
};

export const itemFlavors = object([
  // Key                barBg     badgeBg   boxBg  fontColor  fontStyle   iconColor
  /* eslint-disable no-multi-spaces */
  [ItemState.NORMAL,    teal,     'none',   info,   black,    'normal',   warning],
  [ItemState.SENT,      gray3,    gray3,    gray7,  gray2,    'normal',   gray2],
  [ItemState.ACTIVATED, primary,  primary,  info,   black,    'normal',   warning],
  [ItemState.ERROR,     danger,   danger,   gray7,  gray3,    'italic',   gray2],
  [ItemState.DRAFT,     gray4,    gray4,    gray7,  gray3,    'italic',   gray2],
  /* eslint-enable no-multi-spaces */
].map<[string, ItemFlavor]>((f) => ([
  f[0], {
    barBg: f[1],
    badgeBg: f[2],
    boxBg: f[3],
    fontColor: f[4],
    fontStyle: f[5],
    iconColor: f[6],
  },
])));

const styles = (itemState: ItemState, isLastCreated?: boolean, showLateralColorBar?: boolean) => {
  const flavor: ItemFlavor = itemState && itemFlavors[itemState];

  return css`
    position: relative;
    border-bottom: dashed 1px ${gray6};
    cursor: pointer;

    ${showLateralColorBar
    && `&::before {
      position: absolute;
      content: '';
      width: ${quarterSpacing}px;
      height: 100%;
      top: 0;
      left: 0;
    }`}

    ${!showLateralColorBar
    && `& > div {
      padding-left: 0px;
    }`}


    &:hover .subject {
      color: ${primary};
    }
    .subject {
      font-weight: ${boldFontWeight};
    }
    color: ${flavor?.fontColor};
    background-color: ${flavor?.boxBg};
    font-style: ${flavor?.fontStyle};

    .announcer-avatar {
      font-style: normal;
    }

    &::before {
      background-color: ${isLastCreated ? warning : flavor?.barBg};
      opacity: ${isLastCreated ? '.7' : 'inherit'};
    }

    .icons-wrapper .icon {
      color: ${flavor?.iconColor};
    }

    .communication-badge {
      ${flavor?.badgeBg === 'none' && 'display: none'};
      ${flavor?.badgeBg !== 'none' && `background-color: ${flavor?.badgeBg}`};
    }
  `;
};

const scrollToRef = (ref) => {
  setTimeout(() => {
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  });
};

const CommunicationItem = ({
  communicationId,
  showOwnerActivity,
  isPreview,
  communication,
  itemState,
  isConfirmationOverlayShown,
  showLateralColorBar = true,
}: CommunicationItemProps & StateProps) => {
  const { State, dispatch } = useContext(CommunicationDispatch);

  const itemRef = useRef(null);
  const lastCreatedCourseCommunicationId: number = useSelector((state: RootState) => getLastCreatedCourseCommunicationId(state));

  useEffect(() => {
    if (lastCreatedCourseCommunicationId === communicationId) {
      scrollToRef(itemRef);
    }
  }, [lastCreatedCourseCommunicationId, communicationId, itemRef]);

  return (
    communication ? (
      <NvTooltip
        // This has to work flawlessly. When modal is displayed, the
        // tooltip has to be removed. But seems like there is an issue
        // somewhere and tooltip stays there, when the modal is opened.
        // So enabling this tooltip only if the modal is not shown
        // and the confirmation overlay is not shown
        enabled={!State.showModal && !isConfirmationOverlayShown}
        text={t.COURSE_COMMUNICATIONS.TOOLTIP.ITEM()}
        placement='top'
      >
        <div
          css={styles(itemState, lastCreatedCourseCommunicationId === communicationId, showLateralColorBar)}
          ref={itemRef}
          onClick={() => dispatch({
            type: CommunicationAction.SHOW_MODAL,
            triggerType: TriggerType.VIEW,
            communicationId,
            communicationType: communication.communicationType,
          })}
        >
          <CommunicationTitleRow communicationId={communicationId} />
          <CommunicationTriggerRow
            communicationId={communicationId}
            showOwnerActivity={showOwnerActivity}
            isPreview={isPreview}
          />
          <CommunicationFilterRow communicationId={communicationId} showOwnerActivity={showOwnerActivity} />
        </div>
      </NvTooltip>
    ) : null
  );
};

const ConnectedCommunicationItem = connect(
  (state: RootState, ownProps: CommunicationItemProps) => ({
    communication: getCommunication(state, ownProps.communicationId),
    itemState: getItemState(state, ownProps.communicationId),
    isConfirmationOverlayShown: isConfirmationDialogShown(state),
  }),
)(CommunicationItem);

export default ConnectedCommunicationItem;
