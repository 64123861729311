import prodPathReplace from 'shared/prod-path-rewrite';

/* @ngInject */
export default function OfflineManager(
  $q,
  $timeout,
  _,
  config,
  moment,
) {
  let firstOfflineTime;
  let callbacks = [];
  const manager = {
    initialize,
    registerReturnOnlineCallback,
    deRegisterReturnOnlineCallback,
    showLoadingBanner: false,
    timedQuizInProgress: false,
    isOffline: false,
  };

  function initialize() {
    window.Offline.options = {
      requests: false,
      reconnect: {
        initialDelay: config.offline.initialDelay,
        delay: config.offline.delay,
      },
      checks: {
        xhr: {
          url() {
            return `${prodPathReplace('images/favicon.ico')}?_=${new Date().getTime()}`;
          },
        },
      },
    };

    window.Offline.on('up', onHandler);
    window.Offline.on('down', offHandler);
  }

  function registerReturnOnlineCallback(func) {
    callbacks.push(func);
  }

  function deRegisterReturnOnlineCallback(func) {
    callbacks = _.without(callbacks, func);
  }

  function onHandler() {
    $timeout(() => {
      manager.isOffline = false;
      if (moment() - firstOfflineTime > 1000 * config.offline.offlineDeterminationTime) {
        manager.showLoadingBanner = true;
        const promises = _.map(callbacks, (callback) => callback());

        $q.all(promises).then(() => {
          firstOfflineTime = null;
          manager.showLoadingBanner = false;
        }, () => {
          manager.showLoadingBanner = false;
        });
      } else {
        firstOfflineTime = null;
      }
    });
  }

  function offHandler() {
    $timeout(() => {
      manager.showLoadingBanner = false;
      manager.isOffline = true;
      if (!firstOfflineTime) {
        firstOfflineTime = moment();
      }
    });
  }

  return manager;
}
