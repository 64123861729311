export default {
  bindings: {
    report: '<',
  },
  controller: function ctrl(
    CurrentCourseManager,
  ) {
'ngInject';
    this.CurrentCourseManager = CurrentCourseManager;
  },
  controllerAs: 'vm',
  templateUrl: 'exercises/templates/nv-submission-status-text.html',
};
