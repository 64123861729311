import { css } from '@emotion/react';

import { connect } from 'react-redux';
import { RootState } from 'redux/schemas';

import { getLectureSection } from 'redux/selectors/timeline';

import { gray6 } from 'styles/global_defaults/colors';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { LectureSection, NLectureSection } from 'redux/schemas/models/lecture-section';
import TimelineLecturePage from './timeline-lecture-page';

type TimelineSubsectionProps = {
  lectureSubsectionId: number
};

type StateProps = {
  lectureSubsection: NLectureSection,
};

const styles = css`
  .subsection-row {
    display: flex;
    align-items: center;
    border-bottom: solid 1px ${gray6};
    min-height: 60px;
  }
`;

/**
 * Timeline subsection header
 */
const TimelineSubsection = (props: TimelineSubsectionProps & StateProps) => (
  <div css={styles}>
    <div className='subsection-row'>
      <div className='course-title-small'>{props.lectureSubsection.title}</div>
    </div>
    {
      // Lecture pages
      props.lectureSubsection.lecturePages && props.lectureSubsection.lecturePages.map(lecturePageId => (
        <TimelineLecturePage key={lecturePageId.toString()} lecturePageId={lecturePageId} />
      ))
    }
  </div>
);

const ConnectedTimelineSubsection = connect(
  (state: RootState, ownProps: TimelineSubsectionProps): StateProps => ({
    lectureSubsection: getLectureSection(state, ownProps.lectureSubsectionId),
  }),
)(TimelineSubsection);

export default ConnectedTimelineSubsection;
