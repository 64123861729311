import { css } from '@emotion/react';
import { useContext } from 'react';
import moment from 'moment';
import t from 'react-translate';

// Contexts
import { CommunicationDispatch, TriggerType } from 'communications/course_communications/contexts/communication-context';

// Hooks
import { DraftSubmittedState } from 'communications/course_communications/hooks/use-communication-form';

// Styles
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { primary, gray3 } from 'styles/global_defaults/colors';

type SaveAsDraftProps = {
  changesMade?: boolean
  onClick: () => void
  draftSaved: string
  isDraft?: boolean // Whether its already a draft
  isFormValid?: boolean
};

const SaveAsDraft = (props: SaveAsDraftProps) => {
  const textStyles = css`
    margin-top: ${halfSpacing}px;
    display: block;
    color: ${gray3};
    text-align: center;
  `;

  const linkStyles = css`
    color: ${primary};
    display: block;
    margin-top: ${halfSpacing}px;
    text-align: center;
  `;

  const { State } = useContext(CommunicationDispatch);

  if (State.triggerType === TriggerType.CREATE
    || State.triggerType === TriggerType.DUPLICATE
    || (State.triggerType === TriggerType.EDIT && props.isDraft)) {
    if (props.draftSaved === DraftSubmittedState.SUBMITTING) {
      return <div className='save-draft' css={textStyles}>{t.COURSE_COMMUNICATIONS.SAVING()}</div>;
    }

    // For Duplicate check the form is valid or not
    if (props.changesMade || (State.triggerType === TriggerType.DUPLICATE && props.isFormValid)) {
      return <a className='save-draft' css={linkStyles} onClick={props.onClick}>{t.COURSE_COMMUNICATIONS.SAVE_AS_DRAFT()}</a>;
    }

    /** If draft saved equals 'loading', display loading text
     * Else draftSaved is a string representing date draft was saved.
     */
    if (moment(props.draftSaved).isValid()) {
      return <div className='save-draft' css={textStyles}>{t.COURSE_COMMUNICATIONS.DRAFT_SAVED(moment(props.draftSaved).format('lll'))}</div>;
    }
  }

  return null;
};

SaveAsDraft.defaultProps = {
  isDraft: false,
  changesMade: () => {},
};

export default SaveAsDraft;
