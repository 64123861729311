import React, { useState } from 'react';
import { AngularContext } from 'react-app';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { getCurrentCourse } from 'redux/selectors/course';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import EnrollmentPanel, { defaultPeriod, defaultPeriodType } from 'institutions/components/enrollment-panel';
import { css } from '@emotion/core';
import getAdminToolsFlyoutItems from 'lhs/services/admin-tools-flyout-config';
import FlyoutContainer from './flyout-container';
import FlyoutMenuItem from './flyout-menu-item';
import { EmptyContent } from './loading-placeholders';

export const AdminToolsPanel = () => {
  const CurrentCourse = useSelector(getCurrentCourse);
  const [showModal, setShowModal] = useState(false);
  const { injectServices } = React.useContext(AngularContext);
  const [CurrentPermissionsManager] = injectServices(['CurrentPermissionsManager']);
  const institution = useSelector((state) => getCurrentInstitution(state));
  // styles to avoid calendars overflow to be hidden inside the modal
  const modalStyles = css`
    .bs4-modal-body {
      &.downloads-modal {
        overflow:visible;
      }
    }
`;
  const hasLicenseDashboard = institution?.hasLicenseDashboard;

  const openModal = () => {
    setShowModal(true);
  };
  const adminToolsItems = getAdminToolsFlyoutItems(t, CurrentPermissionsManager, hasLicenseDashboard, openModal, CurrentCourse?.isContentManagementCollection);

  const noItems = !adminToolsItems || adminToolsItems?.length === 0;

  return (
    <>
      <FlyoutContainer title={t.LHS.ORG_ADMIN_TOOLS()}>
        { noItems
          ? <EmptyContent text={t.LHS.NO_ITEMS()} />
          : adminToolsItems.map(item => (
            <FlyoutMenuItem key={item.label} {...item} />
          ))}
      </FlyoutContainer>
      <NvModal
        show={showModal}
        width={720}
        type={ModalType.FIXED}
        fullHeight={false}
        bodyClassName='downloads-modal'
        modalStyles={modalStyles}
        onClose={() => setShowModal(false)}
        header={t.LHS.DOWNLOAD_USER_ENROLLMENT_DATA()}
        body={(<EnrollmentPanel />)}
      />
    </>
  );
};

export default AdminToolsPanel;
