import { jsx, css } from '@emotion/react';
import React, { useState, useCallback, useEffect, useRef, SetStateAction, Dispatch } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { getCurrentCourse } from 'redux/selectors/course';
import { hasGetUserMedia, hasMediaRecorder, requestMediaStream, MediaSourceType } from 'recording/services/media-stream';
import { convertBlobToFile } from 'shared/hooks/use-upload-file';
import { CombinedCourse, RootState } from 'redux/schemas';
import RecordingOverlay, { RecordingType } from 'recording/components/recording-overlay';
import NvFilePicker from 'shared/components/nv-filepicker';
import { gray2, gray3, gray4, primary } from 'styles/global_defaults/colors';
import { doubleSpacing, halfSpacing, largeSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { boldFontWeight, textSmallFontSize } from 'styles/global_defaults/fonts';
import { handheld } from 'styles/global_defaults/media-queries';
import { BaseProps } from 'shared/components/inputs/form-props';
import { useAppDispatch } from 'redux/store';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { FileRejection } from 'react-dropzone';
import { config } from '../../../config/config.json';
import NvPopover from './nv-popover';
import ValidationErrorMessage from './inputs/validation-error-message';
import NvIcon from './nv-icon';

const sizesToBytes = {
  KB: 1024,
  MB: 1024 ** 2,
  GB: 1024 ** 3,
};

export const convertSizeToBytes = (size: number, unit: string): number => size * sizesToBytes[unit];

export enum NvFileUploadType {
  DOCUMENT = 'document',
  PRESENTATION = 'presentation',
  IMAGE = 'image',
  COVER_PHOTO = 'cover_photo',
  SPREADSHEET = 'spreadsheet',
  VIDEO = 'video',
  AUDIO = 'audio',
  SUBTITLES = 'subtitles',
  FAVICON = 'favicon',
  MULTIMEDIA = 'multimedia',
  ALL = 'all',
}

interface NvFileUploadProps extends BaseProps {
  type: string;
  customText?: string;
  error?: string;
  catalogId?: string;
  maxSize?: string;
  showInfoText?: boolean;
  onUploadFile: (files: File[], validate?: boolean, fileIsRecording?: boolean) => void;
  recordingOverlaySubmitButtonText?: string;
  multiple?: boolean;
  onSelectError?: (files: FileRejection[]) => void
  showactionButton?: boolean
  dataQa?: string
}

const NvFileUpload = ({
  type,
  customText,
  error,
  catalogId,
  maxSize = '50MB',
  onUploadFile,
  showInfoText = true,
  recordingOverlaySubmitButtonText,
  multiple,
  onSelectError,
  showactionButton = true,
  className,
  dataQa,
}: NvFileUploadProps) => {
  const styles = css`
    .file-upload-container {
      display: flex;
      align-items: center;
      border: 1px dashed ${gray4};
      padding: ${largeSpacing}px;

      .nv-file-picker {
        flex: 1;
      }

      .upload-file {
        flex: 1;
        border: none;
        margin: 0;
        padding: ${halfSpacing}px ${doubleSpacing}px;
        text-align: center;
        height: 100px;

        .icon-upload {
          color: ${gray3};
        }

        .upload-file-text-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: ${gray2};

          .upload-file-text {
            font-weight: ${boldFontWeight};
          }

          .upload-file-text-info {
            font-size: ${textSmallFontSize}px;
          }
        }

        &:hover {
          cursor: pointer;

          .icon-upload {
            color: ${primary};
          }
        }
      }

      .record {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        border-left: 1px solid ${gray4};
        height: 100px;

        .or {
          text-transform: uppercase;
          position: absolute;
          left: 0;
          top: 50%;
          transform:translateX(-50%) translateY(-50%);
          background: white;
          color: ${gray2};
          padding: ${halfSpacing}px;
        }

        .action {
          padding: ${halfSpacing}px ${doubleSpacing}px;
          text-align: center;

          .action-text {
            margin-top: ${halfSpacing}px;
            font-weight: ${boldFontWeight};
          }
        }

        .record-button {
          content: '';
          width: 32px;
          height: 32px;
          background-color: ${gray3};
          background-clip: content-box;
          padding: 4px;
          border-radius: 50%;
          border: 2px solid ${gray3};
          margin: auto;
        }

        &:hover {
          cursor: pointer;

          .record-button {
            background-color: ${primary};
          }
        }
      }

      ${handheld(css`
        flex-direction: column;
        padding: ${standardSpacing}px;

        .nv-file-picker {
          padding-bottom: ${doubleSpacing}px;

          .upload-file {
            padding: ${standardSpacing}px;
          }
        }

        .record {
          border-left: none;
          border-top: 1px solid ${gray4};
          padding: ${standardSpacing}px;
          padding-top: ${doubleSpacing}px;
          width: 100%auto;
          height: 166px;

          .or {
            top: 0;
            left: auto;
            transform: translateY(-50%);
          }
        }
      `)};
    }
  `;

  const dispatch = useAppDispatch();

  const hasUserMedia = hasGetUserMedia();
  const hasRecorder = hasMediaRecorder();

  const currentCourse = useSelector<RootState, CombinedCourse>((state) => getCurrentCourse(state));

  const [mediaStream, setMediaStream] = useState<MediaStream>();
  const [showRecordingOverlay, setShowRecordingOverlay] = useState(false);

  const getFileUploadText = () => {
    switch (type) {
      case 'document':
        return t.FILE_UPLOAD.UPLOAD_DOCUMENT();
      case 'presentation':
        return t.FILE_UPLOAD.UPLOAD_PRESENTATION();
      case 'image':
        return t.FILE_UPLOAD.UPLOAD_IMAGE();
      case 'cover_photo':
        return t.COURSES.FORM.HELP_TEXT.COVER_PHOTO(currentCourse.isProgram.toString());
      case 'spreadsheet':
        return t.FILE_UPLOAD.UPLOAD_SPREADSHEET();
      case 'video':
        return t.FILE_UPLOAD.UPLOAD_VIDEO();
      case 'audio':
        return t.FILE_UPLOAD.UPLOAD_AUDIO();
      case 'subtitles':
        return t.FILE_UPLOAD.UPLOAD_SUBTITLES();
      case 'favicon':
        return t.FILE_UPLOAD.UPLOAD_FAVICON();
      case 'multimedia':
        return t.FILE_UPLOAD.UPLOAD_DOCUMENT();
      default:
        return t.FILE_UPLOAD.UPLOAD_FILE();
    }
  };

  const getRecommendedFileTypes = () => {
    switch (type) {
      case 'presentation':
        return t.FILE_UPLOAD.UPLOAD_PRESENTATION_FILE_TYPES();
      case 'image':
        return t.FILE_UPLOAD.UPLOAD_IMAGE_FILE_TYPES();
      case 'cover_photo':
        return t.FILE_UPLOAD.UPLOAD_COVER_PHOTO_FILE_TYPES();
      case 'spreadsheet':
        return t.FILE_UPLOAD.UPLOAD_SPREADSHEET();
      case 'video':
        return t.FILE_UPLOAD.UPLOAD_VIDEO_FILE_TYPES();
      case 'audio':
        return t.FILE_UPLOAD.UPLOAD_AUDIO_FILE_TYPES();
      case 'subtitles':
        return t.FILE_UPLOAD.UPLOAD_SUBTITLES_FILE_TYPES();
      case 'favicon':
        return t.FILE_UPLOAD.UPLOAD_FAVICON_FILE_TYPES();
      case 'multimedia':
        return t.FILE_UPLOAD.UPLOAD_MULTI_MEDIA_FILE_TYPES();
      default:
        return '';
    }
  };

  const getFilePatterns = () => {
    switch (type) {
      case 'document':
        return config.files.documents.split(',');
      case 'presentation':
        return config.files.presentations.split(',');
      case 'image':
      case 'cover_photo':
        return config.files.images.split(',');
      case 'spreadsheet':
        return config.files.excels.split(',');
      case 'video':
        return config.files.videos.split(',');
      case 'audio':
        return config.files.audios.split(',');
      case 'subtitles':
        return config.files.subtitles.split(',');
      case 'favicon':
        return config.files.favicons.split(',');
      default:
        return config.files.allUploadableExtensions;
    }
  };

  const onCloseRecordingOverlay = (blob: Blob) => {
    if (blob) {
      const file = convertBlobToFile(blob);
      onUploadFile([file], false, true);
    }

    setShowRecordingOverlay(false);
  };

  const getMediaSourceType = () => {
    const mediaSource = type === 'audio' ? MediaSourceType.MIC : MediaSourceType.CAMERA_MIC;
    return mediaSource;
  };

  const initiateRecordVideo = () => {
    const source = getMediaSourceType();

    requestMediaStream(source as MediaSourceType)
      .then((stream: MediaStream) => {
        setMediaStream(stream);
        setShowRecordingOverlay(true);
      })
      .catch(err => {
        if (type === 'video' && err.name === 'NotFoundError') {
          dispatch(openConfirmationDialog({
            confirmButtonVariant: 'primary',
            title: t.FILE_UPLOAD.CANNOT_RECORD.TITLE(),
            bodyText: t.FILE_UPLOAD.CANNOT_RECORD.VIDEO_NOT_FOUND(),
            confirmText: t.FORM.CONTINUE(),
            onConfirm: initiateRecordVideo,
          }));
        } else if (type === 'video' && err.name === 'NotAllowedError') {
          dispatch(openConfirmationDialog({
            confirmButtonVariant: 'primary',
            title: t.FILE_UPLOAD.CANNOT_RECORD.TITLE(),
            bodyText: t.FILE_UPLOAD.CANNOT_RECORD.VIDEO_NOT_ALLOWED(),
            confirmText: t.FORM.CONTINUE(),
            onConfirm: initiateRecordVideo,
          }));
        } else if (type === 'audio' && err.name === 'NotFoundError') {
          dispatch(openConfirmationDialog({
            confirmButtonVariant: 'primary',
            title: t.FILE_UPLOAD.CANNOT_RECORD.TITLE(),
            bodyText: t.FILE_UPLOAD.CANNOT_RECORD.AUDIO_NOT_FOUND(),
            confirmText: t.FORM.CONTINUE(),
            onConfirm: initiateRecordVideo,
          }));
        } else if (type === 'audio' && err.name === 'NotAllowedError') {
          dispatch(openConfirmationDialog({
            confirmButtonVariant: 'primary',
            title: t.FILE_UPLOAD.CANNOT_RECORD.TITLE(),
            bodyText: t.FILE_UPLOAD.CANNOT_RECORD.AUDIO_NOT_ALLOWED(),
            confirmText: t.FORM.CONTINUE(),
            onConfirm: initiateRecordVideo,
          }));
        }
      });
  };

  let actionButton;
  if (!hasUserMedia || !hasRecorder) {
    actionButton = (
      <div className='action'>
        <NvIcon icon='ban' size='small' className='text-gray-3' />
        <div className='action-text text-gray-3 text-small lacking-support'>{t.FILE_UPLOAD.CANNOT_RECORD.BROWSER_SUPPORT()}</div>
      </div>
    );
  } else if (hasUserMedia && hasRecorder && type === 'video') {
    actionButton = (
      <div className='action' onClick={initiateRecordVideo}>
        <div className='record-button icon' />
        <div className='action-text recording-label'>{t.FILE_UPLOAD.RECORD_VIDEO()}</div>
      </div>
    );
  } else if (hasUserMedia && hasRecorder && type === 'audio') {
    actionButton = (
      <div className='action'>
        <NvIcon icon='audio-recording' size='large' className='text-gray-3' />
        <div className='action-text recording-label'>{t.FILE_UPLOAD.RECORD_AUDIO()}</div>
      </div>
    );
  }

  const acceptPatterns = getFilePatterns();

  const number = maxSize.slice(0, -2);
  const unit = maxSize.slice(-2);
  const sizeInBytes = convertSizeToBytes(parseInt(number, 10), unit);

  return (
    <div css={styles} className={className}>
      <NvPopover
        show={!!error}
        placement='top'
        preventOverflow={false}
        content={<ValidationErrorMessage text={error} />}
      >
        <div className='file-upload-container'>
          <NvFilePicker
            accept={acceptPatterns}
            onChange={(files) => onUploadFile(files, true, false)}
            maxSize={sizeInBytes}
            multiple={multiple}
            onSelectError={onSelectError}
            data-qa={dataQa}
          >
            <div className='upload-file'>
              <NvIcon icon='upload' size='large' />
              <div className='upload-file-text-wrapper text-center'>
                <span className='upload-file-text'>{customText || getFileUploadText()}</span>
                {showInfoText && (
                  <div className='upload-file-text-info text-center'>
                    {getRecommendedFileTypes()} <span>{t.FILE_UPLOAD.UP_TO_SIZE(maxSize)}</span>
                  </div>
                )}
              </div>
            </div>
          </NvFilePicker>
          {actionButton && showactionButton && (
            <div className='record'>
              <div className='or'>{t.FILE_UPLOAD.OR()}</div>
              {actionButton}
            </div>
          )}
        </div>
      </NvPopover>
      {showRecordingOverlay && (
        <RecordingOverlay
          mediaSourceType={getMediaSourceType()}
          initialMediaStream={mediaStream}
          onClose={onCloseRecordingOverlay}
          submitButtonText={recordingOverlaySubmitButtonText}
        />
      )}
    </div>
  );
};

export default NvFileUpload;
