import LectureComponentDeadline from 'components/lecture-component-deadline';
import { useLectureComponentContext } from 'components/lecture-component-provider';
import { useGetPollLectureComponent, useUpdatePoll } from './poll-utils';

interface PollDeadlineProps {
}

/* @ngInject */
export default function PollDeadline(props: PollDeadlineProps) {
  const lectureComponentData = useLectureComponentContext();
  const lectureComponent = useGetPollLectureComponent();
  const updater = useUpdatePoll();


  return (
    <LectureComponentDeadline
      componentName='poll'
      expirationDate={lectureComponent.poll.expirationDate}
      catalogId={lectureComponentData.catalogId}
      lecturePageId={lectureComponentData.lecturePageId}
      isEdit={lectureComponentData.isEdit}
      hasHardDeadlines={lectureComponent.poll.hardDeadline}
      onHardDeadlineChange={(value) => {
        updater({
          hardDeadline: value,
        });
      }}
      onDeadlineChange={(date) => {
        updater({
          expirationDate: date?.toISOString(),
        });
      }}
      isDeadlineEditable={lectureComponentData.isDeadlineEditable}
    />
  );
}
