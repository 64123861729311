import React from 'react';

import moment from 'moment';

import { connect, useSelector } from 'react-redux';
import t from 'react-translate';

// schemas
import { RootState } from 'redux/schemas';
import { TimelineQuiz } from 'redux/schemas/models/quiz';
import { ActivityType } from 'redux/schemas/models/activity';
import { ComponentKeyPluralized } from 'redux/schemas/models/lecture-component';

// actions
import { setActivityExpandedInAutomatedCommunications } from 'redux/actions/course-communications';

// selectors
import { getVideoPractice, hasVideoPracticeGotCommunicationError } from 'redux/selectors/timeline';
import { isActivityExpandedInAutomatedCommunications } from 'redux/selectors/course-communications';

// components
import NvIcon from 'shared/components/nv-icon';
import NvStackedTab from 'shared/components/nv-stacked-tab';

import CommunicationCreateNewDropdown from 'communications/course_communications/components/communication-create-new-dropdown';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';
import { VideoPracticeActivity, VideoPracticeScenario } from 'redux/schemas/models/video-practice';
import { useAppDispatch } from 'redux/store';
import NvTooltip from 'shared/components/nv-tooltip';

type VideoPracticeLectureComponentProps = {
  practiceActivityId: number
};

const VideoPracticeActivityLectureComponent = (props: VideoPracticeLectureComponentProps) => {
  const dispatch = useAppDispatch();

  const isExpanded = useSelector<RootState, boolean>((state) => isActivityExpandedInAutomatedCommunications(state, ComponentKeyPluralized.VIDEO_PRACTICE, props.practiceActivityId));
  const practiceActivity = useSelector<RootState, VideoPracticeActivity>((state) => state.models.practiceActivities[props.practiceActivityId]);
  const practiceScenario = useSelector<RootState, VideoPracticeScenario>((state) => state.models.practiceScenarios[practiceActivity.scenarioId]);
  const hasCommunicationError = useSelector<RootState, boolean>((state) => hasVideoPracticeGotCommunicationError(state, props.practiceActivityId));

  return (
    <React.Fragment>
      <div className={`lecture-component-row ${isExpanded && practiceActivity?.communicationsCount > 0 && 'expanded-box'}`}>
        <NvIcon icon='media-practice' size='smallest' />
        <div className='description'>{practiceScenario.title}</div>
        {practiceActivity?.hardDeadline && (
          <NvTooltip text={t.TIMELINE.HARD_DEADLINE()} placement='top'>
            <div className='spacing'>
              <NvIcon icon='harddeadline' size='smallest' />
            </div>
          </NvTooltip>
        )}
        <div className='date'>
          {practiceActivity?.deadline && t.LECTURE_PAGES.COMPONENTS.DUE_DATE.STUDENT_VIEW(moment(practiceActivity.deadline).format('LLL'))}
        </div>
        <CommunicationCreateNewDropdown activityType={ActivityType.VIDEO_PRACTICE} activityId={props.practiceActivityId} />
        {practiceActivity?.communicationsCount > 0 && (
          <NvStackedTab
            count={practiceActivity.communicationsCount}
            hasError={hasCommunicationError}
            isExpanded={isExpanded}
            errorMessage={t.COURSE_COMMUNICATIONS.WARNINGS.ACTIVITY_COMMUNICATION()}
            onClick={() => dispatch(setActivityExpandedInAutomatedCommunications({
              type: ComponentKeyPluralized.VIDEO_PRACTICE,
              id: props.practiceActivityId,
              isExpanded: !isExpanded,
            }))}
          />
        )}
      </div>
      {
        // Communications
        isExpanded && practiceActivity?.communications && practiceActivity.communications.map(communicationId => (
          <CommunicationItem key={communicationId.toString()} communicationId={communicationId} />
        ))
      }
    </React.Fragment>
  );
};

export default VideoPracticeActivityLectureComponent;
