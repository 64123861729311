import { css } from '@emotion/react';
import { useContext, useState } from 'react';
import t from 'react-translate';
import { map } from 'underscore';

import { QuizAIQuestion } from 'redux/schemas/models/quiz';

import { black, gray4, gray5, gray7, primary } from 'styles/global_defaults/colors';
import { halfSpacing, largeSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';

import ClickableContainer from 'components/clickable-container';
import NvIcon from 'shared/components/nv-icon';
import { ReactComponent as CheckedBox } from 'styles/icons/checked-box.svg';
import { getAlphabetCharacter } from 'quizzes/components/utils';
import NvTooltip from 'shared/components/nv-tooltip';
import { ReactComponent as UncheckedBox } from 'styles/icons/unchecked-box.svg';
import { AiQuestionsModalContext } from './ai-questions-modal';
import { config } from '../../../../config/config.json';

type QuestionCardProps = {
  question: QuizAIQuestion;
  questionNo: number;
};

const QuestionCard = (props: QuestionCardProps) => {
  const { question, questionNo } = props;

  const [showExplanation, setShowExplanation] = useState(false);
  const [onHover, setOnHover] = useState(false);
  const { selectedQuestionIds, onQuestionClick } = useContext(AiQuestionsModalContext);

  const selected = selectedQuestionIds.includes(question.id);

  const styles = css`
    &.question-card {
      border-radius: 4px;
      background-color: ${gray7};
      padding: ${threeQuartersSpacing}px;
      padding-right: 45px;
      border: 1px solid ${gray7};

      ${(selected || onHover) && css`
        padding-right: ${threeQuartersSpacing}px;

        .question-no {
          color: ${black};
          font-weight: 700;
          border: 1px solid ${gray4};
        }
      `};

      ${selected && css`
        background-color: #F4FAFD;
        border-color: ${primary};
      `};
    }

    .question-no {
      min-width: ${largeSpacing}px;
      height: ${largeSpacing}px;
      border-radius: 4px;
      border: 1px solid ${gray5};
    }
    .check-box {
      min-width: ${largeSpacing}px;
    }

    .options {
      gap: ${halfSpacing}px;
    }
  `;

  const toggleExplanation = (event) => {
    event.stopPropagation();
    setShowExplanation(!showExplanation);
  };

  return (
    <NvTooltip
      text={t.FORM[(onHover && selected) ? 'CLICK_TO_UNSELECT' : 'CLICK_TO_SELECT']()}
      enabled={onHover}
      placement='top'
    >
      <ClickableContainer
        css={styles}
        className='question-card d-flex mb-4'
        onClick={() => onQuestionClick(question.id, !selected)}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        data-qa={config.pendo.novoAi.quiz.question}
        data-qa-question-index={`${config.pendo.novoAi.quiz.question}_${questionNo}`}
      >
        <div className='question-no d-flex align-items-center justify-content-center text-large-body'>
          {questionNo}
        </div>
        <div className='d-flex flex-column pl-2'>
          <div className='text-large-body bold'>{question.questionText}</div>
          <div className='options d-flex flex-column pt-4 text-large-body'>
            {map(question.responseOptionsAttributes, (option, index) => (
              <div className='d-flex' key={`${option.optionContent}-${index}`}>
                <span>{getAlphabetCharacter(index)}.</span>
                <div className='pl-1'>{option.optionContent}</div>
              </div>
            ))}
          </div>
          <ClickableContainer
            className='d-flex text-primary align-items-center mt-4 py-1'
            onClick={toggleExplanation}
            data-qa={config.pendo.novoAi.quiz.explanation}
            data-qa-question-index={`${config.pendo.novoAi.quiz.explanation}_${questionNo}`}
          >
            <span>{t.LECTURE_PAGES.COMPONENTS.QUIZ.AI_QUESTIONS.EXPLANATION()}</span>
            <NvIcon
              icon={`arrow-${showExplanation ? 'up' : 'down'}`}
              size='xs-smallest'
              className='pl-1'
            />
          </ClickableContainer>
          {showExplanation && (
          <div className='options d-flex flex-column pt-2 text-large-body'>
            {map(question.responseOptionsAttributes, (option, index) => (
              <div
                className={`d-flex ${option.isCorrect && 'text-success bold'}`}
                key={`${option}-${index}`}
              >
                <span className='pr-1'>{getAlphabetCharacter(index)}.</span>
                <div>{option.explanation}</div>
              </div>
            ))}
          </div>
          )}
        </div>
        {selected && (
          <CheckedBox
            width={largeSpacing}
            height={largeSpacing}
            className='check-box ml-auto'
            color={primary}
          />
        )}
        {!selected && onHover && (
          <UncheckedBox
            width={largeSpacing}
            height={largeSpacing}
            className='check-box ml-auto'
            color={gray5}
          />
        )}
      </ClickableContainer>
    </NvTooltip>
  );
};

export default QuestionCard;
