import { css } from '@emotion/react';
import React from 'react';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import NvTooltip from './nv-tooltip';

export type WhiteBlackColorButtonsProps = {
  whiteTooltipText: string,
  blackTooltipText: string,
  setColor: (val: string) => void,
};

const styles = css`
  height: 16px;

  .circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    cursor: pointer;

    &.white-circle {
      background-color: white;
      border-color: black;
      margin-right: ${standardSpacing}px;
    }

    &.black-circle {
      background-color: black;
      border-color: white;
    }
  }
`;

/** Creates two white & black circle buttons used to choose the foreground text color in the institution
 * dashboard and the lecture page title */
export const WhiteBlackColorButtons = (props: WhiteBlackColorButtonsProps) => (
  <div css={styles}>
    <NvTooltip text={props.whiteTooltipText}>
      <div className='circle white-circle' onClick={() => props.setColor('#fff')} />
    </NvTooltip>
    <NvTooltip text={props.blackTooltipText}>
      <div className='circle black-circle' onClick={() => props.setColor('#000')} />
    </NvTooltip>
  </div>
);

export default WhiteBlackColorButtons;
