import { css, jsx } from '@emotion/react';

// Components
import { NvTooltip } from 'shared/components/nv-tooltip';

// Styles
import { standardSpacing, halfSpacing, quarterSpacing, largeSpacing } from 'styles/global_defaults/scaffolding';
import { primary, lightBlue } from 'styles/global_defaults/colors';

type SortPillProps = {
  label: string
  toolTipText: string
  isSelected: boolean
};

const styles = (isSelected: boolean) => css`
  height: ${largeSpacing}px;
  background-color: ${isSelected ? lightBlue : '#fff'};
  border: 1px solid ${isSelected ? primary : lightBlue};
  border-radius: ${standardSpacing}px;
  cursor: pointer;

  :hover {
    background-color: ${lightBlue};
    border-color: ${primary};
  }
`;

const SortModePill = (props: SortPillProps) => (
  <NvTooltip text={props.toolTipText}>
    <div
      css={styles(props.isSelected)}
      className='d-inline-block pt-1 pb-1 pr-2 pl-2 text-primary'
    >
      {props.label}
    </div>
  </NvTooltip>
);
export default SortModePill;
