import React from 'react';
import { css } from '@emotion/react';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import {
  gray1,
  gray3,
  gray5,
  gray6,
  gray7,
  lightBlue,
  primary,
} from 'styles/global_defaults/colors';
import { openSans, semiBoldFontWeight } from 'styles/global_defaults/fonts';
import {
  halfSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import { handheld } from 'styles/global_defaults/media-queries';
import { NvFilter, NvFilterProps } from './nv-filter';
import { config } from '../../../../config/pendo.config.json';

export type FiltersData = NvFilterProps;

type FiltersBarProps = {
  filters: Array<FiltersData>;
  hasFiltersSelectedOrApplied: boolean;
  onApplyFilters: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClearFilters: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export const NvFiltersBar = ({
  filters,
  hasFiltersSelectedOrApplied,
  onApplyFilters,
  onClearFilters,
}: FiltersBarProps) => {
  const styles = css`
    width: 100%;
    height: 55px;
    padding: 0px ${standardSpacing}px;
    background: ${gray7};
    border-bottom: 1px solid ${gray6};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: ${standardSpacing}px;

    ${handheld(css`
      height: initial;
      padding: ${halfSpacing}px ${standardSpacing}px;
      gap: ${halfSpacing}px;
    `)};

    .filters,
    .buttons {
      display: flex;
      flex-wrap: wrap;
      gap: ${halfSpacing}px;
    }

    .filters {
      font-family: ${openSans};

      div.bs4-dropdown {
        border: none;

        div[role='button'] {
          overflow: hidden;
          border-radius: inherit;
          height: 30px;
          padding: ${halfSpacing}px;
          border: 1px solid ${lightBlue};
          border-radius: ${threeQuartersSpacing}px;
          gap: ${quarterSpacing}px;

          .icon {
            transition: transform 0.218s ease-in;
          }

          &.selected {
            border: 1px solid ${primary};
            background: ${lightBlue};
          }
        }

        &.show div[role='button'] .icon {
          transform: rotate(-180deg);
        }

        &:hover {
          background: ${lightBlue};
        }

        .bs4-dropdown-menu.filter-menu.show {
          min-width: 200px;
          max-height: 400px;
          overflow: auto;
          padding: ${halfSpacing}px ${standardSpacing}px;
          border-radius: 4px;
          border: 1px solid ${gray5};
          box-shadow: 1px 2px 2px rgba(0,0,0,0.1);
          display: flex !important;
          flex-direction: column;
          gap: ${halfSpacing}px;
        }

        .nv-dropdown-header.title {
          height: initial;
          margin: 0;
          padding: 0;
          font-weight: ${semiBoldFontWeight};
          color: ${gray1};
        }

        .nv-checkbox-item.filter-item {
          padding: 0;

          label {
            margin-bottom: 0;
          }
        }
      }
    }

    .buttons {
      button {
        padding: ${quarterSpacing}px ${threeQuartersSpacing}px;
        font-weight: ${semiBoldFontWeight};
      }

      .bs4-btn[disabled], .bs4-btn-primary[disabled] {
        opacity: initial;
        color: ${gray3};
        font-weight: ${semiBoldFontWeight};
        line-height: 24px;
      }
    }
  `;

  const applyFiltersStyles = css`
    box-shadow: none;
    transition: background-color 0.3s ease-in-out;
  `;

  const handleOnApplyFilters = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onApplyFilters(e);
    e.currentTarget.blur();
  };

  return (
    <section css={styles}>
      <div className='filters'>
        {filters.map(filter => (
          <NvFilter
            key={filter.title}
            title={filter.title}
            selected={filter.selected}
            items={filter.items}
          />
        ))}
      </div>
      <div className='buttons'>
        <Button
          variant='link'
          className='semi-bold pl-0 pr-0'
          disabled={!hasFiltersSelectedOrApplied}
          onClick={onClearFilters}
          data-qa={config.pendo.learningCatalog.reset}
        >
          {t.SHARED.RESET()}
        </Button>
        <Button
          variant='primary'
          css={applyFiltersStyles}
          disabled={!hasFiltersSelectedOrApplied}
          onClick={handleOnApplyFilters}
          data-qa={config.pendo.learningCatalog.applyFilters}
        >
          {t.SHARED.APPLY_FILTERS()}
        </Button>
      </div>
    </section>
  );
};

export default NvFiltersBar;
