import React, {
  useContext, useState, useRef, useEffect,
} from 'react';
import { Course } from 'redux/schemas/models/course';
import { Institution, CourseCloning } from 'redux/schemas/models/institution';
import { Enrollment } from 'redux/schemas/models/my-account';
import { css, SerializedStyles } from '@emotion/react';
import { success, primary, gray5, black, gray3 } from 'styles/global_defaults/colors';
import { semiBoldFontWeight } from 'styles/global_defaults/fonts';
import { halfSpacing, standardSpacing, quarterSpacing, createGridStyles } from 'styles/global_defaults/scaffolding';
import { AngularServicesContext } from 'react-app';
import t from 'react-translate';
import { NvCourseAvatar } from 'components/nv-course-avatar';
import moment from 'moment';
import { NvTableRowProps, BKG_ROW_Z_INDEX, NvTableCellProps, RowsHoverContext, useIsRowHovered } from 'shared/components/nv-responsive-table';
import _ from 'underscore';
import { NvPopover } from 'shared/components/nv-popover';
import { NvTooltip } from 'shared/components/nv-tooltip';
import { isHandheld } from 'styles/global_defaults/media-queries';
import { setCourseAccessModalProps } from 'redux/actions/course-access-modal';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserEnrollments } from 'redux/selectors/users';
import { Button } from 'react-bootstrap';
import Badge from 'cohort_management/components/badge';
import ClickableContainer from 'components/clickable-container';
import CourseRowOptions from './course-row-options';
import { InstitutionDashboardContext } from './institution-dashboard-home';
import { Filters } from './filter-flyout-modal';
import { config } from '../../../config/config.json';

export type CourseRowExtraProps = { institution: Institution };

export type CourseCellProps = NvTableCellProps<Course, CourseCloning, CourseRowExtraProps> & {
  course: Course,
  optionsCellRef?: React.MutableRefObject<HTMLDivElement>,
  isBeingCloned?: boolean,
  enrollment?: Enrollment,
};

/** All of these styles are reated as constants outside the definition of CourseRow, because otherwise Emotion
 * will create new <style> tags every time it adds a row. That demolishes browser performance very quickly. */
const commonStyles = css`
  height: 100%;
  display: flex;
  align-items: center;
`;

const nameStyles = css`
  ${commonStyles};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${halfSpacing}px;
  padding-bottom: ${halfSpacing}px;

  &:not(.disabled) {
    pointer-events: none; /* Allows events to pass through the bkg row. */
    /* Place above the bkg row to take mouse over focus */
    z-index: ${BKG_ROW_Z_INDEX + 1} !important;
  }

  /* TODO: Move this into the NvAvatar */
  .name-panel {
    margin-left: ${halfSpacing}px;
    flex-direction: column;
    align-items: flex-start;

    .view-primary-link, .cohorts-link {
      color: ${primary};
      pointer-events: all;
      display: inline-block;

      &.gray {
        color: ${gray3};
        pointer-events: none;
      }
    }

    .view-primary-link {
      opacity: 0;
    }
  }

  &:not(.disabled) {
    .course-name {
      pointer-events: all;
      display: inline-block;
      margin-right: ${halfSpacing}px;
    }

    .course-name:hover {
      color: ${primary};
    }
  }

  /* Displayed on hover */
  .created-by-name {
    display: none;
  }

  grid-column: 1;

  .course-name-btn-link {
    padding: 0;
    color: ${black};
    text-decoration: none;
    font-weight: ${semiBoldFontWeight};

    &:hover,
    &:focus {
      color: ${primary};
    }

    &:focus {
      border-radius: unset;
      outline-offset: 0;
    }
  }
`;
const releaseDateStyles = css`
${commonStyles};
  width: 100%;
  pointer-events: none;
  z-index: ${BKG_ROW_Z_INDEX + 1} !important;
`;

const closeDateStyles = css`
  ${commonStyles};
  z-index: ${BKG_ROW_Z_INDEX + 1} !important;
  pointer-events: none;
`;

const numEnrolleesStyles = css`
  ${commonStyles};

  &:not(.disabled) {
    z-index: ${BKG_ROW_Z_INDEX + 1} !important;
    pointer-events: none;
  }

  .num-enrollees-text {
    pointer-events: all;
  }
`;

const completionRateStyles = css`
  ${commonStyles};
  display: flex;
  z-index: ${BKG_ROW_Z_INDEX + 1} !important;
  pointer-events: none;
`;

const optionsStyles = css`
  ${commonStyles};
`;

export const completionRateProgressStyles = css`
  width: 100%;
  margin-right: ${standardSpacing}px;

  .completion-bar {
    position: relative;
    margin-bottom: ${quarterSpacing}px;
    height: ${quarterSpacing}px;
    display: flex;
    width: 100%;
    border-radius: 28px;
    overflow: hidden;

    .divider {
      position: absolute;
      background-color: white;
      height: ${quarterSpacing}px;
      width: 1px;
    }
  }

  .completion-bar-part {
    &.completed {
      background-color: ${success}
    }

    &.in-progress {
      background-color: ${primary};
    }

    &.remaining {
      background-color: ${gray5};
    }
  }

  .completion-pct {
    width: ${standardSpacing * 2}px;
    padding-bottom: ${standardSpacing}px;
  }
`;


const CourseRow = (props: NvTableRowProps<Course, CourseCloning, CourseRowExtraProps>) => {
  const optionCellRef = useRef<HTMLDivElement>(null);

  const { setRowsHoverState } = React.useContext(RowsHoverContext);

  const cells: [(props: CourseCellProps) => JSX.Element, SerializedStyles][] = [
    [NameCell, nameStyles],
    [ReleaseDateCell, releaseDateStyles],
    [CloseDateCell, closeDateStyles],
    [NumEnrolleesCell, numEnrolleesStyles],
    [CompletionRateCell, completionRateStyles],
    [OptionsCell, optionsStyles],
  ];

  const mobileCells = [cells[0], cells[cells.length - 1]];

  const course = props.data;
  const [showMobileView, setShowMobileView] = useState(isHandheld());
  const rowCells = showMobileView ? mobileCells : cells;

  const isBeingCloned = !_.isEmpty(props.extraObject);

  // Show disabled styles if the row is currently being deleted or being cloned
  const isDisabled = course?.isBeingDeleted || isBeingCloned;

  // Hide most of the cells when in a phone view
  // TODO: Is it safe to add all of these event listeners?
  useEffect(() => {
    const listen = window.addEventListener('resize', _.debounce(() => {
      setShowMobileView(isHandheld());
    }, 100));

    return listen;
  }, []);

  const enrollments = useSelector(getCurrentUserEnrollments);
  const enrollment = enrollments.find(({ courseId }) => courseId === course.id);

  return (
    <React.Fragment>
      {
        rowCells.map((cell, i) => {
          const onMouseLeave = () => setRowsHoverState((prev) => ({ ...prev, [props.rowKey]: false }));
          const onMouseEnter = () => setRowsHoverState((prev) => ({ ...prev, [props.rowKey]: true }));

          const cellProps: CourseCellProps = {
            ...props,
            course,
            reactKey: `${props.rowIndex}-${i + 1}`,
            serializedStyles: cell[1],
            rowClasses: isDisabled ? ' disabled' : '',
            divProps: {
              style: createGridStyles(i + 1, props.rowIndex, i + 2, props.rowIndex + 1),
              onMouseEnter,
              onMouseLeave,
            },
            // Overwrite the props.disabled with the local state so it can be dynamically updated
            disabled: isDisabled,
            optionsCellRef: optionCellRef,
            isBeingCloned,
            enrollment,
          };
          return cell[0](cellProps);
        })
      }
    </React.Fragment>
  );
};

const NameCell = (props: CourseCellProps) => {
  const { course, enrollment } = props;
  const angularServices = useContext(AngularServicesContext);
  const dispatch = useDispatch();
  const isHover = useIsRowHovered(props.rowKey);
  const { setFilters } = React.useContext(InstitutionDashboardContext);

  const goToCourseUrl = angularServices.$state.href('course-home', {
    catalogId: course.catalogId,
  });

  const openCourseAccessModal = () => {
    dispatch(setCourseAccessModalProps({ catalogId: course.catalogId }));
  };

  // Open course access modal if user is not enrolled in course, otherwise redirect it to course home
  const renderLinkOrButton = enrollment
    ? <a href={goToCourseUrl} target='_blank' rel='noopener noreferrer'>{course.name}</a>
    : <Button className='course-name-btn-link text-left border-0' variant='link' onClick={openCourseAccessModal}>{course.name}</Button>;

  const courseCloning = props.extraObject as CourseCloning;

  const isCohort = props.isBeingCloned ? courseCloning.isCohort : course.isCohort;
  const isPrimary = props.isBeingCloned ? courseCloning.isPrimary : course.isPrimary;

  const styles = css`
    .course-avatar-container {
      position: relative;

      .cohort-management-badge {
        pointer-events: all;
      }
    }

    ${props.serializedStyles};

    .name-panel .view-primary-link:focus {
      opacity: 1;
    }

    ${isHover && css`
      .name-panel .view-primary-link {
        opacity: 1;
      }

      .created-by-name {
        display: block;
      }
    `};
  `;

  return (
    <div className={`name-cell${props.rowClasses ?? ''}`} css={styles} key={props.reactKey} {...props.divProps}>
      <div className='course-avatar-container'>
        <NvCourseAvatar course={course} institution={props.extraProps.institution} />
        <Badge
          className='cohort-management-badge'
          course={{
            isCohort,
            isPrimary,
          } as Course}
        />
      </div>
      <div className='name-panel'>
        {/* Deletion text that appears when a course is being deleted */}
        { course.isBeingDeleted && <div className='text-small font-weight-bold text-warning'>{t.COURSES.DASHBOARD_TABLE.DELETION_IN_PROGRESS()}</div> }
        {/* Cloning in progress text during a course clone */}
        { props.isBeingCloned && <div className='text-small font-weight-bold text-warning'>{t.COURSES.DASHBOARD_TABLE.CLONING_IN_PROGRESS()}</div> }
        <div>
          {/* Clickable course name that navigates to course home page */}
          <div className='page-title-xs font-weight-bold black course-name'>
            <NvTooltip
              text={course.isProgram ? t.INSTITUTIONS.CLICK_TO_VISIT_PROGRAM() : t.INSTITUTIONS.CLICK_TO_VISIT_COURSE()}
              placement='top'
              preventOverflow={false}
              enabled={!props.disabled}
            >
              { !props.disabled ? renderLinkOrButton : <div>{course.name}</div> }
            </NvTooltip>
          </div>
          {course.isCohort && (
            <ClickableContainer
              data-qa={config.pendo.orgDashboard.viewPrimary}
              data-qa-id={`${config.pendo.orgDashboard.viewPrimary}_${course.catalogId}`}
              onClick={() => setFilters({
                [Filters.PRIMARY_SINGLE]: course.primary.catalogId,
              })}
              className='text-small bold view-primary-link'
            >
              {t.COHORT_MANAGEMENT.VIEW_PRIMARY()}
            </ClickableContainer>
          )}
          {course.isPrimary && (
            <ClickableContainer
              disabled={!course.numCohorts}
              data-qa={config.pendo.orgDashboard.viewCohorts}
              data-qa-id={`${config.pendo.orgDashboard.viewCohorts}_${course.catalogId}`}
              onClick={() => setFilters({
                [Filters.COHORTS_OF]: course.id,
              })}
              className={`text-small bold cohorts-link${course.numCohorts ? '' : ' gray'}`}
            >
              {t.COHORT_MANAGEMENT.VIEW_COHORTS(course.numCohorts)}
            </ClickableContainer>
          )}
        </div>
        <div className='text-small font-weight-bold'>
          {props.isBeingCloned ? courseCloning.nickname : course.catalogId}
        </div>
        <div className='created-by-name text-small font-weight-bold'>
          {t.COURSES.DASHBOARD_TABLE.CREATED_BY(course.creator?.fullName || 'NovoEd Team')}
        </div>
      </div>
    </div>
  );
};

const ReleaseDateCell = (props: CourseCellProps) => {
  const { course } = props;
  return (
    <div className={`release-date-cell${props.rowClasses}`} css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      {course.releaseDate && moment(course.releaseDate).format('MM/DD/YYYY')}
    </div>
  );
};

const CloseDateCell = (props: CourseCellProps) => {
  const { course } = props;
  let closeDateText = '';

  if (course.closeDate) {
    closeDateText = moment(course.closeDate).format('MM/DD/YYYY');
  }

  if (course.isSelfPaced && course.enrollmentLimitInDays) {
    if (course.closeDate) {
      closeDateText += '<br />';
    }
    closeDateText += `${t.COURSES.DASHBOARD_TABLE.SELF_PACE_BASED()}<br/>${t.COURSES.DASHBOARD_TABLE.DAYS_ACCESS(course.enrollmentLimitInDays.toString())}`;
  }

  if (!course.closeDate && !course.enrollmentLimitInDays && course.isSelfPaced) {
    closeDateText = t.COURSES.DASHBOARD_TABLE.SELF_PACE_BASED();
  }

  return (
    <div className={`close-date-cell${props.rowClasses}`} css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      {/* eslint-disable-next-line react/no-danger */}
      <span dangerouslySetInnerHTML={{ __html: closeDateText }} />
    </div>
  );
};

const NumEnrolleesCell = (props: CourseCellProps) => {
  const { course } = props;
  const numEnrolledPopover = (
    <div className='bs4-row bs4-no-gutters page-title-xxs'>
      <div className='bs4-col-10 gray-3'>{t.COURSES.DASHBOARD_TABLE.NUM_UNENROLLED_BY_TEACHING_TEAM()}</div>
      <div className='bs4-col-2 text-right'>{course.numBanned}</div>
      <div className='w-100' />
      {' '}
      {/* For some reason this class does not get auto prefixed */}
      <div className='bs4-col-12'>&nbsp;</div>
      {' '}
      {/* empty row */}
      <div className='w-100' />
      <div className='bs4-col-10 gray-3'>{t.COURSES.DASHBOARD_TABLE.NUM_WITHDRAWN()}</div>
      <div className='bs4-col-2 text-right'>{course.numWithdrawn}</div>
    </div>
  );

  const popoverEnabled = !!course.numEnrolled && !props.disabled && course.numEnrolled !== 0;

  return (
    <div className={`num-enrollees-cell${props.rowClasses}`} css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      <NvPopover content={numEnrolledPopover} placement='top' preventOverflow={false} enabled={popoverEnabled} showOnHover>
        <span className={`num-enrollees-text font-weight-bold ${popoverEnabled ? 'text-primary' : ''}`}>{course.numEnrolled ?? 0}</span>
      </NvPopover>
    </div>
  );
};

export type CompletionRateProgressProps = {
  course: Course;
};

export const CompletionRateProgress = ({ course }: CompletionRateProgressProps) => {
  const completionPct = Math.round(course?.learnersCompleted?.percentage ?? 0);
  const inProgressPct = Math.round(course?.learnersProgress?.percentage ?? 0);
  const remainingPct = 100 - inProgressPct - completionPct;

  let courseStatusStyle = '';

  const getCourseStatusText = () => {
    const courseDuration = moment(course.closeDate).diff(moment(course.releaseDate), 'hours');
    const remainingCourseHours = Math.max(moment(course.closeDate).diff(moment(), 'hours'), 0);
    const courseCompletionPct = 1 - (remainingCourseHours / courseDuration);

    // Course is closed
    if (course.closeDate !== null && moment(course.closeDate) < moment()) {
      return t.COURSES.DASHBOARD_TABLE.CLOSED();
    }
    // Course is yet to be released
    if (moment(course.releaseDate) > moment()) {
      const releaseDayCount = moment(course.releaseDate).diff(moment(), 'days');
      if (releaseDayCount === 0) {
        const releaseHourCount = moment(course.releaseDate).diff(moment(), 'hours');
        if (releaseHourCount === 0) {
          return t.COURSES.DASHBOARD_TABLE.RELEASING_IN_HOUR();
        }
        return t.COURSES.DASHBOARD_TABLE.RELEASING_IN_X_HOURS(releaseHourCount);
      }
      return t.COURSES.DASHBOARD_TABLE.RELEASING_IN_X_DAYS(releaseDayCount);
    }
    // Show 'Closing in X Days' if there's only 10% of the time remaining in a course
    if (course.closeDate && courseCompletionPct >= 0.9) {
      courseStatusStyle = 'text-primary';
      if (remainingCourseHours > 24) {
        const remainingCourseDays = moment(course.closeDate).diff(moment(), 'days');
        return t.COURSES.DASHBOARD_TABLE.CLOSING_IN_X_DAYS(remainingCourseDays);
      }
      if (remainingCourseHours === 0) {
        return t.COURSES.DASHBOARD_TABLE.CLOSING_IN_HOUR();
      }
      return t.COURSES.DASHBOARD_TABLE.CLOSING_IN_X_HOURS(remainingCourseHours);
    }
    // Normal 'in progress' status
    courseStatusStyle = 'text-primary';
    return t.COURSES.DASHBOARD_TABLE.IN_PROGRESS();
  };

  const courseStatusText = getCourseStatusText();

  // Calculates positions for the white divider bars between segments of the completion progress bars.
  // We make special case offets arouns a bar being placed at 1% or 99% because the white bars being drawin
  // the rounded borers at the edge breaks the aesthetic of a rounded bar
  const dividerAtPos = (pos: number) => {
    if (pos < 1) {
      return null;
    }
    if (pos === 1) {
      return `calc(${pos}% + 1px)`;
    }
    if (pos === 99) {
      return `calc(${pos}% - 1px)`;
    }
    if (pos < 99) {
      return `${pos}%`;
    }
    return null;
  };

  const firstDividerPos = dividerAtPos(completionPct) ?? dividerAtPos(completionPct + inProgressPct);
  const firstDividerStyle: React.CSSProperties = {};
  if (firstDividerPos) {
    firstDividerStyle.left = firstDividerPos;
  } else {
    firstDividerStyle.display = 'none';
  }

  const secondDividerPos = dividerAtPos(completionPct + inProgressPct);
  const secondDividerStyle: React.CSSProperties = {};

  if (secondDividerPos) {
    secondDividerStyle.left = secondDividerPos;
  } else {
    secondDividerStyle.display = 'none';
  }

  return (
    <React.Fragment>
      <div className='completion-bar-area' css={completionRateProgressStyles}>
        <div className='completion-bar'>
          <div className='completion-bar-part completed' style={{ width: `${completionPct}%` }} />
          <div className='divider first-divider' style={firstDividerStyle} />
          <div className='completion-bar-part in-progress' style={{ width: `${inProgressPct}%` }} />
          <div className='divider second-divider' style={secondDividerStyle} />
          <div className='completion-bar-part remaining' style={{ width: `${remainingPct}%` }} />
        </div>
        <div className={`status-text page-title-xxs font-weight-bold ${courseStatusStyle}`}>{courseStatusText}</div>
      </div>
      <div className={`completion-pct ${completionPct > 0 ? 'text-success' : ''}`}>
        {completionPct}
        %
      </div>
    </React.Fragment>
  );
};

const CompletionRateCell = (props: CourseCellProps) => {
  const { course } = props;

  return (
    <div className={`completion-rate-cell${props.rowClasses}`} css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      <CompletionRateProgress course={course} />
    </div>
  );
};

const OptionsCell = (props: CourseCellProps) => (
  <CourseRowOptions cellProps={props} key={props.reactKey} />
);

export default CourseRow;
