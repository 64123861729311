import store from 'redux/store';
import { TextAlign } from 'shared/components/nv-tooltip';
import { updateLectureComponentFromAngular } from 'redux/actions/lecture-components';
import { getLecturePage } from '../../../redux/actions/lecture-pages';
import t from '../../../react-translate';
import submitQuiz from '../../services/component-submit-quiz.ts';
import { LecturePageMode } from '../../components/index.tsx';

export default {
  bindings: {
    lectureComponent: '<',
    editMode: '<',
    restrictedEditMode: '<',
    reorderMode: '<',
    linkedEditMode: '<',
    index: '<',
    context: '<',
  },
  controller: function ctrl(
    $controller,
    _,
    AlertMessages,
    RailsRoutes,
    nvUtil,
    $timeout,

    CurrentCourseManager,
    CurrentUserManager,
    TimelinesManager,
    CurrentPermissionsManager,
    ReactTimelineService,
    config,
    $state,
  ) {
'ngInject';
    angular.extend(this, $controller('LectureComponentBaseCtrl'));
    const vm = this;

    vm.config = config;
    vm.isContentManagementCollection = CurrentCourseManager?.course?.isContentManagementCollection ?? false;
    const { contentManagementCollection, contentManagementLecturePage } = vm.lectureComponent.lecturePage;

    vm.remainUnansweredRequiredQuestions = () => vm.lectureComponent.quiz.questions.some(
      (question) => {
        if (question.isRequired && question.type === 'MultipleChoiceMultipleAnswerQuestion') {
          return !question.hasSelectedOneAtLeast();
        }

        return false;
      },
    );

    vm.submit = (lectureComponent) => {
      submitQuiz(lectureComponent)
        .then((response) => {
          const { quiz } = lectureComponent;
          lectureComponent.shouldShowFeedback = true;
          quiz.pointsReceived = quiz.submission.pointsReceived;

          if (CurrentCourseManager.course.gamificationEnabled) {
            // TODO: Thse timelines manager updates are likely broken
            ReactTimelineService.updateTimeline(lectureComponent.lecturePage.id);
            TimelinesManager.updateComponentPointsAndProgress(lectureComponent.lecturePage.id, lectureComponent.type, lectureComponent.id,
              quiz.submission.pointsReceived);

            if (quiz.submission.pointsReceived) {
              vm.showPointsModal(quiz.submission, null);
            }
          } else {
            ReactTimelineService.updateTimeline(lectureComponent.lecturePage.id);
            TimelinesManager.updateComponentProgress(lectureComponent.lecturePage.id, lectureComponent.type, lectureComponent.id, lectureComponent.quiz.progress);
            AlertMessages.success('', 'SURVEYS.RESPONSES_SUBMITTED_SUCCESSFULLY');
          }

          if (!CurrentUserManager.isAdmin()) {
            nvUtil.addUserToHeads(CurrentUserManager.user, lectureComponent.quiz.usersRecentlyCompleted);
          }
          // Update the redux survey modal
          store.dispatch(updateLectureComponentFromAngular({ 
            survey: {
              ...lectureComponent.survey,
              submission: quiz.submission,
            },
          }));
          /**
           * There is no focused element when displaying survey feedback after submission.
           * This will cause the page to scroll to the bottom. So scrolling to the
           * top of the component after submission is the same as with other components.
           */
          $timeout(() => {
            const $scrollTo = $(`#lecture-component-${vm.lectureComponent.id}`);

            if ($scrollTo.length) {
              $('.main-panel-scrollable').scrollToElementAnimated($scrollTo, null, 400);
            }
          });
        }).catch(
          (error) => {
            AlertMessages.error('', 'FORM.ERROR');
          },
        );
    };

    vm.setEditMenu = () => {
      const { sharedProps } = this.context;
      const extraOptions = [];

      extraOptions.push({
        type: 'link',
        link: RailsRoutes.editQuestionSetPath(vm.$stateParams.catalogId, vm.lectureComponent.quiz.id),
        text: t.LECTURE_PAGES.COMPONENTS.QUIZ.DROPDOWN.EDIT_QUESTIONS(),
        disabled: !!vm.lectureComponent.lecturePage.isLinked,
        tooltip: {
          text: t.LECTURE_PAGES.COMPONENTS.SET_UP_FROM_COLLECTION_TOOLTIP(),
          textAlign: TextAlign.LEFT,
          enabled: !!vm.lectureComponent.lecturePage.isLinked,
          placement: 'left',
          offset: 20,
        },
      });

      if (!vm.CurrentCourseManager.course.isSelfPaced) {
        extraOptions.push({
          type: 'text',
          text: vm.lectureComponent.hasDeadline() ? t.LECTURE_PAGES.COMPONENTS.QUIZ.DROPDOWN.REMOVE_DEADLINE() : t.LECTURE_PAGES.COMPONENTS.QUIZ.DROPDOWN.ADD_DEADLINE(),
          callback: () => {
            vm.toggleDeadline();
            vm.setEditMenu();
          },
          disabled: vm.isContentManagementCollection,
          tooltip: {
            enabled: vm.isContentManagementCollection,
            text: t.LECTURE_PAGES.COMPONENTS.DROPDOWN.SETUP_IN_LINKED_LESSON_TOOLTIP(),
            placement: 'left',
            offset: 20,
          },
        });
      }

      sharedProps.extraOptions = {
        ...sharedProps.extraOptions,
        ...{
          options: extraOptions,
        },
      };
    };

    vm.setEditMenu();

    vm.hasBuilderPermissionInCollection = () => (contentManagementCollection.userCourse
      && CurrentPermissionsManager.hasCollectionBuilderPermissions(contentManagementCollection.userCourse));

    vm.goToCollectionLesson = () => {
      const url = $state.href('lecture-page-edit', {
        catalogId: contentManagementCollection.catalogId,
        id: contentManagementLecturePage.id,
        lectureActivityId: vm.lectureComponent.cloneParentId,
      });

      window.open(url, '_blank');
    };

    /* Admin Actions */
  },
  controllerAs: 'vm',
  templateUrl: 'lecture_pages/templates/components/nv-survey-lecture-component.html',
};
