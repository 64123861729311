/* @ngInject */
export default function NvBindHtmlUnsafe(
  $sce,
) {
  return {
    restrict: 'A',
    scope: {
      nvBindHtmlUnsafe: '=',
    },
    link(scope, elem, attr) {
      scope.$watch('nvBindHtmlUnsafe', (newVal, oldVal) => {
        scope.trustedHtml = $sce.trustAsHtml(newVal);
      });
    },
    template: '<div ng-bind-html="trustedHtml"></div>',
  };
}
