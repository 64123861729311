/* @ngInject */
export default function TeamDirectorySortFilterController(
  TeamDirectoryManager,
  CurrentCourseManager,
) {
  const vm = this;

  vm.manager = TeamDirectoryManager;
  vm.currentCourseManager = CurrentCourseManager;
}
