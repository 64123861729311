/**
 * Finds the offset by which to place the tribute popover
 * @param {Tribute} tributeRange - Tribute object
 * @param {number} selectedNodePosition - Range in text where menu should be positioned
 */
/* @ngInject */
export default function getContentEditableCaretPosition(tributeRange, selectedNodePosition) {
  /* from tribute */
  const sel = tributeRange.getWindowSelection();

  const range = document.createRange();
  range.setStart(sel.anchorNode, selectedNodePosition);
  range.setEnd(sel.anchorNode, selectedNodePosition);

  range.collapse(false);

  const rect = range.getBoundingClientRect();

  const prevRange = window.getSelection().getRangeAt(0);

  /* start NovoEd Custom - copied partially from Mentio */
  const markerTextChar = '\ufeff';
  const markerId = `sel_${new Date().getTime()}_${Math.random().toString().substr(2)}`;
  const markerEl = tributeRange.getDocument().createElement('span');
  markerEl.id = markerId;
  markerEl.appendChild(tributeRange.getDocument().createTextNode(markerTextChar));
  range.insertNode(markerEl);
  sel.removeAllRanges();
  sel.addRange(prevRange);

  // technically we should trace upwards to offsetParent like our custom code in mentio, but this seems to work so far
  const coordinates = {
    left: markerEl.offsetLeft,
    top: markerEl.offsetTop + rect.height,
  };

  markerEl.parentNode.removeChild(markerEl);

  return coordinates;
}
