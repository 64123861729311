/* eslint-disable react/require-default-props */
import { css } from '@emotion/react';
import { FilesUploading } from 'shared/hooks/use-upload-file';
import { ProgressBar } from 'react-bootstrap';

const loadingBarStyles = css`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;

  .bs4-progress {
    width: 30px;
    height: 5px;
    align-self: center;
    border-radius: 0;
  }
`;

/** Shows loading bar progress for an image file upload for a header component image area */
const FileUploadingBar = (props: { filesUploading: FilesUploading, className?: string }) => {
  const { filesUploading } = props;
  return (
    <div>
      {Object.keys(filesUploading).map((key) => {
        const fileUploading = filesUploading[key];
        let uploadPct = 100;
        uploadPct = fileUploading.uploadPercentage;

        return (
          <div key={key} css={loadingBarStyles} className={props.className}>
            <ProgressBar now={uploadPct} />
          </div>
        );
      })}
    </div>
  );
};

export default FileUploadingBar;
