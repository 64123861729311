/* eslint-disable react/no-danger */


import React from 'react';
import { connect, useSelector } from 'react-redux';
import t from 'react-translate';
import { isEmpty } from 'underscore';

// Helpers
import { addCourseFilters, addJourneyFilters } from 'communications/course_communications/helpers/filters-description';

// Schemas
import { RootState } from 'redux/schemas';
import { Communication, CommunicationType, Recipient, ItemState } from 'redux/schemas/models/course-communication';

// Selectors
import { getCommunication, getItemState, getRolesFilterTitles } from 'redux/selectors/course-communications';
import { getCourseAliases, getCoursesArray, getCurrentCourse } from 'redux/selectors/course';
import { getPrefixedTitle } from 'redux/selectors/activity';
import { getJourneyCollections } from 'redux/selectors/learning-journeys';

// Components
import NvRoleBadge from 'shared/components/nv-role-badge';
import { getRecipientLabel, getRecipientsLabel, orderedRecipients } from 'communications/course_communications/components/communication-modal/send-to';
import CommunicationRow from './communication-row';

type CommunicationFilterRowProps = {
  communicationId?: number,
  showOwnerActivity?: boolean,
};

type StateProps = {
  communication: Communication,
  rolesFilterTitles: string[],
  itemState: ItemState,
};

export const CommunicationFilterRow = (props: CommunicationFilterRowProps & StateProps) => {
  const aliases = useSelector((state) => getCourseAliases(state));
  const { isJourney } = useSelector((state) => getCurrentCourse(state));
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const journeyCollections = useSelector((state) => getJourneyCollections(state, catalogId));
  const courses = useSelector(getCoursesArray);
  // Need to pass aliases, so using useSelector pattern
  // Getting the actvity titles with prefix

  const courseFiltersTitles = {
    ownerActivityTitle: useSelector((state) => getPrefixedTitle(
      state,
      props.communication.ownerType,
      props.communication.ownerId,
      aliases,
    )),
    hasCompletedActivityTitle: useSelector((state) => getPrefixedTitle(
      state,
      props.communication.filters?.hasCompleted?.type,
      props.communication.filters?.hasCompleted?.id,
      aliases,
      props.communication.filters?.hasCompleted?.category,
    )),
    hasNotCompletedActivityTitle: useSelector((state) => getPrefixedTitle(
      state,
      props.communication.filters?.hasNotCompleted?.type,
      props.communication.filters?.hasNotCompleted?.id,
      aliases,
      props.communication.filters?.hasNotCompleted?.category,
    )),
  };

  const filters = [];
  const isSent = props.itemState === ItemState.SENT;

  if (isJourney) {
    addJourneyFilters(filters, props, aliases, isSent, courses, journeyCollections);
  } else {
    addCourseFilters(filters, props, aliases, isSent, courseFiltersTitles);
  }

  // No need to display filters for trigger type
  const showFilterRow = filters.length > 0
    || !(props.communication?.communicationType === CommunicationType.COMPLETED
      || props.communication?.communicationType === CommunicationType.NOT_COMPLETED);

  let filterRowDescription: string = '';

  const recipients = props.communication?.recipients && !isEmpty(props.communication.recipients)
    ? orderedRecipients(props.communication.recipients)
    : [Recipient.TRIGGER_LEARNER]; // To handle trigger learner recipient

  if (showFilterRow) {
    const recipientTitle = getRecipientsLabel(recipients, aliases);

    filterRowDescription = filters.length > 0
      ? t.COURSE_COMMUNICATIONS.FILTERS.FILTER_ROW_DESCRIPTION(recipientTitle)
      : recipientTitle;
  }

  return (
    <React.Fragment>
      {showFilterRow && (
        <CommunicationRow>
          <div className='content-wrapper'>
            <div className='icons-wrapper recipient-icons'>
              {/* The role badges are displyed right to left , so reversing it */}
              { recipients && recipients.reverse().map((recipient) => (
                <NvRoleBadge key={recipient} recipient={recipient} label={getRecipientLabel(recipient, aliases)} />
              ))}
            </div>
            <div className='content text-medium'>
              <div className='title'>
                { showFilterRow && filterRowDescription }
              </div>
              { filters.map((filter) => (
                <React.Fragment key={filter.key}>
                  { // string means its a translated string which may contain html,
                    // or else treat it as a ReactElement => titles with warnings
                    typeof filter.title === 'string'
                      ? (
                        <div
                          className='title'
                          dangerouslySetInnerHTML={{
                            __html: filter.title,
                          }}
                        />
                      )
                      : (
                        <div className='title'>{filter.title}</div>
                      )
                  }
                </React.Fragment>
              ))}
            </div>
          </div>
        </CommunicationRow>
      )}
    </React.Fragment>
  );
};

const ConnectedCommunicationFilterRow = connect(
  (state: RootState, ownProps: CommunicationFilterRowProps) => ({
    communication: getCommunication(state, ownProps.communicationId),
    rolesFilterTitles: getRolesFilterTitles(state, ownProps.communicationId),
    itemState: getItemState(state, ownProps.communicationId),
  }),
)(CommunicationFilterRow);

export default ConnectedCommunicationFilterRow;
