import { Fragment } from 'react';
import { css } from '@emotion/react';

import NvAvatar, { NvAvatarDefaultBkg } from 'components/nv-avatar';
import { VideoPracticeSubmission } from 'redux/schemas/models/video-practice';
import {
  NvTableCellProps,
  NvTableRowProps,
} from 'shared/components/nv-responsive-table';
import { createGridStyles } from 'styles/global_defaults/scaffolding';
import { getInitials, timeSince } from 'shared/utils';
import { useCourseSupervisorContext } from 'course_supervisor_dashboard/context/course_supervisor_provider';

type VideoPracticeSubmissionCellProps = NvTableCellProps<
VideoPracticeSubmission,
unknown
> & {
  submission: VideoPracticeSubmission;
};

const Cell = ({
  children,
  rowKey,
  reactKey,
  serializedStyles,
  divProps,
  onClick,
  rowIndex,
  onKeyDown,
}: any) => (<div onKeyDown={onKeyDown} className={rowKey} css={serializedStyles} key={reactKey} {...divProps} onClick={onClick} data-qa={`${rowKey}-${rowIndex}`} role='button' tabIndex='0'>{children}</div>);


const TextCell = (props: VideoPracticeSubmissionCellProps) => {
  const { submission } = props;
  return <Cell {...props}>{submission.scenario.title}</Cell>;
};

const DaysCell = (props: VideoPracticeSubmissionCellProps) => <Cell {...props}>{timeSince(props.submission.requestedAt, true)}</Cell>;

const UserCell = (props: VideoPracticeSubmissionCellProps) => {
  const { submission } = props;
  const { onClickPendingSubmissionRow } = useCourseSupervisorContext();
  const onUserClick = () => {
    onClickPendingSubmissionRow(submission);
  };

  // Allow user to click on the cell by pressing Enter or Space - accessibility
  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onUserClick();
    }
  };

  return (
    <Cell {...props} onClick={onUserClick} onKeyDown={onKeyDown}>
      {submission.user.profilePicture ? (
        <NvAvatar
          imageUrl={submission.user.profilePicture}
          size='md'
          borderType='round'
          defaultBkg={{ type: NvAvatarDefaultBkg.SOLID_COLOR, color: '#fff' }}
        />
      ) : (
        <div className='user-initials'>{getInitials(submission.user)}</div>
      )}
      <span className='user-name'>{submission.user.fullName}</span>
    </Cell>
  );
};

const createCellProps = (
  props: NvTableRowProps<VideoPracticeSubmission, {}>,
  key: string,
  i: number,
  rowIndex: number,
) => ({
  ...props,
  reactKey: `${rowIndex}-${i + 1}`,
  serializedStyles: css``,
  rowKey: key,
  divProps: {
    style: createGridStyles(i + 1, rowIndex, i + 2, rowIndex + 1),
  },
  submission: props.data,
});

const PendingSubmissionsRow = (
  props: NvTableRowProps<VideoPracticeSubmission, {}>,
) => {
  const cells: [string, (props: any) => JSX.Element][] = [
    ['scenario-cell', TextCell],
    ['user-cell', UserCell],
    ['pending-review-cell', DaysCell],
  ];

  return (
    <Fragment>
      {cells.map((Item, i) => {
        const [key, Component] = Item;
        const cellProps = createCellProps(props, key, i, props.rowIndex);
        return <Component key={key} {...cellProps} />;
      })}
    </Fragment>
  );
};

export default PendingSubmissionsRow;
