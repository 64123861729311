import { omit } from 'underscore';
import { schema } from 'normalizr';
import { CombinedCourse } from 'redux/schemas';
import { Collection, Course } from 'redux/schemas/models/course';
import { enrollmentSchema } from 'redux/schemas/api/my-account';
import { institutionSchema } from 'redux/schemas/api/institutions';

export interface CourseDeleteRequest {
  id: number;
  scope: null;
  ownerID: number;
  ownerType: string;
  completed: boolean;
  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;
  requesterID: number;
  settingsHash: {};
  name: null;
  multipleDuplicationCommandID: null;
}

export interface CourseDeleteResponse {
  success: boolean;
  request: CourseDeleteRequest;
}

/**
 * GET `/:catalogId.json`
 */
export type FetchCourseParams = {
  catalogId: string,
};

export interface FetchTeachingTeamMembersParams {
  catalogId: string,
}

export interface GetActiveStudentsForCourseParams {
  catalogId: string,
}

export const JourneyCollectionSchema = new schema.Entity<Collection>(
  'journeyCollections',
);

export const courseSchema = new schema.Entity<CombinedCourse>('courses', {
  collectionIds: [JourneyCollectionSchema],
  institution: institutionSchema,
  userCourse: enrollmentSchema,
}, {
  idAttribute: 'catalogId',
  processStrategy: (value) => omit({
    ...value,
    ...(value.collections && { collectionIds: value.collections }),
  }, ['collections']),
});

export const discoveryOfferingSchema = new schema.Entity<Course>('discoveryOffering');
export const discoveryOfferingsSchema = new schema.Array(discoveryOfferingSchema);
