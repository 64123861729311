
// import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useState, useRef } from 'react';

import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { NvFroala } from 'froala/components/nv-froala';
import { FroalaViewMode, UploadType } from 'froala/helpers/nv-froala-constants';
import { SanitizationLevel } from 'froala/helpers/sanitizer';

import { NvModal, ModalType } from 'shared/components/nv-modal';
import t from 'react-translate';
import { FroalaUploadModal } from 'froala/components/froala-upload-modal';
import { useForm, FormProvider } from 'react-hook-form';

// const ComponentContainer = styled.div`
//   margin: 10px;
// `;

// TODO: Translations
const Bs4Testbed = () => {
  const [froalaWithoutForm, setFroalaWithoutForm] = useState('initialContent');
  const methods = useForm<{ froalaWithForm: string }>({
    defaultValues: {
      froalaWithForm: 'initial content',
    },
  });
  const [show, setShow] = useState(false);
  // eslint-disable-next-line no-console
  const onSubmit = data => console.log(data);
  const froalaWithForm = methods.watch('froalaWithForm');
  const onChange = (content) => {
    // methods.setValue('froalaWithForm', content);
    setFroalaWithoutForm(content);
  };


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const ref = useRef(null);

  const popover = (props: any) => (
    <Popover {...props} id='popover-basic' show={props.show.toString()} ref={ref}>
      <Popover.Title as='h3'>Popover right</Popover.Title>
      <Popover.Content>
        And here&apos;s some
        {' '}
        <strong>amazing</strong>
        {' '}
        content. It&apos;s very engaging.
        right?
      </Popover.Content>
    </Popover>
  );


  return (
    <div className='container'>
      <h5>w/o Form</h5>
      Air
      <NvFroala
        preset={FroalaViewMode.AIR}
        sanitizationLevel={SanitizationLevel.NORMAL}
      />

      Inline
      <NvFroala
        preset={FroalaViewMode.INLINE}
        sanitizationLevel={SanitizationLevel.NORMAL}
      />

      Normal
      <NvFroala
        preset={FroalaViewMode.NORMAL}
        withCodeView
        sanitizationLevel={SanitizationLevel.NORMAL}
      />

      Code View
      <NvFroala
        preset={FroalaViewMode.NORMAL}
        withCodeView
        value={froalaWithoutForm}
        onChange={onChange}
        sanitizationLevel={SanitizationLevel.NORMAL}
      />
      {/* <NvModal
        type={ModalType.FIXED}
        header='Froala in Modal'
        body={<NvFroala preset={Mode.INLINE} withCodeView />}
        show
      /> */}

      {/* <FormProvider {...methods}>
        with Form
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <NvFroala preset={Mode.NORMAL} withCodeView withForm name='froalaWithForm' />

          <ButtonToolbar>
            <Button variant='primary' type='submit'>Log Values</Button>
          </ButtonToolbar>
        </form>
      </FormProvider> */}


      {/* <ComponentContainer>
        <Alert variant='primary'>
          Alert - Pimary
        </Alert>
      </ComponentContainer>

      <ComponentContainer>
        <Badge pill variant='primary'>
          Primary
        </Badge>
      </ComponentContainer>

      <ComponentContainer>
        <ButtonToolbar>
          <Button variant='primary'>Button- Primary</Button>
        </ButtonToolbar>
      </ComponentContainer>

      <ComponentContainer>
        <Dropdown>
          <Dropdown.Toggle variant='success' id='dropdown-basic'>
            Dropdown Button
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href='#/action-1'>Action</Dropdown.Item>
            <Dropdown.Item href='#/action-2'>Another action</Dropdown.Item>
            <Dropdown.Item href='#/action-3'>Something else</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ComponentContainer>

      <ComponentContainer>
        <Form>
          <Form.Group controlId='formBasicEmail'>
            <Form.Label>Email address</Form.Label>
            <Form.Control type='email' placeholder='Enter email' />
            <Form.Text className='text-muted'>
              We&apos;ll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group controlId='formBasicPassword'>
            <Form.Label>Password</Form.Label>
            <Form.Control type='password' placeholder='Password' />
          </Form.Group>
          <Form.Group controlId='formBasicCheckbox'>
            <Form.Check type='checkbox' label='This is taking styles from our other app. not sure if we want to get rid...' />
          </Form.Group>
          <Button variant='primary' type='submit'>
            Submit
          </Button>
        </Form>
      </ComponentContainer>

      <ComponentContainer>
        <InputGroup className='mb-2'>
          <InputGroup.Prepend>
            <InputGroup.Text id='basic-addon1'>@</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder='Username'
            aria-label='Username'
            aria-describedby='basic-addon1'
          />
        </InputGroup>
      </ComponentContainer>

      <ComponentContainer>
        <Button variant='primary' onClick={handleShow}>
          Launch demo modal
        </Button>
      </ComponentContainer>

      <ComponentContainer>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>Woohoo, you&apos;re reading this text in a modal!</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Close
            </Button>
            <Button variant='primary' onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </ComponentContainer>

      <ComponentContainer>
        <OverlayTrigger trigger='click' placement='right' overlay={popover}>
          <Button variant='success'>Click me to see</Button>
        </OverlayTrigger>
      </ComponentContainer>

      <ComponentContainer>
        <h1 className='text-center font-weight-light'>text-center font-weight-light</h1>
        <h4 className='text-center font-weight-bolder condensed'>text-center font-weight-bolder condensed</h4>
      </ComponentContainer> */}
    </div>
  );
};

export default Bs4Testbed;
