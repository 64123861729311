import { css } from '@emotion/react';
import React, { useState, useEffect, useRef, FunctionComponent } from 'react';
import ClickableContainer from 'components/clickable-container';
import NvDropdown, { NvDropdownAlign, NvDropdownOption, NvDropdownButtonStyle } from 'shared/components/inputs/nv-dropdown';
import useClickOutside from 'shared/hooks/use-click-outside';
import { gray1, gray3 } from 'styles/global_defaults/colors';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import NvPopover, { NvPopoverProps } from './nv-popover';
import NvTooltip from './nv-tooltip';


export type BaseButtonProps = {
  onClick?: () => void,
  tooltip?: string,
  disabled?: boolean,
  dataQa?: string,
  /** A function component that will wrap the button, if present.
   * I don't know that this is a good design but it greatly simplifies my implementation for the file upload button in Image Lecture Component. I'll
   * revisit this later. */
  wrappingComponent?: FunctionComponent
  wrappingComponentProps?: any,
};

export type TextButton = {
  type: 'text',
  text: string,
} & BaseButtonProps;

export type IconButton = {
  type: 'icon',
  /** The classname of the icon to show */
  iconClass: string,
} & BaseButtonProps;

export type DropdownButton = {
  type: 'dropdown',
  title: string,
  dropdownItems: NvDropdownOption[],
} & BaseButtonProps;

export type CustomButton = {
  type: 'custom',
  customItem: JSX.Element,
} & BaseButtonProps;

export type Button =
  TextButton |
  IconButton |
  DropdownButton |
  CustomButton;

type NvButtonPopoverProps = {
  buttons: Button[],
  setShow?: (show: boolean) => void,
};

/** This is a helper function I'm trying out to make conditionally wrapping components easier - Nathan */
const ConditionalWrap = ({ condition, wrap, children }) => (<React.Fragment>{condition ? wrap(children) : children}</React.Fragment>);

export const NvButtonPopover = (props: Omit<NvPopoverProps, 'content'> & NvButtonPopoverProps) => {
  const ref = useRef(null);

  useClickOutside(ref, () => {
    props?.setShow?.(false);
  });

  const popoverStyles = css`
    max-width: none;
    background-color: ${gray1};
    height: 36px;
    border: 0;

    .bs4-popover-body {
      color: white;
      padding: 0;
    }

    .arrow::after {
      border-top-color: ${gray1};
    }

    ${props.overlayStyles}
  `;

  const styles = css`
    .popover-button {
      height: 36px;
      padding: ${halfSpacing}px 16px;
      &:not(.disabled):hover {
        cursor: pointer;
        background-color: ${gray3};
      }

      &.disabled {
        color: ${gray3};
      }
    }
  `;

  const [content, setContent] = useState(null);


  useEffect(() => {
    setContent(
      <div ref={ref} css={styles} className='d-flex'>
        {props.buttons.map(button => (
          <div key={`button-${keyForButton(button)}-container`}>
            <ConditionalWrap
              condition={!!button.wrappingComponent}
              wrap={(children) => {
                const WrappingComponent = button.wrappingComponent;

                return <WrappingComponent {...button.wrappingComponentProps}>{children}</WrappingComponent>;
              }}
            >
              <NvTooltip
                text={button.tooltip}
                enabled={!!button.tooltip && !button.disabled}
                key={`${keyForButton(button)}-tooltip`}
                placement='bottom'
                // These float outside their container and will be hidden via the `[data-popper-escaped=true]` selector in
                // react-app-component-styles.scss unless this is false
                preventOverflow={false}
              >
                {button.type === 'dropdown' ? (
                  <div key={keyForButton(button)}>
                    <NvDropdown
                      buttonStyle={NvDropdownButtonStyle.CUSTOM}
                      customTarget={() => (
                        <div className='popover-button d-flex align-items-center' data-qa={button.dataQa}>
                          {button.title}
                          <div className='ml-1 icon text-xs icon-dropdown-arrow' />
                        </div>
                      )}
                      items={button.dropdownItems}
                      align={NvDropdownAlign.LEFT}
                      showSelectedIndicator
                      allowOverflow
                    />
                  </div>
                ) : (
                  <ClickableContainer
                    key={keyForButton(button)}
                    className={`popover-button ${button.disabled ? 'disabled' : ''}`}
                    onClick={() => !button.disabled && button.onClick?.()}
                    isFocusable={!button.disabled}
                    data-qa={button.dataQa}
                  >
                    {button.type === 'icon' && <div className={`icon icon-smallest ${button.iconClass}`} />}
                    {button.type === 'text' && <div>{button.text}</div>}
                    {button.type === 'custom' && <div>{button.customItem}</div>}
                  </ClickableContainer>
                )}
              </NvTooltip>
            </ConditionalWrap>
          </div>
        ))}
      </div>,
    );
  }, [props.buttons]);

  return (
    <NvPopover
      content={content}
      {...props}
      overlayStyles={popoverStyles}
    />
  );
};

const keyForButton = (button: Button): string | number => {
  switch (button.type) {
    case 'icon':
      return button.iconClass;
    case 'text':
      return button.text;
    case 'dropdown':
      return button.title;
    case 'custom':
      return button.customItem.key;
    default:
      return '';
  }
};

export default NvButtonPopover;
