import store, { cloneDeepSerializable } from 'redux/store';
import { loadCourse } from 'redux/actions/courses';
import CourseHomeHeader from 'courses/components/course-home-header';
import { JourneyBadge } from '../components/journey-badge';

/* @ngInject */
export default function CourseHomeBaseController(
  $scope,
  CourseHomeManager,
  CurrentCourseManager,
  ReportsManager,
  TimelinesManager,
  nvCurrentPage,
  RailsRoutes,
  $state,
  $stateParams,
) {
  const vm = this;

  vm.manager = CourseHomeManager;
  vm.currentCourseManager = CurrentCourseManager;
  vm.reportsManager = ReportsManager;
  vm.TimelinesManager = TimelinesManager;
  vm.catalogId = CurrentCourseManager.course.catalogId;
  vm.nvCurrentPage = nvCurrentPage;
  vm.RailsRoutes = RailsRoutes;

  $scope.CourseHomeHeader = CourseHomeHeader;
  $scope.JourneyBadge = JourneyBadge;

  // TODO: This revalidation is necessary to have the inJourneys property updated, we can optimize this by having an external endpoint that sends the most recent information which is what is planned to be done  the end of course project
  async function revalidateCourse() {
    // TODO: Passing false to shouldSetCourse argument due to at some point the course info in Redux is outdated compare to Angular, in specific the completionCriteria property (NOV-84017)
    const updatedCourse = await CurrentCourseManager.requestCourse($stateParams?.catalogId, false, null, false);
    // We should update Angular with the latest information of the course
    CurrentCourseManager.course.updateFromReact({
      visitedHome: updatedCourse.visitedHome,
      inJourneys: updatedCourse.inJourneys,
    });
    store.dispatch(loadCourse(cloneDeepSerializable(updatedCourse)));
  }

  revalidateCourse();

  vm.getExtraTopNavContentClass = () => ({
    'no-padding': true,
  });

  vm.exitEditMode = () => {
    $state.go('course-home', { id: vm.currentCourseManager.course.catalogId });
  };
}
