/* @ngInject */
export default function ReadOnlyQuestionService(

  _,
) {
  const ReadOnlyQuestion = function (attributes) {
    const _this = this;
    const base = {
      templateUrl: 'quizzes/templates/questions/nv-read-only-question.html',
      displayState: null,
    };

    /** Setting Up Basic Attributes * */
    _.extend(_this, base, attributes);

    /** Public Functions * */
    _this.isUnAnswered = isUnAnswered;
    _this.responsePayload = responsePayload;
    _this.setResponse = setResponse;
    _this.resetResponse = resetResponse;
    _this.updateResponseOptions = updateResponseOptions;

    /** Private Functions * */
    function isUnAnswered() {
      return false;
    }

    function responsePayload() {
    }

    function updateResponseOptions(question) {
    }

    function setResponse(response) {
    }

    function resetResponse() {
    }
  };

  return ReadOnlyQuestion;
}
