import React from 'react';
import t from 'react-translate';

// Styles
import { css } from '@emotion/react';
import { gray2 } from 'styles/global_defaults/colors';
import {
  lightFontWeight,
  textMediumFontSize,
  textMediumLineHeight,
} from 'styles/global_defaults/fonts';

// Form
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

// Redux
import { useAppDispatch } from 'redux/store';
import { doSignIn } from 'redux/actions/sign-in';
import { LoginParams } from 'redux/schemas/api/sign-in';
import { UserLogin } from 'redux/schemas/models/sign-in';

// Components
import { Button } from 'react-bootstrap';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvIcon from '../nv-icon';
import ForgotPassword from './nv-forgot-password';
import SignInError from './nv-sign-in-error';

type Props = {
  email: string,
  maxAttempts: number,
  onSuccess: (payload: UserLogin) => void,
  goBack: () => void,
};

const EnterPassword = (props: Props) => {
  const {
    email,
    maxAttempts,
    onSuccess,
    goBack,
  } = props;
  const dispatch = useAppDispatch();
  const [response, setResponse] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [accountLocked, setAccountLocked] = React.useState<boolean>(false);

  const validationSchemaRef = React.useRef(yup.object().shape({
    password: yup.string()
      .min(1, t.VALIDATION.REQUIRED()),
  }));

  const methods = useForm({
    mode: 'onChange',
    shouldUnregister: true,
    resolver: yupResolver(validationSchemaRef.current),
  });

  const { handleSubmit, formState } = methods;

  const onSubmit = ({ password }) => {
    const user: LoginParams = { email, password };
    setIsLoading(true);
    dispatch(doSignIn(user)).then(({ payload }) => {
      setResponse(payload);
      if (payload?.data?.error || payload?.status === 500) setIsLoading(false);
    });
  };

  const onSuccessLogin = (payload) => {
    setAccountLocked(false);
    onSuccess(payload);
  };

  const styles = css`
    .signing-with-email {
      font-size: ${textMediumFontSize}px;
      font-weight: ${lightFontWeight};
      line-height: ${textMediumLineHeight}px;
      color: ${gray2};
    }
  `;

  return (
    <React.Fragment>
      <div css={styles} className='authentications-overlay'>
        <div className='authentications-body'>
          <div className='authentications-header-text d-flex mb-0'>
            <NvIcon
              className='d-flex align-items-center'
              size='small'
              icon='arrow-left'
              onClick={goBack}
            />
            <div className='w-100'>{t.SHARED.ENTER_PASSWORD()}</div>
          </div>
          <div className='signing-with-email mb-5'>{t.SHARED.SIGNING_WITH_EMAIL(email)}</div>
          <SignInError
            payload={response}
            maxAttempts={maxAttempts}
            onSuccess={onSuccessLogin}
            onAccountLocked={() => setAccountLocked(true)}
          />
          <div className='registration-form'>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <NvTextInput
                  required
                  withForm
                  withLabel
                  type='password'
                  name='password'
                  className='mt-2'
                  placeholder={t.USERS.REGISTRATION.PASSWORD()}
                  data-qa='password'
                />
                <ForgotPassword />
                <Button
                  className='mb-5'
                  disabled={!formState.isValid || accountLocked || isLoading}
                  type='submit'
                  data-qa='sign-in'
                >
                  {isLoading ? t.USERS.REGISTRATION.SIGNING_IN() : t.USERS.REGISTRATION.SIGN_IN()}
                </Button>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EnterPassword;
