/* @ngInject */
export default function AwardExtraPointsCtrl(
  _,
  $scope,
  validationConstants,
  AlertMessages,
  ConfirmationOverlays,
  CurrentCourseManager,
  UserManagementResources,
  config,
) {
  class AwardExtraPointsController {
    constructor() {
      const { user, awardedPoints, closeModal } = $scope;

      this.CurrentCourseManager = CurrentCourseManager;
      this.ConfirmationOverlays = ConfirmationOverlays;
      this.validationConstants = validationConstants;
      this.user = user;
      this.awardedPoints = awardedPoints;
      this.closeModal = closeModal;

      this.awardPointsView = !this.awardedPoints.length;
      this.editPointsView = false;
      this.config = config;

      this.award = {
        points: null,
        reason: '',
      };

      this.editAward = {
        id: null,
        receivedPoints: null,
        reason: '',
      };
    }

    onAwardPoints() {
      const pointObject = { receivedPoints: this.award.points, reason: this.award.reason, userIds: [this.user.id] };
      return UserManagementResources.addManuallyAwardedPoints({ catalogId: CurrentCourseManager.course.catalogId }, pointObject)
        .$promise.then((response) => {
          this.closeModal();
          AlertMessages.success('FORM.SUCCESS_BANG', 'USER_MANAGEMENT.AWARD_EXTRA_POINTS_SUCCESS', {},
            { fullName: this.user.fullName, pointsAlias: CurrentCourseManager.course.pointsName.downcasedPluralized });
          return response.result;
        }).catch(() => {
          AlertMessages.error('FORM.OOPS', 'FORM.ERROR_SOMETHING_WRONG');
        });
    }

    awardMorePoints() {
      this.awardPointsView = true;
    }

    editPoints(pointObject) {
      _.extend(this.editAward, _.pick(pointObject, 'receivedPoints', 'reason', 'id'));
      this.editPointsView = true;
    }

    changesMade() {
      const original = this.awardedPoints.find((award) => award.id === this.editAward.id);
      return this.editAward.receivedPoints && (this.editAward.receivedPoints !== original.receivedPoints || this.editAward.reason !== original.reason);
    }

    tooBig(num) {
      return parseInt(num, 10) > 2147483647; // 2 ** 32 => 2147483647
    }

    updatePoints() {
      const updateObject = { receivedPoints: this.editAward.receivedPoints, reason: this.editAward.reason, userIds: [this.user.id] };
      return UserManagementResources.updateManuallyAwardedPoints({ catalogId: CurrentCourseManager.course.catalogId, pointObjectId: this.editAward.id }, updateObject)
        .$promise.then((response) => {
          this.closeModal();
          AlertMessages.success('FORM.SUCCESS_BANG', 'USER_MANAGEMENT.UPDATE_EXTRA_POINTS_SUCCESS', {},
            { fullName: this.user.fullName, pointsAlias: CurrentCourseManager.course.pointsName.downcasedPluralized });
          return response.result;
        }).catch(() => {
          AlertMessages.error('FORM.OOPS', 'FORM.ERROR_SOMETHING_WRONG');
        });
    }

    deletePoints(pointObject) {
      this.ConfirmationOverlays.openConfirmationModal('lecture_pages/templates/components/delete-confirmation-overlay.html').result.then(() => UserManagementResources.deleteManuallyAwardedPoints({ catalogId: CurrentCourseManager.course.catalogId, pointObjectId: pointObject.id })
        .$promise.then((response) => {
          this.closeModal();
          AlertMessages.success('FORM.SUCCESS_BANG', 'USER_MANAGEMENT.DELETE_EXTRA_POINTS_SUCCESS', {},
            { fullName: this.user.fullName, pointsAlias: CurrentCourseManager.course.pointsName.downcasedPluralized });
          return response.result;
        }).catch(() => {
          AlertMessages.error('FORM.OOPS', 'FORM.ERROR_SOMETHING_WRONG');
        }));
    }

    cancelAward() {
      if (this.editPointsView) {
        const original = this.awardedPoints.find((award) => award.id === this.editAward.id);
        if (this.editAward.receivedPoints !== original.receivedPoints || this.editAward.reason !== original.reason) {
          this.ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html').result.then(() => {
            this.closeModal();
          });
        } else {
          this.closeModal();
        }
      } else if (this.awardPointsView && (this.award.points || this.award.reason)) {
        this.ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html').result.then(() => {
          this.closeModal();
        });
      } else {
        this.closeModal();
      }
    }
  }

  return new AwardExtraPointsController();
}
