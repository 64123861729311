import React from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';

import { useAppDispatch } from 'redux/store';
import { setLeftPanelNovoAIItemTrueType } from 'redux/actions/lecture-pages';
import { useSelector } from 'react-redux';
import { ComponentTrueType, ComponentType, RichTextType } from 'redux/schemas/models/lecture-component';

import {
  cardIconSize, doubleSpacing, halfSpacing, quarterSpacing,
  standardSpacing, threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import { gray6 } from 'styles/global_defaults/colors';

import ClickableContainer from 'components/clickable-container';
import prodPathReplace from 'shared/prod-path-rewrite';
import Preferences from './preferences';
import AICommonStyles from '../shared/ai-common-styles';
import { config } from '../../../../../../../config/config.json';

const SelectLayout = () => {
  const SAMPLE_IMAGE = prodPathReplace('images/content_template_sample.jpg');
  const dispatch = useAppDispatch();
  const handleClick = (type: ComponentTrueType) => {
    dispatch(setLeftPanelNovoAIItemTrueType(type));
  };
  const selectedItemTrueType = useSelector(state => state.app.lecturePage.novoAI?.itemTrueType);
  const lectureComponentToReplace = useSelector(state => state.app.lecturePage.novoAI?.lectureComponentToReplace);
  const selectLayoutStyles = css`
    ${AICommonStyles};
    gap: ${standardSpacing}px;

    .sample-image {
      margin: 0 auto;
      background-image: url(${SAMPLE_IMAGE});
      background-color: unset;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .layout {
      width: 100%;
      padding: ${halfSpacing}px;
    }
    .image-gradient {
      background-image: linear-gradient(rgba(29,33,38,0.5), rgba(29,33,38,0.5)), url(${SAMPLE_IMAGE});
      height: 80px;
    }
    .text {
      font-size: 9px;
      line-height: ${threeQuartersSpacing}px;
      align-content: center;
    }
    .text-simple {
      border: 1px solid ${gray6};
      border-radius: ${quarterSpacing}px;
      align-content: center;
    }
    .text-with-image-bkg {
      .text {
        padding: 0 ${doubleSpacing}px;
      }
    }
    .text-with-image-side {
      .sample-image {
        align-self: center;
        width: ${cardIconSize}px;
        min-width: ${cardIconSize}px;
        height: ${cardIconSize}px;
        border-radius: 100%;
      }
      .text {
        padding: ${halfSpacing}px;
      }
    }
    .text-with-image-top {
      text-align: center;
      .sample-image {
        width: ${doubleSpacing}px;
        height: ${doubleSpacing}px;
        border-radius: 100%;
      }
      .text {
        padding-top: ${halfSpacing}px;
      }
    }
  `;
  return (
    <React.Fragment>
      {!lectureComponentToReplace && (
        <div className='layouts'>
          <div className='d-flex flex-column mt-8' css={selectLayoutStyles}>
            <div className='page-title text-center'>
              { t.LECTURE_PAGES.LHS.NOVO_AI.LAYOUT_HEADER() }
            </div>
            <ClickableContainer
              onClick={(event) => { handleClick(RichTextType.SIMPLE); }}
              data-qa={config.pendo.novoAi.summaryAndKeyTakeAways.layout.simpleText}
            >
              <div className={`text-simple option layout ${selectedItemTrueType === RichTextType.SIMPLE ? 'selected' : ''}`}>
                <div className='text font-weight-bolder'>{t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.BLURB_SAMPLE_CONTENT()}</div>
              </div>
            </ClickableContainer>
            <ClickableContainer
              onClick={(event) => { handleClick(ComponentType.TEXT_WITH_IMAGE_SIDE); }}
              data-qa={config.pendo.novoAi.summaryAndKeyTakeAways.layout.textWithImageSide}
            >
              <div className={`text-with-image-side option layout d-flex ${selectedItemTrueType === ComponentType.TEXT_WITH_IMAGE_SIDE ? 'selected' : ''}`}>
                <div className='sample-image' />
                <div className='text font-weight-bolder'>{t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.BLURB_SAMPLE_CONTENT()}</div>
              </div>
            </ClickableContainer>
            <ClickableContainer
              onClick={(event) => { handleClick(ComponentType.TEXT_WITH_IMAGE_BKG); }}
              data-qa={config.pendo.novoAi.summaryAndKeyTakeAways.layout.textWithImageBkg}
            >
              <div className={`text-with-image-bkg option layout ${selectedItemTrueType === ComponentType.TEXT_WITH_IMAGE_BKG ? 'selected' : ''}`}>
                <div className='text sample-image image-gradient text-white font-weight-bolder'>{t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.BLURB_BACKGROUND_IMAGE_SAMPLE_CONTENT()}</div>
              </div>
            </ClickableContainer>
            <ClickableContainer
              onClick={(event) => { handleClick(ComponentType.TEXT_WITH_IMAGE_TOP); }}
              data-qa={config.pendo.novoAi.summaryAndKeyTakeAways.layout.textWithImageTop}
            >
              <div className={`text-with-image-top option layout ${selectedItemTrueType === ComponentType.TEXT_WITH_IMAGE_TOP ? 'selected' : ''}`}>
                <div className='sample-image' />
                <div className='text font-weight-bolder'>{t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.BLURB_SAMPLE_CONTENT()}</div>
              </div>
            </ClickableContainer>
          </div>
        </div>
      )}
      {
        selectedItemTrueType
        && <Preferences />
      }
    </React.Fragment>
  );
};

export default SelectLayout;
