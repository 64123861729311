import { css } from '@emotion/react';
import React, {
  useState,
  forwardRef,
} from 'react';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import {
  VideoPracticeActivity, VideoPracticeOption,
  VideoPracticeScenario, VideoPracticeType,
} from 'redux/schemas/models/video-practice';

import {
  doubleSpacing, extraLargeSpacing, halfSpacing,
  standardSpacing, tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import { black, gray4, hexToRgbaString, primary, shade } from 'styles/global_defaults/colors';
import { largeDesktop, handheld, screenXsMax } from 'styles/global_defaults/media-queries';

import NvIcon from 'shared/components/nv-icon';
import ClickableContainer from 'components/clickable-container';
import { useMediaQuery } from 'react-responsive';
import PracticeActivityProgress from './practice-activity-progress';
import VideoPracticeTitle from './video-practice-title';
import { RecordMode } from './practice-activity-modal';
import { getTimeLimits } from './video-practice-utils';
import CountdownTimer from './countdown-timer';

import { config } from '../../../../config/pendo.config.json';

type SelectRecordingModeProps = {
  practiceType: VideoPracticeType;
  scenario: VideoPracticeScenario;
  activity?: VideoPracticeActivity;
  modalStepIndex: number;
  onNextStep: (mode: RecordMode) => void;
  take: number;
  onRetry: () => void
};

const styles = (practiceType) => css`
  background-color: ${hexToRgbaString(black, 0.95)};
  flex: 1;
  ${largeDesktop(css`
    min-height: 0;
  `)}

  .practice-activity-progress {
    margin-top: ${tripleSpacing}px;
    margin-bottom: ${extraLargeSpacing}px;
  }

  .details {
    width: 720px;

    .video-practice-title {
      margin-right: ${practiceType === VideoPracticeOption.ON_SPOT ? '100px' : 'initial'};
    }
  }

  .select-mode {
    max-width: 720px;
    min-height: 0;
    background-color: ${shade};
  }

  .option-container {
    flex-grow: 1;
    color: ${gray4};
    div {
      &:hover, &:focus, &.active {
        color: ${primary};
        outline: 2px solid ${primary};
      }
    }
    .icon-camera-only {
      font-size: 80px !important;
      margin-left: 95px;
      margin-right: 95px;
    }
    .icon-screen {
      font-size: 110px !important;
      margin-left: 95px;
      margin-right: 65px;
    }
  }
  .button-container {
    display: flex;
    margin-top: ${tripleSpacing}px;
    margin-bottom: ${doubleSpacing}px;

    button:first-of-type {
      margin-right: ${halfSpacing}px;
    }
  }
  ${handheld(css`
    .practice-activity-progress {
      margin-bottom: 0;
    }

    .details {
      width: 100%;
      padding-right: ${standardSpacing}px;
      padding-left: ${standardSpacing}px;

      .video-practice-title {
        margin-right: ${standardSpacing}px;
      }
    }

    .button-container, .option-container {
      flex-direction: column;
      width: 100%;
      padding: ${standardSpacing}px;
    }

  `)};
`;

const SelectRecordingMode = forwardRef<any, SelectRecordingModeProps>(({
  practiceType,
  scenario,
  activity,
  modalStepIndex,
  onNextStep,
  take,
  onRetry,
}, ref) => {
  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const [selectedMode, setSelectedMode] = useState(null);

  const { timeToRecord } = getTimeLimits(scenario);

  const isRetryDisabled = practiceType === VideoPracticeOption.ON_SPOT && take >= activity.maxTries;

  return (
    <div
      css={styles(practiceType)}
      className='d-flex flex-column justify-content-center align-items-center'
    >
      <PracticeActivityProgress modalStepIndex={modalStepIndex} />
      <div className='course-title-small text-white text-center mb-5'>
        {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SCREEN_RECORDING.RECORDING_MODE.SELECT_TITLE()}
      </div>
      <div className='details d-flex mb-1 justify-content-between'>
        <VideoPracticeTitle
          practiceType={practiceType}
          activityTitle={scenario.title}
          activity={activity}
          take={take}
        />
        <CountdownTimer countdownRemainingSeconds={timeToRecord} paused />
      </div>
      <div className='select-mode d-flex justify-content-center p-7 w-100'>
        <div className='d-flex option-container justify-content-between align-items-center mx-4 my-7'>
          <ClickableContainer
            className={`d-flex flex-column py-7 ${selectedMode === RecordMode.CAMERA ? 'active' : ''}`}
            onClick={() => setSelectedMode(RecordMode.CAMERA)}
            pendo-tag-name={config.pendo.practice.cameraMode}
          >
            <NvIcon size='largest' icon='camera-only' />
            <span className='text-xl font-weight-bold mt-4 text-center'>
              {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SCREEN_RECORDING.RECORDING_MODE.CAMERA()}
            </span>
          </ClickableContainer>
          <ClickableContainer
            className={`d-flex flex-column pt-4 pb-7 ${selectedMode === RecordMode.SCREEN ? 'active' : ''}`}
            onClick={() => setSelectedMode(RecordMode.SCREEN)}
            pendo-tag-name={config.pendo.practice.screenMode}
          >
            <NvIcon size='largest' icon='screen' />
            <span className='text-xl font-weight-bold mt-4 text-center'>
              {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SCREEN_RECORDING.RECORDING_MODE.SCREEN()}
            </span>
          </ClickableContainer>
        </div>
      </div>
      <div className='button-container'>
        <Button
          block={isHandheld}
          variant='secondary'
          onClick={onRetry}
          className='mr-4'
          disabled={isRetryDisabled}
        >
          {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RETRY.TITLE()}
        </Button>
        <Button
          block={isHandheld}
          onClick={() => onNextStep(selectedMode)}
          disabled={!selectedMode}
        >
          {t.FORM.CONFIRM()}
        </Button>
      </div>
    </div>
  );
});

export default SelectRecordingMode;
