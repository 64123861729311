import { AccordionSectionType, ComponentTrueType, ComponentType, ExternalToolType, RichTextType, StyledLinkType } from 'redux/schemas/models/lecture-component';
import { gray6, black, primary, gray7, teal, gray1, gray2, gray3, gray4, gray5, white, yellow } from 'styles/global_defaults/colors';
import transform from 'lodash/transform';
import deepFreeze from 'deep-freeze';
import cloneDeep from 'lodash/cloneDeep';
import { TimelineLectureVideo } from 'redux/schemas/models/lecture-video';
import { _DeepPartialArray } from 'utility-types/dist/mapped-types';
import t from 'react-translate';
import { ExternalToolWebLinkTypes } from 'redux/schemas/models/external-tool';
import { ComponentRecord } from '.';
import { SESSION_STORAGE_ITEM as WHITE_SPACE_SESSION_STORAGE_ITEM } from '../white-space-lecture-component';

/** Defines default lecture component data used to populate a lecture component object
 * when adding submitting it as a new component to the backend. */
const defaultComponentData: Readonly<ComponentRecord> = deepFreeze({
  [ComponentType.ACCORDION]: {
    // TODO: how to set this up?
    // viewOptions: {
    //   listStyleType:
    // }
  },
  [AccordionSectionType.STYLE_1]: {
    accordionSections: [
      {
        content: '',
        header: '',
        viewOptions: {
          icon_color: gray3,
          line_color: gray3,
        },
      },
    ],
    viewOptions: {
      listStyleType: 'more_less',
    },
  },
  [AccordionSectionType.STYLE_2]: {
    accordionSections: [
      {
        content: '',
        header: '',
        viewOptions: {
          iconColor: gray2,
          headerBackgroundColor: gray5,
        },
      },
    ],
    viewOptions: {
      listStyleType: 'more_less_circled',
    },
  },
  [AccordionSectionType.STYLE_3]: {
    accordionSections: [
      {
        content: '',
        header: '',
        viewOptions: {
          iconColor: white,
          headerBackgroundColor: gray4,
          textBackgroundColor: gray6,
          leftHandSymbolColor: white,
          leftHandSymbolBackgroundColor: gray3,
          leftHandSymbolShape: 'square',
          leftHandSymbolPosition: 'centered',
        },
      },
    ],
    viewOptions: {
      listStyleType: 'decimal',
    },
  },
  [AccordionSectionType.STYLE_4]: {
    accordionSections: [
      {
        content: '',
        header: '',
        viewOptions: {
          iconColor: gray1,
          headerBackgroundColor: gray5,
          textBackgroundColor: gray6,
          leftHandSymbolColor: black,
          leftHandSymbolBackgroundColor: '#ffffff',
          leftHandSymbolShape: 'circle',
          leftHandSymbolPosition: 'centered',
        },
      },
    ],
    viewOptions: {
      listStyleType: 'alphabetical',
    },
  },
  [AccordionSectionType.STYLE_5]: {
    accordionSections: [
      {
        content: '',
        header: '',
        viewOptions: {
          iconColor: white,
          headerBackgroundColor: gray3,
          textBackgroundColor: gray6,
          leftHandSymbolColor: white,
          leftHandSymbolBackgroundColor: gray3,
          leftHandSymbolShape: 'square',
          iconLibraryName: null,
        },
      },
    ],
    viewOptions: {
      listStyleType: 'custom_symbol',
    },
  },
  [ComponentType.ATTACHMENT]: {
    viewOptions: {
      embeded: false,
    },
  },
  [ComponentType.AUDIO]: {

  },
  [ComponentType.EXERCISE]: {},
  [ExternalToolType.LTI]: {
    externalTool: {
      toolType: 'lti',
      url: null,
      consumerKey: null,
      consumerSecret: null,
      gradePassback: false,
      sendEmail: false,
      aspectRatio: '4:3',
      activityType: 'reading',
    },
  },
  [ExternalToolType.SCORM]: {
    externalTool: {
      toolType: 'scorm',
      aspectRatio: '4:3',
      activityType: 'reading',
    },
  },
  [ExternalToolType.WEB_LINK]: {
    externalTool: {
      toolType: ExternalToolWebLinkTypes.WEB_LINK,
      name: null,
      url: null,
      activityType: 'reading',
    },
    viewOptions: {
      backgroundColor: gray6,
      libraryIconColor: white,
      libraryIconName: 'open-external',
      pictureShape: 'square',
      pictureBackgroundColor: gray3,
      textColor: black,
    },
  },
  [ExternalToolType.WEB_EMBED]: {
    externalTool: {
      toolType: ExternalToolWebLinkTypes.WEB_EMBED,
      name: null,
      url: null,
      aspectRatio: '16:9',
      activityType: 'reading',
    },
  },
  [ComponentType.GROUP_FORMATION]: {},
  [ComponentType.HEADER_STYLE1]: {
    viewOptions: {
      backgroundColor: gray7,
      textColor: black, // TODO: Should this be $.FE.DEFAULTS.defaultTextColor?
    },
  },
  [ComponentType.HEADER_STYLE2]: {
    viewOptions: {
      leftBorderColor: null, // setting as null allows us to use the brand color as default
      textColor: black, // TODO: Should this be $.FE.DEFAULTS.defaultTextColor?
    },
  },
  [ComponentType.HEADER_STYLE3]: {
    viewOptions: {
      backgroundColor: gray6,
      libraryIconColor: white,
      libraryIconName: '',
      pictureShape: 'square',
      pictureBackgroundColor: primary,
      textColor: black,
    },
  },
  [ComponentType.IMAGE]: {
    viewOptions: {
      alignment: 'left',
      width: '100%',
    },
  },
  [ComponentType.WHITE_SPACE]: {
    viewOptions: {
      topBorderWidth: 20,
    },
  },
  [ComponentType.LINE_DIVIDER]: {
    viewOptions: {
      topBorderColor: black, // TODO: Should this be $.FE.DEFAULTS.defaultTextColor
      topBorderWidth: '1px',
    },
  },
  [ComponentType.LIVE_SESSION]: {},
  [ComponentType.MEET_AND_GREET]: {},
  [ComponentType.POLL]: {},
  [ComponentType.PRIVATE_PEER_EVALUATION]: {},
  [ComponentType.PROFILE_COMPLETION]: {
    profileRequirement: {
      isTodo: false,
      requiredOrgFieldIds: null,
      requiredNovoedQuestions: null,
      requiredCourseProfileQuestionIds: null,
    },
  },
  [ComponentType.PROGRESSIVE_QUIZ]: {
    progressiveQuiz: {
      title: '',
      graded: true,
      isTodo: false,
      hardDeadline: false,
      expirationDate: null,
    },
  },
  [ComponentType.PUBLIC_PEER_EVALUATION]: {},
  [ComponentType.EXERCISE_SKILLS_RATING]: {},
  [ComponentType.QUIZ]: {
    quiz: {
      title: '',
      expirationDate: null,
      hardDeadline: false,
      graded: true,
      maximumAttempts: null,
      isTodo: false,
    },
  },
  [RichTextType.FULL]: {
    viewOptions: {
      styleOptions: 'full',
    },
  },
  [RichTextType.SIMPLE]: {
    viewOptions: {

      styleOptions: 'simple',
    },
  },
  [ComponentType.SUBMISSION_DISCOVERY]: {},
  [StyledLinkType.BUTTON]: {
    viewOptions: {
      styleType: 'button',
      libraryIconName: 'open-external',
      libraryIconColor: '#FFFFFF',
      buttonColor: primary,
    },
  },
  [StyledLinkType.CARD]: {
    viewOptions: {
      styleType: 'card',
      libraryIconName: 'admin-embeds',
      libraryIconColor: '#FFFFFF',
      libraryIconBackground: yellow,
    },
  },
  [ComponentType.SURVEY]: {
    survey: {
      title: 'Untitled', // TODO: Does this need translated?
    },
  },
  [ComponentType.TEAM_DISCUSSION]: {
    topic: {
      title: '',
      body: '',
      highLightedFrom: null,
      highlightedTo: null,
      starterId: null,
      formedByStudents: null,
      teamSizeLimit: 10,
      name: '',
    },
  },
  [ComponentType.TEAM_FORMATION]: {},
  [ComponentType.TEXT_WITH_IMAGE_BKG]: {
    picture: null,
    content: '',
    viewOptions: {
      componentWidth: '100%',
      width: '80%',
      minHeight: '120px',
      textStyle: 'small-title',
      textColor: '#ffffff',
      verticalAlign: 'middle',
      textAlign: 'center',
      backgroundColor: gray4,
    },
  },
  [ComponentType.TEXT_WITH_IMAGE_SIDE]: {
    picture: null,
    content: '',
    viewOptions: {
      componentWidth: '100%',
      picturePlacement: 'left',
      pictureWidth: '20%',
      pictureShape: 'circle',
      pictureAlt: '',
      verticalAlign: 'top',
    },
  },
  [ComponentType.TEXT_WITH_IMAGE_TOP]: {
    picture: null,
    content: '',
    viewOptions: {
      componentWidth: '100%',
      pictureWidth: '80px',
      pictureShape: 'circle',
      pictureAlt: '',
    },
  },
  [ComponentType.TIMED_QUIZ]: {
    quiz: {
      title: '',
      expirationDate: null,
      hardDeadline: true,
      releaseResultsAt: null,
      timeLimit: null,
      timeInterval: 'Minutes',
    },
  },
  [ComponentType.TOPIC]: {
    post: {
      title: '',
      body: '',
      topic: null,
      starterId: null,
      expandedOnLoad: true,
    },
  },
  [ComponentType.VIDEO]: {
    // A type error occurs from this being incompatible with _DeepPartial<TimelineLectureVideo[]>
    // without this cast, which seems incorrect
    lectureVideos: [] as any,
  },
  [ComponentType.VIDEO_PRACTICE]: {},
  [ComponentType.VIDEO_PRACTICE_FEEDBACK]: {},
  [ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK]: {},
});

/** These define a runtime reverse mapping of true type strings to component type strings. This is only used in this file and
 * shouldn't need changed unless we add more category component types in the future (which we should avoid), so let's keep these functions
 * local to this file */
const reverseMapTrueType = (trueTypeEnum: Object, type: ComponentType): Object => transform(trueTypeEnum, (r, v: any) => { r[v] = type; });
export const reverseTrueTypeMapping = {
  ...Object.assign({}, ...Object.values(ComponentType).map(v => ({ [v]: v }))),
  ...reverseMapTrueType(ExternalToolType, ComponentType.EXTERNAL_TOOL),
  ...reverseMapTrueType(RichTextType, ComponentType.RICH_TEXT),
  ...reverseMapTrueType(AccordionSectionType, ComponentType.ACCORDION),
  ...reverseMapTrueType(StyledLinkType, ComponentType.STYLED_LINK),
};

/** Gets a lecture component data object pre-populated with default data during new component
 * creation. This is used instead of directly exporting defaultComponentData for these reasons:
 * 1) Allows pre-populatings some fields the same across all components
 * 2) Allows default data to use translations, which are not loaded at build time and cannot be
 * eval'ed in an exported constant obj
 * 3) Other hardcoded specific behaviors for specific components
 */
const getDefaultNewComponentData = (componentType: ComponentTrueType) => {
  const defaultData = {
    ...cloneDeep(defaultComponentData[componentType]),
    type: reverseTrueTypeMapping[componentType],
    trueType: componentType,
  };

  if (componentType === StyledLinkType.BUTTON) {
    defaultData.header = t.LECTURE_PAGES.COMPONENTS.STYLED_LINK_BUTTON.BLURB();
  } else if (componentType === StyledLinkType.CARD) {
    defaultData.header = t.LECTURE_PAGES.COMPONENTS.STYLED_LINK_CARD.BLURB();
  }

  if (componentType === ComponentType.WHITE_SPACE) {
    const lastWidth = sessionStorage.getItem(WHITE_SPACE_SESSION_STORAGE_ITEM);
    defaultData.viewOptions.topBorderWidth = lastWidth ? Number(lastWidth) : defaultData.viewOptions.topBorderWidth;
  }

  return defaultData;
};

export default getDefaultNewComponentData;
