import { RootState } from 'redux/schemas';
import { createSelector } from 'reselect';
import { find, findIndex } from 'lodash';

export const allTags = (state: RootState) => state.models.skillTags;

export const getSkillTagsForProdCourses = createSelector(
  [(state: RootState) => state.models.skillTagsForProdCourses],
  (skillTags) => Object.values(skillTags).map((skillTag) => ({
    id: skillTag.id,
    name: skillTag.name,
  })).sort((a, b) => a.name.localeCompare(b.name)),
);

const getSkillTagId = (state: RootState, skillTaggingId: number) => state.models.skillTaggings[skillTaggingId]?.skillTagId;
export const getSkillTag = createSelector([getSkillTagId, allTags], (tagId, tags) => tags[tagId]);

export const hasRatings = (state: RootState, skillTagId: number) => find(state.models.skillTaggings, (skillTagging) => skillTagging.skillTagId === skillTagId)?.hasRatings;

const authorFeedback = (state: RootState, ownerId: number, ownerType: string) => state.app[ownerType]?.[ownerId]?.authorFeedback?.ratings ?? [];
const allRatings = (state: RootState) => state.models.skillRatings;
const skillTaggings = (state: RootState) => state.models.skillTaggings;

export const getSkillTagsFromTaggings = createSelector([
  (state: RootState, skillTaggingIds: number[]) => skillTaggingIds,
  skillTaggings,
  allTags,
], (skillTaggingIds, taggings, tags) => skillTaggingIds
  .filter((taggingId) => (
    taggings[taggingId]
      && taggings[taggingId].skillTagId
      && tags[taggings[taggingId].skillTagId]
  ))
  .map((taggingId) => tags[taggings[taggingId].skillTagId])
  .sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  }));

export const sortSkillTags = (skillTags) => skillTags.sort((a, b) => (
  a.name.toLowerCase().localeCompare(b.name.toLowerCase())
));

export const authorRatingsSorted = createSelector([
  skillTaggings,
  allTags,
  authorFeedback,
  allRatings,
], (
  skillTagging,
  tags,
  authorRatings,
  ratings,
) => {
  const arrayToSort = [...authorRatings];
  const ids = arrayToSort?.sort((a, b) => {
    const tagIdA = skillTagging?.[ratings?.[a]?.skillTaggingId]?.skillTagId;
    const tagIdB = skillTagging?.[ratings?.[b]?.skillTaggingId]?.skillTagId;

    if (tags?.[tagIdA]?.name?.toLowerCase() < tags?.[tagIdB]?.name?.toLowerCase()) {
      return -1;
    }
    if (tags?.[tagIdA]?.name?.toLowerCase() > tags?.[tagIdB]?.name?.toLowerCase()) {
      return 1;
    }
    return 0;
  });
  return ids;
});

export const getSubmissionRatingsByUser = createSelector(
  (state: RootState, ratingIds: number[]) => ratingIds,
  allRatings,
  allTags,
  skillTaggings,
  (ratingIds, ratings, tags, taggings) => ratingIds.map(id => {
    const rating = {
      ...ratings[id],
      skillTag: tags[taggings[ratings[id]?.skillTaggingId]?.skillTagId],
    };
    return rating;
  }),
);

export const getToSubmission = (state: RootState, submissionId: number, isPrevious: boolean) => {
  const submission = state.models.submissions[submissionId];

  if (!submission) {
    return null;
  }

  const exerciseSkillRatingActivity = find(
    state.models.exerciseSkillsRatingActivities,
    (skillRating) => skillRating.activity?.id === submission.exercise?.id,
  );

  if (!exerciseSkillRatingActivity) {
    return null;
  }

  let toIndex: number;
  if (isPrevious) {
    toIndex = findIndex(exerciseSkillRatingActivity.feedbackGalleryIds, (id) => id === submission.id) - 1;
    if (toIndex < 0) {
      return null;
    }
  } else {
    toIndex = findIndex(exerciseSkillRatingActivity.feedbackGalleryIds, (id) => id === submission.id) + 1;
    if (toIndex > exerciseSkillRatingActivity.feedbackGalleryIds.length) {
      return null;
    }
  }

  const toSubmissionId = exerciseSkillRatingActivity.feedbackGalleryIds[toIndex];

  if (!toSubmissionId) {
    return null;
  }

  const toSubmission = state.models.submissions[toSubmissionId];

  if (!toSubmission) {
    return null;
  }

  return toSubmission;
};

export const getNextNotRatedSubmission = (state: RootState, submissionId: number) => {
  const submission = state.models.submissions[submissionId];

  if (!submission) {
    return null;
  }

  const exerciseSkillRatingActivity = find(
    state.models.exerciseSkillsRatingActivities,
    (skillRating) => skillRating.activity?.id === submission.exercise?.id,
  );

  if (!exerciseSkillRatingActivity) {
    return null;
  }

  if (!(exerciseSkillRatingActivity.feedbackGalleryIds?.length > 0)) {
    return null;
  }

  return find(
    exerciseSkillRatingActivity.feedbackGalleryIds.map((galleryId) => state.models.submissions[galleryId]),
    (gallerySubmission) => gallerySubmission.id !== submissionId && !gallerySubmission.isRatedByCurrentUser,
  );
};

const skillRatings = (
  state: RootState,
  ownerKey: string,
  submissionId: number,
  skillTaggingId: number,
) => state.app[ownerKey]?.[submissionId]?.skillTaggings?.[skillTaggingId]?.ratings ?? [];

export const ratingsSorted = createSelector([
  skillRatings,
  allRatings,
], (
  ratingsToSort,
  ratings,
) => {
  const arrayToSort = [...ratingsToSort];
  const ids = arrayToSort?.sort((a, b) => {
    const createdAtA = new Date(ratings?.[a]?.createdAt);
    const createdAtB = new Date(ratings?.[b]?.createdAt);

    return createdAtB.getTime() - createdAtA.getTime();
  });
  return ids;
});
