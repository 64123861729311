export default {
  bindings: {
    lectureComponent: '<',
    editMode: '<',
    restrictedEditMode: '<',
    reorderMode: '<',
    index: '<',
    context: '<',
  },
  controller: function ctrl(
    $controller,
    $timeout,
    $scope,
  ) {
'ngInject';
    angular.extend(this, $controller('LectureComponentBaseCtrl'));
    const vm = this;

    vm.setEditMenu = () => {
      const { sharedProps, setSharedProps } = this.context;
      const extraOptions = [];
      extraOptions.push(sharedProps.extraOptions.getEditOption());

      setSharedProps({
        ...sharedProps,
        isFullWidth: true,
        extraOptions: {
          ...sharedProps.extraOptions,
          ...{ options: extraOptions },
          renderOnMount: true,
        },
      });
    };

    vm.setEditMenu();

    vm.editBasics = () => {
      $timeout(() => {
        this.context.sharedProps.openBasicModal();
      });
    };

    $scope.$watch('vm.editMode', () => {
      if (vm.editMode) {
        vm.carouselSubmissions = [];
        vm.submissions = [];
      } else {
        vm.carouselSubmissions = vm.lectureComponent.carouselSubmissions;
        vm.submissions = vm.lectureComponent.submissions;
      }
    });
  },
  controllerAs: 'vm',
  templateUrl: 'lecture_pages/templates/components/nv-submissions-discovery-lecture-component.html',
};
