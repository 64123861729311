import { createReducer } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import mergeWith from 'lodash/mergeWith';
import { replaceArrays } from 'shared/lodash-utils';
import { PracticeActivitiesAvailableSchema } from 'redux/schemas/api/video-practice';
import {
  getAvailablePracticeActivities, setFeedbackActivity,
  setRequirementSubmitted,
  setShowFeedbackInstruction, setShowPracticeFeedbackModal,
  updateActivityPoints, updatePracticeFeedbackPointsAndProgress,
} from 'redux/actions/video-practice-feedback';
import { initialRootState } from '.';

export default createReducer(initialRootState, builder => {
  builder
    .addCase(getAvailablePracticeActivities.fulfilled, (state, action) => {
      const { catalogId } = action.meta.arg;
      const data = normalize(action.payload, PracticeActivitiesAvailableSchema);
      state.models.courses[catalogId].practiceActivitiesAvailableForFeedback = data.result;
      mergeWith(state.models.practiceActivities, data.entities.practiceActivities, replaceArrays);
    })
    .addCase(setFeedbackActivity, (state, action) => {
      state.app.videoPracticeFeedback.practiceFeedbackCriteriaId = action.payload;
    })
    .addCase(setShowPracticeFeedbackModal, (state, action) => {
      state.app.videoPracticeFeedback.showPracticeFeedbackModal = action.payload;
    })
    .addCase(setShowFeedbackInstruction, (state, action) => {
      state.app.videoPracticeFeedback.showFeedbackInstruction = action.payload.show;
      if (action.payload.practiceFeedbackCriteriaId) {
        if (!state.app.videoPracticeFeedback.userHasViewdInstruction) {
          state.app.videoPracticeFeedback.userHasViewdInstruction = {};
        }
        state.app.videoPracticeFeedback.userHasViewdInstruction[action.payload.practiceFeedbackCriteriaId] = true;
      }
    })
    .addCase(setRequirementSubmitted, (state, action) => {
      if (state.models.practiceFeedbackActivities[action.payload]) {
        state.models.practiceFeedbackActivities[action.payload].hasSubmittedRequirement = true;
      } else {
        state.models.exerciseSkillsRatingActivities[action.payload].hasSubmittedRequirement = true;
      }
    })
    .addCase(updateActivityPoints, (state, action) => {
      if (state.models.practiceFeedbackActivities[action.payload.id]) {
        state.models.practiceFeedbackActivities[action.payload.id].pointsReceived = action.payload.points;
      } else {
        state.models.exerciseSkillsRatingActivities[action.payload.id].pointsReceived = action.payload.points;
      }
    })
    .addCase(updatePracticeFeedbackPointsAndProgress, (state, action) => {
      const { id, progress, numReviewesCompleted, pointsReceived } = action.payload;
      const activity = state.models.practiceFeedbackActivities?.[id] ?? state.models.exerciseSkillsRatingActivities?.[id];
      if (activity) {
        activity.progress = progress;
        activity.pointsReceived = pointsReceived;
        activity.numReviewesCompleted = numReviewesCompleted;
      }
    });
});
