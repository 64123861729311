import { useContext, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import t from 'react-translate';
import { validateFiles } from 'froala/helpers/nv-froala-functions';
import useUploadFile, { NovoEdFile } from 'shared/hooks/use-upload-file';
import { S3NameSpaces } from 'shared/services/s3-upload-factory';
import NvFileUpload from 'shared/components/nv-file-upload';
import NvFileUploadProgress from 'shared/components/nv-file-upload-progress';
import NvUploadedFile from 'shared/components/nv-uploaded-file';
import { FileRejection } from 'react-dropzone';
import { some } from 'underscore';
import { VideoPracticeOptionsModalContext } from './video-practice-options-modal';

import { config } from '../../../../config/config.json';

const VideoPracticePrompt = ({ file }) => {
  const { register, setValue } = useFormContext() || {};

  const { catalogId } = useContext(VideoPracticeOptionsModalContext);

  const { uploadFiles, isUploading, hasUploadPercentage, filesUploading, abortUpload } = useUploadFile();
  const [document, setDocument] = useState<NovoEdFile>(file);
  const [fileError, setFileError] = useState<string>('');
  const [isRecording, setIsRecording] = useState<boolean>(false);

  const onUploadFile = async (files: File[], validate = false, fileIsRecording = false) => {
    setIsRecording(fileIsRecording);

    let fError;
    if (validate) {
      fError = validateFiles(files, config.files.allVideoExtensions);
    }

    if (fError) {
      setFileError(fError);
    } else {
      setFileError('');
      const [novoedFile] = await uploadFiles(files, S3NameSpaces.VIDEO_PRACTICE);
      setDocument(novoedFile);
      setValue('videoPrompt', novoedFile);
    }
  };

  const onSelectError = (files: FileRejection[]) => {
    const hasFileError = some(files[0]?.errors, (error => error.code === 'file-too-large'));
    if (hasFileError) {
      setFileError(t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.CREATE_PRACTICE_SCENARIO.SIZE_EXCEEDED());
    } else {
      setFileError('');
    }
  };

  useEffect(() => {
    register('videoPrompt');
  }, [register]);

  const onDelete = () => {
    setDocument(null);
    setValue('videoPrompt', null);
  };

  const fileUploading = filesUploading[0];
  let content = (
    <NvFileUpload
      multiple={false}
      type='video'
      error={fileError}
      catalogId={catalogId}
      onUploadFile={onUploadFile}
      onSelectError={onSelectError}
      recordingOverlaySubmitButtonText={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.CREATE_PRACTICE_SCENARIO.ADD_TO_PRACTICE_SCENARIO()}
    />
  );

  if (!fileError && isUploading) {
    content = (
      <NvFileUploadProgress
        showFileName={!isRecording}
        hasUploadPercentage={hasUploadPercentage}
        uploadInProgress={isUploading}
        fileUploading={fileUploading}
        abortUpload={abortUpload}
      />
    );
  }

  if (!fileError && document?.name) {
    content = <NvUploadedFile file={document ?? fileUploading.novoEdFile} onDelete={onDelete} />;
  }

  return content;
};

export default VideoPracticePrompt;
