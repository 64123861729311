/* @ngInject */
export default function UploadLiveSessionAttendeesModalCtrl(

  _,
  AlertMessages,
  CurrentCourseManager,
  Papa,
  $uibModalInstance,
  $timeout,
  nvUtil,
  uploadAttendance,
  validateEmails,
  validationConstants,
) {
  class UploadLiveSessionAttendeesModalController {
    constructor() {
      this.emails = [];

      this.frontendErrors = [];

      // results from backend
      this.validEmails = [];
      this.invalidEmails = [];

      this.VIEWS = {
        UPLOAD_CSV: 0,
        PARSING_CSV: 1,
        FRONTEND_VALIDATION_FAILED: 2,
        FINAL: 3,
      };
      this.currentView = this.VIEWS.UPLOAD_CSV;

      this.nvUtil = nvUtil;
      this.templateCSV = [['john.doe@email.com'], ['jane.doe@email.com']];

      this.uploadAttendance = uploadAttendance;
      this.validateEmails = validateEmails;
      this.CurrentCourseManager = CurrentCourseManager;

      this.uploadFailed = false;
      this.overwriteList = true;
      this.submitting = false;
    }

    chooseCSV(files) {
      this.currentView = this.VIEWS.PARSING_CSV;

      Papa.parse(files[0], {
        header: false,
        dynamicTyping: true,
        complete: this.onParsingComplete.bind(this),
        skipEmptyLines: true,
      });
    }

    onParsingComplete(res) {
      const emailRegex = new RegExp(validationConstants.EMAIL_REGEX);

      $timeout(() => {
        res.data.forEach((row, index) => {
          row.forEach((item, column) => {
            const trimmedItem = item?.trim();
            if (!emailRegex.test(trimmedItem)) {
              if (trimmedItem) {
                this.frontendErrors.push({ row: (index + 1), column: (column + 1) });
              }
            } else {
              this.emails.push(trimmedItem);
            }
          });
        });
        if (this.frontendErrors.length) {
          this.currentView = this.VIEWS.FRONTEND_VALIDATION_FAILED;
        } else {
          this.validateUsersInCourse();
        }
      });
    }

    uploadNewCSV(files) {
      this.emails = [];
      this.frontendErrors = [];
      this.validEmails = [];
      this.invalidEmails = [];

      this.chooseCSV(files);
    }

    // This is necessary because we want the standard aliases in addition to the lengths of valid and invalid emails
    // Object.assign is not available in the template and using interpolated translate-values
    //    eg: translate-value-number-of-valid-emails='{{ vm.validEmails.length }}'
    // does not work because the interpolation and the translation have the same priority (https://docs.angularjs.org/api/ng/service/$compile#-priority-)
    getTranslateValues() {
      return Object.assign(CurrentCourseManager.course.getAliases(), { numberOfValidEmails: this.validEmails.length, numberOfInvalidEmails: this.invalidEmails.length });
    }

    validateUsersInCourse() {
      this.validateEmails(this.emails).then((res) => {
        this.validEmails = res.validEmails;
        this.invalidEmails = res.invalidEmails;
        this.currentView = this.VIEWS.FINAL;
      });
    }

    save() {
      this.uploadFailed = false;
      this.submitting = true;
      this.uploadAttendance(this.validEmails, this.overwriteList).then((res) => {
        this.submitting = false;
        $uibModalInstance.dismiss();
        AlertMessages.success('FORM.SUCCESS_BANG', 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.SUCCESS');
      }).catch((err) => {
        this.submitting = false;
        this.uploadFailed = true;
      });
    }
  }

  return new UploadLiveSessionAttendeesModalController();
}
