import { css } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'react-bootstrap';
import React, { memo, useState, useEffect, useImperativeHandle, forwardRef, MutableRefObject, useRef, useCallback, Fragment, SetStateAction, Dispatch } from 'react';
import t from 'react-translate';
import useTimer from 'recording/hooks/use-timer';
import useCountdownBeep from 'recording/hooks/use-countdown-beep';
import useMountEffect from 'shared/hooks/use-mount-effect';
import useMediaRecorder from 'recording/hooks/use-media-recorder';
import { MediaSourceType, requestMediaStream, stopMediaStreams } from 'recording/services/media-stream';
import AudioStreamPreview from 'recording/components/audio-stream-preview';
import VideoStreamPreview from 'recording/components/video-stream-preview';
import { useAppDispatch } from 'redux/store';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import {
  RecordingFormat,
  VideoPracticeScenario,
  VideoPracticeActivity,
  VideoPracticeOption,
  VideoPracticeType,
  PracticeAgain,
} from 'redux/schemas/models/video-practice';
import { black, danger, hexToRgbaString, shade, white } from 'styles/global_defaults/colors';
import {
  quarterSpacing, halfSpacing, standardSpacing, largeSpacing,
  doubleSpacing, tripleSpacing, extraLargeSpacing,
} from 'styles/global_defaults/scaffolding';
import { handheld, largeDesktop, notHandheld, screenXsMax } from 'styles/global_defaults/media-queries';
import NvPopover from 'shared/components/nv-popover';
import NvVideoPreview from 'shared/components/nv-video-preview';
import ClickableContainer from 'components/clickable-container';
import { PercentageIndicator, RecordingIndicator } from 'recording/components/recording-indicator';
import { getTimeLimits, roundToMinutes } from './video-practice-utils';
import PracticeActivityProgress from './practice-activity-progress';
import VideoPracticeTitle from './video-practice-title';
import { RecordMode } from './practice-activity-modal';
import CountdownTimer from './countdown-timer';
import { config } from '../../../../config/pendo.config.json';

export type OnStopRecordingParams = {
  blob: Blob;
  timeElapsed: number;
};

type RecordPracticeActivityProps = {
  practiceType: VideoPracticeType;
  modalStepIndex: number;
  onStopRecording: (params: OnStopRecordingParams) => void;
  onRetry: () => void;
  scenario: VideoPracticeScenario;
  activity?: VideoPracticeActivity;
  take: number;
  mode?: RecordMode;
  selectRecordingMode: () => void;
  showPrompt: boolean;
  setShowPrompt: Dispatch<SetStateAction<boolean>>;
  promptFontSize: number;
  setPromptFontSize: Dispatch<SetStateAction<number>>;
};

enum RecordState {
  NOT_STARTED = 'notStarted',
  RUNNING = 'running',
  PAUSED = 'paused',
  COUNTING_DOWN = 'countingDown',
  DONE = 'done',
}

const Prompt = ({ scenario, onlyVideo, increaseFontSize, decreaseFontSize }) => (onlyVideo ? (
  <div className='video-wrapper overflow-hidden'>
    <NvVideoPreview file={scenario.videoPrompt} />
  </div>
) : (
  <div>
    <div className='font-size-change'>
      <div className='font-button' onClick={() => increaseFontSize()}>T+</div>
      <div className='font-button-divider' />
      <div className='font-button' onClick={() => decreaseFontSize()}>T–</div>
    </div>
    <div className='prompt'>
      {/* eslint-disable-next-line react/no-danger */}
      <div className='text-prompt' dangerouslySetInnerHTML={{ __html: scenario.textPrompt }} />
      {scenario.videoPrompt && (
        <div className='video-wrapper'>
          <NvVideoPreview file={scenario.videoPrompt} />
        </div>
      )}
    </div>
  </div>
));

const MemoizedPrompt = memo(Prompt);

const RecordPracticeActivity = forwardRef<any, RecordPracticeActivityProps>(({
  practiceType,
  modalStepIndex,
  onRetry,
  onStopRecording,
  scenario,
  activity,
  take,
  mode,
  selectRecordingMode,
  showPrompt,
  setShowPrompt,
  promptFontSize,
  setPromptFontSize,
}, ref: MutableRefObject<any>) => {
  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const [recordState, setRecordState] = useState<RecordState>(RecordState.NOT_STARTED);

  const styles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${hexToRgbaString(black, 0.95)};
    flex: 1;
    ${largeDesktop(css`
      overflow: hidden;
    `)}

    .practice-activity-progress {
      margin-top: 60px;
      margin-bottom: 50px;
    }

    .course-title-small {
      margin-bottom: ${largeSpacing}px;
    }

    .details {
      display: flex;
      justify-content: space-between;
      width: 900px;
      margin-bottom: ${quarterSpacing}px;

      .video-practice-title {
        margin-right: 100px;
      }
    }

    .prompt {
      overflow-y: auto;
      background-color: ${black};
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      margin-top: ${doubleSpacing}px;

      ${notHandheld(css`
        width: 960px;
        max-height: 480px;
        overflow-y: auto;
        flex: 1;
      `)};
    }

    .text-prompt {
      width: 720px;
      color: white;
      margin-top: ${standardSpacing}px;
      margin-bottom: ${largeSpacing}px;

      &, & > * {
        font-size: ${promptFontSize}px !important;
        line-height: ${Math.round(promptFontSize * 1.5)}px !important;
      }
    }

    .video-wrapper {
      width: 720px;
      max-width: 100%;
      .nv-video-preview, .clearfix, .jwplayer-container, .jwplayer {
        height: 100% !important;
      }
    }

    .font-size-change {
      color: ${white};
      background-color: ${black};
      padding: ${halfSpacing}px;

      display: flex;
      flex-direction: column;
      position: relative;
      float: right;
      top: ${doubleSpacing}px;

      font-weight: bold;
      font-size: 18px;

      .font-button {
        cursor: pointer;
      }

      .font-button-divider {
        background-color: ${white};
        height: 1px;
        width: 100%;
        margin-top: ${halfSpacing}px;
        margin-bottom: ${halfSpacing}px;
      }
    }

    .hr-divider {
      background-color: ${white};
      height: 2px;
      width: 100%;
      margin-top: ${standardSpacing}px;
      margin-bottom: ${standardSpacing}px;
    }

    .prompt-button {
      border-radius: ${halfSpacing}px;
      align-self: center;
    }

    .record-activity {
      max-height: 405px;
      min-height: 150px;
      position: relative;
      ${mode === RecordMode.SCREEN && `
        max-width: 720px;
        width: 100%;
        height: 480px;
        background: ${recordState === RecordState.RUNNING ? '#597393' : ''};
      `}
      .video-stream-preview {
        width: 100%;
        ${mode === RecordMode.SCREEN && `
        .video-wrapper {
          position: initial;
          video {
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }`}
      }
      .screen-recording-desc {
        ${notHandheld(css`padding: ${tripleSpacing}px ${extraLargeSpacing * 2}px;`)}
        z-index: 1;
      }

      .when-ready-desc {
        z-index: 1;
      }

      .video-stream-mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${hexToRgbaString(shade, 0.7)};
      }

      .record-countdown, .record-when-ready {
        position: absolute;
        left: 50%;
        top: ${isHandheld ? 70 : 80}%;
        transform: translate(-50%, -50%);
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;

        .starts-in, .when-ready {
          margin-bottom: ${standardSpacing}px;
          white-space: nowrap;
        }

        .recording-countdown-border {
          width: ${isHandheld ? 40 : 98}px;
          height: ${isHandheld ? 40 : 46}px;
          background-color: ${hexToRgbaString(danger, 0.5)};
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          .recording-countdown {
            width: ${isHandheld ? 30 : 88}px;
            height: ${isHandheld ? 30 : 36}px;
            background-color: ${danger};
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .record-button-border {
          height: ${isHandheld ? 36 : 46}px;
          width: ${isHandheld ? 36 : 46}px;
          background-color: white;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .record-button {
            height: ${isHandheld ? 26 : 36}px;
            width: ${isHandheld ? 26 : 36}px;
            background-color: red;
            border-radius: 50%;
          }
        }
      }
    }

    .audio-stream-preview {
      background: ${black};

      width: 900px;
    }
    .recording-indicator {
      left: ${halfSpacing}px;
      bottom: ${halfSpacing}px;
    }

    .button-container {
      display: flex;
      margin-top: ${tripleSpacing}px;
      margin-bottom: ${doubleSpacing}px;

      button:first-of-type {
        margin-right: ${halfSpacing}px;
      }
    }

    ${handheld(css`
      .practice-activity-progress {
        margin-bottom: 0;
      }

      .details {
        width: 100%;
        padding-right: ${standardSpacing}px;
        padding-left: ${standardSpacing}px;

        .video-practice-title {
          margin-right: ${standardSpacing}px;
        }
      }

      .prompt {
        width: 100%;
        padding: ${standardSpacing}px;

        .text-prompt {
          width: 100%;
          margin-top: 0;
        }

        .video-wrapper {
          width: 100%;
        }
      }

      .record-activity {
        padding-left: 20px;
        padding-right: 20px;
      }

      .button-container {
        width: 100%;
        flex-direction: column-reverse;
        padding-right: ${standardSpacing}px;
        padding-left: ${standardSpacing}px;

        button:first-of-type {
          margin-top: ${halfSpacing}px;
        }
      }
    `)};
  `;

  const dispatch = useAppDispatch();

  const { timeToRecord } = getTimeLimits(scenario);
  const timeToRecordRounded = roundToMinutes(timeToRecord);

  const isMounted = useRef(false);

  const [mediaStream, setMediaStream] = useState<MediaStream>();
  const recorder = useMediaRecorder(
    mediaStream,
    scenario.recordingFormat === RecordingFormat.AUDIO ? MediaSourceType.MIC : MediaSourceType.CAMERA_MIC,
    timeToRecord,
  );

  const [preRecordCountdown, startCountdown, pauseCountdown, resumeCountdown] = useTimer();
  const [recordCountdown, startTimer, pauseTimer, resumeTimer] = useTimer();
  const [playCountdownBeep] = useCountdownBeep();

  const pause = () => {
    if (recordState === RecordState.RUNNING || recordState === RecordState.COUNTING_DOWN) {
      setRecordState(RecordState.PAUSED);
      if (preRecordCountdown) {
        pauseCountdown();
      } else if (recordCountdown) {
        recorder.pauseRecording();
        pauseTimer();
      }
    }
  };

  const resume = () => {
    if (recordState === RecordState.PAUSED) {
      recorder.resumeRecording();
      if (preRecordCountdown) {
        setRecordState(RecordState.COUNTING_DOWN);
        resumeCountdown();
      } else if (recordCountdown) {
        setRecordState(RecordState.RUNNING);
        resumeTimer();
      }
    }
  };

  const stopRecordingCallback = () => {
    stopMediaStreams(mediaStream);
    setMediaStream(null);
    recorder.destroyCurrentRecorder();
    setRecordState(RecordState.DONE);
  };

  const stopRecording = (proceedToSubmit = false) => {
    recorder.stopRecording()
      .then((blob: Blob) => {
        stopRecordingCallback();
        if (proceedToSubmit) {
          onStopRecording({ blob, timeElapsed: timeToRecord - recordCountdown });
        }
      });
  };

  const preRecordCountdownCallback = () => {
    startTimer(timeToRecord, () => stopRecording(true));
    recorder.startRecording();
    setRecordState(RecordState.RUNNING);
  };

  const startPreRecordCountdown = () => {
    if (mode === RecordMode.SCREEN) {
      playCountdownBeep();
    }
    startCountdown(3, preRecordCountdownCallback);
    setRecordState(RecordState.COUNTING_DOWN);
  };

  const onStopScreenRecording = useCallback(() => {
    if (isMounted.current) {
      setMediaStream(null);
    }

    if (recordState === RecordState.NOT_STARTED
      || recordState === RecordState.COUNTING_DOWN) {
      selectRecordingMode();
    } else {
      stopRecording(true);
    }
  }, [recordState]);

  useEffect(() => {
    window.stopScreenStreamCallback = () => {
      window.stopScreenStreamCallback = null;

      onStopScreenRecording();
    };
  }, [onStopScreenRecording]);

  useMountEffect(() => {
    const getMediaStream = () => {
      setMediaStream(null);

      let mediaStreamType = MediaSourceType.MIC;
      if (scenario.recordingFormat === RecordingFormat.VIDEO) {
        mediaStreamType = mode === RecordMode.SCREEN ? MediaSourceType.SCREEN_MIC : MediaSourceType.CAMERA_MIC;
      }
      requestMediaStream(mediaStreamType).then((stream: MediaStream) => {
        setMediaStream(stream);
        recorder.initializeRecorder(stream);

        if (practiceType === VideoPracticeOption.ON_SPOT) {
          startPreRecordCountdown();
        }

        if (mode === RecordMode.SCREEN) {
          window.stopScreenStreamCallback = () => {
            window.stopScreenStreamCallback = null;

            onStopScreenRecording();
          };
        }
      }).catch(error => {
        if (mode === RecordMode.SCREEN) {
          if (error.type === 'audio' && error.name === 'NotFoundError') {
            dispatch(openConfirmationDialog({
              confirmButtonVariant: 'primary',
              title: t.FILE_UPLOAD.CANNOT_RECORD.TITLE(),
              bodyText: t.FILE_UPLOAD.CANNOT_RECORD.AUDIO_NOT_FOUND(),
              confirmText: t.FORM.CONTINUE(),
              onConfirm: () => getMediaStream(),
              onCancel: () => selectRecordingMode(),
            }));
          } else if (error.type === 'audio' && error.name === 'NotAllowedError') {
            dispatch(openConfirmationDialog({
              confirmButtonVariant: 'primary',
              title: t.FILE_UPLOAD.CANNOT_RECORD.TITLE(),
              bodyText: t.FILE_UPLOAD.CANNOT_RECORD.AUDIO_NOT_ALLOWED(),
              confirmText: t.FORM.CONTINUE(),
              onConfirm: () => getMediaStream(),
              onCancel: () => selectRecordingMode(),
            }));
          } else if (error.name === 'NotAllowedError') {
            dispatch(openConfirmationDialog({
              title: t.FILE_UPLOAD.CANNOT_RECORD.TITLE(),
              bodyText: t.FILE_UPLOAD.CANNOT_RECORD.SCREEN_NOT_ALLOWED(),
              confirmText: t.FORM.CONTINUE(),
              onConfirm: () => getMediaStream(),
              onCancel: () => selectRecordingMode(),
            }));
          }
        } else if (error.name === 'NotFoundError') {
          dispatch(openConfirmationDialog({
            title: t.FILE_UPLOAD.CANNOT_RECORD.TITLE(),
            bodyText: t.FILE_UPLOAD.CANNOT_RECORD.VIDEO_NOT_FOUND(),
            confirmText: t.FORM.CONTINUE(),
            onConfirm: () => getMediaStream(),
            onCancel: () => selectRecordingMode(),
          }));
        } else if (error.name === 'NotAllowedError') {
          dispatch(openConfirmationDialog({
            title: t.FILE_UPLOAD.CANNOT_RECORD.TITLE(),
            bodyText: t.FILE_UPLOAD.CANNOT_RECORD.VIDEO_NOT_ALLOWED(),
            confirmText: t.FORM.CONTINUE(),
            onConfirm: () => getMediaStream(),
            onCancel: () => selectRecordingMode(),
          }));
        }
      });
    };

    getMediaStream();
  });

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => () => {
    stopMediaStreams(mediaStream);
  }, [mediaStream]);

  useImperativeHandle(ref, () => ({
    pause,
    resume,
    stopRecording,
  }));

  const isButtonDisabled = recordState !== 'running' || !recordCountdown;
  const isRetryDisabled = isButtonDisabled || (practiceType === VideoPracticeOption.ON_SPOT && take >= activity.maxTries);

  let countdown;
  switch (recordState) {
    case RecordState.NOT_STARTED:
    case RecordState.COUNTING_DOWN:
      countdown = timeToRecord;
      break;
    case RecordState.DONE:
      countdown = 0;
      break;
    default:
      countdown = recordCountdown;
      break;
  }

  useEffect(() => {
    if (countdown === 3 && mode === RecordMode.SCREEN) {
      playCountdownBeep();
    }
  }, [countdown]);

  const paused = recordState === RecordState.NOT_STARTED || recordState === RecordState.DONE || recordState === RecordState.PAUSED;

  return (
    <div css={styles}>
      {showPrompt ? (
        <div className='d-flex flex-column align-items-center'>
          <MemoizedPrompt
            scenario={scenario}
            onlyVideo={scenario.videoPrompt && !scenario.textPrompt}
            increaseFontSize={() => setPromptFontSize(size => size + 2)}
            decreaseFontSize={() => setPromptFontSize(size => size - 2)}
          />
          <div className='hr-divider' />
          <Button
            className='prompt-button mb-4'
            onClick={() => setShowPrompt(false)}
          >
            {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORD.HIDE_PROMPT()}
          </Button>
          <div className='details'>
            <VideoPracticeTitle
              practiceType={practiceType}
              activityTitle={scenario.title}
              activity={activity}
              take={take}
            />
            <CountdownTimer countdownRemainingSeconds={countdown} paused={paused} />
          </div>
        </div>
      ) : (
        <Fragment>
          <PracticeActivityProgress modalStepIndex={modalStepIndex} />
          <div className='course-title-small text-white'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORD.TITLE(timeToRecordRounded)}</div>
          <Button
            className='prompt-button'
            onClick={() => setShowPrompt(true)}
          >
            {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORD.SHOW_PROMPT()}
          </Button>
          <div className='details'>
            <VideoPracticeTitle
              practiceType={practiceType}
              activityTitle={scenario.title}
              activity={activity}
              take={take}
            />
            <CountdownTimer countdownRemainingSeconds={countdown} paused={paused} />
          </div>
        </Fragment>
      )}
      <div className='record-activity d-flex justify-content-center'>
        {mediaStream && (
          <React.Fragment>
            {mode === RecordMode.SCREEN ? (
              <React.Fragment>
                {(recordState === RecordState.NOT_STARTED || preRecordCountdown) ? (
                  <div className='video-stream-mask' />
                ) : ''}
                <div className='d-flex align-items-center text-white page-title'>
                  {preRecordCountdown ? (
                    <React.Fragment>
                      <span className={`screen-recording-desc starts-in ${isHandheld ? 'text-regular mb-6' : 'page-title'} text-center`}>
                        {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORD.STARTS_IN.SCREEN()}
                      </span>
                      <div className='record-countdown'>
                        <div className='recording-countdown-border'>
                          <div className={`recording-countdown ${isHandheld ? 'text-regular' : 'course-title-regular'}`}>
                            {preRecordCountdown}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : ''}
                  {recordState === RecordState.RUNNING ? (
                    <span className='screen-recording-desc text-center'>
                      {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORD.RUNNING()}
                    </span>
                  ) : ''}
                </div>
                { (practiceType === VideoPracticeOption.WHEN_READY || practiceType === PracticeAgain.PRACTICE_AGAIN)
                && !preRecordCountdown && !recordCountdown ? (
                  <div className='when-ready-desc d-flex align-items-center text-white page-title'>
                    <div className={`text-center ${handheld ? 'mb-6' : ''}`}>
                      {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORD.WHEN_READY()}
                    </div>
                    <div className='record-when-ready'>
                      <ClickableContainer onClick={startPreRecordCountdown} className='record-when-ready record-button-border'>
                        <div className='record-button' />
                      </ClickableContainer>
                    </div>
                  </div>
                  ) : ''}
                {!!recordCountdown && (
                  <RecordingIndicator />
                )}
                {recordCountdown && timeToRecord > 0 && (
                  <PercentageIndicator
                    timeLimit={recordCountdown && timeToRecord}
                    recordState={recordState}
                  />
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {scenario.recordingFormat === RecordingFormat.AUDIO
                  ? (
                    <Fragment>
                      <AudioStreamPreview mediaStream={mediaStream} isPlaying />
                      {recordState === RecordState.RUNNING && <RecordingIndicator />}
                    </Fragment>
                  ) : (
                    <VideoStreamPreview
                      recordState={recordState}
                      timeLimit={recordCountdown && timeToRecord}
                      mediaStream={mediaStream}
                      showRecordIcon={!!recordCountdown}
                      showAudioVisualizer
                      width={720}
                      previewPrimaryOnly
                    />
                  )}
                {!!preRecordCountdown && (
                  <div className='video-stream-mask d-flex justify-content-center'>
                    <div className='when-ready-desc d-flex align-items-center text-white page-title'>
                      <div className={`starts-in ${isHandheld ? 'text-regular mb-6' : 'page-title'} text-center`}>
                        {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORD.STARTS_IN.CAMERA()}
                      </div>
                      <div className='record-countdown'>
                        <div className='recording-countdown-border'>
                          <div className={`recording-countdown ${isHandheld ? 'text-regular' : 'course-title-regular'}`}>
                            {preRecordCountdown}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {(practiceType === VideoPracticeOption.WHEN_READY || practiceType === PracticeAgain.PRACTICE_AGAIN)
                  && !preRecordCountdown && !recordCountdown && (
                  <div className='video-stream-mask d-flex justify-content-center'>
                    <div className='when-ready-desc d-flex align-items-center text-white page-title'>
                      <div className={`when-ready ${isHandheld ? 'text-regular font-weight-bold mb-6' : 'page-title'}`}>
                        {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORD.WHEN_READY()}
                      </div>
                      <div className='record-when-ready'>
                        <ClickableContainer onClick={startPreRecordCountdown} className='record-button-border'>
                          <div className='record-button' />
                        </ClickableContainer>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
      <div className='button-container'>
        <NvPopover
          showOnHover
          enabled={!isRetryDisabled}
          placement='top'
          content={(
            <div>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RETRY.DETAILS()}</div>
          )}
        >
          <Button
            block={isHandheld}
            variant='secondary'
            disabled={isRetryDisabled}
            onClick={onRetry}
            pendo-tag-name={config.pendo.practice.retry}
            data-qa={`${config.pendo.practiceActivity.learner.retry}_${scenario.recordingFormat}`}
          >
            {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RETRY.TITLE()}
          </Button>
        </NvPopover>
        <Button
          block={isHandheld}
          onClick={() => stopRecording(true)}
          disabled={isButtonDisabled}
          pendo-tag-name={config.pendo.practice.endRecording}
          data-qa={`${config.pendo.practiceActivity.learner.endRecording}_${scenario.recordingFormat}`}
        >
          {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORD.END_RECORDING()}
        </Button>
      </div>
    </div>
  );
});

export default RecordPracticeActivity;
