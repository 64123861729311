

import { Controller } from 'react-hook-form';
import React, { ReactNode, ReactText } from 'react';

/**
 * Function to swap the position of two elements of an array; a new array is
 * returned so therefore, this doesn't mutate the array param.
 * @param array - Array that we want to swap the position of the elements from.
 * @param elementIndex - Index of the first element.
 */
const swapElementsPosition = <T extends unknown>(array: T[], elementIndex: number): T[] => {
  const arrayCopy = [...array];
  const temp = array[elementIndex];

  arrayCopy[elementIndex] = arrayCopy[elementIndex + 1];
  arrayCopy[elementIndex + 1] = temp;

  return arrayCopy;
};

export type RenderPropData<T> = {
  item: T,
  index: number,
  canMoveUp: boolean,
  moveUp: () => void,
  canMoveDown: boolean,
  moveDown: () => void,
};

type Props<T> = {
  items: T[],
  keyExtractor: (item: T) => ReactText,
  renderItem: (data: RenderPropData<T>) => ReactNode,
  onChange: (reorderedItems: T[], swapIndex: number) => void,
};

/**
 * This component is a helper component that renders a simple list of elements
 * but allows the implementer to rearrange each element through a render prop.
 */
const Reorderer = <T extends unknown>(props: Props<T>) => {
  const {
    items,
    onChange,
    renderItem,
    keyExtractor,
  } = props;

  const handleMoveUp = (indexToMove: number) => {
    if (indexToMove > 0) {
      const elementIndex = indexToMove - 1;

      onChange(swapElementsPosition(items, elementIndex), elementIndex);
    }
  };

  const handleMoveDown = (indexToMove: number) => {
    if (indexToMove < items.length - 1) {
      onChange(swapElementsPosition(items, indexToMove), indexToMove);
    }
  };

  return (
    <React.Fragment>
      {items.map((item: T, index: number, array: T[]) => {
        const canMoveUp = index > 0;
        const canMoveDown = index < array.length - 1;

        return (
          <React.Fragment key={keyExtractor(item)}>
            {
              renderItem({
                item,
                index,
                canMoveUp,
                canMoveDown,
                moveUp: () => handleMoveUp(index),
                moveDown: () => handleMoveDown(index),
              })
            }
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

type FormProps<T> = {
  name: string,
  defaultValue?: T[],
};

export const FormReorderer = <T extends unknown>(
  props: Omit<Props<T>, 'items' | 'onChange'> & FormProps<T>,
) => {
  const {
    name,
    defaultValue,
    ...restProps
  } = props;

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={(data) => {
        const { value, onChange } = data.field;

        return (
          <Reorderer<T>
            {...restProps}
            items={value}
            onChange={onChange}
          />
        );
      }}
    />
  );
};

export default Reorderer;
