import React, { useState } from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';
import {
  each, findKey, indexOf, isEmpty, pick, pluck, some, where, union, uniqueId,
  mapObject, uniq, lastIndexOf, sortBy,
} from 'underscore';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  CSVHeader, downloadCSVFromJson, ErrorType, parseCSV,
} from 'shared/csv-utils';
import { camelCase } from 'lodash';
import { getPlaceholder } from 'org_level_profile/utils';

// Schemas
import { useAppDispatch } from 'redux/store';
import { CombinedInstitution, RootState } from 'redux/schemas';
import { OrgProfileField } from 'redux/schemas/models/account-fields';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { AddMultipleUsersParams, BulkUploadErrorUser } from 'redux/schemas/api/org-level-roles';

// Actions
import { addMultipleUsers } from 'redux/actions/org-level-roles';
import { addAlertMessage } from 'redux/actions/alert-messages';

// Selectors
import { getCurrentInstitution } from 'redux/selectors/institutions';

// Components
import NvFilePicker from 'shared/components/nv-filepicker';
import { Button } from 'react-bootstrap';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import NvRadioButton from 'shared/components/inputs/nv-radio-button';

// Styles
import { doubleSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { config } from '../../../../config/pendo.config.json';

const styles = css`
  & {
    padding: 0 ${doubleSpacing}px;
  }
  .button-row {
    margin-top: ${tripleSpacing}px;
  }

  .row-no {
    width: 80px;
  }
  .column-no {
    width: 100px;
  }
`;

type OrgProfileBulkUploadModalProps = {
  closeModal: () => void
  reloadList: () => void
};

enum ExtraErrorType {
  EMPTY_SSO = 'empty_sso',
  EMPTY_EMAIL = 'empty_email',
  NOT_FOUND_USERS = 'not_found_users',
  SERVER_VALIDATION = 'server_validation',
}

const OrgProfileBulkUploadModal = (props: OrgProfileBulkUploadModalProps) => {
  const { closeModal, reloadList } = props;
  const [filterEmptyCells, setFilterEmptyCells] = useState(false);
  const [addNewUser, setAddNewUser] = useState(false);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [parseData, setParseData] = useState(null);
  const [submitResponse, setSubmitResponse] = useState(null);

  const dispatch = useAppDispatch();

  const currentInstitution = useSelector<RootState, CombinedInstitution>(getCurrentInstitution);
  const orgLevelFields = currentInstitution.profileSettings?.orgLevel || [];

  // Adding nullable with required fields to handle empty field value.
  const validation = {
    firstName: yup.string().nullable()
      .required(t.VALIDATION.REQUIRED())
      .max(50, t.VALIDATION.WITHIN_MIN_MAX('50', '1')),
    lastName: yup.string().nullable()
      .required(t.VALIDATION.REQUIRED())
      .max(50, t.VALIDATION.WITHIN_MIN_MAX('50', '1')),
    email: yup.string().nullable()
      .required(t.VALIDATION.REQUIRED())
      .email(t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.INVALID_EMAIL()),
    profilePhotoUrl: yup.string().nullable().url(t.VALIDATION.URL()),
    headline: yup.string().nullable(),
    bio: yup.string().nullable(),
  };

  const defaultHeaders: CSVHeader[] = [
    {
      key: 'firstName',
      headerName: 'First Name',
    },
    {
      key: 'lastName',
      headerName: 'Last Name',
    },
    {
      key: 'email',
      headerName: 'Email',
    },
    {
      key: 'profilePhotoUrl',
      headerName: 'Profile Image',
    },
    {
      key: 'headline',
      headerName: 'Headline',
    },
    {
      key: 'bio',
      headerName: 'Bio',
    },
  ];

  const templateHeaders: CSVHeader[] = [...defaultHeaders];
  const allHeaders: CSVHeader[] = [...defaultHeaders];
  const ssoColumns = [];
  const profileSettings = {};

  const getProfileHeaderName = (field) => {
    let { name } = field;
    const { id, migratedField } = field;

    if (!name && migratedField) {
      name = getPlaceholder(migratedField, false);
    }

    return `${name ? `${name} ` : ''}id${id}`;
  };

  if (orgLevelFields.length > 0) {
    each(orgLevelFields, (field: OrgProfileField) => {
      const headerName = getProfileHeaderName(field);
      const header = {
        key: field.id,
        headerName,
      };

      profileSettings[field.id] = field.name;
      allHeaders.push(header);

      if (field.isIntegrated) {
        // To validate the SSO columns only if they exist in the CSV.
        validation[field.id] = yup.string().when('$exist', {
          is: exist => exist,
          then: yup.string().required(),
          otherwise: yup.string(),
        });

        ssoColumns[field.id] = headerName;
      } else {
        templateHeaders.push(header);
      }
    });
  }

  const validationSchema = yup.object().shape(validation);

  const downloadTemplate = () => {
    downloadCSVFromJson({
      headers: templateHeaders,
      dummyRows: {
        totalRows: 2,
        values: {
          firstName: ['Jane', 'John'],
          lastName: ['Doe', 'Doe'],
          email: ['jane.doe@email.com', 'john.doe@email.com'],
        },
      },
    });
  };

  const parseCallback = (response) => {
    setParseData(response);

    if (response.errors.length > 0) {
      const invalidEmailErrors = where(response.errors, { method: 'email', columnKey: 'email' });
      const ssoErrorColumns = ssoColumns.filter(
        (field, key) => some(response.errors, (err) => +err?.columnKey === key),
      );

      if (invalidEmailErrors.length === response?.rawData?.length) {
        // If No valid emails were found show the no email error
        setErrors([{
          type: ExtraErrorType.EMPTY_EMAIL,
        }]);
      } else if (ssoErrorColumns.length > 0) {
        // If any SSO column have any error, show the SSO common error
        setErrors([{
          type: ExtraErrorType.EMPTY_SSO,
          columns: ssoErrorColumns,
        }]);
      } else {
        setErrors(response.errors);
      }

      setLoading(false);
    } else if (response.data.length > 0) {
      submit(response);
    }
  };

  const onCSVSelected = (files: File[]) => {
    setParseData(null);
    setErrors([]);
    const [file] = files;

    if (!file) {
      return;
    }
    setLoading(true);

    parseCSV({
      headers: allHeaders,
      file,
      validationSchema,
      extraValidationContext: { exist: false },
      callback: parseCallback,
    });
  };

  const onFileError = () => {
    setParseData(null);

    setErrors([{
      type: ErrorType.EMPTY_OR_INVALID_FILE,
      columns: [],
    }]);
  };

  // Submitting the CSV values.
  const submit = (parsedResponse) => {
    const { data: rows, fields = [], rawData } = parsedResponse;
    setLoading(true);

    const users = [];

    /**
     * Keeping the users as uniue based on the email.
     * The last occurrence of each email row is kept.
     */
    each(uniq(rows.reverse(), r => r.email), (row) => {
      /**
       * Selecting profile answers from a row values.
       * And converting all field values into the string.
       */
      const profileAnswers = mapObject(pick(row, (value, key) => (
        !['firstName', 'lastName', 'email'].includes(key)
      )), (answer) => (answer ? answer.toString() : null));

      users.push({
        firstName: row.firstName,
        lastName: row.lastName,
        email: row.email,
        profileAnswers,
        intro: {
          profilePhotoUrl: row.profilePhotoUrl,
          bio: row.bio,
          headline: row.headline,
        },
      });
    });

    const data: AddMultipleUsersParams = {
      institutionId: currentInstitution.id,
      users,
      filterEmptyCells,
      addNewUser,
      sendWelcomeEmail: addNewUser ? sendWelcomeEmail : false,
      profileSettings,
    };

    dispatch(addMultipleUsers(data))
      .then((response: any) => {
        reloadList();
        setErrors([]);
        const result = response.payload;
        const responseData = {
          newUsersAdded: result.newUsersAdded,
          existingUsersUpdated: result.existingUsersUpdated,
          errors: [],
        };

        if (result?.notFoundUsers?.length > 0) {
          const errorEmails = pluck(result.notFoundUsers, 'email');
          setErrors([{
            type: ExtraErrorType.NOT_FOUND_USERS,
            columns: errorEmails,
          }]);
        }

        setLoading(false);

        if (result.threshold) {
          dispatch(addAlertMessage({
            type: AlertMessageType.INFO,
            header: t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.THRESHOLD_ALERT.HEADER(),
            message: t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.THRESHOLD_ALERT.MESSAGE(),
          }));

          closeModal();
        } else if ((result.newUsersAdded > 0 || result.existingUsersUpdated > 0)
          && (result.notFoundUsers.length === 0
            && result.profilesNotUpdated.length === 0
            && result.profilesUpdatedPartially.length === 0)
        ) {
          dispatch(addAlertMessage({
            type: AlertMessageType.SUCCESS,
            header: t.FORM.SUCCESS_BANG(),
            message: getSuccessMessage(result.newUsersAdded, result.existingUsersUpdated),
          }));

          closeModal();
        } else {
          const serverErrors = [];
          const errorUsers = union(result.profilesUpdatedPartially || [], result.profilesNotUpdated || []);

          // Formatting the server errors based on the uploaded CSV file.
          if (errorUsers.length > 0) {
            const csvEmails = pluck(rawData, 'email');

            each(errorUsers, (user: BulkUploadErrorUser) => {
              each(user?.profileErrors || [], (error) => {
                const hasProfileSettingError = !some(defaultHeaders, (header) => header.key === camelCase(error.columnName));
                const columnKey = hasProfileSettingError
                  ? findKey(profileSettings, (field) => field === error.columnName)
                  : camelCase(error.columnName);

                serverErrors.push({
                  columnKey,
                  type: ExtraErrorType.SERVER_VALIDATION,
                  // Finding the row number based on the uploaded CSV emails list.
                  row: lastIndexOf(csvEmails, user.email) + 1,
                  column: indexOf(fields, hasProfileSettingError ? +columnKey : columnKey) + 1,
                  message: t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.ERROR[error.code.toUpperCase()](),
                });
              });
            });

            // Sorting the error list to show the row and column number in alphabetical order.
            responseData.errors = sortBy(serverErrors, ['row', 'column']);
          }
        }

        setSubmitResponse(responseData);
      });
  };

  const getUploadButtonText = () => {
    if (loading) {
      return t.FORM.UPLOADING_CSV();
    }

    return (errors?.length > 0 || !isEmpty(parseData?.data)) ? t.FORM.UPLOAD_NEW_CSV() : t.FORM.UPLOAD_CSV();
  };

  const getSuccessMessage = (addedCount, existingCount) => {
    let message = t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.SUCCESS_ALERT.ADDED_WITH_EXIST(
      addedCount,
      existingCount,
    );

    if (addedCount === 0) {
      message = t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.SUCCESS_ALERT.ONLY_EXIST(existingCount);
    } else if (existingCount === 0) {
      message = t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.SUCCESS_ALERT.ONLY_ADDED(addedCount);
    }

    return message;
  };

  const hasSubmitResponseError = () => (!isEmpty(submitResponse?.errors)
      || (errors?.length > 0 && errors[0].type === ExtraErrorType.NOT_FOUND_USERS));

  return (
    <div className='d-flex flex-column mx-auto' css={styles}>
      {errors.length > 0 || !isEmpty(submitResponse?.errors) ? (
        <React.Fragment>
          {(submitResponse?.newUsersAdded > 0 || submitResponse?.existingUsersUpdated > 0) && (
            <p className='mb-4'>
              <b>{t.FORM.SUCCESS_BANG()} </b>
              {getSuccessMessage(submitResponse.newUsersAdded, submitResponse.existingUsersUpdated)}
            </p>
          )}
          {!isEmpty(submitResponse?.errors) && (
            <React.Fragment>
              <ErrorsList
                className='mb-2'
                errors={submitResponse.errors}
                hasValidRows
              />
            </React.Fragment>
          )}
          {errors.length > 0 && (
            <ErrorsList
              errors={errors}
              hasValidRows={!isEmpty(parseData?.data)}
            />
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className='text-body'>
            <p>{t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.DESCRIPTION_1()}</p>
            {currentInstitution.ssoLogin && !currentInstitution.enableBasicSsoInfoEdit && (
              <p className='mt-3'>
                {t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.DESCRIPTION_2()}
              </p>
            )}
          </div>
          <NvCheckbox
            className='mt-2'
            name='filterEmptyCells'
            label={t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.REMOVE_BLANK_CELL()}
            labelClassName='text-regular'
            checked={filterEmptyCells}
            onChange={(event) => setFilterEmptyCells(!!event.target.checked)}
          />
          <NvCheckbox
            className='mt-3'
            name='addNewUser'
            label={t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.ADD_NEW_USER()}
            labelClassName='text-regular'
            checked={addNewUser}
            onChange={(event) => setAddNewUser(!!event.target.checked)}
          />
          {addNewUser && (
            <div className='ml-6'>
              <NvRadioButton
                value='true'
                name='send-welcome-email'
                label={t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.SEND_WELCOME_EMAIL()}
                labelClassName='text-regular'
                checked={sendWelcomeEmail}
                onChange={(event) => setSendWelcomeEmail(!!event.target.value)}
              />
              <NvRadioButton
                value=''
                className='mt-0'
                checked={!sendWelcomeEmail}
                name='not-send-welcome-email'
                label={t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.NOT_SEND_WELCOME_EMAIL()}
                labelClassName='text-regular'
                onChange={(event) => setSendWelcomeEmail(!!event.target.value)}
              />
            </div>
          )}
        </React.Fragment>
      )}
      <div className='button-row d-flex mx-auto mb-4'>
        { hasSubmitResponseError() ? (
          <Button onClick={closeModal}>
            {t.FORM.OK()}
          </Button>
        ) : (
          <React.Fragment>
            {isEmpty(parseData?.data) && (
              <Button
                variant='secondary'
                className='font-weight-bold'
                onClick={downloadTemplate}
              >
                {t.FORM.DOWNLOAD_TEMPLATE()}
              </Button>
            )}
            <NvFilePicker
              multiple={false}
              accept={['.csv', 'text/csv']}
              onChange={(e) => onCSVSelected(e)}
              onSelectError={onFileError}
            >
              <Button
                className='ml-2'
                disabled={loading}
                variant={(!isEmpty(parseData?.data) && !loading) ? 'secondary' : 'primary'}
                pendo-tag-name={config.pendo.userManagement.bulkUploadCsvOrgProfile}
              >
                {getUploadButtonText()}
              </Button>
            </NvFilePicker>
            {!isEmpty(parseData?.data) && (
              <Button
                className='ml-2 font-weight-bold'
                onClick={() => submit(parseData)}
                disabled={loading}
              >
                {loading ? t.FORM.PROCEEDING_WITH_REST() : t.FORM.PROCEED_WITH_REST()}
              </Button>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};


const ErrorsList = (props) => {
  const { errors = [], hasValidRows = false, className = '' } = props;

  const getErrorText = (error) => {
    switch (error.type) {
      case ErrorType.EMPTY_OR_INVALID_FILE:
        return t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.ERROR.EMPTY_OR_INVALID_FILE();
      case ErrorType.REQUIRED_COLUMNS_MISSING:
        return t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.ERROR.REQUIRED_COLUMNS_MISSING();
      case ErrorType.INVALID_COLUMN:
        return t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.ERROR.INVALID_COLUMN();
      case ErrorType.DUPLICATE_COLUMNS:
        return t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.ERROR.DUPLICATE_COLUMNS();
      case ExtraErrorType.EMPTY_EMAIL:
        return t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.ERROR.EMPTY_EMAIL();
      case ExtraErrorType.EMPTY_SSO:
        return t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.ERROR.EMPTY_SSO();
      case ErrorType.VALIDATION:
      case ExtraErrorType.SERVER_VALIDATION:
        return hasValidRows
          ? t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.ERROR.VALIDATION_WITH_DATA()
          : t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.ERROR.VALIDATION_WITHOUT_DATA();
      case ExtraErrorType.NOT_FOUND_USERS:
        return t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.ERROR.NOT_FOUND_USERS(error.columns.length);

      default:
        return null;
    }
  };

  const getErrorDescription = (error) => {
    switch (error.type) {
      case ErrorType.EMPTY_OR_INVALID_FILE:
      case ErrorType.REQUIRED_COLUMNS_MISSING:
      case ErrorType.INVALID_COLUMN:
      case ErrorType.DUPLICATE_COLUMNS:
      case ExtraErrorType.EMPTY_SSO:
        return t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.DESCRIPTION.INVALID_FILE();
      case ExtraErrorType.EMPTY_EMAIL:
        return t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.DESCRIPTION.EMPTY_EMAIL();
      case ErrorType.VALIDATION:
        return !hasValidRows
          ? t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.DESCRIPTION.INVALID_FILE() : '';
      case ExtraErrorType.NOT_FOUND_USERS:
        return t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.DESCRIPTION.NOT_FOUND_USERS();
      default:
        return null;
    }
  };

  const hasErrorTextdanger = (
    !hasValidRows
      || [ExtraErrorType.SERVER_VALIDATION, ExtraErrorType.NOT_FOUND_USERS].includes(errors[0].type)
  );

  return (
    <div className={className}>
      <p className={`mb-4 ${hasErrorTextdanger ? 'text-danger' : ''} ${errors[0].type === ErrorType.VALIDATION && !hasValidRows ? 'text-nowrap' : ''}`}>
        {getErrorText(errors[0])}
      </p>
      { errors[0].columns?.length > 0 && (
        <div className='mb-3'>
          {errors[0].columns.map((col) => (
            <p className='border-gray-5 border-bottom pb-2' key={uniqueId('col')}>
              {errors[0].type === ErrorType.DUPLICATE_COLUMNS
                ? t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.COLUMN_NAME(col)
                : col}
            </p>
          ))}
        </div>
      )}
      { [ErrorType.VALIDATION, ExtraErrorType.SERVER_VALIDATION].includes(errors[0].type) && (
        <table className={`w-100 ${!hasValidRows ? 'mb-4' : ''}`}>
          <tbody>
            {errors.map((errCol, i) => (
              <tr
                className='font-weight-normal border-bottom border-gray-5'
                key={`${errCol.row}-${errCol.column}-${errCol.columnKey}`}
              >
                <td className='py-2 row-no'>{t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.ROW(errCol.row)}</td>
                <td className='py-2 column-no'>
                  {t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.COLUMN(errCol.column)}
                </td>
                <td className='py-2 text-danger'>{errCol.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <p className='text-body'>
        {getErrorDescription(errors[0])}
      </p>
    </div>
  );
};

export default OrgProfileBulkUploadModal;
