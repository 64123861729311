/* @ngInject */
export default function OrgLevelProfileContentHeaderController(
  InstitutionsManager,
  OrgLevelProfileManager,
) {
  const vm = this;

  vm.OrgLevelProfileManager = OrgLevelProfileManager;

  vm.headerContainerStyles = {
    backgroundColor: InstitutionsManager.institution.brandColor,
  };

  vm.headerTextStyles = {
    color: InstitutionsManager.institution.brandBarFontColor,
  };
}
