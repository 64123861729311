import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import t from 'react-translate';

import { useSelector } from 'react-redux';

import { AngularContext, AngularServicesContext } from 'react-app';
import { getCollectionLecturePage } from 'redux/actions/collections';
import { useAppDispatch } from 'redux/store';
import { getCurrentCourse } from 'redux/selectors/course';
import { getLecturePage, resetDestinationLecturePage, setLecturePageAsCached } from 'redux/actions/lecture-pages';

import NvModal, { ModalType } from 'shared/components/nv-modal';
import { gray6 } from 'styles/global_defaults/colors';
import { standardModalWidth } from 'styles/global_defaults/scaffolding';
import { getCurrentUser } from 'redux/selectors/users';
import { translateLecturePage } from 'redux/actions/users';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { css } from '@emotion/react';
import { LecturePageUrlParams } from './lecture-page-routes';

import { LecturePageMode } from '.';
import { LecturePagePreviewContent } from './lecture-page-preview-content';

export const LecturePagePreviewContext = React.createContext(null);

export enum PreviewSource {
  COPY = 'copy',
  MOVE = 'move',
}


const Content = () => {
  const { $state } = useContext(AngularServicesContext);
  const { lecturePageId } = useContext(LecturePagePreviewContext);

  const dispatch = useAppDispatch();

  const lecturePage = useSelector(state => state.models.lecturePages[lecturePageId]);
  const currentUser = useSelector(getCurrentUser);
  const { automatedTranslationEnabled: courseTranslationEnabled, isContentManagementCollection, id: courseId } = useSelector(getCurrentCourse);
  const { cachedLecturePageLookup } = useSelector(state => state.app.lecturePage);
  const { translationPreferenceLanguage } = currentUser;
  const {
    automatedTranslationEnabled: institutionTranslationEnabled,
  } = useSelector(getCurrentInstitution);
  const catalogId = useSelector((state) => state.app.currentCatalogId);

  const params: LecturePageUrlParams = useMemo(() => ({
    catalogId,
    lecturePageId,
    mode: LecturePageMode.VIEW,
  }), [catalogId, lecturePageId]);

  const { injectServices } = useContext(AngularContext);

  const [
    CurrentUserManager,
  ] = injectServices([
    'CurrentUserManager',
  ]);

  const fetchLecturePage = useCallback(() => dispatch(isContentManagementCollection
    ? getCollectionLecturePage({ lecturePageId })
    : getLecturePage(params)),
  [dispatch, isContentManagementCollection, lecturePageId, params]);

  useEffect(() => {
    // Avoid re-fetching if cached
    const pageCached = cachedLecturePageLookup[lecturePageId];
    if (!pageCached) {
      let beforePromise = null;

      if (
        translationPreferenceLanguage
        && institutionTranslationEnabled
        && courseTranslationEnabled
      ) {
        beforePromise = dispatch(translateLecturePage({
          lecturePageId,
          catalogId,
          language: translationPreferenceLanguage,
        })).then(() => {
          CurrentUserManager.user.updateFromReact({
            translationPreferenceLanguage,
            lastTranslationPreferenceLanguage: translationPreferenceLanguage,
          });
        });
      }

      const requestLecturePage = () => fetchLecturePage().then(resultAction => {
        // Navigate to the home page if the page can't be loaded.
        if (getLecturePage.rejected.match(resultAction)) {
          $state.go('course-home', { catalogId });
          return;
        }
        dispatch(setLecturePageAsCached(lecturePageId));
      });

      if (beforePromise) {
        beforePromise.then(requestLecturePage);
      } else {
        requestLecturePage();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    catalogId,
    lecturePageId,
    translationPreferenceLanguage,
    institutionTranslationEnabled,
    courseTranslationEnabled,
    lecturePage?.isLinked,
  ]);

  return (
    <div>
      <LecturePagePreviewContent />
    </div>
  );
};
const modalStyles = css`
    .bs4-modal-body {
      padding: 0;
      .main-panel-scrollable {
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
`;

const LecturePagePreviewModal = (props: {
  show: boolean,
  onClose: () => void,
}) => {
  const [showModal, setShowModal] = useState(props.show);
  const action = useSelector(state => state.app.destinationLecturePage?.action);

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  return (
    <NvModal
      type={ModalType.DYNAMIC}
      width={standardModalWidth}
      header={action && t.LECTURE_PAGES.COMPONENTS.PICK_LOCATION_TITLE[action.toUpperCase()]()}
      headerBgColor={gray6}
      body={<Content />}
      show={showModal}
      onClose={props.onClose}
      modalStyles={modalStyles}
    />
  );
};

export default LecturePagePreviewModal;
