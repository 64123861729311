import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Result } from 'redux/schemas/api';
import { FetchCourseRolesParams, FetchRolesParams } from 'redux/schemas/api/role';
import { CourseCloneRoles, Role, RolesNormalized } from 'redux/schemas/models/role';

/**
 * load existing course into redux from angularjs side
 */
export const loadRoles = createAction<RolesNormalized>('LOAD_ROLES');


async function fetchCourseRoles({courseId}: FetchCourseRolesParams) {
  const response = await axios.get<Result<CourseCloneRoles>>(`courses/${courseId}/clone_overview.json`);
  return { response: response?.data?.result?.courseClone };
}

async function fetchRole(params: FetchRolesParams) {
  const response = await axios.get<Result<Role[]>>(`/course_roles.json?institution_id=${params.institutionId}.json`);
  return response.data.result;
}

export const getCourseRolesCount = createAsyncThunk(
  'FETCH_COURSE_ROLES',
  fetchCourseRoles,
);

export const getRoles = createAsyncThunk(
  'FETCH_ROLES',
  fetchRole,
);
