/* @ngInject */
export default function DashboardManager(
  _,

  CurrentUserManager,
  InstitutionsManager,

  DashboardModel,
  ReportModel,
) {
  let lastApprovalSubmissionsRequest;
  const manager = {
    getTodos,
    initialize,

    currentLearning: null,
    approvalSubmissionsCount: 0,
    approvalSubmissions: [],
    todos: null,
    activeDrafts: null,
    currentCourses: null,
    certificates: null,
    submissions: null,
    completedCourses: null,

    getApprovalSubmissions,
    getDiscoveryCourses,
  };

  function initialize() {
    getCurrentLearning();
    getApprovalSubmissions();
    getTodos();
    getActiveDrafts();
    if (InstitutionsManager.institution.discoveryEnabled) {
      getDiscoveryCourses();
    }
    getCurrentCourses();
    getSubmissions();
    getCompletedCourses();
    getCertificates();
  }

  function getCurrentLearning() {
    return DashboardModel.getCurrentLearning().then((resource) => {
      manager.currentLearning = resource;

      _.each(manager.currentLearning, (learning) => {
        if (learning.isLecture()) {
          learning.course = CurrentUserManager.coursesHashByCatalogId[learning.course.catalogId];
        } else {
          // it is a course
          _.extend(learning, CurrentUserManager.coursesHashByCatalogId[learning.catalogId]);
        }
      });
    });
  }

  function getApprovalSubmissions() {
    manager.approvalSubmissionsCount = 0;
    manager.approvalSubmissions = [];

    if (lastApprovalSubmissionsRequest) {
      lastApprovalSubmissionsRequest.$cancelRequest();
    }

    lastApprovalSubmissionsRequest = DashboardModel.getApprovalSubmissions();
    lastApprovalSubmissionsRequest.$promise.then((response) => {
      manager.approvalSubmissionsCount = response.count;
      manager.approvalSubmissions = response.reports;

      _.each(manager.approvalSubmissions, ReportModel.normalizeSubmissionData);

      if (manager.approvalSubmissions.length < manager.approvalSubmissionsCount) {
        const carouselAddition = {
          isPlaceholder: true,
          submissionApprovalDashboard: true,
        };

        manager.approvalSubmissions.push(carouselAddition);
      }
    });
  }

  function getTodos() {
    return DashboardModel.getTodos().then((resource) => {
      manager.todos = resource;

      _.each(manager.todos, (todo) => {
        todo.course = CurrentUserManager.coursesHashByCatalogId[todo.course.catalogId];
      });
    });
  }


  function getDiscoveryCourses() {
    return DashboardModel.getDiscoveryCourses().then((resource) => {
      manager.discoveryCourses = resource;

      _.each(manager.discoveryCourses, (course) => {
        course.institution = InstitutionsManager.institution;
      });

      return manager.discoveryCourses;
    });
  }

  function getCurrentCourses() {
    return DashboardModel.getCurrentCourses().then((resource) => {
      manager.currentCourses = resource;

      _.each(manager.currentCourses, (currentCourse) => {
        _.extend(currentCourse, CurrentUserManager.coursesHashByCatalogId[currentCourse.catalogId]);
      });
    });
  }

  function getCompletedCourses() {
    return DashboardModel.getCompletedCourses().then((resource) => {
      manager.completedCourses = resource;
    });
  }

  function getCertificates() {
    return DashboardModel.getCertificates().then((resource) => {
      manager.certificates = resource;
    });
  }

  function getSubmissions() {
    return DashboardModel.getSubmissions().then((resource) => {
      manager.submissions = resource;

      _.each(manager.submissions, ReportModel.normalizeSubmissionData);
    });
  }

  function getActiveDrafts() {
    return DashboardModel.getActiveDrafts().then((resource) => {
      manager.activeDrafts = resource;
    });
  }

  return manager;
}
