import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import humps from 'humps';

import { useAppDispatch } from 'redux/store';
import { addComment, editComment, getCommentsForSubmission, removeComment, voteComment } from 'redux/actions/comments';
import { getMentionableUsers, setSubmissionVoteCount } from 'redux/actions/submissions';
import { ExerciseSubmissionState, Submission } from 'redux/schemas/models/exercise';
import { RatingActivityExercise } from 'redux/schemas/models/exercise-skills-rating';

import CommentsContainer from 'shared/components/comment/comments-container';
import PusherService from 'shared/services/pusher-service';
import { addReply, editReply, removeReply, voteReply } from 'redux/actions/replies';
import { CommentEvent, ReplyEvent } from 'redux/schemas/models/submissions';

type CommentsTabProps = {
  reportId: number,
};

export enum SubmissionEventsMap {
  NEW_COMMENT = 'new_post',
  DELETE_COMMENT = 'delete_post',
  UPDATE_COMMENT = 'update_post',
  LIKE_COMMENT = 'post_vote',

  NEW_REPLY = 'new_comment',
  DELETE_REPLY = 'delete_comment',
  UPDATE_REPLY = 'update_comment',
  LIKE_REPLY = 'comment_vote',

  LIKE_SUBMISSION = 'submission_vote',
}

const CommentsTab = ({ reportId }: CommentsTabProps) => {
  const styles = css`
    margin: auto;
    max-width: 800px;
  `;

  const dispatch = useAppDispatch();

  const [mentionableUsers, setMentionableUsers] = useState([]);
  const submission: Submission = useSelector(state => state.models.submissions?.[reportId]);
  const exercise = submission?.exercise as RatingActivityExercise;
  const appSubmissionState = (useSelector(
    state => state.app.exerciseSubmissions,
  ) as ExerciseSubmissionState)?.[reportId];

  const ownerId = submission?.id;
  const catalogId = exercise?.catalogId;

  useEffect(() => {
    if (ownerId && catalogId) {
      if (!appSubmissionState?.commentsFetched) {
        dispatch(getCommentsForSubmission({
          catalogId,
          reportId: ownerId,
          pageSize: 50,
        }));
      }

      dispatch(getMentionableUsers({
        catalogId,
        reportId: ownerId,
      })).then((response) => {
        setMentionableUsers(response.payload);
      });
    }
  }, [dispatch, ownerId, catalogId, appSubmissionState?.commentsFetched]);

  useEffect(() => {
    const pusherChannel = PusherService.initializeDiscussionsChannelForReport(reportId);

    pusherChannel.bind(SubmissionEventsMap.NEW_COMMENT, (data) => {
      const camelizedData = humps.camelizeKeys(data) as unknown as CommentEvent;

      if (camelizedData.reportId) {
        dispatch(addComment({
          commentId: camelizedData.postId,
          postId: null,
          userId: null,
          catalogId,
          ownerId: camelizedData.reportId,
          ownerType: 'report',
          postsCount: camelizedData.postsCount,
        }));
      }
    });

    pusherChannel.bind(SubmissionEventsMap.DELETE_COMMENT, (data) => {
      const camelizedData = humps.camelizeKeys(data) as unknown as CommentEvent;

      if (camelizedData.reportId) {
        dispatch(removeComment({
          catalogId,
          commentId: camelizedData.postId,
          postId: null,
          userId: null,
          ownerId: camelizedData.reportId,
          ownerType: 'report',
          postsCount: camelizedData.postsCount,
        }));
      }
    });

    pusherChannel.bind(SubmissionEventsMap.UPDATE_COMMENT, (data) => {
      const camelizedData = humps.camelizeKeys(data) as unknown as CommentEvent;

      if (camelizedData.reportId) {
        dispatch(editComment({
          commentId: camelizedData.postId,
          catalogId,
        }));
      }
    });

    pusherChannel.bind(SubmissionEventsMap.LIKE_COMMENT, (data) => {
      const camelizedData = humps.camelizeKeys(data) as unknown as CommentEvent;

      if (camelizedData.reportId) {
        dispatch(voteComment({
          commentId: camelizedData.postId,
          numLikes: camelizedData.numLikes,
          catalogId,
        }));
      }
    });

    pusherChannel.bind(SubmissionEventsMap.NEW_REPLY, (data) => {
      const camelizedData = humps.camelizeKeys(data) as unknown as ReplyEvent;

      if (camelizedData.reportId) {
        dispatch(addReply({
          replyId: camelizedData.commentId,
          commentId: camelizedData.postId,
          userId: null,
          catalogId,
          reportId: camelizedData.reportId,
        }));
      }
    });

    pusherChannel.bind(SubmissionEventsMap.DELETE_REPLY, (data) => {
      const camelizedData = humps.camelizeKeys(data) as unknown as ReplyEvent;

      if (camelizedData.reportId) {
        dispatch(removeReply({
          replyId: camelizedData.commentId,
          commentId: camelizedData.postId,
          userId: null,
          catalogId,
          reportId: camelizedData.reportId,
        }));
      }
    });

    pusherChannel.bind(SubmissionEventsMap.UPDATE_REPLY, (data) => {
      const camelizedData = humps.camelizeKeys(data) as unknown as ReplyEvent;

      if (camelizedData.reportId) {
        dispatch(editReply({
          replyId: camelizedData.commentId,
          catalogId,
        }));
      }
    });

    pusherChannel.bind(SubmissionEventsMap.LIKE_REPLY, (data) => {
      const camelizedData = humps.camelizeKeys(data) as unknown as ReplyEvent;

      if (camelizedData.reportId) {
        dispatch(voteReply({
          replyId: camelizedData.commentId,
          numLikes: camelizedData.numLikes,
          catalogId,
        }));
      }
    });

    pusherChannel.bind(SubmissionEventsMap.LIKE_SUBMISSION, (data) => {
      const camelizedData = humps.camelizeKeys(data) as unknown as ReplyEvent;

      dispatch(setSubmissionVoteCount({
        submissionId: camelizedData.reportId,
        numLikes: camelizedData.numLikes,
      }));
    });

    return () => {
      pusherChannel.unbind(SubmissionEventsMap.NEW_COMMENT);
      pusherChannel.unbind(SubmissionEventsMap.DELETE_COMMENT);
      pusherChannel.unbind(SubmissionEventsMap.UPDATE_COMMENT);
      pusherChannel.unbind(SubmissionEventsMap.LIKE_COMMENT);
      pusherChannel.unbind(SubmissionEventsMap.NEW_REPLY);
      pusherChannel.unbind(SubmissionEventsMap.DELETE_REPLY);
      pusherChannel.unbind(SubmissionEventsMap.UPDATE_REPLY);
      pusherChannel.unbind(SubmissionEventsMap.LIKE_REPLY);
      pusherChannel.unbind(SubmissionEventsMap.LIKE_SUBMISSION);
    };
  }, [catalogId, dispatch, reportId, submission]);

  if (!submission) {
    return (<React.Fragment />);
  }

  return (
    <div css={styles}>
      <CommentsContainer
        catalogId={catalogId}
        commentIds={submission.commentIds}
        ownerType='report'
        ownerId={ownerId}
        mentionableUsers={mentionableUsers}
        appSubmissionState={appSubmissionState}
      />
    </div>
  );
};

export default CommentsTab;
