/* @ngInject */
export default function QuizModelService(

  QuizzesResources,
  LikertScaleQuestion,
  MultipleChoiceQuestion,
  MultipleChoiceMultipleAnswerQuestion,
  NumberQuestion,
  ReadOnlyQuestion,
  RichTextQuestion,
  ShortTextQuestion,
  TableNumberQuestion,
  TableShortTextQuestion,
  $q,
  moment,
  _,
) {
  const questionTypes = {
    LikertScaleQuestion,
    MultipleChoiceQuestion,
    MultipleChoiceMultipleAnswerQuestion,
    NumberQuestion,
    ReadOnlyQuestion,
    RichTextQuestion,
    ShortTextQuestion,
    TableNumberQuestion,
    TableShortTextQuestion,
  };

  const QuizModel = function (attributes) {
    const _this = this;

    _.extend(_this, attributes);

    preprocess();

    /** Public Data * */
    _this.currentQuestion = null;
    _this.reviewQuestion = null;

    /** Public Functions * */
    _this.unansweredCount = unansweredCount;
    _this.persist = persist;
    _this.persistTimedQuiz = persistTimedQuiz;
    _this.setResponses = setResponses;
    _this.getDropdownQuestions = getDropdownQuestions;
    _this.getNextQuestionToDisplay = getNextQuestionToDisplay;
    _this.setCurrentQuestion = setCurrentQuestion;
    _this.resetCurrentQuestion = resetCurrentQuestion;
    _this.gradeQuestion = gradeQuestion;
    _this.resetResponses = resetResponses;
    _this.calculateTimeRemaining = calculateTimeRemaining;
    _this.minCorrectForThreshold = minCorrectForThreshold;
    _this.preprocess = preprocess;

    /** Private Functions * */
    function preprocess() {
      let displayIndexCount = 1;
      _this.questions = _.map(_this.questions, (question) => {
        if (questionTypes[question.type]) {
          return new questionTypes[question.type](question);
        }
        return null;
      });

      // question display index
      _.each(_this.questions, (question) => {
        if (question.type !== 'ReadOnlyQuestion') {
          question.displayIndex = displayIndexCount;
          displayIndexCount += 1;
        }
      });

      // For lecture quiz
      _this.unsubmittedSubmission = _this.unsubmittedSubmission || {};
      _this.submission = _this.submission || {};
    }

    function unansweredCount() {
      return _.filter(_this.questions, (question) => question.isUnAnswered()).length;
    }

    function persist(questionSetSubmissionId, isSave) {
      const payload = formQuizPayload(isSave);

      if (isSave) {
        _this.saving = true;
      } else {
        _this.submitting = true;
      }

      if (questionSetSubmissionId) {
        return QuizzesResources.updateQss({ catalogId: _this.catalogId, questionSetSubmissionId }, payload).$promise
          .then((response) => {
            resetStatus();
            return response.result;
          }).catch(
            (error) => {
              resetStatus();
              return $q.reject(error);
            },
          );
      }
      return QuizzesResources.createQss({ catalogId: _this.catalogId, questionSetId: _this.id }, payload).$promise
        .then((resource) => {
          resetStatus();
          return resource.result;
        }).catch(
          (error) => {
            resetStatus();
            return $q.reject(error);
          },
        );
    }

    function persistTimedQuiz(questionSetSubmissionId, windowId, isSave) {
      const payload = formQuizPayload(isSave);
      payload.questionSetId = _this.questionSetId;
      payload.windowId = windowId;
      payload.userLocalTimestamp = Date.now();

      return QuizzesResources.updateTimedQss({ catalogId: _this.catalogId, questionSetSubmissionId }, payload).$promise
        .then((response) => {
          resetStatus();
          return response.result;
        }).catch(
          (error) => {
            resetStatus();
            return $q.reject(error);
          },
        );
    }

    function resetStatus() {
      _this.saved = false;
      _this.saving = false;
      _this.submitting = false;
    }

    function formQuizPayload(isSave) {
      const payload = {
        exerciseId: _this.exerciseId,
        questionSetId: _this.id,
        userResponse: {
        },
        optionFields: {
        },
        saveSubmission: isSave,
      };

      const editableQuestions = _.filter(_this.questions, (question) => question.type !== 'ReadOnlyQuestion');

      _.each(editableQuestions, (question, index) => {
        if (question.type === 'MultipleChoiceQuestion' || question.type === 'MultipleChoiceMultipleAnswerQuestion') {
          payload.optionFields[index] = question.otherFieldResponsePayload();
        }

        payload.userResponse[index] = question.responsePayload();
      });

      return payload;
    }

    function setResponses(responses) {
      _.each(responses, (response) => {
        const question = _.findWhere(_this.questions, { id: response.questionId });
        question.setResponse(response);
      });
    }

    function resetResponses(responses) {
      _.each(responses, (response) => {
        const question = _.findWhere(_this.questions, { id: response.questionId });
        question.resetResponse();
      });
    }

    function calculateTimeRemaining(startedExamAt, timeLimit, timeInterval, expirationDate) {
      const currentMoment = moment();
      const deadlineMoment = expirationDate ? moment(expirationDate) : null;
      const startedExamAtMoment = startedExamAt ? moment(startedExamAt) : moment();
      const timeLimitMoment = startedExamAtMoment.add(timeLimit, timeInterval === 'Hours' ? 'h' : 'm');

      const effectiveDeadlineMoment = (!deadlineMoment || deadlineMoment.isAfter(timeLimitMoment)) ? timeLimitMoment : deadlineMoment;
      return effectiveDeadlineMoment.diff(currentMoment, 'seconds');
    }

    /* Video Quiz */
    function getDropdownQuestions() {
      // get all answered questions
      const questions = _.filter(_this.questions, (question) => {
        if (!question.currentResponse) {
          return false;
        } if (question.currentResponse.isCorrect) {
          return true;
        } if (question.currentResponse.numberOfAttempts < _this.maximumAttempts) {
          return !_this.graded;
        }
        return true;
      });

      const firstUnansweredQuestion = getFirstUnansweredQuestion();

      if (firstUnansweredQuestion) {
        questions.push(firstUnansweredQuestion);
      }

      return questions;
    }

    function getFirstUnansweredQuestion() {
      return _.find(_this.questions, (question) => {
        if (!question.currentResponse) {
          return true;
        } if (question.currentResponse.isCorrect) {
          return false;
        } if (question.currentResponse.numberOfAttempts < _this.maximumAttempts) {
          return _this.graded;
        }
        return false;
      });
    }

    function getNextQuestionToDisplay() {
      return _.find(_this.questions, (question) => {
        if (question === _this.reviewQuestion) {
          return true;
        } if (!question.currentResponse) {
          return true;
        } if (question.currentResponse.isCorrect) {
          return false;
        } if (question.currentResponse.numberOfAttempts < _this.maximumAttempts) {
          return _this.graded;
        }
        return false;
      });
    }

    function setCurrentQuestion(question) {
      _this.currentQuestion = question;
    }

    function resetCurrentQuestion() {
      _this.currentQuestion = null;
    }

    function gradeQuestion() {
      const payload = formVideoQuizPayload();
      _this.submitting = true;

      return QuizzesResources.gradeQuestion({ catalogId: _this.catalogId, videoListId: _this.videoListId, lectureVideoId: _this.lectureVideoId }, payload).$promise
        .then((response) => {
          // process response
          const question = _.findWhere(response.result.questions, { id: _this.currentQuestion.id });
          const questionResponse = _.findWhere(response.result.responses, { questionId: _this.currentQuestion.id });
          _this.currentQuestion.updateResponseOptions(question);
          _this.currentQuestion.setResponse(questionResponse);

          resetStatus();
          return response.result;
        }).catch(
          (error) => {
            resetStatus();
            return $q.reject(error);
          },
        );
    }

    function formVideoQuizPayload() {
      const payload = {
        questionId: _this.currentQuestion.id,
        userResponse: _this.currentQuestion.responsePayload(),
      };

      return payload;
    }

    function minCorrectForThreshold() {
      return Math.ceil(_this.pointsConfiguration.threshold * _this.answerableQuestionsCount);
    }
  };

  QuizModel.get = function (catalogId, id, attrs) {
    return QuizzesResources.get({ catalogId, id }).$promise
      .then((resource) => new QuizModel(_.extend(resource.result, attrs, { id, catalogId })));
  };

  QuizModel.getTimedQuiz = function (catalogId, questionSetId, windowId, actionState, adminPreview) {
    const payload = {
      windowId,
      actionState,
      adminPreview,
    };
    return QuizzesResources.createTimedQss({ catalogId, questionSetId }, payload).$promise
      .then((resource) => new QuizModel(_.extend(resource.result, { catalogId, questionSetId })));
  };

  QuizModel.getSubmissions = function (catalogId, userId) {
    return QuizzesResources.getSubmissions({ catalogId, userId }).$promise;
  };

  QuizModel.resetAttempts = function (catalogId, userId, questionSetIds) {
    return QuizzesResources.resetAttempts({ catalogId }, { userId, questionSetIds }).$promise;
  };

  return QuizModel;
}
