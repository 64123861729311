import { css, SerializedStyles } from '@emotion/react';
import ClickableContainer, { ClickableContainerProps } from 'components/clickable-container';
import ColorPickerPopover from 'components/color-picker-popover';
import { ConditionalWrap } from 'components/conditional-wrap';
import FileUploadingBar from 'components/file-uploading-bar';
import CustomRadioGroup from 'components/custom-radio-group';
import React from 'react';
import t from 'react-translate';
import {
  convertToPictureData,
  Style5AccordionSectionViewOptions,
} from 'redux/schemas/models/lecture-component';
import { FileUpload } from 'redux/schemas/models/file-uploads';
import { LecturePage, NLecturePage } from 'redux/schemas/models/lecture-page';
import NvDropdown, { NvDropdownOption, NvDropdownButtonStyle } from 'shared/components/inputs/nv-dropdown';
import NvFilePicker from 'shared/components/nv-filepicker';
import IconLibraryModal from 'shared/components/nv-icon-library';
import NvTooltip from 'shared/components/nv-tooltip';
import useUploadFile from 'shared/hooks/use-upload-file';
import { S3NameSpaces } from 'shared/services/s3-upload-factory';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { pick } from 'underscore';
import { useLecturePageFromParams } from 'lecture_pages/hooks/lecture-routing';
import { LecturePageMode } from '..';
import ApplyToAllSectionsButton from './apply-to-all-sections-button';
import { CircleCentered, SquareCentered } from './ordered-section-left-hand-symbol-style-selector';
import Style5Picture from './style-5-picture';
import Style5SectionContainer from './style-5-section-container';

const containerStyle = css`
  .nv-dropdown,
  .bs4-dropdown {
    height: 100%;
    /* the dropdown sets the cursor to 'auto' when it's disabled but in this
    case we wrap it with a button container and we want to inherit the cursor */
    cursor: inherit;
  }

  .bs4-dropdown > div:first-of-type {
    height: 100%;
    display: flex;
  }
`;

const leftHandSymbolContainerStyle = css`
  width: 65px;
  display: flex;
  flex-shrink: 0;
  height: 100%;
  margin-right: ${halfSpacing}px;
`;

const leftHandSymbolSquareContainerStyle = css`
  min-height: 65px;
  margin-right: ${standardSpacing}px;
`;

const leftHandSymbolCircleContainerStyle = css`
  flex-shrink: 0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: 0 15px;
  align-self: center;
`;

interface Style5LeftHandIconProps extends ClickableContainerProps {
  sectionViewOptions: Style5AccordionSectionViewOptions;
  mode: LecturePageMode;
  children: React.ReactNode;
}

/**
 * renders the container that wraps the symbol (either square or circle)
 */
const Style5LeftHandButton = React.forwardRef<HTMLDivElement, Style5LeftHandIconProps>((props, ref) => {
  const { sectionViewOptions, mode, ...rest } = props;
  let containerClassName = '';
  let buttonContainerStyle: SerializedStyles;
  const lecturePage = useLecturePageFromParams();
  if (props.sectionViewOptions.leftHandSymbolShape === 'circle') {
    buttonContainerStyle = leftHandSymbolCircleContainerStyle;
    containerClassName = 'rounded-circle';
  } else {
    // square style
    buttonContainerStyle = leftHandSymbolSquareContainerStyle;
  }

  return (
    <ClickableContainer
      {...rest}
      layoutOnly={props.mode !== LecturePageMode.EDIT}
      css={css`
        ${leftHandSymbolContainerStyle};
        ${buttonContainerStyle};
        background-color: ${props.sectionViewOptions.leftHandSymbolBackgroundColor};
      `}
      ref={ref}
    >
      <NvTooltip enabled={props.mode === LecturePageMode.EDIT} text={t.LECTURE_PAGES.COMPONENTS.ACCORDION.CLICK_TO_EDIT_STYLE()}>
        <div className='d-flex flex-grow-1'>
          <div
            className={`overflow-hidden d-flex flex-grow-1 align-items-center justify-content-center ${containerClassName}`}
          >
            {props.children}
          </div>
        </div>
      </NvTooltip>
    </ClickableContainer>
  );
});

type Style5SectionComponent = React.ComponentProps<typeof Style5SectionContainer>;

interface Style5SectionLeftHandSymbolProps {
  onViewOptionsChange: Style5SectionComponent['onViewOptionsChange'];
  onLeftHandPictureChange(picture: FileUpload): void;
  onLeftHandIconChange(iconLibraryName: string): void;
  applyViewOptionsToAllSections: Style5SectionComponent['applyViewOptionsToAllSections'];
  sectionViewOptions: Style5AccordionSectionViewOptions;
  mode: LecturePageMode;
  currentLecture: NLecturePage;
  isOnlySection: boolean;
  picture: FileUpload;
  thumbnailUrl: string | null;
}

/* @ngInject */
export default function Style5SectionLeftHandSymbol(props: Style5SectionLeftHandSymbolProps) {
  const [isLeftSideColorPickerOpen, setIsLeftSideColorPickerOpen] = React.useState(false);
  const [showIconLibrary, setShowIconLibrary] = React.useState(false);
  const icon = props.sectionViewOptions.iconLibraryName;
  const { picture } = props;

  const { uploadFiles, isUploading, filesUploading } = useUploadFile();

  async function onImagePicked(files: File[]) {
    const [novoEdFile] = await uploadFiles(files, S3NameSpaces.CONTENT_TEMPLATES);
    props.onLeftHandPictureChange(convertToPictureData(novoEdFile));
  }

  function onLeftSideColorPickerToggle(nextOpen: boolean) {
    setIsLeftSideColorPickerOpen(nextOpen);
  }

  const dropdownItems: NvDropdownOption[] = [
    {
      type: 'custom',
      customItem: (
        <NvFilePicker accept={['image/*']} onChange={onImagePicked}>
          <div className='bs4-dropdown-item'>{t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_5.UPLOAD()}</div>
        </NvFilePicker>
      ),
    },
    {
      type: 'text',
      // TODO: use the correct translations for this component
      text: t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_5.PICK_FROM_LIBRARY(),
      callback: () => setShowIconLibrary(true),
    },
    { type: 'divider' },
    {
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_5.CHANGE_COLORS_AND_STYLE(),
      callback: () => setIsLeftSideColorPickerOpen(true),
    },
  ];

  // the picture can exist without containing the cdnUrl. This flag check that
  // the cdnUrl is present
  const isPicture = Boolean(props.thumbnailUrl || picture?.cdnUrl);
  const isIcon = Boolean(icon);
  const edit = props.mode === LecturePageMode.EDIT;
  const showUploadIcon = !isIcon && !picture && edit;
  // checks if the color picker is open to prevent the dropdown from showing
  // when the button is clicked and we rather expect the color picker to close
  const dropdownDisabled = Boolean(!edit || isUploading || isLeftSideColorPickerOpen);

  let buttonChildren = null as React.ReactElement;

  if (isUploading) {
    buttonChildren = <FileUploadingBar filesUploading={filesUploading} className='w-100' />;
  } else if (showUploadIcon || isIcon) {
    buttonChildren = (
      <i
        className={`icon icon-${icon || 'upload'}`}
        style={{
          fontSize: props.sectionViewOptions.leftHandSymbolShape === 'circle' ? '20px' : '24px',
          color: props.sectionViewOptions.leftHandSymbolColor,
        }}
      />
    );
  } else if (isPicture) {
    buttonChildren = (
      <Style5Picture
        picture={picture}
        thumbnailUrl={props.thumbnailUrl}
      />
    );
  }

  let backgroundColorInfo = t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_5.LEFT_HAND_SQUARE_PICTURE_BACKGROUND_COLOR_PICKER_TOOLTIP();
  if (isIcon) {
    backgroundColorInfo = t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_5.LEFT_HAND_ICON_BACKGROUND_COLOR_PICKER_TOOLTIP();
  } else if (props.sectionViewOptions.leftHandSymbolShape === 'circle') {
    backgroundColorInfo = t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_5.LEFT_HAND_CIRCLE_PICTURE_BACKGROUND_COLOR_PICKER_TOOLTIP();
  }

  return (
    // in edit mode we wrap the leftHandSymbol with the ColorPickerPopover
    <div css={containerStyle}>
      <ConditionalWrap
        condition={edit}
        wrap={(children) => (
          <ColorPickerPopover<{
            leftHandSymbolColor?: string,
            leftHandSymbolBackgroundColor: string,
          }>
            placement='top-start'
            show={isLeftSideColorPickerOpen}
            onToggle={onLeftSideColorPickerToggle}
            sections={[{
              name: 'leftHandSymbolBackgroundColor' as const,
              title: t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_5.LEFT_HAND_BACKGROUND_COLOR_PICKER_LABEL(),
            }, (isIcon && {
              name: 'leftHandSymbolColor' as const,
              title: t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_5.LEFT_HAND_ICON_COLOR_PICKER_LABEL(),
            })].filter(Boolean)}
            onChange={(section, newColor) => {
              props.onViewOptionsChange({
                [section]: newColor,
              });
            }}
            colorValues={{
              leftHandSymbolColor: props.sectionViewOptions.leftHandSymbolColor,
              leftHandSymbolBackgroundColor: props.sectionViewOptions.leftHandSymbolBackgroundColor,
            }}
            renderBeforePalette={() => {
              const iconContent = (
                <div>
                  <div className='label gray-1 mb-2'>{t.SHARED.SELECT_SHAPE()}</div>
                  <Style5LeftHandSymbolStyleSelector
                    value={props.sectionViewOptions.leftHandSymbolShape}
                    onChange={(leftHandSymbolShape) => {
                      props.onViewOptionsChange({
                        leftHandSymbolShape,
                      });
                    }}
                  />
                </div>
              );

              const notOnlyContent = !props.isOnlySection && (
              <div className='d-flex flex-column align-items-center mb-2'>
                <ApplyToAllSectionsButton
                  onClick={() => {
                    props.applyViewOptionsToAllSections(
                      pick(
                        props.sectionViewOptions,
                        'leftHandSymbolBackgroundColor',
                        'leftHandSymbolColor',
                        'leftHandSymbolShape',
                      ),
                    );
                  }}
                />
              </div>
              );
              return (
                <div>
                  {notOnlyContent}
                  {iconContent}
                </div>
              );
            }}
          >
            {children}
          </ColorPickerPopover>
        )}
      >
        <NvDropdown
          buttonStyle={NvDropdownButtonStyle.CUSTOM}
          items={dropdownItems}
          customTarget={() => (
            <Style5LeftHandButton mode={props.mode} sectionViewOptions={props.sectionViewOptions}>
              {buttonChildren}
            </Style5LeftHandButton>
          )}
          disabled={dropdownDisabled}
        />
      </ConditionalWrap>
      <IconLibraryModal
        onIconClick={props.onLeftHandIconChange}
        show={showIconLibrary}
        onClose={() => setShowIconLibrary(false)}
      />
    </div>
  );
}

const iconRadioButtonStyle = css`
  display: flex;
  align-self: flex-start;
`;

const radioButtonsGroupValues = [
  {
    value: 'square',
    label: 'Square',
  } as const,

  {
    value: 'circle',
    label: 'Circle',
  } as const,
];

export type LeftHandSymbolStyles = typeof radioButtonsGroupValues[number]['value'];

interface LeftHandSymbolStyleSelectorProps {
  value: LeftHandSymbolStyles;
  onChange: (value: LeftHandSymbolStyles) => void;
}

/**
 * UI to select the symbol shape shown in the color picker popover
 */
function Style5LeftHandSymbolStyleSelector(props: LeftHandSymbolStyleSelectorProps) {
  return (
    <CustomRadioGroup
      css={iconRadioButtonStyle}
      optionContainerStyle={css`
        margin-right: 7px;
      `}
      activeValue={props.value}
      name='style-3-left-hand-symbol-style'
      onChange={props.onChange}
      radios={radioButtonsGroupValues}
      renderRadioButton={(radioButtonProps) => {
        if (radioButtonProps.value === 'circle') {
          return <CircleCentered checked={radioButtonProps.checked} />;
        }

        return <SquareCentered checked={radioButtonProps.checked} />;
      }}
    />
  );
}
