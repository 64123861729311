import store from 'redux/store';
import { CreateBookmarkRequest } from 'redux/schemas/api/bookmarks';
import { addBookmark, highlightBookmark } from 'redux/actions/bookmarks';

/* @ngInject */
export default function BookmarkModel(
  _,
  CurrentCourseManager,
  BookmarkResources,
  FlyoutModalManager,
) {
  class Bookmark {
    constructor(attributes) {
      _.extend(this, attributes);
    }


    static openBookmarkPanel = () => {
      FlyoutModalManager.openFlyout({
        controller: 'BookmarksFlyoutCtrl as vm',
        template: 'bookmarks/templates/bookmarks-flyout-react-app.html',
        isNavigational: false,
      });
    };

    static highlightBookmark = (id) => {
      store.dispatch(highlightBookmark({ id }));
      Bookmark.openBookmarkPanel();
    };


    static createBookmark({ type, catalogId, componentId }: CreateBookmarkRequest) {
      return BookmarkResources.createBookmark({}, {
        type,
        catalogId,
        componentId,
      }).$promise.then(response => {
        const { bookmark } = response.result;
        store.dispatch(addBookmark(bookmark));
        Bookmark.openBookmarkPanel();
        return bookmark;
      });
    }
  }

  return Bookmark;
}
