/* @ngInject */
export default function LearnerProfilePaginationController(
  $stateParams,
  $state,
  LearnerDirectoryManager,
  LearnerProfileManager,
  _,
) {
  const vm = this;

  vm.LearnerDirectoryManager = LearnerDirectoryManager;
  vm.LearnerProfileManager = LearnerProfileManager;
  vm.$stateParams = $stateParams;

  vm.getPrevious = getPrevious;
  vm.getNext = getNext;

  function getPrevious() {
    if (!$stateParams.galleryMode) {
      return undefined;
    }


    if (!vm.LearnerProfileManager.profileUser) {
      return null;
    }

    const prevIndex = _.findIndex(vm.LearnerDirectoryManager.users, (userProfile) => userProfile.user.id === vm.LearnerProfileManager.profileUser.id) - 1;
    const prev = vm.LearnerDirectoryManager.users[prevIndex];

    if (!prev) {
      return null;
    }

    return $state.href('learner-profile-modal',
      {
        catalogId: $stateParams.catalogId,
        userId: prev ? prev.user.id : null,
      });
  }

  function getNext() {
    if (!$stateParams.galleryMode) {
      return undefined;
    }

    if (!vm.LearnerProfileManager.profileUser) {
      return null;
    }

    const nextIndex = _.findIndex(vm.LearnerDirectoryManager.users, (userProfile) => userProfile.user.id === vm.LearnerProfileManager.profileUser.id) + 1;
    const next = vm.LearnerDirectoryManager.users[nextIndex];

    if (!next) {
      return null;
    }
    return $state.href('learner-profile-modal',
      {
        catalogId: $stateParams.catalogId,
        userId: next ? next.user.id : null,
      });
  }
}
