import { each, uniq } from 'underscore';
import React from 'react';
import ReactDOM from 'react-dom';

import { BaseUser } from 'redux/schemas/models/my-account';
import { NvUserAvatar } from 'components/nv-user-avatar';
import { renderToStaticMarkup } from 'react-dom/server';
import { isRtl } from 'styles/global_defaults/media-queries';
import { selectElementContents, setCursor } from 'froala/helpers/nv-froala-functions';
import store from 'redux/store';
import { Provider } from 'react-redux';

import 'tributejs/dist/tribute.css';

export default (values, node) => ({
  trigger: '@',
  menuContainer: node,
  lookup: (user) => user.firstName + user.lastName,
  values: values ?? [],
  fillAttr: 'fullName',
  allowSpaces: true,
  selectTemplate: (item) => renderToStaticMarkup(
    <span
      oe-mention=''
      data-user-id={item.original.id}
      // @ts-ignore
      user={JSON.stringify(item.original).replace(/'/g, '&#39;')}
      dir='ltr'
    >
      {`@${item.original.firstName} ${item.original.lastName}`}
    </span>,
  ),
  menuItemTemplate: (item) => {
    const myDiv = document.createElement('div');

    ReactDOM.render(
      <Provider store={store}>
        <NvUserAvatar
          borderType='round'
          size='xs'
          user={item.original}
        />
        <div className='user-name'>{`${item.original.firstName} ${item.original.lastName}`}</div>
      </Provider>,
      myDiv,
    );

    return myDiv.innerHTML;
  },
});

/** Parse froala content and check if any mentions. Return array of mention ids  */
export const parseMentionables = (body, users) => {
  const mentionableIds = [];
  if (body?.indexOf('<span oe-mention') > -1) {
    each($('*[oe-mention]', body), (element) => {
      const userId = $(element).data('user-id');
      if (userId === 0) {
        mentionableIds.push(...users.map(user => user.id));
      } else {
        mentionableIds.push(userId);
      }
    });
  }
  return uniq(mentionableIds);
};

export const createFroalaMentionableString = (user: BaseUser) => `<p>${onSelectMentionable(user)}&nbsp;</p>`;

const onSelectMentionable = (user: BaseUser) => {
  const fullName = `${user.firstName} ${user.lastName}`;
  // eslint-disable-next-line @typescript-eslint/quotes
  return (
    `<span data-user-id="${user.id}" dir="ltr" oe-mention="" user='${JSON.stringify(user).replace(/'/g, '&#39;')}'>@${fullName}</span>`
  );
};

/** Keypress handler for froala. Handles delete */
export const interactWithOeMentionSpanBefore = (_e, editor, keyupEvent) => {
  if (!keyupEvent) {
    return null;
  }

  const keyCode = keyupEvent.which;

  const isDeleteKey = keyCode === 8 || keyCode === 46;
  const isPrintableKey = (function (keycode) {
    if (!keycode) {
      return false;
    }
    const isPrintable = (keycode > 47 && keycode < 58) // number keys
        || keycode === 32 || keycode === 13 // spacebar & return key(s) (if you want to allow carriage returns)
        || (keycode > 64 && keycode < 91) // letter keys
        || (keycode > 95 && keycode < 112) // numpad keys
        || (keycode > 185 && keycode < 193) // ;=,-./` (in order)
        || (keycode > 218 && keycode < 223); // [\]' (in order)
    return isPrintable;
  }(keyCode));

  if (editor.selection.get().anchorNode) {
    const currentNode = editor.selection.get().anchorNode.parentNode;

    // see if cursor inside mention span
    if (currentNode.hasAttribute('oe-mention')) {
      if (isPrintableKey || isDeleteKey) {
        currentNode.remove();

        return false;
      }
    }
  }

  return null;
};

/** Keypress handler for froala. Handles left and right key */
export const interactWithOeMentionSpanAfter = (_e, editor, keyupEvent) => {
  if (!keyupEvent) {
    return;
  }

  const keyCode = keyupEvent.which;

  const moveLeftKey = isRtl() ? keyCode === 39 : keyCode === 37;
  const moveRightKey = isRtl() ? keyCode === 37 : keyCode === 39;

  if (editor.selection.get().anchorNode) {
    const currentNode = editor.selection.get().anchorNode.parentNode;

    if (currentNode.hasAttribute('oe-mention')) {
      if (moveLeftKey) {
        if (!editor.selection.get().anchorNode.parentNode.previousSibling) {
          const $superParent = $(editor.selection.get().anchorNode.parentNode.parentNode);
          $superParent.prepend('&#8203;');
        }
        editor.selection.setBefore(currentNode);
        editor.selection.restore();
      } else if (moveRightKey) {
        const mentionParent = currentNode.parentNode;
        if (currentNode.nextSibling) {
          let characterIndex = 0;
          const firstCharacterCode = currentNode.nextSibling.wholeText?.charCodeAt(0);

          if (firstCharacterCode === 32 || firstCharacterCode === 160) {
            characterIndex = 1;
          }

          setCursor(currentNode.nextSibling, characterIndex);
        } else {
          $(mentionParent).append('\xA0');
          setCursor(currentNode.nextSibling, 1);
        }
      } else {
        selectElementContents(currentNode);
      }
    } // else if about to go into a selection
  }
};
