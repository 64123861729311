import store from '../../../redux/store.ts';

/* @ngInject */
export default function AttachmentLectureComponentModel(
  _,
  LectureComponentBaseModel,
  config,
  S3UploadFactory,
  S3NameSpaces,
  S3UploadWrapper,
  $q,
  AttachmentsResources,
) {
  class AttachmentLectureComponent extends LectureComponentBaseModel {
    constructor(attributes) {
      const defaults = {
        type: 'AttachmentLectureComponent',
        attachment: null,
        uploadInProgress: null,
        file: null,
        viewOptions: {
          embeded: false,
        },
        isActivity: false,
        canBeCopied: true,
      };
      super(_.extend({}, defaults, attributes));
    }

    getPayload() {
      return _.pick(this, ['attachment', 'viewOptions']);
    }

    toggleEmbedOnLecturePage($event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.viewOptions.embeded = !this.viewOptions.embeded;
      return this.save();
    }

    toggleDownloadableStatus($event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.attachment.file.isDownloadable = !this.attachment.file.isDownloadable;
      return this.save();
    }

    updateEmbeddingStyle(embeddingStyle) {
      this.attachment.file.embeddingStyle = embeddingStyle;
      return AttachmentsResources.updateStyle({ catalogId: this.catalogId, id: this.attachment.id, embeddingStyle }, '');
    }

    persistAttachment() {
      const unpersistedComponentIndexes = Object.keys(
        store.getState().app.unpersistedComponents[this.lecturePage.id] || {},
      );
      // count un-persisted components before the current one
      const unpersistedComponentsBeforeCurrentOneCount = unpersistedComponentIndexes.filter(
        (indexes) => indexes < this.index,
      ).length;

      // if we upload multiple files at the same time, we insert multiple
      // unpersisted components and files can be uploaded in a different order
      // than they appear in the lecture page. Since the server doesn't know
      // about unpersisted components, we need the server to insert the
      // lecture component in the right place considering only persisted
      // components so we generate this index based in persisted
      // component only
      const promise = this.save(undefined, { index: this.index - unpersistedComponentsBeforeCurrentOneCount });

      promise.then(
        () => {
          this.s3Promise = null;
        },
        () => {
          this.abortUpload();
        },
      );

      return promise;
    }

    saveAttachmentAndPersist(createNewComponent) {
      this.uploadToS3Object = S3UploadFactory.uploadToS3(this.file, S3NameSpaces.ATTACHMENTS, true);

      let componentPromise = null;
      return this.uploadToS3Object.promise.then((resp) => {
        const s3FileData = resp.config.data.file;
        this.attachment = {
          fileName: s3FileData.name,
          fileSize: s3FileData.size,
          fileType: s3FileData.type,
          uniqueId: s3FileData.uniqueId,
        };

        if (createNewComponent) {
          componentPromise = createNewComponent(this.getPayload());
        } else {
          componentPromise = this.save();
        }

        // TODO: This comes from https://github.com/novoed/NovoEdWeb/commit/49bafd6bea177828830ba2ee357d312d7c4ec5b6 and is related to a fix Juan made
        // for file upload ordering. No clue if this still persists in this new arch, needs tested
        // const unpersistedComponentIndexes = Object.keys(store.getState().app.unpersistedComponents[this.lecturePage.id] || {});
        // // count un-persisted components before the current one
        // const unpersistedComponentsBeforeCurrentOneCount = unpersistedComponentIndexes.filter((indexes) => indexes < this.index).length;

        // // if we upload multiple files at the same time, we insert multiple
        // // unpersisted components and files can be uploaded in a different order
        // // than they appear in the lecture page. Since the server doesn't know
        // // about unpersisted components, we need the server to insert the
        // // lecture component in the right place considering only persisted
        // // components so we generate this index based in persisted
        // // component only
        // componentPromise = this.save(undefined, { index: this.index - unpersistedComponentsBeforeCurrentOneCount });

        componentPromise.then(() => {
          this.s3Promise = null;
        }, () => {
          this.abortUpload();
        });

        // once the file is uploaded, we need to save the lecture component on the BE. We enqueue the
        // API call to do save it and the queue will guarantee that no other save call runs concurrently
        // preventing a race condition where multiple files are sent with the same index then stored
        // out of order
        // return AttachmentLectureComponent.enqueuePersistAttachmentCall(() => this.persistAttachment());
      });
    }

    abortUpload() {
      if (this.uploadToS3Object) {
        this.uploadToS3Object.abort();
      }
      this.attachment = null;
      this.s3Promise = null;
      this.lecturePage.afterComponentDelete(this);
    }

    hasEmbeddingStyleOptions() {
      return this.viewOptions.embeded && this.attachment?.file
                && !_.includes(config.files.images.split(','), this.attachment.file.type)
                && !_.includes(config.files.audios.split(','), this.attachment.file.type);
    }

    isEmbeddingStyleAvailable(style) {
      if (!this.attachment || !this.attachment.file) {
        return false;
      } if (_.includes(config.files.images.split(','), this.attachment.file.type)) {
        return false;
      } if (_.includes(config.files.videos.split(','), this.attachment.file.type)) {
        return _.includes(['4:3', '16:9', '21:9'], style);
      } if (_.includes(config.files.audios.split(','), this.attachment.file.type)) {
        return false;
      } if (_.includes(config.files.pdfs.split(','), this.attachment.file.type) || _.includes(config.files.presentations.split(','), this.attachment.file.type)
                    || _.includes(config.files.documents.split(','), this.attachment.file.type) || _.includes(config.files.excels.split(','), this.attachment.file.type)) {
        return true;
      }
      return false;
    }

    static isPersistAttachmentCallInProgress = false;

    static persistAttachmentQueue = [];

    static enqueuePersistAttachmentCall(promiseFactory) {
      return new Promise((resolve, reject) => {
        this.persistAttachmentQueue.push({
          resolve,
          reject,
          promiseFactory,
        });

        this.dequeuePersistAttachmentCall();
      });
    }

    static dequeuePersistAttachmentCall() {
      if (this.isPersistAttachmentCallInProgress) {
        return;
      }

      const nextItem = this.persistAttachmentQueue.shift();

      if (!nextItem) {
        return;
      }

      this.isPersistAttachmentCallInProgress = true;

      nextItem
        .promiseFactory()
        .then((val) => {
          nextItem.resolve(val);
        })
        .catch((err) => {
          nextItem.reject(err);
        })
        .finally(() => {
          this.isPersistAttachmentCallInProgress = false;
          this.dequeuePersistAttachmentCall();
        });
    }
  }

  const translateKeyDelimiter = 'LECTURE_PAGES.COMPONENTS.ATTACHMENT_LIST.EMBEDDING_STYLE.';

  AttachmentLectureComponent.embeddingStyleOptions = [{ name: '4:3', translateKey: `${translateKeyDelimiter}4BY3` },
    { name: '16:9', translateKey: `${translateKeyDelimiter}16BY9` },
    { name: '1:1', translateKey: `${translateKeyDelimiter}1BY1` },
    { name: '21:9', translateKey: `${translateKeyDelimiter}21BY9` },
    { name: 'a4', translateKey: `${translateKeyDelimiter}A4` },
    { name: 'letter', translateKey: `${translateKeyDelimiter}LETTER` }];
  AttachmentLectureComponent.maxFileSize = `${config.files.attachmentFileSizeLimitGb}GB`;
  AttachmentLectureComponent.allowedFileTypes = [config.files.presentations, config.files.documents, config.files.pdfs,
    config.files.audios, config.files.videos, config.files.images, config.files.zips, config.files.allUploadableExtensions.join(',')].join(',');
  AttachmentLectureComponent.iconClass = 'icon-files';
  AttachmentLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.ATTACHMENT_LIST.DESCRIPTION';
  AttachmentLectureComponent.createWithFileUpload = true;
  AttachmentLectureComponent.multipleFilesAllowed = true;
  AttachmentLectureComponent.isContentComponent = true;
  AttachmentLectureComponent.pendoTagName = config.pendo.lectureEdit.resources;
  AttachmentLectureComponent.toolTipKey = 'LECTURE_PAGES.COMPONENTS.ATTACHMENT_LIST.TOOLTIP';


  return AttachmentLectureComponent;
}
