import mergeWith from 'lodash/mergeWith';
import store from 'redux/store';
import { replaceArrays } from '../../shared/lodash-utils';
import { unsetCurrentCourseCourseLongTeamSet, unsetCurrentCourseGroupTeamSet } from '../../redux/actions/lecture-components';

/* @ngInject */
export default function CourseModelService(
  CoursesResource,
  UserCourseModel,
  UserManagementResources,
  MentoringsResource,
  $http,
  $q,
  Upload,
  moment,
  config,
  _,
  humps,
  $translate,
  ConfirmationOverlays,
  RolesService,
  AlertMessages,
  $timeout,
) {
  /* eslint-disable  max-statements */
  const CourseModel = function (attributes) {
    const _this = this;
    const attributesCopy = _.clone(attributes);
    // defaults
    _this.isSelfPaced = false;

    _this.getTeachingTeamMembers = getTeachingTeamMembers;
    _this.getActiveStudents = getActiveStudents;
    _this.getActiveMentionableStudents = getActiveMentionableStudents;
    _this.getActiveStudentsNotInTeams = getActiveStudentsNotInTeams;
    _this.getParentProgram = getParentProgram;

    _this.save = save;
    _this.requestDeletion = requestDeletion;
    _this.deletionRequested = deletionRequested;

    _this.isPersisted = isPersisted;
    _this.isInProgram = isInProgram;
    _this.isClosedToLearners = isClosedToLearners;
    _this.isLeaderboardEnabled = isLeaderboardEnabled;
    _this.setExtras = setExtras;

    _this.courseLongTeamsAvailable = courseLongTeamsAvailable;
    _this.isClassic = isClassic;
    _this.isEditable = isEditable;

    _this.publicEnrollment = publicEnrollment;
    _this.freeAndOpenEnrollment = freeAndOpenEnrollment;
    _this.paidAndOpenEnrollment = paidAndOpenEnrollment;
    _this.optionallyPaidAndOpenEnrollment = optionallyPaidAndOpenEnrollment;
    // _this.closedEnrollment = closedEnrollment;
    _this.openEnrollmentInInstitution = openEnrollmentInInstitution;
    _this.getShareText = getShareText;
    _this.enroll = enroll;
    _this.enrollWithRole = enrollWithRole;
    _this.bulkUnenroll = bulkUnenroll;
    _this.reenroll = reenroll;
    _this.setUserCourse = setUserCourse;

    _this.assignMentee = assignMentee;
    _this.assignMentees = assignMentees;
    _this.getMentees = getMentees;
    _this.removeMentee = removeMentee;
    _this.getAliases = getAliases;

    _this.unenrollUserDialog = unenrollUserDialog;
    _this.loadCourseCompletionCriteria = loadCourseCompletionCriteria;
    _this.getCompletionCriteriaCount = getCompletionCriteriaCount;

    _this.isDecayEnabled = isDecayEnabled;
    _this.requiredPoints = requiredPoints;
    _this.requiredAssignmentsCount = requiredAssignmentsCount;
    _this.requiredTodosCount = requiredTodosCount;
    _this.getPendoProperties = getPendoProperties;

    _this.extendProfileRequirements = extendProfileRequirements;
    _this.addProfileRequirement = addProfileRequirement;
    _this.removeProfileRequirementOfLecturePage = removeProfileRequirementOfLecturePage;
    _this.removeTeamSet = removeTeamSet;
    _this.removeGroupTeamSet = removeGroupTeamSet;

    _this.config = config;
    _this.updateFromReact = updateFromReact;

    if (attributes?.institution) {
      _this.institution = attributes.institution;
      attributesCopy.institutionFontColor = attributes.institution.brandBarFontColor;
      attributesCopy.institutionBrandColor = attributes.institution.brandColor;
    } else {
      attributesCopy.institutionFontColor = attributes.institutionFontColor || '#FFF';
      attributesCopy.institutionBrandColor = attributes.institutionBrandColor || '#467b8c';
    }

    // Convert a UserCourse into the appropriate model
    if (attributes.userCourse) {
      attributesCopy.userCourse = new UserCourseModel(_.extend({ catalogId: attributes.catalogId }, attributes.userCourse));
    }

    _.extend(_this, attributesCopy);

    initialize();

    function initialize() {
      _this.officialReleaseDateIsPast = _this.officialReleaseDate && moment(_this.officialReleaseDate) < moment();
      _this.endDateIsPast = _this.endDate && moment(_this.endDate) < moment();
    }

    function save(saveAttributes) {
      const attributesToSend = ['id', 'name', 'isProgram', 'catalogId', 'rate',
        'usedFor', 'isSelfPaced', 'enrollmentLimitInDays', 'typeOfRegistration',
        'institutionId', 'beingOffered', 'releaseDate', 'officialReleaseDate',
        'registrationCloseDate', 'paymentsStartAt', 'paymentsEndAt', 'endDate',
        'closeDate', 'parentCourseId', 'executiveSummary', 'setOfFeatures',
        'coverPhotoUrl', 'nameFontColor', 'headerColor', 'durationType',
        'duration', 'degreedTags', 'skill_tags', 'logoSize', 'deleteHeaderBackground',
        'deleteLogo', 'entitlements', 'calendarEventsEnabled', 'openInJourney',
        'lmsActivityId', 'lmsActivityCode', 'courseMetadata', 'nextCourseButtonEnabled',
      ];

      let course = _.pick(_this, attributesToSend);
      if (!course.institutionId && _this.institution) {
        course.institutionId = _this.institution.id;
      }
      if (saveAttributes) {
        _.extend(course, saveAttributes);
      }

      _.each(attributesToSend, (attribute) => {
        if (course[attribute] === undefined) {
          course[attribute] = null;
        }
      });

      course.entitlements = course.entitlements?.map(entitlement => _.pick(entitlement, ['id', 'profileSettingId', 'values']));
      if (course.entitlements?.length === 0) {
        // If no entitlements is selected, then give a null value to backend
        // so that backend could know, it has to be removed.
        course.entitlements = null;
      }

      course = humps.decamelizeKeys(course);

      // Only adding the background image and logo if they exist.
      if (_this.headerBackground) {
        course.header_background = _this.headerBackground;
      }
      if (_this.logo) {
        course.logo = _this.logo;
      }

      course.cover_photo = _this.coverPhoto;
      course.delete_cover_photo = !_this.coverPhoto && !_this.coverPhotoUrl;

      return Upload.upload({
        url: course.id ? `courses/${course.id}` : 'courses',
        method: course.id ? 'PUT' : 'POST',
        fields: {
          course,
        },
      }).then(
        (response) => {
          if (typeof (response.data.result) === 'object') {
            _.extend(_this, response.data.result);
          }
          return $q.resolve(_this);
        },
        (response) => $q.reject(response),
      );
    }

    function requestDeletion() {
      _this.requestingDeletion = true;
      return CoursesResource.requestDeletion({
        id: _this.id,
      }).$promise;
    }

    function deletionRequested() {
      _this.requestDeletion = false;
      _this.isBeingDeleted = true;
    }

    function isPersisted() {
      return !!_this.id;
    }

    function isLeaderboardEnabled() {
      return _this.gamificationEnabled && _this.gamificationLeaderboardEnabled;
    }

    function getTeachingTeamMembers() {
      if (_this.teachingTeamMembers) {
        return $q.when(_this.teachingTeamMembers);
      }
      return CourseModel.getTeachingTeamMembersForCourse(_this.catalogId)
        .then((teachingTeamList) => {
          _this.teachingTeamMembers = teachingTeamList;
        });
    }

    function getActiveStudents() {
      return CourseModel.getActiveStudentsForCourse(_this.catalogId);
    }

    function getActiveMentionableStudents() {
      const deferred = $q.defer();

      const getActiveStudentsPromise = _this.getActiveStudents();
      const unmentionablePromise = CoursesResource.getUnmentionableUserIds({ catalogId: _this.catalogId }).$promise
        .then((response) => response.result);

      $q.all([getActiveStudentsPromise, unmentionablePromise])
        .then((responses) => {
          const [activeStudentsHash, unmentionable] = responses;

          $.each(unmentionable, (index, value) => {
            delete activeStudentsHash[value];
          });
          deferred.resolve(activeStudentsHash);
        });

      return deferred.$promise;
    }

    function getActiveStudentsNotInTeams(teamId) {
      const getActiveStudentsPromise = _this.getActiveStudents();
      const getUsersWithTeamsPromise = CoursesResource.getUsersWithTeams({ catalogId: _this.catalogId, teamId }).$promise;

      return $q.all([getActiveStudentsPromise, getUsersWithTeamsPromise]).then((responses) => {
        const [activeStudentsHash, usersWithTeams] = responses;

        _.each(usersWithTeams, (userId) => {
          delete activeStudentsHash[userId];
        });

        return activeStudentsHash;
      });
    }

    function isInProgram() {
      return _this.parentPrograms?.length;
    }

    function getParentProgram() {
      // for simplicity we will return the first program if the course is in multiple separate programs
      if (!isInProgram()) {
        return null;
      }

      return _this.parentPrograms[0];
    }

    function isClosedToLearners() {
      return _this.inArchiveMode;
    }

    function setExtras(extras) {
      _.extend(_this, extras);
    }

    function courseLongTeamsAvailable() {
      return _this.hasCourseLongTeams;
    }

    function isClassic() {
      return _this.version === CourseModel.VERSION.CLASSIC;
    }

    function isEditable() {
      return !_this.duplicationInProgress && !_this.isBeingDeleted;
    }

    function publicEnrollment() {
      return freeAndOpenEnrollment() || paidAndOpenEnrollment() || optionallyPaidAndOpenEnrollment();
    }

    function freeAndOpenEnrollment() {
      return _this.typeOfRegistration === CourseModel.FREE_ENROLLMENT;
    }

    function paidAndOpenEnrollment() {
      return _this.typeOfRegistration === CourseModel.PAID_ENROLLMENT;
    }

    function optionallyPaidAndOpenEnrollment() {
      return _this.typeOfRegistration === CourseModel.FREE_AND_PAID_CERTIFICATE_ENROLLMENT;
    }

    // function closedEnrollment() {
    _this.closedEnrollment = () => _this.typeOfRegistration === CourseModel.CLOSED_ENROLLMENT;

    function openEnrollmentInInstitution() {
      return _this.typeOfRegistration === CourseModel.FREE_ENROLLMENT_IN_INSTITUTION;
    }

    _this.entitlementBasedEnrollment = () => _this.typeOfRegistration === CourseModel.OPEN_BASED_ON_ENTITLEMENTS;

    function getShareText() {
      return $translate.instant('FLYER.COURSE.SHARE', {
        courseName: _this.name,
        free: freeAndOpenEnrollment(),
        isProgram: false,
        institutionName: _this.institution.name,
      });
    }

    function setUserCourse(userCourse) {
      _this.userCourse = new UserCourseModel(userCourse);
    }

    function enroll(referralToken) {
      return CoursesResource.enroll({ catalogId: _this.catalogId, referralToken }).$promise
        .then((response) => {
          const userCourse = response.result;
          _this.setUserCourse(userCourse);

          return userCourse;
        });
    }

    /**
       * Enroll users into this course with a specific role
       *
       * @param {{firstName: string, lastName: string, email: string, externalId: number}[]} users
       * @param {number} courseRoleId
       * @returns {Promise<{
            userManagement: {
              usersCount: number,
              enrolledUsersCount: number,
              rolesChangedCount: number,
              errorMsg: null | string
              isSucceeded: number
            }
          }>}
        */
    function enrollWithRole(users, courseRoleId, scheduledOn = null, managedByCsv) {
      courseRoleId = courseRoleId > 0 ? courseRoleId : null;
      const enrollUsers = {
        catalogId: this.catalogId,
        users,
        courseRoleId,
        scheduledOn,
        managedByCsv,
      };
      return UserManagementResources.save({ catalogId: this.catalogId }, enrollUsers).$promise;
    }

    function bulkUnenroll(users) {
      return UserManagementResources.bulkUnenroll({ catalogId: this.catalogId }, {
        users,
      }).$promise;
    }

    function reenroll() {
      return CoursesResource.reenroll({ catalogId: _this.catalogId }).$promise
        .then((response) => {
          const userCourse = response.result;
          _this.setUserCourse(userCourse);

          return userCourse;
        });
    }

    function assignMentee(mentorId, menteeEmail) {
      return MentoringsResource.save({ catalogId: _this.catalogId }, {
        userId: mentorId,
        overwrite: false,
        mentees: [{
          email: menteeEmail,
        }],
      }).$promise;
    }

    /**
       * Assign mentees to users in the course
       * @param { Array<{email: string, }> } mentorsToMentees
       * @param {*} overwrite
       */
    function assignMentees(mentorsToMentees, overwrite) {
      return MentoringsResource.save({ catalogId: _this.catalogId }, {
        mentorings: mentorsToMentees,
        overwrite,
      }).$promise;
    }

    function getMentees(user) {
      return MentoringsResource.getMentees({
        catalogId: _this.catalogId,
        userId: user.id,
      }).$promise;
    }

    function removeMentee(menteeRelationshipId) {
      return MentoringsResource.removeMentee({
        catalogId: _this.catalogId,
        relationshipId: menteeRelationshipId,
      }).$promise;
    }

    function unenrollUserDialog(user) {
      let key = 'USER_MANAGEMENT.UNENROLL_EXPLANATION';

      if (user.roles && RolesService.isMentor(user.roles) && user.menteesCount) {
        key = 'USER_MANAGEMENT.UNENROLL_EXPLANATION_RELATIONSHIP';
      }

      const description = $translate.instant(key,
        _.extend({ firstName: user.firstName, lastName: user.lastName },
          _this.getAliases()));

      const modalInstanceCtrl = ['$scope', function ($scope) {
        $scope.description = description;
        $scope.config = _this.config;
      }];

      const modalInstance = ConfirmationOverlays.openConfirmationModal('user_management/templates/unenroll-confirm.html', modalInstanceCtrl);

      return modalInstance.result.then(() => UserManagementResources.toggleBanned({ catalogId: _this.catalogId, userId: user.id })
        .$promise.then((res) => {
          AlertMessages.success('FORM.SUCCESS_BANG', 'PROFILE.UNENROLL_SUCCESS_ALIAS', {}, { name: user.fullName, isJourney: _this.isJourney, courseAlias: _this.getAliases().courseAlias });

          return res;
        }).catch(() => {
          AlertMessages.error('FORM.OOPS', 'FORM.ERROR_SOMETHING_WRONG');
        }));
    }

    function loadCourseCompletionCriteria() {
      return CoursesResource.getCourseCompletionCriteria({ catalogId: _this.catalogId }).$promise
        .then((response) => {
          _this.completionCriteria = response.result;
          return response.result;
        });
    }

    function getCompletionCriteriaCount() {
      const CRITERIA_COUNT = {
        3: 'triple',
        2: 'double',
        1: 'single',
      };

      if (_this.completionCriteria) {
        const requiredCriteria = _.filter([_this.completionCriteria.requiredPoints,
          _this.completionCriteria.requiredAssignmentsCount, _this.completionCriteria.totalRequiredTodosCount], (count) => count !== 0);

        return requiredCriteria?.length ? CRITERIA_COUNT[requiredCriteria.length] : 0;
      }

      return null;
    }

    function isDecayEnabled() {
      return _this.gamificationEnabled && _this.expiringPointsEnabled;
    }

    function requiredPoints() {
      return _this.completionCriteria ? _this.completionCriteria.requiredPoints : 0;
    }

    function requiredAssignmentsCount() {
      return _this.completionCriteria ? _this.completionCriteria.requiredAssignmentsCount : 0;
    }

    function requiredTodosCount() {
      return _this.completionCriteria ? _this.completionCriteria.totalRequiredTodosCount : 0;
    }

    function getPendoProperties() {
      return {
        // course related information
        catalogId: _this.catalogId,
        isDemo: _this.usedFor === 1,
        typeOfRegistration: _this.typeOfRegistrationInWords,
        isProgram: _this.isProgram,
        groupsRelease: _this.hasGroups,
        courseLongTeamsReleased: _this.hasCourseLongTeams,
        inArchiveMode: _this.userCourse ? _this.userCourse.accessInArchiveMode : null,

        // mentor context
        // NOTE: this is a fix for sentry issue 1626354597, where, when a user enrolls, the course object from the backend has no mentor field on it
        hasAnAssignedMentor: !!(_this.userCourse?.mentors?.length),

        // progress data
        hasCompleted: _this.userCourse ? _this.userCourse.receivesStatementOfAccomplishment : false,
        pointsEarned: _this.userCourse ? _this.userCourse.leaderboardPoints : 0,
      };
    }

    function getAliases() {
      return getCourseAliases(_this);
    }

    function updateFromReact(newModelData) {
      $timeout(() => {
        mergeWith(this, newModelData, replaceArrays);
      });
    }
  };

  CourseModel.getCourseCompletionCriteria = function (catalogId) {
    return CoursesResource.getCourseCompletionCriteria({ catalogId }).$promise
      .then((response) => response.result);
  };

  CourseModel.sortByUrgencyToAccess = function (a, b) {
    if (a.inProgress && !b.inProgress) {
      return -1;
    } if (!a.inProgress && b.inProgress) {
      return 1;
    }

    if (!a.endDate && b.endDate) {
      return -1;
    } if (a.endDate && !b.endDate) {
      return 1;
    }

    if (a.endDate > b.endDate) {
      return -1;
    } if (a.endDate < b.endDate) {
      return 1;
    }

    if (!a.releaseDate && b.releaseDate) {
      return -1;
    } if (a.releaseDate && !b.releaseDate) {
      return 1;
    }

    if (a.releaseDate > b.releaseDate) {
      return -1;
    } if (a.releaseDate < b.releaseDate) {
      return 1;
    }

    if (!a.createdAt && b.createdAt) {
      return -1;
    } if (a.createdAt && !b.createdAt) {
      return 1;
    }

    if (a.createdAt > b.createdAt) {
      return -1;
    } if (a.createdAt < b.createdAt) {
      return 1;
    }

    if (a.id > b.id) {
      return -1;
    }

    return 1;
  };

  function extendProfileRequirements(profileRequirementData) {
    _.each(this.profileRequirements, (pr) => {
      _.extend(pr, profileRequirementData);
    });
  }

  function addProfileRequirement(profileRequirementData) {
    if (!this.profileRequirements) {
      this.profileRequirements = [];
    }

    if (profileRequirementData.id) {
      const profileRequirement = this.profileRequirements.find((pr) => pr.id === profileRequirementData.id);
      if (profileRequirement) {
        _.extend(profileRequirement, profileRequirementData);
      }
    } else {
      this.profileRequirements.push(profileRequirementData);
    }
  }

  function removeProfileRequirementOfLecturePage(lecturePageId) {
    this.profileRequirements = this.profileRequirements.filter((pr) => pr.lecturePageId === lecturePageId);
  }

  function removeTeamSet() {
    this.hasCourseLongTeamSet = false;
    this.courseLongTeamSet = null;

    // Removing team from redux
    store.dispatch(unsetCurrentCourseCourseLongTeamSet());
  }

  function removeGroupTeamSet() {
    this.groupTeamSet = null;

    // Removing group from redux
    store.dispatch(unsetCurrentCourseGroupTeamSet());
  }

  CourseModel.VERSION = {
    CLASSIC: 'classic',
    NEW: '2.0',
  };

  CourseModel.USED_FOR_PRODUCTION = 0;
  CourseModel.USED_FOR_DEMO = 1;
  CourseModel.USED_FOR_PRIMARY = 2;

  CourseModel.STANDARD_FEATURES = 0;
  CourseModel.PREMIUM_FEATURES = 1;

  CourseModel.FREE_ENROLLMENT = 0;
  CourseModel.PAID_ENROLLMENT = 1;
  CourseModel.FREE_AND_PAID_CERTIFICATE_ENROLLMENT = 2;
  CourseModel.CLOSED_ENROLLMENT = 3;
  CourseModel.FREE_ENROLLMENT_IN_INSTITUTION = 4;
  CourseModel.OPEN_BASED_ON_ENTITLEMENTS = 5;

  CourseModel.REGISTRATION_TYPES = [
    CourseModel.FREE_ENROLLMENT,
    CourseModel.FREE_ENROLLMENT_IN_INSTITUTION,
    CourseModel.OPEN_BASED_ON_ENTITLEMENTS,
    CourseModel.PAID_ENROLLMENT,
    CourseModel.FREE_AND_PAID_CERTIFICATE_ENROLLMENT,
    CourseModel.CLOSED_ENROLLMENT,
  ];

  CourseModel.getActiveStudentsForCourse = function (catalogId) {
    const deferred = $q.defer();
    CoursesResource.getActiveStudentsPath({ catalogId }).$promise
      .then((response) => {
        $http({
          url: response.result,
          method: 'GET',
        }).then((getResponse) => {
          deferred.resolve(getResponse.data);
        });
      });

    return deferred.promise;
  };

  CourseModel.getTeachingTeamMembersForCourse = function (catalogId) {
    return CoursesResource.getTeachingTeamMembers({ catalogId }).$promise
      .then((response) => {
        const teachingTeamMembers = _.each(response.result, (user) => {
          user.id = user.userId;
        });
        return teachingTeamMembers;
      });
  };

  return CourseModel;
}

/* @ngInject */
export function getCourseAliases(courseData) {
  return {
    assignmentAlias: courseData.assignmentName.downcasedSingularized,
    assignmentsAlias: courseData.assignmentName.downcasedPluralized,
    AssignmentAlias: courseData.assignmentName.capitalizedSingularized,
    AssignmentsAlias: courseData.assignmentName.capitalizedPluralized,
    AssignmentTitleAlias: courseData.assignmentName.singularizedTitleized,
    AssignmentsTitleAlias: courseData.assignmentName.pluralizedTitleized,

    courseAlias: courseData.offeringName.downcasedSingularized,
    coursesAlias: courseData.offeringName.downcasedPluralized,
    CourseAlias: courseData.offeringName.capitalizedSingularized,
    CoursesAlias: courseData.offeringName.capitalizedPluralized,
    CourseTitleAlias: courseData.offeringName.singularizedTitleized,
    CoursesTitleAlias: courseData.offeringName.pluralizedTitleized,

    lectureAlias: courseData.lectureName.downcasedSingularized,
    lecturesAlias: courseData.lectureName.downcasedPluralized,
    LectureAlias: courseData.lectureName.capitalizedSingularized,
    LecturesAlias: courseData.lectureName.capitalizedPluralized,
    LectureTitleAlias: courseData.lectureName.singularizedTitleized,
    LecturesTitleAlias: courseData.lectureName.pluralizedTitleized,

    learnerAlias: courseData.learnersName.downcasedSingularized,
    learnersAlias: courseData.learnersName.downcasedPluralized,
    LearnerAlias: courseData.learnersName.capitalizedSingularized,
    LearnersAlias: courseData.learnersName.capitalizedPluralized,
    LearnerTitleAlias: courseData.learnersName.singularizedTitleized,
    LearnersTitleAlias: courseData.learnersName.pluralizedTitleized,

    pointAlias: courseData.pointsName.downcasedSingularized,
    pointsAlias: courseData.pointsName.downcasedPluralized,
    PointAlias: courseData.pointsName.capitalizedSingularized,
    PointsAlias: courseData.pointsName.capitalizedPluralized,
    PointTitleAlias: courseData.pointsName.singularizedTitleized,
    PointsTitleAlias: courseData.pointsName.pluralizedTitleized,

    teamAlias: courseData.teamName.downcasedSingularized,
    teamsAlias: courseData.teamName.downcasedPluralized,
    TeamAlias: courseData.teamName.capitalizedSingularized,
    TeamsAlias: courseData.teamName.capitalizedPluralized,
    TeamTitleAlias: courseData.teamName.singularizedTitleized,
    TeamsTitleAlias: courseData.teamName.pluralizedTitleized,

    teachingTeamAlias: courseData.teachingTeamName.downcasedSingularized,
    teachingTeamsAlias: courseData.teachingTeamName.downcasedPluralized,
    TeachingTeamAlias: courseData.teachingTeamName.capitalizedSingularized,
    TeachingTeamsAlias: courseData.teachingTeamName.capitalizedPluralized,
    TeachingTeamTitleAlias: courseData.teachingTeamName.singularizedTitleized,
    TeachingTeamsTitleAlias: courseData.teachingTeamName.pluralizedTitleized,

    groupAlias: courseData.groupName.downcasedSingularized,
    groupsAlias: courseData.groupName.downcasedPluralized,
    GroupAlias: courseData.groupName.capitalizedSingularized,
    GroupsAlias: courseData.groupName.capitalizedPluralized,
    GroupTitleAlias: courseData.groupName.singularizedTitleized,
    GroupsTitleAlias: courseData.groupName.pluralizedTitleized,
  };
}

