/* @ngInject */
export default function TeamAddMembersCtrl(
  TeamWorkspaceManager,
  TeamManager,
  CurrentUserManager,
  $stateParams,
  $scope,
  _,
) {
  class TeamRecentPostsController {
    constructor() {
      const { catalogId } = $stateParams;
      const { teamId, translateValuesForTeam, onClose, addMembers } = $scope;
      this.teamId = teamId;
      this.translateValuesForTeam = translateValuesForTeam;
      this.onClose = onClose;
      this.addMembers = addMembers;

      const course = CurrentUserManager.coursesHashByCatalogId[catalogId];
      this.numShown = 10;
      this.selectedMember = null;
      this.newMembers = [];

      this.TeamWorkspaceManager = TeamWorkspaceManager;
      this.TeamManager = TeamManager;

      this.TeamManager.requestTeam(catalogId, teamId);

      course.getActiveStudentsNotInTeams(teamId).then((usersHash) => {
        this.users = _.map(_.keys(usersHash), (userId) => usersHash[userId]);
      });
    }

    addMember(item, model) {
      if (!this.newMembers.includes(this.selectedMember)) {
        this.newMembers.push(this.selectedMember);
      }
      this.selectedMember = null;
    }

    removeMember(member) {
      this.newMembers.splice(_.indexOf(this.newMembers, member), 1);
    }

    submit(members) {
      this.addMembers({ catalogId: $stateParams.catalogId, teamId: this.teamId, teamMembers: members });
      this.onClose();
    }
  }

  return new TeamRecentPostsController();
}
