import { css } from '@emotion/react';
import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import t from 'react-translate';
import { Button } from 'react-bootstrap';
import ClickableContainer from 'components/clickable-container';
import NvFlyoutModal, { ModalType } from 'shared/components/nv-flyout-modal';
import { SectionSubsection } from 'redux/schemas/models/learner-progress';
import { getCourseAliases } from 'redux/selectors/course';
import { gray6, white } from 'styles/global_defaults/colors';
import { tripleSpacing } from 'styles/global_defaults/scaffolding';
import NvTooltip from 'shared/components/nv-tooltip';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import { config } from '../../../config/pendo.config.json';


type CustomizationFlyoutModalProps = {
  showModal: boolean,
  onClose: () => void,
  allSections: SectionSubsection[],
};

const CustomizationFlyoutForm = forwardRef<any, any>(({
  onClose,
  catalogId,
  onSubmit,
  allSections,
  initialSections,
  defaultSections,
}, ref) => {
  const styles = css`
    .header {
      height: ${tripleSpacing}px;
      border-bottom: 1px solid ${gray6};

      position: sticky;
      top: 0;
      background: ${white};
      z-index: 10;
    }

    .customization-buttons {
      position: sticky;
      top: ${tripleSpacing}px;
      background: ${white};
      z-index: 10;
    }
  `;

  const aliases = useSelector((state) => getCourseAliases(state));

  const controllerRef = useRef<HTMLDivElement>();

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      selectedSections: initialSections,
    },
  });

  useImperativeHandle(ref, () => form);

  const {
    control,
    setValue,
    formState,
    getValues,
    handleSubmit,
  } = form;

  const resetToDefault = () => {
    setValue('selectedSections', defaultSections);
  };

  const applyFilters = (formData) => {
    onSubmit(formData.selectedSections);

    onClose();
  };

  const onSelectParent = (id, isSelected) => {
    const { selectedSections } = getValues();

    const newState = {
      ...selectedSections,
      [id]: {
        ...selectedSections[id],
        checked: isSelected,
      },
    };
    Object.keys(selectedSections[id].lectureSubsections).forEach((subsectionId) => {
      newState[id].lectureSubsections[subsectionId].checked = isSelected;
    });

    setValue('selectedSections', newState);
  };

  const onSelectChild = (id, parentId, isSelected) => {
    const { selectedSections } = getValues();

    const newState = {
      ...selectedSections,
      [parentId]: {
        ...selectedSections[parentId],
        lectureSubsections: {
          ...selectedSections[parentId]?.lectureSubsections,
          [id]: {
            ...selectedSections[parentId]?.lectureSubsections[id],
            checked: isSelected,
          },
        },
      },
    };
    if (!newState[parentId].hasLessonChildren) {
      const checks = Object.values(newState[parentId].lectureSubsections).map((subSec: SectionSubsection) => subSec.checked);
      if (!checks.includes(!isSelected)) {
        newState[parentId].checked = isSelected;
      }
    }

    setValue('selectedSections', newState);
  };

  return (
    <div css={styles} ref={controllerRef}>
      <Controller
        name='selectedSections'
        control={control}
        render={(data) => (
          <Fragment>
            <div className='header d-flex justify-content-center align-items-center text-large-regular font-weight-bolder'>{t.LEARNER_PROGRESS.CUSTOMIZE_TABLE.CUSTOMIZE()}</div>
            <div className='customization-buttons d-flex justify-content-between align-items-center pt-2 pb-4 px-4'>
              <NvTooltip text={t.LEARNER_PROGRESS.CUSTOMIZE_TABLE.RESET_TOOLTIP()}>
                <ClickableContainer onClick={() => resetToDefault()} className='text-primary' data-qa={config.pendo.learnerProgress.customizationFlyout.resetToDefault}>
                  {t.LEARNER_PROGRESS.CUSTOMIZE_TABLE.RESET_TO_DEFAULT()}
                </ClickableContainer>
              </NvTooltip>
              <Button onClick={handleSubmit(applyFilters)} data-qa={config.pendo.learnerProgress.customizationFlyout.applyFilters}>{t.LEARNER_PROGRESS.CUSTOMIZE_TABLE.APPLY_FILTERS()}</Button>
            </div>
            <div className='px-4 pb-1 font-weight-bolder'>{t.LEARNER_PROGRESS.CUSTOMIZE_TABLE.DESCRIPTION({ ...aliases.learnersAliases })}</div>
            {allSections.map((section) => (
              <div className='mx-4'>
                <NvCheckbox
                  name={`section-${section.id}`}
                  label={section.title}
                  labelClassName='text-regular ml-3 pl-5 pt-3'
                  checked={getValues().selectedSections[section.id]?.checked}
                  onChange={(e) => onSelectParent(section.id, !!e.target.checked)}
                />
                {!!section.lectureSubsections.length && section.lectureSubsections.map((subsection) => (
                  <div className='ml-3'>
                    <NvCheckbox
                      name={`section-${subsection.id}`}
                      label={subsection.title}
                      labelClassName='text-regular ml-5 pl-5 pt-2'
                      checked={getValues().selectedSections[section.id]?.lectureSubsections?.[subsection.id]?.checked}
                      onChange={(e) => onSelectChild(subsection.id, section.id, !!e.target.checked)}
                    />
                  </div>
                ))}
              </div>
            ))}
          </Fragment>
        )}
      />
    </div>
  );
});

const CustomizationFlyoutModal = ({ onClose, showModal, catalogId, onSubmit, allSections = [] }) => {
  const selected = [];
  allSections.forEach((section) => {
    if (section.hasLessonChildren) {
      selected.push({ ...section });
    }
    selected.push(...section.lectureSubsections);
  });
  selected.sort((secA, secB) => moment(secB.releaseDate).diff(moment(secA.releaseDate)));
  const selectedIds = selected.slice(0, 5).map((section) => section.id);

  const defaultSections = Object.fromEntries(allSections.map((sec) => ([
    sec.id, {
      ...sec,
      checked: selectedIds.includes(sec.id),
      lectureSubsections: Object.fromEntries(sec.lectureSubsections.map((subSec) => ([
        subSec.id, {
          ...subSec,
          checked: selectedIds.includes(subSec.id),
        },
      ]))),
    },
  ])));

  let initialSections;
  const initialSectionIds = JSON.parse(localStorage.getItem(`learner-progress-dashboard-${catalogId}-section-ids`));
  if (initialSectionIds) {
    initialSections = Object.fromEntries(allSections.map((sec) => ([
      sec.id, {
        ...sec,
        checked: initialSectionIds.includes(sec.id),
        lectureSubsections: Object.fromEntries(sec.lectureSubsections.map((subSec) => ([
          subSec.id, {
            ...subSec,
            checked: initialSectionIds.includes(subSec.id),
          },
        ]))),
      },
    ])));
  }

  return (
    <Fragment>
      {showModal && (
        <NvFlyoutModal
          type={ModalType.RIGHT}
          // this header attribute is required, but seems to be unused. Consider removing
          header='header'
          width={360}
          onClose={onClose}
        >
          <CustomizationFlyoutForm
            onClose={onClose}
            catalogId={catalogId}
            onSubmit={onSubmit}
            allSections={allSections}
            initialSections={initialSections ?? defaultSections}
            defaultSections={defaultSections}
          />
        </NvFlyoutModal>
      )}
    </Fragment>
  );
};

export default CustomizationFlyoutModal;
