/* @ngInject */
export default function TableShortTextQuestionService(

  _,
  ShortTextQuestion,
) {
  const TableShortTextQuestion = function (attributes) {
    const _this = this;
    const base = {
      childrenQuestionsTable: [],
      templateUrl: 'quizzes/templates/questions/nv-table-short-text-question.html',
      displayState: null,
    };
    let correctAnswer;
    let incorrectResponsesCount = 0;
    const correctAnswersCount = 0;


    /** Setting Up Basic Attributes * */
    _.extend(_this, base, attributes);

    preprocess();

    /** Public Functions * */
    _this.isUnAnswered = isUnAnswered;
    _this.responsePayload = responsePayload;
    _this.setResponse = setResponse;
    _this.resetResponse = resetResponse;
    _this.updateResponseOptions = updateResponseOptions;
    _this.getCorrectAnswer = getCorrectAnswer;
    _this.getResponse = getResponse;
    _this.closePopover = closePopover;

    /** Private Functions * */
    function preprocess() {
      // convert question
      _this.childrenQuestions = _.map(_this.childrenQuestions, (question) => new ShortTextQuestion(question));

      // make response options array into a 2d array
      _.times(_this.numRows, () => {
        _this.childrenQuestionsTable.push([]);
      });

      _.each(_this.childrenQuestions, (question) => {
        _this.childrenQuestionsTable[window.Math.floor(question.questionIndex / _this.numColumns)].push(question);
      });

      calculateAnswer();
    }

    function getCorrectAnswer() {
      return _this.correctAnswer;
    }

    function getResponse() {
      return _this.response;
    }

    function calculateAnswer() {
      // Count if correct answers provided and use that to hide table when displaying quiz answers
      _this.correctAnswersCount = 0;
      _.each(_this.childrenQuestions, (question) => {
        if (!question.responseOptions[0].readOnly && question.responseOptions[0].optionContent) {
          _this.correctAnswersCount += 1;
        }
      });

      _this.correctAnswer = {
        correctAnswersCount: _this.correctAnswersCount,
        childrenQuestionsTable: _this.childrenQuestionsTable,
      };
    }

    function closePopover() {
      _this.response.isSummaryPopoverVisible = false;
    }

    function isUnAnswered() {
      return _.some(_this.childrenQuestions, (question) => {
        if (!question.responseOptions[0].readOnly) {
          return question.isUnAnswered();
        }
        return false;
      });
    }

    function responsePayload() {
      const payload = {};

      _.each(_this.childrenQuestions, (question) => {
        payload[question.questionIndex] = question.responsePayload();
      });

      return payload;
    }

    function updateResponseOptions(question) {
      _.each(_this.childrenQuestions, (childQuestion) => {
        const newChildQuestion = _.findWhere(question.childrenQuestions, { id: childQuestion.id });
        childQuestion.updateResponseOptions(newChildQuestion);
      });

      calculateAnswer();
    }

    function setResponse(response) {
      incorrectResponsesCount = 0;
      _.each(_this.childrenQuestions, (question) => {
        const individualResponse = _.findWhere(response.childrenQuestionResponses, { questionId: question.id });

        if (!question.responseOptions[0].readOnly) {
          question.setResponse(individualResponse);

          if (!individualResponse.isCorrect) {
            incorrectResponsesCount += 1;
          }
        }
      });

      _this.currentResponse = response;

      _this.response = {
        incorrectResponsesCount,
        isSummaryPopoverVisible: true,
        childrenQuestionsTable: _this.childrenQuestionsTable,
      };
    }

    function resetResponse() {
      _.each(_this.childrenQuestions, (question) => {
        question.resetResponse();
      });
    }
  };

  return TableShortTextQuestion;
}
