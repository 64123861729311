import { CourseAdminMenu } from '../../course_home/components/course-admin-menu';
import AutomatedTranslationMenu from '../../course_home/components/automated-translation-menu';

/* @ngInject */
export default function NvHeaderPageLinks(
  $state,
  $stateParams,
  $uibModal,
  CurrentCourseManager,
  CurrentPermissionsManager,
  CurrentUserManager,
  LecturePageManager,
  RailsRoutes,
  TimelinesManager,
  _,
  config,
  nvCurrentPage,
) {
  return {
    restrict: 'EA',
    /* eslint-disable complexity */
    link(scope, elem, attrs) {
      scope.$stateParams = $stateParams;
      scope.currentState = $state.current.name;
      scope.nvCurrentPage = nvCurrentPage;
      scope.RailsRoutes = RailsRoutes;
      scope.CurrentUserManager = CurrentUserManager;
      scope.CurrentCourseManager = CurrentCourseManager;
      scope.TimelinesManager = TimelinesManager;
      scope.moreActionsDropdownIsOpen = false;
      scope.config = config;

      scope.CourseAdminMenu = CourseAdminMenu;
      scope.AutomatedTranslationMenu = AutomatedTranslationMenu;
      scope.showAutomatedTranslationMenu = scope.CurrentCourseManager.course.automatedTranslationEnabled && scope.CurrentCourseManager.course.institution.automatedTranslationEnabled;

      scope.fontColor = nvCurrentPage.getFontColor();

      scope.isExpanded = attrs.isExpanded;

      scope.isProgram = nvCurrentPage.getCourse() ? nvCurrentPage.getCourse().isProgram : false;
      scope.isInProgram = nvCurrentPage.isInProgram();
      scope.filterCoursesInProgram = filterCoursesInProgram;

      scope.coursesInProgram = null;
      scope.coursesInProgramFiltered = null;
      scope.canEditBasics = CurrentPermissionsManager.isConfigAndRegistrationRole();
      scope.isEditOrReorderMode = () => LecturePageManager.isEditMode()
        || LecturePageManager.isReorderMode()
        || TimelinesManager.isEditMode()
        || TimelinesManager.isReorderMode();

      scope.desktopMoreActionsVisible = !nvCurrentPage.isInstitutionPage()
          && (nvCurrentPage.hasTeams()
          || nvCurrentPage.hasGroups()
          || nvCurrentPage.hasLearnersNav()
          || nvCurrentPage.hasTeachingTeamNav()
          || scope.isInProgram);

      scope.mobileMoreActionsVisible = !nvCurrentPage.isInstitutionPage()
          && (nvCurrentPage.hasTeams()
          || nvCurrentPage.hasGroups()
          || nvCurrentPage.hasLearnersNav()
          || nvCurrentPage.hasTeachingTeamNav()
          || nvCurrentPage.hasGallery()
          || nvCurrentPage.hasDiscussions()
          || nvCurrentPage.hasHelpWidget()
          || nvCurrentPage.hasLeaderboard()
          || scope.isInProgram || (scope.MainGridCtrl.hasRhs && nvCurrentPage.hasCommunity()));

      // Quick Access is only hidden to TAs. We're probably missing some cases here, needs refactored
      scope.isQuickAccessVisible = CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isCourseBuilder()
            || CurrentPermissionsManager.isReportAdmin() || CurrentPermissionsManager.isConfigAndRegistrationRole();

      scope.$watch('CurrentCourseManager.course.courseLongTeamSet', () => {
        scope.manageTeamsVisible = !!(CurrentCourseManager.course?.courseLongTeamSet)
          && (CurrentPermissionsManager.isConfigAndRegistrationRole() || CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isCourseBuilder());
      });
      scope.$watch('CurrentCourseManager.course.groupTeamSet', () => {
        scope.manageGroupsVisible = !!(CurrentCourseManager.course?.groupTeamSet)
          && (CurrentPermissionsManager.isConfigAndRegistrationRole() || CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isCourseBuilder());
      });

      scope.$watch('CurrentCourseManager.course.automatedTranslationEnabled', () => {
        scope.showAutomatedTranslationMenu = scope.CurrentCourseManager.course.automatedTranslationEnabled && scope.CurrentCourseManager.course.institution.automatedTranslationEnabled;
      });
      scope.$watch('CurrentCourseManager.course.institution.automatedTranslationEnabled', () => {
        scope.showAutomatedTranslationMenu = scope.CurrentCourseManager.course.automatedTranslationEnabled && scope.CurrentCourseManager.course.institution.automatedTranslationEnabled;
      });

      // initialization if in a program
      if (scope.isInProgram) {
        if (CurrentUserManager.programsHash
            && CurrentUserManager.programsHash[nvCurrentPage.getParentProgram().id]) {
          scope.coursesInProgram = CurrentUserManager.programsHash[nvCurrentPage.getParentProgram().id].coursesInProgram;
        }

        if (scope.coursesInProgram?.length) {
          scope.coursesInProgramFiltered = filterCoursesInProgram(scope.coursesInProgram);
        }
      } else if (scope.isProgram) {
        if (CurrentUserManager.programsHash
            && CurrentUserManager.programsHash[nvCurrentPage.getCourse().id]) {
          scope.coursesInProgram = CurrentUserManager.programsHash[nvCurrentPage.getCourse().id].coursesInProgram;
        }
      }

      scope.toggleMoreActionsDropdown = function (open) {
        scope.moreActionsDropdownIsOpen = open;
      };

      scope.openHelpWidgetModal = function () {
        return $uibModal.open({
          templateUrl: 'shared/templates/help-widget-modal.html',
          controller: 'NvModalCtrl',
          controllerAs: 'vm',
          resolve: {
            modalOptions() {
              return {
                disableConfirm: true,
                disableCancel: true,
                model: {
                  helpWidget: scope.nvCurrentPage.getHelpWidget(),
                },
              };
            },
          },
        });
      };

      function filterCoursesInProgram(courses) {
        return _.filter(courses, (courseModel) => courseModel.id !== nvCurrentPage.getCourse().id);
      }
    },
    templateUrl: 'shared/templates/nv-header-page-links.html',
  };
}
