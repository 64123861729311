// eslint-disable-next-line import/prefer-default-export
export enum SortAttr {
  LAST_ACTIVITY_DATE = 'last_activity_date',
  RELEASE_DATE = 'release_date',
  START_DATE = 'official_release_date',
  END_DATE = 'end_date',
  CLOSE_DATE = 'close_date',
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}
