
/* @ngInject */
export default function UserEnrollmentDetailPageHeaderController(
  CurrentUserManager,
  DashboardManager,
  $state,
) {
  const vm = this;
  vm.$state = $state;

  vm.hasEnrollments = () => (CurrentUserManager.user?.enrollments?.length > 0
      || DashboardManager?.discoveryCourses?.length > 0);
}
