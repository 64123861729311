import React from 'react';
import { AngularContext } from 'react-app';

/**
 * Hook to watch an specific value from Angular to React
 * @param cb the callback with the value that needs to be watched
 * @returns the updated value
 */
export const useAngularExpression = <T extends unknown>(cb: () => T) => {
  const { $scope } = React.useContext(AngularContext);
  const cbRef = React.useRef<() => T>();
  cbRef.current = cb;
  const [value, setValue] = React.useState(cb());

  React.useEffect(() => {
    const callback = () => cbRef.current();

    return $scope.$watch(callback, (newValue) => {
      setValue(newValue);
    });
  }, [$scope]);

  return value;
};

export default useAngularExpression;
