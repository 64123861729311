/* @ngInject */
export default function BookmarkResources($resource) {
  return $resource(
    '/bookmarks/:id.json',
    {},
    {
      createBookmark: { method: 'POST', url: 'bookmarks.json' },
      updateBookmark: { method: 'PATCH', url: 'bookmarks/:id.json' },
      deleteBookmark: { method: 'DELETE', url: 'boomkarks/:id.json' },
    },
  );
}
