/* @ngInject */
export default function NvOnScroll(
  _,
) {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      const callback = scope.$eval(attrs.nvOnScroll);
      const throttled = _.throttle(() => {
        callback();
      }, 100);

      element.scroll(throttled);
    },
  };
}
