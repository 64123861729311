
import { css } from '@emotion/react';
import { ComponentType, useState } from 'react';
import t from 'react-translate';
import { AccordionSectionLectureComponent } from 'redux/schemas/models/lecture-component';
import { LecturePage, NLecturePage } from 'redux/schemas/models/lecture-page';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import SectionContent from './section-content';
import { applyViewOptionsToAllSectionsOptions, SectionContainerProps } from './accordion-types';
import { LecturePageMode } from '..';
import { copyTitleStyle } from './accordion-lecture-component';

interface SectionProps<T extends AccordionSectionLectureComponent> {
  sectionData: T;
  accordionSections: T[];
  index: number;
  SectionContainerComponent: ComponentType<SectionContainerProps<any>>;
  mode: LecturePageMode;
  onAccordionSectionChange: (accordionSectionData: T) => void;
  currentLecture: NLecturePage;
  // determines if the section will be expanded by default. This value is only
  // read the initial render and will be ignore for the following renders
  autoExpand: boolean;
}

/* @ngInject */
export default function AccordionSection<T extends AccordionSectionLectureComponent>(props: SectionProps<T>) {
  const [expanded, setExpanded] = useState<boolean>(props.autoExpand);

  const styles = css`
    margin-left: -${standardSpacing}px;
    padding-left: ${standardSpacing}px;
    .drag-handle {
      display: none;
      width: ${standardSpacing}px;
    }
    &:hover .drag-handle {
      display: block;
      position: relative;
      height: 0;
      top: 25px;
      right: 25px;
      cursor: pointer;
    }
  `;

  const { SectionContainerComponent } = props;

  // #region handlers
  function toggleExpanded() {
    setExpanded(!expanded);
  }

  function onHeaderChange(newHeader: string) {
    if (newHeader !== props.sectionData.header) {
      props.onAccordionSectionChange({
        ...props.sectionData,
        header: newHeader,
      });
    }
  }

  function onContentChange(newContent: string) {
    props.onAccordionSectionChange({
      ...props.sectionData,
      content: newContent,
    });
  }

  function onViewOptionsChange(newViewOptions: Partial<AccordionSectionLectureComponent['viewOptions']>) {
    props.onAccordionSectionChange({
      ...props.sectionData,
      viewOptions: {
        ...props.sectionData.viewOptions,
        ...newViewOptions,
      },
    });
  }

  function applyViewOptionsToAllSections(
    baseViewOptions: Partial<T['viewOptions']>,
    options?: applyViewOptionsToAllSectionsOptions,
  ) {
    props.accordionSections.forEach((currentSection) => {
      // skip the current section
      if (currentSection.id !== props.sectionData.id) {
        const divElement = document.createElement('div');
        divElement.innerHTML = currentSection.header;
        const titleTextContent = divElement.textContent;

        props.onAccordionSectionChange({
          ...currentSection,
          picture: options?.includePicture ? props.sectionData.picture : currentSection.picture,
          header: options?.includeHeaderTextFormat
            ? copyTitleStyle(props.sectionData.header, titleTextContent)
            : currentSection.header,
          viewOptions: {
            ...currentSection.viewOptions,
            ...baseViewOptions,
          },
        });
      }
    });
  }
  // #endregion

  return (
    <div className='accordion-lecture-component-section' css={styles}>
      {props.mode === LecturePageMode.EDIT && (
        <div className='drag-handle'>
          <NvTooltip text={t.LECTURE_PAGES.COMPONENTS.ACCORDION.DRAG_AND_DROP_TO_REORDER()}>
            <NvIcon size='small' icon='reorder' />
          </NvTooltip>
        </div>
      )}
      <SectionContainerComponent
        onAccordionSectionChange={props.onAccordionSectionChange}
        currentLecture={props.currentLecture}
        autoFocusHeader={props.autoExpand}
        applyViewOptionsToAllSections={applyViewOptionsToAllSections}
        index={props.index}
        sectionData={props.sectionData}
        toggleExpanded={toggleExpanded}
        expanded={expanded}
        isLastSection={props.index === props.accordionSections.length - 1}
        isOnlySection={props.accordionSections.length === 1}
        mode={props.mode}
        onHeaderChange={onHeaderChange}
        onViewOptionsChange={onViewOptionsChange}
        renderSectionContent={(sectionContentProps) => (
          // this component render the Rich Text of the section content. Using
          // this render prop patterns allows the SectionContainerComponent to
          // pass extra props to customize further the style depending on the
          // current accordion style
          <SectionContent
            content={props.sectionData.content}
            expanded={expanded}
            mode={props.mode}
            onContentChange={onContentChange}
            {...sectionContentProps}
          />
        )}
      />
    </div>
  );
}
