// ideally we'd only modify the attribute right after interpolation occurs, but that will trigger an infinite cycle of the 2 directives modifying the attribute
// this solution should be good enough for us

/* @ngInject */
export default function PopoverPlacement(
  nvUtil,
) {
  return {
    restrict: 'A',
    compile: function compile(tElement, tAttrs, transclude) {
      // In Rtl there are some cases which we dont need to flip the popover so it will be decided by checking the `no-flip` attr.
      if (nvUtil.isRtl() && tAttrs.noFlip !== 'true') {
        tAttrs.$set('popoverPlacement', nvUtil.swapLeftRight(tAttrs.popoverPlacement));
      }
    },
  };
}
