import { MockDefinition, MockTypes } from 'shared/axios/axios-mocks';

const CommunicationsMocks: MockDefinition[] = [
  {
    type: MockTypes.POST,
    url: '/first_journey/course_communications.json',
    status: 200,
    response: {
      result: {
        id: 23,
        journey_id: 1,
        owner_id: null,
        owner_type: null,
        state: 'Scheduled',
        submitted: false,
        has_errors: false,
        error_codes: null,
        communication_type: 'scheduled_manual_email',
        edited_by: {
          full_name: 'Isael Duran Admin',
          updated_at: '2023-06-17T08:37:58.138Z',
          is_edited: false,
        },
        enable_push_notification: false,
        push_notification_body: null,
        filters: {
          never_active: true,
        },
        communication: {
          body: null,
          subject: null,
        },
        recipients: [
          'students',
        ],
        scheduled_for: 'send_now',
        scheduled_at: '2023-06-17T08:37:57.922Z',
        communication_admin: null,
      },
      message: 'Successfully completed the request',
    },
  },
  {
    type: MockTypes.PUT,
    url: '/first_journey/course_communications/23.json',
    status: 200,
    response: {
      result: {
        id: 23,
        journey_id: 1,
        owner_id: null,
        owner_type: null,
        state: 'Scheduled',
        submitted: false,
        has_errors: false,
        error_codes: null,
        communication_type: 'scheduled_manual_email',
        edited_by: {
          full_name: 'Isael Duran Admin',
          updated_at: '2023-06-17T08:37:58.138Z',
          is_edited: false,
        },
        enable_push_notification: false,
        push_notification_body: null,
        filters: {
          never_active: true,
          // not_completed_specific_collection: {
          //   id: 3,
          //   value: 3,
          // },
          has_completed_journey: true,
        },
        communication: {
          body: null,
          subject: null,
        },
        recipients: [
          'students',
        ],
        scheduled_for: 'send_now',
        scheduled_at: '2023-06-17T08:37:57.922Z',
        communication_admin: null,
      },
      message: 'Successfully completed the request',
    },
  },
  // {
  //   type: MockTypes.PUT,
  //   url: '/first_journey/course_communications/23.json',
  //   status: 200,
  //   response: {
  //     result: {
  //       id: 35,
  //       lecture_page_id: null,
  //       course_id: 1,
  //       owner_id: null,
  //       owner_type: null,
  //       state: 'Scheduled',
  //       submitted: true,
  //       has_errors: false,
  //       error_codes: null,
  //       x_days: null,
  //       communication_type: 'scheduled_manual_email',
  //       edited_by: {
  //         full_name: 'Isael Duran Admin',
  //         updated_at: '2023-06-27T08:40:58.000Z',
  //         is_edited: true,
  //       },
  //       first_triggered_at: null,
  //       enable_push_notification: false,
  //       push_notification_body: null,
  //       filters: {
  //         never_active: true,
  //         not_completed_specific_collection: {
  //           id: 3,
  //           value: 3,
  //         },
  //       },
  //       communication: {
  //         body: '\u003cp class=\'froala-style-regular\' dir=\'ltr\'\u003efrfr\u003c/p\u003e',
  //         subject: 'fefe',
  //       },
  //       event: null,
  //       recipients: [
  //         'students',
  //       ],
  //       scheduled_for: 'send_now',
  //       scheduled_at: '2023-06-27T08:40:58.000Z',
  //       communication_admin: null,
  //     },
  //     message: 'Successfully completed the request',
  //   },
  // },
  {
    type: MockTypes.POST,
    url: '/first_journey/course_communications/send_test_email.json',
    status: 200,
    response: {
      result: true,
      message: 'Successfully completed the request',
    },
  },
  // {
  //   type: MockTypes.POST,
  //   url: '/first_course/course_communications/scheduled.json?start_time=2023-06-28T06:00:00.000Z&display=past&page=1',
  //   status: 200,
  //   response: {
  //     result: {
  //       communications: [
  //         {
  //           id: 35,
  //           lecture_page_id: null,
  //           course_id: 1,
  //           owner_id: null,
  //           owner_type: null,
  //           state: 'Sent',
  //           submitted: true,
  //           has_errors: false,
  //           error_codes: null,
  //           x_days: null,
  //           communication_type: 'scheduled_manual_email',
  //           edited_by: {
  //             full_name: 'Isael Duran Admin',
  //             updated_at: '2023-06-27T08:41:18.000Z',
  //             is_edited: true,
  //           },
  //           first_triggered_at: null,
  //           enable_push_notification: false,
  //           push_notification_body: null,
  //           filters: {
  //             id: 14,
  //             course_communication_id: 35,
  //             course_roles_list: null,
  //             days_inactive: null,
  //             days_active: null,
  //             has_completed: null,
  //             has_not_completed: null,
  //             earned_more_than_x_points: null,
  //             earned_less_than_x_points: null,
  //             completed_x_assignments_as_todo: null,
  //             has_completed_course: true,
  //             has_logged_in: null,
  //             enrolled_in_last_x_days: null,
  //           },
  //           communication: {
  //             body: '\u003cp class="froala-style-regular" dir="ltr"\u003efrfr\u003c/p\u003e',
  //             subject: 'fefe',
  //           },
  //           event: null,
  //           recipients: [
  //             'students',
  //           ],
  //           scheduled_for: 'send_now',
  //           scheduled_at: '2023-06-27T08:40:58.000Z',
  //           communication_admin: null,
  //         },
  //         {
  //           id: 22,
  //           lecture_page_id: null,
  //           course_id: 1,
  //           owner_id: null,
  //           owner_type: null,
  //           state: 'Sent',
  //           submitted: true,
  //           has_errors: false,
  //           error_codes: null,
  //           x_days: null,
  //           communication_type: 'scheduled_manual_email',
  //           edited_by: {
  //             full_name: 'Isael Duran Admin',
  //             updated_at: '2023-06-13T05:07:05.000Z',
  //             is_edited: false,
  //           },
  //           first_triggered_at: null,
  //           enable_push_notification: false,
  //           push_notification_body: null,
  //           filters: {
  //             id: 2,
  //             course_communication_id: 22,
  //             course_roles_list: [
  //               0,
  //             ],
  //             days_inactive: null,
  //             days_active: null,
  //             has_completed: null,
  //             has_not_completed: {
  //               id: 8,
  //               type: 'Exercise',
  //             },
  //             earned_more_than_x_points: null,
  //             earned_less_than_x_points: 22,
  //             completed_x_assignments_as_todo: null,
  //             has_completed_course: true,
  //             has_logged_in: null,
  //             enrolled_in_last_x_days: 8,
  //           },
  //           communication: {
  //             body: '\u003cp class="froala-style-regular" dir="ltr"\u003eas\u003c/p\u003e',
  //             subject: 'as',
  //           },
  //           event: null,
  //           recipients: [
  //             'students',
  //             'mentors',
  //           ],
  //           scheduled_for: 'send_now',
  //           scheduled_at: '2023-06-13T05:06:44.000Z',
  //           communication_admin: null,
  //         },
  //       ],
  //       count: 2,
  //       per_page: 20,
  //     },
  //     message: 'Successfully completed the request',
  //   },
  // },
  {
    type: MockTypes.POST,
    url: '/first_journey/course_communications/scheduled.json?start_time=2023-07-11T06:00:00.000Z&display=past&page=1',
    status: 200,
    response: {
      result: {
        communications: [
          {
            id: 55,
            lecture_page_id: null,
            course_id: 2,
            owner_id: null,
            owner_type: null,
            state: 'Sent',
            submitted: true,
            has_errors: true,
            error_codes: [
              'missing_not_completed_collection_filter_error',
            ],
            x_days: null,
            communication_type: 'scheduled_manual_email',
            edited_by: {
              full_name: 'Isael Duran Admin',
              updated_at: '2023-07-07T00:12:37.000Z',
              is_edited: true,
            },
            first_triggered_at: null,
            enable_push_notification: true,
            push_notification_body: 'werwerwerwer',
            filters: {
              id: 21,
              course_communication_id: 55,
              course_roles_list: null,
              days_inactive: null,
              days_active: null,
              has_completed: null,
              has_not_completed: null,
              earned_more_than_x_points: null,
              earned_less_than_x_points: null,
              completed_x_assignments_as_todo: null,
              has_completed_course: null,
              has_logged_in: null,
              enrolled_in_last_x_days: null,
              never_active: null,
              enrolled_to_course_id: null,
              not_enrolled_to_course_id: null,
              completed_course_id: null,
              not_completed_course_id: null,
              completed_collection_id: null,
              not_completed_collection_id: 3,
              has_completed_journey: null,
            },
            communication: {
              body: '\u003cp class="froala-style-regular" dir="ltr"\u003ewerwerewr\u003c/p\u003e',
              subject: 'wretret',
            },
            event: null,
            recipients: [
              'students',
            ],
            scheduled_for: 'send_now',
            scheduled_at: '2023-07-07T00:12:22.000Z',
            communication_admin: null,
          },
          {
            id: 52,
            lecture_page_id: null,
            course_id: 2,
            owner_id: null,
            owner_type: null,
            state: 'Sent',
            submitted: true,
            has_errors: true,
            error_codes: [
              'missing_not_completed_collection_filter_error',
            ],
            x_days: null,
            communication_type: 'scheduled_manual_email',
            edited_by: {
              full_name: 'Isael Duran Admin',
              updated_at: '2023-07-05T22:21:47.000Z',
              is_edited: true,
            },
            first_triggered_at: null,
            enable_push_notification: false,
            push_notification_body: null,
            filters: null,
            communication: {
              body: '\u003cp class="froala-style-regular" dir="ltr"\u003euy\u003c/p\u003e',
              subject: 'uy',
            },
            event: null,
            recipients: [
              'students',
            ],
            scheduled_for: 'send_now',
            scheduled_at: '2023-07-05T22:21:29.000Z',
            communication_admin: null,
          },
        ],
        count: 2,
        per_page: 20,
      },
      message: 'Successfully completed the request',
    },
  },
  {
    type: MockTypes.POST,
    url: '/first_journey/course_communications/scheduled.json?start_time=2023-07-11T06:00:00.000Z&display=future&page=1',
    status: 200,
    response: {
      result: {
        communications: [
          {
            id: 56,
            lecture_page_id: null,
            course_id: 2,
            owner_id: null,
            owner_type: null,
            state: 'Scheduled',
            submitted: true,
            has_errors: true,
            error_codes: [
              'missing_completed_course_filter_error',
            ],
            x_days: null,
            communication_type: 'scheduled_manual_email',
            edited_by: {
              full_name: 'Isael Duran Admin',
              updated_at: '2023-07-10T15:46:40.000Z',
              is_edited: true,
            },
            first_triggered_at: null,
            enable_push_notification: true,
            push_notification_body: 'this is a new notification',
            filters: {
              id: 23,
              course_communication_id: 56,
              course_roles_list: null,
              days_inactive: null,
              days_active: null,
              has_completed: null,
              has_not_completed: null,
              earned_more_than_x_points: null,
              earned_less_than_x_points: null,
              completed_x_assignments_as_todo: null,
              has_completed_course: null,
              has_logged_in: null,
              enrolled_in_last_x_days: null,
              never_active: null,
              enrolled_to_course_id: null,
              not_enrolled_to_course_id: null,
              completed_course_id: 1,
              not_completed_course_id: null,
              completed_collection_id: null,
              not_completed_collection_id: null,
              has_completed_journey: null,
            },
            communication: {
              body: '\u003cp class="*"froala-style-regular"*" dir="*"ltr"*"\u003emy email\u003c/p\u003e',
              subject: 'subject',
            },
            event: null,
            recipients: [
              'students',
              'course_admins',
            ],
            scheduled_for: 'scheduled',
            scheduled_at: '2023-07-12T06:00:00.000Z',
            communication_admin: null,
          },
        ],
        count: 1,
        per_page: 20,
      },
      message: 'Successfully completed the request',
    },
  },
  {
    type: MockTypes.GET,
    url: '/best_journey/course_communications/5',
    status: 200,
    response: {
      result: {
        communications: [
          {
            id: 173,
            lecture_page_id: null,
            course_id: 62,
            owner_id: null,
            owner_type: null,
            state: 'Sent',
            submitted: true,
            has_errors: false,
            error_codes: null,
            x_days: null,
            communication_type: 'automated_journey_email',
            edited_by: {
              full_name: 'Admin Novoed',
              updated_at: '2023-09-19T09:06:58.000Z',
              is_edited: false,
            },
            first_triggered_at: null,
            enable_push_notification: true,
            push_notification_body: 'Test Push',
            filters: null,
            communication: {
              body: '<p class="*"froala-style-regular"*" dir="*"ltr"*">Test Email content</p>',
              subject: 'Test Duplicate Subject',
            },
            event: null,
            recipients: [
              'students',
            ],
            scheduled_for: 'scheduled',
            scheduled_at: '2023-09-19T09:06:36.000Z',
            communication_admin: null,
          },
        ],
      },
    },
  },
];

export default CommunicationsMocks;

