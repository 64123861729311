import { ReactElement, useState, useEffect, Fragment } from 'react';
import { css } from '@emotion/react';
import prodPathReplace from 'shared/prod-path-rewrite';
import { gray4, gray3, primary } from 'styles/global_defaults/colors';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import NvPopover from '../nv-popover';

export type SelectionItemProps = {
  name: string;
  imagePrefix: string;
  selected?: boolean;
  description?: string;
  disabled?: boolean;
  detailedText?: string;
  children?: any;
  tooltip?: ReactElement;
  cb?: (option: string) => void;
  dataQa?: string;
};

const SelectionItem = (props: SelectionItemProps) => {
  const {
    selected,
    imagePrefix,
    description,
    detailedText,
    children,
    name,
    disabled,
    tooltip,
    cb,
    dataQa,
  } = props;

  const [image, setImage] = useState('');
  const [coloredImage, setColoredImage] = useState('');
  const [disabledImage, setDisabledImage] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const getSelectedClass = () => (selected ? 'selected' : '');
  const getSelectionClass = () => (disabled ? 'gray-5' : 'selection');

  useEffect(() => {
    setImage(`images/${imagePrefix}.png`);
    setColoredImage(`images/${imagePrefix}-colored.png`);
    setDisabledImage(`images/${imagePrefix}-disabled.png`);
  }, [imagePrefix]);

  document.addEventListener('click', () => setIsFocused(false));

  const selectionStyles = css`
    width: 50%;
    margin: 0 auto;
    margin-bottom: auto;
    text-align: center;
    color: ${gray4};
    ${!disabled && css`cursor: pointer;`}

    .selection-circle {
      width: 81px;
      height: 81px;
      margin: auto 0;
      display: inline-block;
      padding: 20px;
      border: 1px solid ${gray3};
      border-radius: 100%;

      img {
        width: 40px;
      }
    }

    .colored {
      display: none;
    }

    .detailed {
      display: none;
      margin-top: ${standardSpacing}px;
    }

    ${!disabled
      && css`
        &:hover, &.selected {
          color: ${primary};

          .selection-circle {
            border-color: ${primary};
          }

          .gray {
            display: none;
          }

          .colored {
            display: inline;
          }

          .detailed {
            display: block;
            color: ${gray4};
          }
        }
      `}

    .description, .title {
      margin-top: ${halfSpacing}px;
    }
  `;

  return (
    <NvPopover
      content={tooltip}
      enabled={disabled}
      show={disabled && isFocused}
      rootClose={false}
      placement='top'
      className='w-100'
    >
      <div
        css={selectionStyles}
        className={`${getSelectionClass()} ${getSelectedClass()}`}
        onClick={() => { if (!disabled) cb(name); }}
        onFocus={() => setIsFocused(true)}
        onMouseOver={() => setIsFocused(true)}
        data-qa={dataQa || ''}
      >
        <div className='selection-circle'>
          {disabled ? (
            <img
              className='disabled-skill'
              src={prodPathReplace(disabledImage)}
              alt={description ?? ''}
            />
          ) : (
            <Fragment>
              <img className='gray' src={prodPathReplace(image)} alt={description ?? ''} />
              <img className='colored' src={prodPathReplace(coloredImage)} alt={description ?? ''} />
            </Fragment>
          )}
        </div>
        {description && <div className='description'>{description}</div>}
        {(detailedText || children) && (
          <div className='detailed text-left'>
            {detailedText && <div className='detailed-text text-gray-2 mb-4'>{detailedText}</div>}
            {children && <div className='d-flex align-items-center'>{children}</div>}
          </div>
        )}
      </div>
    </NvPopover>
  );
};

type NvColoredSelectorProps = {
  items?: Omit<SelectionItemProps, 'selectedOption'>[],
  selectedOption: string,
};

export const NvColoredSelector = (props: NvColoredSelectorProps) => {
  const { items, selectedOption } = props;
  const [selected, setSelected] = useState(selectedOption);
  const containerStyles = css`
    display: flex;
    flex: 1;
  `;

  return (
    <div css={containerStyles} className='selection-container'>
      {items.map((item: SelectionItemProps) => (
        <SelectionItem
          {...item}
          key={item.name}
          selected={selected === item.name}
          cb={() => {
            setSelected(item.name);
            item.cb?.(item.name);
          }}
        />
      ))}
    </div>
  );
};
