import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import t from 'react-translate';
import { pick } from 'underscore';

import { Result } from 'redux/schemas/api';
import {
  AddSkillRatingsParams, AddSkillRatingsResponse, DeleteFeedbackRequest,
  DeleteSkillRatingsResponse, ResetSkillsFeedbackState, SkillsFeedbackRequest,
} from 'redux/schemas/api/skills-feedback';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { SkillRatingFeedback, Summary } from 'redux/schemas/app/skills-feedback';

import { addAlertMessage } from './alert-messages';
import { makeQueryParamString } from './helpers';

export const resetSkillsFeedbackState = createAction<ResetSkillsFeedbackState>('RESET_SKILLS_FEEDBACK_STATE');

export const addRating = createAsyncThunk<AddSkillRatingsResponse, AddSkillRatingsParams>(
  'ADD_SKILL_FEEDBACK',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const { institutionId, ...bodyParams } = params;
      const response = await axios.post<Result<AddSkillRatingsResponse>>(`/institutions/${institutionId}/skill_ratings.json`, bodyParams);
      return response.data.result;
    } catch (error) {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.OOPS(),
        message: t.FORM.ERROR_SOMETHING_WRONG(),
      }));
      return rejectWithValue(error);
    }
  },
);

export const getSummary = createAsyncThunk(
  'GET_AGGREGATE_SUMMARY',
  async (params: SkillsFeedbackRequest) => {
    const { institutionId, ...queryParams } = params;
    const paramString = makeQueryParamString(queryParams);
    const response = await axios.get<Result<Summary[]>>(`/institutions/${institutionId}/feedback_summary.json?${paramString}`);
    return response.data.result;
  },
);

export const getAllFeedback = createAsyncThunk(
  'FETCH_ALL_SKILLS_FEEDBACK',
  async (params: SkillsFeedbackRequest) => {
    const { institutionId, ...queryParams } = params;
    const paramString = makeQueryParamString(queryParams);
    const response = await axios.get<Result<SkillRatingFeedback>>(`/institutions/${institutionId}/skill_ratings.json?${paramString}`);
    return response.data.result;
  },
);

export const deleteFeedback = createAsyncThunk<DeleteSkillRatingsResponse, DeleteFeedbackRequest>(
  'DELETE_SKILLS_FEEDBACK',
  async (params: DeleteFeedbackRequest, { dispatch, rejectWithValue }) => {
    try {
      const { institutionId, ...queryParams } = params;
      const paramString = makeQueryParamString(queryParams);
      const response = await axios.post<Result<DeleteSkillRatingsResponse>>(`/institutions/${institutionId}/destroy_feedback.json?${paramString}`);
      if (params.userId) {
        dispatch(getSummary(pick(params, 'institutionId', 'ownerId', 'ownerType')));
      }
      return response.data.result;
    } catch (error) {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR(),
      }));
      return (rejectWithValue(error));
    }
  },
);
