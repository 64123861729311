import { css, SerializedStyles } from '@emotion/react';
import { CSSProperties, useEffect, useState } from 'react';
import { standardSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { handheld, isHandheld } from 'styles/global_defaults/media-queries';
import { primary } from 'styles/global_defaults/colors';
import Anchor from 'react-bootstrap/SafeAnchor';
import NvIcon from 'shared/components/nv-icon';
import NvDropdown, { NvDropdownOption, NvDropdownButtonStyle, NvDropdownAlign } from './inputs/nv-dropdown';
import { NvResponsiveTabs, NvTab, NvResponsiveTabsDisplayType } from './nv-responsive-tabs';

type NvResponsiveTabRowProps = {
  /** A list of tabs to display on the left */
  defaultTabs: NvTab[],
  filteredTabs?: NvTab[],
  isFiltered?: boolean,
  tabType: NvResponsiveTabsDisplayType,
  /** A button for clearing search results, appears right after the tabs */
  clearBtn?: JSX.Element,
  /** A button for saving filter results, appears right after the clearBtn */
  saveAsMyViewButton?: JSX.Element,
  /** Show or hide the clear btn */
  showClearBtn?: boolean,
  /** Show or hide the saveAsMyViewButton btn */
  showSaveAsMyViewButton?: boolean,
  /** A search bar to display to the left of the icon buttons. Omit this to not display a search UI. */
  searchBar?: JSX.Element,
  /** A list of icon buttons that sits between the search bar and CTA button */
  iconBtns?: JSX.Element[],
  /** If given, displays a blue call-to-action button on the right of the bar with this text */
  ctaButtonText?: string,
  /** If given, disables the call-to-action button */
  ctaButtonDisabled?: boolean,
  /** If given, that button displays a dropdown */
  ctaButtonItems?: NvDropdownOption[],
  /** If given, dipslays a button that will navigate to that url */
  ctaUrl?: string,
  /** The class name for the icon shown on the left side of the CTA button. Omit to show no icon */
  ctaIconClass?: string,
  /** data-qa tag used for CTA */
  ctaDataQa?: string,
  style?: CSSProperties,
  revertActiveTab?: number,
  tabTextClass?: string,
  preventActiveTabOnClick?: boolean,
  activeClass?: string,
  activeBorderColor?: string,
  selectedTabIndex?: number,
};

/** Provides the structure for a common UI pattern where there are NvResponsiveTabs left-aligned alongside
 * right-aligned icon buttons and a blue CTA button dropdown. Typically this involves submitting a search
 * query, changing the tabs to a 'results' view, and supporting filtering, but those actions
 * are not managed by this component directly. */
export const NvResponsiveTabsRow = (props: NvResponsiveTabRowProps) => {
  const styles = css`
    display: flex;
    align-items: center;
    position: relative; /* For absolute positioning the filter-search-panel */

    .btn-panel {
      /* Float this panel above the other content on small devices */
      position: absolute;
      top: 0;
      right: 0;
      z-index: 99;

      margin-left: auto;
      display: flex;
      align-items: center;

      /* The panel is full height and semi transparent so it can hover and mask on top of the responsive tabs
      when search is expanded on a mobile view */
      background-color: rgba(255, 255, 255, .95);
      height: ${standardSpacing * 3}px;

      /* TODO: The below styles were copied from institution-dashboard-home but might not be generic enough
      for accepting arbitrary content in this component */
      & > * {
        margin-left: ${standardSpacing}px;
        margin-right: ${standardSpacing}px;

        ${handheld(css`
          margin-left: ${halfSpacing}px;
          margin-right: ${halfSpacing}px;
        `)};
      }


      /* Hover styles for icon buttons */
      div[class*="icon-"]:hover {
        cursor: pointer;
        color: ${primary} !important;
      }

      /* Spacing tweak for mobile view that's applied to the icon instead of the panel to avoid accidentally
      offsetting the nv-expandable-search-bar padding. */
      ${handheld(css`
        margin-right: 0;
      `)};

      .cta-button-link {
        display: flex;
        align-items: center;
        margin-right: ${halfSpacing}px;

        .icon {
          margin-right: ${halfSpacing}px;
        }
      }
    }
  `;

  // Hide the CTA button text in small mobile views
  const [showButtonText, setShowButtonText] = useState(!isHandheld());
  useEffect(() => window.addEventListener('resize', () => {
    setShowButtonText(!isHandheld());
  }), []);

  return (
    <div css={styles} style={props.style} className='nv-responsive-tabs-row-wrapper'>
      <NvResponsiveTabs
        tabs={props.isFiltered ? props.filteredTabs : props.defaultTabs}
        displayType={props.tabType}
        revertActiveTab={props.revertActiveTab}
        tabTextClass={props.tabTextClass}
        preventActiveTabOnClick={props.preventActiveTabOnClick}
        activeClass={props.activeClass}
        activeBorderColor={props.activeBorderColor}
        selectedTabIndex={props.selectedTabIndex}
      />
      {props.showClearBtn && props.clearBtn}
      {props.showSaveAsMyViewButton && props.saveAsMyViewButton}
      {/* A right-aligned panel of buttons for the searching, filtering, etc.
      Floats above the responsive tabs in a mobile view to keep the search bar visible upon expanding */}
      <div className='btn-panel'>
        {props.searchBar && props.searchBar}
        {props.iconBtns?.length && props.iconBtns.map(ib => ib)}
        {props.ctaButtonText && ((
          props.ctaButtonItems && (
            <NvDropdown
              items={props.ctaButtonItems}
              buttonStyle={NvDropdownButtonStyle.BUTTON}
              align={NvDropdownAlign.RIGHT}
              title={props.ctaButtonText}
              // hideTitle={!showButtonText}
              iconClass={props.ctaIconClass}
              disabled={props.ctaButtonDisabled}
            />
          )) || ((
          props.ctaUrl && (
            <Anchor
              href={props.ctaUrl}
              data-qa={props.ctaDataQa}
              className='cta-button-link btn btn-primary'
            >
              <NvIcon icon={props.ctaIconClass} size='smallest' />
              {props.ctaButtonText}
            </Anchor>
          )
        )))}
      </div>
    </div>
  );
};

export default NvResponsiveTabsRow;
