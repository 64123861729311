/* @ngInject */
export default function AnnouncementsCtrl(

  $scope,
  AnnouncementsManager,
  CurrentCourseManager,
  ConfirmationOverlays,
  $stateParams,
  moment,
  $uibModal,
  $timeout,
  _,
) {
  const vm = this;
  vm.AnnouncementsManager = AnnouncementsManager;
  vm.lastUpdated = null; // for badge
  vm.lastNew = null; // for badge


  vm.new = () => {
    openFormModal()
      .result.then((announcement) => {
        vm.lastNew = announcement;
      });
  };

  vm.attemptEdit = (announcement) => {
    checkEmailReleased(announcement).then(() => {
      openFormModal(announcement)
        .result.then((updatedAnnouncement) => {
          if (vm.lastNew === updatedAnnouncement) {
            vm.lastNew = null;
          }
          vm.lastUpdated = updatedAnnouncement;
        });
    });
  };

  vm.preview = (announcement) => {
    announcement.getPreview().then((result) => $uibModal.open({
      templateUrl: 'announcements/templates/admin/announcement-view-email-modal.html',
      controller: 'AnnouncementViewEmailModalCtrl',
      controllerAs: 'vm',
      windowClass: 'admin-announcements-modal',
      resolve: {
        announcement,
      },
    }));
  };

  vm.delete = (announcement) => {
    const modalInstance = ConfirmationOverlays.openConfirmationModal(
      'announcements/templates/admin/deletion-warning-overlay.html',
      'AttachModalResolvesToVmCtrl as vm', {
        vmResolves: () => ({
          announcement,
          course: CurrentCourseManager.course,
        }),
      },
    );

    modalInstance.result.then(() => {
      AnnouncementsManager.deleteAnnouncement(announcement);
    });
  };


  /* Helpers */
  function openFormModal(announcement) {
    return $uibModal.open({
      templateUrl: 'announcements/templates/admin/announcement-form-modal.html',
      controller: 'AnnouncementFormModalCtrl',
      controllerAs: 'vm',
      windowClass: 'admin-announcements-modal',
      resolve: {
        originalAnnouncement: announcement,
      },
    });
  }

  function checkEmailReleased(announcement) {
    if (announcement.hasEmail && announcement.hasBeenReleased()) {
      return ConfirmationOverlays.openConfirmationModal(
        'announcements/templates/admin/edit-announcement-only-overlay.html',
        'AttachModalResolvesToVmCtrl as vm', {
          vmResolves: () => ({
            course: CurrentCourseManager.course,
          }),
        },
      ).result;
    }
    return $.when();
  }
}
