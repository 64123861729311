
import { useEffect, useRef, useState } from 'react';
import { barSpacingWidth, barWidth, drawAudioVisualizer, getAudioAnalyzer, getVolume, maxValue } from 'recording/services/media-visualizer-helper';
import useWindowResize from 'shared/hooks/use-window-resize';
import { isHandheld } from 'styles/global_defaults/media-queries';

const handHeldCanvasWidth = 320;
const nonHandHeldCanvasWidth = 602;

type AudioStreamPreviewProps = {
  mediaStream: MediaStream,
  isPlaying: boolean,
};


/**
 * Displaying a running bar graph of the volume sampled every 150ms
 */
const AudioStreamPreview = ({
  mediaStream,
  isPlaying,
}: AudioStreamPreviewProps) => {
  const canvasRef = useRef<HTMLCanvasElement>();

  // read the following link for more on getting frequency data
  // https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/getByteFrequencyData
  const analyserRef = useRef<AnalyserNode>();
  const [currentData, setCurrentData] = useState<Array<number>>(new Array(150).fill(0)); // this is the maximum number we show, so need to hold all the data for responsive

  useWindowResize(() => {
    if (canvasRef.current) {
      canvasRef.current.setAttribute('width', (isHandheld() ? handHeldCanvasWidth : nonHandHeldCanvasWidth).toString());
    }
  }, 100, false, []);

  // set up the analyzer based on the stream
  useEffect(() => {
    if (mediaStream) {
      analyserRef.current = getAudioAnalyzer(mediaStream);
    }

    return () => {
      analyserRef.current = null;
    };
  }, [mediaStream]);


  // this useEffect updates the currentData array to have the latest volume numbers
  useEffect(() => {
    let interval;

    if (isPlaying && analyserRef.current) {
      interval = setInterval(() => {
        const volumePercentage = getVolume(analyserRef.current);
        setCurrentData((c) => [...c.slice(1, c.length), volumePercentage * maxValue]);
      }, 150);
    }

    return () => clearInterval(interval);
  }, [isPlaying]);

  // this useEffect is responsible for rendering the data in currentData
  useEffect(() => {
    const barsShown = Math.floor((canvasRef.current.width - barSpacingWidth) / (barWidth + barSpacingWidth)); // number of bars to show in the canvas
    const displayData = currentData.slice(-barsShown, currentData.length);

    drawAudioVisualizer(displayData, canvasRef.current);
  }, [currentData]);

  return <canvas ref={canvasRef} className='audio-stream-preview' height='60' width={(isHandheld() ? handHeldCanvasWidth : nonHandHeldCanvasWidth).toString()} />;
};

export default AudioStreamPreview;
