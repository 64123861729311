import React from 'react';
import t from 'react-translate';
import { css } from '@emotion/react';
import { MetadataFieldType, MetadataValueType } from 'redux/schemas/models/org-level-metadata-settings';
import NvDropdown, { NvDropdownOption, NvDropdownButtonStyle } from 'shared/components/inputs/nv-dropdown';
import NvTagButtonClosable from 'shared/components/nv-tag-button-closable';
import NvPopover from 'shared/components/nv-popover';
import NvConfirmationPopover from 'shared/components/nv-confirmation-popover';
import NvAddItem, { NvAddItemForm } from 'shared/components/nv-add-item';
import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import { gray1, gray5, hexToRgbaString } from 'styles/global_defaults/colors';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { config } from '../../../config/pendo.config.json';

type Props = {
  metadata: MetadataFieldType,
  addMetadataValue: (name: string, value: string) => boolean,
  deleteMetadataSection: (name: string) => void,
  deleteMetadataValue: (name: string, metadataValue: string) => void,
  editingMetadataName?: (metadataId: number, status: boolean) => void,
  markMetadataAsRequired: (name: string, value: boolean) => void,
  updateMetadataName: (oldName: string, newName: string) => boolean,
  dragHandleSelectorClassName: string,
  existingMetadataValue: (name: string, metadataValue: string) => MetadataValueType,
  existingMetadataField: (name: string) => MetadataFieldType,
};


const MetadataSection = ({
  metadata,
  addMetadataValue,
  deleteMetadataSection,
  deleteMetadataValue,
  editingMetadataName,
  markMetadataAsRequired,
  updateMetadataName,
  existingMetadataValue,
  existingMetadataField,
  dragHandleSelectorClassName,
}: Props) => {
  const initialTooltipMetadata = { name: null, value: null };
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [showTooltipConfirmation, setShowTooltipConfirmation] = React.useState(initialTooltipMetadata);
  const [editingName, setEditingName] = React.useState(false);
  const [showTooltip, setShowTooltip] = React.useState(false);
  const displayNameOnly = false;
  const styles = css`
    .metadata-section {
      position: relative;
      border: 1px solid ${gray5};
      padding: ${halfSpacing}px;
      margin-bottom: ${standardSpacing}px;

      .rotate {
        transform: rotate(90deg);
      }

      .header {
        display: flex;
        justify-content: space-between;
        .name {
          padding: ${halfSpacing}px;
          font-weight: bold;
        }
        .options {
          padding: ${halfSpacing}px;
          display: flex;
          .menu {
            display: flex;
            align-items: center;
          }
        }
      }
      .edit-name {
        padding: ${halfSpacing}px;
        .add-form {
          min-height: unset;
        }
      }
      .values {
        display: flex;
        flex-wrap: wrap;
      }
      .adding .add-form {
        padding: 0 ${halfSpacing}px;
        min-height: unset;
        .add-input {
          max-width 200px;
        }
      }
      .deletion-confirmation {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${hexToRgbaString(gray1, 0.95)};
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        .deletion-confirmation-content {
          padding: 20px;
          ${displayNameOnly && css`
            display: flex;
          `}

          .confirmation-text {
            margin-bottom: ${halfSpacing}px;

            ${displayNameOnly && css`
              margin-right: ${standardSpacing}px;
              margin-bottom: 0;
              display: flex;
              align-items: center;
            `}
          }
        }

        .btn-danger {
          margin-left: ${halfSpacing}px;
        }
      }

      // Special style for DnD handler

      :not(&:hover) .${dragHandleSelectorClassName}{
        max-width: 0;
        max-height: 0;
        opacity: 0;
      }

      .${dragHandleSelectorClassName} {
        display: flex;
        align-items: center;
        width: 16px;
        position: absolute;
        z-index: 10;
        background-color: red;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    .nv-round-button {
      padding: ${halfSpacing}px;
    }
  `;

  const editMetadataName = (status: boolean) => {
    editingMetadataName(metadata.id, status);
    setEditingName(status);
  };

  const updateMetadataNameEvent = (oldName: string, newName: string) => {
    const saved = updateMetadataName(oldName, newName);
    if (saved) {
      editMetadataName(false);
    }
    return saved;
  };

  const showDeleteMetadataConfirmationMessage = () => {
    setShowConfirmation(true);
  };

  const addMetadataValueEvent = (name: string, value: string) => addMetadataValue(name, value);

  const deleteMetadataSectionEvent = (name: string) => {
    deleteMetadataSection(name);
    setShowConfirmation(false);
  };

  const deleteMetadataValueEvent = (name: string, value: string) => {
    deleteMetadataValue(name, value);
  };

  const dropDownItems: NvDropdownOption[] = [
    {
      type: 'text',
      text: t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.DROPDOWN.EDIT(),
      callback: () => editMetadataName(true),
    },
    { type: 'divider', class: 'mb-2' },
    {
      class: 'text-danger',
      type: 'text',
      text: t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.DROPDOWN.DELETE(),
      callback: () => showDeleteMetadataConfirmationMessage(),
      dataQa: config.pendo.settings.metadataModal.deleteMetadataOption,
    },
  ];

  const nvTooltipText = () => {
    let text = t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.HOVER_REQUIRED();
    if (metadata.required) {
      text = t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.HOVER_NOT_REQUIRED();
    }
    return text;
  };

  return (
    <div css={styles}>
      <div className='metadata-section bg-white'>
        <div className={`${dragHandleSelectorClassName} bg-gray-7`}>
          <NvIcon className='rotate' size='smallest' icon='drag-handle' />
        </div>
        {
          editingName ? (
            <div className='edit-name'>
              <div className='text-small text-gray-3'>
                {t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.DROPDOWN.METADATA_DISPLAY_NAME()}
              </div>
              <NvAddItemForm
                errorMessage={{ message: t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.EXISTING_METADATA_NAME() }}
                onSave={(value) => updateMetadataNameEvent(metadata.name, value)}
                onCancel={() => editMetadataName(false)}
                placeholder={t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.TYPE_HERE()}
                value={metadata.name}
                errorOnTouching={false}
              />
            </div>
          ) : (
            <div className='header'>
              <div className='name ellipsis'>
                {metadata.name}
              </div>
              <div className='options'>
                <NvTooltip text={nvTooltipText()} enabled={showTooltip}>
                  <div
                    className='checkbox switch required-text text-small'
                    onMouseEnter={() => { setShowTooltip(true); }}
                    onFocus={() => { setShowTooltip(true); }}
                    onMouseLeave={() => { setShowTooltip(false); }}
                    onBlur={() => { setShowTooltip(false); }}
                  >
                    <input type='checkbox' name={`metadata_${metadata.name}`} id={`metadata_${metadata.name}`} checked={metadata.required} onChange={() => { markMetadataAsRequired(metadata.name, !metadata.required); }} />
                    <label htmlFor={`metadata_${metadata.name}`}>&nbsp;</label>
                  </div>
                </NvTooltip>
                <div className='menu'>
                  <NvDropdown
                    buttonStyle={NvDropdownButtonStyle.CUSTOM}
                    customTarget={() => (
                      <NvIcon className='rotate' size='smallest' icon='in-progress' data-qa={config.pendo.settings.metadataModal.editIcon} />
                    )}
                    items={dropDownItems}
                    menuClassName='drop-down-menu'
                    drop='down'
                  />
                </div>
              </div>
            </div>
          )
        }

        <div className='values'>
          {metadata.metadataValues.map((value) => (
            <NvPopover
              key={value.value}
              placement='top'
              preventOverflow
              flip
              content={(
                <NvConfirmationPopover
                  onCancel={() => { setShowTooltipConfirmation(initialTooltipMetadata); }}
                  onConfirm={() => { setShowTooltipConfirmation(initialTooltipMetadata); deleteMetadataValueEvent(metadata.name, value.value); }}
                  header={t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.ARE_YOU_SURE()}
                  message={!!existingMetadataValue(metadata.name, value.value) && t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.TOOLTIP.CONFIRMATION_MESSAGE()}
                  cancelBtnText={t.NOVOED.CANCEL()}
                  confirmBtnText={t.NOVOED.DELETE()}
                  pendoTagName={config.pendo.settings.deleteMetadataValue}
                />
              )}
              show={showTooltipConfirmation?.name === metadata.name && showTooltipConfirmation?.value === value.value}
            >
              <NvTagButtonClosable
                className='nv-round-button'
                text={value.value}
                elementId={`value-m${metadata.name}-v${value.value}`}
                onIconClick={() => { setShowTooltipConfirmation({ name: metadata.name, value: value.value }); }}
              />
            </NvPopover>
          ))}
        </div>
        <div className='adding'>
          <NvAddItem
            onSave={(value) => addMetadataValueEvent(metadata.name, value)}
            closeOnSave
            errorMessage={{ message: t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.EXISTING_METADATA_VALUE() }}
            icon='icon-create-new-post'
            buttonLabel={t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.ADD_VALUE()}
            saveBtnText={t.FORM.ADD()}
            placeholder={t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.ADD_VALUE()}
            errorOnTouching={false}
          />
        </div>
        {showConfirmation && (
          <div className='deletion-confirmation'>
            <div className='deletion-confirmation-content'>
              <div className='confirmation-text'>{!existingMetadataField(metadata.name) ? t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.ARE_YOU_SURE() : t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.SETTINGS_MODAL.DROPDOWN.CONFIRMATION_MESSAGE() }</div>
              <button type='button' className='btn btn-default btn-sm dark-theme' onClick={() => { setShowConfirmation(false); }}>{t.FORM.CANCEL()}</button>
              <button
                type='button'
                className='btn btn-danger btn-sm dark-theme'
                onClick={() => { deleteMetadataSectionEvent(metadata.name); }}
                pendo-tag-name={config.pendo.settings.deleteMetadataField}
              >
                {t.FORM.DELETE()}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MetadataSection;
