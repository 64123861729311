import OrgMentorAdminPanel from '../components/org-mentor-admin-panel';

/* @ngInject */
export default function OrgMentorAdminController(
  $scope,
  CurrentUserManager,
  OrgLevelProfileManager,
) {
  $scope.CurrentUserManager = CurrentUserManager;
  $scope.OrgMentorAdminPanel = OrgMentorAdminPanel;
  $scope.OrgLevelProfileManager = OrgLevelProfileManager;
}
