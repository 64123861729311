/* @ngInject */
export default function oeContentMath(

  MathjaxLoader,
  $timeout,
) {
  return {
    link(scope, element, attrs) {
      // froala box
      if (!element.closest('.fr-element').length) {
        // already rendered
        if (element.has('script').length > 0) {
          return;
        }

        element.css('visibility', 'hidden');
        element.html(`$$${element.html()}$$`);

        MathjaxLoader.mathjaxize(element);
      }
    },
  };
}
