import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import t from 'react-translate';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvTooltip from 'shared/components/nv-tooltip';

const labelClass = 'text-small gray-3 m-0';

type ZoomHostValidatorType = {
  name: string
  disabled: boolean
  isContentManagementCollection: boolean
  host: string
  validate: (email: string, onValidating: () => void, onSuccess: () => void, onError: () => void) => void
  onValidHost: (isValid: boolean) => void
};

const ZoomHostValidator = (props: ZoomHostValidatorType) => {
  const {
    name,
    disabled,
    isContentManagementCollection,
    host: authenticationEmail,
    validate,
    onValidHost,
  } = props;
  const { watch, setError, clearErrors } = useFormContext();
  const formName = `${name}.authenticationEmail`;
  const host = watch(formName);

  const [validating, setValidating] = useState(false);
  const [validHost, setValidHost] = useState(authenticationEmail);
  const [isValid, setIsValid] = useState(authenticationEmail && authenticationEmail === host);
  const [invalidHost, setInvalidHost] = useState('');

  const onValidating = () => {
    setValidating(true);
  };

  const onSuccess = () => {
    setValidating(false);
    setIsValid(true);
    setValidHost(host);
    clearErrors(formName);
    onValidHost(true);
  };

  const onError = () => {
    setValidating(false);
    setIsValid(false);
    setValidHost('');
    setInvalidHost(host);
    setError(formName, { type: 'custom' });
    onValidHost(false);
  };

  useEffect(() => {
    onValidHost(isValid && host === validHost);
  }, [host, validHost, onValidHost]);

  return (
    <div>
      <label className={labelClass}>{t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.ZOOM_MEETING_HOST()}</label>
      <div className='d-flex w-100 mb-4'>
        <NvTooltip
          text={t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.SETUP_IN_COURSE_TOOLTIP()}
          placement='top'
          enabled={isContentManagementCollection}
        >
          <div className='col-xs-10 p-0'>
            <NvTextInput
              withForm
              required
              type='email'
              name={formName}
              autoComplete='off'
              disabled={disabled || isContentManagementCollection}
              ariaLabel={t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.ZOOM_MEETING_HOST()}
              placeholder={t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.ZOOM_MEETING_HOST()}
            />
          </div>
        </NvTooltip>
        <div css={{ minWidth: '110px' }} className='d-flex ml-2 align-items-center bold'>
          {(isValid && host === validHost) ? (
            <span className='text-success'>
              {t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.ZOOM_HOST_VALIDATED()}
            </span>
          ) : (
            <Button
              variant='primary'
              className='w-100'
              type='button'
              disabled={!host || validating || host === invalidHost || disabled || isContentManagementCollection}
              onClick={() => validate(host, onValidating, onSuccess, onError)}
            >
              {t.FORM.VALIDATE()}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ZoomHostValidator;
