/* @ngInject */
export default function TeamProfileBaseCtrl(
  TeamManager,
  CurrentUserManager,
  $state,
) {
  const vm = this;

  vm.manager = TeamManager;
  vm.course = CurrentUserManager.coursesHashByCatalogId[$state.params.catalogId];
  vm.$state = $state;
}
