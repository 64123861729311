import store from 'redux/store';
import { ActivityKey } from 'redux/schemas/models/activity';
import { setActivityProgress } from 'redux/actions/lecture-components';

/* @ngInject */
export default function TimelinePublicPracticeFeedbackCriteriaLectureComponentModelService(
  _,
  moment,
  nvUtil,
) {
  class TimelinePublicPracticeFeedbackCriteriaLectureComponent {
    constructor(attributes) {
      const base = {
        templateUrl: 'timelines/templates/nv-timeline-video-practice-feedback.html',
        canHaveHardDeadline: true,
      };

      /** Setting Up Basic Attributes * */
      _.extend(this, base, attributes);
      // passed lecturePage, lectureSection, course
      this.preprocess();
    }

    /** Function Declarations * */
    /* Used Publicly */
    getStatus() {
      return this[this.feedbackKey].progress;
    }

    isInProgress() {
      return this[this.feedbackKey].progress === 'in_progress';
    }

    isCompleted() {
      return this[this.feedbackKey].progress === 'completed';
    }

    isMissed() {
      return this[this.feedbackKey].progress === 'missed';
    }

    isTodoRequiredForCompletion() {
      return this[this.feedbackKey].isRequiredForCompletion;
    }

    isPrereqCompleted(prereq) {
      if (prereq.type === 'PracticeFeedbackCriteria' && prereq.id === this[this.feedbackKey].id) {
        return this.isCompleted();
      }
      return false;
    }

    updateComponentPointsAndProgress(componentType, componentId, pointsReceived, totalPoints, progress) {
      if ((this.type === componentType && this.id === componentId)
        || (componentType === 'practice_feedback_criteria' && this[this.feedbackKey].id === componentId)
      ) {
        this[this.feedbackKey].progress = progress ?? 'completed';

        store.dispatch(setActivityProgress({
          pointsReceived,
          progress: this[this.feedbackKey].progress,
          activityKey: this.type === 'VideoPracticeSkillsRatingLectureComponent'
            ? ActivityKey.VIDEO_PRACTICE_SKILLS_FEEDBACK
            : ActivityKey.VIDEO_PRACTICE_FEEDBACK,
          activityPayloadId: this[this.feedbackKey].id,
        }));

        this[this.feedbackKey].pointsReceived = pointsReceived;
        this.pointsReceived = pointsReceived;

        if (_.isNumber(totalPoints)) {
          this.currentTotalPoints = totalPoints;
        } else {
          this.currentTotalPoints = nvUtil.getCurrentTotalPoints(this.totalPoints, this.lecturePage.releaseDate, this.course.expiringPointsEnabled);
        }

        this.viewPreprocess();

        this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    updateComponentProgress(componentType, componentId, progress) {
      if ((this.type === componentType && this.id === componentId) || (componentType === 'practice_feedback_criteria' && this[this.feedbackKey].id === componentId)) {
        this[this.feedbackKey].progress = progress;
        store.dispatch(setActivityProgress({
          progress,
          activityKey: this.type === 'VideoPracticeSkillsRatingLectureComponent'
            ? ActivityKey.VIDEO_PRACTICE_SKILLS_FEEDBACK
            : ActivityKey.VIDEO_PRACTICE_FEEDBACK,
          activityPayloadId: this[this.feedbackKey].id,
        }));
        this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    getPayload() {
      return _.pick(this, ['title', 'deadline', 'hardDeadline']);
    }

    hasDeadline() {
      return !!this[this.feedbackKey].deadline;
    }

    setDeadline(newDeadline) {
      this[this.feedbackKey].deadline = newDeadline;
      this.deadline = newDeadline;
    }

    isDeadlineBeforeLesson() {
      return this[this.feedbackKey].deadline < this.lecturePage.releaseDate;
    }

    /* Used Privately */
    preprocess() {
      this.feedbackKey = this.type === 'VideoPracticeSkillsRatingLectureComponent' ? 'skillsRating' : 'publicFeedback';
      this.title = this[this.feedbackKey].title;
      this.deadline = this[this.feedbackKey].deadline;
      this.isTodo = this[this.feedbackKey].isTodo;
      this.hasStructuralIssues = this[this.feedbackKey].hasStructuralIssues;
      this.pointsReceived = this[this.feedbackKey].pointsReceived;
      this.totalPoints = this[this.feedbackKey].totalPoints;
      this.activityId = this[this.feedbackKey].id;
      this.hardDeadline = this[this.feedbackKey].hardDeadline;

      this.currentTotalPoints = nvUtil.getCurrentTotalPoints(this.totalPoints, this.lecturePage.releaseDate, this.course.expiringPointsEnabled, this[this.feedbackKey].expired);

      this.viewPreprocess();
    }

    viewPreprocess() {
      this.showDueSoon = this.__showDueSoon();

      this.displayTotalPointsOnly = !this.pointsReceived && this.currentTotalPoints && !this.isMissed();
    }

    showPointsDenominator() {
      return this.currentTotalPoints > this[this.feedbackKey].pointsReceived && !this.isCompleted() && !this.isMissed();
    }

    __showDueSoon() {
      if (this[this.feedbackKey].deadline) {
        return !this.isCompleted() && moment(this[this.feedbackKey].deadline) > moment() && moment(this[this.feedbackKey].deadline) < moment().add(7, 'days');
      }
      return false;
    }
  }

  return TimelinePublicPracticeFeedbackCriteriaLectureComponent;
}
