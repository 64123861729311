
import { css } from '@emotion/react';
import t from 'react-translate';
import { primary } from 'styles/global_defaults/colors';
import ClickableContainer, { ClickableContainerProps } from '../../../components/clickable-container';

interface ApplyToAllSectionsButtonProps {
  onClick: ClickableContainerProps['onClick'];
}
/* @ngInject */
export default function ApplyToAllSectionsButton(props: ApplyToAllSectionsButtonProps) {
  return (
    <ClickableContainer onClick={props.onClick}>
      <span
        className='text-small'
        css={css`
          color: ${primary};
        `}
      >
        {t.LECTURE_PAGES.COMPONENTS.ACCORDION.APPLY_TO_ALL_SECTIONS()}
      </span>
    </ClickableContainer>
  );
}
