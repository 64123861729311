export default [
  function () {
    return {
      restrict: 'A',
      scope: {
        iconClassName: '=?',
        iconImageSrc: '=?',
        unreadCount: '=',
        size: '@',
        linkAttribute: '@?',
        iconLabel: '@?',
      },
      link(scope, elem, attrs) {
      },
      templateUrl: 'shared/templates/nv-icon-unread-badge.html',
    };
  },
];
