import React, { CSSProperties, useContext } from 'react';
import moment from 'moment';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import { AngularServicesContext } from 'react-app';
import { object } from 'underscore';
import { css } from '@emotion/react';

import { Team } from 'redux/schemas/models/team';
import { User } from 'redux/schemas/models/my-account';
import { Submission } from 'redux/schemas/models/submissions';
import { ExerciseOwner, SubmittingStatus } from 'redux/schemas/models/exercise';

import { getCurrentInstitution } from 'redux/selectors/institutions';

import NvIcon from 'shared/components/nv-icon';
import BaseCard, { CARD_WIDTH } from 'offerings/components/base-card';
import { openSansCondensed, semiBoldFontWeight, textExtraLargeFontSize, textLargeLineHeight, textSmallFontSize, textSmallLineHeight } from 'styles/global_defaults/fonts';
import { doubleSpacing, halfSpacing, largeSpacing, quarterSpacing, standardSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { black, gray2, gray5, primary, success, warning } from 'styles/global_defaults/colors';

import { NvUserAvatar } from 'components/nv-user-avatar';
import NvTeamAvatar from 'components/nv-team-avatar';
import ClickableContainer from 'components/clickable-container';

export enum ApprovalStatus {
  APPROVED = 'approved',
  PENDING_APPROVAL = 'pending_approval',
  REJECTED_CAN_REVISE = 'rejected_can_revise',
  REJECTED_CANNOT_REVISE = 'rejected_cannot_revise',
}

const getApprovalStatus = (submittingStatus: SubmittingStatus, exerciseIsMissing: boolean): ApprovalStatus | null => {
  if (submittingStatus === SubmittingStatus.APPROVED) {
    return ApprovalStatus.APPROVED;
  }

  if (submittingStatus === SubmittingStatus.PENDING_APPROVAL) {
    return ApprovalStatus.PENDING_APPROVAL;
  }

  if (submittingStatus === SubmittingStatus.REJECTED) {
    return exerciseIsMissing
      ? ApprovalStatus.REJECTED_CANNOT_REVISE
      : ApprovalStatus.REJECTED_CAN_REVISE;
  }

  return null;
};

export type ApprovalStatusFlavor = {
  bg: string;
  translateKey: string;
};

const approvalStatusFlavors = object([
  [ApprovalStatus.APPROVED, 'bg-color-success', 'APPROVED'],
  [ApprovalStatus.PENDING_APPROVAL, 'bg-color-primary', 'PENDING'],
  [ApprovalStatus.REJECTED_CAN_REVISE, 'bg-color-warning', 'REJECTED'],
  [ApprovalStatus.REJECTED_CANNOT_REVISE, 'bg-color-gray', 'REJECTED_PAST'],
].map<[string, ApprovalStatusFlavor]>((f) => ([
  f[0], {
    bg: f[1],
    translateKey: f[2],
  },
])));

type NvSubmissionCardProps = {
  submission: Submission;
  style: CSSProperties;
  showStatus?: boolean,
  statusClass?: string,
  statusContent?: JSX.Element,
  stateParams?: {
    galleryMode?: boolean,
    isRatingFeedback?: boolean,
  };
  showApprovalStatus?: boolean
};

const NvSubmissionCard = React.forwardRef<HTMLDivElement, NvSubmissionCardProps>((props, ref) => {
  const { $state } = useContext(AngularServicesContext);
  const { submission, showStatus, statusClass, statusContent, style, showApprovalStatus = true } = props;
  const {
    id,
    exercise,
    owner,
    ownerType,
    title,
    body,
    isVotable,
    votesCount,
    numPostsAndComments,
    submitting,
    coverPhotoUrl,
  } = submission;
  const { approvalRequired, catalogId, hardDeadline, deadline, course } = exercise;
  const { submissionStatus } = submitting;

  const institution = useSelector(getCurrentInstitution);

  const backgroundImage = coverPhotoUrl ? `url(${coverPhotoUrl})` : '';

  const styles = css`
    display: block;
    max-width: ${CARD_WIDTH}px;

  &:hover {
    color: unset;
  }

  .submission-card {
    width: 100%;
    background-color: white;

    &:hover {
      border: 1px solid ${primary};
    }

    .card-header {
      height: 120px;

      .backdrop-layer {
        position: relative;
      }

      .status-wrapper {
        position: absolute;
        top: 0;
        width: 100%;
        height: ${showStatus ? doubleSpacing : largeSpacing}px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: ${semiBoldFontWeight};
        font-size: ${textSmallFontSize}px;
        ${showStatus && css`opacity: .8;`}

        &.bg-color-primary {
          background-color: ${primary};
        }

        &.bg-color-success {
          background-color: ${success};
        }

        &.bg-color-warning {
          background-color: ${warning};
        }

        &.bg-color-gray {
          background-color: ${gray2};
        }
      }

      .header-main-content {
        width: 100%;
        height: 100%;

        .course-logo {
          width: 100%;
          height: 100%;
          background-color: ${exercise.course.headerColor};
          background-image: ${backgroundImage};
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          border-bottom: 1px solid ${gray5};
        }

        .submission-header {
          width: 100%;
          height: 100%;
          padding-top: ${approvalRequired ? doubleSpacing : threeQuartersSpacing}px;
          padding-bottom: ${approvalRequired ? quarterSpacing : halfSpacing}px;
          padding-left: ${halfSpacing}px;
          padding-right: ${halfSpacing}px;
          border-bottom: 1px solid ${gray5};
          overflow: hidden;

          .body {
            width: 100%;
            height: 100%;
            overflow: hidden;
          }
        }
      }


      .owner-type-info {
        .nv-user-avatar {
          position: absolute;
          bottom: -44px;
          left: 50%;
          transform: translate(-50%);
          margin: 0;

          .avatar-container {
            border-radius: 50%;
            border: 3px solid white;
          }
        }

        .nv-team-avatar {
          position: absolute;
          top: 98px;
          left: 50%;
          transform: translate(-50%);
          margin: 0;
          max-width: 100%;

          > div:first-child {
            border: 3px solid white;
          }

          .team-name {
            color: ${black};
            font-weight: normal;
            padding: 0 ${standardSpacing}px;
            margin-top: ${quarterSpacing}px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .card-content {
      flex: 1;

      .submission-name {
        margin-top: 54px;
        padding: 0 ${standardSpacing}px;
        font-size: ${textExtraLargeFontSize}px;
        line-height: ${textLargeLineHeight}px;
        font-family: ${openSansCondensed};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
      }

      .activity-name {
        margin-top: ${quarterSpacing}px;
        font-size: ${textSmallFontSize}px;
        text-align: center;
        color: ${gray2};
      }
    }

    .extra {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: end;

      .social {
        display: flex;
        gap: ${standardSpacing}px;
        justify-content: center;
        padding-bottom: ${threeQuartersSpacing}px;
        margin-top: ${quarterSpacing}px;

        .likes {
          display: flex;
          gap: ${halfSpacing}px;
        }

        .comments {
          display: flex;
          gap: ${halfSpacing}px;
        }

        .num {
          color: ${primary};
          font-size: ${textSmallFontSize}px;
          line-height: ${textSmallLineHeight}px;
        }
      }
    }
  }
`;

  const exerciseIsMissing = Boolean(hardDeadline) && moment(deadline) < moment();
  const approvalStatus = getApprovalStatus(submissionStatus, exerciseIsMissing);
  const approvalStatusFlavor = approvalStatusFlavors[approvalStatus];

  const renderHeader = () => (
    <React.Fragment>
      {showStatus && (
        <div className={`status-wrapper ${statusClass}`}>
          {statusContent}
        </div>
      )}
      {!showStatus && showApprovalStatus && approvalRequired && (
        <div className={`status-wrapper ${approvalStatusFlavor.bg}`}>
          {t.SUBMISSION_APPROVAL.SUBMISSION_STATUS[approvalStatusFlavor.translateKey]()}
        </div>
      )}
      <div className='header-main-content'>
        {coverPhotoUrl
          ? <div className='course-logo' />
          : (
            <div className='submission-header'>
              <div className='body'>
                {body}
              </div>
            </div>
          )}
      </div>
      <div className='owner-type-info'>
        {ownerType === ExerciseOwner.USER ? (
          <NvUserAvatar
            size='md'
            borderType='round'
            user={owner as User}
            displayName
            inlineName
          />
        ) : (
          <NvTeamAvatar
            center
            size='md'
            team={owner as Team}
            course={course}
            institution={institution}
          />
        )}
      </div>
    </React.Fragment>
  );

  const renderContent = () => (
    <React.Fragment>
      <div className='submission-name'>
        {title}
      </div>
      <div className='activity-name ellipsis mx-4'>
        {exercise.title}
      </div>
    </React.Fragment>
  );

  const renderExtra = () => (
    <div className='extra'>
      <div className='social'>
        {isVotable && votesCount > 0 && (
          <div className='likes'>
            <NvIcon icon='like' size='smallest' />
            <span className='num'>{votesCount}</span>
          </div>
        )}
        {numPostsAndComments > 0 && (
          <div className='comments'>
            <NvIcon icon='comments' size='smallest' />
            <span className='num'>{numPostsAndComments}</span>
          </div>
        )}
      </div>
    </div>
  );

  const goToSubmissionModal = () => $state.go('individual-submission-modal', {
    catalogId,
    reportId: id,
    ...(props.stateParams ?? {}),
  });

  return (
    <ClickableContainer
      ref={ref}
      css={styles}
      style={style}
      onClick={goToSubmissionModal}
    >
      <BaseCard
        layoutOnly
        className='submission-card'
        renderHeader={renderHeader}
        renderContent={renderContent}
        renderExtraContent={renderExtra}
      />
    </ClickableContainer>
  );
});

export default NvSubmissionCard;
