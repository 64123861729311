/* @ngInject */
export default function PostsResources(

  $resource,
  $http,
) {
  return $resource(
    '/:catalogId/forums/:topicId/topics/:id',
    { },
    {
      // index actions
      search: {
        method: 'GET',
        url: '/:catalogId/topics/search',
        cancellable: true,
      },
      getPostsForCourse: { method: 'GET', url: '/:catalogId/topics/search' },
      getPostsForTopic: { method: 'GET', url: '/:catalogId/forums/:topicId/topics' },
      getSinglePost: { method: 'GET', url: '/:catalogId/topics/:id' },

      // single post actions,
      update: { method: 'PUT', url: '/:catalogId/topics/:id' },
      highlight: { method: 'GET', url: '/:catalogId/topics/:id/highlight?from=:from&to=:to' },
      unHighlight: { method: 'GET', url: '/:catalogId/topics/:id/unhighlight' },
      like: { method: 'POST', url: '/:catalogId/topics/:id/voteup' },
      unlike: { method: 'POST', url: '/:catalogId/topics/:id/votedown' },
      follow: { method: 'GET', url: '/:catalogId/topics/:id/subscribe' },
      unfollow: { method: 'GET', url: '/:catalogId/topics/:id/unsubscribe' },
      report: { method: 'POST', url: '/:catalogId/flaggings?flag=abuse&flaggable_id=:id&flaggable_type=Topic' },
      delete: { method: 'DELETE', url: '/:catalogId/topics/:id' },

      // post for videos
      createPost: { method: 'POST', url: '/:catalogId/forums/:id/topics.json' },
      createPostwithNewTopic: { method: 'POST', url: '/:catalogId/topics.json' },

      // single post attribute actions
      postsCount: { method: 'GET', url: '/:catalogId/topics/:id/posts_count' },
      votersInfo: { method: 'GET', url: '/:catalogId/topics/:id/voters?page=:page' },
    },
  );
}
