import { InstitutionDashboardState, JourneysDashboardState } from 'redux/schemas/models/institution';
import { normalize } from 'normalizr';
import { institutionSchema } from 'redux/schemas/api/institutions';
import {
  getCourseCounts,
  getCourseClonings,
  getInstitutionData,
  setMyViewData,
  getJourneyClonings,
  setAutomatedTranslationState,
  setCourseHighlight,
  setSpeechToText,
  getJourneyCounts,
} from 'redux/actions/institutions';
import { createReducer } from '@reduxjs/toolkit';
import { initialRootState, newState } from '.';

export const initialInstitutionDashboardState: InstitutionDashboardState = {
  courseTypeCounts: {
    myView: 0,
    active: 0,
    demo: 0,
    future: 0,
    past: 0,
    total: 0,
    primary: 0,
  },
  countsLoaded: false,
  myViewData: null,
  courseHighlight: null,
};

export const initialJourneysDashboardState: JourneysDashboardState = {
  journeyTypeCounts: {
    myView: 0,
    active: 0,
    demo: 0,
    future: 0,
    past: 0,
    total: 0,
    primary: 0,
  },
  countsLoaded: false,
};

export default createReducer(initialRootState, builder => {
  builder.addCase(getCourseCounts.fulfilled, (state, action) => {
    state.app.institutionDashboard.courseTypeCounts = action.payload;
    state.app.institutionDashboard.countsLoaded = true;
  });
  builder.addCase(getJourneyCounts.fulfilled, (state, action) => {
    state.app.journeysDashboard.journeyTypeCounts = action.payload;
    state.app.journeysDashboard.countsLoaded = true;
  });
  builder.addCase(getCourseClonings.fulfilled, (state, action) => {
    state.models.courseClonings = action.payload;
  });
  builder.addCase(getJourneyClonings.fulfilled, (state, action) => {
    state.models.journeyClonings = action.payload;
  });
  builder.addCase(getInstitutionData.fulfilled, (state, action) => {
    const { result: requestResult } = action.payload;
    const normalized = normalize(requestResult, institutionSchema);
    state.models.institutions[normalized.result] = normalized.entities.institutions[normalized.result] as any;
  });
  builder.addCase(setMyViewData, (state, action) => {
    state.app.institutionDashboard.myViewData = action.payload;
  });
  builder.addCase(setAutomatedTranslationState.fulfilled, (state, action) => {
    state.models.institutions[state.app.currentInstitutionId].automatedTranslationEnabled = action.meta.arg.enabled;
  });
  builder.addCase(setSpeechToText.fulfilled, (state, action) => {
    state.models.institutions[state.app.currentInstitutionId].speechToTextEnabled = action.meta.arg.enabled;
  });
  builder.addCase(setCourseHighlight, (state, action) => {
    state.app.institutionDashboard.courseHighlight = action.payload;
  });
});
