import React, { useContext, useState } from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';
import { Button } from 'react-bootstrap';
import OrgColorPaletteConfigureModal from 'institutions/components/org-color-palette-configure-modal';
import prodPathReplace from 'shared/prod-path-rewrite';
import ClickableContainer from 'components/clickable-container';
import Favicon from './favicon';
import SectionContent from '../section-content';
import { config } from '../../../../../config/pendo.config.json';

const styles = css`
  .color-palette {
    img {
      min-width: 260px;
      height: 200px;
    }
  }
`;

const Branding = () => {
  const { $state } = useContext(AngularServicesContext);
  const [showOrgColorPaletteModal, setShowOrgColorPaletteModal] = useState(null);

  return (
    <div css={styles}>
      <div className='border-bottom border-gray-5 pb-6'>
        <SectionContent
          header={t.INSTITUTIONS.ADVANCED_SETTINGS.BRANDED_HEADER.TITLE()}
          description={[t.INSTITUTIONS.ADVANCED_SETTINGS.BRANDED_HEADER.DESCRIPTION()]}
          extras={(
            <div>
              <img
                alt={t.INSTITUTIONS.ADVANCED_SETTINGS.BRANDED_HEADER.TITLE()}
                src={prodPathReplace('images/org-advanced-settings.gif')}
                className='my-2'
              />
              <ClickableContainer
                className='text-primary'
                onClick={() => $state.go('institution-dashboard')}
                data-qa={config.pendo.settings.brandedHeaderEdit}
              >{t.INSTITUTIONS.ADVANCED_SETTINGS.BRANDED_HEADER.EDIT()}
              </ClickableContainer>
            </div>
          )}
        />
      </div>
      <div className='border-bottom border-gray-5 py-6 color-palette'>
        <SectionContent
          header={t.INSTITUTIONS.ADVANCED_SETTINGS.COLOR_PALETTE.TITLE()}
          description={[]}
          extras={(
            <>
              <div className='d-flex align-items-center mb-4'>
                <div className='text-body mr-4'>
                  {t.INSTITUTIONS.ADVANCED_SETTINGS.COLOR_PALETTE.DESCRIPTION()}
                </div>
                <img src={prodPathReplace('images/color-palette.png')} alt='Color Palette' />
              </div>
              <Button
                className='primary mt-6'
                data-qa={config.pendo.orgColors.openConfigureOrgColorsModal}
                onClick={showOrgColorPaletteModal}
              >
                {t.INSTITUTIONS.ADVANCED_SETTINGS.CONFIGURE()}
              </Button>
            </>
          )}
        />
      </div>
      <div className='border-bottom border-gray-5 py-6'>
        <Favicon />
      </div>
      <OrgColorPaletteConfigureModal
        forwardShowModal={(func) => setShowOrgColorPaletteModal(() => func)}
      />
    </div>
  );
};

export default Branding;
