import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { InfiniteTableLoadingParams } from 'redux/create-action-creators';
import { LearnerProgress } from 'redux/schemas/models/learner-progress';
import { Result } from 'redux/schemas/api';

export const getExercisesWithHardDeadline = createAsyncThunk(
  'GET_EXERCISES_WITH_HARD_DEADLINE',
  async (params: any) => axios.get(`${params.catalogId}/exercises/exercises_with_hard_deadline.json?user_id=${params.userId}`).then(response => response.data.result),
);

export default getExercisesWithHardDeadline;
