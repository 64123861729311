import React from 'react';
import { css } from '@emotion/react';

import t from 'react-translate';
import { AngularContext } from 'react-app';
import NvSwitch from 'shared/components/inputs/nv-switch';
import NvTooltip from 'shared/components/nv-tooltip';

type Props = {
  /**
   * Angular expression string
   */
  value: string,
  /**
   * Angular function expression string
   */
  disabled?: boolean,
  onChange: string | ((any) => void),
  dataQa?: string,
  labelText?: string,
  pendoTagName?: string,
  tooltipText?: string,
  tooltipEnabled?: boolean,
};

const AdvancedSettingsSwitch = (props: Props) => {
  const {
    value,
    dataQa,
    onChange,
    labelText,
    pendoTagName,
    disabled,
  } = props;
  const { $scope, injectServices } = React.useContext(AngularContext);
  const [$parse] = injectServices(['$parse']);
  const [switchValue, setSwitchValue] = React.useState($scope.$eval(value));

  React.useEffect(() => {
    $scope.$watch(value, (newValue) => {
      setSwitchValue(newValue);
    });
  }, [$scope, value]);

  const handleSwitchChange = (newValue) => {
    if (typeof (onChange) === 'string') {
      $parse(onChange)($scope, {
        value: newValue,
      });
    } else {
      onChange(newValue);
    }
  };

  const styles = css`
    & > * {
      vertical-align: middle;
    }
    .disabled-switch {
      opacity: 0.5;
    }
  `;

  return (
    <React.Fragment>
      {/* Angular institution page styles class */}
      <div className='switch' css={styles}>
        <NvTooltip
          text={props.tooltipText}
          enabled={!!props.tooltipEnabled}
        >
          <NvSwitch
            data-qa={dataQa}
            checked={switchValue}
            onChange={handleSwitchChange}
            pendo-tag-name={pendoTagName}
            className={disabled ? 'disabled-switch' : ''}
            disabled={disabled}
            enabledLabel={t.SHARED.ENABLED().toUpperCase()}
            disabledLabel={t.SHARED.DISABLED().toUpperCase()}
          />
        </NvTooltip>
        {labelText && (
          <span className='ml-2'>{labelText}</span>
        )}
      </div>
    </React.Fragment>
  );
};

export default AdvancedSettingsSwitch;
