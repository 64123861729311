import { useAngularPreventLecturePageNavigation } from '../hooks/use-prevent-lecture-page-navigation';

/* @ngInject */
export default function VideoDiscussionEditFormModalController(
  $uibModalInstance,
  StateManager,
  CurrentCourseManager,
  CurrentUserManager,
  DiscussionsManager,
  ConfirmationOverlays,
  PostsResources,
  PostModel,
  $scope,
  $window,
  $stateParams,
  currentVideo,
  editing,
  lecturePage,
  $timeout,
  _,
) {
  const vm = this;

  vm.currentVideo = currentVideo;
  vm.editing = editing;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.DiscussionsManager = DiscussionsManager;
  vm.isContentManagementCollection = CurrentCourseManager.course.isContentManagementCollection;
  vm.isLinked = lecturePage.isLinked;

  let dismissedByUser = false;
  if (vm.currentVideo.topic) {
    vm.postDraft = _.extend(vm.currentVideo.topic);
  } else {
    vm.postDraft = {
      title: '',
      body: '',
      topic: null,
      starterId: null,
      expandedOnLoad: true,
      forumId: null,
    };
  }

  vm.postDraft.metaContent = {
    id: currentVideo.id,
    type: 'LectureVideo',
  };

  if (!vm.postDraft.topicId && vm.postDraft.forum?.id) {
    vm.postDraft.topicId = vm.postDraft.forum?.id;
  }

  DiscussionsManager.initialize({ catalogId: $stateParams.catalogId });
  DiscussionsManager.getAllTopics(null, { nameList: true })
    .then(() => {
      if (vm.postDraft.topicId) {
        vm.postDraft.topic = DiscussionsManager.findTopic(vm.postDraft.topicId);
      } else {
        $timeout(() => {
          vm.postDraftForm.$setValidity('selectedTopic', vm.isContentManagementCollection);
        });
      }
    });
  CurrentCourseManager.course.getTeachingTeamMembers()
    .then(() => {
      if (CurrentCourseManager.course.teachingTeamMembers) {
        if (!vm.postDraft.starterId) {
          vm.postDraft.starterId = CurrentUserManager.user.id;
          vm.postDraft.user = CurrentUserManager.user;
        }

        vm.discussionStarterOptions = _.pluck(_.clone(CurrentCourseManager.course.teachingTeamMembers), 'user');

        // if the original starter isn't already in the list, add
        if (!_.findWhere(vm.discussionStarterOptions, { id: vm.postDraft.user.id })) {
          vm.discussionStarterOptions.unshift(vm.postDraft.user);
        }

        // if the current user isn't in the list, add
        if (!_.findWhere(vm.discussionStarterOptions, { id: CurrentUserManager.user.id })) {
          vm.discussionStarterOptions.unshift(vm.CurrentUserManager.user);
        }
      }
    });

  vm.selectTopic = function (topic) {
    if (topic) {
      vm.postDraft.topic = topic;
    } else {
      vm.postDraft.topic = {
        isNewTopic: true,
      };
    }

    vm.postDraftForm.$setValidity('selectedTopic', true);
    vm.postDraftForm.$setDirty();
  };

  vm.changeDiscussionStarter = function (newDiscussionStarterUser) {
    vm.postDraft.starterId = newDiscussionStarterUser.id;

    vm.postDraftForm.$setDirty();
  };

  vm.findDiscussionStarterById = function (userId) {
    return _.findWhere(vm.discussionStarterOptions, { id: userId });
  };

  vm.filterTopicOptions = function () {
    return _.filter(DiscussionsManager.allTopics, (topic) => topic.id > 0);
  };

  vm.save = () => {
    // create discussion
    let payload;
    dismissedByUser = true;
    if (vm.postDraft?.topic?.id && !vm.postDraft?.id) {
      vm.postDraft.forumId = vm.postDraft.topic.id;
      payload = {
        catalogId: $stateParams.catalogId,
        topic: _.pick(vm.postDraft, 'body', 'forumId', 'metaContent', 'starterId'),
      };

      PostsResources.createPost({ catalogId: $stateParams.catalogId, id: vm.postDraft.topic.id }, payload).$promise.then(() => {
        $uibModalInstance.close({ action: 'create' });
      });
    } else if (vm.editing && vm.postDraft?.id) {
      // update
      let forumKey;
      if (vm.postDraft?.topic?.isNewTopic) {
        vm.postDraft.forumTitle = vm.postDraft.topic.title;
        forumKey = 'forumTitle';
      } else {
        vm.postDraft.forumId = vm.postDraft?.topic?.id;
        forumKey = 'forumId';
      }
      payload = {
        catalogId: $stateParams.catalogId,
        topic: _.pick(vm.postDraft, 'body', forumKey, 'metaContent', 'starterId'),
      };
      PostsResources.update({ catalogId: $stateParams.catalogId, id: vm.postDraft.id }, payload).$promise.then((response) => {
        const post = response.result;
        if (vm.postDraft.topic?.isNewTopic) {
          DiscussionsManager.addNewTopic(post.forum);
        } else {
          DiscussionsManager.setSinglePostData(
            PostModel.normalizePostData(post, {
              catalogId: DiscussionsManager.catalogId,
              owner: DiscussionsManager.findTopic(post.forumId),
            }), true, vm.postDraft.topicId,
          );
        }
        $uibModalInstance.close({ action: 'update' });
      });
    } else {
      // new topic
      vm.postDraft.forumTitle = vm.postDraft.topic?.title;
      payload = {
        catalogId: $stateParams.catalogId,
        topic: _.pick(vm.postDraft, 'body', 'forumTitle', 'metaContent', 'starterId'),
      };

      PostsResources.createPostwithNewTopic({ catalogId: $stateParams.catalogId, id: vm.postDraft.topic?.id }, payload).$promise.then((response) => {
        const post = response.result;
        DiscussionsManager.addNewTopic(post.forum);
        $uibModalInstance.close({ action: 'update_with_topic' });
      });
    }
  };

  function hasUnsavedChanges() {
    return vm.postDraftForm && (vm.postDraftForm.$dirty
        || (vm.postDraft.topic?.id !== vm.postDraft.forumId)
        || (vm.postDraft.user?.id !== vm.postDraft.starterId));
  }

  const checkUnsavedChanges = () => hasUnsavedChanges();

  useAngularPreventLecturePageNavigation($scope, checkUnsavedChanges);

  // warn if leaving the modal and have unsaved changes
  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(checkUnsavedChanges,
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    'FORM.NAVIGATE_AWAY.CLOSE_WINDOW',
    undefined,
    undefined,
    () => {
      vm.deregisterModalClosing?.();
      $uibModalInstance.dismiss();
    });

  vm.deregisterModalClosing = $scope.$on('modal.closing', ($event) => {
    if (hasUnsavedChanges() && !dismissedByUser) {
      $event.preventDefault();

      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        dismissedByUser = true;
        $uibModalInstance.dismiss();
      });
    }
  });

  $scope.$on('$destroy', () => {
    angular.element($window).off('beforeunload');
    vm.deregisterStateChangeStart?.();
  });
}
