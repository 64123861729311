import { css } from '@emotion/react';
import ClickableContainer from 'components/clickable-container';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip, { TextAlign } from 'shared/components/nv-tooltip';
import { gray5, primary, white } from 'styles/global_defaults/colors';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { useMediaQuery } from 'react-responsive';
import { screenMdMin } from 'styles/global_defaults/media-queries';

export type LhsMenuItemProps = {
  label: string;
  icon: string;
  highlighted: boolean;
  expanded: boolean;
  onClick: (label?: string) => void | React.MouseEventHandler<HTMLDivElement>;
  dataQa: string;
};

export const LhsMenuItem = ({
  label,
  icon,
  onClick,
  highlighted,
  expanded,
  dataQa,
}: LhsMenuItemProps) => {
  const stylesBase = css`
    padding: 0;
    height: 50px;
    color: ${gray5};
    background: none;
    justify-content: center;
    gap: ${standardSpacing}px;

    &.highlighted {
      color: ${white};
      background-color: ${primary};
    }

    &.expanded {
      padding-left: 42px;
      justify-content: flex-start;
    }
  `;

  /**
   * Here, the expanded prop is referring to $state.current.data.expandedLHS
   * which is a state property used to set the LHS to be expanded even in
   * the desktop view. We do need to check that, but we also need to check
   * for the LHS being in the expanded state because we are on mobile/tablet
   * So for that we need this mediaQuery:
  */
  const isDesktop = useMediaQuery({
    query: `(min-width: ${screenMdMin}px)`,
  });

  return (
    <NvTooltip
      text={label}
      placement='right'
      textAlign={TextAlign.LEFT}
      enabled={!expanded && isDesktop}
      preventOverflow={false}
    >
      <ClickableContainer
        css={stylesBase}
        className={`
          w-100 border-0 d-flex align-items-center
          ${highlighted ? 'highlighted' : ''}
          ${(expanded || !isDesktop) ? 'expanded' : ''}
        `}
        onClick={onClick as unknown as React.MouseEventHandler<HTMLDivElement>}
        aria-label={label}
        data-qa={dataQa}
      >
        <NvIcon icon={icon} data-qa={`${dataQa}_ICON`} size='small' />
        {(expanded || !isDesktop) && (<div>{label}</div>)}
      </ClickableContainer>
    </NvTooltip>
  );
};

export default LhsMenuItem;
