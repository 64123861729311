import React, { useContext, useMemo, useState } from 'react';
import t from 'react-translate';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import CourseInfo from '../course-info';
import { CloneCourseContext, CloningType, cloneCourseContextType } from '../hooks/use-clone-course-form';
import CourseForm from './course-form';
import MediaAndRegistration from './media-and-registration';
import ClickableContainer from 'components/clickable-container';
import NvIcon from 'shared/components/nv-icon';
import { css } from '@emotion/react';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';

const style = css`
  .add-button {
    max-width: fit-content;
    .add-cohort {
      padding-top: ${standardSpacing}px;
      span {
        padding-left: ${halfSpacing}px;
      }
    }
  }
`;

const StepOne = () => {
  const cloneCourseFormMethods = useContext<cloneCourseContextType>(CloneCourseContext);
  const {
    course,
    cloningType,
    nextStep,
    addNewCoursesToForm,
    formData,
    errorCourseIndexList,
  } = cloneCourseFormMethods;

  const initialCourseFormData = {
    catalogId: course.catalogId,
    name: course.name,
    officialReleaseDate: null,
  };
  const [courseFormList, setCourseFormList] = useState(formData.coursesList || [initialCourseFormData]);
  const courseAdmins = useSelector((state) => state.app.orderedCourseUsers ? state.app.orderedCourseUsers?.admins : []);

  // Adding new cohort courses when comes from a Primary Course
  const addNewCohort = () => {
    const newCourseFormList = [...courseFormList];
    newCourseFormList.push(initialCourseFormData);
    setCourseFormList(newCourseFormList);
  };

  // Removing the selected cohort course
  const removeCohort = (index) => {
    const newCourseFormList = [...courseFormList];
    newCourseFormList.splice(index, 1);
    setCourseFormList(newCourseFormList);
  };

  const updateCourse = (courseIndex, courseModified) => {
    const newCourseFormList = [...courseFormList];
    newCourseFormList[courseIndex] = courseModified;
    setCourseFormList(newCourseFormList);
  };

  /**
   * Validates the uniqueness of every new catalog id
   * and if the new course has a start date.
   */
  const isValid = useMemo(() => {
    const anyEmptyDateCourse = courseFormList.find(newCourse => {
      // It has no start date
      if (!newCourse.officialReleaseDate) {
        return true;
      }
    });
    return !anyEmptyDateCourse && !errorCourseIndexList.length;
  }, [courseFormList, errorCourseIndexList]);

  const handleNextStep = () => {
    addNewCoursesToForm(courseFormList);
    nextStep();
  };

  return (
    <div css={style}>
      <CourseInfo course={course} totalCourseAdmins={courseAdmins.length}/>
      {
        courseFormList.map((courseForm, index) => <CourseForm
          key={index}
          courseForm={courseForm}
          cloningType={cloningType}
          index={index}
          updateCourse={updateCourse}
          removeCohort={removeCohort}
          showRemoveButton={courseFormList.length > 1}
          courseFormList={courseFormList}
        />)
      }
      {
        cloningType === CloningType.COHORT_COURSE && (
          <ClickableContainer
            onClick={addNewCohort}
            className='add-button'
          >
            <div className='add-cohort text-primary d-flex'>
              <NvIcon icon='add' size='small' />
              <span>
                {t.COURSES.CLONE.STEPS.STEP_ONE.FORM.ADD_ANOTHER_COHORT()}
              </span>
            </div>
          </ClickableContainer>
        )
      }
      <MediaAndRegistration />
      <div className='buttons'>
        <Button type='button' disabled={!isValid} onClick={handleNextStep}>
          {t.COURSES.CLONE.BUTTONS.NEXT()}
        </Button>
      </div>
    </div>
  );
};

export default StepOne;
