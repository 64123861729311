import React from 'react';

type NativeListeners = {
  [eventName: string]: (e: Event) => void;
};

/**
 * This hook exists for ocassions where we need to attach event listeners
 * natively (via "addEventListener" method).
 * One use case of that is the fix of NOV-79876: https://github.com/novoed/NovoEdWeb/pull/6423
 */
const useNativeListeners = <T extends HTMLElement>(
  elementRef: React.RefObject<T>,
  listeners: NativeListeners,
) => {
  const listenersRef = React.useRef<NativeListeners>();
  listenersRef.current = listeners;

  React.useEffect(() => {
    const element = elementRef.current;
    const actualListeners = {};

    Object.keys(listenersRef.current).forEach((eventName) => {
      actualListeners[eventName] = (e) => {
        listenersRef.current[eventName](e);
      };

      element.addEventListener(eventName, actualListeners[eventName]);
    });


    return () => {
      Object.keys(actualListeners).forEach((eventName) => {
        element.removeEventListener(eventName, actualListeners[eventName]);
      });
    };
  }, [elementRef]);
};

export default useNativeListeners;
