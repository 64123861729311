import { ConditionalWrap } from 'components/conditional-wrap';
import NvFilePicker from 'shared/components/nv-filepicker';
import { addNewComponent, addTemporaryComponent } from 'redux/actions/lecture-components';
import { useContext } from 'react';
import { TemporaryLectureComponent } from 'redux/schemas/models/lecture-component';
import uuid from 'react-uuid';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { hideAddUI } from 'lecture_pages/templates/components/nv-add-component';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { DirectlyUploadedFileWorkflow, FileWorkflow, WorkflowParams } from '.';
import getDefaultNewComponentData from '../data/new-component-defaults';
import LecturePageContext, { LecturePageContextType } from '../lecture-page-context';

// TODO: markFilesForUpload() and getTemporaryLcId are used outside of this workflow and should probably be
// moved elsewhere

/** Modifies the LecturePageContext to indicate the selected file(s) should be uploaded to the backend.
 * We do *not* do the upload in this workflow because LectureComponents must use useUploadFiles() to both
 * trigger the upload & stream upload progress into their loading bar UIs */
export const markFilesForUpload = (ctx: LecturePageContextType, files: File[], lectureComponentId: number) => {
  const componentFiles = ctx.filesToUpload[lectureComponentId] ?? [];
  componentFiles.push(...files);

  ctx.setFilesToUpload({
    ...ctx.filesToUpload,
    [lectureComponentId]: componentFiles,
  });
};

export const removeMarkedFile = (ctx: LecturePageContextType, lectureComponentId: number, index: number) => {
  const componentFiles = ctx.filesToUpload[lectureComponentId];

  if (componentFiles && componentFiles[index]) {
    componentFiles.splice(index, 1);

    ctx.setFilesToUpload({
      ...ctx.filesToUpload,
      [lectureComponentId]: componentFiles,
    });
  }
};

/** Generates a string ID for a temporary lecture component */
export const getTemporaryLcId = () => uuid().replaceAll('-', '');

/** Wrapped around a component add button to trigger a file select dialog and
 * use the selected file during the component creation process */
const FileWorkflowHandler = (props: {
  workflow: FileWorkflow | DirectlyUploadedFileWorkflow,
  children: React.ReactNode,
  // TODO: The 'save' prop should be removed from this now that we support temporary components
} & Pick<WorkflowParams, 'componentType' | 'lectureComponent' | 'mode' | 'save'>) => {
  const dispatch = useAppDispatch();
  const ctx = useContext(LecturePageContext);
  const { newComponentIndex } = useSelector(state => state.app.lecturePage);
  const { catalogId, lecturePageId } = useLecturePageParams();

  const onCancel = () => {
    props.workflow?.onCancel();
  };

  /** After file selection, mark the selected files for upload, either immediately if this is a component edit
   * or after the new component has been created and we're creating a new component */
  const onChange = (files: File[]) => {
    if (props.workflow.type === 'directlyUploadedFile') {
      const fileToUpload = files[0];
      const newComponent = getDefaultNewComponentData(props.componentType);

      newComponent.id = getTemporaryLcId();
      newComponent.index = newComponentIndex;
      (newComponent as any).file = fileToUpload;

      dispatch(addTemporaryComponent(newComponent as TemporaryLectureComponent));
      markFilesForUpload(ctx, [fileToUpload], newComponent.id);
    } else {
      // TODO: Unused, remove
      props.workflow.onChange?.(props.lectureComponent, files);

      if (props.mode === 'edit') {
        markFilesForUpload(ctx, files, props.lectureComponent.id);
        return;
      }

      // TODO: Right now this workflow is rigged so that selecting multiple files causes
      // creating one new component per file. Some components may want multiple files uploaded for a single component
      // Let's make this configurable

      files.forEach((file, i) => {
        const newComponent = getDefaultNewComponentData(props.componentType);
        // Createa a random ID to distinguish this from server saved components. We
        // remove `-` characters because the cannot be used in JS var names, and
        // we do generate angular expressions with this ID for AngularLectureComponents
        newComponent.id = getTemporaryLcId();
        // TODO: We're relying on all temp components having the same index being rendered
        // in the order they're added to the temp list
        newComponent.index = newComponentIndex;

        dispatch(addTemporaryComponent(newComponent as TemporaryLectureComponent));
        markFilesForUpload(ctx, [file], newComponent.id);
      });
    }

    if (props.mode === 'new') {
      hideAddUI(dispatch);
    }
  };

  return (
    <NvFilePicker
      accept={props.workflow.accept}
      onChange={onChange}
      onCancel={onCancel}
      multiple={props.workflow.type === 'directlyUploadedFile' ? false : props.workflow.multiple}
    >
      {props.children}
    </NvFilePicker>
  );
};

export const ConditionalWrapFileWorkflow = (props: WorkflowParams) => {
  const condition = props.metadata?.workflow.type === 'file'
    || props.metadata?.workflow.type === 'directlyUploadedFile';

  return (
    <ConditionalWrap
      condition={condition}
      wrap={condition && ((children) => (
        <FileWorkflowHandler
          workflow={props.metadata.workflow as FileWorkflow | DirectlyUploadedFileWorkflow}
          mode={props.mode}
          componentType={props.componentType}
          lectureComponent={props.lectureComponent}
          save={props.save}
        >
          {children}
        </FileWorkflowHandler>
      ))}
    >
      {props.children}
    </ConditionalWrap>
  );
};


export default FileWorkflowHandler;
