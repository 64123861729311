/* @ngInject */
export default function QuizzesManager(

  _,
  QuizModel,
  QuizzesResources,
  ExercisesManager,
  $stateParams,
  $state,
) {
  const manager = {
    // functions
    getQuiz,
    setCurrentSubmission,
    save,
    submit,
    closeExternalQuiz,
    resetCurrentQuiz,

    // data:
    currentQuiz: null,
  };

  initialize();

  function initialize() {
  }

  function getQuiz(catalogId, id, exerciseId) {
    return QuizModel.get(catalogId, id, { exerciseId }).then((quiz) => {
      manager.currentQuiz = quiz;
    });
  }

  function resetCurrentQuiz() {
    manager.currentQuiz = null;
  }

  function setCurrentSubmission(submission) {
    if (submission.responses) {
      manager.currentQuiz.setResponses(submission.responses);
    }
  }

  function save() {
    const currentSubmission = getCurrentSubmission();

    if (currentSubmission) {
      return manager.currentQuiz.persist(currentSubmission.id, true)
        .then((submission) => {
          ExercisesManager.currentExercise.unsubmittedSubmissions[0] = submission;

          if (ExercisesManager.currentExercise.progress === 'not_started') {
            ExercisesManager.currentExercise.progress = 'in_progress';
          }

          return submission;
        });
    }
    return manager.currentQuiz.persist(null, true)
      .then((submission) => {
        ExercisesManager.currentExercise.unsubmittedSubmissions[0] = submission;

        if (ExercisesManager.currentExercise.progress === 'not_started') {
          ExercisesManager.currentExercise.progress = 'in_progress';
        }

        return submission;
      });
  }

  function submit() {
    const currentSubmission = getCurrentSubmission();

    if (currentSubmission) {
      return manager.currentQuiz.persist(currentSubmission.id, false)
        .then((submission) => {
          ExercisesManager.currentExercise.unsubmittedSubmissions.pop();
          ExercisesManager.currentExercise.submissions[0] = submission;
          ExercisesManager.currentExercise.progress = 'completed';

          return submission;
        });
    }
    return manager.currentQuiz.persist(null, false)
      .then((submission) => {
        ExercisesManager.currentExercise.submissions[0] = submission;
        ExercisesManager.currentExercise.progress = 'completed';

        return submission;
      });
  }

  function closeExternalQuiz(questionSetId) {
    ExercisesManager.shouldReload = true;
    $state.go('exercise-show');
    return QuizzesResources.createQss({ catalogId: $stateParams.catalogId, exerciseId: $stateParams.exerciseId, questionSetId }, '');
  }

  function getCurrentSubmission() {
    return _.first(ExercisesManager.currentExercise.unsubmittedSubmissions) || _.first(ExercisesManager.currentExercise.submissions);
  }

  return manager;
}
