import { css } from '@emotion/react';
import { useContext } from 'react';
import t from 'react-translate';
import { AiQuestionsModalContext } from './ai-questions-modal';

type QuestionsHeaderProps = {
  totalCount: number;
  displayQuestionCount: number;
  isLoading: boolean
};

const styles = css`
  &.position-sticky {
    background: white;
    top: 0
  }
`;

const QuestionsHeader = (props: QuestionsHeaderProps) => {
  const { totalCount, displayQuestionCount, isLoading } = props;
  const { selectedQuestionIds } = useContext(AiQuestionsModalContext);

  return (
    <div
      className='position-sticky d-flex justify-content-between pt-5 pb-2'
      css={styles}
    >
      <div className='text-medium bold'>
        {isLoading
          ? t.LECTURE_PAGES.COMPONENTS.QUIZ.AI_QUESTIONS.GENERATED_SO_FAR(totalCount)
          : t.LECTURE_PAGES.COMPONENTS.QUIZ.AI_QUESTIONS.QUESTIONS_CHOICE(displayQuestionCount, totalCount)}
      </div>
      <div className='text-medium bold'>
        {t.LECTURE_PAGES.COMPONENTS.QUIZ.AI_QUESTIONS.SELECTED_QUESTIONS(selectedQuestionIds.length)}
      </div>
    </div>
  );
};

export default QuestionsHeader;
