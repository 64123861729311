import store from 'redux/store';
import { ActivityKey } from 'redux/schemas/models/activity';
import { setActivityProgress } from 'redux/actions/lecture-components';

/* @ngInject */
export default function TimelineQuizLectureComponentModelService(

  _,
  nvUtil,
  moment,
) {
  const TimelineQuizLectureComponentModel = function (attributes) {
    const _this = this;
    const currentMoment = moment();
    let deadlineMoment;
    const base = {
      templateUrl: 'timelines/templates/nv-timeline-lecture-quiz.html',
      canHaveHardDeadline: true,
    };

    /** Setting Up Basic Attributes * */
    _.extend(_this, base, attributes);
    // passed lecturePage, lectureSection, course
    preprocess();

    /** Public Functions * */
    _.extend(_this, {
      getStatus,
      isCompleted,
      isMissed,
      isTodoRequiredForCompletion,
      updateComponentPointsAndProgress,
      updateComponentProgress,
      isPrereqCompleted,
      getPayload,
      hasDeadline,
      setDeadline,
    });

    /** Function Declarations * */
    /* Used Publicly */
    function getStatus() {
      return _this.quiz.progress;
    }

    function isCompleted() {
      return _this.quiz.progress === 'completed';
    }

    function isMissed() {
      return _this.quiz.progress === 'missed';
    }

    function isTodoRequiredForCompletion() {
      return _this.quiz.isRequiredForCompletion;
    }

    function updateComponentPointsAndProgress(componentType, componentId, pointsReceived, totalPoints) {
      if ((_this.type === componentType && _this.id === componentId) || (componentType === 'quiz' && _this.quiz.id === componentId)) {
        _this.quiz.progress = 'completed';
        store.dispatch(setActivityProgress({
          pointsReceived,
          progress: _this.quiz.progress,
          activityKey: ActivityKey.QUIZZES,
          activityPayloadId: _this.quiz.id,
        }));

        _this.quiz.pointsReceived = pointsReceived;
        _this.pointsReceived = pointsReceived;

        if (_.isNumber(totalPoints)) {
          _this.currentTotalPoints = totalPoints;
        }

        viewPreprocess();

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function updateComponentProgress(componentType, componentId, progress) {
      if ((_this.type === componentType && _this.id === componentId) || (componentType === 'quiz', _this.quiz.id === componentId)) {
        _this.quiz.progress = progress;
        store.dispatch(setActivityProgress({
          progress,
          activityKey: ActivityKey.QUIZZES,
          activityPayloadId: _this.quiz.id,
        }));

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function isPrereqCompleted(prereq) {
      if ((prereq.type === _this.type && prereq.id === _this.id) || (prereq.type === 'Quiz' && prereq.id === _this.quiz.id)) {
        return isCompleted();
      }
      return false;
    }

    function getPayload() {
      return _.pick(_this, ['title', 'expirationDate', 'hardDeadline']);
    }

    function hasDeadline() {
      return !!this.expirationDate;
    }

    function setDeadline(newDeadline) {
      this.expirationDate = newDeadline;
    }

    function isDeadlineBeforeLesson() {
      return _this.quiz.deadlineMoment < _this.lecturePage.releaseDate;
    }

    /* Used Privately */
    function preprocess() {
      _.extend(_this, _.pick(_this.quiz, 'title', 'hardDeadline', 'isTodo', 'hasStructuralIssues',
        'expirationDate', 'expired', 'pointsReceived', 'totalPoints', 'scheduledCommunicationsCount'));
      _this.activityId = _this.quiz.id;
      deadlineMoment = moment(_this.quiz.expirationDate);

      // current total points takes into account if the the user can score more
      // so if the user has maxed out attempts, the total points will be the same as the pointsReceived
      _this.currentTotalPoints = nvUtil.getCurrentTotalPoints(_this.totalPoints, _this.lecturePage.releaseDate, _this.course.expiringPointsEnabled);

      viewPreprocess();
    }


    function viewPreprocess() {
      _this.showDueSoon = showDueSoon();

      if (_this.pointsReceived > _this.currentTotalPoints) {
        _this.currentTotalPoints = _this.pointsReceived ? _this.pointsReceived : 0;
      }
      _this.displayTotalPointsOnly = !_this.pointsReceived && _this.currentTotalPoints;
    }

    function showDueSoon() {
      if (_this.expirationDate) {
        return !isCompleted()
            && deadlineMoment > currentMoment
            && deadlineMoment < moment().add(7, 'days');
      }
      return false;
    }
  };

  return TimelineQuizLectureComponentModel;
}
