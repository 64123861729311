import moment, { Moment } from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MediaSourceType } from 'recording/services/media-stream';
import RecordRTC from 'recordrtc';
import { Reader, Decoder, tools } from 'ts-ebml';
import useTimer from './use-timer';

const readAsArrayBuffer = (blob: Blob) => new Promise(resolve => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(blob);
  reader.onloadend = () => {
    resolve(reader.result);
  };
});

// workaround for Chrome 92 issue - https://github.com/legokichi/ts-ebml/issues/33
// current version of getSeekableBlob used by RecordRTC is broken (https://github.com/muaz-khan/RecordRTC/issues/760)
const getSeekableBlob = async (blob: Blob) => {
  try {
    const decoder = new Decoder();
    const reader = new Reader();
    reader.logging = false;
    reader.drop_default_duration = false;

    const buffer = (await readAsArrayBuffer(blob)) as ArrayBuffer;
    let elms = decoder.decode(buffer);
    const validEmlType = ['m', 'u', 'i', 'f', 's', '8', 'b', 'd'];
    elms = elms?.filter((elm) => validEmlType.includes(elm.type));
    elms.forEach(elm => {
      if (elm.type === 'u' && elm.name === 'Timestamp') {
        // This is necessary because there is a bug in ts-ebml
        // It should be looking for 'Timestamp', but instead is looking for 'Timecode'
        // This is due to an update to Matroska that ts-ebml has not accounted for
        // https://github.com/legokichi/ts-ebml/pull/42 <-While this pr remains left open, we will need to keep this hack.
        elm.name = 'Timecode';
      }
      reader.read(elm);
    });
    reader.stop();

    const refinedMetadataBuf = tools.makeMetadataSeekable(reader.metadatas, reader.duration, reader.cues);
    const body = buffer.slice(reader.metadataSize);

    return new Blob([refinedMetadataBuf, body], { type: blob.type });
  } catch (error) {
    console.error(error);
  }
  return null;
};

// force all browsers to use the same container + code
const getRecordingMimeType = (recordRTCType: 'video' | 'audio') => {
  switch (recordRTCType) {
    case 'video':
      return 'video/webm;codecs=vp9';
    case 'audio':
      return 'audio/webm';
    default:
  }

  return undefined;
};

// Convert our media type to Record rtc type
const getRecordRTCType = (mediaStreamType: MediaSourceType) => {
  switch (mediaStreamType) {
    case MediaSourceType.MIC:
      return 'audio';
    default:
      return 'video';
  }
};

const getRecordRTCSettings = (mediaStreamType: MediaSourceType) => {
  const recordRTCType = getRecordRTCType(mediaStreamType);
  const settings: RecordRTC.Options = {
    type: recordRTCType,
    disableLogs: true,
    mimeType: getRecordingMimeType(recordRTCType),
  };

  return settings;
};

/**
 * wraps the provided stream with a wrapped RecordRTC (https://github.com/muaz-khan/RecordRTC) instance
 */
const useMediaRecorder = (initialMediaStream: MediaStream, mediaStreamType: MediaSourceType, maxLength: number) => {
  const recorder = useRef<any>();

  useEffect(() => {
    if (initialMediaStream) {
      recorder.current = new RecordRTC(initialMediaStream, getRecordRTCSettings(mediaStreamType));
    }
  }, [initialMediaStream]);

  const [recordedLength, setRecordedLength] = useState(0); // length of already recorded portion
  // startTime is needed to share this with the outside world and have React react properly
  const [startTime, setStartTime] = useState<Moment>(); // start time of call to startRecording
  // startTimeRef is needed to keep an always updated instance where we pass stopRecording as a callback
  const startTimeRef = useRef<Moment>(); // start time of call to startRecording
  const [, startTimer, pauseTimer, resumeTimer, stopTimer] = useTimer();
  const [mediaBlob, setMediaBlob] = useState<Blob>();

  const initializeRecorder = (mediaStream: MediaStream) => {
    recorder.current = new RecordRTC(mediaStream, getRecordRTCSettings(mediaStreamType));
  };

  const updateStartTime = (newStartTime: Moment) => {
    setStartTime(newStartTime);
    startTimeRef.current = newStartTime;
  };

  const stopRecording = (resetAll = false) => new Promise<Blob>((resolve, reject) => {
    const currentStartTime = startTimeRef.current;

    if (resetAll) {
      setRecordedLength(0);
    } else if (currentStartTime) {
      setRecordedLength((currentRecordedLength) => (currentRecordedLength + moment().diff(currentStartTime, 'seconds')));
    }
    updateStartTime(null);
    stopTimer();

    return recorder.current?.stopRecording(() => {
      const newBlob = recorder.current.getBlob();

      if (getRecordRTCType(mediaStreamType) === 'video'
        || getRecordRTCType(mediaStreamType) === 'audio') {
        getSeekableBlob(newBlob).then((seekableBlob) => {
          setMediaBlob(seekableBlob);
          resolve(seekableBlob);
        });
      } else {
        (RecordRTC as any).getSeekableBlob(newBlob, (seekableBlob) => {
          setMediaBlob(seekableBlob);
          resolve(seekableBlob);
        });
      }
    });
  });

  const startRecording = (onRecordingStoppedCallback: () => void = () => {}) => {
    if (!recorder.current) {
      throw new Error('no RecordRTC object found');
    }

    setRecordedLength(0);
    updateStartTime(moment());
    recorder.current.startRecording();
    startTimer(maxLength, async () => {
      await stopRecording();
      onRecordingStoppedCallback();
    });
  };

  const pauseRecording = () => {
    const currentStartTime = startTimeRef.current;

    if (currentStartTime) {
      setRecordedLength((currentRecordedLength) => currentRecordedLength + moment().diff(currentStartTime, 'seconds'));
    }
    updateStartTime(null);
    pauseTimer();

    recorder.current?.pauseRecording();
  };

  const resumeRecording = () => {
    updateStartTime(moment());
    resumeTimer();

    recorder.current?.resumeRecording();
  };

  const destroyCurrentRecorder = useCallback(() => {
    recorder.current?.destroy();
    recorder.current = null;
  }, [recorder]);

  useEffect(() => () => destroyCurrentRecorder, [destroyCurrentRecorder]);

  /**
   * replace the current recorder instance; this is useful for when the user stops recording, but wants to record again
   * when the user stops recording, the streams must be stopped as well and cannot be restarted
   */
  const replaceRecorder = (newMediaStream: MediaStream) => {
    stopTimer();
    destroyCurrentRecorder();
    setRecordedLength(0);
    updateStartTime(null);

    recorder.current = new RecordRTC(newMediaStream, getRecordRTCSettings(mediaStreamType));
  };

  return {
    recordedLength,
    startTime,
    mediaBlob,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    destroyCurrentRecorder,
    replaceRecorder,
    initializeRecorder,
  };
};

export default useMediaRecorder;
