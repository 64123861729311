/* @ngInject */
export default function InvitationAndRequestModel(

  InvitationsAndRequestsResources,
  _,
  $q,
  $sce,
  nvUtil,
) {
  const CLASS_NAME_TO_VERB = {
    MembershipRequest: 'MembershipRequest',
    TeamMember: 'MembershipInvitation',
  };

  const INVITATION_AND_REQUEST_TYPES = {
    MEMBERSHIP_REQUEST: 'MembershipRequest',
    MEMBERSHIP_INVITATION: 'TeamMember',
  };

  class InvitationAndRequest {
    static getAll(userId) {
      return InvitationsAndRequestsResources.get({ userId }).$promise
        .then((response) => {
          _.each(response.result, (course) => {
            course.requests = _.map(course.requests, (invitationAndRequest) => {
              invitationAndRequest.catalogId = course.catalogId;
              return new InvitationAndRequest(invitationAndRequest);
            });
          });

          return response.result;
        });
    }

    constructor(attributes) {
      _.extend(this, attributes);

      this.uniqueId = this.id + this.className;
    }

    accept() {
      const resourceAction = `accept${CLASS_NAME_TO_VERB[this.className]}`;

      return InvitationsAndRequestsResources[resourceAction]({
        catalogId: this.catalogId,
        teamId: this.team.id,
        invitationAndRequestId: this.id,
      }).$promise.then((response) => {
        _.extend(this, response.result);
        return this;
      }, (response) => {
        this.errors = response.data.errors;
        this.error = response.data.error;

        return this;
      });
    }

    reject() {
      const resourceAction = `reject${CLASS_NAME_TO_VERB[this.className]}`;

      return InvitationsAndRequestsResources[resourceAction]({
        catalogId: this.catalogId,
        teamId: this.team.id,
        invitationAndRequestId: this.id,
      }).$promise.then(() => {
      }, (response) => {
        this.errors = response.data.errors;
        this.error = response.data.error;
        return $q.reject(response);
      });
    }

    cancel() {
      const resourceAction = `cancel${CLASS_NAME_TO_VERB[this.className]}`;

      return InvitationsAndRequestsResources[resourceAction]({
        catalogId: this.catalogId,
        teamId: this.team.id,
        invitationAndRequestId: this.id,
      }).$promise.then(() => {
      }, (response) => {
        this.errors = response.data.errors;
        this.error = response.data.error;
        return $q.reject(response);
      });
    }

    isTeamSpecificInvitation() {
      return this.isInvitation() && this.isTeamSpecific();
    }

    isGroupSpecificInvitation() {
      return this.isInvitation() && this.isGroupSpecific();
    }


    isMembershipRequestMadeByUser(userId) {
      return this.isMembershipRequest() && this.user.id === userId;
    }

    isTeamSpecificMembershipRequestMadeByUser(userId) {
      return this.isMembershipRequestMadeByUser(userId) && this.isTeamSpecific();
    }

    isGroupSpecificMembershipRequestMadeByUser(userId) {
      return this.isMembershipRequestMadeByUser(userId) && this.isGroupSpecific();
    }

    // helpers
    isInvitation() {
      return this.className === INVITATION_AND_REQUEST_TYPES.MEMBERSHIP_INVITATION;
    }

    isMembershipRequest() {
      return this.className === INVITATION_AND_REQUEST_TYPES.MEMBERSHIP_REQUEST;
    }

    isTeamSpecific() {
      return !this.isGroup;
    }

    isGroupSpecific() {
      return this.isGroup;
    }

    getTemplateUrl() {
      return `invitations_and_requests/templates/${nvUtil.toSnakeCase(this.className)}.html`;
    }
  }

  return InvitationAndRequest;
}
