import React, { useContext } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import moment from 'moment';

import { AngularServicesContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { RootState } from 'redux/schemas';
import { ActivityProgress, ActivityType, TimelineActivity } from 'redux/schemas/models/activity';
import { resetShowPortableCompletionPanel } from 'redux/actions/timeline';
import { getCourseAliases } from 'redux/selectors/course';
import { VideoType } from 'redux/schemas/models/video';

import NvIcon from 'shared/components/nv-icon';
import { gray5, primary } from 'styles/global_defaults/colors';
import { doubleSpacing, halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import ClickableContainer from 'components/clickable-container';
import { config } from '../../../../config/pendo.config.json';

const EXTERNAL_ACTIVITY_TO_ICON = {
  quiz: 'quiz',
  survey: 'survey',
  assignment: 'assignments',
  feedback: 'evaluation',
  video: 'video',
  reading: 'read',
  discussion: 'conversations',
  audio: 'audio',
};

type TimelineActivityProps = {
  activity: TimelineActivity,
};

const ActivityIcon = ({
  activity,
}: TimelineActivityProps) => {
  let activityIcon = null;

  /** For the following the backend returns the type in activityType */
  switch (activity.activityType) {
    case ActivityType.EXERCISE:
      // For exercise skills rating, activityType is return as same, so
      // escaping it
      activityIcon = activity.type !== ActivityType.EXERCISE_SKILLS_RATING
        ? 'assignments'
        : null;
      break;
    case ActivityType.LECTURE_VIDEO:
      // In case of Lecture video, the type is used to determine whether it
      // is video or audio
      activityIcon = activity.type as any === VideoType.VIDEO
        ? 'video'
        : 'audio';
      break;
    case ActivityType.PEER_EVALUATION:
      activityIcon = activity.isFeedbackPublic
        ? 'comments'
        : 'evaluation';
      break;
    case ActivityType.POST:
      activityIcon = 'conversations';
      break;
    case ActivityType.TEAM_FORMATION:
      activityIcon = 'team';
      break;
    default:
      break;
  }

  if (!activityIcon) {
    switch (activity.type) {
      case ActivityType.EXERCISE_SKILLS_RATING:
        activityIcon = 'skills-feedback-activity';
        break;
      case ActivityType.EXTERNAL_TOOL:
        activityIcon = EXTERNAL_ACTIVITY_TO_ICON[activity.activityType]
          ?? 'read';
        break;
      case ActivityType.GROUP_FORMATION:
        activityIcon = 'groups';
        break;
      case ActivityType.LIVE_SESSION:
        activityIcon = 'zoom';
        break;
      case ActivityType.POLL:
        activityIcon = 'polls';
        break;
      case ActivityType.POST:
        activityIcon = 'conversations';
        break;
      case ActivityType.PROFILE_REQUIREMENT:
        activityIcon = 'profile';
        break;
      case ActivityType.QUIZ:
      case ActivityType.PROGRESSIVE_QUIZ:
        activityIcon = 'quiz';
        break;
      case ActivityType.SURVEY:
        activityIcon = 'survey';
        break;
      case ActivityType.TEAM_DISCUSSION:
        activityIcon = 'conversations';
        break;
      case ActivityType.TIMED_EXAM:
        activityIcon = 'timedexam';
        break;
      case ActivityType.VIDEO_PRACTICE:
        activityIcon = 'media-practice';
        break;
      case ActivityType.VIDEO_PRACTICE_FEEDBACK:
        activityIcon = 'peer-feedback';
        break;
      default:
        activityIcon = 'read';
    }
  }

  let activityIconClass = null;
  if (activity.progress === 'completed'
    || activity.progress === 'approved'
    // Live session completed statuses
    || activity.progress === 'attended_manual'
    || activity.progress === 'attended_auto'
    || activity.progress === 'watched_recording') {
    activityIcon = 'check';
    activityIconClass = 'text-success';
  } else if (activity.progress === 'in_progress'
    || activity.progress === 'started'
    || activity.progress === 'pending') {
    activityIconClass = 'in-progress';
  }

  const styles = css`
    &.in-progress {
      color: ${primary};
      border: solid 2px ${gray5};
      border-color: ${gray5} ${primary} ${primary} ${gray5};
      border-radius: 50%;
      transform: rotate(-45deg);
      i {
        transform: rotate(45deg);
      }
    }
  `;

  return (
    <div
      css={styles}
      className={`activity-icon d-flex align-items-center justify-content-center ${activityIconClass}`}
    >
      <NvIcon icon={activityIcon} size='smallest' />
    </div>
  );
};

enum PointDisplayType {
  DEFAULT = 'default', // 20 points
  X_OF_Y = 'x_of_y', // 10/20
  FULL = 'full', // 20 (in green color)
}

const ActivityPoints = ({
  activity,
}: TimelineActivityProps) => {
  const aliases = useSelector((state) => getCourseAliases(state));

  if (!activity.totalPoints || !activity.totalPoints[0]) {
    return null;
  }

  const totalPoints = activity.totalPoints[0];

  const isStatusCompleted = activity.progress === 'completed'
    || activity.progress === 'approved'
    // Live session completed statuses
    || activity.progress === 'attended_manual'
    || activity.progress === 'attended_auto'
    || activity.progress === 'watched_recording';

  const isStatusInProgress = activity.pointsReceived > 0
    && (
      activity.progress === 'in_progress'
      || activity.progress === 'started'
      || activity.progress === 'pending'
    );

  // Activity completed but didn't get full points
  const isPartiallyCompleted = isStatusCompleted
    && activity.pointsReceived > 0
    && activity.pointsReceived < totalPoints;

  const isSuccessfullyCompleted = isStatusCompleted
    && activity.pointsReceived > 0
    && activity.pointsReceived >= totalPoints;

  let pointsDisplay = PointDisplayType.DEFAULT;

  if (isPartiallyCompleted || isStatusInProgress) {
    pointsDisplay = PointDisplayType.X_OF_Y;
  } else if (isSuccessfullyCompleted) {
    pointsDisplay = PointDisplayType.FULL;
  }

  return (
    <div
      className='activity-desc-points pl-2 d-inline'
    >
      <NvIcon
        icon={pointsDisplay !== PointDisplayType.DEFAULT ? 'highlight' : 'points'}
        size='xss-smallest'
        className={pointsDisplay !== PointDisplayType.DEFAULT ? 'text-success' : 'text-gray-3'}
      />
      <span className={`mx-1 label ${isSuccessfullyCompleted ? 'text-success' : 'text-gray-3'}`}>
        {pointsDisplay === PointDisplayType.FULL && totalPoints}
        {pointsDisplay === PointDisplayType.X_OF_Y && (
          <span>
            <span className='text-success font-weight-bold'>
              {activity.pointsReceived}
            </span>
            <span className='text-xsmall font-weight-bold'>
              /
              {totalPoints}
            </span>
          </span>
        )}
        {pointsDisplay === PointDisplayType.DEFAULT && (
          t.LECTURE_PAGES.COMPONENTS.ACTIVITY.POINTS({
            ...aliases.pointsAliases,
            pointsCount: activity.totalPoints[0],
          })
        )}
      </span>
    </div>
  );
};

const ActivityStatus = ({
  activity,
}: TimelineActivityProps) => {
  let statusText: string;
  let textClass: string;
  if (activity.progress === 'not_started'
    || activity.progress === 'approval_needed'
    // Live session, no text is displayed if it is the following
    || activity.progress === 'not_available'
    || activity.progress === 'attended_manual'
    || activity.progress === 'attended_auto'
    || activity.progress === 'watched_recording') {
    return null;
  }
  if (activity.progress === 'missed'
    || activity.progress === 'rejected_and_missed') {
    textClass = 'text-gray-2';
    statusText = t.TIMELINE.EXERCISE_STATUS.MISSED();
  } else if (activity.progress === 'in_progress'
    || activity.progress === 'started') {
    textClass = 'text-primary';
    statusText = t.TIMELINE.EXERCISE_STATUS.IN_PROGRESS();
  } else if (activity.progress === 'pending'
    || activity.progress === 'rejected') {
    textClass = 'text-primary';
    statusText = t.TIMELINE.EXERCISE_STATUS.PENDING();
  } else if (activity.progress === 'approved') {
    textClass = 'text-success';
    statusText = t.TIMELINE.EXERCISE_STATUS.SUBMITTED();
  } else if (activity.progress === 'completed') {
    textClass = 'text-success';

    switch (activity.activityType) {
      case ActivityType.EXERCISE:
      case ActivityType.PEER_EVALUATION:
      case ActivityType.POST:
        statusText = t.TIMELINE.EXERCISE_STATUS.SUBMITTED();
        break;
      case ActivityType.TEAM_FORMATION:
        statusText = t.TIMELINE.EXERCISE_STATUS.SUBMITTED();
        break;
      case ActivityType.LECTURE_VIDEO:
        // In case of Lecture video, the type is used to determine whether it
        // is video or audio
        statusText = activity.type as any === VideoType.VIDEO
          ? t.TIMELINE.EXERCISE_STATUS.WATCHED()
          : t.TIMELINE.EXERCISE_STATUS.LISTENED();
        break;
      default:
        break;
    }

    if (!statusText) {
      switch (activity.type) {
        case ActivityType.PEER_EVALUATION:
        case ActivityType.POLL:
        case ActivityType.QUIZ:
        case ActivityType.PROGRESSIVE_QUIZ:
        case ActivityType.SURVEY:
        case ActivityType.TIMED_EXAM:
        case ActivityType.POST:
        case ActivityType.EXERCISE_SKILLS_RATING:
        case ActivityType.PROFILE_REQUIREMENT:
          statusText = t.TIMELINE.EXERCISE_STATUS.SUBMITTED();
          break;
        case ActivityType.TEAM_FORMATION:
        case ActivityType.VIDEO_PRACTICE:
        case ActivityType.LIVE_SESSION:
        case ActivityType.VIDEO_PRACTICE_FEEDBACK:
          statusText = t.TIMELINE.EXERCISE_STATUS.COMPLETED();
          break;
        default:
          statusText = t.TIMELINE.EXERCISE_STATUS.COMPLETED();
      }
    }
  } else {
    statusText = activity.progress;
  }

  return (
    <div className={`activity-status-text course-title-xxs ${textClass}`}>
      { statusText }
    </div>
  );
};

const ActivityDueDate = ({
  activity,
}: TimelineActivityProps) => {
  if (!activity.dueDate) {
    return null;
  }
  const deadlineMoment = moment(activity.dueDate);
  const currentMoment = moment();

  const textColor = !(
    activity.progress === 'completed' // Activity is not completed
      || activity.progress === 'approved' // nor approved
      || activity.progress === 'missed' // nor missed
  )
    && deadlineMoment > currentMoment
    && deadlineMoment < moment().add(7, 'days')
    ? 'text-danger'
    : 'text-gray-2';

  return (
    <div
      className={`activity-deadline course-title-xxs ${textColor}`}
    >
      {t.TIMELINE.DUE_DEADLINE(
        moment(activity.dueDate).format('L'),
      )}
    </div>
  );
};

const ActivityExtra = ({
  activity,
}: TimelineActivityProps) => {
  if (activity.activityType === ActivityType.EXERCISE) {
    const activityStatuses: {
      [key in ActivityProgress]?: [string, string]
    } = {
      pending: ['bg-primary', t.SUBMISSION_APPROVAL.SUBMISSION_STATUS.PENDING()],
      rejected: ['bg-warning', t.SUBMISSION_APPROVAL.SUBMISSION_STATUS.REJECTED()],
      rejected_and_missed: ['bg-gray-2', t.SUBMISSION_APPROVAL.SUBMISSION_STATUS.REJECTED_PAST()],
      approved: ['bg-success', t.SUBMISSION_APPROVAL.SUBMISSION_STATUS.APPROVED()],
    };

    if (Object.keys(activityStatuses).includes(activity.progress)) {
      return (
        <div className={`d-inline badge badge-small ${activityStatuses[activity.progress][0]}`}>
          {activityStatuses[activity.progress][1]}
        </div>
      );
    }
  }

  return null;
};

const TimelineActivityRow = ({
  activity,
}: TimelineActivityProps) => {
  const styles = css`
    border-bottom: 1px dashed ${gray5};
    gap: ${doubleSpacing}px;
    min-height: 60px;

    &:hover {
      .activity-title {
        color: ${primary};
      }
    }

    &.unreleased {
      opacity: 0.5;
    }

    .activity-description {
      gap: ${standardSpacing}px;

      .activity-icon {
        width: 36px;
        height: 36px;
        flex-shrink: 0;
      }

      .activity-title {
        .activity-desc-points {
          .icon {
            display: inline-block;
            position: relative;
            top: 1px;
          }
        }
      }
    }

    .activity-status {
      min-width: fit-content;
    }

    .badge-small {
      display: inline-block;
      padding: 1px;
      padding-left: ${halfSpacing}px;
      padding-right: ${halfSpacing}px;
    }
  `;

  const dispatch = useAppDispatch();
  const catalogId = useSelector((state: RootState) => state.app.currentCatalogId);

  const { $state } = useContext(AngularServicesContext);

  const goToLectureActivity = () => {
    dispatch(resetShowPortableCompletionPanel());
    $state.go('lecture-page', {
      catalogId,
      id: activity.lecturePageId,
      lectureActivityId: activity.lectureComponentId,
    });
  };

  return (
    <ClickableContainer
      css={styles}
      className={`${!activity.released ? 'unreleased' : ''} activity-item d-flex align-items-center justify-content-between px-4 py-2`}
      onClick={goToLectureActivity}
      data-qa={config.pendo.outline.timeline.activityRow}
    >
      <div className='activity-description d-flex align-items-center'>
        <ActivityIcon activity={activity} />
        <div>
          <div className='activity-title d-inline text-regular'>
            {activity.title}
            <ActivityPoints activity={activity} />
            <ActivityExtra activity={activity} />
          </div>
        </div>
      </div>
      <div className='activity-status d-flex flex-column align-items-end'>
        <ActivityStatus activity={activity} />
        <ActivityDueDate activity={activity} />
      </div>
    </ClickableContainer>
  );
};

export default TimelineActivityRow;
