/* @ngInject */
export default function NotificationsManager(
  _,
  moment,
  NotificationModel,
  NotificationsResources,
  CurrentUserManager,
  PusherManager,
  PusherChannels,
  OfflineManager,
  humps,
  $q,
  $timeout,
  nvCurrentPage,
  $state,
) {
  const PUSHER_EVENT_NAME = 'notifications';
  let pusherChannel;
  let pusherCallback;

  const manager = {
    initialize,
    setCourse,
    requestNotifications,
    markRead,
    markAllRead,

    // exposed data attributes
    numUnreadNotifications: 0,
    currentCourse: null,
    currentCourseNotificationsShown: true,
    courses: [],
    coursesHash: {},
    isLoading: false,
    shouldReloadList: true,
    practiceRoomNotificationsShown: false,

    redirectToPracticeroom,
  };

  const NOTIFICATION_TYPES = {
    MENTIONED_IN_PRACTICE_SUBMISSION_NOTIFICATION: 'MentionedInPracticeSubmissionNotification',
    FEATURED_PRACTICE_SUBMISSION_NOTIFICATION: 'FeaturedPracticeSubmissionNotification',
    NEW_COMMENT_FOR_PRACTICE_SUBMISSION_NOIFICATION: 'NewCommentForPracticeSubmissionNotification',
    NEW_PRACTICE_SKILLS_RATING_NOTIFICATION: 'NewSkillsRatingForPracticeSubmissionNotification',
  };

  $timeout(() => {
    manager.initialize();
  });

  function initialize() {
    if (CurrentUserManager.hasLoggedIn()) {
      requestNotifications();
      setupPusherEvents();
    }

    OfflineManager.registerReturnOnlineCallback(() => {
      if (CurrentUserManager.hasLoggedIn()) {
        return requestNotifications(true);
      }
      return $q.when();
    });
  }

  function requestNotifications(hideLoading) {
    if (!hideLoading) {
      manager.isLoading = true;
    }
    manager.shouldReloadList = false;

    return NotificationModel.getAll()
      .then((response) => {
        _.extend(manager, response);
        _.each(response.courses, (course) => {
          manager.coursesHash[course.id] = course;
        });

        if (manager.currentCourse) {
          manager.currentCourse = manager.coursesHash[manager.currentCourse.id];
        }

        manager.isLoading = false;
      });
  }

  function setCourse(course) {
    if (manager.shouldReloadList) {
      requestNotifications();
    }

    if (course) {
      manager.currentCourse = manager.coursesHash[course.id];
      manager.currentCourseNotificationsShown = true;
    } else {
      manager.currentCourse = null;
    }
  }

  function markRead(notification) {
    if (!notification.read) {
      manager.numUnreadNotifications -= 1;
      if (notification.catalogId) {
        manager.currentCourse.numUnreadNotifications -= 1;
      } else if (notification.object.scenario) {
        manager.practiceRoomNumUnreadNotifications -= 1;
      }
      notification.markRead();
    }
  }

  function markAllRead(event, notificationCategory) {
    event.stopPropagation();
    let maxUpdatedAt;
    let currentMoment;
    let previousMoment;

    // need this attribute because there can be unread notifications that aren't shown
    // if they aren't shown. we can't show the mark all as read link
    // this attribute will get cleared when we repull all the data
    if (notificationCategory === 'course') {
      manager.currentCourse.hasMarkedAllasRead = true;

      _.each(manager.currentCourse.notifications, (notification) => {
        notification.markRead(true);

        currentMoment = moment(notification.sortByTimestamp);
        if (!previousMoment || currentMoment > previousMoment) {
          previousMoment = currentMoment;
          maxUpdatedAt = notification.sortByTimestamp;
        }
      });

      const course = manager.currentCourse;

      return NotificationModel.markAllRead(manager.currentCourse.catalogId, maxUpdatedAt)
        .then((response) => {
          manager.numUnreadNotifications = response.numUnreadNotifications;
          course.numUnreadNotifications = response.courseNumUnreadNotifications;
        });
    }

    _.each(manager.practiceRoomNotifications, (notification) => {
      notification.markRead(true);

      currentMoment = moment(notification.sortByTimestamp);
      if (!previousMoment || currentMoment > previousMoment) {
        previousMoment = currentMoment;
        maxUpdatedAt = notification.sortByTimestamp;
      }
    });

    return NotificationModel.markAllPracticeRoomRead(maxUpdatedAt)
      .then((response) => {
        manager.practiceRoomNumUnreadNotifications = response.practiceRoomNumUnreadNotifications;
        manager.numUnreadNotifications = response.numUnreadNotifications;
      });
  }

  /** Private Functions * */
  function setupPusherEvents() {
    pusherChannel = PusherManager.setupChannel(PusherChannels.userChannel(CurrentUserManager.user.anonymizedIdentifier.substr(0, 10)));
    pusherChannel.bind(PUSHER_EVENT_NAME, (pusherData) => {
      const promises = [];
      let course;

      pusherData = humps.camelizeKeys(pusherData);

      if (manager.coursesHash[pusherData.courseId]) {
        promises.push(NotificationModel.getNumUnreadNotifications());
        course = manager.coursesHash[pusherData.courseId];

        promises.push(NotificationModel.getCourseNumUnreadNotifications(course.catalogId));
        $q.all(promises).then((values) => {
          const [numUnreadNotifications, courseNumUnreadNotifications] = values;

          $timeout(() => {
            manager.numUnreadNotifications = numUnreadNotifications;
            if (course) {
              course.numUnreadNotifications = courseNumUnreadNotifications;
            }

            manager.shouldReloadList = true;
            if (manager.currentCourse === course) {
              course.shouldReloadList = true;
            }
          });
        });
      } else {
        requestNotifications();
      }
    });
  }

  function unsubscribePusherEvents() {
    pusherChannel.unbind(PUSHER_EVENT_NAME, pusherCallback);
  }

  function redirectToPracticeroom(notification) {
    const stateParams = {
      scenarioId: notification.object.scenario.id,
      submission: notification.object.submissionId,
    };
    if (notification.type === NOTIFICATION_TYPES.MENTIONED_IN_PRACTICE_SUBMISSION_NOTIFICATION) {
      stateParams.commentId = notification.object.id;
      stateParams.user = notification.object.user.id;
      stateParams.selected = notification.selectedPracticeRoomTab();
    }
    if (notification.type === NOTIFICATION_TYPES.NEW_COMMENT_FOR_PRACTICE_SUBMISSION_NOIFICATION) {
      stateParams.commentId = notification.object.firstCommentId;
      stateParams.selected = 'my_practice';
    }
    if (notification.type === NOTIFICATION_TYPES.FEATURED_PRACTICE_SUBMISSION_NOTIFICATION) {
      stateParams.user = notification.object.user.id;
      stateParams.selected = notification.baseTranslateObject.isObjectCurrentUser ? 'my_practice' : 'featured';
    }
    if (notification.type === NOTIFICATION_TYPES.NEW_PRACTICE_SKILLS_RATING_NOTIFICATION) {
      stateParams.selected = 'my_practice';
      stateParams.submissionView = 'all_feedback';
      stateParams.feedbackBy = notification.object.user.id;
    }

    $state.go('practice-room-modal', stateParams);
  }

  return manager;
}
