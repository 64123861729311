import React from 'react';
import moment from 'moment';

import t from 'react-translate';

// Forms
import { get, useFormContext, useWatch } from 'react-hook-form';

// Styles
import { css } from '@emotion/react';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { primary } from 'styles/global_defaults/colors';

// Reused components
import NvDatePicker, { DatePickerType } from 'shared/components/inputs/nv-datepicker';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import { Button } from 'react-bootstrap';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';

export enum InputClickToEditTypes {
  INPUT,
  DATEPICKER,
}

type InputClickToEditProps = {
  initialValue: string,
  name: string,
  disabled: boolean,
  forceHover?: boolean,
  type?: InputClickToEditTypes,
  dataQa?: string,
  dataQaPendo?: string,
  inputClassName?: string,
  buttonClassName?: string,
  labelClassName?: string,
  ariaLabel?: string,
  maxLength?: number,
  onChange?: (value: string) => void,
  onHover?: () => void,
  onBlur?: () => void,
  onInputBlur?: (value: string) => void,
  placeholder?: string,
  keepingErrorAfterClick?: boolean,
  showEditingIcon?: boolean,
  tooltipText?: string,
};

const InputClickToEdit = (props: InputClickToEditProps) => {
  const {
    initialValue: value,
    name,
    disabled = false,
    forceHover = false,
    type = InputClickToEditTypes.INPUT,
    dataQa,
    dataQaPendo,
    inputClassName = '',
    buttonClassName = '',
    labelClassName = '',
    ariaLabel,
    maxLength,
    onChange,
    onHover,
    onBlur,
    onInputBlur,
    placeholder,
    keepingErrorAfterClick,
    showEditingIcon = true,
    tooltipText,
  } = props;

  const [showInput, setShowInput] = React.useState(false);
  const [showEditIcon, setShowEditIcon] = React.useState(false);
  const [firstTimeClicked, setFirstTimeClicked] = React.useState(false);
  const inputRef = React.useRef<any>();
  const isMountedRef = React.useRef(false);
  const datePickerRef = React.useRef<any>();

  const { formState, setValue, setError, clearErrors } = useFormContext() || {};
  const watched = useWatch({ name });
  const error = get(formState.errors, name);
  const dateLabel = watched || value;

  const shouldShowInput = () => {
    const hasError = error && (type === InputClickToEditTypes.INPUT
      || (type === InputClickToEditTypes.DATEPICKER && keepingErrorAfterClick && firstTimeClicked));
    return showInput || hasError;
  };

  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    onChange?.(inputValue);
  };

  const handleBlur = () => {
    if (!error) setShowInput(false);
    onInputBlur?.(watched);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleDateChange = (date: moment.Moment) => {
    const formattedDate = date?.format('L LT');
    onChange?.(formattedDate);
  };

  const handleClick = () => {
    if (!disabled) setShowInput(true);
  };

  const handleMouseLeave = () => {
    setShowEditIcon(false);
    onBlur?.();
  };

  const handleMouseEnter = () => {
    setShowEditIcon(true);
    onHover?.();
  };

  const getButtonClasses = () => {
    const disabledClass = disabled ? 'disabled' : '';
    const hoverClass = forceHover ? 'hover' : '';
    const hideClass = shouldShowInput() ? 'hide' : 'show d-flex';
    const buttonClasses = `${disabledClass} ${hoverClass} ${hideClass} ${buttonClassName}`;
    return buttonClasses;
  };

  const shouldShowEditIcon = () => showEditingIcon && ((showEditIcon && !disabled) || forceHover);

  const styles = css`
    .show {
      visibility: visible;
    }
    .hide {
      visibility: hidden;
    }
    .label {
      text-align: left;
      border: 0;
      box-shadow: none;
      overflow-wrap: anywhere;
      padding-right: 0;
      &:not(.disabled) {
        min-height: ${standardSpacing}px;
        padding-right: 0;
        &:hover {
          color: ${primary};
          background: white;
          padding-right: 0;
        }
      }
      &.hide {
        display: none;
      }
    }
    .edit-icon {
      color: ${primary};
      &.show-icon {
        visibility: visible;
      }
      &.hide-icon {
        visibility: hidden;
      }
    }
    .truncate {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  `;

  React.useEffect(() => {
    if (showInput) {
      setFirstTimeClicked(true);
      if (inputRef?.current) {
        inputRef.current?.focus();
      } else if (datePickerRef?.current) {
        datePickerRef.current?.setOpen(true);
      }
    }
  }, [showInput]);

  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (disabled && error) clearErrors(name);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  let label = typeof watched === 'object' ? watched?.format('L LT') : watched;
  if (type === InputClickToEditTypes.DATEPICKER && isMountedRef.current) {
    if (dateLabel) {
      const temp = moment(dateLabel);
      label = temp.format('L LT');
      if (error) clearErrors(name);
      if (!watched) setValue(name, temp.toISOString());
    } else if (!error && !disabled) {
      setError(name, { message: t.VALIDATION.REQUIRED(), type: 'required' });
    }
  }

  return (
    <div css={styles} className={`w-100 d-flex ${inputClassName}`}>
      {type === InputClickToEditTypes.INPUT && (
        <NvTextInput
          name={name}
          ref={inputRef}
          className={`w-100 ${shouldShowInput() ? 'show' : 'hide'}`}
          error={error}
          defaultValue={value}
          maxLength={maxLength}
          ariaLabel={ariaLabel}
          data-qa={`${dataQa}-input`}
          data-qa-pendo={`${dataQaPendo}-input`}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          errorOnTouching={false}
          withForm
          required
          forceShowErrorState
        />
      )}
      {type === InputClickToEditTypes.DATEPICKER && (
        <NvDatePicker
          className={`w-100 ${shouldShowInput() ? 'show' : 'hide'}`}
          withForm
          name={name}
          onChange={handleDateChange}
          onCalendarClose={() => setShowInput(false)}
          type={DatePickerType.DATETIME}
          disabled={disabled}
          value={dateLabel ? moment(dateLabel) : null}
          dataQa={`${dataQa}-input`}
          dataQaPendo={`${dataQaPendo}-input`}
          ref={datePickerRef}
          required
        />
      )}
      <NvTooltip
        text={tooltipText ?? t.FORM.CLICK_TO_EDIT()}
        enabled={!disabled}
      >
        <Button
          className={`label w-100 align-items-center ${getButtonClasses()}`}
          variant='light'
          data-qa={`${dataQa}-label`}
          data-qa-pendo={`${dataQaPendo}-label`}
          disabled={disabled}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
        >
          {
            label
              ? <span className={`truncate ${labelClassName}`}>{label}</span>
              : <span className='truncate gray-2'>{placeholder}</span>
          }
          <NvIcon
            size='sm'
            icon='edit'
            className={`ml-1 edit-icon ${shouldShowEditIcon() ? 'show-icon' : 'hide-icon'}`}
          />
        </Button>
      </NvTooltip>

    </div>
  );
};

export default InputClickToEdit;
