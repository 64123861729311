import React from 'react';
import t from 'react-translate';
import { camelCase } from 'lodash';
import { downloadCSVFromJson } from 'shared/csv-utils';

// Redux
import { useSelector } from 'react-redux';
import { wrapThunkAction } from 'redux/utils';
import { useAppDispatch } from 'redux/store';
import { Institution } from 'redux/schemas/models/courseFull';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { downloadHCMTemplate, uploadHCMCSV } from 'redux/actions/hcm';

// Styles
import { css } from '@emotion/react';
import { doubleSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { gray3 } from 'styles/global_defaults/colors';

// Components
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { Button } from 'react-bootstrap';
import NvFilePicker from 'shared/components/nv-filepicker';

// Pendo
import { config } from '../../../../../config/pendo.config.json';

const DownloadTemplate = () => {
  const institution: Institution = useSelector(state => getCurrentInstitution(state));
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const btnText = loading
    ? t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.DATA_MAPPING_MODAL.DOWNLOADING()
    : t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.DATA_MAPPING_MODAL.DOWNLOAD();

  const downloadCSV = (payload) => {
    const [headers, ...data] = payload;
    const templateHeaders = headers.map((name) => ({
      key: camelCase(name),
      headerName: name,
    }));
    const values = {};
    const totalRows = data.length;

    data.forEach((cols) => {
      cols.forEach((value, index) => {
        const header = templateHeaders[index];
        if (!values[header.key]) values[header.key] = [];
        values[header.key].push(value);
      });
    });

    downloadCSVFromJson({
      headers: templateHeaders,
      dummyRows: { totalRows, values },
    });
  };

  const downloadTemplate = () => {
    setLoading(true);
    wrapThunkAction(dispatch(downloadHCMTemplate({ institutionId: institution.id })))
      .then((action: { payload: any }) => {
        const { payload = [] } = action;
        if (payload.length) downloadCSV(payload);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className='text-body double-margin-bottom'>{t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.DATA_MAPPING_MODAL.DOWNLOAD_TEMPLATE()}</div>
      <div className='button-bar'>
        <Button
          variant='secondary'
          onClick={downloadTemplate}
          data-qa={config.pendo.hcm.dataMappingDownloadTemplate}
          disabled={loading}
        >
          {btnText}
        </Button>
      </div>
    </div>
  );
};

type UploadProps = {
  closeModal: () => void,
};

const UploadCSV = ({ closeModal }: UploadProps) => {
  const institution: Institution = useSelector(state => getCurrentInstitution(state));
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const btnText = loading
    ? t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.DATA_MAPPING_MODAL.UPLOADING()
    : t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.DATA_MAPPING_MODAL.UPLOAD();

  const onCSVSelected = (data) => {
    setLoading(true);
    wrapThunkAction(dispatch(uploadHCMCSV({ institutionId: institution.id, data })))
      .then(() => {
        closeModal();
      }).finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className='text-body double-margin-bottom'>{t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.DATA_MAPPING_MODAL.UPLOAD_CSV()}</div>
      <div className='button-bar mb-4 d-flex justify-content-center upload-area'>
        <NvFilePicker
          multiple={false}
          accept={['.csv', 'text/csv']}
          onChange={(e) => onCSVSelected(e)}
        >
          <Button
            disabled={loading}
            variant='primary'
            data-qa={config.pendo.hcm.dataMappingUploadCsv}
          >
            {btnText}
          </Button>
        </NvFilePicker>
      </div>
    </div>
  );
};

type Props = {
  show?: boolean
  onClose?: () => void
};

const HCMDataMapping = (props: Props) => {
  const [show, setShow] = React.useState(props.show);

  const handleModalClose = () => {
    setShow(false);
    props.onClose();
  };

  const modalBodyStyles = css`
    padding: ${standardSpacing}px;

    .double-margin-bottom {
      margin-bottom: ${doubleSpacing}px;
    }

    .divider {
      height: 1px;
      background-color: ${gray3};
      width: 100%;
      margin: ${doubleSpacing}px 0;
    }

    .upload-area > * {
      display: contents;
    }
  `;

  React.useEffect(() => {
    if (props.show) setShow(true);
  }, [props.show]);

  return (
    <NvModal
      show={show}
      height='auto'
      width={620}
      type={ModalType.FIXED}
      onClose={handleModalClose}
      header={t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.DATA_MAPPING_MODAL.TITLE()}
      body={(
        <div css={modalBodyStyles}>
          <DownloadTemplate />
          <div className='divider' />
          <UploadCSV closeModal={handleModalClose} />
        </div>
      )}
      data-qa={config.pendo.hcm.dataMappingModal}
    />
  );
};

export default HCMDataMapping;
