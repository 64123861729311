import React from 'react';
import { jsx } from '@emotion/react';

import t from 'react-translate';
import { NvModal, ModalType } from 'shared/components/nv-modal';
import ConfigureEntitlementsModalBody, {
  Props as ConfigureEntitlementsModalBodyProps,
} from 'offerings/components/configure-entitlements-modal-body';

export type Entitlement = {
  id?: number,
  profileSettingId: number,
  values: string[],
};

export type Props = ConfigureEntitlementsModalBodyProps & {
  show: boolean,
};

const ConfigureEntitlementsModal = (props: Props) => {
  const {
    show,
    ...restProps
  } = props;

  return (
    <NvModal
      show={show}
      width={800}
      onClose={props.onCancel}
      type={ModalType.DYNAMIC}
      header={t.COURSES.FORM.ENTITLEMENTS.CONFIGURE()}
      body={(
        <ConfigureEntitlementsModalBody
          {...restProps}
        />
      )}
    />
  );
};

export default ConfigureEntitlementsModal;
