import { css } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { object } from 'underscore';
import t from 'react-translate';
import { useSelector } from 'react-redux';

// Styles
import {
  doubleSpacing,
} from 'styles/global_defaults/scaffolding';
import {
  kelp, warning, danger, success,
} from 'styles/global_defaults/colors';

// Schemas
import { FilterType } from 'redux/schemas/models/org-mentors';

// Selectors
import { getRoleAliases } from 'redux/selectors/institutions';

// Components
import Tab from 'dashboard/components/tab';

import prodPathReplace from '../../shared/prod-path-rewrite';

type DashboardFilterBoxProps = {
  filterType: string
  count: number
  isSelected: boolean
  scrolledUp: boolean
};

type FilterBoxItem = {
  title: string,
  description: string,
  tooltipText: string,
  icon: string,
  color: string,
};

const DashboardFilterBox = ({ filterType, count, isSelected, scrolledUp }: DashboardFilterBoxProps) => {
  const roleAliases = useSelector(getRoleAliases);
  const menteesAlias = count > 1 ? roleAliases?.mentee.capitalizedPluralized : roleAliases?.mentee.capitalizedSingularized;

  const filterBoxItems = useCallback(() => object([
    // Key                  icon                 color
    /* eslint-disable no-multi-spaces */
    ['TOTAL_MENTEES',       'total-mentees',     kelp], // TODO-OLM: Update the new colors
    ['NEVER_LOGGED_IN',     'never-logged',      '#1A2172'],
    ['MISSED_DEADLINE',     'missed-deadline',    warning],
    ['NOT_ACTIVE',          'not-active',         danger],
    ['RECENTLY_COMPLETED',  'recently-completed', success],
    /* eslint-enable no-multi-spaces */
  ].map<[string, FilterBoxItem]>((f) => ([
    FilterType[f[0]], {
      title: t.INSTITUTIONS.ORG_MENTORS.FILTER.TITLE[f[0]]({ MenteesAlias: menteesAlias }),
      description: (FilterType[f[0]] !== FilterType.TOTAL_MENTEES)
        ? t.INSTITUTIONS.ORG_MENTORS.FILTER.DESCRIPTION[f[0]]() : '',
      tooltipText: t.INSTITUTIONS.ORG_MENTORS.FILTER.TOOLTIP_TEXT[f[0]](),
      icon: f[1],
      color: f[2],
    },
  ]))), [menteesAlias]);

  const filter: FilterBoxItem = filterType && filterBoxItems()[filterType];
  const [isCompactMode, setCompactMode] = useState<boolean>(scrolledUp);

  const imageStyles = css`
    width: ${doubleSpacing}px;
    height: ${doubleSpacing}px;
  `;

  useEffect(() => {
    setCompactMode(scrolledUp);
  }, [scrolledUp]);

  const tabDescriptionItems = [filter?.description].filter(Boolean).map((description) => ({
    text: description,
  }));

  return (
    <Tab
      count={count}
      color={filter?.color}
      title={filter?.title}
      isCompact={isCompactMode}
      isSelected={isSelected}
      tooltipText={filter?.tooltipText}
      descriptionItems={tabDescriptionItems}
      renderImage={() => (
        <img
          css={imageStyles}
          src={prodPathReplace(`images/${filter?.icon}.png`)}
          alt={filter.title}
          aria-hidden
        />
      )}
    />
  );
};
export default DashboardFilterBox;
