/* eslint-disable react/require-default-props */
import { css } from '@emotion/react';
import { pick } from 'underscore';
import { Style5AccordionSectionLectureComponent } from 'redux/schemas/models/lecture-component';
import { FileUpload } from 'redux/schemas/models/file-uploads';
import ClickableContainer from 'components/clickable-container';
import t from 'react-translate';
import AccordionRtHeader from './accordion-rt-header';
import { SectionContainerProps } from './accordion-types';
import useAccordionSectionContainer from './use-accordion-section-container';
import ApplyToAllSectionsButton from './apply-to-all-sections-button';
import { LecturePageMode } from '..';
import ArrowToggleIcon from './arrow-toggle-icon';
import Style5SectionLeftHandSymbol from './Style-5-section-left-hand-symbol';
import { getSanitizedStyles } from 'shared/utils';

type Style5SectionContainerProps = SectionContainerProps<Style5AccordionSectionLectureComponent>;

/* @ngInject */
export default function Style5SectionContainer(props: Style5SectionContainerProps) {
  const { headerEditorRef, onHeaderRTEChange } = useAccordionSectionContainer(props);

  // #region handlers
  function onHeaderBackgroundColorChange(newColor: string) {
    props.onViewOptionsChange({
      headerBackgroundColor: newColor,
    });
  }

  function onIconColorChange(newColor: string) {
    props.onViewOptionsChange({
      iconColor: newColor,
    });
  }

  function onTextBackgroundColorChange(newColor: string) {
    props.onViewOptionsChange({
      textBackgroundColor: newColor,
    });
  }

  function onLeftHandPictureChange(picture: FileUpload) {
    props.onAccordionSectionChange({
      ...props.sectionData,
      picture,
      viewOptions: {
        ...props.sectionData.viewOptions,
        iconLibraryName: null,
      },
    } as any);
    // TODO: For some reason the type checker is getting confused and thinking these viewOptions are not
    // specifically for the Accordion Style 5
  }

  function onLeftHandIconChange(iconLibraryName: string) {
    props.onAccordionSectionChange({
      ...props.sectionData,
      removePicture: true,
      viewOptions: {
        ...props.sectionData.viewOptions,
        iconLibraryName,
      },
    } as any);
  }
  // #endregion

  const edit = props.mode === LecturePageMode.EDIT;

  return (
    <div
      css={css`
        border-bottom: 1px solid #fff;
      `}
    >
      <ClickableContainer
        className='d-flex align-items-stretch'
        onClick={props.toggleExpanded}
        layoutOnly={edit /* disable toggle functionality for the whole header on edit mode */}
        style={{
          backgroundColor: props.sectionData.viewOptions.headerBackgroundColor,
        }}
      >
        <Style5SectionLeftHandSymbol
          sectionViewOptions={props.sectionData.viewOptions}
          onViewOptionsChange={props.onViewOptionsChange}
          mode={props.mode}
          currentLecture={props.currentLecture}
          applyViewOptionsToAllSections={props.applyViewOptionsToAllSections}
          isOnlySection={props.isOnlySection}
          onLeftHandPictureChange={onLeftHandPictureChange}
          picture={props.sectionData.picture}
          onLeftHandIconChange={onLeftHandIconChange}
          thumbnailUrl={props.sectionData.thumbnailUrl}
        />

        <AccordionRtHeader<{
          headerBackgroundColor: string,
          iconColor?: string,
          textBackgroundColor: string,
        }>
          defaultTextColor='#ffffff'
          onColorChange={(section, newColor) => {
            props.onViewOptionsChange({
              [section]: newColor,
            });
          }}
          currentLecture={props.currentLecture}
          sections={[
            {
              name: 'headerBackgroundColor',
              title: t.LECTURE_PAGES.COMPONENTS.ACCORDION.HEADER_BACKGROUND_COLOR_PICKER_LABEL(),
            },
            {
              name: 'iconColor',
              title: t.LECTURE_PAGES.COMPONENTS.ACCORDION.RIGHT_HAND_ICON_COLOR_PICKER_LABEL(),
            },
            {
              name: 'textBackgroundColor',
              title: t.LECTURE_PAGES.COMPONENTS.ACCORDION.CONTENT_BACKGROUND_COLOR_PICKER_LABEL(),
            },
          ]}
          isOnlySection={props.isOnlySection}
          colorValues={{
            headerBackgroundColor: props.sectionData.viewOptions.headerBackgroundColor,
            iconColor: props.sectionData.viewOptions.iconColor,
            textBackgroundColor: props.sectionData.viewOptions.textBackgroundColor,
          }}
          renderBeforePalette={!props.isOnlySection ? () => (
            <div className='d-flex flex-column align-items-center'>
              <ApplyToAllSectionsButton
                onClick={() => {
                  props.applyViewOptionsToAllSections(
                    pick(props.sectionData.viewOptions, 'headerBackgroundColor', 'iconColor', 'textBackgroundColor'),
                    {
                      includeHeaderTextFormat: true,
                    },
                  );
                }}
              />
            </div>
          ) : undefined}
          autoFocus={props.autoFocusHeader}
          backgroundColor={props.sectionData.viewOptions.headerBackgroundColor}
          mode={props.mode}
          sectionData={props.sectionData}
          editorRef={headerEditorRef}
          onChange={onHeaderRTEChange}
        />
        <ArrowToggleIcon
          className='align-self-center'
          onClick={props.toggleExpanded}
          layoutOnly={!edit /* this button only works on edit mode, otherwise behaves as a normal icon */}
          expanded={props.expanded}
          sectionViewOptions={props.sectionData.viewOptions}
          mode={props.mode}
        />
      </ClickableContainer>
      {props.renderSectionContent({
        containerCss: css`
          background-color: ${props.sectionData.viewOptions.textBackgroundColor};
        `,
      })}
    </div>
  );
}
