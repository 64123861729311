import {
  useContext, useMemo,
} from 'react';
import moment from 'moment';
import * as yup from 'yup';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import { values } from 'underscore';
import { css } from '@emotion/react';

// Contexts
import { CommunicationDispatch } from 'communications/course_communications/contexts/communication-context';

// Schemas
import { ScheduledForType, CommunicationType } from 'redux/schemas/models/course-communication';

// Selectors
import { getCourseAliases, getCoursesArray } from 'redux/selectors/course';
import { getActivity } from 'redux/selectors/course-communications';
import { getPrefixedTitle } from 'redux/selectors/activity';
import { getJourneyCollections } from 'redux/selectors/learning-journeys';

// Components
import CompletedJourney from 'communications/course_communications/components/communication-modal/filters/completed-journey';
import NumberInput from 'communications/course_communications/components/communication-modal/filters/number-input';
import OneSelector from 'communications/course_communications/components/communication-modal/filters/one-selector';
import JourneySendTo from 'communications/journey_communications/components/communication-modal/journey-send-to';
import JourneySendOn from 'communications/journey_communications/components/communication-modal/journey-send-on';
import NeverActive from 'communications/course_communications/components/communication-modal/filters/never-active';
import { NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import CommunicationForm, { formDropdownSchema } from '../../../course_communications/components/communication-modal/communication-form';

const generalStyle = css`
  label {
    margin-bottom: unset;
  }
`;

const JourneyScheduledEmail = () => {
  const { State: communicationState } = useContext(CommunicationDispatch);
  const aliases = useSelector((state) => getCourseAliases(state));
  const activity: any = useSelector((state) => getActivity(state, communicationState.activityType, communicationState.activityId));
  const prefixedTitle = useSelector((state) => getPrefixedTitle(
    state,
    communicationState.activityType,
    communicationState.activityId,
    aliases,
  ));
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const journeyCollections = useSelector((state) => getJourneyCollections(state, catalogId));
  const courses = useSelector(getCoursesArray);


  const coursesByCollections: NvDropdownOption[] = useMemo(() => {
    const collections: NvDropdownOption[] = [];
    values(journeyCollections).forEach((collection) => {
      const collectionName = collection.name.length ? collection.name : t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COLLECTION.COLLECTION_GENERIC_NAME();
      collections.push(
        {
          type: 'header',
          title: collectionName,
          class: 'dropdown-item',
        },
      );
      collection.courseIds.forEach(courseId => {
        const relatedCourse = courses.find((course) => course.id === courseId);
        collections.push(
          {
            type: 'text',
            text: relatedCourse.name,
            id: relatedCourse.id,
            value: relatedCourse.id,
            class: 'dropdown-item',
          },
        );
      });
    });
    return collections;
  }, []);

  const collectionsOfCourses: NvDropdownOption[] = useMemo(() => {
    const collections: NvDropdownOption[] = [];
    values(journeyCollections).forEach((collection) => {
      const collectionName = collection.name.length ? collection.name : t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COLLECTION.COLLECTION_GENERIC_NAME();
      collections.push(
        {
          type: 'text',
          text: collectionName,
          id: collection.id,
          value: collection.id,
          class: 'dropdown-item',
        },
      );
    });
    return collections;
  }, []);

  const validationSchema = yup.object().shape({
    scheduledFor: yup.string().nullable(),
    scheduledOn: yup.date().nullable()
      .when('scheduledFor', {
        is: ScheduledForType.SCHEDULED,
        then: yup.date()
          .transform(function (value, originalValue) {
            if (originalValue?._isAMomentObject) {
              return originalValue.toDate();
            }
            return value;
          })
          .typeError(t.VALIDATION.DATE())
          .required(t.VALIDATION.REQUIRED())
          .test(
            'isFutureTime',
            t.VALIDATION.SELECT_FUTURE_TIME(),
            (scheduledOn) => moment(scheduledOn).isAfter(moment()),
          ),
      }),
    filterByRoles: yup.boolean(),
    hasNeverActiveChecked: yup.boolean(),
    daysInactiveChecked: yup.boolean(),
    enrolledLastDaysChecked: yup.boolean(),
    enrolledToSpecificCourseChecked: yup.boolean(),
    notEnrolledToSpecificCourseChecked: yup.boolean(),
    completedSpecificCourseChecked: yup.boolean(),
    notCompletedSpecificCourseChecked: yup.boolean(),
    completedSpecificCollectionChecked: yup.boolean(),
    notCompletedSpecificCollectionChecked: yup.boolean(),
    hasCompletedJourneyChecked: yup.boolean(),
    recipients: yup.array().required().min(1),
    courseRolesList: yup.array().of(yup.string())
      .when('filterByRoles', {
        is: true,
        then: yup.array().of(yup.string()).required().min(1),
      }),
    daysInactive: yup.number().nullable()
      .when('daysInactiveChecked', {
        is: true,
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      }),
    enrolledInLastXDays: yup.number().nullable()
      .when('enrolledLastDaysChecked', {
        is: true,
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      }),
    enrolledToSpecificCourse: yup.object().nullable()
      .when('enrolledToSpecificCourseChecked', {
        is: true,
        then: formDropdownSchema.required(t.VALIDATION.REQUIRED()),
      }),
    notEnrolledToSpecificCourse: yup.object().nullable()
      .when('notEnrolledToSpecificCourseChecked', {
        is: true,
        then: formDropdownSchema.required(t.VALIDATION.REQUIRED()),
      }),
    completedSpecificCourse: yup.object().nullable()
      .when('completedSpecificCourseChecked', {
        is: true,
        then: formDropdownSchema.required(t.VALIDATION.REQUIRED()),
      }),
    notCompletedSpecificCourse: yup.object().nullable()
      .when('notCompletedSpecificCourseChecked', {
        is: true,
        then: formDropdownSchema.required(t.VALIDATION.REQUIRED()),
      }),
    completedSpecificCollection: yup.object().nullable()
      .when('completedSpecificCollectionChecked', {
        is: true,
        then: formDropdownSchema.required(t.VALIDATION.REQUIRED()),
      }),
    notCompletedSpecificCollection: yup.object().nullable()
      .when('notCompletedSpecificCollectionChecked', {
        is: true,
        then: formDropdownSchema.required(t.VALIDATION.REQUIRED()),
      }),
    hasCompletedJourney: yup.object().nullable()
      .when('hasCompletedJourneyChecked', {
        is: true,
        then: yup.object().required(t.VALIDATION.REQUIRED()),
      }),
  });

  let sendOnDetailsText: string;
  let referenceDate: string;
  if (communicationState.communicationType === CommunicationType.DUE_DATE_EMAIL) {
    referenceDate = activity.dueDate;
    sendOnDetailsText = t.JOURNEY_COMMUNICATIONS.SEND_ON.DUE_DATE.LABEL(moment(activity.dueDate).format('LLL'), prefixedTitle);
  }
  if (communicationState.communicationType === CommunicationType.RELEASE_DATE_EMAIL) {
    referenceDate = activity.releaseDate;
    sendOnDetailsText = t.JOURNEY_COMMUNICATIONS.SEND_ON.RELEASE_DATE.LABEL(moment(activity.releaseDate).format('LLL'), activity.title);
  }

  return (
    <div className='journey-scheduled-email' css={generalStyle}>
      <CommunicationForm validationSchema={validationSchema}>
        <div className='modal-body-title page-title text-center pb-4 mt-2'>
          {t.JOURNEY_COMMUNICATIONS.SCHEDULED.TITLE.STEP_1()}
        </div>
        <JourneySendTo />
        <JourneySendOn
          details={sendOnDetailsText}
          referenceDate={referenceDate}
        />

        <div className='bold text-large-regular mt-5'>
          <div className='text-warning inline-block'>{t.JOURNEY_COMMUNICATIONS.FILTERS.OPTIONAL()}</div>
          <div className='ml-2 inline-block'>{t.JOURNEY_COMMUNICATIONS.FILTERS.ADD_MORE(aliases.learnersAliases)}</div>
        </div>
        <div className='text-medium bold my-1'>{t.JOURNEY_COMMUNICATIONS.FILTERS.SCHEDULED_DESCRIPTION()}</div>
        <div className='text-medium gray-3 mt-4 mb-1'>{t.JOURNEY_COMMUNICATIONS.FILTERS.MULTIPLE_SELECTIONS()}</div>

        {/* Has logged-in/not-logged-in */}
        <NeverActive
          checkboxName='hasNeverActiveChecked'
          disableOn={[
            'completedSpecificCourseChecked',
            'completedSpecificCollectionChecked',
            'daysInactiveChecked',
            'hasCompletedJourneyChecked',
          ]}
        />

        {/* Has been inactive for more than X days */}
        <NumberInput
          checkboxName='daysInactiveChecked'
          name='daysInactive'
          defaultLabel={t.JOURNEY_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.INACTIVE.DEFAULT()}
          inputLabel={t.JOURNEY_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.INACTIVE.LABEL('INPUT')}
          note={t.JOURNEY_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.INACTIVE.NOTE()}
          ariaLabel={t.JOURNEY_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.INACTIVE.ARIA_LABEL()}
          disableOn={['hasNeverActiveChecked']}
        />

        {/* Enrolled to the journey in last X days */}
        <NumberInput
          checkboxName='enrolledLastDaysChecked'
          name='enrolledInLastXDays'
          defaultLabel={t.JOURNEY_COMMUNICATIONS.FILTERS.ENROLLED_LAST_DAYS.DEFAULT()}
          inputLabel={t.JOURNEY_COMMUNICATIONS.FILTERS.ENROLLED_LAST_DAYS.LABEL('INPUT')}
        />

        {/* Enrollment */}

        {/* Has enrolled to a specific course */}
        <OneSelector
          checkboxName='enrolledToSpecificCourseChecked'
          name='enrolledToSpecificCourse'
          dependentName='notEnrolledToSpecificCourse'
          label={t.JOURNEY_COMMUNICATIONS.FILTERS.COURSE_ENROLLMENT.ENROLLED.DEFAULT()}
          items={coursesByCollections}
        />
        {/* Has NOT enrolled to a specific course */}
        <OneSelector
          checkboxName='notEnrolledToSpecificCourseChecked'
          name='notEnrolledToSpecificCourse'
          dependentName='enrolledToSpecificCourse'
          label={t.JOURNEY_COMMUNICATIONS.FILTERS.COURSE_ENROLLMENT.NOT_ENROLLED.DEFAULT()}
          items={coursesByCollections}
        />

        {/* Completion */}

        {/* Has completed a specific course */}
        <OneSelector
          checkboxName='completedSpecificCourseChecked'
          name='completedSpecificCourse'
          dependentName='notCompletedSpecificCourse'
          label={t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COURSE.COMPLETED.DEFAULT()}
          items={coursesByCollections}
          disableOn='hasNeverActiveChecked'
        />
        {/* Has NOT completed a specific course */}
        <OneSelector
          checkboxName='notCompletedSpecificCourseChecked'
          name='notCompletedSpecificCourse'
          dependentName='completedSpecificCourse'
          label={t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COURSE.NOT_COMPLETED.DEFAULT()}
          items={coursesByCollections}
        />
        {/* Has completed a specific collection */}
        <OneSelector
          checkboxName='completedSpecificCollectionChecked'
          name='completedSpecificCollection'
          dependentName='notCompletedSpecificCollection'
          label={t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COLLECTION.COMPLETED.DEFAULT()}
          items={collectionsOfCourses}
          disableOn='hasNeverActiveChecked'
        />
        {/* Has NOT completed a specific collection */}
        <OneSelector
          checkboxName='notCompletedSpecificCollectionChecked'
          name='notCompletedSpecificCollection'
          dependentName='completedSpecificCollection'
          label={t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COLLECTION.NOT_COMPLETED.DEFAULT()}
          items={collectionsOfCourses}
        />

        <CompletedJourney
          checkboxName='hasCompletedJourneyChecked'
          name='hasCompletedJourney'
          disableOn={['hasNeverActiveChecked']}
        />
      </CommunicationForm>
    </div>
  );
};

export default JourneyScheduledEmail;
