import { createSelector } from 'reselect';

import { RootState } from 'redux/schemas';
import { NProfileRequirement, ProfileRequirement } from 'redux/schemas/models/profile-requirement';
import {
  getOrgProfileField,
  getCourseProfileQuestion,
} from 'redux/selectors/profile-questions';

export const getProfileRequirement = (
  state: RootState,
  profileRequirementId: number,
) => state.models.profileRequirements[profileRequirementId.toString()];

export const createGetProfileRequirementOrgFields = () => createSelector(
  (state: RootState) => state,
  getProfileRequirement,
  (state: RootState, profileRequirement: NProfileRequirement) => (
    profileRequirement.orgFields.map(
      (fieldId: number) => getOrgProfileField(state, fieldId),
    )
  ),
);

export const createGetProfileRequirementCourseProfileQuestions = () => createSelector(
  (state: RootState) => state,
  getProfileRequirement,
  (state: RootState, profileRequirement: NProfileRequirement) => (
    profileRequirement.courseProfileQuestionIds.map(
      (questionId: number) => getCourseProfileQuestion(state, questionId),
    )
  ),
);
