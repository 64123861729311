import { mergeWith } from 'lodash';
import { replaceArrays } from '../../shared/lodash-utils';
import store, { cloneDeepSerializable } from '../../redux/store';
import { providerTypes } from '../../institutions/components/advanced-settings/integrations/lms-integration';
import { setCourseHighlight } from '../../redux/actions/institutions';

/* @ngInject */
export default function EditCourseCtrl(
  CurrentPermissionsManager,
  $scope,
  $controller,
  $state,
  AlertMessages,
  ConfirmationOverlays,
  StateManager,
  CoursesManager,
  _,
  $timeout,
  config,
  CurrentUserManager,
  FilterCoursesManager,
  InstitutionsManager,
) {
  angular.extend(this, $controller('CourseFormBaseCtrl'));
  const vm = this;
  vm.config = config;
  vm.CoursesManager = CoursesManager;
  vm.saveCourse = saveCourse;
  vm.cancel = cancel;
  vm.$state = $state;
  vm.isNameValid = false;
  vm.setNameValid = setNameValid;

  /* Values that need confirmation from user before saving */
  vm.confirmationKeys = ['isSelfPaced'];

  /** Spot-fix for NOV-62581
   * If a tooltip is shown in the Institution Dashboard Home page while the navigation into
   * this page is in-process (user mouses over a row after clicking the Basics link), the tooltip
   * will persist in the browser UI indefinitely. This manually removes it.
   * TODO: Please find a more elegant fix for this if it affects other tooltips/experiences */
  const reactTooltipNode = document.getElementById('tooltip');
  if (reactTooltipNode) {
    reactTooltipNode.remove();
  }

  initialize();

  function initialize() {
    vm.courseDraft = angular.copy(CoursesManager.course);
    vm.initialize($scope);
    if (vm.institution.courseEnrollmentTypes.length === 0) {
      vm.courseEnrollmentTypes = vm.CourseModel.REGISTRATION_TYPES;
    } else {
      vm.courseEnrollmentTypes = _.filter(vm.CourseModel.REGISTRATION_TYPES, (typeOfRegistration) => _.includes(vm.institution.courseEnrollmentTypes, typeOfRegistration.toString())
            || vm.CurrentUserManager.user.admin || CoursesManager.course.typeOfRegistration === typeOfRegistration);
    }

    vm.videoSharing = !!CoursesManager.course.parentCourseId && CoursesManager.course.parentCourseId !== CoursesManager.course.id;
    vm.hasBeenVideoSharing = !!CoursesManager.course.parentCourseId && CoursesManager.course.parentCourseId !== CoursesManager.course.id;
    vm.setNameValid(vm.nameIsValid(vm.courseDraft.name));
    vm.msCalendar = vm.courseDraft.calendarEventsEnabled;

    if (!vm.courseDraft.isProgram && !vm.courseDraft.durationType) {
      vm.courseDraft.durationType = 'hours';
    }

    if (vm.courseDraft.skill_tags) {
      // The APIs on this page expect these tags to be a comma separated string instead of an array of strings
      vm.courseDraft.skill_tags = vm.courseDraft.skill_tags.toString();
    }
    if (vm.institution.lmsEnabled && vm.institution.lmsConnectionSettings?.lms === providerTypes.SUMTOTAL) {
      const lmsActivityCode = vm.courseDraft?.lmsCourse?.externalCourseNumber;
      const lmsActivityId = vm.courseDraft?.lmsCourse?.externalCourseId;
      vm.courseDraft.sumTotalIntegration = {
        validating: false,
        editing: false,
        validationError: false,
        lmsActivityCode,
        lmsActivityId,
      };
      _.extend(vm.courseDraft, { lmsActivityCode, lmsActivityId });
    }

    vm.renderReactCheckboxDropdown();
    vm.renderReactDropdown();
  }

  function cancel() {
    const prevStateData = $state.params.prevStateData || { name: 'course-home', params: { catalogId: CoursesManager.course.catalogId } };
    $state.go(prevStateData.name, prevStateData.params);
  }

  function setNameValid(isValid) {
    $timeout(() => {
      vm.isNameValid = isValid;
    });
  }
  function saveValues() {
    let isVideoSharingDecoupling = false;

    // stops video sharing
    if (vm.hasBeenVideoSharing && !vm.videoSharing) {
      isVideoSharingDecoupling = true;
    }

    // has video sharing enabled but chooses a new source
    if (vm.hasBeenVideoSharing && CoursesManager.course.parentCourseId !== vm.courseDraft.parentCourseId) {
      isVideoSharingDecoupling = true;
    }

    vm.savingCourse = true;
    if (CoursesManager.course.headerBackground === vm.courseDraft.headerBackground) {
      vm.courseDraft.headerBackground = null;
    }

    if (vm.courseDraft.logo?.url) {
      vm.courseDraft.logo = null;
    }

    _.extend(CoursesManager.course, vm.courseDraft);

    vm.baseSaveCourse()
      .then(course => {
        vm.courseForm.$setPristine();
        const prevStateData = $state.params.prevStateData || { name: 'course-home', params: { catalogId: course.catalogId } };
        prevStateData.params.catalogId = course.catalogId;

        const currentEnrollment = CurrentUserManager.user.enrollments.find((enrollment) => enrollment.course.id === course.id);

        if (currentEnrollment) {
          mergeWith(currentEnrollment.course, cloneDeepSerializable(course), replaceArrays);
          const currentRawUserData = cloneDeepSerializable(CurrentUserManager.user);
          CurrentUserManager.setCourses(currentRawUserData);
          CurrentUserManager.sortCourses();
          CurrentUserManager.setUserCourses(currentRawUserData);
        }

        if (course.isPrimary && (
          CurrentPermissionsManager.hasOrgAdminPermissions()
          || CurrentPermissionsManager.hasCourseManagerPermissions()
        )) {
          store.dispatch(setCourseHighlight(course.catalogId));
          $state.go('institution-dashboard', {
            dashboardTab: FilterCoursesManager.tabForCourse(course),
            institutionId: InstitutionsManager.institution.id,
          }, { reload: true });
        } else {
          $state.go(prevStateData.name, prevStateData.params, { reload: true });
        }

        // had to fix this as state.go was not returning a promise.
        $state.transition.then(() => {
          AlertMessages.success(
            'COURSES.FORM.UPDATED_SUCCESSFULLY_WITH_VIDEO_SHARING_DECOUPLING',
            null,
            { isProgram: vm.courseDraft.isProgram, isVideoSharingDecoupling },
          );
        });
      },
      (response) => vm.handleErrorResponse(response))
      .finally(() => {
        vm.savingCourse = false;
      });
  }

  async function saveCourse() {
    const originalValues = _.pick(CoursesManager.course, vm.confirmationKeys);
    const tempValues = _.pick(vm.courseDraft, vm.confirmationKeys);

    const valuesChanged = !vm.newCourse && !_.isEqual(originalValues, tempValues);

    let canSave = true;

    if (canSave && valuesChanged) {
      let messageKey;
      if (vm.courseDraft.isSelfPaced) {
        messageKey = 'COURSES.FORM.SELF_PACED.CONFIRMATION_MESSAGE';
      } else {
        messageKey = 'COURSES.FORM.TIME_BASED.CONFIRMATION_MESSAGE';
      }

      const modalCtrl = ['$scope', ($ctrlScope) => {
        $ctrlScope.messageKey = messageKey;
      }];

      await ConfirmationOverlays.openConfirmationModal('courses/templates/change-course-settings-confirm.html', modalCtrl).result.catch(() => {
        canSave = false;
      });
    }

    if (canSave && !vm.videoSharing && vm.hasBeenVideoSharing) {
      const modalCtrl = ['$scope', ($ctrlScope) => {
        $ctrlScope.courseName = vm.courseDraft.name;
      }];

      await ConfirmationOverlays.openConfirmationModal('courses/templates/remove-video-sharing-confirmation.html', modalCtrl).result.catch(() => {
        canSave = false;
      });
    }

    if (canSave) {
      saveValues();
    }
  }

  function checkUnsavedChanges() {
    return vm.courseForm?.$dirty;
  }

  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(
    checkUnsavedChanges,
    'shared/templates/modal-navigate-away.html',
    'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY',
  );

  $scope.$on('$destroy', () => {
    vm.deregisterStateChangeStart?.();
  });
}
