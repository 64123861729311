/* @ngInject */
export default function TeamRecentAssignmentsCtrl(
  ReportsManager,
  ReportsResources,
  $stateParams,
  $state,
  $scope,
) {
  class TeamRecentAssignmentsController {
    constructor() {
      const { catalogId } = $stateParams;
      const { assignments, hideRef } = $scope;

      hideRef();

      this.ReportsManager = ReportsManager;
      this.ReportsManager.reports = [];

      assignments.forEach((assignment) => {
        ReportsResources.getSingleSubmission({
          catalogId,
          reportId: assignment.id,
        }, (resource) => {
          this.ReportsManager.reports.push(resource.result);
          if (this.ReportsManager.reports.length === 1) {
            $state.go('individual-submission-modal', { catalogId, reportId: assignment.id, galleryMode: assignments.length > 1 });
          }
        });
      });
    }
  }

  return new TeamRecentAssignmentsController();
}
