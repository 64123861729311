import { css } from '@emotion/react';
import React, { useContext, useEffect, useState } from 'react';
import { NvResponsiveTabsDisplayType, NvTab } from 'shared/components/nv-responsive-tabs';
import NvResponsiveTabsRow from 'shared/components/nv-responsive-tabs-row';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { gray5, gray7 } from 'styles/global_defaults/colors';
import { createGridStyles } from 'styles/global_defaults/scaffolding';
import Branding from './branding/branding';
import DataConfiguration from './data-configuration/data-configuration';
import PrivacySecurity from './privacy-and-security/privacy-security';
import Integrations from './integrations/integrations';
import AiHub from './ai-hub/ai-hub';
import { config } from '../../../../config/pendo.config.json';

const styles = css`
  .tab-container {
    max-width: 800px;
  }
`;

const tabStyles = {
  ...createGridStyles(1, 2),
  justifyContent: 'center',
  background: gray7,
  borderBottom: `solid 1px ${gray5}`,
  position: 'sticky',
  top: 0,
  zIndex: 1,
};

enum Tab {
  BRANDING = 'branding',
  DATA_CONFIGURATION = 'data_configuration',
  PRIVACY = 'privacy',
  INTEGRATIONS = 'integrations',
  AI_HUB = 'ai_hub',
}

const AdvancedSettings = () => {
  const { $state } = useContext(AngularServicesContext);

  const [activeTab, setActiveTab] = useState<Tab>($state.params?.tab);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!activeTab && $state.params?.tab) {
      setActiveTab($state.params?.tab);
    }
  }, [$state.params?.tab]);

  useEffect(() => {
    if ($state.params?.zoom_account_oauth_success === true) {
      handleTabChange(Tab.INTEGRATIONS, 'zoom_account_oauth_success');
      dispatch(
        addAlertMessage({
          type: AlertMessageType.SUCCESS,
          header: t.FORM.SUCCESS_BANG(),
          message: t.INSTITUTIONS.ADVANCED_SETTINGS.ENABLE_ZOOM_SUCCESS(),
        }),
      );
    }
    if ($state.params?.mailbox === false) {
      handleTabChange(Tab.INTEGRATIONS, 'mailbox');
      dispatch(
        addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_CALENDAR_INSTITUTION_INTEGRATION.MAILBOX_FAILURE(),
        }),
      );
    } else if ($state.params?.redirect === 'outlook') {
      handleTabChange(Tab.INTEGRATIONS, 'redirect');
      dispatch(
        addAlertMessage({
          type: AlertMessageType.SUCCESS,
          header: t.FORM.SUCCESS_BANG(),
          message: t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_CALENDAR_INSTITUTION_INTEGRATION.SUCCESS(),
        }),
      );
    }
  }, [$state.params]);

  const tabs: NvTab[] = [
    {
      text: t.INSTITUTIONS.ADVANCED_SETTINGS.BRANDING(),
      onClick: () => handleTabChange(Tab.BRANDING),
      dataQA: config.pendo.settings.branding,
    },
    {
      text: t.INSTITUTIONS.ADVANCED_SETTINGS.DATA_CONFIGURATION(),
      onClick: () => handleTabChange(Tab.DATA_CONFIGURATION),
      dataQA: config.pendo.settings.dataConfiguration,
    },
    {
      text: t.INSTITUTIONS.ADVANCED_SETTINGS.PRIVACY_SECURITY(),
      onClick: () => handleTabChange(Tab.PRIVACY),
      dataQA: config.pendo.settings.privacyAndSecurity,
    },
    {
      text: t.INSTITUTIONS.ADVANCED_SETTINGS.INTEGRATIONS(),
      onClick: () => handleTabChange(Tab.INTEGRATIONS),
      dataQA: config.pendo.settings.integrations,
    },
    {
      text: t.INSTITUTIONS.ADVANCED_SETTINGS.AI_HUB(),
      onClick: () => handleTabChange(Tab.AI_HUB),
      dataQA: config.pendo.settings.aiHub,
    },
  ];

  const handleTabChange = (tab, clear = null) => {
    setActiveTab(tab);
    const params = { tab, reload: false };
    // clear the unwanted param from the URL
    if (clear) {
      params[clear] = null;
    }
    $state.go($state.current, params, { notify: false });
  };

  const tabContent: { [key in Tab]: JSX.Element } = {
    [Tab.BRANDING]: <Branding />,
    [Tab.DATA_CONFIGURATION]: <DataConfiguration />,
    [Tab.PRIVACY]: <PrivacySecurity />,
    [Tab.INTEGRATIONS]: <Integrations />,
    [Tab.AI_HUB]: <AiHub />,
  };

  return (
    <div className='d-flex flex-column' css={styles}>
      <NvResponsiveTabsRow
        defaultTabs={tabs}
        tabType={NvResponsiveTabsDisplayType.TEXT_ONLY}
        tabTextClass='card-title'
        style={tabStyles}
        revertActiveTab={activeTab && Object.values(Tab).indexOf(activeTab)}
      />
      <div className='tab-container d-flex justify-content-center py-6 mx-auto'>
        {activeTab ? tabContent[activeTab] : tabContent.branding}
      </div>
    </div>
  );
};

export default AdvancedSettings;
