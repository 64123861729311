import React from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { AngularServicesContext } from 'react-app';
import { Course } from 'redux/schemas/models/course';
import { fetchJourney } from 'redux/actions/learning-journeys';

const useJourneyFormUtils = (formState?) => {
  const dispatch = useAppDispatch();
  const formStateRef = React.useRef();
  const { catalogId } = useParams<{ catalogId: string }>();
  const catalogIdRef = React.useRef(catalogId);
  const { $scope, $state } = React.useContext(AngularServicesContext);

  formStateRef.current = formState;

  const isDirty = formState?.isDirty ?? false;

  React.useEffect(() => {
    let deregister;

    if (formStateRef.current) {
      deregister = $scope.StateManager.registerStateChangeStart(
        () => isDirty,
        'shared/templates/modal-navigate-away.html',
        'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY',
      );
    }

    return () => deregister?.();
  }, [isDirty, $scope.StateManager]);

  const showErrorAlert = () => $scope.AlertMessages.error(
    'FORM.OOPS',
    'FORM.ERROR_SOMETHING_WRONG',
    {},
    {},
  );

  const showSuccessAlert = (isEditing) => $scope.AlertMessages.success(
    `LEARNING_JOURNEYS.FORMS.${isEditing ? 'UPDATED' : 'CREATED'}_SUCCESSFULLY`,
  );

  React.useEffect(() => {
    catalogIdRef.current = catalogId;
  });

  const fetchJourneyRef = React.useRef((preferedCatalogId?: string, isEditing?: boolean) => wrapThunkAction<{
    journey: Course,
    isEditing: boolean,
  }>(
    dispatch(
      fetchJourney({
        catalogId: preferedCatalogId ?? catalogIdRef.current,
        isEditing,
      }),
    ),
  ).catch((action) => {
    if (action.payload === 404 || action.payload === 401) {
      $scope.AlertMessages.error('', 'COURSE_HOME.HEADER.COURSE_ACCESS_DENIED');
      $state.go('learning-journeys');
    }

    // Rejecting the same caught action because this function is only an
    // interceptor that shows an alert message and redirects to journeys
    // dashboard
    return Promise.reject(action);
  }));

  return {
    showErrorAlert,
    showSuccessAlert,
    getJourney: fetchJourneyRef.current,
  };
};

export default useJourneyFormUtils;
