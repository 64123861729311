/* eslint-disable import/prefer-default-export */
import _ from 'underscore';

// See https://docs.sentry.io/platforms/javascript/angular/#angularjs-1x for angular 1.x integration
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { Integration, StackFrame } from '@sentry/types';

import { config } from '../../config/config.json';

const { Dedupe } = Integrations;

/** This is a bit of a hack to override the implementation of the error deduplication checking. Angular.js
 * frequently triggers the same error many times in succession (within a second), but with very slightly different
 * stack traces.  See https://github.com/getsentry/sentry-javascript/blob/master/packages/integrations/src/dedupe.ts#L107
 * for the original implementation. */
(Dedupe.prototype as any)._isSameStacktrace = function (currentEvent, previousEvent) {
  const currentFrames: StackFrame[] = this._getFramesFromEvent(currentEvent);
  const previousFrames: StackFrame[] = this._getFramesFromEvent(previousEvent);

  const framesAreEqual = (i: number) => {
    const frameA = previousFrames[i];
    const frameB = currentFrames[i];
    if (frameA.filename !== frameB.filename
          || frameA.lineno !== frameB.lineno
          || frameA.colno !== frameB.colno
          || frameA.function !== frameB.function) {
      return false;
    }

    return true;
  };

  // If neither event has a stacktrace, they are assumed to be the same
  if (!currentFrames && !previousFrames) {
    return true;
  }
  // If only one event has a stacktrace, but not the other one, they are not the same
  if ((currentFrames && !previousFrames) || (!currentFrames && previousFrames)) {
    return false;
  }

  // Decide that stack traces are effectively the same if the first frame (the one closest to the error) is
  // identical. This prevents us from sending many duplicate error reports due to Angular.js triggering the same error
  // multiple times with subtlely different stack traces
  if (framesAreEqual(0)) {
    return true;
  }

  // If number of frames differ, they are not the same
  if (previousFrames.length !== currentFrames.length) {
    return false;
  }
  // Otherwise, compare the two
  for (let i = 1; i < previousFrames.length; i += 1) {
    return framesAreEqual(i);
  }
  return true;
};


const createSentryIntegrations = (angularInstance) => {
  const sentryIntegrations: Integration[] = [new Integrations.Angular(angularInstance), new Integrations.Dedupe()];

  // See https://github.com/getsentry/sentry-javascript/issues/2100; fixes error stack traces from originating
  // from `breadcrumbs.js` in dev
  if (process.env.NODE_ENV === 'development') {
    sentryIntegrations.push(new Sentry.Integrations.Breadcrumbs({
      console: false,
    }));
  }

  return sentryIntegrations;
};

// A list of error messages that will not be reported
const blacklistedErrors = [
  'Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target',
  'UnhandledRejection: Non-Error promise rejection captured with keys: isTrusted',
  // we've fixed all known instances of "$rootScope:inprog: $digest already in progress"
  // unfortunately this is killing our quota and does not provide a meaningful breadcrumb
  '$rootScope:inprog',
  // https://sentry.io/organizations/novoed/issues/1267577229/?project=1761446&query=is%3Aunresolved+play%28%29&statsPeriod=90d
  'The play() request was interrupted by a "pause" event.',
  // https://sentry.io/organizations/novoed/issues/1267325719/?project=1761446&query=is%3Aunresolved+play%28%29&statsPeriod=90d
  'AbortError: The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22',
];

export const sentryEnabled = process.env.NODE_ENV !== 'development';

// TODO: Enhance React error reporting with https://docs.sentry.io/platforms/javascript/react/
export const initSentry = (angularInstance) => {
  Sentry.init({
    dsn: 'https://93709601451a4655ae47cfbae439c664@sentry.io/1761446',
    sampleRate: config.sentry.sampleRate,
    integrations: createSentryIntegrations(angularInstance),
    environment: config.envName,
    release: process.env.RELEASE_NAME,
    ignoreErrors: [
      'Cannot read property \'metadataType\' of undefined',
      'Extension context invalidated.',
      // https://sentry.io/organizations/novoed/issues/1626400936/
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      'ResizeObserver loop limit exceeded',
    ],
    blacklistUrls: [
      /extensions\//i,
      /^chrome:\/\//i,
      /^file:\/\//i,
    ],
    beforeSend: (event, hint) => {
      if (navigator?.userAgent && navigator.userAgent.toLowerCase().indexOf('phantomjs') !== -1) {
        return null;
      }

      try {
        // Filtering out extension errors like https://sentry.io/organizations/novoed/issues/1267242319/?project=1761446&query=is%3Aunresolved&statsPeriod=14d
        if ((event.extra.__serialized__ as any).detail.reason.message === 'Cannot read property \'metadataType\' of undefined'
         || (event.extra.__serialized__ as any).detail.reason.message === 'Extension context invalidated.') return null;
      } catch (e) {
        // Continue with normal error checking
      }

      // Check if the error is blacklisted. Some errors use a `message` property, while others
      // fill a list of exception objects
      let isBlacklisted = false;

      if (event.message) {
        isBlacklisted = blacklistedErrors.indexOf(event.message) !== -1;
      } else {
        isBlacklisted = _.any(event.exception?.values, (v) => blacklistedErrors.indexOf(v.value) !== -1) ?? false;
      }

      if (isBlacklisted) {
        return null;
      }

      // Ignore errors with an invalid error object https://novoed.atlassian.net/browse/NOV-63152
      if (event?.exception?.values?.[0].value.startsWith('Non-Error exception captured')) {
        return null;
      }

      // https://github.com/getsentry/sentry-javascript/issues/3440
      if (event?.exception?.values?.[0].value.startsWith('Non-Error promise rejection captured')) {
        return null;
      }

      if ((hint?.originalException as Error)?.message?.startsWith('Non-Error exception captured')) {
        return null;
      }

      return event;
    },
    enabled: sentryEnabled,
  });
};
