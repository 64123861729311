import React from 'react';
import { css } from '@emotion/react';
import CustomRadioGroup from 'components/custom-radio-group';
import { Style3AccordionSectionLectureComponent } from 'redux/schemas/models/lecture-component';
import t from 'react-translate';
import AbstractOrderedSectionContainer, { getOrderedSectionIndexString } from './abstract-ordered-section-container';
import { SectionContainerProps } from './accordion-types';
import FixedHeightLetter from './fixed-height-letter';
import {
  SquareCentered,
  CircleCentered,
  innerCenteredRadioButtonContainerStyle,
  RadioButtonContainer,
  RadioButtonProps,
} from './ordered-section-left-hand-symbol-style-selector';

/* @ngInject */
export function SquareRightAligned(props: RadioButtonProps) {
  return (
    <RadioButtonContainer checked={props.checked}>
      <div
        css={css`
          ${innerCenteredRadioButtonContainerStyle};
          display: flex;
          justify-content: flex-end;
          overflow: hidden;
        `}
      >
        <FixedHeightLetter capitalHeight={30}>{props.children}</FixedHeightLetter>
      </div>
    </RadioButtonContainer>
  );
}

const radioButtonsGroupValues = [
  {
    value: 'square-centered',
    label: 'Square centered',
  } as const,

  {
    value: 'circle-centered',
    label: 'Circle centered',
  } as const,

  {
    value: 'square-aligned-right',
    label: 'Square aligned right',
  } as const,
];

export type LeftHandSymbolStyles = typeof radioButtonsGroupValues[number]['value'];

/**
 * maps the viewOptions to the radio button value
 */
/* @ngInject */
export function mapViewOptionsToValue(
  viewOptions: Style3AccordionSectionLectureComponent['viewOptions'],
): LeftHandSymbolStyles {
  if (viewOptions.leftHandSymbolPosition === 'right') {
    return 'square-aligned-right';
  }
  if (viewOptions.leftHandSymbolShape === 'circle') {
    return 'circle-centered';
  }
  return 'square-centered';
}

/**
 * maps the value to a viewOption object
 */
/* @ngInject */
export function mapValueToViewOptions(
  value: LeftHandSymbolStyles,
): Partial<Style3AccordionSectionLectureComponent['viewOptions']> {
  if (value === 'circle-centered') {
    return {
      leftHandSymbolPosition: 'centered',
      leftHandSymbolShape: 'circle',
    };
  }

  if (value === 'square-aligned-right') {
    return {
      leftHandSymbolPosition: 'right',
      leftHandSymbolShape: 'square',
    };
  }

  // value === 'square-centered'
  return {
    leftHandSymbolPosition: 'centered',
    leftHandSymbolShape: 'square',
  };
}

interface LeftHandSymbolStyleSelectorProps {
  value: LeftHandSymbolStyles;
  onChange: (value: LeftHandSymbolStyles) => void;
}

/**
 * Radio group for the style 3 left hand symbol style selector
 */
function Style3LeftHandSymbolStyleSelector(props: LeftHandSymbolStyleSelectorProps) {
  return (
    <CustomRadioGroup
      css={css`
        display: flex;
        align-self: flex-start;
      `}
      optionContainerStyle={css`
        margin-right: 7px;
      `}
      activeValue={props.value}
      name='style-3-left-hand-symbol-style'
      onChange={props.onChange}
      radios={radioButtonsGroupValues}
      renderRadioButton={(radioButtonProps) => {
        const children = getOrderedSectionIndexString(0, 'decimal');
        let Component = SquareRightAligned;
        if (radioButtonProps.value === 'square-centered') {
          Component = SquareCentered;
        } else if (radioButtonProps.value === 'circle-centered') {
          Component = CircleCentered;
        }

        return <Component checked={radioButtonProps.checked}>{children}</Component>;
      }}
    />
  );
}

type Style3SectionContainerProps = SectionContainerProps<Style3AccordionSectionLectureComponent>;

/* @ngInject */
export default function Style3SectionContainer(props: Style3SectionContainerProps) {
  return (
    <AbstractOrderedSectionContainer
      orderedSectionType='decimal'
      defaultHeaderTextColor='#ffffff'
      leftHandSymbolStyleSelector={(
        <React.Fragment>
          <div className='label gray-1 mb-2'>{t.SHARED.SELECT_SHAPE()}</div>
          <Style3LeftHandSymbolStyleSelector
            value={mapViewOptionsToValue(props.sectionData.viewOptions)}
            onChange={(value) => {
              props.onViewOptionsChange(mapValueToViewOptions(value));
            }}
          />
        </React.Fragment>
      )}
      {...props}
    />
  );
}
