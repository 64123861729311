/* @ngInject */
export default function NvSetVisibility(
) {
  return {
    restrict: 'A',
    scope: {
      setVisibilityCallbackFn: '=',
    },
    link(scope, element, attrs) {
      scope.setVisibilityCallbackFn(element.is(':visible'));

      scope.$watch(
        () => element.is(':visible'),
        (newVal, oldVal) => {
          if (oldVal !== newVal) {
            scope.setVisibilityCallbackFn(newVal);
          }
        },
      );
    },
  };
}
