import { PayloadAction } from '@reduxjs/toolkit';

/* eslint-disable import/prefer-default-export */
/**
 * Function that wraps a thunk action that always resolves an action (no matter
 * if thunk fails or succeeds) into a promise that resolves if the thunk
 * succeeded and rejects if the thunk didn't.
 */
export const wrapThunkAction = <Payload extends unknown>(promise) => new Promise<PayloadAction<Payload>>((resolve, reject) => {
  promise.then((action) => {
    if (/\w+\/fulfilled$/.test(action.type)) {
      resolve(action);
    } else {
      reject(action);
    }
  });
});
