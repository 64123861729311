import React, { useContext, useEffect, useState } from 'react';
import { AngularContext } from 'react-app';
import t from 'react-translate';
import SectionContent from '../section-content';
import { config } from '../../../../../config/pendo.config.json';

const WelcomeEmail = () => {
  const { injectServices, $scope } = useContext(AngularContext);
  const [InstitutionsManager] = injectServices(['InstitutionsManager']);
  const [hasEnabled, setHasEnabled] = useState($scope.$eval('vm.InstitutionsManager.institution.welcomeEmailEnabled'));

  useEffect(() => {
    $scope.$watch('vm.InstitutionsManager.institution.welcomeEmailEnabled', (newValue) => {
      setHasEnabled(newValue);
    });
  }, [$scope]);

  const enableWelcomeEmail = () => {
    InstitutionsManager.enableWelcomeEmail();
  };

  const disableWelcomeEmail = () => {
    InstitutionsManager.disableWelcomeEmail();
  };

  return (
    <SectionContent
      header={t.INSTITUTIONS.ADVANCED_SETTINGS.WELCOME_EMAIL_TITLE()}
      description={[t.INSTITUTIONS.ADVANCED_SETTINGS.WELCOME_EMAIL_DESCRIPTION()]}
      showCTAButton
      ctaText={hasEnabled
        ? t.INSTITUTIONS.ADVANCED_SETTINGS.DISABLE_WELCOME_EMAIL()
        : t.INSTITUTIONS.ADVANCED_SETTINGS.ENABLE_WELCOME_EMAIL()}
      onAction={() => (hasEnabled ? disableWelcomeEmail() : enableWelcomeEmail())}
      buttonVariant={hasEnabled ? 'secondary' : 'primary'}
      dataQa={config.pendo.settings.welcomeEmailCta}
    />
  );
};

export default WelcomeEmail;
