/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import t from 'react-translate';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { omit } from 'underscore';
import {
  FetchRepliesRequest,
  FetchRepliesResponse,
  CreateReplyRequest,
  DeleteReplyRequest,
  UpdateReplyRequest,
  LikeReplyRequest,
  LikeResponse,
  AddReplyRequest,
  AddReplyResponse,
  EditReplyRequest,
  EditReplyResponse,
  RemoveReply,
  VoteReply,
  GetReplyLikersInfoRequest,
  RemoveNewReplyId,
  TranslateReplyRequest,
} from 'redux/schemas/api/reply';
import { fetchSinglePost } from 'redux/actions/posts';
import { User } from 'redux/schemas/models/my-account';
import { Result } from 'redux/schemas/api';
import { Reply } from 'redux/schemas/models/reply';

import { makeQueryParamString } from 'redux/actions/helpers';
import { addAlertMessage } from './alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';

const REPLIES_PAGE_SIZE = 5;

const getSingleReply = (catalogId, replyId) => axios.get<Result<Reply>>(`${catalogId}/comments/${replyId}`);

export const removeNewReplyId = createAction<RemoveNewReplyId>('REMOVE_NEW_REPLY_ID');

export const getReplyLikersInfo = createAsyncThunk<User[], GetReplyLikersInfoRequest>(
  'REPLY_LIKERS_INFO',
  async (params) => {
    const response = await axios.get(`${params.catalogId}/comments/${params.replyId}/voters?page=${params.page}`);
    return response.data.result;
  },
);

export const addReply = createAsyncThunk<AddReplyResponse, AddReplyRequest>(
  'ADD_REPLY',
  async (params, thunkAPI) => {
    const { catalogId, postId, replyId } = params;
    const res = await getSingleReply(catalogId, replyId);
    if (postId) {
      thunkAPI.dispatch(fetchSinglePost({ catalogId, postId, includeUserBar: true }));
    }
    return {
      ...omit(params, 'replyId'),
      reply: res.data.result,
    };
  },
);

export const removeReply = createAsyncThunk<RemoveReply, RemoveReply>(
  'REMOVE_REPLY',
  async (params, thunkAPI) => {
    const { catalogId, postId } = params;
    if (postId) {
      thunkAPI.dispatch(fetchSinglePost({ catalogId, postId, includeUserBar: true }));
    }
    return params;
  },
);

export const editReply = createAsyncThunk<EditReplyResponse, EditReplyRequest>(
  'EDIT_REPLY',
  async (params) => {
    const { catalogId, replyId } = params;
    const res = await getSingleReply(catalogId, replyId);
    return { reply: res.data.result };
  },
);

export const voteReply = createAsyncThunk<VoteReply, VoteReply>(
  'LIKE_REPLY',
  async (params, thunkAPI) => {
    const { catalogId, replyId } = params;
    thunkAPI.dispatch(getReplyLikersInfo({
      catalogId,
      replyId,
      page: 1,
    }));
    return params;
  },
);

export const fetchReplies = createAsyncThunk<FetchRepliesResponse, FetchRepliesRequest>(
  'FETCH_REPLIES',
  async (params) => {
    const queryString = makeQueryParamString(
      omit({
        ...params,
        pageSize: params.pageSize ?? REPLIES_PAGE_SIZE,
      },
      ['catalogId', 'commentId']),
    );
    const response = await axios.get(`${params.catalogId}/posts/${params.commentId}/comments?${queryString}`);
    return response.data.result;
  },
);

export const createReply = createAsyncThunk<Reply, CreateReplyRequest>(
  'CREATE_REPLY',
  async (params, thunkAPI) => {
    const { catalogId, commentId, postId, callback } = params;
    const response = await axios.post(`${catalogId}/posts/${commentId}/comments`, {
      comment: params,
      mentionedIds: params.mentionedIds,
    });
    if (postId) {
      thunkAPI.dispatch(fetchSinglePost({ catalogId, postId, callback, includeUserBar: true }));
    }
    return response.data.result;
  },
);

export const deleteReply = createAsyncThunk<Reply, DeleteReplyRequest>(
  'DELETE_REPLY',
  async (params, thunkAPI) => {
    const { catalogId, postId, replyId, callback } = params;
    const response = await axios.delete(`${catalogId}/comments/${replyId}`);
    if (postId) {
      thunkAPI.dispatch(fetchSinglePost({ catalogId, postId, callback, includeUserBar: true }));
    }
    return response.data.result;
  },
);

export const updateReply = createAsyncThunk<Reply, UpdateReplyRequest>(
  'UPDATE_REPLY',
  async (params) => {
    const response = await axios.put(`${params.catalogId}/comments/${params.replyId}`, {
      comment: params.comment,
      mentionedIds: params.mentionedIds,
    });
    return response.data;
  },
);

export const voteUpReply = createAsyncThunk<LikeResponse, LikeReplyRequest>(
  'VOTE_UP_REPLY',
  async (params) => {
    const response = await axios.post(`${params.catalogId}/comments/${params.replyId}/voteup`, {});
    return response.data.result;
  },
);

export const voteDownReply = createAsyncThunk<LikeResponse, LikeReplyRequest>(
  'VOTE_DOWN_REPLY',
  async (params) => {
    const response = await axios.post(`${params.catalogId}/comments/${params.replyId}/votedown`, {});
    return response.data.result;
  },
);

export const translateReply = createAsyncThunk<Reply, TranslateReplyRequest>(
  'TRANSLATE_DISCUSSION_REPLY',
  async ({ catalogId, replyId, language }, thunkAPI) => {
    try {
      const response = await axios.post(`${catalogId}/comments/${replyId}/translate`, { language });
      return response.data.result;
    } catch (error) {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.DISCUSSIONS_AUTO_TRANSLATION.ERROR(),
        message: t.DISCUSSIONS_AUTO_TRANSLATION.RETRY()
      }))
      return thunkAPI.rejectWithValue(error)
    }
  }
)
