import { createReducer } from '@reduxjs/toolkit';
import { enableHCMIntegration, updateHCMIntegration } from 'redux/actions/hcm';
import { HCMConfigState, HCMSettings } from 'redux/schemas/models/hcm';
import { initialRootState } from './index';

export const initialHCMConfigState: HCMConfigState = {
  isLoading: false,
};

export const initialHCMSettings: HCMSettings = {
  endpoint: '',
  username: '',
  password: '',
  integrationType: '',
};

const setHCMConfig = (state, action) => {
  state.app.hcmConfig.isLoading = false;
  if (action.payload !== 500) {
    Object.assign(state.models.hcmConfig, action.payload);
  }
};

export default createReducer(initialRootState, (builder) => {
  builder.addCase(enableHCMIntegration.pending, (state) => {
    state.app.hcmConfig.isLoading = true;
  });

  builder.addCase(updateHCMIntegration.pending, (state) => {
    state.app.hcmConfig.isLoading = true;
  });

  builder.addCase(enableHCMIntegration.fulfilled, (state, action) => {
    setHCMConfig(state, action);
  });

  builder.addCase(updateHCMIntegration.fulfilled, (state, action) => {
    setHCMConfig(state, action);
  });

  builder.addCase(enableHCMIntegration.rejected, (state, action) => {
    state.app.hcmConfig.isLoading = false;
  });

  builder.addCase(updateHCMIntegration.rejected, (state, action) => {
    state.app.hcmConfig.isLoading = false;
  });
});
