import { createReducer } from '@reduxjs/toolkit';
import { getCourseRolesCount, loadRoles } from 'redux/actions/roles';
import { initialRootState } from '.';
import { mergeWith } from 'lodash';

export default createReducer(initialRootState, builder => {
  builder
    .addCase(loadRoles, (state, action) => {
      state.models.roles = action.payload;
    })
    .addCase(getCourseRolesCount.fulfilled, (state, action) => {
      state.app.orderedCourseUsers = action.payload.response;
    });
});
