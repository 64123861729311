/* @ngInject */
export default function PageLevelManager(
  _,
  $state,
  TimelinesManager,
) {
  let isGoingBack = false;

  const manager = {
    // Data
    backStack: [],
    lastParentState: null,
    // Getters
    lastL4,
    lastParent,
    // Methods
    push,
    ignoreNextPush,
    callL4CloseCallbacks,
    isLastParentOrgLevel,
  };

  function push(prevState, prevParams, nextState, nextParams) {
    // for lecture page timeline keeping stuff
    if (prevParams.catalogId !== nextParams.catalogId
            || (prevState.data?.level === 1)
            || (nextState.data?.level === 1)
            || (prevState.data?.level === 2 && nextState.data?.level === 2)) {
      TimelinesManager.clearSavedUnmarkedLecturePageId();
    }

    // To reset the selected todos tab if switched to another l1 or l2
    if (prevParams.catalogId !== nextParams.catalogId
        || (prevState.name === 'lecture-page' && nextState.name === 'lecture-page')) {
      TimelinesManager.selectedTodo = null;
    }

    /* Back Stack */
    if (prevState.data?.level === 4 && nextState.data?.level === 4) {
      if (isGoingBack) {
        this.backStack.pop();
      } else if (!nextStateMatchesPreviousState(prevState, prevParams, nextState, nextParams) && nextParams.galleryMode !== 'true') {
        this.backStack.push({
          state: prevState,
          params: prevParams,
        });
      }
    } else {
      this.backStack = [];
    }
    isGoingBack = false;

    /* Close Stack */
    const prevLevel = prevState?.data?.level || 1;
    const nextLevel = nextState?.data?.level || 1;

    const stickyStateLevels = [3.5, 4];

    const isNextStickyState = stickyStateLevels.includes(nextLevel);

    if (prevLevel < 4 && isNextStickyState) {
      this.lastParentState = {
        state: prevState,
        params: prevParams,
      };
    }
  }

  function lastParent() {
    return this.lastParentState;
  }

  function lastL4() {
    return _.last(this.backStack);
  }

  function ignoreNextPush() {
    isGoingBack = true;
  }

  function nextStateMatchesPreviousState(prevState, prevParams, nextState, nextParams) {
    return prevState.name === nextState.name && _.matches(prevParams)(nextParams);
  }

  function callL4CloseCallbacks() {
    $state.go(this.lastParent().state.name, this.lastParent().params);
  }

  function isLeavingLecturePages(nextState) {
    return nextState.name !== 'lecture-page' && nextState.data?.level !== 4;
  }

  /* Whether the last parent state is an org level state */
  function isLastParentOrgLevel() {
    let $$state = null;

    if (this.lastParent() && this.lastParent().state && this.lastParent().state.$$state) {
      $$state = this.lastParent().state.$$state();
    }

    if ($$state) {
      return $$state.includes['institution-abstract'];
    }

    return false;
  }

  return manager;
}
