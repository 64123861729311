/* @ngInject */
export default function MenteesResources($resource) {
  return $resource(
    '/:catalogId/mentorships',
    {},
    {
      getMentees: {
        method: 'GET',
        url: '/:catalogId/mentorings/mentees/:ownerType/:ownerId.json',
        cancellable: true,
      },
      getFilterCounts: { method: 'GET', url: '/:catalogId/mentorings/summary/:ownerType/:ownerId.json' },
    },
  );
}
