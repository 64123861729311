import { css } from '@emotion/react';
import React, { useState, useMemo, Fragment } from 'react';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import _ from 'underscore';

// Selectors
import { getCourseAliases, getCourseActivities, getCoursesArray } from 'redux/selectors/course';
import { getLabelForActivity, getActivityTypeForKey } from 'redux/selectors/activity';
import { getCourseTimeLine } from 'redux/selectors/timeline';

// Schemas
import { CourseAliases, TimelineNormalized, TimelineItemTypes } from 'redux/schemas/models/course';
import { CourseActivities } from 'redux/schemas/api/course-communications';
import { ActivityKey, ActivityType } from 'redux/schemas/models/activity';
import { LecturePageType } from 'redux/schemas/models/lecture-page';
import { FeedbackCategory, OwnerType } from 'redux/schemas/models/course-communication';
import { RteTag, RteTagTypes } from 'froala/helpers/nv-froala-constants';

// Styles
import { standardSpacing, quarterSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { textMediumFontSize } from 'styles/global_defaults/fonts';

// Components
import NvDropdown, {
  NvDropdownTextItem,
  NvDropdownButtonStyle,
  NvDropdownOption,
} from 'shared/components/inputs/nv-dropdown';
import { convertRelatedActivityToRteTag, convertRelatedCourseToRteTag, createCourseRteTagValue, createRteTagValue, getCourseDateTypes } from 'froala/helpers/nv-froala-tag-functions';
import { getJourneyCollections } from 'redux/selectors/learning-journeys';

type FroalaCreateNewTagProps = {
  onClose(): void,
  onAdd(tags: RteTag): void,
  tags: { [key: string]: RteTag },
  rteTagType: RteTagTypes,
};

const styles = css`
  margin: ${standardSpacing}px 0px;

  > div {
    padding-left: 0px;
  }

  .add-tags-button-container {
    padding-top: ${quarterSpacing}px;
    padding-right: 0px;

    button {
      margin-right: ${quarterSpacing}px;
      &:last-child {
        margin-right: 0px
      }
    }
  }

  .bs4-dropdown {
    .bs4-dropdown-menu {
      .rte-tag-dropdown-item {
        font-size: ${textMediumFontSize}px;
        padding: ${quarterSpacing}px ${threeQuartersSpacing}px;
        .item-body {
          width: inherit;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .course-collections {
    .bs4-dropdown {
      position: inherit;
      .bs4-dropdown-menu {
        width: 100%;
      }
    }
  }

  .rte-tag-dropdown-title {
    font-size: ${textMediumFontSize}px;
    line-height: 16px;
  }

  .page-title-xxs {
    margin: ${quarterSpacing}px ${threeQuartersSpacing}px;
  }
`;

// Get label for activity type dropdown for pick another date
const getDateActivityDropdownLabel = (activityKey: ActivityKey, aliases: CourseAliases): string => {
  const label = activityKey === ActivityKey.LIVE_SESSION
    ? t.FROALA.INSERT_TAGS.START_DATE(getLabelForActivity(activityKey, aliases))
    : t.FROALA.INSERT_TAGS.DUE_DATE(getLabelForActivity(activityKey, aliases));
  return label;
};

// Create a list of course activity type dropdown
const createCourseActivityTypeDropDown = (
  courseActivities: CourseActivities,
  rteTagType: RteTagTypes,
  aliases: CourseAliases,
  callback: (type: ActivityKey | LecturePageType) => void,
): NvDropdownTextItem[] => {
  // Add Activities to the DropDown
  let types: NvDropdownTextItem[] = _.keys(courseActivities).map((type: ActivityKey) => ({
    type: 'text',
    text: rteTagType === RteTagTypes.DATE ? getDateActivityDropdownLabel(type, aliases) : getLabelForActivity(type, aliases),
    value: type,
    class: 'rte-tag-dropdown-item',
    callback: () => {
      callback(type);
    },
  }));
  // Add Lecture Page Type to the Types Array
  types = [
    {
      type: 'text',
      text: rteTagType === RteTagTypes.DATE ? t.FROALA.INSERT_TAGS.RELEASE_DATE(aliases.lectureAliases.LectureAlias) : aliases.lectureAliases.LectureAlias,
      value: LecturePageType.LECTURE,
      class: 'rte-tag-dropdown-item',
      callback: () => {
        callback(LecturePageType.LECTURE);
      },
      textClassName: 'ellipsis',
    },
    ...types,
  ];
  return types;
};

// For Due Date RTE Tag, Get activities with due date ( Except for Live Session )
const filterActivitiesWithTagType = (activities: CourseActivities, rteTagType: RteTagTypes): CourseActivities => {
  // If Rte Tag Type is Date
  if (rteTagType === RteTagTypes.DATE) {
    const filteredActivities = {};
    _.map(_.keys(activities), (key: ActivityKey) => {
      const activitiesThatMatched = key === ActivityKey.LIVE_SESSION
        ? activities[key] // IF Live Session- Display Start date Tag
        : _.filter((activities[key] || []), (activity) => (!!activity.dueDate)); // Filter Activities With Due Date
      if (activitiesThatMatched.length) {
        filteredActivities[key] = activitiesThatMatched;
      }
    });
    return filteredActivities;
  }
  return activities;
};

// The original component to create tags related to courses
const FroalaCreateNewTagForCourse = ({
  onClose,
  onAdd,
  tags,
  rteTagType,
}: FroalaCreateNewTagProps) => {
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const aliases = useSelector((state) => (catalogId ? getCourseAliases(state, catalogId) : getCourseAliases(state)));
  const courseActivities: CourseActivities = useSelector((state) => getCourseActivities(state));

  const [entityKey, setEntityKey] = useState(null);
  const [entity, setEntity] = useState(null);
  const timeLine: TimelineNormalized[] = useSelector((state) => getCourseTimeLine(state));

  const isDisabled = (entityType: OwnerType, entityId: number, isFeedbackPublic: boolean) => _.has(tags, createRteTagValue(entityType, entityId, rteTagType, isFeedbackPublic));

  // Filter course activities ( Avoid assignments that doesn't have dueDate For Data Type RTE tag)
  const filteredCourseActivities: CourseActivities = useMemo(() => filterActivitiesWithTagType(courseActivities, rteTagType), [courseActivities, rteTagType]);

  // Get Activities Type Drop
  const lectureEntityTypes: NvDropdownTextItem[] = useMemo(() => createCourseActivityTypeDropDown(filteredCourseActivities, rteTagType, aliases, (type) => {
    setEntityKey(type);
    setEntity(null);
  }), [filteredCourseActivities, rteTagType, aliases]);

  const timelineDropdownItems: NvDropdownOption[] = [];
  let lastTimelineItem: TimelineNormalized;
  (timeLine || []).forEach((item) => {
    if (item.type === TimelineItemTypes.PAGE) {
      // Its a Page
      timelineDropdownItems.push({
        type: 'text',
        text: item.title,
        value: item.id,
        disabled: isDisabled(LecturePageType.LECTURE, item.id, false),
        class: 'rte-tag-dropdown-item',
        callback: () => {
          setEntity(item);
        },
      });
    } else {
      // Subsection or Section
      if (lastTimelineItem?.type === TimelineItemTypes.PAGE) {
        // Add a divider if its a section or subsection after a page
        timelineDropdownItems.push({
          type: 'divider',
        });
      }
      timelineDropdownItems.push({
        type: 'header',
        title: item.title,
        class: 'page-title-xxs',
      });
    }
    lastTimelineItem = item;
  });

  const getLectureEntity = (item): NvDropdownTextItem[] => item.sessions.map((session) => ({
    type: 'text',
    text: session.title,
    value: session.id,
    disabled: isDisabled(ActivityType.LIVE_SESSION, session.id, false),
    class: 'rte-tag-dropdown-item',
    callback: () => {
      setEntity(session);
    },
  }) as NvDropdownTextItem);

  const getEntitiesFromCourseActivities = (): NvDropdownTextItem[] => {
    if (entityKey === ActivityKey.LIVE_SESSION) {
      const liveSessions: NvDropdownTextItem[] = [];
      filteredCourseActivities[entityKey].forEach((activity) => {
        liveSessions.push(...getLectureEntity(activity));
      });
      return liveSessions;
    }
    return (filteredCourseActivities[entityKey] || []).map((item) => ({
      type: 'text',
      text: item.title,
      value: item.id,
      disabled: isDisabled(getActivityTypeForKey(entityKey, item.category), item.id, item.isFeedbackPublic),
      class: 'rte-tag-dropdown-item',
      callback: () => {
        setEntity(item);
      },
    }));
  };

  const lectureEntities: NvDropdownOption[] = entityKey === LecturePageType.LECTURE
    ? timelineDropdownItems
    : getEntitiesFromCourseActivities();

  const addNewCustomTag = () => {
    const tag = convertRelatedActivityToRteTag({
      id: entity.id,
      type: entityKey === LecturePageType.LECTURE
        ? LecturePageType.LECTURE
        : getActivityTypeForKey(entityKey, entity.category),
    }, rteTagType, true, entity.isFeedbackPublic, entity.category);
    if (entityKey === ActivityKey.LIVE_SESSION) {
      tag.displayName = t.FROALA.INSERT_TAGS.START_DATE_FOR(entity.title);
      tag.category = FeedbackCategory.LIVE_EVENT_SESSION;
    }
    onAdd(tag);
    onClose();
  };

  const findEntity = () => {
    const lectureEntity = lectureEntities.find((item: NvDropdownTextItem) => item.value === entity.id);
    return (lectureEntity as NvDropdownTextItem).text;
  };

  return (
    <div className='bs4-row' css={styles}>
      <Fragment>
        <div className='bs4-col-md-3'>
          <NvDropdown
            showSelectedIndicator
            items={lectureEntityTypes}
            buttonStyle={NvDropdownButtonStyle.FORM}
            title={entityKey ? lectureEntityTypes.find(item => item.value === entityKey).text : t.FORM.DROPDOWN.SELECT()}
            titleClass='rte-tag-dropdown-title'
            insideDoubleModal
          />
        </div>
        <div className='bs4-col-md-4'>
          <NvDropdown
            showSelectedIndicator
            items={lectureEntities}
            buttonStyle={NvDropdownButtonStyle.FORM}
            title={entity ? findEntity() : t.FORM.DROPDOWN.SELECT()}
            disabled={entityKey == null}
            titleClass='rte-tag-dropdown-title'
            insideDoubleModal
          />
        </div>
      </Fragment>
      <div className='bs4-col-md-5 add-tags-button-container'>
        <Button
          type='button'
          size='sm'
          disabled={!entity || !entityKey}
          variant='primary'
          onClick={addNewCustomTag}
        >
          {t.FORM.ADD()}
        </Button>
        <Button type='button' size='sm' variant='secondary' onClick={onClose}>{t.FORM.CANCEL()}</Button>
      </div>
    </div>
  );
};

// The component to create new tags adapted to journeys requirements
const FroalaCreateNewTagForJourney = ({
  onClose,
  onAdd,
  tags,
  rteTagType,
}: FroalaCreateNewTagProps) => {
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const [entityKey, setEntityKey] = useState(null);
  const [entity, setEntity] = useState(null);

  const journeyCollections = useSelector((state) => getJourneyCollections(state, catalogId));
  const courses = useSelector(getCoursesArray);

  // Create a list of courses type dropdown, divided by collections
  const createCourseCollectionsTypeDropDown = (
    callback: (catalogId: string, course: any) => void,
  ): NvDropdownOption[] => {
    const collections: NvDropdownOption[] = [];
    _.values(journeyCollections).forEach((collection, index) => {
      const collectionName = collection?.name?.length ? collection.name : t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COLLECTION.COLLECTION_GENERIC_NAME();
      collections.push(
        {
          type: 'header',
          title: collectionName,
          class: 'rte-tag-dropdown-item',
        },
      );
      collection.courseIds.forEach(courseId => {
        const relatedCourse = courses.find((course) => course.id === courseId);
        collections.push(
          {
            type: 'text',
            text: relatedCourse.name,
            tooltip: {
              text: relatedCourse.name,
            },
            value: relatedCourse.catalogId,
            class: 'rte-tag-dropdown-item',
            callback: () => {
              callback(relatedCourse.catalogId, relatedCourse);
            },
            textClassName: 'ellipsis',
          },
        );
      });
    });
    return collections;
  };

  // Get courses grouped by collections
  const courseCollections: NvDropdownOption[] = useMemo(() => createCourseCollectionsTypeDropDown((type, course) => {
    setEntityKey(type);
    if (rteTagType === RteTagTypes.DATE) {
      setEntity(null);
    } else {
      setEntity(course);
    }
  }), [journeyCollections, rteTagType, courses]);

  const dates = useMemo(() => getCourseDateTypes(), []);
  const courseDates: NvDropdownTextItem[] = dates.map(date => {
    const relatedCourse = courses.find((course) => course.catalogId === entityKey);
    return {
      type: 'text',
      text: date.title,
      value: date.value,
      disabled: entityKey && _.has(tags, createCourseRteTagValue(relatedCourse.id, rteTagType, date.value)),
      class: 'rte-tag-dropdown-item',
      callback: () => {
        setEntity({
          ...relatedCourse,
          dateType: date.value,
        });
      },
    };
  });

  const addNewCustomTag = () => {
    const tag = convertRelatedCourseToRteTag({
      id: entity.id,
      tagName: rteTagType === RteTagTypes.URL ? entity.name : dates.find(date => date.value === entity.dateType).tagName(entity.name),
      dateType: entity.dateType,
    }, rteTagType, true);
    onAdd(tag);
    onClose();
  };

  const getSelectors = () => {
    const courseSelected = courseCollections.find(item => ('value' in item && item.value === entityKey));
    return (
      <Fragment>
        <div className={`${rteTagType === RteTagTypes.DATE ? 'bs4-col-md-5' : 'bs4-col-md-7'} course-collections`}>
          <NvDropdown
            showSelectedIndicator
            items={courseCollections}
            buttonStyle={NvDropdownButtonStyle.FORM}
            title={entityKey ? ('text' in courseSelected && courseSelected.text) : t.FORM.DROPDOWN.SELECT()}
            titleClass='rte-tag-dropdown-title'
            insideDoubleModal
          />
        </div>
        {
          rteTagType === RteTagTypes.DATE
          && (
            <div className='bs4-col-md-4'>
              <NvDropdown
                showSelectedIndicator
                items={courseDates}
                buttonStyle={NvDropdownButtonStyle.FORM}
                title={entity ? courseDates.find(item => item.value === entity.dateType).text : t.FORM.DROPDOWN.SELECT()}
                disabled={entityKey == null}
                titleClass='rte-tag-dropdown-title'
                insideDoubleModal
              />
            </div>
          )
        }
      </Fragment>
    );
  };

  return (
    <div className='bs4-row' css={styles}>
      {
        getSelectors()
      }
      <div className='bs4-col-md-3 add-tags-button-container'>
        <Button
          type='button'
          size='sm'
          disabled={!entity || !entityKey}
          variant='primary'
          onClick={addNewCustomTag}
        >
          {t.FORM.ADD()}
        </Button>
        <Button type='button' size='sm' variant='secondary' onClick={onClose}>{t.FORM.CANCEL()}</Button>
      </div>
    </div>
  );
};

// Depending on the value of 'isJourney' it returns the corresponding component for courses or journeys.
export const FroalaCreateNewTagModal = ({
  onClose,
  onAdd,
  tags,
  rteTagType,
  isJourney,
}: FroalaCreateNewTagProps & { isJourney }) => (
  <React.Fragment>
    {
      isJourney
        ? (
          <FroalaCreateNewTagForJourney
            onClose={onClose}
            onAdd={onAdd}
            tags={tags}
            rteTagType={rteTagType}
          />
        )
        : (
          <FroalaCreateNewTagForCourse
            onClose={onClose}
            onAdd={onAdd}
            tags={tags}
            rteTagType={rteTagType}
          />
        )
    }
  </React.Fragment>
);

export default FroalaCreateNewTagModal;
