/* eslint-disable react/require-default-props */
import { css, SerializedStyles } from '@emotion/react';
import React from 'react';

const containerStyle = css`
  position: relative;
  margin: 0;
  display: block;
`;

const radioStyle = css`
  /* override the default "display: none" in the radio button (not sure why).
  We'll make it visually invisible with opacity instead */
  &[type='radio'] {
    display: inline-block;
  }
  position: absolute;
  opacity: 0;
`;

interface Radio {
  // required for accessibility
  readonly label?: string;
  // value should be unique for each option
  readonly value: string;

  readonly disabled?: boolean;
}

interface IconRadioButtonProps<TValues extends readonly Radio[]> {
  // list of possible values
  readonly radios: TValues;

  // render prop for the custom radio UI
  renderRadioButton(args: TValues[number] & { checked: boolean } & { index?: number }): React.ReactNode;

  // value of the current selected option
  activeValue: TValues[number]['value'];

  onChange(value: TValues[number]['value']): void;
  name: string;
  optionContainerStyle?: SerializedStyles;
  className?: string;
}

/**
 * Group of radio buttons with custom UI. It receives and array of options and a
 * `renderButton` render prop to render the custom radio button
 */
/* @ngInject */
export default function CustomRadioGroup<TValues extends Radio[]>(props: IconRadioButtonProps<TValues>) {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    props.onChange(event.target.value as TValues[number]['value']);
  }

  return (
    <div className={props.className} role='radiogroup'>
      {props.radios.map((value, index) => (
        <label
          key={value.value}
          css={css`
            ${containerStyle};
            ${props.optionContainerStyle};
            cursor: ${value.disabled ? 'auto' : 'pointer'};
          `}
        >
          {/* label text will be visible only for screen readers */}
          {value.label && <span className='sr-only'>{value.label}</span>}
          <input
            type='radio'
            checked={props.activeValue === undefined ? undefined : props.activeValue === value.value}
            name={props.name}
            css={radioStyle}
            onChange={handleChange}
            value={value.value}
            disabled={value.disabled}
            tabIndex={-1}
          />
          {props.renderRadioButton({
            checked: props.activeValue === value.value,
            index,
            ...value,
          })}
        </label>
      ))}
    </div>
  );
}
