import { css } from '@emotion/react';
import React, { useEffect, useRef, useState } from 'react';
import t from 'react-translate';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Schemas
import { Post } from 'redux/schemas/api/video-practice';
import { BaseUser } from 'redux/schemas/models/my-account';

// Styles
import { gray7, warning } from 'styles/global_defaults/colors';
import { lightFontWeight } from 'styles/global_defaults/fonts';
import { doubleSpacing, halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';

// Components
import Button from 'react-bootstrap/Button';
import { Timestamp } from 'shared/components/nv-comment-row';
import NvPopover from 'shared/components/nv-popover';

import { createFroalaMentionableString } from 'froala/helpers/tribute-options';
import NvFroala from 'froala/components/nv-froala';
import { FroalaViewMode } from 'froala/helpers/nv-froala-constants';
import { handheld, isHandheld, notHandheld } from 'styles/global_defaults/media-queries';

/**
 * This comment form is used in Video Practice. You can reuse this component
 * if your design matches with the Practice Room comments section.
 */

type CommentFormProps = {
  timestamp?: number
  onSubmit: (any) => Promise<boolean>
  onCancel: () => void
  timestampInfo?: string
  content?: string
  isEdit?: boolean
  isReply?: boolean
  mentionedUser?: BaseUser
  mentionableUsers?: BaseUser[]
  loadMentionableUsers: () => void
};

const styles = css`
  .highlighted {
    border-left: solid 3px;
    background-color: ${gray7};
    border-color: ${warning};
  }

  .comment-input {
    /* override froala default styles */
    .nv-froala-editor.air .fr-element:focus {
      outline: none;
    }
    .nv-froala-editor.air.fr-box.fr-basic:not(.no-background) {
      .fr-wrapper {
        background-color: transparent;
        border: none;
      }
      .fr-placeholder  {
        font-weight: ${lightFontWeight};
        border: none;
      }
    }
  }

  .reply {
    margin-left: ${2 * doubleSpacing}px;
  }
  .action-row {
    ${handheld(css`
      margin: 0 ${standardSpacing}px 0 ${standardSpacing}px;
      flex-direction: column;
    `)}
    ${notHandheld(css`
      margin-top: ${standardSpacing}px;
      margin-right: ${halfSpacing}px;
    `)}
  }
`;

const NvCommentForm = ({
  timestamp,
  onSubmit,
  onCancel,
  timestampInfo,
  content,
  isEdit,
  isReply,
  mentionedUser,
  mentionableUsers,
  loadMentionableUsers,
}: CommentFormProps) => {
  const getDefaultValue = () => {
    if (content) {
      return content;
    }
    if (mentionedUser) {
      return createFroalaMentionableString(mentionedUser);
    }
    return '';
  };
  const inputRef = useRef<HTMLTextAreaElement>();

  const validationSchema = yup.object().shape({
    body: yup.string().required(),
  });

  const methods = useForm<{ body: string }>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      body: getDefaultValue(),
    },
  });
  const { handleSubmit, formState, getValues } = methods;
  const { isValid } = formState;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitComment = async () => {
    setIsSubmitting(true);
    const comment: Post = {
      body: getValues().body,
      videoTimestamp: timestamp,
    };
    await onSubmit(comment);
    setIsSubmitting(false);
  };

  useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus();
    }, 0);
  }, []);

  useEffect(() => {
    loadMentionableUsers();
  }, [loadMentionableUsers]);

  const getSubmitButtonText = () => {
    if (isEdit) {
      return isSubmitting ? t.DISCUSSIONS.UPDATING() : t.DISCUSSIONS.UPDATE();
    }
    if (isReply) {
      return isSubmitting ? t.DISCUSSIONS.REPLYING() : t.DISCUSSIONS.REPLY();
    }
    return isSubmitting ? t.DISCUSSIONS.COMMENTING() : t.DISCUSSIONS.COMMENT();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submitComment)}>
        <div css={styles}>
          <div className={`d-flex highlighted my-1 p-2 ${isReply ? 'reply' : ''}`}>
            <div className='py-1 w-100'>
              <div className='d-flex justify-content-between'>
                <div className='pr-4 w-100'>
                  <NvFroala
                    withForm
                    ref={inputRef}
                    name='body'
                    placeholder={t.DISCUSSIONS.PLACEHOLDER()}
                    className='comment-input text-body'
                    preset={FroalaViewMode.AIR}
                    minHeight={100}
                    mentionableUsers={mentionableUsers}
                  />
                </div>
                {/* No need to display timestamp while replying */}
                {timestamp && !isReply && (
                  timestampInfo ? (
                    <NvPopover
                      content={(
                        <span className='text-small'>
                          {timestampInfo}
                        </span>
                      )}
                      showOnHover
                      placement='top'
                    >
                      <Timestamp value={timestamp} />
                    </NvPopover>
                  ) : <Timestamp value={timestamp} />
                )}
              </div>
              <div className='action-row d-flex justify-content-end'>
                <Button
                  className={isHandheld() ? 'mb-2' : 'mr-2'}
                  variant='secondary'
                  onClick={onCancel}
                >
                  {t.FORM.CANCEL()}
                </Button>
                <Button
                  type='submit'
                  disabled={!isValid || isSubmitting}
                >
                  {getSubmitButtonText()}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default NvCommentForm;
