/* eslint-disable react/require-default-props */
import { css, SerializedStyles } from '@emotion/react';
import {
  AccordionSectionType,
  isStyle1AccordionLectureComponent,
  isStyle2AccordionLectureComponent,
  isStyle3AccordionLectureComponent,
  isStyle4AccordionLectureComponent,
  isStyle5AccordionLectureComponent,
  NLectureComponent,
} from 'redux/schemas/models/lecture-component';
import { pick } from 'underscore';
import humps from 'humps';
import { black, gray1, gray3, gray4, gray5, gray6 } from 'styles/global_defaults/colors';
import NvTooltip from 'shared/components/nv-tooltip';
import t from 'react-translate';

const containerStyle = css`
  padding: 5px;
  border: 1px dashed transparent;
  &:hover {
    border-color: #acb5c2;
  }
`;

const sharedAccordionComponent = css`
  height: 45px;
  display: flex;
  align-items: center;
`;

function Title() {
  return (
    <span className='froala-style-title-xs condensed'>
      <strong>{t.LECTURE_PAGES.COMPONENTS.ACCORDION.TITLE_PLACEHOLDER()}</strong>
    </span>
  );
}

function Style1LeftHandSymbol() {
  return (
    <i
      css={css`
        color: ${gray1};
        font-size: 12px;
        margin: 0 10px;
      `}
      className='icon-create-new-post'
    />
  );
}

function Style2LeftHandSymbol() {
  return (
    <div
      css={css`
        width: 16px;
        height: 16px;
        background-color: ${gray3};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin: 0 10px;
      `}
    >
      <i
        css={css`
          color: #fff;
          font-size: 8px;
        `}
        className='icon-create-new-post'
      />
    </div>
  );
}

function Style3LeftHandSymbol() {
  return (
    <div
      css={css`
        width: 45px;
        height: 45px;
        background-color: ${gray3};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        font-size: 18px;
        font-weight: 600;
      `}
      className='condensed'
    >
      1
    </div>
  );
}

function Style4LeftHandSymbol() {
  return (
    <div
      css={css`
        width: 31px;
        height: 31px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        margin-left: 10px;
        font-size: 18px;
        font-weight: 600;
        border-radius: 50%;
      `}
      className='condensed'
    >
      A
    </div>
  );
}

function Style5LeftHandSymbol() {
  return (
    <div
      css={css`
        width: 31px;
        margin-right: 10px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <i
        css={css`
          color: #fff;
          font-size: 20px;
        `}
        className='icon icon-images'
      />
    </div>
  );
}

interface ArrowDownIconProps {
  dark?: boolean;
}
function ArrowDownIcon(props: ArrowDownIconProps) {
  return (
    <div
      css={css`
        margin-left: auto;
        margin-right: 10px;
      `}
    >
      <i
        css={css`
          color: ${props.dark ? gray1 : '#fff'};
          font-size: 12px;
        `}
        className='icon-arrow-down'
      />
    </div>
  );
}

/** This renders a Lecture Page Accordion Component in a smaller, view-only format that is used in the
 * LHS of the Lecture Page when adding a component. */
const LhsAccordionComponent = (props: { model: NLectureComponent<AccordionSectionType> }) => {
  const modelInstance = humps.camelizeKeys(pick(props.model, 'view_options', 'accordion_sections')) as any;
  let currentAccordionStyle: SerializedStyles = null;
  let leftIcon = null;
  let rightIcon = null;
  let tooltipText = '';

  if (isStyle1AccordionLectureComponent(modelInstance)) {
    currentAccordionStyle = css`
      border-width: 1px 0 1px 0;
      border-color: ${gray3};
      border-style: solid;
      color: ${black};
    `;
    leftIcon = <Style1LeftHandSymbol />;
    tooltipText = t.LECTURE_PAGES.COMPONENTS.ACCORDION.TOOLTIP.INSERT_ACCORDION_STYLE_1();
  } else if (isStyle2AccordionLectureComponent(modelInstance)) {
    currentAccordionStyle = css`
      background-color: ${gray6};
      color: ${black};
    `;
    leftIcon = <Style2LeftHandSymbol />;
    tooltipText = t.LECTURE_PAGES.COMPONENTS.ACCORDION.TOOLTIP.INSERT_ACCORDION_STYLE_2();
  } else if (isStyle3AccordionLectureComponent(modelInstance)) {
    leftIcon = <Style3LeftHandSymbol />;
    rightIcon = <ArrowDownIcon />;
    currentAccordionStyle = css`
      background-color: ${gray4};
      color: #fff;
    `;
    tooltipText = t.LECTURE_PAGES.COMPONENTS.ACCORDION.TOOLTIP.INSERT_ACCORDION_STYLE_3();
  } else if (isStyle4AccordionLectureComponent(modelInstance)) {
    leftIcon = <Style4LeftHandSymbol />;
    rightIcon = <ArrowDownIcon dark />;
    currentAccordionStyle = css`
      background-color: ${gray5};
      color: ${black};
    `;
    tooltipText = t.LECTURE_PAGES.COMPONENTS.ACCORDION.TOOLTIP.INSERT_ACCORDION_STYLE_4();
  } else if (isStyle5AccordionLectureComponent(modelInstance)) {
    leftIcon = <Style5LeftHandSymbol />;
    rightIcon = <ArrowDownIcon />;
    currentAccordionStyle = css`
      background-color: ${gray3};
      color: #fff;
    `;
    tooltipText = t.LECTURE_PAGES.COMPONENTS.ACCORDION.TOOLTIP.INSERT_ACCORDION_STYLE_5();
  }

  return (
    <div css={containerStyle} className='accordion-component'>
      <div
        css={css`
          ${sharedAccordionComponent};
          ${currentAccordionStyle};
        `}
      >
        {leftIcon}
        <Title />
        {rightIcon}
      </div>
    </div>
  );
};

export default LhsAccordionComponent;
