import { MockDefinition, MockTypes } from '../axios/axios-mocks';

const SkillTagsMocks: MockDefinition[] = [
  {
    type: MockTypes.GET,
    url: '/institutions/1/skill_tags.json',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      tags: [{ id: 1, name: 'mock tag', activitiesTagged: 6, coursesTagged: 7 }, { id: 2, name: 'mock tagtest tag', activitiesTagged: 1, coursesTagged: 1 }],
    },
  },
  {
    type: MockTypes.POST,
    url: '/institutions/1/skill_tags.json',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: { id: Math.floor(Math.random() * 100), name: `new tag${Math.floor(Math.random() * 100)}`, activitiesTagged: 0, coursesTagged: 0 },
    },
  },
  {
    type: MockTypes.DELETE,
    url: '/institutions/1/skill_tags/1.json',
    status: 200,
    response: {
      message: 'ok',
    },
  },

];
export default SkillTagsMocks;
