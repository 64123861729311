import React from 'react';
import { AngularContext } from 'react-app';
import { QrveyState } from 'redux/schemas/models/course';
import { useAppDispatch } from 'redux/store';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { addAlertMessage } from 'redux/actions/alert-messages';
import t from 'react-translate';
import useScript from 'shared/hooks/use-script';
import useLoadToken from 'shared/hooks/use-load-token';
import { useSelector } from 'react-redux';
import { AnalyticsDashBoardType } from 'redux/schemas/app/analytics';
import { AnalyticsTokenProps } from './analytics-dashboard';
import AnalyticsLoader from './analytics-loader';
import { baseStyles, cohortStyles, courseStyles, institutionStyles, journeyStyles, skillsStyles } from './custom-styles';
import { config } from '../../../config/config.json';

export type QrveyProps = {
  data?: QrveyState;
  type: AnalyticsDashBoardType;
  configKeyName?: string;
  pageIdKey?: string;
};

type QrveyConfig = {
  qv_token: string;
  domain: string;
  app_id: string;
  user_id: string;
  page_id? : string;
  customCSSRules: string;
  custom_styles: boolean;
  automaticDownload: any;
  pageIds? : any;
  downloadingBoxText?: string;
  userFilters?: any;
};

// Provided by qrvey, this is how we reset the variables so we can switch dashboards and
// provide different CSS every time.
const resetQrveyValues = () => {
  if (typeof window.customEUStyle !== 'undefined' && window.customEUStyle !== '') {
    window.customEUStyle = undefined;
  }
};

const QrveyComponent = (props: QrveyProps) => {
  const { type, pageIdKey } = props;
  let { configKeyName = 'config' } = props;
  const dispatch = useAppDispatch();
  const institutionId = useSelector((state) => state.app.currentInstitutionId);
  const { injectServices } = React.useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const { catalogId } = $state.params || null;
  if (!config.qrvey?.launcherUrl) {
    dispatch(
      addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.OOPS(),
        message: t.QRVEY.CONFIG_ERROR(),
      }),
    );
  }
  const launcherStatus = useScript(config.qrvey.launcherUrl);
  const params: AnalyticsTokenProps = { type, catalogId, institutionId, pageIdKey };
  const data = useLoadToken(params);
  const isReady: boolean = data && launcherStatus === 'ready';
  const initializeQrvey = (qrveydata: QrveyState) => {
    const { token, appId, userId, host } = qrveydata[type];
    const overwritestyles = `${baseStyles}
    ${type === AnalyticsDashBoardType.LEARNING_JOURNEY ? journeyStyles : ''}
    ${type === AnalyticsDashBoardType.INSTITUTION ? institutionStyles : ''}
    ${(type === AnalyticsDashBoardType.COURSE && pageIdKey === 'skills') ? skillsStyles : ''}
    ${(type === AnalyticsDashBoardType.COURSE && pageIdKey !== 'skills') ? courseStyles : ''}
    ${(type === AnalyticsDashBoardType.COHORTS) ? cohortStyles : ''}
    `;

    const configQrvey: QrveyConfig = {
      qv_token: token,
      domain: host,
      app_id: appId,
      user_id: userId,
      customCSSRules: overwritestyles,
      custom_styles: true,
      downloadingBoxText: t.QRVEY.DOWNLOAD_BOX(),
      automaticDownload: false
    };

    if (type === AnalyticsDashBoardType.COHORTS) {
      configQrvey.page_id = qrveydata.cohort.pageId;
    }
    if (type === AnalyticsDashBoardType.COURSE) {
      configQrvey.page_id = qrveydata.course.pageIds[pageIdKey];
    }
    if (type === AnalyticsDashBoardType.LICENSE) {
      configQrvey.page_id = qrveydata[type].pageId;
      configQrvey.userFilters = qrveydata[type].userFilters;
      // for license analytics, we need a dynamic config name based on the page id.
      configKeyName = `${configKeyName}_${qrveydata[type].pageId}`;
    }
    window[configKeyName] = configQrvey;
    resetQrveyValues();
  };
  if (data && isReady) {
    initializeQrvey(data);
  }

  return (
    <>
      {!isReady && <AnalyticsLoader />}
      {isReady && <qrvey-end-user settings={configKeyName} />}
    </>
  );
};

export default QrveyComponent;
