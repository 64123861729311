import { createReducer } from '@reduxjs/toolkit';
import { fetchAIGeneratedPrompts, fetchSinglePost } from 'redux/actions/posts';
import { initialRootState } from '.';

export default createReducer(initialRootState, builder => {
  builder
    .addCase(fetchSinglePost.fulfilled, (state, action) => {
      const { postId } = action.meta.arg;
      const post = action.payload;
      Object.assign(state.models.posts[postId], post);
    })
    .addCase(fetchAIGeneratedPrompts.rejected, (state, action) => {
      state.app.generatedAIContent.discussionPrompts.loading = false;
    })
    .addCase(fetchAIGeneratedPrompts.pending, (state, action) => {
      state.app.generatedAIContent.discussionPrompts.loading = true;
    })
    .addCase(fetchAIGeneratedPrompts.fulfilled, (state, action) => {
      state.app.generatedAIContent.discussionPrompts.loading = false;
      state.app.generatedAIContent.discussionPrompts.errorCode = action.payload.errorCode;
      state.app.generatedAIContent.discussionPrompts.prompts = action.payload.prompts;
    });
});
