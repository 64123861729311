/* @ngInject */
export default function SubmissionApprovalBaseCtrl(
  _,

  ReportsManager,
) {
  _.extend(this, {
    ReportsManager,
  });
}
