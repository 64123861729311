import React from 'react';
import { NvUploadedFileProps } from '.';
import NvBoxPreview from './nv-box-preview';

const EMBEDDING_STYLES = {
  '4:3': 'embed-responsive-4by3',
  '1:1': 'embed-responsive-1by1',
  '21:9': 'embed-responsive-21by9',
  '16:9': 'embed-responsive-16by9',
  a4: 'embed-responsive-a4',
  letter: 'embed-responsive-letter',
};

const NvBoxResponsivePreview = ({ file }: Pick<NvUploadedFileProps, 'file'>) => {
  const getResponsiveEmbeddingClass = EMBEDDING_STYLES[file.embeddingStyle] || EMBEDDING_STYLES['16:9'];

  return (
    <div className={`embed-responsive ${getResponsiveEmbeddingClass}`}>
      <div className='embed-responsive-item'>
        <NvBoxPreview boxDocumentId={file.boxDocumentId} />
      </div>
    </div>
  );
};

export default NvBoxResponsivePreview;
