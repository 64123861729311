/* @ngInject */
export default function AccountSettingsNavController(InstitutionsManager, StateManager) {
  const vm = {
    tabs: [
      { name: 'ACCOUNT_SETTINGS.ACCOUNT_BASICS_TAB', state: 'account-basics', id: 'basics-tab', panel: 'basics-panel' },
      { name: 'ACCOUNT_SETTINGS.EMAIL_PREFERENCES_TAB', state: 'email-preferences', id: 'email-preferences-tab', panel: 'email-preferences-panel' },
      { name: 'ACCOUNT_SETTINGS.LANGUAGE_AND_TIMEZONE_TAB', state: 'language-and-timezone', id:'langs-and-tz-tab', panel: 'langs-and-tz-panel' },
    ],
  };

  vm.institution = InstitutionsManager.institution;
  StateManager.accountSettingsTabs = vm.tabs;

  return vm;
}
