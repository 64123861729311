export default ['$timeout',
  function ($timeout) {
    return {
      restrict: 'A',
      controller: function ctrl($scope) {
'ngInject';
        let scrollToElem = null;

        this.registerScrollHandler = function (elem) {
          scrollToElem = elem;
        };

        this.deregisterScrollHandler = function () {
          scrollToElem = null;
        };

        this.scrollTo = function () {
          if (scrollToElem) {
            $timeout(() => {
              const scrollContainer = $('#main-panel');
              scrollContainer.scrollToElementAnimated(scrollToElem, 400);
            }, 1);
          }
        };
      },
    };
  }];
