/* @ngInject */
export default function TodoBaseModelService(

  _,
) {
  const EXERCISE = 'Exercise';
  const QUIZ_DELIVERABLE_TYPE = 'QuestionSetSubmission';
  const SURVEY = 'Survey';
  const TEAM_SET = 'TeamSet';
  const GROUP_TEAM_SET = 'GroupTeamSet';
  const LECTURE_PAGE = 'LecturePage';
  const POLL = 'Poll';

  const IN_PROGRESS = 'in_progress';

  // - Submission Approval Specific
  const PENDING_APPROVAL = 'pending';
  const REJECTED = 'rejected';
  const REJECTED_AND_MISSED = 'rejected_and_missed';

  class TodoBaseModel {
    constructor(attributes) {
      _.extend(this, attributes);
    }

    isReportDeliverable() {
      return this.activityType === EXERCISE;
    }

    isQuizDeliverable() {
      return this.deliverableType === QUIZ_DELIVERABLE_TYPE;
    }

    isPeerEvaluation() {
      return this.peerReview;
    }

    isPollDeliverable() {
      return this.type === POLL;
    }

    isTimedQuizDeliverable() {
      return false;
    }

    isSurveyDeliverable() {
      return this.type === SURVEY;
    }

    isTeamDeliverable() {
      return this.activityType === TEAM_SET || this.activityType === GROUP_TEAM_SET;
    }

    isLecture() {
      return this.type === LECTURE_PAGE;
    }

    isMeeting() {
      return this.meetingTime !== undefined;
    }

    addMeetingAttendance(rsvpData) {
      if (this.attendance && !_.findWhere(this.attendance, { teamMemberId: rsvpData.teamMemberId })) {
        this.attendance.push({ id: rsvpData.id, rsvp: rsvpData.rsvp, teamMemberId: rsvpData.teamMemberId });
      }
    }

    isInProgress() {
      return this.activityStarted || this.started;
    }

    // exercise specific
    isPendingApproval() {
      return this.progress === PENDING_APPROVAL;
    }

    isRejectedCanRevise() {
      return this.progress === REJECTED;
    }

    approvalStatusKey() {
      if (this.isPendingApproval()) {
        return 'SUBMISSION_APPROVAL.SUBMISSION_STATUS.PENDING';
      } if (this.isRejectedCanRevise()) {
        return 'SUBMISSION_APPROVAL.SUBMISSION_STATUS.REJECTED';
      }

      return null;
    }
  }

  return TodoBaseModel;
}
