import { extend } from 'underscore';
import { createReducer } from '@reduxjs/toolkit';

import { patchProfileRequirement } from 'redux/actions/profile-completion';
import { initialRootState } from './index';

export default createReducer(initialRootState, builder => {
  builder
    .addCase(patchProfileRequirement, (state, action) => {
      const { profileRequirementId, profileRequirementPatch } = action.payload;

      extend(state.models.profileRequirements[profileRequirementId], profileRequirementPatch);
    });
});
