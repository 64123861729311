/* @ngInject */
export default function DownloadDetailsController(
  $stateParams,
  $uibModalInstance,
  CurrentCourseManager,
  CustomDownloadsModel,
  CustomDownloadsManager,
  $uibModal,
  downloadInfo,
  moment,
  nvUtil,
  $translate,
  _,
) {
  const vm = this;

  vm.downloadInfo = downloadInfo;
  vm.dropdownOrgProfileDetails = {};
  vm.$stateParams = $stateParams;
  vm.moment = moment;
  vm.nvUtil = nvUtil;
  vm.CurrentCourseManager = CurrentCourseManager;

  getExistingReport();

  function processProfileQuestions(data) {
    _.each(data, (value, key) => {
      const truthyOptions = _.reduce(value[1], (str, questionValue) => {
        if (questionValue[1] === true) {
          return str ? (`${str}, ${questionValue[0]}`) : questionValue[0];
        }
        return str;
      }, '');

      if (!truthyOptions || !truthyOptions.length) {
        delete data[key];
      } else {
        data[key][1] = truthyOptions;
      }
    });
  }

  function getExistingReport() {
    return CustomDownloadsModel.getExistingReport(vm.$stateParams.catalogId, vm.downloadInfo.reportId).then((data) => {
      vm.data = data;

      const { orgProfileDetails } = vm.data.profileDetails;

      // Convert profile questions to display string format
      processProfileQuestions(vm.data.profileQuestions);

      vm.dropdownOrgProfileDetails = Object.entries(orgProfileDetails).filter(
        ([key, value]) => typeof value !== 'boolean',
      ).reduce((acc, [key, value]) => {
        acc[key] = value;

        return acc;
      }, {});

      processProfileQuestions(vm.dropdownOrgProfileDetails);
    });
  }


  vm.isEmpty = function (obj) {
    vm.selectedCount = 0;

    const selected = _.each(obj, (value, key) => {
      if (obj[key][1]) {
        vm.selectedCount += 1;
      }
    });

    return !vm.selectedCount;
  };

  vm.filterData = function (obj, isRoles) {
    let keys; let camelCaseKey; let
      result;

    if (obj) {
      keys = Object.keys(obj);

      if (isRoles) {
        const roles = _.map(keys, (key) => obj[key]);
        return _.map(_.filter(roles, (role) => role[2]), (role) => role[0]).join(', ');
      }
      _.each(keys, (key) => {
        if (obj[key] === true) {
          camelCaseKey = $translate.instant(vm.nvUtil.convertToTranslationText(key), {
            learnerAlias: vm.CurrentCourseManager.course.learnersName.singularizedTitleized,
            teachingTeamAlias: vm.CurrentCourseManager.course.teachingTeamName.singularizedTitleized,
            TeachingTeamTitleAlias: vm.CurrentCourseManager.course.teachingTeamName.singularizedTitleized,
            groupAlias: vm.CurrentCourseManager.course.groupName.singularizedTitleized,
            GroupsAlias: vm.CurrentCourseManager.course.groupName.capitalizedPluralized,
            PointsAlias: vm.CurrentCourseManager.course.pointsName.capitalizedPluralized,
            TeamAlias: vm.CurrentCourseManager.course.teamName.capitalizedSingularized,
            CourseAlias: vm.CurrentCourseManager.course.offeringName.capitalizedSingularized,
          });
          result = result ? (`${result}, ${camelCaseKey}`) : camelCaseKey;
        }

        if (key === 'orgProfileDetails') {
          const orgProfileDetails = obj[key];
          let { profileSettings } = CurrentCourseManager.course.institution;

          profileSettings = profileSettings.orgLevel;

          _.each(orgProfileDetails, (orgProfileDetailValue, orgProfileDetailKey) => {
            const orgProfileField = profileSettings.find(({ id }) => id === Number(orgProfileDetailKey));

            if (typeof orgProfileDetailValue === 'boolean') {
              if (orgProfileDetailValue) {
                result = result ? (`${result}, ${orgProfileField.name}`) : orgProfileField.name;
              }
            } else if (orgProfileDetailValue[2] === true) {
              result = result ? (`${result}, ${orgProfileDetailValue[0]}`) : orgProfileDetailValue[0];
            }
          });
        }
      });


      return result;
    }

    return null;
  };

  vm.editReport = function () {
    $uibModalInstance.close();

    const modal = $uibModal.open({
      templateUrl: 'custom_downloads/templates/create-reports-modal.html',
      controller: 'CreateReportsCtrl as vm',
      windowClass: 'full-screen-modal-handheld full-screen-modal-tablet create-reports-modal custom-downloads',
      resolve: {
        reportId: vm.downloadInfo.reportId,
        reportPreviousStatus: vm.downloadInfo.reportPrevStatus,
      },
    }).result.then((action) => {
      CustomDownloadsManager.getUpdatedCustomReport(vm.$stateParams.catalogId, action.type === 'new' ? null : vm.downloadInfo.reportId, 'dataDownload');
    });
  };

  // Downloads List modal
  vm.viewDownloadList = function ($event) {
    $uibModalInstance.close();

    const modal = $uibModal.open({
      templateUrl: 'custom_downloads/templates/data-downloads-list-modal.html',
      controller: 'DataDownloadsListCtrl as vm',
      windowClass: 'full-screen-modal-handheld full-screen-modal-tablet downloads-list-modal custom-downloads',
      resolve: {
        downloadInfo: vm.downloadInfo,
      },
    });
  };
}
