import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { AngularServicesContext } from 'react-app';
import { Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import t from 'react-translate';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { saveOrgLevelCredlySettings } from 'redux/actions/credly';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { CredlySettingsPayload } from 'redux/schemas/models/credly';
import { Institution } from 'redux/schemas/models/institution';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { PopoversContainerContext } from 'shared/react-utils';
import { gray3 } from 'styles/global_defaults/colors';
import {
  openSans,
  semiBoldFontWeight,
  textSmallFontSize,
} from 'styles/global_defaults/fonts';
import { screenXsMax } from 'styles/global_defaults/media-queries';
import {
  doubleSpacing,
  halfSpacing,
  quarterSpacing,
  standardSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import * as yup from 'yup';
import { config } from '../../../config/pendo.config.json';

type FormProps = {
  handleModalClose: () => void;
};

const Form = React.forwardRef<any, FormProps>((props, ref) => {
  const containerRef = React.useRef<HTMLDivElement>();
  const dispatch = useAppDispatch();
  const institution: Institution = useSelector(state => getCurrentInstitution(state));
  const { InstitutionsManager } = React.useContext(AngularServicesContext);
  const isMobile = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const hasCredlyIntegration = institution?.isCredlyEnabled ?? false;
  const organizationId = institution?.credlyConfig?.organizationId ?? '';
  const organizationToken = institution?.credlyConfig?.organizationToken ?? '';

  const validationSchema = yup.object().shape({
    organizationId: yup.string().required(t.VALIDATION.REQUIRED()),
    organizationToken: yup.string().required(t.VALIDATION.REQUIRED()),
  });

  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      organizationId: organizationId ?? '',
      organizationToken: organizationToken ?? '',
    },
  });

  React.useImperativeHandle(ref, () => formMethods);
  const {
    formState: { isValid, isDirty },
    handleSubmit,
    reset,
  } = formMethods;

  const handleEnableClick = data => {
    const {
      organizationId: inputOrganizationId,
      organizationToken: inputOrganizationToken,
    } = data;

    wrapThunkAction<CredlySettingsPayload>(
      dispatch(
        saveOrgLevelCredlySettings({
          institutionId: institution.id,
          form: {
            organizationId: inputOrganizationId,
            organizationToken: inputOrganizationToken,
          },
        }),
      ),
    )
      .then(response => {
        dispatch(
          addAlertMessage({
            type: AlertMessageType.SUCCESS,
            header: t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.SUCCESS_TITLE(),
            message: !hasCredlyIntegration ? t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.SUCCESS_MESSAGE() : t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.UPDATE_MESSAGE(),
          }),
        );

        InstitutionsManager.institution.updateFromReact({
          ...response.payload.institutionList,
        });

        props.handleModalClose();
      })
      .catch(() => {
        reset({
          organizationId: inputOrganizationId,
          organizationToken: inputOrganizationToken,
        });

        dispatch(
          addAlertMessage({
            type: AlertMessageType.ERROR,
            header: t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.ERROR_TITLE(),
            message: t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.ERROR_MESSAGE(),
          }),
        );
      });
  };

  const styles = css`
    ${!isMobile
      && css`
        padding: 0 ${doubleSpacing}px;
      `}

    .message {
      margin-bottom: ${standardSpacing + quarterSpacing}px;
    }

    form label {
      font-family: ${openSans};
      font-weight: ${semiBoldFontWeight};
      font-size: ${textSmallFontSize}px;
      color: ${gray3};
    }

    .button-bar {
      margin-top: ${tripleSpacing}px;
      display: flex;
      justify-content: center;
      gap: ${halfSpacing}px;
    }
  `;

  return (
    <PopoversContainerContext.Provider value={containerRef.current}>
      <FormProvider {...formMethods}>
        <div css={styles} ref={containerRef}>
          <p className='message'>
            {t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.MESSAGE()}
          </p>

          <form onSubmit={handleSubmit(handleEnableClick)}>
            <label htmlFor='organizationId' className='m-0'>
              {t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.ORGANIZATION_ID()}
            </label>
            <NvTextInput
              withForm
              autoComplete='off'
              name='organizationId'
              ariaLabel={t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.ORGANIZATION_ID()}
              className='mb-4'
              placeholder={t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.ORGANIZATION_ID()}
              required
              data-qa={config.pendo.credly.integrationOrganizationId}
            />

            <label htmlFor='organizationToken' className='m-0'>
              {t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.AUTHORIZATION_TOKEN()}
            </label>
            <NvTextInput
              withForm
              autoComplete='off'
              name='organizationToken'
              ariaLabel={t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.AUTHORIZATION_TOKEN()}
              placeholder={t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.AUTHORIZATION_TOKEN()}
              required
              data-qa={config.pendo.credly.integrationOrganizationToken}
            />

            <div className='button-bar'>
              <Button variant='secondary' onClick={props.handleModalClose} data-qa={config.pendo.credly.integrationCancel}>
                {t.FORM.CANCEL()}
              </Button>

              <Button
                type='submit'
                variant='primary'
                disabled={!isDirty || !isValid}
                data-qa={!hasCredlyIntegration ? config.pendo.credly.integrationEnable : config.pendo.credly.integrationUpdate}
              >
                {!hasCredlyIntegration ? t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.SETTINGS_MODAL.ENABLE() : t.FORM.UPDATE()}
              </Button>
            </div>
          </form>
        </div>
      </FormProvider>
    </PopoversContainerContext.Provider>
  );
});

type Props = {
  show: boolean,
  onClose: () => void
};

const CredlySettings = (props: Props) => {
  const [show, setShow] = React.useState(props.show);
  const formRef = React.useRef<any>();

  React.useEffect(() => {
    if (props.show) {
      setShow(true);
    }
  }, [props.show]);

  const handleModalClose = () => {
    setShow(false);
    props.onClose();
  };

  const modalBodyStyles = css`
    padding: ${standardSpacing}px;
  `;

  return (
    <NvModal
      show={show}
      height='auto'
      type={ModalType.FIXED}
      onClose={handleModalClose}
      header={t.INSTITUTIONS.ADVANCED_SETTINGS.CREDLY.TITLE()}
      body={(
        <div css={modalBodyStyles}>
          <Form ref={formRef} handleModalClose={handleModalClose} />
        </div>
      )}
      data-qa={config.pendo.credly.integrationModal}
    />
  );
};

export default CredlySettings;
