import t from 'react-translate';

// Components
import NvFormDropdown from 'shared/components/inputs/nv-form-dropdown';
import { NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import React, { useEffect, useRef } from 'react';
import { config } from '../../../config/pendo.config.json';

export const MS_LOBBY_BY_PASS_TYPES = {
  ORGANIZER: 'organizer',
  ORGANIZATION: 'organization',
  ORGANIZATION_AND_FEDERATED: 'organizationAndFederated',
  EVERYONE: 'everyone',
};

type MSTeamsSettingsProps = {
  disabled: boolean
  email: string
};

const MSTeamsSettings = (props: MSTeamsSettingsProps) => {
  const { email, disabled } = props;
  const lobbyByPassTypesRef = useRef<NvDropdownOption[]>([]);

  useEffect(() => {
    lobbyByPassTypesRef.current = Object.entries(MS_LOBBY_BY_PASS_TYPES).map(([key, value]) => ({
      id: value,
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.LOBBY_BY_PASS[key](),
      dataQa: `${config.pendo.liveEvent.liveEventSession.msTeams.lobbyByPass}-${value}`,
    }));
  }, []);

  return (
    <React.Fragment>
      <div className='text-small bold gray-3'>{t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.LOBBY_BY_PASS.LABEL()}</div>
      <NvFormDropdown
        title={t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.LOBBY_BY_PASS.LABEL()}
        name='settings.lobbyBypassSettings'
        items={lobbyByPassTypesRef.current}
        disabled={disabled}
        isSmallSize
      />
      <div className='bold text-small gray-2 mt-2'>
        {t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.MS_TEAMS_ORGANIZER(email)}
      </div>
    </React.Fragment>
  );
};

export default MSTeamsSettings;
