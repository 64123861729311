import React from 'react';
import moment from 'moment';
import t from 'react-translate';

import { Exercise } from 'redux/schemas/models/exercise';
import NvIcon from 'shared/components/nv-icon';

import { CheckIcon, todoIcon, todoProgress } from './todo-row';
import ProgressIcon from '../progress-icon';

type ExerciseTodoProps = Exercise & {
  thumbnailSrc: string,
  backgroundColor: string,
};

function ExerciseTodo({
  type,
  title,
  deadline,
  progress,
  thumbnailSrc,
  backgroundColor,
}: ExerciseTodoProps) {
  const deadlineMoment = moment(deadline);
  const deadlineDay = deadlineMoment.format('dddd');
  const deadlineTime = deadlineMoment.format('lll');
  const currentMoment = moment();

  const isMissed = progress === todoProgress.MISSED;
  const isComplete = progress === todoProgress.COMPLETED;
  const isApproved = progress === todoProgress.APPROVED;
  const isRejected = progress === todoProgress.REJECTED;
  const inProgress = progress === todoProgress.IN_PROGRESS;
  const isNotStarted = progress === todoProgress.NOT_STARTED;
  const isApprovalRequired = progress === todoProgress.APPROVAL_REQUIRED;
  const isPendingApproval = progress === todoProgress.PENDING_APPROVAL;
  const isSubmitted = isComplete || isApproved;
  const isPending = isPendingApproval || isRejected;

  const showDueSoon = (isNotStarted
    || inProgress
    || isApprovalRequired
    || isPendingApproval
    || isRejected)
    && deadlineMoment > currentMoment
    && deadlineMoment < moment().add(7, 'days');

  const todoClassNames = [
    'todo-container',
    isSubmitted && 'completed',
    showDueSoon && 'due-soon',
    (inProgress || isPendingApproval || isRejected) && 'in-progress',
  ]
    .filter(Boolean)
    .join(' ');

  const icon = <NvIcon icon={todoIcon[type]} size='smallest' />;
  const showTodoIcon = isNotStarted || isApprovalRequired || isMissed;
  const showIconProgress = inProgress || isPendingApproval || isRejected;
  const showCheckIcon = isComplete || isApproved;

  return (
    <div className={todoClassNames}>
      <div className='todo-description'>
        <div className='todo-cover'>
          {thumbnailSrc
            ? <img src={thumbnailSrc} alt='' />
            : <div className='square-thumbnail' style={{ backgroundColor }} />}
        </div>
        <div className='todo-icon'>
          {showTodoIcon && icon}
          {showCheckIcon && <CheckIcon />}
          {showIconProgress && <ProgressIcon>{icon}</ProgressIcon>}
        </div>
        <div className='todo-title'>
          {title}
        </div>
      </div>
      <div className='todo-content'>
        <div className='status'>
          {isSubmitted && t.TIMELINE.EXERCISE_STATUS.SUBMITTED()}
          {inProgress && t.TIMELINE.EXERCISE_STATUS.IN_PROGRESS()}
          {isPending && t.TIMELINE.EXERCISE_STATUS.PENDING()}
          {isMissed && t.TIMELINE.EXERCISE_STATUS.MISSED()}
        </div>
        {deadline && (
          <div className='date'>
            <span className='day'>{deadlineDay}</span>
            <span className='time'>{deadlineTime}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default ExerciseTodo;
