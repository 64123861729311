import { schema } from 'normalizr';
import { omit } from 'underscore';
import { Submission } from '../models/submissions';
import { RatingActivityExercise } from '../models/exercise-skills-rating';
import { SkillTaggingListSchema } from './video-practice';

export const SubmissionSchema = new schema.Entity<Submission>('submissions', {
  exercise: {
    skillTaggingIds: SkillTaggingListSchema,
  },
}, {
  processStrategy: (value) => ({
    ...value,
    exercise: {
      ...value?.exercise,
      ...value?.exercise?.lectureComponent?.skillTaggings && { skillTaggingIds: value?.exercise?.lectureComponent?.skillTaggings },
    },
  }),
});

export const SubmissionListSchema = new schema.Array(SubmissionSchema);
