import React, { ReactNode } from 'react';
import { css } from '@emotion/react';

import { gray5, primary } from 'styles/global_defaults/colors';

const styles = css`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-width: 2px;
  border-radius: 50%;
  border-style: solid;
  border-color: ${gray5} ${primary} ${primary} ${gray5};
  transform: rotate(-45deg);

  i {
    transform: rotate(45deg);
  }
`;

type ProgressIconProps = {
  children: ReactNode;
};

function ProgressIcon({ children }: ProgressIconProps) {
  return (
    <div
      css={styles}
      className='progress-icon'
    >
      {children}
    </div>
  );
}

export default ProgressIcon;
