/* @ngInject */
export default function NotificationModel(_, CurrentUserManager, NotificationsResources, $q, nvUtil) {
  function hasMultipleSubjectsOrProvidedAttribute(notification, attribute) {
    if (notification.subjects) {
      if (!notification.subjects.length) {
        return true;
      }

      // normal case
      return !!(notification.subjects.length > 1 || (notification.subjects[0][attribute] && notification.subjects[0][attribute].length));
    }

    return null;
  }

  function Notification(attributes) {
    const _this = this;
    _.extend(_this, attributes);

    _this.markRead = (skipUpdateBackend) => {
      const isAlreadyRead = _this.read;
      _this.read = true;

      if (isAlreadyRead || skipUpdateBackend) {
        return $q.when();
      }

      if (_this.catalogId) {
        return NotificationsResources.updateCourseNotification({ catalogId: _this.catalogId, notificationId: _this.id, read: true }).$promise;
      }

      if (_this.object.scenario) {
        return NotificationsResources.updatePracticeRoomNotification({ notificationId: _this.id, read: true }).$promise;
      }
    };

    _this.selectedPracticeRoomTab = () => {
      if (_this.baseTranslateObject.isObjectCurrentUser) {
        return 'my_practice';
      }

      if (_this.baseTranslateObject.isFeatured) {
        return 'featured';
      }

      return 'gallery';
    };

    // for angular translate
    let baseTranslateObject = {};

    if (_this.subjects) {
      baseTranslateObject.subjectCount = _this.subjects ? _this.subjects.length : 0;

      for (let i = 0; i <= 2; i += 1) {
        if (_this.subjects[i]) {
          baseTranslateObject[`subject${i}FirstName`] = _this.subjects[i].firstName;
          baseTranslateObject[`subject${i}LastName`] = _this.subjects[i].lastName;
        }
      }
    }

    baseTranslateObject = _.extend(baseTranslateObject, {
      courseName: CurrentUserManager.coursesHash[_this.courseId]?.name,
      courseAlias: CurrentUserManager.coursesHash[_this.courseId]?.offeringName.downcasedSingularized,
      groupAlias: CurrentUserManager.coursesHash[_this.courseId]?.groupName.downcasedSingularized,
      pointsAliasSingularized: CurrentUserManager.coursesHash[_this.courseId]?.pointsName.downcasedSingularized,
      pointsAliasPluralized: CurrentUserManager.coursesHash[_this.courseId]?.pointsName.downcasedPluralized,
      teachingTeamNameCapitalizedSingularized: CurrentUserManager.coursesHash[_this.courseId]?.teachingTeamName.capitalizedSingularized,
      teachingTeamNameDowncasedSingularized: CurrentUserManager.coursesHash[_this.courseId]?.teachingTeamName.downcasedSingularized,
      teamAlias: CurrentUserManager.coursesHash[_this.courseId]?.teamName.downcasedSingularized,
    });

    if (_this.object) {
      baseTranslateObject = _.extend(baseTranslateObject, {
        objectTitle: _this.object.title,
        objectName: _this.object.name,
        ownerTitle: _this.object.owner?.title,
        objectFirstName: _this.object.user?.firstName,
        objectLastName: _this.object.user?.lastName,

        isObjectCurrentUser: _this.object.user?.id === CurrentUserManager.user.id,

        statementName: _this.object.statementName,
        amount: _this.object.amount,
        targetGoal: _this.object.targetGoal,
        exerciseTitle: (_this.object.exercise?.title) || _this.object.exerciseTitle,
        numPeerReviews: _this.object.exercise?.numPeerReviews,
        forumTitle: _this.object.forum?.title,
        topicTitle: _this.object.topic?.title,
        teamName: (_this.object.team?.name) || _this.object.name,
        meetingTitle: _this.object.meeting?.title,
        documentName: _this.document?.displayName,
        teamTopicTitle: _this.object?.metaContent?.title,
        scenarioTitle: _this.object?.scenario?.title,
        isFeatured: _this.object?.isFeatured,

        hasMultiplePosts: hasMultipleSubjectsOrProvidedAttribute(_this, 'posts'),
        pointsReceived: _this.object.pointsReceived,
      });

      _this.getTemplateUrl = function () {
        return `notifications/templates/individual_notifications/${nvUtil.toSnakeCase(this.type)}.html`;
      };
    }

    _this.baseTranslateObject = baseTranslateObject;
  }

  Notification.getAll = function () {
    return NotificationsResources.get().$promise
      .then((response) => {
        response.result.practiceRoomNotifications = _.map(response.result.practiceRoomNotifications, (notification) => new Notification(notification));

        _.each(response.result.courses, (course) => {
          course.notifications = _.map(course.notifications, (notification) => {
            notification.catalogId = course.catalogId;
            return new Notification(notification);
          });
        });

        return response.result;
      });
  };

  Notification.markAllRead = function (catalogId, beforeUpdatedAt) {
    return NotificationsResources.markAllRead({ catalogId, beforeUpdatedAt }).$promise
      .then((response) => response.result);
  };

  Notification.markAllPracticeRoomRead = function (beforeUpdatedAt) {
    return NotificationsResources.markAllPracticeRoomRead({ beforeUpdatedAt }).$promise
      .then((response) => response.result);
  };

  Notification.getNumUnreadNotifications = function () {
    return NotificationsResources.getNumUnreadNotifications().$promise
      .then((response) => response.result);
  };

  Notification.getCourseNumUnreadNotifications = function (catalogId) {
    return NotificationsResources.getCourseNumUnreadNotifications({ catalogId }).$promise
      .then((response) => response.result);
  };

  Notification.getPracticeRoomNumUnreadNotifications = function () {
    return NotificationsResources.getPracticeRoomNumUnreadNotifications().$promise
      .then((response) => response.result);
  };

  return Notification;
}
