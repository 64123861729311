import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { OrgColor } from 'redux/schemas/models/institution';

// eslint-disable-next-line import/prefer-default-export
export const updateOrgLevelColors = createAsyncThunk(
  'UPDATE_ORG_LEVEL_COLORS',
  (payload: { institutionId: number, mainColors: OrgColor, highlightColors: OrgColor }) => axios
    .put(
      `institutions/${payload.institutionId}/update_org_colors.json`,
      {
        colors: {
          mainColors: payload.mainColors,
          highlightColors: payload.highlightColors,
        },
      },
    )
    .then(response => response.data.result),
);
