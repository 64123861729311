import React, { useCallback, useContext, useEffect, useState } from 'react';
import t from 'react-translate';

import { AngularContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { without } from 'underscore';
import SectionContent from '../section-content';
import { DisableProps } from './integrations';
import { config } from '../../../../../config/pendo.config.json';

const MsIntegration = (props: { onDisable: (props: DisableProps) => void }) => {
  const { injectServices, $scope } = useContext(AngularContext);
  const [InstitutionsManager] = injectServices(['InstitutionsManager']);
  const [source, setLiveSessionSettings] = useState($scope.$eval('vm.InstitutionsManager.institution.liveSessionSettings.source'));
  const dispatch = useAppDispatch();
  const integrationType = InstitutionsManager.INTEGRATION_TYPES.MS_TEAMS_USER_LEVEL;


  useEffect(() => {
    $scope.$watch('vm.InstitutionsManager.institution.liveSessionSettings.source', (newValue) => {
      setLiveSessionSettings(newValue);
    });
  }, [$scope]);

  const hasIntegration = useCallback(() => {
    if (source?.includes(integrationType)) {
      return true;
    }
    return false;
  }, [source]);

  const enableMicrosoft = () => {
    InstitutionsManager.saveLiveSessionSettings(null, null, integrationType)
      .then((isValid) => {
        if (isValid) {
          InstitutionsManager.institution.updateFromReact({
            liveSessionSettings: {
              ...InstitutionsManager.institution.liveSessionSettings,
              source: [
                ...InstitutionsManager.institution.liveSessionSettings?.source,
                integrationType,
              ],
            },
          });
          dispatch(
            addAlertMessage({
              type: AlertMessageType.SUCCESS,
              header: t.FORM.SUCCESS_BANG(),
              message: t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_INTEGRATION.ENABLE_SUCCESS(),
            }),
          );
        }
      }, () => {
        dispatch(
          addAlertMessage({
            type: AlertMessageType.ERROR,
            header: t.FORM.OOPS(),
            message: t.FORM.ERROR_SOMETHING_WRONG(),
          }),
        );
      });
  };

  const disableMicrosoft = () => {
    props.onDisable({
      action: () => InstitutionsManager.disableLiveSessionSettings(integrationType),
      onSuccess: () => InstitutionsManager.institution.updateFromReact({
        liveSessionSettings: {
          source: without(
            InstitutionsManager.institution.liveSessionSettings.source,
            integrationType,
          ),
        },
      }),
      successMessage: t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_INTEGRATION.DISABLE_SUCCESS(),
      title: t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_INTEGRATION.DISABLE_TITLE(),
      bodyText: t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_INTEGRATION.DISABLE_DESCRIPTION(),
      confirmDataQa: config.pendo.settings.microsoft.disableConfirm,
      cancelDataQa: config.pendo.settings.microsoft.disableCancel,
    });
  };

  const msIntegrationCta = hasIntegration()
    ? t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_INTEGRATION.DISABLE()
    : t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_INTEGRATION.ENABLE();

  return (
    <SectionContent
      header={t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_INTEGRATION.TITLE()}
      description={[t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_INTEGRATION.DESCRIPTION()]}
      showCTAButton
      ctaText={msIntegrationCta}
      onAction={() => (hasIntegration() ? disableMicrosoft() : enableMicrosoft())}
      buttonVariant={hasIntegration() ? 'secondary' : 'primary'}
      dataQa={config.pendo.settings.microsoft.cta}
    />
  );
};

export default MsIntegration;
