/* @ngInject */
export default function MentionablesModel(

  _,
  $translate,
) {
  const manager = {
    // data
    mentionableUsers: [],
    searchMentionablesResults: [],
    lastUpdatedSource: null,

    // functions
    setMentionableUsers,
    getLastUpdatedSource,
    getMentionableUsers,

    resetSearchResults,
    onSearchMentionables,
    getSearchMentionablesResults,
    onSelectMentionable,
    createFroalaMentionableString,

    parseMentionables,
  };

  function setMentionableUsers(mentionableUsers, lastUpdatedSource = null) {
    manager.mentionableUsers = mentionableUsers;
    manager.lastUpdatedSource = lastUpdatedSource;
  }

  function getLastUpdatedSource() {
    return manager.lastUpdatedSource;
  }

  function getMentionableUsers() {
    return manager.mentionableUsers;
  }

  function getSearchMentionablesResults() {
    return manager.searchMentionablesResults;
  }

  function onSearchMentionables(term) {
    const results = new Set();

    manager.mentionableUsers.forEach((user) => {
      if (results.length >= 10) {
        return false;
      }

      if (user.firstNameKey) {
        user.firstName = $translate.instant(user.firstNameKey);
      }

      user.firstName = user.firstName || '';
      user.lastName = user.lastName || '';

      const names = [user.firstName + user.lastName, user.lastName + user.firstName, `${user.firstName} ${user.lastName}`, `${user.lastName} ${user.firstName}`];

      names.forEach((name) => {
        if (name.toUpperCase().indexOf(term.toUpperCase()) > -1) {
          results.add(user);
        }
      });

      return true;
    });

    manager.searchMentionablesResults = Array.from(results);
  }

  function onSelectMentionable(item) {
    resetSearchResults();
    const fullName = `${item.firstName} ${item.lastName}`;
    // eslint-disable-next-line @typescript-eslint/quotes
    return (
      `<span
        oe-mention=''
        data-user-id='${item.id}'
        user='${JSON.stringify(item).replace(/'/g, '&#39;')}'
        dir='ltr'
      >@${fullName}</span>`
    );
  }

  function createFroalaMentionableString(item) {
    const fullName = `${item.firstName} ${item.lastName}`;
    return `<p> ${manager.onSelectMentionable(item)}&nbsp;</p>`;
  }

  function resetSearchResults() {
    manager.searchMentionablesResults = [];
  }

  function parseMentionables(body) {
    const mentionableIds = [];
    if (body?.indexOf('<span oe-mention') > -1) {
      _.each($('*[oe-mention]', body), (element) => {
        mentionableIds.push($(element).data('user-id'));
      });
    }
    return mentionableIds;
  }

  return manager;
}
