import { css } from '@emotion/react';
import React, {
  useState, useEffect, forwardRef, MutableRefObject,
} from 'react';

// Styles
import {
  halfSpacing, largeSpacing, doubleSpacing, extraLargeSpacing,
} from 'styles/global_defaults/scaffolding';
import { gray4, hexToRgbaString } from 'styles/global_defaults/colors';
import { desktop } from 'styles/global_defaults/media-queries';

// Hooks
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';

// Components
import NvIcon from 'shared/components/nv-icon';
import ClickableContainer from 'components/clickable-container';

const styles = css`
  cursor: pointer;
  position: absolute;
  width: ${doubleSpacing}px;
  height: ${doubleSpacing}px;
  right: ${halfSpacing}px;
  bottom: ${halfSpacing}px;
  ${desktop(css`
    right: ${extraLargeSpacing}px;
    bottom: ${largeSpacing}px;
  `)};
  border-radius: 50%;
  box-shadow: 2px 4px 8px 0 ${hexToRgbaString(gray4, 0.7)};

  /* To show the up arrow icon, rotate the collapse icon by 90 degree  */
  .icon {
    /* Setting important to cancel the rtl flipping transform for icon-collapse */
    transform: rotate(90deg) !important;
    color: #fff;
  }
`;

const moveToTop = (itemRef: MutableRefObject<HTMLInputElement>) => {
  if (itemRef?.current) {
    itemRef?.current.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

type BackToTopProps = {
  scrollBuffer?: number
};

// Back to top component display only if scroll passes scrollBuffer.
const BackToTop = forwardRef((
  { scrollBuffer }: BackToTopProps,
  forwardedRef: MutableRefObject<HTMLInputElement>,
) => {
  const [showBackToTop, setShowBackToTop] = useState<boolean>(false);

  // Checking if the scroll has reached the scrollBuffer from the top
  const isScrolledUp = useInfiniteScroll(forwardedRef?.current, scrollBuffer, false);

  useEffect(() => {
    if (isScrolledUp) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  }, [isScrolledUp]);

  return (
    <React.Fragment>
      {showBackToTop && (
        <ClickableContainer
          css={styles}
          onClick={() => moveToTop(forwardedRef)}
          className='d-flex align-items-center justify-content-center bg-gray-4'
        >
          <NvIcon icon='collapse' size='xss-smallest' />
        </ClickableContainer>
      )}
    </React.Fragment>
  );
});

BackToTop.defaultProps = {
  scrollBuffer: 10,
};

export default BackToTop;
