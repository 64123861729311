import { MockDefinition, MockTypes } from '../axios/axios-mocks';

const MyMenteesMocks: MockDefinition[] = [
  {
    type: MockTypes.GET,
    url: '/users/my_mentees.json',
    status: 200,
    response: {
      result: [
        {
          id: 9,
          thumbnail: '/assets/brand/temp.png',
          mentees: '',
          name: 'Unlocking Creativity 2022',
          catalogId: 'sixth_course',
        },
        {
          id: 11,
          thumbnail: '/assets/brand/temp.png',
          mentees: '24 Mentees',
          name: 'NovoEd Foundations: Pedagogy, Learning and Cases',
          catalogId: 'sixth_course',
        },
        {
          id: 3,
          thumbnail: '/assets/brand/temp.png',
          mentees: '34 Direct Reports',
          name: 'Unlocking Creativity 2021',
          catalogId: 'first_course',
        },
      ],
      message: 'Successfully completed the request',
    },
  },
];

export default MyMenteesMocks;
