import React from 'react';
import embedAngular from 'shared/embed-angular';
import { useFormContext } from 'react-hook-form';
import { AngularContext, AngularServicesContext } from 'react-app';

const recaptchaTemplate = `
  <div
    vc-recaptcha=''
    lang='CurrentUserManager.recaptchaLang'
    ng-model='recaptchaResponse'
    on-create='onCreate(widgetId)'
    on-success='onSuccess(response)'
    on-expire='onExpire()'
  />
`;

type Props = {
  name: string,
  className?: string,
};

const NvRecaptcha = (props: Props) => {
  const { name, className = '' } = props;
  const angularServices = React.useContext(AngularServicesContext);
  const { injectServices } = React.useContext(AngularContext);
  const [vcRecaptchaService] = injectServices(['vcRecaptchaService']);
  const recaptcha = React.useRef();

  const methods = useFormContext();
  const { register, unregister, setValue } = methods;

  React.useEffect(() => {
    const scope = angularServices.$scope.$new();
    register(name);

    scope.CurrentUserManager = angularServices.CurrentUserManager;
    scope.recaptchaResponse = null;
    scope.widgetId = null;

    scope.onCreate = (widgetId: number) => {
      scope.widgetId = widgetId;
    };

    scope.onSuccess = (response: string) => {
      setValue(name, response, { shouldDirty: true, shouldValidate: true });
    };

    scope.onExpire = () => {
      setValue(name, '', { shouldValidate: true });
    };

    embedAngular(recaptchaTemplate, recaptcha, angularServices, scope);

    return () => {
      vcRecaptchaService.reload(scope.widgetId);
      unregister(name);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className={`d-flex justify-content-center ${className}`} ref={recaptcha} />;
};

export default NvRecaptcha;
