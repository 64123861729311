import { css } from '@emotion/react';
import ColorPickerPopover from 'components/color-picker-popover';
import FileUploadingBar from 'components/file-uploading-bar';
import { useState, useEffect } from 'react';
import t from 'react-translate';
import { LectureComponent, ExternalToolType } from 'redux/schemas/models/lecture-component';
import NvDropdown, { NvDropdownOption, NvDropdownButtonStyle } from 'shared/components/inputs/nv-dropdown';
import NvFilePicker from 'shared/components/nv-filepicker';
import NvIcon from 'shared/components/nv-icon';
import IconLibraryModal from 'shared/components/nv-icon-library';
import useUploadFile, { NovoEdFile } from 'shared/hooks/use-upload-file';
import { S3NameSpaces } from 'shared/services/s3-upload-factory';
import { gray4 } from 'styles/global_defaults/colors';
import _ from 'underscore';
import { config } from '../../../../config/config.json';

const styles = css`
.dropdown-target {
  width: 50px;
  height: 60px;
}

.web-link-image {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}
`;

const popoverStyles = css`
.shape-button {
  width: 36px;
  height: 36px;

  padding: 3px;

  &:hover {
    cursor: pointer;
  }

  &.selected {
    padding: 2px;
    border: 1px dashed ${gray4};
  }
}
`;

/** The square or circular area on the side of a Web Link lecture component that shows either
 * an icon or an image */
const WebLinkPictureArea = (props: {
  userCanEdit: boolean,
  viewOptions: LectureComponent<ExternalToolType.WEB_LINK>['viewOptions'],
  picture: LectureComponent<ExternalToolType.WEB_LINK>['picture'],
  /** The component save function from WebLinkLectureComponent */
  saveWebLinkImage: (viewOptions: Partial<typeof props.viewOptions>, pictureData?: NovoEdFile) => void,
  isPreview: boolean,
}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showIconLibrary, setShowIconLibrary] = useState(false);
  const [preparingImage, setPreparingImage] = useState(false);

  // Local state vars used to show lecture component updates sooner than the API responses finishing
  const [icon, setIcon] = useState(props.viewOptions.libraryIconName);
  const [picture, setPicture] = useState(props.picture);

  const showUploadIcon = !icon && !picture;

  const { uploadFiles, isUploading, filesUploading } = useUploadFile();

  const [iconColorProps, setIconColorProps] = useState(_.pick(props.viewOptions, 'libraryIconColor', 'pictureBackgroundColor'));

  const onImagePicked = async (files: File[]) => {
    setIcon(null);
    setPreparingImage(true);
    const [novoEdFile] = await uploadFiles(files, S3NameSpaces.CONTENT_TEMPLATES);
    props.saveWebLinkImage({ libraryIconName: null }, novoEdFile);
  };

  // Update the picture state when a new photo upload is available.
  // We have to update this in a useeffect because the actual cdn url is only available in the data response to saving the component, and not
  // in the novoEdFile above.
  useEffect(() => {
    if (!props.picture || props.picture?.cdnUrl !== picture?.cdnUrl) {
      setPicture(props.picture);
    }
  }, [props.picture]);

  useEffect(() => {
    if (picture?.cdnUrl) {
      setPreparingImage(false);
    }
  }, [picture]);

  const dropdownItems: NvDropdownOption[] = [
    { type: 'custom',
      customItem: (
        <NvFilePicker
          accept={['image/*']}
          multiple={false}
          onChange={(files) => onImagePicked(files)}
          data-qa={config.pendo.activities.webLink.pickImage}
        >
          <div className='bs4-dropdown-item'>{ t.LECTURE_PAGES.COMPONENTS.WEB_LINK.UPLOAD() }</div>
        </NvFilePicker>
      ),
    },
    {
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.WEB_LINK.PICK_FROM_LIBRARY(),
      callback: () => setShowIconLibrary(true),
      dataQa: config.pendo.activities.webLink.pickIcon,
    },
    { type: 'divider' },
    {
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.WEB_LINK.CHANGE_COLORS(),
      callback: () => setShowColorPicker(true),
      dataQa: config.pendo.activities.webLink.changeColors,
    },
  ];

  const iconPicked = (iconName: string) => {
    setIcon(iconName);
    setPicture(null);
    props.saveWebLinkImage({
      libraryIconName: iconName,
    }, null);
  };

  const isProcessingImage = () => preparingImage || isUploading;

  const imageStyles = css`
    background-image: ${picture && !isProcessingImage() ? `url("${picture.cdnUrl}")` : ''};
  `;

  const handleChange = (colorProp, newVal: string) => {
    const newColorProps = {
      ...iconColorProps,
      [colorProp]: newVal,
    };
    setIconColorProps(newColorProps);
    props.saveWebLinkImage(newColorProps);
  };

  return (
    <div css={styles}>
      {/* Color picker that shows when clicking the */}
      <ColorPickerPopover<{
        libraryIconColor: string,
        pictureBackgroundColor: string,
      }>
        show={showColorPicker}
        sections={[{
          name: 'libraryIconColor',
          title: t.LECTURE_PAGES.COMPONENTS.WEB_LINK.ICON_COLOR(),
        }, {
          name: 'pictureBackgroundColor',
          title: t.LECTURE_PAGES.COMPONENTS.WEB_LINK.BACKGROUND_COLOR(),
        }]}
        placement='top-start'
        colorValues={iconColorProps}
        onToggle={() => setShowColorPicker(false)}
        onChange={handleChange}
      >
        <NvDropdown
          buttonStyle={NvDropdownButtonStyle.CUSTOM}
          items={dropdownItems}
          customTarget={() => (
            <div
              css={imageStyles}
              style={{
                backgroundColor: iconColorProps.pictureBackgroundColor,
                color: iconColorProps.libraryIconColor ?? 'black',
              }}
              className='web-link-image d-flex align-items-center justify-content-center dropdown-target'
              data-qa={config.pendo.activities.webLink.editPicture}
            >
              {/* Loading bar. If the image is Updated, but the image processing is not ready, then it will show 100% of progress until the image is ready */}
              { isProcessingImage() && <FileUploadingBar filesUploading={isUploading ? filesUploading : { ...filesUploading, 0: { ...filesUploading[0], uploadPercentage: 100 } }} />}
              {/* Upload icon visible when no picture or icon defined */}
              {!isProcessingImage() && !picture && showUploadIcon && <NvIcon icon='upload' size='medium' />}
              {/* Icon */}
              {!isProcessingImage() && !picture && !showUploadIcon && icon
              && <NvIcon icon={icon} size={props.isPreview ? 'small' : 'medium'} />}
            </div>
          )}
          disabled={!props.userCanEdit || isProcessingImage()}
        />
      </ColorPickerPopover>
      <IconLibraryModal onIconClick={iconPicked} show={showIconLibrary} onClose={() => setShowIconLibrary(false)} />
    </div>
  );
};


export default WebLinkPictureArea;
