import { MockDefinition, MockTypes } from '../axios/axios-mocks';

const InstitutionMocks: MockDefinition[] = [
  {
    type: MockTypes.GET,
    url: '/institutions/1',
    status: 200,
    response: {
      result: {
        id: 1,
        name: 'NovoEd',
        logo: null,
        logo_size: 'small',
        brand_color: '#748499',
        brand_bar_font_color: '#fff',
        is_premium: false,
        course_enrollment_types: ['0', '4', '5', '3'],
        email_header: null,
        email_header_style: null,
        document_sharing_provider: null,
        analytics_identifier: '',
        nickname: 'novoed',
        host: 'novoed.localhost',
        favicon: null,
        is_institutional_admin: true,
        is_institutional_mentor: false,
        admin_role: 'org_admin',
        sso_login: false,
        identity_providers: [],
        welcome_email_enabled: true,
        has_csod: true,
        about: null,
        header: null,
        font_color: null,
        tos: null,
        account_management_link: null,
        enable_basic_sso_info_edit: false,
        enable_search_all_users: 0,
        current_period_started_at: '2022-08-07T18:33:32.000Z',
        current_period_ended_at: '2032-08-08T18:33:32.000Z',
        no_seats: null,
        grace_period: 0,
        unenroll_grace_period: 0,
        notes: '',
        created_at: '2022-08-08T18:33:33.000Z',
        direct_upload: false,
        live_session_settings: { source: [] },
        zoom_enabled: null,
        degreed_settings: {},
        profile_settings: {
          account_level: {
            bio: { is_hidden: false },
            location: { is_hidden: false },
            headline: { is_hidden: false },
          },
          org_level: [],
        },
        industry_segment: 'Corporate',
        offerings_audience: 'Public for free',
        is_pilot: true,
        test_or_demo: true,
        for_production: false,
        lcount_cached: null,
        lcount_total: null,
        disable_discussion_edits: false,
        parent_nickname: '',
        active: true,
        discovery_enabled: true,
        lms_enabled: false,
        lms_connection_settings: {},
        allow_file_downloads: true,
        has_microsoft_calendar_integration: false,
        calendar_events_enabled: true,
        calendar_settings: {},
        role_aliases: {
          mentor: {
            capitalizedPluralized: 'Mentors',
            capitalizedSingularized: 'Mentor',
            downcasedSingularized: 'mentor',
            downcasedPluralized: 'mentors',
            pluralizedTitleized: 'Mentors',
            singularizedTitleized: 'Mentor',
          },
          mentee: {
            capitalizedPluralized: 'Mentees',
            capitalizedSingularized: 'Mentee',
            downcasedSingularized: 'mentee',
            downcasedPluralized: 'mentees',
            pluralizedTitleized: 'Mentees',
            singularizedTitleized: 'Mentee',
          },
        },
        skill_tags: [],
        metadata_fields: [],
        default_filter_views: [],
        has_enabled_program_creation: true,
        video_practice_insights_enabled: true,
        credlySettings: {
          isActive: false,
          organizationId: '',
          authorizationToken: '',
          badgeTemplate: {
            id: '',
            name: '',
            description: '',
            state: '',
            url: '',
            imageUrl: '',
          },
        },
        // credlySettings: {
        //   isActive: true,
        //   organizationId: '6dac84e3-7283-41f8-9acb-2b2af277df00',
        //   authorizationToken: 'XXwJArBw8J6aJezzluJSzCn_UFzg1DayuwlJ',
        //   badgeTemplate: {
        //     id: '',
        //     name: '',
        //     description: '',
        //     state: '',
        //     url: '',
        //     imageUrl: '',
        //   },
        // },
        // credlySettings: {
        //   isActive: true,
        //   organizationId: '6dac84e3-7283-41f8-9acb-2b2af277df00',
        //   authorizationToken: 'XXwJArBw8J6aJezzluJSzCn_UFzg1DayuwlJ',
        //   badgeTemplate: {
        //     id: 'qwerty',
        //     name: 'Badge Name',
        //     description: 'Description lorem sit amet amet morbi a sapien condimentum. Vestium, ac cras sit egestas. Morbi auctor tincidunt aliquam adipisasf cing malesuada sem id. Risus viverra eget amet malesuada ac.',
        //     state: 'active',
        //     url: 'https://www.credly.com/org/organization-4/badge/badge-template-1',
        //     imageUrl: 'https://images.credly.com/size/680x680/images/3e0593b8-a799-4ad4-8ba0-e344376fe694/Platform_Trainer_badge.png',
        //   },
        // },
      },
      message: 'Successfully completed the request',
    },
  },
];
export default InstitutionMocks;
