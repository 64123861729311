import { css } from '@emotion/react';
import NvDropdown, {
  NvDropdownButtonStyle, NvDropdownCheckbox, NvDropdownHeader,
} from 'shared/components/inputs/nv-dropdown';

export type NvFilterProps = {
  title: string;
  selected: boolean;
  items: Array<NvDropdownHeader | NvDropdownCheckbox>;
};

export const NvFilter = ({ title, selected, items }: NvFilterProps) => {
  const styles = css`
    .nv-dropdown {
      display: inline-flex;
    }
  `;

  return (
    <div css={styles}>
      <NvDropdown
        showSelectedIndicator
        pill
        title={title}
        altLabel={title}
        buttonStyle={NvDropdownButtonStyle.FORM_SMALL}
        buttonClass={selected && 'selected'}
        items={items}
        menuClassName='filter-menu'
        toggleDataQa={title.split(' ').join('_').toUpperCase()}
      />
    </div>
  );
};

export default NvFilter;
