import PracticeRoom from '../components/practice-room';

export default class PracticeRoomReactCtrl {
  /* @ngInject */
  constructor(
    $scope,
    StateManager,
  ) {
    $scope.StateManager = StateManager;
    $scope.PracticeRoom = PracticeRoom;
  }
}
