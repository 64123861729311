import Pusher, { Channel } from 'pusher-js';

import { config } from '../../../config/config.json';
import PusherChannelNames from './pusher-channel-names';
import Singleton from './singleton';

type ChannelsHash = {
  [key: string]: Channel;
};

class PusherService extends Singleton {
  private pusherInstance = new Pusher(config.pusher.key);
  // TODO: Find out why the options object is causing
  // ERROR in /db/www/novo/black/releases/origami/app/shared/services/pusher-service.ts(13,85)
  // TS2339: Property 'cluster' does not exist on type '{ "key": string; }'.
  // private pusherInstance = new Pusher(config.pusher.key, { cluster: config.pusher.cluster });
  private channelsHash: ChannelsHash = {};
  identifierForUserChannel = '';

  setupChannel(channelName) {
    if (this.channelsHash[channelName]) {
      return this.channelsHash[channelName];
    }

    this.channelsHash[channelName] = this.pusherInstance.channel(channelName) || this.pusherInstance.subscribe(channelName);
    return this.channelsHash[channelName];
  }

  removeChannel(channelName) {
    delete this.channelsHash[channelName];
    this.pusherInstance.unsubscribe(channelName);
  }

  setupUserChannel(identifier) {
    this.identifierForUserChannel = identifier;
    this.setupChannel(PusherChannelNames.userChannel(this.identifierForUserChannel));
  }

  removeUserChannel(identifier) {
    this.removeChannel(PusherChannelNames.userChannel(identifier));
  }

  currentUserChannel() {
    return this.channelsHash[PusherChannelNames.userChannel(this.identifierForUserChannel)];
  }

  initializeCourseChannel(courseId) {
    return this.setupChannel(PusherChannelNames.courseChannel(courseId));
  }

  removeCourseChannel(courseId) {
    this.removeChannel(PusherChannelNames.courseChannel(courseId));
  }

  courseChannel(courseId) {
    return this.channelsHash[PusherChannelNames.courseChannel(courseId)];
  }


  initializeDiscussionsChannelForCourse(courseId) {
    return this.setupChannel(PusherChannelNames.discussionsChannelCourse(courseId));
  }

  removeDiscussionsChannelForCourse(courseId) {
    this.removeChannel(PusherChannelNames.discussionsChannelCourse(courseId));
  }

  discussionsChannelForCourse(courseId) {
    return this.channelsHash[PusherChannelNames.discussionsChannelCourse(courseId)];
  }

  initializeDiscussionsChannelForReport(reportId) {
    return this.setupChannel(PusherChannelNames.discussionsChannelReport(reportId));
  }

  removeDiscussionsChannelForReport(reportId) {
    this.removeChannel(PusherChannelNames.discussionsChannelReport(reportId));
  }

  discussionsChannelForReport(reportId) {
    return this.channelsHash[PusherChannelNames.discussionsChannelReport(reportId)];
  }

  initializeUserCourseChannel(identifier, courseId) {
    return this.setupChannel(PusherChannelNames.userCourseChannel(identifier, courseId));
  }

  removeUserCourseChannel(identifier, courseId) {
    this.removeChannel(PusherChannelNames.userCourseChannel(identifier, courseId));
  }

  userCourseChannel(identifier, courseId) {
    return this.channelsHash[PusherChannelNames.userCourseChannel(identifier, courseId)];
  }
}

export default PusherService.Instance(PusherService);
