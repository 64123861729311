import _ from 'underscore';

export const S3UploadWrapper = function (file, promise, abort = () => {}) {
  const _this = this;
  let abortCallback;

  const baseAttributes = {
    file,
    promise,
    progress: 0,
    uploadInProgress: true,
    abort: () => {
      abortCallback?.();
      abort();
    },
    setAbortCallback: (abortCallbackToSet) => {
      abortCallback = abortCallbackToSet;
    },
  };
  _.extend(_this, baseAttributes);

  // response has data, status, headers, config
  promise.then((response) => {
    _this.progress = 100;
    _this.uploadInProgress = false;

    return response;
  }, (response) => {
    _this.uploadInProgress = false;

    return response;
  }, (evt) => {
    _this.progress = parseInt((100.0 * evt.loaded) / evt.total, 10);
    return evt;
  });
};

const S3UploadWrapperService = () => S3UploadWrapper;

export default S3UploadWrapperService;
