import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { pluck } from 'underscore';
import { Button } from 'react-bootstrap';

import { AngularServicesContext } from 'react-app';
import t from 'react-translate';

import { useAppDispatch } from 'redux/store';
import { getAllFeedback } from 'redux/actions/skills-feedback';
import { RootState } from 'redux/schemas';
import { ExerciseSubmissionState } from 'redux/schemas/models/exercise';
import { Submission } from 'redux/schemas/models/submissions';
import { User } from 'redux/schemas/models/my-account';
import { Team } from 'redux/schemas/models/team';
import { RatingActivityExercise } from 'redux/schemas/models/exercise-skills-rating';
import { getCurrentUserId } from 'redux/selectors/users';
import { getSkillTagsFromTaggings } from 'redux/selectors/skills-feedback';

import { JoinMode, i18nJoin } from 'shared/services/i18n-utils';
import AuthorFeedback from 'shared/components/skill-feedback/author-feedback';
import LoadingWrapper, { LoaderType } from 'shared/components/loading-wrapper';

type YourFeedbackTabProps = {
  reportId: number,
  onDeleteFeedback: () => void,
};

const YourFeedbackTab = ({ reportId, onDeleteFeedback }: YourFeedbackTabProps) => {
  const { $scope, $timeout } = useContext(AngularServicesContext);
  const dispatch = useAppDispatch();

  const submission: Submission = useSelector(state => state.models.submissions?.[reportId]);
  const exercise = submission?.exercise as RatingActivityExercise;
  const exerciseSubmissionstate = (useSelector(
    state => state.app.exerciseSubmissions,
  ) as ExerciseSubmissionState)?.[reportId];
  const skillTags = useSelector((state: RootState) => getSkillTagsFromTaggings(state, exercise?.skillTaggingIds ?? []));
  const currentUserId = useSelector(state => getCurrentUserId(state));
  const currentInstitutionId = useSelector(
    (state) => state.app.currentInstitutionId,
  );

  useEffect(() => {
    dispatch(getAllFeedback({
      institutionId: currentInstitutionId,
      ownerId: reportId,
      ownerType: 'Report',
      displayOwnFeedback: true,
    }));
  }, [currentInstitutionId, currentUserId, dispatch, reportId]);

  const getSkillsText = () => {
    const skillTagNames = pluck(skillTags, 'name');

    return skillTagNames.join(', ');
  };

  return (
    <div className='p-4'>
      <LoadingWrapper
        isLoaded={exerciseSubmissionstate?.feedbackOnceLoaded}
        loaderType={LoaderType.PLACEHOLDER}
      >
        {exerciseSubmissionstate?.authorFeedback?.ratings?.length > 0 ? (
          <AuthorFeedback
            ownerId={reportId}
            ownerKey='exerciseSubmissions'
            createdAt={exerciseSubmissionstate?.authorFeedback?.createdAt}
            submittedUserName={
              (submission.owner as Team)?.name ?? (submission.owner as User)?.firstName
            }
            onDelete={onDeleteFeedback}
          />
        ) : (
          <div className='d-flex flex-column align-items-center'>
            {skillTags && (
            <div className='mb-6'>
              {t.SKILLS_FEEDBACK.NO_SKILLS_FEEDBACK_PROVIDED(
                getSkillsText(),
              )}
            </div>
            )}
            {exercise?.skillsRatingFeedback?.progress === 'missed' && (
              <span className='gray-2 label mb-2'>{t.SKILLS_FEEDBACK.MISSED_ACTIVITY()}</span>
            )}
            <Button
              variant='primary'
              onClick={() => $timeout(() => { $scope.toggleRatingForm(true); })}
              className='mb-6'
              disabled={exercise?.skillsRatingFeedback?.progress === 'missed'}
            >
              {t.SKILLS_FEEDBACK.PROVIDE_FEEDBACK()}
            </Button>
          </div>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default YourFeedbackTab;
