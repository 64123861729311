import store from 'redux/store';
import { ActivityKey } from 'redux/schemas/models/activity';
import { setActivityProgress } from 'redux/actions/lecture-components';

/* @ngInject */
export default function TimelineTimedQuizLectureComponentModelService(

  _,
  nvUtil,
  moment,
  CountdownTimerModel,
) {
  const TimelineTimedQuizLectureComponentModel = function (attributes) {
    const _this = this;
    let deadlineMoment;
    const currentMoment = moment();

    const base = {
      templateUrl: 'timelines/templates/nv-timeline-timed-quiz.html',
      canHaveHardDeadline: true,
    };

    /** Setting Up Basic Attributes * */
    _.extend(_this, base, attributes);
    // passed lecturePage, lectureSection, course
    preprocess();

    /** Public Functions * */
    _.extend(_this, {
      getStatus,
      inProgress,
      isCompleted,
      isMissed,
      isResultReleased,
      isTodoRequiredForCompletion,
      updateComponentPointsAndProgress,
      updateComponentProgress,
      isPrereqCompleted,
      getPayload,
      hasDeadline,
      setDeadline,
    });

    /** Function Declarations * */
    /* Used Publicly */
    function getStatus() {
      return _this.quiz.progress;
    }

    function inProgress() {
      return _this.quiz.progress === 'in_progress';
    }

    function isCompleted() {
      return _this.quiz.progress === 'completed';
    }

    function isMissed() {
      return _this.quiz.progress === 'missed' || (timeUp() && !isCompleted());
    }

    function isResultReleased() {
      return moment().isSameOrAfter(_this.quiz.releaseResultsAt);
    }

    function isTodoRequiredForCompletion() {
      return _this.quiz.isRequiredForCompletion;
    }

    function updateComponentPointsAndProgress(componentType, componentId, pointsReceived, totalPoints, progress) {
      if ((_this.type === componentType && _this.id === componentId) || (componentType === 'quiz' && _this.quiz.id === componentId)) {
        if (_this.quiz.progress === 'not_started' && progress === 'in_progress') {
          // Update start timer when a timed Quiz move from not_started to in_progress
          _this.timer = new CountdownTimerModel({
            duration: _this.quiz.duration,
            timeRemaining: _this.quiz.timeRemaining,
            timeInterval: _this.quiz.timeInterval,
          });
        }
        if (progress) {
          _this.quiz.progress = progress;
        } else {
          _this.quiz.progress = 'completed';
        }

        store.dispatch(setActivityProgress({
          pointsReceived,
          progress: _this.quiz.progress,
          activityPayloadId: _this.quiz.id,
          activityKey: ActivityKey.TIMED_QUIZZES,
        }));

        _this.quiz.pointsReceived = pointsReceived;
        _this.pointsReceived = pointsReceived;

        if (_.isNumber(totalPoints)) {
          _this.currentTotalPoints = totalPoints;
        }

        viewPreprocess();

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function updateComponentProgress(componentType, componentId, progress) {
      if ((_this.type === componentType && _this.id === componentId) || (componentType === 'quiz', _this.quiz.id === componentId)) {
        _this.quiz.progress = progress;
        store.dispatch(setActivityProgress({
          progress,
          activityPayloadId: _this.quiz.id,
          activityKey: ActivityKey.TIMED_QUIZZES,
        }));
        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function isPrereqCompleted(prereq) {
      if ((prereq.type === _this.type && prereq.id === _this.id)
        || (prereq.type === 'LectureComponentTimedQuiz' && prereq.id === _this.quiz.id)
        || (prereq.type === 'TimedExam' && prereq.id === _this.quiz.id)
      ) {
        return isCompleted();
      }
      return false;
    }

    function getPayload() {
      return _.pick(_this, ['title', 'expirationDate', 'hardDeadline']);
    }

    function hasDeadline() {
      return !!this.expirationDate;
    }

    function setDeadline(newDeadline) {
      this.expirationDate = newDeadline;
    }

    function isDeadlineBeforeLesson() {
      return _this.quiz.deadlineMoment < _this.lecturePage.releaseDate;
    }

    function showDueSoon() {
      if (_this.quiz.expirationDate) {
        return !isCompleted() && !isMissed() && !timeUp()
            && deadlineMoment > currentMoment
            && deadlineMoment < moment().add(7, 'days');
      }
      return false;
    }

    function timeUp() {
      return inProgress() && !_this.timer.time.hours
        && !_this.timer.time.minutes
        && !_this.timer.time.seconds;
    }

    /* Used Privately */
    function preprocess() {
      deadlineMoment = moment(_this.quiz.expirationDate);

      _.extend(_this, _.pick(_this.quiz, 'title', 'hardDeadline', 'isTodo', 'hasStructuralIssues', 'expirationDate', 'pointsReceived',
        'totalPoints', 'scheduledCommunicationsCount'));
      _this.activityId = _this.quiz.id;
      _this.expired = _this.quiz.expired || (!_this.quiz.timeRemaining && isResultReleased());

      // current total points takes into account if the the user can score more
      // so if the user has maxed out attempts, the total points will be the same as the pointsReceived
      _this.currentTotalPoints = nvUtil.getCurrentTotalPoints(_this.totalPoints, _this.lecturePage.releaseDate, _this.course.expiringPointsEnabled, _this.expired);

      _this.timer = new CountdownTimerModel({
        duration: _this.quiz.duration,
        timeRemaining: _this.quiz.timeRemaining,
        timeInterval: _this.quiz.timeInterval,
      });

      viewPreprocess();
    }

    function viewPreprocess() {
      _this.showDueSoon = showDueSoon();

      if (_this.pointsReceived > _this.currentTotalPoints) {
        _this.currentTotalPoints = _this.pointsReceived ? _this.pointsReceived : 0;
      }
      _this.displayTotalPointsOnly = !_this.pointsReceived && _this.currentTotalPoints && !isResultReleased() && !isMissed();
    }
  };

  return TimelineTimedQuizLectureComponentModel;
}
