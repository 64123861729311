import * as Sentry from '@sentry/browser';
import store, { cloneDeepSerializable } from '../../redux/store';
import { updateLectureComponentFromAngular } from '../../redux/actions/lecture-components';

/* @ngInject */
export default function ReportModelService(
  _,
  moment,
  $q,
  $sce,
  $translate,
  $timeout,

  nvUtil,
  CurrentUserManager,
  CurrentPermissionsManager,
  PubSubDiscussions,

  ReportsResources,
  FlaggingModel,
  CommentModel,
  AlertMessages,
) {
  const PENDING_APPROVAL = 'pending';
  const REJECTED = 'rejected';
  const APPROVED = 'approved';
  const COMPLETED = 'completed';

  const FLAG_OPTIONS = ['no_content', 'forgery', 'abuse', 'spam'];

  class ReportModel {
    static newReport(exercise) {
      const deferred = $q.defer();
      const titlePromise = exercise.currentTeam?.name
        ? $translate('EXERCISES.TEAMS_SUBMISSION', {
          teamName: exercise.currentTeam?.name,
        }) : $translate('EXERCISES.LEARNERS_SUBMISSION', {
          firstName: CurrentUserManager.user.firstName,
          lastName: CurrentUserManager.user.lastName,
        });
      titlePromise.then((title) => {
        const report = new ReportModel({
          createdInNewReport: 'true',
          title,
          exercise,
          template: exercise,
        });
        report.currentRevision = {
          sections: report.mergeSectionData(exercise.template.sections),
        };
        if (exercise.privacySetting) {
          if (exercise.privacySetting === 'sharable_with_public' || exercise.privacySetting === 'sharable_with_instructor_or_class') {
            report.privacySetting = 'shared_with_class';
          } else {
            report.privacySetting = _.first(exercise.privacyOptions);
          }
        } else {
          Sentry.configureScope((scope) => {
            scope.setExtras({
              user_id: CurrentUserManager.user.id,
              exercise_id: exercise.id,
            });
          });
          Sentry.captureException(new Error('Submission Privacy: Missing Exercise'));
        }
        return deferred.resolve(report);
      });
      return deferred.promise;
    }

    static normalizeSubmissionData(submissionFromBackend) {
      submissionFromBackend.commentsCount = submissionFromBackend.postsCount;
      submissionFromBackend.numCommentsAndReplies = submissionFromBackend.numPostsAndComments;

      return submissionFromBackend;
    }

    constructor(attributes) {
      this.submittingObject = attributes.submitting;

      _.extend(this, attributes, {
        // data
        saving: false,
        submitting: false,
        commentsPageSize: 50,
        commentsDeletedByUser: [],
        likers: [],
        flag: [],
      });

      this.catalogId = this.catalogId || (this.exercise?.catalogId);
      this.isTranslationOn = false;
      this.isTranslationLoading = false;
      this.originalTranslationPreferenceLanguage = CurrentUserManager.user.translationPreferenceLanguage;
      this.translatedSections = [];

      this.__initializeFlags();
    }

    /* Private Functions - Start */
    __initializeFlags() {
      this.flags = [];
      _.each(FLAG_OPTIONS, (flagName) => {
        let flagModel = null;
        const existingFlag = _.find(this.flags, { flag: flagName });

        if (existingFlag) {
          flagModel = new FlaggingModel(angular.merge(existingFlag, { name: flagName }));
          flagModel.flagged = true;
        } else {
          flagModel = new FlaggingModel({ name: flagName });
        }

        this.flags.push(flagModel);
      });
    }

    __attributesForRequestBody(exerciseId) {
      const reportAnswers = {};
      _.each(this.currentRevision.sections, (section) => {
        const { reportSection } = section;

        if (reportSection?.file) {
          if (reportSection.id) {
            section.oldSectionId = reportSection.id;
          } else {
            section.newFile = {
              fileName: reportSection.file.name,
              fileType: reportSection.file.type,
              fileSize: reportSection.file.size,
            };

            if (reportSection.file.lastModifiedDate) {
              section.newFile.fileUpdatedAt = reportSection.file.lastModifiedDate.toISOString();
            }
          }

          reportAnswers[section.id] = {
            oldSectionId: section.oldSectionId,
            newFile: section.newFile,
            uniqueId: reportSection.uniqueId,
          };
        } else if (reportSection?.response) {
          reportAnswers[section.id] = reportSection.response;
        }
      });

      // start: remove after submission privacy bug fixed
      // there is a bug where we set the privacy setting to one that is not allowed
      if (!this.exercise
          || !this.privacySetting
          || (this.privacySetting === 'shared_with_instructor' && _.contains(['sharable_with_class_only', 'sharable_with_public_only'], this.exercise.privacySetting))) {
        Sentry.configureScope((scope) => {
          scope.setExtras({
            user_id: CurrentUserManager.user.id,
            exercise_id: exerciseId,
            exercise_privacy_setting: this.exercise ? this.exercise.privacySetting : 'no exercise',
            exercise_privacy_options: this.exercise.privacyOptions,
            report_id: this.id || 'new report',
            report_privacy_setting: this.privacySetting,
            report_previous_privacy_setting: this.__previousPrivacySetting,
            original_exercise_privacy_setting: this.__originalExercisePrivacySetting,
            original_exercise_privacy_options: this.__originalExercisePrivacyOptions,
            original_default_privacy_setting: this.__originalReportPrivacySetting,
            reportCreatedInNewReport: this.createdInNewReport,
          });
        });

        Sentry.captureException(new Error('Submission Privacy: Submit - Privacy Mismatch'));
      }
      // end: remove after submission privacy bug fixed

      return {
        exerciseId: exerciseId || this.exercise.id,
        title: this.title,
        reportAnswers,
        report: {
          privacySetting: this.privacySetting,
        },
      };
    }


    /* Public Functions - Start */
    flagSubmission(flagModel) {
      if (flagModel.flagged) {
        ReportsResources.unflagSubmission({ catalogId: this.catalogId, flagId: flagModel.id }).$promise.then(() => {
          flagModel.flagged = !flagModel.flagged;
        });
      } else {
        ReportsResources.flagSubmission({
          catalogId: this.catalogId, flaggableType: 'Report', flag: flagModel.name, flaggableId: this.id,
        }, {}).$promise.then((response) => {
          flagModel.flagged = !flagModel.flagged;
          flagModel.id = response.result.id;
        });
      }
    }

    hasFlaggedOption() {
      return !!_.findWhere(this.flags, { flagged: true });
    }

    updatePrivacy(privacyFlag) {
      ReportsResources.updatePrivacy({ catalogId: this.catalogId, reportId: this.id, privacySetting: privacyFlag }, {}).$promise.then(() => {
        this.privacySetting = privacyFlag;
      });
    }

    like() {
      ReportsResources.likeSubmission({ catalogId: this.catalogId, reportId: this.id }).$promise.then((response) => {
        this.votesCount = response.result.numLikes;
      });
    }

    unlike() {
      ReportsResources.unlikeSubmission({ catalogId: this.catalogId, reportId: this.id }).$promise.then((response) => {
        this.votesCount = response.result.numLikes;
      });
    }

    privacySettingAllowed(internalName) {
      return _.indexOf(this.allowedPrivacyOptions, internalName) > -1 || this.privacySetting === internalName;
    }

    updateLikes() {
      if (this.liked) {
        this.unlike();
      } else {
        this.like();
      }

      this.liked = !this.liked;
    }

    save(catalogId, exerciseId) {
      this.saving = true;
      this.error = null;

      if (this.isPersisted()) {
        return ReportsResources.update(
          {
            catalogId: catalogId || CurrentUserManager.coursesHash[this.exercise.courseId].catalogId,
            reportId: this.id,
          },
          this.__attributesForRequestBody(exerciseId),
        ).$promise.then((response) => {
          const { sections } = this.currentRevision;

          _.extend(this, _.pick(response.result, 'editable'));
          _.extend(this.currentRevision, response.result.currentRevision);
          _.extend(this.exercise, response.result.exercise);
          this.currentRevision.sections = sections;

          this.saving = false;
          store.dispatch(updateLectureComponentFromAngular({
            exercise: response.result.exercise,
          }));
        }, (response) => {
          if (response.data.error) {
            this.error = response.data.error;
          }

          this.saving = false;
        });
      }
      return ReportsResources.save(
        {
          catalogId: catalogId || CurrentUserManager.coursesHash[this.exercise.courseId].catalogId,
        },
        this.__attributesForRequestBody(exerciseId),
      ).$promise.then((response) => {
        const { sections } = this.currentRevision;
        store.dispatch(updateLectureComponentFromAngular({
          exercise: response.result.exercise,
        }));
        // - we have a one time load that looks at the old sections.
        // - todo: refactor so we don't look at the old sections
        _.extend(this, _.pick(response.result, 'id', 'editable'));
        _.extend(this.currentRevision, response.result.currentRevision);
        _.extend(this.exercise, response.result.exercise);
        this.currentRevision.sections = sections;


        const existingDraft = _.findWhere(this.exercise.unsubmittedSubmissions, { id: this.id });

        if (!existingDraft) {
          this.exercise.unsubmittedSubmissions.push(this);
        } else {
          _.extend(existingDraft, this);
        }
        this.saving = false;
      }, (response) => {
        if (response.data.error) {
          this.error = response.data.error;
        }

        this.saving = false;
      });
    }

    __afterSubmit(report, existingPoints) {
      const sections = this.currentRevision.sections.map(({ id, templateSection, reportSection }) => ({ id, templateSection: { ...templateSection }, reportSection: { ...reportSection } }));

      _.extend(this.exercise, _.omit(report.exercise, 'submissions', 'collaborators', 'currentTeam', 'assignmentTeam'));

      store.dispatch(updateLectureComponentFromAngular({
        exercise: report.exercise,
      }));

      // manually setting recentSubmitters so that the one-way bind will pick up changes
      this.exercise.recentSubmitters = report.exercise.recentSubmitters;

      _.extend(this, _.omit(report, 'exercise', 'submitting'));

      this.submittingObject = report.submitting;

      this.currentRevision.sections = sections;

      const existingSubmissionIndex = _.findIndex(this.exercise.submissions, { id: this.id });
      if (existingSubmissionIndex > -1) {
        this.exercise.submissions[existingSubmissionIndex] = this;
      } else {
        this.exercise.submissions.push(this);
      }

      if (existingPoints < this.pointsReceived) {
        this.newPointsReceived = this.pointsReceived;
      } else {
        this.newPointsReceived = null;
      }
    }

    submit(catalogId, exerciseId) {
      const existingPoints = this.exercise.pointsReceived;

      this.submitting = true;
      this.error = null;

      if (this.isPersisted()) {
        return ReportsResources.update(
          {
            catalogId: catalogId || CurrentUserManager.coursesHash[this.exercise.courseId].catalogId,
            reportId: this.id,
            submitReport: true,
          },
          this.__attributesForRequestBody(exerciseId),
        ).$promise.then((response) => {
          const report = response.result;

          if (response.error) {
            this.error = response.error;
          }

          this.__afterSubmit(report, existingPoints);

          this.submitting = false;

          return this;
        }, (response) => {
          if (response.data?.error) {
            this.error = response.data.error;

            if (this.error.code === 'report.already_approved') {
              this.editable = false;
              this.submittingObject.submissionStatus = APPROVED;
              this.exercise.progress = APPROVED;
            }
          }
          this.submitting = false;
          return $q.reject(response);
        });
      }
      return ReportsResources.save(
        {
          catalogId: catalogId || CurrentUserManager.coursesHash[this.exercise.courseId].catalogId,
          submitReport: true,
        },
        this.__attributesForRequestBody(exerciseId),
      ).$promise.then((response) => {
        const report = response.result;

        if (response.error) {
          this.error = response.error;
        }

        this.__afterSubmit(report, existingPoints);

        this.submitting = false;

        return this;
      }, (response) => {
        if (response.data?.error) {
          this.error = response.data.error;
        }
        this.submitting = false;

        return $q.reject(response);
      });
    }

    sectionForTemplate(templateSection) {
      return _.findWhere(this.currentRevision.sections, { reportTemplateSectionId: templateSection.id });
    }

    hasTemplateSectionAnswer(templateSection) {
      const answer = this.sectionForTemplate(templateSection);
      return answer && (_.keys(answer).length || answer.response.length);
    }

    isOnlySharableWithInstructor(exercise) {
      return exercise?.privacySetting === 'sharable_with_instructor';
    }

    isSharedPublicly() {
      return this.privacySetting === 'shared_with_public';
    }

    selectOption(option, form) {
      this.__previousPrivacySetting = this.privacySetting;
      this.privacySetting = option;

      if (form) {
        form.$setDirty();
      }
    }

    isPersisted() {
      return !!this.id;
    }

    isEmbeddableVideoLink(url) {
      // we can iframe youtube and vimeo links by manipulating the urls, otherwise will just be a link to view
      if (url.match(/youtu\.be/) || url.match(/youtube\.com/)) {
        return 'youtube';
      }
      return url.match(/vimeo\.com\/([^/]*)/) ? 'vimeo' : null;
    }

    getEmbeddableYoutubeLink(url) {
      const youtubeVideoId = nvUtil.parseVideoId(url, /youtu\.be\/([^?]*)/, 1)
                              || nvUtil.parseVideoId(url, /^.*(embed\/)([^&?]*)/, 2)
                              || nvUtil.parseVideoId(url, /^.*(watch\?)(.*)[?&]?v=([^&?]*).*/, 3);

      // console.log('youtube video id, youtubeVideoId');

      return $sce.trustAsResourceUrl(`https://www.youtube.com/embed/${youtubeVideoId}?vq=auto&rel=0&showinfo=0&autohide=1&wmode=transparent`);
    }

    getEmbeddableVimeoLink(url) {
      const vimeoVideoId = nvUtil.parseVideoId(url, /vimeo\.com\/([^/]\d+)/, 1)
                            || nvUtil.parseVideoId(url, /vimeo\.com\/(\D*)(\d+)/, 2)
                            || nvUtil.parseVideoId(url, /player\.vimeo\.com\/video\/([^?]*)/, 1);

      // console.log('vimeo video id', vimeoVideoId);

      return $sce.trustAsResourceUrl(`https://player.vimeo.com/video/${vimeoVideoId}?player_id=${vimeoVideoId}`);
    }

    loadPreviousComments() {
      return CommentModel.getCommentsList({
        catalogId: this.catalogId,
        pageSize: this.commentsPageSize,
        reportId: this.id,
        owner: this,
        beforeId: _.first(this.comments).id,
      }).then((response) => {
        _.each(response.commentsList.reverse(), (comment) => {
          this.comments.unshift(
            new CommentModel(_.extend(comment, {
              catalogId: this.catalogId, owner: this, replyCount: comment.commentsCount,
            })),
          );
        });

        this.additionalCommentsBeforeCount = response.additionalPostsBeforeCount;
        this.additionalNewCommentsBeforeCount = response.additionalNewPostsBeforeCount;
      });
    }

    loadComments(betweenId = null) {
      return CommentModel.getCommentsList({
        catalogId: this.catalogId,
        pageSize: this.commentsPageSize,
        reportId: this.id,
        owner: this,
        betweenId,
      }).then((response) => {
        this.comments = _.map(response.commentsList, (comment) => new CommentModel(
          _.extend(comment, { catalogId: this.catalogId, owner: this, replyCount: comment.commentsCount }),
        ));

        this.additionalNewCommentsAfterCount = response.additionalNewPostsAfterCount;
        this.additionalNewCommentsBeforeCount = response.additionalNewPostsBeforeCount;
        this.additionalCommentsAfterCount = response.additionalPostsAfterCount;
        this.additionalCommentsBeforeCount = response.additionalPostsBeforeCount;
      });
    }

    loadNextComments() {
      return CommentModel.getCommentsList({
        catalogId: this.catalogId,
        pageSize: this.commentsPageSize,
        reportId: this.id,
        owner: this,
        afterId: _.last(this.comments).id,
      }).then((response) => {
        _.each(response.commentsList, (comment) => {
          this.comments.push(
            new CommentModel(_.extend(comment, {
              catalogId: this.catalogId, owner: this, replyCount: comment.commentsCount,
            })),
          );
        });

        this.additionalNewCommentsAfterCount = response.additionalNewPostsAfterCount;
        this.additionalCommentsAfterCount = response.additionalPostsAfterCount;
      });
    }

    createComment(newComment) {
      return CommentModel.create({
        catalogId: this.catalogId,
        ownerId: this.id,
        ownerType: 'report',
        content: newComment,
      }).then((response) => {
        if (response) {
          _.extend(this, _.pick(response.result.owner, ['postsCount', 'numPostsAndComments']));

          if (response.result.peerEvaluation && this.peerEvaluation) {
            _.extend(this.peerEvaluation, _.pick(response.result.peerEvaluation, ['pointsReceived']));
          }

          this.latestComment = response.result;
          const commentModel = new CommentModel(_.extend(response.result, { catalogId: this.catalogId, owner: this }));
          this.comments.push(commentModel);
          this.posted = commentModel.belongsToCurrentUser();

          PubSubDiscussions.publish('comment.create', {
            comment: commentModel,
            ownerType: 'report',
          });

          this.numCommentsAndReplies += 1;

          return commentModel;
        }

        return null;
      }).catch(
        (error) => $q.reject(error),
      );
    }

    updateComment(updatedComment) {
      return updatedComment.update();
    }

    removeComment(comment) {
      this.commentsDeletedByUser.push(comment.id);
      return comment.remove({
        catalogId: this.catalogId,
        id: comment.id,
      }).then((deletedComment) => {
        this.removeCommentData(comment);

        PubSubDiscussions.publish('comment.delete', {
          comment,
          ownerType: 'report',
        });

        return deletedComment;
      });
    }

    removeCommentData(deletedComment) {
      const indexOfToDelete = _.findIndex(this.comments, { id: deletedComment.id });
      if (indexOfToDelete > -1) {
        this.comments.splice(indexOfToDelete, 1);
        this.posted = _.some(this.comments, (comment) => comment.user.id === CurrentUserManager.user.id);
        if (deletedComment.replyCount) {
          this.numCommentsAndReplies -= (deletedComment.replyCount + 1);
        } else {
          this.numCommentsAndReplies -= 1;
        }
      }
    }

    userHasRemovedComment(commentId) {
      return _.contains(this.commentsDeletedByUser, commentId);
    }

    getLikersInfo(params) {
      return ReportsResources.getLikersInfo({
        catalogId: this.catalogId,
        reportId: this.id,
        page: params?.page ? params.page : 1,
      }).$promise.then((response) => {
        this.likers = response.result;
      });
    }

    belongsToCurrentUser() {
      return this.isCollaborator || this.id === CurrentUserManager.user.id;
    }

    hasSkillsRating() {
      return this.exercise?.hasSkillsRating
        // Even if the report has skill rating, there are some other conditions
        // to display the UI for skill rating. Else we should display the
        // default submission view
        && this.exercise?.skillsRatingFeedback?.released
        && this.exercise?.lectureComponent?.skillTaggings?.length > 0
        && (
          // Either this user is a learner who has submitted the assignment
          // Or this is an admin or a mentor
          this.exercise?.skillsRatingFeedback?.hasSubmittedRequirement
          || (
            CurrentPermissionsManager.isTeachingAssistant()
            || CurrentPermissionsManager.isInstructor()
            || this.isUserMentorOfCollaborators
          )
        );
    }

    isSavedAsDraft(exercise) {
      return exercise.unsubmittedSubmissions.length
          && _.findWhere(exercise.unsubmittedSubmissions, { id: this.id });
    }

    canLeaveFeedback() {
      return !this.isCollaborator
        && this.isPublicFeedbackOn
        && !this.gavePublicFeedback
        && this.exercise.customQuestions.released
        && !this.exercise.customQuestions.expired;
    }

    getMetaTags() {
      let submittersName = null;
      if (this.ownerType === 'User') {
        submittersName = this.owner.fullName;
      } else if (this.collaboratorsForPublic?.length > 0) {
        submittersName = _.map(this.collaboratorsForPublic, (item) => item.fullName).join(',');
      }

      return {
        'og:title': this.title,
        'og:description': `${this.title} submitted by ${submittersName}`,
      };
    }

    mergeSectionData(sections) {
      if (this.currentRevision?.sections) {
        return _.map(sections, (section) => ({
          id: section.id,
          reportSection: this.sectionForTemplate(section) || {},
          templateSection: section,
        }));
      }
      return _.map(sections, (section) => ({
        id: section.id,
        reportSection: {},
        templateSection: section,
      }));
    }

    /* Submission Approval - Start */
    approvalStatusKey() {
      if (this.isPendingApproval()) {
        return 'SUBMISSION_APPROVAL.SUBMISSION_STATUS.PENDING';
      } if (this.isRejectedCanRevise()) {
        return 'SUBMISSION_APPROVAL.SUBMISSION_STATUS.REJECTED';
      } if (this.isRejectedCannotRevise()) {
        return 'SUBMISSION_APPROVAL.SUBMISSION_STATUS.REJECTED_PAST';
      } if (this.isApproved()) {
        return 'SUBMISSION_APPROVAL.SUBMISSION_STATUS.APPROVED';
      }

      return null;
    }

    __exerciseIsMissed() {
      if (this.exercise.hardDeadline) {
        return moment(this.exercise.extendedDeadline || this.exercise.deadline) < moment();
      }
      return false;
    }

    isPendingApproval() {
      return this.submittingObject?.submissionStatus === PENDING_APPROVAL;
    }

    isRejectedCanRevise() {
      return this.submittingObject?.submissionStatus === REJECTED && !this.__exerciseIsMissed();
    }

    isRejectedCannotRevise() {
      return this.submittingObject?.submissionStatus === REJECTED && this.__exerciseIsMissed();
    }

    isCompleted() {
      return this.submittingObject?.submissionStatus === COMPLETED;
    }

    isApproved() {
      return this.submittingObject?.submissionStatus === APPROVED;
    }

    approveSubmission(comment = '', sharePublicly = false) {
      return ReportsResources.approveSubmission({ id: this.id }, {
        comment,
        isPrivate: !sharePublicly,
        currentRevision: this.currentRevision.id,
      }, (response) => {
        this.reviews = response.result.reviews;
        this.submittingObject = response.result.submitting;

        PubSubDiscussions.publish('report.reviewed', {
          report: this,
        });

        return response;
      }).$promise;
    }

    rejectSubmission(comment = '', sharePublicly = false) {
      return ReportsResources.rejectSubmission({ id: this.id }, {
        comment,
        isPrivate: !sharePublicly,
        currentRevision: this.currentRevision.id,
      }, (response) => {
        this.reviews = response.result.reviews;
        this.submittingObject = response.result.submitting;

        PubSubDiscussions.publish('report.reviewed', {
          report: this,
        });

        return response;
      }).$promise;
    }

    markViewed() {
      this.hasViewed = true;
      return ReportsResources.markViewed({ catalogId: this.catalogId || (this.exercise?.catalogId), id: this.id }, {}).$promise;
    }

    updateProgress(data) {
      if (this.exercise) {
        this.exercise.progress = data.progress;
      }

      if (this.submittingObject) {
        this.submittingObject.progress = data.progress;
      }
    }

    /* Submission Approval - End */
    onToggleTranslate(isStateOn, language) {
      this.isTranslationOn = isStateOn;

      if (isStateOn) {
        this.isTranslationLoading = true;
        return ReportsResources.translate(
          { catalogId: this.catalogId, id: this.id },
          { language },
        )
          .$promise.then(response => {
            this.titleTranslation = response.result.exerciseTitle;
            this.submissionTranslation = response.result.submissionTitle;
            const translatedSections = [];
            response.result.sections.forEach((section) => {
              translatedSections[section.reportTemplateSectionId] = {
                header: section.header,
                body: section.body,
              };
            });
            this.translatedSections = translatedSections;
          })
          .catch(() => {
            this.isTranslationOn = false;
            this.isTranslationLoading = false;
            AlertMessages.error(
              'DISCUSSIONS_AUTO_TRANSLATION.ERROR',
              'DISCUSSIONS_AUTO_TRANSLATION.RETRY',
            );
          })
          .finally(() => {
            // Setting a timeout to let angular render the texts properly.
            $timeout(() => {
              this.isTranslationLoading = false;
            }, 0);
          });
      }
      // Setting a timeout to let angular render the texts properly.
      $timeout(() => {
        this.isTranslationLoading = false;
      }, 0);
      return null;
    }

    getHeaderTranslated(sectionId) {
      return this.translatedSections[sectionId]?.header;
    }

    getBodyTranslated(sectionId) {
      return this.translatedSections[sectionId]?.body;
    }

    languageHasChanged() {
      if (this.originalTranslationPreferenceLanguage !== CurrentUserManager.user.translationPreferenceLanguage) {
        this.originalTranslationPreferenceLanguage = CurrentUserManager.user.translationPreferenceLanguage;
        return true;
      }
      return false;
    }
  }

  return ReportModel;
}
