import { css } from '@emotion/react';
import t from 'react-translate';
import { gray7, primary } from 'styles/global_defaults/colors';
import { semiBoldFontWeight } from 'styles/global_defaults/fonts';
import { halfSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';

const NoteTag = () => {
  const styles = css`
    color: ${primary};
    background-color: ${gray7};
    border-radius: 15px;
    width: fit-content;
    padding: ${quarterSpacing}px ${halfSpacing}px;
    font-weight: ${semiBoldFontWeight};
  `;

  return (
    <div css={styles} className='note-tag'>{t.LHS.BOOKMARKS.NOTE()}</div>
  );
};

export default NoteTag;
