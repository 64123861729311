import React from 'react';

/**
 * React hook that returns a function that allows you to force a re-render in a
 * component.
 */
/* @ngInject */
export default function useForceUpdate() {
  const [value, setValue] = React.useState(true);
  return () => setValue(!value);
}
