import { css } from '@emotion/react';
import React from 'react';
import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import { doubleSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';

const NoAdmins = () => {
  const noAdminsStyle = css`
    text-align: center;
    margin-top: ${standardSpacing}px;
    margin-bottom: 160px;
    .icon {
      padding: ${doubleSpacing}px ${doubleSpacing}px ${standardSpacing}px;
      &.icon-large {
        font-size: 40px;
      }
    }
  `;
  return (
    <div className='no-admins text-gray-4' css={noAdminsStyle}>
      <NvIcon size='large' icon='profile' />
      <div className='font-weight-bolder text-gray-3'>
        {t.COURSES.CLONE.STEPS.STEP_TWO.NO_ADMINS()}
      </div>
    </div>
  );
};

export default NoAdmins;
