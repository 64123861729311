/* @ngInject */
export default function ConversationsResources($http) {
  return {
    createConversation(catalogId, recipientIds, recipientType, message) {
      return $http.post(
        `/${catalogId}/conversations/create/`,
        { message, recipientIds, recipientType },
      );
    },
    sendMessage(conversationId, messageBody) {
      return $http.post(
        `/conversations/edit/${conversationId}.json`,
        { message: { body: messageBody } },
      );
    },
    getConversations(params) {
      if (!params) {
        return $http.get('/conversations/filter_conversations.json');
      }
      return $http.get('/conversations/filter_conversations.json', { params });
    },
    getConversation(conversationId) {
      return $http.get('/conversations/conversation.json', { params: { id: conversationId } });
    },
    getMessages(conversationId, lastMessageId) {
      return $http.get('/conversations/conversation_messages.json',
        { params: { conversationId, lastMessageId } });
    },
    getMessage(messageId) {
      return $http.get('/conversations/message.json', { params: { id: messageId } });
    },
    toggleFavorite(messageId) {
      return $http.get(`/conversations/change_favorite.json?id=${messageId}`);
    },
    toggleBlockUser(messageId) {
      return $http.get(`/conversations/toggle_block_user.json?message_id=${messageId}`);
    },
    flagAsAbusive(messageId, catalogId) {
      return $http.post(
        `/${catalogId}/flaggings`,
        { flag: 'abuse', flaggableType: 'Message', flaggableId: messageId },
      );
    },
    flagAsSpam(messageId, catalogId) {
      return $http.post(
        `/${catalogId}/flaggings`,
        { flag: 'spam', flaggableType: 'Message', flaggableId: messageId },
      );
    },
    archive(conversationId) {
      return $http.get(`/conversations/change_archive.json?id=${conversationId}`);
    },
    read(conversationId) {
      return $http.get(`/conversations/set_read/${conversationId}.json`);
    },
  };
}
