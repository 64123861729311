/* @ngInject */
export default function LectureVideosBaseController(
  $stateParams,
  LectureVideosManager,
  CurrentUserManager,
  CurrentCourseManager,
  PageLevelManager,
  RailsRoutes,
  InteroperabilityRoutes,
  $controller,
  $scope,
  nvUtil,
) {
  const vm = angular.extend(this, $controller('L3HeaderCtrl', { $scope }));

  vm.manager = LectureVideosManager;
  vm.$stateParams = $stateParams;
  vm.PageLevelManager = PageLevelManager;
  vm.RailsRoutes = RailsRoutes;
  vm.InteroperabilityRoutes = InteroperabilityRoutes;
  vm.CurrentUserManager = CurrentUserManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.LectureVideosManager = LectureVideosManager;
  vm.nvUtil = nvUtil;

  vm.subtitleSref = 'lecture-page({ id: vm.LectureVideosManager.currentLectureVideoList.lecturePage.id })';
  vm.subtitleText = LectureVideosManager.currentLectureVideoList.lecturePage.title;
}
