/* @ngInject */
export default function ConfigureSSOModalCtrl(

  $scope,
  $uibModalInstance,
  humps,
  InstitutionsManager,
  config,
) {
  const vm = this;

  vm.InstitutionsManager = InstitutionsManager;
  vm.providerDrafts = [];
  vm.config = config;

  function initProviderDrafts() {
    vm.InstitutionsManager.institution.identityProviders.forEach((provider) => {
      vm.providerDrafts.push({
        name: provider.name,
        idpEntityId: provider.idpEntityId,
        accountInfoManagementLink: provider.accountInfoManagementLink,
        displayName: provider.displayName,
        description: provider.description,
        idpSsoTargetUrl: provider.idpSsoTargetUrl,
        idpSloTargetUrl: provider.idpSloTargetUrl,
        idpCert: provider.idpCert,
      });
    });
  }

  vm.closeModal = () => {
    initProviderDrafts();
    $uibModalInstance.dismiss('cancel');
  };

  vm.save = () => {
    vm.savingProviders = true;

    InstitutionsManager.saveProviders(vm.providerDrafts)
      .then(() => {
        vm.savingProviders = false;
        vm.providersForm.$setPristine();
      });
  };

  initProviderDrafts();
}
