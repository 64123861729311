import { getTeamsList, getAllUsersForCSV, getTeamInfo, getTeamsCount, getTeamMembers, addTeamMembers, deleteTeam, deleteTeamMember, updateTeamInfo, getTeamMentions, updateProgressDashboardForTeam, updateProgressDashboardForAllTeams, setTeamSet, moveTeamMembers, setCurrentTeamId, makeTeamAdmin, deleteTeamAdmin, makeGroupAdmin, deleteGroupAdmin, deleteAllTeams, bulkUpdateTeams } from 'redux/actions/teams';
import { normalize } from 'normalizr';
import { ITeamEntities, teamsSchema } from 'redux/schemas/api/team';
import { Team } from 'redux/schemas/models/team';
import { createReducer } from '@reduxjs/toolkit';
import { initialRootState } from '.';

export default createReducer(initialRootState, builder => {
  builder
    .addCase(getTeamsList.fulfilled, (state, action) => {
      if (action.meta.arg.filters?.type === 'team') {
        state.app.teamsUpdating = !!action.payload.response.threshold;
      } else {
        state.app.groupsUpdating = !!action.payload.response.threshold;
      }
      if (!action.payload.response.threshold) {
        const normalizedTeams = normalize<Team, ITeamEntities, 'name'>(
          action.payload.response,
          teamsSchema,
        );
        Object.assign(state.models.teams, normalizedTeams.entities.teams);
      }
    })
    .addCase(getAllUsersForCSV.fulfilled, (state, action) => {
      const { isTeam } = action.meta.arg;
      if (isTeam) {
        state.models.teamsForCSV = action.payload;
      } else {
        state.models.groupsForCSV = action.payload;
      }
    })
    .addCase(getTeamInfo.fulfilled, (state, action) => {
      const { teamId } = action.meta.arg;
      const members = state.models.teams[teamId]?.teamMembers;
      state.models.teams[teamId] = { ...state.models.teams[teamId], ...action.payload.response, teamMembers: members ?? [] };
    })
    .addCase(setCurrentTeamId, (state, action) => {
      state.app.manageTeam.currentTeamId = action.payload.teamId;
      state.app.manageTeam.isUploading = action.payload.isUploading;
    })
    .addCase(getTeamsCount.fulfilled, (state, action) => {
      state.app.teamCount = action.payload.teamSize;
      state.app.groupCount = action.payload.groupSize;
    })
    .addCase(getTeamMembers.fulfilled, (state, action) => {
      const { teamId, pageNumber } = action.meta.arg;
      if (pageNumber > 1) {
        state.models.teams[teamId].teamMembers.push(...action.payload);
      } else {
        state.models.teams[teamId].teamMembers = action.payload;
      }
      state.app.manageTeam.isUploading = false;
    })
    .addCase(addTeamMembers.fulfilled, (state, action) => {
      state.models.teams[action.payload.id].totalMembers = action.payload.totalMembers;
    })
    .addCase(moveTeamMembers.fulfilled, (state, action) => {
      const { teamId, currentTeamId, userIds, isTeam } = action.meta.arg;
      if (currentTeamId) {
        state.models.teams[currentTeamId].numApprovedMembers -= 1;
        const assignmentWithSubmissions = state.models.teams[currentTeamId].assignments?.find(assignment => !!assignment.lastSubmittedAt);
        if ((state.models.teams[currentTeamId].numApprovedMembers === 0 && !isTeam) || (isTeam && !assignmentWithSubmissions && state.models.teams[currentTeamId].numApprovedMembers === 0)) {
          delete state.models.teams[currentTeamId];
          if (!isTeam) {
            state.app.groupCount -= 1;
          } else {
            state.app.teamCount -= 1;
          }
        } else {
          state.models.teams[currentTeamId].teamMembers = state.models.teams[currentTeamId].teamMembers.filter(member => !userIds.includes(member.user.id));
        }
      } else {
        state.models.teams[teamId].teamMembers = action.payload.teamMembers;
        state.models.teams[teamId].numApprovedMembers = action.payload.totalMembers;
      }
    })
    .addCase(deleteTeam.fulfilled, (state, action) => {
      const { isTeam, teamId } = action.meta.arg;
      delete state.models.teams[teamId];
      if (isTeam) {
        state.app.teamCount -= 1;
      } else {
        state.app.groupCount -= 1;
      }
    })
    .addCase(deleteTeamMember.fulfilled, (state, action) => {
      const { teamId, teamMemberId } = action.meta.arg;
      state.models.teams[teamId].teamMembers = state.models.teams[teamId].teamMembers.filter(member => member.id !== teamMemberId);
      state.models.teams[teamId].numApprovedMembers -= 1;
    })
    .addCase(makeTeamAdmin.fulfilled, (state, action) => {
      const { teamId, teamMemberId } = action.meta.arg;
      if (action.payload) {
        state.models.teams[teamId].teamMembers.forEach(member => {
          if (member.id === teamMemberId) {
            member.isAdmin = true;
          } else {
            member.isAdmin = false;
          }
        });
      }
    })
    .addCase(deleteTeamAdmin.fulfilled, (state, action) => {
      const { teamId, teamMemberId } = action.meta.arg;
      state.models.teams[teamId].teamMembers.find(member => member.id === teamMemberId).isAdmin = action.payload.isAdmin;
    })
    .addCase(makeGroupAdmin.fulfilled, (state, action) => {
      const { teamId, teamMemberId } = action.meta.arg;
      state.models.teams[teamId].teamMembers.find(member => member.id === teamMemberId).isAdmin = action.payload.isAdmin;
    })
    .addCase(deleteGroupAdmin.fulfilled, (state, action) => {
      const { teamId, teamMemberId } = action.meta.arg;
      if (action.payload) {
        state.models.teams[teamId].teamMembers.find(member => member.id === teamMemberId).isAdmin = false;
      }
    })
    .addCase(updateTeamInfo.fulfilled, (state, action) => {
      const { teamId } = action.meta.arg;
      state.models.teams[teamId] = { ...state.models.teams[teamId], ...action.payload };
    })
    .addCase(getTeamMentions.fulfilled, (state, action) => {
      const { teamId } = action.meta.arg;
      state.models.teams[teamId] = {
        ...state.models.teams[teamId],
        mentionableMembers: Object.keys(action.payload.mentionableMembers),
      };
      state.models.mentionableMembers = action.payload.mentionableMembers;
    })
    .addCase(updateProgressDashboardForTeam.fulfilled, (state, action) => {
      state.models.teams[action.payload.id].hasProgressDashboard = action.payload.hasProgressDashboard;
    })
    .addCase(updateProgressDashboardForAllTeams.fulfilled, (state, action) => {
      const { catalogId, hasProgressDashboard, teamSetId } = action.meta.arg;
      const groupOrTeamSet = state.models.courses[catalogId].courseLongTeamSet?.id === teamSetId ? 'courseLongTeamSet' : 'groupTeamSet';
      state.models.courses[catalogId][groupOrTeamSet].hasProgressDashboard = !hasProgressDashboard;

      Object.values(state.models.teams).forEach(({ id: teamId, teamSet }) => {
        if (teamSet.id === teamSetId) {
          state.models.teams[teamId].hasProgressDashboard = !hasProgressDashboard;
        }
      });
    })
    .addCase(deleteAllTeams.pending, (state, action) => {
      const { isTeam } = action.meta.arg.translationValues;
      if (isTeam) {
        state.app.teamCount = 0;
        state.app.teamsUpdating = true;
      } else {
        state.app.groupCount = 0;
        state.app.groupsUpdating = true;
      }
    })
    .addCase(deleteAllTeams.fulfilled, (state, action) => {
      const { teamSetId } = action.meta.arg;
      state.models.teams = Object.fromEntries(Object.entries(state.models.teams).filter(([teamId, team]) => team.teamSet.id !== teamSetId));
      if (state.models.teamSets[teamSetId]) {
        state.models.teamSets[teamSetId].teams = [];
      }
      if (action.payload.isTeam) {
        state.app.teamCount = 0;
        state.app.teamsUpdating = false;
      } else {
        state.app.groupCount = 0;
        state.app.groupsUpdating = false;
      }
    })
    .addCase(bulkUpdateTeams.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }
      const { teamSetId } = action.meta.arg;
      const { isTeam } = action.meta.arg.translationValues;
      if (state.models.teamSets[teamSetId]) {
        state.models.teamSets[teamSetId].teams = [];
      }
      if (action.payload.threshold) {
        if (isTeam) {
          state.app.teamsUpdating = true;
        } else {
          state.app.groupsUpdating = true;
        }
      } else {
        const otherTeams = Object.fromEntries(Object.entries(state.models.teams).filter(([teamId, team]) => team.teamSet.id !== teamSetId));
        const normalizedTeams = normalize<Team, ITeamEntities, 'name'>(
          action.payload,
          teamsSchema,
        );
        state.models.teams = { ...otherTeams, ...normalizedTeams.entities.teams };
        if (state.models.teamSets[teamSetId]) {
          state.models.teamSets[teamSetId].teams = Object.values({ ...otherTeams, ...normalizedTeams.entities.teams });
        }
        if (isTeam) {
          state.app.teamCount = action.payload.length;
        } else {
          state.app.groupCount = action.payload.length;
        }
      }
    })
    .addCase(setTeamSet, (state, action) => {
      if (action.payload.teamSet.isTeam) {
        state.models.courses[state.app.currentCatalogId].courseLongTeamSet = action.payload.teamSet;
        state.models.courses[state.app.currentCatalogId].teamName = action.payload.teamSet.name;
      } else {
        state.models.courses[state.app.currentCatalogId].groupTeamSet = action.payload.teamSet;
        state.models.courses[state.app.currentCatalogId].groupName = action.payload.teamSet.name;
      }
    });
});
