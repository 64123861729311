/* @ngInject */
export default function NvClickToEditText(
  $timeout,
  $rootScope,
  $document,
) {
  return {
    restrict: 'A',
    require: ['nvClickToEditText', 'ngModel'],
    scope: {
      ngModel: '=',
      form: '=?',
      name: '@',
      label: '@?',
      placeholder: '@?',
      required: '=?',
      maxLength: '=?',
      startSelected: '=?',
      customStyling: '=?',
      onBlurChanged: '&?',
      onInputBlur: '&?',
      onInputFocus: '&?',
      ngDisabled: '=?',
      hideLabel: '=?',
    },
    controller: function ctrl($scope, $element, $attrs) {
'ngInject';
      const vm = this;
      let previousVal;

      vm.inputVisible = false;
      vm.focused = false;
      vm.showInput = showInput;

      vm.enterKeyHandler = (event) => {
        if (event.keyCode && event.keyCode === 13) {
          event.stopPropagation();
          event.preventDefault();

          if (vm.inputVisible && $document[0].activeElement === event.target) {
            event.target.blur();
          } else {
            showInput(true, false);
          }
        }

        return false;
      };

      vm.onBlur = ($event) => {
        if (vm.ngModel?.trim()) {
          hideInput();
        }

        vm.focused = false;
      };

      vm.clickLabel = (event) => {
        event.stopPropagation();
        event.preventDefault();
      };

      // have to use jquery to deal with iOS
      function showInput(focused, selected) {
        const $formGroup = $element.find('.form-group');
        const $input = $element.find('.form-control');

        vm.inputVisible = true;
        $formGroup.show();

        vm.focused = focused;
        if (focused) {
          $input.focus();

          if (selected) {
            $input.select();
          }
        }

        if (vm.onInputFocus) vm.onInputFocus();
        previousVal = vm.ngModel;
      }

      function hideInput() {
        const $formGroup = $element.find('.form-group');

        vm.inputVisible = false;
        $formGroup.hide();
        if (vm.ngModel !== previousVal) {
          vm.onBlurChanged?.();
        }
        if (vm.onInputBlur) vm.onInputBlur();
      }
    },
    link: (scope, element, attrs, ctrls) => {
      const [ctrl, ngModelCtrl] = ctrls;

      if (!ctrl.ngModel || ctrl.startSelected) {
        $timeout(() => {
          ctrl.showInput(ctrl.startSelected, ctrl.startSelected);
        });
      }

      element.on('click', '.response', { ctrlScope: scope.vm }, (event) => {
        if (!event.data.ctrlScope.ngDisabled) {
          ctrl.showInput(true, false);
        }
      });


      ngModelCtrl.$render = () => {
        if (ctrl.ngModel && !ngModelCtrl.$modelValue) {
          $timeout(() => {
            ctrl.showInput();
          });
        }
      };
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'shared/templates/nv-click-to-edit-text.html',
  };
}
