import { useEffect } from 'react';
import { css } from '@emotion/react';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';

// Components
import { getFullCourseTimelineContent } from 'redux/actions/timeline';
import Header from './header';
import ItemsList from '../select-component/list';
import ContentList from '../select-content-source/list';
import ContentRender from '../components';

const styles = css`
  & {
    gap: ${standardSpacing}px;
  }

  .contents-wrapper {
    min-width: 280px;
    max-width: 340px;
    display: flex;
    flex-direction: column;
    gap: ${tripleSpacing}px;
  }
`;

const NovoAiFlyout = () => {
  const dispatch = useAppDispatch();

  const selectedItemType = useSelector(state => state.app.lecturePage.novoAI.itemType);
  const selectedContentId = useSelector(state => state.app.lecturePage.novoAI.content?.id);
  const lectureComponentToReplace = useSelector(state => state.app.lecturePage.novoAI?.lectureComponentToReplace);

  const params = useLecturePageParams();

  useEffect(() => {
    // This is to get timeline details in redux store with section and subsection types.
    dispatch(getFullCourseTimelineContent({
      catalogId: params.catalogId,
      isFromLhs: true,
    }));
  }, [dispatch, params.catalogId]);

  return (
    <div
      className='d-flex flex-column align-items-center w-100'
      css={styles}
    >
      <Header />
      <div className='contents-wrapper mx-4'>
        {!lectureComponentToReplace && <ItemsList />}
        {selectedItemType && <ContentList />}
        {selectedItemType && selectedContentId && <ContentRender itemType={selectedItemType} />}
      </div>
    </div>
  );
};

export default NovoAiFlyout;
