import React from 'react';
import * as yup from 'yup';
import uuid from 'react-uuid';
import omit from 'lodash/omit';
import { range } from 'underscore';
import { css } from '@emotion/react';
import { Omit } from 'utility-types';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, FormProvider } from 'react-hook-form';

import t from 'react-translate';
import { RootState } from 'redux/schemas';
import { useAppDispatch } from 'redux/store';
import { AngularServicesContext } from 'react-app';
import { Course } from 'redux/schemas/models/course';
import { Institution } from 'redux/schemas/models/institution';
import { getInstitutionData } from 'redux/actions/institutions';
import { getName, getInputType } from 'org_level_profile/utils';
import { warning, success } from 'styles/global_defaults/colors';
import CourseCard from 'org_level_profile/components/course-card';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { OrgProfileField } from 'redux/schemas/models/account-fields';
import ProfilePicture from 'org_level_profile/components/profile-picture';
import UserNameClickToEdit from 'org_level_profile/components/user-name-click-to-edit';
import {
  getBasicUserInfo,
  setProfilePicture,
  updateBasicUserProfile,
} from 'redux/actions/users';
import {
  getUser,
  getCurrentUser,
  getUserEnrollments,
  getUserProfileDetails,
  getOrgProfilePracticeSubmissions,
} from 'redux/selectors/users';
import EditableOrgLevelField, {
  Variant,
  FormEditableOrgLevelField,
} from 'org_level_profile/components/editable-org-level-field';
import {
  halfSpacing,
  tripleSpacing,
  doubleSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
  largeSpacing,
} from 'styles/global_defaults/scaffolding';
import {
  handheld,
  notHandheld,
  screenXsMax,
} from 'styles/global_defaults/media-queries';
import { getProfileSubmissions } from 'redux/actions/video-practice';
import VideoPracticeGalleryCard from 'lecture_pages/components/video-practice/video-practice-gallery-card';
import NvSubmissionGallery from 'shared/components/submission/nv-submission-gallery';
import NvGalleryCardPlaceholder from 'shared/components/nv-gallery-card-placeholder';

const loadingGalleryCards = range(5).map(i => <NvGalleryCardPlaceholder key={i} />);

/**
 * I think it's not worth to place this selector in a selector specific file
 * because here's the only place it's going to be used due to the specific shape
 * of this form.
 */
const profileFormSelector = (state: RootState, demandedUserId: number) => {
  const demandedUser = getUser(state, demandedUserId);
  const currentInstitution = getCurrentInstitution(state);
  const demandedUserProfileDetails = getUserProfileDetails(
    state,
    demandedUserId,
  );

  const {
    lastName,
    firstName,
  } = demandedUser;

  const getAccountLevelField = (
    fieldIdentifier: string,
  ) => {
    const {
      isHidden,
      isIntegrated,
    } = currentInstitution.profileSettings.accountLevel[fieldIdentifier];

    return {
      isHidden,
      id: uuid(),
      isIntegrated,
      picture: null,
      selectOptions: [],
      migratedField: null,
      question: getName(fieldIdentifier),
      inputType: getInputType(fieldIdentifier),
      answer: demandedUser[
        fieldIdentifier === 'location' ? 'displayLocation' : fieldIdentifier
      ],
      viewOptions: {
        iconColor: warning,
        icon: fieldIdentifier === 'location' ? 'location' : null,
      },
    };
  };

  return {
    user: {
      lastName,
      firstName,
      bio: getAccountLevelField('bio'),
      headline: getAccountLevelField('headline'),
      location: getAccountLevelField('location'),
    },
    orgFields: demandedUserProfileDetails,
  };
};

const convertFormToRequestData = (form) => ({
  user: {
    bio: form.user.bio.answer,
    lastName: form.user.lastName,
    firstName: form.user.firstName,
    headline: form.user.headline.answer,
    location: form.user.location.answer,
  },
  profileResponse: {
    answers: form.orgFields.map((profileDetail) => omit({
      ...profileDetail,
      /**
       * Backend expects the question id as "profileSettingId"
       */
      profileSettingId: profileDetail.questionId,
    }, ['questionId'])),
  },
});

const INITIAL_COURSES_TO_SHOW = 6;
const COURSES_QUANTITY_HEIGHT = 64;
const NOT_HANDHELD_COURSES_PER_ROW = 3;

type SavingDictionary = {
  [fieldName: string]: boolean,
};

type Props = {
  userId: number, // Demanded user id
};

const Form = (props: Props) => {
  const {
    userId,
  } = props;

  // These are the only fields that need validation for this configuration modal
  const validationSchemaRef = React.useRef(yup.object().shape({
    user: yup.object().shape({
      lastName: yup.string().ensure().min(1, t.VALIDATION.REQUIRED()),
      firstName: yup.string().ensure().min(1, t.VALIDATION.REQUIRED()),
    }),
  }));

  const dispatch = useAppDispatch();
  const currentUser = useSelector(getCurrentUser);
  const currentInstitution = useSelector(getCurrentInstitution);
  const [viewAllCourses, setViewAllCourses] = React.useState(false);
  const [isUploadingPicture, setIsUploadingPicture] = React.useState(false);
  const [galleryCards, setGalleryCards] = React.useState<JSX.Element[]>();
  const [submissionsLoading, setSubmissionsLoading] = React.useState(false);
  const [practiceSubmissionPageNo, setPracticeSubmissionPage] = React.useState(1);
  const {
    $scope,
    $state,
    CurrentUserManager,
  } = React.useContext(AngularServicesContext);

  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const [
    savingFields,
    setSavingFields,
  ] = React.useState<SavingDictionary>({});

  const {
    brandColor,
    profileSettings,
    brandBarFontColor,
    name: institutionName,
  } = useSelector<RootState, Institution>(getCurrentInstitution);

  const isCurrentUserOrgAdmin = currentInstitution.isInstitutionalAdmin || currentUser.admin;

  const demandedUser = useSelector((state: RootState) => getUser(
    state,
    userId,
  ));

  const userEnrollments = useSelector((state: RootState) => getUserEnrollments(
    state,
    userId,
  ));

  const initialFormData = useSelector((state: RootState) => profileFormSelector(
    state,
    userId,
  ));

  const {
    submissions: practiceSubmissions,
    totalCount: totalPracticesCount,
    isOnceLoaded: isPracticeSubmissionsOnceLoaded,
    hasMore: hasMoreVideoPractices,
    isLoading: practiceSubmissionsFetching,
  } = useSelector((state: RootState) => (
    getOrgProfilePracticeSubmissions(state, userId)
  ));

  const methods = useForm({
    mode: 'onChange',
    defaultValues: initialFormData,
    resolver: yupResolver(validationSchemaRef.current),
  });

  const { watch, control, formState, getValues } = methods;

  /**
   * Setting initial user name for sticky header.
   */
  React.useEffect(() => {
    $scope.OrgLevelProfileManager.setUserName(demandedUser.fullName);
  }, []);

  React.useEffect(() => {
    setSubmissionsLoading(true);
    if (!isPracticeSubmissionsOnceLoaded) {
      setGalleryCards(loadingGalleryCards);
    }
    dispatch(getProfileSubmissions({ userId, page: practiceSubmissionPageNo }));
  }, [dispatch, practiceSubmissionPageNo]);

  React.useEffect(() => {
    if (isPracticeSubmissionsOnceLoaded && practiceSubmissions?.length > 0) {
      let cards: JSX.Element[] = [];

      cards = practiceSubmissions.map((submission, i) => (
        <VideoPracticeGalleryCard
          key={submission.id}
          {...submission}
          title={t.PROFILE.GALLERY_CARD_TITLE(submission?.userPracticeNumber, submission?.scenario?.title)}
        />
      ));
      if (practiceSubmissionsFetching && practiceSubmissionPageNo > 1) {
        cards.push(
          <NvGalleryCardPlaceholder key='loading' />,
        );
      }
      setSubmissionsLoading(false);
      setGalleryCards(cards);
    }
  }, [isPracticeSubmissionsOnceLoaded, hasMoreVideoPractices, practiceSubmissionsFetching]);

  const loadMorePracticeSubmissions = () => {
    if (!practiceSubmissionsFetching && hasMoreVideoPractices && !submissionsLoading) {
      const page = practiceSubmissionPageNo + 1;
      setPracticeSubmissionPage(page);
    }
  };

  const save = (data, fieldIdentifier: number | string | string[]) => {
    const savingFieldsData = {
      ...savingFields,
    };

    const multipleFields = Array.isArray(fieldIdentifier);

    if (multipleFields) {
      (fieldIdentifier as string[]).forEach((singleFieldIdentifier) => {
        savingFieldsData[singleFieldIdentifier] = true;
      });
    } else {
      savingFieldsData[fieldIdentifier as number | string] = true;
    }

    setSavingFields(savingFieldsData);

    return dispatch(
      updateBasicUserProfile(
        convertFormToRequestData(data),
      ),
    ).then((action) => {
      // TODO: I think this [fieldIdentifier] is a bug, because it trips up the type checking on _.omit()
      setSavingFields(omit(savingFields, multipleFields ? fieldIdentifier : [fieldIdentifier] as any));

      return action;
    });
  };

  const handleAccountLevelFieldChange = (
    fieldName: string | string[],
    isUserName: boolean = false,
  ) => () => {
    const userForm = getValues().user;

    const userData = {
      ...initialFormData.user,
    };

    if (Array.isArray(fieldName)) {
      fieldName.forEach((singleFieldName) => {
        userData[singleFieldName] = userForm[singleFieldName];
      });
    } else {
      userData[fieldName] = userForm[fieldName];
    }

    const data = {
      ...initialFormData,
      user: userData,
    };

    save(data, fieldName).then((action) => {
      if (isUserName) {
        /**
         * If what has been changed is the user name we are going to update the
         * AngularJs model.
         */

        const {
          lastName,
          firstName,
        } = action.meta.arg.user;

        const fullName = `${firstName} ${lastName}`;

        $scope.OrgLevelProfileManager.setUserName(fullName);

        CurrentUserManager.user.updateFromReact({
          ...action.meta.arg.user,
          fullName,
        });
      }
    });
  };

  const handleOrgFieldChange = (fieldId: number) => () => {
    const form = getValues();

    save(form, fieldId);
  };

  const styles = css`
    padding-bottom: 200px;

    .header-container {
      display: flex;
      justify-content: center;
      background-color: ${brandColor};
      margin-bottom: ${tripleSpacing}px;
      ${handheld(css`
        height: 185px;
      `)};
      ${notHandheld(css`
        height: 160px;
      `)};

      .header {
        width: 100%;
        max-width: 680px;
        padding-left: ${standardSpacing}px;
        padding-right: ${standardSpacing}px;
      }

      .profile-picture {
        flex-shrink: 0;
      }

      .institution-name {
        width: 100%;
        flex-shrink: 0;
        display: block;
        color: ${brandBarFontColor};
      }

      .user-name {
        width: 100%;
        flex-shrink: 0;
        display: block;
      }

      .horizontal-header {
        padding-top: ${doubleSpacing}px;
        ${handheld(css`
          display: none;
        `)};
        ${notHandheld(css`
          display: flex;
        `)};

        .profile-picture {
          margin-right: ${tripleSpacing}px;
        }

        .horizontal-header-right{
          flex: 1;
          min-width: 0;
        }
      }

      .vertical-header {
        text-align: center;
        flex-direction: column;
        padding-top: ${standardSpacing}px;
        ${handheld(css`
          display: flex;
        `)};
        ${notHandheld(css`
          display: none;
        `)};

        .profile-picture {
          align-self: center;
        }

        .institution-name {
          margin-bottom: ${halfSpacing}px;
        }

        .user-name {
          margin-bottom: ${threeQuartersSpacing}px;
        }
      }
    }

    .master-container {
      padding-left: ${standardSpacing}px;
      padding-right: ${standardSpacing}px;

      .profile-details-container {
        width: 100%;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;

        .headline {
          margin-bottom: ${standardSpacing}px;

          .headline-display-container {
            padding-top: ${quarterSpacing}px;
            padding-bottom: ${quarterSpacing}px;
            display: block; // To make .text-center class work
          }
        }

        .bio {
          margin-bottom: ${doubleSpacing}px;

          .bio-display-container {
            padding-top: ${quarterSpacing}px;
            padding-bottom: ${quarterSpacing}px;
          }
        }

        .location {
          margin-bottom: 12px;
        }
        [data-popper-escaped=true],
        [data-popper-reference-hidden=true] {
          visibility: visible;
          pointer-events: visible;
        }
      }

      .user-courses-container {
        width: 100%;
        max-width: 940px;
        margin-left: auto;
        margin-right: auto;

        .separator {
          height: 1px;
          margin-top: ${tripleSpacing}px;
          background-color: ${brandColor};
          margin-bottom: ${standardSpacing}px;
        }

        .courses-quantity {
          margin-bottom: ${standardSpacing}px;
          line-height: ${COURSES_QUANTITY_HEIGHT}px;

          & > * {
            vertical-align: bottom;
          }

          .courses-count {
            color: ${success};
            margin-right: ${standardSpacing}px;
            font-size: ${COURSES_QUANTITY_HEIGHT}px;
            line-height: ${COURSES_QUANTITY_HEIGHT}px;
          }
        }

        .user-courses {
          .user-course {
            margin-bottom: ${standardSpacing}px;

            &:last-child {
              margin-bottom: 0;
            }

            ${notHandheld(css`
              width: ${100 / 3}%;
              vertical-align: top;
              display: inline-block;

              &:nth-of-type(3n + 1) {
                padding-right: ${doubleSpacing / NOT_HANDHELD_COURSES_PER_ROW}px;
              }

              &:nth-of-type(3n + 2) {
                padding-left: ${standardSpacing / NOT_HANDHELD_COURSES_PER_ROW}px;
                padding-right: ${standardSpacing / NOT_HANDHELD_COURSES_PER_ROW}px;
              }

              &:nth-of-type(3n + 3) {
                padding-left: ${doubleSpacing / NOT_HANDHELD_COURSES_PER_ROW}px;
              }
            `)};
          }
        }
      }
    }
  `;

  const getUserNameValue = () => {
    const formValues = watch('user');

    const {
      lastName,
      firstName,
    } = formValues;

    return `${firstName} ${lastName}`;
  };

  const getShowAccountLevelField = (fieldName: 'bio' | 'location' | 'headline') => {
    const isAnswered = !!watch(`user.${fieldName}.answer`);

    const { isHidden } = profileSettings.accountLevel[fieldName];

    if (isSelfUser) {
      if (isCurrentUserOrgAdmin) {
        return true;
      }

      return !isHidden;
    }

    if (isCurrentUserOrgAdmin) {
      return isAnswered;
    }

    return isAnswered && !isHidden;
  };

  const handleProfilePictureChange = (image: File) => {
    setIsUploadingPicture(true);

    dispatch(setProfilePicture({
      image,
      userId: currentUser.id,
    })).then((action) => {
      setIsUploadingPicture(false);
      CurrentUserManager.user.updateFromReact({
        // NOTE: Today in our platform we do some references to the user profile
        // picture with two properties, "profilePicture" and "profilePictureUrl"
        // TODO: If both are for same single purpose, this should be cleaned up.
        profilePicture: action.payload,
        profilePictureUrl: action.payload,
      });
    });
  };

  const handleUserNameBlur = (hasChanges: boolean) => {
    if (hasChanges) {
      handleAccountLevelFieldChange(['firstName', 'lastName'], true)();
    }
  };

  const handleCourseVisibilityToggleClick = () => setViewAllCourses(!viewAllCourses);

  const handleCourseCardClick = (course: Course) => $state.go('course-home', {
    catalogId: course.catalogId,
  });

  const isSelfUser = userId === currentUser.id; // Can edit if true

  const renderProfilePicture = () => (
    <div className='profile-picture'>
      <ProfilePicture
        profile={demandedUser}
        editable={isSelfUser}
        size={isHandheld ? 120 : 160}
        isUploading={isUploadingPicture}
        onChange={handleProfilePictureChange}
        readonly={currentUser.loggedInFromRussia}
        readonlyWarning={t.PROFILE.RUSSIA_COMPLIANCE_WARNING()}
      />
    </div>
  );

  const renderInstitutionName = () => (
    <div
      className={`institution-name ellipsis${isHandheld ? ' course-subtitle' : ' course-title-regular'}`}
    >
      {institutionName}
    </div>
  );

  const renderName = () => (
    <UserNameClickToEdit
      control={control}
      className='user-name'
      color={brandBarFontColor}
      onBlur={handleUserNameBlur}
      displayValue={getUserNameValue()}
      readonly={currentUser.loggedInFromRussia}
      lastNameError={formState.errors?.user?.lastName}
      firstNameError={formState.errors?.user?.firstName}
      readonlyWarning={t.PROFILE.RUSSIA_COMPLIANCE_WARNING()}
      isSaving={savingFields.firstName && savingFields.lastName}
      editable={isSelfUser && currentInstitution.enableBasicSsoInfoEdit}
    />
  );

  return (
    <FormProvider {...methods}>
      <div css={styles}>
        <div className='header-container'>
          <div className='header horizontal-header'>
            {renderProfilePicture()}
            <div className='horizontal-header-right'>
              {renderInstitutionName()}
              {renderName()}
            </div>
          </div>
          <div className='header vertical-header'>
            {renderInstitutionName()}
            {renderName()}
            {renderProfilePicture()}
          </div>
        </div>
        <div className='master-container'>
          <div className='profile-details-container'>
            {getShowAccountLevelField('headline') && (
              <FormEditableOrgLevelField
                name='user.headline'
                className='headline'
                editable={isSelfUser}
                variant={Variant.SIMPLE}
                isSaving={savingFields.headline}
                readonly={currentUser.loggedInFromRussia}
                onChange={handleAccountLevelFieldChange('headline')}
                readonlyWarning={t.PROFILE.RUSSIA_COMPLIANCE_WARNING()}
                textContainerClassName='headline-display-container bold text-center body-text-large'
              />
            )}
            {getShowAccountLevelField('bio') && (
              <FormEditableOrgLevelField
                className='bio'
                name='user.bio'
                editable={isSelfUser}
                variant={Variant.SIMPLE}
                isSaving={savingFields.bio}
                readonly={currentUser.loggedInFromRussia}
                onChange={handleAccountLevelFieldChange('bio')}
                readonlyWarning={t.PROFILE.RUSSIA_COMPLIANCE_WARNING()}
                textContainerClassName='bio-display-container body-text-large'
              />
            )}
            {getShowAccountLevelField('location') && (
              <FormEditableOrgLevelField
                name='user.location'
                className='location'
                editable={isSelfUser}
                isSaving={savingFields.location}
                variant={Variant.ORG_LEVEL_FIELD}
                readonly={currentUser.loggedInFromRussia}
                onChange={handleAccountLevelFieldChange('location')}
                readonlyWarning={t.PROFILE.RUSSIA_COMPLIANCE_WARNING()}
              />
            )}
            <FormOrgLevelFields
              name='orgFields'
              editable={isSelfUser}
              isSelfUser={isSelfUser}
              savingFields={savingFields}
              readonly={currentUser.loggedInFromRussia}
              isCurrentUserOrgAdmin={isCurrentUserOrgAdmin}
              readonlyWarning={t.PROFILE.RUSSIA_COMPLIANCE_WARNING()}
              onChange={(modifiedFieldId) => handleOrgFieldChange(modifiedFieldId)()}
            />
          </div>
          {totalPracticesCount > 0 && (
            <div className='user-courses-container'>
              <div className='separator' />
              <div className='courses-quantity'>
                <span className='courses-count condensed'>
                  {totalPracticesCount}
                </span>
                <span className='course-subtitle'>
                  {t.PROFILE.PRACTICE_VIDEOS(totalPracticesCount)}
                </span>
              </div>
              <div className='user-courses'>
                <NvSubmissionGallery
                  spacing={largeSpacing}
                  arrowsHidden={submissionsLoading}
                  loadMoreItems={loadMorePracticeSubmissions}
                  alignCenter={false}
                >
                  {galleryCards}
                </NvSubmissionGallery>
              </div>
            </div>
          )}
          {!!userEnrollments.length && (
            <div className='user-courses-container'>
              <div className='separator' />
              <div className='courses-quantity'>
                <span className='courses-count condensed'>
                  {userEnrollments.length}
                </span>
                <span className='course-subtitle'>
                  {t.DASHBOARD.COURSES(userEnrollments.length)}
                </span>
                {userEnrollments.length > INITIAL_COURSES_TO_SHOW && (
                  <button
                    type='button'
                    onClick={handleCourseVisibilityToggleClick}
                    className='btn btn-link hovered text-regular'
                  >
                    {viewAllCourses ? t.SHARED.VIEW_LESS() : t.SHARED.VIEW_ALL()}
                  </button>
                )}
              </div>
              <div className='user-courses'>
                {userEnrollments.slice(
                  0,
                  viewAllCourses ? userEnrollments.length : INITIAL_COURSES_TO_SHOW,
                ).map((enrollment) => (
                  <div className='user-course' key={enrollment.id}>
                    <CourseCard
                      onClick={handleCourseCardClick}
                      catalogId={enrollment.courseCatalogId}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </FormProvider>
  );
};

type OrgLevelFieldsProps = {
  editable: boolean,
  isSelfUser: boolean,
  orgFields: OrgProfileField[],
  isCurrentUserOrgAdmin: boolean,
  savingFields: SavingDictionary,
  onChange: (newOrgFields: OrgProfileField[], modifiedFieldId: number) => void,
  readonly?: boolean,
  readonlyWarning?: string,
};

/**
 * This component was abstracted to render this org level fields list in course
 * level profile.
 */
const OrgLevelFields = (props: OrgLevelFieldsProps) => {
  const {
    editable,
    onChange,
    readonly,
    orgFields,
    isSelfUser,
    savingFields,
    readonlyWarning,
    isCurrentUserOrgAdmin,
  } = props;

  const getShowOrgLevelField = (field: OrgProfileField) => {
    const isAnswered = !!field.answer;

    if (isSelfUser) {
      if (isCurrentUserOrgAdmin) {
        return true;
      }

      return !field.isHidden;
    }

    if (isCurrentUserOrgAdmin) {
      return isAnswered;
    }

    return isAnswered && !field.isHidden;
  };

  return (
    <div>
      {orgFields.map((orgField, index) => {
        const handleChange = (newField) => {
          const list = [...orgFields];

          list[index] = newField;

          onChange(list, orgField.questionId);
        };

        return (
          <React.Fragment key={orgField.questionId}>
            {getShowOrgLevelField(orgField) && (
              <EditableOrgLevelField
                readonly={readonly}
                field={orgField}
                editable={editable}
                onChange={handleChange}
                readonlyWarning={readonlyWarning}
                variant={Variant.ORG_LEVEL_FIELD}
                isSaving={savingFields[orgField.questionId]}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

type FormOrgLevelFieldsProps = {
  name: string,
  onChange: (modifiedFieldId: number) => void,
};

const FormOrgLevelFields = (
  props: Omit<OrgLevelFieldsProps, 'onChange' | 'orgFields'> & FormOrgLevelFieldsProps,
) => {
  const {
    name,
    onChange: propsOnChange,
    ...restProps
  } = props;

  return (
    <Controller
      name={name}
      render={(data) => {
        const { value, onChange } = data.field;

        const handleChange = (orgFields, modifiedFieldId) => {
          onChange(orgFields);

          propsOnChange(modifiedFieldId);
        };

        return (
          <OrgLevelFields
            orgFields={value}
            onChange={handleChange}
            {...restProps}
          />
        );
      }}
    />
  );
};

const LoadingState = () => {
  const {
    brandColor,
  } = useSelector<RootState, Institution>(getCurrentInstitution);

  const styles = css`
    padding-bottom: 200px;

    .header-container {
      display: flex;
      justify-content: center;
      background-color: ${brandColor};
      margin-bottom: ${doubleSpacing}px;
      ${handheld(css`
        height: 185px;
        padding-top: ${standardSpacing * 5}px;
      `)};
      ${notHandheld(css`
        height: 160px;
        padding-top: ${doubleSpacing}px;

        .header {
          width: 100%;
          max-width: 680px;
        }
      `)};

      .header {
        padding-left: ${standardSpacing}px;
        padding-right: ${standardSpacing}px;
      }
    }

    .profile-picture {
      border-radius: 50%;

      ${handheld(css`
        width: 120px;
        height: 120px;
      `)};
      ${notHandheld(css`
        width: 160px;
        height: 160px;
      `)};
    }

    .master-container {
      padding-left: ${standardSpacing}px;
      padding-right: ${standardSpacing}px;

      .loading-container {
        width: 100%;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;

        .loading-text {
          margin-bottom: ${doubleSpacing}px;
        }

        .loading-bar {
          height: 4px;
          margin-bottom: ${standardSpacing}px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

    }
  `;


  return (
    <div css={styles}>
      <div className='header-container'>
        <div className='header'>
          <div className='profile-picture loading' />
        </div>
      </div>
      <div className='master-container'>
        <div className='loading-container'>
          <div className='loading-text text-center page-title'>
            {t.ORG_LEVEL_PROFILE.LOADING_PROFILE()}
          </div>
          <div>
            <div className='loading loading-bar' />
            <div className='loading loading-bar' />
            <div className='loading loading-bar' />
            <div className='loading loading-bar' />
          </div>
        </div>
      </div>
    </div>
  );
};

const OrgLevelProfile = () => {
  const {
    $state: { params: { userId: demandedUserId } },
  } = React.useContext(AngularServicesContext);

  const dispatch = useAppDispatch();
  const [loaded, setLoaded] = React.useState(false);
  const currentInstitutionId = useSelector(
    (state: RootState) => state.app.currentInstitutionId,
  );

  React.useEffect(() => {
    Promise.all([
      dispatch(getInstitutionData({
        institutionId: currentInstitutionId,
      })),
      dispatch(getBasicUserInfo({
        userId: demandedUserId,
        forOrgLevelProfile: true,
      })),
    ]).then((actions) => {
      const succeededAll = actions.every(
        (action) => /\w+\/fulfilled$/.test(action.type),
      );

      if (succeededAll) {
        setLoaded(true);
      }
    });
  }, []);


  return loaded ? <Form userId={demandedUserId} /> : <LoadingState />;
};

export default OrgLevelProfile;
