/* @ngInject */
export default function NvCompileAlways(
  _,
  $compile,
  nvUtil,
  $parse,
) {
  return {
    restrict: 'A',
    replace: true,
    link(scope, element, attrs) {
      scope.$watch(() => attrs.nvCompileAlways, (newValue, oldValue) => {
        element.html(nvUtil.makeIframesResponsive(newValue));
        $compile(element.contents())(scope);

        if (_.has(attrs, 'onCompile')) {
          $parse(attrs.onCompile)(scope);
        }
      });
    },
  };
}
