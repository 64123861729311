import { cloneDeepSerializable } from 'redux/store';

/* @ngInject */
export default function SubmissionsDiscoveryLectureComponentModelS(
  _,
  moment,
  $stateParams,
  $translate,

  CurrentCourseManager,
  LectureComponentBaseModel,
  ExerciseModel,
  ReportModel,
  config,
) {
  const defaultSubmissionsDiscoveryCriteria = {
    exerciseId: null,
    submissionsCount: 8,
    sortBy: 'trending',
  };

  class SubmissionsDiscoveryLectureComponentModel extends LectureComponentBaseModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      const defaults = {
        type: 'SubmissionsDiscoveryLectureComponent',
        editFormTemplateUrl: 'lecture_pages/templates/components/submissions-discovery-edit-modal.html',
        editFormController: 'SubmissionsDiscoveryEditFormModalCtrl',
        submissionsDiscoveryCriteria: defaultSubmissionsDiscoveryCriteria,
        updateExercisesAfterCreationDeletion: true,
        isActivity: false,
        canBeCopied: true,
      };
      super(_.extend({}, defaults, attributes));

      this.__preprocess();

      if (useDefaults) {
        this.createDraft();
      }
    }

    __preprocess() {
      this.submissions = cloneDeepSerializable(this.submissions);
      this.requiredExercise = cloneDeepSerializable(this.requiredExercise);
      _.each(this.submissions, ReportModel.normalizeSubmissionData);
      // prevent slick carousel from breaking when ReportsManager tries to update this
      this.carouselSubmissions = angular.copy(this.submissions);

      _.each(this.carouselSubmissions, (submission) => {
        submission.exercise = this.requiredExercise;
      });

      // To add extra card at end of submissions
      const carouselAddition = {
        isPlaceholder: true,
        lowSubmissionCount: undefined,
      };

      if (this.carouselSubmissions?.length) {
        this.requiredExercise.id = this.carouselSubmissions[0].exerciseId;

        if (this.carouselSubmissions.length < this.totalSubmissionsCount) {
          // If more submissions exist in gallery
          carouselAddition.lowSubmissionCount = false;
          this.carouselSubmissions.push(carouselAddition);
        } else if (this.carouselSubmissions.length < this.submissionsDiscoveryCriteria.submissionsCount) {
          // Not enough submissions to show
          carouselAddition.lowSubmissionCount = true;
          this.carouselSubmissions.push(carouselAddition);
        }
      }
    }

    get fullWidth() {
      return true;
    }

    createDraft() {
      this.criteriaDraft = _.clone(this.submissionsDiscoveryCriteria || defaultSubmissionsDiscoveryCriteria);
    }

    getPayload() {
      return {
        submissionsDiscoveryCriteria: _.pick(this.submissionsDiscoveryCriteria, ['id', 'exerciseId', 'submissionsCount', 'sortBy']),
      };
    }

    saveDraft() {
      this.submissionsDiscoveryCriteria = _.clone(this.criteriaDraft);
      this.requiredExercise = _.clone(this.criteriaDraft.exercise);
    }

    moveToWarningMessages(targetLecturePage) {
      const warningMessages = super.moveToWarningMessages(targetLecturePage);

      if (this.requiredExercise
            && moment() < moment(this.requiredExercise.releaseDate)
            && !targetLecturePage.released
            && (moment(this.requiredExercise.releaseDate) > moment(targetLecturePage)
              || (moment(this.requiredExercise.deadline) > moment(targetLecturePage) && !this.requiredExercise.submissionsViewableBeforeDeadline)
            )
      ) {
        warningMessages.push($translate.instant('LECTURE_PAGES.COMPONENTS.SUBMISSIONS_DISCOVERY.MOVE_WARNING', CurrentCourseManager.course.getAliases()));
      }

      return warningMessages;
    }

    removeExercise(exercise) {
      if (this.requiredExercise?.id === exercise.id) {
        this.requiredExercise = null;
      }
    }

    afterUpdate() {
      this.createDraft();
    }
  }

  SubmissionsDiscoveryLectureComponentModel.iconClass = 'icon-gallery';
  SubmissionsDiscoveryLectureComponentModel.descriptionKey = 'LECTURE_PAGES.COMPONENTS.SUBMISSIONS_DISCOVERY.DESCRIPTION';
  SubmissionsDiscoveryLectureComponentModel.unavailabilityDescriptionKey = 'LECTURE_PAGES.COMPONENTS.SUBMISSIONS_DISCOVERY.UNAVAILABLE';
  SubmissionsDiscoveryLectureComponentModel.showModalBeforeCreate = true;
  SubmissionsDiscoveryLectureComponentModel.sortOptions = ['recent', 'trending'];
  SubmissionsDiscoveryLectureComponentModel.pendoTagName = config.pendo.lectureEdit.carousel;

  return SubmissionsDiscoveryLectureComponentModel;
}
