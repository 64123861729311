/* @ngInject */
export default function TimedQuizFeedbackController(
  $scope,
  $stateParams,
  QuizzesManager,
  questionSetId,
  quiz,
) {
  const vm = this;

  const getQuizPromise = null;
  vm.manager = QuizzesManager; /* using QuizzesManager here, not TimedQuizzesManager */
  vm.$stateParams = $stateParams;
  vm.questionSetId = questionSetId;
  vm.quiz = quiz;
  vm.quizForm = null;

  initialize();

  function initialize() {
    vm.manager.getQuiz($stateParams.catalogId, vm.questionSetId, null).then(() => {
      vm.manager.setCurrentSubmission(vm.manager.currentQuiz.submission);
    });
  }
}
