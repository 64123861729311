import React from 'react';
import CourseSupervisorProvider from 'course_supervisor_dashboard/context/course_supervisor_provider';
import CourseSupervisorDashboard from './course_supervisor_dashboard';

const CourseSupervisorContainer = () => (
  <CourseSupervisorProvider>
    <CourseSupervisorDashboard />
  </CourseSupervisorProvider>
);

export default CourseSupervisorContainer;
