/* @ngInject */
export default function NvDiscussionTopicEditOptions(
  DiscussionsManager,
  CurrentUserManager,
  InstitutionsManager,
) {
  return {
    restrict: 'A',
    scope: {
      topic: '=',
    },
    controller: function ctrl() {
'ngInject';
      const vm = this;

      vm.manager = DiscussionsManager;
      vm.currentUserManager = CurrentUserManager;
      vm.adminOptionsVisible = CurrentUserManager.isInstructorForCurrentCourse() || CurrentUserManager.isTeachingAssistantForCurrentCourse() || CurrentUserManager.isCourseBuilderForCurrentCourse();
      vm.institutionsManager = InstitutionsManager;
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'discussions/templates/nv-discussion-topic-edit-options.html',
  };
}
