/* @ngInject */
export default function LectureVideosCtrl(
  requestLectureVideo,

  $scope,
  $stateParams,
  LectureVideosManager,
  DiscussionsManager,
  CurrentCourseManager,
  CurrentUserManager,
  PostModel,
  InteroperabilityRoutes,
) {
  const vm = this;
  vm.manager = LectureVideosManager;
  vm.currentLectureVideo = LectureVideosManager.currentLectureVideoList.currentLectureVideo;
  vm.InteroperabilityRoutes = InteroperabilityRoutes;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.pusherCourseId = CurrentCourseManager.course.id;

  // discussions for lecture video
  DiscussionsManager.resetData();
  DiscussionsManager.initialize({ context: 'lectureVideo', catalogId: $stateParams.catalogId });
  DiscussionsManager.initializePusherEvents(vm.pusherCourseId);
  DiscussionsManager.initializeMentions();

  $scope.$on('$destroy', () => {
    DiscussionsManager.unsubscribeFromPusherEvents(vm.pusherCourseId);
  });
}
