import { RootState, CombinedInstitution } from 'redux/schemas';
import { OrgMentorRoleAlias } from 'redux/schemas/models/institution';
import { createSelector } from 'reselect';

/* eslint-disable import/prefer-default-export */

export const getCurrentInstitution = (state: RootState): CombinedInstitution => {
  if (!state.app.currentInstitutionId) {
    return null;
  }

  return state.models.institutions[state.app.currentInstitutionId];
};

export const getRoleAliases = (state: RootState): OrgMentorRoleAlias => {
  if (!state.app.currentInstitutionId) {
    return null;
  }
  return state.models.institutions[state.app.currentInstitutionId].roleAliases;
};

export const getBrand = createSelector(
  [getCurrentInstitution],
  ({ name, logo, logoSize, brandColor, brandBarFontColor }) => ({
    name,
    logo,
    logoSize,
    brandColor,
    brandBarFontColor: brandBarFontColor?.toUpperCase() || '#000',
  }),
);

export const getDiscoveryEnabled = createSelector(
  [getCurrentInstitution],
  ({ discoveryEnabled }) => discoveryEnabled,
);
