import { createSelector } from 'reselect';
import { RootState } from 'redux/schemas';
import { getDemoData } from 'redux/actions/teams';
import { difference, isEqual } from 'underscore';

export const getChangesToTeams = (state: RootState, isTeam, newTeamData) => {
  if (!newTeamData.length) {
    return {
      newTeams: [],
      teamsToDelete: [],
      teamsToUpdate: [],
    };
  }

  const currentUsers = isTeam ? state.models.teamsForCSV : state.models.groupsForCSV;
  const newTeams = {};
  const teamsToDelete = Object.fromEntries(currentUsers.filter(({ teamId }) => teamId && teamId !== 'CREATE NEW').map(team => [team.teamId, team]));
  const teamsToUpdate = {};
  const currentTeams = {};
  currentUsers.forEach(({ teamId, firstName, lastName, isAdmin }) => {
    if (Number.isNaN(Number(teamId))) {
      return;
    }
    const fullName = (firstName && lastName) ? `${firstName} ${lastName}` : null;
    if (currentTeams[teamId]) {
      currentTeams[teamId].members.push(fullName);
    } else {
      currentTeams[teamId] = {
        members: fullName ? [fullName] : [],
        listOfAdmins: [],
      };
    }
    if (isAdmin) {
      if (isTeam) {
        currentTeams[teamId].listOfAdmins = [fullName];
      } else {
        currentTeams[teamId].listOfAdmins.push(fullName);
      }
    }
  });
  const usersInNoTeam = newTeamData.filter(({ teamId, groupId }) => (teamId || groupId) === 'NO GROUP').map(({ email }) => email);
  const existingTeamsFromCSV = {};
  newTeamData.forEach(({ teamId, groupId, teamName, groupName, firstName, lastName, email, isTeamLead, isAdmin }) => {
    teamId ??= groupId;
    teamName ??= groupName;
    isTeamLead ??= isAdmin;
    const fullName = `${firstName} ${lastName}`;
    if (usersInNoTeam.includes(email)) {
      return;
    }
    if (teamId === 'CREATE NEW') {
      if (newTeams[teamName]) {
        newTeams[teamName].totalMembers += 1;
      } else {
        newTeams[teamName] = {
          teamName,
          totalMembers: 1,
          teamLeadName: isTeam ? '' : [],
        };
      }
      if (isTeamLead.toLowerCase() === 'yes') {
        if (isTeam) {
          newTeams[teamName].teamLeadName = fullName;
        } else {
          newTeams[teamName].teamLeadName.push(` ${fullName}`);
        }
      }
    } else if (!Number.isNaN(Number(teamId))) {
      if (existingTeamsFromCSV[teamId]) {
        existingTeamsFromCSV[teamId].members.push(fullName);
      } else {
        existingTeamsFromCSV[teamId] = {
          teamName,
          members: [fullName],
          listOfAdmins: [],
        };
      }
      if (isTeamLead.toLowerCase() === 'yes') {
        if (isTeam) {
          existingTeamsFromCSV[teamId].listOfAdmins = [fullName];
        } else {
          existingTeamsFromCSV[teamId].listOfAdmins.push(fullName);
        }
      }
    }

    delete teamsToDelete[teamId];
  });

  Object.keys(existingTeamsFromCSV).forEach(key => {
    const membersAdded = difference(existingTeamsFromCSV[key].members, currentTeams[key].members).length;
    const membersRemoved = difference(currentTeams[key].members, existingTeamsFromCSV[key].members).length;
    const hasChangedTeamLeads = !isEqual(currentTeams[key].listOfAdmins, existingTeamsFromCSV[key].listOfAdmins);
    if (membersAdded || membersRemoved || hasChangedTeamLeads) {
      teamsToUpdate[key] = {
        teamName: existingTeamsFromCSV[key].teamName,
        membersAdded,
        membersRemoved,
        listOfAdmins: hasChangedTeamLeads ? existingTeamsFromCSV[key].listOfAdmins : null,
      };
    }
  });

  // Teams with contributions are not deleted. Instead, they're updated
  Object.keys(teamsToDelete).forEach(teamId => {
    if (teamsToDelete[teamId].hasContributions) {
      if (currentTeams[teamId].members.length) {
        teamsToUpdate[teamId] = {
          teamName: teamsToDelete[teamId].teamName,
          membersRemoved: currentTeams[teamId].members.length,
        };
      }
      delete teamsToDelete[teamId];
    }
  });

  return {
    newTeams: Object.values(newTeams ?? {}),
    teamsToDelete: Object.values(teamsToDelete ?? {}),
    teamsToUpdate: Object.values(teamsToUpdate ?? {}),
  };
};

export const getValidTeamIds = (state: RootState, isTeam) => Array.from(new Set(state.models[isTeam ? 'teamsForCSV' : 'groupsForCSV'].filter(({ teamId }) => teamId).map(({ teamId }) => teamId.toString())).add(isTeam ? 'NO TEAM' : 'NO GROUP').add('CREATE NEW'));

export const getValidUsers = (state: RootState, isTeam) => {
  const setOfUsers = new Set(state.models[isTeam ? 'teamsForCSV' : 'groupsForCSV'].map(({ email }) => email));
  getDemoData(isTeam).forEach(demoUser => setOfUsers.delete(demoUser.email));
  return Array.from(setOfUsers);
};

const getTeamMentionableMembers = (state: RootState, teamId: number) => state.models.teams[teamId]?.mentionableMembers?.filter((userId) => state.app.currentUserId !== parseInt(userId, 10));

const getMentionableMembers = (state: RootState) => state.models.mentionableMembers;

const getMentionsForTeam = createSelector(
  [getTeamMentionableMembers, getMentionableMembers],
  (teamMentionableMembers, mentionableMembers) => (teamMentionableMembers || []).map(id => mentionableMembers[id]),
);

export default getMentionsForTeam;
