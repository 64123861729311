/* @ngInject */
export default function TeamFormModalCtrl(
  // S3UploadFactory,
  // S3NameSpaces,
  _,
  $uibModalInstance,
  $templateCache,
  $scope,
  modalOptions,
  StateManager,
  ConfirmationOverlays,
) {
  const _this = this;
  _this.modalOptions = modalOptions;
  _this.confirmingClose = false;
  _this.submitting = false;
  _this.unsavedChangesMessage = 'FORM.NAVIGATE_AWAY.CLOSE_WINDOW';
  _this.closeModal = closeModal;
  _this.formSubmit = formSubmit;
  _this.uploadTeamPicture = uploadTeamPicture;
  _this.removeTeamPicture = removeTeamPicture;
  _this.hasUnsavedChanges = hasUnsavedChanges;


  if (_this.modalOptions.bodyTemplateUrl) {
    _this.modalOptions.body = $templateCache.get(_this.modalOptions.bodyTemplateUrl);
  }
  _this.team = _this.modalOptions.model.team;
  _this.currentCourse = _this.modalOptions.model.currentCourse;

  function closeModal() {
    $uibModalInstance.dismiss('cancel');
  }

  function formSubmit() {
    _this.submitting = true;
    _this.team.submit().then(() => {
      if (!_this.team.errors || !_this.team.errors.length) {
        $uibModalInstance.close();
        _this.submitting = false;
        if (_this.modalOptions.afterConfirm) {
          _this.modalOptions.afterConfirm(_this.team);
        }
      }
    });
  }

  function uploadTeamPicture(file, teamPicture) {
    // teamPicture.fileUploadProgress = 0;

    // var upload = S3UploadFactory.uploadToS3(file, S3NameSpaces.REPORTS);

    // upload.then(function(resp) {
    //   teamPicture.uniqueId = resp.config.data.file.uniqueId;
    //   teamPicture.fileUploadProgress = 100;
    // }, function(resp) {
    //   // error
    // }, function(evt) {
    //   var percent = parseInt(100.0 * evt.loaded / evt.total);
    //   teamPicture.fileUploadProgress = Math.min(99, percent);
    // });
    teamPicture = file;
  }

  function removeTeamPicture(team) {
    team.picture = undefined;
  }

  function hasUnsavedChanges() {
    return _this.teamForm?.$dirty && !_this.team.saving && !_this.submitting;
  }

  // warn if leaving the modal and have unsaved changes
  _this.deregisterStateChangeStart = StateManager.registerStateChangeStart(
    () => _this.hasUnsavedChanges(),
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    _this.unsavedChangesMessage,
    undefined, undefined,
    () => {
      _this.confirmingClose = true;
      $uibModalInstance.dismiss();
    },
  );

  $scope.$on('modal.closing', ($event) => {
    if (_this.hasUnsavedChanges() && !_this.confirmingClose && !_this.team.saving && !_this.submitting) {
      $event.preventDefault();


      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        _this.confirmingClose = true;
        $uibModalInstance.dismiss();
      });
    }
  });

  $scope.$on('$destroy', () => {
    if (_this.deregisterStateChangeStart) {
      $uibModalInstance.dismiss();
      _this.deregisterStateChangeStart();
    }
  });
}
