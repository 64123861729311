/* @ngInject */
export default function TextLectureComponentModel(
  _,
  $q,

  LectureComponentBaseModel,
  config,
) {
  class TextLectureComponent extends LectureComponentBaseModel {
    constructor(attributes) {
      const defaults = {
        type: 'RichTextLectureComponent',
        content: '',
        viewOptions: {
          styleOptions: 'simple',
        },
        isActivity: false,
        canBeCopied: true,
      };
      super(_.extend({}, defaults, attributes));
    }

    getPayload() {
      return _.pick(this, ['content', 'index', 'viewOptions']);
    }
  }

  TextLectureComponent.iconClass = 'icon-admin-rich-text';
  TextLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.TEXT.DESCRIPTION';
  TextLectureComponent.pendoTagName = config.pendo.lectureEdit.simpleText;
  TextLectureComponent.isContentComponent = true;
  TextLectureComponent.toolTipKey = 'LECTURE_PAGES.COMPONENTS.TEXT.TOOLTIP';

  return TextLectureComponent;
}
