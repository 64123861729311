import React from 'react';
import t from 'react-translate';
import _ from 'underscore';
import { useSelector } from 'react-redux';
import { Course } from 'redux/schemas/models/course';
import useJourneyFormUtils from 'learning_journeys/hooks/use-journey-form-utils';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { getJourneyClonings } from 'redux/actions/institutions';
import { useAppDispatch } from 'redux/store';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import CourseInformationCloneModal from './course-information-clone-modal';
import JourneyInformationCloneModal, { CourseStartDatesTypes, JourneyInformationProps } from './journey-information-clone-modal';

enum JourneyCloneSteps {
  JOURNEY_INFORMATION_STEP,
  COURSE_INFORMATION_STEP,
}

type OwnProps = {
  course: Course,
  closeModal(): void,
};

export const JourneyCloneModal = (props: OwnProps) => {
  const { course, closeModal } = props;
  const { getJourney: fetchJourney } = useJourneyFormUtils();

  const [currentStep, setCurrentStep] = React.useState<JourneyCloneSteps>(JourneyCloneSteps.JOURNEY_INFORMATION_STEP);
  const [showBulkDates, setShowBulkDates] = React.useState(false);
  const [collections, setCollections] = React.useState([]);
  const [originalCollections, setOriginalCollections] = React.useState([]);
  const [journeyConfig, setJourneyConfig] = React.useState<JourneyInformationProps>();

  const dispatch = useAppDispatch();

  const currentInstitutionId = useSelector((state) => state.app.currentInstitutionId);

  const handleJourneySuccess = (config: JourneyInformationProps) => {
    const { courseStartDates } = config;
    const isManually = courseStartDates === CourseStartDatesTypes.MANUALLY;
    const isAutomaticallyAndDateChange = !isManually && config?.releaseDate !== journeyConfig?.releaseDate;
    if (courseStartDates !== journeyConfig?.courseStartDates || isAutomaticallyAndDateChange) {
      const collectionsTemp = _.clone(collections);
      collectionsTemp.forEach(({ courses }) => {
        courses.forEach((item) => { item.releaseDateDraft = null; });
      });
      setCollections(collectionsTemp);
    }
    setShowBulkDates(isManually);
    setJourneyConfig({ ...config, bulkDate: journeyConfig?.bulkDate });
    setCurrentStep(JourneyCloneSteps.COURSE_INFORMATION_STEP);
  };

  const handleCourseSuccess = () => {
    dispatch(addAlertMessage({
      type: AlertMessageType.SUCCESS,
      header: t.LEARNING_JOURNEYS.CLONE.SUCCESS_HEADER(),
      message: t.LEARNING_JOURNEYS.CLONE.SUCCESS_BODY(),
    }));

    dispatch(getJourneyClonings({ institutionId: currentInstitutionId }));
    closeModal();
  };

  const handleBack = (collectionsDraft, bulkDate) => {
    setCollections(collectionsDraft);
    setJourneyConfig({ ...journeyConfig, bulkDate });
    setCurrentStep(JourneyCloneSteps.JOURNEY_INFORMATION_STEP);
  };

  React.useEffect(() => {
    fetchJourney(course.catalogId).then(({ payload }) => {
      const list = payload?.journey?.collections;
      setCollections(list);
      setOriginalCollections(list);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className='clone-course-modal'>
      {currentStep === JourneyCloneSteps.JOURNEY_INFORMATION_STEP
        ? (
          <JourneyInformationCloneModal
            journey={course}
            config={journeyConfig}
            onSuccess={handleJourneySuccess}
          />
        ) : (
          <CourseInformationCloneModal
            journey={course}
            config={journeyConfig}
            base={originalCollections}
            collections={collections}
            showBulkDates={showBulkDates}
            onBack={handleBack}
            onSuccess={handleCourseSuccess}
          />
        )}
    </div>
  );
};

export default JourneyCloneModal;
