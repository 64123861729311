import { css } from '@emotion/react';
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import moment from 'moment';

// Selectors
import { getCourseAliases } from 'redux/selectors/course';

// Styles
import { quarterSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { primary, gray3, gray4, warning } from 'styles/global_defaults/colors';
import { desktop } from 'styles/global_defaults/media-queries';

// Components
import { NvPopover } from 'shared/components/nv-popover';
import NvModal, { ModalType } from 'shared/components/nv-modal';

// Hooks
import useClickOutside from 'shared/hooks/use-click-outside';


type ScheduledDayHeaderProps = {
  date: string,
  activities: string[],
  lessons: string[],
};

const ROW_HEIGHT = 80;
const MODAL_WIDTH = 600;

const displayDay = (dateString: string) => {
  const date = moment(dateString);

  if (date.isSame(new Date(), 'day')) {
    // Today
    return t.COURSE_COMMUNICATIONS.SCHEDULED_TAB.TODAY();
  }

  if (date.isSame(moment().subtract(1, 'day'), 'day')) {
    // Yesterday
    return t.COURSE_COMMUNICATIONS.SCHEDULED_TAB.YESTERDAY();
  }

  if (date.isSame(moment().add(1, 'day'), 'day')) {
    // Tomorrow
    return t.COURSE_COMMUNICATIONS.SCHEDULED_TAB.TOMORROW();
  }

  return date.format('L');
};

const styles = (isFutureDate: boolean) => css`
  .scheduled-day-header-row {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: ${ROW_HEIGHT}px;
    position: relative;

    :before {
      position: absolute;
      content: '';
      width: ${quarterSpacing}px;
      min-height: ${ROW_HEIGHT}px;
      top: 0;
      left: 0;
      background-color: ${isFutureDate ? warning : gray4};
    }

    .items + .items {
      margin-top: ${quarterSpacing}px;
    }

    .entity-title {
      color: ${primary};
      cursor: pointer;
    }

    .popover-wrapper {
      display: inline;
    }
  }
`;

const listDot = css`
  /* Colored dot for indicating list items*/
  ::before {
    display: inline-block;
    min-width: ${quarterSpacing}px;
    height: ${quarterSpacing}px;
    border-radius: 100%;
    background: ${gray3};
    margin-top: ${quarterSpacing}px;
    margin-right: ${halfSpacing}px;
    content: ' ';
  }
`;

const popoverStyles = css`
  .list {
    display: flex;

    ${listDot};
  }

  .list + .list {
    margin-top: ${halfSpacing}px;
  }

  .view-all {
    color: ${primary};
    cursor: pointer;
  }
`;

const modalStyles = css`
  .modal-data {
    display: flex;

    ${desktop(css`
      margin: auto 80px;
    `)};
  }

  .modal-list {
    display: flex;

    ${listDot};
  }
`;

const getModalBody = (items: string[], displayDate: string) => (
  <div css={modalStyles}>
    <div className='text-center page-title-l py-3'>{displayDate}</div>
    <div className='modal-data text-medium mb-4'>
      <div className='modal-data-wrapper'>
        {items.map(title => (
          <div className='modal-list mt-4' key={title}>
            {title}
          </div>
        ))}
      </div>
    </div>
  </div>
);

type EntityListProps = {
  items: string[],
  title: string,
  header: string,
  displayDate: string,
};

const EntityList = ({
  items,
  title,
  header,
  displayDate,
}: EntityListProps) => {
  const [showPopover, setShowPopover] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const popoverRef = useRef(null);

  useClickOutside(popoverRef, () => {
    setShowPopover(false);
  });

  return (
    <React.Fragment>
      <NvPopover
        content={(
          <React.Fragment>
            <div className='text-small'>
              {items.slice(0, 3).map(itemTitle => (
                <div className='list' key={itemTitle}>
                  {itemTitle}
                </div>
              ))}
            </div>
            <div>
              {items.length > 3
                ? (
                  <div className='view-all text-center py-2'>
                    <span
                      className='text-small'
                      ref={popoverRef}
                      onClick={() => { setShowModal(true); setShowPopover(false); }}
                    >
                      View All
                    </span>
                  </div>
                )
                : ''}
            </div>
          </React.Fragment>
        )}
        show={showPopover}
        placement='top'
        overlayStyles={popoverStyles}
      >
        <span
          ref={popoverRef}
          className='entity-title'
          onClick={() => setShowPopover(!showPopover)}
        >
          {title}
        </span>
      </NvPopover>
      {(items.length > 3 && showModal)
        ? (
          <NvModal
            type={ModalType.DYNAMIC}
            width={MODAL_WIDTH}
            fullHeight={false}
            header={header}
            show={showModal}
            body={getModalBody(items, displayDate)}
            onClose={() => setShowModal(false)}
          />
        )
        : ''}
    </React.Fragment>
  );
};

const ScheduledDayHeader = ({
  date,
  activities,
  lessons,
}: ScheduledDayHeaderProps) => {
  const aliases = useSelector((state) => getCourseAliases(state));

  const isFutureDate = moment(date).isSameOrAfter(new Date(), 'day');

  return (
    <div css={styles(isFutureDate)}>
      <div className='scheduled-day-header-row px-4 py-3'>
        <div className='page-title-l'>
          {
            (activities?.length > 0 || lessons?.length > 0)
              ? t.COURSE_COMMUNICATIONS.SCHEDULED_TAB.TITLE_WITH_COLON(displayDay(date))
              : displayDay(date)
          }
        </div>
        <div className='ml-2 bold gray-2'>
          { activities?.length > 0 && (
            <div className='items'>
              {t.COURSE_COMMUNICATIONS.SCHEDULED_TAB.DUE_DATE()}
              <EntityList
                items={activities}
                title={t.COURSE_COMMUNICATIONS.SCHEDULED_TAB.ACTIVITY(activities.length)}
                header={t.COURSE_COMMUNICATIONS.SCHEDULED_TAB.DUE_DATE_MODAL_HEADER(activities.length)}
                displayDate={displayDay(date)}
              />
            </div>
          )}
          {(lessons?.length > 0) && (
            <div className='items'>
              {t.COURSE_COMMUNICATIONS.SCHEDULED_TAB.RELEASE_DATE()}
              <EntityList
                items={lessons}
                title={t.COURSE_COMMUNICATIONS.SCHEDULED_TAB.LESSON({ lessonsCount: lessons.length, ...aliases.lectureAliases })}
                header={t.COURSE_COMMUNICATIONS.SCHEDULED_TAB.RELEASE_DATE_MODAL_HEADER({ lessonsCount: lessons.length, ...aliases.lectureAliases })}
                displayDate={displayDay(date)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScheduledDayHeader;
