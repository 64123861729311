import { css } from '@emotion/react';

import { UploadedFile } from 'redux/schemas/models/file-uploads';

import NvVideoPreview from 'shared/components/nv-video-preview';
import NvBoxEmbed from './nv-box-embed';
import NvBoxFileModalPreview from './nv-box-file-modal-preview';
import useUploadedFile from './hooks/use-uploaded-file';

export type NvUploadedFileContentProps = {
  file: UploadedFile,
  isOpenedInModal?: boolean
};

const styles = css`
  .image {
    width: auto;
    height: auto !important;
    max-width: 100%;

    .preview {
      vertical-align: baseline;
    }
  }
`;

const NvUploadedFileContent = (props: NvUploadedFileContentProps) => {
  const { file, isOpenedInModal = false } = props;
  const { getNormalizeFileType } = useUploadedFile();
  const normalizedFileType = getNormalizeFileType(file.type, file.name);

  const ContentPreview = isOpenedInModal ? NvBoxFileModalPreview : NvBoxEmbed;

  const getContent = (fileType) => {
    switch (fileType) {
      case 'video':
      case 'audio':
        return <NvVideoPreview file={file} isAudio={fileType === 'audio'} />;
      case 'image':
        return (
          <div className='image'>
            <img className='preview' src={file.url} alt={file.name} />
          </div>
        );
      case 'pdf':
      case 'document':
      case 'spreadsheet':
      case 'presentation':
      case 'externaldocument':
      case 'zip':
      case 'other':
      default:
        return <ContentPreview file={file} />;
    }
  };

  return (
    <div css={styles}>
      {getContent(normalizedFileType)}
    </div>
  );
};

export default NvUploadedFileContent;
