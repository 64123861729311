import { css } from '@emotion/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';
import { isEmpty } from 'underscore';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Redux
import { useAppDispatch } from 'redux/store';
import { addUsersToCourse } from 'redux/actions/users';
import { CollectionCourse } from 'redux/schemas/models/collections';
import { getCurrentUser } from 'redux/selectors/users';
import { setHighlightedCollectionId, unsetCollectionCanLinkAndConvert } from 'redux/actions/collections';
import { getCurrentInstitution } from 'redux/selectors/institutions';

// Styles
import { openSansCondensed } from 'styles/global_defaults/fonts';

// components
import NvIcon from 'shared/components/nv-icon';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import {
  NvResponsiveTabsDisplayType, NvTab,
} from 'shared/components/nv-responsive-tabs';
import NvResponsiveTabsRow from 'shared/components/nv-responsive-tabs-row';
import L1PageHeader from 'shared/components/l1-page-header';
import { config } from '../../../config/config.json';
import CollectionList from './collection-list';
import NewCollectionModalBody from './new-collection-modal-body';
import ManagePermissionModal from './user-management/manage-permission-modal';

export const CollectionDashboard = () => {
  const styles = css`
    .tab-content {
      height: calc(100vh - 120px); // 120px = top header(60) + tab header(60)
    }

    .tab-text {
      font-family: ${openSansCondensed};
    }
  `;

  const currentInstitution = useSelector(getCurrentInstitution);
  const currentUser = useSelector(getCurrentUser);
  const canLinkCollectionLesson = useSelector(state => state.app.collection.canLinkCollectionLesson);
  const canConvertToCollectionLesson = useSelector(state => state.app.collection.canConvertToCollectionLesson);

  const [showCreationModal, setShowCreationModal] = useState<boolean>(false);
  const [permissionModalCatalogId, setPermissionModalCatalogId] = useState<string>();
  // Toggling loadCollectionsTable to reload the collections list.
  const [loadCollectionsTable, setLoadCollectionsTable] = useState(true);

  const { CurrentPermissionsManager, $scope, $state, $timeout } = useContext(AngularServicesContext) || {};
  const dispatch = useAppDispatch();
  const history = useHistory();

  /**
   * Resetting the loadTable value to true when it changes to false for
   * reloading the collections list table.
   */
  useEffect(() => {
    if (!loadCollectionsTable) {
      setLoadCollectionsTable(true);
    }
  }, [loadCollectionsTable]);

  /**
   * We are using react routing in content management pages. So the angular state
   * change not working as expected. Triggering a render on the angular side to
   * keep angular states updated with the current page.
   */
  useEffect(() => {
    $timeout(() => {
      if ($state.current.name !== 'content-library-collections') {
        $scope.$apply();
      }
    });
  }, []);

  /**
   * The 'canLinkCollectionLesson' and 'canLinkCollectionLesson' fields should
   * be unset if they have already been set. Otherwise, these values are never
   * re-fetch when accessing the outline or lecture page.
   */
  useEffect(() => {
    if (canLinkCollectionLesson !== null || canConvertToCollectionLesson !== null) {
      dispatch(unsetCollectionCanLinkAndConvert());
    }
  }, [canConvertToCollectionLesson, canLinkCollectionLesson, dispatch]);

  const unsetRowHighlightCatalogId = useCallback(() => {
    dispatch(setHighlightedCollectionId(null));
  }, [dispatch]);

  const closeCreateCollectionModel = () => {
    setShowCreationModal(false);
  };

  const tabs: NvTab[] = [
    {
      text: t.INSTITUTIONS.CONTENT_LIBRARY.ALL_COLLECTIONS(),
    },
  ];

  const closeModal = (collectionCatalogId: string = null, isUpdate: boolean, reoadList?: boolean) => {
    setShowCreationModal(false);

    if (collectionCatalogId) {
      if (!isUpdate) {
        setPermissionModalCatalogId(collectionCatalogId);
      }

      if (reoadList) {
        setLoadCollectionsTable(false);
      }

      // Setting the collection's row as highlighted.
      dispatch(setHighlightedCollectionId(collectionCatalogId));
    }
  };
  const onJoinCollection = (collection: CollectionCourse, courseRoleId: string) => {
    const payload = {
      catalogId: collection.catalogId,
      courseRoleId,
      users: [{
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
      }],
    };

    dispatch(addUsersToCourse(payload)).then((response) => {
      if (isEmpty(response?.error)) {
        history.push(`/collections/${collection.id}/${collection.catalogId}/home`);
      }
    });
  };

  const createCollectionBtn = (
    CurrentPermissionsManager?.hasCourseManagerPermissions() && (
      <Button
        className='d-flex align-items-center'
        variant='primary'
        size='sm'
        key='create-collection-btn'
        onClick={() => setShowCreationModal(true)}
        data-qa={config.pendo.contentManagement.openAddNewCollectionModal}
      >
        <NvIcon icon='create-new-post' size='xs-smallest' className='mr-1' />
        {t.INSTITUTIONS.CONTENT_LIBRARY.NEW_COLLECTION()}
      </Button>
    ));

  const getTitleLink = () => ({
    title: currentInstitution.name,
    href: $state.href('institution-dashboard', { institutionId: currentInstitution.id }),
  });

  return (
    <div css={styles}>
      <L1PageHeader
        title={t.INSTITUTIONS.CONTENT_LIBRARY.HEADER()}
        titleLink={getTitleLink()}
      />
      <div onClick={unsetRowHighlightCatalogId}>
        <NvResponsiveTabsRow
          defaultTabs={tabs}
          tabType={NvResponsiveTabsDisplayType.TEXT_ONLY}
          iconBtns={[createCollectionBtn]}
        />
        <div className='tab-content bg-gray-7'>
          {loadCollectionsTable && (
            <CollectionList
              showPermissionModal={(catalogId) => setPermissionModalCatalogId(catalogId)}
              closeModal={closeModal}
            />
          )}
        </div>
      </div>
      <NvModal
        type={ModalType.FIXED}
        header={t.INSTITUTIONS.CONTENT_LIBRARY.NEW_COLLECTION_FORM.TITLE()}
        show={showCreationModal}
        width={720}
        height={385}
        onClose={closeCreateCollectionModel}
        body={(
          <NewCollectionModalBody
            closeModal={closeModal}
          />
        )}
      />

      <ManagePermissionModal
        show={!!permissionModalCatalogId}
        closeModal={() => setPermissionModalCatalogId(null)}
        onJoin={onJoinCollection}
        catalogId={permissionModalCatalogId}
      />
    </div>
  );
};

export default CollectionDashboard;
