import { useHistory, useParams } from 'react-router-dom';
import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { getCourse } from 'redux/selectors/course';
import { useContext } from 'react';
import { AngularServicesContext } from 'react-app';
import { isEmpty } from 'underscore';
import { CollectionActionDropdown } from '../collection-action-dropdown';
import { config } from '../../../../config/config.json';

const TopHeader = () => {
  const styles = css`
    & {
      height: 55px;
    }

    .collection-name {
      white-space: pre-wrap;
    }
  `;

  const { catalogId } = useParams<{ collectionId: string, catalogId: string }>();
  const collection = useSelector((state) => getCourse(state, catalogId));
  const { CurrentPermissionsManager } = useContext(AngularServicesContext);

  const history = useHistory();

  const canViewEditMenu = !isEmpty(collection) && (
    CurrentPermissionsManager.hasCollectionManagerPermissions()
    || CurrentPermissionsManager.hasCollectionAdminPermissions());

  return (
    <div
      className='d-flex justify-content-between px-4 py-3 border-bottom border-gray-6'
      css={styles}
    >
      <div
        className='d-flex align-items-center course-title-small cursor-pointer'
        onClick={() => history.push('/collections/')}
        data-qa={config.pendo.contentManagement.backToCollectionList}
      >
        <NvIcon icon='back' size='small' className='pr-2' />
        {t.FORM.BACK()}
      </div>
      <div className='collection-name px-4 course-title-small ellipsis'>
        {collection?.title}
      </div>
      <div>
        {canViewEditMenu && (
          <CollectionActionDropdown
            iconClass='icon-small icon-settings'
            collection={collection}
          />
        )}
      </div>
    </div>
  );
};

export default TopHeader;

