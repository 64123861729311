/* @ngInject */
export default function LeaderboardManager(

  _,
  LeaderboardModel,
  CurrentUserManager,
  ConfettiAnimation,
) {
  const manager = {
    getLeaderboard,
    initialize,
  };

  function initialize(catalogId) {
    getLeaderboard(catalogId);
  }

  function getLeaderboard(catalogId) {
    return LeaderboardModel.getLeaderboard(catalogId).then((data) => {
      manager.leaderboard = data.leaderboard;
      manager.userBefore = data.userBefore;
      manager.currentUser = data.currentUser;
      manager.userAfter = data.userAfter;

      if (data.leaderboard.length) {
        manager.lastRankOnLeaderboard = _.last(data.leaderboard).rank;
      }

      manager.existsOnLeaderboard = _.find(manager.leaderboard, (learner) => learner.user.id === CurrentUserManager.user.id);

      if (manager.existsOnLeaderboard) {
        ConfettiAnimation.showConfetti();
      }

      // If current user has 0 points, update currentUser from null to currentUser info
      if (!manager.existsOnLeaderboard && !manager.currentUser) {
        manager.currentUser = {
          user: CurrentUserManager.user,
          receivedPoints: 0,
        };
      }
    });
  }

  return manager;
}
