import { useState, useEffect } from 'react';
import t from 'react-translate';

// Components
import { Button } from 'react-bootstrap';

// Styles
import { css } from '@emotion/react';
import { gray6, gray3 } from 'styles/global_defaults/colors';

import { config } from '../../../../config/pendo.config.json';

const styles = css`
  &.disabled {
    background-color: ${gray6};
    border-color: ${gray6};
    color: ${gray3};
    pointer-events: none;
  }
`;

export enum LiveSessionState {
  REGISTER = 'register',
  JOIN_NOW = 'join_now',
}

const actionSettings = {
  [LiveSessionState.REGISTER]: {
    type: 'outline-primary',
    text: 'REGISTER',
  },
  [LiveSessionState.JOIN_NOW]: {
    type: 'primary',
    text: 'JOIN_NOW',
  },
};

type LiveSessionCardStateProps = {
  status: LiveSessionState
  disabled: boolean
  joinUrl: string
  onClick: (state: LiveSessionState) => void
};

const LiveSessionCardAction = (props: LiveSessionCardStateProps) => {
  const { status, disabled, joinUrl, onClick } = props;
  const [text, setText] = useState('');
  const [variant, setVariant] = useState('');

  const translationPrefix = t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.SESSION_CARD.ACTION;

  useEffect(() => {
    const settings = actionSettings[status];
    setText(translationPrefix[settings.text]());
    setVariant(settings.type);
  }, [status]);

  return (
    <Button
      variant={variant}
      css={styles}
      disabled={disabled}
      href={joinUrl}
      target='_blank'
      onClick={() => onClick(status)}
      data-qa={config.pendo.liveEvent.liveSesssionCard.joinNow}
    >
      {text}
    </Button>
  );
};

export default LiveSessionCardAction;
