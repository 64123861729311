import { useCallback, useEffect } from 'react';
import humps from 'humps';

import { useAppDispatch } from 'redux/store';
import { SyncingStatus } from 'redux/schemas/models/lecture-page';
import { lecturePageSyncingStatusPusherUpdate } from 'redux/actions/lecture-pages';

import PusherService from 'shared/services/pusher-service';
import { LecturePageSyncEvent } from 'lecture_pages/components';


const useSyncingPusher = (collectionId) => {
  const dispatch = useAppDispatch();

  const pusherHandler = useCallback((data, status) => {
    const pusherData = humps.camelizeKeys(data) as unknown as LecturePageSyncEvent;
    const payload = {
      lecturePageId: pusherData[status === SyncingStatus.SYNC_IN_PROGRESS ? 'lecturePageId' : 'contentManagementCollectionLecturePageId'],
      hasUpdatedLinks: pusherData.hasUpdatedLinks,
    };

    dispatch(lecturePageSyncingStatusPusherUpdate(payload));
  }, [dispatch]);

  useEffect(() => {
    if (!collectionId) {
      return null;
    }

    const pusherChannel = PusherService.initializeCourseChannel(collectionId);
    pusherChannel.bind('lecture_page_sync_completed', (data) => pusherHandler(data, SyncingStatus.SYNC_IN_PROGRESS));
    pusherChannel.bind('lecture_page_link_completed', (data) => pusherHandler(data, SyncingStatus.LINK_IN_PROGRESS));
    pusherChannel.bind('lecture_page_convert_completed', (data) => pusherHandler(data, SyncingStatus.CONVERT_IN_PROGRESS));

    return () => {
      pusherChannel.unbind('lecture_page_sync_completed');
      pusherChannel.unbind('lecture_page_link_completed');
      pusherChannel.unbind('lecture_page_convert_completed');
    };
  }, [collectionId, pusherHandler]);
};

export default useSyncingPusher;
