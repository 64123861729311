import LearningJourneys from '../components/learning-journeys';

/* @ngInject */
export default function LearningJourneysCtrl(
  $scope,
  $uibModal,

  CourseModel,
  StateManager,
  AlertMessages,
) {
  $scope.$uibModal = $uibModal;
  $scope.CourseModel = CourseModel;
  $scope.StateManager = StateManager;
  $scope.AlertMessages = AlertMessages;
  $scope.LearningJourneys = LearningJourneys;
}
