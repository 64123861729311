import { css } from '@emotion/react';
import React, { useContext } from 'react';
import { extend } from 'underscore';
import { SanitizationLevel, sanitize } from 'froala/helpers/sanitizer';
import { AngularServicesContext } from 'react-app';
import t from 'react-translate';
import { BookmarkType, CourseObjectBookmark, DiscussionBookmarkComponent, TeamDiscussionBookmarkComponent } from 'redux/schemas/models/bookmark';
import ClickableContainer from 'components/clickable-container';
import { NvUserAvatar } from 'components/nv-user-avatar';
import { boldFontWeight, headerLineHeight, textSmallLineHeight, textXSFontSize } from 'styles/global_defaults/fonts';
import { standardSpacing, doubleSpacing, halfSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import { gray2 } from 'styles/global_defaults/colors';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import { config } from '../../../../config/pendo.config.json';

type CommentReplySnippetProps = {
  bookmark: CourseObjectBookmark
};

const CommentReplySnippet = ({
  bookmark: { catalogId, component, type: bookmarkType },
}: CommentReplySnippetProps) => {
  const styles = css`
    &.comment-reply-snippet {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${standardSpacing}px;
      padding-left: ${doubleSpacing}px;

      .text-wrapper {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: hidden;
        margin-right: ${standardSpacing}px;
        flex: 1;

        .title {
          line-height: ${headerLineHeight}px;
        }

        .snippet {
          margin-bottom: ${halfSpacing}px;
        }

        .subtitle {
          display: flex;
          font-weight: ${boldFontWeight};
          color: ${gray2};
          font-size: ${textXSFontSize}px;

          .lecture-page-name, .course-name {
            max-width: 50%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .middot {
            margin-left: ${quarterSpacing}px;
            margin-right: ${quarterSpacing}px;
          }
        }
      }
    }
  `;

  const angularServices = useContext(AngularServicesContext);
  const { $state, FlyoutModalManager } = angularServices;

  const {
    id,
    type,
    body,
    lecturePageName,
    courseName,
    user,
    commentableId,
    topic,
    expired,
    submission,
    owner,
  } = component as DiscussionBookmarkComponent;

  const { team } = (component as TeamDiscussionBookmarkComponent);
  const isComment = bookmarkType === BookmarkType.POST;
  const sanitizedBody = sanitize(body, SanitizationLevel.BOOKMARK_SNIPPET);
  const isSubmission = !!submission || owner?.type === 'report';

  const onClickSnippet = () => {
    if (expired) { return; }

    FlyoutModalManager?.closeFlyout();

    const linkParams = {
      catalogId,
    };

    if (isSubmission) {
      extend(linkParams, {
        reportId: (submission?.id || owner?.id),
      });

      if (isComment) {
        extend(linkParams, {
          commentId: id,
        });
      } else {
        extend(linkParams, {
          replyId: id,
        });
      }

      $state.go('individual-submission-modal', linkParams);
    } else if (team) {
      if (isComment) {
        extend(linkParams, {
          postId: topic.id,
          commentId: id,
          teamId: team.id,
        });
        $state.go('team-workspace-comment-direct-link', linkParams);
      } else {
        extend(linkParams, {
          postId: topic.id,
          commentId: commentableId,
          replyId: id,
          teamId: team.id,
        });
        $state.go('team-workspace-reply-direct-link', linkParams);
      }
    } else if (isComment) {
      extend(linkParams, {
        topicId: topic.forum.id,
        postId: topic.id,
        commentId: id,
      });
      $state.go('comment-direct-link', linkParams);
    } else {
      extend(linkParams, {
        topicId: topic.forum.id,
        postId: topic.id,
        commentId: commentableId,
        replyId: id,
      });
      $state.go('reply-direct-link', linkParams);
    }
  };

  return (
    <ClickableContainer className='comment-reply-snippet' css={styles} onClick={onClickSnippet} data-qa={config.pendo.bookmarks.commentReplySnippet}>
      <div className='text-wrapper'>
        <div className='title font-weight-bold'>
          {isComment
            ? t.LHS.BOOKMARKS.SNIPPET_TITLE.COMMENT(user.fullName)
            : t.LHS.BOOKMARKS.SNIPPET_TITLE.REPLY(user.fullName)}
        </div>
        <ResponsivelyEmbeddedAngularHTML
          template={sanitizedBody}
          className='snippet text-gray-2 text-xs'
          angularServices={angularServices}
        />
        <div className='subtitle text-gray-2 text-small'>
          {lecturePageName && (
            <>
              <div className='lecture-page-name'>{lecturePageName}</div>
              <div className='middot'>&middot;</div>
            </>
          )}
          <div className='course-name'>{courseName}</div>
        </div>
      </div>
      <NvUserAvatar
        tooltipEnabled={false}
        directToProfile={false}
        displayRoleBadge={false}
        user={user}
        size='md'
        borderType='round'
      />
    </ClickableContainer>
  );
};

export default CommentReplySnippet;
