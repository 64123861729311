/* @ngInject */
export default function AnnouncementFormModalCtrl(

  $scope,
  AnnouncementsManager,
  CurrentCourseManager,
  CurrentUserManager,
  StateManager,
  ConfirmationOverlays,
  AnnouncementModel,
  $stateParams,
  $uibModalInstance,
  $timeout,
  // resolved data
  originalAnnouncement,
) {
  const vm = this;

  vm.CurrentUserManager = CurrentUserManager;
  vm.previewEmailMode = false;
  vm.course = CurrentCourseManager.course;

  vm.originalAnnouncement = originalAnnouncement;
  if (originalAnnouncement) {
    vm.announcement = originalAnnouncement.clone();
  } else {
    vm.announcement = AnnouncementModel.new($stateParams.catalogId);
  }

  function post() {
    vm.saving = true;

    AnnouncementsManager.save(vm.announcement)
      .then((announcement) => {
        vm.dismissedByUser = true;
        $uibModalInstance.close(announcement);
      });
  }

  vm.attemptPost = () => {
    // first time immediately releasing announcement
    const newReleasing = !originalAnnouncement && !vm.announcement.scheduledDate;
    const existingReleasing = originalAnnouncement?.scheduledForFuture() && !vm.announcement.scheduledDate;

    if (newReleasing || existingReleasing) {
      ConfirmationOverlays.openConfirmationModal('announcements/templates/admin/post-confirmation-overlay.html',
        'AttachModalResolvesToVmCtrl', {
          vmResolves() {
            return {
              announcement: vm.announcement,
            };
          },
        })
        .result.then(post);
    } else {
      post();
    }
  };

  vm.shouldShowPreviewButton = () => {
    const noPreviewNeeded = !vm.announcement.hasEmail || (originalAnnouncement?.hasBeenReleased());

    return !noPreviewNeeded;
  };

  vm.previewEmail = (announcement) => {
    announcement.getPreview().then((result) => {
      vm.previewEmailMode = true;
      $timeout(() => {
        $('#preview').contents().find('body').html(vm.announcement.preview.body);
      }, 0);
    });
  };

  vm.cancelPreview = () => {
    vm.previewEmailMode = false;
    $timeout(() => {
      $timeout(() => {
        vm.announcementForm.$setDirty();
      });
    });
  };

  /* Closing of Modal */
  vm.cancel = (force) => {
    $uibModalInstance.dismiss();
  };

  function isFormDirty() {
    return !!(vm.announcementForm?.$dirty);
  }

  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(
    () => isFormDirty() || vm.previewEmailMode,
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    'FORM.NAVIGATE_AWAY.CLOSE_WINDOW',
    undefined,
    undefined,
    () => {
      vm.deregisterModalClosing?.();

      $uibModalInstance.dismiss();
    },
  );

  vm.deregisterModalClosing = $scope.$on('modal.closing', ($event) => {
    if ((isFormDirty() || vm.previewEmailMode) && !vm.dismissedByUser) {
      $event.preventDefault();

      ConfirmationOverlays.openConfirmationModal('announcements/templates/admin/unsaved-close-window-overlay.html')
        .result.then(() => {
          vm.dismissedByUser = true;
          $uibModalInstance.close();
        });
    }
  });

  $scope.$on('$destroy', () => {
    vm.deregisterStateChangeStart?.();
  });
}
