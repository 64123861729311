
import React, { useCallback, useEffect } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';

// Actions
import { getDraftCommunications } from 'redux/actions/course-communications';

// Components
import NvIcon from 'shared/components/nv-icon';

// Styles
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { gray4 } from 'styles/global_defaults/colors';

// Selectors
import { getDraftCommunicationIds, isDraftCommunicationsLoaded } from 'redux/selectors/course-communications';
import { getCurrentCourse } from 'redux/selectors/course';
import EmptyList from 'shared/components/empty-list';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';
import LoadingPlaceholder from './loading-placeholder';
import PageIntro from './page-intro';

const DraftCommunications = () => {
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const { isJourney } = useSelector((state) => getCurrentCourse(state));
  const dispatch = useAppDispatch();
  const fetchDrafts = useCallback((props) => {
    dispatch(getDraftCommunications(props));
  }, []);

  useEffect(() => {
    if (catalogId) {
      fetchDrafts({
        catalogId,
      });
    }
  }, [catalogId, fetchDrafts]);

  const draftCommunicationIds = useSelector((state) => getDraftCommunicationIds(state));

  const isLoaded = useSelector((state) => isDraftCommunicationsLoaded(state));
  const getNoContentComponent = () => {
    const style = css`
      display: flex;
      align-items: center;
      padding: 0px ${standardSpacing}px;
      .icon {
        color: ${gray4};
        padding: ${standardSpacing}px;
      }
    `;
    if (isJourney) {
      return (
        <div css={style} className='bg-info mt-6 text-center'>
          <div className='bold'>
            <NvIcon size='large' icon='drafts' />
          </div>
          <div className='text-regular'>
            {t.JOURNEY_COMMUNICATIONS.NO_CONTENT.DRAFTS()}
          </div>
        </div>
      );
    }
    return (
      <EmptyList icon='drafts' description={t.COURSE_COMMUNICATIONS.NO_CONTENT.DRAFTS()} />
    );
  };

  return (
    <React.Fragment>
      { !isLoaded && <LoadingPlaceholder /> }
      { isLoaded && (
        <React.Fragment>
          <PageIntro
            title={t.COURSE_COMMUNICATIONS.TABS.TITLE.DRAFTS()}
            description={t.COURSE_COMMUNICATIONS.TABS.DESCRIPTION.DRAFTS()}
          />
          {isEmpty(draftCommunicationIds)
            ? getNoContentComponent()
            : (
              <div className='pt-6'>
                {
                  draftCommunicationIds.map(communicationId => (
                    <div className='mb-4' key={communicationId.toString()}>
                      <CommunicationItem communicationId={communicationId} showOwnerActivity />
                    </div>
                  ))
                }
              </div>
            )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default DraftCommunications;
