// we need to use this more involved Google Drive service (which involves a redirect to the backend endpoint) when we need to save a refresh token so that the backend can

import t from 'react-translate';
import get from 'lodash/get';
import store from '../../redux/store';
// do complicated things for the app on behalf of the user behind the scenes.
/* @ngInject */
export default function MetaTagsService(
  ReportsManager,
  CurrentCourseManager,
  StatementsManager,
  InstitutionsManager,
  CurrentUserManager,
) {
  return {
    getStateData,
    getDescription,
    getPageTitle,
  };

  function getStateData(stateName) {
    if (stateName === 'individual-submission-page') {
      return ReportsManager.currentReport ? ReportsManager.currentReport.getMetaTags() : { };
    } if (stateName === 'statement-page' || stateName === 'statement-modal') {
      return StatementsManager.getMetaTags();
    } if (stateName === 'course-flyer') {
      return CurrentCourseManager.getMetaTags();
    }
    return { };
  }

  function getPageTitle(lastState) {
    const state = store.getState();
    const currentLectureId = state.app.lecturePage?.currentLectureId;
    if (lastState?.parent === 'lecture-page-parent'
      && currentLectureId
      && state.models.lecturePages
      && state.models.lecturePages[currentLectureId]?.title
      && CurrentCourseManager.course) {
      return `${state.models.lecturePages[currentLectureId]?.title} | ${CurrentCourseManager.course.name}`;
    }
    // We are adding custo tab/window titles here, so the translation file is needed.
    // all routes with tabTitleOption set on the data (on routes.js) will display custom title
    // We added this for standford, to properly display for dashboards, course home and discussions
    // If we need to add more, change here and set the proper value on routes.js
    if (lastState?.data?.tabTitleOption) {
      // we have the title key, need to extract the right value from t
      const title = get(t, lastState?.data?.titleKey)?.();
      if (lastState.data.tabTitleOption === 'title_institution') {
        return `${title} | ${InstitutionsManager?.institution?.name}`;
      }
      if (lastState.data.tabTitleOption === 'title_course') {
        return `${title} | ${CurrentCourseManager.course.name}`;
      }
    }
    if (CurrentCourseManager.course) {
      return `${CurrentCourseManager.course.name} | ${InstitutionsManager?.institution?.name
        || CurrentUserManager?.currentInstitution?.name
        || CurrentCourseManager.course.institution?.name}`;
    }

    if (InstitutionsManager?.institution?.name) {
      return InstitutionsManager.institution.name;
    }

    if (CurrentUserManager?.currentInstitution?.name) {
      return CurrentUserManager.currentInstitution.name;
    }

    return '';
  }

  function getDescription(stateName) {
    const stateData = getStateData(stateName);
    return stateData ? stateData['og:description'] : '';
  }
}
