import { jsx } from '@emotion/react';
import { NvUserAvatar, RoleBadgePlacement } from 'components/nv-user-avatar';
import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/selectors/users';

interface PollVoteAvatarProps {
  voterId: number;
}

/* @ngInject */
export default function PollVoterAvatar(props: PollVoteAvatarProps) {
  const user = useSelector((state) => getUser(state, props.voterId));

  return (
    <NvUserAvatar
      size='sm'
      user={user}
      borderType='round'
      directToProfile
      tooltipPlacement='top'
      displayRoleBadge
      roleBadgePlacement={RoleBadgePlacement.BOTTOM}
    />
  );
}
