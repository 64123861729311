import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';

type ViewValidationErrorProps = {
  characterLimit: string
};
const ViewValidationError = (props: ViewValidationErrorProps) => (
  <div className='mt-2 d-flex align-items-center justify-content-end text-danger'>
    <NvIcon
      icon='warning'
      size='small'
      className='align-items-center'
    />
    <p className='text-large-body mb-0 ml-2'>{t.VALIDATION.CHARACTER_LIMIT_REACHED(props.characterLimit)}</p>
  </div>
);

export default ViewValidationError;
