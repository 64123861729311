import RouterRoot from '../components/router-root';

export default class InstitutionDashboardReactController {
  /* @ngInject */
  constructor(
    $scope,
    StateManager,
  ) {
    $scope.StateManager = StateManager;
    $scope.InstitutionDashboard = RouterRoot;
  }
}
