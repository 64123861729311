import NvCourseAvatar from 'components/nv-course-avatar';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Course, CourseRegistrationType } from 'redux/schemas/models/course';
import { CombinedInstitution, RootState } from 'redux/schemas';
import Badge from 'cohort_management/components/badge';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { css } from '@emotion/react';
import { gray5 } from 'styles/global_defaults/colors';
import NvIcon from 'shared/components/nv-icon';
import moment from 'moment';
import t from 'react-translate';
import { doubleSpacing, halfSpacing, quarterSpacing, tooltipZIndex } from 'styles/global_defaults/scaffolding';
import NvPopover from 'shared/components/nv-popover';
import { CloneCourseContext, cloneCourseContextType } from './hooks/use-clone-course-form';
import NvTooltip from 'shared/components/nv-tooltip';

type CourseInfoProps = {
  course: Course,
  totalCourseAdmins: number,
};

const CourseInfo = ({
  course,
  totalCourseAdmins,
}: CourseInfoProps) => {
  const cloneCourseFormMethods = useContext<cloneCourseContextType>(CloneCourseContext);
  const {
    stringFormatDate,
  } = cloneCourseFormMethods;
  const currentInstitution = useSelector<RootState, CombinedInstitution>(getCurrentInstitution);
  const at = t.LECTURE_VIDEO.AT();
  const startDateText = course.officialReleaseDate
    ? `${moment(course.officialReleaseDate).format('MM/DD/YYYY [at] hh:mm A')}`.replace('at', at)
    : t.COURSES.CLONE.STEPS.STEP_ONE.NOT_SET();
  const styleCourseInfo = css`
    padding: 10px;
    border: solid 1px ${gray5} ;
    .course-avatar-container {
      position: relative;

      .avatar-container {
        display: unset;
      }

      .cohort-management-badge {
        pointer-events: all;
        top: -5px;
        left: -7px;
      }
    }
    .data {
      width: calc(100% - ${doubleSpacing}px);
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
      padding: 0px 20px;
    }
    .icon {
      padding: 0px ${quarterSpacing}px;
    }
  `;

  return (
    <div css={styleCourseInfo} className='bg-gray-7 d-flex'>
      <div className='course-avatar-container'>
        <NvCourseAvatar course={course} institution={currentInstitution} size='md' />
        <Badge
          className='cohort-management-badge'
          course={{
            isCohort: course.isCohort,
            isPrimary: course.isPrimary,
          } as Course}
        />
      </div>
      <div className='data'>
        <InfoSection
          title={t.COURSES.CLONE.COURSE_DETAILS.COURSE_NAME()}
          content={course.name}
          showTooltip
        />
        <InfoSection
          title={t.COURSES.CLONE.COURSE_DETAILS.CATALOG_ID()}
          content={course.catalogId}
        />
        <InfoSection
          title={t.COURSES.CLONE.COURSE_DETAILS.START_DATE()}
          content={(
            <div className='d-flex align-items-center'>
              {startDateText}
              <CourseDatesDetails
                release={stringFormatDate(course.releaseDate)}
                start={stringFormatDate(course.officialReleaseDate)}
                registration={stringFormatDate(course.registrationCloseDate)}
                end={stringFormatDate(course.endDate)}
                closure={stringFormatDate(course.closeDate)}
                icon='info'
                typeOfRegistration={course.typeOfRegistration}
              />
            </div>
            )}
        />
        <InfoSection
          title={t.COURSES.CLONE.COURSE_DETAILS.ADMIN_USERS()}
          content={totalCourseAdmins}
        />
      </div>
    </div>
  );
};

export const InfoSection = ({
  title,
  content,
  showTooltip = false,
}) => {
  const style = css`
    flex-direction: column;
    justify-content: space-between;
    padding-right: ${quarterSpacing}px;
    .value {
      width: min-content;
      max-width: 100%;
    }
  `;
  return (
    <div css={style} className='text-small d-flex'>
      <div className='title text-gray-2'>
        {title}
      </div>
      <NvTooltip
        text={content}
        preventOverflow={false}
        enabled={showTooltip}
      >
        <div className='value ellipsis semi-bold'>
          {content}
        </div>
      </NvTooltip>
    </div>
  );
};

export const CourseDatesDetails = ({
  release,
  start,
  registration,
  end,
  closure = '--',
  icon,
  typeOfRegistration,
}) => {
  const contentStyles = css`
    .title {
      padding-bottom: ${halfSpacing}px;
    }
  `;
  const popoverStyles = css`
    max-width: unset;
  `;
  const registrationWithRegistrationDates = [
    CourseRegistrationType.OPEN_BASED_ON_ENTITLEMENTS,
    CourseRegistrationType.FREE_ENROLLMENT,
    CourseRegistrationType.FREE_ENROLLMENT_IN_INSTITUTION,
  ];
  return (
    <NvPopover
      placement='top'
      showOnHover
      content={(
        <div css={contentStyles}>
          <div className='title gray-3'>
            {t.COURSES.CLONE.COURSE_DETAILS.TOOLTIP.TITLE()}
          </div>
          <div className='details'>
            <div>{t.COURSES.CLONE.COURSE_DETAILS.TOOLTIP.RELEASE_DATE()}: <b>{release}</b></div>
            <div>{t.COURSES.CLONE.COURSE_DETAILS.TOOLTIP.START_DATE()}: <b>{start}</b></div>
            {
              registrationWithRegistrationDates.includes(typeOfRegistration)
              && <div>{t.COURSES.CLONE.COURSE_DETAILS.TOOLTIP.REGISTRATION_DATE()}: <b>{registration}</b></div>
            }
            <div>{t.COURSES.CLONE.COURSE_DETAILS.TOOLTIP.END_DATE()}: <b>{end}</b></div>
            <div>{t.COURSES.CLONE.COURSE_DETAILS.TOOLTIP.CLOSURE_DATE()}: <b>{closure}</b></div>
          </div>
        </div>
        )}
      overlayStyles={popoverStyles}
      >
      <div className='info-dates'>
        <NvIcon icon={icon} size='xss-smallest' className='text-primary' />
      </div>
    </NvPopover>
  );
};

export default CourseInfo;
