import { useHistory, useParams } from 'react-router-dom';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import { css } from '@emotion/react';
import { AngularServicesContext } from 'react-app';
import { Container, Draggable } from 'react-smooth-dnd';
import { primary } from 'styles/global_defaults/colors';
import { doubleSpacing } from 'styles/global_defaults/scaffolding';

// redux
import { useAppDispatch } from 'redux/store';
import { fetchCollection, reorderFolder, reorderLesson, setFolderExpandedStatus, setHighlightedCollectionId, setHighlightedLessonId } from 'redux/actions/collections';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { setCurrentCatalogId } from 'redux/actions/courses';

// components
import NvIcon from 'shared/components/nv-icon';
import { getCourse } from 'redux/selectors/course';
import { CollectionCourse } from 'redux/schemas/models/collections';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import TopHeader from './top-header';
import DetailsHeader from './details-header';
import Folder from './folder';

interface ReorderedLessonData {
  addedIndex: number
  removedIndex: number
  lessonId: number
  destinationFolderId: number
  sourceFolderId: number
  roleBackSourceFolder: boolean
  roleBackDestinationFolder: boolean
}

type InitialStateType = {
  reorderLessonData: ReorderedLessonData
  setReorderLessonData: Function
  isDragging: boolean
  setIsDragging: Function
  isLessonDragging: boolean
  setIsLessonDragging: Function
};

const initialState: InitialStateType = {
  reorderLessonData: {
    addedIndex: null,
    removedIndex: null,
    lessonId: null,
    destinationFolderId: null,
    sourceFolderId: null,

    /**
     * these fields are set true to trigger the roleback the source and destination
     * folder when reorder of lesson between folders request fail
     */
    roleBackSourceFolder: false,
    roleBackDestinationFolder: false },
  setReorderLessonData: (reorderedLessonData: ReorderedLessonData) => {},
  isDragging: false,
  setIsDragging: (isDragging: boolean) => {},
  isLessonDragging: false,
  setIsLessonDragging: (isLessonDragging: boolean) => {},
};
export const CollectionHomeContext = createContext(initialState);
const CollectionHome = () => {
  const styles = css`
    .no-folder {
      .icon-large {
        font-size: ${doubleSpacing}px;
      }
    }
    .smooth-dnd-container {
      .smooth-dnd-draggable-wrapper {
        overflow: visible;
      }
      .drag-folder {
        border: 2px solid ${primary};
      }
    }
    .dragging {
      .smooth-dnd-container {
        border: 1.5px dashed ${primary};
        // not giving dotted border for the lessons container while dragging folder
        .smooth-dnd-container {
          border: none;
        }
      }
    }
    .dragging.lesson-dragging {
      .smooth-dnd-container {
        // not giving dotted border for parent container when lesson is dragging
        border: none;
        .smooth-dnd-container {
          border: 1.5px dashed ${primary};
        }
      }
    }
    .folders-list {
      // total height - (top header height + details header height)
      height: calc(100vh - 115px);
      overflow-y: scroll;
    }
  `;

  const [isCollectionLoading, setIsCollectionLoading] = useState(false);
  const [collectionFolderIds, setCollectionFolderIds] = useState([]);
  const [reorderLessonData, setReorderLessonData] = useState({ ...initialState.reorderLessonData });
  // isDragging will set to true while dragging lesson or folder
  const [isDragging, setIsDragging] = useState(false);
  // isLessonDragging will set to true only while dragging lesson
  const [isLessonDragging, setIsLessonDragging] = useState(false);
  const dispatch = useAppDispatch();
  const { collectionId, catalogId } = useParams<{ collectionId: string, catalogId: string }>();
  const currentCollection: CollectionCourse = useSelector((state) => getCourse(state, catalogId));

  const { CurrentPermissionsManager, $scope, $state, $timeout } = useContext(AngularServicesContext);
  const history = useHistory();

  const getCollection = useCallback(() => {
    if (collectionId) {
      dispatch(fetchCollection(collectionId)).then((response) => {
        if (isEmpty(response?.error) && !isEmpty(response.payload?.userCourse)) {
          /**
           * There is no isEnrolled field in the collection API userCourse,
           * so adding it as true if there is a userCourse object.
           */
          response.payload.userCourse.isEnrolled = true;
          CurrentPermissionsManager.setCurrentCourse(new $scope.CourseModel(response.payload));
          dispatch(setCurrentCatalogId({ id: +collectionId, catalogId }));
          setIsCollectionLoading(false);
          const folderIds = response.payload.folders.map((eachFolder) => eachFolder.id);
          setCollectionFolderIds([...folderIds]);
        } else {
          history.push('/collections/');

          dispatch(addAlertMessage({
            type: AlertMessageType.ERROR,
            header: t.FORM.ERROR_SOMETHING_WRONG(),
          }));
        }
      });
    }
  }, [$scope.CourseModel, CurrentPermissionsManager, catalogId, collectionId, dispatch, history]);

  /**
   * We are using react routing in content management pages. So the angular state
   * change not working as expected. Triggering a render on the angular side to
   * keep angular states updated with the current page.
   */
  useEffect(() => {
    $timeout(() => {
      if ($state.current.name !== 'content-library-collection-home') {
        $scope.$apply();
      }
    });
  }, []);

  useEffect(() => {
    setIsCollectionLoading(true);
    getCollection();
  }, [getCollection]);

  /**
   * Setting the folder as expanded and lesson as highlighted if the state param
   * has lesson id and folder id.
   */
  useEffect(() => {
    if ($state.params.folderId) {
      dispatch(setFolderExpandedStatus({
        folderId: $state.params.folderId,
        value: true,
      }));
    }
    if ($state.params?.lessonId) {
      dispatch(setHighlightedLessonId($state.params.lessonId));
    }
  }, [$state.params, dispatch]);

  // Setting the collection as highlighted when accessing the collection home
  useEffect(() => {
    dispatch(setHighlightedCollectionId(catalogId));
  }, [catalogId, dispatch]);

  const handleFolderDrop = (e) => {
    const prevFolders = [...collectionFolderIds];
    const [movedFolder] = prevFolders.splice(e.removedIndex, 1);
    prevFolders.splice(e.addedIndex, 0, movedFolder);
    setCollectionFolderIds([...prevFolders]);
    setIsDragging(false);
    dispatch(reorderFolder({
      folderId: movedFolder,
      addedIndex: e.addedIndex,
      folders: prevFolders,
      catalogId,
    })).then((res) => {
      if (!isEmpty(res.error)) {
        setCollectionFolderIds([...currentCollection.folders]);
      }
    });
  };

  /**
    * useEffect to dispatch the reorder lesson between two folders,
    * action is dispatched only after getting added index and removed index of lesson from the corresponding folders
    */
  useEffect(() => {
    if (reorderLessonData.addedIndex !== null && reorderLessonData.removedIndex !== null) {
      dispatch(reorderLesson({
        destinationFolderId: reorderLessonData.destinationFolderId,
        sourceFolderId: reorderLessonData.sourceFolderId,
        addedIndex: reorderLessonData.addedIndex,
        removedIndex: reorderLessonData.removedIndex,
        lessonId: reorderLessonData.lessonId,
      })).then((res) => {
        setIsDragging(false);
        if (!isEmpty(res.error)) {
          setReorderLessonData({ ...reorderLessonData,
            addedIndex: null,
            removedIndex: null,
            lessonId: null,
            roleBackDestinationFolder: true,
            roleBackSourceFolder: true });
        } else {
          setReorderLessonData({ ...initialState.reorderLessonData });
        }
      });
    }
  }, [dispatch, reorderLessonData.addedIndex, reorderLessonData.removedIndex]);

  return (
    (!isCollectionLoading) && (
      <CollectionHomeContext.Provider value={{ reorderLessonData, setReorderLessonData, isDragging, setIsDragging, isLessonDragging, setIsLessonDragging }}>
        <div css={styles}>
          <TopHeader />
          <DetailsHeader
            setCollectionFolderIds={setCollectionFolderIds}
          />
          {isEmpty(collectionFolderIds) ? (
            <div className='no-folder mt-6 d-flex flex-column align-items-center'>
              <NvIcon icon='admin-folder' size='large' className='text-gray-5' />
              <div className='text-gray-3 text-regular bold mt-4'>
                {t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.NO_FOLDERS()}
              </div>
            </div>
          ) : (
            <div className={`folders-list ${isDragging ? 'dragging' : ''} ${isLessonDragging ? 'lesson-dragging' : ''} bg-gray-7 pb-6`}>
              <Container
                onDragStart={(e) => setIsDragging(true)}
                onDragEnd={(e) => setIsDragging(false)}
                dragHandleSelector='.drag'
                nonDragAreaSelector='.non-draggable'
                dragClass='drag-folder'
                onDrop={handleFolderDrop}
                lockAxis='y'
              >
                {collectionFolderIds.map((folderId, i) => (
                  <Draggable key={folderId}>
                    <Folder
                      isDragging={isDragging}
                      key={folderId}
                      folderId={folderId}
                      isLast={(collectionFolderIds.length - 1) === i}
                      setCollectionFolderIds={setCollectionFolderIds}
                    />
                  </Draggable>
                ))}
              </Container>
            </div>
          )}
        </div>
      </CollectionHomeContext.Provider>
    )
  );
};

export default CollectionHome;

