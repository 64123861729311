import React from 'react';
import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import t from 'react-translate';
import { validateActivityCode } from 'redux/actions/learning-journeys';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import { Section } from './basics';

export type SumTotal = {
  lmsActivityId: string,
  lmsActivityCode: string,
  editing?: boolean,
  validating?: boolean,
  validationError?: boolean,
};

type SumTotalProps = {
  institutionId: number,
  lmsActivityId: string,
  lmsActivityCode: string,
  hasBeenReleased: boolean,
  onChange: Function,
  onError: Function,
};

const DEFAULT_SUMTOTAL_DATA = {
  validating: false,
  editing: false,
  validationError: false,
};

const SumTotalIntegration = (props: SumTotalProps) => {
  const {
    institutionId,
    lmsActivityId,
    hasBeenReleased,
  } = props;
  const dispatch = useAppDispatch();
  const [sumTotalIntegration, setSumTotalIntegration] = React.useState<SumTotal>({
    ...DEFAULT_SUMTOTAL_DATA,
    lmsActivityCode: props.lmsActivityCode,
    lmsActivityId,
  });
  const { setValue, watch } = useFormContext() || {};
  const lmsActivityCode = watch('lmsActivityCode');

  const handleValidationClick = () => {
    const validatingActivity = {
      lmsActivityCode,
      validating: true,
      editing: false,
      validationError: false,
    };
    const payload = { institutionId, lmsActivityCode };

    let activityId = '';
    let validationError = false;

    setSumTotalIntegration(prevSumTotal => ({
      ...prevSumTotal,
      ...validatingActivity,
    }));

    wrapThunkAction(dispatch(validateActivityCode(payload))).then((action: any) => {
      ({ lmsActivityId: activityId } = action.payload);
    }).catch(() => {
      validationError = true;
    }).finally(() => {
      setValue('lmsActivityId', activityId);
      setSumTotalIntegration(prevSumTotal => ({
        ...prevSumTotal,
        validating: false,
        lmsActivityId: activityId,
        validationError,
      }));
    });
  };

  const shouldDisableValidateButton = () => !sumTotalIntegration.editing || hasBeenReleased;

  const shouldShowValidateButton = () => {
    const { editing, validationError } = sumTotalIntegration;
    return editing || !validationError || !lmsActivityCode;
  };

  const shouldShowSuccessMessage = () => {
    const { editing, validating, lmsActivityId: activityId } = sumTotalIntegration;
    const isValidated = activityId && !!lmsActivityCode;
    return !hasBeenReleased && !editing && !validating && isValidated;
  };

  const hasValidationError = () => {
    const { editing, validationError, validating } = sumTotalIntegration;
    return validationError && !editing && !validating && lmsActivityCode;
  };

  const shouldDisableActivityCode = () => hasBeenReleased || sumTotalIntegration.validating;

  const hasError = () => {
    const isNotEmpty = !!sumTotalIntegration.lmsActivityId && !lmsActivityCode;
    const hasError = hasValidationError() || isNotEmpty;
    props.onError(hasError);
    return hasError ? {
      type: 'value',
      message: t.COURSES.FORM.SUMTOTAL_INTEGRATION.ACTIVITY_NOT_FOUND(),
    } : null;
  };

  React.useEffect(() => {
    setSumTotalIntegration(prevSumTotal => ({
      ...prevSumTotal,
      editing: !!lmsActivityCode && prevSumTotal.lmsActivityCode !== lmsActivityCode,
    }));
  }, [lmsActivityCode]);

  React.useEffect(() => {
    props.onChange(sumTotalIntegration);
  }, [props, sumTotalIntegration]);

  return (
    <Section title={t.COURSES.FORM.SUMTOTAL_INTEGRATION.TITLE()}>
      <div className='col-xs-12 sumtotal-row'>
        <div className='col-xs-5'>
          <NvTextInput
            withForm
            disabled={shouldDisableActivityCode()}
            name='lmsActivityCode'
            placeholder={t.COURSES.FORM.SUMTOTAL_INTEGRATION.ACTIVITY_CODE()}
            defaultValue={props.lmsActivityCode || ''}
            error={hasError()}
          />
        </div>
        {shouldShowValidateButton() && !shouldShowSuccessMessage() && (
          <div className='col-xs-3 vertical-center-btn'>
            <Button
              type='button'
              onClick={handleValidationClick}
              disabled={shouldDisableValidateButton()}
            >
              {sumTotalIntegration.validating ? t.COURSES.FORM.SUMTOTAL_INTEGRATION.VALIDATING() : t.COURSES.FORM.SUMTOTAL_INTEGRATION.VALIDATE()}
            </Button>
          </div>
        )}
        {shouldShowSuccessMessage() && (
          <div className='col-xs-3 vertical-center-text text-success'>
            <label htmlFor='lmsActivityCode'>{t.COURSES.FORM.SUMTOTAL_INTEGRATION.SUCCESS_VALIDATION()}</label>
          </div>
        )}
        {hasValidationError() && (
          <div className='col-xs-3 vertical-center-text text-danger'>
            <label htmlFor='lmsActivityCode'>{t.COURSES.FORM.SUMTOTAL_INTEGRATION.ACTIVITY_NOT_FOUND()}</label>
          </div>
        )}
      </div>
      <div className='col-xs-12 sumtotal-row'>
        <div className='col-xs-5'>
          <NvTextInput
            withForm
            disabled
            name='lmsActivityId'
            placeholder={t.COURSES.FORM.SUMTOTAL_INTEGRATION.ACTIVITY_ID()}
            defaultValue={lmsActivityId || ''}
          />
        </div>
      </div>
      <div className='col-xs-8 sumtotal-row'>
        <div className='field-info'>
          {t.COURSES.FORM.SUMTOTAL_INTEGRATION.LEARNING_JOURNEYS.INDICATIONS()}
        </div>
      </div>
    </Section>
  );
};

export default SumTotalIntegration;
