/* @ngInject */
export default function LearnerProfileModel(
  LearnerProfileResources,
  _,
) {
  const LearnerProfile = function (attributes) {
    const _this = this;
    _.extend(_this, attributes);
  };

  LearnerProfile.getProfile = function (userId) {
    return LearnerProfileResources.getProfile({ userId }).$promise
      .then((resource) => resource.result);
  };

  LearnerProfile.getCourseProfile = function (userId, catalogId) {
    return LearnerProfileResources.getCourseProfile({ catalogId, userId }).$promise
      .then((resource) => resource.result);
  };


  LearnerProfile.getViewerMemberships = function (userId, catalogId) {
    return LearnerProfileResources.getViewerMemberships({ catalogId, vieweeId: userId }).$promise
      .then((resource) => resource.result);
  };

  LearnerProfile.getActivityMetrics = function (userId, catalogId) {
    if (catalogId) {
      return LearnerProfileResources.getCourseActivityMetrics(catalogId, userId).$promise
        .then((resource) => resource.result);
    }
    return LearnerProfileResources.getActivityMetrics(userId).$promise
      .then((resource) => resource.result);
  };

  LearnerProfile.getSubmissions = function (userId, catalogId) {
    if (catalogId) {
      return LearnerProfileResources.getCourseSubmissions(catalogId, userId).$promise
        .then((resource) => resource.result);
    }
    return LearnerProfileResources.getSubmissions(userId).$promise
      .then((resource) => resource.result);
  };

  LearnerProfile.getSubmissions = function (userId, catalogId) {
    return LearnerProfileResources.getSubmissions(userId).$promise
      .then((resource) => resource.result);
  };

  LearnerProfile.getPrivateSubmissions = function (userId, catalogId) {
    return LearnerProfileResources.getPrivateSubmissions(userId).$promise
      .then((resource) => resource.result);
  };

  LearnerProfile.getCertificates = function (userId) {
    return LearnerProfileResources.getCertificates({ vieweeId: userId }).$promise
      .then((resource) => resource.result);
  };

  LearnerProfile.getDiscussionContributions = function (catalogId, userId) {
    return LearnerProfileResources.getDiscussionContributions(catalogId, userId).$promise
      .then((resource) => resource.result);
  };

  LearnerProfile.getCourseProfileQuestions = function (catalogId, userId) {
    return LearnerProfileResources.getCourseProfileQuestions(catalogId, userId).$promise
      .then((resource) => resource.result);
  };

  LearnerProfile.saveProfile = function (catalogId, payload) {
    return LearnerProfileResources.saveProfile({ catalogId }, payload).$promise
      .then((resource) => resource.result);
  };

  LearnerProfile.updateLoginCredentials = function (userId, payload) {
    return LearnerProfileResources.updateLoginCredentials({ userId }, payload).$promise
      .then((resource) => resource.result);
  };

  LearnerProfile.sendMessage = function (catalogId, payload) {
    return LearnerProfileResources.sendMessage({ catalogId }, payload).$promise
      .then((resource) => resource.result);
  };

  // Admin controls
  LearnerProfile.grantSop = function (catalogId, userId) {
    return LearnerProfileResources.grantSop({ catalogId, userId }, {}).$promise
      .then((resource) => resource.result);
  };

  LearnerProfile.grantSopWithDistinction = function (catalogId, userId) {
    return LearnerProfileResources.grantSopWithDistinction({ catalogId, userId }, {}).$promise
      .then((resource) => resource.result);
  };

  LearnerProfile.revokeSop = function (catalogId, userId) {
    return LearnerProfileResources.revokeSop({ catalogId, userId }, {}).$promise
      .then((resource) => resource.result);
  };

  LearnerProfile.unenroll = function (catalogId, userId) {
    return LearnerProfileResources.unenroll({ catalogId, userId }, {}).$promise
      .then((resource) => resource.result);
  };

  LearnerProfile.delete = function (userId) {
    return LearnerProfileResources.delete({ userId }, {}).$promise
      .then((resource) => resource.result);
  };

  LearnerProfile.inviteMember = function (catalogId, teamId, userId) {
    return LearnerProfileResources.inviteMember({ catalogId, teamId }, { userIds: [userId] }).$promise
      .then((resource) => resource.result);
  };

  LearnerProfile.uninviteMember = function (catalogId, teamId, userId) {
    return LearnerProfileResources.uninviteMember({ catalogId, teamId }, { userId }).$promise
      .then((resource) => resource.result);
  };

  return LearnerProfile;
}
