import { ReactNode, useState } from 'react';
import t from 'react-translate';
import { css } from '@emotion/react';
import NvTooltip from 'shared/components/nv-tooltip';
import ClickableContainer from 'components/clickable-container';
import { copyToClipboard } from 'shared/react-utils';

type Props = {
  text: string,
  children: ReactNode,
};

export const NvClickToCopy = (props: Props) => {
  const {
    text,
    children,
  } = props;

  const [copied, setCopied] = useState(false);

  const styles = css`
    input, textarea {
      cursor: pointer;
    }
  `;

  return (
    <NvTooltip
      text={copied
        ? t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.COPIED()
        : t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.COPY_TO_CLIPBOARD()}
    >
      <ClickableContainer
        css={styles}
        onClick={() => { setCopied(true); copyToClipboard(text); }}
        onMouseEnter={() => setCopied(false)}
      >
        {children}
      </ClickableContainer>
    </NvTooltip>
  );
};

export default NvClickToCopy;
