import React from 'react';
import { css } from '@emotion/react';
import TextareaAutosize from 'react-textarea-autosize';
import { Placement } from 'react-bootstrap/esm/Overlay';
import {
  get,
  FieldError,
  useFormContext,
  UseFormRegisterReturn,
} from 'react-hook-form';

import { gray3 } from 'styles/global_defaults/colors';
import { textSmallLineHeight } from 'styles/global_defaults/fonts';
import { mergeRefs } from 'shared/react-utils';
import { NvPopover } from 'shared/components/nv-popover';
import ValidationErrorMessage from 'shared/components/inputs/validation-error-message';
import uuid from 'react-uuid';

const Textarea = TextareaAutosize as any;

//------------------------------------------------------------------------------
type NativeTextareaProps = React.ComponentProps<'textarea'>;

/**
 * Props that we want to inherit from the native textarea props.
 */
// Just omitting "className", since it's now "textareaClassName".
type NativeTextareaPropsInherited = Omit<NativeTextareaProps, 'className'>;

/**
 * Props is the combination of:
 * 1. Our inherited props that are directly passed to the textarea element via
 * "restProps"
 * 2: Our "additional" props which is any prop that is not from the native
 * textarea props.
 */
type Props = NativeTextareaPropsInherited & {
  // "aria-label" attribute value of the textarea element.
  ariaLabel?: string,
  // Class name of the container element.
  className?: string,
  // Error of the textarea, using FieldError as the type of our errors, if you
  // want to display an error that doesn't come from a react-hook-form form use
  // "as FieldError". Example:
  // "error={{ message: 'This is my error' } as FieldError}"
  error?: FieldError,
  // Whether we want to use this component in a react-hook-form form.
  // NOTE: Requires "FormProvider" parent wrapper to work.
  withForm?: boolean,
  // If true, a label with the placeholder prop text will be rendered right above the input
  withLabel?: boolean,
  // Whether the textarea element should focus itself on mounting.
  autoFocus?: boolean,
  // Class name of the textarea element.
  textareaClassName?: string,
  // Whether we want the error to only be shown when the textarea is focused, if
  // "false" it will be always visible. "true" as default.
  errorOnTouching?: boolean,
  // Placement of the error popover.
  popoverPlacement?: Placement,
  // Value of error popover "preventOverflow" prop.
  preventPopoverOverflow?: boolean,
  // display error popup immediatly, without the need for focus.
  showErrorWithoutFocus?: boolean,
  // Whether to show the error icon the right if there is an error
  // "true" is default
  showErrorIcon?: boolean,
  errorValidationPopoverTitle?: string,
};

/**
 * NovoEd textarea component.
 * This component can be used as a controlled and uncontrolled component as well
 * as it can be hooked into a react-hook-form form if you pass the "withForm"
 * prop as "true".
 * If you use withForm make sure:
 *   - You don't pass the "value" prop or you'll be forcing the value of the
 *     textarea to be the one you are declaring even though it's connected to a
 *     react-hook-form form already.
 *   - You pass the "name" prop as well to make react-hook-form field registry
 *     process work.
 */
export const NvTextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  (props, ref) => {
    const {
      // Native textarea props:
      name,
      value,
      onBlur,
      onFocus,
      onChange,
      readOnly,
      required,
      placeholder,
      // Additional props:
      withLabel,
      className,
      autoFocus,
      ariaLabel,
      withForm = false,
      error: propsError,
      errorValidationPopoverTitle = null,
      errorOnTouching = true,
      textareaClassName,
      popoverPlacement = 'top',
      preventPopoverOverflow = false,
      showErrorWithoutFocus,
      showErrorIcon = true,
      ...restProps // Native textarea only props
    } = props;

    const { register, formState } = useFormContext() || {};
    const [showError, setShowError] = React.useState(false);
    const [hasTouched, setHasTouched] = React.useState(false);

    const error = withForm ? (get(formState.errors, name) ?? propsError) : propsError;
    const errorMessage = error?.message || '';
    const errorPopupId = `${name ?? 'error'}-${uuid()}`;

    let extraProps = {};
    let reactHookFormRefHandler;
    let reactHookFormProps: Partial<UseFormRegisterReturn> = {};

    if (withForm) {
      const { ref: registerRef, ...restRegisterProps } = register(name);

      reactHookFormRefHandler = registerRef;
      reactHookFormProps = restRegisterProps;
    } else {
      extraProps = {
        value,
      };
    }

    const textAreaContainerStyles = css`
      position: relative;
      padding-top: ${withLabel ? textSmallLineHeight : 0}px;

      .label {
        top: 0;
        left: 0;
        color: ${gray3};
        position: absolute;
        line-height: ${textSmallLineHeight}px;
      }
    `;

    const styles = css`
      textarea {
        width: 100%;
        height: auto;
        resize: none;
      }
    `;

    const handleBlur = e => {
      setShowError(false);
      onBlur?.(e);
      (reactHookFormProps as UseFormRegisterReturn).onBlur?.(e);
    };

    const handleFocus = e => {
      setShowError(true);
      onFocus?.(e);
    };

    const handleChange = (e) => {
      setHasTouched(true);
      onChange?.(e);
      (reactHookFormProps as UseFormRegisterReturn).onChange?.(e);
    };

    return (
      <div
        className={className}
        css={textAreaContainerStyles}
      >
        {withLabel && <div className='label'>{placeholder}</div>}
        <NvPopover
          placement={popoverPlacement}
          show={(errorOnTouching ? hasTouched : true) && (showError || showErrorWithoutFocus) && !!errorMessage}
          preventOverflow={preventPopoverOverflow}
          content={(
            <ValidationErrorMessage
              title={errorValidationPopoverTitle}
              text={errorMessage}
              id={errorPopupId}
            />
          )}
          className='nv-text-area'
        >
          <div
            css={styles}
            className={
              `bs4-input-group${(errorOnTouching ? hasTouched : true) && !!error ? ' bs4-invalid ' : ''}${readOnly ? ' readonly' : ''}`
            }
          >
            <Textarea
              {...restProps}
              {...extraProps}
              name={name}
              onBlur={handleBlur}
              readOnly={readOnly}
              autoFocus={autoFocus}
              onFocus={handleFocus}
              aria-label={ariaLabel}
              aria-hidden={restProps?.disabled ?? false}
              onChange={handleChange}
              placeholder={placeholder}
              ref={withForm ? mergeRefs(ref, reactHookFormRefHandler) : ref}
              className={`bs4-form-control${textareaClassName ? ` ${textareaClassName}` : ''}`}
              data-qa={restProps['data-qa']}
              aria-describedby={errorPopupId}
            />
            {showErrorIcon && !!errorMessage && (
              <div className='bs4-input-group-append'>
                <div className='bs4-input-group-text text-danger'>
                  <div className='icon icon-xss-smallest icon-warning' />
                </div>
              </div>
            )}
            {required && (
              <div className='bs4-input-group-append'>
                <div className='bs4-input-group-text'>*</div>
              </div>
            )}
          </div>
        </NvPopover>
      </div>
    );
  },
);

export default NvTextArea;
