import { css } from '@emotion/react';
import { useContext } from 'react';

import ClickableContainer from 'components/clickable-container';
import { CourseObjectBookmark, MediaBookmarkComponent } from 'redux/schemas/models/bookmark';
import { headerLineHeight } from 'styles/global_defaults/fonts';
import { doubleSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { AngularServicesContext } from 'react-app';

type AudioSnippetProps = {
  bookmark: CourseObjectBookmark
};

const AudioSnippet = ({
  bookmark: { catalogId, lecturePageId, lectureComponentId, component },
}: AudioSnippetProps) => {
  const styles = css`
    &.audio-snippet {
      display: flex;
      flex-direction: column;
      padding: ${standardSpacing}px ${doubleSpacing}px;

      .title {
        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .subtitle {
        display: flex;

        .lecture-page-name, .course-name {
          max-width: 50%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .middot {
          margin-left: ${quarterSpacing}px;
          margin-right: ${quarterSpacing}px;
        }
      }

      .image-container {
        position: relative;

        img {
          height: 80px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        .icon-play {
          position: absolute;
          color: white;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  `;

  const { $state, FlyoutModalManager } = useContext(AngularServicesContext);

  const {
    title,
    lecturePageName,
    courseName,
    expired,
  } = component as MediaBookmarkComponent;

  const onClickSnippet = () => {
    if (expired) { return; }

    FlyoutModalManager?.closeFlyout();
    $state.go('lecture-page', { catalogId, id: lecturePageId, lectureActivityId: lectureComponentId });
  };

  return (
    <ClickableContainer className='audio-snippet' css={styles} onClick={onClickSnippet}>
      <div className='title text-body font-weight-bold'>{title}</div>
      <div className='subtitle text-gray-2 text-small'>
        <div className='lecture-page-name'>{lecturePageName}</div>
        <div className='middot'>&middot;</div>
        <div className='course-name'>{courseName}</div>
      </div>
    </ClickableContainer>
  );
};

export default AudioSnippet;
