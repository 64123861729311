import React from 'react';

import t from 'react-translate';
import InsightsCount from '../insights-count';
import PhraseList from './phrase-list';
import FadeInView from '../../fade-in-view';

const KeysToAvoid = (props: { notAvoidedKeyphrases: string[] }) => {
  const { notAvoidedKeyphrases } = props;

  return (
    <FadeInView>
      <div className='pb-6 pt-6'>
        <div className='d-flex'>
          <InsightsCount
            count={notAvoidedKeyphrases?.length}
            label={t.PRACTICE_ROOM.INSIGHTS.AVOIDED.LABEL()}
            className={notAvoidedKeyphrases?.length > 0 ? 'text-warning' : 'text-success'}
          />
          <div className='ml-4'>
            <div className='gray-1 text-regular'>
              {notAvoidedKeyphrases?.length === 0
                ? t.PRACTICE_ROOM.INSIGHTS.AVOIDED.NOT_INCLUDED()
                : t.PRACTICE_ROOM.INSIGHTS.AVOIDED.INCLUDED(notAvoidedKeyphrases?.length)}
            </div>
            {notAvoidedKeyphrases?.length > 0 && (
              <PhraseList
                label={t.PRACTICE_ROOM.INSIGHTS.AVOIDED.FORGOT_TO_AVOID()}
                items={notAvoidedKeyphrases}
                borderClassName='border-warning'
              />
            )}
          </div>
        </div>
      </div>
    </FadeInView>
  );
};
export default KeysToAvoid;
