import React, { useContext, useEffect, useState } from 'react';
import NvFileUpload, { NvFileUploadType } from 'shared/components/nv-file-upload';
import t from 'react-translate';
import NvUploadedFile from 'shared/components/nv-uploaded-file';
import { AngularContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { FileRejection } from 'react-dropzone';
import SectionContent from '../section-content';
import { config } from '../../../../../config/pendo.config.json';

const Favicon = () => {
  const { injectServices, $scope } = useContext(AngularContext);
  const [InstitutionsManager] = injectServices(['InstitutionsManager']);
  const [favicon, setFavicon] = useState($scope.$eval('vm.InstitutionsManager.institution.favicon'));
  const dispatch = useAppDispatch();

  useEffect(() => {
    $scope.$watch('vm.InstitutionsManager.institution.favicon', (newValue: any) => {
      if (newValue && (typeof (newValue) === 'string' || !newValue?.type || newValue.type !== 'image')) {
        newValue = {
          type: 'image',
          url: newValue,
        };
      }

      setFavicon(newValue);
    });
  }, [$scope]);

  const updateFaviconOnPage = () => {
    InstitutionsManager.updateFavicon(InstitutionsManager.institution.favicon);
  };

  const saveFavicon = (file) => {
    InstitutionsManager.institution.updateFromReact({
      favicon: file[0],
    });
    // setting timout for angular updation
    setTimeout(() => {
      InstitutionsManager.updateBrand().then(() => {
        updateFaviconOnPage();
      }, (errorResponse) => {
        $.noop();
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.FILE_UPLOAD.NOT_SUPPORTED_ALERT(),
        }));
        console.log(errorResponse);
      });
    });
  };

  const removeFavicon = () => {
    InstitutionsManager.institution.updateFromReact({
      favicon: null,
    });
    setTimeout(() => {
      InstitutionsManager.updateBrand().then(() => {
        updateFaviconOnPage();
      });
    });
  };

  const onError = (files: FileRejection[]) => {
    const { errors } = files[0];
    if (errors?.length > 0 && errors[0]?.code === 'file-too-large') {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.OOPS(),
        message: t.FILE_UPLOAD.SIZE_EXCEEDED_ALERT('1MB'),
      }));
    }
  };

  return (
    <SectionContent
      header={t.INSTITUTIONS.ADVANCED_SETTINGS.FAVICON_TITLE()}
      description={[t.INSTITUTIONS.ADVANCED_SETTINGS.FAVICON_DESCRIPTION()]}
      showCTAButton={false}
      extras={
        favicon?.type ? (
          <NvUploadedFile
            file={favicon}
            onDelete={removeFavicon}
            editable
            className='w-100'
            downloadDataQa={config.pendo.settings.favicon.download}
            deleteDataQa={config.pendo.settings.favicon.delete}
            cancelDeleteDataQa={config.pendo.settings.favicon.cancelDelete}
            confirmDeleteDataQa={config.pendo.settings.favicon.confirmDelete}
          />
        ) : (
          <NvFileUpload
            multiple={false}
            type={NvFileUploadType.FAVICON}
            onUploadFile={saveFavicon}
            maxSize='1MB'
            showactionButton={false}
            className='w-100'
            dataQa={config.pendo.settings.favicon.upload}
            onSelectError={onError}
          />
        )
      }
    />
  );
};

export default Favicon;
