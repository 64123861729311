import { FetchProvidersParams } from '../api/sign-in';
import { User } from './my-account';

export type IdentityProvider = {
  id: number;
  institutionId: number;
  name: string;
};

export interface IdentityProviderNormalized {
  [id: string]: IdentityProvider;
}

export type LoginProvider = {
  provider: string;
  withPassword: boolean;
  domain: string;
};

export interface LoginProviderNormalized {
  [id: string]: LoginProvider;
}

export type UserLogin = {
  user: User;
  returnToUrl: string;
};

export interface UserLoginNormalized {
  [id: string]: UserLogin;
}

export enum SignInErrors {
  emailNotFound = 'devise.sessions.account_not_found',
  signInFailed = 'error.password_does_not_match',
}

export type LoginProviderData = LoginProvider & FetchProvidersParams;
