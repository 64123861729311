/* @ngInject */
export default function CommentsResources(

  $resource,
) {
  return $resource(
    '/:catalogId/topics/:postId/posts/:id',
    { },
    {
      // index actions
      getCommentsForPost: { method: 'GET', url: '/:catalogId/topics/:postId/posts' },
      getCommentsForSubmission: { method: 'GET', url: '/:catalogId/posts' },

      // single comment actions
      getSingleComment: { method: 'GET', url: '/:catalogId/posts/:id' },
      update: { method: 'PUT', url: '/:catalogId/posts/:id' },
      remove: { method: 'DELETE', url: '/:catalogId/posts/:id' },
      highlight: { method: 'GET', url: '/:catalogId/posts/:id/highlight' },
      unHighlight: { method: 'GET', url: '/:catalogId/posts/:id/unhighlight' },
      like: { method: 'POST', url: '/:catalogId/posts/:id/voteup' },
      unlike: { method: 'POST', url: '/:catalogId/posts/:id/votedown' },
      follow: { method: 'GET', url: '/:catalogId/posts/:id/follow' },
      unFollow: { method: 'GET', url: '/:catalogId/posts/:id/unfollow' },
      report: { method: 'POST', url: '/:catalogId/flaggings?flag=abuse&flaggable_id=:id&flaggable_type=Post' },
      saveToSubmission: { method: 'POST', url: ':catalogId/posts' },
      translate: { method: 'POST', url: '/:catalogId/posts/:id/translate' },

      // single comment attribute actions
      repliesCount: { method: 'GET', url: '/:catalogId/posts/:id/comments_count' },
      getLikersInfo: { method: 'GET', url: '/:catalogId/posts/:id/voters?page=:page' },
    },
  );
}
