import { css } from '@emotion/react';
import omit from 'lodash/omit';
import { useState, useEffect, CSSProperties } from 'react';
import _ from 'underscore';
import { useFormContext, Controller } from 'react-hook-form';

import t from 'react-translate';
import NvDropdown, { NvDropdownCheckbox, NvDropdownButtonStyle, NvDropdownProps, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { gray3, primary, teal, warning } from 'styles/global_defaults/colors';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { textLargeFontSize, textMediumFontSize } from 'styles/global_defaults/fonts';
import { getSanitizedStyles } from 'shared/utils';

export type NvCheckboxDropdownItem = {
  id: number | string,
  name: string,
  label: string,
  value?: any,
  index?: number
  group?: string,
};

export type OnItemChanged = (item: NvCheckboxDropdownItem, index: number, values?: NvCheckboxDropdownItem[]) => void;

export type NvCheckboxDropdownGroup = {
  key: string,
  label: string,
};

interface NvCheckboxDropdownProps {
  name?: string
  items: NvCheckboxDropdownItem[],
  styles: CSSProperties,
  onChange?: OnItemChanged
  dropdownProps?: NvDropdownProps,
  /** A custom prompt displayed in the dropdown header when no items are selected */
  prompt?: string,
  /** Text displayed as a single item in the dropdown when no items are provided */
  noItemsText?: string,
  /** Grouping labels */
  groups?: NvCheckboxDropdownGroup[],
  /** No grouping if there is only one group */
  noSingleGroup?: boolean,
}

const defaultProps: Partial<NvCheckboxDropdownProps> = {
  name: 'checkbox-dropdown',
  onChange: null,
  dropdownProps: null,
  prompt: null,
  noItemsText: null,
};


const getTitle = (numSelected = 0, prompt?: string) => (
  <div className='title'>
    {numSelected
      ? <div className='num-selected'>{t.FORM.DROPDOWN.SELECTED(numSelected)}</div>
      : <div className='text-gray-3'>{prompt ?? t.FORM.DROPDOWN.SELECT()}</div>}
    <div className='icon text-xs icon-dropdown-arrow text-gray-3' />
  </div>
);

const NvCheckboxDropdown = (props: NvCheckboxDropdownProps) => {
  const styles = css`
    &.nv-checkbox-dropdown {
      width: 200px;
      display: inline-block;

      .title {
        width: 100%;
        padding: ${halfSpacing}px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .num-selected {
          color: ${warning};
        }
      }

      .bs4-dropdown {
        border: 1px solid ${gray3};
        border-radius: 5px;
        background-color: white;

        /** Color the background when hover to match our other dropdowns
        TODO: This should be handled in the same way as we are in bootstrap.
        Note that this isn't actually one of our predefined colors */
        &:hover {
          background-color: rgba(30, 159, 214, 0.1);
        }
      }
      .bs4-dropdown-menu {
        width: 100%;
        max-height: 250px;
        overflow-y: scroll;

        .nv-checkbox-item label {
          font-size: ${textMediumFontSize}px;
          word-break:break-word;
          overflow:hidden;
          width:220px;
        }
      }
    }
  `;
  const { getValues, setValue } = useFormContext() || {};
  const [title, setTitle] = useState(getTitle(0, props.prompt));

  const { [props.name]: values = [] } = getValues?.();

  useEffect(() => {
    setTitle(getTitle(values.length, props.prompt));
  }, [values, props.prompt]);

  const onItemSelect = (item, index) => {
    // Find value in existing array
    // Remove if exists; add if not
    const idx = values.findIndex(val => val.id === item.id);

    if (idx >= 0) {
      values.splice(idx, 1);
    } else {
      values.push(omit(item, ['group']));
    }

    const numSelected = values.length;
    setTitle(getTitle(numSelected, props.prompt));

    setValue?.(props.name, values, { shouldValidate: true, shouldDirty: true });
    props.onChange?.(item, index, values);
  };

  const itemObj: { [key: string]: NvDropdownOption[] } = {};

  // Loop through each item and group it
  _.each(props.items, (item, index) => {
    let isChecked = false;

    if (_.isObject(item) && !_.isUndefined(item.id)) {
      isChecked = values?.some((value) => value?.id === item.id);
    } else {
      isChecked = values?.includes(item);
    }

    const option: NvDropdownCheckbox = {
      type: 'checkbox',
      name: item.name,
      label: item.label,
      onChanged: () => onItemSelect(item, index),
      checked: isChecked,
    };

    // Check this item got a valid group, or else push to rest of items
    const groupKey = (item.group && _.some(props.groups, (group) => group.key === item.group))
      ? item.group
      : 'restOfItems';
    if (!itemObj[groupKey]) {
      itemObj[groupKey] = [];
    }
    itemObj[groupKey].push(option);
  });

  // Loop through groups and push to array
  let items: NvDropdownOption[] = [];
  _.each(props.groups, (group) => {
    // This grouping is only needed if there are multiple groups in the
    // itemsObj.
    if (itemObj[group.key]) {
      if (props.noSingleGroup && Object.keys(itemObj).length === 1) {
        // If single groups are not needed, append items to main array.
        items = [
          ...items,
          ...itemObj[group.key],
        ];
      } else {
        // Append a header group with items
        items.push({
          type: 'accordion-header',
          title: group.label,
          items: itemObj[group.key],
        });
      }
    }
  });

  // Lastly push the rest of the items
  items = [
    ...items,
    ...(itemObj.restOfItems || []),
  ];

  const noItemsStyle = css`
    /* Override the default dropdown item styles when showing the no items text */
    ${!items.length && css`
      .bs4-dropdown-item {
        padding: 0;
        white-space: break-spaces;
        width: unset;
        &:hover {
          background-color: unset;
        }
      }
    `};
  `;

  // Display an indicator item if configured to do so and no items exist
  if (!items.length && props.noItemsText) {
    items = [
      {
        type: 'text',
        text: props.noItemsText,
        class: 'text-gray-2 font-italic m-3',
      },
    ];
  }

  return (
    <div css={getSanitizedStyles([styles, noItemsStyle])} className='nv-checkbox-dropdown'>
      <Controller
        name={props.name}
        render={({ field }) => (
          <NvDropdown
            withForm
            showSelectedIndicator
            items={items}
            buttonStyle={NvDropdownButtonStyle.CUSTOM}
            customTarget={() => title}
            {...props.dropdownProps}
            {...omit(field, ['ref'])}
          />
        )}
      />
    </div>
  );
};

NvCheckboxDropdown.defaultProps = defaultProps;

export default NvCheckboxDropdown;
