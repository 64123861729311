import moment from 'moment';
import { createContext, useCallback, useEffect, useState } from 'react';
import { getCourseClonings } from 'redux/actions/institutions';
import { doRequestMultipleCloneCourse } from 'redux/actions/license';
import { CombinedInstitution, RootState } from 'redux/schemas';
import { CloneMultipleCourseRequest } from 'redux/schemas/api/license';
import { Course, CourseRegistrationType } from 'redux/schemas/models/course';
import { User } from 'redux/schemas/models/my-account';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { getCourseRolesCount } from 'redux/actions/roles';
import { getCalculatedDates } from 'redux/actions/calculated-dates';
import { getCoursesArray } from 'redux/selectors/course';

export const CloneCourseContext = createContext(null);

export const CloneCourseProvider = ({
  children,
  ...props
}) => (
  <CloneCourseContext.Provider value={{ ...props }}>
    {children}
  </CloneCourseContext.Provider>
);

export enum Steps {
  STEP_ONE,
  STEP_TWO,
  STEP_THREE,
}

export type UseCloneCourseProps = {
  course: Course,
  cloningType: CloningType,
};

export enum CloningType {
  NORMAL_COURSE = 1,
  PRIMARY_COURSE = 2,
  COHORT_COURSE = 3,
}

export type MediaRegistrationConfiguration = {
  copyAudioVideo: boolean,
  registrationType: CourseRegistrationType,
  openInJourney: boolean,
};

export type FormData = {
  copyAudioVideo: boolean,
  registrationType: CourseRegistrationType,
  openInJourney: boolean,
  coursesList: newClonedCourse[],
};

export type cloneCourseContextType = {
  course: Course,
  cloningType: CloningType,
  currentStep: Steps,
  formData: FormData,
  listOfAdmins: courseAdminToClone[],
  addNewCoursesToForm: (coursesList: newClonedCourse[]) => void,
  addMediaAndRegistrationToForm,
  existentCourses: string[],
  nextStep: () => void,
  prevStep: () => void,
  setListOfAdmins: ([]) => void,
  getTotalSelectedAdmins: () => number,
  goToFirstStep: () => void,
  errorCourseIndexList: [],
  addErrorCourseIndex: (index: number) => void,
  removeErrorCourseIndex: (index: number) => void,
  stringFormatDate: (time: Date|string) => string,
  willBeOpenInJourneys: (typeOfRegistration: CourseRegistrationType) => boolean,
  submit: (params: { callback: () => void }) => void,
}

export type newClonedCourse = {
  catalogId: string,
  name: string,
  officialReleaseDate: Date,
  endDate?: Date,
  releaseDate?: Date,
  registrationCloseDate?: Date,
  closeDate?: Date,
};

export type courseAdminToClone = User & {
  id: number,
  selected: boolean,
  role: string,
};

const useCloneCourseForm = ({
  course,
  cloningType,
}: UseCloneCourseProps) => {
  const currentInstitution = useSelector<RootState, CombinedInstitution>(getCurrentInstitution);
  const currentCourses = useSelector(getCoursesArray);
  const [currentStep, setCurrentStep] = useState(Steps.STEP_ONE);
  const [formData, setFormData] = useState<FormData>({
    copyAudioVideo: cloningType !== CloningType.COHORT_COURSE,
    registrationType: course.typeOfRegistration,
    openInJourney: course.openInJourney,
    coursesList: null,
  });
  const [listOfAdmins, setListOfAdmins] = useState<courseAdminToClone[]>([]);
  const [errorCourseIndexList, setErrorCourseIndexList] = useState([]);
  const [existentCourses, setExistentCourses] = useState([]);
  const dispatch = useAppDispatch();

  // Get the list of admins and the list of their respective roles
  useEffect(() => {
    dispatch(getCourseRolesCount({courseId: course.id}));
    setExistentCourses(currentCourses.map(currentCourse => currentCourse.catalogId));
  },[]);

  const nextStep = () => {
    if (currentStep < Steps.STEP_THREE) {
      setCurrentStep(currentStep + 1);
    }
  };
  const prevStep = () => {
    if (currentStep > Steps.STEP_ONE) {
      setCurrentStep(currentStep - 1);
    }
  };
  const goToFirstStep = () => {
    setCurrentStep(Steps.STEP_ONE);
  }
  const addNewCoursesToForm = (coursesList) => {
    // Checks if something has changed after an update in the state.
    // Everytime the form is updated the reference in memory changes.
    // Otherwise it keeps the same reference, and the the endpoint will not be called.
    if (coursesList !== formData.coursesList) {
      const dates = coursesList.map(course => `'${course.officialReleaseDate}'`);
      dispatch(getCalculatedDates({courseId: course.id, dates })).then(result => {
        const dates = result.payload.response;
        coursesList.forEach((course, index) => {
          course.endDate = moment(dates[index]?.endDate).toISOString();
          course.registrationCloseDate = moment(dates[index]?.registrationCloseDate).toISOString();
          course.releaseDate = moment(dates[index]?.releaseDate).toISOString();
          course.closeDate = moment(dates[index]?.closeDate).toISOString();
        });
        setFormData({
          ...formData,
          coursesList,
        });
      });
    }
  };
  const addMediaAndRegistrationToForm = ({
    copyAudioVideo,
    registrationType,
    openInJourney,
  }: MediaRegistrationConfiguration) => {
    setFormData({
      ...formData,
      copyAudioVideo,
      registrationType,
      openInJourney,
    });
  };

  const getTotalSelectedAdmins = useCallback(() => {
    let counter = 0;
    listOfAdmins.forEach(admin => {
      if (admin.selected) {
        counter += 1;
      }
    });
    return counter;
  }, [listOfAdmins]);

  /**
   * Adding to errorCourseIndexList the index of a course that has an error
   * in any of its inputs.
   * In normal cloning, index is always 0, because there is only one element,
   * but when cloning a list of cohorts, the index depends on the position
   * of the cohort in the list.
   * @param courseIndex
   */
  const addErrorCourseIndex = (courseIndex) => {
    if (!errorCourseIndexList.includes(courseIndex)) {
      const newErrorCourseIndexList = [...errorCourseIndexList];
      newErrorCourseIndexList.push(courseIndex);
      setErrorCourseIndexList(newErrorCourseIndexList);
    }
  }

  /**
   * Removing, from the errorCourseIndexList,
   * the index of a course in case it had a previous error.
   * @param courseIndex
   */
  const removeErrorCourseIndex = (courseIndex) => {
    const errorIndex = errorCourseIndexList.findIndex(index => index === courseIndex);
    if (errorIndex > -1) {
      const newErrorCourseIndexList = [...errorCourseIndexList];
      newErrorCourseIndexList.splice(errorIndex, 1);
      setErrorCourseIndexList(newErrorCourseIndexList);
    }
  };

  /**
   * Returns the time in a nicer format, and in case the time is not valid
   * then it shows '--'
   * @param time
   */
  const stringFormatDate = (time) => `${time ? moment(time).format('L hh:mm a'): '--'}`;

  const willBeOpenInJourneys = (typeOfRegistration: CourseRegistrationType) => (
    ([CourseRegistrationType.CLOSED_ENROLLMENT, CourseRegistrationType.OPEN_BASED_ON_ENTITLEMENTS].includes(typeOfRegistration)
      && formData.openInJourney)
    || undefined
  );

  const submit = ({callback}) => {
    const selectedAdmins = listOfAdmins.filter(admin => admin.selected);
    const payload: CloneMultipleCourseRequest = {
      id: course.id,
      clones: formData.coursesList.map(currentCourse => {
        return ({
          name: currentCourse.name,
          newCatalogId: currentCourse.catalogId,
          newReleaseDate: currentCourse.officialReleaseDate.toString(),
          copyAudioVideo: !!formData.copyAudioVideo,
          registrationType: formData.registrationType,
          createCohort: cloningType === CloningType.COHORT_COURSE,
          admins: selectedAdmins.map(admin => admin.id),
          openInJourney: willBeOpenInJourneys(formData.registrationType),
        });
      }),
    };

    dispatch(doRequestMultipleCloneCourse(payload)).then(result => {
      if (result?.payload) {
        if (result.payload.error) {
          const { repeatedCatalogIds } = result.payload.error;
          setExistentCourses(existentCourses.concat(repeatedCatalogIds));
        } else {
          // Request the current clonings list make the table redraw with the 'Cloning in progress...' row
          dispatch(getCourseClonings({ institutionId: currentInstitution.id }));
          callback();
        }
      }
    });
  };

  return {
    course,
    cloningType,
    currentStep,
    formData,
    listOfAdmins,
    errorCourseIndexList,
    existentCourses,
    addErrorCourseIndex,
    removeErrorCourseIndex,
    addNewCoursesToForm,
    addMediaAndRegistrationToForm,
    nextStep,
    prevStep,
    setListOfAdmins,
    getTotalSelectedAdmins,
    goToFirstStep,
    stringFormatDate,
    willBeOpenInJourneys,
    submit,
  };
};

export default useCloneCourseForm;
