import { schema } from 'normalizr';
import { omit } from 'underscore';
import { ComponentType, NLectureComponent } from 'redux/schemas/models/lecture-component';
import { PracticeActivitySchema, PracticeSubmissionListSchema, SkillTaggingListSchema } from 'redux/schemas/api/video-practice';
import { TimelineExercise } from '../models/exercise';
import { TimelineLectureVideo } from '../models/lecture-video';
import { TimelineQuiz } from '../models/quiz';
import { TimelineTimedQuiz } from '../models/timed-quiz';
import { TimelineSurvey } from '../models/survey';
import { TimelineTeamSet } from '../models/team-set';
import { TimelineGroupTeamSet } from '../models/group-team-set';
import { TimelinePost } from '../models/timeline-post';
import { TimelinePeerEvaluation } from '../models/peer-evaluation';
import { TimelineExternalTool } from '../models/external-tool';
import { TimelineLiveSession } from '../models/live-session';
import { CommunicationListSchema } from './course-communications';
import { Video } from '../models/video';
import { ProfileRequirement } from '../models/profile-requirement';
import { PointsConfigurationSchema } from './points-configurations';
import { OrgFieldsSchema } from './org-fields';
import { CourseProfileQuestionSchema } from './course-profile-questions';
import { Poll, PollOptionNormalized, PollQuestion, PollVieweeSubmission } from '../models/poll';
import { User } from '../models/my-account';
import { TeamDiscussion } from '../models/team-discussion';
import { AttachmentLectureComponent } from '../models/attachment';
import { VideoPracticeFeedbackActivity } from '../models/video-practice-feedback';
import { ExerciseSkillsRatingActivity, RatingActivityExercise } from '../models/exercise-skills-rating';
import { SubmissionListSchema } from './submission';
import { ProgressiveQuiz } from '../models/progressive-quiz';
import { QuizQuestionSchema } from './progressive-quiz';

export const AttachmentSchema = new schema.Entity<AttachmentLectureComponent>('attachments');
export const ExerciseSchema = createCommunicationSchema<TimelineExercise>('exercises');
export const QuizSchema = createCommunicationSchema<TimelineQuiz>('quizzes');
export const TimedQuizSchema = createCommunicationSchema<TimelineTimedQuiz>('timedQuizzes');

export const ProgressiveQuizSchema = createCommunicationSchema<ProgressiveQuiz>(
  'progressiveQuizzes',
  {
    questions: [QuizQuestionSchema],
    pointsConfiguration: PointsConfigurationSchema,
  },
);

export const SurveySchema = createCommunicationSchema<TimelineSurvey>('surveys');
export const TeamSetSchema = createCommunicationSchema<TimelineTeamSet>('teamSets');
export const GroupTeamSetSchema = createCommunicationSchema<TimelineGroupTeamSet>('groupTeamSets');
export const PostSchema = createCommunicationSchema<TimelinePost>('posts');
export const PeerEvaluationSchema = createCommunicationSchema<TimelinePeerEvaluation>('peerEvaluations');
export const ExternalToolSchema = createCommunicationSchema<TimelineExternalTool>('externalTools');
export const LiveSessionSchema = createCommunicationSchema<TimelineLiveSession>(
  'liveSessions',
  {},
  {
    processStrategy: (value) => ({
      ...value,
      progress: value.attendeeInfo?.status,
      sessionType: value.sessions?.[0]?.sessionType,
    }),
  },
);
export const VideosSchema = createCommunicationSchema<TimelineLectureVideo>('lectureVideos', {
  video: new schema.Entity<Video>('video'),
});
export const TeamDiscussionSchema = createCommunicationSchema<TeamDiscussion>('teamDiscussions');

function createCommunicationSchema<T>(modelName: string, additionalSchema?: object, schemaOptions?: schema.EntityOptions<T>) {
  return new schema.Entity<T>(modelName, {
    communications: CommunicationListSchema,
    ...additionalSchema,
  }, schemaOptions);
}

const ProfileRequirementSchema = new schema.Entity<ProfileRequirement>('profileRequirements', {
  orgFields: [OrgFieldsSchema],
  pointsConfigurationId: PointsConfigurationSchema,
  courseProfileQuestionIds: [CourseProfileQuestionSchema],
  communications: CommunicationListSchema,
}, {
  processStrategy: (value) => (omit({
    ...value,
    ...(value.pointsConfiguration && { pointsConfigurationId: value.pointsConfiguration }),
    ...(value.courseProfileQuestions && { courseProfileQuestionIds: value.courseProfileQuestions }),
  }, ['pointsConfiguration'])),
});

// TODO: See if we can rework these schemas like I did for the other schemas to remove unnecessary processStrategies;
// we should *never* use processStrategy since that means we're transforming backend data into a different format
// for storage
const UserSchema = new schema.Entity<User>('users');

export const PollOptionSchema = new schema.Entity<PollOptionNormalized>('pollOptions', {
  voterIds: [UserSchema],
}, {
  processStrategy: (value) => omit({
    ...value,
    ...(value.voters && { voterIds: value.voters }),
  }, ['voters']),
});

const PollQuestionSchema = new schema.Entity<PollQuestion>('pollQuestions', {
  responseOptionIds: [PollOptionSchema],
}, {
  processStrategy: (value) => (omit({
    ...value,
    ...(value.responseOptions && { responseOptionIds: value.responseOptions }),
  }, ['responseOptions'])),
});

export const PollQuestionListSchema = new schema.Array(PollQuestionSchema);

export const PollVieweeSubmissionSchema = new schema.Entity<PollVieweeSubmission>('pollVieweeSubmission', {
  questionIds: [PollQuestionSchema],
}, {
  processStrategy: (value) => (omit({
    ...value,
    ...(value.questions && { questionIds: value.questions }),
  }, ['questions'])),
});

export const PollSchema = new schema.Entity<Poll>('polls', {
  pointsConfigurationId: PointsConfigurationSchema,
  questionIds: [PollQuestionSchema],
  vieweeSubmissionId: PollVieweeSubmissionSchema,
  communications: CommunicationListSchema,
}, {
  processStrategy: (value) => (omit({
    ...value,
    ...(value.pointsConfiguration && { pointsConfigurationId: value.pointsConfiguration }),
    ...(value.questions && { questionIds: value.questions }),
    ...(value.vieweeSubmission && { vieweeSubmissionId: value.vieweeSubmission }),
  }, ['pointsConfiguration', 'questions', 'vieweeSubmission'])),
});

export const PracticeFeedbackSchema = new schema.Entity<VideoPracticeFeedbackActivity>('practiceFeedbackActivities', {
  feedbackGalleryIds: PracticeSubmissionListSchema,
  communications: CommunicationListSchema,
}, {
  processStrategy: (value) => (omit({
    ...value,
    ...value.activity && { activityId: value.activity.id },
    ...value.feedbackGallery && { feedbackGalleryIds: value.feedbackGallery },
  }, ['feedbackGallery'])),
});

export const ExerciseSkillsRatingSchema = new schema.Entity<ExerciseSkillsRatingActivity>('exerciseSkillsRatingActivities', {
  activity: {
    skillTaggingIds: SkillTaggingListSchema,
  },
  feedbackGalleryIds: SubmissionListSchema,
  communications: CommunicationListSchema,
}, {
  processStrategy: (value) => (omit({
    ...value,
    ...value.activity && { activityId: value.activity.id },
    ...value.feedbackGallery && { feedbackGalleryIds: value.feedbackGallery },
    ...value.activity && {
      activity: {
        ...value.activity,
        ...value.activity?.lectureComponent?.skillTaggings && { skillTaggingIds: value.activity.lectureComponent?.skillTaggings },
      },
    },
  }, ['feedbackGallery'])),
});

export const LectureComponentSchema = new schema.Entity<NLectureComponent>('lectureComponents', {
  skillTaggings: SkillTaggingListSchema,
  lectureVideos: [VideosSchema],
  attachment: AttachmentSchema,
  exercise: ExerciseSchema,
  quiz: QuizSchema,
  timedQuiz: TimedQuizSchema,
  progressiveQuiz: ProgressiveQuizSchema,
  survey: SurveySchema,
  teamSet: TeamSetSchema,
  groupTeamSet: GroupTeamSetSchema,
  post: PostSchema,
  peerEvaluation: PeerEvaluationSchema,
  externalTool: ExternalToolSchema,
  liveSession: LiveSessionSchema,
  profileRequirement: ProfileRequirementSchema,
  poll: PollSchema,
  teamDiscussion: TeamDiscussionSchema,
  practiceActivity: PracticeActivitySchema,
  practiceFeedbackActivity: PracticeFeedbackSchema,
  exerciseSkillsRatingActivity: ExerciseSkillsRatingSchema,
}, {
  processStrategy: (value) => ({
    ...value,
    ...(value.quiz && value.type === ComponentType.QUIZ && { quiz: value.quiz }),
    ...(value.quiz && value.type === ComponentType.TIMED_QUIZ && { timedQuiz: value.quiz }),
    ...(value.teamSet && value.type === ComponentType.TEAM_FORMATION && { teamSet: value.teamSet }),
    ...(value.teamSet && value.type === ComponentType.GROUP_FORMATION && { groupTeamSet: value.teamSet }),
    // There are two parsers for `discussion` here.
    // 1. To parse the data from API, where discussion/post will be in key `topic`. So need to do the
    //    conversion here to parse from `topic` and then feed `posts`
    // 2. To parse the data from the Angular side. That will be already converted to `post` key and so
    //    just need to parse from `post` and feed `posts`.
    ...(value.topic && { post: value.topic, commentIds: [], commentsCount: value.topic.postsCount }),
    ...(value.post && { post: value.post, commentIds: [], commentsCount: value.post.postsCount }),
    ...(value.videoPractice && { practiceActivity: { ...value.videoPractice } }),
    ...(value.publicFeedback && { practiceFeedbackActivity: value.publicFeedback }),
    ...(value.skillsRating && { exerciseSkillsRatingActivity: { ...value.skillsRating, lectureComponentType: value.type } }),
  }),
});

export const LectureComponentListSchema = new schema.Array(LectureComponentSchema);
