import { normalize } from 'normalizr';
import _ from 'underscore';
import merge from 'lodash/merge';
import * as humps from 'humps';
import mergeWith from 'lodash/mergeWith';
import { CombinedCoursesNormalized, RootState } from 'redux/schemas';
import { createReducer, current } from '@reduxjs/toolkit';
import { institutionSchema } from 'redux/schemas/api/institutions';
import {
  ExportTypes,
  ExportDetails,
  ExportFile,
  CourseDetails,
  LicenseDashboardState,
  ExportStatus,
} from 'redux/schemas/models/license';
import {
  coursesSchema,
  IInstitutionEntities,
  ICourseEntities,
  ExportDetailsResponse,
  getCourseEnrollmentDetailsResponse,
} from 'redux/schemas/api/license';
import { replaceArrays } from 'shared/lodash-utils';
import { Course } from 'redux/schemas/models/course';
import { Institution } from 'redux/schemas/models/institution';
import {
  getLicenseData,
  getTopTenCourses,
  getCoursesLogins,
  getMonthlyEnrollments,
  getEnrollmentsForPeriod,
  getExportDetails,
  getExportFileList,
  addDownloadFile,
  getExportData,
  getCourseEnrollmentDetails,
} from '../actions/license';
import loadInstitution, {
  loadCoursesForInstitution, getInstitutionData, updateInstitutionBranding, getCoursesList,
} from '../actions/institutions';
import {
  initialRootState,
  initialLicenseState,
  initialCourseRankingsState,
  initialTotalLogins,
  initialMonthlyEnrollments,
  initialEnrollmentsForPeriod,
  initialCourseCommunicationForCourse,
  newState,
} from './index';

export const initialLicenseDashboardState: LicenseDashboardState = {
  allCoursesLoaded: false,
  monthlyEnrollmentsLoaded: false,
  enrollmentsForPeriodLoaded: false,
};

const transformExportDetails = (details: ExportDetailsResponse, oldDetails): ExportDetails => {
  const newDetails = { ...oldDetails };
  details = humps.decamelizeKeys(details);
  Object.keys(details).forEach((key: ExportTypes) => {
    details[key].forEach((obj) => {
      newDetails[key][obj.period] = {};
      newDetails[key][obj.period].reportStatus = obj.report_status;
    });
  });

  return newDetails;
};

const transformCourseEnrollmentDetails = (result: getCourseEnrollmentDetailsResponse): CourseDetails => {
  const { reportStatus } = result.courseEnrollmentExportDetails?.courseEnrollment?.[0] || {};
  const { completedAt, url } = result.courseEnrollmentExportFileList?.[0] || {};
  return { reportStatus, downloadFile: { completedAt, url } };
};

export default createReducer(initialRootState, builder => {
  builder
    .addCase(getExportData.fulfilled, (state, action) => {
      if (action.payload.exportType === ExportTypes.COURSE_ENROLLMENT) {
        state.models.license.courseEnrollmentDetails[action.payload.courseId].reportStatus = ExportStatus.IN_PROGRESS;
      } else {
        state.models.license.exportDetails[action.payload.exportType][action.payload.period].reportStatus = ExportStatus.IN_PROGRESS;
      }
    })
    .addCase(getLicenseData.pending, (state, action) => {
      state.models.license = initialLicenseState;
    })
    .addCase(getLicenseData.fulfilled, (state, action) => {
      Object.assign(state.models.license, action.payload);
      state.models.license.loaded = true;
    })
    .addCase(loadInstitution, (state, action) => {
      const data = normalize<Institution, IInstitutionEntities, string>(action.payload, institutionSchema);
      mergeWith(state.models, data.entities, replaceArrays);
      state.app.currentInstitutionId = action.payload.id;
    })
    .addCase(loadCoursesForInstitution.pending, (state, action) => {
      state.app.licenseDashboard.allCoursesLoaded = true;
    })
    .addCase(loadCoursesForInstitution.fulfilled, (state, action) => {
      const data = normalize<Course, ICourseEntities>(action.payload, coursesSchema);
      Object.assign(state.models.courses, data.entities.courses);
      state.app.licenseDashboard.allCoursesLoaded = true;
    })
    // The license data reducers below follow the pattern of having the .started actions
    // set the state back to the initial state, and the .done state updating the state w/ the license
    // data response. This lets us show a 'loading' indicator whenever the redux state is set to the
    // initial values.
    .addCase(getTopTenCourses.pending, (state, action) => {
      state.models.courseRankings = initialCourseRankingsState;
    })
    .addCase(getTopTenCourses.fulfilled, (state, action) => {
      state.models.courseRankings.top10Courses = action.payload.top10Courses;
    })

    .addCase(getCoursesLogins.pending, (state, action) => {
      state.models.totalLogins = initialTotalLogins;
    })
    .addCase(getCoursesLogins.fulfilled, (state, action) => {
      state.models.totalLogins = action.payload.totalLogins[0];
      state.models.totalEnrollments = action.payload.totalEnrollments;
    })
    .addCase(getMonthlyEnrollments.pending, (state, action) => {
      state.models.monthlyEnrollments = initialMonthlyEnrollments;
      state.app.licenseDashboard.monthlyEnrollmentsLoaded = false;
    })
    .addCase(getMonthlyEnrollments.fulfilled, (state, action) => {
      state.models.monthlyEnrollments.monthlyEnrollments = action.payload.monthlyEnrollments;
      state.app.licenseDashboard.monthlyEnrollmentsLoaded = true;
    })
    .addCase(getEnrollmentsForPeriod.pending, (state, action) => {
      state.models.enrollmentsForPeriod = initialEnrollmentsForPeriod;
      state.app.licenseDashboard.enrollmentsForPeriodLoaded = false;
    })
    .addCase(getEnrollmentsForPeriod.fulfilled, (state, action) => {
      state.models.enrollmentsForPeriod = action.payload;
      state.app.licenseDashboard.enrollmentsForPeriodLoaded = true;
    })
    .addCase(getExportDetails.fulfilled, (state, action) => {
      state.models.license.exportDetails = transformExportDetails(action.payload, state.models.license.exportDetails);
    })
    .addCase(getExportFileList.fulfilled, (state, action) => {
      state.models.license.exportFileList = action.payload.licenseDashboardExportFileList;
    })
    .addCase(addDownloadFile, (state, action) => {
      state.models.license.exportFileList = [humps.camelizeKeys(action.payload) as unknown as ExportFile, ...state.models.license.exportFileList];
    })
    .addCase(getCourseEnrollmentDetails.fulfilled, (state, action) => {
      state.models.license.courseEnrollmentDetails[action.meta.arg.courseId] = transformCourseEnrollmentDetails(action.payload);
    })
    .addCase(updateInstitutionBranding.fulfilled, (state, action) => {
      Object.assign(state.models.institutions[action.meta.arg.institutionId], action.payload);
    })
    .addCase(getCoursesList.fulfilled, (state, action) => {
      const data = normalize<Course, ICourseEntities>(
        action.payload.response,
        coursesSchema,
      );
      const updatedCoursesInfo = {};
      if (data.entities.courses) {
        Object.values(data.entities.courses).forEach((course) => {
          const updatedCourseInfo = { ...state.models.courses[course.catalogId], ...data.entities.courses[course.catalogId] };
          if (updatedCoursesInfo[course.catalogId]) {
            Object.assign(updatedCoursesInfo[course.catalogId], updatedCourseInfo);
          } else {
            updatedCoursesInfo[course.catalogId] = updatedCourseInfo;
          }
        });
      }
      Object.assign(state.models.courses, { ...state.models.courses, ...updatedCoursesInfo });
      if (data.entities.courses) {
        Object.values(data.entities.courses).forEach((course) => {
          state.app.courseCommunications[course.catalogId] = initialCourseCommunicationForCourse;
        });
      }
    });
});
