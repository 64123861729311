/* @ngInject */
export default function nvDateRange(

  moment,
  _,
) {
  return function (inputObjects, field, startDate, endDate) {
    const outputObjects = [];

    _.each(inputObjects, (object) => {
      if (object[field] && moment(startDate) <= moment(object[field])) {
        if (!endDate || moment(endDate) >= moment(object[field])) {
          outputObjects.push(object);
        }
      }
    });

    return outputObjects;
  };
}
