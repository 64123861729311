/* @ngInject */
export default function NvDirectoryProfileFiltersList() {
  return {
    restrict: 'A',
    scope: true,
    link(scope, elem, attrs) {
      const questionList = scope.$eval(attrs.questionList);

      scope.questionList = questionList;
    },
    templateUrl: 'directories/templates/nv-directory-profile-filters-list.html',
  };
}
