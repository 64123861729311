$.fn.datepicker.language.ja = {
  days: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
  daysShort: ['日曜', '月曜', '火曜', '水曜', '木曜', '金曜', '土曜'],
  daysMin: ['日', '月', '火', '水', '木', '金', '土'],
  months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
  monthsShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
  today: '今日',
  clear: 'クリア',
  dateFormat: 'mm/dd/yyyy',
  timeFormat: 'hh:ii',
  firstDay: 0,
};

$.fn.datepicker.language.ko = {
  days: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
  daysShort: ['일', '월', '화', '수', '목', '금', '토'],
  daysMin: ['일', '월', '화', '수', '목', '금', '토'],
  months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  monthsShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  today: '오늘',
  clear: '초기화',
  dateFormat: 'yyyy-mm-dd',
  timeFormat: 'hh:ii',
  firstDay: 0,
};

$.fn.datepicker.language.it = {
  days: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
  daysShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
  daysMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
  months: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
  monthsShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
  today: 'Oggi',
  clear: 'Pulisci',
  dateFormat: 'mm/dd/yyyy',
  timeFormat: 'hh:ii aa',
  firstDay: 1,
};
$.fn.datepicker.language.nl = {
  days: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag', 'zondag'],
  daysShort: ['zon', 'maa', 'din', 'woe', 'don', 'vri', 'zat', 'zon'],
  daysMin: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'],
  months: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],
  monthsShort: ['Jan', 'Feb', 'Maa', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
  today: 'Vandaag',
  clear: 'Wissen',
  dateFormat: 'dd-mm-yyyy',
  timeFormat: 'hh:ii',
  firstDay: 1,
};
$.fn.datepicker.language.sv = {
  days: ['söndag', 'måndag', 'tisdag', 'onsdag', 'torsdag', 'fredag', 'lördag'],
  daysShort: ['sön', 'mån', 'tis', 'ons', 'tor', 'fre', 'lör'],
  daysMin: ['sö', 'må', 'ti', 'on', 'to', 'fr', 'lö'],
  months: ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti', 'september', 'oktober', 'november', 'december'],
  monthsShort: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
  today: 'Idag',
  clear: 'Rensa',
  dateFormat: 'yyyy-mm-dd',
  timeFormat: 'hh:ii',
  firstDay: 1,
};
// Because datepicker doesn't support hebrew we replace it with english
$.fn.datepicker.language.he = {
  ...$.fn.datepicker.language.en,
};

/* @ngInject */
export default function NvDatepicker(
  $parse,
  $timeout,
  $translate,
  nvUtil,
  CurrentUserManager,
  _,
  config,
  moment,
) {
  const LANGUAGE_MAPPING = {
    en_US: 'en',
    es_MX: 'es',
    es_ES: 'es',
    fr_FR: 'fr',
    pt_PT: 'pt',
    pt_BR: 'pt-BR',
    zh_CN: 'zh',
    ja_JP: 'ja',
    ko_KP: 'ko',
    ru_RU: 'ru',
    de_DE: 'de',
    ar_SA: 'ar',
    he_IL: 'he', // hebrew is not supported, but included to detect it when needed
    pl_PL: 'pl',
    it_IT: 'it',
    fr_CA: 'fr',
    nl_NL: 'nl',
    ro_RO: 'ro',
    sv_SE: 'sv',
  };


  // Configuration to make the prev and the next buttons well drawn.
  // Also the date info is displayed as it should when the app is RTL
  const rtlOptions = {
    prevHtml: '<svg><path d="M 14,12 l 5,5 l -5,5"></path></svg>',
    nextHtml: '<svg><path d="M 17,12 l -5,5 l 5,5"></path></svg>',
    monthsField: 'months',
    daysField: 'days',
    navTitles: {
      days: 'MM yyyy',
      months: 'yyyy',
      years: 'yyyy1 - yyyy2',
    },
  };

  return {
    restrict: 'A',
    scope: true,
    require: ['^?form', 'ngModel'],
    link(scope, element, attrs, ctrls) {
      const currentLang = LANGUAGE_MAPPING[CurrentUserManager.user.platformLanguage];

      const [formCtrl, ngModelCtrl] = ctrls;
      let lastValidDate;
      let hasSetDefaultDate = false;
      const timepicker = $parse(attrs.timepicker)(scope);
      const futureOnly = $parse(attrs.futureOnly)(scope);
      const minDate = $parse(attrs.minDate)(scope) ?? (futureOnly && new Date());
      const onHide = $parse(attrs.onHide)(scope);
      const preventUnselect = $parse(attrs.preventUnselect)(scope);

      element.attr('type', 'text');
      const datepickerOptions = {
        timepicker: timepicker || false,
        language: currentLang,
        keyboardNav: false,
        startDate: momentAsDateForCurrentUser(moment().add(1, 'day').startOf('day')), // This causes the timepicker to default to 12:00AM
        onSelect(formattedDate, date, instance) {
          if (!date) {
            if (preventUnselect && ngModelCtrl.$modelValue) {
              const currentValue = ngModelCtrl.$modelValue;

              $timeout(() => {
                const ngModelMoment = momentForCurrentUser(moment(currentValue));
                if (ngModelMoment.isValid()) {
                  datepicker.selectDate(momentAsDateForCurrentUser(ngModelMoment));
                }
              });
            } else {
              return;
            }
          }
          lastValidDate = date;
          ngModelCtrl.$setViewValue(formattedDate);
          if (!hasSetDefaultDate && formCtrl) {
            formCtrl.$setPristine();
          }
        },
        onShow(inst, animationCompleted) {
          if (!animationCompleted) {
            updatePosition();
            // Hack Fix for updating position after other UI element closes
            $timeout(() => {
              updatePosition();
            }, 100);
          }
        },
        onHide(inst, animationCompleted) {
          // if there is an onHide, do it asap
          if (!animationCompleted) {
            $timeout(() => {
              onHide?.();
            });
          }
        },
        minDate: minDate ? new Date(minDate) : '',
      };

      if (config.rtlLanguages.includes(CurrentUserManager.user.platformLanguage)) {
        _.extend(datepickerOptions, rtlOptions);
      }

      const datepicker = element.datepicker(datepickerOptions).data('datepicker');

      if (timepicker) {
        _.each(datepicker.timepicker.$ranges, (input) => {
          input.setAttribute('dir', 'ltr');
        });
      }

      if ($parse(attrs.showImmediately)(scope)) {
        datepicker.show();
      }

      $timeout(() => {
        if (ngModelCtrl.$modelValue) {
          const ngModelMoment = momentForCurrentUser(moment(ngModelCtrl.$modelValue));
          if (ngModelMoment.isValid()) {
            datepicker.selectDate(momentAsDateForCurrentUser(ngModelMoment));
          }
        }
        hasSetDefaultDate = true;
      });

      element.blur((e) => {
        if (!element.val()) {
          ngModelCtrl.$setViewValue(undefined);
        } else if (!inputValueAsMoment().isValid()) {
          if (lastValidDate) {
            datepicker.selectDate(lastValidDate);
          } else {
            datepicker.clear();
          }
        }
      });

      element.keyup((e) => {
        // if keyCode is numeric, 'am', 'pm', space, colon, forward slash
        if ((e.keyCode >= 48 && e.keyCode <= 57) || [32, 65, 77, 80, 186, 191].includes(e.keyCode)) {
          if (inputValueAsMoment().isValid()) {
            datepicker.selectDate(momentAsDateForCurrentUser(inputValueAsMoment()));
          }
        }
      });

      ngModelCtrl.$formatters.push((value) => {
        if (value) {
          return moment(value).format(formatString());
        }

        return null;
      });


      // view -> model
      ngModelCtrl.$parsers.push((value) => {
        if (value) {
          return stringAsMoment(value).toISOString();
        }

        return null;
      });

      // Private Functions
      function inputValueAsMoment() {
        return stringAsMoment(element.val());
      }

      function stringAsMoment(str) {
        if (currentLang === 'he') {
          moment.locale('en');
        }
        const momentFromString = moment(str, formatString(), true);
        if (currentLang === 'he') {
          moment.locale('he');
        }
        return momentFromString;
      }

      function momentForCurrentUser(thisMoment) {
        if (CurrentUserManager.user.timeZone) {
          return thisMoment.tz(CurrentUserManager.user.timeZone);
        }
        return thisMoment;
      }

      function momentAsDateForCurrentUser(thisMoment) {
        const date = thisMoment.toDate();
        let offset = 0;
        if (CurrentUserManager.user.timeZone) {
          offset = date.getTimezoneOffset() - moment.tz.zone(CurrentUserManager.user.timeZone).utcOffset(date);
        }
        return new Date(date.getTime() + offset * 60 * 1000);
      }

      function updatePosition() {
        if (attrs.datepickerPosition) {
          datepicker.update('position', nvUtil.isRtl() ? nvUtil.swapLeftRight(attrs.datepickerPosition) : attrs.datepickerPosition);
        } else {
          const INPUT_HEIGHT = 43;
          const DATEPICKER_OFFSET = 12;
          const DATEPICKER_HEIGHT = $('.datepicker.active').height();
          const TOTAL_HEIGHT = INPUT_HEIGHT + DATEPICKER_OFFSET + DATEPICKER_HEIGHT;

          const windowHeight = $(window).height();
          const distanceFromTopOfPage = $(element).offset().top;
          const distanceFromTopOfWindow = distanceFromTopOfPage - $(window).scrollTop();
          const distanceFromBottomOfWindow = windowHeight - distanceFromTopOfWindow;
          const distanceFromTopOfFooter = distanceFromBottomOfWindow - 70;

          if (distanceFromTopOfFooter < TOTAL_HEIGHT && distanceFromTopOfPage > DATEPICKER_OFFSET + DATEPICKER_HEIGHT) {
            datepicker.update('position', 'top left');
          } else {
            datepicker.update('position', 'bottom left');
          }
        }
      }

      function formatString() {
        const dateFormat = $.fn.datepicker.language[currentLang].dateFormat.toUpperCase();
        if (timepicker) {
          let timeFormat = $.fn.datepicker.language[currentLang].timeFormat.replace('ii', 'mm');

          if (timeFormat.includes('aa')) {
            timeFormat = timeFormat.replace('aa', 'a');
          } else {
            timeFormat = timeFormat.replace('hh', 'HH');
          }

          return `${dateFormat} ${timeFormat}`;
        }
        return dateFormat;
      }
    },
  };
}
