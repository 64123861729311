import React from 'react';

const DROPDOWN_ITEM_CLASS = 'bs4-dropdown-item';
const DROPDOWN_ITEM_SELECTOR = `.${DROPDOWN_ITEM_CLASS}`;
const VERTICAL_ARROW_KEY_NAMES = ['ArrowUp', 'ArrowDown'] as const;

/**
 * Given that some dropdown items (elements that have DROPDOWN_ITEM_CLASS class)
 * from NvDropdown are wrapped by another element we may need to look for it
 * in its children (that's why I use querySelector).
 */
const getRealSiblingDropdownItems = (element: Element) => {
  const {
    nextElementSibling,
    previousElementSibling,
  } = element;

  const getRealDropdownItem = (siblingElement: Element) => {
    if (siblingElement.classList.contains(DROPDOWN_ITEM_CLASS)) {
      return siblingElement;
    }

    return siblingElement.querySelector(DROPDOWN_ITEM_SELECTOR);
  };

  return {
    nextElementSibling: getRealDropdownItem(nextElementSibling),
    previousElementSibling: getRealDropdownItem(previousElementSibling),
  };
};

/**
 * Recursive function that returns a list of all focusable children elements
 * of a given element, this is used in useDropdownItemInnerFocusables hook to
 * know what are the elements and order we need to focus them before focusing
 * next dropdown item.
 */
const getFocusableChildrenElements = (element: HTMLElement | null, list = [], isFirstTime = true) => {
  if (element === null) {
    return list;
  }

  if (!isFirstTime && element.tabIndex === 0) {
    list.push(element);
  }

  getFocusableChildrenElements(element.firstElementChild as HTMLElement, list, false);

  if (!isFirstTime) {
    getFocusableChildrenElements(element.nextElementSibling as HTMLElement, list, false);
  }

  return list;
};

export const isVerticalArrowKey = (key: string) => VERTICAL_ARROW_KEY_NAMES.includes(key as VerticalArrowKeyName);

const getDeltaFromArrowKeyName = (verticalArrowKeyName: VerticalArrowKeyName) => {
  if (verticalArrowKeyName === 'ArrowUp') {
    return -1;
  }

  return 1;
};

type VerticalArrowKeyName = typeof VERTICAL_ARROW_KEY_NAMES[number];

/**
 * Hook that provides keyboard accessibility features specifically to NvDropdown
 * component items, you may need to use this hook if you built a custom dropdown
 * item that has focusable elements in it.
 * It allows you to select children focusable elements with up and down keyboard
 * arrow keys.
 * NOTE: This is for strict use with NvDropdown component.
 */
const useDropdownItemInnerFocusables = () => {
  const itemElementRef = React.useRef<HTMLElement>();
  const focusedElementIndexRef = React.useRef<number>();
  const focusablesRef = React.useRef<HTMLElement[]>([]);

  const focusCurrentElementRef = React.useRef(() => {
    const { current: focusables } = focusablesRef;
    const { current: focusedElementIndex } = focusedElementIndexRef;

    focusables[focusedElementIndex]?.focus();
  });

  const handleFocusRef = React.useRef<React.FocusEventHandler<HTMLElement>>((e) => {
    const { current: focusables } = focusablesRef;
    const { current: itemElement } = itemElementRef;
    const { current: focusCurrentElement } = focusCurrentElementRef;

    if (
      itemElement.previousElementSibling
      && (
        e.relatedTarget === itemElement.previousElementSibling
        || itemElement.previousElementSibling.contains(e.relatedTarget as Node)
      )
    ) {
      focusedElementIndexRef.current = -1;
    } else if (
      itemElement.nextElementSibling
      && (
        e.relatedTarget === itemElement.nextElementSibling
        || itemElement.nextElementSibling.contains(e.relatedTarget as Node)
      )
    ) {
      focusedElementIndexRef.current = focusables.length;
      focusCurrentElement();
    }
  });

  const handleKeydownRef = React.useRef<React.KeyboardEventHandler<HTMLElement>>((e) => {
    const key = e.key as VerticalArrowKeyName;
    const { current: focusables } = focusablesRef;
    const { current: itemElement } = itemElementRef;
    const { current: focusCurrentElement } = focusCurrentElementRef;
    const {
      nextElementSibling,
      previousElementSibling,
    } = getRealSiblingDropdownItems(itemElement);

    if (isVerticalArrowKey(key)) {
      e.stopPropagation();

      const delta = getDeltaFromArrowKeyName(key);
      const newValue = focusedElementIndexRef.current + delta;

      if (
        newValue < focusables.length
        && newValue > -1
      ) {
        focusedElementIndexRef.current = newValue;
        focusCurrentElement();
      } else if (newValue < 0) {
        (previousElementSibling as HTMLElement).focus();
      } else {
        (nextElementSibling as HTMLElement).focus();
      }
    }
  });

  React.useEffect(() => {
    const { current: itemElement } = itemElementRef;

    if (itemElement) {
      focusablesRef.current = getFocusableChildrenElements(itemElement);
    }
  }, [itemElementRef]);

  return {
    ref: itemElementRef,
    className: DROPDOWN_ITEM_CLASS,
    onFocus: handleFocusRef.current,
    onKeyDown: handleKeydownRef.current,
  };
};

export default useDropdownItemInnerFocusables;
