/* @ngInject */
export default function OrgLevelProfileManager() {
  const manager = {
    userName: '',
    setUserName,
  };

  function setUserName(newUserName: string) {
    manager.userName = newUserName;
  }

  return manager;
}
