import { css } from '@emotion/react';
import { useState, useContext, forwardRef, MutableRefObject, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import t from 'react-translate';
import Button from 'react-bootstrap/Button';
import { BaseUser } from 'redux/schemas/models/my-account';
import NvUserAvatar from 'components/nv-user-avatar';
import { gray6, hexToRgbaString } from 'styles/global_defaults/colors';
import { standardSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { NvFroala } from 'froala/components/nv-froala';
import { FroalaViewMode } from 'froala/helpers/nv-froala-constants';
import { notHandheld } from 'styles/global_defaults/media-queries';
import { getCurrentUser } from 'redux/selectors/users';
import { Reply } from 'redux/schemas/models/reply';
import { createReply, updateReply, removeNewReplyId } from 'redux/actions/replies';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { AngularServicesContext } from 'react-app';
import { parseMentionables, createFroalaMentionableString } from 'froala/helpers/tribute-options';
import { useAppDispatch } from 'redux/store';
import { LecturePageMode } from 'lecture_pages/components';
import usePreventLecturePageNavigation from 'lecture_pages/hooks/use-prevent-lecture-page-navigation';
import { useTimelineService } from 'timelines/services/react-timeline-service';
import { getSkillTagsFromTaggings } from 'redux/selectors/skills-feedback';
import { config } from '../../../../config/pendo.config.json';
import { TeamDiscussionContext } from './team-discussion';

interface DiscussionReplyFormProps {
  edit?: boolean;
  replyId?: number;
  commentId: number;
  onCancel: () => void;
  afterSubmit?: () => void;
  myForwardedRef?: MutableRefObject<any>
  mentionableMembers?: BaseUser[];
  mentionedUser?: BaseUser;
}

const DiscussionReplyForm = forwardRef<MutableRefObject<any>, any>(
  (props: DiscussionReplyFormProps, ref: MutableRefObject<any>) => {
    const styles = css`
      display: flex;
      position: relative;
      background: ${hexToRgbaString(gray6, 0.6)};
      padding: ${standardSpacing}px;
      border-left: 1px solid ${gray6};
      border-right: 1px solid ${gray6};

      ${notHandheld(css`
        margin-left: ${halfSpacing}px;
      `)};

      .new-reply-body {
        width: 100%;
        margin-left: ${standardSpacing}px;
      }

      .buttons {
        display: flex;
        justify-content: center;
        margin-top: ${standardSpacing}px;

        button.cancel {
          margin-right: ${standardSpacing}px;
        }
      }

      .buttons-xs {
        margin-top: ${standardSpacing}px;

        button {
          max-width: 480px;
          margin: 0 auto;

          &[type=submit] {
            margin-top: ${halfSpacing}px;
          }
        }
      }
    `;

    const stateChangeRef = useRef(null);

    const dispatch = useAppDispatch();

    const { $scope, $uibModal, TimelinesManager } = useContext(AngularServicesContext);
    const { mode, postId, currentLecture } = useContext(TeamDiscussionContext);

    const catalogId = useSelector((state) => state.app.currentCatalogId);
    const currentUser = useSelector((state) => getCurrentUser(state));
    const reply = useSelector((state) => state.models.replies[props.replyId]);
    const post = useSelector((state) => state.models.posts[postId]);
    const lectureComponentId = post?.metaContent?.lectureComponentId;
    const lectureComponent = useSelector((state) => lectureComponentId && state.models.lectureComponents[lectureComponentId]) || {};
    const skillTags = useSelector((state) => getSkillTagsFromTaggings(state, lectureComponent?.skillTaggings || [])) || [];

    const [loading, setLoading] = useState(false);

    const timelineService = useTimelineService();

    const validationSchema = yup.object().shape({
      reply: yup.string().required(t.VALIDATION.REQUIRED()),
    });

    const getDefaultValue = () => {
      if (reply) {
        return reply?.body;
      }
      if (props.mentionedUser) {
        return createFroalaMentionableString(props.mentionedUser);
      }
      return '';
    };

    const methods = useForm({
      mode: 'onChange',
      shouldUnregister: true,
      resolver: yupResolver(validationSchema),
      defaultValues: {
        reply: getDefaultValue(),
      },
    });

    const { handleSubmit, formState, setValue, getValues, watch } = methods;
    const replyBody = watch('reply');

    useEffect(() => {
      if (props.mentionedUser) {
        ref.current.focus();
      }
    }, [props.mentionedUser, ref]);

    const checkUnsavedChanges = () => {
      if (isEmpty(replyBody) && isEmpty(reply?.body)) {
        return false;
      }

      return replyBody !== reply?.body;
    };

    const checkUnsavedChangesRef = useRef<Function>();
    checkUnsavedChangesRef.current = checkUnsavedChanges;

    useEffect(() => {
      stateChangeRef.current = $scope.StateManager.registerStateChangeStart(
        checkUnsavedChangesRef.current,
        'shared/templates/modal-navigate-away.html',
        'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY',
      );

      const { current: deregister } = stateChangeRef;

      return () => {
        deregister();
      };
    }, [$scope.StateManager]);

    usePreventLecturePageNavigation(checkUnsavedChanges);

    const onReply = (data, e) => {
      setLoading(true);
      if (props.edit) {
        dispatch(updateReply({
          catalogId,
          replyId: reply.id,
          comment: data.reply,
          mentionedIds: parseMentionables(data.reply, props.mentionableMembers),
        })).then(() => {
          props?.afterSubmit();
        }).finally(() => setLoading(false));
      } else {
        const previousPointsReceived = post.pointsReceived || 0;

        dispatch(createReply({
          catalogId,
          commentId: props.commentId,
          comment: data.reply,
          mentionedIds: parseMentionables(data.reply, props.mentionableMembers),
          userId: currentUser.id,
          postId,
          // Update posts and progress of timeline after new post is fetched
          callback: (newPost) => {
            timelineService.updateTimeline(currentLecture.id);
            TimelinesManager.updateComponentPointsAndProgress(
              currentLecture.id,
              newPost.type,
              newPost.id,
              newPost.pointsReceived,
              newPost.totalPoints,
              newPost.progress,
            );
          },
        })).then(res => {
          const {
            pointsReceived,
            leaderboardPoints,
            leaderboardRank,
            priorLeaderboardRank,
            id,
          } = (res.payload as Reply);

          const isCompleted = post.progress === 'completed';
          const showSkillTags = skillTags.length > 0 && !isCompleted;

          dispatch(removeNewReplyId({
            replyId: id,
          }));

          if ((pointsReceived && pointsReceived > previousPointsReceived) || showSkillTags) {
            $uibModal.open({
              templateUrl: 'shared/templates/points-modal.html',
              windowClass: 'points-modal',
              controller: 'PointsModalCtrl as vm',
              resolve: {
                pointsReceived,
                leaderboardPoints,
                leaderboardRank,
                priorLeaderboardRank,
                extras: { skillTags },
              },
            });
          }
          setValue('reply', '');
          props.afterSubmit?.();
        }).finally(() => setLoading(false));
      }
    };

    const hasUnsavedChanges = () => {
      const replyValue = getValues().reply;
      const defaultValue = getDefaultValue();
      const same = replyValue === defaultValue;
      return !same && !loading;
    };

    const onCancel = () => {
      if (hasUnsavedChanges()) {
        dispatch(openConfirmationDialog({
          bodyText: t.FORM.UNSAVED_CHANGES.ARE_YOU_SURE(),
          confirmText: t.FORM.CLOSE_ANYWAY(),
          cancelText: t.FORM.DO_NOT_CLOSE(),
          icon: 'warning',
          onConfirm: props.onCancel,
        }));
      } else {
        props.onCancel?.();
      }
    };

    if (mode !== LecturePageMode.VIEW) {
      return null;
    }

    return (
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onReply)}>
          <div css={styles}>
            <NvUserAvatar
              user={currentUser}
              size='sm'
              borderType='round'
              displayRoleBadge={false}
              pendoTagName={config.pendo.userProfiles.openProfile}
            />
            <div className='new-reply-body'>
              <NvFroala
                ref={ref}
                preset={FroalaViewMode.AIR}
                withForm
                name='reply'
                placeholder={t.FROALA.TEXT_PLACEHOLDER()}
                mentionableUsers={props.mentionableMembers}
                ariaLabel={`${t.DISCUSSIONS.REPLY()} ${t.FROALA.BODY_PLACEHOLDER()}`}
              />
              {/* Visible only on xs */}
              <div className='d-block d-md-none'>
                <div className='buttons-xs'>
                  <Button block variant='secondary' size='sm' className='cancel' onClick={onCancel}>{t.FORM.CANCEL()}</Button>
                  <Button
                    block
                    type='submit'
                    variant='primary'
                    size='sm'
                    disabled={!formState.isValid}
                    pendo-tag-name={config.pendo.teamDiscussion.reply}
                  >
                    {t.DISCUSSIONS.REPLY()}
                  </Button>
                </div>
              </div>
              {/* Hidden only on xs */}
              <div className='d-none d-md-block'>
                <div className='buttons'>
                  <Button variant='secondary' size='sm' className='cancel' onClick={onCancel}>{t.FORM.CANCEL()}</Button>
                  <Button
                    type='submit'
                    variant='primary'
                    size='sm'
                    disabled={!formState.isValid || loading}
                    pendo-tag-name={config.pendo.teamDiscussion.reply}
                  >
                    {t.DISCUSSIONS.REPLY()}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    );
  },
);

export default DiscussionReplyForm;
