/* @ngInject */
export default function FlyOutPanelController(
  $scope,
  $element,
  FlyOutPanelManager,
  $state,
  $document,
  $rootScope,
  nvCurrentPage,
  $timeout,
  _,
  $uibModalStack,
) {
  const _this = this;
  _this.FlyOutPanelManager = FlyOutPanelManager;
  _this.hideFlyOutPanel = hideFlyOutPanel;
  _this.isModalOpen = isModalOpen;

  function hideFlyOutPanel() {
    // Manually Closing the Panel and going to prev state
    // Which will trigger a state change to a < L3.5 state
    // Thus closing the flyout panel from app.js stateChangeSuccess
    FlyOutPanelManager.closeFlyoutPanelCallback();
  }

  /**
   * Check whether current state is a modal page.
   * Used for adding area-hidden attribute to prevent screen readers
   * reading the flyout when modal opened
   * @returns boolian
   */
  function isModalOpen() {
    return $state.includes('modal-page') || $uibModalStack.getTop();
  }

  // BEGIN ACCESSIBILITY FIXES COPIED FROM ANGULAR BOOTRAP'S UIBMODALSTACK
  // AND FROM STICKY HEADER FOOTER CONTROLLER

  // Modal focus behavior
  const tabableSelector = 'a[href], area[href], input:not([disabled]), '
      + 'button:not([disabled]),select:not([disabled]), textarea:not([disabled]), '
      + 'iframe, object, embed, *[tabindex], *[contenteditable=true]';


  const loadFocusElementList = function () {
    const elements = $element[0].querySelectorAll(tabableSelector);
    return elements
      ? _.filter(elements, (element) => $(element).css('display') !== 'none') : elements;
  };

  const focusFirstFocusableElement = function (list) {
    if (list.length > 0) {
      list[0].focus();
      return true;
    }
    return false;
  };

  const focusLastFocusableElement = function (list) {
    if (list.length > 0) {
      list[list.length - 1].focus();
      return true;
    }
    return false;
  };

  const isModalFocused = function (evt) {
    if (evt) {
      return (evt.target || evt.srcElement) === $element[0];
    }
    return false;
  };

  const isFocusInFirstItem = function (evt, list) {
    if (list.length > 0) {
      return (evt.target || evt.srcElement) === list[0];
    }
    return false;
  };

  const isFocusInLastItem = function (evt, list) {
    if (list.length > 0) {
      return (evt.target || evt.srcElement) === list[list.length - 1];
    }
    return false;
  };


  $document.on('keydown', keydownListener);

  $rootScope.$on('$destroy', () => {
    $document.off('keydown', keydownListener);
  });

  function keydownListener(evt) {
    if (evt.isDefaultPrevented()) {
      return evt;
    }


    if (nvCurrentPage.getCurrentStateData().level === 3.5) {
      switch (evt.which) {
        case 9: {
          const list = loadFocusElementList();
          let focusChanged = false;
          if (evt.shiftKey) {
            if (isFocusInFirstItem(evt, list) || isModalFocused(evt)) {
              focusChanged = focusLastFocusableElement(list);
            }
          } else if (isFocusInLastItem(evt, list)) {
            focusChanged = focusFirstFocusableElement(list);
          }
          if (focusChanged) {
            evt.preventDefault();
            evt.stopPropagation();
          }

          break;
        }
        default:
          break;
      }
    }
    return null;
  }

  // focus the first element in the modal page
  function init() {
    if (!($document[0].activeElement && $element[0].contains($document[0].activeElement))) {
      const inputWithAutofocus = $element[0].querySelector('[autofocus]');
      /**
         * Auto-focusing of a freshly-opened modal element causes any child elements
         * with the autofocus attribute to lose focus. This is an issue on touch
         * based devices which will show and then hide the onscreen keyboard.
         * Attempts to refocus the autofocus element via JavaScript will not reopen
         * the onscreen keyboard. Fixed by updated the focusing logic to only autofocus
         * the modal element if the modal does not contain an autofocus element.
      */
      if (inputWithAutofocus) {
        inputWithAutofocus.focus();
      } else {
        // THIS DIFFERS FROM UI BOOTSTRAP:
        $timeout(() => {
          $('.flyout-page').focus();
        });
      }
    }
  }

  $scope.$on('$stateChangeSuccess', (event, toState) => {
    // This is needed when opening an Level 3.5 from an Level 3.5
    // Eg: Going to a different team workspace page while viewing one
    if (toState.data.level === 3.5) {
      // Initialize tab events and set focus for the first element
      init();
    }
  });

  $scope.$on('$destroy', () => {
    if ($rootScope.focusedElementPreFlyout) {
      $rootScope.focusedElementPreFlyout.focus();
    }
  });
  // END ACCESSIBILITY FIXES

  return _this;
}
