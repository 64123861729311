import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';
import { orderBy } from 'lodash';
// spacing, colors, fonts
import { gray5, gray2, danger, warning } from 'styles/global_defaults/colors';
import { doubleSpacing, halfSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import { textSmallFontSize } from 'styles/global_defaults/fonts';
// components
import { handheld } from 'styles/global_defaults/media-queries';

// types
export type BasicTableColumn = {
  key: string,
  name: string,
  className: string,
  sortable: boolean,
  gridWidth: string,
  type: ColumnType,
};

export enum ColumnType {
  STRING = 'string',
  NUMBER = 'number',
}

// TODO: RowComponentType doesn't seem to be used. Investigate removing.
export type NvBasicTableProps<RowComponentType, RowDataType> = {
  columns: BasicTableColumn[],
  tabledata: RowDataType,
  rowComponent: React.FC<RowDataType>,
  rowClass: string,
  highlightClass?: string,
  highlightId?: number,
  defaultSort?: ColumnSort,
};

export enum SortType {
  INACTIVE = 'inactive',
  ASC = 'up-active',
  DESC = 'down-active',
}

type ColumnSort = {
  name: string,
  type: SortType,
};

export const NvBasicTable = <RowComponentType, RowDataType>({ columns, tabledata, rowComponent, rowClass, highlightClass, highlightId, defaultSort }: NvBasicTableProps<RowComponentType, RowDataType>) => {
  const [columnSorting, setColumnSorting] = useState<ColumnSort>({ name: defaultSort?.name || '', type: defaultSort?.type || SortType.INACTIVE });
  const [data, setData] = useState(Object.values(tabledata));

  // grabs the value of gridWidth for each column and creates the value to be used for grid-template-columns.
  // the gridWidth value can be set on pixels, percentages or fr.
  const gridColumnValues = columns.map((item) => item.gridWidth).join(' ');

  const getSortedData = (datalist) => {
    const sortType = columnSorting.type === SortType.DESC ? 'desc' : 'asc';
    // case insensitive sort if the value is a string
    const doSort = (item) => (typeof (item[columnSorting.name]) === 'string' ? item[columnSorting.name]?.toLowerCase() : item[columnSorting.name]);
    const sortTypes: ['asc' | 'desc'] = [sortType];
    const additionalColumns = columns.filter(column => column.key !== columnSorting.name).map(
      (col) => {
        // by default, when same values, sort the other columns in ascending order.
        sortTypes.push('asc');
        // return a method when the column is a string, same as above
        if (col.type === ColumnType.STRING) {
          return (item) => (typeof (item[col.key]) === 'string' ? item[col.key]?.toLowerCase() : item[col.key]);
        }
        return col.key;
      },
    );
    const sorteddata = orderBy(datalist, [doSort, ...additionalColumns], sortTypes);
    return sorteddata;
  };

  // sorts data when columnsorting changes
  useEffect(() => {
    if (columnSorting.type !== SortType.INACTIVE) {
      setData(getSortedData(data));
    }
  }, [columnSorting]);

  // updates data and sorting if needed when props.data changes
  useEffect(() => {
    if (columnSorting.type !== SortType.INACTIVE) {
      setData(getSortedData(Object.values(tabledata)));
    } else {
      setData(Object.values(tabledata));
    }
  }, [tabledata]);

  const styles = css`
    &.basic-table {
      display: grid;
      margin-top: ${doubleSpacing}px;
      .table-row {
        display: grid;
        grid-template-columns: ${gridColumnValues};
        border-bottom: 1px solid ${gray5};
        &.row-added {
          animation: highlightrow 4s ease;
        }
        @keyframes highlightrow {
          from { border-bottom: 1px solid ${gray5};}
          50% {border-bottom: 1px solid ${warning};}
          to { border-bottom: 1px solid ${gray5};}
        }
        &:hover {
          border-bottom: 1px solid ${warning};
        }
        &.table-header {
          font-size: ${textSmallFontSize}px;
          color: ${gray2};
          padding-bottom: ${halfSpacing}px;
          &:hover {
            border-bottom: 1px solid ${gray5};
          }
        }
      }
    }
  `;

  const onHeaderSorting = (name, type) => {
    setColumnSorting({ name, type });
  };

  const RowComponent = rowComponent;

  // const LoadingRow = () => <div>Loading...</div>;
  return (
    <div css={styles} className='basic-table'>
      <div className='table-row table-header'>
        {columns.map((column) => <TableHeader active={columnSorting.name === column.key} key={column.name} column={column} onColumnSort={onHeaderSorting} currentSort={columnSorting} />)}
      </div>
      {data.map((item: any) => (
        <div key={item.id} className={`table-row ${rowClass} ${item.id === highlightId ? highlightClass : ''}`}>
          <RowComponent {...tabledata[item.id]} />
        </div>
      ))}
    </div>
  );
};

// Table header display a column header that can be sortable or not
// active : determines if the header is the currently active one, a there's only one active per table.
// column: object with column properties to be displayed (sortable, name)
// onColumnSort : callback when the column is clicked.
const TableHeader = ({ active, column, currentSort, onColumnSort }) => {
  const [sort, setSort] = useState(active ? currentSort.type : SortType.INACTIVE);

  const handleSortClick = (e) => {
    if (sort === SortType.INACTIVE) {
      setSort(SortType.DESC);
    } else {
      setSort(sort === SortType.DESC ? SortType.ASC : SortType.DESC);
    }
  };

  useEffect(() => {
    if (sort !== SortType.INACTIVE) {
      onColumnSort(column.key, sort);
    }
  }, [sort]);

  useEffect(() => {
    if (!active) {
      setSort(SortType.INACTIVE);
    }
  }, [active]);

  const styles = css`
    &.column-header {
      display: flex;
      flex-direction: row;
      align-items: center;

      ${handheld(css`
        .column-label {
          inline-size: min-content;
        }
      `)};

      &.sortable {
        cursor: pointer;
      }
      .icon {
        margin-left: ${quarterSpacing}px;
      }
    }
  `;

  return (
    <div
      css={styles}
      key={column.key}
      className={`column-header ${column.sortable ? 'sortable' : null} ${column.className}`}
      // adding the onclick handler only if the column is sortable
      onClick={(column.sortable && handleSortClick) || undefined}
    >
      <span className='column-label'>{column.name}</span>
      {column.sortable && <span className={`icon icon-xsmall icon-sorting-${sort}`} />}
    </div>
  );
};
