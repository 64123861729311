import { useAngularPreventLecturePageNavigation } from '../../lecture_pages/hooks/use-prevent-lecture-page-navigation';

/* @ngInject */
export default function NewTopicModalController(
  DiscussionsManager,
  $uibModalInstance,
  selectedTopic,
  CurrentUserManager,
  StateManager,
  _,
  $filter,
  ConfirmationOverlays,
  $scope,
  AlertMessages,
) {
  const vm = this;
  vm.manager = DiscussionsManager;
  vm.currentUserManager = CurrentUserManager;
  vm._ = _;
  vm.unsavedChangesMessage = 'FORM.NAVIGATE_AWAY.CLOSE_WINDOW';
  vm.confirmingClose = false;
  vm.submitting = false;
  vm.submittingSuccessful = false;
  vm.defaultReleaseDate = Date.now();
  vm.deregisterModalClosing = null;

  let insertAfter = null;

  if (selectedTopic && selectedTopic.position !== 0) {
    insertAfter = selectedTopic.position - 1;
  }

  vm.topic = {
    title: selectedTopic ? selectedTopic.title : null,
    description: selectedTopic ? selectedTopic.description : null,
    insertAfter,
    releaseDate: selectedTopic ? selectedTopic.releaseDate : vm.defaultReleaseDate,
    id: selectedTopic ? selectedTopic.id : null,
    updating: !!selectedTopic,
  };

  vm.selectingDate = selectedTopic && !selectedTopic.released;

  vm.submitNewTopic = function () {
    vm.submitting = true;
    // if user didn't change default release date, then set to null so we don't try to save it
    const normalizedTopicData = _.extend(vm.topic, { releaseDate: (vm.topic.releaseDate === vm.defaultReleaseDate ? null : vm.topic.releaseDate) });
    let responsePromise = null;

    if (vm.topic.updating) {
      responsePromise = DiscussionsManager.updateTopic(normalizedTopicData);
    } else {
      responsePromise = DiscussionsManager.createTopic(normalizedTopicData);
    }

    responsePromise.then(
      () => {
        vm.submittingSuccessful = true;
        vm.submitting = false;
        vm.deregisterModalClosing?.();
        vm.deregisterStateChangeStart?.();
        $uibModalInstance.close();
      },
    ).catch(
      (error) => {
        vm.submitting = false;
        AlertMessages.error('', 'FORM.ERROR');
      },
    );
  };

  vm.insertAfter = function (index) {
    vm.topic.insertAfter = index;
  };

  vm.findTopicByPosition = function (index, topicId) {
    if (index === undefined) {
      return _.findWhere(vm.manager.allTopics, { id: topicId });
    }
    return $filter('orderBy')($filter('filter')(_.rest(DiscussionsManager.allTopics), (!CurrentUserManager.isAdmin() || undefined) && { released: true }), 'position')[index];
  };

  vm.adjustReleaseDateValue = function () {
    if (!vm.selectingDate || (vm.releaseDate === undefined || vm.releaseDate === '')) {
      vm.topic.releaseDate = null;
    }
  };

  vm.checkFormValidity = function () {
    return (vm.topic.title && vm.newTopicForm.$valid)
      && (!vm.topic.updating || vm.newTopicForm.$dirty || (selectedTopic && vm.topic.insertAfter !== (selectedTopic.position - 1)));
  };

  const checkUnsavedChanges = () => vm.newTopicForm && (vm.newTopicForm.$dirty || (selectedTopic && vm.topic.insertAfter !== (selectedTopic.position - 1))) && !vm.submitting;

  useAngularPreventLecturePageNavigation($scope, checkUnsavedChanges);

  // warn if leaving the modal and have unsaved changes
  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(
    checkUnsavedChanges,
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    vm.unsavedChangesMessage,
    undefined,
    undefined,
    () => {
      vm.deregisterModalClosing?.();

      vm.confirmingClose = true;
      vm.submitting = false;

      $uibModalInstance.dismiss();
    },
  );

  vm.deregisterModalClosing = $scope.$on('modal.closing', ($event) => {
    if ((vm.newTopicForm
      && (vm.newTopicForm.$dirty || (selectedTopic && vm.topic.insertAfter !== (selectedTopic.position - 1))))
      && !vm.confirmingClose
      && !vm.submitting && !vm.submittingSuccessful) {
      $event.preventDefault();


      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        vm.confirmingClose = true;
        vm.submitting = false;
        $uibModalInstance.dismiss();
      });
    }
  });

  $scope.$on('$destroy', () => {
    vm.deregisterStateChangeStart?.();
  });
}
