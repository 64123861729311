/* @ngInject */
export default function ProfileCompletionEditFormModalController(
  $scope,
  config,
  vmResolves,
  MigratedFields,
  CurrentUserManager,
  LearnerProfileModel,
  CurrentCourseManager,
) {
  const vm = this;
  vm.config = config;
  vm.vmResolves = vmResolves;
  vm.lectureComponent = vmResolves.lectureComponent;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.isEditing = vm.vmResolves?.workflowCtx?.mode === 'edit';

  vm.questionsLists = {
    novoed: ([
      {
        isRequired: false,
        questionIdentifier: 'profile_image',
        translation: 'PROFILE.PROFILE_IMAGE',
      },
      {
        isRequired: false,
        questionIdentifier: 'headline',
        translation: 'PROFILE.HEADLINE',
      },
      {
        isRequired: false,
        questionIdentifier: 'bio',
        translation: 'PROFILE.BIO',
      },
      {
        isRequired: false,
        questionIdentifier: 'location',
        translation: 'PROFILE.BIO_QUESTIONS.LOCATION',
      },
    ]).filter((novoedQuestion) => {
      const { profileSettings } = CurrentCourseManager.course.institution;

      const accountLevelFieldSettings = profileSettings.accountLevel[novoedQuestion.questionIdentifier];

      if (accountLevelFieldSettings) {
        return !accountLevelFieldSettings.isHidden;
      }

      return true;
    }),
    course: [],
    orgLevel: CurrentCourseManager.course.institution.profileSettings.orgLevel.filter(
      /**
       * NOTE: Hidden and integrated fields shouldn't appear in configuration
       * modal but only evaluating with "isIntegrated" because only integrated
       * fields can be hidden.
       * CSV Managed fields will not be displayed in the modal regardless of
       * hidden or not.
       */
      (orgField) => !orgField.isIntegrated && !orgField.isCsvManaged,
    ).map((orgField) => {
      const { name, migratedField } = orgField;

      const displayName = (migratedField && !name) ? MigratedFields.getDisplayName(migratedField) : name;

      return {
        ...orgField,
        name: displayName,
      };
    }),
  };

  vm.questionsStatuses = {
    novoed: {},
    course: {},
    orgLevel: {},
  };
  vm.initialQuestionsStatuses = {
    novoed: {},
    course: {},
    orgLevel: {},
  };

  vm.save = save;
  vm.getIsDirty = getIsDirty;
  vm.handleCourseProfileQuestionsDashboardClick = handleCourseProfileQuestionsDashboardClick;

  initialize();

  function initialize() {
    LearnerProfileModel.getCourseProfileQuestions({
      userId: CurrentUserManager.user.id,
      catalogId: CurrentCourseManager.currentCatalogId,
    })
      .then((questions) => {
        // Setting initial isRequired flag to fetched course profile questions
        questions.forEach((question) => {
          question.isRequired = false;
        });

        return questions;
      })
      .then((questions) => {
        vm.questionsLists.course = questions;

        const {
          orgFields: orgFieldsLC,
          novoedQuestions: novoedQuestionsLC,
          courseProfileQuestions: courseProfileQuestionsLC,
        } = vm.lectureComponent.profileRequirement;

        // Org fields status population
        if (orgFieldsLC) {
          orgFieldsLC.forEach((orgFieldLC) => {
            const foundQuestion = vm.questionsLists.orgLevel.find(
              (orgField) => orgField.id === orgFieldLC.id,
            );

            if (foundQuestion) {
              foundQuestion.isRequired = orgFieldLC.isRequired;
            }
          });
        }

        // Novoed questions status population
        if (novoedQuestionsLC) {
          novoedQuestionsLC.forEach((identifier) => {
            const foundQuestion = vm.questionsLists.novoed.find(
              (novoedQuestion) => novoedQuestion.questionIdentifier === identifier,
            );

            if (foundQuestion) {
              foundQuestion.isRequired = true;
            }
          });
        }

        // Course profile questions population
        if (courseProfileQuestionsLC) {
          courseProfileQuestionsLC.forEach((courseProfileQuestionLC) => {
            const foundQuestion = vm.questionsLists.course.find(
              (courseQuestion) => courseQuestion.questionId === courseProfileQuestionLC.id,
            );

            if (foundQuestion) {
              foundQuestion.isRequired = courseProfileQuestionLC.isRequired;
            }
          });
        }

        // Setting up questions statuses
        vm.questionsStatuses.novoed = vm.questionsLists.novoed.reduce(
          (acc, novoedQuestion) => {
            acc[novoedQuestion.questionIdentifier] = novoedQuestion.isRequired;

            return acc;
          },
          {},
        );

        vm.questionsStatuses.course = vm.questionsLists.course.reduce(
          (acc, courseQuestion) => {
            acc[courseQuestion.questionId] = courseQuestion.isRequired;

            return acc;
          },
          {},
        );

        vm.questionsStatuses.orgLevel = vm.questionsLists.orgLevel.reduce(
          (acc, orgField) => {
            acc[orgField.id] = orgField.isRequired;

            return acc;
          },
          {},
        );

        // Setting up initial questions statuses
        vm.initialQuestionsStatuses.novoed = { ...vm.questionsStatuses.novoed };
        vm.initialQuestionsStatuses.course = { ...vm.questionsStatuses.course };
        vm.initialQuestionsStatuses.orgLevel = { ...vm.questionsStatuses.orgLevel };
      });
  }

  /**
   * @param {boolean} redirectToAdminNavigation - Redirect to NovoEd 1.0 admin
   * page after the saving.
   */
  // eslint-disable-next-line consistent-return
  function save(redirectToAdminNavigation = false) {
    const newRequiredNovoedQuestions = Object.entries(
      vm.questionsStatuses.novoed,
    )
      .filter(([, value]) => value)
      .map(([key]) => key);

    const newRequiredOrgFields = Object.entries(
      vm.questionsStatuses.orgLevel,
    )
      .filter(([, value]) => value)
      .map(([key]) => Number(key));

    const newRequiredCourseProfileQuestions = Object.entries(
      vm.questionsStatuses.course,
    )
      .filter(([, value]) => value)
      .map(([key]) => Number(key));

    const requiredQuestions = {
      novoed: newRequiredNovoedQuestions,
      orgLevel: newRequiredOrgFields,
      course: newRequiredCourseProfileQuestions,
    };

    vm.lectureComponent.setDraftRequiredQuestions(requiredQuestions);
    vm.lectureComponent.redirectToAdminNavigation = redirectToAdminNavigation;

    $scope.$close();
  }

  function getIsDirty() {
    // Novoed dirty
    const novoedQuestionsDirty = Object.entries(
      vm.initialQuestionsStatuses.novoed,
    ).some(
      ([id, status]) => vm.questionsStatuses.novoed[id] !== status,
    );

    // Course dirty
    const courseQuestionsDirty = Object.entries(
      vm.initialQuestionsStatuses.course,
    ).some(
      ([id, status]) => vm.questionsStatuses.course[id] !== status,
    );

    // Org level fields dirty
    const orgFieldsDirty = Object.entries(
      vm.initialQuestionsStatuses.orgLevel,
    ).some(
      ([id, status]) => vm.questionsStatuses.orgLevel[id] !== status,
    );

    return novoedQuestionsDirty || courseQuestionsDirty || orgFieldsDirty;
  }

  function handleCourseProfileQuestionsDashboardClick() {
    save(true);
  }
}
