/* @ngInject */
export default function NvCountdownTimer(
  $timeout,
) {
  return {
    restrict: 'A',
    scope: {
      timer: '=',
      displayMode: '=',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'shared/templates/nv-countdown-timer.html',
  };
}
