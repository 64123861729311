import { RootState } from 'redux/schemas';
import { MentorDashboard, OrgMentee, OrgMentor } from 'redux/schemas/models/org-mentors';

export const getOrgMentorsKeys = (state: RootState): number[] => {
  if (!state.app.currentInstitutionId) {
    return null;
  }
  return (state.app.orgMentorAdminDashboard.mentors ?? []).map((id) => (state.models.orgMentors[id] ? id : undefined));
};

export const getMentorDetails = (state: RootState, mentorId: number): OrgMentor => {
  if (!state.app.currentInstitutionId) {
    return null;
  }
  return state.models.orgMentors[mentorId];
};

export const searchHasMore = (state: RootState): boolean => state.app.orgMentorAdminDashboard.search.hasMore;

export const getMentorDashboard = (state: RootState): MentorDashboard => state.app.mentorDashboard;

export const getCurrentMentees = (state: RootState) => state.app.mentorDashboard.mentees;

export const getOrgMentee = (state: RootState, menteeId: number): OrgMentee => state.models.orgMentees[menteeId];
