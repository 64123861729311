export default {
  bindings: {
    exercise: '<',
    course: '<',
    onNewReport: '&',
    onReviseSubmitted: '&',
    onResumeUnsubmitted: '&',
  },
  controller: function ctrl(
    _,
    $stateParams,
    CurrentCourseManager,
    moment,
    config,
  ) {
'ngInject';
    this.moment = moment;
    this.CurrentCourseManager = CurrentCourseManager;
    this.config = config;

    this.$onInit = function () {
      this.$stateParams = $stateParams;
    };

    this.revise = function (reportId) {
      this.onReviseSubmitted({ reportId });
    };

    this.resume = function (reportId) {
      this.onResumeUnsubmitted({ reportId });
    };

    this.hasFeedbackReceived = function () {
      return this.exercise.hasSkillsRating
        && _.some(this.exercise.submissions, (submission) => submission.numReviewsReceived > 0);
    };

    this.ratedSubmissions = function () {
      return this.exercise.submissions.filter((submission) => submission.numReviewsReceived > 0);
    };

    this.submissionStringTranslateData = function () {
      const dateFormat = this.moment().isSame(this.exercise.submissions[0].currentRevision.revisedAt, 'year') ? 'MOMENT.MONTH_DAY_AT_TIME' : 'MOMENT.MONTH_DAY_COMMA_YEAR_AT_TIME';

      return _.extend({}, CurrentCourseManager.course.getAliases(), {
        user: this.exercise.submissions[0].currentRevision?.creator?.firstName,
        date: moment(this.exercise.submissions[0].submittingObject?.createdAt).format(dateFormat),
        revisedDate: this.exercise.submissions[0].revisedAfterSubmit && this.exercise.submissions[0].currentRevision.revisedAt && moment(this.exercise.submissions[0].currentRevision.revisedAt).format(dateFormat),
        revisedAfterSubmit: this.exercise.submissions[0].revisedAfterSubmit,
        isPendingApproval: this.exercise.isPendingApproval(),
        isNeedRevision: this.exercise.isRejectedCanRevise(),
        wasNeedRevision: this.exercise.isRejectedCannotRevise(),
        isApproved: this.exercise.isApproved(),
      });
    };
  },
  controllerAs: 'vm',
  templateUrl: 'exercises/templates/report_deliverable/learner-exercise-actions-report-deliverable.html',
};
