/* @ngInject */
export default function LeaderboardResources(

  $resource,
) {
  return $resource(
    '',
    {},
    {
      getLeaderboard: { method: 'GET', url: '/:catalogId/leaderboard.json' },
    },
  );
}
