/* @ngInject */
export default function EvaluationsTaskManager(

  _,
  EvaluationsTaskModel,
  PeerReviewModel,
  AggregatePeerReviewModel,
  nvUtil,
  CurrentCourseManager,
  $q,
) {
  const manager = {
    // functions
    getNewPeerReview,
    getPeerReview,
    getAggregatePeerReviews,
    setCurrentPeerReview,

    // data:
    currentEvaluationsTask: null,
    currentPeerReview: null,
    currentAggregatePeerReviews: null,
  };

  function getNewPeerReview(catalogId, exerciseId, reportId) {
    return PeerReviewModel.getNewPeerReview(catalogId, exerciseId, reportId)
      .then((peerReview) => {
        manager.currentPeerReview = peerReview;
        manager.currentPeerReview.isNew = true;

        return peerReview;
      });
  }

  function getPeerReview(catalogId, peerReviewId) {
    return PeerReviewModel.get(catalogId, peerReviewId)
      .then((peerReview) => {
        manager.currentPeerReview = peerReview;

        return peerReview;
      });
  }

  function getAggregatePeerReviews(catalogId, reportId) {
    return AggregatePeerReviewModel.get(catalogId, reportId)
      .then((aggregatePeerReview) => {
        manager.currentAggregatePeerReviews = aggregatePeerReview;

        return manager.currentAggregatePeerReviews;
      });
  }

  function setCurrentPeerReview(peerReviewId) {
    manager.currentPeerReview = _.findWhere(manager.currentAggregatePeerReviews.peerReviews, { id: peerReviewId });
  }

  return manager;
}
