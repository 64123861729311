import { normalize } from 'normalizr';
import mergeWith from 'lodash/mergeWith';
import { createReducer } from '@reduxjs/toolkit';
import { replaceArrays } from 'shared/lodash-utils';
import {
  initialRootState,
  initialCourseCommunicationForCourse,
} from 'redux/reducers';
import {
  fetchJourney,
  createJourney,
  enrollInJourney,
  updateJourneyCollections,
  validateActivityCode,
  updateVisitedJourney,
} from 'redux/actions/learning-journeys';
import { courseSchema } from 'redux/schemas/api/course';
import { enrollmentSchema } from 'redux/schemas/api/my-account';
import { VisitedLearningJourneySchema } from 'redux/schemas/app/learning-journey';
import { isEmpty } from 'underscore';

export const initialVisitedLearningJourney: VisitedLearningJourneySchema = {
  id: null,
  catalogId: null,
  name: null,
  releaseDate: null,
  closeDate: null,
  headerColor: null,
  nameFontColor: null,
  headerBackground: null,
  completionStats: null,
};

const journeyCase = (state, journey) => {
  const normalized: any = normalize(journey, courseSchema);

  const currentJourney = normalized.entities.courses[normalized.result];

  if (currentJourney.collectionIds) {
    const courses = {};

    // Manually normalization because using recursive schemas doesn't work.
    currentJourney.collectionIds.forEach((collectionId) => {
      normalized.entities.journeyCollections[collectionId].courseIds = [];
      normalized.entities.journeyCollections[collectionId].courses.forEach((course) => {
        courses[course.catalogId] = course;
        normalized.entities.journeyCollections[collectionId].courseIds.push(course.id);
      });

      delete normalized.entities.journeyCollections[collectionId].courses;
    });

    mergeWith(state.models.courses, courses, replaceArrays);
  }
  mergeWith(state.models, normalized.entities, replaceArrays);

  if (!state.app.courseCommunications[currentJourney.catalogId]) {
    state.app.courseCommunications[currentJourney.catalogId] = initialCourseCommunicationForCourse;
  }
};

const sumTotalCase = (state, payload) => {
  const {
    lmsActivityId: externalCourseId = null,
    lmsActivityCode: externalCourseNumber = null,
  } = payload;
  state.app.sumTotalIntegration = { externalCourseId, externalCourseNumber };
};

export default createReducer(initialRootState, builder => {
  builder
    .addCase(fetchJourney.fulfilled, (state, action) => {
      const { journey, shouldSetCourse } = action.payload;

      journeyCase(state, journey);

      if (shouldSetCourse) {
        state.app.currentCourseId = journey.id;
        state.app.currentCatalogId = journey.catalogId;
      }
    });
  builder
    .addCase(createJourney.fulfilled, (state, action) => journeyCase(state, action.payload));
  builder
    .addCase(updateJourneyCollections.fulfilled, (state, action) => journeyCase(state, action.payload));
  builder
    .addCase(enrollInJourney.fulfilled, (state, action) => {
      const normalized = normalize(action.payload, enrollmentSchema);
      state.models.users[state.app.currentUserId].enrollmentIds.push(normalized.result);
      mergeWith(state.models, normalized.entities, replaceArrays);
    });
  builder
    .addCase(validateActivityCode.fulfilled, (state, action) => sumTotalCase(state, action.payload));
  builder
    .addCase(validateActivityCode.rejected, state => sumTotalCase(state, {}));
  builder
    .addCase(updateVisitedJourney, (state, action) => {
      state.app.visitedLearningJourney = action.payload;
    });
});
