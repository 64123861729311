/* @ngInject */
export default function ExerciseShareWithTeamModalController(
  $uibModal,
  $uibModalInstance,
  config,
  submission,
) {
  const vm = Object.assign(this, {
    config,
    submission,
  });

  vm.submit = () => {
    $uibModal.open({
      templateUrl: 'lecture_pages/templates/components/exercise-share-submission-modal.html',
      windowClass: 'large-modal',
      controller: 'ExerciseShareSubmissionModalCtrl as vm',
      resolve: {
        submission: vm.submission,
      },
    });
    $uibModalInstance.dismiss();
  };
}
