/* @ngInject */
export default function TeamDiscussionLectureComponentModel(
  _,
  $uibModal,
  $state,
  $translate,
  AbstractDiscussionLectureComponentModel,
  CurrentCourseManager,
) {
  class TeamDiscussionComponent extends AbstractDiscussionLectureComponentModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      const defaults = {
        type: 'TeamDiscussionLectureComponent',
        topic: {
          title: '',
          body: '',
          highLightedFrom: null,
          highlightedTo: null,
          starterId: null,
          formedByStudents: null,
          teamSizeLimit: 10,
          name: CurrentCourseManager.course.teamName.capitalizedPluralized,
        },
        isActivity: true,
        canBeCopied: true,
      };

      super(_.extend({}, defaults, attributes), isSample, useDefaults);
    }

    getPayload(includePost = false) {
      const postFields = [
        'id', 'title', 'body', 'isTodo', 'starterId', 'highlightedFrom',
        'highlightedTo', 'formedByStudents', 'teamSizeLimit', 'name',
      ];
      const payload = {
        topic: _.pick(this.post, postFields),
      };

      if (includePost) {
        payload.post = { ...payload.topic };
      }

      return payload;
    }

    getSavingMessage() {
      if (this.addingTeamFormationExercise) {
        return $translate.instant('LECTURE_PAGES.COMPONENTS.TEAM_DISCUSSION.TEAM_FORMATION_AND_TEAM_DISCUSSION_ADDING', CurrentCourseManager.course.getAliases());
      }

      return null;
    }

    saveDraft() {
      if ((!this.post.teamSet || !this.post.teamSet.isCourseLong) && !CurrentCourseManager.course.hasCourseLongTeamSet) {
        this.addingTeamFormationExercise = true;
      } else {
        this.addingTeamFormationExercise = false;
      }

      super.saveDraft();
      if (this.addingTeamFormationExercise) {
        CurrentCourseManager.course.courseLongTeamSet = this.post.teamSet;
      }
    }

    get showCreateConfirmation() {
      return this.addingTeamFormationExercise;
    }

    get createConfirmationTemplate() {
      return 'lecture_pages/templates/components/team-discussion-creation-confirmation-overlay.html';
    }

    // Currently, there is no way to add 2 components to a lecture page at once.
    // When adding a team discussion activity (TDA) which needs a team formation,
    // the TDA is inserted synchronously into the list of lecture page components.
    // Need to force a state reload here to retrieve full list of components from BE.
    afterCreate() {
      if (this.addingTeamFormationExercise) {
        $state.reload();
      }
    }
  }

  return TeamDiscussionComponent;
}
