import React, { useState } from 'react';
import t from 'react-translate';

import { formatFileSize } from 'shared/react-utils';
import prodPathReplace from 'shared/prod-path-rewrite';
import { UploadedFile } from 'redux/schemas/models/file-uploads';

import ClickableContainer from 'components/clickable-container';
import NvIcon from '../nv-icon';
import NvTooltip from '../nv-tooltip';
import NvModal, { ModalType } from '../nv-modal';
import NvUploadedFileContent from './nv-uploaded-file-content';

import useUploadedFile from './hooks/use-uploaded-file';

type NvUploadedFileCompactViewProps = {
  file: UploadedFile,
  openModalOnClick?: boolean
};

const NvUploadedFileCompactView = (props: NvUploadedFileCompactViewProps) => {
  const { file, openModalOnClick = true } = props;
  const [showContentModal, setShowContentModal] = useState(false);
  const { getNormalizeFileType, isFileAvailableToDownload, getImageSrc } = useUploadedFile();

  const isExternalDoc = file.type === 'externaldocument' || file.source === 'googleDrive';
  const normalizedFileType = getNormalizeFileType(file.type, file.name);

  const getFileImagepath = () => (isExternalDoc ? prodPathReplace('images/google.png') : getImageSrc(normalizedFileType));

  return (
    <React.Fragment>
      <ClickableContainer
        className='file downloadable'
        onClick={() => setShowContentModal(true)}
        disabled={!openModalOnClick}
      >
        <img
          className={`${isExternalDoc ? 'file-source' : 'uploaded-file-image'}`}
          src={getFileImagepath()}
          alt={normalizedFileType}
        />
        <div className={`uploaded-file-name ${normalizedFileType === 'zip' ? 'zip' : ''}`}>
          <span>{file.name || file.title}</span>
          {normalizedFileType === 'zip' && (
            <div className='uploaded-file-size'>
              { formatFileSize(file.size) }
            </div>
          )}
        </div>
        <div className='file-actions'>
          {(file.url && isFileAvailableToDownload(file)) && (
            <a
              className='downloadable-icon'
              href={file.url}
              target='_blank'
              rel='noreferrer'
              download
            >
              <span className='sr-only'>
                {t.LECTURE_PAGES.DOWNLOAD_FILE_SCREEN_READ_ONLY()}
              </span>
              <NvTooltip
                text={t.FILE_UPLOAD.DOWNLOAD()}
                placement='top'
              >
                <NvIcon size='small' icon='download' />
              </NvTooltip>
            </a>
          )}
        </div>
      </ClickableContainer>

      <NvModal
        type={ModalType.FULL}
        fullHeight={false}
        header={file.name}
        show={showContentModal}
        body={<NvUploadedFileContent file={file} isOpenedInModal />}
        onClose={() => setShowContentModal(false)}
      />
    </React.Fragment>
  );
};

export default NvUploadedFileCompactView;
