/* @ngInject */
export default function CoursesResource(

  $resource,
) {
  return $resource(
    '/courses/:id.json',
    {},
    {
      get: { method: 'GET', url: '/:catalogId.json' },
      getFromJourney: { method: 'GET', url: '/:catalogId.json?parent_journey_id=:journeyId' },
      getById: { method: 'GET', url: '/courses/:id.json' },
      update: { method: 'PUT', url: '/courses/:id.json' },
      query: { isArray: false },
      getTeachingTeamMembers: { method: 'GET', url: '/:catalogId/teaching_team_list.json' },
      getActiveStudentsPath: { method: 'GET', url: '/:catalogId/enrolled_students.json' },
      getUnmentionableUserIds: { method: 'GET', url: '/:catalogId/unmentionable.json' },
      getUsersWithTeams: { method: 'GET', url: '/:catalogId/team_members/users_with_teams/:teamId', isArray: true },
      requestDeletion: { method: 'DELETE', url: '/courses/:id' },
      getDomainInfo: { method: 'GET', url: '/:catalogId/domain.json' },
      getCourseCompletionCriteria: { method: 'GET', url: '/:catalogId/course_completion_criteria.json' },
      updateCourseBasics: { method: 'PUT', url: '/courses/:id.json' },
      enroll: { method: 'GET', url: '/:catalogId/enroll.json' },
      reenroll: { method: 'GET', url: '/:catalogId/reenroll.json' },
      getAsJourney: { method: 'GET', url: '/journeys/:catalogId.json' },
    },
  );
}
