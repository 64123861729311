/* eslint-disable no-plusplus */
export default [
  function () {
    return {
      restrict: 'E',
      link: ($scope, $element) => {
        const onLoad = (event) => {
          const element = event.target;
          const doc = element.contentDocument;
          if (doc?.head) {
            const links = doc.head.getElementsByTagName('link');
            let href = '';
            for (let i = 0; i < links.length; i++) {
              if (links[i]?.href?.includes('/preview.css')) {
                href = links[i]?.href?.replace('/preview.css', '/preview_controls.css');
              }
            }
            const cssLink = document.createElement('link');
            cssLink.href = href;
            cssLink.rel = 'stylesheet';
            cssLink.type = 'text/css';
            doc.head.appendChild(cssLink);
          }
        };
        $element.on('load', onLoad);
      },
    };
  },
];
