/* eslint-disable react/require-default-props */
import { css } from '@emotion/react';
import ClickableContainer, { ClickableContainerProps } from 'components/clickable-container';
import t from 'react-translate';
import { BaseRightToggleButtonSectionViewOptions } from 'redux/schemas/models/lecture-component';
import NvTooltip from 'shared/components/nv-tooltip';
import { doubleSpacing } from 'styles/global_defaults/scaffolding';
import { LecturePageMode } from '..';

const rightHandIconContainerStyle = css`
  height: ${doubleSpacing}px;
  width: ${doubleSpacing}px;
  align-items: center;
  justify-content: center;
`;

const rightHandIconStyle = css`
  margin-right: 8px;
`;

interface ArrowToggleIconProps {
  expanded: boolean;
  mode: LecturePageMode;
  sectionViewOptions: BaseRightToggleButtonSectionViewOptions;
  onClick: ClickableContainerProps['onClick'];
  layoutOnly: ClickableContainerProps['layoutOnly'];
  // eslint-disable-next-line react/require-default-props
  hidden?: boolean;
  className?: string;
}

/* @ngInject */
export default function ArrowToggleIcon(props: ArrowToggleIconProps) {
  const iconClassName = props.expanded ? 'icon-arrow-up' : 'icon-arrow-down';
  return (
    <ClickableContainer
      className={props.className}
      onClick={props.onClick}
      layoutOnly={props.layoutOnly}
      css={css`
        ${rightHandIconStyle};
        ${rightHandIconContainerStyle};
        display: ${props.hidden ? 'none' : 'inline-flex'};
      `}
    >
      <NvTooltip
        enabled={props.mode === LecturePageMode.EDIT}
        text={
          props.expanded
            ? t.LECTURE_PAGES.COMPONENTS.ACCORDION.CLICK_TO_COLLAPSE()
            : t.LECTURE_PAGES.COMPONENTS.ACCORDION.CLICK_TO_EXPAND()
        }
      >
        <div
          css={css`
            color: ${props.sectionViewOptions.iconColor};
            font-size: 16px;
          `}
          className={iconClassName}
          aria-hidden
        />
      </NvTooltip>
    </ClickableContainer>
  );
}
