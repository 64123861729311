/* @ngInject */
export default function NvTeamProfilePanel(
  _,
  $window,
  $timeout,
  config,
  CurrentUserManager,
) {
  return {
    scope: {
      post: '=',
      showNewComment: '<?',
    },
    transclude: true,
    controller: function ctrl($scope, $element) {
'ngInject';
      const vm = this;
      vm.config = config;
      vm.displayAvatars = [];
      vm.remainingAvatars = [];
      vm.course = CurrentUserManager.getCurrentCourse();

      vm.onClickJoin = () => {
        vm.showNewComment();
      };

      vm.teamDiscussionMembers = vm.post.teamDiscussionMembers;
    },
    link: (scope, element, attrs, ctrl, transclude) => {
      const fitAvatarsInContainer = _.debounce(() => {
        const [buttonContainer] = $(element).find('.button-container');
        const buttonContainerRect = buttonContainer.getBoundingClientRect();

        // number of avatars that can fit in remaining spacing.
        // Width calculated as width of parent container - padding - width of button container
        const numAvatarsDisplayed = Math.floor(buttonContainerRect.width / 60) - 1;
        const displayPopover = numAvatarsDisplayed < ctrl.teamDiscussionMembers?.length;

        if (displayPopover) {
          ctrl.displayAvatars = ctrl.teamDiscussionMembers.slice(0, numAvatarsDisplayed);

          ctrl.leftOverAvatars = ctrl.teamDiscussionMembers.slice(numAvatarsDisplayed);
          ctrl.remainingAvatars = [];
          ctrl.remainingTotalNotifications = 0;
          let newRow = [];
          _.each(ctrl.leftOverAvatars, (avatar, i) => {
            if (avatar.newContributions) {
              ctrl.remainingTotalNotifications += avatar.newContributions;
            }
            newRow.push(avatar);
            if ((i + 1) % 4 === 0) {
              ctrl.remainingAvatars.push(newRow);
              newRow = [];
            }
          });

          if (newRow.length) {
            ctrl.remainingAvatars.push(newRow);
          }
        } else {
          ctrl.displayAvatars = ctrl.teamDiscussionMembers;
          ctrl.leftOverAvatars = [];
          ctrl.remainingAvatars = [];
        }
      }, 100);

      $timeout(fitAvatarsInContainer);

      angular.element($window).on('resize', fitAvatarsInContainer);
      scope.$on('$destroy', () => {
        angular.element($window).off('resize', fitAvatarsInContainer);
      });
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'team_workspace/templates/nv-team-profile-panel.html',
  };
}


