import { History } from 'history';
import { addNewComponent } from 'redux/actions/lecture-components';
import { ComponentTrueType, LectureComponent } from 'redux/schemas/models/lecture-component';
import { AppDispatch, useAppDispatch } from 'redux/store';
import merge from 'lodash/merge';
import { PayloadAction } from '@reduxjs/toolkit';
import { DeepPartial } from 'utility-types';
import { ComponentMetadata } from '../data';
import getDefaultNewComponentData from '../data/new-component-defaults';
import { adminWorkflowNavigate } from '../workflows';

/** Callback for adding a new component used in the left hand view and in the creation workflows.
 * Typically used to indicate that when a button is pressed, the caller should provide component-specific data that
 * will be passed into createNewComponent() defined below. */
export type AddComponentCallback = (
  componentType: ComponentTrueType,
  metadata?: ComponentMetadata,
  modelData?: DeepPartial<LectureComponent>
) => Promise<ReturnType<typeof addNewComponent.fulfilled> | ReturnType<typeof addNewComponent.rejected>>;

/** Fires creating a new lecture component against the backend API & triggers any necessary
 * component add workflow actions */
const createNewComponent = (
  newComponentIndex: number,
  catalogId: string,
  lectureId: number,
  componentType: ComponentTrueType,
  metadata: ComponentMetadata,
  dispatch: AppDispatch,
  history: History, // TODO: Unused, remove?
  modelData?: DeepPartial<LectureComponent>,
): ReturnType<AddComponentCallback> => {
  const defaultData = merge({}, getDefaultNewComponentData(componentType), modelData);

  return dispatch(addNewComponent({
    index: newComponentIndex,
    catalogId,
    lecturePageId: lectureId,
    lectureComponent: defaultData,
  })).then(actionResult => {
    // For some reason an error prop is defined on failure even though the typing doesn't show it
    // TODO: Delete/revise
    if ((actionResult as any).error) {
      throw Error(JSON.stringify((actionResult as any).error));
    }

    // Navigate into the correct admin 1.0 page if necessary. This does nothing if no admin 1.0 url is set on the workflow
    // TODO: Special cased to be ignored during a modal workflow as the modal workflow does this on its end.
    // maybe we should move this out of this func altogether? Seems like a bad place for it
    if (metadata.workflow.type !== 'modal') {
      adminWorkflowNavigate(metadata.workflow, catalogId, actionResult.payload as LectureComponent);
    }

    return actionResult;
  });
};

export default createNewComponent;
