import store from 'redux/store';
import { ActivityKey } from 'redux/schemas/models/activity';
import { setActivityProgress } from 'redux/actions/lecture-components';

/* @ngInject */
export default function TimelineExternalToolLectureComponentModelService(

  _,
  nvUtil,
  moment,
) {
  const TimelineExternalToolLectureComponentModel = function (attributes) {
    const _this = this;
    const base = {
      templateUrl: 'timelines/templates/nv-timeline-external-tool.html',
    };

    const ACTIVITY_TO_ICON = {
      quiz: 'icon-quiz',
      survey: 'icon-survey',
      assignment: 'icon-assignments',
      feedback: 'icon-evaluation',
      video: 'icon-video',
      reading: 'icon-read',
      discussion: 'icon-conversations',
      audio: 'icon-audio',
    };

    /** Setting Up Basic Attributes * */
    _.extend(_this, base, attributes);
    // passed lecturePage, lectureSection, course
    preprocess();

    /** Public Functions * */
    _.extend(_this, {
      getStatus,
      isCompleted,
      isMissed,
      isTodoRequiredForCompletion,
      updateComponentPointsAndProgress,
      updateComponentProgress,
      getIconClass,
      getPayload,
    });

    /** Function Declarations * */
    /* Used Publicly */
    function getIconClass() {
      return ACTIVITY_TO_ICON[this.externalTool.activityType];
    }

    function getStatus() {
      return _this.externalTool.progress;
    }

    function isCompleted() {
      return _this.externalTool.progress === 'completed';
    }

    function isMissed() {
      return false;
    }

    function isTodoRequiredForCompletion() {
      return _this.externalTool.isRequiredForCompletion;
    }

    function updateComponentPointsAndProgress(componentType, componentId, pointsReceived, totalPoints, progress) {
      if ((_this.type === componentType && _this.id === componentId) || (componentType === 'external_tool' && _this.externalTool.id === componentId)) {
        if (progress) {
          _this.externalTool.progress = progress;
        } else {
          _this.externalTool.progress = 'completed';
        }

        _this.externalTool.pointsReceived = pointsReceived;
        _this.pointsReceived = pointsReceived;

        store.dispatch(setActivityProgress({
          pointsReceived,
          progress: _this.externalTool.progress,
          activityKey: ActivityKey.EXTERNAL_TOOLS,
          activityPayloadId: _this.externalTool.id,
        }));

        if (_.isNumber(totalPoints)) {
          _this.currentTotalPoints = totalPoints;
        } else {
          _this.currentTotalPoints = nvUtil.getCurrentTotalPoints(_this.totalPoints, _this.lecturePage.releaseDate, _this.course.expiringPointsEnabled);
        }

        viewPreprocess();

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function updateComponentProgress(componentType, componentId, progress) {
      if ((_this.type === componentType && _this.id === componentId) || (componentType === 'external_tool', _this.externalTool.id === componentId)) {
        _this.externalTool.progress = progress;
        store.dispatch(setActivityProgress({
          progress,
          activityKey: ActivityKey.EXTERNAL_TOOLS,
          activityPayloadId: _this.externalTool.id,
        }));

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function getPayload() {
      return _.pick(_this.externalTool, ['title']);
    }

    /* Used Privately */
    function preprocess() {
      _.extend(_this, _.pick(_this.externalTool, 'title', 'isTodo', 'hasStructuralIssues', 'pointsReceived', 'totalPoints'));

      _this.activityId = _this.externalTool.id;

      _this.currentTotalPoints = nvUtil.getCurrentTotalPoints(_this.totalPoints, _this.lecturePage.releaseDate, _this.course.expiringPointsEnabled);

      viewPreprocess();
    }

    function viewPreprocess() {
      if (isCompleted() || _this.pointsReceived > _this.currentTotalPoints) {
        _this.currentTotalPoints = _this.pointsReceived ? _this.pointsReceived : 0;
      }
      _this.displayTotalPointsOnly = !_this.pointsReceived && _this.currentTotalPoints;
    }
  };

  return TimelineExternalToolLectureComponentModel;
}
