/* @ngInject */
export default function LShowReportHeaderCtrl(
  ExercisesManager,
  $controller,
  $scope,
  $translate,
) {
  const vm = angular.extend(this, $controller('L3HeaderCtrl', { $scope }));

  vm.subtitleSref = 'submissions-gallery({ exerciseId: vm.ExercisesManager.currentExercise.id })';
  vm.subtitleTextKey = 'COURSE_HOME.HEADER.GALLERY';
}
