import { css } from '@emotion/react';
import React, { MutableRefObject, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'redux/store';

import { OrgAdminParams, OrgAdminUpdateParams } from 'redux/schemas/api/org-level-roles';
import { OrgAdminRoles } from 'redux/schemas/models/org-level-roles';
import { addOrgAdmin, updateOrgAdmin } from 'redux/actions/org-level-roles';

import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvFormDropdown from 'shared/components/inputs/nv-form-dropdown';
import { NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';
import { halfSpacing } from 'styles/global_defaults/scaffolding';

type OrgAdminFormProps = {
  institutionId: number
  closeForm?: () => void
  addedCallback?: (id: number) => void
  isEditing?: boolean
  initialAdminRole?: string
  adminId?: number
  firstNameRef?: MutableRefObject<HTMLInputElement>
};

const styles = (isEditing: boolean) => css`
  ${isEditing && css`
    &.admin-form {
      width: 40%;
    }
  `}

  .nv-form-dropdown {
    flex-grow: 1;

    .icon-dropdown-arrow {
      margin-left: ${halfSpacing}px;
    }
  }

  .name-input {
    width: 15%
  }

  .email-input {
    width: 30%;
  }
`;

export const OrgAdminInput = ({
  institutionId,
  closeForm,
  addedCallback,
  isEditing,
  initialAdminRole,
  adminId,
  firstNameRef,
}: OrgAdminFormProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useAppDispatch();
  const { $scope } = React.useContext(AngularServicesContext);

  const orgRoleItems: NvDropdownTextItem[] = [{
    id: 0,
    type: 'text',
    text: t.INSTITUTIONS.ROLES.ORG_ADMIN_ROLES.ORG_ADMIN(),
    value: OrgAdminRoles.ORG_ADMIN,
  }, {
    id: 1,
    type: 'text',
    text: t.INSTITUTIONS.ROLES.ORG_ADMIN_ROLES.COURSE_MANAGER(),
    value: OrgAdminRoles.COURSE_MANAGER,
  }];

  const validationSchema = yup.object().shape({
    firstName: yup.string().required(t.VALIDATION.REQUIRED()),
    lastName: yup.string().required(t.VALIDATION.REQUIRED()),
    email: yup.string().email(t.VALIDATION.EMAIL()).required(t.VALIDATION.REQUIRED()),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      adminRole: initialAdminRole
        ? orgRoleItems.filter(role => role.value === initialAdminRole)[0]
        : orgRoleItems[0],
    },
  });
  const { handleSubmit, formState, watch } = methods;
  const { firstName, lastName, email, adminRole } = watch();
  const { isValid, isDirty } = formState;

  useEffect(() => {
    const deRegisterStateChangeStart = $scope.StateManager.registerStateChangeStart(
      () => isDirty,
      'shared/templates/modal-navigate-away.html',
      'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY',
    );

    return () => {
      deRegisterStateChangeStart();
    };
  }, [$scope.StateManager, isDirty]);

  const onSubmit = () => {
    setIsSaving(true);
    const data: OrgAdminParams = {
      adminRole: adminRole.value,
      institutionId,
      user: {
        firstName,
        lastName,
        email,
        admin: true,
      },
    };
    dispatch(addOrgAdmin(data))
      .then((response: any) => {
        setIsSaving(false);
        closeForm();
        addedCallback(response.payload?.id);
      });
  };

  const onEdit = () => {
    setIsSaving(true);
    const data: OrgAdminUpdateParams = {
      adminRole: adminRole.value,
      institutionId,
      adminId,
    };
    dispatch(updateOrgAdmin(data))
      .then(() => {
        setIsSaving(false);
        closeForm();
      });
  };

  return (
    <FormProvider {...methods}>
      <form
        className='admin-form d-flex align-items-center'
        onSubmit={handleSubmit(onSubmit)}
        css={styles(isEditing)}
      >
        <div className={`d-flex ${isEditing ? 'w-100' : ''}`}>
          {!isEditing && (
            <React.Fragment>
              <NvTextInput
                withForm
                name='firstName'
                placeholder={t.USERS.REGISTRATION.FIRST_NAME()}
                required
                className='name-input pr-2'
                ref={firstNameRef}
              />
              <NvTextInput
                withForm
                name='lastName'
                placeholder={t.USERS.REGISTRATION.LAST_NAME()}
                required
                className='name-input pr-2'
              />
              <NvTextInput
                withForm
                name='email'
                placeholder={t.ACCOUNT_SETTINGS.ACCOUNT_BASICS.EMAIL()}
                required
                className='email-input pr-2'
              />
            </React.Fragment>
          )}
          <NvFormDropdown
            name='adminRole'
            items={orgRoleItems}
            className='mr-2 w-auto'
            isSmallSize
            withFormDefaultValue={initialAdminRole}
          />
          <div className='d-flex py-1'>
            <Button
              type='submit'
              className='mr-2'
              disabled={isSaving || !isDirty || (!isEditing && !isValid)}
              onClick={() => isEditing && onEdit()}
            >
              {isSaving ? t.FORM.SAVING() : t.FORM.SAVE()}
            </Button>
            <Button
              variant='secondary'
              onClick={() => closeForm()}
            >
              {t.FORM.CANCEL()}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default OrgAdminInput;
