import React, { useContext } from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import { AngularServicesContext } from 'react-app';

import { getCourseAliases, getCurrentCourse } from 'redux/selectors/course';

import CourseOutlineNavigation from 'lecture_pages/components/left-panel/course-outline-navigation';
import NvIcon from 'shared/components/nv-icon';
import LoadingPlaceholder from 'shared/components/loading-placeholder';


const EmptyTimeline = () => {
  const {
    $state, CurrentUserManager,
  } = useContext(AngularServicesContext);
  const isAdmin = CurrentUserManager.isAdmin();

  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const aliases = useSelector((state) => getCourseAliases(state));
  const currentCourse = useSelector(getCurrentCourse);

  const styles = css`
    margin-top: 75px;
  `;

  return (
    <div css={styles} className='d-flex flex-column align-items-center'>
      <NvIcon icon='read' size='ultra-large' className='gray-5' />
      <div className='mt-4'>
        {isAdmin
          ? (
            <a
              className='btn btn-link color-primary bold'
              href={$state.href(
                currentCourse.isProgram ? 'program-timelines-edit' : 'course-home-edit',
                {
                  catalogId,
                },
              )}
            >
              {t.TIMELINE.GET_STARTED({ ...aliases.courseAliases })}
            </a>
          )
          : (
            <span className='gray-4 bold'>{t.TIMELINE.NO_CONTENT()}</span>
          )}
      </div>
    </div>
  );
};

const Outline = () => {
  const { lectureSectionIds, lectureSubsectionIds } = useSelector(getCurrentCourse);
  const { isTimelineContentLoaded } = useSelector(state => state.app.lecturePage);

  const isCourseEmpty = isTimelineContentLoaded
    && !lectureSectionIds?.length
    && !lectureSubsectionIds?.length;

  return (
    <React.Fragment>
      {!isTimelineContentLoaded && (
        <div className='mt-6'>
          <LoadingPlaceholder />
        </div>
      )}
      {isCourseEmpty && <EmptyTimeline />}
      {(isTimelineContentLoaded && !isCourseEmpty) && (
        <div className='mt-6'>
          <CourseOutlineNavigation />
        </div>
      )}
    </React.Fragment>
  );
};

export default Outline;

