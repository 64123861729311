import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import NvFilePicker from 'shared/components/nv-filepicker';
import { CSVHeader, ErrorType, downloadCSVFromJson, parseCSV } from 'shared/csv-utils';
import { useSelector } from 'react-redux';
import { CombinedInstitution, RootState } from 'redux/schemas';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { useAppDispatch } from 'redux/store';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import * as yup from 'yup';
import { isEmpty, some, uniqueId } from 'underscore';
import { useParams } from 'react-router-dom';
import { removeUsersFromCourse } from 'redux/actions/users';
import { css } from '@emotion/react';

type UserBulkRemoveModalProps = {
  closeModal(reload?: boolean): void,
};

const styles = css`
  & {
    max-height: calc(100vh - 120px);
    overflow-y: scroll;
  }

  .error-email, .error-external-id {
    width: 33%;
  }

  .user-email {
    width: 40%;
  }
`;

const UserBulkRemoveModal = (props: UserBulkRemoveModalProps) => {
  const { closeModal } = props;
  const [validationErrors, setValidationErrors] = useState([]);
  const [backendResponse, setBackendResponse] = useState(null);
  const [validRows, setValidRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showUsersList, setShowUsersList] = useState(false);
  const [saving, setSaving] = useState(false);

  const institution = useSelector<RootState, CombinedInstitution>(getCurrentInstitution);
  const { catalogId, collectionId } = useParams<{ collectionId: string, catalogId: string }>();

  const dispatch = useAppDispatch();
  const hasSsoLogin = institution.ssoLogin;

  const validationSchema = yup.object().shape({
    email: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .email(t.VALIDATION.EMAIL()),
  });

  const templateHeaders: CSVHeader[] = [
    {
      key: 'email',
      headerName: 'Email',
    },
  ];

  if (hasSsoLogin) {
    templateHeaders.push({
      key: 'externalId',
      headerName: 'External ID (optional - Use when SSO with External ID configured only)',
    });
  }

  const onFileError = () => {
    setValidRows([]);
    setValidationErrors([]);

    dispatch(addAlertMessage({
      type: AlertMessageType.ERROR,
      header: t.FORM.OOPS(),
      message: t.FILE_UPLOAD.NOT_SUPPORTED_ALERT(),
    }));
  };

  const parseCallback = (response) => {
    setValidRows(response.data);
    if (response.errors.length > 0) {
      if (some(response.errors, (error) => error.type === ErrorType.VALIDATION)) {
        setValidationErrors(response.errors);
      } else {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.NO_USERS(),
        }));
      }
      setLoading(false);
    } else if (response.data.length > 0) {
      setShowUsersList(true);
    }
  };

  const onCSVSelected = (files: File[]) => {
    setValidRows([]);
    setValidationErrors([]);
    const [file] = files;

    if (!file) {
      return;
    }
    setLoading(true);

    parseCSV({
      headers: templateHeaders,
      file,
      validationSchema,
      callback: parseCallback,
    });
  };

  const removeUploadedFile = () => {
    setValidationErrors([]);
    setValidRows([]);
    setShowUsersList(false);
  };

  // Submitting the CSV values.
  const submit = () => {
    const payload = {
      catalogId,
      users: validRows,
    };

    dispatch(removeUsersFromCourse(payload)).then((response) => {
      const result = response.payload;

      if (result.threshold) {
        dispatch(addAlertMessage({
          type: AlertMessageType.WARNING,
          header: t.FORM.SUCCESS_BANG(),
          message: t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_BEING_ADDED(),
        }));

        closeModal(false);
      } else if (!isEmpty(result?.errors)) {
        setBackendResponse(result);
      } else {
        setBackendResponse(result);

        const showWarning = validRows?.length !== result?.unenrolledUsers?.length;

        dispatch(addAlertMessage({
          type: showWarning ? AlertMessageType.WARNING : AlertMessageType.SUCCESS,
          header: !showWarning ? t.FORM.SUCCESS_BANG() : '',
          message: getResponseDescription(result?.existingUnenrolledUsers?.length, result?.unenrolledUsers?.length),
        }));

        closeModal(result?.unenrolledUsers?.length > 0);
      }
    });
  };

  const downloadTemplate = (e) => {
    downloadCSVFromJson({
      headers: templateHeaders,
      dummyRows: {
        totalRows: 2,
        values: {
          email: ['jane.doe@email.com', 'john.doe@email.com'],
          externalId: ['ID_ASSIGNED_BY_CORPORATE_IDENTITY_PROVIDER', 'ID_ASSIGNED_BY_CORPORATE_IDENTITY_PROVIDER'],
        },
      },
    });
  };

  const ValidationErrorlist = () => (
    <React.Fragment>
      <p className='mb-2 text-body'>
        {t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.ERROR_DESCRIPTION(validationErrors.length)}
      </p>
      <table className='mb-5'>
        <tbody>
          {validationErrors.map((error) => (
            <tr
              className='font-weight-normal border-bottom border-gray-5'
              key={`${error.row}-${error.column}`}
            >
              <td className='py-2 pl-4'>
                {t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.ROW(error.row)}
              </td>
              <td className='py-2 text-danger'>{error.message}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='d-flex mx-auto mt-5'>
        <NvFilePicker
          multiple={false}
          accept={['.csv', 'text/csv']}
          onChange={(e) => onCSVSelected(e)}
          onSelectError={onFileError}
        >
          <Button
            variant='secondary'
            disabled={loading}
          >
            {t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.UPLOAD_NEW_CSV()}
          </Button>
        </NvFilePicker>
        {validRows.length > 0 && (
          <Button
            className='ml-1'
            onClick={() => setShowUsersList(true)}
          >
            {t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.PROCEED()}
          </Button>
        )}
      </div>
    </React.Fragment>
  );

  const Userslist = () => (
    <React.Fragment>
      <p className='mb-2 text-body'>
        {t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.LIST_OF_USERS()}
      </p>
      <div className='mb-5'>
        {validRows.map((user, key) => (
          <div
            className='d-flex font-weight-normal border-bottom border-gray-5 p-4'
            key={`${uniqueId('user')}`}
          >
            <div className='user-email pr-2'>{user.email}</div>
            <div>{user.externalId}</div>
          </div>
        ))}
        <div className='mt-4 pl-4'>
          {t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.TOTAL_USERS(validRows.length)}
        </div>
      </div>
      <div className='d-flex mx-auto mt-5'>
        <Button
          variant='secondary'
          onClick={removeUploadedFile}
        >
          {t.FORM.CANCEL()}
        </Button>
        <Button
          className='ml-1'
          onClick={submit}
          disabled={saving}
        >
          {t.USER_MANAGEMENT.CSV_UPLOAD_MODAL[saving ? 'REMOVING_ALL' : 'REMOVE_ALL']()}
        </Button>
      </div>
    </React.Fragment>
  );

  const UploadInfo = () => (
    <React.Fragment>
      <p className=''>
        {t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.BULK_UNENROLL.DESCRIPTION()}
      </p>
      <div className='d-flex mx-auto mt-7'>
        <Button
          variant='secondary'
          onClick={downloadTemplate}
        >
          {t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.DOWNLOAD_TEMPLATE()}
        </Button>
        <NvFilePicker
          multiple={false}
          accept={['.csv', 'text/csv']}
          onChange={(e) => onCSVSelected(e)}
          onSelectError={onFileError}
        >
          <Button className='ml-2'>
            {t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.UPLOAD_CSV()}
          </Button>
        </NvFilePicker>
      </div>
    </React.Fragment>
  );

  const getResponseDescription = (existingCount, unenrolledCount) => {
    const transValues = {
      existingCount,
      unenrolledCount,
      courseAlias: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTION_NAME.DOWNCASED_SINGULARIZED(),
    };

    return existingCount !== 0
      ? t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.SOME_ALREADY_REMOVED(transValues)
      : t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_REMOVED(transValues);
  };

  const BackEndErrorlist = () => (
    <React.Fragment>
      <p className='mb-4'>
        {getResponseDescription(
          backendResponse?.existingUnenrolledUsers?.length,
          backendResponse?.unenrolledUsers?.length,
        )}
      </p>
      <p className='mb-2 text-danger'>
        {t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.CANNOT_BE_UNENROLLED(backendResponse.errors.length)}
      </p>
      <div className='mb-5'>
        {backendResponse?.errors?.map((user) => (
          <div
            className='d-flex font-weight-normal border-bottom border-gray-5'
            key={`${uniqueId('be-error')}`}
          >
            <div className='error-email py-2 pl-4'>
              {user.email}
            </div>
            <div className='error-external-id py-2 pl-2'>
              {user.externalId}
            </div>
            <div className='py-2 pl-2 text-danger'>
              {t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.ERRORS[user.error.toUpperCase()]()}
            </div>
          </div>
        ))}
      </div>
      <div className='d-flex mx-auto mt-5'>
        <Button
          className='ml-1'
          onClick={() => closeModal(backendResponse?.unenrolledUsers?.length > 0)}
        >
          {t.FORM.OK()}
        </Button>
      </div>
    </React.Fragment>
  );

  const getModalContent = () => {
    if (validationErrors.length > 0 && !showUsersList) {
      return <ValidationErrorlist />;
    }

    if (backendResponse?.errors?.length > 0) {
      return <BackEndErrorlist />;
    }

    if (showUsersList && validRows.length > 0) {
      return <Userslist />;
    }

    return <UploadInfo />;
  };

  return (
    <div className='d-flex flex-column mx-auto p-4' css={styles}>
      {getModalContent()}
    </div>
  );
};

export default UserBulkRemoveModal;
