/* @ngInject */
export default function nvSecondsToClockTime(moment) {
  return function (seconds) {
    const secondsMoment = moment.duration(seconds, 'seconds');

    if (Math.floor(secondsMoment.asHours()) > 0) {
      return Math.floor(secondsMoment.asHours()) + moment.utc(secondsMoment.asMilliseconds()).format(':mm:ss');
    } if (Math.floor(secondsMoment.asMinutes()) > 0) {
      return Math.floor(secondsMoment.asMinutes()) + moment.utc(secondsMoment.asMilliseconds()).format(':ss');
    }
    return moment.utc(secondsMoment.asMilliseconds()).format('0:ss');
  };
}
