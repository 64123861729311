// This file provides functions to display streams in players
// 1. video stream in video player
// 2. video blob in video player
// 3. audio stream visualized on a canvas
// 4. audio stream in audio player

import { gray4, hexToRgbaString } from 'styles/global_defaults/colors';

/**
 * This function checks with the browser if the mimeType can be played.
 * This browser does not actually attempt to play the file so only a best guess is made by the browser
 */
/* @ngInject */
export function probablySupportsVideoType(mimeType: string) {
  const video = document.createElement('video');

  return video.canPlayType(mimeType) === 'probably' || video.canPlayType(mimeType) === 'maybe';
}

/**
 * display stream in video element & turn down audio to prevent feedback
 * @param playerElement video element to provide preview of camera
 * @param mediaStream media stream provided by the browser
 */
/* @ngInject */
export function displayStreamInPlayer(playerElement: HTMLVideoElement | HTMLAudioElement, mediaStream: MediaStream) {
  // mute the video to prevent audio feedback
  playerElement.muted = true;
  playerElement.volume = 0;
  playerElement.autoplay = true;
  playerElement.controls = false;

  // link the video src to be the provided stream
  playerElement.srcObject = mediaStream;

  return () => resetPlayer(playerElement);
}

/**
 * remove stream from video element & reset volume
 */
function resetPlayer(playerElement: HTMLVideoElement | HTMLAudioElement) {
  playerElement.src = null;
  playerElement.srcObject = null;
}

/**
 * display video or audio blob in video or audio element
 */
/* @ngInject */
export function displayBlobInPlayer(playerElement: HTMLVideoElement | HTMLAudioElement, mediaBlob: Blob) {
  playerElement.muted = false;
  playerElement.volume = 1;
  playerElement.autoplay = false;
  playerElement.controls = true;

  const objectURL = URL.createObjectURL(mediaBlob);
  playerElement.src = objectURL;

  return () => {
    resetPlayer(playerElement);
    URL.revokeObjectURL(objectURL);
  };
}


export const maxValue = 255; // max value of the frequency; data is composed of integers on a scale from 0 to 255.
export const barWidth = 2; // width of each bar in the frequency bar graph
export const barSpacingWidth = 2; // width between each bar

/**
 * draws a bar graph in a canvas with the given data
 * @param dataPointsCount number of data points to display, currently takes from the beginning
 * @param canvas element to draw the bar graph
 */
/* @ngInject */
export function drawAudioVisualizer(data: Uint8Array | Array<number>, canvas: HTMLCanvasElement) {
  const { height: canvasHeight, width: canvasWidth } = canvas;
  const ctx = canvas.getContext('2d');
  ctx.strokeStyle = hexToRgbaString(gray4);
  ctx.lineWidth = barWidth;
  ctx.lineCap = 'round';

  const yMid = canvasHeight / 2; // y value that is half way down the canvas
  // the rounded caps on top of the bars are added on top of the given height, so we need to adjust the height to account for the cap
  const preventClippingMultiplier = (canvasHeight - 10) / canvasHeight;

  const toCanvasBarHeight = canvasHeight / maxValue; // multiplier to get actual bar height
  const xIncrement = barSpacingWidth + barWidth; // number of pixels to move right each time for drawing each bar

  ctx.clearRect(0, 0, canvasWidth, canvasHeight); // clear canvas before each redraw

  for (let index = 0; index < data.length; index += 1) {
    const barHeight = data[index] * toCanvasBarHeight;
    const x = barWidth / 2 + xIncrement * index; // x-axis starting point

    // find the bottom of the bar:
    // 1. start at mid point
    // 2. go down half of full length; since there is a line cap that is the width of the bar, normalize to prevent clipping
    const yBottom = yMid + (barHeight / 2) * preventClippingMultiplier;
    const yTop = yMid - (barHeight / 2) * preventClippingMultiplier;

    ctx.beginPath();
    ctx.moveTo(x, yBottom);
    ctx.lineTo(x, yTop);
    ctx.stroke();
  }
}

// solution copied from https://gist.github.com/kevincennis/6149078
// we get the root mean square of the power, however, there are magic numbers after the sqrt is being done
// without the magic multipliers, the peaks are very flat
/**
 * Given an (already set up) analyser node, returns the percentage(decimal) volume of the input
 */
/* @ngInject */
export function getVolume(analyser: AnalyserNode) {
  const streamData = new Uint8Array(analyser.frequencyBinCount);

  analyser.getByteTimeDomainData(streamData);

  const sum = streamData.reduce((acc, value) => {
    const normalizedValue = value / 128 - 1;
    return acc + normalizedValue * normalizedValue;
  }, 0);
  const rms = Math.sqrt(sum / streamData.length);
  let db = 20 * (Math.log(rms) / Math.log(10));
  db = Math.max(-48, Math.min(db, 0));
  const percentage = (100 + (db * 2.083)) / 100;

  return percentage;
}

// must be a power of 2 between 25 and 215, so one of: 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, and 32768. Defaults to 2048.
// we only display the first 16/128 sampled frequencies, optimizing for 0-5k hz
const defaultFftSize = 128; // number of samples used when performing a Fast Fourier Transform (FFT) to get frequency domain data

/* @ngInject */
export function getAudioAnalyzer(mediaStream: MediaStream) {
  const audioCtx = new AudioContext();
  const analyser = audioCtx.createAnalyser();
  const source = audioCtx.createMediaStreamSource(mediaStream);
  source.connect(analyser);

  analyser.fftSize = defaultFftSize;

  return analyser;
}
