
/**
 * @ngdoc function
 * @name origamiApp.controller:StyleGuideCtrl
 * @description
 * # StyleGuideCtrl
 * Controller of the origamiApp
 */
/* @ngInject */
export default function StyleGuideController(
  $scope,
  $uibModal,
  $state,
  moment,
  AlertMessages,
) {
  initialize();

  function initialize() {
    $('.loading-screen').hide();
  }

  // Click to edit directive
  $scope.editInfo = {
    isEditable: true,
  };
  $scope.firstName = '';
  $scope.essay = '';
  $scope.complexQuestion = {
    formType: 'Textarea',
  };

  // Show all directive
  $scope.defaultMin = 2;
  $scope.shownListItems = { num: $scope.defaultMin };

  // popovers
  $scope.teamRequestPopoverOptions = {
    templateUrl: 'style-guide/templates/team-request-popover.html',
    title: 'Team Membership Requests',
    joinTeamRequests: [
      {
        userName: 'Dayna Santos',
        teamRequesting: 'Team Awesome',
        // there would be more info here from the user serializer
      },
      {
        userName: 'Nick Fuller',
        teamRequesting: 'Team Awesome',
      },
      {
        userName: 'John Perry',
        teamRequesting: 'Team Procrastination',
      },
    ],
    teamInvitations: [
      {
        teamName: 'Already Existing Team',
      },
    ],
  };

  // modals
  $scope.openStyleGuideModal = function () {
    const confirmationModalInstance = $uibModal.open({
      backdropClass: 'modal-overlay-backdrop',
      templateUrl: 'style-guide/templates/style-guide-modal.html',
      windowClass: 'modal-overlay',
    });
  };

  // calendars
  $scope.calendar = {
    isOpen: false,
    date: new Date().toISOString(),
    displayOptions: {

    },
  };

  $scope.openCalendar = function () {
    $scope.calendar.isOpen = true;
  };


  $scope.setCustomDateClass = function (date, mode) {
    const today = new Date();
    const dayInMonth = date.getDay();


    if (date.toDateString() === today.toDateString()) {
      return 'today';
    } if (dayInMonth % 7 === 0 && date.getTime() > today.getTime()) {
      return 'assignment-due-date';
    }
    return '';
  };

  // Datepicker / Timepicker
  $scope.datepickerDate1 = moment().toISOString();
  $scope.datepickerDate2 = moment().toISOString();
  $scope.datepickerDate3 = undefined;
  $scope.datepickerDate4 = undefined;

  $scope.AlertMessages = AlertMessages;

  $scope.textModel = 'asdf';
}
