
import * as yup from 'yup';
import t from 'react-translate';
import { useSelector } from 'react-redux';

// Schemas
import { ScheduledForType } from 'redux/schemas/models/course-communication';

// Selectors
import { getCourseAliases, getCourseTotalPoints, getNumberofTodos, isGamificationEnabled } from 'redux/selectors/course';

// Components
import FilterByRoles from 'communications/course_communications/components/communication-modal/filters/filter-by-role';
import CompletedCourse from 'communications/course_communications/components/communication-modal/filters/completed-course';
import NumberInput from 'communications/course_communications/components/communication-modal/filters/number-input';
import SendOn from 'communications/course_communications/components/communication-modal/send_on/send-on';
import SendTo from 'communications/course_communications/components/communication-modal/send-to';
import CommunicationForm, { formDropdownSchema } from './communication-form';
import Activity from './filters/activity';

const OnComplete = () => {
  const aliases = useSelector((state) => getCourseAliases(state));

  const totalPoints = useSelector((state) => getCourseTotalPoints(state));
  const gamificationEnabled = useSelector((state) => isGamificationEnabled(state));
  const totalAssignments: number = useSelector((state) => getNumberofTodos(state));

  const validationSchema = yup.object().shape({
    scheduledFor: yup.string().nullable(),
    xDays: yup.number().nullable()
      .when('scheduledFor', {
        is: ScheduledForType.AFTER_DATE,
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      }),
    filterByRoles: yup.boolean(),
    daysInactiveChecked: yup.boolean(),
    notCompletedActivityChecked: yup.boolean(),
    completedActivityChecked: yup.boolean(),
    earnedMoreThanXPointsChecked: yup.boolean(),
    earnedLessThanXPointsChecked: yup.boolean(),
    completedXAssignmentsAsTodoChecked: yup.boolean(),
    hasCompletedCourseChecked: yup.boolean(),
    courseRolesList: yup.array().of(yup.string())
      .when('filterByRoles', {
        is: true,
        then: yup.array().of(yup.string()).required().min(1),
      }),
    daysInactive: yup.number().nullable()
      .when('daysInactiveChecked', {
        is: true,
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      }),
    notCompletedType: yup.object().nullable()
      .when('notCompletedActivityChecked', {
        is: true,
        then: formDropdownSchema.required(t.VALIDATION.REQUIRED()),
      }),
    notCompletedActivity: yup.object().nullable()
      .when('notCompletedActivityChecked', {
        is: true,
        then: formDropdownSchema.required(t.VALIDATION.REQUIRED()),
      }),
    notCompletedCategory: yup.string(),
    completedType: yup.object().nullable()
      .when('completedActivityChecked', {
        is: true,
        then: formDropdownSchema.required(t.VALIDATION.REQUIRED()),
      }),
    completedActivity: yup.object().nullable()
      .when('completedActivityChecked', {
        is: true,
        then: formDropdownSchema.required(t.VALIDATION.REQUIRED()),
      }),
    completedCategory: yup.string(),
    earnedMoreThanXPoints: yup.number().nullable()
      .when('earnedMoreThanXPointsChecked', {
        is: true,
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      }),
    earnedLessThanXPoints: yup.number().nullable()
      .when('earnedLessThanXPointsChecked', {
        is: true,
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      }),
    completedXAssignmentsAsTodo: yup.number().nullable()
      .when('completedXAssignmentsAsTodoChecked', {
        is: true,
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      }),
    hasCompletedCourse: yup.object().nullable()
      .when('hasCompletedCourseChecked', {
        is: true,
        then: yup.object().required(t.VALIDATION.REQUIRED()),
      }),
  });

  return (
    <CommunicationForm validationSchema={validationSchema}>
      <div className='modal-body-title page-title text-center pb-4 mt-2'>
        {t.COURSE_COMMUNICATIONS.TRIGGERS.TITLE.STEP_1()}
      </div>
      <SendTo
        title={t.COURSE_COMMUNICATIONS.ON_COMPLETE.SEND_TO}
      />
      <SendOn />
      <div className='bold text-large-regular mt-5'>
        <div className='text-warning inline-block'>{t.COURSE_COMMUNICATIONS.FILTERS.OPTIONAL()}</div>
        <div className='ml-2 inline-block'>{t.COURSE_COMMUNICATIONS.FILTERS.ADD_MORE(aliases.learnersAliases)}</div>
      </div>
      <div className='text-medium bold my-1'>{t.COURSE_COMMUNICATIONS.FILTERS.DESCRIPTION()}</div>
      <div className='text-medium gray-3 mt-4 mb-1'>{t.COURSE_COMMUNICATIONS.FILTERS.MULTIPLE_SELECTIONS()}</div>

      {/* Filter by roles */}
      <FilterByRoles />

      {/* Has been inactive for more than X days */}
      <NumberInput
        checkboxName='daysInactiveChecked'
        name='daysInactive'
        defaultLabel={t.COURSE_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.INACTIVE.DEFAULT()}
        inputLabel={t.COURSE_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.INACTIVE.LABEL('INPUT')}
        ariaLabel={t.COURSE_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.INACTIVE.ARIA_LABEL()}
      />

      {/* Has NOT completed a specific activity */}
      <Activity
        name='notCompleted'
        dependentName='completed'
        label={t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.HAS_NOT()}
      />

      {/* Has completed a specific activity */}
      <Activity
        name='completed'
        dependentName='notCompleted'
        label={t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.HAS()}
      />

      {/* Has earned more than X points */}
      <NumberInput
        checkboxName='earnedMoreThanXPointsChecked'
        name='earnedMoreThanXPoints'
        defaultLabel={t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.MORE_THAN.DEFAULT(aliases.pointsAliases)}
        inputLabel={t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.MORE_THAN.LABEL({ input: 'INPUT', ...aliases.pointsAliases })}
        inputWidth={100}
        ariaLabel={t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.MORE_THAN.ARIA_LABEL(aliases.pointsAliases)}
        info={!gamificationEnabled
          ? t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.NOT_ENABLED(aliases.pointsAliases)
          : t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.TOTAL({ count: totalPoints, ...aliases.pointsAliases })}
      />

      {/* Has earned less than X points */}
      <NumberInput
        checkboxName='earnedLessThanXPointsChecked'
        name='earnedLessThanXPoints'
        defaultLabel={t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.LESS_THAN.DEFAULT(aliases.pointsAliases)}
        inputLabel={t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.LESS_THAN.LABEL({ input: 'INPUT', ...aliases.pointsAliases })}
        inputWidth={100}
        ariaLabel={t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.LESS_THAN.ARIA_LABEL(aliases.pointsAliases)}
        info={!gamificationEnabled
          ? t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.NOT_ENABLED(aliases.pointsAliases)
          : t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.TOTAL({ count: totalPoints, ...aliases.pointsAliases })}
      />

      {/* Completed less than X number of assignments that are marked as to-dos */}
      <NumberInput
        checkboxName='completedXAssignmentsAsTodoChecked'
        name='completedXAssignmentsAsTodo'
        defaultLabel={t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ASSIGNMENTS.DEFAULT(aliases.assignmentAliases)}
        inputLabel={t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ASSIGNMENTS.LABEL({ input: 'INPUT', ...aliases.assignmentAliases })}
        info={!totalAssignments
          ? t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ASSIGNMENTS.NO_ASSIGNMENTS(aliases.assignmentAliases)
          : t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ASSIGNMENTS.TOTAL(totalAssignments)}
      />

      <CompletedCourse />
    </CommunicationForm>
  );
};

export default OnComplete;
