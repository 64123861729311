import { css, SerializedStyles } from '@emotion/react';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { AngularContext } from 'react-app';
import { NvTableRowProps, NvTableCellProps } from 'shared/components/nv-responsive-table';
import { createGridStyles, standardSpacing } from 'styles/global_defaults/scaffolding';
import { Course } from 'redux/schemas/models/course';
import { Institution } from 'redux/schemas/models/institution';
import { NvPopover } from 'shared/components/nv-popover';
import NvTooltip from 'shared/components/nv-tooltip';
import moment from 'moment';
import t from 'react-translate';
import { black, warning, lightOrange, hexToRgbaString, gray6, gray4, gray7, white, primary, success, getColorBetweenColorsByPercentage } from 'styles/global_defaults/colors';
import { CompletionTypeFilters, LearnerProgress, SectionSubsection } from 'redux/schemas/models/learner-progress';
import { User } from 'redux/schemas/models/my-account';
import { Tab } from 'timelines/components/course_home/course-timeline';
import { NvUserAvatar } from 'components/nv-user-avatar';
import NvProgressBar from 'shared/components/nv-progress-bar';
import NvProgressGauge, { ProgressGaugeType } from 'shared/components/nv-progress-gauge';
import LearnerRowOptions from './learner-row-options';
import { config } from '../../../config/pendo.config.json';


export type LearnerRowExtraProps = {
  institution: Institution
  course: Course,
  selectedSectionsList: SectionSubsection[],
};

export type LearnerCellProps = NvTableCellProps<LearnerProgress, {}, LearnerRowExtraProps> & {
  learner: LearnerProgress
  /* TODO: This might need to be pulled out somewhere */
  optionsCellRef?: React.MutableRefObject<HTMLDivElement>,
};

const commonStyles = css`
  display: flex;
  align-items: center;
  height: 80px;
  color: ${black};

  .cursor-pointer {
    cursor: pointer;
  }

  .hover-box {
    display: flex;
    position: relative;
    align-items: center;
    pointer-events: all;
    height: 100%;
    width: 100%;
    padding-left: 10px;

    &:hover {
      background-color: ${gray6};
    }

    & > .position-absolute {
      left: ${standardSpacing}px;
    }
  }
`;

const learnerNameStyles = css`
  ${commonStyles};

  position: sticky;
  left: 0;
  z-index: 15 !important;

  .name-and-email {
    max-width: 140px; // 190 (static size of cell) - 40 (size of avatar) - 10 (spacing)
  }

  .name, .email {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const lastActiveDateStyles = css`
  ${commonStyles};

  position: sticky;
  left: 230px;
  z-index: 15 !important;
`;

const progressStyles = css`
  ${commonStyles};

  position: sticky;
  left: 370px;
  z-index: 15 !important;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const sectionStyles = css`
  ${commonStyles};

  .show-on-row-hover {
    visibility: hidden;
  }
`;

const spacingStyles = css`
  ${commonStyles};
`;

const optionsStyles = css`
  ${commonStyles};

  position: sticky;
  right: 0;

  background-color: ${gray7} !important;

  &.disabled {
    background-color: ${white} !important;
    opacity: 1 !important;
  }

  .warning {
    /* !important is needed to override default dropdown header styles */
    color: ${warning} !important;
  }

  .icon {
    display: block;
  }

  &:hover:not(.disabled), &:active:not(.disabled) {
    background: ${hexToRgbaString(lightOrange, 0.80)} !important;
    .icon {
      color: ${black} !important;
    }
  }
`;

const formatDate = (date: string | Date, showTime?: boolean): string => {
  if (moment().diff(date, 'days') > 7) {
    return `${moment(date).format('L')} ${showTime ? moment(date).format('LT') : ''}`;
  }
  return (moment().diff(date) > 0 ? moment(date).fromNow() : moment().fromNow());
};


export const LearnerRow = (props: NvTableRowProps<LearnerProgress, {}, LearnerRowExtraProps>) => {
  const cells: [(props: LearnerCellProps) => JSX.Element, SerializedStyles][] = [
    [LearnerNameCell, learnerNameStyles],
    [LastActiveDateCell, lastActiveDateStyles],
  ];

  if (props.extraProps.course.automatedCompletionsEnabled || props.extraProps.course.gamificationEnabled) {
    cells.push([ProgressCell, progressStyles]);
  }

  props.extraProps.selectedSectionsList.forEach(section => {
    cells.push([(_props) => SectionCell(_props, section.id), sectionStyles]);
  });

  cells.push([SpacingCell, spacingStyles]);

  cells.push([OptionsCell, optionsStyles]);

  return (
    <Fragment>
      {
        cells.map((cell, i) => {
          const cellProps: LearnerCellProps = {
            ...props,
            learner: props.data,
            reactKey: `${props.rowIndex}-${i + 1}`,
            serializedStyles: cell[1],
            divProps: {
              style: createGridStyles(i + 1, props.rowIndex, i + 2, props.rowIndex + 1),
            },
          };

          const CellComponent = cell[0];

          // eslint-disable-next-line react/no-array-index-key
          return <CellComponent {...cellProps} key={i} />;
        })
      }
    </Fragment>
  );
};

const LearnerNameCell = (props: LearnerCellProps) => {
  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const learnerNameRef = useRef(null);
  const [hasOverflow, setHasOverflow] = useState(false);

  useEffect(() => {
    setHasOverflow(learnerNameRef.current?.scrollWidth > learnerNameRef.current?.clientWidth);
  }, [learnerNameRef]);

  return (
    <div className='learner-name-cell' css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      <div
        className='d-flex cursor-pointer'
        onClick={() => $state.go('learner-profile-modal', { catalogId: props.extraProps.course.catalogId, userId: props.learner.userId })}
        data-qa={`open-learner-profile-${props.learner.userId}`}
      >
        <NvUserAvatar
          // This `unknown` needs to be used, because currently, the backend is not sending all of the needed data for the `User` type
          user={props.learner as unknown as User}
          tooltipEnabled={false}
          size='md'
          borderType='round'
        />
        <div className='name-and-email d-flex flex-column ml-2'>
          <NvTooltip enabled={hasOverflow} text={t.USERS.FULL_NAME(props.learner.firstName, props.learner.lastName)}>
            <div>
              <div className='name mb-1 text-medium' ref={learnerNameRef}>{t.USERS.FULL_NAME(props.learner.firstName, props.learner.lastName)}</div>
            </div>
          </NvTooltip>
          <div className='email text-small'>{props.learner.email}</div>
        </div>
      </div>
    </div>
  );
};

const LastActiveDateCell = (props: LearnerCellProps) => {
  let cellContent;

  if (props.learner.lastActivity) {
    cellContent = <div className='font-weight-bold'>{formatDate(props.learner.lastActivity, false)}</div>;
  } else if (props.learner.courseHomeFirstVisitedAt) {
    cellContent = <div className='font-weight-bold'>{t.LEARNER_PROGRESS.LAST_ACTIVE_DATE.VISITED_COURSE_HOME({ CourseAlias: props.extraProps.course.offeringName.singularizedTitleized })}</div>;
  } else {
    cellContent = <div className='text-danger font-weight-bold'>{t.USER_MANAGEMENT.NEVER_ACTIVE()}</div>;
  }

  return (
    <div className='last-active-date-cell' css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      <div className='d-flex'>
        <NvPopover placement='top' content={LastActivePopover(props)} showOnHover>
          {cellContent}
        </NvPopover>
      </div>
    </div>
  );
};

const LastActivePopover = (props: LearnerCellProps) => (
  <div>
    <div className='text-medium font-weight-bold'>{t.LEARNER_PROGRESS.LAST_ACTIVE_DATE.ENROLLMENT_DATE()}</div>
    <div className='text-medium'>{formatDate(props.learner.enrolledAt, true)}</div>
    {props.learner.courseHomeFirstVisitedAt && (
      <Fragment>
        <div className='text-medium font-weight-bold'>{t.LEARNER_PROGRESS.LAST_ACTIVE_DATE.FIRST_COURSE_HOME_VISIT({ courseAlias: props.extraProps.course.offeringName.downcasedSingularized })}</div>
        <div className='text-medium'>{formatDate(props.learner.courseHomeFirstVisitedAt, true)}</div>
      </Fragment>
    )}
  </div>
);

const ProgressCell = (props: LearnerCellProps) => {
  const { injectServices } = useContext(AngularContext);
  const [$uibModal] = injectServices(['$uibModal']);

  const {
    automaticCompletionCriteria: {
      requiredAssignmentsCount,
      totalRequiredTodosCount,
      requiredPoints,
    },
  } = props.extraProps.course;

  const numberOfCriteria = (requiredAssignmentsCount > 0 ? 1 : 0)
    + (totalRequiredTodosCount > 0 ? 1 : 0)
    + (requiredPoints > 0 ? 1 : 0);

  const { pointsReceived,
    numRequiredTodosCompleted,
    numRequiredAssignmentsCompleted,
    totalPoints,
    completionStatus,
  } = props.learner;

  const onClick = (selectedTab) => {
    $uibModal.open({
      templateUrl: 'shared/templates/points-breakdown-modal.html',
      controller: 'PointsBreakdownModalController',
      controllerAs: 'vm',
      windowClass: 'large-modal',
      resolve: {
        user: {
          id: props.learner.userId,
          fullName: props.learner.fullName,
        },
        course: {
          completionStatus,
        },
        showOutline: true,
        selectedTab: () => selectedTab,
      },
    });
  };

  // can be either a single progress bar. or two or three progress guages
  const progressIndicators = [];

  if (requiredPoints > 0) {
    progressIndicators.push({
      maxValue: requiredPoints,
      currentValue: pointsReceived,
      activeColor: success,
      type: numberOfCriteria > 1 ? 'gauge' : 'bar',
      tooltipText: props.extraProps.course.pointsName.pluralizedTitleized,
      selectedTab: Tab.POINTS,
      id: 1,
    });
  }
  if (totalRequiredTodosCount > 0) {
    progressIndicators.push({
      maxValue: totalRequiredTodosCount,
      currentValue: numRequiredTodosCompleted,
      activeColor: (numberOfCriteria === 1 && numRequiredTodosCompleted >= totalRequiredTodosCount) ? success : primary,
      type: numberOfCriteria > 1 ? 'gauge' : 'bar',
      tooltipText: t.LEARNER_PROGRESS.PROGRESS.REQUIRED_TODOS(),
      selectedTab: Tab.TODOS_FOR_COMPLETION,
      id: 2,
    });
  }
  if (requiredAssignmentsCount > 0) {
    progressIndicators.push({
      maxValue: requiredAssignmentsCount,
      currentValue: numRequiredAssignmentsCompleted,
      activeColor: (numberOfCriteria === 1 && numRequiredAssignmentsCompleted >= requiredAssignmentsCount) ? primary : warning,
      type: numberOfCriteria > 1 ? 'gauge' : 'bar',
      tooltipText: props.extraProps.course.assignmentName.pluralizedTitleized,
      selectedTab: Tab.ASSIGNMENTS,
      id: 3,
    });
  }

  if (!progressIndicators.length) {
    progressIndicators.push({
      maxValue: totalPoints,
      currentValue: pointsReceived,
      activeColor: success,
      type: 'bar',
      tooltipText: props.extraProps.course.pointsName.pluralizedTitleized,
      selectedTab: Tab.POINTS,
      id: 4,
    });
  }

  return (
    <div className='progress-cell' css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      {props.learner.completionStatus === CompletionTypeFilters.MANUALLY_COMPLETED && (
        <NvTooltip text={t.LEARNER_PROGRESS.PROGRESS.COMPLETED_TOOLTIP(moment(props.learner.autoPassedAt).format('l'))} enabled={!!props.learner.autoPassedAt}>
          <div className='d-flex text-success font-weight-bolder mb-1'>
            <i className='icon icon-xss-smallest icon-badge' />
            <span>{t.LEARNER_PROGRESS.PROGRESS.COMPLETED()}</span>
          </div>
        </NvTooltip>
      )}
      <div className='w-100 d-flex flex-row'>
        {progressIndicators.map(({ maxValue, currentValue, activeColor, tooltipText, selectedTab, id }) => (
          <NvTooltip key={id} text={tooltipText}>
            {numberOfCriteria > 1 ? (
              <div className='cursor-pointer mr-2' onClick={() => onClick(selectedTab)}>
                <NvProgressGauge
                  maxValue={maxValue}
                  currentValue={currentValue}
                  activeColor={activeColor}
                  displayType={ProgressGaugeType.ACTUAL_ONLY}
                />
              </div>
            ) : (
              <div className='w-100 mb-3 d-flex flex-column cursor-pointer' onClick={() => onClick(selectedTab)}>
                <div className='font-weight-bolder' css={css` color: ${activeColor}; `}>{`${currentValue}/${maxValue}`}</div>
                <NvProgressBar
                  maxValue={maxValue}
                  currentValue={currentValue}
                  color={(currentValue < maxValue) ? `linear-gradient(90deg, ${getColorBetweenColorsByPercentage('#fff', activeColor, 0.4)} 0%, ${activeColor} 100%)` : activeColor}
                />
              </div>
            )}
          </NvTooltip>
        ))}
      </div>
    </div>
  );
};

const SectionCell = (props: LearnerCellProps, id: number) => {
  const { progress = 'not_started', progressPercentage = 0 } = [...props.learner.lectureSections, ...props.learner.lectureSubsections].find(sec => sec.id === id) ?? {};

  let tooltiptext = t.LEARNER_PROGRESS.SECTIONS.SOME_COMPLETED_TOOLTIP({ lectureAlias: props.extraProps.course.lectureName.downcasedSingularized });
  let description = `${Math.round(progressPercentage)}%`;
  let activeColor = primary;
  if (progressPercentage === 0) {
    tooltiptext = t.LEARNER_PROGRESS.SECTIONS.NO_COMPLETED_TOOLTIP({ lectureAlias: props.extraProps.course.lectureName.downcasedSingularized });
  }
  if (progress === 'completed') {
    tooltiptext = t.LEARNER_PROGRESS.SECTIONS.ALL_COMPLETED_TOOLTIP({ lectureAlias: props.extraProps.course.lectureName.downcasedSingularized });
    description = t.LEARNER_PROGRESS.SECTIONS.COMPLETED();
    activeColor = success;
  }
  if (progress === 'not_started') {
    tooltiptext = t.LEARNER_PROGRESS.SECTIONS.NO_PROGRESS_TOOLTIP();
    description = t.LEARNER_PROGRESS.SECTIONS.NOT_STARTED();
    activeColor = gray4;
  }

  return (
    <div className={`section-${id}-cell`} css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      <div className='w-100 mb-3 d-flex flex-column'>
        <NvTooltip text={tooltiptext}>
          <div>
            <div className='font-weight-bolder show-on-row-hover'>{description}</div>
            <NvProgressBar
              currentValue={progressPercentage}
              maxValue={100}
              color={activeColor}
            />
          </div>
        </NvTooltip>
      </div>
    </div>
  );
};

// This only functions to allow the row to extend the whole length of the screen
const SpacingCell = (props: LearnerCellProps) => (
  <div className='spacing-cell' css={props.serializedStyles} key={props.reactKey} {...props.divProps} />
);

const OptionsCell = (props: LearnerCellProps) => {
  const { injectServices } = useContext(AngularContext);
  const [CurrentPermissionsManager] = injectServices(['CurrentPermissionsManager']);

  if (CurrentPermissionsManager.isInstructor()) {
    return <LearnerRowOptions cellProps={props} key={props.reactKey} dataQa={config.pendo.learnerProgress.learnerOptions.button} dataQaId={`learner-${props.data.id}`} />;
  }
  return <div className='options-hidden-cell disabled' css={props.serializedStyles} key={props.reactKey} {...props.divProps} />;
};

export default LearnerRow;
