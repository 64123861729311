import React from 'react';
import { css } from '@emotion/react';
import CustomRadioGroup from 'components/custom-radio-group';
import { Style4AccordionSectionLectureComponent } from 'redux/schemas/models/lecture-component';
import t from 'react-translate';
import AbstractOrderedSectionContainer, { getOrderedSectionIndexString } from './abstract-ordered-section-container';
import { SectionContainerProps } from './accordion-types';
import {
  CircleCentered,
  innerCenteredRadioButtonContainerStyle,
  RadioButtonContainer,
  RadioButtonProps,
} from './ordered-section-left-hand-symbol-style-selector';


/**
 * maps the viewOptions to the radio button value
 */
/* @ngInject */
export function mapViewOptionsToValue(
  viewOptions: Style4AccordionSectionLectureComponent['viewOptions'],
): LeftHandSymbolStyles {
  if (viewOptions.leftHandSymbolShape === 'circle') {
    return 'circle-centered';
  }

  if (viewOptions.leftHandSymbolSize === 'big') {
    return 'square-symbol-big';
  }
  return 'square-centered';
}

/**
 * maps the value to a viewOption object
 */
/* @ngInject */
export function mapValueToViewOptions(
  value: LeftHandSymbolStyles,
): Partial<Style4AccordionSectionLectureComponent['viewOptions']> {
  if (value === 'circle-centered') {
    return {
      leftHandSymbolShape: 'circle',
      leftHandSymbolSize: 'normal',
    };
  }

  if (value === 'square-symbol-big') {
    return {
      leftHandSymbolShape: 'square',
      leftHandSymbolSize: 'big',
    };
  }

  // value === 'square-centered'
  return {
    leftHandSymbolShape: 'square',
    leftHandSymbolSize: 'normal',
  };
}

const radioButtonsGroupValues = [
  {
    value: 'square-centered',
    label: 'Square centered',
  } as const,

  {
    value: 'circle-centered',
    label: 'Circle centered',
  } as const,

  {
    value: 'square-symbol-big',
    label: 'Square symbol big',
  } as const,
];

export type LeftHandSymbolStyles = typeof radioButtonsGroupValues[number]['value'];

interface LeftHandSymbolStyleSelectorProps {
  value: LeftHandSymbolStyles;
  onChange: (value: LeftHandSymbolStyles) => void;
}

interface SquareRadioButtonProps extends RadioButtonProps {
  symbolSize: 'normal' | 'big';
}

/* @ngInject */
export function SquareCentered(props: SquareRadioButtonProps) {
  return (
    <RadioButtonContainer checked={props.checked}>
      <div
        css={css`
          ${innerCenteredRadioButtonContainerStyle};
          font-size: ${props.symbolSize === 'big' ? '32px' : '14px'};
        `}
        className='condensed font-weight-bolder'
      >
        {props.children}
      </div>
    </RadioButtonContainer>
  );
}

function Style4LeftHandSymbolStyleSelector(props: LeftHandSymbolStyleSelectorProps) {
  return (
    <CustomRadioGroup
      css={css`
        display: flex;
        align-self: flex-start;
      `}
      optionContainerStyle={css`
        margin-right: 7px;
      `}
      activeValue={props.value}
      name='style-3-left-hand-symbol-style'
      onChange={props.onChange}
      radios={radioButtonsGroupValues}
      renderRadioButton={(radioButtonProps) => {
        const children = getOrderedSectionIndexString(0, 'alphabetical');
        const radioButtonPassedProps = {
          children,
          checked: radioButtonProps.checked,
        };
        if (radioButtonProps.value === 'circle-centered') {
          return <CircleCentered {...radioButtonPassedProps} />;
        }
        if (radioButtonProps.value === 'square-symbol-big') {
          return <SquareCentered {...radioButtonPassedProps} symbolSize='big' />;
        }
        return <SquareCentered {...radioButtonPassedProps} symbolSize='normal' />;
      }}
    />
  );
}

type Style4SectionContainerProps = SectionContainerProps<Style4AccordionSectionLectureComponent>;

/* @ngInject */
export default function Style4SectionContainer(props: Style4SectionContainerProps) {
  return (
    <AbstractOrderedSectionContainer
      orderedSectionType='alphabetical'
      leftHandSymbolStyleSelector={(
        <React.Fragment>
          <div className='label gray-1 mb-2'>{t.SHARED.SELECT_SHAPE()}</div>
          <Style4LeftHandSymbolStyleSelector
            value={mapViewOptionsToValue(props.sectionData.viewOptions)}
            onChange={(value) => {
              props.onViewOptionsChange(mapValueToViewOptions(value));
            }}
          />
        </React.Fragment>
      )}
      {...props}
    />
  );
}
