import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import t from 'react-translate';
import { debounce } from 'underscore';
// colors - spacing
import { standardSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
// redux
import { getSkillTags } from 'redux/actions/skill-tags';
import { RootState } from 'redux/schemas';
import { SkillTagsNormalized } from 'redux/schemas/api/skill-tag';
import { SkillTag } from 'redux/schemas/models/skill-tag';
import { useAppDispatch } from 'redux/store';
// components
import { NvBasicTable, SortType } from 'shared/components/nv-basic-table';
import SkillRowModal from './skill-row-modal';
import { getColumns } from './skill-tags-dashboard';

const styles = css`
  max-height: calc(100vh - 220px);
  overflow-y: auto;
  margin: -${standardSpacing}px;

  .skill-tags {
    padding: ${standardSpacing}px ${standardSpacing}px 0 ${standardSpacing}px;

    .skill-row {
      padding: ${halfSpacing}px 0 ${halfSpacing}px 0;
    }
  }
`;

const SkillsModal = () => {
  const dispatch = useAppDispatch();

  const [pageIndex, setPageIndex] = useState(1);

  const listRef = useRef<HTMLDivElement>(null);

  const tags = useSelector((state) => Object.fromEntries(
    Object.values(state.models.skillTags)
      // eslint-disable-next-line no-prototype-builtins
      .filter((tag: SkillTag) => tag.hasOwnProperty('activitiesTagged') && tag.hasOwnProperty('coursesTagged'))
      .map((tag: SkillTag) => [tag.id, tag]),
  ));

  const isLoading = useSelector((state) => state.app.skillTag.isLoading);
  const total = useSelector((state) => state.app.skillTag.totalTags);
  const currentInstitutionId = useSelector((state: RootState) => state.app.currentInstitutionId);

  useEffect(() => {
    if (!total || total > Object.keys(tags).length) {
      dispatch(getSkillTags({
        institutionId: currentInstitutionId,
        page: pageIndex,
      }));
    }
  }, [pageIndex]);

  const handleScroll = debounce(() => {
    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    const pctScrolled = (scrollTop / (scrollHeight - clientHeight));
    if (!isLoading && Object.keys(tags).length < total && pctScrolled > 0.8) {
      setPageIndex(pageIndex + 1);
    }
  }, 100);

  return (
    <div css={styles} className='scroll-handler' ref={listRef} onScroll={handleScroll}>
      <div className='skill-tags'>
        <div>
          {t.INSTITUTIONS.SKILL_TAGS.DESCRIPTION()}
        </div>
        <NvBasicTable<JSX.Element, SkillTagsNormalized>
          columns={getColumns()}
          tabledata={tags}
          rowComponent={SkillRowModal as FunctionComponent}
          rowClass='skill-row'
          defaultSort={{ name: 'name', type: SortType.ASC }}
        />
      </div>
    </div>
  );
};
export default SkillsModal;
