import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SkillTag } from 'redux/schemas/models/skill-tag';

export type AdminParams = { institutionId: number, tag?: SkillTag, page?: number };
export type TagParams = { catalogId: string, lecturePageId: number, lectureComponentId: number, lectureComponentName: string, skillTagId: number };
type BulkUpdateParams = { institutionId: number, fileName: string };

export const getSkillTags = createAsyncThunk(
  'GET_SKILL_TAGS',
  async (params: AdminParams) => axios.get(`/institutions/${params.institutionId}/skill_tags.json?page=${params.page}`).then((response) => response.data.result),
);

export const getAllSkillTags = createAsyncThunk(
  'GET_ALL_SKILL_TAGS',
  async (params: AdminParams) => axios.get(`/institutions/${params.institutionId}/skill_tags/all.json`).then((response) => response.data.result),
);

export const addSkillTag = createAsyncThunk(
  'ADD_SKILL_TAG',
  async (params: AdminParams, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/institutions/${params.institutionId}/skill_tags.json`, {
        name: params.tag.name,
      }).then((postresponse) => postresponse.data.result);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteSkillTag = createAsyncThunk(
  'DELETE_SKILL_TAG',
  async (params: AdminParams) => axios.delete(`institutions/${params.institutionId}/skill_tags/${params.tag.id}.json`).then((response) => response.data),
);

export const tagLectureComponent = createAsyncThunk(
  'TAG_LECTURE_COMPONENT',
  async (params: TagParams) => axios.post(`/${params.catalogId}/lecture_pages/${params.lecturePageId}/${params.lectureComponentName}/${params.lectureComponentId}/skill_tags.json`,
    { skill_tag_id: params.skillTagId }).then((response) => response.data.result),
);

export const removeTagLectureComponent = createAsyncThunk(
  'REMOVE_TAG_LECTURE_COMPONENT',
  async (params: TagParams) => axios.delete(`/${params.catalogId}/lecture_pages/${params.lecturePageId}/${params.lectureComponentName}/${params.lectureComponentId}/skill_tags/${params.skillTagId}.json`)
    .then((response) => response.data.result),
);

export const fetchSkillTagsForProdCourses = createAsyncThunk(
  'FETCH_SKILL_TAGS_FOR_PROD_COURSES',
  async (params: AdminParams) => {
    const response = await axios.get(`/institutions/${params.institutionId}/skill_tags_for_production_courses.json`);
    return response.data.result;
  },
);

export const validateSkillTagsCSV = createAsyncThunk(
  'VALIDATE_SKILL_TAGS_CSV',
  async ({ institutionId, fileName }: BulkUpdateParams) => axios.post(`/institutions/${institutionId}/skill_tags/validate_csv.json`, { file_name: fileName })
    .then((response) => response.data.result),
);

export const confirmSkillTagsCSV = createAsyncThunk(
  'CONFIRM_SKILL_TAGS_CSV',
  async ({ institutionId, fileName }: BulkUpdateParams) => axios.post(`/institutions/${institutionId}/skill_tags/save_csv.json`, { file_name: fileName })
    .then((response) => response.data.result),
);
