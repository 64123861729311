import { css } from '@emotion/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import t from 'react-translate';
import { Button } from 'react-bootstrap';

import { AngularServicesContext } from 'react-app';
import { RootState } from 'redux/schemas';
import { getCourseAliases, getCurrentCourse } from 'redux/selectors/course';
import { ComponentType, NLectureComponent } from 'redux/schemas/models/lecture-component';
import { ExerciseSkillsRatingActivity, ExerciseSkillsRatingLectureComponentType, RatingActivityExercise } from 'redux/schemas/models/exercise-skills-rating';
import { ActivityType } from 'redux/schemas/models/activity';
import { useAppDispatch } from 'redux/store';
import { getCourseExercises, updateLectureComponent } from 'redux/actions/lecture-pages';
import { Submission } from 'redux/schemas/models/submissions';
import { getSkillTagsFromTaggings } from 'redux/selectors/skills-feedback';

import ActivityStatus from 'shared/components/activity/activity-status';
import ActivityTitle from 'shared/components/activity/activity-title';
import ActivityPoints from 'shared/components/activity/activity-points';
import ForActivityTitle from 'shared/components/activity/for-activity-title';
import LectureComponentHeaderInput from 'shared/components/lecture-component-header-input';
import NvIcon from 'shared/components/nv-icon';
import LectureComponentDeadline from 'components/lecture-component-deadline';
import LectureComponentProvider, { useLectureComponentContext } from 'components/lecture-component-provider';
import { FroalaViewMode } from 'froala/helpers/nv-froala-constants';
import NvFroala from 'froala/components/nv-froala';
import { LectureComponentProps, LecturePageMode } from 'lecture_pages/components';
import NvSubmissionGallery from 'shared/components/submission/nv-submission-gallery';
import NvSubmissionCard from 'shared/components/submission/nv-submission-card';
import useEntityListByIdList from 'dashboard/hooks/use-entity-list-by-id-list';

import { danger, gray6, hexToRgbaString } from 'styles/global_defaults/colors';
import { halfSpacing, standardSpacing, threeQuartersSpacing, largeSpacing } from 'styles/global_defaults/scaffolding';
import { NotAvailableGreyContainer } from 'shared/components/activity/not-available-container';
import BaseLectureComponentContext from 'lecture_pages/directives/components/base-lecture-component/context';
import NvSubmissionPlaceholderCard from 'shared/components/submission/nv-submission-placeholder-card';
import { config } from '../../../../config/pendo.config.json';

const styles = (isEdit: boolean) => css`
  .activity-container {
    max-width: 800px;
  }
  .unavailable {
    background-color: ${hexToRgbaString(danger, 0.05)};
    border: 1px dashed ${danger};
  }
  .for-activity {
    white-space: nowrap;
  }

  .not-available-grey-container {
    margin-bottom: 0px !important;
  }

  .submission-gallery {
    z-index: 1;
  }
  .skill-tag {
    width: fit-content;
    border-radius: ${threeQuartersSpacing}px;
    padding: 3px ${halfSpacing}px;
  }
`;

const useGetExerciseSkillsRatingLectureComponent = (passedLectureComponentId?: number) => {
  const contextLectureComponentId = useLectureComponentContext().lectureComponentId;

  const lectureComponentId = String(passedLectureComponentId ?? contextLectureComponentId);
  return useSelector<RootState, ExerciseSkillsRatingLectureComponentType>((state: RootState) => state.models.lectureComponents[lectureComponentId] as NLectureComponent<ComponentType.EXERCISE_SKILLS_RATING>);
};

const useUpdateExerciseSkillsRatingActivity = () => {
  const dispatch = useAppDispatch();

  const { catalogId, lecturePageId } = useLectureComponentContext();
  const { id, type, index, exerciseSkillsRatingActivity: exerciseSkillsRatingActivityId } = useGetExerciseSkillsRatingLectureComponent();
  const activity = useSelector<RootState, ExerciseSkillsRatingActivity>((state) => state.models.exerciseSkillsRatingActivities[exerciseSkillsRatingActivityId]);

  return useCallback(
    (newExerciseSkillsRatingActivity: Partial<ExerciseSkillsRatingActivity>, showSavingOverlay: boolean = true) => {
      const updatedComponent = {
        catalogId,
        lecturePageId,
        componentData: {
          id,
          type,
          index,
          skillsRating: {
            ...activity,
            activityType: ActivityType.EXERCISE,
            ...newExerciseSkillsRatingActivity,
          },
        },
        showSavingOverlay,
      };

      return dispatch(updateLectureComponent(updatedComponent)).then(() => {
        dispatch(getCourseExercises({ catalogId }));
      });
    },
    [dispatch, catalogId, index, id, lecturePageId, type, activity],
  );
};

const SkillTags = (props: { tag: string }) => (
  <div
    className='border border-gray-5 skill-tag align-items-center label mr-2 bg-gray-7 mb-2 text-black'
  >
    {props.tag}
  </div>
);

const ExerciseSkillsRatingComponentContextWrapper = (props: LectureComponentProps<ComponentType.EXERCISE_SKILLS_RATING>) => {
  const isEdit = props.mode === LecturePageMode.EDIT;

  const catalogId = useSelector((state) => state.app.currentCatalogId);

  return (
    <LectureComponentProvider
      catalogId={catalogId}
      lectureComponentId={props.lectureComponent.id}
      lecturePageId={props.currentLecture.id}
      isEdit={isEdit}
      angularComponent={props.angularComponent}
    >
      <ExerciseSkillsRatingComponent {...props} />
    </LectureComponentProvider>
  );
};

const ExerciseSkillsRatingComponent = ({
  currentLecture,
}: LectureComponentProps<ComponentType.EXERCISE_SKILLS_RATING>) => {
  const { isEdit, catalogId, lectureComponentId } = useLectureComponentContext();
  const { sharedProps, setSharedProps } = useContext(BaseLectureComponentContext);
  const { $state, $uibModal } = useContext(AngularServicesContext);
  const activityUpdater = useUpdateExerciseSkillsRatingActivity();

  const currentCourse = useSelector((state: RootState) => getCurrentCourse(state));
  const lectureComponent = useSelector((state: RootState) => state.models.lectureComponents[lectureComponentId]) as NLectureComponent<ComponentType.EXERCISE_SKILLS_RATING>;
  const lecturePage = useSelector((state: RootState) => state.models.lecturePages[lectureComponent.lecturePageId]);
  const skillRatingActivity = useSelector((state: RootState) => state.models.exerciseSkillsRatingActivities[lectureComponent.exerciseSkillsRatingActivity]) as ExerciseSkillsRatingActivity;
  const aliases = useSelector((state: RootState) => getCourseAliases(state));
  const { activity } = skillRatingActivity;
  const skillTags = useSelector((state: RootState) => getSkillTagsFromTaggings(state, activity?.skillTaggingIds ?? []));

  const [activityTitle, setactivityTitle] = useState(skillRatingActivity.title);
  const [expectations, setExpectations] = useState(skillRatingActivity.description);

  const pendingEvaluationCount = skillRatingActivity.requiredToComplete - skillRatingActivity.numReviewesCompleted;
  const isExerciseReleased = moment(activity?.releaseDate).isBefore(moment());
  const submissions = useEntityListByIdList<Submission>('submissions', skillRatingActivity.feedbackGalleryIds);

  /**
   * this date would either be the release date of the lesson containing the assignment
   * or if the assignment has a hard deadline and submissions don't get released before the deadline: would be the hard deadline date
   */
  let unAvailableDate = activity?.releaseDate;
  if (activity?.hardDeadline && moment(activity?.deadline).isBefore(activity?.releaseDate)) {
    unAvailableDate = activity?.deadline;
  }
  let submissionsNotViewable = false;
  if (activity?.hardDeadline && moment(activity?.deadline).isAfter(moment())) {
    submissionsNotViewable = !activity.submissionsViewableBeforeDeadline;
    unAvailableDate = activity?.deadline;
  }

  const openEditModal = useCallback(() => {
    const modalInstance = $uibModal.open({
      templateUrl: 'lecture_pages/templates/components/exercise-skills-feedback-edit-modal.html',
      controller: 'FeedbackEditFormModalCtrl',
      controllerAs: 'vm',
      resolve: {
        vmResolves: {
          isEdit: true,
          lectureComponent: {
            ...lectureComponent,
            lecturePage,
            feedbackDraft: {
              exercise: activity,
              requiredExercise: activity,
              exerciseId: skillRatingActivity?.activityId,
              targetGoal: skillRatingActivity.requiredToComplete,
            },
          },
        },
      },
    });

    modalInstance.result.then((res) => {
      activityUpdater({
        title: res.title,
        requiredToComplete: res.targetGoal,
        activityId: res.exerciseId,
      });
    });
  }, [$uibModal, activity, activityUpdater, lectureComponent, lecturePage, skillRatingActivity.activityId, skillRatingActivity.requiredToComplete]);

  useEffect(() => {
    setactivityTitle(skillRatingActivity.title);
  }, [skillRatingActivity.title]);

  useEffect(() => {
    const extraOptions = [];
    extraOptions.push({
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.DROPDOWN.EDIT_BASICS(),
      callback: () => openEditModal(),
    });
    if (!currentCourse.isSelfPaced) {
      extraOptions.push({
        type: 'text',
        text: skillRatingActivity.deadline ? t.LECTURE_PAGES.COMPONENTS.DROPDOWN.REMOVE_DEADLINE() : t.LECTURE_PAGES.COMPONENTS.DROPDOWN.ADD_DEADLINE(),
        callback: () => {
          activityUpdater({
            deadline: skillRatingActivity.deadline
              ? null
              : moment(currentLecture.releaseDate).add(1, 'weeks').endOf('day').toISOString(),
          }, false);
        },
      });
    }

    extraOptions.push({
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.VIEW_RELATED_ASSIGNMENT(),
      callback: () => gotoAssignment(true),
    });

    setSharedProps({
      ...sharedProps,
      isFullWidth: true,
      deleteConfirmationTitle: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.DELETE.TITLE(),
      extraOptions: {
        mode: 'prepend',
        renderOnMount: true,
        options: extraOptions,
      },
    });
  }, [setSharedProps, skillRatingActivity.deadline, currentCourse.isSelfPaced, currentLecture.releaseDate, openEditModal]);

  const gotoAssignment = (isEditMode?: boolean) => {
    const stateParams = {
      page: 'lecture-page',
      catalogId,
      id: activity?.lecturePageId,
      lectureActivityId: activity?.lectureComponentId,
    };
    if (isEditMode) {
      stateParams.page = 'lecture-page-edit';
    }
    const { page, ...restParams } = stateParams;
    /**
     * doing an angular-ui-router transition via $state.go() doesn't seem to update the template,
     * so we're using the same alternative through 'document.location.hash'
     */
    document.location.hash = $state.href(page, restParams);
  };

  const submissionItems = submissions?.map((submission) => (
    <NvSubmissionCard
      key={`submission-${submission.id}`}
      submission={submission}
      style={{ width: '280px', minWidth: '208px' }}
      showStatus={submission.isRatedByCurrentUser}
      statusClass='bg-gray-2'
      statusContent={(<NvIcon icon='check' size='medium' />)}
      stateParams={{
        galleryMode: true,
        isRatingFeedback: true,
      }}
      showApprovalStatus={false}
    />
  ));

  if (skillRatingActivity?.requiredToComplete
    && submissions?.length
    && submissions.length < skillRatingActivity.requiredToComplete) {
    submissionItems.push(
      <NvSubmissionPlaceholderCard
        key='submission-placholder'
        style={{
          width: '280px',
          minWidth: '208px',
        }}
        content={(
          <div className='d-flex align-items-center justify-content-center flex-column gray-2 text-small'>
            <NvIcon size='small' icon='gallery' className='mb-3' />
            <div className='d-flex text-center justify-content-center align-items-center'>
              {t.INFORMAL_FEEDBACK.NO_MORE_SUBMISSIONS()}
            </div>
          </div>
        )}
      />,
    );
  }

  const totalPoints = skillRatingActivity.totalPoints?.[0];

  return (
    <div
      className='d-flex flex-column mb-3'
      id={`lecture-component-${lectureComponentId}`}
      css={styles(isEdit)}
    >
      <div className='d-flex flex-column activity-container mx-auto justify-content-center align-items-center w-100 body-text-wrapper'>
        <ActivityStatus icon='skills-feedback-activity' status={skillRatingActivity.progress} />
        <div className='text-small d-flex flex-column align-items-center mt-2'>
          <ActivityTitle
            status={skillRatingActivity.progress}
            statusesTexts={{
              not_started: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.PROVIDE_FEEDBACK_DESCRIPTION.NOT_STARTED(skillRatingActivity.requiredToComplete ?? 0),
              completed: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.PROVIDE_FEEDBACK_DESCRIPTION.COMPLETED(skillRatingActivity.numReviewesCompleted ?? 0),
              missed: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.PROVIDE_FEEDBACK_DESCRIPTION.MISSED(pendingEvaluationCount ?? 0, skillRatingActivity.requiredToComplete ?? 0),
            }}
          />
          {skillRatingActivity.progress === 'in_progress' && (
            <span className='font-weight-bold mt-2'>
              {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.PROVIDE_FEEDBACK_DESCRIPTION.IN_PROGRESS(pendingEvaluationCount, skillRatingActivity.numReviewesCompleted)}
            </span>
          )}
        </div>
        <LectureComponentHeaderInput
          value={activityTitle}
          editable={isEdit}
          onBlur={() => {
            if (activityTitle !== skillRatingActivity.title) {
              activityUpdater({ title: activityTitle }, false);
            }
          }}
          onChange={(newTitle) => setactivityTitle(newTitle)}
          required
        />
        {activity && (
          <ForActivityTitle
            title={t.INFORMAL_FEEDBACK.FOR_ASSIGNMENT({ assignmentNameAlias: aliases.assignmentAliases?.AssignmentTitleAlias })}
            activity={activity.title}
            titleclassName='text-small font-weight-bolder for-activity'
            activityClassName='d-block text-small font-weight-bold text-primary ml-1 ellipsis'
            gotoActivty={() => gotoAssignment(isEdit)}
            dataQa={config.pendo.activities.assignmentSkillRating.gotoAssignment}
          />
        )}
        {skillRatingActivity.deadline && (
          <div className='mt-2'>
            <LectureComponentDeadline
              componentName='exercise-skills-rating-deadline'
              expirationDate={skillRatingActivity.deadline}
              lecturePageId={currentLecture.id}
              catalogId={catalogId}
              isEdit={isEdit}
              hasHardDeadlines={skillRatingActivity.hardDeadline}
              onHardDeadlineChange={(hardDeadline) => {
                activityUpdater({ hardDeadline }, false);
              }}
              onDeadlineChange={(date) => {
                activityUpdater({ deadline: date?.toISOString() }, false);
              }}
              isDeadlineEditable={isEdit}
            />
          </div>
        )}
        {skillRatingActivity.pointsConfiguration && (
          <div className='mt-2'>
            {(skillRatingActivity.progress === 'in_progress' || (skillRatingActivity.progress === 'missed' && skillRatingActivity.pointsReceived)) ? (
              <div className='d-flex text-small font-weight-bold'>
                <NvIcon icon='highlight' size='xss-smallest' className='text-success mr-2' />
                <span>
                  <span className='text-success'>{skillRatingActivity.pointsReceived}</span>
                  <span className='text-gray-2'>
                    /
                    {skillRatingActivity.totalPoints?.[0]}
                  </span>
                </span>
              </div>
            ) : (
              <div className='activity-points'>
                <ActivityPoints
                  totalPoints={totalPoints}
                  pointsReceived={skillRatingActivity.pointsReceived}
                  pointsText={(skillRatingActivity.progress === 'not_started' || isEdit
                  || (skillRatingActivity.progress === 'missed' && !skillRatingActivity.pointsReceived)) ? t.LECTURE_PAGES.COMPONENTS.ACTIVITY.X_POINTS_EACH({
                      ...aliases.pointsAliases,
                      pointsCount: skillRatingActivity.pointsConfiguration.points,
                      totalPoints,
                    }) : undefined}
                />
              </div>
            )}
          </div>
        )}
        <div className='w-100 mt-2'>
          {isEdit ? (
            <NvFroala
              value={expectations}
              onChange={setExpectations}
              preset={FroalaViewMode.INLINE}
              immediateReactModelUpdate
              onBlur={() => {
                if (expectations !== skillRatingActivity.description) {
                  activityUpdater({ description: expectations }, false);
                }
              }}
            />
          ) : (
            <div
              className='text-body text-gray-1 px-2'
            // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: skillRatingActivity.description }}
            />
          )}
        </div>
        {activity && skillTags.length > 0 && (
          <div className='d-flex flex-column align-items-center'>
            <div className='d-flex align-items-center my-4'>
              {isEdit ? (
                t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.SKILLS_FOR(activity.title)
              ) : (
                <>
                  {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.SKILLS_INFO()}
                </>
              )}
            </div>
            <div className='d-flex flex-wrap'>
              {skillTags?.map(tag => (
                <SkillTags tag={tag.name} key={tag.id} />
              ))}
            </div>
          </div>
        )}
      </div>
      <div>
        {/* Don't have an exercise associated with it */}
        {!activity
          && (isEdit ? (
            <div className='unavailable d-flex flex-column align-items-center justify-content-center p-2 mt-5 w-100 body-text-wrapper'>
              <div className='font-weight-bolder gray-1'>
                {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.NOT_AVAILABLE.ACTIVITY({ ...aliases.assignmentAliases })}
              </div>
              <div className='gray-1 my-1'>
                {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.NOT_AVAILABLE.DESCRIPTION()}
              </div>
              <Button
                variant='primary mt-2'
                onClick={() => openEditModal()}
              >
                {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.NOT_AVAILABLE.ACTIVITY({ ...aliases.assignmentAliases })}
              </Button>
            </div>
          ) : (
            <NotAvailableGreyContainer icon='ban' text={t.LECTURE_PAGES.COMPONENTS.NOT_AVAILABLE_YET()} />
          ))}
        {/* Has exercise, but no skillTags associated with it */}
        {(activity && (!skillTags || skillTags.length === 0))
          && (isEdit ? (
            <div className='unavailable d-flex flex-column align-items-center justify-content-center p-2 mt-5 w-100 body-text-wrapper'>
              <div className='font-weight-bolder gray-1'>
                {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.NOT_AVAILABLE.SKILLS({ ...aliases.assignmentAliases })}
              </div>
              <div className='gray-1 my-1'>
                {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.NOT_AVAILABLE.DESCRIPTION()}
              </div>
              <Button
                variant='primary mt-2'
                onClick={() => gotoAssignment(true)}
                data-qa={config.pendo.activities.assignmentSkillRating.gotoAssignment}
              >
                {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.NOT_AVAILABLE.SKILLS({ ...aliases.assignmentAliases })}
              </Button>
            </div>
          ) : (
            <NotAvailableGreyContainer icon='ban' text={t.LECTURE_PAGES.COMPONENTS.NOT_AVAILABLE_YET()} />
          ))}
        {/* Has exercise, Has skill tags, In Learner mode, Associated assignment is not completed yet */}
        {!skillRatingActivity.hasSubmittedRequirement && !isEdit && activity && skillTags?.length > 0 && isExerciseReleased && skillRatingActivity.progress !== 'missed' && (
          <div className='d-flex flex-column align-items-center'>
            <div className='text-small gray-3 text-center'>
              {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.NOT_COMPLETED(activity.title)}
            </div>
            <Button
              variant='primary'
              className='my-2'
              onClick={() => gotoAssignment()}
            >
              {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.GOTO_ASSINMENT({ ...aliases.assignmentAliases })}
            </Button>
          </div>
        )}
      </div>
      <div className='submission-gallery'>
        {isEdit && (
          <NotAvailableGreyContainer
            icon='gallery'
            text={t.EXERCISES.NO_SUBMISSIONS_YET({ assignmentNameAlias: aliases.assignmentAliases?.AssignmentTitleAlias })}
          />
        )}
        {!isEdit
          && activity
          && skillTags?.length > 0
          && (!isExerciseReleased || submissionsNotViewable) && (
          <NotAvailableGreyContainer
            icon='gallery'
            text={t.EXERCISES.SUBMISSIONS_NOT_AVAILABLE_YET(moment(unAvailableDate).format('LLL'))}
          />
        )}
        {!isEdit
          && activity
          && isExerciseReleased
          && skillRatingActivity.hasSubmittedRequirement
          && skillTags?.length > 0
          && !submissionsNotViewable
          && (!skillRatingActivity.feedbackGalleryIds || skillRatingActivity.feedbackGalleryIds.length === 0) && (
          <NotAvailableGreyContainer
            icon='gallery'
            text={t.EXERCISES.NO_SUBMISSIONS_YET({ assignmentNameAlias: aliases.assignmentAliases?.AssignmentTitleAlias })}
          />
        )}
        {!isEdit
          && activity
          && isExerciseReleased
          && skillRatingActivity.hasSubmittedRequirement
          && skillRatingActivity.feedbackGalleryIds?.length > 0
          && skillTags?.length > 0
          && (
            <div className='mt-6'>
              <NvSubmissionGallery
                spacing={standardSpacing}
                backgroundColor={gray6}
                arrowsHidden={!skillRatingActivity.feedbackGalleryIds?.length}
                topPadding={standardSpacing}
                bottomPadding={standardSpacing}
                alignCenter={false}
              >
                {submissionItems.map((item) => item)}
              </NvSubmissionGallery>
            </div>
          )}
      </div>
    </div>
  );
};

export default ExerciseSkillsRatingComponentContextWrapper;
