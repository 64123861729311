import { PermissionTypes } from 'institutions/services/roles-service';
import { RoleResourceType } from 'redux/schemas/models/role';

export default {
  bindings: {
    headerText: '@?',
    onRoleChanged: '&?',
    learnersOnly: '=',
    adminsOnly: '=',
    initialRole: '<?',
  },
  controllerAs: 'vm',
  controller: function ctrl($scope, $state, _, $translate, InstitutionsManager, CourseRolesManager, RolesService,
    CurrentCourseManager) {
'ngInject';
    const vm = this;
    vm.CourseRolesManager = CourseRolesManager;
    vm.CurrentCourseManager = CurrentCourseManager;
    vm.courseRoles = _.filter(CourseRolesManager.roles, ((role) => role.resourceType === RoleResourceType.COURSE));
    vm.popoverRoleId = null;

    vm.learnerRoles = [
      vm.CourseRolesManager.noRolesRole,
    ];
    vm.adminOnlyRoles = [];
    vm.adminAndMentorRoles = [];
    vm.mentorOnlyRoles = [];

    vm.isJourney = CurrentCourseManager.course.isJourney;

    const allowedRolesInJourney = [
      PermissionTypes.CONFIGURATION_REGISTRATION,
      PermissionTypes.INSTRUCTOR,
      PermissionTypes.TEACHER_ASSISTANT,
      PermissionTypes.COURSE_BUILDER,
      PermissionTypes.REPORT_ADMIN,
      PermissionTypes.LEARNER_REGISTRATION,
    ];

    vm.getAliases = () => (_.extend({}, CurrentCourseManager.course.getAliases(), {
      isJourney: vm.isJourney,
    }));

    if (!vm.adminsOnly) {
      vm.learnerRoles = vm.learnerRoles.concat(_.filter(vm.courseRoles, (r) => RolesService.hasPermission(r.permission, PermissionTypes.CUSTOM_ROLE)));
    }

    if (!vm.learnersOnly) {
      vm.adminOnlyRoles = _.filter(
        vm.courseRoles,
        (r) => {
          const journeyAllowed = vm.isJourney ? (
            allowedRolesInJourney.some((permission) => RolesService.hasPermission(r.permission, permission))
          ) : true;

          // If we are hiding mentor categories, we need to include mentors in admin only category as well.
          const includeMentors = !RolesService.isMentor(r);

          return r.permission > PermissionTypes.CUSTOM_ROLE && includeMentors && journeyAllowed;
        },
      );

      vm.adminAndMentorRoles = _.filter(vm.courseRoles, (r) => r.permission > PermissionTypes.CUSTOM_ROLE
          && RolesService.isMentor(r) && r.permission !== PermissionTypes.MENTOR);
      // Mentors are considered 'admins' for the purpose of this filtering
      vm.mentorOnlyRoles = _.filter(vm.courseRoles, (r) => r.permission === PermissionTypes.MENTOR);
    }

    if (vm.initialRole) {
      vm.selectedRoleId = vm.initialRole.id;
    }

    vm.onRoleSelected = (roleId) => {
      vm.selectedRoleId = roleId;

      if (vm.onRoleChanged) {
        let role = null;
        if (roleId === vm.CourseRolesManager.noRolesRole.id) {
          role = vm.CourseRolesManager.noRolesRole;
        } else {
          role = _.find(vm.CourseRolesManager.roles, (roleObj) => roleObj.id === roleId);
        }

        vm.onRoleChanged()(role);
      }
    };

    vm.categoryHasInitRole = (category) => vm.selectedRoleId >= 0 && _.some(category, (roleModel) => roleModel.id === vm.selectedRoleId);

    return vm;
  },
  templateUrl: 'user_management/templates/nv-roles-radio.html',
};
