/* @ngInject */
export default function NvLeaderboardTableRows(
  nvUtil,
  CurrentCourseManager,
) {
  return {
    scope: {
      learner: '=',
      maxPoints: '=',
      currentUser: '=?',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
      vm.CurrentCourseManager = CurrentCourseManager;

      vm.isCurrentUser = vm.currentUser?.user.id === vm.learner.user.id;

      vm.calculateWidth = function (current) {
        const width = $(window).width();
        const pointsWidth = (current / vm.maxPoints) * 100;

        // If bar is going to smaller than roughly 10px, we want to hide it altogether
        if (width >= 480 && pointsWidth > 2) {
          return `${pointsWidth}%`;
        } if (width < 480 && pointsWidth > 6) {
          return `${pointsWidth}%`;
        }
        return 0;
      };
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'leaderboard/templates/nv-leaderboard-table-rows.html',
  };
}
