import { normalize } from 'normalizr';
import { omit, without } from 'lodash';
import { isEmpty, some, union, uniq } from 'underscore';
import { orgMenteeSchema, OrgMentorEntitties, orgMentorListSchema, orgMentorSchema } from 'redux/schemas/api/org-mentors';
import { OrgMentorDashboard, MentorDashboard, FilterKeys, FilterType, OrgMentor } from 'redux/schemas/models/org-mentors';
import {
  fetchOrgMentors,
  deleteInstitutionAdmin,
  addOrgMentor,
  updateRoleAliases,
  searchNonAdminUsers,
  searchOrganizationUsers,
  addInstitutionMentee,
  deleteInstitutionMentee,
  getMenteesForMentor,
  addMultipleMenteesToMentor,
  getMentorDashboardSummary,
} from 'redux/actions/org-mentors';
import { createReducer } from '@reduxjs/toolkit';
import { OrgAdminRole } from 'institutions/services/roles-service';
import { initialRootState } from '.';

export const initialOrgMentorDashboard: OrgMentorDashboard = {
  mentors: [],
  search: {
    hasMore: false,
  },
};

const initialFilterCounts = {
  [FilterType.TOTAL_MENTEES]: 0,
  [FilterType.NEVER_LOGGED_IN]: 0,
  [FilterType.MISSED_DEADLINE]: 0,
  [FilterType.NOT_ACTIVE]: 0,
  [FilterType.RECENTLY_COMPLETED]: 0,
};

export const initialMentorDashboard: MentorDashboard = {
  isMenteesLoading: false,
  isSummaryLoading: false,
  onceLoaded: false,
  mentees: [],
  totalMenteesCount: null,
  filterCounts: initialFilterCounts,
  filterKey: FilterKeys.TOTAL_MENTEES,
  nextPage: 1,
};

export default createReducer(initialRootState, builder => {
  builder.addCase(fetchOrgMentors.pending, (state, action) => {
    state.app.orgMentorAdminDashboard.mentors = state.app.orgMentorAdminDashboard.mentors || [];
    state.models.orgMentors = state.models.orgMentors || {};
  })
    .addCase(fetchOrgMentors.fulfilled, (state, action) => {
      const data = normalize<OrgMentor, OrgMentorEntitties>(
        action.payload.response,
        orgMentorListSchema,
      );
      Object.assign(state.models.orgMentors, data.entities.orgMentors);
      Object.assign(state.app.orgMentorAdminDashboard.mentors, data.result);
    })
    .addCase(addOrgMentor.fulfilled, (state, action) => {
      if (action?.payload) {
        const { result: requestResult } = action?.payload;
        state.app.orgMentorAdminDashboard.mentors = [
          ...state.app.orgMentorAdminDashboard.mentors,
          requestResult.id,
        ];
        state.models.orgMentors[requestResult.id] = { ...requestResult, mentees: [] };
      }
    })
    .addCase(deleteInstitutionAdmin.fulfilled, (state, action) => {
      const { institutionId, institutionAdminId } = action.meta.arg;
      state.app.orgMentorAdminDashboard.mentors = without(state.app.orgMentorAdminDashboard.mentors, institutionAdminId);
      state.models.orgMentors = omit(state.models.orgMentors, [institutionAdminId]);
    })
    .addCase(updateRoleAliases.fulfilled, (state, action) => {
      const { institutionId } = action.meta.arg;
      state.models.institutions[institutionId].roleAliases = action.payload.result.roleAliases;
    })
    .addCase(searchNonAdminUsers.fulfilled, (state, action) => {
      state.app.orgMentorAdminDashboard.search.hasMore = false;
    })
    .addCase(searchOrganizationUsers.fulfilled, (state, action) => {
      state.app.orgMentorAdminDashboard.search.hasMore = action.payload.result.count > action.meta.arg.page * action.meta.arg.pageSize;
    })
    .addCase(addInstitutionMentee.fulfilled, (state, action) => {
      (action.payload?.result?.created || []).map((item) => {
        if (item.institutionMentor && state.models.orgMentors[item.institutionMentor.id]) {
          state.models.orgMentors[item.institutionMentor.id].mentees = [
            ...state.models.orgMentors[item.institutionMentor.id].mentees,
            {
              ...omit(item, ['user']),
            },
          ];
        }
        return true;
      });
    })
    .addCase(deleteInstitutionMentee.fulfilled, (state, action) => {
      const { institutionMentoringId, institutionMentorId } = action.meta.arg;
      state.models.orgMentors[institutionMentorId].mentees = state.models.orgMentors[institutionMentorId].mentees.filter(
        mentees => mentees.id !== institutionMentoringId,
      );
    })
    .addCase(getMenteesForMentor.pending, (state, action) => {
      const { filter } = action.meta.arg;
      state.app.mentorDashboard.isMenteesLoading = true;

      // Resetting the mentor dashboard data once the filter change
      if (state.app.mentorDashboard.filterKey !== filter) {
        state.app.mentorDashboard.mentees = [];
        state.app.mentorDashboard.onceLoaded = false;
        state.app.mentorDashboard.filterKey = filter;
      }
    })
    .addCase(getMenteesForMentor.fulfilled, (state, action) => {
      const { mentess, total, nextPage } = action.payload.result;
      const normalized = normalize(mentess, [orgMenteeSchema]);
      const allMentees = union(state.app.mentorDashboard.mentees, normalized.result);

      state.app.mentorDashboard.mentees = allMentees;
      Object.assign(state.models.orgMentees, normalized.entities.orgMentees);
      state.app.mentorDashboard.onceLoaded = true;
      state.app.mentorDashboard.nextPage = nextPage;
      state.app.mentorDashboard.totalMenteesCount = total;
      state.app.mentorDashboard.isMenteesLoading = false;
    })
    .addCase(getMentorDashboardSummary.pending, (state, action) => {
      state.app.mentorDashboard.isSummaryLoading = true;
    })
    .addCase(getMentorDashboardSummary.fulfilled, (state, action) => {
      state.app.mentorDashboard.filterCounts = action.payload['result'];
      state.app.mentorDashboard.isSummaryLoading = false;
    })
    .addCase(addMultipleMenteesToMentor.fulfilled, (state, action) => {
      const existingMentorsIds = [];
      const newMentorsIds = [];
      (action.payload?.result?.created || []).map((item) => {
        if (state.app.orgMentorAdminDashboard.mentors.includes(item.institutionMentor.id)) {
          if (!some(state.models.orgMentors[item.institutionMentor.id].mentees, (mentee) => mentee.id === item.id)) {
            existingMentorsIds.push(item.institutionMentor.id);
            state.models.orgMentors[item.institutionMentor.id].mentees.push({
              id: item.id,
              mentee: item.mentee,
            });
          }
        } else {
          newMentorsIds.push(item.institutionMentor.id);
          state.app.orgMentorAdminDashboard.mentors = [
            ...state.app.orgMentorAdminDashboard.mentors,
            item.institutionMentor.id,
          ];
          state.models.orgMentors[item.institutionMentor.id] = {
            id: item.institutionMentor.id,
            user: item.user,
            institutionId: item.institutionMentor.institutionId,
            adminRole: OrgAdminRole.ORG_MENTOR,
            mentees: [{
              id: item.id,
              mentee: item.mentee,
            }],
          };
        }
        /**
         * We need the existing mentor count and new mentor count in the response
         * for showing alert message.
         */
        action.payload.result.data = {
          existingMentorsCount: uniq(existingMentorsIds).length,
          newMentorsCount: uniq(newMentorsIds).length,
        };
        return true;
      });
    });
});
