import { css } from '@emotion/react';
import React, { useState, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { Button } from 'react-bootstrap';
import { filter, keys, map } from 'underscore';

// schemas
import { CommunicationType } from 'redux/schemas/models/course-communication';
import { CourseActivities } from 'redux/schemas/api/course-communications';
import { ActivityKey, MinimalActivity } from 'redux/schemas/models/activity';

// contexts
import { CommunicationDispatch, CommunicationAction } from 'communications/course_communications/contexts/communication-context';

// selectors
import { getCourseActivities, getCourseAliases } from 'redux/selectors/course';
import { getLabelForActivity, getActivityTypeForKey, getKeyForActivityType } from 'redux/selectors/activity';

// components
import NvFormDropdown from 'shared/components/inputs/nv-form-dropdown';
import { NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';

type DuplicateActivityProps = {
  isDisplayed: boolean
  onClose: Function
};

const styles = (isDisplayed) => css`
  .activity-wrapper {
    display: ${isDisplayed ? 'flex' : 'none'};
    align-items: center;

    .dropdowns {
      display: flex;
      height: auto;
      flex: 4;

      .activity-type {
        flex: 1;
      }

      .activity {
        flex: 2;
      }
    }
  }
`;

const filterActivities = (communicationType: CommunicationType, activities: CourseActivities): CourseActivities => {
  if (communicationType === CommunicationType.DUE_DATE_EMAIL
    || communicationType === CommunicationType.DUE_DATE_ANNOUNCEMENT
  ) {
    const filteredActivities = {};
    map(keys(activities), (key: ActivityKey) => {
      const activitiesThatMatched = filter((activities[key] || []), (activity) => (!!activity.dueDate));
      if (activitiesThatMatched.length) {
        filteredActivities[key] = activitiesThatMatched;
      }
    });
    return filteredActivities;
  }
  return activities;
};

const DuplicateActivity = (props: DuplicateActivityProps) => {
  const { State, dispatch } = useContext(CommunicationDispatch);
  const courseActivities: CourseActivities = useSelector((state) => getCourseActivities(state));
  const aliases = useSelector((state) => getCourseAliases(state));

  const [activityType, setActivityType] = useState(State.activityType);
  const [activityId, setActivityId] = useState(State.activityId);

  // Filter activities according to the communication type
  const filteredCourseActivities: CourseActivities = useMemo(() => filterActivities(State.communicationType, courseActivities), [courseActivities, State.communicationType]);

  const types: NvDropdownTextItem[] = keys(filteredCourseActivities).map((type: ActivityKey) => ({
    id: getActivityTypeForKey(type),
    type: 'text',
    text: getLabelForActivity(type, aliases),
    value: getActivityTypeForKey(type),
    key: type,
  }));

  const activities: NvDropdownTextItem[] = (filteredCourseActivities[getKeyForActivityType(activityType)] || [] as MinimalActivity[]).map((item) => ({
    id: item.id,
    type: 'text',
    text: item.title,
    value: item.id,
  }));

  const onSave = () => {
    if (activityId && activityType) {
      // Updating context
      dispatch({
        type: CommunicationAction.SET_ACTIVITY,
        activityType,
        activityId,
      });
      props.onClose();
    }
  };

  return (
    <div css={styles(props.isDisplayed)}>
      <div className='pt-2 activity-wrapper bs4-row'>
        <div className='dropdowns bs4-col-8'>
          <NvFormDropdown
            name='ownerType'
            className='activity-type mr-2'
            items={types}
            title={t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.SELECT_TYPE()}
            onChange={(val) => { setActivityType(val.value); setActivityId(null); }}
          />
          <NvFormDropdown
            name='ownerId'
            className='activity ml-2'
            items={activities}
            title={t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.SELECT_ACTIVITY()}
            onChange={(val) => setActivityId(val.value)}
          />
        </div>
        <div className='buttons bs4-col-4'>
          <Button
            className='ml-2'
            onClick={() => onSave()}
          >
            {t.FORM.SAVE()}
          </Button>
          <Button
            className='ml-2'
            variant='secondary'
            onClick={() => props.onClose()}
          >
            {t.FORM.CANCEL()}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DuplicateActivity;
