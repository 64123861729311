import { LecturePageMode } from 'lecture_pages/components';
import getDefaultAddMenuComponentData from 'lecture_pages/components/data/add-menu-defaults';
import StyledLinkLectureComponent from 'lecture_pages/components/styled-link-lecture-component/styled-link-lecture-component';
import LhsStyledComponent from 'lecture_pages/directives/components/lhs-styled-link-component';
import React from 'react';
import { ComponentType, NLectureComponent, StyledLinkType } from 'redux/schemas/models/lecture-component';

const StyledLinksContentPreview = (props: { type: StyledLinkType }) => {
  const lectureComponent = getDefaultAddMenuComponentData(ComponentType.STYLED_LINK, props.type) as NLectureComponent<StyledLinkType>;
  return (
    <LhsStyledComponent lectureComponent={lectureComponent} />
  );
};

export default StyledLinksContentPreview;
