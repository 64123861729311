import { getColorBetweenColorsByPercentage, success, gray6, gray4 } from 'styles/global_defaults/colors';

/* @ngInject */
export default function nvProgressGauge(
) {
  return {
    scope: {
      currentValue: '=',
      maxValue: '=',
      activeColor: '=?', // Color for the active part
      inactiveColor: '=?', // Color for the inactive part
      bgColor: '=?', // background color for the center part of the radial bar
      size: '=?', // LARGE, SMALL
      labelKey: '=?', // Translate Key if label not provided
      keyValues: '=?', // translate values
      icon: '=?',
      label: '=?',
      displayType: '=?', // ACTUAL_VS_TOTAL, ACTUAL_ONLY, ACTUAL_WITH_ICON, NONE
      selected: '=?',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
      vm.rotation = 0;
      vm.over50 = false;


      // Default values if not provided
      vm.size = vm.size ?? 'SMALL';
      vm.bgColor = vm.bgColor ?? '#fff';
      vm.activeColor = vm.activeColor ?? success;
      vm.inActiveColor = vm.inactiveColor ?? gray6;
      vm.displayType = vm.displayType ?? 'NONE';

      // Function that define styles for different parts of the radial graph
      vm.getCircleStyles = getCircleStyles;
      vm.getContentStyles = getContentStyles;
      vm.getFirst50Styles = getFirst50Styles;
      vm.getValueBarStyles = getValueBarStyles;

      $scope.$watch('vm.currentValue', () => {
        initialize();
      });

      initialize();

      function initialize() {
        vm.percentage = (vm.currentValue / vm.maxValue).toFixed(2);
        if (vm.percentage >= 1) {
          vm.percentage = 1;
        }

        // Start Color for the gradient, calculated at .4 percentage from white and active color
        vm.startColor = getColorBetweenColorsByPercentage('#fff', vm.activeColor, 0.4);

        vm.endColor = vm.activeColor;

        if (vm.percentage >= 0.5) {
          // If values is > 50%, radial graph is constructed with 2 half circles.
          // 1st half circles cover the 0 - 50
          // 2nd half circles cover from %value - 50% - %value
          // So we need to find the gradient color for the point (%value - 50%)
          // and start gradient from that color for the first part
          // Eg: if the value is 75, then
          // First part half circle will cover 0 to 50 and Second half circle will cover 25( ie 75 - 50) to 75
          // Here we calculate the color for point 25 start gradient from that color for the 2nd half circle

          vm.part1EndColor = getColorBetweenColorsByPercentage(vm.startColor, vm.endColor, 0.5);
          vm.part2StartColor = getColorBetweenColorsByPercentage(vm.startColor, vm.endColor, (vm.percentage - 0.5));
          vm.over50 = true;
        } else {
          // Only Part 2 half circle is visible. So set part2Start color as start color
          vm.part1EndColor = vm.endColor;
          vm.part2StartColor = vm.startColor;
          vm.over50 = false;
        }


        // Calculate the rotation needed for accurately clip the half circles
        // Each percentage take 1 % of 360
        vm.rotation = Math.round(vm.percentage * 100 * 3.6);
      }

      /**
       * Styles for the basic circle
       */
      function getCircleStyles() {
        if (vm.selected) {
          return {
            backgroundColor: vm.inActiveColor,
            boxShadow: `0px 0px 17px -2px ${vm.startColor}`,
          };
        }
        return {
          backgroundColor: vm.inActiveColor,
        };
      }

      /**
       * Get Styles for the center content
       */
      function getContentStyles() {
        if (vm.selected) {
          return {
            backgroundColor: vm.bgColor,
            boxShadow: `inset 0px 0px 17px -6px ${vm.startColor}`,
            color: vm.currentValue > 0 ? vm.activeColor : gray4,
          };
        }
        return {
          backgroundColor: vm.bgColor,
          color: vm.currentValue > 0 ? vm.activeColor : gray4,
        };
      }

      /**
       * Get Styles for the first 50 part of the radial graph when value > 50
       */
      function getFirst50Styles() {
        if (vm.percentage === 1) {
          return {
            backgroundColor: vm.activeColor,
          };
        }
        return {
          backgroundColor: vm.endColor,
          background: `linear-gradient(180deg, ${vm.startColor} 0%, ${vm.part1EndColor} 100%)`,
        };
      }

      /**
       * Styles for the actual part of the radial graph
       */
      function getValueBarStyles() {
        if (vm.percentage === 1) {
          return {
            transform: `rotate(${vm.rotation}deg)`,
            backgroundColor: vm.activeColor,
          };
        }
        return {
          transform: `rotate(${vm.rotation}deg)`,
          backgroundColor: vm.endColor,
          background: `linear-gradient(0deg, ${vm.part2StartColor} 0%, ${vm.endColor} 100%)`,
        };
      }
    },
    replace: true,
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'shared/templates/nv-progress-gauge.html',
  };
}
