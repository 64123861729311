import { css } from '@emotion/react';
import React, { useContext, useState } from 'react';
import _, { isEmpty } from 'underscore';
import t from 'react-translate';
import { connect, useSelector } from 'react-redux';
import { AngularServicesContext } from 'react-app';

// Schemas
import { RootState } from 'redux/schemas';
import { OrgMentee } from 'redux/schemas/models/org-mentors';

// Selectors
import { getOrgMentee } from 'redux/selectors/org-mentors';

// Styles
import { doubleSpacing } from 'styles/global_defaults/scaffolding';
import { isHandheld } from 'styles/global_defaults/media-queries';
import { gray5, warning } from 'styles/global_defaults/colors';

// Components
import moment from 'moment';
import NvDropdown, { NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { copyToClipboard } from 'shared/react-utils';
import NvTooltip from 'shared/components/nv-tooltip';
import ClickableContainer from 'components/clickable-container';
import { NvUserAvatar } from 'components/nv-user-avatar';
import MenteeEnrollmentStatus, { EnrollmentStatus } from '../mentee-enrollment-status';
import MenteeRowNotes from './mentee-row-notes';

type MenteeRowProps = {
  menteeId: number,
};

type StateProps = {
  mentee: OrgMentee,
};

const styles = css`
  border-bottom: 1px solid;
  border-color: ${gray5};

  :hover {
    border-color: ${warning};
  }

  .copy-alert {
    margin-top: -${doubleSpacing}px;
    right: 0;
    width: 170px;
    border-radius: 2px;
  }

  .cursor-pointer {
    cursor: pointer;
  }
`;

const MenteeRow = ({ menteeId, mentee }: MenteeRowProps & StateProps) => {
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const { $state } = useContext(AngularServicesContext) || {};
  const currentInstitutionId = useSelector((state) => state.app.currentInstitutionId);

  const fullName = `${mentee?.firstName} ${mentee?.lastName}`;
  const getInitials = (FirstName: string, LastName: string) => (FirstName[0]?.toUpperCase() ?? '')
 + (LastName[0]?.toUpperCase() ?? '');

  const moreDropdownItems: NvDropdownOption[] = [
    {
      type: 'text',
      text: t.MENTOR_DASHBOARD.COPY_EMAIL(),
      class: 'px-3 py-2',
      callback: () => copyEmail(),
    },
  ];

  const getLastActive = (courseCompletionProgress) => {
    let displayText = '';
    let textClass = 'text-black';
    const lastActivity = mentee.highlights.lastActiveInCourses;
    const activeWarningStatuses = [
      EnrollmentStatus.IN_PROGRESS,
      EnrollmentStatus.ENROLLED,
    ];
    const showWarning = activeWarningStatuses.some(status => Object.keys(courseCompletionProgress).includes(status));

    if (!isEmpty(lastActivity)) {
      if (Math.round(moment().diff(moment(lastActivity), 'days', true)) > 7
        && showWarning) {
        textClass = 'text-danger';
      }
      displayText = moment().diff(lastActivity) > 0
        ? moment(lastActivity).fromNow()
        : moment().fromNow();
    } else {
      displayText = t.INSTITUTIONS.ORG_MENTORS.FILTER.FLAG_NAME.NEVER_LOGGED_IN();
      textClass = 'text-gray-2';
    }
    return (
      <span className={textClass}>
        {isHandheld() && `${t.INSTITUTIONS.ROLES.LAST_ACTIVE()}: `}
        {displayText}
      </span>
    );
  };

  const copyEmail = () => {
    // For copying mentee email to clipboard
    copyToClipboard(mentee.email);

    // Showing copy succes alert for 3 seconds
    setShowCopyAlert(true);
    setTimeout(() => {
      setShowCopyAlert(false);
    }, 3000);
  };

  const openEnrollmentModal = (id: number) => {
    $state.go('non-admin-user-enrollment-details-modal', {
      userId: id,
      institutionId: currentInstitutionId,
      galleryMode: true,
    });
  };

  return (
    <div className='table-row py-2' css={styles}>
      <ClickableContainer
        className='name-cell'
        onClick={() => openEnrollmentModal(mentee.id)}
      >
        <NvUserAvatar
          tooltipText={t.INSTITUTIONS.ROLES.VIEW_DETAILS()}
          tooltipPlacement='top'
          inlineName
          borderType='round'
          size='md'
          user={{
            ...mentee,
            admin: false,
            fullName,
            roles: null,
            initials: getInitials(mentee.firstName, mentee.lastName),
            userNameForURL: fullName,
          }}
        />
        <NvTooltip
          text={t.INSTITUTIONS.ROLES.VIEW_DETAILS()}
          placement='top'
        >
          <div className='ml-2 text-primary'>
            {fullName}
          </div>
        </NvTooltip>
      </ClickableContainer>
      {isEmpty(mentee.highlights?.courseCompletionProgress) ? (
        <div className='enrollment-cell d-flex align-items-center text-gray-3 font-weight-bold'>
          {t.MENTOR_DASHBOARD.NO_ENROLLMENT()}
        </div>
      ) : (
        <React.Fragment>
          <ClickableContainer
            className='enrollment-cell cursor-pointer'
            onClick={() => openEnrollmentModal(mentee.id)}
          >
            <MenteeEnrollmentStatus popupContents={mentee.highlights?.courseCompletionProgress} />
          </ClickableContainer>
          <ClickableContainer
            className='last-active-cell font-weight-bold cursor-pointer'
            onClick={() => openEnrollmentModal(mentee.id)}
          >
            {getLastActive(mentee.highlights.courseCompletionProgress)}
          </ClickableContainer>
          <ClickableContainer
            className='notes-cell cursor-pointer'
            onClick={() => openEnrollmentModal(mentee.id)}
          >
            <MenteeRowNotes highlights={mentee.highlights} />
          </ClickableContainer>
        </React.Fragment>
      )}
      <div className='more-cell'>
        {showCopyAlert && (
          <div className='position-relative'>
            <div className='copy-alert position-absolute bg-black px-2 text-white py-1 text-small font-weight-bold'>
              {t.MENTOR_DASHBOARD.COPY_SUCCESS()}
            </div>
          </div>
        )}
        <NvDropdown
          buttonStyle={NvDropdownButtonStyle.ICON}
          iconClass='pl-1 icon-smallest icon-more'
          menuClassName='mt-5'
          items={moreDropdownItems}
          drop='left'
          altLabel={t.MENTOR_DASHBOARD.COPY_EMAIL()}
        />
      </div>
    </div>
  );
};

const ConnectedMenteeRow = connect(
  (state: RootState, ownProps: MenteeRowProps) => ({
    mentee: getOrgMentee(state, ownProps.menteeId),
  }),
)(MenteeRow);

export default ConnectedMenteeRow;
