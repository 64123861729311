import React from 'react';
import t from 'react-translate';
import { css } from '@emotion/react';

// Components
import NvFormDropdown from 'shared/components/inputs/nv-form-dropdown';

// Hooks
import useTimezones from './use-timezones';

type TimezoneDropdownProps = {
  name?: string
  disabled?: boolean
  placeholder?: string
};

const styles = css`
  .bs4-dropdown-menu {
    max-height: 250px;
    overflow-y: scroll;
  }
`;

const TimezoneDropdown = (props: TimezoneDropdownProps) => {
  const {
    name,
    disabled,
    placeholder,
  } = props;
  const timezones = useTimezones();

  return (
    <NvFormDropdown
      name={name}
      items={timezones}
      title={placeholder ?? t.TIMEZONE.TITLE()}
      disabled={disabled}
      isSmallSize
    />
  );
};

export default TimezoneDropdown;
