/* @ngInject */
export default function nvSubmitButtonValidationPopover(

  $timeout,
  $compile,
) {
  return {
    require: '^form',
    link(scope, element, attr, formCtrl) {
      element.attr('uib-popover', '{{ nvSubmitButtonValidationPopoverText }}');
      element.attr('popover-trigger', 'mouseenter');
      element.attr('popover-append-to-body', 'true');
      element.removeAttr('nv-submit-button-validation-popover');

      $timeout(() => {
        $compile(element)(scope);
      });

      element.on('mouseenter', () => {
        $timeout(() => {
          if (formCtrl.$valid) {
            scope.nvSubmitButtonValidationPopoverText = '';
          } else if (formCtrl.$error.required) {
            scope.nvSubmitButtonValidationPopoverText = 'Please fill out all of the required fields';
          }
        });
      });
    },
  };
}
