
/**
 * This method creates an array of ticks depending on the range of the slider.
 * Ticks could be separated depending on the levels.
 * Level 0: 1.
 * Level 1: 5
 * Level 2: 10.
 * Level 3: 50.
 * ...
 * Levels are calculated by dividing the range in 1, 5, 10, ... until the result is less or equals
 * to the maximium allowed number of ticks. With 15 as a default maximum, the slider still looks clear enough.
 * The way we are obtaining this is by chosing between two factors: 1 and 5
 * These two factors are obtained calculating the module 2 of the level (level % 2).
 * If it's equals to 0, then we take the factor 1. In other case we take factor 5.
 * After that, we multiply by an exponent of 10, that is equals to the integer value of the level divided by 2.
 * This is the formula:
 * selectedFactor * (10 ^ (level / 2))
 * At the end we have these results:
 * 1 * 10^0 = 1, 5 * 10^0 = 5, 1 * 10^1 = 10, ...
 */
const getTicksArray = (floor, ceil, maxTicks = 15) => {
  const ticksArray = [floor];
  const factorOne = 1;
  const factorFive = 5;
  const base = 10;
  const originalQuantity = ceil - floor;
  let level = 0;
  let selectedFactor = level % 2 === 0 ? factorOne : factorFive;
  let tenBasePow = 1;
  let spaceTicks = 1;
  let quantity = originalQuantity;
  while (quantity > maxTicks) {
    selectedFactor = level % 2 === 0 ? factorOne : factorFive;
    tenBasePow = base ** Number.parseInt(level / 2, 10);
    spaceTicks = selectedFactor * tenBasePow;
    quantity = Number.parseInt(originalQuantity / spaceTicks, 10);
    level += 1;
  }
  const differenceBetweenFloorAndSpace = floor % spaceTicks;
  const startingSpaceTick = (floor + (spaceTicks - differenceBetweenFloorAndSpace));
  for (let i = startingSpaceTick; i < ceil; i += spaceTicks) {
    ticksArray.push(i);
  }
  ticksArray.push(ceil);
  return ticksArray;
};

export default getTicksArray;
