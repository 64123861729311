/* @ngInject */
export default function NvRightClick($parse, $timeout) {
  return function (scope, element, attrs) {
    const fn = $parse(attrs.nvRightClick);
    element.bind('contextmenu', (event) => {
      $timeout(() => {
        fn(scope, { $event: event });
      });
    });
  };
}
