import { css } from '@emotion/react';
import React, { Dispatch, SetStateAction } from 'react';
import t from 'react-translate';

// Services
import { isSafari, hasGetUserMedia, hasMediaRecorder } from 'recording/services/media-stream';

// Styles
import { quarterSpacing, standardSpacing, largeSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';

// Components
import ValidationErrorMessage from 'shared/components/inputs/validation-error-message';
import NvPopover from 'shared/components/nv-popover';
import NvTooltip from 'shared/components/nv-tooltip';
import ClickableContainer from 'components/clickable-container';
import NvIcon from 'shared/components/nv-icon';
import { config } from '../../../../config/pendo.config.json';

const bgGradient = 'linear-gradient(54.55deg, #009AC0 0%, #00CCBC 58.31%, #60F3AB 100%)';
// stylis-plugin-rtl flips the linear-gradient wrongly. Also @no-flip doesn't
// work - https://github.com/styled-components/stylis-plugin-rtl/issues/16. So
// following is a hacky way to give the plugin a wrong gradient, so that it will
// flip to correct value.
const rtlBgGradient = 'linear-gradient(-54.55deg, #009AC0 100%, #00CCBC 58.31%, #60F3AB 100%)';

const styles = (browserNotSupported: boolean) => css`
  .comment-panel {
    display: flex;
    justify-content: flex-end;
    height: ${tripleSpacing}px;
    width: ${tripleSpacing}px;
    border-radius: ${largeSpacing}px;
    background: ${bgGradient};
    cursor: pointer;

    html[dir="rtl"] & {
      background: ${rtlBgGradient};
    }

    .comment-btn {
      opacity: 0;
      height: 0;
      width: 0;
    }

    .feedback-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: ${tripleSpacing}px;
      width: ${tripleSpacing}px;
      background: ${bgGradient};
      box-shadow: 0 ${quarterSpacing}px ${standardSpacing}px 0 rgba(0,202,157,0.3), 0 ${quarterSpacing}px ${standardSpacing}px 0 rgba(29,33,38,0.2);

      border-radius: 50%;
      color: #fff;
      padding-top: ${quarterSpacing}px;

      html[dir="rtl"] & {
        background: ${rtlBgGradient};
      }
    }
  }

  .expanded {
    width: 200px;

    .feedback-btn {
      opacity: 0.5;
    }
    .comment-btn {
      display: flex;
      opacity: 1;
      justify-content: center;
      align-items: center;
      height: ${tripleSpacing}px;
      width: ${tripleSpacing}px;
      color: #fff;

    }
    .video-record {
      ${browserNotSupported ? css`
        opacity: 0.5;
        cursor: initial;
      ` : 'cursor: pointer;'}
      .icon-step-record {
        height : ${standardSpacing}px;
      }
    }
  }
`;

type CommentButtonProps = {
  onTextComment: () => void
  onVideoComment: () => void
  pauseVideo: () => void
  isExpanded?: boolean
  setExpanded?: Dispatch<SetStateAction<boolean>>;
};

const NvCommentButton = ({
  onTextComment,
  onVideoComment,
  pauseVideo,
  isExpanded,
  setExpanded,
}: CommentButtonProps) => {
  const browserNotSupported = isSafari || !hasGetUserMedia() || !hasMediaRecorder();

  return (
    <div css={styles(browserNotSupported)}>
      <div className={`comment-panel ${isExpanded ? 'expanded' : ''}`}>
        {isExpanded ? (
          <React.Fragment>
            <ClickableContainer onClick={onTextComment}>
              <NvTooltip text={t.PRACTICE_ROOM.TOOLTIP.COMMENT.TEXT()}>
                <NvIcon
                  icon='admin-rich-text'
                  size='small'
                  className='comment-btn'
                  pendo-tag-name={config.pendo.practice.textComment}
                  data-qa={config.pendo.practice.textComment}
                  aria-label={t.PRACTICE_ROOM.TOOLTIP.COMMENT.TEXT()}
                />
              </NvTooltip>
            </ClickableContainer>
            <ClickableContainer
              onClick={!browserNotSupported && onVideoComment}
              disabled={browserNotSupported}
            >
              <NvPopover
                enabled={browserNotSupported}
                showOnHover
                content={(
                  <ValidationErrorMessage
                    text={t.FILE_UPLOAD.CANNOT_RECORD.BROWSER_SUPPORT()}
                  />
                )}
              >
                <NvTooltip
                  enabled={!browserNotSupported}
                  text={t.PRACTICE_ROOM.TOOLTIP.COMMENT.VIDEO()}
                >
                  <NvIcon
                    icon='step-record'
                    size='medium'
                    className='comment-btn video-record'
                    pendo-tag-name={config.pendo.practice.videoComment}
                    data-qa={config.pendo.practice.videoComment}
                    aria-label={t.PRACTICE_ROOM.TOOLTIP.COMMENT.VIDEO()}
                  />
                </NvTooltip>
              </NvPopover>
            </ClickableContainer>
            <NvIcon
              onClick={() => { pauseVideo(); setExpanded(!isExpanded); }}
              className='feedback-btn'
              icon='close'
              size='medium'
            />
          </React.Fragment>
        ) : (
          <ClickableContainer onClick={() => { pauseVideo(); setExpanded(!isExpanded); }}>
            <NvIcon
              className='feedback-btn'
              icon='comments'
              size='medium'
            />
          </ClickableContainer>
        )}
      </div>
    </div>
  );
};

export default NvCommentButton;
