import { css } from '@emotion/react';

import React, { ReactNode, ReactElement } from 'react';
import { reduce, isEmpty, sortBy, keys } from 'underscore';
import t from 'react-translate';

// Schemas
import { CommunicationErrorCode } from 'redux/schemas/models/course-communication';
import { CourseAliases } from 'redux/schemas/models/course';

// Styles
import { halfSpacing, standardSpacing, quarterSpacing, threeQuartersSpacing, tooltipZIndex } from 'styles/global_defaults/scaffolding';
import { textSmallFontSize, boldFontWeight } from 'styles/global_defaults/fonts';
import { warning } from 'styles/global_defaults/colors';

// Components
import { NvPopover } from 'shared/components/nv-popover';
import NvIcon from 'shared/components/nv-icon';

type CommunicationRowProps = {
  children: ReactNode
};

const suffixStyles = (isBold, isWarning) => css`
  ${isBold ? `font-weight: ${boldFontWeight}` : ''};
  ${isWarning ? `color: ${warning}` : ''};
`;

/* When the titles are displayed in the listing, the first character
 * is lower cased. So reusing the same translate keys used in create,
 * may be separate translations will be added later
 */
export const transformFirstCharacterToLowerCase = (title: string) => title.charAt(0).toLowerCase() + title.slice(1);

/**
 * Gets the translate key and activity name and transforms to a
 * ReactElement which has the activity name in bold
 *
 * @param {Function} titleKey - Translation method
 * @param {string} title - Activity title
 * @param {boolean} showAsThisActivity - Display activity name as this activity
 * @param {boolean} isFilter - Whether its from a filter
 * @param {CourseAliases} aliases - Course aliases
 * @param {any} values - Any additional values
 * @param {boolean} showWarning - Display any additional info as warning
 * @returns {ReactElement}
 */
export const transformToActivityTitle = (
  titleKey: Function,
  title: string,
  showAsThisActivity: boolean,
  isFilter: boolean = true,
  aliases?: CourseAliases,
  values?: any,
  showWarning?: boolean,
  isLesson: boolean = false,
) => {
  let inputs: { [key: string]: any } = {
    activity: 'ACTIVITY',
  };

  if (!isEmpty(aliases)) {
    inputs = {
      ...inputs,
      ...aliases?.learnersAliases,
    };
  }

  if (!isEmpty(values)) {
    inputs = {
      ...inputs,
      ...values,
    };
  }

  // If aliases are not there, need to spread the argument to the translate function.
  // Also arguments need to alphabetically sorted, so sorting the keys.
  const [prefix, suffix] = isEmpty(aliases)
    ? titleKey(...(sortBy(keys(inputs)).map(key => inputs[key]))).split('ACTIVITY')
    : titleKey(inputs).split('ACTIVITY');

  // For handling following scenarios
  // Manually scheduled for *12/01/2019 at 11:00 am*
  // 3 days after release date of *Join the Webinar (will be sent on 01/18/2019 10:00 am)*
  const isBold = !!values?.info || !!values?.scheduledDate;

  const showAsThisKey = showAsThisActivity && isLesson ? 'THIS_LESSON' : 'THIS_ACTIVITY';

  // Connecting it together with activity
  return (
    <React.Fragment>
      { isFilter ? transformFirstCharacterToLowerCase(prefix) : prefix }
      <span className='bold'>
        {
          showAsThisActivity
            ? t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY[showAsThisKey]()
            : title
        }
      </span>
      <span css={suffixStyles(isBold, showWarning)}>{suffix}</span>
    </React.Fragment>
  );
};

const warningPopover = (errorMessage: string) => (
  <div>
    <div className='text-center text-danger bold'>{ t.FORM.WARNING() }</div>
    <p className='mt-2 mb-0'>{ errorMessage }</p>
  </div>
);

type WarningIconWithPopoverProps = {
  errorMessage: string;
};

const popoverStyles = css`
  z-index: ${tooltipZIndex + 1};
`;

const WarningIconWithPopover = (props: WarningIconWithPopoverProps) => (
  <NvPopover
    content={warningPopover(props.errorMessage)}
    placement='top'
    preventOverflow={false}
    showOnHover
    overlayStyles={popoverStyles}
  >
    <span className='text-danger'>
      <NvIcon icon='warning' size='xss-smallest' />
    </span>
  </NvPopover>
);

// Sent communication does not need to display as error
const ErrorText = (props: WarningIconWithPopoverProps & { isSent: boolean, errorText: string, }) => (
  props.isSent
    ? (
      <span className='text-gray-3 bold'>
        { props.errorText }
      </span>
    ) : (
      <React.Fragment>
        <WarningIconWithPopover errorMessage={props.errorMessage} />
        &nbsp;
        <span className='text-danger bold'>
          { props.errorText }
        </span>
      </React.Fragment>
    )
);

/* If any related entity is missing, the title need to display a warning
 * icon, with the corresponding error. So need to inject the warning
 * icon by splitting the translated title, and then sew together
 */
export const transformToErrorTitle = (
  titleKey: Function,
  errorCode: CommunicationErrorCode,
  aliases: CourseAliases,
  isSent: boolean,
  values?: any,
  isFilter: boolean = true,
) => {
  let prefix: string;
  let suffix: string;
  let errorMessage: string;

  // Get the title from translation and then split it at the variable
  if (errorCode === CommunicationErrorCode.MISSING_COMPLETED_ACTIVITY_FILTER_ERROR
    || errorCode === CommunicationErrorCode.MISSING_NOT_COMPLETED_ACTIVITY_FILTER_ERROR) {
    // has completed /\ activity missing
    // has NOT completed /\ activity missing
    [prefix, suffix] = titleKey('ACTIVITY').split('ACTIVITY');
    prefix = transformFirstCharacterToLowerCase(prefix);
    suffix = t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.ACTIVITY_MISSING();
    errorMessage = t.COURSE_COMMUNICATIONS.WARNINGS.ACTIVITY_MISSING();
  } else if (errorCode === CommunicationErrorCode.MISSING_COMPLETED_ACTIVITY_EVENT_ERROR) {
    // Immediately after learner completed /\ activity missing
    [prefix, suffix] = titleKey({
      activity: 'ACTIVITY',
      ...aliases.learnersAliases,
      ...values,
    }).split('ACTIVITY');
    suffix = t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.ACTIVITY_MISSING();
    errorMessage = t.COURSE_COMMUNICATIONS.WARNINGS.ACTIVITY_MISSING();
  } else if ((errorCode === CommunicationErrorCode.UNABLE_TO_TRACK_FEEDBACK_COMPLETION && isFilter)
    || (errorCode === CommunicationErrorCode.UNABLE_TO_TRACK_THIRD_PARTY_COMPLETION && isFilter)
    || errorCode === CommunicationErrorCode.UNABLE_TO_TRACK_COMPLETED_ACTIVITY_FEEDBACK_COMPLETION
    || errorCode === CommunicationErrorCode.UNABLE_TO_TRACK_NOT_COMPLETED_ACTIVITY_FEEDBACK_COMPLETION
    || errorCode === CommunicationErrorCode.UNABLE_TO_TRACK_COMPLETED_ACTIVITY_THIRD_PARTY_COMPLETION
    || errorCode === CommunicationErrorCode.UNABLE_TO_TRACK_NOT_COMPLETED_THIRD_PARTY_COMPLETION) {
    // has completed /\ activity missing
    // has NOT completed /\ activity missing
    [prefix, suffix] = titleKey('ACTIVITY').split('ACTIVITY');
    prefix = transformFirstCharacterToLowerCase(prefix);
    suffix = t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.ACTIVITY_MISSING();
    errorMessage = t.COURSE_COMMUNICATIONS.WARNINGS.UNABLE_TO_TRACK_COMPLETION(aliases.learnersAliases);
  } else if (errorCode === CommunicationErrorCode.UNABLE_TO_TRACK_EVENT_FEEDBACK_COMPLETION
    || errorCode === CommunicationErrorCode.UNABLE_TO_TRACK_EVENT_THIRD_PARTY_COMPLETION
    || errorCode === CommunicationErrorCode.UNABLE_TO_TRACK_FEEDBACK_COMPLETION
    || errorCode === CommunicationErrorCode.UNABLE_TO_TRACK_THIRD_PARTY_COMPLETION) {
    // Immediately after learner completed /\ activity missing
    [prefix, suffix] = titleKey({
      activity: 'ACTIVITY',
      ...aliases.learnersAliases,
      ...values,
    }).split('ACTIVITY');
    suffix = t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.ACTIVITY_MISSING();
    errorMessage = t.COURSE_COMMUNICATIONS.WARNINGS.UNABLE_TO_TRACK_COMPLETION(aliases.learnersAliases);
  } else if (errorCode === CommunicationErrorCode.COURSE_POINTS_DISABLED) {
    // has earned more than /\ 12 points

    [prefix, suffix] = titleKey({
      warningIcon: 'ICON',
      input: values.input,
      ...aliases.learnersAliases,
      ...aliases.pointsAliases,
    }).split('ICON');
    prefix = isFilter ? transformFirstCharacterToLowerCase(prefix) : prefix;
    errorMessage = t.COURSE_COMMUNICATIONS.WARNINGS.POINTS_DISABLED(aliases.pointsAliases);
  } else if (errorCode === CommunicationErrorCode.COURSE_NO_TODO_EXERCISES) {
    // completed less than /\ 5 assignments that are marked as to-dos
    [prefix, suffix] = titleKey({
      warningIcon: 'ICON',
      input: values.input,
      ...aliases.learnersAliases,
      ...aliases.assignmentAliases,
    }).split('ICON');
    prefix = isFilter ? transformFirstCharacterToLowerCase(prefix) : prefix;
    errorMessage = t.COURSE_COMMUNICATIONS.WARNINGS.NO_TODO_EXERCISES(aliases.assignmentAliases);
  } else if (errorCode === CommunicationErrorCode.COURSE_ROLE_DELETED_FILTER_ERROR) {
    // has No Roles, Custom Student Roles or /\
    errorMessage = t.COURSE_COMMUNICATIONS.WARNINGS.ROLE_MISSING();
    suffix = t.COURSE_COMMUNICATIONS.FILTERS.ROLES.ROLE_MISSING();

    // Its bit different for roles, as there can multiple roles missing.
    // So split it into parts and then sew with warning icon
    const parts = transformFirstCharacterToLowerCase(titleKey(values.rolesText))
      .split('{ROLE_MISSING_ERROR}')
      .map((part) => part.replace(/^\s+|\s+$/g, ''));

    // Returning from here itself after joining
    return reduce(parts, (prev: ReactElement, part) => (
      <React.Fragment>
        { prev }
        &nbsp;
        <ErrorText errorMessage={errorMessage} isSent={isSent} errorText={suffix} />
        &nbsp;
        { part }
      </React.Fragment>
    ));
  } else if (errorCode === CommunicationErrorCode.ACTIVITY_DUE_DATE_REMOVED
    || errorCode === CommunicationErrorCode.MISSING_PROFILE_COMPLETION_REQUIRED_QUESTIONS
    || errorCode === CommunicationErrorCode.MISSING_COMPLETED_PROFILE_COMPLETION_REQUIRED_QUESTIONS
    || errorCode === CommunicationErrorCode.MISSING_NOT_COMPLETED_PROFILE_COMPLETION_REQUIRED_QUESTIONS
    || errorCode === CommunicationErrorCode.MISSING_EVENT_PROFILE_COMPLETION_REQUIRED_QUESTIONS) {
    // On due date of <b>this activity</b> /\ due date missing
    // Has completed <b>this activity</b> /\ required questions missing

    const inputs = errorCode !== CommunicationErrorCode.ACTIVITY_DUE_DATE_REMOVED && isFilter
      ? 'ACTIVITY'
      : { ...values, activity: 'ACTIVITY' };
    let title: string;

    if (errorCode === CommunicationErrorCode.ACTIVITY_DUE_DATE_REMOVED) {
      title = titleKey(...(sortBy(keys(inputs)).map(key => inputs[key])));
      errorMessage = t.COURSE_COMMUNICATIONS.WARNINGS.DUE_DATE_MISSING();
    } else {
      title = titleKey(inputs);
      errorMessage = t.COURSE_COMMUNICATIONS.WARNINGS.REQUIRED_QUESTIONS_MISSING();
    }

    [prefix, suffix] = title.split('ACTIVITY');
    const [activitySuffix, error] = suffix.split('WARNING_ICON');

    // Connecting it together
    return (
      <React.Fragment>
        { prefix }
        &nbsp;
        <span className='bold'>
          { values.activity }
        </span>
        &nbsp;
        { activitySuffix }
        &nbsp;
        <ErrorText errorMessage={errorMessage} isSent={isSent} errorText={error} />
      </React.Fragment>
    );
  }
  switch (errorCode) {
    case CommunicationErrorCode.MISSING_ENROLLED_COURSE_FILTER_ERROR:
      prefix = transformFirstCharacterToLowerCase(titleKey('COURSE'));
      suffix = t.JOURNEY_COMMUNICATIONS.FILTERS.COURSE_ENROLLMENT.COURSE_MISSING();
      errorMessage = t.JOURNEY_COMMUNICATIONS.WARNINGS.COURSE_MISSING();
      break;
    case CommunicationErrorCode.MISSING_NOT_ENROLLED_COURSE_FILTER_ERROR:
      prefix = transformFirstCharacterToLowerCase(titleKey('COURSE'));
      suffix = t.JOURNEY_COMMUNICATIONS.FILTERS.COURSE_ENROLLMENT.COURSE_MISSING();
      errorMessage = t.JOURNEY_COMMUNICATIONS.WARNINGS.COURSE_MISSING();
      break;
    case CommunicationErrorCode.MISSING_COMPLETED_COURSE_FILTER_ERROR:
      prefix = transformFirstCharacterToLowerCase(titleKey('COURSE'));
      suffix = t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COURSE.COURSE_MISSING();
      errorMessage = t.JOURNEY_COMMUNICATIONS.WARNINGS.COURSE_MISSING();
      break;
    case CommunicationErrorCode.MISSING_NOT_COMPLETED_COURSE_FILTER_ERROR:
      prefix = transformFirstCharacterToLowerCase(titleKey('COURSE'));
      suffix = t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COURSE.COURSE_MISSING();
      errorMessage = t.JOURNEY_COMMUNICATIONS.WARNINGS.COURSE_MISSING();
      break;
    case CommunicationErrorCode.MISSING_COMPLETED_COLLECTION_FILTER_ERROR:
      prefix = transformFirstCharacterToLowerCase(titleKey('COLLECTION'));
      suffix = t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COLLECTION.COLLECTION_MISSING();
      errorMessage = t.JOURNEY_COMMUNICATIONS.WARNINGS.COLLECTION_MISSING();
      break;
    case CommunicationErrorCode.MISSING_NOT_COMPLETED_COLLECTION_FILTER_ERROR:
      prefix = transformFirstCharacterToLowerCase(titleKey('COLLECTION'));
      suffix = t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COLLECTION.COLLECTION_MISSING();
      errorMessage = t.JOURNEY_COMMUNICATIONS.WARNINGS.COLLECTION_MISSING();
      break;
    default:
      break;
  }


  // Removing the <b> tag if any and also removing the spaces around
  prefix = prefix.replace(/\s*<b>|\s+$/g, '');
  suffix = suffix.replace(/^\s+|\s*<\/b>/g, '');

  // Connecting it together
  return (
    <React.Fragment>
      { prefix }
      &nbsp;
      <ErrorText errorMessage={errorMessage} isSent={isSent} errorText={suffix} />
    </React.Fragment>
  );
};

const styles = css`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding-top: ${standardSpacing}px;
  padding-left: ${standardSpacing + threeQuartersSpacing}px;
  position: relative;

  &:last-child {
    padding-bottom: ${standardSpacing}px;
  }

  .communication-badge {
    position: absolute;
    top: 0;
    left: ${quarterSpacing}px;
    padding: 0 3px;
    text-transform: uppercase;
    font-weight: ${boldFontWeight};
    color: white;
    font-size: ${textSmallFontSize}px;
    font-style: normal;
  }

  .content-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;

    .content {
      padding: 0 ${standardSpacing}px;
      margin: 0;
      width: calc( 100% - ${4 * standardSpacing}px);

      .activity {
        font-weight: ${boldFontWeight};
      }
    }
  }

  .recipient-icons {
    position: relative;
    left: ${quarterSpacing}px;
  }

  .icons-wrapper {
    width: 50px;
    display: flex;
    flex-direction: row-reverse;

    .icon {
      padding-left: ${halfSpacing}px;
    }
  }

  .popover-wrapper {
    display: inline-block;
  }
`;

const CommunicationRow = (props: CommunicationRowProps) => (
  <div css={styles}>
    {
      props.children
    }
  </div>
);

export default CommunicationRow;
