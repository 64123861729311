import { css } from '@emotion/core';
import { useContext, useEffect, useState } from 'react';
import t from 'react-translate';
import { ColorPickerContent } from 'shared/components/inputs/color-picker';
import { gray7, gray4, getColorBrightness, gray6, primary } from 'styles/global_defaults/colors';
import { AngularServicesContext } from 'react-app';
import tinycolor from 'tinycolor2';
import {
  doubleSpacing,
  halfSpacing,
  standardSpacing,
  threeQuartersSpacing,
  tripleSpacing } from 'styles/global_defaults/scaffolding';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Components
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { ReactComponent as SimpleColorPickerIcon } from 'styles/icons/simple-color-picker.svg';
import { updateOrgLevelColors } from 'redux/actions/org-level-colors';
import NvPopover from 'shared/components/nv-popover';
import NvTooltip from 'shared/components/nv-tooltip';
import { config } from '../../../config/pendo.config.json';

const styles = css`
 .body-header {
    height: ${doubleSpacing}px;
 }
 .default-colors-container {
    width: 720px;
    .grid-container {
      display: grid;
      grid-template-columns: repeat(7, auto);
      justify-content: space-between;
      grid-row-gap: ${standardSpacing}px;
    }
 }
 .highlight-colors-container {
    width: 720px;
    .flex-container {
      gap: ${threeQuartersSpacing}px;
    }
 }
 .button-container {
    gap: ${halfSpacing}px;
    justify-content: center;
 }
`;

interface ColorProps {
  colour: string
  setDefaultColors?: Function
  setHighlightColors?: Function
}
/**
 * will be used to determine when to add border to the color button
 * border for the color button is given based on the brightness
 * For more information, See the <ColorButton /> implementation
 */
const DEFAULT_BORDER_COLOR = gray6;
const DEFAULT_BORDER_COLOR_BRIGHTNESS = getColorBrightness(DEFAULT_BORDER_COLOR);

const ColorPickContainer = (colorProps: ColorProps) => {
  const { colour, setDefaultColors, setHighlightColors } = colorProps;
  const [inputcolor, setInputColor] = useState(colour.substring(1));
  const [showPopover, setShowPopover] = useState(false);
  let borderWidth = 0;
  const colorBrightness = colour ? getColorBrightness(colour) : 0;
  if (colorBrightness > DEFAULT_BORDER_COLOR_BRIGHTNESS) {
    borderWidth = 1;
  }
  const getColorStyle = (hexColor: string) => (
    css`
    & {
      width: 90px;
      height: ${doubleSpacing}px;
      border: solid;
      border-color: ${gray4};
      border-width: thin;
    }
    .color-circle {
      background-color: ${hexColor};
      border-radius: 50%;
      width: ${standardSpacing}px;
      height: ${standardSpacing}px;
      border-width: ${borderWidth}px;
      border-color: ${DEFAULT_BORDER_COLOR};
      border-style: ${borderWidth ? 'solid' : 'none'};
    }
    input {
      border: none !important;
      padding-left: 4px;
      width: ${tripleSpacing}px;
    }
    input:focus {
      outline: none;
    }
    &:focus-within {
      border-color: ${primary};
    }
    `
  );

  const getIsValueValid = (color) => tinycolor(color).isValid()
    && color.charAt(0) === '#'
    /**
     * With this, we exclude 4 and 8 character hex codes, given that we don't
     * use transparency.
     */
    && (color.length === 7 || color.length === 4);

  const handlePickerChange = (e) => {
    if (setDefaultColors) {
      setDefaultColors((prevState) => {
        const prevDefaultColours = [...prevState];
        const colorIndex = prevDefaultColours.findIndex((eachColor) => eachColor === colour);
        prevDefaultColours[colorIndex] = e.toUpperCase();
        if (!getIsValueValid(e)) {
          setInputColor(colour.substring(1));
          return [...prevState];
        }
        setInputColor(e.toUpperCase().substring(1));
        return prevDefaultColours;
      });
    } else {
      setHighlightColors((prevState) => {
        const prevHighlightColours = [...prevState];
        const colorIndex = prevHighlightColours.findIndex((eachColor) => eachColor === colour);
        prevHighlightColours[colorIndex] = e.toUpperCase();
        if (!getIsValueValid(e)) {
          setInputColor(colour.substring(1));
          return [...prevState];
        }
        setInputColor(e.toUpperCase().substring(1));
        return prevHighlightColours;
      });
    }
  };

  return (

    <div
      css={getColorStyle(colour)}
      className='d-flex align-items-center px-1'
    >

      <NvPopover
        content={(
          <ColorPickerContent
            onChange={handlePickerChange}
            className='color-picker-content'
            color={colour}
            showRecentlyUsedColors={false}
          />
        )}
        rootClose
        placement='right'
        offset={70}
        show={showPopover}
        onHide={() => setShowPopover(false)}
      >
        <NvTooltip
          text={t.INSTITUTIONS.ADVANCED_SETTINGS.COLOR_PALETTE.MODAL.CLICK_TO_EDIT()}
          enabled={!showPopover}
        >
          <div
            onClick={() => setShowPopover(!showPopover)}
            data-qa={config.pendo.orgColors.openColorPicker}
            className='color-circle'
          />
        </NvTooltip>
      </NvPopover>
      <input
        value={inputcolor}
        className='text-body'
        onBlur={(e) => handlePickerChange(`#${e.target.value}`)}
        onChange={(e) => setInputColor(e.target.value)}
      />
    </div>
  );
};

const OrgColorPaletteConfigureModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [defaultColors, setDefaultColors] = useState([]);
  const [highlightColors, setHighlightColors] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    InstitutionsManager,
  } = useContext(AngularServicesContext);

  const dispatch = useDispatch();
  const currentInstitutionId: number = useSelector((state) => state.app.currentInstitutionId);
  const mainColorsObject = useSelector((state) => state.models.institutions[currentInstitutionId].orgColors?.mainColors);
  const highlightColorsObject = useSelector((state) => state.models.institutions[currentInstitutionId].orgColors?.highlightColors);

  useEffect(() => {
    if (mainColorsObject) {
      const defaultColorsArray = Object.values(mainColorsObject);
      const highlightColorsArray = Object.values(highlightColorsObject);
      setDefaultColors([...defaultColorsArray]);
      setHighlightColors([...highlightColorsArray]);
    }
  }, [highlightColorsObject, mainColorsObject]);

  useEffect(() => {
    props.forwardShowModal(() => setShowModal(true));
  }, []);

  useEffect(() => {
    const modalElem = document.querySelector('.color-modal');
    if (modalElem) {
      modalElem.removeAttribute('tabindex');
    }
  }, [showModal]);

  const handleModalClose = () => {
    setShowModal(false);
    setDefaultColors([...Object.values(mainColorsObject)]);
    setHighlightColors([...Object.values(highlightColorsObject)]);
  };

  const handleSubmit = () => {
    let mainColorPayload = mainColorsObject;
    let highlightColorPayload = highlightColorsObject;
    defaultColors.forEach((eachColor, i) => {
      mainColorPayload = { ...mainColorPayload, [Object.keys(mainColorsObject)[i]]: eachColor };
    });
    highlightColors.forEach((eachColor, i) => {
      highlightColorPayload = { ...highlightColorPayload, [Object.keys(highlightColorsObject)[i]]: eachColor };
    });
    const payload = {
      institutionId: currentInstitutionId, mainColors: mainColorPayload, highlightColors: highlightColorPayload,
    };
    setLoading(true);
    dispatch(updateOrgLevelColors(payload)).then((res) => {
      setLoading(false);
      setShowModal(false);

      /**
       * Updating InstitutionManager of AngularJS state. Because in institution dashboard,
       * redux store collects data from InstitutionManager
       */
      InstitutionsManager.institution.updateFromReact({
        orgColors: { ...res.payload.orgColors },
      });
    });
  };

  const ModalBody = () => (
    <div className='px-4' css={styles}>
      <div className='bg-gray-7 body-header d-flex align-items-center'>
        <SimpleColorPickerIcon
          className='mx-2'
          width={standardSpacing}
          height={standardSpacing}
        />
        <p className='text-large-regular font-weight-bolder mb-0'>
          {t.INSTITUTIONS.ADVANCED_SETTINGS.COLOR_PALETTE.MODAL.BODY_HEADER()}
        </p>
      </div>
      <p className='mt-4 mb-0 text-body'>
        {t.INSTITUTIONS.ADVANCED_SETTINGS.COLOR_PALETTE.MODAL.DESCRIPTION()}
      </p>
      <div className='default-colors-container mt-5'>
        <p className='font-weight-bolder text-small'>
          {t.INSTITUTIONS.ADVANCED_SETTINGS.COLOR_PALETTE.MODAL.DEFAULT_COLOR()}
        </p>
        <div className='grid-container mt-4'>
          {defaultColors.map((eachColor) => (
            <ColorPickContainer
              setDefaultColors={setDefaultColors}
              colour={eachColor}
            />
          ))}
        </div>
      </div>
      <div className='highlight-colors-container mt-5'>
        <p className='font-weight-bolder text-small'>
          {t.INSTITUTIONS.ADVANCED_SETTINGS.COLOR_PALETTE.MODAL.HIGHLIGHT_COLOR()}
        </p>
        <div className='d-flex flex-container mt-4'>
          {highlightColors.map((eachColor) => (
            <ColorPickContainer
              setHighlightColors={setHighlightColors}
              colour={eachColor}
            />
          ))}
        </div>
      </div>
      <div className='button-container d-flex align-items-center mt-8 mb-4'>
        <button
          className='btn btn-default'
          type='button'
          onClick={handleModalClose}
          data-qa={config.pendo.orgColors.closeConfigureOrgColorsModal}
        >
          {t.FORM.CANCEL()}
        </button>
        <button
          className='btn btn-primary'
          type='button'
          disabled={loading}
          onClick={handleSubmit}
          data-qa={config.pendo.orgColors.configureOrgColors}
        >
          {t.FORM.CONFIRM()}
        </button>
      </div>
    </div>
  );

  return (
    <NvModal
      type={ModalType.DYNAMIC}
      rootClassName='color-modal'
      width={800}
      fullHeight={false}
      header={t.INSTITUTIONS.ADVANCED_SETTINGS.COLOR_PALETTE.TITLE()}
      headerBgColor={gray7}
      body={ModalBody()}
      show={showModal}
      onClose={handleModalClose}
    />
  );
};

export default OrgColorPaletteConfigureModal;
