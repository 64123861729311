import React from 'react';
import {
  Route,
  matchPath,
  RouteProps,
  useHistory,
  BrowserRouter as Router,
  useLocation,
} from 'react-router-dom';

import NvRouterSwitch from 'nv-router/nv-switch';

type RouterWatcherProps = {
  debugMode: boolean,
  routes: RouteProps[],
  children: React.ReactElement,
};

const RouterWatcher = (props: RouterWatcherProps) => {
  const {
    routes,
    children,
    debugMode,
  } = props;
  const history = useHistory();

  React.useEffect(() => {
    let unlisten;

    if (debugMode) {
      unlisten = history.listen((location, action) => {
        /* eslint-disable no-console */
        console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`);
        console.log(`The last navigation action was ${action}`, JSON.stringify(history, null, 2));
        /* eslint-enable no-console */
      });
    }

    return () => unlisten?.();
  }, [history, debugMode]);

  React.useEffect(() => {
    const unblockHandle = history.block((location) => {
      const selectedRoute = routes.find((route) => matchPath(location.pathname, {
        path: route.path,
        exact: route.exact,
        strict: route.strict,
      }) !== null);

      if (selectedRoute?.path) {
        return undefined;
      }
      return false;
    });

    return () => unblockHandle?.();
  }, [routes, history]);

  return children;
};

/**
 * A react-router wrapper to handle the inner routes in the react world.
 */
const CustomRouter = (props: any) => {
  const {
    routes,
    children,
    debugMode,
    ...restProps
  } = props;

  return (
    <Router
      routes={routes}
      {...restProps}
    >
      <RouterWatcher
        routes={routes}
        debugMode={debugMode}
      >
        {children}
      </RouterWatcher>
    </Router>
  );
};

export type NvRouterProps = {
  routes: RouteProps[],
  basename?: string,
  createRouter?: boolean,
};

const NvRouter = ({
  routes,
  basename,
  createRouter = true,
}: NvRouterProps) => {
  const content = (
    <NvRouterSwitch>
      {routes.map((route) => (
        <Route
          path={route.path}
          exact={route.exact}
          component={route.component}
          key={route.path.toString()}
        />
      ))}
    </NvRouterSwitch>
  );

  return createRouter ? (
    <CustomRouter basename={basename} routes={routes}>
      {content}
    </CustomRouter>
  ) : content;
};

export default NvRouter;
