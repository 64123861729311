/* @ngInject */
export default function CountdownTimerModelService(
  $interval,
  moment,
  _,
) {
  const STATUS = {
    DEFAULT: 'default',
    WARNING: 'warning',
    DANGER: 'danger',
  };

  class CountdownTimerModel {
    constructor(attributes) {
      _.extend(this, attributes);

      this.timerRunning = false;
      this.showTimer = false;
      this.status = STATUS.DEFAULT;
      this.countdownInterval = null;
      this.days = null;
      this.time = {
        hours: 0,
        minutes: 0,
        seconds: 0,
      };

      this.totalDuration = this.duration * 1000; // convert to milliseconds
      this.timeLeft = this.timeRemaining * 1000;

      this.startTime = moment();

      this.startTimer();
    }

    startTimer() {
      // In case one is already active
      if (this.countdownInterval) {
        $interval.cancel(this.countdownInterval);
      }

      this.tickingCallback();
      this.countdownInterval = $interval(() => {
        this.tickingCallback();
      }, 1000);
    }

    tickingCallback() {
      const milliSecondsElapsed = moment().diff(this.startTime);
      const timeLeft = (this.timeRemaining * 1000) - milliSecondsElapsed;
      this.timeLeft = timeLeft > 0 ? timeLeft : 0;

      this.timeLeftDuration = moment.duration(this.timeLeft, 'milliseconds');

      this.days = this.timeLeftDuration.days();

      this.time.hours = this.timeLeftDuration.hours() + 24 * this.days;

      if (this.timeInterval?.toLowerCase() === 'hours') {
        this.time.minutes = this.timeLeftDuration.minutes();
      } else {
        // convert hours to minutes to be consistent with interface
        this.time.minutes = this.timeLeftDuration.minutes() + this.time.hours * 60;
      }

      this.time.seconds = this.timeLeftDuration.seconds();

      this.determineStatus();
      this.showTimer = true;

      if (this.timeLeft <= 0) {
        // If there is a callback, call it and then stop timer
        this.timeUpCallback?.();
        this.stopTimer();
      }
    }

    determineStatus() {
      if (this.timeLeft / this.totalDuration <= 0.05) {
        this.status = STATUS.DANGER;
      } else if (this.timeLeft / this.totalDuration <= 0.2) {
        this.status = STATUS.WARNING;
      } else {
        this.status = STATUS.DEFAULT;
      }
    }

    stopTimer() {
      $interval.cancel(this.countdownInterval);
    }

    hideTimer() {
      this.showTimer = false;
    }
  }

  return CountdownTimerModel;
}
