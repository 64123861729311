/* @ngInject */
export default function EvaluationsTaskResources(

  $resource,
) {
  return $resource(
    '/:catalogId/exercises/:exerciseId/custom_questions/:id.json',
    {},
    {
      get: { method: 'GET', url: '/:catalogId/exercises/:exerciseId/custom_questions' },

      getPeerReview: { method: 'GET', url: '/:catalogId/peer_reviews/:peerReviewId.json' },
      getPeerReviews: { method: 'GET', url: '/:catalogId/peer_reviews.json' },
      getNewRandomPeerReview: { method: 'GET', url: '/:catalogId/peer_reviews/assign_random_review.json?exercise_id=:exerciseId' },
      getNewSpecifiedPeerReview: { method: 'GET', url: '/:catalogId/peer_reviews/assign_requested_review.json?report_id=:reportId' },
      persistPeerReview: { method: 'PUT', url: '/:catalogId/peer_reviews/:peerReviewId.json' },
      skipPeerReview: { method: 'PUT', url: '/:catalogId/peer_reviews/:peerReviewId/skip.json' },
      markAsHelpful: { method: 'POST', url: '/:catalogId/peer_reviews/:peerReviewId/helpful.json' },
      markAsUnHelpful: { method: 'POST', url: '/:catalogId/peer_reviews/:peerReviewId/unhelpful.json' },
    },
  );
}
