// eslint-disable-next-line import/prefer-default-export
import { omit } from 'underscore';
import { schema } from 'normalizr';
import { CourseInstitution, CourseUser, Enrollment, MyAccountCourse, User } from 'redux/schemas/models/my-account';
import { BasicEnrollment, BasicMembership, BasicMentee } from '../models/course';

// TODO: The below 'any' declarations have been added dude to the addition of a _processStrategy prop
// assignment below. We need to remove these declarations and rework this file to not use process strategy.
// As a reminder, there is *never* a valid use case for the processStrategy pattern of normalizr given
// how we model data in our Redux store
const userSchema: any = new schema.Entity<User>('users');
export const enrollmentSchema = new schema.Entity<Enrollment>('enrollments');
const courseSchema = new schema.Entity<MyAccountCourse>('courses', {}, {
  idAttribute: 'catalogId',
});
const institutionSchema = new schema.Entity<CourseInstitution>('institutions');
export const myAccount = new schema.Entity('myAccount');

myAccount._processStrategy = (value) => omit({
  ...value,
  ...(value.enrollments ? { enrollmentIds: value.enrollments } : {}),
  ...(value.institution ? { institutionId: value.institution } : {}),
}, ['enrollments', 'institution']);

myAccount.define({
  enrollmentIds: [enrollmentSchema],
  institutionId: institutionSchema,
});

enrollmentSchema._processStrategy = (value) => omit({
  ...value,
  ...(value.user ? { userId: value.user } : {}),
  ...(value.course ? { courseCatalogId: value.course } : {}),
}, ['user', 'course']);

enrollmentSchema.define({
  userId: userSchema,
  courseCatalogId: courseSchema,
});

courseSchema._processStrategy = (value) => omit({
  ...value,
  ...(value.institution ? { institutionId: value.institution } : {}),
}, ['institution']);

courseSchema.define({
  institutionId: institutionSchema,
  userCourse: enrollmentSchema,
});

userSchema._processStrategy = (value) => omit({
  ...value,
  ...(value.enrollments ? { enrollmentIds: value.enrollments } : {}),
}, ['enrollments']);

userSchema.define({
  enrollmentIds: [enrollmentSchema],
});

export const userListSchema = new schema.Array(
  new schema.Object({
    user: userSchema,
  }),
);

export const basicEnrollmentSchema = new schema.Entity<BasicEnrollment>('basicenrollment');
export const basicEnrollmentsSchema = new schema.Array(basicEnrollmentSchema);

const basicMenteeSchema = new schema.Entity<BasicMentee>('basicmentee');
export const basicMenteesSchema = new schema.Array(basicMenteeSchema);

const basicMembershipSchema = new schema.Entity<BasicMembership>('basicmembership');
export const basicMembershipsSchema = new schema.Array(basicMembershipSchema);

export const courseUserSchema = new schema.Entity<CourseUser>('courseUser');
export const courseUsersSchema = new schema.Array(courseUserSchema);
