import mergeWith from 'lodash/mergeWith';
import { createReducer } from '@reduxjs/toolkit';

import { replaceArrays } from 'shared/lodash-utils';
import { normalize } from 'normalizr';
import { getSummary, getAllFeedback, addRating, deleteFeedback, resetSkillsFeedbackState } from 'redux/actions/skills-feedback';
import { SkillRatingListSchema } from 'redux/schemas/api/video-practice';
import { isEmpty, uniq, map, values } from 'underscore';
import { initialRootState } from '.';

const appStateMapping = {
  VideoPracticeSubmission: 'videoPracticeSubmissions',
  Report: 'exerciseSubmissions',
};

const modelStateMapping = {
  VideoPracticeSubmission: 'practiceSubmissions',
  Report: 'submissions',
};

const initialState = {
  summary: [],
  summaryOnceLoaded: false,
  commentsOnceLoaded: false,
  commentsLoading: false,
  feedbackOnceLoaded: false,
  feedbackLoading: false,
  feedbackByUserOnceLoaded: false,
  feedbackByUserLoading: false,
  skillTaggings: {},
  authorFeedback: {
    ratings: [],
    createdAt: '',
  },
  ratingsByUser: {},
  mentionables: {
    isLoading: false,
    onceLoaded: false,
    userIds: [],
  },
};

const skillsFeedbackReducer = createReducer(initialRootState, builder => {
  builder
    .addCase(resetSkillsFeedbackState, (state, action) => {
      const { ownerId, ownerType } = action.payload;
      const key = appStateMapping[ownerType];

      if (state.app[key]?.[ownerId]) {
        state.app[key][ownerId] = {
          ...initialState,
        };
      }
    })
    .addCase(getSummary.pending, (state, action) => {
      const { ownerId, ownerType } = action.meta.arg;
      const key = appStateMapping[ownerType];

      if (!state.app[key]?.[ownerId]) {
        state.app[key][ownerId] = {
          ...initialState,
        };
      }
    })
    .addCase(getSummary.fulfilled, (state, action) => {
      const { ownerId, ownerType } = action.meta.arg;
      const key = appStateMapping[ownerType];

      if (action.payload) {
        state.app[key][ownerId].summaryOnceLoaded = true;
        state.app[key][ownerId].summary = action.payload;
      }
    })
    .addCase(getAllFeedback.pending, (state, action) => {
      const { skillTaggingId, ownerId, userId, ownerType } = action.meta.arg;
      const key = appStateMapping[ownerType];

      if (!state.app[key]?.[ownerId]) {
        state.app[key][ownerId] = {
          ...initialState,
          feedbackLoading: true,
        };
      }
      state.app[key][ownerId].feedbackLoading = true;

      if (userId) {
        state.app[key][ownerId].feedbackByUserLoading = true;
        if (!state.app[key]?.[ownerId].ratingsByUser) {
          state.app[key][ownerId].ratingsByUser = {};
        }
      } else {
        if (!state.app[key]?.[ownerId]?.authorFeedback) {
          state.app[key][ownerId].authorFeedback = {
            ratings: [],
            createdAt: '',
          };
        }
        state.app[key][ownerId].feedbackOnceLoaded = !!state.models[modelStateMapping[ownerType]][ownerId]?.isRatedByCurrentUser
        && !isEmpty(state.app[key]?.[ownerId]?.authorFeedback?.ratings);
      }

      if (skillTaggingId) {
      /**
       * skillTagId and skillTaggingId are different
       * skillTagId associated with institution, where
       * skillTaggingId associated with scenario
       */
        if (!state.app[key]?.[ownerId]?.skillTaggings) {
          state.app[key][ownerId].skillTaggings = {};
        }
        if (!state.app[key]?.[ownerId].skillTaggings?.[skillTaggingId]) {
          state.app[key][ownerId].skillTaggings[skillTaggingId] = {
            onceLoaded: false,
            loading: true,
            ratings: [],
            totalRecords: 0,
            hasMore: false,
          };
        }
        state.app[key][ownerId].skillTaggings[skillTaggingId].loading = true;
      }
    })
    .addCase(getAllFeedback.fulfilled, (state, action) => {
      if (action.payload?.ratings) {
        const { skillTaggingId, ownerId, userId, ownerType } = action.meta.arg;
        const key = appStateMapping[ownerType];

        const data = normalize(action.payload.ratings, SkillRatingListSchema);
        if (data.entities?.skillRatings) {
          Object.entries(data.entities.skillRatings).forEach(([ratingId, rating]) => {
            data.entities.skillRatings[ratingId].ownerId = ownerId;
          });
        }
        mergeWith(state.models.skillRatings, data.entities.skillRatings, replaceArrays);
        if (skillTaggingId) {
          const feedback = state.app[key]?.[ownerId].skillTaggings[skillTaggingId];
          feedback.onceLoaded = true;
          feedback.loading = false;
          feedback.ratings = uniq([...feedback.ratings, ...data.result]);
          feedback.totalRecords = action.payload.totalRecords;
          feedback.hasMore = action.payload?.totalRecords - feedback.ratings?.length > 0;
        } else if (userId) {
          state.app[key][ownerId].feedbackByUserOnceLoaded = true;
          state.app[key][ownerId].feedbackByUserLoading = false;
          state.app[key][ownerId].ratingsByUser[userId] = data.result;
        } else {
          state.app[key][ownerId].authorFeedback.ratings = data.result;
          if (state.app[key][ownerId].authorFeedback.ratings <= 0 && state.models.practiceSubmissions[ownerId]) {
            state.models.practiceSubmissions[ownerId].isRatedByCurrentUser = false;
          }
          state.app[key][ownerId].authorFeedback.createdAt = action.payload.ratings?.[0]?.createdAt;
          state.app[key][ownerId].feedbackOnceLoaded = true;
          state.app[key][ownerId].feedbackLoading = false;
        }
      }
    })
    .addCase(addRating.fulfilled, (state, action) => {
      const { ownerId, ownerType, feedbackCriteriaId } = action.meta.arg;

      if (action.payload) {
        state.models[modelStateMapping[ownerType]][ownerId].isRatedByCurrentUser = true;
        if (ownerType === 'VideoPracticeSubmission' && state.models.submissions?.[ownerId]) {
          state.models.submissions[ownerId].isRatedByCurrentUser = true;
        }

        if ((ownerType === 'Report' || ownerType === 'VideoPracticeSubmission') && action.payload.skillsRating && feedbackCriteriaId) {
          const skillRatingActivity = state.models.exerciseSkillsRatingActivities[feedbackCriteriaId];
          if (skillRatingActivity) {
            skillRatingActivity.progress = action.payload.skillsRating.progress;
            skillRatingActivity.pointsReceived = action.payload.skillsRating.pointsReceived;
            skillRatingActivity.numReviewesCompleted = action.payload.skillsRating.numReviewesCompleted;
          }
        }
      }
    })
    .addCase(deleteFeedback.fulfilled, (state, action) => {
      if (action.payload) {
        const { ownerId, userId, ownerType, feedbackCriteriaId } = action.meta.arg;
        const key = appStateMapping[ownerType];

        map(values(state.models.skillRatings).filter(rating => rating.user.id === userId && rating.ownerId === ownerId), rating => {
          if (rating.id && state.models.skillRatings[rating.id] && rating.ownerId === ownerId) {
            delete state.models.skillRatings[rating.id];
          }
        });
        const updatedSkillTaggings: {
          [id: string]: {
            onceLoaded: boolean;
            ratings: number[];
            totalRecords: number;
            loading: boolean;
            hasMore: boolean;
          }
        } = {};
        map(state.app[key]?.[ownerId].skillTaggings, (skillTagging, id) => {
          updatedSkillTaggings[id] = {
            ...skillTagging,
            ratings: skillTagging.ratings.filter(ratingId => (!!state.models.skillRatings?.[ratingId])),
          };
        });
        state.app[key][ownerId].skillTaggings = updatedSkillTaggings;
        if (userId === state.app.currentUserId) {
          state.models[modelStateMapping[ownerType]][ownerId].isRatedByCurrentUser = false;
          if (ownerType === 'VideoPracticeSubmission' && state.models.submissions?.[ownerId]) {
            state.models.submissions[ownerId].isRatedByCurrentUser = false;
          }
          state.app[key][ownerId].authorFeedback.ratings = [];
        }

        if ((ownerType === 'Report' || ownerType === 'VideoPracticeSubmission') && action.payload.skillsRating && feedbackCriteriaId) {
          const skillRatingActivity = state.models.exerciseSkillsRatingActivities[feedbackCriteriaId];
          if (skillRatingActivity) {
            skillRatingActivity.progress = action.payload.skillsRating.progress;
            skillRatingActivity.pointsReceived = action.payload.skillsRating.pointsReceived;
            skillRatingActivity.numReviewesCompleted = action.payload.skillsRating.numReviewesCompleted;
          }
        }
      }
    });
});

export default skillsFeedbackReducer;
