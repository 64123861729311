import { css } from '@emotion/react';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

// Schemas
import { HasLoggedInFilterType } from 'redux/schemas/models/course-communication';

// Components
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import NvRadioButton from 'shared/components/inputs/nv-radio-button';

// Styles
import baseStyles from './base-styles';

type LabelOnlyProps = {
  name: string, // Not much needed, just keeping it here for css
  checkboxName?: string,
  // If there is an event value, then this is considered as a event
  // If not, then this is a filter
  eventValue?: string,
  label: string,
  info?: string,
};

const styles = (name: string) => css`
  &.${name} {
    ${baseStyles};
  }
`;

/**
 * A generic filter which is display with a label along with a
 * checkbox / radio
 */
const LabelOnly = ({
  name,
  checkboxName,
  eventValue,
  label,
  info,
}: LabelOnlyProps) => {
  const ref = useRef<HTMLInputElement>();

  // If its an event, watch the value of 'event' and if its equal to
  // the eventValue given, then this is enabled
  // If its a filter, watch the value of the checkbox and if its checked
  // then this is enabled
  const { watch } = useFormContext();
  const fieldValue = eventValue ? watch('event') : watch(checkboxName);
  const isEnabled = eventValue ? fieldValue === eventValue : fieldValue;

  // Disabled this filter if has not logged in since course release date or start date selected
  const [hasLoggedInChecked, hasLoggedIn] = watch(['hasLoggedInChecked', 'hasLoggedIn']);
  const isFilterDisabled: boolean = (hasLoggedInChecked
    && (hasLoggedIn?.value === HasLoggedInFilterType.HAS_NOT_SINCE_START_DATE
      || hasLoggedIn?.value === HasLoggedInFilterType.HAS_NOT_SINCE_RELEASE_DATE));

  return (
    <div css={styles(name)} className={`${name} ${isEnabled ? 'on' : ''} ${isFilterDisabled ? 'disabled' : ''}`}>
      <div className='checkbox-wrapper'>
        {eventValue ? (
          <NvRadioButton
            withForm
            value={eventValue}
            name='event'
            label={label}
            labelClassName='text-large-regular pl-6'
          />
        ) : (
          <NvCheckbox
            withForm
            name={checkboxName}
            label={label}
            labelClassName='text-large-regular pl-6'
            disabled={isFilterDisabled}
          />
        )}
      </div>
      {info && <div className='info text-small text-gray-2 font-weight-bold'>{info}</div>}
    </div>
  );
};

LabelOnly.defaultProps = {
  checkboxName: null,
  eventValue: null,
  info: '',
};

export default LabelOnly;
