import { css } from '@emotion/react';
import { gray5, danger, gray3 } from 'styles/global_defaults/colors';
import {
  standardSpacing, quarterSpacing, threeQuartersSpacing, halfSpacing,
} from 'styles/global_defaults/scaffolding';
import { handheld, notHandheld } from 'styles/global_defaults/media-queries';
import React from 'react';
import NvIcon from 'shared/components/nv-icon';

export enum Mode {
  HALF_CIRCLE,
  FULL_CIRCLE,
  NESTED_FULL_CIRCLE,
}

export enum HeaderStyle {
  TOTAL_COUNT = 'Total Count',
  TOTAL_VS_ACTUAL = 'Total v. Actual',
}

/** Used in the React Testing Library tests */
export enum TestIDs {
  WEDGE1 = 'wedge1',
  WEDGE2 = 'wedge2',
  WEDGE3 = 'wedge3',
  WEDGE4 = 'wedge4',
  METER = 'meter',
}

export type RadialGaugeProps = {
  mode: Mode,
  /** Text shown in the center of the gauge for the current count */
  currentLabel?: string,
  /** Color string to be applied on the bar */
  activeColor: string,
  /** Used in full circle mode. Text shwon in the legend for the current count. */
  currentLegendLabel?: string,
  /** Used in full circle mode. Text shwon in the legend for the unhighlighted part of the circle. */
  remainingLegendLabel?: string,
  min: number,
  max: number,
  current: number,
  // Wether to show the current as the percentage of the max, or a flat value
  isPct?: boolean,
  headerStyle?: HeaderStyle,
  bkgColor?: string,
  /** If true, disables showing the current value indicators and hides the legend + total labels */
  currentDisabled?: boolean,
  /** Hides the color bar and min/max labels */
  hideMeter?: boolean,
  reverseLegendOrder?: boolean,
  /* Disables displaying all labels, including the numeric label in the center */
  labelsDisabled?: boolean,
  /** The width in pixels of the radial gauge */
  width?: number;
  /** The width in pixels of the circle meter */
  meterThickness?: number;
  /** An icon to display in the center of the circle
   * This should really be extracted into a separate component since this conflicts with the center label
   * if labelsDisabled is false */
  iconClass?: string;
};

const RadialGauge = (props: RadialGaugeProps) => {
  let { headerStyle } = props;
  let { bkgColor } = props;

  if (!headerStyle) {
    headerStyle = HeaderStyle.TOTAL_COUNT;
  }

  if (!bkgColor) {
    bkgColor = 'white';
  }

  const isFullCircle = props.mode === Mode.FULL_CIRCLE || props.mode === Mode.NESTED_FULL_CIRCLE;
  let { min, max, current } = props;

  // TODO: This is a bad hack to prevent this chart from hitting an exception when the chart is rendered before
  // data is loaded, as happens when used with <LoadingWrapper/>
  if (current === null) {
    [min, max, current] = [0, 1, 0];
  }

  const meterThickness = props.meterThickness ?? (isFullCircle ? 5 : 30); // The width of the meter bar in pixels
  const meterWidth = props.width ?? (isFullCircle ? 200 : 300); // Width of the overall gauge in pixels
  let meterHeight = !isFullCircle ? meterWidth / 2 : meterWidth;

  if (props.hideMeter) {
    meterHeight -= (meterThickness + 60);
  }

  const usedPct = current / max;

  /**
   * These set the rotate and skew properties of the four wedge-shaped regions that are drawn to color
   * in the "unused", gray portion of the graphic.
   */
  /** 'circle factor' */
  const cf = isFullCircle ? 1.0 : 0.5;

  // At -90 rotation, wedge starts at the left exreme of the half-circle meter, or the top of the full circle meter
  // TODO: Document further
  const rotate1 = -90;
  const skew1 = -90 + Math.min(360 * usedPct * cf, 90); // rotate1 >= 180 ? 180-rotate1 : 0; // -90 <-> 90

  const rotate2 = 0;
  const skew2 = Math.max(-90 + Math.min(360 * (usedPct * cf - 0.25), 90), -90);
  const rotate3 = 90;
  const skew3 = Math.max(-90 + Math.min(360 * (usedPct * cf - 0.50), 90), -90);
  const rotate4 = 180;
  const skew4 = Math.max(-90 + Math.min(360 * (usedPct * cf - 0.75), 90), -90);

  // console.log(`skew1: ${skew1}`);
  // console.log(`skew2: ${skew2}`);
  // console.log(`skew3: ${skew3}`);
  // console.log(`skew4: ${skew4}`);

  let meterColor = props.activeColor;

  if (!isFullCircle && usedPct * 100 > 100) {
    meterColor = danger;
  }

  if (props.currentDisabled) {
    meterColor = gray5;
  }

  let innerCircleWidth = meterWidth - meterThickness * 2;
  const innerCircleOffset = meterThickness;
  // Only used by the second inner circle
  const originalInnerCircleWidth = innerCircleWidth;
  const originalInnerCircleOffset = innerCircleOffset;

  if (props.mode === Mode.NESTED_FULL_CIRCLE) {
    innerCircleWidth -= meterThickness * 4;
  }

  const wedgeDisplayStyle = (skew: number) => ((skew === -90 || Number.isNaN(skew1)) ? 'display: none;' : '');

  const styles = css`
    ${isFullCircle ? css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    ` : ''};

    position: relative;
    .meter-container-container {
      position: relative;
      display: inline-block;
    }

    .meter-container {
      display: inline-block;
      position: relative;

      height: ${meterHeight}px;
      overflow: hidden;

      width: ${meterWidth}px;
    }

    .meter {
      display: ${props.hideMeter ? 'none' : 'flex'};
      align-items: center;
      height: ${meterWidth}px;

      border-radius: 50%;
      /* Required due to a longstanding bug in Safari where overflow hidden doesn't apply to curved corners */
      mask-image: radial-gradient(white, black);
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      background: ${gray5};
      position: relative;
      overflow: hidden;
      margin-left: auto;
      margin-right: auto;

      /* Rotate the entire meter by 90 degrees for full circle mode, since those visually indicated
      0 fill at '12 o-clock' */
      transform: rotate(${isFullCircle ? 90 : 0}deg);
    }

    /* Add no styles to the inner meter div if not rendering a nested circle, to prevent mis aligning, clipping, etc */
    .inner-meter {
      ${props.mode === Mode.NESTED_FULL_CIRCLE ? css`
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      height: 90%;
      /* Required due to a longstanding bug in Safari where overflow hidden doesn't apply to curved corners */
      border-radius: 90%;
      mask-image: radial-gradient(white, black);
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      overflow: hidden;
      position: absolute;
      left: 5%;
      top: 5%;
      ` : ''};
    }

    .inner-circle {
      position: absolute;
      top: ${innerCircleOffset}px;
      /* Center the inner circle so that it matches .meter's auto side margin centering */
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: ${innerCircleWidth}px;
      height: ${innerCircleWidth}px;
      border-radius: 50%;
      background: ${bkgColor};
    }

    /** Used as the outer mask for nested full circles */
    .second-inner-circle {
      top: ${originalInnerCircleOffset}px;
      width: ${originalInnerCircleWidth}px;
      height: ${originalInnerCircleWidth}px;
      background: ${bkgColor};
      display: ${props.mode === Mode.NESTED_FULL_CIRCLE ? 'block' : 'none'};
    }

    .wedge-container {
      overflow: hidden;
      mask-image: radial-gradient(white, black);
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      position: absolute;
      top: -50%;
      right: -50%;
      width: ${props.mode === Mode.NESTED_FULL_CIRCLE ? innerCircleWidth + meterThickness * 2 : meterWidth}px;
      height: ${props.mode === Mode.NESTED_FULL_CIRCLE ? innerCircleWidth + meterThickness * 2 : meterWidth}px;
      transform-origin: 0 ${innerCircleWidth + meterThickness * 2}px;
    }

    .wedge {
      position: absolute;
      left: -100%;
      width: ${meterWidth * 2}px;
      height: ${meterWidth * 2}px;
      background: ${meterColor};
    }

    .wedge-container:first-of-type {
      transform: rotate(${rotate1}deg) skewY(${skew1}deg);
      /* this and similar .wedge blocks are commented out but left as-is for debugging */
      /* .wedge { background: red; } */
      /* Attempting to render these divs with skew === 90deg casues them to have impossibly
      large heights, only in chrome. This then obscures parts of the page that they supposedly clip */
      ${wedgeDisplayStyle(skew1)}
    }

    .wedge-container:nth-of-type(2) {
      transform: rotate(${rotate2}deg) skewY(${skew2}deg);
      /* .wedge { background: blue; } */
      ${wedgeDisplayStyle(skew2)}
    }

    .wedge-container:nth-of-type(3) {
      transform: rotate(${rotate3}deg) skewY(${skew3}deg);
      /* .wedge { background: green; } */
      ${wedgeDisplayStyle(skew3)}
    }

    .wedge-container:nth-of-type(4) {
      transform: rotate(${rotate4}deg) skewY(${skew4}deg);
      /* .wedge { background: purple; } */
      ${wedgeDisplayStyle(skew4)}
    }

    .inside-circle-panel {
      .current-count {
        /* Would be great to reference our typography styles here */
        font-size: ${isFullCircle ? 48 : 36}px;
      }
    }

    .min, .max, .total {
      display: inline;
    }

    .min, .max {
      /* position: absolute; */
      bottom: 0;
      /* TODO: For some reason I need a negative margin here to make these sit flush with the bottom.
      Not sure why, should try to remove */
      margin-bottom: -5px;
      ${props.hideMeter ? 'display: none' : ''};
    }

    /* Position this just below the left end of the radial gauge in mobile mode */
    .min {
      ${handheld(css`
        left: 0;
        position: absolute;
        margin-left: 20px;
        bottom: -25px;
      `)};
    }

    /* Same position as .min but beneath the right end of the radial gauge */
    .max {
      ${handheld(css`
        right: 0;
        position: absolute;
        margin-right: 20px;
        bottom: -25px;
      `)};
    }

    .inside-circle-panel {
      text-align: center;

      position: absolute;
      left: 0;
      right: 0;
      bottom: ${isFullCircle ? '29%' : '0'};
    }

    .min {
      left: 0;
      text-align: right;
    }

    .max {
      /* prevent colliding w/ the handheld styles above */
      ${notHandheld(css`
        right: -${standardSpacing}px;
      `)}
    }

    .total {
      margin-bottom: ${threeQuartersSpacing}px;
    }

    .legend {
      display: inline-flex;
      margin-top: ${threeQuartersSpacing}px;
      & > *:not(:last-of-type) {
        padding-right: ${standardSpacing * 2}px;
      }

      /* Draw colored boxes to indicate the legend item's color */
      & > *::before {
        display: inline-block;
        width: 10px;
        height: 10px;
        background: ${meterColor};
        margin-right: ${halfSpacing}px;
        content: ' ';
      }

      /** IE specific styles http://browserhacks.com/#hack-a60b03e301a67f76a5a22221c739dc64 */
      @media screen and (min-width:0\0) {
        justify-content: center;
      }
    }

    .legend-current::before {
      background: ${meterColor};
    }

    .legend-remaining {
      color: ${gray3};
      &::before {
        background: ${gray5};
      }
    }
  `;

  let currentText = current.toString();
  if (props.isPct) {
    if (max === 0) {
      currentText = '0';
    } else {
      currentText = Math.round((current / max) * 100).toString();
    }
    currentText += '%';
  }

  const legendDivs = props.labelsDisabled ? [] : [
    <div key='legend1' className='legend-current'>
      {props.currentLegendLabel}
    </div>,
    <div key='legend2' className='legend-remaining'>
      {props.remainingLegendLabel}
    </div>,
  ];

  if (props.reverseLegendOrder) {
    legendDivs.reverse();
  }

  return (
    <div css={styles}>
      {isFullCircle && !props.labelsDisabled && !props.currentDisabled
    && (
    <div className='total text-xl text-center font-weight-bold condensed'>
      { props.headerStyle === HeaderStyle.TOTAL_COUNT
        ? `Total: ${max}`
        : `Total vs. ${props.currentLabel}`}
    </div>
    )}
      {!isFullCircle && !props.labelsDisabled && !props.currentDisabled
    && (
    <div className='min text-large-regular font-weight-bold condensed'>
      {min}
    </div>
    )}
      <div className='meter-container-container'>
        <div className='meter-container'>
          <div className='meter' data-testid={TestIDs.METER}>
            <div className='inner-circle second-inner-circle' />
            <div className='inner-meter'>
              {/* Four pie wedges used to draw the filled progress. Each wedge can fill up to
            at most 25% of the circle */}
              <div className='wedge-container' data-testid={TestIDs.WEDGE1}>
                <div className='wedge' />
              </div>
              <div className='wedge-container' data-testid={TestIDs.WEDGE2}>
                <div className='wedge' />
              </div>
              <div className='wedge-container' data-testid={TestIDs.WEDGE3}>
                <div className='wedge' />
              </div>
              <div className='wedge-container' data-testid={TestIDs.WEDGE4}>
                <div className='wedge' />
              </div>
              <div className='inner-circle' />
            </div>
          </div>
        </div>

        <div className='inside-circle-panel d-flex flex-column justify-content-center'>
          {!props.labelsDisabled
          && (
            <React.Fragment>
              <div className='current-count font-weight-bold condensed'>{currentText}</div>
              <div className='text-large-regular font-weight-bold condensed'>{props.currentLabel}</div>
            </React.Fragment>
          )}
          {props.iconClass
            && (
            <div style={{ color: props.activeColor }}>
              <NvIcon icon={props.iconClass} size='smallest' />
            </div>
            )}
        </div>
      </div>

      {!isFullCircle && !props.labelsDisabled && !props.currentDisabled
    && (
    <div className='max text-large-regular font-weight-bold condensed'>
      {max}
    </div>
    )}

      {isFullCircle && !props.labelsDisabled && !props.currentDisabled
    && (
    <div className='legend mx-auto text-medium font-weight-bold condensed'>
      {legendDivs}
    </div>
    )}
    </div>
  );
};

export default RadialGauge;
