import { css } from '@emotion/react';
import { ReactNode, useContext, useEffect } from 'react';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

// Contexts
import { CommunicationAction, CommunicationDispatch } from 'communications/course_communications/contexts/communication-context';

// Hooks
import useCommunicationForm, { communicationDefaultState, CommunicationFormProvider } from 'communications/course_communications/hooks/use-communication-form';
import { RteTagContextProvider } from 'froala/hooks/use-tags-history';

// Schemas
import { Communication, CommunicationCategory } from 'redux/schemas/models/course-communication';

// Selectors
import { getCommunication, getCourseWelcomeEmail } from 'redux/selectors/course-communications';
import { getCurrentUser } from 'redux/selectors/users';

// Components
import { NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';
import NvSteps from 'shared/components/nv-steps';
import StepOne from './step-1';
import StepTwo from './step-2';
import StepThree from './step-3';

type CommunicationFormProps = {
  catalogId?: string,
  validationSchema: yup.ObjectSchema,
  children: ReactNode,
};

const styles = (showSteps: boolean) => css`
  padding: ${showSteps ? 40 : 0}px 80px;
  height: 100%;

  .nv-steps {
    display: flex;
    justify-content: center;
  }
`;

export const formDropdownSchema = yup.object().shape<NvDropdownTextItem>({
  id: yup.mixed(),
  type: yup.string(),
  text: yup.string(),
  value: yup.mixed(),
});

const CommunicationForm = (props: CommunicationFormProps) => {
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const catalogIdToUse = props.catalogId ?? catalogId;

  const { State, dispatch } = useContext(CommunicationDispatch);

  const initialCommunication: Communication = useSelector((state) => getCommunication(state, State.communicationId));
  const currentUser = useSelector(getCurrentUser);
  const welcomeEmail: Communication = useSelector((state) => getCourseWelcomeEmail(state, catalogIdToUse));

  const showSteps = State.communicationCategory === CommunicationCategory.SCHEDULED_EMAIL
    || State.communicationCategory === CommunicationCategory.AUTOMATED_JOURNEY_EMAIL
    || State.communicationCategory === CommunicationCategory.TRIGGERS;

  const communication = State.communicationCategory === CommunicationCategory.WELCOME_EMAIL
    ? welcomeEmail : initialCommunication;

  const communicationFormMethods = useCommunicationForm(communication ?? {
    ...communicationDefaultState,
    communicationType: State.communicationType,
    ownerId: State.activityId,
    ownerType: State.activityType,
    lecturePageId: State.lecturePageId,
    communication: {
      ...communicationDefaultState.communication,
      adminUserId: State.communicationCategory === CommunicationCategory.SCHEDULED_ANNOUNCEMENT
        ? currentUser.id
        : undefined,
    },
  }, {
    catalogId: catalogIdToUse,
    triggerType: State.triggerType,
  }, catalogIdToUse);

  const { steps, activeStep, setActiveStep, lastValidStep, isDirty } = communicationFormMethods;

  // Set this in context so that any changes are made, can be checked when
  // the modal is closed
  useEffect(() => {
    dispatch({
      type: CommunicationAction.SET_IS_DIRTY,
      isDirty,
    });
  }, [dispatch, isDirty]);

  let renderContent = null;
  switch (activeStep) {
    case 2:
      renderContent = <StepThree />;
      break;
    case 1:
      renderContent = <StepTwo />;
      break;
    default:
      renderContent = (
        <StepOne
          validationSchema={props.validationSchema}
          isAnnouncement={State.communicationCategory === CommunicationCategory.SCHEDULED_ANNOUNCEMENT}
        >
          {props.children}
        </StepOne>
      );
      break;
  }

  return (
    <CommunicationFormProvider {...communicationFormMethods}>
      <RteTagContextProvider>
        <div css={styles(showSteps)}>
          {showSteps && (
            <NvSteps
              active={activeStep}
              steps={steps}
              onChange={setActiveStep}
              lastValidStep={lastValidStep}
            />
          )}
          {renderContent}
        </div>
      </RteTagContextProvider>
    </CommunicationFormProvider>
  );
};

export default CommunicationForm;
