/* @ngInject */
export default function NvVideoDownload($timeout, $parse) {
  return {
    restrict: 'A',
    scope: {
      video: '=',
    },
    replace: true,
    controller: function ctrl($scope, $element, $attrs) {
'ngInject';
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'lecture_videos/templates/nv-video-download.html',
  };
}
