import React from 'react';
import ClickableContainer from 'components/clickable-container';
import NvIcon from '../nv-icon';

type Props = {
  title: string,
  onBack: () => void,
};

const GoBackTitle = ({ title, onBack }: Props) => (
  <>
    <ClickableContainer onClick={onBack} data-qa='back'>
      <NvIcon
        className='d-flex align-items-center'
        size='small'
        icon='arrow-left'
      />
    </ClickableContainer>
    <div className='w-100'>{title}</div>
  </>
);

export default GoBackTitle;
