import store from 'redux/store';
import { ActivityKey } from 'redux/schemas/models/activity';
import { setActivityProgress } from 'redux/actions/lecture-components';

/* @ngInject */
export default function TimelineVideoPracticeLectureComponentModelService(
  _,
  moment,
  nvUtil,
) {
  class TimelineVideoPracticeLectureComponent {
    constructor(attributes) {
      const base = {
        templateUrl: 'timelines/templates/nv-timeline-video-practice.html',
        canHaveHardDeadline: true,
      };

      /** Setting Up Basic Attributes * */
      _.extend(this, base, attributes);
      // passed lecturePage, lectureSection, course
      this.preprocess();
    }

    /** Function Declarations * */
    /* Used Publicly */
    getStatus() {
      return this.videoPractice.progress;
    }

    isCompleted() {
      return this.videoPractice.progress === 'completed';
    }

    isMissed() {
      return this.videoPractice.progress === 'missed';
    }


    isTodoRequiredForCompletion() {
      return this.videoPractice.isRequiredForCompletion;
    }

    isPrereqCompleted(prereq) {
      if (prereq.type === 'VideoPracticeActivity' && prereq.id === this.videoPractice.id) {
        return this.isCompleted();
      }
      return false;
    }

    updateComponentPointsAndProgress(componentType, componentId, pointsReceived, totalPoints, progress) {
      if ((this.type === componentType && this.id === componentId) || (componentType === 'video_practice' && this.videoPractice.id === componentId)) {
        this.videoPractice.progress = progress ?? 'completed';

        store.dispatch(setActivityProgress({
          pointsReceived,
          progress: this.videoPractice.progress,
          activityKey: ActivityKey.VIDEO_PRACTICES,
          activityPayloadId: this.videoPractice.id,
        }));

        this.videoPractice.pointsReceived = pointsReceived;
        this.pointsReceived = pointsReceived;

        if (_.isNumber(totalPoints)) {
          this.currentTotalPoints = totalPoints;
        } else {
          this.currentTotalPoints = nvUtil.getCurrentTotalPoints(this.totalPoints, this.lecturePage.releaseDate, this.course.expiringPointsEnabled);
        }

        this.viewPreprocess();

        this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    updateComponentProgress(componentType, componentId, progress) {
      if ((this.type === componentType && this.id === componentId) || (componentType === 'video_practice' && this.videoPractice.id === componentId)) {
        this.videoPractice.progress = progress;
        store.dispatch(setActivityProgress({
          progress,
          activityKey: ActivityKey.VIDEO_PRACTICES,
          activityPayloadId: this.videoPractice.id,
        }));
        this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    getPayload() {
      return _.pick(this, ['title', 'deadline', 'hardDeadline']);
    }

    hasDeadline() {
      return !!this.videoPractice.deadline;
    }

    setDeadline(newDeadline) {
      this.videoPractice.deadline = newDeadline;
      this.deadline = newDeadline;
    }

    isDeadlineBeforeLesson() {
      return this.videoPractice.deadline < this.lecturePage.releaseDate;
    }

    /* Used Privately */
    preprocess() {
      this.title = this.videoPractice.title;
      this.deadline = this.videoPractice.deadline;
      this.isTodo = this.videoPractice.isTodo;
      this.hasStructuralIssues = this.videoPractice.hasStructuralIssues;
      this.pointsReceived = this.videoPractice.pointsReceived;
      this.totalPoints = this.videoPractice.totalPoints;
      this.activityId = this.videoPractice.id;
      this.hardDeadline = this.videoPractice.hardDeadline;

      this.currentTotalPoints = nvUtil.getCurrentTotalPoints(this.totalPoints, this.lecturePage.releaseDate, this.course.expiringPointsEnabled);

      this.viewPreprocess();
    }

    viewPreprocess() {
      this.showDueSoon = this.__showDueSoon();

      if (this.isCompleted() || this.pointsReceived > this.currentTotalPoints) {
        this.currentTotalPoints = this.pointsReceived ? this.pointsReceived : 0;
      }
      this.displayTotalPointsOnly = !this.pointsReceived && this.currentTotalPoints;
    }

    __showDueSoon() {
      if (this.videoPractice.deadline) {
        return !this.isCompleted() && moment(this.videoPractice.deadline) > moment() && moment(this.videoPractice.deadline) < moment().add(7, 'days');
      }
      return false;
    }
  }

  return TimelineVideoPracticeLectureComponent;
}
