/* eslint-disable react/no-danger */


import { connect, useSelector } from 'react-redux';
import { ReactElement } from 'react';
import t from 'react-translate';
import moment from 'moment';

// Schemas
import { RootState } from 'redux/schemas';
import { MinimalActivity } from 'redux/schemas/models/activity';
import { LecturePageType, MinimalLecturePage } from 'redux/schemas/models/lecture-page';

// Selectors
import { getActivity, getCommunication, getItemState } from 'redux/selectors/course-communications';
import { getCourseAliases } from 'redux/selectors/course';
import { getPrefixedTitle } from 'redux/selectors/activity';

// Components
import NvIcon from 'shared/components/nv-icon';
import { Communication, CommunicationType, CommunicationErrorCode, ScheduledForType, ItemState } from 'redux/schemas/models/course-communication';
import CommunicationRow, { transformToErrorTitle, transformToActivityTitle } from './communication-row';

type CommunicationTriggerRowProps = {
  communicationId?: number,
  showOwnerActivity?: boolean,
  isPreview?: boolean,
};

type StateProps = {
  communication: Communication,
  itemState?: ItemState
};

export const CommunicationTriggerRow = (props: CommunicationTriggerRowProps & StateProps) => {
  const aliases = useSelector((state) => getCourseAliases(state));

  // Need to pass aliases, so using useSelector pattern
  // Getting the actvity titles with prefix
  const ownerActivityTitle = useSelector((state) => getPrefixedTitle(
    state,
    props.communication.ownerType,
    props.communication.ownerId,
    aliases,
  ));
  const hasCompletedActivityTitle = useSelector((state) => getPrefixedTitle(
    state,
    props.communication.event?.hasCompleted?.type,
    props.communication.event?.hasCompleted?.id,
    aliases,
  ));
  const activity: MinimalActivity | MinimalLecturePage = useSelector((state) => getActivity(
    state,
    props.communication.ownerType,
    props.communication.ownerId,
  ));

  const isSent = props.itemState === ItemState.SENT;

  let triggerTitle: ReactElement | string;
  if (props.communication?.communicationType === CommunicationType.COMPLETED) {
    if (props.communication.errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_FEEDBACK_COMPLETION)
      || props.communication.errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_THIRD_PARTY_COMPLETION)) {
      triggerTitle = props.communication?.xDays && props.communication?.xDays > 0
        ? transformToErrorTitle(
          t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.DAYS_AFTER.EVENT.HAS_COMPLETED,
          CommunicationErrorCode.UNABLE_TO_TRACK_FEEDBACK_COMPLETION,
          aliases,
          isSent,
          { days: props.communication.xDays },
          false,
        )
        : transformToErrorTitle(
          t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.IMMEDIATELY.EVENT.HAS_COMPLETED,
          CommunicationErrorCode.UNABLE_TO_TRACK_FEEDBACK_COMPLETION,
          aliases,
          isSent,
          {},
          false,
        );
    } else if (props.communication.errorCodes?.includes(CommunicationErrorCode.MISSING_PROFILE_COMPLETION_REQUIRED_QUESTIONS)) {
      const titleKey = props.communication?.xDays && props.communication?.xDays > 0
        ? t.COURSE_COMMUNICATIONS.SEND_ON.COMPLETE_ACTIVITY.DAYS_AFTER.ERROR
        : t.COURSE_COMMUNICATIONS.SEND_ON.COMPLETE_ACTIVITY.IMMEDIATELY.ERROR;
      triggerTitle = transformToErrorTitle(
        titleKey,
        CommunicationErrorCode.MISSING_PROFILE_COMPLETION_REQUIRED_QUESTIONS,
        aliases,
        isSent,
        {
          days: props.communication.xDays,
          activity: !props.showOwnerActivity
            ? t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.THIS_ACTIVITY()
            : ownerActivityTitle,
          learnerAlias: aliases.learnersAliases?.learnerAlias,
        },
        false,
      );
    } else {
      triggerTitle = props.communication?.xDays && props.communication?.xDays > 0
        ? transformToActivityTitle(
          t.COURSE_COMMUNICATIONS.SEND_ON.COMPLETE_ACTIVITY.DAYS_AFTER.DISPLAY_ACTIVITY,
          ownerActivityTitle,
          !props.showOwnerActivity,
          false,
          aliases,
          {
            days: props.communication.xDays,
          },
        )
        : transformToActivityTitle(
          t.COURSE_COMMUNICATIONS.SEND_ON.COMPLETE_ACTIVITY.IMMEDIATELY.DISPLAY_ACTIVITY,
          ownerActivityTitle,
          !props.showOwnerActivity,
          false,
          aliases,
        );
    }
  } else if (props.communication?.communicationType === CommunicationType.NOT_COMPLETED && props.communication?.event) {
    // Communication Type is Has Not Completed. So append Event/Trigger details
    // Only one event will be there
    if (props.communication?.event.hasCompleted) {
      if (props.communication.errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_EVENT_FEEDBACK_COMPLETION)
        || props.communication.errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_EVENT_THIRD_PARTY_COMPLETION)) {
        triggerTitle = props.communication?.xDays && props.communication?.xDays > 0
          ? transformToErrorTitle(
            t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.DAYS_AFTER.EVENT.HAS_COMPLETED,
            CommunicationErrorCode.UNABLE_TO_TRACK_EVENT_FEEDBACK_COMPLETION,
            aliases,
            isSent,
            { days: props.communication.xDays },
            false,
          )
          : transformToErrorTitle(
            t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.IMMEDIATELY.EVENT.HAS_COMPLETED,
            CommunicationErrorCode.UNABLE_TO_TRACK_EVENT_FEEDBACK_COMPLETION,
            aliases,
            isSent,
            {},
            false,
          );
      } else if (props.communication.errorCodes?.includes(CommunicationErrorCode.MISSING_EVENT_PROFILE_COMPLETION_REQUIRED_QUESTIONS)
        && hasCompletedActivityTitle) {
        const titleKey = props.communication?.xDays && props.communication?.xDays > 0
          ? t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.DAYS_AFTER.EVENT.HAS_COMPLETED_QUESTION_MISSING
          : t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.IMMEDIATELY.EVENT.HAS_COMPLETED_QUESTION_MISSING;
        triggerTitle = transformToErrorTitle(
          titleKey,
          CommunicationErrorCode.MISSING_EVENT_PROFILE_COMPLETION_REQUIRED_QUESTIONS,
          aliases,
          isSent,
          {
            days: props.communication.xDays,
            activity: hasCompletedActivityTitle,
            learnerAlias: aliases.learnersAliases?.learnerAlias,
          },
          false,
        );
      } else if (props.communication.errorCodes?.includes(CommunicationErrorCode.MISSING_COMPLETED_ACTIVITY_EVENT_ERROR)
        || !hasCompletedActivityTitle) {
        triggerTitle = props.communication?.xDays && props.communication?.xDays > 0
          ? transformToErrorTitle(
            t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.DAYS_AFTER.EVENT.HAS_COMPLETED,
            CommunicationErrorCode.MISSING_COMPLETED_ACTIVITY_EVENT_ERROR,
            aliases,
            isSent,
            { days: props.communication.xDays },
          )
          : transformToErrorTitle(
            t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.IMMEDIATELY.EVENT.HAS_COMPLETED,
            CommunicationErrorCode.MISSING_COMPLETED_ACTIVITY_EVENT_ERROR,
            aliases,
            isSent,
          );
      } else {
        triggerTitle = props.communication?.xDays && props.communication?.xDays > 0
          ? transformToActivityTitle(
            t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.DAYS_AFTER.EVENT.HAS_COMPLETED,
            hasCompletedActivityTitle,
            false,
            false,
            aliases,
            {
              days: props.communication.xDays,
            },
          )
          : transformToActivityTitle(
            t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.IMMEDIATELY.EVENT.HAS_COMPLETED,
            hasCompletedActivityTitle,
            false,
            false,
            aliases,
          );
      }
    } else if (props.communication?.event.earnedAtLeastXPoints) {
      if (props.communication.errorCodes?.includes(CommunicationErrorCode.COURSE_POINTS_DISABLED)) {
        triggerTitle = props.communication?.xDays && props.communication?.xDays > 0
          ? transformToErrorTitle(
            t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.DAYS_AFTER.EVENT.ATLEAST_X_POINTS,
            CommunicationErrorCode.COURSE_POINTS_DISABLED,
            aliases,
            isSent,
            {
              days: props.communication.xDays,
              input: props.communication?.event.earnedAtLeastXPoints,
            },
            false,
          )
          : transformToErrorTitle(
            t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.IMMEDIATELY.EVENT.ATLEAST_X_POINTS,
            CommunicationErrorCode.COURSE_POINTS_DISABLED,
            aliases,
            isSent,
            {
              input: props.communication?.event.earnedAtLeastXPoints,
            },
            false,
          );
      } else {
        triggerTitle = props.communication?.xDays && props.communication?.xDays > 0
          ? t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.DAYS_AFTER.EVENT.ATLEAST_X_POINTS({
            days: props.communication.xDays,
            input: props.communication?.event.earnedAtLeastXPoints,
            ...aliases.learnersAliases,
            ...aliases.pointsAliases,
          })
          : t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.IMMEDIATELY.EVENT.ATLEAST_X_POINTS({
            input: props.communication?.event.earnedAtLeastXPoints,
            ...aliases.learnersAliases,
            ...aliases.pointsAliases,
          });
      }
    } else if (props.communication?.event.completedXAssignmentsAsTodo) {
      if (props.communication.errorCodes?.includes(CommunicationErrorCode.COURSE_NO_TODO_EXERCISES)) {
        triggerTitle = props.communication?.xDays && props.communication?.xDays > 0
          ? transformToErrorTitle(
            t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.DAYS_AFTER.EVENT.COMPLETED_X_ASGMT_AS_TODO,
            CommunicationErrorCode.COURSE_NO_TODO_EXERCISES,
            aliases,
            isSent,
            {
              days: props.communication.xDays,
              input: props.communication?.event.completedXAssignmentsAsTodo,
            },
            false,
          )
          : transformToErrorTitle(
            t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.IMMEDIATELY.EVENT.COMPLETED_X_ASGMT_AS_TODO,
            CommunicationErrorCode.COURSE_NO_TODO_EXERCISES,
            aliases,
            isSent,
            {
              input: props.communication?.event.completedXAssignmentsAsTodo,
            },
            false,
          );
      } else {
        triggerTitle = props.communication?.xDays && props.communication?.xDays > 0
          ? t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.DAYS_AFTER.EVENT.COMPLETED_X_ASGMT_AS_TODO({
            days: props.communication.xDays,
            input: props.communication?.event.completedXAssignmentsAsTodo,
            ...aliases.learnersAliases,
            ...aliases.assignmentAliases,
          })
          : t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.IMMEDIATELY.EVENT.COMPLETED_X_ASGMT_AS_TODO({
            input: props.communication?.event.completedXAssignmentsAsTodo,
            ...aliases.learnersAliases,
            ...aliases.assignmentAliases,
          });
      }
    } else if (props.communication?.event.enrolledInCourse) {
      triggerTitle = props.communication?.xDays && props.communication?.xDays > 0
        ? t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.DAYS_AFTER.EVENT.ENROLLED_IN_COURSE({
          days: props.communication.xDays,
          ...aliases.learnersAliases,
          ...aliases.courseAliases,
        })
        : t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.IMMEDIATELY.EVENT.ENROLLED_IN_COURSE({
          ...aliases.learnersAliases,
          ...aliases.courseAliases,
        });
    } else if (props.communication?.event.visitsHomePage) {
      triggerTitle = props.communication?.xDays && props.communication?.xDays > 0
        ? t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.DAYS_AFTER.EVENT.VISITS_HOME_PAGE({
          days: props.communication.xDays,
          ...aliases.learnersAliases,
          ...aliases.courseAliases,
        })
        : t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.IMMEDIATELY.EVENT.VISITS_HOME_PAGE({
          ...aliases.learnersAliases,
          ...aliases.courseAliases,
        });
    } else if (props.communication?.event.hasCompletedCourse) {
      triggerTitle = props.communication?.xDays && props.communication?.xDays > 0
        ? t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.DAYS_AFTER.EVENT.COMPLETED_COURSE({
          days: props.communication.xDays,
          ...aliases.learnersAliases,
          ...aliases.courseAliases,
        })
        : t.COURSE_COMMUNICATIONS.SEND_ON.DONE_FOLLOWING.IMMEDIATELY.EVENT.COMPLETED_COURSE({
          ...aliases.learnersAliases,
          ...aliases.courseAliases,
        });
    }
  } else if (props.communication?.communicationType === CommunicationType.DUE_DATE_EMAIL
    || props.communication?.communicationType === CommunicationType.RELEASE_DATE_EMAIL
    || props.communication?.communicationType === CommunicationType.DUE_DATE_ANNOUNCEMENT
    || props.communication?.communicationType === CommunicationType.RELEASE_DATE_ANNOUNCEMENT) {
    const { communicationType, scheduledFor, scheduledAt } = props.communication;
    let scheduledDate: moment.Moment;
    let scheduledKey;
    let showOwnerActivity = !props.showOwnerActivity;

    let activityTitle = communicationType === CommunicationType.DUE_DATE_EMAIL
      || communicationType === CommunicationType.DUE_DATE_ANNOUNCEMENT
      ? ownerActivityTitle
      : activity?.title;
    const referenceDate = communicationType === CommunicationType.DUE_DATE_EMAIL
      || communicationType === CommunicationType.DUE_DATE_ANNOUNCEMENT
      ? moment((activity as MinimalActivity)?.dueDate)
      : moment((activity as MinimalLecturePage)?.releaseDate);
    const communicationTypeKey = communicationType === CommunicationType.DUE_DATE_EMAIL
      || communicationType === CommunicationType.DUE_DATE_ANNOUNCEMENT
      ? 'DUE_DATE' : 'RELEASE_DATE';

    const xDays = props.communication?.xDays ?? 0;
    if (scheduledFor && scheduledFor === ScheduledForType.SEND_NOW) {
      scheduledKey = 'SEND_NOW';
      scheduledDate = moment(scheduledAt);
      // send now does not show actvity or this activity
      showOwnerActivity = false;
      activityTitle = '';
    } else if (scheduledFor && scheduledFor === ScheduledForType.ON_DATE) {
      scheduledKey = 'ON';
      scheduledDate = referenceDate;
    } else if (scheduledFor && scheduledFor === ScheduledForType.BEFORE_DATE) {
      scheduledKey = 'BEFORE';
      scheduledDate = referenceDate.subtract(xDays, 'days');
    } else {
      scheduledKey = 'AFTER';
      scheduledDate = referenceDate.add(xDays, 'days');
    }

    if (!props.isPreview && scheduledAt) {
      scheduledDate = moment(scheduledAt);
    }

    // A warning text need to be displayed if this date has past and in preview mode.
    const showPastDateWarning = props.isPreview && scheduledDate.isBefore(moment());
    let infoKey: string;

    if (props.itemState === ItemState.SENT && scheduledAt) {
      infoKey = 'PAST';
    } else if (props.itemState === ItemState.ERROR && scheduledDate.isBefore(moment())) {
      infoKey = 'ERROR_PAST';
    } else {
      infoKey = 'DEFAULT';
    }

    const values: { info: string, days?: number } = {
      info: showPastDateWarning
        ? t.COURSE_COMMUNICATIONS.SEND_ON.SCHEDULED_INFO.PAST_DATE_WARNING()
        : t.COURSE_COMMUNICATIONS.SEND_ON.SCHEDULED_INFO[infoKey](scheduledDate.format('lll')),
    };
    if (xDays) {
      values.days = xDays;
    }

    triggerTitle = props.communication.errorCodes?.includes(CommunicationErrorCode.ACTIVITY_DUE_DATE_REMOVED)
      ? transformToErrorTitle(
        t.COURSE_COMMUNICATIONS.SEND_ON[communicationTypeKey][scheduledKey].ERROR,
        CommunicationErrorCode.ACTIVITY_DUE_DATE_REMOVED,
        aliases,
        isSent,
        {
          ...values,
          activity: showOwnerActivity
            ? t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.THIS_ACTIVITY()
            : activityTitle,
        },
        false,
      )
      : transformToActivityTitle(
        t.COURSE_COMMUNICATIONS.SEND_ON[communicationTypeKey][scheduledKey].DISPLAY_ACTIVITY,
        activityTitle,
        showOwnerActivity,
        false,
        {},
        values,
        showPastDateWarning,
        props.communication.ownerType === LecturePageType.LECTURE,
      );
  } else if (props.communication?.communicationType === CommunicationType.MANUAL_EMAIL
    || props.communication?.communicationType === CommunicationType.MANUAL_ANNOUNCEMENT) {
    const { scheduledFor, scheduledAt, scheduledOn } = props.communication;

    const scheduledDate = scheduledAt && !props.isPreview // on Duplicating or editing use scheduledOn
      ? scheduledAt : scheduledOn ?? '';
    const scheduledKey = scheduledFor && scheduledFor === ScheduledForType.SEND_NOW
      ? 'NOW' : 'LATER';
    const displayKey = props.itemState === ItemState.SENT && scheduledAt
      ? 'SENT' : 'DEFAULT';

    triggerTitle = transformToActivityTitle(
      t.COURSE_COMMUNICATIONS.SEND_ON.CUSTOM[scheduledKey].DISPLAY_ACTIVITY[displayKey],
      '',
      false,
      false,
      {},
      { scheduledDate: moment(scheduledDate).format('lll') },
    );
  } else if (props.communication?.communicationType === CommunicationType.AUTOMATED_JOURNEY_EMAIL) {
    triggerTitle = t.JOURNEY_COMMUNICATIONS.AUTOMATED.SEND_ON.LABEL(`${props.communication.xDays}`);
  }

  return (
    <CommunicationRow>
      <div className='content-wrapper'>
        <div className='icons-wrapper'>
          <NvIcon size='xss-smallest' icon='calendar' />
        </div>
        <div className='content text-medium'>
          { // string means its a translated string which may contain html,
            // or else treat it as a ReactElement => titles with warnings
            typeof triggerTitle === 'string'
              ? (
                <span
                  className='title'
                  dangerouslySetInnerHTML={{
                    __html: triggerTitle,
                  }}
                />
              )
              : (
                <span className='title'>{triggerTitle}</span>
              )
          }
        </div>
      </div>
    </CommunicationRow>
  );
};

const ConnectedCommunicationTriggerRow = connect(
  (state: RootState, ownProps: CommunicationTriggerRowProps) => ({
    communication: getCommunication(state, ownProps.communicationId),
    itemState: getItemState(state, ownProps.communicationId),
  }),
)(CommunicationTriggerRow);

export default ConnectedCommunicationTriggerRow;
