import { css } from '@emotion/react';
import ClickableContainer from 'components/clickable-container';
import React from 'react';
import {
  black,
  blue5,
  gray4,
  gray5,
  gray7,
  primary,
} from 'styles/global_defaults/colors';
import {
  halfSpacing,
  largeSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import { StatusIndicator } from './status-indicator';

type Option = {
  id: number;
  text: string;
};

type SelectedOptions = number[];

type SelectableOptionProps = {
  option: Option;
  onSelect: (optionId: number, selected: boolean) => void;
  dataQa: string;
  dataQaId: string;
};

type ListOfSelectableOptionProps = {
  options: Option[];
  dataQa: string;
  onSelectionChange: (selectedOptions: SelectedOptions) => void;
};

const listStyles = css`
  &,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  display: flex;
  flex-direction: column;
  gap: ${standardSpacing}px;
`;

const selectableOptionStyles = css`
  padding: ${threeQuartersSpacing}px;
  border-radius: 4px;
  border: 1px solid;
  display: flex;
  justify-content: space-between;
  gap: ${halfSpacing}px;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${blue5};
  }

  .index {
    min-width: ${largeSpacing}px;
    min-height: ${largeSpacing}px;
    border-radius: 4px;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p {
    color: ${black};
  }
`;

// Renders a container that allows the user to select or deselect the option
const SelectableOption: React.FC<SelectableOptionProps> = ({
  option,
  onSelect,
  dataQa,
  dataQaId,
}) => {
  const [isSelected, setIsSelected] = React.useState(false);

  const handleOnClick = () => {
    const newSelectedState = !isSelected;
    setIsSelected(newSelectedState);
    onSelect(option.id, newSelectedState);
  };

  const containerStyles = css`
    ${selectableOptionStyles};
    background-color: ${!isSelected ? gray7 : blue5};
    border-color: ${!isSelected ? 'transparent' : primary};

    .content {
      display: flex;
      align-items: flex-start;
      gap: ${halfSpacing}px;
    }

    .index {
      font-weight: ${!isSelected ? '400' : '700'};
      border-color: ${!isSelected ? gray5 : gray4};
    }
  `;

  return (
    <li>
      <ClickableContainer
        onClick={handleOnClick}
        css={containerStyles}
        data-qa={dataQa}
        data-qa-id={dataQaId}
      >
        <div className='content'>
          <div className='index text-large-body'>{option.id}</div>
          <p className='m-0 text-large-body bold'>{option.text}</p>
        </div>
        <StatusIndicator variant='lg' isVisible={isSelected} />
      </ClickableContainer>
    </li>
  );
};

// This component renders a list of options that can be selectable (clickable container)
// The first phase of this component is implemented as a multiple selection, but can be used as implemented in the QuizQuestionOption component
export const ListOfSelectableOptions: React.FC<ListOfSelectableOptionProps> = ({
  options,
  dataQa,
  onSelectionChange,
}) => {
  const [selectedOptions, setSelectedOptions] = React.useState<number[]>(
    [],
  );

  // Handle the selection of an option
  const handleSelectOption = (optionId: number, selected: boolean) => {
    setSelectedOptions(prevSelectedOptions => {
      let newSelectedOptions: SelectedOptions = [];

      if (selected) {
        // Add optionId to selected options
        newSelectedOptions = [...prevSelectedOptions, optionId];
      } else {
        // Remove the optionId from the selected options
        newSelectedOptions = prevSelectedOptions.filter(id => id !== optionId);
      }

      onSelectionChange(newSelectedOptions);
      return newSelectedOptions;
    });
  };

  return (
    <ul css={listStyles}>
      {options.map(option => (
        <SelectableOption
          key={option.id}
          option={option}
          onSelect={handleSelectOption}
          dataQa={dataQa}
          dataQaId={`${dataQa}_${option.id}`}
        />
      ))}
    </ul>
  );
};

export default ListOfSelectableOptions;
