import t from '../../../react-translate';

export default {
  bindings: {
    lectureComponent: '<',
    editMode: '<',
    restrictedEditMode: '<',
    reorderMode: '<',
    index: '<',
    context: '<',
  },
  controller: function ctrl(
    _,
    $scope,
    $controller,
    $state,
    $stateParams,
    CurrentPermissionsManager,
    CurrentCourseManager,
    RailsRoutes,
    LectureComponentsHelper,
    nvUtil,
  ) {
'ngInject';
    angular.extend(this, $controller('LectureComponentBaseCtrl'));
    const vm = this;
    vm.CurrentPermissionsManager = CurrentPermissionsManager;

    vm.editBasicsVisible = CurrentPermissionsManager.isCourseBuilder();
    vm.editTeamsVisible = vm.lectureComponent.teamSet.isTeam && !vm.lectureComponent.teamSet.formedByStudents;
    vm.editTeamsAvailable = vm.editTeamsVisible && CurrentPermissionsManager.isInstructor();
    vm.editQuestionsVisible = CurrentPermissionsManager.isCourseBuilder();
    vm.uploadGroupsVisible = vm.lectureComponent.teamSet.isGroup;
    vm.uploadGroupsAvailable = vm.uploadGroupsVisible && CurrentPermissionsManager.isInstructor();
    vm.editDeadlinesVisible = vm.lectureComponent.teamSet.isTeam && vm.lectureComponent.teamSet.formedByStudents
          && !vm.CurrentCourseManager.course.isSelfPaced && CurrentPermissionsManager.isCourseBuilder();

    // Whether any of the above options are visible. This really needs a better name
    vm.aboveDividerOptionsVisible = vm.editBasicsVisible || vm.editTeamsVisible || vm.editQuestionsVisible
                                      || vm.uploadGroupsVisible || vm.editDeadlinesVisible;
    vm.toggleProgressDashboardVisible = CurrentPermissionsManager.isInstructor();

    vm.optionsVisible = vm.editBasicsVisible || vm.editTeamsVisible || vm.editQuestionsVisible
          || vm.uploadGroupsVisible || vm.editDeadlinesVisible || vm.toggleProgressDashboardVisible;

    let lastSavedDescription;

    vm.$onInit = () => {
      lastSavedDescription = vm.lectureComponent.teamSet.description;
    };

    /* Admin Section */
    vm.descriptionBlurCallback = () => {
      if (lastSavedDescription !== vm.lectureComponent.teamSet.description) {
        lastSavedDescription = vm.lectureComponent.teamSet.description;
        vm.lectureComponent.save();
      }
    };

    vm.setEditMenu = () => {
      if ((!vm.editMode && !vm.restrictedEditMode) || !vm.optionsVisible) {
        return;
      }

      const { sharedProps } = this.context;
      const extraOptions = [];

      const courseAliases = CurrentCourseManager.course.getAliases();

      if (vm.editBasicsVisible) {
        extraOptions.push(sharedProps.extraOptions.getEditOption());
      }

      if (vm.editTeamsVisible && vm.editTeamsAvailable) {
        extraOptions.push({
          type: 'text',
          text: t.LECTURE_PAGES.COMPONENTS.EXERCISE.DROPDOWN.EDIT_TEAMS({
            TeamsAlias: vm.CurrentCourseManager.course.teamName.pluralizedTitleized,
          }),
          callback: () => $state.go('team-facilitation', {
            catalogId: $stateParams.catalogId,
            initialState: true,
            loadGroupsTab: false,
          }),
        });
      }

      if (vm.editTeamsVisible && !vm.editTeamsAvailable) {
        extraOptions.push({
          type: 'text',
          text: t.LECTURE_PAGES.COMPONENTS.EXERCISE.DROPDOWN.EDIT_TEAMS({
            TeamsAlias: vm.CurrentCourseManager.course.teamName.pluralizedTitleized,
          }),
          tooltip: {
            text: t.TEAM_FORMATION.RESTRICTED_TOOLTIP({ ...courseAliases, isTeam: vm.lectureComponent.teamSet.isTeam, isEdit: true }),
          },
          disabled: true,
        });
      }

      if (vm.uploadGroupsVisible && vm.uploadGroupsAvailable) {
        extraOptions.push({
          type: 'text',
          text: t.LECTURE_PAGES.COMPONENTS.GROUP_FORMATION.UPLOAD_GROUPS({
            GroupAlias: vm.lectureComponent.teamSet.name.capitalizedPluralized,
          }),
          callback: () => $state.go('team-facilitation', {
            catalogId: $stateParams.catalogId,
            initialState: true,
            loadGroupsTab: true,
          }),
        });
      }

      if (vm.uploadGroupsVisible && !vm.uploadGroupsAvailable) {
        extraOptions.push({
          type: 'text',
          text: t.LECTURE_PAGES.COMPONENTS.GROUP_FORMATION.UPLOAD_GROUPS({
            GroupAlias: vm.lectureComponent.teamSet.name.capitalizedPluralized,
          }),
          tooltip: {
            text: t.TEAM_FORMATION.RESTRICTED_TOOLTIP({ ...courseAliases, isTeam: vm.lectureComponent.teamSet.isTeam, isEdit: true }),
          },
          disabled: true,
        });
      }

      if (vm.editBasicsVisible) {
        extraOptions.push({
          type: 'link',
          text: t.LECTURE_PAGES.COMPONENTS.GROUP_FORMATION.EDIT_PROFILE_QUESTIONS(courseAliases),
          link: RailsRoutes.editGroupProfileQuestionsPath($stateParams.catalogId, vm.lectureComponent.teamSet.id),
        });
      }

      if (vm.editDeadlinesVisible) {
        extraOptions.push({
          type: 'text',
          text: vm.lectureComponent.hasDeadline() ? t.LECTURE_PAGES.COMPONENTS.DROPDOWN.REMOVE_DEADLINE() : t.LECTURE_PAGES.COMPONENTS.DROPDOWN.ADD_DEADLINE(),
          callback: () => vm.toggleDeadline(),
        });
      }

      if (vm.toggleProgressDashboardVisible && vm.aboveDividerOptionsVisible) {
        extraOptions.push({ type: 'divider' });
      }

      if (vm.toggleProgressDashboardVisible) {
        if (!vm.lectureComponent.teamSet.hasProgressDashboard) {
          extraOptions.push({
            type: 'text',
            text: t.LECTURE_PAGES.COMPONENTS.GROUP_FORMATION.ENABLE_PROGRESS_DASHBOARD({
              TeamAlias: vm.lectureComponent.teamSet.name.capitalizedSingularized,
            }),
            callback: () => {
              vm.lectureComponent.teamSet.enableProgressDashboard($stateParams.catalogId);
              vm.setEditMenu();
            },
          });
        } else {
          extraOptions.push({
            type: 'text',
            text: t.LECTURE_PAGES.COMPONENTS.GROUP_FORMATION.DISABLE_PROGRESS_DASHBOARD({
              TeamAlias: vm.lectureComponent.teamSet.name.capitalizedSingularized,
            }),
            callback: () => {
              vm.lectureComponent.teamSet.disableProgressDashboard($stateParams.catalogId);
              vm.setEditMenu();
            },
          });
        }
      }

      sharedProps.extraOptions = {
        ...sharedProps.extraOptions,
        ...{
          options: extraOptions,
        },
      };
    };

    vm.setEditMenu();

    $scope.$watch('vm.lectureComponent.teamSet.deadline', () => vm.setEditMenu());
    $scope.$watch('vm.lectureComponent.teamSet.name', () => vm.setEditMenu());
    $scope.$watch('vm.lectureComponent.teamSet.formedByStudents', () => {
      vm.editTeamsVisible = vm.lectureComponent.teamSet.isTeam && !vm.lectureComponent.teamSet.formedByStudents;
      vm.editTeamsAvailable = vm.editTeamsVisible && CurrentPermissionsManager.isInstructor();
      vm.setEditMenu();
    });
  },
  controllerAs: 'vm',
  templateUrl: 'lecture_pages/templates/components/nv-team-formation-lecture-component.html',
};
