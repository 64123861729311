import { css, Global } from '@emotion/react';
import React, { useCallback, useContext, useEffect, useRef } from 'react';

import { gray2, gray5 } from 'styles/global_defaults/colors';
import { largeSpacing } from 'styles/global_defaults/scaffolding';
import { semiBoldFontWeight } from 'styles/global_defaults/fonts';

import { AngularServicesContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { fetchBookmarks, createBookmark } from 'redux/actions/bookmarks';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/schemas';
import { BookmarkType } from 'redux/schemas/models/bookmark';
import { FetchBookmarksListResponse } from 'redux/schemas/api/bookmarks';
import { wrapThunkAction } from 'redux/utils';
import BookmarksHeader from './bookmarks-header';
import BookmarksList, { PAGE_SIZE } from './bookmarks-list';

const BookmarksPanel = () => {
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState<number>(null);
  const containerRef = React.useRef<HTMLDivElement>();

  const styles = css`
    height: 100%;
    display: flex;
    flex-direction: column;

    .add-first-bookmark {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      margin-top: 25%;

      .icon-create-new-post {
        color: ${gray5};
      }

      .add-new-note {
        color: ${gray2};
        margin-top: ${largeSpacing}px;
        font-weight: ${semiBoldFontWeight};
      }
    }

    .show-all {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .filters-menu {
      max-width: 80%;
    }
  `;

  const { $scope, FlyoutModalManager } = useContext(AngularServicesContext);

  const dispatch = useAppDispatch();

  const deregisterStateChangeStartRef = useRef<any>(null);
  const bookmarksListRef = useRef<any>(null);

  const { filter, courses } = useSelector(((state: RootState) => state.app.bookmarks));

  useEffect(() => {
    const actionPayload: {
      page: number,
      pageSize: number,
      catalogId?: string,
    } = {
      page,
      pageSize: PAGE_SIZE,
    };

    if (filter) {
      actionPayload.catalogId = filter;
    }

    wrapThunkAction(dispatch(fetchBookmarks(actionPayload))).then((action) => {
      setTotal((action.payload as FetchBookmarksListResponse).total);
    }).catch(() => {});
  }, [dispatch, filter, page]);

  const checkUnsavedChanges = useCallback(() => {
    const changes = bookmarksListRef.current?.checkUnsavedChanges();
    return changes;
  }, []);

  useEffect(() => {
    deregisterStateChangeStartRef.current = $scope.StateManager.registerStateChangeStart(
      checkUnsavedChanges,
      'shared/templates/modal-navigate-away.html',
      'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY_CHANGES',
    );

    const { current: deregister } = deregisterStateChangeStartRef;

    return () => {
      deregister();
    };
  }, [checkUnsavedChanges, $scope.StateManager]);


  const onClose = () => {
    if (checkUnsavedChanges()) {
      const modalInstance = $scope.ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-navigate-away-unsaved-changes-confirmation-overlay.html');
      modalInstance.result.then(() => {
        FlyoutModalManager?.closeFlyout();
      });
    } else {
      FlyoutModalManager?.closeFlyout();
    }
  };

  const addNewBookmark = () => {
    dispatch(createBookmark({
      type: filter ? BookmarkType.COURSE : BookmarkType.INSTITUTION,
      catalogId: filter,
    }));
  };

  const handleBookmarkHeaderChange = () => setPage(1);

  return (
    <>
      <Global
        styles={css`
          .flyout-content {
            /* .flyout-content sets a min-width but we need a fixed width instead */
            width: 50%;
          }
          .react-app#bookmarks-react-app {
            height: 100%;

            & > div {
              height: inherit;
            }
          }
        `}
      />
      <div css={styles} ref={containerRef}>
        <BookmarksHeader
          addNewBookmark={addNewBookmark}
          onClose={onClose}
          onChange={handleBookmarkHeaderChange}
          containerRef={containerRef}
        />
        <BookmarksList
          total={total}
          ref={bookmarksListRef}
          page={page}
          onPageChange={setPage}
        />
      </div>
    </>
  );
};

export default BookmarksPanel;
