import { css } from '@emotion/react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { RootState } from 'redux/schemas';
import { CourseAliases } from 'redux/schemas/models/course';
import { CourseProfileQuestion } from 'redux/schemas/models/account-fields';
import { getCourseAliases } from 'redux/selectors/course';
import {
  getFormOrgLevelProfileSettings as orgLevelProfileSettingsSelector,
} from 'redux/selectors/org-level-profile';
import { getCourseProfileQuestions } from 'redux/selectors/profile-questions';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import NvDropdown, { NvDropdownAlign, NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { config } from '../../../../config/pendo.config.json';

enum SortOrderOptions {
  BY_RECENTLY_ENROLLED = 'by_recently_enrolled',
  BY_RECENTLY_ACTIVE = 'by_recently_active',
}

export enum DefaultProfileQuestion {
  DISPLAY_LOCATION = 'display_location',
  HEADLINE = 'headline',
  BIO = 'bio',
}

type AdditionalInformation = {
  defaultProfileQuestion?: DefaultProfileQuestion,
  answerableType?: 'CourseProfileQuestion' | 'ProfileSetting',
  answerableId?: number,
};

type MeetAndGreetModalProps = {
  onClose: () => void,
  createMeetAndGreet: ({ sortBy, photosRequired }: { sortBy: SortOrderOptions, photosRequired: boolean }) => void,
  editBasics: ({ sortBy, photosRequired }: { sortBy: SortOrderOptions, photosRequired: boolean }) => void,
  isEdit: boolean,
  initialAdditionalInformation: AdditionalInformation,
  initialSortOrder: SortOrderOptions,
  initialPhotosRequired: boolean,
};

const MeetAndGreetModal = ({
  onClose,
  createMeetAndGreet,
  editBasics,
  isEdit,
  initialAdditionalInformation = { defaultProfileQuestion: null, answerableType: null, answerableId: null },
  initialSortOrder = SortOrderOptions.BY_RECENTLY_ENROLLED,
  initialPhotosRequired = false,
}: MeetAndGreetModalProps) => {
  const styles = css`
    .dropdown-section {
      width: 290px;
    }

    .text-wrap {
      text-wrap: wrap;
    }
  `;

  const [additionalInformation, setAdditionalInformation] = useState<AdditionalInformation>(initialAdditionalInformation);
  const [sortOrder, setSortOrder] = useState(initialSortOrder);
  const [photosRequired, setPhotosRequired] = useState(initialPhotosRequired);
  const aliases: CourseAliases = useSelector((state: RootState) => getCourseAliases(state));

  const courseProfileQuestions = useSelector(getCourseProfileQuestions);
  const orgLevelProfileSettings = useSelector(orgLevelProfileSettingsSelector);
  const { accountLevel: { bio, headline, location }, orgLevel = [] } = orgLevelProfileSettings;

  const profileQuestionItems: NvDropdownOption[] = [{
    type: 'text',
    text: t.LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.MODAL.PICK_PROFILE_QUESTION(),
    textClassName: 'mw-100 text-wrap',
    id: 'pick-profile-question',
    callback: () => setAdditionalInformation({ defaultProfileQuestion: null, answerableType: null, answerableId: null }),
  }];

  if (!location.isHidden) {
    profileQuestionItems.push({
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.MODAL.LOCATION(),
      textClassName: 'mw-100 text-wrap',
      id: 'display_location',
      callback: () => setAdditionalInformation({ defaultProfileQuestion: DefaultProfileQuestion.DISPLAY_LOCATION, answerableType: null, answerableId: null }),
      dataQa: 'meet-and-greet-additional-information-location',
    });
  }
  if (!headline.isHidden) {
    profileQuestionItems.push({
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.MODAL.SNIPPET_OF_HEADLINE(),
      textClassName: 'mw-100 text-wrap',
      id: 'headline',
      callback: () => setAdditionalInformation({ defaultProfileQuestion: DefaultProfileQuestion.HEADLINE, answerableType: null, answerableId: null }),
      dataQa: 'meet-and-greet-additional-information-headline',
    });
  }
  if (!bio.isHidden) {
    profileQuestionItems.push({
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.MODAL.SNIPPET_OF_SHORT_BIO(),
      textClassName: 'mw-100 text-wrap',
      id: 'bio',
      callback: () => setAdditionalInformation({ defaultProfileQuestion: DefaultProfileQuestion.BIO, answerableType: null, answerableId: null }),
      dataQa: 'meet-and-greet-additional-information-bio',
    });
  }

  Object.values(courseProfileQuestions).forEach((courseQuestion: CourseProfileQuestion) => {
    profileQuestionItems.push({
      type: 'text',
      text: courseQuestion.question,
      textClassName: 'mw-100 text-wrap',
      id: `CourseProfileQuestion-${courseQuestion.id}`,
      callback: () => setAdditionalInformation({ defaultProfileQuestion: null, answerableType: 'CourseProfileQuestion', answerableId: courseQuestion.id }),
      dataQa: `meet-and-greet-additional-information-CourseProfileQuestion-${courseQuestion.id}`,
    });
  });

  orgLevel.forEach((orgQuestion) => {
    if (!orgQuestion.isHidden) {
      profileQuestionItems.push({
        type: 'text',
        text: orgQuestion.question,
        textClassName: 'mw-100 text-wrap',
        id: `ProfileSetting-${orgQuestion.id}`,
        callback: () => setAdditionalInformation({ defaultProfileQuestion: null, answerableType: 'ProfileSetting', answerableId: orgQuestion.id }),
        dataQa: `meet-and-greet-additional-information-ProfileSetting-${orgQuestion.id}`,
      });
    }
  });

  let profileQuestionInitialIndex = 0;
  if (initialAdditionalInformation.defaultProfileQuestion) {
    profileQuestionInitialIndex = profileQuestionItems.findIndex(val => val.id === initialAdditionalInformation.defaultProfileQuestion);
  }
  if (initialAdditionalInformation.answerableType) {
    profileQuestionInitialIndex = profileQuestionItems.findIndex(val => val.id === `${initialAdditionalInformation.answerableType}-${initialAdditionalInformation.answerableId}`);
  }
  // If we can't find the profile question (this is happening sometimes with bad data, or if a profile question is hidden after being selected), it will return -1 (which makes the dropdown blank). Instead we should return 0, which makes makes the dropdown behave like normal when there is no profile question selected
  if (profileQuestionInitialIndex === -1) {
    profileQuestionInitialIndex = 0;
  }

  const displayOrderItems: NvDropdownOption[] = [
    {
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.MODAL.MOST_RECENTLY_ENROLLED(),
      callback: () => setSortOrder(SortOrderOptions.BY_RECENTLY_ENROLLED),
      dataQa: config.pendo.meetAndGreet.modal.byMostRecentlyEnrolled,
    },
    {
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.MODAL.MOST_RECENTLY_ACTIVE(aliases.courseAliases),
      callback: () => setSortOrder(SortOrderOptions.BY_RECENTLY_ACTIVE),
      dataQa: config.pendo.meetAndGreet.modal.byMostRecentlyActive,
    },
  ];

  const onSubmit = () => {
    if (isEdit) {
      editBasics({ sortBy: sortOrder, photosRequired, ...additionalInformation });
    } else {
      createMeetAndGreet({ sortBy: sortOrder, photosRequired, ...additionalInformation });
    }
  };

  return (
    <div css={styles}>
      <div>{t.LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.MODAL.DESCRIPTION({ ...aliases.courseAliases, ...aliases.learnersAliases })}</div>
      <div className='d-flex justify-content-between mt-5'>
        <div className='dropdown-section'>
          <div className='mb-2'>{t.LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.MODAL.DISPLAY_ADDITIONAL_INFORMATION()}</div>
          <NvDropdown
            items={profileQuestionItems}
            initialIndex={profileQuestionInitialIndex}
            buttonStyle={NvDropdownButtonStyle.FORM_SMALL}
            align={NvDropdownAlign.CENTER}
            showSelectedIndicator
            minWidth={290}
            maxWidth={290}
            isFocusable
          />
        </div>
        <div className='dropdown-section'>
          <div className='mb-2'>{t.LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.MODAL.SET_DISPLAY_ORDER()}</div>
          <NvDropdown
            items={displayOrderItems}
            initialIndex={initialSortOrder === SortOrderOptions.BY_RECENTLY_ENROLLED ? 0 : 1}
            buttonStyle={NvDropdownButtonStyle.FORM_SMALL}
            align={NvDropdownAlign.CENTER}
            showSelectedIndicator
            minWidth={290}
            isFocusable
          />
        </div>
      </div>
      <div>
        <NvCheckbox
          className='my-5'
          name={config.pendo.meetAndGreet.modal.profileImageRequired}
          label={t.LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.MODAL.PROFILE_IMAGE_CHECKBOX()}
          labelClassName='text-regular'
          checked={photosRequired}
          onChange={(event) => setPhotosRequired(!!event.target.checked)}
          dataQa={config.pendo.meetAndGreet.modal.profileImageRequired}
        />
      </div>
      {/* This is used by Product to create a pendo guide. Do not remove. */}
      <div id='meet-and-greet-lx-design-tips' />
      <div className='button-bar'>
        <Button variant='secondary' onClick={() => onClose()} data-qa={config.pendo.meetAndGreet.modal.cancel}>{t.FORM.CANCEL()}</Button>
        <Button onClick={() => onSubmit()} data-qa={config.pendo.meetAndGreet.modal.save}>{isEdit ? t.FORM.UPDATE() : t.FORM.CREATE()}</Button>
      </div>
    </div>
  );
};

export default MeetAndGreetModal;
