/* @ngInject */
export default function MenteesPageHeaderController(

  CurrentCourseManager,
  $stateParams,
) {
  const vm = this;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.$stateParams = $stateParams;
}
