import { ReactElement } from 'react';
import t from 'react-translate';
import { CommunicationErrorCode, CommunicationType, HasLoggedInFilterType } from 'redux/schemas/models/course-communication';
import { i18nJoin, JoinMode } from 'shared/services/i18n-utils';
import { transformFirstCharacterToLowerCase, transformToActivityTitle, transformToErrorTitle } from '../components/communication-item/communication-row';

export const addCourseFilters = (filters, communicationProps, aliases, isSent, titles) => {
  if (communicationProps.communication?.communicationType === CommunicationType.NOT_COMPLETED) {
    let title;

    if (communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_THIRD_PARTY_COMPLETION)
      || communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_FEEDBACK_COMPLETION)) {
      title = transformToErrorTitle(
        t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.HAS_NOT_WITH_ACTIVITY,
        CommunicationErrorCode.UNABLE_TO_TRACK_THIRD_PARTY_COMPLETION,
        aliases,
        isSent,
        {
          activity: !communicationProps.showOwnerActivity
            ? t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.THIS_ACTIVITY()
            : titles.ownerActivityTitle,
        },
      );
    } else if (communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.MISSING_PROFILE_COMPLETION_REQUIRED_QUESTIONS)) {
      title = transformToErrorTitle(
        t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.HAS_NOT_WITH_ACTIVITY_QUESTION_MISSING,
        CommunicationErrorCode.MISSING_PROFILE_COMPLETION_REQUIRED_QUESTIONS,
        aliases,
        isSent,
        {
          activity: !communicationProps.showOwnerActivity
            ? t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.THIS_ACTIVITY()
            : titles.ownerActivityTitle,
        },
      );
    } else {
      title = transformToActivityTitle(
        t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.HAS_NOT_WITH_ACTIVITY,
        titles.ownerActivityTitle,
        !communicationProps.showOwnerActivity,
        true,
      );
    }

    filters.push({
      key: 'hasNotCompletedType',
      title,
    });
  }

  if (communicationProps.communication?.filters) {
    // has No Roles, Catalyst Roles or Team Roles
    if (communicationProps.communication.filters.courseRolesList) {
      // Reduce through role titles to get comma-separated(,|or) text
      let hasError: boolean = false;

      const rolesText = i18nJoin(
        communicationProps.rolesFilterTitles.map((title) => {
          let titleText: string;

          // Role missing error will be for whole communication. If the title is
          // not present that means this role is missing
          if (!title) {
            hasError = true;
            // Injecting a string here, so that it will be used to
            // split the text later to inject the error.
            titleText = '{ROLE_MISSING_ERROR}';
          } else {
            titleText = title === 'NO_ROLE'
              ? t.COURSE_COMMUNICATIONS.FILTERS.ROLES.NO_ROLES()
              : t.COURSE_COMMUNICATIONS.FILTERS.ROLES.TITLE(title);
          }

          return titleText;
        }),
        JoinMode.OR,
      );

      let title: ReactElement | string;
      if (hasError) {
        title = transformToErrorTitle(
          t.COURSE_COMMUNICATIONS.FILTERS.ROLES.DISPLAY,
          CommunicationErrorCode.COURSE_ROLE_DELETED_FILTER_ERROR,
          aliases,
          isSent,
          { rolesText },
        );
      } else {
        title = transformFirstCharacterToLowerCase(t.COURSE_COMMUNICATIONS.FILTERS.ROLES.DISPLAY(rolesText));
      }

      filters.push({
        key: 'courseRolesList',
        title,
      });
    }

    // enrolled in last X days
    if (communicationProps.communication.filters.enrolledInLastXDays) {
      filters.push({
        key: 'enrolledInLastXDays',
        title: transformFirstCharacterToLowerCase(t.COURSE_COMMUNICATIONS.FILTERS.ENROLLED_LAST_DAYS.LABEL(communicationProps.communication.filters.enrolledInLastXDays.toString())),
      });
    }

    // has been active at least once in the last X days
    if (communicationProps.communication.filters.daysActive) {
      filters.push({
        key: 'daysActive',
        title: transformFirstCharacterToLowerCase(t.COURSE_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.ACTIVE.LABEL(communicationProps.communication.filters.daysActive.toString())),
      });
    }

    // has been inactive for more than X days
    if (communicationProps.communication.filters.daysInactive) {
      filters.push({
        key: 'daysInactive',
        title: transformFirstCharacterToLowerCase(t.COURSE_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.INACTIVE.LABEL(communicationProps.communication.filters.daysInactive.toString())),
      });
    }

    // has completed <b>VIDEO : Title</b>
    if (communicationProps.communication.filters.hasCompleted) {
      let title: ReactElement | string;
      if (communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_COMPLETED_ACTIVITY_FEEDBACK_COMPLETION)
        || communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_COMPLETED_ACTIVITY_THIRD_PARTY_COMPLETION)) {
        title = transformToErrorTitle(
          t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.HAS_WITH_ACTIVITY,
          CommunicationErrorCode.UNABLE_TO_TRACK_COMPLETED_ACTIVITY_FEEDBACK_COMPLETION,
          aliases,
          isSent,
        );
      } else if (communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.MISSING_COMPLETED_PROFILE_COMPLETION_REQUIRED_QUESTIONS)
        && titles.hasCompletedActivityTitle) {
        title = transformToErrorTitle(
          t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.HAS_WITH_ACTIVITY_QUESTION_MISSING,
          CommunicationErrorCode.MISSING_COMPLETED_PROFILE_COMPLETION_REQUIRED_QUESTIONS,
          aliases,
          isSent,
          {
            activity: titles.hasCompletedActivityTitle,
          },
        );
      } else if (communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.MISSING_COMPLETED_ACTIVITY_FILTER_ERROR)
        || !titles.hasCompletedActivityTitle) {
        title = transformToErrorTitle(
          t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.HAS_WITH_ACTIVITY,
          CommunicationErrorCode.MISSING_COMPLETED_ACTIVITY_FILTER_ERROR,
          aliases,
          isSent,
        );
      } else {
        title = transformToActivityTitle(
          t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.HAS_WITH_ACTIVITY,
          titles.hasCompletedActivityTitle,
          false,
          true,
        );
      }

      filters.push({
        key: 'hasCompleted',
        title,
      });
    }

    // has not completed <b>VIDEO : Title</b>
    if (communicationProps.communication.filters.hasNotCompleted) {
      let title: ReactElement | string;
      if (communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_NOT_COMPLETED_ACTIVITY_FEEDBACK_COMPLETION)
        || communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_NOT_COMPLETED_THIRD_PARTY_COMPLETION)) {
        title = transformToErrorTitle(
          t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.HAS_NOT_WITH_ACTIVITY,
          CommunicationErrorCode.UNABLE_TO_TRACK_NOT_COMPLETED_ACTIVITY_FEEDBACK_COMPLETION,
          aliases,
          isSent,
        );
      } else if (communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.MISSING_NOT_COMPLETED_PROFILE_COMPLETION_REQUIRED_QUESTIONS)
        && titles.hasNotCompletedActivityTitle) {
        title = transformToErrorTitle(
          t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.HAS_NOT_WITH_ACTIVITY_QUESTION_MISSING,
          CommunicationErrorCode.MISSING_NOT_COMPLETED_PROFILE_COMPLETION_REQUIRED_QUESTIONS,
          aliases,
          isSent,
          {
            activity: titles.hasNotCompletedActivityTitle,
          },
        );
      } else if (communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.MISSING_NOT_COMPLETED_ACTIVITY_FILTER_ERROR)
        || !titles.hasNotCompletedActivityTitle) {
        title = transformToErrorTitle(
          t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.HAS_NOT_WITH_ACTIVITY,
          CommunicationErrorCode.MISSING_NOT_COMPLETED_ACTIVITY_FILTER_ERROR,
          aliases,
          isSent,
        );
      } else {
        title = transformToActivityTitle(
          t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.HAS_NOT_WITH_ACTIVITY,
          titles.hasNotCompletedActivityTitle,
          false,
          true,
        );
      }

      filters.push({
        key: 'hasNotCompleted',
        title,
      });
    }

    // has earned more than X points
    if (communicationProps.communication.filters.earnedMoreThanXPoints) {
      let title: ReactElement | string;
      if (communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.COURSE_POINTS_DISABLED)) {
        title = transformToErrorTitle(
          t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.MORE_THAN.LABEL,
          CommunicationErrorCode.COURSE_POINTS_DISABLED,
          aliases,
          isSent,
          { input: communicationProps.communication.filters.earnedMoreThanXPoints },
        );
      } else {
        title = transformFirstCharacterToLowerCase(t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.MORE_THAN.LABEL({
          input: communicationProps.communication.filters.earnedMoreThanXPoints,
          ...aliases.pointsAliases,
        }));
      }

      filters.push({
        key: 'earnedMoreThanXPoints',
        title,
      });
    }

    // has earned less than X points
    if (communicationProps.communication.filters.earnedLessThanXPoints) {
      let title: ReactElement | string;
      if (communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.COURSE_POINTS_DISABLED)) {
        title = transformToErrorTitle(
          t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.LESS_THAN.LABEL,
          CommunicationErrorCode.COURSE_POINTS_DISABLED,
          aliases,
          isSent,
          { input: communicationProps.communication.filters.earnedLessThanXPoints },
        );
      } else {
        title = transformFirstCharacterToLowerCase(t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.LESS_THAN.LABEL({
          input: communicationProps.communication.filters.earnedLessThanXPoints,
          ...aliases.pointsAliases,
        }));
      }

      filters.push({
        key: 'earnedLessThanXPoints',
        title,
      });
    }

    // completed less than X number of assignments that are marked as To-dos
    if (communicationProps.communication.filters.completedXAssignmentsAsTodo) {
      let title: ReactElement | string;

      if (communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.COURSE_NO_TODO_EXERCISES)) {
        title = transformToErrorTitle(
          t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ASSIGNMENTS.LABEL,
          CommunicationErrorCode.COURSE_NO_TODO_EXERCISES,
          aliases,
          isSent,
          { input: communicationProps.communication.filters.completedXAssignmentsAsTodo },
        );
      } else {
        title = transformFirstCharacterToLowerCase(t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ASSIGNMENTS.LABEL({
          input: communicationProps.communication.filters.completedXAssignmentsAsTodo,
          ...aliases.assignmentAliases,
        }));
      }

      filters.push({
        key: 'completedXAssignmentsAsTodo',
        title,
      });
    }

    // has completed the course
    if (communicationProps.communication.filters.hasCompletedCourse === true) {
      filters.push({
        key: 'hasCompletedCourse',
        title: transformFirstCharacterToLowerCase(t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_COURSE.HAS(aliases.courseAliases)),
      });
    }

    // has NOT completed the course
    if (communicationProps.communication.filters.hasCompletedCourse === false) {
      filters.push({
        key: 'hasNotCompletedCourse',
        title: transformFirstCharacterToLowerCase(t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_COURSE.HAS_NOT(aliases.courseAliases)),
      });
    }

    // has logged in
    if (communicationProps.communication.filters.hasLoggedIn) {
      let title: string;
      if (communicationProps.communication.filters.hasLoggedIn === HasLoggedInFilterType.HAS_LOGGED_IN) {
        title = t.COURSE_COMMUNICATIONS.FILTERS.LOGGED_IN.HAS();
      } else if (communicationProps.communication.filters.hasLoggedIn === HasLoggedInFilterType.HAS_NOT_SINCE_START_DATE) {
        title = t.COURSE_COMMUNICATIONS.FILTERS.LOGGED_IN.HAS_NOT_COURSE_DATE(aliases.courseAliases);
      } else if (communicationProps.communication.filters.hasLoggedIn === HasLoggedInFilterType.HAS_NOT_SINCE_RELEASE_DATE) {
        title = t.COURSE_COMMUNICATIONS.FILTERS.LOGGED_IN.HAS_NOT_RELEASE_DATE(aliases.courseAliases);
      }

      filters.push({
        key: 'hasLoggedIn',
        title: transformFirstCharacterToLowerCase(title),
      });
    }
  }
};

export const addJourneyFilters = (filters, communicationProps, aliases, isSent, courses, collections) => {
  if (communicationProps.communication?.filters) {
    // never active in the Journey
    if (communicationProps.communication.filters.neverActive) {
      filters.push({
        key: 'neverActive',
        title: transformFirstCharacterToLowerCase(t.JOURNEY_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.NEVER_ACTIVE.DEFAULT()),
      });
    }

    // not enrolled in any course in the journey
    if (communicationProps.communication.filters.notEnrolledToAnyCourse) {
      filters.push({
        key: 'notEnrolledToAnyCourse',
        title: transformFirstCharacterToLowerCase(t.JOURNEY_COMMUNICATIONS.FILTERS.NOT_ENROLLED_IN_ANY_COURSE.DEFAULT()),
      });
    }

    // enrolled in last X days
    if (communicationProps.communication.filters.enrolledInLastXDays) {
      filters.push({
        key: 'enrolledInLastXDays',
        title: transformFirstCharacterToLowerCase(t.JOURNEY_COMMUNICATIONS.FILTERS.ENROLLED_LAST_DAYS.LABEL(communicationProps.communication.filters.enrolledInLastXDays.toString())),
      });
    }

    // has been inactive for more than X days
    if (communicationProps.communication.filters.daysInactive) {
      filters.push({
        key: 'daysInactive',
        title: transformFirstCharacterToLowerCase(t.JOURNEY_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.INACTIVE.LABEL(communicationProps.communication.filters.daysInactive.toString())),
      });
    }

    // has enrolled to a specific course
    if (communicationProps.communication.filters.enrolledToCourseId) {
      const currentCourse = courses.find(course => course.id === communicationProps.communication.filters.enrolledToCourseId);
      let title: ReactElement | string;

      if (communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.MISSING_ENROLLED_COURSE_FILTER_ERROR)) {
        title = transformToErrorTitle(
          t.JOURNEY_COMMUNICATIONS.FILTERS.COURSE_ENROLLMENT.ENROLLED.LABEL,
          CommunicationErrorCode.MISSING_ENROLLED_COURSE_FILTER_ERROR,
          aliases,
          isSent,
          { name: currentCourse?.name },
        );
      } else {
        title = transformFirstCharacterToLowerCase(t.JOURNEY_COMMUNICATIONS.FILTERS.COURSE_ENROLLMENT.ENROLLED.LABEL(currentCourse?.name));
      }

      filters.push({
        key: 'enrolledToSpecificCourse',
        title,
      });
    }

    // has NOT enrolled to a specific course
    if (communicationProps.communication.filters.notEnrolledToCourseId) {
      const currentCourse = courses.find(course => course.id === communicationProps.communication.filters.notEnrolledToCourseId);
      let title: ReactElement | string;

      if (communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.MISSING_NOT_ENROLLED_COURSE_FILTER_ERROR)) {
        title = transformToErrorTitle(
          t.JOURNEY_COMMUNICATIONS.FILTERS.COURSE_ENROLLMENT.NOT_ENROLLED.LABEL,
          CommunicationErrorCode.MISSING_NOT_ENROLLED_COURSE_FILTER_ERROR,
          aliases,
          isSent,
          { name: currentCourse?.name },
        );
      } else {
        title = transformFirstCharacterToLowerCase(t.JOURNEY_COMMUNICATIONS.FILTERS.COURSE_ENROLLMENT.NOT_ENROLLED.LABEL(currentCourse?.name));
      }

      filters.push({
        key: 'notEnrolledToSpecificCourse',
        title,
      });
    }

    // has completed a specific course
    if (communicationProps.communication.filters.completedCourseId) {
      const currentCourse = courses.find(course => course.id === communicationProps.communication.filters.completedCourseId);
      let title: ReactElement | string;

      if (communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.MISSING_COMPLETED_COURSE_FILTER_ERROR)) {
        title = transformToErrorTitle(
          t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COURSE.COMPLETED.LABEL,
          CommunicationErrorCode.MISSING_COMPLETED_COURSE_FILTER_ERROR,
          aliases,
          isSent,
          { name: currentCourse?.name },
        );
      } else {
        title = transformFirstCharacterToLowerCase(t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COURSE.COMPLETED.LABEL(currentCourse?.name));
      }

      filters.push({
        key: 'completedSpecificCourse',
        title,
      });
    }

    // has NOT completed a specific course
    if (communicationProps.communication.filters.notCompletedCourseId) {
      const currentCourse = courses.find(course => course.id === communicationProps.communication.filters.notCompletedCourseId);
      let title: ReactElement | string;

      if (communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.MISSING_NOT_COMPLETED_COURSE_FILTER_ERROR)) {
        title = transformToErrorTitle(
          t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COURSE.NOT_COMPLETED.LABEL,
          CommunicationErrorCode.MISSING_NOT_COMPLETED_COURSE_FILTER_ERROR,
          aliases,
          isSent,
          { name: currentCourse?.name },
        );
      } else {
        title = transformFirstCharacterToLowerCase(t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COURSE.NOT_COMPLETED.LABEL(currentCourse?.name));
      }

      filters.push({
        key: 'notCompletedSpecificCourse',
        title,
      });
    }

    // has completed a specific collection
    if (communicationProps.communication.filters.completedCollectionId) {
      const currentCollection = collections.find(collection => collection.id === communicationProps.communication.filters.completedCollectionId);
      const collectionName = currentCollection?.name?.length ? currentCollection.name : t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COLLECTION.COLLECTION_GENERIC_NAME();
      let title: ReactElement | string;

      if (communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.MISSING_COMPLETED_COLLECTION_FILTER_ERROR)) {
        title = transformToErrorTitle(
          t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COLLECTION.COMPLETED.LABEL,
          CommunicationErrorCode.MISSING_COMPLETED_COLLECTION_FILTER_ERROR,
          aliases,
          isSent,
          { name: collectionName },
        );
      } else {
        title = transformFirstCharacterToLowerCase(t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COLLECTION.COMPLETED.LABEL(collectionName));
      }

      filters.push({
        key: 'completedSpecificCollection',
        title,
      });
    }

    // has NOT completed a specific collection
    if (communicationProps.communication.filters.notCompletedCollectionId) {
      const currentCollection = collections.find(collection => collection.id === communicationProps.communication.filters.notCompletedCollectionId);
      const collectionName = currentCollection?.name?.length ? currentCollection.name : t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COLLECTION.COLLECTION_GENERIC_NAME();
      let title: ReactElement | string;

      if (communicationProps.communication.errorCodes?.includes(CommunicationErrorCode.MISSING_NOT_COMPLETED_COLLECTION_FILTER_ERROR)) {
        title = transformToErrorTitle(
          t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COLLECTION.NOT_COMPLETED.LABEL,
          CommunicationErrorCode.MISSING_NOT_COMPLETED_COLLECTION_FILTER_ERROR,
          aliases,
          isSent,
          { name: collectionName },
        );
      } else {
        title = transformFirstCharacterToLowerCase(t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_COLLECTION.NOT_COMPLETED.LABEL(collectionName));
      }

      filters.push({
        key: 'notCompletedSpecificCollection',
        title,
      });
    }

    // has completed the journey
    if (communicationProps.communication.filters.hasCompletedJourney === true) {
      filters.push({
        key: 'hasCompletedJourney',
        title: transformFirstCharacterToLowerCase(t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_JOURNEY.HAS(aliases.courseAliases)),
      });
    }

    // has NOT completed the journey
    if (communicationProps.communication.filters.hasCompletedJourney === false) {
      filters.push({
        key: 'hasNotCompletedJourney',
        title: transformFirstCharacterToLowerCase(t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_JOURNEY.HAS_NOT(aliases.courseAliases)),
      });
    }
  }
};
