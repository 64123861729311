/* @ngInject */
export default function TeamMembershipModelService(

  TeamMembershipsResource,
  _,
  moment,
) {
  const TeamMembershipModel = function (attributes) {
    const _this = this;

    _.extend(_this, attributes);

    _this.leaveTeam = leaveTeam;
    _this.fire = fire;
    _this.makeAdmin = makeAdmin;
    _this.removeAdmin = removeAdmin;

    preprocess();

    function preprocess() {
      if (_this.visitedWorkspaceAt) {
        const visitedWorkspaceAtMoment = moment(_this.visitedWorkspaceAt);
        const currentMoment = moment();

        if (currentMoment.isSame(visitedWorkspaceAtMoment, 'd')) {
          _this.visitedWorkspaceAtText = visitedWorkspaceAtMoment.format('LT');
        } else if (currentMoment.isSame(visitedWorkspaceAtMoment, 'year')) {
          _this.visitedWorkspaceAtText = visitedWorkspaceAtMoment.format('MOMENT.MONTH_DAY');
        } else {
          _this.visitedWorkspaceAtText = visitedWorkspaceAtMoment.format('MOMENT.MONTH_DAY_YY');
        }
      }
    }

    function leaveTeam(options) {
      return TeamMembershipsResource.leave(
        { catalogId: options.catalogId, id: options.teamId },
        { removeSubmittings: options.removeSubmittings || null },
      ).$promise;
    }

    function fire(options) {
      return TeamMembershipsResource.fire(
        { catalogId: options.catalogId, id: options.teamId, teamMemberId: _this.id },
        {},
      ).$promise;
    }

    function makeAdmin(options) {
      let makeAdminCall;
      if (options.isTeam) {
        makeAdminCall = TeamMembershipsResource.makeAdmin;
      } else {
        makeAdminCall = TeamMembershipsResource.makeGroupAdmin;
      }
      return makeAdminCall(
        { catalogId: options.catalogId, id: options.teamId, teamMemberId: _this.id },
        {},
      ).$promise.then(() => {
        _this.isAdmin = true;
      });
    }

    function removeAdmin(options) {
      return TeamMembershipsResource.removeAdmin(
        { catalogId: options.catalogId, id: _this.id },
        {},
      ).$promise.then(() => {
        _this.isAdmin = false;
      });
    }
  };

  TeamMembershipModel.sortByWorkspaceVisit = function (a, b) {
    if (a.workspaceVisitInPastWeek && !b.workspaceVisitInPastWeek) {
      return -1;
    } if (!a.workspaceVisitInPastWeek && b.workspaceVisitInPastWeek) {
      return 1;
    }

    if (a.joinedAt > b.joinedAt) {
      return -1;
    } if (a.joinedAt < b.joinedAt) {
      return 1;
    }

    if (a.id > b.id) {
      return -1;
    }

    return 1;
  };

  return TeamMembershipModel;
}
