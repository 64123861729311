/* @ngInject */
export default function TimelinesResources(

  $resource,
) {
  return $resource(
    '/:catalogId/users/:userId/course_timeline.json',
    {},
    {
      get: {
        cancellable: true,
      },
      translateTimeline: { method: 'POST', url: '/courses/:catalogId/translate_outline' },

      getCurrentLecture: { method: 'GET', url: '/:catalogId/users/current_lecture.json' },
      getAdminCredits: { method: 'GET', url: '/:catalogId/users/awarded_points.json?viewee_id=:vieweeId' },
      getLectureSection: { method: 'GET', url: '/:catalogId/users/:userId/course_timeline/lecture_sections/:id' },
      getLecturePage: { method: 'GET', url: '/:catalogId/users/:userId/course_timeline_lecture_page.json?lecture_page_id=:id&edit_mode=:isEditMode' },

      createLectureSection: { method: 'POST', url: '/:catalogId/lecture_sections' },
      createLecturePage: { method: 'POST', url: '/:catalogId/lecture_pages.json' },
      createFromPreset: { method: 'POST', url: '/:catalogId/lecture_sections/template_create.json?template_name=:templateName' },

      copyLectureSection: { method: 'POST', url: ':catalogId/lecture_sections/:lectureSection/copy_to.json' },
      copyLecturePage: { method: 'POST', url: '/:catalogId/lecture_pages/:lecturePage/copy_to.json' },

      getPrerequisites: { method: 'GET', url: '/:catalogId/activity_prerequisites.json' },
      reorder: { method: 'PUT', url: '/:catalogId/lecture_sections/batch_update.json' },
      getActivities: { method: 'GET', url: ':catalogId/lecture_pages/:id/activities.json' },
      getActivitiesInSection: { method: 'GET', url: ':catalogId/lecture_sections/:lectureSectionId/activities.json' },
      toggleCardView: { method: 'POST', url: '/:catalogId/dashboard/toggle_card_view.json' },

      updateLectureSection: { method: 'PUT', url: '/:catalogId/lecture_sections/:lectureSectionId' },
      duplicateLectureSection: { method: 'POST', url: '/:catalogId/lecture_sections/:lectureSectionId/duplicate.json' },
      deleteLectureSection: { method: 'DELETE', url: '/:catalogId/lecture_sections/:lectureSectionId?keep_content=:keepContent' },

      updateLectureComponent: { method: 'PUT', url: '/:catalogId/lecture_pages/:lecturePageId/:lectureComponentType/:lectureComponentId' },
      bulkUpdateActivityDeadline: { method: 'PUT', url: '/:catalogId/lecture_pages/:lecturePageId/bulk_update_deadline.json' },
      bulkUpdateDeadline: { method: 'PUT', url: '/:catalogId/lecture_sections/:lectureSectionId/bulk_update_deadline.json' },
      bulkUpdateReleaseDates: { method: 'PUT', url: '/:catalogId/lecture_sections/:lectureSectionId/bulk_update_release_date.json' },
    },
  );
}
