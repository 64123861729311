import React from 'react';
import { css } from '@emotion/react';
import { mapObject } from 'underscore';

import { getScrollParent } from 'shared/utils';
import { mergeRefs } from 'shared/react-utils';
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';

type Triggers = { [key: string]: number };
type ScrollingState = { [key: string]: boolean };

type Props = {
  triggers: Triggers,
  children: (scrollingState: ScrollingState) => React.ReactNode,
  sticky?: boolean,
  className?: string,
};

/**
 * Base component to build sticky headers, see an example in
 * app/learning_journeys/components/navigation-header.tsx.
 * NOTE: Make sure at any moment of using this component that the triggers
 * object has all the time the same amount of properties. Otherwise we may get a
 * "Rendered fewer/more hooks than during the previous render" error.
 */
const StickyHeader = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    triggers,
    className,
    sticky = true,
  } = props;

  const innerRef = React.useRef<HTMLDivElement>();
  const scrollParentRef = React.useRef<HTMLElement>();

  React.useEffect(() => {
    scrollParentRef.current = getScrollParent(innerRef.current);
  }, []);

  const scrollingState = mapObject(
    triggers,
    // NOTE: By using this hook to achieve what we want here it comes with a
    // limitation, mentioned in JSDoc comment of the component.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    (triggerValue) => useInfiniteScroll(scrollParentRef.current, triggerValue, false),
  );

  const styles = sticky ? css`
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
  ` : css`
    width: 100%;
  `;

  return (
    <div
      css={styles}
      className={className}
      ref={mergeRefs(ref, innerRef)}
    >
      {children(scrollingState)}
    </div>
  );
});

export default StickyHeader;
