import _ from 'lodash';
import { useEffect, useState } from 'react';

// Redux
import { RootState } from 'redux/schemas';
import { getLectureComponent } from 'redux/selectors/timeline';
import { useSelector } from 'react-redux';
import { ComponentType } from 'redux/schemas/models/lecture-component';
import { getSkillTagsFromTaggings } from 'redux/selectors/skills-feedback';

// Styles
import { css } from '@emotion/react';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { getSanitizedStyles } from 'shared/utils';

// Components
import SkillViewBody from './skill-view-body';
import SkillViewTitle from './skill-view-title';

enum SkillViewWidth {
  COLLAPSED = 240,
  EXPANDED = 490,
}

const styles = css`
  position: absolute;
  top: 0;
  .skill-view-container {
    background-color: white;
    border-radius: 0 0 ${standardSpacing}px ${standardSpacing}px;
    padding: ${halfSpacing}px ${standardSpacing}px;
    flex-direction: column;
    box-shadow: 0px 4px 4px 0px #00000040;
    .skill-view-wrapper {
      gap: ${halfSpacing}px;
      flex-wrap: wrap;
      max-width: 490px;
    }
  }
`;

type LecturePageSkillViewProps = {
  lectureComponents: number[];
};

const LecturePageSkillView = (props: LecturePageSkillViewProps) => {
  const { lectureComponents: lectureComponentIds } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const skillTagsForVideoPractice = [];

  const lectureComponents = useSelector((state: RootState) => _.map(lectureComponentIds, (id) => getLectureComponent(state, id)));
  // Get the skill taggings from the lecture components
  // note that every skillTaggings prop is number[]
  // that's the reason we are using flatten
  const skillTaggings: number[] = _.flatten(lectureComponents.map((lc) => {
    if (lc.type !== ComponentType.VIDEO_PRACTICE) {
      return lc.skillTaggings;
    }
    // Video Practice has a different structure for skillTaggings
    // We need to get the skill tags from the scenario
    const st = lc.videoPractice?.scenario?.skillTaggings?.map((st) => st.skillTag) ?? [];
    skillTagsForVideoPractice.push(...st);
    return null;
  }));
  // Check if there are skill tags to retrieve based on the skillTaggings
  const hasSkills = skillTaggings.length === 1 ? !!skillTaggings[0] : skillTaggings.length > 0;
  const hasSkillTags = !!skillTagsForVideoPractice.length || hasSkills;
  // If there are skill taggings, we get all of them based on the skillTaggings
  const skillTags = useSelector((state: RootState) => getSkillTagsFromTaggings(state, skillTaggings ?? []));
  // Filter skillTags to avoid duplications
  const filteredSkillTags = hasSkillTags ? _.uniqBy([...skillTags, ...skillTagsForVideoPractice], 'id') : [];

  const width = isExpanded ? SkillViewWidth.EXPANDED : SkillViewWidth.COLLAPSED;
  const skillViewStyles = css`
    left: calc(50% - ${width / 2}px);
    width: ${width}px;
  `;

  return filteredSkillTags.length > 0 ? (
    <div
      className='d-flex align-items-center justify-content-center'
      css={getSanitizedStyles([styles, skillViewStyles])}
    >
      <div className='skills-container'>
        <div className='d-flex align-items-center skill-view-container'>
          <SkillViewTitle
            isExpanded={isExpanded}
            skillTagsCount={filteredSkillTags.length}
            setIsExpanded={setIsExpanded}
          />
          {isExpanded && <SkillViewBody skillTags={filteredSkillTags} />}
        </div>
      </div>
    </div>
  ) : null;
};

export default LecturePageSkillView;
