import React from 'react';
import { css } from '@emotion/react';

import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import { threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import {
  openSans,
  textSmallFontSize,
  semiBoldFontWeight,
  textSmallLineHeight,
} from 'styles/global_defaults/fonts';

type Props = {
  className?: string,
  style?: React.CSSProperties,
};

const UnmetRequirementOverlay = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    style,
    className,
  } = props;

  const styles = css`
    color: #fff;
    text-align: center;

    .icon {
      margin-bottom: ${threeQuartersSpacing}px;
    }

    .info {
      color: #fff;
      font-family: ${openSans}px;
      font-size: ${textSmallFontSize}px;
      font-weight: ${semiBoldFontWeight};
      line-height: ${textSmallLineHeight}px;
    }
  `;

  return (
    <div
      ref={ref}
      css={styles}
      style={style}
      className={`d-flex flex-column justify-content-center${className ? ` ${className}` : ''}`}
    >
      <NvIcon
        icon='ban'
        size='small'
      />
      <div className='info'>{t.OFFERINGS.CARD.UNMET_REQUIREMENT()}</div>
    </div>
  );
});

export default UnmetRequirementOverlay;
