import sanitizeHtml from 'sanitize-html';
import { FROALA_TEXT_CLASSES, PASTE_STRIP_ATTRS } from 'froala/helpers/nv-froala-constants';
import isMostlyRTLText from './isMostlyRTLText';

export const cleanupNovoEdCode = (html: string): string => {
  const $clipboardHTML = $(`<div>${html}</div>`);

  PASTE_STRIP_ATTRS.forEach(attr => $clipboardHTML.find(`[${attr}]`).removeAttr(attr));

  // clean up mentions
  $clipboardHTML.find('[oe-mention]')
    .each(function () {
      const $this = $(this);
      const $span = $('<span />');

      $span.attr('oe-mention', '')
        .attr('data-user-id', $this.attr('data-user-id'))
        .attr('user', $this.attr('user'))
        .html($this.html());

      $this.replaceWith([$span, '&nbsp;']);
    });

  return $clipboardHTML.html();
};

const FONT_WEIGHT_ATTRIBUTE = 'font-weight';
const TEXT_DECORATION_ATTRIBUTE = 'text-decoration';
const FONT_STYLE_ATTRIBUTE = 'font-style';
const FONT_COLOR_ATTRIBUTE = 'color';

function hasNoMeaningfulAttributes(node: HTMLElement): boolean {
  if (!node.hasAttributes()
      || (node.attributes.length === 1 && node.attributes[0].name === 'style' && !node.attributes[0].value.trim())) {
    return true;
  }

  return false;
}

export enum SanitizationLevel {
  SIMPLE = 'simple',
  PLAIN = 'plain',
  BASIC = 'basic',
  NORMAL = 'normal',
  NO_TAGS = 'noTags',
  BOOKMARK_SNIPPET = 'bookmarkSnippet',
}

const BLOCK_TAGS = [
  'p',
  'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
  'ol', 'ul',
  'pre',
  'address',
  'blockquote',
  'dl',
  'div',
];

const INLINE_TAGS = ['br', 'span', 'a', 'strong', 'b', 'em', 'u'];

const normalAllowedTags = [
  'div', 'p', 'br',
  'span', 'a', 'strong', 'b', 'em', 'u',
  'img',
  'ul', 'ol', 'li',
  'table', 'thead', 'th', 'tbody', 'tr', 'td',
];

const normalAllowedAttributes = {
  '*': ['style'],
  a: ['href', 'name', 'target'],
  img: ['src', 'alt'],
  span: ['oe-mention', 'data-user-id', 'user'],
};

const allowedStyles = {
  [TEXT_DECORATION_ATTRIBUTE]: [/^(underline)$/],
  [FONT_WEIGHT_ATTRIBUTE]: [/^(bold|[0-9]+)$/],
  [FONT_STYLE_ATTRIBUTE]: [/^italic$/],
  [FONT_COLOR_ATTRIBUTE]: [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
};

const SANITIZE_CONFIGS = {
  // this configuration should be renamed. it is just basic but without lists
  simple: {
    allowedTags: [
      'span', 'strong', 'b', 'em', 'u',
    ],
    allowedAttributes: {
      '*': ['style'],
    },
    allowedClasses: {
      div: FROALA_TEXT_CLASSES,
      p: FROALA_TEXT_CLASSES,
    },
    allowedStyles: {
      '*': allowedStyles,
    },
  },
  plain: {
    allowedTags: [
      'p', 'br',
    ],
  },
  basic: {
    allowedTags: [
      'div', 'p', 'br',
      'span', 'a', 'strong', 'b', 'em', 'u',
      'ul', 'ol', 'li',
    ],
    allowedAttributes: {
      '*': ['style'],
      a: ['href', 'name', 'target'],
    },
    allowedClasses: {
      div: FROALA_TEXT_CLASSES,
      p: FROALA_TEXT_CLASSES,
    },
    allowedStyles: {
      '*': allowedStyles,
    },
  },
  normal: {
    allowedTags: normalAllowedTags,
    allowedAttributes: normalAllowedAttributes,
    allowedClasses: {
      div: FROALA_TEXT_CLASSES,
      p: FROALA_TEXT_CLASSES,
    },
    allowedStyles: {
      '*': allowedStyles,
    },
  },
  noTags: {
    allowedTags: [],
  },
  bookmarkSnippet: {
    allowedTags: normalAllowedTags.filter((tag) => INLINE_TAGS.includes(tag)),
    allowedAttributes: Object.entries(normalAllowedAttributes).reduce((acc, [key, value]) => {
      if (INLINE_TAGS.includes(key)) {
        acc[key] = value;
      }

      return acc;
    }, {}),
  },
};

/**
 * sanitizes user html to allow only allow what's specified below
 * there is a corresponding backend sanitization, so pleas make sure the backend is updated when you add to this list
 * @param html user generated html
 */
export const sanitize = (html: string, sanitizationLevel: SanitizationLevel = SanitizationLevel.BASIC): string => {
  const sanitizeConfig = SANITIZE_CONFIGS[sanitizationLevel];

  let currentHtml = html;

  if (!sanitizeConfig.allowedTags.includes('ul') || !sanitizeConfig.allowedTags.includes('ol')) {
    const jqueryElement = $(`<div>${currentHtml}</div>`);

    const listenerListTransformer = function () {
      const list = $(this);

      list.find('li').each(function (index) {
        const suffix = (list.prop('tagName') === 'OL')
          ? `${index + 1}. `
          : '• ';

        const li = $(this);
        li.text(`${suffix} ${li.text()} `);
      });
    };

    jqueryElement.find('ul, ol').each(listenerListTransformer);

    currentHtml = jqueryElement.unwrap().html();
  }

  const sanitizedHtml = sanitizeHtml(currentHtml, sanitizeConfig);

  /* The following code converts all the inline style css of bold, italics, and underline into their corresponding tags
    so that the user can interact with them through the rte
    */
  const $sanitizedHtml = $(`<div>${sanitizedHtml}</div>`);

  // bold
  $sanitizedHtml.find(`[style*="${FONT_WEIGHT_ATTRIBUTE}"]`)
    .each(function () {
      const $this = $(this);
      const originalFontWeight = $this.css(FONT_WEIGHT_ATTRIBUTE);

      if (originalFontWeight === 'bold' || parseInt(originalFontWeight, 10) >= 700) {
        $this.wrapInner('<strong></strong>');
      }

      $this.css(FONT_WEIGHT_ATTRIBUTE, '');

      $(this).replaceWith([$this]);
    });

  // italics
  $sanitizedHtml.find(`[style*="${FONT_STYLE_ATTRIBUTE}"]`)
    .each(function () {
      const $this = $(this);
      // i couldn't find a good way to incorporate the following condition into the jquery find; please feel free to fix
      // the tricky part is that technically there may be any number of spaces between the property and value
      if ($this.css(FONT_STYLE_ATTRIBUTE).includes('italic')) {
        $this.css(FONT_STYLE_ATTRIBUTE, '').wrapInner('<em></em>');
      }
    });

  // underline
  $sanitizedHtml.find(`[style*="${TEXT_DECORATION_ATTRIBUTE}"]`)
    .each(function () {
      const $this = $(this);
      if ($this.css(TEXT_DECORATION_ATTRIBUTE).includes('underline')) {
        $this.css(TEXT_DECORATION_ATTRIBUTE, '').wrapInner('<u></u>');
      }
    });

  // remove unnecessary spans that have no attributes
  $sanitizedHtml.find('span').each(function () {
    if (hasNoMeaningfulAttributes(this)) {
      $(this).contents().unwrap();
    }
  });

  // remove unnecessary p tags that have no attributes but are wrapped by another immediate tag
  // p tags have specific styles in NovoEd, but sources often like to add it as a wrapper
  $sanitizedHtml.find('p:first-child:last-child').each(function () {
    if (hasNoMeaningfulAttributes(this)) {
      $(this).contents().unwrap();
    }
  });

  // set the direction of block level contents based on its text
  $sanitizedHtml.children().each(function () {
    if (BLOCK_TAGS.includes(this.tagName.toLowerCase())) {
      $(this).attr('dir', isMostlyRTLText(this.innerText) ? 'rtl' : 'ltr');
    }
  });

  return $sanitizedHtml.html();
};
