type Ctor<T> = new() => T;

export default class Singleton {
  private static instance: Record<string, any> = {};

  public static Instance<T>(Type: Ctor<T>): T {
    if (!Singleton.instance[Type.name]) {
      Singleton.instance[Type.name] = new Type();
    }

    return Singleton.instance[Type.name] as T;
  }
}
