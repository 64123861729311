export default class PusherChannelNames {
  static announcementsChannel = 'public-announcements';

  static userChannel(identifier): string {
    return `public-${identifier}`;
  }

  static userCourseChannel(identifier, courseId): string {
    return `public-${courseId}-${identifier}`;
  }

  static courseChannel(courseId): string {
    return `public-course-${courseId}`;
  }

  static discussionsChannelCourse(courseId): string {
    return `public-course-discussions-${courseId}`;
  }

  static discussionsChannelReport(reportId): string {
    return `submission-discussions-${reportId}`;
  }
}
