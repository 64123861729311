/* @ngInject */
export default function QuizLectureComponentModel(
  _,
  nvUtil,
  moment,
  $translate,
  $window,
  LectureComponentBaseModel,
  QuizModel,
  RailsRoutes,
  CurrentCourseManager,
  config,
) {
  class QuizLectureComponent extends LectureComponentBaseModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      const defaults = {
        type: 'QuizLectureComponent',
        editFormTemplateUrl: 'lecture_pages/templates/components/quiz-edit-modal.html',
        formName: 'editQuizForm',
        quiz: {
          title: '',
          expirationDate: null,
          hardDeadline: false,
          graded: true,
          maximumAttempts: null,
          isTodo: false,
        },
        isActivity: true,
        canBeCopied: true,
      };
      super(_.extend({}, defaults, attributes));

      this.__preprocess();

      if (useDefaults) {
        this.createDraft();
      }
    }

    __preprocess() {
      const quiz = new QuizModel(_.extend(this.quiz, { catalogId: this.catalogId }));
      this.quiz = quiz;
      this.scheduledCommunicationsCount = this.quiz.scheduledCommunicationsCount;

      if (this.quiz.expirationDate) {
        this.__deadlineMoment = moment(this.quiz.expirationDate);
      }

      if (quiz.unsubmittedSubmission?.lastSavedAt) {
        quiz.setResponses(quiz.unsubmittedSubmission.responses);
      } else if (quiz.submission.responses) {
        quiz.setResponses(quiz.submission.responses);
        this.shouldShowFeedback = true;
      }
    }


    /* Student Functions */
    getStatus() {
      return this.quiz.progress;
    }

    isNotStarted() {
      return this.quiz.progress === 'not_started';
    }

    inProgress() {
      return this.quiz.progress === 'in_progress';
    }

    isCompleted() {
      return this.quiz.progress === 'completed';
    }

    isMissed() {
      return this.quiz.progress === 'missed';
    }

    minCorrectAnswers() {
      return this.quiz.pointsConfiguration
        ? Math.ceil(this.quiz.pointsConfiguration.threshold * this.quiz.answerableQuestionsCount)
        : 0;
    }

    canShowTotalPoints() {
      return (this.isMissed() || this.isCompleted())
        && this.quiz.pointsReceived < this.currentTotalPoints();
    }

    hasQuizAttemptsRemaining() {
      if (this.quiz.graded) {
        if (this.isPastHardDue()) {
          return false;
        }
        if (this.quiz.submission?.numberOfAttempts) {
          return this.quiz.submission.numberOfAttempts < this.quiz.maximumAttempts;
        }
        return true;
      }
      return true;
    }

    currentTotalPoints() {
      return nvUtil.getCurrentTotalPoints(this.quiz.totalPoints, this.releaseDate, CurrentCourseManager.course.isDecayEnabled());
    }

    submittedButCanGetFullThresholdPoints() {
      return this.quiz.pointsConfiguration
          && !this.quiz.pointsConfiguration.rewardsPointsProportionally
          && !!this.quiz.submission
          && this.quiz.submission.numberOfAttempts < this.quiz.maximumAttempts
          && this.quiz.pointsReceived < this.currentTotalPoints();
    }

    receivedMaxScore() {
      return !!this.quiz.submission && this.quiz.submission.score && this.quiz.submission.maxScore === this.quiz.submission.score;
    }

    isPastHardDue() {
      return this.quiz.hardDeadline && this.quiz.expired;
    }

    /* Admin Functions */
    createDraft() {
      this.quizDraft = _.clone(this.quiz);
    }

    save(doNotUpdate = false) {
      if (!this.quiz.title) {
        this.quiz.title = $translate.instant('LECTURE_PAGES.COMPONENTS.UNTITLED');
      }

      return super.save(doNotUpdate).then(() => {
        this.__preprocess();

        return this;
      });
    }

    getPayload() {
      return {
        quiz: _.pick(this.quiz, ['id', 'title', 'expirationDate', 'hardDeadline', 'graded', 'maximumAttempts', 'isTodo']),
      };
    }

    saveDraft() {
      _.extend(this.quiz, this.quizDraft);
    }

    afterCreate(catalogId) {
      $window.location = RailsRoutes.editQuestionSetPath(catalogId, this.quiz.id);
    }

    moveToWarningMessages(targetLecturePage, omitParentWarnings) {
      const warningMessages = super.moveToWarningMessages(targetLecturePage);
      // NOV-67169 added this parameter so we don't get duplicated warnings on timed quiz,
      // but we still get base lecture component warnings added if needed
      if (omitParentWarnings) {
        return warningMessages;
      }

      if (this.lecturePage.released && !targetLecturePage.released) {
        warningMessages.push($translate.instant('LECTURE_PAGES.COMPONENTS.QUIZ.MOVE.UNRELEASE', CurrentCourseManager.course.getAliases()));
      }

      if (this.quiz.expirationDate && moment(this.quiz.expirationDate) < moment(targetLecturePage.releaseDate)) {
        warningMessages.push($translate.instant('LECTURE_PAGES.COMPONENTS.QUIZ.MOVE.DEADLINE', CurrentCourseManager.course.getAliases()));
      }

      return warningMessages;
    }

    isTodo() {
      return this.quiz.isTodo;
    }

    toggleToDo() {
      this.quiz.isTodo = !this.quiz.isTodo;
      this.save();
    }

    isRequiredForCompletion() {
      return this.quiz.isRequiredForCompletion;
    }

    hasDeadline() {
      return !!this.quiz.expirationDate;
    }

    addDefaultDeadline() {
      this.quiz.expirationDate = this.defaultDeadline;
      return this.save();
    }

    removeDeadline() {
      this.quiz.expirationDate = null;
      return this.save();
    }

    get deadline() {
      return this.quiz.expirationDate;
    }

    isDueSoon() {
      return super.isDueSoon(this.quiz.expirationDate);
    }

    hardDeadlinePassed() {
      return this.quiz.hardDeadline && this.quiz.expirationDate && this.__deadlineMoment < moment();
    }
  }

  QuizLectureComponent.iconClass = 'icon-quiz';
  QuizLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.QUIZ.DESCRIPTION';
  QuizLectureComponent.showModalBeforeCreate = true;
  QuizLectureComponent.pendoTagName = config.pendo.lectureEdit.quiz;

  return QuizLectureComponent;
}
