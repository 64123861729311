/* @ngInject */
export default function TimelineBaseController(
  $scope,
  CurrentUserManager,
  CurrentCourseManager,
  TimelinesManager,
  $state,
  $stateParams,
) {
  const vm = this;

  vm.manager = TimelinesManager;
  vm.TimelinesManager = TimelinesManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.$stateParams = $stateParams;
  vm.$state = $state;
  vm.manager.selectedTab = 'Outline'; // this is only on the manager because the lightbox header and main need to be able to talk to each other

  vm.selectTab = function (tab) {
    vm.manager.selectedTab = tab;
  };
}
