import getDefaultAddMenuComponentData from 'lecture_pages/components/data/add-menu-defaults';
import getDefaultNewComponentData from 'lecture_pages/components/data/new-component-defaults';
import LhsAccordionComponent from 'lecture_pages/directives/components/lhs-accordion-component';
import React from 'react';
import { AccordionListStyleType, AccordionSectionType, ComponentType, NLectureComponent } from 'redux/schemas/models/lecture-component';

const AccordionContentPreview = (props: { style: AccordionSectionType, type: AccordionListStyleType }) => {
  const lhsDefaultComponentData = {
    view_options: {
      list_style_type: props.type,
    },
    accordion_sections: [
      {
        index: 0,
        content: '',
        header: '',
        ...getDefaultNewComponentData(props.style),
      },
    ],
  };
  const defaultComponentData = getDefaultAddMenuComponentData(ComponentType.ACCORDION_SECTION, props.style) as NLectureComponent<AccordionSectionType>;
  const lectureComponent = { ...lhsDefaultComponentData, ...defaultComponentData };

  return (
    <LhsAccordionComponent model={lectureComponent} />
  );
};

export default AccordionContentPreview;
