// The purpose of this directive is to have an error callback on an element such as a video
/* @ngInject */
export default function NvError($parse) {
  return {
    restrict: 'A',
    compile($element, attr) {
      const fn = $parse(attr.nvError);

      return function (scope, element) {
        element.on('error', (event) => {
          scope.$apply(() => {
            fn(scope, { $event: event });
          });
        });
      };
    },
  };
}
