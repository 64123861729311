
import { css } from '@emotion/react';

// Styles
import { gray4, gray5 } from 'styles/global_defaults/colors';
import { standardSpacing, doubleSpacing } from 'styles/global_defaults/scaffolding';
import { boldFontWeight } from 'styles/global_defaults/fonts';

// Components
import NvIcon from 'shared/components/nv-icon';

interface EmptyListProps {
  icon: string,
  description: string
}

const styles = css`
  .no-content {
    margin-top: ${doubleSpacing}px;
    text-align: center;
    color: ${gray5};
  }
  .description {
    margin-top: ${standardSpacing}px;
    color: ${gray4};
    text-align: center;
    font-weight: ${boldFontWeight};
  }
`;

const EmptyList = (props: EmptyListProps) => (
  <div css={styles}>
    <div className='no-content'>
      <NvIcon size='ultra-large' icon={props.icon} />
    </div>
    <div className='description'>{props.description}</div>
  </div>
);

export default EmptyList;
