import Scratchpad from '../components/scratchpad';

export default class ScratchpadCtrl {
  /* @ngInject */
  constructor(
    $scope,
  ) {
    $scope.Scratchpad = Scratchpad;
  }
}
