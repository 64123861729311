import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { keys } from 'underscore';

// Schemas
import { RootState } from 'redux/schemas';
import { PracticeRoomTab, SortOrFilterOption, VideoPracticeScenario } from 'redux/schemas/models/video-practice';
import { GallerySubmissionsState, SubmissionsListstate } from 'redux/schemas/app/video-practice';

// Actions
import { getVideoPracticeSubmissions } from 'redux/actions/video-practice';

// Selectors
import { getGallerySubmissions, getScenario, getSubmissionIds } from 'redux/selectors/video-practice';

// Hooks
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';

// Styles
import {
  standardSpacing,
  largeSpacing,
  doubleSpacing,
  extraLargeSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import { handheld } from 'styles/global_defaults/media-queries';

// Components
import ClickableContainer from 'components/clickable-container';
import TabLoadingWrapper from '../shared/tab-loading-wrapper';
import EmptyTab from '../shared/empty-tab';
import SortModePill from './sort-mode-pill';
import GalleryCard from '../shared/gallery-card';

const styles = () => css`
  margin-top: ${doubleSpacing}px;
    ${handheld(css`
      margin-top: ${extraLargeSpacing}px;
    `)};

  .filter-container {
    margin-bottom: ${largeSpacing}px;
    ${handheld(css`
      margin-bottom: ${standardSpacing}px;
    `)};
  }

  .empty-tab-wrapper {
    padding-top: ${tripleSpacing}px
  }
`;

const GalleryTab = () => {
  const dispatch = useAppDispatch();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [sortOrFilterBy, setSortOrFilterBy] = useState<SortOrFilterOption>(null);

  const scenarioId = useSelector((state) => state.app.practiceRoom.params.scenarioId);
  const selectedTab = useSelector((state) => state.app.practiceRoom.params.selectedTab);
  const {
    isOnceLoaded, isLoading,
    hasMore,
  } = useSelector<RootState, GallerySubmissionsState>((state) => getGallerySubmissions(state));
  const submissionIds = useSelector<RootState, number[]>((state) => getSubmissionIds(state, {
    selectedTab: PracticeRoomTab.GALLERY,
    isFilterApplied: !!sortOrFilterBy,
  }));
  const { isPracticeAdmin } = useSelector<RootState, VideoPracticeScenario>((state) => getScenario(state, scenarioId));

  const scrollingElement: HTMLElement = document.querySelector('.tab-content');
  const isPageBottom = useInfiniteScroll(scrollingElement, 40);

  useEffect(() => {
    if (selectedTab === PracticeRoomTab.GALLERY) {
      dispatch(getVideoPracticeSubmissions({
        scenarioId,
        tab: PracticeRoomTab.GALLERY,
        page: pageNo,
        option: sortOrFilterBy,
      }));
    }
  }, [selectedTab, scenarioId, dispatch, pageNo, sortOrFilterBy]);

  // Change page no when scrolled to bottom
  useEffect(() => {
    if (scrollingElement
      && isPageBottom
      && !isLoading
      && hasMore) {
      setPageNo((prePageNo => prePageNo + 1));
    }
  }, [scrollingElement, isLoading, isPageBottom, hasMore]);

  // First load doesn't need the bottom loading. Bottom loading need to be set
  // only after the first call
  useEffect(() => {
    if (isOnceLoaded && pageNo > 1) {
      setIsPageLoading(isLoading);
    }
  }, [isOnceLoaded, isLoading, pageNo]);

  const sortOrFilterOptions = keys(SortOrFilterOption).filter(key => {
    // Display 'No Admin Comments' filter only if admin=isPracticeAdmin
    if (!(SortOrFilterOption[key] === SortOrFilterOption.NO_ADMIN_COMMENTS
      && !isPracticeAdmin)) {
      return key;
    }
    return null;
  });

  return (
    <div
      css={styles}
      // Margin bottom given while loading more data on infinte scrolling
      // to display margin below the loading placeholder
      className={`d-flex flex-column mx-auto gallery-card-tab-container ${isPageLoading ? 'mb-6' : ''}`}
    >
      <TabLoadingWrapper isLoaded={isOnceLoaded} isMoreLoading={isPageLoading}>
        <React.Fragment>
          {(submissionIds.length > 0 || !!sortOrFilterBy) && (
            <React.Fragment>
              <div className='d-flex flex-wrap filter-container'>
                {sortOrFilterOptions.map(key => (
                  <ClickableContainer
                    key={key}
                    className='mr-2 mb-2'
                    onClick={() => {
                      setSortOrFilterBy(SortOrFilterOption[key]);
                      setPageNo(1); // Reset pageNo
                    }}
                  >
                    <SortModePill
                      label={t.PRACTICE_ROOM.SORT_AND_FILTER_OPTIONS.LABEL[key]()}
                      isSelected={sortOrFilterBy === SortOrFilterOption[key]}
                      toolTipText={t.PRACTICE_ROOM.SORT_AND_FILTER_OPTIONS.TOOLTIP[key]()}
                    />
                  </ClickableContainer>
                ))}
              </div>
              <div className='d-flex flex-wrap'>
                {submissionIds.map(submissionId => (
                  <GalleryCard key={submissionId} submissionId={submissionId} />
                ))}
              </div>
            </React.Fragment>
          )}
          {!submissionIds.length && (
          <div className='empty-tab-wrapper'>
            <EmptyTab
              icon='media-practice'
              description={t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.TABS.NO_CONTENT.DEFAULT()}
            />
          </div>
          )}
        </React.Fragment>
      </TabLoadingWrapper>
    </div>
  );
};

export default GalleryTab;
