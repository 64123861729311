import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import NvDropdown, { NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import { extraLargeSpacing, halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { gray6, gray7, warning, white } from 'styles/global_defaults/colors';

import { CloneCourseContext, cloneCourseContextType } from '../hooks/use-clone-course-form';
import NvUserAvatar from 'components/nv-user-avatar';
import NvTooltip from 'shared/components/nv-tooltip';
import NoAdmins from './no-admins';
import { config } from '../../../../../config/config.json';

const StepTwo = () => {
  const cloneCourseFormMethods = useContext<cloneCourseContextType>(CloneCourseContext);
  const {
    listOfAdmins,
    prevStep,
    nextStep,
    setListOfAdmins,
  } = cloneCourseFormMethods;

  const courseAdmins = useSelector((state) => state.app.orderedCourseUsers ? state.app.orderedCourseUsers?.admins : []);
  const listOfRoles = useSelector((state) => state.app.orderedCourseUsers ? state.app.orderedCourseUsers?.courseRoleUsersCount : []);
  const [roleFilter, setRoleFilter] = useState(null);

  const listRolesOptions = useMemo<NvDropdownOption[]>(() => {
    if (listOfRoles?.length) {
      const initialListOfRoles: NvDropdownOption[] = [
        {
          type: 'header',
          title: t.COURSES.CLONE.STEPS.STEP_TWO.FILTER.FILTER_BY_ROLE(),
          class: 'text-gray-2',
        },
        {
          type: 'text',
          text: t.COURSES.CLONE.STEPS.STEP_TWO.FILTER.ALL_ROLES(),
          callback: () => {setRoleFilter(null)}
        },
      ];
      listOfRoles.forEach((role) => {
        if (role.total > 0) {
          initialListOfRoles.push({
            type: 'text',
            text: `${role.role} (${role.total})`,
            callback: () => {setRoleFilter(role.permission)},
          });
        }
      });
      return initialListOfRoles;
    }
  },[listOfRoles]);

  const styles = css`
    .admins-selection {
      min-height: 200px;
      input {
        &:checked + label:before,
        &.indeterminate + label:before {
          color: ${white};
          background-color: ${warning};
          border-color: ${warning};
        }
      }
      label {
        margin: 0px;
      }
      .filters {
        justify-content: space-between;
        align-items: center;
        padding-bottom: 7px;
        .counter {
          padding: 0px ${halfSpacing}px;
          align-items: center;
          .checkbox-container {
            width: ${standardSpacing}px;
            height: ${standardSpacing}px;
            padding-top: ${halfSpacing}px;
            margin-right: ${halfSpacing}px;

          }
        }
        .nv-dropdown {
          width: 200px;
          .options {
            width: 240px;
          }
        }
      }
      .admins-list {
        position: relative;
        max-height: 300px;
        overflow: hidden;
        overflow-y: scroll;
        border-top: solid 1px ${gray6};
        .admin:not(.roles-badge) {
          height: ${extraLargeSpacing}px;
          align-items: center;
          padding: 0px ${halfSpacing}px;
          margin-bottom: 2px;
          .roles-badge {
            bottom: 0px;
          }
          &.selected,
          &:hover {
            background-color: ${gray7}
          }
        }
      }
    }
  `;

  /**
   * Selects or unselect all admins
   * @param checked
   */
  const toggleAllAdmins = (checked: boolean) => {
    const newListOfAdmins = [...listOfAdmins];
    newListOfAdmins.forEach(admin => admin.selected = checked);
    setListOfAdmins(newListOfAdmins);
  };

  /**
   * Selection or unselection of individual Admin
   * @param index (its index in the array)
   */
  const selectAdmin = (adminId) => {
    const newListOfAdmins = [...listOfAdmins];
    const newAdmin = newListOfAdmins.find(admin => admin.id === adminId);
    newAdmin.selected = !newAdmin.selected;
    setListOfAdmins(newListOfAdmins);
  };

  /**
   *  Getting the list of admins and the total of roles in the same function
   */
  useEffect(() => {
    const hasListOfAdmins = !!listOfAdmins.length;
    // Here we set the list of all admins
    const adminsList = [];
    courseAdmins.forEach(courseAdmin => {
      const admin = hasListOfAdmins ? listOfAdmins.find(admin => admin.id === courseAdmin.id) : courseAdmin;
      adminsList.push(
        {
          ...admin,
          selected: hasListOfAdmins ? admin.selected : true,
        }
      );
    });
    setListOfAdmins(adminsList);
  }, [courseAdmins]);

  /**
   * Gets the list of components considering the filter applied
   */
  const getListOfAdminsComponent = useCallback(() => {
    let admins;
    if (roleFilter) {
      admins = listOfAdmins.filter(admin => admin.roles.permission === roleFilter);
    } else {
      admins = listOfAdmins;
    }
    return admins.map((admin, index) => (
        <div
          key={index}
          className={`admin d-flex ${admin.selected ? 'selected' : ''}`}
          data-qa={`admin_${index}`}
        >
          <NvCheckbox
            label=''
            name={`admin_${admin.id}`}
            checked={admin.selected}
            onChange={(event) => selectAdmin(admin.id)}
          />
          <NvUserAvatar
            displayRoleBadge
            size='sm'
            user={admin}
          />
          <div className='info'>
            <div className='name font-weight-bolder'>
              {admin.fullName}
            </div>
            <div className='position text-small text-gray-3'>
              {admin.roles.role}
            </div>
          </div>
        </div>
      )
    );
  },[roleFilter, listOfAdmins]);

  /**
   * Gets the number of admins currently selected
   */
  const getTotalAdminsSelected = useCallback(() => {
    let counter = 0;
    listOfAdmins.forEach(admin => {
      if (admin.selected) {
        counter += 1;
      }
    })
    return counter;
  },[listOfAdmins]);

  return (
    <div css={styles}>
      {
        courseAdmins.length > 0
        ? (
          <div className='admins-selection'>
            <div className='filters d-flex'>
              <div className='counter d-flex'>
                <NvTooltip
                    placement='top'
                    text={getTotalAdminsSelected() === listOfAdmins.length ? t.COURSES.CLONE.STEPS.STEP_TWO.UNSELECT_ALL() : t.COURSES.CLONE.STEPS.STEP_TWO.SELECT_ALL()}
                  >
                  <div className='checkbox-container'>
                    <NvCheckbox
                      label=''
                      name='allAdmins'
                      indeterminate={0 < getTotalAdminsSelected() && getTotalAdminsSelected() < listOfAdmins.length}
                      checked={getTotalAdminsSelected() === listOfAdmins.length}
                      onChange={(event) => toggleAllAdmins(event.currentTarget.checked)}
                      dataQa={config.pendo.cloningModal.selectAll}
                    />
                  </div>
                </NvTooltip>
                <label htmlFor='allAdmins' className='text-regular font-weight-normal'>
                  {t.COURSES.CLONE.STEPS.STEP_TWO.COUNTER(`${getTotalAdminsSelected()}`,`${listOfAdmins.length}`)}
                </label>
              </div>
              <NvDropdown
                items={listRolesOptions}
                buttonStyle={NvDropdownButtonStyle.FORM_SMALL}
                initialIndex={1}
                showSelectedIndicator
                titleClass='text-gray-1'
                menuClassName='options'
                toggleDataQa={config.pendo.cloningModal.rolesFilter}
              />
            </div>
            <div className='admins-list'>
              { getListOfAdminsComponent() }
            </div>
          </div>
        )
        : <NoAdmins />
      }

      <div className='buttons'>
        <Button type='button' variant='outline-primary' onClick={(e) => { prevStep(); }}>
          {t.COURSES.CLONE.BUTTONS.BACK()}
        </Button>
        <Button type='button' onClick={(e) => { nextStep(); }}>
          {t.COURSES.CLONE.BUTTONS.NEXT()}
        </Button>
      </div>
    </div>
  );
};

export default StepTwo;
