/*
  original version: 2.6.5
  latest referenced version: 2.8.4
*/

const alignmentOptions = document.dir === 'rtl' ? ['right', 'center', 'left'] : ['left', 'center', 'right'];

// This was copied from froala_editor core but modified for NovoEd needs
/**
 * Gets alignment of block based on text-align css attribute or dir attribute set on parents
 */
function getAlignment($block) {
  let alignment = ($block.css('text-align') || '').replace(/-(.*)-/g, '');

  // Detect rtl.
  if (['left', 'right', 'justify', 'center'].indexOf(alignment) < 0) {
    const dir = ($block.attr('dir') || $block.closest('[dir]').attr('dir') || 'ltr').toLowerCase();
    alignment = dir === 'rtl' ? 'right' : 'left';
  }

  return alignment;
}


function getNextAlignment(currentAlignment) {
  let nextIndex = alignmentOptions.indexOf(currentAlignment) + 1;
  if (nextIndex > 2) {
    nextIndex = 0;
  }

  return alignmentOptions[nextIndex];
}

(function (factory) {
  factory(window.jQuery);
}(($) => {
  $.FE.PLUGINS.align = function (editor) {
    function apply() {
      let alignment; let
        format;
      const el = editor.selection.element();

      if ($(el).parents('.fr-img-caption').length) {
        alignment = getAlignment($(el));
        format = getNextAlignment(alignment);
        $(el).css('text-align', format);
      } else {
        // Wrap.
        editor.selection.save();
        editor.html.wrap(true, true, true, true);
        editor.selection.restore();

        const blocks = editor.selection.blocks();

        if (blocks.length) {
          alignment = getAlignment($(blocks[0]));
          format = getNextAlignment(alignment);
        }

        for (let i = 0; i < blocks.length; i += 1) {
          $(blocks[i])
            .css('text-align', '')
            .removeClass('fr-temp-div')
            .removeClass(alignmentOptions.map(currentAlignment => `froala-text-align-${currentAlignment}`).join(' '));

          $(blocks[i]).addClass(`froala-text-align-${format}`);

          if ($(blocks[i]).attr('class') === '') {
            $(blocks[i]).removeAttr('class');
          }

          if ($(blocks[i]).attr('style') === '') {
            $(blocks[i]).removeAttr('style');
          }
        }

        editor.selection.save();
        editor.html.unwrap();
        editor.selection.restore();
      }
    }

    function refresh($btn) {
      const blocks = editor.selection.blocks();

      if (blocks.length) {
        const alignment = getAlignment($(blocks[0]));

        $btn.find('.icon').removeClass(alignmentOptions.map(currentAlignment => `icon-format-align${currentAlignment}`).join(' '));
        $btn.find('.icon').addClass(`icon-format-align${alignment}`);
        $btn.attr('aria-pressed', true);
      }
    }

    return {
      apply,
      refresh,
    };
  };

  $.FroalaEditor.DefineIcon('align', { NAME: 'format-aligncenter' });
  $.FE.RegisterCommand('align', {
    title: 'Change Text Alignment',
    callback(cmd) {
      this.align.apply();
    },
    refresh($btn) {
      this.align.refresh($btn);
    },
    plugin: 'align',
  });
}));
