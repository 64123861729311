import { css } from '@emotion/react';
import { useContext, Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';

import { useAppDispatch } from 'redux/store';
import { fetchComments } from 'redux/actions/comments';

import { standardSpacing, largeSpacing, halfSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { primary, warning, gray1, gray6, hexToRgbaString } from 'styles/global_defaults/colors';
import { semiBoldFontWeight, textSmallFontSize } from 'styles/global_defaults/fonts';


import ClickableContainer from 'components/clickable-container';
import { TeamDiscussionContext } from './team-discussion';

type PostTabsProps = {
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
};

const PostTabs = ({
  activeTab,
  setActiveTab,
}: PostTabsProps) => {
  const styles = css`
    display: flex;
    padding-left: ${standardSpacing}px;
    padding-bottom: ${halfSpacing}px;
    background-color: ${hexToRgbaString(gray6, 0.6)};

    .discussion-tab {
      display: flex;
      margin-right: ${largeSpacing}px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-align: center;
      position: relative;

      .discussion-tab-text {
        font-weight: ${semiBoldFontWeight};
        font-size: ${textSmallFontSize}px;
        padding-top: ${threeQuartersSpacing}px;
      }

      &.active {
        color: ${warning};

        .bottom-border {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 5px;
          background-color: ${warning};
        }
      }

      &:not(.active) {
        color: ${gray1};

        &:hover {
          color: ${primary};
        }
      }

      &:not(:last-of-type) {
        margin-right: ${standardSpacing}px
      }
    }
  `;

  const dispatch = useAppDispatch();

  const { postId } = useContext(TeamDiscussionContext);

  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const post = useSelector((state) => state.models.posts[postId]);


  const sortComments = (byLikes) => {
    setActiveTab(byLikes ? 'likes' : 'date');
    dispatch(fetchComments({
      postId,
      catalogId,
      order: byLikes ? 'likes' : undefined,
      vieweeLastActivity: post.vieweeLastActivity,
    }));
  };

  return (
    <div css={styles}>
      <ClickableContainer
        className={`discussion-tab ${activeTab === 'date' ? 'active' : ''}`}
        onClick={() => sortComments(false)}
        aria-pressed={activeTab === 'date'}
      >
        <div className='sr-only'>{t.DISCUSSIONS.MOBILE_FILTERS.SORTING_TEXT()}</div>
        <div className='discussion-tab-text'>{t.DISCUSSIONS.BY_DATE()}</div>
        <div className='bottom-border' />
      </ClickableContainer>
      <ClickableContainer
        className={`discussion-tab ${activeTab === 'likes' ? 'active' : ''}`}
        onClick={() => sortComments(true)}
        aria-pressed={activeTab === 'likes'}
      >
        <div className='sr-only'>{t.DISCUSSIONS.MOBILE_FILTERS.SORTING_TEXT()}</div>
        <div className='discussion-tab-text'>{t.DISCUSSIONS.BY_LIKES()}</div>
        <div className='bottom-border' />
      </ClickableContainer>
    </div>
  );
};

export default PostTabs;
