import React from 'react';
import pick from 'lodash/pick';

/**
 * Learner dashboard specific hook, used to calculate the proper width of the
 * cards.
 * @param container - Container of the cards, we use the container to get it's
 * width and from that calculate the proper width.
 * @param cardsAmount - Given the approach we are using this is just to limit
 * the iteration on the children cards to only consider the ones in the first
 * row.
 */
const useCardWidth = (containerRef: React.MutableRefObject<HTMLElement>, cardsAmount: number) => {
  const [cardWidth, setCardWidth] = React.useState(0);
  const previousContainerWidthRef = React.useRef<number>();

  const calculateCardWidth = React.useCallback(() => {
    const {
      current: containerElement,
    } = containerRef;

    if (containerElement) {
      const { children } = containerElement;

      const {
        width: containerWidth,
      } = containerElement.getBoundingClientRect();

      const { current: previousContainerWidth } = previousContainerWidthRef;

      if (previousContainerWidth === containerWidth) {
        return;
      }

      previousContainerWidthRef.current = containerWidth;

      const horizontalMarginsWidth = Array.from(children).slice(0, cardsAmount).reduce<number>((acc, curr) => {
        const horizontalMargins = pick(
          getComputedStyle(curr),
          ['marginLeft', 'marginRight'],
        );

        return acc + Object.values(horizontalMargins).reduce<number>(
          (innerAcc: number, innerCurr: string) => innerAcc + parseFloat(innerCurr),
          0,
        );
      }, 0);

      const newCardWidth = (containerWidth - horizontalMarginsWidth) / cardsAmount;

      setCardWidth(newCardWidth);
    }
  }, [cardsAmount, containerRef]);

  React.useLayoutEffect(() => {
    calculateCardWidth();
  }, [calculateCardWidth]);

  React.useEffect(() => {
    window.addEventListener('resize', calculateCardWidth);

    return () => window.removeEventListener('resize', calculateCardWidth);
  }, [calculateCardWidth]);

  return cardWidth;
};

export default useCardWidth;
