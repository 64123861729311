export default {
  bindings: {
    totalPossible: '@?',
    progress: '<',
    pointsEnabled: '<?',
    onClick: '&',
    completionCriteria: '=',
    type: '=?',
    course: '<',
  },
  controller: function ctrl(
    $scope,
  ) {
'ngInject';
    $scope.$watch(this.completionCriteria, () => {
      this.completionsEnabled = this.completionCriteria?.automatedCompletionsEnabled;

      // if it's 0, todos/points haven't been set yet, so set to an arbitrary value to show empty progress bar (assumes earned will be 0 or null)
      this.totalPossibleToInt = this.completionsEnabled ? this.completionCriteria.requiredPoints : parseInt(this.totalPossible, 10);
      this.maxForProgressBar = this.totalPossibleToInt;
      if (this.maxForProgressBar === 0) {
        this.maxForProgressBar = 1;
      }
    });

    $scope.$watch(this.pointsEnabled, () => {
      if (this.pointsEnabled) {
        this.hasCompleted = (this.progress.pointsReceived + 0) >= this.totalPossibleToInt; // coerce a null value into an integer
        this.earned = (this.progress.pointsReceived + 0);
      } else if (!this.pointsEnabled) {
        this.hasCompleted = (this.progress.numTodosCompleted + 0) >= this.totalPossibleToInt; // coerce a null value into an integer
        this.earned = (this.progress.numTodosCompleted + 0);
      }
    });
  },
  controllerAs: 'progressCtrl',
  templateUrl: 'mentors/templates/nv-mentee-progress-bar.html',
};
