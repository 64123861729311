/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
import AhoCorasick, { AhoCorasickMatches, ResultsByKeyword } from 'libs/aho-corasick';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setLastContentSearchString } from 'redux/reducers/content-search';

interface Course {
  catalogId: string;
  name: string;
  coverPhotoUrl: string;
  thumbnail: string;
  headerColor: string;
}

export interface SearchResultData {
  activitiesTitles: string;
  componentsTitle: string;
  activitiesTitle: string;
  id: number;
  content: string;
  title: string;
  type: string;
  course: Course;
}

/* @ngInject */
export function contentSearchReducer(
  state: { inputValue: string; wasSubmitted: boolean },
  action: { type: 'setInputValue'; payload: string } | { type: 'setWasSubmitted'; payload: boolean },
) {
  if (action.type === 'setInputValue') {
    return {
      ...state,
      // set was submitted if the value changed
      wasSubmitted: action.payload === state.inputValue,
      inputValue: action.payload,
    };
  }
  if (action.type === 'setWasSubmitted') {
    return {
      ...state,
      wasSubmitted: action.payload,
    };
  }

  throw new Error('unknown action in contentSearchReducer');
}

export const contentSearchContext = React.createContext<
[React.ReducerState<typeof contentSearchReducer>, React.Dispatch<React.ReducerAction<typeof contentSearchReducer>>]
>([
  { inputValue: '', wasSubmitted: false },
  () => {
    throw new Error('Please wrap your component with the contentSearchContext context provider');
  },
]);

export const isSearchValid = (str) => str.trim().length >= 3;

/* @ngInject */
export function useOnSearchContent() {
  const dispatch = useDispatch();
  const [state, contentSearchDispatch] = React.useContext(contentSearchContext);

  return React.useCallback(
    (val) => {
      if (isSearchValid(val)) {
        dispatch(setLastContentSearchString(val));
      }
      contentSearchDispatch({ type: 'setWasSubmitted', payload: true });
      // used for when the search is not made through the search input
      contentSearchDispatch({ type: 'setInputValue', payload: val });
    },
    [dispatch, contentSearchDispatch],
  );
}

/* @ngInject */
export function useOnSearchInputChange() {
  const [state, contentSearchDispatch] = React.useContext(contentSearchContext);

  return React.useCallback(
    (val) => {
      contentSearchDispatch({ type: 'setWasSubmitted', payload: false });
      contentSearchDispatch({ type: 'setInputValue', payload: val });
    },
    [contentSearchDispatch],
  );
}

/* @ngInject */
export function useOnResetSearch() {
  const dispatch = useDispatch();
  const [state, contentSearchDispatch] = React.useContext(contentSearchContext);

  return React.useCallback(() => {
    dispatch(setLastContentSearchString(''));
    contentSearchDispatch({ type: 'setInputValue', payload: '' });
    contentSearchDispatch({ type: 'setWasSubmitted', payload: false });
  }, [dispatch, contentSearchDispatch]);
}

/* @ngInject */
export function useContentSearchActions() {
  const onSearchContent = useOnSearchContent();
  const onSearchInputChange = useOnSearchInputChange();
  const onResetSearch = useOnResetSearch();

  return {
    onSearchContent,
    onSearchInputChange,
    onResetSearch,
  };
}

// the order of the items also represents field priority. If content and title fields have the score
// then "content" is preferred because it's likely that it contains more unique keywords
const textFields = ['content', 'title', 'componentsTitle', 'activitiesTitle'] as const;
export type TextField = typeof textFields[number];
export type AcTries = {
  acKeywords: AhoCorasick<string[]>;
  acSearch: AhoCorasick<[string]>;
};

/**
 * receives the tries to perform the keyword matching and weighting of each field return by the BE
 */
/* @ngInject */
export function getFieldsScore(acTries: AcTries, resultData: SearchResultData) {
  const snippetsByField: Map<TextField, string[]> = new Map(
    textFields.map((field) => [field, resultData[field].split('\r\n')]),
  );

  // array of tuples with the range of characters to highlight
  const matchResultsByField = new Map<TextField, SnippetSearchResult[]>();
  const scoreByField = new Map<TextField, number>();

  textFields.forEach((field) => {
    let fieldScore = 0;
    let fieldResultsByKeyword: ResultsByKeyword<string[]> = {};

    const resultsBySnippet = snippetsByField.get(field).map((snippet) => {
      let isExact = false;
      const lowerCaseSnippet = snippet.toLowerCase();
      const exactSearchResult = acTries.acSearch.search(lowerCaseSnippet);
      const keywordSearchResult = acTries.acKeywords.search(lowerCaseSnippet);

      let matches: AhoCorasickMatches;

      if (Object.keys(exactSearchResult.keywords).length === 1 && acTries.acKeywords.keywords.length !== 1) {
        // if there are multiple keywords but an exact match was found, give the highest score: Infinity
        fieldScore = Infinity;
        isExact = true;
        matches = exactSearchResult;
      } else {
        matches = keywordSearchResult;

        fieldResultsByKeyword = { ...fieldResultsByKeyword, ...keywordSearchResult.keywords };
        const currentSnippetScore = Object.keys(fieldResultsByKeyword).length;

        fieldScore = Math.max(fieldScore, currentSnippetScore);
      }

      return { matches, isExact };
    });

    matchResultsByField.set(field, resultsBySnippet);

    // we give title fields twice as much weight
    if (['title', 'activitiesTitle', 'componentsTitle'].includes(field)) {
      fieldScore *= 2;
    }
    scoreByField.set(field, fieldScore);
  });

  return { snippetsByField, scoreByField, matchResultsByField };
}

type SnippetSearchResult = {
  matches: AhoCorasickMatches<string[]>;
  isExact: boolean;
};

/* @ngInject */
export function filterSnippets(unfilteredResults: SnippetSearchResult[], unfilteredSnippets: string[]) {
  let matchingResults = [] as SnippetSearchResult[];
  let snippets = [] as string[];

  for (let i = 0; i < unfilteredResults.length; i++) {
    const currentResult = unfilteredResults[i];
    const matchesCount = Object.keys(currentResult.matches.keywords).length;
    if (
      // stop if an exact match was found
      currentResult.isExact
      // or the snippets includes all the keywords
      || (matchesCount > 0 && matchesCount === currentResult.matches.trie.keywords.length)
    ) {
      matchingResults = [unfilteredResults[i]];
      snippets = [unfilteredSnippets[i]];
      break;
    }
    if (matchesCount > 0) {
      matchingResults.push(unfilteredResults[i]);
      snippets.push(unfilteredSnippets[i]);
    }
  }

  // returns a tuple with [matches, snippets]
  return [matchingResults, snippets] as [SnippetSearchResult[], string[]];
}

// average character width. NOTE: if the font size changes, this needs to be updated
const CHARACTER_WIDTH = 6.4;

/**
 * returns a truncated text results to try to make sure that the matched text is visible
 */
/* @ngInject */
export function truncateFirstSnippet(
  matchingResults: SnippetSearchResult[],
  snippets: string[],
  containerWidth: number,
) {
  let truncatedFirstSnippet = snippets[0];
  let firstSnippetMatchingResults = matchingResults[0];
  const firstMatchOffset = firstSnippetMatchingResults.matches[0][0] - firstSnippetMatchingResults.matches[0][1][0].length + 1;

  let offset = 0;
  const halfScreen = containerWidth / 2;

  // will heuristically detect if the match is within the first half of the text, otherwise will cut to the last period before the match
  if (firstMatchOffset * CHARACTER_WIDTH > halfScreen) {
    const lastPeriodBeforeFirstMatch = truncatedFirstSnippet.lastIndexOf('. ', firstMatchOffset);
    if (lastPeriodBeforeFirstMatch !== -1) {
      offset = lastPeriodBeforeFirstMatch + 2;
    }
  }

  // if the match is still too long, we'll try to force that the first match is within the first half of the screen
  if ((firstMatchOffset - offset) * CHARACTER_WIDTH > halfScreen) {
    const halfScreenChars = Math.floor(halfScreen / CHARACTER_WIDTH);
    offset = firstMatchOffset - halfScreenChars;
  }

  if (offset > 0) {
    truncatedFirstSnippet = `…${truncatedFirstSnippet.substr(offset)}`;
    // after truncating part of it, we need to update the match offsets. Instead f of mutation the current result, we copy it and update
    const newMatches = firstSnippetMatchingResults.matches.map((result) => [
      result[0] - offset + 1,
      result[1],
    ]) as AhoCorasickMatches;
    newMatches.keywords = firstSnippetMatchingResults.matches.keywords;

    firstSnippetMatchingResults = {
      matches: newMatches,
      isExact: firstSnippetMatchingResults.isExact,
    };
  }

  // returns a tuple with [matches, snippets]
  return [
    [firstSnippetMatchingResults, ...matchingResults.slice(1)],
    [truncatedFirstSnippet, ...snippets.slice(1)],
  ] as [SnippetSearchResult[], string[]];
}

/**
 * wraps the matches with a <span /> element to highlight them
 */
/* @ngInject */
export function highlightMatches(matchingResults: SnippetSearchResult[], snippets: string[]) {
  const matchedTextHighlighted = [] as React.ReactNode[];

  for (let i = 0; i < snippets.length; i++) {
    const currentSnippet = snippets[i];
    const currentMatchingResult = matchingResults[i];
    let lastMatchEnd = 0;

    for (const currentMatch of currentMatchingResult.matches) {
      // the first element of the array is the longest match
      const longestMatchedWord = currentMatch[1][0];

      const matchEndOffset = currentMatch[0] + 1;
      const matchStartOffset = matchEndOffset - longestMatchedWord.length;

      const textBeforeMatch = currentSnippet.substring(lastMatchEnd, matchStartOffset);
      lastMatchEnd = matchEndOffset;

      // copy the text before the match
      matchedTextHighlighted.push(textBeforeMatch);

      // copy the match wrapped by the span
      matchedTextHighlighted.push(
        React.createElement(
          'span',
          { className: 'highlight', key: `${i}-${matchEndOffset}` },
          currentSnippet.substr(matchStartOffset, longestMatchedWord.length),
        ),
      );
    }

    // copy the rest of the text and add an space to the end
    matchedTextHighlighted.push(`${currentSnippet.substring(lastMatchEnd)} `);
  }

  return matchedTextHighlighted;
}
