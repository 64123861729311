import ColorIconButton from 'components/color-icon-button';
import ColorPickerPopover, { ColorValuesMap, ColorPickerPopoverProps } from 'components/color-picker-popover';
import { useState } from 'react';
import t from 'react-translate';
import _ from 'underscore';
import { config } from '../../../../config/config.json';


type Props<ColorValuesMapT extends ColorValuesMap> = Pick<ColorPickerPopoverProps<ColorValuesMapT>, 'onChange' | 'colorValues'>;

/** Wraps the ColorPickerPopover for use in a web link lecture component text area.  */
const WebLinkColorPickerPopover = <ColorValuesMapT extends ColorValuesMap>(props: Props<ColorValuesMapT>) => {
  const { colorValues, onChange } = props;
  const [showColorPicker, setShowColorPicker] = useState(false);

  return (
    <ColorPickerPopover<ColorValuesMapT>
      show={showColorPicker}
      sections={[{
        name: 'textColor',
        title: t.LECTURE_PAGES.COMPONENTS.WEB_LINK.TEXT_COLOR(),
      }, {
        name: 'backgroundColor',
        title: t.LECTURE_PAGES.COMPONENTS.WEB_LINK.BACKGROUND_COLOR(),
      }]}
      placement='top-end'
      onChange={onChange}
      colorValues={colorValues}
      onToggle={(nextShow) => { setShowColorPicker(nextShow); }}
    >
      <div className={`icon-btn-container ${showColorPicker ? 'picker-visible' : ''}`}>
        <ColorIconButton tooltip={!showColorPicker && t.LECTURE_PAGES.COMPONENTS.WEB_LINK.EDIT_COLORS()} data-qa={config.pendo.activities.webLink.textColorPicker} />
      </div>
    </ColorPickerPopover>
  );
};

export default WebLinkColorPickerPopover;
