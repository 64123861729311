import React from 'react';

// Components
import NvIcon from 'shared/components/nv-icon';

interface EmptyTabProps {
  icon: string,
  description: string
}

const EmptyTab = (props: EmptyTabProps) => (
  <React.Fragment>
    <div className='text-center gray-5'>
      <NvIcon size='ultra-large' icon={props.icon} />
    </div>
    <div className='page-title-small text-center gray-2'>
      {props.description}
    </div>
  </React.Fragment>
);

export default EmptyTab;
