import { jsx, css } from '@emotion/react';
import React from 'react';
import { gray6, primary } from 'styles/global_defaults/colors';
import { handheld } from 'styles/global_defaults/media-queries';
import { quarterSpacing } from 'styles/global_defaults/scaffolding';

interface AnimatedResultBarProps {
  progress: number;
}

/* @ngInject */
export default function AnimatedResultBar(props: AnimatedResultBarProps) {
  React.useEffect(() => {
    requestAnimationFrame(() => {
      if (animatedBarRef.current) {
        animatedBarRef.current.style.transform = `scaleX(${props.progress / 100})`;
      }
    });
  }, [props.progress]);

  const animatedBarRef = React.useRef<HTMLDivElement>();

  return (
    <div css={resultBarContainerStyle}>
      <div css={animatedProgressBarStyle} ref={animatedBarRef} />
    </div>
  );
}

const resultBarContainerStyle = css`
  position: relative;
  width: 100%;
  background-color: ${gray6};
  height: ${quarterSpacing}px;
  margin-bottom: ${quarterSpacing}px;
  margin-top: ${quarterSpacing}px;

  ${handheld(css`
    order: 2;
  `)};
`;

const animatedProgressBarStyle = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${primary};
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.8s;
`;
