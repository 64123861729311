import { createContext } from 'react';
import { CommunicationCategory, CommunicationType, OwnerType, ViewMode } from 'redux/schemas/models/course-communication';

export enum TriggerType {
  CREATE = 'create',
  EDIT = 'edit',
  DUPLICATE = 'duplicate',
  DELETE = 'delete',
  VIEW = 'view',
}

export enum CommunicationAction {
  SHOW_MODAL,
  CLOSE_MODAL,
  SET_ACTIVITY,
  SET_IS_DIRTY,
  SET_VIEW_MODE,
}

type CommunicationState = {
  communicationId: number
  communicationType: CommunicationType
  communicationCategory: CommunicationCategory
  triggerType: TriggerType
  showModal: boolean
  activityType: OwnerType
  activityId: number,
  category?: string,
  isDirty: boolean,
  lecturePageId: number,
  viewMode: ViewMode
};

interface ShowModal {
  communicationId: number
  type: CommunicationAction.SHOW_MODAL
  triggerType: TriggerType
  communicationType: CommunicationType
  activityType: OwnerType
  activityId: number
  category?: string
  lecturePageId: number
  viewMode?: ViewMode
}

interface CloseModal {
  type: CommunicationAction.CLOSE_MODAL
}

interface SetActivity {
  type: CommunicationAction.SET_ACTIVITY
  activityType: OwnerType
  activityId: number
  category?: string
}

interface SetIsDirty {
  type: CommunicationAction.SET_IS_DIRTY
  isDirty: boolean
}

interface SetViewMode {
  type: CommunicationAction.SET_VIEW_MODE
  viewMode: ViewMode
  triggerType: TriggerType
}

type Action = ShowModal | CloseModal | SetActivity | SetIsDirty | SetViewMode ;

export const getCategory = (communicationType: CommunicationType) => {
  if (communicationType === CommunicationType.COURSE_WELCOME_EMAIL) {
    return CommunicationCategory.WELCOME_EMAIL;
  }
  if (communicationType === CommunicationType.RELEASE_DATE_EMAIL
      || communicationType === CommunicationType.DUE_DATE_EMAIL
      || communicationType === CommunicationType.MANUAL_EMAIL
  ) {
    return CommunicationCategory.SCHEDULED_EMAIL;
  }
  if (communicationType === CommunicationType.DUE_DATE_ANNOUNCEMENT
    || communicationType === CommunicationType.RELEASE_DATE_ANNOUNCEMENT
    || communicationType === CommunicationType.MANUAL_ANNOUNCEMENT
  ) {
    return CommunicationCategory.SCHEDULED_ANNOUNCEMENT;
  }
  if (communicationType === CommunicationType.AUTOMATED_JOURNEY_EMAIL) {
    return CommunicationCategory.AUTOMATED_JOURNEY_EMAIL;
  }
  return CommunicationCategory.TRIGGERS;
};

export const initialState: CommunicationState = {
  communicationId: null,
  communicationType: null,
  communicationCategory: null,
  triggerType: null,
  showModal: false,
  activityType: null,
  activityId: null,
  isDirty: null,
  lecturePageId: null,
  viewMode: null,
};

export const reducer = (state: CommunicationState, action: Action) => {
  switch (action.type) {
    case CommunicationAction.SHOW_MODAL:
      return {
        ...state,
        showModal: true,
        triggerType: action.triggerType,
        communicationType: action.communicationType,
        communicationCategory: getCategory(action.communicationType),
        activityType: action.activityType,
        activityId: action.activityId,
        category: action.category,
        communicationId: action.communicationId,
        lecturePageId: action.lecturePageId,
        viewMode: action.viewMode ?? ViewMode.FORM_VIEW,
      };
    case CommunicationAction.CLOSE_MODAL:
      return {
        ...state,
        showModal: false,
        communicationId: null,
      };
    case CommunicationAction.SET_ACTIVITY:
      return {
        ...state,
        activityType: action.activityType,
        activityId: action.activityId,
      };
    case CommunicationAction.SET_VIEW_MODE:
      return {
        ...state,
        viewMode: action.viewMode,
        triggerType: action.triggerType ?? state.triggerType,
      };
    case CommunicationAction.SET_IS_DIRTY:
      return {
        ...state,
        isDirty: action.isDirty,
      };
    default:
      return state;
  }
};

export const CommunicationDispatch = createContext(null);
