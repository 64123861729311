/* @ngInject */
export default function DocumentWorkspacePostModalInstanceController(
  $uibModalInstance,
  StateManager,
  ConfirmationOverlays,
  $scope,
  config,
  S3UploadFactory,
  S3NameSpaces,
  existingWorkspacePost,
  TeamWorkspaceManager,
  _,
  MentionablesModel,
  AlertMessages,
) {
  const vm = this;

  initialize();

  function initialize() {
    let file = null;
    if (existingWorkspacePost) {
      file = existingWorkspacePost.metaContent.type === 'externaldocument' ? existingWorkspacePost.metaContent : existingWorkspacePost.metaContent.file;
    }

    vm.MentionablesModel = MentionablesModel;
    vm.unsavedChangesMessage = 'FORM.NAVIGATE_AWAY.ARE_YOU_SURE';
    vm.dismissedByUser = false;
    vm.submitting = false; // so we can disable the submit button while in the process of submitting to prevent double submits
    vm.documentWorkspacePost = {
      file,
      body: existingWorkspacePost ? existingWorkspacePost.body : null,
    };

    vm.s3nameSpace = S3NameSpaces.ATTACHMENTS;
    vm.fileUploadPercentComplete = undefined;
    vm.fileUniqueId = null;

    vm.submitDocumentWorkspacePost = submitDocumentWorkspacePost;
    vm.uploadWorkspaceDocument = uploadWorkspaceDocument;
    vm.hasUnsavedChanges = hasUnsavedChanges;
  }

  function hasUnsavedChanges() {
    return ((vm.documentWorkspacePost.file || vm.documentWorkspacePost.body) && !existingWorkspacePost)
        || ((vm.fileUploadPercentComplete !== undefined && vm.fileUploadPercentComplete < 100)
          || (vm.documentWorkspacePost.file?.id && existingWorkspacePost.metaContent.id !== vm.documentWorkspacePost.file.id)
          || (vm.documentWorkspacePost.file?.uniqueId))
        || (existingWorkspacePost?.body !== vm.documentWorkspacePost.body);
  }


  function submitDocumentWorkspacePost() {
    vm.submitting = true;
    let externalDocId = null;

    if (!existingWorkspacePost) {
      if (!vm.documentWorkspacePost.file.source || vm.documentWorkspacePost.file.source !== 'googleDrive') {
        TeamWorkspaceManager.createWorkspaceDocumentPost(vm.documentWorkspacePost).then(afterCreateOrUpdate)
          .catch(
            (error) => {
              AlertMessages.error('', 'FORM.ERROR');
            },
          );
      } else {
        // massage the data a bit
        externalDocId = vm.documentWorkspacePost.file.id;
        vm.documentWorkspacePost.file.externalId = externalDocId;
        vm.documentWorkspacePost.file.id = null;

        TeamWorkspaceManager.createWorkspaceDocumentPostFromGoogleDrive(vm.documentWorkspacePost).then(afterCreateOrUpdate)
          .catch(
            (error) => {
              AlertMessages.error('', 'FORM.ERROR');
            },
          );
      }
    } else if (!vm.documentWorkspacePost.file.source || vm.documentWorkspacePost.file.source !== 'googleDrive') {
      vm.documentWorkspacePost.file.id = vm.documentWorkspacePost.file.uniqueId ? null : existingWorkspacePost.metaContent.id;
      vm.documentWorkspacePost.postId = existingWorkspacePost.id;

      TeamWorkspaceManager.updateWorkspaceDocumentPost(
        _.extend(vm.documentWorkspacePost, { fileId: existingWorkspacePost.metaContent.id }),
      ).then(afterCreateOrUpdate);
    // two cases for Google Drive post updates:
    // has only the body changed?
    } else if (vm.documentWorkspacePost.file.id === existingWorkspacePost.metaContent.id) {
      TeamWorkspaceManager.updateWorkspaceDocumentPostFromGoogleDrive(
        vm.documentWorkspacePost,
        // {
        //   body: vm.documentWorkspacePost.body,
        //   file: {
        //     id: vm.documentWorkspacePost.file.id
        //   }
        // },
        null,
        existingWorkspacePost.id,
      ).then(afterCreateOrUpdate);
    // has a new file been picked?
    } else if (vm.documentWorkspacePost.file.id !== existingWorkspacePost.metaContent.id) {
      // massage the data a bit
      externalDocId = vm.documentWorkspacePost.file.id;
      vm.documentWorkspacePost.file.externalId = externalDocId;
      vm.documentWorkspacePost.file.id = null;

      TeamWorkspaceManager.updateWorkspaceDocumentPostFromGoogleDrive(
        vm.documentWorkspacePost,
        externalDocId,
        existingWorkspacePost.id,
      ).then(afterCreateOrUpdate);
    }

    function afterCreateOrUpdate() {
      vm.submitting = false;
      vm.dismissedByUser = true;
      $uibModalInstance.close();
    }
  }

  function uploadWorkspaceDocument(file) {
    vm.documentWorkspacePost.file = null;
    // Check for file extension (avoid using nv-file-upload tooltip)
    const extension = _.last(file.name.split('.'));
    const isValidFileType = _.contains(config.files.allUploadableExtensions, `.${extension.toLowerCase()}`);
    if (!isValidFileType) {
      AlertMessages.error('FORM.OOPS', 'FILE_UPLOAD.NOT_SUPPORTED_ALERT');
      return false;
    }

    vm.fileUploadPercentComplete = 0;
    vm.documentWorkspacePost.file = file;
    // first upload the file to S3
    if (file && (!file.source || file.source !== 'googleDrive')) {
      S3UploadFactory.uploadToS3(vm.documentWorkspacePost.file, vm.s3nameSpace)
        .then((response) => {
          vm.documentWorkspacePost.file.uniqueId = response.config.data.file.uniqueId;
          vm.fileUploadPercentComplete = 100;
        },
        (response) => {
          // error case
        },
        (event) => {
          const percent = Math.round((100.0 * event.loaded) / event.total);
          vm.fileUploadPercentComplete = Math.min(99, percent);
        });
    } else {
      // from google Drive, doesn't need uploading
      vm.fileUploadPercentComplete = undefined;
    }

    return null;
  }

  // - unsaved changes overlay
  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(
    () => vm.hasUnsavedChanges() && !vm.dismissedByUser,
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    vm.unsavedChangesMessage,
  );

  $scope.$on('modal.closing', ($event) => {
    if (vm.hasUnsavedChanges() && !vm.dismissedByUser) {
      $event.preventDefault();


      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        vm.dismissedByUser = true;
        $uibModalInstance.dismiss();
      });
    }
  });

  $scope.$on('$destroy', () => {
    vm.deregisterStateChangeStart?.();
  });
}
