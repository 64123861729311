import { jsx, css } from '@emotion/react';
import React from 'react';
import { Poll, PollOptionNormalized, PollQuestion } from 'redux/schemas/models/poll';
import { black, gray2, gray4, primary } from 'styles/global_defaults/colors';
import {
  doubleSpacing,
  halfSpacing,
  largeSpacing,
  quarterSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import { handheld } from 'styles/global_defaults/media-queries';

import { useGetPollLectureComponent } from './poll-utils';
import AnimatedResultBar from './animated-result-bar';
import PollVoterAvatars from './poll-voter-avatars';

interface PollResultProps {
  question: PollQuestion;
  selectedOption?: number;
}
/* @ngInject */
export default function PollResult(props: PollResultProps) {
  const totalVoteCount = props.question.responseOptions.reduce((acc, currentValue) => acc + currentValue.votesCount, 0);
  const lectureComponent = useGetPollLectureComponent();

  return (
    <div>
      {props.question.responseOptions.map((responseOption) => (
        <PollOptionResult
          key={responseOption.id}
          option={responseOption}
          totalVoteCount={totalVoteCount}
          responsePrivacySetting={lectureComponent.poll.responsePrivacySetting}
          highlight={props.selectedOption === responseOption.id}
        />
      ))}
    </div>
  );
}

interface PollOptionResultProps {
  option: PollOptionNormalized;
  totalVoteCount: number;
  responsePrivacySetting: Poll['responsePrivacySetting'];
  highlight: boolean;
}

function PollOptionResult(props: PollOptionResultProps) {
  const fraction = props.option.votesCount / props.totalVoteCount || 0; // 0 in case is NaN when the operation is 0 / 0
  const percentage = Math.floor(fraction * 100);
  const noVotes = props.option.votesCount === 0;

  return (
    <div css={pollOptionContainerStyle}>
      <div css={pollOptionTextContainerStyle}>
        <div
          css={css`
            ${percentageTextStyle};
            ${props.highlight && highlightColorStyle};
            ${noVotes && noVotesStyle};
          `}
        >
          {percentage}
          %
        </div>

        <div
          css={css`
            ${pollOptionTextStyle};
            ${props.highlight && highlightColorStyle};
            ${noVotes && noVotesStyle};
          `}
        >
          {props.option.optionContent}
        </div>
      </div>

      <div css={votersContainerStyle}>
        <PollVoterAvatars isSocial={props.responsePrivacySetting === 'social'} option={props.option} />
      </div>

      <div css={percentageBarContainerStyle}>
        <AnimatedResultBar progress={percentage} />
      </div>
    </div>
  );
}

const pollOptionContainerStyle = css`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${halfSpacing}px;
`;

const pollOptionTextContainerStyle = css`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-top: ${threeQuartersSpacing}px;
`;

const percentageBarContainerStyle = css`
  width: 100%;
  margin-left: ${doubleSpacing * 2}px;
`;

const percentageTextStyle = css`
  width: 50px;
  text-align: right;
  margin-right: ${largeSpacing}px;
  line-height: 25px;
  font-size: 18px;
  color: ${gray2};
  font-weight: 300;
`;

const highlightColorStyle = css`
  color: ${primary};
`;

const noVotesStyle = css`
  color: ${gray4};
`;

const pollOptionTextStyle = css`
  font-size: 16px;
  line-height: 30px;
  color: ${black};
  flex: 1;
  align-self: flex-end;
`;

const votersContainerStyle = css`
  height: ${largeSpacing}px;
  margin-top: ${halfSpacing}px;
  margin-bottom: ${quarterSpacing}px;
  align-self: flex-end;
  display: flex;

  /* prevents layout broken on IE11 when this container doesn't have any content  */
  min-width: 1px;

  ${handheld(css`
    margin-top: ${quarterSpacing}px;
    margin-left: ${doubleSpacing * 2}px;
    order: 3;
  `)};
`;
