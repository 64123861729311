import React from 'react';

type InsightsCountProps = {
  count?: number | string,
  label: string,
  className: string,
  showCount?: boolean,
};
export const InsightsCount = ({
  count,
  label,
  className,
  showCount = true,
}: InsightsCountProps) => (
  <div
    className={`insight-count d-flex flex-column bg-gray-7 align-items-center justify-content-center ${className}`}
  >
    <div className='course-title-l'>{showCount ? count : 'N/A'}</div>
    <div className='text-small font-weight-bolder text-center'>
      {label}
    </div>
  </div>
);

export default InsightsCount;
