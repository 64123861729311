import t from 'react-translate';
import Badge from 'react-bootstrap/esm/Badge';

export enum CardBadge {
  REGISTERED = 'registered',
  ATTENDED = 'attended',
  VIEWED = 'viewed',
}

const liveSessionCardBadgeSettings = {
  [CardBadge.REGISTERED]: {
    className: 'bg-gray-2',
    translationKey: 'REGISTERED',
  },
  [CardBadge.ATTENDED]: {
    className: 'bg-success',
    translationKey: 'ATTENDED',
  },
  [CardBadge.VIEWED]: {
    className: 'bg-primary',
    translationKey: 'VIEWED',
  },
};

type LiveSessionCardTitleProps = {
  title: string
  status: CardBadge
  allowUserRegistration: boolean
};

const LiveSessionCardTitle = (props: LiveSessionCardTitleProps) => {
  const {
    title,
    status,
    allowUserRegistration = true,
  } = props;
  const { translationKey, className } = liveSessionCardBadgeSettings[status];
  const translationPrefix = t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.SESSION_CARD.BADGE;
  const text = translationPrefix[translationKey]();
  const hideRegistration = !allowUserRegistration && status === CardBadge.REGISTERED;

  return (
    <div className='session-title d-flex align-items-center'>
      <div className='text-large-body bold mr-2'>{title}</div>
      {status && !hideRegistration && (
        <div>
          <Badge css={{ borderRadius: '5px' }} className={`${className} text-small bold`}>{text}</Badge>
        </div>
      )}
    </div>
  );
};

export default LiveSessionCardTitle;
