/* @ngInject */
export default function NvDynamicDropdown(
  $window,
) {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      const dropdownWidth = 220;
      const dropdownRightClass = 'dropdown-menu-right';

      element.click(() => {
        const dropdownMenuElement = element.find('.dropdown-menu');

        if (element.offset().left + dropdownWidth > $window.innerWidth) {
          dropdownMenuElement.addClass(dropdownRightClass);
        } else {
          dropdownMenuElement.removeClass(dropdownRightClass);
        }
      });
    },
  };
}
