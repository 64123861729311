import React, { useState, useContext } from 'react';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'underscore';
import { useHistory, useLocation } from 'react-router-dom';

// redux
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { deleteCollection } from 'redux/actions/collections';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { CollectionCourse } from 'redux/schemas/models/collections';

// components
import NvDropdown, { NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { TextAlign } from 'shared/components/nv-tooltip';
import { config } from '../../../config/config.json';
import CollectionForm from './new-collection-modal-body';

type CollectionActionDropdownProps = {
  collection: CollectionCourse
  iconClass: string
  closeModal?: (catalogId?: string, isUpdate?: boolean, reloadList?: boolean) => void
  onMenuToggle?: (isOpen: boolean) => void,
};

export const CollectionActionDropdown = (props: CollectionActionDropdownProps) => {
  const { collection, iconClass } = props;
  const [showCreationModal, setShowCreationModal] = useState<boolean>(false);
  const { CurrentPermissionsManager } = useContext(AngularServicesContext);
  const openEditCollectionModal = () => {
    setShowCreationModal(true);
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const onDelete = () => {
    dispatch(deleteCollection({ id: collection.id, catalogId: collection.catalogId }))
      .then((response) => {
        if (isEmpty(response?.error)) {
          dispatch(addAlertMessage({
            type: AlertMessageType.SUCCESS,
            message: t.INSTITUTIONS.CONTENT_LIBRARY.DELETE_COLLECTION.SUCCESS_ALERT(),
          }));


          // Redirect to the collection list page if deleted from the collection home.
          if (location?.pathname?.includes(collection.catalogId)) {
            history.push('/collections/');
          }
        }
      });
  };

  const confirmDelete = () => {
    if (collection.lecturePagesCount > 0) {
      dispatch(openConfirmationDialog({
        title: t.INSTITUTIONS.CONTENT_LIBRARY.DELETE_COLLECTION.TITLE(collection.lecturePagesCount),
        bodyText: t.INSTITUTIONS.CONTENT_LIBRARY.DELETE_COLLECTION.BODY_TEXT(),
        confirmText: t.DASHBOARD.CONFIRMATION.YES_SURE(),
        cancelText: t.FORM.CANCEL(),
        onConfirm: onDelete,
      }));
    } else {
      onDelete();
    }
  };

  const isOrgCourseManager = CurrentPermissionsManager.hasCourseManagerPermissions();

  const actionItems: NvDropdownOption[] = [
    {
      type: 'text',
      text: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTION_ACTION.EDIT(),
      callback: () => openEditCollectionModal(),
      dataQa: config.pendo.contentManagement.openEditCollectionModal,
      disabled: !(isOrgCourseManager || CurrentPermissionsManager.hasCollectionAdminPermissions(collection.userCourse)),
    },
    {
      type: 'text',
      text: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTION_ACTION.USER_MANAGEMENT(),
      callback: () => history.push(`/collections/${collection.id}/${collection.catalogId}/user-management`),
      disabled: !(isOrgCourseManager || CurrentPermissionsManager.hasCollectionManagerPermissions(collection.userCourse)),
      dataQa: config.pendo.contentManagement.openUserManagement,
    },
    {
      type: 'divider',
    },
    {
      type: 'text',
      text: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTION_ACTION.DELETE(),
      class: 'text-danger',
      disabled: collection.linkedLecturePagesCount > 0 || !isOrgCourseManager,
      tooltip: {
        text: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTION_ACTION.DELETE_TOOLTIP(),
        enabled: collection.linkedLecturePagesCount > 0,
        textAlign: TextAlign.LEFT,
      },
      callback: () => confirmDelete(),
      dataQa: config.pendo.contentManagement.deleteCollection,
    },
  ];

  const onCloseModal = (catalogId?: string, isUpdate?: boolean, reloadList?: boolean) => {
    setShowCreationModal(false);
    props.closeModal?.(catalogId, true, reloadList);
  };

  return (
    <React.Fragment>
      <NvDropdown
        buttonStyle={NvDropdownButtonStyle.ICON}
        items={actionItems}
        iconClass={iconClass}
        onToggle={props.onMenuToggle}
        toggleDataQa={config.pendo.contentManagement.collectionActionMenu}
        toggleDataQaId={`${config.pendo.contentManagement.collectionActionMenu}_${collection.id}`}
        tooltip={t.INSTITUTIONS.CONTENT_LIBRARY.EDIT_COLLECTION_FORM.TITLE()}
        tooltipPlacement='bottom'
      />

      <NvModal
        type={ModalType.FIXED}
        header={t.INSTITUTIONS.CONTENT_LIBRARY.EDIT_COLLECTION_FORM.TITLE()}
        show={showCreationModal}
        width={720}
        height={385}
        onClose={onCloseModal}
        body={(
          <CollectionForm
            closeModal={onCloseModal}
            catalogId={collection.catalogId}
          />
        )}
      />
    </React.Fragment>
  );
};

export default CollectionActionDropdown;

