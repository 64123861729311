import React from 'react';
import { css } from '@emotion/react';

import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import { openSans } from 'styles/global_defaults/fonts';
import { info, gray2 } from 'styles/global_defaults/colors';
import useTextMeasurer from 'shared/hooks/use-text-measurer';
import {
  halfSpacing,
  largeSpacing,
  quarterSpacing,
} from 'styles/global_defaults/scaffolding';

const ICON_SIZE = 12;
const DEFAULT_MAX_BADGE_WIDTH = 280;
export const SPACE_BETWEEN_BADGES = halfSpacing;

type EntitlementBadgeProps = {
  value: string,
  color?: string,
  maxWidth?: number,
  showIcon?: boolean,
  badgeColor?: string,
};

const EntitlementBadge = (props: EntitlementBadgeProps) => {
  const {
    value,
    color = gray2,
    showIcon = true,
    badgeColor = info,
    maxWidth = DEFAULT_MAX_BADGE_WIDTH,
  } = props;

  const measureText = useTextMeasurer({
    fontSize: 12,
    lineHeight: 15,
    fontWeight: 600,
    fontFamily: openSans,
  });

  const isTooltipEnabled = React.useMemo(() => {
    const textWidth = Math.round(measureText(value));

    return textWidth > ((maxWidth - largeSpacing) - (showIcon ? ICON_SIZE : 0));
  }, [value, showIcon, maxWidth, measureText]);


  const styles = css`
    height: 25px;
    color: ${color};
    font-size: 12px;
    font-weight: 600;
    line-height: 25px;
    align-items: center;
    display: inline-flex;
    border-radius: 12.5px;
    font-family: ${openSans};
    max-width: ${maxWidth}px;
    padding: 0 ${halfSpacing}px;
    background-color: ${badgeColor};
    margin-right: ${quarterSpacing}px;

    &:last-of-type {
      margin-right: 0;
    }

    .icon {
      display: inline-block;
      margin-right: ${SPACE_BETWEEN_BADGES}px;
    }

    span {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `;

  return (
    <NvTooltip text={value} enabled={isTooltipEnabled}>
      <div css={styles}>
        {showIcon && (
          <NvIcon
            icon='check'
            size='xss-smallest'
          />
        )}
        <span>{value}</span>
      </div>
    </NvTooltip>
  );
};

export default EntitlementBadge;
