import * as yup from 'yup';
import t from 'react-translate';
import { useSelector } from 'react-redux';

// Schemas
import { ScheduledForType } from 'redux/schemas/models/course-communication';

// Selectors
import { getCourseAliases, getCourseTotalPoints, getNumberofTodos, isGamificationEnabled, getCurrentCourse } from 'redux/selectors/course';

// Components
import FilterByRoles from 'communications/course_communications/components/communication-modal/filters/filter-by-role';
import CompletedCourse, { CompletedCourseFilterType } from 'communications/course_communications/components/communication-modal/filters/completed-course';
import NumberInput from 'communications/course_communications/components/communication-modal/filters/number-input';
import SendOn from 'communications/course_communications/components/communication-modal/send_on/send-on';
import SendTo from 'communications/course_communications/components/communication-modal/send-to';
import CommunicationForm, { formDropdownSchema } from './communication-form';
import Activity from './filters/activity';
import LabelOnly from './filters/label-only';


const OnNotComplete = () => {
  const aliases = useSelector((state) => getCourseAliases(state));

  const currentCourse = useSelector((state) => getCurrentCourse(state));
  const totalPoints = useSelector((state) => getCourseTotalPoints(state));
  const gamificationEnabled = useSelector((state) => isGamificationEnabled(state));
  const totalAssignments: number = useSelector((state) => getNumberofTodos(state));

  const validationSchema = yup.object().shape({
    scheduledFor: yup.string().nullable(),
    xDays: yup.number().nullable()
      .when('scheduledFor', {
        is: ScheduledForType.AFTER_DATE,
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      }),
    event: yup.string().required(),
    filterByRoles: yup.boolean(),
    courseRolesList: yup.array().of(yup.string())
      .when('filterByRoles', {
        is: true,
        then: yup.array().of(yup.string()).required().min(1),
      }),
    completedType: yup.object().nullable()
      .when('event', {
        is: 'completed-activity',
        then: formDropdownSchema.required(t.VALIDATION.REQUIRED()),
      }),
    completedActivity: yup.object().nullable()
      .when('event', {
        is: 'completed-activity',
        then: formDropdownSchema.required(t.VALIDATION.REQUIRED()),
      }),
    earnedAtLeastXPoints: yup.number().nullable()
      .when('event', {
        is: 'earned-points',
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      }),
    completedXAssignmentsAsTodo: yup.number().nullable()
      .when('event', {
        is: 'completed-assignments',
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      }),
  });

  return (
    <CommunicationForm validationSchema={validationSchema}>
      <div className='modal-body-title page-title text-center pb-4 mt-2'>{t.COURSE_COMMUNICATIONS.TRIGGERS.TITLE.STEP_1()}</div>
      <SendTo
        title={t.COURSE_COMMUNICATIONS.ON_NOT_COMPLETE.SEND_TO}
      />
      <div className='mb-4'>
        <SendOn />
      </div>

      {/* Enrolled in course */}
      <LabelOnly
        name='enrolledInCourse'
        eventValue='enrolled-in-course'
        label={t.COURSE_COMMUNICATIONS.FILTERS.ENROLLED_IN_COURSE(aliases.courseAliases)}
      />

      {/* Enrolled in course */}
      <LabelOnly
        name='visitsCourseHome'
        eventValue='visits-course-home'
        label={t.COURSE_COMMUNICATIONS.FILTERS.VISITS_COURSE_HOME(aliases.courseAliases)}
      />

      {/* Has completed a specific activity */}
      <Activity
        name='completed'
        eventValue='completed-activity'
        label={t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.HAS()}
      />

      {/* Has earned more than X points */}
      <NumberInput
        name='earnedAtLeastXPoints'
        eventValue='earned-points'
        defaultLabel={t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.AT_LEAST.DEFAULT(aliases.pointsAliases)}
        inputLabel={t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.AT_LEAST.LABEL({ input: 'INPUT', ...aliases.pointsAliases })}
        inputWidth={100}
        ariaLabel={t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.AT_LEAST.ARIA_LABEL(aliases.pointsAliases)}
        info={!gamificationEnabled
          ? t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.NOT_ENABLED(aliases.pointsAliases)
          : t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.TOTAL({ count: totalPoints, ...aliases.pointsAliases })}
      />

      {/* Completed at least X number of assignments that are marked as to-dos */}
      <NumberInput
        name='completedXAssignmentsAsTodo'
        eventValue='completed-assignments'
        defaultLabel={t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ASSIGNMENTS.AT_LEAST.DEFAULT(aliases.assignmentAliases)}
        inputLabel={t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ASSIGNMENTS.AT_LEAST.LABEL({ input: 'INPUT', ...aliases.assignmentAliases })}
        inputWidth={100}
        info={!totalAssignments
          ? t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ASSIGNMENTS.NO_ASSIGNMENTS(aliases.assignmentAliases)
          : t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ASSIGNMENTS.TOTAL(totalAssignments)}
      />

      <CompletedCourse type={CompletedCourseFilterType.RADIO} />
      <div className='bold text-large-regular mt-5 mb-4'>
        <div className='text-warning inline-block'>{t.COURSE_COMMUNICATIONS.FILTERS.OPTIONAL()}</div>
        <div className='ml-2 inline-block'>{t.COURSE_COMMUNICATIONS.FILTERS.ADD_MORE(aliases.learnersAliases)}</div>
      </div>
      <FilterByRoles />
      {currentCourse.isSelfPaced && (
        <CompletedCourse type={CompletedCourseFilterType.CHECKBOX} />
      )}
    </CommunicationForm>
  );
};

export default OnNotComplete;
