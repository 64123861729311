import t from 'react-translate';
import { ExtrasForCelebrationModal } from './nv-celebration-modal';

export type ConditionsType = {
  allCorrect: boolean,
  firstAttemptAndNotAllCorrect: boolean,
  lowerScoreThanPreviousAttempt: boolean,
  higherScoreThanPreviousAttempt: boolean,
  extras: ExtrasForCelebrationModal,
};

const CongratulationsMessage = (props: ConditionsType) => {
  const {
    allCorrect,
    firstAttemptAndNotAllCorrect,
    lowerScoreThanPreviousAttempt,
    higherScoreThanPreviousAttempt,
    extras,
  } = props;

  let message = t.LEARNING_JOURNEYS.COURSE_HOME.MODAL.CONGRATULATIONS();
  if (allCorrect) {
    message = t.POINTS.ALL_CORRECT();
  } else if (
    lowerScoreThanPreviousAttempt
    || firstAttemptAndNotAllCorrect
    || higherScoreThanPreviousAttempt
  ) {
    message = t.POINTS.DIFFERENT_SCORE(extras?.submissionScore.toString());
  }

  return (
    <div className='page-title score-status'>
      <span>{message}</span>
    </div>
  );
};

export default CongratulationsMessage;
