import React, { useContext, useState } from 'react';
import { AngularContext, AngularServicesContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import moment from 'moment';

// Schemas
import { CompletionStatusEnum, UserEnrollment } from 'redux/schemas/models/org-users';
import { RolesService } from 'institutions/services/roles-service';

// Actions
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { unEnrollUserFromCourse } from 'redux/actions/org-users';

// Selectors
import getOrgUserDetails from 'redux/selectors/org-users';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { hasOrgAdminPermissions } from 'redux/selectors/users';
import { getCourseAliases } from 'redux/selectors/course';

// Components
import NvDropdown, { NvDropdownAlign, NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import ClickableContainer from 'components/clickable-container';

const EditRow = ({ enrollment }: { enrollment: UserEnrollment }) => {
  const { course, completionProgress, accessCloseDate } = enrollment;

  const dispatch = useAppDispatch();
  const { $state, $scope } = useContext(AngularServicesContext);
  const [userId] = useState<number>(parseInt($state.params.userId, 10));

  const courseAliases = useSelector((state) => getCourseAliases(state, course.catalogId));
  const isOrgAdmin = useSelector(hasOrgAdminPermissions);
  const learnerDetails = useSelector((state) => getOrgUserDetails(state, userId));
  const currentInstitution = useSelector(getCurrentInstitution);
  const courseRoles = useSelector((state) => state.models.roles);

  const { injectServices } = React.useContext(AngularContext);

  const [CourseModel] = injectServices(['CourseModel']);

  const isLearnerActiveInCourse = () => completionProgress !== CompletionStatusEnum.WITHDRAWN
    && completionProgress !== CompletionStatusEnum.REMOVED;

  const viewProfile = () => {
    // For permission checking we need the userCourseModal saved in courseIdToUserCourseHash.
    $scope.CurrentUserManager.addUserCourse(
      { ...enrollment, roles: courseRoles[enrollment.courseRoleId] ?? {} },
    );

    $scope.CurrentUserManager.addCourse(new CourseModel(enrollment.course));

    $state.go('learner-profile-modal', {
      catalogId: course.catalogId,
      userId,
    });
  };

  const isLearnerUnenrollable = () => {
    const currentDate = moment();
    return currentDate > moment(course.closeDate)
      || currentDate > moment(course.endDate)
      || currentDate > moment(accessCloseDate);
  };

  const unEnrollUser = () => {
    const translationValues = {
      firstName: learnerDetails.user.firstName,
      lastName: learnerDetails.user.lastName,
      ...courseAliases.courseAliases,
      ...courseAliases.teamAliases,
      ...courseAliases.groupAliases,
      ...courseAliases.learnersAliases,
    };

    let message = t.USER_MANAGEMENT.UNENROLL_EXPLANATION(translationValues);
    if (enrollment.courseRoleId
      && RolesService.isMentor(courseRoles[enrollment.courseRoleId])
      && enrollment?.menteesCount > 0) {
      message = t.USER_MANAGEMENT.UNENROLL_EXPLANATION_RELATIONSHIP(translationValues);
    }
    dispatch(openConfirmationDialog({
      title: t.USER_MANAGEMENT.UNENROLL_USER(),
      bodyText: message,
      confirmText: t.PROFILE.ADMIN_OPTIONS.UNENROLL(),
      cancelText: t.FORM.CANCEL(),
      icon: 'warning',
      onConfirm: () => {
        dispatch(unEnrollUserFromCourse({
          user: learnerDetails.user,
          catalogId: course.catalogId,
          institutionId: currentInstitution.id,
          isJourney: course.isJourney,
          isCollection: true,
          translationValues,
        }));
      },
    }));
  };

  const getOrgAdminDropdownOptions = () => {
    const items: NvDropdownOption[] = [
      {
        type: 'text',
        text: t.PROFILE.ADMIN_OPTIONS.UNENROLL(),
        class: 'text-danger',
        callback: () => unEnrollUser(),
        disabled: !isLearnerActiveInCourse()
        || isLearnerUnenrollable(),
      },
    ];
    if (!enrollment.course.isJourney) {
      items.unshift({ type: 'divider' });
      items.unshift(
        {
          type: 'text',
          text: t.INSTITUTIONS.LICENSES.VIEW_USER_COURSE_PROFILE(),
          callback: () => viewProfile(),
          disabled: !isLearnerActiveInCourse(),
        },
      );
    }
    return items;
  };

  return (
    <div>
      {isOrgAdmin ? (
        <NvDropdown
          buttonStyle={NvDropdownButtonStyle.CUSTOM}
          items={getOrgAdminDropdownOptions()}
          align={NvDropdownAlign.RIGHT}
          customTarget={() => (
            <NvTooltip text={t.TIMELINE.MORE_OPTIONS()}>
              <div className='options-btn d-flex align-items-center justify-content-center'>
                <NvIcon size='sm' icon='more' />
              </div>
            </NvTooltip>
          )}
        />
      ) : !enrollment.course.isJourney && (
        <NvTooltip
          text={t.INSTITUTIONS.LICENSES.VIEW_COURSE_PROFILE()}
          enabled={isLearnerActiveInCourse()}
        >
          <ClickableContainer
            onClick={isLearnerActiveInCourse()
              ? () => viewProfile()
              : undefined}
            className={`${isLearnerActiveInCourse() ? '' : 'disabled'}`}
            aria-label={t.INSTITUTIONS.LICENSES.VIEW_USER_COURSE_PROFILE()}
          >
            <NvIcon size='sm' icon='profile' />
          </ClickableContainer>
        </NvTooltip>
      )}
    </div>
  );
};

export default EditRow;


