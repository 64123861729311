/* @ngInject */
export default function MembershipRequestResource(

  $resource,
) {
  return $resource(
    ':catalogId/teams/:teamId/membership_requests/:id',
    {},
    {
      cancel: { method: 'POST', url: '/:catalogId/teams/:teamId/membership_requests/:id/cancel' },
    },
  );
}
