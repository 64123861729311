import React from 'react';
import uuid from 'react-uuid';
const PhraseList = (props: { label: string, items: string[], borderClassName: string }) => (
  <div>
    <div className='gray-1 mb-2 mt-4'>
      {props.label}
    </div>
    <div className='d-flex flex-wrap'>
      {props.items.map(phrase => (
        <div
          key={uuid()}
          className={`border phrase align-items-center label gray-1 mr-2 bg-gray-7 mb-2 ${props.borderClassName}`}
        >
          {phrase}
        </div>
      ))}
    </div>
  </div>
);
export default PhraseList;
