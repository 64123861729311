/* eslint-disable react/require-default-props */
import React from 'react';
import t from 'react-translate';
import { css } from '@emotion/react';

import NvUserAvatar from 'components/nv-user-avatar';
import { User } from 'redux/schemas/models/my-account';
import { gray6, primary } from 'styles/global_defaults/colors';
import { quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';

type Props = {
  user: User,
  anchorElementExtraProps?: Object,
  onViewProfile?: (event: React.MouseEvent<HTMLAnchorElement>) => void,
};

const styles = css`
  width: 100%;
  max-width: 320px;
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid ${gray6};
  padding-top: ${standardSpacing}px;
  padding-bottom: ${standardSpacing}px;
  box-shadow: 1px 2px 2px 0 rgba(0,0,0,0.1);

  .user-avatar-container {
    margin-bottom: ${quarterSpacing}px;
  }

  .cta-button {
    color: ${primary};
    text-decoration: none;
  }
`;

const NvProfileCard = (props: Props) => {
  const {
    user,
    onViewProfile,
    anchorElementExtraProps = {},
  } = props;

  return (
    <div css={styles}>
      <div className='user-avatar-container'>
        <NvUserAvatar
          size='lg'
          user={user}
          borderType='round'
        />
      </div>
      <div className='page-title-small text-center mb-2'>
        {user.fullName}
      </div>
      <a
        {...anchorElementExtraProps}
        onClick={onViewProfile}
        // eslint-disable-next-line no-script-url
        href='javascript:void(0)'
        className='cta-button text-center text-regular'
      >
        {t.LECTURE_PAGES.COMPONENTS.PROFILE_COMPLETION.VIEW_PROFILE()}
      </a>
    </div>
  );
};

export default NvProfileCard;
