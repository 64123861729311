import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { QuizQuestionType } from 'redux/schemas/models/progressive-quiz';
import { wrapThunkAction } from 'redux/utils';
import { createQuizQuestionSubmission } from 'redux/actions/quizzes';
import { RootState } from 'redux/schemas';
import { getPointsConfiguration } from 'redux/selectors/points-configurations';

import { ProgressiveQuizMode } from 'quizzes/components/mode';
import ProgressiveQuizContext from 'quizzes/components/context';

const useProgressiveQuizNavigation = () => {
  const {
    mode,
    reveal,
    questions,
    fetchQuestion,
    progressiveQuiz,
    currentQuestion,
    notMetRequirement,
    requiredCorrectQuestionsCount,
    currentQuestionIndex,
    setCurrentQuestionIndex,
    currentQuestionResponse,
    isSubmitting,
    setIsSubmitting,
  } = React.useContext(ProgressiveQuizContext);

  const isEditMode = mode === ProgressiveQuizMode.EDIT;
  const isAnswerMode = mode === ProgressiveQuizMode.ANSWER;
  const [answerModeMaxIndexAllowed, setAnswerModeMaxIndexAllowed] = React.useState(-1);
  const isStatement = currentQuestion.type === QuizQuestionType.STATEMENT;

  const areAttemptsLeft = currentQuestion.completedQuestionAttempts
  < currentQuestion.totalQuestionAttempts;

  const index = currentQuestion.questionIndex;
  const dispatch = useDispatch();

  const questionResponses = useSelector((state: RootState) => state.models.quizQuestionResponses);
  const pointsConfiguration = useSelector(
    (state: RootState) => getPointsConfiguration(state, progressiveQuiz.pointsConfiguration),
  );

  const maxWrongAnswersAllowed = progressiveQuiz.answerableQuestionsCount - (pointsConfiguration?.passingScoreRequired ? requiredCorrectQuestionsCount : 0);

  const totalWrongAnswerQuestions = questions
    .filter((eachQuestion) => ((eachQuestion.responses !== null) && !questionResponses[eachQuestion.responses]?.isCorrect)).length;

  React.useEffect(() => {
    const questionsWithResponses = questions.filter((eachQuestion) => eachQuestion.responses !== null);

    // Checking whether there are any questions that were submitted incorrectly and still have attempts left.
    const hasIncompleteQuestion = !!questionsWithResponses
      .filter((eachQuestion) => (eachQuestion.totalQuestionAttempts > eachQuestion.completedQuestionAttempts)
        && !questionResponses[eachQuestion.responses].isCorrect)
      .length;

    /**
     * 1. hasIncompleteQuestion -> to prevent accessing next unattended question from a incomplete question
     * 2. (totalWrongAnswerQuestions > maxWrongAnswersAllowed) -> to prevent accessing next unattended question
     *     when learner didn't met requirement when passing score is required
     * 3. (questionsWithResponses.length === progressiveQuiz.totalQuestions) -> condition where
     *    learner can navigate to every question
     * */
    if (hasIncompleteQuestion
      || (totalWrongAnswerQuestions > maxWrongAnswersAllowed)
      || questionsWithResponses.length === progressiveQuiz.totalQuestions) {
      setAnswerModeMaxIndexAllowed(questionsWithResponses.length - 1);
    } else {
      // Allowing learner to attend next unattended question
      setAnswerModeMaxIndexAllowed(questionsWithResponses.length);
    }
  }, [
    areAttemptsLeft,
    index,
    maxWrongAnswersAllowed,
    notMetRequirement,
    progressiveQuiz.totalQuestions,
    questionResponses,
    questions,
    totalWrongAnswerQuestions,
  ]);

  const previous = () => {
    const newQuestionIndex = currentQuestionIndex - 1;

    if (isAnswerMode) {
      setIsSubmitting(true);
      fetchQuestion({
        page: newQuestionIndex + 1,
      }).then(() => {
        setIsSubmitting(false);
      });
    } else {
      setCurrentQuestionIndex(newQuestionIndex);
    }
  };

  const next = () => {
    const newQuestionIndex = currentQuestionIndex + 1;

    if (isAnswerMode) {
      setIsSubmitting(true);
      if (isStatement && !currentQuestionResponse) {
        wrapThunkAction(dispatch(createQuizQuestionSubmission({
          questionId: currentQuestion!.id,
          userResponse: '',
        }))).then(() => {
          fetchQuestion({
            page: newQuestionIndex + 1,
          }).then(() => {
            setIsSubmitting(false);
          });
        }).catch(() => {
          setIsSubmitting(false);
        });
        return;
      }
      fetchQuestion({
        page: newQuestionIndex + 1,
      }).then(() => {
        setIsSubmitting(false);
      });
    } else {
      setCurrentQuestionIndex(newQuestionIndex);
    }
  };

  const isPreviousDisabled = !currentQuestionIndex || isSubmitting;

  const maxIndexAllowed = (() => {
    if (isEditMode || reveal) {
      return progressiveQuiz.totalQuestions - 1;
    }

    if (isAnswerMode) {
      return answerModeMaxIndexAllowed;
    }

    return questions.length - 1;
  })();

  const isNextDisabled = (() => {
    if (isEditMode) {
      return currentQuestionIndex === progressiveQuiz.totalQuestions - 1;
    }

    // disabling next question navigation from the last question
    if (currentQuestionIndex === progressiveQuiz.totalQuestions - 1) {
      return true;
    }
    return (currentQuestionIndex >= maxIndexAllowed || isSubmitting) && !isStatement;
  })();


  return {
    next,
    previous,
    isNextDisabled,
    maxIndexAllowed,
    isPreviousDisabled,
  };
};

export default useProgressiveQuizNavigation;
