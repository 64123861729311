import { Assign } from 'utility-types';
import { LectureSection, LectureSectionsNormalized } from '../models/lecture-section';
import { TimelineUser } from '../models/user';
import { LectureSectionListSchema } from './lecture-sections';
import { LecturePagesNormalized } from '../models/lecture-page';
import { LectureComponentEntities, LectureComponentsNormalized, NLectureComponent } from '../models/lecture-component';
import { LecturePageListSchema } from './lecture-pages';
import { LectureComponentListSchema } from './lecture-components';
import { SkillTaggingNormalized } from '../models/skill-tag';

export interface FetchCourseTimelineParams {
  catalogId: string,
  /** used to give the progress for specified user */
  userId: number,
  /** if true, fetches lecture section, lecture subsection, lecture pages
   * if false, fetches all of the above plus lecture components(only those marked as to-dos or have points) */
  outlineOnly?: boolean,
  // This should be used carefully, as lecture components coming from timeline
  // API only consider activity type lecture components and ignore non-activity
  // type lecture components.
  updateLectureComponentData?: boolean,
  editMode?: boolean,
}

export const fetchCourseTimelineParamsDefault = {
  catalogId: undefined,
  userId: undefined,
  outlineOnly: false,
};

export interface CourseTimelineResponse {
  lectureSubsections: LectureSection[];
  lectureSections: LectureSection[];
  user: TimelineUser;
  currentLecture: null;
  awardedPoints: any[];
}

export const TimelineSchema = {
  lectureSections: LectureSectionListSchema,
  lectureSubsections: LectureSectionListSchema,
  lecturePages: LecturePageListSchema,
  lectureComponents: LectureComponentListSchema,
};

export type TimelineEntities = Assign<LectureComponentEntities, {
  lectureSections: LectureSectionsNormalized,
  lectureSubsections: LectureSectionsNormalized,
  lecturePages: LecturePagesNormalized,
  lectureComponents: LectureComponentsNormalized,
  skillTaggings?: SkillTaggingNormalized,
}>;

export interface TimelineNormalizedResult {
  lectureSections: number[],
  lectureSubsections: number[],
  // TODO: add remaining items
}
