
import { css } from '@emotion/react';
import t from 'react-translate';
import NvTooltip from 'shared/components/nv-tooltip';
import { standardSpacing, halfSpacing, doubleSpacing } from 'styles/global_defaults/scaffolding';
import { Style2AccordionSectionLectureComponent } from 'redux/schemas/models/lecture-component';
import { ReactComponent as DashIcon } from 'styles/icons/dash.svg';
import { ReactComponent as CreateNewPostIcon } from 'styles/icons/create-new-post.svg';
import { SectionContainerProps } from './accordion-types';
import AccordionRtHeader from './accordion-rt-header';
import ClickableContainer, { ClickableContainerProps } from '../../../components/clickable-container';
import useAccordionSectionContainer from './use-accordion-section-container';
import { LecturePageMode } from '..';
import ApplyToAllSectionsButton from './apply-to-all-sections-button';

const iconContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${doubleSpacing}px;
  height: ${doubleSpacing}px;
  margin-left: ${halfSpacing}px;
`;

const circleIconStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${standardSpacing}px;
  width: ${standardSpacing}px;
  border-radius: 50%;
`;

interface Style2LeftHandSymbolProps {
  expanded: boolean;
  sectionData: Style2AccordionSectionLectureComponent;
  mode: LecturePageMode;
  onClick: ClickableContainerProps['onClick'];
  layoutOnly: ClickableContainerProps['layoutOnly'];
}

function Style2ToggleButton(props: Style2LeftHandSymbolProps) {
  const IconComponent = props.expanded ? DashIcon : CreateNewPostIcon;
  return (
    <ClickableContainer css={iconContainerStyle} onClick={props.onClick} layoutOnly={props.layoutOnly}>
      <NvTooltip
        enabled={props.mode === LecturePageMode.EDIT}
        text={
          props.expanded
            ? t.LECTURE_PAGES.COMPONENTS.ACCORDION.CLICK_TO_COLLAPSE()
            : t.LECTURE_PAGES.COMPONENTS.ACCORDION.CLICK_TO_EXPAND()
        }
      >
        <div
          css={css`
            ${circleIconStyle};
            background-color: ${props.sectionData.viewOptions.iconColor};
          `}
        >
          <IconComponent
            fill={props.sectionData.viewOptions.headerBackgroundColor}
            width={halfSpacing}
            height={halfSpacing}
          />
        </div>
      </NvTooltip>
    </ClickableContainer>
  );
}

type Style2SectionContainerProps = SectionContainerProps<Style2AccordionSectionLectureComponent>;

/* @ngInject */
export default function Style2SectionContainer(props: Style2SectionContainerProps) {
  const {
    headerEditorRef,
    onHeaderRTEChange,
  } = useAccordionSectionContainer(props);

  const content = (
    <ClickableContainer
      css={css`
        background-color: ${props.sectionData.viewOptions.headerBackgroundColor};
      `}
      onClick={props.toggleExpanded}
      className='w-100 align-items-center'
      layoutOnly={props.mode === LecturePageMode.EDIT}
    >
      <Style2ToggleButton
        mode={props.mode}
        sectionData={props.sectionData}
        expanded={props.expanded}
        onClick={props.toggleExpanded}
        layoutOnly={props.mode !== LecturePageMode.EDIT}
      />

      <AccordionRtHeader<{
        headerBackgroundColor: string,
        iconColor: string,
      }>
        currentLecture={props.currentLecture}
        isOnlySection={props.isOnlySection}
        colorValues={{
          headerBackgroundColor: props.sectionData.viewOptions.headerBackgroundColor,
          iconColor: props.sectionData.viewOptions.iconColor,
        }}
        onColorChange={(section, newColor) => {
          props.onViewOptionsChange({
            [section]: newColor,
          });
        }}
        sections={[
          {
            name: 'headerBackgroundColor',
            title: t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_2.BACKGROUND_COLOR_PICKER_LABEL(),
          },
          {
            name: 'iconColor',
            title: t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_2.ICON_COLOR_PICKER_LABEL(),
          },
        ]}
        renderBeforePalette={!props.isOnlySection ? () => (
          <div className='d-flex flex-column align-items-center'>
            <ApplyToAllSectionsButton
              onClick={() => {
                props.applyViewOptionsToAllSections(props.sectionData.viewOptions, {
                  includeHeaderTextFormat: true,
                });
              }}
            />
          </div>
        ) : undefined}
        backgroundColor={props.sectionData.viewOptions.headerBackgroundColor}
        autoFocus={props.autoFocusHeader}
        mode={props.mode}
        sectionData={props.sectionData}
        editorRef={headerEditorRef}
        onChange={onHeaderRTEChange}
      />
    </ClickableContainer>
  );

  return (
    <div className='mb-2'>
      {content}
      {props.renderSectionContent({
        containerCss: css`
          /*padding added inside the collapsible content */
          padding-bottom: ${halfSpacing}px;
          background-color: white;
        `,
      })}
    </div>
  );
}
