import { RootState } from 'redux/schemas';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import {
  OrgLevelMetadataSettings,
  FormOrgLevelMetadataSettings,
} from 'redux/schemas/models/org-level-metadata-settings';

export const getOrgLevelMetadataSettings = (
  state: RootState,
): OrgLevelMetadataSettings => {
  const currentInstitution = getCurrentInstitution(state);
  return currentInstitution.metadataFields;
};

export const getFormOrgLevelMetadataSettings = (
  state: RootState,
): FormOrgLevelMetadataSettings => {
  const settings = getOrgLevelMetadataSettings(state);
  return {
    metadataFields: settings.map((metadataField) => ({
      ...metadataField,
    })),
  };
};
