/* @ngInject */
export default function FilterCoursesManager(

  $filter,
  nvDateRangeFilter,
  moment,
  CourseModel,
  _,
) {
  const TAB_ACTIVE = 'active';
  const TAB_FUTURE = 'future';
  const TAB_PAST = 'past';
  const TAB_DEMO = 'demo';
  const TAB_TOTAL = 'total';
  const TAB_PRIMARY = 'primary';

  const FILTER_TYPE_TAB = 'tab';
  const FILTER_TYPE_FILTER = 'filter';
  const FILTER_TYPE_SEARCH = 'search';

  const ALL_FILTER = 'all';

  const SELF_PACED = true;
  const TIME_BASED = false;

  const _this = {
    initialize,
    initializeTabs,
    initializeSearch,
    initializeFilter,

    isTypeTab,
    isTypeFilter,
    isTypeSearch,

    // Tabs
    currentTab: null,
    tabs: [TAB_ACTIVE, TAB_FUTURE, TAB_PAST, TAB_DEMO, TAB_TOTAL],
    selectTab,
    coursesForTab,
    coursesForCurrentTab,
    tabForCourse,

    // Search
    searchQuery: null,
    enteringSearchQuery: null,
    searchIsOpen: null,
    enterSearch,
    closeSearch,
    searchCourses,

    // Filter
    filterType: null,
    allFilter: ALL_FILTER,
    typesOfRegistration: CourseModel.REGISTRATION_TYPES.concat(ALL_FILTER),
    paceTypes: [TIME_BASED, SELF_PACED, ALL_FILTER],
    usedForOptions: [CourseModel.USED_FOR_PRODUCTION, CourseModel.USED_FOR_DEMO, ALL_FILTER],

    coursesOfRegistrationType,
    coursesOfListingStatus,
    coursesOfPaceType,
    coursesOfUsedForType,

    filterCourses,
    numFiltersApplied,
    currentRegistrationType: null,
    currentListingStatus: null,
    currentPaceType: null,
    currentUsedForOption: null,
  };

  function initialize() {
    _this.filterType = FILTER_TYPE_TAB;
    _this.initializeTabs();
    _this.initializeSearch();
    _this.initializeFilter();
  }

  function initializeTabs() {
    _this.currentTab = _this.tabs[0];
  }

  function initializeSearch() {
    _this.enteringSearchQuery = false;
    _this.searchIsOpen = false;
    _this.searchQuery = '';
  }

  function initializeFilter() {
    _this.currentRegistrationType = ALL_FILTER;
    _this.currentListingStatus = ALL_FILTER;
    _this.currentPaceType = ALL_FILTER;
    _this.currentUsedForOption = ALL_FILTER;
  }

  function selectTab(tab) {
    _this.filterType = FILTER_TYPE_TAB;
    _this.initializeSearch();
    _this.initializeFilter();
    _this.currentTab = tab;
  }

  function coursesForCurrentTab(courses) {
    return coursesForTab(courses, _this.currentTab);
  }

  function coursesForTab(courses, tab) {
    const productionAndPrimary = (course) => course.usedFor === CourseModel.USED_FOR_PRODUCTION || course.usedFor === CourseModel.USED_FOR_PRIMARY;

    if (tab === TAB_ACTIVE) {
      const activeCourses = filterActiveCourses(courses);
      return _.select(activeCourses, productionAndPrimary);
    } if (tab === TAB_FUTURE) {
      const futureCourses = filterFutureCourses(courses);
      return _.select(futureCourses, productionAndPrimary);
    } if (tab === TAB_PAST) {
      const pastCourses = filterPastCourses(courses);
      return _.select(pastCourses, productionAndPrimary);
    } if (tab === TAB_DEMO) {
      return _.select(courses, (course) => course.usedFor === CourseModel.USED_FOR_DEMO);
    } if (tab === TAB_TOTAL) {
      return courses;
    }

    return null;
  }

  function tabForCourse(course) {
    let tab;
    const courses = [course];

    if (course.usedFor === CourseModel.USED_FOR_PRIMARY) {
      tab = TAB_PRIMARY;
    } else if (course.usedFor === CourseModel.USED_FOR_DEMO) {
      tab = TAB_DEMO;
    } else if (filterActiveCourses(courses).length > 0) {
      tab = TAB_ACTIVE;
    } else if (filterFutureCourses(courses).length > 0) {
      tab = TAB_FUTURE;
    } else if (filterPastCourses(courses).length > 0) {
      tab = TAB_PAST;
    }

    return tab;
  }

  function enterSearch() {
    _this.filterType = FILTER_TYPE_SEARCH;
    _this.enteringSearchQuery = true;
    _this.searchIsOpen = true;
    _this.initializeTabs();
    _this.initializeFilter();
  }

  function closeSearch() {
    _this.searchIsOpen = false;
    _this.enteringSearchQuery = false;
  }

  function searchCourses(courses, query) {
    _this.filterType = FILTER_TYPE_SEARCH;
    _this.enteringSearchQuery = false;
    _this.searchQuery = query;
    return $filter('filter')(courses, _this.searchQuery);
  }

  function coursesOfRegistrationType(courses, type) {
    if (type === ALL_FILTER) {
      return courses;
    }
    return $filter('filter')(courses, { typeOfRegistration: type });
  }

  function coursesOfListingStatus(courses, listed) {
    if (listed === ALL_FILTER) {
      return courses;
    }
    return $filter('filter')(courses, { listed });
  }

  function coursesOfPaceType(courses, type) {
    if (type === ALL_FILTER) {
      return courses;
    }
    return _.filter(courses, { isSelfPaced: type });
  }

  function coursesOfUsedForType(courses, type) {
    if (type === ALL_FILTER) {
      return courses;
    }
    return $filter('filter')(courses, { usedFor: type });
  }

  function filterCourses(courses) {
    let filteredCourses = courses;
    let hasFilter = false;

    _this.filterType = FILTER_TYPE_FILTER;
    _this.initializeTabs();
    _this.initializeSearch();

    if (_this.currentRegistrationType !== ALL_FILTER) {
      filteredCourses = coursesOfRegistrationType(filteredCourses, _this.currentRegistrationType);
      hasFilter = true;
    }

    if (_this.currentListingStatus !== ALL_FILTER) {
      filteredCourses = coursesOfListingStatus(filteredCourses, _this.currentListingStatus);
      hasFilter = true;
    }

    if (_this.currentPaceType !== ALL_FILTER) {
      filteredCourses = coursesOfPaceType(filteredCourses, _this.currentPaceType);
      hasFilter = true;
    }

    if (_this.currentUsedForOption !== ALL_FILTER) {
      filteredCourses = coursesOfUsedForType(filteredCourses, _this.currentUsedForOption);
      hasFilter = true;
    }

    if (!hasFilter) {
      _this.filterType = FILTER_TYPE_TAB;
      _this.initializeTabs();
      filteredCourses = _this.coursesForCurrentTab(courses);
    }

    return filteredCourses;
  }

  function numFiltersApplied() {
    let numFilters = 0;

    if (_this.currentRegistrationType !== ALL_FILTER) {
      numFilters += 1;
    }

    if (_this.currentListingStatus !== ALL_FILTER) {
      numFilters += 1;
    }

    if (_this.currentPaceType !== ALL_FILTER) {
      numFilters += 1;
    }

    if (_this.currentUsedForOption !== ALL_FILTER) {
      numFilters += 1;
    }

    return numFilters;
  }

  function isTypeTab() {
    return _this.filterType === FILTER_TYPE_TAB;
  }

  function isTypeFilter() {
    return _this.filterType === FILTER_TYPE_FILTER;
  }

  function isTypeSearch() {
    return _this.filterType === FILTER_TYPE_SEARCH;
  }

  // private functions
  function filterActiveCourses(courses) {
    return _.select(courses, (course) => moment(course.releaseDate) < moment()
      && (
        (course.closeDate && moment(course.closeDate) > moment())
        || (!course.closeDate && course.endDate && moment(course.endDate) > moment())
        || (!course.closeDate && !course.endDate)
      ));
  }

  function filterFutureCourses(courses) {
    const futureCourses = nvDateRangeFilter(courses, 'releaseDate', moment());
    return futureCourses.concat(
      _.select(courses, (course) => !course.releaseDate),
    );
  }

  function filterPastCourses(courses) {
    return _.select(courses, (course) => (course.closeDate && moment(course.closeDate) <= moment())
      || (!course.closeDate && course.endDate && moment(course.endDate) <= moment()));
  }

  return _this;
}
