import {
  black,
  gray1,
  hexToRgbaString,
} from 'styles/global_defaults/colors';
import store from '../../redux/store';
import ManageCourseAccessModal from '../../institutions/components/manage-course-access-modal';
import { setCourseAccessModalProps } from '../../redux/actions/course-access-modal';
import SkillViewCourseFlyer from '../components/skill-view-course-flyer';

/* @ngInject */
export default function FlyerCtrl(
  _,
  nvCurrentPage,
  $state,
  $scope,
  $stateParams,
  RailsRoutes,
  UserAuthentication,
  CurrentUserManager,
  CurrentCourseManager,
  CurrentPermissionsManager,
) {
  const vm = this;

  $scope.CurrentUserManager = CurrentUserManager;
  $scope.CurrentCourseManager = CurrentCourseManager;

  $scope.ManageCourseAccessModal = ManageCourseAccessModal;

  vm.$stateParams = $stateParams;
  vm.currentState = $state.current.name;
  vm.CurrentUserManager = CurrentUserManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.CurrentPermissionsManager = CurrentPermissionsManager;
  vm.UserAuthentication = UserAuthentication;
  vm.RailsRoutes = RailsRoutes;
  vm.nvCurrentPage = nvCurrentPage;
  $scope.SkillViewCourseFlyer = SkillViewCourseFlyer;

  vm.canEditBasics = CurrentPermissionsManager.isConfigAndRegistrationRole();

  vm.currentUserCourse = CurrentCourseManager.course.userCourse;

  vm.courseBelongsToJourneyThatUserIsEnrolledIn = !!CurrentCourseManager.course.inJourneys.find(
    (journey) => CurrentUserManager.user?.enrollments && !!CurrentUserManager.user.enrollments.find(
      (enrollment) => enrollment.courseId === journey.id,
    ),
  );

  vm.getParentJourneyNameThatBlocksEnrollment = () => CurrentCourseManager.course.inJourneys.find(
    (journey) => journey.catalogId === CurrentCourseManager.course.blockingJourneyCatalogId,
  )?.name;

  vm.getParentJourneyHomeLinkThatBlocksEnrollment = () => $state.href('learning-journey-home', {
    catalogId: CurrentCourseManager.course.blockingJourneyCatalogId,
  });

  function goToCourseHome() {
    if (CurrentCourseManager.course.isProgram) {
      $state.go('discussions-index', { catalogId: CurrentCourseManager.course.catalogId });
    } else {
      $state.go('course-home', { catalogId: CurrentCourseManager.course.catalogId })
        .then(() => {
        }, () => {
          vm.currentUserCourse = CurrentCourseManager.course.userCourse;
        });
    }
  }

  vm.enroll = () => {
    vm.enrolling = true;

    CurrentCourseManager.enroll($stateParams.referralToken)
      .then(() => {
        if ($stateParams.mobileapp) {
          return webviewDismiss();
        }

        goToCourseHome();
      })
      .catch(webviewDismiss);
  };

  vm.reenroll = () => {
    vm.reEnrolling = true;

    CurrentCourseManager.reenroll()
      .then(() => {
        if ($stateParams.mobileapp) {
          return webviewDismiss();
        }

        goToCourseHome();
      })
      .catch(webviewDismiss);
  };

  function webviewDismiss() {
    if ($stateParams.mobileapp) {
      const stateParams = { ...$stateParams };
      stateParams['webview_dismiss'] = true;
      $state.go($state.current.name, stateParams);
    }
  }

  vm.getHeaderStyle = () => {
    const style = {};
    const backgroundImage = vm.CurrentCourseManager.course.flyerCoverPhoto
      ? vm.CurrentCourseManager.course.flyerCoverPhoto
      : vm.CurrentCourseManager.course.headerBackground;

    if (backgroundImage) {
      style.background = `linear-gradient(0deg, ${hexToRgbaString(gray1, 0.5)} 0%, ${hexToRgbaString(gray1, 0)} 100%),
        linear-gradient(${hexToRgbaString(black, 0.1)}, ${hexToRgbaString(black, 0.1)}),
        url("${backgroundImage}")`;
      style['background-position'] = 'bottom, center, center';
      style['background-repeat'] = 'no-repeat, no-repeat, no-repeat';
      style['background-size'] = '100% 50%, cover, cover';
    } else {
      style['background-color'] = vm.CurrentCourseManager.course.headerColor || vm.CurrentCourseManager.course.institution.brandColor;
    }

    return style;
  };

  vm.openManageCourseAccessModal = () => {
    store.dispatch(setCourseAccessModalProps({ catalogId: CurrentCourseManager.course.catalogId }));
  };

  vm.shouldShowSkillView = () => vm.CurrentCourseManager.course?.skillTags?.length > 0;
}
