/* @ngInject */
export default function RoutesDev(
  $urlRouterProvider,
  $stateProvider,
) {
  $stateProvider
    .state('style-guide', {
      url: '/style_guide',
      templateUrl: 'style-guide/templates/style-guide.html',
      controller: 'StyleGuideCtrl as vm',
      data: {
        title: 'Style Guide',
      },
      resolve: {
        translateReady($translate) {
          return $translate.onReady();
        },
      },
    })
    .state('style-guide-react', {
      url: '/style_guide_react',
      templateUrl: 'style-guide/templates/style-guide-react.html',
      controller: 'StyleGuideReactCtrl as vm',
      data: {
        level: 0,
      },
    })
    .state('style-guide-icons', {
      url: '/style_guide/icons',
      templateUrl: 'style-guide/templates/icons.html',
      controller: 'StyleGuideCtrl as vm',
      data: {
        title: 'Style Guide Icons',
      },
      resolve: {
        translateReady($translate) {
          return $translate.onReady();
        },
      },
    })
    .state('style-guide-icons-reference', {
      url: '/style_guide/icons_reference',
      templateUrl: 'styles/fonts/novoed-icons/icons-reference.html',
      data: {
        level: 1,
      },
    })
    .state('style-guide-code', {
      url: '/style_guide/code',
      templateUrl: 'style-guide/templates/code.html',
      controller: 'StyleGuideCodeCtrl as vm',
      data: {
        title: 'Style Guide Code',
      },
      resolve: {
        translateReady($translate) {
          return $translate.onReady();
        },
      },
    })
    .state('style-guide-froala', {
      url: '/style_guide/froala',
      templateUrl: 'style-guide/templates/froala.html',
      controller: 'StyleGuideFroalaCtrl as vm',
      data: {
        title: 'Style Guide Code',
      },
      resolve: {
        translateReady($translate) {
          return $translate.onReady();
        },
      },
    })
    .state('bs4-testbed', {
      url: '/bs4-testbed',
      templateUrl: 'style-guide/templates/bs4-testbed.html',
      controller: 'Bs4TestbedCtrl as vm',
      data: {
        title: 'Bootstrap 4 Testbed',
      },
      resolve: {
        translateReady($translate) {
          return $translate.onReady();
        },
      },
    })
    .state('scratchpad', {
      url: '/scratchpad',
      templateUrl: 'style-guide/templates/scratchpad.html',
      controller: 'ScratchpadCtrl as vm',
      data: {
        title: 'React Scratchpad',
      },
      resolve: {
        translateReady($translate) {
          return $translate.onReady();
        },
      },
    });
}
