import { css } from '@emotion/react';
import getCourseAdminCategories from 'course_home/services/course-admin-categories-data';
import CredlyBadgeSettings from 'institutions/components/credly-badge-settings';
import React from 'react';
import { AngularContext } from 'react-app';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { Institution } from 'redux/schemas/models/institution';
import { getCourseFontColor, getCurrentCourse } from 'redux/selectors/course';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { NvMenu } from 'shared/components/menu/nv-menu';
import useAngularExpression from 'shared/hooks/use-angular-expression';
import { useScrollTop } from 'shared/hooks/use-scroll';

const styles = css`
  .bs4-dropdown-menu {
    z-index: 1005;
  }
`;

export const CourseAdminMenu = () => {
  const { $scope, injectServices } = React.useContext(AngularContext);
  const [
    $state,
    $uibModal,
    CurrentCourseManager,
    RailsRoutes,
    LecturePageManager,
    TimelinesManager,
    CurrentPermissionsManager,
    CurrentUserManager,
  ] = injectServices([
    '$state',
    '$uibModal',
    'CurrentCourseManager',
    'RailsRoutes',
    'LecturePageManager',
    'TimelinesManager',
    'CurrentPermissionsManager',
    'CurrentUserManager',
  ]);
  const institution: Institution = useSelector(getCurrentInstitution);
  const currentStateName = useAngularExpression(() => $state.current.name);
  const courseFontColor = useSelector(getCourseFontColor);
  const isTopHeaderScrolling = useScrollTop(document.getElementById('main-panel'));
  const currentCourse = useSelector(getCurrentCourse);
  const userCourse = useSelector((state) => state.models.enrollments[currentCourse.userCourse]);
  const skillTags = Object.values(useSelector((state) => state.models.institutions[state.app.currentInstitutionId].skillTags));
  const institutionHasSkilltags = skillTags?.length > 0;
  // Logic to handle the credly badge configuration if the instituition has the credly integration
  const hasCredlyIntegration = institution.isCredlyEnabled ?? false;
  const handleCredlyBadgeRef = React.useRef<Function>();

  const courseAdminCategories = React.useMemo(() => {
    let categories = getCourseAdminCategories(
      t,
      $state,
      $uibModal,
      RailsRoutes,
      CurrentPermissionsManager,
      currentCourse,
      userCourse,
      CurrentUserManager,
      hasCredlyIntegration,
      handleCredlyBadgeRef,
      institutionHasSkilltags,
    )
      .filter(category => category.show)
      .map(category => ({
        ...category,
        items: category.items.filter(item => item.show),
      }))
      .filter(category => category.items.length > 0);

    // For roles that only have one category, the icon and/or title is not shown
    if (categories.length === 1) {
      categories = categories.map(category => ({
        show: category.show,
        items: category.items,
      }));
    }

    return categories;
  }, [
    $uibModal,
    $state,
    RailsRoutes,
    CurrentPermissionsManager,
    hasCredlyIntegration,
    currentCourse,
    CurrentUserManager,
  ]);

  // Cheking if it's a course/program home
  const courseRelatedPaths = [
    'course-home',
    'course-home-edit',
    'program-home',
  ];
  const isCourseHome = courseRelatedPaths.includes(currentStateName);

  const isWhiteIcon = isCourseHome && !isTopHeaderScrolling && courseFontColor === '#FFF';

  const isEditOrReorderMode = LecturePageManager.isEditMode()
    || LecturePageManager.isReorderMode()
    || TimelinesManager.isEditMode()
    || TimelinesManager.isReorderMode();

  const pageStates = [
    'team-facilitation',
    'user-management-learners',
    'user-management-course-admins',
    'announcements',
    'custom-downloads',
    'course-communications-edit',
  ];

  const selectedIfCurrentPageState = Boolean(
    pageStates.find(pageState => $scope.currentState === pageState),
  );

  return (
    <div css={styles}>
      <NvMenu
        icon='admin-dashboard'
        isWhiteIcon={isWhiteIcon}
        categories={courseAdminCategories}
        isSelected={selectedIfCurrentPageState}
        labeledBy={t.INSTITUTIONS.DASHBOARD.ADMIN_DASHBOARD()}
        tabIndex={!isEditOrReorderMode ? 0 : -1}
        hasRhs={$scope.MainGridCtrl.hasRhs}
        tooltip={t.COURSE_ADMIN_MEGA_MENU.TOOLTIP()}
      />

      {hasCredlyIntegration
      && <CredlyBadgeSettings forwardShowModal={(func) => { handleCredlyBadgeRef.current = func; }} />}
    </div>
  );
};

export default CourseAdminMenu;
