import { css } from '@emotion/react';
import { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useFormContext } from 'react-hook-form';
import _ from 'underscore';
import t from 'react-translate';

// schemas
import { CourseAliases } from 'redux/schemas/models/course';
import { HasLoggedInFilterType, CommunicationType } from 'redux/schemas/models/course-communication';
import { CommunicationDispatch } from 'communications/course_communications/contexts/communication-context';

// selectors
import { getCourseAliases, getCourse, getCurrentCourse } from 'redux/selectors/course';

// components
import NvFormDropdown from 'shared/components/inputs/nv-form-dropdown';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import NvRadioButton from 'shared/components/inputs/nv-radio-button';
import { NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';

// styles
import baseStyles from './base-styles';

export enum CompletedCourseFilterType {
  CHECKBOX = 'Checkbox',
  RADIO = 'Radio',
  CHECKBOX_WITH_DROPDOWN = 'CheckboxWithDropdown',
}

type CompletedCourseProps = {
  type?: CompletedCourseFilterType
};

const styles = css`
  &.completed-course {
    ${baseStyles};
    align-items: flex-start;
    flex-direction: column;

    .filter-wrapper {
      display: flex;
      flex-direction: row;
    }
  }
`;

const RadioFilter = (aliases: CourseAliases) => {
  const { watch, setValue } = useFormContext();
  const [radioOptionSelected, hasNotCompletedCourse] = watch(['event', 'hasNotCompletedCourse']);

  const isHighlighted = radioOptionSelected === 'completed-course';

  return (
    <div css={styles} className={`completed-course ${isHighlighted ? 'on' : ''}`}>
      <div className='checkbox-wrapper'>
        <NvRadioButton
          withForm
          disabled={hasNotCompletedCourse}
          value='completed-course'
          name='event'
          label={t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_COURSE.HAS(aliases.courseAliases)}
          labelClassName='text-large-regular pl-6'
        />
      </div>
    </div>
  );
};

const CheckboxFilter = (aliases: CourseAliases) => {
  const { watch } = useFormContext();
  const [radioOptionSelected, hasNotCompletedCourse] = watch(['event', 'hasNotCompletedCourse']);

  const disabled = radioOptionSelected === 'completed-course';
  return (
    <div css={styles} className={`completed-course ${hasNotCompletedCourse ? 'on' : ''}`}>
      <div className='checkbox-wrapper'>
        <NvCheckbox
          withForm
          disabled={disabled}
          name='hasNotCompletedCourse'
          label={t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_COURSE.HAS_NOT(aliases.courseAliases)}
          labelClassName='text-large-regular pl-6'
        />
      </div>
    </div>
  );
};

const CheckboxDropdownFilter = (aliases: CourseAliases, isSelfPaced: boolean) => {
  const { State } = useContext(CommunicationDispatch);
  const { watch, setValue } = useFormContext();
  const [hasCompletedCourseChecked = false, hasCompletedCourse, hasLoggedInChecked, hasLoggedIn] = watch([
    'hasCompletedCourseChecked', 'hasCompletedCourse', 'hasLoggedInChecked', 'hasLoggedIn',
  ]);

  /**
   * Disabled the has completed option if has not logged in since course release
   * date or start date selected
   *  */
  const isDisabledHasCompleted: boolean = (hasLoggedInChecked
    && (hasLoggedIn?.value === HasLoggedInFilterType.HAS_NOT_SINCE_START_DATE
      || hasLoggedIn?.value === HasLoggedInFilterType.HAS_NOT_SINCE_RELEASE_DATE));

  const items: NvDropdownTextItem[] = useMemo(() => [
    {
      id: 'has-completed',
      type: 'text',
      text: t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_COURSE.HAS(aliases.courseAliases),
      value: true,
      disabled: isDisabledHasCompleted,
    },
    {
      id: 'has-not-completed',
      type: 'text',
      text: t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_COURSE.HAS_NOT(aliases.courseAliases),
      value: false,
    },
  ], [aliases.courseAliases, isDisabledHasCompleted]);

  useEffect(() => {
    if (hasCompletedCourseChecked) {
      // Setting the default value based on the enabled option.
      setValue(
        'hasCompletedCourse',
        isDisabledHasCompleted ? items[1] : hasCompletedCourse || items[0],
        { shouldValidate: true, shouldDirty: true },
      );
    }
  }, [hasCompletedCourse, hasCompletedCourseChecked, isDisabledHasCompleted, items, setValue]);

  return (
    <div css={styles} className={`completed-course ${hasCompletedCourseChecked ? 'on' : ''}`}>
      <div className='filter-wrapper'>
        <div className='checkbox-wrapper'>
          <NvCheckbox
            withForm
            name='hasCompletedCourseChecked'
            label={!hasCompletedCourseChecked ? t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_COURSE.DEFAULT(aliases.courseAliases) : ''}
            labelClassName='text-large-regular pl-6'
          />
        </div>
        <div className='content'>
          <NvFormDropdown
            name='hasCompletedCourse'
            items={items}
          />
        </div>
      </div>
      {hasCompletedCourse?.id === 'has-not-completed'
        && hasCompletedCourseChecked
        && isSelfPaced
        && (State.communicationType === CommunicationType.MANUAL_EMAIL
          || State.communicationType === CommunicationType.RELEASE_DATE_EMAIL)
        && (
          <div className='text-small text-gray-1 mt-1 px-6 w-100'>
            <p>
              <span className='bold'>{t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_COURSE.PLEASE_NOTE()}</span>
              {t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_COURSE.INFO({ ...aliases.courseAliases, ...aliases.learnersAliases })}
            </p>
          </div>
        )}
    </div>
  );
};

const CompletedCourse = (props: CompletedCourseProps) => {
  const aliases = useSelector((state) => getCourseAliases(state));
  const currentCourse = useSelector((state) => getCurrentCourse(state));

  switch (props.type) {
    case CompletedCourseFilterType.RADIO:
      return RadioFilter(aliases);
    case CompletedCourseFilterType.CHECKBOX:
      return CheckboxFilter(aliases);
    default:
      return CheckboxDropdownFilter(aliases, currentCourse.isSelfPaced);
  }
};

export default CompletedCourse;
