import { css } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import t from 'react-translate';
import { useMediaQuery } from 'react-responsive';
import RecordRTC from 'recordrtc';

import { displayStreamInPlayer } from 'recording/services/media-visualizer-helper';
import { screenXsMax } from 'styles/global_defaults/media-queries';
import VideoAudioVisualizer from './video-audio-visualizer';
import { PercentageIndicator, RecordingIndicator } from './recording-indicator';

type VideoStreamPreviewProps = {
  mediaStream: MediaStream,
  showRecordIcon?: boolean;
  showAudioVisualizer?: boolean;
  timeLimit?: number;
  width?: number;
  height?: number;
  recordState?: string;
  previewPrimaryOnly?: boolean; // Only for multiple streams
};

/**
 * Displays the given video stream in a player
 */
const VideoStreamPreview = ({
  mediaStream,
  showRecordIcon,
  showAudioVisualizer,
  timeLimit,
  width,
  height,
  recordState,
  previewPrimaryOnly,
}: VideoStreamPreviewProps) => {
  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const videoRef = useRef<HTMLVideoElement>();

  const styles = css`
    .video-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      video {
        max-width: 100%;
        height: 100%;
      }

      .video-audio-visualizer {
        position: absolute;
        bottom: 3px;
        left: -17px;
      }
    }
  `;

  const [primaryStream, setPrimaryStream] = useState(null);
  const [secondaryStream, setSecondaryStream] = useState(null);

  useEffect(() => {
    if (Array.isArray(mediaStream)) {
      const screen = mediaStream[0];
      const camera = mediaStream[1];

      const screenTracks = mediaStream[0]?.getVideoTracks();
      const settings = screenTracks?.[0]?.getSettings();

      screen.width = settings?.width ? settings.width : window.screen.width;
      screen.height = settings?.height ? settings.height : window.screen.height;
      screen.fullcanvas = true;

      // Camera is shown in 20% width in right bottom. For top value, height of
      // the camera video is calculated in a 16:9 aspect ratio of the width
      camera.width = screen.width * 0.2;
      camera.height = height;
      camera.top = screen.height - (camera.width * (9 / 16));
      camera.left = screen.width - camera.width;

      setPrimaryStream(camera); // Camera is still the primary for visualizer
      setSecondaryStream(screen);
    } else {
      setPrimaryStream(mediaStream);
    }
  }, [mediaStream, width, height]);

  useEffect(() => {
    let recorder;
    if (secondaryStream && primaryStream && !previewPrimaryOnly) {
      recorder = new RecordRTC([secondaryStream, primaryStream] as any, {
        type: 'video',
        mimeType: 'video/webm;codecs=vp9',
        previewStream(s) {
          videoRef.current.muted = true;
          videoRef.current.srcObject = s;
        },
      });

      recorder.startRecording();
    } else if (primaryStream) {
      return displayStreamInPlayer(videoRef.current, primaryStream);
    }
    return () => {
      if (recorder) {
        recorder.stopRecording();
        recorder.destroy();
        setPrimaryStream(null);
        setSecondaryStream(null);
      }
    };
  }, [mediaStream, previewPrimaryOnly, secondaryStream, primaryStream]);

  return (
    <div css={styles} className='video-stream-preview'>
      <div className='video-wrapper d-flex justify-content-center'>
        <video ref={videoRef} muted autoPlay playsInline disablePictureInPicture width={width} height={height} />
        {showRecordIcon && (
          <RecordingIndicator />
        )}
        {timeLimit && (
          <PercentageIndicator timeLimit={timeLimit} recordState={recordState} />
        )}
        {showAudioVisualizer && primaryStream && (
          <VideoAudioVisualizer mediaStream={primaryStream} />
        )}
      </div>
    </div>
  );
};

export default VideoStreamPreview;
