import moment from 'moment';
import { getLearnerProgressList, getCourseOutlineSections, getLearnerProgressCounts, addManualCompletion, removeManualCompletion } from 'redux/actions/learner-progress';
import { createReducer } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { LearnerProgress, ILearnerProgressEntities, learnerProgressesSchema, LearnerProgressDashboardState } from 'redux/schemas/models/learner-progress';
import { initialRootState } from '.';


export const initialLearnerProgressDashboardState: LearnerProgressDashboardState = {
  learnerCounts: {
    totalLearners: 0,
    completed: 0,
    inProgress: 0,
    missedDeadlines: 0,
    inactiveInPastSevenDays: 0,
    neverActive: 0,
  },
};

export default createReducer(initialRootState, builder => {
  builder
    .addCase(getLearnerProgressList.fulfilled, (state, action) => {
      const data = normalize<LearnerProgress, ILearnerProgressEntities>(action.payload.response, learnerProgressesSchema);
      // We need to keep the existing section progress data, since the table caches states.
      // If you replace the existing data (and delete some) and then return to the cached state, we don't refetch. So we need to retain it.
      if (data?.entities?.learnerProgresses) {
        Object.keys(data.entities.learnerProgresses).forEach((key) => {
          if (data.entities.learnerProgresses[key].lectureSections && state.models.learnerProgresses?.[key]?.lectureSections) {
            const oldSectionsToKeep = state.models.learnerProgresses[key].lectureSections.filter(section => !data.entities.learnerProgresses[key].lectureSections.find(sec => sec.id === section.id));
            data.entities.learnerProgresses[key].lectureSections.push(...oldSectionsToKeep);
          }
          if (data.entities.learnerProgresses[key].lectureSubsections && state.models.learnerProgresses?.[key]?.lectureSubsections) {
            const oldSectionsToKeep = state.models.learnerProgresses[key].lectureSubsections.filter(section => !data.entities.learnerProgresses[key].lectureSubsections.find(sec => sec.id === section.id));
            data.entities.learnerProgresses[key].lectureSubsections.push(...oldSectionsToKeep);
          }
        });
      }
      Object.assign(state.models.learnerProgresses, { ...data.entities.learnerProgresses });
    })
    .addCase(getLearnerProgressCounts.fulfilled, (state, action) => {
      Object.assign(state.app.learnerProgressDashboard.learnerCounts, action.payload);
    })
    .addCase(getCourseOutlineSections.fulfilled, (state, action) => {
      const { lectureSections, lectureSubsections } = action.payload;

      const sortedSections = [...lectureSections, ...lectureSubsections].sort((sec1, sec2) => sec1.index - sec2.index);

      const mappedSections = sortedSections.map((sec) => {
        sec.lectureSubsections = sec.lectureSubsections.map((subSec) => {
          const subSecReleaseDates = subSec.lecturePages.map(lp => moment(lp.releaseDate)).filter((releaseDate) => releaseDate.isBefore(moment()));
          return {
            ...subSec,
            releaseDate: subSecReleaseDates.length ? moment.max(subSecReleaseDates).toISOString() : null,
          };
        });

        sec.lectureSubsections = sec.lectureSubsections.filter(subsec => subsec.releaseDate);

        const subsectionReleaseDates = sec.lectureSubsections.map(subSec => moment(subSec.releaseDate));
        const lectureReleaseDates = sec.lecturePages.map(lp => moment(lp.releaseDate)).filter((releaseDate) => releaseDate.isBefore(moment()));
        const hasLessonChildren = !!lectureReleaseDates.length;

        let sectionReleaseDate = null;
        if (lectureReleaseDates.length || subsectionReleaseDates.length) {
          sectionReleaseDate = moment.max([...lectureReleaseDates, ...subsectionReleaseDates]).toISOString();
        }

        return {
          ...sec,
          hasLessonChildren,
          releaseDate: sectionReleaseDate,
        };
      });

      const filteredSections = mappedSections.filter(sec => sec.releaseDate);

      state.models.courseOutlineSections = filteredSections;
    })
    .addCase(addManualCompletion.fulfilled, (state, action) => {
      const { id } = Object.values(state.models.learnerProgresses).find(learnerProgress => learnerProgress.userId === action.meta.arg.userId);
      state.models.learnerProgresses[id].completionStatus = action.payload;
      state.app.learnerProgressDashboard.learnerCounts.completed += 1;
      if (state.models.learnerProgresses[id].lastActivity) {
        state.app.learnerProgressDashboard.learnerCounts.inProgress -= 1;
      }
    })
    .addCase(removeManualCompletion.fulfilled, (state, action) => {
      const { id } = Object.values(state.models.learnerProgresses).find(learnerProgress => learnerProgress.userId === action.meta.arg.userId);
      state.models.learnerProgresses[id].completionStatus = action.payload;
      state.app.learnerProgressDashboard.learnerCounts.completed -= 1;
      if (state.models.learnerProgresses[id].lastActivity) {
        state.app.learnerProgressDashboard.learnerCounts.inProgress += 1;
      }
    });
});
