/* @ngInject */
export default function MigratedFields(
  $translate,
) {
  return {
    getDisplayName(migratedField) {
      switch (migratedField) {
        case 'employer':
          return $translate.instant('ORG_LEVEL_PROFILE.ORG_FIELDS_DEFAULT_TEXTS.WORK');
        case 'institution':
          return $translate.instant('ORG_LEVEL_PROFILE.ORG_FIELDS_DEFAULT_TEXTS.EDUCATION');
        case 'job_title':
          return $translate.instant('ORG_LEVEL_PROFILE.ORG_FIELDS_DEFAULT_TEXTS.JOB_TITLE');
        default:
          return null;
      }
    },
  };
}
