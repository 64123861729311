/* @ngInject */
export default function PusherChannels() {
  return {
    announcementsChannel: 'public-announcements',
    userChannel(identifier) {
      return `public-${identifier}`;
    },
    userCourseChannel(identifier, courseId = null) {
      return `public-${courseId}-${identifier}`;
    },
    courseChannel(courseId) {
      return `public-course-${courseId}`;
    },
    discussionsChannelCourse(courseId) {
      return `public-course-discussions-${courseId}`;
    },
    discussionsChannelReport(reportId) {
      return `submission-discussions-${reportId}`;
    },
    scormProgressChannel(lectureId) {
      return `scorm-automation-${lectureId}`;
    },
  };
}
