/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AddMultipleUsersParams, CourseRolesParams, LargerBulkUploadResponse,
  CourseRolesUpdateParams, OrgAdminParams, OrgAdminUpdateParams, SearchOrgUsersResponse,
  SmallerBulkUploadResponse, SearchOrgAdminsResponse,
} from 'redux/schemas/api/org-level-roles';
import { InfiniteTableLoadingParams } from 'redux/create-action-creators';
import { CourseRole } from 'redux/schemas/models/org-level-roles';
import { OrgAdmin, OrgLevelUser } from 'redux/schemas/models/user';
import { Result } from 'redux/schemas/api';
import t from 'react-translate';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { RoleResourceType } from 'redux/schemas/models/role';
import { addAlertMessage } from './alert-messages';

export const searchOrgUsers = createAsyncThunk(
  'ORG_ROLES_SEARCH_ALL_USERS',
  async (params: { institutionId: number } & InfiniteTableLoadingParams<OrgLevelUser>) => {
    const payload = {
      page: params.pageIndex,
      pageSize: params.pageSize,
      queryTerm: params.searchQuery,
      // Need this option for getting the `canRemoveFromInstitution` field in the response.
      checkIfCanRemove: true,
    };
    const response = await axios.post<Result<SearchOrgUsersResponse>>('/users/search/organization.json', payload);
    const users = response?.data?.result?.users || [];
    const totalCount = response?.data?.result?.count || 0;
    return {
      totalCount,
      response: users,
    };
  },
);

export const deleteOrgUser = createAsyncThunk(
  'DELETE_ORG_USER',
  async (params: { institutionId: number, userId: number }, thunkAPI) => {
    try {
      const response = await axios.post(
        `/institutions/${params.institutionId}/user_management/remove_user_from_institution`,
        { userId: params.userId },
      );
      return response;
    } catch (error) {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.USERS.REGISTRATION.ERRORS.GENERIC(),
      }));
      return null;
    }
  },
);

export const getOrgAdmins = createAsyncThunk(
  'ORG_ROLES_ORG_ADMINS',
  async (params: { institutionId: number } & InfiniteTableLoadingParams<OrgAdmin>) => {
    const response = await axios.get<Result<SearchOrgAdminsResponse>>(`institutions/${params.institutionId}/institution_admins?page=${params.pageIndex}&page_size=${params.pageSize}`);
    const users = response?.data?.result?.orgAdmins || [];
    const totalCount = response?.data?.result?.count || 0;
    return {
      totalCount,
      response: users,
    };
  },
);

export const addOrgAdmin = createAsyncThunk(
  'ADD_NEW_ORG_ADMIN',
  async (params: OrgAdminParams, thunkAPI) => {
    try {
      const response = await axios.post<Result<OrgAdmin>>(`institutions/${params.institutionId}/institution_admins`, params);
      return response.data.result;
    } catch (error) {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR(),
      }));
      return null;
    }
  },
);

export const deleteOrgAdmin = createAsyncThunk(
  'DELETE_ORG_ADMIN',
  (params: { institutionId: number, adminId: number }) => axios.delete(
    `/institutions/${params.institutionId}/institution_admins/${params.adminId}`,
  ).then((response) => response.data),
);

export const updateOrgAdmin = createAsyncThunk(
  'UPDATE_ORG_ADMIN',
  async (params: OrgAdminUpdateParams, thunkAPI) => {
    const { adminId, ...updateParams } = params;
    try {
      const response = await axios.patch<Result<boolean>>(`institutions/${params.institutionId}/institution_admins/${params.adminId}`, updateParams);
      return response.data.result;
    } catch (error) {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR(),
      }));
      return null;
    }
  },
);

export const getCourseRoles = createAsyncThunk(
  'GET_COURSE_ROLES',
  async (params: { institutionId: number } & InfiniteTableLoadingParams<CourseRole>) => {
    const response = await axios.get<Result<CourseRole[]>>('course_roles.json', { params: { institutionId: params.institutionId } });
    const users = response?.data?.result?.filter((role) => role.resourceType === RoleResourceType.COURSE) || [];
    return { response: users };
  },
);

export const addCourseRoles = createAsyncThunk(
  'ADD_COURSE_ROLE',
  async (params: CourseRolesParams, thunkAPI) => {
    try {
      const response = await axios.post<Result<CourseRole>>(`course_roles?institution_id=${params.institutionId}`, params);
      return response.data.result;
    } catch (error) {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR(),
      }));
      return null;
    }
  },
);

export const updateCourseRole = createAsyncThunk(
  'UPDATE_COURSE_ROLE',
  async (params: CourseRolesUpdateParams, thunkAPI) => {
    try {
      const response = await axios.put<Result<CourseRole>>(`course_roles/${params.roleId}?institution_id=${params.institutionId}`, params);
      return response.data.result;
    } catch (error) {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR(),
      }));
      return null;
    }
  },
);

export const deleteCourseRole = createAsyncThunk(
  'DELETE_COURSE_ROLE',
  (params: { institutionId: number, roleId: number }) => axios.delete(
    `/course_roles/${params.roleId}`, { params: { institutionId: params.institutionId } },
  ).then((response) => response.data),
);

export const getCourseRole = createAsyncThunk(
  'GET_COURSE_ROLE',
  async (params: { institutionId: number, roleId: number }) => {
    const response = await axios.get<Result<CourseRole>>(`/course_roles/${params.roleId}`, { params: { institutionId: params.institutionId } });

    return response.data.result;
  },
);

export const addMultipleUsers = createAsyncThunk(
  'ORG_ROLES_ADD_MULTIPLE_USERS',
  async (params: AddMultipleUsersParams, thunkAPI) => {
    try {
      const response = await axios.post<Result<LargerBulkUploadResponse | SmallerBulkUploadResponse>>(
        `institutions/${params.institutionId}/upload_org_profiles`,
        params,
      );
      return response.data.result;
    } catch (error) {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR(),
      }));
      return null;
    }
  },
);
