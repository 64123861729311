import React from 'react';

const AsyncContentContext = React.createContext<{ register?: (promise: Promise<any>) => void}>({});

type AsyncContentProviderProps = {
  children: React.ReactNode,
  onContentReady: () => void,
};

export const AsyncContentProvider = (props: AsyncContentProviderProps) => {
  const { children, onContentReady } = props;

  const asyncContentItemsRef = React.useRef([]);
  const onContentReadyRef = React.useRef<() => void>();
  onContentReadyRef.current = onContentReady;

  React.useEffect(() => {
    Promise.all(asyncContentItemsRef.current).then(onContentReadyRef.current);
  }, []);

  const registerRef = React.useRef((promise) => {
    asyncContentItemsRef.current.push(promise);
  });

  const contextValueRef = React.useRef({ register: registerRef.current });

  return (
    <AsyncContentContext.Provider value={contextValueRef.current}>
      {children}
    </AsyncContentContext.Provider>
  );
};

const useAsyncContent = () => {
  const isResolvedRef = React.useRef(false);
  const promiseRef = React.useRef<Promise<null>>();
  const resolvePromiseRef = React.useRef<Function>();

  const onReady = React.useCallback(() => {
    if (!isResolvedRef.current) {
      resolvePromiseRef.current?.(null);
      isResolvedRef.current = true;
    }
  }, []);

  const asyncContentContext = React.useContext(AsyncContentContext);

  React.useEffect(() => {
    if (asyncContentContext?.register) {
      const promise = new Promise<null>((resolve) => {
        resolvePromiseRef.current = resolve;
      });

      promiseRef.current = promise;

      asyncContentContext.register(promise);
    }
  }, [asyncContentContext]);

  return React.useMemo(() => ({
    onReady,
  }), [onReady]);
};

export default useAsyncContent;
