import React from 'react';
import { css } from '@emotion/react';
import { gray4 } from 'styles/global_defaults/colors';

type LineDividerProps = {
  className?: string,
  shouldHide?: boolean,
  extraComponent?: JSX.Element,
};

const LineDivider = (props: LineDividerProps) => {
  const {
    className,
    shouldHide = false,
    extraComponent,
  } = props;

  const styles = css`
    position: relative;
    width: ${260}px;
    .line {
      height: 1px;
      background-color: ${gray4};
    }
    .extra-component {
      justify-content: center;
    }
  `;

  if (shouldHide) {
    return null;
  }

  return (
    <div css={styles} className={className}>
      <div className='line' />
      <div className='extra-component d-flex'>
        {extraComponent}
      </div>
    </div>
  );
};

export default LineDivider;
