import { css } from '@emotion/react';
import { standardSpacing, halfSpacing, largeSpacing, threeQuartersSpacing, dropdownBottomPadding } from 'styles/global_defaults/scaffolding';
import { gray1, gray3, gray6, black, primary, gray7, gray4, warning, info, white } from 'styles/global_defaults/colors';
import { allDesktop, notDesktop } from 'styles/global_defaults/media-queries';
import { textSmallFontSize, textSmallLineHeight, semiBoldFontWeight } from 'styles/global_defaults/fonts';

const menuWidth = 30;
const menuWithPaddingWidth = menuWidth + standardSpacing;

export const allVersionsStyles = css`
  color: ${black};
  span[oe-mention] {
    color: ${primary};
    cursor: default;
    margin-right: 0;
  }

  iframe {
    width: 100%;
  }

  a {
    color: ${primary};
    cursor: pointer;
  }
`;

const getStyles = (inlineMode: boolean, hasBeenFocused: boolean, isInFocus: boolean) => css`
  /* All Versions */
  .nv-froala-editor {
    /* this is a little tricky to do, will rely on angularJS side for now */
    /* @include sansSerifFonts; */

    &.fr-box.fr-basic.fr-top .fr-wrapper {
      box-shadow: none;
    }

    .fr-element {
      overflow-x: auto;
      padding: ${halfSpacing}px;
      ${allVersionsStyles};
    }

    &.no-padding {
      .fr-element {
        padding: 0;
      }
    }

    ${!isInFocus && css`&:not(.no-background) {
      .fr-placeholder {
        height: 100%;
        background-color: ${gray7};
        border: 1px solid ${gray4};
      }

      &:hover {
        cursor: pointer;
        .fr-wrapper{
          &:not(.show-placeholder) { /* combining this row and the row above fails */
            .fr-element {
              background-color: ${gray7};
            }
          }
        }
      }
    }`}

    /* need this because of scss ordering */
    &.fr-box.fr-code-view .fr-placeholder {
      display: none;
    }
  }

  /* Air Only */
  .nv-froala-editor.air {
    &.fr-box.fr-basic:not(.no-background) .fr-wrapper {
      border: 1px solid ${gray4};
    }

    ${!isInFocus && css`&:not(.no-background) {
      .fr-wrapper{
        &:not(.show-placeholder) { /* combining this row and the row above fails */
          .fr-element {
            background-color: ${gray7};
          }
        }
      }
    }`}

    .fr-element:focus {
      outline: 2px solid ${primary};
    }
  }

  /* body:not(.no-touch) .nv-froala-origami .froala-novoed-menu button:not(:disabled):hover {
    i {
      color: ${black};
    }
  } */

  /* body.no-touch .nv-froala-origami .froala-novoed-menu button:not(:disabled):hover { */
  .nv-froala-origami .froala-novoed-menu button:not(:disabled):hover {
    background-color: ${primary};

    i {
      color: white;
    }
  }

  /* Full Version Only */
  .nv-froala-origami {
    position: relative;

    ${inlineMode && hasBeenFocused && allDesktop(css`
      margin-left: ${menuWithPaddingWidth}px;
    `)}

    .froala-novoed-menu {
      width: 100%;

      button {
        padding: 0;
        text-decoration: none;

        &:not(.submit-button) {
          background-color: ${gray7};
          color: black;
          border-radius: 100%;
          min-height: ${menuWidth}px;
          height: ${menuWidth}px;
          width: ${menuWidth}px;

          &.active {
            background-color: ${primary};
            color: white;
          }
        }
      }
    }

    ${allDesktop(css`
      .froala-novoed-menu {
        position: absolute;
        left: -${menuWithPaddingWidth}px;

        button {
          display: block;
          margin-bottom: ${halfSpacing}px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    `)}

    ${notDesktop(css`
      .froala-novoed-menu {
        display: block;
        margin-bottom: ${standardSpacing}px;

        button {
          margin-right: ${halfSpacing}px;
        }
      }
    `)}

    .support-tags-row {
      margin-top: ${halfSpacing}px;
      color: ${primary};
      cursor: pointer;
    }

    .support-tags-popover {
      width: 110px;
    }

    .fr-element:focus {
      outline: none;
    }

    .fr-box.fr-inline.fr-code-view .fr-command.fr-btn.html-switch {
      display: none;
    }
  }

  .fr-buttons.dark {
    background: ${gray1};

    i {
      color: white;
    }
  }

  .fr-toolbar.fr-inline {
    margin-top: 5px;
    border-top: 0px;
    background: ${black};
    z-index: 1070;

    .icon {
      color: white;
    }

    .fr-active {
      .icon {
        color: ${primary};
      }
    }

    .fr-command.fr-btn.fr-dropdown::after {
      border-top-color: white !important; // froala added important so we need to add important
    }

    .fr-command.fr-btn {
      &:hover, &:focus {
        background: ${gray3} !important; // froala added important so we need to add important
      }
    }

    .fr-arrow {
      top: -5px;
      border-bottom-color: ${black};
    }
  }

  .fr-popup {
    margin-top: 5px;
    border-top: 0px;
    ${dropdownBottomPadding(3 * largeSpacing)};

    ${notDesktop(css`
      .froala-novoed-menu {
        display: block;
        margin-bottom: ${standardSpacing}px;

        button {
          margin-right: ${halfSpacing}px;
        }
      }
    `)}

    .fr-command.fr-btn {
      &:hover, &:focus {
        background: ${gray3} !important; /* froala added important so we need to add important */
      }
    }

    .fr-arrow {
      top: -5px;
    }

    .fr-color-set {
      padding: 15px;

      &> span:hover {
        outline: none;
      }

      .fr-select-color {
        width: 16px;
        height: 16px;
        border-radius: 100%;
        margin: 5px;

        &.outline:not(.fr-active-item) {
          border: 1px solid ${gray6};
        }
      }

      > span .fr-selected-color {
        border-radius: 100%;
        height: 22px;
        width: 22px;
        border: 3px solid ${gray6};
        box-shadow: 2px 2px 4px ${black};
        top: -3px;
        left: -3px;
        cursor: pointer;
      }

      &.fr-background-color {
        background-color: ${gray3};

        .color-header {
          color: white;
        }
      }

      .color-header {
        font-size: ${textSmallFontSize}px;
        line-height: ${textSmallLineHeight}px;
        font-weight: ${semiBoldFontWeight};
        margin-bottom: 5px;
      }
    }
  }

  .upload-media {
    .or {
      text-align: center;
      margin: ${largeSpacing}px 0 ${threeQuartersSpacing}px 0;
    }

    .button-bar {
      margin-top: ${largeSpacing}px;
    }
  }

  .fr-toolbar {
    .fr-command.fr-btn + .fr-dropdown-menu {
      ${dropdownBottomPadding(3 * largeSpacing)};
      /* // todo: revisit if the following is needed. it is breaking floating toolbar placement */
      /* // overflow: visible; */

      & .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list {
        margin: ${standardSpacing}px 0;

        li a.paragraph-style-item {
          position: relative;
          padding-right: 76px;

          i.icon.icon-check {
            color: ${warning};
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: ${largeSpacing}px;
            display: none;
          }

          &.fr-novoed-active {
            i.icon.icon-check {
              display: block;
            }
          }
        }
      }
    }
  }

  ${isInFocus && css`body:not(.no-touch) .modal.discussions-modal [nv-froala-editor] {
    ${dropdownBottomPadding(300)};
  }`}

  .tribute-container {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    max-height: 300px;
    max-width: 500px;
    overflow: auto;
    display: block;
    z-index: 999999;
    border: 1px solid ${gray4};
    border-radius: 5px;
    width: 200px;

    ul {
      margin: 0;
      margin-top: 2px;
      padding: 0;
      list-style: none;
      background-color: white;
    }

    li {
      display: flex;
      padding: 5px 15px;
      display: flex;
      cursor: pointer;

      &:hover {
        background-color: ${info};
      }

      &.highlight {
        background-color: ${info};
      }

      .user-name {
        margin-left: 10px;
      }
    }
    li > div {
      display: flex;
      align-items: center;
    }

    li span {
      font-weight: bold;
    }

    li.no-match {
      cursor: default;
    }
  }

  .oe-mention {
    background-color: ${info};
    font-weight: ${semiBoldFontWeight};
    color: ${primary};
  }

  .froala-container {
    position: relative;
  }

`;

export default getStyles;

/* styles to take care of - may not be needed, but keeping around just in case */
// body.keyboard_user .fr-popup {
//   .fr-link-insert-layer .fr-input-line .fr-link-attr {
//     margin-top: 9px;
//     padding-left: 3px;
//     padding-right: 3px;
//   }
// }
// .froala-style-title {
//   ${courseTitleLg}
// }
// .froala-style-subtitle {
//   ${cardTitle}
// }
// .froala-style-big {
//   ${pageTitle}
// }
// .froala-style-regular {
//   ${fontSizeMedium}
//   line-height: $header-line-height;
// }
// .froala-style-small {
//   color: ${gray1};
//   ${fontSizeSmall}
//   font-weight: ${semiBold};
// }

// .froala-text-align-left {
//   text-align: left;
// }

// .froala-text-align-center {
//   text-align: center;
// }

// .froala-text-align-right {
//   text-align: right;
// }
