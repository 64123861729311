import { Method } from 'axios';

export type HCMConfigState = {
  isLoading?: boolean;
};

export enum HCMServices {
  CONFIG_HCM = 'config_hcm',
  DOWNLOAD_TEMPLATE = 'download_csv',
  UPLOAD_CSV = 'upload_csv',
  INTEGRATION_TYPE = 'config/integration_type',
}

export enum HCMIntegrationTypes {
  CSV_SFTP = 'csv_sftp',
  WORKDAY_API = 'workday_api',
}

export type HCMUploadCSVPayload = {
  institutionId: number,
  data: any,
};

export type HCMSettings = {
  endpoint: string,
  username: string,
  password: string,
  integrationType: string,
};

export type HCMSettingsPayload = HCMSettings & {
  institutionId: number,
};

export type HCMDownloadTemplatePayload = {
  institutionId: number;
};

export type HCMEndpoint = {
  service: HCMServices,
  institutionId: number,
  method?: Method,
  data?: any,
};
