import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import React, { useRef, useEffect, forwardRef, useImperativeHandle } from 'react';

import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';

import { fetchBookmarks, fetchSingleBookmark } from 'redux/actions/bookmarks';
import { getFilteredBookmarks } from 'redux/selectors/bookmarks';

import { useAppDispatch } from 'redux/store';
import SingleBookmark from './single-bookmark';
import LoadingPlaceholder from './loading-placeholder';
import NoBookmarks from './no-bookmarks';

export const PAGE_SIZE = 20;

type BookmarksListProps = {
  page: number,
  total: number | null,
  onPageChange: (newPage: number) => void,
};

const BookmarksList = forwardRef<{
  checkUnsavedChanges: Function,
}, BookmarksListProps>((props, ref) => {
  const { page, total, onPageChange } = props;

  const styles = css`
    overflow-y: scroll;
    position: relative;
  `;

  useImperativeHandle(ref, () => ({
    checkUnsavedChanges,
  }));

  const bookmarksRef = useRef<any>({});

  const dispatch = useAppDispatch();

  const { loading, filter, highlighted, all } = useSelector(state => state.app.bookmarks);
  const courseBookmarks = useSelector(state => state.app.bookmarks.courses?.[filter]);

  const scrollingElementRef = useRef<HTMLDivElement>(null);
  const isPageBottom = useInfiniteScroll(scrollingElementRef.current, 20);

  const filteredBookmarks = useSelector(getFilteredBookmarks);

  const loadingRef = React.useRef<boolean>();
  loadingRef.current = loading;

  const pageRef = React.useRef<number>();
  pageRef.current = page;

  const missingContentRef = React.useRef<boolean>();
  missingContentRef.current = (page * PAGE_SIZE) < (total === null ? Infinity : total);

  useEffect(() => {
    if (isPageBottom && !loadingRef.current && missingContentRef.current) {
      onPageChange(pageRef.current + 1);
    }
  }, [isPageBottom, onPageChange]);

  useEffect(() => {
    if (highlighted && !all[highlighted]) {
      dispatch(fetchSingleBookmark(highlighted));
    }
  }, [highlighted, all, dispatch]);

  useImperativeHandle(ref, () => ({
    checkUnsavedChanges,
  }));

  const checkUnsavedChanges = () => Object.values(bookmarksRef.current).some((bookmark: any) => bookmark?.unsavedChanges());

  return (
    <div className='bookmarks-list' css={styles} ref={scrollingElementRef}>
      {(loading && (page === 1)) ? (
        <LoadingPlaceholder />
      ) : (
        <>
          {filteredBookmarks.length ? (
            <React.Fragment>
              {filteredBookmarks.map((bookmark, index) => (
                <SingleBookmark
                  ref={(bookmarkRef) => { bookmarksRef.current[index] = bookmarkRef; }}
                  key={bookmark.id}
                  bookmark={bookmark}
                  index={index}
                />
              ))}
              {loading && <LoadingPlaceholder />}
            </React.Fragment>
          ) : <NoBookmarks />}
        </>
      )}
    </div>
  );
});

export default BookmarksList;
