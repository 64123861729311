/* @ngInject */
export default function nvRolesBadge(
  RolesService,
  _,
) {
  return {
    restrict: 'E',
    scope: {
      roles: '=',
    },
    link(scope, elem, attrs) {
      scope.isAdmin = RolesService.isAdminRole(scope.roles);
      scope.isMentor = RolesService.isMentor(scope.roles);
    },
    templateUrl: 'shared/templates/nv-role-badge.html',
  };
}
