import React from 'react';
import _ from 'underscore';
import { WorkflowParams } from '.';
import { ConditionalWrapFileWorkflow } from './file-workflow';
import ConditionalWrapModalWorkflow from './modal-workflow';

const ConditionalWorkflow = (props: WorkflowParams) => {
  const workflowParams: Omit<WorkflowParams, 'children' | 'saveNewComponent'> = _.omit(props, 'children', 'saveNewComponent');

  if (workflowParams.isDisabled) {
    return (
      <React.Fragment>
        {props.children}
      </React.Fragment>
    );
  }

  const save = (lcData, customType = null) => {
    const type = customType || props.componentType;
    return props.saveNewComponent
      ? props.saveNewComponent(type, props.metadata, lcData)
      : props.save(lcData, props.componentType, props.lectureComponent, props.metadata);
  };

  return (
    <div>
      {/* Handlers for the different workflow types. Only one of these will take affect at a time */}
      <ConditionalWrapModalWorkflow {...workflowParams} save={save}>
        <ConditionalWrapFileWorkflow {...workflowParams} save={save}>
          {props.children}
        </ConditionalWrapFileWorkflow>
      </ConditionalWrapModalWorkflow>
    </div>
  );
};

export default ConditionalWorkflow;
