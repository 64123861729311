
import { AngularServicesContext } from 'react-app';
import { useRef, useEffect, useContext } from 'react';
import { embedAngularTemplate } from 'shared/embed-angular';
import { getExercisesWithHardDeadline } from 'redux/actions/exercises';
import { BaseUser } from 'redux/schemas/models/my-account';
import { useAppDispatch } from 'redux/store';

type GrantLateSubmissionPermitProps = {
  user: BaseUser,
  catalogId: string,
  closeModal: () => void,
};

const GrantLateSubmissionPermitModal = (props: GrantLateSubmissionPermitProps) => {
  const dispatch = useAppDispatch();

  const grantPermitRef = useRef(null);
  const angularServices = useContext(AngularServicesContext);

  useEffect(() => {
    dispatch(getExercisesWithHardDeadline({ catalogId: props.catalogId, userId: props.user.id })).then(res => {
      const exercises = res.payload;
      angularServices.$scope.user = props.user;
      angularServices.$scope.closeModal = props.closeModal;
      angularServices.$scope.exercises = exercises;
      angularServices.$scope.origin = 'user';
      angularServices.$scope.header = { name: `${props.user.firstName} ${props.user.lastName}` };
      embedAngularTemplate('learner_progress/templates/grant-late-submission-permit-modal.html', grantPermitRef, angularServices);
    });
  }, []);

  return (
    <div>
      <div ref={grantPermitRef} />
    </div>
  );
};

export default GrantLateSubmissionPermitModal;
