import { css } from '@emotion/react';
import React, { useEffect, useRef, useState } from 'react';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';

// Styles
import { quarterSpacing } from 'styles/global_defaults/scaffolding';
import { handheld } from 'styles/global_defaults/media-queries';

export enum TagButtonGenericIconPosition {
  LEFT,
  RIGHT,
}

type TagButtonGenericProps = {
  text: string,
  customIcon?: string,
  normalClassNames?: string[],
  selectedClassNames?: string[],
  elementId?: string;
  iconPosition?: number,
  onClick?: (event?: any) => void,
  onIconClick?: (event?: any) => void,
  isSelectable?: boolean,
  isSelected?: boolean,
};

const NvTagButtonGeneric = ({
  text,
  customIcon = null,
  normalClassNames = ['text-small', 'bg-info', 'text-gray-2', 'font-weight-bolder'],
  selectedClassNames = ['text-small', 'font-weight-bolder'],
  elementId,
  iconPosition = TagButtonGenericIconPosition.LEFT,
  onClick,
  onIconClick,
  isSelectable = false,
  isSelected = false,
}: TagButtonGenericProps) => {
  const [selected, setSelected] = useState(isSelected);
  const [showTooltip, setShowTooltip] = useState(false);
  const textRef = useRef(null);
  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);
  const maxTagButtonGenericWidth = 300;
  const styles = css`
    button {
      max-width: ${maxTagButtonGenericWidth}px;
      display: grid;
      grid-auto-flow: column;
      border-radius: 15px;
      border: 0px;
      height: 30px;
      &.button-left{
        direction: rtl;
      }
      .icon {
        align-self: center;
        padding: 0 2px;
      }
      span {
        direction: ltr;
        align-self: center;
        padding: 0 ${quarterSpacing}px;
      }
    }
    ${handheld(css`
      button{
        max-width: ${maxTagButtonGenericWidth / 2}px;
      }
    `)}
  `;

  const handleClick = (event) => {
    if (isSelectable) {
      setSelected(!selected);
    }
    onClick?.(event);
  };

  const handleIconClick = (event) => {
    if (isSelectable) {
      setSelected(!selected);
    }
    onIconClick?.(event);
  };

  const handleFocus = (event: any) => {
    const textElement = textRef?.current;
    if (parseInt(textElement?.offsetWidth, 10) < parseInt(textElement?.scrollWidth, 10)) {
      setShowTooltip(true);
    }
  };

  const handleUnfocus = (event: any) => {
    if (showTooltip) {
      setShowTooltip(false);
    }
  };

  const getClasses = (classes: string[]) => classes.join(' ');

  customIcon = selected ? 'check' : customIcon;
  const positionClass = iconPosition === TagButtonGenericIconPosition.LEFT ? 'button-left' : '';
  const elementClassNames = selected ? getClasses(selectedClassNames) : getClasses(normalClassNames);
  return (
    <div css={styles}>
      <NvTooltip text={text} show={showTooltip}>
        <button
          id={elementId}
          type='button'
          className={`${positionClass} ${elementClassNames}`}
          onClick={handleClick}
          onFocus={handleFocus}
          onMouseEnter={handleFocus}
          onBlur={handleUnfocus}
          onMouseLeave={handleUnfocus}
        >
          <span className='ellipsis' ref={textRef}>
            {text}
          </span>
          {customIcon
            && (
              <div className='icon text-gray-3'>
                <NvIcon
                  size='xss-smallest'
                  icon={customIcon}
                  onClick={handleIconClick}
                />
              </div>
            )}
        </button>
      </NvTooltip>
    </div>
  );
};

export default NvTagButtonGeneric;
