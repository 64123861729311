/* @ngInject */
export default function nvCompileOnce($compile, nvUtil) {
  return {
    restrict: 'A',
    replace: true,
    link(scope, element, attrs) {
      element.html(nvUtil.makeIframesResponsive(attrs.nvCompileOnce));
      $compile(element.contents())(scope);
    },
  };
}
