import { css } from '@emotion/react';
import t from 'react-translate';
import { useMediaQuery } from 'react-responsive';

import { quarterSpacing } from 'styles/global_defaults/scaffolding';
import { danger, gray6 } from 'styles/global_defaults/colors';
import { screenXsMax } from 'styles/global_defaults/media-queries';

export const RecordingIndicator = () => {
  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const styles = css`
    display: flex;
    position: absolute;
    bottom: ${isHandheld ? 30 : 20}px;
    left: 30px;

    .recording-icon {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: ${danger};
      align-self: center;
      margin-right: ${quarterSpacing}px;
    }

    .recording-text {
      color: white;
      font-weight: bold;
    }
  `;

  return (
    <div className='recording-indicator' css={styles}>
      <div className='recording-icon' />
      {!isHandheld && (
        <div className='recording-text'>{t.FILE_UPLOAD.RECORDING()}</div>
      )}
    </div>
  );
};

const percentageStyles = (timeLimit: number, recordState: string) => css`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${gray6};
  height: 6px;

  .video-percentage {
    height: 100%;
    background-color: ${danger};
    animation-name: progress;
    animation-duration: ${timeLimit}s;
    animation-timing-function: linear;
    animation-play-state: ${recordState};
    animation-fill-mode: forwards;
  }

  @keyframes progress {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }
`;

export const PercentageIndicator = (props:{ timeLimit: number, recordState: string}) => (
  <div css={percentageStyles(props.timeLimit, props.recordState)}>
    <div className='video-percentage' />
  </div>
);
