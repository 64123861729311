import React, { useState, useContext, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';
import { isEmpty } from 'underscore';

import { getCurrentInstitution } from 'redux/selectors/institutions';
import { CombinedInstitution, RootState } from 'redux/schemas';

import { black, gray6, gray7 } from 'styles/global_defaults/colors';
import {
  createGridStyles, doubleSpacing, halfSpacing, quarterSpacing, standardSpacing,
  threeQuartersSpacing, tripleSpacing,
} from 'styles/global_defaults/scaffolding';

import NvResponsiveTabsRow from 'shared/components/nv-responsive-tabs-row';
import {
  NvResponsiveTabsDisplayType,
  NvTab,
} from 'shared/components/nv-responsive-tabs';
import { NvExpandableSearchBar } from 'shared/components/nv-search-bar';
import NvPopover from 'shared/components/nv-popover';
import OrgMentorAdminPanel from 'org_mentor/components/org-mentor-admin-panel';
import OrgAllUsers from './org-all-users';
import OrgAdmins from './org-admins';
import CourseRoles from './course-roles';

enum Tab {
  ALL_USERS = 'all-users',
  ORG_ADMINS = 'org-admins',
  MENTORS = 'org-mentors',
  COURSE_ROLES = 'manage-course-roles',
}

const styles = (isSearchOpen) => css`
  ${isSearchOpen && css`
    .btn-panel {
      width: 100%;
      margin-right: ${standardSpacing}px;
    }
  `}

  .nv-expandable-search-bar {
    &.expanded {
      width: 100%;
      margin-left: ${standardSpacing}px;

      .search-button {
        margin-right: ${quarterSpacing}px;
      }
    }

    input {
      color: ${black};
      font-weight: 400;
    }

    .close-button {
      font-size: 16px;
      height: 16px;
      margin-right: 0;
    }

    .icon-search {
      /* To override the default gray2 */
      color: ${black} !important;
    }
  }
`;

const tabContentStyles = css`
  .tab-action-header {
    display: flex;
    height: ${tripleSpacing}px;
    width: 100%;
    justify-content: center;
    background-color: ${gray7};
    padding: 0 ${halfSpacing}px;

    .tab-action-panel {
      display: flex;
      width: 800px;
    }
  }
`;

const popoverStyles = css`
  max-width: none;

  .arrow {
    /**
     * Popover arrow shows the center of the NvExpandableSearchBar which has 100% width.
     * Overrides the default arrow transform for showing the arrow at the start of the search bar.
     */
    transform: translate(${doubleSpacing}px, 0px) !important;
  }

  .bs4-popover-body {
    padding: ${halfSpacing}px ${threeQuartersSpacing}px;
    font-size: 14px;
    line-height: ${standardSpacing}px
  }
`;

const OrgLevelUserManagement = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const searchRef = useRef<HTMLInputElement>(null);
  const { $state } = useContext(AngularServicesContext);
  const institution = useSelector<RootState, CombinedInstitution>(getCurrentInstitution);

  const tabParam = $state?.params?.tab;

  const lastTabRef = React.useRef();
  lastTabRef.current = tabParam || lastTabRef.current;

  const tab = lastTabRef.current;

  useEffect(() => {
    if (isSearchOpen) {
      searchRef?.current.focus();
    }
  }, [isSearchOpen]);

  const tabs: NvTab[] = [
    {
      text: t.INSTITUTIONS.ROLES.ALL_USERS.TITLE(),
      onClick: () => $state.go('institution-roles', {
        tab: Tab.ALL_USERS,
      }),
    }, {
      text: t.INSTITUTIONS.ROLES.ORG_ADMINS(),
      onClick: () => $state.go('institution-roles', {
        tab: Tab.ORG_ADMINS,
      }),
    }, {
      text: t.INSTITUTIONS.ROLES.ORG_MENTORS({
        MentorsTitleAlias: institution.roleAliases.mentor.pluralizedTitleized,
      }),
      onClick: () => $state.go('institution-roles', {
        tab: Tab.MENTORS,
      }),
    }, {
      text: t.INSTITUTIONS.ROLES.MANAGE_COURSE_ROLES(),
      onClick: () => $state.go('institution-roles', {
        tab: Tab.COURSE_ROLES,
      }),
    },
  ];

  const resultsTab: NvTab = {
    text: t.COURSES.TABS.TAB_RESULTS(),
    onClick: () => { },
  };

  const onSearch = () => {
    setSearchValue(searchRef?.current?.value);
  };

  const onClear = () => {
    searchRef.current.value = '';
    setSearchValue('');
  };

  const onSearchExpanded = (isExpanded) => {
    if (isExpanded) {
      setIsSearchOpen(true);
    } else {
      setIsSearchOpen(false);
      onClear();
    }
  };

  const onSearchClear = () => {
    onClear();
    setIsSearchOpen(false);
  };

  const clearBtn = (
    <div
      className='clear-btn page-title-xxs text-primary'
      onClick={onClear}
    >
      {t.SEARCH.CLEAR()}
    </div>
  );

  const searchBar = (
    <NvPopover
      placement='bottom-start'
      enabled={isSearchOpen}
      showOnHover
      content={<span>{t.USER_MANAGEMENT.SEARCH_TOOLTIP()}</span>}
      preventOverflow
      overlayStyles={popoverStyles}
      className='w-100'
      offset={30}
    >
      <NvExpandableSearchBar
        onSearch={onSearch}
        placeholder={t.INSTITUTIONS.LICENSES.SEARCH_FOR_USER()}
        isExpanded={isSearchOpen}
        onExpanded={onSearchExpanded}
        ref={searchRef}
        showPlaceholderAlways
      />
    </NvPopover>
  );

  const tabStyles = {
    ...createGridStyles(1, 2),
    borderBottom: `solid 1px ${gray6}`,
  };

  return (
    <div css={styles(isSearchOpen)}>
      <NvResponsiveTabsRow
        style={tabStyles}
        defaultTabs={tabs}
        filteredTabs={[resultsTab]}
        isFiltered={!isEmpty(searchValue)}
        tabType={NvResponsiveTabsDisplayType.TEXT_ONLY}
        clearBtn={clearBtn}
        showClearBtn={!isEmpty(searchValue)}
        searchBar={searchBar}
        tabTextClass='card-title'
        revertActiveTab={tab && Object.values(Tab).indexOf(tab)}
        preventActiveTabOnClick
      />
      <div className='tab-content pb-5' css={tabContentStyles}>
        {/* Using the OrgAllUsers component for both search and all users list. */}
        {(tab === Tab.ALL_USERS || !isEmpty(searchValue))
          && <OrgAllUsers searchQuery={searchValue} onSearchClear={onSearchClear} />}
        {tab === Tab.ORG_ADMINS && <OrgAdmins />}
        {tab === Tab.MENTORS && <OrgMentorAdminPanel />}
        {tab === Tab.COURSE_ROLES && <CourseRoles />}
      </div>
    </div>
  );
};

export default OrgLevelUserManagement;
