import { css } from '@emotion/react';
import t from 'react-translate';
import moment from 'moment';
import React from 'react';
import { CompletionStatusEnum, UserEnrollment } from 'redux/schemas/models/org-users';
import NvProgressGauge, { ProgressGaugeSize, ProgressGaugeType } from 'shared/components/nv-progress-gauge';
import NvProgressBar, { NvProgressBarProps, BAR_HEIGHT } from 'shared/components/nv-progress-bar';
import { warning, primary, success, gray7, gray4, getColorBetweenColorsByPercentage } from 'styles/global_defaults/colors';
import NvPopover from 'shared/components/nv-popover';
import { useSelector } from 'react-redux';
import { getCourseAliases } from 'redux/selectors/course';

type NvCourseProgressBarProps = {
  courseClosed: boolean
  displayStatus?: boolean
} & NvProgressBarProps;

const NvCourseProgressBar = ({
  color,
  maxValue,
  currentValue,
  courseClosed,
  displayStatus,
}: NvCourseProgressBarProps) => {
  const isCompleted = maxValue === currentValue;
  const inProgress = (!isCompleted && !courseClosed);
  const [barWidth, setBarWidth] = React.useState(0);

  let backgroundColor;
  let textColor;
  let progressLabel;

  if (isCompleted) {
    backgroundColor = success;
    textColor = 'success';
    progressLabel = t.INSTITUTIONS.LICENSES.COMPLETED();
  } else if (inProgress) {
    const startColor = getColorBetweenColorsByPercentage('#fff', color, 0.4);
    backgroundColor = `linear-gradient(90deg, ${startColor} 0%, ${color} 100%)`;
    textColor = 'primary';
    progressLabel = t.INSTITUTIONS.LICENSES.IN_PROGRESS();
  } else {
    backgroundColor = gray4;
    textColor = 'gray-3';
    progressLabel = t.INSTITUTIONS.LICENSES.INCOMPLETE();
  }

  const styles = css`
    width: 100%;

    .arrow {
      width: 0;
      height: 0;
      margin-bottom: 2px;
      margin-left: calc(${barWidth}% - ${BAR_HEIGHT}px);
      border-left: ${BAR_HEIGHT}px solid transparent;
      ${!inProgress ? 'visibility: hidden' : ''};
      border-right: ${BAR_HEIGHT}px solid transparent;
      border-top: ${BAR_HEIGHT}px solid ${color};
    }
`;
  return (
    <div css={styles}>
      <div className='arrow' />
      <NvProgressBar
        maxValue={maxValue}
        color={backgroundColor}
        currentValue={currentValue}
        onBarWidthChange={(width) => setBarWidth(width)}
      />
      {displayStatus && (
        <div className={`mt-1 label text-${textColor}`}>
          {progressLabel}
        </div>
      )}
    </div>
  );
};

const CourseProgress = ({ enrollment }: { enrollment: UserEnrollment }) => {
  const aliases = useSelector((state) => getCourseAliases(state, enrollment?.course?.catalogId));
  const progress = displayBarOrGauge(enrollment, aliases);
  const disabledCompletionStatuses = [CompletionStatusEnum.INCOMPLETE, CompletionStatusEnum.REMOVED];

  const popoverContent = (item) => (
    <span className='text-black bold'>
      {
        enrollment.course.isJourney
          ? item.label
          : `${item.label} ${t.MENTEES.EARNED_OUT_OF_TOTAL(item.currentValue, item.maxValue)}`
      }
    </span>
  );
  return (
    <div className='d-flex flex-column justify-content-center w-100 py-2'>
      <div className='d-flex align-item-center w-100'>
        {progress && progress.map((item) => (
          <React.Fragment key={item.label}>
            { item.type === 'bar' ? (
              <div className='w-100 pr-4'>
                <NvPopover
                  showOnHover
                  placement='top'
                  content={popoverContent(item)}
                >
                  <div className='mb-1 mt-2'>
                    <NvCourseProgressBar
                      maxValue={item.maxValue}
                      currentValue={item.currentValue}
                      courseClosed={enrollment.completionProgress === CompletionStatusEnum.INCOMPLETE}
                      color={disabledCompletionStatuses.includes(enrollment.completionProgress)
                        ? gray4
                        : item.activeColor}
                    />
                  </div>
                </NvPopover>
              </div>
            ) : (
              <NvPopover
                showOnHover
                placement='top'
                content={popoverContent(item)}
              >
                <div className='pr-1 pb-1'>
                  <NvProgressGauge
                    maxValue={item.maxValue}
                    currentValue={item.currentValue}
                    activeColor={disabledCompletionStatuses.includes(enrollment.completionProgress)
                      ? gray4
                      : item.activeColor}
                    size={ProgressGaugeSize.SMALL}
                    displayType={ProgressGaugeType.ACTUAL_ONLY}
                    bgColor={gray7}
                  />
                </div>
              </NvPopover>
            )}
          </React.Fragment>
        ))}
      </div>
      <div className={`text-small font-weight-bold completion-status ${enrollment.course.isJourney ? 'learning-journey' : ''}`}>
        {getCompletionProgressText(enrollment)}
      </div>
      <div className={`text-small font-weight-bold text-primary view-points-break-down ${enrollment.course.isJourney ? 'learning-journey' : ''}`}>
        {t.INSTITUTIONS.ROLES.VIEW_DETAILS()}
      </div>
    </div>
  );
};

const displayBarOrGauge = (enrollment: UserEnrollment, aliases) => {
  /* Show the progress bar in these conditions:
    * 1. Any points have been awarded
    * 2. Some # of assignments are required and some have been
    * completed
    * 3. Some # of todos are required and some have been completed
    * 4. Automatic completions are disabled, no # of assignemnts
    * or todos are required, and some to-dos have been completed
    * Hide the progress bar in all other cases.
  */

  const { course, progress } = enrollment;
  const progressBars = [];
  if (course.isJourney) {
    if (progress) {
      progressBars.push({
        currentValue: progress.completedCourses,
        maxValue: progress.totalCourses,
        activeColor: progress.completedCourses === progress.totalCourses ? success : primary,
        type: 'bar',
        label: t.MENTEES.EARNED_OUT_OF_TOTAL_COMPLETED(`${progress.completedCourses}`, `${progress.totalCourses}`),
      });
    }
    return progressBars;
  }
  if (course.automaticCompletionCriteria) {
    const numberOfCriteria = (course.automaticCompletionCriteria.requiredAssignmentsCount > 0 ? 1 : 0)
      + (course.automaticCompletionCriteria.totalRequiredTodosCount > 0 ? 1 : 0)
      + (course.automaticCompletionCriteria.requiredPoints > 0 ? 1 : 0);

    if (course.automaticCompletionCriteria.requiredPoints > 0) {
      progressBars.push({
        currentValue: progress.pointsReceived,
        maxValue: course.automaticCompletionCriteria.requiredPoints,
        activeColor: success,
        type: numberOfCriteria > 1 ? 'gauge' : 'bar',
        label: t.MENTEES.EARNED_POINTS({ ...aliases.pointsAliases }),
      });
    }

    if (course.automaticCompletionCriteria.requiredAssignmentsCount > 0) {
      progressBars.push({
        currentValue: progress.numRequiredAssignmentsCompleted,
        maxValue: course.automaticCompletionCriteria.requiredAssignmentsCount,
        activeColor: warning,
        type: numberOfCriteria > 1 ? 'gauge' : 'bar',
        label: t.MENTEES.COMPLETED_REQUIRED_ASSIGNMENTS({ ...aliases.assignmentAliases }),
      });
    }
    if (course.automaticCompletionCriteria.totalRequiredTodosCount > 0) {
      progressBars.push({
        currentValue: progress.numRequiredTodosCompleted,
        maxValue: course.automaticCompletionCriteria.totalRequiredTodosCount,
        activeColor: primary,
        type: numberOfCriteria > 1 ? 'gauge' : 'bar',
        label: t.MENTEES.COMPLETED_REQUIRED_TODOS(),
      });
    }
  }

  if (progressBars.length === 0 && progress.totalPoints && progress.pointsReceived) {
    progressBars.push({
      currentValue: progress.pointsReceived,
      maxValue: progress.totalPoints,
      activeColor: success,
      type: 'bar',
      label: t.MENTEES.EARNED_POINTS({ ...aliases.pointsAliases }),
    });
  }
  return progressBars;
};

const getCompletionProgressText = (enrollment: UserEnrollment) => {
  switch (enrollment.completionProgress) {
    case CompletionStatusEnum.ENROLLED:
      return <span className='gray-2'>{t.CUSTOM_DOWNLOADS.DROPDOWN.ENROLLED()}</span>;
    case CompletionStatusEnum.INCOMPLETE:
      return <span className='gray-3'>{t.INSTITUTIONS.LICENSES.INCOMPLETE()}</span>;
    case CompletionStatusEnum.WITHDRAWN:
      return <span className=''>{t.INSTITUTIONS.LICENSES.WITHDRAWN(moment(enrollment.enrollmentStatusUpdatedAt).format('L'))}</span>;
    case CompletionStatusEnum.REMOVED:
      return <span>{t.INSTITUTIONS.LICENSES.REMOVED(moment(enrollment.enrollmentStatusUpdatedAt).format('L'))}</span>;
    case CompletionStatusEnum.IN_PROGRESS:
      return <span className='text-primary'>{t.INSTITUTIONS.LICENSES.IN_PROGRESS()}</span>;
    case CompletionStatusEnum.COMPLETED:
      return <span className='text-success'>{t.INSTITUTIONS.LICENSES.COMPLETED()}</span>;
    default:
      return '';
  }
};
export default CourseProgress;
