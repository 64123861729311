/* @ngInject */
export default function DirectoryManagerInterface(
  $timeout,
  $q,
  _,
  nvCurrentPage,
  $uibModal,
  CurrentUserManager,
  CurrentCourseManager,
) {
  /* eslint-disable max-statements */
  function DirectoryManager() {
    const _this = this;

    // data
    const ORG_PROFILE_FILTER_TYPE = 'org-profile';
    const COURSE_PROFILE_FILTER_TYPE = 'course-profile';

    this.constants = {
      ORG_PROFILE_FILTER_TYPE,
      COURSE_PROFILE_FILTER_TYPE,
    };
    this.initialized = false;
    this.allFilters = [];
    this.searchMode = false;
    this.selectedFilters = {};
    this.pageNumber = 1;
    this.queryTerm = null; // what is passed to search
    this.queryTermDisplay = null; // mirrors what the user enters in the search input
    this.loading = false;
    this.hasLocationData = false;
    this.locationFilter = ''; // what is passed to search
    this.locationFilterDisplay = '';
    this.dropdownFilter = null;
    this.filterMenuOpen = false;
    this.hasExecutedSearchRequest = false;
    this.resultsCount = 0;
    this.hasMoreToLoad = true;
    this.delayImmediateSearch = false;
    this.selectedProfileFilters = {
      [ORG_PROFILE_FILTER_TYPE]: {},
      [COURSE_PROFILE_FILTER_TYPE]: {},
    };
    this.loadingSearchResults = false;
    this.hideDropdown = false;


    // functions
    this.initialize = initialize;
    this.fetchProfileQuestionFilters = fetchProfileQuestionFilters;
    this.toggleSearchMode = toggleSearchMode;
    this.undoSearchDisplayTerm = undoSearchDisplayTerm;
    this.toggleFilterSelection = toggleFilterSelection;
    this.countProfileQuestionFilters = countProfileQuestionFilters;
    this.countProfileQuestionsSearch = countProfileQuestionsSearch;
    this.clearProfileQuestionFilters = clearProfileQuestionFilters;
    this.getSelectedFilters = getSelectedFilters;
    this.resetAllFilters = resetAllFilters;
    this.doSearch = doSearch;
    this.loadAdditionalResults = loadAdditionalResults;
    this.filtersAreDefault = filtersAreDefault;
    this.filtersAreDefaultForSearch = filtersAreDefaultForSearch;
    this.isSearchRequest = isSearchRequest;
    this.displayNameForDropdownFilter = displayNameForDropdownFilter;
    this.searchUrlForDropdownFilter = searchUrlForDropdownFilter;
    this.displayNameForSearchables = displayNameForSearchables;
    this.getPlaceholderKey = getPlaceholderKey;
    this.getPlaceholderValues = getPlaceholderValues;
    this.displayNameForNearbyFilterKeys = displayNameForNearbyFilterKeys;
    this.displayNameForNearbyFilterValues = displayNameForNearbyFilterValues;
    this.emptyResultSet = emptyResultSet;
    this.hasResults = hasResults;
    this.selectLocationFilter = selectLocationFilter;
    this.formatLocationFilterForSearch = formatLocationFilterForSearch;
    this.resetData = resetData;
    this.resetSharedData = resetSharedData;
    this.setLocationData = setLocationData;
    this.emptyDirectory = emptyDirectory;
    this.filtersAvailable = filtersAvailable;
    this.filterEnabled = filterEnabled;
    this.noResultsIcon = noResultsIcon;


    function initialize(attributes) {
      _.extend(this, attributes);
      this.initialized = true;
    }

    function fetchProfileQuestionFilters() {
      return $.noop(); // implementation is left to subclasses
    }

    function setLocationData() {
      if (
        (
          CurrentUserManager.user.city
          || CurrentUserManager.user.region
          || CurrentUserManager.user.country
        )
        && !CurrentCourseManager.course.institution.profileSettings.accountLevel.location.isHidden
      ) {
        this.hasLocationData = true;
      }
    }

    function toggleSearchMode(valueToSet, resetSearchAllFilters, delayImmediateSearch) {
      this.searchMode = valueToSet;

      $('.directory-page-header')
        .toggleClass('has-filters', !!this.allFilters.length);

      this.delayImmediateSearch = delayImmediateSearch;

      if (this.searchMode) {
        this.filterMenuOpen = true;

        // this property is related to delayImmediateSearch on handheld
        if (delayImmediateSearch) {
          this.profileFiltersExpanded = true;
        }
      } else {
        this.profileFiltersExpanded = false;
      }

      if (!this.searchMode && resetSearchAllFilters !== false) {
        this.resetAllFilters();
      }
    }

    function undoSearchDisplayTerm() {
      this.queryTermDisplay = this.queryTerm;
    }

    function toggleFilterSelection(profileQuestion, answer) {
      const { id, type } = profileQuestion;

      if (this.selectedProfileFilters[type][id]) {
        this.selectedProfileFilters[type][id].selected = (this.selectedProfileFilters[type][id].selected === answer ? null : answer);
      } else {
        this.selectedProfileFilters[type][id] = {
          selected: answer,
        };
      }

      if (!this.delayImmediateSearch) {
        this.selectedProfileFilters[type][id].searched = answer;
        this.doSearch();
      }
    }

    function countProfileQuestionFilters() {
      const filterCount = Object.keys(this.selectedProfileFilters).reduce((acc, type) => {
        const { selected } = _.countBy(
          this.selectedProfileFilters[type],
          (question) => ((!question.selected) ? 'not selected' : 'selected'),
        );

        return acc + (selected || 0);
      }, 0);

      return filterCount + (this.locationFilterDisplay.length ? 1 : 0);
    }

    function countProfileQuestionsSearch() {
      const filterCount = Object.keys(this.selectedProfileFilters).reduce((acc, type) => {
        const { searched } = _.countBy(
          this.selectedProfileFilters[type],
          (question) => ((!question.searched) ? 'not searched' : 'searched'),
        );

        return acc + (searched || 0);
      }, 0);

      return filterCount + (this.locationFilter.length ? 1 : 0);
    }

    function clearProfileQuestionFilters(clearSearchFilters) {
      _.each(this.selectedProfileFilters, (domain) => {
        _.each(domain, (question) => {
          question.selected = null;
          if (clearSearchFilters) {
            question.searched = false;
          }
        });
      });

      this.locationFilterDisplay = '';
      if (clearSearchFilters) {
        this.locationFilter = '';
      }
    }

    function getSelectedFilters() {
      const getDomainFilters = (domain) => {
        const toReturn = {};

        _.each(this.selectedProfileFilters[domain], (question, key) => {
          if (question.selected) {
            toReturn[key] = question.selected;
          }
        });

        return toReturn;
      };

      return {
        [ORG_PROFILE_FILTER_TYPE]: getDomainFilters(ORG_PROFILE_FILTER_TYPE),
        [COURSE_PROFILE_FILTER_TYPE]: getDomainFilters(COURSE_PROFILE_FILTER_TYPE),
      };
    }

    function resetAllFilters() {
      this.clearProfileQuestionFilters(true);
      this.dropdownFilter = null;
      this.queryTerm = null;
      this.queryTermDisplay = null;
      this.hasMoreToLoad = true;
    }

    function doSearch(shouldAppendResults, shouldCancelPrevRequests) {
      return $.noop(); // implementation is left to subclasses
    }

    function loadAdditionalResults() {
      if (!this.loadingSearchResults) {
        this.pageNumber += 1;
        this.doSearch(true, false);
      }
    }

    function filtersAreDefault() {
      return this.countProfileQuestionFilters() === 0 && !this.queryTerm;
    }

    function filtersAreDefaultForSearch() {
      return this.countProfileQuestionsSearch() === 0 && !this.queryTerm && !this.locationFilter.length;
    }

    function isSearchRequest() {
      return !this.filtersAreDefaultForSearch() || this.queryTerm;
    }

    function displayNameForDropdownFilter(internalName) {
      // implementation is left to subclass
      return $.noop();
    }

    function searchUrlForDropdownFilter(filterName) {
      // implementation in subclass
      return $.noop();
    }

    function displayNameForSearchables() {
      // implementation in subclass
      return $.noop();
    }

    function getPlaceholderKey(filterName) {
      // implementation in subclass
      return $.noop();
    }

    function getPlaceholderValues(filterName) {
      // implementation in subclass
      return $.noop();
    }

    function displayNameForNearbyFilterKeys(filterName) {
      // implementation in subclass
      return $.noop();
    }

    function displayNameForNearbyFilterValues(filterName) {
      // implementation in subclass
      return $.noop();
    }

    function emptyResultSet() {
      // implementation in subclass
      return $.noop();
    }

    function hasResults() {
      // implementation in subclass
      return $.noop();
    }

    function selectLocationFilter(locationPart) {
      if (this.locationFilterDisplay === locationPart) {
        this.locationFilterDisplay = '';
      } else {
        this.locationFilterDisplay = locationPart;
      }

      if (!this.delayImmediateSearch) {
        this.locationFilter = this.formatLocationFilterForSearch();
        this.doSearch();
      }
    }

    function formatLocationFilterForSearch() {
      const locationParts = [];
      if (this.locationFilterDisplay === '') {
        return '';
      }
      if (this.locationFilterDisplay === 'country') {
        locationParts.unshift(CurrentUserManager.user.country);
      }
      if (this.locationFilterDisplay === 'region') {
        if (CurrentUserManager.user.country.length) {
          locationParts.unshift(CurrentUserManager.user.country);
        }
        locationParts.unshift(CurrentUserManager.user.region);
      }
      if (this.locationFilterDisplay === 'city') {
        if (CurrentUserManager.user.country.length) {
          locationParts.unshift(CurrentUserManager.user.country);
        }
        if (CurrentUserManager.user.region.length) {
          locationParts.unshift(CurrentUserManager.user.region);
        }
        locationParts.unshift(CurrentUserManager.user.city);
      }

      return locationParts.join(', ');
    }

    function resetData() {
      // subclass to implement
      return $.noop();
    }

    function resetSharedData() {
      this.allFilters = [];
      this.resetAllFilters();
      this.resultsCount = 0;
      this.initialized = false;
      this.locationFilter = '';
      this.locationFilterDisplay = '';
      this.context = null;

      this.searchMode = false;
    }

    function emptyDirectory() {
      return this.initialized && !this.loadingSearchResults && (this.filtersAreDefaultForSearch() && !this.dropdownFilter) && this.emptyResultSet();
    }

    function filtersAvailable() {
      return !this.loadingSearchResults && (!this.filtersAreDefaultForSearch() || this.dropdownFilter || this.resultsCount > 0);
    }

    function filterEnabled(filter) {
      // default:
      return true;
    }

    function noResultsIcon() {
      return $.noop();
    }
  }

  return {
    create() {
      return new DirectoryManager();
    },
  };
}
