/* @ngInject */
export default function NvProfilePicture(
  CurrentCourseManager,
  InteroperabilityRoutes,
  $state,
  $timeout,
  nvUtil,
  CurrentUserManager,
) {
  return {
    restrict: 'EA',
    scope: {
      size: '@?',
      team: '=?', // NOTE: this directive determines which type of profile to show by checking against undefined. null and objects evaluate to true
      user: '=?',
      directToProfile: '=?',
      notificationContent: '=?', // small blip or unread count in corner, for example
      updateBindingsImmediately: '=', // whether you need the stuff inside this directive to update on-the-fly. the default is false since it's more performant, use carefully and only as needed
      course: '=?', // set when being used in a place where profile links should be interoperable
      borderColor: '=', // currently only used for walled garden institution in LHS
      pendoTag: '@?',
      tooltipEnable: '=?',
      tooltipAppendToBody: '=?',
      tooltipPlacement: '=?',
    },
    transclude: true,
    link(scope, elem, attr) {
      let transcludedElement = null;

      // todo: i18n
      scope.getInitials = function (userObj) {
        if (!userObj) {
          return '';
        }
        return userObj.initials ? userObj.initials
          : ((userObj.firstName ? userObj.firstName[0].toUpperCase() : '') + (userObj.lastName ? userObj.lastName[0].toUpperCase() : ''));
      };

      scope.getFontSize = function () {
        if (scope.size) {
          const sizeInfo = scope.parseSizeAndUnits(scope.size);
          const calculatedFontSize = (0.5 * sizeInfo.size).toString() + sizeInfo.units;

          return { 'font-size': calculatedFontSize };
        }
        return { 'font-size': '50%' };
      };

      scope.parseSizeAndUnits = function (sizeStr) {
        const sizeInt = parseInt(sizeStr, 10);
        const splitSizeInforray = sizeStr.split(sizeInt.toString());
        const sizeUnits = splitSizeInforray.length > 1 && splitSizeInforray[1].length ? splitSizeInforray[1] : 'px';

        return {
          size: sizeInt,
          units: sizeUnits,
        };
      };

      scope.getPositionOffset = function () {
        const sizeInfo = scope.parseSizeAndUnits(scope.size);
        transcludedElement = scope.getTranscludedElement();

        const transcludedWidth = transcludedElement.width();
        const transcludedHeight = transcludedElement.height();

        if (transcludedWidth > 0 || transcludedHeight > 0) {
          if (scope.isRound) {
            const sizeRadius = (sizeInfo.size / 2);
            const y = sizeRadius * Math.sin(45);
            const x = Math.sqrt((sizeRadius ** 2) - (y ** 2));
            const top = (Math.floor((sizeRadius - y) - transcludedHeight / 2)).toString();
            const side = (Math.floor((sizeRadius - x) - transcludedWidth / 2)).toString();
            const width = Math.floor(sizeInfo.size - side);
            if (nvUtil.isRtl()) {
              return {
                top: `${top}px`,
                left: `${side}px`,
                width: `${width}px`,
              };
            }
            return {
              top: `${top}px`,
              right: `${side}px`,
              width: `${width}px`,
            };
          }
          const offset = `-${(transcludedWidth / 2).toString()}${sizeInfo.units}`;

          if (nvUtil.isRtl()) {
            return {
              top: offset,
              left: offset,
            };
          }
          return {
            top: offset,
            right: offset,
          };
        }

        return null;
      };

      scope.getTranscludedElement = function () {
        return elem.find('[ng-transclude=""]');
      };


      if (!scope.size) {
        scope.size = '100%'; // this assumes the parent will be square, otherwise will be stretched/squished on 1 axis
      }


      scope.$watchGroup([
        'user.firstName',
        'user.lastName',
        'user.profilePictureUrl',
        'user.profilePicture',
        'team.name',
        'team.profilePictureUrl',
        'team.profilePicture',
      // eslint-disable-next-line no-shadow
      ], (newValues, oldValues, scope) => {
        const profileSubject = scope.team || scope.user; // the subject of the profile picture

        // Getting current course for setting profile link URL
        // Reason: We can open portable team workspace / team profile on top of a different course or without a course and
        // profile link needed to be based on the currently selected course
        let applicableCourse = null;
        if (scope.course) {
          // Passed course have higher priority and it will be used if available
          applicableCourse = scope.course;
        } else if ($state.params?.catalogId
          && $state.params.catalogId !== CurrentCourseManager?.course?.catalogId
          && CurrentUserManager.coursesHashByCatalogId[$state.params.catalogId]) {
          // If currentCourseManager course is not set or isn't the same as the state catalogId
          // fetch course from the state and use that in profile profile url
          applicableCourse = CurrentUserManager.coursesHashByCatalogId[$state.params.catalogId];
        } else {
          // If state doesn't include a catalogId or it match with currentCourseManager
          applicableCourse = CurrentCourseManager.course;
        }


        scope.profilePictureUrl = null;

        if (profileSubject) {
          if (profileSubject.profilePictureUrl) {
            scope.profilePictureUrl = profileSubject.profilePictureUrl;
          } else if (profileSubject.profilePicture) {
            scope.profilePictureUrl = profileSubject.profilePicture;
          }
        }

        scope.isRound = scope.user !== undefined;

        if (scope.directToProfile) {
          if (scope.user) {
            scope.profileLink = $state.href('learner-profile-modal', { catalogId: applicableCourse ? applicableCourse.catalogId : null, userId: profileSubject.id });
          } else {
            scope.profileLink = (applicableCourse ? InteroperabilityRoutes.teamProfilePath(applicableCourse, scope.team?.id) : null);
          }
        }

        if (!scope.profilePictureUrl || !scope.profilePictureUrl.length) {
          scope.profilePictureUrl = undefined; // we have to set it to undefined vs null so that the one time bindings will not stabilize
        }

        scope.initials = scope.getInitials(profileSubject);
      });

      scope.$watch(() => {
        const element = scope.getTranscludedElement();
        return !!element && (element.width() && element.height());
      }, (newValue, oldValue) => {
        if (newValue) {
          // - the profile picture might not be rendered yet, so wait a cycle
          $timeout(() => {
            scope.transcludedContentOffset = scope.getPositionOffset();
          });
        }
      });

      if (scope.isRound === null || scope.isRound === undefined) {
        scope.isRound = true; // default
      }
    },
    templateUrl: 'shared/templates/nv-profile-picture.html',
  };
}
