import { schema } from 'normalizr';
import { omit } from 'underscore';
import { MentorUser, OrgMentee, FilterKeys, InstitutionAdminUser, OrgMentor } from 'redux/schemas/models/org-mentors';
import { OrgAdminRole } from 'institutions/services/roles-service';
import { UserEnrollment, UserEnrollmentCourse } from '../models/org-users';

export interface AddOrgMentorProps {
  user: {
    id: number,
    admin: boolean,
    firstName?: string,
    lastName?: string,
    profilePicture?: string,
    email: string,
  },
  institutionId: number,
  adminRole: OrgAdminRole,
}

export interface OrgMentorEntitties {
  orgMentors: OrgMentorsNormalized
}

export interface OrgMentorsResponse {
  count: number,
  orgAdmins: OrgMentor[];
}

export interface AddInstitutionMenteeProps {
  user_id: number,
  overwrite: boolean,
  mentees: {
    id?: number,
    firstName?: string,
    lastName?: string,
    email: string
  }[],
}

export interface GetMenteesForMentorParams {
  filter: FilterKeys,
  institutionId: number,
  mentorUserId: number,
  pageNumber: number,
  pageSize?: number,
}

interface MenteeAddSuccessResponseItem {
  id: number,
  user: InstitutionAdminUser,
  mentee: MentorUser,
  institutionMentor: {
    id: number,
    institutionId: number,
    institutionName: string,
  }
}

export interface MenteeAddResponse {
  errors: any[],
  created: MenteeAddSuccessResponseItem[],
  existing: MenteeAddSuccessResponseItem[],
  data?: {},
}

export interface AddMultipleMenteesToMentorParams {
  mentorings: {
    firstName: string,
    lastName: string,
    email: string,
    mentees: {
      firstName: string,
      lastName: string,
      email: string,
    }[],
  }[],
  overwrite: boolean
}

export interface OrgMentorsNormalized {
  [id: string]: OrgMentor
}

export const orgMentorSchema = new schema.Entity('orgMentors');
export const orgMenteeSchema = new schema.Entity('orgMentees');

export const userEnrollmentSchema = new schema.Entity<UserEnrollment>('userEnrollments');
export const userEnrollmentsSchema = [userEnrollmentSchema];

export const orgMentorListSchema = new schema.Array(orgMentorSchema);

export const userEnrollmentCourseSchema = new schema.Entity<UserEnrollmentCourse>('courses', {}, {
  idAttribute: 'catalogId',
});

userEnrollmentSchema._processStrategy = (value) => omit({
  ...value,
  ...(value.course ? { courseId: value.course } : {}),
}, ['course']);

userEnrollmentSchema.define({
  courseId: userEnrollmentCourseSchema,
});
