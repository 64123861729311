/* expected attributes:

onSearchToggle: handler for when the search input is toggled between the icon and the full input box.
the handler will be passed a bool indicating whether the search box is visible or not.

onSearchInputClose: handler for when the 'x' is clicked and the search box is hidden.

searchInputAttributes: an object of properties to apply to the text box


*/

/* @ngInject */
export default function NvSearchInput(
  $parse,
  ScrollFocusConnectorFactory,
  $rootScope,
) {
  return {
    restrict: 'A',
    scope: true,
    link(scope, elem, attributes) {
      const _elem = elem;

      scope.searchDisabled = attributes.searchDisabled === 'true';
      scope.searchMode = attributes.showSearch === 'true';
      scope.toggleConditions = scope.$eval(attributes.toggleConditions);

      scope.onSearch = $parse(attributes.onSearch);
      const onSearchToggle = $parse(attributes.onSearchToggle);
      const onSearchClose = $parse(attributes.onSearchInputClose);

      const triggerOnToggleHandler = function () {
        onSearchToggle(scope, { searchMode: scope.searchMode });
      };

      const toggleDomSearchClasses = function (newValue, oldValue) {
        if (newValue !== oldValue) {
          scope.searchMode = scope.$eval(newValue);
          scope.toggleConditions = scope.$eval(attributes.toggleConditions);

          $('.directory-page-main').toggleClass('search-mode',
            scope.searchMode
              && (scope.toggleConditions && scope.toggleConditions.header !== undefined ? !!scope.toggleConditions.header : true));

          $('.directory-page-header')
            .toggleClass('search-mode', scope.searchMode);
        }
      };

      scope.$watch(() => attributes.showSearch,
        toggleDomSearchClasses);

      $rootScope.$on('$stateChangeSuccess', () => {
        toggleDomSearchClasses('false', undefined);
      });

      $rootScope.$on('$translateChangeSuccess', () => {
        scope.searchInputAttributes = scope.$eval(attributes.searchInputAttributes);
      });

      scope.$watch(() => attributes.searchDisabled,
        (newValue, oldValue) => {
          const newValAsBool = scope.$eval(newValue);

          if (newValue !== oldValue) {
            scope.searchDisabled = newValAsBool;
          }
        });

      scope.$watch(() => attributes.searchInputAttributes, () => {
        scope.searchInputAttributes = scope.$eval(attributes.searchInputAttributes);
      });

      scope.toggleSearchMode = function () {
        scope.searchMode = !scope.searchMode;


        if (scope.searchMode) {
          ScrollFocusConnectorFactory.focus(null, null, { elem: elem.find('.search-input') });
        }
        triggerOnToggleHandler();
      };

      scope.handleSearchInputClose = function () {
        scope.searchMode = !scope.searchMode;

        $('.directory-page-main').removeClass('search-mode');
        $('.directory-page-header').removeClass('search-mode');

        onSearchClose(scope);
      };

      scope.clickSearchHandler = function () {
        if (scope.searchDisabled) {
          scope.toggleSearchMode();
        } else {
          scope.onSearch(scope);
        }
      };
    },
    templateUrl: 'shared/templates/nv-search-input.html',
  };
}
