import { PermissionTypes } from 'institutions/services/roles-service';

/* @ngInject */
export default function ManageCourseRoles(
  _,
  $q,
  $translate,
  RolesService,
  CourseRolesManager,
  InstitutionsManager,
  AlertMessages,
  ConfirmationOverlays,
) {
  class ManageCourseRolesCtrl {
    constructor() {
      /** @type {CourseRoleModel[]} */
      this.courseRoles = [];
      this.InstitutionsManager = InstitutionsManager;
      this.RolesService = RolesService;
      this.CourseRolesManager = CourseRolesManager;
      this.courseRoles = CourseRolesManager.roles;
      this.menteeInputVisible = false;

      // Create this as a fat-arrow function so we retain the value of 'this' when used as a callback
      this.onSelectedPermissionsChanged = (permissions) => {
        this.permissionsDraft = permissions;
        this.setMenteesNameVisible();
      };

      this.roleNameNotUnique = () => _.values(this.courseRoles).map((r) => r.name).some((r) => r === this.courseRoleNameDraft);

      this.initCourseRoleDrafts();
    }

    /** Displays the mentees name input with correct placeholder text. */
    setMenteesNameVisible() {
      this.menteeInputVisible = _.some(this.permissionsDraft, (p) => p.type === PermissionTypes.MENTOR);

      if (this.menteeInputVisible) {
        this.roleNameLabel = $translate.instant('INSTITUTIONS.ROLES.MENTOR_PLACEHOLDER');
      } else {
        this.menteesNameDraft = null;
        this.roleNameLabel = $translate.instant('INSTITUTIONS.ROLES.COURSE_ROLE_NAME');
      }
    }

    initCourseRoleDrafts() {
      this.editingId = -1;
      this.isAddingNewRole = false;
      this.isEditingRole = false;
      this.courseRoleNameDraft = '';
      this.badgeDraft = '';
      this.menteesNameDraft = null;
      this.permissionsDraft = [];
      this.menteeInputVisible = false;
      this.roleNameLabel = $translate.instant('INSTITUTIONS.ROLES.COURSE_ROLE_NAME');
    }

    setCourseRoleEdit(role) {
      this.permissionsDraft = role.permissions;
      this.isEditingRole = true;
      this.isAddingNewRole = false;
      this.editingId = role.id;
      this.courseRoleNameDraft = role.name;
      this.badgeDraft = role.badge;
      this.menteeInputVisible = false;
      this.menteesNameDraft = role.menteeName;
      this.setMenteesNameVisible();
    }

    setCourseRoleNew() {
      this.isEditingRole = false;
      this.isAddingNewRole = true;
      this.editingId = -1;
      this.courseRoleNameDraft = '';
      this.badgeDraft = '';
      this.menteesNameDraft = null;
      this.menteeInputVisible = false;
    }

    saveRolePermissions() {
      this.isSaving = true;

      let courseRole;
      if (this.isEditingRole) {
        courseRole = this.courseRoles[this.editingId];

        const mentorPermission = _.find(this.permissionsDraft, (p) => p.type === PermissionTypes.MENTOR);
        const mentorRemoved = !mentorPermission && RolesService.isMentor(this.courseRoles[this.editingId]);

        this.CourseRolesManager.getCourseRole(this.InstitutionsManager.institution.id, this.editingId).then((response) => {
          if (response.result.isUsed) {
            if (mentorRemoved) {
              const modalInstanceCtrl = ['$scope', function ($scope) {
                $scope.courseRole = courseRole;
                $scope.description = $translate.instant('INSTITUTIONS.ROLES.REMOVE_MENTOR_PERMISSION');
              }];
              return ConfirmationOverlays.openConfirmationModal('institutions/templates/delete-role-confirm.html', modalInstanceCtrl)
                .result.then(() => {
                  this.menteesNameDraft = null;
                });
            }
          }
          return null;
        }).then((result) => courseRole.save(
          this.InstitutionsManager.institution.id,
          this.courseRoleNameDraft,
          this.badgeDraft,
          this.permissionsDraft,
          this.menteesNameDraft,
        )).then((result) => {
          this.courseRoles[this.editingId].permission = CourseRolesManager.roles[this.editingId].permission;

          if (mentorRemoved) {
            this.courseRoles[this.editingId].menteeName = '';
          }

          this.isSaving = false;
          this.initCourseRoleDrafts();
        })
          .catch((reason) => {
            this.isSaving = false;

            if (!reason) {
              return;
            }

            AlertMessages.error('', 'FORM.ERROR');
          });
      } else if (this.isAddingNewRole) {
        this.CourseRolesManager.addCourseRole(
          this.InstitutionsManager.institution.id,
          this.courseRoleNameDraft,
          this.badgeDraft,
          this.permissionsDraft,
          this.menteesNameDraft,
        ).then((courseRoleResponse) => {
          this.isSaving = false;
          this.initCourseRoleDrafts();
          this.courseRoles[courseRoleResponse.id] = courseRoleResponse;
        }).catch((reason) => {
          this.isSaving = false;
          AlertMessages.error('', 'FORM.ERROR');
        });
      }
    }

    deleteCourseRole(roleId) {
      /* eslint-disable arrow-body-style */
      this.CourseRolesManager.getCourseRole(this.InstitutionsManager.institution.id, roleId).then((response) => {
        if (response.result.isUsed) {
          let description = '';

          if (RolesService.isMentor(this.courseRoles[roleId])) {
            description = $translate.instant('INSTITUTIONS.ROLES.REMOVE_ROLE_MENTOR', {
              courseRole: this.courseRoles[roleId].name,
            });
          } else {
            description = $translate.instant('INSTITUTIONS.ROLES.REMOVE_ROLE_WARNING');
          }

          const modalInstanceCtrl = ['$scope', function ($scope) {
            $scope.courseRole = this.courseRoles[roleId];
            $scope.description = description;
          }.bind(this)];
          const modalInstance = ConfirmationOverlays.openConfirmationModal('institutions/templates/delete-role-confirm.html', modalInstanceCtrl);
          return modalInstance.result;
        }
        return null;
      }).then(() => {
        // We only enter this 'then' if the 'Yes' button was clicked in the modal, or if it never appeared
        return this.CourseRolesManager.deleteCourseRole(this.InstitutionsManager.institution.id, roleId);
      }).then(() => {
        delete this.courseRoles[roleId];
      });
      /* eslint-enable arrow-body-style */
    }

    cancelEdit() {
      this.initCourseRoleDrafts();
    }
  }

  return new ManageCourseRolesCtrl();
}
