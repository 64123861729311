import React, { useContext, useState } from 'react';
import { AngularServicesContext } from 'react-app';
import t from 'react-translate';
import OrgLevelProfileSettings from 'institutions/components/org-level-profile-settings';
import OrgLevelMetadataSettings from 'institutions/components/org-level-metadata-settings';
import SectionContent from '../section-content';
import { config } from '../../../../../config/pendo.config.json';

const DataConfiguration = () => {
  const { CurrentUserManager, $state } = useContext(AngularServicesContext);
  const [showOrgLevelProfileModal, setShowOrglevelProfileModal] = useState(null);
  const [showOrgLevelMetadataSettingsModal, setShowOrgLevelMetadataSettingsModal] = useState(null);

  const getProfileLink = () => $state.href('org-level-profile-modal', {
    userId: CurrentUserManager.user.id,
  });

  return (
    <div>
      <div className='border-bottom border-gray-5 pb-6'>
        <SectionContent
          header={t.INSTITUTIONS.ADVANCED_SETTINGS.ORG_LEVEL_PROFILE.TITLE()}
          description={[t.INSTITUTIONS.ADVANCED_SETTINGS.ORG_LEVEL_PROFILE.DESCRIPTION(getProfileLink())]}
          showCTAButton
          ctaText={t.INSTITUTIONS.ADVANCED_SETTINGS.CONFIGURE()}
          onAction={showOrgLevelProfileModal}
          dataQa={config.pendo.settings.configureOrganizationProfileButton}
        />
      </div>
      <div className='border-bottom border-gray-5 py-6'>
        <SectionContent
          header={t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.TITLE()}
          description={[t.INSTITUTIONS.ADVANCED_SETTINGS.METADATA.DESCRIPTION()]}
          showCTAButton
          ctaText={t.INSTITUTIONS.ADVANCED_SETTINGS.CONFIGURE()}
          onAction={showOrgLevelMetadataSettingsModal}
          pendo-tag-name={config.pendo.settings.configureMetadataButton}
          dataQa={config.pendo.settings.configureMetadataButton}
        />
      </div>
      <OrgLevelProfileSettings
        forwardShowModal={(func) => setShowOrglevelProfileModal(() => func)}
      />
      <OrgLevelMetadataSettings
        forwardShowModal={(func) => setShowOrgLevelMetadataSettingsModal(() => func)}
      />
    </div>
  );
};

export default DataConfiguration;
