import { css } from '@emotion/react';
import { CARD_WIDTH, MOBILE_CARD_WIDTH } from 'offerings/components/base-card';
import React from 'react';
import { gray5 } from 'styles/global_defaults/colors';
import { mobile } from 'styles/global_defaults/media-queries';

type NvSubmissionPlaceholderCardProps = {
  content: React.ReactElement,
  style?: React.CSSProperties,
};

const NvSubmissionPlaceholderCard = React.forwardRef<HTMLDivElement, NvSubmissionPlaceholderCardProps>(({ content, style }, ref) => {
  const isResponsive = !style?.width;

  const styles = css`
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid ${gray5};
    background-color: white;
    max-width: ${CARD_WIDTH}px;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);

    ${isResponsive ? css`
      width: ${CARD_WIDTH}px;

      ${mobile(css`
        width: ${MOBILE_CARD_WIDTH}px;
      `)};
    ` : mobile(css`
      min-width: ${MOBILE_CARD_WIDTH}px;
    `)};
  `;

  return (
    <div ref={ref} css={styles} style={style}>
      {content}
    </div>
  );
});

export default NvSubmissionPlaceholderCard;
