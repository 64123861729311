export default {
  bindings: {
    announcement: '<',
    // admin function
    showAdminOptions: '=?',
    onEdit: '&?',
    onPreview: '&?',
    onDelete: '&?',
    isNew: '=?',
    isUpdated: '=?',
  },
  controller: function ctrl(
  ) {
'ngInject';
    const vm = this;
  },
  controllerAs: 'vm',
  templateUrl: 'announcements/templates/nv-announcement.html',
};
