/* @ngInject */
export default function AnnouncementViewEmailModalCtrl(

  $uibModalInstance,
  announcement,
  $timeout,
) {
  const vm = this;

  vm.announcement = announcement;

  $timeout(() => {
    $('#preview').contents().find('body').html(vm.announcement.preview.body);
  }, 0);

  /* Closing of Modal */
  vm.dismiss = () => {
    $uibModalInstance.dismiss();
  };
}
