import React from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { getPollQuestion, getNormalizedPollVieweeSubmission } from 'redux/selectors/polls';
import t from 'react-translate';
import { doubleSpacing, halfSpacing, threeQuartersSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { useAppDispatch } from 'redux/store';
import { pollVote } from 'redux/actions/polls';
import { useLectureComponentContext } from 'components/lecture-component-provider';
import ClickableContainer from 'components/clickable-container';
import { gray3, gray6, primary } from 'styles/global_defaults/colors';
import { AngularServicesContext } from 'react-app';
import { getCurrentCourse } from 'redux/selectors/course';
import NvTooltip from 'shared/components/nv-tooltip';
import { RolesService } from 'institutions/services/roles-service';
import { useTimelineService } from 'timelines/services/react-timeline-service';
import { getCurrentLecture } from 'redux/selectors/lecture-page';
import EditPollOptions from './edit-poll-options';
import PollResult from './poll-result';
import PollSelectOption from './poll-select-option';
import { useGetPollLectureComponent } from './poll-utils';
import { config } from '../../../../config/pendo.config.json';

interface PollOptionsProps {
  // eslint-disable-next-line react/no-unused-prop-types
  pollQuestionId: number;
  catalogId: string;
  pollId: number;
}

/* @ngInject */
export default function PollOptions(props: PollOptionsProps) {
  const dispatch = useAppDispatch();
  const lectureComponent = useGetPollLectureComponent();
  const { isEdit, lectureComponentId } = useLectureComponentContext();
  const { $uibModal, TimelinesManager, CurrentPermissionsManager } = React.useContext(AngularServicesContext);

  // selectors
  const question = useSelector((state) => getPollQuestion(state, props));
  const pollVieweeSubmission = useSelector((state) => getNormalizedPollVieweeSubmission(state, { vieweeSubmissionId: lectureComponent.poll.vieweeSubmissionId }));
  const course = useSelector((state) => getCurrentCourse(state));
  const userCourse = useSelector((state) => state.models.enrollments[course.userCourse]);

  // state
  const [initialPointsReceived] = React.useState(pollVieweeSubmission?.pointsReceived || 0);
  const [selectedOption, setSelectedOption] = React.useState<number>(undefined);

  const { progress } = lectureComponent.poll;
  const isPollOpen = progress === 'not_started' || (progress === 'missed' && !lectureComponent.poll.hardDeadline);

  const [shouldShowResult, setShouldShowResult] = React.useState<boolean>(!isPollOpen);

  const timelineService = useTimelineService();
  const currentLecture = useSelector(getCurrentLecture);

  const userCanVote = !course.isContentManagementCollection && !RolesService.isAdminRole(userCourse.roles);
  const isSubmitButtonEnabled = selectedOption !== undefined;
  const shouldShowToggleResult = !isEdit
    && isPollOpen
    && (CurrentPermissionsManager.isTeachingAssistant() || CurrentPermissionsManager.isInstructor());

  let options = shouldShowResult ? (
    <PollResult question={question} selectedOption={pollVieweeSubmission?.responses[0]?.response} />
  ) : (
    <PollSelectOption
      disabled={!userCanVote}
      options={question.responseOptions}
      selectedOption={selectedOption}
      onSelectOption={setSelectedOption}
    />
  );
  if (isEdit) {
    options = <EditPollOptions question={question} />;
  }

  return (
    <React.Fragment>
      {options}
      <div
        css={css`
          border-bottom: ${isPollOpen ? `1px solid ${gray6}` : 'none'};
          ${submitButtonContainerStyle};
        `}
      >
        <div css={userCannotVoteContainer}>
          {isPollOpen && !userCanVote && !isEdit && !course.isContentManagementCollection && (
            <span css={userCannotVoteStyle}>{t.LECTURE_PAGES.COMPONENTS.POLLS.ADMINS_CANNOT_VOTE()}</span>
          )}
        </div>
        {isPollOpen && (
          <button
            type='button'
            css={css`
              margin-bottom: ${shouldShowToggleResult ? halfSpacing : doubleSpacing}px;
            `}
            onClick={() => {
              dispatch(
                pollVote({
                  pollId: props.pollId,
                  responseOptionId: selectedOption,
                  catalogId: props.catalogId,
                }),
              ).then(({ payload }) => {
                setShouldShowResult(true);

                const { pointsReceived, leaderboardPoints, leaderboardRank, priorLeaderboardRank } = payload as any;
                if (pointsReceived && pointsReceived > initialPointsReceived) {
                  timelineService.updateTimeline(currentLecture.id);
                  TimelinesManager.updateComponentPointsAndProgress(
                    lectureComponentId,
                    'poll',
                    props.pollId,
                    pointsReceived,
                  );

                  $uibModal.open({
                    templateUrl: 'shared/templates/points-modal.html',
                    windowClass: 'points-modal',
                    controller: 'PointsModalCtrl as vm',
                    resolve: {
                      pointsReceived,
                      leaderboardPoints,
                      leaderboardRank,
                      priorLeaderboardRank,
                      extras: null,
                    },
                  });
                } else {
                  timelineService.updateTimeline(currentLecture.id);
                  TimelinesManager.updateComponentProgress(lectureComponentId, 'poll', props.pollId, 'completed');
                }
              });
            }}
            className='bs4-btn bs4-btn-primary'
            disabled={!isSubmitButtonEnabled}
            pendo-tag-name={config.pendo.polls.submitPollAnswer}
          >
            {t.LECTURE_PAGES.COMPONENTS.POLLS.SUBMIT_VOTE()}
          </button>
        )}

        {shouldShowToggleResult && !course.isContentManagementCollection && (
          <NvTooltip
            text={t.LECTURE_PAGES.COMPONENTS.POLLS.VIEW_RESULTS_ADMINS_ONLY({
              learnerAlias: course.learnersName.capitalizedPluralized,
            })}
          >
            <ClickableContainer
              css={showResultButton}
              onClick={() => {
                setShouldShowResult((value) => !value);
              }}
            >
              {shouldShowResult
                ? t.LECTURE_PAGES.COMPONENTS.POLLS.HIDE_POLL_RESULTS()
                : t.LECTURE_PAGES.COMPONENTS.POLLS.VIEW_POLL_RESULTS()}
            </ClickableContainer>
          </NvTooltip>
        )}
      </div>
    </React.Fragment>
  );
}

const submitButtonContainerStyle = css`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const userCannotVoteContainer = css`
  height: ${tripleSpacing}px;
  display: flex;
  align-items: flex-end;
`;

const userCannotVoteStyle = css`
  margin-bottom: ${halfSpacing}px;
  color: ${gray3};
  font-size: 12px;
`;

const showResultButton = css`
  color: ${primary};
  font-size: 12px;
  margin-bottom: ${threeQuartersSpacing}px;
`;
