export const iconXssSmallestSize = 12;
export const iconSmallestSize = 16;
export const iconSmallSize = 20;
export const iconMedSize = 24;
export const iconMedLargeSize = 28;
export const iconLargeSize = 36;
export const iconLargerSize = 48;
export const iconLargestSize = 60;
export const iconSuperLargeSize = 80;
export const iconUltraLargeSize = 100;
