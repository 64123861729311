/* @ngInject */
export default function ExerciseShowBaseController(
  ExercisesManager,
  PageLevelManager,
  CurrentUserManager,
  CurrentCourseManager,
  RailsRoutes,
  InteroperabilityRoutes,
  nvCurrentPage,
  $stateParams,
  $controller,
  $scope,
  nvUtil,
) {
  const vm = this;
  const removedTeamWarningPopoverOpen = true;

  vm.removedTeamWarningPopoverOpen = removedTeamWarningPopoverOpen;
  vm.closeTeamWarningPopover = closeTeamWarningPopover;

  vm.manager = ExercisesManager;
  vm.PageLevelManager = PageLevelManager;
  vm.railsRoutes = RailsRoutes;
  vm.RailsRoutes = RailsRoutes;
  vm.InteroperabilityRoutes = InteroperabilityRoutes;
  vm.$stateParams = $stateParams;
  vm.nvCurrentPage = nvCurrentPage;
  vm.CurrentUserManager = CurrentUserManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.nvUtil = nvUtil;

  function closeTeamWarningPopover() {
    vm.removedTeamWarningPopoverOpen = false;
  }
}
