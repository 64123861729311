import React from 'react';
import { css } from '@emotion/react';

import t from 'react-translate';
import { doubleSpacing } from 'styles/global_defaults/scaffolding';
import useDashboardListingCollapser from 'dashboard/hooks/use-dashboard-listing-collapser';

type Props = {
  items: any[],
  keyExtractor: (item: any) => React.Key,
  renderItem: (item: any) => React.ReactElement,
  className?: string,
};

const RowListing = (props: Props) => {
  const {
    items,
    className,
    renderItem,
    keyExtractor,
  } = props;

  const [elementsToRender, collapser] = useDashboardListingCollapser(items, 10);

  const styles = css`
    .rows-container {
      margin-bottom: ${doubleSpacing}px;
    }
  `;

  return (
    <div css={styles} className={className}>
      <div className='rows-container'>
        {elementsToRender.map((item, index) => React.cloneElement(
          renderItem(item),
          {
            key: keyExtractor(item),
          },
        ))}
      </div>
      {collapser}
    </div>
  );
};

export default RowListing;
