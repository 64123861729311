/* @ngInject */
export default function SubmissionApprovalDashboardCtrl(
  _,
  $scope,
  $rootScope,
  $state,

  ReportsManager,
) {
  _.extend(this, {
    ReportsManager,
  });

  ReportsManager.initialize({
    reportsCatalogId: null,
    scope: ReportsManager.SUBMISSION_APPROVAL_DASHBOARD,
  });

  ReportsManager.getApprovalExercises().then(() => {
  }, () => {
    $state.go('dashboard');
  });

  const deregisterReenterState = $rootScope.$on('$stateChangeSuccess', (event, toState, toParams) => {
    if (toState.name === 'submission-approval-dashboard') {
      ReportsManager.doSearch();
    }
  });

  $scope.$on('$destroy', deregisterReenterState);
}
