import store from 'redux/store';

import { ActivityKey } from 'redux/schemas/models/activity';
import { setActivityProgress } from 'redux/actions/lecture-components';

/* @ngInject */
export default function TimelineProgressiveQuizLectureComponentModelService(
  _,
  nvUtil,
  moment,
) {
  const TimelineProgressiveQuizLectureComponentModel = function (attributes) {
    const _this = this;
    let deadlineMoment;
    const currentMoment = moment();

    const base = {
      templateUrl: 'timelines/templates/nv-timeline-progressive-quiz.html',
      canHaveHardDeadline: true,
    };

    _.extend(_this, base, attributes);
    preprocess();

    _.extend(_this, {
      getStatus,
      inProgress,
      isCompleted,
      isMissed,
      isTodoRequiredForCompletion,
      updateComponentPointsAndProgress,
      updateComponentProgress,
      getPayload,
      hasDeadline,
      setDeadline,
    });

    function getStatus() {
      return _this.progressiveQuiz.progress;
    }

    function inProgress() {
      return _this.progressiveQuiz.progress === 'in_progress';
    }

    function isCompleted() {
      return _this.progressiveQuiz.progress === 'completed';
    }

    function isMissed() {
      return _this.progressiveQuiz.progress === 'missed';
    }

    function isTodoRequiredForCompletion() {
      return _this.progressiveQuiz.isRequiredForCompletion;
    }

    function updateComponentPointsAndProgress(componentType, componentId, pointsReceived, totalPoints, progress) {
      if ((_this.type === componentType && _this.id === componentId) || (componentType === 'progressiveQuiz' && _this.progressiveQuiz.id === componentId)) {
        _this.progressiveQuiz.progress = progress;

        store.dispatch(setActivityProgress({
          pointsReceived,
          progress: _this.progressiveQuiz.progress,
          activityPayloadId: _this.progressiveQuiz.id,
          activityKey: ActivityKey.PROGRESSIVE_QUIZZES,
        }));

        _this.progressiveQuiz.pointsReceived = pointsReceived;
        _this.pointsReceived = pointsReceived;

        if (_.isNumber(totalPoints)) {
          _this.currentTotalPoints = totalPoints;
        }

        viewPreprocess();

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function updateComponentProgress(componentType, componentId, progress) {
      if ((_this.type === componentType && _this.id === componentId) || (componentType === 'progressiveQuiz', _this.progressiveQuiz.id === componentId)) {
        _this.progressiveQuiz.progress = progress;

        store.dispatch(setActivityProgress({
          progress,
          activityPayloadId: _this.progressiveQuiz.id,
          activityKey: ActivityKey.PROGRESSIVE_QUIZZES,
        }));
        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function getPayload() {
      return _.pick(_this, ['title', 'expirationDate', 'hardDeadline']);
    }

    function hasDeadline() {
      return !!this.expirationDate;
    }

    function setDeadline(newDeadline) {
      this.expirationDate = newDeadline;
    }

    function showDueSoon() {
      if (_this.expirationDate) {
        return !isCompleted()
            && deadlineMoment > currentMoment
            && deadlineMoment < moment().add(7, 'days');
      }
      return false;
    }

    /* Used Privately */
    function preprocess() {
      deadlineMoment = moment(_this.progressiveQuiz.expirationDate);

      _.extend(_this, _.pick(_this.progressiveQuiz, 'title', 'hardDeadline', 'isTodo', 'hasStructuralIssues', 'expirationDate', 'pointsReceived',
        'totalPoints', 'scheduledCommunicationsCount'));
      _this.activityId = _this.progressiveQuiz.id;

      // current total points takes into account if the the user can score more
      // so if the user has maxed out attempts, the total points will be the same as the pointsReceived
      _this.currentTotalPoints = nvUtil.getCurrentTotalPoints(_this.totalPoints, _this.lecturePage.releaseDate, _this.course.expiringPointsEnabled);

      viewPreprocess();
    }

    function viewPreprocess() {
      _this.showDueSoon = showDueSoon();

      if (_this.pointsReceived > _this.currentTotalPoints) {
        _this.currentTotalPoints = _this.pointsReceived ? _this.pointsReceived : 0;
      }
      _this.displayTotalPointsOnly = !_this.pointsReceived && _this.currentTotalPoints;
    }
  };

  return TimelineProgressiveQuizLectureComponentModel;
}
