
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import axios, { AxiosResponse } from 'axios';
import t from 'react-translate';

// Schemas
import { AlertMessageType } from 'redux/schemas/app/alert-message';

// Actions
import { addAlertMessage } from 'redux/actions/alert-messages';

// Components
import NvSampleEmail from 'shared/components/nv-sample-email';
import { CommunicationType } from 'redux/schemas/models/course-communication';

type CommunicationEmailPreviewProps = {
  catalogId?: string,
  subject: string,
  body: string,
  communicationType: CommunicationType
};

const mapDispatchToProps = {
  alertMessage: addAlertMessage,
};

const CommunicationEmailPreview = (props: CommunicationEmailPreviewProps & typeof mapDispatchToProps) => {
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const catalogIdToUse = props.catalogId ?? catalogId;

  const [emailPreviewBody, setEmailPreviewBody] = useState('');
  const [emailPreviewSubject, setEmailPreviewSubject] = useState('');
  const [emailPreviewFrom, setEmailPreviewFrom] = useState('');
  const [isPreviewLoading, setIsPreviewLoading] = useState(true);

  useEffect(() => {
    if (props.subject && props.body) {
      setIsPreviewLoading(true);
      axios.post(`${catalogIdToUse}/course_communications/email_preview.json`, {
        communication: {
          subject: props.subject,
          body: props.body,
        },
        communicationType: props.communicationType,
      })
        .then((res: AxiosResponse<{ result: { body: string, subject: string, from: string } }>) => {
          setIsPreviewLoading(false);
          const { data } = res;
          setEmailPreviewBody(data.result.body);
          setEmailPreviewSubject(data.result.subject);
          setEmailPreviewFrom(data.result.from);
        })
        .catch(() => {
          setIsPreviewLoading(false);
          props.alertMessage({
            type: AlertMessageType.ERROR,
            header: t.FORM.ERROR(),
            message: t.COURSE_COMMUNICATIONS.SAMPLE_EMAIL.ERROR(),
          });
        });
    }
  }, [catalogIdToUse, props]);

  return (
    <NvSampleEmail
      subject={emailPreviewSubject}
      from={emailPreviewFrom}
      content={emailPreviewBody}
      isPreviewLoading={isPreviewLoading}
    />
  );
};

const ConnectedCommunicationEmailPreview = connect(null, mapDispatchToProps)(CommunicationEmailPreview);

export default ConnectedCommunicationEmailPreview;
