import React, { useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';

// Constants
import { standardSpacing } from 'styles/global_defaults/scaffolding';

// Contexts
import { AngularContext } from 'react-app';

// Redux
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { getTeamInfo, getTeamMembers, setCurrentTeamId } from 'redux/actions/teams';
import { RootState } from 'redux/schemas';

// Components
import TeamInfoBody from './team-info-body';
import TeamInfoHeader from './team-info-header';

const PAGE_SIZE = 20;
const INITIAL_PAGE = 1;

const ManageTeamGroup = () => {
  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const { catalogId, teamId } = $state?.params;
  const currentTeam = useSelector(((state: RootState) => state.models.teams[teamId]));
  const isUploading = useSelector((state: RootState) => state.app.manageTeam.isUploading);
  const [teamInfo, setTeamInfo] = useState(null);
  const dispatch = useAppDispatch();

  useEffect(() => (() => dispatch(setCurrentTeamId({ teamId: null, isUploading: false }))), []);

  useEffect(() => {
    if (currentTeam?.id !== teamInfo?.id && currentTeam.id === Number.parseInt(teamId, 10)) {
      setTeamInfo(currentTeam);
    }
  }, [currentTeam]);

  useEffect(() => {
    if (teamInfo) {
      dispatch(getTeamMembers({
        catalogId,
        teamId,
        pageSize: PAGE_SIZE,
        pageNumber: INITIAL_PAGE,
      }));
    }
  }, [teamInfo]);

  useEffect(() => {
    dispatch(setCurrentTeamId({ teamId: parseInt(teamId, 10), isUploading: true }));
    dispatch(getTeamInfo({ teamId, catalogId }));
  }, [teamId, catalogId]);

  const style = css`
    padding: ${standardSpacing}px;
  `;

  return (
    <React.Fragment>
      {
        teamInfo && !isUploading && (
          <div className='manage-team-group' css={style}>
            <TeamInfoHeader />
            <TeamInfoBody />
          </div>
        )
      }
    </React.Fragment>
  );
};

export default ManageTeamGroup;
