/* @ngInject */
export default function ResetPasswordCtrl(
  UserAuthentication,
  PasswordsResource,
  CurrentUserManager,
  CurrentCourseManager,
  validationConstants,
  $state,
) {
  const vm = this;

  vm.params = $state.params;
  vm.course = CurrentCourseManager.course;
  vm.validationConstants = validationConstants;
  vm.resetPassword = resetPassword;

  initialize();

  function initialize() {
    reinitializePasswordFields();
  }

  function resetPassword() {
    vm.resetingPassword = true;
    CurrentUserManager.requestCurrentUserPromise = PasswordsResource.update(
      {
        user: {
          resetPasswordToken: vm.params.resetPasswordToken,
          password: vm.password,
          passwordConfirmation: vm.passwordConfirmation,
        },
      },
      (resource) => {
        CurrentUserManager.setNewUser(resource.result);
        reinitializePasswordFields();
        UserAuthentication.verifyAuthenticated().finally(() => {
          $state.go('root');
        });
      },
      (resource) => {
        reinitializePasswordFields();
        $state.go('users.authentications.request-password-reset-email', { linkExpired: true });
      },
    ).$promise;
  }

  function reinitializePasswordFields() {
    vm.password = '';
    vm.passwordConfirmation = '';
    vm.resetingPassword = false;
  }
}
