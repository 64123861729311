/* @ngInject */
export default function UserManager(
  UserModel,
  UsersResources,
) {
  const manager = {
    user: null,

    getUser,
    getSearchFilters,
    searchUsers,
    getCurrentEnrollments,
    getCurrentMemberships,
  };

  function getUser(userId) {
    return UsersResources.getUser({ userId }).$promise.then((result) => new UserModel(result));
  }

  function getSearchFilters(catalogId) {
    return UsersResources.searchFilters({ catalogId }).$promise;
  }

  function searchUsers(params, catalogId) {
    return UsersResources.search({ catalogId }, params).$promise;
  }

  function getCurrentEnrollments(userId) {
    return UsersResources.currentEnrollments({ userId }).$promise;
  }

  function getCurrentMemberships() {
    return UsersResources.currentMemberships().$promise;
  }

  return manager;
}
