/* eslint-disable */
/*
  original version: 2.6.5
  latest referenced version: 2.8.4
*/
(function (factory) {
  factory(window.jQuery);
}(($) => {
  $.extend($.FE.POPUP_TEMPLATES, {
    'link.edit': '[_BUTTONS_]',
    'link.insert': '[_BUTTONS_][_INPUT_LAYER_]',
  });

  $.extend($.FE.DEFAULTS, {
    linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
    linkInsertButtons: ['linkBack', '|'],
    linkAttributes: {},
    linkAutoPrefix: 'http://',
    linkConvertEmailAddress: true,
    linkAlwaysBlank: true,
    linkAlwaysNoFollow: false,
    linkNoOpener: true,
    linkNoReferrer: true,
    linkText: true,
  });

  $.FE.PLUGINS.link = function (editor) {
    /**
     * Get the link DOM object of the current selection. If there is more than one link in the selection, only the first one will be returned.
     */
    // eslint-disable-next-line complexity
    function get() {
      const $currentImage = editor.image ? editor.image.get() : null;

      if (!$currentImage && editor.$wp) {
        let cEl = editor.selection.ranges(0).commonAncestorContainer;

        try {
          if (cEl && ((cEl.contains && cEl.contains(editor.el)) || !editor.el.contains(cEl) || editor.el === cEl)) {
            cEl = null;
          }
        } catch (ex) {
          cEl = null;
        }

        if (cEl && cEl.tagName === 'A') {
          return cEl;
        }
        let sEl = editor.selection.element();
        let endEl = editor.selection.endElement();

        if (sEl.tagName !== 'A' && !editor.node.isElement(sEl)) {
          sEl = $(sEl).parentsUntil(editor.$el, 'a:first').get(0);
        }

        if (endEl.tagName !== 'A' && !editor.node.isElement(endEl)) {
          endEl = $(endEl).parentsUntil(editor.$el, 'a:first').get(0);
        }

        try {
          if (endEl && ((endEl.contains && endEl.contains(editor.el)) || !editor.el.contains(endEl) || editor.el === endEl)) {
            endEl = null;
          }
        } catch (ex) {
          endEl = null;
        }

        try {
          if (sEl && ((sEl.contains && sEl.contains(editor.el)) || !editor.el.contains(sEl) || editor.el === sEl)) {
            sEl = null;
          }
        } catch (ex) {
          sEl = null;
        }

        if (endEl && endEl === sEl && endEl.tagName === 'A') {
          // We do not clicking at the end / input of links because in IE the selection is changing shortly after mouseup.
          // https://jsfiddle.net/jredzam3/
          if ((editor.browser.msie || editor.helpers.isMobile()) && (editor.selection.info(sEl).atEnd || editor.selection.info(sEl).atStart)) {
            return null;
          }
          return sEl;
        }

        return null;
      } if (editor.el.tagName === 'A') {
        return editor.el;
      }
      if ($currentImage && $currentImage.get(0).parentNode && $currentImage.get(0).parentNode.tagName === 'A') {
        return $currentImage.get(0).parentNode;
      }
    }

    /**
     * An array of DOM elements of A type from the current selection.
     */
    function allSelected() {
      const $currentImage = editor.image ? editor.image.get() : null;
      const selectedLinks = [];

      if ($currentImage) {
        if ($currentImage.get(0).parentNode.tagName === 'A') {
          selectedLinks.push($currentImage.get(0).parentNode);
        }
      } else {
        let range;
        let containerEl;
        let links;
        let linkRange;

        if (editor.win.getSelection) {
          const sel = editor.win.getSelection();

          if (sel.getRangeAt && sel.rangeCount) {
            linkRange = editor.doc.createRange();

            for (let r = 0; r < sel.rangeCount; ++r) {
              range = sel.getRangeAt(r);
              containerEl = range.commonAncestorContainer;

              if (containerEl && containerEl.nodeType !== 1) {
                containerEl = containerEl.parentNode;
              }

              if (containerEl && containerEl.nodeName.toLowerCase() === 'a') {
                selectedLinks.push(containerEl);
              } else {
                links = containerEl.getElementsByTagName('a');

                for (let i = 0; i < links.length; ++i) {
                  linkRange.selectNodeContents(links[i]);

                  if (linkRange.compareBoundaryPoints(range.END_TO_START, range) < 1 && linkRange.compareBoundaryPoints(range.START_TO_END, range) > -1) {
                    selectedLinks.push(links[i]);
                  }
                }
              }
            }

            // linkRange.detach();
          }
        } else if (editor.doc.selection && editor.doc.selection.type !== 'Control') {
          range = editor.doc.selection.createRange();
          containerEl = range.parentElement();

          if (containerEl.nodeName.toLowerCase() === 'a') {
            selectedLinks.push(containerEl);
          } else {
            links = containerEl.getElementsByTagName('a');
            linkRange = editor.doc.body.createTextRange();

            for (let j = 0; j < links.length; ++j) {
              linkRange.moveToElementText(links[j]);

              if (linkRange.compareEndPoints('StartToEnd', range) > -1 && linkRange.compareEndPoints('EndToStart', range) < 1) {
                selectedLinks.push(links[j]);
              }
            }
          }
        }
      }

      return selectedLinks;
    }

    /**
     * file.insert (link, [text], [response]) Returns: Object
     * Insert the link to a file at the cursor position.
     */
    /* eslint-disable max-statements */
    /* eslint-disable complexity */
    function insert(href, text, attrs) {
      if (typeof attrs === 'undefined') {
        attrs = {};
      }

      if (editor.events.trigger('link.beforeInsert', [href, text, attrs]) === false) {
        return false;
      }

      // Get image if we have one selected.
      const $currentImage = editor.image ? editor.image.get() : null;

      if (!$currentImage && editor.el.tagName !== 'A') {
        editor.selection.restore();
        editor.popups.hide('link.insert');
      } else if (editor.el.tagName === 'A') {
        editor.$el.focus();
      }

      const originalHref = href;

      // Convert email address.
      if (editor.opts.linkConvertEmailAddress) {
        const regex = $.FE.MAIL_REGEX;
        if (editor.helpers.isEmail(href) && !/^mailto:.*/i.test(href)) {
          href = `mailto:${href}`;
        }
      }

      // Check if is local path.
      const localPath = /^([A-Za-z]:(\\){1,2}|[A-Za-z]:((\\){1,2}[^\\]+)+)(\\)?$/i;

      // Add autoprefix.
      if (editor.opts.linkAutoPrefix !== '' && !new RegExp(`^(${$.FE.LinkProtocols.join('|')}):.`, 'i').test(href)
        && !/^data:image.*/i.test(href)
        && !/^(https?:|ftps?:|file:|)\/\//i.test(href)
        && !localPath.test(href)) {
        // Do prefix only if starting character is not absolute.
        if (['/', '{', '[', '#', '(', '.'].indexOf((href || '')[0]) < 0) {
          href = editor.opts.linkAutoPrefix + editor.helpers.sanitizeURL(href);
        }
      }

      // Sanitize the URL.
      href = editor.helpers.sanitizeURL(href);

      // Default attributs.
      if (editor.opts.linkAlwaysBlank) {
        attrs.target = '_blank';
      }

      if (editor.opts.linkAlwaysNoFollow) {
        attrs.rel = 'nofollow';
      }

      // https://github.com/froala/wysiwyg-editor/issues/1576.
      if (attrs.target === '_blank') {
        if (editor.opts.linkNoOpener) {
          if (!attrs.rel) {
            attrs.rel = 'noopener';
          } else {
            attrs.rel += ' noopener';
          }
        }

        if (editor.opts.linkNoReferrer) {
          if (!attrs.rel) {
            attrs.rel = 'noreferrer';
          } else {
            attrs.rel += ' noreferrer';
          }
        }
      } else if (attrs.target === null) {
        if (attrs.rel) {
          attrs.rel = attrs.rel.replace(/noopener/, '').replace(/noreferrer/, '');
        } else {
          attrs.rel = null;
        }
      }

      // Format text.
      text = text || '';

      if (href === editor.opts.linkAutoPrefix) {
        const $popup = editor.popups.get('link.insert');
        $popup.find('input[name="href"]').addClass('fr-error');
        editor.events.trigger('link.bad', [originalHref]);

        return false;
      }

      // Check if we have selection only in one link.
      const link = get();
      let $link;

      if (link) {
        $link = $(link);

        $link.attr('href', href);

        // Change text if it is different.
        if (text.length > 0 && $link.text() !== text && !$currentImage) {
          let child = $link.get(0);

          while (child.childNodes.length === 1 && child.childNodes[0].nodeType === Node.ELEMENT_NODE) {
            child = child.childNodes[0];
          }

          $(child).text(text);
        }

        if (!$currentImage) {
          $link
            .prepend($.FE.START_MARKER)
            .append($.FE.END_MARKER);
        }

        // Set attributes.
        $link.attr(attrs);

        if (!$currentImage) {
          editor.selection.restore();
        }
      } else {
        // We don't have any image selected.
        if (!$currentImage) {
          // Remove current links.
          editor.format.remove('a');

          // Nothing is selected.
          if (editor.selection.isCollapsed()) {
            text = (text.length === 0 ? originalHref : text);
            editor.html.insert(`<a href="${href}">${$.FE.START_MARKER}${text.replace(/&/g, '&amp;')}${$.FE.END_MARKER}</a>`);
            editor.selection.restore();
          } else if (text.length > 0 && text !== editor.selection.text().replace(/\n/g, '')) {
            editor.selection.remove();
            editor.html.insert(`<a href="${href}">${$.FE.START_MARKER}${text.replace(/&/g, '&amp;')}${$.FE.END_MARKER}</a>`);
            editor.selection.restore();
          } else {
            _split();

            // Add link.
            editor.format.apply('a', { href });
          }
        } else {
          // Just wrap current image with a link.
          $currentImage.wrap(`<a href="${href}"></a>`);
        }

        // Set attributes.
        const links = allSelected();

        for (let i = 0; i < links.length; i += 1) {
          $link = $(links[i]);
          $link.attr(attrs);
          $link.removeAttr('_moz_dirty');
        }

        // Show link edit if only one link.
        if (links.length === 1 && editor.$wp && !$currentImage) {
          $(links[0])
            .prepend($.FE.START_MARKER)
            .append($.FE.END_MARKER);

          editor.selection.restore();
        }
      }

      // Hide popup and try to edit.
      if (!$currentImage) {
        _edit();
      } else {
        const $pop = editor.popups.get('link.insert');
        if ($pop) {
          $pop.find('input:focus').blur();
        }
        editor.image.edit($currentImage);
      }
    }

    function imageLink() {
      let $el = editor.image ? editor.image.getEl() : null;

      if ($el) {
        let $popup = editor.popups.get('link.insert');

        if (editor.image.hasCaption()) {
          $el = $el.find('.fr-img-wrap');
        }

        if (!$popup) {
          $popup = _initInsertPopup();
        }
        _refreshInsertPopup(true);
        editor.popups.setContainer('link.insert', editor.$sc);
        const left = $el.offset().left + $el.outerWidth() / 2;
        const top = $el.offset().top + $el.outerHeight();
        editor.popups.show('link.insert', left, top, $el.outerHeight());
      }
    }

    /**
     * Removes the current selected link.
     */
    function remove() {
      const link = get();

      const $currentImage = editor.image ? editor.image.get() : null;

      if (editor.events.trigger('link.beforeRemove', [link]) === false) {
        return false;
      }

      if ($currentImage && link) {
        $currentImage.unwrap();
        editor.image.edit($currentImage);
      } else if (link) {
        editor.selection.save();
        $(link).replaceWith($(link).html());
        editor.selection.restore();
        _hideEditPopup();
      }
    }

    function _edit(e) {
      if (editor.core.hasFocus()) {
        _hideEditPopup();

        // Do not show edit popup for link when ALT is hit.
        if (e && e.type === 'keyup' && (e.altKey || e.which === $.FE.KEYCODE.ALT)) {
          return true;
        }

        setTimeout(() => {
          // No event passed.
          // Event passed and (left click or other event type).
          if (!e || (e && (e.which === 1 || e.type !== 'mouseup'))) {
            const link = get();
            const $currentImage = editor.image ? editor.image.get() : null;

            if (link && !$currentImage) {
              if (editor.image) {
                const contents = editor.node.contents(link);

                // https://github.com/froala/wysiwyg-editor/issues/1103
                if (contents.length === 1 && contents[0].tagName === 'IMG') {
                  const range = editor.selection.ranges(0);

                  if (range.startOffset === 0 && range.endOffset === 0) {
                    $(link).before($.FE.MARKERS);
                  } else {
                    $(link).after($.FE.MARKERS);
                  }

                  editor.selection.restore();

                  return false;
                }
              }

              if (e) {
                e.stopPropagation();
              }
              _showEditPopup(link);
            }
          }
        }, editor.helpers.isIOS() ? 100 : 0);
      }
    }


    /* Insert Popup */
    function _initInsertPopup(delayed) {
      if (delayed) {
        editor.popups.onRefresh('link.insert', _refreshInsertPopup);
        editor.popups.onHide('link.insert', _hideInsertPopup);

        return true;
      }

      // Image buttons.
      let linkButtons = '';

      if (editor.opts.linkInsertButtons.length >= 1) {
        linkButtons = `<div class="fr-buttons">${editor.button.buildList(editor.opts.linkInsertButtons)}</div>`;
      }

      // Image by url layer.
      let inputLayer = '';
      let tabIdx = 0;
      inputLayer = `<div class="fr-link-insert-layer fr-layer fr-active" id="fr-link-insert-layer-${editor.id}">`;
      inputLayer += `<div class="fr-input-line">
        <input id="fr-link-insert-layer-url-${editor.id}" name="href" type="text" class="fr-link-attr"
          placeholder="${editor.language.translate('URL')}" tabIndex="${(++tabIdx)}">
      </div>`;


      if (editor.opts.linkText) {
        inputLayer += `<div class="fr-input-line">
          <input id="fr-link-insert-layer-text-${editor.id}"
            name="text"
            type="text"
            class="fr-link-attr"
            placeholder="${editor.language.translate('Text')}"
            tabIndex="${++tabIdx}">
        </div>`;
      }

      // Add any additional fields.
      for (const attr in editor.opts.linkAttributes) {
        if (editor.opts.linkAttributes.hasOwnProperty(attr)) {
          const placeholder = editor.opts.linkAttributes[attr];
          inputLayer += `<div class="fr-input-line">
            <input name="${attr}" type="text" class="fr-link-attr" placeholder="${editor.language.translate(placeholder)}" tabIndex="${++tabIdx}">
          </div>`;
        }
      }

      inputLayer += `<div class="fr-action-buttons">
        <button class="fr-command fr-submit" role="button" data-cmd="linkInsert" href="#" tabIndex="${++tabIdx}" type="button">${editor.language.translate('Insert')}</button>
      </div></div>`;

      const template = {
        buttons: linkButtons,
        /* eslint-disable camelcase */
        input_layer: inputLayer,
      };

      // Set the template in the popup.
      const $popup = editor.popups.create('link.insert', template);

      if (editor.$wp) {
        editor.events.$on(editor.$wp, 'scroll.link-insert', () => {
          const $currentImage = editor.image ? editor.image.get() : null;

          if ($currentImage && editor.popups.isVisible('link.insert')) {
            imageLink();
          }

          if (get && editor.popups.isVisible('link.insert')) {
            update();
          }
        });
      }

      return $popup;
    }

    function _showInsertPopup() {
      const $btn = editor.$tb.find('.fr-command[data-cmd="insertLink"]');
      let $popup = editor.popups.get('link.insert');

      if (!$popup) {
        $popup = _initInsertPopup();
      }

      if (!$popup.hasClass('fr-active')) {
        editor.popups.refresh('link.insert');
        editor.popups.setContainer('link.insert', editor.$tb || editor.$sc);

        if ($btn.is(':visible')) {
          const left = $btn.offset().left + $btn.outerWidth() / 2;
          const top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);
          editor.popups.show('link.insert', left, top, $btn.outerHeight());
        } else {
          editor.position.forSelection($popup);
          editor.popups.show('link.insert');
        }
      }
    }

    function _refreshInsertPopup() {
      const $popup = editor.popups.get('link.insert');
      const link = get();

      if (link) {
        const $link = $(link);
        const textInputs = $popup.find('input.fr-link-attr[type="text"]');
        let i;
        let $input;

        for (i = 0; i < textInputs.length; i += 1) {
          $input = $(textInputs[i]);
          $input.val($link.attr($input.attr('name') || ''));
        }

        $popup.find('input.fr-link-attr[type="text"][name="text"]').val($link.text());
      } else {
        $popup.find('input.fr-link-attr[type="text"]').val('');
        $popup.find('input.fr-link-attr[type="text"][name="text"]').val(editor.selection.text());
      }

      $popup.find('input.fr-link-attr').trigger('change');
      const $currentImage = editor.image ? editor.image.get() : null;

      if ($currentImage) {
        $popup.find('.fr-link-attr[name="text"]').parent().hide();
      } else {
        $popup.find('.fr-link-attr[name="text"]').parent().show();
      }
    }

    function _hideInsertPopup() {
    }


    /* Edit Popup */
    function _initEditPopup() {
      // Link buttons.
      let linkButtons = '';

      if (editor.opts.linkEditButtons.length >= 1) {
        if (editor.el.tagName === 'A' && editor.opts.linkEditButtons.indexOf('linkRemove') >= 0) {
          editor.opts.linkEditButtons.splice(editor.opts.linkEditButtons.indexOf('linkRemove'), 1);
        }

        linkButtons = `<div class="fr-buttons dark">${editor.button.buildList(editor.opts.linkEditButtons)}</div>`;
      }

      const template = {
        buttons: linkButtons,
      };

      // Set the template in the popup.
      const $popup = editor.popups.create('link.edit', template);

      if (editor.$wp) {
        editor.events.$on(editor.$wp, 'scroll.link-edit', () => {
          if (get() && editor.popups.isVisible('link.edit')) {
            _showEditPopup(get());
          }
        });
      }

      return $popup;
    }

    function _showEditPopup(link) {
      let $popup = editor.popups.get('link.edit');

      if (!$popup) {
        $popup = _initEditPopup();
      }

      const $link = $(link);

      if (!editor.popups.isVisible('link.edit')) {
        editor.popups.refresh('link.edit');
      }
      editor.popups.setContainer('link.edit', editor.$sc);
      const left = $link.offset().left + $(link).outerWidth() / 2;
      const top = $link.offset().top + $link.outerHeight();
      editor.popups.show('link.edit', left, top, $link.outerHeight());
    }

    function _hideEditPopup() {
      editor.popups.hide('link.edit');
    }


    function back() {
      const $currentImage = editor.image ? editor.image.get() : null;

      if (!$currentImage) {
        editor.events.disableBlur();
        editor.selection.restore();
        editor.events.enableBlur();

        const link = get();

        if (link && editor.$wp) {
          editor.selection.restore();
          _hideEditPopup();
          _edit();
        } else if (editor.el.tagName === 'A') {
          editor.$el.focus();
          _edit();
        } else {
          editor.popups.hide('link.insert');
          editor.toolbar.showInline();
        }
      } else {
        editor.image.back();
      }
    }


    function update() {
      _hideEditPopup();

      const link = get();

      if (link) {
        let $popup = editor.popups.get('link.insert');

        if (!$popup) {
          $popup = _initInsertPopup();
        }

        if (!editor.popups.isVisible('link.insert')) {
          editor.popups.refresh('link.insert');
          editor.selection.save();

          if (editor.helpers.isMobile()) {
            editor.events.disableBlur();
            editor.$el.blur();
            editor.events.enableBlur();
          }
        }

        editor.popups.setContainer('link.insert', editor.$sc);
        const $ref = (editor.image ? editor.image.get() : null) || $(link);
        const left = $ref.offset().left + $ref.outerWidth() / 2;
        const top = $ref.offset().top + $ref.outerHeight();

        editor.popups.show('link.insert', left, top, $ref.outerHeight());
      }
    }

    // attach keyboard events for showing popup
    function _init() {
      // Edit on keyup.
      editor.events.on('keyup', (e) => {
        if (e.which !== $.FE.KEYCODE.ESC) {
          _edit(e);
        }
      });

      editor.events.on('window.mouseup', _edit);

      // Do not follow links when edit is disabled.
      editor.events.$on(editor.$el, 'click', 'a', (e) => {
        if (editor.edit.isDisabled()) {
          e.preventDefault();
        }
      });

      if (editor.helpers.isMobile()) {
        editor.events.$on(editor.$doc, 'selectionchange', _edit);
      }

      _initInsertPopup(true);

      // Init on link.
      if (editor.el.tagName === 'A') {
        editor.$el.addClass('fr-view');
      }

      // Hit ESC when focus is in link edit popup.
      editor.events.on('toolbar.esc', () => {
        if (editor.popups.isVisible('link.edit')) {
          editor.events.disableBlur();
          editor.events.focus();

          return false;
        }
      }, true);
    }

    function insertCallback() {
      const $popup = editor.popups.get('link.insert');
      const textInputs = $popup.find('input.fr-link-attr[type="text"]');
      const href = (textInputs.filter('[name="href"]').val() || '').trim();
      const text = textInputs.filter('[name="text"]').val();
      const attrs = {};
      let $input;
      let i;

      for (i = 0; i < textInputs.length; i += 1) {
        $input = $(textInputs[i]);

        if (['href', 'text'].indexOf($input.attr('name')) < 0) {
          attrs[$input.attr('name')] = $input.val();
        }
      }

      const t = editor.helpers.scrollTop();
      insert(href, text, attrs);
      /* eslint-disable camelcase */
      $(editor.o_win).scrollTop(t);
    }

    function _split() {
      if (!editor.selection.isCollapsed()) {
        editor.selection.save();
        let markers = editor.$el.find('.fr-marker').addClass('fr-unprocessed').toArray();

        while (markers.length) {
          const $marker = $(markers.pop());
          $marker.removeClass('fr-unprocessed');

          // Get deepest parent.
          const deepParent = editor.node.deepestParent($marker.get(0));

          if (deepParent) {
            let node = $marker.get(0);
            let closeStr = '';
            let openStr = '';

            do {
              node = node.parentNode;

              if (!editor.node.isBlock(node)) {
                closeStr += editor.node.closeTagString(node);
                openStr = editor.node.openTagString(node) + openStr;
              }
            } while (node !== deepParent);

            const markerStr = editor.node.openTagString($marker.get(0)) + $marker.html() + editor.node.closeTagString($marker.get(0));

            $marker.replaceWith('<span id="fr-break"></span>');
            let h = deepParent.outerHTML;

            h = h.replace(/<span id="fr-break"><\/span>/g, closeStr + markerStr + openStr);

            deepParent.outerHTML = h;
          }

          markers = editor.$el.find('.fr-marker.fr-unprocessed').toArray();
        }

        editor.html.cleanEmptyTags();

        editor.selection.restore();
      }
    }


    return {
      _init,

      get,
      allSelected,
      insert,
      update,
      remove,

      showInsertPopup: _showInsertPopup,
      back,
      insertCallback,
      imageLink,
    };
  };

  // Register the link command.
  $.FE.DefineIcon('insertLink', { NAME: 'format-makelink' });
  $.FE.RegisterShortcut($.FE.KEYCODE.K, 'insertLink', null, 'K');
  $.FE.RegisterCommand('insertLink', {
    title: 'Insert Link',
    undo: false,
    focus: true,
    refreshOnCallback: false,
    popup: true,
    callback() {
      if (!this.popups.isVisible('link.insert')) {
        this.link.showInsertPopup();
      } else {
        if (this.$el.find('.fr-marker').length) {
          this.events.disableBlur();
          this.selection.restore();
        }
        this.popups.hide('link.insert');
      }
    },
    plugin: 'link',
  });

  $.FE.DefineIcon('linkOpen', { NAME: 'open-external' });
  $.FE.RegisterCommand('linkOpen', {
    title: 'Open Link',
    undo: false,
    refresh($btn) {
      const link = this.link.get();

      if (link) {
        $btn.removeClass('fr-hidden');
      } else {
        $btn.addClass('fr-hidden');
      }
    },
    callback() {
      const link = this.link.get();

      if (link) {
        /* eslint-disable camelcase */
        this.o_win.open(link.href);

        // this.o_win.open(link.href, '_blank', 'noopener');

        // https://mathiasbynens.github.io/rel-noopener/
        // var otherWindow = window.open();
        // otherWindow.opener = null;
        // otherWindow.location = link.href;

        this.popups.hide('link.edit');
      }
    },
    plugin: 'link',
  });

  $.FE.DefineIcon('linkEdit', { NAME: 'edit' });
  $.FE.RegisterCommand('linkEdit', {
    title: 'Edit Link',
    undo: false,
    refreshAfterCallback: false,
    popup: true,
    callback() {
      this.link.update();
    },
    refresh($btn) {
      const link = this.link.get();

      if (link) {
        $btn.removeClass('fr-hidden');
      } else {
        $btn.addClass('fr-hidden');
      }
    },
    plugin: 'link',
  });

  $.FE.DefineIcon('linkRemove', { NAME: 'close' });
  $.FE.RegisterCommand('linkRemove', {
    title: 'Unlink',
    callback() {
      this.link.remove();
    },
    refresh($btn) {
      const link = this.link.get();

      if (link) {
        $btn.removeClass('fr-hidden');
      } else {
        $btn.addClass('fr-hidden');
      }
    },
    plugin: 'link',
  });

  $.FE.DefineIcon('linkBack', { NAME: 'arrow-left' });
  $.FE.RegisterCommand('linkBack', {
    title: 'Back',
    undo: false,
    focus: false,
    back: true,
    refreshAfterCallback: false,
    callback() {
      this.link.back();
    },
    refresh($btn) {
      const link = this.link.get() && this.doc.hasFocus();
      const $currentImage = this.image ? this.image.get() : null;

      if (!$currentImage && !link && !this.opts.toolbarInline) {
        $btn.addClass('fr-hidden');
        $btn.next('.fr-separator').addClass('fr-hidden');
      } else {
        $btn.removeClass('fr-hidden');
        $btn.next('.fr-separator').removeClass('fr-hidden');
      }
    },
    plugin: 'link',
  });

  $.FE.RegisterCommand('linkInsert', {
    focus: false,
    refreshAfterCallback: false,
    callback() {
      this.link.insertCallback();
    },
    refresh($btn) {
      const link = this.link.get();

      if (link) {
        $btn.text(this.language.translate('Update'));
      } else {
        $btn.text(this.language.translate('Insert'));
      }
    },
    plugin: 'link',
  });
}));
