import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import transform from 'lodash/transform';
import isObject from 'lodash/isObject';
import rootReducer from './reducers';

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActionPaths: [
        'payload.onConfirm',
        'payload.onCancel',
        'payload.bodyText',
        'meta.arg.handleDataPage',
        'meta.arg.callback',
        'meta.arg.flyerLogo',
        'payload.files',
        'meta.arg',
        'payload.file',
      ],
      ignoredPaths:
        [
          'app.confirmationDialog.onConfirm',
          'app.confirmationDialog.onCancel',
          'app.lecturePage.filesToUpload',
          'app.confirmationDialog.bodyText',
          'app.lecturePage.temporaryComponents',
        ],
    },
  }),
  devTools: {
    serialize: {
      options: {
        set: true,
      },
    },
  },
});

export default store;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

/** Deep clones an object and recursively removes all of the non-serializable properties that are not
 * suitable to be stored in Redux. These are typically Angularjs services defined on Angularjs model files,
 * and methods on model class instances */
export const cloneDeepSerializable = (value: unknown) => cloneDeepSerializableWithoutCycles(value, new Set<unknown>());

/**
 * recursively clones a value skipping circular references (example below) which are not serializable:
 * let objectA = {};
 * let objectB = { objectA };
 * objectA.objectBRef = objectB;
 *
 * Also removes/omits object keys of the following types:
 * - Those beginning with a capital letter (in AngularJS this is reserved for Angularjs services, which are non-serializable)
 * - Those beginning with a $ (these are also always AngularJS services)
 * - Those beginning with an underscore (sometimes used to refer to temp or debug data, like "__originalLpData")
 */
function cloneDeepSerializableWithoutCycles(value: unknown, visitedNodes: Set<unknown>) {
  // skip functions, symbols and visited values to prevent cycles
  if (typeof value === 'function' || typeof value === 'symbol' || visitedNodes.has(value)) {
    return undefined;
  }

  // isObject will return false for null
  if (isObject(value)) {
    if (Array.isArray(value)) {
      visitedNodes.add(value);

      const arrCopy = value.map((prop) => cloneDeepSerializableWithoutCycles(prop, visitedNodes));
      visitedNodes.delete(value);
      return arrCopy;
    }
    // not an array

    // track visited nodes
    visitedNodes.add(value);

    const objCopy = transform(
      value,
      (acc, prop, key: string) => {
        if ((Number.isNaN(parseInt(key, 10)) && key[0] === key[0].toUpperCase()) || key[0] === '_' || key[0] === '$') {
          delete acc[key];
          return;
        }

        const propValue = cloneDeepSerializableWithoutCycles(prop, visitedNodes);
        if (propValue !== undefined) {
          acc[key] = propValue;
        }
      },
      {},
    );

    // Removing the value from visitedNodes to keep the value only during child traversing.
    // Inspired from https://github.com/tracker1/safe-clone-deep
    visitedNodes.delete(value);

    return objCopy;
  }

  // assume that it's a primitive. No need to track them as visited
  return value;
}

// Decline hot updates in all of our Redux files because they refrequently cause a bug where
// hot reloading re-initializes React Context values. This typically causes the error UI to indicate
// our Angular services are null
if (module.hot) {
  module.hot.decline();
}
