export enum VideoType {
  VIDEO = 'Video',
  AUDIO = 'Audio',
}

export type AutoGeneratedStatus = 'in_progress' | 'completed' | 'error' | 'regenerating';
type AutoGeneratedError = 'unsupported_language' | 'language_low_confidence' | 'repeated_language' | 'internal_error';

interface AutoGeneratedInfo {
  fileName: string;
  url: string;
  status: AutoGeneratedStatus;
  videoId?: number;
  errorCode: AutoGeneratedError;
}

export interface AutoGenerated {
  transcript: AutoGeneratedInfo;
  caption: {
    language: string;
  } & AutoGeneratedInfo;
}

export interface AutoTranslated {
  caption: {
    language: string;
  } & AutoGeneratedInfo;
}

export interface Video {
  id: number;
  name: string;
  length: number;
  mediaSource: string;
  type: VideoType;
  autoGenerateCaption: boolean;
  autoGenerateTranscript: boolean;
  autoGenerated: AutoGenerated;
  autoTranslated: AutoTranslated;
}

export interface VideosNormalized { [id: string]: Video }

export interface JwPlayerVideo extends Video {
  hasBeenTranscoded: string;
  originalUrl: string;
  videoPlayerUrl: string;
  downloadLink: string;
  canDownload: boolean;
  lowestAvailableQualityUrl: string;
  highQualityUrl: string;
  mediumQualityUrl: string;
  lowQualityUrl: string;
  thumbnailUrl: string;
  highQualityThumbnailUrl: string;
  numCoursesUsing: boolean;
  lowDefaultPlayback: boolean;
  mediumDefaultPlayback: boolean;
  highDefaultPlayback: boolean;
  transcriptUrl: string;
  downloadUrl: string;
  url: string;
}
