import { css } from '@emotion/react';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { AngularServicesContext } from 'react-app';
import t from 'react-translate';
import { filter, indexBy, isEmpty } from 'underscore';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Styles
import { gray2, gray5, gray7, primary, warning, white } from 'styles/global_defaults/colors';
import { createGridStyles, doubleSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';

// Redux
import { fetchCollectionsList } from 'redux/actions/collections';
import { RootState } from 'redux/schemas';
import { CollectionCourse } from 'redux/schemas/models/collections';
import { PagedDataQueryParams } from 'redux/create-action-creators';

// components
import ClickableContainer from 'components/clickable-container';
import LoadingPlaceholder from 'shared/components/loading-placeholder';
import NvResponsiveTable, { ColumnSortings, NvResponsiveTableColumn, NvTableRowProps } from 'shared/components/nv-responsive-table';
import { config } from '../../../config/config.json';
import CollectionActionDropdown from './collection-action-dropdown';

const PAGE_SIZE = 20;
export const collectionSortKeys = ['name'];

type CollectionListProps = {
  showPermissionModal(catalogId: string): void
  closeModal?: (catalogId?: string, isUpdate?: boolean, reloadList?: boolean) => void
};

interface CollectionPagedDataQueryParams extends PagedDataQueryParams {
  includeEmpty: boolean
}

const initialFetchParams: CollectionPagedDataQueryParams = {
  searchQuery: null,
  filters: null,
  sorting: [`${collectionSortKeys[0]}&order=asc`], // default sorting name asc
  includeEmpty: true,
};

const CollectionList = (props: CollectionListProps) => {
  const { showPermissionModal, closeModal } = props;
  const styles = css`
    height: calc(100vh - 120px); // 120 = (top header(60) + tab header(60))

    .nv-responsive-table {
      min-height: calc(100vh - 120px);
    }

    .no-results-panel {
      padding-top: ${doubleSpacing}px;

      .icon:not(.icon-smallest) {
        font-size: ${doubleSpacing}px;
        margin-bottom: ${standardSpacing}px;
      }
    }

    .collection-row {
      &:hover, &.options-open {
        .options {
          display: flex;
          background-color: ${gray5};
          .icon {
            color: ${gray2}
          }
        }
      }

      .name {
        padding: ${standardSpacing}px;

        span {
          white-space: pre-wrap;
        }
      }

      .options {
        display: none;
        &:hover {
          background-color: ${primary};
          .icon {
            color: ${white}
          }
        }

        .nv-dropdown {
          height: 100%;
          width: 100%;

          .bs4-dropdown {
            &, > div:first-of-type {
              height: 100%;
            }
          }
        }
      }

      &.highlight {
        border-left: solid ${quarterSpacing}px ${warning};
        background-color: ${white}
      }
    }
  `;

  const [columnSortings, setColumnSortings] = useState<ColumnSortings>({ 0: true });
  const [pagedFetchParams, setPagedFetchParams] = useState<CollectionPagedDataQueryParams>(initialFetchParams);

  const tableColumnsWidth = {
    name: '40%',
    creator: '12%',
    createdTime: '10%',
    lastEditor: '12%',
    lastEdited: '10%',
    role: '12%',
    options: '4%',
  };

  const tableColumns: NvResponsiveTableColumn[] = [
    {
      name: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_TABLE.NAME(),
      className: 'name-cell',
      gridWidth: tableColumnsWidth.name,
      sortable: true,
    },
    {
      name: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_TABLE.CREATOR(),
      className: 'creator-cell',
      gridWidth: tableColumnsWidth.creator,
    },
    {
      name: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_TABLE.CREATED_TIME(),
      className: 'created-time-cell',
      gridWidth: tableColumnsWidth.createdTime,
    },
    {
      name: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_TABLE.LAST_EDITOR(),
      className: 'last-editor-cell',
      gridWidth: tableColumnsWidth.lastEditor,
    },
    {
      name: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_TABLE.LAST_EDITED(),
      className: 'last-edited-cell',
      gridWidth: tableColumnsWidth.lastEdited,
    },
    {
      name: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_TABLE.ROLE(),
      className: 'role-cell',
      gridWidth: tableColumnsWidth.role,
    },
    {
      name: '',
      className: 'options-cell',
      gridWidth: tableColumnsWidth.options,
    },
  ];

  const collectionRowSyles = (columnsWidth) => css`
    &:hover {
      box-shadow: 0px 0px 10px rgba(29, 33, 38, 0.2);
      background-color: ${gray7};
      z-index: 1;
    }

    .name {
      width: ${columnsWidth.name}
    }
    .creator {
      width: ${columnsWidth.creator}
    }
    .created-time {
      width: ${columnsWidth.createdTime}
    }
    .last-editor {
      width: ${columnsWidth.lastEditor}
    }
    .last-edited {
      width: ${columnsWidth.lastEdited}
    }
    .role {
      width: ${columnsWidth.role}
    }
    .options {
      width: ${columnsWidth.options};
    }
  `;

  const CollectionRow = (rowProps: NvTableRowProps<CollectionCourse>) => {
    const { data: collection, rowIndex } = rowProps;
    const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
    const highlightedCatalogId: string = useSelector((state: RootState) => state.app.collection.highlightedCollectionCatalogId);

    const isHighlighted = highlightedCatalogId === collection.catalogId;
    const { CurrentPermissionsManager } = useContext(AngularServicesContext);

    const history = useHistory();

    const canViewEditMenu = !isEmpty(collection) && (
      CurrentPermissionsManager.hasCollectionManagerPermissions(collection.userCourse)
      || CurrentPermissionsManager.hasCollectionAdminPermissions(collection.userCourse)
      || CurrentPermissionsManager.hasCourseManagerPermissions);

    const canDisableNameClick = !CurrentPermissionsManager.hasCollectionViewerPermissions(collection.userCourse)
      && !CurrentPermissionsManager.hasCourseManagerPermissions;

    const onClickName = () => {
      if (isEmpty(collection.userCourse?.role) && CurrentPermissionsManager.hasCourseManagerPermissions()) {
        showPermissionModal(collection.catalogId);
      } else {
        history.push(`/collections/${collection.id}/${collection.catalogId}/home`);
      }
    };

    const onOptionsToggle = (isOpen: boolean) => setIsOptionsOpen(isOpen);

    return (
      <div
        className={`collection-row d-flex w-100 border-bottom text-black ${isHighlighted ? 'highlight' : ''} ${isOptionsOpen ? 'options-open' : ''}`}
        style={createGridStyles(1, rowIndex, tableColumns.length + 1, rowIndex + 1)}
        css={collectionRowSyles(tableColumnsWidth)}
      >
        <ClickableContainer
          className={`name text-regular bold ${isHighlighted ? 'pl-3' : ''}`}
          onClick={onClickName}
          disabled={canDisableNameClick}
          data-qa={config.pendo.contentManagement.openCollection}
          data-qa-id={`${config.pendo.contentManagement.openCollection}_${collection.id}`}
        >
          <span className='two-line-ellipsis'>{collection.title}</span>
        </ClickableContainer>
        <div className='creator py-4 pr-4'>
          <span className='text-regular two-line-ellipsis'>
            {`${collection.creator?.firstName ?? ''} ${collection.creator?.lastName ?? ''}`}
          </span>
        </div>
        <div className='created-time text-regular d-flex align-items-center py-4 pr-4'>
          {moment(collection.createdAt).format('MM/DD/YYYY')}
        </div>
        <div className='last-editor d-flex align-items-center py-4 pr-4'>
          <span className='text-regular two-line-ellipsis'>
            {`${collection.lastEditor?.firstName ?? ''} ${collection.lastEditor?.lastName ?? ''}`}
          </span>
        </div>
        <div className='last-edited text-regular d-flex align-items-center py-4 pr-4'>
          {moment(collection.updatedAt).format('MM/DD/YYYY')}
        </div>
        <div className='role text-regular d-flex align-items-center py-4 pr-4'>
          {collection.userCourse?.role ?? '-' }
        </div>
        {canViewEditMenu && (
          <div className='options align-items-center justify-content-center'>
            <CollectionActionDropdown
              iconClass='icon-smallest icon-more d-flex align-items-center justify-content-center h-100 w-100'
              collection={collection}
              closeModal={closeModal}
              onMenuToggle={onOptionsToggle}
            />
          </div>
        )}
      </div>
    );
  };

  const LoadingRow = (rowProps: { rowIndex: number }, rowEnd: number) => (
    <div className='px-5' style={createGridStyles(1, rowProps.rowIndex, rowEnd)}>
      <LoadingPlaceholder className=' mt-5' />
    </div>
  );

  const getStoreCollectionData = (state: RootState) => indexBy(
    filter(state.models.courses, (course) => course.isContentManagementCollection),
    'catalogId',
  );

  const noResultsTextComponent = (
    <div className='text-regular text-gray-3 bold'>
      {t.INSTITUTIONS.CONTENT_LIBRARY.NO_COLLECTION_YET()}
    </div>
  );

  const onHeaderSortingClicked = (colIndex: number) => {
    const newSorting = !columnSortings[colIndex];
    const newSortKey = `${collectionSortKeys[colIndex]}&order=${newSorting ? 'asc' : 'desc'}`;

    setColumnSortings({ [colIndex]: newSorting });
    setPagedFetchParams({
      ...pagedFetchParams,
      sorting: [newSortKey],
    });
  };

  return (
    <div css={styles}>
      <NvResponsiveTable<CollectionCourse>
        columns={tableColumns}
        fetchData={fetchCollectionsList as any}
        fetchParams={{}}
        pagedFetchParams={pagedFetchParams}
        columnSortings={columnSortings}
        onSortClicked={onHeaderSortingClicked}
        rowComponent={CollectionRow}
        rowProps={{}}
        loadingComponent={(rawProps) => LoadingRow(rawProps, tableColumns.length + 1)}
        dataKey='catalogId'
        cacheDataKey='catalogId'
        cacheLookup={getStoreCollectionData}
        clearSearch={() => {}}
        pageSize={PAGE_SIZE}
        style={createGridStyles(1, 3)}
        noResultsIcon='content'
        noResultsTextComponent={noResultsTextComponent}
        hoverDisabled
      />
    </div>
  );
};

export default CollectionList;

