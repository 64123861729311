import { css } from '@emotion/react';
import { connect, useDispatch } from 'react-redux';
import { RootState } from 'redux/schemas';
import {
  Institution,
  InstitutionLogoSize,
} from 'redux/schemas/models/institution';
import t from 'react-translate';
import React, { useState, ChangeEvent, useContext, CSSProperties, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import {
  black,
  gray3,
  logo,
  hexToRgbaString,
  warning,
  isValidHex,
} from 'styles/global_defaults/colors';
import NvPopover from 'shared/components/nv-popover';
import { NvFilePicker } from 'shared/components/nv-filepicker';
import { NvTooltip } from 'shared/components/nv-tooltip';
import { updateInstitutionBranding } from 'redux/actions/institutions';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { isNotDesktop, notDesktop } from 'styles/global_defaults/media-queries';
import { AngularServicesContext } from 'react-app';
import WhiteBlackColorButtons from 'shared/components/white-black-color-buttons';
import NvDropdown, {
  NvDropdownAlign,
  NvDropdownButtonStyle,
  NvDropdownOption,
} from 'shared/components/inputs/nv-dropdown';
import ClickableContainer from 'components/clickable-container';

// Styles
import {
  standardSpacing,
  halfSpacing,
  threeQuartersSpacing,
  doubleSpacing,
  quarterSpacing,
} from 'styles/global_defaults/scaffolding';
import Dropdown from 'react-bootstrap/Dropdown';
import ValidationErrorMessage from 'shared/components/inputs/validation-error-message';
import ColorPickerPopover, { SetBackgroundColorTarget } from 'components/color-picker-popover';
import { config } from '../../../config/config.json';

type StateProps = {
};

type DispatchProps = {
  updateInstitutionBranding: typeof updateInstitutionBranding,
  openConfirmationDialog: typeof openConfirmationDialog,
};

type OwnProps = {
  institution: Institution,
  style: CSSProperties,
};

/** The maximum institution name, enforced by the backend */
const MAX_NAME_LENGTH = 75;
/** z-index for the partially transparent black background beneath the institution banner when editing */
const SHADING_MASK_Z_INDEX = 100;
/* 5MB as max logo file size(Represented in bytes). */
const MAX_LOGO_SIZE = 5242880;
/* Allowed image file types to upload for logo */
const ALLOWED_LOGO_TYPES = config.files.images.split(',');

const LOGO_SIZE = {
  [InstitutionLogoSize.SMALL]: standardSpacing * 3,
  [InstitutionLogoSize.MEDIUM]: standardSpacing * 4,
  [InstitutionLogoSize.LARGE]: standardSpacing * 5,
};

// A partial adaptation of edit-institution-branding.jade
const InstitutionBanner = (props: StateProps & DispatchProps & OwnProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const showOnEdit = (displayStyle: string = 'block') => css`
    display: ${isEditing ? displayStyle : 'none'};
  `;

  const [brandColorDraft, setBrandColorDraft] = useState(props.institution.brandColor);
  const [institutionNameDraft, setInstitutionNameDraft] = useState(props.institution.name);
  // A Blob of the uploaded file
  const [logoDraft, setLogoDraft] = useState<Blob>(null);
  // Tracks whether the draft includes deleting the logo. Added b/c it was difficult to track this with logoDraft
  // given that the logo is null when not initially set
  const [logoDeleted, setLogoDeleted] = useState(false);
  // Track the logo src with a different url since the logo draft is not displayed via an actual URL but
  // instead a base64 encoded string. logoDraft is uploaded during a form save, and logoSrc is not.
  const [logoSrc, setLogoSrc] = useState(props.institution.logo);
  // If size not selected, Setting default logo size as small.
  const [logoSizeDraft, setLogoSizeDraft] = useState(props.institution.logoSize ?? InstitutionLogoSize.SMALL);
  const [fontColorDraft, setFontColorDraft] = useState<string>(props.institution.brandBarFontColor);

  const [showLogoDeleteConfirm, setShowLogoDeleteConfirm] = useState(false);

  const [logoHeight, setLogoHeight] = useState(LOGO_SIZE[logoSizeDraft]);
  const [selectedLogoInvalid, setSelectedLogoInvalid] = useState(false);

  const angularServices = useContext(AngularServicesContext);
  const { CurrentPermissionsManager, InstitutionsManager } = angularServices;
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setLogoHeight(LOGO_SIZE[logoSizeDraft]);
  }, [logoSizeDraft]);

  const styles = css`
    display: flex;
    flex-direction: column;
    z-index: ${SHADING_MASK_Z_INDEX + 1}; /* Place the edit btns above the shading mask */

    background: ${brandColorDraft};
    color: ${fontColorDraft};
    text-align: center;

    .top-section {
      height: ${doubleSpacing}px;
    }

    .edit-input-panel {
      display: grid;
      display: -ms-grid;
    }

    input {
      /* Overrides MacOS Safari coloring disabled input as gray */
      -webkit-text-fill-color: ${fontColorDraft};
    }

    /* Hide the default <input>s, and only show them edit mode
    is enabled */
    input.edit-input {
      ${showOnEdit()};
      background-color: transparent;
      border: none;
      text-align: center;
      margin: ${halfSpacing}px;
      color: ${fontColorDraft};
    }

    input.edit-input:focus {
      outline: none;
    }

    .edit-color-input {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    /* Holds both logo and the logo deletion confirmation overlay */
    .logo-area {
      position: relative;
    }

    .brand-logo {
      /* Not setting this causes the UI to shift when in edit mode due to the .logo-image border */
      height: ${logoHeight}px;

      .logo-image {
        background-image: url(${logoSrc});
        height: ${logoHeight}px;
        max-width: 100vw;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      border: ${isEditing ? `1px dashed ${gray3}` : 'none'};
    }

    .blank-logo-upload-bkg > * {
      ${showOnEdit()};
    }

    .logo-delete-confirm {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: ${logoHeight + 2}px;
      background-color: ${black}F2; /* F2 == 95% */

      display: flex;
      justify-content: center;
      align-items: center;
      & > *:first-of-type {
        margin-right: ${standardSpacing}px;
      }

      & .bs4-btn:first-of-type {
        margin-right: ${halfSpacing}px;
      }
    }

    .institution-name {
      display: block;
      padding: ${threeQuartersSpacing}px;
      /* Force this input to always display */
      input {
        display: block;
        width: calc(100% - 10px);
      }
    }

    .icons-panel {
      display: flex;
      padding: ${halfSpacing}px;
      padding-top: 0;
      padding-bottom: 0;
      align-items: center;
      position: absolute;
      left: 10px;
      top: 15px;

      .icon {
        padding-right: ${halfSpacing}px;
      }
    }

    .hamburger-btn {
      display: none;
      padding-right: ${halfSpacing}px;
    }

    ${notDesktop(css`
      .hamburger-btn {
        display: block;
      }
    `)};

    .edit-btn {
      display: ${isEditing ? 'none' : 'flex'};
      cursor: pointer;
      width: 160px;
      align-items: center;
    }

    .foreground-color-picker {
      ${showOnEdit('inline')};
      position: absolute;
      bottom: -8px;
      width: 100%;
    }

    .form-buttons-panel {
      ${showOnEdit()};

      /* Set these buttons beneath the bottom of the institution banner */
      position: absolute;
      left: 0;
      right: 0;
      bottom: -${standardSpacing * 3}px;

      & > *:first-of-type {
        margin-right: ${halfSpacing}px;
      }
    }

    /* Gives an element a transparent black color */
    .shaded-bkg {
      ${isEditing ? css`
        /* 4C for an opacity of .3
        Avoid using the 'opacity' property here since it makes the
        input child be transparent, too */
        background-color: ${hexToRgbaString(black, 0.3)};

        /* rgba(red(${black}), green(${black}, blue(${black}), .3); */
      ` : ''}
    }
  `;

  const shadingMaskStyles = css`
    ${showOnEdit()};
    position: fixed;
    background: black;
    opacity: 0.9;
    height: 100%;
    width: 100%;
    z-index: ${SHADING_MASK_Z_INDEX};
  `;

  const logoMenuStyles = css`
    .bs4-dropdown-menu {
      max-width: 160px;

      .bs4-dropdown-item {
        color: ${black};
        padding: ${quarterSpacing}px ${threeQuartersSpacing}px;

        .icon {
          color:${warning};
        }
      }

      .nv-dropdown-header {
        margin-top: ${halfSpacing}px;
        margin-left: ${threeQuartersSpacing}px;
      }

      .logo-item-action {
        padding: ${quarterSpacing}px ${threeQuartersSpacing}px;
        color: ${black};
      }
    }
  `;

  const cancelEditing = () => {
    setBrandColorDraft(props.institution.brandColor);
    setInstitutionNameDraft(props.institution.name);
    setLogoDraft(null);
    setLogoSrc(props.institution.logo);
    setFontColorDraft(props.institution.brandBarFontColor);
    setShowLogoDeleteConfirm(false);
    setIsEditing(false);
    setLogoSizeDraft(props.institution.logoSize ?? InstitutionLogoSize.SMALL);
  };

  const saveEdits = () => {
    const newBranding: any = {
      institutionId: props.institution.id,
      name: institutionNameDraft,
      brandBarFontColor: fontColorDraft,
      brandColor: brandColorDraft,
      flyerLogoSize: logoSizeDraft,
    };

    // Add an extra param to delete the logo if it was removed
    // Otherwise, add the new logo
    if (logoDeleted) {
      newBranding.deleteLogo = true;
    } else {
      newBranding.flyerLogo = logoDraft;
    }

    props.openConfirmationDialog({
      title: t.INSTITUTIONS.FORM.CONFIRMATION_MESSAGE(),
      bodyText: null,
      onConfirm: () => {
        setSaving(true);
        dispatch(updateInstitutionBranding(newBranding)).then((res) => {
          InstitutionsManager.institution.updateFromReact({
            ...res.payload,
          });
        }).finally(() => {
          InstitutionsManager?.initialize(props.institution.id);
          setSaving(false);
          setIsEditing(false);
        });
      },
    });
  };

  const handleBrandColorInputChange = (section, newColor) => {
    setBrandColorDraft(newColor);
  };

  const onDeleteClicked = () => {
    setShowLogoDeleteConfirm(true);
  };

  const onLogoDeleteCancelled = () => {
    setShowLogoDeleteConfirm(false);
  };

  const onLogoDeleteConfirmed = () => {
    setLogoDeleted(true);
    setLogoDraft(null);
    setLogoSrc(null);
    setShowLogoDeleteConfirm(false);
  };

  /* Show a file select dialog on click */
  const onLogoClicked = (e) => {
    if (!isEditing) {
      // Prevent showing the dialog via the NvFilePicker if we're not currently editing
      e.stopPropagation();
    }
    setSelectedLogoInvalid(false);
  };

  /* Set the selected file as the logo draft */
  const onLogoChanged = (files: File[]) => {
    const [file] = files;

    if (!file) {
      return;
    }

    const logoSrcReader = new FileReader();
    logoSrcReader.readAsDataURL(file);

    logoSrcReader.onload = () => {
      setLogoSrc(`${logoSrcReader.result}`);
    };

    setLogoDraft(file);

    logoSrcReader.onerror = (error) => {
      /* eslint-disable-next-line no-console */
      console.error(error);
    };
  };

  const onNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setInstitutionNameDraft(e.target.value);
  };

  const nameIsValid = () => institutionNameDraft.length > 0 && institutionNameDraft.length < MAX_NAME_LENGTH;

  /** Enable saving if any of the drafts have been changed and if all drafts are valid */
  const saveEnabled = () => isValidHex(brandColorDraft)
    && nameIsValid()
    && (props.institution.name !== institutionNameDraft
    || logoDraft !== null
    || logoDeleted
    || props.institution.brandBarFontColor !== fontColorDraft
    || props.institution.brandColor !== brandColorDraft
    || props.institution.logoSize !== logoSizeDraft);

  let logoArea = null;
  let logoBackground = null;

  const logoMenuItems: NvDropdownOption[] = [
    {
      type: 'custom',
      customItem: (
        <Dropdown.Item
          as={(itemProps) => (
            <NvFilePicker
              {...itemProps}
              multiple={false}
              maxSize={MAX_LOGO_SIZE}
              accept={ALLOWED_LOGO_TYPES}
              onChange={(e) => onLogoChanged(e)}
              onSelectError={handleLogoSelectError}
              className='logo-item-action'
            >
              {t.INSTITUTIONS.FORM.LOGO_MENU.NEW_LOGO()}
            </NvFilePicker>
          )}
        />
      ),
    },
    { type: 'divider' },
    {
      type: 'header',
      title: t.INSTITUTIONS.FORM.LOGO_MENU.SIZE.HEADER(),
      class: 'font-weight-bold',
    },
    {
      type: 'text',
      text: t.INSTITUTIONS.FORM.LOGO_MENU.SIZE.SMALL(),
      id: InstitutionLogoSize.SMALL,
      callback: () => setLogoSizeDraft(InstitutionLogoSize.SMALL),
    },
    {
      type: 'text',
      text: t.INSTITUTIONS.FORM.LOGO_MENU.SIZE.MEDIUM(),
      id: InstitutionLogoSize.MEDIUM,
      callback: () => setLogoSizeDraft(InstitutionLogoSize.MEDIUM),
    },
    {
      type: 'text',
      text: t.INSTITUTIONS.FORM.LOGO_MENU.SIZE.LARGE(),
      id: InstitutionLogoSize.LARGE,
      callback: () => setLogoSizeDraft(InstitutionLogoSize.LARGE),
    },
    { type: 'divider' },
    {
      // Using Dropdown.Item in custom type to hide the dropdown on click
      // Delete logo option will show as selected on click, while using the item
      // type as text. Which is due to the showSelectedIndicator
      type: 'custom',
      customItem: (
        <Dropdown.Item
          onClick={() => onDeleteClicked()}
          as={(itemProps) => (
            <div
              {...itemProps}
              className='logo-item-action text-danger'
            >
              {t.INSTITUTIONS.FORM.LOGO_MENU.DELETE()}
            </div>
          )}
        />
      ),
    },
  ];

  const selectedLogoSizeIndex = logoMenuItems.findIndex(
    item => item?.id === logoSizeDraft,
  );

  // Show a default "Upload an image" UI if no logo is specified
  if (!logoSrc || !logoSrc.length) {
    logoBackground = <BrandLogoUploadBackground logoHeight={logoHeight} />;
  } else {
    logoBackground = (
      <NvTooltip
        text={t.INSTITUTIONS.FORM.UPDATE_LOGO()}
        enabled={isEditing}
        placement='bottom'
      >
        <div className='logo-image' />
      </NvTooltip>
    );
  }

  const handleLogoSelectError = () => setSelectedLogoInvalid(true);

  const logoDeleteConfirm = showLogoDeleteConfirm ? (
    <div className='logo-delete-confirm text-white'>
      <span>{t.FILE_UPLOAD.CONFIRM_DELETE_SHORT()}</span>
      <Button variant='secondary' className='bs4-night' onClick={() => onLogoDeleteCancelled()}>{t.NOVOED.CANCEL()}</Button>
      <Button variant='danger' className='bs4-night' onClick={() => onLogoDeleteConfirmed()}>{t.FORM.DELETE()}</Button>
    </div>
  ) : '';

  if (!isEditing) {
    logoArea = (
      <div className='logo-area'>
        <div className='brand-logo shaded-bkg' onClick={(e) => onLogoClicked(e)}>
          {logoBackground}
        </div>
      </div>
    );
  } else {
    logoArea = (
      <div className='logo-area text-white' css={logoMenuStyles}>
        <NvPopover
          placement='bottom'
          show={selectedLogoInvalid}
          content={(
            <ValidationErrorMessage
              text={t.PROFILE.INVALID_FILE()}
            />
          )}
        >
          <React.Fragment>
            {(!logoSrc?.length) ? (
              <NvFilePicker
                multiple={false}
                maxSize={MAX_LOGO_SIZE}
                accept={ALLOWED_LOGO_TYPES}
                onChange={(e) => onLogoChanged(e)}
                onSelectError={handleLogoSelectError}
              >
                <div
                  className='brand-logo shaded-bkg'
                  onClick={(e) => onLogoClicked(e)}
                >
                  {logoBackground}
                </div>
              </NvFilePicker>
            ) : (
              <NvDropdown
                buttonStyle={NvDropdownButtonStyle.CUSTOM}
                items={logoMenuItems}
                align={NvDropdownAlign.CENTER}
                customTarget={() => (
                  <div
                    className='brand-logo shaded-bkg w-100'
                    onClick={(e) => onLogoClicked(e)}
                  >
                    {logoBackground}
                  </div>
                )}
                showSelectedIndicator
                initialIndex={selectedLogoSizeIndex}
              />
            )}

            {/* An inline confirm dialog that floats above the image when the delete btn is clicked */}
            {logoDeleteConfirm}
          </React.Fragment>
        </NvPopover>
      </div>
    );
  }

  /** Opens the left hand nav menu */
  const openLhs = () => {
    // Open the LHS by getting the MainGridCtrl from the Angular.js app through the root scope. This is a short-term fix
    // until the LHS is translated to React
    angularServices.$rootScope.$$childHead.MainGridCtrl.toggleLhsMobile();
  };

  /* Conditionally apply position: relative to this component so that we can position the text color btns & cancel/save buttons
   * with absolute positioning, while also not clipping any popovers on the page with this banner when not in edit mode */
  const headerStyle: CSSProperties = {
    position: isEditing ? 'relative' : 'unset',
  };

  return (
    <div style={props.style}>
      <div css={styles} style={headerStyle}>
        {/* Brand color input box */}
        <div className='top-section mb-2'>
          {isEditing && (
            <NvTooltip text={t.INSTITUTIONS.FORM.UPDATE_BRAND_COLOR()} enabled={isEditing}>
              <div className='edit-color-input shaded-bkg mx-auto mb-4 position-absolute'>
                <ColorPickerPopover<{
                  backgroundColor: string;
                }>
                  placement='bottom'
                  colorValues={{
                    backgroundColor: brandColorDraft,
                  }}
                  onChange={handleBrandColorInputChange}
                  sections={[{
                    name: 'backgroundColor',
                    title: t.LECTURE_PAGES.COMPONENTS.HEADER.BACKGROUND_COLOR(),
                  }]}
                >
                  <SetBackgroundColorTarget />
                </ColorPickerPopover>
              </div>
            </NvTooltip>
          )}
        </div>
        {logoArea}
        {/* Institution name edit input */}
        <NvTooltip text={t.INSTITUTIONS.FORM.UPDATE_NAME()} enabled={isEditing}>
          <div className='institution-name course-title-l shaded-bkg edit-input-panel'>
            <input className='edit-input' type='text' disabled={!isEditing} value={institutionNameDraft} onChange={onNameChanged} maxLength={MAX_NAME_LENGTH} />
          </div>
        </NvTooltip>
        {/* Floating LHS open hamburger btn and Edit Institution btn */}
        <div className='icons-panel'>
          <ClickableContainer
            className='hamburger-btn'
            onClick={() => openLhs()}
            aria-label={t.LHS.OPEN_LHS()}
          >
            <div className='icon icon-menu icon-small' />
          </ClickableContainer>
          {CurrentPermissionsManager.hasOrgAdminPermissions() && (
            <NvTooltip text={t.INSTITUTIONS.FORM.EDIT_INSTITUTION_BRANDING()} enabled={!isEditing} placement='bottom'>
              <ClickableContainer className='edit-btn' onClick={() => setIsEditing(true)}>
                <div className='icon icon-edit text-xsmall' />
                <span className='text-small'>{t.INSTITUTIONS.FORM.EDIT_BRANDING()}</span>
              </ClickableContainer>
            </NvTooltip>
          )}
        </div>
        {/* Cancel / Save form edit buttons */}
        <div className='form-buttons-panel'>
          <Button
            variant='secondary'
            className='bs4-night'
            onClick={() => cancelEditing()}
          >
            {t.NOVOED.CANCEL()}
          </Button>
          <Button
            variant='primary'
            className='bs4-night'
            disabled={!saveEnabled() || saving}
            onClick={() => saveEdits()}
          >
            {saving ? t.FORM.SAVING() : t.FORM.SAVE()}
          </Button>
        </div>
        {/* White/black text selection buttons */}
        <div className='foreground-color-picker'>
          <WhiteBlackColorButtons
            whiteTooltipText={t.INSTITUTIONS.FORM.TITLE_TEXT_WHITE()}
            blackTooltipText={t.INSTITUTIONS.FORM.TITLE_TEXT_BLACK()}
            setColor={(val) => setFontColorDraft(val)}
          />
        </div>
      </div>
      <div css={shadingMaskStyles} />
    </div>
  );
};

// Adapted from `brand-logo-upload-background.jade`
const BrandLogoUploadBackground = (props: { logoHeight: number }) => {
  const styles = css`
    display: flex;
    justify-items: center;
    text-align: left;
    height: ${props.logoHeight}px; /* Should match the height of .logo-image */
    justify-content: center;
    align-content: center;
    align-items: center;
    .text-wrapper {
      padding-left: ${halfSpacing}px;
    }
  `;

  return (
    <div className='blank-logo-upload-bkg' css={styles}>
      <i className='icon-upload icon-large inline-block' />
      <div className='text-wrapper'>
        <div className='update-text'>
          {t.INSTITUTIONS.FORM.UPLOAD_LOGO()}
        </div>
        <div className='file-info-text'>
          { /* TODO: I think this actually belongs in nv-filepicker
          https://projects.invisionapp.com/d/?origin=v7#/console/5847843/212336034/preview */ }
          <span>
            {t.FILE_UPLOAD.IMAGE_TYPES()}
            &nbsp;
          </span>
          <span>{t.FILE_UPLOAD.UP_TO_SIZE('5MB')}</span>
        </div>
      </div>
    </div>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(() => ({
}), {
  updateInstitutionBranding,
  openConfirmationDialog,
} as any)(InstitutionBanner);
