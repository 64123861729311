/* @ngInject */
export default function nvSubmissionApprovalNewComment(
  _,
  $state,

  AlertMessages,
  PageLevelManager,

  ReportsManager,
) {
  return {
    restrict: 'A',
    scope: {
      report: '=',
    },
    controller: function ctrl($scope, $element) {
'ngInject';
      _.extend(this, {
        submitting: false,

        approveSubmission,
        rejectSubmission,
      });

      function goToNextSubmission() {
        const nextSubmission = ReportsManager.getNextReport();

        if (nextSubmission) {
          return $state.go('individual-submission-modal', {
            catalogId: nextSubmission.exercise.catalogId || nextSubmission.exercise.course.catalogId,
            reportId: nextSubmission.id,
          });
        } if (PageLevelManager.lastParent()) {
          PageLevelManager.callL4CloseCallbacks();
        } else {
          return $state.go('submission-dashboard');
        }

        return null;
      }

      function reloadCurrentSubmission() {
        ReportsManager.isLoadingSubmission = true;
        ReportsManager.getSingleSubmission(ReportsManager.currentReport.catalogId, ReportsManager.currentReport.id)
          .then(() => {
            ReportsManager.isLoadingSubmission = false;
          });
      }

      function errorHandler(response) {
        switch (response.data.error.code) {
          case 'report.error.rejected_by_another_user':
          case 'report.error.approved_by_another_user':
            AlertMessages.error('SUBMISSION_APPROVAL.SUBMISSION_ALERT_FAILURE', 'SUBMISSION_APPROVAL.SUBMISSION_ALREADY_REVIEWED');
            goToNextSubmission();
            break;
          case 'report.error.new_submission':
            AlertMessages.error('SUBMISSION_APPROVAL.SUBMISSION_ALERT_FAILURE', 'SUBMISSION_APPROVAL.SUSMISSION_REVISED');
            reloadCurrentSubmission();
            break;
          default:
            AlertMessages.error('SUBMISSION_APPROVAL.SUBMISSION_ALERT_FAILURE', 'SUBMISSION_APPROVAL.NOT_AVAILABLE');
            goToNextSubmission();
        }
      }

      function approveSubmission() {
        this.submitting = true;

        this.report.approveSubmission(this.comment, !!this.shareApprovalPublicly)
          .then(() => {
            AlertMessages.success('SUBMISSION_APPROVAL.SUBMISSION_ALERT_SUCCESS', 'SUBMISSION_APPROVAL.SUBMISSION_APPROVED_ALERT');
            goToNextSubmission();
          }, errorHandler);
      }

      function rejectSubmission() {
        this.submitting = true;

        this.report.rejectSubmission(this.comment, !!this.shareApprovalPublicly)
          .then(() => {
            AlertMessages.success('SUBMISSION_APPROVAL.SUBMISSION_ALERT_SUCCESS', 'SUBMISSION_APPROVAL.SUBMISSION_REJECTED_ALERT');
            goToNextSubmission();
          }, errorHandler);
      }
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'submission_approval_dashboard/templates/nv-submission-approval-new-comment.html',
  };
}
