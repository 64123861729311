/* eslint-disable */
/*
  original version: 2.6.5
  latest referenced version: 2.8.4
*/
import React from 'react';
import store from 'redux/store';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';

import { black, white } from 'styles/global_defaults/colors';
import AppStylesProvider from 'components/bs4-theme-provider';
import { getCurrentLecture } from 'redux/selectors/lecture-page';
import { RefValue, ColorPaletteContent, ColorPickerContent } from 'shared/components/inputs/color-picker';
import useUserColors from 'shared/hooks/use-user-colors';
import prodPathReplace from '../../shared/prod-path-rewrite';
import { getDefaultColorPalette, getHighlightColorPalette } from 'redux/selectors/org-level-colors';

const TRANSPARENT_RGBA = 'rgba(0, 0, 0, 0)';

const Wrapper = (props) => (
  <Provider store={store}>
    <AppStylesProvider>{props.children}</AppStylesProvider>
  </Provider>
);

(function (factory) {
  factory(window.jQuery);
}(($) => {
  // Extend defaults.
  $.extend($.FE.DEFAULTS, {
    colorsStep: 6,
    defaultTextColor: black,
    defaultBackgroundColor: white,
  });

  $.FE.PLUGINS.colors = function (editor) {
    const PALETTE_POPUP_NAME = `colors-palette-${editor.id}`;
    const PICKER_POPUP_NAME = `color-picker-${editor.id}`;

    $.extend($.FE.POPUP_TEMPLATES, {
      [PALETTE_POPUP_NAME]: '[_PALETTE_]',
      [PICKER_POPUP_NAME]: '[_PICKER_]',
    });

    let lastRange;
    let scrollListener;
    let lastActiveColor;
    let mouseDownCaptureListener;
    let updateRecentlyUsedColors;
    /*
     * Show the colors popup.
     */
    function showColorsPopup() {
      let $palettePopup = editor.popups.get(PALETTE_POPUP_NAME);
      let $pickerPopup = editor.popups.get(PICKER_POPUP_NAME);

      if (!$palettePopup || !$pickerPopup) {
        const { $palettePopup: palettePopup, $pickerPopup: pickerPopup } = _initColorsPopups();

        $palettePopup = palettePopup;
        $pickerPopup = pickerPopup;
      }

      _initializeReactColorState();

      editor.popups.show(PALETTE_POPUP_NAME);
      editor.position.forSelection($palettePopup);
    }

    /*
     * Hide colors popup.
     */
    function hideColorsPopup() {
      // Hide popup.
      editor.popups.hide(PALETTE_POPUP_NAME);
    }

    function _restoreSelection() {
      if (lastRange) {
        const documentSelection = document.getSelection();
        documentSelection.removeAllRanges();
        documentSelection.addRange(lastRange);
      }
    }

    /**
     * Init the colors popups, palette and color picker.
     */
    function _initColorsPopups() {
      const paletteId = `${editor.id}-palette`;
      const paletteTemplate = {
        palette: `<div class="react-app" id="${paletteId}"></div>`,
      };

      const pickerId = `${editor.id}-picker`
      const pickerTemplate = {
        picker: `<div class="react-app" id="${pickerId}"></div>`,
      };

      const $palettePopup = editor.popups.create(PALETTE_POPUP_NAME, paletteTemplate);
      const $pickerPopup = editor.popups.create(PICKER_POPUP_NAME, pickerTemplate);
      $palettePopup.css({
        top: 0,
        left: 0,
      });
      $pickerPopup.css({
        top: 0,
        left: 0,
      });

      const { colorPickerContainer } = editor.opts;

      let container;

      if (colorPickerContainer) {
        container = editor.$el.closest(colorPickerContainer);

        if (container.length) {
          editor.popups.setContainer(PALETTE_POPUP_NAME, container);
          editor.popups.setContainer(PICKER_POPUP_NAME, container);
        }
      }

      scrollListener = () => {
        // Do not update popover position when scrolling for when their
        // container is the actuall scroll container.
        if (container ? !(editor.$sc.get(0) === container.get(0) || editor.$sc.find(container).length) : true) {
          editor.position.forSelection($pickerPopup);
          editor.position.forSelection($palettePopup);
        }
      };


      editor.$sc.on('scroll', scrollListener);

      // Defined handlers by froala break react pickers, so removing them.
      $pickerPopup.off();

      mouseDownCaptureListener = (e) => {
        if (lastRange && !(e.target instanceof HTMLInputElement)) {
          e.preventDefault();

          _restoreSelection();
        }
      }

      $pickerPopup.get(0).addEventListener('mousedown', mouseDownCaptureListener, true);

      const ColorPalette = () => {
        const [colorValues, setColorValues] = React.useState({
          'text-color': null,
          'background-color': null,
        });

        const highlightColorPalette: string[] = useSelector(getHighlightColorPalette);
        const defaultColorPalette: string[] = useSelector(getDefaultColorPalette);

        $palettePopup.updateColors = setColorValues;

        return (
          <ColorPaletteContent
            onChange={(sectionName, newColor) => {
              if (sectionName === 'text-color') {
                editor.commands.exec('textColor', [newColor]);
              } else {
                editor.commands.exec('backgroundColor', [newColor]);
              }

              setColorValues((prev) => ({
                ...prev,
                [sectionName]: newColor,
              }));
            }}
            onColorPickerClick={(sectionName) => {
              editor.colors.hideColorsPopup();
              $palettePopup.currentSection = sectionName;
              $pickerPopup.updateColors(colorValues[sectionName]);

              editor.popups.show(PICKER_POPUP_NAME);
              editor.selection.restore();
              lastRange = editor.selection.get().getRangeAt(0);
              editor.position.forSelection($pickerPopup);
            }}
            colorValues={colorValues}
            sections={[
              {
                removable: true,
                name: 'background-color',
                predefinedColors: highlightColorPalette,
                title: editor.language.translate('Highlight Color'),
              },
              {
                name: 'text-color',
                predefinedColors: defaultColorPalette,
                title: editor.language.translate('Text Color'),
              },
            ]}
          />
        )
      };

      const ColorPicker = () => {
        const inputRef = React.useRef<HTMLInputElement>();
        const debouncedRef = React.useRef<NodeJS.Timeout>();
        const currentLecture = useSelector(getCurrentLecture);
        const colorPickerContentRef = React.useRef<RefValue>();
        const [activeColor, setActiveColor] = React.useState<string>();

        const { updateRecentlyUsedColorsIfNeeded } = useUserColors({
          currentLecture,
        });

        updateRecentlyUsedColors = () => lastActiveColor && updateRecentlyUsedColorsIfNeeded(lastActiveColor, true);

        $pickerPopup.updateColors = setActiveColor;

        React.useEffect(() => {
          lastActiveColor = activeColor;
        }, [activeColor]);

        return (
            <ColorPickerContent
              color={activeColor}
              ref={colorPickerContentRef}
              inputProps={{
                onBlur: (e) => {
                  e.stopPropagation();
                  if (lastRange) {
                    const documentSelection = document.getSelection();
                    documentSelection.removeAllRanges();
                    documentSelection.addRange(lastRange);
                  }
                },
                ref: inputRef,
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
                editor.events.disableBlur();
              }}
              onChange={(hex, causedByHexInput?: boolean) => {
                // Given that the hex color input is focusable (user needs to
                // focus on input to be able to edit value, right?), it removes
                // the popover text selection because of that reason, so in that
                // case we immediately restore the selection so that the text
                // style mutation actually occurs, if there is nothing selected,
                // there's nothing to apply the style to.
                if (causedByHexInput) {
                  colorPickerContentRef.current.disableInputBlur();
                  _restoreSelection();
                }

                // We need to run this function to update lastRange every time
                // we somehow modify the content, because the selection is not
                // the same thing after the mutation.
                // (Look at code that runs right before calling
                // "updateLastRange" function in this file)
                function updateLastRange() {
                  lastRange = editor.selection.get().getRangeAt(0);
                }

                const sectionName = $palettePopup.currentSection;

                // Here using "editor.commands.exec" method to run the style
                // changes as a command, one difference is that when you run
                // it as a command you get all the benefits of a command, like
                // making the change undoable.
                const applyHistoryChange = () => {
                  if (sectionName === 'text-color') {
                    editor.commands.exec('textColor', [hex]);
                  } else {
                    editor.commands.exec('backgroundColor', [hex]);
                  }

                  // Calling this because we modified content ^
                  updateLastRange();
                };

                if (causedByHexInput) {
                  applyHistoryChange();
                  // Focusing input back once we've applied the change if it was
                  // triggered by the hex input
                  editor.events.disableBlur();
                  inputRef.current.focus();
                } else {
                  // Here directly calling update methods because I don't want
                  // to update the changes history each time the color picker
                  // changes One example of when this handler executes many
                  // times is when you drag the saturation pointer or the hue
                  // slider.
                  if (sectionName === 'text-color') {
                    editor.colors.text(hex);
                  } else {
                    editor.colors.background(hex);
                  }

                  // Calling this because we modified content ^
                  updateLastRange();

                  clearTimeout(debouncedRef.current);
                  // In this case I want the color change only to be undoable
                  // when the user has not changed the color at all for 250
                  // milliseconds (debouncing).
                  debouncedRef.current = setTimeout(() => {
                    applyHistoryChange();
                  }, 250);
                }

                $palettePopup.updateColors((prev) => ({
                  ...prev,
                  [sectionName]: hex,
                }));

                setActiveColor(hex);
              }}
            />
        );
      };

      const paletteContainer = $palettePopup.find(`#${paletteId}`).get(0);
      const pickerContainer = $pickerPopup.find(`#${pickerId}`).get(0);

      ReactDOM.render((
        <Wrapper>
          <ColorPalette />
        </Wrapper>
      ), paletteContainer);
      ReactDOM.render((
        <Wrapper>
          <ColorPicker />
        </Wrapper>
      ), pickerContainer);

      editor.events.on('destroy', () => {
        editor.$sc.off('scroll', scrollListener);
        $pickerPopup.get(0).removeEventListener('mousedown', mouseDownCaptureListener, true);
        ReactDOM.unmountComponentAtNode(paletteContainer);
        ReactDOM.unmountComponentAtNode(pickerContainer);
        delete $.FE.POPUP_TEMPLATES[PALETTE_POPUP_NAME];
        delete $.FE.POPUP_TEMPLATES[PICKER_POPUP_NAME];
      });

      editor.events.on(`popups.hide.${PICKER_POPUP_NAME}`, () => {
        lastRange = undefined;
        updateRecentlyUsedColors();
      });

      // _addAccessibility($popup);

      return {
        $palettePopup,
        $pickerPopup,
      };
    }

    /*
     * Show the current selected color.
     */
    function _initializeReactColorState() {
      const $palettePopup = editor.popups.get(PALETTE_POPUP_NAME);
      let $element = $(editor.selection.element());

      // Find the selected color.
      let backgroundColorValue = null;
      let colorValue = null;
      while (true) {
        if (editor.$el.find($element).length) {
          const backgroundColor = $element.css('background-color');
          const color = $element.css('color');

          if ((backgroundColor !== undefined) && (backgroundColor !== 'transparent') && (backgroundColor !== TRANSPARENT_RGBA) && !backgroundColorValue) {
            backgroundColorValue = editor.helpers.RGBToHex(backgroundColor);
          }

          if (color !== undefined && !colorValue) {
            colorValue = editor.helpers.RGBToHex(color);
          }

          if (colorValue && backgroundColorValue) {
            break;
          }

          $element = $element.parent();
        } else {
          break;
        }
      }

      $palettePopup.updateColors({
        'text-color': colorValue,
        'background-color': backgroundColorValue,
      });
    }

    /*
     * Change background color.
     */
    function background(val) {
      if (val) {
        editor.format.applyStyle('background-color', editor.helpers.HEXtoRGB(val));
      } else {
        editor.format.removeStyle('background-color');
      }
    }

    /*
     * Change text color.
     */
    function text(val) {
      if (val === editor.opts.defaultTextColor) {
        editor.format.removeStyle('color');
      } else {
        editor.format.applyStyle('color', editor.helpers.HEXtoRGB(val));
      }
    }

    return {
      showColorsPopup,
      hideColorsPopup,
      background,
      text,
    };
  };

  // Toolbar colors button.
  $.FE.RegisterCommand('color', {
    title: 'Colors',
    undo: false,
    focus: true,
    refreshOnCallback: false,
    popup: true,
    callback() {
      this.colors.showColorsPopup();
    },
    plugin: 'colors',
  });

  // Select text color command.
  $.FE.RegisterCommand('textColor', {
    undo: true,
    callback(cmd, val) {
      this.colors.text(val);
    },
  });

  // Select background color command.
  $.FE.RegisterCommand('backgroundColor', {
    undo: true,
    callback(cmd, val) {
      this.colors.background(val);
    },
  });

  const colorIconSrc = prodPathReplace('images/icon-colorpicker.png');

  $.FroalaEditor.DefineIcon('color', { SRC: colorIconSrc, ALT: 'color picture button', template: 'image' });
}));
