import { css } from '@emotion/react';
import t from 'react-translate';
import { pick } from 'underscore';
import humps from 'humps';
import prodPathReplace from 'shared/prod-path-rewrite';

import { primary, yellow, gray6, gray5 } from 'styles/global_defaults/colors';

import {
  largeSpacing, halfSpacing, quarterSpacing, doubleSpacing,
} from 'styles/global_defaults/scaffolding';
import { textSmallFontSize, textXSFontSize, semiBoldFontWeight } from 'styles/global_defaults/fonts';
import { NLectureComponent, StyledLinkType } from 'redux/schemas/models/lecture-component';


function LhsCardStyledLink() {
  const iconSize = largeSpacing;
  return (
    <div css={css`
      display: flex;
      align-items: center;
      box-shadow: 0px 0px 20px ${gray5};
      border-radius: 6px;
      min-height: ${doubleSpacing}px;

      .icon {
        display: flex;
        align-self: flex-start;
        border-radius: 50%;
        margin-top: -${iconSize / 3}px;
        margin-left: -${iconSize / 3}px;
        background-color: ${yellow};
        color: #FFFFFF;

        .icon-section {
          border-radius: 50%;
          box-shadow: 0px 0px 10px ${gray5};
          align-items: center;
          justify-content: center;
          display: flex;
          width: ${iconSize}px;
          height:${iconSize}px;
        }
      }

      .title {
        padding: ${halfSpacing}px;
        word-break: break-word;

        span {
          padding: 0px;
          font-size: ${textXSFontSize}px;
          font-weight: ${semiBoldFontWeight};
          border: 0px;
          background-color: white;
        }

      }

      .image-container {
        display: flex;
        align-self: stretch;
        min-width: 50px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        background-color: ${gray6};
      }

      .styled-link-default-image {
        background-image: url(${prodPathReplace('images/styled_link_sample.png')});
      }
  `}
    >
      <div className='icon'>
        <div className='icon-section'>
          <div className='icon-admin-embeds icon-xss-smallest-size' />
        </div>
      </div>
      <div className='title'>
        <span>{t.LECTURE_PAGES.COMPONENTS.STYLED_LINK_CARD.BLURB()}</span>
      </div>
      <div className='image-container styled-link-default-image' />
    </div>
  );
}

function LhsButtonStyledLink() {
  return (
    <div css={css`
    display:flex;
    align-items: center;

    color: #FFF;
    background-color: ${primary};

    padding: ${halfSpacing}px;
    border-radius: ${quarterSpacing}px;
    height: ${doubleSpacing}px;

    .title {
      margin-left: ${halfSpacing}px;

      span {
        font-size: ${textSmallFontSize}px;
      }
    }`}
    >
      <div className='icon-open-external icon-xss-smallest' />
      <div className='title'>
        <span>{t.LECTURE_PAGES.COMPONENTS.STYLED_LINK_BUTTON.BLURB()}</span>
      </div>
    </div>
  );
}

const LhsStyledComponent = (props: { lectureComponent: NLectureComponent<StyledLinkType> }) => {
  // eslint-disable-next-line new-cap
  const modelInstance = humps.camelizeKeys(pick(props.lectureComponent, 'viewOptions', 'header', 'content')) as any;

  const styles = css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  `;

  return (
    <div css={styles}>
      { modelInstance.viewOptions.styleType === 'button'
        ? <LhsButtonStyledLink />
        : <LhsCardStyledLink />}
    </div>
  );
};

export default LhsStyledComponent;
