import { Permission, User } from './my-account';
import { Role } from './role';

export enum OrgAdminRoles {
  ORG_ADMIN = 'org_admin',
  COURSE_MANAGER = 'course_manager',
}

export enum PermissionOptions {
  LEARNER = 'learner',
  CUSTOM_ROLE = 'custom_role',
  CONFIGURATION_REGISTRATION = 'configuration_registration',
  LEARNER_REGISTRATION = 'learner_registration',
  INSTRUCTOR = 'instructor',
  TEACHER_ASSISTANT = 'teacher_assistant',
  COURSE_BUILDER = 'course_builder',
  REPORT_ADMIN = 'report_admin',
  MENTOR = 'mentor',
}

export interface RolePermission extends Permission{
  name: string
}
export interface CourseRole extends Role {
  permissions: RolePermission[]
  isUsed?: boolean
}
