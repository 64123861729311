import mergeWith from 'lodash/mergeWith';
import { replaceArrays } from 'shared/lodash-utils';

export default {
  restrict: 'A',
  bindings: {
    context: '@', // [ 'lectureVideo', 'submission', 'lecture', 'directLink', 'workspace', 'workspaceDirectLink'],
    directLinkInfo: '<',
    expandComments: '<?',
    hideDirectLink: '<',
    hideVideo: '<?', // used in lecture pages because we dont' want to show the video
    newCommentUi: '<?', // needed for discussion post backwards compatibility
    newCommentMode: '=?',
    onFetchNextComments: '&',
    onFetchPreviousComments: '&',
    post: '<',
    previewMode: '<?',
    readonlyMode: '<?',
    showNewCommentPrompt: '<?',
    inModal: '<?',
  },
  transclude: {

  },
  controller: function ctrl(
    $attrs,
    $scope,
    $translate,
    $uibModalStack,
    CurrentCourseManager,
    CurrentUserManager,
    ScrollFocusConnectorFactory,
    TimelinesManager,
    ReactTimelineService,
    DiscussionsManager,
    _,
    $element,
    config,
  ) {
'ngInject';
    const vm = this;
    vm.config = config;

    vm.commentsSort = 'date';

    // functions
    vm.fetchComments = fetchComments;
    vm.sortCommentsByLikes = sortCommentsByLikes;
    vm.sortCommentsByDate = vm.fetchComments;
    vm.closeModal = closeModal;
    vm.updateComment = updateComment;
    vm.deleteComment = deleteComment;
    vm.CurrentCourseManager = CurrentCourseManager;
    vm.CurrentUserManager = CurrentUserManager;
    vm.DiscussionsManager = DiscussionsManager;
    vm.isContentManagementCollection = vm.CurrentCourseManager?.course?.isContentManagementCollection;
    vm.currentTeamCourse = vm.CurrentUserManager.getCurrentCourse();

    vm.shouldExpandCommentsOnLoad = shouldExpandCommentsOnLoad;
    vm.isFirstChange = false;
    vm.getPlaceholderTextFromContext = getPlaceholderTextFromContext;

    vm.newCommentMode = vm.newCommentUi;
    vm.updateAvatar = true;
    if (vm.expandComments || vm.shouldExpandCommentsOnLoad()) {
      vm.fetchComments(false, false, false);
    }

    $scope.$watch(vm.post && 'vm.post.updateAvatar', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        vm.updateAvatar = newValue;
      }
    });

    this.$onChanges = function (changes) {
      if ((changes.context || changes.expandComments)) {
        if (changes.context) {
          if (!changes.context.isFirstChange()) {
            vm.context = changes.context.currentValue;
          } else {
            vm.isFirstChange = true;
          }
        }

        if (changes.expandComments && !changes.expandComments.isFirstChange()) {
          vm.expandComments = changes.expandComments.currentValue;
        }

        if ((changes.context && !changes.context.isFirstChange()
              && (changes.context.currentValue === 'directLink' || changes.context.currentValue === 'workspaceDirectLink'))
              || (changes.expandComments && !changes.expandComments.isFirstChange() && changes.expandComments.currentValue)) {
          vm.fetchComments(vm.newCommentMode, false, false);
        }
      }

      if (changes.showNewCommentPrompt && !changes.showNewCommentPrompt.isFirstChange()) {
        vm.showNewCommentPrompt = changes.showNewCommentPrompt.currentValue;
      }

      if (changes.newCommentUi) {
        vm.newCommentUi = changes.newCommentUi.currentValue;

        vm.newCommentMode = vm.newCommentUi;
      }
    };

    this.$postLink = function () {
      vm.mentionMenuTemplateUrl = $attrs.mentionMenuTemplateUrl;
    };

    let commentsReady = 0;
    let commentsCount;


    this.onCommentReady = function () {
      commentsReady += 1;

      if (commentsReady === commentsCount) {
        const images = $element[0].querySelectorAll('[nv-compile-always] img');
        let settledImages = 0;

        const checkFinalization = () => {
          if (images.length === settledImages) {
            // When this event is broadcasted, it means that at that point
            // it's safe to say that the entire discussion is ready as we have
            // settled all the images (either load or error) and therefore a
            // paint has occurred. One case for what this is useful is for
            // knowing when to automatically scroll to a specific comment or
            // reply of the discussion since given that images are loaded
            // asynchronously the scroll may be innacurate if we scroll before
            // everything is loaded.
            $scope.$broadcast('discussion-thread-ready');
          }
        };

        images.forEach((image) => {
          if (image.complete) {
            // This is added because once an image has been loaded, it remains
            // in the cache, so it doesn't RELOAD the image. Now we check
            // if images are already there, and if so, the value of
            // settledImages increases. Finally we call checkFinalization().
            settledImages += 1;
          } else {
            const settleImage = () => {
              settledImages += 1;
              requestAnimationFrame(() => {
                checkFinalization();
              });

              image.removeEventListener('load', settleImage);
              image.removeEventListener('error', settleImage);
            };

            image.addEventListener('load', settleImage);
            image.addEventListener('error', settleImage);
          }
        });

        checkFinalization();
      }
    };


    function sortCommentsByLikes() {
      vm.fetchComments(false, true, false);
    }

    function closeModal() {
      $uibModalStack.dismissAll('cancel');
    }

    function fetchComments(newCommentUi, sortByLikes, scrollToComments) {
      if (!vm.post.fetchingComments) {
        if ((vm.post.comments.length < vm.post.postsCount && !vm.post.commentsFetched)
              || (sortByLikes && vm.commentsSort === 'date')
              || (!sortByLikes && vm.commentsSort === 'likes')) {
          // Load 15 comments by default, 5 comments on subsequent loads
          vm.post.fetchCommentsForPost(sortByLikes, vm.directLinkInfo ? vm.directLinkInfo.commentId : null, 15).then((commentsList) => {
            // apparently $onChanges doesn't get fired for subproperties so have to manually set the comments list
            // this is hacky and shouldn't be done according to the one-way bindings paradigm in components
            // but otherwise would have to bubble all the way up to the main controller
            vm.post.comments = commentsList;
            commentsCount = commentsList.length;

            if (newCommentUi) {
              vm.newCommentUi = true;
              ScrollFocusConnectorFactory.scrollTo(vm.post.id, 'post');
            } else {
              $.noop();
            }
          });
        } else if (newCommentUi) {
          vm.newCommentUi = true;

          ScrollFocusConnectorFactory.focus(vm.post.id, 'post');
        } else {
          $.noop();
        }
      }

      /**
       * Updating the discussion manager with the fetched comments. This
       * `DiscussionsManager.currentPosts` is used in lecture page discussion
       * pusher handlers.
       */
      const existingPost = _.findWhere(DiscussionsManager?.currentPosts, { id: vm.post.id });
      if (!_.isEmpty(existingPost)) {
        mergeWith(existingPost, vm.post, replaceArrays);
      } else {
        vm.DiscussionsManager.currentPosts.push(vm.post);
      }
    }


    function getPlaceholderTextFromContext() {
      if (vm.context === 'lecture' || vm.context === 'lectureVideo') {
        return 'DISCUSSIONS.BE_THE_FIRST_TO_PARTICIPATE';
      }
      return 'DISCUSSIONS.BE_THE_FIRST_TO_POST';
    }

    function shouldExpandCommentsOnLoad() {
      if (vm.context === 'lectureVideo' || vm.context === 'directLink' || vm.context === 'workspaceDirectLink') {
        return true;
      }

      // TODO; direct link *should* be expanded by default
      return false;
    }


    function updateComment(comment) {
      vm.existingCommentToEdit = comment;
      vm.newCommentUi = true;


      if (vm.commentActionCallback) {
        vm.commentActionCallback(comment, 'edit');
      }
    }

    function deleteComment(comment) {
      vm.post.removeComment(comment).then(() => {
        if (vm.commentActionCallback) {
          vm.commentActionCallback(comment, 'delete');
        }

        if (vm.post.metaContent?.lecturePage) {
          ReactTimelineService.updateTimeline(vm.post.metaContent.lecturePage.id);
          TimelinesManager.updateComponentPointsAndProgress(vm.post.metaContent.lecturePage.id, 'topic', vm.post.id, vm.post.pointsReceived, null, vm.post.progress);
        }
      });
    }
  },
  controllerAs: 'vm',
  templateUrl: 'shared/templates/nv-post-container.html',
};
