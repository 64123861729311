import { updateOrgLevelColors } from 'redux/actions/org-level-colors';
import { createReducer } from '@reduxjs/toolkit';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { initialRootState } from '.';

export default createReducer(initialRootState, builder => {
  builder
    .addCase(updateOrgLevelColors.fulfilled, (state, action) => {
      const currentInstitution = getCurrentInstitution(state);

      Object.assign(currentInstitution, action.payload);
    });
});
