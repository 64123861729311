import React, { useEffect, useState } from 'react';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { setBottomBarVisibility, setLeftPanelAiMode } from 'redux/actions/lecture-pages';
import { getCurrentLecture, getNovoAIProperties } from 'redux/selectors/lecture-page';
import { getCourseAliases } from 'redux/selectors/course';
import { ComponentType, LectureComponentCommon } from 'redux/schemas/models/lecture-component';
import { NLecturePage } from 'redux/schemas/models/lecture-page';
import { RootState } from 'redux/schemas';
import { CourseAliases } from 'redux/schemas/models/course';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import ClickableContainer from 'components/clickable-container';
import { hideAddUI } from 'lecture_pages/templates/components/nv-add-component';
import { config } from '../../../../config/pendo.config.json';

type RegenerateAIParams = {
  lectureComponent: LectureComponentCommon<any>,
};

const RegenerateAIComponent = ({
  lectureComponent,
}: RegenerateAIParams) => {
  const dispatch = useAppDispatch();
  const currentLecture: NLecturePage = useSelector(getCurrentLecture);
  const aliases: CourseAliases = useSelector((state: RootState) => getCourseAliases(state));
  const novoAIProperties = useSelector(getNovoAIProperties);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = () => {
    hideAddUI(dispatch);
    setTimeout(() => {
      dispatch(setBottomBarVisibility(false));
      dispatch(setLeftPanelAiMode({
        itemType: lectureComponent.aiOrigin,
        content: undefined,
        itemTrueType: lectureComponent.trueType,
        lectureComponentToReplace: lectureComponent,
      }));
    });
  };

  useEffect(() => {
    if (
      // Disabling the regenerate button if the component it's being regenerating
      novoAIProperties?.lectureComponentToReplace
      // Or disabling if the quiz LC is inside a released lesson
      || ([ComponentType.QUIZ, ComponentType.PROGRESSIVE_QUIZ].includes(lectureComponent.trueType)
      && currentLecture?.released)
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [currentLecture?.released, lectureComponent.trueType, novoAIProperties?.lectureComponentToReplace]);

  const getTooltipText = () => {
    switch (lectureComponent.trueType) {
      case ComponentType.QUIZ:
      case ComponentType.PROGRESSIVE_QUIZ:
        return isDisabled
          ? t.LECTURE_PAGES.COMPONENTS.QUIZ.REGENERATE_WITH_AI_DISABLE({ lectureAlias: aliases.lectureAliases.lectureAlias })
          : t.LECTURE_PAGES.COMPONENTS.QUIZ.REGENERATE_WITH_AI();
      case ComponentType.TOPIC:
        return t.LECTURE_PAGES.COMPONENTS.DISCUSSION.REGENERATE_WITH_AI();
      default:
        return t.LECTURE_PAGES.COMPONENTS.AI_REGENERATE();
    }
  };

  return (
    <NvTooltip
      // Disabling when starting the AI regeneration flow.
      enabled={!novoAIProperties?.lectureComponentToReplace}
      text={getTooltipText()}
    >
      <div>
        <ClickableContainer
          onClick={handleClick}
          aria-label={t.LECTURE_PAGES.COMPONENTS.AI_REGENERATE_ARIA_LABEL()}
          disabled={isDisabled}
          isFocusable={!isDisabled}
          data-qa={config.pendo.novoAi.regenerate}
        >
          <NvIcon
            icon='gen-ai'
            size='xss-smallest'
            className={`text-${isDisabled ? 'gray-4' : 'primary'}`}
          />
        </ClickableContainer>
      </div>
    </NvTooltip>
  );
};

export default RegenerateAIComponent;
