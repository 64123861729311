import React from 'react';
import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import NvUserAvatar from 'components/nv-user-avatar';
import { gray2, highTide, success, warning } from 'styles/global_defaults/colors';
import { User } from 'redux/schemas/models/my-account';
import StatusBar from './status-bar';

const PeerComparison = (props: {
  fillerPercentage: number,
  peerAverage: number,
  user: User,
}) => {
  let peerComparisonKey = '';
  let comparisonColor = '';
  const maxValue = Math.max(props.fillerPercentage, props.peerAverage);

  if (props.fillerPercentage < props.peerAverage) {
    peerComparisonKey = 'BELOW';
    comparisonColor = success;
  } else if (props.fillerPercentage > props.peerAverage) {
    peerComparisonKey = 'ABOVE';
    comparisonColor = warning;
  } else {
    peerComparisonKey = 'EQUAL';
    comparisonColor = highTide;
  }

  return (
    <div>
      <div className='mb-2 text-gray-1'>
        {t.PRACTICE_ROOM.INSIGHTS.FILLER_WORDS.PEER_COMPARISON[peerComparisonKey](
          props.fillerPercentage.toString(),
          props.peerAverage.toString(),
        )}
      </div>
      <div className='d-flex align-items-center mr-1 mb-2'>
        <NvUserAvatar
          borderType='round'
          size='xs'
          user={props.user}
          className='mr-2'
          displayRoleBadge={false}
        />
        <StatusBar
          maxValue={maxValue}
          currentValue={props.fillerPercentage}
          activeColor={comparisonColor}
        />
        <div className='label text-black ml-2'>
          {`${props.fillerPercentage}%`}
        </div>
      </div>
      <div className='d-flex align-items-center'>
        <NvIcon size='small' icon='g-groups' className='mr-2' />
        <StatusBar
          maxValue={maxValue}
          currentValue={props.peerAverage}
          activeColor={gray2}
        />
        <div className='label text-black ml-2'>
          {`${props.peerAverage}%`}
        </div>
      </div>
    </div>
  );
};

export default PeerComparison;
