import NvSignUp, { SignInExistingAccount } from '../../shared/components/sign-up/nv-sign-up';
import OrDivider from '../../shared/components/sign-in/nv-or-divider';

/* @ngInject */
export default function UserSignUpCtrl(
  CurrentUserManager,
  CurrentCourseManager,
  UserAuthentication,
  requestInstitution,
  validationConstants,
  $state,
  $stateParams,
  config,
  _,
  vcRecaptchaService,
  $scope,
) {
  const vm = this;
  let widgetId;

  $scope.NvSignUp = NvSignUp;
  $scope.OrDivider = OrDivider;
  $scope.SignInExistingAccount = SignInExistingAccount;

  vm.CurrentUserManager = CurrentUserManager;
  vm.user = CurrentUserManager.user;
  vm.course = CurrentCourseManager.course;
  vm.currentInstitution = requestInstitution;
  vm.UserAuthentication = UserAuthentication;
  vm.validationConstants = validationConstants;
  vm.params = $state.params;
  vm.config = config;
  vm.signUp = signUp;

  vm.onWidgetCreate = (_widgetId) => {
    vm.widgetId = _widgetId;
  };

  function signUp() {
    vm.user.authActivityInProgress = true;
    vm.recaptchaError = false;

    CurrentUserManager.signUp($stateParams, vm.recaptchaResponse).then(
      () => {
        UserAuthentication.redirectAfterAuthentication();
      },
      (response) => {
        vm.user.authActivityInProgress = false;
        vcRecaptchaService.reload(widgetId);

        if (response.data.error.code === 'login.errors.recaptcha') {
          vm.recaptchaError = true;
        } else if (response.data.error.code === 'login.errors.user_already_exists') {
          const params = _.clone(vm.params);
          params.alreadyJoined = true;
          $state.go('users.authentications.sign-in', params);
        }
      },
    );
  }
}
