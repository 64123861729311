/* @ngInject */
export default function NvDiscussionAvatar(
  _,
  $translate,
  InteroperabilityRoutes,
  $state,
  CourseRolesManager,
  CurrentCourseManager,
  RolesService,
  config,
  CurrentUserManager,
) {
  return {
    restrict: 'A',
    scope: {
      size: '@', // options: [ sm, lg, xl ] = [ 32px, 40px, 60px ]
      user: '=',
      roleInfo: '=', // TODO: Refactor this to 'role'
      showUserName: '=',
      showUserNameInTooltip: '=',
      inlineName: '=',
      includeSpacing: '=?',
      adminActionsContent: '=?',
      updateBindingsImmediately: '=',
      directToProfile: '=?',
      userNameTooltipPlacement: '@',
      notification: '=?',
      messages: '=?',
      removeSizeWrapper: '=?',
    },
    transclude: true,
    link(scope) {
      scope.clickedOnHandheld = false;
      scope.profileLink = null;
      scope.config = config;

      scope.notificationContent = '';
      if (Number.isInteger(scope.notification)) {
        scope.notificationIsNumber = true;
        scope.notificationContent = scope.notification;
      }

      if (!scope.userNameTooltipPlacement) {
        scope.userNameTooltipPlacement = 'auto bottom';
      }

      scope.mapSizeToPx = mapSizeToPx;
      scope.getTranslatedRole = getTranslatedRole;
      scope.getProfileLink = getProfileLink;
      scope.toggleTooltipClickHandheld = toggleTooltipClickHandheld;
      scope.preventEventBubbling = preventEventBubbling;

      updateRole();
      getProfileLink(scope.user);

      scope.$watch('user', (newValue, oldValue) => {
        if (newValue && (!oldValue || newValue.id !== oldValue.id)) {
          updateRole();
          getProfileLink(newValue);
        }
      });

      scope.$watch('showUserName', (newValue) => {
        scope.nameVisible = !!newValue || (newValue === undefined);
      });

      scope.getBadgeClass = (role) => {
        if (!role) {
          return '';
        } if (RolesService.isAdminRole(role)) {
          return 'admin';
        } if (RolesService.isMentor(role)) {
          return 'mentor';
        }
        return 'learner-role';
      };

      // TODO: Remove after refactor to 'role'
      function updateRole() {
        if (scope.roleInfo) {
          scope.role = scope.roleInfo;
          getTranslatedRole();
        }
      }

      function mapSizeToPx() {
        switch (scope.size) {
          case 'sm':
            return '30px';
          case 'lg':
            return '40px';
          case 'xl':
            return '60px';
          default:
            return scope.size;
        }
      }

      function getTranslatedRole() {
        if (!scope.role || !_.keys(CourseRolesManager.roles.length)) {
          return;
        }

        CourseRolesManager.requestCourseRolesPromise.then(() => {
          let courseRole = null;

          if (scope.role.permission) {
            courseRole = CourseRolesManager.roles[scope.role.courseRoleId];
          }

          if (courseRole) {
            scope.roleName = scope.role.role;
            scope.roleAbbreviation = courseRole.badge;
          } else if (RolesService.isMentor(scope.role.isMentor)) {
            scope.roleName = $translate.instant('DISCUSSIONS.ROLES.MENTOR');
            scope.roleAbbreviation = 'M';
          }
        });
      }

      function toggleTooltipClickHandheld($event) {
        $event.stopPropagation(); // prevent the click from triggering the link
        scope.clickedOnHandheld = !scope.clickedOnHandheld;
      }

      function getProfileLink(user) {
        // Getting current course for setting profile link URL
        // Reason: We can open portable team workspace / team profile on top of a different course or without a course and
        // profile link needed to be based on the currently selected course
        let applicableCourse = null;
        if ($state.params?.catalogId
          && $state.params.catalogId !== CurrentCourseManager?.course?.catalogId
          && CurrentUserManager.coursesHashByCatalogId[$state.params.catalogId]) {
          // If currentCourseManager course is not set or isn't the same as the state catalogId
          // fetch course from the state and use that in profile profile url
          applicableCourse = CurrentUserManager.coursesHashByCatalogId[$state.params.catalogId];
        } else {
          // If state doesn't include a catalogId or it match with currentCourseManager
          applicableCourse = CurrentCourseManager.course;
        }

        const isInLearningJourneys = $state.includes('learning-journey');

        scope.profileLink = scope.directToProfile
          ? $state.href(`${(isInLearningJourneys && CurrentCourseManager.course?.isJourney) ? 'org-level' : 'learner'}-profile-modal`, { catalogId: applicableCourse ? applicableCourse.catalogId : null, userId: user.id })
          : null;
      }

      function preventEventBubbling($event) {
        $event.stopPropagation();
        $event.preventDefault();
      }
    },
    templateUrl: 'discussions/templates/nv-discussion-avatar.html',
  };
}
