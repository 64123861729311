import React from 'react';
import t from 'react-translate';

// Redux
import { useAppDispatch } from 'redux/store';
import { doValidateDomain } from 'redux/actions/sign-in';
import { DomainResponse } from 'redux/schemas/api/sign-in';

// Styles
import { css } from '@emotion/react';
import { gray3 } from 'styles/global_defaults/colors';
import {
  semiBoldFontWeight,
  textSmallFontSize,
  textSmallLineHeight,
} from 'styles/global_defaults/fonts';

// Form
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

// Components
import { Button } from 'react-bootstrap';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import GoBackTitle from './nv-go-back-title';

type Props = {
  domain: string,
  email: string,
  onSuccess: (host: string) => void,
  onError?: () => void,
  goBack: () => void,
};

const EnterSubdomain = (props: Props) => {
  const {
    domain,
    email,
    onSuccess,
    onError,
    goBack,
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [domainNotFound, setDomainNotFound] = React.useState<boolean>(false);
  const [ssoNotEnabled, setSsoNotEnabled] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  const validationSchemaRef = React.useRef(yup.object().shape({
    subdomain: yup.string()
      .min(1, t.VALIDATION.REQUIRED()),
  }));

  const methods = useForm({
    mode: 'onChange',
    shouldUnregister: true,
    resolver: yupResolver(validationSchemaRef.current),
  });

  const { handleSubmit, formState, setError } = methods;

  const submit = ({ subdomain }) => {
    setDomainNotFound(false);
    setSsoNotEnabled(false);
    setIsLoading(true);
    dispatch(doValidateDomain({ domain: subdomain }))
      .then((action) => {
        if (action.payload.error || action.payload.data?.error) {
          setDomainNotFound(true);
          setError('subdomain', { message: t.FORM.ERRORS.DOMAIN_NOT_FOUND() });
          onError?.();
        } else {
          const { payload }: { payload: DomainResponse } = action;
          const { host, institution } = payload;
          if (email || institution?.ssoLogin) onSuccess(host);
          else {
            setSsoNotEnabled(true);
            setError('subdomain', { message: t.FORM.ERRORS.SSO_NOT_ENABLED() });
            onError?.();
          }
        }
        setIsLoading(false);
      });
  };

  const styles = css`
    .domain-input {
      flex: 1;
    }
    .domain-label {
      font-weight: ${semiBoldFontWeight};
      font-size: ${textSmallFontSize}px;
      line-height: ${textSmallLineHeight}px;
      color: ${gray3};
    }
  `;

  return (
    <React.Fragment>
      <div css={styles} className='authentications-overlay'>
        <div className='authentications-body'>
          <div className='authentications-header-text d-flex pb-5'>
            <GoBackTitle
              title={t.SHARED.ENTER_YOUR_SUB_DOMAIN()}
              onBack={goBack}
            />
          </div>
          {
            domainNotFound && (
              <div className='error'>{t.FORM.ERRORS.DOMAIN_NOT_FOUND()}</div>
            )
          }
          {
            ssoNotEnabled && (
              <div className='error'>{t.FORM.ERRORS.SSO_NOT_ENABLED()}</div>
            )
          }
          <div className='registration-form'>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(submit)}>
                <div className='d-flex mt-5 mb-4 align-items-center justify-content-between'>
                  <NvTextInput
                    required
                    withForm
                    name='subdomain'
                    className='mr-1 domain-input'
                    placeholder={t.SHARED.DOMAIN()}
                    data-qa='subdomain'
                  />
                  <span className='domain-label'>{domain}</span>
                </div>
                <Button
                  className='mb-0 mt-2'
                  disabled={!formState.isValid || isLoading}
                  type='submit'
                  data-qa='next'
                >
                  {t.SHARED.NEXT()}
                </Button>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EnterSubdomain;
