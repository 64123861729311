import React from 'react';

type FocusEventType = 'blur' | 'focus';

const mapFocusEventTypeToRealEvent = (type: FocusEventType) => {
  if (type === 'blur') {
    return 'focusout';
  }

  return 'focusin';
};

/**
 * Hook that allows you to attach focusin and focusout events to element, as
 * react doesn't allow you to do that through props.
 * The main motivation to consider using focusin and focusout events is that
 * they propagate while onBlur and onFocus don't.
 */
const useFocusEvent = (
  elementRef: React.RefObject<HTMLDivElement>,
  type: FocusEventType,
  handler: React.FocusEventHandler,
  dependencies: React.DependencyList | undefined,
  capture = false,
) => {
  const handlerRef = React.useRef(handler);
  handlerRef.current = handler;

  const { current: element } = elementRef;

  React.useEffect(() => {
    const actualHandler = (e) => {
      handlerRef.current(e);
    };
    const eventName = mapFocusEventTypeToRealEvent(type);

    if (element) {
      element.addEventListener(eventName, actualHandler, capture);
    }

    return () => {
      if (element) {
        element.removeEventListener(eventName, actualHandler, capture);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, capture, type, element]);
};

export default useFocusEvent;
