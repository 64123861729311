import { css } from '@emotion/react';
import { gray5, gray6, white } from 'styles/global_defaults/colors';
import { cardOverlaySize, cardSize, halfSpacing, longBar, quarterSpacing, shortBar, standardSpacing } from 'styles/global_defaults/scaffolding';

export enum PlaceholderType {
  LINE = 'line',
  AVATAR = 'avatar',
  PANEL = 'panel',
}

export enum Shape {
  ROUND = 'round',
  SQUARE = 'square',
}

interface BaseProps {
  type: PlaceholderType,
  width?: string,
}

export enum AvatarSize {
  EXTRA_SMALL = '20px',
  SMALL = '30px',
  MEDIUM = '40px',
  LARGE = '60px',
  EXTRA_LARGE = '100px',
}

export enum LineSize {
  SHORT = 'short',
  FULL = 'full',
  MEDIUM = 'medium',
}

export enum LineHeight {
  HALF_SPACING = halfSpacing,
  QUARTER_SPACING = quarterSpacing,
}

const mapLineSize: {[Key in LineSize]: string} = {
  [LineSize.FULL]: '100%',
  [LineSize.SHORT]: '50%',
  [LineSize.MEDIUM]: '60%',
};

interface AvatarPlaceHolderProps extends BaseProps {
  size: AvatarSize,
  className?: string,
  shape?: Shape,
  height?: LineHeight
}

interface LinePlaceHolderProps extends BaseProps {
  size: LineSize,
  className?: string,
  shape?: Shape,
  height?: LineHeight,
}

interface PanelPlaceholderProps {
  width: string,
  height: string,
}

type NvLoadingPlaceholderProps = LinePlaceHolderProps | AvatarPlaceHolderProps ;

const NvLoadingPlaceholder = (props: NvLoadingPlaceholderProps) => {
  const { size, type, shape, height, className = '' } = props;

  const getStyles = (placeHolder: PlaceholderType) => {
    switch (placeHolder) {
      case PlaceholderType.AVATAR:
        return css`
          width: ${size};
          height: ${size};
          margin: auto;
          border-radius: ${shape === Shape.ROUND ? '50%' : '5%'};
        `;
      case PlaceholderType.LINE:
        return css`
          width: ${mapLineSize[size]};
          height: ${height ?? halfSpacing}px;
        `;
      case PlaceholderType.PANEL:
        return css`
          width: ${size};
          height: ${size};
        `;
      default:
        return '';
    }
  };

  return (
    <div
      css={getStyles(type)}
      className={`loading ${type} gray-2 m-1 ${className}`}
      aria-hidden='true'
    />
  );
};

export const NVLoadingPlaceholderPanel = (props: PanelPlaceholderProps) => {
  const { width, height } = props;
  const styles = css`
    width: ${width};
    height: ${height};
    margin: auto;
  `;

  return <div css={styles} className='loading panel gray-2 m-1' aria-hidden='true' />;
};

export const NvLoadingPlaceholderCard = () => {
  const styles = css`
    width: ${cardSize}px;
    height: ${cardSize}px;
    border: 1px solid ${gray6};
    background: ${white};
    box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.10);

    .panel {
      margin: 0 !important;
      border: none;
      border-radius: 0px;
      box-shadow: none;
    }

    .rectangle {
      width: 100%;
      height: ${quarterSpacing}px;
      background: ${gray5};
    }

    .bars {
      width: 100%;
      height: calc(${cardSize}px - ${cardOverlaySize + quarterSpacing}px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: ${standardSpacing}px;

      .bar {
        width: ${longBar}px;
        height: ${quarterSpacing}px;
      }

      .short-bar {
        width: ${shortBar}px;
      }
    }
  `;

  return (
    <div css={styles} className='loading' aria-hidden='true'>
      <NVLoadingPlaceholderPanel height={`${cardOverlaySize}px`} width={`${cardSize}px`} />
      <div className='rectangle' />
      <div className='bars'>
        <div className='loading bar short-bar' />
        <div className='loading bar' />
        <div className='loading bar short-bar' />
      </div>
    </div>
  );
};

export default NvLoadingPlaceholder;
