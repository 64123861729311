
import { NovoEdFile } from 'shared/hooks/use-upload-file';
import _ from 'underscore';
import { ValuesType } from 'utility-types';
import { TimelineLectureVideosNormalized } from './lecture-video';
import { TimelineExercisesNormalized } from './exercise';
import { TimelineQuizzesNormalized } from './quiz';
import { TimelineTimedQuizzesNormalized } from './timed-quiz';
import { TimelinePeerEvaluationsNormalized } from './peer-evaluation';
import { TimelineLiveSessionsNormalized } from './live-session';
import { TimelineExternalToolsNormalized } from './external-tool';
import { TimelineTeamSetsNormalized } from './team-set';
import { TimelineGroupTeamSetsNormalized } from './group-team-set';
import { TimelineSurveysNormalized } from './survey';
import { TimelinePostsNormalized } from './timeline-post';
import { TeamDiscussionsNormalized } from './team-discussion';
import { CommunicationsNormalized } from './course-communication';
import { VideosNormalized } from './video';
import { LecturePage, NovoAIItemType } from './lecture-page';
import { Normalized } from '../normalized';
import { LectureComponentPayload, NovoAIPayload } from './activity';
import { FileUpload } from './file-uploads';
import { ProfileRequirementsNormalized } from './profile-requirement';
import { SkillTag } from './skill-tag';
import { VideoPracticeFeedbackActivity } from './video-practice-feedback';

export enum ComponentType {
  ACCORDION = 'AccordionLectureComponent',
  ACCORDION_SECTION = 'AccordionSection',
  ATTACHMENT = 'AttachmentLectureComponent',
  AUDIO = 'AudioListLectureComponent',
  EXERCISE = 'ExerciseLectureComponent',
  EXTERNAL_TOOL = 'ExternalToolLectureComponent',
  EXERCISE_SKILLS_RATING = 'ExerciseSkillsRatingLectureComponent',
  GROUP_FORMATION = 'GroupFormationLectureComponent',
  HEADER_STYLE1 = 'HeaderLectureComponent',
  HEADER_STYLE2 = 'HeaderTabLectureComponent',
  HEADER_STYLE3 = 'HeaderImageLectureComponent',
  IMAGE = 'ImageLectureComponent',
  LINE_DIVIDER = 'LineDividerLectureComponent',
  LIVE_SESSION = 'LiveSessionLectureComponent',
  MEET_AND_GREET = 'MeetAndGreetLectureComponent',
  POLL = 'PollLectureComponent',
  PRIVATE_PEER_EVALUATION = 'PrivatePeerEvaluationLectureComponent',
  PROFILE_COMPLETION = 'ProfileCompletionLectureComponent',
  PUBLIC_PEER_EVALUATION = 'PublicPeerEvaluationLectureComponent',
  QUIZ = 'QuizLectureComponent',
  RICH_TEXT = 'RichTextLectureComponent',
  STYLED_LINK = 'StyledLinkLectureComponent',
  SUBMISSION_DISCOVERY = 'SubmissionsDiscoveryLectureComponent',
  SURVEY = 'SurveyLectureComponent',
  TEAM_FORMATION = 'TeamFormationLectureComponent',
  TEAM_DISCUSSION = 'TeamDiscussionLectureComponent',
  TEXT_WITH_IMAGE_BKG = 'BlurbBackgroundImageLectureComponent',
  TEXT_WITH_IMAGE_SIDE = 'BlurbSideImageLectureComponent',
  TEXT_WITH_IMAGE_TOP = 'BlurbTopImageLectureComponent',
  TIMED_QUIZ = 'TimedQuizLectureComponent',
  TOPIC = 'DiscussionLectureComponent',
  VIDEO = 'VideoListLectureComponent',
  VIDEO_PRACTICE = 'VideoPracticeLectureComponent',
  VIDEO_PRACTICE_FEEDBACK = 'PublicPracticeFeedbackCriteriaLectureComponent',
  WHITE_SPACE = 'WhiteSpaceLectureComponent',
  VIDEO_PRACTICE_SKILLS_FEEDBACK = 'VideoPracticeSkillsRatingLectureComponent',
  PROGRESSIVE_QUIZ = 'ProgressiveQuizLectureComponent',
}

type ComponentContainerType = ComponentType.EXTERNAL_TOOL | ComponentType.RICH_TEXT | ComponentType.ACCORDION_SECTION | ComponentType.STYLED_LINK;

export enum RichTextType {
  SIMPLE = 'SimpleRichTextLectureComponent',
  FULL = 'FullRichTextLectureComponent',
}

export enum AccordionSectionType {
  STYLE_1 = 'MoreLessAccordionLectureComponent',
  STYLE_2 = 'MoreLessCircledAccordionLectureComponent',
  STYLE_3 = 'DecimalAccordionLectureComponent',
  STYLE_4 = 'AlphabeticalAccordionLectureComponent',
  STYLE_5 = 'CustomSymbolAccordionLectureComponent',
}

export enum ExternalToolType {
  WEB_EMBED = 'WebLinkExternalToolLectureComponent',
  WEB_LINK = 'NonEmbeddedWebLinkExternalToolLectureComponent',
  SCORM = 'ScormExternalToolLectureComponent',
  LTI = 'LtiExternalToolLectureComponent',
}

export enum StyledLinkType {
  BUTTON = 'ButtonStyledLinkLectureComponent',
  CARD = 'CardStyledLinkLectureComponent',
}

export type ComponentTrueType =
  Exclude<ComponentType, ComponentContainerType> |
  RichTextType |
  AccordionSectionType |
  ExternalToolType |
  StyledLinkType;

/* eslint-disable */
/** Maps ComponentTrueType to the corresponding ComponentType value.
 * IE, 'WebLinkExternalToolLectureComponent' -> ComponentType.EXTERNAL_TOOL */
export type TypeFromTrueType<C extends ComponentTrueType> =
  C extends RichTextType ? ComponentType.RICH_TEXT :
  C extends AccordionSectionType ? ComponentType.ACCORDION_SECTION :
  C extends ExternalToolType ? ComponentType.EXTERNAL_TOOL :
  C extends StyledLinkType ? ComponentType.STYLED_LINK :
  C;
/* eslint-enable */

// TODO: What is this and how does it differ from ComponentType above?
export enum ComponentKey {
  EXERCISE = 'exercise',
  EXERCISE_SKILLS_RATING = 'exerciseSkillsRatingActivity',
  EXTERNAL_TOOL = 'externalTool',
  GROUP_FORMATION = 'groupTeamSet',
  LECTURE_VIDEO = 'lectureVideo',
  LIVE_SESSION = 'liveSession',
  PEER_EVALUATION = 'peerEvaluation',
  POLL = 'poll',
  POST = 'post',
  PROFILE_REQUIREMENT = 'profileRequirement',
  QUIZ = 'quiz',
  SURVEY = 'survey',
  TEAM_FORMATION = 'teamSet',
  TIMED_QUIZ = 'timedQuiz',
  VIDEO_PRACTICE = 'practiceActivity',
  PROGRESSIVE_QUIZ = 'progressiveQuiz',
  VIDEO_PRACTICE_FEEDBACK = 'practiceFeedbackActivity',
  VIDEO_PRACTICE_SKILLS_FEEDBACK = 'exerciseSkillsRatingActivity',
}

export enum ComponentKeyPluralized {
  EXERCISE = 'exercises',
  EXERCISE_SKILLS_RATING = 'exerciseSkillsRatingActivities',
  EXTERNAL_TOOL = 'externalTools',
  GROUP_FORMATION = 'groupTeamSets',
  LECTURE_VIDEO = 'lectureVideos',
  LIVE_SESSION = 'liveSessions',
  PEER_EVALUATION = 'peerEvaluations',
  POLL = 'polls',
  POST = 'posts',
  PROFILE_REQUIREMENT = 'profileRequirements',
  QUIZ = 'quizzes',
  SURVEY = 'surveys',
  TEAM_DISCUSSION = 'teamDiscussions',
  TEAM_FORMATION = 'teamSets',
  TIMED_QUIZ = 'timedQuizzes',
  PROGRESSIVE_QUIZ = 'progressiveQuizzes',
  VIDEO_PRACTICE = 'practiceActivities',
  VIDEO_PRACTICE_FEEDBACK = 'practiceFeedbackActivities',
  VIDEO_PRACTICE_SKILLS_FEEDBACK = 'exerciseSkillsRatingActivities',
}

export type LectureComponentCommon<C extends ComponentTrueType> = {
  id: number;
  type: TypeFromTrueType<C>;
  trueType: C;
  content: string;
  index: number;
  viewOptions: ViewOptions[C];
  lecturePage?: LecturePage;
  // Note that this property actually *does* exist on the api responses we get when fetching a lecture
  // page. When this is used, lecturePage above is null
  lecturePageId: number;
  picture: FileUpload;
  removePicture?: boolean,
  hasFormedGroup?: boolean;
  header?: string,
  activityType?: string;
  /** Typically 'isTodo' status is tracked on a component's payload object, but video & lecture components track them
   * here at the lecture component level */
  isTodo?: boolean;
  skillTags: SkillTag[];
  skillTaggings: number[];
  bookmarkId?: number;
  publicFeedback?: VideoPracticeFeedbackActivity;
  /**
   * isPristine is not part of the BE response, but is added because some components need it,
   * to indicate they are recently created.
   */
  isPristine?: boolean;
  /**
   * These are the properties of a component that will be filled with an AI.
   */
  aiProperties?: NovoAIPayload<TypeFromTrueType<C>>,
  /**
   * Indicates if the element created is part of the components created with an AI
   */
  aiOrigin?: NovoAIItemType,
} & LectureComponentPayload<TypeFromTrueType<C>>;

type DiscriminatedUnionLectureComponent = ValuesType<{
  [CT in ComponentTrueType]: LectureComponentCommon<CT>
}>;

// TODO: This default of boolean is completely nuts; it's hard forcing us into the "else" condition
// because for whatever reason a default of 'any' is never making that trigger
export type LectureComponent<C = boolean> = C extends ComponentTrueType
  ? LectureComponentCommon<C>
  : DiscriminatedUnionLectureComponent;

type DescriminatedUnionNLectureComponent = ValuesType<{
  [CT in ComponentTrueType]: Normalized<LectureComponentCommon<CT>, keyof LectureComponentPayload<TypeFromTrueType<CT>>>
}>;

export type NLectureComponent<C = boolean> = C extends ComponentTrueType
  ? Normalized<LectureComponentCommon<C>, keyof LectureComponentPayload<TypeFromTrueType<C>>>
  : DescriminatedUnionNLectureComponent;

/** This is a replacement for the 'unpersisted components' idea from the Angularjs architecture:
 * This is a 'faked' component that is being uploaded to the backend which allows for showing a temporary
 * UI to indicate progress. Currently only used in the FileWorkflow and a few other instances of files being
 * uploaded */
export type TemporaryLectureComponent = NLectureComponent & {
  /** Temporary components have ids that are GUID strings */
  id: string,
};

/* @ngInject */
export function isTemporaryLectureComponent(lectureComponent: NLectureComponent | TemporaryLectureComponent): lectureComponent is TemporaryLectureComponent {
  return typeof lectureComponent.id === 'string';
}

export type AccordionListStyleType = 'more_less' | 'more_less_circled' | 'decimal' | 'alphabetical' | 'custom_symbol';

/* @ngInject */
export function isStyle1AccordionLectureComponent(
  accordionData: AccordionLectureComponentData,
): accordionData is Style1AccordionLectureComponent {
  return accordionData.viewOptions.listStyleType === 'more_less';
}

/* @ngInject */
export function isStyle2AccordionLectureComponent(
  accordionData: AccordionLectureComponentData,
): accordionData is Style2AccordionLectureComponent {
  return accordionData.viewOptions.listStyleType === 'more_less_circled';
}

/* @ngInject */
export function isStyle3AccordionLectureComponent(
  accordionData: AccordionLectureComponentData,
): accordionData is Style3AccordionLectureComponent {
  return accordionData.viewOptions.listStyleType === 'decimal';
}

/* @ngInject */
export function isStyle4AccordionLectureComponent(
  accordionData: AccordionLectureComponentData,
): accordionData is Style4AccordionLectureComponent {
  return accordionData.viewOptions.listStyleType === 'alphabetical';
}

/* @ngInject */
export function isStyle5AccordionLectureComponent(
  accordionData: AccordionLectureComponentData,
): accordionData is Style5AccordionLectureComponent {
  return accordionData.viewOptions.listStyleType === 'custom_symbol';
}

export type AccordionLectureComponent<
  T extends AccordionListStyleType,
  U extends AccordionSectionLectureComponent,
> = NLectureComponent<ComponentType.ACCORDION> & {
  viewOptions: {
    listStyleType: T;
  };
  accordionSections: U[];
};

// general type that includes all the accordion styles
export type AccordionLectureComponentData =
  AccordionLectureComponent<AccordionListStyleType, AccordionSectionLectureComponent>;

export type Style1AccordionLectureComponent =
  AccordionLectureComponent<'more_less', Style1AccordionSectionLectureComponent>;
export type Style2AccordionLectureComponent =
  AccordionLectureComponent<'more_less_circled', Style2AccordionSectionLectureComponent>;
export type Style3AccordionLectureComponent =
  AccordionLectureComponent<'decimal', Style3AccordionSectionLectureComponent>;

export type Style4AccordionLectureComponent =
  AccordionLectureComponent<'alphabetical', Style4AccordionSectionLectureComponent>;

export type Style5AccordionLectureComponent =
  AccordionLectureComponent<'custom_symbol', Style4AccordionSectionLectureComponent>;

export type AccordionSectionLectureComponent = NLectureComponent<AccordionSectionType> & {
  header: string;
  ownerId: number;
};

interface Style1AccordionSectionViewOptions {
  iconColor: string;
  lineColor: string;
}

interface Style2AccordionSectionViewOptions {
  iconColor: string;
  headerBackgroundColor: string;
}

export interface BaseRightToggleButtonSectionViewOptions {
  iconColor: string;
  headerBackgroundColor: string;
  textBackgroundColor: string;
  leftHandSymbolShape: 'square' | 'circle';
  leftHandSymbolBackgroundColor: string;
  leftHandSymbolColor: string;
}

export type Style3AccordionSectionViewOptions = BaseRightToggleButtonSectionViewOptions & {
  leftHandSymbolPosition: 'centered' | 'right';
};

// inherit all the style 3 view options except for `leftHandSymbolPosition`
export type Style4AccordionSectionViewOptions = BaseRightToggleButtonSectionViewOptions & {
  leftHandSymbolSize: 'normal' | 'big';
};

export type Style5AccordionSectionViewOptions = BaseRightToggleButtonSectionViewOptions & {
  iconLibraryName?: string;
};

export type Style1AccordionSectionLectureComponent = AccordionSectionLectureComponent & {
  viewOptions: Style1AccordionSectionViewOptions;
};

export type Style2AccordionSectionLectureComponent = AccordionSectionLectureComponent & {
  viewOptions: Style2AccordionSectionViewOptions;
};

export type Style3AccordionSectionLectureComponent = AccordionSectionLectureComponent & {
  viewOptions: Style3AccordionSectionViewOptions;
};

export type Style4AccordionSectionLectureComponent = AccordionSectionLectureComponent & {
  viewOptions: Style4AccordionSectionViewOptions;
};

export type Style5AccordionSectionLectureComponent = AccordionSectionLectureComponent & {
  viewOptions: Style5AccordionSectionViewOptions;
  thumbnailUrl: string | null;
};

export type OrderedAccordionSectionLectureComponent =
  | Style3AccordionSectionLectureComponent
  | Style4AccordionSectionLectureComponent;

export interface LectureComponentsNormalized { [id: string]: NLectureComponent }

// TODO: I have not updated this to match any model changes made after merging in the image lecture component project, so it
// may be missing some properties
export interface LectureComponentEntities {
  lectureComponents: LectureComponentsNormalized,
  communications: CommunicationsNormalized,
  exercises: TimelineExercisesNormalized,
  quizzes: TimelineQuizzesNormalized,
  timedQuizzes: TimelineTimedQuizzesNormalized,
  surveys: TimelineSurveysNormalized,
  teamSets: TimelineTeamSetsNormalized,
  groupTeamSets: TimelineGroupTeamSetsNormalized,
  posts: TimelinePostsNormalized,
  teamDiscussions: TeamDiscussionsNormalized,
  peerEvaluations: TimelinePeerEvaluationsNormalized,
  externalTools: TimelineExternalToolsNormalized,
  liveSessions: TimelineLiveSessionsNormalized,
  lectureVideos: TimelineLectureVideosNormalized,
  video: VideosNormalized,
  profileRequirements: ProfileRequirementsNormalized,
}

/* @ngInject */
export function convertToPictureData(pictureFile: NovoEdFile): FileUpload {
  return {
    contentType: pictureFile.type,
    fileName: pictureFile.name,
    uniqueId: pictureFile.uniqueId,
    fileSize: pictureFile.size,
  };
}

interface HeaderComponentViewOptions {
  textColor: string,
}

interface StyledLinkComponentViewOptions {
  libraryIconName: string,
  libraryIconColor: string,
  libraryIconBackground: string,
  buttonColor?: string,
}

/* @ngInject */
export function isHeaderStyle1(component: NLectureComponent): component is NLectureComponent<ComponentType.HEADER_STYLE1> {
  return component.type === ComponentType.HEADER_STYLE1;
}

/* @ngInject */
export function isHeaderStyle2(component: NLectureComponent): component is NLectureComponent<ComponentType.HEADER_STYLE2> {
  return component.type === ComponentType.HEADER_STYLE2;
}

/* @ngInject */
export function isHeaderStyle3(component: NLectureComponent): component is NLectureComponent<ComponentType.HEADER_STYLE3> {
  return component.type === ComponentType.HEADER_STYLE3;
}


export interface ViewOptions extends Record<ComponentTrueType, Object> {
  [ComponentType.ATTACHMENT]: {
    embeded: boolean,
  }
  [ComponentType.IMAGE]: {
    /** This unit is degrees */
    rotation?: 0 | -90 | -180 | -270,
    alignment?: 'left' | 'center' | 'right'
    width?: 'full' | '100%' | '75%' | '50%',
    altText?: string,
    caption?: string,
    cropping?: {
      left: number,
      top: number,
      width: number,
      height: number,
    }
  },
  [ComponentType.HEADER_STYLE1]: HeaderComponentViewOptions & {
    backgroundColor: string,
  },
  [ComponentType.HEADER_STYLE2]: HeaderComponentViewOptions & {
    leftBorderColor: string, // TODO: I'm pretty sure this is leftBorderColor on existing components, but is named backgroundColor in the api docs
  },
  [ComponentType.HEADER_STYLE3]: HeaderComponentViewOptions & {
    backgroundColor: string,
    pictureBackgroundColor: string,
    pictureShape: 'circle' | 'square',
    libraryIconName: string,
    libraryIconColor: string,
  },
  [RichTextType.FULL]: {
    styleOptions: 'full',
  },
  [RichTextType.SIMPLE]: {
    styleOptions: 'simple',
  },
  [ComponentType.ACCORDION],
  [AccordionSectionType.STYLE_1],
  [AccordionSectionType.STYLE_2],
  [AccordionSectionType.STYLE_3],
  [AccordionSectionType.STYLE_4],
  [AccordionSectionType.STYLE_5]: {
    listStyleType: AccordionListStyleType;
  },
  [StyledLinkType.BUTTON]: {
    styleType: 'button',
  } & StyledLinkComponentViewOptions,
  [StyledLinkType.CARD]: {
    styleType: 'card',
  } & StyledLinkComponentViewOptions,
  [ComponentType.ATTACHMENT]: {
    embeded: boolean,
  },
  [ComponentType.WHITE_SPACE]: {
    topBorderWidth: number,
  },
  [ComponentType.LINE_DIVIDER]: {
    topBorderColor: string,
    topBorderWidth: string,
  },
  [ComponentType.TEXT_WITH_IMAGE_BKG]: {
    componentWidth: ViewOptionsDimension,
    width: ViewOptionsDimension,
    minHeight: ViewOptionsDimension,
    textStyle: string,
    textColor: string,
    verticalAlign: 'top' | 'middle' | 'bottom',
    textAlign: 'left' | 'center' | 'right',
    backgroundColor: string,
  },
  [ComponentType.TEXT_WITH_IMAGE_SIDE]: {
    componentWidth: ViewOptionsDimension,
    picturePlacement: 'left' | 'center' | 'right',
    pictureWidth: ViewOptionsDimension,
    pictureShape: 'circle' | 'square',
    pictureAlt: string,
    verticalAlign: 'top' | 'middle' | 'bottom',
  },
  [ComponentType.TEXT_WITH_IMAGE_TOP]: {
    componentWidth: ViewOptionsDimension,
    pictureWidth: ViewOptionsDimension,
    pictureShape: 'circle' | 'square',
    pictureAlt: string,
  },
  [ExternalToolType.WEB_LINK]: {
    backgroundColor: string,
    pictureBackgroundColor: string,
    pictureShape: 'circle' | 'square',
    libraryIconName: string,
    libraryIconColor: string,
    textColor: string,
  },
}

/** The acceptabile size dimension formats for ViewOptions properties. Please extend if more are possible
 * than percentages and pixel values */
type ViewOptionsDimension = `${number}%` | `${number}px`;

export enum StyledLinkTypes {
  BUTTON = 'button',
  CARD = 'card',
}
