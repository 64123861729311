/* @ngInject */
export default function LineDividerLectureComponentModel(
  _,
  ContentTemplateBaseModel,
  config,
) {
  class LineDividerLectureComponent extends ContentTemplateBaseModel {
    constructor(attributes, ...args) {
      if (attributes) {
        attributes.editContentMode = false;
      }
      const defaults = {
        iconClass: 'icon-dash',
        type: 'LineDividerLectureComponent',
        componentClasses: ['divider'],
        hasContent: false,
        uploadSupported: false,
      };

      super(_.extend({}, defaults, attributes), ...args);
    }
  }

  LineDividerLectureComponent.SAMPLE_DATA = {
    contentKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LINE_LABEL',

    viewOptions: {
      topBorderColor: $.FE.DEFAULTS.defaultTextColor,
      topBorderWidth: '1px',
    },
  };

  LineDividerLectureComponent.DEFAULT_DATA = {
    viewOptions: {
      topBorderColor: $.FE.DEFAULTS.defaultTextColor,
      topBorderWidth: '1px',
    },
  };

  LineDividerLectureComponent.FORM_DATA = [
    [{
      labelKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.LINE_COLOR',
      type: 'COLOR',
      modelAttribute: 'topBorderColor',
    }, {
      labelKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.LINE_THICKNESS',
      type: 'FREEFORM_PIXELS',
      modelAttribute: 'topBorderWidth',
    }],
  ];

  LineDividerLectureComponent.pendoTagName = config.pendo.lectureEdit.lineDivider;
  LineDividerLectureComponent.toolTipKey = 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.LINE';

  return LineDividerLectureComponent;
}
