/* @ngInject */
export default function RichTextQuestionService(

  _,
  nvUtil,
) {
  const RichTextQuestion = function (attributes) {
    const _this = this;
    const base = {
      response: null,
      templateUrl: 'quizzes/templates/questions/nv-rich-text-question.html',
      displayState: null,
    };

    _this.getCorrectAnswer = function () {
      return _this.responseOptions[0].explanation;
    };

    _this.getResponse = function () {
      return _this.currentResponse;
    };

    /** Setting Up Basic Attributes * */
    _.extend(_this, base, attributes);

    /** Public Functions * */
    _this.isUnAnswered = isUnAnswered;
    _this.responsePayload = responsePayload;
    _this.setResponse = setResponse;
    _this.resetResponse = resetResponse;
    _this.updateResponseOptions = updateResponseOptions;
    _this.onInputFocus = onInputFocus;
    _this.onInputBlur = onInputBlur;

    /** Private Functions * */
    function isUnAnswered() {
      return !nvUtil.hasMeaningfulContent(_this.response);
    }

    function responsePayload() {
      return _this.response || '';
    }

    function updateResponseOptions(question) {
      _this.responseOptions = question.responseOptions;
    }

    function setResponse(response) {
      _this.currentResponse = response;
      _this.response = response.response;
    }

    function resetResponse() {
      _this.response = null;
    }

    function onInputFocus(form) {
      _this.isFocused = true;
    }

    function onInputBlur() {
      _this.isFocused = false;
      _this.isTouched = true;
    }
  };

  return RichTextQuestion;
}
