import React from 'react';
import { InlineRichTextEditorImplerativeInterface } from 'froala/components/nv-froala-inline';
import { AccordionSectionLectureComponent } from 'redux/schemas/models/lecture-component';
import { SectionContainerProps } from './accordion-types';

/**
 * This custom hook is a helper to abstract all the common logic from different
 * flavors of section containers
 */
/* @ngInject */
export default function useAccordionSectionContainer(props: SectionContainerProps<AccordionSectionLectureComponent>) {
  const [isLeftSideColorPickerOpen, setIsLeftSideColorPickerOpen] = React.useState(false);

  const headerEditorRef = React.useRef<InlineRichTextEditorImplerativeInterface>(null);


  function onHeaderRTEChange(html: string) {
    props.onHeaderChange(html);
  }


  function onLeftSideColorPickerToggle(nextShow: boolean) {
    setIsLeftSideColorPickerOpen(nextShow);
  }

  // exported refs, handlers and state
  return {
    onHeaderRTEChange,
    headerEditorRef,
    isLeftSideColorPickerOpen,
    onLeftSideColorPickerToggle,
  };
}
