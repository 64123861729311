import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { NvResponsiveTabsRow } from 'shared/components/nv-responsive-tabs-row';
import { NvTab, NvResponsiveTabsDisplayType } from 'shared/components/nv-responsive-tabs';
import t from 'react-translate';
import { useState, useRef, FunctionComponent, useEffect, useContext } from 'react';
import { NvExpandableSearchBar } from 'shared/components/nv-search-bar';
import { NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { NvResponsiveTable, NvResponsiveTableColumn, ColumnSortings, CacheLookup } from 'shared/components/nv-responsive-table';
import { downloadCSV } from 'shared/csv-utils';
import { isHandheld } from 'styles/global_defaults/media-queries';
import { getTeamsList, getAllUsersForCSV, getTeamsCount, updateProgressDashboardForAllTeams, deleteAllTeams } from 'redux/actions/teams';
import { standardSpacing, tripleSpacing, createGridStyles } from 'styles/global_defaults/scaffolding';
import _ from 'underscore';
import { getCurrentCourse, getCourseAliases } from 'redux/selectors/course';
import { useAppDispatch } from 'redux/store';
import LoadingRow from 'shared/components/loading-row';
import { Institution } from 'redux/schemas/models/institution';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { PagedDataQueryParams } from 'redux/create-action-creators';
import { Course, CourseAliases } from 'redux/schemas/models/course';
import { Team } from 'redux/schemas/models/team';
import { AngularServicesContext } from 'react-app';
import { RootState, CombinedCourse } from 'redux/schemas';
import { Button } from 'react-bootstrap';
import { NvModal, ModalType } from 'shared/components/nv-modal';


import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import prodPathReplace from '../../shared/prod-path-rewrite';
import { TeamRow, TeamRowExtraProps } from './team-row';
import PostMessageModal from './post-message-modal';
import BulkUploadModal from './bulk-upload-modal';
import { config } from '../../../config/pendo.config.json';


type Props = {
  hasAssignments: boolean,
};

export const createCourseTableColumns: (hasAssignments: boolean, TeamTitleAlias?: string, AssignmentsAlias?: string) => NvResponsiveTableColumn[] = (hasAssignments, TeamTitleAlias = 'Team', AssignmentsAlias = 'Assignments') => {
  const columns = [
    {
      name: t.TEAM_FACILITATION.COLUMN_HEADERS.TEAM_NAME(TeamTitleAlias),
      className: 'name-cell',
      sortable: true,
      gridWidth: '2fr',
    },
    {
      name: t.TEAM_FACILITATION.COLUMN_HEADERS.LAST_ACTIVITY(),
      className: 'last-activity-cell',
      sortable: true,
      gridWidth: '2fr',
    },
    {
      name: t.TEAM_FACILITATION.COLUMN_HEADERS.LAST_ADMIN_VISITED(),
      className: 'last-admin-visited-cell',
      sortable: true,
      gridWidth: '1fr',
    },
    {
      name: t.TEAM_FACILITATION.COLUMN_HEADERS.RECENT_POSTS(),
      className: 'recent-posts-cell',
      sortable: true,
      // These columns are 120px instead of the listed 100px to account for the 20px spacing
      // shown in https://gallery.io/projects/MCHbtQVoQ2HCZTIOge2PXDaq/files/MCEJu8Y2hyDSceUcBiHyXpcnwT5WP8BYAzk.
      gridWidth: '120px',
    },
    {
      name: t.TEAM_FACILITATION.COLUMN_HEADERS.RECENT_ACTIVE_MEMBERS(),
      className: 'recent-active-members-cell',
      sortable: true,
      gridWidth: '120px',
    },
  ];
  if (hasAssignments) {
    columns.push({
      name: t.TEAM_FACILITATION.COLUMN_HEADERS.TEAM_ASSIGNMENTS({ TeamTitleAlias, AssignmentsAlias }),
      className: 'team-assignments-cell',
      sortable: true,
      gridWidth: '120px',
    });
  }
  columns.push({
    name: '',
    className: 'options-cell',
    sortable: false,
    gridWidth: `${standardSpacing * 3}px`,
  });
  return columns;
};

/** A subset of courseTableColumns used on mobile devices */
const createMobileTableColumns = (hasAssignments: boolean, TeamTitleAlias?: string, AssignmentsAlias?: string) => {
  const courseTableColumns = createCourseTableColumns(hasAssignments, TeamTitleAlias, AssignmentsAlias);
  return [_.clone(courseTableColumns[0]), courseTableColumns[courseTableColumns.length - 1]];
};

const sortKeys = [
  { asc: 'name:asc', desc: 'name:desc' },
  { asc: 'activity:asc', desc: 'activity:desc' },
  { asc: 'admin:asc', desc: 'admin:desc' },
  { asc: 'posts:asc', desc: 'posts:desc' },
  { asc: 'members:asc', desc: 'members:desc' },
  { asc: 'assignments:asc', desc: 'assignments:desc' },
];

const emptyFetchParams: PagedDataQueryParams = {
  searchQuery: '',
  filters: {},
  sorting: [],
};

const initialFetchParams: PagedDataQueryParams = {
  searchQuery: '',
  sorting: [],
};


const TeamFacilitationDashboard = (props: Props) => {
  const {
    hasAssignments,
  } = props;

  const styles = css`
    /* TODO: Required for the NvRespsonsiveTable. Consider refactoring out */
    display: grid;
    display: -ms-grid;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto 1fr;
    -ms-grid-columns: 1fr;
    .clear-btn:hover {
      cursor: pointer;
    }
    /* TODO: remove */
    height: calc(100vh - 60px);
  `;

  const dispatch = useAppDispatch();
  const { CurrentPermissionsManager, CurrentCourseManager, RailsRoutes, $state } = useContext(AngularServicesContext);

  const currentCourse = useSelector<RootState, CombinedCourse>((state) => getCurrentCourse(state)) as Course;
  const institution = useSelector((state) => getCurrentInstitution(state));

  const [isTeam, setIsTeam] = useState(!$state.params.loadGroupsTab && !!currentCourse.courseLongTeamSet);
  const teamSetId = isTeam ? currentCourse.courseLongTeamSet.id : currentCourse.groupTeamSet.id;

  const teamsCount = useSelector((state) => state.app.teamCount);
  const groupsCount = useSelector((state) => state.app.groupCount);
  const teamsUpdating = useSelector((state) => state.app.teamsUpdating);
  const groupsUpdating = useSelector((state) => state.app.groupsUpdating);
  const teamsForCSV = useSelector((state) => state.models.teamsForCSV);
  const groupsForCSV = useSelector((state) => state.models.groupsForCSV);

  const [resultsCount, setResultsCount] = useState(0);
  const [showResultsTab, setShowResultsTab] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [pagedFetchParams, setPagedFetchParams] = useState<PagedDataQueryParams>(initialFetchParams);
  const [showProgressDashboard, setShowProgressDashboard] = useState(false);
  const [showPostMessage, setShowPostMessage] = useState(false);
  const [showBulkUpload, setShowBulkUpload] = useState(false);
  const aliases: CourseAliases = useSelector((state: RootState) => getCourseAliases(state));

  const teamIDs = useSelector((state) => Object.values(state.models.teams).filter((team: Record<string, Team>) => team?.teamSet?.id === teamSetId).map(({ id }) => id));

  const [showMobileView, setShowMobileView] = useState(isHandheld());
  const tableColumns = showMobileView ? createMobileTableColumns(hasAssignments && isTeam, currentCourse.teamName.singularizedTitleized, currentCourse.assignmentName.pluralizedTitleized) : createCourseTableColumns(hasAssignments && isTeam, isTeam ? currentCourse.teamName.singularizedTitleized : currentCourse.groupName.singularizedTitleized, currentCourse.assignmentName.pluralizedTitleized);

  const [columnSortings, setColumnSortings] = useState<ColumnSortings>({ 1: false }); // Desc sort the Activity column by default

  useEffect(() => {
    if ( // Here, we are checking against the conditions under which downloading the csv can be true. First if the Bulk Update Modal will be available
      !(CurrentPermissionsManager.isInstructor() && ((isTeam && !currentCourse.courseLongTeamSet.formedByStudents) || (!isTeam && !currentCourse.groupTeamSet.formedByStudents)))
      // And, if the Download Team CSV Menu Option will be available
      && !((isTeam && teamsCount) || (!isTeam && groupsCount))
    ) { // If they're both false, there is no need to fetch that data
      return;
    }
    // This is a separate block only to increase readability
    if ($state.current.url !== '/team-facilitation') {
      return; // There is no need to fetch this data when we are navigating somewhere else
    }
    const { catalogId } = currentCourse;
    const getTeamUsers = (isTeam && (teamsCount || !teamsForCSV.length)) || (!isTeam && (groupsCount || !groupsForCSV.length));
    dispatch(getAllUsersForCSV({ catalogId, teamSetId, isTeam, getTeamUsers }));
  }, [currentCourse, CurrentPermissionsManager, isTeam, teamsCount, groupsCount, $state.current]);

  const teamsTab: NvTab = {
    text: currentCourse.teamName.pluralizedTitleized,
    count: teamsCount,
    onClick: () => setIsTeam(true),
    dataQA: config.pendo.teamFacilitationDashboard.teamTab,
  };

  const groupsTab: NvTab = {
    text: currentCourse.groupName.pluralizedTitleized,
    count: groupsCount,
    onClick: () => setIsTeam(false),
    dataQA: config.pendo.teamFacilitationDashboard.groupTab,
  };

  const defaultTabs = [];
  if (currentCourse.courseLongTeamSet) {
    defaultTabs.push(teamsTab);
  }
  if (currentCourse.groupTeamSet) {
    defaultTabs.push(groupsTab);
  }

  useEffect(() => {
    setPagedFetchParams({
      ...pagedFetchParams,
      filters: { type: isTeam ? 'team' : 'group' },
    });
  }, [isTeam]);

  const resultsTab: NvTab = {
    text: t.COURSES.TABS.TAB_RESULTS(),
    count: resultsCount,
    onClick: () => { },
  };

  // TODO: Copied from institution dashboard home. Can I pull this out somewhere?
  const clearSearchResults = () => {
    setPagedFetchParams({
      ...initialFetchParams,
      filters: { type: isTeam ? 'team' : 'group' },
    });
    setShowResultsTab(false);
    setIsSearchOpen(false);
    resetInputText();
  };

  // TODO: Copied from institution dashboard home. Can I pull this out somewhere?
  const clearBtn = <div className='clear-btn page-title-xxs text-primary' onClick={() => clearSearchResults()}>{t.SEARCH.CLEAR()}</div>;

  // TODO: Copied, see above
  const resetInputText = () => {
    inputRef.current.value = '';
  };

  // TODO: Copied, see above
  const handleSearch = (query: string) => {
    const newPagedFetchParams = {
      ...emptyFetchParams, // Reset the sorting (but not the filtering) entirely upon a search
      filters: { type: isTeam ? 'team' : 'group' }, // We leave the filtering, because we only want to search in the specific type
      searchQuery: query,
    };
    setPagedFetchParams(newPagedFetchParams);

    switchToResultsTab(newPagedFetchParams);
  };

  const switchToResultsTab = (newPagedFetchParams: PagedDataQueryParams) => {
    dispatch(getTeamsCount({
      institutionId: institution.id,
      catalogId: currentCourse.catalogId,
      ...newPagedFetchParams,
      handleCountResponse: (counts) => {
        setResultsCount(isTeam ? counts.teamSize : counts.groupSize);
      },
      pageIndex: 0,
      filters: { type: isTeam ? 'team' : 'group' },
    }));

    setShowResultsTab(true);
  };

  const onHeaderSortingClicked = (colIndex: number) => {
    const newSorting = !columnSortings[colIndex];
    const newSortKey = sortKeys[colIndex][newSorting ? 'asc' : 'desc'];

    setColumnSortings({ [colIndex]: newSorting });
    setPagedFetchParams({
      ...pagedFetchParams,
      sorting: [newSortKey],
    });
  };

  const searchBar = <NvExpandableSearchBar onSearch={handleSearch} placeholder={t.TEAM_FACILITATION.SEARCH.PLACEHOLDER(isTeam ? currentCourse.teamName.downcasedPluralized : currentCourse.groupName.downcasedPluralized)} isExpanded={isSearchOpen} onExpanded={setIsSearchOpen} ref={inputRef} />;

  const getStoreTeamData: CacheLookup<Team> = (state: RootState) => Object.fromEntries(Object.entries(state.models.teams).filter(([id, team]) => team?.teamSet?.isTeam === isTeam));

  const downloadTeamsCSV = () => {
    const headers = isTeam
      ? ['Team ID', 'Team Name', 'First Name', 'Last Name', 'Email', 'Is Team Lead? (Yes/No)']
      : ['Group ID', 'Group Name', 'Is Open? (Yes/No)', 'First Name', 'Last Name', 'Email', 'Is Admin? (Yes/No)'];

    const data = [headers];
    if (isTeam) {
      const teamData = teamsForCSV.map(({ teamId, teamName, firstName, lastName, email, isAdmin }) => {
        const teamArray = [teamId ?? 'NO TEAM', teamName ?? '', firstName, lastName, email];
        if (teamId && email) {
          teamArray.push(isAdmin ? 'Yes' : 'No');
        } else {
          teamArray.push('');
        }
        return teamArray;
      });
      data.push(...teamData);
    } else {
      const groupData = groupsForCSV.map(({ teamId, teamName, isOpen, firstName, lastName, email, isAdmin }) => {
        const groupArray = [teamId ?? 'NO GROUP', teamName ?? ''];
        if (teamId) {
          groupArray.push(isOpen ? 'Yes' : 'No');
        } else {
          groupArray.push('');
        }
        groupArray.push(firstName, lastName, email);
        if (teamId && email) {
          groupArray.push(isAdmin ? 'Yes' : 'No');
        } else {
          groupArray.push('');
        }
        return groupArray;
      });
      data.push(...groupData);
    }

    const alias = isTeam ? aliases.teamAliases.TeamTitleAlias : aliases.groupAliases.GroupTitleAlias;
    const { catalogId } = currentCourse;
    const timestamp = (new Date()).toISOString().replace(/[^A-Z0-9]/g, '');
    const csvName = `${alias}_${catalogId}_${timestamp}.csv`;
    downloadCSV(data, csvName);
  };

  const ctaButtonItems: NvDropdownOption[] = [];

  if (CurrentPermissionsManager.isInstructor() && ((isTeam && !currentCourse.courseLongTeamSet.formedByStudents) || (!isTeam && !currentCourse.groupTeamSet.formedByStudents))) {
    let dataQa;
    if (isTeam) {
      dataQa = teamsCount ? config.pendo.manageAllTeamsGroups.options.bulkUpdateTeamViaCsv : config.pendo.manageAllTeamsGroups.options.bulkCreateTeamViaCsv;
    } else {
      dataQa = groupsCount ? config.pendo.manageAllTeamsGroups.options.bulkUpdateGroupViaCsv : config.pendo.manageAllTeamsGroups.options.bulkCreateGroupViaCsv;
    }
    ctaButtonItems.push({
      type: 'text',
      text: ((isTeam && teamsCount) || (!isTeam && groupsCount))
        ? t.TEAM_FACILITATION.MANAGE_TEAMS.BULK_UPDATE_VIA_CSV_UPLOAD(isTeam ? currentCourse.teamName.pluralizedTitleized : currentCourse.groupName.pluralizedTitleized)
        : t.TEAM_FACILITATION.MANAGE_TEAMS.BULK_CREATE_VIA_CSV_UPLOAD(isTeam ? currentCourse.teamName.pluralizedTitleized : currentCourse.groupName.pluralizedTitleized),
      callback: () => setShowBulkUpload(true),
      dataQa,
    });
  }

  if ((isTeam && teamsCount) || (!isTeam && groupsCount)) {
    ctaButtonItems.push({
      type: 'text',
      text: t.TEAM_FACILITATION.MANAGE_TEAMS.DOWNLOAD_CSV(isTeam ? currentCourse.teamName.singularizedTitleized : currentCourse.groupName.singularizedTitleized),
      callback: () => downloadTeamsCSV(),
      dataQa: config.pendo.manageAllTeamsGroups.options.downloadTeamGroupCsv,
    });
  }

  if (isTeam && !currentCourse.courseLongTeamSet.formedByStudents && CurrentPermissionsManager.isInstructor()) {
    ctaButtonItems.push({
      type: 'link',
      text: t.TEAM_FACILITATION.MANAGE_TEAMS.OTHER_TEAM_FORMATION_OPTIONS(currentCourse.teamName.singularizedTitleized),
      link: RailsRoutes.editTeamsPath(currentCourse.catalogId, teamSetId, true),
      dataQa: config.pendo.manageAllTeamsGroups.options.otherTeamFormationOptions,
    });
  }

  if ((isTeam && teamsCount) || (!isTeam && groupsCount)) {
    ctaButtonItems.push({
      type: 'divider',
    }, {
      type: 'text',
      text: t.TEAM_FACILITATION.MANAGE_TEAMS.POST_MESSAGE_TO_ALL_WORKSPACES(isTeam ? currentCourse.teamName.singularizedTitleized : currentCourse.groupName.singularizedTitleized),
      callback: () => setShowPostMessage(true),
      dataQa: config.pendo.manageAllTeamsGroups.options.postMessageToAllWorkspaces,
    });
  }

  if (CurrentPermissionsManager.isInstructor()) {
    ctaButtonItems.push({
      type: 'text',
      text: ((isTeam && currentCourse.courseLongTeamSet.hasProgressDashboard) || (!isTeam && currentCourse.groupTeamSet.hasProgressDashboard))
        ? t.TEAM_FACILITATION.PROGRESS_DASHBOARD.DISABLE(isTeam ? currentCourse.teamName.singularizedTitleized : currentCourse.groupName.singularizedTitleized)
        : t.TEAM_FACILITATION.PROGRESS_DASHBOARD.ENABLE(isTeam ? currentCourse.teamName.singularizedTitleized : currentCourse.groupName.singularizedTitleized),
      callback: () => setShowProgressDashboard(true),
      dataQa: config.pendo.manageAllTeamsGroups.options.enableAllProgressDashboard,
    });
    if ((isTeam && teamsCount) || (!isTeam && groupsCount)) {
      ctaButtonItems.push({
        type: 'divider',
      }, {
        type: 'text',
        text: t.TEAM_FACILITATION.MANAGE_TEAMS.DELETE_ALL(isTeam ? currentCourse.teamName.pluralizedTitleized : currentCourse.groupName.pluralizedTitleized),
        class: 'text-danger',
        callback: () => dispatch(openConfirmationDialog({
          title: t.TEAM_FACILITATION.MANAGE_TEAMS.DELETE_ALL_WARNING_TITLE({ isTeam, ...aliases.teamAliases, ...aliases.groupAliases }),
          bodyText: t.TEAM_FACILITATION.MANAGE_TEAMS.DELETE_ALL_WARNING_DESCRIPTION({ isTeam, ...aliases.teamAliases, ...aliases.groupAliases }),
          confirmText: t.FORM.YES_SURE(),
          onConfirm: () => dispatch(deleteAllTeams({ catalogId: currentCourse.catalogId, teamSetId, translationValues: { isTeam, ...aliases.teamAliases, ...aliases.groupAliases } })),
        })),
        dataQa: config.pendo.manageAllTeamsGroups.options.deleteAllTeamsGroups,
      });
    }
  }

  const ProgressDashboardModal = () => {
    const progressDashboardModalStyles = css`
      .position-absolute {
        bottom: 0;
      }

      img {
        max-width: 60%;
      }
    `;

    const hasProgressDashboard = (isTeam && currentCourse.courseLongTeamSet.hasProgressDashboard) || (!isTeam && currentCourse.groupTeamSet.hasProgressDashboard);

    return (
      <div css={progressDashboardModalStyles} className='position-relative h-100'>
        {hasProgressDashboard
          ? <div className='font-weight-bold pb-2'>{t.TEAM_FACILITATION.PROGRESS_DASHBOARD.ALL_TEAMS_DASBOARD_ENABLED({ isTeam, ...aliases.teamAliases, ...aliases.groupAliases, ...aliases.courseAliases })}</div>
          : null}
        <div>{t.TEAM_FACILITATION.PROGRESS_DASHBOARD.ALL_TEAMS_DESCRIPTION({ isTeam, ...aliases.teamAliases, ...aliases.groupAliases, ...aliases.assignmentAliases, ...aliases.courseAliases })}</div>
        <div className='position-absolute w-100'>
          <img className='d-block m-auto' src={prodPathReplace('images/team-dashboard-example.png')} alt='' />
          <Button
            className='d-block mt-8 mx-auto mb-4'
            onClick={() => {
              dispatch(updateProgressDashboardForAllTeams({ catalogId: currentCourse.catalogId, teamSetId, hasProgressDashboard }));
              setShowProgressDashboard(false);
              dispatch(addAlertMessage({
                type: AlertMessageType.SUCCESS,
                header: t.FORM.SUCCESS_BANG(),
                message: hasProgressDashboard
                  ? t.TEAM_FACILITATION.PROGRESS_DASHBOARD.PROGRESS_DASHBOARD_HAS_BEEN_DISABLED_ALL_TEAMS({ isTeam, ...aliases.teamAliases, ...aliases.groupAliases })
                  : t.TEAM_FACILITATION.PROGRESS_DASHBOARD.PROGRESS_DASHBOARD_HAS_BEEN_ENABLED_ALL_TEAMS({ isTeam, ...aliases.teamAliases, ...aliases.groupAliases }),
              }));
              if (isTeam) {
                CurrentCourseManager.course.courseLongTeamSet.hasProgressDashboard = !CurrentCourseManager.course.courseLongTeamSet.hasProgressDashboard;
              } else {
                CurrentCourseManager.course.groupTeamSet.hasProgressDashboard = !CurrentCourseManager.course.groupTeamSet.hasProgressDashboard;
              }
            }}
          >
            {hasProgressDashboard
              ? t.TEAM_FACILITATION.PROGRESS_DASHBOARD.DISABLE_FOR_ALL({ isTeam, ...aliases.teamAliases, ...aliases.groupAliases })
              : t.TEAM_FACILITATION.PROGRESS_DASHBOARD.ENABLE_FOR_ALL({ isTeam, ...aliases.teamAliases, ...aliases.groupAliases })}

          </Button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!showResultsTab) {
      dispatch(getTeamsCount({
        ...emptyFetchParams,
        institutionId: institution.id,
        catalogId: currentCourse.catalogId,
        handleCountResponse: () => null,
        pageIndex: 0,
        filters: { type: isTeam ? 'team' : 'group' },
      }));
    }
  }, [showResultsTab]);

  return (
    <div css={styles}>
      <NvResponsiveTabsRow
        defaultTabs={defaultTabs}
        filteredTabs={[resultsTab]}
        isFiltered={showResultsTab}
        revertActiveTab={isTeam ? 0 : 1}
        tabType={NvResponsiveTabsDisplayType.STACKED_NUMBERED}
        clearBtn={clearBtn}
        showClearBtn={showResultsTab}
        searchBar={searchBar}
        ctaButtonItems={ctaButtonItems}
        ctaButtonText={
          isTeam
            ? t.TEAM_FACILITATION.MANAGE_ALL_TEAMS({ TeamsTitleAlias: currentCourse.teamName.pluralizedTitleized })
            : t.TEAM_FACILITATION.MANAGE_ALL_GROUPS({ GroupsTitleAlias: currentCourse.groupName.pluralizedTitleized })
        }
        ctaButtonDisabled={isTeam ? (teamsUpdating || !teamsForCSV.length) : (groupsUpdating || !groupsForCSV.length)}
      />
      <NvResponsiveTable<Team, TeamRowExtraProps>
        columns={tableColumns}
        columnSortings={columnSortings}
        onSortClicked={onHeaderSortingClicked}
        fetchData={getTeamsList}
        fetchParams={{ institutionId: institution.id, catalogId: currentCourse.catalogId }}
        pagedFetchParams={pagedFetchParams}
        rowComponent={TeamRow as FunctionComponent} // The hot() is necessitating this 'as'
        rowProps={{ isTeam, institution, course: currentCourse, hasAssignments }}
        loadingComponent={LoadingRow}
        dataKey='id'
        cacheDataKey='id'
        extraDataKeys={teamIDs}
        cacheLookup={getStoreTeamData}
        hoverDisabled
        clearSearch={clearSearchResults}
        hideClearSearch={!showResultsTab}
        style={createGridStyles(1, 3)}
        noResultsText={showResultsTab ? t.TEAM_FACILITATION.NO_RESULTS_FOUND(isTeam ? currentCourse.teamName.pluralizedTitleized : currentCourse.groupName.pluralizedTitleized) : t.TEAM_FACILITATION.NO_TEAMS({ isTeam, offeringName: currentCourse.offeringName.downcasedSingularized, teamsAlias: currentCourse.teamName.downcasedPluralized, groupsAlias: currentCourse.groupName.downcasedPluralized })}
        noResultsIcon={isTeam ? 'team' : 'groups'}
        disabled={isTeam ? teamsUpdating : groupsUpdating}
      />

      <NvModal
        type={ModalType.FIXED}
        header={((isTeam && teamsCount) || (!isTeam && groupsCount)) ? t.TEAM_FACILITATION.BULK_UPLOAD.UPDATE_TITLE(isTeam ? currentCourse.teamName.pluralizedTitleized : currentCourse.groupName.pluralizedTitleized) : t.TEAM_FACILITATION.BULK_UPLOAD.CREATE_TITLE(isTeam ? currentCourse.teamName.pluralizedTitleized : currentCourse.groupName.pluralizedTitleized)}
        body={<BulkUploadModal isTeam={isTeam} isUpdate={!!((isTeam && teamsCount) || (!isTeam && groupsCount))} teamSetId={teamSetId} downloadCSV={() => downloadTeamsCSV()} onClose={() => setShowBulkUpload(false)} />}
        show={showBulkUpload}
        onClose={() => setShowBulkUpload(false)}
        height='unset'
        closeDataQa={config.pendo.manageAllTeamsGroups.bulkUpdateModal.closeBulkUpdateModal}
      />
      <NvModal
        type={ModalType.FIXED}
        header={t.TEAM_FACILITATION.MANAGE_TEAMS.POST_MESSAGE_TO_ALL_WORKSPACES(isTeam ? currentCourse.teamName.singularizedTitleized : currentCourse.groupName.singularizedTitleized)}
        body={<PostMessageModal isTeam={isTeam} onClose={() => setShowPostMessage(false)} />}
        show={showPostMessage}
        onClose={() => setShowPostMessage(false)}
        height='unset'
        width={960}
      />
      <NvModal
        type={ModalType.FIXED}
        header={t.TEAM_FACILITATION.PROGRESS_DASHBOARD.TITLE({ isTeam, ...aliases.teamAliases, ...aliases.groupAliases })}
        body={ProgressDashboardModal()}
        show={showProgressDashboard}
        onClose={() => setShowProgressDashboard(false)}
        height={650}
      />
    </div>
  );
};

export default TeamFacilitationDashboard;
