/**
 * output normalization. If the input contains only html tags without any
 * content we discard it and save an empty script to the Database
 */
/* @ngInject */
export function normalizeHTMLOutput(html: string) {
  const textContainer = document.createElement('span');
  textContainer.innerHTML = html;

  // if it doesn't contain any text, image of embedded content return just the empty string
  if (textContainer.textContent === '' && !textContainer.querySelector('img, iframe')) {
    return '';
  }

  return html;
}

/**
 * input normalization. If the response coming from the Database is and empty
 * string we use the default html template
 */
/* @ngInject */
export function normalizeHTMLInput(html: string, defaultTemplate: string) {
  if (html === '') {
    return defaultTemplate;
  }
  return html;
}
