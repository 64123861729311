/* @ngInject */
export default function NvIntervalSwitchItem(
  _,
  $interval,
  $templateCache,
  $window,
  config,
) {
  return {
    restrict: 'A',
    scope: {
      fullDataList: '=',
      numToShow: '=',
      refreshInterval: '=',
      itemTemplateUrl: '@',
      pauseSwappingCheck: '=',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
      let swapIntervalFn = null;
      vm.numSwapped = 0;
      vm.itemsToDisplay = [];
      vm.shuffled = [];
      vm.config = config;

      if (vm.fullDataList?.length) {
        initialize();
      }

      $scope.$watch(
        () => vm.fullDataList,
        (newValue, oldValue) => {
          if (newValue?.length && !oldValue.length) {
            initialize();
          }
        },
      );


      function swapItem() {
        if (vm.pauseSwappingCheck) {
          return;
        }

        const destinationIndex = vm.numSwapped % $window.Math.min(vm.numToShow, vm.shuffled.length);
        const sourceIndex = (vm.numToShow + vm.numSwapped) % vm.shuffled.length;

        vm.itemsToDisplay[destinationIndex] = vm.shuffled[sourceIndex];

        vm.numSwapped += 1;
      }

      function initialize() {
        // shuffle the items to get a random ordering
        vm.shuffled = _.shuffle(vm.fullDataList);

        // assign a subset to be displayed
        vm.itemsToDisplay = vm.shuffled.slice(0, vm.numToShow);

        // only do the swapping if there are more items to swap in
        if (vm.fullDataList.length > 1 && vm.fullDataList.length > vm.numToShow) {
          // kick-start swapping
          swapIntervalFn = $interval(swapItem, vm.refreshInterval, $window.Math.min(vm.fullDataList.length - vm.numToShow, vm.fullDataList.length));
        }
      }
    },
    bindToController: true,
    controllerAs: 'vm',
    templateUrl: 'shared/templates/nv-interval-switch-item.html',
  };
}
