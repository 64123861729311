/* @ngInject */
export default function TeamProfileTeamMembersCtrl(

  TeamManager,
  CurrentUserManager,
  $state,
  $scope,
  config,
) {
  const vm = this;

  vm.config = config;
  vm.manager = TeamManager;
  vm.$state = $state;

  vm.canAddMember = canAddMember;

  function canAddMember() {
    if (vm.manager.currentTeam.numApprovedMembers < vm.manager.currentTeam.teamSet.teamSizeLimit && !vm.manager.currentTeam.invitesFull
          && $scope.ProfileCtrl.numShownMembers >= vm.manager.currentTeam.numApprovedMembers && vm.manager.currentTeam.teamSet.formedByStudents) {
      if (vm.manager.currentTeam.teamSet.isTeam) {
        return vm.manager.currentTeam.ceo && vm.manager.currentTeam.ceo.id === CurrentUserManager.user.id;
      }
      return vm.manager.currentTeam.currentAccountTeamMember && vm.manager.currentTeam.currentAccountTeamMember.isAdmin;
    }
    return false;
  }
}
