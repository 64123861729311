/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import _ from 'underscore';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Result } from 'redux/schemas/api';
import { makeQueryParamString } from './helpers';
import {
  CourseRankingState,
  EnrollmentForPeriod,
  EnrollmentPeriod,
  ExportFile,
  ExportTypes,
  LicenseData,
  SeatsUsageData,
} from '../schemas/models/license';
import {
  TotalLoginsResponse,
  MonthlyEnrollmentsResponse,
  getExportDetailsRequest,
  getCourseEnrollmentDetailsRequest,
  getCourseEnrollmentDetailsResponse,
  ExportDataRequest,
  getExportFileListRequest,
  getExportFileListResponse,
  CloneMultipleCourseRequest,
  ExportDetailsResponse,
  CloneJourneyRequest,
  JourneyInformationRequest,
} from '../schemas/api/license';

import { addAlertMessage } from './alert-messages';
import { AlertMessageType } from '../schemas/app/alert-message';
import t from '../../react-translate';

async function fetchLicenseAndSeatsData(params: { institutionId: number }) {
  const [licenseDataResponse, seatsResponse] = await Promise.all([
    fetchLicenseData(params),
    fetchSeats(params),
  ]);
  return ({
    ...licenseDataResponse.data.result,
    ...seatsResponse.data.result,
  });
}

function fetchLicenseData(params: { institutionId: number }) {
  return axios.get<Result<LicenseData>>(`/institutions/${params.institutionId}/license.json`);
}

function fetchSeats(params: { institutionId: number }) {
  return axios.get<Result<SeatsUsageData>>(`/institutions/${params.institutionId}/seats.json`);
}

async function fetchEnrollmentsForPeriod(params: { institutionId: number, startTime: string, endTime: string, period: EnrollmentPeriod }) {
  const response = await axios.post<Result<EnrollmentForPeriod>>(`/institutions/${params.institutionId}/period_enrollment.json`, params);
  return response.data.result;
}

async function fetchTop10Courses(params: { institutionId: number, startTime: string, endTime: string }) {
  const response = await axios.post<Result<CourseRankingState>>(`/institutions/${params.institutionId}/top_10_courses.json`, params);
  return response.data.result;
}

async function fetchCoursesLogins(params: { institutionId: number, }) {
  const response = await axios.post<Result<TotalLoginsResponse>>(`/institutions/${params.institutionId}/courses_logins.json`, params);
  return response.data.result;
}

async function fetchMonthlyEnrollments(params: { institutionId: number, period: EnrollmentPeriod }) {
  const response = await axios.post<Result<MonthlyEnrollmentsResponse>>(`/institutions/${params.institutionId}/monthly_enrollment.json`, params);
  return response.data.result;
}

export const setExportDetails = createAction<ExportDataRequest>('SET_EXPORT_DATA');

export const getExportData = createAsyncThunk(
  'EXPORT_DATA',
  fetchExportData,
);

/** TODO: We need types on these */
async function fetchExportData(params: ExportDataRequest, thunkAPI) {
  try {
    await axios.post(`/institutions/${params.institutionId}/export_data.json`, params);
    switch (params.exportType) {
      case ExportTypes.ORGANIZATION_ENROLLMENT:
        thunkAPI.dispatch(addAlertMessage({
          duration: 20000,
          type: AlertMessageType.SUCCESS,
          header: params.alertSuccessHeader,
          message: t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.ENROLLMENT_DATA.SUCCESS.MESSAGE(),
        }));
        break;
      case ExportTypes.ORGANIZATION_USERS:
        thunkAPI.dispatch(addAlertMessage({
          duration: 20000,
          type: AlertMessageType.SUCCESS,
          header: params.alertSuccessHeader,
          message: t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.USER_DATA.SUCCESS.MESSAGE(),
        }));
        break;
      case ExportTypes.COURSE_ENROLLMENT:
        thunkAPI.dispatch(addAlertMessage({
          duration: 20000,
          type: AlertMessageType.SUCCESS,
          header: t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.COURSE_ENROLLMENT_DATA.SUCCESS.HEADER(params.courseName),
          message: t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.COURSE_ENROLLMENT_DATA.SUCCESS.MESSAGE(),
        }));
        break;
      default:
        break;
    }
  } catch (error) {
    switch (params.exportType) {
      case ExportTypes.ORGANIZATION_ENROLLMENT:
        thunkAPI.dispatch(addAlertMessage({
          duration: 5000,
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.ENROLLMENT_DATA.FAILURE.MESSAGE(),
        }));
        break;
      case ExportTypes.ORGANIZATION_USERS:
        thunkAPI.dispatch(addAlertMessage({
          duration: 5000,
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.USER_DATA.FAILURE.MESSAGE(),
        }));
        break;
      case ExportTypes.COURSE_ENROLLMENT:
        thunkAPI.dispatch(addAlertMessage({
          duration: 5000,
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.COURSE_ENROLLMENT_DATA.FAILURE.MESSAGE(params.courseName),
        }));
        break;
      default:
        break;
    }
  }
  return params;
}

async function requestMultipleCloneCourse({ id, ...params }: CloneMultipleCourseRequest, thunkAPI) {
  const url = `/courses/${id}/clones.json`;
  const clone = { clones: params.clones };
  return axios.post<Result<{ dataRequest: Object }>>(url, clone)
    .then(response => {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.SUCCESS,
        header: t.COURSES.CLONE.SUCCESS_HEADER(),
        message: t.COURSES.CLONE.SUCCESS_BODY(),
      }));
      return response.data.result;
    })
    .catch((error) => {
      let catalogError = error.response?.data;
      if (catalogError?.error.data) {
        const errorKey = Object.keys(catalogError.error.data);
        const repeatedCatalogIds = errorKey.filter(errorKey => catalogError.error.data[errorKey].catalog_id[0] === 'already_taken');
        thunkAPI.dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.SOMETHING_WRONG(),
          message: repeatedCatalogIds.length > 1
            ? t.COURSES.CLONE.STEPS.STEP_ONE.FORM.DUPLICATE_MULTIPLE_CATALOG_IDS(repeatedCatalogIds.join(', '))
            : t.COURSES.CLONE.STEPS.STEP_ONE.FORM.DUPLICATE_CATALOG_ID(repeatedCatalogIds[0]),
        }));
        catalogError = {
          ...catalogError,
          error: {
            ...catalogError.error,
            repeatedCatalogIds,
          },
        };
      } else {
        thunkAPI.dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.FORM.ERROR_SOMETHING_WRONG(),
        }));
      }
      return catalogError;
    });
}

async function requestCloneJourney({ id, ...params }: CloneJourneyRequest, thunkAPI) {
  const url = `/journeys/${id}/clone.json`;
  const clone = { clone: params };
  return axios.post<Result<{ dataRequest: Object }>>(url, clone)
    .then(response => response.data.result)
    .catch((error) => {
      const catalogError = error.response?.data;
      if (!catalogError) {
        thunkAPI.dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.FORM.ERROR_SOMETHING_WRONG(),
        }));
      }
      return catalogError;
    });
}

async function requestJourneyInformation(params: JourneyInformationRequest, thunkAPI) {
  const url = `/courses/${params.catalogId}/available.json`;
  return axios.get<Result<{ dataRequest: Object }>>(url)
    .then(response => response.data.result)
    .catch(() => {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR(),
        message: t.COURSES.FORM.ERRORS.CATALOG_ID_TAKEN(),
      }));
    });
}

async function fetchExportDetails(params: getExportDetailsRequest) {
  const response = await axios.get<Result<{ licenseDashboardExportDetails: ExportDetailsResponse; }>>(`/institutions/${params.institutionId}/license_dashboard_export_details.json`);
  return response.data.result.licenseDashboardExportDetails;
}

async function fetchCourseEnrollmentDetails(params: getCourseEnrollmentDetailsRequest) {
  const response = await axios.post<{ result: getCourseEnrollmentDetailsResponse; }>(`/institutions/${params.institutionId}/course_enrollment.json`, params);
  return response.data.result;
}

async function fetchExportFiles(params: getExportFileListRequest) {
  const { institutionId, period } = params;
  const response = await axios.post<Result<getExportFileListResponse>>(`/institutions/${institutionId}/license_dashboard_export_file_list.json`, { period });
  return response.data.result;
}

export const addDownloadFile = createAction<ExportFile>('ADD_LD_EXPORT_FILES');

export const getLicenseData = createAsyncThunk(
  'LOAD_LICENSE_DATA',
  fetchLicenseAndSeatsData,
);

type timeRangeParams = {
  startTime: string,
  endTime: string,
  institutionId: number,
};


export const getTopTenCourses = createAsyncThunk(
  'LOAD_TOP_10_COURSES_RANKING',
  fetchTop10Courses,
);

export const getCoursesLogins = createAsyncThunk(
  'LOAD_TOTAL_COURSES_LOGINS',
  fetchCoursesLogins,
);

export const getMonthlyEnrollments = createAsyncThunk(
  'GET_MONTHLY_ENROLLMENTS',
  fetchMonthlyEnrollments,
);

export const getEnrollmentsForPeriod = createAsyncThunk(
  'GET_ENROLLMENT_FOR_PERIOD',
  fetchEnrollmentsForPeriod,
);

export const getExportDetails = createAsyncThunk(
  'GET_EXPORT_DETAILS',
  fetchExportDetails,
);

export const getCourseEnrollmentDetails = createAsyncThunk(
  'GET_USER_ENROLLMENT_DETAILS',
  fetchCourseEnrollmentDetails,
);

export const getExportFileList = createAsyncThunk(
  'GET_LD_EXPORT_FILES',
  fetchExportFiles,
);

export const doRequestMultipleCloneCourse = createAsyncThunk(
  'CREATE_MULTIPLE_CLONE_COURSE',
  requestMultipleCloneCourse,
);

export const doRequestCloneJourney = createAsyncThunk(
  'GET_CLONE_JOURNEY',
  requestCloneJourney,
);

export const doRequestJourneyInformation = createAsyncThunk(
  'GET_JOURNEY_INFORMATION',
  requestJourneyInformation,
);
