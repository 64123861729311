import { css } from '@emotion/core';
import _ from 'underscore';
import { useFormContext } from 'react-hook-form';
import t from 'react-translate';
// components
import NvCheckbox from 'shared/components/inputs/nv-checkbox';

import baseStyles from './base-styles';

type OneOptionProps = {
  checkboxName: string,
  label: string,
  disableOn?: string[],
};

const OneOption = ({
  checkboxName,
  label,
  disableOn,
}: OneOptionProps) => {
  const styles = css`
    &.logged-in {
      ${baseStyles};
    }
  `;

  const { watch, setValue } = useFormContext();
  const [hasOneOptionChecked = false] = watch([checkboxName]);

  const onCheckboxToggle = (e) => {
    // setValue('daysInactiveChecked', false, { shouldValidate: true });
  };

  let dependentFieldChecked;
  if (disableOn?.length > 0) {
    if (watch('hasCompletedJourneyChecked')) {
      // If the option "Has completed journey" has been checked and the selected value
      // is true, the "Never active" checkbox will be disabled as it works in <NeverActive />
      const hasCompletedJourney = watch('hasCompletedJourney');
      if (hasCompletedJourney?.value) {
        dependentFieldChecked = hasCompletedJourney;
      }
    } else {
      // Watching the rest of the dependent fields
      dependentFieldChecked = disableOn.find(filter => watch(filter));
    }
  }

  return (
    <div css={styles} className={`logged-in ${hasOneOptionChecked ? 'on' : ''} ${dependentFieldChecked ? 'disabled' : ''}`}>
      <div className='checkbox-wrapper'>
        <NvCheckbox
          withForm
          name={checkboxName}
          label={label}
          onChange={onCheckboxToggle}
          labelClassName='text-large-regular pl-6'
          disabled={dependentFieldChecked}
        />
      </div>
    </div>
  );
};

export default OneOption;
