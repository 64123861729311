/* eslint-disable max-classes-per-file */

import { ComponentType, LectureComponent } from 'redux/schemas/models/lecture-component';
import prodPathReplace from 'shared/prod-path-rewrite';
import { primary } from 'styles/global_defaults/colors';

export type HeaderComponentType = ComponentType.HEADER_STYLE1 | ComponentType.HEADER_STYLE2 | ComponentType.HEADER_STYLE3;

// TODO: This is a total mess where I'm trying to satisfy the dat arequired by nv-content-template
// where it wants data placed on the model that does not match what the backend
// expects
type InitAttributes = Pick<LectureComponent<HeaderComponentType>, 'type' | 'viewOptions'>;
type DefaultData = {
  contentKey: string
  picture?: {
    cdnUrl: string,
  },
  componentClasses?: string[],
  isSample?: boolean,
} & Pick<LectureComponent<HeaderComponentType>, 'viewOptions'>;

// Adding this explicitly since our .ts files don't add @ngInject automatically
HeaderLectureComponentModel.$inject = ['_', '$translate', 'ContentTemplateBaseModel', 'config'];
/* @ngInject */
export default function HeaderLectureComponentModel(
  _,
  $translate,
  ContentTemplateBaseModel,
  config,
) {
  const HeaderLectureComponentFactory = (componentDefaults: InitAttributes, defaultData: DefaultData, sampleData: DefaultData, pendoTagName: string, toolTipKey: string) => {
    class HeaderLectureComponent extends ContentTemplateBaseModel {
      constructor(attributes, ...args) {
        const defaults = {
          ...componentDefaults,
          componentClasses: ['header'],
          hasContent: true,
          contentIsRichText: false,
          uploadSupported: false,
          placeholder: $translate.instant('LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.HEADER_PLACEHOLDER'),
        };

        super(_.extend({}, defaults, attributes), ...args);
      }
    }

    HeaderLectureComponent.DEFAULT_DATA = defaultData;
    HeaderLectureComponent.SAMPLE_DATA = sampleData;
    HeaderLectureComponent.pendoTagName = pendoTagName;
    HeaderLectureComponent.toolTipKey = toolTipKey;

    return HeaderLectureComponent;
  };

  class HeaderLectureComponentService {
    static create: (type: HeaderComponentType) => any;
  }

  HeaderLectureComponentService.create = (type: HeaderComponentType): ReturnType<typeof HeaderLectureComponentFactory> => {
    switch (type) {
      case ComponentType.HEADER_STYLE1:
        return HeaderLectureComponentFactory({
          type,
          viewOptions: {
            textColor: '#000000',
            backgroundColor: '#ffffff',
          },
        }, {
          content: '',
          viewOptions: {
            textColor: $.FE.DEFAULTS.defaultTextColor,
            backgroundColor: '#F7F8FA',
          },
        } as any, {
          contentKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.HEADER_SAMPLE_CONTENT',
          viewOptions: {
            textColor: $.FE.DEFAULTS.defaultTextColor,
            backgroundColor: '#E4E7EC',
          },
        }, config.pendo.lectureEdit.header, 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.HEADER');
      case ComponentType.HEADER_STYLE2:
        return HeaderLectureComponentFactory({
          type,
          viewOptions: {
            textColor: '#000000',
            backgroundColor: '#97CED4',
          },
        } as any, {
          content: '',
          viewOptions: {
            leftBorderColor: '#97CED4',
            backgroundColor: '#F7F8FA',
            textColor: $.FE.DEFAULTS.defaultTextColor,
          },
        } as any, {
          componentClasses: ['header', 'with-tab'],
          contentKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.HEADER_SAMPLE_CONTENT',
          viewOptions: {
            leftBorderColor: primary,
            textColor: $.FE.DEFAULTS.defaultTextColor,
            backgroundColor: '#FFFFFF',
          } as any,
        }, config.pendo.lectureEdit.borderedHeader, 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.HEADER_TAB');
      case ComponentType.HEADER_STYLE3:
        return HeaderLectureComponentFactory({
          type,
          viewOptions: {
            textColor: 'black',
            backgroundColor: 'white',
            pictureBackgroundColor: 'blue',
            pictureShape: 'square',
            libraryIconName: '',
            libraryIconColor: 'white',
          },
          smallImageSupport: true,
        } as any, {
          picture: null,
          content: '',
          viewOptions: {
            pictureBackgroundColor: primary,
            pictureShape: 'square',
            textColor: $.FE.DEFAULTS.defaultTextColor,
            backgroundColor: '#FFFFFF',
            libraryIconName: '',
            libraryIconColor: '#000000',
          },
        } as any, {
          componentClasses: ['header', 'with-image'],
          picture: null,
          contentKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.HEADER_SAMPLE_CONTENT',
          isSample: true,

          viewOptions: {
            pictureBackgroundColor: primary,
            pictureShape: 'square',

            textColor: $.FE.DEFAULTS.defaultTextColor,
            backgroundColor: ' #E4E7EC',

            libraryIconName: 'images',
            libraryIconColor: 'white',
          },
        }, config.pendo.lectureEdit.pictureHeader, 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.HEADER_IMAGE');
      default:
        throw new Error(`Invalid header type ${type} given to angular header service`);
    }
  };

  return HeaderLectureComponentService;
}
