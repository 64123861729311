import React, { useContext } from 'react';
import t from 'react-translate';
import { useSelector } from 'react-redux';

// Selectors
import { getCourse, getCurrentCourse } from 'redux/selectors/course';

// contexts
import { AngularServicesContext } from 'react-app';

const ExtraTermsInfo = () => {
  const { $state } = useContext(AngularServicesContext);
  const currentCourse = useSelector((state) => getCurrentCourse(state));

  return (
    <React.Fragment>
      {currentCourse?.isTosRequiredForCourseComms && currentCourse?.hasExtraTerms && (
        <div className='text-small text-gray-3 text-center'>{t.COURSE_COMMUNICATIONS.NOTE_FOR_EXTRA_TERMS()}</div>
      )}
    </React.Fragment>
  );
};

export default ExtraTermsInfo;
