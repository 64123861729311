import React from 'react';
import moment from 'moment';
import t from 'react-translate';

import { PeerEvaluation } from 'redux/schemas/models/peer-evaluation';
import NvIcon from 'shared/components/nv-icon';

import { CheckIcon, todoIcon, todoProgress } from './todo-row';
import ProgressIcon from '../progress-icon';

type PeerReviewTodoProps = PeerEvaluation & {
  thumbnailSrc: string,
  backgroundColor: string,
};

function PeerReviewTodo({
  type,
  title,
  progress,
  expirationDate,
  isFeedbackPublic,
  thumbnailSrc,
  backgroundColor,
}: PeerReviewTodoProps) {
  const deadlineMoment = moment(expirationDate);
  const deadlineDay = deadlineMoment.format('dddd');
  const deadlineTime = deadlineMoment.format('lll');
  const currentMoment = moment();

  const isComplete = progress === todoProgress.COMPLETED;
  const inProgress = progress === todoProgress.IN_PROGRESS;
  const isNotStarted = progress === todoProgress.NOT_STARTED;
  const isMissed = progress === todoProgress.MISSED;

  const iconType = `${isFeedbackPublic ? 'public' : ''}${type}`;
  const icon = todoIcon[iconType];
  const showTodoIcon = isNotStarted || isMissed;

  const showDueSoon = expirationDate
    && (inProgress || isNotStarted)
    && deadlineMoment > currentMoment
    && deadlineMoment < moment().add(7, 'days');

  const todoClassNames = [
    'todo-container',
    isComplete && 'completed',
    inProgress && 'in-progress',
    showDueSoon && 'due-soon',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={todoClassNames}>
      <div className='todo-description'>
        <div className='todo-cover'>
          {thumbnailSrc
            ? <img src={thumbnailSrc} alt='' />
            : <div className='square-thumbnail' style={{ backgroundColor }} />}
        </div>
        <div className='todo-icon'>
          {showTodoIcon && <NvIcon icon={icon} size='smallest' />}
          {isComplete && <CheckIcon />}
          {inProgress && (
            <ProgressIcon>
              <NvIcon icon={icon} size='smallest' />
            </ProgressIcon>
          )}
        </div>
        <div className='todo-title'>
          {title}
        </div>
      </div>
      <div className='todo-content'>
        <div className='status'>
          {isComplete && t.TIMELINE.EXERCISE_STATUS.SUBMITTED()}
          {inProgress && t.TIMELINE.EXERCISE_STATUS.IN_PROGRESS()}
          {isMissed && t.TIMELINE.EXERCISE_STATUS.MISSED()}
        </div>
        {expirationDate && (
          <div className='date'>
            <span className='day'>{deadlineDay}</span>
            <span className='time'>{deadlineTime}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default PeerReviewTodo;
