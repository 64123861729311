import store from '../../../redux/store';
import { setComponentLibraryState } from '../../../redux/actions/lecture-pages';

/* @ngInject */
export default function LectureComponentsHelper(
  _,
  $uibModal,
  validationConstants,

  CurrentCourseManager,
) {
  return {
    showEditModal: (lectureComponent, modalOptions = {}) => $uibModal.open({
      templateUrl: modalOptions.editFormTemplateUrl || lectureComponent.editFormTemplateUrl,
      controller: modalOptions.editFormController || lectureComponent.editFormController || 'LectureComponentModalCtrl',
      controllerAs: 'vm',
      windowClass: modalOptions.windowClass || 'discussions-modal',
      keyboard: false,
      resolve: {
        vmResolves: _.extend({
          CurrentCourseManager,
          validationConstants,
          lectureComponent,
          formName: lectureComponent.formName,
        }, modalOptions),
      },
    }),
    showSavingOverlay: (lectureComponent) => {
      const modalInstance = $uibModal.open({
        templateUrl: 'lecture_pages/templates/saving-overlay.html',
        backdropClass: 'modal-overlay-backdrop',
        windowClass: 'modal-overlay',
        keyboard: false,
        controller: 'AttachModalResolvesToVmCtrl as vm',
        resolve: {
          vmResolves: {
            lectureComponent,
          },
        },
      });

      return function closeModal(shouldUpdateExercises) {
        modalInstance.close();

        modalInstance.rendered.then(() => {
          modalInstance.close();
        });
      };
    },
  };
}
