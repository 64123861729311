import React, { useState } from 'react';
import { css } from '@emotion/react';
import { RootState, CombinedInstitution } from 'redux/schemas';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import * as yup from 'yup';
import { each, findWhere, some } from 'underscore';
import { useAppDispatch } from 'redux/store';

// Actions
import { addAlertMessage } from 'redux/actions/alert-messages';
import { addMultipleMenteesToMentor } from 'redux/actions/org-mentors';

// Helpers
import { CSVHeader, downloadCSVFromJson, ErrorType, parseCSV } from 'shared/csv-utils';

// Schemas
import { AlertMessageType } from 'redux/schemas/app/alert-message';

// Selectors
import { getRoleAliases, getCurrentInstitution } from 'redux/selectors/institutions';

// Styles
import { doubleSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';

// Components
import NvFilePicker from 'shared/components/nv-filepicker';
import { Button } from 'react-bootstrap';

const styles = css`
  & {
    padding: 0 ${doubleSpacing}px;
  }
  .description {
    margin-bottom: ${tripleSpacing}px;
  }

  .row-no {
    width: 80px;
  }
  .column-no {
    width: 100px;
  }
`;

type MentorBulkUploadModalProps = {
  closeModal(): void,
};

const MentorBulkUploadModal = (props: MentorBulkUploadModalProps) => {
  const { closeModal } = props;
  const [validationErrors, setValidationErrors] = useState([]);
  const [validRows, setValidRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const currentInstitution = useSelector<RootState, CombinedInstitution>(getCurrentInstitution);
  const roleAliases = useSelector(getRoleAliases);

  const dispatch = useAppDispatch();

  // Adding nullable with email to handle empty field value.
  const validationSchema = yup.object().shape({
    mentorFirstName: yup.mixed().required(t.VALIDATION.REQUIRED()),
    mentorLastName: yup.mixed().required(t.VALIDATION.REQUIRED()),
    mentorEmail: yup.string().nullable()
      .required(t.VALIDATION.REQUIRED())
      .email(t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.INVALID_EMAIL()),
    learnerFirstName: yup.mixed().required(t.VALIDATION.REQUIRED()),
    learnerLastName: yup.mixed().required(t.VALIDATION.REQUIRED()),
    learnerEmail: yup.string().nullable()
      .required(t.VALIDATION.REQUIRED())
      .email(t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.INVALID_EMAIL()),
  });

  const headers: CSVHeader[] = [
    {
      key: 'mentorFirstName',
      headerName: 'Mentor First Name',
    },
    {
      key: 'mentorLastName',
      headerName: 'Mentor Last Name',
    },
    {
      key: 'mentorEmail',
      headerName: 'Mentor Email',
    },
    {
      key: 'learnerFirstName',
      headerName: 'Learner First Name',
    },
    {
      key: 'learnerLastName',
      headerName: 'Learner Last Name',
    },
    {
      key: 'learnerEmail',
      headerName: 'Learner Email',
    },
  ];

  if (currentInstitution.ssoLogin) {
    // Adding mentor External ID  after the Mentor Email
    headers.splice(3, 0, {
      key: 'mentorExternalId',
      headerName: 'Mentor External ID',
    });

    headers.push({
      key: 'learnerExternalId',
      headerName: 'Learner External ID',
    });
  }

  const downloadTemplate = () => {
    downloadCSVFromJson({
      headers,
      dummyRows: {
        totalRows: 2,
        values: {
          mentorFirstName: ['John', 'John'],
          mentorLastName: ['Doe', 'Doe'],
          mentorEmail: ['Johndoe@johndoe.com', 'Johndoe@johndoe.com'],
          learnerFirstName: ['James', 'James'],
          learnerLastName: ['Blunt', 'Blunt2'],
          learnerEmail: ['Jamesblunt@jamesblunt.com', 'Jamesblunt2@jamesblunt.com'],
        },
      },
    });
  };

  const onFileError = () => {
    setValidRows([]);
    setValidationErrors([]);

    dispatch(addAlertMessage({
      type: AlertMessageType.ERROR,
      header: t.FORM.OOPS(),
      message: t.FILE_UPLOAD.NOT_SUPPORTED_ALERT(),
    }));
  };

  const parseCallback = (response) => {
    if (response.errors.length > 0) {
      setValidRows(response.data);

      if (some(response.errors, (error) => error.type === ErrorType.VALIDATION)) {
        setValidationErrors(response.errors);
      } else {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.NO_USERS(),
        }));
      }
      setLoading(false);
    } else if (response.data.length > 0) {
      submit(response.data);
    }
  };

  const onCSVSelected = (files: File[]) => {
    setValidRows([]);
    setValidationErrors([]);
    const [file] = files;

    if (!file) {
      return;
    }
    setLoading(true);

    parseCSV({
      headers,
      file,
      validationSchema,
      callback: parseCallback,
    });
  };

  // Submitting the CSV values.
  const submit = (rows) => {
    const FilteredMentorings = [];
    each(rows, (row) => {
      const findRow = findWhere(FilteredMentorings, { email: row.mentorEmail });

      if (findRow) {
        findRow.mentees.push({
          firstName: row.learnerFirstName,
          lastName: row.learnerLastName,
          email: row.learnerEmail,
          externalId: row.learnerExternalId,
        });
      } else {
        const mentor = {
          firstName: row.mentorFirstName,
          lastName: row.mentorLastName,
          email: row.mentorEmail,
          externalId: row.mentorExternalId,
          mentees: [
            {
              firstName: row.learnerFirstName,
              lastName: row.learnerLastName,
              email: row.learnerEmail,
              externalId: row.learnerExternalId,
            },
          ],
        };
        FilteredMentorings.push(mentor);
      }
    });

    dispatch(addMultipleMenteesToMentor({
      institutionId: currentInstitution.id,
      data: {
        overwrite: true,
        mentorings: FilteredMentorings,
      },
    })).then((response: any) => {
      setLoading(false);
      closeModal();

      const responseResult = response.payload?.result;

      if (response.error) {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.SOMETHING_WRONG(),
          message: t.FORM.ERROR_TRY_AGAIN(),
        }));
      } else if (responseResult?.threshold) {
        dispatch(addAlertMessage({
          type: AlertMessageType.INFO,
          header: t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.WARNING_HEADER(),
          message: t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.WARNING_MESSAGE(),
        }));
      } else {
        let messageKey = 'DEFAULT';
        let alertType = AlertMessageType.SUCCESS;

        if (responseResult?.created?.length === 0) {
          messageKey = 'ONLY_SKIP';
          alertType = AlertMessageType.INFO;
        } else if (responseResult?.existing?.length === 0
          && responseResult?.data?.existingMentorsCount === 0) {
          messageKey = 'ONLY_ADDED';
        } else if (responseResult?.existing?.length === 0
          && responseResult?.data?.newMentorsCount === 0) {
          messageKey = 'ONLY_EXISTING';
        } else if (responseResult?.existing?.length === 0) {
          messageKey = 'ADDED_WITH_EXISTING';
        } else if (responseResult?.data?.newMentorsCount
          && responseResult?.data?.existingMentorsCount) {
          messageKey = 'ADDED_WITH_EXISTING_AND_SKIP';
        } else if (responseResult?.data?.newMentorsCount) {
          messageKey = 'ADDED_WITH_SKIP';
        } else if (responseResult?.data?.existingMentorsCount) {
          messageKey = 'EXISTING_WITH_SKIP';
        }

        dispatch(addAlertMessage({
          type: alertType,
          header: alertType === AlertMessageType.SUCCESS ? t.FORM.SUCCESS_BANG() : null,
          message: t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.SUCCESS_ALERT[messageKey]({
            newMentorsCount: responseResult?.data?.newMentorsCount || 0,
            existingMentorsCount: responseResult?.data?.existingMentorsCount || 0,
            existingMenteeCount: responseResult?.existing?.length || 0,
            mentorAlias: roleAliases?.mentor?.downcasedSingularized,
            menteesAlias: roleAliases?.mentee?.downcasedPluralized,
            menteeAlias: roleAliases?.mentee?.downcasedSingularized,
          }),
        }));
      }
    });
  };

  return (
    <div className='d-flex flex-column mx-auto' css={styles}>
      {validationErrors.length ? (
        <React.Fragment>
          <p className='description mb-2 text-body'>
            {t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.ERROR_DESCRIPTION(validationErrors.length)}
          </p>
          <table className='mb-5'>
            <tbody>
              {validationErrors.map((error) => (
                <tr
                  className='font-weight-normal border-bottom border-gray-5'
                  key={`${error.row}-${error.column}`}
                >
                  <td className='py-2 row-no'>{t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.ROW(error.row)}</td>
                  <td className='py-2 column-no'>{t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.COLUMN(error.column)}</td>
                  <td className='py-2 text-danger'>{error.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='d-flex mx-auto mb-4 mt-5'>
            <NvFilePicker
              multiple={false}
              accept={['.csv', 'text/csv']}
              onChange={(e) => onCSVSelected(e)}
              onSelectError={onFileError}
            >
              <Button
                variant='secondary'
                className='font-weight-bold'
                disabled={loading}
              >
                {t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.UPLOAD_NEW_CSV()}
              </Button>
            </NvFilePicker>
            <Button
              className='ml-1'
              onClick={() => submit(validRows)}
              disabled={validRows.length === 0}
            >
              {t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.PROCEED_WITH_THE_REST()}
            </Button>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p className='description text-body'>
            {t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.DESCRIPTION({
              MentorAlias: roleAliases.mentor.downcasedPluralized,
              MenteeAlias: roleAliases.mentee.downcasedPluralized,
            })}
          </p>
          <div className='d-flex mx-auto mb-5'>
            <Button
              variant='secondary'
              className='font-weight-bold'
              onClick={downloadTemplate}
            >
              {t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.DOWNLOAD_TEMPLATE()}
            </Button>
            <NvFilePicker
              multiple={false}
              accept={['.csv', 'text/csv']}
              onChange={(e) => onCSVSelected(e)}
              onSelectError={onFileError}
            >
              <Button className='ml-1' disabled={loading}>
                {t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.UPLOAD_CSV()}
              </Button>
            </NvFilePicker>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default MentorBulkUploadModal;
