import { css } from '@emotion/react';
import React, { MutableRefObject, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { find, values } from 'underscore';
import { CourseRolesParams, CourseRolesUpdateParams } from 'redux/schemas/api/org-level-roles';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { RolesNormalized } from 'redux/schemas/models/role';
import { RootState } from 'redux/schemas';
import { addCourseRoles, updateCourseRole } from 'redux/actions/org-level-roles';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvDropdown, { NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { PermissionTypes, RolesService } from 'institutions/services/roles-service';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import NvPopover from 'shared/components/nv-popover';
import NvIcon from 'shared/components/nv-icon';
import { halfSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { RolePermission } from 'redux/schemas/models/org-level-roles';
import { aliases, getTranslateKey } from 'shared/services/i18n-utils';
import { permissionInfo } from './course-roles';

type CourseRoleFormProps = {
  institutionId: number
  closeForm?: () => void
  addedCallback?: (id: number) => void
  roleId?: number
  nameRef?: MutableRefObject<HTMLInputElement>
  formData: {
    name?: string
    menteeName?: string
    badge?: string
    permissions: { [key: string]: boolean }
  }
};

const styles = (hasMentorPermission) => css`
  .nv-dropdown {
    flex-grow: 1;
    margin-right: ${halfSpacing}px;

    .icon-dropdown-arrow {
      margin-left: ${tripleSpacing}px;
    }
  }

  .name-input {
    width: ${hasMentorPermission ? 15 : 30}%;
  }

  .mentee-name-input {
    width: 15%;
  }

  .badge-input {
    width: 20%;
  }
`;

export const CourseRoleInput = ({
  institutionId,
  closeForm,
  addedCallback,
  formData,
  roleId,
  nameRef,
}: CourseRoleFormProps) => {
  const [isSaving, setIsSaving] = useState(false);

  const allRoles = useSelector<RootState, RolesNormalized>(state => state.models.roles);
  const dispatch = useAppDispatch();
  const { $scope } = React.useContext(AngularServicesContext);
  const mentorPermission = find(RolesService.permissions, (p) => p.type === PermissionTypes.MENTOR);

  const roleNamesArray = values(allRoles)
    .filter(allRole => roleId !== allRole.id)
    .map(role => role.name);

  const validationSchema = yup.object().shape({
    name: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .min(2, t.VALIDATION.MIN_LENGTH('2'))
      .max(64, t.VALIDATION.MAX_LENGTH('64'))
      .notOneOf(roleNamesArray, t.INSTITUTIONS.ROLES.UNIQUE_ROLE_NAME()),
    menteeName: yup.string()
      .when(`permissionOptions.${mentorPermission.apiName}`, {
        is: true,
        then: yup.string().required(t.VALIDATION.REQUIRED()),
      }),
    badge: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .min(1, t.VALIDATION.MIN_LENGTH('1'))
      .max(2, t.VALIDATION.MAX_LENGTH('2')),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: formData?.name ?? '',
      menteeName: formData?.menteeName ?? '',
      badge: formData?.badge ?? '',
      permissionOptions: formData?.permissions,
    },
  });

  const { formState, handleSubmit, watch, control } = methods;
  const { permissionOptions, badge, name, menteeName } = watch();
  const { isValid, isDirty } = formState;

  useEffect(() => {
    const deRegisterStateChangeStart = $scope.StateManager.registerStateChangeStart(
      () => isDirty,
      'shared/templates/modal-navigate-away.html',
      'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY',
    );

    return () => {
      deRegisterStateChangeStart();
    };
  }, [$scope.StateManager, isDirty]);

  const courseRoleItems: NvDropdownOption[] = RolesService.permissions
    .filter(allPermission => allPermission.name)
    .map(permission => ({
      type: 'custom',
      customItem: <CourseRoleItem permission={permission} />,
    }));

  const onSubmit = () => {
    setIsSaving(true);
    const permissionsList = RolesService.permissions.filter(role => permissionOptions?.[role.apiName]);
    const data = {
      badge,
      institutionId,
      name,
      menteeName,
      permissionOptions: RolesService.makePermissionOptions(permissionsList),
      roleId,
    };
    if ((data as CourseRolesUpdateParams).roleId) {
      dispatch(updateCourseRole(data))
        .then(() => {
          setIsSaving(false);
          closeForm();
        });
    } else {
      dispatch(addCourseRoles(data as CourseRolesParams))
        .then((response: any) => {
          setIsSaving(false);
          closeForm();
          addedCallback(response.payload?.id);
        });
    }
  };

  const hasMentorPermission = permissionOptions[mentorPermission.apiName];
  const permissionsCount = values(permissionOptions).filter(value => value).length;
  const dropdownTitle = permissionsCount > 0
    ? t.INSTITUTIONS.ROLES.SELECTED_COUNT(permissionsCount)
    : t.INSTITUTIONS.ROLES.NO_PERMISSIONS();
  const titleClass = permissionsCount > 0 ? 'text-warning' : 'text-black';

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='d-flex align-items-center w-100'
      >
        <div className='d-flex w-100' css={styles(hasMentorPermission)}>
          <NvTextInput
            withForm
            name='name'
            placeholder={t.INSTITUTIONS.ROLES[hasMentorPermission ? 'MENTOR_PLACEHOLDER' : 'COURSE_ROLE_NAME']()}
            required
            className='name-input pr-2'
            ref={nameRef}
          />
          {hasMentorPermission && (
            <NvPopover
              content={<span>{t.INSTITUTIONS.ROLES.MENTEE_DESC()}</span>}
              placement='top'
              showOnHover
              className='mentee-name-input pr-2'
            >
              <NvTextInput
                withForm
                name='menteeName'
                placeholder={t.INSTITUTIONS.ROLES.MENTEE_PLACEHOLDER()}
                required
              />
            </NvPopover>
          )}
          <NvTextInput
            withForm
            name='badge'
            placeholder={t.INSTITUTIONS.ROLES.BADGE()}
            required
            className='badge-input pr-2'
          />
          <NvDropdown
            items={courseRoleItems}
            buttonStyle={NvDropdownButtonStyle.FORM}
            title={dropdownTitle}
            titleClass={`font-weight-bolder ${titleClass}`}
            menuClassName='p-2'
            withForm
          />
          <div className='d-flex py-1'>
            <Button
              type='submit'
              className='mr-2'
              disabled={!isDirty || !isValid || isSaving}
            >
              {isSaving ? t.FORM.SAVING() : t.FORM.SAVE()}
            </Button>
            <Button
              variant='secondary'
              onClick={closeForm}
            >
              {t.FORM.CANCEL()}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const CourseRoleItem = (props: { permission: RolePermission }) => {
  const [showPopover, setShowPopover] = useState(false);

  return (
    <div className='d-flex p-1 mb-1' key={props.permission.value}>
      <NvCheckbox
        withForm
        name={`permissionOptions.${props.permission.apiName}`}
        label={t.INSTITUTIONS.ROLES[`${getTranslateKey(props.permission.name)}`](aliases)}
        labelClassName='text-regular mr-1'
      />
      <div
        onMouseEnter={() => setShowPopover(true)}
        onMouseLeave={() => setShowPopover(false)}
      >
        <NvPopover
          content={permissionInfo(props.permission.helpText)}
          placement='top'
          preventOverflow={false}
          className='py-1'
          offset={5}
          show={showPopover}
        >
          <div>
            <NvIcon icon='info' size='xss-smallest' className='color-primary' />
          </div>
        </NvPopover>
      </div>
    </div>
  );
};

export default CourseRoleInput;
