import ClickableContainer from 'components/clickable-container';
import React from 'react';

type Props = {
  title: string,
  titleclassName: string,
  activity: string,
  activityClassName: string,
  gotoActivty?: () => void
  dataQa?: string
};

/**
 * This component is part of lecture component feedback activities UI, it is meant to
 * display the link to activty for which the feedback to be given.
 * NOTE: This component allows you to pass the title and the name of the activity (eg: assignment/video practice)
 */
const ForActivityTitle = ({
  title,
  activity,
  titleclassName,
  activityClassName,
  gotoActivty,
  dataQa,
}: Props) => (
  <div className='d-flex justify-content-center w-100 flex-column flex-md-row align-items-center px-2'>
    <div className={titleclassName}>{title}</div>
    <ClickableContainer
      className={activityClassName}
      onClick={() => gotoActivty?.()}
      data-qa={dataQa}
    >{activity}
    </ClickableContainer>
  </div>
);


export default ForActivityTitle;
