import { schema } from 'normalizr';
import { IdentityProvider, UserLogin } from '../models/sign-in';

export interface IdentityProvidersNormalized {
  [id: string]: IdentityProvider;
}

export interface IIdentityProviderEntities {
  providers: IdentityProvidersNormalized,
}

const identityProvidersSchema = new schema.Entity<IdentityProvider>('providers');
export const providersSchema = new schema.Array(identityProvidersSchema);

export const userLoginSchema = new schema.Entity<UserLogin>('login');

export type FetchProvidersParams = {
  email: string;
};

export type LoginParams = {
  email: string;
  password: string;
};

export type DomainParams = {
  domain: string;
};

type DomainInstitutionResponse = {
  id: number,
  identityProviders: IdentityProvider[],
  name: string,
  ssoLogin: boolean,
};

export type DomainResponse = {
  host: string,
  institution: DomainInstitutionResponse,
  name: string,
};

export interface IdentityProvidersResponse {
  providers: IdentityProvidersNormalized;
}
