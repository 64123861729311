import { css } from '@emotion/react';
import { isCourseModalViewed, setCourseModalViewed } from 'course_home/services/course-modal-viewed';
import JourneyCardLite from 'offerings/components/journey-card-lite';
import OfferingCard from 'offerings/components/offering-card';
import React from 'react';
import { AngularContext } from 'react-app';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import {
  fetchCourseCompletionStatus,
  setShowCourseCompletionStatusModal,
} from 'redux/actions/courses';
import { CourseCompletionStatusState } from 'redux/schemas/app/course-completion-status';
import { VisitedLearningJourneySchema } from 'redux/schemas/app/learning-journey';
import { Course, CourseAliases } from 'redux/schemas/models/course';
import { getCourseAliases, getCourseCompletionStatus, getCurrentCourse } from 'redux/selectors/course';
import { getJourney, getVisitedLearningJourney } from 'redux/selectors/learning-journeys';
import { getCurrentEnrollment } from 'redux/selectors/users';
import { useAppDispatch } from 'redux/store';
import LoadingPlaceholder from 'shared/components/loading-placeholder';
import NvModal, { BackdropType, ModalType } from 'shared/components/nv-modal';
import useAngularExpression from 'shared/hooks/use-angular-expression';
import prodPathReplace from 'shared/prod-path-rewrite';
import { primary } from 'styles/global_defaults/colors';
import {
  lightFontWeight,
  normalFontWeight,
  openSans,
  semiBoldFontWeight,
  textExtraLargeFontSize,
  textLargeFontSize,
  textMediumFontSize,
  textSmallFontSize,
} from 'styles/global_defaults/fonts';
import { handheld } from 'styles/global_defaults/media-queries';
import { halfSpacing, journeyCardWidth, standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { isEmpty } from 'underscore';
import { updateVisitedJourney } from 'redux/actions/learning-journeys';
import { config } from '../../../config/pendo.config.json';

enum CompletionCases {
  FIRST_CASE,
  SECOND_CASE,
  THIRD_CASE,
}

type ModalBodyProps = {
  showConfetti?: boolean;
  hasBadge: boolean;
  image?: {
    name: string;
    alt: string;
  } ;
  headerText?: string;
  topMessage?: {
    first: string;
    second?: string;
  };
  card?: React.ReactElement;
  hasJourneyLink?: boolean;
  journeyLink?: string;
  hasCourseButton?: boolean;
  courseLink?: string;
  bottomMessage?: string;
};

type RelatedJourneyCardProps = {
  info: VisitedLearningJourneySchema;
  $state: any;
};

// Extracting the ModalBody as a functional component to render content based on cases
const ModalBody = ({
  showConfetti = true,
  hasBadge,
  image,
  headerText = t.OFFERINGS.CARD.CONGRATULATIONS(),
  topMessage,
  card,
  hasJourneyLink = false,
  journeyLink,
  hasCourseButton = false,
  courseLink,
  bottomMessage = '',
}: ModalBodyProps) => {
  const { injectServices } = React.useContext(AngularContext);
  const [ConfettiAnimation] = injectServices(['ConfettiAnimation']);
  const { courseAliases }: CourseAliases = useSelector(getCourseAliases);
  const courseCompletionStatus: CourseCompletionStatusState = useSelector(getCourseCompletionStatus);

  React.useEffect(() => {
    if (
      courseCompletionStatus.modal.show && !courseCompletionStatus.modal.isLoading && showConfetti
    ) {
      ConfettiAnimation?.showConfetti();
    }
  }, []);

  const styles = css`
    font-family: ${openSans};
    gap: ${standardSpacing}px;
    margin-bottom: ${standardSpacing}px;

    ${handheld(css`
      margin: 0;
    `)};

    p {
      margin: 0;
    }

    .image-badge {
      position: absolute;
      top: 0;
      // modal header height: 60px + badge height/2: 60px;
      transform: translate(0%, -${tripleSpacing * 2}px);
      width: ${tripleSpacing * 2}px;
    }

    .header, .top-message, .bottom-message {
      text-align: center;
    }

    .header {
      font-size: ${textExtraLargeFontSize}px;
      font-weight: ${lightFontWeight};
    }

    .top-message {
      font-size: ${textLargeFontSize}px;
      font-weight: ${normalFontWeight};
    }

    #journey-card-lite-cover {
      width: ${journeyCardWidth}px;
      max-width: 100%;
    }

    .bottom-message {
      font-size: ${textMediumFontSize}px;
      font-weight: ${normalFontWeight};

      a {
        color: ${primary};
      }
    }

    .journey-link {
      color: ${primary};
      font-weight: ${semiBoldFontWeight};
    }
  `;

  return (
    <div
      css={styles}
      className='d-flex flex-column justify-content-center align-items-center'
    >
      {hasBadge && (
        <img
          className='image-badge'
          src={prodPathReplace('images/congratulations.png')}
          alt={t.LEARNING_JOURNEYS.COURSE_HOME.MODAL.CONGRATULATIONS()}
        />
      )}
      {image?.name && (
        <img
          src={prodPathReplace(`images/${image.name}`)}
          alt={image?.alt ?? ''}
        />
      )}
      <header className='header'>{headerText}</header>
      {topMessage?.first && (
        <div className='top-message'>
          {topMessage.first && <p>{topMessage.first}</p>}
          {topMessage.second && <p>{topMessage.second}</p>}
        </div>
      )}
      {card ?? null}
      {bottomMessage && <p className='bottom-message'>{bottomMessage}</p>}
      {hasCourseButton && (
        <Button
          variant='primary'
          href={courseLink}
          data-qa={config.pendo.course.backToCourseHome}
        >
          {t.LEARNING_JOURNEYS.COURSE_HOME.MODAL.BACK_TO_COURSE_HOME({ ...courseAliases })}
        </Button>
      )}
      {hasJourneyLink && (
        <a href={journeyLink} className='journey-link' data-qa={config.pendo.course.backToJourneyHome}>
          {t.LEARNING_JOURNEYS.COURSE_HOME.MODAL.BACK_TO_JOURNEY_HOME()}
        </a>
      )}
    </div>
  );
};

// Extracting the RelatedJourneyCard as a functional component to be use in multiple modal cases
const RelatedJourneyCard = ({ info, $state }: RelatedJourneyCardProps) => (
  <JourneyCardLite
    variant='cover'
    name={info?.name}
    releaseDate={info?.releaseDate}
    closeDate={info?.closeDate}
    completionStats={info?.completionStats}
    journeyHomeLink={$state.href('learning-journey-home', {
      catalogId: info?.catalogId,
    })}
    headerColor={info?.headerColor}
    headerBackground={info?.headerBackground}
  />
);

export const CourseCompletionStatusModal = () => {
  const { injectServices } = React.useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const dispatch = useAppDispatch();
  const currentStateName = useAngularExpression(() => $state.current.name);
  const catalogId: string = useSelector(state => state.app.currentCatalogId);
  const { courseAliases, lectureAliases }: CourseAliases = useSelector(getCourseAliases);
  const visitedJourneyInfo: VisitedLearningJourneySchema = useSelector(
    getVisitedLearningJourney,
  );
  const courseCompletionStatus: CourseCompletionStatusState = useSelector(getCourseCompletionStatus);
  const enrollment = useSelector(getCurrentEnrollment);
  const isCourseComplete = enrollment?.completionStatus;
  const { nextCourseButtonEnabled } = useSelector(getCurrentCourse);
  const onClose = () => dispatch(setShowCourseCompletionStatusModal(false));

  // Checking if we keep track of the visited learning journey
  const visitedJourneyId = visitedJourneyInfo?.catalogId;
  const visitedJourneyLink = !visitedJourneyId ? '' : ($state.href('learning-journey-home', {
    catalogId: visitedJourneyId,
  }));
  const currentJourneyInfo = useSelector(state => getJourney(state, visitedJourneyId));

  const courseLink = $state.href('course-home', { catalogId });

  // Cheking if there's an updated data of the visited journey
  if (visitedJourneyId && courseCompletionStatus?.journey?.catalogId === visitedJourneyId) {
    const updatedVisitedJourney = courseCompletionStatus?.journey;

    if (!isEmpty(updatedVisitedJourney?.completionStats)) {
      dispatch(updateVisitedJourney(updatedVisitedJourney));
    }
  }

  // Cheking if it's a course home
  const courseRelatedPaths = [
    'course-home',
    'course-home-edit',
    'program-home',
  ];
  const isCourseHome = courseRelatedPaths.includes(currentStateName);

  // Should show the course completion status modal for the course home when the user has not viewed it by selecting the ‘Next’ button on the last lesson page
  React.useEffect(() => {
    const autoEnrollEnabled = currentJourneyInfo?.autoEnrollment ?? false;
    const shouldShowInCourseHome = isCourseHome && isCourseComplete && (nextCourseButtonEnabled || autoEnrollEnabled) && !isCourseModalViewed(enrollment, catalogId);

    if (shouldShowInCourseHome) {
      setCourseModalViewed(enrollment, catalogId);
      dispatch(setShowCourseCompletionStatusModal(true));
    }
  }, [catalogId, dispatch, enrollment, isCourseComplete, isCourseHome, currentJourneyInfo?.autoEnrollment]);

  // Getting the course completion status data
  React.useEffect(() => {
    if (courseCompletionStatus.modal.show) {
      dispatch(fetchCourseCompletionStatus({ catalogId, journeyId: visitedJourneyId ?? '' }));
    }
  }, [catalogId, courseCompletionStatus.modal.show, dispatch, visitedJourneyId]);

  const modalStyles = css`
    .course-completion-status-modal {
      overflow: initial;
    }

    .bs4-modal-dialog {
      ${handheld(css`
        height: auto !important;
        top: initial !important;
      `)};
    }

    .bs4-modal-content {
      border-radius: ${halfSpacing}px;
      border: none;
      outline: none;
    }

    .icon-close {
      font-size: ${textSmallFontSize}px;
    }
  `;
  const modalBodyStyles = css`
    padding: 0 ${standardSpacing}px;

    ${handheld(css`
      padding: 0;
    `)};
  `;

  // Identifying the cases to display the corresponding content in the Modal based on UI design
  const getCompletionCase = () => {
    const {
      journeyCompleted = false,
      courseCompleted = false,
    } = courseCompletionStatus.completionInfo;

    if (courseCompleted) {
      return journeyCompleted ? CompletionCases.SECOND_CASE : CompletionCases.FIRST_CASE;
    }

    return CompletionCases.THIRD_CASE;
  };

  // Defining the content to be rendered in the modal body
  const getModalBody = () => {
    const completionCase = getCompletionCase();

    // Content when we don´t know the visited learning journey
    if (!visitedJourneyId && completionCase !== CompletionCases.THIRD_CASE) {
      const learnerDashboardLink = $state.href('dashboard');
      const journeysCount = courseCompletionStatus?.additionalJourneysCount;

      return (
        <ModalBody
          hasBadge
          topMessage={{
            first: t.LEARNING_JOURNEYS.COURSE_HOME.MODAL.YOU_HAVE_COMPLETED_THIS_COURSE({ ...courseAliases }),
            second: t.LEARNING_JOURNEYS.COURSE_HOME.MODAL.CONTINUE_WITH_JOURNEYS(),
          }}
          card={(
            <RelatedJourneyCard
              info={
                courseCompletionStatus?.journey
              }
              $state={$state}
            />
          )}
          bottomMessage={t.LEARNING_JOURNEYS.COURSE_HOME.MODAL.COURSE_BELONGS_TO_OTHER_JOURNEYS({ ...courseAliases, journeysCount, learnerDashboardLink })}
        />
      );
    }

    // Content when we do know the visited learning journey
    switch (completionCase) {
      case CompletionCases.FIRST_CASE:
        if (!isEmpty(courseCompletionStatus?.journey) && isEmpty(courseCompletionStatus?.journey?.nextCourse)) {
          return (
            <ModalBody
              hasBadge
              topMessage={{
                first: t.LEARNING_JOURNEYS.COURSE_HOME.MODAL.YOU_HAVE_COMPLETED_THIS_COURSE({ ...courseAliases }),
                second: t.LEARNING_JOURNEYS.COURSE_HOME.MODAL.CONTINUE_WITH_JOURNEY(),
              }}
              card={<RelatedJourneyCard info={courseCompletionStatus?.journey} $state={$state} />}
            />
          );
        }

        if (!isEmpty(courseCompletionStatus?.journey?.nextCourse)) {
          return (
            <ModalBody
              hasBadge
              topMessage={{
                first: t.LEARNING_JOURNEYS.COURSE_HOME.MODAL.YOU_HAVE_COMPLETED_THIS_COURSE({ ...courseAliases }),
                second: t.LEARNING_JOURNEYS.COURSE_HOME.MODAL.YOUR_NEXT_COURSE_IN_JOURNEY({ ...courseAliases }),
              }}
              card={(
                <OfferingCard
                  parent={courseCompletionStatus?.journey as Course}
                  offering={courseCompletionStatus?.journey?.nextCourse}
                  journey={visitedJourneyInfo}
                  key={courseCompletionStatus?.journey?.nextCourse?.id}
                />
              )}
              hasJourneyLink
              journeyLink={visitedJourneyLink}
            />
          );
        }

        return null;

      case CompletionCases.SECOND_CASE:
        return (
          <ModalBody
            hasBadge
            topMessage={{
              first: t.LEARNING_JOURNEYS.COURSE_HOME.MODAL.YOU_HAVE_COMPLETED_THIS_COURSE_AND_JOURNEY({ ...courseAliases }),
            }}
            card={(
              <OfferingCard
                parent={courseCompletionStatus?.journey as Course}
                offering={courseCompletionStatus?.journey as Course}
                journey={visitedJourneyInfo}
                key={courseCompletionStatus?.journey?.id}
                useJourneyInferedCompletion
              />
            )}
          />
        );

      case CompletionCases.THIRD_CASE:
        return (
          <ModalBody
            showConfetti={false}
            hasBadge={false}
            image={{
              name: 'no-lectures.png',
              alt: t.LEARNING_JOURNEYS.COURSE_HOME.MODAL.NO_LECTURES_AVAILABLE({ ...lectureAliases }),
            }}
            headerText={`${t.LEARNING_JOURNEYS.COURSE_HOME.MODAL.YOU_HAVE_REACHED_AVAILABLE_LESSONS({ ...lectureAliases })}`}
            bottomMessage={t.LEARNING_JOURNEYS.COURSE_HOME.MODAL.CHECK_YOUR_COURSE_PROGRESS({ ...courseAliases })}
            hasCourseButton
            courseLink={courseLink}
            hasJourneyLink
            journeyLink={visitedJourneyLink}
          />
        );

      default:
        return null;
    }
  };

  return (
    <NvModal
      modalStyles={modalStyles}
      show={courseCompletionStatus.modal.show}
      onClose={onClose}
      backdropType={BackdropType.LIGHT}
      type={ModalType.FIXED}
      width={440}
      height={courseCompletionStatus.modal.isLoading ? 440 : 'auto'}
      headerBgColor='transparent'
      bodyClassName='course-completion-status-modal'
      header=''
      body={(
        <section css={modalBodyStyles}>
          {courseCompletionStatus.modal.isLoading ? (
            <LoadingPlaceholder />
          ) : (
            courseCompletionStatus.completionInfo && getModalBody()
          )}
        </section>
      )}
    />
  );
};

export default CourseCompletionStatusModal;
