import angular, { auto } from 'angular';
import BaseLectureComponentContext from 'lecture_pages/directives/components/base-lecture-component/context';
import { denormalize } from 'normalizr';
import { useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { AngularContext, AngularServicesContext } from 'react-app';
import { LectureComponentSchema } from 'redux/schemas/api/lecture-components';
import { LecturePageSchema } from 'redux/schemas/api/lecture-pages';
import { LectureComponent, ComponentType, NLectureComponent, ExternalToolType, ComponentTrueType, AccordionSectionType, TemporaryLectureComponent, StyledLinkType, isTemporaryLectureComponent } from 'redux/schemas/models/lecture-component';
import store, { useAppDispatch } from 'redux/store';
import embedAngular from 'shared/embed-angular';
import _, { findWhere, isEmpty } from 'underscore';
import cloneDeep from 'lodash/cloneDeep';
import { addNewComponent } from 'redux/actions/lecture-components';
import { unsetLastDeletedBookmark } from 'redux/actions/bookmarks';
import { RootState } from 'redux/schemas';
import { BookmarkType, CourseObjectBookmark, DiscussionBookmarkComponent } from 'redux/schemas/models/bookmark';
import { NLecturePage } from 'redux/schemas/models/lecture-page';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import useAsyncContent from 'shared/hooks/use-async-content';
import { css } from '@emotion/react';
import LecturePageModelService from 'lecture_pages/services/lecture-page-model';
import { useSelector } from 'react-redux';
import { validateLectureComponent } from 'lecture_pages/services/validate-lecture-component';
import { LecturePageMode } from '..';
import LecturePageContext from '../lecture-page-context';
import getComponentMetadata from '../data';
import { removeMarkedFile } from '../workflows/file-workflow';
import { DirectlyUploadedFileWorkflow } from '../workflows';
import { LecturePagePreviewContext } from '../lecture-page-preview-modal';


// const angularModelForTrueType: { [type in Exclude<ComponentTrueType, ReactLectureComponentTypes>]: any } = {

/** Maps component true types to the angularjs model name to be used in creating & rendering them
 * TODO:  This used to have the more restrictive definition that's commented out above, making these entries
 * mutually exclusive with entries in ReactLectureComponentTypes. But this has been removed as some components have
 * angularjs modal creation flows with Reactjs rendering. Let's see if there's a way to return to that more restrictive
 * typing and reorganize this */
const angularModelForTrueType: Partial<{ [type in ComponentTrueType]: string }> = {
  // TODO: These empty ones should be in the react type instead
  [ComponentType.ACCORDION]: '',
  [AccordionSectionType.STYLE_1]: 'AccordionLectureComponent',
  [AccordionSectionType.STYLE_2]: 'AccordionLectureComponent',
  [AccordionSectionType.STYLE_3]: 'AccordionLectureComponent',
  [AccordionSectionType.STYLE_4]: 'AccordionLectureComponent',
  [AccordionSectionType.STYLE_5]: 'AccordionLectureComponent',
  [ComponentType.ATTACHMENT]: 'AttachmentLectureComponentModel',
  [ComponentType.AUDIO]: 'AudioListLectureComponentModel',
  [ComponentType.EXERCISE]: 'ExerciseLectureComponentModel',
  [ComponentType.EXERCISE_SKILLS_RATING]: 'ExerciseSkillsRatingLectureComponentModel',
  [ExternalToolType.LTI]: 'LTILectureComponentModel',
  [ExternalToolType.SCORM]: 'ScormLectureComponentModel',
  [ExternalToolType.WEB_EMBED]: 'WebEmbedLectureComponentModel',
  [ExternalToolType.WEB_LINK]: 'WebLinkLectureComponentModel',
  [StyledLinkType.BUTTON]: 'StyledLinkLectureComponentModel',
  [StyledLinkType.CARD]: 'StyledLinkLectureComponentModel',
  [ComponentType.GROUP_FORMATION]: 'GroupFormationLectureComponentModel',
  [ComponentType.LINE_DIVIDER]: 'LineDividerLectureComponentModel',
  [ComponentType.LIVE_SESSION]: 'LiveSessionLectureComponentModel',
  [ComponentType.MEET_AND_GREET]: 'MeetAndGreetLectureComponentModel',
  [ComponentType.POLL]: 'PollLectureComponentModel',
  [ComponentType.PRIVATE_PEER_EVALUATION]: 'PrivatePeerEvaluationLectureComponentModel',
  [ComponentType.PROFILE_COMPLETION]: 'ProfileCompletionComponentModel',
  [ComponentType.PUBLIC_PEER_EVALUATION]: 'PublicPeerEvaluationLectureComponentModel',
  [ComponentType.QUIZ]: 'QuizLectureComponentModel',
  [ComponentType.SUBMISSION_DISCOVERY]: 'SubmissionsDiscoveryLectureComponentModel',
  [ComponentType.SURVEY]: 'SurveyLectureComponentModel',
  [ComponentType.TEAM_DISCUSSION]: 'TeamDiscussionLectureComponentModel',
  [ComponentType.TEAM_FORMATION]: 'TeamFormationLectureComponentModel',
  [ComponentType.TIMED_QUIZ]: 'TimedQuizLectureComponentModel',
  [ComponentType.VIDEO]: 'VideoListLectureComponentModel',
  [ComponentType.VIDEO_PRACTICE]: 'VideoPracticeLectureComponentModel',
  [ComponentType.VIDEO_PRACTICE_FEEDBACK]: 'PublicPracticeFeedbackCriteriaLectureComponentModel',
  [ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK]: 'VideoPracticeSkillsRatingLectureComponentModel',
  // Note that this model name differs from the exported value's name in
  // discussion-lecture-component-model.js
  [ComponentType.TOPIC]: 'DiscussionLectureComponentModel',
  [ComponentType.TEXT_WITH_IMAGE_BKG]: 'BlurbBackgroundImageLectureComponentModel',
  [ComponentType.TEXT_WITH_IMAGE_SIDE]: 'BlurbSideImageLectureComponentModel',
  [ComponentType.TEXT_WITH_IMAGE_TOP]: 'BlurbTopImageLectureComponentModel',
};

/** An Angularjs component model constructor. Note that returned values are actually a superset
 * of LectureComponent<C> since they have Angularjs services and other functions defined on them */
type AngularModelCtor<C extends ComponentTrueType> = new (
  attributes: Partial<LectureComponent<C>>,
  isSample: false,
  useDefaults: true
) => LectureComponent<C>
// These are misc properties defined on lecture component models that are used in the
// new component workflows
& {
  catalogId: string,
  $scope: angular.IScope,
  file?: File,
  saveAttachmentAndPersist: (createNewComponent: (lc: LectureComponent<C>) => Promise<any>) => Promise<any>,
};

type LecturePageModel = ReturnType<typeof LecturePageModelService>;

/** Retrieves a model factory of the Angularjs model service for a given lecture component's type */
export const loadAngularLectureComponentModel = <C extends ComponentTrueType>($injector: auto.IInjectorService, lectureComponent: LectureComponent<C>): AngularModelCtor<C> => {
  const model = angularModelForTrueType[lectureComponent.trueType];

  if (!model) {
    throw new Error(`No Angularjs model found in loadAngularLectureComponentModel: type ${lectureComponent.trueType} id: ${lectureComponent.id}`);
  }

  return $injector.get(model) as unknown as AngularModelCtor<C>;
};

/** TODO: These denorm and loadmodel functions are very generalized; move them out of this file into some react<->angularjs utils
 * file
 */

export const denormLectureComponent = (lectureComponent: NLectureComponent, lecturePage: NLecturePage) => {
  const { models } = store.getState();
  const denormedLC: LectureComponent = denormalize(lectureComponent, LectureComponentSchema, models);
  denormedLC.lecturePage = denormLecturePage(lecturePage);
  // cloneDeep here to remove the readonly property of models from redux store
  return cloneDeep(denormedLC);
};

export const denormLecturePage = (lecturePage: NLecturePage) => {
  const { models } = store.getState();
  return cloneDeep(denormalize(lecturePage, LecturePageSchema, models));
};

export const loadLecturePageModel = ($injector: auto.IInjectorService, lecturePage: NLecturePage) => {
  const denormedLP = denormLecturePage(lecturePage);
  return new ($injector.get('LecturePageModel') as LecturePageModel)(denormedLP) as any;
};

const angularComponentStyles = css`
  /* nv-lecture-component:not(.full-width) {
    max-width: $body-text-max-width;
    margin: auto;
  } */
`;

export const getAngularComponentRenderKey = (componentId: number) => `angular-component-${componentId}`;
export const getAngularLectureComponentId = (componentId: number) => `lecture-component-${componentId}`;

/** Contains the Angularjs evaluation logic & DOM required to evaluate an Angularjs Lecture Component. Uses the Angularjs API
 * to manually do an angularjs compile, and feeds in leture component data from Redux.
 * Also note that all changes to $scope is done in $timeout calls to ensure that a new digest cycle is triggered with
 * the new $scope values. Failing to do this will cause the UI to not render the new $scope changes. */
const AngularLectureComponent = (props: {
  lectureComponent: NLectureComponent<any> | TemporaryLectureComponent,
  /** This is always supplied by lecture components in the page list but is ommitted in the LHS */
  currentLecture?: NLecturePage,
  /** Hardcodes this component to treat the lecture page as being in view mode. This is used in the LHS content previews */
  forceViewMode?: boolean,
  /** Skipes looking up data from Redux and renders the provided lectureComponent as-is. Used when rendering the LHS
   * content previews */
  useStaticData?: boolean,
  mode?: LecturePageMode
}) => {
  const dispatch = useAppDispatch();
  const params = useLecturePageParams();
  const { lectureComponent } = props;
  const isMountedRef = useRef(false);
  const { injectServices } = useContext(AngularContext);
  const angularServices = useContext(AngularServicesContext);
  const lecturePageContext = useContext(LecturePageContext);
  const { lecturePageModelRef, filesToUpload, setFilesToUpload } = lecturePageContext;
  const componentFilesToUpload = filesToUpload[lectureComponent.id];
  const { $injector, $scope, $timeout } = angularServices;
  const [$q] = injectServices(['$q']);
  const [angularModel, setAngularModel] = useState<any>(null);
  const currentCatalogId = useSelector(state => state.app.currentCatalogId);
  const lastDeletedBookmark: CourseObjectBookmark = useSelector((state: RootState) => state.app.bookmarks.lastDeleted);
  const angularLectureComponentScope = useMemo(() => $scope.$new(), []);

  const previewParams = useContext(LecturePagePreviewContext);
  const isPreview = !isEmpty(previewParams);

  /** Used to track which angular components have been eval'ed by `evalAngularLectureComponents()` */
  const renderKey = getAngularComponentRenderKey(props.lectureComponent.id);

  const scopeLCName = `lectureComponent${lectureComponent.id}`;
  /**
   * The context function references are not working as expected when using the
   * same name for the context in the preview list and lesson list. Therefore,
   * renaming the context name in the preview mode.
   */
  const scopeContextName = `context${lectureComponent.id}${isPreview ? 'preview' : ''}`;
  const componentContext = useContext(BaseLectureComponentContext);
  const asyncContent = useAsyncContent();

  const mode = props.mode ?? params.mode;

  const setModeProps = useCallback((pageMode: LecturePageMode, forceViewMode: boolean) => {
    $scope.editMode = pageMode === LecturePageMode.EDIT;
    $scope.restrictedEditMode = pageMode === LecturePageMode.RESTRICTED_EDIT;
    $scope.reorderMode = pageMode === LecturePageMode.REORDER;
    $scope.linkedEditMode = pageMode === LecturePageMode.LINKED_EDIT;
  }, [$scope]);

  /** Retrieves the denormalized lecture component data from redux and sets it on the $scope */
  useEffect(() => {
    // cloneDeep is used here because store.getState() returns direct object references from Redux, and those objects are immutable (meaning Object.isExtensible() === false)
    const denormedLC: any = props.useStaticData ? props.lectureComponent : cloneDeep(denormLectureComponent(lectureComponent, props.currentLecture));
    const ModelCtor = loadAngularLectureComponentModel($injector, denormedLC);
    const model = new ModelCtor({
      // Deep cloning for Angular components as Immer (dependency of Redux
      // Toolkit) is retrieving immutable objects, so we don't care about
      // mutability in Angular side.
      ...cloneDeep(denormedLC),
      // Some lecture components need catalogId attached to their attributes
      catalogId: currentCatalogId,
    }, false, true);

    lecturePageContext.angularComponentsModelsRef.current[denormedLC.id] = model;

    setAngularModel(model);
    model.catalogId = params.catalogId;
    $scope[scopeLCName] = model;
    $scope[scopeContextName] = componentContext;
    /* Add a reference to this scope to the lecture component model.
    This is a bad hack to allow our lecture component models to manually refresh the component UI
    after any model value changes, via $scope.$digest(). Without doing this modifications caused by the
    UI elements in the React lecture base component (such as the edit dropdown) will not cause angular
    to re-evaluate the UI since that is managed outside of Angularjs.
    Potentially very dangerous, let's look for other solutions!
    */
    model.$scope = $scope;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    angularLectureComponentScope.onContentReady = asyncContent.onReady;
  }, [angularLectureComponentScope, asyncContent.onReady]);

  useEffect(() => {
    if (!lecturePageModelRef.current) return;

    const angularLC = $scope[scopeLCName];

    // Validate essential properties required for rendering the lecture component. If a critical property is missing, throw an error to prevent rendering and display an error message
    validateLectureComponent(angularLC);

    $timeout(() => {
      angularLC.lecturePage = lecturePageModelRef.current;
    }, 0);
  }, [$scope, $timeout, scopeLCName, lecturePageModelRef]);

  useEffect(() => {
    if (angularModel && componentFilesToUpload?.length) {
      $timeout(() => {
        const createNewComponent = (newComponent) => dispatch(addNewComponent({
          index: lectureComponent.index,
          catalogId: params.catalogId,
          lecturePageId: params.lecturePageId,
          lectureComponent: {
            type: props.lectureComponent.type,
            ..._.omit(newComponent, 'id'),
          },
          temporaryComponentId: lectureComponent.id.toString(),
        }));

        // Some components use an 'attachment and persist' pattern for uploading files, and some
        // implement an uploadFiles function instead
        if (angularModel?.saveAttachmentAndPersist) {
          angularModel.file = componentFilesToUpload[0];
          angularModel.saveAttachmentAndPersist(createNewComponent);
        } else if (angularModel.uploadFiles) {
          angularModel.uploadFiles(componentFilesToUpload, createNewComponent);
        }

        setFilesToUpload({
          ...filesToUpload,
          [props.lectureComponent.id]: [],
        });
      }, 0);
    }
  }, [$timeout, angularModel, componentFilesToUpload, dispatch, filesToUpload, lectureComponent.id, lectureComponent.index, params.catalogId, params.lecturePageId, props.lectureComponent, props.lectureComponent.id, setFilesToUpload]);

  /** Update the angularjs lecture component model in response to updates to
   * this lecture component in Redux */
  useEffect(() => {
    if (isMountedRef.current) {
      $timeout(() => {
        const angularLC = $scope[scopeLCName];
        // See above useEffect for use of cloneDeep
        const denormedLC = cloneDeep(denormLectureComponent(lectureComponent, props.currentLecture));

        // A hacky fix to keep the post model for course wide discussion
        // If we pass this component.post on an update, it will break the
        // already loaded states in the angular level. So instead of `post`,
        // we are using another key `updatedPost` to pass the latest data so
        // that it can be used in abstract-discussion-lecture-component-model.js
        if (denormedLC.type === ComponentType.TOPIC) {
          (denormedLC as any).updatedPost = denormedLC.post;
          delete denormedLC.post;
        }

        _.extend(angularLC, denormedLC);
        angularLC.__preprocess();
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$scope, $timeout, lectureComponent, scopeLCName]);

  const containerRef = useRef(null);

  useEffect(() => {
    $timeout(() => {
      angularLectureComponentScope.index = lectureComponent.index;
    });
  }, [$timeout, lectureComponent.index, angularLectureComponentScope]);

  // Check if there is a last deleted bookmark. If yes, handle it in
  // the angular components and unset it after.
  useEffect(() => {
    if (lastDeletedBookmark?.lectureComponentId === lectureComponent.id
      && lecturePageContext.angularComponentsModelsRef.current[lectureComponent.id]) {
      const angularComponent = lecturePageContext.angularComponentsModelsRef.current[lectureComponent.id];
      const { component } = lastDeletedBookmark;
      if (angularComponent && component) {
        switch (lastDeletedBookmark.type) {
          case BookmarkType.TOPIC:
            if (angularComponent.post) {
              angularComponent.post.bookmarkId = null;
            }
            // If it's a Video Discussion, we need to handle it differently
            if (angularComponent.currentLectureVideo?.post) {
              angularComponent.currentLectureVideo.post.bookmarkId = null;
            }
            break;
          case BookmarkType.POST: {
            const comment = findWhere(angularComponent.post?.comments, { id: component.id });
            if (comment) {
              comment.bookmarkId = null;
            }
            break;
          }
          case BookmarkType.COMMENT: {
            const comment = findWhere(angularComponent.post?.comments, { id: (component as DiscussionBookmarkComponent).commentableId });
            if (comment) {
              const reply = findWhere(comment.replies, { id: component.id });
              if (reply) {
                reply.bookmarkId = null;
              }
            }
            break;
          }
          case BookmarkType.VIDEO:
          case BookmarkType.AUDIO: {
            const lectureVideo = findWhere(angularComponent.lectureVideos, { id: component.id });
            if (lectureVideo) {
              lectureVideo.bookmarkId = null;
            }
            break;
          }
          case BookmarkType.ATTACHMENT:
            angularComponent.attachment.bookmarkId = null;
            break;
          default:
            break;
        }
      }

      dispatch(unsetLastDeletedBookmark());
    }
  }, [lastDeletedBookmark, dispatch, lectureComponent.id, lecturePageContext.angularComponentsModelsRef]);

  useEffect(() => () => {
    angularLectureComponentScope.$destroy();
  }, [angularLectureComponentScope]);

  /** Compiles the angularjs component & DOM. Should only be fired once for any lecture component across all rerenders */
  const containerCbRef = useCallback((node) => {
    if (node) {
      // Ensure that the page mode props are set on $scope before
      // we do a render. Failing to do so will cause component init calculations that rely on this mode
      // to fail, causing different behavior between initial page load and subsequent renders
      setModeProps(mode, props.forceViewMode);

      containerRef.current = node;
      const angularHtml = `
        <nv-lecture-component
          class='lecture-component'
          id='${getAngularLectureComponentId(lectureComponent.id)}'
          lecture-component='${scopeLCName}'
          mark-read-backend-as-read='vm.markReadBackendAsRead()'
          edit-mode='${props.forceViewMode ? false : (mode === LecturePageMode.EDIT)}'
          linked-edit-mode='${props.forceViewMode ? false : (mode === LecturePageMode.LINKED_EDIT)}'
          restricted-edit-mode='restrictedEditMode'
          reorder-mode='reorderMode'
          index='index'
          ng-class='{ "full-width": lectureComponent${lectureComponent.id}.fullWidth }'
          context='${scopeContextName}'
          on-content-ready='onContentReady'
        />
      `;

      $scope.containerRef = containerRef;

      // This angular is rendered via embed because the previous approach of rendering to the page
      // via JSX & then compiling after meant that the uncompiled dom was present & visible for 1 render
      // cycle, which was a bad user experience
      embedAngular(angularHtml, containerRef, angularServices, angularLectureComponentScope);
    }
  }, [
    $scope,
    angularServices,
    lectureComponent.id,
    mode,
    props.forceViewMode,
    scopeContextName,
    scopeLCName,
    setModeProps,
    angularLectureComponentScope,
  ]);

  useEffect(() => {
    $timeout(() => {
      setModeProps(mode, props.forceViewMode);
    }, 0);
  }, [$timeout, mode, props.forceViewMode, setModeProps]);

  useEffect(() => {
    if (
      props.lectureComponent.type !== ComponentType.VIDEO
      && props.lectureComponent.type !== ComponentType.AUDIO) {
      // AsyncContent will be registered for all angular lecture components
      // Only for Video/Audio we need it to be asynced, which is handled
      // in corresponding lecture component. For all the other LC types
      // we make it ready on next compile.
      $timeout(() => {
        asyncContent.onReady();
      }, 0);
    }
  }, [$timeout, asyncContent, props.lectureComponent.type]);

  useEffect(() => {
    isMountedRef.current = true;
  }, []);

  const isTemporary = isTemporaryLectureComponent(props.lectureComponent);
  const componentMetadata = getComponentMetadata(props.lectureComponent.trueType);
  const isDirectFileUploadType = componentMetadata.workflow.type === 'directlyUploadedFile';
  const hasComponentFilesToUpload = componentFilesToUpload?.length;

  useEffect(() => {
    // Uploading file while persisting angular temporary lecture component
    // to backend
    if (
      isTemporary
      && angularModel
      && isDirectFileUploadType
      && hasComponentFilesToUpload
      && (componentMetadata.workflow as unknown as DirectlyUploadedFileWorkflow).isAngularLectureComponent
    ) {
      const file = componentFilesToUpload[0];

      if (file) {
        const mimickedUploadPromise = $q.defer();

        store.dispatch(addNewComponent({
          file,
          catalogId: params.catalogId,
          index: props.lectureComponent.index,
          lecturePageId: params.lecturePageId,
          lectureComponent: props.lectureComponent,
          onUploadProgress: mimickedUploadPromise.notify,
          filePath: (componentMetadata.workflow as unknown as DirectlyUploadedFileWorkflow).filePath,
          temporaryComponentId: (props.lectureComponent.id as unknown as string),
        })).then(mimickedUploadPromise.resolve).catch(mimickedUploadPromise.reject).finally(() => {
          removeMarkedFile(lecturePageContext, props.lectureComponent.id, 0);
        });

        angularModel.propagateReactUpload(file, mimickedUploadPromise.promise);
      }
    }
  }, [angularModel, isTemporary, isDirectFileUploadType, hasComponentFilesToUpload]);

  if (!angularModel) {
    return null;
  }

  return (
    <div
      css={angularComponentStyles}
      ref={containerCbRef}
      key={renderKey}
      data-component-key={renderKey}
    />
  );
};

export default AngularLectureComponent;
