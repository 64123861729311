/* @ngInject */
export default function PeerReviewModelService(

  _,
  $q,
  EvaluationsTaskResources,
  CurrentCourseManager,
  moment,
  $sce,
) {
  function PeerReviewModel(attributes) {
    const _this = this;

    const base = {
      saving: false,
      submitting: false,
      feedbackGrid: {
        notable: '',
        constructive: '',
        questions: '',
        ideas: '',
      },
      isNew: false, // will be set to true by manager
    };

    _.extend(_this, base, attributes);
    preprocess();

    /* Public Functions */
    _this.submit = submit;
    _this.skip = skip;
    _this.getQualitativeQuestionTemplates = getQualitativeQuestionTemplates;
    _this.hasQualitativeAnswers = hasQualitativeAnswers;
    _this.markAsHelpful = markAsHelpful;
    _this.markAsUnHelpful = markAsUnHelpful;

    /* Private Functions */
    function preprocess() {
      // populate question template responses
      // link questionAnswers to the peer review themselves
      _.each(_this.customQuestions.questionTemplates, (questionTemplate) => {
        const questionAnswer = _.findWhere(_this.currentRevision?.questionAnswers, { questionTemplateId: questionTemplate.id });

        if (questionAnswer) {
          if (questionTemplate.questionType === 'Numeric Slider') {
            questionTemplate.response = parseInt(questionAnswer.answer, 10);
          } else {
            questionTemplate.response = questionAnswer.answer;
          }

          questionAnswer.peerReview = _this;
        } else if (questionTemplate.questionType === 'Numeric Slider') {
          questionTemplate.response = questionTemplate.parsedSettings[0];
          //   questionTemplate.unAnswered = true;
          //   questionTemplate.updateAnswered = function() {
          //     questionTemplate.unAnswered = false;
          //   };
        } else {
          questionTemplate.response = '';
        }
      });

      if (_this.currentRevision) {
        _.extend(_this.feedbackGrid, _this.currentRevision.feedbackGrid);
      }
    }

    function submit(isSave) {
      _this.isNew = false;

      if (isSave) {
        _this.saving = true;
      } else {
        _this.submitting = true;
      }

      const payload = formPayload(isSave);
      return EvaluationsTaskResources.persistPeerReview({ catalogId: _this.catalogId, peerReviewId: _this.id }, payload).$promise
        .then((response) => {
          _.extend(_this, response.result);
          preprocess();

          _this.saving = false;
          _this.submitting = false;
        }).catch(
          (error) => $q.reject(error),
        );
    }

    function formPayload(isSave) {
      const payload = {
        peerReview: {
          submitted: !isSave,
          currentRevision: {
            feedbackGrid: _this.feedbackGrid,
            questionAnswers: [],
          },
        },
      };

      _.each(_this.customQuestions.questionTemplates, (questionTemplate) => {
        // if (!questionTemplate.unAnswered) {
        // }
        payload.peerReview.currentRevision.questionAnswers.push({
          questionTemplateId: questionTemplate.id,
          // answer: questionTemplate.response ? questionTemplate.response.toString() : ''
          answer: questionTemplate.response.toString(),
        });
      });

      return payload;
    }

    function skip() {
      return EvaluationsTaskResources.skipPeerReview({ catalogId: _this.catalogId, peerReviewId: _this.id }, {}).$promise
        .then((response) => response.result);
    }

    function getQualitativeQuestionTemplates() {
      return _.filter(_this.customQuestions.questionTemplates, (questionTemplate) => PeerReviewModel.isQualitative(questionTemplate));
    }

    function hasQualitativeAnswers() {
      let flag = _.some(_this.customQuestions.questionTemplates, (questionTemplate) => questionTemplate.questionType.response && PeerReviewModel.isQualitative(questionTemplate));

      if (!flag && _this.customQuestions.hasFeedbackGrid) {
        flag = _.some(_.values(_this.feedbackGrid));
      }

      return flag;
    }

    function markAsHelpful() {
      _this.markedAsHelpful = true;

      return EvaluationsTaskResources.markAsHelpful({ catalogId: _this.catalogId, peerReviewId: _this.id }, {}).$promise
        .then((response) => {
        });
    }

    function markAsUnHelpful() {
      _this.markedAsHelpful = false;

      return EvaluationsTaskResources.markAsUnHelpful({ catalogId: _this.catalogId, peerReviewId: _this.id }, {}).$promise
        .then((response) => {
        });
    }
  }

  PeerReviewModel.QUESTION_TYPES = {
    NUMERIC_SLIDER: 'Numeric Slider', // likert scale with min and max
    NUMERIC_GRADE: 'Numeric Grade', // number with min and max
    NUMERIC_TEXT_ENTRY: 'Numeric Text Entry', // number with no min or max
    SHORT_ANSWER: 'Short Answer', // normal text
    TEXT_ENTRY: 'Text Entry', // rich text
  };

  PeerReviewModel.isQualitative = function isQualitative(questionTemplate) {
    return questionTemplate.questionType === PeerReviewModel.QUESTION_TYPES.NUMERIC_TEXT_ENTRY
        || questionTemplate.questionType === PeerReviewModel.QUESTION_TYPES.SHORT_ANSWER
        || questionTemplate.questionType === PeerReviewModel.QUESTION_TYPES.TEXT_ENTRY;
  };

  PeerReviewModel.get = function (catalogId, peerReviewId) {
    return EvaluationsTaskResources.getPeerReview({ catalogId, peerReviewId }).$promise
      .then((response) => new PeerReviewModel(_.extend(response.result, { catalogId })));
  };

  PeerReviewModel.getAll = function (catalogId, reportId) {
    return EvaluationsTaskResources.getPeerReviews({ catalogId, reportId }).$promise
      .then((response) => _.map(response.result, (peerReview) => new PeerReviewModel(_.extend(peerReview, { catalogId }))));
  };

  PeerReviewModel.getNewPeerReview = function (catalogId, exerciseId, reportId) {
    const deferred = $q.defer();

    if (reportId) {
      EvaluationsTaskResources.getNewSpecifiedPeerReview({ catalogId, reportId }).$promise
        .then((response) => {
          if (_.isObject(response.result)) {
            const peerReview = new PeerReviewModel(_.extend(response.result, { catalogId }));
            deferred.resolve(peerReview);
          } else {
            deferred.reject();
          }
        }, () => {
          deferred.reject();
        });
    } else {
      EvaluationsTaskResources.getNewRandomPeerReview({ catalogId, exerciseId }).$promise
        .then((response) => {
          if (_.isObject(response.result)) {
            const peerReview = new PeerReviewModel(_.extend(response.result, { catalogId }));
            deferred.resolve(peerReview);
          } else {
            deferred.reject();
          }
        }, () => {
          deferred.reject();
        });
    }

    return deferred.promise;
  };

  return PeerReviewModel;
}
