/* @ngInject */
export default function NvSubmissionEditOptions(
  _,
  $state,
  $stateParams,

  RailsRoutes,
  CurrentUserManager,

  ReportsManager,
  ExercisesManager,
  config,
) {
  return {
    restrict: 'A',
    scope: {
      flagOnly: '=?',
      includeSkip: '=?',
      skipCallback: '&?',
      course: '=',
    },
    controller: function ctrl() {
'ngInject';
      _.extend(this, {
        $state,
        $stateParams,

        RailsRoutes,
        CurrentUserManager,

        ExercisesManager,
        ReportsManager,

        config,
      });

      this.goToLecturePageWithRevise = function () {
        const params = {
          catalogId: this.course.catalogId,
          id: this.ExercisesManager.currentExercise.lecturePageId,
          lectureActivityId: this.ExercisesManager.currentExercise.lectureComponentId,
          reportId: this.ReportsManager.currentReport.id,
          revise: true,
        };

        $state.go('lecture-page', params, { reload: true });
      };
    },
    link(scope, elem, attrs) {
    },
    bindToController: true,
    controllerAs: 'vm',
    templateUrl: 'submissions/templates/nv-submission-edit-options.html',
  };
}
