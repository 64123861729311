/* @ngInject */
export default function TeamMembershipsResource(

  $resource,
) {
  return $resource(
    '/team_members/:id.json',
    {},
    {
      query: { method: 'GET', url: '/:catalogId/teams/:teamId/team_members' },
      get: { method: 'GET', url: '/:catalogId/team_members/:id' },
      leave: { method: 'POST', url: '/:catalogId/team_members/unjoin/:id' },
      fire: { method: 'POST', url: '/:catalogId/team_members/fire/:id' },
      makeAdmin: { method: 'POST', url: '/:catalogId/teams/appoint/:id' },
      removeAdmin: { method: 'POST', url: '/:catalogId/team_members/:id/remove_admin' },
      makeGroupAdmin: { method: 'POST', url: '/:catalogId/team_members/:teamMemberId/make_admin' },
    },
  );
}
