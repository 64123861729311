import { css, jsx } from '@emotion/react';
import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { PollOptionNormalized } from 'redux/schemas/models/poll';
import { gray2, gray6 } from 'styles/global_defaults/colors';
import { handheld } from 'styles/global_defaults/media-queries';
import { largeSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import PollVoterAvatar from './poll-voter-avatar';

interface PollVoterAvatarsProps {
  isSocial: boolean;
  option: PollOptionNormalized;
}

type PollVoterAvatarsReducer = { type: 'NEW_VOTES'; voterIds: number[] };

interface PollVoterAvatarsState {
  /**
   * contains all the votes received but in different order
   */
  avatarsToShow: number[];
  /**
   * next place to insert the next vote
   */
  replaceSeq: number;
}
function pollVoterAvatarsReducer(state: PollVoterAvatarsState, action: PollVoterAvatarsReducer) {
  if (action.type === 'NEW_VOTES') {
    const currentLength = state.avatarsToShow.length;

    // elements that has not been copied from voterIds to avatarsToShow
    const diff = action.voterIds.slice(currentLength);

    // no new votes to add
    if (diff.length === 0) return state;

    // list is being truncated so we insert the element in the right place
    if (currentLength >= 6) {
      const indexToReplace = currentLength - 1 - state.replaceSeq;
      const tempReplaced = state.avatarsToShow[indexToReplace];
      const newAvatarsToShow = [
        tempReplaced,
        ...state.avatarsToShow.slice(0, indexToReplace),
        ...diff,
        ...state.avatarsToShow.slice(indexToReplace + 1),
      ];
      return {
        avatarsToShow: newAvatarsToShow,
        replaceSeq: (state.replaceSeq + 1) % 4,
      };
    }

    // the list is not truncated. Just append the diff
    return {
      ...state,
      avatarsToShow: state.avatarsToShow.concat(diff),
    };
  }
  throw new Error('unexpected action in pollVoterAvatarsReducer');
}

/* @ngInject */
export default function PollVoterAvatars(props: PollVoterAvatarsProps) {
  const { voterIds = [], votesCount } = props.option;

  const initialState = {
    avatarsToShow: voterIds,
    replaceSeq: 0,
  };

  const [state, dispatch] = React.useReducer(pollVoterAvatarsReducer, initialState);

  // if we use useEffect instead, some avatars may "flash" when some updates happen
  React.useLayoutEffect(() => {
    if (voterIds.length) {
      dispatch({ type: 'NEW_VOTES', voterIds });
    }
  }, [voterIds, dispatch]);

  const truncatedAvatarsToShow = votesCount > 5 ? state.avatarsToShow.slice(-4) : state.avatarsToShow;
  const restVotesCount = !props.isSocial ? props.option.votesCount : props.option.votesCount - truncatedAvatarsToShow.length;

  return (
    <React.Fragment>
      {(props.isSocial) && (
        <div css={containerStyleStyle}>
          {truncatedAvatarsToShow.map((voterId, index) => (
            // NOTE: using the index as the `key` here because the actual "key
            // logic" happens inside AvatarTransitionSwitch. We need this to make
            // sure that the component are not unmounted before the fade-out
            // animation
            // eslint-disable-next-line react/no-array-index-key
            <AvatarTransitionSwitch voterId={voterId} key={index} />
          ))}
        </div>
      )}

      {(!!restVotesCount || !props.isSocial) && (
        <div css={votesCountContainerStyle}>
          {props.isSocial && '+'}
          {restVotesCount}
        </div>
      )}
    </React.Fragment>
  );
}

const TRANSITION_DURATION = 500;

interface AvatarSwitchProps {
  voterId: number;
}

function AvatarTransitionSwitch(props: AvatarSwitchProps) {
  const elementRef = React.useRef<HTMLDivElement>(undefined);

  return (
    <SwitchTransition mode='out-in'>
      <CSSTransition appear key={props.voterId} timeout={TRANSITION_DURATION} classNames='fade' nodeRef={elementRef}>
        <div css={avatarContainerStyle} ref={elementRef}>
          <PollVoterAvatar voterId={props.voterId} />
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
}

const votersCircleStyle = css`
  margin-left: ${standardSpacing}px;

  ${handheld(css`
    margin-left: 0;
    margin-right: ${standardSpacing}px;
  `)};
`;

const containerStyleStyle = css`
  display: flex;
  flex-direction: row-reverse;

  .fade-enter,
  .fade-appear {
    opacity: 0;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-enter-active,
  .fade-appear-active {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-exit-active,
  .fade-appear-active {
    transition: opacity ${TRANSITION_DURATION}ms;
  }
`;

const avatarContainerStyle = css`
  ${votersCircleStyle};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${largeSpacing}px;
  height: ${largeSpacing}px;
`;

const votesCountContainerStyle = css`
  ${votersCircleStyle};
  ${handheld(css`
    margin-right: 0;
  `)};
  width: ${largeSpacing}px;
  height: ${largeSpacing}px;
  background-color: ${gray6};
  border-radius: 50%;
  color: ${gray2};
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
`;
