/* @ngInject */
export default function TeamWorkspaceMembersBaseController(
  $controller,
  $scope,
  TeamWorkspaceManager,
) {
  const vm = angular.extend(this, $controller('L3HeaderCtrl', { $scope }));
  vm.TeamWorkspaceManager = TeamWorkspaceManager;

  vm.subtitleSref = 'team-workspace({ teamId: vm.TeamWorkspaceManager.currentTeam.id })';
  vm.subtitleText = vm.TeamWorkspaceManager.currentTeam.name;
}
