import React from 'react';

import { connect, useSelector } from 'react-redux';
import t from 'react-translate';

// Schemas
import { RootState } from 'redux/schemas';
import { NProfileRequirement, ProfileRequirement } from 'redux/schemas/models/profile-requirement';
import { ActivityType } from 'redux/schemas/models/activity';
import { ComponentKeyPluralized } from 'redux/schemas/models/lecture-component';

// Actions
import { setActivityExpandedInAutomatedCommunications } from 'redux/actions/course-communications';

// selectors
import { getProfileRequirement, hasProfileRequirementGotCommunicationError } from 'redux/selectors/timeline';
import { isActivityExpandedInAutomatedCommunications } from 'redux/selectors/course-communications';

// Components
import NvIcon from 'shared/components/nv-icon';
import NvStackedTab from 'shared/components/nv-stacked-tab';
import CommunicationCreateNewDropdown from 'communications/course_communications/components/communication-create-new-dropdown';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';

type ProfileCompletionLectureComponentProps = {
  profileRequirementId: number
};

type StateProps = {
  profileRequirement: NProfileRequirement,
  hasCommunicationError: boolean,
};

const mapDispatchToProps = {
  setExpanded: setActivityExpandedInAutomatedCommunications,
};

const ProfileCompletionLectureComponent = (props: ProfileCompletionLectureComponentProps & StateProps & typeof mapDispatchToProps) => {
  const isExpanded = useSelector<RootState, boolean>((state) => isActivityExpandedInAutomatedCommunications(state, ComponentKeyPluralized.PROFILE_REQUIREMENT, props.profileRequirementId));

  const disabledText = !props.profileRequirement?.hasQuestions ? t.COURSE_COMMUNICATIONS.TOOLTIP.CREATE_DISABLED_NO_REQUIRED_QUESTIONS() : null;

  return (
    <React.Fragment>
      <div className={`lecture-component-row ${isExpanded && props.profileRequirement?.communicationsCount > 0 && 'expanded-box'}`}>
        <NvIcon icon='profile' size='smallest' />
        <div className='description'>{props.profileRequirement?.title}</div>
        <div className=''>
          <CommunicationCreateNewDropdown
            activityType={ActivityType.PROFILE_REQUIREMENT}
            activityId={props.profileRequirementId}
            disabledText={disabledText}
          />
        </div>
        { props.profileRequirement?.communicationsCount > 0 && (
          <NvStackedTab
            count={props.profileRequirement.communicationsCount}
            hasError={props.hasCommunicationError}
            isExpanded={isExpanded}
            errorMessage={t.COURSE_COMMUNICATIONS.WARNINGS.ACTIVITY_COMMUNICATION()}
            onClick={() => props.setExpanded({
              type: ComponentKeyPluralized.PROFILE_REQUIREMENT,
              id: props.profileRequirementId,
              isExpanded: !isExpanded,
            })}
          />
        )}
      </div>
      {
        // Communications
        isExpanded && props.profileRequirement?.communications && props.profileRequirement.communications.map(communicationId => (
          <CommunicationItem key={communicationId.toString()} communicationId={communicationId} />
        ))
      }
    </React.Fragment>
  );
};

const ConnectedProfileCompletionLectureComponent = connect(
  (state: RootState, ownProps: ProfileCompletionLectureComponentProps): StateProps => ({
    profileRequirement: getProfileRequirement(state, ownProps.profileRequirementId),
    hasCommunicationError: hasProfileRequirementGotCommunicationError(state, ownProps.profileRequirementId),
  }),
  mapDispatchToProps,
)(ProfileCompletionLectureComponent);

export default ConnectedProfileCompletionLectureComponent;
