import NvCelebrationModal from '../components/nv-celebration-modal/nv-celebration-modal';

/* @ngInject */
export default function PointsModalController(
  _,
  $scope,
  $state,
  $stateParams,
  $uibModalInstance,
  $timeout,

  CurrentCourseManager,
  CurrentUserManager,
  ConfettiAnimation,

  pointsReceived,
  leaderboardPoints,
  leaderboardRank,
  priorLeaderboardRank,
  extras,
  config,
) {
  const vm = this;
  _.extend(this, {
    $stateParams,
    CurrentCourseManager,
    CurrentUserManager,

    pointsReceived,
    leaderboardPoints,
    leaderboardRank,
    priorLeaderboardRank,
    extras,
    config,
    hasSkillTags,
    hasPoints,
    showLeaderboard,
    handleClose,
  });

  $scope.NvCelebrationModal = () => (
    <NvCelebrationModal
      isAdmin={CurrentUserManager.isAdminForCurrentCourse()}
      showSkillTagsAnimation={vm.showSkillTagsAnimation}
      closeModal={$uibModalInstance.close}
      modal={$uibModalInstance}
      {...vm}
    />
  );

  initialize();

  function initialize() {
    vm.showPointsAnimation = vm.hasPoints();
    vm.showSkillTagsAnimation = vm.showPointsAnimation ? false : vm.hasSkillTags();
    if ($state.params?.catalogId && CurrentCourseManager?.course?.catalogId !== $state.params?.catalogId) {
      vm.course = CurrentUserManager.getCurrentCourse();
    } else {
      vm.course = CurrentCourseManager.course;
    }

    if (
      !CurrentUserManager.isAdminForCurrentCourse()
      && vm.leaderboardRank
      && vm.leaderboardRank <= vm.course.gamificationLeaderboardNumStudentsToShow
      && (
        !vm.priorLeaderboardRank
        || (
          vm.priorLeaderboardRank
          && vm.priorLeaderboardRank > vm.course.gamificationLeaderboardNumStudentsToShow
        )
      )
    ) {
      vm.isNewToLeaderboard = true;
      ConfettiAnimation.showConfetti();
    } else {
      vm.isNewToLeaderboard = false;
    }
  }

  function hasSkillTags() {
    return vm.extras?.skillTags?.length > 0;
  }

  function hasPoints() {
    return vm.pointsReceived > 0;
  }

  function closeModal() {
    if ((vm.showPointsAnimation && !vm.hasSkillTags()) || vm.showSkillTagsAnimation) {
      if (vm.showSkillTagsAnimation) {
        $timeout($uibModalInstance.close, 3000);
      } else $uibModalInstance.close();
    } else {
      vm.showSkillTagsAnimation = true;
      vm.showPointsAnimation = false;
    }
  }

  function showLeaderboard() {
    return vm.isNewToLeaderboard && vm.showPointsAnimation;
  }

  function handleClose() {
    if (vm.showLeaderboard()) {
      vm.showPointsAnimation = false;
      vm.showSkillTagsAnimation = true;
    } else {
      $uibModalInstance.dismiss();
    }
  }

  $timeout(closeModal, 3000);
}
