/* @ngInject */
export default function NvDiscussionPost(
  CurrentUserManager,
  DiscussionsManager,
  _,
  $uibModal,
  $uibModalStack,
  ScrollFocusConnectorFactory,
  moment,
  nvUtil,
  InstitutionsManager,
  CurrentPermissionsManager,
  $q,
  config,
  CurrentCourseManager,
  $state,
) {
  return {
    restrict: 'A',
    scope: {
      post: '=',
      expandComments: '=?',
      showNewCommentPrompt: '=?',
      context: '@', // [ 'lectureVideo', 'submission', 'lecture', 'directLink', 'workspace'],
      hideVideo: '=',
      hideTitle: '=?',
      directLinkInfo: '=',
      readonlyMode: '=?',
      previewMode: '=?',
      hidePoints: '=?',
      inModal: '<?',
    },

    controller: function ctrl($scope) {
'ngInject';
      const vm = this;

      vm.config = config;
      // data
      vm.commentActionCallback = null;
      vm.commentsModeOn = vm.expandComments;
      vm.CurrentUserManager = CurrentUserManager;
      vm.CurrentPermissionsManager = CurrentPermissionsManager;
      vm.discussionsManager = DiscussionsManager;
      vm.institutionsManager = InstitutionsManager;
      vm.nvUtil = nvUtil;
      vm.newCommentUi = false;
      vm.commentsSort = 'date';
      vm.moment = moment;
      vm.$state = $state;

      // Get current Course from the state catalogId
      vm.course = CurrentUserManager.getCurrentCourse();
      if (!vm.course) {
        // Fallback for some reason course is not available use course set in CurrentCourseManager
        vm.course = CurrentCourseManager.course;
      }

      vm.post.currentExpiringPoints = vm.nvUtil.getCurrentTotalPoints(vm.post.totalPoints, vm.post.releaseDate, vm.course.expiringPointsEnabled);

      // functions
      vm.fetchComments = fetchComments;
      vm.showNewCommentUi = showNewCommentUi;
      vm.hideNewCommentUi = hideNewCommentUi;
      vm.sortCommentsByLikes = sortCommentsByLikes;
      vm.highlight = highlight;
      vm.getParentTopic = getParentTopic;
      vm.canToggleComments = canToggleComments;
      vm.toggleExpandedComments = toggleExpandedComments;
      vm.getPlaceholderTextFromContext = getPlaceholderTextFromContext;
      vm.registerCommentActionHandlers = registerCommentActionHandlers;
      vm.unregisterCommentActionHandlers = unregisterCommentActionHandlers;
      vm.closeModal = closeModal;
      vm.canEdit = canEdit;
      vm.canDelete = canDelete;
      vm.adminOptionsVisible = CurrentUserManager.isInstructorForCurrentCourse() || CurrentUserManager.isTeachingAssistantForCurrentCourse();

      function registerCommentActionHandlers(handler) {
        vm.commentActionCallback = handler;
      }

      function unregisterCommentActionHandlers() {
        delete vm.commentActionCallback;
      }

      function canToggleComments() {
        return vm.context !== 'report' && vm.context !== 'lectureVideo';
      }

      function toggleExpandedComments() {
        // don't toggle if in submissions or lecture video because these should always stay expanded
        if (canToggleComments()) {
          vm.commentsModeOn = !vm.commentsModeOn;

          if (!vm.commentsModeOn) {
            vm.newCommentUi = false;
          }
        } else {
          vm.commentsModeOn = true;
        }


        if (vm.commentsModeOn) {
          vm.fetchComments(false, false, false);
        }
      }

      function fetchComments(newCommentUi, sortByLikes, scrollToComments) {
        const deferred = $q.defer();
        vm.commentsModeOn = true;

        if ((vm.post.comments.length < vm.post.postsCount && !vm.post.commentsFetched)
              || (sortByLikes && vm.commentsSort === 'date')
              || (!sortByLikes && vm.commentsSort === 'likes')) {
          vm.post.fetchingComments = true;
          vm.post.fetchCommentsForPost(sortByLikes, (DiscussionsManager.directLink ? DiscussionsManager.directLink.commentId : null)).then((commentsList) => {
            vm.post.comments = commentsList;

            vm.post.fetchingComments = false;
            if (newCommentUi) {
              showNewCommentUi(true);
            }
            return deferred.resolve();
          });
        } else if (newCommentUi) {
          showNewCommentUi(true);
          deferred.resolve();
        }
        return deferred.promise;
      }

      function showNewCommentUi(scrollTo) {
        vm.newCommentUi = true;
        if (scrollTo) {
          ScrollFocusConnectorFactory.focus(vm.post.id, 'post');
        }
      }

      function hideNewCommentUi() {
        vm.newCommentUi = false;
      }

      function getParentTopic() {
        return _.findWhere(DiscussionsManager.allTopics, { id: vm.post.topicId });
      }


      function highlight() {
        $uibModal.open({
          templateUrl: 'discussions/templates/nv-highlight-post-modal.html',
          windowClass: 'discussions-modal',
          controller: 'HighlightPostModalInstanceCtrl',
          controllerAs: 'vm',
          resolve: {
            post() {
              return vm.post;
            },
          },
        });
      }

      function getPlaceholderTextFromContext() {
        if (vm.context === 'lecture' || vm.context === 'lectureVideo') {
          return 'DISCUSSIONS.BE_THE_FIRST_TO_PARTICIPATE';
        }
        return 'DISCUSSIONS.BE_THE_FIRST_TO_POST';
      }

      function sortCommentsByLikes() {
        vm.fetchComments(false, true, false);
      }

      function closeModal() {
        $uibModalStack.dismissAll('cancel');
      }

      function canDelete() {
        return (CurrentUserManager.isInstructorForCurrentCourse() || CurrentUserManager.isTeachingAssistantForCurrentCourse())
            || (vm.post.belongsToCurrentUser() && !vm.discussionsManager.topicIsLocked(vm.post.forum));
      }

      function canEdit() {
        return !this.institutionsManager.institution.disableDiscussionEdits
          && vm.canDelete(); // checking other conditions
      }
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'discussions/templates/nv-discussion-post.html',
  };
}
