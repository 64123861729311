import { css } from '@emotion/react';
import React from 'react';
import NvTagButtonGeneric, { TagButtonGenericIconPosition } from 'shared/components/nv-tag-button-generic';

// Styles
import { hexToRgbaString, black, teal, primary } from 'styles/global_defaults/colors';

type TagButtonClosableProps = {
  text: string,
  className?: string,
  elementId?: string;
  onClick?: (event?: any) => void,
  isSelected?: boolean,
};

const NvTagButtonSelectable = ({
  text,
  className = '',
  elementId,
  onClick = () => {},
  isSelected = false,
}: TagButtonClosableProps) => {
  const styles = css`
    button.bg-info {
      &:focus, &:hover {
        // Next should be '!important' because they are overwriting a class with these values with '!important' property.
        background-color: ${hexToRgbaString(teal, 0.2)} !important;
        color: ${black} !important;
      }
    }
    .bg-primary-button {
      background-color: ${primary};
      &:focus {
        outline: 1px solid #ffffff;
      }
      .icon{
        div:focus {
          outline: 1px solid #ffffff;
        }
      }
    }
  `;

  return (
    <div css={styles} className={className}>
      <NvTagButtonGeneric
        text={text}
        customIcon='create-new-post'
        elementId={elementId}
        selectedClassNames={['text-small', 'bg-primary-button', 'text-white', 'font-weight-bolder']}
        iconPosition={TagButtonGenericIconPosition.LEFT}
        onClick={onClick}
        isSelectable
        isSelected={isSelected}
      />
    </div>
  );
};

export default NvTagButtonSelectable;
