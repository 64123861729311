/* @ngInject */
export default function ConfettiAnimation(
  $timeout,
) {
  const _this = this;
  _this.showConfetti = showConfetti;

  const NUM_OF_CONFETTI = 70;
  const NUM_OF_FAST_CONFETTI = 60;

  function showConfetti() {
    let count = 1; let
      randomNumber;

    setInterval(() => {
      if (count < NUM_OF_CONFETTI) {
        count += 1;
        const $confetti = $('<div></div>');
        $confetti.css({ left: `${Math.floor((Math.random() * 100) + 1)}%` });
        $confetti.css({ '-webkit-animation-duration': '0.8s' });
        $confetti.css({ 'animation-duration': '0.8s' });

        if (count > NUM_OF_FAST_CONFETTI) {
          // slow down the last ones for a smoother feel
          $confetti.css({ '-webkit-animation-duration': `${Math.random() * 1 + 0.5}s` });
          $confetti.css({ 'animation-duration': `${Math.random() * 1 + 0.5}s` });
        }

        if (count % 3 === 0) {
          // star confetti
          $confetti.addClass('confetti icon icon-highlight');
        } else {
          // paper confetti
          randomNumber = Math.floor((Math.random() * 2) + 1); // to assign colors
          $confetti.addClass(`confetti paper confetti${randomNumber}`);
        }

        $('body').append($confetti);
      }
    }, 3);
  }

  return _this;
}
