import { createReducer } from '@reduxjs/toolkit';
import {
  openSavingOverlay, closeSavingOverlay, setSavingOverlayText,
  unsetSavingOverlayText,
} from 'redux/actions/saving-overlay';
import { initialRootState } from '.';

const reducer = createReducer(initialRootState, builder => {
  builder
    .addCase(openSavingOverlay, (state) => {
      state.app.showSavingOverlay = true;
    })
    .addCase(closeSavingOverlay, (state) => {
      state.app.showSavingOverlay = false;
    })
    .addCase(setSavingOverlayText, (state, action) => {
      state.app.savingOverlayText = action.payload;
    })
    .addCase(unsetSavingOverlayText, (state) => {
      state.app.savingOverlayText = null;
    });
});

export default reducer;
