import { css } from '@emotion/react';
import { useState, useRef, useMemo } from 'react';
import t from 'react-translate';
import { useSelector } from 'react-redux';

import { Reply } from 'redux/schemas/models/reply';
import { Comment } from 'redux/schemas/models/comment';

import { getCurrentUser } from 'redux/selectors/users';
import useClickOutside from 'shared/hooks/use-click-outside';

import { RootState } from 'redux/schemas';
import { MyAccount } from 'redux/schemas/models/my-account';
import { NvUserAvatar } from 'components/nv-user-avatar';
import NvPopover from 'shared/components/nv-popover';
import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import { PopoversContainerContext } from 'shared/react-utils';

import { textSmallFontSize } from 'styles/global_defaults/fonts';
import { halfSpacing, largeSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { primary, gray4 } from 'styles/global_defaults/colors';

import { config } from '../../../../config/pendo.config.json';

interface DiscussionLikesProps {
  discussionComponent: Comment | Reply;
  like: Function;
  unlike: Function;
  getLikersInfo: Function;
}

const DiscussionLikes = (props: DiscussionLikesProps) => {
  const ref = useRef(null);
  const containerRef = useRef(null);

  const [showPopover, setShowPopover] = useState(false);

  const styles = css`
    display: flex;
    align-items: center;
    width: ${largeSpacing}px;

    .likers-popover {
      flex-wrap: wrap;
      max-height: 240px;
      overflow-y: auto;

      &-row {
        display: flex;

        .nv-user-avatar:not(.first) {
          margin-left: ${standardSpacing}px;
        }
      }
    }

    button {
      border: none;
      background: none;
      outline: none;
      padding-left: 0;
    }

    .likes-count {
      font-size: ${textSmallFontSize}px;
      margin-left: ${quarterSpacing}px;
    }

    &.disabled {
      .icon {
        color: ${gray4};
      }
    }

    &:not(.disabled) {
      &:hover {
        cursor: pointer;
      }

      &.active {
        .icon-like, .likes-count {
          color: ${primary};
        }
      }

      .icon-like:hover {
        color: ${primary};
      }
    }
  `;

  const currentUser = useSelector<RootState, MyAccount>(getCurrentUser);

  const openPopover = () => {
    setShowPopover(true);
    if (!props.discussionComponent.likersFetched) {
      props.getLikersInfo();
    }
  };

  useClickOutside(ref, () => {
    setShowPopover(false);
  });

  const likerRows = useMemo(() => {
    const rows = [];
    let row = [];
    props.discussionComponent.likers.forEach((user, i) => {
      row.push(user);
      if ((i + 1) % 3 === 0) {
        rows.push(row);
        row = [];
      }
    });

    if (row.length) {
      rows.push(row);
    }

    return rows;
  }, [props.discussionComponent.likers]);

  const popoverContent = (
    <div className='likers-popover' ref={ref}>
      {likerRows.map(row => (
        <div className='likers-popover-row'>
          {row.map((user, i) => (
            <NvUserAvatar
              className={`${i === 0 ? 'first' : ''}`}
              user={user}
              size='sm'
              borderType='round'
              displayName
            />
          ))}
        </div>
      ))}
    </div>
  );

  const toggleLike = () => {
    if (props.discussionComponent.liked) {
      props.unlike();
    } else {
      props.like();
    }
  };

  const isCurrentUser = currentUser?.id === props.discussionComponent?.user?.id;

  return (
    <PopoversContainerContext.Provider value={containerRef.current}>
      <div css={styles} className={`${props.discussionComponent.liked ? 'active' : ''} ${isCurrentUser ? 'disabled' : ''}`} ref={containerRef}>
        <NvTooltip
          text={props.discussionComponent.liked ? t.DISCUSSIONS.UNLIKE() : t.DISCUSSIONS.LIKE()}
          placement='bottom'
          enabled={!isCurrentUser}
        >
          <button
            type='button'
            disabled={isCurrentUser}
            onClick={toggleLike}
            pendo-tag-name={config.pendo.teamDiscussion.likeComment}
            aria-label={props.discussionComponent.liked ? t.DISCUSSIONS.UNLIKE() : t.DISCUSSIONS.LIKE()}
          >
            <NvIcon size='smallest' icon='like' />
          </button>
        </NvTooltip>
        {props.discussionComponent.votesCount > 0 && (
          <NvPopover content={popoverContent} placement='top' show={showPopover}>
            <div className='likes-count btn-link' onClick={openPopover}>
              {props.discussionComponent.votesCount}
            </div>
          </NvPopover>
        )}
      </div>
    </PopoversContainerContext.Provider>
  );
};

export default DiscussionLikes;
