import React from 'react';
import { jsx } from '@emotion/react';
import angular, { IScope } from 'angular';

import { AngularServicesContext } from 'react-app';
import {
  LearningJourneyContext,
} from 'learning_journeys/components/learning-journey';

const ANGULAR_STATE_NAME = 'learning-journey-user-management';

const getAllParamsUsed = (fromParams, toParams) => {
  const allParamsSet = new Set();

  Object.keys(fromParams).forEach(
    (key) => {
      allParamsSet.add(key);
    },
  );
  Object.keys(toParams).forEach(
    (key) => {
      allParamsSet.add(key);
    },
  );

  return Array.from(allParamsSet.values());
};

export type UserManagementRef = {
  $scopeRef: React.MutableRefObject<IScope>,
};

const isNavigatingBetweenInternalStates = (fromState, toState) => (
  fromState.parent === ANGULAR_STATE_NAME
    && toState.parent === ANGULAR_STATE_NAME
);

const UserManagement = React.forwardRef<UserManagementRef>((props, ref) => {
  const currentScopeRef = React.useRef<IScope>();
  const currentElementRef = React.useRef<JQLite>();
  const containerRef = React.useRef<any>();
  const renderUserManagementRef = React.useRef<Function>();
  const { loadJourney } = React.useContext(LearningJourneyContext);

  const {
    $scope,
    $compile,
    $templateCache,
  } = React.useContext(AngularServicesContext);

  React.useImperativeHandle(
    ref,
    () => ({ $scopeRef: currentScopeRef }),
    [],
  );

  const cleanupOldUserManagement = () => {
    if (currentElementRef.current) {
      currentElementRef.current.children().remove();
      currentElementRef.current = null;
    }

    if (currentScopeRef.current) {
      currentScopeRef.current.$destroy();
      currentScopeRef.current = null;
    }
  };

  const renderUserManagement = () => {
    const template = $templateCache.get('user_management/templates/main.html');
    const templateScope = $scope.$new();

    templateScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
      if (isNavigatingBetweenInternalStates(fromState, toState)) {
        const allParams = getAllParamsUsed(fromParams, toParams);

        const shouldReRenderUserManagement = allParams
          .some((key: string) => toParams[key] !== fromParams[key]);

        if (shouldReRenderUserManagement) {
          cleanupOldUserManagement();
          renderUserManagementRef.current();
        }
      }
    });

    templateScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
      if (isNavigatingBetweenInternalStates(fromState, toState)) {
        $scope.StateManager.previousStatesEntered.pop();
        $scope.StateManager.previousParamsEntered.pop();
      }
    });

    templateScope.loadJourney = loadJourney;

    currentScopeRef.current = templateScope;

    const templateCtrl = $scope.$controller(
      'UserManagementMainController',
      { $scope: templateScope },
    );

    templateScope.vm = templateCtrl;

    const angularElement = angular.element(containerRef.current);

    angularElement.html(template as any);
    angularElement.children().data('$ngControllerController', templateCtrl);
    $compile(angularElement.contents() as unknown as JQuery<HTMLElement>)(templateScope);

    currentElementRef.current = angularElement;
  };

  renderUserManagementRef.current = renderUserManagement;

  React.useEffect(() => {
    renderUserManagementRef.current();

    return cleanupOldUserManagement;
  }, []);

  return <div ref={containerRef} />;
});

export default UserManagement;
