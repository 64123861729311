/* @ngInject */
export default function TimelineLectureSubsectionModelService(
  _,
  TimelineLecturePageModel,
  TimelinesResources,
) {
  const TimelineLectureSubsectionModel = function (attributes, lectureSection, timeline, course, isAdmin) {
    // Subsection can only have LP as children
    const _this = this;

    const base = {
      lectureSection,
      timeline,
      course,
    };
    _.extend(_this, attributes, base);

    /** Public Data * */

    /** Public Functions * */
    _.extend(_this, {
      // full view
      getLecturePage,
      getFirstLecturePage,
      hasAnyActivities,
      hasDeadlines,
      updateReleaseDate,
      bulkUpdateActivityDeadline,
      getAllItems,
      // todo view
      getAllTodoItems,
      // point view
      getAllPointsReceivedItems,
      // lecturepage view
      resetCurrent,
      updateLecturePage,
      updateLecturePageAttribute,
      markUnread,
      removeComponent,
      updateComponentPointsAndProgress,
      updateComponentProgress,
      removeLecturePage,
      addLecturePage,
      insertLecturePageAfter,
      adoptChildren,

      getAllLecturePages,
      cancelTimeouts,
      isPrereqCompleted,
      createReorderDraft,
      saveReorderDraft,
      updateSubsectionCompletionDetails,
    });
    preprocess();


    /** Function Declarations * */
    /* Used Publicly */

    // Returns the first parent lecture page that has the current item
    function getLecturePage(lecturePageId) {
      return _.findWhere(_this.lecturePages, { id: lecturePageId });
    }

    function getFirstLecturePage() {
      return _.first(_this.lecturePages);
    }

    function getAllItems() {
      return _.flatten(_.map(_this.lecturePages, (lecturePage) => lecturePage.getAllComponents()));
    }

    function hasAnyActivities() {
      return _.some(_this.lecturePages, (lp) => lp.hasAnyActivities);
    }

    function hasDeadlines() {
      return _.some(_this.lecturePages, (lp) => lp.hasDeadlines);
    }

    function updateReleaseDate(releaseDate) {
      return TimelinesResources.bulkUpdateReleaseDates({ catalogId: _this.course.catalogId, lectureSectionId: _this.id }, { releaseDate }).$promise
        .then((response) => {
          const serverSubsection = response.result.updatedObject;
          const affectedLecturePages = response.result.dependentUpdates;

          _.each(serverSubsection.lecturePages, (serverLecturePage) => {
            const exisitingLp = _.findWhere(_this.lecturePages, { id: serverLecturePage.id });

            if (exisitingLp) {
              exisitingLp.updateLecturePageAttribute(exisitingLp.id, 'releaseDate', releaseDate);
              exisitingLp.updateLecturePageAttribute(exisitingLp.id, 'hasStructuralIssues', serverLecturePage.hasStructuralIssues);
              exisitingLp.updateLecturePageAttribute(exisitingLp.id, 'hasTimelineIssues', serverLecturePage.hasTimelineIssues);
            }

            _this.timeline.updateDependentActivitiesReleaseDate(exisitingLp);
          });

          _this.timeline.updateLecturePagesWarnings(affectedLecturePages);

          return serverSubsection;
        });
    }

    function bulkUpdateActivityDeadline(deadline, hardDeadline) {
      return TimelinesResources.bulkUpdateDeadline({ catalogId: _this.course.catalogId, lectureSectionId: _this.id }, { deadline, hardDeadline }).$promise
        .then((response) => {
          const serverSubsection = response.result;

          _.each(serverSubsection.lecturePages, (serverLecturePage) => {
            const exisitingLp = _.findWhere(_this.lecturePages, { id: serverLecturePage.id });

            if (exisitingLp) {
              exisitingLp.setActivityDeadlines(deadline, hardDeadline);
              exisitingLp.updateLecturePageAttribute(exisitingLp.id, 'hasStructuralIssues', serverLecturePage.hasStructuralIssues);
              exisitingLp.updateLecturePageAttribute(exisitingLp.id, 'hasTimelineIssues', serverLecturePage.hasTimelineIssues);
            }
          });

          return response.result;
        });
    }

    function getAllTodoItems() {
      return _.flatten(_.map(_this.lecturePages, (lecturePage) => lecturePage.getAllTodoItems()));
    }

    function getAllPointsReceivedItems() {
      return _.flatten(_.map(_this.lecturePages, (lecturePage) => lecturePage.getAllPointsReceivedItems()));
    }

    function resetCurrent() {
      _this.expanded = false;
      _.each(_this.lecturePages, (lecturePage) => lecturePage.resetCurrent());
    }

    function getAllLecturePages() {
      return _this.lecturePages;
    }

    // only for admins
    function removeLecturePage(lecturePageId) {
      let removed = false;

      removed = _.some(_this.lecturePages, (lecturePage, index) => {
        if (lecturePage.id === lecturePageId) {
          _this.lecturePages.splice(index, 1);
          return true;
        }
        return false;
      });

      if (removed) {
        _.each(_this.lecturePages, (lecturePage, index) => {
          lecturePage.index = index;
        });

        updateSubsectionCompletionDetails();
      }

      return removed;
    }

    // When Remove Subsection Title is called
    function adoptChildren(originSection) {
      if (originSection.lecturePages.length) {
        _this.lecturePages.push(...originSection.lecturePages);
        _.each(_this.lecturePages, (lp, index) => {
          lp.index = index;
        });
      }
    }

    function addLecturePage(newLecturePage) {
      _this.lecturePages.splice(newLecturePage.index, 0, newLecturePage);
      _.each(_this.lecturePages, (lecturePage, index) => {
        lecturePage.index = index;
      });

      updateSubsectionCompletionDetails();
    }


    function insertLecturePageAfter(lecturePageId, lecturePageToInsert) {
      const index = _.findIndex(_this.lecturePages, { id: lecturePageId });

      if (index > -1) {
        _this.lecturePages.splice(index + 1, 0, lecturePageToInsert);
        lecturePageToInsert.lectureSection = _this;

        _.each(_this.lecturePages, (lecturePage, lpIndex) => {
          lecturePage.index = lpIndex;
        });

        updateSubsectionCompletionDetails();

        return true;
      }

      return false;
    }

    function updateLecturePage(lecturePageId) {
      return _.map(_this.lecturePages, (lecturePage) => lecturePage.updateLecturePage(lecturePageId));
    }

    function updateLecturePageAttribute(lecturePageId, attribute, value) {
      _.each(_this.lecturePages, (lecturePage) => {
        lecturePage.updateLecturePageAttribute(lecturePageId, attribute, value);
      });
    }

    // for all lecture pages
    function markUnread(lecturePageId) {
      _.each(_this.lecturePages, (lecturePage) => {
        lecturePage.markUnread(lecturePageId);
      });
    }

    function removeComponent(lecturePageId, componentType, componentId) {
      _.each(_this.lecturePages, (lecturePage) => {
        lecturePage.removeComponent(lecturePageId, componentType, componentId);
      });
    }

    function updateComponentPointsAndProgress(lecturePageId, componentType, componentId, pointsReceived, totalPoints, progress) {
      _.each(_this.lecturePages, (lecturePage) => {
        lecturePage.updateComponentPointsAndProgress(lecturePageId, componentType, componentId, pointsReceived, totalPoints, progress);
      });
    }

    function updateComponentProgress(lecturePageId, componentType, componentId, progress) {
      _.each(_this.lecturePages, (lecturePage) => {
        lecturePage.updateComponentProgress(lecturePageId, componentType, componentId, progress);
      });
    }

    function cancelTimeouts() {
      _.each(_this.lecturePages, (lecturePage) => {
        lecturePage.cancelTimeouts();
      });
    }

    function isPrereqCompleted(prereq) {
      return _.some(_this.lecturePages, (lecturePage) => lecturePage.isPrereqCompleted(prereq));
    }

    function createReorderDraft() {
      _this.lecturePagesReorderDraft = _this.lecturePages ? [..._this.lecturePages] : null;
    }

    function saveReorderDraft() {
      _this.lecturePages = _this.lecturePagesReorderDraft;

      _.each(_this.lecturePages, (lp, index) => {
        lp.index = index;
      });
    }

    function updateSubsectionCompletionDetails() {
      _this.isReleased = _.some(_this.lecturePages, (lecturePage) => lecturePage.isReleased);

      _this.isFullyCompleted = _this.lecturePages.length && _.every(_this.lecturePages, (lecturePage) => lecturePage.isFullyCompleted());

      if (_this.timeline.hasLectureComponents) {
        const allLecturePageTotalPointItems = _.flatten(_.map(_this.lecturePages, (lecturePage) => lecturePage.getAllComponents()));

        _this.allPointsReceived = calculatePointsReceived(allLecturePageTotalPointItems);
        _this.allCurrentTotalPoints = calculateTotalPoints(allLecturePageTotalPointItems);
      } else if (_.some(_this.lecturePages, (lp) => lp?.allPointItems?.length > 0)) {
        _this.allPointsReceived = _.reduce(_this.lecturePages, (memo, item) => ((item.currentPointsReceived || 0) + memo), 0);
        _this.allCurrentTotalPoints = _.reduce(_this.lecturePages, (memo, item) => ((item.currentTotalPoints || 0) + memo), 0);
      }
    }

    /* Used Privately */
    function preprocess() {
      _this.type = 'lectureSubsection';
      _this.expanded = false;

      _this.lecturePages = _.map(_this.lecturePages, (lecturePage) => new TimelineLecturePageModel(lecturePage, _this, _this.course, isAdmin));

      updateSubsectionCompletionDetails();
    }

    function calculatePointsReceived(items) {
      return _.reduce(items, (memo, item) => {
        if (item.pointsReceived) {
          return item.pointsReceived + memo;
        }
        return memo;
      }, 0);
    }

    function calculateTotalPoints(items) {
      return _.reduce(items, (memo, item) => {
        if (item.currentTotalPoints) {
          return item.currentTotalPoints + memo;
        }
        return memo;
      }, 0);
    }
  };

  return TimelineLectureSubsectionModel;
}
