import * as Sentry from '@sentry/browser';
import { CategoryProps } from 'shared/components/menu/nv-category';
import { RailsRoutes as railsRoutes } from 'shared/services/rails-routes';
import enUS from '../../../languages/en_US';

/* @ngInject */
export function getCourseAdminCategories(
  t: typeof enUS,
  $state,
  $uibModal,
  RailsRoutes: typeof railsRoutes,
  CurrentPermissionsManager,
  currentCourse,
  userCourse,
  CurrentUserManager,
  hasCredlyIntegration: boolean,
  handleCredlyBadgeRef: React.RefObject<Function>,
  institutionHasSkillTags: boolean,
) {
  const {
    offeringName,
    courseLongTeamSet,
    groupTeamSet,
    learnersName,
  } = currentCourse;

  // Getting logged user roles and permissions
  const isCourseBuilder = CurrentPermissionsManager.isCourseBuilder();
  const isConfigAndRegistration = CurrentPermissionsManager.isConfigAndRegistrationRole();
  const isInstructor = CurrentPermissionsManager.isInstructor();
  const isLearnerRegistration = CurrentPermissionsManager.isLearnerRegistrationRole();
  const isTeachingAssistant = CurrentPermissionsManager.isTeachingAssistant();
  const isReportAdmin = CurrentPermissionsManager.isReportAdmin();

  // Getting data for routing
  const { catalogId } = $state?.params;
  const {
    isProgram,
    id: courseId = currentCourse?.id, // Using default courseId from currentCourse
    institution = {},
  } = $state?.$current?.locals?.globals?.checkCoursePermission ?? {};


  // Using default institutionId value from currentCourse
  const { id: institutionId = currentCourse?.institutionId } = institution;
  const { name: currentState } = $state?.current;
  const { $stateParams } = $state?.$current?.locals?.globals;

  // Handling errors
  function handleError(params: { [key: string]: string }, error: Error) {
    Sentry.configureScope(scope => {
      scope.setExtras(params);
    });

    Sentry.captureException(error);
  }

  // Getting urls for links
  function getCourseOutlineUrl() {
    let url = '';

    try {
      url = $state.href(!isProgram ? 'course-home-edit' : 'program-timelines-edit', {
        catalogId,
      });
    } catch (error) {
      handleError({
        catalogId,
      }, error);
    }

    return url;
  }

  function getCourseBasicsUrl() {
    let url = '';

    try {
      url = $state.href('course-edit-basics', {
        institutionId,
        id: courseId,
        prevStateData: {
          name: currentState,
          params: $stateParams,
        },
      });
    } catch (error) {
      handleError({
        institutionId,
        id: courseId,
      }, error);
    }

    return url;
  }

  const getUserManagementLearnersUrl = (): string => $state.href('user-management-learners', { catalogId });

  const getLearnerProgressUrl = (): string => $state.href('learner-progress', { catalogId });

  const getCourseCommunicationsEditUrl = (): string => $state.href('course-communications-edit', { catalogId });

  const getDataDownloadsUrl = (sectionId): string => $state.href('custom-downloads', { sectionId, catalogId });

  const getAnalyticsUrl = (): string => $state.href('course-analytics', { catalogId });

  const getSkillsAnalyticsUrl = (): string => `${getAnalyticsUrl()}/skills`;

  const getCohortAnalyticsUrl = ():string => `${getAnalyticsUrl()}/cohorts`;

  function getTeamGroupFormationUrl(teamGroupSet) {
    let url = '';

    try {
      url = $state.href('lecture-page-edit', {
        id: teamGroupSet?.lecturePageId,
        lectureActivityId: teamGroupSet?.lectureComponentId,
        catalogId,
      });
    } catch (error) {
      handleError({
        lecturePageId: teamGroupSet?.lecturePageId,
        lectureActivityId: teamGroupSet?.lectureComponentId,
      }, error);
    }

    return url;
  }

  function getTeamFacilitationUrl() {
    let url = '';

    try {
      url = $state.href('team-facilitation', {
        catalogId,
        initialState: true,
      });
    } catch (error) {
      handleError({
        catalogId,
      }, error);
    }

    return url;
  }
  const showCohorts = (currentCourse?.isPrimary || (currentCourse?.isCohort && userCourse?.numCohortsReportAdminOrInstructorIn > 1)) && (isReportAdmin || isInstructor);
  const courseAdminCategories: CategoryProps[] = [
    {
      show: isCourseBuilder || isConfigAndRegistration || isInstructor,
      icon: 'content',
      title: t.COURSE_ADMIN_MEGA_MENU.EXPERIENCE_DESIGN(),
      items: [
        {
          show: isCourseBuilder,
          label: t.COURSE_ADMIN_MEGA_MENU.COURSE_OUTLINE.LABEL(
            offeringName.singularizedTitleized,
          ),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.COURSE_OUTLINE.TOOLTIP(),
          link: getCourseOutlineUrl(),
        },
        {
          show: isConfigAndRegistration,
          label: t.COURSE_ADMIN_MEGA_MENU.COURSE_BASICS.LABEL(
            offeringName.singularizedTitleized,
          ),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.COURSE_BASICS.TOOLTIP(
            offeringName.downcasedSingularized,
          ),
          link: getCourseBasicsUrl(),
        },
        {
          show: isCourseBuilder || isConfigAndRegistration || isInstructor,
          label: t.COURSE_ADMIN_MEGA_MENU.HELP_MENU.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.HELP_MENU.TOOLTIP(),
          link: RailsRoutes.courseAdminDashboardTabSectionPath(
            catalogId,
            'course_homepage',
            'help-menu',
          ),
        },
        {
          show: isCourseBuilder || isConfigAndRegistration || isInstructor,
          label: t.COURSE_ADMIN_MEGA_MENU.GOOGLE_CALENDAR.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.GOOGLE_CALENDAR.TOOLTIP(),
          link: RailsRoutes.courseAdminDashboardTabSectionPath(
            catalogId,
            'course_homepage',
            'google-calendar',
          ),
        },
        {
          show: isCourseBuilder || isConfigAndRegistration,
          label: t.COURSE_ADMIN_MEGA_MENU.COURSE_HOME_WIDGETS.LABEL(
            offeringName.singularizedTitleized,
          ),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.COURSE_HOME_WIDGETS.TOOLTIP(
            offeringName.downcasedSingularized,
          ),
          link: RailsRoutes.courseAdminDashboardTabSectionPath(
            catalogId,
            'course_homepage',
            'course-home-widgets',
          ),
        },
        {
          show: isCourseBuilder || isInstructor,
          label: t.COURSE_ADMIN_MEGA_MENU.HOMEPAGE_GREETING.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.HOMEPAGE_GREETING.TOOLTIP(
            offeringName.downcasedSingularized,
          ),
          link: RailsRoutes.courseAdminDashboardTabSectionPath(
            catalogId,
            'course_homepage',
            'homepage-greeting',
          ),
        },
        {
          show: isCourseBuilder,
          label: t.COURSE_ADMIN_MEGA_MENU.PREREQUISITES.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.PREREQUISITES.TOOLTIP(),
          link: RailsRoutes.courseAdminDashboardTabSectionPath(
            catalogId,
            'content',
            'prerequisites',
          ),
        },
        {
          show: isCourseBuilder || isConfigAndRegistration,
          label: t.COURSE_ADMIN_MEGA_MENU.COURSE_FLYER.LABEL(
            offeringName.singularizedTitleized,
          ),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.COURSE_FLYER.TOOLTIP(
            offeringName.downcasedSingularized,
          ),
          link: RailsRoutes.courseAdminDashboardTabPath(
            catalogId,
            'flyer_page',
          ),
        },
      ],
    },
    {
      show: isCourseBuilder || isConfigAndRegistration || isInstructor,
      icon: 'badge',
      title: t.COURSE_ADMIN_MEGA_MENU.COURSE_COMPLETION(
        offeringName.singularizedTitleized,
      ),
      items: [
        {
          show: isCourseBuilder || isConfigAndRegistration || isInstructor,
          label: t.COURSE_ADMIN_MEGA_MENU.POINTS_AND_LEADERBOARD.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.POINTS_AND_LEADERBOARD.TOOLTIP(),
          link: RailsRoutes.courseAdminDashboardTabPath(catalogId, 'points'),
        },
        {
          show: isCourseBuilder || isConfigAndRegistration,
          label: t.COURSE_ADMIN_MEGA_MENU.AUTOMATIC_COMPLETION_CRITERIA.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.AUTOMATIC_COMPLETION_CRITERIA.TOOLTIP(
            offeringName.downcasedSingularized,
          ),
          link: RailsRoutes.courseAdminDashboardTabSectionPath(
            catalogId,
            'statements',
            'automatic-completion-criteria',
          ),
        },
        {
          show: isCourseBuilder || isConfigAndRegistration || isInstructor,
          label: t.COURSE_ADMIN_MEGA_MENU.COMPLETION_EMAIL.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.COMPLETION_EMAIL.TOOLTIP(
            offeringName.downcasedSingularized,
          ),
          link: RailsRoutes.courseAdminDashboardTabSectionPath(
            catalogId,
            'statements',
            'completion-email',
          ),
        },
        {
          show: isCourseBuilder || isConfigAndRegistration,
          label: t.COURSE_ADMIN_MEGA_MENU.STATEMENT_OF_ACCOMPLISHMENT.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.STATEMENT_OF_ACCOMPLISHMENT.TOOLTIP(),
          link: RailsRoutes.courseAdminDashboardTabSectionPath(
            catalogId,
            'statements',
            'statement-of-accomplishment',
          ),
        },
        {
          show: isCourseBuilder && hasCredlyIntegration,
          label: t.COURSE_ADMIN_MEGA_MENU.CREDLY_BADGE.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.CREDLY_BADGE.TOOLTIP(
            offeringName.downcasedSingularized,
          ),
          callback: () => {
            handleCredlyBadgeRef.current?.();
          },
        },
        {
          show: isCourseBuilder || isInstructor,
          label: t.COURSE_ADMIN_MEGA_MENU.BULK_UPLOAD_COMPLETION.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.BULK_UPLOAD_COMPLETION.TOOLTIP(),
          link: RailsRoutes.courseAdminDashboardTabSectionPath(
            catalogId,
            'statements',
            'bulk-upload-completion',
          ),
        },
      ],
    },
    {
      show:
        isCourseBuilder
        || isConfigAndRegistration
        || isInstructor
        || isLearnerRegistration
        || isTeachingAssistant
        || isReportAdmin,
      icon: 'managing-roles',
      title: t.COURSE_ADMIN_MEGA_MENU.COURSE_FACILITATION(
        offeringName.singularizedTitleized,
      ),
      items: [
        {
          show:
            isCourseBuilder
            || isConfigAndRegistration
            || isInstructor
            || isLearnerRegistration
            || isTeachingAssistant
            || isReportAdmin,
          label: t.COURSE_ADMIN_MEGA_MENU.USER_MANAGEMENT.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.USER_MANAGEMENT.TOOLTIP(),
          link: getUserManagementLearnersUrl(),
        },
        {
          show: isInstructor || isReportAdmin || isTeachingAssistant,
          label: t.COURSE_ADMIN_MEGA_MENU.LEARNER_PROGRESS.LABEL({ LearnerAlias: learnersName.singularizedTitleized }),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.LEARNER_PROGRESS.TOOLTIP({ learnerAlias: learnersName.downcasedSingularized, offeringName: offeringName.downcasedSingularized }),
          link: getLearnerProgressUrl(),
        },
        {
          show: isCourseBuilder || isInstructor,
          label: t.COURSE_ADMIN_MEGA_MENU.COURSE_COMMUNICATIONS.LABEL(
            offeringName.singularizedTitleized,
          ),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.COURSE_COMMUNICATIONS.TOOLTIP(
            offeringName.downcasedSingularized,
          ),
          link: getCourseCommunicationsEditUrl(),
        },
        {
          show: isConfigAndRegistration || isInstructor || isTeachingAssistant,
          label: t.COURSE_ADMIN_MEGA_MENU.CONTENT_FLAGS.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.CONTENT_FLAGS.TOOLTIP(),
          link: RailsRoutes.courseAdminDashboardTabPath(catalogId, 'flaggings'),
        },
      ],
    },
    {
      show: isInstructor || isReportAdmin,
      icon: 'data',
      title: t.COURSE_ADMIN_MEGA_MENU.DATA_ANALYTICS(),
      items: [
        {
          show: (isInstructor || isReportAdmin) && !isProgram,
          label: t.COURSE_ADMIN_MEGA_MENU.ANALYTICS.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.ANALYTICS.TOOLTIP(
            offeringName.downcasedSingularized,
          ),
          link: getAnalyticsUrl(),
        },
        {
          show: (isInstructor || isReportAdmin) && !isProgram && institutionHasSkillTags,
          label: t.COURSE_ADMIN_MEGA_MENU.SKILLS_ANALYTICS.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.SKILLS_ANALYTICS.TOOLTIP(
            offeringName.downcasedSingularized,
          ),
          link: getSkillsAnalyticsUrl(),
        },
        {
          show: showCohorts,
          label: t.COURSE_ADMIN_MEGA_MENU.COHORTS_ANALYTICS.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.COHORTS_ANALYTICS.TOOLTIP(),
          link: getCohortAnalyticsUrl(),
        },
        {
          show: isReportAdmin,
          label: t.COURSE_ADMIN_MEGA_MENU.DATA_DOWNLOADS.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.DATA_DOWNLOADS.TOOLTIP(),
          link: getDataDownloadsUrl('data-downloads'),
        },
        {
          show: isReportAdmin,
          label: t.COURSE_ADMIN_MEGA_MENU.USER_GENERATED_CONTENT_DOWNLOADS.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.USER_GENERATED_CONTENT_DOWNLOADS.TOOLTIP(),
          link: getDataDownloadsUrl('content-downloads'),
        },
      ],
    },
    {
      show:
        (courseLongTeamSet || groupTeamSet)
        && (isCourseBuilder
          || isConfigAndRegistration
          || isInstructor
          || isTeachingAssistant),
      icon: 'g-teams',
      title: t.COURSE_ADMIN_MEGA_MENU.TEAMS_AND_GROUPS(),
      items: [
        {
          show:
            (courseLongTeamSet
              || groupTeamSet)
            && (isInstructor || isTeachingAssistant),
          label: t.COURSE_ADMIN_MEGA_MENU.FACILITATION_DASHBOARD.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.FACILITATION_DASHBOARD.TOOLTIP(),
          link: getTeamFacilitationUrl(),
        },
        {
          show:
            !!(
              courseLongTeamSet
              && courseLongTeamSet?.lecturePageId
              && courseLongTeamSet?.lectureComponentId
            )
            && (isCourseBuilder || isConfigAndRegistration || isInstructor)
            && getTeamGroupFormationUrl(courseLongTeamSet),
          label: t.COURSE_ADMIN_MEGA_MENU.TEAM_FORMATION_ACTIVITY_MANAGE_TEAMS.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.TEAM_FORMATION_ACTIVITY_MANAGE_TEAMS.TOOLTIP(
            offeringName.downcasedSingularized,
          ),
          link: getTeamGroupFormationUrl(courseLongTeamSet),
        },
        {
          show:
            !!(
              groupTeamSet
              && groupTeamSet?.lecturePageId
              && groupTeamSet?.lectureComponentId
            )
            && (isCourseBuilder || isConfigAndRegistration || isInstructor)
            && getTeamGroupFormationUrl(groupTeamSet),
          label: t.COURSE_ADMIN_MEGA_MENU.GROUP_FORMATION_ACTIVITY_MANAGE_GROUPS.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.GROUP_FORMATION_ACTIVITY_MANAGE_GROUPS.TOOLTIP(
            offeringName.downcasedSingularized,
          ),
          link: getTeamGroupFormationUrl(groupTeamSet),
        },
      ],
    },
    {
      show: isCourseBuilder || isConfigAndRegistration,
      icon: 'settings',
      title: t.COURSE_ADMIN_MEGA_MENU.CONFIGURATIONS(),
      items: [
        {
          show: isCourseBuilder || isConfigAndRegistration,
          label: t.COURSE_ADMIN_MEGA_MENU.DEFAULT_COURSE_LANGUAGE.LABEL(
            offeringName.singularizedTitleized,
          ),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.DEFAULT_COURSE_LANGUAGE.TOOLTIP(
            offeringName.downcasedSingularized,
          ),
          callback: () => $uibModal.open({
            templateUrl: 'shared/templates/language-modal.html',
            controller: 'LanguageModalCtrl as vm',
            resolve: {
              currentUser: CurrentUserManager.user,
              context: { isCourse: true },
            },
          }),
        },
        {
          show: isCourseBuilder || isConfigAndRegistration,
          label: t.COURSE_ADMIN_MEGA_MENU.USER_PROFILE_QUESTIONS.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.USER_PROFILE_QUESTIONS.TOOLTIP(
            offeringName.downcasedSingularized,
          ),
          link: RailsRoutes.courseAdminDashboardTabPath(catalogId, 'profiles'),
        },
        {
          show: isCourseBuilder || isConfigAndRegistration,
          label: t.COURSE_ADMIN_MEGA_MENU.ALIASES.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.ALIASES.TOOLTIP(
            offeringName.downcasedSingularized,
          ),
          link: RailsRoutes.courseAdminDashboardTabSectionPath(
            catalogId,
            'configurations',
            'aliases',
          ),
        },
        {
          show: isCourseBuilder || isConfigAndRegistration,
          label: t.COURSE_ADMIN_MEGA_MENU.CONFIGURE_COMMUNITY_PANEL.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.CONFIGURE_COMMUNITY_PANEL.TOOLTIP(
            offeringName.downcasedSingularized,
          ),
          link: RailsRoutes.courseAdminDashboardTabSectionPath(
            catalogId,
            'configurations',
            'configure-community-panel',
          ),
        },
        {
          show: isCourseBuilder || isConfigAndRegistration,
          label: t.COURSE_ADMIN_MEGA_MENU.ADVANCED_SETTINGS.LABEL(),
          tooltip: t.COURSE_ADMIN_MEGA_MENU.ADVANCED_SETTINGS.TOOLTIP(
            offeringName.downcasedSingularized,
          ),
          link: RailsRoutes.courseAdminDashboardTabPath(
            catalogId,
            'configurations',
          ),
        },
      ],
    },
  ];

  return courseAdminCategories;
}

export default getCourseAdminCategories;
