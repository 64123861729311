import React, { useContext } from 'react';
import t from 'react-translate';
import AdvancedSettingsSwitch from 'institutions/components/advanced-settings-switch';
import { AngularContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import SectionContent from '../section-content';
import { config } from '../../../../../config/pendo.config.json';

export const learnMoreLink = 'https://help.novoed.com/hc/en-us/articles/26698414779924-AI-Hub-Configurations#h_01J04YQWDH0K2MWEVAMENZ299A';

const GenerativeAiCapabilities = () => {
  const { injectServices } = useContext(AngularContext);
  const [InstitutionsManager] = injectServices(['InstitutionsManager']);
  const dispatch = useAppDispatch();
  const switchGenerativeAiCapabilities = (value) => {
    InstitutionsManager.institution.updateFromReact({
      hasAiComponentGeneration: value,
    });

    const failureCallback = () => {
      dispatch(addAlertMessage({ type: AlertMessageType.ERROR, header: t.FORM.OOPS(), message: t.FORM.ERROR_SOMETHING_WRONG() }));
      InstitutionsManager.institution.updateFromReact({
        hasAiComponentGeneration: !value,
      });
    };

    InstitutionsManager.toggleGenerativeAiCapabilities(value)
      .then((success) => {
        if (!success) {
          failureCallback();
        }
      }, () => {
        failureCallback();
      });
  };

  return (
    <SectionContent
      header={t.INSTITUTIONS.ADVANCED_SETTINGS.GENERATIVE_AI_CAPABILITIES.TITLE()}
      description={[t.INSTITUTIONS.ADVANCED_SETTINGS.GENERATIVE_AI_CAPABILITIES.DESCRIPTION(learnMoreLink)]}
      extras={(
        <div className='d-flex align-self-start'>
          <AdvancedSettingsSwitch
            onChange={switchGenerativeAiCapabilities}
            dataQa={config.pendo.settings.toggleGenerativeAiCapabilities}
            value='vm.InstitutionsManager.institution.hasAiComponentGeneration'
          />
        </div>
      )}
    />
  );
};

export default GenerativeAiCapabilities;
