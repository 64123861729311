/* this file should not be used and should be wiped out in the near future. please use classes from react_typography
 * all typography should follow the global design
 */
// font sizes
export const textXSFontSize = 10;
export const textSmallFontSize = 12;
export const textMediumFontSize = 14;
export const textLargeBodyFontSize = 16;
export const textLargeFontSize = 18;
export const textExtraLargeFontSize = 24;
export const headerFontSize = 36;

// line heights
export const textSmallLineHeight = 15;
export const textMediumLineHeight = 20;
export const textLargeLineHeight = 30;
export const headerLineHeight = 25;
export const headerRegularLineHeight = 35;
export const headerLargeLineHeight = 50;

// font weights
export const lightFontWeight = 300;
export const normalFontWeight = 400;
export const semiBoldFontWeight = 600;
export const boldFontWeight = 700;

// font families
export const openSans = '"Open Sans", Helvetica, Arial, sans-serif';
export const openSansCondensed = '"Open Sans Condensed", Helvetica, Arial, sans-serif';
