/*
  original version: 2.6.5
  latest referenced version: 2.8.4
*/
(function (factory) {
  factory(window.jQuery);
}(($) => {
  $.extend($.FE.DEFAULTS, {
    paragraphStyles: {
      'fr-text-gray': 'Gray',
      'fr-text-bordered': 'Bordered',
      'fr-text-spaced': 'Spaced',
      'fr-text-uppercase': 'Uppercase',
    },
    paragraphMultipleStyles: true,
  });

  $.FE.PLUGINS.paragraphStyle = function (editor) {
    /**
     * Apply style.
     */
    function apply(val, paragraphStyles, paragraphMultipleStyles) {
      if (typeof paragraphStyles === 'undefined') {
        ({ paragraphStyles } = editor.opts);
      }

      if (typeof paragraphMultipleStyles === 'undefined') {
        ({ paragraphMultipleStyles } = editor.opts);
      }

      let styles = '';

      // Remove multiple styles.
      if (!paragraphMultipleStyles) {
        styles = Object.keys(paragraphStyles);
        styles.splice(styles.indexOf(val), 1);
        styles = styles.join(' ');
      }
      editor.selection.save();
      editor.html.wrap(true, true, true, true);
      editor.selection.restore();

      const blocks = editor.selection.blocks();

      // Save selection to restore it later.
      editor.selection.save();

      const hasClass = $(blocks[0]).hasClass(val);

      for (let i = 0; i < blocks.length; i += 1) {
        $(blocks[i]).removeClass(styles).toggleClass(val, !hasClass);

        if ($(blocks[i]).hasClass('fr-temp-div')) {
          $(blocks[i]).removeClass('fr-temp-div');
        }

        if ($(blocks[i]).attr('class') === '') {
          $(blocks[i]).removeAttr('class');
        }
      }

      // Unwrap temp divs.
      editor.html.unwrap();

      // Restore selection.
      editor.selection.restore();
    }

    function refreshOnShow($btn, $dropdown) {
      const blocks = editor.selection.blocks();

      if (blocks.length) {
        const $blk = $(blocks[0]);
        let hasAnyClass = false;

        $dropdown.find('.fr-command').each(function () {
          const cls = $(this).data('param1');
          const active = $blk.hasClass(cls);
          hasAnyClass = hasAnyClass || active;
          $(this).toggleClass('fr-novoed-active', active).attr('aria-selected', active);
        });
      }
    }

    function _init() {
    }

    return {
      _init,
      apply,
      refreshOnShow,
    };
  };

  // Register the font size command.
  $.FE.RegisterCommand('paragraphStyle', {
    type: 'dropdown',
    html() {
      let c = '<ul class="fr-dropdown-list" role="presentation">';
      const options = this.opts.paragraphStyles;

      // this is copied from library - could be using "for...in" intentionally
      // eslint-disable-next-line no-restricted-syntax
      for (const val in options) {
        if (Object.prototype.hasOwnProperty.call(options, val)) {
          c += `<li role="presentation">
            <a class="fr-command ${val} paragraph-style-item"
                tabIndex="-1"
                role="option"
                data-cmd="paragraphStyle"
                data-param1="${val}"
                title="${this.language.translate(options[val])}">
              ${this.language.translate(options[val])}
              <i class="icon icon-smallest icon-check"></i>
            </a>
          </li>`;
        }
      }
      c += '</ul>';

      return c;
    },
    title: 'Font Style',
    callback(cmd, val) {
      this.paragraphStyle.apply(val);
    },
    refreshOnShow($btn, $dropdown) {
      this.paragraphStyle.refreshOnShow($btn, $dropdown);
    },
    plugin: 'paragraphStyle',
  });

  // Add the font size icon.
  $.FE.DefineIcon('paragraphStyle', { NAME: 'format-fontstyle' });
}));
