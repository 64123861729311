import { InstitutionLogoSize } from 'redux/schemas/models/institution';

/* @ngInject */
export default function NvCurrentPage(
  _,
  $state,
  $sce,
  $rootScope,
  $location,
  $cookies,

  CurrentCourseManager,
  CurrentUserManager,
  CurrentPermissionsManager,
  InstitutionsManager,
  PageLevelManager,
  FlyOutPanelManager,
) {
  let modalHeaderCourse;
  let modalPageNoComments = false;
  let trustedHelpWidgetContent = null;

  $rootScope.$on('$stateChangeSuccess', () => {
    trustedHelpWidgetContent = null;
  });

  return {
    isCourseHome,
    isJourneyHome,
    isDisplayingState,
    isInstitutionPage,
    isDirectoryPage,
    isViewerAdmin,
    getBrandColor,
    getMainContentBrandColor,
    getFontColor,
    getLanguageClass,
    getLogo,
    hasCourse,
    hasInstitution,
    hasDiscussions,
    hasTeams,
    hasGroups,
    hasGallery,
    hasCommunity,
    hasHelpWidget,
    hasLeaderboard,
    hasLearnersNav,
    hasTeachingTeamNav,
    hasSecondaryQuickAccessOptions,
    getCourse,
    getInstitution,
    getPageLevel,
    getCurrentStateData,
    getNonL4Data,
    getNonL4State,
    getParentState,
    getHelpWidget,
    getTrustedHelpWidgetContent,

    isInProgram,
    getParentProgram,

    isInWalledGarden,
    getCurrentWalledGardenInstitution,

    unreadPrivacyPolicy,
    markUnreadPrivacyPolicyAsRead,
    unreadCookiePolicy,
    markUnreadCookiePolicyAsRead,
    showDowntimeAlert,
    markDowntimeAlertAsRead,
    setModalPageNoComments,
    resetModalPageNoComments,
    modalPageHasNoComments,
    setModalHeaderCourse,
    resetModalHeaderCourse,
    getModalHeaderCourse,
    getModalHeaderCourseLogo,
    getCourseLogoClass,
    uiScrollAdapter: null,

    CurrentPermissionsManager,
  };

  function isCourseHome(includeEditMode = true) {
    // If current state is a modal or flyout, then get parent state
    if (!isModalOrFlyoutPanel()) {
      return CurrentCourseManager.course?.isProgram
        ? $state.includes('program-home') : $state.includes('course-home') || $state.includes('course-home-edit');
    }
    return getNonL4State().name === 'course-home' || getNonL4State().name === 'program-home';
  }

  function isJourneyHome() {
    return $state.includes('learning-journey-home');
  }

  // have to put this here because controllers at the top of the routing chain dont' have access to $stateParams it didn't declare
  function isDisplayingState(stateName) {
    return getCurrentStateData().level !== 4 ? $state.includes(stateName) : getNonL4State().name === stateName;
  }

  function isInstitutionPage() {
    return $state.includes('institution-abstract');
  }

  function isDirectoryPage() {
    return getNonL4Data().isDirectoryPage;
  }

  function isViewerAdmin() {
    return CurrentUserManager.isAdmin();
  }

  /* Brand color for the main-content view. Uses the org branding if an institution page */
  function getMainContentBrandColor() {
    if (PageLevelManager.isLastParentOrgLevel()) {
      return getInstitution().brandColor;
    }

    return getBrandColor();
  }

  function getBrandColor() {
    if (!isInstitutionPage() && hasCourse()) {
      // if course has overriden color, return that, and default to the institution brand color
      return CurrentCourseManager.course.headerColor ? CurrentCourseManager.course.headerColor : CurrentCourseManager.course.institutionBrandColor;
    } if (hasInstitution()) {
      return getInstitution().brandColor;
    }
    return getCurrentWalledGardenInstitution().brandColor;
  }

  function getFontColor() {
    if (hasCourse()) {
      return CurrentCourseManager.course.nameFontColor ? CurrentCourseManager.course.nameFontColor : CurrentCourseManager.course.institutionFontColor;
    } if (hasInstitution()) {
      return getInstitution().brandBarFontColor;
    }
    return getCurrentWalledGardenInstitution().brandBarFontColor;
  }

  /*
      default font: "Open Sans" covers Latin 1, Latin CE, Greek and Cyrillic character sets
      here we only overwrite the default if the language needs a new font stack
      as of 2019-08-28 only Chinese and Japanese needs it's own font stack
    */
  function getLanguageClass() {
    let lang = '';

    // only languages specified in LANGUAGES_WITH_OWN_FONT_STACK have their own font stack. for everything else, we can use the default font stack
    const LANGUAGES_WITH_OWN_FONT_STACK = ['zh_CN', 'ja_JP', 'ko_KP', 'ar_SA'];
    if (hasCourse() && CurrentCourseManager.course.contentLanguage && _.contains(LANGUAGES_WITH_OWN_FONT_STACK, CurrentCourseManager.course.contentLanguage)) {
      lang = CurrentCourseManager.course.contentLanguage;
    } else if (CurrentUserManager.user?.platformLanguage && _.contains(LANGUAGES_WITH_OWN_FONT_STACK, CurrentUserManager.user.platformLanguage)) {
      lang = CurrentUserManager.user.platformLanguage;
    } else if ($cookies.get('platform_language') && _.contains(LANGUAGES_WITH_OWN_FONT_STACK, $cookies.get('platform_language'))) {
      lang = $cookies.get('platform_language');
    }

    if (lang) {
      return `lang-${lang}`;
    }

    return null;
  }

  function getLogo() {
    if (hasCourse()) {
      return CurrentCourseManager.course.logo
        ? CurrentCourseManager.course.logo.url
        : CurrentCourseManager.course.institution.logo;
    } if (hasInstitution()) {
      return getInstitution().logo;
    }
    return null;
  }

  function getCourseLogoClass() {
    const LOGO_CLASSES = {
      [InstitutionLogoSize.SMALL]: 'logo-small',
      [InstitutionLogoSize.MEDIUM]: 'logo-medium',
      [InstitutionLogoSize.LARGE]: 'logo-large',
    };

    let logoSize = InstitutionLogoSize.SMALL;
    if (hasCourse()) {
      logoSize = getCourse().logo ? getCourse().logoSize : getCourse().institution?.logoSize;
    }

    return LOGO_CLASSES[logoSize];
  }

  function hasCourse() {
    return CurrentCourseManager?.course;
  }

  function hasInstitution() {
    return InstitutionsManager?.institution;
  }

  function hasDiscussions() {
    return hasCourse() && _.indexOf(getCourse().quickAccessOptions, 'Discussions') > -1 && getCourse().numForumsReleased > 0;
  }

  function hasTeams() {
    return hasCourse() && _.indexOf(getCourse().quickAccessOptions, 'Teams') > -1 && getCourse().hasCourseLongTeams;
  }

  function hasGroups() {
    return hasCourse() && _.indexOf(getCourse().quickAccessOptions, 'Groups') > -1 && getCourse().hasGroups;
  }

  function hasCommunity() {
    return hasCourse() && getCourse().hasCommunity;
  }

  function hasGallery() {
    return hasCourse() && _.indexOf(getCourse().quickAccessOptions, 'Gallery') > -1 && getCourse().numFeatureableExercises > 0;
  }

  function hasHelpWidget() {
    return hasCourse() && _.indexOf(getCourse().quickAccessOptions, 'Help') > -1 && !!getCourse().helpWidget;
  }

  function hasLeaderboard() {
    return hasCourse() && _.indexOf(getCourse().quickAccessOptions, 'Leaderboard') > -1 && getCourse().isLeaderboardEnabled();
  }

  function hasLearnersNav() {
    return hasCourse() && _.indexOf(getCourse().quickAccessOptions, 'Learners') > -1;
  }

  function hasTeachingTeamNav() {
    return hasCourse() && _.indexOf(getCourse().quickAccessOptions, 'Teaching Team') > -1;
  }

  function hasSecondaryQuickAccessOptions() {
    return hasTeams() || hasGroups() || hasLearnersNav() || hasTeachingTeamNav();
  }

  function getCourse() {
    return CurrentCourseManager.course;
  }

  function getInstitution() {
    return InstitutionsManager.institution;
  }

  function isModalOrFlyoutPanel() {
    return $state.current.data.level === 4 || $state.current.data.level === 3.5;
  }

  function getPageLevel() {
    return getNonL4Data().level;
  }

  function getCurrentStateData() {
    return $state.current.data;
  }

  function getNonL4Data() {
    return getNonL4State().data;
  }

  function getNonL4State() {
    // return getCurrentStateData().level !== 4 ? $state.current : PageLevelManager.lastParent().state;
    if (getCurrentStateData().level === 3.5) {
      // Current state is flyout panel get parent
      return FlyOutPanelManager.getParentState();
    }
    if (getCurrentStateData().level === 4) {
      // L4 page. Get Parent
      if (PageLevelManager.lastParent().state && PageLevelManager.lastParent().state.data.level === 3.5) {
        // If parent is an L 3.5 Flyout Panel. Get its flyout panel's parent
        return FlyOutPanelManager.getParentState();
      }
      return PageLevelManager.lastParent().state;
    }
    return $state.current;
  }

  function getParentState() {
    return $state.current.parent;
  }

  function getHelpWidget() {
    return hasCourse() && getCourse().helpWidget;
  }

  function getTrustedHelpWidgetContent() {
    if (!trustedHelpWidgetContent && hasHelpWidget()) {
      trustedHelpWidgetContent = $sce.trustAsHtml(
        `<div class="label text-light help-menu-header">${getHelpWidget().title}</div><br/> <div class = "blue-links">${getHelpWidget().body}</div>`,
      );
    }
    return trustedHelpWidgetContent;
  }

  function isInProgram() {
    if (hasCourse()) {
      const currentCourse = CurrentUserManager.coursesHash[getCourse().id];
      return currentCourse?.parentPrograms?.length;
    }
    return false;
  }

  function getParentProgram() {
    if (!isInProgram()) {
      return null;
    }

    const currentCourse = CurrentUserManager.coursesHash[getCourse().id];

    // for simplicity we only return the first program
    return currentCourse.parentPrograms[0];
  }

  function isInWalledGarden() {
    return true;
  }

  function getCurrentWalledGardenInstitution() {
    const urlHost = $location.host();

    const currentInstitution = _.find(CurrentUserManager.user.institutions, (walledGardenInst) => walledGardenInst.host === urlHost);

    return currentInstitution;
  }

  function unreadPrivacyPolicy() {
    return _.find(CurrentUserManager.user.unreadLegalTrackables, (trackable) => trackable.type === 'PrivacyPolicy'
              && (!trackable.institutionId || (CurrentUserManager.currentInstitution && trackable.institutionId === CurrentUserManager.currentInstitution.id)));
  }

  function unreadCookiePolicy() {
    return _.find(CurrentUserManager.user.unreadLegalTrackables, (trackable) => trackable.type === 'CookiePolicy');
  }

  function markUnreadPrivacyPolicyAsRead() {
    const unreadPolicy = unreadPrivacyPolicy();
    if (unreadPolicy) {
      return CurrentUserManager.removeUnreadTrackable(unreadPolicy.id);
    }
    return null;
  }

  function markUnreadCookiePolicyAsRead() {
    const unreadPolicy = unreadCookiePolicy();
    if (unreadPolicy) {
      return CurrentUserManager.removeUnreadTrackable(unreadPolicy.id);
    }
    return null;
  }

  function showDowntimeAlert() {
    return CurrentUserManager.user.mustSeeDowntimeAlert;
  }

  function markDowntimeAlertAsRead() {
    return CurrentUserManager.markDowntimeAlertAsRead();
  }

  function setModalPageNoComments() {
    modalPageNoComments = true;
  }

  function resetModalPageNoComments() {
    modalPageNoComments = false;
  }

  function modalPageHasNoComments() {
    return !!modalPageNoComments;
  }

  function setModalHeaderCourse(course) {
    modalHeaderCourse = course;
  }

  function resetModalHeaderCourse() {
    modalHeaderCourse = null;
  }

  function getModalHeaderCourse() {
    return modalHeaderCourse;
  }

  function getModalHeaderCourseLogo() {
    if (modalHeaderCourse) {
      if (modalHeaderCourse.thumbnail && !modalHeaderCourse.thumbnail.includes('assets/brand/temp.png')) {
        return modalHeaderCourse.thumbnail;
      }
      return modalHeaderCourse.institution.logo;
    }

    return null;
  }
}
