import t from 'react-translate';
import { css } from '@emotion/react';
import NvIcon from 'shared/components/nv-icon';
import { gray6 } from 'styles/global_defaults/colors';
import { standardSpacing } from 'styles/global_defaults/scaffolding';

import NvModal, { ModalType, NvModalProps } from './nv-modal';
import NvTooltip from './nv-tooltip';

export type IconLibraryModalProps = {
  onIconClick: (iconName: string) => void,
} & Pick<NvModalProps, 'show' | 'onClose'>;

const iconNames = ['read', 'assignments', 'conversations', 'comments', 'video', 'audio', 'quiz', 'survey', 'evaluation', 'zoom', 'drafts', 'gallery', 'team', 'groups', 'profile', 'announcements', 'notifications', 'messages', 'settings', 'language', 'info', 'help', 'question', 'create-new-post', 'add', 'check', 'success', 'warning', 'like', 'share', 'highlight', 'points', 'admin-credits', 'dueday', 'ideas', 'improvement', 'home', 'admin-dashboard', 'dashboard', 'card-view', 'calendar', 'search', 'mobile', 'files', 'admin-embeds', 'education', 'job-title', 'location', 'work', 'events', 'locked', 'badge', 'course', 'email-template', 'flag', 'download', 'open-external'];

/** Creates a modal that displays a grid of icons from our icon font to be selected, and closes the modal after
 * a selection is made.  */
export const IconLibraryModal = (props: IconLibraryModalProps) => {
  const styles = css`
    margin-left: ${standardSpacing * 2}px;
    margin-right: ${standardSpacing * 2}px;
    overflow-x: none;
    width: 720px;

    .iconContainer:hover {
      cursor: pointer;
      background-color: ${gray6};
    }

    /* Unfortunately the icons in this modal do not correspond to any of the preset classes */
    .icon-large {
      font-size: 40px;
    }
  `;

  const onIconClick = (iconName: string) => {
    props.onIconClick?.(iconName);
    props.onClose?.();
  };

  const content = (
    <div css={styles} className='d-flex flex-wrap mb-4'>
      {iconNames.map(iconName => (
        <NvTooltip key={iconName} text={t.ICON_LIBRARY.TOOLTIP()}>
          <div className='iconContainer p-4 m-4' onClick={() => onIconClick(iconName)}>
            <NvIcon icon={iconName} size='large' />
          </div>
        </NvTooltip>
      ))}
    </div>
  );

  return (
    <NvModal
      header={t.ICON_LIBRARY.HEADER()}
      body={content}
      type={ModalType.DYNAMIC}
      width={880}
      show={props.show}
      onClose={props.onClose}
    />
  );
};

export default IconLibraryModal;
