import { css, jsx } from '@emotion/react';
import { useState, useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import t from 'react-translate';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AngularServicesContext } from 'react-app';

// Selectors
import { getRoleAliases } from 'redux/selectors/institutions';

// Actions
import { updateRoleAliases } from 'redux/actions/org-mentors';

import { useAppDispatch } from 'redux/store';
import NvTextInput from 'shared/components/inputs/nv-text-input';

const styles = css`
  .mentee-input {
    width: 200px;
  }
`;

interface UpdateOrgMentorAliasModalProps {
  onClose: () => void,
}

const UpdateOrgMentorAlias = (props: UpdateOrgMentorAliasModalProps) => {
  const angularServices = useContext(AngularServicesContext);
  const { InstitutionsManager } = angularServices;
  const dispatch = useAppDispatch();
  const roleAliases = useSelector(getRoleAliases);
  const [submitting, setSubmitting] = useState(false);
  const currentInstitutionId = useSelector((state) => state.app.currentInstitutionId);

  const validationSchema = yup.object().shape({
    mentor: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .max(255, t.VALIDATION.MAX_LENGTH('255')),
    mentee: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .max(255, t.VALIDATION.MAX_LENGTH('255')),
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      mentor: roleAliases?.mentor?.singularizedTitleized,
      mentee: roleAliases?.mentee?.singularizedTitleized,
    },
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, formState } = methods;
  const { isDirty, isValid } = formState;

  const onSubmit = (formData) => {
    setSubmitting(true);
    dispatch(updateRoleAliases({ institutionId: currentInstitutionId, roleAliases: formData }))
      .then((response) => {
        // Update Angular Manager for the new role alias
        InstitutionsManager.institution.roleAliases = response.payload.result.roleAliases;
        props.onClose();
      });
  };
  return (
    <div css={styles}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className='text-center pt-4 pb-2 '>{t.INSTITUTIONS.ORG_MENTORS.CHANGE_ROLE_ALIAS_MODAL_DETAIL()}</p>
          <div className='d-flex justify-content-center pt-3 pb-6'>
            <NvTextInput
              required
              withForm
              className='mentee-input-wrapper mx-2'
              name='mentor'
            />
            <NvTextInput
              required
              withForm
              className='mentee-input-wrapper  mx-2'
              name='mentee'
            />
          </div>
          <div className='bs4-row py-4'>
            <div className='mx-auto'>
              <button
                type='button'
                className='bs4-btn bs4-btn-secondary bs4-btn-sm mx-1'
                onClick={props.onClose}
              >
                {t.FORM.CANCEL()}
              </button>
              <button
                type='submit'
                className='bs4-btn bs4-btn-primary bs4-btn-sm  mx-1'
                disabled={!isDirty || !isValid || submitting}
              >
                {submitting ? t.FORM.SAVING() : t.FORM.SAVE()}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default UpdateOrgMentorAlias;
