import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { css, ClassNames } from '@emotion/core';

import t from 'react-translate';
import { RootState } from 'redux/schemas';
import NvIcon from 'shared/components/nv-icon';
import { gray1 } from 'styles/global_defaults/colors';
import ProgressiveQuizContext from 'quizzes/components/context';
import ClickableContainer from 'components/clickable-container';
import { getPointsConfiguration } from 'redux/selectors/points-configurations';
import { doubleModalZIndex, getModalManager } from 'shared/components/nv-modal';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { ReactComponent as GrayStarGraphic } from 'styles/icons/gray-star-graphic.svg';

const FailedQuizModal = () => {
  const [showModal, setShowModal] = React.useState(false);
  const {
    progressiveQuiz,
    notMetRequirement,
    requiredCorrectQuestionsCount,
  } = React.useContext(ProgressiveQuizContext);

  const pointsConfiguration = useSelector(
    (state: RootState) => getPointsConfiguration(state, progressiveQuiz.pointsConfiguration),
  );

  const isModalEnabled = pointsConfiguration?.passingScoreRequired;

  React.useEffect(() => {
    setShowModal(notMetRequirement());
  }, [notMetRequirement]);

  const styles = css`
    z-index: ${doubleModalZIndex};
    display: flex!important;
    align-items: center;
    justify-content: center;

    .bs4-modal-dialog {
      width: ${440}px;
    }

    .bs4-modal-content {
      border: 0;
      border-radius: ${halfSpacing}px;
    }

    .failed-quiz-modal-content {
      padding-bottom: 95px;

      .graphic {
        margin: 0 auto;
      }

      .close-button {
        top: ${standardSpacing}px;
        right: ${standardSpacing}px;
      }
    }
  `;

  const closeModal = () => setShowModal(false);

  return (
    <ClassNames>
      {({ css: innerCss }) => (
        <Modal
          css={styles}
          enforceFocus
          animation={false}
          onHide={closeModal}
          manager={getModalManager()}
          show={isModalEnabled && showModal}
          backdropClassName={innerCss`
            opacity: 0.1!important;
            background-color: ${gray1};
            z-index: ${doubleModalZIndex};
          `}
        >
          <div className='failed-quiz-modal-content pt-5 pl-5 pr-5 position-relative'>
            <GrayStarGraphic
              width={293}
              height={80}
              className='graphic display-block mb-4'
            />
            <div className='page-title text-center mb-2'>
              {t.QUIZZES.UNMET_PASSING_SCORE()}
            </div>
            <div className='text-center mb-5'>
              {t.QUIZZES.ANSWER_AT_LEAST(requiredCorrectQuestionsCount)}
            </div>
            <div className='d-flex flex-column align-items-center'>
              <Button size='sm' onClick={closeModal}>
                {t.FORM.OK()}
              </Button>
            </div>
            <ClickableContainer className='close-button position-absolute' onClick={closeModal}>
              <NvIcon icon='close' size='xss-smallest' />
            </ClickableContainer>
          </div>
        </Modal>
      )}
    </ClassNames>
  );
};

export default FailedQuizModal;
