import { FilesToUploadMapping, LecturePageContextType } from '../components/lecture-page-context';

/* @ngInject */
export default function ReactLecturePageContextFactory() {
  class ReactLecturePageContext {
    updateLecturePage: () => void;

    filesToUpload: FilesToUploadMapping;

    setFilesToUpload: (newFilesToUpload: FilesToUploadMapping) => void;

    updateAngularComponentStatus: (componentType, componentId, data) => void;

    setContext(contextValue: LecturePageContextType) {
      Object.assign(this, contextValue);
    }
  }

  return new ReactLecturePageContext();
}
