export default {
  bindings: {
    course: '<',
    institution: '<',
  },
  replace: true,
  controller: function ctrl(
  ) {
'ngInject';
  },
  controllerAs: 'vm',
  templateUrl: 'shared/templates/nv-program-badge.html',
};
