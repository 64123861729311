import { css } from '@emotion/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';

import { getFlatCourseAliases } from 'redux/selectors/course';
import ColorPickerPopover from 'components/color-picker-popover';
import { useLecturePageFromParams, useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { TitleTextAlignment } from 'redux/schemas/models/lecture-page';

import { quarterSpacing } from 'styles/global_defaults/scaffolding';
import { black } from 'styles/global_defaults/colors';

import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import prodPathReplace from 'shared/prod-path-rewrite';
import { LecturePageEditContentParams } from '../lecture-page-content';

type LectureContentHeaderStyleToolbarProps = Omit<LecturePageEditContentParams, 'setIsHoverOnHeader'>;

export const LectureContentHeaderStyleToolbar = (props: LectureContentHeaderStyleToolbarProps) => {
  const styles = css`
    &.style-toolbar {
      visibility: hidden;
      height: 36px;
      max-width: max-content;
      padding: 0 ${quarterSpacing}px;
      border-radius: 4px;
      margin: -18px auto 0 auto;
      z-index: 10;

      .style-option {
        display: flex;
        justify-content: center;
        width: 36px;
        cursor: pointer;
      }

      .color-picker {
        width: 16px;
        height: 16px;
      }
    }
  `;

  const params = useLecturePageParams();
  const lecturePage = useLecturePageFromParams();

  const courseAliases = useSelector(state => getFlatCourseAliases(state, params.catalogId));
  const [isSaving, setIsSaving] = useState(false);

  const textAlignArray = Object.values(TitleTextAlignment);
  const titleTextAlign = lecturePage.viewOptions.alignment;

  const saveViewOptionChanges = (updatedViewOptions) => {
    setIsSaving(true);

    props.saveLecturePageData({
      viewOptions: {
        ...props.lecturePage.viewOptions,
        ...updatedViewOptions,
      },
    }).then(() => setIsSaving(false));
  };

  const setFontColor = (val) => {
    saveViewOptionChanges({ titleColor: val });
  };

  const setTitleTextAlign = () => {
    const currentIndex = textAlignArray.indexOf(titleTextAlign);
    const nextAlignItem = textAlignArray[(currentIndex + 1) % textAlignArray.length];

    saveViewOptionChanges({ alignment: nextAlignItem });
  };

  return (
    <div
      css={styles}
      className='style-toolbar d-flex justify-content-center align-items-center bg-black'
    >
      <ColorPickerPopover<{
        titleColor: string;
      }>
        sections={[{
          name: 'titleColor',
          title: t.LECTURE_PAGES.ADMIN.HEADER.TITLE_TEXT_COLOR(courseAliases),
        }]}
        colorValues={{
          titleColor: (() => {
            const initialColor = lecturePage.viewOptions.titleColor;
            if (!initialColor) {
              return black;
            }
            return initialColor.startsWith('#') ? initialColor : `#${initialColor}`;
          })(),
        }}
        onChange={(section, newColor) => {
          if (!isSaving) {
            setFontColor(newColor);
          }
        }}
        placement='bottom'
        offset={15}
      >
        <div className='style-option'>
          <NvTooltip
            text={t.LECTURE_PAGES.ADMIN.HEADER.TITLE_TEXT_COLOR_TOOLTIP()}
            offset={5}
          >
            <img
              className='color-picker'
              src={prodPathReplace('images/icon-colorpicker.png')}
              alt='color'
            />
          </NvTooltip>
        </div>
      </ColorPickerPopover>
      <div className='style-option'>
        <NvTooltip text={t.FROALA.TEXT_ALIGNMENT()} offset={5}>
          <NvIcon
            size='smallest'
            icon={`format-align${titleTextAlign}`}
            className='text-white'
            onClick={!isSaving && setTitleTextAlign}
          />
        </NvTooltip>
      </div>
    </div>
  );
};

export default LectureContentHeaderStyleToolbar;
