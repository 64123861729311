import React from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { mergeRefs } from 'shared/react-utils';
import { openSans } from 'styles/global_defaults/fonts';
import { CourseRegistrationType } from 'redux/schemas/models/course';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { OfferingContext } from 'offerings/components/offering-card';
import { black, hexToRgbaString } from 'styles/global_defaults/colors';
import EntitlementBadge, {
  SPACE_BETWEEN_BADGES,
} from 'offerings/components/entitlement-badge';

const MAX_AMOUNT_OF_BADGES_TO_SHOW = 3;

type Props = {
  className?: string,
  style?: React.CSSProperties,
};

const OfferedToOverlay = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    style,
    className,
  } = props;

  const containerWidthRef = React.useRef<number>();
  const offering = React.useContext(OfferingContext);
  const containerRef = React.useRef<HTMLDivElement>();
  const [badgeMaxWidth, setBadgeMaxWidth] = React.useState(0);
  const currentInstitution = useSelector(getCurrentInstitution);

  const containerWidth = containerRef.current?.getBoundingClientRect().width ?? 0;
  containerWidthRef.current = containerWidth;

  const calculateBadgeMaxWidthRef = React.useRef(() => {
    const {
      paddingLeft,
      paddingRight,
    } = getComputedStyle(containerRef.current);

    const containerContentWidth = (containerWidthRef.current
      - parseFloat(paddingLeft))
      - parseFloat(paddingRight);

    const newBadgeMaxWidth = (containerContentWidth - (SPACE_BETWEEN_BADGES * 2)) / 2;

    setBadgeMaxWidth(newBadgeMaxWidth);
  });

  React.useLayoutEffect(() => {
    calculateBadgeMaxWidthRef.current();
  }, [containerWidth]);

  React.useEffect(() => {
    const resizeListener = calculateBadgeMaxWidthRef.current;

    window.addEventListener('resize', resizeListener);

    return () => window.removeEventListener('resize', resizeListener);
  }, []);

  const styles = css`
    text-align: center;

    .title {
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      font-family: ${openSans}px;
    }

    .entitlement-values {
      line-height: 35px;

      & > * {
        vertical-align: bottom;
      }
    }
  `;

  const isOpenToAll = offering.typeOfRegistration === CourseRegistrationType.FREE_ENROLLMENT_IN_INSTITUTION
    || offering.typeOfRegistration === CourseRegistrationType.FREE_ENROLLMENT;

  const entitlementsToRender = offering.entitlements?.filter(
    (entitlement) => {
      const currentProfileSetting = currentInstitution.profileSettings.orgLevel.find(
        (profileSetting) => profileSetting.id === entitlement.profileSettingId,
      );

      if (currentProfileSetting) {
        return !currentProfileSetting.isHidden;
      }

      return false;
    },
  );

  return (
    <div
      css={styles}
      style={style}
      ref={mergeRefs(ref, containerRef)}
      className={`d-flex flex-column align-items-center justify-content-center${className ? ` ${className}` : ''}`}
    >
      {(isOpenToAll || !!entitlementsToRender.length) && <div className='title'>{t.OFFERINGS.CARD.OFFERED_TO()}</div>}
      <div className='entitlement-values'>
        {isOpenToAll ? (
          <EntitlementBadge
            color='#fff'
            showIcon={false}
            maxWidth={badgeMaxWidth}
            value={t.OFFERINGS.CARD.OPEN_TO_ALL()}
            badgeColor={hexToRgbaString(black, 0.8)}
          />
        ) : entitlementsToRender.reduce(
          (acc, curr) => acc.concat(curr.values.map((value, index) => ({
            value,
            key: `${curr.id}-${index}-${value}`,
          }))),
          [],
        ).slice(0, MAX_AMOUNT_OF_BADGES_TO_SHOW).map((entitlementBadge) => (
          <EntitlementBadge
            color='#fff'
            maxWidth={badgeMaxWidth}
            key={entitlementBadge.key}
            value={entitlementBadge.value}
            badgeColor={hexToRgbaString(black, 0.8)}
          />
        ))}
      </div>
    </div>
  );
});

export default OfferedToOverlay;
