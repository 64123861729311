import { schema } from 'normalizr';

import { Comment, CommentsNormalized } from 'redux/schemas/models/comment';

export interface RemoveNewCommentId {
  commentId: number;
}

export interface FetchCommentsRequest {
  catalogId: string;
  postId?: number;
  order?: 'likes';
  beforeId?: number;
  afterId?: number;
  pageSize?: number;
  vieweeLastActivity?: string;
  reportId?: number;
}

export interface FetchCommentsResponse {
  additionalPostsAfterCount: number;
  additionalPostsBeforeCount: number;
  posts: Comment[];
}

export const FetchCommentsSchema = new schema.Object({
  posts: new schema.Array(new schema.Entity<Comment>('comments', {}, {
    processStrategy: (value) => ({
      ...value,
      replyIds: [],
      likers: [],
      repliesCount: value.commentsCount,
    }),
  })),
});

export interface FetchCommentsEntities {
  comments: CommentsNormalized;
}

export interface FetchCommentsNormalizedResult {
  commentIds: number[];
}

export interface CreateCommentRequest {
  postId?: number;
  ownerType?: string; // report
  ownerId?: number;
  catalogId: string;
  body: string;
  mentionedIds?: number[];
  includeVideoTimestamp?: number;
  userId?: number;
  callback?: Function;
}

export interface DeleteCommentRequest {
  postId?: number;
  ownerType?: string; // report
  ownerId?: number;
  catalogId: string;
  commentId: number;
  userId?: number;
  callback?: Function;
}

export interface UpdateCommentRequest {
  catalogId: string;
  commentId: number;
  body: string;
  mentionedIds?: number[];
  includeVideoTimestamp?: boolean;
  videoTimestamp?: string;
}

export interface LikeCommentRequest {
  catalogId: string;
  commentId: number;
}

export interface LikeResponse {
  numLikes: number;
}

export interface HighlightCommentRequest {
  catalogId: string;
  commentId: number;
}

export interface UnHighlightCommentRequest {
  catalogId: string;
  commentId: number;
}

export interface FlagCommentRequest {
  catalogId: string;
  commentId: number;
}

interface AddComment {
  postId: number;
  userId: number;
  catalogId: string;
}

export interface AddCommentRequest extends AddComment {
  commentId: number;
  callback?: Function;
  ownerType?: string; // report
  ownerId?: number;
  postsCount?: number;
}

export interface AddCommentResponse extends AddComment {
  comment: Comment;
}

export interface EditCommentRequest {
  catalogId: string;
  commentId: number;
}

export interface EditCommentResponse {
  comment: Comment;
}

export interface RemoveComment {
  commentId: number;
  postId: number;
  userId: number;
  catalogId: string;
  ownerType?: string; // report
  ownerId?: number;
  postsCount?: number;
}

export interface VoteComment {
  commentId: number;
  numLikes: number;
  catalogId: string;
}

export interface GetCommentLikersInfoRequest {
  catalogId: string;
  commentId: number;
  page: number;
}

export interface TranslateCommentRequest {
  catalogId: string;
  commentId: number;
  language: string;
}
