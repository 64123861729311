import axios from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toArray } from 'lodash';

import { Submission } from 'redux/schemas/models/submissions';
import { RootState } from 'redux/schemas';
import { PrivacySetting } from 'redux/schemas/models/exercise';
import { getActiveStudentsForCourse, getTeachingTeamMembers } from './courses';

export const setSubmission = createAction<Submission>('SET_SUBMISSION');

export const setSubmissions = createAction<Submission[]>('SET_SUBMISSIONS');

export const setSubmissionVoteCount = createAction<{ submissionId: number, numLikes: number }>('SET_SUBMISSION_VOTE_COUNT');

export const getCurrentUserSubmissions = createAsyncThunk(
  'GET_CURRENT_USER_SUBMISSIONS',
  async (arg, thunkAPI) => {
    const submissions: Submission[] = (await axios.get('/users/all_submissions.json')).data.result;

    thunkAPI.dispatch(setSubmissions(submissions));

    return submissions.map((submission) => submission.id);
  },
);

export const getCurrentUserActiveDrafts = createAsyncThunk(
  'GET_CURRENT_USER_ACTIVE_DRAFTS',
  async (arg, thunkAPI) => {
    const drafts: Submission[] = (await axios.get('/users/active_drafts.json')).data.result;

    thunkAPI.dispatch(setSubmissions(drafts));

    return drafts.map((draft) => draft.id);
  },
);

export const getSingleSubmission = createAsyncThunk<Submission, { catalogId: number, reportId: number }>(
  'GET_SINGLE_SUBMISSION',
  async (arg) => axios.get(
    `/${arg.catalogId}/reports/${arg.reportId}`,
  ).then((response) => response.data.result),
);

export const likeSubmission = createAsyncThunk<{ numLikes: number }, { catalogId: number, reportId: number }>(
  'LIKE_SUBMISSION',
  async (arg) => axios.get(
    `/${arg.catalogId}/reports/${arg.reportId}/vote_up.json`,
  ).then((response) => response.data.result),
);

export const unLikeSubmission = createAsyncThunk<{ numLikes: number }, { catalogId: number, reportId: number }>(
  'UNLIKE_SUBMISSION',
  async (arg) => axios.get(
    `/${arg.catalogId}/reports/${arg.reportId}/vote_down.json`,
  ).then((response) => response.data.result),
);

export const getMentionableUsers = createAsyncThunk<any, { catalogId: string, reportId: number }>(
  'GET_MENTIONABLE_USERS_FOR_SUBMISSION',
  async ({ catalogId, reportId }, thunkAPI) => {
    const submission = (thunkAPI.getState() as RootState).models.submissions[reportId];
    const exercise = (thunkAPI.getState() as RootState).models.exercises[submission.exercise.id] as any;
    const { currentUserId } = (thunkAPI.getState() as RootState).app;

    let mentionList = [];
    if (submission.privacySetting === PrivacySetting.SHARABLE_WITH_INSTRUCTOR
      || submission.privacySetting === PrivacySetting.SHARABLE_WITH_INSTRUCTOR_AND_TEAM) {
      if (submission.ownerType === 'Team') {
        mentionList = exercise?.collaborators ?? [];
      } else {
        mentionList = [submission.owner];
      }
      if (submission.privacySetting === PrivacySetting.SHARABLE_WITH_INSTRUCTOR_AND_TEAM) {
        await thunkAPI.dispatch(getActiveStudentsForCourse({ catalogId })).then((response) => {
          const studentList = response.payload;
          submission.mentionableUserIds.forEach((id) => studentList[id] && mentionList.push(studentList[id]));
        });
      } else {
        await thunkAPI.dispatch(getTeachingTeamMembers({ catalogId })).then((response) => {
          const teachingTeamMemberList = response.payload;
          teachingTeamMemberList?.forEach((member) => mentionList.push(member.user));
        });
      }
    } else {
      await thunkAPI.dispatch(getActiveStudentsForCourse({ catalogId })).then((response) => {
        const studentList = response.payload;
        mentionList.push(...toArray(studentList));
      });
    }
    return mentionList.filter((user) => user.id !== currentUserId);
  },
);
