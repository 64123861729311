import MeetAndGreetModal from 'lecture_pages/components/meet-and-greet/meet-and-greet-modal';

/* @ngInject */
export default function MeetAndGreetEditFormModalController(
  $scope,
  $uibModalInstance,
  vmResolves,
) {
  const vm = Object.assign(this, vmResolves);
  let dismissedByUser = false;

  vm.isCreate = !(vm.isEdit || vm.isLinked);

  const createMeetAndGreet = (meetAndGreet) => {
    vm.lectureComponent.meetAndGreet = meetAndGreet;
    $uibModalInstance.close();
  };

  const editBasics = (data) => {
    dismissedByUser = true;
    $uibModalInstance.close(data);
  };

  const closeModal = () => {
    dismissedByUser = true;
    $uibModalInstance.dismiss();
  };

  $scope.MeetAndGreetModalContent = () => (
    <MeetAndGreetModal
      onClose={closeModal}
      createMeetAndGreet={createMeetAndGreet}
      editBasics={editBasics}
      isEdit={vm.isEdit || vm.isLinked}
      initialAdditionalInformation={vm.initialAdditionalInformation}
      initialSortOrder={vm.initialSortOrder}
      initialPhotosRequired={vm.initialPhotosRequired}
    />
  );
}
