import React from 'react';

import { connect, useSelector } from 'react-redux';
import t from 'react-translate';

// schemas
import { RootState } from 'redux/schemas';
import { NTimelineLiveSession } from 'redux/schemas/models/live-session';
import { ActivityType } from 'redux/schemas/models/activity';
import { ComponentKeyPluralized } from 'redux/schemas/models/lecture-component';

// actions
import { setActivityExpandedInAutomatedCommunications } from 'redux/actions/course-communications';

// selectors
import { getLiveSession, hasLiveSessionGotCommunicationError } from 'redux/selectors/timeline';
import { isActivityExpandedInAutomatedCommunications } from 'redux/selectors/course-communications';

// components
import NvIcon from 'shared/components/nv-icon';
import NvStackedTab from 'shared/components/nv-stacked-tab';

import CommunicationCreateNewDropdown from 'communications/course_communications/components/communication-create-new-dropdown';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';

type LiveSessionLectureComponentProps = {
  liveSessionId: number
};

type StateProps = {
  liveSession: NTimelineLiveSession,
  hasCommunicationError: boolean,
};

const mapDispatchToProps = {
  setExpanded: setActivityExpandedInAutomatedCommunications,
};

const LiveSessionLectureComponent = (props: LiveSessionLectureComponentProps & StateProps & typeof mapDispatchToProps) => {
  const isExpanded = useSelector<RootState, boolean>((state) => isActivityExpandedInAutomatedCommunications(state, ComponentKeyPluralized.LIVE_SESSION, props.liveSessionId));

  return (
    <React.Fragment>
      <div className={`lecture-component-row ${isExpanded && props.liveSession?.communicationsCount > 0 && 'expanded-box'}`}>
        <NvIcon icon='zoom' size='smallest' />
        <div className='description'>{props.liveSession?.title}</div>
        <div className=''>
          <CommunicationCreateNewDropdown activityType={ActivityType.LIVE_SESSION} activityId={props.liveSessionId} />
        </div>
        { props.liveSession?.communicationsCount > 0 && (
          <NvStackedTab
            count={props.liveSession.communicationsCount}
            hasError={props.hasCommunicationError}
            isExpanded={isExpanded}
            errorMessage={t.COURSE_COMMUNICATIONS.WARNINGS.ACTIVITY_COMMUNICATION()}
            onClick={() => props.setExpanded({
              type: ComponentKeyPluralized.LIVE_SESSION,
              id: props.liveSessionId,
              isExpanded: !isExpanded,
            })}
          />
        )}
      </div>
      {
        // Communications
        isExpanded && props.liveSession?.communications && props.liveSession.communications.map(communicationId => (
          <CommunicationItem key={communicationId.toString()} communicationId={communicationId} />
        ))
      }
    </React.Fragment>
  );
};

const ConnectedLiveSessionLectureComponent = connect(
  (state: RootState, ownProps: LiveSessionLectureComponentProps) => ({
    liveSession: getLiveSession(state, ownProps.liveSessionId),
    hasCommunicationError: hasLiveSessionGotCommunicationError(state, ownProps.liveSessionId),
  }),
  mapDispatchToProps,
)(LiveSessionLectureComponent);

export default ConnectedLiveSessionLectureComponent;
