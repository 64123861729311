// - This service is used as a communication chancel for discussion updates performed by the current user in the same browser window
/* @ngInject */
export default function PubSubDiscussions(_) {
  const channels = {};
  let tokenTracker = -1;
  const pubSubManager = {
    publish(channelName, data) {
      const channel = channels[channelName];

      if (!channel) {
        return false;
      }

      for (let i = 0; i < channel.length; i += 1) {
        channel[i].callback(data);
      }

      return null;
    },
    subscribe(channelName, callback) {
      if (!channels[channelName]) {
        channels[channelName] = [];
      }

      tokenTracker += 1;
      const token = (tokenTracker).toString();
      channels[channelName].push({
        token,
        callback,
      });

      return token;
    },
    unsubscribe(token) {
      _.each(channels, (channel) => {
        _.each(channel, (subscriber, index) => {
          if (subscriber.token === token) {
            channel.splice(index, 1);
          }
        });
      });
    },
  };

  return pubSubManager;
}
