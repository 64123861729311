import _ from 'lodash';
import t from 'react-translate';
import { gray3 } from 'styles/global_defaults/colors';

// Redux
import { sortSkillTags } from 'redux/selectors/skills-feedback';

// Components
import Divider from 'shared/components/divider';
import { NvModalProps } from 'shared/components/nv-modal';
import NvSkillsView, { NvSkillViewTheme, getModalSettingsForSkillView } from 'shared/components/nv-skills-view';
import NvViewMore, { ViewMoreBehavior, ViewMoreCollapser } from 'shared/components/nv-view-more';

type SkillViewBodyProps = {
  skillTags: any;
};

const SkillViewBody = (props: SkillViewBodyProps) => {
  const { skillTags } = props;
  const sortedSkillTags = sortSkillTags(_.cloneDeep(skillTags));

  const modalSettings: NvModalProps = getModalSettingsForSkillView({
    header: t.INSTITUTIONS.SKILL_TAGS.SKILL_VIEW_FOR_LEARNERS.SKILLS_RELATED(),
    skillTags: sortedSkillTags,
  })

  return (
    <>
      <div className='w-100 mt-2 mb-2'>
        <Divider color={gray3} />
      </div>
      <NvViewMore
        type={ViewMoreBehavior.MODAL}
        text={t.INSTITUTIONS.SKILL_TAGS.SKILL_VIEW_FOR_LEARNERS.VIEW_ALL()}
        maxRows={3}
        collapser={ViewMoreCollapser.NORMAL}
        modalSettings={modalSettings}
      >
        <NvSkillsView skillTags={sortedSkillTags} theme={NvSkillViewTheme.DARK} />
      </NvViewMore>
    </>
  );
};

export default SkillViewBody;
