import { css } from '@emotion/react';
import ClickableContainer from 'components/clickable-container';
import { useContext } from 'react';
import { AngularServicesContext } from 'react-app';

import { CourseObjectBookmark, LecturePageBookmarkComponent } from 'redux/schemas/models/bookmark';
import { gray2 } from 'styles/global_defaults/colors';
import { boldFontWeight, headerLineHeight, textSmallLineHeight, textXSFontSize } from 'styles/global_defaults/fonts';
import { standardSpacing, doubleSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { config } from '../../../../config/pendo.config.json';

type LecturePageSnippetProps = {
  bookmark: CourseObjectBookmark
};

const LecturePageSnippet = ({
  bookmark: { catalogId, component },
}: LecturePageSnippetProps) => {
  const styles = css`
    &.lecture-page-snippet {
      display: flex;
      flex-direction: column;
      padding: ${standardSpacing}px ${doubleSpacing}px;

      .title {
        line-height: ${headerLineHeight}px;
        margin-bottom: ${halfSpacing}px;
      }

      .subtitle {
        white-space: nowrap;
        line-height: ${textSmallLineHeight}px;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: ${boldFontWeight};
        color: ${gray2};
        font-size: ${textXSFontSize}px;
      }
    }
  `;

  const { $state, FlyoutModalManager } = useContext(AngularServicesContext);

  const {
    id,
    title,
    subtitle,
    expired,
  } = component as LecturePageBookmarkComponent;

  const onClickSnippet = () => {
    if (expired) { return; }

    FlyoutModalManager?.closeFlyout();
    $state.go('lecture-page', { catalogId, id });
  };

  return (
    <ClickableContainer className='lecture-page-snippet' css={styles} onClick={onClickSnippet} data-qa={config.pendo.bookmarks.lecturePageSnippet}>
      <div className='title font-weight-bold'>{title}</div>
      <div className='subtitle'>{subtitle}</div>
    </ClickableContainer>
  );
};

export default LecturePageSnippet;
