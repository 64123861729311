import { createReducer } from '@reduxjs/toolkit';

import { getCurrentInstitution } from 'redux/selectors/institutions';
import { saveOrgLevelMetadataSettings } from 'redux/actions/org-level-metadata';
import { initialRootState } from './index';

export default createReducer(initialRootState, builder => {
  builder.addCase(saveOrgLevelMetadataSettings.fulfilled, (state, action) => {
    const currentInstitution = getCurrentInstitution(state);

    Object.assign(currentInstitution, action.payload);
  });
});
