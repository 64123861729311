import { css } from '@emotion/react';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import t from 'react-translate';
import NvTextArea from 'shared/components/inputs/nv-text-area';
import { Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { useRef, useEffect, useCallback, useState } from 'react';
import { standardSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import ImageComponentImage from './image-component-image';
import { ImageDisplayInputs } from './image-component-utils';

type AltTextModalProps = {
  show: boolean,
  onClose: () => void,
  altText: string,
  setAltText: (newText: string) => void,
  imgUrl: string,
  displayInputs: Omit<ImageDisplayInputs, 'calculatedImageWidth'>
};

export const AltTextModal = (props: AltTextModalProps) => (
  <NvModal
    show={props.show}
    onClose={props.onClose}
    body={<AltTextModalBody {...props} />}
    header={t.LECTURE_PAGES.COMPONENTS.IMAGE.ALT_TEXT_MODAL.HEADER()}
    type={ModalType.FULL}
    fullHeight={false}
  />
);

const AltTextModalBody = (props: AltTextModalProps) => {
  const styles = css`
    margin-left: ${140 - quarterSpacing}px;
    margin-right: ${140 - quarterSpacing}px;
    padding-top: ${standardSpacing}px;

    /* Strangely, this does not comform to any of the font styles listed at in the typography guide */
    .prompt-text {
      font-size: 16px;
    }

    textarea {
      resize: none;
    }

    .image-size-container {
      width: 100%;
      max-width: 800px;
      margin-top: ${standardSpacing * 3}px;
      margin-bottom: ${standardSpacing}px;
      margin-left: auto;
      margin-right: auto;

      align-items: center;
    }

    /* Adapted from image-lecture-component.tsx. This might need to be moved into
    image-component-image.tsx */
    .crop-container {
      align-self: center;
    }

    .image-children {
      align-items: center;
    }
  `;

  const inputName = 'placeholder-input';
  const inputRef = useRef(null);
  const methods = useForm({
    shouldUnregister: true,
    defaultValues: {
      [inputName]: props.altText,
    } });

  const altText = methods.watch(inputName);

  const onCancel = () => {
    props.onClose();
  };

  const onSubmit = (e) => {
    const value = e[inputName];
    props.setAltText(value);
    methods.setValue(inputName, value);
    props.onClose();
  };

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, [props.show]);

  // const [image, setImage] = useState(null);

  // const imageSizeContainer = useCallback(node => {
  //   if (node !== null) {
  //     imageSizeContainerRef.current = node;
  //     setImage(
  //       <ImageComponentImage
  //         altText={altText}
  //         imageUrl={props.imgUrl}
  //         displayInputs={{ ...props.displayInputs, imageAlignment: 'center' }}
  //         fitToContainer
  //         sizeContainerRef={imageSizeContainerRef}
  //         imageWidth='100%'
  //       />,
  //     );
  //   }
  // }, [altText, props.displayInputs, props.imgUrl]);

  const [containerRef, setContainerRef] = useState(null);

  const container = useCallback(node => {
    if (props.show) {
      setContainerRef(node);
    }
  }, [props.show]);

  if (!props.show) {
    return null;
  }

  return (
    <div css={styles}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className='prompt-text font-weight-bold mb-2'>{t.LECTURE_PAGES.COMPONENTS.IMAGE.ALT_TEXT_MODAL.PROMPT()}</div>
          <div className='text-small text-gray-3 mb-2'>{t.LECTURE_PAGES.COMPONENTS.IMAGE.ALT_TEXT_MODAL.DESCRIPTION()}</div>
          <NvTextArea
            name={inputName}
            placeholder={t.LECTURE_PAGES.COMPONENTS.IMAGE.ALT_TEXT_MODAL.PLACEHOLDER()}
            maxLength={255}
            ref={inputRef}
            required
            withForm
          />
          <div ref={container} className='image-size-container'>
            <ImageComponentImage
              altText={altText}
              imageUrl={props.imgUrl}
              displayInputs={{ ...props.displayInputs, imageAlignment: 'center' }}
              fitToContainer
              sizeContainerRef={{ current: containerRef }}
              imageWidth='100%'
            />
            {/* {image} */}
          </div>
          <div className='mx-auto d-table'>
            <Button className='mr-2 mt-6 mb-4' onClick={onCancel} variant='secondary'>{t.FORM.CANCEL()}</Button>
            <Button className='mt-6 mb-4' variant='primary' type='submit' disabled={!methods.formState.isDirty}>{t.FORM.SAVE()}</Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AltTextModal;
