/* @ngInject */
export default function GroupDirectorySortFilterController(
  GroupDirectoryManager,
  CurrentCourseManager,
) {
  const vm = this;

  vm.manager = GroupDirectoryManager;
  vm.currentCourseManager = CurrentCourseManager;
}
