
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

// Contexts
import { CommunicationFormContext, UseCommunicationMethods } from 'communications/course_communications/hooks/use-communication-form';

// Schemas
import { RootState } from 'redux/schemas';

// Selectors
import { getRolesTitles } from 'redux/selectors/course-communications';

// Components
import CommunicationItemPreview from 'communications/course_communications/components/communication-item/communication-item-preview';
import CommunicationEmailPreview from 'communications/course_communications/components/communication-item/communication-email-preview';
import EmailHeader from 'communications/course_communications/components/communication-modal/email-header';

const EmailPreview = () => {
  const { formData, getCommunicationDraft } = useContext<UseCommunicationMethods>(CommunicationFormContext);

  const communicationDraft = getCommunicationDraft();
  const rolesFilterTitles: any = useSelector((state: RootState) => getRolesTitles(state, communicationDraft?.filters?.courseRolesList));

  return (
    <React.Fragment>
      <CommunicationItemPreview
        communication={communicationDraft}
        rolesFilterTitles={rolesFilterTitles}
        showOwnerActivity
      />
      <div className='email mt-5 mb-4'>
        <EmailHeader communicationDraft={communicationDraft} />
        <CommunicationEmailPreview
          subject={formData.emailSubject}
          body={formData.emailBody}
          communicationType={formData.communicationType}
        />
      </div>
    </React.Fragment>
  );
};

export default EmailPreview;
