import { ReactNode } from 'react';
import { ExposedAngularServices } from 'react-app';
import { RootState } from 'redux/schemas';
import { Course } from 'redux/schemas/models/course';
import { FullCourse } from 'redux/schemas/models/courseFull';
import { ComponentTrueType, LectureComponent } from 'redux/schemas/models/lecture-component';
import { AppDispatch } from 'redux/store';
import { NvFilePickerProps } from 'shared/components/nv-filepicker';
import { DeepPartial } from 'utility-types';
import { ComponentMetadata } from '../data';
import { AddComponentCallback } from '../left-panel/create-new-component';
import { ModalWorkflowSettings } from './modal-workflow';

/** The files in this workflows/ folder represents the different possible UI experiences that occur after clicking a component to be added
 * in the lecture page left panel. They are responsible for showing any modal UIs, file select dialogs, etc, and also for calling the appropriate
 * callback function used to create the new component in Redux + render to the page.
 * These Workflows may also implement Angular-specific experiences to preserve the behavior used pre React architecture.
 *
 * Specific component types are configured to different workflows in app/lecture_pages/components/data/index.tsx.
 * */
export type ComponentCreationWorkflow<C extends ComponentTrueType> =
DefaultWorkflow<C> |
ModalWorkflow<C> |
AdminWorkflow<C> |
FileWorkflow<C> |
DirectlyUploadedFileWorkflow<C>;

/** Settings universal to all workflows */
type BaseWorkflow<C extends ComponentTrueType> = {
  // If specified, will be called after the API response from saving (adding or updating) a component is received but *before* any Admin 1.0 navigation
  // Adding afterCreate function that is in the lecture component model (Now it's only used in Team Discussion with Team Creation together).
  afterSave?: (dispatch: AppDispatch, mode: WorkflowMode, lectureComponent: DeepPartial<LectureComponent<C>>, extraDeps?: any) => void,
};

/** Whether a workflow is being presented to create a new component or edit an existing one */
export type WorkflowMode = 'new' | 'edit';

export type WorkflowSave = (lectureComponentData: DeepPartial<LectureComponent>, customComponentType: ComponentTrueType, lectureComponent?: Partial<LectureComponent>, metadata?: ComponentMetadata) => ReturnType<AddComponentCallback>;

export type WorkflowParams = {
  componentType: ComponentTrueType,
  metadata: ComponentMetadata,
  children: React.ReactNode,
  save?: WorkflowSave,
  saveNewComponent?: AddComponentCallback,
  mode: WorkflowMode,
  lectureComponent?: LectureComponent,
  /** Disables the workflow if true. This property only exists so that we don't have to wrap
   * <ConditionalWorkflow> in an additional <ConditionalWrap> */
  isDisabled?: boolean,
  // Determine if existing data should be used
  useExistingData?: boolean;
};

/** A function that constructs a URL for navigating into an Admin dashboard 1.0 page */
type AdminNavigationCreator<C extends ComponentTrueType> = (catalogId: string, lectureComponent: LectureComponent<C>) => string;

/** Used to create a new component with no additional interaction & is immediately saved to the backend */
export type DefaultWorkflow<C extends ComponentTrueType = any> = {
  type: 'default'
} & BaseWorkflow<C>;

/** Used to create a component only after interacting with a modal and then clicking a save button. Can be
 * cancelled. */
export type ModalWorkflow<C extends ComponentTrueType = any> = {
  type: 'modal',
  /** Renders the content of the modal. Currently only ever used to render 'children', but this will probably
   * change when we add modal content that is React */
  renderModal: (props: {
    /** The embedded Angularjs content if specified in initialSettings. Ignore & do not use this
     * if your component is React-only */
    children?: ReactNode,
    onCancel: () => void,
    onConfirm: (lectureComponent?: DeepPartial<LectureComponent<C>>) => void,
    forwardOnModalClose: (fn: (event: Event, closeModal: Function) => void) => void,
  }) => JSX.Element,
  /** The default context values for the modal UI. Can be overwritten at runtime */
  initialSettings: ModalWorkflowSettings,
  // onConfirm?: Pick<NvModalProps, ''
  /** If set, constructs a url that will be navigated to after the component is created. Intended to be an admin 1.0 page
   * url. See adminWorkflowNavigate() */
  adminNavigation?: AdminNavigationCreator<C>,
  /** If set and a string is returned, opens that string as a modal template path after the component has been saved.
   * TODO: This is a legacy config for showing a message after a student-formed group formation activity is created
   * after making an exercise component. See if we can remove in the near future */
  showCreateConfirmation?: (
    lectureComponent: LectureComponent<C>,
    currentCourse: FullCourse,
    mode: WorkflowMode,
  ) => string,
} & BaseWorkflow<C>;

/** Used to create a component immediately like in DefaultWorkflow, but then navigates the user to a specific
 * Admin 1.0 page for customization */
export type AdminWorkflow<C extends ComponentTrueType = any> = {
  type: 'admin-1.0',
  /** A function called after the new component has been saved which is used to generate the Admin 1.0 page
   * url to be navigated to */
  createUrl: AdminNavigationCreator<C>,
} & BaseWorkflow<C>;

/** Used to create a component only after choosing a file from the local file system. Set the
 * 'accept', 'onCancel', and 'onChange' props to define additional actions to occur when interacting
 * with the filepicker. */
export type FileWorkflow<C extends ComponentTrueType = any> = {
  type: 'file',
  // TODO: Currently not intended to be used; remove
  onChange?: (LectureComponent: DeepPartial<LectureComponent<C>>, ...rest: Parameters<NvFilePickerProps['onChange']>) => void,
  s3Namespace: string,
  // TODO: Max file size & S3 upload w/ S3 namespace
} & Pick<NvFilePickerProps, 'accept' | 'onCancel' | 'multiple' | 'maxSize' > & BaseWorkflow<C>;

/**
 * Used to create a component only after choosing a file from local file system.
 * Similar to "FileWorkflow" but this one is intended to be used for cases where
 * the file should be directly uploaded to NovoEd backend.
 */
export type DirectlyUploadedFileWorkflow<C extends ComponentTrueType = any> = {
  type: 'directlyUploadedFile',
  // Lecture component data path where file must be placed when uploading
  filePath: string,
  // If lecture component is an angular one, its corresponding component model
  // should implement "propagateReactUpload" method.
  isAngularLectureComponent: boolean,
} & Pick<NvFilePickerProps, 'accept' | 'onCancel' | 'maxSize' > & BaseWorkflow<C>;

/** Navigates to the url specified on a component creation workflow */
export const adminWorkflowNavigate = <C extends ComponentTrueType>(
  workflow: ComponentCreationWorkflow<C>,
  ...params: Parameters<AdminNavigationCreator<C>>
): void => {
  let navigate: AdminNavigationCreator<C> = null;

  if (workflow.type === 'admin-1.0') {
    navigate = workflow.createUrl;
  }

  if (workflow.type === 'modal' && workflow.adminNavigation) {
    navigate = workflow.adminNavigation;
  }

  if (navigate) {
    window.location.assign(navigate(params[0], params[1]));
  }
};
