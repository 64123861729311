/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { FormOrgLevelMetadataSettings } from 'redux/schemas/models/org-level-metadata-settings';

const savingCallOrgLevelMetadataSettings = payload => (
  axios.post(
    'metadata_fields.json',
    payload.form,
  ).then(response => ({ metadataFields: response.data.result })).catch((error) => {
    throw (error.response?.data?.error);
  })
);

export const saveOrgLevelMetadataSettings = createAsyncThunk(
  'SAVE_ORG_LEVEL_METADATA_SETTINGS',
  (payload: {
    institutionId: number,
    form: FormOrgLevelMetadataSettings,
  }) => savingCallOrgLevelMetadataSettings(payload),
);
