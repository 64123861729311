//        768                           1025                          1280
// '     '    '     '     '     '     '     '     '     '     '     '     '     '
// <-------^-----------------------------^------------------------------^-------------->
//    xs                  sm                             md                     lg
//  (phone)            (tablet)                       (laptop)               (desktop)

// Note: We don't support phone sizes that are smaller than 320px

import { css, SerializedStyles } from '@emotion/react';

// The chart above is left-end exclusive and right end inclusive. So, xs (phone) size is (0 pixels <-> 767) pixels,
// sm (tablet) size is (768 <-> 1024) pixels, etc
export const screenXsMax = 768 - 1;
export const screenSmMin = screenXsMax;
export const screenSmMax = 1025 - 1;
export const screenMdMin = screenSmMax;
export const screenMdMax = 1280 - 1;
export const screenLgMin = screenMdMin;
export const screen2KMax = 1441 - 1;
export const screen4KMin = screen2KMax;
export const screen4KMax = 2560 - 1;

export const handheld = (styles: SerializedStyles) => css`
  @media(max-width: ${screenXsMax}px) {
    ${styles};
  }
`;

export const notHandheld = (styles: SerializedStyles) => css`
  @media(min-width: ${screenSmMin}px) {
    ${styles};
  }
`;

export const mobile = (styles: SerializedStyles) => css`
  @media(max-width: ${screenSmMin}px) {
    ${styles};
  }
`;

export const notMobile = (styles: SerializedStyles) => css`
  @media(min-width: ${screenSmMin}px) {
    ${styles};
  }
`;

export const tablet = (styles: SerializedStyles) => css`
  @media(min-width: ${screenSmMin}px) and (max-width: ${screenSmMax}px) {
    ${styles};
  }
`;

export const desktop = (styles: SerializedStyles) => css`
  @media(min-width: ${screenLgMin}px) {
    ${styles};
  }
`;

export const allDesktop = (styles: SerializedStyles) => css`
  @media (min-width: ${screenMdMin}px) {
    ${styles};
  }
`;

export const smallDesktop = (styles: SerializedStyles) => css`
  @media (min-width: ${screenMdMin}px) and (max-width: ${screenMdMax}px) {
    ${styles};
  }
`;

export const notDesktop = (styles: SerializedStyles) => css`
  @media(max-width: ${screenSmMax}px) {
    ${styles};
  }
`;

export const largeDesktop = (styles: SerializedStyles) => css`
  @media (min-width: ${screen4KMin}px) {
    ${styles}
  }
`;

/* @ngInject */
export function isHandheld() {
  return document.body.clientWidth < screenSmMin;
}

/* @ngInject */
export function isSmallDesktop() {
  return document.body.clientWidth > screenMdMin
    && document.body.clientWidth < screenMdMax;
}

/* @ngInject */
export function isNotDesktop() {
  return document.body.clientWidth <= screenMdMin;
}

// https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript/4819886#4819886
/* @ngInject */
export function isTouchDevice() {
  try {
    document.createEvent('TouchEvent');
    return true;
  } catch (e) {
    return false;
  }
}

export const isRtl = () => document.dir === 'rtl';
