import { css } from '@emotion/react';

import { standardSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import { boldFontWeight } from 'styles/global_defaults/fonts';
import { success, yellow, primary, gray3 } from 'styles/global_defaults/colors';

import { Recipient } from 'redux/schemas/models/course-communication';
import NvTooltip from './nv-tooltip';

type NvRoleBadgeProps = {
  recipient: Recipient
  label?: string
};

const recipientConfig: {[key in Exclude<Recipient, Recipient.REGISTERED_USER>]: { background: string, initials: string }} = {
  [Recipient.TRIGGER_LEARNER]: { background: success, initials: 'L' },
  [Recipient.LEARNERS]: { background: success, initials: 'L' },
  [Recipient.MENTORS]: { background: yellow, initials: 'M' },
  [Recipient.COURSE_ADMINS]: { background: primary, initials: 'A' },
  [Recipient.TEAM_LEADS]: { background: gray3, initials: 'T' },
  [Recipient.GROUP_ADMINS]: { background: gray3, initials: 'G' },
};

const styles = (recipient: Recipient) => {
  const backgroundColor = recipientConfig[recipient]?.background;

  return css`
    .user-icon {
      display:flex;
      align-items:center;
      justify-content:center;
      width: ${standardSpacing}px;
      height: ${standardSpacing}px;
      border-radius: 50%;
      margin-left: -${quarterSpacing}px;
      font-weight: ${boldFontWeight};
      font-style: normal;
      background-color: ${backgroundColor};
    }
  `;
};
const NvRoleBadge = (props: NvRoleBadgeProps) => (
  <div css={styles(props.recipient)}>
    <NvTooltip text={props.label} enabled={!!props.label}>
      <div className='role-badge user-icon'>{recipientConfig[props.recipient]?.initials}</div>
    </NvTooltip>
  </div>
);
export default NvRoleBadge;
