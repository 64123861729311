import * as yup from 'yup';
import {
  ExternalSession,
  ZoomSession,
  MSTeamsSession,
  SessionTypes,
  LiveEventSession,
  SessionSettings,
  ZoomSettings,
} from './live-event';

// Shared Session Schema
const getSharedSessionSchema = (isContentManagementCollection, errorMessages) => ({
  index: yup.number().required(),
  title: yup.string().required(errorMessages.required),
  description: yup.string().optional().nullable(),
  startTime: yup.string().when([], {
    is: () => isContentManagementCollection,
    then: yup.string().optional().nullable(),
    otherwise: yup.string().required(errorMessages.required),
  }),
});

type GetSchemaParams = {
  sessionType: SessionTypes,
  isContentManagementCollection: boolean
  errorMessages: { [key: string]: string }
  isAccountLevel?: boolean
};

// External Session Schema
type GetExternalSchemaType = (GetSchemaParams) => yup.Schema<ExternalSession>;
const getExternalSchema: GetExternalSchemaType = ({
  isContentManagementCollection,
  errorMessages,
}) => (
  yup.object().shape({
    ...getSharedSessionSchema(isContentManagementCollection, errorMessages),
    joinUrl: yup.string().when([], {
      is: () => isContentManagementCollection,
      then: yup.string().optional(),
      otherwise: yup.string().url(errorMessages.url ?? errorMessages.required).required(errorMessages.required),
    }),
  }) as yup.Schema<ExternalSession>
);

// Zoom Session Schema
type GetZoomSchemaType = (GetSchemaParams) => yup.Schema<ZoomSession>;
const getZoomSchema: GetZoomSchemaType = ({
  isContentManagementCollection,
  errorMessages,
  isAccountLevel,
}) => (
  yup.object().shape({
    ...getSharedSessionSchema(isContentManagementCollection, errorMessages),
    alternativeHosts: yup.array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(', ') : [];
      })
      .of(yup.string().email(errorMessages.email)),
    recordMeetingAutomatically: yup.boolean().required(),
    automaticallyUploadRecording: yup.boolean().required(),
    participantVideo: yup.boolean().required(),
    muteUponEntry: yup.boolean().required(),
    enableEmailConfirmation: yup.boolean().required(),
    trackAttendance: yup.boolean().required(),
    authenticationEmail: yup.string().when([], {
      is: () => isAccountLevel && !isContentManagementCollection,
      then: yup.string().email(errorMessages.email).required(errorMessages.required),
      otherwise: yup.string().optional().email(),
    }),
  }) as yup.Schema<ZoomSession>
);

// MS Teams Session Schema
type GetMSTeamsSchemaType = (GetSchemaParams) => yup.Schema<MSTeamsSession>;
const getMSTeamsSchema: GetMSTeamsSchemaType = ({
  isContentManagementCollection,
  errorMessages,
}) => (
  yup.object().shape({
    ...getSharedSessionSchema(isContentManagementCollection, errorMessages),
  }) as yup.Schema<MSTeamsSession>
);

type GetSessionSchemaType = (GetSchemaParams) => yup.Schema<LiveEventSession>;
export const getSessionSchema: GetSessionSchemaType = (settings) => {
  switch (settings.sessionType) {
    case SessionTypes.EXTERNAL:
      return getExternalSchema(settings);
    case SessionTypes.ZOOM:
      return getZoomSchema(settings);
    case SessionTypes.MS_TEAMS:
      return getMSTeamsSchema(settings);
    default:
      return getExternalSchema(settings);
  }
};

// Live Event Settings
type GetSessionSettingsSchema = (sessionType: SessionTypes) => yup.Schema<SessionSettings>;
export const getSessionSettingsSchema: GetSessionSettingsSchema = (sessionType) => {
  switch (sessionType) {
    case SessionTypes.EXTERNAL:
      return yup.object().shape({}) as yup.Schema<{}>;
    case SessionTypes.ZOOM:
      return yup.object().shape({
        alternativeHosts: yup.string().optional(),
        recordMeetingAutomatically: yup.boolean().required(),
        automaticallyUploadRecording: yup.boolean().required(),
        participantVideo: yup.boolean().required(),
        muteUponEntry: yup.boolean().required(),
        enableEmailConfirmation: yup.boolean().required(),
        trackAttendance: yup.boolean().required(),
      }) as yup.Schema<ZoomSettings>;
    default:
      return yup.object().shape({}) as yup.Schema<{}>;
  }
};
