/* @ngInject */
export default function AnnouncementsPreviewCtrl(

  $scope,
  AnnouncementsManager,
  CurrentCourseManager,
  config,
) {
  const vm = this;

  vm.config = config;
  vm.AnnouncementsManager = AnnouncementsManager;
  vm.showAnnouncementsForCourse = showAnnouncementsForCourse;
  vm.closePopover = closePopover;
  vm.previewPopoverOpenCallback = previewPopoverOpenCallback;
  vm.CurrentCourseManager = CurrentCourseManager;

  function showAnnouncementsForCourse() {
    return !!AnnouncementsManager.currentCourse();
  }

  function closePopover() {
    $scope.$emit('closeLhsPopover');
  }

  function previewPopoverOpenCallback() {
    AnnouncementsManager.initialize();
  }
}
