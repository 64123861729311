// ideally we'd only modify the attribute right after interpolation occurs, but that will trigger an infinite cycle of the 2 directives modifying the attribute
// this solution should be good enough for us

/* @ngInject */
export default function TooltipPlacement(
  nvUtil,
) {
  return {
    restrict: 'A',
    compile: function compile(tElement, tAttrs, transclude) {
      if (nvUtil.isRtl()) {
        tAttrs.$set('tooltipPlacement', nvUtil.swapLeftRight(tAttrs.tooltipPlacement));
      }
    },
  };
}
