import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { CollectionCourse } from 'redux/schemas/models/collections';
import NvIcon from 'shared/components/nv-icon';
import { gray6 } from 'styles/global_defaults/colors';
import { useAppDispatch } from 'redux/store';
import { fetchCollection } from 'redux/actions/collections';
import { getCourse } from 'redux/selectors/course';
import { useSelector } from 'react-redux';
import LoadingPlaceholder, { LoaderType } from 'shared/components/loading-placeholder';
import ClickableContainer from 'components/clickable-container';
import CollectionRowFolder from './collection-row-folder';

export const useOnClickExpand = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);

    if (!isExpanded) {
      setIsLoading(true);
    }
  };

  return {
    isLoading, isExpanded, setIsLoading, toggleExpanded,
  };
};

export type CollectionRowProps = {
  catalogId: string
};

const styles = css`
  & {
    border-bottom: solid 1px ${gray6}
  }
`;

const CollectionRow = (props: CollectionRowProps) => {
  const { catalogId } = props;
  const collection: CollectionCourse = useSelector((state) => getCourse(state, catalogId));
  const { folders } = collection;

  const dispatch = useAppDispatch();
  const { isLoading, isExpanded, setIsLoading, toggleExpanded } = useOnClickExpand();

  useEffect(() => {
    if (isExpanded) {
      dispatch(fetchCollection(String(collection.id))).then(() => {
        setIsLoading(false);
      });
    }
  }, [collection.id, dispatch, isExpanded, setIsLoading]);

  return (
    <div
      className='d-flex flex-column pr-2 py-4'
      css={styles}
      key={catalogId}
    >
      <ClickableContainer
        className={`d-flex flex-column ${isExpanded ? 'pb-2' : ''}`}
        onClick={toggleExpanded}
      >
        <div className='d-flex align-items-center'>
          <NvIcon
            icon={`arrow-${isExpanded ? 'down' : 'right'}`}
            size='xss-smallest'
            className='text-gray-1 pr-4'
          />
          <div className='text-large-body font-weight-bolder ellipsis'>
            {collection.title}
          </div>
        </div>
        <div className='ml-5 text-body ellipsis'>
          <span className='text-gray-2 font-weight-normal'>
            {collection.description}
          </span>
        </div>
      </ClickableContainer>
      {isExpanded && (
        (isLoading && (!folders || folders === null)) ? (
          <LoadingPlaceholder loaderType={LoaderType.HALF} className='p-4' />
        ) : (
          folders?.length > 0 && folders.map((folderId) => (
            <CollectionRowFolder
              folderId={folderId}
              key={folderId}
              catalogId={catalogId}
            />
          ))))}
    </div>
  );
};

export default CollectionRow;
