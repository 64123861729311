/* @ngInject */
export default function ConversationsPreviewCtrl(

  $scope,
  ConversationsManager,
  CurrentUserManager,
  CurrentCourseManager,
  RailsRoutes,
  InteroperabilityRoutes,
  PusherManager,
  PusherChannels,
  moment,
  _,
  humps,
  config,
) {
  const vm = this;

  vm.config = config;
  vm.ConversationsManager = ConversationsManager;
  vm.conversationsCopy = angular.copy(ConversationsManager.conversations);
  vm.CurrentUserManager = CurrentUserManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.RailsRoutes = RailsRoutes;
  vm.InteroperabilityRoutes = InteroperabilityRoutes;
  vm.moment = moment;
  vm.numUnreadMessagesAbove = 0;
  vm.loadingMessagesAbove = false;
  vm.requestConversations = requestConversations;
  vm.loadMessagesAbove = loadMessagesAbove;
  vm.senderForConversationView = senderForConversationView;

  $scope.$on('$destroy', () => {
    PusherManager.currentUserChannel().unbind(null, newMessageOrConversationEventHandler);
    PusherManager.currentUserChannel().unbind('conversation_archive');
    PusherManager.currentUserChannel().unbind('conversation_read');
  });

  initialize();

  function initialize() {
    PusherManager.currentUserChannel().bind('new_conversation', newMessageOrConversationEventHandler);
    PusherManager.currentUserChannel().bind('new_message', newMessageOrConversationEventHandler);
    PusherManager.currentUserChannel().bind('conversation_archive', () => {
      reinitializeConversations();
    });
    PusherManager.currentUserChannel().bind('conversation_read', () => {
      reinitializeConversations();
    });
  }

  function requestConversations() {
    return ConversationsManager.requestConversations().then(() => {
      vm.conversationsCopy = angular.copy(ConversationsManager.conversations);
    });
  }

  function loadMessagesAbove() {
    vm.numUnreadMessagesAbove = 0;
    vm.loadingMessagesAbove = true;

    vm.requestConversations().then(() => {
      vm.loadingMessagesAbove = false;
    });
  }

  function senderForConversationView(conversation) {
    const latestMessageByOtherUser = _.find(conversation.messages.slice().reverse(), (message) => message.sender.id !== CurrentUserManager.user.id);

    if (latestMessageByOtherUser) {
      return latestMessageByOtherUser.sender;
    }
    const otherParticipant = _.find(conversation.participants.slice().reverse(), (participant) => participant.user.id !== CurrentUserManager.user.id);

    return otherParticipant ? otherParticipant.user : CurrentUserManager.user;
  }

  // Private Functions
  function newMessageOrConversationEventHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);

    if (CurrentUserManager.coursesHashByCatalogId[pusherData.catalogId]) {
      vm.numUnreadMessagesAbove += 1;
      ConversationsManager.numUnreadMessages += 1;
    }
  }

  function reinitializeConversations() {
    ConversationsManager.initialize().then(() => {
      vm.conversationsCopy = angular.copy(ConversationsManager.conversations);
    });
  }
}
