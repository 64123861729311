export default [
  function () {
    return {
      restrict: 'A',
      scope: {
        unreadCount: '=',
      },
      templateUrl: 'shared/templates/nv-unread-badge.html',
    };
  },
];
