import React from 'react';
import t from 'react-translate';
import _ from 'underscore';

// Form
import { useFieldArray, FieldArrayWithId, useFormContext } from 'react-hook-form';

// Styles
import { css } from '@emotion/react';
import { textExtraLargeFontSize, headerRegularLineHeight, boldFontWeight, textLargeLineHeight, lightFontWeight, normalFontWeight, textSmallFontSize, textSmallLineHeight } from 'styles/global_defaults/fonts';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { gray5, info, primary, warning } from 'styles/global_defaults/colors';

import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import { CourseInformationFormCollection, CourseInformationFormData as FormData, ReleaseDatesType } from './course-information-clone-modal';
import CourseCollection, { CourseClone } from './course-collection';

export enum CloneOptions {
  CLONE = 'clone',
  KEEP = 'keep',
  EXCLUDE = 'exclude',
}

type JourneyCollectionProps = {
  base: CourseInformationFormCollection;
  index: number;
  control: any,
  bulkDate?: string,
  collection: FieldArrayWithId<FormData, 'collections', 'key'>;
  releaseDates?: ReleaseDatesType,
};

export const JourneyCollection = (props: JourneyCollectionProps) => {
  const {
    base,
    index,
    control,
    bulkDate,
    collection,
    releaseDates,
  } = props;

  const [disabledFields, setDisabledFields] = React.useState([]);

  const { setValue } = useFormContext();

  const {
    fields: courses,
  } = useFieldArray<FormData, 'collections.0.courses', 'key'>({
    // @ts-ignore
    name: `collections.${index}.courses`,
    keyName: 'key',
    control,
  });

  const handleChange = (courseIndex: number, collectionIndex: number, option: CloneOptions) => {
    const newDisabledFields = _.clone(disabledFields);
    if (!newDisabledFields[collectionIndex]) newDisabledFields[collectionIndex] = [];
    newDisabledFields[collectionIndex][courseIndex] = option !== CloneOptions.CLONE;
    setDisabledFields(newDisabledFields);
  };

  const journeyCollectionStyles = css`
    .headers {
      display: grid;
      grid-template-columns: 43% 23% 22% 8%;
      gap: ${halfSpacing}px;
      margin: ${halfSpacing}px;
      .header {
        font-size: ${textSmallFontSize}px;
        line-height: ${textSmallLineHeight}px;
        font-weight: ${normalFontWeight};
      }
      .catalog-id-header {
        color: ${warning};
        .catalog-id-icon {
          color: ${primary};
        }
      }
    }
    .gray-box {
      padding: 0;
      background-color: ${info}80;
      border-top: 1px solid ${gray5};
    }
  `;

  React.useEffect(() => {
    setValue(`collections.${index}.name`, collection.name);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection.name]);

  return (
    <div css={journeyCollectionStyles}>
      <div className='course-title-regular mt-5 mb-4'>
        {t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.COURSE_INFORMATION.COLLECTION()} {index + 1}:
        <span className='card-title'>{collection.name}</span>
      </div>
      <div className='headers'>
        <span className='header'>
          {t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.COURSE_INFORMATION.COURSE_NAME()}
        </span>
        <span className='header d-flex catalog-id-header'>
          {t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.COURSE_INFORMATION.CATALOG_ID_REQUIRED()}
          <NvTooltip text={t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.COURSE_INFORMATION.CATALOG_ID_REQUIRED_TOOLTIP()}>
            <div className='catalog-id-icon ml-1 d-flex align-items-center' data-qa='catalog-id-tooltip'>
              <NvIcon icon='info' size='sm' />
            </div>
          </NvTooltip>
        </span>
        <span className='header'>
          {t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.COURSE_INFORMATION.START_DATE()}
        </span>
      </div>
      <div className='gray-box'>
        {courses.map((course, courseIndex) => (
          <CourseCollection
            key={course.key}
            startDate={bulkDate}
            collectionIndex={index}
            courseIndex={courseIndex}
            releaseDates={releaseDates}
            onOptionChange={handleChange}
            base={base.courses[courseIndex]}
            course={course as unknown as CourseClone}
          />
        ))}
      </div>
    </div>
  );
};

export default JourneyCollection;
