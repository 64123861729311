import { RootState } from 'redux/schemas';

import { OwnerType, ItemState, NotificationType, OwnerKey,
  CommunicationCategory, CommunicationType,
} from 'redux/schemas/models/course-communication';
import { LecturePageType, LecturePageKey } from 'redux/schemas/models/lecture-page';
import { ActivityType } from 'redux/schemas/models/activity';
import { UserRole } from 'redux/schemas/models/user';
import { ComponentKeyPluralized } from 'redux/schemas/models/lecture-component';
import { getActivityTypeForKey, getKeyForActivityType } from 'redux/selectors/activity';
import { FilteredCommunicationsState, ScheduledCommunicationState } from 'redux/schemas/app/course-communication';
import { getCategory } from 'communications/course_communications/contexts/communication-context';

export const getCommunication = (state: RootState, communicationId: number) => state.models.communications[communicationId];

export const getDraftCommunicationIds = (state: RootState) => (
  state.models.courses[state.app.currentCatalogId]?.draftCommunicationIds?.filter(
    id => state.models.communications?.[id]?.communicationType !== CommunicationType.COURSE_WELCOME_EMAIL,
  ) ?? []
);

export const getItemState = (state: RootState, communicationId: number) => {
  const communication = state.models.communications[communicationId];

  let itemState: ItemState;
  if (communication) {
    itemState = ItemState.NORMAL;

    if (!communication.submitted) {
      itemState = ItemState.DRAFT;
    }
    if (communication.firstTriggeredAt) {
      itemState = ItemState.ACTIVATED;
    }
    if (communication.hasErrors) {
      itemState = ItemState.ERROR;
    }
    if (communication.state?.toLowerCase() === ItemState.SENT) {
      itemState = ItemState.SENT;
    }
  }
  return itemState;
};

export const getNotificationTypes = (state: RootState, communicationId: number) => {
  const notificationTypes = [];
  if (getCategory(state.models.communications[communicationId].communicationType) === CommunicationCategory.SCHEDULED_ANNOUNCEMENT) {
    notificationTypes.push(NotificationType.ANNOUNCEMENT);
    if (state.models.communications[communicationId].communication?.hasEmail) {
      notificationTypes.push(NotificationType.MAIL);
    }
    notificationTypes.push(NotificationType.MOBILE);
  } else {
    notificationTypes.push(NotificationType.MAIL);
    if (state.models.communications[communicationId]?.enablePushNotification) {
      notificationTypes.push(NotificationType.MOBILE);
    }
  }
  return notificationTypes;
};

// Step 1 only need Learner roles
export const getRecipientRoles = (state: RootState, communicationId?: number) => [UserRole.LEARNER];

export const getOwnerActivity = (state: RootState, communicationId: number) => getActivity(
  state,
  state.models.communications[communicationId].ownerType,
  state.models.communications[communicationId].ownerId,
);

export const getRolesTitles = (state: RootState, roleIds: number[] = []) => roleIds.map(roleId => (roleId !== 0 ? state.models.roles[roleId]?.name : 'NO_ROLE'));

export const getRolesFilterTitles = (state: RootState, communicationId: number) => {
  if (state.models.communications[communicationId]?.filters
    && state.models.communications[communicationId]?.filters.courseRolesList) {
    return getRolesTitles(state, state.models.communications[communicationId]?.filters.courseRolesList.map(Number));
  }
  return [];
};

export const getHasCompletedFilterActivity = (state: RootState, communicationId: number) => {
  if (state.models.communications[communicationId]?.filters
    && state.models.communications[communicationId]?.filters.hasCompleted) {
    const { type, id } = state.models.communications[communicationId].filters.hasCompleted;
    return getActivity(state, type, id);
  }
  return null;
};

export const getHasNotCompletedFilterActivity = (state: RootState, communicationId: number) => {
  if (state.models.communications[communicationId]?.filters
    && state.models.communications[communicationId]?.filters.hasNotCompleted) {
    const { type, id } = state.models.communications[communicationId].filters.hasNotCompleted;
    return getActivity(state, type, id);
  }
  return null;
};

export const getHasCompletedEventActivity = (state: RootState, communicationId: number) => {
  if (state.models.communications[communicationId]?.event
    && state.models.communications[communicationId]?.event.hasCompleted) {
    const { type, id } = state.models.communications[communicationId].event.hasCompleted;
    return getActivity(state, type, id);
  }
  return null;
};

export const getActivity = (state: RootState, type: OwnerType, id: number) => {
  if (!type || !id) {
    return null;
  }

  switch (type) {
    case ActivityType.EXERCISE_SKILLS_RATING:
      return state.models.exerciseSkillsRatingActivities[id] || state.models.videoPracticeSkillsRating[id];
    case ActivityType.EXERCISE:
      return state.models.exercises[id];
    case ActivityType.EXTERNAL_TOOL:
      return state.models.externalTools[id];
    case ActivityType.GROUP_FORMATION:
      return state.models.groupTeamSets[id];
    case ActivityType.LECTURE_VIDEO:
      return state.models.lectureVideos[id];
    case ActivityType.LIVE_SESSION:
      return state.models.liveSessions[id];
    case ActivityType.PEER_EVALUATION:
      return state.models.peerEvaluations[id];
    case ActivityType.POLL:
      return state.models.polls[id];
    case ActivityType.POST:
      return state.models.posts[id];
    case ActivityType.PROFILE_REQUIREMENT:
      return state.models.profileRequirements[id];
    case ActivityType.PROGRESSIVE_QUIZ:
      return state.models.progressiveQuizzes[id];
    case ActivityType.QUIZ:
      return state.models.quizzes[id];
    case ActivityType.SURVEY:
      return state.models.surveys[id];
    case ActivityType.TEAM_DISCUSSION:
      return state.models.teamDiscussions[id];
    case ActivityType.TEAM_FORMATION:
      return state.models.teamSets[id];
    case ActivityType.TIMED_EXAM:
      return state.models.timedQuizzes[id];
    case ActivityType.VIDEO_PRACTICE_FEEDBACK:
      return state.models.practiceFeedbackActivities[id];
    case ActivityType.VIDEO_PRACTICE_SKILLS_FEEDBACK:
      return state.models.videoPracticeSkillsRating[id];
    case ActivityType.VIDEO_PRACTICE:
      return state.models.practiceActivities[id];
    case LecturePageType.LECTURE:
      return state.models.lecturePages[id];
    default:
      return null;
  }
};

export const getOwnerTypeForKey = (key: OwnerKey): OwnerType => (
  key === LecturePageKey.LECTURE
    ? LecturePageType.LECTURE
    : getActivityTypeForKey(key)
);

export const getKeyForOwnerType = (type: OwnerType): OwnerKey => (
  type === LecturePageType.LECTURE
    ? LecturePageKey.LECTURE
    : getKeyForActivityType(type)
);

export const isTimelineLoadedInAutomatedCommunications = (state: RootState) => state.app.courseCommunications[state.app.currentCatalogId].automatedCommunications.isTimelineLoaded;

export const isDraftCommunicationsLoaded = (state: RootState) => state.app.courseCommunications[state.app.currentCatalogId].draftCommunications.isLoaded;

export const isScheduledCommunicationsLoaded = (state: RootState) => state.app.courseCommunications[state.app.currentCatalogId].scheduledCommunications.isLoaded;

export const isActivityExpandedInAutomatedCommunications = (state: RootState, type: ComponentKeyPluralized | LecturePageKey, id: number) => state.app.courseCommunications[state.app.currentCatalogId].automatedCommunications.expandedActivities[type]?.[id];

export const getLastCreatedCourseCommunicationId = (state: RootState) => state.app.courseCommunications[state.app.currentCatalogId].automatedCommunications.lastCreatedCommunicationId;

export const isLecturePageExpandedInAutomatedCommunications = (state: RootState, id: number) => state.app.courseCommunications[state.app.currentCatalogId].automatedCommunications.expandedLecturePages[id]?.isExpanded;

export const isLecturePageShowCommunicationsInAutomatedCommunications = (state: RootState, id: number) => state.app.courseCommunications[state.app.currentCatalogId].automatedCommunications.expandedLecturePages[id]?.showCommunications;

export const getScheduledCommunications = (state: RootState): ScheduledCommunicationState => state.app.courseCommunications?.[state.app.currentCatalogId]?.scheduledCommunications;

export const getFilteredCommunicationsState = (state: RootState, catalogId: string): FilteredCommunicationsState => state.app.courseCommunications[catalogId]?.filteredCommunications;

export const getFilteredCommunicationIds = (state: RootState) => state.app.courseCommunications?.[state.app.currentCatalogId]?.filteredCommunicationIds ?? [];

export const getCourseWelcomeEmail = (state: RootState, catalogId: string) => state.app.courseCommunications[catalogId]?.automatedCommunications.welcomeEmail;

export const getCurrentCourseWelcomeEmail = (state: RootState) => state.app.courseCommunications?.[state.app.currentCatalogId]?.automatedCommunications.welcomeEmail;
