import React, { useState } from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';
// color sizes and fonts
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { gray5, gray6, gray7 } from 'styles/global_defaults/colors';
// components
import NvTooltip from 'shared/components/nv-tooltip';
import ClickableContainer from 'components/clickable-container';
import { copyToClipboard } from 'shared/react-utils';

type SkillItemModalProps ={
  name: string,
};
const styles = css`
  &.skill-item{
    height:${standardSpacing}px;
    border-radius: ${standardSpacing}px;
    background-color: ${gray7};
    border:1px solid ${gray5};

    &.inline-item{
      display:inline-flex;
      min-width:0;
      max-width:100%;
    }
    &:hover{
      background-color:${gray6};
    }
  }
`;
const SkillItemModal = (props: SkillItemModalProps) => {
  const [copied, setCopied] = useState(false);

  return (
    <NvTooltip
      text={copied
        ? t.INSTITUTIONS.SKILL_TAGS.COPIED()
        : t.INSTITUTIONS.SKILL_TAGS.COPY_CLIPBOARD()}
    >
      <ClickableContainer
        css={styles}
        className='skill-item inline-item align-items-center px-3 py-2}'
        onClick={() => { copyToClipboard(props.name); setCopied(true); }}
        onMouseEnter={() => setCopied(false)}
      >
        <div className='skill-label ellipsis'>
          {props.name}
        </div>
      </ClickableContainer>
    </NvTooltip>
  );
};

export default SkillItemModal;
