/* eslint-disable import/prefer-default-export */
const alphabet = 'abcdefghijklmnopqrstuvwxyz';

// This function returns you an alphabet character depending on the index you
// provide, if you pass 0, you will get 'A', if you pass 25 you will get 'Z', if
// you pass a higher value, there will be added an alphabet prefix, for example,
// if you pass 26, you will get 'AA' if you pass 27 you will get 'AB' if you
// pass 28 you will get 'AC' and so on.
export const getAlphabetCharacter = (index) => {
  let lapse = Math.trunc(index / alphabet.length);
  if (!lapse) {
    lapse = null;
  } else {
    lapse -= 1;
  }

  const reminder = index % alphabet.length;

  const prefixIndex = lapse;
  const suffixIndex = reminder;

  return `${prefixIndex === null
    ? '' : alphabet[prefixIndex]}${alphabet[suffixIndex]}`.toUpperCase();
};

export const removeDecimalForInteger = (value: string) => {
  if (value?.slice(-2) === '.0') {
    return value.slice(0, -2);
  }
  return value;
};
