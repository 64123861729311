import { ReactElement } from 'react';
import t from 'react-translate';
import { Button } from 'react-bootstrap';
import { ViewMoreCollapser } from './nv-view-more';
import { config } from '../../../config/pendo.config.json';

export const getCollapser = ({
  type,
  onClick,
  text,
  viewMore,
  customViewMore,
  dataQa,
}) => {
  const collapser = {
    [ViewMoreCollapser.NORMAL]: (
      <NormalCollapser
        onClick={onClick}
        dataQa={config.pendo.viewMore.viewMore}
        text={text}
      />
    ),
    [ViewMoreCollapser.DYNAMIC]: (
      <NormalCollapser
        onClick={onClick}
        dataQa={viewMore ? config.pendo.viewMore.viewMore : config.pendo.viewMore.viewLess}
        text={viewMore ? t.SHARED.VIEW_MORE() : t.SHARED.VIEW_LESS()}
      />
    ),
    [ViewMoreCollapser.CUSTOM]: (
      <CustomCollapser
        onClick={onClick}
        viewMoreElement={customViewMore}
        dataQa={dataQa}
        viewMore={viewMore}
      />
    ),
  };
  return collapser[type];
}

type NormalCollapserProps = {
  onClick: () => void
  dataQa: string
  text: string
};

const NormalCollapser = ({ onClick, text, dataQa }: NormalCollapserProps) => (
  <Button
    variant='link'
    size='sm'
    onClick={onClick}
    data-qa={dataQa}
  >
    {text}
  </Button>
);

type CustomCollapserProps = {
  onClick: () => void
  viewMoreElement?: (viewMore: boolean) => ReactElement
  dataQa?: string
  viewMore: boolean
};

const CustomCollapser = ({
  onClick,
  viewMoreElement,
  dataQa = '',
  viewMore,
}: CustomCollapserProps) => (
  <div
    onClick={onClick}
    data-qa={dataQa}
    className='custom-collapser'
  >
    {viewMoreElement ? viewMoreElement(viewMore) : null}
  </div>
);
