import React, { Fragment, useState } from 'react';
import { css } from '@emotion/react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { confirmSkillTagsCSV, validateSkillTagsCSV } from 'redux/actions/skill-tags';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { Institution } from 'redux/schemas/models/courseFull';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import NvFilePicker from 'shared/components/nv-filepicker';
import NvIcon from 'shared/components/nv-icon';
import useUploadFile from 'shared/hooks/use-upload-file';
import { S3NameSpaces } from 'shared/services/s3-upload-factory';
import { gray3, gray5, primary, white } from 'styles/global_defaults/colors';
import { iconMedSize } from 'styles/global_defaults/icons';
import { standardSpacing } from 'styles/global_defaults/scaffolding';


type BulkUploadSkillsModalProps = {
  onClose: () => void;
};

const BulkUploadSkillsModal = ({
  onClose,
}: BulkUploadSkillsModalProps) => {
  const styles = css`
    .step-icon {
      margin-top: 25px;
      height: ${iconMedSize}px;
      width: ${iconMedSize}px;
      border-radius: 12px;

      padding-top: 2px;
      text-align: center;
      color: ${white};
      background-color: ${primary};
    }

    .hr-divider {
      width: 100%;
      height: 1px;
      background-color: ${gray3};
      margin: ${standardSpacing}px;
    }

    .skill-tag-error {
      border-bottom: 1px solid ${gray5};
    }

    .error-row {
      width: 90px;
    }
  `;

  const dispatch = useAppDispatch();
  const { uploadFiles } = useUploadFile();
  const institution: Institution = useSelector(state => getCurrentInstitution(state));

  const [page, setPage] = useState(1);
  const [fileName, setFileName] = useState('');
  const [skillsAddedCount, setSkillsAddedCount] = useState(0);
  const [skillsUpdatedCount, setSkillsUpdatedCount] = useState(0);
  const [skillsDeletedCount, setSkillsDeletedCount] = useState(0);
  const [skillErrors, setSkillErrors] = useState([]);
  const [deletedTags, setDeletedTags] = useState([]);

  const onCSVSelected = async (data) => {
    const [novoEdFile] = await uploadFiles(data, `${S3NameSpaces.SKILL_TAGS_CSV}${institution.id}`);

    setFileName(`${novoEdFile.uniqueId}-${novoEdFile.name}`);

    dispatch(validateSkillTagsCSV({
      institutionId: institution.id,
      fileName: `${novoEdFile.uniqueId}-${novoEdFile.name}`,
    })).then(({ payload }) => {
      setPage(2);
      setSkillsAddedCount(payload.counters.tagAddCounter);
      setSkillsUpdatedCount(payload.counters.tagUpdateCounter);
      setSkillsDeletedCount(payload.counters.tagDeleteCounter);
      setSkillErrors(payload.errors.slice(0, 10));
      setDeletedTags(payload.deletedTags);
    }).catch(() => {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.OOPS(),
        message: t.FORM.ERROR_SOMETHING_WRONG(),
      }));
    });
  };

  const onFileError = () => {
    setPage(1);
    setSkillsAddedCount(0);
    setSkillsUpdatedCount(0);
    setSkillsDeletedCount(0);
    setSkillErrors([]);

    dispatch(addAlertMessage({
      type: AlertMessageType.ERROR,
      header: t.FORM.OOPS(),
      message: t.FILE_UPLOAD.NOT_SUPPORTED_ALERT(),
    }));
  };

  const confirmOnClick = () => {
    if (deletedTags && deletedTags.length) {
      const warningStyles = css`
        .w-fit-content {
          width: fit-content;
        }

        .text-align-start {
          text-align: start;
        }
      `;

      const bodyText: JSX.Element = (
        <div css={warningStyles} className='text-large-body'>
          <div className='mb-6'>{t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.DELETE_CONFIRMATION_DESCRIPTION()}</div>
          <div>{t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.SKILLS_TO_BE_DELETED()}</div>
          <ul className='m-auto w-fit-content'>
            {deletedTags.map(({ name }) => (
              <li className='font-weight-bolder text-align-start'>{name}</li>
            ))}
          </ul>
        </div>
      );

      dispatch(openConfirmationDialog({
        title: t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.DELETE_CONFIRMATION_TITLE(),
        bodyText,
        confirmText: t.FORM.YES_SURE(),
        onConfirm,
      }));
    } else {
      onConfirm();
    }
  };

  const onConfirm = () => {
    dispatch(confirmSkillTagsCSV({
      institutionId: institution.id,
      fileName,
    })).then(() => {
      dispatch(addAlertMessage({
        type: AlertMessageType.INFO,
        header: t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.PROCESSING_UPLOADED_CSV_FILE(),
        message: t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.WE_WILL_SEND_EMAIL(),
      }));
    }).catch(() => {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.AN_ERROR_OCCURRED_IN_THE_PROCESS(),
        message: t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.THE_DETAILS_HAVE_BEEN_SENT_VIA_EMAIL(),
      }));
    });

    onClose();
  };

  return (
    <div css={styles} className='d-flex flex-column align-items-center'>
      {page === 1 ? (
        <Fragment>
          <div className='step-icon'>1</div>
          <div className='mt-2 page-title'>{t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.STEP_ONE()}</div>
          <div className='my-4'>{t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.DOWNLOAD_DESCRIPTION()}</div>
          <Button
            href={`institutions/${institution.id}/skill_tags/download`}
            target='_blank'
            variant='secondary'
            className='m-4'
          >
            {t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.DOWNLOAD()}
          </Button>
          <div className='hr-divider' />
          <div className='step-icon'>2</div>
          <div className='mt-2 page-title'>{t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.STEP_TWO()}</div>
          <div className='w-100 my-4'>{t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.PLEASE_SELECT_CSV()}</div>
          <NvFilePicker
            multiple={false}
            accept={['.csv', 'text/csv']}
            onChange={(e) => onCSVSelected(e)}
            onSelectError={onFileError}
          >
            <Button
              variant='primary'
              className='m-4'
            >
              {t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.UPLOAD_CSV()}
            </Button>
          </NvFilePicker>
        </Fragment>
      ) : (
        <Fragment>
          {(skillsAddedCount || skillsUpdatedCount || skillsDeletedCount) ? (
            <div className='w-100 my-4'>{t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.FOUND_IN_CSV()}</div>
          ) : (
            <div className='w-100 my-4'>{t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.NO_CHANGES_IN_CSV()}</div>
          )}
          {!!skillsAddedCount && (
            <Fragment>
              <div className='d-flex w-100'>
                <NvIcon icon='skills-feedback' size='small' className='text-primary mr-1' />
                <div className='font-weight-bolder'>{t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.NEW_SKILLS_FOUND()}</div>
              </div>
              <div className='d-flex w-100 mt-2 mb-4'>{t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.NUM_NEW_SKILLS(skillsAddedCount)}</div>
            </Fragment>
          )}
          {!!skillsUpdatedCount && (
            <Fragment>
              <div className='d-flex w-100'>
                <NvIcon icon='skills-feedback' size='small' className='text-primary mr-1' />
                <div className='font-weight-bolder'>{t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.SKILLS_TO_UPDATE()}</div>
              </div>
              <div className='d-flex w-100 my-2'>{t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.NUM_SKILLS_UPDATED(skillsUpdatedCount)}</div>
            </Fragment>
          )}
          {!!skillsDeletedCount && (
            <Fragment>
              <div className='d-flex w-100'>
                <NvIcon icon='skills-feedback' size='small' className='text-primary mr-1' />
                <div className='font-weight-bolder'>{t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.SKILLS_TO_DELETE()}</div>
              </div>
              <div className='d-flex w-100 my-2'>{t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.NUM_SKILLS_DELETED(skillsDeletedCount)}</div>
              <div className='text-small text-gray-2 mb-4'>{t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.DELETE_MESSAGE()}</div>
            </Fragment>
          )}
          {!!skillErrors.length && (
            <Fragment>
              <div className='d-flex w-100'>
                <NvIcon icon='warning' size='small' className='text-danger mr-1' />
                <div className='font-weight-bolder'>{t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.SKILLS_WITH_ERRORS()}</div>
              </div>
              <div className='w-100 text-small text-gray-2 mt-2'>{t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.ERROR_MESSAGE()}</div>
              {skillErrors.map((err) => (
                <div className='skill-tag-error d-flex w-100 py-2'>
                  <div className='error-row'>{t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.ROW()} {err.row}</div>
                  <div className='text-danger'>{err.message}</div>
                </div>
              ))}
            </Fragment>
          )}
          <div className='d-flex mt-7'>
            <NvFilePicker
              multiple={false}
              accept={['.csv', 'text/csv']}
              onChange={(e) => onCSVSelected(e)}
              onSelectError={onFileError}
            >
              <Button
                variant='secondary'
                className='mr-2'
              >
                {t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.UPLOAD_NEW_CSV()}
              </Button>
            </NvFilePicker>
            <Button
              variant='primary'
              onClick={() => confirmOnClick()}
            >
              {t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.CONFIRM()}
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default BulkUploadSkillsModal;
