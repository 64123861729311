/* @ngInject */
export default function CustomDownloadsResources(

  $resource,
) {
  return $resource(
    '',
    {},
    {
      getCustomReports: { method: 'GET', url: '/:catalogId/course_custom_data_report.json' },
      getCount: { method: 'POST', url: '/:catalogId/course_custom_data_role_report/report_record_count.json' },

      // Create new
      getNewReport: { method: 'GET', url: '/:catalogId/:userRole/course_custom_data_role_report/new.json' },
      createCustomReport: { method: 'POST', url: '/:catalogId/:userRole/course_custom_data_role_report.json' },
      generateReport: { method: 'POST', url: '/:catalogId/course_custom_data_role_report/generate_report.json' },

      // Existing reports
      getExistingReport: { method: 'GET', url: '/:catalogId/course_custom_data_role_report/:id.json' },
      updateCustomReport: { method: 'PUT', url: '/:catalogId/course_custom_data_role_report/:id.json' },
      duplicateReport: { method: 'POST', url: '/:catalogId/course_custom_data_role_report/duplicate.json' },
      deleteReport: { method: 'POST', url: '/:catalogId/course_custom_data_role_report/delete.json' },

      // List of downloads
      getDownloadsList: { method: 'GET', url: '/:catalogId/course_custom_data_report/:id.json' },

      // Content downloads
      getNewContentDownload: { method: 'GET', url: '/:catalogId/course_custom_content_data_report/new.json' },
      createContentDownload: { method: 'POST', url: '/:catalogId/course_custom_content_data_report.json' },
      generateContentReport: { method: 'POST', url: '/:catalogId/course_custom_content_data_report/generate_content_report.json' },
      deleteContentReport: { method: 'POST', url: '/:catalogId/course_custom_content_data_report/delete_content.json' },
    },
  );
}
