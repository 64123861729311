import { css } from '@emotion/react';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import t from 'react-translate';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

// Redux
import { useAppDispatch } from 'redux/store';
import { createCollection, editCollection } from 'redux/actions/collections';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { getCourse } from 'redux/selectors/course';

// Components
import NvTextInput from 'shared/components/inputs/nv-text-input';
import { isEmpty } from 'underscore';
import NvTextArea from 'shared/components/inputs/nv-text-area';
import { config } from '../../../config/config.json';

type CollectionFormProps = {
  closeModal: (catalogId?: string, isUpdate?: boolean, reloadList?: boolean) => void
  catalogId?: string
};

interface FormData {
  title: string,
  description: string,
}

const CollectionForm = (props: CollectionFormProps) => {
  const styles = css`
    .description-text {
      min-height: 80px;
    }
  `;

  const [saving, setSaving] = useState<boolean>(false);

  const collection = useSelector((state) => getCourse(state, props.catalogId));
  const dispatch = useAppDispatch();

  const validationSchema = yup.object().shape({
    title: yup.string().trim()
      .required(t.VALIDATION.REQUIRED())
      .max(250, t.VALIDATION.MAX('250')),
    description: yup.string().max(255, t.VALIDATION.MAX('255')),
  });

  const methods = useForm<FormData>({
    mode: 'onChange',
    shouldUnregister: true,
    defaultValues: {
      title: collection?.title ?? '',
      description: collection?.description ?? '',
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, formState: { isValid, isDirty } } = methods;

  const onSubmit = (data: FormData) => {
    setSaving(true);
    if (props.catalogId) {
      const isTitleChanged = collection.title !== data.title;

      dispatch(editCollection({
        title: data.title,
        description: data.description,
        collectionId: collection.id,
      })).then((response) => {
        setSaving(false);

        if (isEmpty(response?.error)) {
          dispatch(addAlertMessage({
            type: AlertMessageType.SUCCESS,
            message: t.INSTITUTIONS.CONTENT_LIBRARY.NEW_COLLECTION_FORM.EDIT_SUCCESS_ALERT(),
          }));

          props.closeModal(response.payload.catalogId, true, isTitleChanged);
        }
      });
    } else {
      dispatch(createCollection({
        title: data.title,
        description: data.description,
      })).then((response) => {
        setSaving(false);

        if (isEmpty(response?.error)) {
          dispatch(addAlertMessage({
            type: AlertMessageType.SUCCESS,
            message: t.INSTITUTIONS.CONTENT_LIBRARY.NEW_COLLECTION_FORM.SUCCESS_ALERT(),
          }));

          props.closeModal(response.payload.catalogId, false, true);
        }
      });
    }
  };

  const getSubmitButtonText = () => {
    let key = saving ? 'CREATING' : 'CREATE';

    if (props.catalogId) {
      key = saving ? 'UPDATING' : 'UPDATE';
    }

    return t.FORM[key]();
  };

  return (
    <div className='d-flex flex-column pt-5 px-6 bg-white' css={styles}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <NvTextInput
            withForm
            name='title'
            ariaLabel={t.INSTITUTIONS.CONTENT_LIBRARY.NEW_COLLECTION_FORM.TITLE_ARIA_LABEL()}
            className='my-2'
            placeholder={t.INSTITUTIONS.CONTENT_LIBRARY.NEW_COLLECTION_FORM.UNTITLED_COLLECTION()}
            required
          />
          <NvTextArea
            name='description'
            placeholder={t.INSTITUTIONS.CONTENT_LIBRARY.NEW_COLLECTION_FORM.ADD_DESCRIPTION()}
            withForm
            className='mt-3 mb-2 border-right'
            textareaClassName='description-text'
          />
          <div className='d-flex justify-content-center mt-7'>
            <Button
              className='mr-2'
              variant='secondary'
              onClick={() => {
                props?.closeModal(null, !!props.catalogId);
              }}
              data-qa={config.pendo.contentManagement.closeAddNewCollectionModal}
            >
              {t.FORM.CANCEL()}
            </Button>
            <Button
              disabled={!isValid || !isDirty || saving}
              type='submit'
              data-qa={props.catalogId ? config.pendo.contentManagement.editCollection : config.pendo.contentManagement.createNewCollection}
            >
              {getSubmitButtonText()}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CollectionForm;
