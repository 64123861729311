import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getBoxAccessToken = createAsyncThunk(
  'GET_BOX_ACCESS_TOKEN',
  async (params: { boxDocumentId: number }, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    return axios.get(
      `/embeddings/box_access_token/${params.boxDocumentId}`,
      { cancelToken: source.token },
    ).then(response => response.data.result);
  },
);

export default getBoxAccessToken;
