export default {
  bindings: {
    // Should be 'true' if the user viewing this directive is a mentor, and false otherwise
    isMentorView: '<?',
  },
  controller: function ctrl(
    _,
    $state,
    $stateParams,
    $translate,
    nvCurrentPage,
    CurrentUserManager,
    CurrentCourseManager,
  ) {
'ngInject';
    this.$stateParams = $stateParams;
    this.CurrentUserManager = CurrentUserManager;
    this.nvCurrentPage = nvCurrentPage;
    this.CurrentCourseManager = CurrentCourseManager; // used for mentee alias
    this.isExpanded = false;

    this.onClick = () => {
      if (CurrentCourseManager.course.userCourse.mentors.length === 1) {
        $state.go('learner-profile-modal', {
          catalogId: $stateParams.catalogId,
          userId: CurrentCourseManager.course.userCourse.mentors[0].id,
        });
      } else {
        this.isExpanded = !this.isExpanded;
      }
    };

    this.getHeaderText = () => {
      if (this.isMentorView) {
        return $translate.instant('MENTEES.SEE_MY_MENTORS', {
          mentors: CurrentCourseManager.course.userCourse.roles.menteeName.pluralizedTitleized,
        });
      }
      let rolesCount = 0;
      const mentorRoleNames = {};

      // Construct a header string listing the names of the different kinds of mentors for this
      // user. Makes a string like "Mentor1 and Mentor2" if less than two unique kind of mentor
      // roles. Otherwise we use another translation string like "See My Mentor Relationships"
      for (let i = 0; i < CurrentCourseManager.course.userCourse.mentors.length; i += 1) {
        const mentor = CurrentCourseManager.course.userCourse.mentors[i];

        // Default this mentor's name to 'Mentor' or 'Mentors' in the event that data migration
        // put us in a weird state where the name doesn't exist.
        let name = {
          singularizedTitleized: $translate.instant('INSTITUTIONS.ROLES.MENTOR'),
          capitalizedPluralized: $translate.instant('INSTITUTIONS.ROLES.MENTORS'),
        };

        name.pluralizedTitleized = name.capitalizedPluralized;

        if (mentor.roles.roles.name) {
          ({ name } = mentor.roles.roles);
        }

        if (Object.prototype.hasOwnProperty.call(mentorRoleNames, name.capitalizedPluralized)) {
          mentorRoleNames[name.capitalizedPluralized] = name.pluralizedTitleized;
        } else {
          mentorRoleNames[name.capitalizedPluralized] = name.singularizedTitleized;
          rolesCount += 1;
        }
      }

      const names = _.values(mentorRoleNames);

      switch (rolesCount) {
        case 1:
          return $translate.instant('MENTEES.SEE_MY_MENTORS', {
            mentors: names[0],
          });
        case 2:
          return $translate.instant('MENTEES.SEE_MY_MENTORS_AND', {
            mentorsOne: names[0],
            mentorsTwo: names[1],
          });
        case 3:
          return $translate.instant('MENTEES.SEE_MY_MENTORS_COMMA_AND', {
            mentorsOne: names[0],
            mentorsTwo: names[1],
            mentorsThree: names[2],
          });
        default:
          return $translate.instant('MENTEES.SEE_MY_MENTORS_AND_OTHERS', {
            mentorsOne: names[0],
            mentorsTwo: names[1],
            othersCount: names.length - 2,
          });
      }
    };

    this.getMentors = () => CurrentCourseManager.course.userCourse.mentors;

    this.getMentorCount = () => this.getMentors().length;
  },
  controllerAs: 'vm',
  templateUrl: 'shared/templates/nv-mentees-button.html',
};
