/* @ngInject */
export default function LectureVideoListResources(

  $resource,
) {
  return $resource(
    '/:catalogId/video_list_lecture_components/:id',
    {},
    {
      query: { method: 'GET', isArray: false },
      getLibraryVideos: { method: 'GET', url: '/:catalogId/videos.json' },
    },
  );
}
