import { css } from '@emotion/react';
import { gray5, primary } from 'styles/global_defaults/colors';
import React, { useState, CSSProperties, useEffect, useCallback, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import t from 'react-translate';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { Button } from 'react-bootstrap';
import { quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import _ from 'underscore';
import useWindowResize from 'shared/hooks/use-window-resize';
import { ImageCropping, reactCropToImageCropping, correctCropForRotation, imageCroppingToReactCrop, rotatedCropToUnrotated, ImageRotation } from './image-component-utils';

/** Calls correctCropForRotation() on a ReactCrop.Crop object, transforming it into an ImageCropping */
const correctReactCropForRotation = (reactCrop: ReactCrop.Crop, imageWidth: number, imageHeight: number, rotation: number, isSideways): ReactCrop.Crop => {
  let imageCropping = reactCropToImageCropping(reactCrop);

  // const [iWidth, iHeight] = isSideways ? [imageHeight, imageWidth] : [imageWidth, imageHeight];
  imageCropping = correctCropForRotation(imageCropping, imageWidth, imageHeight, rotation);

  return imageCroppingToReactCrop(imageCropping);
};

/** Gets the crop coords needed to show the cropping UI */
const scaleCropToDisplay = (realCrop: ReactCrop.Crop, rect: DOMRect, imageBaseWidth: number, imageBaseHeight: number, rotation: ImageRotation, isSideways: boolean): ReactCrop.Crop => {
  const correctedCrop = correctReactCropForRotation(realCrop, imageBaseWidth, imageBaseHeight, rotation, isSideways);
  const displayWidth = isSideways ? imageBaseHeight : imageBaseWidth;
  const scalingFactor = displayWidth / rect.width;

  return {
    x: correctedCrop.x / scalingFactor,
    y: correctedCrop.y / scalingFactor,
    width: correctedCrop.width / scalingFactor,
    height: correctedCrop.height / scalingFactor,
  };
};

const CroppingModal = (props: {
  show: boolean,
  cropping: ImageCropping,
  setCropping: (cropSetting: ImageCropping) => void,
  onClose: () => void,
  imgUrl: string,
  rotation: ImageRotation,
  imageBaseWidth: number,
  imageBaseHeight: number,
}) => {
  // TODO: Copied from the component, generalize
  let rotationOffset = '';
  let transformOrigin = 'center';
  if (props.rotation === -270) {
    rotationOffset = 'translateY(-100%)';
    transformOrigin = 'left bottom';
  } else if (props.rotation === -90) {
    rotationOffset = 'translateX(-100%)';
    transformOrigin = 'top right';
  }

  const styles = css`
    margin-left: ${standardSpacing * 3}px;
    margin-right: ${standardSpacing * 3}px;

    .crop-container {
      width: 100%;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .ReactCrop {
      /** Makes the crop selector circles not be visually clipped when at the edges of the image */
      overflow: visible;
      margin: 0;
    }

    /* This div that wraps the img also contains the crop ui, and by default it is constrained to the height of the
    unrotated image, which prevents dragging the crop to the bottom of a rotated image */
    .ReactCrop > div {
      height: 100%;
    }

    /** Make the div containing the cancel/submit buttons appear visually on top of the light gray masking region used
    by the crop reticle UI */
    .ReactCrop ~ div {
      z-index: 1;
    }

    .ReactCrop__rule-of-thirds-hz {
      &::before, &::after {
        background-color: ${gray5};
      }
    }

    .ReactCrop__crop-selection {
      box-shadow: 0 0 0 9999em rgba(255, 255, 255, 0.33);
      border: 1px solid white;
    }

    .ReactCrop__drag-handle::after {
      width: 12px;
      height: 12px;
      border: 1px solid;
      border-color: ${primary};
      background-color: white;
      border-radius: 6px;
    }

    .ReactCrop__drag-bar {
      border-image-source: unset;
      border-color: white;
    }

    .ord-n, .ord-e, .ord-s, .ord-w {
      display: none;
    }

    img {
      transform: rotate(${props.rotation}deg);
      transform: ${rotationOffset} rotate(${props.rotation}deg);
      transform-origin: ${transformOrigin};
      /* max-width: 800px; */
      max-width: none;
    }
  `;

  const defaultCrop = {
    x: 0,
    y: 0,
    width: props.imageBaseWidth,
    height: props.imageBaseHeight,
  };

  const defaultImageCrop = reactCropToImageCropping(defaultCrop);

  const isSideways = props.rotation === -90 || props.rotation === -270;

  const [crop, setCrop] = useState<ReactCrop.Crop>(props.cropping ? {
    x: props.cropping.left,
    y: props.cropping.top,
    width: props.cropping.width,
    height: props.cropping.height,
  } : defaultCrop);

  /** We use different crop coords for displaying the crop UI because they need
   * to account for the image's rotation and real screen width & height */
  const [displayCrop, setDisplayCrop] = useState<ReactCrop.Crop>(null);

  const [reactCropStyle, setReactCropStyle] = useState<CSSProperties>({
    width: '100%',
    maxWidth: '100%',
  });
  const [imageStyle, setImageStyle] = useState<CSSProperties>({});

  const onReset = () => {
    updateDisplayCrop(
      scaleCropToDisplay(defaultCrop, cropContainerRef?.current.getBoundingClientRect(), props.imageBaseWidth, props.imageBaseHeight, props.rotation, isSideways),
    );
  };

  const onSubmit = () => {
    /** The amount to scale the crop coords by due to image render resizing */
    const displayWidth = cropContainerRef?.current.getBoundingClientRect().width;
    const scalingFactor = (isSideways ? props.imageBaseHeight : props.imageBaseWidth) / displayWidth;

    props.setCropping({
      left: crop.x * scalingFactor,
      top: crop.y * scalingFactor,
      width: crop.width * scalingFactor,
      height: crop.height * scalingFactor,
    });
    props.onClose();
  };

  const cropContainerRef = useRef(null);

  /** Updates the crop state after adjusting the new crop value for the current rotation and image scaling */
  const updateDisplayCrop = (newCrop: ReactCrop.Crop): void => {
    if (!cropContainerRef?.current) {
      return;
    }

    const displayRect = cropContainerRef?.current.getBoundingClientRect();

    /** The value of newCrop is given to us by react-crop, and for a rotated image
     * that means it's left/top coords & width/height dimensions are relative to
     * the screen pixels, not the image's (0, 0) origin at the top left. This converts
     * that value into unrotated image coordinate space. */
    const unrotatedCrop = imageCroppingToReactCrop(rotatedCropToUnrotated(reactCropToImageCropping(newCrop), displayRect.width, displayRect.height, props.rotation));

    setCrop(unrotatedCrop);
    // Note that we don't call getDisplayCrop() here, as `newCrop` is already rotated
    setDisplayCrop(newCrop);
  };

  // const [cropContainerRect, setCropContainerRect] = useState<DOMRect>(null);

  const cropContainerRefCb = useCallback((node: HTMLDivElement) => {
    // Setting the image style causes this to go into an infinite loop unless we
    // short circuit here
    if (!props.show || cropContainerRef?.current === node) {
      return;
    }

    // Calculate based on the parent element here since the ReactCrop element may still be sized from the previous time this modal was opened,
    // which causes an offset bug if the image was rotated differently in the previous render
    const rect = node.parentElement.getBoundingClientRect();

    recalculateCropRender(
      rect,
      defaultImageCrop,
      props.cropping,
      props.rotation,
      props.imageBaseWidth,
      props.imageBaseHeight,
      isSideways,
      setImageStyle,
      setReactCropStyle,
      setDisplayCrop,
    );
    cropContainerRef.current = node;
  }, [defaultImageCrop, isSideways, props.cropping, props.imageBaseHeight, props.imageBaseWidth, props.rotation, props.show]);

  useWindowResize(() => {
    if (!props.show) {
      return;
    }
    recalculateCropRender(
      cropContainerRef.current.getBoundingClientRect(),
      defaultImageCrop,
      props.cropping,
      props.rotation,
      props.imageBaseWidth,
      props.imageBaseHeight,
      isSideways,
      setImageStyle,
      setReactCropStyle,
      setDisplayCrop,
    );
  }, 10, false, [defaultImageCrop, props.show, props.imageBaseWidth, props.imageBaseHeight, cropContainerRef, isSideways, props.cropping, props.rotation]);

  const body = (
    <div css={styles}>
      <div className='crop-container'>
        <ReactCrop
          src={props.imgUrl}
          crop={displayCrop}
          onChange={c => updateDisplayCrop(c)}
          className='mt-4 mb-4'
          ruleOfThirds
          style={reactCropStyle}
          imageStyle={imageStyle}
          ref={(ref: any) => ref?.componentRef && cropContainerRefCb(ref.componentRef)}
        />
        <div className='mx-auto d-table'>
          <Button className='mr-2 mt-6 mb-4' onClick={() => onReset()} variant='secondary'>{t.LECTURE_PAGES.COMPONENTS.IMAGE.CROP_MODAL.RESET()}</Button>
          <Button className='mt-6 mb-4' variant='primary' onClick={() => onSubmit()}>{t.FORM.SAVE()}</Button>
        </div>
      </div>
    </div>
  );

  return (
    <NvModal
      {...props}
      header={t.LECTURE_PAGES.COMPONENTS.IMAGE.CROP_MODAL.HEADER()}
      body={body}
      type={ModalType.FULL}
      fullHeight={false}
    />
  );
};

type DisplayDimensions = { width: number, height: number };

const getImageDisplayDimensions = (
  containerRect: DOMRect,
  imageBaseWidth: number,
  imageBaseHeight: number,
  isSideways: boolean,
): DisplayDimensions => {
  const dims: DisplayDimensions = { width: 0, height: 0 };

  if (!containerRect) {
    return dims;
  }

  if (!isSideways) {
    const displayWidth = Math.min(containerRect.width, imageBaseWidth) ?? 0;
    dims.width = displayWidth;
    dims.height = imageBaseHeight * (displayWidth / imageBaseWidth);
  } else {
    const displayWidth = Math.min(containerRect.width, imageBaseHeight) ?? 0;
    dims.height = displayWidth;
    dims.width = imageBaseWidth * (displayWidth / imageBaseHeight);
  }

  return dims;
};

const getReactCropDisplaySize = (isSideways: boolean, cropContainerRect: DOMRect, imageBaseWidth: number, imageBaseHeight: number, displayDims: DisplayDimensions): CSSProperties => {
  let width = '';

  if (isSideways) {
    width = imageBaseHeight <= cropContainerRect.height ? `${imageBaseHeight}px` : '100%';
  } else {
    width = imageBaseWidth <= cropContainerRect.width ? `${imageBaseWidth}px` : '100%';
  }

  const height = `${isSideways ? displayDims.width : displayDims.height}px`;

  return {
    width,
    height,
  };
};

const recalculateCropRender = (
  rect: DOMRect,
  defaultImageCrop: ImageCropping,
  cropping: ImageCropping,
  rotation: ImageRotation,
  imageBaseWidth: number,
  imageBaseHeight: number,
  isSideways: boolean,
  setImageStyle: (style: CSSProperties) => void,
  setReactCropStyle: React.Dispatch<React.SetStateAction<React.CSSProperties>>,
  setDisplayCrop: (newCrop: ReactCrop.Crop) => void,
) => {
  const displayDims = getImageDisplayDimensions(
    rect,
    imageBaseWidth,
    imageBaseHeight,
    isSideways,
  );

  setImageStyle({
    ...displayDims,
  });

  const newRenderStyles = getReactCropDisplaySize(isSideways, rect, imageBaseWidth, imageBaseHeight, displayDims);
  setReactCropStyle((cropStyle) => ({
    ...cropStyle,
    ...newRenderStyles,
  }));

  // We want to scale the crop relative the display dimensions and not the container element due to this function being called
  // when initial layout & rendering is occurring. This means the container rect may not yet be rendered at the correct size as it will
  // be after the useEffect() calling this function is complete.
  const sizingRect = isSideways ? {
    width: displayDims.height,
    height: displayDims.width,
  } : {
    width: displayDims.width,
    height: displayDims.height,
  };

  const newDisplayCrop = scaleCropToDisplay(imageCroppingToReactCrop(cropping ?? defaultImageCrop), sizingRect as DOMRect, imageBaseWidth, imageBaseHeight, rotation, isSideways);
  setDisplayCrop(newDisplayCrop);
};


export default CroppingModal;
