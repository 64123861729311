import { css } from '@emotion/react';
import { useContext, useEffect } from 'react';
import t from 'react-translate';
import { useSelector } from 'react-redux';

// Contexts
import { CommunicationFormContext, FormSubmittedState, UseCommunicationMethods } from 'communications/course_communications/hooks/use-communication-form';
import { CommunicationAction, CommunicationDispatch } from 'communications/course_communications/contexts/communication-context';

// Selectors
import { getRolesTitles } from 'redux/selectors/course-communications';
import { getCourse, getCurrentCourse } from 'redux/selectors/course';

// Styles
import { gray4, primary } from 'styles/global_defaults/colors';
import { halfSpacing } from 'styles/global_defaults/scaffolding';

// Components
import Button from 'react-bootstrap/Button';
import SamplePushNotification from 'communications/course_communications/components/communication-modal/sample_push_notification/sample-push-notification';
import CommunicationEmailPreview from 'communications/course_communications/components/communication-item/communication-email-preview';
import CommunicationItemPreview from 'communications/course_communications/components/communication-item/communication-item-preview';
import EmailHeader from 'communications/course_communications/components/communication-modal/email-header';
import SaveDraft from 'communications/course_communications/components/communication-modal/save_draft/save-draft';
import SubmitButton from 'communications/course_communications/components/communication-modal/submit-button';
import ExtraTermsInfo from 'communications/course_communications/components/communication-modal/extra-terms-info';

const styles = css`
  .modal-body-title {
    border-bottom: 1px solid ${gray4};
    text-align: center;
  }

  .notification {

    .notification-header {
      border-top: 2px solid black;
      border-bottom: 1px solid ${gray4};
      display: flex;
      align-items: center;
    }
  }

  .actions {
    .buttons {
      display: flex;
      margin-top: 60px;
      justify-content: center;
    }

    .save-as-draft {
      color: ${primary};
      display: block;
      margin-top: ${halfSpacing}px;
      text-align: center
    }
  }
`;

const StepThree = () => {
  const { dispatch } = useContext(CommunicationDispatch);
  const { formData, prevStep, getCommunicationDraft,
    saveAsDraft, isDirty, draftSubmittedAt,
    formSubmitState,
  } = useContext<UseCommunicationMethods>(CommunicationFormContext);

  const currentCourse = useSelector((state) => getCurrentCourse(state));

  useEffect(() => {
    if (formSubmitState === FormSubmittedState.SUBMITTED) {
      dispatch({ type: CommunicationAction.CLOSE_MODAL });
    }
  }, [formSubmitState, dispatch]);

  const communicationDraft = getCommunicationDraft();

  const rolesFilterTitles: any = useSelector((state) => getRolesTitles(state, communicationDraft?.filters?.courseRolesList));


  return (
    <div css={styles}>
      <div className='modal-body-title page-title pb-4 mt-2 mb-5'>
        {t.COURSE_COMMUNICATIONS.TRIGGERS.TITLE.STEP_3()}
      </div>
      <CommunicationItemPreview
        communication={communicationDraft}
        rolesFilterTitles={rolesFilterTitles}
        showOwnerActivity
      />
      <div className='email mt-5 mb-4'>
        <EmailHeader communicationDraft={communicationDraft} />
        <CommunicationEmailPreview
          subject={formData.emailSubject}
          body={formData.emailBody}
          communicationType={formData.communicationType}
        />
      </div>
      {formData.includePushNotification && (
        <div className='notification mt-5 mb-4'>
          <div className='notification-header py-4 px-0'>
            <div className='page-title-small font-weight-bolder'>{t.COURSE_COMMUNICATIONS.PUSH_NOTIFICATION.TITLE()}</div>
          </div>
          <SamplePushNotification notification={{ title: currentCourse.name, bodyText: formData.pushNotificationText }} />
          <div className='text-small text-gray-2 mt-1'>{t.COURSE_COMMUNICATIONS.PUSH_NOTIFICATION.POSTSCRIPT()}</div>
        </div>
      )}
      <ExtraTermsInfo />
      <div className='actions pb-7'>
        <div className='buttons'>
          <Button onClick={prevStep} className='go-back mr-2' variant='secondary'>{t.FORM.BACK()}</Button>
          <SubmitButton isValid />
        </div>
        <SaveDraft
          onClick={saveAsDraft}
          changesMade={isDirty}
          draftSaved={draftSubmittedAt}
          isDraft={communicationDraft?.submitted === false}
        />
      </div>
    </div>
  );
};

export default StepThree;
