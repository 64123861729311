import { css } from '@emotion/react';
import { primary } from 'styles/global_defaults/colors';
import { largeSpacing } from 'styles/global_defaults/scaffolding';
import { ReactComponent as CheckedBox } from 'styles/icons/checked-box.svg';

type StatusIndicatorProps = {
  variant: 'lg';
  isVisible: boolean;
};

// The first phase of this component renders an indicator when an item is selected
// If necessary, we can add more size-based variants and an icon prop to represent other indicators here, such as SelectedRadio or UncheckedBox, as implementented in the QuizQuestionOption component
export const StatusIndicator = ({
  variant,
  isVisible,
}: StatusIndicatorProps) => {
  const defaultStyles = css`
    color: ${primary};
    opacity: ${!isVisible ? '0' : '1'};
    transition: opacity 0.3s ease-in-out;
  `;
  let styles = css``;

  switch (variant) {
    case 'lg':
      styles = css`
        ${defaultStyles};
        min-width: ${largeSpacing}px;
        min-height: ${largeSpacing}px;
      `;
      break;

    default:
      styles = css`
        ${defaultStyles};
        min-width: ${largeSpacing}px;
        min-height: ${largeSpacing}px;
      `;
      break;
  }

  return <CheckedBox css={styles} />;
};

export default StatusIndicator;
