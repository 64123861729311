/* @ngInject */
export default function DataDownloadsListController(
  $stateParams,
  CurrentCourseManager,
  CustomDownloadsModel,
  PusherManager,
  _,
  downloadInfo,
  humps,
) {
  const vm = this;

  vm.downloadInfo = downloadInfo;
  vm.$stateParams = $stateParams;
  vm.CurrentCourseManager = CurrentCourseManager;

  vm.generatingReport = vm.downloadInfo.reportStatus === 1 && !vm.downloadInfo.isDuplicatePlaceholder;

  getDownloadsList();

  vm.defaultMin = 4;
  vm.shownDownloads = { num: vm.defaultMin };

  PusherManager.courseChannel(vm.CurrentCourseManager.course.id).bind('custom_report_generated', reportGenerationHandler);
  PusherManager.courseChannel(vm.CurrentCourseManager.course.id).bind('custom_report_failed', reportGenerationHandler);

  function reportGenerationHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);

    if (pusherData.courseId === vm.CurrentCourseManager.course.id && pusherData.reportId === vm.downloadInfo.reportId) {
      getDownloadsList();
      vm.generatingReport = false;
    }
  }

  vm.generateReport = function () {
    CustomDownloadsModel.generateReport(vm.$stateParams.catalogId, vm.downloadInfo.reportId).then(() => {
      vm.generatingReport = true;
    });
  };

  function getDownloadsList() {
    return CustomDownloadsModel.getDownloadsList(vm.$stateParams.catalogId, vm.downloadInfo.reportId).then((data) => {
      if (vm.data) {
        // Newly generated report
        vm.data.downloadDetails.splice(0, 0, data.downloadDetails[0]);
        vm.defaultMin += 1;
        vm.shownDownloads.num += 1;
      } else {
        vm.data = data;
      }
    });
  }
}
