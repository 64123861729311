/* @ngInject */
export default function NvScrollFocusConnector(
  $timeout,
  _,
  $uibPosition,
) {
  const connector = {
    ownerMap: {},

    findEntry(ownerId, ownerType) {
      return this.ownerMap[ownerId] && this.ownerMap[ownerId][ownerType];
    },

    register(elem, scrollContainer, ownerId, ownerType) {
      let ownerIdMap;

      if (!this.ownerMap[ownerId]) {
        this.ownerMap[ownerId] = {};
      }

      this.ownerMap[ownerId][ownerType] = { ownerType, elem, scrollContainer: (scrollContainer || angular.element($uibPosition.scrollParent(elem))) };
    },

    deregister(ownerId, ownerType) {
      if (this.findEntry(ownerId, ownerType)) {
        delete this.ownerMap[ownerId][ownerType];
      }
    },

    scrollTo(ownerId, ownerType, options) {
      const entry = this.findEntry(ownerId, ownerType);

      if (entry) {
        $timeout(() => {
          entry.scrollContainer.scrollToElementAnimated(entry.elem, options ? options.offset : null, 400);
        }, 1);
      } else if (options) {
        // not using the registered values, just scroll directly using passed in elements in options
        const elem = options.elem?.length ? options.elem.get(0) : options.elem;
        $timeout(() => {
          (options.container || angular.element($uibPosition.scrollParent(elem))).scrollToElementAnimated(elem, options.offset || null, 400);
        }, 1);
      }
    },

    deregisterFocusHandler(ownerId, ownerType) {
      const entry = this.findEntry(ownerId, ownerType);

      if (entry) {
        entry.elem.blur();
        entry.elem = null;
      }
    },

    focus(ownerId, ownerType, options, focusAtEnd) {
      const _this = this;

      $timeout(() => {
        const entry = _this.findEntry(ownerId, ownerType);
        let focusElem = null;

        if (entry) {
          focusElem = entry.elem;
        } else if (options) {
          focusElem = options.elem;
        }

        if (focusElem) {
          if (focusElem.is('[nv-froala-editor]') && focusElem.data() && focusElem.data().focus) {
            focusElem.data().focus(focusAtEnd);
          } else {
            focusElem.focus();
          }
        }
      }, 1);
    },

  };

  return connector;
}
