import _ from 'underscore';
import { denormalize } from 'normalizr';
import { createSelector, Selector, ParametricSelector } from 'reselect';
import merge from 'lodash/merge';
import {
  CourseActivities,
  CourseActivitiesEntities,
  CourseActivitiesNormalizedResult,
  CourseActivitiesSchema,
} from 'redux/schemas/api/course-communications';
import { CombinedCourse, RootState } from 'redux/schemas';
import {
  CourseAliases, LearnerAliases, AssignmentAliases,
  OfferingAliases, TeachingTeamAliases, GroupAliases,
  PointAliases, ProgramCourseAliases, TeamAliases, LectureAliases,
} from 'redux/schemas/models/course';
import { Name } from 'redux/schemas/models/courseFull';
import { ActivityKey } from 'redux/schemas/models/activity';
import { RolesService } from 'institutions/services/roles-service';
import { ComponentTrueType, ComponentType } from 'redux/schemas/models/lecture-component';
import { compareHexColors } from 'styles/global_defaults/colors';
import { getUserById, getUserEnrollments } from './users';

/** Components that require at least one exercise to exist on the course in order to be added */
export const EXERCISE_DEPENDENT_COMPONENTS: ComponentTrueType[] = [
  ComponentType.PRIVATE_PEER_EVALUATION,
  ComponentType.PUBLIC_PEER_EVALUATION,
  ComponentType.SUBMISSION_DISCOVERY,
  ComponentType.EXERCISE_SKILLS_RATING,
];

export const getCourseTotalPoints = (state: RootState) => state.models.courses[state.app.currentCatalogId].totalPoints;

export const isGamificationEnabled = (state: RootState) => state.models.courses[state.app.currentCatalogId].gamificationEnabled;

export const getCourseUserIds = (state: RootState) => state.models.courses[state.app.currentCatalogId]?.courseUserIds;

/** TODO: This is a rename of a function previously called `getCurrentCourse`, which has been replaced by the
 * getCurrentCourse below. Many/most of the current uses of this actually pass in the catalog Id for the current
 * course. Let's see if we can replace uses of this with uses of getCurrentCourse */
export const getCourse = (state: RootState, catalogId: string) => state.models.courses[catalogId];

export const getCurrentCourse: Selector<RootState, CombinedCourse> = (state: RootState) => state.models.courses[state.app.currentCatalogId];

export const getCoursesArray = (state: RootState) => Object.values(state.models.courses);

export const getCurrentCourseId = (state: RootState) => state.app.currentCourseId;

export const selectCourse = createSelector(
  (state: RootState, catalogId: number) => catalogId,
  (state) => state.models.courses,
  (catalogId, courses) => courses[catalogId],
);

/*
  transform aliases from redux store
  EX:
    pointsName: {
      capitalizedPluralized: 'Points',
      capitalizedSingularized: 'Point',
      downcasedSingularized: 'point',
      downcasedPluralized: 'points',
      pluralizedTitleized: 'Points',
      singularizedTitleized: 'Point',
    }
    =>
    {
      pointsAliases: {
        PointsAlias: 'Points',
        PointAlias: 'Point',
        pointsAlias: 'points',
        pointAlias: 'point',
        PointsTitleAlias: 'Points',
        PointTitleAlias: 'Point',
      }
    }
*/

const getAssignmentNames: ParametricSelector<RootState, string | number, Name> = (state: RootState, courseId) => state.models.courses[courseId]?.assignmentName;

export const getAssignmentAliases = createSelector<RootState, string | number, Name, AssignmentAliases|null>(
  getAssignmentNames,
  (assignmentName: Name) => (assignmentName ? {
    AssignmentsAlias: assignmentName.capitalizedPluralized,
    AssignmentAlias: assignmentName.capitalizedSingularized,
    assignmentsAlias: assignmentName.downcasedPluralized,
    assignmentAlias: assignmentName.downcasedSingularized,
    AssignmentsTitleAlias: assignmentName.pluralizedTitleized,
    AssignmentTitleAlias: assignmentName.singularizedTitleized,
  } : null),
);

const getOfferingNames: ParametricSelector<RootState, string | number, Name> = (state: RootState, courseId) => state.models.courses[courseId]?.offeringName;

export const getOfferingAliases = createSelector<RootState, string | number, Name, OfferingAliases>(
  getOfferingNames,
  (offeringName: Name) => (offeringName ? {
    CoursesAlias: offeringName.capitalizedPluralized,
    CourseAlias: offeringName.capitalizedSingularized,
    coursesAlias: offeringName.downcasedPluralized,
    courseAlias: offeringName.downcasedSingularized,
    CoursesTitleAlias: offeringName.pluralizedTitleized,
    CourseTitleAlias: offeringName.singularizedTitleized,
  } : null),
);

const getTeachingTeamNames: ParametricSelector<RootState, string | number, Name> = (state: RootState, courseId) => state.models.courses[courseId]?.teachingTeamName;

export const getTeachingTeamAliases = createSelector<RootState, string | number, Name, TeachingTeamAliases>(
  getTeachingTeamNames,
  (teachingTeamName: Name) => (teachingTeamName ? {
    TeachingTeamsAlias: teachingTeamName.capitalizedPluralized,
    TeachingTeamAlias: teachingTeamName.capitalizedSingularized,
    teachingTeamsAlias: teachingTeamName.downcasedPluralized,
    teachingTeamAlias: teachingTeamName.downcasedSingularized,
    TeachingTeamsTitleAlias: teachingTeamName.pluralizedTitleized,
    TeachingTeamTitleAlias: teachingTeamName.singularizedTitleized,
  } : null),
);

const getGroupNames: ParametricSelector<RootState, string | number, Name> = (state: RootState, courseId) => state.models.courses[courseId]?.groupName;

export const getGroupAliases = createSelector<RootState, string | number, Name, GroupAliases>(
  getGroupNames,
  (groupName: Name) => (groupName ? {
    GroupsAlias: groupName.capitalizedPluralized,
    GroupAlias: groupName.capitalizedSingularized,
    groupsAlias: groupName.downcasedPluralized,
    groupAlias: groupName.downcasedSingularized,
    GroupsTitleAlias: groupName.pluralizedTitleized,
    GroupTitleAlias: groupName.singularizedTitleized,
  } : null),
);

const getPointNames: ParametricSelector<RootState, string | number, Name> = (state: RootState, courseId) => state.models.courses[courseId]?.pointsName;

export const getPointAliases = createSelector<RootState, string | number, Name, PointAliases>(
  getPointNames,
  (pointName: Name) => (pointName ? {
    PointsAlias: pointName.capitalizedPluralized,
    PointAlias: pointName.capitalizedSingularized,
    pointsAlias: pointName.downcasedPluralized,
    pointAlias: pointName.downcasedSingularized,
    PointsTitleAlias: pointName.pluralizedTitleized,
    PointTitleAlias: pointName.singularizedTitleized,
  } : null),
);

const getProgramCourseNames: ParametricSelector<RootState, string | number, Name> = (state: RootState, courseId) => state.models.courses[courseId]?.programCoursesName;

export const getProgramCourseAliases = createSelector<RootState, string | number, Name, ProgramCourseAliases>(
  getProgramCourseNames,
  (programCourseName: Name) => (programCourseName ? {
    ProgramCoursesAlias: programCourseName.capitalizedPluralized,
    ProgramCourseAlias: programCourseName.capitalizedSingularized,
    programCoursesAlias: programCourseName.downcasedPluralized,
    programCourseAlias: programCourseName.downcasedSingularized,
    ProgramCoursesTitleAlias: programCourseName.pluralizedTitleized,
    ProgramCourseTitleAlias: programCourseName.singularizedTitleized,
  } : null),
);

const getTeamNames: ParametricSelector<RootState, string | number, Name> = (state: RootState, courseId) => state.models.courses[courseId]?.teamName;

export const getTeamAliases = createSelector<RootState, string | number, Name, TeamAliases>(
  getTeamNames,
  (teamName: Name) => (teamName ? {
    TeamsAlias: teamName.capitalizedPluralized,
    TeamAlias: teamName.capitalizedSingularized,
    teamsAlias: teamName.downcasedPluralized,
    teamAlias: teamName.downcasedSingularized,
    TeamsTitleAlias: teamName.pluralizedTitleized,
    TeamTitleAlias: teamName.singularizedTitleized,
  } : null),
);

const getLearnerNames: ParametricSelector<RootState, string | number, Name> = (state: RootState, courseId) => state.models.courses[courseId]?.learnersName;

export const getLearnerAliases = createSelector<RootState, string | number, Name, LearnerAliases>(
  getLearnerNames,
  (learnerName: Name) => (learnerName ? {
    LearnersAlias: learnerName.capitalizedPluralized,
    LearnerAlias: learnerName.capitalizedSingularized,
    learnersAlias: learnerName.downcasedPluralized,
    learnerAlias: learnerName.downcasedSingularized,
    LearnersTitleAlias: learnerName.pluralizedTitleized,
    LearnerTitleAlias: learnerName.singularizedTitleized,
  } : null),
);

const getLectureNames: ParametricSelector<RootState, string | number, Name> = (state: RootState, courseId) => state.models.courses[courseId]?.lectureName;

export const getLectureAliases = createSelector<RootState, string | number, Name, LectureAliases>(
  getLectureNames,
  (lectureName: Name) => (lectureName ? {
    LecturesAlias: lectureName.capitalizedPluralized,
    LectureAlias: lectureName.capitalizedSingularized,
    lecturesAlias: lectureName.downcasedPluralized,
    lectureAlias: lectureName.downcasedSingularized,
    LecturesTitleAlias: lectureName.pluralizedTitleized,
    LectureTitleAlias: lectureName.singularizedTitleized,
  } : null),
);

/** Accepting course Id for fetching course from store.
 * Using the current course catalog id if the course id does not exist in the parameter
 */
export const getCourseAliases = (state: RootState, courseCatalogOrId: string | number = state.app.currentCatalogId) => createCourseSelector(state, courseCatalogOrId);

const createCourseSelector = createSelector<
/* eslint-disable @typescript-eslint/indent */
  RootState,
  string | number,
  AssignmentAliases,
  OfferingAliases,
  TeachingTeamAliases,
  GroupAliases,
  PointAliases,
  ProgramCourseAliases,
  TeamAliases,
  LearnerAliases,
  LectureAliases,
  CourseAliases
/* eslint-enable @typescript-eslint/indent */
>(
  getAssignmentAliases,
  getOfferingAliases,
  getTeachingTeamAliases,
  getGroupAliases,
  getPointAliases,
  getProgramCourseAliases,
  getTeamAliases,
  getLearnerAliases,
  getLectureAliases,
  (
    assignmentAliases: AssignmentAliases,
    courseAliases: OfferingAliases,
    teachingTeamAliases: TeachingTeamAliases,
    groupAliases: GroupAliases,
    pointsAliases: PointAliases,
    programCoursesAliases: ProgramCourseAliases,
    teamAliases: TeamAliases,
    learnersAliases: LearnerAliases,
    lectureAliases: LectureAliases,
  ): CourseAliases => ({
    ...(assignmentAliases && { assignmentAliases }),
    ...(courseAliases && { courseAliases }),
    ...(teachingTeamAliases && { teachingTeamAliases }),
    ...(groupAliases && { groupAliases }),
    ...(pointsAliases && { pointsAliases }),
    ...(programCoursesAliases && { programCoursesAliases }),
    ...(teamAliases && { teamAliases }),
    ...(learnersAliases && { learnersAliases }),
    ...(lectureAliases && { lectureAliases }),
  }),
);

/** Flattens the course aliases data structure so it has the same structure as CourseModel's getCourseAliases() */
export const getFlatCourseAliases = createSelector<RootState, string | number, CourseAliases, any>(
  [(state: RootState, catalogId: string | number) => getCourseAliases(state, catalogId)],
  (courseAliases) => merge({}, ..._.values(courseAliases)),
);

const getCourseActivityEntities: Selector<RootState, CourseActivitiesEntities> = (state: RootState) => ({
  [ActivityKey.CUSTOM_QUESTIONS]: state.models.peerEvaluations,
  [ActivityKey.EXERCISE_SKILLS_RATING]: state.models.exerciseSkillsRatingActivities,
  [ActivityKey.EXERCISES]: state.models.exercises,
  [ActivityKey.EXTERNAL_TOOLS]: state.models.externalTools,
  [ActivityKey.GROUP_TEAM_SET]: state.models.groupTeamSets,
  [ActivityKey.LECTURE_VIDEOS]: state.models.lectureVideos,
  [ActivityKey.LIVE_SESSION]: state.models.liveSessions,
  [ActivityKey.POLLS]: state.models.polls,
  [ActivityKey.PROFILE_REQUIREMENT]: state.models.profileRequirements,
  [ActivityKey.PROGRESSIVE_QUIZZES]: state.models.progressiveQuizzes,
  [ActivityKey.QUIZZES]: state.models.quizzes,
  [ActivityKey.SURVEYS]: state.models.surveys,
  [ActivityKey.TEAM_DISCUSSION]: state.models.teamDiscussions,
  [ActivityKey.TEAM_SET]: state.models.teamSets,
  [ActivityKey.TIMED_QUIZZES]: state.models.timedQuizzes,
  [ActivityKey.TOPICS]: state.models.posts,
  [ActivityKey.VIDEO_PRACTICE_FEEDBACK]: state.models.practiceFeedbackActivities,
  [ActivityKey.VIDEO_PRACTICE_SKILLS_FEEDBACK]: state.models.videoPracticeSkillsRating,
  [ActivityKey.VIDEO_PRACTICES]: state.models.practiceActivities,
});

const getCourseActivityNormalizedResult: Selector<RootState, CourseActivitiesNormalizedResult> = (state: RootState) => {
  const catalogId = state.app.currentCatalogId;
  return ({
    [ActivityKey.CUSTOM_QUESTIONS]: state.models.courses[catalogId].peerEvaluationIds,
    [ActivityKey.EXERCISE_SKILLS_RATING]: state.models.courses[catalogId].exerciseSkillsRatingActivityIds,
    [ActivityKey.EXERCISES]: state.models.courses[catalogId].exerciseIds,
    [ActivityKey.EXTERNAL_TOOLS]: state.models.courses[catalogId].externalToolIds,
    [ActivityKey.GROUP_TEAM_SET]: state.models.courses[catalogId].groupTeamSetIds,
    [ActivityKey.LECTURE_VIDEOS]: state.models.courses[catalogId].lectureVideoIds,
    [ActivityKey.LIVE_SESSION]: state.models.courses[catalogId].liveSessionIds,
    [ActivityKey.POLLS]: state.models.courses[catalogId].pollIds,
    [ActivityKey.PROFILE_REQUIREMENT]: state.models.courses[catalogId].profileRequirementIds,
    [ActivityKey.PROGRESSIVE_QUIZZES]: state.models.courses[catalogId].progressiveQuizIds,
    [ActivityKey.QUIZZES]: state.models.courses[catalogId].quizIds,
    [ActivityKey.SURVEYS]: state.models.courses[catalogId].surveyIds,
    [ActivityKey.TEAM_DISCUSSION]: state.models.courses[catalogId].teamDiscussionIds,
    [ActivityKey.TEAM_SET]: state.models.courses[catalogId].teamSetIds,
    [ActivityKey.TIMED_QUIZZES]: state.models.courses[catalogId].timedQuizIds,
    [ActivityKey.TOPICS]: state.models.courses[catalogId].postIds,
    [ActivityKey.VIDEO_PRACTICE_FEEDBACK]: state.models.courses[catalogId].practiceFeedbackActivityIds,
    [ActivityKey.VIDEO_PRACTICE_SKILLS_FEEDBACK]: state.models.courses[catalogId].videoPracticeSkillsRatingIds,
    [ActivityKey.VIDEO_PRACTICES]: state.models.courses[catalogId].practiceActivityIds,
  });
};

export const getCourseActivities = createSelector<RootState, CourseActivitiesEntities, CourseActivitiesNormalizedResult, CourseActivities>(
  [getCourseActivityEntities, getCourseActivityNormalizedResult],
  (entities, result) => {
    const denormalizedData: CourseActivities = denormalize(result, CourseActivitiesSchema, entities);
    const newActivities: CourseActivities = {};

    Object.keys(denormalizedData).forEach(key => {
      if (denormalizedData[key]?.length) {
        newActivities[key] = denormalizedData[key];
      }
    });

    return newActivities;
  },
);

export const getNumberofTodos = createSelector(
  [getCourseActivities],
  (activities) => (activities[ActivityKey.EXERCISES] || []).reduce((todos, assignment) => {
    if (assignment.isTodo) {
      todos += 1;
    }
    return todos;
  }, 0),
);

export const getCourseAdmins = createSelector(
  [getCourseUserIds, getUserById],
  (userIds, getUser) => userIds?.map((userId) => getUser(userId))
    .filter((user) => user && RolesService.isAdminRole(user.roles)),
);

export const isCourseSelfPaced = (state: RootState) => state.models.courses[state.app.currentCatalogId].isSelfPaced;

// Adapted from updateExerciseComponentExclusions() in the LecturePageManager
/** Gets the list of  */
export const getExerciseDependantComponentExclusions = (state: RootState) => {
  const courseExercises = state.app.lecturePage.exercises;

  const addableActivities = _.chain(courseExercises)
    .map((exercise) => exercise.addableTo)
    .flatten()
    .uniq()
    .value();

  return _.difference(EXERCISE_DEPENDENT_COMPONENTS, addableActivities);
};

export const getCurrentCourseCourseLongTeamSet = (state: RootState) => getCourse(state, state.app.currentCatalogId)?.courseLongTeamSet;

export const getCurrentCourseGroupTeamSet = (state: RootState) => getCourse(state, state.app.currentCatalogId)?.groupTeamSet;

export const getUserEnrolledCourses = createSelector(
  [
    getUserEnrollments,
    state => state.models.courses,
  ],
  (userEnrollments, courses) => userEnrollments.map(enrollment => courses[enrollment.courseCatalogId]),
);

export const isVideoPracticeFeedbackDisabled = (state: RootState) => !getCourse(state, state.app.currentCatalogId)?.practiceActivitiesAvailableForFeedback?.length;

export const getCourseFontColor = createSelector(
  [getCurrentCourse],
  ({ nameFontColor, institutionBrandBarFontColor }) => (nameFontColor ?? institutionBrandBarFontColor).toUpperCase(),
);

export const getCourseJourneys = createSelector(
  [getCurrentCourse],
  ({ inJourneys }) => (
    {
      total: inJourneys?.length,
      journeys: inJourneys?.map(journey => ({
        ...journey,
        sameColors: journey?.headerBackground ? false : compareHexColors(journey?.headerColor ?? '', journey?.nameFontColor ?? '#fff'),
      })),
    }
  ),
);

export const getCourseInstitutionColors = createSelector(
  [getCurrentCourse],
  ({ institutionBrandColor, institutionBrandBarFontColor }) => (
    {
      sameColors: compareHexColors(institutionBrandColor ?? '', institutionBrandBarFontColor ?? '#fff'),
      color: institutionBrandBarFontColor,
      backgroundColor: institutionBrandColor,
    }
  ),
);

export const getCourseCompletionStatus = (state: RootState) => state.app.courseCompletionStatus;

export const getFeaturedDiscovery = (state: RootState) => state.app.featuredDiscovery;

export const getDiscoveryParams = (state: RootState) => state.app.discovery;
