/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { FormOrgLevelProfileSettings } from 'redux/schemas/models/org-level-profile-settings';

export const saveOrgLevelProfileSettings = createAsyncThunk(
  'SAVE_ORG_LEVEL_PROFILE_SETTINGS',
  (payload: {
    institutionId: number,
    form: FormOrgLevelProfileSettings,
  }) => axios.put(
    `institutions/${payload.institutionId}/update_profile_settings.json`,
    payload.form,
  ).then(response => response.data.result),
);
