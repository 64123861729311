import { css } from '@emotion/react';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import t from 'react-translate';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from 'react-bootstrap';

// Contexts
import { CommunicationAction, CommunicationDispatch, TriggerType } from 'communications/course_communications/contexts/communication-context';

// Schema
import { CommunicationCategory, ViewMode } from 'redux/schemas/models/course-communication';

// Hooks
import { CommunicationFormContext, UseCommunicationMethods } from 'communications/course_communications/hooks/use-communication-form';

// Styles
import { tripleSpacing } from 'styles/global_defaults/scaffolding';
import { gray4 } from 'styles/global_defaults/colors';

// Components
import SaveDraft from 'communications/course_communications/components/communication-modal/save_draft/save-draft';
import SubmitButton from 'communications/course_communications/components/communication-modal/submit-button';

type StepOneProps = {
  validationSchema: yup.ObjectSchema,
  children: ReactNode,
  isAnnouncement: boolean,
};

const styles = (isWelcomeEmail: boolean) => css`
  ${isWelcomeEmail && `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
  `}

  .modal-body-title {
    border-bottom: 1px solid ${gray4};
  }

  .actions {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: ${tripleSpacing}px;
  }
`;

const StepOne = ({
  validationSchema,
  children,
  isAnnouncement,
}: StepOneProps) => {
  const { formData, updateFormData, setLastValidStep, nextStep,
    saveAsDraft, isDirty, draftSubmittedAt, getCommunicationDraft,
  } = useContext<UseCommunicationMethods>(CommunicationFormContext);

  const rhfMethods = useForm({
    mode: 'all',
    shouldUnregister: true,
    defaultValues: formData,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, formState, watch } = rhfMethods;
  const { State, dispatch } = useContext(CommunicationDispatch);

  // subscribing to formState fields - https://react-hook-form.com/api/useform/formstate
  const { isDirty: isFormStateDirty, isValid: isFormValid } = formState;
  // Watch all fields in this form. If a change occured
  // refresh the communicationFormData
  const watchAll = watch();
  useEffect(() => {
    // updateFormData for announcements is called from announcement form
    if (State.communicationCategory !== CommunicationCategory.SCHEDULED_ANNOUNCEMENT) {
      updateFormData(watchAll);
    }
  }, [watchAll, updateFormData, State.viewMode, State.communicationCategory]);

  useEffect(() => {
    setLastValidStep(isFormValid ? null : 0);
  }, [isFormValid, setLastValidStep]);

  const openForm = () => {
    dispatch({
      type: CommunicationAction.SET_VIEW_MODE,
      viewMode: ViewMode.FORM_VIEW,
    });
  };

  const communicationDraft = getCommunicationDraft();
  const isWelcomeEmail = State.communicationCategory === CommunicationCategory.WELCOME_EMAIL;

  const submitButton = () => {
    let button: JSX.Element;
    if (isWelcomeEmail) {
      if (State.viewMode === ViewMode.EMAIL_PREVIEW) {
        button = (
          <Button
            onClick={() => dispatch({
              type: CommunicationAction.SET_VIEW_MODE,
              viewMode: ViewMode.FORM_VIEW,
              // triggerType will be view only if the mail already exists
              triggerType: State.triggerType === TriggerType.VIEW
                ? TriggerType.EDIT : State.triggerType,
            })}
          >
            {State.triggerType === TriggerType.CREATE
              ? t.COURSE_COMMUNICATIONS.ANNOUNCEMENT.FORM.PREVIEW_BACK()
              : t.FORM.EDIT()}
          </Button>
        );
      } else {
        // Enable button only if the form is valid and dirty for Update action
        const isValid = State.triggerType === TriggerType.EDIT
          ? isFormValid && isFormStateDirty : isFormValid;
        button = <SubmitButton isValid={isValid} />;
      }
    } else if (!isAnnouncement) {
      button = (
        <Button
          type='submit'
          disabled={!isFormValid}
          className='on-next'
        >
          {t.COURSE_COMMUNICATIONS.TRIGGERS.CREATE.NEXT_BUTTON.STEP_1()}
        </Button>
      );
    } else if (State.viewMode !== ViewMode.FORM_VIEW) {
      button = (
        <Button className='on-next' onClick={openForm}>
          {t.COURSE_COMMUNICATIONS.ANNOUNCEMENT.FORM.PREVIEW_BACK()}
        </Button>
      );
    } else {
      button = <SubmitButton isValid={isFormValid} />;
    }

    return button;
  };

  const secondaryButton = () => (
    <Button
      variant='secondary'
      disabled={!isFormValid}
      className='mr-2'
      onClick={() => (State.viewMode === ViewMode.FORM_VIEW
        ? dispatch({
          type: CommunicationAction.SET_VIEW_MODE,
          viewMode: ViewMode.EMAIL_PREVIEW,
        }) : dispatch({ type: CommunicationAction.CLOSE_MODAL })
      )}
    >
      {State.viewMode === ViewMode.FORM_VIEW
        ? t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.PREVIEW_EMAIL()
        : t.FORM.CLOSE()}
    </Button>
  );

  return (
    <FormProvider {...rhfMethods}>
      <form onSubmit={handleSubmit(nextStep)}>
        <div css={styles(isWelcomeEmail)}>
          {/* Step 1 Filters */}
          {children}
          <div className='actions pb-7'>
            {isWelcomeEmail ? (
              <div className='d-flex'>
                {(State.viewMode === ViewMode.FORM_VIEW
                || State.triggerType !== TriggerType.CREATE)
                  && secondaryButton()}
                {submitButton()}
              </div>
            ) : (
              <>
                {submitButton()}
                {!(isAnnouncement && State.viewMode !== ViewMode.FORM_VIEW) && (
                <SaveDraft
                  onClick={saveAsDraft}
                  changesMade={isFormValid && isDirty}
                  draftSaved={draftSubmittedAt}
                  isDraft={communicationDraft?.submitted === false}
                />
                )}
              </>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default StepOne;
