
import t from 'react-translate';

// components
import { FilterType } from 'redux/schemas/models/course-communication';
import { NvTooltip } from 'shared/components/nv-tooltip';
import { NvDropdown, NvDropdownButtonStyle, NvDropdownAlign, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';

type FilterButtonProps = {
  value: FilterType,
  onChange: Function,
};

const FilterButton = (props: FilterButtonProps) => {
  const filterItems: NvDropdownOption[] = [
    {
      type: 'header',
      title: t.COURSE_COMMUNICATIONS.FILTER.HEADER(),
      class: 'page-title-xxs',
    },
    {
      labelClass: 'text-medium',
      type: 'radio',
      class: 'mt-4',
      group: 'filter_by',
      label: t.COURSE_COMMUNICATIONS.FILTER.ANNOUNCEMENTS(),
      isChecked: props.value === FilterType.ANNOUNCEMENTS,
      callback: () => props.onChange(FilterType.ANNOUNCEMENTS),
    },
    {
      labelClass: 'text-medium',
      type: 'radio',
      class: 'mt-4',
      group: 'filter_by',
      label: t.COURSE_COMMUNICATIONS.FILTER.DATE_BASED_EMAILS(),
      isChecked: props.value === FilterType.DATE_BASED_EMAILS,
      callback: () => props.onChange(FilterType.DATE_BASED_EMAILS),
    },
    {
      labelClass: 'text-medium',
      type: 'radio',
      class: 'mt-4',
      group: 'filter_by',
      label: t.COURSE_COMMUNICATIONS.FILTER.COMPLETION_TRIGGERED_EMAILS(),
      isChecked: props.value === FilterType.COMPLETION_TRIGGERED_EMAILS,
      callback: () => props.onChange(FilterType.COMPLETION_TRIGGERED_EMAILS),
    },
  ];

  return (
    <NvTooltip text={t.COURSES.FILTER.TITLE()} placement='top' key='filter-btn'>
      <div className='filter-panel'>
        <div className={props.value ? 'text-primary' : 'text-gray-2'}>
          <NvDropdown
            buttonStyle={NvDropdownButtonStyle.ICON}
            iconClass='icon-small icon-filter py-2 mt-2'
            align={NvDropdownAlign.RIGHT}
            items={filterItems}
          />
        </div>
      </div>
    </NvTooltip>
  );
};

export default FilterButton;
