import store from 'redux/store';

/* @ngInject */
export default function LecturePageManager(
  _,
  $q,
  TimelinesManager,
) {
  const manager = {
    // data
    allLecturePages: [], // flat list of all lecture pages
    /**
     * TODO: The following property is used in feedback and submission carousel
     * and introduced in https://novoed.atlassian.net/browse/NOV-51082
     * But couldn't recreate the use of it. This is might be stale after LPR.
     * Not removing this for now, but can be removed along with this in other
     * components
     */

    loadingCarousel: false,

    getLecturePages,
    currentMode,
    isViewMode,
    isEditMode,
    isRestrictedEditMode,
    isReorderMode,
  };

  function getLecturePages() {
    const { fullTimelinePromise, lastLecturePageRequestPromise } = TimelinesManager.getAllCurrentPromises();
    const promise = $q.all(_.compact([fullTimelinePromise, lastLecturePageRequestPromise]));

    return promise.then(() => {
      if (TimelinesManager.currentTimeline) {
        manager.allLecturePages = TimelinesManager.currentTimeline.lecturePages;
      }
    });
  }

  function currentMode() {
    return store.getState().app.lecturePage.mode;
  }

  /* Admin Edit */
  function isViewMode() {
    return this.currentMode() === 'view';
  }

  function isEditMode() {
    return this.currentMode() === 'edit';
  }

  function isRestrictedEditMode() {
    return this.currentMode() === 'restrictedEdit';
  }

  function isReorderMode() {
    return this.currentMode() === 'reorder';
  }

  return manager;
}
