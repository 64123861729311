import React from 'react';
import moment from 'moment';
import t from 'react-translate';

import { VideoPracticeActivity } from 'redux/schemas/models/video-practice';
import NvIcon from 'shared/components/nv-icon';

import { CheckIcon, todoIcon, todoProgress } from './todo-row';

type VideoPracticeTodoProps = VideoPracticeActivity & {
  thumbnailSrc: string,
  backgroundColor: string,
};

function VideoPracticeTodo({
  type,
  title,
  deadline,
  progress,
  thumbnailSrc,
  backgroundColor,
}: VideoPracticeTodoProps) {
  const icon = todoIcon[type];
  const deadlineMoment = moment(deadline);
  const deadlineDay = deadlineMoment.format('dddd');
  const deadlineTime = deadlineMoment.format('lll');
  const currentMoment = moment();

  const isComplete = progress === todoProgress.COMPLETED;

  const showDueSoon = deadline
    && !isComplete
    && deadlineMoment > currentMoment
    && deadlineMoment < moment().add(7, 'days');

  const todoClassNames = [
    'todo-container',
    isComplete && 'completed',
    showDueSoon && 'due-soon',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={todoClassNames}>
      <div className='todo-description'>
        <div className='todo-cover'>
          {thumbnailSrc
            ? <img src={thumbnailSrc} alt='' />
            : <div className='square-thumbnail' style={{ backgroundColor }} />}
        </div>
        <div className='todo-icon'>
          {isComplete
            ? <CheckIcon />
            : <NvIcon icon={icon} size='smallest' />}
        </div>
        <div className='todo-title'>
          {title}
        </div>
      </div>
      <div className='todo-content'>
        <div className='status'>
          {isComplete && t.TIMELINE.EXERCISE_STATUS.COMPLETED()}
        </div>
        {deadline && (
          <div className='date'>
            <span className='day'>{deadlineDay}</span>
            <span className='time'>{deadlineTime}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default VideoPracticeTodo;
