/* eslint-disable react/require-default-props */
/** @jsx */
import { jsx } from '@emotion/react';
import { FieldError } from 'react-hook-form';
import React, { ChangeEvent, FocusEvent, KeyboardEvent } from 'react';

import t from 'react-translate';
import { mergeRefs } from 'shared/react-utils';
import NvPopover from 'shared/components/nv-popover';
import ValidationErrorMessage from 'shared/components/inputs/validation-error-message';
import { config } from '../../config/config.json';

/**
 * Created this variable to prevent typescript errors about google property
 */
const windowVariable = window as any;

const addGoogleScript = () => {
  const googleScript = document.createElement('script');
  googleScript.id = 'google_script';
  googleScript.type = 'text/javascript';
  googleScript.src = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&callback=initLocationAutocomplete&key=${config.google.key}`;
  googleScript.setAttribute('defer', '');
  document.body.appendChild(googleScript);
};

type Props = {
  value?: string,
  required?: boolean,
  className?: string,
  error?: FieldError,
  readOnly?: boolean,
  placeholder?: string,
  onChange?: (location: string) => void,
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void,
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void,
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void,
};

/**
 * Component that acts as an autocomplete input, showing cities as options from
 * Google Places API.
 * NOTE: This component is based on current existing AngularJS version, see:
 * app/learner_profiles/directives/nv-google-location-autocomplete.js
 * TODO: This component will be able to render more validation UI for Profile
 * Completion project integration.
 */
const NvGooglePlacesAutocomplete = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    readOnly,
    className,
    placeholder,
    required = false,
    error: propsError,
    value: propsValue,
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
    onKeyDown = () => {},
  } = props;


  /**
   * This state is a "shadow" of the value of the input. This means I always
   * want this state value to be equal to the input value.
   * NOTE: Value not used to controll the value, it's for validation purposes
   * instead.
   */
  const [shadowValue, setShadowValue] = React.useState(propsValue || '');

  const hasInvalidValueRef = React.useRef(false);
  const inputRef = React.useRef<HTMLInputElement>();
  const [touched, setTouched] = React.useState(false);
  const [focused, setFocused] = React.useState(false);

  React.useEffect(() => {
    /**
     * Defining callback attached to window
     */
    windowVariable.initLocationAutocomplete = () => {
      const autocomplete = new windowVariable.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ['(cities)'],
        },
      );

      windowVariable.google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace().formatted_address;

        if (place) {
          setShadowValue(place);
          hasInvalidValueRef.current = false;
          onChange(place);
        }
      });
    };

    /**
     * If Google script is already loaded, let's just initialize the
     * autocomplete input
     */
    if (windowVariable.google) {
      windowVariable.initLocationAutocomplete();
    } else {
      addGoogleScript();
    }
  }, []);

  React.useEffect(() => {
    const isComponentBeingControlled = typeof propsValue === 'string';

    if (isComponentBeingControlled) {
      /**
       * Imperatively setting the value of the input, to simulate the
       * controlling feature.
       */
      inputRef.current.value = propsValue;
    }
  }, [propsValue]);

  const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    event.persist();
    setFocused(false);

    setTimeout(() => {
      setTouched(true);

      if (!hasInvalidValueRef.current) {
        onBlur(event);
      }
    }, 500);
  };

  const handleInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    onFocus(event);
  };

  const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    event.persist();

    setTimeout(() => {
      if (event.key === 'Enter') {
        setTouched(true);
      }

      if (!hasInvalidValueRef.current) {
        onKeyDown(event);
      }
    }, 500);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setShadowValue(value);

    // if Google Maps API js library fails to load, allow user to enter any input
    if (value && windowVariable.google) {
      hasInvalidValueRef.current = true;
    } else {
      /**
       * Because empty is a valid value for this input.
       */
      hasInvalidValueRef.current = false;
      onChange(value);
    }
  };

  const requiredSelectionError = hasInvalidValueRef.current ? t.VALIDATION.LOCATION() : '';
  const notRequiredSelectionError = hasInvalidValueRef.current && shadowValue ? t.VALIDATION.LOCATION() : '';

  const selectionError = required ? requiredSelectionError : notRequiredSelectionError;
  const requiredError = required && !shadowValue ? t.VALIDATION.REQUIRED() : '';
  const error = propsError?.message || requiredError || selectionError;

  return (
    <NvPopover
      placement='top'
      preventOverflow
      content={<ValidationErrorMessage text={error} />}
      show={!!error.length && (readOnly ? focused : touched)}
    >
      <div className={`bs4-input-group ${readOnly ? 'readonly ' : ''}`}>
        <input
          type='text'
          readOnly={readOnly}
          onBlur={handleInputBlur}
          placeholder={placeholder}
          onFocus={handleInputFocus}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          ref={mergeRefs(ref, inputRef)}
          className={`bs4-form-control${className ? ` ${className}` : ''}`}
        />
      </div>
    </NvPopover>
  );
});

export default NvGooglePlacesAutocomplete;
