import React from 'react';
import { css } from '@emotion/core';

import NvIcon from 'shared/components/nv-icon';
import { danger, gray5 } from 'styles/global_defaults/colors';

type Props = {
  used: boolean;
  className?: string;
};

const AttemptHeart = React.forwardRef<HTMLElement, Props>((props, ref) => {
  const { used, className } = props;
  const style = css`
    color: ${danger};
    ${used && css`
      animation-duration: 0.5s;
      animation-name: attempt-used;
      animation-fill-mode: forwards;
    `};

    @keyframes attempt-used {
      from {
        color: ${danger};
      }
      to {
        color: ${gray5};
      }

      animation-timing-function: ease-in-out;
    }

    &:last-child {
      margin-right: 0;
    }
  `;

  return (
    <NvIcon
      ref={ref}
      css={style}
      icon='attempt'
      size='xss-smallest'
      className={className}
    />
  );
});

export default AttemptHeart;
