import axios from 'axios';
import React from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import Icon from 'cohort_management/components/icon';
import { Course } from 'redux/schemas/models/course';
import NvTooltip from 'shared/components/nv-tooltip';
import { openSans } from 'styles/global_defaults/fonts';
import NvAutocompleteBase from 'shared/components/nv-autocomplete-base';
import ClickableContainer, { ClickableContainerProps } from 'components/clickable-container';
import {
  info,
  gray2,
  gray3,
  gray4,
  primary,
} from 'styles/global_defaults/colors';
import {
  halfSpacing,
  doubleSpacing,
  quarterSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import { config } from '../../../config/pendo.config.json';

const RESULTS_CONTAINER_BORDER_WIDTH = 1;
const RESULT_ITEM_HEIGHT = doubleSpacing;

const sharedInputStyles = css`
  flex: 1;
  border: 1px solid;
  border-color: ${gray4};
  height: ${doubleSpacing}px;

  &:focus {
    border-color: ${primary}px;
  }
`;

const sharedContentStyles = css`
  background-color: #fff;
  height: ${doubleSpacing}px;
  padding: 0 ${halfSpacing}px;
  line-height: ${doubleSpacing}px;
`;

const commonTextStyles = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: ${openSans}px;
`;

type Props = {
  onCourseAdd: (course: Course) => void,
  className?: string,
  autocompleteClassName?: string,
  deniedCoursesDictionary?: { [catalogId: string]: boolean },
};

const InputCourseSearch = (props: Props) => {
  const {
    className,
    onCourseAdd,
    autocompleteClassName,
    deniedCoursesDictionary,
  } = props;

  const [course, setCourse] = React.useState(null);
  const currentInstitutionId = useSelector((state) => state.app.currentInstitutionId);
  const resetCourse = () => setCourse(null);

  const styles = css`
    display: flex;
    align-items: center;

    .autocomplete-results-container {
      max-height: ${(RESULT_ITEM_HEIGHT * 5) + (RESULTS_CONTAINER_BORDER_WIDTH * 2)}px;
    }

    .add-button {
      margin-left: ${halfSpacing}px;

      &[aria-disabled=true] {
        color: ${gray4};
      }

      &[aria-disabled=false] {
        color: ${primary};
      }
    }
  `;

  const handleAddClick = () => {
    resetCourse();
    onCourseAdd(course);
  };

  const fetchPredictions = (hint: string) => {
    if (hint) {
      return axios.get('/courses.json', {
        params: {
          active: 1,
          future: 1,
          text: hint,
          primary: 1,
          production: 1,
          courses_only: 1,
          matching_desc: 1,
          institution_id: currentInstitutionId,
        },
      }).then((response) => response.data.result as Course[]);
    }

    return null;
  };

  const isCurrentCourseDenied = !!deniedCoursesDictionary?.[course?.catalogId];

  return (
    <div css={styles} className={className}>
      <NvAutocompleteBase<Course>
        value={course}
        onChange={setCourse}
        className={autocompleteClassName}
        fetchPredictions={fetchPredictions}
        keyExtractor={(localCourse) => localCourse.catalogId}
        resultsContainerClassName='autocomplete-results-container'
        renderLoadingIndicator={() => <LoadingIndicator />}
        renderNoResults={() => (
          <PresentationalOption
            text={t.LEARNING_JOURNEYS.DETAILS.COURSES.EMPTY_RESULTS()}
          />
        )}
        renderInput={(inputProps) => (
          <Input
            placeholder={t.LEARNING_JOURNEYS.DETAILS.COURSES.SEARCH_PLACEHOLDER()}
            {...inputProps}
          />
        )}
        renderResultItem={(resultItemProps, value) => (
          <SearchResultItem
            course={value}
            denied={deniedCoursesDictionary[value.catalogId]}
            {...resultItemProps}
          />
        )}
        renderValueSelected={(resultItemProps, value) => (
          <SearchResultItem
            isToggle
            course={value}
            denied={isCurrentCourseDenied}
            {...resultItemProps}
          />
        )}
      />
      <NvTooltip
        enabled={!!course}
        text={
          isCurrentCourseDenied
            ? t.LEARNING_JOURNEYS.DETAILS.COURSES.ALREADY_ADDED()
            : t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.ADD_TO_COLLECTION()
        }
      >
        <ClickableContainer
          className='add-button'
          onClick={handleAddClick}
          disabled={!course || isCurrentCourseDenied}
          pendo-tag-name={config.pendo.learningJourneys.addCourseToCollection}
        >
          <NvIcon icon='add' size='small' />
        </ClickableContainer>
      </NvTooltip>
    </div>
  );
};

const RequiredIndicator = () => {
  const styles = css`
    top: 11px;
    color: ${gray3};
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    right: ${halfSpacing}px;
    font-family: ${openSans};
    line-height: ${standardSpacing}px;
  `;

  return (
    <div css={styles}>*</div>
  );
};

type InputProps = Omit<React.ComponentProps<'input'>, 'type'>;

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { onBlur, onFocus, className, ...restProps } = props;

  const [isFocused, setIsFocused] = React.useState(false);

  const styles = css`
    position: relative;
    ${sharedInputStyles};
    ${isFocused && css`
      border-color: ${primary};
    `};

    input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      font-size: 14px;
      font-weight: 400;
      font-family: ${openSans};
      line-height: ${standardSpacing}px;
      padding: 0 ${halfSpacing * 3}px 0 ${halfSpacing}px;

      ::placeholder {
        color: ${gray2};
      }
    }
  `;

  const handleInputBlur = (event) => {
    onBlur?.(event);

    setIsFocused(false);
  };

  const handleInputFocus = (event) => {
    onFocus?.(event);

    setIsFocused(true);
  };

  return (
    <div css={styles} className={className}>
      <input
        ref={ref}
        type='text'
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        {...restProps}
      />
      <RequiredIndicator />
    </div>
  );
});

type SearchResultItemProps = ClickableContainerProps & {
  course: Course,
  denied?: boolean,
  isToggle?: boolean,
};

const SearchResultItem = React.forwardRef<HTMLDivElement, SearchResultItemProps>((props, ref) => {
  const {
    denied,
    course,
    className,
    isToggle = false,
    ...restProps
  } = props;

  const styles = css`
    width: 100%;
    border: none;
    display: block;
    text-align: left;
    position: relative;
    ${sharedContentStyles};

    .cohort-management-icon {
      display: inline-block;
      margin-right: ${quarterSpacing}px;
    }

    ${denied && css`
      color: ${gray4};
      font-style: italic;

      .catalog-id {
        color: ${gray4};
      }
    `};

    ${isToggle ? css`
      border-color: ${primary};
      padding-right: ${halfSpacing * 3}px;
      ${sharedInputStyles};
    ` : css`
      &:hover, &:focus {
        background-color: ${info};
      }
    `};
  `;

  return (
    <ClickableContainer
      ref={ref}
      css={styles}
      className={`ellipsis${(isToggle && className) ? ` ${className}` : ''}`}
      {...restProps}
    >
      <Icon course={course} className='cohort-management-icon' />
      <TextContent
        name={course.name}
        catalogId={course.catalogId}
        catalogIdClassName='catalog-id'
      />
      {isToggle && <RequiredIndicator />}
    </ClickableContainer>
  );
});

type TextContentProps = {
  name: string,
  catalogId: string,
  catalogIdClassName?: string,
};

const TextContent = (props: TextContentProps) => {
  const {
    name,
    catalogId,
    catalogIdClassName,
  } = props;

  const spacerStyles = css`
    display: inline-block;
    width: ${quarterSpacing}px;
  `;

  const catalogIdStyles = css`
    color: ${gray2};
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    font-family: ${openSans}px;
  `;

  return (
    <React.Fragment>
      <span css={commonTextStyles}>{name}</span>
      <div css={spacerStyles} />
      <span
        css={catalogIdStyles}
        className={catalogIdClassName}
      >
        {catalogId}
      </span>
    </React.Fragment>
  );
};

type PresentationalOptionProps = {
  text: string,
};

const PresentationalOption = (props: PresentationalOptionProps) => {
  const styles = css`
    display: flex;
    align-items: center;
    ${sharedContentStyles};
    ${commonTextStyles};
  `;

  return <div css={styles}>{props.text}</div>;
};

const LoadingIndicator = () => {
  const loadingText = NvAutocompleteBase.useLoadingText(t.LEARNING_JOURNEYS.DETAILS.COURSES.SEARCHING());

  return <PresentationalOption text={loadingText} />;
};

export default InputCourseSearch;
