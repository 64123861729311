import { schema } from 'normalizr';
import { omit } from 'underscore';
import { LectureSection } from '../models/lecture-section';
import { LecturePageListSchema } from './lecture-pages';

export const LectureSectionSchema = new schema.Entity<LectureSection>('lectureSections', {});

// eslint-disable-next-line import/prefer-default-export
export const LectureSectionListSchema = new schema.Array(LectureSectionSchema);
LectureSectionSchema.define({
  lectureSubsections: LectureSectionListSchema,
  lecturePages: LecturePageListSchema,
});

export default LectureSectionListSchema;
