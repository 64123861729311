import React, { useContext, useState } from 'react';
import { AngularContext } from 'react-app';
import t from 'react-translate';
// Redux
import { useSelector } from 'react-redux';
import { getCourseAliases } from 'redux/selectors/course';
import { useAppDispatch } from 'redux/store';
import { deleteTeam } from 'redux/actions/teams';
// Models
import { Team, TeamMember } from 'redux/schemas/models/team';
// Style
import { css } from '@emotion/react';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
// Components
import { Button } from 'react-bootstrap';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
// Config
import { config } from '../../../config/pendo.config.json';


enum CheckboxOptions {
  REMOVE_MEMBER = 0,
  REMOVE_TEAM = 1,
}

interface ModalBodyProps {
  memberToRemove: TeamMember,
  teamInfo: Team,
  cleanMemberToRemove: () => void,
}

const LastMemberModalBody = ({
  memberToRemove,
  teamInfo,
  cleanMemberToRemove,
}: ModalBodyProps) => {
  const { injectServices } = useContext(AngularContext);
  const [PageLevelManager] = injectServices(['PageLevelManager']);
  const aliases = useSelector((state) => getCourseAliases(state, teamInfo.teamSet.catalogId));
  const [checkboxes, setCheckboxes] = useState([false, false]);
  const dispatch = useAppDispatch();

  if (!teamInfo) {
    return null;
  }
  const closeL4 = () => {
    PageLevelManager.callL4CloseCallbacks();
  };

  const setCheckbox = (checkbox) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[checkbox] = !newCheckboxes[checkbox];
    setCheckboxes(newCheckboxes);
  };
  const isEverythingChecked = () => {
    let result = true;
    checkboxes.forEach(checkboxValue => {
      result = result && checkboxValue;
    });
    return result;
  };
  const style = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;

    padding-top: 20vh;
    padding-left: 20vw;
    padding-right: 20vw;

    .icon {
      margin-bottom: ${standardSpacing * 2}px;
    }

    .title-text, .body-text {
      max-width: 800px;
    }

    .title-text {
      margin-bottom: ${standardSpacing}px;
    }

    .body-text {
      margin-bottom: ${standardSpacing}px;
    }

    .checkbox-area {
      width: 100%;
      max-width: 800px;
      .checkbox {
        padding: ${halfSpacing}px 0;
        margin: unset;
        label {
          font-size: inherit;
          line-height: inherit;
        }
      }
    }

    .buttons {
      & > *:not(:last-of-type) {
        margin-right: ${standardSpacing}px;
      }
    }
  `;
  return (
    <React.Fragment>
      {
        memberToRemove && (
        <div className='text-white' css={style}>
          <div className='text-center icon icon-large icon-warning' />
          <div className='text-center title-text page-title'>
            {t.TEAMS.MANAGE_TEAM_GROUP.MODAL.REMOVING_LAST_MEMBER.TITLE(memberToRemove.user.fullName, teamInfo.name)}
          </div>
          <div className='text-center body-text text-large-body'>
            {t.TEAMS.MANAGE_TEAM_GROUP.MODAL.REMOVING_LAST_MEMBER.DESCRIPTION({ isTeam: !teamInfo.teamSet.isGroup, ...aliases.teamAliases, ...aliases.groupAliases })}
          </div>
          <div className='checkbox-area page-title-small'>
            <NvCheckbox
              className='checkbox text-regular'
              name='removing-member'
              onChange={(e) => {
                setCheckbox(CheckboxOptions.REMOVE_MEMBER);
              }}
              label={t.TEAMS.MANAGE_TEAM_GROUP.MODAL.REMOVING_LAST_MEMBER.REMOVE_MEMBER(memberToRemove.user.fullName)}
              data-qa={config.pendo.manageOneTeamGroup.modalContent.body.memberList.editMenu.menuOptions.deleteLastMemberConfirmationModal.checkDeleteMember}
            />
            <NvCheckbox
              className='checkbox text-regular'
              name='removing-team'
              onChange={(e) => {
                setCheckbox(CheckboxOptions.REMOVE_TEAM);
              }}
              label={t.TEAMS.MANAGE_TEAM_GROUP.MODAL.REMOVING_LAST_MEMBER.REMOVE_TEAM(teamInfo.name)}
              data-qa={config.pendo.manageOneTeamGroup.modalContent.body.memberList.editMenu.menuOptions.deleteLastMemberConfirmationModal.checkDeleteTeam}
            />
          </div>
          <div className='buttons'>
            <Button
              variant='secondary'
              className='bs4-night'
              data-qa={config.pendo.manageOneTeamGroup.modalContent.body.memberList.editMenu.menuOptions.deleteLastMemberConfirmationModal.cancelDeleteConfirmationModal}
              onClick={(e) => cleanMemberToRemove()}
            >
              {t.TEAMS.MANAGE_TEAM_GROUP.MODAL.REMOVING.CANCEL()}
            </Button>
            <Button
              variant='danger'
              className='bs4-night'
              data-qa={config.pendo.manageOneTeamGroup.modalContent.body.memberList.editMenu.menuOptions.deleteLastMemberConfirmationModal.confirmDeleteConfirmationModal}
              onClick={(e) => {
                closeL4();
                dispatch(deleteTeam({
                  isTeam: !teamInfo.teamSet.isGroup,
                  teamId: teamInfo.id,
                  catalogId: teamInfo.teamSet.catalogId,
                }));
              }}
              disabled={!isEverythingChecked()}
            >
              {t.TEAMS.MANAGE_TEAM_GROUP.MODAL.REMOVING.CONFIRM()}
            </Button>
          </div>
        </div>
        )
      }
    </React.Fragment>
  );
};

export default LastMemberModalBody;
