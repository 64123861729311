import PracticeScenarios from '../components/scenarios-flyout/practice-scenarios';

export default class PracticeRoomFlyoutReactCtrl {
  /* @ngInject */
  constructor(
    $scope,
  ) {
    $scope.PracticeScenarios = PracticeScenarios;
  }
}
