export default {
  bindings: {
    type: '<?',
    index: '<?',
    parent: '<?',
  },
  controller: function ctrl(
    TimelinesManager,
    _,
  ) {
'ngInject';
    const vm = this;
    vm.TimelinesManager = TimelinesManager;
  },
  controllerAs: 'vm',
  templateUrl: 'timelines/templates/nv-add-subsection-component.html',
};
