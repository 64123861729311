import React, { useLayoutEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import t from 'react-translate';

import { getCourseAliases } from 'redux/selectors/course';
import { CompletionCriteriaType } from 'redux/schemas/app/timeline';

import { getColorBetweenColorsByPercentage, gray6 } from 'styles/global_defaults/colors';

/**
 * NvProgressBar is different with what we need for completion criteria.
 * So keeping this separate and adapting from nv-criteria-progress-bar
 *
 */

export enum CriteriaProgressBarContext {
  HEADER = 'header',
  PANEL = 'panel',
}

type CriteriaProgressBarProps = {
  currentValue: number,
  maxValue: number,
  activeColor: string,
  type: CompletionCriteriaType,
  context: CriteriaProgressBarContext,
};

const CriteriaProgressBar = ({
  currentValue,
  maxValue,
  activeColor,
  type,
  context,
}: CriteriaProgressBarProps) => {
  const aliases = useSelector((state) => getCourseAliases(state));

  const progressBarRef = useRef(null);
  const [progressState, setProgressState] = useState(0);
  const [showBubble, setShowBubble] = useState(false);

  const isCompleted = currentValue >= maxValue;
  const barWidth = !isCompleted
    ? (currentValue / maxValue) * 100
    : 100;

  useLayoutEffect(() => {
    const widthTimeout = setTimeout(() => {
      setProgressState(barWidth);
    }, 0);

    return () => {
      clearTimeout(widthTimeout);
    };
  }, [barWidth]);

  useLayoutEffect(() => {
    if (progressBarRef.current) {
      progressBarRef.current.addEventListener('transitionend', () => {
        setShowBubble(true);
      });
    }
  }, []);

  if (!maxValue) {
    return null;
  }

  let background = activeColor;
  if (!isCompleted) {
    const startColor = getColorBetweenColorsByPercentage('#fff', activeColor, 0.4);
    background = `linear-gradient(90deg, ${startColor} 0%, ${activeColor} 100%)`;
  }

  const barHeight = 10;
  const arrowHeight = 8;
  const bubbleHeight = 32;

  const headerBarStyles = css`
    width: 200px;

    .arrow {
      width: 0;
      height: 0;
      margin-bottom: 2px;
      margin-left: calc(${barWidth}% - ${arrowHeight}px);
      border-left: ${arrowHeight}px solid transparent;
      border-right: ${arrowHeight}px solid transparent;
      border-top: ${arrowHeight}px solid ${activeColor};
    }

    .completed {
      color: ${activeColor};
    }

    .header-progress {
      height: ${barHeight}px;
      margin: 0;
      border-radius: ${barHeight}px;
      background: ${gray6};

      .header-progress-bar {
        height: ${barHeight}px;
        width: ${barWidth}%;
        border-radius: ${barHeight}px;
        background: ${background};
      }
    }
  `;

  const panelBarStyles = css`
    width: 80%;

    .panel-progress-wrapper {
      width: 100%;
      position: relative;

      .bubble-container {
        width: ${bubbleHeight}px;
        height: ${bubbleHeight}px;
        margin-left: calc(${barWidth}% - ${bubbleHeight / 2}px);
        background-color: ${activeColor};
        border-radius: 50% 50% 0;
        transform: rotate(45deg);
        position: absolute;
        box-shadow: 0 5px 7px rgba(29, 33, 38, 0.1);
      }
      .bubble-content {
        width: ${bubbleHeight}px;
        height: ${bubbleHeight}px;
        color: white;
        transform: rotate(-45deg);
        text-align: center;
      }
      .panel-progress {
        height: ${barHeight}px;
        border-radius: ${barHeight}px;
        background: ${gray6};
        margin-top: 45px;

        .panel-progress-bar {
          height: ${barHeight}px;
          width: ${progressState}%;
          border-radius: ${barHeight}px;
          background: ${background};
          transition: width 0.6s ease;
        }
      }
    }
  `;

  return (
    context === CriteriaProgressBarContext.PANEL ? (
      <div css={panelBarStyles} className='d-flex mt-2'>
        <div className='panel-progress-wrapper'>
          { showBubble && (
            <div className='bubble-container'>
              <div className='bubble-content semi-bold text-small pt-2'>{currentValue}</div>
            </div>
          )}
          <div className='panel-progress'>
            <div className='panel-progress-bar' ref={progressBarRef} />
          </div>
        </div>
      </div>
    ) : (
      <div css={headerBarStyles}>
        <React.Fragment>
          {isCompleted ? (
            <div className='d-flex justify-content-center text-small semi-bold completed'>
              {type === CompletionCriteriaType.POINTS
                ? t.COMPLETION_CRITERIA.RECEIVED_POINTS_SHORT({
                  ...aliases.pointsAliases,
                  receivedPoints: currentValue,
                })
                : t.COMPLETION_CRITERIA.ALL_DONE()}
            </div>
          ) : (
            <div className='arrow' />
          )}
        </React.Fragment>
        <div className='header-progress'>
          <div className='header-progress-bar' />
        </div>
      </div>
    )
  );
};

export default CriteriaProgressBar;
