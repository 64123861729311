import React from 'react';
import * as yup from 'yup';
import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';

import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import { gray5 } from 'styles/global_defaults/colors';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { OrgProfileField } from 'redux/schemas/models/account-fields';
import EntitlementFormRow from 'offerings/components/entitlement-form-row';
import { Entitlement } from 'offerings/components/configure-entitlements-modal';
import { config } from '../../../config/pendo.config.json';


type EntitlementsForm = {
  entitlements: FormEntitlement[],
};

// This is created because RHF doesn't take arrays with primitives
type FormEntitlementValue = {
  value: string,
};

type FormEntitlement = {
  id?: string,
  profileSetting: {
    id: number,
  },
  values: FormEntitlementValue[],
};

export type Props = {
  onCancel(): void,
  entitlements: Entitlement[],
  onClose(entitlements: Entitlement[]): void,
};

const getStyles = (hasEntitlements: boolean) => (css`
  padding: ${standardSpacing}px;

  form {
    width: 592px;
    margin: 0 auto;

    .entitlements {
      margin: ${2 * standardSpacing}px 0 ${3 * standardSpacing}px 0;
    }

    .add-entitlement {
      ${hasEntitlements ? css`
        border-top: 1px solid ${gray5};
      ` : css`
        justify-content: center;
      `};
      button>* {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
`);

const getFormDefaultValues = (entitlements) => ({
  entitlements: entitlements.map(entitlement => ({
    id: entitlement.id ? entitlement.id.toString() : undefined,
    profileSetting: {
      id: entitlement.profileSettingId,
    },
    values: entitlement.values.map(value => ({
      value,
    })),
  })),
});

const getValuesFromForm = (formData: EntitlementsForm) => (
  formData.entitlements?.map((entitlement) => ({
    id: entitlement.id ? parseInt(entitlement.id, 10) : undefined,
    profileSettingId: entitlement?.profileSetting.id,
    values: entitlement?.values.map((value) => value.value),
  }
  )));

const ConfigureEntitlementsModalBody = ({
  entitlements: currentEntitlements = [],
  onCancel,
  onClose,
}: Props) => {
  const currentInstitution = useSelector(getCurrentInstitution);
  const profileSettings: OrgProfileField[] = currentInstitution.profileSettings.orgLevel.filter(
    (profileSetting) => profileSetting.isIntegrated || profileSetting.isCsvManaged,
  );

  const validationSchema = yup.object().shape({
    entitlements: yup.array().of(
      yup.object().shape({
        profileSetting: yup.object().shape({
          id: yup.number().required(),
        }),
        values: yup.array().of(
          yup.object().shape({
            value: yup.string()
              .required(t.VALIDATION.REQUIRED())
              .max(255, t.VALIDATION.MAX_LENGTH('255')),
          }),
        ),
      }),
    ).ensure(),
  });

  const methods = useForm({
    mode: 'onChange',
    shouldUnregister: true,
    defaultValues: getFormDefaultValues(currentEntitlements),
    resolver: yupResolver(validationSchema),
  });
  const { control, handleSubmit, setValue, getValues, formState } = methods;

  const {
    fields: entitlementFields,
    remove: removeFromEntitlements,
    append: appendToEntitlements,
  } = useFieldArray({
    control,
    name: 'entitlements',
    keyName: 'keyName',
  });

  const onSubmit = (formData: EntitlementsForm) => {
    onClose(getValuesFromForm(formData));
  };

  const addEntitlementHandler = () => appendToEntitlements({
    profileSetting: {},
    values: [{
      value: '',
    }],
  });


  return (
    <div css={getStyles(!!entitlementFields.length)}>
      <div className='description'>{t.COURSES.FORM.ENTITLEMENTS.MODAL.DESCRIPTION()}</div>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='entitlements'>
            {entitlementFields.map((entitlementField, index) => (
              <EntitlementFormRow
                key={entitlementField.keyName}
                entitlementField={entitlementField}
                index={index}
                profileSettings={profileSettings}
                onRemove={() => removeFromEntitlements(index)}
              />
            ))}

            <div className='d-flex add-entitlement'>
              <Button onClick={addEntitlementHandler} variant='link' className='' disabled={entitlementFields.length >= profileSettings.length}>
                <NvIcon icon='add' size='xss-smallest' />
                {' '}
                {t.COURSES.FORM.ENTITLEMENTS.MODAL.ADD()}
              </Button>
            </div>
          </div>

          <ButtonToolbar className='d-flex justify-content-center'>
            <Button onClick={onCancel} variant='secondary' className='mr-2'>{t.FORM.CANCEL()}</Button>
            <Button
              type='submit'
              disabled={!formState.isDirty || !formState.isValid}
              pendo-tag-name={config.pendo.basics.configureEntitlements.update}
            >
              {t.FORM.UPDATE()}
            </Button>
          </ButtonToolbar>
        </form>
      </FormProvider>
    </div>
  );
};

export default ConfigureEntitlementsModalBody;
