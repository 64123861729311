import { Enrollment, User } from 'redux/schemas/models/my-account';
import { Name } from 'redux/schemas/models/courseFull';
import { OrgAdminRole } from 'institutions/services/roles-service';

export enum FilterType {
  TOTAL_MENTEES = 'total',
  NEVER_LOGGED_IN = 'neverLoggedIn',
  MISSED_DEADLINE = 'missedDeadline',
  NOT_ACTIVE = 'inactive',
  RECENTLY_COMPLETED = 'recentlyCompleted',
}

export enum FlagType {
  NEVER_LOGGED_IN = 'neverLoggedIn',
  MISSED_DEADLINE = 'deadlineMissed',
  NOT_ACTIVE = 'inactive',
  RECENTLY_COMPLETED = 'recentlyCompleted',
}

export enum FilterKeys {
  TOTAL_MENTEES = '',
  NEVER_LOGGED_IN = 'ghost_users',
  MISSED_DEADLINE = 'missed_deadline',
  NOT_ACTIVE = 'inactive_users',
  RECENTLY_COMPLETED = 'recently_completed',
}

export enum SortOrderType {
  ASC = 'asc',
  DESC = 'desc',
}
export interface InstitutionAdminUser {
  id: number,
  admin: boolean,
  firstName: string,
  lastName: string,
  profilePicture?: string,
  email: string,
}

export interface MentorUser extends User {
  userEmail: string,
}

interface Mentees {
  id: number,
  mentee: MentorUser
}
export interface OrgMentor {
  id: number,
  user: InstitutionAdminUser,
  institutionId: number,
  adminRole: OrgAdminRole,
  mentees: Mentees[],
}

export interface OrgMentee {
  id: number,
  firstName: string,
  lastName: string,
  profilePicture?: string,
  email: string,
  highlights: OrgMenteeHighlight,
}

export interface OrgMentorDashboard {
  mentors: number[],
  search: {
    hasMore?: boolean,
  }
}

export interface MentorDashboard {
  isMenteesLoading: boolean,
  isSummaryLoading: boolean,
  onceLoaded: boolean,
  mentees: number[],
  totalMenteesCount: number,
  filterCounts: FilterCounts,
  filterKey: FilterKeys,
  nextPage: number,
}

export interface OrgMenteeHighlight {
  numDeadlineMissed: number,
  lastActiveInCourses: string,
  courseCompletionProgress: {
    completed?: OrgMenteeHighlightCourse[],
    enrolled?: OrgMenteeHighlightCourse[],
    inProgress?: OrgMenteeHighlightCourse[],
    incomplete?: OrgMenteeHighlightCourse[],
  },
  inactivtyInCourses: OrgMenteeHighlightCourse[],
  neverLoggedInCourses: OrgMenteeHighlightCourse[],
  recentlyCompletedInCourses: OrgMenteeHighlightCourse[],
}

export interface OrgMenteeHighlightCourse {
  catalogId: string,
  name: string,
}
export interface FilterCounts {
  [FilterType.TOTAL_MENTEES]: number,
  [FilterType.NEVER_LOGGED_IN]: number,
  [FilterType.MISSED_DEADLINE]: number,
  [FilterType.NOT_ACTIVE]: number,
  [FilterType.RECENTLY_COMPLETED]: number,
}
