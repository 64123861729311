import React from 'react';
import { css } from '@emotion/react';

import t from 'react-translate';
import { mergeRefs } from 'shared/react-utils';
import ClickableContainer from 'components/clickable-container';
import { NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import NvSwitch from 'shared/components/inputs/nv-switch';
import useDropdownItemInnerFocusables, {
  isVerticalArrowKey,
} from 'shared/components/inputs/nv-dropdown/hooks/use-dropdown-item-inner-focusables';
import NvTooltip from 'shared/components/nv-tooltip';
import { useSelector } from 'react-redux';
import { config } from '../../../config/pendo.config.json';

type Tooltip = {
  on: string,
  off: string,
};

export type AutoCompletionProps = {
  lectureComponent: any,
  tooltip?: Tooltip,
  autoCompletionText?: string,
};

const getTooltipText = (checked: boolean, tooltip: Tooltip) => {
  const tooltipOff = tooltip?.off || t.LECTURE_PAGES.COMPONENTS.SCORM.AUTO_COMPLETION_OFF_TOOLTIP();
  const tooltipOn = tooltip?.on || t.LECTURE_PAGES.COMPONENTS.SCORM.AUTO_COMPLETION_ON_TOOLTIP();
  return checked ? tooltipOff : tooltipOn;
};

const AutoCompletionDropdownItem = React.forwardRef<HTMLDivElement, AutoCompletionProps>((props, ref) => {
  const {
    lectureComponent,
    tooltip,
    autoCompletionText = t.LECTURE_PAGES.COMPONENTS.SCORM.AUTO_COMPLETION(),
  } = props;
  const { externalTool } = lectureComponent;
  const { id } = externalTool;

  const containerRef = React.useRef<HTMLDivElement>();
  const accessibilityDropdownItemProps = useDropdownItemInnerFocusables();
  const {
    ref: accessibilityDropdownItemRef,
    ...restAccessibilityDropdownItemProps
  } = accessibilityDropdownItemProps;

  const styles = css`
    .auto-complete-switch {
      margin-left: 63px;
    }
  `;

  const handleSwitchChange = (newChecked: boolean) => {
    externalTool.autocompleteScorm = newChecked;
    lectureComponent.save();
  };

  const handleSwitchKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!isVerticalArrowKey(e.key)) {
      e.stopPropagation();
    }
  };

  const checked = useSelector((state) => state.models?.externalTools[id]?.autocompleteScorm);
  const tooltipText = getTooltipText(checked, tooltip);

  const dataQaProps = {};
  if (lectureComponent.externalTool.toolType === 'scorm') {
    dataQaProps['data-qa'] = checked ? config.pendo.activities.scorm.autoCompletionOff : config.pendo.activities.scorm.autoCompletionOn;
  }

  return (
    <ClickableContainer
      css={styles}
      ref={mergeRefs(ref, containerRef, accessibilityDropdownItemRef)}
      {...restAccessibilityDropdownItemProps}
      className={`${restAccessibilityDropdownItemProps.className} justify-content-between`}
    >
      <div className='text-medium'>
        {autoCompletionText}
      </div>
      <NvTooltip text={tooltipText}>
        <NvSwitch
          checked={checked}
          onChange={handleSwitchChange}
          onKeyDown={handleSwitchKeyDown}
          className='auto-complete-switch'
          enabledLabel={t.SHARED.ON().toUpperCase()}
          disabledLabel={t.SHARED.OFF().toUpperCase()}
          {...dataQaProps}
        />
      </NvTooltip>
    </ClickableContainer>
  );
});

export const createAutoCompletionDropdownItem = (props: AutoCompletionProps): NvDropdownOption[] => ([
  {
    type: 'header',
    title: t.LECTURE_PAGES.COMPONENTS.SCORM.AUTO_COMPLETION_HEADER(),
  }, {
    id: 'auto-completion',
    type: 'custom',
    preventClosing: true,
    customItem: <AutoCompletionDropdownItem {...props} />,
  }, {
    type: 'divider',
  },
]);

export default AutoCompletionDropdownItem;
