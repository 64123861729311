import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';

import { AngularServicesContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { getCurrentUser } from 'redux/selectors/users';
import { deleteComment, flagComment, highlightComment, unHighlightComment } from 'redux/actions/comments';
import { deleteReply } from 'redux/actions/replies';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';

import {
  NvDropdown,
  NvDropdownOption,
  NvDropdownButtonStyle,
  NvDropdownAlign,
} from 'shared/components/inputs/nv-dropdown';
import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import { CommentRowContext } from './comment-row';
import { CommentsContainerContext } from './comments-container';

type CommentActionsProps = {
  setIsEditing: (isEditing: boolean) => void,
};

const CommentActions = ({ setIsEditing }: CommentActionsProps) => {
  const { catalogId, ownerType, ownerId } = useContext(CommentsContainerContext);
  const { comment, parentCommentId, isReply } = useContext(CommentRowContext);
  const { InstitutionsManager, CurrentUserManager } = React.useContext(AngularServicesContext);

  const dispatch = useAppDispatch();

  const [dropdownItems, setDropdownItems] = useState<NvDropdownOption[]>([]);
  const currentUser = useSelector((state) => getCurrentUser(state));
  const adminOptionsVisible = CurrentUserManager.isInstructorForCurrentCourse()
    || CurrentUserManager.isTeachingAssistantForCurrentCourse();
  const belongsToCurrentUser = comment.user.id === currentUser.id;
  const canDelete = belongsToCurrentUser || adminOptionsVisible;
  const canEdit = !InstitutionsManager.institution.disableDiscussionEdits && canDelete;

  const onEditComment = useCallback(() => setIsEditing(true), [setIsEditing]);

  const onDelete = useCallback(() => {
    if (isReply) {
      dispatch(deleteReply({
        catalogId,
        replyId: comment.id,
        commentId: parentCommentId,
        reportId: ownerType === 'report' ? ownerId : null,
      }));
    } else {
      dispatch(deleteComment({
        catalogId,
        ownerType,
        ownerId,
        commentId: comment.id,
      }));
    }
  }, [catalogId, parentCommentId, ownerType, ownerId, comment.id, dispatch, isReply]);

  const onDeleteComment = useCallback(() => {
    const hasReplies = comment.repliesCount > 0;
    const hasPoints = comment.pointsReceived;

    let deleteMessage = t.DISCUSSIONS.DELETE_COMMENT_MSG();
    if (isReply) {
      deleteMessage = t.DISCUSSIONS.DELETE_REPLY_MSG();

      if (hasPoints && comment.user.id === currentUser.id) {
        deleteMessage = t.DISCUSSIONS.DELETE_REPLY_WITH_POINTS_MSG();
      }
    } else if (comment.user.id !== currentUser.id) {
      deleteMessage = t.DISCUSSIONS.DELETE_COMMENT_MSG();
    } else if (hasReplies && hasPoints) {
      deleteMessage = t.DISCUSSIONS.DELETE_COMMENT_WITH_POINTS_AND_REPLIES_MSG();
    } else if (!hasReplies && hasPoints) {
      deleteMessage = t.DISCUSSIONS.DELETE_COMMENT_WITH_POINTS_MSG();
    } else if (hasReplies && !hasPoints) {
      deleteMessage = t.DISCUSSIONS.DELETE_COMMENT_WITH_REPLIES_MSG();
    }
    dispatch(openConfirmationDialog({
      bodyText: deleteMessage,
      confirmText: t.FORM.DELETE(),
      cancelText: t.FORM.CANCEL(),
      icon: 'warning',
      onConfirm: onDelete,
    }));
  }, [comment.pointsReceived, comment.repliesCount, comment.user.id, currentUser.id, dispatch, isReply, onDelete]);

  const onHighlightComment = useCallback(() => {
    dispatch(highlightComment({
      catalogId,
      commentId: comment.id,
    }));
  }, [catalogId, comment.id, dispatch]);

  const onUnhighlightComment = useCallback(() => {
    dispatch(unHighlightComment({
      catalogId,
      commentId: comment.id,
    }));
  }, [catalogId, comment.id, dispatch]);

  const onFlagComment = useCallback(() => {
    dispatch(flagComment({
      catalogId,
      commentId: comment.id,
    }));
  }, [catalogId, comment.id, dispatch]);

  useEffect(() => {
    const items: NvDropdownOption[] = [];

    if (adminOptionsVisible) {
      items.push(
        { type: 'header', title: t.DISCUSSIONS.ADMIN_OPTIONS() },
      );
      if (!isReply) {
        if (comment.highlighted) {
          items.push(
            { type: 'text', text: t.DISCUSSIONS.DROPDOWN.UNHIGHLIGHT(), callback: onUnhighlightComment },
          );
        } else {
          items.push(
            { type: 'text', text: t.DISCUSSIONS.DROPDOWN.HIGHLIGHT(), callback: onHighlightComment },
          );
        }
      }
    }

    if (canEdit) {
      items.push(
        { type: 'text', text: t.DISCUSSIONS.DROPDOWN.EDIT(), callback: onEditComment },
      );
    }

    if (canDelete) {
      items.push(
        { type: 'text', text: t.DISCUSSIONS.DROPDOWN.DELETE(), callback: onDeleteComment },
      );
    }

    if (!belongsToCurrentUser) {
      items.push(
        { type: 'text', text: t.DISCUSSIONS.DROPDOWN.FLAG(), callback: onFlagComment, disabled: comment.flagged },
      );
    }
    setDropdownItems(items);
  }, [
    adminOptionsVisible, isReply, belongsToCurrentUser, canDelete, canEdit,
    comment.flagged, comment.highlighted,
    onDeleteComment, onEditComment,
    onUnhighlightComment, onHighlightComment, onFlagComment,
  ]);

  return (
    <div className='d-flex'>
      {/* <BookmarkIcon
        size='xss-smallest'
        bookmarked={!!comment.bookmarkId}
        onCreate={onCreateBookmark}
        onHighlight={onHighlightBookmark}
      /> */}
      {dropdownItems.length > 0 && (
        <div className='show-on-hover ml-1'>
          <NvDropdown
            minWidth={220}
            buttonStyle={NvDropdownButtonStyle.CUSTOM}
            items={dropdownItems}
            align={NvDropdownAlign.RIGHT}
            customTarget={() => (
              <NvTooltip text={t.DISCUSSIONS.ACTIONS()}>
                <div>
                  <NvIcon size='xss-smallest' icon='arrow-down' />
                </div>
              </NvTooltip>
            )}
            toggleDataQa='comment-actions-toggle'
          />
        </div>
      )}
    </div>
  );
};

export default CommentActions;
