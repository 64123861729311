/* @ngInject */
export default function TeamSetsResources(

  $resource,
) {
  return $resource(
    '/:catalogId/team_sets/:id.json',
    {},
    {
      get: { method: 'GET', url: '/:catalogId/teams/:id' },
      enableProgressDashboard: { method: 'GET', url: '/:catalogId/team_sets/:id/enable_progress_dashboard' },
      disableProgressDashboard: { method: 'GET', url: '/:catalogId/team_sets/:id/disable_progress_dashboard' },
    },
  );
}
