import React, { useEffect } from 'react';

// redux
import { RootState } from 'redux/schemas';
import { CollectionFolder } from 'redux/schemas/models/collections';
import { getCollectionFolder } from 'redux/selectors/collections';
import { useSelector } from 'react-redux';

// components
import NvIcon from 'shared/components/nv-icon';
import { useAppDispatch } from 'redux/store';
import { fetchFolder } from 'redux/actions/collections';
import LoadingPlaceholder, { LoaderType } from 'shared/components/loading-placeholder';
import ClickableContainer from 'components/clickable-container';
import CollectionRowLesson from './collection-row-lesson';
import { CollectionRowProps, useOnClickExpand } from './collection-row';

export type Props = {
  folderId: number
} & CollectionRowProps;

const CollectionRowFolder = (props: Props) => {
  const { folderId, catalogId } = props;

  const currentfolder: CollectionFolder = useSelector((state: RootState) => getCollectionFolder(state, folderId));
  const { lecturePages, lecturePagesCount } = currentfolder;

  const dispatch = useAppDispatch();
  const { isLoading, isExpanded, setIsLoading, toggleExpanded } = useOnClickExpand();

  useEffect(() => {
    if (isExpanded) {
      dispatch(fetchFolder({
        folderId,
        pageIndex: 1,
      })).then(() => {
        setIsLoading(false);
      });
    }
  }, [dispatch, folderId, isExpanded, setIsLoading]);

  return (
    <React.Fragment>
      {lecturePagesCount > 0 && (
        <div className='d-flex flex-column py-2 mt-4' key={folderId}>
          <ClickableContainer
            className={`d-flex align-items-center ${isExpanded ? 'pb-2' : ''}`}
            onClick={toggleExpanded}
          >
            <NvIcon
              icon={`arrow-${isExpanded ? 'down' : 'right'}`}
              size='xss-smallest'
              className='text-gray-1 pr-4'
            />
            <NvIcon
              icon='admin-folder'
              size='small'
              className='text-gray-3 pr-2'
            />
            <div className='text-regular font-weight-bolder ellipsis'>
              {currentfolder.title}
            </div>
          </ClickableContainer>
          {isExpanded && (
            (isLoading && (!lecturePages || lecturePages === null)) ? (
              <LoadingPlaceholder loaderType={LoaderType.HALF} className='px-4 py-2' />
            ) : (
              lecturePages?.length > 0 && lecturePages.map((lecturePageId) => (
                <CollectionRowLesson
                  lecturePageId={lecturePageId}
                  key={lecturePageId}
                  catalogId={catalogId}
                />
              ))))}
        </div>
      )}
    </React.Fragment>
  );
};

export default CollectionRowFolder;
