import { ReactElement, useRef, useState, useEffect } from 'react';
import t from 'react-translate';

// Styles
import { css } from '@emotion/react';

// Component
import NvModal, { NvModalProps } from './nv-modal';
import { getCollapser } from './view-more-collapser';

export enum ViewMoreBehavior {
  NORMAL = 'normal',
  MODAL = 'modal',
  CUSTOM = 'custom',
};

export enum ViewMoreCollapser {
  NORMAL = 'normal',
  DYNAMIC = 'dynamic',
  CUSTOM = 'custom',
};

const DEFAULT_VALUES = {
  rowHeight: 30,
  maxRows: 3,
  gap: 10,
  collapser: ViewMoreCollapser.DYNAMIC,
  behavior: ViewMoreBehavior.NORMAL,
};

type NvViewMoreProps = {
  rowHeight?: number
  maxRows?: number
  gap?: number
  type?: ViewMoreBehavior
  collapser?: ViewMoreCollapser
  text?: string
  maxHeight?: number
  modalSettings?: NvModalProps
  children: any
  dataQa?: string
  contract?: boolean
  customViewMore?: (viewMore: boolean) => ReactElement
  onViewMore?: (viewMore: boolean) => void
};

const NvViewMore = (props: NvViewMoreProps) => {
  const {
    rowHeight = DEFAULT_VALUES.rowHeight,
    maxRows = DEFAULT_VALUES.maxRows,
    gap = DEFAULT_VALUES.gap,
    type = DEFAULT_VALUES.behavior,
    collapser: collapserType = DEFAULT_VALUES.collapser,
    text: viewMoreText = t.SHARED.VIEW_MORE(),
    maxHeight: max,
    modalSettings,
    children,
    dataQa,
    contract,
    customViewMore,
    onViewMore,
  } = props;
  const wrapperRef = useRef(null);
  const getMaxHeight = () => max ?? (rowHeight + gap) * maxRows;

  const [viewMore, setViewMore] = useState(true);
  const [maxHeight, setMaxHeight] = useState(getMaxHeight());
  const [enableViewMore, setEnableViewMore] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const behaviors = {
    [ViewMoreBehavior.NORMAL]: () => {
      const { current } = wrapperRef;
      const { scrollHeight } = current;
      setMaxHeight(viewMore ? scrollHeight : getMaxHeight());
    },
    [ViewMoreBehavior.MODAL]: () => setShowModal(true),
    [ViewMoreBehavior.CUSTOM]: () => onViewMore?.(viewMore),
  };
  const behavior = behaviors[type];

  const onClick = () => {
    behavior();
    setViewMore(!viewMore);
  };

  const maxRealHeight = (contract && enableViewMore) ? maxHeight - rowHeight : maxHeight;

  const styles = css`
    .content-wrapper {
      max-height: ${`${maxRealHeight}px`};
      overflow: hidden;
    }
    .bs4-btn:focus, .bs4-btn:active, .bs4-btn:hover {
      box-shadow: none !important;
      text-decoration: none;
    }
  `;

  useEffect(() => {
    const { current } = wrapperRef;
    const { scrollHeight } = current;
    setViewMore(scrollHeight > maxHeight);
    setEnableViewMore(scrollHeight > maxHeight);
  }, [wrapperRef]);

  return (
    <div className='view-more-wrapper' css={styles}>
      <div className='content-wrapper' ref={wrapperRef}>
        {children}
      </div>
      <div className='d-flex justify-content-center'>
        {enableViewMore && getCollapser({
          type: collapserType,
          onClick,
          viewMore,
          customViewMore,
          text: viewMoreText,
          dataQa,
        })}
      </div>
      {type === ViewMoreBehavior.MODAL && (
        <NvModal
          show={showModal}
          onClose={() => {
            setShowModal(false);
            setViewMore(false);
          }}
          {...modalSettings}
        />
      )}
    </div>
  );
}

export default NvViewMore;
