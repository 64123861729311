/* @ngInject */
export default function TeamManager(
  _,
  moment,
  TeamsResources,
  TeamModel,
  UserManager,
  TeamMembershipModel,
  CurrentCourseManager,
) {
  const manager = {
    currentCourseId: CurrentCourseManager?.course?.id,
    currentTeam: null,
    memberships: [],
    membershipsHash: {},
    teamsGroupsInCurrentCourse: null,
    hasTeamsGroupsInCurrentCourse: null,
    requestingTeam: false,
    requestingMemberships: true,
    isNotOnTeam: false,

    requestTeam,
    requestMemberships,
    addMembership,
    leaveTeam,
    checkUserIsNotOnTeam,
    requestTeamForProfile,
    deleteTeam,
    newTeam,
    getSearchFilters,
    enableProgressDashboard,
    disableProgressDashboard,
    markWorkspaceAsVisited,
  };

  initialize();

  async function initialize() {
    await requestMemberships();
  }

  function requestTeam(catalogId, teamId) {
    manager.requestingTeam = true;

    return TeamsResources.get(
      { catalogId, id: teamId },
      (resource) => {
        manager.requestingTeam = false;
        manager.currentTeam = new TeamModel(resource.result);
      },
    ).$promise;
  }

  function requestMemberships() {
    manager.requestingMemberships = true;

    return UserManager.getCurrentMemberships().then((response) => {
      const memberships = response.result;

      // Getting the full user memberships and memberships hash list
      memberships.forEach(item => manager.addMembership(new TeamMembershipModel(item)));
      memberships.sort(TeamMembershipModel.sortByWorkspaceVisit);

      // Getting the user teams and groups in the current course
      manager.teamsGroupsInCurrentCourse = manager.memberships.filter(mem => mem.team?.teamSet?.courseId === manager.currentCourseId && (mem.team?.teamSet?.isCourseLong || mem.team?.teamSet?.isGroup));

      // Checking if the user has teams and groups
      manager.hasTeamsAndGroups = manager.teamsGroupsInCurrentCourse.some(mem => mem.team.teamSet?.isGroup || mem.team.teamSet?.isTeam);

      manager.requestingMemberships = false;
    });
  }

  function addMembership(membership) {
    if (!manager.membershipsHash[membership.id]) {
      manager.memberships.push(membership);
      manager.membershipsHash[membership.id] = membership;
    }
  }

  function leaveTeam() {
    manager.memberships = manager.memberships.filter(membership => membership.team.id !== manager.currentTeam.id);

    // TODO: Update the teams and groups panel for current user
  }

  async function checkUserIsNotOnTeam(teamId) {
    await requestMemberships();
    const onTeam = manager.memberships.some(membership => membership.team.id === teamId);
    manager.isNotOnTeam = !onTeam;
  }

  function requestTeamForProfile(catalogId, teamId) {
    manager.requestingTeam = true;

    return TeamsResources.getTeamForProfile(
      { catalogId, id: teamId },
      (resource) => {
        manager.requestingTeam = false;
        manager.currentTeam = new TeamModel(resource.result);
      },
    ).$promise;
  }

  function deleteTeam(catalogId) {
    return manager.currentTeam.deleteTeam(catalogId).then(() => {
      manager.currentTeam = null;
    });
  }

  function enableProgressDashboard(catalogId) {
    return manager.currentTeam.enableProgressDashboard(catalogId).then(() => {
      manager.currentTeam.hasProgressDashboard = true;
    });
  }

  function disableProgressDashboard(catalogId) {
    return manager.currentTeam.disableProgressDashboard(catalogId).then(() => {
      manager.currentTeam.hasProgressDashboard = false;
    });
  }

  function newTeam(teamSet) {
    return new TeamModel({
      teamSet,
    });
  }

  function getSearchFilters(catalogId, isGroup) {
    return TeamsResources.searchFilters({ catalogId, isGroup }).$promise;
  }

  function markWorkspaceAsVisited(teamId) {
    const currentTeam = _.find(manager.memberships, (membership) => membership.team.id === teamId);

    if (currentTeam) {
      if (!currentTeam.visitedWorkspaceAt) {
        currentTeam.visitedWorkspaceAt = moment();
      }
      currentTeam.newPostsSinceLastVisit = false;
    }
  }

  return manager;
}
