import { css } from '@emotion/react';
import t from 'react-translate';
import React, { useContext } from 'react';
import { gray6, warning, white } from 'styles/global_defaults/colors';
import { boldFontWeight, lightFontWeight, openSansCondensed, textLargeFontSize } from 'styles/global_defaults/fonts';
import { AngularServicesContext } from 'react-app';
import { halfSpacing, largeSpacing } from 'styles/global_defaults/scaffolding';
import { CourseAliases } from 'redux/schemas/models/course';
import { useSelector } from 'react-redux';
import { getCourseAliases } from 'redux/selectors/course';
import FlyoutGroupTeamCard from './flyout-group-team-card';

type FlyoutCourseContainerCardProps = {
  index: number;
  courseName: string;
  groupsData: any;
  isCurrentCourse: boolean;
};
const FlyoutCourseContainerCard = ({ index, courseName, groupsData, isCurrentCourse }: FlyoutCourseContainerCardProps) => {
  const aliases: CourseAliases = useSelector((state) => getCourseAliases(state));


  const styles = css`
    display:flex;
    flex-direction:column;
    border-bottom: 1px solid ${gray6};
    padding: ${largeSpacing}px 0 ${largeSpacing}px 0;
    order:2;
    &.current{
      order:1;
    }
    .title{
      font-family: ${openSansCondensed};
      font-size:${textLargeFontSize}px;
      margin:0;
      margin-bottom: ${halfSpacing}px;
      margin-left: ${largeSpacing}px;

      font-weight:${lightFontWeight};
      width:310px;
    }
    .current-course{
      background-color: ${warning};
      color: ${white};
      width:fit-content;
      height:16px;
      margin-left: ${largeSpacing}px;
      font-size:${halfSpacing}px;
      font-weight: ${boldFontWeight};
      padding: 1px 4px;
    }
  `;

  return (
    <div css={styles} data-qa={`flyout-teams-course-${index}`} className={`course-container-card ${isCurrentCourse ? 'current' : ''}`}>
      {isCurrentCourse && <div className='current-course' data-qa='flyout-course-team-current'>{t.LHS.COURSE_CARD.IN_CURRENT_COURSE({ courseAlias: aliases.courseAliases.CourseAlias })}</div>}
      <div className='title ellipsis' data-qa={`flyout-teams-course-title-${index}`}>{courseName}</div>
      {groupsData.map((item, itemindex) => (
        <FlyoutGroupTeamCard
          index={itemindex}
          key={item.team.id}
          name={item.team.name}
          image={item.team.profilePicture}
          catalogId={item.team.courseCatalogId}
          teamId={item.team.id}
        />
      ))}
    </div>
  );
};

export default FlyoutCourseContainerCard;


