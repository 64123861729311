import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import t from 'react-translate';

// schemas
import { useAppDispatch } from 'redux/store';
import { RootState } from 'redux/schemas';
import { ActivityType } from 'redux/schemas/models/activity';
import { ComponentKeyPluralized } from 'redux/schemas/models/lecture-component';
import { ProgressiveQuiz } from 'redux/schemas/models/progressive-quiz';

// actions
import { setActivityExpandedInAutomatedCommunications } from 'redux/actions/course-communications';

// selectors
import { isActivityExpandedInAutomatedCommunications } from 'redux/selectors/course-communications';
import { hasProgressiveQuizGotCommunicationError } from 'redux/selectors/timeline';

// components
import NvIcon from 'shared/components/nv-icon';
import NvStackedTab from 'shared/components/nv-stacked-tab';
import CommunicationCreateNewDropdown from 'communications/course_communications/components/communication-create-new-dropdown';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';
import NvTooltip from 'shared/components/nv-tooltip';

type ProgressiveQuizLectureComponentProps = {
  ProgressiveQuizActivityId: number
};

const ProgressiveQuizLectureComponent = ({ ProgressiveQuizActivityId }: ProgressiveQuizLectureComponentProps) => {
  const dispatch = useAppDispatch();

  const isExpanded = useSelector<RootState, boolean>((state: RootState) => isActivityExpandedInAutomatedCommunications(state, ComponentKeyPluralized.PROGRESSIVE_QUIZ, ProgressiveQuizActivityId));
  const progressiveQuizActivity = useSelector<RootState, ProgressiveQuiz>((state: RootState) => state.models.progressiveQuizzes[ProgressiveQuizActivityId]);

  const hasCommunicationError = useSelector<RootState, boolean>((state) => hasProgressiveQuizGotCommunicationError(state, ProgressiveQuizActivityId));

  return (
    <React.Fragment>
      <div className={`lecture-component-row ${isExpanded && progressiveQuizActivity?.communicationsCount > 0 && 'expanded-box'}`}>
        <NvIcon icon='quiz' size='smallest' />
        <div className='description'>{progressiveQuizActivity?.title}</div>
        {progressiveQuizActivity?.hasStructuralIssues && (
          <div className='text-danger spacing'>
            <NvIcon icon='warning' size='xss-smallest' />
          </div>
        )}
        {progressiveQuizActivity?.hardDeadline && (
          <NvTooltip text={t.TIMELINE.HARD_DEADLINE()} placement='top'>
            <div className='spacing'>
              <NvIcon icon='harddeadline' size='smallest' />
            </div>
          </NvTooltip>
        )}
        {progressiveQuizActivity?.expirationDate && (
          <div className='date'>
            {t.LECTURE_PAGES.COMPONENTS.DUE_DATE.STUDENT_VIEW(moment(progressiveQuizActivity.expirationDate).format('LLL'))}
          </div>
        )}
        <CommunicationCreateNewDropdown
          activityType={ActivityType.PROGRESSIVE_QUIZ}
          activityId={ProgressiveQuizActivityId}
        />
        {progressiveQuizActivity?.communicationsCount > 0 && (
          <NvStackedTab
            count={progressiveQuizActivity.communicationsCount}
            hasError={hasCommunicationError}
            isExpanded={isExpanded}
            errorMessage={t.COURSE_COMMUNICATIONS.WARNINGS.ACTIVITY_COMMUNICATION()}
            onClick={() => dispatch(setActivityExpandedInAutomatedCommunications({
              type: ComponentKeyPluralized.PROGRESSIVE_QUIZ,
              id: ProgressiveQuizActivityId,
              isExpanded: !isExpanded,
            }))}
          />
        )}
      </div>
      {
        // Communications
        isExpanded && progressiveQuizActivity?.communications && progressiveQuizActivity.communications?.map(communicationId => (
          <CommunicationItem key={communicationId.toString()} communicationId={communicationId} />
        ))
      }
    </React.Fragment>
  );
};

export default ProgressiveQuizLectureComponent;
