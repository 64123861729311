import { normalize } from 'normalizr';
import {
  without, keys, filter, find, isEmpty, map, some,
  mapObject, pick, uniq, each, reduce, isArray, union,
} from 'underscore';
import { createReducer } from '@reduxjs/toolkit';
import moment from 'moment';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';

// Schema
import { RootState } from 'redux/schemas';
import {
  CourseActivitiesNormalizedResult,
  CourseActivitiesEntities,
  CourseActivitiesSchema,
  CommunicationListSchema,
  ScheduledDueDatesEntities,
  ScheduledDueDatesNormalizedResult,
  ScheduledDueDatesSchema,
} from 'redux/schemas/api/course-communications';
import {
  AutomatedCommunicationsEntities,
  Communication,
  CommunicationsNormalized,
  CommunicationType,
  DraftCommunicationsEntities,
  FilteredCommunicationsEntities,
  ScheduledCommunicationsEntities,
  ScheduledTimeLineLoadingType,
} from 'redux/schemas/models/course-communication';
import { ActivityKey } from 'redux/schemas/models/activity';
import { LecturePageSchema } from 'redux/schemas/api/lecture-pages';
import {
  LecturePageEntities,
  LecturePageKey,
  LecturePagesNormalized,
  LecturePageType,
} from 'redux/schemas/models/lecture-page';
import { TriggerType } from 'communications/course_communications/contexts/communication-context';

// Actions
import {
  getCourseActivities,
  getLecturePageCommunications,
  deleteCommunication,
  setActivityExpandedInAutomatedCommunications, hideLecturePageCommunicationsInAutomatedCommunications, createCommunication,
  updateCommunication,
  getDraftCommunications,
  setLecturePageExpandedInAutomatedCommunications,
  resetLecturePageExpandedInAutomatedCommunications,
  getScheduledCommunications,
  getAutomatedCommunications,
  getScheduledDueDates,
  hidePastScheduledCommunications,
  resetScheduledCommunicationsTimeline,
  getFilteredCommunications,
  resetFilteredCommunications,
  getCourseWelcomeEmail,
  disableWelcomeEmail,
  enableWelcomeEmail,
  currentSendingNowCommunication,
  updateCommunicationPartially,
} from 'redux/actions/course-communications';

// Selectors
import { getActivityIdsFromLecturePageId } from 'redux/selectors/timeline';
import { getKeyForOwnerType, getOwnerTypeForKey } from 'redux/selectors/course-communications';

import { replaceArrays } from 'shared/lodash-utils';
import { initialRootState } from '.';

const ownerKeys = {
  ...LecturePageKey,
  ...ActivityKey,
};

const getDate = (date: string) => moment(date).format('YYYY-MM-DD');

// TODO: Shouldn't this use an initial course comms state?
export default createReducer(initialRootState, builder => {
  builder.addCase(createCommunication.fulfilled, (state, action) => {
    if (!action.payload) {
      return;
    }

    if (action.payload.communicationType === CommunicationType.COURSE_WELCOME_EMAIL) {
      state.app.courseCommunications[action.meta.arg.catalogId].automatedCommunications.welcomeEmail = action.payload;
      return;
    }

    const key = getKeyForOwnerType(action.payload.ownerType);
    const activityId = action.meta.arg.ownerId;
    const communicationId = action.payload.id;
    const { lecturePageId, triggerType } = action.meta.arg;
    const ownerType = getOwnerTypeForKey(key);

    let { draftCommunicationIds } = {
      ...state.models.courses[action.meta.arg.catalogId],
    };

    if (!action.payload.submitted) {
      draftCommunicationIds = [communicationId, ...(draftCommunicationIds ?? [])];
    }
    state.models.courses[action.meta.arg.catalogId].draftCommunicationIds = draftCommunicationIds;
    state.models.communications[communicationId] = action.payload;

    if (lecturePageId) {
      let newActivityCommunicationCount = find((state.models.lecturePages[lecturePageId]?.activityCommunicationsCount ?? []), (item) => (item.type === ownerType && item.id === activityId));
      if (isEmpty(newActivityCommunicationCount)) {
        // Create new object in communication count if it doesn't exist
        newActivityCommunicationCount = {
          type: ownerType,
          id: activityId,
          count: 1,
        };
      } else {
        // Increment Communication count if communication object exists
        newActivityCommunicationCount.count += 1;
      }

      state.models.lecturePages[lecturePageId].activityCommunicationsCount = [
        ...filter(state.models.lecturePages[lecturePageId].activityCommunicationsCount, (item) => (!(item.id === activityId && item.type === ownerType))),
        newActivityCommunicationCount,
      ];
    }

    const { automatedCommunications } = state.app.courseCommunications[action.meta.arg.catalogId];
    automatedCommunications.lastCreatedCommunicationId = communicationId;

    if (activityId) {
      const activity = {
        ...state.models[key][activityId],
      };

      activity.communications = [...(activity.communications ?? []), communicationId];
      activity.communicationsCount = activity.communications.length;
      state.models[key][activityId] = activity;

      if (!(key in automatedCommunications.expandedActivities)) {
        automatedCommunications.expandedActivities[key] = {};
      }

      automatedCommunications.expandedActivities[key][activityId] = true;
      automatedCommunications.expandedLecturePages[activityId] = {
        isExpanded: true,
        showCommunications: ownerType === LecturePageType.LECTURE
          || !!automatedCommunications.expandedLecturePages[activityId]?.showCommunications,
      };
    }
    if (triggerType === TriggerType.DUPLICATE
      && lecturePageId && lecturePageId !== action.payload?.lecturePageId) {
      // If the resulting lecture page is different from the current lecture page
      // Expand the target lecture page
      automatedCommunications.expandedLecturePages[action.payload.lecturePageId] = {
        isExpanded: true,
        showCommunications: ownerType === LecturePageType.LECTURE
        || !!automatedCommunications.expandedLecturePages[action.payload.lecturePageId]?.showCommunications,
      };
    }
    if (action.payload?.scheduledAt) {
      // Update the scheduledTab
      insertToScheduledTab(state, action.payload);
      // Update communication counts
      updateScheduledCounts(state, communicationId);
    }
    if (action.payload.communicationType === CommunicationType.AUTOMATED_JOURNEY_EMAIL) {
      state.models.courses[action.meta.arg.catalogId].automatedCommunicationIds.push(communicationId);
    }
  });

  builder.addCase(currentSendingNowCommunication, (state, action) => {
    if (!action.payload.state || !action.payload.id) {
      state.app.currentSendingNowCommunication = undefined;
    } else {
      state.app.currentSendingNowCommunication = {
        id: action.payload.id,
        state: action.payload.state,
      };
    }
  });

  builder.addCase(updateCommunicationPartially, (state, action) => {
    state.models.communications[action.payload.id].state = action.payload.state;
  });

  builder.addCase(updateCommunication.fulfilled, (state, action) => {
    if (!action.payload) {
      return;
    }

    if (action.payload.communicationType === CommunicationType.COURSE_WELCOME_EMAIL) {
      state.app.courseCommunications[action.meta.arg.catalogId].automatedCommunications.welcomeEmail = action.payload;
      return;
    }

    let { draftCommunicationIds } = {
      ...state.models.courses[state.app.currentCatalogId],
    };

    // If a draft gets updated to submitted, need to remove it from the course drafts
    if (action.payload.submitted) {
      draftCommunicationIds = [...without(draftCommunicationIds, action.payload.id)];
    }

    if (action.payload.scheduledAt) {
      const { dates: dateGrouped } = state.app.courseCommunications[state.app.currentCatalogId].scheduledCommunications;
      const scheduledCommunicationIds = dateGrouped[getDate(action.payload.scheduledAt)]?.communicationIds;
      // If the date of the communication is updated, need to remove the communicationId,
      // from previous date and insert it into the updated date.
      // So for that get the communications for the scheduled date,
      // and check whether it has the given id. That means the date is not updated.
      // But if it doesn't have the communicationId then that means communication is
      // updated, so need to delete from old date and insert to new.
      if (!scheduledCommunicationIds || (scheduledCommunicationIds && !scheduledCommunicationIds.includes(action.payload.id))) {
        // Remove the older communication and insert the updated communication
        removeScheduledItem(state, action.payload.id);
        insertToScheduledTab(state, action.payload);
      }
    }

    state.models.courses[action.meta.arg.catalogId].draftCommunicationIds = draftCommunicationIds;
    state.models.communications[action.payload.id] = action.payload;
  });

  builder.addCase(getCourseActivities.fulfilled, (state, action) => {
    const normalizedData = normalize<CourseActivitiesNormalizedResult, CourseActivitiesEntities>(action.payload, CourseActivitiesSchema);

    // Assign activities to their analogous models in the store
    mergeWith(state.models, normalizedData.entities, replaceArrays);

    // These properties are renamed onto the course model object. This means this object
    // is no longer a 1-1 mapping with backend data - in the future we'll find a way
    // to clean this up by storing this in a computed value layer of our redux state
    const course = state.models.courses[state.app.currentCatalogId];
    course.exerciseIds = normalizedData.result.exercises;
    course.exerciseSkillsRatingActivityIds = normalizedData.result.exerciseSkillsRatingActivities;
    course.externalToolIds = normalizedData.result.externalTools;
    course.groupTeamSetIds = normalizedData.result.groupTeamSets;
    course.lectureVideoIds = normalizedData.result.lectureVideos;
    course.liveSessionIds = normalizedData.result.liveSessions;
    course.peerEvaluationIds = normalizedData.result.peerEvaluations;
    course.pollIds = normalizedData.result.polls;
    course.postIds = normalizedData.result.posts;
    course.practiceActivityIds = normalizedData.result.practiceActivities;
    course.practiceFeedbackActivityIds = normalizedData.result.practiceFeedbackActivities;
    course.videoPracticeSkillsRatingIds = normalizedData.result.videoPracticeSkillsRating;
    course.profileRequirementIds = normalizedData.result.profileRequirements;
    course.progressiveQuizIds = normalizedData.result.progressiveQuizzes;
    course.quizIds = normalizedData.result.quizzes;
    course.surveyIds = normalizedData.result.surveys;
    course.teamDiscussionIds = normalizedData.result.teamDiscussions;
    course.teamSetIds = normalizedData.result.teamSets;
    course.timedQuizIds = normalizedData.result.timedQuizzes;
    course.videoIds = normalizedData.result.videos;
    course.totalPoints = normalizedData.result.totalPoints;
  });

  builder.addCase(getLecturePageCommunications.pending, (state, action) => {
    state.app.courseCommunications[state.app.currentCatalogId].automatedCommunications.isCommunicationsLoading[action.meta.arg.lecturePageId] = true;
    toggleActivities(state, action.meta.arg.lecturePageId, action.meta.arg.showCommunications);
  });

  builder.addCase(getLecturePageCommunications.fulfilled, (state, action) => {
    state.app.courseCommunications[state.app.currentCatalogId].automatedCommunications.isCommunicationsLoading[action.meta.arg.lecturePageId] = false;
    state.app.courseCommunications[state.app.currentCatalogId].automatedCommunications.isCommunicationsOnceLoaded[action.meta.arg.lecturePageId] = true;

    const normalizedData = normalize<LecturePagesNormalized, LecturePageEntities>(action.payload.lecturePages, LecturePageSchema);

    // Before the very first request, the activities won't be there in
    // the models state and so must be fed from response to appState to
    // toggle the activities according to the `showCommunications` param
    const activities: { [key: string]: number[] } = {};
    Object.values(ownerKeys).forEach(key => {
      activities[key] = keys(normalizedData.entities[key]).map(Number);
    });

    without(Object.keys(normalizedData.entities), 'lecturePages', 'video').forEach(entityType => {
      mergeWith(state.models[entityType], normalizedData.entities[entityType], replaceArrays);
      // TODO: I think we have a bug here b/c the entity prop for 'videos' is 'video'
    });

    // Handle `video` separately since it is singular and not pluarl on the entitites object
    Object.assign(state.models.video, normalizedData.entities.video);

    // Fill lecturePage communications
    Object.assign(
      state.models.lecturePages[action.meta.arg.lecturePageId],
      pick(normalizedData.entities.lecturePages[action.meta.arg.lecturePageId], 'lectureComponents', 'communications', 'communicationsCount'),
    );

    const params = action.meta.arg;
    toggleActivities(state, params.lecturePageId, params.showCommunications, activities);
  });

  builder.addCase(hideLecturePageCommunicationsInAutomatedCommunications, (state, action) => {
    state.app.courseCommunications[state.app.currentCatalogId].automatedCommunications.isCommunicationsOnceLoaded[action.payload.lecturePageId] = false;
    toggleActivities(state, action.payload.lecturePageId, false);
  });

  builder.addCase(resetLecturePageExpandedInAutomatedCommunications, (state) => {
    const { automatedCommunications } = state.app.courseCommunications[state.app.currentCatalogId];
    automatedCommunications.expandedActivities = {};
    automatedCommunications.expandedLecturePages = {};
    automatedCommunications.isCommunicationsLoading = {};
    automatedCommunications.isCommunicationsOnceLoaded = {};
  });

  builder.addCase(setActivityExpandedInAutomatedCommunications, (state, action) => {
    state.app.courseCommunications[state.app.currentCatalogId].automatedCommunications.expandedActivities[action.payload.type][action.payload.id] = action.payload.isExpanded;
  });

  builder.addCase(setLecturePageExpandedInAutomatedCommunications, (state, action) => {
    const { automatedCommunications } = state.app.courseCommunications[state.app.currentCatalogId];
    if (!automatedCommunications.expandedLecturePages) {
      automatedCommunications.expandedLecturePages = {};
    }

    automatedCommunications.expandedLecturePages[action.payload.id] = {
      ...automatedCommunications.expandedLecturePages[action.payload.id],
      ...pick(action.payload, 'isExpanded', 'showCommunications'),
    };
  });

  builder.addCase(getDraftCommunications.fulfilled, (state, action) => {
    const catalogId = state.app.currentCatalogId;
    // TODO: The old code did `payload.result` here, but `result` had already been unpacked in the async request func. Was this a bug?
    const normalizedData = normalize<CommunicationsNormalized, DraftCommunicationsEntities>(action.payload, CommunicationListSchema);
    state.models.courses[catalogId].draftCommunicationIds = normalizedData.result;
    Object.assign(state.models.communications, normalizedData.entities.communications);
    state.app.courseCommunications[catalogId].draftCommunications.isLoaded = true;
  });

  builder.addCase(getAutomatedCommunications.pending, (state, action) => {
    state.app.courseCommunications[state.app.currentCatalogId].automatedCommunications.isCommunicationsLoading[action.meta.arg.id] = true;
  });

  builder.addCase(getAutomatedCommunications.fulfilled, (state, action) => {
    const catalogId = state.app.currentCatalogId;

    // Parse communication and fill normalized data
    if (action.payload?.communications) {
      const normalizedData = normalize<CommunicationsNormalized, AutomatedCommunicationsEntities>(action.payload?.communications, CommunicationListSchema);
      Object.assign(state.models.communications, normalizedData.entities.communications);
      state.models.courses[catalogId].automatedCommunicationIds = normalizedData.result;
    }

    state.app.courseCommunications[catalogId].automatedCommunications.isCommunicationsLoading[action.meta.arg.id] = false;
  });

  builder.addCase(getScheduledCommunications.pending, (state, action) => {
    const { display: loadingType } = action.meta.arg;
    const loadingKey = loadingType === ScheduledTimeLineLoadingType.PAST
      ? 'isPastLoading' : 'isFutureLoading';
    state.app.courseCommunications[state.app.currentCatalogId].scheduledCommunications[loadingKey] = true;
  });

  builder.addCase(getScheduledCommunications.fulfilled, (state, action) => {
    const catalogId = state.app.currentCatalogId;
    const { display: loadingType, startTime, showCommunications, page } = action.meta.arg;

    // Parse communication and fill normalized data
    if (action.payload?.communications) {
      const normalizedData = normalize<CommunicationsNormalized, ScheduledCommunicationsEntities>(action.payload?.communications, CommunicationListSchema);
      Object.assign(state.models.communications, normalizedData.entities.communications);
      state.models.courses[catalogId].scheduledCommunicationIds = normalizedData.result;
    }

    const { dates: dateGrouped } = state.app.courseCommunications[catalogId].scheduledCommunications;

    const { count: totalCount, perPage } = action.payload;
    const hasMorePages: boolean = Math.ceil(totalCount / perPage) > page;

    // TODO : Not using normalized data for the following logic.
    const startTimeMoment = moment(startTime);
    let referenceDate: moment.Moment = startTimeMoment;
    state.app.courseCommunications[catalogId].scheduledCommunications.startTime = getDate(startTime);

    each(action.payload.communications, communication => {
      const scheduledAt = getDate(communication.scheduledAt);

      if (!dateGrouped[scheduledAt]) {
        dateGrouped[scheduledAt] = {};
      }

      if (!dateGrouped[scheduledAt].communicationIds) {
        dateGrouped[scheduledAt].communicationIds = [];
      }

      dateGrouped[scheduledAt].communicationIds = sortScheduledCommunicationIds(state, uniq([
        ...dateGrouped[scheduledAt].communicationIds,
        communication.id,
      ]));

      // Setup referenceDate.
      // If its PAST, then the very past one
      // If its FUTURE, then the very future one
      const scheduledAtMoment = moment(scheduledAt);
      if ((loadingType === ScheduledTimeLineLoadingType.PAST
          && scheduledAtMoment.isBefore(referenceDate))
        || (loadingType === ScheduledTimeLineLoadingType.FUTURE
          && scheduledAtMoment.isAfter(referenceDate))) {
        referenceDate = scheduledAtMoment;
      }
    });

    // Following logic is to fill the communicationsLoaded boolean, so that
    // only those need to be displayed on frontend
    let fillableDates: string[] = [];
    if (loadingType === ScheduledTimeLineLoadingType.PAST) {
      // Filter the dates before startTime
      const filteredDates = Object.keys(dateGrouped)
        .filter((date) => moment(date).isBefore(startTimeMoment)
          // Need to display only if there is communications
          && dateGrouped[date].communicationIds
          && dateGrouped[date].communicationIds.length > 0);

      if (referenceDate) {
        fillableDates = filteredDates.filter((date) => moment(date).isSameOrAfter(referenceDate));
      }

      // No more pages left
      if (!hasMorePages) {
        // For displaying the pending dates
        fillableDates = filteredDates;
      }
    } else if (loadingType === ScheduledTimeLineLoadingType.FUTURE) {
      // Filter the dates after startTime
      const filteredDates = Object.keys(dateGrouped)
        .filter((date) => moment(date).isSameOrAfter(startTimeMoment)
          // Need to display only if there is communications
          && dateGrouped[date].communicationIds
          && dateGrouped[date].communicationIds.length > 0);

      if (referenceDate) {
        fillableDates = filteredDates.filter((date) => moment(date).isSameOrBefore(referenceDate));
      }

      // No more pages left
      if (!hasMorePages) {
        // For displaying the pending dates
        fillableDates = filteredDates;
      }
    }

    // Now loop fillableDates and fill canBeShown
    each(fillableDates, (date) => { dateGrouped[date].canBeShown = showCommunications; });

    // Find and set what's left from the total count
    setCounts(state, loadingType, totalCount);

    state.app.courseCommunications[catalogId].scheduledCommunications.isLoaded = true;
    state.app.courseCommunications[catalogId].scheduledCommunications.isPastLoading = false;
    state.app.courseCommunications[catalogId].scheduledCommunications.isFutureLoading = false;
  });

  builder.addCase(getScheduledDueDates.fulfilled, (state, action) => {
    const normalizedData = normalize<ScheduledDueDatesNormalizedResult, ScheduledDueDatesEntities>(action.payload, ScheduledDueDatesSchema);

    const catalogId = state.app.currentCatalogId;
    const { dates: dateGrouped } = state.app.courseCommunications[catalogId].scheduledCommunications;

    // Fill lessons
    each(normalizedData.entities.lecturePages, (lecturePage, lecturePageId) => {
      if (lecturePage.releaseDate) {
        const dateKey = getDate(lecturePage.releaseDate.toString());

        if (!dateGrouped[dateKey]) {
          dateGrouped[dateKey] = {
            lessons: {},
          };
        }

        if (!dateGrouped[dateKey].lessons) {
          dateGrouped[dateKey].lessons = {};
        }

        dateGrouped[dateKey].lessons[lecturePageId] = lecturePage.title;
      }
    });

    // Fill activities
    each(Object.values(ActivityKey), activityKey => {
      each(normalizedData.entities[activityKey], (activity, activityId) => {
        if (activity.dueDate) {
          const dateKey = getDate(activity.dueDate.toString());

          if (!dateGrouped[dateKey]) {
            dateGrouped[dateKey] = {};
          }

          if (!dateGrouped[dateKey].activities) {
            dateGrouped[dateKey].activities = {};
          }

          // To keep a unique key for activity - ${activityKey}_${activityId}
          dateGrouped[dateKey].activities[`${activityKey}_${activityId}`] = activity.title;
        }
      });
    });

    // Just need to merge in the changes. No need to replace, as scheduled
    // list will only contain the partial data. Merging in so that any relevant
    // data won't be missed.
    Object.values(ownerKeys).forEach(key => {
      merge(state.models[key], normalizedData.entities[key]);
    });
    const course = state.models.courses[action.meta.arg.catalogId];
    course.exerciseIds = uniq([...course.exerciseIds ?? [], ...normalizedData.result.exercises ?? []]);
    course.exerciseSkillsRatingActivityIds = uniq([...course.exerciseSkillsRatingActivityIds ?? [], ...normalizedData.result.exerciseSkillsRatingActivities ?? []]);
    course.externalToolIds = uniq([...course.externalToolIds ?? [], ...normalizedData.result.externalTools ?? []]);
    course.groupTeamSetIds = uniq([...course.groupTeamSetIds ?? [], ...normalizedData.result.groupTeamSets ?? []]);
    course.lectureVideoIds = uniq([...course.lectureVideoIds ?? [], ...normalizedData.result.lectureVideos ?? []]);
    course.liveSessionIds = uniq([...course.liveSessionIds ?? [], ...normalizedData.result.liveSessions ?? []]);
    course.peerEvaluationIds = uniq([...course.peerEvaluationIds ?? [], ...normalizedData.result.peerEvaluations ?? []]);
    course.pollIds = uniq([...course.pollIds ?? [], ...normalizedData.result.polls ?? []]);
    course.postIds = uniq([...course.postIds ?? [], ...normalizedData.result.posts ?? []]);
    course.profileRequirementIds = uniq([...course.profileRequirementIds ?? [], ...normalizedData.result.profileRequirements ?? []]);
    course.quizIds = uniq([...course.quizIds ?? [], ...normalizedData.result.quizzes ?? []]);
    course.surveyIds = uniq([...course.surveyIds ?? [], ...normalizedData.result.surveys ?? []]);
    course.teamSetIds = uniq([...course.teamSetIds ?? [], ...normalizedData.result.teamSets ?? []]);
    course.timedQuizIds = uniq([...course.timedQuizIds ?? [], ...normalizedData.result.timedQuizzes ?? []]);
    course.videoIds = uniq([...course.videoIds ?? [], ...normalizedData.result.videos ?? []]);
  });

  builder.addCase(resetScheduledCommunicationsTimeline, (state, action) => {
    const catalogId = state.app.currentCatalogId;

    Object.assign(state.app.courseCommunications[catalogId].scheduledCommunications, {
      leftInPast: state.app.courseCommunications[catalogId].scheduledCommunications.totalInPast,
      dates: {},
    });
  });

  builder.addCase(hidePastScheduledCommunications, (state, action) => {
    const catalogId = state.app.currentCatalogId;
    const { startTime } = action.payload;

    const { dates: dateGrouped } = state.app.courseCommunications[catalogId].scheduledCommunications;

    each(
      Object.keys(dateGrouped).filter((date) => moment(date).isBefore(startTime)),
      (date) => {
        dateGrouped[date].canBeShown = false;
      },
    );
    state.app.courseCommunications[catalogId].scheduledCommunications.leftInPast = state.app.courseCommunications[catalogId].scheduledCommunications.totalInPast;
  });

  builder.addCase(getFilteredCommunications.pending, (state, action) => {
    const catalogId = state.app.currentCatalogId;
    const { filterBy } = action.meta.arg;

    state.app.courseCommunications[catalogId].filteredCommunications.isLoading = true;
    if (state.app.courseCommunications[catalogId].filteredCommunications.filterType !== filterBy) {
      state.app.courseCommunications[catalogId].filteredCommunicationIds = [];
    }
  });

  builder.addCase(getFilteredCommunications.fulfilled, (state, action) => {
    const catalogId = state.app.currentCatalogId;
    const params = action.meta.arg;
    const { count: totalCount, perPage } = action.payload;
    const remainingCount = totalCount - (perPage * params.page);

    if (action.payload?.communications) {
      const normalizedData = normalize<CommunicationsNormalized, FilteredCommunicationsEntities>(action.payload?.communications, CommunicationListSchema);
      const allFilteredIds = union(state.app.courseCommunications[catalogId].filteredCommunicationIds, normalizedData.result);

      Object.assign(state.models.communications, normalizedData.entities.communications);
      state.app.courseCommunications[catalogId].filteredCommunicationIds = allFilteredIds;
    }

    state.app.courseCommunications[catalogId].filteredCommunications.totalCount = totalCount;
    state.app.courseCommunications[catalogId].filteredCommunications.remainingCount = remainingCount > 0 ? remainingCount : 0;
    state.app.courseCommunications[catalogId].filteredCommunications.isLoading = false;
    state.app.courseCommunications[catalogId].filteredCommunications.filterType = params.filterBy;
    state.app.courseCommunications[catalogId].filteredCommunications.page = params.page;
  });

  builder.addCase(resetFilteredCommunications, (state) => {
    const catalogId = state.app.currentCatalogId;

    state.app.courseCommunications[catalogId].filteredCommunicationIds = [];
    Object.assign(state.app.courseCommunications[catalogId].filteredCommunications, {
      totalCount: 0,
      remainsCount: 0,
      isLoading: false,
      filterType: null,
      page: 1,
    });
  });

  builder.addCase(deleteCommunication.pending, (state, action) => {
    removeCommunication(state, action.meta.arg.communicationId, action.meta.arg.lecturePageId, action.meta.arg.communicationType);
  });

  // TODO: It seems unnecessary for us to do this same remove operation in both the pending and fulfilled states. We should
  // likely remove the pending case
  builder.addCase(deleteCommunication.fulfilled, (state, action) => {
    removeCommunication(state, action.meta.arg.communicationId, action.meta.arg.lecturePageId);
  });

  builder.addCase(getCourseWelcomeEmail.fulfilled, (state, action) => {
    const { catalogId, result } = action.payload as { catalogId, result };

    state.app.courseCommunications[catalogId].automatedCommunications.welcomeEmail = result;
    if (result.id) {
      state.models.courses[catalogId].welcomeEmailEnabled = true;
    }
  });

  builder.addCase(getCourseWelcomeEmail.rejected, (state, action) => {
    const catalogId = action.payload as string;
    state.models.courses[catalogId].welcomeEmailEnabled = false;
  });

  builder.addCase(disableWelcomeEmail.fulfilled, (state, action) => {
    const { catalogId } = action.payload;
    const { currentCatalogId } = state.app;
    const catalogIdToUse = catalogId ?? currentCatalogId;
    state.models.courses[catalogIdToUse].welcomeEmailEnabled = false;
  });

  /**
   * welcome email is enabled only if we get communication data for getCourseWelcomeEmail API
   */

  // builder.addCase(enableWelcomeEmail.fulfilled, (state, action) => {
  //   const { catalogId } = action.payload;
  //   const { currentCatalogId } = state.app;
  //   const catalogIdToUse = catalogId ?? currentCatalogId;
  //   state.models.courses[catalogIdToUse].welcomeEmailEnabled = true;
  // });
});

const toggleActivities = (state: RootState, lecturePageId: number, isShown: boolean, responseActivities?: { [key: string]: number[] }) => {
  const activities = responseActivities ?? getActivityIdsFromLecturePageId(state, lecturePageId);

  const courseComms = state.app.courseCommunications[state.app.currentCatalogId];
  Object.values(ownerKeys).forEach(key => {
    // Converting ids array to a object of booleans
    const newActivities = activities[key]?.reduce((prev: { [k: number]: boolean }, activityId: number) => {
      // Handle duplicating of communication (when target lecture page is different)
      // The activity in the duplicated communication is in expandedActivties
      // Also the showCommunications param will be false(will be true if the owner is lecturePage)
      // So check the existing expandedActivities before assigning the isShown value,
      // to the responsActivities

      // For hide action there won't be responseActivities,
      // So isShown value will be the expanded state
      const isActivityExpanded = responseActivities
        ? courseComms.automatedCommunications.expandedActivities[key]?.[activityId] : undefined;

      prev[activityId] = isActivityExpanded ?? isShown;
      return prev;
    }, {});

    const { expandedActivities } = courseComms.automatedCommunications;
    if (!(key in expandedActivities)) {
      expandedActivities[key] = {};
    }
    Object.assign(expandedActivities[key], newActivities);
  });
  courseComms.automatedCommunications.expandedActivities[LecturePageKey.LECTURE][lecturePageId] = isShown;
};

const removeCommunication = (state: RootState, communicationId: number, lecturePageId: number, communicationType?: CommunicationType) => {
  const catalogId = state.app.currentCatalogId;
  const lecturePagesState = state.models.lecturePages;

  if (lecturePageId) {
    // TODO: Not used anywhere. Remove?
    // const activities = getActivityIdsFromLecturePageId(state, lecturePageId);
    // Decrement communication count of respective activity
    // Filter out activities that have zero communications attached from activityCommunicationsCount
    const activityCommunicationsCount = filter(map(state.models.lecturePages[lecturePageId].activityCommunicationsCount, (item) => {
      if (state.models.communications[communicationId.toString()]
        && item.type === state.models.communications[communicationId.toString()]?.ownerType
        && item.id === state.models.communications[communicationId.toString()]?.ownerId) {
        item.count -= 1;
      }
      return item;
    }), (item) => item.count > 0);

    lecturePagesState[lecturePageId].activityCommunicationsCount = activityCommunicationsCount;
  }

  if (communicationType === CommunicationType.AUTOMATED_JOURNEY_EMAIL) {
    const newList = state.models.courses[catalogId].automatedCommunicationIds.filter(id => id !== communicationId);
    state.models.courses[catalogId].automatedCommunicationIds = newList;
  }
  // Remove from scheduled tab
  removeScheduledItem(state, communicationId);
  updateScheduledCounts(state, communicationId, true);
  state.models.courses[catalogId].draftCommunicationIds = without(state.models.courses[catalogId].draftCommunicationIds, communicationId);
  delete state.models.communications[communicationId.toString()];

  Object.values(ownerKeys).forEach(key => {
    mapObject(state.models[key], obj => {
      const newIds = without(obj.communications, communicationId);
      obj.communicationsCount = newIds.length;
      obj.communications = newIds;
    });
  });

  if (state.app.courseCommunications[catalogId].filteredCommunicationIds.includes(communicationId)) {
    state.app.courseCommunications[catalogId].filteredCommunicationIds = without(state.app.courseCommunications[catalogId].filteredCommunicationIds, communicationId);
    state.app.courseCommunications[catalogId].filteredCommunications.totalCount -= 1;
  }
};

/**
 * Removes communicationId from a date, and delete a date key if the values are empty
*/
const removeScheduledItem = (state: RootState, communicationId: number) => {
  const { dates: dateGrouped } = state.app.courseCommunications[state.app.currentCatalogId].scheduledCommunications;

  each(dateGrouped, (dateGroupedItem, date) => {
    if (dateGroupedItem.communicationIds) {
      dateGroupedItem.communicationIds = [...without(dateGroupedItem.communicationIds, communicationId)];

      // After removing, check if the properties of dateGrouped are empty
      // If so we don't have to show the day header, so delete the date object.
      const dateHasData = some(dateGroupedItem, (value) => isArray(value) && !isEmpty(value));
      if (!dateHasData) {
        delete dateGrouped[date];
      }
    }
  });
};

const insertToScheduledTab = (state: RootState, communication: Communication) => {
  const { dates: dateGrouped, leftInFuture } = state.app.courseCommunications[state.app.currentCatalogId].scheduledCommunications;
  const { scheduledAt, id } = communication;
  const scheduledAtDate = getDate(scheduledAt);
  if (!dateGrouped[scheduledAtDate]) {
    dateGrouped[scheduledAtDate] = {};
  }

  if (!dateGrouped[scheduledAtDate].communicationIds) {
    dateGrouped[scheduledAtDate].communicationIds = [];
  }

  dateGrouped[scheduledAtDate].communicationIds = sortScheduledCommunicationIds(state, uniq([...dateGrouped[scheduledAtDate].communicationIds, id]));

  // Find the last shown one from dateGroupd and check whether its greater than this.
  const mostFutureDate = reduce(
    // Find the dates which can be shown
    Object.keys(dateGrouped).filter((date) => dateGrouped[date].canBeShown),
    (futureDate, currentDate) => (moment(futureDate).isAfter(moment(currentDate)) ? futureDate : currentDate),
  );

  dateGrouped[scheduledAtDate].canBeShown = leftInFuture === 0 || moment(mostFutureDate).isSameOrAfter(moment(scheduledAtDate));
};

const setCounts = (state: RootState, loadingType: ScheduledTimeLineLoadingType, totalCount: number) => {
  const catalogId = state.app.currentCatalogId;
  const { dates: dateGrouped, startTime } = state.app.courseCommunications[catalogId].scheduledCommunications;
  const datesAll = {
    [ScheduledTimeLineLoadingType.PAST]: Object.keys(dateGrouped).filter((date) => moment(date).isBefore(moment(startTime))),
    [ScheduledTimeLineLoadingType.FUTURE]: Object.keys(dateGrouped).filter((date) => moment(date).isSameOrAfter(moment(startTime))),
  };
  // Find loaded communications count corresponding to the loaded type
  const loadedCount = reduce(datesAll[loadingType].filter((date) => dateGrouped[date].canBeShown),
    (count, date) => dateGrouped[date].communicationIds && dateGrouped[date].communicationIds.length + count, 0);

  const loadedDatesKey = loadingType === ScheduledTimeLineLoadingType.FUTURE ? 'Future' : 'Past';
  const difference = totalCount - loadedCount;

  // Updates counts of totalInFuture, leftInFuture, totalInPast, leftInPast with respect to the loadedDatesKey
  state.app.courseCommunications[catalogId].scheduledCommunications[`totalIn${loadedDatesKey}`] = totalCount;
  state.app.courseCommunications[catalogId].scheduledCommunications[`leftIn${loadedDatesKey}`] = difference > 0 ? difference : 0;
};

const updateScheduledCounts = (state: RootState, communicationId: number, isDelete: boolean = false) => {
  const { totalInFuture, totalInPast, startTime } = state.app.courseCommunications[state.app.currentCatalogId].scheduledCommunications;

  const communication = state.models.communications[communicationId];
  if (communication?.scheduledAt) {
    const loadingType = moment(communication.scheduledAt).isBefore(moment(startTime))
      ? ScheduledTimeLineLoadingType.PAST : ScheduledTimeLineLoadingType.FUTURE;
    const totalCountBefore = {
      [ScheduledTimeLineLoadingType.PAST]: totalInPast,
      [ScheduledTimeLineLoadingType.FUTURE]: totalInFuture,
    };
    const totalCountAfter = isDelete ? totalCountBefore[loadingType] - 1 : totalCountBefore[loadingType] + 1;

    setCounts(state, loadingType, totalCountAfter);
  }
};
// Sort scheduled communications with oldest first and newest last
const sortScheduledCommunicationIds = (state: RootState, communicationIds: number[]) => communicationIds.sort((a, b) => +moment(state.models.communications[a]?.scheduledAt) - +moment(state.models.communications[b]?.scheduledAt));
