import React from 'react';
import { useSelector } from 'react-redux';
import { BasicEnrollment, BasicMembership, BasicMentee, Course, InfiniteLoadingState } from 'redux/schemas/models/course';
import { useAppDispatch } from 'redux/store';
import { InfiniteLoadingParams } from 'redux/schemas/api/shared';
import useInfiniteScroll from './use-infinite-scroll';

type Params = InfiniteLoadingState & {
  items: BasicMentee[] | BasicEnrollment[] | BasicMembership[] | Course[];
  noItems: boolean;
};

/**
 * Custom hook to handle the logic and states for the paginated flyout implementation
 * @param stateAppItemsProp Name of the state app property to get the pagination states
 * @param stateModelsItemsProp Name of the state models property to get the fetched items
 * @param fetchItemsQueryParams Params to be passed to the fetchItemsFn
 * @param fetchItemsFn Redux action to fetch new items
 * @returns Params for the paginated flyout state
 */
export const useInfiniteLoading = (
  stateAppItemsProp: string,
  stateModelsItemsProp: string,
  fetchItemsQueryParams: InfiniteLoadingParams,
  fetchItemsFn: ({
    page,
    page_size,
    sort_by,
  }: InfiniteLoadingParams) => void,
): Params => {
  const dispatch = useAppDispatch();

  // Getting the pagination states from the state.app based on the 'stateAppItemsProp'
  const {
    [stateAppItemsProp]: {
      loading,
      loadingMore,
      firstPageLoaded,
      hasMore,
      ids,
      currentPage,
    },
  } = useSelector(state => state.app);

  // Getting the items from the state.models based on the 'stateModelsItemsProp'
  const items: BasicMentee[] | BasicEnrollment[] | Course[] = useSelector(
    state => state.models[stateModelsItemsProp],
  );

  // Identifying if there aren't items
  const noItems = (!items || Object.keys(items)?.length === 0) && firstPageLoaded;

  // Using infinite scroll to load new items
  const scrollingElementRef: HTMLElement = document.querySelector(
    '.flyout-content',
  );
  const isPageBottom = useInfiniteScroll(scrollingElementRef, 20);

  React.useEffect(() => {
    const shouldLoad = ((isPageBottom && hasMore) || (currentPage === 1 && !firstPageLoaded))
      && !loading
      && !loadingMore;

    if (shouldLoad) {
      dispatch(fetchItemsFn({ page: currentPage, ...fetchItemsQueryParams }));
    }
  }, [hasMore, loading, loadingMore, isPageBottom, scrollingElementRef, currentPage]);

  return {
    items,
    noItems,
    loading,
    loadingMore,
    firstPageLoaded,
    hasMore,
    ids,
    currentPage,
  };
};

export default useInfiniteLoading;
