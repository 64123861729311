import { Forum, Post, DiscussionTeam } from 'redux/schemas/models/post';
import { TimelineExercise } from 'redux/schemas/models/exercise';
import { User, MyAccountTeam } from 'redux/schemas/models//my-account';
import { JwPlayerVideo } from 'redux/schemas/models/video';
import { FileUpload, UploadedFile } from 'redux/schemas/models/file-uploads';
import { ViewOptions, ComponentType } from 'redux/schemas/models/lecture-component';
import { Submission } from 'redux/schemas/models/submissions';

export enum BookmarkType {
  INSTITUTION = 'Institution',
  COURSE = 'Course',
  LECTURE_PAGE = 'LecturePage',
  TOPIC = 'Topic',
  POST = 'Post',
  COMMENT = 'Comment',
  VIDEO = 'Video',
  AUDIO = 'Audio',
  IMAGE = 'Image',
  ATTACHMENT = 'Attachment',
  SUBMISSION = 'Submission',
}

export type BookmarkProps = {
  onCreateBookmark?: (e?: any) => void;
  onHighlightBookmark?: (e?: any) => void;
  bookmarkId?: number;
  bookmarkHidden?: boolean;
};

export interface BookmarkComponent {
  type: string;
  id: number;
  lecturePageName: string;
  courseName: string;
  expired: boolean;
}

export interface ImageBookmarkComponent extends BookmarkComponent {
  picture: FileUpload;
  type: BookmarkType.IMAGE;
  viewOptions: ViewOptions[ComponentType.IMAGE];
}

export interface MediaBookmarkComponent extends BookmarkComponent {
  thumbnailUrl: string;
  title: string;
  video: JwPlayerVideo;
}

export interface SubmissionBookmarkComponent extends BookmarkComponent {
  title: string;
  exercise: TimelineExercise;
  ownerType: 'User' | 'Team';
  owner: User | MyAccountTeam;
}

export interface DiscussionBookmarkComponent extends BookmarkComponent {
  body: string;
  title: string;
  user: User;
  commentableId?: number;
  topic?: Post;
  forum?: Forum;
  submission?: Submission;
  owner?: Submission;
  courseName: string;
  lecturePageName: string
}

export interface TeamDiscussionBookmarkComponent extends DiscussionBookmarkComponent {
  team?: DiscussionTeam;
}

export interface LecturePageBookmarkComponent extends BookmarkComponent {
  title: string;
  lecturePageName: string;
  courseName: string;
  subtitle?: string;
}

export interface AttachmentBookmarkComponent extends BookmarkComponent {
  name: string;
  file: UploadedFile
}
export interface GeneralBookmark {
  id: number;
  note?: string;
  updatedAt: string;
  type: BookmarkType;
  catalogId: string | null;
}
export interface CourseBookmark extends GeneralBookmark {
  catalogId: string;
}

export interface CourseObjectBookmark extends CourseBookmark {
  lecturePageId: number;
  lectureComponentId: number;
  component: BookmarkComponent;
}

export type Bookmark = GeneralBookmark | CourseBookmark | CourseObjectBookmark;

export interface BookmarksNormalized {
  [id: string]: Bookmark;
}


