/* @ngInject */
export default function nvNewObjectsAbove() {
  return {
    restrict: 'A',
    scope: {
      numNew: '=',
      clickCallback: '&?',
    },
    templateUrl: 'shared/templates/nv-new-objects-above.html',
  };
}
