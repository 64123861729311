import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { EdCastEndpoint, EdCastPayload } from 'redux/schemas/models/edcast';
import t from '../../react-translate';
import { addAlertMessage } from './alert-messages';

type HandlerType = {
  type: AlertMessageType,
  extra?: any,
};

enum SuccessMessage {
  ENABLE = 'SUCCESS_MESSAGE',
  UPDATE = 'SUCCESS_UPDATE_MESSAGE',
  DISABLE = 'SUCCESS_DISABLE_MESSAGE',
}

enum ErrorMessage {
  DEFAULT = 'ERROR_MESSAGE',
  LXP_ERROR = 'LXP_ERROR_MESSAGE',
  DEV_ERROR = 'DEV_ERROR_MESSAGE',
}

enum ErrorTypes {
  LXP_ERROR = 'error.edcast.lxp_credentials',
  DEV_ERROR = 'error.edcast.dev_credentials',
}

const getSuccessMessage = (extra) => {
  const { update, disable } = extra;
  let messageKey = SuccessMessage.ENABLE;
  if (update) messageKey = SuccessMessage.UPDATE;
  else if (disable) messageKey = SuccessMessage.DISABLE;
  return messageKey;
};

const getErrorMessage = (e) => {
  const error = e.response?.data?.errors;
  switch (error) {
    case ErrorTypes.LXP_ERROR: return ErrorMessage.LXP_ERROR;
    case ErrorTypes.DEV_ERROR: return ErrorMessage.DEV_ERROR;
    default: return ErrorMessage.DEFAULT;
  }
};

const ResponseHandler = {
  [AlertMessageType.SUCCESS]: { header: 'SUCCESS_HEADER', message: getSuccessMessage },
  [AlertMessageType.ERROR]: { header: 'ERROR_HEADER', message: getErrorMessage },
};

const requestEndpoint = (endpoint: EdCastEndpoint) => {
  const { institutionId, service, method = 'get', data = {} } = endpoint;
  const api = `institutions/${institutionId}/edcast/`;
  const url = `${api}${service}.json`;
  const requestMethod = axios[method];
  return requestMethod(url, data).then(response => response.data.result);
};

const configEdCast = (
  payload: EdCastPayload,
  update: boolean = false,
  disable: boolean = false,
  thunkAPI: any,
) => {
  const {
    institutionId,
    lxpCredentials,
    devCredentials,
  } = payload;
  const service = 'config_edcast';
  const method = update ? 'put' : 'post';
  const data = { lxpCredentials, devCredentials };
  const request = requestEndpoint({ service, method, institutionId, data });

  const handleResponse = (handler: HandlerType) => {
    const { type, extra } = handler;
    const labels = t.INSTITUTIONS.ADVANCED_SETTINGS.EDCAST.SETTINGS_MODAL;
    const keys = ResponseHandler[type];
    const header = labels[keys.header]();
    const message = labels[keys.message(extra)]();
    thunkAPI.dispatch(addAlertMessage({ type, header, message }));
  };

  return request.then((response) => {
    handleResponse({ type: AlertMessageType.SUCCESS, extra: { update, disable } });
    return response;
  }).catch((e) => {
    handleResponse({ type: AlertMessageType.ERROR, extra: e });
    throw (e.response?.data?.error);
  });
};

const enableEdCast = (payload: EdCastPayload, thunkAPI) => configEdCast(payload, false, false, thunkAPI);

const updateEdCast = (payload: EdCastPayload, thunkAPI) => configEdCast(payload, true, false, thunkAPI);

const disableEdCast = (payload: EdCastPayload, thunkAPI) => configEdCast(payload, false, true, thunkAPI);

export const enableEdCastIntegration = createAsyncThunk('ENABLE_EDCAST', enableEdCast);

export const updateEdCastIntegration = createAsyncThunk('UPDATE_EDCAST', updateEdCast);

export const disableEdCastIntegration = createAsyncThunk('UPDATE_EDCAST', disableEdCast);
