/* @ngInject */
export default function DirectLinkController(
  DiscussionsManager,
  CurrentUserManager,
  $stateParams,
  _,
  $state,
  CurrentCourseManager,
  $scope,
  MentionablesModel,
  $filter,
  $q,
) {
  const vm = this;
  const loadPostsDeferred = $q.defer();

  // This is identical to vm.postsForUIScroll in discussions-index-controller.js
  vm.postsForUIScroll = {
    get(index, count, success) {
      const lastIndex = index + count - 1;

      if (!vm.manager.currentPosts || vm.manager.currentPosts.length === 0) {
        return loadPostsDeferred.promise.then(() => {
          success(vm.manager.getUIScrollItems(index, count));
        });
      } if (lastIndex > vm.manager.lastScrollIndex && vm.manager.hasMorePostsToLoad) {
        return vm.manager.loadMorePosts().then(() => {
          success(vm.manager.getUIScrollItems(index, count));
        });
      }
      success(vm.manager.getUIScrollItems(index, count));

      return null;
    },
  };

  vm.currentUserManager = CurrentUserManager;
  vm.manager = DiscussionsManager;
  vm.$stateParams = $stateParams;
  vm.$filter = $filter;
  vm.pusherCourseId = CurrentCourseManager.course.id;

  $scope.$on('$destroy', () => {
    DiscussionsManager.unsubscribeFromPusherEvents(vm.pusherCourseId);
  });
}
