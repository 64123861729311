import React from 'react';

import { NvUploadedFileProps } from '.';
import NvUploadedFileCompactView from './nv-uploaded-file-compact-view';
import NvResponsiveBoxPreview from './nv-box-responsive-preview';

export const BOX_FILE_PREVIEW_SIZE_LIMIT = 10000000;

const NvBoxEmbed = ({ file }: Pick<NvUploadedFileProps, 'file'>) => (
  <React.Fragment>
    {file.size >= BOX_FILE_PREVIEW_SIZE_LIMIT ? (
      <NvUploadedFileCompactView file={file} />
    ) : (
      <React.Fragment>
        {file.boxDocumentId ? <NvResponsiveBoxPreview file={file} /> : (
          <NvUploadedFileCompactView
            file={file}
            openModalOnClick={file.boxUploadable}
          />
        )}
      </React.Fragment>
    )}
  </React.Fragment>
);

export default NvBoxEmbed;
