import React from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';

import { useSelector } from 'react-redux';
import { setLeftPanelNovoAIItemType } from 'redux/actions/lecture-pages';
import { useAppDispatch } from 'redux/store';
import { getCurrentLecture } from 'redux/selectors/lecture-page';

import {
  extraLargeSpacing, largeSpacing, quarterSpacing, standardSpacing,
} from 'styles/global_defaults/scaffolding';
import { black, gray1, gray2, gray4 } from 'styles/global_defaults/colors';

import ClickableContainer from 'components/clickable-container';
import { NovoAIItemType } from 'redux/schemas/models/lecture-page';
import {
  ReactComponent as SummaryIcon,
} from 'styles/icons/summary.svg';
import {
  ReactComponent as StarIcon,
} from 'styles/icons/star.svg';
import {
  ReactComponent as KeyTakeawaysIcon,
} from 'styles/icons/key-takeaways.svg';
import {
  ReactComponent as QuizIcon,
} from 'styles/icons/ai-quiz.svg';
import {
  ReactComponent as DiscussionIcon,
} from 'styles/icons/discussion.svg';
import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import humps from 'humps';
import { config } from '../../../../../../config/config.json';

const styles = (isDisabled) => css`
  &.item-wrapper {
    border-radius: ${quarterSpacing}px;
    background-color: transparent;
    transition: background-color 0.3s ease;
    padding: ${standardSpacing}px;
    display: inline-block;
  }

  ${!isDisabled && css`
    &.item-wrapper:hover {
      background-color: #f2fcfc;
      border-radius: ${quarterSpacing}px;
      display: inline-block;
      padding: ${standardSpacing}px;

      .title, p {
        color: ${black};
      }
    }
  `}

  &.selected {
    display: inline-block;
    border: double 2px transparent;
    border-radius: ${quarterSpacing}px;
    margin: -2px;
    border-image-slice: 2;
    background-color: #f2fcfc;
    border-image-source: linear-gradient(to right top, #60f3ab, #00ccbc, #009ac0);

    background-image: linear-gradient(#f2fcfc, #f2fcfc),
      linear-gradient(to right top, #60f3ab, #00ccbc, #009ac0);
    background-origin: border-box;
    background-clip: padding-box, border-box;

    .title, p {
      color: ${black};
    }
  }

  .item-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: stretch;
    align-items: center;
  }

  .title {
    font-feature-settings: 'clig' off, 'liga' off;
    color: ${isDisabled ? gray4 : gray1};
    flex: 1 0 0;
    margin: 0;
  }

  p {
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Open Sans";
    font-weight: 600;
    color: ${isDisabled ? gray4 : gray2};
  }

  .icon-component {
    padding: 0px 3px 0px;
  }

  .detail {
    display: flex;
    align-items: flex-start;
    padding-left: ${isDisabled ? largeSpacing : extraLargeSpacing}px;
    align-self: stretch;
  }

  .top-star {
    top: 12px;
    left: -3px;
  }

  .bottom-star {
    top: -${standardSpacing}px;
    left: 0px;
  }
`;

type ItemProps = {
  type: NovoAIItemType
};

const itemTypeIcons = {
  [NovoAIItemType.SUMMARY]: SummaryIcon,
  [NovoAIItemType.KEY_TAKEAWAYS]: KeyTakeawaysIcon,
  [NovoAIItemType.DISCUSSION]: DiscussionIcon,
  [NovoAIItemType.QUIZ]: QuizIcon,
};

const ICON_SIZE = 30;
const STAR_ICON_SIZE = 7;

const Item = (props: ItemProps) => {
  const { type } = props;

  const dispatch = useAppDispatch();
  const selectedItemType = useSelector(state => state.app.lecturePage.novoAI.itemType);
  const currentLecture = useSelector(getCurrentLecture);

  const IconComponent = itemTypeIcons[type];
  const isDisabled = type === NovoAIItemType.QUIZ && currentLecture?.released;

  if (!IconComponent) {
    return null;
  }

  const setItem = () => {
    dispatch(setLeftPanelNovoAIItemType(type));
  };

  return (
    <NvTooltip
      text={isDisabled ? t.LECTURE_PAGES.LHS.NOVO_AI.ITEM.DISABLED_TOOLTIP[type.toUpperCase()]() : ''}
      enabled={isDisabled}
      offset={-20}
    >
      <ClickableContainer
        css={styles(isDisabled)}
        className={`item-wrapper ${selectedItemType === type ? 'selected' : ''}`}
        onClick={setItem}
        data-qa={config.pendo.novoAi.item[humps.camelize(type)]}
        disabled={isDisabled}
      >
        <div className='item-title'>
          {isDisabled ? (
            <NvIcon className='text-gray-5' icon='read' size='small' />
          ) : (
            <React.Fragment>
              <StarIcon
                className='position-relative top-star'
                height={STAR_ICON_SIZE}
                width={STAR_ICON_SIZE}
              />
              <div className='icon-component'>
                <IconComponent
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                />
              </div>
              <StarIcon
                className='position-relative bottom-star'
                height={STAR_ICON_SIZE}
                width={STAR_ICON_SIZE}
              />
            </React.Fragment>
          )}
          <div className={`title text-large-regular bold ${isDisabled ? 'pl-2' : ''}`}>
            {t.LECTURE_PAGES.LHS.NOVO_AI.ITEM.TITLE[type.toUpperCase()]()}
          </div>
        </div>
        <div className='detail pt-1'>
          <p className='text-small'>
            {t.LECTURE_PAGES.LHS.NOVO_AI.ITEM.DESCRIPTION[type.toUpperCase()]()}
          </p>
        </div>
      </ClickableContainer>
    </NvTooltip>
  );
};

export default Item;
