/* @ngInject */
export default function InstitutionRolesCtrl(
  InstitutionsManager,
  $scope,
) {
  const vm = {
    tabs: [
      { name: 'INSTITUTIONS.ROLES.ORG_ADMINS', state: 'org-admins' },
      { name: 'INSTITUTIONS.ROLES.ORG_MENTORS',
        translateValues: { MentorsTitleAlias: InstitutionsManager.institution.roleAliases.mentor.pluralizedTitleized },
        state: 'org-mentors',
      },
      { name: 'INSTITUTIONS.ROLES.MANAGE_COURSE_ROLES', state: 'manage-course-roles' },
    ],
  };

  /**
   * Updating the mentor alias translate value in tabs,
   * when institution mentor alias value has changed.
   */
  $scope.$watch(
    () => InstitutionsManager.institution.roleAliases.mentor.pluralizedTitleized,
    (newValue, oldValue) => {
      vm.tabs[1].translateValues.MentorsTitleAlias = newValue;
    },
  );

  return vm;
}
