import mergeWith from 'lodash/mergeWith';
import transform from 'lodash/transform';
import cloneDeep from 'lodash/cloneDeep';
import { replaceArrays } from 'shared/lodash-utils';
import store, { cloneDeepSerializable } from 'redux/store';
import { ComponentType } from 'redux/schemas/models/lecture-component';
import { ExternalToolWebLinkTypes } from 'redux/schemas/models/external-tool';
import { getAvailablePracticeActivities } from '../../redux/actions/video-practice-feedback';
import {
  addUnpersistedComponent,
  removeUnpersistedComponent,
  addNewComponent,
  moveComponent,
} from '../../redux/actions/lecture-pages';

// eslint-disable-next-line max-params
/* @ngInject */
export default function LecturePageModelService(
  _,
  $q,
  $state,
  Upload,
  S3UploadFactory,
  S3NameSpaces,
  moment,
  config,
  $timeout,

  TimelinesManager,
  LecturePagesResources,
  LecturePageBaseModel,
  ContentTemplateBaseModel,

  HeaderLectureComponentModel,
  LineDividerLectureComponentModel,
  BlurbSideImageLectureComponentModel,
  BlurbBackgroundImageLectureComponentModel,
  BlurbTopImageLectureComponentModel,

  VideoListLectureComponentModel,
  AudioListLectureComponentModel,
  ExerciseLectureComponentModel,
  GroupFormationLectureComponentModel,
  TeamFormationLectureComponentModel,
  PublicPeerEvaluationLectureComponentModel,
  SubmissionsDiscoveryLectureComponentModel,
  AttachmentLectureComponentModel,
  AbstractDiscussionLectureComponentModel,
  PollLectureComponentModel,
  DiscussionLectureComponentModel,
  TeamDiscussionLectureComponentModel,
  QuizLectureComponentModel,
  TimedQuizLectureComponentModel,
  SurveyLectureComponentModel,
  RichTextLectureComponentModel,
  TextLectureComponentModel,
  EmbedLectureComponentModel,
  ScormLectureComponentModel,
  LTILectureComponentModel,
  LiveSessionLectureComponentModel,
  ImageLectureComponentModel,
  ProfileCompletionComponentModel,
  $translate,
  CurrentCourseManager,
  AccordionLectureComponentModel,
  StyledLinkLectureComponentModel,
  VideoPracticeLectureComponentModel,
  PeerEvaluationLectureComponentModel,
  PublicPracticeFeedbackCriteriaLectureComponentModel,
  MeetAndGreetLectureComponentModel,
) {
  const NAME_TO_MODELS = {
    HeaderLectureComponent: HeaderLectureComponentModel.create(ComponentType.HEADER_STYLE1),
    HeaderTabLectureComponent: HeaderLectureComponentModel.create(ComponentType.HEADER_STYLE2),
    HeaderImageLectureComponent: HeaderLectureComponentModel.create(ComponentType.HEADER_STYLE3),
    LineDividerLectureComponent: LineDividerLectureComponentModel,
    BlurbSideImageLectureComponent: BlurbSideImageLectureComponentModel,
    BlurbBackgroundImageLectureComponent: BlurbBackgroundImageLectureComponentModel,
    BlurbTopImageLectureComponent: BlurbTopImageLectureComponentModel,

    VideoListLectureComponent: VideoListLectureComponentModel,
    AudioListLectureComponent: AudioListLectureComponentModel,
    ExerciseLectureComponent: ExerciseLectureComponentModel,
    TeamFormationLectureComponent: TeamFormationLectureComponentModel,
    GroupFormationLectureComponent: GroupFormationLectureComponentModel,
    PublicPeerEvaluationLectureComponent: PeerEvaluationLectureComponentModel,
    PrivatePeerEvaluationLectureComponent: PeerEvaluationLectureComponentModel,
    SubmissionsDiscoveryLectureComponent: SubmissionsDiscoveryLectureComponentModel,
    AttachmentLectureComponent: AttachmentLectureComponentModel,
    AbstractDiscussionLectureComponent: AbstractDiscussionLectureComponentModel,
    PollLectureComponent: PollLectureComponentModel,
    DiscussionLectureComponent: DiscussionLectureComponentModel,
    TeamDiscussionLectureComponent: TeamDiscussionLectureComponentModel,
    QuizLectureComponent: QuizLectureComponentModel,
    TimedQuizLectureComponent: TimedQuizLectureComponentModel,
    SurveyLectureComponent: SurveyLectureComponentModel,
    RichTextLectureComponent: RichTextLectureComponentModel,
    LiveSessionLectureComponent: LiveSessionLectureComponentModel,
    TextLectureComponent: TextLectureComponentModel,

    ImageLectureComponent: ImageLectureComponentModel,
    AccordionLectureComponent: AccordionLectureComponentModel,
    StyledLinkLectureComponent: StyledLinkLectureComponentModel,
    ProfileCompletionLectureComponent: ProfileCompletionComponentModel,
    VideoPracticeLectureComponent: VideoPracticeLectureComponentModel,
    PeerEvaluationLectureComponent: PeerEvaluationLectureComponentModel,
    ExerciseSkillsRatingLectureComponent: PeerEvaluationLectureComponentModel,
    PublicPracticeFeedbackCriteriaLectureComponent: PublicPracticeFeedbackCriteriaLectureComponentModel,
    MeetAndGreetLectureComponent: MeetAndGreetLectureComponentModel,
    VideoPracticeSkillsRatingLectureComponent: PublicPracticeFeedbackCriteriaLectureComponentModel,
  };

  let moveCallbacks = [];

  const EXTERNAL_TOOL_TYPE_TO_MODEL = {
    [ExternalToolWebLinkTypes.WEB_EMBED]: EmbedLectureComponentModel.create(ExternalToolWebLinkTypes.WEB_EMBED),
    [ExternalToolWebLinkTypes.WEB_LINK]: EmbedLectureComponentModel.create(ExternalToolWebLinkTypes.WEB_LINK),
    scorm: ScormLectureComponentModel,
    lti: LTILectureComponentModel,
  };

  const TIMED_QUIZ_COMPONENT = 'TimedQuizLectureComponent';
  const PEER_EVALUATION_COMPONENT = 'PeerEvaluationLectureComponent';
  const SUBMISSIONS_DISCOVERY_COMPONENT = 'SubmissionsDiscoveryLectureComponent';

  class LecturePageModel extends LecturePageBaseModel {
    constructor(attributes) {
      super(_.extend({}, attributes));
      this.__preprocess();
    }

    __preprocess() {
      this.lectureComponents = _.compact(_.map(this.lectureComponents, (lectureComponent) => (this.__transformLectureComponent(lectureComponent))));
    }

    __transformLectureComponent(lectureComponent) {
      if (!lectureComponent) {
        return null;
      }

      let newComponent;

      if (lectureComponent.type === 'ExternalToolLectureComponent') {
        if (!lectureComponent.externalTool?.toolType) {
          newComponent = null;
        } else {
          newComponent = new EXTERNAL_TOOL_TYPE_TO_MODEL[lectureComponent.externalTool?.toolType](_.extend(lectureComponent, this.__defaultComponentAttributes));
        }
      } else if (NAME_TO_MODELS[lectureComponent.type]) {
        const componentType = (lectureComponent.type === 'RichTextLectureComponent' && lectureComponent.viewOptions?.styleOptions === 'simple') ? 'TextLectureComponent' : lectureComponent.type;
        newComponent = new NAME_TO_MODELS[componentType](_.extend(lectureComponent, this.__defaultComponentAttributes));
      }

      if (lectureComponent instanceof VideoListLectureComponentModel || lectureComponent instanceof AudioListLectureComponentModel) {
        newComponent.currentLectureVideo = _.first(newComponent.lectureVideos);
      }

      return newComponent;
    }

    shouldMarkRead() {
      return !this.viewed && !this.prerequisite;
    }

    markAsRead() {
      return LecturePagesResources.markAsRead({ catalogId: this.catalogId, id: this.id }, {}).$promise;
    }

    get __defaultComponentAttributes() {
      return {
        catalogId: this.catalogId,
        releaseDate: this.releaseDate,
        lecturePage: this,
      };
    }

    updateComponent(itemId, type, catalogId, lecturePageId) {
      LecturePageModel.getResource(catalogId, lecturePageId).then((newLecturePage) => {
        if (type === 'submissions') {
          const peerEvalIndex = _.findIndex(this.lectureComponents, (component) => component.type === PEER_EVALUATION_COMPONENT && component.peerEvaluation.isFeedbackPublic && component.peerEvaluation.itemId === itemId);

          const submissionsDiscoveryIndex = _.findIndex(this.lectureComponents, (component) => component.type === SUBMISSIONS_DISCOVERY_COMPONENT && component.requiredExercise.id === itemId);

          if (peerEvalIndex !== -1) {
            this.lectureComponents[peerEvalIndex] = new PublicPeerEvaluationLectureComponentModel(
              _.extend(newLecturePage.lectureComponents[peerEvalIndex], this.__defaultComponentAttributes),
            );
          }

          if (submissionsDiscoveryIndex !== -1) {
            this.lectureComponents[submissionsDiscoveryIndex] = new SubmissionsDiscoveryLectureComponentModel(
              _.extend(newLecturePage.lectureComponents[submissionsDiscoveryIndex], this.__defaultComponentAttributes),
            );
          }
        } else if (type === 'quiz') {
          /* Timed quiz */
          const quizIndex = _.findIndex(this.lectureComponents, (component) => component.type === TIMED_QUIZ_COMPONENT && component.quiz.id === itemId);

          if (quizIndex !== -1) {
            const timedQuizLectureComponent = this.lectureComponents[quizIndex];

            mergeWith(
              timedQuizLectureComponent,
              _.extend(newLecturePage.lectureComponents[quizIndex], this.__defaultComponentAttributes),
              replaceArrays,
            );

            timedQuizLectureComponent.__preprocess();
          }
        } else if (type === 'teamSet') {
          const assignmentLectureComponents = _.filter(this.lectureComponents, (component) => component instanceof ExerciseLectureComponentModel && component.exercise.teamSet?.id === itemId);

          _.each(assignmentLectureComponents, (currentAssignmentlectureComponent) => {
            const newAssignmentLectureComponent = _.findWhere(newLecturePage.lectureComponents, { id: currentAssignmentlectureComponent.id });
            currentAssignmentlectureComponent.exercise.collaborators = newAssignmentLectureComponent.exercise.collaborators;
            currentAssignmentlectureComponent.exercise.currentTeam = newAssignmentLectureComponent.exercise.currentTeam;
            currentAssignmentlectureComponent.exercise.assignmentTeam = newAssignmentLectureComponent.exercise.assignmentTeam;
            currentAssignmentlectureComponent.exercise.canStartNewSubmission = newAssignmentLectureComponent.exercise.canStartNewSubmission;
          });

          // Update Team/ Group Formation Lecture Component
          const TeamGroupFormationLectureComponents = _.filter(this.lectureComponents, (component) => component instanceof (TeamFormationLectureComponentModel || GroupFormationLectureComponentModel) && component.teamSet?.id === itemId);

          _.each(TeamGroupFormationLectureComponents, (currentLectureComponent) => {
            const newLectureComponent = _.findWhere(newLecturePage.lectureComponents, { id: currentLectureComponent.id });
            currentLectureComponent.teamSet.teams = newLectureComponent.teamSet.teams;
          });
        }
      });
    }

    updateComponentStatus(componentType, componentId, data) {
      _.each(this.lectureComponents, (component) => component.updateComponentStatus(componentType, componentId, data));
    }

    leaveTeam(teamId) {
      _.each(this.lectureComponents, (component) => component.leaveTeam && component.leaveTeam(teamId));
    }

    updateTeam(team) {
      _.each(this.lectureComponents, (component) => component.updateTeam && component.updateTeam(team));
    }

    editReport(lectureComponentId, reportId) {
      const lectureComponent = _.findWhere(this.lectureComponents, { id: lectureComponentId });
      if (lectureComponent) {
        lectureComponent.revise(reportId);
      }
    }

    markAddComponentIndex(index) {
      this.addComponentIndex = index;
    }

    // TODO: This is no longer used or relevant in the new React lecture page architecture. Current calls to this are no-ops
    addUnpersistedComponents(ComponentModelsAndExtras, nvCurrentPage, shownOnPage = true) {
      const newComponents = [];
      _.each(ComponentModelsAndExtras, (ComponentModelAndExtra) => {
        const extras = {};

        if (nvCurrentPage) {
          if (nvCurrentPage.getBrandColor()) {
            extras.leftBorderColor = nvCurrentPage.getBrandColor();
          }
        }

        const componentData = _.extend({
          catalogId: this.catalogId,
          releaseDate: this.releaseDate,
          lecturePage: this,
          index: this.addComponentIndex,
          isPristine: true,
        }, ComponentModelAndExtra.componentExtras);

        const newComponent = new ComponentModelAndExtra.ComponentModel(componentData, false, true, extras);
        if (shownOnPage) {
          this.lectureComponents.splice(this.addComponentIndex, 0, newComponent);
          this.updateLectureComponentIndexes();
          this.addComponentIndex += 1;

          store.dispatch(addUnpersistedComponent({
            index: newComponent.index,
            lecturePageId: this.id,
            unpersistedComponent: {
              ...cloneDeepSerializable(componentData),
              // Create a temporary unique ID for this temp component to be used
              // in tracking which components have rendered in react-lecture-components.tsx
              id: `uc${Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)}`,
            },
          }));
          // store.dispatch(loadLecturePage(this.getModelData()));
        } else {
          newComponent.notShownOnPage = true;
        }

        newComponents.push(newComponent);
      });
      return newComponents;
    }

    appendPersistedComponent(lectureComponent) {
      this.lectureComponents.push(this.__transformLectureComponent(lectureComponent));
      // store.dispatch(loadLecturePage(this.getModelData()));
    }

    updateLectureComponentIndexes() {
      _.each(this.lectureComponents, (lectureComponent, index) => {
        lectureComponent.index = index;
      });
    }

    newComponent(ComponentModel, nvCurrentPage) {
      let shownOnPage = false;

      if (ComponentModel.shownOnPageOnCreate) {
        shownOnPage = true; // this is to make first progress bars visible
      }
      return this.addUnpersistedComponents([{ ComponentModel }], nvCurrentPage, shownOnPage)[0];
    }

    saveNewComponent(component) {
      return component.saveDraft()
        .then((lectureComponent) => {
          if (component.notShownOnPage) {
            component.notShownOnPage = false;

            this.lectureComponents.splice(component.index, 0, component);
            this.updateLectureComponentIndexes();

            // See lecture-component-base-model.ts's save() for where these actions
            // occur when the component is shown on the page
            store.dispatch(removeUnpersistedComponent({
              index: lectureComponent.index,
              lecturePageId: this.id,
            }));

            store.dispatch(addNewComponent({
              lectureComponent: _.omit(cloneDeepSerializable(lectureComponent), 'lecturePage'),
              lecturePageId: this.id,
            }));

            // store.dispatch(loadLecturePage(this.getModelData()));
            store.dispatch(getAvailablePracticeActivities({ catalogId: this.catalogId }));
          }

          return lectureComponent;
        });
    }

    addComponent(ComponentModel, nvCurrentPage) {
      const [newComponent] = this.addUnpersistedComponents([{ ComponentModel }], nvCurrentPage);
      return newComponent.save();
    }

    afterComponentDelete(component) {
      const index = _.indexOf(this.lectureComponents, component);
      this.lectureComponents.splice(index, 1);

      if (component instanceof TeamFormationLectureComponentModel) {
        const exerciseWithTeam = this.lectureComponents.find(lc => lc.type === ComponentType.EXERCISE && lc.exercise.teamSet);

        if (exerciseWithTeam) {
          exerciseWithTeam.exercise.teamSet.formedByStudents = false;
        }
      }

      if (component instanceof ExerciseLectureComponentModel) {
        this.lectureComponents = _.filter(this.lectureComponents, (lc) => !lc.removeExercise(component.exercise));
      }

      this.updateLectureComponentIndexes();

      // Attempt to remove any unpersisted component that is at this index. If none exist this effectively does nothing
      store.dispatch(removeUnpersistedComponent({ index, lecturePageId: this.id }));
      // store.dispatch(loadLecturePage(this.getModelData()));
      store.dispatch(getAvailablePracticeActivities({ catalogId: this.catalogId }));

      this.updateLectureComponentIndexes();
    }

    copyComponentsOrder(componentIndex) {
      this.lectureComponentsCopy = this.lectureComponents.slice();
      this.lectureComponentsCopy.dirty = false;
    }

    moveUp(componentIndex) {
      this.lectureComponentsCopy.dirty = true;
      this.lectureComponentsCopy.splice(componentIndex - 1, 0, this.lectureComponentsCopy.splice(componentIndex, 1)[0]);
      store.dispatch(moveComponent({
        lecturePageId: this.id,
        componentIds: this.lectureComponentsCopy.map(lc => lc.id),
      }));

      $timeout(() => {
        moveCallbacks.forEach((cb) => cb(componentIndex, -1));
      });
    }

    moveDown(componentIndex) {
      this.lectureComponentsCopy.dirty = true;
      this.lectureComponentsCopy.splice(componentIndex + 1, 0, this.lectureComponentsCopy.splice(componentIndex, 1)[0]);
      store.dispatch(moveComponent({
        lecturePageId: this.id,
        componentIds: this.lectureComponentsCopy.map(lc => lc.id),
      }));

      $timeout(() => {
        moveCallbacks.forEach((cb) => cb(componentIndex, 1));
      });
    }

    /**
     * this is a hacky solution to get the vm.index in angularJs and componentIndex in react to be updated correctly in lecture page reorder move
     * after each move, all the components are notified and rerendered
     * in a clean solution, updates would be propogated from redux and top level down and the components would react accordingly
     * there is code that prevents this from easily occuring, so we have gone with this hacky solution for now
     */
    registerMoveCallback(currentCb) {
      moveCallbacks.push(currentCb);

      return () => {
        moveCallbacks = moveCallbacks.filter(cb => cb !== currentCb);
      };
    }

    saveOrder() {
      this.lectureComponents = this.lectureComponentsCopy;

      _.each(this.lectureComponents, (component, index) => {
        component.index = index;
      });

      const lectureComponentsPayload = _.map(this.lectureComponents, (component) => _.pick(component, 'id', 'index'));

      this.lectureComponentsCopy.dirty = false;

      return LecturePagesResources.updateComponentOrder({ catalogId: this.catalogId, id: this.id }, { lectureComponents: lectureComponentsPayload })
        .$promise.then(() => {
          TimelinesManager.updateLecturePage(this.id);
        });
    }

    saveBackgroundImage(file) {
      const deferred = $q.defer();

      S3UploadFactory.uploadToS3(file, S3NameSpaces.COVER_IMAGES)
        .then((resp) => {
          const s3FileData = resp.config.data.file;
          this.coverImage = {
            fileName: s3FileData.name,
            fileSize: s3FileData.size,
            fileType: s3FileData.type,
            uniqueId: s3FileData.uniqueId,
          };

          delete this.viewOptions.backgroundColor;

          this.saveBasics(false).then((serverLecturePage) => {
            if (this.coverImageUrl === this.cardViewImageUrl) {
              this.cardViewImageUrl = serverLecturePage.coverImageUrl;
            }
            this.coverImageUrl = serverLecturePage.coverImageUrl;
            deferred.resolve(this);
          });
        });

      return deferred.promise.then((response) => {
        TimelinesManager.updateLecturePageAttribute(this.id, 'cardViewImageUrl', this.cardViewImageUrl);

        return response;
      });
    }

    toggleHeader() {
      this.showTitle = !this.showTitle;
      return this.saveBasics();
    }

    updateReleaseDate(newReleaseDate) {
      this.releaseDate = newReleaseDate;
      this.released = moment(newReleaseDate) < moment();

      return this.saveBasics()
        .then(() => {
          TimelinesManager.updateLecturePage(this.id);
        });
    }

    hasDateConflict() {
      return this.hasDeadlineBeforeReleaseDate(this.releaseDate);
    }

    hasDeadlineBeforeReleaseDate(newReleaseDate) {
      const newReleaseDateMoment = moment(newReleaseDate);

      return _.any(this.lectureComponents, (lectureComponent) => lectureComponent.deadline && moment(lectureComponent.deadline) < newReleaseDateMoment);
    }

    updateTimeEstimate() {
      return LecturePagesResources.updateTimeEstimate({ catalogId: this.catalogId, id: this.id },
        { estimates: this.estimation })
        .$promise.then((response) => {
          TimelinesManager.updateLecturePage(this.id);

          return response.result;
        });
    }

    whiteMask() {
      return _.contains(['#000', '#0000'], this.viewOptions.titleColor);
    }

    uncopiableLectureComponents() {
      const relevantLectureComponents = _.filter(this.lectureComponents, (lc) => !lc.canBeCopied);
      return _.map(relevantLectureComponents, (lc) => $translate.instant(lc.descriptionKey, CurrentCourseManager.course.getAliases()));
    }

    checkScormStatus() {
      if (_.where(this.lectureComponents, { type: 'ExternalToolLectureComponent' }).length > 0) {
        LecturePagesResources.checkScormStatus({ catalogId: this.catalogId, id: this.id }, '');
      }
    }

    updateReport(report) {
      return _.each(this.lectureComponents, (lc) => {
        if (lc.updateReport) {
          lc.updateReport(report);
        }
      });
    }

    updateReportSection(report, reportSectionId) {
      // Find the given report from lectureComponents and update it
      return _.each(this.lectureComponents, (lc) => {
        if (lc.exercise
          && lc.exercise.id === report.exerciseId
          && lc.updateReportSection) {
          lc.updateReportSection(report, reportSectionId);
        }
      });
    }

    /** Retrieves the model data for this lecture page by omitting the Angularjs
     * services and other properties that should not be stored in our Redux.js data
     * models */
    getModelData() {
      const modelData = cloneDeep(_.pick(_.omit(this, '__originalLpData', 'lectureComponents', 'lectureComponentsCopy'), (value, key) => !_.isFunction(value)));
      modelData.lectureComponents = {};

      this.lectureComponents.forEach((lc, i) => {
        modelData.lectureComponents[i] = cloneDeepSerializable(
          transform(
            _.omit(lc, 'lecturePage'),
            (acc, curr, key) => {
              if (key === 'lectureVideos') {
                // Removing videoList property from each lecture video of the
                // video list lecture component because it's a self reference to
                // itself, that makes cloneDeepSerializable function think that
                // the property value (in this case skillTags) has already been
                // added and omit them when should not.
                acc[key] = curr.map((lectureVideo) => _.omit(lectureVideo, 'videoList'));
              } else {
                acc[key] = curr;
              }
            },
            {},
          ),
        );

        modelData.lectureComponents[i].lecturePage = _.omit(modelData, 'lectureComponents');
      });

      return modelData;
    }
  }

  LecturePageModel.get = (catalogId, id) => LecturePagesResources.get({ catalogId, id }).$promise.then((response) => new LecturePageModel(_.extend(response.result, { catalogId })));

  LecturePageModel.getResource = (catalogId, id) => LecturePagesResources.get({ catalogId, id }).$promise.then((response) => response.result);

  LecturePageModel.createLecturePage = (catalogId, referenceLecturePageId = null) => {
    const lecturePage = {
      lecturePageIdToCreateUnder: referenceLecturePageId,
      releaseDate: moment().add(1, 'months').endOf('day').toISOString(),
    };
    return LecturePagesResources.save({ catalogId }, { lecturePage }).$promise.then((response) => {
      const responseLecturePage = new LecturePageModel(_.extend(response.result, { catalogId }));
      TimelinesManager.addLecturePage(responseLecturePage.lectureSectionId, responseLecturePage.id);
      return responseLecturePage;
    });
  };

  LecturePageModel.getResourceAndPromise = (catalogId, id, editMode) => {
    const resourceCall = LecturePagesResources.get({ catalogId, id, editMode });

    return {
      resource: resourceCall,
      promise: resourceCall.$promise.then((response) => {
        if (response.result) {
          response.result.__originalLpData = JSON.stringify(response.result);
        }
        return new LecturePageModel(_.extend(response.result, { catalogId }));
      }),
    };
  };

  LecturePageModel.LHSComponents = [{
    components: [
      {
        iconClass: 'icon-content',
        descriptionKey: 'LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION',
        type: 'CONTENTS_CONTAINER',
        pendoTagName: config.pendo.lectureEdit.contents,
        isToolTipEnabled: true,
        toolTipKey: 'LECTURE_PAGES.COMPONENTS.CONTENTS.CONTENTS_TOOLTIP',
      },
      VideoListLectureComponentModel,
      AudioListLectureComponentModel,
    ],
  //     AccordionLectureComponentModel.componentFactory('more_less'),
  //     AccordionLectureComponentModel.componentFactory('more_less_circled'),
  //     AccordionLectureComponentModel.componentFactory('decimal'),
  //     RichTextLectureComponentModel,
  //     ImageLectureComponentModel,
  //     {
  //       iconClass: 'icon-admin-template',
  //       descriptionKey: 'LECTURE_PAGES.COMPONENTS.TEMPLATES.DESCRIPTION',
  //       type: 'TEMPLATES_CONTAINER',
  //       pendoTagName: config.pendo.lectureEdit.templates,
  //     },
  //   ],
  // }, {
  //   headerKey: 'LECTURE_PAGES.LHS.COURSE_MATERIAL',
  //   components: [VideoListLectureComponentModel, AudioListLectureComponentModel, AttachmentLectureComponentModel],
  }, {
    headerKey: 'LECTURE_PAGES.LHS.PRACTICE_AND_APPLICATION',
    components: [ExerciseLectureComponentModel, VideoPracticeLectureComponentModel],
  }, {
    headerKey: 'LECTURE_PAGES.LHS.SOCIAL_ACTIVITIES',
    components: [
      ProfileCompletionComponentModel,
      TeamFormationLectureComponentModel,
      GroupFormationLectureComponentModel,
      SubmissionsDiscoveryLectureComponentModel,
      AbstractDiscussionLectureComponentModel,
      PollLectureComponentModel,
    ],
  }, {
    headerKey: 'LECTURE_PAGES.LHS.FEEDBACK_ACTIVITIES',
    components: [PeerEvaluationLectureComponentModel, PublicPracticeFeedbackCriteriaLectureComponentModel],
  }, {
    headerKey: 'LECTURE_PAGES.LHS.QUIZ_AND_SURVEY',
    components: [QuizLectureComponentModel, TimedQuizLectureComponentModel, SurveyLectureComponentModel],
  }, {
    headerKey: 'LECTURE_PAGES.LHS.THIRD_PARTY_LINKS_AND_TOOLS',
    components: [
      EmbedLectureComponentModel.create(ExternalToolWebLinkTypes.WEB_EMBED),
      EmbedLectureComponentModel.create(ExternalToolWebLinkTypes.WEB_LINK),
      ScormLectureComponentModel,
      LTILectureComponentModel,
      LiveSessionLectureComponentModel,
    ],
  }];

  LecturePageModel.LHSTemplates = [
    TextLectureComponentModel,
    ImageLectureComponentModel,
    AttachmentLectureComponentModel,
    {
      isAccordionListing: true,
      isOpen: false,
      titleKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.ACCORDION_LABEL.HEADER',
      toolTipExpandEKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.HEADER_LIST',
      toolTipCollapseKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.LIST_COLLAPSE',
      componentClass: 'title-head',
      components: [
        HeaderLectureComponentModel.create(ComponentType.HEADER_STYLE1),
        HeaderLectureComponentModel.create(ComponentType.HEADER_STYLE2),
        HeaderLectureComponentModel.create(ComponentType.HEADER_STYLE3),
      ],
    },
    {
      isAccordionListing: true,
      isOpen: false,
      sampleImageUrl: ContentTemplateBaseModel.SAMPLE_IMAGE_URL,
      titleKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.ACCORDION_LABEL.BLURB',
      toolTipExpandEKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.BLURB_IMAGE_LIST',
      toolTipCollapseKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.LIST_COLLAPSE',
      components: [
        BlurbSideImageLectureComponentModel,
        BlurbBackgroundImageLectureComponentModel,
        BlurbTopImageLectureComponentModel,
      ],
    },
    {
      isAccordionListing: true,
      isOpen: false,
      titleKey: 'LECTURE_PAGES.COMPONENTS.ACCORDION.DESCRIPTION',
      iconClass: 'icon-add',
      toolTipExpandEKey: 'LECTURE_PAGES.COMPONENTS.ACCORDION.TOOLTIP.LIST_EXPAND',
      toolTipCollapseKey: 'LECTURE_PAGES.COMPONENTS.ACCORDION.TOOLTIP.LIST_COLLAPSE',
      reactComponents: [
        AccordionLectureComponentModel.componentFactory('more_less'),
        AccordionLectureComponentModel.componentFactory('more_less_circled'),
        AccordionLectureComponentModel.componentFactory('decimal'),
        AccordionLectureComponentModel.componentFactory('alphabetical'),
        AccordionLectureComponentModel.componentFactory('custom_symbol'),
      ],
    },
    // {
    //   isAccordionListing: true,
    //   isOpen: false,
    //   titleKey: 'LECTURE_PAGES.COMPONENTS.STYLED_LINK.DESCRIPTION',
    //   iconClass: 'icon-format-makelink',
    //   toolTipExpandEKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.STYLED_LINK_LIST',
    //   toolTipCollapseKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.LIST_COLLAPSE',
    //   reactComponents: [
    //     StyledLinkLectureComponentModel.componentFactory('button'),
    //     StyledLinkLectureComponentModel.componentFactory('card'),
    //   ],
    // },
    LineDividerLectureComponentModel,
    RichTextLectureComponentModel,
  ];

  LecturePageModel.NAME_TO_MODELS = NAME_TO_MODELS;

  return LecturePageModel;
}
