import { useContext, useEffect, useState } from 'react';
import { AngularContext, AngularServicesContext } from 'react-app';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import SectionContent from '../section-content';
import { DisableProps } from './integrations';
import { config } from '../../../../../config/pendo.config.json';

const MsTeamsBotIntegration = (props: {
  onDisable: (props: DisableProps) => void;
}) => {
  const { $uibModal } = useContext(AngularServicesContext);
  const { injectServices, $scope } = useContext(AngularContext);
  const [InstitutionsManager] = injectServices(['InstitutionsManager']);
  const [hasIntegration, setHasIntegration] = useState(
    $scope.$eval(
      'vm.InstitutionsManager.institution.hasMicrosoftBotIntegration',
    ),
  );
  const [isRequesting, setIsRequesting] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    $scope.$watch('vm.InstitutionsManager.institution.hasMicrosoftBotIntegration', (newValue) => {
      setHasIntegration(newValue);
    });
  }, [$scope]);
  const enableMsTeamsBot = () => {
    setIsRequesting(true);
    InstitutionsManager.toggleMsTeamsBotIntegrations(true)
      .then(response => {
        setIsRequesting(false);
        if (response) {
          InstitutionsManager.institution.updateFromReact({
            hasMicrosoftBotIntegration: true,
          });
          dispatch(
            addAlertMessage({
              type: AlertMessageType.SUCCESS,
              header: t.FORM.SUCCESS_BANG(),
              message: t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_TEAMS_BOT_INTEGRATION.ENABLE.SUCCESS(),
            }),
          );
        }
      })
      .catch(({ data: { error } }) => {
        setIsRequesting(false);
        addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.FORM.SOMETHING_WRONG(),
        });
      });
  };

  const disableMsTeamsBot = () => {
    setIsRequesting(true);
    props.onDisable({
      action: () => InstitutionsManager.toggleMsTeamsBotIntegrations(false),
      onSuccess: () => {
        InstitutionsManager.institution.updateFromReact({
          hasMicrosoftBotIntegration: false,
        }); setIsRequesting(false);
      },
      successMessage: t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_TEAMS_BOT_INTEGRATION.DISABLE.SUCCESS(),
      title: t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_TEAMS_BOT_INTEGRATION.DISABLE.TITLE(),
      bodyText: t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_TEAMS_BOT_INTEGRATION.DISABLE.DESCRIPTION(),
      confirmDataQa: config.pendo.settings.msTeamsBot.disableConfirm,
      cancelDataQa: config.pendo.settings.msTeamsBot.disableCancel,
    });
  };

  return (
    <SectionContent
      header={t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_TEAMS_BOT_INTEGRATION.SECTION_TITLE()}
      description={[t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_TEAMS_BOT_INTEGRATION.DESCRIPTION()]}
      showCTAButton
      ctaText={hasIntegration
        ? t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_TEAMS_BOT_INTEGRATION.DISABLE.BUTTON()
        : t.INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_TEAMS_BOT_INTEGRATION.ENABLE.BUTTON()}
      onAction={() => (hasIntegration ? disableMsTeamsBot() : enableMsTeamsBot())}
      buttonVariant={hasIntegration ? 'secondary' : 'primary'}
      dataQa={config.pendo.settings.msTeamsBot.cta}
      disabled={isRequesting}
    />
  );
};

export default MsTeamsBotIntegration;
