import React from 'react';
import CourseTimeline, { CourseTimelineContext } from './course-timeline';

const CourseHomeTimelineContent = () => (
  <CourseTimelineContext.Provider
    value={{
      showOutline: true,
      isFullTimeline: true,
    }}
  >
    <CourseTimeline />
  </CourseTimelineContext.Provider>
);

export default CourseHomeTimelineContent;
