/* @ngInject */
export default function TeamRecentPostsCtrl(
  TeamWorkspaceManager,
  $stateParams,
  $scope,
) {
  class TeamRecentPostsController {
    constructor() {
      const { catalogId } = $stateParams;
      const { teamId, postId, commentId, replyId } = $scope;

      this.TeamWorkspaceManager = TeamWorkspaceManager;

      if (postId) {
        this.TeamWorkspaceManager.setContext('workspaceDirectLink');
        this.TeamWorkspaceManager.requestTeam(catalogId, teamId).then(() => {
          this.TeamWorkspaceManager.fetchSingleWorkspacePost(postId, true);
          this.directLinkInfo = { catalogId, commentId, postId, replyId, teamId };
        });
      } else {
        this.TeamWorkspaceManager.setContext('workspace');
        this.TeamWorkspaceManager.requestTeam(catalogId, teamId).then(() => {
          this.TeamWorkspaceManager.fetchAllWorkspacePosts();
        });
      }
    }
  }

  return new TeamRecentPostsController();
}
