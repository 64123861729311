import { css } from '@emotion/react';
import React, {
  useContext,
  useEffect,
} from 'react';
import moment from 'moment';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

// Contexts
import { CommunicationDispatch, CommunicationAction, TriggerType } from 'communications/course_communications/contexts/communication-context';

// Contexts
import { CommunicationFormContext, UseCommunicationMethods, FormSubmittedState } from 'communications/course_communications/hooks/use-communication-form';

// Schemas
import { CommunicationType, ItemState, ViewMode } from 'redux/schemas/models/course-communication';

// Selectors
import { getCourseAliases } from 'redux/selectors/course';
import { getActivity, getItemState } from 'redux/selectors/course-communications';
import { getPrefixedTitle } from 'redux/selectors/activity';

// Styles
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { primary, gray4, gray6 } from 'styles/global_defaults/colors';

// Components
import SendOn from 'communications/course_communications/components/communication-modal/send_on/send-on';
import SendTo from 'communications/course_communications/components/communication-modal/send-to';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import { NvFroala } from 'froala/components/nv-froala';
import { FroalaViewMode, UploadType, RelatedActivity } from 'froala/helpers/nv-froala-constants';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import ExtraTermsInfo from 'communications/course_communications/components/communication-modal/extra-terms-info';
import Announcer from './announcer';
import { config } from '../../../../../../config/config.json';

const styles = (isPreviewEnabled: boolean, viewMode: ViewMode) => css`
  &.announcement-form {
    display: ${viewMode === ViewMode.FORM_VIEW ? 'block' : 'none'};
  }

  .title-wrapper {
    padding: ${standardSpacing}px 0;
    border-top: 2px solid black;
    border-bottom: 1px solid ${gray4};
    display: flex;
    justify-content: space-between;

    .title {
      display: flex
    }
  }

  .announcement-wrapper {
    display: flex;

    .announcer{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .froala-novoed-menu {
      margin-left: ${standardSpacing}px;
    }
  }
  .notification-header {
    border-top: 2px solid ${gray6};
    border-bottom: 1px solid ${gray6};
    display: flex;
    justify-content: space-between;

  }
  .preview {
    color: ${isPreviewEnabled ? primary : gray4};
    cursor: ${isPreviewEnabled ? 'pointer' : 'auto'};
    display: flex;
    align-items: center;
  }

  .disabled-preview {
    color: ${gray4};
    pointer-events: none;
  }
`;

const AnnouncementForm = () => {
  const { State, dispatch } = useContext(CommunicationDispatch);
  const aliases = useSelector((state) => getCourseAliases(state));
  const activity: any = useSelector((state) => getActivity(state, State.activityType, State.activityId));
  const prefixedTitle = useSelector((state) => getPrefixedTitle(
    state,
    State.activityType,
    State.activityId,
    aliases,
  ));
  const itemState = useSelector((state) => getItemState(state, State.communicationId));

  const { getCommunicationDraft, formSubmitState, updateFormData } = useContext<UseCommunicationMethods>(CommunicationFormContext);
  const { watch } = useFormContext();

  let sendOnDetailsText: string;
  let referenceDate: string;
  if (State.communicationType === CommunicationType.DUE_DATE_EMAIL
    || State.communicationType === CommunicationType.DUE_DATE_ANNOUNCEMENT) {
    referenceDate = activity.dueDate;
    sendOnDetailsText = t.COURSE_COMMUNICATIONS.SEND_ON.DUE_DATE.LABEL(moment(activity.dueDate).format('LLL'), prefixedTitle);
  } else if (State.communicationType === CommunicationType.RELEASE_DATE_EMAIL
    || State.communicationType === CommunicationType.RELEASE_DATE_ANNOUNCEMENT) {
    referenceDate = activity.releaseDate;
    sendOnDetailsText = t.COURSE_COMMUNICATIONS.SEND_ON.RELEASE_DATE.LABEL(moment(activity.releaseDate).format('LLL'), activity.title);
  }

  const communicationDraft = getCommunicationDraft();
  const [
    emailBody, emailSubject, hasEmail, scheduledFor, scheduledOn, adminUserId, xDays,
  ] = watch([
    'emailBody', 'emailSubject', 'hasEmail', 'scheduledFor',
    'scheduledOn', 'adminUserId', 'xDays',
  ]);
  const isPreviewEnabled = !!(emailSubject && emailBody) && hasEmail;
  const isPreviewPushNotificationEnabled = !!(emailSubject);

  // The configuration of a sent announcement cannot be edited
  const isEditAnnouncementOnly = itemState === ItemState.SENT && State.triggerType === TriggerType.EDIT;

  const relatedActivitiesForRteTags: RelatedActivity[] = [
    ...(communicationDraft?.ownerType
      ? [{ id: communicationDraft.ownerId, type: communicationDraft.ownerType }] : []),
    ...(communicationDraft && communicationDraft?.event?.hasCompleted
      ? [{ ...communicationDraft.event.hasCompleted }] : []),
    ...(communicationDraft && communicationDraft?.filters?.hasCompleted
      ? [{ ...communicationDraft.filters.hasCompleted }] : []),
    ...(communicationDraft && communicationDraft?.filters?.hasNotCompleted
      ? [{ ...communicationDraft.filters.hasNotCompleted }] : []),
  ];

  useEffect(() => {
    if (formSubmitState === FormSubmittedState.SUBMITTED) {
      dispatch({ type: CommunicationAction.CLOSE_MODAL });
    }
  }, [formSubmitState, dispatch]);

  useEffect(() => {
    updateFormData({
      emailBody,
      emailSubject,
      hasEmail,
      scheduledFor,
      scheduledOn,
      adminUserId,
      xDays,
    });
  }, [
    emailBody, emailSubject, hasEmail, scheduledFor,
    updateFormData, scheduledOn, adminUserId, xDays,
  ]);

  const changeViewMode = (viewMode) => {
    dispatch({ type: CommunicationAction.SET_VIEW_MODE, viewMode });
  };

  return (
    <div className='announcement-form' css={styles(isPreviewEnabled, State.viewMode)}>
      <SendTo />
      <SendOn
        details={sendOnDetailsText}
        referenceDate={referenceDate}
      />
      <div className='mt-5 mb-4'>
        <div className='title-wrapper'>
          <div className='title'>
            <div className='page-title-small font-weight-bolder pr-4'>{t.COURSE_COMMUNICATIONS.ANNOUNCEMENT.FORM.ANNOUNCEMENT()}</div>
            <NvCheckbox
              name='hasEmail'
              label={t.COURSE_COMMUNICATIONS.ANNOUNCEMENT.FORM.HAS_EMAIL()}
              labelClassName='text-regular'
              withForm
              disabled={isEditAnnouncementOnly}
            />
          </div>
          {hasEmail && !isEditAnnouncementOnly && (
            <div
              className='text-small semi-bold preview'
              onClick={() => isPreviewEnabled && changeViewMode(ViewMode.EMAIL_PREVIEW)}
            >
              {t.COURSE_COMMUNICATIONS.ANNOUNCEMENT.FORM.PREVIEW_EMAIL()}
            </div>
          )}
        </div>
        <div className='announcement-wrapper'>
          <Announcer isDisabled={isEditAnnouncementOnly} />
          <div className='pl-4 w-100'>
            <div className='my-4'>
              <NvTextInput
                name='emailSubject'
                autoComplete='off'
                inputClassName='text-medium'
                placeholder={t.COURSE_COMMUNICATIONS.ANNOUNCEMENT.FORM.ANNOUNCEMENT_TITLE()}
                required
                withForm
              />
            </div>
            <div className=''>
              <NvFroala
                preset={FroalaViewMode.NORMAL}
                withCodeView
                withInsertTag
                name='emailBody'
                placeholder={t.COURSE_COMMUNICATIONS.ANNOUNCEMENT.FORM.ANNOUNCEMENT_BODY()}
                uploadType={UploadType.IMAGE_ONLY}
                relatedActivities={relatedActivitiesForRteTags}
                withForm
                fileExtensions={config.files.rte.images.common}
              />
            </div>
          </div>
        </div>
        <div className='notification-header py-4 my-5'>
          <div className='italic light'>
            {t.COURSE_COMMUNICATIONS.ANNOUNCEMENT.FORM.PUSH_NOTIFICATION_LABEL()}
          </div>
          <a
            className={`text-small semi-bold ${isPreviewPushNotificationEnabled ? 'text-primary' : 'disabled-preview'}`}
            onClick={() => changeViewMode(ViewMode.NOTIFICATION_PREVIEW)}
          >
            {t.COURSE_COMMUNICATIONS.ANNOUNCEMENT.FORM.PUSH_NOTIFICATION_PREVIEW()}
          </a>
        </div>
        <ExtraTermsInfo />
      </div>
    </div>
  );
};

export default AnnouncementForm;
