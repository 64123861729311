/* eslint-disable react/require-default-props */
import t from 'react-translate';
import { css } from '@emotion/react';

import { black, gray1, primary, success } from 'styles/global_defaults/colors';
import { ActivityProgress } from 'redux/schemas/models/activity';
import { semiBoldFontWeight, textSmallFontSize, textSmallLineHeight } from 'styles/global_defaults/fonts';


type Props = {
  status: ActivityProgress,
  className?: string,
  statusesTexts?: Partial<Record<ActivityProgress, string>>,
  statusesColors?: Partial<Record<ActivityProgress, string>>,
};

/**
 * This component is part of lecture component activities UI, it is meant to
 * display the lecture component activity status title.
 * This component currently has default status values which are:
 * "none" => Displays "none" status corresponding text, in english is "Submit"
 * with gray1 text color
 * "in_progress" => Displays "in_progress" status corresponding text, in english
 * is "In progress" with primary text color
 * "done" => Displays "done" status corresponding text, in english is "Done"
 * with success text color
 * NOTE: This component allows you to pass statusesTexts and statusesColors
 * props in case you want to have different text and color in a certain status
 * (see story example).
 */
const ActivityTitle = (props: Props) => {
  const {
    className,
    status = 'not_started',
    statusesTexts = {},
    statusesColors = {},
  } = props;

  const statusesColorsToUse = {
    not_started: black,
    missed: gray1,
    completed: success,
    in_progress: primary,
    ...statusesColors,
  };

  const styles = css`
    font-weight: ${semiBoldFontWeight};
    font-size: ${textSmallFontSize}px;
    line-height: ${textSmallLineHeight}px;
    color: ${statusesColorsToUse[status]};
  `;

  const statusesTextsToUse = {
    completed: t.LECTURE_PAGES.COMPONENTS.ACTIVITY.STATUSES.DONE(),
    not_started: t.LECTURE_PAGES.COMPONENTS.ACTIVITY.STATUSES.SUBMIT(),
    in_progress: t.LECTURE_PAGES.COMPONENTS.ACTIVITY.STATUSES.IN_PROGRESS(),
    missed: t.LECTURE_PAGES.COMPONENTS.ACTIVITY.STATUSES.MISSED(),
    ...statusesTexts,
  };

  return (
    <div css={styles} className={`label black ${className ?? ''}`}>
      {statusesTextsToUse[status]}
    </div>
  );
};

export default ActivityTitle;
