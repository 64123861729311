import React, { useMemo } from 'react';
import { AngularContext } from 'react-app';
import t from 'react-translate';
import { NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';
import { config } from '../../../config/pendo.config.json';

export type Timezone = {
  longName: string;
  offset: string;
  translationKey: string;
  shortName: string;
};

const useTimezones = (onChange = null) => {
  const { injectServices } = React.useContext(AngularContext);
  const [TimezonesService] = injectServices(['TimezonesService']);

  const timezones = useMemo(() => TimezonesService.timezones.map((timezone: Timezone) => {
    const { longName, offset, translationKey } = timezone;
    return {
      id: longName,
      key: translationKey,
      type: 'text',
      text: `${offset} ${t.TIMEZONE[translationKey]()}`,
      value: offset,
      dataQa: `${config.pendo.liveEvent.liveEventSession.timeZone}-${longName}`,
      callback: () => onChange?.(timezone),
    } as NvDropdownTextItem;
  }) as NvDropdownTextItem[], [TimezonesService.timezones, onChange]);

  return timezones;
};

export default useTimezones;
