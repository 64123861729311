/* @ngInject */
export default function MembershipRequestModelService(

  MembershipRequestResource,
  $state,
  _,
) {
  const MembershipRequestModel = function (attributes) {
    const _this = this;

    _.extend(_this, attributes);

    _this.save = save;
    _this.withdraw = withdraw;
    _this.isPersisted = isPersisted;

    function save() {
      return MembershipRequestResource.save(
        { catalogId: $state.params.catalogId, teamId: _this.teamId },
        { message: _this.message },
      ).$promise.then((resource) => _.extend(_this, resource.result));
    }

    function withdraw() {
      return MembershipRequestResource.cancel(
        { catalogId: $state.params.catalogId, teamId: _this.teamId, id: _this.id },
        {},
      ).$promise;
    }

    function isPersisted() {
      return !!_this.id;
    }
  };

  return MembershipRequestModel;
}
