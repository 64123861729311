import { PointsConfiguration } from './points-configuration';
import { MinimalActivity, ActivityType } from './activity';
import { Communication } from './course-communication';
import { Normalized } from '../normalized';


// TODO: I don't think this extends is correct; an ExternalTool is not the same as a
// course activity
export interface MinimalExternalTool extends MinimalActivity {
  type: ActivityType.EXTERNAL_TOOL;
}

export type TimelineExternalTool = {
  id: number;
  activityType: string;
  name: null | string;
  url: null | string;
  consumerKey: null | string;
  consumerSecret: null | string;
  gradePassback: boolean | null;
  sendEmail: boolean | null;
  sendName: boolean;
  description: null;
  launchURL: null;
  pifFileName: null | string;
  toolType: 'web_link' | 'scorm' | 'lti' | 'non_embedded_web_link';
  title: null | string;
  displayText: null | string;
  aspectRatio: '4:3' | '16:9' | '1:1' | '21:9' | 'a4' | 'letter' | 'manual' | string;
  totalPoints: number[];
  pointsReceived: number;
  pointsConfiguration: PointsConfiguration;
  isRequiredForCompletion: boolean;
  isResizing: boolean;

  communications?: Communication[],
  communicationsCount?: number,
  autocompleteScorm?: boolean,
} & MinimalExternalTool;

export type NTimelineExternalTool = Normalized<TimelineExternalTool, 'communications'>;

export interface TimelineExternalToolsNormalized { [id: string]: NTimelineExternalTool }

export enum ExternalToolWebLinkTypes {
  WEB_LINK = 'non_embedded_web_link',
  WEB_EMBED = 'web_link',
  SCORM = 'scorm',
  LTI = 'lti',
}
