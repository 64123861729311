import { css } from '@emotion/react';
import React from 'react';
import NvTagButtonGeneric, { TagButtonGenericIconPosition } from 'shared/components/nv-tag-button-generic';

// Styles
import { hexToRgbaString, black, teal } from 'styles/global_defaults/colors';

type TagTextClosableProps = {
  text: string,
  className?: string,
  elementId?: string;
  onClose?: (event?: any) => void,
};

const NvTagTextClosable = ({
  text,
  className = '',
  elementId,
  onClose = () => {},
}: TagTextClosableProps) => {
  const styles = css`
    button.bg-white {
      cursor: auto;
      border-radius: unset;
      padding-left: 0px;
      span {
        padding-left: 0px;
      }
    }
  `;

  return (
    <div css={styles} className={className}>
      <NvTagButtonGeneric
        text={text}
        customIcon='close'
        elementId={elementId}
        iconPosition={TagButtonGenericIconPosition.RIGHT}
        onIconClick={onClose}
        normalClassNames={['text-small', 'bg-white', 'font-weight-normal']}
      />
    </div>
  );
};

export default NvTagTextClosable;
