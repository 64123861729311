import React from 'react';
import t from 'react-translate';

// Styles
import { css } from '@emotion/react';
import { gray6, gray7 } from 'styles/global_defaults/colors';
import { halfSpacing } from 'styles/global_defaults/scaffolding';

// Components
import NvModal, { ModalType } from '../nv-modal';

const modalStyles = css`
  .bs4-modal-header {
    display: none;
  }
  .bs4-modal-backdrop {
    display: none!important;
  }
`;

const Redirecting = () => {
  const styles = css`
    .loader {
      max-width: 400px;
      margin: 0 auto;

      @keyframes shineAnimation {
        0% {
          background-position: 0% 50%;
        }

        100% {
          background-position: -100% 50%;
        }
      }

      .loading {
        background: linear-gradient(90deg, ${gray6} 35%, ${gray7} 45%, ${gray6} 55%);
        background-size: 600% 600%;
        animation: shineAnimation 1.5s infinite;
      }

      .placeholder-bar {
        width: 100%;
        height: ${halfSpacing}px;
        margin-bottom: ${halfSpacing}px;
        margin-left: 0;
      }

      .first {
        width: 100%;
      }

      .second {
        width: 100%;
      }

      .third {
        width: 60%;
      }
    }
  `;

  return (
    <NvModal
      type={ModalType.FIXED}
      width={1920}
      height={1000}
      header=''
      modalStyles={modalStyles}
      body={(
        <div css={styles}>
          <div className='page-title gray-2 mb-5 mt-5 w-100 text-center'>
            {t.NOVOED.REDIRECTING()}
          </div>
          <div className='loader'>
            <div className='loading placeholder-bar first' />
            <div className='loading placeholder-bar second' />
            <div className='loading placeholder-bar third' />
          </div>
        </div>
      )}
      show
    />
  );
};

export default Redirecting;
