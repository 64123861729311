import { normalize } from 'normalizr';
import { createReducer } from '@reduxjs/toolkit';
import { some } from 'underscore';
import { ActivityProgress } from 'redux/schemas/models/activity';
import {
  fetchReplies,
  createReply,
  deleteReply,
  updateReply,
  voteUpReply,
  voteDownReply,
  addReply,
  editReply,
  removeReply,
  voteReply,
  getReplyLikersInfo,
  removeNewReplyId,
  translateReply,
} from 'redux/actions/replies';
import {
  FetchRepliesNormalizedResult,
  FetchRepliesEntities,
  FetchRepliesSchema,
} from 'redux/schemas/api/reply';
import { initialRootState } from '.';

const parseNewReply = (state, reply, commentId, postId = null, reportId = null) => {
  if (postId) {
    const post = state.models.posts[postId];
    if (!post) return;

    post.progress = 'completed';
  }

  const comment = state.models.comments[commentId];
  if (!comment) return;

  if (state.models.replies[reply.id]) return;

  Object.assign(comment, {
    replyIds: [...(comment.replyIds || []), reply.id],
    repliesCount: comment.repliesCount + 1,
    commented: true,
  });

  Object.assign(state.models.replies, {
    [reply.id]: {
      ...reply,
      likers: [],
    },
  });

  if (reply.isFirstContribution) {
    state.app.newReplyIdsForCurrentUser.push(reply.id);
  }

  if (reportId) {
    const submission = state.models.submissions[reportId];
    if (submission) {
      submission.numPostsAndComments += 1;
    }
  }
};


const parseDeleteReply = (state, commentId, replyId, postId = null, userId = null, reportId = null) => {
  if (postId) {
    const post = state.models.posts[postId];
    if (!post) return;
  }

  const comment = state.models.comments[commentId];
  if (!comment) return;

  const oldReply = state.models.replies[replyId];
  if (!oldReply) return;

  const newReplyIds = comment.replyIds.filter(id => id !== replyId);
  const repliesForComment = newReplyIds.map(id => state.models.replies[id]);

  Object.assign(comment, {
    replyIds: newReplyIds,
    repliesCount: comment.repliesCount - 1,
    commented: some(repliesForComment, (r) => r.user.id === userId),
  });

  if (reportId) {
    const submission = state.models.submissions[reportId];
    if (submission) {
      submission.numPostsAndComments -= 1;
    }
  }

  delete state.models.replies[replyId];
};

const parseVoteReply = (state, replyId, numLikes) => {
  Object.assign(state.models.replies[replyId], {
    votesCount: numLikes,
  });
};

export default createReducer(initialRootState, builder => {
  builder
    .addCase(removeNewReplyId, (state, action) => {
      state.app.newReplyIdsForCurrentUser = state.app.newReplyIdsForCurrentUser.filter(id => action.payload.replyId !== id);
    })
    .addCase(fetchReplies.fulfilled, (state, action) => {
      const {
        entities: { replies },
        result: { comments, additionalCommentsAfterCount, additionalCommentsBeforeCount, additionalNewCommentsBeforeCount },
      } = normalize<FetchRepliesNormalizedResult, FetchRepliesEntities>(action.payload, FetchRepliesSchema);
      const { commentId, beforeId } = action.meta.arg;
      const comment = state.models.comments[commentId];

      let replyIds = [...(comment.replyIds ?? [])];
      if (beforeId) {
        replyIds = [...comments, ...replyIds];
      } else {
        replyIds = [...comments];
      }

      Object.assign(state.models.comments[commentId], {
        replyIds,
        repliesFetched: true,
        additionalRepliesAfterCount: additionalCommentsAfterCount,
        additionalRepliesBeforeCount: additionalCommentsBeforeCount,
        additionalNewRepliesBeforeCount: additionalNewCommentsBeforeCount,
      });

      Object.assign(state.models.replies, replies);
    })
    .addCase(getReplyLikersInfo.fulfilled, (state, action) => {
      const { replyId } = action.meta.arg;
      const reply = state.models.replies[replyId];
      reply.likers = action.payload;
      reply.likersFetched = true;
    })
    .addCase(addReply.fulfilled, (state, action) => {
      const { reportId } = action.meta.arg;
      const { reply, commentId, postId } = action.payload;
      parseNewReply(state, reply, commentId, postId, reportId);
    })
    .addCase(createReply.fulfilled, (state, action) => {
      const reply = action.payload;
      const { commentId, postId, reportId } = action.meta.arg;
      parseNewReply(state, reply, commentId, postId, reportId);
    })
    .addCase(removeReply.fulfilled, (state, action) => {
      const { reportId } = action.meta.arg;
      const { replyId, commentId, postId, userId } = action.payload;
      parseDeleteReply(state, commentId, replyId, postId, userId, reportId);
    })
    .addCase(deleteReply.fulfilled, (state, action) => {
      const { replyId, commentId, postId, userId, reportId } = action.meta.arg;
      parseDeleteReply(state, commentId, replyId, postId, userId, reportId);
    })
    .addCase(editReply.fulfilled, (state, action) => {
      const { reply } = action.payload;
      Object.assign(state.models.replies[reply.id], reply);
    })
    .addCase(updateReply.fulfilled, (state, action) => {
      const { replyId } = action.meta.arg;
      Object.assign(state.models.replies[replyId], action.payload);
    })
    .addCase(voteReply.fulfilled, (state, action) => {
      const { replyId, numLikes } = action.payload;
      parseVoteReply(state, replyId, numLikes);
    })
    .addCase(voteUpReply.fulfilled, (state, action) => {
      const { replyId } = action.meta.arg;
      const { numLikes } = action.payload;

      Object.assign(state.models.replies[replyId], {
        votesCount: numLikes,
        liked: true,
      });
    })
    .addCase(voteDownReply.fulfilled, (state, action) => {
      const { replyId } = action.meta.arg;
      const { numLikes } = action.payload;

      Object.assign(state.models.replies[replyId], {
        votesCount: numLikes,
        liked: false,
      });
    })
    .addCase(translateReply.pending, (state, action) => {
      const { replyId } = action.meta.arg;
      const { body } = state.models.replies[replyId]

      state.app.discussionTranslation.replies[replyId] = {
        ...state.app.discussionTranslation.replies[replyId],
        body,
        isLoading: true,
        error: false
      }
    })
    .addCase(translateReply.fulfilled, (state, action) => {
      const { replyId } = action.meta.arg;
      const { body } = action.payload
      state.app.discussionTranslation.replies[replyId] = {
        ...state.app.discussionTranslation.replies[replyId],
        body,
        isLoading: false,
        error: false
      }
    })
    .addCase(translateReply.rejected, (state, action) => {
      const { replyId } = action.meta.arg;
      
      state.app.discussionTranslation.replies[replyId] = {
        ...state.app.discussionTranslation.replies[replyId],
        isLoading: false,
        error: true
      }
    });
});
