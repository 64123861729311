import { css } from '@emotion/react';
import React from 'react';
import NvTagButtonGeneric, { TagButtonGenericIconPosition } from 'shared/components/nv-tag-button-generic';

// Styles
import { hexToRgbaString, black, teal } from 'styles/global_defaults/colors';

type TagButtonClosableProps = {
  text: string,
  className?: string,
  elementId?: string;
  onClick?: (event?: any) => void,
  onIconClick?: (event?: any) => void,
};

const NvTagButtonClosable = ({
  text,
  className = '',
  elementId,
  onClick = () => {},
  onIconClick = () => {},
}: TagButtonClosableProps) => {
  const styles = css`
    button.bg-info {
      &:focus,&:hover {
        background-color: ${hexToRgbaString(teal, 0.2)} !important;
        color: ${black} !important;
      }
    }
  `;

  return (
    <div css={styles} className={className}>
      <NvTagButtonGeneric
        text={text}
        customIcon='close'
        elementId={elementId}
        iconPosition={TagButtonGenericIconPosition.RIGHT}
        onClick={onClick}
        onIconClick={onIconClick}
      />
    </div>
  );
};

export default NvTagButtonClosable;
