import { css } from '@emotion/react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { AngularServicesContext } from 'react-app';

import { RootState, CombinedInstitution } from 'redux/schemas';
import { User, MyAccountTeam } from 'redux/schemas/models//my-account';
import { Course } from 'redux/schemas/models/course';
import { getCurrentInstitution } from 'redux/selectors/institutions';

import { CourseObjectBookmark, SubmissionBookmarkComponent } from 'redux/schemas/models/bookmark';
import { headerLineHeight, textSmallLineHeight } from 'styles/global_defaults/fonts';
import { standardSpacing, doubleSpacing } from 'styles/global_defaults/scaffolding';
import { NvUserAvatar } from 'components/nv-user-avatar';
import { NvAvatar, NvAvatarDefaultBkg } from 'components/nv-avatar';
import ClickableContainer from 'components/clickable-container';


type SubmissionSnippetProps = {
  bookmark: CourseObjectBookmark
};

const SubmissionSnippet = ({
  bookmark: { catalogId, component },
}: SubmissionSnippetProps) => {
  const styles = css`
    &.submission-snippet {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${standardSpacing}px;
      padding-left: ${doubleSpacing}px;

      .title {
        line-height: ${headerLineHeight}px;
      }

      .subtitle {
        display: flex;
        align-items: center;
        line-height: ${textSmallLineHeight}px;
      }
    }
  `;

  const { $state, $scope, FlyoutModalManager } = useContext(AngularServicesContext);

  const {
    id,
    title,
    exercise,
    lecturePageName,
    courseName,
    owner,
    ownerType,
    expired,
  } = component as SubmissionBookmarkComponent;

  const currentInstitution = useSelector<RootState, CombinedInstitution>(getCurrentInstitution);
  const course = useSelector<RootState, Course>((state) => state.models.courses[catalogId]);
  const avatarColor = course?.headerColor ?? currentInstitution.brandColor;

  const onClickSnippet = () => {
    if (expired) { return; }

    FlyoutModalManager?.closeFlyout();
    $state.go('individual-submission-modal', { catalogId, reportId: id, hasRevised: true });
  };

  return (
    <ClickableContainer className='submission-snippet' css={styles} onClick={onClickSnippet}>
      <div className='text-wrapper'>
        <div className='title font-weight-bold'>{title} - {exercise.title}</div>
        <div className='subtitle text-small text-gray-3'>{lecturePageName} &middot; {courseName}</div>
      </div>
      {ownerType === 'User' ? (
        <NvUserAvatar displayRoleBadge={false} user={owner as User} size='md' borderType='round' tooltipEnabled={false} />
      ) : (
        <NvAvatar imageUrl={(owner as MyAccountTeam).profilePicture} size='md' borderType='square' defaultBkg={{ type: NvAvatarDefaultBkg.SOLID_COLOR, color: avatarColor }} />)}
    </ClickableContainer>
  );
};

export default SubmissionSnippet;
