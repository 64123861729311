import * as Sentry from '@sentry/browser';
/**
 * nv-max-length directive is used to add maximum length validation.
 * It is something to the ng-maxlength in angular js. But in
 * this directive, it report the max length error into the sentry
 * if an extra attribute 'report-max-length: true' is added with the
 * nv-max-length directive and makes the form invalid. Also adding
 * 'source', 'source-id' and 'parent-id' attributes for better debugging.
 */
/* @ngInject */
export default function NvMaxLength(
) {
  return {
    require: 'ngModel',
    link(scope, elem, attr, ngModel) {
      ngModel.$parsers.push((value) => {
        if (value != null) {
          const valid = value.length < attr.nvMaxLength;

          if (!valid && attr.reportMaxLength) {
            Sentry.configureScope((sentryScope) => {
              sentryScope.setTag('jira-card', 'NOV-65827');
              sentryScope.setExtras({
                action: 'The length of the body is larger than the limit',
                bodyValue: value,
                source: attr.source,
                parentId: attr.parentId ? attr.parentId : null,
                sourceId: attr.sourceId ? attr.sourceId : null,
              });
            });
            Sentry.captureException(new Error('Max Length Exceeds NOV-65827: The length of the body is larger than the limit'));
          }

          ngModel.$setValidity('maxLength', valid);
        } else {
          ngModel.$setValidity('maxLength', true);
        }

        return value;
      });
    },
  };
}
