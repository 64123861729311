import { css } from '@emotion/react';
import omit from 'lodash/omit';
import { useContext, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import t from 'react-translate';
import { Controller, useFormContext } from 'react-hook-form';
import { some, findLastIndex } from 'underscore';

// Contexts
import { CommunicationDispatch } from 'communications/course_communications/contexts/communication-context';

// Actions
import { getTeachingTeamMembers } from 'redux/actions/courses';

// Schemas
import { RootState } from 'redux/schemas';
import { User } from 'redux/schemas/models/my-account';

// Selectors
import { getCurrentUser, getUser } from 'redux/selectors/users';
import { getCourseAdmins } from 'redux/selectors/course';
import { getCommunication } from 'redux/selectors/course-communications';

// Components
import { NvUserAvatar } from 'components/nv-user-avatar';
import NvDropdown, { NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';

// Styles
import { gray3, primary } from 'styles/global_defaults/colors';

const mapDispatchToProps = {
  getUsers: getTeachingTeamMembers,
};

type AnnouncerProps = {
  isDisabled: boolean,
};

const styles = (isDisabled) => css`
  display: flex;
  flex-direction: column;
  align-items: center;

  .bs4-dropdown .bs4-dropdown-menu .nv-dropdown-header,
  .bs4-dropdown .bs4-dropdown-menu .bs4-dropdown-item {
    white-space: nowrap;
  }
  .change-user {
    color: ${isDisabled ? gray3 : primary};
  }
  ${isDisabled && 'pointer-events:none;'}
`;

const checkAndPushToCourseAdmins = (courseAdmins: User[], user: User): User[] => {
  if (courseAdmins && !some(courseAdmins, (admin) => admin.id === user.id)) {
    courseAdmins = [user, ...courseAdmins];
  }
  return courseAdmins;
};

const Announcer = ({ isDisabled, getUsers }: AnnouncerProps & typeof mapDispatchToProps) => {
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const { State } = useContext(CommunicationDispatch);
  const { control, setValue, watch } = useFormContext();
  const adminUserId = watch('adminUserId');

  let adminUser = useSelector(
    (state: RootState) => getUser(state, adminUserId),
  );
  // This is a failover scenario. Consider this is an edit screen and this user
  // is not a courseAdmin right now. So for display purpose just use what is
  // returned from backend.
  const communication = useSelector(
    (state: RootState) => getCommunication(state, State.communicationId),
  );
  if (!adminUser && communication?.communicationAdmin) {
    adminUser = communication.communicationAdmin;
  }

  let courseAdmins = useSelector(
    (state: RootState) => getCourseAdmins(state),
  );
  // There are edge cases in which we need to push items to the courseAdmins
  // to display it in the dropdown
  // 1. May be the announcer of this communication has been removed from the
  // admins, so check it and append it.
  if (communication?.communicationAdmin) {
    courseAdmins = checkAndPushToCourseAdmins(courseAdmins, communication.communicationAdmin);
  }
  // 2. The current user can be a Org admin or Course builder, who won't be in
  // the course admins list. It need to be displayed on when they are
  // logged in and are trying to create or edit
  // https://novoed.atlassian.net/browse/NOV-66976
  // https://novoed.atlassian.net/browse/NOV-66979
  const currentUser = useSelector(getCurrentUser);
  courseAdmins = checkAndPushToCourseAdmins(courseAdmins, currentUser);

  useEffect(() => {
    getUsers({
      catalogId,
    });
  }, [catalogId, getUsers]);

  const dropdownItems: NvDropdownOption[] = courseAdmins ? courseAdmins.map(user => (
    {
      type: 'text',
      text: user?.fullName,
      id: user?.id,
      callback: () => setValue('adminUserId', user?.id, { shouldValidate: true, shouldDirty: true }),
    }
  )) : [];

  const adminUserIndex = findLastIndex(dropdownItems, {
    id: adminUserId,
  });

  return (
    <div css={styles(isDisabled)}>
      { adminUser && (
        <NvUserAvatar
          borderType='round'
          size='md'
          user={adminUser}
          displayRoleBadge
          displayName
          className='mt-4 px-4'
        />
      )}
      <Controller
        name='adminUserId'
        control={control}
        render={({ field }) => (
          <NvDropdown
            showSelectedIndicator
            items={[
              { type: 'header', title: t.COURSE_COMMUNICATIONS.ANNOUNCEMENT.FORM.CHOOSE_ANNOUNCER() },
              ...dropdownItems,
            ]}
            buttonStyle={NvDropdownButtonStyle.CUSTOM}
            customTarget={() => (
              <a className='text-small change-user'>
                {t.COURSE_COMMUNICATIONS.ANNOUNCEMENT.FORM.CHANGE_USER()}
              </a>
            )}
            initialIndex={adminUserIndex + 1}
            {...omit(field, ['ref'])}
          />
        )}
      />
    </div>
  );
};

const ConnectedAnnouncer = connect<{}, typeof mapDispatchToProps>(
  (state) => ({}),
  mapDispatchToProps as any,
)(Announcer);

export default ConnectedAnnouncer;
