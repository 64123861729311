import React from 'react';

import angular from 'angular';
import embedAngular from 'shared/embed-angular';
import { AngularServicesContext } from 'react-app';
import ColorPickerPopover from 'components/color-picker-popover';

type Props = {
  color: string;
  ctrl: angular.IController;
  onChange(newColor: string): void;
};

const ReactAngularColorPicker = (props: Props) => {
  const { color, onChange, ctrl } = props;
  const containerRef = React.useRef();

  const ctrlRef = React.useRef(ctrl);

  const angularServices = React.useContext(AngularServicesContext);


  React.useLayoutEffect(() => {
    const templateScope = angularServices.$scope.$new(true);
    templateScope.vm = ctrlRef.current;
    const template = angularServices.$templateCache.get<string>('shared/templates/nv-color-picker.html');
    embedAngular(template, containerRef, angularServices, templateScope);
  }, [angularServices]);

  return (
    <ColorPickerPopover<{
      color: string,
    }>
      sections={[{
        title: '',
        name: 'color',
      }]}
      colorValues={{
        color,
      }}
      onChange={(section, newColor) => {
        onChange(newColor);
      }}
    >
      <div ref={containerRef} />
    </ColorPickerPopover>
  );
};

export default ReactAngularColorPicker;
