import { css } from '@emotion/react';

import NvButtonPopover, { Button } from 'shared/components/nv-button-popover';
import NvTooltip from 'shared/components/nv-tooltip';
import t from 'react-translate';
import NvFilePicker from 'shared/components/nv-filepicker';
import { ImageWidth, ImageAlignment, ImageRotation, widthCycling, alignmentCycling, alignmentToIcon } from './image-component-utils';
import { LecturePageMode } from '..';

export type PopoverProps = {
  width: ImageWidth,
  alignment: ImageAlignment,
  rotation: number,
  buttons: Button[],
  children: any,
  show: boolean,
  setShow: (show: boolean) => void,
  mode: LecturePageMode,
};


/** The black button toolbar popup that shows when editing the image compo  nent */
const ImageEditPopover = (props: PopoverProps) => (
  <NvButtonPopover
    buttons={props.buttons}
    placement='top'
    setShow={props.setShow}
    show={props.show}
  >
    <div>{props.children}</div>
  </NvButtonPopover>
);

export const createPopoverButtons = (
  resizeDisabled: boolean,
  alignmentDisabled: boolean,
  imageWidth: ImageWidth,
  imageAlignment: ImageAlignment,
  imageRotation: ImageRotation,
  setImageAlignment: (alignment: ImageAlignment) => void,
  setImageWidth: (width: ImageWidth) => void,
  setFile: (file: File) => Promise<void>,
  setShowSettingsPopover: (show: boolean) => void,
  setImageRotation: (rotation: ImageRotation) => void,
  setShowCroppingModal: (show: boolean) => void,
  setShowAltTextModal: (show: boolean) => void,
): Button[] => {
  let imageWidthText: string;

  if (resizeDisabled) {
    imageWidthText = '100%';
  } else if (imageWidth === 'full') {
    imageWidthText = t.LECTURE_PAGES.COMPONENTS.IMAGE.FULL_WIDTH();
  } else {
    imageWidthText = imageWidth;
  }

  const button: Button[] = [
    {
      type: 'icon',
      iconClass: 'icon-upload',
      // onClick: () => setShowFileDialog(true),
      tooltip: t.LECTURE_PAGES.COMPONENTS.IMAGE.UPLOAD_TOOLTIP(),
      wrappingComponent: NvFilePicker,
      wrappingComponentProps: {
        accept: ['image/*'],
        onChange: async (files: File[]) => {
          await setFile(files[0]);
        },
      },
    },
    {
      type: 'icon',
      iconClass: 'icon-crop',
      onClick: () => { setShowSettingsPopover(false); setShowCroppingModal(true); },
      tooltip: t.LECTURE_PAGES.COMPONENTS.IMAGE.CROPT_IMAGE(),
    },
    {
      type: 'text',
      text: imageWidthText,
      onClick: () => {
        setImageWidth(widthCycling[imageWidth]);
      },
      disabled: resizeDisabled,
      tooltip: t.LECTURE_PAGES.COMPONENTS.IMAGE.RESIZE_TOOLTIP(),
    },
    {
      type: 'icon',
      iconClass: alignmentToIcon[alignmentDisabled ? 'left' : imageAlignment],
      onClick: () => setImageAlignment(alignmentCycling[imageAlignment]),
      disabled: alignmentDisabled,
      tooltip: t.LECTURE_PAGES.COMPONENTS.IMAGE.ALIGN_TOOLTIP(),
    },
    {
      type: 'icon',
      iconClass: 'icon-rotate',
      onClick: () => setImageRotation(((imageRotation - 90) % 360 as ImageRotation)),
      tooltip: t.LECTURE_PAGES.COMPONENTS.IMAGE.ROTATE_TOOLTIP(),
    },
    {
      type: 'text',
      text: 'Alt',
      onClick: () => { setShowSettingsPopover(false); setShowAltTextModal(true); },
      tooltip: t.LECTURE_PAGES.COMPONENTS.IMAGE.ALT_TEXT_TOOLTIP(),
    },
  ];

  return button;
};

export default ImageEditPopover;
