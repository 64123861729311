/* @ngInject */
export default function NvSimpleProfilePicture(
  CurrentCourseManager,
  InteroperabilityRoutes, // TODO: ABREY REMOVE WHEN YOU FINISH TEAM PROFILE
  $state,
) {
  return {
    restrict: 'A',
    scope: {
      size: '@?',
      team: '=?', // NOTE: this directive determines which type of profile to show by checking against undefined. null and objects evaluate to true
      user: '=?',
      directToProfile: '=?',
      course: '=?', // set when being used in a place where profile links should be interoperable
    },
    transclude: true,
    link(scope, elem, attr) {
      let transcludedElement = null;

      // todo: i18n
      scope.getInitials = function (userObj) {
        return userObj.initials ? userObj.initials
          : ((userObj.firstName ? userObj.firstName[0].toUpperCase() : '') + (userObj.lastName ? userObj.lastName[0].toUpperCase() : ''));
      };

      scope.getFontSize = function () {
        if (scope.size) {
          const sizeInfo = scope.parseSizeAndUnits(scope.size);
          const calculatedFontSize = (0.5 * sizeInfo.size).toString() + sizeInfo.units;

          return { 'font-size': calculatedFontSize };
        }
        return { 'font-size': '50%' };
      };

      scope.parseSizeAndUnits = function (sizeStr) {
        const sizeInt = parseInt(sizeStr, 10);
        const splitSizeInforray = sizeStr.split(sizeInt.toString());
        const sizeUnits = splitSizeInforray.length > 1 && splitSizeInforray[1].length ? splitSizeInforray[1] : 'px';

        return {
          size: sizeInt,
          units: sizeUnits,
        };
      };

      scope.getPositionOffset = function () {
        const sizeInfo = scope.parseSizeAndUnits(scope.size);
        transcludedElement = scope.getTranscludedElement();

        const transcludedWidth = transcludedElement.width();
        const transcludedHeight = transcludedElement.height();

        if (transcludedWidth > 0 || transcludedHeight > 0) {
          if (scope.isRound) {
            const sizeRadius = (sizeInfo.size / 2);
            const y = sizeRadius * Math.sin(45);
            const x = Math.sqrt((sizeRadius ** 2) - (y ** 2));
            return {
              top: `${(Math.floor((sizeRadius - y) - transcludedHeight / 2)).toString()}px`,
              right: `${(Math.floor((sizeRadius - x) - transcludedWidth / 2)).toString()}px`,
            };
          }
          const offset = `-${(transcludedWidth / 2).toString()}${sizeInfo.units}`;

          return {
            top: offset,
            right: offset,
          };
        }

        return null;
      };

      scope.getTranscludedElement = function () {
        return elem.find('[ng-transclude=""]');
      };


      if (!scope.size) {
        scope.size = '100%'; // this assumes the parent will be square, otherwise will be stretched/squished on 1 axis
      }

      const profileSubject = scope.team || scope.user; // the subject of the profile picture

      if (!scope.user && scope.user !== undefined) {
        // temporarily provide placeholder image
        elem.toggleClass('empty-user', !scope.user && scope.user !== undefined);
        scope.isRound = true;
      } else if (!scope.team && scope.team !== undefined) {
        scope.toggleClass('empty-team', !scope.team && scope.team !== undefined);
      } else {
        const applicableCourse = scope.course ? scope.course : CurrentCourseManager.course;
        scope.profilePictureUrl = profileSubject.profilePictureUrl || profileSubject.profilePicture;
        scope.isRound = scope.user !== undefined;

        if (scope.directToProfile) {
          if (scope.user) {
            scope.profileLink = $state.href('learner-profile-modal', { catalogId: applicableCourse ? applicableCourse.catalogId : null, userId: profileSubject.id });
          } else {
            scope.profileLink = (applicableCourse ? InteroperabilityRoutes.teamProfilePath(applicableCourse, scope.team.id) : null);
          }
        } else {
          scope.profileLink = null;
        }

        if (!scope.profilePictureUrl) {
          scope.initials = scope.getInitials(profileSubject);
        }
      }


      if (scope.isRound === null || scope.isRound === undefined) {
        scope.isRound = true; // default
      }
    },
    templateUrl: 'shared/templates/nv-simple-profile-picture.html',
  };
}
