import { css } from '@emotion/react';
import { useContext } from 'react';
import t from 'react-translate';
import moment from 'moment';

// Contexts
import { AngularServicesContext } from 'react-app';

// Schemas
import { useSelector } from 'react-redux';
import { RootState } from 'redux/schemas';
import { VideoPracticeScenario, RecordingFormat } from 'redux/schemas/models/video-practice';

// Selectors
import { getScenario, isCurrentCourseScenario } from 'redux/selectors/video-practice';

// Styles
import {
  black, gray2, gray4, gray5, gray6,
  kelp, lightOrange, primary, warning,
} from 'styles/global_defaults/colors';
import { halfSpacing } from 'styles/global_defaults/scaffolding';

// Components
import NvIcon from 'shared/components/nv-icon';
import ClickableContainer from 'components/clickable-container';
import { getCourseAliases } from 'redux/selectors/course';
import { CourseAliases } from 'redux/schemas/models/course';

/**
 * Displays a Practice Scenario title with stats in the Practice Room flyout
 */

type ScenarioRowProps = {
  scenarioId: number,
};

const styles = css`
  border-bottom: solid 1px ${gray5};
  cursor: pointer;

  .current-course {
    .label {
      color: ${warning};
      background-color: ${lightOrange};
      border-radius: ${halfSpacing}px;
      padding: 1px 5px;
    }
  }

  .stats {
    .stats-row {
      &:last-child {
        /* To override Bootstrap spacing utility */
        margin-bottom: 0 !important;
      }

      .icon-box {
        width: 16px;
        height: 16px;
        color: #fff;
        opacity: 0.5;

        .icon {
          /* Don't have this size for an icon in icons.scss */
          font-size: 8px;
        }

        &.last-practiced {
          background-color: ${primary};
        }

        &.featured {
          background-color: ${warning};
        }

        &.gallery {
          background-color: ${kelp};

          .icon {
            transform: rotate(270deg);
          }
        }

        &.no-videos {
          background-color: ${gray4};
        }
      }

      .label {
        color: ${gray2};
      }
    }
  }

  &:hover {
    background-color: ${gray6};

    .course-title {
      color: ${primary};
    }

    .stats {
      .icon-box {
        opacity: 1;
      }

      .label {
        color: ${black};
      }
    }
  }
`;

type StatsRowProps = {
  icon: string,
  iconClass: string,
  statText: string,
};

const StatsRow = ({
  icon,
  iconClass,
  statText,
}: StatsRowProps) => (
  <div className='stats-row d-flex align-items-center mb-2'>
    <div className={`icon-box mr-1 d-flex align-items-center justify-content-center ${iconClass}`}>
      <NvIcon icon={icon} size='xss-smallest' />
    </div>
    <div className='label'>
      {statText}
    </div>
  </div>
);

const ScenarioRow = ({
  scenarioId,
}: ScenarioRowProps) => {
  const { $state } = useContext(AngularServicesContext);
  const aliases: CourseAliases = useSelector((state) => getCourseAliases(state));

  const scenario = useSelector<RootState, VideoPracticeScenario>((state) => getScenario(state, scenarioId));

  // This is outside of a course actually and so won't be setting current course
  // when entering to react world. So checking whether the url contains a
  // catalogId
  const isCurrentCourse = useSelector<RootState, boolean>(
    (state) => isCurrentCourseScenario(state, scenarioId, $state.params?.catalogId),
  );

  return (
    <ClickableContainer
      css={styles}
      className='px-5 py-4 d-block'
      onClick={() => $state.go('practice-room-modal', { scenarioId })}
    >
      {isCurrentCourse && (
        <div className='current-course'>
          <span className='label'>
            {t.PRACTICE_ROOM.SCENARIOS.IN_CURRENT_COURSE({
              courseAlias: aliases.courseAliases.CourseAlias,
            })}
          </span>
        </div>
      )}
      <div className='course-title'>{scenario.title}</div>
      <div className='stats mt-4 label'>
        {scenario.lastTimePracticed && (
          <StatsRow
            icon={scenario.recordingFormat === RecordingFormat.AUDIO ? 'audio-practice-on-cards' : 'video-practice-on-cards'}
            iconClass='last-practiced'
            statText={t.PRACTICE_ROOM.SCENARIOS.STATS.LAST_PRACTICED(moment(scenario.lastTimePracticed).format('l'))}
          />
        )}
        {scenario?.totalFeaturedCount > 0 && (
          <StatsRow
            icon='highlight'
            iconClass='featured'
            statText={t.PRACTICE_ROOM.SCENARIOS.STATS.FEATURED(scenario.totalFeaturedCount)}
          />
        )}
        {scenario?.totalInGalleryCount > 0 && (
          <StatsRow
            icon='dropdown-arrow'
            iconClass='gallery'
            statText={t.PRACTICE_ROOM.SCENARIOS.STATS.GALLERY(scenario.totalInGalleryCount)}
          />
        )}
        {scenario.totalInGalleryCount === 0 && (
          <StatsRow
            icon='highlight'
            iconClass='no-videos'
            statText={t.PRACTICE_ROOM.SCENARIOS.STATS.NO_VIDEOS()}
          />
        )}
      </div>
    </ClickableContainer>
  );
};

export default ScenarioRow;
