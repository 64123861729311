import React from 'react';
import moment from 'moment';
import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';

import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import { AngularServicesContext } from 'react-app';
import NvPopover from 'shared/components/nv-popover';
import NvTooltip from 'shared/components/nv-tooltip';
import Badge from 'cohort_management/components/badge';
import { openSans } from 'styles/global_defaults/fonts';
import useTextMeasurer from 'shared/hooks/use-text-measurer';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import {
  Course,
  Entitlement,
  CourseRegistrationType,
} from 'redux/schemas/models/course';
import {
  info,
  black,
  gray2,
  hexToRgbaString,
} from 'styles/global_defaults/colors';
import {
  halfSpacing,
  tripleSpacing,
  quarterSpacing,
} from 'styles/global_defaults/scaffolding';
import ReordererMenu, {
  Props as ReordererMenuProps,
} from 'learning_journeys/components/reorderer-menu';

const POPOVER_BODY_WIDTH = 180;

const sharedFontStyles = css`
  font-family: ${openSans};
`;

const smallThinTextStyles = css`
  ${sharedFontStyles};
  font-size: 12px;
  color: ${gray2};
  line-height: 15px;
`;

const smallTextStyles = css`
  ${smallThinTextStyles};
  font-weight: 600;
`;

const bigTextStyles = css`
  ${sharedFontStyles};
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

const useProfileSetting = (profileSettingId: number) => {
  const currentInstitution = useSelector(getCurrentInstitution);

  return currentInstitution.profileSettings.orgLevel.find(
    (orgField) => orgField.id === profileSettingId,
  );
};

type Props = ReordererMenuProps & {
  course: Course,
  locked?: boolean,
  dataQa?: string,
  dataQaId?: string,
};

const MeasuredTextsContext = React.createContext<React.MutableRefObject<{ [id: string]: boolean }>>({ current: {} });

const CourseRow = (props: Props) => {
  const {
    course,
    locked,
    onDelete,
    onMoveUp,
    canMoveUp,
    onMoveDown,
    canMoveDown,
    dataQa,
    dataQaId,
  } = props;

  const measuredProfileSettingNames = React.useRef({});
  const { $state } = React.useContext(AngularServicesContext);

  const isCourseCloseDatePast = moment(course.closeDate).isBefore(moment());
  const courseThumbnailSrc = course.squareThumbnail === '/assets/brand/temp.png'
    ? ''
    : course.squareThumbnail;

  const styles = css`
    display: flex;
    margin-bottom: 2px;
    background-color: ${hexToRgbaString(info, 0.5)};

    &:last-of-type {
      margin-bottom: 0px;
    }

    .content-container {
      flex: 1;
      min-width: 0;
      display: flex;
      padding: ${halfSpacing}px;

      .large-section {
        flex: 3;
      }

      .small-section {
        flex: 2;
        padding: 0 ${halfSpacing}px;
      }

      .large-section, .small-section {
        display: flex;
        flex-shrink: 0;
        align-items: center;
      }

      .registration-type {
        min-width: 0;

        // TODO: Once having improved our NvPopover component by removing the
        // .popover-wrapper element this won't be necessary
        .popover-wrapper {
          display: inline-block;
        }

        .more-entitlements {
          font-size: 12px;
          line-height: 15px;
        }
      }

      .course-basics {
        min-width: 0;
        display: flex;
        align-items: center;

        .course-logo {
          flex-shrink: 0;
          margin-right: ${halfSpacing}px;
        }

        .course-info-container {
          flex: 1;
          min-width: 0;

          .course-name {
            display: block;
          }
        }
      }
    }
  `;

  const shouldShowEntitlementsWarningIndicator = course.typeOfRegistration === CourseRegistrationType.OPEN_BASED_ON_ENTITLEMENTS
    && !course.entitlements.length;

  const shouldShowWarningIndicator = () => (
    !course.released
    || isCourseCloseDatePast
    || shouldShowEntitlementsWarningIndicator
  );

  const getWarningIndicatorTooltipText = () => {
    if (!course.released) {
      return t.LEARNING_JOURNEYS.DETAILS.COURSES.UNRELEASED_COURSE();
    }

    if (isCourseCloseDatePast) {
      return t.LEARNING_JOURNEYS.DETAILS.COURSES.CLOSED_COURSE();
    }

    if (shouldShowEntitlementsWarningIndicator) {
      if (course.openInJourney) {
        return t.LEARNING_JOURNEYS.DETAILS.COURSES.NO_ENTITLEMENTS();
      }

      return t.LEARNING_JOURNEYS.BASICS.REGISTRATION_OPTIONS.ENTITLEMENT_BASED.ADVICE();
    }

    throw new Error('Unhandled warning indicator case for tooltip text');
  };

  const renderDate = () => {
    const formatToDate = (date: string) => moment(date).format('MM/DD/YYYY');

    switch (true) {
      // If course is self paced & course has limited time enrollment set & and
      // course is not closed
      case (
        course.isSelfPaced
        && !!course.enrollmentLimitInDays
        && (course.closeDate ? !isCourseCloseDatePast : true)
      ):
        return (
          <div>
            {t.LEARNING_JOURNEYS.DETAILS.COURSES.DATES.SELF_PACED()}
            <br />
            {t.LEARNING_JOURNEYS.DETAILS.COURSES.DATES.DAYS_ACCESS(course.enrollmentLimitInDays.toString())}
          </div>
        );
      // If already closed
      case (!!course.closeDate && isCourseCloseDatePast):
        return (
          <div>
            {t.LEARNING_JOURNEYS.DETAILS.COURSES.DATES.CLOSED_ON()}
            {' '}
            {formatToDate(course.closeDate)}
          </div>
        );
      // If release and close date is present
      case (!!course.releaseDate && !!course.closeDate):
        return (
          <div>
            {formatToDate(course.releaseDate)}
            {' '}
            {t.LEARNING_JOURNEYS.DETAILS.COURSES.DATES.TO()}
            {' '}
            {formatToDate(course.closeDate)}
          </div>
        );
      // If release date or close date is available
      case (!!course.releaseDate || !!course.closeDate):
        return (
          <div>
            {t.LEARNING_JOURNEYS.DETAILS.COURSES.DATES[course.releaseDate ? 'RELEASE_DATE' : 'CLOSURE_DATE']()}
            {': '}
            {formatToDate(course.releaseDate || course.closeDate)}
          </div>
        );
      default:
        return null;
    }
  };

  const renderOfferedTo = () => {
    const isRegistrationType = (registrationType) => course.typeOfRegistration === registrationType;

    const isCourseOpenInJourney = (
      isRegistrationType(CourseRegistrationType.OPEN_BASED_ON_ENTITLEMENTS)
      || isRegistrationType(CourseRegistrationType.CLOSED_ENROLLMENT)
    ) && course.openInJourney;

    switch (true) {
      case (isCourseOpenInJourney):
        return (
          <div css={smallTextStyles}>
            {t.LEARNING_JOURNEYS.DETAILS.COURSES.OFFERED_TO.OPEN_IN_JOURNEY()}
            {' '}
            (
            {isRegistrationType(CourseRegistrationType.CLOSED_ENROLLMENT) ? (
              t.LEARNING_JOURNEYS.DETAILS.COURSES.OFFERED_TO.CLOSED_ENROLLMENT()
            ) : (
              t.LEARNING_JOURNEYS.DETAILS.COURSES.OFFERED_TO.OPEN_BASED_ON_ENTITLEMENTS()
            )}
            )
          </div>
        );
      case (isRegistrationType(CourseRegistrationType.CLOSED_ENROLLMENT)):
        return (
          <div css={smallTextStyles}>
            {t.LEARNING_JOURNEYS.DETAILS.COURSES.OFFERED_TO.CLOSED_ENROLLMENT()}
          </div>
        );
      case (isRegistrationType(CourseRegistrationType.FREE_ENROLLMENT_IN_INSTITUTION)):
        return (
          <div css={smallTextStyles}>
            {t.LEARNING_JOURNEYS.DETAILS.COURSES.OFFERED_TO.OPEN_TO_WHOLE_ORGANIZATION()}
          </div>
        );
      case (isRegistrationType(CourseRegistrationType.FREE_ENROLLMENT)):
        return (
          <div css={smallTextStyles}>
            {t.LEARNING_JOURNEYS.DETAILS.COURSES.OFFERED_TO.OPEN_ENROLLMENT()}
          </div>
        );
      case (isRegistrationType(CourseRegistrationType.OPEN_BASED_ON_ENTITLEMENTS)): {
        const popoverBodyStyles = css`
          width: ${POPOVER_BODY_WIDTH};
        `;

        const renderEntitlement = (entitlement) => (
          <TruncatedEntitlement entitlement={entitlement} key={entitlement.id} />
        );

        return (
          <div className='registration-type'>
            {course.entitlements.length > 3 ? (
              <React.Fragment>
                {course.entitlements.slice(0, 2).map(renderEntitlement)}
                <NvPopover
                  showOnHover
                  placement='top'
                  preventOverflow
                  content={(
                    <div css={popoverBodyStyles}>
                      {course.entitlements.slice(2, course.entitlements.length).map((entitlement) => (
                        <SmartEntitlement entitlement={entitlement} key={entitlement.id} />
                      ))}
                    </div>
                  )}
                >
                  <button className='btn btn-link hovered more-entitlements' type='button'>
                    {t.LEARNING_JOURNEYS.DETAILS.COURSES.OFFERED_TO.ENTITLEMENT_BASED.MORE()}
                  </button>
                </NvPopover>
              </React.Fragment>
            ) : (
              course.entitlements.map(renderEntitlement)
            )}
          </div>
        );
      }
      default:
        return null;
    }
  };

  return (
    <MeasuredTextsContext.Provider value={measuredProfileSettingNames}>
      <div css={styles} data-qa={dataQa} data-qa-id={dataQaId}>
        <div className='content-container'>
          <div className='large-section course-basics'>
            <LockableImage
              course={course}
              locked={locked}
              className='course-logo'
              src={courseThumbnailSrc}
              backgroundColor={course.headerColor}
            />
            <div className='course-info-container'>
              <a
                // eslint-disable-next-line react/jsx-no-target-blank
                target='_blank'
                css={bigTextStyles}
                className='course-name ellipsis'
                href={$state.href('course-home', {
                  catalogId: course.catalogId,
                })}
              >
                {course.name}
              </a>
              <div className='ellipsis' css={smallTextStyles}>{course.catalogId}</div>
            </div>
          </div>
          <div className='small-section' css={smallTextStyles}>
            {renderDate()}
          </div>
          <div className='large-section registration-type'>
            {shouldShowWarningIndicator() && (
              <NvTooltip text={getWarningIndicatorTooltipText()}>
                <NvIcon
                  icon='warning'
                  size='smallest'
                  className='mr-1 text-danger'
                />
              </NvTooltip>
            )}
            {renderOfferedTo()}
          </div>
        </div>
        <ReordererMenu
          rowMenuColor={info}
          onDelete={onDelete}
          onMoveUp={onMoveUp}
          canMoveUp={canMoveUp}
          onMoveDown={onMoveDown}
          canMoveDown={canMoveDown}
          tooltipText={t.LEARNING_JOURNEYS.DETAILS.COURSES.EDIT_OPTIONS()}
        />
      </div>
    </MeasuredTextsContext.Provider>
  );
};

type LockableImageProps = {
  src: string,
  course?: Course,
  locked?: boolean,
  className?: string,
  backgroundColor?: string,
};

const LockableImage = (props: LockableImageProps) => {
  const {
    src,
    course,
    className,
    locked = false,
    backgroundColor,
  } = props;

  const styles = css`
    position: relative;
    width: ${tripleSpacing}px;
    height: ${tripleSpacing}px;
    background-color: ${backgroundColor || black};

    img {
      width: 100%;
      height: 100%;
      display: block;
      user-select: none;
      object-fit: cover;
    }

    .overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      background-color: ${locked ? hexToRgbaString(black, 0.5) : 'transparent'};

      .icon {
        color: #fff;
      }
    }
  `;

  return (
    <div css={styles} className={className}>
      {src && (
        <img src={src} draggable={false} alt='' />
      )}
      {locked && (
        <div className='overlay'>
          <NvIcon icon='locked' size='small' />
        </div>
      )}
      <Badge course={course} />
    </div>
  );
};

const getEntitlementNameText = (name: string) => `${name}: `;

type BaseEntitlementProps = {
  entitlement: Entitlement,
  className?: string,
  doubleSpacing?: boolean,
  nameContainerClassName?: string,
  valuesContainerClassName?: string,
};

const BaseEntitlement = (props: BaseEntitlementProps) => {
  const {
    className,
    entitlement,
    doubleSpacing = false,
    nameContainerClassName,
    valuesContainerClassName,
  } = props;

  const profileSetting = useProfileSetting(entitlement.profileSettingId);
  const nameText = getEntitlementNameText(profileSetting.name);

  const styles = css`
    ${smallThinTextStyles};
    margin-bottom: ${doubleSpacing ? halfSpacing : quarterSpacing}px;

    &:last-of-type {
      margin-bottom: 0;
    }
  `;

  return (
    <div className={className} css={styles}>
      <span className={nameContainerClassName}>
        {nameText}
      </span>
      <span className={`bold${valuesContainerClassName ? ` ${valuesContainerClassName}` : ''}`}>
        {entitlement.values.map((entitlementValue: string, index, entitlementValues) => {
          const isLast = index === (entitlementValues.length - 1);

          return `${entitlementValue}${isLast ? '' : ` ${t.LEARNING_JOURNEYS.DETAILS.COURSES.OFFERED_TO.ENTITLEMENT_BASED.OR()} `}`;
        })}
      </span>
    </div>
  );
};

type CustomEntitlementProps = Omit<BaseEntitlementProps, 'className' | 'nameContainerClassName' | 'valuesContainerClassName'>;

const SmartEntitlement = (props: CustomEntitlementProps) => {
  const {
    entitlement,
    ...restProps
  } = props;

  const measureText = useTextMeasurer({
    fontSize: 12,
    lineHeight: 15,
    fontFamily: openSans,
  });
  const [isNameLarge, setIsNameLarge] = React.useState(false);
  const profileSetting = useProfileSetting(entitlement.profileSettingId);
  const measuredProfileSettingNames = React.useContext(MeasuredTextsContext);

  const styles = css`
    display: ${isNameLarge ? 'block' : 'flex'};

    .name-container {
      ${!isNameLarge && css`
        margin-right: ${quarterSpacing}px;
      `}
    }

    .values-container {
      ${!isNameLarge && css`
        flex: 1;
      `}
    };
  `;

  React.useLayoutEffect(() => {
    const key = entitlement.id.toString();

    const valueInContext = measuredProfileSettingNames.current[key];

    if (valueInContext !== undefined) {
      if (valueInContext) {
        setIsNameLarge(true);
      }
    } else {
      const textWidth = measureText(
        getEntitlementNameText(profileSetting.name),
      );

      const nameResultedLarge = textWidth > (POPOVER_BODY_WIDTH / 3);

      if (nameResultedLarge) {
        setIsNameLarge(true);
      }

      measuredProfileSettingNames.current[key] = nameResultedLarge;
    }
  }, []);

  return (
    <BaseEntitlement
      css={styles}
      doubleSpacing
      entitlement={entitlement}
      nameContainerClassName='name-container'
      valuesContainerClassName='values-container'
      {...restProps}
    />
  );
};

const TruncatedEntitlement = (props: CustomEntitlementProps) => (
  <BaseEntitlement
    {...props}
    className='ellipsis'
  />
);

export default CourseRow;
