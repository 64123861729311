import { css } from '@emotion/react';
import React, { ReactElement } from 'react';
import { reduce } from 'underscore';

// Styles
import { primary } from 'styles/global_defaults/colors';

// Components
import NvIcon from 'shared/components/nv-icon';

interface PageIntroProps {
  title: string,
  description: string
}

const styles = css`
  .icon-display {
    display: inline-block;
    color: ${primary};
  }

  .intro-description {
    display: flex;
    justify-content: center;
  }
`;

const PageIntro = (props: PageIntroProps) => (
  <div css={styles}>
    <div className='page-title mt-6 text-center'>{props.title}</div>
    <div className='text-body my-4 intro-description'>
      <div>
        {reduce(props.description.split('SQUARE_ICON'), (prev: ReactElement, part) => (prev
          ? (
            <React.Fragment>
              { prev }
              <span className='icon-display'><NvIcon icon='add-square' size='xss-smallest' /></span>
              { part }
            </React.Fragment>
          )
          : (
            <React.Fragment>
              {part}
            </React.Fragment>
          )
        ), null)}
      </div>
    </div>
  </div>
);

export default PageIntro;
