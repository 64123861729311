import React from 'react';
import { useSelector } from 'react-redux';

import { gray7 } from 'styles/global_defaults/colors';
import OfferingCard from 'offerings/components/offering-card';
import { getCurrentJourney } from 'redux/selectors/learning-journeys';
import NvSubmissionGallery from 'shared/components/submission/nv-submission-gallery';
import { journeyCollectionContext } from 'learning_journeys/components/details';
import {
  largeSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import { CombinedCourse } from 'redux/schemas';
import { VisitedLearningJourneySchema } from 'redux/schemas/app/learning-journey';
import { config } from '../../../config/pendo.config.json';

type Props = {
  courses: CombinedCourse[],
  journeyInfo: VisitedLearningJourneySchema,
};

const CoursesCarousel = (props: Props) => {
  const {
    courses,
    journeyInfo,
  } = props;

  const currentJourney = useSelector(getCurrentJourney);

  const {
    getIsCourseLocked,
  } = React.useContext(journeyCollectionContext);

  return (
    <NvSubmissionGallery
      spacing={largeSpacing}
      topPadding={standardSpacing}
      bottomPadding={standardSpacing}
      backgroundColor={gray7}
    >
      {courses.map((course, index) => (
        <OfferingCard
          offering={course}
          position={index + 1}
          key={course.catalogId}
          parent={currentJourney}
          locked={getIsCourseLocked(index)}
          journey={journeyInfo}
          dataQa={config.pendo.learningJourneys.courseInCollection}
          dataQaId={`${config.pendo.learningJourneys.courseInCollection}-${index}`}
        />
      ))}
    </NvSubmissionGallery>
  );
};

export default CoursesCarousel;
