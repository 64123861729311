import { css, jsx } from '@emotion/react';
import React, { forwardRef, MutableRefObject, useMemo, useRef, useState } from 'react';
import t from 'react-translate';

// Schemas
import { User } from 'redux/schemas/models/my-account';

//  Styles
import { gray4 } from 'styles/global_defaults/colors';
import { isHandheld } from 'styles/global_defaults/media-queries';
import {
  doubleSpacing,
  halfSpacing,
  largeSpacing,
  quarterSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';

//  Components
import { NvUserAvatar } from 'components/nv-user-avatar';
import useClickOutside from 'shared/hooks/use-click-outside';
import { PopoversContainerContext } from 'shared/react-utils';
import NvPopover from './nv-popover';
import NvIcon from './nv-icon';
import LoadingWrapper from './loading-wrapper';

type NvLikeButtonProps = {
  isLiked: boolean
  likedCount: number
  likedUsers?: User[] // Is optional. Only needed if we need to show the popover
  isDisabled?: boolean
  onLike?: () => void,
  onUndoLike?: () => void,
  iconSize?: string
  iconClassName?: string
  textClassName?: string
  showLikersInfo?: boolean
  getLikersInfo?: () => void
  displayUserRoleBadge?: boolean
};

const overlayStyles = css`
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);

  .bs4-popover-body {

    padding: ${halfSpacing}px
      0px
      ${halfSpacing}px
      ${standardSpacing}px;

    .likers-popover {
      max-height: 340px;
      overflow-y: scroll;
      &-row {
        display: flex;
        .nv-user-avatar:not(.first) {
          margin-left: ${standardSpacing}px;
        }
      }

      ::-webkit-scrollbar {
        width: 6px;
      }

      ::-webkit-scrollbar-thumb {
        background: ${gray4};
        border-radius: ${quarterSpacing}px;
      }
      .loading-wrapper {
        padding: ${doubleSpacing}px;
        overflow: hidden;
      }
    }
  }
`;

const LikersListing = forwardRef((props: {
  likers: any[],
  displayRoleBadge?: boolean,
}, ref: MutableRefObject<HTMLInputElement>) => (
  <div className='likers-popover flex-wrap' ref={ref}>
    <LoadingWrapper isLoaded={!!props.likers?.length}>
      {props.likers?.map(row => (
        <div className='likers-popover-row'>
          {row.map((user, i) => (
            <NvUserAvatar
              borderType='round'
              size='md'
              user={user}
              displayName
              className={`${i === 0 ? 'first' : ''} my-2 px-1`}
              displayRoleBadge={props.displayRoleBadge}
            />
          ))}
        </div>
      ))}
    </LoadingWrapper>
  </div>
));

const NvLikeButton = ({
  isLiked,
  likedCount,
  likedUsers,
  isDisabled,
  iconSize,
  iconClassName,
  textClassName,
  onLike,
  onUndoLike,
  showLikersInfo,
  getLikersInfo,
  displayUserRoleBadge = false,
}: NvLikeButtonProps) => {
  const [showPopover, setShowPopover] = useState(false);
  const ref = useRef(null);
  const containerRef = useRef(null);

  let iconColorClass = 'text-gray-3';
  if (isLiked) {
    iconColorClass = 'text-primary';
  }
  if (isDisabled) {
    iconColorClass = 'text-gray-5';
  }

  const toggleLike = () => {
    if (!isDisabled) {
      if (isLiked) {
        onUndoLike?.();
      } else {
        onLike?.();
      }
    }
  };

  const openPopover = () => {
    if (!showPopover && likedCount !== likedUsers?.length) {
      getLikersInfo?.();
    }
    setShowPopover(true);
  };

  useClickOutside(ref, () => {
    setShowPopover(false);
  });

  const likerRows = useMemo(() => {
    const rows = [];
    let row = [];
    likedUsers?.forEach((user, i) => {
      row.push(user);
      if (isHandheld()) {
        if ((i + 1) % 2 === 0) {
        // 2 users per row on mobileview
          rows.push(row);
          row = [];
        }
      } else if ((i + 1) % 3 === 0) {
        // show 3 users per row on desktopview
        rows.push(row);
        row = [];
      }
    });

    if (row.length) {
      rows.push(row);
    }

    return rows;
  }, [likedUsers]);

  return (
    <React.Fragment>
      <PopoversContainerContext.Provider value={containerRef.current}>
        <div className='d-flex' ref={containerRef}>
          <NvIcon
            size={iconSize}
            icon='like'
            className={`${iconColorClass}  ${iconClassName}`}
            onClick={!isDisabled ? toggleLike : null}
            altLabel={t.PRACTICE_ROOM.STATS.LIKE(likedCount)}
          />
          {likedCount > 0 && (
          <React.Fragment>
            {showLikersInfo ? (
              <NvPopover
                content={(
                  <LikersListing
                    likers={likerRows}
                    ref={ref}
                    displayRoleBadge={displayUserRoleBadge}
                  />
                )}
                placement='bottom-start'
                show={showPopover}
                overlayStyles={overlayStyles}
                flip
              >
                <div
                  style={{ cursor: 'pointer' }}
                  className={`text-primary ${textClassName}`}
                  onClick={openPopover}
                >
                  {likedCount}
                </div>
              </NvPopover>
            ) : (
              <div className={`${textClassName}`}>{likedCount}</div>
            ) }
          </React.Fragment>
          )}
        </div>
      </PopoversContainerContext.Provider>
    </React.Fragment>
  );
};

export default NvLikeButton;
