/* @ngInject */
export default function AnnouncementsCourseListCtrl(

  AnnouncementsManager,
  CurrentUserManager,
  $element,
  nvUtil,
) {
  const vm = this;

  vm.AnnouncementsManager = AnnouncementsManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.loadCourseAnnouncements = loadCourseAnnouncements;

  function loadCourseAnnouncements(course) {
    const parentElement = $element[0].parentNode;
    vm.AnnouncementsManager.requestAnnouncementsForCourse(course).then(() => {
      if (parentElement) {
        nvUtil.focusFirstElement(parentElement);
      }
    });
  }
}
