
import { css } from '@emotion/react';
import React from 'react';
import t from 'react-translate';
import NvTooltip from 'shared/components/nv-tooltip';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { Style1AccordionSectionLectureComponent } from 'redux/schemas/models/lecture-component';
import { SectionContainerProps } from './accordion-types';
import AccordionRtHeader from './accordion-rt-header';
import ClickableContainer, { ClickableContainerProps } from '../../../components/clickable-container';
import useAccordionSectionContainer from './use-accordion-section-container';
import ApplyToAllSectionsButton from './apply-to-all-sections-button';
import { LecturePageMode } from '..';

const iconContainerStyle = css`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px 0 8px;
`;

interface Style1LeftHandSymbolProps {
  expanded: boolean;
  sectionData: Style1AccordionSectionLectureComponent;
  mode: LecturePageMode;
  onClick: ClickableContainerProps['onClick'];
  layoutOnly: ClickableContainerProps['layoutOnly'];
}

/* @ngInject */
export function Style1ToggleButton(props: Style1LeftHandSymbolProps) {
  const iconClassName = props.expanded ? 'icon-close' : 'icon-create-new-post';

  return (
    <ClickableContainer onClick={props.onClick} layoutOnly={props.layoutOnly} css={iconContainerStyle}>
      <NvTooltip
        enabled={props.mode === LecturePageMode.EDIT}
        text={
          props.expanded
            ? t.LECTURE_PAGES.COMPONENTS.ACCORDION.CLICK_TO_COLLAPSE()
            : t.LECTURE_PAGES.COMPONENTS.ACCORDION.CLICK_TO_EXPAND()
        }
      >
        <div
          css={css`
            color: ${props.sectionData.viewOptions.iconColor};
            font-size: ${props.expanded ? '14px' : '16px'};
          `}
          className={iconClassName}
        />
      </NvTooltip>
    </ClickableContainer>
  );
}

type Style1SectionContainerProps = SectionContainerProps<Style1AccordionSectionLectureComponent>;

/* @ngInject */
export default function Style1SectionContainer(props: Style1SectionContainerProps) {
  const {
    headerEditorRef,
    onHeaderRTEChange,
  } = useAccordionSectionContainer(props);

  const content = (
    <ClickableContainer
      layoutOnly={props.mode === LecturePageMode.EDIT}
      onClick={props.toggleExpanded}
      className='w-100 align-items-center'
    >
      <Style1ToggleButton
        mode={props.mode}
        sectionData={props.sectionData}
        expanded={props.expanded}
        layoutOnly={props.mode !== LecturePageMode.EDIT}
        onClick={props.toggleExpanded}
      />
      <AccordionRtHeader<{
        lineColor: string,
        iconColor: string,
      }>
        colorValues={{
          lineColor: props.sectionData.viewOptions.lineColor,
          iconColor: props.sectionData.viewOptions.iconColor,
        }}
        sections={[{
          name: 'lineColor',
          title: t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_1.LINE_COLOR_PICKER_LABEL(),
        }, {
          name: 'iconColor',
          title: t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_1.ICON_COLOR_PICKER_LABEL(),
        }]}
        onColorChange={(section, newColor) => {
          props.onViewOptionsChange({
            [section]: newColor,
          });
        }}
        renderBeforePalette={!props.isOnlySection ? () => (
          <div className='d-flex flex-column align-items-center'>
            <ApplyToAllSectionsButton
              onClick={() => {
                props.applyViewOptionsToAllSections(props.sectionData.viewOptions, {
                  includeHeaderTextFormat: true,
                });
              }}
            />
          </div>
        ) : undefined}
        autoFocus={props.autoFocusHeader}
        mode={props.mode}
        sectionData={props.sectionData}
        isOnlySection={props.isOnlySection}
        editorRef={headerEditorRef}
        onChange={onHeaderRTEChange}
        currentLecture={props.currentLecture}
      />
    </ClickableContainer>
  );

  return (
    <div
      css={css`
        border-width: 1px;
        border-color: ${props.sectionData.viewOptions.lineColor};
        border-style: solid none ${props.isLastSection ? 'solid' : 'none'} none;
        background-color: white;
      `}
    >
      {content}
      <div>
        {props.renderSectionContent({
          containerCss: css`
            /*padding added inside the collapsible content */
            padding-top: ${halfSpacing}px;
            background-color: white;
          `,
        })}
      </div>
    </div>
  );
}
