import CourseAdminDashboard from '../components/course-admin-dashboard';

/* @ngInject */
export default function CourseAdminDashboardController(
  $scope,
  ReportsManager,
) {
  $scope.CourseAdminDashboard = CourseAdminDashboard;

  ReportsManager.getApprovalExercises(true);
}
