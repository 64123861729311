import React from 'react';
import { css } from '@emotion/react';
import { halfSpacing, quarterSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';

const StatusBar = (props: { maxValue: number, currentValue: number, activeColor: string }) => {
  const styles = css`
    height: ${quarterSpacing}px;
    border-radius: ${threeQuartersSpacing}px;
    background-color: ${props.activeColor};
    ${props.currentValue === 0 ? css`
      width: ${halfSpacing}px;
    ` : css`
      width: calc(${halfSpacing}px + (${props.currentValue} / ${props.maxValue}) * 100%);
    `}
  `;
  return (
    <div css={styles} />
  );
};

export default StatusBar;
