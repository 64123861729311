import { css } from '@emotion/react';
import moment, { Moment } from 'moment';

import { useEffect, useState } from 'react';
import t from 'react-translate';
import { halfSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';

type StopwatchProps = {
  startTime: Moment,
  maxTime: number,
  paddingTime: number,
  warningLength: number,
  displayWarningText?: boolean,
};

const styles = css`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  .time-block {
    padding: 2px 25px;
    border-radius: 50px;

    .time {
      vertical-align: top;
      text-align: center;
    }

    .time-separator {
      width: 15px;
      text-align: center;
      margin-top: ${quarterSpacing}px;
    }
  }

  .additional-messaging {
    margin-top: ${halfSpacing}px;
  }
`;

export const forceTwoDigits = (num) => (`0${parseInt(num)}`).slice(-2);

/**
 * Displays the amount of time elapsed since the provided startTime
 */
const Stopwatch = ({
  startTime,
  maxTime = 0,
  paddingTime = 0,
  warningLength = 0,
  displayWarningText = false,
}: StopwatchProps) => {
  const [totalElapsedSeconds, setTotalElapsedSeconds] = useState(0);

  useEffect(() => {
    let interval;

    const updateElapsedSeconds = () => {
      const currentTotalElapsedSeconds = paddingTime + (startTime ? moment().diff(startTime, 'seconds') : 0);
      setTotalElapsedSeconds(currentTotalElapsedSeconds);

      if (currentTotalElapsedSeconds >= maxTime) {
        clearInterval(interval);
      }
    };

    interval = setInterval(updateElapsedSeconds, 1000);
    updateElapsedSeconds();

    return () => clearInterval(interval);
  }, [startTime, maxTime, paddingTime]);

  const minutesDisplay = (forceTwoDigits(Math.floor(totalElapsedSeconds / 60)));
  const secondsDisplay = (forceTwoDigits(totalElapsedSeconds % 60));

  let additionalClassNames = '';
  let additionalMessaging = '';
  if (totalElapsedSeconds >= maxTime - warningLength) {
    const minuteLimit = maxTime / 60;
    additionalClassNames = 'bg-color-alert';
    additionalMessaging = t.FILE_UPLOAD.APPROACHING_LIMIT(minuteLimit);
  }


  return (
    <div css={styles}>
      <div dir='ltr' className={`time-block course-title-xxs ${additionalClassNames}`}>
        <div className='time inline-block'>
          <div className='course-subtitle'>{minutesDisplay}</div>
          <div className='badge-small'>{t.TIMED_QUIZZES.TIMER.MINUTE()}</div>
        </div>
        <div className='inline-block time-separator'>:</div>
        <div className='time inline-block'>
          <div className='course-subtitle'>{secondsDisplay}</div>
          <div className='badge-small'>{t.TIMED_QUIZZES.TIMER.SECOND()}</div>
        </div>
      </div>
      {displayWarningText
        && (
        <div className='additional-messaging'>
          {additionalMessaging}
        </div>
        )}
    </div>
  );
};

export default Stopwatch;
