import React from 'react';
import { css } from '@emotion/react';

import t from 'react-translate';
import NvPopover from 'shared/components/nv-popover';
import NvTooltip from 'shared/components/nv-tooltip';
import usePostRender from 'shared/hooks/use-post-render';
import NvFilePicker from 'shared/components/nv-filepicker';
import { tripleSpacing } from 'styles/global_defaults/scaffolding';
import { gray3, gray4, primary } from 'styles/global_defaults/colors';
import ValidationErrorMessage from 'shared/components/inputs/validation-error-message';

type Profile = {
  profilePicture?: string,
  defaultPicture?: string,
  initials?: string,
  firstName?: string,
  lastName?: string,
};

type Props = {
  profile: Profile,
  isRound?: boolean,
  isTeam?: boolean,
  size?: number,
  readonly?: boolean,
  editable?: boolean,
  isUploading?: boolean,
  readonlyWarning?: string,
  dataQa?: string,
  onChange?: (file: File) => void,
};

const ProfilePicture = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    profile,
    isRound = true,
    isTeam = false,
    readonly,
    size = 160,
    readonlyWarning,
    editable = false,
    isUploading = false,
    dataQa,
    onChange = () => {},
  } = props;

  const mountedRef = React.useRef(false);
  const filePickerRef = React.useRef<HTMLDivElement>();
  const [hasError, setHasError] = React.useState(false);
  const [hasMouseIn, setHasMouseIn] = React.useState(false);
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [attemptedToUpload, setAttemptedToUpload] = React.useState(false);
  const attemptToUpload = () => setAttemptedToUpload(true);
  const resetAttemptedToUpload = () => setAttemptedToUpload(false);

  React.useEffect(() => {
    if (mountedRef.current) {
      setHasError(false);
      setImageLoaded(false);
    } else {
      mountedRef.current = true;
    }
  }, [profile.profilePicture]);

  const scheduleNewFilePickerFocus = usePostRender(() => {
    filePickerRef.current.focus();
  }, []);

  const styles = css`
    width: ${size}px;
    height: ${size}px;
    border-width: 1px;
    position: relative;
    ${isRound && 'border-radius: 50%;'}
    box-sizing: border-box;
    border-color: ${gray3};
    border-style: ${(hasMouseIn || isUploading) ? 'dashed' : 'none'};

    .hidden-input {
      display: none;
    }

    .image-element {
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
      ${isRound && 'border-radius: 50%;'}
      display: ${imageLoaded ? 'flex' : 'none'};
    }
    .empty-team {
      width: ${size}px;
      height: ${size}px;
    }
    .user-initials {
      color: white;
      height: 100%;
      display: flex;
      ${isRound && 'border-radius: 50%;'}
      align-items: center;
      justify-content: center;
      font-size: ${0.5 * size}px;
      background-color: ${gray4};
    }

    .overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      cursor: pointer;
      color: ${primary};
      ${isRound && 'border-radius: 50%;'}
      position: absolute;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.9);
      opacity: ${(hasMouseIn || isUploading) ? 0.9 : 0};

      .overlay-text {
        text-align: center;
        width: ${isUploading ? 'auto' : `${tripleSpacing}px`};
      }
    }
  `;

  const getInitials = () => profile.initials
    ?? (profile.firstName[0]?.toUpperCase() ?? '')
    + (profile.lastName[0]?.toUpperCase() ?? '');

  const getImageProfile = () => {
    if (!profile.profilePicture) {
      return (
        <div className='nv-profile-picture empty-team' />
      );
    }
    return (
      <img
        alt=''
        draggable={false}
        onLoad={handleImageLoad}
        src={profile.profilePicture}
        className='image-element'
        onError={handleImageError}
      />
    );
  };

  const handleFilePickerClick = () => {
    if (readonly) {
      attemptToUpload();
      scheduleNewFilePickerFocus();
    }
  };

  const handleFilePickerBlur = () => {
    if (readonly) {
      resetAttemptedToUpload();
    }
  };

  const handleFilePickerDrop = (event) => {
    if (readonly) {
      attemptToUpload();
      scheduleNewFilePickerFocus();
      event.preventDefault();
    }
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
    setHasError(false);
  };

  const handleImageError = () => {
    setImageLoaded(false);
    setHasError(true);
  };

  const handleOverlayMouseEnter = () => {
    setHasMouseIn(true);
  };

  const handleOverlayMouseLeave = () => {
    setHasMouseIn(false);
  };

  const handleFilePickerChange = ([file]) => onChange(file);

  return (
    <NvPopover
      preventOverflow
      placement='auto'
      show={readonly && attemptedToUpload}
      content={<ValidationErrorMessage text={readonlyWarning} />}
    >
      <div ref={ref} css={styles} data-qa={dataQa}>
        {((profile.profilePicture && !hasError) || isTeam) ? (
          getImageProfile()
        ) : (
          <div className='user-initials'>{getInitials()}</div>
        )}
        {(editable || isUploading) && (
          <NvTooltip
            text={t.PROFILE.DRAG_AND_DROP_TEXT()}
            enabled={!isUploading && !attemptedToUpload}
          >
            <NvFilePicker
              multiple={false}
              tabIndex={0}
              noDrag={false}
              ref={filePickerRef}
              accept={['image/*']}
              onDrop={handleFilePickerDrop}
              onBlur={handleFilePickerBlur}
              className='text-small overlay'
              onClick={handleFilePickerClick}
              onChange={handleFilePickerChange}
              disabled={isUploading || readonly}
              onMouseEnter={handleOverlayMouseEnter}
              onMouseLeave={handleOverlayMouseLeave}
            >
              <div className='overlay-text'>
                {
                  isUploading
                    ? t.PROFILE.UPLOADING()
                    : t.PROFILE.IMAGE_UPLOAD_TEXT()
                }
              </div>
            </NvFilePicker>
          </NvTooltip>
        )}
      </div>
    </NvPopover>
  );
});

export default ProfilePicture;
