import React, { useEffect } from 'react';
import t from 'react-translate';
import { css } from '@emotion/core';

// redux
import { NQuizQuestion, ResponseOption } from 'redux/schemas/models/progressive-quiz';
import { useSelector } from 'react-redux';
import { getFlatCourseAliases } from 'redux/selectors/course';
import { getQuizQuestionOptions } from 'redux/selectors/quizzes';
import { RootState } from 'redux/schemas';

import ProgressiveQuizContext, { QuestionContext } from 'quizzes/components/context';
import NvIcon from 'shared/components/nv-icon';
import NvPopover from 'shared/components/nv-popover';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import
{ danger,
  gray2,
  gray5,
  primary,
  success,
  white,
} from 'styles/global_defaults/colors';
import { quarterSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import AnswerFeedback from 'quizzes/components/feedback-components/answer-feedback';
import ViewAnswerFeedBack from 'quizzes/components/feedback-components/view-answer-feedback';
import { removeDecimalForInteger } from 'quizzes/components/utils';
import NumberAnswerSettingInput from './number-answer-setting-input';

type QuizQuestionProps = {
  currentQuestion: NQuizQuestion;
};

const NumberAnswerSection = (props: QuizQuestionProps) => {
  const { currentQuestion } = props;
  const {
    answerState,
    setAnswerState,
  } = React.useContext(QuestionContext);
  const courseAliases = useSelector(getFlatCourseAliases);

  const responseOptions: ResponseOption[] = useSelector(
    (state: RootState) => getQuizQuestionOptions(state, currentQuestion?.id),
  );

  const {
    currentQuestionResponse,
  } = React.useContext(ProgressiveQuizContext);

  const {
    isEditMode,
    isAnswerMode,
    isReviewMode,
  } = useQuizModeAndQuestionType();

  const [correctAnswerFeedback, setCorrectAnswerFeedback] = React.useState(responseOptions[0]?.explanation);
  const [incorrectAnswerFeedback, setIncorrectAnswerFeedback] = React.useState(responseOptions[0]?.secondaryExplanation);
  const isTopRangePresent = !!responseOptions[0].topOfRange
    && (responseOptions[0].topOfRange !== responseOptions[0].bottomOfRange);

  const [isAnswerInRange, setIsAnswerInRange] = React.useState(false);
  const [bottomOfRange, setBottomOfRange] = React.useState(removeDecimalForInteger(responseOptions[0].bottomOfRange) || '');
  const [topOfRange, setTopOfRange] = React.useState(isTopRangePresent ? removeDecimalForInteger(responseOptions[0].topOfRange) : '');
  const [triggerShowFeedback, setTriggerShowFeedback] = React.useState(false);

  const showFeedbackIcon = !(correctAnswerFeedback !== null && incorrectAnswerFeedback !== null);

  useEffect(() => {
    if (isTopRangePresent) {
      setIsAnswerInRange(true);
    }
  }, [isTopRangePresent]);

  const getBorderColor = () => {
    if (currentQuestionResponse) {
      if (currentQuestionResponse.isCorrect) {
        return success;
      }
      return danger;
    }
    return gray5;
  };

  const styles = css`
    .input-style {
      font-size: 16px;
      border-radius: ${quarterSpacing}px;
      background-color: ${white};

      ::placeholder {
        color: ${gray2};
      }
    }
    .answer-input-container {
      border-radius: ${quarterSpacing}px;
      border: 1px solid ${gray5};
      border-color: ${getBorderColor()};
      input {
        height: ${tripleSpacing}px;
        border: none;
      }
      input: focus {
        outline: none;
        border: 1px solid ${primary};
      }
    }
    .number-setting-container {
      height: ${tripleSpacing}px;
      .dash-component {
        width: 10%;
      }
    }
  `;

  const getValue = () => {
    if (currentQuestionResponse) {
      if (typeof currentQuestionResponse.response !== 'number') {
        return null;
      }
      return currentQuestionResponse.response;
    }
    if (answerState === null) {
      return '';
    }
    return answerState.toString();
  };

  const handleAnswerChange = (e) => {
    setAnswerState(e.target.value);
  };

  return (
    <div css={styles}>
      <div className='w-100 mb-5'>
        { !isEditMode && (
          <div className='mt-5 mx-auto answer-input-container'>
            <input
              className='pl-2 w-100 input-style'
              disabled={!isAnswerMode || !!currentQuestionResponse}
              placeholder={(isReviewMode && !getValue()) ? '' : '0'}
              value={getValue()}
              type='number'
              onChange={handleAnswerChange}
            />
          </div>
        )}
        { currentQuestionResponse && (
          <ViewAnswerFeedBack currentQuestion={currentQuestion} />
        )}
      </div>
      { isEditMode && (
      <React.Fragment>
        <div className='d-flex pt-5'>
          <p className='semi-bold mb-0'>{t.QUIZZES.NUMBER_ANSWER_QUESTION.CORRECT_NUMBER()}: </p>
          <NvPopover
            className='d-flex'
            showOnHover
            placement='top'
            content={(
              <div className=''>
                {t.QUIZZES.NUMBER_ANSWER_QUESTION.SETTING_NUMBER_ANSWER_INFO(courseAliases)}
              </div>
              )}
          >
            <NvIcon
              className='d-flex align-items-center'
              icon='info ml-1'
              size='xs-smallest text-primary'
            />
          </NvPopover>
        </div>
        <div className='mt-2 number-setting-container d-flex'>
          <NumberAnswerSettingInput
            setTriggerShowFeedback={setTriggerShowFeedback}
            isAnswerInRange={isAnswerInRange}
            setIsAnswerInRange={setIsAnswerInRange}
            topOfRange={topOfRange}
            bottomOfRange={bottomOfRange}
            setBottomOfRange={setBottomOfRange}
            setTopOfRange={setTopOfRange}
            currentQuestion={currentQuestion}
            showFeedbackIcon={showFeedbackIcon}
            isBottomRange
          />
          {isAnswerInRange && (
          <React.Fragment>
            <div className='dash-component d-flex align-items-center justify-content-center'>
              <NvIcon icon='dash' size='small' className='d-flex align-items-center text-gray-4' />
            </div>
            <NumberAnswerSettingInput
              setTriggerShowFeedback={setTriggerShowFeedback}
              isAnswerInRange={isAnswerInRange}
              setIsAnswerInRange={setIsAnswerInRange}
              topOfRange={topOfRange}
              bottomOfRange={bottomOfRange}
              setBottomOfRange={setBottomOfRange}
              setTopOfRange={setTopOfRange}
              currentQuestion={currentQuestion}
              showFeedbackIcon={showFeedbackIcon}
              isBottomRange={false}
            />
          </React.Fragment>
          )}
        </div>
        <AnswerFeedback
          currentQuestion={currentQuestion}
          correctAnswerFeedback={correctAnswerFeedback}
          incorrectAnswerFeedback={incorrectAnswerFeedback}
          setCorrectAnswerFeedback={setCorrectAnswerFeedback}
          setIncorrectAnswerFeedback={setIncorrectAnswerFeedback}
          triggerShowFeedback={triggerShowFeedback}
          setTriggerShowFeedback={setTriggerShowFeedback}
        />
      </React.Fragment>
      )}
    </div>
  );
};

export default NumberAnswerSection;
