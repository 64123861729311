
import React, { useState, useContext } from 'react';
import t from 'react-translate';
import { Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AngularServicesContext } from 'react-app';

// Redux
import { getCurrentUser } from 'redux/selectors/users';
import { useSelector, useDispatch } from 'react-redux';
import { setShowUpdateEmailAlert, updateSystemGeneratedEmail } from 'redux/actions/users';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';

// Components
import NvModal, { ModalType } from './nv-modal';
import NvTextInput from './inputs/nv-text-input';


const UpdateEmailAlert = () => {
  const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const currentUser = useSelector(getCurrentUser);
  const showWarningAlert = useSelector((state) => state.app.showUpdateEmailAlert);
  const dispatch = useDispatch();
  const { CurrentUserManager, $state, $scope } = useContext(AngularServicesContext);
  const validationSchema = yup.object().shape({
    email: yup.string().email(t.VALIDATION.INVALID_EMAIL()).required(t.VALIDATION.REQUIRED()),
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, formState, reset } = methods;
  const { isValid } = formState;
  const updateEmail = (data) => {
    const payload = {
      user: {
        email: data.email,
      },
    };

    dispatch(updateSystemGeneratedEmail(payload)).then((response) => {
      if (response.payload?.errorCodes?.length) {
        setHasError(true);
      } else {
        CurrentUserManager.updateEmail(data.email);
        // To invoke AccountBasicsController for updating email to render changes in UI
        if ($state.current.name === 'account-basics') {
          $scope.$apply();
        }
        reset();
        dispatch(
          addAlertMessage({
            type: AlertMessageType.SUCCESS,
            header: t.FORM.SUCCESS(),
            message: t.SSO_UPDATE_EMAIL.UPDATE_EMAIL_ADDRESS_MODAL.UPDATE_EMAIL_SUCCESS(),
          }),
        );
      }
    });
  };

  const closeWarningAlert = () => {
    dispatch(setShowUpdateEmailAlert(false));
  };

  const closeModal = () => {
    setShowUpdateEmailModal(false);
    reset();
    setHasError(false);
  };

  const modalBody = () => (
    <div className='px-7 pt-4'>
      <p>{t.SSO_UPDATE_EMAIL.UPDATE_EMAIL_ADDRESS_MODAL.BODY()}</p>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(updateEmail)}>
          <div className='mt-4'>
            {hasError && <p className='text-danger'>{t.SSO_UPDATE_EMAIL.UPDATE_EMAIL_ADDRESS_MODAL.EMAIL_ALREADY_EXISTS()}</p>}
            <label htmlFor='email' className='text-small gray-3 font-weight-normal'>{t.USERS.REGISTRATION.EMAIL_ADDRESS()}</label>
          </div>
          <NvTextInput
            withForm
            name='email'
            ariaLabel='Email Address'
            required
            placeholder={t.FORM.TYPE_HERE()}
            onChange={() => setHasError(false)}
          />
          <div className='button-bar mt-7'>
            <Button
              variant='secondary'
              onClick={closeModal}
              className='mt-2'
            >
              {t.FORM.CANCEL()}
            </Button>
            <Button
              disabled={!isValid || hasError}
              type='submit'
              variant='primary'
              className='mt-2'
            >
              {t.FORM.SAVE()}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );

  return (
    currentUser?.hasSystemGeneratedAddress && showWarningAlert
      ? (
        <div className='flash-messages-container update-email-alert'>
          <div className='flash-holder flash-warning'>
            <div className='flash-close'>
              <i className='icon-close cursor-pointer' onClick={closeWarningAlert} />
            </div>
            <div className='flash-content' role='alert'>
              <div className='flash-message' />
              <i className='icon flash-icon icon-info' />
              <span>{t.SSO_UPDATE_EMAIL.EMAIL_NEEDED()}</span>
              <div className='button-holder'>
                <a className='btn btn-primary' type='button' onClick={() => setShowUpdateEmailModal(true)}>
                  {t.SSO_UPDATE_EMAIL.ADD_EMAIL_ADDRESS()}
                </a>
              </div>

            </div>
          </div>
          <NvModal
            type={ModalType.FIXED}
            header={t.SSO_UPDATE_EMAIL.UPDATE_EMAIL_ADDRESS_MODAL.HEADER()}
            show={showUpdateEmailModal}
            width={600}
            height={393}
            onClose={closeModal}
            body={modalBody()}
          />
        </div>
      ) : null
  );
};

export default UpdateEmailAlert;
