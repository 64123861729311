/* @ngInject */
export default function NvCourseThumbnail(
) {
  return {
    restrict: 'A',
    scope: {
      course: '=',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'dashboard/templates/nv-course-thumbnail.html',
  };
}
