const PROXY_DOMAINS = {
  'api.box.com': 'boxapi.novoed.com',
  'dl.boxcloud.com': 'boxdl.novoed.com',
};

function replaceUrl(originalUrl) {
  let url = originalUrl;
  Object.keys(PROXY_DOMAINS).forEach(key => {
    url = url.replace(key, PROXY_DOMAINS[key]);
  });

  return url;
}

const proxyInterceptor = (request) => {
  request.url = replaceUrl(request.url);
  return request;
};

const responseInterceptor = (response) => {
  try {
    response.data.representations.entries.forEach(entry => {
      if (entry?.content?.url) {
        entry.content.url = replaceUrl(entry.content.url);
      }
      if (entry?.content?.url_template) {
        entry.content.url_template = replaceUrl(entry.content.url_template);
      }
    });

    response.data.authenticated_download_url = replaceUrl(response.data.authenticated_download_url);
  } catch (ex) {
    // in case json signature changes
  }

  return response;
};

/* @ngInject */
export default function nvBoxPreview(
  EmbeddingsResource,
) {
  return {
    scope: {
      boxDocumentId: '=?',
    },
    link(scope, element, attrs) {
      attrs.$set('dir', 'ltr');

      let lastAccessTokenRequest;
      const preview = new window.Box.Preview();
      // Disable the FontInspector to a avoid console error on every box preview: https://novoed.atlassian.net/browse/NOV-63072
      // Unfortunately I've seen no config or other method to disable this
      window.default = { FontInspector: false };

      function updateBoxPreview() {
        lastAccessTokenRequest = EmbeddingsResource.getBoxAccessToken({ boxDocumentId: scope.boxDocumentId });
        lastAccessTokenRequest.$promise.then((response) => {
          const token = response.result.accessToken;

          preview.show(scope.boxDocumentId, token, {
            container: element[0],
            autoFocus: false,
            header: 'none',
            disableEventLog: true,
            requestInterceptor: proxyInterceptor,
            responseInterceptor,
            fixDependencies: true,
          });
        });
      }

      scope.$watch('boxDocumentId', (newBoxDocumentId, oldBoxDocumentId) => {
        if (newBoxDocumentId !== oldBoxDocumentId) {
          updateBoxPreview();
        }
      });

      updateBoxPreview();

      scope.$on('$destroy', () => {
        if (lastAccessTokenRequest) {
          lastAccessTokenRequest.$cancelRequest();
        }
      });
    },
  };
}
