import { css } from '@emotion/react';
import { ComponentType, useContext, useEffect, useRef, useState } from 'react';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import {
  AccordionLectureComponentData,
  isStyle1AccordionLectureComponent,
  isStyle2AccordionLectureComponent,
  AccordionSectionLectureComponent,
  isStyle3AccordionLectureComponent,
  isStyle4AccordionLectureComponent,
  isStyle5AccordionLectureComponent,
  AccordionLectureComponent as AccordionLectureComponentType,
} from 'redux/schemas/models/lecture-component';
import {
  addAccordionSection,
  updateAccordionSection,
  NewAccordionSection,
} from 'redux/actions/lecture-pages';
import usePrevious from 'shared/hooks/use-previous';
import { RootState } from 'redux/schemas';
import { useAppDispatch } from 'redux/store';
import BaseLectureComponentContext from 'lecture_pages/directives/components/base-lecture-component/context';

import { AngularServicesContext } from 'react-app';
import { Container, Draggable } from 'react-smooth-dnd';
import { findWhere } from 'underscore';

import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { getCurrentCourse } from 'redux/selectors/course';
import { NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { primary } from 'styles/global_defaults/colors';
import AccordionSection from './accordion-section';
import { SectionContainerProps } from './accordion-types';

import { LecturePageMode, LectureComponentProps } from '..';
import AddSection from './add-section';
import Style1SectionContainer from './style-1-section-container';
import Style2SectionContainer from './style-2-section-container';
import Style3SectionContainer from './style-3-section-container';
import Style4SectionContainer from './style-4-section-container';
import Style5SectionContainer from './style-5-section-container';

// tags to be skipped. Previously we used to to ignore span's which are used to
// set text color. Tags should be uppercase
const TAGS_DENY_LIST = [];

/**
 * Copy the text style in the markup and applies it to an optional text
 * returning it as a HTML string
 * @param markup html to copy the style from
 * @param textContent text to apply the format
 */
/* @ngInject */
export function copyTitleStyle(markup: string, textContent?: string) {
  const container = document.createElement('div');
  container.innerHTML = markup;

  // skip it if there isn't text
  if (container.textContent === '') {
    return '';
  }

  const result = document.createElement('div');
  let lastSourceElement: Element = container;
  let lastTargetElement: Element = result;

  // repeat while the leaf node isn't found
  while (lastSourceElement.childNodes.length && lastSourceElement.firstChild.nodeType === Node.ELEMENT_NODE) {
    // same as lastSourceElement.childNode but returns a Element type
    lastSourceElement = lastSourceElement.children[0];

    if (!TAGS_DENY_LIST.includes(lastSourceElement.tagName)) {
      const newNode = lastSourceElement.cloneNode();
      lastTargetElement.appendChild(newNode);
      lastTargetElement = newNode as Element;
    }
  }

  lastTargetElement.appendChild(
    typeof textContent === 'string' ? document.createTextNode(textContent) : document.createElement('br'),
  );
  return result.innerHTML;
}

type PromiseState = 'IDLE' | 'LOADING';

type UpdateAccordionSectionThunkResult = ReturnType<ReturnType<typeof updateAccordionSection>>;
type UpdateSectionsPromises = Record<number, UpdateAccordionSectionThunkResult>;

/* @ngInject */
export default function AccordionLectureComponent(props: LectureComponentProps) {
  const params = useLecturePageParams();
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const styles = css`
    .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
      overflow: visible;

      cursor: pointer;
      .collapse {
        cursor: default;
        .fr-element {
          cursor: text;
        }
      }
      .fr-element > * {
        cursor: text;
      }
    }

    ${isDragging ? css`
      .smooth-dnd-container {
        border: 1.5px dashed ${primary};
        padding: 5px;
        & .accordion-lecture-component-section {
          pointer-events: none;
        }
      }
    ` : ''}
  `;

  const dispatch = useAppDispatch();
  const angularServices = useContext(AngularServicesContext);

  // object to store update section promises by section ID
  const updateSectionsPromisesRef = useRef<UpdateSectionsPromises>({});

  const baseComponentContext = useContext(BaseLectureComponentContext);
  const [addSectionState, setAddSectionState] = useState<PromiseState>('IDLE');
  const lectureComponent = useSelector((state) => {
    const lectureComponentId = props.lectureComponent.id.toString();
    return state.models.lectureComponents[lectureComponentId];
  }) as AccordionLectureComponentData;
  const currentCourse = useSelector(getCurrentCourse);

  const { accordionSections } = lectureComponent;

  const { sharedProps, setSharedProps, copyComponent, moveComponent, deleteComponent } = baseComponentContext;

  const options: NvDropdownOption[] = [
    {
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.ACCORDION.DELETE_ALL(),
      class: 'text-danger',
      callback: deleteComponent,
    },
  ];

  if (!currentCourse.isContentManagementCollection) {
    options.unshift(
      {
        type: 'text',
        text: t.LECTURE_PAGES.COMPONENTS.COPY_TO_ELLIPSIS(),
        callback: copyComponent,
        preventClosing: true,
      },
      {
        type: 'text',
        text: t.LECTURE_PAGES.COMPONENTS.MOVE_TO_ELLIPSIS(),
        callback: moveComponent,
        preventClosing: true,
      },
      { type: 'divider' },
    );
  }

  useEffect(() => {
    setSharedProps({
      ...sharedProps,
      extraOptions: {
        mode: 'custom',
        options: [
          ...options,
        ],
      },
    });
  }, []);

  /** Whether or not this component was just now added to the page */
  const isNewComponent = useSelector<RootState, boolean>((state) => state.app.newComponent === lectureComponent?.id) ?? false;

  // #region handlers
  function dispatchAddAccordionSection() {
    const lastSection = accordionSections[accordionSections.length - 1];
    const newSection: NewAccordionSection = {
      viewOptions: lastSection.viewOptions,
      index: accordionSections.length,
      picture: lastSection.picture,
      content: '',
      header: copyTitleStyle(lastSection.header),
    };

    const result = dispatch(
      addAccordionSection({
        childLectureComponent: newSection,
        catalogId: params.catalogId,
        lecturePageId: props.currentLecture.id,
        accordionId: props.lectureComponent.id,
      }),
    );
    setAddSectionState('LOADING');
    result.finally(() => {
      setAddSectionState('IDLE');
    });
  }

  function onAccordionSectionChange(sectionData: AccordionSectionLectureComponent) {
    if (updateSectionsPromisesRef.current[sectionData.id]) {
      // we'll abort pending network request for the current section before
      // creating a new one when updating an accordion component, the Redux
      // state is updated before the network call in a optimistically way and
      // also after it with the data returned from the server (useful e.g. when
      // uploading pictures) but we need to cancel in-progress request before
      // triggering a new one to prevent race conditions
      updateSectionsPromisesRef.current[sectionData.id].abort();
    }
    updateSectionsPromisesRef.current[sectionData.id] = dispatch(
      updateAccordionSection({
        childLectureComponent: sectionData,
        catalogId: params.catalogId,
        lecturePageId: props.currentLecture.id,
        accordionId: props.lectureComponent.id,
      }),
    );

    updateSectionsPromisesRef.current[sectionData.id].then((result) => {
      if ((result as any)?.error?.name !== 'AbortError') {
        // aborted promises also "resolve", skip them
        delete updateSectionsPromisesRef.current[sectionData.id];
      }
    });
  }
  // #endregion


  let SectionContainerComponent: ComponentType<SectionContainerProps<any>>;
  let addNewSectionDisableMessage: string;

  if (isStyle1AccordionLectureComponent(lectureComponent)) {
    SectionContainerComponent = Style1SectionContainer;
  } else if (isStyle2AccordionLectureComponent(lectureComponent)) {
    SectionContainerComponent = Style2SectionContainer;
  } else if (isStyle3AccordionLectureComponent(lectureComponent)) {
    SectionContainerComponent = Style3SectionContainer;
    if (accordionSections.length === 9) {
      addNewSectionDisableMessage = t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_3.DISABLE_ADD_NEW_SECTION();
    }
  } else if (isStyle4AccordionLectureComponent(lectureComponent)) {
    SectionContainerComponent = Style4SectionContainer;
    if (accordionSections.length === 26) {
      addNewSectionDisableMessage = t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_4.DISABLE_ADD_NEW_SECTION();
    }
  } else if (isStyle5AccordionLectureComponent(lectureComponent)) {
    SectionContainerComponent = Style5SectionContainer;
  }

  // track of the sections used the last render
  const previousSections = usePrevious(accordionSections);

  let autoExpandId: number;

  const isFirstSection = previousSections === undefined && isNewComponent;
  const isNewSection = previousSections !== undefined && previousSections.length < accordionSections.length;

  if (props.mode === LecturePageMode.EDIT && (isFirstSection || isNewSection)) {
    autoExpandId = accordionSections[accordionSections.length - 1].id;
  }

  const handleDrop = (sections, dragResult) => {
    const { removedIndex, addedIndex } = dragResult;
    if (removedIndex === null || addedIndex === null || removedIndex === addedIndex) return;
    const result = [...sections];

    const itemToAdd = result.splice(removedIndex, 1)[0];
    result.splice(addedIndex, 0, itemToAdd);
    dispatch(
      updateAccordionSection({
        childLectureComponent: { ...itemToAdd, index: addedIndex },
        catalogId: params.catalogId,
        lecturePageId: props.currentLecture.id,
        accordionId: props.lectureComponent.id,
      }),
    );
  };

  if (props.mode === LecturePageMode.EDIT) {
    return (
      <div className='accordion-lecture-component' css={styles}>
        <Container
          lockAxis='y'
          dragClass='drag-class'
          nonDragAreaSelector='.collapse, .fr-element > *'
          getGhostParent={() => document.body}
          onDragStart={({ willAcceptDrop }) => setIsDragging(willAcceptDrop)}
          onDragEnd={() => setIsDragging(false)}
          onDrop={res => handleDrop(accordionSections, res)}
        >
          {accordionSections.map((sectionData, sectionIndex) => (
            <Draggable key={sectionData.id}>
              <div>{/** This div is where the dragClass is applied. Using a separate div prevents interference with AccordionSection styles */}
                <AccordionSection
                  autoExpand={autoExpandId === sectionData.id}
                  key={sectionData.id}
                  index={sectionIndex}
                  sectionData={sectionData}
                  SectionContainerComponent={SectionContainerComponent}
                  accordionSections={accordionSections}
                  mode={props.mode}
                  onAccordionSectionChange={onAccordionSectionChange}
                  currentLecture={props.currentLecture}
                />
              </div>
            </Draggable>
          ))}
        </Container>
        <AddSection
          onClick={dispatchAddAccordionSection}
          isLoading={addSectionState === 'LOADING'}
          disableMessage={addNewSectionDisableMessage}
        />
      </div>
    );
  }

  return (
    <div className='accordion-lecture-component' css={styles}>
      {accordionSections.map((sectionData, sectionIndex) => (
        <AccordionSection
          autoExpand={autoExpandId === sectionData.id}
          key={sectionData.id}
          index={sectionIndex}
          sectionData={sectionData}
          SectionContainerComponent={SectionContainerComponent}
          accordionSections={accordionSections}
          mode={props.mode}
          onAccordionSectionChange={onAccordionSectionChange}
          currentLecture={props.currentLecture}
        />
      ))}
    </div>
  );
}
