import store from 'redux/store';
import { unsetLecturePageAsCached } from '../../redux/actions/lecture-pages';
import { getSkillTagsFromTaggings } from 'redux/selectors/skills-feedback';
import { getLectureComponent } from 'redux/selectors/lecture-components';
import { getSkillTagsFromAngular } from '../../shared/services/nv-util';
import { RecordingEvent } from '../components/live-session-card/live-session-card';

/* @ngInject */
export default function LiveSessionModel(
  $timeout,
  $uibModal,
  CurrentCourseManager,
  LiveSessionResources,
  TimelinesManager,
  ReactTimelineService,
  moment,
) {
  class LiveSession {
    preprocessRecording() {
      const session = this.sessions?.[0] || {};
      const { recording = {} } = session;
      if (recording?.file?.url) {
        if (recording.file.lowDefaultPlayback) {
          recording.file.lowQualityUrl = recording.file.url;
        }
        if (recording.file.mediumDefaultPlayback) {
          recording.file.mediumQualityUrl = recording.file.url;
        }
        if (recording.file.highDefaultPlayback) {
          recording.file.highQualityUrl = recording.file.url;
        }
      }
    }

    constructor(attributes) {
      Object.assign(this, attributes);
      this.preprocessRecording();
      this.recordingEventCallback = this.recordingEventCallback.bind(this);
    }

    showDownloadLink() {
      const session = this.sessions?.[0] || {};
      const file = session?.recording?.file ?? null;
      return file && (file.isDownloadable || file.textTranscriptUrl || file.slidesUrl);
    }

    isCompleted() {
      const session = this;
      const { attendeeInfo = {} } = session;
      return attendeeInfo && (attendeeInfo.status === 'attended_manual' || attendeeInfo.status === 'attended_auto' || attendeeInfo.status === 'watched_recording');
    }

    isMissed() {
      const session = this.sessions?.[0] || {};
      const { attendeeInfo = {} } = session;
      return attendeeInfo?.status === 'missed';
    }

    beginningSoon() {
      const session = this.sessions?.[0] || {};
      const { startTime } = session;
      return !!startTime && moment(startTime) <= moment().add(10, 'minutes');
    }

    hasEnded() {
      const session = this.sessions?.[0] || {};
      const { startTime, duration } = session;
      return !!(startTime && duration && moment(startTime).add(duration, 'minutes') <= moment());
    }

    hasErrors() {
      const session = this.sessions?.[0] || {};
      const { errorCode } = session;
      return errorCode !== null && errorCode !== undefined && errorCode.code > 0;
    }

    validateEmails(emails) {
      const session = this.sessions?.[0] || {};
      const { id } = session;
      return LiveSessionResources.validateStudents({ catalogId: CurrentCourseManager.course.catalogId, liveSessionId: id }, { emails })
        .$promise.then((response) => response.result);
    }

    dismissNotification() {
      const session = this.sessions?.[0] || {};
      const { id } = session;
      return LiveSessionResources.dismissNotification({ catalogId: CurrentCourseManager.course.catalogId, liveSessionId: id }, {})
        .$promise.then((response) => response.result);
    }

    recordJoinIntention(sessionId) {
      return LiveSessionResources.recordJoinIntention({ catalogId: CurrentCourseManager.course.catalogId, liveSessionId: sessionId }, {})
        .$promise.then((response) => response.result);
    }

    uploadAttendance(sessionId, attendees, overwrite_existing_attendees) {
      const session = this.sessions?.[0] || {};
      const { id } = session;
      return LiveSessionResources.uploadSessionAttendance({ catalogId: CurrentCourseManager.course.catalogId, liveSessionId: sessionId ?? id }, { attendees, overwrite_existing_attendees })
        .$promise.then((response) => response.result);
    }

    deleteSession(sessionId) {
      return LiveSessionResources.deleteSession({ catalogId: CurrentCourseManager.course.catalogId, liveSessionId: sessionId })
        .$promise.then((response) => response.result);
    }

    manualUploadRecording(sessionId, unique_id, file_name) {
      const session = this.sessions?.[0] || {};
      const id = sessionId ?? session.id;
      const video = unique_id && file_name ? { unique_id, file_name } : null;
      return LiveSessionResources.uploadSessionRecording({ catalogId: CurrentCourseManager.course.catalogId, liveSessionId: id }, { video })
        .$promise.then((response) => {
          this.sessions[0].recording = response.result;
          this.preprocessRecording();
        });
    }

    recordingEventCallback(event, second, total, lecturePageId, componentId, sessionId = null) {
      if (event !== 'unload' || second !== 0) {
        // we are currently using a get so the parameter is not converted
        const liveSessionId = sessionId || this.id;
        const { lectureComponentId, pointsReceived } = this;
        this.lastEventCallbackPromise = LiveSessionResources.recordingWatchEvent({
          catalogId: CurrentCourseManager.course.catalogId,
          liveSessionId,
          second,
          video_action: event,
          total,
        }).$promise.then((response) => {
          const lectureVideo = response.result;
          const skillTags = getSkillTagsFromAngular(lectureComponentId);
          const hasPoints = !!lectureVideo.pointsReceived;
          const shouldShowCelebrationModal = lectureVideo.pointsReceived > pointsReceived && event === RecordingEvent.ENDED;

          if (shouldShowCelebrationModal) {
            if (hasPoints) {
              ReactTimelineService.updateTimeline(lecturePageId);
              TimelinesManager.updateComponentPointsAndProgress(
                lecturePageId,
                'LiveSessionLectureComponent',
                componentId,
                lectureVideo.pointsReceived,
                lectureVideo.totalPoints[0],
              );
              this.pointsReceived = lectureVideo.pointsReceived;
              if (!this.attendeeInfo) this.attendeeInfo = {};
              this.attendeeInfo.status = 'watched_recording';
              store.dispatch(unsetLecturePageAsCached(lecturePageId));
            }
            $uibModal.open({
              templateUrl: 'shared/templates/points-modal.html',
              windowClass: 'points-modal',
              controller: 'PointsModalCtrl as vm',
              resolve: {
                pointsReceived: lectureVideo.pointsReceived,
                leaderboardPoints: lectureVideo.leaderboardPoints,
                leaderboardRank: lectureVideo.leaderboardRank,
                priorLeaderboardRank: lectureVideo.priorLeaderboardRank,
                extras: { skillTags },
              },
            });
          }

          return this;
        });

        // the timecallback happens before the event callback sometimes
        $timeout(() => {
          this.startPlaybackFrom = second;
        });

        return this.lastEventCallbackPromise;
      }

      return null;
    }

    recordingTimeCallback(second) {
      this.startPlaybackFrom = second;
    }
  }

  return LiveSession;
}
