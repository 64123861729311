/* @ngInject */
export default function NvTeamHeader(
  $stateParams,
  $state,
  CurrentCourseManager,
  config,
) {
  return {
    restrict: 'A',
    scope: {
      collaborators: '=',
      team: '=',
      hideWorkspaceButton: '=?',
      allCollaboratorsGiven: '=?',
    },
    controller: function ctrl(
    ) {
'ngInject';
      const vm = this;
      const spacingAroundTeam = 20; // $standard-spacing

      vm.CurrentCourseManager = CurrentCourseManager;
      vm.$stateParams = $stateParams;
      vm.$state = $state;
      vm.config = config;

      vm.totalCollaboratorsCount = vm.allCollaboratorsGiven ? vm.collaborators.length : vm.team.numApprovedMembers;
      vm.numToShow = vm.collaborators.length;

      vm.closeTeamWarningPopover = () => {
        vm.teamWarningPopoverOpen = false;
      };

      vm.resizeAssignmentAndTeamTitles = (totalWidth, itemWidth) => {
        const collaboratorsAvailableCount = vm.collaborators.length;

        if ((collaboratorsAvailableCount === vm.totalCollaboratorsCount && (totalWidth >= itemWidth * collaboratorsAvailableCount))
                || (totalWidth >= itemWidth * vm.totalCollaboratorsCount + itemWidth)) {
          vm.numToShow = collaboratorsAvailableCount;
        } else {
          vm.numToShow = Math.floor(totalWidth / itemWidth) - 1;
        }
      };
    },
    link(scope, elem, attrs, ctrl) {
      scope.$watchCollection(
        () => [
          ctrl.collaborators.length,
          elem.find('.team-members').width(),
          elem.find('.team-member').outerWidth(true),
        ],
        (newValues, oldValues) => {
          ctrl.resizeAssignmentAndTeamTitles(newValues[1], newValues[2]);
        },
        true,
      );
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'exercises/templates/nv-team-header.html',
  };
}
