import angular from 'angular';

import StateManagerService from 'shared/services/state-manager';
import PageLevelManagerService from 'shared/services/page-level-manager';

const L4ModalCloseEvent = 'l4-modal-close';

class L4Modal {
  $animate: any;

  $compile: angular.ICompileService;

  $timeout: angular.ITimeoutService;

  $document: angular.IDocumentService;

  $rootScope: angular.IRootScopeService & any;

  $controller: angular.IControllerService;

  StateManager: ReturnType<typeof StateManagerService>;

  $templateCache: angular.ITemplateCacheService;

  PageLevelManager: ReturnType<typeof PageLevelManagerService>;

  current: {
    element: JQuery;
    scope: angular.IScope;
  } | null;

  /* @ngInject */
  constructor(
    $animate,
    $compile,
    $timeout,
    $document,
    $rootScope,
    $controller,
    StateManager,
    $templateCache,
    PageLevelManager,
  ) {
    this.$animate = $animate;
    this.$compile = $compile;
    this.$timeout = $timeout;
    this.$document = $document;
    this.$rootScope = $rootScope;
    this.$controller = $controller;
    this.StateManager = StateManager;
    this.$templateCache = $templateCache;
    this.PageLevelManager = PageLevelManager;

    $rootScope.$on(
      '$stateChangeSuccess',
      (event, toState) => {
        if (toState.data.level === 4 && this.isVisible()) {
          this.close();
        }
      },
    );
  }

  open(templateUrl, controllerName, locals) {
    // Save the focused element (accessibility support)
    this.$rootScope.focusedElementPreL4 = this.$document[0].activeElement;

    if (this.isVisible()) {
      this.close();
    }

    if (this.StateManager.lastStateEntered.data.level === 4) {
      this.PageLevelManager.callL4CloseCallbacks();
    }

    // Show L4 Modal:
    const modalTemplate: string = this.$templateCache.get('layouts/templates/l4-modal.html');

    const element = $(modalTemplate);

    const scope = this.$rootScope.$new(true);

    this.current = {
      element,
      scope,
    };

    this.$controller('L4ModalCtrl', {
      $scope: scope,
      $element: element,
      close: () => this.close(),
    });

    this.$compile(element)(scope);
    this.$animate.enter(element, document.getElementById('l4-modal'));

    // Show L4 modal content:
    const template: string = this.$templateCache.get(templateUrl);

    let contentContainer;

    element.each((_, each) => {
      if (!contentContainer) {
        if ($(each).hasClass('modal-page')) {
          contentContainer = each;
        }
      }
    });

    const innerElement = $(template);

    const templateScope = scope.$new(true);

    this.$controller(controllerName, {
      ...locals,
      $scope: templateScope,
      $element: innerElement,
      forwardOnModalClose: (fn) => this.forwardOnModalClose(fn),
      closeModal: (skipCheck?: boolean) => this.close(skipCheck),
    });

    this.$compile(innerElement)(templateScope);

    this.$animate.enter(innerElement, contentContainer);
  }

  close(skipCheck: boolean = false) {
    const finished = skipCheck ? true : this.notifyModalClosing();

    if (finished) {
      this.$timeout(() => {
        this.closeL4();
      });
    }
  }

  closeL4() {
    this.$animate.leave(this.current.element);

    this.current.scope.$destroy();

    this.current = null;

    this.$rootScope.focusedElementPreL4.focus();
  }

  isVisible() {
    return !!this.current;
  }

  notifyModalClosing = () => {
    const event = new Event(L4ModalCloseEvent, { cancelable: true });
    return document.dispatchEvent(event);
  };

  forwardOnModalClose = (
    listener: Function,
  ) => {
    const actualListener = (e) => listener(e);

    document.addEventListener(L4ModalCloseEvent, actualListener);
    return () => {
      document.removeEventListener(L4ModalCloseEvent, actualListener);
    };
  };
}

export default L4Modal;
