import React from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { useAppDispatch } from 'redux/store';
import NvSwitch from 'shared/components/inputs/nv-switch';
import { css } from '@emotion/react';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { getCourseAliases } from 'redux/selectors/course';
import { CourseAliases } from 'redux/schemas/models/course';
import { setAutoGenerateProcess } from 'redux/actions/lecture-components';
import { ComponentType } from 'redux/schemas/models/lecture-component';
import { AutoGenerateParams } from 'redux/schemas/app/lecture-component';
import { getAutoGenerateProcess } from 'redux/selectors/lecture-components';
import NvPopover from 'shared/components/nv-popover';
import { black } from 'styles/global_defaults/colors';
import { config } from '../../../../config/pendo.config.json';

export const AutoGeneratedTranscriptCaptionsSwitch = () => {
  const dispatch = useAppDispatch();
  const currentInstitution = useSelector(getCurrentInstitution);
  const { lectureAliases }: CourseAliases = useSelector(getCourseAliases);
  const autoGenerateData: AutoGenerateParams = useSelector(state => getAutoGenerateProcess(state, ComponentType.VIDEO));
  const institutionHasAutoGenerateData = currentInstitution.speechToTextEnabled;

  const styles = css`
    display: flex;
    align-items: center;
    gap: ${standardSpacing}px;

    .popover-wrapper {
      display: flex;
    }

    ${!institutionHasAutoGenerateData && css`
      .nv-switch {
        opacity: 0.5;
      }
    `}
  `;
  const popoverStyles = css`
    background: ${black};

    .arrow::after {
      border-top-color: ${black};
    }
  `;

  // Helper function to update the params of the auto generate captions and transcript process
  const updateAutoGenerateProcess = (value) => {
    dispatch(setAutoGenerateProcess({
      componentType: ComponentType.VIDEO,
      params: {
        isEnabled: value,
        isCaptionEnabled: value,
        isTranscriptEnabled: value,
      },
    }));
  };

  // By default, the auto generate should be configured based on the 'speech_to_text_enabled' institution setting
  React.useEffect(() => {
    updateAutoGenerateProcess(institutionHasAutoGenerateData);
  }, []);

  const handleSwitchChange = newChecked => updateAutoGenerateProcess(newChecked);

  return (
    <div css={styles}>
      <NvPopover
        enabled={!institutionHasAutoGenerateData}
        showOnHover
        placement='top-start'
        preventOverflow={false}
        overlayStyles={popoverStyles}
        content={(
          <p className='m-0 text-small semi-bold text-white'>
            {t.LECTURE_VIDEO.AUTO_GENERATED_TRANSCRIPT_AND_CAPTIONS.POPOVER()}
          </p>
        )}
      >
        <NvSwitch
          className='nv-switch'
          enabledIcon='gen-ai'
          onChange={handleSwitchChange}
          checked={autoGenerateData.isEnabled}
          disabled={!institutionHasAutoGenerateData}
          data-qa={config.pendo.aiTranscriptCaptions.switch}
        />
      </NvPopover>
      <span>
        {t.LECTURE_VIDEO.AUTO_GENERATED_TRANSCRIPT_AND_CAPTIONS.LABEL({ ...lectureAliases })}
      </span>
    </div>
  );
};

export default AutoGeneratedTranscriptCaptionsSwitch;
