/* eslint-disable max-classes-per-file */
import { ExternalToolWebLinkTypes } from 'redux/schemas/models/external-tool';

export const STATUS = {
  IN_PROGRESS: 'in_progress',
  NOT_STARTED: 'not_started',
  COMPLETED: 'completed',
};

const optionsByType = {
  [ExternalToolWebLinkTypes.WEB_EMBED]: {
    i18nKey: 'WEB_LINK',
    pendoTagName: 'embedLink',
    iconClass: 'icon-web-embeds',
    externalTool: {
      toolType: ExternalToolWebLinkTypes.WEB_EMBED,
      url: null,
      activityType: 'reading',
      autocompleteScorm: false,
    },
    allowFullScreen: true,
  },
  [ExternalToolWebLinkTypes.WEB_LINK]: {
    i18nKey: 'NON_EMBEDDED_WEB_LINK',
    pendoTagName: 'nonEmbeddedWebLink',
    iconClass: 'icon-admin-embeds',
    externalTool: {
      toolType: ExternalToolWebLinkTypes.WEB_LINK,
      url: null,
      activityType: 'reading',
      autocompleteScorm: false,
    },
  },
};

/* @ngInject */
export default function EmbedLectureComponentModel(
  _,
  ExternalToolLectureComponentModel,
  config,
) {
  function classFactory(type) {
    class EmbedLectureComponentWithType extends ExternalToolLectureComponentModel {
      constructor(attributes) {
        const defaults = {
          editFormTemplateUrl: 'lecture_pages/templates/components/embed-edit-modal.html',
          formName: 'editEmbedForm',
          label: optionsByType[type].i18nKey,
          externalTool: {
            ...optionsByType[type].externalTool,
          },
          embedTypes: {
            WEB_LINK: ExternalToolWebLinkTypes.WEB_LINK,
            WEB_EMBED: ExternalToolWebLinkTypes.WEB_EMBED,
          },
          allowFullScreen: optionsByType[type].allowFullScreen,
        };
        super({ ...defaults, ...attributes });
      }

      shouldDisableMarkDone(editMode) {
        return type === ExternalToolWebLinkTypes.WEB_LINK
          && (this.externalTool.progress !== STATUS.IN_PROGRESS || editMode);
      }

      isAnActivity() {
        return this.externalTool.isTodo || this.externalTool.pointsConfiguration?.points;
      }

      hasSumTotalIntegration() {
        return type === ExternalToolWebLinkTypes.WEB_LINK
          && this.externalTool.hasSumTotalIntegration;
      }

      shouldShowMarkDone() {
        return !this.hasSumTotalIntegration() && this.isAnActivity() && !this.externalTool.isResizing;
      }

      shouldShowCheckMyProgress() {
        return this.hasSumTotalIntegration() && this.isAnActivity() && !this.isCompleted();
      }

      getModalLabel(name) {
        return `LECTURE_PAGES.COMPONENTS.${this.label}.MODAL.${name}`;
      }

      getStatus() {
        return this.externalTool.progress;
      }

      inProgress() {
        return this.getStatus() === STATUS.IN_PROGRESS;
      }

      isNotStarted() {
        return this.getStatus() === STATUS.NOT_STARTED;
      }

      isCompleted() {
        return this.getStatus() === STATUS.COMPLETED;
      }
    }

    EmbedLectureComponentWithType.iconClass = optionsByType[type].iconClass;
    EmbedLectureComponentWithType.descriptionKey = `LECTURE_PAGES.COMPONENTS.${optionsByType[type].i18nKey}.DESCRIPTION`;
    EmbedLectureComponentWithType.showModalBeforeCreate = true;
    EmbedLectureComponentWithType.pendoTagName = config.pendo.lectureEdit[optionsByType[type].pendoTagName];
    return EmbedLectureComponentWithType;
  }

  class EmbedLectureComponent extends ExternalToolLectureComponentModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      super(_.extend({}, attributes), isSample, useDefaults);
    }

    shouldShowMarkDone() {
      return this.externalTool.isTodo || this.externalTool.pointsConfiguration;
    }

    static create(type) {
      return classFactory(type);
    }
  }

  EmbedLectureComponent.iconClass = 'icon-admin-embeds';
  EmbedLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.EMBED.DESCRIPTION';
  EmbedLectureComponent.showModalBeforeCreate = true;
  EmbedLectureComponent.pendoTagName = config.pendo.lectureEdit.embedLink;

  return EmbedLectureComponent;
}
