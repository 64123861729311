import { CombinedInstitution } from 'redux/schemas';
import { User } from 'redux/schemas/models/my-account';
import _ from 'underscore';

export enum PermissionTypes {
  LEARNER = 0,
  CUSTOM_ROLE = 1,
  CONFIGURATION_REGISTRATION = 2,
  INSTRUCTOR = 4,
  TEACHER_ASSISTANT = 8,
  COURSE_BUILDER = 16,
  REPORT_ADMIN = 32,
  MENTOR = 64,
  LEARNER_REGISTRATION = 128,
}

/**
 * The collection uses the existing and new permission types. So used the
 * existing type for those types and used new values for new types.
 */
export enum CollectionPermissionTypes {
  VIEWER = PermissionTypes.CUSTOM_ROLE,
  MANAGER = PermissionTypes.CONFIGURATION_REGISTRATION,
  BUILDER = PermissionTypes.COURSE_BUILDER,
  ADMIN = 50,
}

type RolesPermission = {
  type: PermissionTypes
  value: number
  name: string
  apiName: string
  helpText: string
  visible: boolean
};

export enum OrgAdminRole {
  ORG_ADMIN = 'org_admin',
  COURSE_MANAGER = 'course_manager',
  ORG_MENTOR = 'org_mentor',
}

/* @ngInject */
export default function RolesServiceService() {
  /**
     * See course_role_permissions.rb for a list of permissions. Permissions are provided as bit values
     * on a 'permissions' number in the DB. Permissions with a value <= 4 are special cased with the following rules:
     * LEARNER - Never used and should always be '0'
     * CUSTOM_ROLE - Set '1' when a user has a named role that lacks other permissions. All other permissions
     * should be set '0' by the backend when this is on.
     * NON_ADMIN_INSTRUCTOR - Never used and should always be '0'
     * None of these special cased permissions are visibile in frontend UI, as controlled by the 'visible'
     * property in the definitions below
     */

  class RolesService {
    permissions: RolesPermission[] = [
      {
        type: PermissionTypes.LEARNER,
        value: 0,
        name: '',
        apiName: 'learner',
        helpText: '',
        visible: false,
      },
      {
        type: PermissionTypes.CUSTOM_ROLE,
        value: 1,
        name: '',
        apiName: 'custom_roles',
        helpText: '',
        visible: false,
      },
      {
        type: PermissionTypes.CONFIGURATION_REGISTRATION,
        value: 2,
        name: 'INSTITUTIONS.ROLES.CONFIGURATION_AND_REGISTRATION',
        apiName: 'configuration_registration',
        helpText: 'INSTITUTIONS.ROLES.CONFIGURATION_AND_REGISTRATION_DESC',
        visible: true,
      },
      {
        type: PermissionTypes.LEARNER_REGISTRATION,
        value: 128,
        name: 'INSTITUTIONS.ROLES.LEARNER_REGISTRATION',
        apiName: 'learner_registration',
        helpText: 'INSTITUTIONS.ROLES.LEARNER_REGISTRATION_DESC',
        visible: true,
      },
      {
        type: PermissionTypes.INSTRUCTOR,
        value: 4,
        name: 'INSTITUTIONS.ROLES.INSTRUCTOR_FACILITATOR',
        apiName: 'instructor',
        helpText: 'INSTITUTIONS.ROLES.INSTRUCTOR_FACILITATOR_DESC',
        visible: true,
      },
      {
        type: PermissionTypes.TEACHER_ASSISTANT,
        value: 8,
        name: 'INSTITUTIONS.ROLES.TA_FACILITATOR',
        apiName: 'teacher_assistant',
        helpText: 'INSTITUTIONS.ROLES.TA_FACILITATOR_DESC',
        visible: true,
      },
      {
        type: PermissionTypes.COURSE_BUILDER,
        value: 16,
        name: 'INSTITUTIONS.ROLES.COURSE_BUILDER',
        apiName: 'course_builder',
        helpText: 'INSTITUTIONS.ROLES.COURSE_BUILDER_DESC',
        visible: true,
      },
      {
        type: PermissionTypes.REPORT_ADMIN,
        value: 32,
        name: 'INSTITUTIONS.ROLES.REPORT_ADMIN',
        apiName: 'report_admin',
        helpText: 'INSTITUTIONS.ROLES.REPORT_ADMIN_DESC',
        visible: true,
      },
      {
        type: PermissionTypes.MENTOR,
        value: 64,
        name: 'INSTITUTIONS.ROLES.MENTOR',
        apiName: 'mentor',
        helpText: 'INSTITUTIONS.ROLES.MENTOR_DESC',
        visible: true,
      },
    ];

    permissionsForBits(bitField) {
      const permissions = [];

      if (bitField === 0) {
        permissions.push(this.permissions[0]);
      } else {
        /* eslint-disable no-bitwise */
        this.permissions.forEach((permission) => {
          if (permission.value & bitField) {
            permissions.push(permission);
          }
        });
        /* eslint-enable no-bitwise */
      }

      return permissions;
    }

    getVisiblePermissions(bitField) {
      return _.filter(this.permissionsForBits(bitField), (p) => p.visible);
    }

    getOptionNameForPermission(permission) {
      switch (permission.type) {
        case PermissionTypes.CONFIGURATION_REGISTRATION:
          return 'configuration_registration';
        case PermissionTypes.INSTRUCTOR:
          return 'instructor';
        case PermissionTypes.TEACHER_ASSISTANT:
          return 'teacher_assistant';
        case PermissionTypes.COURSE_BUILDER:
          return 'course_builder';
        case PermissionTypes.REPORT_ADMIN:
          return 'report_admin';
        default:
          return '';
      }
    }

    /* Transforms the permissions list format defined in this RolesService back into the permissions options
       * format required by the backend */
    makePermissionOptions(permissionsList) {
      const permissionOptions = {};
      this.permissions.forEach((permission) => {
        permissionOptions[permission.apiName] = [
          false,
          permission.value.toString(),
        ];
      });

      _.forEach(permissionsList, (permission: RolesPermission) => {
        permissionOptions[permission.apiName][0] = true;
      });

      return permissionOptions;
    }

    hasPermission(bitField, permissionType) {
      // permissionType should be one of _PermissionTypes
      /* eslint-disable no-bitwise */
      return (bitField & permissionType) > 0;
      /* eslint-enable no-bitwise */
    }

    /** A bitfield designates an admin role if it indicates having any role besides 'mentor' */
    hasAdminPermissions(bitField) {
      return bitField > 1 && bitField !== PermissionTypes.MENTOR;
    }

    isAdminRole(roleModel) {
      return roleModel && this.hasAdminPermissions(roleModel.permission);
    }

    isMentor(roleModel) {
      return roleModel && this.hasPermission(roleModel.permission, PermissionTypes.MENTOR);
    }

    isLearner(roleModel) {
      return !(this.isAdminRole(roleModel) || this.isMentor(roleModel));
    }

    isNamedLearnerRole(roleModel) {
      return roleModel?.permission === PermissionTypes.CUSTOM_ROLE;
    }

    isNovoEdAdmin(user: User) {
      return user.admin;
    }

    isOrgAdmin(institution: CombinedInstitution) {
      return institution.isInstitutionalAdmin
        && institution.adminRole === OrgAdminRole.ORG_ADMIN;
    }

    isOrgCourseManager(institution: CombinedInstitution) {
      return institution.isInstitutionalAdmin
        && institution.adminRole === OrgAdminRole.COURSE_MANAGER;
    }

    // Collection related functions from here
    isCollectionAdminRole(roleModel) {
      return roleModel && roleModel.permission === CollectionPermissionTypes.ADMIN;
    }
  }

  return new RolesService();
}

export const RolesService = RolesServiceService();
