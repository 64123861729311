/* @ngInject */
export default function ConfirmationOverlays(
  _,
  $uibModal,
) {
  return {
    openConfirmationModal,
  };

  function openConfirmationModal(templateUrl, modalInstanceCtrl, modalOverlayData) {
    return $uibModal.open({
      backdropClass: 'modal-overlay-backdrop',
      controller: modalInstanceCtrl,
      controllerAs: 'vm',
      templateUrl,
      windowClass: 'modal-overlay',
      resolve: modalOverlayData,
      keyboard: false,
    });
  }
}
