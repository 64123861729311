import { css, SerializedStyles } from '@emotion/react';
import { connect } from 'react-redux';
import { RootState } from 'redux/schemas';
import _ from 'underscore';
import { removeAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageProps as IAlertMessage } from 'redux/schemas/app/alert-message';
import { flashMessageMaxWidth, flashMessageOuterMarginSize, alertMessageZIndex } from 'styles/global_defaults/scaffolding';
import { tablet, smallDesktop, desktop } from 'styles/global_defaults/media-queries';
import uuid from 'react-uuid';

import AlertMessage from './alert-message';

const DEFAULT_MESSAGE_DURATION = 10000;

type StateProps = {
  alertMessages: IAlertMessage[];
};

export type DispatchProps = {
  removeAlertMessage: typeof removeAlertMessage
};

type OwnProps = {};

export const AlertMessagesPanel = (props: StateProps & DispatchProps) => {
  const styles = css`
    &.alert-messages-panel{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      text-align: left;
      z-index: ${alertMessageZIndex};

      ${tablet(css`
        margin: 0 ${flashMessageOuterMarginSize}px;
      `)};

      ${smallDesktop(css`
        margin: 0 ${flashMessageOuterMarginSize}px;
      `)};

      ${desktop(css`
        width: ${flashMessageMaxWidth}px;
        margin-left: auto;
        margin-right: auto;
      `)};
    }
  `;

  _.each(props.alertMessages, (message, index) => {
    if (message.duration !== Infinity) {
      setTimeout(() => props.removeAlertMessage(index), message.duration || DEFAULT_MESSAGE_DURATION);
    }
  });

  return (
    <div css={styles} className='alert-messages-panel'>
      {props.alertMessages && props.alertMessages.map((message, i) => (
        <AlertMessage key={uuid()} {...message} removeAlertMessage={() => props.removeAlertMessage(i)} />
      ))}
    </div>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>((state, ownProps) => ({
  alertMessages: state.app.alertMessages,
  ...ownProps,
}), {
  removeAlertMessage,
})(AlertMessagesPanel);
