/* @ngInject */
export default function WorkspaceMeetingModalInstanceController(
  $scope,
  $uibModalInstance,
  $window,
  AlertMessages,
  ConfirmationOverlays,
  StateManager,
  CurrentUserManager,
  MentionablesModel,
  TeamWorkspaceManager,
  TimezonesService,
  _,
  existingMeeting,
  moment,
) {
  const vm = this;

  initialize();

  function initialize() {
    vm.MentionablesModel = MentionablesModel;
    vm.unsavedChangesMessage = 'FORM.NAVIGATE_AWAY.ARE_YOU_SURE';
    vm.dismissedByUser = false;
    vm.submitting = false; // so we can disable the submit button while in the process of submitting to prevent double submits
    vm.meetingData = existingMeeting || {};

    vm.submitMeeting = submitMeeting;
    vm.hasUnsavedChanges = hasUnsavedChanges;

    const timeZoneString = TimezonesService.correctSpelling(CurrentUserManager.user.timeZone || moment.tz.guess());
    const timeZoneKey = TimezonesService.timezones.find((t) => t.longName === timeZoneString)?.translationKey;
    vm.currentTimeZone = timeZoneKey ? `TIMEZONE.${timeZoneKey}` : timeZoneString;
  }


  function submitMeeting() {
    vm.submitting = true;

    if (existingMeeting) {
      TeamWorkspaceManager.updateMeetingPost(vm.meetingData).then((updatedPost) => {
        const cleanedUpResponse = {
          agenda: updatedPost.metaContent.agenda,
          id: updatedPost.metaContent.id,
          location: updatedPost.metaContent.location,
          meetingTime: updatedPost.metaContent.meetingTime,
          title: updatedPost.metaContent.title,
          userId: updatedPost.metaContent.userId,
        };

        vm.submitting = false;
        vm.dismissedByUser = true;
        $uibModalInstance.close(cleanedUpResponse);
      }).catch(
        (error) => {
          AlertMessages.error('', 'FORM.ERROR');
        },
      );
    } else {
      TeamWorkspaceManager.createMeetingPost(vm.meetingData).then(() => {
        vm.submitting = false;
        vm.dismissedByUser = true;
        $uibModalInstance.close();
      }).catch(
        (error) => {
          AlertMessages.error('', 'FORM.ERROR');
        },
      );
    }
  }

  function hasUnsavedChanges() {
    return vm.meetingForm.$dirty;
  }

  // - unsaved changes overlay
  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(
    () => vm.hasUnsavedChanges() && !vm.dismissedByUser,
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    vm.unsavedChangesMessage,
  );

  $scope.$on('modal.closing', ($event) => {
    if (vm.hasUnsavedChanges() && !vm.dismissedByUser) {
      $event.preventDefault();


      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        vm.dismissedByUser = true;
        $uibModalInstance.dismiss();
      });
    }
  });

  $scope.$on('$destroy', () => {
    vm.deregisterStateChangeStart?.();
  });
}
