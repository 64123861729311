/* @ngInject */
export default function ImageLectureComponentModel(
  $q,
  _,
  config,
  LectureComponentBaseModel,
  S3UploadFactory,
  S3NameSpaces,
) {
  class ImageLectureComponent extends LectureComponentBaseModel {
    constructor(attributes) {
      const defaults = {
        type: 'ImageLectureComponent',
        picture: null,
        viewOptions: {
          alignment: 'left',
          width: '100%',
        },

        // TODO: Do I need these?
        hasContent: true,
        uploadSupported: true,
        canBeCopied: true,
      };
      super(_.extend({}, defaults, attributes));
    }

    getPayload() {
      return _.pick(this, ['index', 'viewOptions', 'picture']);
    }
  }

  ImageLectureComponent.iconClass = 'icon-images';
  ImageLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.IMAGE.DESCRIPTION';
  ImageLectureComponent.type = 'ImageLectureComponent';
  ImageLectureComponent.createWithFileUpload = true;
  ImageLectureComponent.allowedFileTypes = 'image/*';
  ImageLectureComponent.isContentComponent = true;
  ImageLectureComponent.toolTipKey = 'LECTURE_PAGES.COMPONENTS.IMAGE.TOOLTIP';

  return ImageLectureComponent;
}
