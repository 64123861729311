import { css } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import { each, findKey, uniqueId } from 'underscore';
import moment from 'moment';

// Actions
import { useAppDispatch } from 'redux/store';
import {
  getQuestionSetSubmissions,
  getVideoPracticeSubmissions,
  resetVideoPracticeSubmission,
  resetQuestionSetSubmission,
} from 'redux/actions/users';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { addAlertMessage } from 'redux/actions/alert-messages';

// Selectors
import { getCourseAliases } from 'redux/selectors/course';

// Schemas
import { User } from 'redux/schemas/models/my-account';
import { ResetSubmissionType, SubmissionsAttempt } from 'redux/schemas/models/activity';
import { GetSubmissionsParams } from 'redux/schemas/api/user';
import { VideoPracticeSubmission } from 'redux/schemas/models/video-practice';
import { AlertMessageType } from 'redux/schemas/app/alert-message';

// Styles
import { doubleSpacing, halfSpacing, standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { handheld, notHandheld } from 'styles/global_defaults/media-queries';
import { gray5 } from 'styles/global_defaults/colors';

// Components
import { NvUserAvatar } from 'components/nv-user-avatar';
import NvIcon from 'shared/components/nv-icon';
import ClickableContainer from 'components/clickable-container';
import LoadingPlaceholder from 'shared/components/loading-placeholder';

const styles = css`
  & {
    margin: 0 ${doubleSpacing}px;
    max-width: 600px;

    ${handheld(css`
      margin: 0 ${standardSpacing}px;
    `)};
  }

  .action-buttons {
    margin-top: ${tripleSpacing}px;
  }

  .nv-user-avatar {
    .inline-name {
      display: flex;
      justify-content: center;
      font-weight: bold;
    }
  }

  .submission-list-header {
    border-bottom: 1px solid ${gray5};
  }

  .submission-row {
    border-bottom: 1px solid ${gray5};

    ${notHandheld(css`
      .submission-type {
        width: 20%;
      }

      .submission-title {
        width: 70%;
      }

      .submission-action {
        width: 10%;
        cursor: pointer;
      }

      .reset-column {
        width: 25%;
      }
    `)};
  }

  .loading-wrapper {
    .loading, .loading-text {
      margin-bottom: ${halfSpacing}px;
    }
  }
`;

type ResetAttemptsModalProps = {
  user: User,
  onClose: () => void;
};

const ResetAttemptsModal = ({
  user,
  onClose,
}: ResetAttemptsModalProps) => {
  const [submissions, setSubmissions] = useState([]);
  const [resetAttempts, setResetAttempts] = useState([]);
  const [loading, setLoading] = useState(false);
  const aliases = useSelector((state) => getCourseAliases(state));
  const [resetAttemptsHistoryOpen, setResetAttemptsHistoryOpen] = useState(false);

  const dispatch = useAppDispatch();
  const catalogId = useSelector((state) => state.app.currentCatalogId);

  const toggleResetAttemptsHistory = () => {
    setResetAttemptsHistoryOpen((resetAttemptsOpen) => !resetAttemptsOpen);
  };

  const fetchActivitiesSubmissions = useCallback(() => {
    if (catalogId && user?.id) {
      setLoading(true);
      const params: GetSubmissionsParams = {
        catalogId,
        userId: user.id,
      };

      Promise.all([
        dispatch(getQuestionSetSubmissions(params)),
        dispatch(getVideoPracticeSubmissions(params)),
      ]).then((actions) => {
        const [questionSetResponse, videoPracticeResponse] = actions;
        const allSubmissions = [];
        let allResetAttempts = [];

        if (questionSetResponse?.payload?.attempts?.length > 0) {
          each(questionSetResponse.payload.attempts, (attempt: SubmissionsAttempt) => {
            if (!attempt.hardDeadlinePassed && attempt.attempted) {
              allSubmissions.push(attempt);
            }
          });
        }

        if (videoPracticeResponse?.payload?.attempts?.length > 0) {
          each(videoPracticeResponse.payload.attempts, (videoPracticeSubmission: VideoPracticeSubmission) => {
            allSubmissions.push({
              id: videoPracticeSubmission.activity.id,
              type: ResetSubmissionType.PRACTICE,
              title: videoPracticeSubmission.activity.title,
            });
          });
        }

        if (questionSetResponse?.payload?.resetList?.length > 0
          || videoPracticeResponse?.payload?.resetList?.length > 0) {
          allResetAttempts = [
            ...questionSetResponse?.payload?.resetList,
            ...videoPracticeResponse?.payload?.resetList,
          ];
        }

        setSubmissions(allSubmissions);
        setResetAttempts(allResetAttempts);
        setLoading(false);
      });
    }
  }, [catalogId, dispatch, user.id]);

  useEffect(() => {
    fetchActivitiesSubmissions();
  }, [fetchActivitiesSubmissions]);

  const resetActivity = (submission) => {
    dispatch(openConfirmationDialog({
      title: submission.title,
      bodyText: t.USER_MANAGEMENT.RESET_ATTEMPTS_MODAL.RESET_ATTEMPTS_CONFIRM({
        ...aliases.learnersAliases,
        ...aliases.pointsAliases,
      }),
      confirmText: t.FORM.YES_SURE(),
      cancelText: t.FORM.CANCEL(),
      icon: 'warning',
      onConfirm: () => { onResetActivity(submission); },
    }));
  };

  const onResetActivity = (submission) => {
    if (submission.type === ResetSubmissionType.PRACTICE) {
      dispatch(resetVideoPracticeSubmission({
        catalogId,
        userId: user.id,
        activityId: submission.id,
      })).then((response) => {
        afterResetSubmit(response);
      });
    } else {
      dispatch(resetQuestionSetSubmission({
        catalogId,
        userId: user.id,
        questionSetIds: [submission.id],
      })).then((response) => {
        afterResetSubmit(response);
      });
    }
  };

  const afterResetSubmit = (response) => {
    if (response.error) {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.OOPS(),
        message: t.FORM.ERROR_SOMETHING_WRONG(),
      }));
    } else {
      dispatch(addAlertMessage({
        type: AlertMessageType.SUCCESS,
        header: t.FORM.SUCCESS_BANG(),
        message: t.USER_MANAGEMENT.RESET_ATTEMPTS_MODAL.RESET_ATTEMPTS_SUCCESS(),
      }));
      fetchActivitiesSubmissions();
    }
  };

  return (
    <div css={styles}>
      <NvUserAvatar
        borderType='round'
        size='lg'
        user={user}
        displayRoleBadge
        displayName
        inlineName
        className='pb-4'
      />
      {loading ? (
        <div className='loading-wrapper'>
          <LoadingPlaceholder />
        </div>
      ) : (
        <React.Fragment>
          {resetAttempts.length > 0 && (
            <ClickableContainer
              onClick={toggleResetAttemptsHistory}
              className='text-primary justify-content-center'
            >
              {t.USER_MANAGEMENT.RESET_ATTEMPTS_MODAL.HISTORY[
                resetAttemptsHistoryOpen ? 'HIDE' : 'VIEW'
              ]()}
            </ClickableContainer>
          )}
          {resetAttempts.length > 0 && resetAttemptsHistoryOpen && (
            <React.Fragment>
              <div className='mt-4'>
                <div className='submission-row d-none d-md-flex py-2 flex-column flex-md-row'>
                  <div className='w-50 pr-4 pb-2 pb-md-0 text-small gray-3'>
                    {t.USER_MANAGEMENT.RESET_ATTEMPTS_MODAL.ACTIVITIES()}
                  </div>
                  <div className='reset-column pr-4 pb-2 pb-md-0 text-small gray-3'>
                    {t.USER_MANAGEMENT.RESET_ATTEMPTS_MODAL.RESET_BY()}
                  </div>
                  <div className='reset-column pb-2 pb-md-0 text-small gray-3'>
                    {t.USER_MANAGEMENT.RESET_ATTEMPTS_MODAL.DATE()}
                  </div>
                </div>
                {resetAttempts.map(attempt => (
                  <div
                    key={uniqueId('col_')}
                    className='submission-row d-flex py-2 flex-column flex-md-row'
                  >
                    <div className='w-50 pr-4 pb-2 pb-md-0'>
                      {attempt.title}
                    </div>
                    <div className='reset-column pr-4 pb-2 pb-md-0'>
                      {attempt.resetBy}
                    </div>
                    <div className='reset-column pb-2 pb-md-0'>
                      {moment(attempt.resetAt).format('L hh:mma')}
                    </div>
                  </div>
                ))}
              </div>
              <div className='d-none d-md-flex justify-content-center mt-4'>
                <NvIcon
                  icon='in-progress'
                  size='larger'
                  className='gray-4'
                />
              </div>
            </React.Fragment>
          )}
          {submissions.length > 0 ? (
            <div className='mt-4'>
              <p className='d-none d-md-flex submission-list-header pb-2 mb-0'>
                {t.USER_MANAGEMENT.RESET_ATTEMPTS_MODAL.LIST_HEADER(user.firstName)}
              </p>
              {submissions.map(submission => (
                <div className='submission-row d-flex py-2 py-md-4 flex-column flex-md-row' key={submission.id}>
                  <div className='submission-type pr-4 pb-2 pb-md-0'>
                    {t.USER_MANAGEMENT.RESET_ATTEMPTS_MODAL[findKey(ResetSubmissionType, (type) => type === submission.type)]()}
                  </div>
                  <div className='submission-title pr-4 pb-2 pb-md-0'>
                    {submission.title}
                  </div>
                  <ClickableContainer
                    onClick={() => resetActivity(submission)}
                    className='submission-action text-primary d-flex justify-content-md-end label'
                  >
                    {t.USER_MANAGEMENT.RESET_ATTEMPTS_MODAL.RESET()}
                  </ClickableContainer>
                </div>
              ))}
            </div>
          ) : (
            <div className='d-flex flex-column align-items-center mt-4'>
              <NvIcon
                icon='assignments'
                size='ultra-large'
                className='text-gray-5'
              />
              <div className='text-gray-4 bold mt-4'>
                {t.USER_MANAGEMENT.RESET_ATTEMPTS_MODAL.NO_ACTIVITIES(user.firstName)}
              </div>
            </div>
          )}
        </React.Fragment>
      )}
      <div className='action-buttons d-flex justify-content-center mb-4'>
        <Button
          type='button'
          variant='primary'
          onClick={onClose}
        >
          {t.FORM.DONE()}
        </Button>
      </div>
    </div>
  );
};

export default ResetAttemptsModal;
