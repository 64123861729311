import { schema } from 'normalizr';
import { AxiosResponse } from 'axios';
import { MonthlyEnrollment, ExportTypes, EnrollmentPeriod, ExportFile, LicenseState, CourseRankingState, ExportStatus } from 'redux/schemas/models/license';
import { Institution } from 'redux/schemas/models/institution';
import { Course, CourseRegistrationType, CoursesNormalized } from 'redux/schemas/models/course';
import { CourseInformationFormCollection } from 'learning_journeys/components/clone/course-information-clone-modal';

export interface IInstitutionEntities {
  institutions: Institution,
}

export interface ICourseEntities {
  courses: CoursesNormalized,
}

const courseSchema = new schema.Entity<Course>('courses', {}, {
  idAttribute: 'catalogId',
});
export const coursesSchema = new schema.Array(courseSchema);

export interface TotalLoginsResponse {
  // This value is an array but it only ever contains one element
  totalLogins: number[];
  totalEnrollments: number;
}

export interface MonthlyEnrollmentsResponse {
  monthlyEnrollments: {
    [key: string]: MonthlyEnrollment;
  };
}

export type ExportDetailsResponse = {
  [keys in ExportTypes]?: [{
    period: EnrollmentPeriod;
    report_status: ExportStatus;
  }];
};

export interface getExportDetailsRequest {
  institutionId: number;
}

export interface getExportDetailsResponse {
  licenseDashboardExportDetails: ExportDetailsResponse;
}

export type CloneCohortOrCourse = {
  name: string,
  newReleaseDate: string,
  newCatalogId: string,
  copyAudioVideo: boolean,
  registrationType: CourseRegistrationType,
  createCohort?: boolean,
  admins: number[],
}

export type CloneMultipleCourseRequest = {
  id: number,
  clones: CloneCohortOrCourse[],
};

export type CloneCourse = {
  id: number,
  catalogId: string,
  name: string,
  startDate: string,
};

export type ExcludeCourse = {
  id: number,
};

export type CloneJourneyRequest = {
  id: string,
  catalogId: string,
  name: string,
  releaseDate: string,
  coursesStartDateConfig: number,
  copyAudioVideo: boolean,
  cloneCourses: CloneCourse[],
  excludeCourses: ExcludeCourse[],
};

export type JourneyInformationRequest = {
  catalogId: string,
};

export interface getCourseEnrollmentDetailsRequest {
  institutionId: number;
  courseId: number;
}

export interface getCourseEnrollmentDetailsResponse {
  courseEnrollmentExportDetails: {
    courseEnrollment: {
      period: string;
      reportStatus: ExportStatus;
    };
  };
  courseEnrollmentExportFileList: Array<{
    completedAt: string;
    url: string;
  }>;
}

export interface getExportFileListRequest {
  institutionId: number;
  period: EnrollmentPeriod;
}

export interface getExportFileListResponse {
  licenseDashboardExportFileList: ExportFile[];
}

export interface ExportDataRequest {
  institutionId: number;
  exportType: ExportTypes;
  period: EnrollmentPeriod;
  startTime: string;
  endTime: string;
  userId?: number;
  courseId?: number;
  courseName?: string;
  alertSuccessHeader?: string;
}

const license = new schema.Entity<LicenseState>('license');
const top10Courses = new schema.Entity<CourseRankingState>('top10Courses');
export default { license, top10Courses };
