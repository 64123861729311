import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';
import { Button } from 'react-bootstrap';
import { debounce } from 'underscore';
// redux
import { useSelector } from 'react-redux';
// spaces and styles
import { gray3, gray7, primary } from 'styles/global_defaults/colors';
import { doubleSpacing, halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { handheld } from 'styles/global_defaults/media-queries';
// components
import { RootState } from 'redux/schemas';
import { addSkillTag, getSkillTags } from 'redux/actions/skill-tags';
import { SkillTagsNormalized } from 'redux/schemas/api/skill-tag';
import { SkillTag } from 'redux/schemas/models/skill-tag';
import LoadingWrapper, { LoaderType } from 'shared/components/loading-wrapper';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { useAppDispatch } from 'redux/store';
import NVAddItem from '../../shared/components/nv-add-item';
import { NvBasicTable, BasicTableColumn, ColumnType, SortType } from '../../shared/components/nv-basic-table';
import BulkUploadSkillsModal from './bulk-upload-skills-modal';
import SkillRow from './skill-row';
import { config } from '../../../config/pendo.config.json';
import NvRoundButton from 'shared/components/nv-round-button';

// types
type HeaderProps = {
  total: number
};

// styles
const styles = css`
  &.skill-tags {
    ${handheld(css`
      padding: 0 ${standardSpacing}px 0 ${standardSpacing}px;
    `)};

    .buttons-container{
    margin-top: ${standardSpacing}px;
    }
    .column-centered {
      justify-content: center;
    }

    .skill-row {
      padding: ${halfSpacing}px 0 ${halfSpacing}px 0;
    }
  }
`;

const scrollHandlerStyles = css`
  height: calc(100vh - 120px);
  overflow-y: scroll;
`;

const DashboardHeader = (props: HeaderProps) => (
  <div className='title'>
    <div className='page-subtitle text-center'>
      {t.INSTITUTIONS.SKILL_TAGS.HEADER()} {props.total > 0 && `(${props.total})`}
    </div>
    <div className='description mt-2'>
      {t.INSTITUTIONS.SKILL_TAGS.DESCRIPTION()}
    </div>
  </div>
);

export const getColumns = () => {
  const cols: BasicTableColumn[] = [
    {
      key: 'name',
      name: t.INSTITUTIONS.SKILL_TAGS.SKILL_NAME(),
      className: 'column',
      sortable: true,
      gridWidth: '2fr',
      type: ColumnType.STRING,
    },
    {
      key: 'activitiesTagged',
      name: t.INSTITUTIONS.SKILL_TAGS.ACTIVITIES_TAGGED(),
      className: 'column-centered',
      sortable: true,
      gridWidth: '1fr',
      type: ColumnType.NUMBER,
    },
    {
      key: 'coursesTagged',
      name: t.INSTITUTIONS.SKILL_TAGS.COURSES_TAGGED(),
      className: 'column-centered',
      sortable: true,
      gridWidth: '1fr',
      type: ColumnType.NUMBER,
    },
  ];
  return cols;
};

const SkillTagsDashboard = (props) => {
  const dispatch = useAppDispatch();
  const listRef = useRef<HTMLDivElement>(null);

  const [lastAdded, setLastAdded] = useState(null);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);

  const isLoading = useSelector((state) => state.app.skillTag.isLoading);
  const total = useSelector((state) => state.app.skillTag.totalTags);
  const currentInstitutionId = useSelector((state: RootState) => state.app.currentInstitutionId);

  const tags = useSelector((state) => Object.fromEntries(
    Object.values(state.models.skillTags)
      // eslint-disable-next-line no-prototype-builtins
      .filter((tag: SkillTag) => tag.hasOwnProperty('activitiesTagged') && tag.hasOwnProperty('coursesTagged'))
      .map((tag: SkillTag) => [tag.id, tag]),
  ));

  const errorMessage = { message: t.INSTITUTIONS.SKILL_TAGS.ALREADY_EXISTS_ERROR() };

  useEffect(() => {
    if (!total || total > Object.keys(tags).length) {
      dispatch(getSkillTags({
        institutionId: currentInstitutionId,
        page: pageIndex,
      }));
    }
  }, [pageIndex]);

  // When user deletes a skill tag, we're refreshing the data (in order to avoid this issue: NOV-90567; see reducer for more info)
  // To do this, we remove all the skill tags from redux (this happens in the reducer).
  // So we need to check if the number of skill tags has changed.
  useEffect(() => {
    if (Object.keys(tags).length) return;

    // If we're on the first page, we need to manually make this call.
    // Checking for total prevents us from making 2 calls on page load
    if (pageIndex === 1 && total) {
      dispatch(getSkillTags({
        institutionId: currentInstitutionId,
        page: pageIndex,
      }));
      // If we weren't on page 1, setting the page to 1 will make the call for us
    } else {
      setPageIndex(1);
    }
  }, [Object.keys(tags).length]);

  useEffect(() => {
  }, [isLoading]);


  const onAddTag = async (value) => {
    const result = await dispatch(addSkillTag({ institutionId: currentInstitutionId, tag: { name: value } }));
    if (result.payload?.error) {
      return false;
    }
    setLastAdded(result.payload?.id);
    return true;
  };

  // TODO: This selector seems wrong; investigate.
  const overwritestyles = css`
    .&add-item-container {
      background-color: red;
    }
  `;

  const handleScroll = debounce(() => {
    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    const pctScrolled = (scrollTop / (scrollHeight - clientHeight));
    if (!isLoading && Object.keys(tags).length < total && pctScrolled > 0.8) {
      setPageIndex(pageIndex + 1);
    }
  }, 100);

  return (
    <div css={scrollHandlerStyles} className='scroll-handler' ref={listRef} onScroll={handleScroll}>
      <div css={styles} className='skill-tags body-text-wrapper'>
        {/* <SkillsTags lectureComponentId='14' lectureComponentName='QuizLectureComponent' lecturePageId='4' catalogId='local_course3' /> */}
        <DashboardHeader total={total} />
        <div className='d-flex buttons-container'>
          <NVAddItem
            css={overwritestyles}
            onSave={onAddTag}
            errorMessage={errorMessage}
            icon='icon-create-new-post'
            buttonLabel={t.INSTITUTIONS.SKILL_TAGS.ADD_SKILL()}
            showConfirmation
            confirmationMessage={t.INSTITUTIONS.SKILL_TAGS.CONFIRMATION}
            placeholder={t.INSTITUTIONS.SKILL_TAGS.PLACEHOLDER()}
            maxLength={50}
            pendoTag={config.pendo.skillTags.addSkill}
          />
          <NvRoundButton
            label={t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.TITLE()}
            onClick={() => setShowBulkUploadModal(true)}
          />
        </div>

        {Object.keys(tags).length > 0
        && (
          <LoadingWrapper isLoaded={!isLoading} loaderType={LoaderType.PLACEHOLDER}>
            <NvBasicTable<JSX.Element, SkillTagsNormalized>
              columns={getColumns()}
              tabledata={tags}
              rowComponent={SkillRow as FunctionComponent}
              rowClass='skill-row'
              highlightClass='row-added'
              highlightId={lastAdded}
              defaultSort={{ name: 'name', type: SortType.ASC }}
            />
          </LoadingWrapper>
        )}

        <NvModal
          type={ModalType.FIXED}
          header={t.INSTITUTIONS.SKILL_TAGS.BULK_UPLOAD_MODAL.TITLE()}
          body={<BulkUploadSkillsModal onClose={() => setShowBulkUploadModal(false)} />}
          show={showBulkUploadModal}
          onClose={() => setShowBulkUploadModal(false)}
          height='unset'
        />
      </div>
    </div>
  );
};

export default SkillTagsDashboard;
