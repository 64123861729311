/* @ngInject */
export default function ExercisesResources(

  $resource,
) {
  return $resource(
    '/:catalogId/exercises/:id.json',
    {},
    {
      get: { cancellable: true },
      getTemplate: { method: 'GET', url: '/:catalogId/exercises/:id/template.json' },
      getGalleryEnabledExercises: {
        method: 'GET',
        url: '/:catalogId/exercises/featureable_exercises.json',
      },
      getAllExercises: { method: 'GET', url: '/:catalogId/exercises.json' },
      getMentorCommentableExercises: {
        method: 'GET',
        url: '/:catalogId/exercises/mentor_commentable_exercises.json',
      },
      saveAttachment: { method: 'POST', url: '/:catalogId/Exercise/:id/attachments/save' },
      deleteAttachment: { method: 'PUT', url: '/:catalogId/attachments/:id/delete.json' },
      getSubmissionReviewers: { method: 'GET', url: '/:catalogId/exercises/:id/reviewers.json' },
      // saveSubmissionReviewers: { method: 'POST', url: '/:catalogId/exercises/:id/update_reviewers.json' },
      enableSubmissionReviewers: { method: 'POST', url: '/:catalogId/exercises/:id/update_reviewers.json' },
      disableSubmissionReviewers: { method: 'POST', url: '/:catalogId/exercises/:id/disable_submission_approval.json' },
      editAdvancedOptions: { method: 'PATCH', url: '/:catalogId/exercises/:id/save_advanced' },

      // - submission approval list
      exercisesForSubmissionApproval: { method: 'POST', url: '/submissions_approval/assignments_to_approve' },

      // Hard deadline extension
      updateExercisesWithHardDeadline: { method: 'POST', url: '/:catalogId/exercises/bulk_extend_hard_deadline.json' },
    },
  );
}
