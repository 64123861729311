import { useSelector } from 'react-redux';

// Schema
import { RootState } from 'redux/schemas';
import { RecordingFormat, VideoPracticeSubmission } from 'redux/schemas/models/video-practice';

// Selectors
import { getScenario, getSubmission } from 'redux/selectors/video-practice';

// Components
import VideoPracticeGalleryCard from 'lecture_pages/components/video-practice/video-practice-gallery-card';


type GalleryCardProps = {
  submissionId: number;
};

const GalleryCard = (props: GalleryCardProps) => {
  const practiceSubmission = useSelector<RootState, VideoPracticeSubmission>((state) => getSubmission(state, props.submissionId));
  const scenario = useSelector((state) => getScenario(state, practiceSubmission.scenario.id));

  return (
    <VideoPracticeGalleryCard
      isAudio={scenario.recordingFormat === RecordingFormat.AUDIO}
      {...practiceSubmission}
    />
  );
};

export default GalleryCard;
