import ProgressiveQuizModal from '../components/progressive-quiz-modal';

/* @ngInject */
export default function ProgressiveQuizModalController(
  mode,
  $scope,
  reveal,
  closeModal,
  lectureComponentId,
  forwardOnModalClose,
  initialQuestionIndex,
) {
  $scope.ProgressiveQuizModal = () => (
    <ProgressiveQuizModal
      mode={mode}
      closeModal={closeModal}
      reveal={reveal ?? false}
      lectureComponentId={lectureComponentId}
      forwardOnModalClose={forwardOnModalClose}
      questionIndex={initialQuestionIndex}
    />
  );
}
