import { css } from '@emotion/react';
import { useState, useEffect, useRef } from 'react';
import t from 'react-translate';
import { warning, gray1, gray3 } from 'styles/global_defaults/colors';
import { forceTwoDigits } from 'recording/components/stopwatch';
import { quarterSpacing } from 'styles/global_defaults/scaffolding';
import { semiBoldFontWeight } from 'styles/global_defaults/fonts';

type CountdownTimerProps = {
  countdownRemainingSeconds: number;
  paused?: boolean;
};

const CountdownTimer = ({
  countdownRemainingSeconds,
  paused,
}: CountdownTimerProps) => {
  const styles = css`
    display: inline-block;
    border-radius: 10px;
    background-color: ${gray1};
    color: ${gray3};
    padding-left: ${quarterSpacing}px;
    padding-right: ${quarterSpacing}px;
    font-weight: ${semiBoldFontWeight};
    white-space: nowrap;

    &:hover {
      cursor: pointer;
    }

    &.orange {
      background-color: ${warning};
      color: white;
    }
  `;

  const intervalRef = useRef<any>();

  const last10Seconds = countdownRemainingSeconds <= 10;
  const last30Seconds = countdownRemainingSeconds <= 30;

  const [flashing, setFlashing] = useState(false);

  useEffect(() => {
    if (last10Seconds && !intervalRef.current) {
      intervalRef.current = setInterval(() => {
        setFlashing(flash => !flash);
      }, 500);
    }
  }, [setFlashing, last10Seconds]);

  useEffect(() => {
    if (!countdownRemainingSeconds) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, [countdownRemainingSeconds]);

  const minutesDisplay = (forceTwoDigits(Math.floor(countdownRemainingSeconds / 60)));
  const secondsDisplay = (forceTwoDigits(countdownRemainingSeconds % 60));

  const isFlashing = last30Seconds && !flashing && !paused;

  return (
    <div
      css={styles}
      className={`countdown-timer ${isFlashing ? 'orange' : ''}`}
    >
      <div className='text-label'>
        <span>{`${t.FILE_UPLOAD.TIME_LEFT()} - ${minutesDisplay} : ${secondsDisplay}`}</span>
      </div>
    </div>
  );
};

export default CountdownTimer;
