import * as plurals from 'messageformat-runtime/lib/plurals';
import _ from 'underscore';
import mergeWith from 'lodash/mergeWith';
import single from 'webpack-hmr-singleton';
import { number, plural, select } from 'messageformat-runtime';
import { translationsLoaded } from 'shared/hooks/use-translations-loaded';
import { createElement } from 'react';
import createRuntimeTranslationFuncs from '../languages/runtime-ts-translations';
import { config } from '../config/config.json';
// Import the type of our enUS translation without including the code in our output bundle. We assume all languages will
// use the same type
import type enUS from '../languages/en_US';

/** This 'single' module is used to preserve this value as a singleton
 * across webpack hot module reloads. */
const t: typeof enUS = single(module, 't', () => ({}));

/** A mapping of language names (like `en_US`) to the objects holding their runtime translations */
const translations: Record<string, typeof enUS> = {};

/** The language name for the currently set language */
let currentLang: string = config.defaultPlatformLanguage;

// Place from messageformat translation helper functions onto the global scope so they can be referenced when evaluating the translation functions.
_.extend(window, { number, plural, select, ...plurals, createElement });

/** Sets the target language of the app but does not update the translations object unless a there is an existing saved translation or languageJson passed as argument
 * If a translations JSON file is provided, the function processes it, saves it into `translations`, and sets that as the currently used translations object.
 * If the translations for the target language is not available, saveLanguage should be called later which will set the translation object correctly
 * @param langCode A code for a language like `enUS`
 * @param languageJson A JavaScript object created from one of our translation JSON files
 * @param forceUpdate Force replacing the current translation object with a new one even if it already exists. Used for hot reloading. */
/* @ngInject */
export function setLanguage(langCode: string, languageJson?: any, forceUpdate?: boolean) {
  currentLang = langCode;

  if (!forceUpdate && Object.prototype.hasOwnProperty.call(translations, langCode)) {
    updateTranslationsObject(translations[langCode], langCode === config.defaultPlatformLanguage);
  } else if (languageJson) {
    saveLanguage(langCode, languageJson);
  }
}

/** Stores a translation JSON file
 * If the language matches the target language, update current translations object.
 * See setLanguage() for param documentation. */
/* @ngInject */
export function saveLanguage(langCode: string, languageJson: any): typeof enUS {
  // The message format runtime expects language codes without the '_'
  const mfPrefix = langCode.replace('_', '').slice(0, 2);

  const funcs = createRuntimeTranslationFuncs(langCode, mfPrefix, languageJson);
  translations[langCode] = funcs as typeof enUS;

  // need to always load english since it's the only language guaranteed to have all the translation strings
  if (langCode === config.defaultPlatformLanguage) {
    updateTranslationsObject(translations[langCode], true);

    // the current language can be loaded before english, so we need to update the translation object after loading in english
    if (currentLang !== config.defaultPlatformLanguage && translations[currentLang]) {
      updateTranslationsObject(translations[currentLang]);
    }

  // update translation object with current language translations if the language matches the target language
  } else if (langCode === currentLang) {
    updateTranslationsObject(translations[currentLang]);
  }

  // Notifying react translation listeners
  notify();

  return translations[langCode];
}

/** Updates the current translation object with the language functions provided */
function updateTranslationsObject(languageFuncs, isDefaultPlatformLanguage = false) {
  // Lodash's merge() is used here to mutate the current object with the
  // new translation functions. Mutation is important here so that
  // our components importing the translations object will see the new values
  // in the same object reference they keep via their `import`s
  if (isDefaultPlatformLanguage) {
    mergeWith(t, languageFuncs);
  } else {
    // In case there are conflicting types (from modifying existing values from obj -> string or vice versa)
    // we will stick with the English version
    mergeWith(t, languageFuncs, (objValue, srcValue) => {
      if (typeof objValue !== typeof srcValue) {
        return objValue;
      }

      return undefined;
    });
  }

  // Set the "translations loaded" singleton so our react-app will know that translations are finished and that we're ready to render the page.
  // See the useEffect() in `root-component.tsx`
  translationsLoaded.val = true;

  // Notifying react translation listeners
  notify();
}

if (module.hot) {
  module.hot.accept();
}

const ReactTranslateEvent = 'react-translation-changed';

export const notify = () => {
  const event = new Event(ReactTranslateEvent);
  document.dispatchEvent(event);
};

export const listen = (
  listener: Function,
) => {
  const actualListener = () => listener();
  document.addEventListener(ReactTranslateEvent, actualListener);
  return () => {
    document.removeEventListener(ReactTranslateEvent, actualListener);
  };
};

export default t;
