import isArray from 'lodash/isArray';

/**
 * Customizer function for mergeWith lodash utility function to prevent arrays
 * to be merged recursively, we want them to be replaced instead.
 * See: https://lodash.com/docs/4.17.15#mergeWith
 */
// eslint-disable-next-line import/prefer-default-export
export const replaceArrays = (objValue, srcValue) => {
  if (isArray(objValue) && isArray(srcValue)) {
    return srcValue;
  }

  return undefined;
};
