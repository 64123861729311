import prodPathReplace from 'shared/prod-path-rewrite';
import store, { cloneDeepSerializable } from 'redux/store';
import { updateLectureComponentFromAngular } from 'redux/actions/lecture-components';

/* @ngInject */
export default function ContentTemplateBaseModelService(
  $q,
  $translate,
  LectureComponentBaseModel,
  S3NameSpaces,
  S3UploadFactory,
  Upload,
  config,
  _,
  humps,
) {
  class ContentTemplateBaseModel extends LectureComponentBaseModel {
    constructor(attributes, isSample = false, useDefaults = false, extras = {}) {
      const defaults = {
        isActivity: false,
        canBeCopied: true,
      };

      super(_.extend({}, defaults, attributes));
    }

    get directiveName() {
      return 'nv-content-template';
    }

    getPayload() {
      return _.extend(
        _.pick(this, ['content', 'viewOptions', 'removePicture']),
        { picture: _.pick(this.picture, ['fileName', 'fileSize', 'fileType', 'uniqueId']) },
      );
    }

    uploadImage(file) {
      const deferred = $q.defer();

      this.uploadingPicture = true;
      this.picture = {
        file,
      };

      const base64DataUrlConverstionPromise = Upload.base64DataUrl(file).then((base64DataUrl) => {
        this.picture.base64DataUrl = base64DataUrl;
      });

      this.picture.s3UploadObj = S3UploadFactory.uploadToS3(file, S3NameSpaces.CONTENT_TEMPLATES, true);
      this.picture.s3UploadObj.promise.then((resp) => {
        const s3FileData = resp.config.data.file;
        const s3Attributes = {
          fileName: s3FileData.name,
          fileSize: s3FileData.size,
          fileType: s3FileData.type,
          uniqueId: s3FileData.uniqueId,
        };

        _.extend(this.picture, s3Attributes);
        this.removePicture = false;
        super.save(false).then((serverLectureComponent) => {
          _.extend(this.picture, serverLectureComponent.picture);

          deferred.resolve(this);
          this.uploadingPicture = false;

          store.dispatch(updateLectureComponentFromAngular(this));
        });
      });

      return deferred.promise;
    }

    removeImage() {
      this.picture = null;
      this.removePicture = true;
      return super.save();
    }

    getImgUrl() {
      if (this.picture && !this.uploadingImageInProgress()) {
        // don't want to reload the picture. so just keep using the base64 version...
        if (this.picture.s3UploadObj && !this.picture.s3UploadObj.uploadInProgress && this.picture.base64DataUrl) {
          return this.picture.base64DataUrl;
        } if (this.picture.cdnUrl) {
          return this.picture.cdnUrl;
        }
      }

      return null;
    }

    uploadingImageInProgress() {
      return !!this.uploadingPicture;
    }

    updateContent(newContent) {
      if (!this.contentIsRichText && !newContent) {
        newContent = $translate.instant(this.constructor.HEADER_PLACEHOLDER_KEY);
      }

      if (newContent !== this.content) {
        this.content = newContent;
        return this.save();
      }
      return $q.when(this);
    }

    cloneViewOptions() {
      this.viewOptionsCopy = _.clone(this.viewOptions);
    }

    saveClonedViewOptions() {
      _.extend(this.viewOptions, this.viewOptionsCopy);

      this.save();
    }
  }

  ContentTemplateBaseModel.descriptionKey = 'LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION';

  ContentTemplateBaseModel.SAMPLE_IMAGE_URL = prodPathReplace('images/content_template_sample.jpg');

  ContentTemplateBaseModel.HEADER_PLACEHOLDER_KEY = 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.HEADER_PLACEHOLDER';
  ContentTemplateBaseModel.RICH_TEXT_PLACEHOLDER_KEY = 'FROALA.TEXT_PLACEHOLDER';

  ContentTemplateBaseModel.getDropdownKey = (value) => {
    if (value === 'FULL_WIDTH') {
      return `LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.DROPDOWN_VALUES.${value}`;
    }
    return `LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.DROPDOWN_VALUES.${humps.decamelize(humps.camelize(value)).toUpperCase()}`;
  };

  ContentTemplateBaseModel.pendoTagName = config.pendo.lectureEdit.contents;

  return ContentTemplateBaseModel;
}
