import { css } from '@emotion/react';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownMenuProps } from 'react-bootstrap/esm/DropdownMenu';
import { DropdownToggleProps } from 'react-bootstrap/esm/DropdownToggle';
import { useMediaQuery } from 'react-responsive';
import { black, gray2, warning, white } from 'styles/global_defaults/colors';
import {
  screen4KMin,
  screenMdMax,
  screenMdMin,
  screenXsMax,
} from 'styles/global_defaults/media-queries';
import {
  halfSpacing,
  largeSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import NvDropdown, {
  NvDropdownAlign,
  NvDropdownButtonStyle,
  NvDropdownOption,
} from '../inputs/nv-dropdown';
import NvIcon from '../nv-icon';
import NvTooltip from '../nv-tooltip';
import { CategoryProps, NvCategory } from './nv-category';

type CustomToggleProps = DropdownToggleProps &
JSX.IntrinsicElements['button'] & {
  icon: string;
  isMobile: boolean;
  ariaLabel: string;
  tabIndex: number;
  tooltip: string;
  isSelected: boolean;
};

type CustomMenuProps = DropdownMenuProps & {
  categories: CategoryProps[];
  labeledBy?: string;
  hasRhs?: boolean;
};

type NvMenuProps = {
  icon: string;
  isWhiteIcon: boolean;
  categories: CategoryProps[];
  isSelected?: boolean;
  labeledBy: string;
  tabIndex?: number;
  hasRhs?: boolean;
  tooltip: string;
};

function getAccordionCategories(
  categories: CategoryProps[],
): NvDropdownOption[] {
  const accordionCategories: NvDropdownOption[] = categories.map(
    (category, index) => ({
      type: 'accordion-header',
      title: category.title,
      id: `header-${index}`,
      items: category.items.map(item => ({
        type: 'link',
        text: item.label,
        link: item?.link,
        callback: item?.callback,
        targetBlank: item?.targetBlank,
      })),
    }),
  );

  return accordionCategories;
}

const CustomToggle = React.forwardRef(
  (
    { icon, isMobile, ariaLabel, tabIndex, tooltip, isSelected, ...rest }: CustomToggleProps,
    ref,
  ) => (
    <button
      aria-label={ariaLabel}
      tabIndex={tabIndex ?? 0}
      key='toggle'
      ref={ref as any}
      type='button'
      {...rest}
    >
      <div className='icon-wrapper' role='presentation'>
        <NvTooltip
          text={tooltip}
          placement='bottom'
          enabled={!!tooltip?.length}
        >
          <NvIcon icon={icon} size='small' aria-hidden='true' />
        </NvTooltip>
      </div>
      {isSelected && (
        <div className='selected-indicator' />
      )}
    </button>
  ),
);

const CustomMenu = React.forwardRef(
  ({ categories, className, labeledBy, hasRhs }: CustomMenuProps, ref) => {
    const singleCategory = categories.length === 1;

    // When the course has RHS, the gap of the menu has the following values to fit the menu to the space available:
    // Min gap: standardSpacing
    // Max gap: largeSpacing
    const styles = css`
      padding: ${singleCategory ? threeQuartersSpacing : largeSpacing}px;
      padding-right: ${singleCategory ? threeQuartersSpacing : standardSpacing}px;
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: ${hasRhs ? `clamp(${standardSpacing}px, calc(${standardSpacing}px + (${largeSpacing} - ${standardSpacing})*(100vw - ${screenMdMax}px)/(${screen4KMin} - ${screenMdMax})), ${largeSpacing}px)` : `${largeSpacing}px`};
    `;

    return (
      <div ref={ref as any} className={className} aria-labelledby={labeledBy}>
        <div css={styles}>
          {categories.map(category => (
            <NvCategory
              key={category.title}
              singleCategory={singleCategory}
              {...category}
            />
          ))}
        </div>
      </div>
    );
  },
);

export const NvMenu = ({
  icon,
  isWhiteIcon,
  categories,
  isSelected,
  labeledBy,
  tabIndex,
  hasRhs,
  tooltip,
}: NvMenuProps) => {
  const isScreenMdMin = useMediaQuery({
    query: `(width: ${screenMdMin}px)`,
  });
  const isMobile = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });
  const isSmallDesktop = useMediaQuery({
    query: `(min-width: ${screenMdMin}px) and (max-width: ${screenMdMax}px)`,
  });
  const isDesktop = useMediaQuery({
    query: `(min-width: ${screenMdMin}px)`,
  });

  // For desktop resolutions and menu with multiple categories, the position absolute has to be relative to the body
  const isDesktopAndMultipleCategories = isDesktop && categories.length > 1;

  // Getting the apropiate menu max-width for desktop resolutions
  let maxWidthForDesktop = '';
  if (isScreenMdMin) {
    maxWidthForDesktop = '98vw';
  } else if (isSmallDesktop) {
    maxWidthForDesktop = `calc(92vw + (114 - 92)*(100vw - ${screenMdMin}px)/(${screenMdMax} - ${screenMdMin}))`;
  } else {
    // When the course has RHS, the max menu width has the following values to fit the menu to the space available:
    // Min width: 900px
    // Max width: 1100px
    maxWidthForDesktop = hasRhs ? `clamp(900px, calc(900px + (1100 - 900)*(100vw - ${screenMdMax}px)/(${screen4KMin} - ${screenMdMax})), 1100px)` : '1100px';
  }

  const singleCategory = categories.length === 1;

  const stylesBase = css`
    height: inherit;

    button {
      padding: 0;
      border: none;
    }

    .bs4-dropdown-toggle::after {
      height: inherit;
      content: none;
      display: none;
    }

    &.is-white-icon .icon-wrapper, .is-white-icon .icon-wrapper {
      color: ${white};
    }
  `;

  const stylesMobile = css`
    ${stylesBase};

    .nv-dropdown,
    .bs4-dropdown,
    .nv-dropdown [role='button'] {
      height: inherit;
    }

    .bs4-dropdown-menu {
      width: 200px;
      max-width: 200px;
      border-radius: 4px;

      .bs4-dropdown-item {
        color: ${black};
        white-space: initial;
        line-height: ${standardSpacing}px;
      }

      .accordion-header {
        border-radius: 4px;

        i.icon {
          color: ${gray2};
        }

        &.expanded i.icon {
          color: ${warning}
        }
      }
    }
  `;

  const stylesDesktop = css`
    ${stylesBase};

    display: inline-block;
    position: ${!isDesktopAndMultipleCategories ? 'relative' : 'initial'};

    .bs4-dropdown-menu {
      width: ${singleCategory ? '160px' : 'auto'};
      max-width: ${singleCategory ? '160px' : maxWidthForDesktop};
      line-height: initial;
    }
  `;

  // Conditional to render to accordion component for resolutions < 1024px and multiple categories
  if (!isDesktop && categories.length > 1) {
    return (
      <div css={stylesMobile} className={`page-link ${!isWhiteIcon ? '' : 'is-white-icon'} ${isSelected && 'selected'}`}>
        <NvDropdown
          buttonStyle={NvDropdownButtonStyle.CUSTOM}
          items={getAccordionCategories(categories)}
          align={NvDropdownAlign.RIGHT}
          noMenuPadding
          showSelectedIndicator
          customTarget={() => (
            <React.Fragment>
              <div className='icon-wrapper' role='presentation'>
                <NvIcon
                  icon={icon}
                  size='small'
                  aria-hidden='true'
                  key='toggle'
                />
              </div>
              <div className='selected-indicator' />
            </React.Fragment>
          )}
        />
      </div>
    );
  }

  // Render the menu:
  // All resolutions if has a single category
  // For resolutions > 1024px if has multiple categories
  return (
    <Dropdown alignRight css={stylesDesktop}>
      <Dropdown.Toggle
        as={CustomToggle}
        className={`page-link ${isSelected && 'selected'} ${!isWhiteIcon ? '' : 'is-white-icon'}`}
        icon={icon}
        tabIndex={tabIndex ?? 0}
        isMobile={isMobile}
        ariaLabel={labeledBy}
        tooltip={tooltip}
        data-qa='menu-toggle'
        isSelected={isSelected}
      />

      <Dropdown.Menu
        as={CustomMenu}
        categories={categories}
        labeledBy={labeledBy}
        hasRhs={hasRhs}
      />
    </Dropdown>
  );
};

export default NvMenu;
