/* eslint-disable import/prefer-default-export */
import { omit } from 'underscore';
import { schema } from 'normalizr';

import { Todo } from 'redux/schemas/models/todos';
import { courseSchema } from 'redux/schemas/api/course';

export const TodoSchema = new schema.Entity<Todo>(
  'todos',
  {
    courseId: courseSchema,
  },
  {
    idAttribute: (value) => `${value.id}-${value.type}`,
    processStrategy(value) {
      return omit({
        ...value,
        ...(value.course ? { courseId: value.course, courseCatalogId: value.course.catalogId } : {}),
      }, ['course']);
    },
  },
);
