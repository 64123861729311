import { useEffect, FunctionComponent, forwardRef, useCallback, MutableRefObject, useRef } from 'react';
import { createPortal } from 'react-dom';

type PortalProps = {
  children: React.ReactNode;
};

const Portal = forwardRef(({
  children,
}: PortalProps, portalRef: MutableRefObject<any>) => {
  const elRef = useRef(null);
  if (!elRef.current) {
    elRef.current = document.createElement('div');
  }

  useEffect(() => {
    const mount = portalRef.current;
    mount.appendChild(elRef.current);
    return () => mount.removeChild(elRef.current);
  });

  return createPortal(children, elRef.current);
});

export default Portal;
