/* @ngInject */
export default function TeachingTeamDirectoryMainController(
  CurrentUserManager,
  TeachingTeamDirectoryManager,
  $stateParams,
  CurrentCourseManager,
  config,
) {
  const vm = this;

  vm.config = config;
  vm.currentUserManager = CurrentUserManager;
  vm.manager = TeachingTeamDirectoryManager;
  vm.currentCourseManager = CurrentCourseManager;

  vm.getShowLocation = (userId) => {
    const userInfo = vm.manager.users.find((eachUserInfo) => eachUserInfo.user.id === userId);

    return (
      !CurrentCourseManager.course.institution.profileSettings.accountLevel.location.isHidden
        && !!userInfo.user.displayLocation
    );
  };

  if (TeachingTeamDirectoryManager.initialized) {
    TeachingTeamDirectoryManager.resetData();
  }

  TeachingTeamDirectoryManager.initialize({ catalogId: $stateParams.catalogId, context: 'TeachingTeam' });

  TeachingTeamDirectoryManager.fetchProfileQuestionFilters().then(TeachingTeamDirectoryManager.doSearch());
}
