/*
  this modal controller expects to be able to close the modal after form submission
*/
/* @ngInject */
export default function NvModalCtrl(
  _,
  $scope,
  $uibModalInstance,
  $templateCache,
  modalOptions,
) {
  const _this = this;

  const modalOptionsDefaults = {
    // header
    headerKey: null,
    // body
    bodyTemplateUrl: '',
    model: {},
    // footer
    confirmTextKey: 'FORM.CONFIRM',
    confirmButtonClasses: ['btn-primary'],
    disableConfirm: false,
    onConfirm: null, // should return a promise
    cancelTextKey: 'FORM.CANCEL',
    disableCancel: false,
  };
  _this.modalOptions = _.extend(modalOptionsDefaults, modalOptions);

  if (_this.modalOptions.bodyTemplateUrl) {
    _this.modalOptions.body = $templateCache.get(_this.modalOptions.bodyTemplateUrl);
  }

  if (_this.modalOptions.footerTemplateUrl) {
    _this.modalOptions.footer = $templateCache.get(_this.modalOptions.footerTemplateUrl);
  }

  _this.closeModal = function () {
    $uibModalInstance.dismiss('cancel');
  };

  _this.formSubmit = function () {
    if (modalOptions.onConfirm) {
      modalOptions.onConfirm(modalOptions.model).then(() => {
        $uibModalInstance.close();
      });
    } else {
      $uibModalInstance.close();
    }
  };
}
