import { CacheProvider, EmotionCache, jsx } from '@emotion/react';
import React from 'react';
import { ThemeProvider } from 'react-bootstrap';
import createCache from '@emotion/cache';
import stylisRtl from 'stylis-plugin-rtl';

/** Used for auto prefixing the Bootstrap4 styles used by react-bootstrap
 * with 'bs4-'. Exported since it's used by Storybook. */
export const bs4Components = [
  'alert',
  'arrow',
  'badge',
  'bs-popover-top', 'bs-popover-right', 'bs-popover-left', 'bs-popover-bottom',
  'btn-toolbar', 'btn', 'btn-outline-primary',
  'close',
  'dropdown', 'dropdown-toggle', 'dropdown-item', 'dropdown-menu',
  'form-group', 'form-label', 'form-control', 'form-text', 'text-muted',
  'form-check', 'form-check-input', 'form-check-label',
  'input-group', 'input-group-append', 'input-group-prepend', 'input-group-text',
  'modal', 'modal-header', 'modal-body', 'modal-footer', 'modal-dialog', 'modal-title',
  'nav', 'nav-item', 'nav-link',
  'popover', 'bs-popover-top', 'bs-popover-bottom', 'bs-popover-left', 'bs-popover-right', 'popover-header', 'popover-body',
  'tooltip', 'bs-tooltip-top', 'bs-tooltip-bottom', 'bs-tooltip-left', 'bs-tooltip-right',
  'overlay',
  'progress', 'progressbar',
];

/** Modifies our Emotion styling to use bootstrap4 prefixed versions
 * of b4 styles to avoid conflicts with the B3 styles used in Angularjs. Also adds automatic right-to-left language style conversion
 * to Emotion css blocks. */
/* @ngInject */
export default function AppStylesProvider(props: { children: React.ReactNode }) {
  const prefixes = {};

  /**
   * converts all the component names above to be prefixed with 'bs4-'
   * e.g. { btn: 'bs4-btn }
   */
  bs4Components.forEach((component) => {
    prefixes[component] = `bs4-${component}`;
  });

  const ltr = createCache({
    key: 'bs-theme-provider-ltr',
    stylisPlugins: [],
  });

  const rtl = createCache({
    key: 'bs-theme-provider-rtl',
    stylisPlugins: [stylisRtl],
  });

  return (
    <CacheProvider value={document.dir === 'rtl' ? rtl : ltr}>
      <ThemeProvider prefixes={prefixes}>{props.children}</ThemeProvider>
    </CacheProvider>
  );
}
