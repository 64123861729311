import { useRef } from 'react';
import useMountEffect from 'shared/hooks/use-mount-effect';
import prodPathReplace from 'shared/prod-path-rewrite';

const useCountdownBeep = () => {
  const beep = useRef<HTMLAudioElement>();

  useMountEffect(() => {
    beep.current = new Audio(prodPathReplace('other_assets/sounds/countdown-beep.wav'));
  });

  const play = () => {
    beep.current.play();
  };

  return [play];
};

export default useCountdownBeep;
