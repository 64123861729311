import { createReducer } from '@reduxjs/toolkit';
import { getSkillTags, addSkillTag, deleteSkillTag, tagLectureComponent, removeTagLectureComponent, fetchSkillTagsForProdCourses, getAllSkillTags } from 'redux/actions/skill-tags';
import { normalize } from 'normalizr';
import { skilltagsSchema } from 'redux/schemas/api/skill-tag';
import { SkillTagState } from 'redux/schemas/models/skill-tag';
import { initialRootState } from '.';

export const initialSkillTagState: SkillTagState = {
  lastInsertedTagId: -1,
  isLoading: false,
  hasBeenLoaded: false,
  totalTags: 0,
};

export default createReducer(initialRootState, builder => {
  builder
    .addCase(getSkillTags.pending, (state, action) => {
      state.app.skillTag.isLoading = true;
    })
    .addCase(getSkillTags.fulfilled, (state, action) => {
      const data = normalize(action.payload.tags, skilltagsSchema);
      Object.assign(state.models.skillTags, data.entities.skilltags);

      state.app.skillTag.hasBeenLoaded = true;
      state.app.skillTag.isLoading = false;
      state.app.skillTag.totalTags = action.payload.totalTags;
    })
    .addCase(getAllSkillTags.fulfilled, (state, action) => {
      const data = normalize(action.payload, skilltagsSchema);
      Object.assign(state.models.skillTags, data.entities.skilltags, state.models.skillTags);
    })
    .addCase(addSkillTag.fulfilled, (state, action) => {
      state.models.skillTags[action.payload.id] = action.payload;
      state.app.skillTag.lastInsertedTagId = action.payload.id;
      state.app.skillTag.totalTags += 1;
    })
    .addCase(deleteSkillTag.fulfilled, (state, action) => {
      // delete state.models.skillTags[action.meta.arg.tag.id];

      // This might be unexpected. In order to avoid this issue: NOV-90567,
      // we are refreshing the skill tags data. Clearing it from redux will cause a refetch on the dashboard.
      // Note: at some point in the future, we should probably change this so
      // that the backend is taking care of the issue and we don't need to reload everything
      state.models.skillTags = {};
    })
    .addCase(tagLectureComponent.fulfilled, (state, action) => {
      const component = state.models.lectureComponents[action.meta.arg.lectureComponentId];
      component.skillTags = component.skillTags || [];
      state.models.lectureComponents[action.meta.arg.lectureComponentId].skillTags = [...component.skillTags, action.payload];
      state.app.skillTag.hasBeenLoaded = false;
    })
    .addCase(removeTagLectureComponent.fulfilled, (state, action) => {
      const tags = state.models.lectureComponents[action.meta.arg.lectureComponentId].skillTags || [];
      state.models.lectureComponents[action.meta.arg.lectureComponentId].skillTags = tags.filter(item => item.id !== action.meta.arg.skillTagId);
      state.app.skillTag.hasBeenLoaded = false;
    })
    .addCase(fetchSkillTagsForProdCourses.fulfilled, (state, action) => {
      const data = normalize(action.payload, skilltagsSchema);
      Object.assign(state.models.skillTagsForProdCourses, data.entities.skilltags);
    });
});
