import { css } from '@emotion/react';
import ColorPickerPopover from 'components/color-picker-popover';
import FileUploadingBar from 'components/file-uploading-bar';
import { useState, useEffect } from 'react';
import t from 'react-translate';
import { LectureComponent, ComponentType, convertToPictureData } from 'redux/schemas/models/lecture-component';
import NvDropdown, { NvDropdownOption, NvDropdownButtonStyle } from 'shared/components/inputs/nv-dropdown';
import NvFilePicker from 'shared/components/nv-filepicker';
import NvIcon from 'shared/components/nv-icon';
import IconLibraryModal from 'shared/components/nv-icon-library';
import useUploadFile, { NovoEdFile } from 'shared/hooks/use-upload-file';
import { S3NameSpaces } from 'shared/services/s3-upload-factory';
import { gray4 } from 'styles/global_defaults/colors';
import _ from 'underscore';
import { LectureComponentProps } from '..';

const styles = css`
.dropdown-target {
  width: 60px;
  height: 60px;
}

.header-image {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}
`;

const popoverStyles = css`
.shape-button {
  width: 24px;
  height: 24px;

  &:first-child {
    margin-right: 7px;
  }

  padding: 3px;

  &:hover {
    cursor: pointer;
  }

  &.selected {
    padding: 2px;
    border: 1px dashed ${gray4};
  }
}
`;

/** The square or circular area on the side of a header lecture component that shows either
 * an icon or an image */
const HeaderPictureArea = (props: {
  currentLecture: LectureComponentProps['currentLecture'],
  userCanEdit: boolean,
  viewOptions: LectureComponent<ComponentType.HEADER_STYLE3>['viewOptions'],
  picture: LectureComponent<ComponentType.HEADER_STYLE3>['picture'],
  /** The component save function from HeaderLectureComponent */
  saveHeaderImage: (viewOptions: Partial<typeof props.viewOptions>, pictureData?: NovoEdFile) => void,
  /** Updates the shape state in the HeaderLectureComponent to carry through shape specific styling */
  setShape: (shape: LectureComponent<ComponentType.HEADER_STYLE3>['viewOptions']['pictureShape']) => void,
  isPreview: boolean,
}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showIconLibrary, setShowIconLibrary] = useState(false);

  // Local state vars used to show lecture component updates sooner than the API responses finishing
  const [icon, setIcon] = useState(props.viewOptions.libraryIconName);
  const [picture, setPicture] = useState(props.picture);
  const [shape, setShape] = useState(props.viewOptions.pictureShape);

  const showUploadIcon = !icon && !picture;

  const { uploadFiles, isUploading, filesUploading } = useUploadFile();

  const [iconColorProps, setIconColorProps] = useState(_.pick(props.viewOptions, 'libraryIconColor', 'pictureBackgroundColor'));

  const onImagePicked = async (files: File[]) => {
    setIcon(null);
    const [novoEdFile] = await uploadFiles(files, S3NameSpaces.CONTENT_TEMPLATES);
    setPicture(convertToPictureData(novoEdFile));
    props.saveHeaderImage({ libraryIconName: null }, novoEdFile);
  };

  // Update the picture state when a new photo upload is available.
  // We have to update this in a useeffect because the actual cdn url is only available in the data response to saving the component, and not
  // in the novoEdFile above.
  useEffect(() => {
    if (!props.picture || props.picture?.cdnUrl !== picture?.cdnUrl) {
      setPicture(props.picture);
    }
  }, [props.picture]);

  const dropdownItems: NvDropdownOption[] = [
    { type: 'custom',
      customItem: (
        <NvFilePicker accept={['image/*']} multiple={false} onChange={(files) => onImagePicked(files)}>
          <div className='bs4-dropdown-item'>{ t.LECTURE_PAGES.COMPONENTS.HEADER.UPLOAD() }</div>
        </NvFilePicker>
      ) },
    { type: 'text', text: t.LECTURE_PAGES.COMPONENTS.HEADER.PICK_FROM_LIBRARY(), callback: () => setShowIconLibrary(true) },
    { type: 'divider' },
    { type: 'text', text: t.LECTURE_PAGES.COMPONENTS.HEADER.CHANGE_COLORS(), callback: () => setShowColorPicker(true) },
  ];

  const iconPicked = (iconName: string) => {
    setIcon(iconName);
    setPicture(null);
    props.saveHeaderImage({
      libraryIconName: iconName,
    }, null);
  };

  const setPictureShape = (pictureShape: LectureComponent<ComponentType.HEADER_STYLE3>['viewOptions']['pictureShape']) => {
    props.saveHeaderImage({
      pictureShape,
    });
    setShape(pictureShape);
    props.setShape(pictureShape);
  };

  const isShapeCircle = shape === 'circle';
  const imageShapeClass = isShapeCircle ? 'rounded-circle' : '';

  // TODO: I originally placed this in the `style` attribute on .header-image div but that was causing the image to be
  // re-requested on every single component render. This does not do that - worth investigating!
  const imageStyles = css`
    background-image: ${picture && !isUploading ? `url("${picture.cdnUrl}")` : ''};
  `;

  return (
    <div css={styles}>
      {/* Color picker that shows when clicking the */}
      <ColorPickerPopover<{
        libraryIconColor: string,
        pictureBackgroundColor: string,
      }>
        onChange={(colorProp, newVal: string) => {
          const newColorProps = {
            ...iconColorProps,
            [colorProp]: newVal,
          };
          setIconColorProps(newColorProps);
          props.saveHeaderImage(newColorProps);
        }}
        sections={[
          {
            name: 'libraryIconColor',
            title: t.LECTURE_PAGES.COMPONENTS.HEADER.ICON_COLOR(),
          },
          {
            name: 'pictureBackgroundColor',
            title: t.LECTURE_PAGES.COMPONENTS.HEADER.BACKGROUND_COLOR(),
          },
        ]}
        show={showColorPicker}
        placement='top-start'
        renderBeforePalette={() => (
          <div css={popoverStyles}>
            <div className='label gray-1 mb-2'>{t.SHARED.SELECT_SHAPE()}</div>
            <div className='d-flex'>
              <div
                className={`shape-button ${shape === 'square' ? 'selected' : ''}`}
                onClick={() => setPictureShape('square')}
              >
                <div className='w-100 h-100 bg-gray-3' />
              </div>
              <div
                className={`shape-button rounded-circle ${isShapeCircle ? 'selected' : ''}`}
                onClick={() => setPictureShape('circle')}
              >
                <div className='w-100 h-100 bg-gray-3 rounded-circle' />
              </div>
            </div>
          </div>
        )}
        colorValues={iconColorProps}
        onToggle={() => setShowColorPicker(false)}
      >
        <NvDropdown
          buttonStyle={NvDropdownButtonStyle.CUSTOM}
          items={dropdownItems}
          customTarget={() => (
            <div>
              <div
                css={imageStyles}
                style={{
                  borderRadius: isShapeCircle ? '50%' : '',
                  backgroundColor: iconColorProps.pictureBackgroundColor,
                  color: iconColorProps.libraryIconColor ?? 'black',
                }}
                className={`header-image ${imageShapeClass} d-flex align-items-center justify-content-center dropdown-target`}
              >
                {/* Loading bar */}
                { isUploading && <FileUploadingBar filesUploading={filesUploading} />}
                {/* Upload icon visible when no picture or icon defined */}
                {!isUploading && !picture && showUploadIcon && <NvIcon icon='upload' size='medium' />}
                {/* Icon */}
                {!isUploading && !picture && !showUploadIcon && icon
                && <NvIcon icon={icon} size={props.isPreview ? 'small' : 'medium'} />}
              </div>
            </div>
          )}
          disabled={!props.userCanEdit || isUploading}
        />
      </ColorPickerPopover>
      <IconLibraryModal onIconClick={iconPicked} show={showIconLibrary} onClose={() => setShowIconLibrary(false)} />
    </div>
  );
};


export default HeaderPictureArea;
