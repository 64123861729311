import React from 'react';
import { AngularServicesContext } from 'react-app';
import { useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import { css } from '@emotion/core';
import t from 'react-translate';

import NvIcon from 'shared/components/nv-icon';
import ProgressiveQuizContext from 'quizzes/components/context';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import { NQuizQuestion, ResponseOption } from 'redux/schemas/models/progressive-quiz';
import { RootState } from 'redux/schemas';
import { getQuizQuestionOptions } from 'redux/selectors/quizzes';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';

type ViewAnswerFeedBackProps = {
  currentQuestion: NQuizQuestion;
};

const ViewAnswerFeedBack = (props: ViewAnswerFeedBackProps) => {
  const {
    currentQuestionResponse,
  } = React.useContext(ProgressiveQuizContext);
  const {
    isLongAnswerQuestion,
  } = useQuizModeAndQuestionType();
  const angularServices = React.useContext(AngularServicesContext);

  const responseOptions: ResponseOption[] = useSelector(
    (state: RootState) => getQuizQuestionOptions(state, props.currentQuestion?.id),
  );

  const { explanation, secondaryExplanation } = responseOptions[0];
  const styles = css`
    .fr-element {
      p {
        margin-bottom: 0px;
      }
    }
  `;
  const getFeedback = () => {
    if (isLongAnswerQuestion) {
      return (!isEmpty(explanation))
        ? explanation
        : `<p>${t.QUIZZES.LONG_ANSWER_QUESTION.DEFAULT_FEEDBACK()}</p>`;
    }
    if (currentQuestionResponse.isCorrect) {
      return (!isEmpty(explanation))
        ? explanation
        : `<p>${t.SHARED.CORRECT()}</p>`;
    }
    return (!isEmpty(secondaryExplanation))
      ? secondaryExplanation
      : `<p>${t.SHARED.INCORRECT()}</p>`;
  };

  return (
    <div css={styles} className='d-flex mt-2'>
      <NvIcon
        size='small'
        className={`${currentQuestionResponse.isCorrect ? 'text-success' : 'text-danger'} d-flex align-items-center px-1`}
        icon={`${currentQuestionResponse.isCorrect ? 'success' : 'error'}`}
      />
      <ResponsivelyEmbeddedAngularHTML
        className={`ml-2 w-100 fr-element froala-style-medium ${currentQuestionResponse.isCorrect ? 'text-success' : 'text-danger'} mb-0`}
        template={getFeedback()}
        angularServices={angularServices}
      />
    </div>
  );
};

export default ViewAnswerFeedBack;
