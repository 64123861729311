import { css } from '@emotion/react';


export type AvatarSize = 'xl' | 'lg' | 'md' | 'sm' | 'xs';
export type BorderType = 'round' | 'square';

export enum NvAvatarDefaultBkg {
  INITIALS,
  SOLID_COLOR,
  IMAGE,
}

export type InitialsBkg = {
  type: NvAvatarDefaultBkg.INITIALS,
  initials: string,
};

export type SolidColorBkg = {
  type: NvAvatarDefaultBkg.SOLID_COLOR,
  color: string,
};

export type ImageBkg = {
  type: NvAvatarDefaultBkg.IMAGE,
  imageUrl: string,
};

export const sizes: { [key in AvatarSize]: number } = {
  xl: 100,
  lg: 60,
  md: 40,
  sm: 30,
  xs: 20,
};

export type NvAvatarProps = {
  imageUrl: string,
  size: AvatarSize,
  borderType: BorderType,
  defaultBkg: InitialsBkg | SolidColorBkg | ImageBkg,
};

/**
 * NovoEd-customized wrapper around a react-bootstrap Dropdown component.
 */
export const NvAvatar = (props: NvAvatarProps) => {
  const getBkgImage = () => {
    // TODO: remove this hack for ignoring the default image
    if (props.imageUrl?.indexOf('temp.png') === -1) {
      return props.imageUrl;
    }

    if (props.defaultBkg.type === NvAvatarDefaultBkg.IMAGE) {
      return props.defaultBkg.imageUrl;
    }

    return '';
  };

  const getBkgColor = () => {
    if (props.defaultBkg.type === NvAvatarDefaultBkg.SOLID_COLOR) {
      return props.defaultBkg.color;
    }
    return '';
  };

  const styles = css`
    .avatar {
      background-image: url(${getBkgImage()});
      background-color: ${getBkgColor()};
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: ${sizes[props.size]}px;
      height: ${sizes[props.size]}px;
      ${props.borderType === 'round' && css`
        border-radius: 50%;
      `};
    }
  `;

  return (
    <div css={styles}>
      <div className='avatar' />
    </div>
  );
};

export default NvAvatar;
