/* @ngInject */
export default function IndividualSubmissionController(
  _,
  $stateParams,
  $scope,

  CurrentUserManager,
  CurrentCourseManager,

  ReportsManager,
  ReactLecturePageContext,
) {
  const vm = this;

  _.extend(vm, {
    $stateParams,
    CurrentUserManager,
    course: CurrentUserManager.hasLoggedIn() ? CurrentUserManager.coursesHashByCatalogId[$stateParams.catalogId] : CurrentCourseManager.course,
  });

  $scope.$on('$destroy', () => {
    if (vm.lastState === 'submissions-gallery' && vm.$state.current.name === 'submissions-gallery') {
      ReportsManager.updateSelectedExercise();
    }
  });
}
