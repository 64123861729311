/* @ngInject */
export default function CourseHomeRhsManager(

  CourseHomeResources,
  TeamModel,
  PostModel,
  UserModel,
  _,
  CurrentUserManager,
  $translate,
  CurrentCourseManager,
) {
  const defaultActiveUsersShown = 6;

  const recentActivityItemsPerPage = 10;

  const _this = {
    // functions
    getCourseHomeRhs,
    resetCourseHomeRhs,
    resumeRefresh,
    stopRefresh,
    setDataRefreshVariable,
    calculateNumUsersToShow,
    loadMoreRecentActivity,

    // data:
    rhsUsers: [],
    rhsDisplayedUsers: [],
    rhsTeams: [],
    rhsGroups: [],
    rhsPosts: [],
    rhsDisplayedPosts: [],
    numActiveUsersShown: defaultActiveUsersShown,
    maxActiveUserShown: 12,
    numTeamsShown: 6,
    numPostsShown: 1,
    numGroupsShown: 3,
    refreshedUsersCount: 0,
    userRefreshTimeout: 10000,
    refreshedPostsCount: 0,
    postRefreshTimeout: 12000,
    initialized: false,
    shouldRefreshDisplayedData: false,
    recentCourseActivity: [],
    recentCourseActivityLoaded: false,
    recentCourseActivityPageNumber: 1,
    hasMoreRecentActivityToLoad: true,


    CurrentCourseManager,
  };

  function resetCourseHomeRhs(catalogId) {
    _this.initialized = false;
    _this.rhsUsers = [];
    _this.rhsDisplayedUsers = [];
    _this.rhsTeams = [];
    _this.rhsGroups = [];
    _this.rhsPosts = [];
    _this.rhsDisplayedPosts = [];
    _this.numActiveUsersShown = defaultActiveUsersShown;
    _this.refreshedUsersCount = 0;
    _this.refreshedPostsCount = 0;
    _this.shouldRefreshDisplayedData = false;
    _this.recentCourseActivity = [];
    _this.recentCourseActivityLoaded = false;
    _this.recentCourseActivityPageNumber = 1;
    _this.hasMoreRecentActivityToLoad = true;
  }

  function getCourseHomeRhs(catalogId) {
    if (_this.initialized) {
      return null;
    }
    _this.initialized = _this.catalogId && _this.catalogId === catalogId; // two ui-views included to handle RHS interactions
    _this.catalogId = catalogId;

    _this.course = CurrentUserManager.coursesHashByCatalogId[_this.catalogId];

    if (_this.course.isSelfPaced) {
      return CourseHomeResources.getRecentCourseActivity(
        {
          catalogId,
          page: _this.recentCourseActivityPageNumber,
          pageSize: recentActivityItemsPerPage,
        },
        (resource) => {
          _this.recentCourseActivity = resource.result.activities;
          _this.recentCourseActivityLoaded = true;
          _this.hasMoreRecentActivityToLoad = resource.result.nextPage;
        },
      ).$promise;
    }
    return CourseHomeResources.getCommunity(
      { catalogId },
      (resource) => {
        setRhsUsers(resource.result);
        setRhsTeams(resource.result);
        setRhsGroups(resource.result);
        setRhsPosts(resource.result);
      },
    ).$promise;
  }

  function setRhsUsers(data) {
    _this.rhsUsers = [];
    _.each(data.learners, (userCourse) => {
      _this.rhsUsers.push(new UserModel(userCourse.user));
    });
  }

  function setRhsTeams(data) {
    _this.rhsTeams = [];
    _.each(data.teams, (team) => {
      _this.rhsTeams.push(new TeamModel(team));
    });
  }

  function setRhsGroups(data) {
    _this.rhsGroups = [];
    _.each(data.groups, (group) => {
      _this.rhsGroups.push(new TeamModel(group));
    });
  }

  function setRhsPosts(data) {
    _this.rhsPosts = [];
    _.each(data.topics, (post) => {
      _this.rhsPosts.push(new PostModel(_.extend(post, { catalogId: _this.catalogId })));
    });
  }

  function calculateNumUsersToShow() {
    return (_this.rhsTeams.length && _this.rhsGroups.length && _this.rhsPosts.length) ? _this.maxActiveUserShown : _this.numActiveUsersShown;
  }

  function resumeRefresh() {
    _this.shouldRefreshDisplayedData = true;
  }

  function stopRefresh() {
    _this.shouldRefreshDisplayedData = false;
  }

  function setDataRefreshVariable(val) {
    _this.shouldRefreshDisplayedData = val;
  }

  function loadMoreRecentActivity() {
    if (_this.hasMoreRecentActivityToLoad) {
      _this.recentCourseActivityPageNumber += 1;

      return CourseHomeResources.getRecentCourseActivity(
        {
          catalogId: _this.catalogId,
          page: _this.recentCourseActivityPageNumber,
          pageSize: recentActivityItemsPerPage,
        },
        (resource) => {
          _this.recentCourseActivity = _this.recentCourseActivity.concat(resource.result.activities);
          _this.hasMoreRecentActivityToLoad = resource.result.nextPage > 0;
        },
      ).$promise;
    }

    return null;
  }

  return _this;
}
