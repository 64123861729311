/* @ngInject */
export default function BlurbTopImageLectureComponentModel(
  _,
  $translate,
  ContentTemplateBaseModel,
  config,
) {
  class BlurbTopImageLectureComponent extends ContentTemplateBaseModel {
    constructor(attributes, ...args) {
      const defaults = {
        type: 'BlurbTopImageLectureComponent',
        componentClasses: ['blurb', 'top-image'],
        hasContent: true,
        contentIsRichText: true,
        uploadSupported: true,
      };

      super(_.extend({}, defaults, attributes), ...args);
    }
  }

  BlurbTopImageLectureComponent.FORM_DATA = [
    [{
      labelKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.COMPONENT_WIDTH',
      type: 'DROPDOWN',
      optionsKey: 'COMPONENT_WIDTHS',
      modelAttribute: 'componentWidth',
    }, {
      labelKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.FIXED_IMAGE_WIDTH',
      type: 'FREEFORM_PIXELS',
      modelAttribute: 'pictureWidth',
    }],
    [{
      labelKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.IMAGE_STYLE',
      type: 'SELECTION',
      optionsKey: 'IMAGE_STYLES',
      modelAttribute: 'pictureShape',
    }],
    [{
      labelKey: 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.IMAGE_ALT',
      type: 'FREEFORM_OPTIONAL',
      modelAttribute: 'pictureAlt',
    }],
  ];

  BlurbTopImageLectureComponent.COMPONENT_WIDTHS = _.map(['100%', '80%', '60%', '50%'], (value) => ({ labelKey: BlurbTopImageLectureComponent.getDropdownKey(value), value }));


  BlurbTopImageLectureComponent.IMAGE_STYLES = _.map(['square', 'original', 'circle'], (value) => ({ css: `shape ${value}`, value: `${value}`, tooltipKey: `LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.SHAPE.${value.toUpperCase()}` }));
  BlurbTopImageLectureComponent.pendoTagName = config.pendo.lectureEdit.pictureAboveText;
  BlurbTopImageLectureComponent.toolTipKey = 'LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.TOOLTIP.BLURB_TOP_IMAGE';

  return BlurbTopImageLectureComponent;
}
