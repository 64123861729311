import { css } from '@emotion/react';
import ClickableContainer, { ClickableContainerProps } from 'components/clickable-container';
import { doubleSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';

type NvGradientBorderButtonProps = {
  onClick: ClickableContainerProps['onClick'],
  children: React.ReactNode,
  className?: string,
  dataQa?: string,
};

const styles = css`
  &.gradient-btn {
    position: relative;
    border-radius: ${doubleSpacing}px;
    padding: ${standardSpacing}px;
    display: flex;
    background-color: transparent; /* Transparent by default */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    z-index: 0; /* Ensure the .gradient-btn is on top */

    ::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: inherit; /* Ensure the border radius is applied */
      padding: 2px; /* Adjust to match the border width */
      background: linear-gradient(to left, #60f3ab, #00ccbc, #009ac0);
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude; /* Make the center part transparent */
      z-index: -1;
    }

    :hover {
      background-color: rgba(0, 204, 188, 0.05); /* Background color on hover */
    }
  }
`;

const NvGradientBorderButton = (props: NvGradientBorderButtonProps) => (
  <ClickableContainer
    css={styles}
    className={`gradient-btn ${props.className ? props.className : ''}`}
    onClick={props.onClick}
    data-qa={props.dataQa}
  >
    {props.children}
  </ClickableContainer>
);

export default NvGradientBorderButton;
