import VideoPracticeOptionsModal from 'lecture_pages/components/video-practice/video-practice-options-modal';

/* @ngInject */
export default function VideoPracticeEditFormModalController(
  $state,
  $scope,
  $timeout,
  $uibModalInstance,
  _,
  vmResolves,
) {
  const vm = this;
  _.extend(vm, vmResolves);

  vm.title = '';
  vm.isCreate = !vm.editBasics && !vm.editScenario;

  const createVideoPractice = (videoPractice) => {
    vm.lectureComponent.videoPractice = videoPractice;
    $uibModalInstance.close();
  };

  const editVideoPractice = (data) => {
    $uibModalInstance.close(data);
  };

  const closeModal = () => {
    $uibModalInstance.dismiss();
  };

  const setTitle = (title) => {
    vm.title = title;
    $timeout(() => {
      vm.workflowCtx?.setSettings({
        ...vm.workflowCtx.settings,
        title: () => title,
      });
    });
  };

  $scope.VideoPracticeOptionsModalContent = () => (
    <VideoPracticeOptionsModal
      createVideoPractice={createVideoPractice}
      editVideoPractice={editVideoPractice}
      closeModal={closeModal}
      setTitle={setTitle}
      catalogId={$state.params.catalogId}
      editBasics={vm.editBasics}
      editScenario={vm.editScenario}
      formData={vm.formData}
    />
  );
}
