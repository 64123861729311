export default {
  bindings: {
    existingWorkspacePost: '<',
    shouldFocus: '<',
    saveDisabled: '<',
    onUpdate: '&',
    onCreate: '&',
    onCancel: '&',
  },
  controller: function ctrl(
    MentionablesModel,
    CurrentUserManager,
    ScrollFocusConnectorFactory,
    $uibPosition,
    $element,
    _,
    StateManager,
    config,
  ) {
'ngInject';
    const _this = this;

    this.config = config;

    this.$onChanges = function (changes) {
      if (changes.shouldFocus && !changes.shouldFocus.isFirstChange()) {
        this.focusTextBox();
      }
    };

    this.$onInit = function () {
      this.CurrentUserManager = CurrentUserManager;
      this.currentCourseRoles = CurrentUserManager.getCurrentUserCourseByUrl().user.roles;
      this.MentionablesModel = MentionablesModel;
      this.scrollParent = $uibPosition.scrollParent($element);
      this.workspacePost = {
        body: this.existingWorkspacePost ? this.existingWorkspacePost.body : '',
      };

      this.initConfirmationOverlay = initConfirmationOverlay;

      ScrollFocusConnectorFactory.focus(null, null, { elem: $element.find('[nv-froala-editor]') });
      this.initConfirmationOverlay();
      // this.mentionedIds = this.existingWorkspacePost ? MentionablesModel.parseMentionables(this.existingWorkspacePost.body) : null
    };

    this.focusTextBox = function () {
      ScrollFocusConnectorFactory.focus(null, null, { elem: $element.find('[nv-froala-editor]') });
    };

    this.$onDestroy = function () {
      this.deregisterStateChangeStart?.();
    };

    this.create = function () {
      this.onCreate({
        $event: {
          workspacePost: _.extend(this.workspacePost, {
            mentionedIds: MentionablesModel.parseMentionables(this.workspacePost.body),
          }),
        },
      });
    };

    this.update = function () {
      this.onUpdate({
        $event: {
          workspacePost: _.extend(this.existingWorkspacePost, this.workspacePost, {
            mentionedIds: MentionablesModel.parseMentionables(this.workspacePost.body),
          }),
        },
      });
    };

    function initConfirmationOverlay() {
      // - unsaved changes overlay
      const msg = 'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY';
      _this.deregisterStateChangeStart = StateManager.registerStateChangeStart(
        () => (_this.existingWorkspacePost ? _this.existingWorkspacePost.body !== _this.workspacePost.body : _this.workspacePost.body.length),
        'shared/templates/modal-navigate-away-unsaved-changes.html',
        msg,
      );
    }
  },
  controllerAs: 'vm',
  templateUrl: 'team_workspace/templates/nv-team-workspace-edit-post.html',
};
