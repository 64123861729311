/* @ngInject */
export default function NvScrollToInModal($timeout) {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      const offset = attrs.nvScrollToInModalOffset || 0;
      attrs.$observe('nvScrollToInModal', (value) => {
        if (value === 'true') {
          // need timeout for the content to load
          $timeout(() => {
            const modalBody = element.closest('.modal-body');
            modalBody.scrollTop(modalBody.scrollTop() + element.position().top - offset);
          });
        }
      });
    },
  };
}
