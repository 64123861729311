import { ComponentType } from 'redux/schemas/models/lecture-component';
import store from 'redux/store';

import { useAngularPreventLecturePageNavigation } from '../hooks/use-prevent-lecture-page-navigation';

/* @ngInject */
export default function FeedbackEditFormModalController(
  $uibModalInstance,
  $window,
  $scope,
  $stateParams,
  $timeout,
  _,
  $translate,
  moment,

  ConfirmationOverlays,
  StateManager,
  CurrentUserManager,
  CurrentCourseManager,
  ExercisesManager,
  CurrentPermissionsManager,
  validationConstants,
  PrivatePeerEvaluationLectureComponentModel,
  PublicPeerEvaluationLectureComponentModel,
  ExerciseSkillsRatingLectureComponentModel,

  vmResolves,
  config,
) {
  const vm = this;
  let dismissedByUser = false;

  vm.CurrentUserManager = CurrentUserManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.CurrentPermissionsManager = CurrentPermissionsManager;
  vm.lectureComponent = vmResolves.lectureComponent;
  vm.feedbackDraft = vmResolves.lectureComponent.feedbackDraft;
  vm.validationConstants = validationConstants;
  vm.selectedState = vm.lectureComponent.id ? vmResolves.lectureComponent.type : '';
  vm.config = config;
  vm.componentType = ComponentType;
  vm.showAssignmentSection = !!vm.lectureComponent.id;
  vm.skillTagsLink = `#!/institutions/${vm.CurrentPermissionsManager.institution.id}/skill-tags`;

  ExercisesManager.getAllExercisesForCourse($stateParams.catalogId).then(exercises => {
    vm.exercisesList = exercises;
    if (vm.feedbackDraft.requiredExercise) {
      vm.selectExercise(vm.feedbackDraft.requiredExercise, true);
    } else {
      $timeout(() => {
        if (vm.editFeedbackForm) {
          vm.editFeedbackForm.$setValidity('selectedTopic', false);
        }
      });
    }
  });

  vm.selectExercise = (exercise, setCurrentExercise = false) => {
    vm.feedbackDraft.exerciseId = exercise.id;
    vm.feedbackDraft.exercise = exercise;

    if (!setCurrentExercise) {
      vm.feedbackDraft.title = $translate.instant('LECTURE_PAGES.COMPONENTS.FEEDBACK_SHARED.FEEDBACK_TITLE', { assignmentName: exercise.title });

      _.extend(vm.feedbackDraft, _.pick(exercise, ['hardDeadline', 'deadline']));

      if (vm.editFeedbackForm) {
        vm.editFeedbackForm.$setValidity('selectedTopic', true);
        vm.editFeedbackForm.$setDirty();
      }
    }
  };

  vm.showReleaseDateWarning = () => {
    if (!vm.feedbackDraft.exercise) {
      return false;
    }
    const exerciseDeadline = moment(vm.feedbackDraft.exercise.deadline);
    const lectureComponentRelease = vm?.lectureComponent?.lecturePage?.releaseDate
      ? moment(vm.lectureComponent.lecturePage.releaseDate)
      : moment(vm.feedbackDraft.exercise.releaseDate);
    return vm.feedbackDraft.exercise?.hardDeadline
        && exerciseDeadline > lectureComponentRelease;
  };

  vm.filterExerciseOptions = () => {
    const exercisesList = vm.exercisesList?.filter((exercise) => exercise.addableTo.includes(vm.lectureComponent.type));

    if (vm.feedbackDraft.requiredExercise && exercisesList && !exercisesList?.some((exercise) => exercise.id === vm.feedbackDraft.requiredExercise.id)) {
      exercisesList.push(vm.feedbackDraft.requiredExercise);
    }
    return exercisesList;
  };

  vm.selectFeedbackType = (type) => {
    vm.selectedState = type;
    vm.lectureComponent.type = type;

    if (type === vm.componentType.PRIVATE_PEER_EVALUATION || type === vm.componentType.EXERCISE_SKILLS_RATING) {
      vm.feedbackDraft.isFeedbackPublic = false;
    } else {
      vm.feedbackDraft.isFeedbackPublic = true;
    }
    vm.showAssignmentSection = true;
  };

  vm.save = () => {
    if (vm.lectureComponent.type === vm.componentType.EXERCISE_SKILLS_RATING) {
      if (!vm.lectureComponent.id) {
        const component = new ExerciseSkillsRatingLectureComponentModel({}, false, true);
        vm.lectureComponent.setRealComponent(component);
        vm.feedbackDraft.description = component.skillsRating.description;
        vm.lectureComponent.realComponent.feedbackDraft = vm.feedbackDraft;
      }
      dismissedByUser = true;
      $uibModalInstance.close(vm.lectureComponent.id ? vm.feedbackDraft : false);
    } else {
      let component;
      if (vm.lectureComponent.type === vm.componentType.PRIVATE_PEER_EVALUATION) {
        component = new PrivatePeerEvaluationLectureComponentModel({}, false, true);
        if (!vm.lectureComponent.id) {
          vm.feedbackDraft.description = component.peerEvaluation.description;
        }
      } else {
        component = new PublicPeerEvaluationLectureComponentModel({}, false, true);
      }
      vm.lectureComponent.setRealComponent(component);
      vm.feedbackDraft.requiredExercise = vm.feedbackDraft.exercise;
      vm.lectureComponent.realComponent.feedbackDraft = vm.feedbackDraft;

      dismissedByUser = true;
      $uibModalInstance.close();
    }
  };

  function hasUnsavedChanges() {
    return vm.editFeedbackForm && (vm.editFeedbackForm.$dirty
        || (vm.feedbackDraft.exerciseId && vm.feedbackDraft.requiredExercise.id !== vm.feedbackDraft.exerciseId));
  }

  const checkUnsavedChanges = () => hasUnsavedChanges();

  useAngularPreventLecturePageNavigation($scope, checkUnsavedChanges);

  // warn if leaving the modal and have unsaved changes
  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(checkUnsavedChanges,
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    'FORM.NAVIGATE_AWAY.CLOSE_WINDOW',
    undefined,
    undefined,
    () => {
      vm.deregisterModalClosing?.();

      $uibModalInstance.dismiss();
    });

  vm.deregisterModalClosing = $scope.$on('modal.closing', ($event) => {
    if (hasUnsavedChanges() && !dismissedByUser) {
      $event.preventDefault();
      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        dismissedByUser = true;
        $uibModalInstance.dismiss();
      });
    }
  });

  $scope.$on('$destroy', () => {
    angular.element($window).off('beforeunload');
    vm.deregisterStateChangeStart?.();
  });

  vm.onNumberChange = ($event) => {
    let newNumber = $event.currentTarget.value;
    newNumber = newNumber.replace(/^0+/, '');
    if (newNumber !== vm.feedbackDraft.targetGoal) {
      vm.feedbackDraft.targetGoal = newNumber;
    }
  };

  vm.hasSkillTags = () => {
    const { skillTags } = store.getState().models;
    return !_.isEmpty(skillTags);
  };

  vm.getSubmitText = () => {
    if (vm.lectureComponent.id) {
      return 'FORM.UPDATE';
    }
    return vm.selectedState === vm.componentType.PRIVATE_PEER_EVALUATION
      ? 'LECTURE_PAGES.COMPONENTS.PRIVATE_PEER_EVALUATION.ADD_EVALUATION_CRITERIA_AND_RUBRICS'
      : 'LECTURE_PAGES.COMPONENTS.PUBLIC_PEER_EVALUATION.ADD';
  };
}
