import { css } from '@emotion/react';
import t from 'react-translate';
import _ from 'underscore';
import { useState } from 'react';
import { useSelector } from 'react-redux';

// Schemas
import { RteTag, RteTagTypes } from 'froala/helpers/nv-froala-constants';
import { ActivityType } from 'redux/schemas/models/activity';
import { LecturePageType } from 'redux/schemas/models/lecture-page';

// Selectors
import { getPrefixedTitle } from 'redux/selectors/activity';
import { getCourseAliases } from 'redux/selectors/course';

// Components
import NvIcon from 'shared/components/nv-icon';

// Styles
import { black, gray6, teal, primary, warning } from 'styles/global_defaults/colors';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { textSmallFontSize } from 'styles/global_defaults/fonts';

export type onItemChanged = (item: RteTag, checked: boolean) => void;

interface NvTagSelectorCheckboxProps {
  tag: RteTag
  onChange: onItemChanged,
}

const styles = css`
  position: relative;

  input[type=checkbox] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    display: block;

    +.tag-selector {
      background-color: ${gray6};
      border-radius: 20px;

      p {
        display: flex;
        margin: 0;
        color: ${black};
        padding: 7px ${halfSpacing}px;
        font-size: ${textSmallFontSize}px;
        height: 30px;

        i.icon {
          color: ${primary};
          margin-left: ${halfSpacing}px;
          padding: 2px;
          float: right;
        }

        span {
          max-width: 450px;
        }
      }
    }

    :checked+.tag-selector {
      background-color: ${teal};

      p {
        i.icon {
          color: ${warning};
        }
      }
    }
  }
`;

const formatTagDisplayName = (tag: RteTag, title: string): string => {
  if (tag.type === RteTagTypes.DATE) {
    if (tag.entityType === LecturePageType.LECTURE) {
      // Lecture Release Tag
      return t.FROALA.INSERT_TAGS.RELEASE_DATE_FOR(title);
    }
    if (tag.entityType === ActivityType.LIVE_SESSION) {
      // Live Stream Start Tag
      return t.FROALA.INSERT_TAGS.START_DATE_FOR(title);
    }
    // Assignment Due Date Tag
    return t.FROALA.INSERT_TAGS.DUE_DATE_FOR(title);
  }
  return title;
};

const TagDisplay = ({ tag }: { tag: RteTag }): any => {
  const aliases = useSelector((state) => getCourseAliases(state));
  const title = useSelector((state) => getPrefixedTitle(
    state,
    tag.entityType,
    tag.entityId,
    aliases,
    tag?.category,
  ));
  return formatTagDisplayName(tag, title);
};

export const FroalaTagSelectorCheckbox = (props: NvTagSelectorCheckboxProps) => {
  const [checked, setChecked] = useState<boolean>(!!props.tag.selected);

  const onSelectingIcon: (e: React.ChangeEvent<HTMLInputElement>) => void = (e) => {
    e.stopPropagation();
    setChecked(e.target.checked);
    props.onChange?.(props.tag, e.target.checked);
  };

  return (
    <div css={styles}>
      <input type='checkbox' checked={checked} onChange={onSelectingIcon} />
      <div className='tag-selector'>
        <p>
          <span className='ellipsis'>
            { props.tag.displayName ? props.tag.displayName : <TagDisplay tag={props.tag} /> }
          </span>
          { checked ? (
            <NvIcon icon='check' size='xss-smallest' />
          ) : (
            <NvIcon icon='create-new-post' size='xss-smallest' />
          )}
        </p>
      </div>
    </div>
  );
};

export default FroalaTagSelectorCheckbox;
