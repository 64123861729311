import { jsx } from '@emotion/react';
import t from 'react-translate';
import { useState, useContext } from 'react';
import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import NvUserAvatar from 'components/nv-user-avatar';

import { UserRoles } from 'redux/schemas/models/my-account';
import { AngularServicesContext } from 'react-app';

export interface UserItemProps {
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  profilePicture?: string,
  userNameForURL: string,
  fullName?: string,
  initials?: string,
  roles?: UserRoles,
}

interface UserListItemProps {
  user: UserItemProps,
  actionTooltip: string,
  actionIcon: string,
  onAction: (param: UserItemProps) => Promise<any>
}

const getInitials = (FirstName: string, LastName: string) => `${FirstName?.[0]?.toUpperCase() ?? ''}${LastName?.[0]?.toUpperCase() ?? ''}`;

const UserListItem = (props: UserListItemProps) => {
  const [loading, setLoading] = useState(false);
  const { user, actionTooltip, actionIcon, onAction } = props;
  const { $state } = useContext(AngularServicesContext) || {};

  const addUser = (data) => {
    setLoading(true);
    onAction(data).finally(() => setLoading(false));
  };

  return (
    <div className='d-flex align-items-center border-bottom border-gray-4 py-2'>
      <div className='pr-2'>
        <NvUserAvatar
          directToOrgLevelProfile
          openInNewTab
          borderType='round'
          size='md'
          user={{
            admin: false,
            ...user,
            fullName: user.fullName ?? `${user.firstName} ${user.lastName}`,
            roles: user.roles,
            initials: user.initials ?? getInitials(user.firstName, user.lastName),
            userNameForURL: user.userNameForURL ?? `${user.firstName}-${user.lastName}`,
          }}
        />
      </div>
      <div className='w-50 pr-2 font-weight-bolder'>
        <a
          href={$state?.href('org-level-profile-modal', { userId: user.id })}
          target='_blank'
          rel='noreferrer'
        >
          {`${user.firstName} ${user.lastName}`}
        </a>
      </div>
      <div className='w-50 pr-2'>{user.email}</div>
      <div>
        <NvTooltip text={actionTooltip}>
          <div>
            <NvIcon
              size='smallest'
              icon={actionIcon}
              className={`${loading ? 'gray-4' : 'color-primary'} pr-1`}
              onClick={() => !loading && addUser(user)}
            />
          </div>
        </NvTooltip>
      </div>
    </div>
  );
};

export default UserListItem;
