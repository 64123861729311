/* @ngInject */
export default function ChangeInstitutionCtrl(
  $location,
  $state,
  $stateParams,
  CurrentUserManager,
  RailsRoutes,
  config,
) {
  const vm = this;
  vm.$state = $state;
  vm.$stateParams = $stateParams;
  vm.CurrentUserManager = CurrentUserManager;
  vm.RailsRoutes = RailsRoutes;
  vm.config = config;
  vm.calculateSwitchInstitutionLink = calculateSwitchInstitutionLink;

  // User info
  const userHasOtherInstitutions = !!CurrentUserManager.user.institutions.filter(institution => institution.id !== CurrentUserManager.currentInstitution?.id)?.length;

  // The help container should show if:
  // 1. User doesn’t have any course assigned
  // 2. User belongs to only one institution && no discovery enabled for that institution
  // 3. User belongs to multiple institutions && no discovery enabled for any institution
  // -> routes: .state('dashboard')
  const userHasEnrollmentsInAnyInstitution = !!CurrentUserManager.user?.institutionsWithEnrollment?.length;
  const anyInstitutionHasDiscoveryEnabled = CurrentUserManager.user?.institutions.find(institution => institution?.discoveryEnabled);
  vm.shouldShowHelpContainer = !CurrentUserManager.user?.institutions?.length && (!userHasEnrollmentsInAnyInstitution || !anyInstitutionHasDiscoveryEnabled);

  if (vm.shouldShowHelpContainer) {
    console.log({
      institution: CurrentUserManager.user.institution,
      institutions: CurrentUserManager.user.institutions,
      institutionsWithEnrollment: CurrentUserManager.user.institutionsWithEnrollment,
      anyInstitutionHasDiscoveryEnabled,
      userHasOtherInstitutions,
    });
  }

  // The list of institutions should show if:
  // 1. User has active institutions
  // 2. User has other institutions than the current one
  vm.shouldShowInstitutionsList = CurrentUserManager.user?.institutions?.length && userHasOtherInstitutions && !vm.shouldShowHelpContainer;

  function calculateSwitchInstitutionLink(institution) {
    if (vm.$stateParams.mobileapp) {
      return `${$location.protocol()}://${institution.host}/${vm.$state.href('dashboard')}`;
    }
    return `${$location.protocol()}://${institution.host}/${vm.$state.href('root')}`;
  }
}
