/* @ngInject */
export default function FramedFroalaController(
  $stateParams,
  RichTextComposerResource,
  $timeout,
  nvUtil,
) {
  const vm = this;
  vm.stateParams = $stateParams;

  RichTextComposerResource.get({ textId: $stateParams.textId }).$promise.then((response) => {
    vm.textModel = response.result;
  });

  let submitEnabled = true;

  // Submitting the framed form directs to different app states based on whether
  // the backend update fails or succeeds
  vm.submit = function () {
    RichTextComposerResource.save({ textId: $stateParams.textId, body: vm.textModel }).$promise.then(() => {
      // The iOS app expects us to redirect to either /success or /failure in order for it to know
      // editing is finished and it should request the updated text
      let navLocation = `#!/success?textId=${$stateParams.textId}`;
      if (vm.froalaForm.$pristine) {
        // iOS only checks if 'pristine' is present, not the boolean val. Do not do `pristine=false` here
        navLocation += '&pristine=true';
      }
      // Unfortunately, doing an angular-ui-router transition via $state.go() does not seem to trigger the iOS
      // app's WKNavigationDelegate. No clue why. So we set document.location to achieve the same effect
      document.location.hash = navLocation;
    }).catch(() => {
      document.location.hash = `#!/failure?textId=${$stateParams.textId}`;
    });
  };

  vm.submitCallback = function () {
    // This is to stop the focusout submission, or there will be double
    // submission
    submitEnabled = false;
    vm.submit();
  };

  // Disable form submission during a paste event, as it unfocuses the input and will otherwise incorrectly submit the form.
  vm.beforePaste = function (e, editor, originalEvent) {
    submitEnabled = false;
  };

  vm.afterPaste = function (e, editor) {
    submitEnabled = true;
  };

  $('form[name="vm.froalaForm"]').submit(vm.submit);

  $(document).ready(() => {
    $('.loading-screen').hide();

    // Trigger form submission when the input loses focus. This most notably happens
    // when the iOS keyboard closes via the iOS 'Done' button.
    $('.fr-element').focusout(() => {
      // Ensure neither the media upload modal nor any froala popups are active
      // and check newly focused element is not under the parent framed-froala
      $timeout(() => {
        if (!$('.super-modal').length
          && !$('.fr-popup.fr-active').length
          && !nvUtil.checkFocusWithin($('.framed-froala')[0])
          && submitEnabled) {
          vm.submit();
        }
      });
    });
  });
}
