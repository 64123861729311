import { css } from '@emotion/react';
import React, { useCallback } from 'react';
import {
  boldFontWeight,
  textExtraLargeFontSize,
} from 'styles/global_defaults/fonts';
import { screenXsMax } from 'styles/global_defaults/media-queries';
import { useMediaQuery } from 'react-responsive';
import {
  getColorBetweenColorsByPercentage,
  gray2,
  gray4,
  gray6,
  success,
} from 'styles/global_defaults/colors';
import {
  doubleSpacing,
  halfSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import NvIcon from './nv-icon';

export enum ProgressGaugeType {
  ACTUAL_VS_TOTAL = 'ActualVsTotal',
  ACTUAL_ONLY = 'ActualOnly',
  ACTUAL_WITH_ICON = 'ActualWithIcon',
  NONE = 'none',
}

export enum ProgressGaugeSize {
  SMALL = 'Small',
  LARGE = 'Large',
}

type Props = {
  currentValue: number,
  maxValue: number,
  activeColor?: string,
  inactiveColor?: string,
  bgColor?: string,
  size?: string,
  icon?: string,
  label?: string,
  displayType?: ProgressGaugeType,
  selected?: boolean,
};

const NvProgressGauge = (props: Props) => {
  const {
    currentValue,
    maxValue,
    activeColor = success,
    inactiveColor = gray6,
    bgColor = '#fff',
    size = ProgressGaugeSize.SMALL,
    icon,
    label,
    displayType = ProgressGaugeType.NONE,
    selected = false,
  } = props;

  const SMALL_GAUGE_WIDTH = 6;

  let rotation = 0;
  let over50 = false;
  let percentage = +(currentValue / maxValue).toFixed(2);

  if (percentage >= 1) {
    percentage = 1;
  }

  // Start Color for the gradient, calculated at .4 percentage from white and active color
  const startColor = getColorBetweenColorsByPercentage('#fff', activeColor, 0.4);
  const endColor = activeColor;

  let part1EndColor = activeColor;
  let part2StartColor = activeColor;

  if (percentage >= 0.5) {
    // If values is > 50%, radial graph is constructed with 2 half circles.
    // 1st half circles cover the 0 - 50
    // 2nd half circles cover from %value - 50% - %value
    // So we need to find the gradient color for the point (%value - 50%)
    // and start gradient from that color for the first part
    // Eg: if the value is 75, then
    // First part half circle will cover 0 to 50 and Second half circle will cover 25( ie 75 - 50) to 75
    // Here we calculate the color for point 25 start gradient from that color for the 2nd half circle

    part1EndColor = getColorBetweenColorsByPercentage(startColor, endColor, 0.5);
    part2StartColor = getColorBetweenColorsByPercentage(startColor, endColor, (percentage - 0.5));
    over50 = true;
  } else {
    // Only Part 2 half circle is visible. So set part2Start color as start color
    part1EndColor = endColor;
    part2StartColor = startColor;
  }

  // Calculate the rotation needed for accurately clip the half circles
  // Each percentage take 1 % of 360
  rotation = Math.round(percentage * 100 * 3.6);

  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const progressGaugeStyles = (width, gaugeWidth) => css`
  .progress-circle {
    background-color: ${inactiveColor};
    position: relative; /* so that children can be absolutely positioned */
    border-radius: 50%;
    width: ${width}px;
    height: ${width}px;
    ${selected ? `
      box-shadow: 0px 0px 17px -2px ${startColor};
    ` : ''}

    .center-gauge-content {
      border-radius: 50%;
      position: absolute;
      z-index: 2;
      top: ${gaugeWidth / 2}px;
      left: ${gaugeWidth / 2}px;
      width: calc(100% - ${gaugeWidth}px);
      height: calc(100% - ${gaugeWidth}px);
      background-color: ${bgColor ?? 'white'};
      color: ${currentValue > 0 ? activeColor : gray4};
      ${selected ? `
        box-shadow: inset 0px 0px 17px -6px ${startColor};
      ` : ''}
    }

    .cs-actual-with-icon {
      .current {
        color: ${activeColor};
        line-height: 1;

        ${isHandheld && css`
          font-size: ${textExtraLargeFontSize}px;
        `}
      }
    }

    .cs-actual-vs-total {
      justify-content: space-evenly;

      .current {
        color: ${activeColor};
        line-height: 1;
        ${isHandheld && css`
          font-size: ${textExtraLargeFontSize}px;
        `}
      }
      .max {
        color: ${gray2};
      }
      .gauge-label {
        color: ${gray2};
      }
    }

    .cs-actual-only {
      .current {
        color: ${activeColor};
        font-weight: ${boldFontWeight};
        line-height: 1;
      }
    }

    &.over50 {
      .left-half-clipper {
        clip: rect(auto,auto,auto,auto);
      }

      .first50-bar {
        /*Progress bar for the first 50%, filling the whole right half*/
        position: absolute; /*needed for clipping*/
        border-radius: 50%;

        width: ${width}px;
        height: ${width}px;
        clip: rect(0, ${width}px, ${width}px, ${width / 2}px);
      }
    }

    .left-half-clipper {
      /* a round circle */
      border-radius: 50%;
      position: absolute; /* needed for clipping */

      width: ${width}px;
      height: ${width}px;
      clip: rect(0, ${width}px, ${width}px, ${width / 2}px); /* clips the whole left half*/

      .first50-bar {
        ${percentage === 1 ? `
          background-color: ${activeColor};
        ` : `
          background-color: ${endColor};
          background: linear-gradient(180deg, ${startColor} 0%, ${part1EndColor} 100%);
        `}
      }
    }

    .value-bar {
      /* This is an overlayed square, that is made round with the border radius,
      then it is cut to display only the left half, then rotated clockwise
      to escape the outer clipping path. */
      position: absolute; /*needed for clipping*/
      border-radius: 50%;
      box-sizing: border-box;

      width: ${width}px;
      height: ${width}px;
      clip: rect(0, ${width / 2}px, ${width}px, 0);
      transform: rotate(${rotation}deg);

      ${percentage === 1 ? `
        background-color: ${activeColor};
      ` : `
        background-color: ${endColor};
        background: linear-gradient(0deg, ${part2StartColor} 0%, ${endColor} 100%);
      `}
    }
  }
`;

  const getStyleParams = useCallback((): [number, number] => {
    let circleWidth = doubleSpacing;
    let gaugeWidth = SMALL_GAUGE_WIDTH;

    if (size === ProgressGaugeSize.LARGE) {
      circleWidth = 2 * tripleSpacing;
      gaugeWidth = halfSpacing;

      if (isHandheld) {
        circleWidth = tripleSpacing;
        gaugeWidth = SMALL_GAUGE_WIDTH;
      }
    }

    return [circleWidth, gaugeWidth];
  }, [size, isHandheld]);

  return (
    <div className='nv-progress-gauge' css={progressGaugeStyles(...getStyleParams())}>
      <div className={`progress-circle p-0 ${over50 ? 'over50' : ''}`}>
        <div className='center-gauge-content d-block text-center'>
          { displayType === ProgressGaugeType.ACTUAL_VS_TOTAL && (
            <div className='cs-actual-vs-total d-flex flex-column align-items-center w-100 h-100 p-2'>
              {size === ProgressGaugeSize.LARGE && (
                <NvIcon size='xss-smallest' icon={icon} className='hidden-xs m-0' />
              )}

              <div className='d-flex py-1'>
                {size === ProgressGaugeSize.LARGE ? (
                  <React.Fragment>
                    <div className='current condensed font-weight-light header-lg align-self-end'>
                      {currentValue}
                    </div>
                    <div className='max hidden-xs condensed text-medium font-weight-light text-medium mt-2 align-self-end'>
                      {`/ ${maxValue}`}
                    </div>
                  </React.Fragment>
                ) : (
                  <div className='condensed text-xs'>
                    {currentValue}
                    <span className='max'>{` /${maxValue}`}</span>
                  </div>
                )}
              </div>
              {label && size === ProgressGaugeSize.LARGE && (
                <span className='gauge-label hidden-xs condensed text-medium font-weight-light'>
                  {label}
                </span>
              )}
            </div>
          )}

          {displayType === ProgressGaugeType.ACTUAL_WITH_ICON && (
            <div className='cs-actual-with-icon d-flex flex-column align-items-center w-100 h-100 p-2'>
              <NvIcon size='xss-smallest' icon={icon} className='hidden-xs m-0' />

              {size === ProgressGaugeSize.LARGE && (
                <div className='d-flex my-auto'>
                  <div className='current condensed font-weight-light header-lg align-self-end'>
                    {currentValue}
                  </div>
                </div>
              )}
            </div>
          )}

          {displayType === ProgressGaugeType.ACTUAL_ONLY && (
            <div className='cs-actual-only d-flex justify-content-center align-items-center flex-column align-items-center w-100 h-100 p-2'>
              <div className='d-flex'>
                <div className={`current align-self-end course-title-${size === ProgressGaugeSize.SMALL ? 'xs' : 'l'}`}>
                  {currentValue}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='left-half-clipper'>
          <div className='first50-bar' />
          <div className='value-bar' />
        </div>
      </div>
    </div>
  );
};

export default NvProgressGauge;
