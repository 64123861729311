import { STATUS } from './embed-lecture-component-model';

/* @ngInject */
export default function LTILectureComponentModel(
  _,
  ExternalToolLectureComponentModel,
  config,
) {
  class LTILectureComponent extends ExternalToolLectureComponentModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      const defaults = {
        editFormTemplateUrl: 'lecture_pages/templates/components/lit-edit-modal.html',
        formName: 'editLTIForm',
        externalTool: {
          toolType: 'lti',
          url: null,
          consumerKey: null,
          consumerSecret: null,
          gradePassback: false,
          sendEmail: false,
          aspectRatio: '4:3',
          activityType: 'reading',
          autocompleteScorm: true,
        },
        allowFullScreen: true,
      };

      super(_.extend({}, defaults, attributes), isSample, useDefaults);
    }

    shouldShowMarkDone() {
      return (this.externalTool.isTodo || this.externalTool.totalPoints) && !this.externalTool.gradePassback && !this.externalTool.isResizing;
    }

    getStatus() {
      return this.externalTool.progress;
    }

    inProgress() {
      return this.getStatus() === STATUS.IN_PROGRESS;
    }

    isNotStarted() {
      return this.getStatus() === STATUS.NOT_STARTED;
    }

    isCompleted() {
      return this.getStatus() === STATUS.COMPLETED;
    }
  }

  LTILectureComponent.iconClass = 'icon-admin-lti-tools';
  LTILectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.LTI.DESCRIPTION';
  LTILectureComponent.showModalBeforeCreate = true;
  LTILectureComponent.pendoTagName = config.pendo.lectureEdit.lti;

  return LTILectureComponent;
}
