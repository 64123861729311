/* @ngInject */
export default function NvActiveDraftCard(
  moment,
  config,
) {
  return {
    scope: {
      activeDraft: '=',
      deliverableType: '=',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;

      vm.config = config;

      let effectiveDeadline;
      if (this.activeDraft.exercise.extendedDeadline) {
        effectiveDeadline = this.activeDraft.exercise.extendedDeadline;
      } else {
        effectiveDeadline = this.activeDraft.exercise.deadline;
      }

      const lastUpdatedMoment = moment(this.activeDraft.lastSavedAt);
      const currentMoment = moment();

      vm.type = {
        QuestionSetSubmission: 'QuestionSetSubmission',
      };

      const deadlineMoment = moment(effectiveDeadline);
      vm.effectiveDeadline = effectiveDeadline;
      vm.isDueToday = deadlineMoment.isSame(currentMoment, 'days');
      vm.isDueThisWeek = deadlineMoment.diff(currentMoment, 'days') > 0 && deadlineMoment.diff(currentMoment, 'days') < 7;


      // Functions to calculate last updated time format
      vm.lastUpdatedTranslateKey = function () {
        if (currentMoment.diff(lastUpdatedMoment, 'months') > 0) {
          return 'DASHBOARD.LAST_UPDATED.MONTHS_AGO';
        } if (currentMoment.diff(lastUpdatedMoment, 'days') > 0) {
          return 'DASHBOARD.LAST_UPDATED.DAYS_AGO';
        } if (currentMoment.diff(lastUpdatedMoment, 'hours') > 0) {
          return 'DASHBOARD.LAST_UPDATED.HOURS_AGO';
        } if (currentMoment.diff(lastUpdatedMoment, 'minutes') > 0) {
          return 'DASHBOARD.LAST_UPDATED.MINUTES_AGO';
        } if (currentMoment.diff(lastUpdatedMoment, 'seconds') > 0) {
          return 'DASHBOARD.LAST_UPDATED.LESS_THAN_MINUTE_AGO';
        }

        return null;
      };

      vm.lastUpdatedTranslateValue = function () {
        if (currentMoment.diff(lastUpdatedMoment, 'months') > 0) {
          return currentMoment.diff(lastUpdatedMoment, 'months');
        } if (currentMoment.diff(lastUpdatedMoment, 'days') > 0) {
          return currentMoment.diff(lastUpdatedMoment, 'days');
        } if (currentMoment.diff(lastUpdatedMoment, 'hours') > 0) {
          return currentMoment.diff(lastUpdatedMoment, 'hours');
        } if (currentMoment.diff(lastUpdatedMoment, 'minutes') > 0) {
          return currentMoment.diff(lastUpdatedMoment, 'minutes');
        } if (currentMoment.diff(lastUpdatedMoment, 'seconds') > 0) {
          return currentMoment.diff(lastUpdatedMoment, 'seconds');
        }

        return null;
      };
    },
    replace: true,
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'dashboard/templates/nv-active-draft-card.html',
  };
}
