/* eslint-disable react/require-default-props */
import { css } from '@emotion/react';
import t from 'react-translate';
import NvTooltip from 'shared/components/nv-tooltip';
import { gray4, primary } from 'styles/global_defaults/colors';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import ClickableContainer, { ClickableContainerProps } from '../../../components/clickable-container';

const containerStyle = css`
  margin: ${halfSpacing}px 0;
  align-items: center;
  display: inline-flex;
`;

const addIconContainerStyle = css`
  color: ${primary};
  .add-accordion-section[aria-disabled='true'] & {
    color: ${gray4};
  }
`;

const labelContainerStyle = css`
  margin-left: 10px;
  color: ${primary};
  .add-accordion-section[aria-disabled='true'] & {
    color: ${gray4};
  }
`;

interface AddSectionProps {
  onClick: ClickableContainerProps['onClick'];
  isLoading: boolean;
  disableMessage?: string;
}

/* @ngInject */
export default function AddSection(props: AddSectionProps) {
  return (
    <NvTooltip enabled={Boolean(props.disableMessage)} text={props.disableMessage}>
      <ClickableContainer
        className='add-accordion-section'
        css={containerStyle}
        onClick={props.onClick}
        disabled={props.isLoading || Boolean(props.disableMessage)}
      >
        <div className='icon-smallest icon-add' css={addIconContainerStyle} />
        <div className='text-medium' css={labelContainerStyle}>
          {props.isLoading ? t.LECTURE_PAGES.COMPONENTS.ACCORDION.ADDING_NEW_SECTION() : t.LECTURE_PAGES.COMPONENTS.ACCORDION.ADD_NEW_SECTION()}
        </div>
      </ClickableContainer>
    </NvTooltip>
  );
}
