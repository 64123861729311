import React, { useEffect, useRef } from 'react';
import { SkillTag } from 'redux/schemas/models/skill-tag';
import NvCelebrationBody from './nv-celebration-body';

const ANIMATION_DURATION = 3000;

export enum CelebrationType {
  POINTS = 'POINTS',
  SKILLS = 'SKILLS',
}

export type ExtrasForCelebrationModal = {
  submissionScore: number
  maxScore: number
  lowerScoreThanPreviousAttempt: number
  pointsReceivedFromPreviousAttempt: number
  skillTags: SkillTag[]
};

const getCelebrationType = ({ hasSkills, hasPoints }): CelebrationType => {
  if (hasPoints) return CelebrationType.POINTS;
  if (hasSkills) return CelebrationType.SKILLS;
  return CelebrationType.POINTS;
};

export type NvCelebrationModalProps = {
  isAdmin: boolean
  showSkillTagsAnimation: boolean
  modal: ng.ui.bootstrap.IModalInstanceService & { canClose: boolean }
  closeModal: () => void
  // vm props from Angular
  pointsReceived: number
  leaderboardPoints: number
  leaderboardRank: number
  priorLeaderboardRank: number
  extras?: ExtrasForCelebrationModal
};

const NvCelebrationModal = (props: NvCelebrationModalProps) => {
  const {
    pointsReceived,
    extras,
    showSkillTagsAnimation,
    modal,
    closeModal,
  } = props;

  const hasSkills = extras?.skillTags?.length > 0;
  const hasPoints = pointsReceived > 0;
  const originalDismiss = useRef(null);

  const initialCelebrationType = showSkillTagsAnimation
    ? CelebrationType.SKILLS
    : getCelebrationType({ hasSkills, hasPoints });

  const [type, setType] = React.useState<CelebrationType>(initialCelebrationType);

  const dismiss = (reason) => {
    if (modal.canClose) originalDismiss.current(reason);
    else setType(CelebrationType.SKILLS);
  };

  useEffect(() => {
    // Controls the modal closing behavior and the duration of the animations.
    if (type === CelebrationType.POINTS) {
      setTimeout(() => {
        if (hasSkills) {
          setType(CelebrationType.SKILLS);
          modal.canClose = true;
        } else closeModal();
      }, ANIMATION_DURATION);
    } else {
      setTimeout(closeModal, ANIMATION_DURATION);
      modal.canClose = true;
    }
  }, [type]);

  useEffect(() => {
    originalDismiss.current = modal.dismiss;
    modal.canClose = !hasSkills || type === CelebrationType.SKILLS;
    modal.dismiss = dismiss;
  }, []);

  return (
    <div
      className='text-center modal-body pl-1 pr-1'
      aria-live='assertive'
      role='alert'
      aria-atomic='true'
      aria-labelledby='points'
    >
      <NvCelebrationBody type={type} {...props} />
    </div>
  );
};

export default NvCelebrationModal;
