import { css } from '@emotion/react';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import t from 'react-translate';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, isUndefined } from 'underscore';
import { doubleSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { AngularServicesContext } from 'react-app';

// redux
import { RootState } from 'redux/schemas';
import { CollectionFolder } from 'redux/schemas/models/collections';
import { getCollectionFolder } from 'redux/selectors/collections';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { createLecturePage, deleteFolder, setNewFolderId, setFolderExpandedStatus,
  setHighlightedLessonId, updateFolder,
} from 'redux/actions/collections';
import { closeSavingOverlay, openSavingOverlay } from 'redux/actions/saving-overlay';

// components
import NvIcon from 'shared/components/nv-icon';
import ClickableContainer from 'components/clickable-container';
import { FormNvClickToEdit, InputType } from 'components/nv-click-to-edit';
import { gray6, gray7 } from 'styles/global_defaults/colors';
import NvDropdown, { NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import NvTooltip, { TextAlign } from 'shared/components/nv-tooltip';
import { config } from '../../../../config/config.json';
import FolderLectureList from './folder-lecture-list';

interface FolderProps {
  folderId: number;
  isLast: boolean;
  isDragging: boolean;
  setCollectionFolderIds: Function;
}

interface FormData {
  title: string,
}

export const CollectionFolderContext = createContext(null);

const Folder = (props: FolderProps) => {
  const styles = css`
    .folder-header {
      height: ${tripleSpacing}px;
      border-top: 1px solid ${gray6};
      .empty {
        min-width: ${doubleSpacing}px;
      }
    }
    .reorder-icon {
      opacity: 0;
    }
    .folder-header:hover .reorder-icon {
      opacity: 1;
    }
    .right-container {
      min-width: 170px;
      .nv-dropdown {
        display: flex;
        align-items: center;
      }
    }

    .expanded, .last-folder {
      border-bottom: 1px solid ${gray6};
    }

    .folder-name {
      width: calc(100% - 285px); // 220px - add button width + total spacing

      .content-container:hover {
        background: none;
      }

      .text-container span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      input {
        font-weight: 600;
        border: none;
        background: ${gray7};
        padding-left: 0;
        font-size: 18px;
        font-family: Open Sans Condensed;
      }
    }
  `;

  const currentfolder: CollectionFolder = useSelector((state: RootState) => getCollectionFolder(state, props.folderId));
  const isExpanded: boolean = useSelector((state: RootState) => state.app.collection.folder.expanded[props.folderId]);
  const highlightedLessonId: number = useSelector((state: RootState) => state.app.collection.highlightedLessonId);
  const newFolderId = useSelector((state: RootState) => state.app.collection.newFolderId);

  const [expand, setExpanded] = useState<boolean>(isExpanded);
  const folderRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const params = useLecturePageParams();
  const { $state, CurrentPermissionsManager } = useContext(AngularServicesContext);

  const hasEditPermission = CurrentPermissionsManager.hasCollectionBuilderPermissions();

  const validationSchema = yup.object().shape({
    title: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .max(255, t.VALIDATION.MAX('255')),
  });

  const methods = useForm<FormData>({
    mode: 'onChange',
    shouldUnregister: true,
    defaultValues: {
      title: currentfolder?.title,
    },
    resolver: yupResolver(validationSchema),
  });

  const { formState: { isValid }, getValues, setValue } = methods;

  const unsetRowHighlightLessonId = useCallback(() => {
    dispatch(setHighlightedLessonId(null));
  }, [dispatch]);

  // Removing the row's highlight on clicking the window.
  useEffect(() => {
    if (highlightedLessonId) {
      window.addEventListener('click', unsetRowHighlightLessonId);
    }

    return () => {
      if (highlightedLessonId) {
        window.removeEventListener('click', unsetRowHighlightLessonId);
      }
    };
  }, [highlightedLessonId, unsetRowHighlightLessonId]);

  const changeExpanded = useCallback((value: boolean) => {
    setExpanded(value);

    if (props.folderId) {
      dispatch(setFolderExpandedStatus({ folderId: props.folderId, value }));
    }
  }, [dispatch, props.folderId]);

  useEffect(() => {
    if (highlightedLessonId
      && isUndefined(expand)
      && !isEmpty(currentfolder)
      && currentfolder.lecturePages?.includes(highlightedLessonId)
    ) {
      changeExpanded(true);
    }
  }, [changeExpanded, currentfolder, currentfolder?.lecturePages, expand, highlightedLessonId]);

  useEffect(() => {
    if (currentfolder?.id === newFolderId) {
      scrollToRef(folderRef);
      dispatch(setNewFolderId(null));
    }
  }, [currentfolder?.id, dispatch, folderRef, newFolderId]);

  const scrollToRef = (ref) => {
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  };

  const onTitleChange = (value) => {
    if (isEmpty(value)) {
      /**
       * Default is name "Folder Name". So replacing with default when user
       * delete all characters.
       */
      setValue('title', 'Folder Name', { shouldValidate: true });
    }

    if (isValid) {
      dispatch(updateFolder({
        title: getValues()?.title,
        folderId: currentfolder.id,
      }));
    }
  };

  const addNewLecturePage = useCallback(() => {
    dispatch(openSavingOverlay());

    dispatch(createLecturePage({
      title: 'New Lesson',
      folderId: currentfolder.id,
    })).then((response) => {
      if (isEmpty(response?.error)) {
        $state.go(
          'lecture-page-edit',
          { catalogId: params.catalogId, id: response.payload.id },
        ).then(() => {
          dispatch(closeSavingOverlay());
        });
      } else {
        dispatch(closeSavingOverlay());
      }
    });
  }, [$state, currentfolder?.id, dispatch, params.catalogId]);

  /**
   * Used to check whether the class name provided element is ellipsed or not
   * based on the span element's offsetWidth and scrollWidth.
   */
  const isEllipsisActive = (className) => {
    const element: HTMLElement = document.querySelector(`.${className} span`);
    return element?.offsetWidth < element?.scrollWidth;
  };

  const folderContextValue = {
    addNewLecturePage,
  };

  const getFolderTitleTooltip = () => (
    isEllipsisActive(`folder-${currentfolder.id}`) ? currentfolder.title : t.FORM.CLICK_TO_EDIT());

  const onDeleteFolder = () => {
    dispatch(openConfirmationDialog({
      title: t.TIMELINE.DELETE_COLLECTION_LESSON.TITLE(),
      bodyText: t.TIMELINE.DELETE_COLLECTION_LESSON.DESCRIPTION(),
      confirmText: t.FORM.YES_SURE(),
      onConfirm: () => {
        const deleteParams = {
          folderId: currentfolder.id,
          folderTitle: currentfolder.title,
          catalogId: params.catalogId,
        };
        dispatch(deleteFolder(deleteParams)).then(result => {
          props.setCollectionFolderIds((prevState) => {
            const prevFolderIds = [...prevState];
            const newFolderIds = prevFolderIds.filter((eachId) => eachId !== currentfolder.id);
            return newFolderIds;
          });
          dispatch(addAlertMessage({
            type: AlertMessageType.SUCCESS,
            header: t.FORM.SUCCESS_BANG(),
            message: t.TIMELINE.DELETE_LESSON.SUCCESS_ALERT(result.meta.arg.folderTitle),
          }));
        });
      },
    }));
  };

  const folderDropdownItems: NvDropdownOption[] = [
    {
      type: 'text',
      text: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.DELETE_FOLDER(),
      class: 'text-danger',
      callback: onDeleteFolder,
      disabled: currentfolder?.contentManagementLinksCount > 0,
      tooltip: {
        text: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.DELETE_FOLDER_DISABLED_TOOLTIP(),
        enabled: currentfolder?.contentManagementLinksCount > 0,
        textAlign: TextAlign.LEFT,
      },
      dataQa: config.pendo.contentManagement.deleteFolder,
    },
  ];

  return (
    <CollectionFolderContext.Provider value={folderContextValue}>
      {!isEmpty(currentfolder) && (
        <div className={`bg-gray-7 ${!hasEditPermission ? 'non-draggable' : ''}`} css={styles} ref={folderRef}>
          <div
            className={`folder-header d-flex align-items-center cursor-pointer ${expand ? 'expanded' : ''} ${props.isLast ? 'last-folder' : ''}`}
            onClick={() => changeExpanded(!expand)}
            data-qa={!expand ? config.pendo.contentManagement.expandFolder : config.pendo.contentManagement.contractFolder}
            data-qa-id={!expand ? `${config.pendo.contentManagement.expandFolder}_${currentfolder?.id}` : `${config.pendo.contentManagement.contractFolder}_${currentfolder?.id}`}
          >
            <NvTooltip text={t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.DRAG_AND_DROP()} enabled={!props.isDragging && hasEditPermission}>
              <div className='pl-4 reorder-icon drag'>
                <NvIcon
                  className={`${(props.isDragging || !hasEditPermission) ? 'invisible' : ''}`}
                  icon='reorder'
                  size='xs-smallest'
                />
              </div>
            </NvTooltip>
            <NvIcon
              icon={`arrow-${expand ? 'down' : 'right'}`}
              size='xs-smallest'
              className='pl-4'
            />
            <NvIcon icon='admin-folder' size='xs-smallest' className='px-2 text-gray-3' />
            <div
              className='folder-name d-flex'
              onClick={(e) => (hasEditPermission ? e.stopPropagation() : null)}
            >
              <FormProvider {...methods}>
                <FormNvClickToEdit
                  type={InputType.SHORT_TEXT}
                  name='title'
                  text='Folder Name'
                  replaceText
                  editable={hasEditPermission}
                  className='w-100 course-title-small text-black text-nowrap font-weight-bolder'
                  textContainerClassName={`folder-${currentfolder.id} d-flex align-items-center`}
                  onChange={onTitleChange}
                  maxLength={250}
                  /**
                   * Showing the tooltip text as folder title if the text is
                   * ellipsed else showing the default tooltip text.
                   */
                  tooltipText={(hasEditPermission && !props.isDragging) ? getFolderTitleTooltip() : ''}
                  tooltipMaxWidth={500}
                  tooltipPlacement='top-start'
                  tooltipTextAlign={TextAlign.LEFT}
                />
              </FormProvider>
            </div>
            <div className='empty gray-2 italic text-small mt-2'>
              {!expand && currentfolder.lecturePagesCount === 0 && <p>{t.TIMELINE.EMPTY()}</p>}
            </div>
            {hasEditPermission && (
              <div className='d-flex mr-2 right-container'>
                <ClickableContainer
                  className='d-flex align-items-center text-regular ml-4 mr-1 text-primary'
                  onClick={(e) => { e.stopPropagation(); addNewLecturePage(); }}
                  data-qa={config.pendo.contentManagement.createNewCollectionLesson}
                  data-qa-id={`${config.pendo.contentManagement.createNewCollectionLesson}_${currentfolder?.id}`}
                >
                  <NvIcon icon='add' size='xs-smallest' className='pr-1' />
                  {t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.ADD_LESSON()}
                </ClickableContainer>
                <ClickableContainer
                  className='ml-5'
                  onClick={(e) => { e.stopPropagation(); }}
                >
                  <NvDropdown
                    buttonStyle={NvDropdownButtonStyle.ICON}
                    iconClass='icon-more text-primary'
                    items={folderDropdownItems}
                    toggleDataQa={config.pendo.contentManagement.folderMenu}
                    toggleDataQaId={`${config.pendo.contentManagement.folderMenu}_${currentfolder?.id}`}
                  />
                </ClickableContainer>
              </div>
            )}

          </div>
          {expand && (
            <FolderLectureList folderId={props.folderId} />
          )}
        </div>
      )}
    </CollectionFolderContext.Provider>
  );
};

export default Folder;
