
import { useState, createContext, useContext } from 'react';
import { RteTag } from 'froala/helpers/nv-froala-constants';

const RteTagStateContext = createContext(undefined);
const RteTagDispatchContext = createContext(undefined);

export const RteTagContextProvider = (props) => {
  const [tagsHistory, setTagsHistory] = useState<RteTag[]>([]);

  const addToTagsHistory = (tag: RteTag): void => {
    setTagsHistory(prevTagsHistory => ([
      ...prevTagsHistory,
      tag,
    ]));
  };

  return (
    <RteTagStateContext.Provider value={tagsHistory}>
      <RteTagDispatchContext.Provider value={addToTagsHistory}>
        {props.children}
      </RteTagDispatchContext.Provider>
    </RteTagStateContext.Provider>
  );
};

/* @ngInject */
export function useTagsState(): RteTag[] {
  const context = useContext(RteTagStateContext);
  if (context === undefined) {
    throw new Error('useTagsState must be used within a RteTagContextProvider');
    // In order to silently fails if not used within a RteTagContextProvider return an empty array
    // return [];
  }
  return context;
}

/* @ngInject */
export function useTagsDispatch() {
  const context = useContext(RteTagDispatchContext);
  if (context === undefined) {
    throw new Error('useTagsDispatch must be used within a RteTagContextProvider');
    // In order to silently fails if not used within a RteTagContextProvider return an empty function
    // return () => {};
  }
  return context;
}
