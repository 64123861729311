import React from 'react';
import { css } from '@emotion/react';
import { Omit } from 'utility-types';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { Controller } from 'react-hook-form';
import Dropdown from 'react-bootstrap/Dropdown';

import t from 'react-translate';
import { mergeRefs } from 'shared/react-utils';
import { readFileAsDataURL } from 'shared/utils';
import NvFilePicker from 'shared/components/nv-filepicker';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { InstitutionLogoSize } from 'redux/schemas/models/institution';
import { NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { black, gray3, hexToRgbaString } from 'styles/global_defaults/colors';
import BrandingHeader, {
  MAX_FILE_SIZE,
  Ref as BrandingHeaderRef,
  Value as BrandingHeaderValue,
  Props as BrandingHeaderProps,
} from 'shared/components/branding-header';
import {
  halfSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import { getCourse } from 'redux/selectors/course';

export type Props = Omit<BrandingHeaderProps, 'renderLogoContainer' | 'logoDropdownOptions'> & {
  /**
   * Catalog id of the offering, only required for when "editable" prop is false
   */
  catalogId?: string,
  updateLogoText?: string,
  /**
   * If true, parent program name is displayed after the logo. Defaults to false.
   */
  showParentProgram?: boolean,
};

/**
 * OfferingBrandingHeader is a variant of BrandingHeader that actually
 * implements it, it extends the BrandingHeader to allow user change the
 * offering logo size, reset the logo to the institution one and use current
 * institution branding as initial value (for when we are creating a new
 * offering).
 */
const OfferingBrandingHeader = React.forwardRef<BrandingHeaderRef, Props>((props, ref) => {
  const {
    value,
    editable,
    onChange,
    catalogId,
    updateLogoText,
    showParentProgram = false,
    ...restProps
  } = props;

  const hasOwnLogo = !!value.logo;
  const brandingHeaderRef = React.useRef<BrandingHeaderRef>();
  const currentInstitution = useSelector(getCurrentInstitution);
  const hasCurrentInstitutionLogo = !!currentInstitution.logo;
  const currentOffering = useSelector((state) => getCourse(state, catalogId));
  const [
    showDeleteLogoConfirm,
    setShowDeleteLogoConfirm,
  ] = React.useState(false);

  const handleChange = (newData: Partial<BrandingHeaderValue>) => onChange({
    ...value,
    ...newData,
  });

  const styles = css`
    .logo-item-action {
      color: ${black};
      padding: ${quarterSpacing}px ${threeQuartersSpacing}px;
    }
  `;

  const deleteOverlayStyles = css`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    color: ${'#fff'};
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: ${hexToRgbaString(black, 95)};

    & > *:first-of-type {
      margin-right: ${standardSpacing}px;
    }

    & .bs4-btn:first-of-type {
      margin-right: ${halfSpacing}px;
    }
  `;


  const handleDeleteClick = () => setShowDeleteLogoConfirm(true);

  const handleLogoDeleteCancel = () => setShowDeleteLogoConfirm(false);

  const handleLogoDeleteConfirm = () => {
    handleChange({
      logo: null,
    });

    setShowDeleteLogoConfirm(false);
  };

  const handleLogoSelectError = () => {
    brandingHeaderRef.current.onLogoSelectError();
  };

  const handleFilePickerChange = (files) => {
    const [file] = files;

    readFileAsDataURL(file).then((src) => {
      handleChange({
        logo: {
          file,
          url: src,
          size: (value.logo?.size || currentInstitution.logoSize || InstitutionLogoSize.SMALL) as InstitutionLogoSize,
        },
      });
    });
  };

  const onLogoSizeChanged = (newSize) => handleChange({
    logo: {
      ...value.logo,
      size: newSize,
    },
  });

  const dropdownOptions: NvDropdownOption[] = [
    {
      type: 'custom',
      customItem: (
        <NvFilePicker
          multiple={false}
          accept={['image/*']}
          maxSize={MAX_FILE_SIZE}
          className='logo-item-action'
          onChange={handleFilePickerChange}
          onSelectError={handleLogoSelectError}
        >
          {updateLogoText}
        </NvFilePicker>
      ),
    },
  ];

  if (hasOwnLogo) {
    dropdownOptions.push(
      { type: 'divider' },
      {
        type: 'header',
        title: t.INSTITUTIONS.FORM.LOGO_MENU.SIZE.HEADER(),
        class: 'font-weight-bold mt-2 ml-3',
      },
      {
        type: 'text',
        text: t.INSTITUTIONS.FORM.LOGO_MENU.SIZE.SMALL(),
        id: InstitutionLogoSize.SMALL,
        callback: () => onLogoSizeChanged(InstitutionLogoSize.SMALL),
      },
      {
        type: 'text',
        text: t.INSTITUTIONS.FORM.LOGO_MENU.SIZE.MEDIUM(),
        id: InstitutionLogoSize.MEDIUM,
        callback: () => onLogoSizeChanged(InstitutionLogoSize.MEDIUM),
      },
      {
        type: 'text',
        text: t.INSTITUTIONS.FORM.LOGO_MENU.SIZE.LARGE(),
        id: InstitutionLogoSize.LARGE,
        callback: () => onLogoSizeChanged(InstitutionLogoSize.LARGE),
      },
      { type: 'divider' },
      {
        /* Using Dropdown.Item in custom type to hide the dropdown on click
        Delete logo option will show as selected on click, while using the item
        type as text. Which is due to the showSelectedIndicator */
        type: 'custom',
        customItem: (
          <Dropdown.Item
            onClick={handleDeleteClick}
            as={(itemProps) => (
              <div
                {...itemProps}
                className={`logo-item-action${hasCurrentInstitutionLogo ? '' : ' text-danger'}`}
              >
                {hasCurrentInstitutionLogo
                  ? t.INSTITUTIONS.BRANDING.HEADER.REPLACE_LOGO()
                  : t.INSTITUTIONS.BRANDING.HEADER.DELETE_LOGO()}
              </div>
            )}
          />
        ),
      },
    );
  }

  const definitiveControllerValue = {
    ...value,
    brandColor: value.brandColor || currentInstitution.brandColor || gray3,
    fontColor: value.fontColor || currentInstitution.brandBarFontColor || '#000',
    logo: value.logo || (hasCurrentInstitutionLogo ? {
      url: currentInstitution.logo,
      size: (currentInstitution.logoSize as InstitutionLogoSize) || InstitutionLogoSize.SMALL,
    } : null),
  };

  return (
    <BrandingHeader
      {...restProps}
      css={styles}
      editable={editable}
      onChange={onChange}
      value={definitiveControllerValue}
      logoDropdownOptions={dropdownOptions}
      ref={mergeRefs(ref, brandingHeaderRef)}
      renderAfterLogo={showParentProgram ? (() => {
        const parentProgram = currentOffering?.parentPrograms?.[0];

        return !!parentProgram && (
          <div className='text-small ellipsis'>{parentProgram.name}</div>
        );
      }) : undefined}
      renderLogoContainer={() => showDeleteLogoConfirm && (
        <div css={deleteOverlayStyles}>
          <span>{t.FILE_UPLOAD.CONFIRM_DELETE_SHORT()}</span>
          <Button
            variant='secondary'
            className='bs4-night'
            onClick={handleLogoDeleteCancel}
          >
            {t.NOVOED.CANCEL()}
          </Button>
          <Button
            variant='danger'
            className='bs4-night'
            onClick={handleLogoDeleteConfirm}
          >
            {t.FORM.DELETE()}
          </Button>
        </div>
      )}
    />
  );
});

type FormOfferingsBrandingHeaderProps = Omit<Props, 'value' | 'onChange'> & {
  name: string,
};

export const FormOfferingsBrandingHeader = (props: FormOfferingsBrandingHeaderProps) => {
  const {
    name,
    ...restProps
  } = props;

  return (
    <Controller
      name={name}
      render={({ field: { value, onChange } }) => (
        <OfferingBrandingHeader
          {...restProps}
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
};

export default OfferingBrandingHeader;
