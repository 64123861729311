import React from 'react';
import t from 'react-translate';

// Angular
import { AngularContext, AngularServicesContext } from 'react-app';

// Form
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

// Redux
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { getLoginProviders } from 'redux/actions/sign-in';
import { LoginProvider, LoginProviderData } from 'redux/schemas/models/sign-in';
import { Course } from 'redux/schemas/models/course';

// Components
import { Button } from 'react-bootstrap';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import OrDivider from './nv-or-divider';
import NvHeaderText from './nv-header-text';

type Props = {
  alreadyJoined: boolean,
  course: Course,
  onSuccess: (data: LoginProviderData) => void,
  onError?: () => void,
};

enum NextButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

const SSOSignIn = (props: Props) => {
  const { alreadyJoined, course, onSuccess, onError } = props;
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = React.useState(false);

  const { InstitutionsManager } = React.useContext(AngularServicesContext) || {};
  const { injectServices } = React.useContext(AngularContext);

  const [UserAuthentication] = injectServices(['UserAuthentication']);
  const { institution } = InstitutionsManager;
  const { identityProviders = [] } = institution;

  const ssoUrl = identityProviders.length
    ? UserAuthentication.getSSOSignInUrl(identityProviders[0].name)
    : '';

  const validationSchemaRef = React.useRef(yup.object().shape({
    email: yup.string()
      .min(1, t.VALIDATION.REQUIRED())
      .email(t.VALIDATION.EMAIL()),
  }));

  const methods = useForm({
    mode: 'onChange',
    shouldUnregister: true,
    resolver: yupResolver(validationSchemaRef.current),
  });

  const { handleSubmit, formState } = methods;

  const onSubmit = ({ email }) => {
    setIsLoading(true);
    wrapThunkAction(dispatch(getLoginProviders({ email })))
      .then((action: any) => {
        const { payload }: { payload: LoginProvider } = action;
        onSuccess({ ...payload, email });
      }).catch(() => {
        onError?.();
      }).finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className='authentications-overlay'>
        <div className='authentications-body'>
          <NvHeaderText alreadyJoined={alreadyJoined} course={course} />
          <div className='registration-form'>
            <SSOButton url={ssoUrl} />
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <NvTextInput
                  required
                  withForm
                  withLabel
                  name='email'
                  className='mb-5 mt-5'
                  placeholder={t.USERS.REGISTRATION.EMAIL_ADDRESS()}
                  data-qa='email'
                />
                <Button
                  variant={ssoUrl ? NextButtonVariant.SECONDARY : NextButtonVariant.PRIMARY}
                  className='mb-5'
                  disabled={!formState.isValid || isLoading}
                  type='submit'
                  data-qa='next'
                >
                  {t.SHARED.NEXT()}
                </Button>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const SSOButton = ({ url }) => (
  <>
    {
      url && (
        <>
          <a className='mb-5 btn btn-primary w-100' href={url} data-qa='sso-login'>
            {t.SHARED.USE_SINGLE_SIGN_ON()}
          </a>
          <OrDivider />
        </>
      )
    }
  </>
);

export default SSOSignIn;
