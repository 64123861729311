/* @ngInject */
export default function EmbeddingsResource(
  $resource,
) {
  return $resource(
    '/embeddings/:id',
    {},
    {
      getBoxAccessToken: { method: 'GET', url: '/embeddings/box_access_token/:boxDocumentId', cancellable: true },
    },
  );
}
