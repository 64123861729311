import moment, { Moment } from 'moment';
import _ from 'underscore';
import { RootState } from 'redux/schemas';

export const getPeriodStartDate = (state: RootState) => moment(state.models.license.currentPeriodStartedAt);
export const getPeriodEndDate = (state: RootState) => moment(state.models.license.currentPeriodEndedAt);

/** Whether or not the current institution has no contract period defined, even after it's
 * loaded. */
export const noContractPeriodDefined = (state: RootState): boolean => state.models.license.loaded && !state.models.license.currentPeriodStartedAt;

export const getLicenseInformation = (state: RootState): LicenseInformation => {
  if (state.app.currentInstitutionId) {
    const { noSeats, currentPeriodEndedAt, currentPeriodStartedAt, unenrollGracePeriod, notes } = state.models.institutions[state.app.currentInstitutionId];
    return {
      noSeats,
      currentPeriodEndedAt: moment(currentPeriodEndedAt),
      currentPeriodStartedAt: moment(currentPeriodStartedAt),
      unenrollGracePeriod,
      notes,
    };
  }
  return null;
};

// TODO: This should be in a different file
export const getCurrentOrgCreationDate = (state: RootState): moment.Moment => {
  if (state.app.currentInstitutionId) {
    return moment(state.models.institutions[state.app.currentInstitutionId].createdAt);
  }

  return null;
};

const findCourseById = (
  courses,
  id,
) => courses.find((course) => course.id === +id);

export const getCourseNamesToRankingData = (state: RootState): CourseRankingData => {
  if (!state.models.courseRankings.top10Courses
    || !state.app.licenseDashboard.allCoursesLoaded) {
    return null;
  }

  // Special case for when a given top 10 course doesn't exist in the course model
  // TODO: Re-request the courses for the institution on each LD page reload as a band-aid for us not being able
  // to update the model whenever a clone happens
  if (_.any(Object.keys(state.models.courseRankings.top10Courses).map(courseId => !findCourseById(Object.values(state.models.courses), courseId)))) {
    return null;
  }

  return Object.keys(state.models.courseRankings.top10Courses).map(courseId => {
    const course = findCourseById(Object.values(state.models.courses), courseId);

    return ({
      catalogId: course.catalogId,
      name: course.name,
      enrollmentCount: state.models.courseRankings.top10Courses[courseId].courseEnrollments,
      loginCount: state.models.courseRankings.top10Courses[courseId].courseLogins,
    });
  });
};

export type CourseRankingData = {
  name: string,
  catalogId: string,
  enrollmentCount: number,
  loginCount: number,
}[];

export type LicenseInformation = {
  currentPeriodStartedAt: Moment,
  currentPeriodEndedAt: Moment,
  noSeats: number,
  unenrollGracePeriod: number,
  notes: string,
  licenseModel?: LicenseType
};

export enum LicenseType {
  ACCESS = 'access',
  ACTIVE_MONTH = 'active_month',
  COURSE = 'by_course'
}
