import React, { useCallback, useContext } from 'react';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';
import { css } from '@emotion/react';

// redux
import { useSelector } from 'react-redux';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { unlinkCollectionLesson } from 'redux/actions/lecture-pages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { RootState } from 'redux/schemas';
import { CourseAliases } from 'redux/schemas/models/course';
import { getCourseAliases, getCurrentCourse } from 'redux/selectors/course';
import { useAppDispatch } from 'redux/store';
import { addAlertMessage } from 'redux/actions/alert-messages';

import NvDropdown, { NvDropdownOption, NvDropdownButtonStyle } from 'shared/components/inputs/nv-dropdown';
import { useLecturePageFromParams, useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import ClickableContainer from 'components/clickable-container';
import { isEmpty } from 'underscore';
import { primary } from 'styles/global_defaults/colors';
import { config } from '../../../../config/config.json';

const styles = css`
  .bs4-dropdown {
    .bs4-dropdown-menu {
      width: 300px;

      .collection-links {
        color: ${primary};

        .collection-home-link:hover {
          color: #3094BF;
        }
      }
    }
  }
`;

export const LectureContentLinkedDropdown = () => {
  const lecturePage = useLecturePageFromParams();
  const params = useLecturePageParams();
  const currentCourse = useSelector(getCurrentCourse);
  const aliases: CourseAliases = useSelector((state: RootState) => getCourseAliases(state));

  const { $state, CurrentPermissionsManager } = useContext(AngularServicesContext);
  const dispatch = useAppDispatch();

  const collection = lecturePage.contentManagementCollection;
  const collectionLecturePage = lecturePage.contentManagementLecturePage;

  const isCollectionViewer = collection.userCourse && CurrentPermissionsManager.hasCollectionViewerPermissions(collection.userCourse);
  const isCollectionBuilder = collection.userCourse && CurrentPermissionsManager.hasCollectionBuilderPermissions(collection.userCourse);
  const isCourseBuilder = CurrentPermissionsManager.isCourseBuilder();

  const gotoCollectionHome = (extraParams) => {
    const stateParams = {
      collectionId: lecturePage.contentManagementCollection.id,
      catalogId: lecturePage.contentManagementCollection.catalogId,
      ...extraParams,
    };

    window.open($state?.href(
      'content-library-collection-home',
      stateParams,
    ), '_blank');
  };

  const onclickFolderName = () => {
    gotoCollectionHome({
      folderId: collectionLecturePage.folderId,
      lessonId: collectionLecturePage.id,
    });
  };

  const unlinkLessonFromCollection = useCallback(() => {
    dispatch(openConfirmationDialog({
      title: t.TIMELINE.UNLINK.ARE_YOU_SURE(aliases.lectureAliases),
      bodyText: t.TIMELINE.UNLINK.DESCRIPTION(aliases.lectureAliases),
      footerText: t.TIMELINE.UNLINK.FOOTER_NOTE(),
      confirmText: t.FORM.YES_SURE(),
      onConfirm: () => {
        dispatch(unlinkCollectionLesson({
          courseId: currentCourse.id,
          lecturePages: [{
            id: params.lecturePageId,
          }],
        })).then((response) => {
          if (isEmpty(response?.error)) {
            dispatch(addAlertMessage({
              type: AlertMessageType.SUCCESS,
              header: t.FORM.SUCCESS_BANG(),
              message: t.TIMELINE.UNLINK.SUCCESS_ALERT(lecturePage.title),
            }));
          }
        });
      },
    }));
  }, [aliases.lectureAliases, currentCourse.id, dispatch, lecturePage.title, params.lecturePageId]);

  const redirectToCollectionLesson = () => {
    window.open($state?.href(
      isCollectionBuilder ? 'lecture-page-edit' : 'lecture-page',
      {
        catalogId: collection.catalogId,
        id: collectionLecturePage.id,
        fromLinkedCourse: true,
      },
    ), '_blank');
  };

  const linkedDropdownItems: NvDropdownOption[] = [
    {
      type: 'header',
      title: t.LECTURE_PAGES.ADMIN.LINKED_OPTIONS.LESSON_UNDER(aliases.lectureAliases),
      class: 'mt-2 label',
    },
    {
      type: 'custom',
      disabled: true,
      customItem: (
        <div className={`px-4 my-1 ${isCollectionViewer ? 'collection-links' : 'text-black'}`}>
          <ClickableContainer
            className='d-inline collection-home-link'
            onClick={gotoCollectionHome}
            disabled={!isCollectionViewer}
            isFocusable={isCollectionViewer}
            data-qa={config.pendo.contentManagement.backToCollectionHome}
            data-qa-id={`${config.pendo.contentManagement.backToCollectionHome}-${lecturePage.contentManagementCollection.catalogId}`}
          >
            {collection.title}
          </ClickableContainer>
          <span className='d-inline text-black mx-1'>&gt;</span>
          <ClickableContainer
            className='d-inline collection-home-link'
            onClick={onclickFolderName}
            disabled={!isCollectionViewer}
            isFocusable={isCollectionViewer}
            data-qa={config.pendo.contentManagement.expandFolder}
            data-qa-id={`${config.pendo.contentManagement.expandFolder}_${collectionLecturePage?.folderId}`}
          >
            {collectionLecturePage.folderTitle}
          </ClickableContainer>
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      type: 'text',
      iconClass: (isCollectionViewer && !isCollectionBuilder) ? 'view' : 'edit',
      text: t.LECTURE_PAGES.ADMIN.LINKED_OPTIONS.EDIT_IN_COLLECTION({
        showView: isCollectionViewer && !isCollectionBuilder,
        lectureAlias: aliases.lectureAliases.lectureAlias,
      }),
      callback: redirectToCollectionLesson,
      disabled: isEmpty(collection.userCourse),
      tooltip: {
        text: t.LECTURE_PAGES.ADMIN.LINKED_OPTIONS.NO_PERMISSION_TO_EDIT_IN_COLLECTION(),
        enabled: isEmpty(collection.userCourse),
        placement: 'left',
      },
      dataQa: config.pendo.contentManagement.editLessonInCollectionOption,
    },
    {
      type: 'text',
      iconClass: 'format-unlink',
      text: t.TIMELINE.LESSON_OPTIONS.UNLINK_FROM_COLLECTION(),
      callback: unlinkLessonFromCollection,
      disabled: !isCourseBuilder,
      tooltip: {
        text: t.LECTURE_PAGES.ADMIN.LINKED_OPTIONS.NO_PERMISSION_TO_UNLINK(),
        enabled: !isCourseBuilder,
        placement: 'left',
      },
      dataQa: config.pendo.contentManagement.unlinkFromCollection,
    },
  ];

  return (
    <div css={styles}>
      <NvDropdown
        buttonStyle={NvDropdownButtonStyle.ICON}
        iconClass='icon-smallest icon-format-makelink ml-2 mr-4'
        items={linkedDropdownItems}
        offset={10}
        minWidth={240}
        toggleDataQa={config.pendo.contentManagement.linkedLessonActionMenu}
      />
    </div>
  );
};

export default LectureContentLinkedDropdown;
