/* @ngInject */
export default function AnnouncementsResources(

  $resource,
) {
  return $resource(
    '/:catalogId/announcements/:id.json',
    {},
    {
      query: { method: 'GET', isArray: false, cancellable: true },
      numUnreadAnnouncementsByCourse: { method: 'GET', url: '/courses/courses_to_unread_announcements_count.json', cancellable: true },
      update: { method: 'PUT' },
      getPreview: { method: 'POST', url: '/:catalogId/announcements/preview' },
    },
  );
}
