/* eslint-disable prefer-const */
/* eslint-disable react/require-default-props */
import { Omit } from 'utility-types';
import { css } from '@emotion/react';
import { useDropzone, FileRejection } from 'react-dropzone';
import { mergeRefs } from 'shared/react-utils';
import { useFormContext } from 'react-hook-form';

import { useEffect, useCallback, forwardRef } from 'react';

type ReactDivProps = React.ComponentProps<'div'>;

export type NvFilePickerProps = {
  children?: any,
  // A list of unique file type specifiers of files accepted by this dialog per https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
  accept: string[],
  onChange: (files: File[]) => void,
  multiple?: boolean,
  noDrag?: boolean,
  maxSize?: number,
  onSelectError?: (files: FileRejection[]) => void,
  /** Fires when the file pick dialog is canceled and no selection is made */
  onCancel?: () => void,
  withForm?: boolean;
  name?: string;
  disabled?: boolean,
  onDrop?: (event) => void,
} & Omit<ReactDivProps, 'onChange'>;

const noop = () => {};

/** A component used to wrap an element so that clicking the element will cause a file select dialog to appear. */
export const NvFilePicker = forwardRef<HTMLDivElement, NvFilePickerProps>(({
  children = '',
  accept,
  onChange,
  multiple = true,
  noDrag = true,
  maxSize,
  onSelectError,
  onCancel = () => {},
  name,
  withForm,
  disabled = false,
  onBlur = () => {},
  onDrop = () => {},
  onClick = () => {},
  ...restProps
}, ref) => {
  const onFileSelected = useCallback((acceptedFiles: File[]) => {
    onChange(acceptedFiles);
  }, [onChange]);

  const { getRootProps: dropzoneProps, getInputProps: getDropzoneInputProps } = useDropzone({
    accept,
    multiple,
    noDrag,
    maxSize,
    disabled,
    onDropRejected: onSelectError,
    onDropAccepted: onFileSelected,
  });

  const styles = css`
    cursor: pointer;
    flex: 1;
  `;

  let { ref: dropzoneRef, ...restDropzoneProps } = dropzoneProps();

  let {
    onDrop: originalOnDrop,
    onBlur: originalOnBlur,
    onClick: originalOnClick,
    ...withoutOnDrop
  } = restDropzoneProps;
  restDropzoneProps = withoutOnDrop;

  /**
   * react-dropzone library provides null instead of the functions if it's
   * disabled, using noop to ensure existence since we are intercepting them.
   */
  originalOnDrop = originalOnDrop || noop;
  originalOnBlur = originalOnBlur || noop;
  originalOnClick = originalOnClick || noop;

  const handleDrop = (event) => {
    onDrop(event);

    if (!event.defaultPrevented) {
      originalOnDrop(event);
    }
  };

  const handleClick = (event) => {
    onClick(event);
    originalOnClick(event);
  };

  const handleBlur = (event) => {
    onBlur(event);
    originalOnBlur(event);
  };

  return (
    <div
      className='nv-file-picker'
      css={styles}
      onDrop={handleDrop}
      onBlur={handleBlur}
      onClick={handleClick}
      ref={mergeRefs(ref, dropzoneRef)}
      {...restProps as ReactDivProps}
      {...restDropzoneProps}
    >
      <input {...getDropzoneInputProps()} />
      {children}
    </div>
  );
});

export default NvFilePicker;
