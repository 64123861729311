/* @ngInject */
export default function InstitutionAdminsResource(

  $resource,
) {
  const path = '/institutions/:institutionId/institution_admins/:id';
  return $resource(
    path,
    { institutionId: '@institutionId', id: '@id' },
    {
      query: { method: 'GET', isArray: false, url: path },
      getUserExportDetails: { method: 'POST', url: '/institutions/:institutionId/user_enrollment.json' },
      exportData: { method: 'POST', url: '/institutions/:institutionId/export_data.json' },
      updateAdminRole: { method: 'PATCH', url: '/institutions/:institutionId/institution_admins/:institutionAdminId.json' },
    },
  );
}
