import React, { useContext } from 'react';
import { css } from '@emotion/react';
import { AngularServicesContext } from 'react-app';
import { useSelector } from 'react-redux';

import {
  halfSpacing,
  tripleSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import { danger, gray2, gray3, gray6, primary, success } from 'styles/global_defaults/colors';
import { openSansCondensed, textMediumFontSize, textSmallFontSize } from 'styles/global_defaults/fonts';
import { ActivityTodoWithDeadline, ActivityType } from 'redux/schemas/models/activity';
import { getCourse } from 'redux/selectors/course';
import NvIcon from 'shared/components/nv-icon';

import QuizTodo from './quiz-todo';
import PollTodo from './poll-todo';
import SurveyTodo from './survey-todo';
import ExerciseTodo from './exercise-todo';
import TimedQuizTodo from './timed-quiz-todo';
import VideoPracticeTodo from './video-practice-todo';
import PeerReviewTodo from './peer-review-todo';
import TeamSetTodo from './team-set-todo';

const styles = css`
  .todo-container {
    display: flex;
    padding: ${halfSpacing}px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed ${gray6};
    height: ${tripleSpacing}px;

    .todo-description {
      display: flex;
      align-items: center;
      gap: ${standardSpacing}px;

      .todo-cover {
        img,
        .square-thumbnail {
          width: ${standardSpacing}px;
          height: ${standardSpacing}px;
        }
      }

      .todo-icon {
        display: flex;
        justify-content: center;
        min-width: 36px;
        i {
          color: ${gray3};

          &.icon-check {
            color: ${success};
          }
        }
      }

      .todo-title {
        font-size: ${textSmallFontSize}px;
      }
    }

    .todo-content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-size: ${textSmallFontSize}px;
      font-family: ${openSansCondensed};

      .status {
        color: ${gray2};
      }

      .date {
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        .day {
          color: ${gray2};
          font-size: ${textMediumFontSize}px;
        }
      }
    }

    &.in-progress {
      .todo-content {
        .status {
          color: ${primary};
        }
      }
    }

    &.due-soon {
      .todo-content {
        .date {
          .time {
            color: ${danger};
          }
        }
      }
    }

    &.missed {
      .todo-content {
        .status {
          color: ${gray3};
        }
      }
    }

    &.completed {
      .todo-content {
        .status,
        .date {
          .time {
            color: ${success};
          }
        }
      }
    }
  }
`;

type TodoRowProps = {
  todo: ActivityTodoWithDeadline;
};

const TodoRow = React.forwardRef<HTMLDivElement, TodoRowProps>(({ todo }, ref) => {
  const { $state } = useContext(AngularServicesContext);
  const { catalogId, courseCatalogId, lecturePageId, lectureComponentId } = todo;

  const currentCourseCatalogId = courseCatalogId || catalogId;
  const course = useSelector(state => getCourse(state, currentCourseCatalogId));
  const { squareThumbnail, headerColor } = course;

  let todoContent = null;

  const thumbnailSrc = squareThumbnail === '/assets/brand/temp.png' ? '' : squareThumbnail;
  const todoProps: any = {
    ...todo,
    thumbnailSrc,
    backgroundColor: headerColor,
  };

  switch (todo.type) {
    case 'exercise':
      todoContent = <ExerciseTodo {...todoProps} />;
      break;
    case 'customquestions':
      todoContent = <PeerReviewTodo {...todoProps} />;
      break;
    case 'teamset':
      todoContent = <TeamSetTodo {...todoProps} />;
      break;
    case ActivityType.SURVEY:
      // Todo
      todoContent = <SurveyTodo {...todoProps} />;
      break;
    case ActivityType.TIMED_EXAM:
      todoContent = <TimedQuizTodo {...todoProps} />;
      break;
    case ActivityType.QUIZ:
    case ActivityType.PROGRESSIVE_QUIZ:
      todoContent = <QuizTodo {...todoProps} />;
      break;
    case ActivityType.POLL:
      todoContent = <PollTodo {...todoProps} />;
      break;
    case ActivityType.VIDEO_PRACTICE:
      todoContent = <VideoPracticeTodo {...todoProps} />;
      break;
    default:
      break;
  }

  return (
    <div css={styles}>
      <a href={$state.href('lecture-page', { catalogId: currentCourseCatalogId, id: lecturePageId, lectureActivityId: lectureComponentId })}>
        {todoContent}
      </a>
    </div>
  );
});

export const todoIcon = {
  exercise: 'assignments',
  publiccustomquestions: 'comments',
  customquestions: 'evaluation',
  Quiz: 'quiz',
  ProgressiveQuiz: 'quiz',
  Poll: 'polls',
  Survey: 'survey',
  TimedExam: 'timedexam',
  VideoPracticeActivity: 'media-practice',
  teamset: 'team',
};

export const todoProgress = {
  NOT_STARTED: 'not_started',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  MISSED: 'missed',
  APPROVAL_REQUIRED: 'approval_needed',
  PENDING_APPROVAL: 'pending',
  REJECTED: 'rejected',
  REJECTED_AND_MISSED: 'rejected_and_missed',
  APPROVED: 'approved',
};

export const CheckIcon = () => (
  <NvIcon icon='check' size='smallest' />
);

export default TodoRow;
