import store from 'redux/store';
import { deleteLectureSection } from 'redux/actions/timeline';

/* @ngInject */
export default function TimelineModelService(

  _,
  $q,
  TimelinesResources,
  TimelineLectureSectionModel,
  TimelineLectureSubsectionModel,
  TimelineLecturePageModel,
  moment,
) {
  const TimelineModel = function (attributes, userId, isAdmin, course, { hasLectureComponents = true } = {}) {
    // This can have section and/or subsection as children
    const _this = this;
    const base = {
      lectureSections: null,
      allTodoItems: null,
      userId,
      isAdmin,
      hasLectureComponents,
    };

    _.extend(_this, base, attributes);
    _this.course = course;

    preprocess(); // convert objects to proper javascript objects
    todosViewPreprocess(); // get all exercise objects
    pointViewPreprocess(); // get all point objects

    currentLecturePreprocess();

    /** Public Data * */

    /** Public Functions * */
    _.extend(_this, {
      resetCurrentLecturePage,
      setCurrentLecturePage,

      hasLectureSections,
      hasReleasedLecturePages,
      addLecturePage,
      removeLecturePage,
      removeLectureSection,
      addLectureSection,
      copyLectureSection,
      insertLecturePageAfter,

      updateLecturePage,
      updateLecturePageAttribute,
      markUnread,
      removeComponent,
      removeLectureSectionTitle,
      updateComponentPointsAndProgress,
      updateComponentProgress,
      updateLecturePagesWarnings,
      updateDependentActivitiesReleaseDate,

      preprocessViewData,
      setPrerequisitesData,
      cancelTimeouts,
      setNewLecturePageCallback,
      isPrereqCompleted,

      // reorder functions
      createReorderDraft,
      isFirstSubsectionWithoutSection,
      isLastSubsection,
      moveSectionUp,
      moveSectionDown,
      moveSubsectionUp,
      moveSubsectionDown,
      saveOrder,
    });

    /** Function Declarations * */
    /* Used Publicly */
    function resetCurrentLecturePage() {
      _.each(getTopLevelSections(), (section) => {
        section.resetCurrent();
      });
    }

    function setCurrentLecturePage(currentLecturePageId) {
      resetCurrentLecturePage();
      _this.currentLectureId = currentLecturePageId;

      currentLecturePreprocess();
    }


    function hasLectureSections() {
      return !_.isEmpty(getTopLevelSections());
    }

    function hasReleasedLecturePages() {
      return !_.isEmpty(_this.lecturePages) && _.some(_this.lecturePages, (item) => item.isReleased);
    }

    function removeLecturePage(lecturePageId) {
      const removed = _.some(getTopLevelSections(), (section) => section.removeLecturePage(lecturePageId));

      if (removed) {
        preprocessViewData();
      }
    }

    // Clean Every Lecture Page in the section or subsection when deleted
    function cleanLecturePages(sectionDeleted) {
      const sectionLecturePages = sectionDeleted.getAllLecturePages();
      sectionLecturePages.forEach(element => {
        if (element.type === 'LecturePage') {
          element.cleanLecturePage();
        }
      });
    }

    // Remove section or subsection
    function removeLectureSection(lectureSectionId, parent, type) {
      let index;

      if (parent) {
        const subsectionDeleted = parent.removeLectureSubsection(lectureSectionId);
        cleanLecturePages(subsectionDeleted[0]);
      } else if (type === 'LectureSection') {
        index = _.findIndex(_this.lectureSections, { id: lectureSectionId });
        const sectionDeleted = _this.lectureSections.splice(index, 1);
        cleanLecturePages(sectionDeleted[0]);
        _.each(_this.lectureSections, (lectureSection, lsIndex) => { lectureSection.index = lsIndex; });
      } else {
        index = _.findIndex(_this.lectureSubsections, { id: lectureSectionId });
        const subsectionDeleted = _this.lectureSubsections.splice(index, 1);
        cleanLecturePages(subsectionDeleted[0]);
        _.each(_this.lectureSubsections, (lectureSubsection, lssIndex) => { lectureSubsection.index = lssIndex; });
      }
    }

    // Adding from global level and from plus icons
    function addLectureSection(parentLectureSection, type, result) {
      let index; let
        newLectureComponent;

      if (type === 'LectureSection') {
        // remove subsections without parent
        if (result.index === -1) {
          result.index = 0;
          _this.lectureSubsections = [];
        }

        newLectureComponent = new TimelineLectureSectionModel(result, _this, _this.course, isAdmin);

        _this.lectureSections.splice(newLectureComponent.index, 0, newLectureComponent); // add to bottom

        _.each(_this.lectureSections, (lectureSection, lsIndex) => { lectureSection.index = lsIndex; });
      } else if (type === 'LectureSubsection') {
        // Remove lecture pages from previous parent section
        if (result.index === -1 && parentLectureSection) {
          result.index = 0;
          parentLectureSection.lecturePages = [];
        }

        newLectureComponent = new TimelineLectureSubsectionModel(result, null, _this, _this.course, isAdmin);

        if (parentLectureSection) {
          parentLectureSection.lectureSubsections.splice(newLectureComponent.index, 0, newLectureComponent); // last position within parent
          newLectureComponent.lectureSection = parentLectureSection;
        } else {
          _this.lectureSubsections.splice(newLectureComponent.index, 0, newLectureComponent);
        }
      }
    }

    function addLecturePage(parentLectureSection, lecturePage) {
      parentLectureSection.addLecturePage(lecturePage);
      preprocessViewData();
    }

    function insertLecturePageAfter(lecturePageId, result, catalogId) {
      let index;
      const newLectureComponent = new TimelineLecturePageModel(result, null, _this.course, isAdmin); // lectureSection is set in insertLecturePageAfter
      _.some(_this.lectureSections, (lectureSection) => lectureSection.insertLecturePageAfter(lecturePageId, newLectureComponent));
      _.some(_this.lectureSubsections, (lectureSubsection) => lectureSubsection.insertLecturePageAfter(lecturePageId, newLectureComponent));

      preprocessViewData();
    }

    // Copy section or subsection
    function copyLectureSection(result, sectionType, sourceSectionId, parent) {
      let index; let
        newLectureComponent;

      if (sectionType === 'LectureSection') {
        newLectureComponent = new TimelineLectureSectionModel(result, _this, _this.course, isAdmin);

        index = _.findIndex(_this.lectureSections, { id: sourceSectionId });
        _this.lectureSections.splice(index + 1, 0, newLectureComponent);

        _.each(_this.lectureSections, (lectureSection, lsIndex) => { lectureSection.index = lsIndex; });
      } else if (sectionType === 'LectureSubsection') {
        newLectureComponent = new TimelineLectureSubsectionModel(result, null, _this, _this.course, isAdmin);

        index = _.findIndex(parent.lectureSubsections, { id: sourceSectionId });
        parent.lectureSubsections.splice(index + 1, 0, newLectureComponent);

        _.each(parent.lectureSubsections, (lectureSection, lsIndex) => { lectureSection.index = lsIndex; });
      }
    }


    // Remove section title for section, and then subsection - ie move children to prev element and then delete curr section
    function removeLectureSectionTitle(lectureSectionId, parent, type) {
      let index;
      if (type === 'LectureSection') {
        index = _.findIndex(_this.lectureSections, { id: lectureSectionId });

        if (index === 0) {
          if (_this.lectureSubsections.length > 0) {
            _.last(_this.lectureSubsections).adoptChildren(_this.lectureSections[index]);
          }
          _this.lectureSubsections.push(..._this.lectureSections[index].lectureSubsections);
          _.each(_this.lectureSubsections, (lectureSubsection, lsIndex) => { lectureSubsection.index = lsIndex; });
        } else if (index >= 1) {
          _this.lectureSections[index - 1].adoptChildren(_this.lectureSections[index]);
        }
      } else if (type === 'LectureSubsection') {
        if (parent) {
          // Move to parent section's last subsections or after last lesson - similar to what we're doing above
          index = _.findIndex(parent.lectureSubsections, { id: lectureSectionId });
          if (index > 0) {
            parent.lectureSubsections[index - 1].adoptChildren(parent.lectureSubsections[index]);
          } else {
            parent.adoptChildren(parent.lectureSubsections[index], true);
          }
        } else {
          // Subsections without lecture section
          index = _.findIndex(_this.lectureSubsections, { id: lectureSectionId });
          _this.lectureSubsections[index - 1].adoptChildren(_this.lectureSubsections[index]);
        }
      }

      removeLectureSection(lectureSectionId, parent, type);
    }

    function updateLecturePage(lecturePageId) {
      return _.map(getTopLevelSections(), (section) => section.updateLecturePage(lecturePageId));
    }

    function updateLecturePageAttribute(lecturePageId, attribute, value) {
      _.each(getTopLevelSections(), (section) => {
        section.updateLecturePageAttribute(lecturePageId, attribute, value);
      });
    }

    function markUnread(lecturePageId) {
      _.each(getTopLevelSections(), (section) => {
        section.markUnread(lecturePageId);
      });
    }

    function removeComponent(lecturePageId, componentType, componentId) {
      _.each(getTopLevelSections(), (section) => {
        section.removeComponent(lecturePageId, componentType, componentId);
      });

      setPrerequisitesData();
      todosViewPreprocess();
      pointViewPreprocess();
    }

    function updateComponentPointsAndProgress(lecturePageId, componentType, componentId, pointsReceived, totalPoints, progress) {
      _.each(getTopLevelSections(), (section) => {
        section.updateComponentPointsAndProgress(lecturePageId, componentType, componentId, pointsReceived, totalPoints, progress);
      });

      todosViewPreprocess();
      pointViewPreprocess();
    }

    function updateComponentProgress(lecturePageId, componentType, componentId, progress) {
      _.each(getTopLevelSections(), (section) => {
        section.updateComponentProgress(lecturePageId, componentType, componentId, progress);
      });

      todosViewPreprocess();
      pointViewPreprocess();
    }

    function updateLecturePagesWarnings(affectedLecturePages) {
      _.each(affectedLecturePages, (affectedLecturePage) => {
        const exisitingLp = _.findWhere(_this.lecturePages, { id: affectedLecturePage.id });

        if (exisitingLp) {
          exisitingLp.updateLecturePageAttribute(exisitingLp.id, 'hasTimelineIssues', affectedLecturePage.hasTimelineIssues);
        }
      });
    }

    function updateDependentActivitiesReleaseDate(changedlecturePage) {
      _.each(_this.lecturePages, (lecturePage) => {
        lecturePage.updateDependentActivitiesReleaseDate(changedlecturePage);
      });
    }

    function cancelTimeouts() {
      _.each(getTopLevelSections(), (section) => {
        section.cancelTimeouts();
      });
    }

    function setNewLecturePageCallback(func) {
      _this.newDisplayableDataCallback = func;
    }

    function isPrereqCompleted(prereq) {
      return _.some(getTopLevelSections(), (section) => section.isPrereqCompleted(prereq));
    }

    /* Reorder Start */
    function createReorderDraft() {
      this.reorderDirty = false;
      _this.lectureSubsectionsReorderDraft = _this.lectureSubsections ? [..._this.lectureSubsections] : [];
      _.each(_this.lectureSubsectionsReorderDraft, (lectureSubsection) => lectureSubsection.createReorderDraft());

      _this.lectureSectionsReorderDraft = _this.lectureSections ? [..._this.lectureSections] : [];
      _.each(_this.lectureSectionsReorderDraft, (lectureSection) => lectureSection.createReorderDraft());

      _.each(this.lectureSubsectionsReorderDraft, (subsection) => { subsection.moveClass = null; });
      _.each(this.lectureSectionsReorderDraft, (section) => {
        _.each(section.lectureSubsectionsReorderDraft, (subsection) => { subsection.moveClass = null; });
      });
    }

    function isFirstSubsectionWithoutSection(subsection) {
      if (this.lectureSubsectionsReorderDraft.length) {
        return this.lectureSubsectionsReorderDraft[0] === subsection;
      }

      return false;
    }

    function isLastSubsection(subsection) {
      if (this.lectureSectionsReorderDraft.length) {
        return _.last(this.lectureSectionsReorderDraft).lectureSubsectionsReorderDraft.length
            && _.last(_.last(this.lectureSectionsReorderDraft).lectureSubsectionsReorderDraft) === subsection;
      } if (this.lectureSubsectionsReorderDraft.length) {
        return _.last(this.lectureSubsectionsReorderDraft) === subsection;
      }

      return false;
    }

    function moveSectionUp(index) {
      this.reorderDirty = true;
      if (index > 0) {
        this.lectureSectionsReorderDraft.splice(index - 1, 0, this.lectureSectionsReorderDraft.splice(index, 1)[0]);
      } else {
        const section = this.lectureSectionsReorderDraft[index];
        section.lectureSubsectionsReorderDraft.push(...this.lectureSubsectionsReorderDraft);
        this.lectureSubsectionsReorderDraft = [];
      }
    }

    function moveSectionDown(index) {
      this.reorderDirty = true;
      this.lectureSectionsReorderDraft.splice(index + 1, 0, this.lectureSectionsReorderDraft.splice(index, 1)[0]);
    }

    function moveSubsectionUp(parent, index) {
      this.reorderDirty = true;

      const subsection = parent.lectureSubsectionsReorderDraft[index];
      subsection.moveClass = 'move-up';

      if (index === 0) {
        if (parent === this) {
          return false; // should never hit this case
        }
        const parentIndex = _.findIndex(this.lectureSectionsReorderDraft, (ls) => ls === parent);

        if (parentIndex === 0) {
          this.lectureSubsectionsReorderDraft.push(parent.lectureSubsectionsReorderDraft.splice(index, 1)[0]);
        } else {
          const newParent = this.lectureSectionsReorderDraft[parentIndex - 1];

          newParent.lectureSubsectionsReorderDraft.push(parent.lectureSubsectionsReorderDraft.splice(index, 1)[0]);
        }
      } else {
        parent.lectureSubsectionsReorderDraft.splice(index - 1, 0, parent.lectureSubsectionsReorderDraft.splice(index, 1)[0]);
      }

      return true;
    }

    function moveSubsectionDown(parent, index) {
      this.reorderDirty = true;

      const subsection = parent.lectureSubsectionsReorderDraft[index];
      subsection.moveClass = 'move-down';

      if (index === parent.lectureSubsectionsReorderDraft.length - 1) {
        if (parent === this) {
          this.lectureSectionsReorderDraft[0].lectureSubsectionsReorderDraft.unshift(parent.lectureSubsectionsReorderDraft.splice(index, 1)[0]);
        } else {
          const parentIndex = _.findIndex(this.lectureSectionsReorderDraft, (ls) => ls === parent);

          const newParent = this.lectureSectionsReorderDraft[parentIndex + 1];

          newParent.lectureSubsectionsReorderDraft.unshift(parent.lectureSubsectionsReorderDraft.splice(index, 1)[0]);
        }
      } else {
        parent.lectureSubsectionsReorderDraft.splice(index + 1, 0, parent.lectureSubsectionsReorderDraft.splice(index, 1)[0]);
      }
    }

    function saveOrder() {
      const payload = {
        lectureSubsections: _.map(this.lectureSubsectionsReorderDraft, (subsection) => ({
          id: subsection.id,
          lecturePages: _.map(subsection.lecturePagesReorderDraft, (lecturePage) => _.pick(lecturePage, 'id')),
        })),
        lectureSections: _.map(this.lectureSectionsReorderDraft, (section) => ({
          id: section.id,
          lecturePages: _.map(section.lecturePagesReorderDraft, (lecturePage) => _.pick(lecturePage, 'id')),
          lectureSubsections: _.map(section.lectureSubsectionsReorderDraft, (subsection) => ({
            id: subsection.id,
            lecturePages: _.map(subsection.lecturePagesReorderDraft, (lecturePage) => _.pick(lecturePage, 'id')),
          })),
        })),
      };

      _this.lectureSubsections = _this.lectureSubsectionsReorderDraft;
      _.each(_this.lectureSubsections, (lectureSubsection, index) => {
        lectureSubsection.index = index;
        lectureSubsection.saveReorderDraft();
      });

      _this.lectureSections = _this.lectureSectionsReorderDraft;
      _.each(_this.lectureSections, (lectureSection, index) => {
        lectureSection.index = index + _this.lectureSubsections.length;
        lectureSection.saveReorderDraft();
      });

      updateSubsectionCompletionDetails();
      preprocessViewData();

      return TimelinesResources.reorder({ catalogId: this.course.catalogId }, payload).$promise
        .then((response) => response.result);
    }

    function updateSubsectionCompletionDetails() {
      _.each(getTopLevelSections(), (section) => section.updateSubsectionCompletionDetails());
    }
    /* Reorder Done */


    /* Used Privately */
    function getTopLevelSections() {
      return _this.lectureSubsections.concat(_this.lectureSections);
    }

    function preprocess() {
      _this.lectureSubsections = _.map(_this.lectureSubsections, (lectureSubsection) => new TimelineLectureSubsectionModel(lectureSubsection, null, _this, _this.course, isAdmin));

      _this.lectureSections = _.map(_this.lectureSections, (lectureSection) => new TimelineLectureSectionModel(lectureSection, _this, _this.course, isAdmin));

      preprocessViewData();

      if (_this.hasLectureComponents && _this.isAdmin) {
        preprocessPrerequisitesData();
      }
    }

    function setPrerequisitesData() {
      _.each(_this.lecturePages, (lp) => lp.resetPrereq());

      _.each(_this.__prereqData, (dependency) => {
        const lpWithPrereqIndex = _.findIndex(_this.lecturePages, { id: dependency.activityId }); // this is always a lecture page
        const lpWithPrereq = _this.lecturePages[lpWithPrereqIndex];

        const prereqLpIndex = _.findIndex(_this.lecturePages, { id: dependency.prerequisiteLecturePageId });
        const prereqLp = _this.lecturePages[prereqLpIndex];

        if (lpWithPrereq && prereqLp && lpWithPrereqIndex < prereqLpIndex) {
          lpWithPrereq.setWarningForLecture();
          if (dependency.prerequisiteType === 'LecturePage') {
            prereqLp.setPrereqWarningForSelf();
          } else {
            prereqLp.setWarningForItem(dependency.prerequisiteId);
          }
        }
      });
    }

    function preprocessPrerequisitesData() {
      _this.__prereqData = [];

      getPrerequisites().then((list) => {
        _this.__prereqData = list;

        setPrerequisitesData();
      });
    }

    function getPrerequisites() {
      return TimelinesResources.getPrerequisites({ catalogId: _this.course.catalogId }, {})
        .$promise.then((response) => response.result);
    }


    function preprocessViewData() {
      _this.lecturePages = _.flatten(_.map(getTopLevelSections(), (section) => section.getAllLecturePages()));

      _this.newDisplayableDataCallback?.();

      itemsViewPreprocess();
      setPrerequisitesData(); // doesn't run the first time since still waiting on data
      todosViewPreprocess();
      pointViewPreprocess();
    }

    function itemsViewPreprocess() {
      _this.allItems = _.flatten(_.map(getTopLevelSections(), (section) => section.getAllItems()));
    }

    /** Preprocessing for Todos View * */
    function todosViewPreprocess() {
      _this.allTodoItems = _.flatten(_.map(getTopLevelSections(), (section) => section.getAllTodoItems()));

      _this.actionableTodoItems = _.filter(_this.allTodoItems, (item) => !(item.isCompleted() || (item.isApproved && item.isApproved())) && !item.isMissed());

      _this.missedTodoItems = _.filter(_this.allTodoItems, (item) => item.isMissed());

      _this.completedTodoItems = _.filter(_this.allTodoItems, (item) => (item.isCompleted() || (item.isApproved && item.isApproved())));

      _this.requiredTodosForCompletion = _.filter(_this.allTodoItems, (item) => item.isTodoRequiredForCompletion());

      _this.requiredTodosCompleted = _.filter(_this.allTodoItems, (item) => (item.isCompleted() || (item.isApproved && item.isApproved())) && item.isTodoRequiredForCompletion());

      // These items do not have to be marked required for completion
      _this.requiredAssignmentsForCompletion = _.filter(_this.allTodoItems, (item) => item.isExercise);

      _this.requiredAssignmentsCompleted = _.filter(_this.allTodoItems, (item) => (item.isCompleted() || (item.isApproved && item.isApproved())) && item.isExercise);
    }

    /** Preprocessing for Points View * */
    function pointViewPreprocess() {
      _this.allPointItems = _.flatten(_.map(getTopLevelSections(), (section) => section.getAllPointsReceivedItems()));

      _this.pointsReceived = _.reduce(_this.allPointItems, (memo, item) => item.pointsReceived + memo, 0);
    }

    function currentLecturePreprocess() {
      if (_.isEmpty(getTopLevelSections())) {
        return false;
      }

      if (_this.currentLectureId) {
        _.some(getTopLevelSections(), (section) => {
          _this.currentLecturePage = section.getLecturePage(_this.currentLectureId);
          if (_this.currentLecturePage) {
            _this.currentLecturePage.current = true;
            _this.currentLecturePage.isCurrentModule = true;
            _this.currentLecturePage.lectureSection.expanded = true;

            return true;
          }
          return false;
        });
      }

      return null;
    }
  };

  TimelineModel.translateTimeline = function (catalogId, language) {
    return TimelinesResources.translateTimeline({ catalogId }, { language }).$promise;
  };

  TimelineModel.getResourceAndPromise = function (catalogId, userId, isAdmin, course, { includeLectureComponents = true, editMode = false } = {}) {
    const resourceCall = TimelinesResources.get({
      catalogId,
      userId,
      // query string parameters start
      bust: new Date().getTime(),
      // lite version does not include lecture components - progress & point data will be provided by the backend at the lecture page level
      // lite=false mode will have progress and points at the lecture component level
      lite: !includeLectureComponents,
      editMode,
    });

    return {
      resource: resourceCall,
      promise: resourceCall.$promise.then((response) => new TimelineModel(response.result, userId, isAdmin, course, { hasLectureComponents: includeLectureComponents })),
    };
  };

  TimelineModel.getAdminCredits = function (catalogId, userId) {
    return TimelinesResources.getAdminCredits({ catalogId, vieweeId: userId }).$promise
      .then((response) => response.result);
  };

  TimelineModel.getCurrentLecture = (catalogId) => TimelinesResources.getCurrentLecture({ catalogId }).$promise
    .then((response) => response.result);

  TimelineModel.copyLectureSection = function (catalogId, lectureSection, target) {
    return TimelinesResources.copyLectureSection({ catalogId, lectureSection }, target).$promise;
  };

  TimelineModel.copyLecturePage = function (catalogId, lecturePage, target) {
    return TimelinesResources.copyLecturePage({ catalogId, lecturePage }, target).$promise;
  };

  /* Admin View */
  /* Create */
  TimelineModel.createLectureSection = function (catalogId, type, parentLectureSection, index) {
    const newSection = {
      title: 'Section',
      lecturePages: [],
      type,
      index,
    };

    // for subsection, supply id of parent
    if (type === 'LectureSubsection') {
      newSection.title = 'Subsection';
      newSection.parentId = parentLectureSection?.id;
      newSection.index = index;
    }

    return TimelinesResources.createLectureSection({ catalogId }, { lectureSection: newSection }).$promise
      .then((response) => response.result);
  };

  TimelineModel.createLecturePage = function (catalogId, lectureSection) {
    const payload = {
      lectureSectionId: lectureSection.id,
      title: 'New Lesson',
      index: lectureSection.lecturePages.length,
      releaseDate: moment().add(1, 'months').endOf('day').toISOString(),
    };
    return TimelinesResources.createLecturePage({ catalogId }, { lecturePage: payload }).$promise
      .then((response) => response.result);
  };


  TimelineModel.updateLectureSection = function (catalogId, lectureSection) {
    return TimelinesResources.updateLectureSection({ catalogId, lectureSectionId: lectureSection.id },
      { lectureSection: _.pick(lectureSection, 'id', 'title', 'index', 'type') }).$promise
      .then((response) => response.result);
  };

  TimelineModel.deleteLectureSection = function (catalogId, lectureSectionId, removeTitleOnly = true) {
    const payload = { catalogId, lectureSectionId, keepContent: removeTitleOnly };
    const deferred = $q.defer();

    store.dispatch(deleteLectureSection(payload))
      .then((response) => deferred.resolve(_.isEmpty(response.error) ? response.payload : false))
      .catch((e) => deferred.reject(e));

    return deferred.promise;
  };

  TimelineModel.duplicateLectureSection = function (catalogId, lectureSectionId) {
    return TimelinesResources.duplicateLectureSection({
      catalogId,
      lectureSectionId,
    }, {
      releaseDate: moment().add(1, 'months').endOf('day').toISOString(),
    }).$promise.then((response) => response.result);
  };

  TimelineModel.toggleCardView = function (catalogId) {
    return TimelinesResources.toggleCardView({ catalogId }, {}).$promise
      .then((response) => response.result);
  };

  TimelineModel.getActivitiesInSection = function (catalogId, lectureSectionId) {
    return TimelinesResources.getActivitiesInSection({ catalogId, lectureSectionId }, {}).$promise
      .then((response) => response.result);
  };

  TimelineModel.createFromPreset = function (catalogId, templateName, userId, isAdmin, course) {
    return TimelinesResources.createFromPreset({ catalogId, templateName }, {}).$promise
      .then((response) => new TimelineModel(response.result, userId, isAdmin, course, { hasLectureComponents: true }));
  };

  return TimelineModel;
}
