// Adapted from https://gist.github.com/bripc/e6e8118028baf5465f7d688ae467251a due to https://novoed.atlassian.net/browse/NOV-58235
// and https://github.com/HubSpot/offline/issues/170#issuecomment-309910102

/* @ngInject */
export default function OfflineInterceptor($q) {
  // queue for saving requests to be sent to the server when connection is down
  let queue = [];

  return {
    request(config) {
      let deferred;

      // when Offline determines the connection is back up, send all the requests in the queue
      window.Offline.on('up', () => {
        // if they sent requests while offline, resend them now
        if (queue.length) {
          angular.forEach(queue, (req) => {
            req.promise.resolve(req.config);
          });
          queue = [];
        }
      });

      if (window.Offline.state === 'down') {
        // create and return a promise for resolving once the connection is restored
        deferred = $q.defer();
        queue.push({
          config,
          promise: deferred,
        });
        return deferred.promise;
      }
      return config;
    },
  };
}
