import { each } from 'underscore';
import t from 'react-translate';
import { CourseAliases } from 'redux/schemas/models/course';
import { LecturePageType } from 'redux/schemas/models/lecture-page';
import { FeedbackCategory, OwnerType } from 'redux/schemas/models/course-communication';
import { ActivityType } from 'redux/schemas/models/activity';

import {
  RteTag, RteTagTypes, RelatedActivity, RteTagValue, RelatedCourse,
} from './nv-froala-constants';

// Export Default Tags that are Course Depended
/* @ngInject */
export function getDefaultTags(
  isSelfPaced: boolean,
  aliases: CourseAliases,
  isJourney: boolean,
): { [key: string]: RteTag } {
  if (isJourney) {
    return getJourneyDefaultTags(aliases);
  }
  return getCourseDefaultTags(isSelfPaced, aliases);
}
/* @ngInject */
export function getCourseDefaultTags(
  isSelfPaced: boolean,
  aliases: CourseAliases,
): { [key: string]: RteTag } {
  return {
    FIRST_NAME: {
      tagValue: RteTagValue.FIRST_NAME,
      displayName: t.FROALA.INSERT_TAGS.LEARNER_FIRST_NAME(aliases.learnersAliases),
      type: RteTagTypes.NAME,
    },
    LAST_NAME: {
      tagValue: RteTagValue.LAST_NAME,
      displayName: t.FROALA.INSERT_TAGS.LEARNER_LAST_NAME(aliases.learnersAliases),
      type: RteTagTypes.NAME,
    },
    OFFERING_NAME: {
      tagValue: RteTagValue.OFFERING_NAME,
      displayName: t.FROALA.INSERT_TAGS.COURSE_NAME(aliases.courseAliases),
      type: RteTagTypes.NAME,
    },
    OFFERING_HOME_URL: {
      tagValue: RteTagValue.OFFERING_HOME_URL,
      displayName: t.FROALA.INSERT_TAGS.COURSE_HOME(aliases.courseAliases),
      type: RteTagTypes.URL,
    },
    OFFERING_START_DATE: {
      tagValue: RteTagValue.OFFERING_START_DATE,
      displayName: t.FROALA.INSERT_TAGS.COURSE_OPENING_DATE(aliases.courseAliases),
      type: RteTagTypes.DATE,
    },
    OFFERING_RELEASE_DATE: {
      tagValue: RteTagValue.OFFERING_RELEASE_DATE,
      displayName: t.FROALA.INSERT_TAGS.COURSE_RELEASE_DATE(aliases.courseAliases),
      type: RteTagTypes.DATE,
    },
    OFFERING_END_DATE: {
      tagValue: RteTagValue.OFFERING_END_DATE,
      displayName: t.FROALA.INSERT_TAGS.COURSE_END_DATE(aliases.courseAliases),
      type: RteTagTypes.DATE,
    },
    OFFERING_CLOSING_DATE: {
      tagValue: RteTagValue.OFFERING_CLOSING_DATE,
      displayName: t.FROALA.INSERT_TAGS.COURSE_CLOSING_DATE(aliases.courseAliases),
      type: RteTagTypes.DATE,
    },
    ...(isSelfPaced ? {
      OFFERING_REMAINING_ACCESS_DAYS: {
        tagValue: RteTagValue.OFFERING_REMAINING_ACCESS_DAYS,
        displayName: t.FROALA.INSERT_TAGS.REMAINING_DAYS_FOR_ACCESS(),
        type: RteTagTypes.DATE,
      },
    } : {}),
  };
}

// Export Default Tags for Journeys
/* @ngInject */
export function getJourneyDefaultTags(
  aliases: CourseAliases,
): { [key: string]: RteTag } {
  return {
    FIRST_NAME: {
      tagValue: RteTagValue.FIRST_NAME,
      displayName: t.FROALA.INSERT_TAGS.LEARNER_FIRST_NAME(aliases.learnersAliases),
      type: RteTagTypes.NAME,
    },
    LAST_NAME: {
      tagValue: RteTagValue.LAST_NAME,
      displayName: t.FROALA.INSERT_TAGS.LEARNER_LAST_NAME(aliases.learnersAliases),
      type: RteTagTypes.NAME,
    },
    OFFERING_NAME: {
      tagValue: RteTagValue.OFFERING_NAME,
      displayName: t.FROALA.INSERT_TAGS.COURSE_NAME(aliases.courseAliases),
      type: RteTagTypes.NAME,
    },
    OFFERING_HOME_URL: {
      tagValue: RteTagValue.OFFERING_HOME_URL,
      displayName: t.FROALA.INSERT_TAGS.COURSE_HOME(aliases.courseAliases),
      type: RteTagTypes.URL,
    },
    OFFERING_RELEASE_DATE: {
      tagValue: RteTagValue.OFFERING_RELEASE_DATE,
      displayName: t.FROALA.INSERT_TAGS.COURSE_RELEASE_DATE(aliases.courseAliases),
      type: RteTagTypes.DATE,
    },
    OFFERING_CLOSING_DATE: {
      tagValue: RteTagValue.OFFERING_CLOSING_DATE,
      displayName: t.FROALA.INSERT_TAGS.COURSE_CLOSE_DATE(aliases.courseAliases),
      type: RteTagTypes.DATE,
    },
  };
}

export const getExtraTags = (tagValues: RteTagValue[]): { [ key: string]: RteTag } => {
  // Return only the reuested tags from parent component
  const extraRteTags = {
    [RteTagValue.INSERT_PASSWORD_SENTENCE]: {
      tagValue: RteTagValue.INSERT_PASSWORD_SENTENCE,
      displayName: t.FROALA.INSERT_TAGS.INSERT_PASSWORD_SENTENCE(),
      type: RteTagTypes.ACCESS_INSTRUCTION,
    },
  };
  const requestedTags: { [key: string]: RteTag } = {};
  each(tagValues, tagvalue => {
    requestedTags[tagvalue] = extraRteTags[tagvalue];
  });
  return requestedTags;
};

// Convert OwnerType ( Activity + LecturePage ) to the tag Format
export const getRteTagPrefixFromActivityType = (type: OwnerType, isFeedbackPublic: boolean, category?: FeedbackCategory) => {
  switch (type) {
    case ActivityType.EXERCISE_SKILLS_RATING:
      return 'EXERCISE_SKILLS_RATING';
    case ActivityType.EXERCISE:
      return 'ASSIGNMENT';
    case ActivityType.EXTERNAL_TOOL:
      return 'THIRD_PARTY_TOOL';
    case ActivityType.GROUP_FORMATION:
      return 'GROUP_FORMATION';
    case ActivityType.LECTURE_VIDEO:
      return 'AUDIO_VIDEO';
    case ActivityType.LIVE_SESSION:
      return 'LIVE_VIDEO_EVENT';
    case ActivityType.PEER_EVALUATION:
      return isFeedbackPublic ? 'PUBLIC_FEEDBACK' : 'PRIVATE_FEEDBACK';
    case ActivityType.POLL:
      return 'POLL';
    case ActivityType.POST:
      return 'DISCUSSION';
    case ActivityType.PROFILE_REQUIREMENT:
      return 'PROFILE_COMPLETION';
    case ActivityType.PROGRESSIVE_QUIZ:
      return 'PROGRESSIVE_QUIZ';
    case ActivityType.QUIZ:
      return 'QUIZ';
    case ActivityType.SURVEY:
      return 'SURVEY';
    case ActivityType.TEAM_DISCUSSION:
      return 'TEAM_DISCUSSION';
    case ActivityType.TEAM_FORMATION:
      return 'TEAM_FORMATION';
    case ActivityType.TIMED_EXAM:
      return 'TIMED_EXAM';
    case ActivityType.VIDEO_PRACTICE_FEEDBACK:
      return 'FEEDBACK_CRITERIA';
    case ActivityType.VIDEO_PRACTICE_SKILLS_FEEDBACK:
      return 'EXERCISE_SKILLS_RATING';
    case ActivityType.VIDEO_PRACTICE:
      return 'VIDEO_PRACTICE';
    case LecturePageType.LECTURE:
      return 'LESSON';
    default:
      return '';
  }
};

// Place the tags to the RTE editor
/* @ngInject */
export function insertRteTags(
  {
    tagValue,
    displayName,
  }: RteTag,
  froalaInstanceRef,
): void {
  froalaInstanceRef.current.selection.restore();
  froalaInstanceRef.current.html.insert(`[${tagValue}]`);
  froalaInstanceRef.current.undo.saveStep(); // save current HTML into the undo stack
}

// Create Actual Tag for a Lecture Entity ( Activity / Lesson )
/* @ngInject */
export function createRteTagValue(
  entityType: OwnerType,
  entityId: number,
  tagType: RteTagTypes,
  isFeedbackPublic: boolean,
  category?: FeedbackCategory,
): string {
  if (tagType === RteTagTypes.URL) {
    // URL tag
    return `${getRteTagPrefixFromActivityType(entityType, isFeedbackPublic, category)}_${entityId}_URL`;
  }
  if (entityType === LecturePageType.LECTURE) {
    // Lecture Release Tag
    return `${getRteTagPrefixFromActivityType(entityType, isFeedbackPublic)}_${entityId}_RELEASE_DATE`;
  }
  if (entityType === ActivityType.LIVE_SESSION) {
    // Live Stream Start Tag
    return `${getRteTagPrefixFromActivityType(entityType, isFeedbackPublic)}_${entityId}_START_DATE`;
  }
  // Assignment Due Date Tag
  return `${getRteTagPrefixFromActivityType(entityType, isFeedbackPublic)}_${entityId}_DUE_DATE`;
}

// Create Tag for a Course Entity
/* @ngInject */
export function createCourseRteTagValue(
  courseId: number,
  tagType: RteTagTypes,
  dateType: string,
): string {
  return tagType === RteTagTypes.URL ? `COURSE_${courseId}_URL` : `COURSE_${courseId}_${dateType}`;
}

// Created Tag Object From Passed Activity / Lesson object
/* @ngInject */
export function convertRelatedActivityToRteTag(
  activity: RelatedActivity,
  tagType: RteTagTypes,
  defaultSelected?: boolean,
  isFeedbackPublic?: boolean,
  category?: FeedbackCategory,
): RteTag {
  return {
    tagValue: createRteTagValue(activity.type, activity.id, tagType, isFeedbackPublic, category),
    entityType: activity.type,
    entityId: activity.id,
    type: tagType,
    selected: !!defaultSelected,
    category,
  };
}

// Created Tag Object From Passed Course object
/* @ngInject */
export function convertRelatedCourseToRteTag(
  course: RelatedCourse,
  tagType: RteTagTypes,
  defaultSelected?: boolean,
): RteTag {
  return {
    tagValue: createCourseRteTagValue(course.id, tagType, course.dateType),
    displayName: course.tagName,
    entityId: course.id,
    type: tagType,
    selected: !!defaultSelected,
  };
}

/* @ngInject */
export function getCourseDateTypes() {
  return [
    {
      title: 'Release Date',
      value: 'RELEASE_DATE',
      tagName: t.FROALA.INSERT_TAGS.RELEASE_DATE_FOR_COURSE,
    },
    {
      title: 'Start Date',
      value: 'START_DATE',
      tagName: t.FROALA.INSERT_TAGS.START_DATE_FOR_COURSE,
    },
    {
      title: 'End Date',
      value: 'END_DATE',
      tagName: t.FROALA.INSERT_TAGS.END_DATE_FOR_COURSE,
    },
    {
      title: 'Close Date',
      value: 'CLOSE_DATE',
      tagName: t.FROALA.INSERT_TAGS.CLOSE_DATE_FOR_COURSE,
    },
  ];
}
