/* @ngInject */
export default function CoursesManager(

  CoursesResource,
  CourseModel,
) {
  const manager = {
    course: null,
    newCourse,
    requestCourseById,
    resetCourse,
  };

  function newCourse(attributes) {
    manager.course = new CourseModel(attributes);
  }

  function requestCourseById(id, options) {
    if (!options) { options = {}; }
    if (!options.serializer) { options.serializer = 'list'; }

    return CoursesResource.getById({ id, serializer: options.serializer }).$promise.then(
      (resource) => {
        manager.course = new CourseModel(resource.result);
      },
      (resource) => {
        manager.resetCourse();
      },
    );
  }

  function resetCourse() {
    manager.course = null;
  }

  return manager;
}
