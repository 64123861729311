import { css } from '@emotion/react';
import { useState } from 'react';
import t from 'react-translate';
import { UploadedFile } from 'redux/schemas/models/file-uploads';
import { gray1, gray4, hexToRgbaString } from 'styles/global_defaults/colors';
import { halfSpacing, standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import NvIcon from '../nv-icon';
import useUploadedFile from './hooks/use-uploaded-file';
import NvUploadedFileContent from './nv-uploaded-file-content';
import NvUploadedFileCompactView from './nv-uploaded-file-compact-view';

export type NvUploadedFileProps = {
  file: UploadedFile,
  displayInline?: any,
  // displayInlineStrict?: any,
  // downloadAlwaysVisible?: any,
  onDelete?: any,
  // clickHandler?: any,
  originalUrl?: any,
  showConfirmation?: any,
  displayNameOnly?: boolean;
  displayTopOptions?: boolean;
  editable?: boolean
  className?: string
  downloadDataQa?: string
  deleteDataQa?: string
  confirmDeleteDataQa?: string
  cancelDeleteDataQa?: string
};

const NvUploadedFile = ({
  file,
  displayInline = true,
  displayNameOnly,
  displayTopOptions = true,
  onDelete,
  editable,
  className,
  downloadDataQa,
  deleteDataQa,
  confirmDeleteDataQa,
  cancelDeleteDataQa,
}: NvUploadedFileProps) => {
  const styles = css`
    .editable {
      border: 1px dashed transparent;
      left: -${halfSpacing}px;
      padding: ${halfSpacing}px;
      width: 100%;
    }
    .file-container {
      position: relative;
      box-sizing: content-box;
      min-height: ${tripleSpacing}px;
      &:hover, &:focus-within {
        .file-container {
          border-color: ${gray4};
        }
      }
      .deletion-confirmation {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${hexToRgbaString(gray1, 0.95)};
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        .deletion-confirmation-content {
          ${displayNameOnly && css`
            display: flex;
          `}

          .confirmation-text {
            margin-bottom: ${halfSpacing}px;

            ${displayNameOnly && css`
              margin-right: ${standardSpacing}px;
              margin-bottom: 0;
              display: flex;
              align-items: center;
            `}
          }
        }

        .btn-danger {
          margin-left: ${halfSpacing}px;
        }
      }
    }

    &:hover {
      .file-container {
        border: 1px dashed ${gray4};
      }
    }

    &.confirming-delete {
      .delete-icon, .download-icon {
        visibility: hidden;
      }

      .file-container {
        border-color: transparent;
      }
    }

    .display-name {
      img {
        height: ${tripleSpacing}px;
      }
    }
  `;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { getNormalizeFileType, isFileAvailableToDownload, getImageSrc } = useUploadedFile();
  const normalizedFileType = getNormalizeFileType(file.type, file.name);

  const downloadFile = () => {
    if (file?.url) {
      window.open(file.url, '_blank');
    }
  };

  const FileContent = displayInline ? NvUploadedFileContent : NvUploadedFileCompactView;

  return (
    <div className={`nv-uploaded-file ${className} ${showConfirmation ? 'confirming-delete' : ''}`} css={styles}>
      {displayTopOptions && (
        <div className='d-flex mb-1'>
          {isFileAvailableToDownload(file) && (
            <div
              className='d-flex download-icon text-primary mr-4 cursor-pointer'
              onClick={downloadFile}
              data-qa={downloadDataQa}
            >
              <NvIcon size='smallest' icon='download' />
              <p className='ml-1 mb-1 font-weight-bold'>{t.FILE_UPLOAD.DOWNLOAD()}</p>
            </div>
          )}
          {onDelete && (
            <div
              className='d-flex delete-icon text-primary cursor-pointer'
              onClick={() => setShowConfirmation(true)}
              data-qa={deleteDataQa}
            >
              <NvIcon size='smallest' icon='trash' />
              <p className='ml-1 mb-1 font-weight-bold'>{t.NOVOED.DELETE()}</p>
            </div>
          )}
        </div>
      )}
      <div className={`file-container ${editable ? 'editable' : ''}`}>
        {displayNameOnly ? (
          <div className='bg-gray-7 display-name'>
            <img
              src={getImageSrc(normalizedFileType)}
              alt={`${normalizedFileType}-type-icon`}
            />
            <span className='file-name text-small pl-4'>{file.name}</span>
          </div>
        ) : <FileContent file={file} /> }

        {showConfirmation && (
          <div className='deletion-confirmation'>
            <div className='deletion-confirmation-content'>
              <div className='confirmation-text'>{t.FILE_UPLOAD.CONFIRM_DELETE()}</div>
              <button
                type='button'
                className='btn btn-default btn-sm dark-theme'
                onClick={() => setShowConfirmation(false)}
                data-qa={cancelDeleteDataQa}
              >{t.FORM.CANCEL()}
              </button>
              <button
                type='button'
                className='btn btn-danger btn-sm dark-theme'
                onClick={() => { setShowConfirmation(false); onDelete(); }}
                data-qa={confirmDeleteDataQa}
              >{t.FORM.DELETE()}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NvUploadedFile;
