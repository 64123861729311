/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  FetchSinglePostRequest,
} from 'redux/schemas/api/post';
import { AIDiscussionPrompts, Post } from 'redux/schemas/models/post';
import { makeQueryParamString } from 'redux/actions/helpers';
import { RootState } from 'redux/schemas';
import { NovoAIPayload } from 'redux/schemas/models/activity';
import { ComponentTrueType, TypeFromTrueType } from 'redux/schemas/models/lecture-component';

export const fetchSinglePost = createAsyncThunk<Post, FetchSinglePostRequest>(
  'FETCH_SINGLE_POST',
  async (params) => {
    const { includeUserBar } = params;
    const queryString = makeQueryParamString({ includeUserBar });
    const response = await axios.get(`${params.catalogId}/topics/${params.postId}?${queryString}`);
    /* Temporary placeholder to update points and progress on timeline manager with new points data
    * Tracked in https://novoed.atlassian.net/browse/NOV-66502. Need to be called after response for
    * fetch single post returns
    */
    params.callback?.(response.data.result);
    return response.data.result;
  },
);

export const fetchAIGeneratedPrompts = createAsyncThunk(
  'GET_GEN_AI_PROMPTS',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;
    const selectedContent = state.app.lecturePage.novoAI.content;

    const aiProperties: Partial<NovoAIPayload<TypeFromTrueType<ComponentTrueType>>> = {
      aiOriginTarget: {
        id: selectedContent.id,
        type: selectedContent.type,
      },
    };

    return axios.post(
      `${catalogId}/topics/ai_new`,
      { aiProperties },
    ).then(
      (response) => {
        const prompts: AIDiscussionPrompts['prompts'] = response?.data.result.map(prompt => ({
          id: prompt.index + 1,
          text: prompt.body,
        }));

        return {
          errorCode: null,
          prompts,
        };
      },
    ).catch((e) => {
      let errorCode = '';

      if (!e.response?.data?.error?.code) {
        errorCode = e.code;
      } else {
        errorCode = e.response?.data?.error?.code;
      }

      return {
        errorCode,
        prompts: [],
      };
    });
  },
);
