import store from 'redux/store';
import { getCourseCompletionProgress, updateTimeline } from 'redux/actions/timeline';

class ReactTimelineService {
  updateTimeline(lecturePageId: number) {
    const state = store.getState();
    const catalogId = state.models.lecturePages[lecturePageId]?.course?.catalogId;
    store.dispatch(updateTimeline(lecturePageId));
    if (catalogId) {
      store.dispatch(getCourseCompletionProgress({ catalogId }));
    }
  }
}

const ReactTimelineServiceSingleton = new ReactTimelineService();

export default ReactTimelineServiceSingleton;

export const useTimelineService = () => ReactTimelineServiceSingleton;
