/**
 * react-router Link component equivalent to ensure <Prompt /> of react-router
 * works properly. This exists because we can't use react-router default Link
 * with Prompt because it internally uses an anchor element and when we navigate
 * with it (changing the url), react-router fails at resetting the url when we
 * decide to cancel navigation: https://novoed.atlassian.net/browse/NOV-68484
 * Navigating imperatively isn't broken (via history.push method).
 * TODO: Consider in the future to migrate to a newer react-router version, we
 * can't now because they have removed <Prompt /> in v6:
 * https://github.com/remix-run/react-router/blob/381e90515289756e40f1620a4196dbd3cad300e9/docs/upgrading/v5.md#prompt-is-not-currently-supported
 * Inspired on https://github.com/remix-run/react-router/blob/v5.2.0/packages/react-router-dom/modules/Link.js
 */
import React from 'react';
import { __RouterContext as RouterContext } from 'react-router';
import {
  LocationState,
  LocationDescriptor,
  Location as HistoryLocation,
} from 'history';
import {
  resolveToLocation,
} from 'react-router-dom/modules/utils/locationUtils';

import { AngularContext } from 'react-app';
import ClickableContainer, { ClickableContainerProps } from 'components/clickable-container';

const isModifiedEvent = (event) => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

type LinkAnchorProps = ClickableContainerProps & {
  navigate: () => void,
};

const LinkAnchor = React.forwardRef<HTMLDivElement, LinkAnchorProps>(
  (
    {
      navigate,
      onClick,
      ...rest
    },
    ref,
  ) => {
    const props = {
      ...rest,
      ref,
      onClick: event => {
        if (onClick) onClick(event);

        if (
          !event.defaultPrevented // onClick prevented default
          && event.button === 0 // ignore everything but left clicks
          && !isModifiedEvent(event) // ignore clicks with modifier keys
        ) {
          navigate();
        }
      },
    };

    return <ClickableContainer {...props} role='link' />;
  },
);

type Props = ClickableContainerProps & {
  to: LocationDescriptor<LocationState> | ((location: HistoryLocation<LocationState>) => LocationDescriptor<LocationState>),
  replace?: boolean,
  component?: React.ComponentType<LinkAnchorProps>,
};

/**
 * The public API for rendering a history-aware <a>.
 */
const Link = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      to,
      replace = false,
      component = LinkAnchor,
      ...rest
    },
    ref,
  ) => {
    const { injectServices } = React.useContext(AngularContext);
    const [$rootScope] = injectServices(['$rootScope']);

    return (
      <RouterContext.Consumer>
        {context => {
          const { history } = context;

          const props = {
            ...rest,
            ref,
            navigate() {
              const location = resolveToLocation(to, context.location);
              const method = replace ? history.replace : history.push;

              method(location);
              // This makes sure react-router and angular-ui-router navigations
              // sync up.
              // TODO: It will not be necessary when our <NvLink /> component
              // internally calls $state.go method instead of using the react
              // imperative navigation (history.push & history.replace).
              $rootScope.$applyAsync();
            },
          };

          return React.createElement(component, props);
        }}
      </RouterContext.Consumer>
    );
  },
);

export default Link;
