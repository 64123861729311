import { css } from '@emotion/react';
import React, { useCallback, useRef, useState, useEffect } from 'react';

import t from 'react-translate';
import { Estimation } from 'redux/schemas/models/lecture-page';
import { getEstimateReadout } from 'redux/selectors/lecture-page';
import NvDropdown, { NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import useClickOutside from 'shared/hooks/use-click-outside';
import NvPopover from 'shared/components/nv-popover';
import ValidationErrorMessage from 'shared/components/inputs/validation-error-message';
import { largeSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import _ from 'underscore';
import { LecturePageEditContentParams } from '../lecture-page-content';
import { LecturePageMode } from '..';

export type LectureTimeEstimateButtonProps = {
  readOnly: boolean,
  estimate: Estimation,
  isHoverOnHeader: boolean;
  setEstimate: (val: Estimation) => void,
} & LecturePageEditContentParams;

export const LectureTimeEstimateButton = (props: LectureTimeEstimateButtonProps) => {
  const styles = css`
    // TODO: Setting this manually vs using a predefined size (and overriding the prop to NVIcon)
    // is pretty bad. Let's revise
    .icon-dueday {
      font-size: 10px;
    }

    .estimate-readout {
      height: 30px;
      padding-left: ${standardSpacing}px;
      padding-right: ${standardSpacing}px;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    ${props.isHoverOnHeader && css`
      .show-inputs-button {
        cursor: pointer;
        background-color: rgba(255, 255, 255, .4);
      }
    `}
  `;


  // The local version of the estimate; updated by the inputs & used to drive the entire UI.
  // Gets committed during saveEstimate()
  const [draftEstimate, setDraftEstimate] = useState(_.clone(props.estimate));
  const editInputsRef = useRef(null);
  const [showEditInputs, setShowEditInputs] = useState(false);
  const hasEstimate = draftEstimate?.estimatedEffort > 0 ?? false;

  /** This button is not recreated when changing lecture pages, so we need to manually update the estimate when the prop changes */
  useEffect(() => {
    setDraftEstimate(_.clone(props.estimate));
  }, [props.estimate]);

  /** Hides the edit inputs panel & saves any changes */
  const closeEditInputs = () => {
    setShowEditInputs(false);

    if (!Number.isNaN(draftEstimate.estimatedEffort)
      && (props.estimate.estimatedEffort !== draftEstimate.estimatedEffort
      || props.estimate.estimatedEffortMeasure !== draftEstimate.estimatedEffortMeasure
      || props.estimate.estimatedEffortType !== draftEstimate.estimatedEffortType)) {
      props.setEstimate(draftEstimate);
    }
  };

  /** Displays the edit inputs and sets up the estimate defaults in the event they are null */
  const displayEditUI = () => {
    setShowEditInputs(true);

    const newDraftEstimate: Estimation = { ...draftEstimate };
    if (!draftEstimate.estimatedEffort) {
      newDraftEstimate.estimatedEffort = 0;
    }

    if (!draftEstimate.estimatedEffortMeasure) {
      newDraftEstimate.estimatedEffortMeasure = 'Min';
    }

    if (!draftEstimate.estimatedEffortType) {
      newDraftEstimate.estimatedEffortType = 'Read';
    }

    setDraftEstimate(newDraftEstimate);
  };

  useClickOutside(editInputsRef, () => {
    if (+draftEstimate.estimatedEffort >= 0) {
      closeEditInputs();
    }
  }, [draftEstimate]);

  if (props.readOnly && !props.estimate) {
    return null;
  }

  return (
    <div css={styles}>
      {/* Non-edit display */}
      {!showEditInputs
        && (
          <NvTooltip
            text={hasEstimate ? t.LECTURE_PAGES.ADMIN.TIME_ESTIMATES.UPDATE() : t.LECTURE_PAGES.ADMIN.TIME_ESTIMATES.ADD()}
            enabled={!props.readOnly}
          >
            {/* Displays the current estimate if available */}
            <div className={`estimate-readout ${!props.readOnly ? 'show-inputs-button' : ''}`} onClick={() => !props.readOnly && displayEditUI()}>
              {hasEstimate && (
                <div className='d-flex align-items-center text-small justify-content-center'>
                  {props.mode !== LecturePageMode.EDIT && (
                    <div className='pr-1'>
                      <NvIcon size='xss-smallest' icon='timedexam' />
                    </div>
                  )}
                  {getEstimateReadout(draftEstimate)}
                </div>
              )}
              {/* Displays a button to add an estimate if we're not in readonly (page edit) mode */}
              {!hasEstimate && !props.readOnly
              && (
              <div className='d-flex align-items-center text-small'>
                <NvIcon icon='dueday' size='xs' />
                <div className='pl-1'>{t.TIME_ESTIMATES.ADD()}</div>
              </div>
              )}
            </div>
          </NvTooltip>
        )}
      {/* No estimate set, click to add */}
      {/* Edit UX */}
      {showEditInputs && (
        <div ref={editInputsRef}>
          <TimeEstimateInputs
            estimate={draftEstimate}
            setEstimate={(estimate) => setDraftEstimate(estimate)}
          />
        </div>
      )}
    </div>
  );
};

const estimatonInputsStyles = css`
  & > * {
    padding-right: ${quarterSpacing}px;
  }
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: button;
    -moz-appearance: unset !important;
    margin: unset;
  }

  input[type=number] {
    -moz-appearance: unset;
    width: ${standardSpacing * 3}px;
    height ${largeSpacing}px;
  }

  .bs4-dropdown {
    .title {
      height ${largeSpacing}px !important;
    }
  }
`;


const TimeEstimateInputs = (props: {
  estimate: Estimation,
  setEstimate: (estimation: Estimation) => void,
}) => {
  const [estimateDraft, setEstimateDraft] = useState(props.estimate);

  const updateEstimateDraft = useCallback((newEstimate: Estimation) => {
    setEstimateDraft(newEstimate);
    props.setEstimate(newEstimate);
  }, [props]);

  const updateEstimateNumber = useCallback((val: any) => {
    updateEstimateDraft({ ...estimateDraft, estimatedEffort: val });
  }, [estimateDraft, updateEstimateDraft]);

  const updateEffortMeasure = useCallback((val: Estimation['estimatedEffortMeasure']) => {
    updateEstimateDraft({ ...estimateDraft, estimatedEffortMeasure: val });
  }, [estimateDraft, updateEstimateDraft]);

  const updateEffortType = useCallback((val: Estimation['estimatedEffortType']) => {
    updateEstimateDraft({ ...estimateDraft, estimatedEffortType: val });
  }, [estimateDraft, updateEstimateDraft]);

  const [initialEffortTypeIndex, setInitialEffortTypeIndex] = useState(0);
  const [initialEffortMeasureIndex, setInitialEffortMeasureIndex] = useState(0);

  const [effortTypeDropdownItems, setEffortTypeDropdownItems] = useState<NvDropdownOption[]>([]);

  useEffect(() => {
    const items: NvDropdownOption[] = [];
    const effortTypes: { val: Estimation['estimatedEffortType'], name: string }[] = [
      { val: 'Read', name: t.TIME_ESTIMATES.EFFORT_TYPE.READ() },
      { val: 'Watch', name: t.TIME_ESTIMATES.EFFORT_TYPE.WATCH() },
      { val: 'Listen', name: t.TIME_ESTIMATES.EFFORT_TYPE.LISTEN() },
      { val: 'Work', name: t.TIME_ESTIMATES.EFFORT_TYPE.WORK() },
    ];

    effortTypes.forEach((effortType, i) => {
      if (estimateDraft.estimatedEffortType === effortType.val) {
        setInitialEffortTypeIndex(i);
      }
      items.push({
        type: 'text',
        text: effortType.name,
        callback: () => updateEffortType(effortType.val),
        textClassName: 'text-medium font-weight-bold',
        class: 'p-1',
      });
    });

    setEffortTypeDropdownItems(items);
  }, [estimateDraft.estimatedEffortType, updateEffortType]);

  const [effortMeasureDropdownItems, setEffortMeasureDropdownItems] = useState<NvDropdownOption[]>([]);

  useEffect(() => {
    const items: NvDropdownOption[] = [];
    const effortMeasures: { val: Estimation['estimatedEffortMeasure'], name: string }[] = [
      { val: 'Min', name: t.TIME_ESTIMATES.EFFORT_MEASURE.MIN() },
      { val: 'Hour', name: t.TIME_ESTIMATES.EFFORT_MEASURE.HOUR() },
      { val: 'Day', name: t.TIME_ESTIMATES.EFFORT_MEASURE.DAY() },
    ];

    effortMeasures.forEach((effortMeasure, i) => {
      if (estimateDraft.estimatedEffortMeasure === effortMeasure.val) {
        setInitialEffortMeasureIndex(i);
      }
      items.push({
        type: 'text',
        text: effortMeasure.name,
        callback: () => updateEffortMeasure(effortMeasure.val),
        textClassName: 'text-medium font-weight-bold',
        class: 'p-1',
      });
    });

    setEffortMeasureDropdownItems(items);
  }, [estimateDraft.estimatedEffortMeasure, updateEffortMeasure]);

  return (
    // Note the text-black here is forcing these inputs to ignore the foreground color set on the other header elements
    // via the white/black
    <div css={estimatonInputsStyles} className='d-flex align-items-center text-black'>
      {/* Conversion to a string here is due to NvTextInput doing a falsy checkon the value, causing a 0 value to be displayed as nothing */}
      <NvPopover
        show={+estimateDraft.estimatedEffort < 0}
        placement='top'
        preventOverflow={false}
        content={(
          <ValidationErrorMessage
            title={t.FORM.WARNING()}
            text={t.VALIDATION.POSITIVE_INTEGER()}
          />
        )}
      >
        <NvTextInput
          type='number'
          value={estimateDraft.estimatedEffort?.toString() ?? '0'}
          onChange={(e) => updateEstimateNumber(e.target.value)}
          min={0}
        />
      </NvPopover>
      {/* TODO: Reminder for when this goes to QA; we don't actually seem to have official mocks for what form-style dropdowns should look like. The default styling on these differ from what's used on prod here, which itself differs from other form-style dropdowns elsewhere on the site. Let's talk to Lei about this */}
      <NvDropdown
        buttonStyle={NvDropdownButtonStyle.FORM}
        items={effortMeasureDropdownItems}
        initialIndex={initialEffortMeasureIndex}
        showSelectedIndicator
        titleClass='font-weight-bolder text-small mr-1'
      />
      <NvDropdown
        buttonStyle={NvDropdownButtonStyle.FORM}
        items={effortTypeDropdownItems}
        initialIndex={initialEffortTypeIndex}
        showSelectedIndicator
        titleClass='font-weight-bolder text-small mr-1'
      />
    </div>
  );
};

export default LectureTimeEstimateButton;
