import React from 'react';
import uuid from 'react-uuid';
import tinycolor from 'tinycolor2';
import { css } from '@emotion/react';
import throttle from 'lodash/throttle';
import { useSelector } from 'react-redux';
import { HSLColor, HSVColor } from 'react-color';
import { HueProps } from 'react-color/lib/components/common/Hue';
import { Saturation, Hue } from 'react-color/lib/components/common';

import t from 'react-translate';
import { isSameColor } from 'shared/utils';
import { mergeRefs } from 'shared/react-utils';
import Divider from 'shared/components/divider';
import { getCurrentLecture } from 'redux/selectors/lecture-page';
import useUserColors from 'shared/hooks/use-user-colors';
import { headerLineHeight, textMediumFontSize } from 'styles/global_defaults/fonts';
import ClickableContainer, { ClickableContainerProps } from 'components/clickable-container';
import { gray2, gray5, gray6, getColorBrightness, white } from 'styles/global_defaults/colors';
import { halfSpacing, quarterSpacing, standardSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { getDefaultColorPalette } from 'redux/selectors/org-level-colors';

const getIsValueValid = (color) => tinycolor(color).isValid()
    && color.charAt(0) === '#'
    // With this, we exclude 4 and 8 character hex codes, given that we don't
    // use transparency.
    && (color.length === 7 || color.length === 4);

// I can't use @svgr/webpack since this svg has reference to ids (look at the
// linear gradients) and when there's more instance rendered of this same
// graphic at the same time it stops properly working.
// Creating the component from scratch allows me to dynamically set id values to
// prevent this problem.
const ColorPickerIcon = (props: Omit<React.ComponentProps<'svg'>, 'viewBox' | 'fill'>) => {
  const id = uuid();
  return (
    <svg viewBox='0 0 15 15' fill='none' {...props}>
      <path d='M15.0001 7.5C15.0001 11.6421 11.6422 15 7.5001 15C3.35798 15 0.00012207 11.6421 0.00012207 7.5C0.00012207 3.35786 3.35798 0 7.5001 0C11.6422 0 15.0001 3.35786 15.0001 7.5Z' fill='white' />
      <path d='M7.5 -3.41241e-07C11.6421 -5.29704e-07 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 -1.41011e-07 11.6421 -3.14956e-07 7.5C-4.88902e-07 3.35786 3.35786 -1.52779e-07 7.5 -3.41241e-07Z' fill={`url(#paint0_linear_${id})`} style={{ mixBlendMode: 'multiply' }} />
      <path d='M15.0001 7.5C15.0001 11.6421 11.6422 15 7.5001 15C3.35798 15 0.00012207 11.6421 0.00012207 7.5C0.00012207 3.35786 3.35798 0 7.5001 0C11.6422 0 15.0001 3.35786 15.0001 7.5Z' fill={`url(#paint1_linear_${id})`} style={{ mixBlendMode: 'multiply' }} />
      <defs>
        <linearGradient id={`paint0_linear_${id}`} x1='-0.0267857' y1='7.5' x2='15' y2='7.5' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#FF0000' />
          <stop offset='0.0001' stopColor='#7D00FF' />
          <stop offset='0.489583' stopColor='#FEFFFE' />
          <stop offset='1' stopColor='#7DFF00' />
        </linearGradient>
        <linearGradient id={`paint1_linear_${id}`} x1='7.51033' y1='0' x2='7.5001' y2='15' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#FF0000' />
          <stop offset='0.489583' stopColor='#FEFFFE' />
          <stop offset='1' stopColor='#00FFFF' />
        </linearGradient>
      </defs>
    </svg>
  );
};

const ITEM_SIZE = threeQuartersSpacing;

type GridWrapProps = {
  size: number;
  columns: number;
  spacing: number;
  children: React.ReactElement[];
};

const GridWrap = (props: GridWrapProps) => {
  const {
    size,
    columns,
    spacing,
    children,
  } = props;

  const style = css`
    width: ${(size * columns) + (spacing * (columns - 1))}px;

    & > * {
      width: ${size}px;
      height: ${size}px;
      margin-right: ${spacing}px;
      margin-bottom: ${spacing}px;

      &:nth-child(${columns}n) {
        margin-right: 0px;
      }

      &:nth-last-child(-n + ${columns}) {
        margin-bottom: 0px;
      }
    }
  `;

  return (
    <div css={style} className='d-flex flex-wrap'>
      {children}
    </div>
  );
};

const SATURATION_HEIGHT = 150;
const SATURATION_WIDTH = 165;
const PICKER_CONTENT_WIDTH = SATURATION_WIDTH + (standardSpacing * 2);

const CustomSlider = (props: HueProps) => {
  const { hsl } = props;

  const saturatedHSL = { ...hsl };

  saturatedHSL.s = 1;
  saturatedHSL.l = 0.5;

  const tColor = tinycolor(saturatedHSL);

  const styles = css`
    margin-top: 1px;
    border: 1px solid white;
    width: ${quarterSpacing}px;
    height: ${threeQuartersSpacing}px;
    background-color: #${tColor.toHex()};
    border-radius: ${quarterSpacing / 2}px;
    transform: translate(-${quarterSpacing / 2}px, -6px);
  `;

  return (
    <ClickableContainer css={styles} />
  );
};

const CUSTOM_POINTER_SIZE = 11;

const CustomPointer = () => {
  const styles = css`
    border-radius: 50%;
    border: 1px solid white;
    width: ${CUSTOM_POINTER_SIZE}px;
    height: ${CUSTOM_POINTER_SIZE}px;
    transform: translate(-${CUSTOM_POINTER_SIZE / 2}px, -${CUSTOM_POINTER_SIZE / 2}px);
  `;

  return (
    <ClickableContainer css={styles} />
  );
};

type InputProps = Omit<React.ComponentProps<'input'>, 'className' | 'onChange' | 'ref' | 'value'> & {
  ref?: React.Ref<HTMLInputElement>;
  value?: null | string;
};

type ColorData = {
  hex: string,
  hsv: HSVColor,
  hsl: HSLColor,
};

type Props = {
  color?: string | null;
  inputProps?: InputProps;
  showRecentlyUsedColors?: boolean
  onChange?: (
    newColor: string,
    /**
     * Indicates whether the color changed by using the hex color input
     * (Only froala colors plugin implementation requires it, see froala
     * colors plugin code for more details)
     */
    causedByHexInput?: boolean,
  ) => void;
} & React.ComponentProps<'div'>;

export type RefValue = {
  disableInputBlur: Function,
};

export const ColorPickerContent = React.forwardRef<RefValue, Props>((props, ref) => {
  const {
    className,
    showRecentlyUsedColors = true,
    inputProps: {
      ref: propsInputRef,
      onBlur: onInputBlur,
      onFocus: onInputFocus,
      onKeyDown: onInputKeyDown,
      ...restInputProps
    } = {},
    onChange: propsOnChange,
    ...restProps
  } = props;

  let { color } = props;

  color = color || '#FFF';
  const disableBlurRef = React.useRef(false);
  const inputRef = React.useRef<HTMLInputElement>();

  const disableInputBlur = React.useCallback(() => {
    disableBlurRef.current = true;
  }, []);

  React.useImperativeHandle(ref, () => ({
    disableInputBlur,
  }), [disableInputBlur]);

  const propsOnChangeRef = React.useRef(propsOnChange);
  propsOnChangeRef.current = propsOnChange;

  // Since this color picker updates the color in real time every time the color
  // change in the picker, I am throttling the event to prevent a lot of renders
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = React.useCallback(throttle((newColor, causedByHexInput?: boolean) => propsOnChangeRef.current(newColor, causedByHexInput), 100), []);

  const currentLecture = useSelector(getCurrentLecture);

  const { recentlyUsedColors } = useUserColors({ currentLecture });

  const initialColorData = React.useMemo(() => {
    const tColor = tinycolor(color);

    return {
      hsl: tColor.toHsl(),
      hsv: tColor.toHsv(),
      hex: color,
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [colorData, setColorData] = React.useState<ColorData>(initialColorData);

  const isHexValid = getIsValueValid(colorData.hex);

  const colorDataRef = React.useRef(colorData);
  colorDataRef.current = colorData;

  const lastColorDataRef = React.useRef<ColorData>();

  // Updates color picker local color data from controlling value
  React.useEffect(() => {
    if (color !== colorDataRef.current.hex) {
      const tColor = tinycolor(color);

      setColorData((prev) => ({
        hsv: tColor.toHsv(),
        hsl: tColor.toHsl(),
        hex: color,
      }));
    }
  }, [color]);

  const styles = css`
    background-color: ${white};
    width: ${PICKER_CONTENT_WIDTH}px;

    .saturation {
      flex-shrink: 0;
      border: 1px solid ${gray5};
      width: ${SATURATION_WIDTH}px;
      height: ${SATURATION_HEIGHT}px;
    }

    .hue {
      flex-shrink: 0;
      height: ${quarterSpacing}px;
      width: ${SATURATION_WIDTH}px;
    }

    .editable-input {
      height: 25px;

      .selected-color {
        width: 25px;
        height: 25px;
        flex-shrink: 0;
        border: 1px solid ${gray5};
        background-color: ${isHexValid ? color : lastColorDataRef.current?.hex};
      }

      input {
        flex: 1;
        width: 100%;
        border: none;
        height: 25px;
        padding-left: 0;
        color: ${gray2}!important;
        background-color: transparent;
        line-height: ${headerLineHeight}px;
        font-size: ${textMediumFontSize};
      }
    }
  `;

  const handleHueChange = hue => {
    const tColor = tinycolor(hue);
    const hex = `#${tColor.toHex()}`;

    setColorData({
      hex,
      hsv: tColor.toHsv(),
      hsl: hue,
    });

    onChange?.(hex);
  };

  const handleSaturationChange = (hsv: HSVColor) => {
    const tColor = tinycolor(hsv);
    const hex = `#${tColor.toHex()}`;

    setColorData({ hex, hsl: tColor.toHsl(), hsv });
    onChange?.(hex);
  };

  const handleInputChange = (newColor) => {
    if (newColor === '') {
      newColor = '#';
    }

    const isValid = getIsValueValid(newColor);
    if (isValid) {
      const tColor = tinycolor(newColor);
      setColorData({
        hsv: tColor.toHsv(),
        hsl: tColor.toHsl(),
        hex: newColor,
      });
      onChange?.(newColor, true);
    } else {
      setColorData({
        hex: newColor,
        hsv: null,
        hsl: null,
      });
    }
  };

  const handleInputFocus = (e) => {
    if (!lastColorDataRef.current) {
      lastColorDataRef.current = colorData;
    }

    onInputFocus?.(e);
  };

  const handleInputBlur = (e) => {
    if (disableBlurRef.current) {
      disableBlurRef.current = false;
    } else if (!isHexValid) {
      setColorData(lastColorDataRef.current);
    } else {
      lastColorDataRef.current = undefined;
    }

    onInputBlur?.(e);
  };

  const handleInputKeyDown = (e) => {
    onInputKeyDown?.(e);

    if (e.key === 'Enter') {
      e.preventDefault();

      inputRef.current?.blur();
    }
  };

  return (
    <div
      css={styles}
      className={`d-flex position-relative flex-column p-4 ${className ?? ''}`}
      {...restProps}
    >
      <div className='overflow-hidden position-relative mb-2 saturation'>
        <Saturation
          pointer={CustomPointer}
          onChange={handleSaturationChange}
          hsl={isHexValid ? colorData.hsl : lastColorDataRef.current?.hsl}
          hsv={isHexValid ? colorData.hsv : lastColorDataRef.current?.hsv}
        />
      </div>
      <div className='position-relative mt-1 mb-3 hue'>
        <Hue
          direction='horizontal'
          onChange={handleHueChange}
          pointer={CustomSlider as React.ComponentType}
          hsl={isHexValid ? colorData.hsl : lastColorDataRef.current?.hsl}
        />
      </div>
      <div className='editable-input d-flex flex-row mb-3 w-100'>
        <div className='selected-color mr-2' />
        <input
          value={colorData.hex}
          className='text-body'
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
          onKeyDown={handleInputKeyDown}
          ref={mergeRefs(inputRef, propsInputRef)}
          onChange={(e) => handleInputChange(e.target.value?.trim())}
          {...restInputProps}
        />
      </div>
      {showRecentlyUsedColors && (
        <React.Fragment>
          <div className='text-xs mb-2 gray-3'>{t.SHARED.MOST_RECENTLY_USED()}</div>
          <GridWrap size={ITEM_SIZE} spacing={halfSpacing} columns={7}>
            {recentlyUsedColors.map((currentColor: string) => {
              const handleColorSampleClick = () => onChange?.(currentColor);

              return (
                <ClickableContainer
                  key={currentColor}
                  onClick={handleColorSampleClick}
                  style={{
                    backgroundColor: currentColor,
                  }}
                />
              );
            })}
          </GridWrap>
        </React.Fragment>
      )}
    </div>
  );
});

const DEFAULT_BORDER_COLOR = gray6;
const DEFAULT_BORDER_COLOR_BRIGHTNESS = getColorBrightness(DEFAULT_BORDER_COLOR);

type ColorButtonProps = ClickableContainerProps & {
  active?: boolean;
  color?: string;
};

const ColorButton = (props: ColorButtonProps) => {
  const { active, color, children, className, ...rest } = props;

  let borderWidth = 0;

  // If the color brightness is too high, we add a small border
  const colorBrightness = color ? getColorBrightness(color) : 0;
  if (colorBrightness > DEFAULT_BORDER_COLOR_BRIGHTNESS) {
    borderWidth = 1;
  }

  const style = css`
    .button {
      z-index: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-color: ${color};
      border-width: ${borderWidth}px;
      border-color: ${DEFAULT_BORDER_COLOR};
      border-style: ${borderWidth ? 'solid' : 'none'};

      ${active && css`
        top: -2.5px;
        left: -2.5px;
        width: ${standardSpacing}px;
        height: ${standardSpacing}px;
        box-shadow: 2px 2px 4px 0 #1d2126;
        background-color: ${DEFAULT_BORDER_COLOR};
      `};

      .inner {
        z-index: 1;
        border-radius: 100%;
        background: ${color};
        width: ${threeQuartersSpacing}px;
        height: ${threeQuartersSpacing}px;
      }

    }
  `;

  return (
    <div css={style} className='position-relative'>
      <ClickableContainer
        aria-label={color}
        className={`button align-items-center justify-content-center position-absolute ${className}`}
        {...rest}
      >
        {children}
        {active && (
          <div className='inner' />
        )}
      </ClickableContainer>
    </div>
  );
};

interface ColorListProps {
  colorList: string[];
  removable?: boolean;
  activeColor?: string;
  onColorPickerClick?: () => void;
  onChange?(newColor: string | null): void;
}

function ColorList(props: ColorListProps) {
  const { activeColor, colorList, onChange, removable, onColorPickerClick } = props;

  const isActiveColorInList = colorList.some((eachColor) => isSameColor(eachColor, activeColor));
  const isCustomColorActive = !isActiveColorInList && !!activeColor && tinycolor(activeColor).isValid();
  const colorPickerIconSize = isCustomColorActive ? standardSpacing : threeQuartersSpacing;

  return (
    <GridWrap size={ITEM_SIZE} spacing={halfSpacing} columns={7}>
      <React.Fragment>
        {colorList.map((color) => (
          <ColorButton
            key={color}
            color={color}
            onClick={() => {
              let value = color;
              if (removable) {
                value = isSameColor(color, activeColor) ? null : color;
              }
              onChange?.(value);
            }}
            active={isSameColor(color, activeColor)}
          />
        ))}
      </React.Fragment>
      <ColorButton onClick={onColorPickerClick} active={isCustomColorActive} color={activeColor}>
        <ColorPickerIcon
          width={colorPickerIconSize}
          height={colorPickerIconSize}
          className='position-absolute'
        />
      </ColorButton>
    </GridWrap>
  );
}

export type Section<T extends string | number | symbol = string> = {
  name: T;
  title: string;
  removable?: boolean;
  predefinedColors?: string[];
};

export type ChangeHandler<T extends string | number | symbol = string> = (sectionName: T, newColor: string) => void;


export type ColorValues<T extends string | number | symbol = string> = Record<T, string | null>;

type ColorPaletteContentProps<T extends ColorValues> = {
  sections: Section<keyof T>[];
  colorValues: ColorValues<keyof T>;
  onChange?: ChangeHandler;
  onColorPickerClick?: (sectionName: string) => void;
};

export const ColorPaletteContent = <ColorValuesMapT extends ColorValues>(props: ColorPaletteContentProps<ColorValuesMapT>) => {
  const { sections, colorValues, onChange, onColorPickerClick } = props;
  const defaultColorPalette: string[] = useSelector(getDefaultColorPalette);

  const style = css`
    width: ${PICKER_CONTENT_WIDTH}px;

    .section {
      padding: ${halfSpacing}px ${standardSpacing}px;

      .label {
        margin-bottom: ${halfSpacing}px;
      }
    }
  `;

  return (
    <div css={style}>
      {sections.map((section, index) => {
        const handleChange = (color: string) => onChange?.(section.name as string, color);
        const isLast = index === sections.length - 1;

        return (
          <React.Fragment key={section.toString()}>
            <div
              className='section'
              key={section.name as string}
            >
              <div className='label gray-1'>{section.title}</div>
              <ColorList
                onChange={handleChange}
                activeColor={colorValues[section.name]}
                removable={section.removable}
                colorList={section.predefinedColors || defaultColorPalette}
                onColorPickerClick={() => onColorPickerClick?.(section.name as string)}
              />
            </div>
            {!isLast && (
              <Divider />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};


