import React from 'react';
import t from 'react-translate';
import { difference } from 'underscore';
import PhraseList from './phrase-list';
import InsightsCount from '../insights-count';
import FadeInView from '../../fade-in-view';

const KeysToCover = (props: {
  keyphrasesToCover: string[]
  coveredKeyphrases: string[]
  keyphrasesToAvoid: string[]
}) => {
  const { keyphrasesToCover, coveredKeyphrases, keyphrasesToAvoid } = props;
  const notCoveredKeyphrases: string[] = difference(keyphrasesToCover, coveredKeyphrases) ?? [];

  return (
    <FadeInView>
      <div
        className={`${keyphrasesToAvoid?.length > 0 ? 'border-bottom border-gray-4' : ''} py-6`}
      >
        <div className='d-flex'>
          <InsightsCount
            count={coveredKeyphrases?.length}
            label={t.PRACTICE_ROOM.INSIGHTS.MENTIONED.LABEL()}
            className={coveredKeyphrases?.length > 0 ? 'text-success' : 'text-warning'}
          />
          <div className='ml-4'>
            <div className='gray-1 text-regular'>
              {coveredKeyphrases?.length > 0
                ? t.PRACTICE_ROOM.INSIGHTS.MENTIONED.INCLUDED(coveredKeyphrases?.length)
                : t.PRACTICE_ROOM.INSIGHTS.MENTIONED.NOT_INCLUDED()}
            </div>
            {coveredKeyphrases?.length > 0 && (
              <PhraseList
                label={t.PRACTICE_ROOM.INSIGHTS.MENTIONED.MENTIONED()}
                items={coveredKeyphrases}
                borderClassName='border-success'
              />
            )}
            {notCoveredKeyphrases?.length > 0 && (
              <PhraseList
                label={t.PRACTICE_ROOM.INSIGHTS.MENTIONED.NOT_MENTIONED()}
                items={notCoveredKeyphrases}
                borderClassName='border-gray-5'
              />
            )}
          </div>
        </div>
      </div>
    </FadeInView>
  );
};

export default KeysToCover;
