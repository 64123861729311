import store from '../../redux/store';
import { getProfileRequirement } from '../../redux/selectors/profile-requirements';

/* @ngInject */
export default function NvStickyHeaderFooterController(
  $scope,
  $element,
  nvCurrentPage,
  PageLevelManager,
  _,
  $document,
  $rootScope,
  $state,
  $timeout,
  humps,
  CurrentUserManager,
  ConfirmationOverlays,
  CurrentCourseManager,
  LearnerProfileManager,
  LecturePageManager,
  config,
) {
  const _this = this;

  // data
  /* eslint-disable dot-notation */
  _this.$state = $state;
  _this.showBackButton = nvCurrentPage.getParentState() === 'modal-page' || nvCurrentPage.getCurrentStateData().level === 4;
  _this.headerExpandedVisible = true;
  _this.modalPageContentIsScrolling = false;
  _this.showModalTitleInStickyHeader = false;
  _this.lightboxPageContentIsScrolling = false;
  _this.showLightboxTitleInStickyHeader = false;
  _this.nvCurrentPage = nvCurrentPage;
  _this.PageLevelManager = PageLevelManager;
  _this.socialBarInView = false; // the likes and comments bar that toggles whether the sticky footer social bar displays or not
  _this.commentsRowVisible = false;

  _this.visibleSections = {};

  // functions
  _this.setSectionVisible = setSectionVisible;

  _this.checkStickyFooterVisible = checkStickyFooterVisible;
  _this.checkCommentsRowVisible = checkCommentsRowVisible;
  _this.closeL4 = closeL4;

  _this.config = config;


  // set up sticky headers for L3 and L4 pages
  if (nvCurrentPage.getCurrentStateData().headerCollapse) {
    _this.lightboxPageContentIsScrolling = true;
    _this.showLightboxTitleInStickyHeader = true;
  } else if (nvCurrentPage.getCurrentStateData().level > 1 && !nvCurrentPage.getCurrentStateData().headerCollapse) {
    setUpHeaderToggle('#modal-content-wrapper', 'modalPageContentIsScrolling', 'showModalTitleInStickyHeader');
    setUpHeaderToggle('.lightbox-panel', 'lightboxPageContentIsScrolling', 'showLightboxTitleInStickyHeader');
  }


  // BEGIN ACCESSIBILITY FIXES COPIED FROM ANGULAR BOOTRAP'S UIBMODALSTACK

  // Modal focus behavior
  const tabableSelector = 'a[href], area[href], input:not([disabled]), '
    + 'button:not([disabled]),select:not([disabled]), textarea:not([disabled]), '
    + 'iframe, object, embed, *[tabindex], *[contenteditable=true]';


  const loadFocusElementList = function () {
    const elements = $element[0].querySelectorAll(tabableSelector);
    return elements
      ? _.filter(elements, (element) => $(element).css('display') !== 'none') : elements;
  };

  const focusFirstFocusableElement = function (list) {
    if (list.length > 0) {
      list[0].focus();
      return true;
    }
    return false;
  };

  const focusLastFocusableElement = function (list) {
    if (list.length > 0) {
      list[list.length - 1].focus();
      return true;
    }
    return false;
  };

  const isModalFocused = function (evt) {
    if (evt) {
      return (evt.target || evt.srcElement) === $element[0];
    }
    return false;
  };

  const isFocusInFirstItem = function (evt, list) {
    if (list.length > 0) {
      return (evt.target || evt.srcElement) === list[0];
    }
    return false;
  };

  const isFocusInLastItem = function (evt, list) {
    if (list.length > 0) {
      return (evt.target || evt.srcElement) === list[list.length - 1];
    }
    return false;
  };


  $document.on('keydown', keydownListener);

  $rootScope.$on('$destroy', () => {
    $document.off('keydown', keydownListener);
  });

  function keydownListener(evt) {
    if (evt.isDefaultPrevented()) {
      return evt;
    }


    if (nvCurrentPage.getCurrentStateData().level === 4) {
      switch (evt.which) {
        case 27: {
          evt.preventDefault();
          if (evt.target.nodeName !== 'INPUT') {
            closeL4();
          }

          break;
        }
        case 9: {
          const list = loadFocusElementList();
          let focusChanged = false;
          if (evt.shiftKey) {
            if (isFocusInFirstItem(evt, list) || isModalFocused(evt)) {
              focusChanged = focusLastFocusableElement(list);
            }
          } else if (isFocusInLastItem(evt, list)) {
            focusChanged = focusFirstFocusableElement(list);
          }

          if (focusChanged) {
            evt.preventDefault();
            evt.stopPropagation();
          }

          break;
        }
        default:
          break;
      }
    }
    return null;
  }


  // focus the first element in the modal page

  if (!($document[0].activeElement && $element[0].contains($document[0].activeElement))) {
    const inputWithAutofocus = $element[0].querySelector('[autofocus]');
    /**
         * Auto-focusing of a freshly-opened modal element causes any child elements
         * with the autofocus attribute to lose focus. This is an issue on touch
         * based devices which will show and then hide the onscreen keyboard.
         * Attempts to refocus the autofocus element via JavaScript will not reopen
         * the onscreen keyboard. Fixed by updated the focusing logic to only autofocus
         * the modal element if the modal does not contain an autofocus element.
         */
    if (inputWithAutofocus) {
      inputWithAutofocus.focus();
    } else {
      // THIS DIFFERS FROM UI BOOTSTRAP:
      $timeout(() => {
        $('.modal-page').focus();
      });
    }
  }


  $scope.$on('$destroy', () => {
    if ($rootScope.focusedElementPreL4) {
      $rootScope.focusedElementPreL4.focus();
    }
  });

  $scope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
    // When moving from an L4 page to a different L4
    if (fromState?.data?.level === 4 && toState?.data?.level === 4) {
      // Initialize tab events and set focus for the modal
      $timeout(() => {
        $('.modal-page').focus();
      });
    }
  });

  // END ACCESSIBILITY FIXES

  function setSectionVisible(section, $inview) {
    _this.visibleSections[section] = $inview;
  }

  function setUpHeaderToggle(nodeName, scrollingPropertyToSet, titlePropertyToSet) {
    let node = $(nodeName);

    if (node.length > 1) {
      node = $(node[1]); // i don't know why this sometimes finds 2, but the last one is the valid one
    }

    const debouncedScrollHandler = _.debounce(() => {
      $timeout(() => {
        _this[scrollingPropertyToSet] = node.scrollTop() > 0;

        if (node.find('#expanded-title').length) {
          _this[titlePropertyToSet] = node.find('#expanded-title').offset().top <= 92; // 2 * 60 for the headers - the page title line height
        }

        if (_this[scrollingPropertyToSet]) {
          _this.headerIsExpanded = true;
        }
      });
    }, 20);


    angular.element(nodeName).on('scroll', debouncedScrollHandler);

    $scope.$on('$destroy', () => {
      // console.log('destroying scroll handler for ', nodeName);
      angular.element(nodeName).off('scroll', debouncedScrollHandler);
    });

    $scope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
      angular.element(nodeName).off('scroll', debouncedScrollHandler);

      // Slider Animation for modal. Slider animation get activated when between same states and
      // animateSlide flag is enabled in routes data object.
      // Additionally a stateParam ltr is added to determine the direction of the slide.
      // ltr == 1 => Left to right
      // ltr == empty => Right to left
      // Animating CSS are written in _modals.scss and it will run for 500ms. So removing the animating-modal class after 500ms
      if (toState.name === fromState.name && toState.data.level === 4 && toState.data.animateSlide) {
        if (toParams.ltr === '1') {
          $('body').removeClass('modal-animate-slide-from-left').addClass('modal-animate-slide-from-right').addClass('animating-modal');
        } else {
          $('body').removeClass('modal-animate-slide-from-right').addClass('modal-animate-slide-from-left').addClass('animating-modal');
        }
        setTimeout(() => {
          $('body').removeClass('animating-modal');
        }, 500);
      }
    });
  }

  function resetHeaderData() {
    _this.modalPageContentIsScrolling = false;
    _this.showModalTitleInStickyHeader = false;

    _this.lightboxPageContentIsScrolling = false;
    _this.showLightboxTitleInStickyHeader = false;
  }


  function checkStickyFooterVisible($inview, $inviewpart) {
    _this.socialBarInView = $inview;
    // console.log('sticky footer', $inview, $inviewpart, _this.socialBarInView);
  }


  function checkCommentsRowVisible($inview) {
    _this.commentsRowVisible = $inview;
  }

  $scope.$on('$stateChangeSuccess', () => {
    $timeout(() => {
      if (nvCurrentPage.getCurrentStateData().headerCollapse) {
        _this.lightboxPageContentIsScrolling = true;
        _this.showLightboxTitleInStickyHeader = true;
      } else {
        resetHeaderData();
        // set up sticky headers for L3 and L4 pages
        if (nvCurrentPage.getCurrentStateData().level > 1 && !nvCurrentPage.getCurrentStateData().headerCollapse) {
          setUpHeaderToggle('#modal-content-wrapper', 'modalPageContentIsScrolling', 'showModalTitleInStickyHeader');
          setUpHeaderToggle('.lightbox-panel', 'lightboxPageContentIsScrolling', 'showLightboxTitleInStickyHeader');
        }
      }

      $timeout(() => {
        angular.element(window).trigger('checkInView');
      });
    });
  });

  function closeL4($event) {
    $event?.stopPropagation();

    /**
     * Following variables are just for profile completion activity conditional
     * logic.
     */
    const { profileRequirement } = (CurrentCourseManager?.course || {});
    const isInLearnerProfileModal = $state.current.name === 'learner-profile-modal';
    const isSameUser = CurrentUserManager.user?.id === LearnerProfileManager.profileUser?.id;

    let profileRequirementContainerLecturePage = null;

    if (profileRequirement) {
      profileRequirementContainerLecturePage = LecturePageManager.allLecturePages.find(
        (lecturePage) => lecturePage.id === profileRequirement.lecturePageId,
      );
    }

    if (
      isInLearnerProfileModal
      && profileRequirement
      && isSameUser
      && profileRequirementContainerLecturePage?.released
    ) {
      const reduxState = store.getState();
      const { id: profileRequirementId, novoedQuestions: fromCourseNovoedQuestions } = profileRequirement;
      const reduxProfileRequirement = getProfileRequirement(reduxState, profileRequirementId);
      const { novoedQuestions: reduxNovoedQuestions } = (reduxProfileRequirement || {});
      const requiredNovoedQuestions = reduxNovoedQuestions || fromCourseNovoedQuestions;
      const answeredCourseProfileQuestions = (
        LearnerProfileManager.editableData.questionResponse.answers || []
      );
      const answeredOrgLevelProfileQuestions = (
        LearnerProfileManager.editableData.profileDetails || []
      );

      const remainNovoedQuestions = requiredNovoedQuestions.some(
        (questionIdentifier) => {
          switch (questionIdentifier) {
            case 'profile_image':
              return !CurrentUserManager.user.profilePicture;
            case 'location':
              return !CurrentUserManager.user.displayLocation;
            default:
              /**
               * Default case for novoed questions identifiers that matches
               * properties in current user object and are strings:
               * bio & headline.
               */
              return !CurrentUserManager.user[humps.camelize(questionIdentifier)];
          }
        },
      );

      const remainAccountField = (fields) => fields.filter(
        (field) => field.isRequired,
      ).some((field) => !field.answer);

      const remainCourseProfileQuestions = remainAccountField(answeredCourseProfileQuestions);
      const remainOrgLevelProfileQuestions = remainAccountField(answeredOrgLevelProfileQuestions);

      if (
        remainNovoedQuestions
        || remainCourseProfileQuestions
        || remainOrgLevelProfileQuestions
      ) {
        const modalInstance = ConfirmationOverlays.openConfirmationModal(
          'profile_completion/templates/modal-required-fields.html',
        );

        modalInstance.result.then(() => {
          PageLevelManager.callL4CloseCallbacks();
        });
      } else {
        PageLevelManager.callL4CloseCallbacks();
      }
    } else {
      PageLevelManager.callL4CloseCallbacks();
    }
  }

  return _this;
}
