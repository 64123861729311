import ManageTeamGroup from '../components/manage-team-group';

export default class ManageTeamGroupReactCtrl {
  /* @ngInject */
  constructor(
    $scope,
  ) {
    $scope.ManageTeamGroup = ManageTeamGroup;
  }
}
