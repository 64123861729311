import React, { useCallback, useContext } from 'react';
import t from 'react-translate';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { useSelector } from 'react-redux';

// components
import NvDropdown, { NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { AngularServicesContext } from 'react-app';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { useAppDispatch } from 'redux/store';
import { deleteLecturePage, duplicateLecturePage } from 'redux/actions/lecture-pages';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { getLecturePage } from 'redux/selectors/timeline';
import { setHighlightedLessonId } from 'redux/actions/collections';
import { config } from '../../../../config/config.json';
import { CollectionFolderContext } from '../collection-home/folder';

type LessonActionDropdownProps = {
  lessonId: number
  iconClass: string
  onMenuToggle?: (isOpen: boolean) => void
  setLectureData: Function
  folderId: number
};

export const LessonActionDropdown = (props: LessonActionDropdownProps) => {
  const dispatch = useAppDispatch();
  const params = useLecturePageParams();

  const { $state } = useContext(AngularServicesContext);
  const { addNewLecturePage } = useContext(CollectionFolderContext);
  const lecturePage = useSelector(state => getLecturePage(state, props.lessonId));
  const folderLessonIds = useSelector((state) => state.models.collectionFolders[props.folderId].lecturePages);

  const duplicateLesson = useCallback(() => {
    /**
     * We are showing a copying row disabled state when copying a Lesson. For
     * that we are keeping the copying lesson in the id of Zero. So that we are
     * considering the Id zero as disabled and showing copying text over it.
     */
    const duplicateParam = {
      catalogId: params.catalogId,
      lecturePageId: props.lessonId,
      currentCourseIsCollection: true,
      isFromLessonsList: true,
    };

    dispatch(duplicateLecturePage(duplicateParam)).then((result) => {
      props.setLectureData((prevData) => {
        const prevLectureData = [...prevData];
        const index = prevLectureData.findIndex((id) => id === result.meta.arg.lecturePageId);
        prevLectureData.splice(index + 1, 0, result.payload.id);
        return prevLectureData;
      });
      dispatch(setHighlightedLessonId(result.payload.id));
    });
  }, [dispatch, params.catalogId, props.lessonId]);

  const confirmDelete = useCallback(() => {
    dispatch(openConfirmationDialog({
      title: t.TIMELINE.DELETE_COLLECTION_LESSON.TITLE(),
      bodyText: t.TIMELINE.DELETE_COLLECTION_LESSON.DESCRIPTION(),
      confirmText: t.FORM.YES_SURE(),
      onConfirm: () => {
        const deleteParams = {
          catalogId: params.catalogId,
          lecturePageId: props.lessonId,
          currentCourseIsCollection: true,
        };
        props.setLectureData((prevState) => prevState.filter((eachLectureId) => eachLectureId !== props.lessonId));
        dispatch(deleteLecturePage(deleteParams)).then(result => {
          if (!result.payload) {
            dispatch(addAlertMessage({
              type: AlertMessageType.ERROR,
              header: t.FORM.ERROR_SOMETHING_WRONG(),
            }));
            // roleback the changes when the delete api fail
            props.setLectureData([...folderLessonIds]);
          } else {
            dispatch(addAlertMessage({
              type: AlertMessageType.SUCCESS,
              header: t.FORM.SUCCESS_BANG(),
              message: t.TIMELINE.DELETE_LESSON.SUCCESS_ALERT(lecturePage?.title),
            }));
          }
        });
      },
    }));
  }, [dispatch, folderLessonIds, lecturePage?.title, params.catalogId, props.lessonId]);

  const actionItems: NvDropdownOption[] = [
    {
      type: 'text',
      text: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.LESSON_ACTION.EDIT_LESSON(),
      callback: () => $state.go('lecture-page-edit', { catalogId: params.catalogId, id: props.lessonId }),
      dataQa: config.pendo.contentManagement.editLessonLibrary,
    },
    {
      type: 'text',
      text: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.LESSON_ACTION.COPY(),
      callback: duplicateLesson,
      dataQa: config.pendo.contentManagement.copyLessonLibrary,
    },
    {
      type: 'text',
      text: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.LESSON_ACTION.DELETE(),
      class: 'text-danger',
      callback: () => confirmDelete(),
      disabled: lecturePage?.contentManagementLinks?.length > 0,
      tooltip: {
        text: t.LECTURE_PAGES.ADMIN.COG_OPTIONS.DELETE_TOOLTIP(),
        enabled: lecturePage?.contentManagementLinks?.length > 0,
      },
      dataQa: config.pendo.contentManagement.deleteLessonOption,
    },
    {
      type: 'divider',
    },
    {
      type: 'text',
      text: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.LESSON_ACTION.NEW_LESSON(),
      callback: () => addNewLecturePage(),
      dataQa: config.pendo.contentManagement.createNewCollectionLesson,
    },
  ];

  return (
    <NvDropdown
      buttonStyle={NvDropdownButtonStyle.ICON}
      items={actionItems}
      iconClass={props.iconClass}
      onToggle={props.onMenuToggle}
      toggleDataQa={config.pendo.contentManagement.collectionLessonActionMenu}
      toggleDataQaId={`${config.pendo.contentManagement.collectionLessonActionMenu}_${props.lessonId}`}
    />
  );
};

export default LessonActionDropdown;

