/**
 * This file contains methods which are more like utils/helpers than
 * actual selectors. Need to refactor this and need to find a better
 * home for the following.
 */
import t from 'react-translate';

import { RootState } from 'redux/schemas';
import { ActivityType, ActivityKey } from 'redux/schemas/models/activity';
import { LecturePageType } from 'redux/schemas/models/lecture-page';
import { CourseAliases } from 'redux/schemas/models/course';
import { FeedbackCategory, OwnerType } from 'redux/schemas/models/course-communication';
import { VideoType } from 'redux/schemas/models/video';

import { getActivity } from './course-communications';

export const getLabelForActivity = (activity: ActivityKey, aliases?: CourseAliases): string => {
  switch (activity) {
    case ActivityKey.CUSTOM_QUESTIONS:
      return t.COURSE_COMMUNICATIONS.ACTIVITIES.PEER_EVALUATION();
    case ActivityKey.EXERCISE_SKILLS_RATING:
      return aliases
        ? t.COURSE_COMMUNICATIONS.ACTIVITIES.ASSIGNMENT_SKILLS_FEEDBACK_WITH_ALIAS(aliases.assignmentAliases)
        : t.COURSE_COMMUNICATIONS.ACTIVITIES.ASSIGNMENT_SKILLS_FEEDBACK();
    case ActivityKey.EXERCISES:
      return aliases
        ? aliases.assignmentAliases.AssignmentTitleAlias
        : t.COURSE_COMMUNICATIONS.ACTIVITIES.ASSIGNMENT();
    case ActivityKey.EXTERNAL_TOOLS:
      return t.COURSE_COMMUNICATIONS.ACTIVITIES.EXTERNAL_TOOL();
    case ActivityKey.GROUP_TEAM_SET:
      return t.COURSE_COMMUNICATIONS.ACTIVITIES.GROUP_FORMATION();
    case ActivityKey.LECTURE_VIDEOS:
      return t.COURSE_COMMUNICATIONS.ACTIVITIES.LECTURE_VIDEO();
    case ActivityKey.LIVE_SESSION:
      return t.COURSE_COMMUNICATIONS.ACTIVITIES.LIVE_EVENT();
    case ActivityKey.POLLS:
      return t.COURSE_COMMUNICATIONS.ACTIVITIES.POLL();
    case ActivityKey.PROFILE_REQUIREMENT:
      return t.COURSE_COMMUNICATIONS.ACTIVITIES.PROFILE_COMPLETION();
    case ActivityKey.PROGRESSIVE_QUIZZES:
      return t.COURSE_COMMUNICATIONS.ACTIVITIES.PROGRESSIVE_QUIZ();
    case ActivityKey.QUIZZES:
      return t.COURSE_COMMUNICATIONS.ACTIVITIES.QUIZ();
    case ActivityKey.SURVEYS:
      return t.COURSE_COMMUNICATIONS.ACTIVITIES.SURVEY();
    case ActivityKey.TEAM_DISCUSSION:
      return t.COURSE_COMMUNICATIONS.ACTIVITIES.TEAM_DISCUSSION();
    case ActivityKey.TEAM_SET:
      return t.COURSE_COMMUNICATIONS.ACTIVITIES.TEAM_FORMATION();
    case ActivityKey.TIMED_QUIZZES:
      return t.COURSE_COMMUNICATIONS.ACTIVITIES.TIMED_EXAM();
    case ActivityKey.TOPICS:
      return t.COURSE_COMMUNICATIONS.ACTIVITIES.DISCUSSION();
    case ActivityKey.VIDEO_PRACTICE_FEEDBACK:
      return t.COURSE_COMMUNICATIONS.ACTIVITIES.PRACTICE_FEEDBACK();
    case ActivityKey.VIDEO_PRACTICE_SKILLS_FEEDBACK:
      return t.COURSE_COMMUNICATIONS.ACTIVITIES.PRACTICE_SKILLS_RATING();
    case ActivityKey.VIDEO_PRACTICES:
      return t.COURSE_COMMUNICATIONS.ACTIVITIES.PRACTICE();
    default:
      return undefined;
  }
};

export const getKeyForActivityType = (type: OwnerType, category?: FeedbackCategory | ActivityKey): ActivityKey => {
  switch (type) {
    case ActivityType.EXERCISE_SKILLS_RATING:
      return (category === FeedbackCategory.VIDEO_PRACTICE_SKILLS_FEEDBACK || category === ActivityKey.VIDEO_PRACTICE_SKILLS_FEEDBACK)
        ? ActivityKey.VIDEO_PRACTICE_SKILLS_FEEDBACK
        : ActivityKey.EXERCISE_SKILLS_RATING;
    case ActivityType.EXERCISE:
      return ActivityKey.EXERCISES;
    case ActivityType.EXTERNAL_TOOL:
      return ActivityKey.EXTERNAL_TOOLS;
    case ActivityType.GROUP_FORMATION:
      return ActivityKey.GROUP_TEAM_SET;
    case ActivityType.LECTURE_VIDEO:
      return ActivityKey.LECTURE_VIDEOS;
    case ActivityType.LIVE_SESSION:
      return ActivityKey.LIVE_SESSION;
    case ActivityType.PEER_EVALUATION:
      return ActivityKey.CUSTOM_QUESTIONS;
    case ActivityType.POLL:
      return ActivityKey.POLLS;
    case ActivityType.POST:
      return ActivityKey.TOPICS;
    case ActivityType.PROFILE_REQUIREMENT:
      return ActivityKey.PROFILE_REQUIREMENT;
    case ActivityType.PROGRESSIVE_QUIZ:
      return ActivityKey.PROGRESSIVE_QUIZZES;
    case ActivityType.QUIZ:
      return ActivityKey.QUIZZES;
    case ActivityType.SURVEY:
      return ActivityKey.SURVEYS;
    case ActivityType.TEAM_DISCUSSION:
      return ActivityKey.TEAM_DISCUSSION;
    case ActivityType.TEAM_FORMATION:
      return ActivityKey.TEAM_SET;
    case ActivityType.TIMED_EXAM:
      return ActivityKey.TIMED_QUIZZES;
    case ActivityType.VIDEO_PRACTICE_FEEDBACK:
      return ActivityKey.VIDEO_PRACTICE_FEEDBACK;
    case ActivityType.VIDEO_PRACTICE_SKILLS_FEEDBACK:
      return ActivityKey.VIDEO_PRACTICE_SKILLS_FEEDBACK;
    case ActivityType.VIDEO_PRACTICE:
      return ActivityKey.VIDEO_PRACTICES;
    default:
      return undefined;
  }
};

export const getActivityTypeForKey = (key: ActivityKey, category?: FeedbackCategory): ActivityType => {
  switch (key) {
    case ActivityKey.CUSTOM_QUESTIONS:
      return ActivityType.PEER_EVALUATION;
    case ActivityKey.EXERCISE_SKILLS_RATING:
      return category === FeedbackCategory.VIDEO_PRACTICE_SKILLS_FEEDBACK
        ? ActivityType.VIDEO_PRACTICE_SKILLS_FEEDBACK
        : ActivityType.EXERCISE_SKILLS_RATING;
    case ActivityKey.EXERCISES:
        return ActivityType.EXERCISE;
    case ActivityKey.EXTERNAL_TOOLS:
      return ActivityType.EXTERNAL_TOOL;
    case ActivityKey.GROUP_TEAM_SET:
      return ActivityType.GROUP_FORMATION;
    case ActivityKey.LECTURE_VIDEOS:
      return ActivityType.LECTURE_VIDEO;
    case ActivityKey.LIVE_SESSION:
      return ActivityType.LIVE_SESSION;
    case ActivityKey.POLLS:
      return ActivityType.POLL;
    case ActivityKey.PROFILE_REQUIREMENT:
      return ActivityType.PROFILE_REQUIREMENT;
    case ActivityKey.PROGRESSIVE_QUIZZES:
      return ActivityType.PROGRESSIVE_QUIZ;
    case ActivityKey.QUIZZES:
      return ActivityType.QUIZ;
    case ActivityKey.SURVEYS:
      return ActivityType.SURVEY;
    case ActivityKey.TEAM_DISCUSSION:
      return ActivityType.TEAM_DISCUSSION;
    case ActivityKey.TEAM_SET:
      return ActivityType.TEAM_FORMATION;
    case ActivityKey.TIMED_QUIZZES:
      return ActivityType.TIMED_EXAM;
    case ActivityKey.TOPICS:
      return ActivityType.POST;
    case ActivityKey.VIDEO_PRACTICE_FEEDBACK:
      return ActivityType.VIDEO_PRACTICE_FEEDBACK;
    case ActivityKey.VIDEO_PRACTICE_SKILLS_FEEDBACK:
      return ActivityType.VIDEO_PRACTICE_SKILLS_FEEDBACK;
    case ActivityKey.VIDEO_PRACTICES:
      return ActivityType.VIDEO_PRACTICE;
    default:
      return null;
  }
};

export const getVideoType = (state: RootState, id: number) => {
  const lectureVideo = state.models.lectureVideos[id];

  return state.models.video[lectureVideo?.video]?.type;
};

/**
 * Find a prefix for the given activity type and prefix it
 * with the activity's actual title
 *
 * @returns {string} i18n Prefixed Title
 */
export const getPrefixedTitle = (
  state: RootState,
  type: OwnerType,
  id: number,
  aliases: CourseAliases,
  category?: FeedbackCategory,
) => {
  if (
    category === FeedbackCategory.VIDEO_PRACTICE_SKILLS_FEEDBACK
    || category === FeedbackCategory.ASSIGNMENT_SKILLS_FEEDBACK
    || type === FeedbackCategory.ASSIGNMENT_SKILLS_FEEDBACK as any
  ) {
    type = ActivityType.EXERCISE_SKILLS_RATING;
  }
  if (!type || !id) {
    return null;
  }

  const activity = getActivity(state, type, id);
  if (!activity) {
    return null;
  }

  let prefix;
  if (!category && 'category' in activity) {
    const { category: activityCategory }: { category: any } = activity;
    category = activityCategory;
  }
  switch (type) {
    case ActivityType.EXERCISE:
      prefix = aliases.assignmentAliases.AssignmentTitleAlias;
      break;
    case ActivityType.EXERCISE_SKILLS_RATING:
      prefix = category === FeedbackCategory.VIDEO_PRACTICE_SKILLS_FEEDBACK
        ? t.COURSE_COMMUNICATIONS.ACTIVITIES.PRACTICE_SKILLS_RATING()
        : t.COURSE_COMMUNICATIONS.ACTIVITIES.ASSIGNMENT_SKILLS_FEEDBACK_WITH_ALIAS(aliases.assignmentAliases);
      break;
    case ActivityType.EXTERNAL_TOOL:
      prefix = t.COURSE_COMMUNICATIONS.ACTIVITIES.EXTERNAL_TOOL();
      break;
    case ActivityType.GROUP_FORMATION:
      prefix = t.COURSE_COMMUNICATIONS.ACTIVITIES.GROUP_FORMATION();
      break;
    case ActivityType.LECTURE_VIDEO:
      // For video check video or audio
      prefix = getVideoType(state, id) === VideoType.AUDIO
        ? t.COURSE_COMMUNICATIONS.ACTIVITIES.AUDIO()
        : t.COURSE_COMMUNICATIONS.ACTIVITIES.VIDEO();
      break;
    case ActivityType.LIVE_SESSION:
      prefix = t.COURSE_COMMUNICATIONS.ACTIVITIES.LIVE_EVENT();
      break;
    case ActivityType.PEER_EVALUATION:
      prefix = t.COURSE_COMMUNICATIONS.ACTIVITIES.PEER_EVALUATION();
      break;
    case ActivityType.POLL:
      prefix = t.COURSE_COMMUNICATIONS.ACTIVITIES.POLL();
      break;
    case ActivityType.POST:
      prefix = t.COURSE_COMMUNICATIONS.ACTIVITIES.DISCUSSION();
      break;
    case ActivityType.PROFILE_REQUIREMENT:
      prefix = t.COURSE_COMMUNICATIONS.ACTIVITIES.PROFILE_COMPLETION();
      break;
    case ActivityType.PROGRESSIVE_QUIZ:
      prefix = t.COURSE_COMMUNICATIONS.ACTIVITIES.PROGRESSIVE_QUIZ();
      break;
    case ActivityType.QUIZ:
      prefix = t.COURSE_COMMUNICATIONS.ACTIVITIES.QUIZ();
      break;
    case ActivityType.SURVEY:
      prefix = t.COURSE_COMMUNICATIONS.ACTIVITIES.SURVEY();
      break;
    case ActivityType.TEAM_DISCUSSION:
      prefix = t.COURSE_COMMUNICATIONS.ACTIVITIES.TEAM_DISCUSSION();
      break;
    case ActivityType.TEAM_FORMATION:
      prefix = t.COURSE_COMMUNICATIONS.ACTIVITIES.TEAM_FORMATION();
      break;
    case ActivityType.TIMED_EXAM:
      prefix = t.COURSE_COMMUNICATIONS.ACTIVITIES.TIMED_EXAM();
      break;
    case ActivityType.VIDEO_PRACTICE_FEEDBACK:
      prefix = t.COURSE_COMMUNICATIONS.ACTIVITIES.PRACTICE_FEEDBACK();
      break;
    case ActivityType.VIDEO_PRACTICE:
      prefix = t.COURSE_COMMUNICATIONS.ACTIVITIES.PRACTICE();
      break;
    case LecturePageType.LECTURE:
      prefix = aliases.lectureAliases.LectureAlias;

      break;
    default:
      prefix = null;
      break;
  }

  return prefix
    ? t.COURSE_COMMUNICATIONS.ACTIVITY_TITLE(prefix, activity.title)
    : activity.title;
};
