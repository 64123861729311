import ColorIconButton from 'components/color-icon-button';
import ColorPickerPopover, { ColorPickerPopoverProps } from 'components/color-picker-popover';
import { useState } from 'react';
import _ from 'underscore';
import t from 'react-translate';

type HeaderColorValuesMap = {
  textColor: string,
  backgroundColor?: string,
  leftBorderColor?: string,
};

type Props = Pick<ColorPickerPopoverProps<HeaderColorValuesMap>, 'onChange' | 'colorValues'>;

/** Wraps the ColorPickerPopover for use in a header lecture component text area.  */
const HeaderColorPickerPopover = (props: Props) => {
  const { colorValues, onChange } = props;
  const [showColorPicker, setShowColorPicker] = useState(false);

  const hasBackgroundColor = _.has(colorValues, 'backgroundColor');
  const hasBorderColor = _.has(colorValues, 'leftBorderColor');

  return (
    <ColorPickerPopover<HeaderColorValuesMap>
      show={showColorPicker}
      sections={[{
        name: 'textColor' as const,
        title: t.LECTURE_PAGES.COMPONENTS.HEADER.TEXT_COLOR(),
      }, (hasBackgroundColor && {
        name: 'backgroundColor' as const,
        title: t.LECTURE_PAGES.COMPONENTS.HEADER.BACKGROUND_COLOR(),
      }), (hasBorderColor && {
        name: 'leftBorderColor' as const,
        title: t.LECTURE_PAGES.COMPONENTS.HEADER.BORDER_COLOR(),
      })].filter(Boolean)}
      placement='top-end'
      onChange={onChange}
      colorValues={colorValues}
      onToggle={(nextShow) => { setShowColorPicker(nextShow); }}
    >
      <div className={`icon-btn-container ${showColorPicker ? 'picker-visible' : ''}`}>
        <ColorIconButton tooltip={!showColorPicker && 'Edit Colors for this Header'} />
      </div>
    </ColorPickerPopover>
  );
};

export default HeaderColorPickerPopover;
