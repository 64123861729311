/* @ngInject */
export default function StatementCtrl(
  StatementsManager,
  CurrentUserManager,
  CurrentCourseManager,
  RailsRoutes,
  $stateParams,
  $scope,
  $uibModal,
  _,
  $window,
  $timeout,
  $state,
  config,
) {
  const vm = this;
  vm.StatementsManager = StatementsManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.RailsRoutes = RailsRoutes;
  vm.$stateParams = $stateParams;
  vm.course = vm.CurrentUserManager.coursesHashByCatalogId[$stateParams.catalogId] || CurrentCourseManager.course;
  vm.config = config;

  vm.openShareModal = function openShareModal() {
    $uibModal.open({
      templateUrl: 'statements/templates/share.html',
      controller: 'AttachModalResolvesToVmCtrl as vm',
      resolve: {
        vmResolves() {
          return {
            course: vm.course,
            StatementsManager: vm.StatementsManager,
          };
        },
      },
    });
  };

  vm.openMakePublicModal = function openMakePublicModal() {
    const modalInstance = $uibModal.open({
      backdropClass: 'modal-overlay-backdrop',
      windowClass: 'modal-overlay',
      templateUrl: 'statements/templates/make-public.html',
      controller: 'AttachModalResolvesToVmCtrl as vm',
      resolve: {
        vmResolves() {
          return {
            config: vm.config,
            course: vm.course,
            StatementsManager: vm.StatementsManager,
          };
        },
      },
    });

    modalInstance.result.then(() => {
      StatementsManager.makePublic($stateParams.catalogId);
      $timeout(vm.openShareModal);
    }, () => {
    });
  };
}
