export default {
  bindings: {
    user: '<',
    rsvpStatus: '@', // ['attending', 'notAttending', 'mayAttend']
    includeUserName: '<',
    includeSpacing: '<',
    size: '@',
  },
  controller: function ctrl() {
'ngInject';
  },
  controllerAs: 'vm',
  templateUrl: 'team_workspace/templates/team-workspace-avatar.html',
};
