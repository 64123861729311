import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { FetchProvidersParams, LoginParams, DomainParams } from 'redux/schemas/api/sign-in';

const successHelper = (response) => response.data.result;
const errorHelper = (error) => error.response || { status: 500 };

const fetchLoginProviders = (params: FetchProvidersParams) => {
  const endpoint = '/providers.json';
  const request = axios.get(endpoint, { params });
  return request.then(successHelper).catch(errorHelper);
};

const signIn = (user: LoginParams) => {
  const endpoint = '/users/sign_in.json';
  const params = { user };
  const request = axios.post(endpoint, params);
  return request.then(successHelper).catch(errorHelper);
};

const validateDomain = (params: DomainParams) => {
  const endpoint = '/lookup_domain.json';
  const request = axios.post(endpoint, params);
  return request.then(successHelper).catch(errorHelper);
};

export const getLoginProviders = createAsyncThunk(
  'FETCH_LOGIN_PROVIDERS',
  fetchLoginProviders,
);

export const doSignIn = createAsyncThunk<unknown, LoginParams>(
  'SIGN_IN',
  signIn,
);

export const doValidateDomain = createAsyncThunk<unknown, DomainParams>(
  'VALIDATE_DOMAIN',
  validateDomain,
);
