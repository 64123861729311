import React, { useRef, useState } from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import { first, last } from 'underscore';

import { useAppDispatch } from 'redux/store';
import { getCommentsForSubmission } from 'redux/actions/comments';
import { getCurrentUser } from 'redux/selectors/users';
import { BaseUser } from 'redux/schemas/models/my-account';
import { ExerciseSubmissionState } from 'redux/schemas/models/exercise';

import NvUserAvatar from 'components/nv-user-avatar';
import ClickableContainer from 'components/clickable-container';
import CommentSection from './comment-section';
import CommentForm from './comment-form';
import { config } from '../../../../config/pendo.config.json';
import LoadingWrapper, { LoaderType } from '../loading-wrapper';

type CommentsContainerProps = {
  catalogId: string,
  commentIds: number[],
  ownerType: string,
  ownerId: number,
  mentionableUsers: BaseUser[],
  appSubmissionState: ExerciseSubmissionState[number],
};

type CommentsContainerContextValues = {
  catalogId: string,
  ownerType: string,
  ownerId: number,
  mentionableUsers: BaseUser[],
};

export const CommentsContainerContext = React.createContext<CommentsContainerContextValues>(null);

const CommentsContainer = ({
  catalogId,
  commentIds,
  ownerType,
  ownerId,
  mentionableUsers,
  appSubmissionState,
}: CommentsContainerProps) => {
  const commentFormRef = useRef(null);
  const dispatch = useAppDispatch();

  const styles = css`
    .comment-avatar {
      flex-shrink: 0;
      width: 80px;
    }
  `;

  const [loadingPreviousComments, setLoadingPreviousComments] = useState(false);
  const [loadingNextComments, setLoadingNextComments] = useState(false);
  const [showCommentForm, setShowCommentForm] = useState(false);

  const currentUser = useSelector((state) => getCurrentUser(state));

  const onShowCommentForm = () => {
    setShowCommentForm(true);
    setTimeout(() => {
      commentFormRef.current?.focus();
    });
  };

  const onLoadPreviousComments = () => {
    setLoadingPreviousComments(true);
    dispatch(getCommentsForSubmission({
      catalogId,
      reportId: ownerId,
      beforeId: first(commentIds),
      pageSize: 50,
    })).then(() => {
      setLoadingPreviousComments(false);
    });
  };

  const onLoadNextComments = () => {
    setLoadingNextComments(true);
    dispatch(getCommentsForSubmission({
      catalogId,
      reportId: ownerId,
      afterId: last(commentIds),
      pageSize: 50,
    })).then(() => {
      setLoadingNextComments(false);
    });
  };

  return (
    <CommentsContainerContext.Provider
      value={{
        catalogId,
        ownerType,
        ownerId,
        mentionableUsers,
      }}
    >
      <div css={styles} className='px-4'>
        {appSubmissionState?.additionalCommentsBeforeCount > 0 && (
          <LoadingWrapper
            isLoaded={!loadingPreviousComments}
            loaderType={LoaderType.PLACEHOLDER}
          >
            <ClickableContainer
              className='text-small text-primary mb-5 font-weight-bold'
              onClick={onLoadPreviousComments}
            >
              {appSubmissionState.additionalNewCommentsBeforeCount > 0
                ? t.DISCUSSIONS.LOAD_PREVIOUS_COMMENTS_WITH_NEW(appSubmissionState.additionalCommentsBeforeCount, appSubmissionState.additionalNewCommentsBeforeCount)
                : t.DISCUSSIONS.LOAD_PREVIOUS_COMMENTS(appSubmissionState.additionalCommentsBeforeCount)}
            </ClickableContainer>
          </LoadingWrapper>
        )}

        <LoadingWrapper
          isLoaded={appSubmissionState?.commentsFetched}
          loaderType={LoaderType.PLACEHOLDER}
        >
          {commentIds?.map(id => (
            <CommentSection
              key={`comment-row-${id}`}
              commentId={id}
            />
          ))}
        </LoadingWrapper>
        {appSubmissionState?.additionalCommentsAfterCount > 0 && (
          <LoadingWrapper
            isLoaded={!loadingNextComments}
            loaderType={LoaderType.PLACEHOLDER}
          >
            <ClickableContainer
              className='text-small text-primary mb-5 font-weight-bold'
              onClick={onLoadNextComments}
            >
              {appSubmissionState.additionalNewCommentsAfterCount > 0
                ? t.DISCUSSIONS.LOAD_MORE_COMMENTS_WITH_NEW(appSubmissionState.additionalCommentsBeforeCount, appSubmissionState.additionalNewCommentsAfterCount)
                : t.DISCUSSIONS.LOAD_MORE_COMMENTS(appSubmissionState.additionalCommentsAfterCount)}
            </ClickableContainer>
          </LoadingWrapper>
        )}
        <div className={`py-5 ${commentIds?.length > 0 ? 'border-top' : ''}`}>
          {showCommentForm
            ? (
              <CommentForm
                ref={commentFormRef}
                catalogId={catalogId}
                ownerType={ownerType}
                ownerId={ownerId}
                onComment={() => setShowCommentForm(false)}
                onCancel={() => setShowCommentForm(false)}
              />
            )
            : (
              <div className='d-flex'>
                <NvUserAvatar
                  displayName
                  displayRoleBadge
                  alignNameRight={false}
                  user={currentUser}
                  size='sm'
                  borderType='round'
                  className='comment-avatar'
                  data-qa={config.pendo.userProfiles.openProfile}
                />
                <ClickableContainer className='fake-text-input form-control' onClick={onShowCommentForm}>
                  {t.DISCUSSIONS.POST_COMMENT()}
                </ClickableContainer>
              </div>
            )}
        </div>
      </div>
    </CommentsContainerContext.Provider>
  );
};

export default CommentsContainer;
