import { css } from '@emotion/react';
import t from 'react-translate';

import { Placement } from 'react-bootstrap/Overlay';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';

import { primary } from 'styles/global_defaults/colors';
import { getCurrentCourse } from 'redux/selectors/course';
import { useSelector } from 'react-redux';

type BookmarkIconState = 'default' | 'onHover' | 'bookmarked';

type BookmarkIconProps = {
  bookmarked: boolean;
  onCreate: () => void;
  onHighlight: () => void;
  size: string;
  tooltipDisabled?: boolean;
  tooltipPlacement?: Placement;
  createTooltip?: string;
  altText?: string;
  colors?: Partial<Record<BookmarkIconState, string>>;
  tooltipZIndex?: number;
};

const BookmarkIcon = ({
  bookmarked,
  onCreate,
  onHighlight,
  size,
  tooltipDisabled,
  tooltipPlacement = 'top',
  createTooltip = t.LHS.BOOKMARKS.ADD_BOOKMARK.ADD(),
  altText,
  colors,
  tooltipZIndex,
}: BookmarkIconProps) => {
  const styles = css`
    &.bookmark-icon {
      .icon-bookmark {
        color: ${colors?.default ?? 'black'};
      }

      .icon-bookmarked {
        color: ${colors?.bookmarked ?? primary}
      }

      &:hover {
        cursor: pointer;
        color: ${colors?.onHover ?? primary};
      }
    }
  `;
  const currentCourse = useSelector(getCurrentCourse);

  if (!bookmarked && !currentCourse?.isContentManagementCollection) {
    return (
      <NvTooltip
        text={createTooltip}
        zIndex={tooltipZIndex}
        enabled={!tooltipDisabled}
        placement={tooltipPlacement}
      >
        <div
          css={styles}
          className='bookmark-icon show-on-hover removess'
        >
          <NvIcon icon='bookmark' size={size} onClick={onCreate} altLabel={altText ?? t.LHS.BOOKMARKS.ADD_BOOKMARK.ADD()} />
        </div>
      </NvTooltip>
    );
  }

  if (bookmarked && !currentCourse.isContentManagementCollection) {
    return (
      <NvTooltip
        zIndex={tooltipZIndex}
        enabled={!tooltipDisabled}
        placement={tooltipPlacement}
        text={t.LHS.BOOKMARKS.OPEN_BOOKMARK()}
      >
        <div css={styles} className='bookmark-icon addss'>
          <NvIcon icon='bookmarked' size={size} onClick={onHighlight} altLabel={t.LHS.BOOKMARKS.OPEN_BOOKMARK()} />
        </div>
      </NvTooltip>
    );
  }

  return null;
};

export default BookmarkIcon;
