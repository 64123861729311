/* @ngInject */
export default function TeamWorkspaceShareSubmissionModalInstanceController(
  $uibModalInstance,
  StateManager,
  ConfirmationOverlays,
  TeamWorkspaceManager,
  CurrentUserManager,
  existingSubmissionPost,
  $scope,
  _,
  MentionablesModel,
  CurrentCourseManager,
  AlertMessages,
  ReportsResources,
  $state,
) {
  const vm = this;

  initialize();

  function initialize() {
    vm.loading = true;

    vm.MentionablesModel = MentionablesModel;
    vm.TeamWorkspaceManager = TeamWorkspaceManager;
    vm.CurrentUserManager = CurrentUserManager;
    vm.existingSubmissionPost = existingSubmissionPost;


    vm.submitting = false;
    vm.dismissedByUser = false;

    vm.toggleSelectedSubmission = toggleSelectedSubmission;
    vm.shareSubmission = shareSubmission;
    vm.hasUnsavedChanges = hasUnsavedChanges;
    vm.isShareable = isShareable;
    vm.catalogId = $state.params.catalogId || CurrentCourseManager.course.catalogId;


    // this should be somewhere else
    return ReportsResources.getAllSubmissionsForUserInCourse({
      catalogId: vm.catalogId,
      userId: vm.existingSubmissionPost?.user.id ?? vm.CurrentUserManager.user.id,
    }).$promise.then((response) => {
      vm.userSubmissions = response.result;

      vm.selectedSubmission = existingSubmissionPost ? _.findWhere(vm.userSubmissions, { id: existingSubmissionPost.metaContent.id }) : null;
      vm.body = existingSubmissionPost ? existingSubmissionPost.body : '';
      vm.submissionNoLongerShareable = vm.selectedSubmission && !vm.isShareable(vm.selectedSubmission);

      vm.loading = false;
    });
  }

  function toggleSelectedSubmission(submission) {
    if (isShareable(submission)) {
      if (vm.selectedSubmission?.id === submission.id) {
        vm.selectedSubmission = null;
      } else {
        vm.selectedSubmission = submission;
      }
    }
  }

  function isShareable(submission) {
    return TeamWorkspaceManager.isSubmissionPostShareable(submission);
  }

  function shareSubmission() {
    vm.submitting = true;

    if (existingSubmissionPost) {
      // editing
      TeamWorkspaceManager.updatePost(
        _.extend(
          _.pick(existingSubmissionPost, 'body', 'metaContent', 'id'), {
            body: vm.body,
            metaContent: {
              type: 'Report',
              id: vm.selectedSubmission.id,
            },
          },
        ),
      ).then(() => {
        vm.submitting = false;
        vm.dismissedByUser = true;
        $uibModalInstance.close();
      }).catch(
        (error) => {
          AlertMessages.error('', 'FORM.ERROR');
        },
      );
    } else {
      TeamWorkspaceManager.createSubmissionPost({
        body: vm.body || '',
        title: '',
        metaContent: {
          type: 'Report',
          id: vm.selectedSubmission.id,
        },
      }).then(() => {
        vm.submitting = false;
        vm.dismissedByUser = true;
        $uibModalInstance.close();
      }).catch(
        (error) => {
          AlertMessages.error('', 'FORM.ERROR');
        },
      );
    }
  }

  function hasUnsavedChanges() {
    return (!vm.existingSubmissionPost && (vm.body.length || vm.selectedSubmission))
      || (vm.selectedSubmission && (vm.selectedSubmission.id !== vm.existingSubmissionPost.metaContent.id))
        || (vm.existingSubmissionPost && (vm.existingSubmissionPost.body !== vm.body));
  }


  // - unsaved changes overlay
  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(
    () => vm.hasUnsavedChanges() && !vm.dismissedByUser,
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    vm.unsavedChangesMessage,
  );

  $scope.$on('modal.closing', ($event) => {
    if (vm.hasUnsavedChanges() && !vm.dismissedByUser) {
      $event.preventDefault();


      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        vm.dismissedByUser = true;
        $uibModalInstance.dismiss();
      });
    }
  });

  $scope.$on('$destroy', () => {
    vm.deregisterStateChangeStart?.();
  });
}
