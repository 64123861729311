
import { css, jsx } from '@emotion/react';
import ClickableContainer from 'components/clickable-container';
import { useLectureComponentContext } from 'components/lecture-component-provider';
import Reorder from 'components/reorderer';
import React from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { createPollOption, deletePollOption, reorderPollOptions } from 'redux/actions/polls';
import { PollQuestion } from 'redux/schemas/models/poll';
import { getLecturePage } from 'redux/selectors/timeline';
import { useAppDispatch } from 'redux/store';
import NvIcon from 'shared/components/nv-icon';
import { primary } from 'styles/global_defaults/colors';
import { halfSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import moment from 'moment';
import { getCurrentCourse } from 'redux/selectors/course';
import EditPollOption from './edit-poll-option';

interface EditPollOptionsProps {
  question: PollQuestion;
}

/* @ngInject */
export default function EditPollOptions(props: EditPollOptionsProps) {
  const dispatch = useAppDispatch();
  const { catalogId, lecturePageId } = useLectureComponentContext();
  const lecturePage = useSelector((state) => getLecturePage(state, lecturePageId));
  // creates a dictionary indexed by option ID and contains the option current
  // index and previous index in order to track when the option is moved up or
  // down so that we can animate the option transition
  const [indexChanges, setIndexChanges] = React.useState<{
    [key: number]: { previousIndex: number; currentIndex: number };
  }>({});

  const currentCourse = useSelector(getCurrentCourse);

  // local copy to update synchronously with `indexChanges`
  const [localResponseOptions, setLocalResponseOptions] = React.useState(props.question.responseOptions);

  React.useEffect(() => {
    setLocalResponseOptions(props.question.responseOptions);
  }, [props.question.responseOptions]);

  return (
    <React.Fragment>
      <Reorder
        items={localResponseOptions}
        keyExtractor={(option) => option.id}
        onChange={(payload, swapIndex) => {
          const elementA = localResponseOptions[swapIndex];
          const elementB = localResponseOptions[swapIndex + 1];
          dispatch(
            reorderPollOptions({
              catalogId,
              questionId: props.question.id,
              order: payload.map((option) => option.id),
            }),
          );

          setIndexChanges((prevList) => ({
            ...prevList,
            [elementA.id]: {
              previousIndex: swapIndex,
              currentIndex: swapIndex + 1,
            },
            [elementB.id]: {
              previousIndex: swapIndex + 1,
              currentIndex: swapIndex,
            },
          }));
          setLocalResponseOptions(payload);
        }}
        renderItem={({ item, index, canMoveUp, canMoveDown, moveUp, moveDown }) => (
          <div css={containerStyle} key={item.id}>
            <EditPollOption
              indexChange={indexChanges[item.id]}
              catalogId={catalogId}
              canDelete={currentCourse.isContentManagementCollection || moment(lecturePage.releaseDate).isAfter()}
              option={item}
              index={index}
              canMoveUp={canMoveUp}
              canMoveDown={canMoveDown}
              onMoveDownClick={moveDown}
              onMoveUpClick={moveUp}
              onDeleteClick={() => {
                dispatch(
                  deletePollOption({
                    catalogId,
                    responseOptionId: item.id,
                    questionId: props.question.id,
                  }),
                );
              }}
            />
          </div>
        )}
      />
      <ClickableContainer
        css={addButtonStyle}
        onClick={() => {
          dispatch(
            createPollOption({
              catalogId,
              questionId: props.question.id,
            }),
          );
        }}
      >
        <NvIcon size='smallest' icon='add' />
        {' '}
        {t.LECTURE_PAGES.COMPONENTS.POLLS.ADD_OPTION()}
      </ClickableContainer>
    </React.Fragment>
  );
}

const containerStyle = css`
  margin-bottom: ${halfSpacing}px;
`;

const addButtonStyle = css`
  display: inline-flex;
  align-items: center;
  color: ${primary};
  padding: ${quarterSpacing}px 0;

  i {
    margin-right: ${halfSpacing}px;
  }
`;
