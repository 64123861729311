/* @ngInject */
export default function NvExerciseStatus() {
  return {
    scope: {
      exercise: '=',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'exercises/templates/nv-exercise-status.html',
  };
}
