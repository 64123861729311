import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { useHistory } from 'react-router-dom';
import createNewComponent, { AddComponentCallback } from 'lecture_pages/components/left-panel/create-new-component';
import { ComponentType } from 'redux/schemas/models/lecture-component';
import { TopicPayload } from 'redux/schemas/models/activity';
import { NovoAIItemType } from 'redux/schemas/models/lecture-page';
import { AngularServicesContext } from 'react-app';
import { useContext } from 'react';
import { ComponentMetadata } from 'lecture_pages/components/data';
import { merge } from 'lodash';
import { updateLectureComponent } from 'redux/actions/lecture-pages';

/**
 * This hook provides the functions to create a new lecture component or edit it in the context of a lecture page
 */
export const useCreateLectureComponent = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const params = useLecturePageParams();
  const angularServices = useContext(AngularServicesContext);
  const { catalogId, lecturePageId: lectureId } = useLecturePageParams();
  const newComponentIndex = useSelector(state => state.app.lecturePage.newComponentIndex);

  const fireCreateNewComponent: AddComponentCallback = (componentType, metadata, modelData) => {
    // Setting the aiProperties of the lecture component if the process comes from the traditional workflow
    switch (componentType) {
      case ComponentType.TOPIC:
        if ((modelData as TopicPayload).topic?.aiOrigin === NovoAIItemType.DISCUSSION) {
          const aiProperties = {
            aiAction: NovoAIItemType.DISCUSSION,
          };

          modelData = { ...modelData, aiProperties };
        }
        break;

      default:
        break;
    }

    return createNewComponent(
      newComponentIndex,
      catalogId,
      lectureId,
      componentType,
      metadata,
      dispatch,
      history,
      modelData,
    );
  };

  // Moving the 'doUpdateLectureComponent' from the 'BaseLectureComponent' to centralize the update logic.
  // TODO: Check if the componentType param can be deleted
  const fireUpdateComponent = (editedLectureComponent, componentType, lectureComponent, metadata: ComponentMetadata) => {
    // Manually call the angularjs comonent model's save() function if this was rendered via angular.
    // TODO: This is a shortcut to distinguish between how the angularjs & non-angularjs using workflow modals work. This is really convoluted, could use heavy refactoring
    if (metadata.workflow.type === 'modal' && !!metadata.workflow.initialSettings.angularConfig) {
      const angularComponent = angularServices.$scope[`lectureComponent${lectureComponent.id}`];
      // This effectively saves the data from this components getPayload() as given to the onConfirm() in angular-modal-content into the angularjs object itself.
      // TODO: Kind of circuitous, could be simplified
      merge(angularComponent, editedLectureComponent);

      // This *must* refer to the same model object as used by AngularModalContent. These should be the same, but be on the lookout for data sync issues where a different object exists in the angular modal
      return angularComponent.save(true);
    }

    return dispatch(updateLectureComponent({
      catalogId: params.catalogId,
      lecturePageId: params.lecturePageId,
      componentData: {
        ...lectureComponent,
        ...editedLectureComponent,
      },
      showSavingOverlay: true,
    }));
  };

  return { fireCreateNewComponent, fireUpdateComponent };
};

export default useCreateLectureComponent;
