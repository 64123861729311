/* @ngInject */
export default function QuizAnswerFeedbackController(
  $state,
  $stateParams,
  _,
  ExercisesManager,
  QuizzesManager,
) {
  let getQuizPromise = null;
  const vm = this;

  vm.ExercisesManager = ExercisesManager;
  vm.manager = QuizzesManager;
  vm.$stateParams = $stateParams;

  vm.quizForm = null;
  vm.deregisterStateChangeStart = null;

  getQuizPromise = vm.manager.getQuiz($stateParams.catalogId, $stateParams.questionSetId, $stateParams.exerciseId);

  // lecture page feedback is shown inline and doesn't hit this controller
  if ($state.is('quiz-feedback-modal') || $state.is('quiz-feedback-page')) {
    getQuizPromise.then(() => {
      const submission = _.first(ExercisesManager.currentExercise.submissions);

      if (submission) {
        QuizzesManager.setCurrentSubmission(submission);
      } else {
        $state.go('quiz-new');
      }
    });
  }
}
