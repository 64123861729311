import React from 'react';
import { css } from '@emotion/react';
// redux
import { SkillTag } from 'redux/schemas/models/skill-tag';
// components
import SkillItemModal from './skill-item-modal';

const styles = css`
  &.skill-cell{
    min-width:0;
    display:flex;
  }
  &.cell-centered{
    display:flex;
    justify-content:center;
    align-items:center;
  }
  `;

const SkillRowModal = (props: SkillTag) => (
  <>
    <div css={styles} className='skill-cell'>
      <SkillItemModal name={props.name} />
    </div>
    <div css={styles} className='cell-centered'>{props.activitiesTagged}</div>
    <div css={styles} className='cell-centered'>{props.coursesTagged}</div>
  </>
);

export default SkillRowModal;
