import React, { useCallback, useEffect, useState } from 'react';
import t from 'react-translate';
import { css } from '@emotion/react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { find, omit } from 'underscore';
import { Button } from 'react-bootstrap';

import { RootState } from 'redux/schemas';
import { useAppDispatch } from 'redux/store';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { ActivityType } from 'redux/schemas/models/activity';
import { ComponentType, LectureComponent } from 'redux/schemas/models/lecture-component';
import { MinimalVideoPracticeActivity } from 'redux/schemas/models/video-practice';
import { PracticeActivitiesAvailable } from 'redux/schemas/api/video-practice';
import { CourseAliases } from 'redux/schemas/models/course';
import { NLecturePage } from 'redux/schemas/models/lecture-page';

import { getPracticeActivitiesAvailable } from 'redux/selectors/video-practice-feedback';
import { getCourseAliases } from 'redux/selectors/course';

import NvFormDropdown from 'shared/components/inputs/nv-form-dropdown';
import { NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import { gray3, gray4 } from 'styles/global_defaults/colors';
import { NvColoredSelector, SelectionItemProps } from 'shared/components/nv-colored-selector/nv-colored-selector';
import { AngularContext } from 'react-app';
import { config } from '../../../../config/pendo.config.json';
import { isEmpty } from 'underscore';

const styles = css`
  .nv-text-input {
    max-width: 60px;
  }
  .nv-form-dropdown .bs4-dropdown .bs4-dropdown-menu {
    max-height: 150px;
    overflow-y: auto;
  }
  .bs4-dropdown-menu {
    width: 250px;
    overflow-x: hidden;
    .bs4-dropdown-item {
      white-space: normal;
      word-break: break-word;
    }
  }
  .form-title {
    max-width: 250px;
  }
  .disabled {
    background-color: #fff !important;
    pointer-events: none;
    .bs4-dropdown {
      border-color: ${gray4};
      .icon-dropdown-arrow {
        color: ${gray3};
      }
    }
  }
`;

type FormData = {
  videoPractice: NvDropdownTextItem,
  count: number,
};

type PracticeFeedbackCriteria = {
  activityId: number,
  activityType: ActivityType.VIDEO_PRACTICE,
  requiredToComplete: number,
  title: string,
  description: string,
  hardDeadline: boolean,
  isTodo: boolean,
  activity?: MinimalVideoPracticeActivity
};

type VideoPracticeEvaluationProps = {
  onClose?: () => void,
  createVideoPracticeEvaluation: (data: any, type: string) => void
  editBasics: (data: PracticeFeedbackCriteria) => void
  isEdit?: boolean
  formData?: PracticeFeedbackCriteria
  setModalTitle?: (title: string) => void
  lectureComponent?: LectureComponent
  onChange: (isDirty: boolean) => void
};

const VideoPracticeEvaluation = ({
  onClose,
  createVideoPracticeEvaluation,
  editBasics,
  isEdit,
  formData,
  setModalTitle,
  lectureComponent,
  onChange,
}: VideoPracticeEvaluationProps) => {
  const practiceActivitiesAvailable = useSelector<RootState, PracticeActivitiesAvailable>(state => getPracticeActivitiesAvailable(state));
  const aliases = useSelector<RootState, CourseAliases>(state => getCourseAliases(state));
  const lecturePage: NLecturePage = useSelector(state => state.models.lecturePages[lectureComponent?.lecturePageId]);
  const [selectedOption, setSelectedOption] = useState(isEdit ? lectureComponent.type : null);

  const dispatch = useAppDispatch();
  const skillTags = useSelector((state) => state.models.skillTags);

  const dropdownItems = useCallback(() => {
    const activities: NvDropdownTextItem[] = practiceActivitiesAvailable?.map(activity => ({
      type: 'text',
      id: activity.id,
      value: activity.id,
      text: activity.title,
      dataQa: `select-practice-activity-${activity.id}`,
    }));
    if (isEdit && formData.activity?.id) {
      activities.push({
        type: 'text',
        id: formData.activity.id,
        value: formData.activity.id,
        text: formData.activity.title,
        dataQa: `select-practice-activity-${formData.activity.id}`,
      });
    }
    return activities;
  }, [practiceActivitiesAvailable, formData?.activity, isEdit]);

  const validationSchema = yup.object({
    videoPractice: yup.object().shape({
      value: yup.number().required(t.VALIDATION.REQUIRED()),
    }).required(),
    count: yup.number().typeError(t.VALIDATION.POSITIVE_DECIMAL()).min(1, t.VALIDATION.POSITIVE_DECIMAL()).required(t.VALIDATION.REQUIRED()),
  });

  const methods = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      videoPractice: formData && formData?.activity
        ? find(dropdownItems(),
          (item: NvDropdownTextItem) => (
            item.id === formData.activityId
          )) : null,
      count: formData?.requiredToComplete ?? 5,
    },
    resolver: yupResolver(validationSchema) as any,
  });
  const { handleSubmit, formState } = methods;
  const { isDirty, isValid } = formState;

  useEffect(() => {
    const title = isEdit
      ? t.LECTURE_PAGES.COMPONENTS.DROPDOWN.EDIT_BASICS()
      : t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.MODAL.TITLE();
    setModalTitle?.(title);
  }, [isEdit, setModalTitle]);

  const onSubmit = (data: FormData) => {
    const isSkillsFeedback = selectedOption === ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK;
    const title = isSkillsFeedback
      ? t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.SKILLS_FEEDBACK_TITLE(data.videoPractice.text)
      : t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.FEEDBACK_TITLE(data.videoPractice.text);
    const description = isSkillsFeedback
      ? t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.FEEDBACK_DESCRIPTION({
        ...aliases.learnersAliases, ...aliases.courseAliases,
      })
      : t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.FEEDBACK_DESCRIPTION({
        ...aliases.learnersAliases, ...aliases.courseAliases,
      });
    if (isEdit) {
      const editPayload: PracticeFeedbackCriteria = {
        ...omit(formData, 'activity'),
        requiredToComplete: data.count,
        activityId: data.videoPractice.id as number,
        activityType: ActivityType.VIDEO_PRACTICE,
      };

      if (data.videoPractice.id !== lectureComponent?.publicFeedback?.activity?.id) {
        editPayload.title = title;
      }

      editBasics(editPayload);
    } else {
      const practiceFeedbackCriteria: PracticeFeedbackCriteria = {
        activityId: data.videoPractice.id as number,
        activityType: ActivityType.VIDEO_PRACTICE,
        requiredToComplete: data.count,
        title,
        description: `<div class="froala-style-regular">${description}</div>`,
        hardDeadline: false,
        isTodo: false,
      };
      createVideoPracticeEvaluation(practiceFeedbackCriteria, selectedOption);
    }
  };

  const onCancel = () => {
    if (isDirty) {
      dispatch(openConfirmationDialog({
        onConfirm: onClose,
        cancelText: t.FORM.CANCEL(),
        confirmText: t.FORM.DISCARD_CHANGES(),
        title: t.FORM.UNSAVED_CHANGES.NAVIGATE_AWAY_CHANGES(),
      }));
    } else {
      onClose();
    }
  };

  useEffect(() => {
    onChange(isDirty);
  }, [isDirty, onChange]);

  const isActivityDisabled = lecturePage?.released && !!formData?.activityId;
  const isDropdownDisabled = isEdit && !formData?.activity && !practiceActivitiesAvailable?.length;

  const items: Omit<SelectionItemProps, 'selectedOption'>[] = [{
    name: ComponentType.VIDEO_PRACTICE_FEEDBACK,
    description: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.MODAL.PEER_FEEDBACK(),
    imagePrefix: 'practice-peer-feedback',
    cb: () => { setSelectedOption(ComponentType.VIDEO_PRACTICE_FEEDBACK); },
    dataQa: config.pendo.practiceFeedback.peerFeedback,
  }, {
    name: ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK,
    imagePrefix: 'practice-skills-feedback',
    description: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.MODAL.SKILLS_FEEDBACK(),
    disabled: isEmpty(skillTags),
    tooltip: <DisabledTooltip />,
    cb: () => { setSelectedOption(ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK); },
    dataQa: config.pendo.practiceFeedback.skillsFeedback,
  }];

  return (
    <div className='p-4 d-flex flex-column' css={styles}>
      {!isEdit && (
        <>
          <div className='text-body mb-5'>
            {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.MODAL.CHOOSE_TYPE_OF_FEEDBACK()}
          </div>
          <NvColoredSelector items={items} selectedOption={selectedOption} />
        </>
      )}
      {!!selectedOption && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className='mt-5'>
            <div className='gray-3 text-small font-weight-bold mb-1'>
              {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.MODAL.PICK_VIDEO_PRACTICE()}
            </div>
            <NvFormDropdown
              items={dropdownItems()}
              name='videoPractice'
              isSmallSize
              disabled={!dropdownItems().length}
              className={`d-flex my-2 ${isActivityDisabled || isDropdownDisabled ? 'disabled' : ''}`}
              title={t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.MODAL.SELECT_VIDEO_PRACTICE()}
              titleClass={`font-weight-bolder form-title ${isActivityDisabled || isDropdownDisabled ? 'gray-5' : 'gray-2'}`}
            />
            {isActivityDisabled && (
              <div className='text-small gray-3'>
                {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.MODAL.ACTIVITY_DISABLED({ ...aliases.lectureAliases })}
              </div>
            )}
            <div className='text-small gray-1 mb-2 mt-4'>
              {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.MODAL.COUNT_DESCRIPTION()}
            </div>
            <NvTextInput
              withForm
              name='count'
              className='d-flex'
              onKeyDown={(event) => {
                if (event.key === '.') {
                  event.preventDefault();
                }
              }}
            />
            <div className='d-flex mt-6 justify-content-center'>
              <Button
                variant='secondary mr-2'
                onClick={onCancel}
                data-qa={config.pendo.practiceFeedback.cancel}
              >
                {t.FORM.CANCEL()}
              </Button>
              <Button
                variant='primary'
                type='submit'
                disabled={!isValid || !isDirty}
                data-qa={isEdit ? config.pendo.practiceFeedback.update : config.pendo.practiceFeedback.create}
              >
                {isEdit ? t.FORM.UPDATE() : t.FORM.ADD()}
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
      {!selectedOption && (
        <div className='d-flex mt-6 justify-content-center'>
          <Button
            variant='secondary mr-2'
            onClick={onCancel}
            data-qa={config.pendo.practiceFeedback.cancel}
          >
            {t.FORM.CANCEL()}
          </Button>
          <Button
            variant='primary'
            type='submit'
            disabled={!isValid || !isDirty}
            data-qa={isEdit ? config.pendo.practiceFeedback.update : config.pendo.practiceFeedback.create}
          >
            {isEdit ? t.FORM.UPDATE() : t.FORM.ADD()}
          </Button>
        </div>
      )}
    </div>
  );
};

const DisabledTooltip = () => {
  const { injectServices } = React.useContext(AngularContext);
  const [CurrentPermissionsManager] = injectServices(['CurrentPermissionsManager']);
  const skillTagsLink = `#!/institutions/${CurrentPermissionsManager.institution.id}/skill-tags`;

  return (
    <div className='skills-feedback-popover'>
      <div className='text-left title'>
        <i className='icon icon-skills-feedback icon-smallest text-primary' />
        <div className='gray-3 inline-block'>
          {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILL()}
        </div>
      </div>
      {CurrentPermissionsManager.hasOrgAdminPermissions() ? (
        <div>
          {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.DISABLED.DESCRIPTION_2(skillTagsLink)}
        </div>
      ) : (
        <div className='description'>
          {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.DISABLED.DESCRIPTION_1()}
        </div>
      )}
    </div>
  );
};

export default VideoPracticeEvaluation;
