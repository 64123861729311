/* @ngInject */
export default function StyleGuideCodeController(
  S3UploadFactory,
  S3NameSpaces,
  moment,
  $scope,
  MentionablesModel,
  humps,
  _,
) {
  let x;
  const vm = this;

  vm.uploadVideo = function (file) {
    S3UploadFactory.uploadToS3(file, S3NameSpaces.VIDEOS);
  };

  vm.moment = moment;
  vm.currentMoment = null;

  vm.slider = {
    value: 10,
    options: {
      floor: 0,
      ceil: 15,
      step: 0.5,
      precision: 1,
      showTicks: true,
    },
  };

  vm.MentionablesModel = MentionablesModel;
  /* eslint-disable */
    x = {'1204772':{'id':1204772,'first_name':'Teacher','last_name':'1','profile_picture':'https://d2d6mu5qcvgbk5.cloudfront.net/thumb/da977e311ab281fecf5be9d50293a28eda31d964.png?1484002484'},'1283497':{'id':1283497,'first_name':'Teacher','last_name':'2','profile_picture':'https://d2d6mu5qcvgbk5.cloudfront.net/thumb/f57505621bd7fddfb34ab1b873f8077d0c92d322.jpg?1483124221'},'1222081':{'id':1222081,'first_name':'Teacher','last_name':'3','profile_picture':'https://d2d6mu5qcvgbk5.cloudfront.net/thumb/c73d1d7fb3b293a5cef93a014f2872051e67c604.jpg?1480466055'},'1219074':{'id':1219074,'first_name':'Test','last_name':'6Student','profile_picture':null},'1219071':{'id':1219071,'first_name':'Test','last_name':'5Student','profile_picture':null},'1219066':{'id':1219066,'first_name':'Tester','last_name':'4Student4','profile_picture':null}};
    /* eslint-enable */
  x = humps.camelizeKeys(x);
  MentionablesModel.setMentionableUsers(_.toArray(x));

  // vm.videoUrl = 'http://www.sample-videos.com/video/mp4/240/big_buck_bunny_240p_1mb.mp4';
  vm.videoUrl = 'https://s3.amazonaws.com/venture-lab/videos%2Foriginal%2F1uapq29ehdyxecdi-small.mp4';

  $('.loading-screen').hide();
}

// angular.module('origamiApp', {
//     timezone: 'Name of Timezone' // e.g. 'Europe/London'
// }
