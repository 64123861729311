/* @ngInject */
export default function ConfigureTOSModalCtrl(

  $scope,
  $uibModalInstance,
  $uibModal,
  humps,
  InstitutionsManager,
  config,
) {
  const vm = this;

  vm.InstitutionsManager = InstitutionsManager;
  vm.currentTos = InstitutionsManager.institution.tos;
  vm.config = config;

  vm.closeModal = () => {
    InstitutionsManager.institution.tos = vm.currentTos;
    $uibModalInstance.dismiss('cancel');
  };

  vm.save = () => {
    vm.savingTos = true;

    const saveTosConfirmationModal = $uibModal.open({
      backdropClass: 'modal-overlay-backdrop',
      templateUrl: 'institutions/templates/save-tos-confirmation.html',
      windowClass: 'modal-overlay',
    });

    saveTosConfirmationModal.result.then(() => {
      InstitutionsManager.saveTos()
        .then(() => {
          vm.savingTos = false;
          vm.currentTos = InstitutionsManager.institution.tos;
          vm.tosForm.$setPristine();
          vm.closeModal();
        });
    });
  };

  vm.hasBeenDeleted = () => !InstitutionsManager.institution.tos && vm.currentTos !== InstitutionsManager.institution.tos;
}
