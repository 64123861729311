import { css } from '@emotion/react';
import React from 'react';
import NvIcon from 'shared/components/nv-icon';
import { black, gray2, gray6, primary } from 'styles/global_defaults/colors';
import t from 'react-translate';
import { doubleSpacing, quarterSpacing, standardSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { screenSmMin } from 'styles/global_defaults/media-queries';
import { useMediaQuery } from 'react-responsive';
import { Steps } from './hooks/use-clone-course-form';


const Progress = ({
  currentStep,
}) => {
  // Getting the screen size
  const isMobile = useMediaQuery({
    query: `(max-width: ${screenSmMin}px)`,
  });
  const style = css`
    margin: -${standardSpacing}px -${standardSpacing}px 0px -${standardSpacing}px;
    padding: ${threeQuartersSpacing}px ${isMobile ? doubleSpacing : 115}px;
    border-bottom: solid 1px ${gray6};
    justify-content: space-between;
    position: sticky;
    top: -${standardSpacing}px;
    z-index: 1;
    .step {
      align-items: center;
    }
    span {
      font-size: 13px;
      color: ${gray2};
    }
    .little-text{
      font-size: 11px;
    }
    .icon {
      padding-right: 13px;
    }
    .active {
      span {
        color: ${black};
      }
      .icon {
        color: ${primary};
      }
    }
  `;
  return (
    <div className='form-progress text-gray-4 d-flex semi-bold bg-white' css={style}>
      <div className='step d-flex course-information active'>
        <NvIcon icon='content' size='small' />
        <span>{ t.COURSES.CLONE.STEPS.STEP_ONE.NAME() }</span>
      </div>
      <Divider active={currentStep >= Steps.STEP_TWO} />
      <div className={`step d-flex user-management ${currentStep >= Steps.STEP_TWO ? 'active' : ''}`}>
        <NvIcon icon='groups' size='small' />
        <div>
          <span>{ t.COURSES.CLONE.STEPS.STEP_TWO.NAME() }</span>
          <div className='little-text text-gray-3'>Optional</div>
        </div>
      </div>
      <Divider active={currentStep >= Steps.STEP_THREE} />
      <div className={`step d-flex review ${currentStep >= Steps.STEP_THREE ? 'active' : ''}`}>
        <NvIcon icon='survey' size='small' />
        <span>{ t.COURSES.CLONE.STEPS.STEP_THREE.NAME() }</span>
      </div>
    </div>
  );
};

const Divider = ({
  active = false,
}) => {
  const styles = css`
    height: 1px;
    width: 15%;
    align-self: center;
    margin: 0px ${quarterSpacing}px;
  `;
  return (
    <div className={`${active ? 'bg-primary' : 'bg-gray-4'}`} css={styles} />
  );
};

export default Progress;
