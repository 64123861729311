import React, { useEffect, useState } from 'react';

/**
 * Returns true if the user reached bottom or moved from top of the element by scrolling.
 * @param element The scrolling element
 * @param padding
 * @param isScrollingToBottom  If true, check whether the scroll reached bottom,
 * else, check whether the scroll reached top.
 */

const useInfiniteScroll = (
  element: HTMLElement,
  padding: number = 0,
  isScrollingToBottom: boolean = true,
) => {
  const [isScrolledToLimit, setScrolledLimit] = useState(false);

  useEffect(() => {
    if (!element) {
      return () => {};
    }

    const handleScroll = () => {
      let isReachedLimit = false;

      // isReachedLimit checking only if scroll exist
      if (element.scrollHeight > element.offsetHeight) {
        isReachedLimit = isScrollingToBottom
          ? element.offsetHeight + element.scrollTop + padding >= element.scrollHeight
          : element.scrollTop >= padding;
      }
      setScrolledLimit(isReachedLimit);
    };
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [element, padding, isScrollingToBottom]);

  return isScrolledToLimit;
};

export default useInfiniteScroll;

const isEndReach = (scrollContainer: HTMLElement, padding) => scrollContainer.offsetHeight + scrollContainer.scrollTop + padding >= scrollContainer.scrollHeight;

/**
 * Will fire the event listener if the end of the scrollable content is detected
 * immediately after the event is registered and will continue listing the
 * scroll event to detect changes. This won't handle cases where the content is
 * reached due to changes in the content of the scrollable container.
 * @param ref an ref to an scrolling element
 * @param options {object} - additional options
 * @param options.padding {number} - threshold of distance where the event is fired
 * @param options.disabled {boolean} - disable the event listener. Switching this prop off/on will also reset the internal state
 */
export const useOnScrollEndReach = (
  ref: React.MutableRefObject<HTMLElement>,
  handler: () => void,
  options: { padding?: number; disabled?: boolean } = {},
) => {
  const handlerRef = React.useRef(handler);
  const { padding = 0, disabled = false } = options;

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    let wasEndReached = false;
    function callHandler() {
      if (!disabled) {
        handlerRef.current();
      }
    }
    const scrollContainer = ref.current;

    function detectEndReach() {
      const isBottom = isEndReach(scrollContainer, padding);

      if (isBottom && !wasEndReached) {
        callHandler();
      }

      wasEndReached = isBottom;
    }

    detectEndReach();

    scrollContainer.addEventListener('scroll', detectEndReach);
    return () => {
      scrollContainer.removeEventListener('scroll', detectEndReach);
    };
  }, [ref, padding, disabled]);
};
