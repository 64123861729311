import { createAction, createReducer } from '@reduxjs/toolkit';
import { initialRootState } from '.';


export type SearchInSelectOptionIndexes = 0 | 1;
export const setLastContentSearchString = createAction<string>('CONTENT_SEARCH/setLastSearchString');
export const setSearchInSelectedOptionIdx = createAction<SearchInSelectOptionIndexes>('CONTENT_SEARCH/setSearchInSelectedOptionIdx');

const contentSearchReducer = createReducer(initialRootState, (builder) => {
  builder.addCase(setLastContentSearchString, (state, action) => {
    state.app.lastContentSearchString = action.payload;
  });

  builder.addCase(setSearchInSelectedOptionIdx, (state, action) => {
    state.app.searchInSelectedOptionIdx = action.payload;
  });
});

export default contentSearchReducer;
