import { css } from '@emotion/react';
import React, { useContext, useCallback } from 'react';
import omit from 'lodash/omit';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { each, values } from 'underscore';
import { Controller, useFormContext } from 'react-hook-form';

// Schemas
import { Recipient } from 'redux/schemas/models/course-communication';
import { CourseAliases } from 'redux/schemas/models/course';

// Helpers
import { i18nJoin, JoinMode } from 'shared/services/i18n-utils';

// Contexts
import { CommunicationDispatch, TriggerType } from 'communications/course_communications/contexts/communication-context';

// Selectors
import { getCourseAliases } from 'redux/selectors/course';

// Components
import NvDropdown, { NvDropdownCheckbox, NvDropdownButtonStyle } from 'shared/components/inputs/nv-dropdown';

// Styles
import { textLargeFontSize } from 'styles/global_defaults/fonts';
import { quarterSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { primary } from 'styles/global_defaults/colors';

type JourneySendToProps = {
  title?: (any) => string,
};

const styles = (triggerType: TriggerType) => css`
  > .title-with-activity {
    font-size: ${textLargeFontSize}px;

    b {
      margin-left: ${quarterSpacing}px;
      ${triggerType === TriggerType.DUPLICATE
    ? css`
        cursor: pointer;
        color: ${primary};`
    : css`
        cursor: default;
        color: black;
      `}
    }
  }

  > .title {
    font-size: ${textLargeFontSize}px;
  }

  .edit-icon {
    color: ${primary};
    cursor: pointer;

    .icon {
      display: inline;
    }
  }

  .activity-wrapper {
    display: ${'none'};
    align-items: center;

    .dropdowns {
      display: flex;
      height: auto;
      flex: 4;

      .activity-type {
        flex: 1;
      }

      .activity {
        margin-left: ${halfSpacing}px;
        flex: 2;
      }
    }
  }
`;

export const orderedRecipients = (recipients: Recipient[]): Recipient[] => (
  // Returned array will have an order of Recipient enum
  values(Recipient).filter(item => recipients.includes(item))
);

/**
 * Returns the recipient label
 * eg: Mentors
 */
export const getRecipientLabel = (recipient: Recipient, aliases: CourseAliases) => {
  const recipientLabel = {
    [Recipient.LEARNERS]: t.COURSE_COMMUNICATIONS.RECIPIENTS.DEFAULT.LEARNERS(aliases.learnersAliases),
    [Recipient.COURSE_ADMINS]: t.COURSE_COMMUNICATIONS.RECIPIENTS.DEFAULT.COURSE_ADMINS(aliases.courseAliases),
  };

  return recipientLabel[recipient];
};

/**
 * Returns the i18n joined recipients label
 * eg: Mentors and Course Admins
 */
export const getRecipientsLabel = (recipients: Recipient[], aliases: CourseAliases, joinMode: JoinMode = JoinMode.AND) => {
  const recipientLabels: string[] = [];
  // If the prefix is needed, prefix the `All`, for the first one
  each(orderedRecipients(recipients), (recipient, index) => recipientLabels.push(
    getRecipientLabel(
      recipient,
      aliases,
    ),
  ));

  return i18nJoin(recipientLabels, joinMode);
};

type GetTitleProps = {
  title: string,
};

const GetTitle = ({
  title,
}: GetTitleProps) => {
  const titleStyles = () => css`
    &.custom-target {
      display: flex;
      align-items: center;
      & > * {
        display: inline-block;
      }

      .icon {
        display: 'flex';
        align-items: center;
      }
      .title{
        ${`color: ${primary};`}
      }
    }
  `;

  return (
    <div className='custom-target' css={titleStyles}>
      <span className='page-title-small pr-2'>
        {t.COURSE_COMMUNICATIONS.SCHEDULED_EMAIL.TO()}
      </span>
      <div className='title text-large-regular bold'>
        {title}
      </div>
      <div className='icon text-xs icon-dropdown-arrow text-primary ml-2' />
    </div>
  );
};

const JourneySendTo = () => {
  const { State } = useContext(CommunicationDispatch);
  const aliases = useSelector((state) => getCourseAliases(state));

  const { control, watch, setValue } = useFormContext();

  const recipients = watch('recipients') ?? [];

  const checkBoxItems: NvDropdownCheckbox[] = [
    {
      label: t.JOURNEY_COMMUNICATIONS.RECIPIENTS.ALL.LEARNERS(aliases.learnersAliases),
      name: Recipient.LEARNERS,
      id: Recipient.LEARNERS,
      type: 'checkbox',
      checked: recipients.includes(Recipient.LEARNERS),
      labelClass: 'text-large-regular',
      onChanged: (isChecked) => onSelect(isChecked, Recipient.LEARNERS),
    },
    {
      label: t.JOURNEY_COMMUNICATIONS.RECIPIENTS.ALL.JOURNEY_ADMINS(aliases.courseAliases),
      name: Recipient.COURSE_ADMINS,
      id: Recipient.COURSE_ADMINS,
      type: 'checkbox',
      checked: recipients.includes(Recipient.COURSE_ADMINS),
      labelClass: 'text-large-regular',
      onChanged: (isChecked) => onSelect(isChecked, Recipient.COURSE_ADMINS),
    },
  ];

  const onSelect = (isChecked: boolean, recipient: Recipient) => {
    let selectedRecipients = [];
    if (isChecked) {
      selectedRecipients = [...recipients, recipient];
    } else {
      // Remove the unchecked item
      selectedRecipients = recipients.filter(item => item !== recipient);
    }

    setValue('recipients', orderedRecipients(selectedRecipients), { shouldValidate: true, shouldDirty: true });
  };

  const sendToTitle = getRecipientsLabel(recipients, aliases);
  const customTarget = useCallback(() => (
    <GetTitle title={sendToTitle} />
  ), [sendToTitle]);
  const getRecipientsBody = () => (
    <Controller
      name='recipients'
      control={control}
      render={({ field }) => (
        <NvDropdown
          showSelectedIndicator
          items={[...checkBoxItems]}
          buttonStyle={NvDropdownButtonStyle.CUSTOM}
          customTarget={customTarget}
          {...omit(field, ['ref'])}
        />
      )}
    />
  );

  return (
    <div css={styles(State.triggerType)} className='my-5'>
      {getRecipientsBody()}
    </div>
  );
};

export default JourneySendTo;
