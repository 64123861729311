/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import axios from 'axios';
import t from 'react-translate';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import { CurrentEnrollments, UserEnrollmentExportDetails } from 'redux/schemas/models/org-users';
import { User } from 'redux/schemas/models/my-account';
import { UserEnrollmentDownloadDetailsResponse } from 'redux/schemas/api/org-users';
import { Result } from 'redux/schemas/api';
import { addAlertMessage } from './alert-messages';
import { AlertMessageType } from '../schemas/app/alert-message';


export const getUserDetails = createAsyncThunk(
  'GET_USER_DETAILS',
  async (userId: number) => axios.get<User>(
    `/users/user/${userId}.json`,
  ).then((response) => response.data).catch((error) => {
    throw (error.response?.data?.error);
  }),
);

export const getUserEnrollmentDownloadDetails = createAsyncThunk(
  'GET_ENROLLMENT_DOWNLOAD_DETAILS',
  async (params: { userId: number, institutionId: number }) => axios.post<Result<UserEnrollmentDownloadDetailsResponse>>(
    `/institutions/${params.institutionId}/user_enrollment.json`,
    {
      userId: params.userId,
    },
  ).then((response) => response.data).catch((error) => {
    throw (error.response?.data?.error);
  }),
);

export const getUserEnrollmentData = createAsyncThunk(
  'GET_ENROLLMENT_DETAILS',
  async (params: { userId: number, institutionId: number, demoCoursesRequested?: boolean, page?: number }) => axios.post<Result<CurrentEnrollments>>(
    `/users/current_enrollments.json?user_id=${params.userId}&demo_courses_requested=${params.demoCoursesRequested || false}`,
    {
      userId: params.userId,
      page: params.page,
    },
  ).then((response) => response.data).catch((error) => {
    throw (error.response?.data?.error);
  }),
);

export const exportUserEnrollmentData = createAsyncThunk(
  'EXPORT_USER_ENROLLMENT',
  async (params: {
    exportType: string,
    userId: number,
    institutionId: number,
    startTime: Date,
    endTime: string,
    period: string,
    demoCoursesRequested?: boolean,
  }, thunkAPI) => axios.post(
    `/institutions/${params.institutionId}/export_data.json`,
    params,
  ).then((response) => {
    thunkAPI.dispatch(addAlertMessage({
      duration: 20000,
      type: AlertMessageType.SUCCESS,
      header: t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.ENROLLMENT_DATA.SUCCESS.HEADER(),
      message: t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.ENROLLMENT_DATA.SUCCESS.MESSAGE(),
    }));
    return response.data;
  }).catch((error) => {
    thunkAPI.dispatch(addAlertMessage({
      type: AlertMessageType.ERROR,
      header: t.FORM.OOPS(),
      message: t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.ENROLLMENT_DATA.FAILURE.MESSAGE(),
    }));
    throw (error.response?.data?.error);
  }),
);

export const updateExportStatus = createAction<{ userId: number, data: UserEnrollmentExportDetails }>('UPDATE_EXPORT_STATUS');


export const unEnrollUserFromCourse = createAsyncThunk(
  'UN_ENROLL_FROM_COURSE',
  async (params: {
    catalogId: string,
    user: User,
    institutionId: number,
    courseUserId?: number,
    isJourney: boolean,
    isCollection: boolean,
    translationValues: any,
  }, thunkAPI) => axios.get(
    `/${params.catalogId}/user_management/toggle_banned/${params.user.id}`,
  ).then((response) => {
    const message = params.isCollection
      ? t.PROFILE.UNENROLL_SUCCESS(params.user.fullName)
      : t.PROFILE.UNENROLL_SUCCESS_ALIAS({ name: params.user.fullName, isJourney: params.isJourney, ...params.translationValues });

    thunkAPI.dispatch(addAlertMessage({
      type: AlertMessageType.SUCCESS,
      header: t.FORM.SUCCESS_BANG(),
      message,
    }));
    return response.data;
  }).catch((error) => {
    thunkAPI.dispatch(addAlertMessage({
      type: AlertMessageType.ERROR,
      header: t.FORM.OOPS(),
      message: t.FORM.ERROR_SOMETHING_WRONG(),
    }));
    throw (error.response?.data?.error);
  }),
);
