/* @ngInject */
export default function SubmissionSortFilterController(
  ReportsManager,
) {
  const vm = this;

  vm.reportsManager = ReportsManager;

  vm.onHandheldFilterClose = function (isOpen) {
    if (!isOpen) {
      ReportsManager.doSearch(false);
    }
  };
}
