/* @ngInject */
export default function VideoPracticeLectureComponentModel(
  _,
  $translate,
  LectureComponentBaseModel,
  config,
) {
  class VideoPracticeLectureComponent extends LectureComponentBaseModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      const defaults = {
        type: 'VideoPracticeLectureComponent',
        editFormTemplateUrl: 'lecture_pages/templates/components/video-practice-modal.html',
        editFormController: 'VideoPracticeEditFormModalCtrl',
        videoPractice: {},
        canBeCopied: true,
      };
      super(_.extend({}, defaults, attributes));
    }

    __preprocess() {}

    toggleToDo() {
      this.videoPractice.isTodo = !this.videoPractice.isTodo;
      this.save();
    }

    isTodo() {
      return this.videoPractice.isTodo;
    }

    isRequiredForCompletion() {
      return this.videoPractice.isRequiredForCompletion;
    }

    getPayload() {
      return { videoPractice: this.videoPractice };
    }

    saveDraft() {
      // This used to call this.save(), which is no longer valid in the React architecture
    }
  }

  VideoPracticeLectureComponent.iconClass = 'icon-media-practice';
  VideoPracticeLectureComponent.showModalBeforeCreate = true;
  VideoPracticeLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.DESCRIPTION';
  VideoPracticeLectureComponent.pendoTagName = config.pendo.lectureEdit.videoPractice;

  return VideoPracticeLectureComponent;
}
