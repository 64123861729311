/* @ngInject */
export default function PasswordsResource($resource) {
  return $resource(
    '/users/password.json',
    {},
    {
      update: { method: 'PUT', url: '/users/password.json' },
      reset: { method: 'POST', url: '/users/password.json' },
    },
  );
}
