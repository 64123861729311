import { css } from '@emotion/react';
import t from 'react-translate';
import { useContext, useState, useRef } from 'react';
import { AngularServicesContext } from 'react-app';
import { pick, mapObject, keys, omit } from 'underscore';

import { LectureComponentProps, LecturePageMode } from 'lecture_pages/components';

import { standardSpacing, tripleSpacing, quarterSpacing, doubleSpacing } from 'styles/global_defaults/scaffolding';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvDropdown, { NvDropdownOption, NvDropdownButtonStyle } from 'shared/components/inputs/nv-dropdown';
import ColorPickerPopover, { ColorValuesMap } from 'components/color-picker-popover';
import IconLibraryModal from 'shared/components/nv-icon-library';
import NvTooltip from 'shared/components/nv-tooltip';

import { handheld, isHandheld } from 'styles/global_defaults/media-queries';
import { StyledLinkType } from 'redux/schemas/models/lecture-component';
import AutosizeInput from 'react-input-autosize';
import { StyledLinkRenderProps } from './styled-link-lecture-component';

export const getColorInputLabels = () => ({
  libraryIconColor: t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.TEXT_COLOR(),
  buttonColor: t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.BACKGROUND_COLOR(),
});

const ButtonStyledLink = (props: LectureComponentProps<StyledLinkType.BUTTON> & StyledLinkRenderProps) => {
  // angular savingStyledComponent is used for the confirmation modal that appears when the
  // user exits without saving the updated content.
  const angularServices = useContext(AngularServicesContext);

  const [initialContent, setInitialContent] = useState(props.lectureComponent.header ?? '');
  const [newContent, setNewContent] = useState(initialContent);
  const [editText, setEditText] = useState<boolean>();
  const [showColorPicker, setShowColorPicker] = useState<boolean>();
  const [showGallery, setShowIconGallery] = useState<boolean>(false);

  const ref = useRef<HTMLInputElement>();

  const [iconProps, setIconProps] = useState(pick(props.lectureComponent.viewOptions, 'buttonColor', 'libraryIconColor'));
  const [iconName, setIconName] = useState(props.lectureComponent.viewOptions?.libraryIconName);

  const styles = css`
    ${handheld(css`
      width: calc(100vw - 40px);
      max-width: 335px;
    `)};

    .styled-link-wrapper {
      ${props.mode !== LecturePageMode.EDIT ? 'cursor: pointer' : ''};
      display:flex;
      align-items: center;

      color: ${iconProps.libraryIconColor};
      background-color: ${iconProps.buttonColor};
      border-radius: ${quarterSpacing}px;
      height: ${doubleSpacing}px;
      justify-content: center;

      .title {
        color: ${iconProps.libraryIconColor};
        background-color: ${iconProps.buttonColor};
        white-space: pre-wrap;
        outline: none;
      }

      .button-link-adjusting-span {
        visibility: hidden;
      }
      .styled-button-link-text-wrapper {

        input {
          padding: 0px;
          border: 0px;
          width: ${tripleSpacing}px;
          border-radius: ${quarterSpacing}px;
          height: ${standardSpacing}px;
        }
      }
    }

    .overlay-wrapper  {
        ${(props.mode === LecturePageMode.EDIT)
    ? `&:hover {
            opacity: .8;
            filter: brightness(.5);
          }`
    : ''};
      }
  `;

  const onBlurTitle = () => {
    if (initialContent !== newContent) {
      angularServices.$scope.vm.savingStyledComponent = true;

      setInitialContent(newContent);
      props.onUpdate({ header: newContent })
        .then(() => {
          setEditText(false);
        });
    } else {
      setEditText(false);
    }
  };

  const onChangeTitle = (e) => {
    const updateContent = e.target.value;

    if (initialContent !== updateContent) {
      angularServices.$scope.vm.savingStyledComponent = true;
      setNewContent(updateContent);
    }
  };

  const iconPicked = (icon: string) => {
    setIconName(icon);
    props.onUpdate({
      viewOptions: {
        ...props.lectureComponent.viewOptions,
        libraryIconName: icon,
      },
    });
  };

  const removeIcon = () => {
    setIconName(null);
    props.onUpdate({
      viewOptions: {
        ...(omit(props.lectureComponent.viewOptions, 'libraryIconName')),
      },
    });
  };

  const dropdownItems: NvDropdownOption[] = [
    { type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.EDIT_TEXT_DROP_DOWN_OPTION(),
      callback: () => {
        setEditText(true);
        setTimeout(() => {
          ref.current.focus();
        });
      },
    },
    {
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.EDIT_COLOR_DROP_DOWN_OPTION(),
      callback: () => setShowColorPicker(true),
    },
    {
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.EDIT_ICON_DROP_DOWN_OPTION(),
      callback: () => setShowIconGallery(true),
    },
  ];

  if (iconName) {
    dropdownItems.push({
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.REMOVE_ICON_DROP_DOWN_OPTION(),
      callback: () => removeIcon(),
    });
  }

  return (
    <div css={styles}>
      {props.mode === LecturePageMode.EDIT && editText
        ? (
          <div className='p-3 styled-link-wrapper'>
            { iconName && <div className={`pr-2 icon-${iconName} icon-xss-smallest`} /> }
            <div>
              {/* This span is intentional and this control the width of text input as we type  */}
              <AutosizeInput
                className='styled-button-link-text-wrapper'
                onBlur={() => onBlurTitle()}
                inputClassName='text-medium title'
                onChange={onChangeTitle}
                name='styled-input-button'
                placeholder={t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.HEADER_PLACEHOLDER()}
                value={newContent}
                maxLength={33}
                ref={ref}
              />
            </div>
          </div>
        )
        : (
          <ColorPickerPopover<{
            buttonColor?: string;
            libraryIconColor: string;
          }>
            show={showColorPicker}
            placement='bottom-start'
            sections={[{
              name: 'buttonColor',
              title: t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.BACKGROUND_COLOR(),
            }, {
              name: 'libraryIconColor',
              title: t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.TEXT_COLOR(),
            }]}
            onChange={(colorProp, newVal: string) => {
              const newProps = {
                ...iconProps,
                [colorProp]: newVal,
              };
              setIconProps(newProps);
              props.onUpdate({
                viewOptions: {
                  ...props.lectureComponent.viewOptions,
                  ...newProps,
                },
              });
            }}
            colorValues={iconProps}
            onToggle={() => setShowColorPicker(false)}
          >
            <NvDropdown
              buttonStyle={NvDropdownButtonStyle.CUSTOM}
              items={dropdownItems}
              customTarget={() => (
                <NvTooltip
                  text={t.LECTURE_PAGES.COMPONENTS.STYLED_LINK_BUTTON.TOOLTIP()}
                  enabled={props.mode === LecturePageMode.EDIT}
                  preventOverflow
                >
                  <div className='p-3 styled-link-wrapper overlay-wrapper'>
                    {iconName && <div className={`pr-2 icon-${iconName} icon-xss-smallest`} /> }
                    <span className='text-medium title'>{props.lectureComponent.header}</span>
                  </div>
                </NvTooltip>
              )}
              menuClassName='drop-down-menu'
              disabled={props.mode !== LecturePageMode.EDIT}
              drop={isHandheld() ? 'down' : 'right'}
            />
          </ColorPickerPopover>
        )}
      <IconLibraryModal onIconClick={iconPicked} show={showGallery} onClose={() => setShowIconGallery(false)} />
    </div>
  );
};

export default ButtonStyledLink;
