import { useCallback } from 'react';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/schemas';
import { useLectureComponentContext } from 'components/lecture-component-provider';
import { VideoPracticeActivity, VideoPracticeScenario, VideoPracticeLectureComponentType } from 'redux/schemas/models/video-practice';
import { updateLectureComponent } from 'redux/actions/lecture-pages';
import { editScenario } from 'redux/actions/video-practice';
import { ComponentType, NLectureComponent } from 'redux/schemas/models/lecture-component';

export const useUpdatePracticeActivity = () => {
  const dispatch = useAppDispatch();

  const { catalogId, lecturePageId, angularComponent } = useLectureComponentContext();
  const { id, type, index, practiceActivity: practiceActivityId } = useGetVideoPracticeLectureComponent();
  const activity = useSelector<RootState, VideoPracticeActivity>((state) => state.models.practiceActivities[practiceActivityId]);

  return useCallback(
    (newPracticeActivity: Partial<VideoPracticeActivity>) => {
      const updatedComponent = {
        catalogId,
        lecturePageId,
        componentData: {
          id,
          type,
          index,
          videoPractice: {
            ...activity,
            ...newPracticeActivity,
          },
        },
      };

      return dispatch(updateLectureComponent(updatedComponent));
    },
    [dispatch, catalogId, index, id, lecturePageId, type, activity],
  );
};

export const useUpdatePracticeScenario = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (newPracticeScenario: Partial<VideoPracticeScenario>) =>
      // update practice scenario
      dispatch(editScenario({ scenario: newPracticeScenario })),
    [dispatch],
  );
};

export const useGetVideoPracticeLectureComponent = (passedLectureComponentId?: number) => {
  const contextLectureComponentId = useLectureComponentContext().lectureComponentId;

  const lectureComponentId = String(passedLectureComponentId ?? contextLectureComponentId);
  return useSelector<RootState>((state: RootState) => state.models.lectureComponents[lectureComponentId] as NLectureComponent<ComponentType.VIDEO_PRACTICE>);
};

export const getTimeLimits = (scenario: VideoPracticeScenario) => {
  // 3 minutes for text only prompts
  const videoPromptLength = scenario.videoPrompt?.length || 0;
  const totalSeconds = scenario.textPrompt ? 180 : 0;
  const timeToView = Math.floor(1.5 * videoPromptLength + totalSeconds);
  const timeToRecord = scenario.recordingLimit;
  const timeToReview = Math.floor(1.5 * scenario.recordingLimit);

  return {
    timeToView, timeToRecord, timeToReview,
  };
};

export const getTotalTime = (view, record, review) => view + record + review;

export const convertToMinAndSec = (seconds: number) => ({
  min: Math.floor(seconds / 60),
  sec: seconds % 60,
});

export const convertToSeconds = (min: number, sec: number) => min * 60 + sec;

export const roundToMinutes = (seconds: number) => {
  const { min, sec } = convertToMinAndSec(seconds);

  if (!min) {
    return 1;
  }

  return sec >= 30 ? min + 1 : min;
};
