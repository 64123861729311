import { css } from '@emotion/react';
import t from 'react-translate';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/schemas';
import { FullCourse } from 'redux/schemas/models/courseFull';
import { standardSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { gray7 } from 'styles/global_defaults/colors';
import { AppDispatch, useAppDispatch } from 'redux/store';
import { resetLeftPanelNovoAI, setBottomBarVisibility, setLeftPanelView, setNewComponentIndex } from 'redux/actions/lecture-pages';
import { initialLecturePageState } from 'redux/reducers';
import { getCurrentCourse } from 'redux/selectors/course';
import { useLecturePageFromParams, useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import NvGeneratingLoader from 'shared/components/loaders/nv-generating-loader';
import { NovoAIItemType } from 'redux/schemas/models/lecture-page';

type NvAddComponentProps = {
  /** Whether to allow clicking and show the 'Click to Add' prompt */
  enabled: boolean,
  /** Whether any components exist on the lecture page. We show different text copy
   * in this case */
  noComponentsExist?: boolean,
  /** The positional index for components added via this button */
  index: number,
};

/** The `Click to Add` bar the user clicks to add a component to the Lecture page. Always encompasses
 * some space on the page but displays the clickable area when 'show' is true. */
export const NvAddComponent = (props: NvAddComponentProps) => {
  const dispatch = useAppDispatch();
  const params = useLecturePageParams();
  /** Show different copy and a close button if this button was used to begin component selection */
  const showSelect = useSelector(state => state.app.lecturePage.newComponentIndex === props.index);
  const newGeneratedAIComponent = useSelector(state => state.app.newGeneratedAIComponent);
  const forceVisible = props.enabled && (props.noComponentsExist || showSelect);
  const lecturePage = useLecturePageFromParams();

  const styles = css`
    height: ${standardSpacing}px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${halfSpacing}px;
    margin-bottom: ${halfSpacing}px;

    .container-bar {
      background-color: ${gray7};
      display: none;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding-left: ${halfSpacing}px;
    }

    .add-bar {
      cursor: pointer;
    }

    ${(props.enabled && !forceVisible) && css`
      &:hover .container-bar {
        display: flex;
        cursor: pointer; // TODO: Consider removing this, I think this is unneeded after the .add-bar cursor pointer rule above
      }
    `};

    ${forceVisible && css`
      .container-bar {
        display: flex;
      }
    `};

    .add-bar {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    .close-container {
      cursor: pointer;
    }

    /** TODO: We don't have a util class for this size */
    .icon-close {
      font-size: 10px;
    }
  `;

  const currentCourse: FullCourse = useSelector<RootState>(getCurrentCourse) as unknown as FullCourse;

  if (params.catalogId && !currentCourse) {
    return null;
  }


  // TODO: RTL support
  const isRtl = false;

  // For much of the lecture rewrite project, these buttons reserved their space even when not shown. This is a shortcut for hiding them when not in edit mode.
  // Let's consider removing this prop altogether and simply not rendering this component when not in edit mode
  if (!props.enabled) {
    return null;
  }

  if (newGeneratedAIComponent?.index === props.index && newGeneratedAIComponent?.aiAction !== NovoAIItemType.DISCUSSION) {
    return (
      <NvGeneratingLoader
        loadingText={newGeneratedAIComponent?.itemType === NovoAIItemType.QUIZ ? t.FORM.SAVING() : undefined}
      />
    );
  }

  return (
    <div css={styles}>
      {!lecturePage.isLinked && (
        <div className='container-bar'>
          {/* Show the 'Add Component' ui before being clicked. After being clicked, show the
          prompt for picking a component from the LHS */}
          { !showSelect
            ? (
              <div className='add-bar' onClick={() => showNewComponentAddPanel(dispatch, props.index)}>
                <div className='icon icon-add icon-xxs-smallest text-primary mr-1' />
                <span className='text-primary text-small'>
                  { props.noComponentsExist ? t.LECTURE_PAGES.COMPONENTS.ADD_COMPONENT({
                    LectureAlias: currentCourse?.lectureName?.capitalizedSingularized,
                  }) : t.LECTURE_PAGES.COMPONENTS.ADD()}
                </span>
              </div>
            ) : (
              <React.Fragment>
                <div className='icon icon-collapse icon-xxs-smallest text-warning mr-1' />
                <span className='text-warning text-small'>
                  {t.LECTURE_PAGES.COMPONENTS.SELECT_WITH_DIRECTION(isRtl.toString())}
                </span>
                {/* This container is only used to increase the click target area */}
                <div
                  className='close-container h-100 d-flex align-items-center ml-auto pl-2 pr-2 text-primary'
                  onClick={() => hideAddUI(dispatch)}
                >
                  <span className='text-small mr-1'>{t.FORM.CLOSE()}</span>
                  <div className='icon icon-close' />
                </div>
              </React.Fragment>
            )}
        </div>
      )}
    </div>
  );
};

/** Fires all the dispatch actions required to enter the component add UX
 * TODO: Should we combine these into a single action/reducer? If so, how would we reuse the logic from each of these actions?
*/
export const showNewComponentAddPanel = (dispatch: AppDispatch, newComponentIndex: number) => {
  dispatch(setLeftPanelView('add_top_level'));
  dispatch(setBottomBarVisibility(false));
  dispatch(setNewComponentIndex(newComponentIndex));
  dispatch(resetLeftPanelNovoAI());
};
/** Fires all the dispatch actions required to leave the component add UX */
export const hideAddUI = (dispatch: AppDispatch) => {
  dispatch(setLeftPanelView('outline'));
  dispatch(setBottomBarVisibility(true));
  dispatch(setNewComponentIndex(initialLecturePageState.newComponentIndex));
  dispatch(resetLeftPanelNovoAI());
};

export default NvAddComponent;
