import { css } from '@emotion/react';
import t from 'react-translate';
import NvDropdown, { NvDropdownOption, NvDropdownButtonStyle } from 'shared/components/inputs/nv-dropdown';
import { config } from '../../../../config/pendo.config.json';

const editSessionStyles = css`
  .icon-more {
    transform: rotate(90deg);
  }
`;

const baseItem: NvDropdownOption = {
  type: 'text',
  text: '',
  disableSetActive: true,
  preventClosing: true,
};

const items: { [key: string]: NvDropdownOption } = {
  uploadAttendeeList: { ...baseItem },
  uploadRecording: { ...baseItem },
  deleteSession: { ...baseItem, class: 'text-danger' },
};

type EditSessionOptionsProps = {
  disabled: boolean
  hasRecording: boolean
  uploadAttendeeList: () => void
  uploadRecording: () => void
  editRecording: (file: any) => void
  removeRecording: () => void
  delete: () => void
};

const LiveSessionCardEditOptions = (props: EditSessionOptionsProps) => {
  const {
    disabled,
    hasRecording,
    uploadAttendeeList,
    uploadRecording,
    editRecording,
    removeRecording,
    delete: deleteSession,
  } = props;

  const recordingOptions: NvDropdownOption[] = hasRecording ? [{
    ...items.editRecording,
    text: t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.EDIT_RECORDING(),
    callback: editRecording,
    dataQa: config.pendo.liveEvent.liveSesssionCard.editRecording,
    disabled,
  } as NvDropdownOption, {
    ...items.removeRecording,
    text: t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.REMOVE_RECORDING(),
    callback: removeRecording,
    dataQa: config.pendo.liveEvent.liveSesssionCard.removeRecording,
    disabled,
  } as NvDropdownOption] : [{
    ...items.uploadRecording,
    text: t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.UPLOAD_RECORDING(),
    callback: uploadRecording,
    dataQa: config.pendo.liveEvent.liveSesssionCard.uploadRecording,
    disabled,
  } as NvDropdownOption];

  const sessionOptions: NvDropdownOption[] = [{
    ...items.uploadAttendeeList,
    text: t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.UPLOAD_ATTENDEE_LIST(),
    callback: uploadAttendeeList,
    dataQa: config.pendo.liveEvent.liveSesssionCard.uploadAttendeeList,
    disabled,
  } as NvDropdownOption,
  ...recordingOptions, { type: 'divider' }, {
    ...items.deleteSession,
    text: t.FORM.DELETE(),
    callback: deleteSession,
    dataQa: config.pendo.liveEvent.liveSesssionCard.deleteSession,
  } as NvDropdownOption];

  return (
    <div css={editSessionStyles} className='edit-session-options d-flex mt-2'>
      <NvDropdown
        buttonStyle={NvDropdownButtonStyle.ICON}
        iconClass='icon-xss-smallest icon-more'
        items={sessionOptions}
        minWidth={200}
        toggleDataQa='lecture-component-edit'
        focusOutOnItemClick
      />
    </div>
  );
};

export default LiveSessionCardEditOptions;
