/* @ngInject */
export default function AttachModalResolvesToVmCtrl(
  vmResolves,
  CurrentCourseManager,
  _,
) {
  const vm = this;
  vm.CurrentCourseManager = CurrentCourseManager;
  _.extend(vm, vmResolves);
}
