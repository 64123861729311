/* @ngInject */
export default function CourseHomeRhsController(
  $scope,
  CourseHomeRhsManager,
  CurrentCourseManager,
  config,
) {
  const vm = this;

  vm.config = config;

  vm.manager = CourseHomeRhsManager;
  vm.currentCourseManager = CurrentCourseManager;

  return this;
}
