import { MockDefinition, MockTypes } from 'shared/axios/axios-mocks';

const journeys = [
  {
    id: 5991,
    catalogId: 'journey-nov-75067-01',
    name: 'journey-NOV-75067-01',
    thumbnail: '/assets/brand/temp.png',
    squareThumbnail: '/assets/brand/temp.png',
    flyerLogo: null,
    releaseDate: '2022-04-25T05:00:00Z',
    endDate: null,
    createdAt: '2022-04-26T19:58:16Z',
    inProgress: true,
    catalogName: 'journey-NOV-75067-01',
    officialReleaseDate: null,
    registrationCloseDate: null,
    closeDate: '2022-05-06T05:00:00Z',
    beingOffered: null,
    institutionId: 187,
    isClosed: true,
    statementsReleased: false,
    version: '2.0',
    released: true,
    parentPrograms: [],
    isProgram: false,
    isJourney: true,
    isSelfPaced: null,
    showRecentlyActive: true,
    typeOfRegistrationInWords: 'Closed enrollment',
    inArchiveMode: false,
    expiringPointsEnabled: false,
    gamificationLeaderboardEnabled: false,
    gamificationEnabled: false,
    helpWidget: null,
    gamificationLeaderboardNumStudentsToShow: 0,
    classSummary: null,
    headerColor: '#50c878',
    nameFontColor: '#000',
    logo: null,
    quickAccessOptions: [
      'Gallery',
      'Discussions',
      'Leaderboard',
      'Help',
      'Teams',
      'Groups',
      'Learners',
      'Teaching Team',
    ],
    enrollmentLimitInDays: null,
    type: 'Journey',
    automatedCompletionsEnabled: true,
    contentLanguage: 'en_US',
    welcomeMessage: null,
    showWelcomeMessageAtTop: null,
    lastActivity: null,
    automaticCompletionCriteria: {
      totalTodosCount: 0,
      unreleasedTodosCount: 0,
      unreleasedTodoAssignmentsCount: 0,
      automatedCompletionsEnabled: true,
      totalRequiredTodosCount: 0,
      totalTodoAssignmentsCount: 0,
      requiredPoints: 0,
      requiredAssignmentsCount: 0,
      soaCertificateEnabled: false,
    },
    instructorsForFlyer: null,
    enrolledStudentsListUrl: null,
    usedFor: 0,
    welcomeEmailEnabled: true,
    visitedHome: null,
    enrolledStudentsCount: 1,
    url: '/journey-nov-75067-01',
    offeredTo: [],
    typeOfRegistration: 3,
    coverPhotoUrl: '/assets/brand/temp.png',
    largeCoverPhotoUrl: '/assets/brand/temp.png',
    headerBackground: null,
    calendarEventsEnabled: false,
    openInJourney: false,
    currentLecture: null,
    instructorsFullName: '',
    institutionName: 'Kwok Black Institution1',
    institutionBrandColor: '#50c878',
    institutionBrandBarFontColor: '#000',
    executiveSummary: null,
    priceText: '',
    tags: [],
    programCourses: null,
    rate: null,
    hasCourseLongTeams: null,
    isPaidAndOpenEnrollment: false,
    closedEnrollment: true,
    officiallyReleased: false,
    numEnrolled: 1,
    numBanned: 0,
    numWithdrawn: 0,
    isNovoedAdmin: false,
    programId: null,
    creator: {
      firstName: 'Sebastian',
      lastName: 'S1',
      fullName: 'Sebastian S1',
      userNameForUrl: 'sebastian_orgadmin',
      initials: 'SS',
    },
    hasGroups: false,
    numForumsReleased: 0,
    numFeatureableExercises: 0,
    hasHardDeadlineExercises: false,
    hasHardDeadlineTeamExercises: false,
    courseLongTeamSet: null,
    groupTeamSet: null,
    parentCourseId: 5991,
    cardView: false,
    showCommunityPanel: true,
    hasCommunity: true,
    userCourse: null,
    publiclySharedFlyer: true,
    shareableTweet: null,
    classVideoHtml: null,
    promoPicture: null,
    customFlier: false,
    classDescription:
      '<p class="froala-style-regular" dir="ltr">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer augue tellus, facilisis et risus a, bibendum ornare ex. Phasellus tincidunt, dui ac sagittis fringilla, tellus nibh ullamcorper nibh, nec fringilla lorem erat vitae risus. Aenean fermentum velit ipsum, sit amet pharetra tortor aliquam a. Sed quis tellus sit amet metus auctor tincidunt. Nulla purus metus, malesuada sit amet metus eget, tincidunt pellentesque dui. Duis mauris ante, condimentum vitae risus ac, vestibulum vehicula lorem. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In efficitur justo quis posuere gravida. Suspendisse fringilla, odio in facilisis iaculis, erat elit iaculis risus, sed facilisis felis leo at urna. Vivamus interdum orci a nisi pellentesque dictum.</p>',
    moreInformation: null,
    price: 0,
    userAuthenticatedThroughInstitution: false,
    ended: false,
    shopifyEnabled: false,
    registrationClosed: false,
    flyerCoverPhoto: null,
    landingPageComponents: null,
    isBeingDeleted: false,
    canStopSharingVideos: true,
    hasAnyTeams: false,
    hasCourseLongTeamSet: false,
    hasGroupSet: false,
    hasProfileRequirement: false,
    profileRequirement: null,
    soaCertificateEnabled: false,
    loginLearners: {
      loggedIn: 0,
      percentage: 0,
    },
    learnersProgress: {
      inProgress: 0,
      percentage: 0,
    },
    learnersCompleted: {
      learnersCompleted: 0,
      percentage: 0,
    },
    activeLearners: {
      learnersActive: 0,
      percentage: 0,
    },
    learnersInactive: 0,
    duration: null,
    durationType: null,
    numCoursesSharingVideoLibrary: 0,
    hasExtraTerms: false,
    entitlements: [],
    canTakeCourse: true,
    logoSize: 'small',
    lmsCourse: null,
    degreedCourseId: null,
    isTosRequiredForCourseComms: false,
    inJourneys: [],
    hasBeenGrantedManualCompletion: false,
    blockingJourneyCatalogId: null,
    matchingEntitlements: false,
    skillTags: [],
    courseMetadata: [],
    description:
      '<p class="froala-style-regular" dir="ltr">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer augue tellus, facilisis et risus a, bibendum ornare ex. Phasellus tincidunt, dui ac sagittis fringilla, tellus nibh ullamcorper nibh, nec fringilla lorem erat vitae risus. Aenean fermentum velit ipsum, sit amet pharetra tortor aliquam a. Sed quis tellus sit amet metus auctor tincidunt. Nulla purus metus, malesuada sit amet metus eget, tincidunt pellentesque dui. Duis mauris ante, condimentum vitae risus ac, vestibulum vehicula lorem. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In efficitur justo quis posuere gravida. Suspendisse fringilla, odio in facilisis iaculis, erat elit iaculis risus, sed facilisis felis leo at urna. Vivamus interdum orci a nisi pellentesque dictum.</p>',
    collections: [
      {
        id: 524,
        name: 'Collection 1',
        description:
          '<p class="froala-style-regular" dir="ltr">Collection 1</p>',
        index: 0,
        numRequiredCourses: null,
        courses: [
          {
            id: 5988,
            catalogId: 'course-nov-75067-01',
            name: 'course-NOV-75067-01',
            thumbnail: '/assets/brand/temp.png',
            releaseDate: '2022-04-25T05:00:00Z',
            endDate: null,
            officialReleaseDate: null,
            registrationCloseDate: null,
            closeDate: null,
            isClosed: false,
            released: true,
            isJourney: false,
            isSelfPaced: false,
            typeOfRegistrationInWords: 'Free',
            headerColor: '#50c878',
            enrollmentLimitInDays: null,
            type: 'Course',
            typeOfRegistration: 0,
            headerBackground: null,
            openInJourney: null,
            currentLecture: {
              currentLecture: null,
              firstLecture: null,
            },
            numEnrolled: 1,
            userCourse: {
              userId: 1110398,
              courseId: 5988,
              paid: true,
              url:
                'https://kblackinstitution1.emerald.novoed.com/#!/courses/course-nov-75067-01/users/sebastian_orgadmin',
              receivesStatementOfAccomplishment: null,
              user: {
                id: 1110398,
                admin: false,
                firstName: 'Sebastian',
                lastName: 'S1',
                fullName: 'Sebastian S1',
                userNameForUrl: 'sebastian_orgadmin',
                roles: {
                  permission: 54,
                  courseRoleId: 856,
                  role: 'Super Admin',
                  roles: {
                    permission: 54,
                    admin: false,
                    name: {
                      capitalizedPluralized: 'Super admins',
                      capitalizedSingularized: 'Super admin',
                      downcasedSingularized: 'super admin',
                      downcasedPluralized: 'super admins',
                      pluralizedTitleized: 'Super Admins',
                      singularizedTitleized: 'Super Admin',
                    },
                    menteeName: null,
                    badge: 'SA',
                  },
                  isCourseAdmin: true,
                },
                profilePicture: null,
                initials: 'SS',
              },
              role: 'Super Admin',
              roles: {
                permission: 54,
                admin: false,
                name: {
                  capitalizedPluralized: 'Super admins',
                  capitalizedSingularized: 'Super admin',
                  downcasedSingularized: 'super admin',
                  downcasedPluralized: 'super admins',
                  pluralizedTitleized: 'Super Admins',
                  singularizedTitleized: 'Super Admin',
                },
                menteeName: null,
                badge: 'SA',
              },
              isCourseAdmin: true,
              accessCloseDate: null,
              accessInArchiveMode: false,
              banned: false,
              dropped: false,
              isEnrolled: true,
              termsAcceptanceNeeded: false,
              courseLongTeam: null,
              mentors: [],
            },
            ended: false,
            registrationClosed: false,
            soaCertificateEnabled: null,
            entitlements: [],
            canTakeCourse: true,
            inJourneys: [
              {
                id: 5991,
                catalogId: 'journey-nov-75067-01',
                name: 'journey-NOV-75067-01',
              },
            ],
            matchingEntitlements: false,
          },
          {
            id: 5989,
            catalogId: 'course-nov-75067-02',
            name: 'course-NOV-75067-02',
            thumbnail: '/assets/brand/temp.png',
            releaseDate: '2022-04-25T05:01:00Z',
            endDate: null,
            officialReleaseDate: null,
            registrationCloseDate: null,
            closeDate: null,
            isClosed: false,
            released: true,
            isJourney: false,
            isSelfPaced: false,
            typeOfRegistrationInWords: 'Free',
            headerColor: '#50c878',
            enrollmentLimitInDays: null,
            type: 'Course',
            typeOfRegistration: 0,
            headerBackground: null,
            openInJourney: null,
            currentLecture: {
              currentLecture: null,
              firstLecture: null,
            },
            numEnrolled: 1,
            userCourse: null,
            ended: false,
            registrationClosed: false,
            soaCertificateEnabled: null,
            entitlements: [],
            canTakeCourse: true,
            inJourneys: [
              {
                id: 5991,
                catalogId: 'journey-nov-75067-01',
                name: 'journey-NOV-75067-01',
              },
            ],
            matchingEntitlements: false,
          },
          {
            id: 5990,
            catalogId: 'course-nov-75067-03',
            name: 'course-NOV-75067-03',
            thumbnail: '/assets/brand/temp.png',
            releaseDate: '2022-04-25T05:03:00Z',
            endDate: null,
            officialReleaseDate: null,
            registrationCloseDate: null,
            closeDate: null,
            isClosed: false,
            released: true,
            isJourney: false,
            isSelfPaced: false,
            typeOfRegistrationInWords: 'Free',
            headerColor: '#50c878',
            enrollmentLimitInDays: null,
            type: 'Course',
            typeOfRegistration: 0,
            headerBackground: null,
            openInJourney: null,
            currentLecture: {
              currentLecture: null,
              firstLecture: null,
            },
            numEnrolled: 1,
            userCourse: null,
            ended: false,
            registrationClosed: false,
            soaCertificateEnabled: null,
            entitlements: [],
            canTakeCourse: true,
            inJourneys: [
              {
                id: 5991,
                catalogId: 'journey-nov-75067-01',
                name: 'journey-NOV-75067-01',
              },
            ],
            matchingEntitlements: false,
          },
        ],
        completionSettings: 0,
        completionSettingsString: 'all_ordered',
        completionStats: null,
      },
    ],
    collectionsCount: 1,
    coursesCount: 3,
    completionStats: {
      total: 3,
      required: 3,
      completed: 0,
      collections: [
        {
          id: 524,
          total: 3,
          required: 3,
          completed: 0,
        },
      ],
    },
    institutionFontColor: '#000',
  },
  {
    id: 5380,
    catalogId: 'journey-nov-73013-03',
    name: 'journey-NOV-73013-03',
    thumbnail: '/assets/brand/temp.png',
    squareThumbnail: '/assets/brand/temp.png',
    flyerLogo: null,
    releaseDate: '2022-01-01T06:00:00Z',
    endDate: null,
    createdAt: '2022-01-07T19:24:00Z',
    inProgress: true,
    allRoleNames: [],
    catalogName: 'journey-NOV-73013-03',
    officialReleaseDate: null,
    registrationCloseDate: null,
    closeDate: null,
    beingOffered: null,
    institutionId: 187,
    institution: {
      id: 187,
      name: 'Kwok Black Institution1',
      logo: null,
      brandColor: '#50c878',
      brandBarFontColor: '#000',
      documentSharingProvider: '',
      analyticsIdentifier: '',
      nickname: 'kblackinstitution1',
      host: 'kblackinstitution1.emerald.novoed.com',
      isInstitutionalAdmin: true,
      adminRole: 'org_admin',
      welcomeEmailEnabled: true,
      directUpload: false,
      disableDiscussionEdits: false,
    },
    isClosed: true,
    statementsReleased: false,
    version: '2.0',
    released: true,
    parentPrograms: [],
    isProgram: false,
    isJourney: true,
    isSelfPaced: null,
    showRecentlyActive: true,
    typeOfRegistrationInWords: 'Closed enrollment',
    inArchiveMode: false,
    expiringPointsEnabled: false,
    gamificationLeaderboardEnabled: false,
    gamificationEnabled: false,
    helpWidget: null,
    gamificationLeaderboardNumStudentsToShow: 0,
    classSummary: null,
    headerColor: '#50c878',
    nameFontColor: '#000',
    logo: null,
    enrollmentLimitInDays: null,
    type: 'Journey',
    automatedCompletionsEnabled: true,
    contentLanguage: 'en_US',
    welcomeMessage: null,
    showWelcomeMessageAtTop: null,
    lastActivity: null,
    automaticCompletionCriteria: {
      totalTodosCount: 0,
      unreleasedTodosCount: 0,
      unreleasedTodoAssignmentsCount: 0,
      automatedCompletionsEnabled: true,
      totalRequiredTodosCount: 0,
      totalTodoAssignmentsCount: 0,
      requiredPoints: 0,
      requiredAssignmentsCount: 0,
      soaCertificateEnabled: false,
    },
    instructorsForFlyer: null,
    enrolledStudentsListUrl: null,
    usedFor: 0,
    welcomeEmailEnabled: true,
    visitedHome: null,
    enrolledStudentsCount: 2,
    url: '/journey-nov-73013-03',
    offeredTo: [],
    typeOfRegistration: 3,
    coverPhotoUrl: '/assets/brand/temp.png',
    largeCoverPhotoUrl: '/assets/brand/temp.png',
    headerBackground: null,
    calendarEventsEnabled: false,
    openInJourney: false,
    currentLecture: null,
    instructorsFullName: '',
    institutionName: 'Kwok Black Institution1',
    institutionBrandColor: '#50c878',
    institutionBrandBarFontColor: '#000',
    executiveSummary: null,
    priceText: '',
    tags: [],
    programCourses: null,
    rate: null,
    hasCourseLongTeams: null,
    isPaidAndOpenEnrollment: false,
    closedEnrollment: true,
    officiallyReleased: false,
    numEnrolled: 2,
    numUnpaid: 0,
    numPaid: 0,
    numBanned: 0,
    numWithdrawn: 0,
    isNovoedAdmin: false,
    programId: null,
    creator: {
      firstName: 'Sebastian',
      lastName: 'S1',
      fullName: 'Sebastian S1',
      userNameForUrl: 'sebastian_orgadmin',
      initials: 'SS',
    },
    hasGroups: false,
    numForumsReleased: 0,
    numFeatureableExercises: 0,
    hasHardDeadlineExercises: false,
    hasHardDeadlineTeamExercises: false,
    courseLongTeamSet: null,
    groupTeamSet: null,
    parentCourseId: 5380,
    cardView: false,
    showCommunityPanel: true,
    hasCommunity: true,
    userCourse: null,
    publiclySharedFlyer: true,
    shareableTweet: null,
    classVideoHtml: null,
    promoPicture: null,
    customFlier: false,
    classDescription: '<p class="froala-style-regular" dir="ltr">1</p>',
    moreInformation: null,
    price: 0,
    userAuthenticatedThroughInstitution: false,
    ended: false,
    shopifyEnabled: false,
    registrationClosed: false,
    flyerCoverPhoto: null,
    landingPageComponents: null,
    isBeingDeleted: false,
    canStopSharingVideos: true,
    hasAnyTeams: false,
    hasCourseLongTeamSet: false,
    hasGroupSet: false,
    hasProfileRequirement: false,
    profileRequirement: null,
    soaCertificateEnabled: false,
    loginLearners: {
      loggedIn: 0,
      percentage: 0,
    },
    learnersProgress: {
      inProgress: 0,
      percentage: 0,
    },
    learnersCompleted: {
      learnersCompleted: 0,
      percentage: 0,
    },
    activeLearners: {
      learnersActive: 0,
      percentage: 0,
    },
    learnersInactive: 0,
    duration: null,
    durationType: null,
    numCoursesSharingVideoLibrary: 0,
    hasExtraTerms: false,
    entitlements: [],
    canTakeCourse: true,
    logoSize: 'small',
    lmsCourse: null,
    degreedCourseId: null,
    isTosRequiredForCourseComms: false,
    inJourneys: [],
    hasBeenGrantedManualCompletion: false,
    blockingJourneyCatalogId: null,
    matchingEntitlements: false,
    skillTags: [],
    courseMetadata: [],
    description: '<p class="froala-style-regular" dir="ltr">1</p>',
    collections: [
      {
        id: 430,
        name: '1',
        description: '<p class="froala-style-regular" dir="ltr">1</p>',
        index: 0,
        numRequiredCourses: 1,
        courses: [
          {
            id: 5373,
            catalogId: 'course-nov-73013-02',
            name: 'course-NOV-73013-02',
            thumbnail: '/assets/brand/temp.png',
            releaseDate: '2022-01-01T06:00:00Z',
            endDate: null,
            officialReleaseDate: null,
            registrationCloseDate: null,
            closeDate: null,
            isClosed: false,
            released: true,
            isJourney: false,
            isSelfPaced: false,
            typeOfRegistrationInWords: 'Free',
            headerColor: '#50c878',
            enrollmentLimitInDays: null,
            type: 'Course',
            typeOfRegistration: 0,
            headerBackground: null,
            openInJourney: null,
            currentLecture: {
              currentLecture: null,
              firstLecture: {
                id: 866440,
                title: 'New Lesson',
                type: 'LecturePage',
              },
            },
            numEnrolled: 2,
            userCourse: {
              userId: 1110398,
              courseId: 5373,
              paid: true,
              url:
                'https://kblackinstitution1.emerald.novoed.com/#!/courses/course-nov-73013-02/users/sebastian_orgadmin',
              receivesStatementOfAccomplishment: false,
              user: {
                id: 1110398,
                admin: false,
                firstName: 'Sebastian',
                lastName: 'S1',
                fullName: 'Sebastian S1',
                userNameForUrl: 'sebastian_orgadmin',
                roles: {
                  permission: 54,
                  courseRoleId: 856,
                  role: 'Super Admin',
                  roles: {
                    permission: 54,
                    admin: false,
                    name: {
                      capitalizedPluralized: 'Super admins',
                      capitalizedSingularized: 'Super admin',
                      downcasedSingularized: 'super admin',
                      downcasedPluralized: 'super admins',
                      pluralizedTitleized: 'Super Admins',
                      singularizedTitleized: 'Super Admin',
                    },
                    menteeName: null,
                    badge: 'SA',
                  },
                  isCourseAdmin: true,
                },
                profilePicture: null,
                initials: 'SS',
              },
              role: 'Super Admin',
              roles: {
                permission: 54,
                admin: false,
                name: {
                  capitalizedPluralized: 'Super admins',
                  capitalizedSingularized: 'Super admin',
                  downcasedSingularized: 'super admin',
                  downcasedPluralized: 'super admins',
                  pluralizedTitleized: 'Super Admins',
                  singularizedTitleized: 'Super Admin',
                },
                menteeName: null,
                badge: 'SA',
              },
              isCourseAdmin: true,
              accessCloseDate: null,
              accessInArchiveMode: false,
              banned: false,
              dropped: false,
              isEnrolled: true,
              termsAcceptanceNeeded: false,
              courseLongTeam: null,
              mentors: [],
            },
            ended: false,
            registrationClosed: false,
            soaCertificateEnabled: false,
            entitlements: [],
            canTakeCourse: true,
            inJourneys: [
              {
                id: 5371,
                catalogId: 'journey-nov-73013',
                name: 'journey-NOV-73013',
              },
              {
                id: 5376,
                catalogId: 'journey-nov-73013-02',
                name: 'journey-nov-73013-02',
              },
              {
                id: 5380,
                catalogId: 'journey-nov-73013-03',
                name: 'journey-NOV-73013-03',
              },
            ],
            matchingEntitlements: false,
          },
          {
            id: 5378,
            catalogId: 'course-nov-73013-05',
            name: 'course-NOV-73013-05',
            thumbnail: '/assets/brand/temp.png',
            releaseDate: '2022-01-01T06:00:00Z',
            endDate: null,
            officialReleaseDate: null,
            registrationCloseDate: null,
            closeDate: null,
            isClosed: false,
            released: true,
            isJourney: false,
            isSelfPaced: false,
            typeOfRegistrationInWords: 'Free',
            headerColor: '#50c878',
            enrollmentLimitInDays: null,
            type: 'Course',
            typeOfRegistration: 0,
            headerBackground: null,
            openInJourney: null,
            currentLecture: {
              currentLecture: null,
              firstLecture: {
                id: 866439,
                title: 'New Lesson',
                type: 'LecturePage',
              },
            },
            numEnrolled: 2,
            userCourse: {
              userId: 1110398,
              courseId: 5378,
              paid: true,
              url:
                'https://kblackinstitution1.emerald.novoed.com/#!/courses/course-nov-73013-05/users/sebastian_orgadmin',
              receivesStatementOfAccomplishment: false,
              user: {
                id: 1110398,
                admin: false,
                firstName: 'Sebastian',
                lastName: 'S1',
                fullName: 'Sebastian S1',
                userNameForUrl: 'sebastian_orgadmin',
                roles: {
                  permission: 54,
                  courseRoleId: 856,
                  role: 'Super Admin',
                  roles: {
                    permission: 54,
                    admin: false,
                    name: {
                      capitalizedPluralized: 'Super admins',
                      capitalizedSingularized: 'Super admin',
                      downcasedSingularized: 'super admin',
                      downcasedPluralized: 'super admins',
                      pluralizedTitleized: 'Super Admins',
                      singularizedTitleized: 'Super Admin',
                    },
                    menteeName: null,
                    badge: 'SA',
                  },
                  isCourseAdmin: true,
                },
                profilePicture: null,
                initials: 'SS',
              },
              role: 'Super Admin',
              roles: {
                permission: 54,
                admin: false,
                name: {
                  capitalizedPluralized: 'Super admins',
                  capitalizedSingularized: 'Super admin',
                  downcasedSingularized: 'super admin',
                  downcasedPluralized: 'super admins',
                  pluralizedTitleized: 'Super Admins',
                  singularizedTitleized: 'Super Admin',
                },
                menteeName: null,
                badge: 'SA',
              },
              isCourseAdmin: true,
              accessCloseDate: null,
              accessInArchiveMode: false,
              banned: false,
              dropped: false,
              isEnrolled: true,
              termsAcceptanceNeeded: false,
              courseLongTeam: null,
              mentors: [],
            },
            ended: false,
            registrationClosed: false,
            soaCertificateEnabled: false,
            entitlements: [],
            canTakeCourse: true,
            inJourneys: [
              {
                id: 5380,
                catalogId: 'journey-nov-73013-03',
                name: 'journey-NOV-73013-03',
              },
              {
                id: 5471,
                catalogId: 'journey-nov-73103',
                name: 'journey-NOV-73103',
              },
            ],
            matchingEntitlements: false,
          },
          {
            id: 5332,
            catalogId: 'course-nov-72969-001',
            name: 'course-NOV-72969',
            thumbnail: '/assets/brand/temp.png',
            releaseDate: '2022-01-01T06:00:00Z',
            endDate: null,
            officialReleaseDate: '2022-01-01T06:00:00Z',
            registrationCloseDate: null,
            closeDate: null,
            isClosed: false,
            released: true,
            isJourney: false,
            isSelfPaced: false,
            typeOfRegistrationInWords: 'Free',
            headerColor: '#50c878',
            enrollmentLimitInDays: null,
            type: 'Course',
            typeOfRegistration: 0,
            headerBackground: null,
            openInJourney: null,
            currentLecture: {
              currentLecture: null,
              firstLecture: {
                id: 866412,
                title: 'New Lesson',
                type: 'LecturePage',
              },
            },
            numEnrolled: 1,
            userCourse: null,
            ended: false,
            registrationClosed: false,
            soaCertificateEnabled: false,
            entitlements: [],
            canTakeCourse: true,
            inJourneys: [
              {
                id: 5352,
                catalogId: 'journey-nov-73005-01',
                name: 'journey-NOV-73005 Closed enrollment',
              },
              {
                id: 5370,
                catalogId: 'journey-nov-73014',
                name: 'journey-NOV-73014',
              },
              {
                id: 5380,
                catalogId: 'journey-nov-73013-03',
                name: 'journey-NOV-73013-03',
              },
            ],
            matchingEntitlements: false,
          },
        ],
        completionSettings: 2,
        completionSettingsString: 'min_unordered',
        completionStats: null,
      },
      {
        id: 431,
        name: '2',
        description: '<p class="froala-style-regular" dir="ltr">2</p>',
        index: 1,
        numRequiredCourses: null,
        courses: [
          {
            id: 5379,
            catalogId: 'course-nov-73013-06',
            name: 'course-NOV-73013-06',
            thumbnail: '/assets/brand/temp.png',
            releaseDate: '2022-01-01T06:00:00Z',
            endDate: null,
            officialReleaseDate: null,
            registrationCloseDate: null,
            closeDate: null,
            isClosed: false,
            released: true,
            isJourney: false,
            isSelfPaced: false,
            typeOfRegistrationInWords: 'Free',
            headerColor: '#50c878',
            enrollmentLimitInDays: null,
            type: 'Course',
            typeOfRegistration: 0,
            headerBackground: null,
            openInJourney: null,
            currentLecture: {
              currentLecture: null,
              firstLecture: {
                id: 866438,
                title: 'New Lesson',
                type: 'LecturePage',
              },
            },
            numEnrolled: 2,
            userCourse: {
              userId: 1110398,
              courseId: 5379,
              paid: true,
              url:
                'https://kblackinstitution1.emerald.novoed.com/#!/courses/course-nov-73013-06/users/sebastian_orgadmin',
              receivesStatementOfAccomplishment: false,
              user: {
                id: 1110398,
                admin: false,
                firstName: 'Sebastian',
                lastName: 'S1',
                fullName: 'Sebastian S1',
                userNameForUrl: 'sebastian_orgadmin',
                roles: {
                  permission: 54,
                  courseRoleId: 856,
                  role: 'Super Admin',
                  roles: {
                    permission: 54,
                    admin: false,
                    name: {
                      capitalizedPluralized: 'Super admins',
                      capitalizedSingularized: 'Super admin',
                      downcasedSingularized: 'super admin',
                      downcasedPluralized: 'super admins',
                      pluralizedTitleized: 'Super Admins',
                      singularizedTitleized: 'Super Admin',
                    },
                    menteeName: null,
                    badge: 'SA',
                  },
                  isCourseAdmin: true,
                },
                profilePicture: null,
                initials: 'SS',
              },
              role: 'Super Admin',
              roles: {
                permission: 54,
                admin: false,
                name: {
                  capitalizedPluralized: 'Super admins',
                  capitalizedSingularized: 'Super admin',
                  downcasedSingularized: 'super admin',
                  downcasedPluralized: 'super admins',
                  pluralizedTitleized: 'Super Admins',
                  singularizedTitleized: 'Super Admin',
                },
                menteeName: null,
                badge: 'SA',
              },
              isCourseAdmin: true,
              accessCloseDate: null,
              accessInArchiveMode: false,
              banned: false,
              dropped: false,
              isEnrolled: true,
              termsAcceptanceNeeded: false,
              courseLongTeam: null,
              mentors: [],
            },
            ended: false,
            registrationClosed: false,
            soaCertificateEnabled: false,
            entitlements: [],
            canTakeCourse: true,
            inJourneys: [
              {
                id: 5380,
                catalogId: 'journey-nov-73013-03',
                name: 'journey-NOV-73013-03',
              },
              {
                id: 5471,
                catalogId: 'journey-nov-73103',
                name: 'journey-NOV-73103',
              },
            ],
            matchingEntitlements: false,
          },
          {
            id: 5377,
            catalogId: 'course-nov-73013-04',
            name: 'course-NOV-73013-04',
            thumbnail: '/assets/brand/temp.png',
            releaseDate: '2022-01-01T06:00:00Z',
            endDate: null,
            officialReleaseDate: null,
            registrationCloseDate: null,
            closeDate: null,
            isClosed: false,
            released: true,
            isJourney: false,
            isSelfPaced: false,
            typeOfRegistrationInWords: 'Free',
            headerColor: '#50c878',
            enrollmentLimitInDays: null,
            type: 'Course',
            typeOfRegistration: 0,
            headerBackground: null,
            openInJourney: null,
            currentLecture: {
              currentLecture: {
                activityType: 'LecturePage',
                activityDeliverableType: null,
                activityId: 866437,
                actionName: 'view',
                happenedAt: '2022-04-05T18:55:00Z',
                title: 'New Lesson',
              },
              firstLecture: null,
            },
            numEnrolled: 3,
            userCourse: {
              userId: 1110398,
              courseId: 5377,
              paid: false,
              url:
                'https://kblackinstitution1.emerald.novoed.com/#!/courses/course-nov-73013-04/users/sebastian_orgadmin',
              receivesStatementOfAccomplishment: false,
              user: {
                id: 1110398,
                admin: false,
                firstName: 'Sebastian',
                lastName: 'S1',
                fullName: 'Sebastian S1',
                userNameForUrl: 'sebastian_orgadmin',
                roles: {
                  permission: null,
                  courseRoleId: null,
                  role: 'No Role',
                  roles: {
                    permission: null,
                    admin: false,
                    name: null,
                    menteeName: null,
                    badge: null,
                  },
                  isCourseAdmin: false,
                },
                profilePicture: null,
                initials: 'SS',
              },
              role: 'No Role',
              roles: {
                permission: null,
                admin: false,
                name: null,
                menteeName: null,
                badge: null,
              },
              isCourseAdmin: false,
              accessCloseDate: null,
              accessInArchiveMode: false,
              banned: false,
              dropped: false,
              isEnrolled: true,
              termsAcceptanceNeeded: false,
              courseLongTeam: null,
              mentors: [],
            },
            ended: false,
            registrationClosed: false,
            soaCertificateEnabled: false,
            entitlements: [],
            canTakeCourse: true,
            inJourneys: [
              {
                id: 5380,
                catalogId: 'journey-nov-73013-03',
                name: 'journey-NOV-73013-03',
              },
            ],
            matchingEntitlements: false,
          },
        ],
        completionSettings: 0,
        completionSettingsString: 'all_ordered',
        completionStats: null,
      },
    ],
    collectionsCount: 2,
    coursesCount: 5,
    completionStats: null,
    institutionFontColor: '#000',
  },
];

const CoursesMocks: MockDefinition[] = [
  {
    type: MockTypes.GET,
    url: '/users/current_learning.json?journeys_only=1',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: journeys,
    },
  },
];

export default CoursesMocks;

