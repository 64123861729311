import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import NvIcon from 'shared/components/nv-icon';
import NvLikeButton from 'shared/components/nv-like-button';
import { getSubmission } from 'redux/selectors/video-practice';
import { getVideoPracticeSubmissionLikers, likeVideoPracticeSubmission, undoLikeVideoPracticeSubmission } from 'redux/actions/video-practice';
import { PracticeSubmissionActionParams } from 'redux/schemas/api/video-practice';
import { useAppDispatch } from 'redux/store';
import { css } from '@emotion/react';
import { gray7 } from 'styles/global_defaults/colors';
import { tripleSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import { PracticeSubmissionContext } from '../utils';
import { config } from '../../../../../config/pendo.config.json';

const styles = (isMyPractice) => css`
  &.social-actions {
    height: ${tripleSpacing}px;
    background-color: ${gray7};
    .action-item {
      .icon {
        ${isMyPractice && 'pointer-events: none;'}
        margin-right: ${quarterSpacing}px;
        html[dir="rtl"] & {
          margin-left: ${quarterSpacing}px;
        }
      }
      .likes {
        cursor: pointer;
      }
    }
  }
`;

const SocialActions = (props: { isMyPractice: boolean }) => {
  const [{ submissionId, isPracticeFeedback, scenarioId }] = useContext(PracticeSubmissionContext);
  const {
    isLikedByCurrentUser,
    likedUsers,
    numComments,
    numViews,
    numLikes,
  } = useSelector((state) => getSubmission(state, submissionId));
  const { practiceFeedbackCriteriaId } = useSelector(state => state.app.videoPracticeFeedback);

  const dispatch = useAppDispatch();

  const onLike = useCallback(() => {
    if (scenarioId && submissionId) {
      const params: PracticeSubmissionActionParams = { scenarioId, submissionId };
      if (isPracticeFeedback) {
        params.practiceFeedbackCriteriaId = practiceFeedbackCriteriaId;
      }
      dispatch(likeVideoPracticeSubmission(params));
    }
  }, [dispatch, practiceFeedbackCriteriaId, isPracticeFeedback, scenarioId, submissionId]);

  const onUndoLike = useCallback(() => {
    if (scenarioId && submissionId) {
      const params: PracticeSubmissionActionParams = { scenarioId, submissionId };
      if (isPracticeFeedback) {
        params.practiceFeedbackCriteriaId = practiceFeedbackCriteriaId;
      }
      dispatch(undoLikeVideoPracticeSubmission(params));
    }
  }, [dispatch, practiceFeedbackCriteriaId, isPracticeFeedback, scenarioId, submissionId]);

  const onGetPracticeSubmissionLikers = useCallback(() => {
    if (scenarioId && submissionId) {
      dispatch(getVideoPracticeSubmissionLikers({ scenarioId, submissionId }));
    }
  }, [dispatch, scenarioId, submissionId]);
  let likeIconClass = isLikedByCurrentUser ? 'text-primary' : 'text-gray-3';
  if (props.isMyPractice) {
    likeIconClass = 'text-gray-5';
  }

  return (
    <div
      className='social-actions d-flex align-items-center pl-1'
      dir='ltr'
      css={styles(props.isMyPractice)}
    >
      <div
        className='action-item d-flex align-items-center m-3'
        pendo-tag-name={config.pendo.practice.likePractice}
      >
        <NvLikeButton
          isLiked={isLikedByCurrentUser}
          likedCount={numLikes}
          likedUsers={likedUsers}
          isDisabled={props.isMyPractice}
          iconSize='small'
          iconClassName={likeIconClass}
          textClassName='likes text-primary'
          onLike={onLike}
          onUndoLike={onUndoLike}
          showLikersInfo
          getLikersInfo={onGetPracticeSubmissionLikers}
        />
      </div>
      {numComments > 0 && (
        <div className='action-item  d-flex align-items-center m-3'>
          <NvIcon size='small' icon='comments' className='text-gray-3' />
          <span className='text-gray-2'>{numComments}</span>
        </div>
      )}
      {numViews > 0 && (
        <div className='action-item  d-flex align-items-center m-3'>
          <NvIcon size='medium' icon='view' className='text-gray-3' />
          <span className='text-gray-2'>{numViews}</span>
        </div>
      )}
    </div>
  );
};
export default SocialActions;
