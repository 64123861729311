/* @ngInject */
export default function PublicPracticeFeedbackCriteriaLectureComponentModel(
  _,
  LectureComponentBaseModel,
) {
  class PublicPracticeFeedbackCriteriaLectureComponent extends LectureComponentBaseModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      const defaults = {
        type: 'PublicPracticeFeedbackCriteriaLectureComponent',
        editFormTemplateUrl: 'lecture_pages/templates/components/video-practice-evaluation-modal.html',
        editFormController: 'VideoPracticeEvaluationCtrl',
        publicFeedback: {},
        canBeCopied: true,
      };
      super(_.extend({}, defaults, attributes));
    }

    getPayload() {
      const publicFeedback = {
        ...this.publicFeedback, // publicFeedback is the field returned by API
        ...this.publicFeedback,
      };

      return { publicFeedback };
    }

    /* Admin Functions */
    setRealComponent(component) {
      this.realComponent = component;
    }

    saveDraft() {
    //  return this.save();
    }

    toggleToDo() {
      this.publicFeedback.isTodo = !this.publicFeedback.isTodo;
      this.save();
    }
  }

  PublicPracticeFeedbackCriteriaLectureComponent.iconClass = 'icon-peer-feedback';
  PublicPracticeFeedbackCriteriaLectureComponent.showModalBeforeCreate = true;
  PublicPracticeFeedbackCriteriaLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.DESCRIPTION';
  PublicPracticeFeedbackCriteriaLectureComponent.unavailabilityDescriptionKey = 'LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.UNAVAILABLE';
  return PublicPracticeFeedbackCriteriaLectureComponent;
}
