import { some, each, pick, sortBy, isObject } from 'underscore';
import { RootState } from 'redux/schemas';
import { getCommunication, getItemState } from 'redux/selectors/course-communications';
import { ItemState } from 'redux/schemas/models/course-communication';
import { ComponentType, ComponentKeyPluralized, ComponentKey } from 'redux/schemas/models/lecture-component';
import { TimelineNormalized, TimelineItemTypes } from 'redux/schemas/models/course';
import { ActivityType } from 'redux/schemas/models/activity';
import { getPoll } from './polls';


export const getCurrentUserId = (state: RootState) => state.app.currentUserId;

export const getLectureSubsectionIds = (state: RootState) => state.models.courses[state.app.currentCatalogId]?.lectureSubsectionIds ?? [];
export const getLectureSectionIds = (state: RootState) => state.models.courses[state.app.currentCatalogId]?.lectureSectionIds ?? [];

export const getLectureSection = (state: RootState, lectureSectionId: number) => state.models.lectureSections[lectureSectionId];
export const getLecturePage = (state: RootState, lecturePageId: number) => state.models.lecturePages[lecturePageId];
export const getLectureComponent = (state: RootState, lectureComponentId: number) => state.models.lectureComponents[lectureComponentId];

export const getActivityIdsFromLecturePageId = (state: RootState, lecturePageId: number) => {
  const lecturePage = state.models.lecturePages[lecturePageId];

  const lectureComponents = lecturePage?.lectureComponents?.map((lectureComponentId) => state.models.lectureComponents[lectureComponentId]);

  const activities: { [key: string]: number[] } = {};

  each(lectureComponents, (lectureComponent) => {
    if (lectureComponent.type === ComponentType.VIDEO
      || lectureComponent.type === ComponentType.AUDIO) {
      activities[ComponentKeyPluralized.LECTURE_VIDEO] = [
        ...(activities[ComponentKeyPluralized.LECTURE_VIDEO] || []),
        ...lectureComponent.lectureVideos,
      ];
    } else {
      let activityType = null;

      switch (lectureComponent.type) {
        case ComponentType.EXERCISE_SKILLS_RATING:
          activityType = 'EXERCISE_SKILLS_RATING';
          break;
        case ComponentType.EXERCISE:
          activityType = 'EXERCISE';
          break;
        case ComponentType.EXTERNAL_TOOL:
          activityType = 'EXTERNAL_TOOL';
          break;
        case ComponentType.GROUP_FORMATION:
          activityType = 'GROUP_FORMATION';
          break;
        case ComponentType.LIVE_SESSION:
          activityType = 'LIVE_SESSION';
          break;
        case ComponentType.POLL:
          activityType = 'POLL';
          break;
        case ComponentType.PRIVATE_PEER_EVALUATION:
        case ComponentType.PUBLIC_PEER_EVALUATION:
          activityType = 'PEER_EVALUATION';
          break;
        case ComponentType.PROFILE_COMPLETION:
          activityType = 'PROFILE_REQUIREMENT';
          break;
        case ComponentType.PROGRESSIVE_QUIZ:
          activityType = 'PROGRESSIVE_QUIZ';
          break;
        case ComponentType.QUIZ:
          activityType = 'QUIZ';
          break;
        case ComponentType.SURVEY:
          activityType = 'SURVEY';
          break;
        case ComponentType.TEAM_DISCUSSION:
        case ComponentType.TOPIC:
          activityType = 'POST';
          break;
        case ComponentType.TEAM_FORMATION:
          activityType = 'TEAM_FORMATION';
          break;
        case ComponentType.TIMED_QUIZ:
          activityType = 'TIMED_QUIZ';
          break;
        case ComponentType.VIDEO_PRACTICE_FEEDBACK:
          activityType = 'VIDEO_PRACTICE_FEEDBACK';
          break;
        case ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK:
          activityType = 'VIDEO_PRACTICE_SKILLS_FEEDBACK';
          break;
        case ComponentType.VIDEO_PRACTICE:
          activityType = 'VIDEO_PRACTICE';
          break;
        default:
          activityType = null;
          break;
      }

      if (activityType) {
        activities[ComponentKeyPluralized[activityType]] = [
          ...(activities[ComponentKeyPluralized[activityType]] || []),
          isObject(lectureComponent[ComponentKey[activityType]])
            ? lectureComponent[ComponentKey[activityType]].id
            : lectureComponent[ComponentKey[activityType]],
        ];
      }
    }
  });
  return activities;
};

/* Get Lecture Activities */
export const getExercise = (state: RootState, exerciseId: number) => state.models.exercises[exerciseId];
export const hasExerciseGotCommunicationError = (state: RootState, exerciseId: number) => state.models.exercises[exerciseId]
  && state.models.exercises[exerciseId].communications
  && some(state.models.exercises[exerciseId].communications, (communicationId: number) => getCommunication(state, communicationId)?.hasErrors && getItemState(state, communicationId) !== ItemState.SENT);

export const getExerciseSkillsRatingActivity = (state: RootState, exerciseSkillsRatingActivityId: number) => state.models.exerciseSkillsRatingActivities[exerciseSkillsRatingActivityId];
export const hasExerciseSkillsRatingActivityGotCommunicationError = (state: RootState, exerciseSkillsRatingActivityId: number) => state.models.exerciseSkillsRatingActivities[exerciseSkillsRatingActivityId]
  && state.models.exerciseSkillsRatingActivities[exerciseSkillsRatingActivityId].communications
  && some(state.models.exerciseSkillsRatingActivities[exerciseSkillsRatingActivityId].communications, (communicationId: number) => getCommunication(state, communicationId)?.hasErrors && getItemState(state, communicationId) !== ItemState.SENT);

export const getQuiz = (state: RootState, quizId: number) => state.models.quizzes[quizId];
export const hasQuizGotCommunicationError = (state: RootState, quizId: number) => state.models.quizzes[quizId]
  && state.models.quizzes[quizId].communications
  && some(state.models.quizzes[quizId].communications, (communicationId: number) => getCommunication(state, communicationId)?.hasErrors && getItemState(state, communicationId) !== ItemState.SENT);

export const getTimedQuiz = (state: RootState, timedQuizId: number) => state.models.timedQuizzes[timedQuizId];
export const hasTimedQuizGotCommunicationError = (state: RootState, timedQuizId: number) => state.models.timedQuizzes[timedQuizId]
  && state.models.timedQuizzes[timedQuizId].communications
  && some(state.models.timedQuizzes[timedQuizId].communications, (communicationId: number) => getCommunication(state, communicationId)?.hasErrors && getItemState(state, communicationId) !== ItemState.SENT);

export const getSurvey = (state: RootState, surveyId: number) => state.models.surveys[surveyId];
export const hasSurveyGotCommunicationError = (state: RootState, surveyId: number) => state.models.surveys[surveyId]
  && state.models.surveys[surveyId].communications
  && some(state.models.surveys[surveyId].communications, (communicationId: number) => getCommunication(state, communicationId)?.hasErrors && getItemState(state, communicationId) !== ItemState.SENT);

export const getLiveSession = (state: RootState, liveSessionId: number) => state.models.liveSessions[liveSessionId];
export const hasLiveSessionGotCommunicationError = (state: RootState, liveSessionId: number) => state.models.liveSessions[liveSessionId]
  && state.models.liveSessions[liveSessionId].communications
  && some(state.models.liveSessions[liveSessionId].communications, (communicationId: number) => getCommunication(state, communicationId)?.hasErrors && getItemState(state, communicationId) !== ItemState.SENT);

export const getPeerEvaluation = (state: RootState, peerEvaluationId: number) => state.models.peerEvaluations[peerEvaluationId];
export const hasPeerEvaluationGotCommunicationError = (state: RootState, peerEvaluationId: number) => state.models.peerEvaluations[peerEvaluationId]
  && state.models.peerEvaluations[peerEvaluationId].communications
  && some(state.models.peerEvaluations[peerEvaluationId].communications, (communicationId: number) => getCommunication(state, communicationId)?.hasErrors && getItemState(state, communicationId) !== ItemState.SENT);

export const getTeamSet = (state: RootState, teamSetId: number) => state.models.teamSets[teamSetId];
export const hasTeamSetGotCommunicationError = (state: RootState, teamSetId: number) => state.models.teamSets[teamSetId]
  && state.models.teamSets[teamSetId].communications
  && some(state.models.teamSets[teamSetId].communications, (communicationId: number) => getCommunication(state, communicationId)?.hasErrors && getItemState(state, communicationId) !== ItemState.SENT);

export const getGroupTeamSet = (state: RootState, groupTeamSetId: number) => state.models.groupTeamSets[groupTeamSetId];
export const hasGroupTeamSetGotCommunicationError = (state: RootState, groupTeamSetId: number) => state.models.groupTeamSets[groupTeamSetId]
  && state.models.groupTeamSets[groupTeamSetId].communications
  && some(state.models.groupTeamSets[groupTeamSetId].communications, (communicationId: number) => getCommunication(state, communicationId)?.hasErrors && getItemState(state, communicationId) !== ItemState.SENT);

export const getPost = (state: RootState, postId: number) => state.models.posts[postId];
export const hasPostGotCommunicationError = (state: RootState, postId: number) => state.models.posts[postId]
  && state.models.posts[postId].communications
  && some(state.models.posts[postId].communications, (communicationId: number) => getCommunication(state, communicationId)?.hasErrors && getItemState(state, communicationId) !== ItemState.SENT);

export const getTeamDiscussion = (state: RootState, teamDiscussionId: number) => state.models.teamDiscussions[teamDiscussionId];
export const hasTeamDiscussionGotCommunicationError = (state: RootState, teamDiscussionId: number) => state.models.teamDiscussions[teamDiscussionId]
  && state.models.teamDiscussions[teamDiscussionId].communications
  && some(state.models.teamDiscussions[teamDiscussionId].communications, (communicationId: number) => getCommunication(state, communicationId)?.hasErrors && getItemState(state, communicationId) !== ItemState.SENT);

export const getExternalTool = (state: RootState, externalToolId: number) => state.models.externalTools[externalToolId];
export const hasExternalToolGotCommunicationError = (state: RootState, externalToolId: number) => state.models.externalTools[externalToolId]
  && state.models.externalTools[externalToolId].communications
  && some(state.models.externalTools[externalToolId].communications, (communicationId: number) => getCommunication(state, communicationId)?.hasErrors && getItemState(state, communicationId) !== ItemState.SENT);

export const getLectureVideo = (state: RootState, lectureVideoId: number) => state.models.lectureVideos[lectureVideoId];
export const getVideo = (state: RootState, lectureVideoId: number) => state.models.lectureVideos[lectureVideoId]?.video
  && state.models.video[state.models.lectureVideos[lectureVideoId]?.video];
export const hasLectureVideoGotCommunicationError = (state: RootState, lectureVideoId: number) => state.models.lectureVideos[lectureVideoId]
  && state.models.lectureVideos[lectureVideoId].communications
  && some(state.models.lectureVideos[lectureVideoId].communications, (communicationId: number) => getCommunication(state, communicationId)?.hasErrors && getItemState(state, communicationId) !== ItemState.SENT);

export const getProfileRequirement = (state: RootState, profileRequirementId: number) => state.models.profileRequirements[profileRequirementId];
export const hasProfileRequirementGotCommunicationError = (state: RootState, profileRequirementId: number) => state.models.profileRequirements[profileRequirementId]
  && state.models.profileRequirements[profileRequirementId].communications
  && some(state.models.profileRequirements[profileRequirementId].communications, (communicationId: number) => getCommunication(state, communicationId)?.hasErrors && getItemState(state, communicationId) !== ItemState.SENT);

export const getVideoPractice = (state: RootState, practiceActivityId: number) => state.models.practiceActivities[practiceActivityId];
export const hasVideoPracticeGotCommunicationError = (state: RootState, practiceActivityId: number) => state.models.practiceActivities[practiceActivityId]
  && state.models.practiceActivities[practiceActivityId].communications
  && some(state.models.practiceActivities[practiceActivityId].communications, (communicationId: number) => getCommunication(state, communicationId)?.hasErrors
    && getItemState(state, communicationId) !== ItemState.SENT);

export const getVideoPracticeFeedback = (state: RootState, practiceFeedbackActivityId: number) => state.models.practiceFeedbackActivities[practiceFeedbackActivityId];
export const hasVideoPracticeFeedbackGotCommunicationError = (state: RootState, practiceFeedbackActivityId: number) => state.models.practiceFeedbackActivities[practiceFeedbackActivityId]
  && state.models.practiceFeedbackActivities[practiceFeedbackActivityId].communications
  && some(state.models.practiceFeedbackActivities[practiceFeedbackActivityId].communications, (communicationId: number) => getCommunication(state, communicationId)?.hasErrors
    && getItemState(state, communicationId) !== ItemState.SENT);

export const getVideoPracticeSkillsFeedback = (state: RootState, exerciseSkillsRatingActivityId: number) => state.models.exerciseSkillsRatingActivities[exerciseSkillsRatingActivityId];
export const hasVideoPracticeSkillsFeedbackGotCommunicationError = (state: RootState, exerciseSkillsRatingActivityId: number) => state.models.exerciseSkillsRatingActivities[exerciseSkillsRatingActivityId]
  && state.models.exerciseSkillsRatingActivities[exerciseSkillsRatingActivityId].communications
  && some(state.models.exerciseSkillsRatingActivities[exerciseSkillsRatingActivityId].communications, (communicationId: number) => getCommunication(state, communicationId)?.hasErrors
    && getItemState(state, communicationId) !== ItemState.SENT);

export const getProgressiveQuiz = (state: RootState, progressiveQuizActivityId: number) => state.models.progressiveQuizzes[progressiveQuizActivityId];

export const hasProgressiveQuizGotCommunicationError = (state: RootState, progressiveQuizActivityId: number) => state.models.progressiveQuizzes[progressiveQuizActivityId]
  && state.models.progressiveQuizzes[progressiveQuizActivityId].communications
  && some(state.models.progressiveQuizzes[progressiveQuizActivityId].communications, (communicationId: number) => getCommunication(state, communicationId)?.hasErrors
    && getItemState(state, communicationId) !== ItemState.SENT);

export const getActivity = (state: RootState, activityId: number, activityType: ActivityType) => {
  switch (activityType) {
    case ActivityType.EXERCISE_SKILLS_RATING:
      return getExerciseSkillsRatingActivity(state, activityId);
    case ActivityType.EXERCISE:
      return getExercise(state, activityId);
    case ActivityType.EXTERNAL_TOOL:
      return getExternalTool(state, activityId);
    case ActivityType.GROUP_FORMATION:
      return getGroupTeamSet(state, activityId);
    case ActivityType.LECTURE_VIDEO:
      return getLectureVideo(state, activityId);
    case ActivityType.LIVE_SESSION:
      return getLiveSession(state, activityId);
    case ActivityType.PEER_EVALUATION:
      return getPeerEvaluation(state, activityId);
    case ActivityType.POLL:
      return getPoll(state, { pollId: activityId });
    case ActivityType.POST:
      return getPost(state, activityId);
    case ActivityType.PROGRESSIVE_QUIZ:
      return getProgressiveQuiz(state, activityId);
    case ActivityType.QUIZ:
      return getQuiz(state, activityId);
    case ActivityType.SURVEY:
      return getSurvey(state, activityId);
    case ActivityType.TEAM_DISCUSSION:
      return getTeamDiscussion(state, activityId);
    case ActivityType.TEAM_FORMATION:
      return getTeamSet(state, activityId);
    case ActivityType.TIMED_EXAM:
      return getTimedQuiz(state, activityId);
    case ActivityType.VIDEO_PRACTICE_FEEDBACK:
      return getVideoPracticeFeedback(state, activityId);
    case ActivityType.VIDEO_PRACTICE:
      return getVideoPractice(state, activityId);
    default:
      return null;
  }
};

export const getCourseTimeLine = (state: RootState) => {
  const timeline: TimelineNormalized[] = [];
  const lectureMainSections = [
    ...(state.models.courses[state.app.currentCatalogId]?.lectureSectionIds ?? []),
    ...(state.models.courses[state.app.currentCatalogId]?.lectureSubsectionIds ?? []),
  ];
  // Loop through each section and and push to the timeline Object
  each(sortBy(lectureMainSections, (mainSectionId) => state.models.lectureSections[mainSectionId].index), (lectureSectionId: number) => {
    timeline.push(
      {
        ...pick(state.models.lectureSections[lectureSectionId], 'id', 'title'),
        type: (state.models.courses[state.app.currentCatalogId]?.lectureSubsectionIds.includes(lectureSectionId) ? TimelineItemTypes.SUBSECTION : TimelineItemTypes.SECTION),
      },
    );

    // Loop Lecture Pages Inside the Section
    const lecturePageIds = state.models.lectureSections[lectureSectionId]?.lecturePages ?? [];
    each(lecturePageIds, (lecturePageId: number) => {
      timeline.push({ ...pick(state.models.lecturePages[lecturePageId], 'id', 'title'), type: TimelineItemTypes.PAGE });
    });

    // Loop Subsections
    const lectureSubsectionIds = state.models.lectureSections[lectureSectionId]?.lectureSubsections ?? [];
    each(lectureSubsectionIds, (lectureSubSectionId: number) => {
      timeline.push({ ...pick(state.models.lectureSections[lectureSubSectionId], 'id', 'title'), type: TimelineItemTypes.SUBSECTION });

      // Loop Pages
      const lectureSubsectionPageIds = state.models.lectureSections[lectureSubSectionId]?.lecturePages ?? [];
      each(lectureSubsectionPageIds, (lecturePageId: number) => {
        timeline.push({ ...pick(state.models.lecturePages[lecturePageId], 'id', 'title'), type: TimelineItemTypes.PAGE });
      });
    });
  });
  return timeline;
};
