import t from 'react-translate';

type ValidationProps = {
  text: string,
  title?: string,
  textFontClassName?: string,
  id?: string,
};

const ValidationErrorMessage = (props: ValidationProps) => (
  <div
    className={`flex-column ${props.textFontClassName}`}
    id={props.id}
    role='alert'
    aria-live='assertive'
  >
    <div className={`mx-auto text-danger text-center ${props.textFontClassName ?? 'font-weight-bold'}`}>
      {props.title ?? t.FORM.WARNING()}
    </div>
    <div className='mt-2'>{props.text}</div>
  </div>
);

export default ValidationErrorMessage;
