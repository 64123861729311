/* @ngInject */
export default function NvToggleFocus(
  $timeout,
  $parse,
) {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      const focusTrigger = $parse(attrs.nvFocusTrigger);

      scope.$watch(
        focusTrigger,
        (newValue, oldValue) => {
          $timeout(() => {
            if (newValue) {
              if (element.is('[nv-froala-editor]')) {
                element.data().focus();
              } else {
                element.focus();
              }
            } else {
              element.blur();
            }
          }, 1);
        },
      );
    },
  };
}
