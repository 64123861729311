import { createReducer } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { uniq, indexOf, without } from 'underscore';
import mergeWith from 'lodash/mergeWith';
import { replaceArrays } from 'shared/lodash-utils';

import {
  fetchBookmarks,
  createBookmark,
  updateBookmark,
  deleteBookmark,
  highlightBookmark,
  unhighlightBookmark,
  filterBookmarks,
  addBookmark,
  fetchSingleBookmark,
  focusBookmark,
  removeCourseBookmarks,
  unsetLastDeletedBookmark,
} from 'redux/actions/bookmarks';
import { FetchBookmarksEntities, FetchBookmarksSchema } from 'redux/schemas/api/bookmarks';
import { BookmarkType, CourseBookmark, CourseObjectBookmark } from 'redux/schemas/models/bookmark';
import { initialRootState } from '.';

const getBookmarkObject = (state, type, componentId) => {
  switch (type) {
    case BookmarkType.ATTACHMENT:
      if (state.models.attachments[componentId]) {
        return state.models.attachments[componentId];
      }
      break;
    case BookmarkType.TOPIC:
      if (state.models.posts[componentId]) {
        return state.models.posts[componentId];
      }
      break;
    case BookmarkType.POST:
      if (state.models.comments[componentId]) {
        return state.models.comments[componentId];
      }
      break;
    case BookmarkType.COMMENT:
      if (state.models.replies[componentId]) {
        return state.models.replies[componentId];
      }
      break;
    case BookmarkType.IMAGE:
      if (state.models.lectureComponents[componentId]) {
        return state.models.lectureComponents[componentId];
      }
      break;
    case BookmarkType.AUDIO:
    case BookmarkType.VIDEO:
      if (state.models.lectureVideos[componentId]) {
        return state.models.lectureVideos[componentId];
      }
      break;
    case BookmarkType.LECTURE_PAGE:
      if (state.models.lecturePages[componentId]) {
        return state.models.lecturePages[componentId];
      }
      break;
    default:
      return null;
  }
  return null;
};

export default createReducer(initialRootState, builder => {
  builder
    .addCase(fetchBookmarks.pending, (state, action) => {
      state.app.bookmarks.loading = true;
    })
    .addCase(fetchBookmarks.rejected, (state, action) => {
      state.app.bookmarks.loading = false;
    })
    .addCase(fetchBookmarks.fulfilled, (state, action) => {
      state.app.bookmarks.loading = false;
      let entities: FetchBookmarksEntities;
      let result = [];

      if (action.payload.bookmarks) {
        ({ entities, result = [] } = normalize<[], FetchBookmarksEntities>(action.payload.bookmarks, FetchBookmarksSchema));
      }
      const { page, catalogId } = action.meta.arg;

      if (entities) {
        state.app.bookmarks.all = mergeWith(state.app.bookmarks.all, entities.bookmarks, replaceArrays);

        if (catalogId) {
          if (!state.app.bookmarks.courses[catalogId]) {
            state.app.bookmarks.courses[catalogId] = { ids: [] };
          }

          if (page === 1) {
            state.app.bookmarks.courses[catalogId].ids = [...result];
          } else {
            state.app.bookmarks.courses[catalogId].ids = uniq([...state.app.bookmarks.courses[catalogId].ids, ...result]);
          }
        } else if (page === 1) {
          state.app.bookmarks.ids = [...result];
        } else {
          state.app.bookmarks.ids = uniq([...state.app.bookmarks.ids, ...result]);
        }
      }
    })
    .addCase(fetchSingleBookmark.fulfilled, (state, action) => {
      const bookmark = action.payload;
      state.app.bookmarks.ids.push(bookmark.id);
      state.app.bookmarks.all[bookmark.id] = bookmark;
    })
    .addCase(createBookmark.fulfilled, (state, action) => {
      const { catalogId } = action.meta.arg;
      const { id, type } = action.payload.bookmark;

      if (catalogId) {
        state.app.bookmarks.courses[catalogId]?.ids.unshift(id);

        const { component } = (action.payload.bookmark as CourseObjectBookmark);
        const object = getBookmarkObject(state, type, component.id);
        if (object) {
          object.bookmarkId = id;
        }
      }
      state.app.bookmarks.ids.unshift(id);
      state.app.bookmarks.all[id] = action.payload.bookmark;
      state.app.bookmarks.highlighted = null;
      state.app.bookmarks.focusNew = id;
    })
    .addCase(addBookmark, (state, action) => {
      const { id, type, catalogId } = action.payload;

      if (catalogId) {
        state.app.bookmarks.courses[catalogId]?.ids.unshift(id);

        const { component } = (action.payload as CourseObjectBookmark);

        const object = getBookmarkObject(state, type, component.id);
        if (object) {
          object.bookmarkId = id;
        }
      } else {
        state.app.bookmarks.ids.unshift(id);
      }
      state.app.bookmarks.all[id] = action.payload;
      state.app.bookmarks.highlighted = null;
      state.app.bookmarks.focusNew = id;
    })
    .addCase(updateBookmark.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      const bookmark = state.app.bookmarks.all[id];
      state.app.bookmarks.all[id] = action.payload.bookmark;
    })
    .addCase(deleteBookmark.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      const bookmark = state.app.bookmarks.all[id];

      state.app.bookmarks.lastDeleted = bookmark;

      if (bookmark.catalogId) {
        const courseFetched = state.app.bookmarks.courses[(bookmark as CourseBookmark).catalogId];
        if (courseFetched) {
          const index = indexOf(courseFetched.ids, id);
          if (index >= 0) {
            courseFetched.ids.splice(index, 1);
          }
        }

        const { component } = bookmark as CourseObjectBookmark;

        if (component) {
          const object = getBookmarkObject(state, bookmark.type, component.id);
          if (object) {
            object.bookmarkId = null;
          }
        }
      }

      const index = indexOf(state.app.bookmarks.ids, id);
      if (index >= 0) {
        state.app.bookmarks.ids.splice(index, 1);
      }

      if (state.app.bookmarks.highlighted === id) {
        state.app.bookmarks.highlighted = null;
      }

      delete state.app.bookmarks.all?.[id];
    })
    .addCase(highlightBookmark, (state, action) => {
      state.app.bookmarks.highlighted = action.payload.id;
    })
    .addCase(unhighlightBookmark, (state) => {
      state.app.bookmarks.highlighted = null;
    })
    .addCase(filterBookmarks, (state, action) => {
      state.app.bookmarks.filter = action.payload.filter;
      if (action.payload.shouldUnsetCurrentData) {
        state.app.bookmarks.focusNew = null;
        state.app.bookmarks.highlighted = null;
      }
    })
    .addCase(focusBookmark, (state, action) => {
      state.app.bookmarks.focusNew = action.payload;
    })
    .addCase(removeCourseBookmarks, (state, action) => {
      const course = state.app.bookmarks.courses[action.payload];
      const bookmarkIds = course?.ids;

      state.app.bookmarks.ids = without(state.app.bookmarks.ids, ...bookmarkIds);

      delete state.app.bookmarks.courses[action.payload];
    })
    .addCase(unsetLastDeletedBookmark, (state) => {
      state.app.bookmarks.lastDeleted = null;
    });
});
