import { Course } from 'redux/schemas/models/course';
import { BaseUser } from 'redux/schemas/models/my-account';
import { PointsConfiguration } from './points-configuration';
import { MinimalActivity, ActivityType } from './activity';
import { Communication } from './course-communication';
import { TimelinePeerEvaluation } from './peer-evaluation';
import { Normalized } from '../normalized';
import { Role } from './role';
import { ComponentTrueType } from './lecture-component';
import { Summary } from '../app/skills-feedback';

export enum SubmittingStatus {
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  PENDING_APPROVAL = 'pending',
  REJECTED = 'rejected',
  REJECTED_AND_MISSED = 'rejected_and_missed',
  APPROVED = 'approved',
}

export enum PrivacySetting {
  SHARABLE_WITH_INSTRUCTOR = 'sharable_with_instructor',
  SHARABLE_WITH_INSTRUCTOR_AND_TEAM = 'sharable_with_instructor_and_team',
  SHARABLE_WITH_INSTRUCTOR_OR_CLASS = 'sharable_with_instructor_or_class',
  SHARABLE_WITH_CLASS_ONLY = 'sharable_with_class_only',
  SHARABLE_WITH_PUBLIC = 'sharable_with_public',
  SHARABLE_WITH_PUBLIC_ONLY = 'sharable_with_public_only',
}

export enum ExerciseOwner {
  USER = 'User',
  TEAM = 'Team',
  GROUP = 'Group',
}

export type Review = {
  createdAt: string,
  status: ReviewStatus,
  comments: string,
  reviewer: BaseUser,
};

enum ReviewStatus {
  REJECTED = 3,
  APPROVED = 1,
}

export type Exercise = {
  activityId: number,
  activityStarted: null,
  activityType: 'Exercise', // | 'Another' ?
  approvalRequired: boolean,
  catalogId: string,
  collaborators?: BaseUser[],
  courseCatalogId: string,
  course: Course,
  courseId: number
  courseLogo: string,
  description: string,
  dueDate: string | null,
  hardDeadline: boolean,
  id: number,
  isRequiredForCompletion: boolean,
  isTeamSubmission: boolean,
  isTodo: boolean,
  isVotable: boolean,
  lastSubmittedAt: string | null,
  lectureComponentId: number,
  lecturePageId: number,
  numRecentSubmitters: number | null,
  ownerId: number,
  peerReview: boolean,
  pendingSubmissionsCount: number | null,
  privacySetting: PrivacySetting,
  progress: string,
  promptShareSubmission: boolean,
  releaseDate: string,
  submissionsReleased: boolean,
  submissionsViewableBeforeDeadline: boolean,
  submissions: Submission[]
  template: Template,
  title: string,
  type: 'exercise', // | 'Another' ?
  deadline: string | null,
  recentSubmittings: Submitting[],
  reviewers: BaseUser[],
  totalPoints: number[];
  pointsReceived: number | null;
  pointsConfiguration: PointsConfiguration;
  // attachments: [],
  // customQuestions: null,
  // extendedDeadline: null,
  // peerReviewEnd: null,
  // recentSubmitters: null,
};

type Template = {
  id: number,
  sections: TemplateSection[],
};

export enum SubmissionInputType {
  RICH_TEXT = 'Rich Text',
  SLIDES = 'Presentation',
  VIDEO = 'Video',
  VIDEO_UPLOAD = 'Video Upload',
  AUDIO_UPLOAD = 'Audio Upload',
  PICTURE = 'Picture',
  SHORT_TEXT = 'Short Text',
  NUMERIC_ENTRY = 'Numeric Entry',
  EXCEL = 'Excel Workbook',
  DOCUMENT = 'Document',
}

export type TemplateSection = {
  id: number,
  header: string,
  instructions: string,
  inputType: SubmissionInputType,
  reportTemplateId: number,
};

export interface MinimalExercise extends MinimalActivity {
  type: ActivityType.EXERCISE
}

// NOTE: There are many properties missing from this type when used for a LectureComponent. Let's add those
// when this component is rewritten to use React
export type TimelineExercise = {
  id: number;
  title: string;
  deadline?: null;
  extendedDeadline?: null;
  isRequiredForCompletion: boolean;
  releaseDate?: Date;
  isTodo: boolean;
  totalPoints: number[];
  pointsReceived: number | null;
  pointsConfiguration: PointsConfiguration;
  expired?: boolean;
  expirationDate?: null;
  activityType?: string;
  usedBy?: null;
  customQuestions: TimelinePeerEvaluation;

  // TODO: Not sure what this is, try to re-eval when porting team/group formation
  teamSet?: {
    isCourseLong: boolean,
  },
  teamType?: 'individual' | 'course_long' | 'assignment_long';

  submissions?: Submission[];

  communications?: Communication[],
  communicationsCount?: number,

  // TODO : Revist this after api is complete
  hasStructuralIssues?: boolean;
  hardDeadline?: boolean;
} & MinimalExercise;

/** An exercise described by the `:catalogId/exercises.json` endpoint */
export interface CourseExercise {
  title: string;
  id: number;
  /** A list of which component types this exercise should "enable". If a ComponentType is present in
   * any `addableTo` property in any CourseExercise from the `:catalogId/exercises.json` endpoint, then
   * its button in the Add Component panel should be enabled. */
  addableTo: ComponentTrueType[];
  releaseDate: Date;
  deadline: null;
  hardDeadline: boolean;
  teamSetId?: number;
  isCourseLongTeam: boolean;
}

// The below interfaces are only used with `submissions`, which is only present on the LectureComponent data for an exercise

export interface Submission {
  id: number;
  type: string;
  title: string;
  body: string;
  createdAt: Date;
  peerEvaluation: null;
  coverPhotoURL: null;
  url: string;
  courseName: string;
  ownerID: number;
  ownerType: string;
  isVotable: boolean;
  followed: boolean;
  votesCount: number;
  liked: boolean;
  postsCount: number;
  numPostsAndComments: number;
  posted: boolean;
  gavePublicFeedback: null;
  commented: boolean;
  privacySetting: string;
  owner: Owner;
  exercise: Exercise;
  lastSavedAt: Date;
  isPublicFeedbackOn: null;
  submitting: Submitting;
  reviews: null;
  hasViewed: boolean;
  canReview: boolean;
  currentRevision: CurrentRevision;
  revertStatus: string;
  editable: boolean;
  submitted: boolean;
  flags: any[];
  allowedPrivacyOptions: string[];
  isCollaborator: boolean;
  assignedReview: boolean;
  numReviewsReceived: null;
  pointsReceived: number;
  leaderboardPoints: number;
  leaderboardRank: null;
  priorLeaderboardRank: null;
  collaboratorsForPublic: null;
  mentionableUserIds?: number[];
  revisedAfterSubmit: boolean;
  commentIds?: number[],
  bookmarkId?: number,
  isRatedByCurrentUser?: boolean,
  isUserMentorOfCollaborators?: boolean,
}

export interface CurrentRevision {
  id: number;
  reportID: number;
  title: string;
  creator: Owner;
  reviser: null;
  revisedAt: Date;
  sections: CurrentRevisionSection[];
}

export interface CurrentRevisionSection {
  id: number;
  reportTemplateSectionId: number;
  response: string;
  file: null;
  uniqueID: number;
  originalURL: string;
}

export interface Owner {
  id: number;
  admin: boolean;
  firstName: string;
  lastName: string;
  fullName: string;
  userNameForURL: string;
  roles: OwnerRoles | null;
  initialsColor: string;
  initialsColorDark: string;
  initialsColorText: string;
  jobTitle: null;
  country: null;
  city: null;
  region: null;
  profilePicture: string;
  initials: string;
  displayLocation: string;
  primaryLanguage: null;
  platformLanguage: string;
  email: string;
  externalID: null;
  enrolledCoursesCount: null;
}

export interface OwnerRoles {
  permission: null;
  courseRoleID: null;
  role: string;
  roles: Role;
  isCourseAdmin: boolean;
}

export interface Submitting {
  id: number;
  submittableID: number;
  submittableType: 'Report', // | 'Another' ?
  createdAt: Date;
  updatedAt: Date;
  exerciseID: number;
  submissionStatusDate: string | null,
  submissionStatus: SubmittingStatus,
}

export type NTimelineExercise = Normalized<TimelineExercise, 'communications'>;

export interface TimelineExercisesNormalized { [id: string]: NTimelineExercise }

export interface ExerciseNormalized {[id: string]: Exercise}

export interface ExerciseEntities {
  exercises: ExerciseNormalized,
}

export interface ExerciseSubmissionState {
  [id: string]: {
    summaryOnceLoaded: boolean;
    summary: Summary[]
    feedbackOnceLoaded: boolean
    feedbackLoading: boolean
    feedbackByUserOnceLoaded: boolean;
    feedbackByUserLoading: boolean;
    authorFeedback: {
      ratings: number[]
      createdAt: string
    }
    ratingsByUser: {
      [id: string]: number[]
    }

    commentsFetched: boolean;
    additionalNewCommentsBeforeCount: number;
    additionalCommentsBeforeCount: number;
    additionalNewCommentsAfterCount: number;
    additionalCommentsAfterCount: number;
  }
}
