/* @ngInject */
export default function SubmissionGalleryController(
  _,
  $scope,
  $stateParams,
  $uibModal,
  nvUtil,

  ReportsManager,
  CurrentCourseManager,
) {
  _.extend(this, {
    nvUtil,
    CurrentCourseManager,
    ReportsManager,
    showInstructionsModal,
  });

  if ($stateParams.reload !== false) {
    initialize();
  }

  function initialize() {
    ReportsManager.initialize({
      scope: ReportsManager.SUBMISSION_GALLERY,
      reportsCatalogId: $stateParams.catalogId,
      filterOption: $stateParams.filterOption,
      selectedExercise: $stateParams.exerciseId ? { id: $stateParams.exerciseId } : null,
    });
  }

  function showInstructionsModal() {
    $uibModal.open({
      templateUrl: 'submissions/templates/informal-feedback-modal.html',
      windowClass: 'informal-feedback-modal',
      controller: 'InformalFeedbackModalCtrl as vm',
      resolve: {
        currentExercise: ReportsManager.selectedExercise,
        currentPeerEvaluation: ReportsManager.selectedExercise.customQuestions,
      },
    });
  }
}
