import React from 'react';
import t from 'react-translate';

// Angular
import { embedAngularTemplate } from 'shared/embed-angular';
import { AngularServicesContext } from 'react-app';

const OauthProviders = () => {
  const signInWith = React.useRef(null);
  const angularServices = React.useContext(AngularServicesContext);

  React.useEffect(() => {
    embedAngularTemplate('shared/templates/oauth-providers.html', signInWith, angularServices);
  }, [angularServices]);

  return (
    <div className='d-flex align-items-center justify-content-center mt-5'>
      <span>{t.USERS.REGISTRATION.SIGN_IN_WITH()}</span>
      <span ref={signInWith} />
    </div>
  );
};

export default OauthProviders;
