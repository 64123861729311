import { NVLoadingPlaceholderPanel } from 'shared/components/nv-loading-placeholder';
import { css } from '@emotion/react';

const AnalyticsLoader = () => {
  const styles = css`
    display: flex;
    flex-direction: row;
    max-width: 1024px;
    padding-top:40px;
    justify-content:center;
  `;
  return (
    <div css={styles}>
      <NVLoadingPlaceholderPanel height='140px' width='240px' />
      <NVLoadingPlaceholderPanel height='140px' width='240px' />
      <NVLoadingPlaceholderPanel height='140px' width='240px' />
      <NVLoadingPlaceholderPanel height='140px' width='240px' />
    </div>
  );
};
export default AnalyticsLoader;
