import React from 'react';
import { Course } from 'redux/schemas/models/course';
import t from 'react-translate';
import { css } from '@emotion/react';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import StepOne from './step-one/step-one';
import StepThree from './step-three/step-three';
import StepTwo from './step-two/step-two';
import Progress from './progress';
import useCloneCourseForm, { CloneCourseProvider, CloningType, Steps } from './hooks/use-clone-course-form';

type CloneProps = {
  course: Course,
  cloningType: CloningType,
  closeModal(): void,
};

const CurrentStepContent = ({ currentStep, closeModal }) => {
  switch (currentStep) {
    case Steps.STEP_THREE:
      return <StepThree closeModal={closeModal} />;
    case Steps.STEP_TWO:
      return <StepTwo />;
    default:
      return <StepOne />;
  }
};

const styles = css`
  .content {
    padding: ${standardSpacing}px;
  }
  .title-and-description {
    padding-bottom: 20px;
  }
  .buttons {
    text-align: center;
    padding-top: 40px;
    button {
      margin: 0px 5px;
    }
  }
`;

/**
 * The main component for cloning modal
 */
const CourseCloneModal = ({
  course,
  cloningType,
  closeModal,
}: CloneProps) => {
  const cloneCourseFormMethods = useCloneCourseForm({
    course,
    cloningType,
  });

  const {
    currentStep,
  } = cloneCourseFormMethods;
  // Get the corresponding title
  const TitleAndDescription = () => {
    let title = null;
    let description = null;
    switch (currentStep) {
      case Steps.STEP_THREE:
        title = t.COURSES.CLONE.STEPS.STEP_THREE.TITLE();
        description = t.COURSES.CLONE.STEPS.STEP_THREE.DESCRIPTION();
        break;
      case Steps.STEP_TWO:
        title = t.COURSES.CLONE.STEPS.STEP_TWO.TITLE();
        description = cloningType === CloningType.COHORT_COURSE
          ? t.COURSES.CLONE.STEPS.STEP_TWO.DESCRIPTION_COHORT()
          : t.COURSES.CLONE.STEPS.STEP_TWO.DESCRIPTION();
        break;
      default:
        title = t.COURSES.CLONE.STEPS.STEP_ONE.TITLE();
        description = t.COURSES.CLONE.STEPS.STEP_ONE.DESCRIPTION();
    }
    return (
      <div className='title-and-description'>
        <div className='course-title-regular'>
          { title }
        </div>
        <div className='text-regular text-gray-2'>
          { description }
        </div>
      </div>
    );
  };

  return (
    <CloneCourseProvider {...cloneCourseFormMethods}>
      <div css={styles}>
        <Progress currentStep={currentStep} />

        <div className='content'>
          <TitleAndDescription />
          <CurrentStepContent currentStep={currentStep} closeModal={closeModal} />
        </div>
      </div>
    </CloneCourseProvider>
  );
};

export default CourseCloneModal;
