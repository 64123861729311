import { useAngularPreventLecturePageNavigation } from '../hooks/use-prevent-lecture-page-navigation';

/* @ngInject */
export default function TimedQuizEditFormModalCtrl(

  _,
  $uibModalInstance,
  $scope,
  $window,
  $timeout,

  ConfirmationOverlays,
  StateManager,
  CurrentCourseManager,
  vmResolves,
) {
  const vm = this;
  let dismissedByUser = false;
  _.extend(vm, vmResolves);
  vm.isContentManagementCollection = CurrentCourseManager.course.isContentManagementCollection;
  vm.isLinked = vm.lectureComponent?.lecturePage?.isLinked;

  initialize();

  function initialize() {
    $timeout(() => {
      // releaseResultsAt field is not using in collection lesson.
      if (vm.isContentManagementCollection) {
        vm.editTimedQuizForm.$setValidity('releaseResultsAt', true);
      }
    });
  }
  vm.save = () => {
    dismissedByUser = true;
    $uibModalInstance.close();
  };

  vm.timeInternalDropdownToggle = (open) => {
    if (open) {
      vm.editTimedQuizForm.timeLimit.$showValidationMessage = false;
    } else {
      vm.editTimedQuizForm.timeLimit.$validate();
      if (vm.editTimedQuizForm.timeLimit.$invalid) {
        vm.editTimedQuizForm.timeLimit.$showValidationMessage = true;
      }
    }
  };

  vm.selectTimeInterval = (timeInterval) => {
    vm.lectureComponent.quizDraft.timeInterval = timeInterval;
    vm.editTimedQuizForm.$setDirty();
  };

  function hasUnsavedChanges() {
    return vm[vm.lectureComponent.formName] && vm[vm.lectureComponent.formName].$dirty;
  }

  useAngularPreventLecturePageNavigation($scope, hasUnsavedChanges);

  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(
    hasUnsavedChanges,
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    'FORM.NAVIGATE_AWAY.CLOSE_WINDOW',
    undefined,
    undefined,
    () => {
      vm.deregisterModalClosing?.();

      $uibModalInstance.dismiss();
    },
  );

  vm.deregisterModalClosing = $scope.$on('modal.closing', ($event) => {
    if (hasUnsavedChanges() && !dismissedByUser) {
      $event.preventDefault();

      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        dismissedByUser = true;
        $uibModalInstance.dismiss();
        vm.lectureComponent.createDraft();
      });
    }
  });

  $scope.$on('$destroy', () => {
    angular.element($window).off('beforeunload');
    vm.deregisterStateChangeStart?.();
  });
}
