import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { PracticeActivitiesAvailable } from 'redux/schemas/api/video-practice';
import { MinimalPracticeFeedbackCriteria } from 'redux/schemas/models/video-practice-feedback';

export const getAvailablePracticeActivities = createAsyncThunk<PracticeActivitiesAvailable, { catalogId: string }>(
  'FETCH_AVAILABLE_PRACTICE_ACTIVITIES',
  async (params) => {
    const response = await axios.get(`/${params.catalogId}/video_practice_activities.json`);
    return response.data.result;
  },
);

export const setFeedbackActivity = createAction<number | null>('SET_FEEDBACK_ACTIVITY');

export const setShowPracticeFeedbackModal = createAction<boolean>('SET_PRACTICE_FEEDBACK_MODAL');

export const setShowFeedbackInstruction = createAction<{ show: boolean, practiceFeedbackCriteriaId?: number }>('SET_FEEDBACK_INSTRUCTION');

export const setRequirementSubmitted = createAction<number>('SET_REQUIREMENT_SUBMITTED');

// Update redux model with the receieved points
export const updateActivityPoints = createAction<{ id: number, points: number }>('UPDATE_ACTIVITY_POINTS');

export const updatePracticeFeedbackPointsAndProgress = createAction<MinimalPracticeFeedbackCriteria>('UPDATE_POINTS_AND_PROGRESS')
