import React from 'react';
import { css } from '@emotion/core';
import { mergeRefs } from 'shared/react-utils';
import t from 'react-translate';
import { getFocusableParent } from 'shared/utils';
import usePrevious from 'shared/hooks/use-previous';
import NvPopover from 'shared/components/nv-popover';
import ProgressiveQuizContext from 'quizzes/components/context';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import { Button } from 'react-bootstrap';
import { config } from '../../../config/pendo.config.json';

const QuestionPopoverContext = React.createContext(null);

const initialPopoversSate = {
  firstCorrectAnswer: false,
  firstFailedQuestion: false,
  firstIncorrectAnswer: false,
};

type QuestionPopoverProviderProps = {
  children: React.ReactNode;
};

export const QuestionPopoverProvider = (props: QuestionPopoverProviderProps) => {
  const { children } = props;

  const { progressiveQuiz, currentQuestion } = React.useContext(ProgressiveQuizContext);
  const previousProgressiveQuiz = usePrevious(progressiveQuiz);

  const [popoversState, setPopoversState] = React.useState(initialPopoversSate);
  const [isIncorrectQuestionPopoverDisplayed, setIsIncorrectQuestionPopoverDisplayed] = React.useState(false);

  const currentQuestionId = currentQuestion?.id;
  const currentQuestionResponses = currentQuestion?.responses;

  const reset = React.useCallback(() => setPopoversState(initialPopoversSate), []);

  React.useEffect(() => {
    if (currentQuestionId || currentQuestionResponses === null) {
      reset();
    }
  }, [reset, currentQuestionId, currentQuestionResponses]);

  React.useEffect(() => {
    if (previousProgressiveQuiz && progressiveQuiz) {
      if (!previousProgressiveQuiz.hasCorrectAnswer && progressiveQuiz.hasCorrectAnswer) {
        setPopoversState((prev) => ({
          ...prev,
          firstCorrectAnswer: true,
        }));

        return;
      }

      if (!previousProgressiveQuiz.hasIncorrectAnswer
        && progressiveQuiz.hasIncorrectAnswer
        && !isIncorrectQuestionPopoverDisplayed) {
        setPopoversState((prev) => ({
          ...prev,
          firstIncorrectAnswer: true,
        }));
        setIsIncorrectQuestionPopoverDisplayed(true);
      }

      if (!previousProgressiveQuiz.hasFailedQuestion && progressiveQuiz.hasFailedQuestion) {
        setPopoversState((prev) => ({
          ...prev,
          firstFailedQuestion: true,
        }));
      }
    }
  }, [progressiveQuiz, previousProgressiveQuiz, isIncorrectQuestionPopoverDisplayed]);

  return (
    <QuestionPopoverContext.Provider value={[popoversState, reset]}>
      {children}
    </QuestionPopoverContext.Provider>
  );
};

export enum PopoverVariant {
  FIRST_MISTAKE = 'FIRST_MISTAKE',
  FAILED_QUESTTION = 'FAILED_QUESTION',
  REQUIREMENT_MET = 'REQUIREMENT_MET',
  CORRECT_ANSWER = 'CORRECT_ANSWER',
}

type Props = {
  variants: PopoverVariant[];
  children: React.ReactElement;
  onMouseOut?: React.ComponentProps<'div'>['onMouseOut'];
  onMouseOver?: React.ComponentProps<'div'>['onMouseOver'];
};

const QuestionPopover = React.forwardRef<HTMLElement, Props>((props, ref) => {
  const {
    children,
    variants,
    onMouseOut,
    onMouseOver,
  } = props;

  const variantsRef = React.useRef(variants);
  variantsRef.current = variants;

  const [show, setShow] = React.useState(false);
  const targetRef = React.useRef<HTMLElement>();
  const containerRef = React.useRef<HTMLDivElement>();

  const {
    mode,
    progressiveQuiz,
    currentQuestion,
    isRequirementEnabled,
    requiredCorrectQuestionsCount,
  } = React.useContext(ProgressiveQuizContext);

  const {
    isAnswerMode,
  } = useQuizModeAndQuestionType();

  const [popoversState, reset] = React.useContext(QuestionPopoverContext);

  const { correctAnswersCount } = progressiveQuiz;

  const { completedQuestionAttempts = 0 } = currentQuestion || {};

  const attemptsLeft = progressiveQuiz.questionMaximumAttempts - completedQuestionAttempts;

  const chancesCount = attemptsLeft;

  const variantToShow = React.useMemo(() => {
    const propVariants = variantsRef.current;

    if (propVariants.includes(PopoverVariant.CORRECT_ANSWER) && popoversState.firstCorrectAnswer) {
      return PopoverVariant.CORRECT_ANSWER;
    }

    if (propVariants.includes(PopoverVariant.REQUIREMENT_MET)) {
      const metRequirement = (correctAnswersCount >= requiredCorrectQuestionsCount);
      if (isRequirementEnabled && metRequirement) {
        return PopoverVariant.REQUIREMENT_MET;
      }
    }

    if (propVariants.includes(PopoverVariant.FIRST_MISTAKE) && popoversState.firstIncorrectAnswer) {
      return PopoverVariant.FIRST_MISTAKE;
    }

    if (propVariants.includes(PopoverVariant.FAILED_QUESTTION) && popoversState.firstFailedQuestion) {
      return PopoverVariant.FAILED_QUESTTION;
    }

    return undefined;
  }, [
    popoversState,
    correctAnswersCount,
    isRequirementEnabled,
    requiredCorrectQuestionsCount,
  ]);

  React.useEffect(() => {
    setShow(!!variantToShow);
  }, [variantToShow]);

  React.useEffect(() => {
    /**
     * 'Requirement met' popover should automatically close while switching questions.
     * 'variantToShow' is not included in the dependency array because the popover only needs
     * to close while switching questions.
     */
    if (currentQuestion.id && variantToShow === PopoverVariant.REQUIREMENT_MET) {
      setShow(false);
    }
  }, [currentQuestion.id]);

  React.useEffect(() => {
    // When popover is closed we restore focus to target
    if (!show) {
      getFocusableParent(targetRef.current).focus();
    }
  }, [show]);

  const getContent = (variant: PopoverVariant) => {
    let isSuccess = true;
    let title;
    let message;

    if (variant === PopoverVariant.FIRST_MISTAKE || variant === PopoverVariant.FAILED_QUESTTION) {
      isSuccess = false;
    }

    switch (variant) {
      case PopoverVariant.FIRST_MISTAKE:
        title = t.QUIZZES.FIRST_MISTAKE();
        message = t.QUIZZES.CHANCES_LEFT(chancesCount);
        break;
      case PopoverVariant.FAILED_QUESTTION:
        title = t.QUIZZES.QUESTION_MISSED_TITLE();
        message = t.QUIZZES.QUESTION_MISSED();
        break;
      case PopoverVariant.CORRECT_ANSWER:
        title = t.SHARED.GOOD_JOB();
        message = t.QUIZZES.SELECTED_CORRECT();
        break;
      case PopoverVariant.REQUIREMENT_MET:
        title = t.SHARED.GREAT();
        message = t.QUIZZES.REQUIREMENT_MET(correctAnswersCount);
        break;
      default: break;
    }

    return (
      <div className='d-flex flex-column align-items-center pt-3 pb-3' ref={(container) => container?.focus()}>
        <div className={`mb-4 label text-align-center ${isSuccess ? 'text-success' : 'text-danger'}`}>
          {title}
        </div>
        <div className='mb-4 text-small text-align-center'>
          {message}
        </div>
        <Button
          size='sm'
          variant={isSuccess ? 'primary' : 'danger'}
          onClick={() => {
            if (variant === PopoverVariant.REQUIREMENT_MET) {
              setShow(false);
            } else {
              reset();
            }
          }}
          className='px-2 py-1 font-weight-bold text-small'
          data-qa={config.pendo.activities.progressiveQuiz.questionModal.questionPopoverButton}
        >
          {t.SHARED.OK()}
        </Button>
      </div>
    );
  };

  return (
    <div ref={containerRef}>
      <NvPopover
        show={show}
        placement='bottom'
        enabled={isAnswerMode}
        container={containerRef.current}
        content={getContent(variantToShow)}
        overlayStyles={css`
          width: 200px;
        `}
      >
        <div>
          {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
          <div onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
            {React.cloneElement(children, {
              ref: mergeRefs(ref, targetRef),
            })}
          </div>
        </div>
      </NvPopover>
    </div>
  );
});

export default QuestionPopover;
