import React from 'react';
import ProgressiveQuizContext from 'quizzes/components/context';
import t from 'react-translate';

// redux
import { useSelector } from 'react-redux';
import { getFlatCourseAliases } from 'redux/selectors/course';

import NvPopover from 'shared/components/nv-popover';
import NvIcon from 'shared/components/nv-icon';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import ProgressiveFeedback from './progressive-feedback';
import CorrectAnswer from './correct-answer';

type QuizHintProps = {
  isCurrentFeedbackShown: boolean;
  setIsCurrentFeedbackShown: (isCurrentFeedbackShown: boolean) => void;
};
const QuizHint = (props: QuizHintProps) => {
  const { isCurrentFeedbackShown, setIsCurrentFeedbackShown } = props;
  const courseAliases = useSelector(getFlatCourseAliases);
  const {
    reveal,
  } = React.useContext(ProgressiveQuizContext);

  const {
    isEditMode,
    isReviewMode,
    isStatement,
    isLongAnswerQuestion,
  } = useQuizModeAndQuestionType();

  const showCorrectAnswer = isReviewMode && reveal;

  // No need to show any data regarding hint for statement and long answer
  if (isStatement || isLongAnswerQuestion) {
    return null;
  }

  return (
    <>
      {isEditMode ? (
        <div className='d-flex align-items-center mb-2 mt-6'>
          <div className='label text-black mr-2'>
            {t.QUIZZES.HINTS_LABEL()}
          </div>
          <NvPopover
            showOnHover
            placement='top'
            content={(
              <div className=''>
                {t.QUIZZES.HINTS_INFO(courseAliases)}
              </div>
              )}
          >
            <NvIcon icon='info' size='xs-smallest text-primary' className='d-flex' />
          </NvPopover>
        </div>
      ) : (
        <>
          {isCurrentFeedbackShown && (
          <div className='label text-black mb-2'>
            {t.QUIZZES.HINT()}
          </div>
          )}
        </>
      )}
      <div className='bottom-section'>
        <ProgressiveFeedback onVisibleChange={setIsCurrentFeedbackShown} />
        {showCorrectAnswer && (
          <CorrectAnswer className='mt-1' />
        )}
      </div>
    </>
  );
};

export default QuizHint;
