import { css, jsx } from '@emotion/react';
import { forwardRef, SyntheticEvent, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { UploadedFile } from 'redux/schemas/models/file-uploads';
import { probablySupportsVideoType } from 'recording/services/media-visualizer-helper';

import VideoPreviewUnavailable from 'shared/components/video-preview-unavailable';
import { handheld } from 'styles/global_defaults/media-queries';
import NvJwPlayer, { NvJwPlayerImperativeInterface, JwPlayerStates } from './nv-jwplayer';
import { Badge } from './nv-seekbar-badges';

type NvVideoPreviewProps = {
  file: UploadedFile;
  onPlay?: (e?: SyntheticEvent<HTMLVideoElement, Event>) => void;
  onPause?: (e?: SyntheticEvent<HTMLVideoElement, Event>) => void;
  onEnded?: (e?: SyntheticEvent<HTMLVideoElement, Event>) => void;
  onUnload?: (e?: any) => void;
  onReady?: (e?: any) => void;
  badges?: Badge[],
  onClickOnBadge?: Function,
  badgePendoTag?: string,
  isAudio?: boolean,
  autoStart?: boolean,
};

export interface NvVideoPreviewImperativeInterface extends NvJwPlayerImperativeInterface {

}

type Html5VideoProps = {
  file: UploadedFile;
  onPlay?: (e?: SyntheticEvent<HTMLVideoElement, Event>) => void;
  onPause?: (e?: SyntheticEvent<HTMLVideoElement, Event>) => void;
  onEnded?: (e?: SyntheticEvent<HTMLVideoElement, Event>) => void;
  onError?: (e?: any) => void;
  onUnload?: (e?: any) => void;
  onReady?: (e?: any) => void; // Fired when the first frame of the media has finished loading.
};

const Html5Video = forwardRef<NvVideoPreviewImperativeInterface, Html5VideoProps>(({
  file,
  onError,
  onPlay: onPlayCallback,
  onPause,
  onEnded,
  onReady,
}, ref) => {
  const playerInstanceRef = useRef(null);

  const [hasPlayed, setHasPlayed] = useState(false);

  useImperativeHandle(ref, () => ({
    seek: (seconds) => { playerInstanceRef.current.currentTime = seconds; },
    play: (isPlay) => (isPlay ? playerInstanceRef.current.play() : playerInstanceRef.current.pause()),
    getState: () => {
      // 'idle', 'paused', 'playing', 'complete', 'buffering'
      // TODO : Add buffering to match with jwplayer
      if (playerInstanceRef.current.ended) {
        return JwPlayerStates.COMPLETED;
      }
      if (playerInstanceRef.current.paused) {
        return playerInstanceRef.current.played?.length > 0 ? JwPlayerStates.PAUSED : JwPlayerStates.IDLE;
      }
      return JwPlayerStates.PLAYING;
    },
    getPosition: () => playerInstanceRef.current.currentTime,
    getDuration: () => playerInstanceRef.current.duration,
    hasPlayed,
  }));

  const onPlay = useCallback((e) => {
    onPlayCallback?.(e);
    setHasPlayed(true);
  }, [onPlayCallback]);

  return (
    <video
      ref={playerInstanceRef}
      controls
      controlsList='nodownload'
      src={file.url}
      disablePictureInPicture
      onError={onError}
      onPlay={onPlay}
      onPause={onPause}
      onEnded={onEnded}
      onLoadedData={onReady}
    />
  );
});

const NvVideoPreview = forwardRef<NvVideoPreviewImperativeInterface, NvVideoPreviewProps>(({
  file,
  onPlay,
  onPause,
  onEnded,
  onUnload,
  onReady,
  badges,
  onClickOnBadge,
  badgePendoTag,
  isAudio,
  autoStart,
}, ref) => {
  const styles = css`
    video {
      width: 100%;
    }
  `;

  const [isVideoNotPlayable, setIsVideoNotPlayable] = useState(false);

  const canProbablyPreview = !file.url || probablySupportsVideoType(file.mimeType || file.type);

  const onVideoError = (event) => {
    if (event.currentTarget.error.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
      setIsVideoNotPlayable(true);
    }
  };

  const onJwPlayerEventCallback = useCallback((event: string) => {
    if (event === 'play' && onPlay) {
      onPlay();
    } else if (event === 'pause' && onPause) {
      onPause();
    } else if (event === 'ended' && onEnded) {
      onEnded();
    } else if (event === 'unload' && onUnload) {
      onUnload();
    } else if (event === 'ready' && onReady) {
      onReady();
    }
  }, [onPlay, onPause, onEnded, onUnload, onReady]);

  let videoContent;

  if (isAudio) {
    videoContent = (
      <NvJwPlayer
        ref={ref}
        videoUrl={file.url}
        downloadUrl={file.downloadUrl}
        fileName={file.name}
        thumbnailUrl={file.thumbnailUrl}
        badges={badges}
        onClickOnBadge={onClickOnBadge}
        badgePendoTag={badgePendoTag}
        eventCallback={onJwPlayerEventCallback}
        isAudio={isAudio}
      />
    );
  } else {
    if (file.hasBeenTranscoded) {
      videoContent = (
        <NvJwPlayer
          ref={ref}
          videoUrl={file.url}
          downloadUrl={file.downloadUrl}
          fileName={file.name}
          thumbnailUrl={file.customThumbnailUrl || file.thumbnailUrl}
          badges={badges}
          onClickOnBadge={onClickOnBadge}
          badgePendoTag={badgePendoTag}
          eventCallback={onJwPlayerEventCallback}
          isAudio={isAudio}
          captions={file.captions}
          autoStart={autoStart}
        />
      );
    }

    if (!file.hasBeenTranscoded && canProbablyPreview && !isVideoNotPlayable && !isAudio) {
      videoContent = (
        <Html5Video
          ref={ref}
          file={file}
          onError={onVideoError}
          onPlay={onPlay}
          onPause={onPause}
          onEnded={onEnded}
          onReady={onReady}
        />
      );
    }

    if (!file.hasBeenTranscoded && (!canProbablyPreview || isVideoNotPlayable)) {
      videoContent = <VideoPreviewUnavailable downloadUrl={file.downloadUrl} />;
    }
  }

  return (
    <div css={styles} className='nv-video-preview w-100'>
      {videoContent}
    </div>
  );
});

export default NvVideoPreview;
