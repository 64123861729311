import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { black, gray4, gray6, gray7 } from 'styles/global_defaults/colors';
import { extraLargeSpacing, halfSpacing, quarterSpacing, standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import t from 'react-translate';
import NvSwitch from 'shared/components/inputs/nv-switch';
import NvIcon from 'shared/components/nv-icon';
import { useFormContext } from 'react-hook-form';
import NvTooltip from 'shared/components/nv-tooltip';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import { Name } from 'redux/schemas/models/courseFull';
import { config } from '../../../config/config.json';
import { CompletionCriteria } from './completion-criteria-dropdown';

type OrderCollectionsProps = {
  orderName: 'orderLocked';
  autoEnrollmentName: 'autoEnrollment';
  visible: boolean;
  style: SerializedStyles;
  learnerAlias: Name;
  courseAlias: Name;
};

const OrderCollectionsLock: React.FC<OrderCollectionsProps> = ({
  orderName,
  autoEnrollmentName,
  visible,
  style: limitedWidthContainerStyles,
  learnerAlias,
  courseAlias,
}) => {
  const { watch, setValue, getValues } = useFormContext();
  const [locked, autoEnrollment] = watch([orderName, autoEnrollmentName]);
  const { collections } = getValues();

  const allCollectionsAreAllInOrder = collections.length > 0 && collections.every(
    collection => collection.completionCriteria?.value === CompletionCriteria.ALL_IN_ORDER,
  );

  const cssLock = css`
    padding-top: ${standardSpacing}px;
    padding-bottom: ${tripleSpacing}px;
    ${limitedWidthContainerStyles};
    .lock-content {
      border: 1px solid ${gray6};
      padding: ${standardSpacing}px;
      &:hover, &.active {
        background-color: ${gray7};
      }
      .status {
        width: ${extraLargeSpacing * 2}px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: ${quarterSpacing}px;
      }
      .lock-text {
        align-self: center;
        display: flex;
        flex-direction: column;
        gap: ${quarterSpacing}px;
        .order-switch {
          padding: 0px ${quarterSpacing}px;
        }
        .auto-enrollment {
          color: ${!locked ? gray4 : black};
        }
      }
    }
  `;

  // Setting autoEnrollment to false when locked is set to false
  React.useEffect(() => {
    if (locked === false && autoEnrollment !== false) {
      setValue('autoEnrollment', false);
    }
  }, [locked, autoEnrollment, setValue]);

  return (
    <React.Fragment>
      {
        visible
          ? (
            <div css={cssLock}>
              <div className='lock-content gray-2 d-flex items-center'>
                <div className='status'>
                  <NvIcon icon={locked ? 'locked' : 'unlocked'} size='medium' />
                  <div className='text-small bold'>
                    {locked
                      ? t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_ORDER_LOCKED()
                      : t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_ORDER_UNLOCKED()}
                  </div>
                </div>
                <div className='lock-text'>
                  <div className='header d-flex'>
                    <div className='bold'>
                      {t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_ORDER_TITLE()}
                    </div>
                    <NvTooltip
                      text={locked
                        ? t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_ORDER_LOCKED_TOOLTIP()
                        : t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_ORDER_UNLOCKED_TOOLTIP()}
                    >
                      <NvSwitch
                        withForm
                        name={orderName}
                        data-qa={config.pendo.learningJourneys.toggleLock}
                        pendo-tag-name={config.pendo.learningJourneys.toggleLock}
                        className='order-switch'
                        enabledLabel={t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_ORDER_LOCKED().toUpperCase()}
                        disabledLabel={t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_ORDER_UNLOCKED().toUpperCase()}
                      />
                    </NvTooltip>
                  </div>
                  <div className='detail'>
                    {locked
                      ? t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_ORDER_LOCKED_DESCRIPTION()
                      : t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_ORDER_UNLOCKED_DESCRIPTION()}
                  </div>
                  <NvTooltip
                    enabled={!locked || !allCollectionsAreAllInOrder}
                    text={!locked
                      ? t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.AUTO_ENROLLMENT.TOOLTIP.NOT_LOCKED({ coursesAlias: courseAlias.downcasedPluralized })
                      : t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.AUTO_ENROLLMENT.TOOLTIP.LOCKED()}
                  >
                    <div className='auto-enrollment'>
                      <NvCheckbox
                        withForm
                        name={autoEnrollmentName}
                        disabled={!locked || !allCollectionsAreAllInOrder}
                        label={t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.AUTO_ENROLLMENT.TITLE({ learnersAlias: learnerAlias.downcasedPluralized })}
                        labelClassName='text-regular'
                        dataQa={config.pendo.learningJourneys.autoEnrollment}
                      />
                    </div>
                  </NvTooltip>
                </div>
              </div>
            </div>
          )
          : null
      }
    </React.Fragment>
  );
};

export default OrderCollectionsLock;
