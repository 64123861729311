export default {
  templateUrl: 'submissions/templates/nv-submission-approval-comments.html',
  bindings: {
    approvalComments: '<',
    showPrivateOnly: '<?',
    isOwner: '<?',
  },
  controller: function ctrl(
    _,
  ) {
'ngInject';
    const STATUS_CODES = {
      REJECTED: 3,
      APPROVED: 1,
    };

    this.STATUS_CODES = STATUS_CODES;

    this.toggleComments = () => {
      this.showAlldisplayableApprovalComments = !this.showAlldisplayableApprovalComments;
    };

    this.getApprovalComments = () => (this.showPrivateOnly ? _.filter(this.approvalComments, (comment) => comment.isPrivate) : this.approvalComments);
  },
  controllerAs: 'vm',
};
