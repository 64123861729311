import ClickableContainer from 'components/clickable-container';
import { setLeftPanelNovoAIContentId } from 'redux/actions/lecture-pages';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import NvIcon from 'shared/components/nv-icon';
import { camelCase } from 'lodash';
import GradientAiIcon from '../components/shared/gradient-ai-icon';
import { AIContent } from './list';
import AICommonStyles from '../components/shared/ai-common-styles';
import { config } from '../../../../../../config/config.json';

type ContentProps = {
  content: AIContent
};

const Content = (props: ContentProps) => {
  const { content } = props;
  const dispatch = useAppDispatch();
  const selectedContentId = useSelector(state => state.app.lecturePage.novoAI.content?.id);
  const selected = selectedContentId === content.id;

  const setItem = () => {
    dispatch(setLeftPanelNovoAIContentId({
      id: content.id,
      type: content.type,
    }));
  };

  return (
    <div css={AICommonStyles}>
      <ClickableContainer
        className={`d-flex p-4 option ${selected ? 'selected' : ''}`}
        onClick={setItem}
        data-qa={config.pendo.novoAi.content[camelCase(content.type)]}
      >
        {selected
          ? <GradientAiIcon icon='read' size='small' />
          : <NvIcon icon='read' size='small' />}
        <div className='d-flex flex-column w-75 pl-2'>
          <div className='title-text text-regular bold'>{content.title}</div>
          <div
            className='description-text text-small semi-bold'
          >
            {content.description}
          </div>
        </div>
      </ClickableContainer>
    </div>
  );
};

export default Content;
