import { css } from '@emotion/react';
import ClickableContainer from 'components/clickable-container';
import React from 'react';
import t from 'react-translate';
import { gray6, primary } from 'styles/global_defaults/colors';
import { textSmallFontSize, textXSFontSize } from 'styles/global_defaults/fonts';
import AutosizeInput from 'react-input-autosize';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';

const styles = css`
  align-items:center;
  border:1px solid ${primary};
  border-radius:${standardSpacing}px;
  background-color:${gray6};
  display:flex;
  font-size: ${textSmallFontSize}px;
  height:21px;
  margin-left:${halfSpacing}px;
  padding: 0px ${halfSpacing}px;
  .skill-input{
    background:none;
    border:0;
    width:76px;
    &:focus{
      outline:none;
    }
  }
  .close-btn{
    .icon-close{
      font-size:${textXSFontSize}px;
    }
  }
`;
const EditSkillField = ({ value, onClose, onChange, disabled }) => {
  const inputRef = React.useRef(null);
  return (
    <div css={styles}>
      <AutosizeInput
        maxLength={50}
        inputStyle={{ border: 'none', background: 'transparent', minWidth: 46, maxWidth: 210, outline: 'none', marginRight: 10 }}
        style={{ background: 'none', minWidth: 59, maxWidth: 220 }}
        name='form-field-name'
        autoFocus
        autoComplete='off'
        placeholder={t.INSTITUTIONS.SKILL_TAGS.PLACEHOLDER_SKILL()}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        disabled={disabled}
      />
      <ClickableContainer onClick={() => onClose()} className='close-btn'><i className='icon-close' /></ClickableContainer>
    </div>
  );
};
export default EditSkillField;
