import { css } from '@emotion/react';
import getComponentMetadata from 'lecture_pages/components/data';
import getDefaultNewComponentData from 'lecture_pages/components/data/new-component-defaults';
import { WorkflowParams } from 'lecture_pages/components/workflows';
import ConditionalWorkflow from 'lecture_pages/components/workflows/conditional-workflow';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { fetchAIGeneratedPrompts } from 'redux/actions/posts';
import { TopicPayload } from 'redux/schemas/models/activity';
import {
  ComponentTrueType,
  ComponentType,
  LectureComponent,
} from 'redux/schemas/models/lecture-component';
import { NovoAIItemType } from 'redux/schemas/models/lecture-page';
import { AIDiscussionPrompts, errorCodesWithAlert } from 'redux/schemas/models/post';
import { getAIDiscussionPrompts } from 'redux/selectors/posts';
import { useAppDispatch } from 'redux/store';
import NvGeneratingLoader from 'shared/components/loaders/nv-generating-loader';
import NvModal, { BackdropType, ModalType } from 'shared/components/nv-modal';
import { ListOfSelectableOptions } from 'shared/components/selectable-options';
import {
  doubleSpacing,
  halfSpacing,
  standardSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import useCreateLectureComponent from 'lecture_pages/hooks/use-lecture-component';
import { wrapThunkAction } from 'redux/utils';
import { getNovoAIProperties } from 'redux/selectors/lecture-page';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { useMediaQuery } from 'react-responsive';
import { screenMdMin } from 'styles/global_defaults/media-queries';
import { config } from '../../../../../../../config/pendo.config.json';
import GenerateButton from '../shared/generate-button';

type AIGeneratedPromptsProps = {
  handleCloseModal: () => void;
  handleHideModal: () => void;
};

type SelectedOptions = number[];

type DiscussionDefaults = {
  type: ComponentType;
  trueType: ComponentTrueType;
  post: TopicPayload['post'];
};

const commonStyles = css`
  display: flex;
  justify-content: center;
  margin: ${standardSpacing}px ${doubleSpacing * 2}px ${doubleSpacing}px;
`;

const containerStyles = css`
  ${commonStyles};

  .container {
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${tripleSpacing}px;
  }

  .results {
    display: flex;
    flex-direction: column;
    gap: ${standardSpacing}px;
  }

  .button-bar {
    display: flex;
    justify-content: center;
    gap: ${halfSpacing}px;
  }
`;

/**
 * Helper function to get the concatenated text of selected prompts
 * @param selectedOptions - List of selected prompts ids
 * @param prompts - Lits of all available prompts
 * @returns Concatened text of the selected prompts
 */
const getSelectedPromptsText = (
  selectedOptions: SelectedOptions,
  prompts: AIDiscussionPrompts['prompts'],
): string => prompts
  .filter(prompt => selectedOptions.includes(prompt.id))
  .map(prompt => prompt.text)
  .join('<br />');

/**
 * List of AI-generated discussion prompts and the button to fire the conditional workflow of the Discussion lecture component
 * @param handleCloseModal - Callback to handle the closing event of the modal
 * @returns Modal with AI-generated discussion prompts
 */
const AIGeneratedPrompts: React.FC<AIGeneratedPromptsProps> = ({
  handleCloseModal,
  handleHideModal,
}) => {
  const dispatch = useAppDispatch();
  const { fireCreateNewComponent, fireUpdateComponent } = useCreateLectureComponent();
  const [selectedOptions, setSelectedOptions] = React.useState<SelectedOptions>(
    [],
  );
  const { loading, prompts }: AIDiscussionPrompts = useSelector(
    getAIDiscussionPrompts,
  );
  const novoAIProperties = useSelector(getNovoAIProperties);

  // Adding responsive styles
  const isDesktop = useMediaQuery({
    query: `(min-width: ${screenMdMin}px)`,
  });
  const marginX = !isDesktop ? doubleSpacing : doubleSpacing * 2;
  const responsiveContainerStyles = css`
    ${containerStyles};
    margin-left: ${marginX}px;
    margin-right: ${marginX}px;
  `;

  // Identifying if the discussion is being regenerated
  let existingLectureComponent: LectureComponent<ComponentType.TOPIC> = novoAIProperties?.lectureComponentToReplace;
  const isRegenerating = !!existingLectureComponent;

  // Getting the base information to create the Discussion lecture component
  const componentType = ComponentType.TOPIC;
  const metadata = getComponentMetadata(componentType);
  const lectureComponentDefaults = getDefaultNewComponentData(
    componentType,
  ) as DiscussionDefaults;
  const baseLectureComponent = {
    ...lectureComponentDefaults,
    aiOrigin: NovoAIItemType.DISCUSSION,
    post: {
      ...lectureComponentDefaults.post,
      title: t.LECTURE_PAGES.COMPONENTS.DISCUSSION.LETS_DISCUSS(),
      body: getSelectedPromptsText(selectedOptions, prompts),
    },
  };

  // Setting the generated prompt to the existing discussion
  if (existingLectureComponent) {
    existingLectureComponent = {
      ...existingLectureComponent,
      topic: {
        ...existingLectureComponent.topic,
        body: getSelectedPromptsText(selectedOptions, prompts),
      },
    };
  }

  // Specifing the workflow to handle the creation of the Discussion lecture component
  const workflowProps: Omit<WorkflowParams, 'children'> = {
    componentType,
    metadata,
    mode: !isRegenerating ? 'new' : 'edit',
    saveNewComponent: !isRegenerating ? fireCreateNewComponent : undefined,
    save: !isRegenerating ? undefined : fireUpdateComponent,
    lectureComponent: !isRegenerating ? baseLectureComponent as LectureComponent : existingLectureComponent,
    useExistingData: true,
  };

  // Getting the AI-generated prompts from backend
  React.useEffect(() => {
    wrapThunkAction(
      dispatch(fetchAIGeneratedPrompts()),
    ).then((action) => {
      const { errorCode } = action.payload as AIDiscussionPrompts;

      if (errorCode) {
        handleCloseModal();

        let errorMessage = '';
        let errorHeader = '';
        if (errorCodesWithAlert.has(errorCode)) {
          errorHeader = t.FORM.FAILED();
          errorMessage = t.LECTURE_PAGES.COMPONENTS.DISCUSSION.GENERATED_MESSAGES.TOO_SHORT();
        } else {
          errorHeader = t.FORM.OOPS();
          errorMessage = t.FORM.ERROR_SOMETHING_WRONG_SHORT();
        }

        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: errorHeader,
          message: errorMessage,
        }));
      }
    });
  }, []);

  if (loading) {
    return (
      <section css={commonStyles}>
        <NvGeneratingLoader />
      </section>
    );
  }

  return (
    <div css={responsiveContainerStyles}>
      <div className='container'>
        <section className='results'>
          {!loading && prompts?.length > 0 && (
            <>
              <p className='text-regular bold gray-1'>
                {t.LECTURE_PAGES.COMPONENTS.DISCUSSION.GENERATED_PROMPTS()}
              </p>
              <ListOfSelectableOptions
                options={prompts}
                dataQa={
                config.pendo.lectureEdit.aiGeneratedDiscussionPrompts.generatedPrompt
              }
                onSelectionChange={newSelectedOptions => setSelectedOptions(newSelectedOptions)}
              />
            </>
          )}
        </section>

        <section className='button-bar'>
          <Button
            variant='secondary'
            onClick={handleCloseModal}
            data-qa={config.pendo.lectureEdit.aiGeneratedDiscussionPrompts.cancel}
          >
            {t.FORM.CANCEL()}
          </Button>

          <ConditionalWorkflow {...workflowProps}>
            <Button
              variant='primary'
              disabled={selectedOptions.length === 0}
              onClick={handleHideModal}
              data-qa={
                config.pendo.lectureEdit.aiGeneratedDiscussionPrompts.createDiscussion
              }
            >
              {!isRegenerating ? t.LECTURE_PAGES.COMPONENTS.DISCUSSION.CREATE_DISCUSSION() : t.LECTURE_PAGES.COMPONENTS.DISCUSSION.UPDATE_DISCUSSION()}
            </Button>
          </ConditionalWorkflow>
        </section>
      </div>
    </div>
  );
};

/**
 * `Generate Now` button to handle the Course-wide discussion prompts:
 * 1. Opens a Discussion Prompts modal to choose the prompts.
 * 2. Opens the Add course-wide discussion modal with predefined data.
 * @returns Button to handle the GenAI flow of a Discussion component
 */
const GenerateDiscussionPrompts = () => {
  const novoAIProperties = useSelector(getNovoAIProperties);
  const [showModal, setShowModal] = React.useState(false);
  const [hideModal, setHideModal] = React.useState(false);

  const modalStyles = css`
    opacity: ${!hideModal ? 1 : 0};
    transition: opacity .15s ease-in-out;
  `;

  const resetModal = () => {
    setHideModal(false);
    setShowModal(false);
  };

  React.useEffect(() => () => {
    if (showModal) resetModal();
  }, []);

  const handleCloseModal = () => {
    if (showModal) resetModal();
  };

  // The Conditional Modal is a nested modal that follows the Conditional Workflow architecture (see AIGeneratedPrompts). We cannot close this modal and expect the nested modal to remain open. To solve this scenario, we must hide this modal and handle the closing action through a callback.
  const handleHideModal = () => setHideModal(true);

  return (
    <>
      <GenerateButton
        onClick={() => setShowModal(true)}
        text={novoAIProperties.lectureComponentToReplace ? t.LECTURE_PAGES.LHS.NOVO_AI.REGENERATE() : undefined}
      />
      <NvModal
        type={ModalType.FULL}
        backdropType={BackdropType.LIGHT}
        header={t.LECTURE_PAGES.COMPONENTS.COURSE_DISCUSSION.GEN_AI.DISCUSSION_PROMPTS()}
        body={<AIGeneratedPrompts handleCloseModal={handleCloseModal} handleHideModal={handleHideModal} />}
        modalStyles={modalStyles}
        show={showModal}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default GenerateDiscussionPrompts;
