/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// https://github.com/BrunoRB/ahocorasick/blob/master/src/main.js

export type ResultsByKeyword<K extends readonly string[]> = { [keyword in K[number]]?: number[] };
export type ResultTuple<K extends readonly string[]> = [position: number, keyword: K[number][]];

// array with the keyword property containing results indexed by keyword
export interface AhoCorasickMatches<K extends readonly string[] = string[]> extends Array<ResultTuple<K>> {
  keywords: ResultsByKeyword<K>;
  trie: AhoCorasick<K>;
}

class AhoCorasick<KeywordsT extends readonly string[]> {
  /**
   * state to go to on any input symbol
   */
  gotoFn: { [state: number]: { [inputSymbol: string]: number } } = {
    0: {},
  };

  keywords: readonly [...KeywordsT];

  /**
   * array of keywords indexed by accepting states
   */
  output: { [state: number]: string[] } = {};

  /**
   * state to go if the match fails indexed by state
   */
  failure: { [state: number]: number } = {};

  constructor(keywords: readonly [...KeywordsT]) {
    this.keywords = keywords;
    this._buildTables(keywords);
  }

  _buildTables(keywords) {
    let state = 0;

    for (const word of keywords) {
      let curr = 0;
      for (let i = 0; i < word.length; i++) {
        const l = word[i];
        if (this.gotoFn[curr] && l in this.gotoFn[curr]) {
          curr = this.gotoFn[curr][l];
        } else {
          state++;
          this.gotoFn[curr][l] = state;
          this.gotoFn[state] = {};
          curr = state;
          this.output[state] = [];
        }
      }

      this.output[curr].push(word);
    }

    const xs = [];

    // f(s) = 0 for all states of depth 1 (the ones from which the 0 state can transition to)
    for (const l in this.gotoFn[0]) {
      const currentState = this.gotoFn[0][l];
      this.failure[currentState] = 0;
      xs.push(currentState);
    }

    while (xs.length) {
      const r = xs.shift();
      // for each symbol a such that g(r, a) = s
      for (const l in this.gotoFn[r]) {
        const s = this.gotoFn[r][l];
        xs.push(s);

        // set state = f(r)
        let currentState = this.failure[r];
        while (currentState > 0 && !(l in this.gotoFn[currentState])) {
          currentState = this.failure[currentState];
        }

        if (l in this.gotoFn[currentState]) {
          const fs = this.gotoFn[currentState][l];
          this.failure[s] = fs;
          this.output[s] = this.output[s].concat(this.output[fs]);
        } else {
          this.failure[s] = 0;
        }
      }
    }
  }

  search(string) {
    let state = 0;
    const results = [] as AhoCorasickMatches<KeywordsT>;
    results.keywords = {};
    results.trie = this;
    for (let i = 0; i < string.length; i++) {
      const l = string[i];
      while (state > 0 && !(l in this.gotoFn[state])) {
        state = this.failure[state];
      }
      if (!(l in this.gotoFn[state])) {
        continue;
      }

      state = this.gotoFn[state][l];

      if (this.output[state].length) {
        const foundStrs = this.output[state];

        results.push([i, foundStrs]);

        for (const foundStr of foundStrs) {
          if (!Array.isArray(results.keywords[foundStr])) {
            results.keywords[foundStr] = [];
          }
          results.keywords[foundStr].push(i);
        }
      }
    }

    return results;
  }
}

export default AhoCorasick;
