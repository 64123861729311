import { NvModal, ModalType } from 'shared/components/nv-modal';
import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import NvResponsiveTableOptionsCell from 'shared/components/nv-responsive-table-options-cell';
import t from 'react-translate';
import { NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { CompletionTypeFilters, LearnerProgress } from 'redux/schemas/models/learner-progress';
import { addManualCompletion, getLearnerProgressCounts, removeManualCompletion } from 'redux/actions/learner-progress';
import SendMessageModal from 'shared/components/nv-community-completion/send-message-modal';
import { User } from 'redux/schemas/models/my-account';
import { getCourseAliases, getCurrentCourse } from 'redux/selectors/course';
import { useAppDispatch } from 'redux/store';
import { AngularContext } from 'react-app';
import ResetAttemptsModal from 'institutions/components/reset-attempts/reset-attempts-modal';
import { useResponsiveTableContext } from 'shared/components/nv-responsive-table';
import AwardExtraPointsModal from './award-extra-points-modal';
import GrantLateSubmissionPermitModal from './grant-late-submission-permit-modal';
import { LearnerCellProps } from './learner-row';
import { config } from '../../../config/pendo.config.json';


type LearnerRowOptionsProps = {
  cellProps: LearnerCellProps,
  dataQa?: string,
  dataQaId?: string,
};

/**
 * NovoEd-customized wrapper around a react-bootstrap Dropdown component.
 */
const LearnerRowOptions = (props: LearnerRowOptionsProps) => {
  const dispatch = useAppDispatch();

  const { reload } = useResponsiveTableContext();
  const { injectServices } = useContext(AngularContext);
  const [$state, CurrentPermissionsManager] = injectServices(['$state', 'CurrentPermissionsManager']);
  const aliases = useSelector((state) => getCourseAliases(state));
  const { catalogId, gamificationEnabled, hasHardDeadlineExercises } = useSelector((state) => getCurrentCourse(state));

  const [showSendMessageModal, setShowSendMessageModal] = useState(false);
  const [showAwardExtraPointsModal, setShowAwardExtraPointsModal] = useState(false);
  const [showGrantLateSubmissionPermitModal, setShowGrantLateSubmissionPermitModal] = useState(false);
  const [showResetAttemptsModal, setShowResetAttemptsModal] = useState(false);

  const grantManualCompletion = () => {
    const { userId } = props.cellProps.learner;
    const fullName = `${props.cellProps.learner.firstName} ${props.cellProps.learner.lastName}`;
    dispatch(addManualCompletion({ catalogId, userId, translationValues: fullName }));
  };

  const revokeManualCompletion = () => {
    const { userId } = props.cellProps.learner;
    const fullName = `${props.cellProps.learner.firstName} ${props.cellProps.learner.lastName}`;
    dispatch(removeManualCompletion({ catalogId, userId, translationValues: fullName }));
  };

  const optionItems: NvDropdownOption[] = [
    {
      type: 'link',
      text: t.LEARNER_PROGRESS.OPTIONS.EMAIL_USER(),
      link: `mailto:${props.cellProps.learner.email}`,
      dataQa: config.pendo.learnerProgress.learnerOptions.emailUser,
    },
    {
      type: 'text',
      text: t.LEARNER_PROGRESS.OPTIONS.SEND_A_MESSAGE(),
      callback: () => setShowSendMessageModal(true),
      dataQa: config.pendo.learnerProgress.learnerOptions.sendAMessage,
    },
    {
      type: 'divider',
    },
  ];

  if (props.cellProps.learner.completionStatus === CompletionTypeFilters.NOT_COMPLETED) {
    optionItems.push({
      type: 'text',
      text: t.LEARNER_PROGRESS.OPTIONS.GRANT_MANUAL_COMPLETION(),
      callback: grantManualCompletion,
      dataQa: config.pendo.learnerProgress.learnerOptions.grantManualCompletion,
    });
  }
  if (props.cellProps.learner.completionStatus === CompletionTypeFilters.AUTO_COMPLETED) {
    optionItems.push({
      type: 'text',
      text: t.LEARNER_PROGRESS.OPTIONS.GRANT_MANUAL_COMPLETION(),
      disabled: true,
      dataQa: config.pendo.learnerProgress.learnerOptions.grantManualCompletion,
    },
    {
      type: 'header',
      title: t.LEARNER_PROGRESS.OPTIONS.AUTOMATED_COMPLETION_GRANTED(),
      class: 'warning',
    });
  }
  if (props.cellProps.learner.completionStatus === CompletionTypeFilters.MANUALLY_COMPLETED) {
    optionItems.push({
      type: 'text',
      text: t.LEARNER_PROGRESS.OPTIONS.REVOKE_MANUAL_COMPLETION(),
      callback: revokeManualCompletion,
      dataQa: config.pendo.learnerProgress.learnerOptions.revokeManualCompletion,
    });
  }

  if (gamificationEnabled) {
    optionItems.push(
      {
        type: 'text',
        text: t.LEARNER_PROGRESS.OPTIONS.AWARD_EXTRA_POINTS({ ...aliases.pointsAliases }),
        callback: () => setShowAwardExtraPointsModal(true),
        dataQa: config.pendo.learnerProgress.learnerOptions.awardExtraPoints,
      },
    );
  }

  if (hasHardDeadlineExercises) {
    optionItems.push(
      {
        type: 'text',
        text: t.LEARNER_PROGRESS.OPTIONS.GRANT_LATE_SUBMISSION_PERMIT(),
        callback: () => setShowGrantLateSubmissionPermitModal(true),
        dataQa: config.pendo.learnerProgress.learnerOptions.grantLateSubmissionPermit,
      },
    );
  }

  optionItems.push(
    {
      type: 'text',
      text: t.LEARNER_PROGRESS.OPTIONS.RESET_ATTEMPTS(),
      callback: () => setShowResetAttemptsModal(true),
      dataQa: config.pendo.learnerProgress.learnerOptions.resetAttempts,
    },
  );

  const isInstructor = CurrentPermissionsManager.isInstructor();
  const isTeachingAssistant = CurrentPermissionsManager.isTeachingAssistant();


  return (
    <React.Fragment>
      <NvModal
        type={ModalType.DYNAMIC}
        header={t.PROFILE.SEND_A_MESSAGE()}
        show={showSendMessageModal}
        onClose={() => setShowSendMessageModal(false)}
        fullHeight={false}
        body={(
          <SendMessageModal
            recipient={{ ...props.cellProps.learner, id: props.cellProps.learner.userId }}
            closeModal={() => setShowSendMessageModal(false)}
          />
        )}
      />
      <NvModal
        type={ModalType.DYNAMIC}
        header={t.LEARNER_PROGRESS.OPTIONS.AWARD_EXTRA_POINTS({ ...aliases.pointsAliases })}
        show={showAwardExtraPointsModal}
        onClose={() => setShowAwardExtraPointsModal(false)}
        fullHeight={false}
        body={(
          <AwardExtraPointsModal
            user={{ ...props.cellProps.learner, id: props.cellProps.learner.userId }}
            closeModal={() => {
              setShowAwardExtraPointsModal(false);
              dispatch(getLearnerProgressCounts({ catalogId }));
              reload();
            }}
            catalogId={catalogId}
          />
        )}
      />
      <NvModal
        type={ModalType.DYNAMIC}
        header={t.LEARNER_PROGRESS.OPTIONS.GRANT_LATE_SUBMISSION_PERMIT()}
        show={showGrantLateSubmissionPermitModal}
        onClose={() => setShowGrantLateSubmissionPermitModal(false)}
        fullHeight={false}
        body={(
          <GrantLateSubmissionPermitModal
            user={{ ...props.cellProps.learner, id: props.cellProps.learner.userId }}
            closeModal={() => setShowGrantLateSubmissionPermitModal(false)}
            catalogId={catalogId}
          />
        )}
      />
      <NvModal
        type={ModalType.DYNAMIC}
        header={t.LEARNER_PROGRESS.OPTIONS.RESET_ATTEMPTS()}
        show={showResetAttemptsModal}
        onClose={() => setShowResetAttemptsModal(false)}
        fullHeight={false}
        body={(
          <ResetAttemptsModal
            // This `unknown` needs to be used, because currently, the backend is not sending all of the needed data for the `User` type
            user={{ ...props.cellProps.learner, id: props.cellProps.learner.userId } as unknown as User}
            onClose={() => setShowResetAttemptsModal(false)}
          />
        )}
      />
      <NvResponsiveTableOptionsCell<LearnerCellProps, LearnerProgress>
        optionItems={optionItems}
        optionsCellRef={props.cellProps.optionsCellRef}
        {...props.cellProps}
        dataQa={props.dataQa}
        dataQaId={props.dataQaId}
        iconSize='smallest'
      />
    </React.Fragment>
  );
};

export default LearnerRowOptions;
