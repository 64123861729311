import {
  getTopElement,
} from 'froala/helpers/nv-froala-functions';

/* @ngInject */
export default function nvFroalaOrigami(
  $timeout,
  $parse,
  $uibModal,
  $interpolate,
  $http,
  $q,
  Upload,
  S3UploadFactory,
  S3NameSpaces,
  CurrentUserManager,
  nvUtil,
  _,
  config,
) {
  const defaultWidth = 580;
  const defaultHeight = 325;
  window.config = config;

  return {
    restrict: 'A',
    scope: {
      inline: '=?',
      uploadType: '@?',
      includeCodeView: '=?',
      autofocus: '=?',
      showSubmitButton: '=?',
      submitCallback: '&?',
      popoverShownCallback: '&?',
      popoverHiddenCallback: '&?',
    },
    transclude: true,
    templateUrl: 'froala/templates/nv-froala-origami.html',
    controller: function ctrl($element) {
'ngInject';
      const vm = this;

      _.extend(vm, {
        showToolbar: false,
        toolbarTop: 0,
        filesInProgress: [],

        /* callback functions */
        // novoed menu
        focusCallback,
        blurCallback,
        updateSideMenuPosition,
        // file drop
        dropCallback,
        onComponentBlur,

        // user menu buttons
        formatUL,
        formatOL,
        toggleCodeView,
        uploadMedia,
        preventDefault,
        handleButtonMousedown,

        // helpers
        uploadFile,
      });


      function handleButtonMousedown($event) {
        // We strictly need this prevent default on safari, if it's not
        // there it doesn't focus correctly
        $event.preventDefault();
        // Safari doesn't automatically focus element on mousedown as
        // other browsers to match native platform behavior, since our
        // code relies on the focus, we are manually doing it.
        $event.currentTarget.focus();
      }

      /* Callback Functions */
      function focusCallback() {
        if (CurrentUserManager.hasLoggedIn() && !CurrentUserManager.user.hasViewedRteFte) {
          if (_.isFunction(CurrentUserManager.user.updateHasViewedRteFte)) {
            CurrentUserManager.user.updateHasViewedRteFte();
          } else {
            CurrentUserManager.user.hasViewedRteFte = true;
          }

          const modalInstance = $uibModal.open({
            templateUrl: 'froala/templates/fte-modal.html',
            windowClass: 'points-modal mobile-non-blocking super-modal',
            openedClass: 'super-modal',
            controllerAs: 'vm',
            resolve: {
              modalOptions() {
                return {};
              },
            },
          });
        }

        updateSideMenuPosition();

        $timeout(() => {
          vm.showToolbar = true;
          vm.hasBeenFocused = true;
        });

        const fteModal = $('.fte-modal')?.[0];
        if (fteModal) {
          nvUtil.focusFirstElement(fteModal);
        }
      }

      function blurCallback() {
        /**
         * in code view, froala does not update until exiting code view
         * https://github.com/froala/wysiwyg-editor/issues/1465
         * the recommended code was something like vm.froalaEditor('events.trigger', 'form.submit');
         * but this cleans up the html in way we don't like
         */
        /**
         * Only triggering contentChanged in code view mode
         */
        if (vm.codeView) {
          vm.froalaInstance.events.trigger('contentChanged', [], true);
        }

        vm.showFileError = false;
        vm.popoverHiddenCallback?.();

        $timeout(() => {
          if (!vm.inline && !nvUtil.checkFocusWithin($element[0])) {
            vm.showToolbar = false;
          }
        });
      }

      /**
       * This is created to hide toolbar when focus moves outside of the
       * rich text editor and toolbar buttons
       */
      function onComponentBlur() {
        // need timeout for blur event to complete
        $timeout(() => {
          if (!vm.inline && !nvUtil.checkFocusWithin($element[0])) {
            vm.showToolbar = false;
          }
        });
      }

      function updateSideMenuPosition() {
        $timeout(() => {
          const barHeight = vm.includeCodeView ? 150 : 110;

          if (vm.froalaEditor('selection.inEditor')) {
            const cursorBounds = vm.froalaEditor('position.getBoundingRect');
            const containerBounds = vm.froalaElement[0].getBoundingClientRect();

            if (cursorBounds.top + barHeight > containerBounds.bottom) {
              // pin bottom
              vm.toolbarTop = containerBounds.height - barHeight;
            } else {
              // follow cusor
              vm.toolbarTop = cursorBounds.top - containerBounds.top;
            }
          }
        });
      }

      function dropCallback(dropE) {
        dropE.preventDefault();
        dropE.stopPropagation();

        const dt = dropE.originalEvent.dataTransfer;

        if (dt?.files?.length) {
          let extension;
          let valid = true;

          valid = _.every(dt.files, (file) => {
            if (file.name.includes('.')) {
              extension = _.last(file.name.split('.'));

              if (_.indexOf(vm.filePatterns, `.${extension.toLowerCase()}`) === -1 && _.indexOf(vm.filePatterns, file.type) === -1) {
                $timeout(() => {
                  vm.fileError = 'FILE_UPLOAD.NOT_SUPPORTED';
                  vm.showFileError = true;
                  vm.popoverShownCallback?.();
                });
                return false;
              }
            }

            return true;
          });

          if (valid) {
            valid = _.every(dt.files, (file) => {
              if (file.size > 50 * 1024 * 1024) {
                $timeout(() => {
                  vm.fileError = 'FILE_UPLOAD.SIZE_EXCEEDED';
                  vm.showFileError = true;
                  vm.popoverShownCallback?.();
                });
                return false;
              }

              return true;
            });
          }

          if (valid) {
            uploadFiles(dt.files);
          }

          return false;
        }
        return false;
      }

      /* User Actions */
      function formatUL(e) {
        e.preventDefault();

        // get the direction of the block to set on the new tags
        const previousDir = getTopElement(vm.froalaInstance)?.getAttribute('dir');

        vm.froalaEditor('lists.format', 'UL');
        vm.froalaEditor('placeholder.refresh');

        if (previousDir) {
          getTopElement(vm.froalaInstance)?.setAttribute('dir', previousDir);
        }
      }

      function formatOL(e) {
        e.preventDefault();

        // get the direction of the block to set on the new tags
        const previousDir = getTopElement(vm.froalaInstance)?.getAttribute('dir');

        vm.froalaEditor('lists.format', 'OL');
        vm.froalaEditor('placeholder.refresh');

        if (previousDir) {
          getTopElement(vm.froalaInstance)?.setAttribute('dir', previousDir);
        }
      }

      function uploadMedia(e) {
        e.preventDefault();
        vm.froalaEditor('selection.save');

        const modalOptions = {
          multiple: true,
          model: {
            publicLink: '',
            mediaWidth: defaultWidth,
            mediaHeight: defaultHeight,
          },
          uploadType: vm.uploadType,
          afterUploadEach: uploadFile, // for s3 upload
          confirmTextKey: 'FROALA.UPLOAD_MEDIA.INSERT_MEDIA',
          onConfirm: (response) => { // for text link
            insertEmbeddingHtml(response);
          },
        };

        vm.froalaElement.data('uploadModalOpen', true);

        $uibModal.open({
          templateUrl: 'froala/templates/upload_media_modal.html',
          windowClass: 'super-modal',
          openedClass: 'super-modal',
          controller: 'MediaUploadModalInstanceCtrl as vm',
          resolve: {
            modalOptions() {
              return modalOptions;
            },
          },
        }).result.then(() => {
          vm.froalaElement.data('uploadModalOpen', false);
        }, () => {
          vm.froalaElement.data('uploadModalOpen', false);
          vm.froalaEditor('selection.restore');
        });
      }

      function toggleCodeView(e) {
        e.preventDefault();
        e.stopPropagation();
        vm.codeView = !vm.codeView;
        vm.froalaEditor('codeView.toggle');
        vm.toolbarTop = 0;
      }

      function preventDefault(e) {
        e.preventDefault();
      }

      /* Helpers */
      // insert proper html
      function insertEmbeddingHtml(data) {
        let embedHtml;
        if (data.isImage) {
          embedHtml = `<img src="${data.src}" alt="${data.src}"></img>&#8203;`;
        } else if (data.hasDirective) { // for cdf || zaption
          embedHtml = embedHtml.replace('\\"', '"');
        } else if (data.src) {
          const iframeOffset = 0;
          embedHtml = `<iframe
                src="${data.src}?width=${data.width}&height=${data.height}${data.fixedHeight ? '&fixed_height=true' : ''}"
                width="${data.width + iframeOffset}"
                height="${data.height + iframeOffset}"
                frameborder="0"
                allowfullscreen="true">
              </iframe>`;
        }

        vm.froalaEditor('selection.restore');

        if (embedHtml) {
          vm.froalaElement.data().markTouched();
          vm.froalaEditor('html.insert', embedHtml);
          vm.froalaEditor('undo.saveStep');
        }
      }

      // after upload to s3, upload to create NovoEd Embedding
      function uploadFile(response) {
        const { file } = response.config.data;

        return Upload.upload({
          url: '/embeddings/create',
          fields: {
            name: file.name,
            size: file.size,
            type: file.type,
            unique_id: file.uniqueId,
            media_width: defaultWidth,
            media_height: defaultHeight,
          },
        }).then((uploadResponse) => {
          insertEmbeddingHtml(uploadResponse.data);
        });
      }

      // helper for drag and drop to upload first to s3 then call uploadFile
      function uploadFiles(files) {
        const valid = _.every(files, (file) => !file.$error);
        const promises = [];

        if (!valid || !files || !files.length) {
          return;
        }

        _.each(files, (file) => {
          const s3UploadOjbect = S3UploadFactory.uploadToS3(file, S3NameSpaces.ATTACHMENTS, true);

          s3UploadOjbect.promise.then(vm.uploadFile);

          promises.push(s3UploadOjbect.promise);

          vm.filesInProgress.push(s3UploadOjbect);
        });

        $q.all(promises).then((data) => {
          vm.filesInProgress = [];
        });
      }
    },
    controllerAs: 'vm',
    bindToController: true,
    link(scope, element, attr, ctrl) {
      ctrl.froalaElement = element.find('[nv-froala-editor]'); // this is not the contenteditable
      ctrl.froalaEditor = angular.bind(ctrl.froalaElement, ctrl.froalaElement.froalaEditor); // create shorthand for ctrl.froalaElement.froalaEditor
      ctrl.froalaInstance = ctrl.froalaElement.froalaEditor(ctrl.options).data('froala.editor');

      // ctrl.froalaElement.on('froalaEditor.keydown', callback)
      // ctrl.froalaEditor('events.on', 'keydown', callback, true) <- only way to add true at the end to insert at the beginnig of event list
      // ctrl.froalaInstance.events.on('keydown', callback, true) <- only way to add true at the end to insert at the beginnig of event list

      // novoed menu
      ctrl.froalaElement.on('froalaEditor.focus', ctrl.focusCallback);
      ctrl.froalaInstance.events.on('blur', ctrl.blurCallback, true);
      ctrl.froalaElement.on('froalaEditor.click', ctrl.updateSideMenuPosition);
      ctrl.froalaElement.on('froalaEditor.contentChanged', ctrl.updateSideMenuPosition);
      ctrl.froalaElement.on('froalaEditor.keyup', ctrl.updateSideMenuPosition);


      const existingFocus = ctrl.froalaElement.data().focus;
      ctrl.froalaElement.data({
        focus: () => {
          existingFocus();
          ctrl.focusCallback();
        },
      });

      if (ctrl.autofocus) {
        ctrl.focusCallback();
      }

      // file drop
      if (ctrl.uploadType === 'image') {
        ctrl.filePatterns = config.files.rte.images.extensions;
      } else {
        ctrl.filePatterns = _.flatten(_.map(config.files.rte, (type) => type.extensions));
      }
      ctrl.froalaInstance.events.on('drop', ctrl.dropCallback, true);


      scope.$on('$destroy', () => {
        ctrl.froalaElement.off();
      });
    },
  };
}

/* saved code in case we bring it back in the near future */
/*
  const URL_REGEX = new RegExp(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/);
  const EMAIL_REGEX = new RegExp(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i);

  validation currently not needed
  function linkBeforeInsertCallback(e, editor, link, text, attrs) {
    var isValid;

    if (!link) {
      isValid = false;
    } else if (URL_REGEX.test(link) || EMAIL_REGEX.test(link)) {
      isValid = true;
    } else {
      isValid = false;
    }

    if (!isValid) {
      editor.selection.save();
      let $linkInsertLayer = $('.fr-link-insert-layer');
      $linkInsertLayer.find('.invalid-url').remove();
      $linkInsertLayer.prepend('<div class="invalid-url error">Must be a valid URL or E-mail address.</div>');

      return false;
    }
  }

  function linkInsertOnRefresh() {
    var $popup = vm.froalaEditor('popups.get', 'link.insert');
    $popup.find('.invalid-url').remove();
  }

  link validation currenlty not needed
  ctrl.froalaElement.on('froalaEditor.link.beforeInsert', ctrl.linkBeforeInsertCallback);
  ctrl.froalaElement.on('popups.onRefresh', 'link.insert', ctrl.linkInsertOnRefresh);
*/
