import { MockDefinition, MockTypes } from 'shared/axios/axios-mocks';

const teamsAndGroups = [
  {
    id: 8,
    catalogId: 'coursea',
    name: 'jcourse1',
    memberships: [
      {
        id: 9,
        profilePicture: '/assets/brand/temp.png',
        name: 'team1',
      },
    ],
  },
  {
    id: 9,
    catalogId: 'courseb',
    name: 'jcourse1',
    memberships: [
      {
        id: 9,
        profilePicture: '/assets/brand/temp.png',
        name: 'team1',
      },
    ],
  },
  {
    id: 10,
    catalogId: 'coursec',
    name: 'jcourse1',
    memberships: [
      {
        id: 9,
        profilePicture: '/assets/brand/temp.png',
        name: 'team1',
      },
    ],
  },
];

const TeamsAndGroupsMocks: MockDefinition[] = [
  {
    type: MockTypes.GET,
    url: '/users/current_memberships.json?all=1',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: teamsAndGroups,
    },
  },
  {
    type: MockTypes.PUT,
    url: 'lect-rewrite/teams/bulk_update',
    status: 202,
    response: {
      message: 'Successfully completed the request',
      result: { data: 'hello' },
    },
  },
];

export default TeamsAndGroupsMocks;
