import OrgLevelProfile from '../components/org-level-profile';

/* @ngInject */
export default function OrgLevelProfileController(
  $scope,
  CurrentUserManager,
  OrgLevelProfileManager,
) {
  $scope.OrgLevelProfile = OrgLevelProfile;
  $scope.CurrentUserManager = CurrentUserManager;
  $scope.OrgLevelProfileManager = OrgLevelProfileManager;
}
