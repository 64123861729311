import React from 'react';
import has from 'lodash/has';
import omit from 'lodash/omit';
import { jsx } from '@emotion/react';

type CourseUsageRegistryInterface = {
  free,
  allocate,
  contains,
  registry,
};

type CourseUsageRegistryProviderProps = CourseUsageRegistryInterface & {
  children: React.ReactNode,
};

const CourseUsageContext = React.createContext<CourseUsageRegistryInterface>({} as CourseUsageRegistryInterface);

export const CourseUsageRegistryProvider = (props: CourseUsageRegistryProviderProps) => {
  const {
    children,
    ...restProps
  } = props;

  return (
    <CourseUsageContext.Provider value={restProps}>
      {children}
    </CourseUsageContext.Provider>
  );
};

export const useCourseUsageRegistryContext = () => React.useContext(CourseUsageContext);

const useCourseUsageRegistry = (collections) => {
  const [registry, setRegistry] = React.useState(
    collections
      .reduce((acc, curr) => acc.concat(curr.courses), [])
      .reduce((acc, curr) => {
        acc[curr.catalogId] = true;
        return acc;
      }, {}),
  );

  const contains = (catalogId: string) => has(registry, catalogId);

  const allocate = (catalogId: string) => setRegistry((currentRegistry) => ({
    ...currentRegistry,
    [catalogId]: true,
  }));

  const free = (catalogId: string) => setRegistry((currentRegistry) => omit(currentRegistry, [catalogId]));

  return {
    free,
    allocate,
    contains,
    registry,
  };
};

export default useCourseUsageRegistry;
