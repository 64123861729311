/* @ngInject */
export default function NvQuizPoints(
  nvUtil,
  CurrentCourseManager,
) {
  return {
    scope: {
      isCompleted: '=',
      rewardsPointsProportionally: '=',
      pointsReceived: '=',
      totalPoints: '=',
      attemptsRemaining: '=?',
      canShowTotalPoints: '=?',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
      vm.nvUtil = nvUtil;
      vm.CurrentCourseManager = CurrentCourseManager;
    },
    controllerAs: 'vm',
    bindToController: true,
    transclude: true,
    templateUrl: 'quizzes/templates/nv-quiz-points.html',
  };
}
