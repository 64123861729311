import { css, SerializedStyles } from '@emotion/react';
import { getSanitizedStyles } from 'shared/utils';
import { primary } from 'styles/global_defaults/colors';

import {
  ReactComponent as LoadingIcon,
} from 'styles/icons/loading.svg';

export enum LoadingSpinnerType {
  DOTS = 'DOTS',
  SPINNER = 'SPINNER',
  CIRCLE = 'CIRCLE',
}

/**
 * Loading graphic animation. Configurable with LoadingSpinnerType between three-dots and rotating spinner
 * versions
 */
export const LoadingSpinner = (props: { styles?: SerializedStyles, type?: LoadingSpinnerType }) => {
  let spinnerType = LoadingSpinnerType.DOTS;

  if (props.type) {
    spinnerType = props.type;
  }

  if (spinnerType === LoadingSpinnerType.SPINNER) {
    const styles = css`
      @keyframes spin {
        from {transform:rotate(0deg);}
        to {transform:rotate(360deg);}
      }

      width: 24px;
      height: 24px;
      animation: spin 2.5s linear infinite;
      position: relative;
    `;

    return <i css={getSanitizedStyles([props.styles, styles])} className='icon icon-refresh icon-medium' />;
  }

  if (spinnerType === LoadingSpinnerType.CIRCLE) {
    const styles = css`
      @keyframes spin {
        from {transform:rotate(0deg);}
        to {transform:rotate(360deg);}
      }
      svg {
        animation: spin 2.5s linear infinite;
      }
    `;
    return (
      <div css={getSanitizedStyles([props.styles, styles])}>
        <LoadingIcon fill='primary' />
      </div>
    );
  }
  // Type wasn't 'spinner', render the three-dots version instead
  // Copy/pasted from Angularjs three-dots spinner animation

  const dotStyles = css`
    margin-left: auto;
    margin-right: auto;

    .spinner {
      margin: 0 auto;
      width:70px;
      text-align:center;
    }

    .spinner > div{
      width:18px;
      height:18px;
      background-color: ${primary};
      border-radius:100%;
      display:inline-block;
      -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
      animation: bouncedelay 1.4s infinite ease-in-out;
      -webkit-animation-fill-mode:both;animation-fill-mode:both
    }

    .spinner .bounce1{
      -webkit-animation-delay:-0.32s;
      animation-delay:-0.32s;
    }

    .spinner .bounce2{
      -webkit-animation-delay:-0.16s;
      animation-delay:-0.16s;
    }

    @-webkit-keyframes bouncedelay{0%,80%,100%{-webkit-transform:scale(0.0)}40%{-webkit-transform:scale(1.0)}}
    @keyframes bouncedelay{0%,80%,100%{transform:scale(0.0);-webkit-transform:scale(0.0)}40%{transform:scale(1.0);-webkit-transform:scale(1.0)}
    }
  `;

  return (
    <div css={getSanitizedStyles([props.styles, dotStyles])}>
      <div className='spinner'>
        <div className='bounce1' />
        <div className='bounce2' />
        <div className='bounce3' />
      </div>
    </div>
  );
};

export default LoadingSpinner;
