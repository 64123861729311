import React from 'react';
import { css } from '@emotion/react';
import { Omit } from 'utility-types';
import { Placement } from 'react-bootstrap/Overlay';
import { FieldError, Controller } from 'react-hook-form';

import t from 'react-translate';
import NvPopover from 'shared/components/nv-popover';
import NvTooltip, { TextAlign } from 'shared/components/nv-tooltip';
import NvSelect from 'shared/components/inputs/nv-select';
import NvTextArea from 'shared/components/inputs/nv-text-area';
import ClickableContainer from 'components/clickable-container';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import { gray3, gray6, black } from 'styles/global_defaults/colors';
import NvGooglePlacesAutocomplete from 'components/nv-google-places-autocomplete';
import ValidationErrorMessage from 'shared/components/inputs/validation-error-message';
import {
  quarterSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';

export enum InputType {
  DROPDOWN = 'dropdown',
  LOCATION = 'location',
  LONG_TEXT = 'long_text',
  SHORT_TEXT = 'short_text',
}

type Props = {
  // Data props
  text: string,
  type: InputType,
  selectOptions?: string[],
  // Controlling props
  value?: string,
  onChange?: (newValue: string) => void,
  // State props
  editable?: boolean,
  isSaving?: boolean,
  readonly?: boolean,
  // Styling props
  className?: string,
  replaceText?: boolean
  tooltipPlacement?: Placement,
  tooltipMaxWidth?: number,
  tooltipTextAlign?: TextAlign,
  textContainerClassName?: string,
  renderAfterText?: () => React.ReactNode,
  // Translation props
  tooltipText?: string,
  readonlyWarning?: string,
  // Data Qa
  dataQa?: string,
  // Max Length
  maxLength?: number,

  showInputWhenEmpty?: boolean,
  withForm?: boolean,
  name?: string,
};

const NvClickToEdit = (props: Props) => {
  const {
    text,
    type,
    value,
    onChange,
    readonly,
    className,
    tooltipText,
    readonlyWarning,
    editable = false,
    isSaving = false,
    tooltipPlacement,
    tooltipMaxWidth,
    tooltipTextAlign = TextAlign.CENTER,
    replaceText = true,
    selectOptions = [],
    textContainerClassName,
    renderAfterText = () => null,
    dataQa,
    maxLength,
    showInputWhenEmpty = false,
    withForm = false,
    name,
  } = props;


  const valueRef = React.useRef();
  const selectRef = React.useRef<any>();
  const isMountedRef = React.useRef(false);
  const typeRef = React.useRef<InputType>();
  typeRef.current = type;
  const [isEditing, setIsEditing] = React.useState(false);
  const [stateValue, setStateValue] = React.useState(value);
  const [dropdownFocused, setDropdownFocused] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>();

  /**
   * Updating internal state value from prop only on component updates (skipped
   * mounting)
   */
  React.useEffect(() => {
    if (isMountedRef.current) {
      setStateValue(value);
    } else {
      isMountedRef.current = true;
    }
  }, [value]);

  /**
   * Effect to focus focusable input when isEditing
   */
  React.useEffect(() => {
    if (isEditing) {
      if (typeRef.current === InputType.DROPDOWN) {
        selectRef.current.toggle.focus();
      } else {
        inputRef.current.focus();
      }
    }
  }, [isEditing]);

  const getContentContainerStyles = () => {
    if (isSaving) {
      return css`
        background-color: ${gray6};
      `;
    }

    if (editable && !isEditing) {
      return css`
        cursor: pointer;

        &:hover {
          background-color: ${gray6};
        }
      `;
    }

    return null;
  };

  const styles = css`
    display: block;
    color: ${black};
    ${getContentContainerStyles()};

    .text-container {
      min-height: ${standardSpacing + quarterSpacing}px;

      ${type === InputType.LONG_TEXT && css`
        white-space: pre-wrap;
      `};

      & > * {
        vertical-align: middle;
      }

      .field-text {
        color: ${gray3};
      }

      .field-value {
        color: ${black};
      }
    }
  `;

  const handleClickableContainerClick = () => setIsEditing(true);

  const renderInput = () => {
    const placeholder = replaceText ? text : undefined;

    const handleInputKeyDown = (
      event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      if (event.key !== 'Enter' || (event.target instanceof HTMLTextAreaElement && event.shiftKey)) return;

      if (valueRef.current !== undefined) {
        onChange(valueRef.current);
        valueRef.current = undefined;
      }

      setIsEditing(false);
    };

    const handleInputChange = (event) => {
      let newValue;
      if (typeof event === 'string') {
        newValue = event;
      } else {
        newValue = event.target.value;
      }

      setStateValue(newValue);
      valueRef.current = newValue;
    };

    const handleInputEditionFinished = (newValue?) => {
      if (valueRef.current !== undefined) {
        onChange(valueRef.current);
        valueRef.current = undefined;
      } else if (newValue) {
        onChange(newValue);
      }
      setIsEditing(false);
    };

    const nvSelectOptions = selectOptions.map((option) => ({
      value: option,
      label: option,
    }));
    const handleCustomInputChange = (newValue: string) => {
      handleInputChange(newValue);
      handleInputEditionFinished(newValue);
    };

    const readProps = readonly ? { readOnly: true } : {};
    const error = readonly ? { message: readonlyWarning } : undefined;

    switch (type) {
      case InputType.DROPDOWN: {
        const handleDropdownFocus = () => {
          setDropdownFocused(true);
        };

        const handleDropdownBlur = (event) => {
          const { container } = selectRef.current;

          if (!container.contains(event.relatedTarget)) {
            setDropdownFocused(false);
            handleInputEditionFinished();
          }
        };

        return (
          <NvPopover
            placement='top'
            preventOverflow
            show={readonly && dropdownFocused}
            content={<ValidationErrorMessage text={readonlyWarning} />}
          >
            <NvSelect
              ref={selectRef}
              disabled={readonly}
              options={nvSelectOptions}
              value={stateValue || null}
              onBlur={handleDropdownBlur}
              onFocus={handleDropdownFocus}
              onChange={handleCustomInputChange}
            />
          </NvPopover>
        );
      }
      case InputType.LOCATION:
        return (
          <NvGooglePlacesAutocomplete
            value={stateValue}
            readOnly={readonly}
            error={error as FieldError}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            onBlur={() => handleInputEditionFinished()}
            ref={inputRef as React.MutableRefObject<HTMLInputElement>}
          />
        );
      case InputType.SHORT_TEXT:
        return (
          <NvTextInput
            value={stateValue}
            preventPopoverOverflow
            errorOnTouching={false}
            placeholder={placeholder}
            error={error as FieldError}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            onBlur={() => handleInputEditionFinished()}
            ref={inputRef as React.MutableRefObject<HTMLInputElement>}
            maxLength={maxLength}
            withForm={withForm}
            name={name}
            forceShowErrorState={withForm}
            onFocus={() => {
              if (withForm) {
                setIsEditing(true);
              }
            }}
            {...readProps}
          />
        );
      case InputType.LONG_TEXT:
        return (
          <NvTextArea
            value={stateValue}
            preventPopoverOverflow
            placeholder={placeholder}
            error={error as FieldError}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            onBlur={() => handleInputEditionFinished()}
            ref={inputRef as React.MutableRefObject<HTMLTextAreaElement>}
            maxLength={maxLength}
            {...readProps}
          />
        );
      default: throw new Error('Unhandled field type');
    }
  };

  const textDataContainerClassName = `text-container${textContainerClassName ? ` ${textContainerClassName}` : ''}`;
  const isEmptyAndShowInput = showInputWhenEmpty && !value;

  const renderVariant = () => {
    if (!replaceText) {
      return (
        <React.Fragment>
          <div className={textDataContainerClassName}>
            <span className='field-text'>
              {text}
            </span>
            {!isEditing && (
              <React.Fragment>
                {!!value && type !== InputType.LONG_TEXT && (
                  <span className='field-value ml-2'>{value}</span>
                )}
                {renderAfterText()}
                {!!value && type === InputType.LONG_TEXT && (
                  <div className='field-value'>{value}</div>
                )}
              </React.Fragment>
            )}
          </div>
          {isEditing && (
            <div>
              {renderInput()}
            </div>
          )}
        </React.Fragment>
      );
    }

    return (isEditing || isEmptyAndShowInput) ? (
      renderInput()
    ) : (
      <div className={textDataContainerClassName}>
        <span>{value || text}</span>
        {renderAfterText()}
      </div>
    );
  };

  const isEditionDisabled = !editable || isSaving || isEditing;

  return (
    <NvTooltip
      offset={-4}
      text={tooltipText}
      placement={tooltipPlacement}
      maxWidth={tooltipMaxWidth}
      enabled={!isSaving && !isEditing && !!tooltipText}
      textAlign={tooltipTextAlign}
    >
      <ClickableContainer
        css={styles}
        layoutOnly={isEditionDisabled}
        onClick={handleClickableContainerClick}
        className={`content-container text-regular${className ? ` ${className}` : ''}`}
        data-qa={dataQa}
      >
        {isSaving ? (
          <div className={textDataContainerClassName}>
            <span>{t.FORM.SAVING()}</span>
          </div>
        ) : (
          renderVariant()
        )}
      </ClickableContainer>
    </NvTooltip>
  );
};

type FormProps = {
  name: string,
};

export const FormNvClickToEdit = (props: Omit<Props, 'value'> & FormProps) => {
  const {
    name,
    onChange: propsOnChange,
    withForm,
    ...restProps
  } = props;

  return (
    <Controller
      name={name}
      render={(data) => {
        const { value, onChange } = data.field;

        const handleChange = (newValue: string) => {
          onChange(newValue);
          propsOnChange?.(newValue);
        };

        return (
          <NvClickToEdit
            {...restProps}
            name={name}
            value={value}
            onChange={handleChange}
            withForm={withForm}
          />
        );
      }}
    />
  );
};

export default NvClickToEdit;
