/* @ngInject */
export default function AlertMessages(
  $q,
  $timeout,
  $translate,
) {
  const _this = this;

  const DURATION_MESSAGE_SHOWN = 10000;

  _this.arrayOfMessages = arrayOfMessages;
  _this.buttonClick = buttonClick;
  _this.messageIcon = messageIcon;
  _this.remove = remove;

  _this.duplicates = new Set();

  _this.messages = new Set();

  ['success', 'warning', 'error', 'liveEvent', 'info', 'share', 'oAuthError'].forEach((type) => {
    this[type] = (...args) => addMessageToStack(type, ...args);
  }, _this);

  /** * ************************************
   * A message has the following structure:
   * {
   *    type: string
   *    headerKey: string
   *    messageKey: string
   *    headerValues: object
   *    messageValues: object
   *    duration: integer
   *    button: object {
   *        text: string
   *        href: string (url)
   *        onClick: function
   *     }
   *    onClose: function
   *    uniqueId
   * }
   * The only thing that is needed is a type and either a headerKey or a messageKey (and their respective values, if those are needed)
   * In order for a button to display, text and at least one of href or onclick is needed
   * * *************************************
   * If you want to add a new type of message:
   * (1) Add the type to the function above
   * (2) Add the proper icon to the messageIcon fn
   * (3) Add the desired coloring in _shared.scss
   */

  // Private Functions
  function addMessageToStack(
    type,
    headerKey,
    messageKey,
    headerValues = {},
    messageValues = {},
    duration = DURATION_MESSAGE_SHOWN,
    button,
    onClose,
    uniqueId,
    dataQa,
  ) {
    if (_this.duplicates.has(uniqueId) || duration < 1000) return undefined;

    let header;
    let message;
    const promises = [];

    if (headerKey) {
      const headerPromise = $translate(headerKey, headerValues);
      headerPromise.then((translation) => {
        header = translation;
      });
      promises.push(headerPromise);
    }

    if (messageKey) {
      const messagePromise = $translate(messageKey, messageValues);
      messagePromise.then((translation) => {
        message = translation;
      });
      promises.push(messagePromise);
    }

    return $q.all(promises).then(() => addToStack({
      type, header, message, button, onClose, uniqueId, dataQa,
    }, duration));
  }

  function addToStack(obj, duration) {
    _this.messages.add(obj);
    if (obj.uniqueId) {
      _this.duplicates.add(obj.uniqueId);
    }
    if (duration !== Infinity) {
      const timeoutPromise = $timeout(() => {
        remove(obj);
      }, duration);
      obj.promise = timeoutPromise;
    }
    return obj;
  }

  function remove(obj) {
    if (obj.promise) {
      $timeout.cancel(obj.promise);
    }
    obj.onClose?.();
    _this.messages.delete(obj);
  }

  // This is necessary, because
  // (a) AngularJS doesn't directly support ES6 features like Sets
  // (b) it also doesn't support writing global variables in expressions (in the jade file)
  function arrayOfMessages() {
    return Array.from(_this.messages).reverse();
  }

  function messageIcon(type) {
    switch (type) {
      case 'success':
        return 'icon-success';
      case 'warning':
      case 'oAuthError':
        return 'icon-warning';
      case 'error':
        return 'icon-error';
      case 'liveEvent':
        return 'icon-zoom';
      case 'info':
        return 'icon-info';
      case 'share':
        return 'icon-share';
      default:
        return 'icon-info';
    }
  }

  function buttonClick(obj) {
    obj.button?.onClick?.();
    remove(obj);
  }

  return _this;
}
