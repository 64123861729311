import { css } from '@emotion/react';

import React, {
  useLayoutEffect,
  useRef,
  useCallback,
} from 'react';
import { gray3, gray7 } from 'styles/global_defaults/colors';
import t from 'react-translate';

export enum CheckboxValues {
  UNCHECKED = 0,
  CHECKED = 1,
  INDETERMINATE = 2,
}

type NvSampleEmailProps = {
  subject: string,
  from: string
  content: string
  isPreviewLoading?: boolean
};

export const NvSampleEmail = (props: NvSampleEmailProps) => {
  const iframeRef = useRef<HTMLIFrameElement>();
  const styles = css`
    &.nv-sample-email {
      background-color: ${gray7};

      .subject, .label {
        white-space: nowrap;
      }

      .loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 500px;
        color: ${gray3};
      }
    }
  `;

  const resizeIframe = () => {
    const minHeight = 300;
    // iframeRef.current.style.height = '0';
    iframeRef.current.style.height = `${Math.max(iframeRef.current.contentWindow.document.body.scrollHeight, iframeRef.current.contentWindow.document.body.clientHeight, minHeight)}px`;
  };

  const addOnLoadEventForImages = useCallback(() => {
    if (iframeRef.current) {
      const images = Array.from(iframeRef.current.contentWindow.document.getElementsByTagName('img'));
      if (images.length > 0) {
        images.forEach((img) => img.addEventListener('load', resizeIframe));
      }
    }
  }, []);

  const removeOnLoadEventForImages = useCallback(() => {
    if (iframeRef.current) {
      const images = Array.from(iframeRef.current.contentWindow.document.getElementsByTagName('img'));
      if (images.length > 0) {
        images.forEach((img) => img.removeEventListener('load', resizeIframe));
      }
    }
  }, []);

  const onIframeLoad = useCallback(() => {
    // Iframe onload event will overwrite the content set by useLayoutEffect.
    // So setting content on load trigger
    iframeRef.current.contentDocument.body.innerHTML = props.content;
    iframeRef.current.contentWindow.document.addEventListener('load', resizeIframe);
    // calling resize right after load
    resizeIframe();
    addOnLoadEventForImages();
  }, [props.content, addOnLoadEventForImages]);

  useLayoutEffect(() => {
    let timer;
    if (iframeRef.current) {
      // Onload is not triggered due to the following React Issue in chrome.
      // https://github.com/facebook/react/issues/6541
      // So setting content right now. It will be replaced onload( only triggers in firefox)
      iframeRef.current.contentDocument.body.innerHTML = props.content;
      // HACK: Since load event is not triggered in chrome, executing resizeIframe after a 500ms delay.
      // Issue: Only for the first ever load, if the css file took more than 500ms to load, preview may
      // appear with a scroll bar for the height that is set from the css file
      // Eg: Right now, email preview footer height is set from css file, so this won't be considered until
      // css file load completes.
      resizeIframe();
      timer = setTimeout(() => {
        resizeIframe();
      }, 500);

      // Images takes more time to load. Adding Event Listeners For all img tags
      // to refresh the iframe once the image is loaded
      addOnLoadEventForImages();
      iframeRef.current.contentWindow.addEventListener('load', onIframeLoad);
    }

    return () => {
      clearTimeout(timer);
      // Clearing Image Event Listeners
      removeOnLoadEventForImages();
      iframeRef.current?.contentWindow.removeEventListener('load', onIframeLoad);
    };
  }, [props.content, props.isPreviewLoading, onIframeLoad, addOnLoadEventForImages, removeOnLoadEventForImages]);

  return (
    <div css={styles} className='nv-sample-email text-regular '>
      {props.isPreviewLoading
        ? <div className='loading'>{t.COURSE_COMMUNICATIONS.SAMPLE_EMAIL.LOADING()}</div>
        : (
          <React.Fragment>
            <div className='bs4-row pt-4'>
              <div className='subject bs4-col-2 text-gray-3 text-right'>{t.COURSE_COMMUNICATIONS.SAMPLE_EMAIL.SUBJECT()}</div>
              <div className='bs4-col-10'>{props.subject}</div>
            </div>
            <div className='bs4-row mt-3'>
              <div className='header bs4-col-2 text-gray-3 text-right'>{t.COURSE_COMMUNICATIONS.SAMPLE_EMAIL.FROM()}</div>
              <div className='bs4-col-10'>{props.from}</div>
            </div>
            <div className='bs4-row mt-5'>
              <div className='bs4-col-1' />
              <div className='bs4-col-10'>
                {props.content && <iframe ref={iframeRef} frameBorder='0' style={{ border: 0 }} width='100%' scrolling='auto' id='preview' title='email preview' />}
              </div>
            </div>
          </React.Fragment>
        )}
    </div>
  );
};

export default NvSampleEmail;
