import { css } from '@emotion/react';
import { useState, useEffect, useRef } from 'react';
import { getAudioAnalyzer, getVolume } from 'recording/services/media-visualizer-helper';
import NvIcon from 'shared/components/nv-icon';
import { gray2 } from 'styles/global_defaults/colors';
import { halfSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';

type VideoAudioVisualizerProps = {
  mediaStream: MediaStream;
  horizontal?: boolean;
};

const MAX_VOLUME = 9;

/* Displays audio visualizer for video stream */
const VideoAudioVisualizer = ({
  mediaStream,
  horizontal = false,
}: VideoAudioVisualizerProps) => {
  const styles = css`
    ${horizontal ? css`
      display: flex;
      flex-direction: row-reverse;
    ` : ''}

    .bar {
      background-color: ${gray2};
      border-radius: 3px;

      ${horizontal ? css`
        width: ${quarterSpacing}px;
        height: ${standardSpacing}px;
        margin-left: ${halfSpacing}px;
      ` : css`
        width: 12px;
        height: 6px;
        margin-bottom: ${halfSpacing}px;
      `}

      &.active {
        background-color: white;
      }
    }

    .icon-audio {
      color: white;
    }
  `;

  const analyserRef = useRef<AnalyserNode>();

  const [volume, setVolume] = useState<number>(0);
  const [bars, setBars] = useState<any>();


  useEffect(() => {
    const levels: JSX.Element[] = [];
    for (let i = MAX_VOLUME; i > 0; i -= 1) {
      levels.push(
        <div key={i} className={`bar ${i < volume ? 'active' : ''}`} />,
      );
    }
    setBars(levels);
  }, [volume]);


  useEffect(() => {
    analyserRef.current = getAudioAnalyzer(mediaStream);

    return () => {
      analyserRef.current = null;
    };
  }, [mediaStream]);

  useEffect(() => {
    const interval = setInterval(() => {
      const volumePercentage = getVolume(analyserRef.current);
      setVolume(Math.floor(volumePercentage * MAX_VOLUME));
    }, 150);

    return () => clearInterval(interval);
  }, []);

  return (
    <div css={styles} className='video-audio-visualizer'>
      {bars}
      <NvIcon icon='audio' size={horizontal ? 'small' : 'xss-smallest'} />
    </div>
  );
};

export default VideoAudioVisualizer;
