

import { connect } from 'react-redux';
import t from 'react-translate';

// Schemas
import { RootState } from 'redux/schemas';
import { Communication, NotificationType, ItemState, CommunicationCategory } from 'redux/schemas/models/course-communication';
import { MyAccount } from 'redux/schemas/models/my-account';

// Contexts
import { getCategory } from 'communications/course_communications/contexts/communication-context';

// Selectors
import { getCommunication, getItemState, getNotificationTypes } from 'redux/selectors/course-communications';

// Components
import NvIcon from 'shared/components/nv-icon';
import NvUserAvatar from 'components/nv-user-avatar';
import CommunicationRow from './communication-row';
import CommunicationActionsDropdown from './communication-actions-dropdown';

type CommunicationTitleRowProps = {
  communicationId: number,
};

type StateProps = {
  communication: Communication,
  itemState: ItemState,
  notificationTypes: NotificationType[],
};

const CommunicationTitleRow = (props: CommunicationTitleRowProps & StateProps) => {
  // adminUser only used for displaying user name and avatar with badge in announcements list
  // So it takes from the communicationAdmin in the communication object
  const adminUser = props.communication.communicationAdmin;

  let itemStateText: string;
  if (props.itemState) {
    if (props.itemState === ItemState.DRAFT) {
      const category = getCategory(props.communication.communicationType);
      const draftKey = category === CommunicationCategory.TRIGGERS || category === CommunicationCategory.AUTOMATED_JOURNEY_EMAIL
        ? 'DRAFT_WILL_NOT_TRIGGER' : 'DRAFT_WILL_NOT_SENT';

      itemStateText = t.COURSE_COMMUNICATIONS.ITEM_STATES[draftKey]();
    } else {
      itemStateText = t.COURSE_COMMUNICATIONS.ITEM_STATES[props.itemState.toUpperCase()]();
    }
  }

  return (
    <CommunicationRow>
      <div className='communication-badge'>{itemStateText}</div>
      <div className='w-100'>
        {getCategory(props.communication.communicationType) === CommunicationCategory.SCHEDULED_ANNOUNCEMENT
          ? (
            <div className='content-wrapper mb-2'>
              <div className='icons-wrapper announcer-avatar'>
                {adminUser && (
                  <NvUserAvatar
                    borderType='round'
                    size='sm'
                    user={adminUser}
                  />
                )}
              </div>
              <div className='content text-medium'>
                <span className='title bold'>
                  {t.COURSE_COMMUNICATIONS.ANNOUNCEMENT_BY(adminUser?.fullName)}
                </span>
              </div>
            </div>
          ) : null}
        <div className='content-wrapper'>
          <div className='icons-wrapper'>
            { props.notificationTypes?.map((icon) => (
              <NvIcon key={icon} size='xss-smallest' icon={icon} />
            ))}
          </div>
          <div className='content text-medium'>
            <span className='title subject'>
              {props.communication?.communication?.subject}
            </span>
          </div>
        </div>
      </div>
      <CommunicationActionsDropdown communicationId={props.communicationId} />
    </CommunicationRow>
  );
};

const ConnectedCommunicationTitleRow = connect(
  (state: RootState, ownProps: CommunicationTitleRowProps) => ({
    communication: getCommunication(state, ownProps.communicationId),
    itemState: getItemState(state, ownProps.communicationId),
    notificationTypes: getNotificationTypes(state, ownProps.communicationId),
  }),
)(CommunicationTitleRow);

export default ConnectedCommunicationTitleRow;
