import React, { useCallback, useEffect, useState } from 'react';
import t from 'react-translate';
import { isEmpty } from 'underscore';

// Schemas
import { Post } from 'redux/schemas/api/video-practice';

// Actions
import { useAppDispatch } from 'redux/store';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';

// Services & Hooks
import useUploadFile, { convertBlobToFile, NovoEdFile } from 'shared/hooks/use-upload-file';
import { MediaSourceType, requestMediaStream } from 'recording/services/media-stream';

// Components
import RecordingOverlay from 'recording/components/recording-overlay';
import LoadingPlaceholder from 'communications/course_communications/components/loading-placeholder';
import { S3NameSpaces } from 'shared/services/s3-upload-factory';
import VideoCommentUploadProgress from './video-comment-upload-progress';

type RecordVideoCommentProps = {
  onClose: (file?: NovoEdFile) => void,
  onSubmit: Function,
};

const RecordVideoComment = ({
  onClose = () => {},
  onSubmit,
}: RecordVideoCommentProps) => {
  const dispatch = useAppDispatch();
  const [mediaStream, setMediaStream] = useState<MediaStream>();
  const [showVideoCommentOverlay, setShowVideoCommentOverlay] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { uploadFiles, isUploading, hasUploadPercentage, filesUploading, abortUpload } = useUploadFile();

  const requestMediaAndShowOverlay = useCallback(() => {
    requestMediaStream(MediaSourceType.CAMERA_MIC)
      .then((stream: MediaStream) => {
        setMediaStream(stream);
        setShowVideoCommentOverlay(true);
      })
      .catch(err => {
        if (err.name === 'NotFoundError') {
          dispatch(openConfirmationDialog({
            confirmButtonVariant: 'primary',
            title: t.FILE_UPLOAD.CANNOT_RECORD.TITLE(),
            bodyText: t.FILE_UPLOAD.CANNOT_RECORD.VIDEO_NOT_FOUND(),
            confirmText: t.FORM.CONTINUE(),
            onConfirm: requestMediaAndShowOverlay,
            onCancel: () => onClose(),
          }));
        } else if (err.name === 'NotAllowedError') {
          dispatch(openConfirmationDialog({
            confirmButtonVariant: 'primary',
            title: t.FILE_UPLOAD.CANNOT_RECORD.TITLE(),
            bodyText: t.FILE_UPLOAD.CANNOT_RECORD.VIDEO_NOT_ALLOWED(),
            confirmText: t.FORM.CONTINUE(),
            onConfirm: requestMediaAndShowOverlay,
            onCancel: () => onClose(),
          }));
        }
      });
  }, [dispatch, onClose]);

  const uploadFileAndSubmit = useCallback(async (file) => {
    const allFiles = await uploadFiles([file], S3NameSpaces.ATTACHMENTS);

    if (isEmpty(allFiles) || isEmpty(allFiles[0])) {
      // Might be aborting upload, close and return
      onClose();
      return;
    }

    const novoedFile = allFiles[0];

    setIsSubmitting(true);
    const comment: Post = {};
    if (novoedFile) {
      // NovoedFile is converted to FileUpload
      comment.video = {
        fileName: novoedFile.name,
        fileSize: novoedFile.size,
        uniqueId: novoedFile.uniqueId,
        contentType: novoedFile.type,
      };
    }
    await onSubmit(comment);
    setIsSubmitting(false);
    onClose();
  }, [onClose, onSubmit, uploadFiles]);

  const onCloseVideoCommentOverlay = useCallback((blob: Blob) => {
    setShowVideoCommentOverlay(false);
    if (blob) {
      const file = convertBlobToFile(blob);

      uploadFileAndSubmit(file);
    } else {
      onClose();
    }
  }, [uploadFileAndSubmit, onClose]);

  // Request on mount itself
  useEffect(() => {
    requestMediaAndShowOverlay();
  }, []);

  return (
    <React.Fragment>
      {showVideoCommentOverlay && (
        <RecordingOverlay
          mediaSourceType={MediaSourceType.CAMERA_MIC}
          initialMediaStream={mediaStream}
          onClose={onCloseVideoCommentOverlay}
          titleText={t.PRACTICE_ROOM.COMMENTS.VIDEO_COMMENT()}
          submitButtonText={t.PRACTICE_ROOM.COMMENTS.ADD_COMMENT()}
        />
      )}
      {isUploading && (
        <VideoCommentUploadProgress
          hasUploadPercentage={hasUploadPercentage}
          uploadInProgress={isUploading}
          fileUploading={filesUploading[0]}
          abortUpload={abortUpload}
        />
      )}
      {isSubmitting && (
        <LoadingPlaceholder />
      )}
    </React.Fragment>
  );
};

export default RecordVideoComment;
