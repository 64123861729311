import { css } from '@emotion/react';
import t from 'react-translate';
import { tripleSpacing } from 'styles/global_defaults/scaffolding';
import { useSelector } from 'react-redux';
import { getCourse } from 'redux/selectors/course';
import { useParams } from 'react-router-dom';
import { CollectionCourse } from 'redux/schemas/models/collections';
import { useAppDispatch } from 'redux/store';
import { createFolder } from 'redux/actions/collections';
import { useContext } from 'react';
import { AngularServicesContext } from 'react-app';

// components
import NvIcon from 'shared/components/nv-icon';
import { Button } from 'react-bootstrap';
import { config } from '../../../../config/config.json';

interface DetailHeaderProps {
  setCollectionFolderIds: Function;
}

const DetailsHeader = (props: DetailHeaderProps) => {
  const styles = css`
    & {
      height: ${tripleSpacing}px;
    }

    .new-button {
      min-width: 120px;
    }
  `;

  const { CurrentPermissionsManager } = useContext(AngularServicesContext);
  const dispatch = useAppDispatch();

  const { catalogId, collectionId } = useParams<{ collectionId: string, catalogId: string }>();
  const collection: CollectionCourse = useSelector((state) => getCourse(state, catalogId));

  const newFolder = () => {
    dispatch(createFolder({
      title: 'Folder Name',
      collectionId,
      catalogId,
    })).then((response) => {
      props.setCollectionFolderIds((prevState) => ([...prevState, response.payload.id]));
    });
  };

  return (
    <div
      className='d-flex pl-7 pr-6 align-items-center border-bottom border-gray-6'
      css={styles}
    >
      <div className='text-regular ellipsis mr-6'>
        {collection?.description}
      </div>
      {CurrentPermissionsManager.hasCollectionBuilderPermissions() && (
        <Button
          className='new-button d-flex align-items-center px-2 ml-auto'
          variant='primary'
          size='sm'
          onClick={newFolder}
          data-qa={config.pendo.contentManagement.addNewFolder}
        >
          <NvIcon icon='create-new-post' size='xs-smallest' className='mr-1' />
          {t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.NEW_FOLDER_BUTTON()}
        </Button>
      )}
    </div>
  );
};

export default DetailsHeader;

