import { getScrollParent, getOffsetTopRelativeToSpecificParent } from 'shared/utils';

/**
 * Directive to fix NOV-72343 browser specific bug
 */
/* @ngInject */
export default function NvFirefoxAutomaticScrollToFocusedElement($window) {
  const isFirefox = /firefox/i.test($window.navigator.userAgent);

  return {
    restrict: 'A',
    compile(elem) {
      if (isFirefox) {
        // For some reason we are not getting correct offsetTop value in firefox
        // and it works fine if we set the display of the element to be block.
        elem.addClass('display-block');

        return function link(scope, element) {
          const nativeElement = element[0];
          const scrollParent = getScrollParent(nativeElement);

          const handleFocus = () => {
            const parentHeight = scrollParent.getBoundingClientRect().height;
            const elementHeight = nativeElement.getBoundingClientRect().height;
            const offsetTop = getOffsetTopRelativeToSpecificParent(nativeElement, scrollParent);

            const isElementOffAtEnd = ((offsetTop + elementHeight) > (scrollParent.scrollTop + parentHeight));
            const isElementOffAtStart = ((offsetTop + elementHeight) < (scrollParent.scrollTop + elementHeight));

            if (isElementOffAtStart) {
              scrollParent.scrollTop = offsetTop;
            } else if (isElementOffAtEnd) {
              scrollParent.scrollTop = offsetTop - (parentHeight - elementHeight);
            }
          };

          nativeElement.addEventListener('focus', handleFocus);
        };
      }

      return function () {};
    },
  };
}
