import React from 'react';


import { connect, useSelector } from 'react-redux';
import t from 'react-translate';

// schemas
import { RootState } from 'redux/schemas';
import { NTimelinePost } from 'redux/schemas/models/timeline-post';
import { ActivityType } from 'redux/schemas/models/activity';
import { ComponentKeyPluralized } from 'redux/schemas/models/lecture-component';

// actions
import { setActivityExpandedInAutomatedCommunications } from 'redux/actions/course-communications';
import { isActivityExpandedInAutomatedCommunications } from 'redux/selectors/course-communications';

// selectors
import { getPost, hasPostGotCommunicationError } from 'redux/selectors/timeline';

// components
import NvIcon from 'shared/components/nv-icon';
import NvStackedTab from 'shared/components/nv-stacked-tab';

import CommunicationCreateNewDropdown from 'communications/course_communications/components/communication-create-new-dropdown';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';

type PostLectureComponentProps = {
  postId: number;
};

type StateProps = {
  post: NTimelinePost,
  hasCommunicationError: boolean,
};

const mapDispatchToProps = {
  setExpanded: setActivityExpandedInAutomatedCommunications,
};

const PostLectureComponent = (props: PostLectureComponentProps & StateProps & typeof mapDispatchToProps) => {
  const isExpanded = useSelector<RootState, boolean>((state) => isActivityExpandedInAutomatedCommunications(state, ComponentKeyPluralized.POST, props.postId));

  return (
    <React.Fragment>
      <div className={`lecture-component-row ${isExpanded && props.post?.communicationsCount > 0 && 'expanded-box'}`}>
        <NvIcon icon='conversations' size='smallest' />
        <div className='description'>{props.post?.title}</div>
        <div className=''>
          <CommunicationCreateNewDropdown activityType={ActivityType.POST} activityId={props.postId} />
        </div>
        { props.post?.communicationsCount > 0 && (
          <NvStackedTab
            count={props.post.communicationsCount}
            hasError={props.hasCommunicationError}
            isExpanded={isExpanded}
            errorMessage={t.COURSE_COMMUNICATIONS.WARNINGS.ACTIVITY_COMMUNICATION()}
            onClick={() => props.setExpanded({
              type: ComponentKeyPluralized.POST,
              id: props.postId,
              isExpanded: !isExpanded,
            })}
          />
        )}
      </div>
      {
        // Communications
        isExpanded && props.post?.communications && props.post.communications.map(communicationId => (
          <CommunicationItem key={communicationId.toString()} communicationId={communicationId} />
        ))
      }
    </React.Fragment>
  );
};

const ConnectedPostLectureComponent = connect(
  (state: RootState, ownProps: PostLectureComponentProps) => ({
    post: getPost(state, ownProps.postId),
    hasCommunicationError: hasPostGotCommunicationError(state, ownProps.postId),
  }),
  mapDispatchToProps,
)(PostLectureComponent);

export default ConnectedPostLectureComponent;
