import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { useInView } from 'react-intersection-observer';

import { AngularServicesContext } from 'react-app';
import { CourseTimelineContext } from 'timelines/components/course_home/course-timeline';
import { useAppDispatch } from 'redux/store';
import { RootState } from 'redux/schemas';
import { getTimelineAssignmentsList } from 'redux/actions/timeline';
import { TimelineActivity } from 'redux/schemas/models/activity';
import { getCourseAliases } from 'redux/selectors/course';

import LoadingPlaceholder, { LoaderType } from 'shared/components/loading-placeholder';
import TimelineActivityRow from 'timelines/components/timeline-row/timeline-activity-row';
import useCompletionCriteria from '../../use-completion-criteria';

const Assignments = () => {
  const dispatch = useAppDispatch();
  const { vieweeId } = useContext(CourseTimelineContext) || {};

  const {
    CurrentUserManager,
  } = useContext(AngularServicesContext);

  const aliases = useSelector((state: RootState) => getCourseAliases(state));

  const catalogId = useSelector((state: RootState) => state.app.currentCatalogId);
  const { loadingTimelineAssignments, hasMore, lastLoadedPage } = useSelector((state: RootState) => state.app.timeline.assignments);
  const assignments: TimelineActivity[] = useSelector((state: RootState) => state.models.timelines[catalogId]?.assignmentActivities);

  const { ref: endRef, inView } = useInView();

  const {
    requiredAssignmentsCount,
    unreleasedTodoAssignmentsCount,
  } = useCompletionCriteria();

  useEffect(() => {
    if (inView && !loadingTimelineAssignments && hasMore) {
      // fetch the timeline assignments
      dispatch(getTimelineAssignmentsList({
        catalogId,
        page: lastLoadedPage + 1,
        vieweeId,
      }));
    }
  }, [
    dispatch, catalogId, inView, hasMore, loadingTimelineAssignments,
    lastLoadedPage, vieweeId,
  ]);

  return (
    <div className='body-text-wrapper'>
      <div className='mt-6 text-center card-title'>
        { t.COMPLETION_CRITERIA.COMPLETE_ASSIGNMENTS({
          requiredAssignmentsCount,
          assignmentAliasSingularized: aliases.assignmentAliases.AssignmentAlias,
          assignmentAliasPluralized: aliases.assignmentAliases.AssignmentsAlias,
        })}
      </div>
      <div>
        {assignments?.map((assignment) => (
          <TimelineActivityRow
            key={`assignment-${assignment.lectureComponentId}-${assignment.id}`}
            activity={assignment}
          />
        ))}
        {loadingTimelineAssignments && (
          <div className='pt-4'>
            <LoadingPlaceholder loaderType={LoaderType.HALF} />
          </div>
        )}
        {unreleasedTodoAssignmentsCount > 0
          && !hasMore
          && !CurrentUserManager.isAdmin()
          && (
            <div className='unreleased-todos py-2'>
              <span className='title muted'>
                {t.COMPLETION_CRITERIA.X_UNRELEASED_ASSIGNMENTS({
                  count: unreleasedTodoAssignmentsCount,
                  assignmentAliasSingularized: aliases.assignmentAliases.assignmentAlias,
                  assignmentAliasPluralized: aliases.assignmentAliases.assignmentsAlias,
                })}
              </span>
            </div>
          )}
        <span ref={endRef} />
      </div>
    </div>
  );
};

export default Assignments;
