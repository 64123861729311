import { css } from '@emotion/react';
import { useState, useEffect, useContext } from 'react';
import t from 'react-translate';
import { useSelector } from 'react-redux';

// Contexts
import { AngularServicesContext } from 'react-app';

// Hooks
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';
import useTranslationsLoaded from 'shared/hooks/use-translations-loaded';

// Schemas
import { RootState } from 'redux/schemas';
import { ScenariosListState } from 'redux/schemas/app/video-practice';

// Actions
import { useAppDispatch } from 'redux/store';
import { getFlyoutScenarios } from 'redux/actions/video-practice';

// Selectors
import { getScenarioListDetails } from 'redux/selectors/video-practice';

// Styles
import { gray5 } from 'styles/global_defaults/colors';
import { standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { handheld } from 'styles/global_defaults/media-queries';

// Components
import LoadingPlaceholder from 'shared/components/loading-placeholder';
import NvIcon from 'shared/components/nv-icon';
import LoadingWrapper, { LoaderType } from 'shared/components/loading-wrapper';
import ClickableContainer from 'components/clickable-container';
import ScenarioRow from './scenario-row';

/**
 * Component displayed in the Practice Room Flyout. This component will
 * list the scenarios and user can go to the particular scenario by clicking
 * on it.
 */

const styles = css`
  width: 360px;

  ${handheld(css`
    width: 100vw;
  `)};

  .scenarios-header-wrapper {
    position: fixed;
    width: inherit;
    height: ${tripleSpacing}px;
    background-color: #fff;
    border-bottom: 1px solid ${gray5};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .scenarios-close {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: ${standardSpacing}px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .scenarios-content {
    padding-top: ${tripleSpacing}px;

    .loading-wrapper {
      padding: ${standardSpacing}px;
    }
  }
`;

const PracticeScenarios = () => {
  const { FlyoutModalManager } = useContext(AngularServicesContext);
  const dispatch = useAppDispatch();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);

  const { $state } = useContext(AngularServicesContext);

  const {
    isOnceLoaded, isLoading,
    hasMore, sortedScenarioIds,
  } = useSelector<RootState, ScenariosListState>((state) => getScenarioListDetails(state, $state.params?.catalogId));

  const scrollingElement: HTMLElement = document.querySelector('.flyout-content');
  const isPageBottom = useInfiniteScroll(scrollingElement, 20);

  // Get scenarios on page change
  useEffect(() => {
    dispatch(getFlyoutScenarios({ page: pageNo }));
  }, [dispatch, pageNo]);

  // Change page no when scrolled to bottom
  useEffect(() => {
    if (scrollingElement
      && isPageBottom
      && !isLoading
      && hasMore) {
      setPageNo((prePageNo => prePageNo + 1));
    }
  }, [scrollingElement, isPageBottom, isLoading, hasMore]);

  // First load doesn't need the bottom loading. Bottom loading need to be set
  // only after the first call
  useEffect(() => {
    if (isOnceLoaded && pageNo > 1) {
      setIsPageLoading(isLoading);
    }
  }, [isOnceLoaded, pageNo, isLoading]);

  const translationsLoaded = useTranslationsLoaded();
  if (!translationsLoaded) {
    return null;
  }

  return (
    <div css={styles}>
      <div className='scenarios-header-wrapper'>
        <div
          className='course-title-small font-weight-bolder text-center'
          aria-live='polite'
        >
          {t.LHS.PRACTICE_ROOM()}
        </div>
        <ClickableContainer
          className='scenarios-close'
          onClick={() => FlyoutModalManager.closeFlyout()}
          aria-label={t.NOVOED.CLOSE()}
        >
          <NvIcon size='small' icon='close' className='black' />
        </ClickableContainer>
      </div>
      <div className='scenarios-content'>
        <LoadingWrapper isLoaded={isOnceLoaded} loaderType={LoaderType.PLACEHOLDER}>
          {sortedScenarioIds.map((scenarioId) => <ScenarioRow key={scenarioId} scenarioId={scenarioId} />)}
        </LoadingWrapper>
        {isPageLoading && (
          <div className='p-4'>
            <LoadingPlaceholder />
          </div>
        )}
      </div>
    </div>
  );
};

export default PracticeScenarios;
