/* @ngInject */
export default function TeamDirectoryManager(
  _,
  DirectoryManagerInterface,
  TeamManager,
  TeamsResources,
  CurrentCourseManager,
  TeamModel,
  $state,
  $translate,
) {
  const manager = DirectoryManagerInterface.create();
  const TEAM_DIRECTORY_PAGE_SIZE = 15;
  const {
    COURSE_PROFILE_FILTER_TYPE,
  } = manager.constants;

  const instantiateTeams = function (teams) {
    return teams.map((team) => new TeamModel(team));
  };

  const isForGroups = function () {
    return manager.context === 'Groups';
  };

  manager.numMembersShownOnLargeDesktop = 5;
  manager.numMembersShownOnDesktop = 3;
  manager.numMembersShownOnTablet = 2;
  manager.teams = [];
  manager.allFilters = [];
  manager.dropdownFilters = ['all', 'joinable'];

  manager.fetchProfileQuestionFilters = function () {
    return TeamManager.getSearchFilters(manager.catalogId, isForGroups()).then((response) => {
      manager.allFilters = response.result.profileQuestions.map((courseQuestionFilter) => ({
        ...courseQuestionFilter,
        type: COURSE_PROFILE_FILTER_TYPE,
        identifier: `${COURSE_PROFILE_FILTER_TYPE}-${courseQuestionFilter.id}`,
      }));

      manager.teamSet = response.result;
      manager.setLocationData();
    });
  };

  manager.doSearch = function (shouldAppendResults, shouldCancelPrevRequests) {
    manager.loadingSearchResults = true;
    manager.hasExecutedSearchRequest = false;


    if (manager.previousSearchRequest && shouldCancelPrevRequests !== false) {
      manager.previousSearchRequest.$cancelRequest();
      manager.pageNumber = 1;
    }

    _.each(manager.selectedProfileFilters, (domain) => {
      _.each(domain, (question) => {
        question.searched = question.selected !== null;
      });
    });

    if (!shouldAppendResults) {
      manager.teams = [];
    }

    // manually swap the search input string into what we will be using for search
    manager.queryTerm = manager.queryTermDisplay;

    manager.locationFilter = manager.formatLocationFilterForSearch();

    const selectedFilters = manager.getSelectedFilters();

    manager.previousSearchRequest = TeamsResources.search({
      catalogId: manager.catalogId,
    }, {
      isGroup: (isForGroups()),
      page: manager.pageNumber,
      pageSize: TEAM_DIRECTORY_PAGE_SIZE,
      queryTerm: manager.queryTerm || null,
      answer: selectedFilters['course-profile'],
      location: manager.locationFilter,
      joinable: manager.dropdownFilter === 'joinable',
    },
    (searchResults) => {
      if (searchResults.result) {
        manager.loadingSearchResults = false;
        manager.hasExecutedSearchRequest = true;

        manager.teams = shouldAppendResults ? manager.teams.concat(instantiateTeams(searchResults.result.teams)) : instantiateTeams(searchResults.result.teams);
        manager.resultsCount = searchResults.result.count;

        // setting hasMoreToLoad will grab more data (in loading-state.jade) until backend sends no more
        manager.hasMoreToLoad = searchResults.result.teams.length && manager.teams.length !== manager.resultsCount;
      } else {
        manager.loadingSearchResults = false;
        manager.hasExecutedSearchRequest = true;
      }
    });
  };

  manager.displayNameForDropdownFilter = function (internalName) {
    if (internalName === 'joinable') {
      return isForGroups() ? 'GROUP_DIRECTORY.GROUPS_YOU_CAN_JOIN' : 'TEAM_DIRECTORY.FILTER_ACCEPTING_MEMBERS';
    }

    // default: all
    return isForGroups() ? 'GROUP_DIRECTORY.FILTER_ALL' : 'TEAM_DIRECTORY.FILTER_ALL';
  };

  manager.searchUrlForDropdownFilter = function (internalName) {
    if (isForGroups()) {
      return $state.href('group-directory', { catalogId: manager.catalogId, groupFilter: internalName });
    }
    return $state.href('team-directory', { catalogId: manager.catalogId, teamFilter: internalName });
  };

  manager.displayNameForSearchables = function () {
    return isForGroups() ? CurrentCourseManager.course.groupName.pluralizedTitleized : CurrentCourseManager.course.teamName.pluralizedTitleized;
  };

  manager.getPlaceholderKey = function () {
    return 'LEARNER_DIRECTORY.SEARCH_SEARCHABLES';
  };

  manager.getPlaceholderValues = function () {
    return { searchables: isForGroups() ? CurrentCourseManager.course.groupName.pluralizedTitleized : CurrentCourseManager.course.teamName.pluralizedTitleized };
  };

  manager.displayNameForNearbyFilterKey = function () {
    return 'TEAM_DIRECTORY.NEAR_YOU';
  };

  manager.displayNameForNearbyFilterValues = function () {
    return { teamsTerm: isForGroups() ? CurrentCourseManager.course.groupName.pluralizedTitleized : CurrentCourseManager.course.teamName.pluralizedTitleized };
  };

  manager.emptyResultSet = function () {
    return manager.teams.length === 0;
  };

  manager.hasResults = function () {
    return manager.teams.length;
  };

  manager.resetData = function () {
    manager.teams = [];
    manager.resetSharedData();
  };

  manager.hasProfileInfo = function (team) {
    return team && (!_.isEmpty(team.latestActivity) || !_.isEmpty(team.submissionsCount) || !_.isEmpty(team.textDescription) || !_.isEmpty(team.membersSnippet));
  };

  manager.noResultsIcon = function () {
    return 'icon-team';
  };

  return manager;
}
