import { RootState } from 'redux/schemas';
import { createSelector } from 'reselect';
import { indexBy, pluck } from 'underscore';

export const getCollections = (state: RootState) => {
  const courses = Object.values(state.models.courses);
  return courses.filter((course) => course.isContentManagementCollection);
};

export const getCollectionCatalogIds = (state: RootState): string[] => pluck(getCollections(state), 'catalogId');

export const getCollectionsHasFolders = (state: RootState) => {
  const collections = Object.values(getCollections(state));

  return collections.filter((collection) => collection.foldersCount > 0);
};

export const getCollectionFolder = (state: RootState, folderId: number) => state.models.collectionFolders?.[folderId];

export const getCollectionFolderLecturePages = createSelector(
  [
    (state: RootState, folderId: number) => state.models.collectionFolders?.[folderId].lecturePages,
    state => state.models.lecturePages,
  ],
  (folderLecturePages, lecturePages) => indexBy(folderLecturePages?.map(lecturePageId => lecturePages[lecturePageId]), 'id'),
);

export const getLinkedCourses = (state: RootState, lecturePageId: number) => {
  const lecturePage = state.models.lecturePages[lecturePageId];

  return lecturePage?.contentManagementLinks
    ? lecturePage?.contentManagementLinks.map((link) => ({
      courseId: link.course.id,
      courseName: link.course.name,
      courseCatalogId: link.course.catalogId,
      linkedLecturePageId: link.targetId,
    }))
    : [];
};

