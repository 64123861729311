import { css } from '@emotion/react';
import React from 'react';
import t from 'react-translate';
import { findKey } from 'underscore';

// Styles
import {
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import prodPathReplace from 'shared/prod-path-rewrite';

// Components
import NvPopover from 'shared/components/nv-popover';
import NvTooltip from 'shared/components/nv-tooltip';
import NvListWithMore from 'shared/components/nv-list-with-more';

const styles = css`
  img {
    width: ${standardSpacing}px;
    height: ${standardSpacing}px;
    cursor: pointer;
  }
`;

enum STATUS {
  NEVER_LOGGED_IN = 'neverLoggedInCourses',
  MISSED_DEADLINE = 'numDeadlineMissed',
  NOT_ACTIVE = 'inactivtyInCourses',
  RECENTLY_COMPLETED = 'recentlyCompletedInCourses',
}

const statusImages = {
  [STATUS.NEVER_LOGGED_IN]: 'never-logged',
  [STATUS.MISSED_DEADLINE]: 'missed-deadline',
  [STATUS.NOT_ACTIVE]: 'not-active',
  [STATUS.RECENTLY_COMPLETED]: 'recently-completed',
};

const MenteeRowNotes = ({ highlights }) => {
  const statusPopup = (status) => (
    <div>
      { status !== STATUS.MISSED_DEADLINE && highlights[status]?.length && (
        <div className='label'>
          <div className='text-gray-2'>
            {t.INSTITUTIONS.ORG_MENTORS.FILTER.NOTE_POPOVER_TEXT[findKey(STATUS, (item) => item === status)](highlights[status]?.length)}
          </div>
          <NvListWithMore
            items={highlights[status]}
            maxLength={5}
            itemComponent={(item) => (
              <div className='pt-2' key={item.catalogId}>
                <span className='text-black'>
                  {item.courseName}
                </span>
                <span className='text-gray-2'>
                  {` (${item.catalogId})`}
                </span>
              </div>
            )}
            more={(leftCount) => (
              <div className='pt-2 text-gray-2'>
                {t.INSTITUTIONS.ORG_MENTORS.POPOVER_MORE_ITEMS(leftCount)}
              </div>
            )}
          />
        </div>
      )}
      {status === STATUS.MISSED_DEADLINE && highlights[status] && (
        <div className='label'>
          <div className='text-gray-2'>
            {t.INSTITUTIONS.ORG_MENTORS.FILTER.NOTE_POPOVER_TEXT.MISSED_DEADLINE(highlights[status])}
          </div>
          <div className='text-black pt-2'>{t.INSTITUTIONS.LICENSES.CLICK_TO_VIEW_DETAILS()}</div>
        </div>
      )}
    </div>
  );

  return (
    <div className='d-flex' css={styles}>
      {Object.keys(statusImages).map((status) => {
        const popoverEnabled = (status !== STATUS.MISSED_DEADLINE) && highlights[status].length > 0;
        const missedDeadlinePopoverEnabled = (status === STATUS.MISSED_DEADLINE) && highlights[status];
        return (
          <NvPopover
            placement='top'
            showOnHover
            content={statusPopup(status)}
            key={statusImages[status]}
          >
            <img
              className={`${(popoverEnabled || missedDeadlinePopoverEnabled) ? 'mx-1' : 'd-none'}`}
              src={prodPathReplace(`images/${statusImages[status]}.png`)}
              alt={status}
            />
          </NvPopover>
        );
      })}
    </div>
  );
};

export default MenteeRowNotes;
