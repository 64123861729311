import { updateLectureComponentFromAngular } from '../../redux/actions/lecture-components';
import store, { cloneDeepSerializable } from '../../redux/store';

/* @ngInject */
export default function TeamSetModelService(

  TeamSetsResources,
  _,
  moment,
  AlertMessages,
  CurrentCourseManager,
) {
  const NOT_STARTED = 'not_started';
  const IN_PROGRESS = 'in_progress';
  const COMPLETED = 'completed';
  const MISSED = 'missed';
  const JOINED_A_TEAM = 'joined_a_team';
  const CREATED_A_TEAM = 'created_a_team';

  class TeamSetModel {
    constructor(attributes) {
      _.extend(this, attributes);
    }

    /* Status */
    getStatus() {
      return this.progress;
    }

    isReleased() {
      return moment() > this.__releaseMoment;
    }

    isNotStarted() {
      return this.getStatus() === NOT_STARTED;
    }

    isInProgress() {
      return this.getStatus() === IN_PROGRESS;
    }

    isCompleted() {
      return _.contains([COMPLETED, JOINED_A_TEAM, CREATED_A_TEAM], this.getStatus());
    }

    joinedATeam() {
      return this.getStatus() === JOINED_A_TEAM;
    }

    createdATeam() {
      return this.progress === CREATED_A_TEAM;
    }


    isMissed() {
      return this.progress === MISSED;
    }

    setLeaveTeamCallback(callback) {
      this.__leaveTeamCallback = callback;
    }

    leaveTeam(teamId) {
      this.teams = _.filter(this.teams, (team) => team.id !== teamId);

      if (!this.teams.length) {
        this.progress = NOT_STARTED;
        this.pointsReceived = 0;
      }

      store.dispatch(updateLectureComponentFromAngular({
        teamSet: this,
      }));

      this.__leaveTeamCallback?.();
    }

    updateTeam(team) {
      const ourTeam = _.findWhere(this.teams, { id: team.id });

      if (ourTeam) {
        _.extend(ourTeam, _.pick(team, ['name', 'numApprovedMembers', 'profilePicture']));
        ourTeam.membersSnippet = _.map(team.teamMembers, (member) => member.user);
      }

      store.dispatch(updateLectureComponentFromAngular({
        teamSet: this,
      }));
    }

    /* Team Formation */
    setCurrentCreatedTeam(createdTeam) {
      this.currentTeam = createdTeam;

      if (this.teams) {
        this.teams.push(createdTeam);
      } else {
        this.teams = [createdTeam];
      }

      this.progress = CREATED_A_TEAM;
    }

    setCurrentJoinedTeam(joinedTeam) {
      this.currentTeam = joinedTeam;

      if (this.teams) {
        this.teams.push(joinedTeam);
      } else {
        this.teams = [joinedTeam];
      }

      this.progress = JOINED_A_TEAM;
    }

    enableProgressDashboard(catalogId) {
      this.hasProgressDashboard = true;
      const { isTeam } = this;
      return TeamSetsResources.enableProgressDashboard({ catalogId, id: this.id }).$promise.then(() => {
        AlertMessages.success('FORM.SUCCESS_BANG', 'TEAM_FACILITATION.PROGRESS_DASHBOARD.PROGRESS_DASHBOARD_HAS_BEEN_ENABLED_ALL_TEAMS', {}, { ...CurrentCourseManager.course.getAliases(), isTeam });
      });
    }

    disableProgressDashboard(catalogId) {
      this.hasProgressDashboard = false;
      const { isTeam } = this;
      return TeamSetsResources.disableProgressDashboard({ catalogId, id: this.id }).$promise.then(() => {
        AlertMessages.success('FORM.SUCCESS_BANG', 'TEAM_FACILITATION.PROGRESS_DASHBOARD.PROGRESS_DASHBOARD_HAS_BEEN_DISABLED_ALL_TEAMS', {}, { ...CurrentCourseManager.course.getAliases(), isTeam });
      });
    }
  }

  return TeamSetModel;
}
