import { User } from 'redux/schemas/models/my-account';
import { createReducer } from '@reduxjs/toolkit';
import { doSignUp } from '../actions/sign-up';
import { initialRootState } from '.';
import { normalize } from 'normalizr';
import { userLoginSchema } from 'redux/schemas/api/sign-in';

type ActionType = { payload: User };

export default createReducer(initialRootState, builder => {
  builder
    .addCase(doSignUp.fulfilled, (state, action: any) => {
      const { payload }: ActionType = action;
      const user = normalize(action.payload, userLoginSchema);
      const currentUserId = payload.id;
      state.app.currentUserId = currentUserId;
      Object.assign(state.models.users, (state.models.users || {}), { ...user.entities.login });
    });
});
