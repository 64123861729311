/* @ngInject */
export default function InvitationsAndRequestsManager(

  _,
  $q,
  humps,

  $timeout,

  OfflineManager,
  PusherManager,
  PusherChannels,

  CurrentUserManager,
  CurrentCourseManager,
  InvitationAndRequestModel,
) {
  let pusherChannel; let pusherCallback; let
    acceptedCallbacks = [];

  const manager = {
    initialize,
    setCourse,

    requestInvitationsAndRequests,

    getInvitationsAndRequestsReceived,
    getTeamSpecificInvitations,
    getTeamSpecificMembershipRequestsMadeByUser,
    getGroupSpecificInvitations,
    getGroupSpecificMembershipRequestsMadeByUser,
    dataForCourseLoaded,

    setAcceptedCallback,
    acceptInvitationAndRequest,
    rejectInvitationAndRequest,
    cancelRequest,
    addRequest,
    removeRequest,

    // exposed data attributes
    numItems: 0,
    currentCourse: null,
    courses: [],
    coursesHash: {}, // stored based on course id
    isLoading: false,
    shouldReloadList: true,
  };

  $timeout(() => {
    manager.initialize();
  });

  function initialize() {
    if (CurrentUserManager.hasLoggedIn()) {
      requestInvitationsAndRequests();
      setupPusherEvents();
    }

    OfflineManager.registerReturnOnlineCallback(() => {
      if (CurrentUserManager.hasLoggedIn()) {
        requestInvitationsAndRequests(true);
      }
    });
  }

  function setCourse(course) {
    if (course) {
      manager.currentCourse = manager.coursesHash[course.id];
      removeApprovedInvitesAndRequests();
      return $q.when(manager.currentCourse?.requests);
    }
    manager.currentCourse = null;
    if (manager.shouldReloadList) {
      return requestInvitationsAndRequests()
        .then(() => manager.currentCourse?.requests);
    }

    return null;
  }

  function requestInvitationsAndRequests(hideLoading) {
    if (!hideLoading) {
      manager.isLoading = true;
    }
    manager.shouldReloadList = false;

    manager.invitesAndRequestPromise = InvitationAndRequestModel.getAll(CurrentUserManager.user.id)
      .then((courses) => {
        manager.courses = courses;
        _.each(courses, (course) => {
          manager.coursesHash[course.id] = course;
        });

        setInvitationsAndRequestsItemNumbers(true);

        if (manager.currentCourse) {
          manager.currentCourse = manager.coursesHash[manager.currentCourse.id];
        } else if (CurrentCourseManager.course) {
          manager.currentCourse = manager.coursesHash[CurrentCourseManager.course.id];
        }

        manager.isLoading = false;

        return manager.courses;
      });

    return manager.invitesAndRequestPromise;
  }

  function setAcceptedCallback(callback) {
    acceptedCallbacks.push(callback);

    return () => {
      acceptedCallbacks = _.without(acceptedCallbacks, callback);
    };
  }

  function acceptInvitationAndRequest(invitationAndRequest) {
    const deferred = $q.defer();

    $timeout(() => {
      invitationAndRequest.approved = true;
      manager.currentCourse.numItems -= 1;
      manager.numItems -= 1;

      // for team requests, we want to dump all other team requests since those are now invalid
      if (invitationAndRequest.isTeamSpecificInvitation()) {
        $timeout(() => {
          manager.currentCourse.requests = _.reject(manager.currentCourse.requests, (request) => {
            if (request !== invitationAndRequest) {
              return request.isTeamSpecificInvitation();
            }
            return false;
          });

          setInvitationsAndRequestsItemNumbers();
        });
      }
      return invitationAndRequest.accept().then(() => {
        _.each(acceptedCallbacks, (callback) => callback(invitationAndRequest, invitationAndRequest.isGroup));
        deferred.resolve(invitationAndRequest);
      });
    });

    return deferred.promise;
  }

  function rejectInvitationAndRequest(invitationAndRequest) {
    const index = _.indexOf(manager.currentCourse.requests, invitationAndRequest);
    // have to remove in next cycle because there are events still listen to the dom in this cycle
    $timeout(() => {
      manager.currentCourse.requests.splice(index, 1);
      manager.currentCourse.numItems -= 1;
      manager.numItems -= 1;
    });

    invitationAndRequest.reject()
      .then(
        (response) => {},
        (error) => {
          $timeout(() => {
            manager.currentCourse.requests.splice(index, 0, invitationAndRequest);
          });
        },
      );
  }

  function cancelRequest(request) {
    const index = _.indexOf(manager.currentCourse.requests, request);

    $timeout(() => {
      manager.currentCourse.requests.splice(index, 1);
    });

    request.cancel()
      .then(
        (response) => {},
        (error) => {
          $timeout(() => {
            manager.currentCourse.requests.splice(index, 0, request);
          });
        },
      );
  }

  // used for interactive behavior on site
  function addRequest(membershipRequest, team) {
    const invitationAndRequest = new InvitationAndRequestModel(_.extend(_.pick(membershipRequest, ['createdAt', 'id', 'message']), {
      className: 'MembershipRequest',
      courseId: CurrentCourseManager.course.id,
      catalogId: CurrentCourseManager.course.catalogId,
      isGroup: team.teamSet.isGroup,
      team: _.pick(team, ['description', 'id', 'name', 'profilePicture']),
      user: _.pick(CurrentUserManager.user, ['firstName', 'id', 'lastName', 'profilePicture']),
    }));

    const currentCourse = manager.coursesHash[CurrentCourseManager.course.id];
    if (currentCourse) {
      currentCourse?.requests.push(invitationAndRequest);
    }
  }

  function removeRequest(request) {
    _.each(manager.courses, (course) => {
      const index = _.findWhere(course.requests, { id: request.id });

      $timeout(() => {
        if (manager.currentCourse) {
          manager.currentCourse.requests.splice(index, 1);
        }
      });
    });
  }


  function getInvitationsAndRequestsReceived(course) {
    if (manager.dataForCourseLoaded(course)) {
      return _.reject(manager.coursesHash[course.id].requests, (request) => request.isMembershipRequestMadeByUser(CurrentUserManager.user.id));
    }
    return [];
  }

  function getTeamSpecificInvitations(course) {
    if (manager.dataForCourseLoaded(course)) {
      return _.reject(manager.coursesHash[course.id].requests, (request) => !request.isTeamSpecificInvitation(CurrentUserManager.user.id));
    }
    return [];
  }

  function getTeamSpecificMembershipRequestsMadeByUser(course) {
    if (manager.dataForCourseLoaded(course)) {
      return _.reject(manager.coursesHash[course.id].requests, (request) => !request.isTeamSpecificMembershipRequestMadeByUser(CurrentUserManager.user.id));
    }
    return [];
  }

  function getGroupSpecificInvitations(course) {
    if (manager.dataForCourseLoaded(course)) {
      return _.reject(manager.coursesHash[course.id].requests, (request) => !request.isGroupSpecificInvitation(CurrentUserManager.user.id));
    }
    return [];
  }

  function getGroupSpecificMembershipRequestsMadeByUser(course) {
    if (manager.dataForCourseLoaded(course)) {
      return _.reject(manager.coursesHash[course.id].requests, (request) => !request.isGroupSpecificMembershipRequestMadeByUser(CurrentUserManager.user.id));
    }
    return [];
  }

  function dataForCourseLoaded(course) {
    return !!manager.coursesHash[course.id] && !!manager.coursesHash[course.id].requests.length;
  }

  /** Private Functions * */
  function setupPusherEvents() {
    pusherChannel = PusherManager.setupChannel(PusherChannels.userChannel(CurrentUserManager.user.anonymizedIdentifier.substr(0, 10)));
    pusherChannel.bind('new_team_request_or_invitation', (pusherData) => {
      pusherData = humps.camelizeKeys(pusherData);

      const course = manager.coursesHash[pusherData.courseId];

      $timeout(() => {
        if (course) {
          manager.numItems += 1;
          if (course.numNewItems) {
            course.numNewItems += 1;
          } else {
            course.numNewItems = 1;
          }

          if (course.numItems) {
            course.numItems += 1;
          } else {
            course.numItems = 1;
          }
        }
        if (manager.currentCourse) {
          manager.shouldReloadList = true;
        } else {
          requestInvitationsAndRequests();
        }
      });
    });

    pusherChannel.bind('team_invitation_take_back', (pusherData) => {
      pusherData = humps.camelizeKeys(pusherData);

      const course = manager.coursesHash[pusherData.courseId];

      $timeout(() => {
        if (course) {
          manager.numItems -= 1;
          if (course.numNewItems) {
            course.numNewItems -= 1;
          }

          if (course.numItems) {
            course.numItems -= 1;
          }
        }
        if (manager.currentCourse) {
          manager.shouldReloadList = true;
        } else {
          requestInvitationsAndRequests();
        }
      });
    });
  }

  function unsubscribePusherEvents() {
    pusherChannel.unbind('new_team_request_or_invitation', pusherCallback);
    pusherChannel.unbind('team_invitation_take_back', pusherCallback);
  }

  function setInvitationsAndRequestsItemNumbers(ignoreApproved) {
    manager.numItems = 0;
    _.each(manager.courses, (course) => {
      if (ignoreApproved) {
        course.numItems = manager.getInvitationsAndRequestsReceived(course).length;
      } else {
        course.numItems = _.reduce(manager.getInvitationsAndRequestsReceived(course), (memo, request) => {
          if (!request.approved) {
            return memo + 1;
          }
          return memo;
        }, 0);
      }
      manager.numItems += course.numItems;
    });
  }

  function removeApprovedInvitesAndRequests() {
    if (manager.currentCourse) {
      manager.currentCourse.requests = _.reject(manager.currentCourse.requests, (request) => request.approved || request.error);
    }
  }

  return manager;
}
