import { isEmpty } from 'lodash';
import { ExternalToolType, LectureComponent } from 'redux/schemas/models/lecture-component';

type ValidatorFunction = (component: LectureComponent<any>) => void;

// Object that maps lecture component types to their respective validator functions
const validators: Record<string, ValidatorFunction> = {
  [ExternalToolType.SCORM]: (component) => {
    if (isEmpty((component as LectureComponent<ExternalToolType>).externalTool)) {
      throw new Error(`Required property 'externalTool' is undefined for component type: ${component.trueType}`);
    }
  },
  // Add other validators as needed
};

// Helper function to validate a lecture component based on its type
export const validateLectureComponent = (component: LectureComponent): void => {
  const validator = validators[component.trueType];

  if (validator) {
    validator(component);
  }
};

export default validateLectureComponent;
