import { css } from '@emotion/react';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AngularServicesContext } from 'react-app';
import t from 'react-translate';

import { cloneDeepSerializable, useAppDispatch } from 'redux/store';
import { RootState } from 'redux/schemas';
import { Submission } from 'redux/schemas/models/submissions';
import { User } from 'redux/schemas/models/my-account';
import { Team } from 'redux/schemas/models/team';
import { RatingActivityExercise } from 'redux/schemas/models/exercise-skills-rating';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { setSubmission } from 'redux/actions/submissions';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { getCourseAliases } from 'redux/selectors/course';
import { getNextNotRatedSubmission, getSkillTagsFromTaggings } from 'redux/selectors/skills-feedback';
import { useTimelineService } from 'timelines/services/react-timeline-service';

import { gray4 } from 'styles/global_defaults/colors';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { allDesktop, handheld, notDesktop } from 'styles/global_defaults/media-queries';
import SkillFeedback from 'shared/components/skill-feedback/skill-feedback';
import { Tabs } from './skills-rating-tabs';

const SkillsRatingForm = () => {
  const styles = css`
    .tag-badge {
      background: white !important;
      border: solid 1px ${gray4} !important;
      border-radius: ${standardSpacing}px !important;
    }

    ${allDesktop(css`
      .nv-froala-origami {
        margin-left: 0px !important;
      }
    `)}

    ${notDesktop(css`
      .froala-novoed-menu {
        margin-bottom: 0px !important;
      }
    `)}

    ${handheld(css`
      .button-bar {
        flex-direction: column-reverse;

        .bs4-btn-primary.skills-feedback-submit-btn {
          margin-top: ${standardSpacing}px;
        }
      }
    `)}

  `;

  const { $state, $uibModal, $scope, $timeout } = useContext(AngularServicesContext);
  const { reportId, catalogId, isRatingFeedback } = $state?.params;
  const timelineService = useTimelineService();

  const submission: Submission = useSelector((state: RootState) => state.models.submissions?.[reportId]);
  const aliases = useSelector((state: RootState) => getCourseAliases(state));
  const exercise = submission?.exercise as RatingActivityExercise;
  const skillTags = useSelector((state: RootState) => getSkillTagsFromTaggings(state, exercise?.skillTaggingIds ?? []));
  const nextNonRatedSubmission: Submission = useSelector((state: RootState) => getNextNotRatedSubmission(state, reportId));
  const { lecturePageId, lectureComponentId, pointsConfiguration } = exercise?.skillsRatingFeedback ?? {};

  const dispatch = useAppDispatch();

  // Get the submission from angular to push to redux
  useEffect(() => {
    if ($scope.ReportsManager?.currentReport) {
      const { currentReport } = $scope.ReportsManager;
      currentReport.submitting = currentReport.submittingObject;
      dispatch(setSubmission(cloneDeepSerializable(currentReport)));
    }
  }, [dispatch, $scope.ReportsManager?.currentReport]);

  const displayPointsModal = (values) => {
    const {
      totalPoints, pointsReceived, leaderboardPoints,
      leaderboardRank, priorLeaderboardRank,
    } = values;

    if (!totalPoints) return false;

    $uibModal.open({
      templateUrl: 'shared/templates/points-modal.html',
      windowClass: 'points-modal',
      controller: 'PointsModalCtrl as vm',
      resolve: {
        pointsReceived: pointsConfiguration?.points,
        leaderboardPoints,
        leaderboardRank,
        priorLeaderboardRank,
        extras: null,
      },
    });

    return true;
  };

  const onSubmit = (data) => {
    if (!data) return;

    if (data.error) return;

    const {
      numCompleted, pointsReceived,
      leaderboardPoints, leaderboardRank, priorLeaderboardRank,
    } = data.payload?.review ?? {};
    const { requiredToComplete, totalPoints } = data.payload?.skillsRating ?? {};
    const pendingCount = requiredToComplete - numCompleted;

    if (numCompleted === requiredToComplete) {
      dispatch(addAlertMessage({
        type: AlertMessageType.SUCCESS,
        header: t.FORM.SUCCESS_BANG(),
        message: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.SUCCESS.COMPLETED(
          requiredToComplete,
        ),
      }));
    } else if (numCompleted < requiredToComplete && pendingCount) {
      dispatch(addAlertMessage({
        type: AlertMessageType.SUCCESS,
        header: t.FORM.SUCCESS_BANG(),
        message: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.SUCCESS.NOT_COMPLETED(
          pendingCount,
        ),
      }));
    } else {
      dispatch(addAlertMessage({
        type: AlertMessageType.SUCCESS,
        header: t.FORM.SUCCESS_BANG(),
        message: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.SUCCESS.BEYOND(),
      }));
    }

    if ($scope.ReportsManager?.currentReport) {
      $scope.ReportsManager.currentReport.isRatedByCurrentUser = true;
    }

    if (lecturePageId) {
      timelineService.updateTimeline(lecturePageId);
    }

    if (isRatingFeedback) {
      // From feedback gallery. Redirect accordingly and show points modal
      if (pendingCount > 0) {
        // Has more
        if (nextNonRatedSubmission) {
          $state.go('individual-submission-modal', {
            catalogId,
            reportId: nextNonRatedSubmission.id,
            galleryMode: true,
            isRatingFeedback: true,
          }).then(() => {
            $timeout(() => { $scope.toggleRatingForm(true); });
            // Display points modal
            displayPointsModal({
              totalPoints,
              pointsReceived,
              leaderboardPoints,
              leaderboardRank,
              priorLeaderboardRank,
            });
          });
        } else if (lecturePageId) {
          // Didn't have a next submission, got back to lecture page
          $state.go('lecture-page', {
            catalogId,
            id: lecturePageId,
            lectureActivityId: lectureComponentId,
          }).then(() => {
            // Display points modal
            displayPointsModal({
              totalPoints,
              pointsReceived,
              leaderboardPoints,
              leaderboardRank,
              priorLeaderboardRank,
            });
          });
        } else {
          // Didn't have next submission and lecture page, stay here
          $state.go('individual-submission-modal', {
            catalogId,
            reportId,
            selected: Tabs.SKILLS_FEEDBACK,
          }).then(() => {
            // Display points modal
            displayPointsModal({
              totalPoints,
              pointsReceived,
              leaderboardPoints,
              leaderboardRank,
              priorLeaderboardRank,
            });
          });
        }
      } else if (pendingCount === 0 && lecturePageId) {
        // No more submissions, go to lecture page
        $state.go('lecture-page', {
          catalogId,
          id: lecturePageId,
          lectureActivityId: lectureComponentId,
        }).then(() => {
          // Display points modal
          displayPointsModal({
            totalPoints,
            pointsReceived,
            leaderboardPoints,
            leaderboardRank,
            priorLeaderboardRank,
          });
        });
      }
    } else {
      // From submision gallery.
      // Go to same submission with your skill feedback tab selected
      // and then show points only if the it is less than or
      // equal to the required
      $state.go('individual-submission-modal', {
        catalogId,
        reportId,
        selected: Tabs.SKILLS_FEEDBACK,
      }).then(() => {
        if (pendingCount >= 0) {
          // Display points modal
          displayPointsModal({
            totalPoints,
            pointsReceived,
            leaderboardPoints,
            leaderboardRank,
            priorLeaderboardRank,
          });
        }
      });
    }
  };

  return (
    <div css={styles} className='submission-rating d-flex'>
      <div className='submission-rating-item d-flex flex-column align-items-center skills-container p-2 w-100'>
        <a
          className='text-primary mb-4'
          href={$state.href('lecture-page', {
            catalogId,
            id: lecturePageId,
            lectureActivityId: lectureComponentId,
          })}
        >
          {t.EVALUATIONS.EVALUATIONS_FOOTER.VIEW_INSTRUCTION()}
        </a>
        {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.RATING_DESCRIPTION(
          {
            ...aliases.learnersAliases,
            learnerName: (submission?.owner as Team)?.name
              ?? (submission?.owner as User)?.firstName,
          },
        )}
        {skillTags?.length > 0 && (
          <div className='w-100'>
            <SkillFeedback
              skillTags={skillTags}
              onSubmit={(data) => onSubmit(data)}
              ownerId={reportId}
              ownerType='Report'
              feedbackCriteriaId={exercise?.skillsRatingFeedback.id}
              catalogId={catalogId}
              showForm={() => $timeout(() => { $scope.toggleRatingForm(false); })}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SkillsRatingForm;
