import { every, last, each } from 'underscore';
import axios from 'axios';

import t from 'react-translate';

import { S3NameSpaces } from 'shared/services/s3-upload-factory';
import { NovoEdFile } from 'shared/hooks/use-upload-file';


import {
  FroalaViewMode, RteEmbedding, ToolbarOptions,
  DEFAULT_LANGUAGE, HTML_ALLOWED_TAGS, HTML_ALLOWED_ATTRS, DEFAULT_TOOLBAR_BUTTONS,
  defaultEmbeddingWidth, defaultEmbeddingHeight, PARAGRAPH_STYLES, DEFAULT_PARAGRAPH_STYLE, FileLikeBlob, UploadableFileList,
  RelatedActivity,
} from './nv-froala-constants';

import { config } from '../../../config/config.json';
import { SanitizationLevel } from './sanitizer';

/**
 * Creates a NovoEd Embedding based on the uniqueId of a file in S3
 * @param novoedFile container for basic file attributes + uniqueId
 * @param embeddingWidth
 * @param embeddingHeight
 */
/* @ngInject */
export function createEmbeddingFromS3FileId(novoedFile: NovoEdFile, embeddingWidth: number, embeddingHeight: number): Promise<RteEmbedding> {
  return axios.post('/embeddings/create', {
    name: novoedFile.name,
    size: novoedFile.size,
    type: novoedFile.type,
    uniqueId: novoedFile.uniqueId,
    mediaWidth: embeddingWidth,
    mediaHeight: embeddingHeight,
  }).then(response => response.data);
}

/**
 * Creates a NovoEd Embedding(iframe) based on a URL
 * @param url url to be iframed
 * @param embeddingWidth
 * @param embeddingHeight
 */
/* @ngInject */
export function createEmbeddingFromUrl(url: string, embeddingWidth: number, embeddingHeight: number): Promise<RteEmbedding> {
  return axios.post('/embeddings/create_from_url', {
    mediaUrl: url,
    defaultWidth: embeddingWidth,
    defaultHeight: embeddingHeight,
  }).then(response => response.data);
}

/**
 * validates a list of files, returning the first error in string format
 * @param files browser File[]
 * @param filePatterns array of file supported extensions and mime types
 */
/* @ngInject */
export function validateFiles(files: FileList | File[], filePatterns: string[]): string | null {
  let error;

  every(files, (file) => {
    // if there is no file extension, we can't reliably reject files, so we just let it through
    if (file.name.includes('.')) {
      const extension = last(file.name.split('.')) as string;

      if (!filePatterns.includes(`.${extension.toLowerCase()}`) && !filePatterns.includes(file.type)) {
        error = t.FILE_UPLOAD.NOT_SUPPORTED();
        return false;
      }
    }

    return true;
  });

  if (!error) {
    every(files, (file) => {
      if (file.size > config.files.rteFileSizeLimit * 1024 * 1024) {
        error = t.FILE_UPLOAD.SIZE_EXCEEDED();
        return false;
      }

      return true;
    });
  }

  return error;
}

/**
 * Inserts the embedding into the passed Froala instance
 * @param rteEmbedding details regarding embedding
 * @param froalaInstanceRef ref to Froala instance, will insert into this Froala instance
 */
/* @ngInject */
export function insertEmbeddingHtml(
  {
    src,
    isImage = false,
    hasDirective = false,
    width,
    height,
    fixedHeight = false,
  }: RteEmbedding,
  froalaInstanceRef,
  isNovoEdEmbedding,
): void {
  let embedHtml;
  if (isImage) {
    embedHtml = `<img src="${src}" alt="${src}"></img>&#8203;`;
  } else if (hasDirective) { // for CDF Embedding || zaption(zaption.com)
    embedHtml = src.replace('\\"', '"');
  } else if (src) {
    let appendString = '';

    if (isNovoEdEmbedding) {
      const hasExistingQuery = src.includes('?');
      appendString = `${hasExistingQuery ? '&' : '?'}width=${width}&height=${height}${fixedHeight ? '&fixed_height=true' : ''}`;
    }

    embedHtml = `
      <iframe
        src="${src}${appendString}"
        width='${width}'
        height='${height}'
        frameborder='0'
        allowfullscreen='true'>
      </iframe>`.trim();
  }
  froalaInstanceRef.current.selection.restore();

  if (embedHtml) {
    froalaInstanceRef.current.html.insert(embedHtml);
    froalaInstanceRef.current.undo.saveStep(); // save current HTML into the undo stack
  }
}

/**
 * Iterates through top level p tags at dom level to ensure each have a froala paragraph style attached
 */
/* @ngInject */
export function updateParagraphStyle(froalaInstanceRef) {
  const topLevelParagraphs = froalaInstanceRef.current.$el.find('>p');

  each(topLevelParagraphs, (p) => {
    const $p = $(p);
    const hasParagraphStyle = PARAGRAPH_STYLES.some((pClass) => $p.hasClass(pClass));

    if (!hasParagraphStyle) {
      $p.addClass(DEFAULT_PARAGRAPH_STYLE);
    }
  });
}

/* @ngInject */
export function getTopElement(froalaInstance) {
  let { anchorNode } = froalaInstance.selection.get();

  // cannot use parentElement for ie11
  while (anchorNode?.parentNode && anchorNode.parentNode !== froalaInstance.el) {
    anchorNode = anchorNode.parentNode;
  }

  if (anchorNode?.parentNode) {
    return anchorNode;
  }

  return null;
}


/**
 * Adds translations for string added by NovoEd to button titles
 * @param currentLang Language we are adding strings to, this must match the current language in react-translate
 */
/* @ngInject */
export function addMissingStrings(currentLang: string): void {
  if (currentLang !== DEFAULT_LANGUAGE) {
    ($ as any).FE.LANGUAGE[currentLang].translation['Font Style'] = t.FROALA.FONT.HEADER();
    ($ as any).FE.LANGUAGE[currentLang].translation['Text Color'] = t.FROALA.COLOR.TEXT();
    ($ as any).FE.LANGUAGE[currentLang].translation['Highlight Color'] = t.FROALA.COLOR.HIGHLIGHT();
    ($ as any).FE.LANGUAGE[currentLang].translation['Change Text Alignment'] = t.FROALA.TEXT_ALIGNMENT();
  }
}

/* @ngInject */
export function getParagraphStylesWithTranslations() {
  const PARAGRAPH_STYLES_WITH_TRANSLATIONS = {
    'froala-style-title': t.FROALA.FONT.TITLE(),
    'froala-style-subtitle': t.FROALA.FONT.SUBTITLE(),
    'froala-style-title-small': t.FROALA.FONT.TITLE_SMALL(),
    'froala-style-big': t.FROALA.FONT.BIG(),
    'froala-style-medium': t.FROALA.FONT.MEDIUM(),
    'froala-style-regular': t.FROALA.FONT.REGULAR(),
    'froala-style-small': t.FROALA.FONT.SMALL(),
  };
  return PARAGRAPH_STYLES_WITH_TRANSLATIONS;
}

/**
 * Creates the configuration to pass into Froala
 * @param preset mode to initialize Froala in
 * @param minHeight minimum might of editable area
 * @param currentLang Froala display language
 * @param placeholder text placeholder in Froala
 */
/* @ngInject */
export function getFroalaBaseConfig(
  preset: FroalaViewMode,
  sanitizationLevel: SanitizationLevel,
  minHeight: number,
  currentLang: string,
  placeholder: string,
  scrollableContainer: string,
  uploadFiles: (files: UploadableFileList, nameSpace: string) => Promise<NovoEdFile[]>,
  immediateReactModelUpdate: boolean,
  editorClass: string,
  toolbarButtons: ToolbarOptions[] = DEFAULT_TOOLBAR_BUTTONS,
  keepFormatOnDelete: boolean,
  allowToolbar?: boolean,
  pastePlain?: boolean,
): Object {
  // Define all configuration for air mode/shared
  let froalaConfig = {
    /* General */
    direction: document.dir, // force Froala to follow our platform language direction because auto does not work well. direction if set to auto is based on first character and the browser doesn't let us know the determined direction
    language: currentLang,
    editorClass: `nv-froala-editor air ${editorClass}`,
    placeholderText: placeholder || t.FROALA.TEXT_PLACEHOLDER(),

    heightMin: minHeight,
    htmlAllowedTags: HTML_ALLOWED_TAGS,
    htmlAllowedAttrs: HTML_ALLOWED_ATTRS,

    /* Pasting */
    pasteAllowedStyleProps: ['font-weight', 'text-decoration', 'font-style'],
    pasteDeniedAttrs: [],
    wordPasteModal: false,
    pastePlain,
    // pastePlain works except when Froala skips sanitization when the text was copied from another Froala editor in this browser
    // turning it off it easier and to avoid unnecessary processing since we do it manually in an 'paste.afterCleanup'
    // pastePlain: true
    imagePaste: false,

    /* Toolbar */
    toolbarButtons: [],

    // Keep format of the selected text when it is deleted.
    keepFormatOnDelete,

    /* Plugin: Paragraph */
    paragraphStyles: getParagraphStylesWithTranslations(),
    paragraphMultipleStyles: false,

    /* Plugin: Link */
    linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
    linkInsertButtons: ['linkBack'],
    linkConvertEmailAddress: true,
    linkAlwaysBlank: true,

    /* Plugin: Image */
    imageEditButtons: [],

    scrollableContainer,
    immediateReactModelUpdate,
  } as any;

  const toolbarConfig = {
    toolbarInline: true,
    toolbarButtons,
    toolbarButtonsSM: toolbarButtons,
    toolbarButtonsXS: toolbarButtons,
  };

  if (preset !== FroalaViewMode.AIR) {
    froalaConfig = {
      ...froalaConfig,
      /* General */
      editorClass: `nv-froala-editor ${editorClass}`,
      placeholderText: placeholder || t.FROALA.TEXT_AND_FILE_PLACEHOLDER(),

      ...toolbarConfig,

      /* Plugin: Image */
      imageUploadURL: 'https://novoed.com/upload', // filler to bypass image upload checks

      imagePaste: sanitizationLevel === SanitizationLevel.NORMAL,
      customUpload: async (imageBlob: FileLikeBlob, successCallback): Promise<void> => {
        imageBlob.name = imageBlob.name || `${(new Date()).getTime()}.jpg`;

        const [novoEdFile] = await uploadFiles([imageBlob], S3NameSpaces.ATTACHMENTS);

        createEmbeddingFromS3FileId(novoEdFile, defaultEmbeddingWidth, defaultEmbeddingHeight)
          .then(rteEmbedding => successCallback({ link: rteEmbedding.src }));
      },
    };
  }

  if (allowToolbar) {
    return {
      ...froalaConfig,
      ...toolbarConfig,
    };
  }

  return froalaConfig;
}

/**
 * Select element contents
 * @param { HTMLElement } el - The element to be selected
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Range/selectNodeContents}
 */
export const selectElementContents = (el) => {
  setTimeout(() => {
    const range = document.createRange();
    range.selectNodeContents(el);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  });
};

/**
 * Sets the cursor of a given element node at index
 * @param { DOM Node } node - start node
 * @param { number } index - offset
 */
export const setCursor = (node, index) => {
  const range = document.createRange();
  const sel = window.getSelection();
  range.setStart(node, index);
  range.collapse(true);
  sel.removeAllRanges();
  sel.addRange(range);
};

export const placeCaretAtEnd = (el) => {
  el.focus();
  if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(el);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
  }
};

/**
 * A hacky fix to fix the scroll-to-top issue on editor paste event in Chrome.
 * Lecture page has a scrollable div.main-panel-scrollable which has a
 * translate3d(0) style added as a fix to fix a safari issue. Chrome has
 * a bug, in which an element with a transform property style will act as a
 * containing block for the fixed positioned descendants.
 *
 * On pasting, Froala uses a position-fixed-content-editable and this will be
 * created on first paste. If the scrollContainer prop for Froala is
 * provided with a valid scrollable div, then the top of the
 * position-fixed-content-editable will be calculated correctly. If its not
 * provided NvFroala uses the containerId itself, which calculates the top as 0.
 * So on pasting it will try to focus to this position-fixed-content-editable.
 * If its top:0, it will scroll to top. If its a calculated top value,
 * it will work partially but will fail if we paste long contents again again.
 *
 * So this method will be called on paste.before on RTE to remove the transform style
 * from the main-panel-scrollable div and on paste.after will be called to enable
 * the style again
 *
 * @param { boolean } enable - Enable or Disable
 * @see {@link https://novoed.atlassian.net/browse/NOV-67265}
 * @see {@link https://github.com/froala/wysiwyg-editor/issues/3265#event-2157493390}
 */
export const toggleTransformFix = (enable = true) => {
  const mainPanelScrollableElement = document.querySelector<HTMLElement>('.lecture-page .main-panel-scrollable');
  if (mainPanelScrollableElement) {
    if (enable) {
      mainPanelScrollableElement.style.webkitTransform = 'translate3d(0,0,0)';
    } else {
      mainPanelScrollableElement.style.webkitTransform = 'none';
    }
  }
};
