import { gray4, success, warning, primary } from 'styles/global_defaults/colors';

export default {
  bindings: {
    course: '<',
    userCourse: '<?',
    showSoa: '<', // Show Statement of Accomplishment
    showSoaReminder: '<', // Show Statement of Accomplishment reminder
    credlyBadge: '<', // Show Credly Badge link
    completionStatus: '<',
    userId: '<',
    completionCriteria: '<',
    selectedTab: '<',
    selectTab: '&',
    isCompleted: '<',
    criteriaCount: '<',
  },
  controller: function ctrl(
    $scope,
    $state,
    nvCurrentPage,
    config,
  ) {
'ngInject';
    const vm = this;

    vm.config = config;
    vm.nvCurrentPage = nvCurrentPage;
    vm.displayRadialGraph = vm.criteriaCount !== 'single';

    // TODO: May need to use novoed colors
    vm.defaultColor = gray4;
    vm.pointColor = success;
    vm.assignmentColor = warning;
    vm.todoColor = primary;
    vm.toggleTab = toggleTab;
    vm.COMPLETIONSTATUS = {
      AUTO: 1,
      MANUAL: 2,
    };

    function setRequirementsCompleted() {
      // TODO: duplicated from timeline-show-controller
      vm.requirementsCompleted = vm.criteriaCount && vm.isCompleted
      && vm.isCompleted.points && vm.isCompleted.assignments && vm.isCompleted.todos;
    }

    function toggleTab(tab) {
      vm.selectTab()(vm.selectedTab === tab ? 'Outline' : tab);
    }

    // This is duplicate with the same named function in both
    // nv-statement-and-certificate-card.js and timeline-show-controller.js
    // TODO: Refactor all three of these into one function
    vm.getStatementUrl = (catalogId, userId) => $state.href('statement-modal', { catalogId, userId });

    vm.showCredlyBadge = vm.completionStatus && !!vm.credlyBadge;
    vm.selectCase = 'both';
    vm.credlyLink = '';
    vm.soaLink = '';

    if (vm.showCredlyBadge) {
      vm.selectCase = 'credly';
      vm.credlyLink = vm.credlyBadge;
    }

    if (vm.showSoa) {
      vm.selectCase = vm.showCredlyBadge ? 'both' : 'soa';
      vm.soaLink = vm.getStatementUrl(vm.course.catalogId, vm.userId);
    }

    setRequirementsCompleted();

    return vm;
  },
  controllerAs: 'vm',
  templateUrl: 'timelines/templates/nv-completion-criteria.html',
};

/*
// nvCurrentPage
// manager.selectedTab
//   // Set in timeline-show-controller and points-breakdown-modal-controller?!
// selectTab()
// requirementsCompleted
// getStatementUrl
// isCompleted
// CurrentCourseManager.course.getCompletionCriteriaCount()
*/

/* ^^ in
points-breakdown-modal
  manager: TimelinesManager
course-home-timeline (TimelineShowCtrl)
  manager: TimelinesManager
*/
