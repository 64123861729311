/* @ngInject */
export default function oeMention(
  CurrentUserManager,
  $stateParams,
  $state,
) {
  return {
    restrict: 'A',
    scope: {
      user: '@',
      currentUser: '=',
    },
    controller: ['$scope', function ($scope) {
      $scope.mentioned = JSON.parse($scope.user);
      $scope.isCurrentUser = ($scope.mentioned.id === CurrentUserManager.user.id);

      $scope.goToProfile = function () {
        if ($scope.mentioned.id) {
          if ($stateParams.catalogId) {
            $state.go('learner-profile-modal', { catalogId: $stateParams.catalogId, userId: $scope.mentioned.id });
          } else {
            $state.go('org-level-profile-modal', { userId: $scope.mentioned.id });
          }
        }
      };
    }],
    replace: true,
    templateUrl: 'shared/templates/oe-mention.html',
  };
}
