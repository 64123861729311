import { css } from '@emotion/react';
import { useContext, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { isEmpty, values } from 'underscore';
import { useFormContext } from 'react-hook-form';
import t from 'react-translate';

// Helpers
import withoutCurrentActivity from 'communications/course_communications/helpers/without-current-activity';

// Schemas
import { CourseActivities } from 'redux/schemas/api/course-communications';
import { ActivityKey, MinimalActivity } from 'redux/schemas/models/activity';
import { CommunicationCategory, FeedbackCategory, HasLoggedInFilterType } from 'redux/schemas/models/course-communication';

// Selectors
import { getCourseActivities, getCourseAliases } from 'redux/selectors/course';
import { getLabelForActivity, getKeyForActivityType, getActivityTypeForKey } from 'redux/selectors/activity';

// Contexts
import { CommunicationDispatch } from 'communications/course_communications/contexts/communication-context';

// Components
import NvFormDropdown from 'shared/components/inputs/nv-form-dropdown';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import NvRadioButton from 'shared/components/inputs/nv-radio-button';
import { NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';

// Styles
import { info, gray2, gray4, gray7, gray6 } from 'styles/global_defaults/colors';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { textLargeFontSize } from 'styles/global_defaults/fonts';


type ActivityProps = {
  name: string,
  eventValue?: string,
  dependentName?: string,
  label: string,
};

const styles = (name) => css`
  &.${name} {
    background-color: ${gray7};
    border-radius: 10px;

    .selected-values {
      display: inline-block;
      margin-left: ${halfSpacing}px;
    }

    .dropdowns {
      visibility: hidden;
      display: flex;
      height: 0;

      .activity-type {
        flex: 2;
      }

      .activity {
        flex: 5;
      }

      .activity, .activity-type {
        .title {
          font-size: ${textLargeFontSize}px;
        }
      }
    }

    .info {
      flex: 1 0 auto;
      text-align: right;
      color: ${gray2};
      vertical-align: middle;
      line-height: 24px;
    }

    &.on {
      background-color: ${info};

      .dropdowns {
        visibility: visible;
        height: auto;
        margin-bottom: ${halfSpacing}px;
      }
    }

    &.disabled {
      background-color: ${gray6};
      display: flex;

      .info {
        color: ${gray4};
        display: inherit;
        justify-content: flex-end;
        align-items: center;
      }

      .dropdowns {
        display: none;
      }
    }
  }
`;

const getFieldNames = (name: string) => ({
  checked: `${name}ActivityChecked`,
  activity: `${name}Activity`,
  type: `${name}Type`,
  category: `${name}Category`,
});

type CourseActivityType = NvDropdownTextItem & {
  category?: string,
};

/**
 * A generic component for activity filters/events.
 */

const Activity = ({
  name,
  eventValue,
  dependentName,
  label,
}: ActivityProps) => {
  const { State: communicationState } = useContext(CommunicationDispatch);

  const courseActivities: CourseActivities = useSelector((state) => getCourseActivities(state));
  const { setValue, watch } = useFormContext();
  const fieldNames = getFieldNames(name);
  const [activityType, activity] = watch([fieldNames.type, fieldNames.activity]);
  const activitiesWithoutCurrent = useMemo(
    () => withoutCurrentActivity(courseActivities, communicationState.activityId, communicationState.activityType, communicationState?.category),
    [courseActivities, communicationState.activityId, communicationState.activityType, communicationState?.category],
  );
  // The current activity need to be omitted only in the case for triggers
  const courseActivitiesList = communicationState.communicationCategory === CommunicationCategory.TRIGGERS
    ? activitiesWithoutCurrent : courseActivities;

  const aliases = useSelector((state) => getCourseAliases(state));
  const fieldValue = eventValue ? watch('event') : watch(fieldNames.checked);
  const isEnabled = eventValue ? fieldValue === eventValue : fieldValue;

  // Disabled this filter if has not logged in since course release date or start date selected
  const [hasLoggedInChecked, hasLoggedIn] = watch(['hasLoggedInChecked', 'hasLoggedIn']);
  const isFilterDisabled: boolean = (hasLoggedInChecked
    && (hasLoggedIn?.value === HasLoggedInFilterType.HAS_NOT_SINCE_START_DATE
      || hasLoggedIn?.value === HasLoggedInFilterType.HAS_NOT_SINCE_RELEASE_DATE));

  const dependentFieldNames = getFieldNames(dependentName);
  const dependentValues = watch(values(dependentFieldNames));
  const [dependentChecked, dependentActivity, dependentType] = dependentValues;
  const isDisabled = useCallback((value) => {
    // If this value and the dependent fields value is same, then
    // disable this
    if (dependentChecked && activityType?.id === dependentType?.id) {
      return dependentActivity?.id === value;
    }
    return false;
  }, [activityType, dependentChecked, dependentType, dependentActivity]);

  const noActivities = isEmpty(courseActivitiesList);

  const types: CourseActivityType[] = Object.keys(courseActivitiesList).map((key: ActivityKey) => {
    const type = getActivityTypeForKey(key);
    const category = key === ActivityKey.VIDEO_PRACTICE_SKILLS_FEEDBACK
      ? FeedbackCategory.VIDEO_PRACTICE_SKILLS_FEEDBACK
      : null;
    const id = category ?? type;

    return {
      id,
      type: 'text',
      text: getLabelForActivity(key, aliases),
      value: type,
      callback: () => {
        setValue(fieldNames.activity, null, { shouldValidate: true, shouldDirty: true });
        setValue(fieldNames.category, id, { shouldValidate: true, shouldDirty: true });
      },
    };
  });

  /** Build list of activities
   *
   * Activity is disabled if already selected in not-completed-activity dropdown
   * If not disabled, clicking on item will reset activity dropdown in not-completed-activity
   * if same activity selected
   */
  const activityTypeValue = activityType?.id === FeedbackCategory.VIDEO_PRACTICE_SKILLS_FEEDBACK
    ? activityType?.value
    : activityType?.id;
  const keyForActivityType = getKeyForActivityType(activityTypeValue, activityType?.id);
  const activitiesList: MinimalActivity[] = courseActivitiesList[keyForActivityType] ?? [];
  const activities: NvDropdownTextItem[] = activitiesList.map((item) => ({
    id: item.id,
    type: 'text',
    text: item.title,
    disabled: isDisabled(item.id),
    value: item.id,
    callback: () => {
      if (item.id === dependentActivity?.id) {
        setValue(dependentFieldNames.activity, null, { shouldValidate: true, shouldDirty: true });
      }
    },
  }));

  useEffect(() => {
    // If the Owner Activity is same as this value, reset this value
    if (communicationState.communicationCategory === CommunicationCategory.TRIGGERS
      && activityType?.id === communicationState.activityType
      && activity?.id === communicationState.activityId) {
      setValue(fieldNames.activity, null, { shouldValidate: true, shouldDirty: true });
      setValue(fieldNames.type, null, { shouldValidate: true, shouldDirty: true });
    }
  }, [communicationState.communicationCategory, communicationState.activityType, communicationState.activityId,
    activity, activityType, fieldNames.activity, fieldNames.type, setValue]);

  return (
    <div css={styles(name)} className={`px-4 py-2 mb-4 ${name} ${isEnabled ? 'on' : ''} ${isFilterDisabled ? 'disabled' : ''}`}>
      <div className='inline-block mr-2 checkbox-wrapper'>
        {eventValue ? (
          <NvRadioButton
            withForm
            value='completed-activity'
            name='event'
            label={label}
            labelClassName='text-large-regular pl-6 mr-2'
          />
        ) : (
          <NvCheckbox
            withForm
            name={fieldNames.checked}
            label={label}
            labelClassName='text-large-regular pl-6 mr-2'
            disabled={isFilterDisabled}
          />
        )}
      </div>
      {noActivities && (
        <div className='info text-small text-gray-2 font-weight-bold'>
          {t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.NO_ACTIVITIES()}
        </div>
      )}
      <div className='dropdowns ml-6'>
        <NvFormDropdown
          name={fieldNames.type}
          className='activity-type'
          items={types}
          title={t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.SELECT_TYPE()}
        />
        <NvFormDropdown
          maxWidth100
          name={fieldNames.activity}
          className='activity ml-2'
          items={activities}
          title={t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.SELECT_ACTIVITY()}
          disabled={activityType == null}
        />
      </div>
    </div>
  );
};

Activity.defaultProps = {
  eventValue: null,
  dependentName: '',
};

export default Activity;
