import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

// schemas
import { ItemState } from 'redux/schemas/models/course-communication';

// contexts
import { RootState } from 'redux/schemas';

// selectors
import { getItemState } from 'redux/selectors/course-communications';

// Styles
import { ItemFlavor, itemFlavors } from 'communications/course_communications/components/communication-item/communication-item';

const styles = (itemState: ItemState) => {
  const flavor: ItemFlavor = itemState && itemFlavors[itemState];

  return css`
    padding: 0 3px;
    text-transform: uppercase;
    color: white;
    float: right;

    ${flavor?.badgeBg === 'none' && 'visibility: hidden'};
    ${flavor?.badgeBg !== 'none' && `background-color: ${flavor?.badgeBg}`};
  `;
};

const CommunicationBadge = (props: {communicationId: number}) => {
  const itemState = useSelector<RootState, ItemState>((state) => getItemState(state, props.communicationId));
  return (
    <div css={styles(itemState)} className='badge-wrapper text-small bold'>
      {itemState}
    </div>
  );
};


export default CommunicationBadge;
