// TODO: Looks like this is very similar to nv-multiselect-dropdown. We should merge the two
export default {
  bindings: {
    selectedItems: '<',
    defaultText: '@',
    onSelectedChanged: '&?',
  },
  controllerAs: 'vm',
  controller: function ctrl($scope, $state, _, RolesService) {
'ngInject';
    const vm = this;
    vm.items = _.filter(RolesService.permissions, (p) => p.visible);
    vm.model = _.map(vm.items, (i) => false);
    vm.selectedCount = 0;

    vm.model.forEach((val, i) => {
      if (_.contains(vm.selectedItems, vm.items[i])) {
        vm.model[i] = true;
        vm.selectedCount += 1;
      }
    });

    vm.itemClicked = (permission, i) => {
      vm.model[i] = !vm.model[i];
      if (!vm.model[i]) {
        vm.selectedCount -= 1;
      } else {
        vm.selectedCount += 1;
      }

      if (vm.onSelectedChanged) {
        vm.onSelectedChanged()(_.filter(vm.items, (item, j) => vm.model[j]));
      }
    };

    return vm;
  },
  templateUrl: 'shared/templates/nv-checkbox-dropdown.html',
};
