import { useRef, useEffect } from 'react';

// get state (or props) from previous renders
// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
/* @ngInject */
export default function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
