/* @ngInject */
export default function NvUrlField() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link(scope, element, attrs, ngModel) {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line no-useless-escape
      const URL_REGEX = new RegExp(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/);
      // var EMAIL_REGEX = new RegExp(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i);

      ngModel.$validators.validUrl = function (modelValue, viewValue) {
        let isValid = false;

        if (ngModel.$isEmpty(modelValue)) {
          isValid = false;
        } else if (URL_REGEX.test(viewValue)) {
          isValid = true;
        } else {
          isValid = false;
        }

        return isValid;
      };
    },
  };
}
