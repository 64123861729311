/* eslint-disable jsx-a11y/mouse-events-have-key-events */

import { css, SerializedStyles } from '@emotion/react';
import React from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import { getParagraphStylesWithTranslations } from 'froala/helpers/nv-froala-functions';
import {
  DEFAULT_LANGUAGE,
  HTML_ALLOWED_ATTRS,
  HTML_ALLOWED_TAGS,
  LANGUAGE_MAPPING,
  ToolbarOptions,
} from 'froala/helpers/nv-froala-constants';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/schemas';
import { MyAccount } from 'redux/schemas/models/my-account';
import { getCurrentUser } from 'redux/selectors/users';
import { gray4 } from 'styles/global_defaults/colors';
import uuid from 'react-uuid';
import { SanitizationLevel, sanitize } from 'froala/helpers/sanitizer';

const CUSTOM_TOOLBAR_BUTTONS: ToolbarOptions[] = [
  ToolbarOptions.COLOR,
  ToolbarOptions.BOLD,
  ToolbarOptions.ITALIC,
  ToolbarOptions.UNDERLINE,
  ToolbarOptions.PARAGRAPH_STYLE,
];

/**
 * Example
 * for: <p><strong><br /></strong><em>text</em></p>
 * it returns the <br /> element.
 */
function getFirstLeafElement(paragraph: HTMLElement): HTMLElement {
  let deepFirstChild = paragraph;
  let currentElement = paragraph.children;
  while (currentElement.length) {
    deepFirstChild = currentElement[0] as HTMLElement;
    currentElement = deepFirstChild.children;
  }

  return deepFirstChild;
}

type ReactDivProps = React.ComponentProps<'div'>;

export interface InlineRichTextEditorProps {
  value: string;
  onModelChange?(value: string): void;
  onBlur?(e: any): any;
  onFocus?(e: any): any;
  placeholder?: string;
  placeholderColor?: string;

  // required to support tooltips
  onMouseOut?: ReactDivProps['onMouseOut'];
  onMouseOver?: ReactDivProps['onMouseOver'];

  sanitizationLevel?: SanitizationLevel;

  autoFocus?: boolean;
  containerStyle?: SerializedStyles;
  scrollableContainer?: string;
  keepFormatOnDelete?: boolean;
}

export interface InlineRichTextEditorImplerativeInterface {
  focus(): void;
  saveSelection(): void;
  restoreSelection(): void;
  getHTML(): string;
}

const NvFroalaInline = React.forwardRef<InlineRichTextEditorImplerativeInterface, InlineRichTextEditorProps>(
  (props, ref) => {
    const { onBlur, onFocus } = props;
    const froalaInstanceRef = React.useRef<any>();
    const [containerId] = React.useState(() => uuid());

    // we assign the callback props to a ref because those events don't go
    // through the useEffect flow to register/cleanup and the callback props
    // changes frequently. The ref guarantees that we always call the most
    // up-to-date prop
    const froalaOnblurRef = React.useRef<typeof onBlur>(null);
    React.useEffect(() => {
      froalaOnblurRef.current = onBlur;
    }, [onBlur]);

    const froalaOnFocusRef = React.useRef<typeof onFocus>(null);
    React.useEffect(() => {
      froalaOnFocusRef.current = onFocus;
    }, [onFocus]);

    React.useEffect(() => {
      // update placeholder font style
      //
    }, []);

    // imperative component API
    React.useImperativeHandle(ref, () => ({
      focus: () => {
        froalaInstanceRef.current.events.focus();
      },
      saveSelection: () => {
        froalaInstanceRef.current.selection.save();
      },
      restoreSelection: () => {
        froalaInstanceRef.current.selection.restore();
      },
      getHTML: () => froalaInstanceRef.current.html.get(),
    }));

    function getFirstParagraph(): HTMLElement {
      const firstParagraph = froalaInstanceRef.current.$el.find('>p:not(.fr-marker):first-of-type');
      return firstParagraph[0];
    }

    function updatePlaceholderFont() {
      const firstLeaftElement = getFirstLeafElement(getFirstParagraph());
      const computedStyle = getComputedStyle(firstLeaftElement);

      // `font-family` and `font-weight` are the only two properties that the
      // froala Placeholder doesn't match with the first paragraph style
      const currentFontFamily = computedStyle.getPropertyValue('font-family');
      const currentFontWeight = computedStyle.getPropertyValue('font-weight');

      const [placeholderElement] = froalaInstanceRef.current.$placeholder as HTMLElement[];
      placeholderElement.style.fontFamily = currentFontFamily;
      placeholderElement.style.fontWeight = currentFontWeight;
    }

    /**
     * for the inline editor that only allows a single paragraph, iterates over
     * the direct children and removes them except for the first paragraph found
     * to fix a Froala bug tat appends a extra paragraph when the user clicks at
     * the button of the editor
     */
    function sanitizeFroalaInlineHtml() {
      const [container] = froalaInstanceRef.current.$el as HTMLElement[];
      let firstParagraphFound = false;
      for (let i = 0; i < container.children.length; i += 1) {
        if (!firstParagraphFound && container.children[i].tagName === 'P') {
          firstParagraphFound = true;
        } else {
          container.children[i].remove();
        }
      }
    }

    function handleKeyDown() {
      updatePlaceholderFont();
    }

    const currentUser = useSelector<RootState, MyAccount>(getCurrentUser);
    const currentLang = LANGUAGE_MAPPING[currentUser?.platformLanguage] || DEFAULT_LANGUAGE;

    // assigning the prop to the ref to make it safe to omit from the dependency list
    const autoFocusRef = React.useRef(props.autoFocus);
    // Froala has a autoFocus option but it showed some problem on Safari where
    // the cursor appeared in the wrong please (the problem was very obvious in
    // the accordion header component) so we use this effect to programmatically
    // focus the editor
    React.useEffect(() => {
      if (autoFocusRef.current) {
        froalaInstanceRef.current.events.focus();
      }
    }, []);

    return (
      <div
        className='position-relative'
        css={css`
          ${props.containerStyle};
          .fr-placeholder {
            color: ${props.placeholderColor || gray4};
          }
        `}
        onMouseOut={props.onMouseOut}
        onMouseOver={props.onMouseOver}
        id={containerId}
      >
        <FroalaEditor
          tag='textarea'
          model={props.value}
          config={{
            immediateReactModelUpdate: true,
            htmlAllowedTags: HTML_ALLOWED_TAGS,
            htmlAllowedAttrs: HTML_ALLOWED_ATTRS,
            wordPasteModal: false,
            imagePaste: false,
            pastePlain: true,
            placeholderText: props.placeholder,
            language: currentLang,
            toolbarInline: true,
            pasteDeniedAttrs: [],
            multiLine: false,
            paragraphStyles: getParagraphStylesWithTranslations(),
            paragraphMultipleStyles: false,
            keepFormatOnDelete: props.keepFormatOnDelete || false,
            toolbarButtons: CUSTOM_TOOLBAR_BUTTONS,
            toolbarButtonsSM: CUSTOM_TOOLBAR_BUTTONS,
            toolbarButtonsXS: CUSTOM_TOOLBAR_BUTTONS,
            scrollableContainer: props.scrollableContainer || `#${containerId}`,
            events: {
              'froalaEditor.initialized': (_e, editor) => {
                froalaInstanceRef.current = editor;

                // force the placeholder appear at least once
                editor.placeholder.show();
                editor.placeholder.refresh();

                // update placeholder style
                updatePlaceholderFont();

                /**
                 * To prevent further backspacing after deleting all text
                 * because on further backspace, the style of the text will be lost.
                 * */
                editor.events.on('keydown', (keypressEvent) => {
                  if (editor.html.get() === '' && (keypressEvent.which === 8 || keypressEvent.which === 46)) {
                    keypressEvent.preventDefault();
                    return false;
                  }
                  return true;
                }, true);
              },
              'froalaEditor.blur': (event) => {
                froalaOnblurRef.current?.(event);
              },
              'froalaEditor.focus': (event) => {
                froalaOnFocusRef.current?.(event);
              },
              'froalaEditor.keydown': () => {
                handleKeyDown();
              },
              'froalaEditor.click': () => {
                sanitizeFroalaInlineHtml();
              },
              'froalaEditor.paste.afterCleanup': (event, editor, clipboardHtml) => {
                const result = sanitize(clipboardHtml, props.sanitizationLevel || SanitizationLevel.SIMPLE) || '';
                return result;
              },
            },
          }}
          onModelChange={props.onModelChange}
        />
      </div>
    );
  },
);

export default NvFroalaInline;
