import { setActiveOptionMenu } from 'redux/actions/optionsMenu';
import { createReducer } from '@reduxjs/toolkit';
import { initialRootState } from '.';


export default createReducer(initialRootState, builder => {
  builder
    .addCase(setActiveOptionMenu, (state, action) => {
      state.app.optionsMenu = { menuItem: action.payload.menuItem };
      if (action.payload.catalogId) {
        state.app.currentCatalogId = action.payload.catalogId;
      }
    });
});
