/* eslint-disable react/require-default-props */

import React from 'react';
import merge from 'lodash/merge';
import { css } from '@emotion/react';
import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
import { DeepPartial } from 'utility-types';
import { FieldError, Controller } from 'react-hook-form';


import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import NvIcon from 'shared/components/nv-icon';
import NvPopover from 'shared/components/nv-popover';
import NvTooltip from 'shared/components/nv-tooltip';
import { InputType } from 'components/nv-click-to-edit';
import useUploadFile from 'shared/hooks/use-upload-file';
import NvSelect from 'shared/components/inputs/nv-select';
import NvFilePicker from 'shared/components/nv-filepicker';
import FileUploadingBar from 'components/file-uploading-bar';
import { isRtl } from 'styles/global_defaults/media-queries';
import { RootState, CombinedInstitution } from 'redux/schemas';
import ColorPickerPopover from 'components/color-picker-popover';
import IconLibraryModal from 'shared/components/nv-icon-library';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import { S3NameSpaces } from 'shared/services/s3-upload-factory';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { CourseRegistrationType } from 'redux/schemas/models/course';
import { FormOrgProfileField } from 'redux/schemas/models/account-fields';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import ValidationErrorMessage from 'shared/components/inputs/validation-error-message';
import {
  gray2,
  gray3,
  gray5,
  gray6,
  gray7,
} from 'styles/global_defaults/colors';
import CollapsibleRowMenu, {
  CollapsibleRowMenuButton,
  CollapsibleRowMenuDivider,
} from 'components/collapsible-row-menu';
import {
  halfSpacing,
  doubleSpacing,
  tripleSpacing,
  standardSpacing,
  extraLargeSpacing,
} from 'styles/global_defaults/scaffolding';
import NvDropdown, {
  NvDropdownOption,
  NvDropdownButtonStyle,
} from 'shared/components/inputs/nv-dropdown';

/**
 * 2mb as max file size (Represented in bytes).
 */
const MAX_FILE_SIZE = 2097152;

const getImageData = (image: File) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();

  reader.onload = () => {
    resolve(reader.result as string);
  };

  reader.onerror = () => {
    reject(reader.error);
  };

  reader.readAsDataURL(image);
});

type Props = {
  field: FormOrgProfileField,
  onChange: (field: FormOrgProfileField) => void,
  isNew?: boolean,
  className?: string,
  hideIcon?: boolean,
  canMoveUp?: boolean,
  isEditable?: boolean,
  placeholder?: string,
  canMoveDown?: boolean,
  onDelete?: () => void,
  onMoveUp?: () => void,
  nameError?: FieldError,
  onMoveDown?: () => void,
  selectOptionsError?: FieldError,
  popoverContainer?: React.RefObject<HTMLElement>,
};

/**
 * This component represents an Org Level Profile field, and this component is
 * in charge to modify all the properties that these fields can have. Example:
 * - Icon & icon color or a picture
 * - Field text
 * - Field type (If this is equal to "dropdown" we can modify "Select options")
 * - Hidden status
 * - Order
 * It doesn't have internal state since the only use of this component is to
 * be a controlled component, that's why we should ALWAYS use this component
 * providing "field" and "onChange" props
 */
const FieldModifier = (props: Props) => {
  const {
    field,
    onChange,
    className,
    nameError,
    placeholder,
    isNew = false,
    canMoveUp = true,
    hideIcon = false,
    popoverContainer,
    isEditable = true,
    canMoveDown = true,
    selectOptionsError,
    onDelete = () => { },
    onMoveUp = () => { },
    onMoveDown = () => { },
  } = props;

  const [imageData, setImageData] = React.useState('');
  const pictureToShow = imageData || field.picture?.cdnUrl;
  const [showIconLibrary, setShowIconLibrary] = React.useState(false);
  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const { uploadFiles, isUploading, filesUploading } = useUploadFile();
  const hasIlustrationSelected = (field.viewOptions.icon || pictureToShow);
  const [isNameInputTouched, setIsNameInputTouched] = React.useState(false);
  const [selectedInvalidPicture, setSelectedInvalidPicture] = React.useState(
    false,
  );

  const dispatch = useAppDispatch();

  const currentInstitution = useSelector<RootState, CombinedInstitution>(getCurrentInstitution);

  const [
    isSelectOptionsInputTouched,
    setIsSelectOptionsInputTouched,
  ] = React.useState(false);

  const handleChange = (patch: DeepPartial<FormOrgProfileField>) => onChange(
    merge(cloneDeep(field), patch),
  );

  const getFieldIconStyles = () => {
    let pictureStyles: any = '';

    if (pictureToShow && !isUploading) {
      pictureStyles = css`
        background-image: url("${pictureToShow}");
      `;
    }

    const withoutIconColor = field.isHidden ? gray5 : gray3;
    const color = field.viewOptions.icon ? field.viewOptions.iconColor : withoutIconColor;

    const withoutIlustrationColor = field.isHidden ? gray7 : gray6;
    const editableColor = hasIlustrationSelected ? gray7 : withoutIlustrationColor;
    const backgroundColor = isEditable ? editableColor : 'white';

    return css`
      color: ${color};
      background-size: cover;
      background-color: ${backgroundColor};
      ${pictureStyles};
    `;
  };

  const getNameStyles = () => {
    if (field.isHidden) {
      return css`
        color: ${gray3};
        font-style: italic;
      `;
    }

    return '';
  };

  const getInputTypeStyles = () => {
    if (field.isIntegrated) {
      return css`
        color: ${gray3};
        font-style: italic;
      `;
    }

    return '';
  };

  const styles = css`
    .field-modifier {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      box-sizing: content-box;
      height: ${tripleSpacing}px;
    }

    .field-text {
      flex: 1;
      padding: ${halfSpacing}px;
      background-color: ${gray7};
      min-height: ${doubleSpacing}px;
      ${getNameStyles()};
    }

    .left-container {
      flex: 2;
      display: flex;
      padding-top: ${halfSpacing}px;
      padding-right: ${halfSpacing}px;
      padding-bottom: ${halfSpacing}px;

      .field-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${doubleSpacing}px;
        height: ${doubleSpacing}px;
        margin-right: ${halfSpacing}px;
        color: ${field.viewOptions.iconColor ?? gray3};
        ${getFieldIconStyles()};
      }

      .field-text-input-container {
        flex: 1;

        .field-text-input::placeholder {
          color: black;
        }
      }
      .bs4-dropdown-menu {
        inset: unset;
      }
    }

    .right-container {
      flex: 1;
      display: flex;

      .input-type-container {
        flex: 1;
        padding-top: ${halfSpacing}px;
        padding-bottom: ${halfSpacing}px;

        .bs4-dropdown > .title {
          height: 38px;
        }

        .input-type {
          height: 100%;
          display: flex;
          align-items: center;
          ${getInputTypeStyles()};
        }
      }

      .menu-button {
        margin-left: ${standardSpacing}px;
      }
    }

    .select-options-container {
      margin-bottom: ${halfSpacing}px;
      padding-left: ${extraLargeSpacing}px;
      padding-right: ${extraLargeSpacing}px;
    }
  `;

  const DROPDOWN_VALUE = t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.DROPDOWN();
  const SHORT_TEXT_VALUE = t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.SHORT_TEXT();
  const LONG_TEXT_VALUE = t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.LONG_TEXT();
  const LOCATION_TEXT_VALUE = t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.LOCATION();

  const inputTypeSelectOptions = [
    { label: DROPDOWN_VALUE, value: InputType.DROPDOWN },
    { label: SHORT_TEXT_VALUE, value: InputType.SHORT_TEXT },
    { label: LONG_TEXT_VALUE, value: InputType.LONG_TEXT },
  ];

  const inputTypeLabelsMap = {
    [InputType.DROPDOWN]: DROPDOWN_VALUE,
    [InputType.LONG_TEXT]: LONG_TEXT_VALUE,
    [InputType.SHORT_TEXT]: SHORT_TEXT_VALUE,
    [InputType.LOCATION]: LOCATION_TEXT_VALUE,
  };

  const handleFieldIconClick = () => setSelectedInvalidPicture(false);

  const handleSelectError = () => setSelectedInvalidPicture(true);

  const handleFilePickerChange = async (files: File[]) => {
    const [chosenImage] = files;

    const data = await getImageData(chosenImage);
    setImageData(data);

    const [novoEdFile] = await uploadFiles(files, S3NameSpaces.INSTITUTIONS);
    const {
      name,
      size,
      type,
      uniqueId,
    } = novoEdFile;

    handleChange({
      viewOptions: {
        icon: null,
        iconColor: null,
      },
      picture: {
        uniqueId,
        fileName: name,
        fileSize: size,
        contentType: type,
      },
    });
  };

  const uploadItem = (
    <NvFilePicker
      multiple={false}
      maxSize={MAX_FILE_SIZE}
      onChange={handleFilePickerChange}
      onSelectError={handleSelectError}
      accept={['image/png', 'image/jpeg']}
    >
      <div className='bs4-dropdown-item'>
        {t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.UPLOAD()}
      </div>
    </NvFilePicker>
  );

  const handleIconSelect = () => setShowIconLibrary(true);
  const handleIconColorSelect = () => setShowColorPicker(true);
  const handleRemove = () => {
    handleChange({
      picture: null,
      viewOptions: {
        icon: null,
        iconColor: null,
      },
    });

    setImageData('');
  };

  const iconDropdownBaseItems: NvDropdownOption[] = [
    { type: 'custom', customItem: uploadItem },
    {
      type: 'text',
      callback: handleIconSelect,
      text: t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.PICK_FROM_LIBRARY(),
    },
  ];

  const iconDropdownWithoutIlustrationItems = field.viewOptions.icon
    ? iconDropdownBaseItems.concat([
      {
        type: 'text',
        callback: handleIconColorSelect,
        text: t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.CHANGE_ICON_COLOR(),
      },
    ])
    : iconDropdownBaseItems;

  const iconDropdownItems: NvDropdownOption[] = hasIlustrationSelected
    ? iconDropdownWithoutIlustrationItems.concat([
      { type: 'divider' },
      {
        type: 'text',
        class: 'text-danger',
        callback: handleRemove,
        text: t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.REMOVE(),
      },
    ])
    : iconDropdownWithoutIlustrationItems;

  const handleIconLibraryClose = () => setShowIconLibrary(false);

  const handleColorPickerToggle = () => setShowColorPicker(false);

  const handleIconLibraryIconClick = (iconName: string) => handleChange({
    picture: null,
    viewOptions: {
      icon: iconName,
      iconColor: field.viewOptions.iconColor ?? gray2,
    },
  });

  const handleNameInputChange = (event) => handleChange({
    name: event.target.value,
  });

  const handleInputTypeChange = (inputType: InputType) => {
    handleChange({
      inputType,
      selectOptions: '',
    });

    setIsSelectOptionsInputTouched(false);
  };

  const handleVisibilityToggle = () => handleChange({
    isHidden: !field.isHidden,
  });

  const hasEntitlementsCourseRegistrationType = () => (
    currentInstitution?.courseEnrollmentTypes.includes(
      CourseRegistrationType.OPEN_BASED_ON_ENTITLEMENTS?.toString(),
    ));

  const handleCsvMangeChange = () => handleChange({
    isCsvManaged: !field.isCsvManaged,
    isHidden: false,
  });

  const onCsvMangeToggle = () => {
    if (field.isCsvManaged) {
      dispatch(openConfirmationDialog({
        title: t.INSTITUTIONS.DASHBOARD.DELETION.ARE_YOU_SURE(),
        bodyText: t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.CSV_MANAGE_WARNING(
          hasEntitlementsCourseRegistrationType()?.toString(),
        ),
        onConfirm: handleCsvMangeChange,
      }));
    } else {
      handleCsvMangeChange();
    }
  };

  const handleSelectOptionsChange = (event) => handleChange({
    selectOptions: event.target.value,
  });

  const renderFieldText = (text: string) => (
    <NvTooltip text={t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.NOT_EDITABLE()}>
      <div className='field-text text-medium'>
        {text}
      </div>
    </NvTooltip>
  );

  const renderVisibilityToggleButton = () => (
    <CollapsibleRowMenuButton
      onClick={handleVisibilityToggle}
      icon={field.isHidden ? 'hide' : 'view'}
      disabled={field.isIntegrated && !field.migratedField && !field.name}
      tooltipText={
        field.isHidden
          ? t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.SHOW()
          : t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.HIDE()
      }
    />
  );

  /**
   * If field isn't a migrated one it means that the input for the field text is
   * required, since only for migrated ones there are default values.
   */
  const isNameRequired = !field.migratedField;

  const popoversPlacement = isRtl() ? 'top-end' : 'top-start';

  return (
    <div css={styles} className={className}>
      <div className='field-modifier'>
        <div className='left-container'>
          {!hideIcon && (
            <React.Fragment>
              <NvPopover
                preventOverflow={false}
                placement={popoversPlacement}
                show={selectedInvalidPicture}
                content={(
                  <ValidationErrorMessage
                    text={t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.INVALID_FILE()}
                  />
                )}
              >
                <ColorPickerPopover<{
                  iconColor: string;
                }>
                  show={showColorPicker}
                  container={popoverContainer}
                  placement={popoversPlacement}
                  onToggle={handleColorPickerToggle}
                  sections={[{
                    name: 'iconColor',
                    title: t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.ICON_COLOR(),
                  }]}
                  colorValues={{
                    iconColor: field.viewOptions.iconColor,
                  }}
                  onChange={(sectionName, newColor) => {
                    handleChange({
                      viewOptions: {
                        iconColor: newColor,
                      },
                    });
                  }}
                >
                  <NvDropdown
                    items={iconDropdownItems}
                    buttonStyle={NvDropdownButtonStyle.CUSTOM}
                    disabled={field.isHidden || !isEditable || isUploading}
                    customTarget={() => (
                      <div
                        className='field-icon'
                        onClick={handleFieldIconClick}
                      >
                        {isUploading ? (
                          <FileUploadingBar filesUploading={filesUploading} />
                        ) : (
                          !field.picture && (
                            <NvTooltip
                              enabled={!isEditable}
                              text={
                                t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.NOT_EDITABLE()
                              }
                            >
                              <div>
                                <NvIcon
                                  size='smallest'
                                  icon={field.viewOptions.icon ?? 'upload'}
                                />
                              </div>
                            </NvTooltip>
                          )
                        )}
                      </div>
                    )}
                  />
                </ColorPickerPopover>
              </NvPopover>
              <IconLibraryModal
                show={showIconLibrary}
                onClose={handleIconLibraryClose}
                onIconClick={handleIconLibraryIconClick}
              />
            </React.Fragment>
          )}
          {!field.isHidden && isEditable ? (
            <NvTextInput
              value={field.name}
              placeholder={placeholder}
              required={isNameRequired}
              onChange={handleNameInputChange}
              inputClassName='field-text-input'
              className='field-text-input-container'
              onBlur={() => setIsNameInputTouched(true)}
              error={isNameInputTouched ? nameError : undefined}
            />
          ) : renderFieldText(
            `${field.name || placeholder
            }${field.isHidden ? ` ${t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.HIDDEN()}` : ''}`,
          )}
        </div>
        <div className='right-container'>
          <div className='input-type-container'>
            {isNew ? (
              <NvSelect
                value={field.inputType}
                options={inputTypeSelectOptions}
                onChange={handleInputTypeChange}
                placeholder={t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.FORM_TYPE_TITLE()}
              />
            ) : (
              <React.Fragment>
                <div className='input-type text-small text-gray-2 font-weight-bold'>
                  {
                    (field.isIntegrated || field.isCsvManaged)
                      ? (
                        <React.Fragment>
                          {t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.NOT_EDITABLE()}
                          <NvTooltip
                            text={field.isIntegrated
                              ? t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.MANAGED_VIA_SSO(field.name)
                              : t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.MANAGED_VIA_CSV()}
                            placement='top'
                          >
                            <div>
                              <NvIcon
                                size='smallest'
                                icon='info'
                                className='text-primary ml-1'
                              />
                            </div>
                          </NvTooltip>
                        </React.Fragment>
                      ) : inputTypeLabelsMap[field.inputType]
                  }
                </div>
              </React.Fragment>
            )}
          </div>
          <CollapsibleRowMenu className='menu-button'>
            {isEditable ? (
              <React.Fragment>
                <CollapsibleRowMenuButton icon='trash' onClick={onDelete} />
                <CollapsibleRowMenuDivider />
                <CollapsibleRowMenuButton
                  icon='move-up'
                  onClick={onMoveUp}
                  disabled={!canMoveUp}
                />
                <CollapsibleRowMenuButton
                  icon='move-down'
                  onClick={onMoveDown}
                  disabled={!canMoveDown}
                />
                <CollapsibleRowMenuDivider />
                {(field.isIntegrated || field.isCsvManaged) && (
                  renderVisibilityToggleButton()
                )}
                {!field.isIntegrated && (
                  field.isDisabled ? (
                    <NvTooltip
                      text={t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.CSV_MANAGED_AND_DISABLED()}
                    >
                      <div className='pl-2 pr-1'>
                        <NvIcon size='smallest' icon='editable' />
                      </div>
                    </NvTooltip>
                  ) : (
                    <CollapsibleRowMenuButton
                      onClick={onCsvMangeToggle}
                      icon={field.isCsvManaged ? 'editable' : 'not-editable'}
                      tooltipText={t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.CSV_MANAGED(field.isCsvManaged?.toString())}
                    />
                  )
                )}
              </React.Fragment>
            ) : (
              renderVisibilityToggleButton()
            )}
          </CollapsibleRowMenu>
        </div>
      </div>
      {field.inputType === InputType.DROPDOWN && (
        <div className='select-options-container'>
          {isNew && !field.isHidden ? (
            <NvTextInput
              required
              value={field.selectOptions}
              onChange={handleSelectOptionsChange}
              onBlur={() => setIsSelectOptionsInputTouched(true)}
              error={isSelectOptionsInputTouched ? selectOptionsError : undefined}
              placeholder={t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.SELECT_OPTIONS_PLACEHOLDER()}
            />
          ) : renderFieldText(field.selectOptions)}
        </div>
      )}
    </div>
  );
};

type FormProps = {
  name: string,
  control: any,
};

export const FormFieldModifier = (props: Omit<Props, 'field' | 'onChange'> & FormProps) => {
  const {
    name,
    control,
    ...restProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={(data) => {
        const { value, onChange } = data.field;

        return (
          <FieldModifier {...restProps} field={value} onChange={onChange} />
        );
      }}
    />
  );
};

export default FieldModifier;
