import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { VideoPracticeSubmission } from 'redux/schemas/models/video-practice';
import { Result } from 'redux/schemas/api';
import { InfiniteLoadingParams } from 'redux/schemas/api/shared';
import { BasicMentee } from 'redux/schemas/models/course';

interface PendingSubmissions {
  response: VideoPracticeSubmission[];
  totalCount: number;
  hasScenarioId: boolean;
}

interface SupervisorCourses {
  response: BasicMentee[];
  totalCount: number;
}

export const fetchPendingSubmissions = createAsyncThunk<PendingSubmissions, InfiniteLoadingParams>(
  'GET_PENDING_SUBMISSIONS_LIST',
  async (params: InfiniteLoadingParams) => {
    const response = await axios.post<
    Result<{ submissions: VideoPracticeSubmission[]; totalCount: number }>
    >('/video_practice_submissions/mentees_submissions.json', null, {
      params: {
        ...params.filters,
        page: params.page || 1,
        page_size: params.page_size,
      },
    });
    return {
      response: response.data.result.submissions,
      totalCount: response.data.result.totalCount,
      hasScenarioId: !!params.filters?.scenario_id,
    };
  },
);

export const fetchSupervisorCourses = createAsyncThunk<
SupervisorCourses,
InfiniteLoadingParams
>('GET_SUPERVISOR_COURSE_LIST', async (params: InfiniteLoadingParams) => {
  const response = await axios.get<Result<BasicMentee[]>>(
    '/users/mentees_info.json',
    {
      params,
    },
  );
  return {
    response: response.data.result,
    totalCount: response.data.result.length,
  };
});

export const fetchScenariosPendingReview = createAsyncThunk(
  'GET_SCENARIOS_PENDING_REVIEW_LIST',
  async () => {
    const response = await axios.get('/scenarios/mentor_reviews.json?page=1&page_size=100');
    return response.data.result;
  },
);
