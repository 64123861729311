/* @ngInject */
export default function orderEmptyLast(

  moment,
  _,
) {
  return function (inputObjects, field) {
    const notEmptyObjects = [];
    const emptyObjects = [];

    _.each(inputObjects, (object) => {
      if (object[field] && object[field].length > 0) {
        notEmptyObjects.push(object);
      } else {
        emptyObjects.push(object);
      }
    });

    return notEmptyObjects.concat(emptyObjects);
  };
}
