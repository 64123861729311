export default {
  bindings: {
    tabLabelKey: '=',
    count: '<',
    tooltipTranslationKey: '@?',
    internalName: '@',
    isSelected: '<',
  },
  require: {
    nvDashboardFilterTabList: '^^',
  },
  controllerAs: 'tabCtrl',
  controller: function ctrl() {
'ngInject';
    const _this = this;

    this.$onInit = function () {
      this.tab = {
        internalName: this.internalName,
        selected: this.isSelected || false,
        tabLabelKey: this.tabLabelKey,
        count: this.count,
      };

      this.nvDashboardFilterTabList.addTab(this);

      this.initialized = true;
    };

    this.$onChanges = function (changes) {
      if (changes.count && !changes.count.isFirstChange()) {
        this.tab.count = changes.count.currentValue;
      }

      if (changes && this.tab && changes.isSelected) {
        this.tab.isSelected = changes.isSelected.currentValue;
      }
    };

    this.selectTab = function () {
      this.nvDashboardFilterTabList.selectTab(this.tab);
    };
  },
  templateUrl: 'shared/templates/nv-dashboard-filter-tab.html',
};
