import store from '../../../redux/store';
import { updateLectureComponentFromAngular } from '../../../redux/actions/lecture-components';

/* @ngInject */
export default function ProfileCompletionComponentModel(
  _,
  config,
  $translate,
  CurrentCourseManager,
  LectureComponentBaseModel,
) {
  class ProfileCompletionComponent extends LectureComponentBaseModel {
    constructor(attributes, isSample, useDefaults = false) {
      const defaults = {
        content: '',
        type: 'ProfileCompletionLectureComponent',
        profileRequirement: {
          isTodo: false,
          requiredOrgFieldIds: [],
          requiredNovoedQuestions: [],
          requiredCourseProfileQuestionIds: [],
          title: $translate.instant('LECTURE_PAGES.COMPONENTS.PROFILE_COMPLETION.DEFAULT_TITLE'),
        },
        editFormController: 'ProfileCompletionEditFormModalCtrl',
        editFormTemplateUrl: 'lecture_pages/templates/components/profile-completion-edit-form-modal.html',
      };

      super(_.extend({}, defaults, attributes));

      this.__preprocess();

      if (useDefaults) {
        this.createDraft();
      }
    }

    __preprocess() {
      const cpr = this.profileRequirement;

      CurrentCourseManager.course?.addProfileRequirement(cpr);

      if (cpr.novoedQuestions) {
        cpr.requiredNovoedQuestions = cpr.novoedQuestions;
      }

      if (cpr.orgFields) {
        cpr.requiredOrgFieldIds = cpr.orgFields.filter(
          (orgField) => orgField.isRequired,
        ).map((orgField) => orgField.id);
      }

      if (cpr.courseProfileQuestions) {
        cpr.requiredCourseProfileQuestionIds = cpr.courseProfileQuestions.filter(
          (courseProfileQuestion) => courseProfileQuestion.isRequired,
        ).map((courseProfileQuestion) => courseProfileQuestion.id);
      }
    }

    __getProfileRequirement() {
      return _.pick(this.profileRequirement, [
        'title',
        'isTodo',
        'requiredOrgFieldIds',
        'requiredNovoedQuestions',
        'requiredCourseProfileQuestionIds',
      ]);
    }

    cleanup() {

    }

    saveDraft() {
      _.extend(this.profileRequirement, this.profileRequirementDraft);
    }

    save(...args) {
      return super.save(...args).then((resolved) => {
        store.dispatch(updateLectureComponentFromAngular({
          profileRequirement: this.profileRequirement,
        }));

        return resolved;
      });
    }

    getPayload(includeSettings = false) {
      const picked = _.pick(this, [
        'index',
        'content',
        // Modal workflow needs these properties for admin 1.0 redirection
        ...(includeSettings ? ['id', 'catalogId', 'redirectToAdminNavigation'] : []),
      ]);

      return ({
        ...picked,
        profileRequirement: this.__getProfileRequirement(),
      });
    }

    createDraft() {
      this.profileRequirementDraft = this.__getProfileRequirement();
    }

    setDraftRequiredQuestions({
      novoed,
      course,
      orgLevel,
    }) {
      this.profileRequirementDraft.requiredNovoedQuestions = novoed;
      this.profileRequirementDraft.requiredOrgFieldIds = orgLevel;
      this.profileRequirementDraft.requiredCourseProfileQuestionIds = course;
    }

    delete() {
      return super.delete().then((resolved) => {
        this.cleanup();

        return resolved;
      });
    }

    toggleToDo() {
      this.profileRequirement.isTodo = !this.profileRequirement.isTodo;
      return this.save();
    }
  }

  ProfileCompletionComponent.iconClass = 'icon-profile';
  ProfileCompletionComponent.showModalBeforeCreate = true;
  ProfileCompletionComponent.type = 'ProfileCompletionLectureComponent';
  ProfileCompletionComponent.pendoTagName = config.pendo.lectureEdit.profileCompletion;
  ProfileCompletionComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.PROFILE_COMPLETION.DESCRIPTION';
  ProfileCompletionComponent.unavailabilityDescriptionKey = 'LECTURE_PAGES.COMPONENTS.PROFILE_COMPLETION.UNAVAILABLE';

  return ProfileCompletionComponent;
}
