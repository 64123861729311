import axios from 'axios';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import {
  FetchBookmarksListRequest,
  FetchBookmarksListResponse,
  CreateBookmarkRequest,
  UpdateBookmarkRequest,
  DeleteBookmarkRequest,
  HighlightBookmark,
  BookmarkResponse,
} from 'redux/schemas/api/bookmarks';
import { makeQueryParamString } from 'redux/actions/helpers';
import { Bookmark } from 'redux/schemas/models/bookmark';

export const fetchBookmarks = createAsyncThunk<FetchBookmarksListResponse, FetchBookmarksListRequest>(
  'GET_BOOKMARKS_LIST',
  async (params) => {
    const queryString = makeQueryParamString({ ...params });

    const response = await axios.get(`/bookmarks?${queryString}`);
    return response.data.result;
  },
);

export const fetchSingleBookmark = createAsyncThunk<Bookmark, number>(
  'GET_SINGLE_BOOKMARK',
  async (id) => {
    const response = await axios.get(`/bookmarks/${id}`);
    return response.data.result;
  },
);

export const createBookmark = createAsyncThunk<BookmarkResponse, CreateBookmarkRequest>(
  'CREATE_BOOKMARK',
  async (params) => {
    const response = await axios.post('/bookmarks.json', params);
    return response.data.result;
  },
);

export const updateBookmark = createAsyncThunk<BookmarkResponse, UpdateBookmarkRequest>(
  'UPDATE_BOOKMARK',
  async (params) => {
    const response = await axios.patch(`/bookmarks/${params.id}`, params);
    return response.data.result;
  },
);

export const deleteBookmark = createAsyncThunk <any, DeleteBookmarkRequest>(
  'DELETE_BOOKMARK',
  async (params) => {
    const response = await axios.delete(`/bookmarks/${params.id}`);
    return response.data.result;
  },
);

export const filterBookmarks = createAction<{
  filter: string,
  shouldUnsetCurrentData: boolean,
}>('FILTER_BOOKMARKS');
export const highlightBookmark = createAction<HighlightBookmark>('HIGHLIGHT_BOOKMARK');
export const unhighlightBookmark = createAction('UNHIGHLIGHT_BOOKMARK');

export const addBookmark = createAction<Bookmark>('ADD_BOOKMARK');
export const editBookmark = createAction<UpdateBookmarkRequest>('EDIT_BOOKMARK');
export const removeBookmark = createAction<DeleteBookmarkRequest>('REMOVE_BOOKMARK');

export const removeCourseBookmarks = createAction<number>('REMOVE_COURSE_BOOKMARKS');

export const focusBookmark = createAction<number>('FOCUS_BOOKMARK');

export const unsetLastDeletedBookmark = createAction<number>('UNSET_LAST_DELETED_BOOKMARK');
