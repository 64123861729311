/**
 * References from this file comments:
 *
 * CourseFormHeader is a component from Branding Update project (released at
 * 05/24/2021) that existed before this one. This component file abstracted the
 * logic from the original file to make it reusable for many kinds of offerings
 * like courses, programs and newly introduced learning journeys.
 */
import React from 'react';
import Truncate from 'react-truncate';
import { css, jsx } from '@emotion/react';
import { FileRejection } from 'react-dropzone';
import { useMediaQuery } from 'react-responsive';
import TextareaAutosize from 'react-textarea-autosize';

import t from 'react-translate';
import { mergeRefs } from 'shared/react-utils';
import NvIcon from 'shared/components/nv-icon';
import { readFileAsDataURL } from 'shared/utils';
import NvPopover from 'shared/components/nv-popover';
import NvTooltip from 'shared/components/nv-tooltip';
import NvFilePicker from 'shared/components/nv-filepicker';
import ClickableContainer from 'components/clickable-container';
import { InstitutionLogoSize } from 'redux/schemas/models/institution';
import ValidationErrorMessage from 'shared/components/inputs/validation-error-message';
import {
  boldFontWeight,
  openSansCondensed,
} from 'styles/global_defaults/fonts';
import {
  black,
  gray1,
  gray3,
  hexToRgbaString,
} from 'styles/global_defaults/colors';
import {
  halfSpacing,
  tooltipZIndex,
  doubleSpacing,
  tripleSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import {
  desktop,
  handheld,
  screenXsMax,
} from 'styles/global_defaults/media-queries';
import NvDropdown, {
  NvDropdownAlign,
  NvDropdownOption,
  NvDropdownButtonStyle,
} from 'shared/components/inputs/nv-dropdown';
import ColorPickerPopover, { SetBackgroundColorTarget } from 'components/color-picker-popover';
import { config } from '../../../config/config.json';

const CIRCLE_SIZE = 16;
const MAX_NAME_LENGTH = 340;
const DASHED_BORDER_WIDTH = 1;
const Textarea = TextareaAutosize as any;
/* Allowed image file types to upload for logo and header background image */
const ALLOWED_IMAGE_TYPES = config.files.images.split(',');

export const MAX_FILE_SIZE = 5242880;

const LOGO_SIZES = {
  [InstitutionLogoSize.SMALL]: standardSpacing * 3,
  [InstitutionLogoSize.LARGE]: standardSpacing * 5,
  [InstitutionLogoSize.MEDIUM]: standardSpacing * 4,
};

/**
 * "file" property is optional here meaning that you shouldn't provide a File
 * object, because the File object is set by the component internally to provide
 * it to its parent via onChange event handler.
 */
type Logo = {
  file?: File,
  url: string | null,
  size: InstitutionLogoSize,
};

type Background = {
  file?: File,
  url: string | null,
};

export type Value = {
  name: string,
  fontColor: string,
  logo: Logo | null,
  brandColor: string,
  background: Background | null,
};

type Errors = {
  name?: string,
};

export type Props = {
  /**
   * Controlling props
   */
  value: Value,
  onChange?: (newValue: Value) => void,
  /**
   * Errors
   */
  errors?: Errors,
  /**
   * All of the following props are texts used in the component that may vary
   * depending on the implementation.
   */
  logoPickerText?: string,
  logoTooltipText?: string,
  namePlaceholder?: string,
  backgroundImagePickerTooltipText?: string,
  /**
   * Logo dropdown options
   */
  logoDropdownOptions: NvDropdownOption[],
  /**
   * If true, user can change branding and get the new value through onChange
   * prop, if false header will display in view mode.
   */
  editable?: boolean,
  /**
   * Optional className for most parent element, it was useful for when I
   * abstracted the CourseFormHeader component and I needed to style the custom
   * dropdown items in OfferingBrandingHeader component (outside this one).
   */
  className?: string,
  /**
   * If true shows a button in top left section that allows user to change the
   * background of the header, otherwise won't render the button.
   * NOTE: Setting this as false doesn't mean that if the controller value has a
   * background the background won't be shown, the background will show even
   * though this prop is false, because it only affects the background picker
   * rendering, not the background itself.
   */
  backgroundImageEnabled?: boolean,
  /**
   * Render prop that allows you to render something in the logo container area
   */
  renderLogoContainer?: () => React.ReactNode,
  /**
   * If true, no top section will be rendered, usefull for rendering the header
   * in course homes since top section there is made with Angular
   */
  hideTopSection?: boolean,
  /**
   * Render prop to render extra content right afther the logo.
   */
  renderAfterLogo?: () => React.ReactNode,
  /**
   * Whether should have a transparent background.
   */
  transparentBackground?: boolean,
  /**
   * Object of data-qa's of inner parts of branding header.
   */
  dataQa?: {
    // TODO: So far only "title" exists but I anticipate we may add more in the
    // future like "logoPicker", etc.
    title?: string,
  },
};

export type Ref = {
  element?: HTMLDivElement,
  /**
   * onLogoSelectError is to let the component know that a logo has had an error
   * while selecting it with file picker outside the component via ref handle.
   * This is necessary in order to allow updating the branding logo
   */
  onLogoSelectError: () => void,
};

/**
 * BrandingHeader is a component that is expected to be used frequently in
 * NovoEd offering creation and modification forms (for now there are courses,
 * programs and journeys) that allows user to type in the offering name as well
 * as configure the branding styles like logo, logo size, background color,
 * background image & font color.
 * It is a forced controlled component (without internal state, it means you are
 * forced to provide value and onChange props) that allows you to use it as any
 * input in react, so only hook it to the state as shown in the following
 * example:
 *
 * const Example = () => {
 *   // NOTE: the initial value should have a specific shape, please refer to
 *   // "Value" TypeScript type in this same file.
 *   const [value, setValue] = React.useState({
 *     name: '',
 *     logo: null,
 *     fontColor: null,
 *     background: null,
 *     brandColor: null,
 *   });
 *
 *   return (
 *     <BrandingHeader
 *       {...otherProps}
 *       value={value}
 *       onChange={setValue}
 *     />
 *   );
 * };
 */
const BrandingHeader = React.forwardRef<Ref, Props>((props, ref) => {
  const {
    onChange,
    className,
    dataQa = {},
    logoPickerText,
    logoTooltipText,
    namePlaceholder,
    renderAfterLogo,
    editable = false,
    renderLogoContainer,
    logoDropdownOptions,
    hideTopSection = false,
    transparentBackground = false,
    backgroundImageEnabled = true,
    backgroundImagePickerTooltipText,
    value: {
      name,
      logo,
      fontColor,
      brandColor,
      background,
    },
    errors: {
      name: nameError,
    } = {},
  } = props;

  const { title: titleDataQa } = dataQa;

  const logoRef = React.useRef<Ref>();
  const mountedRef = React.useRef(false);
  const headerRef = React.useRef<HTMLDivElement>();
  const [isEditing, setIsEditing] = React.useState(false);
  const titleTextareaRef = React.useRef<HTMLTextAreaElement>();
  const [isEditingColor, setIsEditingColor] = React.useState(false);

  const closeDropdowns = () => {
    if (editable) {
      headerRef.current.click();
    }
  };

  const handleChange = (newData: Partial<Value>, close = true) => {
    if (close) {
      closeDropdowns();
    }
    onChange({
      ...props.value,
      ...newData,
    });
  };

  React.useEffect(() => {
    if (mountedRef.current) {
      if (!isEditing) {
        setIsEditingColor(false);
        titleTextareaRef.current?.blur();
      }
    } else {
      mountedRef.current = true;
    }
  }, [isEditing]);

  React.useImperativeHandle(ref, () => ({
    ...logoRef.current,
    element: headerRef.current,
  }));

  const styles = css`
    position: relative;
    color: ${fontColor};
    background-color: transparent;
    padding-bottom: ${halfSpacing}px;

    ${background ? css`
      ${!hideTopSection && css`
        border-top: ${quarterSpacing}px solid ${brandColor};
      `}
      ${!transparentBackground && css`
        background: linear-gradient(0deg, ${hexToRgbaString(gray1, 0.5)} 0%, ${hexToRgbaString(gray1, 0)} 100%) bottom/100% 50% no-repeat, ${hexToRgbaString(black, 0.1)} url("${background.url}") center/cover no-repeat;
      `}
    ` : (!transparentBackground && css`
      background: ${brandColor};
    `)};

    input[type=text] {
      color: ${fontColor};
      background-color: transparent;
      -webkit-text-fill-color: ${fontColor};

      &:focus {
        outline: none;
      }
    }

    .top-section {
      height: ${doubleSpacing}px;
      margin-bottom: ${background ? threeQuartersSpacing : standardSpacing}px;

      .background-picker {
        top: 0;
        left: 0;
        position: absolute;
      }

      .brand-color-input {
        top: 0;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
      }
    }

    .logo-container {
      position: relative;
      box-sizing: content-box;
      margin-bottom: ${halfSpacing}px;
      min-height: ${LOGO_SIZES[InstitutionLogoSize.SMALL]}px;
      ${!editable && `
        pointer-events: none;
      `}
    }

    .title-container {
      margin-left: auto;
      margin-right: auto;

      ${desktop(css`
        width: 800px;
      `)};
    }

    .foreground-color-picker {
      width: 100%;
      display: flex;
      position: absolute;
      justify-content: center;
      height: ${CIRCLE_SIZE}px;

      .circle {
        cursor: pointer;
        border-radius: 50%;
        border-style: solid;
        display: inline-block;
        width: ${CIRCLE_SIZE}px;
        height: ${CIRCLE_SIZE}px;
        border-width: ${DASHED_BORDER_WIDTH}px;
        transform: translateY(-${(DASHED_BORDER_WIDTH / 2) + (CIRCLE_SIZE / 2)}px);

        &.white-circle {
          border-color: black;
          background-color: white;
        }

        &.black-circle {
          border-color: white;
          background-color: black;
        }
      }
    }

    .dashed-border {
      border: ${DASHED_BORDER_WIDTH}px dashed ${(editable && isEditing) ? gray3 : 'transparent'};
    }

    .shaded-background {
      ${editable && isEditing && css`
        background-color: ${hexToRgbaString(black, 0.3)};
      `}
    }

    .visibility-hidden {
      visibility: ${(editable && isEditing) ? 'visible' : 'hidden'};
    }
  `;

  const handleMouseLeave = () => {
    setIsEditing(false);
    closeDropdowns();
  };

  const handleMouseEnter = () => setIsEditing(true);

  const handleFontColorChange = (newFontColor) => handleChange({
    fontColor: newFontColor,
  });

  const handleBrandColorChange = (section, newColor) => handleChange({
    brandColor: newColor,
  }, false);

  const handleNameChange = (event) => handleChange({ name: event.target.value });

  const handleLogoChange = (newLogo) => {
    if (newLogo) {
      readFileAsDataURL(newLogo.file).then((src) => {
        handleChange({
          logo: {
            ...newLogo,
            url: src,
          },
        });
      });
    } else {
      handleChange({ logo: newLogo });
    }
  };

  const handleBackgroundImagePickerChange = (newBackground) => {
    if (newBackground) {
      readFileAsDataURL(newBackground.file).then((src) => {
        handleChange({
          background: {
            ...newBackground,
            url: src,
          },
        });
      });
    } else {
      handleChange({ background: newBackground });
    }
  };

  // The 'branding-header' id is being used in nv-notifications-popover to prevent the popover from closing on click.
  return (
    <div
      id='branding-header'
      css={styles}
      ref={headerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`text-center${className ? ` ${className}` : ''}`}
    >
      {!hideTopSection && (
        <div className='top-section'>
          {editable && backgroundImageEnabled && (
            <BackgroundImagePicker
              value={background}
              isEditing={isEditing}
              onChange={handleBackgroundImagePickerChange}
              tooltipText={backgroundImagePickerTooltipText}
              className='background-picker shaded-background visibility-hidden'
            />
          )}
          {editable && (
            <div className='brand-color-input shaded-background visibility-hidden'>
              <ColorPickerPopover<{
                backgroundColor: string;
              }>
                placement='bottom'
                colorValues={{
                  backgroundColor: brandColor,
                }}
                onChange={handleBrandColorChange}
                sections={[{
                  name: 'backgroundColor',
                  title: t.LECTURE_PAGES.COMPONENTS.HEADER.BACKGROUND_COLOR(),
                }]}
                show={isEditingColor}
                onToggle={(nextShow) => setIsEditingColor(nextShow)}
              >
                <SetBackgroundColorTarget />
              </ColorPickerPopover>
            </div>
          )}
        </div>
      )}
      <div className='logo-container shaded-background dashed-border'>
        <Logo
          value={logo}
          ref={logoRef}
          editable={editable}
          isEditing={isEditing}
          onChange={handleLogoChange}
          tooltipText={logoTooltipText}
          logoPickerText={logoPickerText}
          dropdownOptions={logoDropdownOptions}
          logoPickerClassName='visibility-hidden'
        />
        {editable && renderLogoContainer?.()}
      </div>
      {renderAfterLogo?.()}
      <div id='expanded-title' className='title-container dashed-border shaded-background'>
        <Title
          value={name}
          error={nameError}
          color={fontColor}
          editable={editable}
          dataQa={titleDataQa}
          enabledPopover={isEditing}
          onChange={handleNameChange}
          placeholder={namePlaceholder}
          textareaRef={titleTextareaRef}
        />
      </div>
      {editable && (
        <div className='foreground-color-picker visibility-hidden'>
          <NvTooltip text={t.INSTITUTIONS.BRANDING.HEADER.TITLE_TEXT_WHITE()}>
            <div
              className='circle white-circle mr-2'
              onClick={() => handleFontColorChange('#fff')}
            />
          </NvTooltip>
          <NvTooltip text={t.INSTITUTIONS.BRANDING.HEADER.TITLE_TEXT_BLACK()}>
            <div
              className='circle black-circle'
              onClick={() => handleFontColorChange('#000')}
            />
          </NvTooltip>
        </div>
      )}
    </div>
  );
});

type TitleProps = {
  color: string,
  editable: boolean,
  enabledPopover: boolean,
  textareaRef: React.MutableRefObject<HTMLTextAreaElement>,
  error?: string,
  dataQa?: string,
} & React.ComponentProps<'textarea'>;

const Title = (props: TitleProps) => {
  const {
    color,
    error,
    dataQa,
    editable,
    enabledPopover,
    textareaRef: propsTextareaRef,
    ...restProps
  } = props;

  const mountedRef = React.useRef(false);
  const textareaRef = React.useRef<HTMLTextAreaElement>();
  const [isEditing, setIsEditing] = React.useState(false);
  const [isTitleTouched, setIsTitleTouched] = React.useState(false);

  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const numOfLines = isHandheld ? 3 : 2;

  React.useEffect(() => {
    if (mountedRef.current) {
      if (isEditing) {
        textareaRef.current.focus();
      }
    } else {
      mountedRef.current = true;
    }
  }, [isEditing]);

  const styles = css`
    display: flex;
    min-height: 100px;
    text-align: center;
    align-items: center;
    justify-content: center;

    ${!isEditing && css`
      padding: 0 ${halfSpacing}px;
    `}
    .course-header{
      margin-top:0;
    }
    .title-text {
      color: ${color};
      text-align: center;
      font-size: 36px !important;
      line-height: 50px !important;
      font-weight: ${boldFontWeight};
      font-family: ${openSansCondensed};

      ${handheld(css`
        font-size: 24px !important;
        line-height: 35px !important;
      `)};
    }

    .full-width {
      width: 100%;
    }

    .title-textarea {
      width: 100%;
      resize: none;
      border: none;
      outline: none;
      vertical-align: bottom;
      padding: 0 ${halfSpacing}px;
      background-color: transparent;

      &::placeholder {
        color: ${color};
      }
    }
  `;

  const handleContainerClick = () => setIsEditing(true);

  const handleTextareaBlur = () => {
    setIsTitleTouched(true);
    setIsEditing(false);
  };
  const renderViewModeContent = () => (
    <h1 className='course-header full-width'>
      <Truncate
        ellipsis='...'
        lines={numOfLines}
        className='title-text full-width'
      >
        {restProps.value || restProps.placeholder}
      </Truncate>
    </h1>
  );

  const renderEditableTitleContent = () => (
    <NvPopover
      placement='bottom'
      className='full-width'
      enabled={enabledPopover}
      show={isTitleTouched && !!error}
      content={<ValidationErrorMessage text={error} id='branding-title' />}
    >
      {isEditing ? (
        <Textarea
          {...restProps}
          maxRows={numOfLines}
          cacheMeasurements={false}
          maxLength={MAX_NAME_LENGTH}
          onBlur={handleTextareaBlur}
          className='title-textarea title-text no-form'
          ref={mergeRefs(textareaRef, propsTextareaRef)}
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          aria-describedby='branding-title'
        />
      ) : renderViewModeContent()}
    </NvPopover>
  );

  return (
    <ClickableContainer
      css={styles}
      data-qa={dataQa}
      disabled={isEditing}
      layoutOnly={!editable}
      onClick={handleContainerClick}
    >
      {editable ? renderEditableTitleContent() : renderViewModeContent()}
    </ClickableContainer>
  );
};

type LogoProps = {
  editable: boolean,
  value: Logo | null,
  isEditing: boolean,
  tooltipText: string,
  logoPickerText: string,
  logoPickerClassName: string,
  onChange: (logo: Logo) => void,
  dropdownOptions: NvDropdownOption[],
};

const Logo = React.forwardRef<Ref, LogoProps>((props, ref) => {
  const {
    value,
    editable,
    onChange,
    isEditing,
    tooltipText,
    logoPickerText,
    dropdownOptions,
    logoPickerClassName,
  } = props;
  const {
    url,
    size,
  } = (value || {});
  const currentSize = LOGO_SIZES[size];
  const [selectedInvalidLogo, setSelectedInvalidLogo] = React.useState(false);

  const handleChange = (newData: Partial<Logo>) => onChange({
    size: InstitutionLogoSize.SMALL,
    ...value,
    ...newData,
  });

  const handleFilePickerChange = (files) => {
    const [file] = files;

    handleChange({ file, url: null });
  };

  const handleLogoSelectError = () => {
    setSelectedInvalidLogo(true);
  };

  const handleLogoPickerClick = () => {
    setSelectedInvalidLogo(false);
  };

  React.useImperativeHandle(ref, () => ({
    onLogoSelectError: handleLogoSelectError,
  }));

  /*
   * In the value variable we can have the logo information of both: the course or the institution.
   * - If the course has a logo configured:
   * then the dropdownOptions array will have more than one option.
   * - If the course doesn't have a logo defined but the institution does:
   * then the dropdownOptions array will have only one option.
   */
  const getLogoComponent = () => {
    let logoComponent;

    if (value) {
      if (dropdownOptions.length > 1) {
        logoComponent = (
          <NvDropdown
            disabled={!editable}
            showSelectedIndicator
            items={dropdownOptions}
            align={NvDropdownAlign.CENTER}
            buttonStyle={NvDropdownButtonStyle.CUSTOM}
            customTarget={() => (
              <LogoImage
                src={url}
                size={currentSize}
                editable={editable}
                isEditing={isEditing}
                tooltipText={tooltipText}
              />
            )}
            initialIndex={dropdownOptions.findIndex(
              item => item?.id === size,
            )}
          />
        );
      } else {
        logoComponent = (
          <NvFilePicker
            multiple={false}
            onClick={handleLogoPickerClick}
            onChange={handleFilePickerChange}
            maxSize={MAX_FILE_SIZE}
            accept={ALLOWED_IMAGE_TYPES}
            onSelectError={handleLogoSelectError}
          >
            <LogoImage
              src={url}
              size={currentSize}
              editable={editable}
              isEditing={isEditing}
              tooltipText={tooltipText}
            />
          </NvFilePicker>
        );
      }
    } else if (editable) {
      logoComponent = (
        <LogoPicker
          text={logoPickerText}
          className={logoPickerClassName}
          onClick={handleLogoPickerClick}
          onChange={handleFilePickerChange}
          onSelectError={handleLogoSelectError}
        />
      );
    }

    return logoComponent;
  };

  return (
    <NvPopover
      placement='bottom'
      enabled={editable}
      show={selectedInvalidLogo && isEditing}
      content={(
        <ValidationErrorMessage
          text={t.PROFILE.INVALID_FILE()}
        />
      )}
    >
      <div>
        {getLogoComponent()}
      </div>
    </NvPopover>
  );
});

type LogoImageProps = {
  src: string,
  size: number,
  editable: boolean,
  isEditing: boolean,
  tooltipText: string,
};

const LogoImage = React.forwardRef<HTMLDivElement, LogoImageProps>((props, ref) => {
  const {
    src,
    size,
    editable,
    isEditing,
    tooltipText,
  } = props;

  const styles = css`
    width: 100%;
    max-width: 100vw;
    height: ${size}px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${src});
  `;

  return (
    <NvTooltip
      text={tooltipText}
      enabled={editable && isEditing}
    >
      <div
        ref={ref}
        css={styles}
        className='logo-image'
      />
    </NvTooltip>
  );
});

type LogoPickerProps = {
  text: string,
  className: string,
  onChange: (files: File[]) => void,
  onSelectError: (files: FileRejection[]) => void,
  onClick: React.ComponentProps<'div'>['onClick'],
};

const LogoPicker = (props: LogoPickerProps) => {
  const {
    text,
    onClick,
    onChange,
    className,
    onSelectError,
  } = props;

  const styles = css`
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: center;
    height: ${tripleSpacing}px;


    .icon {
      padding-right: ${halfSpacing}px;
    }
  `;

  return (
    <NvFilePicker
      multiple={false}
      onClick={onClick}
      onChange={onChange}
      className={className}
      maxSize={MAX_FILE_SIZE}
      accept={ALLOWED_IMAGE_TYPES}
      onSelectError={onSelectError}
    >
      <div css={styles}>
        <NvIcon icon='upload' size='large' />
        <div className='content-container'>
          <div>{text}</div>
          <div>
            {t.FILE_UPLOAD.IMAGE_TYPES()}
            {' '}
            {t.FILE_UPLOAD.UP_TO_SIZE('5MB')}
          </div>
        </div>
      </div>
    </NvFilePicker>
  );
};

type BackgroundImagePickerProps = {
  value: Background,
  isEditing: boolean,
  tooltipText: string,
  onChange: (background: Background) => void,
  className?: string,
};

const BackgroundImagePicker = (props: BackgroundImagePickerProps) => {
  const {
    value,
    onChange,
    className,
    isEditing,
    tooltipText,
  } = props;

  const [selectedInvalidImage, setSelectedInvalidImage] = React.useState(false);

  const styles = css`
    // NOTE: I guess why this was the way to make this component functional and
    // I think it was ultra necessary because of the release urgency.
    // In my opinion the right way for this to have been done is to disable the
    // button tooltip for when the dropdown is open, but today (06/01/2021)
    // there's a known bug that can be summarized on saying that when the
    // "enabled" prop value changes from our NvTooltip component, it unmounts
    // and re-mounts the children component again, causing multiple problems
    // specifically with popovers encountered by myself while working on
    // Org Level Profile project, some of them could be solved with the help of
    // a hook I created called "usePostRender".
    // TODO: Remove this dropdown override for when NvTooltip component is
    // fixed.
    .bs4-dropdown-menu {
      z-index: ${tooltipZIndex + 1};
    }

    .button {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      height: ${doubleSpacing}px;
      padding: 0 ${halfSpacing}px;

      .icon {
        margin-right: ${halfSpacing}px;
      }
    }
  `;

  const handleImageDelete = () => onChange(null);

  const handleImagePickerClick = () => setSelectedInvalidImage(false);

  const handleImagePickerSelectError = () => setSelectedInvalidImage(true);

  const handleImagePickerChange = (files: File[]) => {
    const [file] = files;
    onChange({ file, url: null });
  };

  const renderFilePicker = (extraProps) => (
    <NvFilePicker
      {...extraProps}
      multiple={false}
      maxSize={MAX_FILE_SIZE}
      accept={ALLOWED_IMAGE_TYPES}
      onClick={handleImagePickerClick}
      onChange={handleImagePickerChange}
      onSelectError={handleImagePickerSelectError}
    />
  );

  const renderButton = () => (
    <div className='button'>
      <NvIcon
        size='smallest'
        icon={value ? 'edit' : 'upload'}
      />
      <div className='text-small d-none d-sm-block'>
        {value ? t.INSTITUTIONS.BRANDING.HEADER.EDIT_BACKGROUND_IMAGE() : t.INSTITUTIONS.BRANDING.HEADER.UPLOAD_BACKGROUND_IMAGE()}
      </div>
    </div>
  );

  const backgroundImageItems: NvDropdownOption[] = [
    {
      type: 'custom',
      customItem: renderFilePicker({
        className: 'pl-3 py-1',
        children: t.INSTITUTIONS.BRANDING.HEADER.NEW_BACKGROUND_IMAGE(),
      }),
    },
    { type: 'divider' },
    {
      type: 'text',
      class: 'pl-3 text-danger',
      callback: handleImageDelete,
      text: t.INSTITUTIONS.BRANDING.HEADER.REMOVE_BACKGROUND_IMAGE(),
    },
  ];

  return (
    <NvPopover
      className={className}
      placement='bottom-start'
      show={selectedInvalidImage && isEditing}
      content={(
        <ValidationErrorMessage
          text={t.PROFILE.INVALID_FILE()}
        />
      )}
    >
      <NvTooltip
        text={tooltipText}
        placement='bottom'
        enabled={!selectedInvalidImage}
      >
        <div css={styles}>
          {value ? (
            <NvDropdown
              customTarget={renderButton}
              items={backgroundImageItems}
              buttonStyle={NvDropdownButtonStyle.CUSTOM}
            />
          ) : (
            renderFilePicker({ children: renderButton() })
          )}
        </div>
      </NvTooltip>
    </NvPopover>
  );
};

export default BrandingHeader;
