import { useAngularPreventLecturePageNavigation } from '../hooks/use-prevent-lecture-page-navigation';

/* @ngInject */
export default function ExerciseShareSubmissionModalController(
  $scope,
  $stateParams,
  $translate,
  $uibModalInstance,
  AlertMessages,
  StateManager,
  ConfirmationOverlays,
  CurrentCourseManager,
  CurrentUserManager,
  InteroperabilityRoutes,
  MentionablesModel,
  TeamsResources,
  TeamManager,
  config,
  submission,
) {
  const vm = Object.assign(this, {
    CurrentCourseManager,
    CurrentUserManager,
    InteroperabilityRoutes,
    MentionablesModel,
    config,
    submission,
  });

  vm.currentCourse = CurrentUserManager.user.enrollments.find(enrollment => enrollment.course.catalogId === $stateParams.catalogId).course;
  vm.message = '';
  vm.dismissedByUser = false;

  initialize();

  function initialize() {
    // Getting the memberships that belongs to the current course
    vm.teamsGroupsInCurrentCourse = TeamManager.teamsGroupsInCurrentCourse;
    // Checking if the user has teams and groups
    vm.hasTeamsAndGroups = TeamManager.hasTeamsAndGroups;
    // Selects by default the first membership if there is only one
    vm.selectedTeams = vm.teamsGroupsInCurrentCourse.length === 1 ? [vm.teamsGroupsInCurrentCourse[0].team.id] : [];
  }

  vm.addTeam = (teamId) => {
    if (vm.selectedTeams.includes(teamId)) {
      vm.selectedTeams = vm.selectedTeams.filter(id => id !== teamId);
    } else {
      vm.selectedTeams.push(teamId);
    }
  };

  vm.submit = () => {
    vm.dismissedByUser = true;
    $uibModalInstance.close();
    TeamsResources.createBulkSubmissionPost({
      catalogId: $stateParams.catalogId,
    }, {
      teamIds: vm.selectedTeams,
      topic: {
        body: vm.message,
        metaContent: { type: 'Report', id: vm.submission.id },
      },
    }).$promise.then(() => {
      if (vm.selectedTeams.length === 1) {
        const { isGroup } = vm.teamsGroupsInCurrentCourse.find((mem) => mem.team.id === vm.selectedTeams[0]).team.teamSet;
        const messageValues = { teamOrGroupAlias: isGroup ? vm.currentCourse.groupName.downcasedSingularized : vm.currentCourse.teamName.downcasedSingularized };
        const buttonValues = { teamOrGroupTitleAlias: isGroup ? vm.currentCourse.groupName.singularizedTitleized : vm.currentCourse.teamName.singularizedTitleized };
        AlertMessages.share('', 'EXERCISES.SHARE_SUBMISSION_TO_TEAM.ALERT', {}, messageValues, undefined, {
          text: $translate.instant('EXERCISES.SHARE_SUBMISSION_TO_TEAM.VISIT_TEAM_WORKSPACE', buttonValues),
          href: vm.InteroperabilityRoutes.teamWorkspacePath(vm.currentCourse, vm.selectedTeams[0]),
        });
      } else {
        AlertMessages.share('', 'EXERCISES.SHARE_SUBMISSION_TO_TEAM.ALERT_MULTIPLE');
      }
    }).catch(() => {
      AlertMessages.error('FORM.OOPS', 'FORM.ERROR_SOMETHING_WRONG');
    });
  };

  vm.hasUnsavedChanges = () => !!(vm.message || (vm.teamsGroupsInCurrentCourse.length > 1 && vm.selectedTeams.length));

  const checkUnsavedChanges = () => !vm.dismissedByUser && vm.hasUnsavedChanges();

  useAngularPreventLecturePageNavigation($scope, checkUnsavedChanges);

  // - unsaved changes overlay
  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(
    checkUnsavedChanges,
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    'FORM.NAVIGATE_AWAY.ARE_YOU_SURE',
  );

  $scope.$on('modal.closing', ($event) => {
    if (!vm.dismissedByUser && vm.hasUnsavedChanges()) {
      $event.preventDefault();

      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        vm.dismissedByUser = true;
        $uibModalInstance.dismiss();
      });
    }
  });

  $scope.$on('$destroy', () => vm.deregisterStateChangeStart());
}
