import { css } from '@emotion/react';
import t from 'react-translate';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import store, { useAppDispatch } from 'redux/store';

import { AngularServicesContext } from 'react-app';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { copyLectureComponent, moveLectureComponent } from 'redux/actions/lecture-components';
import { getCourseLecturePageTimeline } from 'redux/actions/timeline';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { LectureComponent } from 'redux/schemas/models/lecture-component';
import { getCurrentUserId } from 'redux/selectors/users';
import { getFlatCourseAliases } from 'redux/selectors/course';
import { standardSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { gray7 } from 'styles/global_defaults/colors';
import NvIcon from 'shared/components/nv-icon';
import { useLecturePageLink } from 'lecture_pages/hooks/lecture-routing';
import ClickableContainer from 'components/clickable-container';
import { LecturePageMode } from 'lecture_pages/components';
import { loadLecturePageModel } from 'lecture_pages/components/content-area/angular-lecture-component';
import { getLectureComponentDomId } from 'lecture_pages/directives/components/base-lecture-component';
import getComponentMetadata from 'lecture_pages/components/data';
import { LecturePagePreviewContext, PreviewSource } from 'lecture_pages/components/lecture-page-preview-modal';

const styles = css`
    height: ${standardSpacing}px;
    max-width: 800px;

    .container-bar {
      cursor: pointer;
      display: none;
    }

    .copy-bar {
      cursor: pointer;
    }

    &:hover .container-bar {
      display: flex;
      cursor: pointer;
      background-color: ${gray7};
      justify-content: center;
      height: 100%;
      padding-left: ${halfSpacing}px;
      .copy-bar {
        display: flex;                    ;
      }
    }
`;

type NvCopyComponentProps = {
  index: number
  catalogId: string
  currentLecturePageId: number
};
/**
 * Copy/Move functionalities are performed here
 * @param index copying/moving location,
 * @param catalogId
 * @param currentLecturePageId
 * @returns Redirects to the destination after copy/move action
 */
export const NvCopyComponent = (props: NvCopyComponentProps) => {
  const { component } = useSelector(
    state => state.app.destinationLecturePage,
  );
  const userId = useSelector(state => getCurrentUserId(state));
  const courseAliases = useSelector(state => getFlatCourseAliases(state, props.catalogId));

  const { $injector, $scope } = useContext(AngularServicesContext);
  const angularComponent = $scope[`lectureComponent${component?.id}`];

  const { lecturePageId, onClosePreviewModal, source } = useContext(LecturePagePreviewContext);

  const [isRequesting, setIsRequesting] = useState(false);

  const lectureLink = useLecturePageLink();
  const dispatch = useAppDispatch();

  const metadata = useMemo(() => getComponentMetadata(component?.trueType), [component?.trueType]);

  const onSelect = useCallback(() => {
    setIsRequesting(true);
    let actionTo: typeof copyLectureComponent | typeof moveLectureComponent = null;

    if (source === PreviewSource.COPY) {
      actionTo = copyLectureComponent;
    } else if (source === PreviewSource.MOVE) {
      actionTo = moveLectureComponent;
    }

    const doActionTo = () => {
      dispatch(actionTo({
        catalogId: props.catalogId,
        lecturePageId: props.currentLecturePageId,
        lectureComponent: component,
        toLecturePageId: lecturePageId.toString(),
        newDestinationIndex: props.index.toString(),
      })).then(result => {
        setIsRequesting(false);
        onClosePreviewModal?.();
        if (actionTo.rejected.match(result)) {
          dispatch(addAlertMessage({
            type: AlertMessageType.ERROR,
            header: t.FORM.OOPS(),
            message: t.FORM.ERROR_SOMETHING_WRONG(),
          }));
        } else if (props.currentLecturePageId === lecturePageId) {
          dispatch(getCourseLecturePageTimeline({
            catalogId: props.catalogId,
            userId,
            lecturePageId,
          })).then(() => {
            /**
             * Calling useLecturePageLink() is the correct method here. But
             * it causes a reload of the content area. So just scrolling to
             * the newly created LC
             */
            const lcElement = document.getElementById(getLectureComponentDomId(result.payload as LectureComponent));
            if (lcElement) {
              setTimeout(() => {
                lcElement.scrollIntoView({ behavior: 'smooth' });
              });
            }
          });
        } else {
          lectureLink({
            catalogId: props.catalogId,
            lecturePageId,
            mode: LecturePageMode.EDIT,
            lectureActivityId: (result.payload as LectureComponent).id,
          });
        }
      });
    };

    const { models } = store.getState();

    const targetLecturePage = loadLecturePageModel($injector, models.lecturePages[lecturePageId]);

    const moveToWarningMessages: string[] = angularComponent?.moveToWarningMessages(targetLecturePage);

    if (moveToWarningMessages?.length > 0 && source === PreviewSource.MOVE) {
      dispatch(openConfirmationDialog({
        title: t.LECTURE_PAGES.COMPONENTS.MOVE_TITLE(metadata.description(courseAliases)),
        confirmText: t.FORM.YES_SURE(),
        cancelText: t.FORM.CANCEL(),
        onConfirm: doActionTo,
        bodyText: (
          <div className='text-justify'>
            {moveToWarningMessages.map(message => <li>{message}</li>)}
          </div>
        ),
        footerText: t.DASHBOARD.CONFIRMATION.ARE_YOU_SURE(),
        onCancel: () => setIsRequesting(false)
      }));
    } else {
      doActionTo();
    }
  }, [
    source, $injector, lecturePageId, angularComponent, dispatch,
    props.catalogId, props.currentLecturePageId, props.index, component,
    onClosePreviewModal, userId, lectureLink, metadata, courseAliases,
  ]);

  return (
    <div css={styles} className='mx-auto my-2'>
      <ClickableContainer className='container-bar' onClick={onSelect}>
        <div className='copy-bar align-items-center text-primary'>
          <NvIcon icon='duplicate' size='xxs-smallest' className='mr-1' />
          <span className='text-primary text-small'>
            {isRequesting ? t.LECTURE_PAGES.COMPONENTS[source.toUpperCase()].REQUESTING()
              : t.LECTURE_PAGES.COMPONENTS[source.toUpperCase()].INITIAL()}
          </span>
        </div>
      </ClickableContainer>
    </div>
  );
};

export default NvCopyComponent;
