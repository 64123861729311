import { schema } from 'normalizr';
import { NovoEdFile } from 'shared/hooks/use-upload-file';
import { omit } from 'underscore';
import {
  VideoPracticeSubmissionsNormalized,
  VideoPracticeActivity,
  VideoPracticeScenario,
  VideoPracticeScenariosNormalized,
  VideoPracticeOption,
  VideoPracticeSubmission,
  PracticeSubmissionComment,
  PracticeRoomTab,
  SortOrFilterOption,
} from 'redux/schemas/models/video-practice';
import { FileUpload, UploadedFile } from '../models/file-uploads';
import { CommunicationListSchema } from './course-communications';
import { BaseUser } from '../models/my-account';
import { SkillTag, SkillTagging } from '../models/skill-tag';
import { Rating } from './skills-feedback';

export interface CreatePracticeActivityAndScenarioRequest {
  activity: {
    videoPracticeOption: VideoPracticeOption;
    maxTries?: number;
    scenario: {
      title: string;
      textPrompt?: string;
      videoPrompt?: NovoEdFile;
      recordingLimit: number;
      submissionPrivacySetting: string;
      scenarioReusabilitySetting: string;
    }
  }
}

export interface GetVideoPracticeSubmissionsRequest {
  catalogId: string;
  activityId: number;
  scenarioId: number;
}

export interface LoadGallery {
  scenarioId: number;
  loading: boolean;
}

/* Practice Room Models */
export interface FetchFlyoutScenariosRequest {
  page: number,
}

export interface FetchFlyoutScenariosResponse {
  resultsCount: number,
  pageCount: number,
  scenarios: VideoPracticeScenario[],
  perPage: number,
}

export interface PracticeRoomStateParams {
  scenarioId?: number,
  selectedTab?: string,
  selectedSubmissionTab?: string,
  userId?: number,
  submissionId?: number,
  newNotification?: {
    submissionId?: number;
    commentId?: number;
  };
  userInteractedWith?: boolean;
  feedbackBy?: number;
}

export interface SetFilteredCommentsParams {
  submissionId: number,
  filteredComments: number[],
}

export interface FetchScenarioRequest {
  scenarioId: number,
}

export interface FetchScenarioResponse extends VideoPracticeScenario {

}

export interface EditScenarioRequest {
  scenario: Partial<VideoPracticeScenario>;
}

export interface SearchScenariosRequest {
  searchQuery?: string;
  pageIndex?: number;
  callback: (res: SearchScenariosResponse) => void;
}

export interface SearchScenariosResponse {
  pageCount: number;
  resultsCount: number;
  scenarios: VideoPracticeScenario[];
}

export interface FetchVideoPracticeSubmissionsRequest {
  miniGallery?: boolean;
  scenarioId: number
  activityId?: number
  catalogId?: string
  page?: number
  tab?: PracticeRoomTab
  option?: SortOrFilterOption
  userId?: number
}
export interface FetchVideoPracticeSubmissionsResponse {
  resultsCount: number
  pageCount: number
  submissions: VideoPracticeSubmission[]
  perPage: number
  totalCommentsCount?: number
  totalFeaturedCount?: number
  totalLikesCount?: number
  totalViews: number
  remainingSubmissions?: number
}

export const SkillTagSchema = new schema.Entity<SkillTag>('skillTags');
export const SkillTagListSchema = new schema.Array(SkillTagSchema);

export const SkillTaggingSchema = new schema.Entity<SkillTagging>('skillTaggings', {
  skillTagId: SkillTagSchema,
}, { processStrategy: (value) => omit({
  ...value,
  ...(value.skillTag && { skillTagId: value.skillTag }),
}, 'skillTag'),
});
export const SkillTaggingListSchema = new schema.Array(SkillTaggingSchema);
export const PracticeScenarioSchema = new schema.Entity<VideoPracticeScenario>('practiceScenarios', {
  skillTagIds: SkillTagListSchema,
  skillTaggingIds: SkillTaggingListSchema,
}, {
  processStrategy: (value) => omit({
    ...value,
    ...(value.skillTags && { skillTagIds: value.skillTags }),
    ...(value.skillTaggings && { skillTaggingIds: value.skillTaggings }),
  }, 'skillTags', 'skillTaggings'),
});
export const PracticeScenarioListSchema = new schema.Array(PracticeScenarioSchema);

export const PracticeSubmissionSchema = new schema.Entity<VideoPracticeSubmission>('practiceSubmissions');
export const PracticeSubmissionListSchema = new schema.Array(PracticeSubmissionSchema);

export const PracticeActivitySchema = new schema.Entity<VideoPracticeActivity>('practiceActivities', {
  scenarioId: PracticeScenarioSchema,
  submissionId: PracticeSubmissionSchema,
  communications: CommunicationListSchema,
}, {
  processStrategy: (value) => omit({
    ...value,
    ...(value.scenario && { scenarioId: value.scenario }),
    ...(value.videoPracticeSubmission && { submissionId: value.videoPracticeSubmission }),
  }, ['scenario', 'videoPracticeSubmission']),
});

export const SkillRatingSchema = new schema.Entity<Rating>('skillRatings');
export const SkillRatingListSchema = new schema.Array(SkillRatingSchema);

export const MentionableUsersSchema = new schema.Entity<BaseUser>('mentionableUsers');
export const MentionableUsersListSchema = new schema.Array(MentionableUsersSchema);

export const PracticeActivitiesAvailableSchema = new schema.Array(new schema.Entity<PracticeActivitiesAvailable>('practiceActivities'));

export interface PracticeScenariosEntities {
  practiceScenarios: VideoPracticeScenariosNormalized,
}

export interface PracticeSubmissionEntities {
  practiceSubmissions: VideoPracticeSubmissionsNormalized,
}

export interface PracticeSubmissionActionParams {
  scenarioId: number
  submissionId: number
  practiceFeedbackCriteriaId?: number
}

export interface VoteUpResponse {
  numLikes: number
}

export interface DeleteCommentParams {
  submissionId: number
  commentId: number
  isUserFirstReview?: boolean
  practiceFeedbackCriteriaId?: number
}

export type Post = {
  body?: string,
  videoTimestamp?: number
  video?: FileUpload
};
export interface PostCommentParams {
  submissionId: number
  post: Post
  practiceFeedbackCriteriaId?: number
}

export interface UpdateCommentParams {
  commentId: number
  post: Post
  practiceFeedbackCriteriaId?: number
}

export interface FetchPracticeCommentsResponse {
  posts: PracticeSubmissionComment[]
  total: number
}

export interface PostVideoHasViewedResponse {
  numViews: number
}

export const FetchPracticeCommentsSchema = new schema.Array(
  new schema.Entity<PracticeSubmissionComment>('comments', {}, {
    processStrategy: (value) => ({
      ...value,
      likers: [],
    }),
  }),
);

/* End Practice Room Models */
export interface StartVideoPracticeSubmissionRequest {
  scenarioId: number;
  catalogId: string;
  activityId: number;
}

export interface StartVideoPracticeSubmissionResponse {
  id: number;
}

export interface CompleteVideoPracticeSubmissionRequest {
  scenarioId: number;
  activityId: number;
  submissionId: number;
  videoFile: UploadedFile;
}

export interface LeaveVideoPracticeSubmission {
  activityId: number;
}

export interface ToggleFeaturePracticeSubmissionRequest {
  scenarioId: number
  submissionId: number
  featured: boolean
  fullName: string
}

export interface DeletePracticeSubmissionRequest {
  scenarioId: number
  submissionId: number
}

export interface UsersSubmissionsRequest {
  scenarioId: number
  userIds: number[]
}

export interface OrgProfileSubmissionsRequest {
  userId: number
  page?: number
}

export interface PracticeActivitiesAvailable {
  title: string,
  id: number,
  releasesDate: string,
  hardDeadline: boolean,
  deadline?: string,
}
