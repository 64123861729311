import { css } from '@emotion/react';
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { first, last } from 'underscore';

import { useAppDispatch } from 'redux/store';
import { AngularServicesContext } from 'react-app';

import { primary, gray6, gray7, warning, hexToRgbaString } from 'styles/global_defaults/colors';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { notHandheld, handheld } from 'styles/global_defaults/media-queries';

import { fetchComments } from 'redux/actions/comments';
import { getTeamMentions } from 'redux/actions/teams';
import getMentionsForTeam from 'redux/selectors/team';
import { getCourseAliases } from 'redux/selectors/course';
import { BaseUser } from 'redux/schemas/models/my-account';

import ClickableContainer from 'components/clickable-container';
import PostBody from './post-body';
import PostTabs from './post-tabs';
import TeamProfilePanel from './team-profile-panel';
import DiscussionComment from './discussion-comment';
import DiscussionCommentForm from './discussion-comment-form';

import { TeamDiscussionContext } from './team-discussion';
import NoTeam from './no-team';

const defaultPageSize = 15;

const PostContainer = () => {
  const styles = css`
    margin-top: ${standardSpacing}px;

    .post-body-footer {
      .discussion-tabs,
      .load-previous-comments,
      .load-next-comments {
        border-left: 1px solid ${gray6};
        border-right: 1px solid ${gray6};

        ${notHandheld(css`
          margin-left: 90px;
        `)};
      }

      .load-previous-comments,
      .load-next-comments {
        background: ${hexToRgbaString(gray6, 0.6)};
        display: flex;
        color: ${primary};
        padding-left: ${standardSpacing}px;
        padding-right: ${standardSpacing}px;
        border-radius: unset;
      }

      .load-previous-comments {
        padding-bottom: ${standardSpacing}px;
      }

      .load-next-comments {
        padding-top: ${standardSpacing}px;
      }
    }

    .no-team {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: ${gray7};
      padding: ${standardSpacing}px;
      border-left: 1px solid ${gray6};
      border-right: 1px solid ${gray6};
      border-bottom: 1px solid ${gray6};
      border-top: 1px solid ${warning};

      ${notHandheld(css`
        margin-left: 90px;
      `)};

      ${handheld(css`
        flex-direction: column;

        button {
          margin-top: ${standardSpacing}px;
        }
      `)};
    }
  `;

  const dispatch = useAppDispatch();

  const { postId } = useContext(TeamDiscussionContext);
  const { $state } = useContext(AngularServicesContext);

  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const post = useSelector((state) => state.models.posts[postId]);
  const { team } = post;
  const teamId = team?.id;
  const teamMentionables = useSelector((state) => getMentionsForTeam(state, teamId));

  const [showComments, setShowComments] = useState(true);
  const [showFroala, setShowFroala] = useState(0);
  const [activeTab, setActiveTab] = useState('date');

  useEffect(() => {
    if (!teamMentionables.length && teamId) {
      dispatch(getTeamMentions({ catalogId, teamId }));
    }
  }, [teamMentionables.length, catalogId, teamId, dispatch]);

  const aliases = useSelector((state) => getCourseAliases(state));
  const mentionableMembers: BaseUser[] = useMemo(() => [{
    id: 0,
    firstName: aliases.teamAliases.teamAlias,
    lastName: '',
  }, ...teamMentionables], [teamMentionables, aliases]);

  /* Load 15 comments by default */
  useEffect(() => {
    if (!post.commentsFetched && post.released && teamId) {
      dispatch(fetchComments({
        catalogId,
        postId,
        pageSize: defaultPageSize,
        vieweeLastActivity: post.vieweeLastActivity,
      }));
    }
  }, [dispatch, catalogId, post, $state, postId, teamId]);

  const onClickCommentsText = () => {
    setShowFroala(0);
    setShowComments(show => !show);
  };

  const onClickCommentsIcon = () => {
    setShowComments(true);
    onClickJoin();
  };

  const onLoadPreviousComments = () => {
    dispatch(fetchComments({
      catalogId,
      postId: post.id,
      beforeId: first(post.commentIds),
      vieweeLastActivity: post.vieweeLastActivity,
      pageSize: defaultPageSize,
    }));
  };

  const onLoadNextComments = () => {
    dispatch(fetchComments({
      catalogId,
      postId: post.id,
      afterId: last(post.commentIds),
      vieweeLastActivity: post.vieweeLastActivity,
      order: activeTab === 'likes' ? 'likes' : undefined,
    }));
  };

  const onClickJoin = () => {
    setShowFroala(f => f + 1);
  };

  const onCancel = () => {
    setShowFroala(0);
  };

  return (
    <div css={styles}>
      <PostBody />
      {(teamId && post.released) ? (
        <React.Fragment>
          <TeamProfilePanel
            commentsExpanded={showComments}
            onClickJoin={onClickJoin}
            onClickCommentsText={onClickCommentsText}
            onClickCommentsIcon={onClickCommentsIcon}
          />
          {showComments && (
            <div className='post-body-footer'>
              <div className='discussion-tabs'>
                <PostTabs activeTab={activeTab} setActiveTab={setActiveTab} />
              </div>
              {post.additionalCommentsBeforeCount > 0 && (
                <ClickableContainer className='load-previous-comments btn btn-link label' onClick={onLoadPreviousComments}>
                  {post.additionalNewCommentsBeforeCount > 0
                    ? t.DISCUSSIONS.LOAD_PREVIOUS_COMMENTS_WITH_NEW(post.additionalCommentsBeforeCount, post.additionalNewCommentsBeforeCount)
                    : t.DISCUSSIONS.LOAD_PREVIOUS_COMMENTS(post.additionalCommentsBeforeCount)}
                </ClickableContainer>
              )}
              {(post?.commentIds || []).map(commentId => (
                <DiscussionComment
                  key={commentId}
                  commentId={commentId}
                  mentionableMembers={mentionableMembers}
                />
              ))}
              {post.additionalCommentsAfterCount > 0 && (
                <ClickableContainer className='load-next-comments btn btn-link label' onClick={onLoadNextComments}>
                  {post.additionalNewCommentsAfterCount > 0
                    ? t.DISCUSSIONS.LOAD_MORE_COMMENTS_WITH_NEW(post.additionalCommentsBeforeCount, post.additionalNewCommentsAfterCount)
                    : t.DISCUSSIONS.LOAD_MORE_COMMENTS(post.additionalCommentsAfterCount)}
                </ClickableContainer>
              )}
            </div>
          )}
          <DiscussionCommentForm
            showFroala={showFroala}
            mentionableMembers={mentionableMembers}
            onCancel={onCancel}
          />
        </React.Fragment>
      ) : <NoTeam />}
    </div>
  );
};

export default PostContainer;
