import { css } from '@emotion/react';
import { AdminToolsPaths } from 'lhs/services/admin-tools-flyout-config';
import React from 'react';
import { AngularContext } from 'react-app';
import { useSelector } from 'react-redux';
import useAngularExpression from 'shared/hooks/use-angular-expression';
import { black, gray2, gray6, warning } from 'styles/global_defaults/colors';
import {
  doubleSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';

export type FlyoutMenuItemProps = {
  label: string;
  icon: string;
  path: AdminToolsPaths;
  dataQa: string;
  customOnClick? : () => void;
  isHighlightState?($state): boolean;
};

const FlyoutMenuItem = (props: FlyoutMenuItemProps) => {
  const { label, icon, path, dataQa, customOnClick, isHighlightState } = props;
  const { injectServices } = React.useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const institutionId = useSelector(state => state.app.currentInstitutionId);
  const currentStateName = useAngularExpression(() => $state.current.name);

  const stylesBase = css`
    padding: ${standardSpacing}px;
    padding-left: ${doubleSpacing}px;
    color: ${black};
    background: none;
    border: none;
    border-bottom: 1px solid ${gray6};

    &:hover {
      background: ${gray6};
    }

    & > div {
      gap: ${standardSpacing}px;
    }

    & > .icon-check {
      color: ${warning};
    }

    .main-icon {
      color: ${gray2};
    }
  `;

  return (
    <button
      css={stylesBase}
      type='button'
      className={`
        w-100 d-flex align-items-center justify-content-between
      `}
      onClick={customOnClick ? () => customOnClick() : () => $state.go(path, { institutionId })}
      aria-label={label}
      data-qa={dataQa}
    >
      <div className='d-flex align-items-center mr-2'>
        <i className={`icon-small icon-${icon} main-icon`} data-qa={`${dataQa}_ICON`} />
        {label}
      </div>
      {(path === currentStateName || isHighlightState?.($state))
        && <i className='icon-small icon-check' />}
    </button>
  );
};

export default FlyoutMenuItem;
