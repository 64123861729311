/* @ngInject */
export default function TimedQuizzesManager(

  _,
  QuizModel,
  QuizzesResources,
  ExercisesManager,
  $stateParams,
  $state,
) {
  const manager = {
    // functions
    getQuiz,
    setQuiz,
    setCurrentSubmission,
    save,
    submit,
    resetCurrentQuiz,

    // data:
    currentQuiz: null,
  };

  function getQuiz(catalogId, questionSetId, windowId, actionState, adminPreview) {
    return QuizModel.getTimedQuiz(catalogId, questionSetId, windowId, actionState, adminPreview).then((quiz) => {
      manager.currentQuiz = quiz;
      manager.currentQuiz.questionSetSubmissionId = quiz.id; // response from api start_exam
    });
  }

  function setQuiz(catalogId, questionSetId, windowId, actionState, quiz) {
    manager.currentQuiz = quiz;
    manager.currentQuiz.questionSetSubmissionId = quiz.id; // response from api start_exam
  }

  function resetCurrentQuiz() {
    manager.currentQuiz = null;
  }

  function setCurrentSubmission(submission) {
    manager.currentQuiz.setResponses(submission.responses);
  }

  function save(windowId) {
    return manager.currentQuiz.persistTimedQuiz(manager.currentQuiz.questionSetSubmissionId, windowId, true)
      .then((submission) => submission);
  }

  function submit(windowId) {
    return manager.currentQuiz.persistTimedQuiz(manager.currentQuiz.questionSetSubmissionId, windowId, false)
      .then((submission) => {
        manager.currentQuiz.submission = submission;
        manager.currentQuiz.progress = 'completed';

        return submission;
      });
  }

  return manager;
}
