/* @ngInject */
export default function DiscussionsSortFilterController(
  DiscussionsManager,
  CurrentUserManager,
  _,
) {
  const vm = this;
  vm.manager = DiscussionsManager;
  vm.currentUserManager = CurrentUserManager;
}
