import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'redux/store';
import { RootState } from 'redux/schemas';
import { resetShowPortableCompletionPanel } from 'redux/actions/timeline';

import NvIcon from 'shared/components/nv-icon';
import ClickableContainer from 'components/clickable-container';
import ApiWrapper from '../api-wrapper';
import HeaderGauges from './header-gauges';
import PortableCompletionPanel from '../portable-completion-panel';
import useCompletionCriteria from '../use-completion-criteria';

const CompletionCriteriaHeader = () => {
  const dispatch = useAppDispatch();
  const isPanelShown = useSelector((state: RootState) => state.app.timeline.showPortableCompletionPanel);

  const {
    automatedCompletionsEnabled,
    enabledFactorCount,
    receivedFactorCount,
  } = useCompletionCriteria();

  useEffect(() => () => {
    dispatch(resetShowPortableCompletionPanel());
  }, [dispatch]);

  if (!automatedCompletionsEnabled) {
    return null;
  }

  return (
    <ApiWrapper>
      {enabledFactorCount > 0 && receivedFactorCount > 0 && (
        <React.Fragment>
          {isPanelShown
            ? (
              <ClickableContainer className='text-primary px-6' onClick={() => dispatch(resetShowPortableCompletionPanel())}>
                <NvIcon icon='close' size='smallest' />
              </ClickableContainer>
            )
            : <HeaderGauges />}
          {isPanelShown && <PortableCompletionPanel />}
        </React.Fragment>
      )}
    </ApiWrapper>
  );
};

export default CompletionCriteriaHeader;
