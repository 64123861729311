export default [
  function () {
    return {
      restrict: 'A',
      link(scope, elem, attr) {
        if (attr.maxHeight) {
          elem.css('max-height', attr.maxHeight);
          elem.addClass('scroll-contents');
        }
      },
    };
  },
];
