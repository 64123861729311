import {
  useEffect,
  useState,
} from 'react';
import * as yup from 'yup';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import { css } from '@emotion/core';

// Schemas
import { Recipient } from 'redux/schemas/models/course-communication';

// Selectors
import { getCourseAliases } from 'redux/selectors/course';

// Hooks
import { useFormContext } from 'react-hook-form';

// Components
import CompletedJourney from 'communications/course_communications/components/communication-modal/filters/completed-journey';
import NumberInput from 'communications/course_communications/components/communication-modal/filters/number-input';
import NeverActive from 'communications/course_communications/components/communication-modal/filters/never-active';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import OneOption from 'communications/course_communications/components/communication-modal/filters/one-option';
import NvPopover from 'shared/components/nv-popover';
import ValidationErrorMessage from 'shared/components/inputs/validation-error-message';
import baseStyles from 'communications/course_communications/components/communication-modal/filters/base-styles';
import { doubleSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import CommunicationForm from '../../../course_communications/components/communication-modal/communication-form';

const generalStyle = css`
  label {
    margin-bottom: unset;
  }
`;

const JourneyScheduledEmail = () => {
  const aliases = useSelector((state) => getCourseAliases(state));

  const validationSchema = yup.object().shape({
    xDays: yup.number()
      .typeError(t.VALIDATION.NUMBER())
      .required(t.VALIDATION.REQUIRED())
      .min(1, t.VALIDATION.MIN('1')),
    hasNeverActiveChecked: yup.boolean().nullable(),
    notEnrolledToAnyCourseChecked: yup.boolean(),
    daysInactiveChecked: yup.boolean(),
    hasCompletedJourneyChecked: yup.boolean(),
    daysInactive: yup.number().nullable()
      .when('daysInactiveChecked', {
        is: true,
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      }),
    hasCompletedJourney: yup.object().nullable()
      .when('hasCompletedJourneyChecked', {
        is: true,
        then: yup.object().required(t.VALIDATION.REQUIRED()),
      }),
  });

  return (
    <div className='journey-automated-email' css={generalStyle}>
      <CommunicationForm validationSchema={validationSchema}>
        <div className='modal-body-title page-title text-center pb-4 mt-2'>
          {t.JOURNEY_COMMUNICATIONS.AUTOMATED.TITLE.STEP_1()}
        </div>
        <div className='send-to py-5 text-large-body'>
          {t.JOURNEY_COMMUNICATIONS.AUTOMATED.SEND_TO.LABEL()}
        </div>
        <SendOn />

        <div className='bold text-large-regular mt-5'>
          <div className='text-warning inline-block'>{t.JOURNEY_COMMUNICATIONS.FILTERS.OPTIONAL()}</div>
          <div className='ml-2 inline-block'>{t.JOURNEY_COMMUNICATIONS.FILTERS.ADD_MORE(aliases.learnersAliases)}</div>
        </div>
        <div className='text-medium bold my-1'>{t.JOURNEY_COMMUNICATIONS.FILTERS.SCHEDULED_DESCRIPTION()}</div>
        <div className='text-medium gray-3 mt-4 mb-1'>{t.JOURNEY_COMMUNICATIONS.FILTERS.MULTIPLE_SELECTIONS()}</div>

        {/* Has logged-in/not-logged-in */}
        <NeverActive
          checkboxName='hasNeverActiveChecked'
          disableOn={[
            'notEnrolledToAnyCourseChecked',
            'daysInactiveChecked',
            'hasCompletedJourneyChecked',
          ]}
        />

        {/* Have not enrolled in any course in the journey */}
        <OneOption
          checkboxName='notEnrolledToAnyCourseChecked'
          label={t.JOURNEY_COMMUNICATIONS.FILTERS.NOT_ENROLLED_IN_ANY_COURSE.LABEL()}
          disableOn={[
            'hasNeverActiveChecked',
            'daysInactiveChecked',
            'hasCompletedJourneyChecked',
          ]}
        />

        {/* Has been inactive for more than X days */}
        <NumberInput
          checkboxName='daysInactiveChecked'
          name='daysInactive'
          defaultLabel={t.JOURNEY_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.INACTIVE.DEFAULT()}
          inputLabel={t.JOURNEY_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.INACTIVE.LABEL('INPUT')}
          note={t.JOURNEY_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.INACTIVE.NOTE()}
          ariaLabel={t.JOURNEY_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.INACTIVE.ARIA_LABEL()}
          disableOn={[
            'hasNeverActiveChecked',
            'notEnrolledToAnyCourseChecked',
          ]}
        />

        <CompletedJourney
          checkboxName='hasCompletedJourneyChecked'
          name='hasCompletedJourney'
          disableOn={[
            'hasNeverActiveChecked',
            'notEnrolledToAnyCourseChecked',
          ]}
        />
      </CommunicationForm>
    </div>
  );
};


const SendOn = () => {
  const [showIsNotValidNumber, setShowIsNotValidNumber] = useState(false);
  const [prefix, suffix] = t.JOURNEY_COMMUNICATIONS.AUTOMATED.SEND_ON.LABEL('INPUT').split('INPUT');
  const { setValue, watch } = useFormContext();
  const styles = css`
    &.send-on {
      ${baseStyles};
      .bs4-input-group{
        height: ${doubleSpacing}px;
        width: ${doubleSpacing * 2}px;
        margin: 0px ${quarterSpacing}px;
        input {
          height: ${doubleSpacing}px;
        }
      }
    }
  `;

  useEffect(() => {
    const currentValue = watch('xDays');
    if (!currentValue) {
      setValue('xDays', '');
    }
    setValue('recipients', [Recipient.TRIGGER_LEARNER]);
  }, []);

  const onChange = (event) => {
    const isValid = !Number(event.target.value) || Number(event.target.value) <= 0;
    setShowIsNotValidNumber(isValid);
  };
  return (
    <div className='send-on on py-5 text-primary text-large-body' css={styles}>
      <b>{prefix}</b>
      <NvTextInput
        required
        withForm
        type='number'
        autoComplete='off'
        name='xDays'
        ariaLabel={t.JOURNEY_COMMUNICATIONS.AUTOMATED.SEND_ON.ARIA_LABEL()}
        inputClassName='text-large-regular'
        onChange={onChange}
      />
      <b>{suffix}</b>
    </div>
  );
};

export default JourneyScheduledEmail;
