import { css } from '@emotion/react';
import React, { useContext, useState, useEffect } from 'react';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import axios, { AxiosResponse } from 'axios';

// Schemas
import { RootState } from 'redux/schemas';
import { Communication, CommunicationType, ViewMode } from 'redux/schemas/models/course-communication';

// Contexts
import { CommunicationAction, CommunicationDispatch, TriggerType } from 'communications/course_communications/contexts/communication-context';

// Actions
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { disableWelcomeEmail } from 'redux/actions/course-communications';

// Selectors
import { getCurrentCourseWelcomeEmail } from 'redux/selectors/course-communications';

// Styles
import { primary } from 'styles/global_defaults/colors';
import { quarterSpacing } from 'styles/global_defaults/scaffolding';

// Components
import { NvTooltip } from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import NvDropdown, { NvDropdownAlign, NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import CommunicationRow from './communication-item/communication-row';

const TARGET_AREA_PADDING = 20;
const styles = css`
  cursor: pointer;

  &:hover .subject {
    color: ${primary};
  }

  .actions-dropdown {
    position: absolute;
    top: 0;
    right: 0;
    color: ${primary};
    cursor: pointer;
    font-style: normal;

    .options-btn {
      padding: 0 ${TARGET_AREA_PADDING}px ${TARGET_AREA_PADDING}px;
      margin-top: ${TARGET_AREA_PADDING}px;
    }
  }
  .placeholder-bar {
    margin: ${quarterSpacing}px 0px;
  }
`;

const WelcomeEmailRow = () => {
  const dispatch = useAppDispatch();
  const welcomeEmail = useSelector<RootState, Communication>((state) => getCurrentCourseWelcomeEmail(state));
  const { dispatch: communicationDispatch } = useContext(CommunicationDispatch);
  const catalogId = useSelector((state) => state.app.currentCatalogId);

  const [welcomeEmailSubject, setWelcomeEmailSubject] = useState('');

  const openModal = (triggerType: TriggerType, viewMode: ViewMode) => {
    communicationDispatch({
      type: CommunicationAction.SHOW_MODAL,
      triggerType,
      communicationType: CommunicationType.COURSE_WELCOME_EMAIL,
      viewMode,
    });
  };

  const dropdownItems: NvDropdownOption[] = [{
    type: 'text',
    text: t.COURSE_COMMUNICATIONS.ACTIONS_DROPDOWN.EDIT(),
    tooltip: {
      text: t.COURSE_COMMUNICATIONS.ACTIONS_DROPDOWN.EDIT(),
      placement: 'top-end',
    },
    callback: () => openModal(TriggerType.EDIT, ViewMode.FORM_VIEW),
  },
  {
    type: 'text',
    text: t.COURSE_COMMUNICATIONS.ACTIONS_DROPDOWN.VIEW(),
    tooltip: {
      text: t.COURSE_COMMUNICATIONS.ACTIONS_DROPDOWN.VIEW(),
      placement: 'top-end',
    },
    callback: () => openModal(TriggerType.VIEW, ViewMode.EMAIL_PREVIEW),
  },
  { type: 'divider' },
  {
    type: 'text',
    text: t.COURSE_COMMUNICATIONS.ACTIONS_DROPDOWN.DISABLE(),
    class: 'text-danger',
    tooltip: {
      text: t.COURSE_COMMUNICATIONS.ACTIONS_DROPDOWN.DISABLE(),
      placement: 'top-end',
    },
    callback: () => dispatch(openConfirmationDialog({
      onConfirm: () => dispatch(disableWelcomeEmail({ courseId: welcomeEmail.courseId })),
      cancelText: t.FORM.CANCEL(),
      confirmText: t.FORM.YES_SURE(),
      title: t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.DISABLE.WARNING_TITLE(),
      bodyText: t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.DISABLE.WARNING_TEXT(),
    })),
  }];

  useEffect(() => {
    if (welcomeEmail?.communication?.subject && welcomeEmail?.communication?.body) {
      axios.post(`${catalogId}/course_communications/email_preview.json`, {
        communication: {
          subject: welcomeEmail?.communication?.subject,
          body: welcomeEmail?.communication?.body,
        },
        communicationType: CommunicationType.COURSE_WELCOME_EMAIL,
      })
        .then((res: AxiosResponse<{ result: { body: string, subject: string, from: string } }>) => {
          const { data } = res;
          setWelcomeEmailSubject(data.result.subject);
        })
        .catch(() => {
          dispatch(addAlertMessage({
            type: AlertMessageType.ERROR,
            header: t.FORM.ERROR(),
            message: t.COURSE_COMMUNICATIONS.SAMPLE_EMAIL.ERROR(),
          }));
        });
    }
  }, [
    catalogId,
    dispatch,
    welcomeEmail?.communication?.body,
    welcomeEmail?.communication?.subject,
  ]);

  return (
    <div css={styles} className='position-relative bg-info'>
      {welcomeEmail && (
      <NvTooltip
        text={t.COURSE_COMMUNICATIONS.TOOLTIP.ITEM()}
        placement='top'
      >
        <div onClick={() => openModal(TriggerType.VIEW, ViewMode.EMAIL_PREVIEW)}>
          <CommunicationRow>
            <div className='w-100'>
              <div className='content-wrapper'>
                <div className='text-warning'>
                  <NvIcon size='xss-smallest' icon='messages' />
                </div>
                <div className='content text-medium'>
                  <span className='title subject font-weight-bolder'>
                    { welcomeEmailSubject || <div className='loading placeholder-bar w-100 py-1' /> }
                  </span>
                </div>
              </div>
            </div>
            <a
              className='actions-dropdown'
              onClick={(e) => e.stopPropagation()}
            >
              <NvDropdown
                buttonStyle={NvDropdownButtonStyle.CUSTOM}
                items={dropdownItems}
                align={NvDropdownAlign.RIGHT}
                offset={-TARGET_AREA_PADDING}
                customTarget={() => (
                  <NvTooltip
                    text={t.COURSE_COMMUNICATIONS.TOOLTIP.ACTIONS()}
                    placement='top'
                  >
                    <div className='options-btn'>
                      <NvIcon size='xss-smallest' icon='edit' />
                    </div>
                  </NvTooltip>
                )}
              />
            </a>
          </CommunicationRow>
          <CommunicationRow>
            <div className='content-wrapper'>
              <div className='text-warning'>
                <NvIcon size='xss-smallest' icon='calendar' />
              </div>
              <div className='content text-medium'>
                <span className='title'>
                  { welcomeEmailSubject
                    ? t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.TRIGGER()
                    : <div className='loading placeholder-bar w-100 py-1' />}
                </span>
              </div>
            </div>
          </CommunicationRow>
        </div>
      </NvTooltip>
      )}
    </div>
  );
};

export default WelcomeEmailRow;


