import { css } from '@emotion/react';

import { halfSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { gray3, gray6, gray7 } from 'styles/global_defaults/colors';
import { textSmallFontSize } from 'styles/global_defaults/fonts';

const LoadingPlaceholder = () => {
  const styles = css`
    margin: 80px;

    @keyframes shineAnimation {
      0% {
        background-position: 0% 50%;
      }

      100% {
        background-position: -100% 50%;
      }
    }

    .loading {
      background: linear-gradient(90deg, ${gray6} 35%, ${gray7} 45%, ${gray6} 55%);
      background-size: 600% 600%;
      animation: shineAnimation 1.5s infinite;
    }

    .placeholder-bar {
      width: 100%;
      height: ${halfSpacing}px;
      margin-bottom: ${halfSpacing}px;
      margin-left: 0;
    }

    .first {
      width: 65%;
    }

    .second {
      width: 70%;
    }

    .third {
      width: 60%;
    }
  `;


  return (
    <div css={styles}>
      <div className='loading placeholder-bar first' />
      <div className='loading placeholder-bar second' />
      <div className='loading placeholder-bar third' />
    </div>
  );
};

export default LoadingPlaceholder;
