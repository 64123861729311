import { css } from '@emotion/react';
import { Course } from 'redux/schemas/models/course';
import { Institution } from 'redux/schemas/models/institution';
import { Team } from 'redux/schemas/models/team';
import { primary } from 'styles/global_defaults/colors';
import { useContext } from 'react';
import { AngularServicesContext } from 'react-app';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { MyAccountTeam } from 'redux/schemas/models/my-account';
import { NvAvatar, NvAvatarDefaultBkg, AvatarSize } from './nv-avatar';

type NvTeamAvatarProps = {
  course: Course,
  team: Team | MyAccountTeam,
  institution: Institution,
  size?: AvatarSize,
  center?: boolean,
};

/**
 * NovoEd-customized wrapper around a react-bootstrap Dropdown component.
 */
export const NvTeamAvatar = (props: NvTeamAvatarProps) => {
  const styles = css`
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    ${props.center ? 'flex-direction: column;' : ''};
    ${props.center ? 'white-space: normal;' : ''};

    &:hover {
      cursor: pointer;
    }

    &:hover .team-name {
      color: ${primary};
    }

    .team-name {
      // TODO: needs to use css-grid style
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
      padding-top: ${halfSpacing}px;
      padding-bottom: ${halfSpacing}px;
      text-align: center;
      max-width: 100%;
    }
  `;

  const { $state } = useContext(AngularServicesContext);

  const avatarColor = props.course.headerColor ?? props.institution.brandColor;
  // TODO: We need to figure out how to apply that to the entire avatar (if possible), and then
  // refactor this and the NvCourseAvatar's linking into NvAvatar

  return (
    <a className='nv-team-avatar' css={styles} href={$state?.href('team-workspace', { catalogId: props.course.catalogId, teamId: props.team.id })}>
      <NvAvatar
        imageUrl={props.team.profilePicture}
        size={props.size || 'sm'}
        borderType='square'
        defaultBkg={{ type: NvAvatarDefaultBkg.SOLID_COLOR, color: avatarColor }}
      />
      {/* TODO: Move into NvAvatar? */}
      <div className={`team-name ${props.center ? '' : 'ml-3'}`}>{props.team.name}</div>
    </a>
  );
};

export default NvTeamAvatar;
