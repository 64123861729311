import { Omit } from 'utility-types';

import { InputType } from 'components/nv-click-to-edit';
import { FileUpload } from 'redux/schemas/models/file-uploads';

export enum MigratedFields {
  WORK = 'employer',
  JOB_TITLE = 'job_title',
  EDUCATION = 'institution',
}

type ViewOptions = {
  icon: string | null,
  iconColor: string | null,
};

export type OrgProfileField = {
  isHidden: boolean,
  picture: FileUpload,
  isIntegrated: boolean,
  isCsvManaged: boolean,
  isDisabled: boolean,
  selectOptions: string[],
  viewOptions: ViewOptions,
  inputType: InputType | null,
  migratedField: MigratedFields | null,
  /**
   * NOTE: Backend calls the following properties differently at different endpoints
   * id === questionId
   * name === question
   */
  id?: number,
  name?: string,
  question?: string, // Same as name
  questionId?: number, // Same as id
  /**
   * For Org Level Profile
   */
  answer?: string, // Requester user field value
  /**
   * For Profile Completion Activity
   */
  isRequired?: boolean,
};

export interface OrgProfileFieldsNormalized { [id: string]: OrgProfileField }

export type CourseProfileQuestion = {
  id: number,
  answer: string,
  question: string,
  formType: InputType,
  selectOptions: string[],
  /**
   * For Profile Completion Activity
   */
  isRequired?: boolean,
};

export interface CourseProfileQuestionsNormalized { [id: string]: CourseProfileQuestion }

// Type for frontend purposes (Org Profile settings modal)
export type FormOrgProfileField = Partial<Omit<OrgProfileField, 'selectOptions'> & {
  isNew: boolean,
  selectOptions: string,
}>;
