/* @ngInject */
export default function NvPrivacyLink(
  $uibModal,
) {
  return {
    restrict: 'A',
    scope: {
      institution: '=?',
      icon: '=?',
      privacyLinkTranslateKey: '=?',
      endLinkWith: '=?',
      fontColor: '=?',
    },
    templateUrl: 'shared/templates/nv-privacy-link.html',
    controller: function ctrl($scope, $element, $attrs) {
'ngInject';
      const vm = this;

      vm.showPrivacyPolicyModal = showPrivacyPolicyModal;
      vm.color = !vm.institution ? 'white' : vm.fontColor;

      function showPrivacyPolicyModal() {
        const modal = $uibModal.open({
          templateUrl: 'shared/templates/privacy-policy-modal.html',
          controller: 'PrivacyPolicyModalCtrl as vm',
          resolve: {
            currentInstitution: vm.institution,
          },
        });
      }
    },
    controllerAs: 'vm',
    bindToController: true,
  };
}
