import React, { useEffect } from 'react';
import t from 'react-translate';

// Types
import { SessionTypes } from 'redux/schemas/models/live-event';

// Styles
import { css } from '@emotion/react';

// Components
import MSTeamsSettings from './ms-teams-settings';
import ZoomSettings from './zoom-settings';

const styles = css`
  border: 1px solid #e5e5e5;
`;

const getKeyForSessionType = (sessionType: SessionTypes) => (
  Object.keys(SessionTypes).find((key) => SessionTypes[key] === sessionType)
);

type LiveEventSettingsProps = {
  sessionType: SessionTypes
  msTeamsEmail: string
  disabled?: boolean
};

const LiveEventSettings = (props: LiveEventSettingsProps) => {
  const {
    sessionType,
    msTeamsEmail,
    disabled,
  } = props;
  const [title, setTitle] = React.useState('');

  useEffect(() => {
    const sessionTypeKey = getKeyForSessionType(sessionType);
    setTitle(t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.SETTINGS[sessionTypeKey]());
  }, [sessionType]);

  return (
    <div css={styles} className='p-4'>
      <div className='text-regular bold mb-2'>{title}</div>
      {sessionType === SessionTypes.ZOOM && <ZoomSettings disabled={disabled} />}
      {sessionType === SessionTypes.MS_TEAMS && <MSTeamsSettings email={msTeamsEmail} disabled={disabled} />}
    </div>
  );
};

export default LiveEventSettings;
