import { createContext, MutableRefObject } from 'react';

export type FilesToUploadMapping = Record<string, File[]>;

/** This context object should be used sparingly to share Lecture Page specific data across all components & page elements
 * that are not appropriate for Redux. Note that this context is specific only to the current lecture page; it should *never*
 * be used to store values for other lecture pages */
export type LecturePageContextType = {
  lecturePageModelRef: MutableRefObject<any>

  angularComponentsModelsRef: MutableRefObject<{ [id: string]: any }>
  /** A mapping of lectureComponent IDs to File objects where each File is one recently selected by a FileWorkflow file select dialog.
   * Files selected when creating/editing a component are added here, and then the relevent LectureComponent's Function Component is responsible
   * for triggering the file upload + displaying file upload progress */
  filesToUpload: FilesToUploadMapping,
  setFilesToUpload: (mapping: FilesToUploadMapping) => void,
  /**
   * Similar equivalent of deprecated LecturePageManager.updateComponent(...).
   * Dispatches getLecturePage redux action under the hood.
   * Basically some lectrure components (Timed Quiz, Team Formation and
   * Individual submission) need to update the UI based on latest state of it
   * but they don't have the latest data when they need it so this currently
   * updates the entire lecture page (as LecturePageManager.updateComponent was
   * doing) but this time updating all the components because the angular side
   * update occurs whenever the data changes:
   * https://github.com/novoed/NovoEdWeb/blob/fecf4f56428ca0a9f5c193c1cee34307e23e054a/app/lecture_pages/components/content-area/angular-lecture-component.tsx#L254
   */
  updateLecturePage: () => void,
  /**
   * Similar equivalent to deprecated LecturePageManager.updateComponentStatus(...).
   * It's only intended for angular lecture components, it ends up calling
   * updateComponentStatus method at the component model level.
   */
  updateAngularComponentStatus: (componentType, componentId, data) => void;
};

const LecturePageContext = createContext<LecturePageContextType>(null);

export default LecturePageContext;
