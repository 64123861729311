import React from 'react';
import { matchPath, __RouterContext } from 'react-router';

import { AngularContext } from 'react-app';

type Props = {
  children: React.ReactNode,
};

/**
 * react-router Switch component equivalent to guarantee correct behavior of the
 * router in conjuction overlay-like page states (flyout and L4 pages).
 * Inspired on https://github.com/remix-run/react-router/blob/v5.3.3/packages/react-router/modules/Switch.js
 */
const NvSwitch = (props: Props) => {
  const { injectServices } = React.useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const lastNonOverlayElementRef = React.useRef<React.ReactNode>(null);
  const { match: contextMatch, location } = React.useContext(__RouterContext);

  let element = null;

  const getRouteLocationElement = (routeChild, routeLocation) => {
    const path = routeChild.props.path || routeChild.props.from;

    const match = path
      ? matchPath(routeLocation.pathname, { ...routeChild.props, path })
      : contextMatch;

    if (match) {
      return React.cloneElement(routeChild, { location: routeLocation, computedMatch: match });
    }

    return null;
  };

  React.Children.forEach(props.children, child => {
    if (!element && React.isValidElement(child)) {
      element = getRouteLocationElement(child, location)
    }
  });

  if ($state.current.data.level <= 3) {
    lastNonOverlayElementRef.current = element;

    return element;
  } else {
    return lastNonOverlayElementRef.current || element;
  }

};

export default NvSwitch;
