import { useCallback, useEffect, useState } from 'react';
import t from 'react-translate';

import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { wrapThunkAction } from 'redux/utils';

import { getAiQuizQuestions } from 'redux/actions/quizzes';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AiQuestionType, QuizAIQuestion } from 'redux/schemas/models/quiz';
import { AlertMessageType } from 'redux/schemas/app/alert-message';

const QUESTION_SET_LENGTH = 5;

const QUESTIONS_COUNT = {
  [AiQuestionType.MULTIPLE_CHOICE_SINGLE_ANSWER]: 6,
  [AiQuestionType.MULTIPLE_CHOICE_MULTIPLE_ANSWER]: 3,
  [AiQuestionType.FILL_BLANKS]: 3,
  [AiQuestionType.TRUE_OR_FALSE]: 3,
};

const useQuizAiQuestions = () => {
  const [isLoadingQuestion, setIsLoadingQuestion] = useState<boolean>(true);
  const [showingQuestionsCount, setShowingQuestionsCount] = useState<number>(QUESTION_SET_LENGTH);
  const [questions, setQuestions] = useState<QuizAIQuestion[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);

  const catalogId: string = useSelector((state) => state.app.currentCatalogId);
  const selectedAIContent = useSelector(state => state.app.lecturePage.novoAI.content);
  const lectureComponentToReplace = useSelector(state => state.app.lecturePage.novoAI?.lectureComponentToReplace);

  const dispatch = useAppDispatch();

  const onCompleteQuestionsFetch = useCallback((hasQuestions, errorCode) => {
    setIsLoadingQuestion(false);

    if (!hasQuestions) {
      setHasError(true);

      let errorHeader = '';
      let errorMessage = '';

      if (['content_too_short', 'blank_text_extract'].includes(errorCode)) {
        errorMessage = t.LECTURE_PAGES.COMPONENTS.QUIZ.AI_QUESTIONS.SHORT_ERROR();
      } else if (errorCode === 'content_too_long') {
        errorMessage = t.LECTURE_PAGES.COMPONENTS.QUIZ.AI_QUESTIONS.LARGE_ERROR();
      } else {
        errorHeader = t.FORM.OOPS();
        errorMessage = t.FORM.ERROR_SOMETHING_WRONG_SHORT();
      }

      dispatch(
        addAlertMessage({
          type: AlertMessageType.ERROR,
          header: errorHeader,
          message: errorMessage,
        }),
      );
    }
  }, [dispatch]);

  const fetchQuestions = useCallback(() => {
    if (catalogId) {
      const params = {
        catalogId,
        aiProperties: {
          aiOriginTarget: selectedAIContent,
          existingQuizLectureComponentId: lectureComponentToReplace?.id,
        },
      };

      setQuestions([]);
      setIsLoadingQuestion(true);

      let completedRequestsCount = 0;
      let errorCode = null;
      let hasQuestions = false;
      const questionTypes = Object.values(AiQuestionType);

      // Creating multiple requests based on the question type
      questionTypes.map((type) => wrapThunkAction(dispatch(getAiQuizQuestions(
        {
          ...params,
          aiProperties: {
            ...params.aiProperties,
            quizType: type,
            questionsCount: QUESTIONS_COUNT[type],
          },
        },
      ))).then((response) => {
        const newQuesions = response.payload as QuizAIQuestion[] ?? [];
        setQuestions((prev) => [...prev, ...newQuesions]);
        hasQuestions = newQuesions.length > 0;
      }).catch((error) => {
        errorCode = error?.payload?.code;
      }).finally(() => {
        completedRequestsCount += 1;

        if (completedRequestsCount === questionTypes.length) {
          onCompleteQuestionsFetch(hasQuestions, errorCode);
        }
      }));
    }
  }, [catalogId, dispatch, lectureComponentToReplace?.id, onCompleteQuestionsFetch, selectedAIContent]);

  useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]);

  const getMoreQuestions = () => {
    setShowingQuestionsCount(showingQuestionsCount + QUESTION_SET_LENGTH);
  };

  const questionsToShow = useCallback(() => (
    questions.slice(0, showingQuestionsCount)
  ), [questions, showingQuestionsCount]);

  return {
    isLoadingQuestion,
    totalQuestionsCount: questions.length,
    hasError,
    questionsToShow,
    getMoreQuestions,
  };
};

export default useQuizAiQuestions;
