import { css } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useFormContext } from 'react-hook-form';
import t from 'react-translate';

// selectors
import { getCourseAliases } from 'redux/selectors/course';

// components
import NvFormDropdown from 'shared/components/inputs/nv-form-dropdown';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import { NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';

// styles
import baseStyles from './base-styles';

const styles = css`
  &.completed-journey {
    ${baseStyles};
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    .filter-wrapper {
      display: flex;
      flex-direction: row;
    }
    &.on .content {
      margin-top: unset;
    }
  }
`;

type CompletedJourneyProps = {
  checkboxName: string,
  name: string,
  disableOn?: string[],
};

const CompletedJourney = ({
  checkboxName,
  name,
  disableOn,
}: CompletedJourneyProps) => {
  const aliases = useSelector((state) => getCourseAliases(state));
  const { watch, setValue } = useFormContext();
  const [hasCompletedJourneyChecked = false, hasCompletedJourney] = watch([checkboxName, name]);

  // In the case the dropdown option is recently selected the default option will be the first one
  // but with the corresponding format.
  useEffect(() => {
    if (hasCompletedJourneyChecked && !hasCompletedJourney) {
      setValue(name, {
        id: 'has-completed',
        type: 'text',
        text: t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_JOURNEY.HAS(aliases.courseAliases),
        value: true,
      }, { shouldValidate: true, shouldDirty: true });
    }
  }, [hasCompletedJourneyChecked, hasCompletedJourney, aliases.courseAliases, setValue]);

  // Disabled this filter if has never been active
  let dependentFieldChecked;

  if (disableOn?.length > 0) {
    dependentFieldChecked = disableOn.find(filter => watch(filter));
  }

  const items: NvDropdownTextItem[] = [
    {
      id: 'has-completed',
      type: 'text',
      text: t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_JOURNEY.HAS(aliases.courseAliases),
      value: true,
    },
    {
      id: 'has-not-completed',
      type: 'text',
      text: t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_JOURNEY.HAS_NOT(aliases.courseAliases),
      value: false,
    },
  ];

  return (
    <div css={styles} className={`completed-journey ${hasCompletedJourneyChecked ? 'on' : ''} ${dependentFieldChecked ? 'disabled' : ''}`}>
      <div className='filter-wrapper'>
        <div className='checkbox-wrapper'>
          <NvCheckbox
            withForm
            name={checkboxName}
            label={!hasCompletedJourneyChecked ? t.JOURNEY_COMMUNICATIONS.FILTERS.COMPLETED_JOURNEY.DEFAULT(aliases.courseAliases) : ''}
            labelClassName='text-large-regular pl-6'
            disabled={dependentFieldChecked}
          />
        </div>
        <div className='content'>
          <NvFormDropdown
            name={name}
            items={items}
          />
        </div>
      </div>
    </div>
  );
};

export default CompletedJourney;
