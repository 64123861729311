import angular from 'angular';
import { BookmarkType } from 'redux/schemas/models/bookmark';
import { getSkillTagsFromAngular } from 'shared/services/nv-util';

/* @ngInject */
export default function NvIndividualSubmission(
  $state,
  $stateParams,
  $timeout,
  $uibModal,
  CurrentCourseManager,
  CurrentUserManager,
  DiscussionsManager,
  ExercisesManager,
  ReactLecturePageContext,
  RailsRoutes,
  ReportsManager,
  ScrollFocusConnectorFactory,
  TimelinesManager,
  ReactTimelineService,
  BookmarkModel,
  PageLevelManager,
  _,
  config,
  nvCurrentPage,
) {
  return {
    restrict: 'A',
    scope: {
      reportId: '=',
      loadSubmissionComments: '=?',
      showSubmissionApprovalComment: '=?',
      stickyHeaderFooterCtrl: '=',
    },
    controller: function ctrl() {
'ngInject';
      const vm = this;

      const directLinkInfo: {
        replyId?: number,
        commentId?: number,
      } = {};

      if ($stateParams.replyId) {
        directLinkInfo.replyId = $stateParams.replyId;
      }

      if ($stateParams.commentId) {
        directLinkInfo.commentId = $stateParams.commentId;
      }

      _.extend(vm, {
        editable: false,
        isLoading: true,

        ReportsManager,
        ExercisesManager,
        CurrentUserManager,
        RailsRoutes,
        $state,
        config,

        registerCommentActionHandlers,
        unregisterCommentActionHandlers,
        updateComment,
        deleteComment,

        shouldShowCommentSignInSection,
        shouldShowComentsSection,
        shouldShowSubmissionApprovalSection,
        noCommentSection,
        createBookmark,
        highlightBookmark,
        directLinkInfo,
      });

      if (CurrentUserManager.hasLoggedIn() && CurrentUserManager.coursesHashByCatalogId[$stateParams.catalogId]) {
        vm.course = CurrentUserManager.coursesHashByCatalogId[$stateParams.catalogId];
      } else {
        vm.course = CurrentCourseManager.course;
      }

      function registerCommentActionHandlers(handler) {
        vm.commentCallbackHandler = handler;
      }

      function unregisterCommentActionHandlers() {
        delete vm.commentCallbackHandler;
      }

      function updateComment(comment) {
        if (vm.commentCallbackHandler) {
          vm.commentCallbackHandler(comment, 'edit');
        }
      }

      function deleteComment(comment) {
        return ReportsManager.currentReport.removeComment(comment).then((result) => {
          if (vm.commentCallbackHandler) {
            vm.commentCallbackHandler(comment, 'delete');
          }
          return result;
        });
      }


      function shouldShowCommentSignInSection() {
        return (ReportsManager.currentReport.isCompleted() || ReportsManager.currentReport.isApproved())
          && !ReportsManager.currentReport.hasSkillsRating()
          && vm.loadSubmissionComments && (!CurrentUserManager.hasEnrolledInCourse(vm.course) || !CurrentUserManager.hasLoggedIn());
      }

      function shouldShowComentsSection() {
        return (ReportsManager.currentReport.isCompleted() || ReportsManager.currentReport.isApproved())
          && !ReportsManager.currentReport.hasSkillsRating()
          && vm.loadSubmissionComments && CurrentUserManager.hasEnrolledInCourse(vm.course);
      }

      function shouldShowSubmissionApprovalSection() {
        return vm.showSubmissionApprovalComment
          && ReportsManager.currentReport?.isPendingApproval() && ReportsManager.currentReport.canReview;
      }

      function noCommentSection() {
        return !(vm.shouldShowCommentSignInSection() || vm.shouldShowComentsSection() || vm.shouldShowSubmissionApprovalSection());
      }

      function createBookmark() {
        vm.stickyHeaderFooterCtrl.closeL4();
        BookmarkModel.createBookmark({
          type: BookmarkType.SUBMISSION,
          catalogId: $stateParams.catalogId,
          componentId: ReportsManager.currentReport.id,
        })
          .then(bookmark => {
            ReportsManager.currentReport.bookmarkId = bookmark.id;
          });
      }

      function highlightBookmark() {
        vm.stickyHeaderFooterCtrl.closeL4();
        BookmarkModel.highlightBookmark(ReportsManager.currentReport.bookmarkId);
      }
    },
    link(scope, elem, attrs, submissionCtrl) {
      _.extend(submissionCtrl, {
        showNewCommentUi,
        hideNewCommentUi,
        onCommentReady,
      });

      function showNewCommentUi(isExpanded, scrollTo) {
        submissionCtrl.ReportsManager.currentReport.commentable = true;

        if (scrollTo) {
          ScrollFocusConnectorFactory.scrollTo(null, null, { elem: elem.find('.comments-section'), offset: (isExpanded ? 250 : 60) });
          ScrollFocusConnectorFactory.focus(submissionCtrl.ReportsManager.currentReport.id, 'report');
        }
      }

      function hideNewCommentUi() {
        submissionCtrl.ReportsManager.currentReport.commentable = false;
      }

      /* Initialization */
      loadReport();

      scope.$watch(() => submissionCtrl.reportId, (newValue, oldValue) => {
        if (newValue && newValue !== oldValue) {
          loadReport();
        }
      });

      scope.$on('$destroy', () => {
        DiscussionsManager.unsubscribeFromPusherEvents(null, submissionCtrl.reportId);
        nvCurrentPage.resetModalPageNoComments();

        // Initialize with previous param on destroying nv submission modal and the new
        // state doesn't include individual-submission-modal or team workspace
        if (DiscussionsManager?.preModalParams?.catalogId && !$state.includes('individual-submission-modal') && !$state.includes('team-workspace')) {
          DiscussionsManager.initialize(DiscussionsManager.preModalParams);
          DiscussionsManager.resetPreModalParams();
          DiscussionsManager.initializeMentions();
        }
      });

      function loadReport() {
        if (
          ReportsManager.currentReport
          && ExercisesManager.currentExercise?.template
          && ReportsManager.currentReport.id === submissionCtrl.reportId
          && ExercisesManager.currentExercise.id === ReportsManager.currentReport.exerciseId
          && !$stateParams.hasRevised
          && !ReportsManager.currentReport.languageHasChanged()
        ) {
          if (submissionCtrl.loadSubmissionComments) {
            loadSubmissionComments();
          }

          if (submissionCtrl.noCommentSection()) {
            nvCurrentPage.setModalPageNoComments();
          }

          if ($stateParams.showCourseHeader) {
            nvCurrentPage.setModalHeaderCourse(CurrentUserManager.coursesHashByCatalogId[ReportsManager.currentReport.catalogId]);
          }
        } else {
          ReportsManager.isLoadingSubmission = true;
          ReportsManager.getSingleSubmission($stateParams.catalogId, submissionCtrl.reportId).then((resultObj) => {
            if (submissionCtrl.loadSubmissionComments) {
              loadSubmissionComments();
            }

            if (submissionCtrl.noCommentSection()) {
              nvCurrentPage.setModalPageNoComments();
            }

            if ($stateParams.showCourseHeader) {
              nvCurrentPage.setModalHeaderCourse(CurrentUserManager.coursesHashByCatalogId[ReportsManager.currentReport.catalogId]);
            }

            ExercisesManager.getExercise($stateParams.catalogId, ReportsManager.currentReport.exerciseId).then(() => {
              if (!ExercisesManager.currentExercise.template) {
                ExercisesManager.currentExercise.requestTemplate($stateParams.catalogId, ReportsManager.currentReport.exerciseId).then(() => {
                  ReportsManager.currentReport = angular.merge(ReportsManager.currentReport, {
                    exercise: ExercisesManager.currentExercise,
                    reportTemplateSectionId: ReportsManager.currentReport.id,
                    template: ExercisesManager.currentExercise.template,
                  });

                  ReportsManager.isLoadingSubmission = false;
                  $timeout(() => angular.element(window).trigger('checkInView'));
                });
              } else {
                ReportsManager.isLoadingSubmission = false;
                $timeout(() => angular.element(window).trigger('checkInView'));
              }

              if (
                ReportsManager.currentReport.isCollaborator
                && $stateParams.hasRevised
                && ReportsManager.currentReport.exercise.approvalRequired
              ) {
                const hasPoints = CurrentCourseManager.course.gamificationEnabled && ReportsManager.currentReport.pointsReceived;
                const skillTags = getSkillTagsFromAngular(ReportsManager.currentReport.exercise?.lectureComponentId);
                const hasSkillTags = skillTags.length > 0;
                if (
                  (hasPoints || hasSkillTags)
                  && ReportsManager.currentReport.isApproved()
                  && !ReportsManager.currentReport.hasViewed
                ) {
                  $uibModal.open({
                    templateUrl: 'shared/templates/points-modal.html',
                    windowClass: 'points-modal',
                    controller: 'PointsModalCtrl as vm',
                    resolve: {
                      pointsReceived: ReportsManager.currentReport.pointsReceived,
                      leaderboardPoints: ReportsManager.currentReport.leaderboardPoints,
                      leaderboardRank: ReportsManager.currentReport.leaderboardRank,
                      priorLeaderboardRank: ReportsManager.currentReport.priorLeaderboardRank,
                      extras: { skillTags },
                    },
                  }).result.then((result) => {
                    ReportsManager.currentReport.markViewed();
                  }, (result) => {
                    ReportsManager.currentReport.markViewed();
                  });
                }

                // the followup updates are necessary because we don't have true synchronous updates
                // parts of the lecture page are out of date, so we force update it this way
                if (ReportsManager.currentReport.isApproved() || ReportsManager.currentReport.isRejectedCanRevise() || ReportsManager.currentReport.isRejectedCannotRevise()) {
                  ReactLecturePageContext.updateAngularComponentStatus(
                    'exercise_approval',
                    ReportsManager.currentReport.exerciseId,
                    {
                      report: ReportsManager.currentReport,
                    },
                  );

                  ReactTimelineService.updateTimeline(ReportsManager.currentReport.exercise.lecturePageId);
                  TimelinesManager.updateComponentPointsAndProgress(ReportsManager.currentReport.exercise.lecturePageId, 'exercise',
                    ReportsManager.currentReport.exercise.id, ReportsManager.currentReport.pointsReceived, null, ReportsManager.currentReport.exercise.progress);
                }
              }
            });
          });
        }
      }

      function loadSubmissionComments() {
        privacyPopOver();
        DiscussionsManager.initialize({
          context: 'report',
          catalogId: $stateParams.catalogId,
          currentReport: ReportsManager.currentReport,
          allowMentions: $stateParams.allowMentions,
        });
        ReportsManager.currentReport.loadComments($stateParams.commentId).then(() => {
          DiscussionsManager.initializeMentions();
          commentsCount = ReportsManager.currentReport.comments?.length;
          if (!DiscussionsManager.pusherEventsInitialized) {
            DiscussionsManager.initializePusherEvents(null, submissionCtrl.reportId);
          }
        });
      }

      function privacyPopOver() {
        ReportsManager.currentReport.privateAndSharingAllowed = false;
        ReportsManager.currentReport.privateAndSharingNotAllowed = false;
        ReportsManager.currentReport.privateAndSharable = false;

        // Display privacy popover when the report is private to teaching team
        if (ReportsManager.currentReport.privacySetting === 'shared_with_instructor'
        || ReportsManager.currentReport.privacySetting === 'shared_with_instructor_and_team') {
          if (ReportsManager.currentReport.exercise.privacySetting === 'sharable_with_instructor') {
            // Private to teaching team and sharing is not allowed
            ReportsManager.currentReport.privateAndSharingNotAllowed = true;
          } else if (ReportsManager.currentReport.exercise.privacySetting === 'sharable_with_instructor_and_team') {
            // Private to teaching team and sharing is allowed
            ReportsManager.currentReport.privateAndSharingAllowed = true;
          } else if (ReportsManager.currentReport.exercise.privacySetting === 'sharable_with_instructor_or_class'
            || ReportsManager.currentReport.exercise.privacySetting === 'sharable_with_public') {
            // private to teaching team and sharable to course/web
            ReportsManager.currentReport.privateAndSharable = true;
          }
        }
      }

      let commentsReady = 0;
      let commentsCount;

      function onCommentReady() {
        commentsReady += 1;

        if (commentsCount && commentsCount === commentsReady) {
          scope.$broadcast('discussion-thread-ready');
        }
      }

      scope.$on('$destroy', () => {
        nvCurrentPage.resetModalHeaderCourse();

        if (ReportsManager?.currentReport) {
          ReportsManager.currentReport.comments = [];
          ReportsManager.currentReport.additionalCommentsBeforeCount = null;
          ReportsManager.currentReport.additionalNewCommentsBeforeCount = null;
        }
      });
    },
    bindToController: true,
    controllerAs: 'vm',
    templateUrl: 'submissions/templates/nv-individual-submission.html',
  };
}
