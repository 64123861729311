import React, { useContext, useRef, useState } from 'react';
import t from 'react-translate';
import moment from 'moment';
import { doubleSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { isEmpty } from 'underscore';
import { AngularServicesContext } from 'react-app';

// redux
import { useSelector } from 'react-redux';
import { getLecturePage } from 'redux/selectors/timeline';
import { CourseAliases } from 'redux/schemas/models/course';
import { getCourseAliases } from 'redux/selectors/course';

// components
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import NvPopover from 'shared/components/nv-popover';
import { css } from '@emotion/react';
import { LinkLessonsContext } from './link-lessons-modal-body';
import { CollectionRowProps } from './collection-row';

export type Props = {
  lecturePageId: number
} & CollectionRowProps;

const CollectionRowLesson = (props: Props) => {
  const { lecturePageId, catalogId } = props;
  const [showPopover, setShowPopover] = useState(false);
  const { onLessonCheckboxToggle, selectedLessonIds } = useContext(LinkLessonsContext);
  const { $state } = useContext(AngularServicesContext) || {};

  const lecturePage = useSelector((state) => getLecturePage(state, lecturePageId));
  const aliases: CourseAliases = useSelector((state) => getCourseAliases(state));

  const styles = css`
    .name {
      max-width: calc(100% - ${doubleSpacing}px);
      .lesson-label {
        line-height: ${standardSpacing}px;;
        display: inline-block;
      }
    }
  `;

  const popoverStyle = css`
    max-width: none;
    width: 340px;
  `;

  return (
    <div
      className='d-flex pl-7'
      key={lecturePageId}
      css={styles}
    >
      <div
        className='name d-flex flex-column'
        onMouseEnter={() => setShowPopover(true)}
        onMouseLeave={() => setShowPopover(false)}
      >
        <NvPopover
          content={(
            <div>
              <div className='text-small text-gray-3 font-weight-bolder pb-2 ellipsis'>
                {lecturePage.title}
              </div>
              <ul className='mb-1'>
                <li className='text-body'>
                  {t.TIMELINE.LESSON_LINK_MODAL.LESSON_POPOVER.LAST_EDITOR()}
                  <b>{`${lecturePage?.lastEditor?.firstName ?? ''} ${lecturePage?.lastEditor?.lastName ?? ''}`}</b>
                </li>
                <li className='text-body'>
                  {t.TIMELINE.LESSON_LINK_MODAL.LESSON_POPOVER.LAST_EDITED_ON()}
                  <b>{moment(lecturePage.updatedAt).format('MM/DD/YYYY h:mm a')}</b>
                </li>
                <li className='text-body'>
                  {t.TIMELINE.LESSON_LINK_MODAL.LESSON_POPOVER.CREATOR()}
                  <b>{`${lecturePage?.creator?.firstName ?? ''} ${lecturePage?.creator?.lastName ?? ''}`}</b>
                </li>
                <li className='text-body'>
                  {t.TIMELINE.LESSON_LINK_MODAL.LESSON_POPOVER.CREATED_ON()}
                  <b>{moment(lecturePage.createdAt).format('MM/DD/YYYY h:mm a')}</b>
                </li>
                <li className='text-body'>
                  {t.TIMELINE.LESSON_LINK_MODAL.LESSON_POPOVER.LINKED_LESSONS({ LecturesAlias: aliases.lectureAliases.LecturesAlias })}
                  <b>{lecturePage.contentManagementLinks?.length ?? 0}</b>
                </li>
                <a
                  className='text-body'
                  href={$state?.href('lecture-page', { catalogId, id: lecturePageId, fromLinkedCourse: true })}
                  target='_blank'
                  rel='noreferrer'
                >
                  <u className='text-primary'>
                    {t.TIMELINE.LESSON_LINK_MODAL.LESSON_POPOVER.PREVIEW_LESSON({ LectureAlias: aliases.lectureAliases.LectureAlias })}
                  </u>
                </a>
              </ul>
            </div>
          )}
          show={showPopover}
          preventOverflow
          placement='top'
          overlayStyles={popoverStyle}
          offset={0}
        >
          <NvCheckbox
            name={`lecturePageId-${lecturePageId}`}
            label={lecturePage.title}
            labelClassName='text-gray-1 font-weight-bolder text-regular text-truncate lesson-label mt-3'
            value={lecturePageId}
            onChange={onLessonCheckboxToggle}
            className='pl-2'
            checked={selectedLessonIds.includes(lecturePageId)}
          />
        </NvPopover>
        {!isEmpty(lecturePage.description) && (
          <div className='ml-6 mt-1 text-gray-1 ellipsis'>
            {lecturePage.description}
          </div>
        )}
      </div>
    </div>
  );
};

export default CollectionRowLesson;
