// schemas
import { CourseActivities, BaseCourseActivities } from 'redux/schemas/api/course-communications';
import { ActivityType, ActivityKey } from 'redux/schemas/models/activity';
import { FeedbackCategory } from 'redux/schemas/models/course-communication';

// selectors
import { getKeyForActivityType } from 'redux/selectors/activity';

const withoutCurrentActivity = (activities: CourseActivities, activityId: number, activityType: ActivityType, category?: FeedbackCategory) => {
  const courseActivities = { ...activities };
  const key: ActivityKey = getKeyForActivityType(activityType, category);
  const items = ((courseActivities as Array<BaseCourseActivities>)[key] || []).filter(activity => activity.id !== activityId);
  if (items.length) {
    courseActivities[key] = items;
  } else {
    delete courseActivities[key];
  }

  return courseActivities;
};
export default withoutCurrentActivity;

