/* @ngInject */
export default function StatementModelService(

  StatementsResources,
  _,
) {
  const StatementModel = function (attributes) {
    const _this = this;

    _.extend(_this, attributes);


    initialize();

    function initialize() {
      preprocess();
    }

    function preprocess() {

    }
  };

  StatementModel.get = function (catalogId, userId) {
    return StatementsResources.get({ catalogId, id: userId }).$promise
      .then((response) => new StatementModel(_.extend(response.result, { })));
  };


  return StatementModel;
}
