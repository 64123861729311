import { css } from '@emotion/react';

import { connect } from 'react-redux';
import { RootState } from 'redux/schemas';

import { getLectureSection } from 'redux/selectors/timeline';

import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { LectureSection, NLectureSection } from 'redux/schemas/models/lecture-section';
import TimelineLecturePage from './timeline-lecture-page';
import TimelineSubsection from './timeline-subsection';

type TimelineSectionProps = {
  lectureSectionId: number
};

type StateProps = {
  lectureSection: NLectureSection,
};

const styles = css`
  .section-row {
    display: flex;
    justify-content: center;
    margin: ${standardSpacing}px ${standardSpacing}px ${halfSpacing}px;
  }
`;

/**
 * Timeline Section Header
 */
const TimelineSection = (props: TimelineSectionProps & StateProps) => (
  <div css={styles}>
    <div className='section-row'>
      <div className='card-title'>{props.lectureSection.title}</div>
    </div>
    {
      // Lecture pages
      props.lectureSection.lecturePages && props.lectureSection.lecturePages.map(lecturePageId => (
        <TimelineLecturePage key={lecturePageId.toString()} lecturePageId={lecturePageId} />
      ))
    }

    {
      // Lecture subsections
      props.lectureSection.lectureSubsections && props.lectureSection.lectureSubsections.map(lectureSubsectionId => (
        <TimelineSubsection key={lectureSubsectionId.toString()} lectureSubsectionId={lectureSubsectionId} />
      ))
    }
  </div>
);

const ConnectedTimelineSection = connect(
  (state: RootState, ownProps: TimelineSectionProps): StateProps => ({
    lectureSection: getLectureSection(state, ownProps.lectureSectionId),
  }),
)(TimelineSection);

export default ConnectedTimelineSection;
