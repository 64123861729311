import { css } from '@emotion/react';
import { AngularServicesContext } from 'react-app';
import { useSelector } from 'react-redux';
import { pick, isEmpty, findWhere } from 'underscore';
import { useContext, useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/store';
import t from 'react-translate';

import {
  StyledLinkType } from 'redux/schemas/models/lecture-component';
import NvModal, { ModalType } from 'shared/components/nv-modal';

import { updateLectureComponent } from 'redux/actions/lecture-pages';
import BaseLectureComponentContext from 'lecture_pages/directives/components/base-lecture-component/context';

import ButtonStyledLink from 'lecture_pages/components/styled-link-lecture-component/button-link';
import CardStyledLink from 'lecture_pages/components/styled-link-lecture-component/card-link';
import StyledLinkUrlModalProps from 'lecture_pages/components/styled-link-lecture-component/styled-link-url-modal';

import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { LectureComponentProps, LecturePageMode } from '..';

export interface StyledLinkRenderProps {
  isNewComponent?: boolean,
  onUpdate?: (component: any) => Promise<any>,
}

/* @ngInject */
export default function StyledLinkLectureComponent(props: LectureComponentProps<StyledLinkType>) {
  const params = useLecturePageParams();
  const dispatch = useAppDispatch();
  // angular savingStyledComponent is used for the confirmation modal that appears when the
  // user exits without saving the updated content.
  const angularServices = useContext(AngularServicesContext);
  const { lectureComponent } = props;
  const { sharedProps, setSharedProps } = useContext(BaseLectureComponentContext);

  const [showEditURLModal, setShowEditURLModal] = useState<boolean>();

  /** Whether or not this component was just now added to the page */
  const isNewComponent = useSelector((state) => state.app.newComponent === lectureComponent?.id) ?? false;

  const styles = css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  // TODO: Fix this 'any'
  let StyledComponent;

  if (lectureComponent.viewOptions.styleType === 'button') {
    StyledComponent = ButtonStyledLink;
  } else if (lectureComponent.viewOptions.styleType === 'card') {
    StyledComponent = CardStyledLink;
  }

  async function updateStyledComponent(component: Partial<typeof lectureComponent>) {
    const data = {
      catalogId: params.catalogId,
      lecturePageId: props.currentLecture.id,
      componentData: {
        ...(pick(lectureComponent, ['id', 'type', 'index', 'header', 'content', 'viewOptions', 'picture'])),
        ...component,
      },
    };
    if (!isEmpty(data.componentData.picture)) {
      data.componentData.picture = pick(data.componentData.picture, 'contentType', 'fileName', 'uniqueId', 'fileSize');
    }
    const result = await dispatch(updateLectureComponent(data as any));
    angularServices.$scope.vm.savingStyledComponent = false;
  }

  useEffect(() => {
    setSharedProps({
      ...sharedProps,
      extraOptions: {
        mode: 'prepend',
        options: [
          {
            type: 'text',
            text: t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.EDIT_URL(),
            callback: () => editURLModal(),
          },
        ],
      },
    });
  }, [setSharedProps]);

  const editURLModal = () => {
    setShowEditURLModal(true);
  };

  const updateStyledLinkUrl = (content: string) => {
    if (content !== lectureComponent.content) {
      updateStyledComponent({ content })
        .then(() => {
          setShowEditURLModal(false);
        });
    } else {
      setShowEditURLModal(false);
    }
  };

  return (
    <div css={styles} className='mt-2 mb-2'>
      { props.mode === LecturePageMode.VIEW
        ? (
          <a href={lectureComponent.content} target='_blank' rel='noreferrer'>
            <StyledComponent
              {...props}
            />
          </a>
        )
        : (
          <StyledComponent
            {...props}
            isNewComponent={isNewComponent}
            onUpdate={updateStyledComponent}
          />
        )}
      {showEditURLModal && (
        <NvModal
          type={ModalType.FIXED}
          header={t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.EDIT_URL()}
          body={(
            <StyledLinkUrlModalProps
              closeModal={() => setShowEditURLModal(false)}
              update={updateStyledLinkUrl}
              content={lectureComponent.content}
            />
          )}
          show={showEditURLModal}
          onClose={() => setShowEditURLModal(false)}
          height={300}
        />
      )}
    </div>
  );
}
