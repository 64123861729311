/* @ngInject */
export default function FlyOutPanelManager(
  PageLevelManager,
  $stickyState,
  $state,
  $rootScope,
  L4Modal,
) {
  let panelVisible = false;
  let lastSavedState = null;
  let lastSavedParam = {};
  const manager = {
    hidePanel,
    showPanel,
    isVisible,
    getParentState,
    getParentParam,
    closeFlyoutPanelCallback,
    lastNonFlyoutState: null,
    initialize() {
      $rootScope.$on('$stateChangeSuccess', (event, toState, stateToParams, fromState, fromParams) => {
        if (toState.data.level < 3.5) {
          manager.lastNonFlyoutState = {
            state: $state.$current,
            stateParams: $state.$current.params,
          };
        }
      });
    },
  };

  function hidePanel() {
    panelVisible = false;
    $stickyState.reset('flyout-panel');
  }

  // Displaying Panel and storing last non L4 prev state ( use for closing )
  function showPanel(lastState, lastParam) {
    if (L4Modal.isVisible()) {
      L4Modal.close();
    }

    // If the last state is an L4 page, save last non L4 state from page level manager
    if (lastState?.data?.level === 4 && PageLevelManager?.lastParent()) {
      lastSavedState = PageLevelManager.lastParent().state;
      lastSavedParam = PageLevelManager.lastParent().params;
    } else {
      // Last state is a L1 page. Store those
      lastSavedState = lastState;
      lastSavedParam = lastParam;
    }
    panelVisible = true;
  }

  function isVisible() {
    return panelVisible;
  }

  function getParentState() {
    return lastSavedState;
  }

  function getParentParam() {
    return lastSavedParam;
  }

  function closeFlyoutPanelCallback() {
    // Trigger state change to prev state
    // Which will trigger a state change to a < L4 state
    // Thus closing the flyout panel from app.js stateChangeSuccess

    const parentState = getParentState();
    if (parentState) {
      return $state.go(parentState?.name, getParentParam());
    }
    // fallback: If no last state stored. go back to dashboard
    return $state.go('dashboard', {});
  }

  return manager;
}
