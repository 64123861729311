import { css } from '@emotion/react';
import { LecturePageMode } from 'lecture_pages/components';
import getComponentMetadata from 'lecture_pages/components/data';
import { useLecturePageLink } from 'lecture_pages/hooks/lecture-routing';
import React, { useContext, useState, useMemo, useEffect, useCallback } from 'react';
import { AngularServicesContext } from 'react-app';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { RootState } from 'redux/schemas';
import { getCourseLecturePageTimeline } from 'redux/actions/timeline';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { copyLectureComponent, moveLectureComponent, setCopyOrMoveAction } from 'redux/actions/lecture-components';
import { ComponentType, LectureComponent, NLectureComponent } from 'redux/schemas/models/lecture-component';
import { NLecturePage } from 'redux/schemas/models/lecture-page';
import { useCourseOutline } from 'redux/selectors/course-outline/use-course-outline';
import { useAppDispatch } from 'redux/store';
import NvDropdown, { NvDropdownOption, NvDropdownButtonStyle, NvDropdownAlign } from 'shared/components/inputs/nv-dropdown';
import { getCurrentCourse, getFlatCourseAliases } from 'redux/selectors/course';
import { isEmpty } from 'underscore';
import { TextAlign } from 'shared/components/nv-tooltip';
import { AutoCompletionProps, createAutoCompletionDropdownItem } from 'lecture_pages/components/auto-completion-dropdown-item';
import { ExternalToolWebLinkTypes } from 'redux/schemas/models/external-tool';
import { getSanitizedStyles } from 'shared/utils';
import { resetDestinationLecturePage, setDestinationLecturePage } from 'redux/actions/lecture-pages';
import LecturePagePreviewModal, { LecturePagePreviewContext } from 'lecture_pages/components/lecture-page-preview-modal';
import BaseLectureComponentContext from './base-lecture-component/context';

export type LectureComponentDropdownMode = 'normal' | 'copy' | 'move';

const narrowWidth = 120;
const wideWidth = 270;

const isExternalToolTypeOf = (component, type) => component?.externalTool?.toolType === type;

const isWebLink = (component) => isExternalToolTypeOf(component, ExternalToolWebLinkTypes.WEB_LINK);
const isWebEmbed = (component) => isExternalToolTypeOf(component, ExternalToolWebLinkTypes.WEB_EMBED);
const isScorm = (component) => isExternalToolTypeOf(component, ExternalToolWebLinkTypes.SCORM);
const isLti = (component) => isExternalToolTypeOf(component, ExternalToolWebLinkTypes.LTI);

export const getAutoCompletionProps = (lectureComponent) => {
  const autoCompletionProps: AutoCompletionProps = { lectureComponent };

  if (!isScorm(lectureComponent)) {
    autoCompletionProps.autoCompletionText = t.LECTURE_PAGES.COMPONENTS.WEB_LINK.API_COMPLETION();
    autoCompletionProps.tooltip = {
      on: t.LECTURE_PAGES.COMPONENTS.WEB_LINK.AUTO_COMPLETION_ON_TOOLTIP(),
      off: t.LECTURE_PAGES.COMPONENTS.WEB_LINK.AUTO_COMPLETION_OFF_TOOLTIP(),
    };
  }

  return autoCompletionProps;
};

// TODO: move this dropdown code into a different file
const lectureComponentEditStyles = css`
  /** Adapted from @mixin dropdown-bottom-padding($height) { in _scaffolding.scss
  Adds a bottom 65px "margin" (not achievable via the margin property) to prevent the
  dropdown from being masked by the lightbox footer */
  .bs4-dropdown-menu {
    &:after {
      position: absolute;
      content: "";
      bottom: -65px;
      height: 65px;
      width: 1px;
    }

    .bs4-dropdown-item {
      white-space: unset;
    }
  }
`;

// A hack to remove the default padding at the top of our dropdowns when doing a moveto or copyto, which
// causes a whitespace above the opaque title background
const actionToStyles = css`
  .bs4-dropdown-menu {
    padding-top: 0 !important;
  }
`;

const LectureComponentEditDropdown = (props: {
  lectureComponent: NLectureComponent,
  catalogId: string,
  currentLecturePage: NLecturePage,
  dropdownMode: LectureComponentDropdownMode,
  show: boolean,
  onToggle: (nextShow: boolean) => void,
}) => {
  const dispatch = useAppDispatch();
  const lecturePage = props.currentLecturePage;
  const metadata = useMemo(() => getComponentMetadata(props.lectureComponent.trueType), [props.lectureComponent.trueType]);
  const baseComponentContext = useContext(BaseLectureComponentContext);
  const { $scope } = useContext(AngularServicesContext);
  const currentCourse = useSelector(getCurrentCourse);
  const angularComponent = $scope[`lectureComponent${props.lectureComponent.id}`];
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [destinationLecturePageId, setDestinationLecturePageId] = useState<number>();
  const [action, setAction] = useState(null);

  /** The dropdown list display items for both move-to and copy-to mode */
  const [copyToItems, setCopyToItems] = useState<NvDropdownOption[]>([]);

  const courseAliases = useSelector(state => getFlatCourseAliases(state, props.catalogId));

  const courseOutline = useCourseOutline(props.catalogId);

  /** Set the options in the dropdown. Default to using the default items but without the top divider if no options were given */
  const items = useMemo(() => {
    let defaultItems: NvDropdownOption[] = [{ type: 'divider' }];

    if (!isEmpty(props.catalogId) && !props.currentLecturePage.isLinked && !currentCourse.isContentManagementCollection) {
      if (!metadata.copyDisabled && !metadata.copyHidden) {
        defaultItems.push({
          type: 'text',
          text: t.LECTURE_PAGES.COMPONENTS.COPY_TO_ELLIPSIS(),
          disableSetActive: true,
          callback: baseComponentContext.copyComponent,
          preventClosing: true,
        });
      }

      if (!metadata.moveDisabled) {
        defaultItems.push({
          type: 'text',
          text: t.LECTURE_PAGES.COMPONENTS.MOVE_TO_ELLIPSIS(),
          disableSetActive: true,
          callback: baseComponentContext.moveComponent,
          preventClosing: true,
        });
      }
    }

    defaultItems.push(
      {
        type: 'text',
        text: baseComponentContext.sharedProps.deleteText,
        class: 'text-danger',
        disableSetActive: true,
        callback: baseComponentContext.deleteComponent,
        disabled: !!props.currentLecturePage.isLinked,
        tooltip: {
          text: t.LECTURE_PAGES.COMPONENTS.DROPDOWN.DELETE_IN_LINKED_LESSON_TOOLTIP(),
          enabled: !!props.currentLecturePage.isLinked,
          textAlign: TextAlign.LEFT,
          placement: 'left',
          offset: 20,
        },
      },
    );

    if (!baseComponentContext.sharedProps.extraOptions?.options?.length) {
      defaultItems.shift();
    } else if (baseComponentContext.sharedProps.extraOptions.mode === 'custom') {
      defaultItems = baseComponentContext.sharedProps.extraOptions.options;
    } else if (baseComponentContext.sharedProps.extraOptions.mode === 'prepend') {
      defaultItems.unshift(...baseComponentContext.sharedProps.extraOptions.options);
    } else if (baseComponentContext.sharedProps.extraOptions.mode === 'append') {
      defaultItems.shift();
      defaultItems.push({ type: 'divider' }, ...baseComponentContext.sharedProps.extraOptions.options);
    }
    if ((angularComponent?.externalTool?.isTodo
      || angularComponent?.externalTool?.pointsConfiguration?.points)
      && !isLti(angularComponent)
    ) {
      const autoCompletionItems = createAutoCompletionDropdownItem(getAutoCompletionProps(angularComponent));
      defaultItems.unshift(...autoCompletionItems);
    }
    return defaultItems;
  }, [angularComponent,
    baseComponentContext.copyComponent,
    baseComponentContext.deleteComponent,
    baseComponentContext.moveComponent,
    baseComponentContext.sharedProps.deleteText,
    baseComponentContext.sharedProps.extraOptions.mode,
    baseComponentContext.sharedProps.extraOptions.options,
    currentCourse.isContentManagementCollection,
    metadata.copyDisabled,
    props.catalogId,
    props.currentLecturePage.isLinked]);

  /** Fires the action to either copy or move this component to a given lecture page */
  const fireActionTo = useCallback((targetLecturePageId: number) => {
    setShowPreviewModal(true);
    dispatch(setDestinationLecturePage({ mode: LecturePageMode.VIEW, lecturePageId: targetLecturePageId }));
    dispatch(setCopyOrMoveAction({ action: props.dropdownMode, component: props.lectureComponent }));
    setAction(props.dropdownMode);
    setDestinationLecturePageId(targetLecturePageId);
  }, [dispatch, props.dropdownMode, props.lectureComponent]);

  const onClosePreviewModal = () => {
    setShowPreviewModal(false);
    dispatch(resetDestinationLecturePage());
  };

  /**
   * If sections doesn't have lesson pages, then add the section with a
   * no available lesson option
   */
  const pushNoItemsOption = useCallback((dropdownItems: NvDropdownOption[], headerName: string) => {
    dropdownItems.push({ type: 'divider', class: dropdownItems.length === 1 ? 'mt-0' : '' });

    dropdownItems.push({
      type: 'header',
      title: headerName,
      class: 'mt-2 ml-3',
    });

    dropdownItems.push({
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.NO_AVAILABLE_LESSONS(courseAliases),
      disabled: true,
      class: 'p-0 mx-3 my-1',
      textClassName: 'text-gray-3',
    });
  }, [courseAliases]);

  const wideWidthStyle = isWebEmbed(angularComponent) || isWebLink(angularComponent)
    ? '305' : wideWidth;

  /** Create the course outline UI displayed in the Copy To and Move To dropdowns that appear when selecting those options. */
  useEffect(() => {
    // There's no reason to create this outlin when not in a copy or move mode
    if (props.dropdownMode === 'normal') {
      // eslint-disable-next-line no-useless-return
      return;
    }

    const dropdownItems: NvDropdownOption[] = [];

    dropdownItems.push({
      type: 'header',
      title: props.dropdownMode === 'copy' ? t.LECTURE_PAGES.COMPONENTS.COPY_TO_COLON() : t.LECTURE_PAGES.COMPONENTS.MOVE_TO_COLON(),
      class: 'bg-gray-6 text-gray-3 p-2 pl-3 m-0 font-weight-bold',
    });

    // Selecting the current page is available when copying but not when moving
    if (props.dropdownMode === 'copy') {
      dropdownItems.push({
        type: 'text',
        text: t.LECTURE_PAGES.COMPONENTS.CURRENT_PAGE(),
        callback: () => {
          fireActionTo(props.currentLecturePage.id);
        },
      });
    }

    /** Iterate through each item in the outline and add dropdown item entries :
     * 1. Sections that contain lecture pages.
     * 2. Sections that doesn't have lecture pages is pushed with a message.
     * 3. Lecture pages
     */
    let lastSectionItem = null;

    courseOutline.forEach((outlineItem, i) => {
      // Overwrite previous "last section" found if this is a section
      if (outlineItem.type !== 'lecture') {
        /**
         * If there is a lastSectionItem here, that means there were no lessons before.
         * If this is not a subsection and not happening after section, then add
         * a message that, this section don't have available lessons         *
         */
        if (lastSectionItem
          && !(lastSectionItem.type === 'section' && outlineItem.type === 'subsection')) {
          pushNoItemsOption(dropdownItems, lastSectionItem.name);
        }

        lastSectionItem = outlineItem;
      }

      if (outlineItem.type === 'lecture' && !outlineItem.isLinked) {
        // Skip this entry if it's the current page and we're in move mode because the app does not support doing a 'move' to the
        // current page
        if (props.dropdownMode === 'move' && outlineItem.id === props.currentLecturePage.id) {
          return;
        }

        // Add the entry for a section only when we see the first of the lectures it contains
        if (lastSectionItem) {
          dropdownItems.push({
            type: 'divider',
            class: 'mt-0',
          });

          dropdownItems.push({
            type: 'header',
            title: lastSectionItem.name,
            class: 'mt-2 ml-3',
          });

          lastSectionItem = null;
        }

        dropdownItems.push({
          type: 'text',
          text: lecturePage.id === outlineItem.id ? t.LECTURE_PAGES.COMPONENTS.CURRENT_LECTURE_PAGE(lecturePage.title) : outlineItem.name,
          callback: () => {
            fireActionTo(outlineItem.id);
          },
        });
      }
    });

    /**
     * If lastSectionItem is present here, that means the last section didn't
     * have any lessons. Push the last section with a no items message.
     */
    if (lastSectionItem) {
      pushNoItemsOption(dropdownItems, lastSectionItem.name);
    }

    setCopyToItems(dropdownItems);
  }, [props.dropdownMode]);

  let dropdownWidth = null;

  const webLinkIsActivity = angularComponent?.externalTool?.isTodo
    || !!angularComponent?.externalTool?.pointsConfiguration?.points;

  // Edit option is disabled for certain lecture components in linked lesson
  const isEditDisabledForLinkedLesson = props.currentLecturePage.isLinked
    && ([
      ComponentType.RICH_TEXT,
      ComponentType.ACCORDION,
      ComponentType.STYLED_LINK,
      ComponentType.WHITE_SPACE,
      ComponentType.IMAGE,
      ComponentType.ATTACHMENT,
      ComponentType.HEADER_STYLE1,
      ComponentType.HEADER_STYLE2,
      ComponentType.HEADER_STYLE3,
      ComponentType.TEXT_WITH_IMAGE_BKG,
      ComponentType.TEXT_WITH_IMAGE_SIDE,
      ComponentType.TEXT_WITH_IMAGE_TOP,
      ComponentType.LINE_DIVIDER,
      ComponentType.VIDEO,
      ComponentType.AUDIO,
    ].includes(props.lectureComponent.type)
    || (isWebLink(angularComponent) && !webLinkIsActivity));

  if (props.dropdownMode === 'normal') {
    // TODO: Remove this. I briefly experimented with letting this be set manually, but now we do it automatically
    // if the edit list has changed
    // TODO: Change the items length check here; Ideally it would be "if the component sets custom items"
    // dropdownWidth = metadata.wideEditDropdown ? wideWidth : narrowWidth;
    if (metadata.wideEditDropdown) {
      dropdownWidth = wideWidthStyle;
    } else {
      dropdownWidth = items.length > 3 ? wideWidthStyle : narrowWidth;
    }
  } else {
    dropdownWidth = wideWidthStyle;
  }

  return (
    // Note that this id is used by angular-scroll to scroll to the correct component on page load
    <div
      css={getSanitizedStyles([lectureComponentEditStyles, actionToStyles])}
      id={`lecture-component-${props.lectureComponent.id}`}
    >
      <NvDropdown
        show={props.show}
        onToggle={props.onToggle}
        key={props.dropdownMode}
        buttonStyle={NvDropdownButtonStyle.ICON}
        iconClass={`icon-xss-smallest icon-edit ${isEditDisabledForLinkedLesson ? 'text-gray-5' : 'text-primary'}`}
        items={props.dropdownMode === 'normal' ? items : copyToItems}
        align={NvDropdownAlign.RIGHT}
        flip={false}
        offset={10}
        showSelectedIndicator={props.dropdownMode === 'normal' && baseComponentContext.sharedProps.extraOptions.showActive}
        initialIndex={baseComponentContext.sharedProps.extraOptions.initialSelectedIndex}
        disabled={isEditDisabledForLinkedLesson}
        tooltip={t.LECTURE_PAGES.COMPONENTS[isEditDisabledForLinkedLesson ? 'LINKED_EDIT_OPTIONS' : 'EDIT_OPTIONS'](courseAliases)}
        tooltipTextAlign={TextAlign.LEFT}
        /** I've been unable to make the dropdown dynamically resize its width based on content. Instead, this minwidth
         * seems to set the expected and actual width of the dropdown. The smaller size works OK until have long
         * lecture names in the actionTo menus */
        minWidth={dropdownWidth}
        maxSizeInterceptor={(maxSize) => {
          const footerVisible = document.querySelector('.lecture-page-footer');
          return maxSize - (footerVisible ? 60 : 0);
        }}
        /** NvDropdown setting the renderOnMount in menu based on the withform
         * value so using the withForm here for rendering on mount.
         */
        withForm={baseComponentContext.sharedProps.extraOptions.renderOnMount}
        toggleDataQa='lecture-component-edit'
        focusOutOnItemClick
      />
      {
        destinationLecturePageId && (
          <LecturePagePreviewContext.Provider value={{
            lecturePageId: destinationLecturePageId,
            onClosePreviewModal,
            source: action,
          }}
          >
            <LecturePagePreviewModal
              show={showPreviewModal}
              onClose={onClosePreviewModal}
            />
          </LecturePagePreviewContext.Provider>
        )
      }
    </div>
  );
};

export default LectureComponentEditDropdown;
