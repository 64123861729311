import { css } from '@emotion/react';
import { ColorValuesMap } from 'components/color-picker-popover';
import { HeaderComponentType } from 'lecture_pages/services/components/content_templates/header-lecture-component-model';
import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { getCurrentCourse } from 'redux/selectors/course';
import t from 'react-translate';
import { RootState } from 'redux/schemas';
import { isHeaderStyle1, isHeaderStyle2, isHeaderStyle3, convertToPictureData } from 'redux/schemas/models/lecture-component';
import { useAppDispatch } from 'redux/store';
import NvTextArea from 'shared/components/inputs/nv-text-area';
import _, { findWhere } from 'underscore';
import { NovoEdFile } from 'shared/hooks/use-upload-file';

import { updateLectureComponent } from 'redux/actions/lecture-pages';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { AngularServicesContext } from 'react-app';
import HeaderColorPickerPopover from './header-color-picker-popover';
import HeaderPictureArea from './header-picture-area';
import { LectureComponentProps, LecturePageMode } from '..';

const headerComponentStyles = css`
  .bs4-form-control {
    padding: 0;
    border: none;
    background: none;
  }

  .left-border {
    width: ${halfSpacing}px;
  }

  textarea[name="header-input"] {
    overflow-y: hidden;
  }
`;

const inputContainerStyles = css`
  // Hide the icon button by default
  .icon-btn-container {
    display: none;
  }

  // Only display it when either the input area is hovered or the colorpicker is :placeholder-shown.
  // TODO: Determine if we need this same behavior elsewhere
  &:hover .icon-btn-container, .icon-btn-container.picker-visible {
    display: block;
  }
`;

// This CSS adjustment is defined in an emotion block so that stylus will rewrite in in RTL mode
const InputContainer = (props) => (
  <div
    css={inputContainerStyles}
    {...props}
  >
    {props.children}
  </div>
);

/** Maps the color attributes to translation strings for their input labels.
 * This must be a function instead of a simple Record because the translation functions under `t` cannot be evaluated until after
 * the `t` translation object has been initialiazed */
export const getColorInputLabels = (): Record<string, string> => ({
  textColor: t.LECTURE_PAGES.COMPONENTS.HEADER.TEXT_COLOR(),
  backgroundColor: t.LECTURE_PAGES.COMPONENTS.HEADER.BACKGROUND_COLOR(),
  leftBorderColor: t.LECTURE_PAGES.COMPONENTS.HEADER.BORDER_COLOR(),
  libraryIconColor: t.LECTURE_PAGES.COMPONENTS.HEADER.ICON_COLOR(),
  pictureBackgroundColor: t.LECTURE_PAGES.COMPONENTS.HEADER.BACKGROUND_COLOR(),
});

/** Displays one of three kinds of header text as a lecture component, sometimes referred to
 * as a "divider" component.
 * https://invis.io/MPXDT3TSUXF#/418568466_divider-_style_3_-_Title */
export const HeaderLectureComponent = (props: LectureComponentProps<HeaderComponentType> & { isPreview?: boolean, textClassName?: string }) => {
  const { lectureComponent } = props;
  // TODO: Should this be a default prop for all lcs?
  const currentCatalogId = useSelector(state => state.app.currentCatalogId);

  const dispatch = useAppDispatch();

  const angularServices = useContext(AngularServicesContext);

  const isNewComponent = useSelector<RootState, boolean>((state) => state.app.newComponent === lectureComponent?.id) ?? false;

  // Whether the current user has editing capabilities
  const userCanEdit = props.mode === LecturePageMode.EDIT && angularServices.CurrentPermissionsManager.isCourseBuilder();

  const [inputAutofocused] = useState(isNewComponent && userCanEdit);
  // Local state copies of component data used to reflect updates sooner than the API responses are received
  const [headerText, setHeaderText] = useState(lectureComponent.content);
  const [shape, setShape] = useState((lectureComponent.viewOptions as any)?.pictureShape ?? null);

  // Left offset to remove the white gap between the background color and circular image
  const circleOffset = shape === 'circle';

  const currentInstitution = useSelector(getCurrentInstitution);
  const currentCourse = useSelector((state) => getCurrentCourse(state));
  const brandColor = currentCourse?.headerColor ?? currentInstitution.brandColor;

  /** An object of color properties for this component based on which type of header this is */
  const [colorProps, setColorProps] = useState(() => {
    if (isHeaderStyle1(lectureComponent)) {
      return _.pick(lectureComponent.viewOptions, 'textColor', 'backgroundColor');
    } if (isHeaderStyle2(lectureComponent)) {
      const { leftBorderColor, textColor } = lectureComponent.viewOptions;
      const borderColor = leftBorderColor ?? brandColor;
      return { textColor, leftBorderColor: borderColor };
    } if (isHeaderStyle3(lectureComponent)) {
      return _.pick(lectureComponent.viewOptions, 'textColor', 'backgroundColor');
    }

    throw new Error(`Invalid component in HeaderLectureComponent: ${lectureComponent}`);
  });

  /**
   * Saves all edits to this component
   * @param text The header text
   * @param newViewOptions A partial set of view options to overwrite the existing options
   * @param pictureData Data for the picture. Not updated if `undefined`, and deleted if set to `null`
   */
  const save = (text: string, newViewOptions: Partial<typeof lectureComponent.viewOptions>, pictureData?: NovoEdFile) => {
    const deletePicture = !pictureData && pictureData !== undefined;

    dispatch(updateLectureComponent({
      catalogId: currentCatalogId,
      lecturePageId: props.currentLecture.id,
      componentData: {
        ...lectureComponent,
        content: text,
        viewOptions: {
          ...lectureComponent.viewOptions,
          ...newViewOptions,
        },
        picture: pictureData ? convertToPictureData(pictureData) : null,
        removePicture: deletePicture,
      },
    })).then(result => {

    });
  };

  /** Saves the header text change on input focus loss */
  const onBlur = (e): void => {
    // Do not update if the value hasn't changed
    if (e.target.value === lectureComponent.content || props.currentLecture.isLinked) {
      return;
    }

    save(e.target.value, colorProps);
  };

  const saveHeaderImage = (colors: Parameters<typeof save>[1], pictureData: Parameters<typeof save>[2]) => {
    save(lectureComponent.content, colors, pictureData);
  };

  const headerComponentComputedStyles = css`
    ${headerComponentStyles};

    .header-textarea {
      color: ${colorProps.textColor};
      padding-left: ${circleOffset ? '30px' : '0'};
    }
  `;

  const renderTextContent = () => {
    const outerClass = `course-title w-100 ${circleOffset ? 'pl-3' : ''}`;
    const innerClass = `header-textarea course-title ${props.textClassName ?? ''}`;

    if (props.isPreview) {
      return (
        <div className={outerClass}>
          <div className={innerClass}>
            {headerText}
          </div>
        </div>
      );
    }

    return (
      <NvTextArea
        className={outerClass}
        textareaClassName={innerClass}
        onBlur={(e) => { onBlur(e); }}
        style={{ color: colorProps.textColor }}
        name='header-input'
        placeholder={t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.HEADER_SAMPLE_CONTENT()}
        disabled={!userCanEdit}
        ariaLabel={headerText}
        value={headerText}
        autoFocus={inputAutofocused}
        onChange={(e) => setHeaderText(e.target.value)}
        role='heading'
        aria-level={3}
          /**
           * The height of the textarea is determined by the `react-textarea-autosize`
           * based on the value of the input. Here the height is blinking on rendering.
           * So setting the maxRows to restricted the height. maxRows is one of
           * the props in the `react-textarea-autosize`
           */
        {...{ maxRows: props.isPreview ? 1 : undefined }}
      />
    );
  };

  return (
    <div css={headerComponentStyles} className='d-flex'>
      {/* Left border for style 2 */}
      { isHeaderStyle2(lectureComponent) && <div className='left-border' style={{ backgroundColor: (colorProps as any).leftBorderColor }} />}
      {/* Left picture/icon area for style 3 */}
      { isHeaderStyle3(lectureComponent)
          && (
          <HeaderPictureArea
            currentLecture={props.currentLecture}
            userCanEdit={userCanEdit}
            picture={lectureComponent.picture}
            viewOptions={lectureComponent.viewOptions}
            saveHeaderImage={saveHeaderImage}
            setShape={setShape}
            isPreview={props.isPreview}
          />
          )}
      <InputContainer
        className='d-flex pl-4 pr-4 pt-2 pb-2 w-100 align-items-center'
        style={{
          backgroundColor: (isHeaderStyle1(lectureComponent) || isHeaderStyle3(lectureComponent)) ? (colorProps as any)?.backgroundColor : '',
          marginLeft: circleOffset ? '-30px' : '0',
        }}
        role={!userCanEdit ? 'heading' : null}
        aria-label={!userCanEdit ? headerText : null}
      >
        {renderTextContent()}
        {userCanEdit && (
          <div className='colorpicker-container ml-auto'>
            <HeaderColorPickerPopover
              colorValues={colorProps}
              onChange={(colorProp, newVal: string) => {
                const newColorProps = {
                  ...colorProps,
                  [colorProp]: newVal,
                };
                setColorProps(newColorProps);
                save(headerText, newColorProps);
              }}
            />
          </div>
        )}
      </InputContainer>
    </div>
  );
};

export default HeaderLectureComponent;
