import mergeWith from 'lodash/mergeWith';

import { replaceArrays } from 'shared/lodash-utils';

/* @ngInject */
export default function UserModelService(
  $rootScope,
  $timeout,
  moment,
  UsersResources,
  nvUtil,
  LearnerProfileResources,
  EmailVerificationResource,
  EmailUnsubscriptionsResource,
  AccountSettingsResource,
  UserManagementResources,
  _,
) {
  class UserModel {
    constructor(attributes) {
      _.extend(this, attributes);

      this.enrollments = this.enrollments?.map((enrollment) => ({
        ...enrollment,
        course: {
          ...enrollment.course,
          // Some features read from course.userCourse, so in order to support
          // them we need to make it available.
          userCourse: _.omit(enrollment, ['course']),
        },
      }));

      this.__preprocess();
    }

    __preprocess() {
      const getInitials = (user) => (user.firstName[0]?.toUpperCase() ?? '')
          + (user.lastName[0]?.toUpperCase() ?? '');

      this.initials = getInitials(this);
      this.fullName = `${this.firstName} ${this.lastName}`;
    }

    updateHasViewedRteFte() {
      this.hasViewedRteFte = true;
      UsersResources.updateHasViewedRteFte();
    }

    updateHasViewedI18nFte() {
      this.hasViewedI18nFte = true;
      UsersResources.updateHasViewedI18nFte();
    }

    addRecentColor(newColor) {
      if (!nvUtil.isValidHex(newColor)) {
        return;
      }
      const defaultColorPalette = Object.values($rootScope.InstitutionsManager.institution.orgColors.mainColors);
      if (!_.contains(defaultColorPalette, newColor)) {
        const index = _.indexOf(this.recentlyUsedColors, newColor);
        if (index > -1) {
          this.recentlyUsedColors.splice(index, 1);
        }

        this.recentlyUsedColors.unshift(newColor);

        if (this.recentlyUsedColors.length > 12) {
          this.recentlyUsedColors.pop();
        }

        UsersResources.saveRecentlyUsedColors({ recentlyUsedColors: this.recentlyUsedColors });
      }
    }

    saveProfile(firstName, lastName, emailAddress) {
      // Set the email as unvalidated if a new one was given and it doesn't match the current email

      const profileUpdates = {
        user: {
          firstName,
          lastName,
        },
      };

      if (emailAddress) {
        profileUpdates.user.email = emailAddress;
      }

      return LearnerProfileResources.saveProfile(profileUpdates).$promise.then((response) => {
        // Only update this model's data for each field if we don't have a partial-success error code
        // for that field
        let errorCodes = [];
        if (response.result?.errorCodes?.length > 0) {
          [errorCodes] = response.result.errorCodes;
        }

        if (errorCodes.indexOf('profile.error.first_name') === -1) {
          this.firstName = firstName;
        }

        if (errorCodes.indexOf('profile.error.last_name') === -1) {
          this.lastName = lastName;
        }

        this.fullName = `${firstName} ${lastName}`;

        if (emailAddress && errorCodes.indexOf('profile.error.email') === -1) {
          this.email = emailAddress;
        }

        this.isEmailVerified = response.result.verified;
        if (!this.isEmailVerified) {
          this.sendVerificationEmail().then((verificationResponse) => {
            this.isEmailVerified = verificationResponse.result.verified;
          });
        }

        this.__preprocess();

        return response;
      });
    }

    updatePassword(password, newPassword, repeatPassword) {
      return AccountSettingsResource.put({
        user: {
          currentPassword: password,
          password: newPassword,
          passwordConfirmation: repeatPassword,
        },
      }).$promise;
    }

    sendVerificationEmail() {
      return EmailVerificationResource.get().$promise;
    }

    getEmailUnsubscriptions() {
      return EmailUnsubscriptionsResource.get().$promise;
    }

    updateEmailUnsubscriptions(unsubscriptions) {
      return EmailUnsubscriptionsResource.update(unsubscriptions).$promise;
    }

    updateTimezone(isAutomaticTimezone, timezone) {
      const shortTzName = timezone ? timezone.shortName : '';
      const longTzName = timezone ? timezone.longName : '';
      return AccountSettingsResource.put({
        use_browser: isAutomaticTimezone,
        user: { time_zone: shortTzName },
        temp: { time_zone: shortTzName },
      }).$promise.then(() => {
        this.timeZone = longTzName;
      });
    }

    updateAutomaticBrowserTimezone(timezone) {
      return AccountSettingsResource.put({
        use_browser: false,
        user: { browser_time_zone: timezone },
        temp: { time_zone: timezone },
      }).$promise.then(() => {
        this.browserTimeZone = timezone;
      });
    }

    updateCourseRole(institutionId, courseId, catalogId, courseRole) {
      return UserManagementResources.updateCourseUsers({ catalogId }, {
        catalogId,
        users: [{
          user: {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            institutionId,
            courseId,
          },
        }],
        courseRoleId: courseRole.id,
      }).$promise.then(() => {
        this.courseRole = courseRole;
      });
    }

    /** Whether this user can still be unenrolled from a given enrollment. */
    unenrollUnavailable(enrollment) {
      // const enrollment = _.find(this.enrollments, (e) => e.course.catalogId === catalogId);
      const currentDate = moment();
      return currentDate > moment(enrollment.course.closeDate) || currentDate > moment(enrollment.course.endDate) || currentDate > moment(enrollment.accessCloseDate);
    }

    /**
     * It's possible we need to call this method once we have updated a user
     * with redux to keep data synced between redux and model.
     */
    updateFromReact(newModelData) {
      $timeout(() => {
        mergeWith(this, newModelData, replaceArrays);
        this.__preprocess();
      });
    }

    removeEnrollment(enrollmentId) {
      const index = this.enrollments.findIndex(e => enrollmentId === e.id);
      if (index >= 0) {
        return this.enrollments.splice(index, 1);
      }
      return null;
    }
  }

  return UserModel;
}
