import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AngularServicesContext } from 'react-app';
import t from 'react-translate';
import moment from 'moment';
import { useAppDispatch } from 'redux/store';
import { addCurrentCourseMyLearning, getFlyoutCurrentLearning } from 'redux/actions/courses';
import { BasicEnrollment } from 'redux/schemas/models/course';
import { CombinedCourse } from 'redux/schemas';
import { LoaderType } from 'shared/components/loading-placeholder';
import { getCurrentUserEnrollments } from 'redux/selectors/users';
import { Enrollment } from 'redux/schemas/models/my-account';
import { RolesService } from 'institutions/services/roles-service';
import { useInfiniteLoading } from 'shared/hooks/use-infinite-loading';
import FlyoutCourseCard from './flyout-course-card';
import FlyoutContainer from './flyout-container';
import { AllLoaded, EmptyContent, LoadingBar } from './loading-placeholders';

const MyLearningPanel = () => {
  const dispatch = useAppDispatch();
  const { $state, CurrentUserManager, InteroperabilityRoutes } = useContext(AngularServicesContext);
  const { currentCatalogId, currentInstitutionId, currentCourseId} = useSelector((state) => state.app);
  const institutionBrandColor = useSelector((state) => state.models.institutions[currentInstitutionId]?.brandColor);
  const isAdmin = !!CurrentUserManager.isAdmin();
  const allCourses = useSelector((state) => state.models.courses);
  const hasCurrentCourse = currentCatalogId && $state.includes('course-wrapper');
  const currentUserEnrollments: Enrollment[] = useSelector(getCurrentUserEnrollments);
  const {
    items,
    noItems,
    loading,
    loadingMore,
    hasMore,
    ids,
  } = useInfiniteLoading(
    'myLearning',
    'basicEnrollments',
    { page_size: 30 },
    getFlyoutCurrentLearning,
  );
  const enrollments = items as BasicEnrollment[];

  useEffect(() => {
    const currentCourse: CombinedCourse = allCourses[currentCatalogId];
    const isMentorInCurrentCourse = currentUserEnrollments.some(enrollment => RolesService.isMentor(enrollment.roles));
    // added the condition of currentCourseId, as, in the admin dashboard if you select the options menu,
    // the currentCatalogId exists, but you are not on a course.
    if (currentCourse && !currentCourse.isJourney && !isMentorInCurrentCourse && !currentCourse.isContentManagementCollection && currentCourseId) {
      const currentEnrollment: BasicEnrollment = {
        id: currentCourse.id,
        catalogId: currentCatalogId,
        name: currentCourse.name,
        thumbnail: currentCourse.thumbnail,
        closeDate: currentCourse.closeDate,
        headerColor: currentCourse.headerColor,
        inJourneys: currentCourse.inJourneys,
        isPrimary: currentCourse.isPrimary,
        isCohort: currentCourse.isCohort,
      };
      dispatch(addCurrentCourseMyLearning(currentEnrollment));
    }
  }, [hasCurrentCourse]);

  return (
    <FlyoutContainer title={t.LHS.MY_LEARNING()}>
      <>
        {loading && <LoadingBar loaderType={LoaderType.FULL} />}
        {loadingMore && <LoadingBar loaderType={LoaderType.HALF} />}
        { !loading && noItems
          ? <EmptyContent text={t.LHS.NO_COURSES()} />
          : ids.map((id, index) => (
            <FlyoutCourseCard
              index={index}
              label={enrollments[id].name}
              link={InteroperabilityRoutes.courseHomePath(allCourses[enrollments[id].catalogId])}
              isCurrentCourse={(currentCatalogId === enrollments[id].catalogId) && $state.includes('course-wrapper')}
              key={id}
              image={enrollments[id].thumbnail}
              bgcolor={enrollments[id].headerColor || institutionBrandColor}
              isClosed={moment(enrollments[id].closeDate).isBefore(moment())}
              isAdmin={isAdmin}
              tooltip={enrollments[id].catalogId}
              badgeLabel={enrollments[id].inJourneys?.length ? enrollments[id].inJourneys[0].name : null}
              badgeUrl={InteroperabilityRoutes.journeyHomePathFromId(allCourses[enrollments[id].catalogId], enrollments[id].inJourneys?.length ? enrollments[id].inJourneys[0].catalogId : '')}
              isCohort={enrollments[id].isCohort}
              isPrimary={enrollments[id].isPrimary}
            />
          ))}
        {!hasMore && !noItems && <AllLoaded />}
      </>
    </FlyoutContainer>
  );
};

export default MyLearningPanel;
