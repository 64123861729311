import { schema } from 'normalizr';
import { LectureComponentListSchema } from './lecture-components';
import { NLecturePage } from '../models/lecture-page';
import { CommunicationListSchema } from './course-communications';

export const LecturePageSchema = new schema.Entity<NLecturePage>('lecturePages', {
  lectureComponents: LectureComponentListSchema,
  communications: CommunicationListSchema,
});

export const LecturePageListSchema = new schema.Array(LecturePageSchema);

export interface LecturePagLinksParams {
  startingIndex: number;
  courseId: number;
  lectureSectionId: number;
  lecturePages: {
    id: number,
    index: number,
    releaseDate: string
  }[];
}

export interface LecturePageUnLinkParams {
  courseId: number,
  lecturePages: {
    // The linked lecture page id
    id: number
  }[],
}
