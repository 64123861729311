import React from 'react';
import moment from 'moment';
import t from 'react-translate';

import NvIcon from 'shared/components/nv-icon';
import { TeamSet } from 'redux/schemas/models/team-set';

import { CheckIcon, todoIcon, todoProgress } from './todo-row';
import ProgressIcon from '../progress-icon';

type TeamSetTodoProps = TeamSet & {
  thumbnailSrc: string,
  backgroundColor: string,
};

function TeamSetTodo({
  type,
  title,
  progress,
  deadline,
  thumbnailSrc,
  backgroundColor,
}: TeamSetTodoProps) {
  const deadlineMoment = moment(deadline);
  const deadlineDay = deadlineMoment.format('dddd');
  const deadlineTime = deadlineMoment.format('lll');
  const currentMoment = moment();

  const isMissed = progress === todoProgress.MISSED;
  const isNotStarted = progress === todoProgress.NOT_STARTED;
  const inProgress = progress === todoProgress.IN_PROGRESS;
  const isComplete = progress === todoProgress.COMPLETED;

  const showDueSoon = (isNotStarted || inProgress)
    && deadlineMoment > currentMoment
    && deadlineMoment < moment().add(7, 'days');


  const todoClassNames = [
    'todo-container',
    isComplete && 'completed',
    showDueSoon && 'due-soon',
    inProgress && 'in-progress',
  ]
    .filter(Boolean)
    .join(' ');

  const icon = <NvIcon icon={todoIcon[type]} size='smallest' />;
  const showTodoIcon = isNotStarted || isMissed;
  const showIconProgress = inProgress;
  const showCheckIcon = isComplete;

  return (
    <div className={todoClassNames}>
      <div className='todo-description'>
        <div className='todo-cover'>
          {thumbnailSrc
            ? <img src={thumbnailSrc} alt='' />
            : <div className='square-thumbnail' style={{ backgroundColor }} />}
        </div>
        <div className='todo-icon'>
          {showTodoIcon && icon}
          {showCheckIcon && <CheckIcon />}
          {showIconProgress && <ProgressIcon>{icon}</ProgressIcon>}
        </div>
        <div className='todo-title'>
          {title}
        </div>
      </div>
      <div className='todo-content'>
        <div className='status'>
          {isComplete && t.TIMELINE.EXERCISE_STATUS.COMPLETED()}
          {inProgress && t.TIMELINE.EXERCISE_STATUS.IN_PROGRESS()}
          {isMissed && t.TIMELINE.EXERCISE_STATUS.MISSED()}
        </div>
        {deadline && (
          <div className='date'>
            <span className='day'>{deadlineDay}</span>
            <span className='time'>{deadlineTime}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default TeamSetTodo;
