import React from 'react';
import { css } from '@emotion/react';

import { gray5 } from 'styles/global_defaults/colors';

type DividerProps = {
  color?: string;
};

const Divider = (props: DividerProps) => {
  const itemStyles = css`
    height: 1px;
    border-top: 1px solid ${props?.color ?? gray5};
  `;

  return <div css={itemStyles} />;
};

export default Divider;
