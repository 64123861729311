import { jsx } from '@emotion/react';
import { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import t from 'react-translate';
import { useSelector } from 'react-redux';


import { getRoleAliases } from 'redux/selectors/institutions';

import { useAppDispatch } from 'redux/store';
import { addOrgMentor, searchNonAdminUsers } from 'redux/actions/org-mentors';
import { addAlertMessage } from 'redux/actions/alert-messages';

import { OrgAdminRole } from 'institutions/services/roles-service';
import { AlertMessageType } from 'redux/schemas/app/alert-message';

import { UserItemProps } from './user-list-item';
import UserAddAndSearch, { UserAddProps } from './user-add-and-search';

const AddMentorModal = () => {
  const dispatch = useAppDispatch();
  const currentInstitutionId = useSelector((state) => state.app.currentInstitutionId);
  const roleAlias = useSelector(getRoleAliases);

  const onAddNewMentor = useCallback((user: UserAddProps): Promise<any> => new Promise((resolve, reject) => {
    dispatch(addOrgMentor({
      user: {
        ...user,
        admin: false,
      },
      institutionId: currentInstitutionId,
      adminRole: OrgAdminRole.ORG_MENTOR,
    })).then((response: any) => {
      const fullName = `${response?.payload?.result?.user?.firstName} ${response?.payload?.result?.user?.lastName}`;
      if (response.error) {
        // Failed to add org Mentor
        let errorMessage = '';
        if (response.error?.code === 'error.user_is_already_in_organization') {
          errorMessage = t.INSTITUTIONS.ORG_MENTORS.USER_IS_ALREADY_IN_ORGANIZATION(
            { mentorAlias: roleAlias.mentor.downcasedSingularized },
          );
        } else if (response?.error?.code === 'error.user_is_novoed_admin') {
          errorMessage = t.USERS.REGISTRATION.ERRORS.USER_IS_NOVOED_ADMIN();
        } else {
          errorMessage = t.FORM.ERROR_SOMETHING_WRONG();
        }
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: errorMessage,
        }));
      } else {
        dispatch(addAlertMessage({
          type: AlertMessageType.SUCCESS,
          header: t.FORM.SUCCESS_BANG(),
          message: t.INSTITUTIONS.ORG_MENTORS.ADD_MENTOR_SUCCESS({
            name: fullName || '',
            mentorAlias: roleAlias.mentor.downcasedSingularized,
          }),
        }));
        resolve(user);
      }
    });
  }), [currentInstitutionId, dispatch, roleAlias]);

  const onSearchNonMentors = useCallback((query: string): Promise<UserItemProps[]> => new Promise((resolve) => {
    dispatch(searchNonAdminUsers({
      institutionId: currentInstitutionId,
      queryTerm: query,
    })).then((response) => {
      resolve(response.payload.result);
    });
  }), [currentInstitutionId, dispatch]);

  return (
    <div className='py-2 px-6'>
      <UserAddAndSearch
        manualAddLabel={t.INSTITUTIONS.ORG_MENTORS.MANUALLY_ADD_MANAGER_DESC({
          mentorAlias: roleAlias.mentor.downcasedSingularized,
        })}
        searchLabel={t.INSTITUTIONS.ORG_MENTORS.MANUALLY_ADD_SEARCH_LABEL()}
        searchPlaceholder={t.INSTITUTIONS.ORG_MENTORS.SEARCH_PLACEHOLDER()}
        usersFoundTitle={t.INSTITUTIONS.ORG_MENTORS.FOUND_USERS_WHILE_SEARCH()}
        onAdd={onAddNewMentor}
        onSearch={onSearchNonMentors}
      />
    </div>
  );
};

export default AddMentorModal;
