/* @ngInject */
export default function ConfigureMsTeamsBotIntegrationModalCtrl(

  $uibModalInstance,
  AlertMessages,
  InstitutionsManager,
) {
  const vm = this;

  vm.InstitutionsManager = InstitutionsManager;
  vm.showErrorMessage = false;
  vm.InstitutionsManager.hasEnabledMsTeamsBotIntegration = false;

  vm.closeModal = () => {
    $uibModalInstance.dismiss('cancel');
  };

  vm.save = () => {
    vm.savingAPI = true;
    vm.showErrorMessage = false;

    InstitutionsManager.enableMsTeamsBotIntegrations(vm.clientTenantId)
      .then((response) => {
        vm.savingAPI = false;
        if (response.result) {
          InstitutionsManager.institution.hasMicrosoftBotIntegration = true;
          vm.closeModal();
          AlertMessages.success('FORM.SUCCESS_BANG', 'INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_TEAMS_BOT_INTEGRATION.ENABLE.SUCCESS', {}, {});
        }
      })
      .catch(({ data: { error } }) => {
        vm.savingAPI = false;
        if (error?.code === 'error.ms_bot_integration.authentication_failed') {
          AlertMessages.error(
            'INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_TEAMS_BOT_INTEGRATION.ENABLE.ERROR_TITLE',
            'INSTITUTIONS.ADVANCED_SETTINGS.MICROSOFT_TEAMS_BOT_INTEGRATION.ENABLE.FAILURE',
            {},
            {},
          );
        } else {
          AlertMessages.error(
            'FORM.OOPS',
            'FORM.SOMETHING_WRONG',
            {},
            {},
          );
        }
        vm.showErrorMessage = true;
      });
  };
}
