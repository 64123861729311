/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'redux/schemas';
import {
  QuizQuestion,
  QuizSubmission,
  QuizQuestionFeedback,
  QuizQuestionSubmission,
  ResponseOption,
} from 'redux/schemas/models/progressive-quiz';
import { QuizAIQuestion } from 'redux/schemas/models/quiz';
import { uniqueId } from 'underscore';

export type GetQuestionParams = {
  questionSetId: number;
  page?: number;
};

export const getQuizQuestion = createAsyncThunk<QuizQuestion, GetQuestionParams>(
  'GET_QUIZ_QUESTION',
  async ({ questionSetId, page }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const params = page ? {
      page,
    } : undefined;

    const response = await axios.get(`${catalogId}/question_sets/${questionSetId}/paginated_questions.json`, {
      params,
    });

    return response.data.result;
  },
);

type AddQuestionParams = {
  questionSetId: number;
  currentQuestionId: number;
  questionType: string;
};

export const addQuizQuestion = createAsyncThunk<QuizQuestion, AddQuestionParams>(
  'ADD_QUIZ_QUESTION',
  async ({ currentQuestionId, questionSetId, questionType }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const response = await axios.post(`${catalogId}/question_sets/${questionSetId}/questions.json`, {
      question: {
        questionSetId,
        questionText: '',
        isRequired: true,
        currentQuestionId,
        type: questionType,
      },
    });

    return response.data.result;
  },
);

type EditQuizQuestionParams = {
  id: number;
  patch: {
    questionText?: string;
    fileName?: string | null;
    fileSize?: number | null;
    fileUniqueId?: string | null;
    fileContentType?: string | null;
    // Used for reordering
    newOrder?: number[]; // To apply reorder locally
    responseOption?: Record<number, { index: number }> // Sent to backend
  },
};

export const editQuizQuestion = createAsyncThunk<QuizQuestion, EditQuizQuestionParams>(
  'EDIT_QUIZ_QUESTION',
  async ({ id, patch }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const response = await axios.patch(`${catalogId}/questions/${id}.json`, {
      question: patch,
    });

    return response.data.result;
  },
);

export const deleteQuizQuestion = createAsyncThunk<QuizQuestion, number>(
  'DELETE_QUIZ_QUESTION',
  async (quizQuestionId, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const response = await axios.delete(`${catalogId}/questions/${quizQuestionId}.json`);

    return response.data.result;
  },
);

export const duplicateQuizQuestion = createAsyncThunk<QuizQuestion, number>(
  'DUPLICATE_QUIZ_QUESTION',
  async (quizQuestionId, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const response = await axios.post(`${catalogId}/questions/${quizQuestionId}/duplicate_question.json`);

    return response.data.result;
  },
);

export const addQuizQuestionOption = createAsyncThunk<ResponseOption, { questionId: number, optionContent: string }>(
  'ADD_QUIZ_QUESTION_OPTION',
  async ({ questionId, optionContent }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const response = await axios.post(`${catalogId}/questions/${questionId}/response_options.json`, {
      responseOption: {
        optionContent,
        questionId,
      },
    });

    return response.data.result;
  },
);

export type EditQuizQuestionOptionParams = {
  id: number;
  patch: {
    isCorrect?: boolean,
    optionContent?: string,
  },
};

export const editQuizQuestionOption = createAsyncThunk<ResponseOption, EditQuizQuestionOptionParams>(
  'EDIT_QUIZ_QUESTION_OPTION',
  async ({ id, patch }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const response = await axios.patch(`${catalogId}/response_options/${id}.json`, {
      responseOption: {
        [id]: patch,
      },
    });

    return response.data.result;
  },
);

export const deleteQuizQuestionOption = createAsyncThunk<ResponseOption, number>(
  'DELETE_QUIZ_QUESTION_OPTION',
  async (quizQuestionOptionId, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const response = await axios.delete(`${catalogId}/response_options/${quizQuestionOptionId}.json`);

    return response.data.result;
  },
);

type FeedbackEntity = {
  feedbackText: string;
};

type ShortAnswerFeedbackEntity = {
  explanation: string;
  secondaryExplanation: string;
  questionOptionId: number;
};

type NumberAnswerEntity = {
  topOfRange: string;
  bottomOfRange: string;
  questionOptionId: number;
};

type SetQuestionOptionFeedbackParams = {
  questionOptionId: number;
  feedback: string;
};

export const addQuestionOptionFeedback = createAsyncThunk<FeedbackEntity, SetQuestionOptionFeedbackParams>(
  'ADD_QUESTION_OPTION_FEEDBACK',
  async ({ questionOptionId, feedback }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const response = await axios.post(`${catalogId}/response_options/${questionOptionId}/create_response_option_feedback.json`, {
      feedback: {
        feedbackText: feedback,
      },
    });

    return response.data.result;
  },
);

export const editQuestionOptionFeedback = createAsyncThunk<FeedbackEntity, SetQuestionOptionFeedbackParams>(
  'EDIT_QUESTION_OPTION_FEEDBACK',
  async ({ questionOptionId, feedback }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const response = await axios.patch(`${catalogId}/response_options/${questionOptionId}/update_response_option_feedback.json`, {
      feedback: {
        feedbackText: feedback,
      },
    });

    return response.data.result;
  },
);

export const deleteQuestionOptionFeedback = createAsyncThunk<FeedbackEntity, number>(
  'DELETE_QUESTION_OPTION_FEEDBACK',
  async (questionOptionId, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const response = await axios.delete(`${catalogId}/response_options/${questionOptionId}/delete_response_option_feedback.json`);

    return response.data.result;
  },
);

type SetQuestionFeedbackParams = {
  questionId: number;
  feedback?: string;
  questionFeedbackId?: number;
};

export const addQuizQuestionFeedback = createAsyncThunk<QuizQuestionFeedback, SetQuestionFeedbackParams>(
  'ADD_QUIZ_QUESTION_FEEDBACK',
  async ({ questionId, feedback }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const response = await axios.post(`${catalogId}/questions/${questionId}/create_attempt_feedback.json`, {
      feedback: {
        feedbackText: feedback,
      },
    });

    return response.data.result;
  },
);

export const editQuizQuestionFeedback = createAsyncThunk<QuizQuestionFeedback, SetQuestionFeedbackParams>(
  'EDIT_QUIZ_QUESTION_FEEDBACK',
  async ({ questionId, feedback, questionFeedbackId }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const response = await axios.patch(`/${catalogId}/questions/${questionId}/update_attempt_feedback/${questionFeedbackId}.json`, {
      feedback: {
        feedbackText: feedback,
      },
    });

    return response.data.result;
  },
);

export const deleteQuizQuestionFeedback = createAsyncThunk<QuizQuestionFeedback, SetQuestionFeedbackParams>(
  'DELETE_QUIZ_QUESTION_FEEDBACK',
  async ({ questionId, questionFeedbackId }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const response = await axios.delete(`/${catalogId}/questions/${questionId}/delete_attempt_feedback/${questionFeedbackId}.json`);

    return response.data.result;
  },
);

type CreateQuizQuestionSubmissionParams = {
  questionId: number;
  userResponse: number | number[] | string | {};
};

export const createQuizQuestionSubmission = createAsyncThunk<QuizQuestionSubmission, CreateQuizQuestionSubmissionParams>(
  'CREATE_QUIZ_QUESTION_SUBMISSION',
  async ({ questionId, userResponse }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const response = await axios.post(`/${catalogId}/question/${questionId}/question_submissions.json`, {
      userResponse,
    });

    return response.data.result;
  },
);

export const unsetQuizQuestionResponse = createAction<number>('UNSET_QUIZ_QUESTION_RESPONSE');

export const submitQuizAttempt = createAsyncThunk<QuizSubmission, number>(
  'SUBMIT_QUIZ_ATTEMPT',
  async (questionSetId: number, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const response = await axios.post(`/${catalogId}/question_set_submissions.json`, {
      questionSetId,
      userResponse: {},
    });

    return response.data.result;
  },
);

type ReviewPreviousAttemptParams = {
  reveal: boolean;
  questionSetId: number;
};

export const reviewPreviousAttempt = createAsyncThunk<QuizSubmission, ReviewPreviousAttemptParams>(
  'REVIEW_PREVIOUS_QUIZ_ATTEMPT',
  async ({
    reveal,
    questionSetId,
  }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const response = await axios.get(`/${catalogId}/last_question_revision/${questionSetId}.json`, {
      params: reveal ? { reveal_answer: true } : undefined,
    });

    return response.data.result;
  },
);

export const updateQuestionsOrder = createAction<{ questionSetId: number, addedIndex: number, removedIndex: number }>('REORDER_QUESTIONS');

export const reorderQuestions = createAsyncThunk<QuizQuestion, { questionSetId: number, questionId: number, addedIndex: number, prevQuestions: number[] }>(
  'REORDER_QUESTIONS',
  async ({
    questionId,
    addedIndex,
  }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const response = await axios.patch(`/${catalogId}/questions/${questionId}/reorder_question.json`, {
      index: addedIndex,
    });

    return response.data.result;
  },
);

export const editAnswerFeedback = createAsyncThunk<ShortAnswerFeedbackEntity, ShortAnswerFeedbackEntity>(
  'EDIT_ANSWER_FEEDBACK',
  async ({ questionOptionId, explanation, secondaryExplanation }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const response = await axios.put(`${catalogId}/response_options/${questionOptionId}.json`, {
      responseOption: {
        [questionOptionId]: {
          explanation,
          secondaryExplanation,
        },
      },
    });

    return response.data.result;
  },
);

export const editNumberAnswer = createAsyncThunk<NumberAnswerEntity, NumberAnswerEntity>(
  'EDIT_NUMBER_ANSWER',
  async ({ questionOptionId, topOfRange, bottomOfRange }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const catalogId = state.app.currentCatalogId;

    const response = await axios.put(`${catalogId}/response_options/${questionOptionId}.json`, {
      responseOption: {
        [questionOptionId]: {
          bottomOfRange,
          topOfRange,
        },
      },
    });

    return response.data.result;
  },
);

export interface GetAiQuizQuestionsParams {
  catalogId: string,
  aiProperties: {
    aiOriginTarget: {
      type: string,
      id: number,
    },
    quizType: string,
    questionsCount: number,
    existingQuizLectureComponentId: number
  }
}

export const getAiQuizQuestions = createAsyncThunk<QuizAIQuestion[], GetAiQuizQuestionsParams>(
  'GET_AI_QUIZ_QUESTIONS',
  (params, thunkAPI) => axios.post(
    `/${params.catalogId}/questions/ai_new.json`,
    { aiProperties: params.aiProperties },
  ).then((response) => (response.data.result?.questionsAttributes ?? [])
    .map((question) => ({
      ...question,
      // Add a unique ID to each question, starting with the text 'ai_q_'.
      id: uniqueId('ai_q_'),
    })))
    .catch((e) => thunkAPI.rejectWithValue(e.response.data.error)),
);

export const resetProgressiveQuizResponses = createAction<{ questionSetId: number }>('RESET_PROGRESSIVE_QUIZ_RESPONSES');
