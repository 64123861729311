import { MockDefinition, MockTypes } from '../axios/axios-mocks';

const CredlyMocks: MockDefinition[] = [
  {
    type: MockTypes.POST,
    url: '/institutions/1/save_credly_settings.json',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: {
        message: 'Credly was successfully integrated',
        credlySettings: {
          isActive: true,
          organizationId: '6dac84e3-7283-41f8-9acb-2b2af277df00',
          authorizationToken: 'XXwJArBw8J6aJezzluJSzCn_UFzg1DayuwlJ',
        },
      },
    },
  },
  // {
  //   type: MockTypes.POST,
  //   url: '/institutions/1/save_credly_settings.json',
  //   status: 400,
  //   response: {
  //     message: 'Failed the request',
  //     result: { message: 'Something was wrong with the Credly integration' },
  //   },
  // },
  {
    type: MockTypes.POST,
    url: '/institutions/1/disable_credly_settings.json',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: {
        message: 'Credly was successfully disabled',
        credlySettings: {
          isActive: false,
          organizationId: '',
          authorizationToken: '',
        },
      },
    },
  },
  // {
  //   type: MockTypes.POST,
  //   url: '/institutions/1/disable_credly_settings.json',
  //   status: 400,
  //   response: {
  //     message: 'Failed the request',
  //     result: { message: 'Something was wrong while disabling Credly' },
  //   },
  // },
  {
    type: MockTypes.POST,
    url: '/institutions/1/validate_credly_badge.json',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: {
        message: 'Credly badge was successfully validated',
        credlySettings: {
          isActive: true,
          organizationId: '6dac84e3-7283-41f8-9acb-2b2af277df00',
          authorizationToken: 'XXwJArBw8J6aJezzluJSzCn_UFzg1DayuwlJ',
          badgeTemplate: {
            id: 'qwerty',
            name: 'Badge Name',
            description: 'Description lorem sit amet amet morbi a sapien condimentum. Vestium, ac cras sit egestas. Morbi auctor tincidunt aliquam adipisasf cing malesuada sem id. Risus viverra eget amet malesuada ac.',
            state: 'active',
            url: 'https://www.credly.com/org/organization-4/badge/badge-template-1',
            imageUrl: 'https://images.credly.com/size/680x680/images/3e0593b8-a799-4ad4-8ba0-e344376fe694/Platform_Trainer_badge.png',
          },
        },
      },
    },
  },
  // {
  //   type: MockTypes.POST,
  //   url: '/institutions/1/validate_credly_badge.json',
  //   status: 400,
  //   response: {
  //     message: 'Failed the request',
  //     result: {
  //       message: 'Template Id not found',
  //       credlySettings: {
  //         isActive: true,
  //         organizationId: '6dac84e3-7283-41f8-9acb-2b2af277df00',
  //         authorizationToken: 'XXwJArBw8J6aJezzluJSzCn_UFzg1DayuwlJ',
  //         badgeTemplate: {
  //           id: '',
  //           name: '',
  //           description: '',
  //           state: '',
  //           url: '',
  //           imageUrl: '',
  //         },
  //       },
  //     },
  //   },
  // },
  {
    type: MockTypes.POST,
    url: '/institutions/1/save_credly_badge_template.json',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: {
        message: 'Credly badge template was successfully saved',
        credlySettings: {
          isActive: true,
          organizationId: '6dac84e3-7283-41f8-9acb-2b2af277df00',
          authorizationToken: 'XXwJArBw8J6aJezzluJSzCn_UFzg1DayuwlJ',
          badgeTemplate: {
            id: 'qwerty',
            name: 'Badge Name',
            description: 'Description lorem sit amet amet morbi a sapien condimentum. Vestium, ac cras sit egestas. Morbi auctor tincidunt aliquam adipisasf cing malesuada sem id. Risus viverra eget amet malesuada ac.',
            state: 'active',
            url: 'https://www.credly.com/org/organization-4/badge/badge-template-1',
            imageUrl: 'https://images.credly.com/size/680x680/images/3e0593b8-a799-4ad4-8ba0-e344376fe694/Platform_Trainer_badge.png',
          },
        },
      },
    },
  },
  // {
  //   type: MockTypes.POST,
  //   url: '/institutions/1/save_credly_badge_template.json',
  //   status: 400,
  //   response: {
  //     message: 'Failed the request',
  //     result: {
  //       message: 'Something was wrong with saving the Credly badge template',
  //       credlySettings: {
  //         isActive: true,
  //         organizationId: '6dac84e3-7283-41f8-9acb-2b2af277df00',
  //         authorizationToken: 'XXwJArBw8J6aJezzluJSzCn_UFzg1DayuwlJ',
  //         badgeTemplate: {
  //           id: '',
  //           name: '',
  //           description: '',
  //           state: '',
  //           url: '',
  //           imageUrl: '',
  //         },
  //       },
  //     },
  //   },
  // },
  {
    type: MockTypes.POST,
    url: '/institutions/1/remove_credly_badge_template.json',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: {
        message: 'Credly badge was successfully removed',
        credlySettings: {
          isActive: true,
          organizationId: '6dac84e3-7283-41f8-9acb-2b2af277df00',
          authorizationToken: 'XXwJArBw8J6aJezzluJSzCn_UFzg1DayuwlJ',
          badgeTemplate: {
            id: '',
            name: '',
            description: '',
            state: '',
            url: '',
            imageUrl: '',
          },
        },
      },
    },
  },
  // {
  //   type: MockTypes.POST,
  //   url: '/institutions/1/remove_credly_badge_template.json',
  //   status: 400,
  //   response: {
  //     message: 'Failed the request',
  //     result: { message: 'Something was wrong while removing the Credly badge' },
  //   },
  // },
];
export default CredlyMocks;
