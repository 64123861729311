import axios from 'axios';
import * as humps from 'humps';
import setupMocks from '../mocks/index';
// eslint-disable-next-line import/no-extraneous-dependencies

import { shouldChangeCase } from '../services/change-case-interceptor';

// eslint-disable-next-line import/prefer-default-export
export const configAxios = () => {
// TODO: We should move this axios setup logic to a different file
// TODO: For reviewer: If I don't do this, the PUT for saving the lecture component fails on a 500 error.
// We also do a similar default header set with angularjs elsewhere in this file
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  // Register request interceptors for Axios
  axios.interceptors.request.use((config) => {
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    // humps operations destory FormData, so don't touch them if the paylod is a FormData
    if (shouldChangeCase(config.url) && !(config.data instanceof FormData)) {
      config.data = humps.decamelizeKeys(config.data);
    }

    return config;
  }, (error) => Promise.reject(error));

  axios.interceptors.response.use((response) => {
  // Avoid camelizing our language .json files
    if (shouldChangeCase(response.config.url)) {
      response.data = humps.camelizeKeys(response.data);
    }
    return response;
  }, (error) => Promise.reject(error));
  // run mocks only on dev with mocks flag set
  if (process.env.NODE_ENV === 'development' && process.env.USE_MOCKS === 'true') {
    setupMocks();
  }
};
