/* @ngInject */
export default function nvLiDisabled() {
  return {
    restrict: 'A',
    scope: {
      disabled: '=',
    },
    link(scope, element, attributes) {
      function setDisabled(disabled) {
        if (disabled) {
          element.addClass('disabled nv-li-disabled');
          element.children('a:first').addClass('nv-li-disabled');
        } else {
          element.removeClass('disabled nv-li-disabled');
          element.children('a:first').removeClass('nv-li-disabled');
        }
      }

      scope.$parent.$watch(attributes.nvLiDisabled, (disabled) => {
        setDisabled(disabled);
      });

      setDisabled(attributes.nvLiDisabled);
    },
  };
}
