import React from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/schemas';
import { CompletionCriteriaType } from 'redux/schemas/app/timeline';

import Points from './content-tabs/points';
import Assignments from './content-tabs/assignments';
import Todos from './content-tabs/todos';

const styles = css`
  padding-bottom: 90px;
`;

const PanelContent = () => {
  const portableCompletionPanelSelectedTab: CompletionCriteriaType = useSelector(
    (state: RootState) => state.app.timeline.portableCompletionPanelSelectedTab,
  );

  return (
    <div css={styles} className='completion-criteria-panel-content'>
      {portableCompletionPanelSelectedTab === CompletionCriteriaType.POINTS && (<Points />)}
      {portableCompletionPanelSelectedTab === CompletionCriteriaType.ASSIGNMENTS && (<Assignments />)}
      {portableCompletionPanelSelectedTab === CompletionCriteriaType.TODOS && (<Todos />)}
    </div>
  );
};

export default PanelContent;
