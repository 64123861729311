/* @ngInject */
export default function TeamWorkspaceMembersController(
  requestTeam,
  TeamWorkspaceManager,
  CurrentUserManager,
  CurrentCourseManager,
  PageLevelManager,
  $stateParams,
  $state,
  $uibModal,
  $scope,
) {
  const vm = this;

  if ($state.includes('team-workspace-members-modal')) {
    vm.isPage = false;
  } else if ($state.includes('team-workspace-members-page')) {
    vm.isPage = true;
  }

  vm.TeamWorkspaceManager = TeamWorkspaceManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.PageLevelManager = PageLevelManager;
  vm.$stateParams = $stateParams;
  vm.launchWorkspaceContributionsModal = launchWorkspaceContributionsModal;

  function launchWorkspaceContributionsModal(memberId) {
    vm.TeamWorkspaceManager.fetchWorkspaceContributions(memberId).then((result) => {
      $uibModal.open({
        templateUrl: 'team_workspace/templates/workspace-contribution-modal.html',
        windowClass: 'contribution-modal',
        scope: $scope,
      });
    });
  }
}
