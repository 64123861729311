import React from 'react';
import * as yup from 'yup';
import moment from 'moment';
import last from 'lodash/last';
import * as humps from 'humps';
import assign from 'lodash/assign';
import { css } from '@emotion/react';
import { Omit } from 'utility-types';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, FieldError, FormProvider } from 'react-hook-form';

import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { AngularServicesContext } from 'react-app';
import { i18nJoin, JoinMode } from 'shared/services/i18n-utils';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { CourseRegistrationType } from 'redux/schemas/models/course';
import NvRadioButton from 'shared/components/inputs/nv-radio-button';
import { Value as Branding } from 'shared/components/branding-header';
import useAngularStateManager from 'shared/hooks/use-angular-state-manager';
import { gray3, gray4, gray6, warning } from 'styles/global_defaults/colors';
import { createJourney, updateJourney } from 'redux/actions/learning-journeys';
import useJourneyFormUtils from 'learning_journeys/hooks/use-journey-form-utils';
import {
  textXSFontSize,
  textSmallFontSize,
  textSmallLineHeight,
} from 'styles/global_defaults/fonts';
import {
  openSavingOverlay,
  closeSavingOverlay,
} from 'redux/actions/saving-overlay';
import {
  halfSpacing,
  largeSpacing,
  doubleSpacing,
  quarterSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import NvTextInput, {
  LABEL_SIZE,
} from 'shared/components/inputs/nv-text-input';
import NvDatePicker, {
  DatePickerType,
} from 'shared/components/inputs/nv-datepicker';
import {
  FormOfferingsBrandingHeader,
} from 'offerings/components/offering-branding-header';
import ConfigureEntitlementsModal, {
  Entitlement,
  Props as ConfigureEntitlementsModalProps,
} from 'offerings/components/configure-entitlements-modal';
import { config } from '../../../../config/pendo.config.json';
import SumTotalIntegration, { SumTotal } from './sumtotal-integration';

type FormSubmissionType = 'UPDATED' | 'CREATED';

type FormData = {
  catalogId: string,
  branding: Branding,
  releaseDate: moment.Moment | null,
  closureDate: moment.Moment | null,
  entitlements: Entitlement[],
  registration: CourseRegistrationType | null,
  lmsActivityCode: string,
  lmsActivityId: string,
  degreedCourseId: string,
};

type FormProps = {
  catalogId: string,
  initialFormData: FormData,
};

const MAX_NAME_LENGTH = 340;

const DEFAULT_FORM_DATA = {
  catalogId: '',
  releaseDate: null,
  closureDate: null,
  entitlements: [],
  registration: null,
  branding: {
    name: '',
    logo: null,
    fontColor: null,
    background: null,
    brandColor: null,
  },
  lmsActivityCode: '',
  lmsActivityId: '',
  degreedCourseId: '',
};

const Form = (props: FormProps) => {
  const {
    catalogId,
    initialFormData,
  } = props;

  const isEditing = !!catalogId;

  const dispatch = useAppDispatch();
  const justCreatedJourneyCatalogIdRef = React.useRef<string>();
  const currentInstitution = useSelector(getCurrentInstitution);
  const [catalogIdTaken, setCatalogIdTaken] = React.useState(false);
  const { $scope, $state } = React.useContext(AngularServicesContext);
  const [isCatalogIdFocused, setIsCatalogIdFocused] = React.useState(false);
  const currentJourney = useSelector((state) => state.models.courses[catalogId]);
  const [showEntitlementsModal, setShowEntitlementsModal] = React.useState(false);
  const [submissionType, setSubmissionType] = React.useState<FormSubmissionType | null>(null);
  const [sumTotalIntegration, setSumTotalIntegration] = React.useState<SumTotal>();
  const [sumTotalError, setSumTotalError] = React.useState(false);

  const validationSchemaRef = React.useRef(yup.object().shape({
    catalogId: yup.string()
      .min(1, t.VALIDATION.REQUIRED())
      .max(190, t.VALIDATION.MAX_LENGTH('190'))
      .novoEdCatalogId(t.COURSES.FORM.VALIDATION_TEXT.CATALOG_ID_PATTERN()),
    branding: yup.object().shape({
      name: yup.string()
        .min(1, t.VALIDATION.REQUIRED())
        .max(MAX_NAME_LENGTH)
        .notOnlySpaces(t.LEARNING_JOURNEYS.BASICS.HEADER.INVALID_TITLE()),
    }),
    registration: yup.mixed().oneOf([
      // NOTE: Doing toString() because values in form state are that type
      // I assume it's because of RHF
      CourseRegistrationType.CLOSED_ENROLLMENT.toString(),
      CourseRegistrationType.OPEN_BASED_ON_ENTITLEMENTS.toString(),
      CourseRegistrationType.FREE_ENROLLMENT_IN_INSTITUTION.toString(),
    ]),
  }));

  const methods = useForm({
    mode: 'onChange',
    defaultValues: initialFormData,
    resolver: yupResolver(validationSchemaRef.current) as any,
  });

  const {
    isDirty,
    isValid,
  } = methods.formState;

  const journeyFormUtils = useJourneyFormUtils(methods.formState);

  const angularStateManager = useAngularStateManager();

  const angularStateManagerRef = React.useRef(angularStateManager);

  React.useEffect(() => {
    if (submissionType !== null) {
      switch (submissionType) {
        case 'UPDATED': {
          const {
            previousStatesEntered,
            previousParamsEntered,
          } = angularStateManagerRef.current;

          const lastStateEntered = last<any>(previousStatesEntered)?.name;
          const lastParamsEntered = last(previousParamsEntered);

          if (lastStateEntered) {
            $state.go(lastStateEntered, lastParamsEntered);
          } else {
            $state.go('learning-journey-home', {
              catalogId: currentJourney.catalogId,
            });
          }

          break;
        }
        case 'CREATED':
          $state.go('edit-learning-journey', {
            isNewJourney: true,
            catalogId: justCreatedJourneyCatalogIdRef.current,
          });
          break;
        default: break;
      }
    }
  }, [$state, submissionType]);

  const styles = css`
    .container {
      padding: ${doubleSpacing}px 0;

      .help-text {
        color: ${gray4};
        font-size: ${textXSFontSize}px;

        &.input-has-label {
          padding-top: ${LABEL_SIZE}px;
        }
        &.lxp-journey {
          color: ${gray3};
        }
      }
    }

    .date-picker {
      margin-bottom: ${standardSpacing}px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .react-datepicker-wrapper {
        display: block;
      }
    }

    .field-info {
      color: ${gray3};
      font-size: ${textSmallFontSize}px;
      line-height: ${textSmallLineHeight}px;
    }

    .sumtotal-row {
      margin-bottom: ${standardSpacing}px;
      &:last-of-type {
        margin-bottom: 0;
      }
      :not(&:last-of-type) {
        padding-left: 0;
      }
    }

    .vertical-center-btn {
      margin: 2px 0;
    }

    .vertical-center-text {
      margin: ${halfSpacing}px 0;
    }
  `;

  const handleCatalogIdChange = () => setCatalogIdTaken(false);
  const handleCatalogIdBlur = () => setIsCatalogIdFocused(false);
  const handleCatalogIdFocus = () => setIsCatalogIdFocused(true);
  const configureEntitlements = () => setShowEntitlementsModal(true);
  const closeEntitlementsConfiguration = () => setShowEntitlementsModal(false);

  const handleFormCancel = () => {
    const {
      previousParamsEntered,
      previousStatesEntered,
    } = $scope.StateManager;

    const canGoBack = !!previousStatesEntered.length;

    if (canGoBack) {
      $state.go(last<any>(previousStatesEntered).name, last(previousParamsEntered));
    } else {
      methods.reset(initialFormData);
    }
  };

  const hasSumTotalIntegration = () => currentInstitution?.lmsConnectionSettings?.lms === 'sum_total';

  const hasEdCastIntegration = () => !!currentJourney?.edcastCourse;

  const showEdCastSection = () => !!currentJourney && hasEdCastIntegration();

  const edcastLastSyncUpAt = () => {
    const dateString = currentJourney?.edcastCourse?.lastSyncUpAt;
    if (!dateString) return '';
    const date = moment(dateString).format('MOMENT.MONTH_DAY_YEAR_TIME');
    return date;
  };

  const hasLxpCourse = () => currentInstitution?.degreedSettings && currentJourney?.degreedCourseId;

  const shouldDisableValidateButton = () => !sumTotalIntegration.editing || currentJourney?.released;

  const isActivityValidated = () => {
    if (!hasSumTotalIntegration()) return true;
    const { validating, validationError, lmsActivityCode, lmsActivityId } = sumTotalIntegration;
    const isValidated = lmsActivityId ? !!lmsActivityCode : true;
    return shouldDisableValidateButton() && !validationError && !validating && isValidated && !sumTotalError;
  };

  const shouldDisableSubmitButton = () => {
    const show = isValid && isDirty && isActivityValidated();
    return !show;
  };

  const getEntitlementsListing = () => {
    const entitlements = (methods.watch as any)('entitlements');

    return entitlements.map((entitlement) => {
      const profileSettings = currentInstitution.profileSettings.orgLevel.filter(
        profileSetting => profileSetting.isIntegrated || profileSetting.isCsvManaged,
      );

      function getEntitlementProfileSettingName() {
        return profileSettings.find((profileSetting) => (profileSetting.id === entitlement.profileSettingId))?.name;
      }

      return {
        key: getEntitlementProfileSettingName(),
        value: i18nJoin(entitlement.values, JoinMode.OR),
      };
    });
  };

  const getIsTypeOfRegistrationEnabled = (typeOfRegistration: CourseRegistrationType) => currentInstitution.courseEnrollmentTypes.includes(typeOfRegistration.toString());

  const handleFormSubmit = (data) => {
    // NOTE: Following lines formats the toString return value of a moment
    // object into a moment object again so we can get the ISO string (bad
    // practice that I know but done by that way here:
    // https://github.com/novoed/NovoEdWeb/blob/learning_journeys_master/app/institutions/components/course-clone-modal.tsx#L88)
    // TODO: Improve that
    const convertToFinalValue = (value) => (value ? moment(value).toISOString() : value);
    let { releaseDate, closureDate } = data;
    releaseDate = convertToFinalValue(releaseDate);
    closureDate = convertToFinalValue(closureDate);
    assign(data, {
      releaseDate,
      closureDate,
    });

    const payload: any = {
      used_for: 0, // Used for production
      is_journey: true,
      // This was removed from the UI in https://novoed.atlassian.net/browse/NOV-61368
      // We default this to the Standard features now.
      set_of_features: 0, // Standard features
      name: data.branding.name,
      catalog_id: data.catalogId,
      close_date: data.closureDate,
      release_date: data.releaseDate,
      delete_logo: !data.branding.logo,
      type_of_registration: data.registration,
      header_color: data.branding.brandColor,
      logo: data.branding.logo?.file ?? null,
      name_font_color: data.branding.fontColor,
      logo_size: data.branding.logo?.size ?? null,
      institution_id: $state.params.institutionId,
      delete_header_background: !data.branding.background,
      header_background: data.branding.background?.file ?? null,
      entitlements: data.entitlements.map((entitlement) => {
        const newEntitlement = { ...entitlement };

        if (!newEntitlement.id) {
          delete newEntitlement.id;
        }

        return humps.decamelizeKeys(newEntitlement);
      }),
    };

    if (data.lmsActivityId) {
      payload.lms_activity_id = data.lmsActivityId;
      payload.lms_activity_code = data.lmsActivityCode;
    }

    const handleError = (action) => {
      if (action.payload?.code === 'error.catalog_id_taken') {
        setCatalogIdTaken(true);
      } else {
        journeyFormUtils.showErrorAlert();
      }
    };

    dispatch(openSavingOverlay());

    const final = () => dispatch(closeSavingOverlay());

    if (isEditing) {
      payload.id = currentJourney.id;

      wrapThunkAction(dispatch(updateJourney(payload))).then(
        () => {
          methods.reset(data, {
            keepValues: true,
          });
          setSubmissionType('UPDATED');
          journeyFormUtils.showSuccessAlert(isEditing);
        },
        handleError,
      ).finally(final);
    } else {
      wrapThunkAction(dispatch(createJourney(payload))).then(
        (action: any) => {
          const { catalogId: justCreatedJourneyCatalogId } = action.payload;

          justCreatedJourneyCatalogIdRef.current = justCreatedJourneyCatalogId;

          methods.reset(data, {
            keepValues: true,
          });

          setSubmissionType('CREATED');

          journeyFormUtils.showSuccessAlert(isEditing);
        },
        handleError,
      ).finally(final);
    }
  };

  return (
    <FormProvider {...methods}>
      <FormConfigureEntitlementsModal
        name='entitlements'
        show={showEntitlementsModal}
        onClose={closeEntitlementsConfiguration}
      />
      <form onSubmit={methods.handleSubmit(handleFormSubmit)} css={styles}>
        <FormOfferingsBrandingHeader
          editable
          name='branding'
          updateLogoText={t.LEARNING_JOURNEYS.BASICS.HEADER.UPDATE_LOGO()}
          logoPickerText={t.LEARNING_JOURNEYS.BASICS.HEADER.UPLOAD_LOGO()}
          logoTooltipText={t.LEARNING_JOURNEYS.BASICS.HEADER.LOGO_TOOLTIP()}
          namePlaceholder={t.LEARNING_JOURNEYS.BASICS.HEADER.NAME_PLACEHOLDER()}
          backgroundImagePickerTooltipText={t.LEARNING_JOURNEYS.BASICS.HEADER.BACKGROUND_IMAGE_TOOLTIP()}
          errors={{
            name: methods.formState.errors.branding?.name?.message,
          }}
        />
        <div className='container'>
          <Section title={t.LEARNING_JOURNEYS.BASICS.BASICS()}>
            <div className='col-xs-8'>
              <NvTextInput
                required
                withForm
                // Using together with 'input-has-label' class for input
                // description.
                withLabel
                name='catalogId'
                onBlur={handleCatalogIdBlur}
                onFocus={handleCatalogIdFocus}
                onChange={handleCatalogIdChange}
                placeholder={t.LEARNING_JOURNEYS.BASICS.CATALOG_ID()}
                error={catalogIdTaken ? { message: t.COURSES.FORM.ERRORS.CATALOG_ID_TAKEN() } as FieldError : undefined}
              />
            </div>
            {isCatalogIdFocused && (
              <div className='col-xs-4 help-text input-has-label'>
                {t.LEARNING_JOURNEYS.BASICS.CATALOG_ID_HELP_TEXT()}
              </div>
            )}
          </Section>
          <Section title={t.LEARNING_JOURNEYS.BASICS.REGISTRATION()}>
            <div className='col-xs-8'>
              {getIsTypeOfRegistrationEnabled(CourseRegistrationType.FREE_ENROLLMENT_IN_INSTITUTION) && (
                <NvRadioButton
                  withForm
                  name='registration'
                  label={t.LEARNING_JOURNEYS.BASICS.REGISTRATION_OPTIONS.OPEN_WITHIN_ORGANIZATION.LABEL()}
                  value={CourseRegistrationType.FREE_ENROLLMENT_IN_INSTITUTION.toString()}
                  customLabel={() => (
                    <CustomRadioButtonLabel
                      description={t.LEARNING_JOURNEYS.BASICS.REGISTRATION_OPTIONS.OPEN_WITHIN_ORGANIZATION.DESCRIPTION()}
                    />
                  )}
                />
              )}
              {getIsTypeOfRegistrationEnabled(CourseRegistrationType.OPEN_BASED_ON_ENTITLEMENTS) && (
                <NvRadioButton
                  withForm
                  name='registration'
                  value={CourseRegistrationType.OPEN_BASED_ON_ENTITLEMENTS.toString()}
                  label={t.LEARNING_JOURNEYS.BASICS.REGISTRATION_OPTIONS.ENTITLEMENT_BASED.LABEL()}
                  customLabel={() => (
                    <CustomRadioButtonLabel
                      listing={getEntitlementsListing()}
                      onCtaClick={configureEntitlements}
                      ctaText={t.LEARNING_JOURNEYS.BASICS.REGISTRATION_OPTIONS.ENTITLEMENT_BASED.CTA()}
                      description={t.LEARNING_JOURNEYS.BASICS.REGISTRATION_OPTIONS.ENTITLEMENT_BASED.DESCRIPTION()}
                      advice={
                        !(methods.watch as any)('entitlements').length
                          && ((methods.watch as any)('registration') as unknown as string === CourseRegistrationType.OPEN_BASED_ON_ENTITLEMENTS.toString())
                          && t.LEARNING_JOURNEYS.BASICS.REGISTRATION_OPTIONS.ENTITLEMENT_BASED.ADVICE()
                      }
                      pendoTag={config.pendo.basics.configureEntitlements.cta}
                    />
                  )}
                />
              )}
              <NvRadioButton
                withForm
                name='registration'
                value={CourseRegistrationType.CLOSED_ENROLLMENT.toString()}
                label={t.LEARNING_JOURNEYS.BASICS.REGISTRATION_OPTIONS.CLOSED.LABEL()}
                customLabel={() => (
                  <CustomRadioButtonLabel
                    description={t.LEARNING_JOURNEYS.BASICS.REGISTRATION_OPTIONS.CLOSED.DESCRIPTION()}
                  />
                )}
              />
            </div>
          </Section>
          <Section title={t.LEARNING_JOURNEYS.BASICS.DATES()}>
            <div className='col-xs-8'>
              <div>
                <NvDatePicker
                  withForm
                  withLabel
                  name='releaseDate'
                  className='date-picker'
                  type={DatePickerType.DATETIME}
                  placeholder={t.LEARNING_JOURNEYS.BASICS.RELEASE_DATE()}
                />
                <NvDatePicker
                  withForm
                  withLabel
                  name='closureDate'
                  className='date-picker'
                  type={DatePickerType.DATETIME}
                  placeholder={t.LEARNING_JOURNEYS.BASICS.CLOSURE_DATE()}
                />
              </div>
              <div className='field-info mt-1'>{t.LEARNING_JOURNEYS.BASICS.CLOSURE_DATE_INFO()}</div>
            </div>
          </Section>
          {hasSumTotalIntegration() && (
            <SumTotalIntegration
              institutionId={currentInstitution.id}
              lmsActivityCode={initialFormData.lmsActivityCode}
              lmsActivityId={initialFormData.lmsActivityId}
              hasBeenReleased={currentJourney?.released}
              onChange={(integration: SumTotal) => setSumTotalIntegration(integration)}
              onError={(hasError: boolean) => setSumTotalError(hasError)}
            />
          )}
          {hasLxpCourse() && (
            <Section title={t.COURSES.FORM.LXP.TITLE()}>
              <div className='col-xs-8'>
                <NvTextInput
                  withForm
                  withLabel
                  name='degreedCourseId'
                  defaultValue={currentJourney.degreedCourseId}
                  placeholder={t.COURSES.FORM.LXP.EXTERNAL_COURSE_ID()}
                  disabled
                />
              </div>
              <div className='col-xs-4 help-text input-has-label lxp-journey'>
                {t.COURSES.FORM.HELP_TEXT.LXP.EXTERNAL_COURSE_ID()}
              </div>
            </Section>
          )}
          {showEdCastSection() && (
            <Section title={t.COURSES.FORM.EDCAST.TITLE()}>
              <div className='col-xs-8'>
                <div className='field-info mt-1'>{t.COURSES.FORM.EDCAST.LAST_SYNC_UP_AT()}</div>
                <div>
                  <NvTextInput
                    defaultValue={edcastLastSyncUpAt()}
                    disabled
                  />
                </div>
              </div>
            </Section>
          )}
          <div className='button-bar'>
            <Button
              type='button'
              variant='secondary'
              onClick={handleFormCancel}
            >
              {t.FORM.CANCEL()}
            </Button>
            <Button
              type='submit'
              disabled={shouldDisableSubmitButton()}
              pendo-tag-name={isEditing ? undefined : config.pendo.learningJourneys.createJourney}
            >
              {isEditing ? t.LEARNING_JOURNEYS.BASICS.UPDATE_JOURNEY() : t.LEARNING_JOURNEYS.BASICS.CREATE_JOURNEY()}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

type JourneyLoadHandler = (course?: any) => void;

type Props = {
  onJourneyLoad?: JourneyLoadHandler,
};

const Basics = (props: Props) => {
  const {
    onJourneyLoad,
  } = props;

  const { getJourney } = useJourneyFormUtils();
  const { catalogId } = useParams<{ catalogId: string }>();
  const onJourneyLoadRef = React.useRef<JourneyLoadHandler>();
  onJourneyLoadRef.current = onJourneyLoad;
  const currentJourney = useSelector((state) => state.models.courses[catalogId] || null);
  const isEditing = !!catalogId;
  const [renderForm, setRenderForm] = React.useState(!isEditing);

  React.useEffect(() => {
    if (isEditing) {
      setRenderForm(false);
      getJourney()
        .then((action) => {
          onJourneyLoadRef.current?.(action.payload.journey);
          setRenderForm(true);
        })
        .catch(() => onJourneyLoadRef.current?.());
    }
  }, [isEditing, catalogId, getJourney]);

  const getInitialFormData = () => {
    if (isEditing) {
      return {
        catalogId,
        entitlements: currentJourney.entitlements,
        closureDate: currentJourney.closeDate ? moment(currentJourney.closeDate) : null,
        releaseDate: currentJourney.releaseDate ? moment(currentJourney.releaseDate) : null,
        registration: currentJourney.typeOfRegistration.toString(),
        branding: {
          name: currentJourney.name,
          brandColor: currentJourney.headerColor,
          fontColor: currentJourney.nameFontColor,
          background: currentJourney.headerBackground ? {
            url: currentJourney.headerBackground,
          } : null,
          logo: currentJourney.logo ? {
            url: currentJourney.logo.url,
            size: currentJourney.logoSize,
          } : null,
        },
        lmsActivityCode: currentJourney?.lmsCourse?.externalCourseNumber || '',
        lmsActivityId: currentJourney?.lmsCourse?.externalCourseId || '',
        degreedCourseId: currentJourney?.degreedCourseId,
      };
    }

    return DEFAULT_FORM_DATA;
  };

  return renderForm && (isEditing ? currentJourney : true) && (
    <Form
      catalogId={catalogId}
      initialFormData={getInitialFormData()}
    />
  );
};

type SectionProps = {
  title: string,
  children: React.ReactNode,
};

export const Section = (props: SectionProps) => {
  const { title, children } = props;

  const styles = css`
    margin-bottom: ${doubleSpacing}px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .title {
      font-size: 36px;
      color: ${gray4};
      font-weight: 300;
      line-height: 50px;
      text-align: right;
      padding: ${quarterSpacing}px ${largeSpacing}px ${quarterSpacing}px 0;
    }

    .section-content {
      border-left: 1px solid ${gray6};
      padding: ${halfSpacing}px 0 ${halfSpacing}px ${largeSpacing}px;
    }
  `;

  return (
    <div css={styles} className='row'>
      <div className='title col-xs-5'>
        {title}
      </div>
      <div className='section-content col-xs-7'>
        {children}
      </div>
    </div>
  );
};

type CustomRadioButtonLabelListing = {
  key: string,
  value: string,
};

type CustomRadioButtonLabelProps = {
  description: string,
  advice?: string,
  ctaText?: string,
  onCtaClick?: () => void,
  listing?: CustomRadioButtonLabelListing[],
  pendoTag?: string,
};

const CustomRadioButtonLabel = (props: CustomRadioButtonLabelProps) => {
  const {
    advice,
    ctaText,
    listing,
    onCtaClick,
    description,
    pendoTag,
  } = props;

  const styles = css`
    padding-left: ${largeSpacing}px;
    padding-top: ${quarterSpacing}px;

    .description {
      color: ${gray3};
    }

    .description, .configure-entitlements-btn {
      margin-bottom: ${quarterSpacing}px;
    }

    .advice {
      color: ${warning};
      background-color: ${gray6};
      padding: ${quarterSpacing}px;
    }

    .each-listing {
      padding: ${quarterSpacing}px;
      margin-top: ${quarterSpacing}px;
    }
  `;

  return (
    <div css={styles}>
      <div className='description text-small'>{description}</div>
      {ctaText && (
        <button
          type='button'
          onClick={onCtaClick}
          className='configure-entitlements-btn btn btn-link hovered'
          pendo-tag-name={pendoTag}
        >
          {ctaText}
        </button>
      )}
      {advice && (
        <div className='advice text-small'>{advice}</div>
      )}
      {listing?.map(({ key, value }, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={`${key}-${value}-${index}`}
          className='bg-gray-6 text-small each-listing'
        >
          {key}
          :
          {' '}
          <span className='bold'>
            {value}
          </span>
        </div>
      ))}
    </div>
  );
};

type FormConfigureEntitlementsModalProps = Omit<ConfigureEntitlementsModalProps, 'entitlements' | 'onClose' | 'onCancel'> & {
  name: string,
  onClose: () => void,
};

const FormConfigureEntitlementsModal = (props: FormConfigureEntitlementsModalProps) => {
  const {
    name,
    onClose,
    ...restProps
  } = props;

  return (
    <Controller
      name={name}
      render={({ field: { value, onChange } }) => {
        const handleModalClose = (entitlements) => {
          onClose();
          onChange(entitlements);
        };

        return (
          <ConfigureEntitlementsModal
            {...restProps}
            onCancel={onClose}
            entitlements={value}
            onClose={handleModalClose}
          />
        );
      }}
    />
  );
};

export default Basics;
