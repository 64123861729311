// Filter for max num characters
/* @ngInject */
export default function nvManualEllipsis() {
  return function (inputString, inputNumChars) {
    const defaultNumChars = 74;
    const numChars = inputNumChars || defaultNumChars;

    if (inputString.length > numChars) {
      return `${inputString.substr(0, (numChars - 3))}...`;
    }
    return inputString;
  };
}


