import { useSelector } from 'react-redux';

import { CombinedCourse } from 'redux/schemas';
import { getCurrentCourse } from 'redux/selectors/course';

const useCompletionCriteria = () => {
  const currentCourse: CombinedCourse = useSelector(getCurrentCourse);

  const {
    automatedCompletionsEnabled,
    automaticCompletionCriteria,
    automaticCompletionProgress,
  } = currentCourse;

  const {
    requiredPoints,
    requiredAssignmentsCount,
    totalTodoAssignmentsCount,
    totalRequiredTodosCount,
    unreleasedTodoAssignmentsCount,
    unreleasedTodosCount,
  } = automaticCompletionCriteria ?? {};
  const {
    pointsReceived,
    todoAssignmentsCompleted,
    requiredTodosCompleted,
  } = automaticCompletionProgress ?? {};

  const enabledFactorCount = automaticCompletionCriteria && [
    requiredPoints,
    requiredAssignmentsCount,
    totalRequiredTodosCount,
  ].filter((count) => count > 0).length;

  const receivedFactorCount = automaticCompletionProgress && [
    pointsReceived,
    todoAssignmentsCompleted,
    requiredTodosCompleted,
  ].filter((count) => count > 0).length;

  const completedFactorCount = [
    requiredPoints > 0 && pointsReceived >= requiredPoints,
    requiredAssignmentsCount > 0 && todoAssignmentsCompleted >= requiredAssignmentsCount,
    totalRequiredTodosCount > 0 && requiredTodosCompleted >= totalRequiredTodosCount,
  ].filter((assert) => assert).length;

  const isCompleted = automatedCompletionsEnabled && completedFactorCount >= enabledFactorCount;

  return {
    automatedCompletionsEnabled,
    automaticCompletionCriteria,
    automaticCompletionProgress,

    requiredPoints,
    requiredAssignmentsCount,
    totalRequiredTodosCount,
    totalTodoAssignmentsCount,
    unreleasedTodoAssignmentsCount,
    unreleasedTodosCount,

    pointsReceived,
    todoAssignmentsCompleted,
    requiredTodosCompleted,

    enabledFactorCount,
    receivedFactorCount,
    isCompleted,
  };
};

export default useCompletionCriteria;
