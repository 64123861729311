import { css } from '@emotion/react';
import React from 'react';
import { mergeRefs } from 'shared/react-utils';

const containerStyle = css`
  display: flex;
`;

const buttonStyle = css`
  cursor: pointer;

  &[aria-disabled='true'] {
    cursor: default;
  }
`;

export interface ClickableContainerProps
  extends React.ComponentPropsWithoutRef<'div'> {
  disabled?: boolean;

  /**
   * set this value to disable completely the button functionality and use it as
   * a normal div
   */
  layoutOnly?: boolean;
  isFocusable?: boolean;
}

/**
 * Make a div clickable and make it accessible. Adapted from the example:
 * https://www.w3.org/TR/wai-aria-practices/examples/button/button.html
 * See JS implementation: https://www.w3.org/TR/wai-aria-practices/examples/button/js/button.js
 */
const ClickableContainer = React.forwardRef<HTMLDivElement, ClickableContainerProps>((props, ref) => {
  const innerRef = React.useRef<HTMLDivElement>(null);
  const {
    disabled = false,
    layoutOnly = false,
    isFocusable = true,
    onClick,
    onKeyDown,
    onKeyUp,
    ...rest
  } = props;

  function handleClick(event: React.MouseEvent<HTMLDivElement>) {
    // won't trigger the click event if the button is disabled
    if (!disabled) {
      onClick?.(event);
    }
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    const firedByItself = event.target === innerRef.current;
    // The action button is activated by space on the keyup event, but the
    // default action for space is already triggered on keydown. It needs to be
    // prevented to stop scrolling the page before activating the button.
    if (event.key === ' ') {
      if (firedByItself) {
        event.preventDefault();
      }
    } else if (event.key === 'Enter') {
      // If enter is pressed, activate the button
      if (firedByItself) {
        event.preventDefault();
        innerRef.current.click();
      }
    }

    onKeyDown?.(event);
  }

  function handleKeyUp(event: React.KeyboardEvent<HTMLDivElement>) {
    const firedByItself = event.target === innerRef.current;

    // Activates the action button with the space key.
    if (event.key === ' ') {
      if (firedByItself) {
        event.preventDefault();
        innerRef.current.click();
      }
    }

    onKeyUp?.(event);
  }

  let containerProps: React.ComponentProps<'div'> = {
    ...rest,
    css: containerStyle,
  };

  // add button functionality
  if (!layoutOnly) {
    containerProps = {
      tabIndex: isFocusable ? 0 : -1,
      role: 'button',
      ...containerProps,
      'aria-disabled': disabled,
      onClick: handleClick,
      onKeyDown: handleKeyDown,
      onKeyUp: handleKeyUp,
      css: css`
        ${containerStyle};
        ${buttonStyle};
      `,
    };
  }

  return (
    <div {...containerProps} ref={mergeRefs(ref, innerRef)}>
      {props.children}
    </div>
  );
});

export default ClickableContainer;
