import React, { useState } from 'react';
import { NvTab, NvResponsiveTabsDisplayType } from 'shared/components/nv-responsive-tabs';
import { NvResponsiveTabsRow } from 'shared/components/nv-responsive-tabs-row';
import { createGridStyles } from 'styles/global_defaults/scaffolding';
import t from 'react-translate';
import { textSmallFontSize } from 'styles/global_defaults/fonts';
import { css } from '@emotion/core';
import { white } from 'styles/global_defaults/colors';
import { AnalyticsDashBoardType } from 'redux/schemas/app/analytics';
import { LicenseInformation, getLicenseInformation } from 'redux/selectors/licenses';
import { useSelector } from 'react-redux';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { CombinedInstitution, RootState } from 'redux/schemas';
import { AngularContext } from 'react-app';
import AnalyticsDashboard from './analytics-dashboard';
import LicenseTopPanel from './license-top-panel';

const LicenseAnalytics = () => {
  const [activeTab, setActiveTab] = useState('main');

  const licensedata: LicenseInformation = useSelector(getLicenseInformation);
  const { name, hasViewableDependentLicenses, licenseModel } = useSelector<RootState, CombinedInstitution>(getCurrentInstitution);

  const institution = useSelector((state) => getCurrentInstitution(state));

  const hasLicenseDashboard = institution?.hasLicenseDashboard;

  const { injectServices } = React.useContext(AngularContext);
  const [$state] = injectServices([
    '$state',
  ]);

  if (!hasLicenseDashboard) {
    $state.go('institution-dashboard');
  }

  const tabs: NvTab[] = [
    {
      text: t.LICENSE_DASHBOARD.MAIN(name),
      onClick: () => setActiveTab('main'),
    }, {
      text: t.LICENSE_DASHBOARD.DEPENDENT(),
      onClick: () => setActiveTab('dependant'),
    },
  ];
  const tabStyles = {
    ...createGridStyles(1, 2),
    fontSize: `${textSmallFontSize}px`,
  };
  const styles = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: calc(((100vw - 1024px)/2) - 10px);
    background-color: ${white};
  `;
  return (
    <>{hasViewableDependentLicenses
      && (
      <div css={styles} className='analytics-header'>
        <NvResponsiveTabsRow
          style={tabStyles}
          defaultTabs={tabs}
          tabType={NvResponsiveTabsDisplayType.TEXT_ONLY}
          tabTextClass='card-title'
        />
      </div>
      )}
      <LicenseTopPanel {...licensedata} licenseModel={licenseModel} />
      {/* This to conditionals are neccesary to rerender the qrvey instance on click of the tabs */}
      {activeTab === 'main'
      && <AnalyticsDashboard dashboardType={AnalyticsDashBoardType.LICENSE} configKeyName={`config_${activeTab}`} pageIdKey={activeTab} />}
      {activeTab === 'dependant'
      && <AnalyticsDashboard dashboardType={AnalyticsDashBoardType.LICENSE} configKeyName={`config_${activeTab}`} pageIdKey={activeTab} />}
    </>
  );
};
export default LicenseAnalytics;
