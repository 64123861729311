/* @ngInject */
export default function MentoringsResource(
  $resource,
) {
  return $resource(
    '/:catalogId/mentorings.json',
    { catalogId: '@catalogId' },
    {
      getMentees: {
        method: 'GET',
        url: '/:catalogId/mentorings/:userId',
      },
      removeMentee: {
        method: 'DELETE',
        url: '/:catalogId/mentorings/:relationshipId',
      },
    },
  );
}
