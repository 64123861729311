/* @ngInject */
export default function NvQuizCorrectAnswer() {
  return {
    scope: {
      questionType: '=?',
      answer: '=',
      answerKey: '=?',
      answerValues: '=?',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;

      vm.questionTypes = {
        RichTextQuestion: 'RichTextQuestion',
        MultipleChoiceMultipleAnswerQuestion: 'MultipleChoiceMultipleAnswerQuestion',
        TableShortTextQuestion: 'TableShortTextQuestion',
        TableNumberQuestion: 'TableNumberQuestion',
      };
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'quizzes/templates/nv-quiz-correct-answer.html',
  };
}
