import React, { useCallback, useContext, useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { AngularServicesContext } from 'react-app';
import t from 'react-translate';

import { useAppDispatch } from 'redux/store';
import { RootState } from 'redux/schemas';
import { getCurrentUser } from 'redux/selectors/users';
import {
  PracticeRoomTab,
  SubmissionTab,
  VideoPracticeScenario,
} from 'redux/schemas/models/video-practice';
import { getPracticeSubmission } from 'redux/actions/video-practice';
import { hexToRgbaString, black, white } from 'styles/global_defaults/colors';
import { doubleSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { desktop } from 'styles/global_defaults/media-queries';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import prodPathReplace from 'shared/prod-path-rewrite';
import pusherService from 'shared/services/pusher-service';
import { config } from '../../../../config/pendo.config.json';

interface PracticeInstantInsightsModalProps {
  submissionId: number;
  scenarioId: number;
  setShowInsightsModal: (show: boolean) => void;
}
const modalStyle = css`
  overflow-y: auto;
`;

const styles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${hexToRgbaString(black, 0.95)};
  flex: 1;

  .image-container {
    transition: opacity 0.8s ease-in-out;
  }

  .image-container.fade-in {
    opacity: 1;
  }

  .image-container.fade-out-effect {
    opacity: 0;
  }

  .dialog-box {
    background-color: ${white};
    width: 400px;
    padding: ${doubleSpacing}px;
    border-radius: ${halfSpacing}px;

    ${desktop(css`
      width: 700px;
    `)}

  }
  .dialog-image {
    display: block;
    height: 250px;
    margin: ${doubleSpacing}px auto;
  }
`;

const maxDisplayTime = 20000;

const AnimatedTitles = ({ hasKeyPhrases }) => {
  const [fade, setFade] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  const titles = [
    {
      title: t.INSTANT_INSIGHT.CALCULATING_RATE(),
      image: prodPathReplace('images/rate-speech.gif'),
      show: true,
    },
    {
      title: t.INSTANT_INSIGHT.IDENTIFY_KEYPHRASES(),
      image: prodPathReplace('images/key-phrases.gif'),
      show: hasKeyPhrases,
    },
    {
      title: t.INSTANT_INSIGHT.DETERMINE_WORDS(),
      image: prodPathReplace('images/filler-words.gif'),
      show: true,
    },
  ].filter((item) => item.show);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % titles.length);
        setFade(true);
      }, 500); // Duration of the fade-out animation
    }, 4000); // Duration to show each image

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`image-container ${fade ? 'fade-in' : 'fade-out-effect'}`}>
      <p className='text-large-regular text-center font-weight-bolder text-gray-2 animated-paragraph'>{titles[currentIndex].title}</p>
      <img
        className='dialog-image'
        src={titles[currentIndex].image}
        alt={titles[currentIndex].title}
      />
    </div>
  );
};

const ModalBody = ({
  submissionId,
  scenarioId,
  setShowInsightsModal,
}: PracticeInstantInsightsModalProps) => {
  const dispatch = useAppDispatch();
  const currentUser = useSelector(getCurrentUser);
  const { $state } = useContext(AngularServicesContext);
  const scenario = useSelector<RootState, VideoPracticeScenario>((state) => state.models.practiceScenarios[scenarioId]) || {};
  const [pusherResponse, setPusherResponse] = useState<boolean>(false);

  const hasKeyPhrases = scenario?.insightCriterion?.keyphrasesToCover?.length > 0 || scenario?.insightCriterion?.keyphrasesToAvoid?.length > 0;
  const channel = `public-${currentUser.anonymizedIdentifier.substr(0, 10)}`;

  const viewPractice = useCallback(() => {
    setShowInsightsModal(false);
    if (scenario.id) {
      $state.go('practice-room-modal', {
        scenarioId: scenario.id,
        user: currentUser?.id,
        submission: submissionId,
        selected: PracticeRoomTab.MY_PRACTICE,
        submissionView: SubmissionTab.INSIGHTS,
        stickyInsights: true,
      });
    }
  }, [$state, currentUser?.id, scenario.id, setShowInsightsModal, submissionId]);

  const getPracticeSubmissionFn = useCallback(async (data) => {
    await dispatch(getPracticeSubmission({
      scenarioId,
      submissionId: data?.video_practice_submission_id,
    }));
    setPusherResponse(true);
  }, [dispatch, scenarioId]);

  useEffect(() => {
    const pusherChannel = pusherService.setupChannel(channel);
    if (submissionId) {
      pusherChannel.bind('filler_words_completed', getPracticeSubmissionFn);
      pusherChannel.bind('transcription_completed', getPracticeSubmissionFn);
    }

    return () => {
      pusherChannel.unbind('filler_words_completed');
      pusherChannel.unbind('transcription_completed');
    };
  }, [submissionId, channel, getPracticeSubmissionFn]);

  useEffect(() => {
    if (pusherResponse) {
      viewPractice();
    }
    // Set max time to display the insights modal
    const maxTimer = setTimeout(() => {
      viewPractice();
    }, maxDisplayTime);

    return () => {
      clearTimeout(maxTimer);
    };
  }, [viewPractice, pusherResponse]);

  return (
    <div css={styles}>
      <div>
        <p className='course-subtitle text-center text-white'>
          {t.INSTANT_INSIGHT.GENERATING()}
        </p>
        <p className='padding-bottom text-center text-white'>
          {t.INSTANT_INSIGHT.VIDEO_SUBMITTED()}
        </p>
        <div className='dialog-box'>
          <AnimatedTitles hasKeyPhrases={hasKeyPhrases} />
          <p className='text-gray-3 text-center padding-top'>
            {t.INSTANT_INSIGHT.WAIT_INSIGHTS()}
          </p>
        </div>
      </div>
    </div>
  );
};

const PracticeInstantInsightsModal = (props: PracticeInstantInsightsModalProps) => {
  const onCloseHandler = () => {
    props.setShowInsightsModal(false);
  };

  return (
    <NvModal
      type={ModalType.NO_DIALOG}
      backdrop={false}
      onClose={onCloseHandler}
      body={<ModalBody {...props} />}
      closePendoTagName={config.pendo.practice.closeModal}
      closeDataQa={config.pendo.practice.closeModal}
      modalStyles={modalStyle}
    />
  );
};

export default PracticeInstantInsightsModal;
