import { css } from '@emotion/react';
import { Course } from 'redux/schemas/models/course';
import { Institution } from 'redux/schemas/models/institution';
import { UserEnrollment } from 'redux/schemas/models/org-users';
import { AvatarSize, NvAvatar, NvAvatarDefaultBkg } from './nv-avatar';

type NvCourseAvatarProps = {
  course: Course | UserEnrollment['course'];
  institution: Institution,
  size?: AvatarSize,
  imageUrl?: string,
};

export const NvCourseAvatar = (props: NvCourseAvatarProps) => {
  const styles = css`
    .avatar-container {
      display: inline-flex;
      position: relative;
    }
    .program-badge {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0; /* Overriding global angularjs style */
      position: absolute;
      right: -5px;
      top: 10px;
      background-color: ${props.institution.brandColor};
      color: ${props.institution.brandBarFontColor};
      width: 18px;
      height: 18px;
      box-shadow: -1px 2px 2px rgba(0, 0, 0, 0.3)
    }
  `;

  const { size = 'lg', imageUrl = props.course.squareThumbnail } = props;
  const avatarColor = imageUrl.includes('temp.png') ? props.course.headerColor ?? props.institution.brandColor : '';

  return (
    <div css={styles}>
      <div className='avatar-container'>
        <NvAvatar
          imageUrl={imageUrl}
          size={size}
          borderType='square'
          defaultBkg={{ type: NvAvatarDefaultBkg.SOLID_COLOR, color: avatarColor }}
        />
        { props.course.isProgram && <div className='program-badge text-xs font-weight-bold'>P</div>}
      </div>
    </div>
  );
};

export default NvCourseAvatar;
