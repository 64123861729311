import { BookmarkType } from 'redux/schemas/models/bookmark';

export default {
  bindings: {
    post: '<',
    readonlyMode: '<',
    hideTitle: '<?',
    context: '@',
    showBadge: '<?',
    inModal: '<?',
  },
  transclude: {
    editOptions: 'editOptions',
    embeddedContent: '?embeddedContent',
    beforePostBodyEnd: '?div',
    teamProfilePanel: '?nvTeamProfilePanel',
  },
  controller: function ctrl(
    $stateParams,
    BookmarkModel,
    CurrentCourseManager,
  ) {
'ngInject';
    this.isContentManagementCollection = CurrentCourseManager?.course?.isContentManagementCollection;
    this.hasEmbeddedContent = function () {
      // slot is considered filled if there's any content inside, even comments, so this won't work
      // return $transclude.isSlotFilled('embeddedContent');

      return !!this.post.metaContent;
    };

    this.createBookmark = function () {
      BookmarkModel.createBookmark({
        type: BookmarkType.TOPIC,
        catalogId: $stateParams.catalogId,
        componentId: this.post.id,
      })
        .then(bookmark => {
          this.post.bookmarkId = bookmark.id;
        });
    };

    this.highlightBookmark = function () {
      BookmarkModel.highlightBookmark(this.post.bookmarkId);
    };
  },
  controllerAs: 'postBodyCtrl',
  templateUrl: 'shared/templates/nv-post-body.html',

};
