import { setSavingOverlayText } from 'redux/actions/saving-overlay';
import store from 'redux/store';
import t from '../../react-translate';
import { useAngularPreventLecturePageNavigation } from '../hooks/use-prevent-lecture-page-navigation';

/* @ngInject */
export default function ExerciseEditFormController(
  $uibModalInstance,
  $window,
  $scope,
  $translate,
  _,

  ConfirmationOverlays,
  CurrentUserManager,
  CurrentCourseManager,
  StateManager,
  config,

  vmResolves,
) {
  const SELECT_INDIVIDUAL_OR_TEAM = 'SELECT_INDIVIDUAL_OR_TEAM';
  const SELECT_TEAM_TYPE = 'SELECT_TEAM_TYPE';
  const SELECT_TEAM_FORMATION = 'SELECT_TEAM_FORMATION';

  const vm = this;
  let dismissedByUser = false;

  _.extend(vm, vmResolves);
  vm.isContentManagementCollection = CurrentCourseManager.course.isContentManagementCollection;
  vm.CurrentUserManager = CurrentUserManager;
  vm.teamPendoTagName = config.pendo.lectureEdit.teamAssignment;
  vm.individualPendoTagName = vm.isContentManagementCollection
    ? config.pendo.lectureEdit.lessonLibrary.individualAssignment
    : config.pendo.lectureEdit.individualAssignment;

  if (!vm.lectureComponent.id) {
    vm.currentState = SELECT_INDIVIDUAL_OR_TEAM;
  } else {
    vm.currentState = SELECT_TEAM_TYPE;
    if (vm.lectureComponent.exercise.teamSet) {
      vm.selectedState = SELECT_TEAM_TYPE;
      vm.lectureComponent.exerciseDraft.teamType = vm.lectureComponent.exercise.teamSet.isCourseLong ? 'course_long' : 'assignment_long';
    }
  }

  vm.selectTeamSubmission = (isTeamSubmission) => {
    vm.selectedState = SELECT_INDIVIDUAL_OR_TEAM;

    if (isTeamSubmission) {
      vm.currentState = SELECT_TEAM_TYPE;
    } else {
      vm.lectureComponent.exerciseDraft.teamType = 'individual';
    }
  };

  vm.save = () => {
    dismissedByUser = true;

    if (vm.lectureComponent.exerciseDraft.teamType === 'course_long'
      && (!vm.lectureComponent.exercise.teamSet || !vm.lectureComponent.exercise.teamSet.isCourseLong)
      && !CurrentCourseManager.course.courseLongTeamSet
    ) {
      store.dispatch(setSavingOverlayText($translate.instant(
        'LECTURE_PAGES.COMPONENTS.EXERCISE.TEAM_FORMATION_AND_ASSIGNMENT_ADDING',
        { ...CurrentCourseManager.course.getAliases(), teamAlias: vm.lectureComponent.exerciseDraft.name },
      )));

      /**
       * This `addingTeamFormationExercise` is used to verify whether the team
       * formation is created or not after the assignment save.
       */
      vm.lectureComponent.addingTeamFormationExercise = true;
    }

    $uibModalInstance.close();
  };

  vm.back = () => {
    switch (vm.currentState) {
      case SELECT_TEAM_TYPE:
        vm.lectureComponent.exerciseDraft.teamType = null;
        vm.selectedState = null;
        vm.currentState = SELECT_INDIVIDUAL_OR_TEAM;
        break;
      case SELECT_TEAM_FORMATION:
        vm.lectureComponent.exerciseDraft.formedByStudents = null;
        vm.selectedState = null;
        vm.currentState = SELECT_TEAM_TYPE;
        break;
      default:
    }
  };

  vm.selectAssignmentTeam = (isAssignmentTeam) => {
    vm.selectedState = SELECT_TEAM_TYPE;

    if (isAssignmentTeam) {
      vm.lectureComponent.exerciseDraft.teamType = 'assignment_long';
    } else {
      vm.lectureComponent.exerciseDraft.teamType = 'course_long';

      if (!CurrentCourseManager.course.courseLongTeamSet) {
        vm.currentState = SELECT_TEAM_FORMATION;
      }
    }

    vm.editExerciseTeamTypeForm.$setDirty();
  };

  vm.selectTeamFormation = (formedByStudents) => {
    vm.lectureComponent.exerciseDraft.formedByStudents = formedByStudents;
    vm.editTeamFormationTypeForm.$setDirty();
  };


  function hasUnsavedChanges() {
    return (vm.editExerciseTeamTypeForm?.$dirty)
        || (vm.editTeamFormationTypeForm?.$dirty);
  }

  const checkUnsavedChanges = () => hasUnsavedChanges();

  useAngularPreventLecturePageNavigation($scope, checkUnsavedChanges);

  // warn if leaving the modal and have unsaved changes
  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(checkUnsavedChanges,
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    'FORM.NAVIGATE_AWAY.CLOSE_WINDOW',
    undefined,
    undefined,
    () => {
      vm.deregisterModalClosing?.();

      $uibModalInstance.dismiss();
    });

  vm.deregisterModalClosing = $scope.$on('modal.closing', ($event) => {
    if (hasUnsavedChanges() && !dismissedByUser) {
      $event.preventDefault();

      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        dismissedByUser = true;
        $uibModalInstance.dismiss();
      });
    }
  });

  // Update the modal title to match the current page view
  $scope.$watch('vm.currentState', () => {
    let title = null;

    if (CurrentCourseManager.course.isContentManagementCollection) {
      vm.selectedState = SELECT_INDIVIDUAL_OR_TEAM;
      vm.lectureComponent.exerciseDraft.teamType = 'individual';
    }

    switch (vm.currentState) {
      case SELECT_INDIVIDUAL_OR_TEAM:
        title = t.LECTURE_PAGES.COMPONENTS.EXERCISE.MODAL.INDIVIDUAL_TEAM.TITLE;
        break;
      case SELECT_TEAM_TYPE:
        if (!vm.lectureComponent.id) {
          title = t.LECTURE_PAGES.COMPONENTS.EXERCISE.MODAL.TEAM_TYPE.TITLE.ADD;
        } else if (!vm.lectureComponent.exercise.isTeamSubmission) {
          title = t.LECTURE_PAGES.COMPONENTS.EXERCISE.MODAL.TEAM_TYPE.TITLE.CHANGE;
        } else {
          title = t.LECTURE_PAGES.COMPONENTS.EXERCISE.MODAL.TEAM_TYPE.TITLE.EDIT;
        }
        break;
      case SELECT_TEAM_FORMATION:
        title = t.LECTURE_PAGES.COMPONENTS.EXERCISE.MODAL.TEAM_TYPE.TITLE.ADD_TEAM_FORMATION;
        break;
      default:
        return;
    }
    if (vm.workflowCtx) {
      vm.workflowCtx.setSettings({
        ...vm.workflowCtx.settings,
        title: (mode, courseAliases) => title(courseAliases),
      });
    }
  });

  $scope.$on('$destroy', () => {
    angular.element($window).off('beforeunload');
    vm.deregisterStateChangeStart?.();
  });
}
