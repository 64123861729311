import { css, Global } from '@emotion/react';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import t from 'react-translate';
import useMountEffect from 'shared/hooks/use-mount-effect';

// Schemas
import { cloneDeepSerializable, useAppDispatch } from 'redux/store';
import { RootState } from 'redux/schemas';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { BaseUpdateNameAndEmailParams, UpdateCourseNameAndEmailParams, UpdateOrgNameAndEmailParams } from 'redux/schemas/api/user';
import { NameAndEmailFormSource } from 'redux/schemas/models/user';
import { User } from 'redux/schemas/models/my-account';

// Actions
import { updateNameAndEmail } from 'redux/actions/users';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { addAlertMessage } from 'redux/actions/alert-messages';

// Selectors
import { getCurrentInstitution } from 'redux/selectors/institutions';

// Components
import NvModal, { ModalType } from 'shared/components/nv-modal';
import NvTextInput from 'shared/components/inputs/nv-text-input';

// Styles
import { gray1, gray3, primary } from 'styles/global_defaults/colors';
import { tripleSpacing } from 'styles/global_defaults/scaffolding';
import { handheld, notDesktop } from 'styles/global_defaults/media-queries';

// Config
import { config } from '../../../../config/pendo.config.json';

const styles = css`
  form {
    margin: 0 80px;
    ${handheld(css`
      margin: 0;
    `)};

    label {
      color: ${gray3};
      margin-bottom: 0;
    }

    .buttons {
      margin-top: ${tripleSpacing}px;
    }
  }
`;

export type NameAndEmailFormUser = {
  id: number,
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
};

enum NameAndEmailUpdateResponseCode {
  CONFIRM_DELETION = 'user.error.confirm_deletion',
  ACCOUNT_ALREADY_EXISTS = 'user.error.account_already_exists',
  CANNOT_DELETE = 'user.error.can_not_delete',
  ADMIN = 'user.error.org_or_novoed_admin',
  SUCCESS = 'success',
}

type NameAndEmailFormProps = EditModalProps & {
  onClose: () => void;
  updateCallback?: (updatedUser) => void;
};

interface FormFields {
  firstName: string,
  lastName: string,
  email: string,
  confirmed: boolean,
}

const NameAndEmailForm = ({
  user,
  source,
  updateCallback,
  onClose,
  catalogId,
  courseUserId,
}: NameAndEmailFormProps) => {
  const dispatch = useAppDispatch();
  const [ssoWarning, setSsoWarning] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [updateErrorInfo, setUpdateErrorInfo] = useState(null);
  const institution = useSelector((state: RootState) => getCurrentInstitution(state));
  const currentCatalogId = useSelector((state) => state.app.currentCatalogId);
  const institutionId = useSelector((state) => state.app.currentInstitutionId);

  const validationSchema = yup.object().shape({
    firstName: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .max(50, t.VALIDATION.MAX_LENGTH('50')),
    lastName: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .max(50, t.VALIDATION.MAX_LENGTH('50')),
    email: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .email(t.VALIDATION.EMAIL()),
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    },
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, formState, watch, getValues } = methods;

  const { isDirty, isValid } = formState;
  const email = watch('email');

  useEffect(() => {
    if (institution.ssoLogin && user.email !== email) {
      setSsoWarning(true);
    } else {
      setSsoWarning(false);
    }
  }, [institution.ssoLogin, user.email, email]);

  const confirmAndSubmit = () => {
    onSubmit({
      ...getValues(),
      confirmed: true,
    });
  };

  const onSubmit = (values: FormFields) => {
    setUpdateError(null);
    setUpdateErrorInfo(null);
    setIsUpdating(true);

    // API only need the changed values, or send null
    const params: BaseUpdateNameAndEmailParams = {
      userId: user.id,
      firstName: user.firstName !== values.firstName ? values.firstName : null,
      lastName: user.lastName !== values.lastName ? values.lastName : null,
      email: user.email !== values.email ? values.email : null,
      confirmed: !!values.confirmed,
      source,
    };

    if (source === NameAndEmailFormSource.COURSE || source === NameAndEmailFormSource.CONTENT_MANAGEMENT_COLLECTION) {
      (params as UpdateCourseNameAndEmailParams).catalogId = catalogId ?? currentCatalogId;
      (params as UpdateCourseNameAndEmailParams).courseUserId = courseUserId;
    } else {
      (params as UpdateOrgNameAndEmailParams).institutionId = institutionId;
    }

    dispatch(updateNameAndEmail(params as UpdateCourseNameAndEmailParams | UpdateOrgNameAndEmailParams)).then((res: any) => {
      setIsUpdating(false);
      const response = res?.payload;

      if (isEmpty(response)) {
        setUpdateError(t.FORM.ERROR_SOMETHING_WRONG_SHORT());
        return;
      }

      const code: NameAndEmailUpdateResponseCode = response.responseCode ?? response.code;

      if (code === NameAndEmailUpdateResponseCode.CONFIRM_DELETION) {
        /**
         * Case1:  email2@email.com is associated with empty account and does not
         * belonging to org level user or other orgs and was created via the
         * organization’s SSO.
         */
        dispatch(openConfirmationDialog({
          title: t.USER_MANAGEMENT.EDIT_NAME_AND_EMAIL.DELETE_CONFIRMATION.TITLE(),
          bodyText: t.USER_MANAGEMENT.EDIT_NAME_AND_EMAIL.DELETE_CONFIRMATION.CONTENT(),
          confirmText: t.USER_MANAGEMENT.EDIT_NAME_AND_EMAIL.DELETE_CONFIRMATION.YES_DELETE_UPDATE(),
          confirmButtonVariant: 'primary',
          icon: 'info',
          onConfirm: () => confirmAndSubmit(),
          confirmPendoTag: config.pendo.userManagement.editNameAndEmail.deleteAndUpdate,
          cancelPendoTag: config.pendo.userManagement.editNameAndEmail.cancelDeleteAndUpdate,
        }));
      } else if (code === NameAndEmailUpdateResponseCode.ACCOUNT_ALREADY_EXISTS) {
        /**
         * Case2:  email2@email.com already exists and is enrolled in x offerings in this org.
         * Case3:  email2@email.com already exists and is enrolled in x offerings in this organization
         *         and y outside.
         * Case4:  email2@email.com already exists and is enrolled y offerings in other organizations.
         */
        const courseCount: number = response.coursesEnrollmentCounts + response.otherOrgCoursesEnrollmentCounts;
        setUpdateError(t.USER_MANAGEMENT.EDIT_NAME_AND_EMAIL.ALERTS.ACCOUNT_ALREADY_EXISTS(courseCount, values.email));
        setUpdateErrorInfo(t.USER_MANAGEMENT.EDIT_NAME_AND_EMAIL.ALERTS.ACCOUNT_ALREADY_EXISTS_INFO(values.email, user.email));
      } else if (code === NameAndEmailUpdateResponseCode.CANNOT_DELETE || code === NameAndEmailUpdateResponseCode.ADMIN) {
        /**
         * Case 5:  email2@email.com already exists but cannot be deleted due to:
         * empty but associated with other orgs or account was not created via
         * SSO login or account exists in any org without SSO or account has
         * “0 current enrollments and unenrolled in the past”
         */
        setUpdateError(t.USER_MANAGEMENT.EDIT_NAME_AND_EMAIL.ALERTS.CANNOT_DELETE());
      } else if (code === NameAndEmailUpdateResponseCode.SUCCESS && response.user) {
        // Success alert
        const updatedUser = response.user;
        if (updateCallback) {
          updateCallback(updatedUser);
        }
        dispatch(addAlertMessage({
          type: AlertMessageType.SUCCESS,
          header: t.FORM.SUCCESS_BANG(),
          message: t.USER_MANAGEMENT.EDIT_NAME_AND_EMAIL.ALERTS.SUCCESS(updatedUser.firstName),
        }));
        onClose();
      } else {
        // Failure alert
        setUpdateError(t.FORM.ERROR_SOMETHING_WRONG_SHORT());
      }
    });
  };

  return (
    <div css={styles}>
      <Global
        styles={css`
          .nv-confirmation-overlay {
            background-color: ${gray1};
          }
          .bs4-modal-dialog .bs4-modal-header .close {
            padding: 14px;

            &:focus, &:active {
              outline: 2px solid ${primary};
            }
          }
          ${notDesktop(css`
            .bs4-modal.modal-dynamic {
              .bs4-modal-dialog {
                /* Removing the override for small screens */
                position: relative !important;
                padding-top: ${tripleSpacing}px !important;
                padding-bottom: ${tripleSpacing}px !important;
              }
            }
          `)};
        `}
      />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {updateError && (
            <p className='text-danger'>
              {updateError}
            </p>
          )}
          {updateErrorInfo && (
            <p className='text-danger'>
              {updateErrorInfo}
            </p>
          )}
          <div className='form-container'>
            <label htmlFor='firstName'>{t.USERS.REGISTRATION.FIRST_NAME()}</label>
            <div className='bs4-row mb-5'>
              <NvTextInput
                withForm
                autoComplete='off'
                name='firstName'
                ariaLabel={t.USERS.REGISTRATION.FIRST_NAME()}
                className='bs4-col'
                required
              />
            </div>
            <label htmlFor='lastName'>{t.USERS.REGISTRATION.LAST_NAME()}</label>
            <div className='bs4-row mb-5'>
              <NvTextInput
                withForm
                autoComplete='off'
                name='lastName'
                ariaLabel={t.USERS.REGISTRATION.LAST_NAME()}
                className='bs4-col'
                required
              />
            </div>
            <label htmlFor='email'>{t.USERS.REGISTRATION.EMAIL_ADDRESS()}</label>
            <div className='bs4-row mb-5'>
              <NvTextInput
                withForm
                autoComplete='off'
                name='email'
                ariaLabel={t.USERS.REGISTRATION.EMAIL_ADDRESS()}
                className='bs4-col'
                required
                disabled={source === NameAndEmailFormSource.CONTENT_MANAGEMENT_COLLECTION}
              />
              {ssoWarning && (
                <p className='pt-2 px-3 label text-black'>
                  <span className='text-warning'>
                    {t.USER_MANAGEMENT.EDIT_NAME_AND_EMAIL.SSO_WARNING.NOTE()}
                  </span>&nbsp;
                  {t.USER_MANAGEMENT.EDIT_NAME_AND_EMAIL.SSO_WARNING.WARNING()}
                </p>
              )}
            </div>
            <div className='buttons mb-4 bs4-row d-flex justify-content-center'>
              <Button
                className='ml-2'
                variant='secondary'
                onClick={() => onClose()}
              >
                {t.FORM.CANCEL()}
              </Button>
              <Button
                className='ml-2'
                disabled={!isDirty || !isValid || isUpdating}
                type='submit'
                pendo-tag-name={
                  source === NameAndEmailFormSource.COURSE || source === NameAndEmailFormSource.CONTENT_MANAGEMENT_COLLECTION
                    ? config.pendo.userManagement.editNameAndEmail.inCourseUpdate
                    : config.pendo.userManagement.editNameAndEmail.orgLevelUpdate
                }
              >
                {isUpdating ? t.FORM.UPDATING() : t.FORM.UPDATE()}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

type EditModalProps = {
  user?: NameAndEmailFormUser;
  source: NameAndEmailFormSource;
  closeCallback?: () => void;
  forwardShowModal?: (showModal: (user: User) => void) => void,
  catalogId?: string;
  courseUserId?: number;
};

const EditModal = ({
  user,
  source,
  forwardShowModal,
  closeCallback,
  catalogId,
  courseUserId,
}: EditModalProps) => {
  const [formUser, setFormUser] = useState<NameAndEmailFormUser>();
  const [angularUser, setAngularUser] = useState<any>();
  const [show, setShow] = useState(!forwardShowModal);

  const onClose = () => {
    setShow(false);

    closeCallback?.();
  };

  useMountEffect(() => {
    forwardShowModal?.((newUser: User) => {
      setFormUser(cloneDeepSerializable(newUser));
      setAngularUser(newUser);
      setShow(true);
    });
  });

  return (
    <NvModal
      show={show}
      width={600}
      type={ModalType.DYNAMIC}
      fullHeight={false}
      onClose={onClose}
      header={t.USER_MANAGEMENT.EDIT_NAME_AND_EMAIL[source === NameAndEmailFormSource.CONTENT_MANAGEMENT_COLLECTION ? 'EDIT_NAME' : 'OPTION']()}
      body={(user ?? formUser) && (
        <NameAndEmailForm
          user={user ?? formUser}
          source={source}
          updateCallback={(updatedUser) => angularUser?.updateFromReact(updatedUser)}
          onClose={onClose}
          catalogId={catalogId}
          courseUserId={courseUserId}
        />
      )}
    />
  );
};

export default EditModal;
