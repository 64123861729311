import React from 'react';
import { css } from '@emotion/react';
import {
  halfSpacing,
  journeyCardWidth,
  journeyIconSize,
  quarterSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import {
  boldFontWeight,
  normalFontWeight,
  openSans,
  openSansCondensed,
  semiBoldFontWeight,
  textLargeFontSize,
  textSmallFontSize,
} from 'styles/global_defaults/fonts';
import {
  black,
  gray2,
  gray5,
  gray6,
  primary,
  success,
} from 'styles/global_defaults/colors';
import moment from 'moment';
import t from 'react-translate';
import ClickableContainer from 'components/clickable-container';
import NvIcon from 'shared/components/nv-icon';
import { ProgressBar } from 'react-bootstrap';
import { config } from '../../../config/pendo.config.json';

type InnerComponentProps = {
  redirectToJourneyHome: () => void;
  journeyHomeLink: string;
  name: string;
  dateLabel: string;
  progress: number;
  progressLabel: string;
};

type JourneyCardLiteProps = {
  variant?: 'clean' | 'cover';
  name: string;
  releaseDate: string;
  closeDate?: string;
  completionStats: {
    total: number;
    completed: number;
    required: number;
  };
  journeyHomeLink: string;
  headerColor?: string;
  headerBackground?: string;
};

// Extracting the inner component to be implemented in multiple variants of the JourneyCardLite component
const InnerComponent = React.memo(({
  redirectToJourneyHome,
  journeyHomeLink,
  name,
  dateLabel,
  progress,
  progressLabel,
}: InnerComponentProps) => (
  <>
    <div className='upper-container'>
      <ClickableContainer
        onClick={redirectToJourneyHome}
        data-qa={config.pendo.learningJourneys.journeyCardIcon}
        className='bs4-dropdown-item'
      >
        <NvIcon icon='path' size='large' />
      </ClickableContainer>
      <div className='information'>
        <a
          className='name bs4-dropdown-item'
          tabIndex={0}
          href={journeyHomeLink}
          data-qa={config.pendo.learningJourneys.journeyCardLabel}
        >
          {name}
        </a>
        <span className='dates'>{dateLabel}</span>
      </div>
    </div>

    <div className='lower-container'>
      <ProgressBar now={progress} />
      <span data-qa={config.pendo.learningJourneys.journeyCardProgress}>
        {progressLabel}
      </span>
    </div>
  </>
));

export const JourneyCardLite = ({
  variant = 'clean',
  name,
  releaseDate,
  closeDate,
  completionStats,
  journeyHomeLink,
  headerColor,
  headerBackground,
}: JourneyCardLiteProps) => {
  const styles = css`
    max-width: ${journeyCardWidth}px;
    display: flex;
    flex-direction: column;
    gap: ${standardSpacing}px;
    font-family: ${openSans};

    .upper-container {
      display: flex;
      gap: ${halfSpacing}px;
      width: 100%;

      > .bs4-dropdown-item {
        padding: 0;
        width: auto;

        .icon {
          align-self: center;
          font-size: ${journeyIconSize}px;
          color: ${primary};
        }
      }

      .information {
        display: flex;
        flex-direction: column;
        gap: ${quarterSpacing}px;

        a.name.bs4-dropdown-item {
          padding: 0;
          width: auto;
          font-family: ${openSansCondensed};
          font-weight: ${boldFontWeight};
          font-size: ${textLargeFontSize}px;
          color: ${black};
          white-space: initial;
          text-decoration: none;
        }

        .dates {
          font-weight: ${semiBoldFontWeight};
          font-size: ${textSmallFontSize}px;
          color: ${gray2};
        }
      }
    }

    .lower-container {
      display: flex;
      flex-direction: column;
      gap: ${quarterSpacing}px;

      .bs4-progress {
        height: ${quarterSpacing}px;
        background-color: ${gray5};

        .bs4-progress-bar {
          background-color: ${success};
        }
      }

      span {
        font-weight: ${normalFontWeight};
        font-size: ${textSmallFontSize}px;
        color: ${gray2};
      }
    }

    .bs4-dropdown-item:hover {
      background-color: inherit;
    }

    .cover-container {
      width: 100%;
      height: 100px;
      ${headerBackground
    ? `
        background-image: url("${headerBackground}");
        background-size: cover;
        `
    : headerColor && `background-color: ${headerColor};`}
    }

    ${variant === 'cover'
      && `
      border-radius: ${halfSpacing}px;
      border: 1px solid ${gray6};
      background: linear-gradient(315deg, rgba(220, 253, 253, 0.30) 0%, rgba(247, 251, 232, 0.30) 100%), #FFF;
      overflow: hidden;

      .upper-container {
        padding: 0 ${standardSpacing}px;
      }

      .lower-container {
        padding: ${standardSpacing}px;
        padding-top: 0px;
      }
    `}
  `;
  // We take required as reference because a journey could contain optional
  // collections that are not relevant to progress.
  const maxValue = completionStats?.required;
  const currentValue = completionStats?.completed;

  // Setting the date label based on data
  const releaseDateFormated = releaseDate && moment(releaseDate)?.format('L');
  const connector = closeDate ? t.LEARNING_JOURNEYS.COURSE_HOME.JOURNEY_NAVIGATION.TO().toLowerCase() : '';
  const closeDateFormated = closeDate ? moment(closeDate)?.format('L') : '';
  const dateLabel = `${releaseDateFormated} ${connector} ${closeDateFormated}`;

  // Setting the progress label based on data
  const progressLabel = t.OFFERINGS.CARD.X_OUT_OF_Y_COMPLETED(
    currentValue,
    maxValue,
  );

  // Calculating the progress of the learning journey
  // If required collections are zero, then it shows always 100%
  const progress = maxValue > 0
    ? Math.round(
      (Math.min(currentValue, maxValue) / maxValue) * 100,
    )
    : 100;

  // Handling the onClick to take the user to the journey home
  const redirectToJourneyHome = () => {
    window.location.href = journeyHomeLink;
  };

  // Defining the common attributes of multiple variants
  const commonAttributes = {
    css: styles,
    'data-qa': config.pendo.learningJourneys.journeyCard,
  };

  // Defining the props to be passed to the inner component
  const innerComponentAttributes: InnerComponentProps = {
    redirectToJourneyHome,
    journeyHomeLink,
    name,
    dateLabel,
    progress,
    progressLabel,
  };

  // Defining the structure to be shown for multiple variants
  if (variant === 'clean') {
    return (
      <section {...commonAttributes}>
        <InnerComponent {...innerComponentAttributes} />
      </section>
    );
  }

  if (variant === 'cover') {
    return (
      <ClickableContainer
        id='journey-card-lite-cover'
        onClick={redirectToJourneyHome}
        {...commonAttributes}
      >
        <div
          className='cover-container'
          data-qa={config.pendo.learningJourneys.journeyCardCover}
        />
        <InnerComponent {...innerComponentAttributes} />
      </ClickableContainer>
    );
  }

  return null;
};

export default JourneyCardLite;
