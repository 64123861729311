/* @ngInject */
export default function ReplyModelService(

  RepliesResources,
  _,
  moment,
  CurrentUserManager,
  AlertMessages
) {
  const ReplyModel = function (attributes) {
    const _this = this;

    _.extend(_this, attributes);

    preprocess();

    /** Public Data * */
    _this.bodyTranslation = _this.body;
    _this.isTranslationOn = false;
    _this.isTranslationLoading = false;

    /** Public Functions * */
    _this.update = update;
    _this.report = report;
    _this.remove = remove;
    _this.like = like;
    _this.unlike = unlike;
    _this.formatReplyDate = formatReplyDate;
    _this.belongsToCurrentUser = belongsToCurrentUser;
    _this.getLikersInfo = getLikersInfo;
    _this.onToggleTranslate = onToggleTranslate;

    /** Private Functions * */
    function preprocess() {
    }

    function update() {
      return RepliesResources.update({
        catalogId: _this.catalogId,
        id: _this.id,
      }, { comment: _this.body }).$promise;
    }


    function report() {
      return RepliesResources.report({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise;
    }

    function remove() {
      return RepliesResources.remove({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise;
    }

    function like() {
      return RepliesResources.like({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise.then((response) => {
        _this.liked = true;
        _this.votesCount = response.result.numLikes;
      });
    }

    function unlike() {
      return RepliesResources.unlike({
        catalogId: _this.catalogId,
        id: _this.id,
      }, {}).$promise.then((response) => {
        _this.liked = false;
        _this.votesCount = response.result.numLikes;
      });
    }

    function formatReplyDate() {
      return moment(_this.createdAt).format(moment().isSame(_this.createdAt, 'year') ? 'MOMENT.MONTH_DAY_AT_TIME' : 'MOMENT.MONTH_DAY_COMMA_YEAR_AT_TIME');
    }

    function belongsToCurrentUser() {
      return _this.user?.id === CurrentUserManager.user.id;
    }

    function getLikersInfo() {
      return RepliesResources.getLikersInfo({
        catalogId: _this.catalogId,
        id: _this.id,
        page: 1,
      }).$promise.then((response) => {
        _this.likers = response.result;
      });
    }

    function onToggleTranslate(isStateOn, language) {
      _this.isTranslationOn = isStateOn;

      if (isStateOn) {
        _this.isTranslationLoading = true;

        return RepliesResources.translate(
          { catalogId: _this.catalogId, id: _this.id },
          { language }
        )
          .$promise.then(response => {
            _this.bodyTranslation = response.result.body;
            _this.isTranslationLoading = false;
          })
          .catch(() => {
            _this.isTranslationOn = false;
            _this.isTranslationLoading = false;
            AlertMessages.error(
              'DISCUSSIONS_AUTO_TRANSLATION.ERROR',
              'DISCUSSIONS_AUTO_TRANSLATION.RETRY'
            );
          });
      }
      return;
    }
  };

  ReplyModel.replyPageSize = 5;
  ReplyModel.loadMorePageSize = 5;

  ReplyModel.create = function (catalogId, body, commentId, mentionedIds, isReplytoReply) {
    return RepliesResources.save({
      catalogId,
      commentId,
    }, {
      comment: { comment: body, reply: isReplytoReply },
      mentionedIds,
    }).$promise;
  };

  ReplyModel.getSingleReply = function (params) {
    return RepliesResources.getSingleReply({
      catalogId: params.catalogId,
      id: params.id,
    }).$promise;
  };

  ReplyModel.getRepliesForComment = function (params) {
    return RepliesResources.getReplies({
      catalogId: params.catalogId,
      commentId: params.owner.id,
      pageSize: params.pageSize ?? (params.beforeId || params.afterId ? ReplyModel.loadMorePageSize : ReplyModel.replyPageSize),
      beforeId: params.beforeId,
      afterId: params.afterId,
      betweenId: params.betweenId,
      vieweeLastActivity: params.vieweeLastActivity,
    }).$promise;
  };

  ReplyModel.processRepliesList = function (repliesList, params, insertAtFront) {
    const replies = _.map(repliesList, (reply) => new ReplyModel(_.extend(reply, { catalogId: params.catalogId, owner: params.owner })));

    return replies;
  };


  return ReplyModel;
}
