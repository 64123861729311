/* @ngInject */
export default function PrivatePeerEvaluationLectureComponentModel(
  _,
  nvUtil,
  moment,
  $translate,
  $window,
  config,
  PeerEvaluationLectureComponentModel,
  EvaluationsTaskModel,
  CurrentCourseManager,
  RailsRoutes,
) {
  class PrivatePeerEvaluationLectureComponent extends PeerEvaluationLectureComponentModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      const defaults = {
        type: 'PrivatePeerEvaluationLectureComponent',
        editFormTemplateUrl: 'lecture_pages/templates/components/feedback-edit-modal.html',
        editFormController: 'FeedbackEditFormModalCtrl',
        translationPrefix: 'PRIVATE',
        peerEvaluation: {
          isFeedbackPublic: false,
          title: '',
          description: `<div class="froala-style-regular">${$translate.instant('LECTURE_PAGES.COMPONENTS.PRIVATE_PEER_EVALUATION.FEEDBACK_DESCRIPTION', {
            learnersAlias: CurrentCourseManager.course.learnersName.downcasedPluralized,
            courseAlias: CurrentCourseManager.course.offeringName.downcasedSingularized,
            teachingTeamAlias: CurrentCourseManager.course.teachingTeamName.downcasedSingularized,
          })}</div>`,
          exerciseId: null,
          targetGoal: 5,
          isTodo: false,
        },
        updateExercisesAfterCreationDeletion: true,
        isActivity: true,
        canBeCopied: true,
      };

      super(_.extend({}, defaults, attributes));

      this.__preprocess();

      if (useDefaults) {
        this.createDraft();
      }
    }

    get directiveName() {
      return 'nv-peer-evaluation-lecture-component';
    }

    __preprocess() {
      this.evaluationsTask = new EvaluationsTaskModel(_.extend(this.peerEvaluation, { catalogId: this.catalogId }));
      this.scheduledCommunicationsCount = this.peerEvaluation?.scheduledCommunicationsCount;

      this.evaluationsTask.expiringPointsPerPeerReview = nvUtil.getCurrentTotalPoints(this.evaluationsTask.pointsPerPeerReview,
        this.evaluationsTask.releaseDate, CurrentCourseManager.course.isDecayEnabled());
    }

    /* Admin Functions */
    createDraft() {
      this.feedbackDraft = _.clone(this.evaluationsTask);
    }

    getPayload() {
      return {
        peerEvaluation: _.pick(this.evaluationsTask, ['id', 'exerciseId', 'title', 'targetGoal', 'isTodo', 'expirationDate', 'hardDeadline', 'description']),
      };
    }

    saveDraft() {
      _.extend(this.evaluationsTask, this.feedbackDraft);
    }

    afterCreate(catalogId) {
      $window.location = RailsRoutes.editPeerEvaluationsPath(catalogId, this.evaluationsTask.exerciseId);
    }

    moveToWarningMessages(targetLecturePage) {
      const warningMessages = super.moveToWarningMessages(targetLecturePage);

      if (this.evaluationsTask.requiredExercise
          && moment() < moment(this.evaluationsTask.requiredExercise.releaseDate)
          && !targetLecturePage.released
          && (moment(this.evaluationsTask.requiredExercise.releaseDate) > moment(targetLecturePage)
            || (moment(this.evaluationsTask.requiredExercise.deadline) > moment(targetLecturePage) && !this.evaluationsTask.requiredExercise.submissionsViewableBeforeDeadline)
          )
      ) {
        warningMessages.push($translate.instant('LECTURE_PAGES.COMPONENTS.FEEDBACK_SHARED.MOVE_WARNING', CurrentCourseManager.course.getAliases()));
      }

      return warningMessages;
    }

    removeExercise(exercise) {
      if (this.evaluationsTask.requiredExercise?.id === exercise.id) {
        this.evaluationsTask.requiredExercise = null;
      }

      this.peerEvaluation.exercise = null;
      this.peerEvaluation.exerciseId = null;
      this.peerEvaluation.requiredExercise = null;
    }

    hasDeadline() {
      return !!this.evaluationsTask.expirationDate;
    }

    addDefaultDeadline() {
      this.evaluationsTask.expirationDate = this.defaultDeadline;
      this.save();
    }

    removeDeadline() {
      this.evaluationsTask.expirationDate = null;
      this.save();
    }

    get deadline() {
      return this.evaluationsTask.deadline;
    }

    isTodo() {
      return this.evaluationsTask.isTodo;
    }

    toggleToDo() {
      this.evaluationsTask.isTodo = !this.evaluationsTask.isTodo;
      this.save();
    }

    isRequiredForCompletion() {
      return this.evaluationsTask.isRequiredForCompletion;
    }

    isReleased() {
      const releaseMoment = moment(this.evaluationsTask.releaseDate);
      const currentMoment = moment();
      return currentMoment > releaseMoment;
    }

    isDueSoon() {
      return super.isDueSoon(this.evaluationsTask.expirationDate);
    }

    afterUpdate() {
      this.createDraft();
    }
  }

  PrivatePeerEvaluationLectureComponent.iconClass = 'icon-evaluation';
  PrivatePeerEvaluationLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.PRIVATE_PEER_EVALUATION.DESCRIPTION';
  PrivatePeerEvaluationLectureComponent.unavailabilityDescriptionKey = 'LECTURE_PAGES.COMPONENTS.PRIVATE_PEER_EVALUATION.UNAVAILABLE';
  PrivatePeerEvaluationLectureComponent.showModalBeforeCreate = true;
  PrivatePeerEvaluationLectureComponent.pendoTagName = config.pendo.lectureEdit.privateFeedback;

  return PrivatePeerEvaluationLectureComponent;
}
