import React from 'react';

// Translations
import t from 'react-translate';

// Styles
import { css } from '@emotion/react';
import { gray4, gray6 } from 'styles/global_defaults/colors';
import { threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { semiBoldFontWeight, textSmallFontSize } from 'styles/global_defaults/fonts';

type Props = {
  className?: string,
};

const OrDivider = (props: Props) => {
  const { className = '' } = props;
  const dividerStyle = css`
    position: relative;
    line-height: ${threeQuartersSpacing}px;
    text-align: center;
    font-size: ${textSmallFontSize}px;
    font-weight: ${semiBoldFontWeight};
    color: ${gray4};
    &::before,
    &::after {
      position: absolute;
      width: 45%;
      height: 1px;
      top: 50%;
      background-color: ${gray6};
      content: '';
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  `;
  return (
    <div css={dividerStyle} className={`or-divider ${className}`}>
      {t.SHARED.OR()}
    </div>
  );
};

export default OrDivider;
