import { css } from '@emotion/react';
import ProfilePicture from 'org_level_profile/components/profile-picture';
import React, { useCallback, useState } from 'react';
import t from 'react-translate';
// Redux
import { useSelector } from 'react-redux';
import { getCourseAliases } from 'redux/selectors/course';
// Styling
import { halfSpacing, standardSpacing, largeSpacing, tripleSpacing, quarterSpacing, doubleSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { Button } from 'react-bootstrap';
import NvAutocompleteBase from 'shared/components/nv-autocomplete-base';
import { TeamUser } from 'redux/schemas/models/team';
import { moveTeamMembers, getPossibleFutureMembers } from 'redux/actions/teams';
import NvTagTextClosable from 'shared/components/nv-tag-text-closable';
import { useAppDispatch } from 'redux/store';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { Input, LoadingIndicator, SearchResultItem } from './autocomplete-aditional-components';
import { config } from '../../../config/pendo.config.json';

const AddMemberModal = ({ teamInfo, closeModal }) => {
  const aliases = useSelector((state) => getCourseAliases(state, teamInfo.teamSet.catalogId));
  const [futureTeamMembers, setFutureTeamMembers] = useState([]);
  const [saving, setSaving] = useState(false);
  const dispatch = useAppDispatch();
  const style = css`
    padding: 0px ${2 * standardSpacing}px;
    .team-picture {
      display: flex;
      justify-content: center;
    }
    .team-name {
      padding: ${halfSpacing}px 0px ${2 * largeSpacing}px 0px;
      text-align: center;
    }
    .modal-description {
      padding: ${halfSpacing}px 0px;
    }
    .new-members-list {
      padding-top: ${threeQuartersSpacing}px;
    }
    .buttons {
      text-align: center;
      button {
        margin: ${tripleSpacing}px ${quarterSpacing}px;
      }
    }
  `;

  const hasNewMembers = () => futureTeamMembers.length > 0;

  const addMembers = () => {
    const userIds = [];
    futureTeamMembers.forEach(futureTeamMember => {
      userIds.push(futureTeamMember.id);
    });
    setSaving(true);
    const handleConfirm = () => dispatch(
      moveTeamMembers({
        catalogId: teamInfo.courseCatalogId,
        teamId: teamInfo.id,
        userIds,
      }),
    );
    handleConfirm().then((response: any) => {
      setSaving(false);
      if (response.error) {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.FORM.ERROR_SOMETHING_WRONG(),
        }));
      } else {
        dispatch(addAlertMessage({
          type: AlertMessageType.SUCCESS,
          header: t.FORM.SUCCESS_BANG(),
          message: t.TEAMS.MANAGE_TEAM_GROUP.MODAL.ADDING.SUCCESS_ADD_MEMBERS_MESSAGE({ isTeam: !teamInfo.teamSet.isGroup, ...aliases.teamAliases, ...aliases.groupAliases, count: futureTeamMembers.length }),
        }));
      }
      closeModal();
    });
  };

  const addNewTeamMember = (teamMember) => {
    if (teamMember) {
      const newFutureTeamMembers = [...futureTeamMembers];
      newFutureTeamMembers.push(teamMember);
      setFutureTeamMembers(newFutureTeamMembers);
    }
  };

  const removeNewTeamMember = (teamMember) => {
    if (teamMember) {
      const newFutureTeamMembers = [...futureTeamMembers].filter(futureMember => futureMember.id !== teamMember.id);
      setFutureTeamMembers(newFutureTeamMembers);
    }
  };

  const fetchPredictions = useCallback((hint: string) => {
    if (hint) {
      return dispatch(getPossibleFutureMembers({
        text: hint,
        catalogId: teamInfo.courseCatalogId,
        teamId: teamInfo.id,
        teamSetId: teamInfo.teamSet.id,
        // Getting only the first 6 elements.
        pageSize: 6,
        pageNumber: 1,
      })).then(action => action.payload);
    }

    return null;
  }, [teamInfo, dispatch]);

  return (
    <div className='team-info' css={style}>
      <div className='team-picture'>
        <ProfilePicture
          profile={teamInfo}
          isRound={false}
          isTeam
          size={100}
        />
      </div>
      <div className='team-name text-medium bold'>
        {teamInfo.name}
      </div>
      <div className='modal-description'>
        {t.TEAMS.MANAGE_TEAM_GROUP.MODAL.ADDING.DESCRIPTION({ isTeam: !teamInfo.teamSet.isGroup, ...aliases.learnersAliases, ...aliases.teamAliases, ...aliases.groupAliases })}
      </div>
      <div className='search'>
        <NvAutocompleteBase<TeamUser>
          value={null}
          cleanHintOnSelect
          onChange={(teamMember) => { addNewTeamMember(teamMember); }}
          fetchPredictions={fetchPredictions}
          keyExtractor={(localMember) => localMember.id}
          resultsContainerClassName='autocomplete-results-container'
          renderLoadingIndicator={() => <LoadingIndicator />}
          renderNoResults={() => (
            <SearchResultItem
              text={t.TEAMS.MANAGE_TEAM_GROUP.MODAL.ADDING.RESULTS_NOT_FOUND()}
              isValidResult={false}
            />
          )}
          renderInput={(inputProps) => (
            <Input
              placeholder={t.TEAMS.MANAGE_TEAM_GROUP.MODAL.ADDING.PLACEHOLDER()}
              data-qa={config.pendo.manageOneTeamGroup.modalContent.body.addMemberModal.newMemberSearchBar}
              {...inputProps}
            />
          )}
          renderResultItem={(resultItemProps, newMember) => (
            <SearchResultItem
              text={`${newMember.fullName}${newMember.team?.name ? ` (${newMember.team.name})` : ''}`}
              {...resultItemProps}
              denied={!!futureTeamMembers.find(futureTeamMember => futureTeamMember.id === newMember.id)}
            />
          )}
        />
      </div>
      <div className='new-members-list'>
        {
          futureTeamMembers.map((futureMember) => (
            <NvTagTextClosable
              key={futureMember.id}
              text={`${futureMember.fullName}${futureMember.team?.name ? ` (${futureMember.team.name})` : ''}`}
              className='new-member'
              onClose={() => { removeNewTeamMember(futureMember); }}
            />
          ))
        }
      </div>
      <div className='buttons'>
        <Button
          variant='secondary'
          onClick={() => { closeModal(); }}
          data-qa={config.pendo.manageOneTeamGroup.modalContent.body.addMemberModal.cancelAdd}
        >
          {t.TEAMS.MANAGE_TEAM_GROUP.MODAL.ADDING.CANCEL()}
        </Button>
        <Button
          variant='primary'
          disabled={!hasNewMembers() || saving}
          onClick={() => { addMembers(); }}
          data-qa={config.pendo.manageOneTeamGroup.modalContent.body.addMemberModal.confirmAdd}
        >
          {t.TEAMS.MANAGE_TEAM_GROUP.MODAL.ADDING.ADD_TO_TEAM({ isTeam: !teamInfo.teamSet.isGroup, ...aliases.teamAliases, ...aliases.groupAliases })}
        </Button>
      </div>
    </div>
  );
};

export default AddMemberModal;
