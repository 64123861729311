import { css } from '@emotion/react';
import omit from 'lodash/omit';
import React, { useState, useContext, useCallback, useMemo, forwardRef, MutableRefObject, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import t from 'react-translate';
import moment from 'moment';

// schemas
import { CommunicationCategory, CommunicationType, ItemState, ScheduledForType } from 'redux/schemas/models/course-communication';

// contexts
import { CommunicationDispatch, TriggerType } from 'communications/course_communications/contexts/communication-context';

// selectors
import { getItemState } from 'redux/selectors/course-communications';
import { isConfirmationDialogShown } from 'redux/selectors/confirmation-dialogs';

// components
import NvDropdown, { NvDropdownTextItem, NvDropdownButtonStyle } from 'shared/components/inputs/nv-dropdown';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvDatePicker, { DatePickerType } from 'shared/components/inputs/nv-datepicker';
import { NvPopover } from 'shared/components/nv-popover';
import ValidationErrorMessage from 'shared/components/inputs/validation-error-message';
import CommunicationBadge from 'shared/components/communication-badge';

// styles
import { gray3, gray7, info, primary } from 'styles/global_defaults/colors';
import { halfSpacing, standardSpacing, quarterSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';

type JourneySendOnProps = {
  inactive?: boolean
  details?: string
  referenceDate?: string
};

type JourneySendOnType = {
  title: string
  option: string
  type: 'string' | 'input' | 'datepicker'
  scheduledFor?: ScheduledForType
};

const getItems = (): JourneySendOnType[] => (
  [
    {
      title: t.COURSE_COMMUNICATIONS.SEND_ON.CUSTOM.NOW.TITLE(),
      option: t.COURSE_COMMUNICATIONS.SEND_ON.CUSTOM.NOW.OPTION(),
      type: 'string',
      scheduledFor: ScheduledForType.SEND_NOW,
    },
    {
      title: t.COURSE_COMMUNICATIONS.SEND_ON.CUSTOM.LATER.TITLE(),
      option: t.COURSE_COMMUNICATIONS.SEND_ON.CUSTOM.LATER.OPTION(),
      type: 'datepicker',
      scheduledFor: ScheduledForType.SCHEDULED,
    },
  ]
);

const GetTitle = forwardRef((props: {
  item: JourneySendOnType,
  canShowTriggerWarning: boolean
  isDisabled: boolean,
}, ref: MutableRefObject<HTMLInputElement>) => {
  const { item } = props;

  const [showTriggerTimeWarning, setShowTriggerTimeWarning] = useState(false);

  const styles = css`
    &.custom-target {
      display: flex;
      align-items: center;
      color: ${props.isDisabled ? gray3 : primary};

      & > * {
        display: inline-block;
      }

      .icon {
        display: flex;
        align-items: center;
      }
    }
  `;

  const onInputPropsChange = (e) => {
    const { target: { value }, type } = e;
    const showPopOver = !!value && type === 'blur';

    setShowTriggerTimeWarning(showPopOver);
  };

  if (item.type === 'input') {
    const [prefix, suffix] = item.title.split('INPUT');

    return (
      <div className='custom-target text-large-regular bold' css={styles}>
        {prefix}
        <NvPopover
          show={showTriggerTimeWarning && props.canShowTriggerWarning}
          placement='top'
          preventOverflow={false}
          content={(
            <ValidationErrorMessage
              title={t.COURSE_COMMUNICATIONS.SEND_ON.WARNING.TITLE()}
              text={t.COURSE_COMMUNICATIONS.SEND_ON.WARNING.NOTE()}
            />
          )}
        >
          <NvTextInput
            withForm
            required
            type='number'
            autoComplete='off'
            name='xDays'
            ariaLabel={t.COURSE_COMMUNICATIONS.SEND_ON.ARIA_LABEL()}
            inputClassName='text-large-regular'
            onBlur={(e) => onInputPropsChange(e)}
            onFocus={(e) => onInputPropsChange(e)}
            onChange={(e) => {
              setShowTriggerTimeWarning(!!Number(e.target.value));
            }}
            ref={ref}
          />
        </NvPopover>
        {suffix}
        <div className='icon text-xs icon-dropdown-arrow ml-2' />
      </div>
    );
  }

  return (
    <div className='custom-target' css={styles}>
      <div className='text-large-regular bold'>{item.title}</div>
      <div className='icon text-xs icon-dropdown-arrow ml-2' />
    </div>
  );
});

export const JourneySendOn = (props: JourneySendOnProps) => {
  const styles = (isDisabled: boolean) => css`
    &.send-on {
      padding: ${standardSpacing}px;
      background-color: ${info};
      border-radius: 10px;

      .nv-text-input {
        display: inline-block;
        width: ${tripleSpacing}px;
        margin-left: ${quarterSpacing}px;
        margin-right: ${quarterSpacing}px;
      }

      .details {
        margin-top: ${halfSpacing}px;
      }

      .send-on-date {
        display: inline-block;
        margin-left: ${standardSpacing}px;

        .nv-text-input {
          width: 250px;
          margin: auto;
        }

        input[readonly] {
          background-color: ${gray7};
        }
      }
      .badge-wrapper {
        margin: -${standardSpacing}px
      }
    }

    .edit-icon {
      cursor: pointer;

      .icon {
        display: inline;
      }
    }

    .send-on-dropdown-wrapper {
      display: flex;
      align-items: center;
      ${isDisabled && 'pointer-events:none;'}
    }
  `;

  const { State: communicationState } = useContext(CommunicationDispatch);

  const itemState = useSelector((state) => getItemState(state, communicationState.communicationId));
  const isConfirmationOverlayShown = useSelector((state) => isConfirmationDialogShown(state));

  const { control, setValue, trigger, watch } = useFormContext();
  const [scheduledFor, scheduledOn] = watch(['scheduledFor', 'scheduledOn']);

  const items = useMemo(() => getItems(), [communicationState.communicationType]);
  const initialIndex = items.findIndex((item) => item.scheduledFor === (scheduledFor
    ?? ScheduledForType.SEND_NOW));

  const selected = useMemo(() => items[initialIndex], [items, initialIndex]);
  const ref = useRef<HTMLInputElement>();

  const showStatusBadge = communicationState.triggerType === TriggerType.EDIT && itemState === ItemState.ACTIVATED;
  const isDisabled = communicationState.communicationCategory === CommunicationCategory.SCHEDULED_ANNOUNCEMENT
    && itemState === ItemState.SENT
    && communicationState.triggerType === TriggerType.EDIT;

  // The warning popover will be enabled when state is not Draft/Error and there is no overlay
  const canShowTriggerWarning = communicationState.triggerType === TriggerType.EDIT
    && (communicationState.communicationType === CommunicationType.COMPLETED
      || communicationState.communicationType === CommunicationType.NOT_COMPLETED) // warning is only for triggers
    && itemState
    && itemState !== ItemState.DRAFT
    && itemState !== ItemState.ERROR && !isConfirmationOverlayShown;

  const onSelect = (item) => {
    setValue('scheduledFor', item.scheduledFor, { shouldValidate: true, shouldDirty: true });

    if (item.type === 'input' || item.type === 'datepicker') {
      setTimeout(() => {
        ref.current?.focus();
        trigger('scheduledFor');
      });
    }
  };

  const dropdownData: NvDropdownTextItem[] = items.map((item) => ({
    type: 'text',
    text: item.option,
    callback: () => onSelect(item),
  }));

  const customTarget = useCallback(
    (titleProps) => (
      <GetTitle
        {...titleProps}
        item={selected}
        ref={ref}
        canShowTriggerWarning={canShowTriggerWarning}
        isDisabled={isDisabled}
      />
    ),
    [selected, canShowTriggerWarning, isDisabled],
  );

  const [prefix, suffix] = props.details ? props.details.split('EDIT_ICON') : [];

  return (
    <div css={styles(isDisabled)} className='send-on'>
      {showStatusBadge && <CommunicationBadge communicationId={communicationState.communicationId} />}
      <div className='send-on-dropdown-wrapper'>
        <Controller
          name='scheduledFor'
          control={control}
          render={({ field }) => (
            <NvDropdown
              showSelectedIndicator
              items={dropdownData}
              buttonStyle={NvDropdownButtonStyle.CUSTOM}
              customTarget={customTarget}
              initialIndex={initialIndex}
              {...omit(field, ['ref'])}
            />
          )}
        />
        {selected?.type === 'datepicker' && (
          <NvDatePicker
            withForm
            required
            name='scheduledOn'
            type={DatePickerType.DATETIME}
            placeholder={t.FORM.PICK_DATE_TIME()}
            placement='top-start'
            className='send-on-date'
            min={moment()}
            value={scheduledOn}
          />
        )}
      </div>
      {props.details && (
      <React.Fragment>
        <div className='mt-2'>
          <span>{prefix + suffix}</span>
        </div>
      </React.Fragment>
      )}
    </div>
  );
};

export default JourneySendOn;
