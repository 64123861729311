export default {
  bindings: {
    lastActivity: '<',
    progress: '<',
    onClick: '&',
    completionCriteria: '<',
    course: '<',
    hideStatusText: '<?',
  },
  controller: function ctrl(
  ) {
'ngInject';
  },
  controllerAs: 'vm',
  templateUrl: 'mentors/templates/nv-progress-bar-group.html',
};
