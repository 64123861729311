import { openConfirmationDialog, closeConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { ConfirmationDialog } from 'redux/schemas/app/confirmation-dialog';
import { createReducer } from '@reduxjs/toolkit';
import { initialRootState, newState } from '.';

export const initialConfirmationDialog: ConfirmationDialog = {
  isShown: false,
  title: null,
  bodyText: null,
  cancelText: null,
  confirmText: null,
  onCancel: null,
  onConfirm: null,
  icon: null,
  showIcon: true,
  cancelPendoTag: null,
  confirmPendoTag: null,
  cancelDataQa: null,
  confirmDataQa: null,
};

const reducer = createReducer(initialRootState, builder => {
  builder
    // TODO: We should probably only have one reducer here and simply read the
    // value of ConfirmationDialog.isShown
    .addCase(openConfirmationDialog, (state, action) => {
      state.app.confirmationDialog = action.payload;
      state.app.confirmationDialog.isShown = true;
    })
    .addCase(closeConfirmationDialog, (state, action) => {
      state.app.confirmationDialog = { ...initialConfirmationDialog };
      state.app.confirmationDialog.isShown = false;
    });
});

export default reducer;
