import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { css } from '@emotion/react';
import { useInView } from 'react-intersection-observer';

import { CourseTimelineContext } from 'timelines/components/course_home/course-timeline';
import { useAppDispatch } from 'redux/store';
import { RootState } from 'redux/schemas';
import { getAwardedPoints, getTimelinePointsList } from 'redux/actions/timeline';
import { AwardedPoint, TimelineActivity } from 'redux/schemas/models/activity';
import { getCourseAliases } from 'redux/selectors/course';
import { getCurrentUser } from 'redux/selectors/users';

import LoadingPlaceholder, { LoaderType } from 'shared/components/loading-placeholder';
import TimelinePointRow from 'timelines/components/timeline-row/timeline-point-row';
import NvIcon from 'shared/components/nv-icon';
import { tripleSpacing } from 'styles/global_defaults/scaffolding';
import useCompletionCriteria from '../../use-completion-criteria';

const styles = css`
  .no-points {
    margin-top: ${tripleSpacing}px;
  }
`;

const Points = () => {
  const dispatch = useAppDispatch();
  const { vieweeId } = useContext(CourseTimelineContext) || {};

  const aliases = useSelector((state) => getCourseAliases(state));

  const catalogId = useSelector((state: RootState) => state.app.currentCatalogId);
  const currentUser = useSelector(getCurrentUser);
  const { loadingTimelinePoints, hasMore, lastLoadedPage } = useSelector((state: RootState) => state.app.timeline.points);
  const points: TimelineActivity[] = useSelector((state: RootState) => state.models.timelines[catalogId]?.pointActivities?.filter(({ pointsReceived }) => pointsReceived));
  const awardedPoints: AwardedPoint[] = useSelector((state: RootState) => state.models.timelines[catalogId]?.awardedPoints);

  const { ref: endRef, inView } = useInView();

  const {
    requiredPoints,
    pointsReceived,
  } = useCompletionCriteria();

  useEffect(() => {
    if (inView && !loadingTimelinePoints && hasMore) {
      // fetch the timeline points
      dispatch(getTimelinePointsList({
        catalogId,
        page: lastLoadedPage + 1,
        vieweeId,
      }));
    }
  }, [
    dispatch, catalogId, inView, hasMore, loadingTimelinePoints,
    lastLoadedPage, vieweeId,
  ]);

  useEffect(() => {
    dispatch(getAwardedPoints({
      catalogId,
      vieweeId: vieweeId ?? currentUser.id,
    }));
  }, [dispatch, catalogId, currentUser.id, vieweeId]);

  return (
    <div css={styles} className='body-text-wrapper'>
      <div className='mt-6 text-center card-title'>
        { requiredPoints > 0 && t.COMPLETION_CRITERIA.EARN_POINTS({
          requiredPoints,
          pointsAlias: aliases.pointsAliases.PointsAlias,
        }) }
      </div>
      <div>
        {!loadingTimelinePoints && pointsReceived === 0 && (
          <div className='no-points d-flex flex-column align-items-center gray-5'>
            <NvIcon icon='points' size='ultra-large' />
            <span className='text-regular bold gray-3 pt-5'>
              {t.GAMIFICATION.NO_POINTS_YET(aliases.pointsAliases)}
            </span>
          </div>
        )}
        {points?.map((point) => (
          <TimelinePointRow
            key={`point-${point.lectureComponentId}-${point.id}`}
            activity={point}
          />
        ))}
        {loadingTimelinePoints && (
          <div className='pt-4'>
            <LoadingPlaceholder loaderType={LoaderType.HALF} />
          </div>
        )}
        <span ref={endRef} />
        {!hasMore && !loadingTimelinePoints && (
          <React.Fragment>
            {awardedPoints?.map((awarded) => (
              <TimelinePointRow
                // Not 100% unique key, but this doesn't have an id
                key={`awarded-${awarded.issuedDate}-${awarded.receivedPoints}`}
                awarded={awarded}
              />
            ))}
            {pointsReceived > 0 && (
              <div className='mt-4 mr-4 d-flex justify-content-end card-title'>
                {t.GAMIFICATION.TOTAL_OUTLINE_POINTS({
                  pointsIncludingCredits: pointsReceived,
                  ...aliases.pointsAliases,
                })}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default Points;
