import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';

// Actions
import { useAppDispatch } from 'redux/store';
import { getAutomatedCommunications } from 'redux/actions/course-communications';

// Schemas
import { RootState } from 'redux/schemas';
import { Communication } from 'redux/schemas/models/course-communication';

// Selectors
import { getCurrentCourseWelcomeEmail } from 'redux/selectors/course-communications';
import { getJourney } from 'redux/selectors/learning-journeys';

// Styles
import { css } from '@emotion/core';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { gray4 } from 'styles/global_defaults/colors';

// Components
import NvIcon from 'shared/components/nv-icon';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';
import WelcomeEmailRow from '../../course_communications/components/welcome-email-row';
import LoadingPlaceholder from '../../course_communications/components/loading-placeholder';
import WelcomeEmail from './welcome-email';

const AutomatedCommunications = ({ welcomeEmailEnabled }) => {
  const welcomeEmailLoaded = useSelector<RootState, Communication>((state) => getCurrentCourseWelcomeEmail(state));
  const isLoadingEmail = welcomeEmailEnabled && !welcomeEmailLoaded;
  const dispatch = useAppDispatch();
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const currentJourney = useSelector((state) => getJourney(state, catalogId));
  const isCommunicationsLoading = useSelector(state => currentJourney && state.app.courseCommunications[catalogId].automatedCommunications.isCommunicationsLoading?.[currentJourney.id]);
  const communicationsIds = useSelector(state => state.models.courses[catalogId]?.automatedCommunicationIds);


  const styles = css`
    .no-journey-communications {
      display: flex;
      align-items: center;
      padding: 0px ${standardSpacing}px;
      .icon {
        color: ${gray4};
        padding: ${standardSpacing}px;
      }
    }
  `;

  const fetchCommunications = useCallback((props) => {
    dispatch(getAutomatedCommunications(props));
  }, []);
  // Fetch all automated communications
  useEffect(() => {
    if (currentJourney?.catalogId) {
      fetchCommunications({
        catalogId: currentJourney.catalogId,
        id: currentJourney.id,
      });
    }
  }, [currentJourney?.catalogId, currentJourney?.id, fetchCommunications]);

  const getNoContentComponent = () => (
    <div className='no-journey-communications bg-info mt-6 text-center'>
      <div className='bold'>
        <NvIcon size='large' icon='calendar' />
      </div>
      <div className='text-regular'>
        {t.JOURNEY_COMMUNICATIONS.NO_CONTENT.AUTOMATED()}
      </div>
    </div>
  );

  return (
    <React.Fragment>
      { isLoadingEmail && <LoadingPlaceholder /> }
      { !isLoadingEmail && (
        <React.Fragment>
          <div className='page-title mt-6 text-center'>
            {t.JOURNEY_COMMUNICATIONS.TABS.TITLE.AUTOMATED()}
          </div>
          <p className='pt-4'>{t.JOURNEY_COMMUNICATIONS.TABS.DESCRIPTION.AUTOMATED.INTRO_FIRST()}</p>
          <div className='pt-3'>
            <div className='mb-4'>
              {
                welcomeEmailEnabled
                  ? <WelcomeEmailRow />
                  : <WelcomeEmail />
              }
            </div>
          </div>
        </React.Fragment>
      )}
      {isCommunicationsLoading && <LoadingPlaceholder />}
      {!isCommunicationsLoading && (
        <div css={styles}>
          {communicationsIds?.length === 0
            ? (
              <div css={styles}>
                {getNoContentComponent()}
              </div>
            ) : (
              <React.Fragment>
                { communicationsIds?.map(communicationId => (
                  <div className='mt-4'>
                    <CommunicationItem
                      key={communicationId.toString()}
                      communicationId={communicationId}
                      showOwnerActivity
                      showLateralColorBar={false}
                    />
                  </div>
                ))}
              </React.Fragment>
            )}
        </div>
      )}
    </React.Fragment>
  );
};

export default AutomatedCommunications;
