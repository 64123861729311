/* @ngInject */
export default function MultipleChoiceQuestionService(

  _,
) {
  const MultipleChoiceQuestion = function (attributes) {
    const _this = this;
    const base = {
      response: null,
      templateUrl: 'quizzes/templates/questions/nv-multiple-choice-question.html',
      displayState: null,
    };

    _this.getCorrectAnswer = function () {
      const correctAnswer = _.find(_this.responseOptions, (responseOption) => responseOption.isCorrect);
      if (correctAnswer) {
        return correctAnswer.optionContent;
      }
      return null;
    };

    _this.getResponse = function () {
      return _this.currentResponse;
    };

    /** Setting Up Basic Attributes * */
    _.extend(_this, base, attributes);

    /** Public Functions * */
    _this.isUnAnswered = isUnAnswered;
    _this.responsePayload = responsePayload;
    _this.otherFieldResponsePayload = otherFieldResponsePayload;
    _this.setResponse = setResponse;
    _this.resetResponse = resetResponse;
    _this.updateResponseOptions = updateResponseOptions;

    function isUnAnswered() {
      return !_this.response;
    }

    function responsePayload() {
      return _this.response;
    }

    function otherFieldResponsePayload() {
      return _this.otherField;
    }

    function updateResponseOptions(question) {
      _this.responseOptions = question.responseOptions;
    }

    function setResponse(response) {
      _this.currentResponse = response;
      _this.response = response.response;
    }

    function resetResponse() {
      _this.response = null;
    }
  };

  return MultipleChoiceQuestion;
}
