
import { AngularServicesContext } from 'react-app';
import { useRef, useEffect, useContext } from 'react';
import { embedAngularTemplate } from 'shared/embed-angular';
import { getManuallyAwardedPoints } from 'redux/actions/learner-progress';
import { BaseUser } from 'redux/schemas/models/my-account';
import { useAppDispatch } from 'redux/store';

type AwardExtraPointsProps = {
  user: BaseUser,
  catalogId: string,
  closeModal: () => void,
};

const AwardExtraPointsModal = (props: AwardExtraPointsProps) => {
  const dispatch = useAppDispatch();

  const awardPointsRef = useRef(null);
  const angularServices = useContext(AngularServicesContext);

  useEffect(() => {
    dispatch(getManuallyAwardedPoints({ catalogId: props.catalogId })).then(res => {
      const awardedPoints = res.payload.filter((award) => award.users.filter((u) => u.id === props.user.id).length);
      angularServices.$scope.user = props.user;
      angularServices.$scope.closeModal = props.closeModal;
      angularServices.$scope.awardedPoints = awardedPoints;
      embedAngularTemplate('learner_progress/templates/award-extra-points-modal.html', awardPointsRef, angularServices);
    });
  }, []);

  return (
    <div>
      <div ref={awardPointsRef} />
    </div>
  );
};

export default AwardExtraPointsModal;
