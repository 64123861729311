import { css, SerializedStyles } from '@emotion/react';

import {
  ChangeEvent, KeyboardEvent, useState, useEffect, forwardRef, CSSProperties, MutableRefObject,
} from 'react';
import { halfSpacing, threeQuartersSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { gray4, primary, gray3 } from 'styles/global_defaults/colors';
import t from 'react-translate';
import { NvTooltip } from './nv-tooltip';

type NvExpandableSearchBarProps = {
  initialQuery?: string,
  onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void,
  onSearch: (query: string, e: KeyboardEvent<HTMLInputElement>) => void,
  isExpanded?: boolean,
  /** TODO: I think we might have a bug here if you set isExpanded but not onExpanded. The passed-down isExpanded state will never get updated */
  onExpanded?: (isExpanded: boolean) => void,
  placeholder?: string,
};

const styles = css`
  display: flex;
  /* Keep the bar a constant size so that it will mask the NvResponsiveTabs on small devices */
  /* This will need changed if used outside of a table search experience */
  /* flex-shrink: 0; */
  max-width: none; /* Reset the max-width set in the angularjs nv-expandable-search-bar */

  .icon-search.expanded {
    margin-right: ${threeQuartersSpacing}px;
  }

  .icon-search:not(.expanded) {
    margin-left: auto;
  }

  .icon-search:not(.expanded), .icon-close {
    cursor: pointer;
    &:hover {
      color: ${primary} !important;
    }
  }

  .icon-close {
    margin-left: ${standardSpacing * 2}px;
    align-self: center;
    font-size: 10px;
    height: 10px;
  }

  input {
    width: 0;
    background-color: transparent;
    border: none;
    &::placeholder {
      color: ${gray3};
    }

    /* Resets the default padding and corrects the height set in iOS Safari */
    padding: 0;
    height: ${standardSpacing}px;
  }

  input:focus {
    outline: none;
  }

  input.expanded {
    width: 100%;
  }
`;

/**
 * NovoEd-customized wrapper around a react-bootstrap Dropdown component.
 */
export const NvExpandableSearchBar = forwardRef((props: NvExpandableSearchBarProps, ref: MutableRefObject<HTMLInputElement>) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.onInputChange) {
      props.onInputChange(e);
    }
  };

  const handleClick = () => {
    props.onExpanded?.(!props.isExpanded);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // Fire the search callback when the enter key is pressed
    if (e.keyCode === 13) {
      props.onSearch(e.currentTarget.value, e);
    }
  };

  const dynamicWidth: CSSProperties = {
    minWidth: props.isExpanded ? '270px' : 'auto',
  };

  useEffect(() => {
    ref.current.focus();
  }, [props.isExpanded]);

  return (
    <div css={styles} className='nv-expandable-search-bar' style={dynamicWidth}>
      <NvTooltip text={t.COURSES.SEARCH.TITLE()} placement='top' enabled>
        <div className={`icon icon-small icon-search text-gray-2 ${props.isExpanded && 'expanded'}`} onClick={() => !props.isExpanded && handleClick()} />
      </NvTooltip>
      <input
        ref={ref as any}
        className={`page-title-xs ${props.isExpanded && 'expanded'}`}
        type='text'
        onChange={(e) => handleChange(e)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={props.placeholder}
        size={props.placeholder?.length && props.placeholder.length - 5}
        defaultValue={props.initialQuery}
      />
      {props.isExpanded && (
        <NvTooltip text={t.NOVOED.CLOSE()}>
          <div className='icon icon-close text-gray-2' onClick={() => handleClick()} />
        </NvTooltip>
      )}
    </div>
  );
});

export default NvExpandableSearchBar;
