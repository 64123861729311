/* @jsx */
import { css } from '@emotion/react';

export const innerCenteredRadioButtonContainerStyle = css`
  background-color: #748499;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

type RadioButtonContainerProps = {
  checked?: boolean,
  children?: any,
  className?: string,
};

export const RadioButtonContainer = (props: RadioButtonContainerProps) => {
  const { checked, children, className = '' } = props;
  const radioButtonStyles = css`
    border: 1px dashed #acb5c2;
    height: 24px;
    width: 24px;
    padding: 3px;
    border-color: ${checked ? '#acb5c2' : '#fff'};
    &:hover {
      border-color: #acb5c2;
    }
  `;
  return <div css={radioButtonStyles} className={className}>{children}</div>;
};

export interface RadioButtonProps {
  checked: boolean;
  children?: React.ReactNode;
}

//
// Radio button Components
//

/* @ngInject */
export function SquareCentered(props: RadioButtonProps) {
  return (
    <RadioButtonContainer checked={props.checked}>
      <div css={innerCenteredRadioButtonContainerStyle} className='condensed font-weight-bolder text-sm'>
        {props.children}
      </div>
    </RadioButtonContainer>
  );
}

/* @ngInject */
export function CircleCentered(props: RadioButtonProps) {
  return (
    <RadioButtonContainer checked={props.checked} className='rounded-circle'>
      <div
        css={css`
          ${innerCenteredRadioButtonContainerStyle};
        `}
        className='condensed text-sm font-weight-bolder rounded-circle'
      >
        {props.children}
      </div>
    </RadioButtonContainer>
  );
}
