import { css } from '@emotion/react';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

// Schemas
import { Communication, ItemState } from 'redux/schemas/models/course-communication';
import { UserRole } from 'redux/schemas/models/user';
import { RootState } from 'redux/schemas';

// selectors
import { getItemState } from 'redux/selectors/course-communications';

// Contexts
import { CommunicationDispatch, TriggerType } from 'communications/course_communications/contexts/communication-context';

// styles
import { info, warning } from 'styles/global_defaults/colors';
import { halfSpacing } from 'styles/global_defaults/scaffolding';

// Components
import { CommunicationTriggerRow } from 'communications/course_communications/components/communication-item/communication-trigger-row';
import { CommunicationFilterRow } from 'communications/course_communications/components/communication-item/communication-filter-row';
import CommunicationBadge from 'shared/components/communication-badge';

type StateProps = {
  communication: Communication,
  rolesFilterTitles: string[],
  showOwnerActivity?: boolean
};

const styles = css`
  background-color: ${info};
  border-radius: ${halfSpacing}px;

  .icons-wrapper {
    width: 25px;

    .icon {
      color: ${warning};
    }
  }
`;

const CommunicationItemPreview = (props: StateProps) => {
  const { State } = useContext(CommunicationDispatch);
  const itemState = useSelector<RootState, ItemState>((state) => getItemState(state, State.communicationId));
  const showStatusBadge = State.triggerType === TriggerType.EDIT && itemState === ItemState.ACTIVATED;

  return (
    <div css={styles}>
      {showStatusBadge && <CommunicationBadge communicationId={State.communicationId} />}
      <CommunicationTriggerRow
        communication={props.communication}
        showOwnerActivity={props.showOwnerActivity}
        isPreview={State.triggerType === TriggerType.CREATE
          || State.triggerType === TriggerType.EDIT
          || State.triggerType === TriggerType.DUPLICATE}
      />
      <CommunicationFilterRow
        communication={props.communication}
        rolesFilterTitles={props.rolesFilterTitles}
        showOwnerActivity={props.showOwnerActivity}
        itemState={itemState}
      />
    </div>
  );
};

export default CommunicationItemPreview;
