import { Course } from 'redux/schemas/models/course';
import { ReactNode } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { standardSpacing, halfSpacing, createGridStyles } from 'styles/global_defaults/scaffolding';
import moment from 'moment';
import {
  gray5, warning, success, primary, yellow,
} from 'styles/global_defaults/colors';
import uuid from 'react-uuid';
import { tablet } from 'styles/global_defaults/media-queries';
import t from 'react-translate';
import { createCourseTableColumns } from './institution-dashboard-home';

type MetricPanelProps = {
  className: string,
  count: number,
  pct: number,
  label: string,
  color: string,
  key: string,
};

const CourseDetailsRow = (props: {
  data: Course,
  rowIndex: number,
  style?: React.CSSProperties,
  children?: ReactNode
}) => {
  const course = props.data;

  if (!course.loginLearners) {
    return <div />;
  }

  const styles = css`
    .metric-panel-container {
      display: flex;
      & > * {
        &:not(:last-of-type) {
          border-right: solid 1px ${gray5};
        }

        margin-top: ${standardSpacing}px;
        margin-bottom: ${standardSpacing}px;

        padding-left: ${standardSpacing * 2}px;
        padding-right: ${standardSpacing * 2}px;

        ${tablet(css`
          padding-left: ${standardSpacing}px;
          padding-right: ${standardSpacing}px;

          /* Overwriting these utility classes is dangerous because we'll miss updating these sizes if we
           * change the class used. Is there a better way to apply different text util classes at different
           * screen breakpoints? */
          .course-title {
            font-size: 18px;
            line-height: 30px;
          }

          .course-title-small {
            font-size: 14px;
          }

          .page-title-xs {
            font-size: 12px;
            line-height: 15px
          }
        `)};
      }
    }

    .metric-panel {
      display: grid;
      display: -ms-grid;
      grid-template-rows: repeat(3, auto);
      -ms-grid-template-rows: ${'(auto)[3]'};
      grid-template-columns: 24px auto;
      -ms-grid-template-columns: 24px auto;
      /* The above column width doesn't seem to take in Edge, so setting manually here */
      .dot-container {
        width: 24px;
      }

      white-space: nowrap;
      max-width: 25%;

      ${tablet(css`
        flex: 1;
        white-space: normal;
        grid-template-columns: 19px auto;
        -ms-grid-template-columns: 19px auto;
        .dot-container {
          width: 19px;
        }
      `)};
    }

    .dot-container {
      display: flex;
      align-items: center;
      justify-content: start;
      border: 0;
    }

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 45%;

      grid-column: 1;
      grid-row: 1;
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }

    .top-header {
      grid-column: 2;
      grid-row: 1;
      -ms-grid-column: 2;
      -ms-grid-row: 1;
    }

    .page-title-xs {
      grid-column: 2;
      grid-row: 2;
      -ms-grid-column: 2;
      -ms-grid-row: 2;
    }

    .progress-panel {
      margin-top: ${halfSpacing}px;
      display: flex;
      grid-column: 2;
      grid-row: 3;
      -ms-grid-column: 2;
      -ms-grid-row: 3;
    }

    .bar {
      height: 3px;
      width: 100%;
    }

    .bar-remaining {
      background-color: ${gray5};
    }
  `;

  const MetricPanel = (mpProps: MetricPanelProps) => {
    const pct = Math.round(mpProps.pct);
    const key = `${mpProps.className}-${props.rowIndex}`;
    return (
      <div key={key} className={`metric-panel ${mpProps.className}`}>
        <div className='dot-container' style={createGridStyles(1, 1)}>
          <div className='dot' style={{ backgroundColor: mpProps.color }} />
        </div>
        <div className='top-header text-black' style={createGridStyles(2, 1)}>
          <span className='course-title'>
            {mpProps.count}
            &nbsp;
            {t.COURSES.DASHBOARD_TABLE.USERS(mpProps.count)}
          </span>
          <span className='course-title-small'>
            &nbsp;(
            {pct}
            %)
          </span>
        </div>
        <div />
        <div className='page-title-xs' style={createGridStyles(2, 2)}>{mpProps.label}</div>
        <div />
        <div className='progress-panel' style={createGridStyles(2, 3)}>
          <div className='bar bar-progress' style={{ width: `${pct}%`, backgroundColor: mpProps.color }} />
          <div className='bar bar-remaining' style={{ width: `${100 - pct}%` }} />
        </div>
      </div>
    );
  };

  const panelsData: MetricPanelProps[] = [
    {
      key: uuid(), // For some reason I can't seem to avoid getting a "unique key prop" required error without specifying a key here
      className: 'logged-in',
      count: course.loginLearners.loggedIn,
      pct: course.loginLearners.percentage,
      label: t.INSTITUTIONS.LICENSES.DASHBOARD.LOGGED_IN(),
      color: warning,
    }, {
      key: uuid(),
      className: 'complete',
      count: course.learnersCompleted.learnersCompleted,
      pct: course.learnersCompleted.percentage,
      label: t.COURSES.DASHBOARD_TABLE.COMPLETE(),
      color: success,
    }, {
      key: uuid(),
      className: 'in-progress',
      count: course.learnersProgress.inProgress,
      pct: course.learnersProgress.percentage,
      label: t.COURSES.DASHBOARD_TABLE.IN_PROGRESS(),
      color: primary,
    }, {
      key: uuid(),
      className: 'active',
      count: course.activeLearners.learnersActive,
      pct: course.activeLearners.percentage,
      label: t.COURSES.DASHBOARD_TABLE.ACTIVE_IN_7_DAYS(),
      color: yellow,
    },
  ];

  if (course.loginLearners.loggedIn === 0) {
    // Remove all panels but logins if no users have logged in yet
    panelsData.splice(1, 3);
  } else if (moment(course.closeDate) < moment()) {
    // Remove the in progress and active panels if the course has already been closed
    panelsData.splice(2, 2);
  }

  const courseTableColumns = createCourseTableColumns();
  const key = `${props.rowIndex}-${courseTableColumns.length + 1}`;

  return (
    <div key={key} css={styles} className='expanded-row' style={{ ...createGridStyles(1, props.rowIndex, courseTableColumns.length + 1), ...props.style }}>
      <div className='metric-panel-container'>
        {panelsData.map(pd => (
          <MetricPanel {...pd} />
        ))}
      </div>
    </div>
  );
};

export default CourseDetailsRow;
