import CollectionRoutes from '../components/collection-routes';

/* @ngInject */
export default function ContentLibraryCtrl(
  $scope,
  $uibModal,
  $controller,
  AlertMessages,
  ConfirmationOverlays,
  CourseModel,
) {
  $scope.$uibModal = $uibModal;
  $scope.$controller = $controller;
  $scope.AlertMessages = AlertMessages;
  $scope.CollectionRoutes = CollectionRoutes;
  $scope.ConfirmationOverlays = ConfirmationOverlays;
  $scope.CourseModel = CourseModel;
}
