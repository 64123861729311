import { css } from '@emotion/react';
import t from 'react-translate';

import NvIcon from 'shared/components/nv-icon';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/schemas';
import { getCourseAliases } from 'redux/selectors/course';
import { gray1, gray4, primary } from 'styles/global_defaults/colors';
import { halfSpacing, largeSpacing } from 'styles/global_defaults/scaffolding';

const NoBookmarks = () => {
  const styles = css`
    margin: 0 auto;
    margin-top: 120px;
    width: 423px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${gray1};

    .general-filter {
      .main {
        margin-bottom: ${largeSpacing}px;
      }

      .additional {
        display: flex;
        align-items: center;

        .icon-new-note {
          color: ${primary};
          margin-left: ${halfSpacing}px;
          margin-right: ${halfSpacing}px;
        }
      }
    }

    .icon-bookmark {
      color: ${gray4};
      margin-bottom: ${largeSpacing}px;
    }
  `;

  const { filter } = useSelector((state: RootState) => state.app.bookmarks);
  const { courseAliases } = useSelector((state) => getCourseAliases(state, filter || undefined));

  return (
    <div css={styles} className='no-bookmarks'>
      <NvIcon icon='bookmark' size='larger' />
      {filter ? (
        <div className='course-filter text-large-body'>
          {t.LHS.BOOKMARKS.NO_NOTES.COURSE(courseAliases)}
        </div>
      ) : (
        <div className='general-filter text-large-body'>
          <div className='main'>{t.LHS.BOOKMARKS.NO_NOTES.GENERAL()}</div>
          <div className='additional'>
            {t.LHS.BOOKMARKS.NO_NOTES.ADDITIONAL()}
            <NvIcon icon='new-note' size='smallest' />
          </div>
        </div>
      )}

    </div>
  );
};

export default NoBookmarks;
