/* eslint-disable no-console */
import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import MockAdapter from 'axios-mock-adapter';

export type MockDefinition = {
  type: MockTypes,
  url: string,
  status: number,
  response: any,
};

export enum MockTypes {
  PUT,
  GET,
  DELETE,
  POST,
  ALL,
  NETWORK_ERROR,
}
const setupMocks = (mocksdefinitions: MockDefinition[]) => {
  // This sets the mock adapter on the default instance
  const mock = getMockAdapter(1000);
  console.group('[MOCKS] Using mock data for axios');
  console.log(`(${mocksdefinitions.length} definitions)`);
  mocksdefinitions.forEach(mockdefinition => {
    // eslint-disable-next-line no-console
    console.log('adding mock', mockdefinition);
    switch (mockdefinition.type) {
      case MockTypes.PUT:
        mock.onPut(mockdefinition.url).reply(mockdefinition.status, mockdefinition.response);
        break;
      case MockTypes.GET:
        mock.onGet(mockdefinition.url).reply(mockdefinition.status, mockdefinition.response);
        break;
      case MockTypes.DELETE:
        mock.onDelete(mockdefinition.url).reply(mockdefinition.status, mockdefinition.response);
        break;
      case MockTypes.POST:
        mock.onPost(mockdefinition.url).reply(mockdefinition.status, mockdefinition.response);
        break;
      case MockTypes.ALL:
        mock.onAny(mockdefinition.url).reply(mockdefinition.status, mockdefinition.response);
        break;
      default:
        break;
    }
  });
  console.groupEnd();
};
export const getMockAdapter = (delayResponse) => new MockAdapter(axios, { onNoMatch: 'passthrough', delayResponse });
export default setupMocks;
