/* @ngInject */
export default function DateIsoFix($filter) {
  return {
    require: 'ngModel',
    priority: 1,
    link($scope, $element, $attrs, ngModelCtrl) {
      // view -> model
      function parser(value) {
        if (value) {
          return value.toISOString();
        }
        return null;
      }
      ngModelCtrl.$parsers.push(parser);

      // model -> view
      function formatter(value) {
        if (value) {
          return new Date(value);
        }
        return null;
      }
      ngModelCtrl.$formatters.push(formatter);
    },
  };
}
