/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { mapKeys } from 'lodash';

import {
  FetchCourseActivitiesParams,
  CourseActivitiesResponse,
  DeleteCommunicationParams,
  DeleteCommunicationResponse,
  FetchLecturePageCommunicationsParams,
  LecturePageCommunicationsResponse,
  FetchDraftCommunicationsParams,
  BaseCommunicationParams,
  FetchScheduledCommunicationsParams,
  ScheduledDueDatesResponse,
  ScheduledCommunicationsResponse,
  FetchScheduledDueDatesParams,
  HidePastScheduledCommunicationsParams,
  FetchFilteredCommunicationsParams,
  FilteredCommunicationsResponse,
  FetchAutomatedCommunicationsParams,
} from 'redux/schemas/api/course-communications';
import { Communication, CommunicationCategory, CommunicationType, FeedbackCategory, ItemState, OwnerType, ScheduledForType } from 'redux/schemas/models/course-communication';
import { getCategory, TriggerType } from 'communications/course_communications/contexts/communication-context';
import t from 'react-translate';

import { ComponentKeyPluralized } from 'redux/schemas/models/lecture-component';
import { LecturePageKey } from 'redux/schemas/models/lecture-page';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Result } from 'redux/schemas/api';
import { ActivityType } from 'redux/schemas/models/activity';
import { addAlertMessage } from './alert-messages';
import { AlertMessageType } from '../schemas/app/alert-message';

const getMessageKey = (params: BaseCommunicationParams) => {
  if (getCategory(params.communicationType) === CommunicationCategory.TRIGGERS) {
    return 'TRIGGERS';
  }
  if (getCategory(params.communicationType) === CommunicationCategory.SCHEDULED_ANNOUNCEMENT) {
    return 'ANNOUNCEMENT';
  }
  if (getCategory(params.communicationType) === CommunicationCategory.WELCOME_EMAIL) {
    return 'WELCOME_EMAIL';
  }
  return 'SCHEDULED';
};

const getTriggerType = (params: BaseCommunicationParams) => (params.triggerType === TriggerType.DUPLICATE ? 'DUPLICATE' : 'CREATE');

async function fetchCourseActivities(params: FetchCourseActivitiesParams) {
  const response = await axios.get<Result<CourseActivitiesResponse>>(`${params.catalogId}/activities_list.json`);
  return backendKeysToFrontendKeys(response.data.result);
}

export const getCourseActivities = createAsyncThunk(
  'LOAD_COURSE_ACTIVITES',
  fetchCourseActivities,
);

async function fetchLecturePageCommunications(params: FetchLecturePageCommunicationsParams) {
  return axios.get<Result<LecturePageCommunicationsResponse>>(`${params.catalogId}/lecture_pages/${params.lecturePageId}/outline_with_communications.json`).then(response => response.data.result);
}

export const getLecturePageCommunications = createAsyncThunk(
  'LOAD_LECTURE_PAGE_COMMUNICATIONS',
  fetchLecturePageCommunications,
);

async function fetchDraftCommunications(params: FetchDraftCommunicationsParams) {
  return axios.get<Result<Communication[]>>(`${params.catalogId}/course_communications/drafts.json`).then(response => response.data.result);
}

async function postCourseCommunication(params: BaseCommunicationParams, thunkAPI) {
  if (params.filters) {
    if (params.filters.hasCompleted) {
      if (params.filters.hasCompleted.type === 'VideoSkillsRatingFeedbackCriteria' as OwnerType) {
        params.filters.hasCompleted.type = ActivityType.VIDEO_PRACTICE_SKILLS_FEEDBACK;
        params.filters.hasCompleted.category = FeedbackCategory.VIDEO_PRACTICE_SKILLS_FEEDBACK;
      }
    }
    if (params.filters.hasNotCompleted) {
      if (params.filters.hasNotCompleted.type === 'VideoSkillsRatingFeedbackCriteria' as OwnerType) {
        params.filters.hasNotCompleted.type = ActivityType.VIDEO_PRACTICE_SKILLS_FEEDBACK;
        params.filters.hasNotCompleted.category = FeedbackCategory.VIDEO_PRACTICE_SKILLS_FEEDBACK;
      }
    }
  }
  return axios.post<Result<Communication>>(`${params.catalogId}/course_communications.json`, params).then(response => {
    /** only show alert message on create */
    if (params.communicationType === CommunicationType.COURSE_WELCOME_EMAIL) {
      // WelcomeEmail requests POST method while updating for the first time, when communicationId is null.
      // But the message displayed is a Update success
      const triggerKey = params.triggerType === TriggerType.CREATE ? 'CREATE' : 'UPDATE';
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.SUCCESS,
        header: t.FORM.SUCCESS_BANG(),
        message: t.COURSE_COMMUNICATIONS.ALERT_MESSAGES[triggerKey].WELCOME_EMAIL.SUCCESS(),
      }));
    } else if (params.submitted) {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.SUCCESS,
        header: t.FORM.SUCCESS_BANG(),
        message: params.scheduledFor === ScheduledForType.SEND_NOW
          ? t.COURSE_COMMUNICATIONS.ALERT_MESSAGES.SEND_NOW[getMessageKey(params)].SUCCESS()
          : t.COURSE_COMMUNICATIONS.ALERT_MESSAGES.CREATE[getMessageKey(params)].SUCCESS[getTriggerType(params)](),
      }));
    }

    if (params.callback) {
      params.callback(response.data.result);
    }
    return response.data.result;
  }).catch(error => {
    thunkAPI.dispatch(addAlertMessage({
      type: AlertMessageType.ERROR,
      header: t.FORM.ERROR(),
      message: params.scheduledFor === ScheduledForType.SEND_NOW
        ? t.COURSE_COMMUNICATIONS.ALERT_MESSAGES.SEND_NOW[getMessageKey(params)].ERROR()
        : t.COURSE_COMMUNICATIONS.ALERT_MESSAGES.CREATE[getMessageKey(params)].ERROR(),
    }));
  });
}

export const getDraftCommunications = createAsyncThunk(
  'LOAD_DRAFT_COMMUNICATIONS',
  fetchDraftCommunications,
);

export const createCommunication = createAsyncThunk(
  'CREATE_COMMUNICATION',
  postCourseCommunication,
);

export const updateCommunicationPartially = createAction<{ id: number, state: ItemState }>('UPDATE_COMUNICATION_PARTIALLY');

export const currentSendingNowCommunication = createAction<{ id: number, state: ItemState | 'Scheduled' }>('CURRENT_SENDING_NOW_COMMUNICATION');

export const updateCommunication = createAsyncThunk(
  'UPDATE_COMMUNICATION',
  async (params: any, thunkAPI) => axios.put<Result<Communication>>(`${params.catalogId}/course_communications/${params.communicationId}.json`, params).then(response => {
    if (params.communicationType === CommunicationType.COURSE_WELCOME_EMAIL
      && params.triggerType === TriggerType.CREATE) {
      // WelcomeEmail requests PUT method while creating after a disable, when communicationId is present.
      // So displaying a Create success here.
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.SUCCESS,
        header: t.FORM.SUCCESS_BANG(),
        message: t.COURSE_COMMUNICATIONS.ALERT_MESSAGES.CREATE.WELCOME_EMAIL.SUCCESS(),
      }));
    } else if (params.submitted) {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.SUCCESS,
        header: t.FORM.SUCCESS_BANG(),
        message: params.scheduledFor === ScheduledForType.SEND_NOW
          ? t.COURSE_COMMUNICATIONS.ALERT_MESSAGES.SEND_NOW[getMessageKey(params)].SUCCESS()
          : t.COURSE_COMMUNICATIONS.ALERT_MESSAGES.UPDATE[getMessageKey(params)].SUCCESS(),
      }));
    }
    if (params.callback) {
      params.callback(response.data.result);
    }
    return response.data.result;
  }).catch(error => {
    thunkAPI.dispatch(addAlertMessage({
      type: AlertMessageType.ERROR,
      header: t.FORM.ERROR(),
      message: params.scheduledFor === ScheduledForType.SEND_NOW
        ? t.COURSE_COMMUNICATIONS.ALERT_MESSAGES.SEND_NOW[getMessageKey(params)].ERROR()
        : t.COURSE_COMMUNICATIONS.ALERT_MESSAGES.UPDATE[getMessageKey(params)].ERROR(),
    }));
  }),
);

async function sendDeleteCommunication(params: DeleteCommunicationParams): Promise<DeleteCommunicationResponse> {
  const response = await axios.delete(`${params.catalogId}/course_communications/${params.communicationId}.json`);
  return response.data?.result;
}

export const deleteCommunication = createAsyncThunk(
  'DELETE_COMMUNICATION',
  sendDeleteCommunication,
);

async function fetchScheduledDueDates(params: FetchScheduledDueDatesParams) {
  return axios.post<Result<ScheduledDueDatesResponse>>(`${params.catalogId}/course_communications/scheduled_due_dates.json`)
    .then(response => backendKeysToFrontendKeys(response.data.result));
}

export const getScheduledDueDates = createAsyncThunk(
  'LOAD_SCHEDULED_DUE_DATES',
  fetchScheduledDueDates,
);

async function fetchFilteredCommunications(params: FetchFilteredCommunicationsParams) {
  return axios.post<Result<FilteredCommunicationsResponse>>(
    `${params.catalogId}/course_communications/filter_by.json?filter_by=${params.filterBy}&page=${params.page}`,
  ).then(response => response.data.result);
}

export const getFilteredCommunications = createAsyncThunk(
  'LOAD_FILTERED_COMMUNICATIONS',
  fetchFilteredCommunications,
);

export const resetFilteredCommunications = createAction(
  'RESET_FILTERED_COMMUNICATIONS',
);

async function fetchAutomatedCommunications(params: FetchAutomatedCommunicationsParams) {
  return axios.get<Result<ScheduledCommunicationsResponse>>(`${params.catalogId}/course_communications.json`, {
    params: {
      type: 'automated_journey_email',
      page: '1',
    },
  }).then(response => response.data.result);
}

export const getAutomatedCommunications = createAsyncThunk(
  'LOAD_AUTOMATED_COMMUNICATIONS',
  fetchAutomatedCommunications,
);

async function fetchScheduledCommunications(params: FetchScheduledCommunicationsParams) {
  return axios.post<Result<ScheduledCommunicationsResponse>>(
    `${params.catalogId}/course_communications/scheduled.json?start_time=${params.startTime}&display=${params.display}&page=${params.page}`,
  ).then(response => response.data.result);
}

export const getScheduledCommunications = createAsyncThunk(
  'LOAD_SCHEDULED_COMMUNICATIONS',
  fetchScheduledCommunications,
);

export const hidePastScheduledCommunications = createAction<HidePastScheduledCommunicationsParams>(
  'HIDE_PAST_SCHEDULED_COMMUNICATIONS',
);

export const resetScheduledCommunicationsTimeline = createAction(
  'RESET_SCHEDULED_COMMUNICATIONS',
);

export type HideLecturePageCommunicationsInAutomatedCommunicationsParams = {
  lecturePageId: number,
};

export const hideLecturePageCommunicationsInAutomatedCommunications = createAction<HideLecturePageCommunicationsInAutomatedCommunicationsParams>(
  'HIDE_LECTURE_PAGE_COMMUNICATIONS_IN_AUTOMATED_COMMUNICATIONS',
);

export const resetLecturePageExpandedInAutomatedCommunications = createAction(
  'RESET_LECTURE_PAGE_EXPANDED_IN_AUTOMATED_COMMUNICATIONS',
);

export type SetLecturePageExpandedInAutomatedCommunicationsParams = {
  id: number,
  isExpanded?: boolean,
  // if showCommunications is undefined, Won't overwrite existing ExpandedActivities State
  showCommunications?: boolean | undefined,
};

export const setLecturePageExpandedInAutomatedCommunications = createAction<SetLecturePageExpandedInAutomatedCommunicationsParams>(
  'SET_LECTURE_PAGE_EXPANDED_IN_AUTOMATED_COMMUNICATIONS',
);

export type SetActivityExpandedInAutomatedCommunicationsParams = {
  type: ComponentKeyPluralized | LecturePageKey,
  id: number,
  isExpanded: boolean,
};

export const setActivityExpandedInAutomatedCommunications = createAction<SetActivityExpandedInAutomatedCommunicationsParams>(
  'SET_ACTIVITY_EXPANDED_IN_AUTOMATED_COMMUNICATIONS',
);

export type SendCommunicationEmailParams = {
  catalogId: string,
  communication: Omit<Communication, 'id'>,
};

export const sendCommunicationEmail = createAsyncThunk(
  'SEND_COMMUNICATION_EMAIL',
  async (params: SendCommunicationEmailParams, thunkAPI): Promise<boolean> => {
    try {
      const response = await axios.post(`${params.catalogId}/course_communications/send_test_email.json`, params.communication);
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.SUCCESS,
        header: t.FORM.SUCCESS_BANG(),
        message: t.COURSE_COMMUNICATIONS.ALERT_MESSAGES.SEND_EMAIL.SUCCESS(),
      }));
      return response.data.result;
    } catch (error) {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR(),
      }));
    }

    return false;
  },
);

export const getCourseWelcomeEmail = createAsyncThunk(
  'GET_WELCOME_EMAIL',
  async (params: { catalogId: string }, thunkAPI) => {
    try {
      const response = await axios.get<Result<Communication>>(`${params.catalogId}/course_communications/welcome_email.json`);

      return {
        catalogId: params.catalogId,
        result: response.data.result,
      };
    } catch (e) {
      return thunkAPI.rejectWithValue(params.catalogId);
    }
  },
);

export const enableWelcomeEmail = createAsyncThunk(
  'ENABLE_WELCOME_EMAIL',
  async (params: { courseId: number, catalogId?: string }, thunkAPI) => {
    try {
      const response = await axios.get<Result<boolean>>(`courses/${params.courseId}/enable_welcome_email.json`);

      return {
        catalogId: params.catalogId,
        result: response.data?.result,
      };
    } catch (e) {
      thunkAPI.dispatch(addAlertMessage({
        header: t.FORM.ERROR(),
        type: AlertMessageType.ERROR,
        message: t.FORM.ERROR_SOMETHING_WRONG(),
      }));

      return e;
    }
  },
);

export const disableWelcomeEmail = createAsyncThunk(
  'DISABLE_WELCOME_EMAIL',
  async (params: { courseId: number, catalogId?: string }, thunkAPI) => {
    try {
      const response = await axios.get<Result<boolean>>(`courses/${params.courseId}/disable_welcome_email.json`);

      return {
        catalogId: params.catalogId,
        result: response.data.result,
      };
    } catch (e) {
      thunkAPI.dispatch(addAlertMessage({
        header: t.FORM.ERROR(),
        type: AlertMessageType.ERROR,
        message: t.FORM.ERROR_SOMETHING_WRONG(),
      }));

      return e;
    }
  },
);

// Renaming the keys to frontend nomenclature. Also unifying every keys
// as plurals
const backendKeysToFrontendKeys = (rawObject: CourseActivitiesResponse | ScheduledDueDatesResponse) => {
  const mapping = {
    exerciseSkillsRating: 'exerciseSkillsRatingActivities',
    topics: 'posts',
    customQuestions: 'peerEvaluations',
    teamSet: 'teamSets',
    groupTeamSet: 'groupTeamSets',
    liveSession: 'liveSessions',
    profileCompletions: 'profileRequirements',
    practiceFeedbackCriteria: 'practiceFeedbackActivities',
  };

  return mapKeys(rawObject, (value, key) => mapping[key] || key);
};
