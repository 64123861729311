import React from 'react';
import TeamFacilitationDashboard from '../components/team-facilitation-dashboard';

export default class TeamFacilitationDashboardReactController {
  /* @ngInject */
  constructor(
    $scope,
    $state,
    moment,
    ExercisesManager,
    CurrentCourseManager,
  ) {
    $state.params.initialState = false;

    ExercisesManager.getAllExercisesForCourse(CurrentCourseManager.rawCourseData.catalogId).then((exercises) => {
      const hasAssignments = !!exercises.filter((ex) => ex.isCourseLongTeam && (moment(ex.releaseDate) < moment())).length;

      $scope.TeamFacilitationComponent = (props) => React.createElement(
        TeamFacilitationDashboard,
        { ...props, hasAssignments },
      );
    });
  }
}
