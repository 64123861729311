import React from 'react';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';
import { Course, CourseRegistrationType } from 'redux/schemas/models/course';

type Props = {
  alreadyJoined: boolean,
  course: Course,
};

const publicEnrollment = (course: Course) => (
  course?.typeOfRegistration === CourseRegistrationType.FREE_ENROLLMENT
);

const NvHeaderText = (props: Props) => {
  const { alreadyJoined, course } = props;
  const { $state } = React.useContext(AngularServicesContext) || {};

  return (
    <>
      {alreadyJoined ? (
        <div className='authentications-header-text'>
          <div className='already-joined-text'>{t.USERS.REGISTRATION.ALREADY_JOINED()}</div>
          {(!course || !publicEnrollment(course)) && (
            <div className='authentications-subtle-text'>
              <span className='mr-1'>{t.USERS.REGISTRATION.SIGN_IN()}</span>
              {t.USERS.REGISTRATION.WITH_A_DIFFERENT_EMAIL()}
            </div>
          )}
          {course && publicEnrollment(course) && (
            <div className='authentications-subtle-text'>
              <span className='mr-1'>{t.USERS.REGISTRATION.SIGN_IN_HERE_OR()}</span>
              <a
                className='blue-link'
                href={$state.href('users.authentications.sign-up', $state.params)}
              >
                {t.USERS.REGISTRATION.SIGN_UP_LOWER()}
              </a>
              <span className='ml-1'>{t.USERS.REGISTRATION.WITH_A_DIFFERENT_EMAIL()}</span>
            </div>
          )}
        </div>
      ) : (
        <div className='authentications-header-text'>
          {t.USERS.REGISTRATION.SIGN_IN()}
        </div>
      )}
    </>
  );
};

export default NvHeaderText;
