import { css } from '@emotion/react';
import AngularLectureComponent from 'lecture_pages/components/content-area/angular-lecture-component';
import getDefaultAddMenuComponentData from 'lecture_pages/components/data/add-menu-defaults';
import React from 'react';
import { ComponentType } from 'redux/schemas/models/lecture-component';
import { boldFontWeight, textSmallLineHeight } from 'styles/global_defaults/fonts';
import { doubleSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';

type TextWithImageComponentType = ComponentType.TEXT_WITH_IMAGE_BKG | ComponentType.TEXT_WITH_IMAGE_SIDE | ComponentType.TEXT_WITH_IMAGE_TOP;

const TextWithImageContentPreview = (props: {
  type: TextWithImageComponentType,
  /** This property is a hack required b/c AngularLectureComponent needs an ID defined to function correctly even
   * though these preview components are not "real" components saved in the backend, and thus have no ID.
   * Any value distinct among the components shown in the current Accordion view should be safe to use here. */
  index: number
}) => (
  <div css={css`
    nv-content-template {
      .content-template {
        &.blurb {
          .main-content {
            font-size: 9px;
            line-height: ${textSmallLineHeight}px;
            font-weight: ${boldFontWeight};
          }

          &.side-image {
            .side {
              align-self: center;
            }

            .main {
              margin: 0 ${halfSpacing}px;
            }
          }

          &.top-image {
            padding: ${halfSpacing}px ${doubleSpacing}px;
          }
        }
      }
    }
  `}
  >
    <AngularLectureComponent
      lectureComponent={{ ...getDefaultAddMenuComponentData(props.type), id: props.index, index: props.index }}
      forceViewMode
      useStaticData
    />
  </div>
);

export default TextWithImageContentPreview;
