/* @ngInject */
export default function CreateReportsController(
  $scope,
  $stateParams,
  $uibModalInstance,
  AlertMessages,
  ConfirmationOverlays,
  CurrentCourseManager,
  CurrentUserManager,
  CustomDownloadsManager,
  CustomDownloadsModel,
  MigratedFields,
  StateManager,
  _,
  nvUtil,
  reportId,
  reportPreviousStatus,
) {
  const vm = this;
  const orgProfileDetailsRegex = /^orgProfileDetails-\d+$/;

  vm.reportId = reportId;
  vm.reportPreviousStatus = reportPreviousStatus;

  vm.CustomDownloadsManager = CustomDownloadsManager;
  vm.$stateParams = $stateParams;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.nvUtil = nvUtil;
  vm.reportForm = null;
  vm.titleForm = null;
  vm.deregisterStateChangeStart = null;
  vm.unsavedChangesMessage = 'FORM.NAVIGATE_AWAY.CLOSE_WINDOW';
  vm.confirmingClose = false;
  vm.submitting = false;
  vm.submittingSuccessful = false;
  vm.switchingRole = false;

  // Default options
  vm.selectedTab = 'DefineWho';
  vm.selectedRole = 'learner';

  vm.selectTab = function (tab) {
    vm.selectedTab = tab;
  };

  vm.selectRole = function (role) {
    vm.switchingRole = true; // So dropdowns are initialized properly on switching tabs
    vm.selectedRole = role;
    vm.data.recordCount = null;
    getDefaultRole();
  };

  initialize();

  function initialize() {
    if (!vm.reportId) {
      getDefaultRole(); // New report
    } else {
      getExistingReport();
    }
  }

  // count selected to show other dropdowns
  vm.countSelected = function (obj) {
    const sorted = _.countBy(obj, (value, key) => (value === true ? 'selected' : 'not selected'));

    return sorted.selected;
  };

  // Aleast one checkbox among all the dropdowns has to be selected to proceed
  vm.isStepTwoValid = function () {
    if (vm.selectedRole === 'team' || vm.selectedRole === 'group') {
      return hasDropdownValue(vm.data.teamDetails) || hasDropdownValue(vm.data.activityData);
    }
    // It is required to have at least the value for the accountData. Other dropdowns may or may not have any value.
    return hasDropdownValue(vm.data.accountData);
  };

  function hasDropdownValue(obj) {
    return !vm.isEmpty(obj) && obj.selectedCount;
  }

  function flattenDropdownOptions(options, dataTransformer, suffixKey) {
    dataTransformer = dataTransformer || (() => {});

    return Object.keys(options).reduce((acc, key) => {
      const currentValue = options[key];
      const isCurrentValueArray = Array.isArray(currentValue);
      const isCurrentValueBoolean = typeof currentValue === 'boolean';

      if (isCurrentValueArray || isCurrentValueBoolean) {
        const keyToAssign = suffixKey ? `${suffixKey}-${key}` : key;
        const data = dataTransformer(keyToAssign, currentValue) || currentValue;

        acc[keyToAssign] = data;

        return acc;
      }

      return { ...acc, ...flattenDropdownOptions(currentValue, dataTransformer, key) };
    }, {});
  }

  function deflattenDropdownOptions(options, dataTransformer) {
    dataTransformer = dataTransformer || (() => {});

    return Object.keys(options).reduce((acc, key) => {
      const currentValue = options[key];

      if (/^\w+-\d+$/.test(key)) {
        const [propertyName, id] = key.split('-');

        const transformedData = dataTransformer(propertyName, currentValue);

        const valueToAssign = transformedData === undefined ? currentValue : transformedData;

        if (_.has(acc, propertyName)) {
          acc[propertyName][id] = valueToAssign;
        } else {
          acc[propertyName] = {
            [id]: valueToAssign,
          };
        }

        return acc;
      }

      if (typeof currentValue === 'boolean') {
        acc[key] = currentValue;
      }

      return acc;
    }, {});
  }

  function flattenOrgProfileDetails() {
    const { orgLevel } = CurrentCourseManager.course.institution.profileSettings;

    if (vm.data.profileDetails) {
      vm.data.profileDetails = flattenDropdownOptions(
        vm.data.profileDetails,
        (key, value) => {
          if (orgProfileDetailsRegex.test(key)) {
            let [, id] = key.split('-');
            id = Number(id);

            const orgField = orgLevel.find((orgLevelField) => orgLevelField.id === id);

            const { name, migratedField } = orgField;

            const displayName = (migratedField && !name) ? MigratedFields.getDisplayName(migratedField) : name;

            // Dropdown fields are retrieved as an array
            if (Array.isArray(value)) {
              const [, dropdownOptionsData, isFiltered] = value;

              vm.data.profileQuestions[key] = [
                displayName,
                dropdownOptionsData,
              ];

              return {
                id, // We need the id only for dropdown types
                value: isFiltered,
                label: displayName,
              };
            }

            return {
              value,
              label: displayName,
            };
          }
          return null;
        },
      );
    }
  }

  function getDefaultRole() {
    const existingTitle = vm.data ? vm.data.title : null;
    return CustomDownloadsModel.getNewReport($stateParams.catalogId, vm.selectedRole).then((data) => {
      vm.data = data;
      vm.data.reportType = 'download';
      vm.data.courseId = vm.CurrentCourseManager.course.id;
      vm.data.title = vm.data.title || existingTitle;
      vm.switchingRole = false;

      flattenOrgProfileDetails();
    });
  }

  function getExistingReport() {
    return CustomDownloadsModel.getExistingReport($stateParams.catalogId, vm.reportId).then((data) => {
      vm.data = data;
      vm.selectedRole = vm.data.userRole;

      flattenOrgProfileDetails();
    });
  }

  vm.isEmpty = function (obj) {
    return _.isEmpty(obj);
  };

  vm.updateRecordCount = function () {
    if (vm.reportForm.$dirty) {
      const payload = getPayload();

      CustomDownloadsModel.getCount($stateParams.catalogId, payload).then((response) => {
        vm.data.recordCount = response.recordCount;
      });
    }
  };

  vm.updateCustomReport = function () {
    // If only title was updated, don't show confirmation overlay
    if (vm.reportForm.$dirty) {
      const modalInstance = ConfirmationOverlays.openConfirmationModal('custom_downloads/templates/modal-update-report-confirmation.html');

      modalInstance.result.then((action) => {
        if (action === 'update') {
          vm.submitCustomReport();
        } else if (action === 'new') {
          vm.submitCustomReport('new');
        }
      });
    } else {
      vm.submitCustomReport(null, true);
    }
  };


  vm.submitCustomReport = function (action, preserveOlderReports) {
    const payload = getPayload();

    vm.submitting = true;

    payload.customReports.preserveOlderReports = !!preserveOlderReports;

    if (!vm.reportId || action === 'new') {
      CustomDownloadsModel.createCustomReport($stateParams.catalogId, vm.selectedRole, payload).then(() => {
        setSubmissionSuccessful();
        $uibModalInstance.close({ type: 'new' });
        AlertMessages.success('CUSTOM_DOWNLOADS.CREATE_REPORT.DATA_DOWNLOAD_SUCCESS_TITLE', 'CUSTOM_DOWNLOADS.CREATE_REPORT.SUCCESS_MESSAGE', {}, {}, 20000);
      });
    } else {
      CustomDownloadsModel.updateCustomReport($stateParams.catalogId, vm.reportId, payload).then(() => {
        setSubmissionSuccessful();
        $uibModalInstance.close({ type: 'update', preserveOlderReports: !!preserveOlderReports });
      });
    }
  };

  function setSubmissionSuccessful() {
    vm.reportForm.$setPristine();
    vm.titleForm.$setPristine();
    vm.submittingSuccessful = true;
    vm.submitting = false;
  }

  function getPayload() {
    vm.data.userRole = vm.selectedRole;
    vm.payloadData = angular.copy(vm.data);

    if (vm.payloadData.profileDetails) {
      vm.payloadData.profileDetails = deflattenDropdownOptions(
        vm.payloadData.profileDetails,
        (key, value) => {
          if (key === 'orgProfileDetails') {
            const { id, value: checkboxValue } = value;

            if (id) {
              const profileQuestionsKey = Object.keys(vm.payloadData.profileQuestions).find((questionKey) => {
                if (orgProfileDetailsRegex.test(questionKey)) {
                  const [, questionKeyIdSuffix] = questionKey.split('-');

                  return id === Number(questionKeyIdSuffix);
                }

                return false;
              });

              return [...vm.payloadData.profileQuestions[profileQuestionsKey], checkboxValue];
            }

            return checkboxValue;
          }

          return undefined;
        },
      );
    }

    // for Profile Questions, iterate over options to set all in the right place
    if (vm.payloadData.profileQuestions) {
      _.each(vm.payloadData.profileQuestions, (value, key) => {
        if (vm.payloadData.profileQuestions[key][1].all) {
          vm.payloadData.profileQuestions[key][1] = { all: ['all', true] }; // be is expecting this format
        } else {
          delete vm.payloadData.profileQuestions[key][1].all; // be only looking for all key, not for true or false
        }
      });
    }

    // For teamMemberDetails, if includeTeamMembers is false, set member name and email to false too
    if (vm.payloadData.teamDetails) {
      if ((vm.selectedRole === 'team' && !vm.payloadData.teamDetails.includeTeamMembers)
            || (vm.selectedRole === 'group' && !vm.payloadData.teamDetails.includeGroupMembers)) {
        if (vm.selectedRole === 'team') {
          vm.payloadData.teamMemberDetails.teamMemberName = false;
        } else {
          vm.payloadData.teamMemberDetails.groupMemberName = false;
        }

        vm.payloadData.teamMemberDetails.userEmail = false;
      }
    }

    // Go over objects and if all:true, remove rest of options
    Object.keys(vm.payloadData).forEach((obj) => {
      const has = Object.prototype.hasOwnProperty;
      if (vm.payloadData[obj] && obj !== 'teamTypes' && has.call(vm.payloadData[obj], 'all')) {
        const [firstKey] = Object.keys(vm.payloadData[obj]);

        // If nested object like activities, delete all keyword
        if (_.isObject(vm.payloadData[obj][firstKey])) {
          delete vm.payloadData[obj].all;
        }
      }
    });

    return { customReports: vm.payloadData, id: vm.data.id };
  }


  // warn if leaving the modal and have unsaved changes
  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(
    () => (vm.reportForm?.$dirty) || (vm.titleForm?.$dirty),
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    vm.unsavedChangesMessage,
    undefined,
    undefined,
    () => {
      vm.deregisterModalClosing?.();

      vm.confirmingClose = true;
      vm.submitting = false;

      $uibModalInstance.dismiss();
    },
  );

  vm.deregisterModalClosing = $scope.$on('modal.closing', ($event) => {
    if (((vm.reportForm?.$dirty) || (vm.titleForm?.$dirty))
          && !vm.confirmingClose
          && !vm.submitting && !vm.submittingSuccessful) {
      $event.preventDefault();


      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        vm.confirmingClose = true;
        vm.submitting = false;

        $uibModalInstance.dismiss();
      });
    }
  });

  $scope.$on('$destroy', () => {
    vm.deregisterStateChangeStart?.();
  });
}
