import { css } from '@emotion/react';
import { kebabCase } from 'lodash';
import { warning } from 'styles/global_defaults/colors';
import {
  boldFontWeight,
  textSmallFontSize,
  textSmallLineHeight,
} from 'styles/global_defaults/fonts';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import NvIcon from '../nv-icon';

export type CategoryHeaderProps = {
  icon?: string;
  title?: string;
};

export const NvCategoryHeader = ({ icon, title }: CategoryHeaderProps) => {
  const styles = css`
    width: fit-content;
    color: ${warning};
    display: flex;
    flex-direction: column;
    gap: ${halfSpacing}px;

    .icon {
      align-self: center;
      color: ${warning} !important;
    }

    .category-header-title {
      width: max-content;
      font-size: ${textSmallFontSize}px;
      font-weight: ${boldFontWeight};
      line-height: ${textSmallLineHeight}px;
      font-family: 'Open Sans Condensed';
    }
  `;

  return (
    <div css={styles}>
      <NvIcon icon={icon} size='medium' />
      <span
        className='category-header-title'
        data-qa={kebabCase(`menu-header-${title}`)}
      >
        {title.toUpperCase()}
      </span>
    </div>
  );
};
