import React, { useContext } from 'react';
import t from 'react-translate';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { AngularContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import AdvancedSettingsSwitch from 'institutions/components/advanced-settings-switch';
import { config } from '../../../../../config/pendo.config.json';
import SectionContent from '../section-content';

const AppPromotion = () => {
  const { injectServices } = useContext(AngularContext);
  const [InstitutionsManager] = injectServices(['InstitutionsManager']);
  const dispatch = useAppDispatch();

  const switchAppPromotion = (value) => {
    InstitutionsManager.institution.updateFromReact({
      isAppPromotionEnabled: value,
    });

    const failureCallback = () => {
      dispatch(addAlertMessage({ type: AlertMessageType.ERROR, header: t.FORM.OOPS(), message: t.FORM.ERROR_SOMETHING_WRONG() }));
      InstitutionsManager.institution.updateFromReact({
        isAppPromotionEnabled: !value,
      });
    };

    const toggleAppPromotion = () => {
      InstitutionsManager.toggleAppPromotion(value)
        .then((success) => {
          if (!success) failureCallback();
        }, () => {
          failureCallback();
        });
    };

    if (!value) {
      dispatch(openConfirmationDialog({
        onConfirm: () => toggleAppPromotion(),
        cancelText: t.FORM.CANCEL(),
        confirmText: t.FORM.YES_SURE(),
        title: t.INSTITUTIONS.ADVANCED_SETTINGS.APP_PROMOTION.DISABLE(),
        bodyText: t.INSTITUTIONS.ADVANCED_SETTINGS.APP_PROMOTION.DISABLE_INFO(),
        confirmDataQa: 'confirm-disable-app-promotion',
        cancelDataQa: 'cancel-disable-app-promotion',
        onCancel: () => InstitutionsManager.institution.updateFromReact({
          isAppPromotionEnabled: !value,
        }),
      }));
    } else {
      toggleAppPromotion();
    }
  };
  return (
    <SectionContent
      header={t.INSTITUTIONS.ADVANCED_SETTINGS.APP_PROMOTION.TITLE()}
      description={[
        t.INSTITUTIONS.ADVANCED_SETTINGS.APP_PROMOTION.DESCRIPTION_1(),
        t.INSTITUTIONS.ADVANCED_SETTINGS.APP_PROMOTION.DESCRIPTION_2(),
        t.INSTITUTIONS.ADVANCED_SETTINGS.APP_PROMOTION.DESCRIPTION_3(),
      ]}
      extras={(
        <div className='d-flex align-self-start'>
          <AdvancedSettingsSwitch
            onChange={switchAppPromotion}
            dataQa='toggle-promote-native-mobile-apps'
            pendoTagName={config.pendo.settings.appPromotion}
            value='vm.InstitutionsManager.institution.isAppPromotionEnabled'
          />
        </div>
      )}
    />
  );
};

export default AppPromotion;
