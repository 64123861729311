import { flatten, keys, map } from 'underscore';
import { css } from '@emotion/react';
import { ChangeEvent, useCallback, useState, Fragment } from 'react';
import * as yup from 'yup';

import { Button, ProgressBar } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

import t from 'react-translate';
import validationConstants from 'shared/services/constants-shared';
import NvTextInput from 'shared/components/inputs/nv-text-input';

import { S3NameSpaces } from 'shared/services/s3-upload-factory';
import { UploadType, RteEmbedding, defaultEmbeddingWidth, defaultEmbeddingHeight } from 'froala/helpers/nv-froala-constants';
import useUploadFile from 'shared/hooks/use-upload-file';
import { createEmbeddingFromS3FileId, createEmbeddingFromUrl, validateFiles } from 'froala/helpers/nv-froala-functions';

import { largeSpacing, threeQuartersSpacing, standardSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { gray4, gray3, black } from 'styles/global_defaults/colors';

import { textSmallFontSize, textSmallLineHeight } from 'styles/global_defaults/fonts';
import { config } from '../../../config/config.json';


type FroalaUploadModalProps = {
  uploadType: UploadType,
  closeModal(rteEmbeddings?: RteEmbedding[], isNovoEdEmbedding?: boolean): void,
  fileExtensions?: string[],
};

interface FormData {
  publicLink: string,
}

const styles = css`
  .upload-file {
    font-size: ${textSmallFontSize}px;
    line-height: ${textSmallLineHeight}px;
    text-align: center;
    color: ${gray3};
    padding: ${threeQuartersSpacing}px;
    border: 1px dashed ${gray4};
    cursor: pointer;
  }
  .or {
    text-align: center;
    color: ${black};
    margin-top: ${largeSpacing}px;
    margin-bottom: ${threeQuartersSpacing}px;
  }
  .url-input {
    .button-row {
      margin: ${largeSpacing}px 0 ${standardSpacing}px 0;
      button+button {
        margin-left: ${halfSpacing}px;
      }
    }
  }
`;

const overlayStyles = css`
  z-index: 7060;
`;

export const FroalaUploadModal = ({
  uploadType,
  closeModal,
  fileExtensions,
}: FroalaUploadModalProps) => {
  /* Upload Portion */
  const [fileError, setFileError] = useState('');
  const { uploadFiles, isUploading, hasUploadPercentage, filesUploading } = useUploadFile();

  const onDrop = useCallback(async (files: File[]) => {
    /* Validate Files */
    const acceptTypes = uploadType === UploadType.IMAGE_ONLY
      ? config.files.rte.images.mimeTypes.concat(config.files.rte.images.extensions)
      : flatten(map(config.files.rte, (type) => type.mimeTypes.concat(type.extensions)));
    setFileError('');
    const firstError = validateFiles(files, acceptTypes);

    if (firstError) {
      setFileError(firstError);
    } else {
      const novoEdFiles = await uploadFiles(files, S3NameSpaces.ATTACHMENTS);
      const rteEmbeddingsPromises = novoEdFiles.map((novoEdFile) => createEmbeddingFromS3FileId(novoEdFile, defaultEmbeddingWidth, defaultEmbeddingHeight));

      Promise.all(rteEmbeddingsPromises).then((rteEmbeddings: RteEmbedding[]) => {
        closeModal(rteEmbeddings, true);
      });
    }
  }, [uploadType, uploadFiles, closeModal]);

  const dropzoneConfig: DropzoneOptions = {
    // maxSize: config.files.rteFileSizeLimit * 1024 * 1024, // use a utility someday - NvUtil is AngularJs only
    onDrop,
  };
  if (fileExtensions && uploadType === UploadType.IMAGE_ONLY) {
    dropzoneConfig.accept = fileExtensions;
  }
  const { getRootProps, getInputProps } = useDropzone(dropzoneConfig);

  const validationSchema = yup.object().shape({
    publicLink: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .matches(validationConstants.URL_REGEX, {
        message: t.VALIDATION.URL(),
      }),
  });


  /* Form Portion */
  const methods = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      publicLink: '',
    },
  });
  const { handleSubmit, formState } = methods;

  const onPublicLinkSubmit = (e: any): void => {
    e.preventDefault();
    handleSubmit(onSubmit)();
    e.stopPropagation();
  };

  const onSubmit = (data: FormData): void => {
    createEmbeddingFromUrl(data.publicLink, defaultEmbeddingWidth, defaultEmbeddingHeight)
      .then((rteEmbedding) => closeModal([rteEmbedding], false));
  };

  return (
    <div css={styles}>
      {isUploading ? (
        Object.keys(filesUploading).map((key) => {
          const fileUploading = filesUploading[key];

          return (
            <div key={key} className='mb-4'>
              <div>{fileUploading.file.name}</div>
              <ProgressBar animated={!hasUploadPercentage} now={hasUploadPercentage ? fileUploading.uploadPercentage : 100} />
            </div>
          );
        })
      ) : (
        <Fragment>
          <div {...getRootProps({ className: 'upload-file' })}>
            {/* Input Area */}
            <input {...getInputProps()} />
            <i className='icon icon-upload icon-medium' />
            {uploadType === UploadType.IMAGE_ONLY ? t.FROALA.UPLOAD_MEDIA.IMAGE_ONLY_DESCRIPTION() : t.FROALA.UPLOAD_MEDIA.ALL_DESCRIPTION()}

          </div>
          {fileError && <div className='warning'>{fileError}</div>}
        </Fragment>
      )}

      {uploadType === UploadType.ALL && (
      <Fragment>
        <div className='or'>{t.FILE_UPLOAD.OR()}</div>

        <FormProvider {...methods}>
          <form className='url-input' onSubmit={onPublicLinkSubmit}>
            <label htmlFor='publicLink'>{t.FROALA.UPLOAD_MEDIA.LABEL()}</label>
            <div className='bs4-row'>
              <NvTextInput
                withForm
                name='publicLink'
                ariaLabel='Public Link for Embedding'
                className='bs4-col'
                placeholder={t.FROALA.UPLOAD_MEDIA.PLACEHOLDER()}
                required
                disabled={isUploading}
                overlayStyles={overlayStyles}
              />
            </div>

            <div className='button-row bs4-row'>
              <div className='mx-auto'>
                <Button className='px-2' variant='secondary' onClick={() => closeModal()}>{t.FORM.CANCEL()}</Button>
                <Button disabled={!formState.isValid || keys(formState.errors).length > 0 || isUploading} type='submit' variant='primary'>{t.FILE_UPLOAD.ADD_MEDIA()}</Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </Fragment>
      )}
    </div>
  );
};

export default FroalaUploadModal;
