import React, { useContext, useEffect, useState } from 'react';
import { AngularContext, AngularServicesContext } from 'react-app';
import t from 'react-translate';
import { Button } from 'react-bootstrap';
import { DisableProps } from './integrations';
import SectionContent from '../section-content';
import { config } from '../../../../../config/pendo.config.json';

const providerNames = {
  saba: 'INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.MODAL.PROVIDER_TYPE.SABA',
  sum_total: 'INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.MODAL.PROVIDER_TYPE.SUMTOTAL',
  csod: 'INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.MODAL.PROVIDER_TYPE.CSOD',
};
export const providerTypes = {
  SABA: 'saba',
  SUMTOTAL: 'sum_total',
  CSOD: 'csod',
};

const LmsIntegration = (props: { onDisable: (props: DisableProps) => void }) => {
  const { $uibModal } = useContext(AngularServicesContext);
  const { injectServices, $scope } = useContext(AngularContext);
  const [InstitutionsManager] = injectServices(['InstitutionsManager']);
  const [lmsEnabled, setLmsEnabled] = useState($scope.$eval('vm.InstitutionsManager.institution.lmsEnabled'));
  const [lmsConnectionSettings, setLmsConnectionSettings] = useState($scope.$eval('vm.InstitutionsManager.institution.lmsConnectionSettings'));

  useEffect(() => {
    $scope.$watch('vm.InstitutionsManager.institution.lmsEnabled', (newValue) => {
      setLmsEnabled(newValue);
    });
  }, [$scope]);

  useEffect(() => {
    $scope.$watch('vm.InstitutionsManager.institution.lmsConnectionSettings', (newValue) => {
      setLmsConnectionSettings(newValue);
    });
  }, [$scope]);

  const showConfigureBtn = () => {
    const noSabaConnections = lmsConnectionSettings && !lmsConnectionSettings.sabaDomainName;
    const sumTotalConnections = lmsConnectionSettings?.lms === providerTypes.SUMTOTAL;
    const csodConnections = lmsConnectionSettings?.lms === providerTypes.CSOD;
    return !lmsConnectionSettings || (noSabaConnections && !sumTotalConnections && !csodConnections);
  };

  const getProviderName = () => {
    const providerType = lmsConnectionSettings
      ? lmsConnectionSettings.lms : '';
    return providerType === providerTypes.SABA
      ? lmsConnectionSettings.lms.toUpperCase()
      : t[(providerNames[providerType])];
  };

  const showSumTotalSection = () => lmsConnectionSettings?.lms === providerTypes.SUMTOTAL;

  const showCornerstoneSection = () => lmsConnectionSettings?.lms === providerTypes.CSOD;


  const configureLms = () => {
    $uibModal.open({
      templateUrl: 'institutions/templates/configure-lms-modal.html',
      controller: 'ConfigureLmsModalCtrl as vm',
    });
  };

  const disableLms = () => {
    props.onDisable({
      action: () => InstitutionsManager.disableLmsIntegration(),
      onSuccess: () => InstitutionsManager.institution.updateFromReact({
        lmsConnectionSettings: null,
      }),
      successMessage: t.INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.LMS_DISABLED(),
      title: t.DASHBOARD.CONFIRMATION.ARE_YOU_SURE(),
      bodyText: t.INSTITUTIONS.ADVANCED_SETTINGS.DISABLE_LMS_DESCRIPTION(getProviderName()),
      confirmDataQa: config.pendo.settings.lms.disableConfirm,
      cancelDataQa: config.pendo.settings.lms.disableCancel,
    });
  };

  const description = [];
  let lmsDescription: string | string[];
  let ctaText = showConfigureBtn() ? t.INSTITUTIONS.ADVANCED_SETTINGS.CONFIGURE() : null;
  let showSaba = false;

  if (showConfigureBtn()) {
    if (lmsEnabled) {
      description.push(t.INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.SECTION_DESCRIPTION_ENABLED());
    } else {
      description.push(t.INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.SECTION_DESCRIPTION());
    }
  }
  if (lmsConnectionSettings?.sabaDomainName) {
    lmsDescription = t.INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.ENABLED_SECTION[`${lmsConnectionSettings.lms.toUpperCase()}_DESCRIPTION`]();
    ctaText = t.INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.DISABLE_CTA();
    showSaba = true;
  }
  if (showSumTotalSection()) {
    lmsDescription = [t.INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.ENABLED_SECTION.SUMTOTAL_DESCRIPTION()];
    ctaText = t.INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.DISABLE_CTA_SUM_TOTAL();
  }
  if (showCornerstoneSection()) {
    lmsDescription = [t.INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.ENABLED_SECTION.CSOD_DESCRIPTION()];
    ctaText = t.INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.DISABLE_CTA_CSOD();
  }

  return (
    <SectionContent
      header={t.INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.SECTION_TITLE()}
      description={showConfigureBtn() ? description : lmsDescription as []}
      showCTAButton={!!ctaText && !showSaba}
      ctaText={ctaText}
      onAction={() => (showConfigureBtn() ? configureLms() : disableLms())}
      disabled={showConfigureBtn() ? !lmsEnabled : false}
      buttonVariant={showConfigureBtn() ? 'primary' : 'secondary'}
      dataQa={config.pendo.settings.lms.cta}
      extras={showSaba && (
        <React.Fragment>
          {lmsConnectionSettings?.sabaDomainName && (
            <div className='d-flex flex-column'>
              <div className='text-body mb-2'>
                {lmsDescription}
              </div>
              <div className='text-body'>
                <b>{t.INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.ENABLED_SECTION.API_ENDPOINT()}</b>
                <span>{lmsConnectionSettings?.clientEndpoint}</span>
              </div>
              <div className='text-body'>
                <b>{t.INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.ENABLED_SECTION.DOMAIN()}</b>
                <span>{lmsConnectionSettings?.sabaDomainName}</span>
              </div>
              <div className='text-body mb-2'>
                <b>{t.INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.ENABLED_SECTION.COURSE_NUMBER_CONFIGURATION()}</b>
                {lmsConnectionSettings?.lms === providerTypes.SABA && (
                  <span>
                    {lmsConnectionSettings?.sabaAutoAssignNumbers
                      ? t.INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.MODAL.SABA.STEP_2.ENABLE_AUTO_ASSIGN()
                      : t.INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.MODAL.SABA.STEP_2.DISABLE_AUTO_ASSIGN()}
                  </span>
                )}
              </div>
              <Button
                variant='secondary'
                onClick={disableLms}
                className='mt-6 align-self-center'
                data-qa={config.pendo.settings.lms.disable}
              >
                {ctaText}
              </Button>
            </div>
          )}
        </React.Fragment>
      )}
    />
  );
};

export default LmsIntegration;
