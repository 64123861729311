/* @ngInject */
export default function TeamsResources(

  $resource,
) {
  return $resource(
    '/:catalogId/teams/:id.json',
    {},
    {
      get: { method: 'GET', url: '/:catalogId/teams/:id' },
      getTeamForProfile: { method: 'GET', url: '/:catalogId/teams/:id/profile_details' },
      getMember: { method: 'GET', url: '/:catalogId/team_members/:teamMemberId' },
      getMemberByUserId: { method: 'GET', url: '/:catalogId/team_members/:teamId/:userId' },
      query: { method: 'GET', url: '/:catalogId/teams' },
      create: { method: 'POST', url: '/:catalogId/teams' },
      update: { method: 'PUT', url: '/:catalogId/teams/:teamId' },
      searchFilters: { method: 'GET', url: '/:catalogId/teams/filters' },
      search: { method: 'POST', url: '/:catalogId/teams/search.json', cancellable: true },
      addMembers: { method: 'POST', url: '/:catalogId/teams/create_approved_members/:teamId' },
      adminSendMessageToTeam: { method: 'POST', url: '/:catalogId/teams/:id/admin_send_message' },
      enableProgressDashboard: { method: 'GET', url: '/:catalogId/teams/:id/enable_progress_dashboard' },
      disableProgressDashboard: { method: 'GET', url: '/:catalogId/teams/:id/disable_progress_dashboard' },

      /* workspace related */
      getTodos: { method: 'GET', url: '/:catalogId/teams/:teamId/todos' },
      getDrafts: { method: 'GET', url: '/:catalogId/teams/:teamId/active_drafts' },
      getSubmissions: { method: 'GET', url: '/:catalogId/teams/:teamId/all_submissions' },
      getWorkspacePosts: { method: 'GET', url: '/:catalogId/teams/:teamId/topics', cancellable: true },
      getSingleWorkspacePost: { method: 'GET', url: '/:catalogId/teams/:teamId/topics/:postId ' },
      getWorkspaceFilterCounts: { method: 'GET', url: '/:catalogId/teams/:teamId/topics_summary' },
      getDiscussionContributions: { method: 'GET', url: '/:catalogId/teams/:teamId/team_members/:teamMemberId/workspace_contributions.json' },

      createTextPost: { method: 'POST', url: '/:catalogId/teams/:teamId/topics' },
      updateWorkspacePost: { method: 'PUT', url: '/:catalogId/topics/:postId' },
      deleteWorkspacePost: { method: 'DELETE', url: '/:catalogId/topics/:postId' },

      createMeetingPost: { method: 'POST', url: '/:catalogId/teams/:teamId/meetings' },
      updateMeetingPost: { method: 'PUT', url: '/:catalogId/teams/:teamId/meetings/:meetingId' },
      createMeetingRsvp: { method: 'POST', url: '/:catalogId/teams/:teamId/meetings/:meetingId/meeting_attendances' },
      updateMeetingRsvp: { method: 'PUT', url: '/:catalogId/teams/:teamId/meetings/:meetingId/meeting_attendances/:attendanceId' },

      uploadWorkspaceDocument: { method: 'POST', url: '/:catalogId/attachments' },
      deleteWorkspaceDocument: { method: 'DELETE', url: '/:catalogId/attachments/:fileId' },
      updateWorkspaceDocument: { method: 'PUT', url: '/:catalogId/attachments/:fileId' },
      uploadWorkspaceDocumentFromGoogleDrive: { method: 'POST', url: '/:catalogId/teams/:teamId/external_documents' },
      updateWorkspaceDocumentFromGoogleDrive: { method: 'PUT', url: '/:catalogId/teams/:teamId/external_documents' },

      createSubmissionPost: { method: 'POST', url: '/:catalogId/teams/:teamId/topics' },
      updateSubmissionPost: { method: 'PUT', url: '/:catalogId/teams/:teamId/topics/:postId' },
      createBulkSubmissionPost: { method: 'POST', url: '/:catalogId/topics/bulk' },
    },
  );
}
