/* @ngInject */
export default function NvClickToEdit(
  $timeout,
  $document,
  $translate,
) {
  return {
    restrict: 'A',
    scope: {
      name: '=',
      ngModel: '=',
      question: '=',
      editInfo: '=',
      inputType: '=',
      centerText: '=',
      replaceQuestion: '=',

      form: '=?',
      picture: '=?',
      isHidden: '=?',
      disabled: '=?',
      readonly: '=?',
      required: '=?',
      maxLength: '=?',
      triggerSave: '&',
      viewOptions: '=?',
      displayClass: '=?',
      isIntegrated: '=?',
      selectOptions: '=?',
      courseRequired: '=?',
      readonlyWarning: '=?',
    },
    require: 'nvClickToEdit',
    controller: function ctrl($scope, $element) {
'ngInject';
      const vm = this;

      vm.isSaving = false;
      vm.isEditing = false;
      vm.focusedInput = false;
      vm.touchedInput = false;
      vm.isTooltipOpen = false;
      vm.initialInput = vm.ngModel;
      vm.isEditable = vm.editInfo.isEditable && !vm.disabled;
      vm.save = save;
      vm.hideInput = hideInput;
      vm.checkOutsideClick = checkOutsideClick;

      function save() {
        hideInput();

        if (vm.ngModel !== vm.initialInput) {
          vm.isSaving = true;
          vm.editInfo.lastEditedField = vm.name;
          $element.find('.content-container').focus();

          vm.triggerSave();

          $timeout(() => {
            vm.isSaving = false;
          }, 800);
        }
      }

      function resetPopoverData() {
        vm.focusedInput = false;
        vm.touchedInput = false;
      }

      function showInput() {
        vm.isEditing = true;
        vm.isTooltipOpen = false;
        vm.initialInput = vm.ngModel;

        if (vm.inputType === 'dropdown' || vm.inputType === 'Dropdown') {
          $document.bind('click', checkOutsideClick);
        }

        if (vm.readonly) {
          $timeout(() => {
            $element.find('.form-control').attr('readonly', true);
          });
        }

        $timeout(() => {
          const formCtrl = (vm.inputType === 'dropdown' || vm.inputType === 'Dropdown') ? $element.find('.dropdown-toggle') : $element.find('.form-control');

          if ($document[0].activeElement !== formCtrl) {
            formCtrl.focus();
          }
        });
      }

      function hideInput() {
        resetPopoverData();

        if (vm.inputType === 'dropdown' || vm.inputType === 'Dropdown') {
          $document.unbind('click', checkOutsideClick);
        }

        vm.isEditing = false;
      }

      function checkOutsideClick(event) {
        if (!$element.find(event.target).length) {
          $timeout(() => {
            vm.touchedInput = true;
            hideInput();
          });
        }
      }

      vm.handleClick = function () {
        if (!vm.isEditing) {
          showInput();
        }
      };

      vm.handleKeyDown = function (event) {
        if (event.key === 'Enter' && !vm.isEditing) {
          showInput();
        }
      };

      vm.selectOption = function (event, option) {
        event.stopPropagation();

        vm.ngModel = option;

        $timeout(save);
      };

      vm.handleBlur = function () {
        vm.touchedInput = true;
        vm.focusedInput = false;
        const isValid = !vm.form[vm.name].$invalid;

        if (isValid) {
          save();
        }
      };

      vm.handleFocus = function () {
        vm.focusedInput = true;
      };

      vm.handleContainerMouseEnter = function () {
        vm.isTooltipOpen = true;
      };

      vm.handleContainerMouseLeave = function () {
        vm.isTooltipOpen = false;
      };

      vm.handleInputKeyDown = function (event) {
        event.stopPropagation();

        vm.touchedInput = true;
        vm.focusedInput = false;
        const isValid = !vm.form[vm.name].$invalid;

        if (
          event.key === 'Enter' && (vm.courseRequired ? true : isValid)
        ) {
          event.preventDefault();
          save();
        }
      };

      vm.getPopoverMessage = function () {
        if (vm.readonly) {
          return vm.readonlyWarning;
        }

        return $translate.instant('VALIDATION.REQUIRED');
      };

      vm.getPopoverIsOpen = function () {
        if (vm.readonly) {
          return vm.focusedInput;
        }

        if (vm.required || vm.courseRequired) {
          return vm.touchedInput && !vm.ngModel;
        }

        return false;
      };
    },
    link($scope, $element, $attrs, clickToEditCtrl) {
      $scope.$on('$destroy', () => {
        if (clickToEditCtrl.inputType === 'dropdown') {
          $document.unbind('click', clickToEditCtrl.checkOutsideClick);
        }
      });
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'learner_profiles/templates/nv-click-to-edit.html',
  };
}
