import {
  black,
  gray1,
  hexToRgbaString,
} from 'styles/global_defaults/colors';

/* eslint-disable react/no-this-in-sfc */
/* @ngInject */
export default function MainContentController(
  _,
  $scope,
  $timeout,
  $stateParams,
  nvCurrentPage,

  DiscussionsManager,
  TimelinesManager,
  ReportsManager,
  CurrentCourseManager,
  CurrentPermissionsManager,

  AlertMessages,
  InstitutionsManager,
  CurrentUserManager,
  RailsRoutes,
) {
  const DEFAULT_TOP_HEADER_COLOR = '#fff';
  const TOP_HEADER_HEIGHT = 60;
  const PROGRAM_HOME_HEADER_HEIGHT = 240 - TOP_HEADER_HEIGHT;

  _.extend(this, {
    DEFAULT_TOP_HEADER_COLOR,
    isTopHeaderScrolling: false,
    courseTitleInView: true,
    isCourseLanguageVisible: nvCurrentPage.CurrentPermissionsManager.isConfigAndRegistrationRole() || nvCurrentPage.CurrentPermissionsManager.isCourseBuilder(),

    nvCurrentPage,
    $stateParams,

    DiscussionsManager,
    TimelinesManager,
    ReportsManager,

    getTopHeaderBackgroundColor,
    getBackgroundStyles,
    CurrentCourseManager,
  });

  function getTopHeaderBackgroundColor() {
    if (!this.isTopHeaderScrolling && $scope.MainGridCtrl.nvCurrentPage.isCourseHome() && $scope.MainGridCtrl.nvCurrentPage.getBrandColor()) {
      return $scope.MainGridCtrl.nvCurrentPage.getBrandColor();
    } if (this.isTopHeaderScrolling && this.DEFAULT_TOP_HEADER_COLOR) {
      return this.DEFAULT_TOP_HEADER_COLOR;
    }

    return null;
  }

  function getBackgroundStyles() {
    if (nvCurrentPage.getCourse().headerBackground) {
      return {
        background: `linear-gradient(0deg, ${hexToRgbaString(gray1, 0.5)} 0%, ${hexToRgbaString(gray1, 0)} 100%),
          linear-gradient(${hexToRgbaString(black, 0.1)}, ${hexToRgbaString(black, 0.1)}),
          url("${nvCurrentPage.getCourse().headerBackground}")`,
        backgroundPosition: 'bottom, center, center',
        backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
        backgroundSize: '100% 50%, cover, cover',
      };
    }

    return {
      backgroundColor: $scope.MainGridCtrl.nvCurrentPage.getMainContentBrandColor(),
    };
  }

  if (nvCurrentPage.getNonL4Data() && nvCurrentPage.getNonL4Data().mainPanelScrollable) {
    const debouncedScrollHandler = _.debounce(() => {
      $timeout(() => {
        const node = $('#main-panel');

        if (node) {
          const expandedTitle = node.find('#expanded-title');

          if (expandedTitle.length) {
            this.courseTitleInView = expandedTitle?.offset().top > 60;
          }

          this.isTopHeaderScrolling = node.scrollTop() > 0;

          // programs home
          if (nvCurrentPage.isCourseHome() && nvCurrentPage.getCourse().isProgram) {
            $('#sort-filter-wrapper').toggleClass('sticky', node.scrollTop() >= PROGRAM_HOME_HEADER_HEIGHT);
            $('.new-box').toggleClass('sticky', node.scrollTop() >= PROGRAM_HOME_HEADER_HEIGHT);
          }
        }
      });
    }, 0);

    angular.element('#main-panel').on('scroll', debouncedScrollHandler);

    $scope.$on('$destroy', () => {
      angular.element('#main-panel').off('scroll', debouncedScrollHandler);
    });
  }

  function checkForCalendarAuthError() {
    if (
      CurrentPermissionsManager.hasOrgAdminPermissions()
      && InstitutionsManager.institution?.calendarSettings?.oauthErrorHappenedAt
      && InstitutionsManager.institution?.calendarSettings?.calendarOauthUserEmail
      && !InstitutionsManager.institution?.calendarSettings?.isErrorDisplayed) {
      InstitutionsManager.institution.calendarSettings.isErrorDisplayed = true;
      AlertMessages.error(
        '',
        'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.AUTHENTICATION_ERROR.TEAMS_AUTHENTICATE_ERROR',
        {},
        { accountEmail: InstitutionsManager.institution?.calendarSettings?.calendarOauthUserEmail },
        Infinity,
        {
          text: 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.AUTHENTICATION_ERROR.AUTHENTICATE',
          href: RailsRoutes.authenticateMicrosoftCalendarOauthUrl(InstitutionsManager.institution.id),
        },
        () => {},
        'calendar-auth-error',
      );
    }
  }
  checkForCalendarAuthError();
}
