/* @ngInject */
export default function MeetAndGreetLectureComponentModel(
  LectureComponentBaseModel,
) {
  class MeetAndGreetLectureComponent extends LectureComponentBaseModel {
    constructor(attributes) {
      const defaults = {
        type: 'MeetAndGreetLectureComponent',
        editFormTemplateUrl: 'lecture_pages/templates/components/meet-and-greet-edit-modal.html',
        editFormController: 'MeetAndGreetEditFormModalCtrl',
        canBeCopied: true,
      };
      super({ ...defaults, ...attributes });
    }

    __preprocess() {}

    getPayload() {
      return { meetAndGreet: this.meetAndGreet };
    }
  }

  MeetAndGreetLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.DESCRIPTION';
  MeetAndGreetLectureComponent.showModalBeforeCreate = true;

  return MeetAndGreetLectureComponent;
}
