import store from '../../redux/store';
import { setCourseAccessModalProps } from '../../redux/actions/course-access-modal';
import { providerTypes } from '../../institutions/components/advanced-settings/integrations/lms-integration';
import { setCourseHighlight } from '../../redux/actions/institutions';

/* @ngInject */
export default function NewCourseCtrl(
  CoursesManager,
  CourseModel,
  InstitutionsManager,
  AlertMessages,
  FilterCoursesManager,
  StateManager,
  $controller,
  $state,
  _,
  $scope,
  $timeout,
  config,
) {
  angular.extend(this, $controller('CourseFormBaseCtrl'));
  const vm = this;
  vm.config = config;
  vm.newCourse = true;
  vm.saveCourse = saveCourse;
  vm.cancel = cancel;
  vm.isNameValid = false;
  vm.setNameValid = setNameValid;
  initialize();

  function initialize() {
    CoursesManager.newCourse({
      institutionId: $state.params.institutionId,
      isProgram: false,
      usedFor: CourseModel.USED_FOR_DEMO,
      // This was removed from the UI in https://novoed.atlassian.net/browse/NOV-61368
      // We default this to the `Standard features` value since the concept of
      // `Premium features` is deprecated
      setOfFeatures: CourseModel.STANDARD_FEATURES,
      typeOfRegistration: vm.defaultTypeOfRegistration(),
    });
    vm.courseDraft = angular.copy(CoursesManager.course);
    vm.initialize($scope);

    if (!vm.courseDraft.isProgram && !vm.courseDraft.durationType) {
      vm.courseDraft.durationType = 'hours';
    }

    if (vm.institution.lmsEnabled && vm.institution.lmsConnectionSettings?.lms === providerTypes.SUMTOTAL) {
      const lmsActivityCode = vm.courseDraft?.lmsCourse?.externalCourseNumber;
      const lmsActivityId = vm.courseDraft?.lmsCourse?.externalCourseId;
      vm.courseDraft.sumTotalIntegration = {
        validating: false,
        editing: false,
        validationError: false,
        lmsActivityCode,
        lmsActivityId,
      };
      _.extend(vm.courseDraft, { lmsActivityCode, lmsActivityId });
    }

    if (vm.institution.courseEnrollmentTypes.length === 0) {
      vm.courseEnrollmentTypes = CourseModel.REGISTRATION_TYPES;
    } else {
      vm.courseEnrollmentTypes = _.filter(CourseModel.REGISTRATION_TYPES, (typeOfRegistration) => _.includes(vm.institution.courseEnrollmentTypes, typeOfRegistration.toString())
            || vm.CurrentUserManager.user.admin || vm.courseDraft.typeOfRegistration === typeOfRegistration);
    }

    vm.renderReactCheckboxDropdown();
    vm.renderReactDropdown();
  }

  function cancel() {
    const prevStateData = $state.params.prevStateData || { name: 'institution-dashboard' };
    $state.go(prevStateData.name, prevStateData.params);
  }

  function setNameValid(isValid) {
    $timeout(() => {
      vm.isNameValid = isValid;
    });
  }

  function saveCourse() {
    vm.savingCourse = true;
    _.extend(CoursesManager.course, vm.courseDraft);
    vm.baseSaveCourse().then(
      (course) => {
        vm.courseForm.$setPristine();

        if (course.usedFor === CourseModel.USED_FOR_PRIMARY) {
          store.dispatch(setCourseHighlight(course.catalogId));
        }

        store.dispatch(setCourseAccessModalProps({ catalogId: course.catalogId }));

        $state.go('institution-dashboard', { dashboardTab: FilterCoursesManager.tabForCourse(course) }, { reload: true }).then(() => {
          AlertMessages.success('COURSES.FORM.CREATED_SUCCESSFULLY', null, { isProgram: vm.courseDraft.isProgram });
        });
      },
      (response) => vm.handleErrorResponse(response),
    ).finally(() => {
      vm.savingCourse = false;
    });
  }

  // private functions
  function checkUnsavedChanges() {
    return vm.courseForm?.$dirty;
  }

  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(
    checkUnsavedChanges,
    'shared/templates/modal-navigate-away.html',
    'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY',
  );

  $scope.$on('$destroy', () => {
    vm.deregisterStateChangeStart?.();
  });

  // To rerender the course header while the user updates offering type
  // so that the placeholder of the title field will change.
  $scope.$watch('vm.courseDraft.isProgram', () => {
    vm.updateFormHeader();
  });
}
