import React, { KeyboardEvent, MouseEvent, useRef, useState, useCallback, useEffect } from 'react';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { find, isEmpty } from 'underscore';

// Redux
import { RootState } from 'redux/schemas';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { User } from 'redux/schemas/models/my-account';
import { CourseRole } from 'redux/schemas/models/org-level-roles';
import { Role } from 'redux/schemas/models/role';
import { useAppDispatch } from 'redux/store';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { addUsersToCourse, searchCourseOrgUsers } from 'redux/actions/users';
import { getCollectionRoles } from 'redux/selectors/roles';

// styles
import { halfSpacing } from 'styles/global_defaults/scaffolding';

// Components
import { NvDropdownCustomItem } from 'shared/components/inputs/nv-dropdown';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvIcon from 'shared/components/nv-icon';
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';
import NvPopover from 'shared/components/nv-popover';
import NvFormDropdown from 'shared/components/inputs/nv-form-dropdown';
import { Button, Dropdown } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import CollectionUserListItems from './collection-user-list-items';
import { getPopoverContent } from './user-management';

export interface ItemProps{
  id: number;
  text: string;
  popOver: boolean
  popOverText: string | boolean
}

export interface UserFormData{
  firstName: string;
  lastName: string;
  email: string;
  courseRoleId: string;
}

export interface CollectionRoleItemProps{
  role: Role
  onClick: (number) => void
}

const SEARCH_PAGE_SIZE = 30;

export const CollectionRoleItem = (props: CollectionRoleItemProps) => {
  const [showPopover, setShowPopover] = useState(false);

  return (
    <Dropdown.Item
      as='div'
      onClick={() => props.onClick(props.role.id)}
    >
      <div className='d-flex align-items-center'>
        <div className=''>
          {props.role.name}
        </div>
        <NvPopover
          content={getPopoverContent(props.role.name)}
          placement='top'
          preventOverflow={false}
          className='ml-1'
          offset={10}
          showOnHover
        >
          <div>
            <NvIcon icon='info' size='xss-smallest' className='color-primary' />
          </div>
        </NvPopover>
      </div>
    </Dropdown.Item>
  );
};

const AddUserModal = (props: { onClose(reload?: boolean): void }) => {
  const [searchKey, setSearchKey] = useState('');
  const [searching, setSearching] = useState(false);
  const [searchedList, setSearchedList] = useState<User[]>([]);
  const [searchListLoaded, setSearchListLoaded] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [saving, setSaving] = useState<boolean>(false);

  const { catalogId } = useParams<{ collectionId: string, catalogId: string }>();
  const searchRef = useRef<HTMLInputElement | HTMLTextAreaElement>();
  const dispatch = useAppDispatch();

  const hasMore = useSelector((state) => state.app.collection.hasMoreInUsersSearch);
  const collectionRoles: CourseRole[] = useSelector<RootState, Role>(getCollectionRoles);

  const viewerRole = find(collectionRoles, (role) => isEmpty(role.permissions));

  const validationSchema = yup.object().shape({
    firstName: yup.string().required(t.VALIDATION.REQUIRED()),
    lastName: yup.string().required(t.VALIDATION.REQUIRED()),
    email: yup.string().email(t.VALIDATION.EMAIL()).required(t.VALIDATION.REQUIRED()),
    courseRoleId: yup.string().required(t.VALIDATION.REQUIRED()),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
  });

  const { handleSubmit, formState, getValues } = methods;
  const { isValid } = formState;

  const modalBody = document.getElementsByClassName('bs4-modal-body')[0] as HTMLDivElement;
  const SCROLL_PADDING = 20;
  const isScrolledToBottom = useInfiniteScroll(
    modalBody,
    SCROLL_PADDING,
  );

  const dropDownStyle = css`
    .bs4-dropdown-item {
      padding-right: ${halfSpacing}px;
      padding-left: ${halfSpacing}px;
    }
  `;
  const bodyStyle = css`
    max-height: calc(100vh - 220px);
    min-height: 340px;
  `;

  // For infinite scrolling
  useEffect(() => {
    if (modalBody && isScrolledToBottom && hasMore && !searching) {
      setSearching(true);
      setPageNo(pageNo + 1);
    }
  }, [modalBody, isScrolledToBottom, hasMore, pageNo, searching]);

  const onSearch = useCallback((query: string): Promise<User[]> => new Promise((resolve) => {
    const payload = {
      catalogId,
      page: pageNo,
      pageSize: SEARCH_PAGE_SIZE,
      courseAdmins: true,
      courseLearners: true,
      courseMentors: true,
      queryTerm: query,
    };

    dispatch(searchCourseOrgUsers(payload)).then((response) => {
      if (!isEmpty(response?.error)) {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.ERROR_SOMETHING_WRONG(),
        }));
        resolve([]);
      }

      resolve(response.payload.users ?? []);
    });
  }), [catalogId, dispatch, pageNo]);

  useEffect(() => {
    if (searching) {
      onSearch(searchKey)
        .then((response) => {
          setSearching(false);
          setSearchListLoaded(true);
          setSearchedList((prevList) => ([...prevList, ...response ?? []]));
        });
    }
  }, [searching, searchKey, onSearch]);

  const collectionRoleItems: NvDropdownCustomItem[] = collectionRoles.map(role => ({
    id: role.id,
    type: 'custom',
    customItem: (
      <CollectionRoleItem
        role={role}
        onClick={(roleId) => methods.setValue('courseRoleId', roleId, { shouldValidate: true })}
      />),
  }));

  const searchUsers = (
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement> | MouseEvent,
  ) => {
    if ((event as KeyboardEvent).key === 'Enter' || event.type === 'click') {
      setSearching(true);
      setPageNo(1);
      setSearchedList([]);
      setSearchListLoaded(false);
    }
  };

  const handleInputChange = (e) => {
    setSearchKey(e.target.value);
  };

  const clearSearch = () => {
    if (searchRef.current) {
      searchRef.current.value = '';
    }
    setSearchListLoaded(false);
    setSearchKey('');
  };

  const onSubmit = useCallback((formData: UserFormData): Promise<void> => new Promise((resolve) => {
    setSaving(true);
    const payload = {
      catalogId,
      courseRoleId: formData.courseRoleId,
      users: [{
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
      }],
    };

    dispatch(addUsersToCourse(payload)).then((response) => {
      setSaving(false);

      if (isEmpty(response?.error)) {
        dispatch(addAlertMessage({
          type: AlertMessageType.SUCCESS,
          header: t.FORM.SUCCESS_BANG(),
          message: t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.ADD_USER_MODAL.SUCCESS_ALERT(1),
        }));
      }

      props.onClose(true);
      resolve();
    });
  }), [catalogId, dispatch, props]);

  const getRoleItemTitle = (): string => find(collectionRoles, (role) => role.id === getValues().courseRoleId)?.name;
  return (
    <div css={bodyStyle}>
      <div>
        <p className='text-body mt-4'>
          {t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.ADD_USER_MODAL.LABEL()}
        </p>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>
          <div className='d-flex'>
            <div className='pr-1 w-75'>
              <NvTextInput
                name='firstName'
                withForm
                required
                placeholder={t.USERS.REGISTRATION.FIRST_NAME()}
              />
            </div>
            <div className='px-1 w-75'>
              <NvTextInput
                name='lastName'
                withForm
                required
                placeholder={t.USERS.REGISTRATION.LAST_NAME()}
              />
            </div>
            <div className='px-1 w-75'>
              <NvTextInput
                name='email'
                withForm
                required
                placeholder={t.USERS.REGISTRATION.EMAIL()}
              />
            </div>
            <div className='w-100 px-1' css={dropDownStyle}>
              <NvFormDropdown
                name='courseRoleId'
                items={collectionRoleItems}
                withFormDefaultValue={viewerRole?.id}
                title={getRoleItemTitle()}
                titleClass='text-black'
              />
            </div>
            <Button
              variant='primary'
              className='ml-1'
              type='submit'
              disabled={!isValid || saving}
            >
              {saving ? t.FORM.ADDING() : t.FORM.ADD()}
            </Button>
          </div>
        </form>
      </FormProvider>

      <p className='text-body mt-5'>
        {t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.ADD_USER_MODAL.MANUALLY_ADD_SEARCH_LABEL()}
      </p>

      <div className=' d-flex align-items-center border-bottom border-gray-4 pb-2'>
        <NvIcon
          size='small'
          icon='search'
          onClick={searchUsers}
        />
        <NvTextInput
          className='flex-grow-1'
          onChange={handleInputChange}
          inputClassName='border-0 bg-transparent'
          value={searchKey}
          onKeyDown={searchUsers}
          name='search'
          placeholder={t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.ADD_USER_MODAL.SEARCH_PLACEHOLDER()}
        />
        {searchKey ? (
          <button
            type='button'
            className='border-0 bg-transparent text-small color-primary'
            onClick={clearSearch}
          >
            {t.SHARED.CLEAR()}
          </button>
        ) : ''}
      </div>
      <div className='searchResults'>
        {searchListLoaded && searchedList.length > 0 ? (
          <div className='py-2'>
            <div className='d-flex align-items-center py-2'>
              {t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.ADD_USER_MODAL.FOUND_USERS_WHILE_SEARCH()}
            </div>
            {searchedList.map((user) => (
              <CollectionUserListItems
                key={user.id}
                user={user}
                collectionRoles={collectionRoles}
                onSubmit={onSubmit}
              />
            ))}
          </div>
        ) : ''}
        {searchListLoaded && !hasMore && searchedList.length > 0 ? (
          <div className='d-flex align-items-center gray-2 text-small pb-2'>{t.SEARCH.ALL_LOADED()}</div>
        ) : ''}
      </div>
      {searching ? (
        <div className='d-flex align-items-center my-2'>
          <NvIcon size='smallest' icon='refresh' className='color-primary pr-2' />
          <span>{t.USER_MANAGEMENT.MANUAL_ADD_MODAL.LOADING_RESULTS()}</span>
        </div>
      ) : (
        <React.Fragment>
          {(searchListLoaded && searchedList.length === 0) && (
          <div className='d-flex align-items-center my-2'>
            <NvIcon size='smallest' icon='warning' className='text-danger pr-2' />
            <span>{t.USER_MANAGEMENT.MANUAL_ADD_MODAL.NO_RESULTS()}</span>
          </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default AddUserModal;

