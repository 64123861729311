import { SerializedStyles } from '@emotion/react';
import { Popover, PopoverContent } from 'react-bootstrap';
import { PopoversContainerContext } from 'shared/react-utils';
import React, { ReactElement, useRef, useState, useEffect, MutableRefObject, ReactNode, useContext, useMemo } from 'react';
import Overlay, { Placement, OverlayInjectedProps } from 'react-bootstrap/Overlay';
import _ from 'underscore';
import { getActualPlacement } from './nv-tooltip';

// copied from scss previously, but where did this come from seems kind of large
const DEFAULT_POPOVER_OFFSET = 15;

export type NvPopoverProps = {
  children: ReactElement,
  content: ReactElement,
  /** Manually sets whether the popover is visible */
  show?: boolean,
  /** Automatically shows the popover when hovering over the children */
  showOnHover?: boolean,
  /** Whether popper.js should attempt to prevent overflowing the popover outside its container. Defaults to false */
  preventOverflow?: boolean
  enabled?: boolean,
  placement?: Placement,
  /** Class names applied to the popover-wrapper */
  className?: string,
  /** Component styles to apply to the popover. Necessary because popovers are added to the root
   * element */
  overlayStyles?: SerializedStyles,
  offset?: number,
  customTarget?: MutableRefObject<any>,
  flip?: boolean,
  rootClose?: boolean,
  onHide?: (e) => void,
  container?: HTMLDivElement,
};

/**
 * NovoEd-customized wrapper around a react-bootstrap Dropdown component.
 */
export const NvPopover = (props: NvPopoverProps, ref: MutableRefObject<any>) => {
  const { placement = 'auto', container } = props;
  const { enabled = true, showOnHover = false } = props;

  const offset = props.offset ?? DEFAULT_POPOVER_OFFSET;

  const target = props.customTarget ?? useRef(null);
  const [show, setShow] = useState(props.show ?? false);
  const contextContainer = useContext(PopoversContainerContext);

  const onHide = (e) => {
    if (props.rootClose) {
      setShow(false);
    }
    props.onHide?.(e);
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const actualPlacement = useMemo(() => getActualPlacement(placement), [placement]);

  return (
    <React.Fragment>
      <div
        className={`popover-wrapper ${props.className ?? ''}`}
        ref={target}
        onMouseEnter={() => !props.show && showOnHover && setShow(true)}
        onMouseLeave={() => !props.show && showOnHover && setShow(false)}
      >
        {props.children}
      </div>
      <Overlay
        target={target.current}
        show={enabled && show}
        placement={actualPlacement}
        container={container ?? contextContainer}
        rootClose={props.rootClose}
        onHide={onHide}
        popperConfig={{
          modifiers: [
            {
              name: 'hide',
              enabled: props.preventOverflow ?? false,
            }, {
              name: 'preventOverflow',
              enabled: props.preventOverflow ?? false,
            }, {
              name: 'flip',
              enabled: props.flip || (placement as string).includes('auto'),
            },
            {
              name: 'offset',
              options: {
                offset: () => [0, offset],
              },
            },
          ],
        }}
      >
        {(oProps: OverlayInjectedProps) => (
          <OverlayContent
            {...oProps}
            content={props.content}
            styles={props.overlayStyles}
          />
        )}
      </Overlay>
    </React.Fragment>
  );
};

type OverlayContentProps = {
  content: ReactNode,
  styles: SerializedStyles,
};

const OverlayContent = React.forwardRef<HTMLDivElement, OverlayContentProps & OverlayInjectedProps>((
  props,
  ref,
) => {
  const {
    styles,
    content,
    ...restProps // Injected ones
  } = props;

  useEffect(() => {
    props.popper.scheduleUpdate();
  }, [props.content]);

  return (
    <Popover
      ref={ref}
      id='popover'
      css={styles}
      {...restProps}
    >
      <PopoverContent>
        {content}
      </PopoverContent>
    </Popover>
  );
});

export default NvPopover;
