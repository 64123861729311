/** @jsx */
import { css, jsx } from '@emotion/react';
import React, { useState, useEffect, useContext, useCallback, FunctionComponent } from 'react';
import t from 'react-translate';
import { useSelector } from 'react-redux';

import { AngularServicesContext } from 'react-app';

// Actions
import { useAppDispatch } from 'redux/store';
import { fetchOrgMentors, deleteInstitutionAdmin } from 'redux/actions/org-mentors';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';

// Selectors
import { getRoleAliases } from 'redux/selectors/institutions';
import { getOrgMentorsKeys } from 'redux/selectors/org-mentors';

// Schemas
import { OrgMentor, SortOrderType } from 'redux/schemas/models/org-mentors';
import { OrgMentorsNormalized } from 'redux/schemas/api/org-mentors';
import { RootState } from 'redux/schemas';

// Styles
import { createGridStyles, tripleSpacing } from 'styles/global_defaults/scaffolding';

import NvIcon from 'shared/components/nv-icon';
import { NvDropdown, NvDropdownButtonStyle, NvDropdownAlign, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { NvModal, ModalType } from 'shared/components/nv-modal';
import NvUserAvatar from 'components/nv-user-avatar';

import ClickableContainer from 'components/clickable-container';
import NvResponsiveTable, { ColumnSortings, NvResponsiveTableColumn, NvTableRowProps } from 'shared/components/nv-responsive-table';
import { PagedDataQueryParams } from 'redux/create-action-creators';
import LoadingRow, { tableStyles } from '../../institutions/components/org-user-management/shared/table-components';
import AddMentorModal from './add-mentor-modal';
import UpdateOrgMentorAliasModal from './update-org-mentor-alias-modal';
import AddViewMenteeModal, { MenteeModalTypes } from './add-view-mentee-modal';
import MentorBulkUploadModal from './mentor-bulk-upload-modal';
import { config } from '../../../config/pendo.config.json';

const styles = css`
  .mentor-action-header {
    height: ${tripleSpacing}px;

    .mentor-action-panel {
      width: 800px;
    }

    .nv-dropdown {
      display: flex;
      align-items: center;
    }
  }
  .mentor-list-body {
    /* 180 = top-nav height(60) + tab header height(60) + tab-action-header height(60) */
    height: calc(100vh - 180px);
    overflow-y: scroll;
  }
  .mentor-container {
    width: 100%;
    .mentor-span {
      display: flex;
      span {
        width: 800px;
        margin: 0 auto;
      }
    }
    height: calc(100vh - 210px);

    .nv-responsive-table {
      ${tableStyles}
    }
  }
`;
const rowStyles = css`
 &.user-row {
   height: ${tripleSpacing}px;
 }
 &.input-row {
   height: ${tripleSpacing}px;
 }
`;
const getInitials = (FirstName: string, LastName: string) => `${FirstName?.[0]?.toUpperCase() ?? ''}${LastName?.[0]?.toUpperCase() ?? ''}`;

const PAGE_SIZE = 25;
const initialFetchParams: PagedDataQueryParams = {
  sorting: [],
};
const OrgMentorAdminPanel = () => {
  const mentorsKeys = useSelector(getOrgMentorsKeys);
  const roleAliases = useSelector(getRoleAliases);
  const [showAddManagerModal, setShowAddManagerModal] = useState(false);
  const [showUpdateMentorAliasModal, setShowUpdateMentorAliasModal] = useState(false);
  const [showBulkUploadManagerModal, setShowBulkUploadManagerModal] = useState(false);
  const [showMenteeModal, setShowMenteeModal] = useState(false);
  const [menteeModalType, setMenteeModalType] = useState<MenteeModalTypes>();
  const [currentMentorId, setCurrentMentorId] = useState<number>();
  const currentInstitutionId = useSelector((state) => state.app.currentInstitutionId);
  const { $state } = useContext(AngularServicesContext) || {};
  const [columnSortings, setColumnSortings] = useState<ColumnSortings>({ 0: false }); // Last name sorted Asc by default
  const [pagedFetchParams, setPagedFetchParams] = useState<PagedDataQueryParams>(initialFetchParams);
  const storedOrgMentorData = (state: RootState) => state.models.orgMentors as OrgMentorsNormalized;

  const dispatch = useAppDispatch();

  const deleteOrgAdmin = (mentor: OrgMentor) => {
    dispatch(openConfirmationDialog({
      title: t.INSTITUTIONS.ORG_MENTORS.REMOVE_MANAGER_TITLE({
        MentorTitleAlias: roleAliases.mentor.singularizedTitleized,
      }),
      bodyText: t.INSTITUTIONS.ORG_MENTORS.REMOVE_MANAGER_DESC({
        name: `${mentor.user.firstName} ${mentor.user.lastName}`,
        mentorAlias: roleAliases.mentor.downcasedSingularized,
        menteesAlias: roleAliases.mentee.downcasedPluralized,
      }),
      confirmText: t.FORM.YES_SURE(),
      cancelText: t.FORM.CANCEL(),
      icon: 'warning',
      onConfirm: () => onDelete(mentor),
    }));
  };

  const onDelete = (mentor: OrgMentor) => {
    dispatch(deleteInstitutionAdmin({
      institutionId: currentInstitutionId,
      institutionAdminId: mentor.id,
    }));
  };

  const openMenteeModal = (mentor: OrgMentor) => {
    setCurrentMentorId(mentor.id);
    setMenteeModalType(mentor.mentees.length > 0 ? MenteeModalTypes.VIEW : MenteeModalTypes.ADD);
    setShowMenteeModal(true);
  };

  const dropDownItems: NvDropdownOption[] = [
    {
      type: 'text',
      text: t.INSTITUTIONS.ORG_MENTORS.MANUALLY_ADD_MENTORS(),
      pendoTag: config.pendo.orgMentors.addMentorManual,
      callback: () => { setShowAddManagerModal(true); },
    },
    {
      type: 'text',
      pendoTag: config.pendo.orgMentors.addMentorBulk,
      text: t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MENTORS(),
      callback: () => setShowBulkUploadManagerModal(true),
    },
  ];

  const tableColumns: NvResponsiveTableColumn[] = [
    {
      name: t.INSTITUTIONS.ROLES.NAME(),
      className: 'name-cell',
      gridWidth: '30%',
      sortable: true,
    },
    {
      name: t.INSTITUTIONS.ROLES.EMAIL(),
      className: 'email-cell',
      gridWidth: '40%',
    },
    {
      name: roleAliases.mentee.capitalizedPluralized,
      className: 'org-level-role-cell',
      gridWidth: '20%',
    },
    {
      name: t.INSTITUTIONS.ROLES.ACTIONS(),
      className: 'actions-cell',
      gridWidth: '10%',
      headerClassName: 'justify-content-end pr-0',
    },
  ];

  const onHeaderSortingClicked = (colIndex: number) => {
    const newSorting = !columnSortings[colIndex];
    const newSortKey = newSorting ? 'last_name:desc' : 'last_name:asc';

    // Don't preserve anything from the previous sorting; we only allow sorting on one column at a time
    setColumnSortings({ [colIndex]: newSorting });
    setPagedFetchParams({
      ...pagedFetchParams,
      sorting: [newSortKey],
    });
  };

  const orgAdminMentor = (rowProps: NvTableRowProps<OrgMentor>) => {
    const { data: user, rowIndex } = rowProps;
    return (
      <div
        className='user-row d-flex border-bottom border-gray-5 py-2 align-items-center text-regular text-black'
        style={createGridStyles(1, rowIndex, tableColumns.length + 1, rowIndex + 1)}
        css={rowStyles}
      >
        <div
          className='font-weight-bolder'
          style={{
            display: 'flex',
            width: '30%',
            alignItems: 'center',
          }}
        >
          <NvUserAvatar
            directToOrgLevelProfile
            borderType='round'
            size='md'
            user={{
              ...user.user,
              fullName: `${user.user.firstName} ${user.user.lastName}`,
              roles: null,
              initials: getInitials(user.user.firstName, user.user.lastName),
              userNameForURL: `${user.user.firstName} ${user.user.lastName}`,
            }}
          />
          <a
            className='pl-2 bold'
            href={$state?.href('org-level-profile-modal', { userId: user.user.id })}
          >
            {`${user.user.firstName} ${user.user.lastName}`}
          </a>
        </div>
        <div style={{ width: '40%' }} className='pr-2'>
          {user.user.email}
        </div>
        <React.Fragment>
          <div style={{ width: '20%' }}>
            <button
              type='button'
              className='border-0 bg-transparent color-primary px-0'
              onClick={() => openMenteeModal(user)}
            >
              {`${user.mentees.length} ${user.mentees.length !== 1 ? roleAliases.mentee.capitalizedPluralized : roleAliases.mentee.capitalizedSingularized}`}
            </button>
          </div>

          <div
            className='actions text-primary'
            style={{ width: '10%' }}
          >
            <ClickableContainer
              className='d-flex align-items-center justify-content-end pr-2'
              onClick={() => deleteOrgAdmin(user)}
            >
              <NvIcon size='xss-smallest' icon='trash' />
            </ClickableContainer>
          </div>
        </React.Fragment>
      </div>
    );
  };

  return (
    <div css={styles}>
      <div className='mentor-action-header w-100 d-flex justify-content-center bg-gray-7'>
        <div className='mentor-action-panel d-flex text-medium'>
          <button
            type='button'
            className='d-flex align-items-center border-0 bg-transparent color-primary mr-4'
            onClick={() => setShowUpdateMentorAliasModal(true)}
            pendo-tag-name={config.pendo.orgMentors.changeAlias}
          >
            <NvIcon size='smallest' icon='edit' className='pr-2 d-inline-block ' />
            {t.INSTITUTIONS.ORG_MENTORS.CHANGE_ROLE_ALIAS()}
          </button>
          <NvDropdown
            buttonStyle={NvDropdownButtonStyle.CUSTOM}
            items={dropDownItems}
            align={NvDropdownAlign.RIGHT}
            customTarget={() => (
              <button
                type='button'
                pendo-tag-name={config.pendo.orgMentors.addMentor}
                className='d-flex align-items-center bg-transparent border-0 color-primary mr-4'
              >
                <NvIcon size='smallest' icon='add' className='pr-2 d-inline-block' />
                {t.INSTITUTIONS.ORG_MENTORS.ADD_MANAGERS({ MentorsTitleAlias: roleAliases.mentor.pluralizedTitleized })}
              </button>
            )}
          />
        </div>
      </div>
      <div className='mentor-list-body w-100 d-flex justify-content-center px-2'>
        <div className='mentor-container pt-5'>
          <div className='mentor-span pb-5'>
            <span>{t.INSTITUTIONS.ORG_MENTORS.MENTOR_LIST_DESC({ mentorAlias: roleAliases.mentor.downcasedSingularized })}</span>
          </div>
          <NvResponsiveTable<OrgMentor, {}, OrgMentorsNormalized>
            columns={tableColumns}
            columnSortings={columnSortings}
            onSortClicked={onHeaderSortingClicked}
            fetchData={fetchOrgMentors}
            fetchParams={{ institutionId: currentInstitutionId }}
            pagedFetchParams={pagedFetchParams}
            rowComponent={orgAdminMentor as FunctionComponent}
            rowProps={{}}
            loadingComponent={(rawProps) => LoadingRow(rawProps, tableColumns.length + 1)}
            dataKey='id'
            cacheDataKey='id'
            cacheLookup={storedOrgMentorData}
            hideClearSearch
            clearSearch={() => {}}
            style={createGridStyles(1, 3)}
            noResultsInTable
            noResultsIcon=''
            noResultsText={t.INSTITUTIONS.ORG_MENTORS.NO_MENTOR_ADDED_YET(
              { mentorsAlias: roleAliases.mentor.downcasedPluralized },
            )}
            hoverDisabled
            pageSize={PAGE_SIZE}
            extraDataKeys={mentorsKeys}
          />
        </div>
      </div>
      <NvModal
        type={ModalType.DYNAMIC}
        header={t.INSTITUTIONS.ORG_MENTORS.ADD_MANAGER({
          MentorTitleAlias: roleAliases.mentor.singularizedTitleized,
        })}
        body={<AddMentorModal />}
        show={showAddManagerModal}
        onClose={() => setShowAddManagerModal(false)}
        width={800}
      />
      <NvModal
        type={ModalType.DYNAMIC}
        header={t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.HEADER({
          MentorsTitleAlias: roleAliases.mentor.pluralizedTitleized,
          MenteesTitleAlias: roleAliases.mentee.pluralizedTitleized,
        })}
        body={(
          <MentorBulkUploadModal
            closeModal={() => setShowBulkUploadManagerModal(false)}
          />
        )}
        show={showBulkUploadManagerModal}
        onClose={() => setShowBulkUploadManagerModal(false)}
        width={600}
        fullHeight={false}
      />
      <NvModal
        type={ModalType.DYNAMIC}
        header={t.INSTITUTIONS.ORG_MENTORS.CHANGE_ROLE_ALIAS()}
        body={(
          <UpdateOrgMentorAliasModal
            onClose={() => setShowUpdateMentorAliasModal(false)}
          />
        )}
        show={showUpdateMentorAliasModal}
        onClose={() => setShowUpdateMentorAliasModal(false)}
        width={800}
        fullHeight={false}
      />
      <NvModal
        type={ModalType.DYNAMIC}
        header={menteeModalType === 'view'
          ? t.INSTITUTIONS.ORG_MENTORS.VIEW_MENTEE({ MenteeAlias: roleAliases.mentee.capitalizedPluralized })
          : t.INSTITUTIONS.ORG_MENTORS.ADD_MENTEE({ MenteeAlias: roleAliases.mentee.capitalizedPluralized })}
        body={(
          <AddViewMenteeModal
            mentorId={currentMentorId}
            type={menteeModalType}
            changeModalType={(type) => setMenteeModalType(type)}
          />
        )}
        show={showMenteeModal}
        onClose={() => setShowMenteeModal(false)}
        width={800}
      />
    </div>
  );
};

export default OrgMentorAdminPanel;
