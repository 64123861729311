import React from 'react';
import _ from 'underscore';
import moment from 'moment';
import { css, jsx, SerializedStyles } from '@emotion/react';

import t from 'react-translate';
import { AngularServicesContext } from 'react-app';
import { Course } from 'redux/schemas/models/course';
import { NvPopover } from 'shared/components/nv-popover';
import { NvTooltip } from 'shared/components/nv-tooltip';
import { isHandheld } from 'styles/global_defaults/media-queries';
import { gray5, primary, success } from 'styles/global_defaults/colors';
import { Institution, JourneyCloning } from 'redux/schemas/models/institution';
import JourneyRowOptions from 'learning_journeys/components/journey-row-options';
import {
  halfSpacing,
  doubleSpacing,
  tripleSpacing,
  quarterSpacing,
  standardSpacing,
  createGridStyles,
} from 'styles/global_defaults/scaffolding';
import {
  BKG_ROW_Z_INDEX,
  NvTableRowProps,
  NvTableCellProps,
} from 'shared/components/nv-responsive-table';

export type JourneyRowExtraProps = { institution: Institution };

export type JourneyCellProps = NvTableCellProps<Course, unknown, JourneyRowExtraProps> & {
  course: Course,
  optionsCellRef?: React.MutableRefObject<HTMLDivElement>,
  isBeingCloned?: boolean,
};

/** All of these styles are reated as constants outside the definition of CourseRow, because otherwise Emotion
 * will create new <style> tags every time it adds a row. That demolishes browser performance very quickly. */
const commonStyles = css`
  height: 100%;
  display: flex;
  align-items: center;
`;

const nameStyles = css`
  ${commonStyles};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${halfSpacing}px;
  padding-bottom: ${halfSpacing}px;

  &:not(.disabled) {
    pointer-events: none; /* Allows events to pass through the bkg row. */
    /* Place above the bkg row to take mouse over focus */
    z-index: ${BKG_ROW_Z_INDEX + 1} !important;
  }

  /* TODO: Move this into the NvAvatar */
  .name-panel {
    flex-direction: column;
    align-items: flex-start;

    .catalog-id {
      word-break: break-word;
    }
  }

  &:not(.disabled) {
    .course-name {
      pointer-events: all;
    }

    .course-name:hover {
      color: ${primary};

      & ~ .created-by-name {
        display: block;
      }
    }
  }

  /* Displayed on hover */
  .created-by-name {
    display: none;
  }

  grid-column: 1;
`;
const releaseDateStyles = css`
${commonStyles};
  width: 100%;
`;

const closeDateStyles = css`
  ${commonStyles};
`;

const numEnrolleesStyles = css`
  ${commonStyles};

  &:not(.disabled) {
    z-index: ${BKG_ROW_Z_INDEX + 1} !important;
    pointer-events: none;
  }

  .num-enrollees-text {
    pointer-events: all;
  }
`;

const completionRateStyles = css`
  ${commonStyles};
  display: flex;

  .completion-bar-area {
    width: 100%;
    margin-right: ${standardSpacing}px;
  }

  .completion-bar {
    position: relative;
    margin-bottom: ${quarterSpacing}px;
    height: ${quarterSpacing}px;
    display: flex;
    width: 100%;
    border-radius: 28px;
    overflow: hidden;

    .divider {
      position: absolute;
      background-color: white;
      height: ${quarterSpacing}px;
      width: 1px;
    }
  }

  .completion-bar-part {
    &.completed {
      background-color: ${success}
    }

    &.in-progress {
      background-color: ${primary};
    }

    &.remaining {
      background-color: ${gray5};
    }
  }

  .completion-pct {
    width: ${doubleSpacing}px;
    padding-bottom: ${standardSpacing}px;
  }
`;

const optionsStyles = css`
  ${commonStyles};
`;


const JourneyRow = (props: NvTableRowProps<Course, unknown, JourneyRowExtraProps>) => {
  const optionCellRef = React.useRef<HTMLDivElement>(null);

  const cells: [(props: JourneyCellProps) => JSX.Element, SerializedStyles][] = [
    [NameCell, nameStyles],
    [NumOfCoursesCell, commonStyles],
    [ReleaseDateCell, releaseDateStyles],
    [CloseDateCell, closeDateStyles],
    [NumEnrolleesCell, numEnrolleesStyles],
    [CompletionRateCell, completionRateStyles],
    [OptionsCell, optionsStyles],
  ];

  const mobileCells = [cells[0], cells[cells.length - 1]];

  const course = _.clone(props.data);
  const [showMobileView, setShowMobileView] = React.useState(isHandheld());
  const rowCells = showMobileView ? mobileCells : cells;

  const isBeingCloned = !_.isEmpty(props.extraObject);

  // Show disabled styles if the row is currently being deleted or being cloned
  const isDisabled = course?.isBeingDeleted || isBeingCloned;

  if (isBeingCloned) {
    const courseCloning = props.extraObject as JourneyCloning;
    const { settingsHash } = courseCloning;
    const { catalogId, name, releaseDate } = settingsHash;
    course.closeDate = '';
    course.catalogId = catalogId;
    course.name = name;
    course.releaseDate = releaseDate;
  }

  // Hide most of the cells when in a phone view
  // TODO: Is it safe to add all of these event listeners?
  React.useEffect(() => {
    const listen = window.addEventListener('resize', _.debounce(() => {
      setShowMobileView(isHandheld());
    }, 100));

    return listen;
  }, []);

  return (
    <React.Fragment>
      {
        rowCells.map((cell, i) => {
          const cellProps: JourneyCellProps = {
            ...props,
            course,
            reactKey: `${props.rowIndex}-${i + 1}`,
            serializedStyles: cell[1],
            rowClasses: isDisabled ? ' disabled' : '',
            divProps: {
              style: createGridStyles(i + 1, props.rowIndex, i + 2, props.rowIndex + 1),
            },
            // Overwrite the props.disabled with the local state so it can be dynamically updated
            disabled: isDisabled,
            optionsCellRef: optionCellRef,
            isBeingCloned,
          };
          return cell[0](cellProps);
        })
      }
    </React.Fragment>
  );
};

const NameCell = (props: JourneyCellProps) => {
  const { course } = props;
  const angularServices = React.useContext(AngularServicesContext);

  const goToJourneyUrl = angularServices.$state.href('learning-journey-home', {
    catalogId: course.catalogId,
  });

  const courseCloning = props.extraObject as JourneyCloning;

  // Without the journey image each row looks thinner, so here forcing to have
  // the specific height.
  const cellStyles = css`
    ${props.serializedStyles};
    height: ${tripleSpacing + standardSpacing}px;
  `;

  return (
    <div
      {...props.divProps}
      css={cellStyles}
      key={props.reactKey}
      className={`name-cell${props.rowClasses ?? ''}`}
    >
      <div className='name-panel'>
        {/* Deletion text that appears when a course is being deleted */}
        { course.isBeingDeleted && <div className='text-small font-weight-bold text-warning'>{t.COURSES.DASHBOARD_TABLE.DELETION_IN_PROGRESS()}</div> }
        {/* Cloning in progress text during a course clone */}
        { props.isBeingCloned && <div className='text-small font-weight-bold text-warning'>{t.COURSES.DASHBOARD_TABLE.CLONING_IN_PROGRESS()}</div> }
        {/* Clickable course name that navigates to course home page */}
        <div className='text-regular font-weight-bolder black course-name'>
          <NvTooltip
            placement='top'
            preventOverflow={false}
            enabled={!props.disabled}
            text={t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_CELLS.VISIT_JOURNEY()}
          >
            {props.disabled ? course.name : (
              <a
                target='_blank'
                href={goToJourneyUrl}
                rel='noopener noreferrer'
              >
                {course.name}
              </a>
            )}
          </NvTooltip>
        </div>
        <div className='catalog-id text-small font-weight-bold'>{course.catalogId}</div>
        <div className='created-by-name text-small font-weight-bold'>
          {t.COURSES.DASHBOARD_TABLE.CREATED_BY(course.creator?.fullName || 'NovoEd Team')}
        </div>
      </div>
    </div>
  );
};

const NumOfCoursesCell = (props: JourneyCellProps) => {
  const {
    course,
  } = props;

  const courseCloning = props.extraObject as JourneyCloning;
  const isBeingCloned = !_.isEmpty(courseCloning);
  const getExcludedCourses = () => courseCloning.settingsHash?.excludeCourses?.length || 0;
  const excludedCourses = isBeingCloned ? getExcludedCourses() : 0;
  const coursesCount = course.coursesCount - excludedCourses;

  return (
    <div
      className={`num-of-courses-cell${props.rowClasses ?? ''}`}
      css={props.serializedStyles}
      key={props.reactKey}
      {...props.divProps}
    >
      {t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_CELLS.NUM_OF_COLLECTIONS(course.collectionsCount)}
      <br />
      {t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_CELLS.NUM_OF_COURSES(coursesCount)}
    </div>
  );
};

const ReleaseDateCell = (props: JourneyCellProps) => {
  const { course } = props;
  return (
    <div className={`release-date-cell${props.rowClasses}`} css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      {course.releaseDate && moment(course.releaseDate).format('L')}
    </div>
  );
};

const CloseDateCell = (props: JourneyCellProps) => {
  const { course, extraObject } = props;
  const isBeingCloned = !_.isEmpty(extraObject);
  let closeDateText = '';

  if (course.closeDate && !isBeingCloned) {
    closeDateText = moment(course.closeDate).format('L');
  }

  if (course.isSelfPaced && course.enrollmentLimitInDays) {
    if (course.closeDate) {
      closeDateText += '<br />';
    }
    closeDateText += `${t.COURSES.DASHBOARD_TABLE.SELF_PACE_BASED()}<br/>${t.COURSES.DASHBOARD_TABLE.DAYS_ACCESS(course.enrollmentLimitInDays.toString())}`;
  }

  if (!course.closeDate && !course.enrollmentLimitInDays && course.isSelfPaced) {
    closeDateText = t.COURSES.DASHBOARD_TABLE.SELF_PACE_BASED();
  }

  return (
    <div className={`close-date-cell${props.rowClasses}`} css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      {/* eslint-disable-next-line react/no-danger */}
      <span dangerouslySetInnerHTML={{ __html: closeDateText }} />
    </div>
  );
};

const NumEnrolleesCell = (props: JourneyCellProps) => {
  const { course } = props;
  const courseCloning = props.extraObject as JourneyCloning;
  const isBeingCloned = !_.isEmpty(courseCloning);
  const numEnrolled = isBeingCloned ? '' : (course.numEnrolled ?? 0);
  const numEnrolledPopover = (
    <div className='bs4-row bs4-no-gutters page-title-xxs'>
      <div className='bs4-col-10 gray-3'>{t.COURSES.DASHBOARD_TABLE.NUM_UNENROLLED_BY_TEACHING_TEAM()}</div>
      <div className='bs4-col-2 text-right'>{course.numBanned}</div>
      <div className='w-100' />
      {' '}
      {/* For some reason this class does not get auto prefixed */}
      <div className='bs4-col-12'>&nbsp;</div>
      {' '}
      {/* empty row */}
      <div className='w-100' />
      <div className='bs4-col-10 gray-3'>{t.COURSES.DASHBOARD_TABLE.NUM_WITHDRAWN()}</div>
      <div className='bs4-col-2 text-right'>{course.numWithdrawn}</div>
    </div>
  );

  const popoverEnabled = !!course.numEnrolled && !props.disabled && course.numEnrolled !== 0;

  return (
    <div className={`num-enrollees-cell${props.rowClasses}`} css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      <NvPopover content={numEnrolledPopover} placement='top' preventOverflow={false} enabled={popoverEnabled} showOnHover>
        <span className={`num-enrollees-text font-weight-bold ${popoverEnabled ? 'text-primary' : ''}`}>{numEnrolled}</span>
      </NvPopover>
    </div>
  );
};

const CompletionRateCell = (props: JourneyCellProps) => {
  const { course } = props;

  const completionPct = Math.round(course?.learnersCompleted?.percentage ?? 0);
  const inProgressPct = Math.round(course?.learnersProgress?.percentage ?? 0);
  const remainingPct = 100 - inProgressPct - completionPct;

  let courseStatusStyle = '';

  const getCourseStatusText = () => {
    const courseDuration = moment(course.closeDate).diff(moment(course.releaseDate), 'hours');
    const remainingCourseHours = Math.max(moment(course.closeDate).diff(moment(), 'hours'), 0);
    const courseCompletionPct = 1 - (remainingCourseHours / courseDuration);

    // Course is closed
    if (course.closeDate !== null && moment(course.closeDate) < moment()) {
      return t.COURSES.DASHBOARD_TABLE.CLOSED();
    }
    // Course is yet to be released
    if (moment(course.releaseDate) > moment()) {
      const releaseDayCount = moment(course.releaseDate).diff(moment(), 'days');
      if (releaseDayCount === 0) {
        const releaseHourCount = moment(course.releaseDate).diff(moment(), 'hours');
        if (releaseHourCount === 0) {
          return t.COURSES.DASHBOARD_TABLE.RELEASING_IN_HOUR();
        }
        return t.COURSES.DASHBOARD_TABLE.RELEASING_IN_X_HOURS(releaseHourCount);
      }
      return t.COURSES.DASHBOARD_TABLE.RELEASING_IN_X_DAYS(releaseDayCount);
    }
    // Show 'Closing in X Days' if there's only 10% of the time remaining in a course
    if (course.closeDate && courseCompletionPct >= 0.9) {
      courseStatusStyle = 'text-primary';
      if (remainingCourseHours > 24) {
        const remainingCourseDays = moment(course.closeDate).diff(moment(), 'days');
        return t.COURSES.DASHBOARD_TABLE.CLOSING_IN_X_DAYS(remainingCourseDays);
      }
      if (remainingCourseHours === 0) {
        return t.COURSES.DASHBOARD_TABLE.CLOSING_IN_HOUR();
      }
      return t.COURSES.DASHBOARD_TABLE.CLOSING_IN_X_HOURS(remainingCourseHours);
    }
    // Normal 'in progress' status
    courseStatusStyle = 'text-primary';
    return t.COURSES.DASHBOARD_TABLE.IN_PROGRESS();
  };

  const courseStatusText = getCourseStatusText();

  // Calculates positions for the white divider bars between segments of the completion progress bars.
  // We make special case offets arouns a bar being placed at 1% or 99% because the white bars being drawin
  // the rounded borers at the edge breaks the aesthetic of a rounded bar
  const dividerAtPos = (pos: number) => {
    if (pos < 1) {
      return null;
    }
    if (pos === 1) {
      return `calc(${pos}% + 1px)`;
    }
    if (pos === 99) {
      return `calc(${pos}% - 1px)`;
    }
    if (pos < 99) {
      return `${pos}%`;
    }
    return null;
  };

  const firstDividerPos = dividerAtPos(completionPct) ?? dividerAtPos(completionPct + inProgressPct);
  const firstDividerStyle: React.CSSProperties = {};
  if (firstDividerPos) {
    firstDividerStyle.left = firstDividerPos;
  } else {
    firstDividerStyle.display = 'none';
  }

  const secondDividerPos = dividerAtPos(completionPct + inProgressPct);
  const secondDividerStyle: React.CSSProperties = {};

  if (secondDividerPos) {
    secondDividerStyle.left = secondDividerPos;
  } else {
    secondDividerStyle.display = 'none';
  }

  const completionCellContent = (
    <React.Fragment>
      <div className='completion-bar-area'>
        <div className='completion-bar'>
          <div className='completion-bar-part completed' style={{ width: `${completionPct}%` }} />
          <div className='divider first-divider' style={firstDividerStyle} />
          <div className='completion-bar-part in-progress' style={{ width: `${inProgressPct}%` }} />
          <div className='divider second-divider' style={secondDividerStyle} />
          <div className='completion-bar-part remaining' style={{ width: `${remainingPct}%` }} />
        </div>
        <div className={`status-text page-title-xxs font-weight-bold ${courseStatusStyle}`}>{courseStatusText}</div>
      </div>
      <div className={`completion-pct ${completionPct > 0 ? 'text-success' : ''}`}>
        {completionPct}
        %
      </div>
    </React.Fragment>
  );

  return (
    <div className={`completion-rate-cell${props.rowClasses}`} css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      {completionCellContent}
    </div>
  );
};

const OptionsCell = (props: JourneyCellProps) => (
  <JourneyRowOptions cellProps={props} key={props.reactKey} />
);

export default JourneyRow;
