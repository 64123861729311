import LearningCatalogModalHeader from '../components/learning-catalog/modal-header';
import LearningCatalogModalContent from '../components/learning-catalog/modal-content';

/* @ngInject */
export default function LearningCatalogController(
  $scope,
) {
  $scope.LearningCatalogModalHeader = LearningCatalogModalHeader;
  $scope.LearningCatalogModalContent = LearningCatalogModalContent;
}
