/* items passed in to the dropdown must contain an unique id property */
import omit from 'lodash/omit';
import { css } from '@emotion/react';
import { Controller, get, useFormContext } from 'react-hook-form';

import NvDropdown, {
  NvDropdownTextItem,
  NvDropdownButtonStyle,
  NvDropdownCustomItem,
  NvDropdownHeader,
  NvDropdownOption,
} from 'shared/components/inputs/nv-dropdown';
import { gray4 } from 'styles/global_defaults/colors';
import { halfSpacing } from 'styles/global_defaults/scaffolding';

export type NvFormDropdownItem = {
  id: number | string
  name: string
  label: string
  value: any,
};

type NvFormDropdownProps = {
  name: string
  items: NvDropdownOption[]
  title?: string
  disabled?: boolean
  className?: string
  onChange?: Function,
  withFormDefaultValue?: any,
  isSmallSize?: boolean, // intended for legacy features where text is smaller
  titleClass?: string
  maxWidth100?: boolean
};

const NvFormDropdown = (props: NvFormDropdownProps) => {
  const { setValue, watch } = useFormContext() ?? {};
  const formValue = get((watch?.()) ?? {}, props.name);

  let index;
  const items = props.items?.map((item, i) => {
    if (item.type === 'text' && item.id === formValue?.id) {
      index = i;
    }

    return {
      ...item,
      callback: () => onItemSelect(item, i),
    };
  });

  const onItemSelect = (item, idx) => {
    setValue?.(props.name, item, { shouldValidate: true, shouldDirty: true }); // set value in react hook form
    (props.items[idx] as NvDropdownTextItem | NvDropdownCustomItem).callback?.(item); // conditionally call item's callback handler if it exists
    props.onChange?.(item);
  };

  const styles = css`
    &.nv-form-dropdown {
      width: 100%;
      display: inline-block;
      min-width: 0px;
      .bs4-dropdown {
        background-color: white;

        .bs4-dropdown-menu {
          min-width: 100%;

          ${props.maxWidth100 && css`
            max-width: 100%;

            .bs4-dropdown-item {
              white-space: normal;
              word-break: break-word;
            }
          `}
        }
        ${props.disabled && css`
          background-color: #f4fbfb;
          cursor: default;
        `};
        .title {
          ${index == null && css`
            color: ${gray4};
          `}
          .icon {
            padding-left: ${halfSpacing}px;
          }
        }
      }
    }
  `;

  return (
    <div css={styles} className={`nv-form-dropdown ${props.className || ''}`}>
      <Controller
        name={props.name}
        defaultValue={props.withFormDefaultValue}
        render={({ field }) => (
          <NvDropdown
            showSelectedIndicator
            items={items}
            buttonStyle={props.isSmallSize ? NvDropdownButtonStyle.FORM_SMALL : NvDropdownButtonStyle.FORM}
            title={index != null ? (items[index] as NvDropdownTextItem)?.text : props.title}
            disabled={props.disabled}
            initialIndex={index}
            titleClass={props.titleClass}
            {...omit(field, ['ref'])}
          />
        )}
      />
    </div>
  );
};

export default NvFormDropdown;
