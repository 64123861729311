import { css } from '@emotion/react';
import { quarterSpacing, halfSpacing, standardSpacing, doubleSpacing } from 'styles/global_defaults/scaffolding';
import { info, gray1, gray2, gray3, gray7, gray6 } from 'styles/global_defaults/colors';
import { textSmallFontSize } from 'styles/global_defaults/fonts';

export default css`
  padding: ${halfSpacing}px ${standardSpacing}px;
  background-color: ${gray7};
  border-radius: 10px;
  min-height: 60px;
  display: flex;
  align-items: center;
  margin-bottom: ${standardSpacing}px;

  .content {
    display: none;

    & > * {
      display: inline-block;
    }

    .nv-text-input {
      width: 60px;
      margin: auto ${halfSpacing}px;
    }
  }

  .info {
    flex: 1 0 auto;
    text-align: right;
    color: ${gray2};
  }

  &.on {
    background-color: ${info};

    .content {
      display: flex;
      align-items: center;
      margin-top: -${quarterSpacing}px;
    }
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    align-self: stretch;

    .checkbox label {
      padding-left: ${doubleSpacing}px;
    }
  }

  .self-paced {
    margin-top: ${quarterSpacing}px;
    font-size: ${textSmallFontSize};
    color: ${gray1};
  }

  &.disabled {
    background-color: ${gray6};
    .info {
      color: ${gray3};
    }
  }
`;
