import React from 'react';
import { connect, useSelector } from 'react-redux';
import t from 'react-translate';

// schemas
import { RootState, CombinedCourse } from 'redux/schemas';
import { NTimelineExternalTool, TimelineExternalTool } from 'redux/schemas/models/external-tool';
import { ActivityType } from 'redux/schemas/models/activity';
import { ComponentKeyPluralized } from 'redux/schemas/models/lecture-component';

// actions
import { setActivityExpandedInAutomatedCommunications } from 'redux/actions/course-communications';

// selectors
import { getExternalTool, hasExternalToolGotCommunicationError } from 'redux/selectors/timeline';
import { isActivityExpandedInAutomatedCommunications } from 'redux/selectors/course-communications';

// components
import NvIcon from 'shared/components/nv-icon';
import NvStackedTab from 'shared/components/nv-stacked-tab';

import CommunicationCreateNewDropdown from 'communications/course_communications/components/communication-create-new-dropdown';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';
import { getCourseAliases, getCourse, getCurrentCourse } from 'redux/selectors/course';
import { CourseAliases } from 'redux/schemas/models/course';

type ExternalToolLectureComponentProps = {
  externalToolId: number
};

type StateProps = {
  externalTool: NTimelineExternalTool,
  hasCommunicationError: boolean,
};

const mapDispatchToProps = {
  setExpanded: setActivityExpandedInAutomatedCommunications,
};

const ACTIVITY_TYPE_ICON = {
  quiz: 'quiz',
  survey: 'survey',
  assignment: 'assignments',
  feedback: 'evaluation',
  video: 'video',
  reading: 'read',
  discussion: 'conversations',
  audio: 'audio',
};

const ExternalToolLectureComponent = (props: ExternalToolLectureComponentProps & StateProps & typeof mapDispatchToProps) => {
  const aliases = useSelector((state) => getCourseAliases(state));

  const currentCourse = useSelector((state) => getCurrentCourse(state));

  const isExpanded = useSelector((state) => isActivityExpandedInAutomatedCommunications(state, ComponentKeyPluralized.EXTERNAL_TOOL, props.externalToolId));

  let disabledText: string;
  if (!props.externalTool?.isTodo && !props.externalTool?.pointsConfiguration) {
    // External tools create trigger option need to be active only if
    // its marked as a todo or it got point configuration as its
    // completion cannot be tracked. If its not both of the above, disable it
    disabledText = currentCourse.gamificationEnabled
      ? t.COURSE_COMMUNICATIONS.TOOLTIP.CREATE_DISABLED({ pointsAlias: aliases.pointsAliases.pointsAlias })
      : t.COURSE_COMMUNICATIONS.TOOLTIP.CREATE_DISABLED_NO_POINTS();
  }

  return (
    <React.Fragment>
      <div className={`lecture-component-row ${isExpanded && props.externalTool?.communicationsCount > 0 && 'expanded-box'}`}>
        <NvIcon icon={ACTIVITY_TYPE_ICON[props.externalTool?.activityType]} size='smallest' />
        <div className='description'>{props.externalTool?.title}</div>
        <div className=''>
          <CommunicationCreateNewDropdown
            activityType={ActivityType.EXTERNAL_TOOL}
            activityId={props.externalToolId}
            disabledText={disabledText}
          />
        </div>
        { props.externalTool?.communicationsCount > 0 && (
          <NvStackedTab
            count={props.externalTool?.communicationsCount}
            hasError={props.hasCommunicationError}
            isExpanded={isExpanded}
            errorMessage={t.COURSE_COMMUNICATIONS.WARNINGS.ACTIVITY_COMMUNICATION()}
            onClick={() => props.setExpanded({
              type: ComponentKeyPluralized.EXTERNAL_TOOL,
              id: props.externalToolId,
              isExpanded: !isExpanded,
            })}
          />
        )}
      </div>
      {
        // Communications
        isExpanded && props.externalTool?.communications && props.externalTool.communications.map(communicationId => (
          <CommunicationItem key={communicationId.toString()} communicationId={communicationId} />
        ))
      }
    </React.Fragment>
  );
};

const ConnectedExternalToolLectureComponent = connect(
  (state: RootState, ownProps: ExternalToolLectureComponentProps) => ({
    externalTool: getExternalTool(state, ownProps.externalToolId),
    hasCommunicationError: hasExternalToolGotCommunicationError(state, ownProps.externalToolId),
  }),
  mapDispatchToProps,
)(ExternalToolLectureComponent);

export default ConnectedExternalToolLectureComponent;
