import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';

import AutosizeInput from 'react-input-autosize';
import * as yup from 'yup';
import NvIcon from 'shared/components/nv-icon';
import useClickOutside from 'shared/hooks/use-click-outside';
import ClickableContainer from 'components/clickable-container';
import NvPopover from './nv-popover';
import ValidationErrorMessage from './inputs/validation-error-message';

type NvPillWithInputProps = {
  value: string,
  onRemove: () => void
  onChange?: (value) => void
  disabled?: boolean
  editModeClassName?: string
  onEdit?: (newValue: string) => void
  canEdit?: boolean;
  onAdd?: () => void
  isAdded?: boolean
  className?: string
  placeholder?: string
  validationSchema?: yup.StringSchema,
  maxLength?: number,
  errorTitle?: string
  onError?: (error: string) => void
  dataQaRemove?: string
  dataQaInput?: string
  pendoTagRemove?: string
};

const NvPillWithInput = ({
  value,
  onRemove,
  onChange,
  disabled,
  editModeClassName,
  onEdit,
  canEdit = false,
  onAdd,
  isAdded,
  className,
  placeholder,
  validationSchema,
  maxLength,
  errorTitle,
  onError,
  dataQaRemove,
  dataQaInput,
  pendoTagRemove,
}: NvPillWithInputProps) => {
  const [error, setError] = useState();
  const [newValue, setNewValue] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    if (isEditMode) {
      setNewValue(value);
    }
  }, [isEditMode, value]);

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (!!value.trim() && !error) {
        if (isEditMode) {
          if (newValue.trim()) {
            onEdit(newValue);
            setIsEditMode(false);
          }
          return;
        }
        onAdd();
      } else {
        e.preventDefault();
      }
    }
  };

  const handleClose = () => {
    if (isEditMode) {
      setIsEditMode(false);
      setError(null);
      return;
    }
    onRemove();
  };

  const handleOnError = (errorMessage) => {
    if (onError) {
      onError(errorMessage);
    }
  };

  const handleChange = (val) => {
    onChange?.(val);
    if (validationSchema) {
      validationSchema.validate(val).then(() => {
        setError(null);
        handleOnError('');
      }).catch((err) => {
        setError(err?.message);
        handleOnError(err?.message);
      });
    }
  };

  const handleClickToEdit = () => {
    if (canEdit) {
      setIsEditMode(true);
    }
  };

  useClickOutside(inputRef, () => {
    if (!!value.trim() && !error) {
      if (isEditMode) {
        if (newValue.trim()) {
          onEdit(newValue);
          setIsEditMode(false);
        }
        return;
      }
      onAdd?.();
    }
  });

  return (
    <NvPopover
      className='nv-text-input'
      placement='top'
      overlayStyles={css`
        max-width: 200px;
        .text-danger {
          font-weight: 600;
        }`}
      preventOverflow
      content={<ValidationErrorMessage text={error} title={errorTitle} />}
      show={!!error}
    >
      <div
        className={`d-flex align-items-center border ${isEditMode ? editModeClassName : ''} ${className} ${error ? 'border-danger' : ''}`}
        ref={inputRef}
      >
        {(!isAdded || isEditMode) ? (
          <AutosizeInput
            maxLength={maxLength ?? 50}
            inputStyle={{ border: 'none', background: 'transparent', minWidth: 46, maxWidth: 210, outline: 'none', marginRight: 10 }}
            style={{ background: 'none', minWidth: 59, maxWidth: 220 }}
            name='form-field-name'
            autoFocus
            autoComplete='off'
            placeholder={placeholder}
            value={newValue}
            onChange={(event) => {
              setNewValue(event.target.value);
              handleChange(event.target.value);
            }}
            disabled={disabled}
            onKeyDown={(event) => onKeyDown(event)}
            data-qa={dataQaInput}
          />
        ) : (
          <ClickableContainer
            onClick={handleClickToEdit}
          >
            <p className='mb-0 text-break text-justify'>{value}</p>
          </ClickableContainer>
        )}
        <NvIcon
          icon='close'
          onClick={handleClose}
          size='xss-smallest'
          className='ml-2 gray-1'
          data-qa={dataQaRemove}
          pendo-tag-name={pendoTagRemove}
        />
      </div>
    </NvPopover>
  );
};

export default NvPillWithInput;
