import store from 'redux/store';
import { updateLectureComponentFromAngular } from 'redux/actions/lecture-components';
import { ComponentType, LectureComponent } from 'redux/schemas/models/lecture-component';
import QuizModelService from 'quizzes/services/quiz-model';

type AngularQuizModel = InstanceType<ReturnType<typeof QuizModelService>> & {
  unsubmittedSubmission: any,
  submission: any,
  progress: any,
  id: number,
};

type QuizlikeLectureComponent = Omit<(LectureComponent<ComponentType.QUIZ> | LectureComponent<ComponentType.SURVEY>), 'quiz'> & {
  /** An instance of the QuizModel object in quiz-model.js */
  quiz: AngularQuizModel,
}

export const saveQuiz = (lectureComponent: QuizlikeLectureComponent) => {
  const currentSubmission = getCurrentSubmission(lectureComponent);

  if (lectureComponent.quiz.progress === 'not_started') {
    lectureComponent.quiz.progress = 'in_progress';
  }

  if (currentSubmission) {
    return lectureComponent.quiz.persist(currentSubmission.id, true)
      .then((submission) => {
        lectureComponent.quiz.unsubmittedSubmission = submission;

        store.dispatch(updateLectureComponentFromAngular({
          quiz: {
            unsubmittedSubmission: submission,
            id: lectureComponent.quiz.id,
          },
        }));

        return submission;
      });
  }
  return lectureComponent.quiz.persist(null, true)
    .then((submission) => {
      lectureComponent.quiz.unsubmittedSubmission = submission;
      return submission;
    });
};


const submitQuiz = (lectureComponent: QuizlikeLectureComponent) => {
  const currentSubmission = getCurrentSubmission(lectureComponent);

  if (lectureComponent.quiz.progress !== 'completed') {
    lectureComponent.quiz.progress = 'completed';
  }

  if (currentSubmission) {
    return lectureComponent.quiz.persist(currentSubmission.id, false)
      .then((submission) => {
        lectureComponent.quiz.unsubmittedSubmission = {};
        lectureComponent.quiz.submission = submission;
        setQuizResponses(lectureComponent.quiz, submission.responses);

        store.dispatch(updateLectureComponentFromAngular({
          quiz: {
            submission,
            id: lectureComponent.quiz.id,
          },
        }));

        return submission;
      });
  }
  return lectureComponent.quiz.persist(null, false)
    .then((submission) => {
      lectureComponent.quiz.submission = submission;
      setQuizResponses(lectureComponent.quiz, submission.responses);
      return submission;
    });
};

function getCurrentSubmission(lectureComponent) {
  return lectureComponent.quiz.unsubmittedSubmission || lectureComponent.quiz.submission;
}

function setQuizResponses(quiz: AngularQuizModel, responses) {
  quiz.setResponses(responses);
}

export default submitQuiz;
