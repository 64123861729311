import LearnerEnrollmentModal from '../components/enrollment_modal/learner-enrollment-modal';

/* @ngInject */
export default function LearnerEnrollmentController(
  $state,
  $scope,

  CurrentUserManager,
  OrgLevelProfileManager,
) {
  this.userId = $state.params.userId;
  $scope.CurrentUserManager = CurrentUserManager;
  $scope.OrgLevelProfileManager = OrgLevelProfileManager;
  $scope.LearnerEnrollmentModal = LearnerEnrollmentModal;
}
