import React from 'react';

import t from 'react-translate';
import { MyAccountCourse } from 'redux/schemas/models/my-account';
import { InstitutionLogoSize } from 'redux/schemas/models/institution';
import OfferingBrandingHeader from 'offerings/components/offering-branding-header';
import { config } from '../../../config/pendo.config.json';

type Props = {
  course: MyAccountCourse
  setHeaderDraft: Function,
};

const CourseFormHeader = ({
  course,
  setHeaderDraft,
}: Props) => {
  const [value, setValue] = React.useState({
    name: course.name ?? '',
    logo: course.logo ? {
      url: course.logo.url,
      size: course.logoSize as InstitutionLogoSize,
    } : null,
    background: course.headerBackground ? {
      url: course.headerBackground,
    } : null,
    brandColor: course.headerColor,
    fontColor: course.nameFontColor,
  });

  const handleBrandingHeaderChange = (data) => {
    setValue(data);

    setHeaderDraft({
      name: data.name,
      deleteLogo: !data.logo,
      headerColor: data.brandColor,
      logo: data.logo?.file ?? null,
      nameFontColor: data.fontColor,
      deleteHeaderBackground: !data.background,
      headerBackground: data.background?.file ?? null,
      logoSize: data.logo?.size ?? InstitutionLogoSize.SMALL,
    });
  };

  return (
    <OfferingBrandingHeader
      editable
      value={value}
      onChange={handleBrandingHeaderChange}
      dataQa={{ title: config.pendo.courseForm.title }}
      updateLogoText={t.COURSES.FORM.HEADER.UPDATE_LOGO()}
      logoPickerText={t.COURSES.FORM.HEADER.UPDATE_LOGO()}
      logoTooltipText={t.COURSES.FORM.HEADER.LOGO_TOOLTIP()}
      namePlaceholder={course.isProgram
        ? t.COURSES.FORM.PROGRAM_TITLE()
        : t.COURSES.FORM.COURSE_TITLE('isProgram')}
      backgroundImagePickerTooltipText={t.COURSES.FORM.HEADER.BACKGROUND_IMAGE_TOOLTIP()}
    />
  );
};

export default CourseFormHeader;
