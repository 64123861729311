import React from 'react';
import t from 'react-translate';

// Types
import { useFormContext, useWatch } from 'react-hook-form';

// Components
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import { config } from '../../../config/pendo.config.json';

type ZoomCheckbox = {
  label: string;
  name: string;
  condition?: string;
  disabled?: boolean;
  className?: string;
};

const checkboxes: ZoomCheckbox[] = [
  { label: 'AUTORECORD', name: 'recordMeetingAutomatically' },
  { label: 'AUTOMATICALLY_UPLOAD_RECORDING', name: 'automaticallyUploadRecording', condition: 'recordMeetingAutomatically', className: 'ml-5' },
  { label: 'PARTICIPANT_VIDEO', name: 'participantVideo' },
  { label: 'MUTE_PARTICIPANTS', name: 'muteUponEntry' },
  { label: 'ENABLE_EMAIL_CONFIRMATION', name: 'enableEmailConfirmation' },
  { label: 'TRACK_ATTENDANCE', name: 'trackAttendance', disabled: true },
];

type ZoomSettingsProps = {
  disabled?: boolean
};

const ZoomSettings = (props: ZoomSettingsProps) => {
  const { disabled } = props;
  const { control } = useFormContext();
  const settings = useWatch({ control, name: 'settings' });

  return (
    <React.Fragment>
      {checkboxes.map((checkbox: ZoomCheckbox) => (
        <React.Fragment>
          {(!checkbox.condition || settings[checkbox.condition]) && (
            <NvCheckbox
              label={t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL[checkbox.label]()}
              disabled={checkbox.disabled ?? disabled}
              name={`settings.${checkbox.name}`}
              className={checkbox.className}
              dataQa={config.pendo.liveEvent.liveEventSession.zoom[checkbox.name]}
              withForm
            />
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default ZoomSettings;
