/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Result } from 'redux/schemas/api';
import { ActivityProgress } from 'redux/schemas/models/activity';

interface ExternalToolParams {
  catalogId: string,
  id: number,
  externalTool: any,
  cb?: (externalTool: any, isCheckingProgress?: boolean) => void,
}

interface ExternalToolActivityParams {
  id: number,
  progress?: ActivityProgress,
  activityType?: string,
}

async function markAsDone(params: ExternalToolParams) {
  return axios.post<Result<any>>(`${params.catalogId}/external_tool_submissions/mark_as_done/${params.id}.json`)
    .then(response => response.data);
}

async function checkProgress(params: ExternalToolParams) {
  return axios.post<Result<any>>(`${params.catalogId}/external_tool_submissions/check_external_tool_status/${params.id}.json`)
    .then(response => response.data);
}

export const markLectureComponentAsDone = createAsyncThunk(
  'MARK_LECTURE_COMPONENT_AS_DONE',
  markAsDone,
);

export const checkLectureComponentProgress = createAsyncThunk(
  'CHECK_LECTURE_COMPONENT_AS_DONE',
  checkProgress,
);

export const updateActivityProgress = createAction<ExternalToolActivityParams>('UPDATE_ACTIVITY_PROGRESS');
