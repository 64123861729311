/* @ngInject */
export default function CountdownTimerManager(
  $interval,
  moment,
  CountdownTimerModel,
) {
  const manager = {
    initialize,
    stopTimer,
    hideTimer,
  };

  function initialize(totalDuration, timeRemaining, timeInterval, timeUpCallback) {
    manager.timer = new CountdownTimerModel({
      duration: totalDuration, timeRemaining, timeInterval, timeUpCallback,
    });
  }

  function stopTimer() {
    manager.timer.stopTimer();
  }

  function hideTimer() {
    manager.timer.hideTimer();
  }

  return manager;
}
