import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  forwardRef,
} from 'react';
import { isEmpty } from 'underscore';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import t from 'react-translate';
import Button from 'react-bootstrap/Button';

import { AngularServicesContext } from 'react-app';
import usePreventLecturePageNavigation from 'lecture_pages/hooks/use-prevent-lecture-page-navigation';

import { useAppDispatch } from 'redux/store';
import { createComment, updateComment } from 'redux/actions/comments';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { getCurrentUser } from 'redux/selectors/users';
import { createReply, updateReply } from 'redux/actions/replies';
import { Comment } from 'redux/schemas/models/comment';

import { parseMentionables } from 'froala/helpers/tribute-options';
import NvUserAvatar from 'components/nv-user-avatar';
import { NvFroala } from 'froala/components/nv-froala';
import { FroalaViewMode } from 'froala/helpers/nv-froala-constants';
import { config } from '../../../../config/pendo.config.json';
import { CommentsContainerContext } from './comments-container';


type CommentFormProps = {
  catalogId: string,
  ownerType: string,
  ownerId: number,
  comment?: Comment, // Only in editing mode
  value?: string, // Only in editing mode
  parentCommentId?: number,
  isReply?: boolean,
  onComment: () => void,
  onCancel: () => void,
};

const CommentForm = forwardRef<HTMLInputElement, CommentFormProps>((
  { catalogId, ownerType, ownerId, comment, parentCommentId, onComment, onCancel, value, isReply },
  froalaRef,
) => {
  const dispatch = useAppDispatch();

  const validationSchema = yup.object().shape({
    commentBody: yup.string().required(),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      commentBody: (comment?.body || value) ?? '',
    },
  });
  const { handleSubmit, formState, getValues, watch } = methods;
  const { isValid } = formState;
  const commentBody = watch('commentBody');

  const stateChangeRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const { $scope } = useContext(AngularServicesContext);
  const { mentionableUsers } = useContext(CommentsContainerContext);
  const currentUser = useSelector((state) => getCurrentUser(state));

  const checkUnsavedChanges = () => {
    if (isEmpty(commentBody) && isEmpty(comment?.body)) {
      return false;
    }

    return commentBody !== comment?.body;
  };

  const checkUnsavedChangesRef = React.useRef<Function>();
  checkUnsavedChangesRef.current = checkUnsavedChanges;

  useEffect(() => {
    stateChangeRef.current = $scope.StateManager.registerStateChangeStart(
      checkUnsavedChangesRef.current,
      'shared/templates/modal-navigate-away.html',
      'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY',
    );

    const { current: deregister } = stateChangeRef;

    return () => {
      deregister();
    };
  }, [$scope.StateManager]);

  usePreventLecturePageNavigation(checkUnsavedChanges);

  const hasUnsavedChanges = () => {
    const commentValue = getValues().commentBody;
    let isDirty = commentValue && !!commentValue.length;
    if (comment) {
      isDirty = commentValue !== comment?.body;
    }
    return isDirty && !loading;
  };

  const onClickCancel = () => {
    if (hasUnsavedChanges()) {
      dispatch(openConfirmationDialog({
        bodyText: t.FORM.UNSAVED_CHANGES.ARE_YOU_SURE(),
        confirmText: t.FORM.CLOSE_ANYWAY(),
        cancelText: t.FORM.DO_NOT_CLOSE(),
        icon: 'warning',
        onConfirm: onCancel,
      }));
    } else {
      onCancel?.();
    }
  };

  const onSubmitComment = (data) => {
    setLoading(true);
    if (comment) {
      dispatch(
        parentCommentId
          ? updateReply({
            catalogId,
            replyId: comment.id,
            comment: commentBody,
          })
          : updateComment({
            catalogId,
            commentId: comment.id,
            body: data.commentBody,
            mentionedIds: parseMentionables(data.commentBody, mentionableUsers),
          }),
      ).then(res => {
        onComment?.();
      }).finally(() => setLoading(false));
    } else {
      dispatch(
        parentCommentId
          ? createReply({
            catalogId,
            commentId: parentCommentId,
            comment: commentBody,
            reply: isReply,
            reportId: ownerType === 'report' ? ownerId : null,
          })
          : createComment({
            catalogId,
            ownerId,
            ownerType,
            body: data.commentBody,
            mentionedIds: parseMentionables(data.commentBody, mentionableUsers),
          }),
      ).then(res => {
        onComment?.();
      }).finally(() => setLoading(false));
    }
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmitComment)}
        >
          <div className='d-flex'>
            <NvUserAvatar
              displayName={!parentCommentId}
              displayRoleBadge={false}
              alignNameRight={false}
              user={currentUser}
              size='sm'
              borderType='round'
              className='comment-avatar'
              pendoTagName={config.pendo.userProfiles.openProfile}
            />
            <div className='w-100'>
              <NvFroala
                preset={parentCommentId ? FroalaViewMode.AIR : FroalaViewMode.INLINE}
                ref={froalaRef}
                withForm
                name='commentBody'
                className='comment-froala p-4 bg-white'
                placeholder={t.DISCUSSIONS.POST_COMMENT()}
                mentionableUsers={mentionableUsers}
                ariaLabel={`${t.DISCUSSIONS.COMMENT()} ${t.FROALA.BODY_PLACEHOLDER()}`}
              />
              <div className='buttons d-flex justify-content-center my-4'>
                <Button
                  variant='secondary'
                  size='sm'
                  className='cancel mx-1'
                  onClick={onClickCancel}
                >
                  {t.FORM.CANCEL()}
                </Button>
                <Button
                  type='submit'
                  variant='primary'
                  size='sm'
                  disabled={!isValid || loading}
                  data-qa={config.pendo.discussionAnywhere.comment}
                  className='mx-1'
                >
                  {t.DISCUSSIONS.COMMENT()}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
});

export default CommentForm;
