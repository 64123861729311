/* @ngInject */
export default function TimelineLectureSectionModelService(

  _,
  TimelineLecturePageModel,
  TimelineLectureSubsectionModel,
  TimelinesResources,
) {
  const TimelineLectureSectionModel = function (attributes, timeline, course, isAdmin) {
    // Section can have LP and/or subsection  as children
    const _this = this;

    _this.timeline = timeline;
    _this.course = course;
    _.extend(_this, attributes);

    /** Public Data * */

    /** Public Functions * */
    _.extend(_this, {
      // full view
      getLecturePage,
      getFirstLecturePage,
      getAllItems,
      // todo view
      getAllTodoItems,
      // point view
      getAllPointsReceivedItems,
      // lecturepage view
      resetCurrent,

      getAllLecturePages,
      hasLecturePages,
      hasAnyActivities,
      hasDeadlines,
      updateReleaseDate,
      bulkUpdateActivityDeadline,
      addLecturePage,
      insertLecturePageAfter,
      adoptChildren,
      removeLecturePage,
      removeLectureSubsection,
      updateLecturePage,
      updateLecturePageAttribute,
      markUnread,
      removeComponent,
      updateComponentPointsAndProgress,
      updateComponentProgress,

      cancelTimeouts,
      isPrereqCompleted,
      createReorderDraft,
      saveReorderDraft,
      updateSubsectionCompletionDetails,
    });

    preprocess();
    /** Function Declarations * */
    /* Used Publicly */
    // Returns the first parent lecture page that has the current item
    function getLecturePage(lecturePageId) {
      return _.findWhere(getAllLecturePages(), { id: lecturePageId });
    }

    // Returns the first lecture page
    function getFirstLecturePage() {
      if (_this.lecturePages.length) {
        return _.first(_this.lecturePages);
      }
      if (_this.lectureSubsections.length) {
        return _.first(_this.lectureSubsections).getFirstLecturePage();
      }
      return null;
    }

    function getAllItems() {
      const allItems = _.flatten(_.map(_this.lecturePages, (lecturePage) => lecturePage.getAllComponents()));

      return allItems.concat(_.flatten(_.map(_this.lectureSubsections, (lectureSubsection) => lectureSubsection.getAllItems())));
    }

    function getAllTodoItems() {
      const todoItems = _.flatten(_.map(_this.lecturePages, (lecturePage) => lecturePage.getAllTodoItems()));

      return todoItems.concat(_.flatten(_.map(_this.lectureSubsections, (lectureSubsection) => lectureSubsection.getAllTodoItems())));
    }

    function getAllPointsReceivedItems() {
      const pointItems = _.flatten(_.map(_this.lecturePages, (lecturePage) => lecturePage.getAllPointsReceivedItems()));

      return pointItems.concat(_.flatten(_.map(_this.lectureSubsections, (lectureSubsection) => lectureSubsection.getAllPointsReceivedItems())));
    }

    function resetCurrent() {
      _.each(_this.lectureSubsections, (lectureSubsection) => {
        lectureSubsection.resetCurrent();
      });
      _.each(_this.lecturePages, (lecturePage) => {
        lecturePage.resetCurrent();
      });
    }

    function getAllLecturePages() {
      return _this.lecturePages.concat(_.flatten(_.map(_this.lectureSubsections, (lectureSubsection) => lectureSubsection.getAllLecturePages())));
    }

    function hasLecturePages() {
      return getAllLecturePages().length;
    }

    function hasAnyActivities() {
      return _.some(getAllLecturePages(), (lp) => lp.hasAnyActivities);
    }

    function hasDeadlines() {
      return _.some(getAllLecturePages(), (lp) => lp.hasDeadlines);
    }

    function updateReleaseDate(releaseDate) {
      return TimelinesResources.bulkUpdateReleaseDates({ catalogId: _this.course.catalogId, lectureSectionId: _this.id }, { releaseDate }).$promise
        .then((response) => {
          const serverLectureSection = response.result.updatedObject;
          const affectedLecturePages = response.result.dependentUpdates;

          const allExisitingLecturePages = getAllLecturePages();
          const serverLecturePages = serverLectureSection.lecturePages.concat(_.flatten(serverLectureSection.lectureSubsections.map((subsection) => subsection.lecturePages)));

          _.each(serverLecturePages, (serverLecturePage) => {
            const exisitingLp = _.findWhere(allExisitingLecturePages, { id: serverLecturePage.id });

            if (exisitingLp) {
              exisitingLp.updateLecturePageAttribute(exisitingLp.id, 'releaseDate', releaseDate);
              exisitingLp.updateLecturePageAttribute(exisitingLp.id, 'hasStructuralIssues', serverLecturePage.hasStructuralIssues);
              exisitingLp.updateLecturePageAttribute(exisitingLp.id, 'hasTimelineIssues', serverLecturePage.hasTimelineIssues);
            }
          });

          _this.timeline.updateLecturePagesWarnings(affectedLecturePages);

          return serverLectureSection;
        });
    }

    function bulkUpdateActivityDeadline(deadline, hardDeadline) {
      return TimelinesResources.bulkUpdateDeadline({ catalogId: _this.course.catalogId, lectureSectionId: _this.id }, { deadline, hardDeadline }).$promise
        .then((response) => {
          const serverLectureSection = response.result;

          const allExisitingLecturePages = getAllLecturePages();
          const serverLecturePages = response.result.lecturePages.concat(_.flatten(serverLectureSection.lectureSubsections.map((subsection) => subsection.lecturePages)));

          _.each(serverLecturePages, (serverLecturePage) => {
            const exisitingLp = _.findWhere(allExisitingLecturePages, { id: serverLecturePage.id });

            if (exisitingLp) {
              exisitingLp.setActivityDeadlines(deadline, hardDeadline);
              exisitingLp.updateLecturePageAttribute(exisitingLp.id, 'hasStructuralIssues', serverLecturePage.hasStructuralIssues);
              exisitingLp.updateLecturePageAttribute(exisitingLp.id, 'hasTimelineIssues', serverLecturePage.hasTimelineIssues);
            }

            _this.timeline.updateDependentActivitiesReleaseDate(exisitingLp);
          });

          return response.result;
        });
    }

    // only for admins
    function removeLecturePage(lecturePageId) {
      let removed = false;
      removed = _.some(_this.lectureSubsections, (lectureSubsection) => lectureSubsection.removeLecturePage(lecturePageId));

      if (!removed) {
        removed = _.some(_this.lecturePages, (lecturePage, index) => {
          if (lecturePage.id === lecturePageId) {
            _this.lecturePages.splice(index, 1);
            return true;
          }
          return false;
        });

        if (removed) {
          _.each(_this.lecturePages, (lecturePage, index) => {
            lecturePage.index = index;
          });
        }
      }

      return removed;
    }

    // Removes a subsection and returns that deleted subsection as an array of one element.
    function removeLectureSubsection(lectureSubsectionId) {
      const index = _.findIndex(_this.lectureSubsections, { id: lectureSubsectionId });
      const subsectionDeleted = _this.lectureSubsections.splice(index, 1);
      _.each(_this.lectureSubsections, (lectureSubsection, lsIndex) => {
        lectureSubsection.index = lsIndex;
      });
      return subsectionDeleted;
    }

    function insertLecturePageAfter(lecturePageId, lecturePageToInsert) {
      const index = _.findIndex(_this.lecturePages, { id: lecturePageId });

      if (index > -1) {
        _this.lecturePages.splice(index + 1, 0, lecturePageToInsert);
        lecturePageToInsert.lectureSection = _this;

        _.each(_this.lecturePages, (lecturePage, lpIndex) => {
          lecturePage.index = lpIndex;
        });

        return true;
      }

      return _.some(_this.lectureSubsections, (lectureSubsection) => lectureSubsection.insertLecturePageAfter(lecturePageId, lecturePageToInsert));
    }

    // When Remove Section Title is called
    function adoptChildren(originSection, adoptAsDirectChildren = false) {
      if (originSection.lecturePages.length) {
        if (adoptAsDirectChildren) {
          _this.lecturePages.push(...originSection.lecturePages);
          _.each(_this.lecturePages, (lp, index) => {
            lp.index = index;
          });
        } else if (_this.lectureSubsections.length > 0) {
          _.last(_this.lectureSubsections).lecturePages.push(...originSection.lecturePages);
          _.each(_.last(_this.lectureSubsections).lecturePages, (lp, index) => {
            lp.index = index;
          });
        } else {
          _this.lecturePages.push(...originSection.lecturePages);
          _.each(_this.lecturePages, (lp, index) => {
            lp.index = index;
          });
        }
      }

      if (originSection.lectureSubsections.length) {
        _this.lectureSubsections.push(...originSection.lectureSubsections);
        _.each(_this.lectureSubsections, (lectureSubsection, index) => {
          lectureSubsection.index = index;
        });
      }
    }

    function addLecturePage(newLecturePage) {
      _this.lecturePages.splice(newLecturePage.index, 0, newLecturePage);
      _.each(_this.lecturePages, (lecturePage, index) => {
        lecturePage.index = index;
      });
    }


    function updateLecturePage(lecturePageId) {
      return _.map(getAllLecturePages(), (lecturePage) => lecturePage.updateLecturePage(lecturePageId));
    }

    function updateLecturePageAttribute(lecturePageId, attribute, value) {
      _.each(getAllLecturePages(), (lecturePage) => {
        lecturePage.updateLecturePageAttribute(lecturePageId, attribute, value);
      });
    }

    function markUnread(lecturePageId) {
      _.each(getAllLecturePages(), (lecturePage) => {
        lecturePage.markUnread(lecturePageId);
      });
    }

    function removeComponent(lecturePageId, componentType, componentId) {
      _.each(getAllLecturePages(), (lecturePage) => {
        lecturePage.removeComponent(lecturePageId, componentType, componentId);
      });
    }

    function updateComponentPointsAndProgress(lecturePageId, componentType, componentId, pointsReceived, totalPoints, progress) {
      _.each(getAllLecturePages(), (lecturePage) => {
        lecturePage.updateComponentPointsAndProgress(lecturePageId, componentType, componentId, pointsReceived, totalPoints, progress);
      });
    }

    function updateComponentProgress(lecturePageId, componentType, componentId, progress) {
      _.each(getAllLecturePages(), (lecturePage) => {
        lecturePage.updateComponentProgress(lecturePageId, componentType, componentId, progress);
      });
    }

    function cancelTimeouts() {
      _.each(getAllLecturePages(), (lecturePage) => {
        lecturePage.cancelTimeouts();
      });
    }

    function isPrereqCompleted(prereq) {
      return _.some(getAllLecturePages(), (lecturePage) => lecturePage.isPrereqCompleted(prereq));
    }

    function createReorderDraft() {
      _this.lecturePagesReorderDraft = _this.lecturePages ? [..._this.lecturePages] : [];

      _this.lectureSubsectionsReorderDraft = _this.lectureSubsections ? [..._this.lectureSubsections] : [];
      _.each(_this.lectureSubsectionsReorderDraft, (lectureSubsection) => lectureSubsection.createReorderDraft());
    }

    function saveReorderDraft() {
      _this.lecturePages = _this.lecturePagesReorderDraft;

      _this.lectureSubsections = _this.lectureSubsectionsReorderDraft;
      _.each(_this.lectureSubsections, (lectureSubsection, index) => {
        lectureSubsection.index = index;
        lectureSubsection.saveReorderDraft();
      });
    }

    function updateSubsectionCompletionDetails() {
      _.each(_this.lectureSubsections, (lectureSubsection) => lectureSubsection.updateSubsectionCompletionDetails());
    }

    /* Used Privately */
    function preprocess() {
      _this.lectureSubsections = _.map(_this.lectureSubsections, (lectureSubsection) => new TimelineLectureSubsectionModel(lectureSubsection, _this, _this.timeline, _this.course, isAdmin));

      _this.lecturePages = _.map(_this.lecturePages, (lecturePage) => new TimelineLecturePageModel(lecturePage, _this, _this.course, isAdmin));

      _this.type = 'LectureSection';
    }
  };

  return TimelineLectureSectionModel;
}
