import { css } from '@emotion/react';
import moment from 'moment';
import t from 'react-translate';
import { connect } from 'react-redux';
import { useState, useEffect, useContext } from 'react';
import { RootState } from 'redux/schemas';
import {
  getExportDetails,
  getExportData,
  getExportFileList,
  addDownloadFile,
} from 'redux/actions/license';
import {
  EnrollmentPeriod,
  ExportDetails,
  ExportTypes,
  ExportFile,
  ExportStatus,
} from 'redux/schemas/models/license';
import { getCurrentOrgCreationDate } from 'redux/selectors/licenses';
import PusherService from 'shared/services/pusher-service';
import { AngularServicesContext } from 'react-app';
import { halfSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import cloneDeep from 'lodash/cloneDeep';
import { Button } from 'react-bootstrap';
import NvIcon from 'shared/components/nv-icon';

type StateProps = {
  currentInstitutionId: number,
  orgCreationDate: moment.Moment,
  exportDetails: ExportDetails,
  exportFileList: ExportFile[],
};

type OwnProps = {
  periodType: EnrollmentPeriod,
  periodStart: moment.Moment,
  periodEnd: moment.Moment,
  exportType: ExportTypes,
};

const actions = {
  getExportDetails,
  getExportData,
  getExportFileList,
  addDownloadFile,
};

const getExportAlertHeaderText = (period: EnrollmentPeriod, startDate?: moment.Moment, endDate?: moment.Moment, type?: string) => {
  switch (period) {
    case EnrollmentPeriod.CURRENT_CONTRACT_PERIOD:
      return t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT[type].SUCCESS.HEADER_CURRENT_CONTRACT_PERIOD();
    case EnrollmentPeriod.CUSTOM_DATE_RANGE:
      return t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.USER_DATA.SUCCESS.HEADER_DATE_RANGE(endDate.format('MM/DD/YYYY'), startDate.format('MM/DD/YYYY'));
    case EnrollmentPeriod.PAST_12_MONTHS:
      return t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT[type].SUCCESS.HEADER_PAST_MONTHS(12);
    case EnrollmentPeriod.PAST_30_DAYS:
      return t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT[type].SUCCESS.HEADER_PAST_DAYS(30);
    case EnrollmentPeriod.PAST_6_MONTHS:
      return t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT[type].SUCCESS.HEADER_PAST_MONTHS(6);
    case EnrollmentPeriod.SINCE_ORG_CREATION:
      return t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.USER_DATA.SUCCESS.HEADER_ORG_CREATION_DATE(startDate.format('MM/DD/YYYY'));
    default:
      return '';
  }
};

const getEnrollmentExportAlertHeaderText = (period: EnrollmentPeriod, startDate?: moment.Moment, endDate?: moment.Moment) => {
  switch (period) {
    case EnrollmentPeriod.CURRENT_CONTRACT_PERIOD:
      return t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.ENROLLMENT_DATA.SUCCESS.HEADER_CURRENT_CONTRACT_PERIOD();
    case EnrollmentPeriod.CUSTOM_DATE_RANGE:
      return t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.ENROLLMENT_DATA.SUCCESS.HEADER_DATE_RANGE(endDate.format('MM/DD/YYYY'), startDate.format('MM/DD/YYYY'));
    case EnrollmentPeriod.PAST_12_MONTHS:
      return t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.ENROLLMENT_DATA.SUCCESS.HEADER_PAST_MONTHS(12);
    case EnrollmentPeriod.PAST_30_DAYS:
      return t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.ENROLLMENT_DATA.SUCCESS.HEADER_PAST_DAYS(30);
    case EnrollmentPeriod.PAST_6_MONTHS:
      return t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.ENROLLMENT_DATA.SUCCESS.HEADER_PAST_MONTHS(6);
    case EnrollmentPeriod.SINCE_ORG_CREATION:
      return t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.ENROLLMENT_DATA.SUCCESS.HEADER_ORG_CREATION_DATE(startDate.format('MM/DD/YYYY'));
    default:
      return '';
  }
};

export const ExportButton = (props: StateProps & typeof actions & OwnProps) => {
  const { $uibModal } = useContext(AngularServicesContext);
  const { periodType, periodStart, periodEnd, exportType } = props;
  const styles = css`
    &.nv-dropdown-container {
      margin-top: ${tripleSpacing}px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .button-label{
        margin-left: ${halfSpacing}px;
      }
      .previous-data:focus{
        outline:0;
        box-shadow:none;
      }
    }
  `;

  const loadDetails = (currentInstitutionId) => {
    if (currentInstitutionId) {
      props.getExportDetails({
        institutionId: currentInstitutionId,
      });
    }
  };

  const isExportOptionStatusActive: boolean = exportType === ExportTypes.NONE || props.exportDetails[exportType][periodType].reportStatus === ExportStatus.IN_PROGRESS;
  const openPreviousDataDownloads = () => {
    $uibModal.open({
      backdrop: 'static',
      templateUrl: 'institutions/templates/data-exports-modal.html',
      controller: 'DataExportsModalController as vm',
      windowClass: 'full-screen-modal-handheld full-screen-modal-tablet tall-modal data-exports-modal',
      resolve: {
        institutionId: props.currentInstitutionId,
        periodType: () => periodType,
        // Pass a copy of the file list as this one is managed by reduxjs and not mutable
        exportFileList: () => cloneDeep(props.exportFileList),
      },
    });
  };

  const exportData = () => {
    props.getExportData({
      institutionId: props.currentInstitutionId,
      exportType: props.exportType,
      period: periodType,
      startTime: periodStart.toISOString(),
      endTime: periodEnd.toISOString(),
      alertSuccessHeader: getExportAlertHeaderText(periodType, periodStart, periodEnd, exportType === ExportTypes.ORGANIZATION_USERS ? 'USER_DATA' : 'ENROLLMENT_DATA'),
    });
  };
  useEffect(() => {
    const pusherChannel = PusherService.setupChannel(`public-institution-${props.currentInstitutionId}`);
    pusherChannel.bind('institution_report_event_name', (file) => {
      loadDetails(props.currentInstitutionId);
    });

    loadDetails(props.currentInstitutionId);

    return () => {
      pusherChannel.unbind('institution_report_event_name');
    };
  }, [props.currentInstitutionId]);

  /* Request a new list of export data files whenever the period is changed or the export status on both relevant exports
   * is updated. This updates the visual indicators of whether an export is happening and whether exports are available */
  useEffect(() => {
    if (!props.currentInstitutionId) {
      return;
    }

    props.getExportFileList({ institutionId: props.currentInstitutionId, period: periodType });
  }, [
    props.currentInstitutionId,
    periodType,
    props.exportDetails[ExportTypes.ORGANIZATION_ENROLLMENT][periodType],
    props.exportDetails[ExportTypes.ORGANIZATION_USERS][periodType],
  ]);
  return (
    <div css={styles} className='nv-dropdown-container mt-8 d-flex flex-column align-items-center'>

      <Button
        className='d-flex'
        onClick={() => exportData()}
        variant='primary'
        disabled={isExportOptionStatusActive}
      ><NvIcon size='smallest' icon='download' /> <span className='button-label'>{t.INSTITUTIONS.LICENSES.DASHBOARD.DOWNLOAD()}</span>
      </Button>

      <Button
        className='previous-data'
        variant='link'
        onClick={openPreviousDataDownloads}
        disabled={exportType === ExportTypes.NONE || !props.exportFileList.length}
      >{!props.exportFileList.length || exportType === ExportTypes.NONE ? t.INSTITUTIONS.LICENSES.DASHBOARD.NO_PREVIOUS_DATA_DOWNLOADS() : t.INSTITUTIONS.LICENSES.DASHBOARD.PREVIOUS_DATA_DOWNLOADS() }
      </Button>
    </div>
  );
};

export default connect<StateProps, typeof actions, OwnProps, RootState>(
  (state, ownProps) => ({
    currentInstitutionId: state.app.currentInstitutionId,
    orgCreationDate: getCurrentOrgCreationDate(state) ?? moment(),
    exportDetails: state.models.license.exportDetails,
    exportFileList: state.models.license.exportFileList,
    ...ownProps,
  }), actions as any,
)(ExportButton);
