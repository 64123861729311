/* eslint-disable react/require-default-props */
/** @jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';
import { FieldError, Controller } from 'react-hook-form';

import t from 'react-translate';
import NvPopover from 'shared/components/nv-popover';
import NvTooltip from 'shared/components/nv-tooltip';
import usePostRender from 'shared/hooks/use-post-render';
import useClickOutside from 'shared/hooks/use-click-outside';
import ClickableContainer from 'components/clickable-container';
import { quarterSpacing } from 'styles/global_defaults/scaffolding';
import { black, gray6, hexToRgbaString } from 'styles/global_defaults/colors';
import ValidationErrorMessage from 'shared/components/inputs/validation-error-message';
import {
  handheld,
  notHandheld,
  screenXsMax,
} from 'styles/global_defaults/media-queries';
import NvTextInput from 'shared/components/inputs/nv-text-input';

type HeaderInputProps = {
  color: string,
  label: string,
  value: string,
  onChange: React.ComponentProps<'input'>['onChange'],
  error?: FieldError,
};

const HeaderInput = (props: HeaderInputProps) => {
  const {
    color,
    error,
    label,
    value,
    onChange,
  } = props;

  const [isTouched, setIsTouched] = React.useState(false);

  const styles = css`
    .input-title {
      color: ${color || black};
    }
  `;

  const handleInputBlur = () => setIsTouched(true);

  const stopPropagation = (event) => event.stopPropagation();

  return (
    <div css={styles}>
      <div className='label input-title'>
        {label}
      </div>
      <NvTextInput
        required
        value={value}
        onChange={onChange}
        onBlur={handleInputBlur}
        onKeyUp={stopPropagation}
        onClick={stopPropagation}
        onKeyDown={stopPropagation}
        error={isTouched ? error : undefined}
      />
    </div>
  );
};

type Props = {
  control: any,
  color: string,
  displayValue: string,
  className?: string,
  editable?: boolean,
  isSaving?: boolean,
  readonly?: boolean,
  readonlyWarning?: string,
  lastNameError?: FieldError,
  firstNameError?: FieldError,
  onBlur?: (hasChanges: boolean) => void,
};

const initialValueRefValue = {
  lastName: undefined,
  firstName: undefined,
};

const UserNameClickToEdit = (props: Props) => {
  const {
    color,
    control,
    className,
    displayValue,
    lastNameError,
    firstNameError,
    readonlyWarning,
    editable = false,
    isSaving = false,
    readonly = false,
    onBlur = () => {},
  } = props;

  const [isEditing, setIsEditing] = React.useState(false);
  const userNameInputsRef = React.useRef<HTMLDivElement>();
  const currentValueRef = React.useRef(initialValueRefValue);
  const clickableContainerRef = React.useRef<HTMLDivElement>();
  const previousValueRef = React.useRef<typeof initialValueRefValue>(initialValueRefValue);

  const [attemptedToChange, setAttemptedToChange] = React.useState(false);
  const attemptToChange = () => setAttemptedToChange(true);
  const resetAttemptedToChange = () => setAttemptedToChange(false);

  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  React.useEffect(() => {
    if (isEditing) {
      const {
        lastName: currentLastName,
        firstName: currentFirstName,
      } = currentValueRef.current;

      previousValueRef.current = {
        firstName: currentFirstName,
        lastName: currentLastName,
      };
    }
  }, [isEditing]);

  const scheduleNewClickableContainerFocus = usePostRender(() => {
    clickableContainerRef.current.focus();
  }, []);

  const getContainerStyles = () => {
    const backgroundColor = hexToRgbaString(gray6, 0.5);

    if (isSaving) {
      return css`
        background-color: ${backgroundColor};
      `;
    }

    if (editable && !isEditing) {
      return css`
        &:hover {
          background-color: ${backgroundColor};
        }
      `;
    }

    return css``;
  };

  const styles = css`
    color: ${color};
    ${handheld(css`
      text-align: center;
    `)}
    ${notHandheld(css`
      text-align: left;
    `)}
    ${getContainerStyles()}

    .inputs-container {
      display: flex;
      text-align: left;
      ${handheld(css`
        justify-content: center;
      `)}
      ${notHandheld(css`
        justify-content: flex-start;
      `)}

      & > * {
        flex: 1;
      }

      & > *:first-of-type {
        margin-right: ${quarterSpacing}px;
      }

      & > *:last-of-type {
        margin-left: ${quarterSpacing}px;
      }
    }
  `;

  /**
   * Hides the user name inputs when user clicks outside of the inputs container
   */
  useClickOutside(userNameInputsRef, () => {
    if (isEditing && !firstNameError && !lastNameError) {
      setIsEditing(false);

      /**
       * Simulating a "blur" event but instead of forwarding the bluring of the
       * inputs this "blur" event is fired when user clicks outside the
       * component inputs container.
       */

      const { current } = currentValueRef;
      const previous = previousValueRef.current;

      const lastNameChanged = previous.lastName !== current.lastName;
      const firstNameChanged = previous.firstName !== current.firstName;

      const hasChanges = lastNameChanged || firstNameChanged;

      /**
       * Parent component needs to know if the user changed anything to verify
       * whether we should start saving the form (Because if there are no
       * changes there's nothing to save after all).
       */
      onBlur(hasChanges);
    }
  });

  const handleUserNameBlur = () => resetAttemptedToChange();

  const handleUserNameClick = () => {
    attemptToChange();
    scheduleNewClickableContainerFocus();

    if (!readonly) {
      setIsEditing(true);
    }
  };

  const updateValueRefs = (fieldName: string, value: string) => {
    currentValueRef.current[fieldName] = value;
  };

  const viewModeClass = isHandheld ? 'course-title-regular' : 'course-title-lg';

  const containerClassNames = [
    className,
    isEditing ? '' : viewModeClass,
  ];

  return (
    <NvPopover
      placement='auto'
      preventOverflow
      show={readonly && attemptedToChange}
      content={<ValidationErrorMessage text={readonlyWarning} />}
    >
      <NvTooltip
        offset={-4}
        text={t.FORM.CLICK_TO_EDIT()}
        enabled={editable && !isEditing && !attemptedToChange}
      >
        <ClickableContainer
          css={styles}
          ref={clickableContainerRef}
          onBlur={handleUserNameBlur}
          onClick={handleUserNameClick}
          className={containerClassNames.filter(Boolean).join(' ')}
          layoutOnly={(!editable || isSaving || isEditing) && !attemptedToChange}
        >
          <div
            css={styles}
            ref={userNameInputsRef}
            className='inputs-container'
          >
            <Controller
              name='user.firstName'
              control={control}
              render={(data) => {
                const { value, onChange } = data.field;

                updateValueRefs('firstName', value);

                return isEditing && (
                  <HeaderInput
                    color={color}
                    value={value}
                    onChange={onChange}
                    error={firstNameError}
                    label={t.PROFILE.FIRST_NAME()}
                  />
                );
              }}
            />
            <Controller
              name='user.lastName'
              control={control}
              render={(data) => {
                const { value, onChange } = data.field;

                updateValueRefs('lastName', value);

                return isEditing && (
                  <HeaderInput
                    color={color}
                    value={value}
                    onChange={onChange}
                    error={lastNameError}
                    label={t.PROFILE.LAST_NAME()}
                  />
                );
              }}
            />
          </div>
          <div className='text-container ellipsis'>
            {!isEditing && (isSaving ? t.FORM.SAVING() : displayValue)}
          </div>
        </ClickableContainer>
      </NvTooltip>
    </NvPopover>
  );
};

export default UserNameClickToEdit;
