import { css } from '@emotion/react';
import React from 'react';
import t from 'react-translate';

import NvIcon from 'shared/components/nv-icon';
import NvPopover from 'shared/components/nv-popover';
import ClickableContainer from 'components/clickable-container';

type ScreenSharePreCheckMessageProps = {
  preCheckState: PrecheckType;
  getMediaStream: (isScreenShareMode: boolean) => void;
};

export enum PrecheckType {
  TEST_PERMISSION = 'TEST_PERMISSION',
  SUCCESS = 'SUCCESS',
  NO_PERMISSION = 'NO_PERMISSION',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
}

const styles = css`
  .action-link {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ScreenSharePreCheckMessage = ({
  preCheckState,
  getMediaStream,
}: ScreenSharePreCheckMessageProps) => {
  const iconName = preCheckState === PrecheckType.SUCCESS ? 'success' : 'info';
  let popoverText = '';
  let iconClassname = 'text-gray-6';
  let labelClassname = 'text-gray-6';

  if (preCheckState === PrecheckType.NOT_SUPPORTED
    || preCheckState === PrecheckType.NO_PERMISSION) {
    iconClassname = 'text-warning';
    labelClassname = 'text-warning';

    popoverText = t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SCREEN_RECORDING.WARNING_TEXT[preCheckState]();
  } else if (preCheckState === PrecheckType.SUCCESS) {
    iconClassname = 'text-success';
  }

  const displayState = () => (
    <div css={styles} className='d-flex justify-content-center align-items-center'>
      <NvIcon
        icon={iconName}
        size='medium'
        className={iconClassname}
      />
      {preCheckState === PrecheckType.TEST_PERMISSION && (
        <ClickableContainer
          className='action-link text-gray-6 ml-2 font-weight-bolder'
          onClick={() => getMediaStream(true)}
        >
          {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SCREEN_RECORDING.STATES.TEST_PERMISSION()}
        </ClickableContainer>
      )}
      {preCheckState === PrecheckType.NO_PERMISSION && (
        <span className={`${labelClassname} d-flex ml-2 font-weight-bolder`}>
          {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SCREEN_RECORDING.STATES.NO_PERMISSION()}
          &nbsp;
          <ClickableContainer
            className='action-link d-inline pull-right'
            onClick={() => getMediaStream(true)}
          >
            {t.FORM.RETRY()}
          </ClickableContainer>
        </span>
      )}
      {(preCheckState === PrecheckType.SUCCESS
        || preCheckState === PrecheckType.NOT_SUPPORTED) && (
        <span className={`${labelClassname} ml-2 font-weight-bolder`}>
          {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SCREEN_RECORDING.STATES[preCheckState]()}
        </span>
      )}
    </div>
  );

  return (
    <div className='precheck py-3 bg-black mx-auto mt-7 px-4'>
      {popoverText ? (
        <NvPopover
          showOnHover
          content={(
            <div>
              {popoverText}
            </div>
            )}
          placement='top'
        >
          {displayState()}
        </NvPopover>
      ) : displayState()}
    </div>
  );
};

export default ScreenSharePreCheckMessage;
