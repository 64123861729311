import { schema } from 'normalizr';
import { CollectionLecturePage } from 'redux/schemas/models/lecture-page';
import { CollectionCourse, CollectionFolder } from '../models/collections';
import { LecturePageListSchema } from './lecture-pages';

export interface CreateCollectionRequest {
  title: string;
  description: string;
  collectionId?: number
}
export interface CreateCollectionResponse {
  id: number;
  title: string;
  description?: string;
}

export interface FetchCollectionResponse {
  collections: CollectionCourse[],
  collectionsCount: number,
}

export interface CreateFolderRequest {
  title: string;
  collectionId: string;
  catalogId: string;
}

export interface FetchFolderResponse {
  response: CollectionFolder
}

export interface UpdateFolderRequest {
  title: string;
  folderId: number;
}

export interface DeleteFolderRequest {
  folderId: number;
  folderTitle: string;
  catalogId: string;
}

export interface CreateLecturePageRequest {
  title: string;
  folderId: number;
}

export interface CollectionsNormalized {
  [id: string]: CollectionCourse
}

export interface CollectionFoldersNormalized {
  [id: string]: CollectionFolder
}

export interface CollectionLecturePagesNormalized {
  [id: string]: CollectionLecturePage
}

export interface CollectionEntities {
  collections: CollectionsNormalized
  collectionFolders: CollectionFoldersNormalized
  collectionLecturePages: CollectionLecturePagesNormalized
}

export const CollectionFolderSchema = new schema.Entity<CollectionFolder>('collectionFolders', {
  lecturePages: LecturePageListSchema,
});
const CollectionFolderListSchema = new schema.Array(CollectionFolderSchema);

export const CollectionCourseSchema = new schema.Entity<CollectionCourse>(
  'courses',
  { folders: CollectionFolderListSchema },
  {
    idAttribute: 'catalogId',
    processStrategy: (value) => ({
      ...value,
      isContentManagementCollection: true,
    }),
  },
);

export const CollectionCourseListSchema = new schema.Array(CollectionCourseSchema);

