import React from 'react';

import moment from 'moment';

import { useSelector } from 'react-redux';
import t from 'react-translate';

// schemas
import { useAppDispatch } from 'redux/store';
import { RootState } from 'redux/schemas';
import { ActivityType } from 'redux/schemas/models/activity';
import { ComponentKeyPluralized } from 'redux/schemas/models/lecture-component';
import { ExerciseSkillsRatingActivity } from 'redux/schemas/models/exercise-skills-rating';

// actions
import { setActivityExpandedInAutomatedCommunications } from 'redux/actions/course-communications';

// selectors
import { isActivityExpandedInAutomatedCommunications } from 'redux/selectors/course-communications';
import { hasExerciseSkillsRatingActivityGotCommunicationError } from 'redux/selectors/timeline';

// components
import NvIcon from 'shared/components/nv-icon';
import NvStackedTab from 'shared/components/nv-stacked-tab';
import CommunicationCreateNewDropdown from 'communications/course_communications/components/communication-create-new-dropdown';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';
import NvTooltip from 'shared/components/nv-tooltip';

type ExerciseSkillRatingLectureComponentProps = {
  exerciseSkillsRatingActivityId: number
};

const ExerciseSkillRatingLectureComponent = ({ exerciseSkillsRatingActivityId }: ExerciseSkillRatingLectureComponentProps) => {
  const dispatch = useAppDispatch();

  const isExpanded = useSelector<RootState, boolean>((state: RootState) => isActivityExpandedInAutomatedCommunications(state, ComponentKeyPluralized.EXERCISE_SKILLS_RATING, exerciseSkillsRatingActivityId));
  const exerciseSkillsRatingActivity = useSelector<RootState, ExerciseSkillsRatingActivity>((state: RootState) => state.models.exerciseSkillsRatingActivities[exerciseSkillsRatingActivityId]);

  const hasCommunicationError = useSelector<RootState, boolean>((state) => hasExerciseSkillsRatingActivityGotCommunicationError(state, exerciseSkillsRatingActivityId));

  return (
    <React.Fragment>
      <div className={`lecture-component-row ${isExpanded && exerciseSkillsRatingActivity?.communicationsCount > 0 && 'expanded-box'}`}>
        <NvIcon icon='skills-feedback-activity' size='smallest' />
        <div className='description'>{exerciseSkillsRatingActivity?.title}</div>
        {exerciseSkillsRatingActivity?.hasStructuralIssues && (
          <div className='text-danger spacing'>
            <NvIcon icon='warning' size='xss-smallest' />
          </div>
        )}
        {exerciseSkillsRatingActivity?.hardDeadline && (
          <NvTooltip text={t.TIMELINE.HARD_DEADLINE()} placement='top'>
            <div className='spacing'>
              <NvIcon icon='harddeadline' size='smallest' />
            </div>
          </NvTooltip>
        )}
        {exerciseSkillsRatingActivity?.deadline && (
          <div className='date'>
            {t.LECTURE_PAGES.COMPONENTS.DUE_DATE.STUDENT_VIEW(moment(exerciseSkillsRatingActivity.deadline).format('LLL'))}
          </div>
        )}
        <CommunicationCreateNewDropdown
          activityType={ActivityType.EXERCISE_SKILLS_RATING}
          activityId={exerciseSkillsRatingActivityId}
        />
        {exerciseSkillsRatingActivity?.communicationsCount > 0 && (
          <NvStackedTab
            count={exerciseSkillsRatingActivity.communicationsCount}
            hasError={hasCommunicationError}
            isExpanded={isExpanded}
            errorMessage={t.COURSE_COMMUNICATIONS.WARNINGS.ACTIVITY_COMMUNICATION()}
            onClick={() => dispatch(setActivityExpandedInAutomatedCommunications({
              type: ComponentKeyPluralized.EXERCISE_SKILLS_RATING,
              id: exerciseSkillsRatingActivityId,
              isExpanded: !isExpanded,
            }))}
          />
        )}
      </div>
      {
        // Communications
        isExpanded && exerciseSkillsRatingActivity?.communications && exerciseSkillsRatingActivity.communications?.map(communicationId => (
          <CommunicationItem key={communicationId.toString()} communicationId={communicationId} />
        ))
      }
    </React.Fragment>
  );
};

export default ExerciseSkillRatingLectureComponent;
