import merge from 'lodash/merge';

/* @ngInject */
export default function EvaluationsTaskModelService(
  _,
  $q,
  EvaluationsTaskResources,
  CurrentCourseManager,
  moment,
  $sce,
) {
  const LOCAL_STATUS_MAPPING = {
    started: 'in_progress',
    above_completed: 'completed',
  };

  const EvaluationsTaskModel = function (attributes) {
    const _this = this;
    let deadlineMoment; let
      currentMoment;

    _.extend(_this, attributes);
    preprocess();

    /* Public Functions */
    _this.dateFormat = dateFormat;

    _this.getStatus = getStatus;
    _this.isNotStarted = isNotStarted;
    _this.isInProgress = isInProgress;
    _this.isCompleted = isCompleted;
    _this.isMissed = isMissed;
    _this.isStarted = isStarted;
    _this.isAboveCompleted = isAboveCompleted;
    _this.numMissed = numMissed;
    _this.numRemaining = numRemaining;
    _this.getTrustedDescription = getTrustedDescription;
    _this.preprocess = preprocess;


    function dateFormat() {
      if (deadlineMoment?.isSame(currentMoment, 'year')) {
        return 'MOMENT.MONTH_DAY_AT_TIME';
      }
      return 'MOMENT.MONTH_DAY_COMMA_YEAR_AT_TIME';
    }

    function getStatus() {
      return LOCAL_STATUS_MAPPING[_this.progress] ? LOCAL_STATUS_MAPPING[_this.progress] : _this.progress;
    }

    function isNotStarted() {
      return _this.progress === 'not_started';
    }

    function isInProgress() {
      return _this.progress === 'in_progress';
    }

    function isCompleted() {
      return _this.progress === 'completed';
    }

    function isAboveCompleted() {
      return _this.progress === 'above_completed';
    }

    function isMissed() {
      return _this.progress === 'missed';
    }

    function isStarted() {
      return _this.progress === 'started';
    }

    function numMissed() {
      return _this.targetGoal - _this.submittedEvaluations.length;
    }

    function numRemaining() {
      return _this.targetGoal - _this.numEvaluated;
    }

    function getTrustedDescription() {
      return $sce.trustAsHtml(_this.description);
    }

    /* Private Functions */
    function preprocess() {
      if (_this.expirationDate) {
        deadlineMoment = moment(_this.expirationDate);
      } else if (_this.deadline) {
        deadlineMoment = moment(_this.deadline);
      }
      currentMoment = moment();

      // If public submissions
      if (_this.isFeedbackPublic) {
        if (_this.evaluations) {
          merge(_this.evaluations, _this.submissionsForPublicReview);
        } else {
          _this.evaluations = _this.submissionsForPublicReview || [];
        }
        // The following use userPeerReviews because submissionsForPublicReview may not include all completed submissions for display
        _this.submittedEvaluations = _this.userPeerReviews ? _.where(_this.userPeerReviews, { submitted: true }) : [];
        _this.drafts = _this.userPeerReviews ? _.where(_this.userPeerReviews, { submitted: false }) : [];
      } else {
        _this.evaluations = _this.userPeerReviews || [];
        _this.submittedEvaluations = _this.userPeerReviews ? _.where(_this.userPeerReviews, { submitted: true }) : [];
        _this.drafts = _this.userPeerReviews ? _.where(_this.userPeerReviews, { submitted: false }) : [];
      }
    }
  };

  return EvaluationsTaskModel;
}
