/* @ngInject */
export default function BulkEditReleaseDatesController(
  $uibModalInstance,
  ConfirmationOverlays,
  CurrentCourseManager,
  section,
  moment,
  _,
) {
  const vm = this;
  vm.section = section;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.edited = false;
  vm.default = { releaseDate: null };
  vm.currentMoment = moment();

  initialize();

  function initialize() {
    vm.default.releaseDate = vm.section.getAllLecturePages()[0];
    _.each(vm.section.getAllLecturePages(), (lecturePage) => {
      vm.default.releaseDate = lecturePage.releaseDate < vm.default.releaseDate ? lecturePage.releaseDate : vm.default.releaseDate;
    });
  }

  vm.setEdited = function () {
    vm.edited = true;
  };

  vm.submit = function () {
    let unreleaseWarning = false;
    const communicationExistWarning = _.some(
      vm.section.getAllLecturePages(),
      (lp) => lp.scheduledCommunicationsCount > 0 && moment(vm.default.releaseDate).isBefore(moment(lp.releaseDate)),
    );

    // Checking if we're unreleasing any of the released lecture page
    if (moment(vm.default.releaseDate) > vm.currentMoment) {
      unreleaseWarning = _.some(vm.section.getAllLecturePages(), (lp) => lp.isReleased);
    }

    if (unreleaseWarning || communicationExistWarning) {
      const modalInstance = ConfirmationOverlays.openConfirmationModal('timelines/templates/modal-bulk-lecture-page-release-date-change-confirmation.html',
        'AttachModalResolvesToVmCtrl', {
          vmResolves() {
            return {
              CurrentCourseManager,
              item: vm.item,
              showUnreleaseWarning: unreleaseWarning,
              showCommunicationExistWarning: communicationExistWarning,
            };
          },
        });

      modalInstance.result.then(() => {
        updateReleaseDates();
      });
    } else {
      updateReleaseDates();
    }
  };

  function updateReleaseDates() {
    vm.section.updateReleaseDate(vm.default.releaseDate).then($uibModalInstance.close);
  }
}
