import { css } from '@emotion/react';
import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';

import { deleteOrgAdmin, getOrgAdmins } from 'redux/actions/org-level-roles';
import { RootState } from 'redux/schemas';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';

import { OrgAdminRoles } from 'redux/schemas/models/org-level-roles';
import { PagedDataQueryParams } from 'redux/create-action-creators';
import { OrgAdmin } from 'redux/schemas/models/user';
import { OrgAdminsNormalized } from 'redux/schemas/api/user';
import { getCurrentInstitution } from 'redux/selectors/institutions';

import NvIcon from 'shared/components/nv-icon';
import NvResponsiveTable, { NvTableRowProps } from 'shared/components/nv-responsive-table';
import { gray4, primary } from 'styles/global_defaults/colors';
import { createGridStyles, tripleSpacing } from 'styles/global_defaults/scaffolding';
import OrgAdminInput from './org-admin-input';
import LoadingRow, { ActionsDropdown, tableStyles } from './shared/table-components';

const PAGE_SIZE = 25;

const styles = css`
  .tab-action-panel {
    color: ${primary};
    &.disabled {
      color: ${gray4};
      pointer-events: none;
    }
  }
  .org-admins-table {
    /* 210 = top-nav height(60) + tab header height(60) + tab-action-header height(60) + table margin(30) */
    height: calc(100vh - 210px);

    .nv-responsive-table {
      ${tableStyles}
    }
  }
`;

const rowStyles = css`
  &.user-row {
    height: ${tripleSpacing}px;
  }
  &.input-row {
    height: ${tripleSpacing}px;
  }
`;

const OrgAdmins = () => {
  const institution = useSelector((state) => getCurrentInstitution(state));
  const storedOrgAdminData = (state: RootState) => state.models.orgAdmins as OrgAdminsNormalized;

  const [showAdminInputRow, setShowAdminInputRow] = useState(false);
  const [editingId, setEditingId] = useState<number>();
  const [addedNewAdminId, setAddedNewAdminId] = useState<[number]>();

  const firstNameRef = useRef(null);
  const dispatch = useAppDispatch();

  const orgAdminRoles: { [key in OrgAdminRoles]: string } = {
    [OrgAdminRoles.ORG_ADMIN]: t.INSTITUTIONS.ROLES.ORG_ADMIN_ROLES.ORG_ADMIN(),
    [OrgAdminRoles.COURSE_MANAGER]: t.INSTITUTIONS.ROLES.ORG_ADMIN_ROLES.COURSE_MANAGER(),
  };

  const tableColumns = [
    {
      name: t.INSTITUTIONS.ROLES.NAME(),
      className: 'name-cell',
      gridWidth: '30%',
    },
    {
      name: t.INSTITUTIONS.ROLES.EMAIL(),
      className: 'email-cell',
      gridWidth: '30%',
    },
    {
      name: t.INSTITUTIONS.ROLES.ORG_ROLE(),
      className: 'org-level-role-cell',
      gridWidth: '30%',
    },
    {
      name: t.INSTITUTIONS.ROLES.ACTIONS(),
      className: 'actions-cell',
      gridWidth: '10%',
      headerClassName: 'justify-content-end pr-0',
    },
  ];
  const filtersRef = React.useRef({});
  const fetchParams: PagedDataQueryParams = {
    filters: filtersRef.current,
  };

  useEffect(() => {
    if (showAdminInputRow && firstNameRef?.current) {
      firstNameRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      firstNameRef.current.focus({ preventScroll: true });
    }
  }, [showAdminInputRow]);

  const onEdit = (adminId: number) => {
    if (adminId !== editingId) {
      setEditingId(adminId);
      setShowAdminInputRow(false);
    }
  };

  const onDelete = (admin: OrgAdmin) => {
    dispatch(openConfirmationDialog({
      title: t.INSTITUTIONS.ROLES.REMOVE_ORG_ADMIN_WARNING.TITLE(`${admin.user.firstName} ${admin.user.lastName}`),
      bodyText: t.INSTITUTIONS.ROLES.REMOVE_ORG_ADMIN_WARNING.TEXT(
        `${admin.user.firstName} ${admin.user.lastName}`,
        orgAdminRoles[admin.adminRole],
      ),
      confirmText: t.DASHBOARD.CONFIRMATION.YES_SURE(),
      cancelText: t.FORM.CANCEL(),
      onConfirm: () => dispatch(deleteOrgAdmin({
        institutionId: institution.id,
        adminId: admin.id,
      })),
    }));
  };

  const orgAdminRow = (rowProps: NvTableRowProps<OrgAdmin>) => {
    const { data: user, rowIndex } = rowProps;

    return (
      <div
        className='user-row d-flex border-bottom border-gray-5 py-2 align-items-center text-regular text-black'
        style={createGridStyles(1, rowIndex, tableColumns.length + 1, rowIndex + 1)}
        css={rowStyles}
      >
        <div
          className='font-weight-bolder'
          style={{ width: '30%' }}
        >
          {`${user.user.firstName} ${user.user.lastName}`}
        </div>
        <div style={{ width: '30%' }} className='pr-2'>
          {user.user.email}
        </div>
        {editingId === user.id ? (
          <OrgAdminInput
            institutionId={institution.id}
            closeForm={() => setEditingId(null)}
            isEditing
            initialAdminRole={user.adminRole}
            adminId={user.id}
          />
        ) : (
          <React.Fragment>
            <div style={{ width: '30%' }}>
              {orgAdminRoles[user.adminRole]}
            </div>

            <div
              className='actions text-primary'
              style={{ width: '10%' }}
            >
              <ActionsDropdown
                editText={t.INSTITUTIONS.ROLES.EDIT_ORG_ADMIN_ROLE()}
                onEdit={() => onEdit(user.id)}
                onDelete={() => onDelete(user)}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  const orgAdminInputRow = (rowProps: { rowIndex: number }) => (
    <div
      className='input-row d-flex border-bottom border-gray-5 py-2 align-items-center text-regular text-black'
      style={createGridStyles(1, rowProps.rowIndex, tableColumns.length + 1)}
      css={rowStyles}
    >
      <OrgAdminInput
        closeForm={() => setShowAdminInputRow(false)}
        addedCallback={(id) => setAddedNewAdminId([id])}
        institutionId={institution.id}
        firstNameRef={firstNameRef}
      />
    </div>
  );

  return (
    <div css={styles}>
      <div className='tab-action-header'>
        <div className={`tab-action-panel text-medium ${showAdminInputRow ? 'disabled' : ''}`}>
          <button
            type='button'
            className='d-flex align-items-center border-0 bg-transparent mr-4'
            onClick={() => { setShowAdminInputRow(true); setEditingId(null); }}
          >
            <NvIcon size='smallest' icon='add' className='pr-2 d-inline-block ' />
            {t.INSTITUTIONS.ROLES.NEW_ORG_ADMIN()}
          </button>
        </div>
      </div>
      <div className='org-admins-table mt-5'>
        <NvResponsiveTable<OrgAdmin, {}, OrgAdminsNormalized>
          columns={tableColumns}
          fetchData={getOrgAdmins}
          fetchParams={{ institutionId: institution.id }}
          pagedFetchParams={fetchParams}
          rowComponent={orgAdminRow as FunctionComponent}
          rowProps={{}}
          loadingComponent={(rawProps) => LoadingRow(rawProps, tableColumns.length + 1)}
          dataKey='id'
          cacheDataKey='id'
          cacheLookup={storedOrgAdminData}
          hideClearSearch
          clearSearch={() => {}}
          style={createGridStyles(1, 3)}
          noResultsInTable
          noResultsIcon=''
          noResultsText={t.SEARCH.NO_USERS_ADDED_YET()}
          extraRowComponent={showAdminInputRow ? orgAdminInputRow : null}
          hoverDisabled
          pageSize={PAGE_SIZE}
          extraDataKeys={addedNewAdminId}
        />
      </div>
    </div>
  );
};
export default OrgAdmins;
