/* @ngInject */
export default function TimelineVideoListLectureComponentModelService(

  _,
  nvUtil,
  TimelineLectureVideoModel,
) {
  const TimelineVideoListLectureComponentModel = function (attributes) {
    const _this = this;
    const base = {
      templateUrl: 'timelines/templates/nv-timeline-video-list.html',
    };

    /** Setting Up Basic Attributes * */
    _.extend(_this, base, attributes);
    // passed lecturePage, lectureSection, course
    preprocess();

    /** Public Data * */

    /** Public Functions * */
    _this.getAllItems = getAllItems;

    /** Function Declarations * */
    /* Used Publicly */
    function getAllItems() {
      return _this.lectureVideos;
    }

    /* Used Privately */
    function preprocess() {
      _this.lectureVideos = _.map(_this.lectureVideos, (lectureVideo) => {
        lectureVideo = _.extend(lectureVideo, {
          videoListLectureComponent: _this,
          lecturePage: _this.lecturePage,
          lectureSection: _this.lectureSection,
          course: _this.course,
          videoId: lectureVideo.video.id,
        });

        return new TimelineLectureVideoModel(lectureVideo);
      });
    }
  };

  return TimelineVideoListLectureComponentModel;
}
