/* @ngInject */
export default function LearnerProfileResources(

  $resource,
) {
  return $resource(
    '/users/:userId.json',
    {},
    {
      getProfile: { method: 'GET', url: '/users/basic_info/:userId' },
      getCourseProfile: { method: 'GET', url: '/:catalogId/users/course_info/:userId' },
      getViewerMemberships: { method: 'GET', url: '/:catalogId/users/memberships.json?viewee_id=:vieweeId' },
      getActivityMetrics: { method: 'GET', url: '/users/:userId/activity_metrics.json' },
      getCourseActivityMetrics: { method: 'GET', url: '/:catalogId/users/:userId/activity_metrics.json' },
      getSubmissions: { method: 'GET', url: '/users/:userId/submissions.json?shared_with_class_only=true' },
      getCourseSubmissions: { method: 'GET', url: '/:catalogId/users/:userId/submissions.json?shared_with_class_only=true' },
      getPrivateSubmissions: { method: 'GET', url: '/:catalogId/users/:userId/submissions.json?shared_with_instructor_or_team=true' }, // The response includes shared_with_instructor and shared_with_instructor_and_team
      getCourseProfileQuestions: { method: 'GET', url: '/:catalogId/users/:userId/course_profile_info.json' },
      getDiscussionContributions: { method: 'GET', url: '/:catalogId/users/:userId/discussion_contributions.json' },
      getCertificates: { method: 'GET', url: '/users/statements_and_certificates.json?viewee_id=:vieweeId' },
      saveProfile: { method: 'PUT', url: '/:catalogId/save_profile.json' },
      sendMessage: { method: 'POST', url: '/:catalogId/conversations/create.json' },


      // Admin actions
      grantSop: { method: 'GET', url: '/:catalogId/users/:userId/grant_course_statement.json' },
      grantSopWithDistinction: { method: 'GET', url: '/:catalogId/users/:userId/grant_course_statement?with_honors=true' },
      revokeSop: { method: 'GET', url: '/:catalogId/users/:userId/revoke_course_statement.json' },
      unenroll: { method: 'PUT', url: '/:catalogId/flip_ban/:userId.json' },
      delete: { method: 'PUT', url: '/disable_account/:userId.json' },
      updateLoginCredentials: { method: 'PUT', url: 'users/:userId/update_login_credentials.json' },

      // Invitations
      inviteMember: { method: 'POST', url: '/:catalogId/team_members/invite_members/:teamId.json' },
      uninviteMember: { method: 'POST', url: '/:catalogId/team_members/uninvite/:teamId.json' },

    },
  );
}
