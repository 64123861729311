import { css } from '@emotion/react';
import React from 'react';
import t from 'react-translate';

// Schemas
import { FlagType } from 'redux/schemas/models/org-mentors';
import { MissedDeadlineActivities } from 'redux/schemas/models/org-users';

// Selector
import { getLabelForActivity, getKeyForActivityType } from 'redux/selectors/activity';

// Styles
import { standardSpacing } from 'styles/global_defaults/scaffolding';

// Components
import { NvPopover } from 'shared/components/nv-popover';
import NvListWithMore from 'shared/components/nv-list-with-more';

import prodPathReplace from '../../shared/prod-path-rewrite';

type EnrollmentRowFlagProps = {
  flagType: FlagType
  activities?: MissedDeadlineActivities[]
};

type Flag = {
  name: string,
  image: string,
};

const styles = () => css`
  img {
    width: ${standardSpacing}px;
    height: ${standardSpacing}px;
  }
`;

const popoverStyles = css`
  .bs4-popover-body {
    padding-top: 0;
  }
`;

const EnrollmentRowFlag = ({ flagType, activities }: EnrollmentRowFlagProps) => {
  const activitiesCount = activities?.length;

  const enrollmentFlags = {
    [FlagType.NEVER_LOGGED_IN]: {
      name: t.INSTITUTIONS.ORG_MENTORS.FILTER.FLAG_NAME.NEVER_LOGGED_IN(),
      image: 'never-logged',
    },
    [FlagType.MISSED_DEADLINE]: {
      name: t.INSTITUTIONS.ORG_MENTORS.FILTER.FLAG_NAME.MISSED_DEADLINE(activitiesCount),
      image: 'missed-deadline',
    },
    [FlagType.NOT_ACTIVE]: {
      name: t.INSTITUTIONS.ORG_MENTORS.FILTER.FLAG_NAME.NOT_ACTIVE(),
      image: 'not-active',
    },
    [FlagType.RECENTLY_COMPLETED]: {
      name: t.INSTITUTIONS.ORG_MENTORS.FILTER.FLAG_NAME.RECENTLY_COMPLETED(),
      image: 'recently-completed',
    },
  };

  const flag: Flag = flagType && enrollmentFlags[flagType];

  return (
    <div css={styles()}>
      <NvPopover
        content={(
          <NvListWithMore
            items={activities}
            maxLength={5}
            itemComponent={(item, index) => (
              <div className='pt-3' key={`${item.title}-${index + 1}`}>
                <span className='text-gray-2'>
                  {`${getLabelForActivity(getKeyForActivityType(item.type, item.category))}: `}
                </span>
                <span className='label text-black'>
                  {item.title}
                </span>
              </div>
            )}
            more={(leftCount) => (
              <div className='pt-3 text-gray-2'>
                {t.INSTITUTIONS.ORG_MENTORS.POPOVER_MORE_ITEMS(leftCount)}
              </div>
            )}
          />
        )}
        placement='top'
        preventOverflow={false}
        enabled={activitiesCount > 0}
        showOnHover
        overlayStyles={popoverStyles}
      >
        <div className='d-flex my-2 align-items-center'>
          <img
            src={prodPathReplace(`images/${flag?.image}.png`)}
            alt={flag.name}
          />
          <div
            className={`label ml-1 text-${flagType === FlagType.NEVER_LOGGED_IN ? 'danger' : 'black'}`}
          >
            {flag?.name}
          </div>
        </div>
      </NvPopover>
    </div>
  );
};
export default EnrollmentRowFlag;

