import React from 'react';
import { css } from '@emotion/react';

import {
  createGridStyles,
  doubleSpacing,
  halfSpacing,
  quarterSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import { notDesktop } from 'styles/global_defaults/media-queries';
import { borderStyle } from './nv-responsive-table';

const LoadingRowStyles = css`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  padding: ${standardSpacing}px;
  border-bottom: ${borderStyle};

  .bar {
    width: 100%;
    height: 12px;
  }

  .group {
    display: flex;
    align-items: center;
    gap: ${halfSpacing}px;
    flex: 1 1 auto;
  }

  .stack {
    display: flex;
    flex-direction: column;
    gap: ${quarterSpacing}px;
    flex: 1 1 auto;
  }

  .img {
    width: 100px;
    height: 60px;
  }
`;

type LoadingRowProps = {
  rowIndex: number;
  hideImg?: boolean;
};

export const loadingWrapperStyles = css`
  width: 100%;
  max-width: 1000px;
  align-self: flex-start;
  margin-top: ${doubleSpacing}px;

  ${notDesktop(css`
    padding: 0 ${standardSpacing}px;
  `)}
`;

const DashboardLoadingRow = ({
  rowIndex,
  hideImg,
}: LoadingRowProps) => (
  <div style={createGridStyles(1, rowIndex, 5)} css={LoadingRowStyles}>
    {!hideImg && (
      <div className='group'>
        <div className='img loading' />
        <div className='stack'>
          <div className='loading bar' style={{ width: '50%' }} />
          <div className='loading bar' style={{ width: '40%' }} />
        </div>
      </div>
    )}
    <div className='group'>
      <div className='loading bar' style={{ width: '50%' }} />
    </div>
    <div className='group'>
      <div className='loading bar' style={{ width: '15%' }} />
    </div>
    <div className='group'>
      <div className='loading bar' style={{ width: '90%' }} />
    </div>
  </div>
);

export default DashboardLoadingRow;
