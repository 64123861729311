import { ComponentType } from 'redux/schemas/models/lecture-component';
import VideoPracticeEvaluation from '../components/video-practice-evaluation/video-practice-evaluation';

/* @ngInject */
export default function VideoPracticeEvaluationCtrl(
  $scope,
  _,
  vmResolves,
  $uibModalInstance,
  $timeout,
  $translate,
  ConfirmationOverlays,
  VideoPracticeSkillsRatingLectureComponentModel,
) {
  const vm = this;
  _.extend(vm, vmResolves);
  vm.title = $translate.instant('LECTURE_PAGES.COMPONENTS.DROPDOWN.EDIT_BASICS');
  vm.isCreate = !vm.isEdit;
  vm.isDirty = false;
  let dismissedByUser = false;

  const closeModal = () => {
    dismissedByUser = true;
    $uibModalInstance.dismiss();
  };

  vm.deregisterModalClosing = $scope.$on('modal.closing', ($event) => {
    if (vm.isDirty && !dismissedByUser) {
      $event.preventDefault();
      const modalOverlay = ConfirmationOverlays.openConfirmationModal('shared/templates/modal-navigate-away-unsaved-changes.html');

      modalOverlay.result.then(() => {
        dismissedByUser = true;
        $uibModalInstance.dismiss();
      });
    }
  });

  const createVideoPracticeEvaluation = (publicFeedback, feedbackType) => {
    dismissedByUser = true;
    vm.lectureComponent.type = feedbackType;
    if (feedbackType === ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK) {
      const component = new VideoPracticeSkillsRatingLectureComponentModel({}, false, true);
      vm.lectureComponent.setRealComponent(component);
      vm.lectureComponent.realComponent.feedbackDraft = publicFeedback;
      vm.lectureComponent.realComponent.skillsRating.activityId = publicFeedback.activityId;
    } else {
      vm.lectureComponent.publicFeedback = publicFeedback;
    }
    $uibModalInstance.close();
  };

  const editBasics = (data) => {
    dismissedByUser = true;
    $uibModalInstance.close(data);
  };

  const setTitle = (title) => {
    $timeout(() => {
      vm.workflowCtx?.setSettings({
        ...vm.workflowCtx.settings,
        title: () => title,
      });
    });
  };

  const setFormDirty = (isDirty) => {
    vm.isDirty = isDirty;
  };

  $scope.VideoPracticeEvaluationModal = () => (
    <VideoPracticeEvaluation
      onClose={closeModal}
      createVideoPracticeEvaluation={createVideoPracticeEvaluation}
      editBasics={editBasics}
      formData={vm.formData}
      isEdit={vm.isEdit}
      setModalTitle={setTitle}
      lectureComponent={vm.lectureComponent}
      onChange={setFormDirty}
    />
  );
}
