/* @ngInject */
export default function DiscussionsResource(
  $resource,
) {
  return $resource(
    '/:catalogId/forums/:id',
    { },
    {
      getUsersActiveInDiscussions: { method: 'GET', url: '/:catalogId/active_in_discussions' },
      createDiscussion: { method: 'POST', url: '/:catalogId/forums/:forumId/topics.json' },
    },
  );
}
