export const PARSING_MODES = {
  COURSE_USERS: 1,
  MENTEES_SINGLE_MENTOR: 2,
  MENTEES_MULTI_MENTOR: 3,
  BULK_UNENROLL: 4,
  SCHEDULE_WELCOME_EMAIL: 5,
};

/* Gets the translation key prefix common translation strings that are categorized by parsing mode. */
export const setTranslationCategory = (parsingMode, parsingModes) => {
  let translationCategory = 'USER_MANAGEMENT.CSV_UPLOAD_MODAL.';
  switch (parsingMode) {
    case parsingModes.COURSE_USERS:
      translationCategory += 'COURSE_USERS.';
      break;
    case parsingModes.MENTEES_SINGLE_MENTOR:
      translationCategory += 'MENTEES_SINGLE_MENTOR.';
      break;
    case parsingModes.MENTEES_MULTI_MENTOR:
      translationCategory += 'MENTEES_MULTI_MENTOR.';
      break;
    case parsingModes.BULK_UNENROLL:
      translationCategory += 'BULK_UNENROLL.';
      break;
    case parsingModes.SCHEDULE_WELCOME_EMAIL:
      translationCategory += 'SCHEDULE_WELCOME_EMAIL.';
      break;
    default:
      break;
  }
  return translationCategory;
};

export const getMenteeName = (parsingMode, parsingModes, mentors) => {
  if (parsingMode === parsingModes.MENTEES_SINGLE_MENTOR && mentors.length === 1) {
    return mentors[0].roles.roles.menteeName;
  }

  return null;
};

/* @ngInject */
export default function UploadUsersCsvCtrl(
  onUserUploaded,
  onUserUnenrolled,
  parsingMode,
  mentor,
) {
  class UploadUsersCsvController {
    constructor() {
      this.PARSING_MODES = PARSING_MODES;
      this.onUserUploaded = onUserUploaded;
      this.onUserUnenrolled = onUserUnenrolled;
      this.parsingMode = parsingMode;
      this.mentor = mentor;
      this.translationCategory = setTranslationCategory(this.parsingMode, this.PARSING_MODES);

      this.mentors = [];

      if (this.mentor) {
        this.mentors.push(this.mentor);
      }

      this.getMenteeName = () => getMenteeName(this.parsingMode, this.PARSING_MODES, this.mentors);

      this.onScheduling = () => {
        const parsingMode = this.PARSING_MODES.SCHEDULE_WELCOME_EMAIL;
        this.translationCategory = setTranslationCategory(parsingMode, this.PARSING_MODES);
      };
    }
  }

  return new UploadUsersCsvController();
}
