import moment from 'moment';
import React from 'react';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import NvDatePicker, { DatePickerType } from 'shared/components/inputs/nv-datepicker';

type Props = {
  date?: moment.Moment,
  onApply: (date: moment.Moment) => void,
};

const BulkCourseStartDates = (props: Props) => {
  const { date, onApply } = props;
  const [startDate, setStartDate] = React.useState();

  const handleApplyClick = () => {
    const newDate = startDate || moment();
    onApply(newDate);
  };

  return (
    <div className='gray-box bulk-dates'>
      <p>{ t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.COURSE_INFORMATION.BULK_APPLY() }</p>
      <div className='d-flex'>
        <NvDatePicker
          withLabel
          placeholder={t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.COURSE_INFORMATION.START_DATE()}
          className='mr-2'
          value={startDate || date}
          type={DatePickerType.DATETIME}
          onChange={(newDate) => setStartDate(newDate)}
        />
        <div className='d-flex align-items-end' style={{ paddingBottom: 2 }}>
          <Button onClick={handleApplyClick}>{ t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.COURSE_INFORMATION.APPLY() }</Button>
        </div>
      </div>
    </div>
  );
};

export default BulkCourseStartDates;
