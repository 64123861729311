import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NovoAIItemType } from 'redux/schemas/models/lecture-page';
import useAIComponentsGenerator, {
  AIComponentsContextProvider,
} from '../hooks/use-ai-components-generator';
import GenerateDiscussionPrompts from './discussion-prompts/generate-prompts';
import LayoutList from './key-takeaway-and-summary/select-layout';
import GenerateQuiz from './quiz/generate-quiz';

const contentRenderMap = {
  [NovoAIItemType.SUMMARY]: LayoutList,
  [NovoAIItemType.KEY_TAKEAWAYS]: LayoutList,
  [NovoAIItemType.DISCUSSION]: GenerateDiscussionPrompts,
  [NovoAIItemType.QUIZ]: GenerateQuiz,
};

type AIContentRenderProps = {
  itemType: NovoAIItemType;
};

const ContentRender = (props: AIContentRenderProps) => {
  const RenderComponent = contentRenderMap[props.itemType];
  const useAIComponentsMethods = useAIComponentsGenerator();
  const contentRef = useRef<HTMLDivElement>(null);
  const contentId = useSelector(
    state => state.app.lecturePage.novoAI.content?.id,
  );

  useEffect(() => {
    if (contentId && contentRef.current) {
      setTimeout(() => {
        contentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      });
    }
  }, [contentId]);

  return (
    <AIComponentsContextProvider {...useAIComponentsMethods}>
      <div ref={contentRef}>
        <RenderComponent />
      </div>
    </AIComponentsContextProvider>
  );
};

export default ContentRender;
