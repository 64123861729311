import React from 'react';
import moment from 'moment';
import t from 'react-translate';
import _ from 'underscore';

// Form and validations
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

// Reused components
import { Button } from 'react-bootstrap';
import NvIcon from 'shared/components/nv-icon';
import validationConstants from 'shared/services/constants-shared';

// Styles
import { css } from '@emotion/react';
import { gray7 } from 'styles/global_defaults/colors';
import { textExtraLargeFontSize, boldFontWeight, textMediumFontSize, textMediumLineHeight, semiBoldFontWeight, textSmallFontSize, textSmallLineHeight } from 'styles/global_defaults/fonts';
import { standardSpacing, doubleSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';

// Actions
import { useAppDispatch } from 'redux/store';
import { doRequestCloneJourney } from 'redux/actions/license';

// Types
import { Course } from 'redux/schemas/models/course';
import { CloneCourse, ExcludeCourse } from 'redux/schemas/api/license';
import { CloneOptions, JourneyCollection } from './journey-collection';

import BulkCourseStartDates from './bulk-course-start-dates';
import { CopyAudioVideoTypes, CourseStartDatesTypes, JourneyInformationProps } from './journey-information-clone-modal';

export type CourseInformationFormCourse = Omit<Course, 'collections'> & { releaseDateDraft: string };

export type CourseInformationFormCollection = {
  id: number,
  name: string,
  courses: CourseInformationFormCourse[],
};

export type CourseInformationFormData = {
  collections: CourseInformationFormCollection[],
};

export type ReleaseDatesType = {
  journeyBase?: string;
  journeyDraft?: string;
  courseBase?: string;
  courseDraft?: string;
};

type CourseInformationProps = {
  journey: Course,
  base: CourseInformationFormCollection[],
  showBulkDates: boolean,
  collections: CourseInformationFormCollection[],
  config: JourneyInformationProps,
  onBack: (collections: CourseInformationFormCollection[], bulkDate: string) => void,
  onSuccess: (result: any) => void,
};

export const CourseInformationCloneModal = (props: CourseInformationProps) => {
  const {
    journey,
    base,
    collections: items,
    showBulkDates,
    config,
    onBack,
    onSuccess,
  } = props;
  const [bulkDate, setBulkDate] = React.useState<string>(config?.bulkDate);
  const [hasError, setHasError] = React.useState(false);
  const [releaseDates, setReleaseDates] = React.useState<ReleaseDatesType>({});

  const dispatch = useAppDispatch();

  const validationSchemaRef = React.useRef(yup.object().shape({
    collections: yup.array().of(
      yup.object().shape({
        courses: yup.array().of(
          yup.object().shape({
            name: yup.string().required(t.VALIDATION.REQUIRED()),
            catalogId: yup.string()
              .min(1, t.VALIDATION.REQUIRED())
              .max(190, t.VALIDATION.MAX_LENGTH('190'))
              .novoEdCatalogId(t.COURSES.FORM.VALIDATION_TEXT.CATALOG_ID_PATTERN()),
          }),
        ),
      }),
    ),
  }));

  const methods = useForm<CourseInformationFormData>({
    mode: 'onChange',
    shouldUnregister: true,
    defaultValues: { collections: items },
    resolver: yupResolver(validationSchemaRef.current),
  });
  const { handleSubmit, control, formState, getValues, setError } = methods;
  const {
    fields: collections,
  } = useFieldArray<CourseInformationFormData, 'collections', 'key'>({
    name: 'collections',
    keyName: 'key',
    control,
  });

  const handleBulkApply = (applyDate: moment.Moment) => {
    if (applyDate) setBulkDate(applyDate.toISOString());
  };

  const handleBack = () => {
    const collectionsDraft: CourseInformationFormCollection[] = getValues('collections');
    const collectionsWithDates = collectionsDraft.map((collection: CourseInformationFormCollection) => ({
      ...collection,
      courses: collection.courses.map((item: CourseInformationFormCourse) => ({
        ...item,
        releaseDateDraft: item.releaseDate,
      })),
    }));
    onBack(collectionsWithDates, bulkDate);
  };

  const getCloneCourses = (list: CourseInformationFormCollection[]) => {
    const cloneCourses: CloneCourse[] = [];
    const excludeCourses: ExcludeCourse[] = [];
    list.forEach(({ courses }) => {
      courses.forEach((course: any) => {
        const { cloneOption = CloneOptions.CLONE } = course;
        if (cloneOption !== CloneOptions.KEEP) {
          if (cloneOption === CloneOptions.CLONE) {
            const newCourse: any = _.pick(course, ['id', 'catalogId', 'name', 'releaseDate']);
            newCourse.startDate = moment(newCourse.releaseDate).toISOString();
            delete newCourse.releaseDate;
            cloneCourses.push(newCourse);
          } else {
            const newCourse: ExcludeCourse = { id: course.id };
            excludeCourses.push(newCourse);
          }
        }
      });
    });
    return { cloneCourses, excludeCourses };
  };

  const setFieldErrors = (errors) => {
    collections.forEach((collection, indexCollection) => {
      collection.courses.forEach((course, indexCourse) => {
        if (errors[course.catalogId]) {
          setError(`collections.${indexCollection}.courses.${indexCourse}.catalogId`, {
            message: t.COURSES.FORM.ERRORS.CATALOG_ID_TAKEN(),
          });
        }
      });
    });
  };

  /** Submits a journey cloning request, sending a callback to update local UI state */
  const onSubmit = (formFields: CourseInformationProps) => {
    const { collections: list } = formFields;
    const { catalogId, copyAudioVideo: copyAudioVideoItem, name, courseStartDates, releaseDate } = config;
    const coursesStartDateConfig = CourseStartDatesTypes.AUTOMATICALLY === courseStartDates ? 0 : 1;
    const filteredCourses = getCloneCourses(list);
    const { cloneCourses, excludeCourses } = filteredCourses;
    const copyAudioVideo = copyAudioVideoItem.id === CopyAudioVideoTypes.COPY;
    dispatch(doRequestCloneJourney({
      id: journey.catalogId,
      catalogId,
      name,
      copyAudioVideo,
      coursesStartDateConfig,
      releaseDate: moment(releaseDate).toISOString(),
      cloneCourses,
      excludeCourses,
    })).then(result => {
      const payloadError = result?.payload?.error?.data;
      if (result.payload && !payloadError) {
        setHasError(false);
        onSuccess(result.payload);
      } else {
        if (payloadError) setFieldErrors(payloadError);
        setHasError(true);
      }
    });
  };

  const shouldDisableButton = () => !formState.isValid || !_.isEmpty(formState.errors);

  React.useEffect(() => {
    if (config?.courseStartDates === CourseStartDatesTypes.AUTOMATICALLY) {
      setReleaseDates({
        journeyBase: journey.releaseDate,
        journeyDraft: config.releaseDate,
      });
    }
  }, [config, journey]);

  const styles = css`
    .gray-box {
      padding: ${standardSpacing}px ${doubleSpacing}px;
      background: ${gray7};
    }

    .course-title-regular {
      display: flex;
      gap: ${halfSpacing}px;
    }

    .form-container {
      margin: 0 ${doubleSpacing}px;

      .catalog-id-description {
        height: ${standardSpacing}px;
      }

      .buttons {
        margin: 0 auto;
        margin-top: ${standardSpacing * 3}px;
        margin-bottom: ${standardSpacing}px;
      }

      .popover-wrapper {
        width: 100%;
      }

      .start-date {
        width: 100%;
      }

      .default-value {
        font-weight: ${boldFontWeight};
      }
      .no-padding-left {
        padding-left: 0;
      }
      .radio-button {
        label {
          font-size: ${textMediumFontSize}px;
        }
      }
      .bs4-dropdown-menu {
        min-width: 100% !important;
        padding: ${standardSpacing}px;
        .dropdown-item {
          .dropdown-item-title {
            font-size: ${textMediumFontSize}px;
            line-height: ${textMediumLineHeight}px;
          }
          .dropdown-item-description {
            font-weight: ${semiBoldFontWeight};
            font-size: ${textSmallFontSize}px;
            line-height: ${textSmallLineHeight}px;
          }
        }
      }
    }
  `;

  return (
    <div css={styles}>
      <p className='mb-4 course-title-regular'>
        <NvIcon icon='course' size='medium-large' />
        <span data-qa='course-information-clone-modal'>{ t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.COURSE_INFORMATION.TITLE() }</span>
      </p>
      <p className='mb-4 gray-box'>{ t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.COURSE_INFORMATION.DESCRIPTION() }</p>

      {hasError && <div className='error mb-5'>{ t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.COURSE_INFORMATION.ERROR() }</div>}

      {showBulkDates && <BulkCourseStartDates onApply={handleBulkApply} date={config?.bulkDate ? moment(config?.bulkDate) : moment()} />}

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className='mb-6'>
              {collections.map((collection, index) => (
                <JourneyCollection
                  key={collection.key}
                  collection={collection}
                  base={base[index]}
                  index={index}
                  control={control}
                  bulkDate={bulkDate}
                  releaseDates={releaseDates}
                />
              ))}
            </div>
            <div className='bs4-row buttons d-flex justify-content-center'>
              <Button
                variant='secondary'
                className='mr-2'
                data-qa='go-back'
                onClick={handleBack}
              >
                {t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.COURSE_INFORMATION.GO_BACK()}
              </Button>
              <Button
                type='submit'
                disabled={shouldDisableButton()}
                data-qa='start-cloning'
              >
                {t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.COURSE_INFORMATION.START_CLONING()}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CourseInformationCloneModal;
