/* @ngInject */
export default function MultipleChoiceMultipleAnswerQuestionService(

  _,
) {
  const MultipleChoiceMultipleAnswerQuestion = function (attributes) {
    const _this = this;
    const base = {
      templateUrl: 'quizzes/templates/questions/nv-multiple-choice-multiple-answer-question.html',
      displayState: null,
    };

    let answer = {
      correct: [],
      incorrect: [],
    };

    /** Setting Up Basic Attributes * */
    _.extend(_this, base);
    const responseOptions = attributes.responseOptions.map(option => ({ ...option }));
    _.extend(_this, { ...attributes, responseOptions });

    answerOptions();

    /** Public Functions * */
    _this.isUnAnswered = isUnAnswered;
    _this.responsePayload = responsePayload;
    _this.otherFieldResponsePayload = otherFieldResponsePayload;
    _this.setResponse = setResponse;
    _this.resetResponse = resetResponse;
    _this.updateResponseOptions = updateResponseOptions;
    _this.hasSelectedOneAtLeast = hasSelectedOneAtLeast;

    _this.getCorrectAnswer = function () {
      return answer;
    };

    _this.getResponse = function () {
      return _this.currentResponse;
    };

    /** Private Functions * */

    /* To calculate answerOptions for getCorrectAnswer */
    function answerOptions() {
      answer = {
        correct: [],
        incorrect: [],
      };

      _.each(_this.responseOptions, (option) => {
        if (option.isCorrect) {
          answer.correct.push(option.optionContent);
        } else {
          answer.incorrect.push(option.optionContent);
        }
      });
    }

    function isUnAnswered() {
      return _.every(_this.responseOptions, (responseOption) => !responseOption.isChecked);
    }

    function responsePayload() {
      const payload = _.compact(_.map(_this.responseOptions, (responseOption) => {
        if (responseOption.isChecked) {
          return responseOption.id;
        }
        return null;
      }));

      if (!payload.length) {
        return null;
      }
      return payload;
    }

    function otherFieldResponsePayload() {
      const payload = _.find(_this.responseOptions, (responseOption) => responseOption.isChecked && responseOption.otherField);

      if (!payload) {
        return null;
      }
      return payload.otherField;
    }

    function updateResponseOptions(question) {
      _this.responseOptions = question.responseOptions;
      answerOptions();
    }

    function hasSelectedOneAtLeast() {
      return this.responseOptions.some((responseOption) => responseOption.isChecked);
    }

    function setResponse(response) {
      _this.currentResponse = { ...response };
      _this.currentResponse.correctResponsesCount = _.where(_this.currentResponse.feedback, { isCorrect: true }).length;
      _this.currentResponse.incorrectResponsesCount = _.where(_this.currentResponse.feedback, { isCorrect: false }).length;

      _.each(response.response, (selectedResponseOptionId) => {
        _.findWhere(_this.responseOptions, { id: selectedResponseOptionId }).isChecked = true;
      });
    }

    function resetResponse() {
      _.each(_this.responseOptions, (responseOption) => {
        responseOption.isChecked = false;
      });
    }
  };

  return MultipleChoiceMultipleAnswerQuestion;
}
