import { normalize } from 'normalizr';
import { createReducer } from '@reduxjs/toolkit';
import { find } from 'underscore';

import {
  fetchComments,
  createComment,
  deleteComment,
  updateComment,
  voteUpComment,
  voteDownComment,
  addComment,
  editComment,
  removeComment,
  voteComment,
  getCommentLikersInfo,
  removeNewCommentId,
  getCommentsForSubmission,
  highlightComment,
  unHighlightComment,
  flagComment,
  translateComment
} from 'redux/actions/comments';

import {
  FetchCommentsNormalizedResult,
  FetchCommentsEntities,
  FetchCommentsSchema,
} from 'redux/schemas/api/comment';
import { DiscussionMember } from 'redux/schemas/models/post';
import { Submission } from 'redux/schemas/models/exercise';

import { mergeWith } from 'lodash';
import { replaceArrays } from 'shared/lodash-utils';
import { initialRootState } from '.';

/**
 * Find member by user id
 * @param {DiscussionMember[]} members the list of members to search through
 * @param {number} userId id to find user of member
 * @returns {number} the index of the member
 */
export const findMemberByUserId = (members: DiscussionMember[], userId: number) => find(members, m => m.user.id === userId);

const parseNewComment = (state, comment, postId = null, ownerType = null, ownerId = null, entityData = null) => {
  let storeData = null;

  if (postId) {
    storeData = state.models.posts[postId];
  } else if (ownerType === 'report' && ownerId) {
    storeData = state.models.submissions[ownerId];
  } else {
    return;
  }

  if (!storeData) return;

  if (entityData) {
    Object.keys(entityData).forEach((key) => {
      storeData[key] = entityData[key];
    });
  }

  if (state.models.comments[comment.id]) return;

  storeData.commentIds.push(comment.id);

  Object.assign(state.models.comments, {
    [comment.id]: {
      ...comment,
      likers: [],
    },
  });

  if (comment.isFirstContribution) {
    state.app.newCommentIdsForCurrentUser.push(comment.id);
  }
};

const parseDeleteComment = (state, commentId, postId = null, ownerType = null, ownerId = null, entityData = null) => {
  let storeData = null;
  if (postId) {
    storeData = state.models.posts[postId];
  } else if (ownerType === 'report' && ownerId) {
    storeData = state.models.submissions[ownerId];
  } else {
    return;
  }

  if (!storeData) return;

  if (entityData) {
    Object.keys(entityData).forEach((key) => {
      storeData[key] = entityData[key];
    });
  }

  if (!state.models.comments[commentId]) return;

  storeData.commentIds = storeData.commentIds.filter(id => id !== commentId);

  delete state.models.comments[commentId];
};

const parseVoteComment = (state, commentId, numLikes) => {
  Object.assign(state.models.comments[commentId], {
    votesCount: numLikes,
  });
};

export default createReducer(initialRootState, builder => {
  builder
    .addCase(removeNewCommentId, (state, action) => {
      state.app.newCommentIdsForCurrentUser = state.app.newCommentIdsForCurrentUser.filter(id => action.payload.commentId !== id);
    })
    .addCase(fetchComments.fulfilled, (state, action) => {
      const {
        entities: { comments },
        result: {
          posts,
          additionalPostsBeforeCount,
          additionalPostsAfterCount,
          additionalNewPostsBeforeCount,
          additionalNewPostsAfterCount,
        },
      } = normalize<FetchCommentsNormalizedResult, FetchCommentsEntities>(action.payload, FetchCommentsSchema);

      const { postId, beforeId, afterId } = action.meta.arg;
      const post = state.models.posts[postId];

      let commentIds = [...(post.commentIds ?? [])];
      if (beforeId) {
        commentIds = [...posts, ...commentIds];
      } else if (afterId) {
        commentIds = [...commentIds, ...posts];
      } else {
        commentIds = [...posts];
      }

      Object.assign(post, {
        commentIds,
        additionalCommentsAfterCount: additionalPostsAfterCount,
        additionalCommentsBeforeCount: additionalPostsBeforeCount,
        additionalNewCommentsBeforeCount: additionalNewPostsBeforeCount,
        additionalNewCommentsAfterCount: additionalNewPostsAfterCount,
        commentsFetched: true,
      });

      Object.assign(state.models.comments, comments);
    })
    .addCase(getCommentLikersInfo.fulfilled, (state, action) => {
      const { commentId } = action.meta.arg;
      const comment = state.models.comments[commentId];
      comment.likers = action.payload;
      comment.likersFetched = true;
    })
    .addCase(addComment.fulfilled, (state, action) => {
      const { comment, postId } = action.payload;
      const { ownerType = null, ownerId = null, postsCount = null } = action.meta.arg;

      parseNewComment(state, comment, postId, ownerType, ownerId, {
        postsCount,
      });
    })
    .addCase(createComment.fulfilled, (state, action) => {
      const comment = action.payload;
      const { postId = null, ownerType = null, ownerId = null } = action.meta.arg;

      parseNewComment(state, comment, postId, ownerType, ownerId, {
        postsCount: comment?.owner?.postsCount,
        numPostsAndComments: comment?.owner?.numPostsAndComments,
      });
    })
    .addCase(removeComment.fulfilled, (state, action) => {
      const { commentId, postId, userId } = action.payload;
      const { ownerType = null, ownerId = null, postsCount = null } = action.meta.arg;

      parseDeleteComment(state, commentId, postId, ownerType, ownerId, {
        postsCount,
      });
    })
    .addCase(deleteComment.fulfilled, (state, action) => {
      const comment = action.payload;
      const { commentId, postId = null, ownerType = null, ownerId = null } = action.meta.arg;

      parseDeleteComment(state, commentId, postId, ownerType, ownerId, {
        postsCount: comment?.owner?.postsCount,
        numPostsAndComments: comment?.owner?.numPostsAndComments,
      });
    })
    .addCase(editComment.fulfilled, (state, action) => {
      const { comment } = action.payload;
      Object.assign(state.models.comments[comment.id], comment);
    })
    .addCase(updateComment.fulfilled, (state, action) => {
      const { commentId } = action.meta.arg;
      Object.assign(state.models.comments[commentId], action.payload);
    })
    .addCase(voteComment.fulfilled, (state, action) => {
      const { commentId, numLikes } = action.payload;
      parseVoteComment(state, commentId, numLikes);
    })
    .addCase(voteUpComment.fulfilled, (state, action) => {
      const { commentId } = action.meta.arg;
      const { numLikes } = action.payload;

      Object.assign(state.models.comments[commentId], {
        votesCount: numLikes,
        liked: true,
      });
    })
    .addCase(voteDownComment.fulfilled, (state, action) => {
      const { commentId } = action.meta.arg;
      const { numLikes } = action.payload;

      Object.assign(state.models.comments[commentId], {
        votesCount: numLikes,
        liked: false,
      });
    })
    .addCase(highlightComment.fulfilled, (state, action) => {
      const { commentId } = action.meta.arg;

      Object.assign(state.models.comments[commentId], {
        highlighted: true,
      });
    })
    .addCase(unHighlightComment.fulfilled, (state, action) => {
      const { commentId } = action.meta.arg;

      Object.assign(state.models.comments[commentId], {
        highlighted: false,
      });
    })
    .addCase(flagComment.fulfilled, (state, action) => {
      const { commentId } = action.meta.arg;

      Object.assign(state.models.comments[commentId], {
        flagged: true,
      });
    })
    .addCase(getCommentsForSubmission.fulfilled, (state, action) => {
      const { reportId, beforeId, afterId } = action.meta.arg;

      const {
        entities: { comments },
        result: {
          posts,
          additionalPostsBeforeCount,
          additionalPostsAfterCount,
          additionalNewPostsBeforeCount,
          additionalNewPostsAfterCount,
        },
      } = normalize<FetchCommentsNormalizedResult, FetchCommentsEntities>(
        action.payload,
        FetchCommentsSchema,
      );

      const submission = state.models.submissions[reportId] as Submission;
      let commentIds = [...(submission.commentIds ?? [])];
      if (beforeId) {
        commentIds = [...posts, ...commentIds];
      } else if (afterId) {
        commentIds = [...commentIds, ...posts];
      } else {
        commentIds = [...posts];
      }
      submission.commentIds = commentIds;

      mergeWith(state.models.comments, comments, (objValue, srcValue, key) => {
        // Backend doesn't return replyIds, but is
        // initialised from schema
        // No need to overwrite replyIds if it is set
        if (!(key === 'replyIds' && objValue)) {
          replaceArrays(objValue, srcValue);
        }
      });

      state.app.exerciseSubmissions[reportId] = {
        ...state.app.exerciseSubmissions[reportId],
        additionalCommentsAfterCount: additionalPostsAfterCount,
        additionalCommentsBeforeCount: additionalPostsBeforeCount,
        additionalNewCommentsBeforeCount: additionalNewPostsBeforeCount,
        additionalNewCommentsAfterCount: additionalNewPostsAfterCount,
        commentsFetched: true,
      };
    })
    .addCase(translateComment.pending, (state, action) => {
      const { commentId } = action.meta.arg;
      const { body } = state.models.comments[commentId]

      state.app.discussionTranslation.comments[commentId] = {
        ...state.app.discussionTranslation.comments[commentId],
        body,
        isLoading: true,
        error: false
      }
    })
    .addCase(translateComment.fulfilled, (state, action) => {
      const { commentId } = action.meta.arg;
      const { body } = action.payload
      state.app.discussionTranslation.comments[commentId] = {
        ...state.app.discussionTranslation.comments[commentId],
        body,
        isLoading: false,
        error: false,
      }
    })
    .addCase(translateComment.rejected, (state, action) => {
      const { commentId } = action.meta.arg;
      
      state.app.discussionTranslation.comments[commentId] = {
        ...state.app.discussionTranslation.comments[commentId],
        isLoading: false,
        error: true
      }
    });
});
