import React from 'react';
import uuid from 'react-uuid';

type ContextValue = {
  isSaving: () => boolean,
  registerSaving: () => () => void,
};

export const SavingRegistryContext = React.createContext<ContextValue>(null);

const useSavingRegistry = (): ContextValue => {
  const savingRegistryRef = React.useRef<Record<string, boolean>>({});

  const registerSaving = React.useCallback(() => {
    const key = uuid();

    savingRegistryRef.current[key] = true;

    return () => {
      delete savingRegistryRef.current[key];
    };
  }, []);

  const isSaving = React.useCallback(() => !!Object.keys(savingRegistryRef.current).length, []);

  return {
    isSaving,
    registerSaving,
  };
};

export default useSavingRegistry;

