import { setLanguage } from 'react-translate';

/* @ngInject */
export default function LangsAndTzController(
  _,
  $uibModal,
  config,
  CurrentUserManager,
  TimezonesService,
  StateManager
) {
  const vm = this;
  vm.$uibModal = $uibModal;
  vm.CurrentUserManager = CurrentUserManager;
  vm.languages = config.platformLanguages;
  vm.selectedLanguage = vm.CurrentUserManager.user.platformLanguage;
  vm.timezones = TimezonesService.timezones;
  vm.selectedTimezone = _.find(TimezonesService.timezones, (t) => t.longName === vm.CurrentUserManager.user.timeZone);
  vm.isAutomaticTimezone = vm.selectedTimezone == null;

  vm.tabConfig = StateManager.accountSettingsTabs.find(tab => tab.state === 'language-and-timezone');

  vm.onLanguageSelected = (selectedLanguage) => {
    vm.selectedLanguage = selectedLanguage;
    vm.saving = true; // The 'Translating...' modal is shown until this is set false

    const uibModalInstance = $uibModal.open({
      backdropClass: 'modal-overlay-backdrop',
      templateUrl: 'shared/templates/language_loading_overlay.html',
      windowClass: 'modal-overlay',
      keyboard: false,
    });

    vm.CurrentUserManager.updatePlatformLanguage(selectedLanguage).then(() => {
      vm.saving = false;
    });

    vm.CurrentUserManager.user.platformLanguage = selectedLanguage;
    vm.previousLanguage = selectedLanguage;

    vm.CurrentUserManager.updateOrigamiLanguage(selectedLanguage).then(() => {
      uibModalInstance.close();
      uibModalInstance.rendered.then(() => {
        uibModalInstance.close();
      });
    });
  };

  vm.onTimezoneSelected = (selectedTimezone) => {
    vm.isAutomaticTimezone = false;

    vm.selectedTimezone = selectedTimezone;
    vm.updateTimezone(selectedTimezone);
  };

  vm.onAutomaticTimezoneSelected = () => {
    vm.isAutomaticTimezone = true;
    vm.updateTimezone(null);
  };

  vm.updateTimezone = (timezone) => {
    vm.CurrentUserManager.user.updateTimezone(vm.isAutomaticTimezone, timezone);
  };

  return vm;
}
