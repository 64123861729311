export default {
  bindings: {
    manager: '<',
    course: '<',
    selectTab: '&',
    hideViewAllButton: '<?',
  },
  controller: function ctrl(
    CurrentUserManager,
    TimelinesManager,
  ) {
'ngInject';
    const vm = this;
    vm.CurrentUserManager = CurrentUserManager;
    vm.TimelinesManager = TimelinesManager;
    return vm;
  },
  controllerAs: 'vm',
  templateUrl: 'timelines/templates/nv-timeline-required-assignments-for-completion.html',
};
