/* @ngInject */
export default function NvSubmissionGalleryCard(
  _,
  moment,
  $state,
  $stateParams,
  $translate,
  nvUtil,
  InteroperabilityRoutes,
  config,

  CurrentUserManager,
  ReportsManager,
) {
  const MINUTE_THRESHOLD = 60 * 1000;
  const HOUR_THRESHOLD = 60 * MINUTE_THRESHOLD;
  const DAY_THRESHOLD = 24 * HOUR_THRESHOLD;

  const REVISION_DATE_STRINGS = {
    days: 'TIMELINE.EXERCISE_DUE.REVISE_IN_DAYS',
    hours: 'TIMELINE.EXERCISE_DUE.REVISE_IN_HOURS',
    minutes: 'TIMELINE.EXERCISE_DUE.REVISE_IN_MINUTES',
    date: 'TIMELINE.DUE_DEADLINE',
  };

  const DUE_DATE_STRINGS = {
    days: 'TIMELINE.EXERCISE_DUE.DUE_IN_DAYS',
    hours: 'TIMELINE.EXERCISE_DUE.DUE_IN_HOURS',
    minutes: 'TIMELINE.EXERCISE_DUE.DUE_IN_MINUTES',
    date: 'TIMELINE.DUE_DEADLINE',
  };

  return {
    restrict: 'A',
    scope: {
      report: '=',
      directlyLinked: '=',
      galleryMode: '=',
      completedPublicFeedback: '=?',
      catalogId: '=?',
      showCourseHeaderInLink: '=?',
      clickHandler: '=?', // optional, what should happen when a user clicks the card. this will only trigger if directlyLinked is false

      // header display options
      showApprovalProgress: '=?',
      showApprovalPendingTime: '=?',

      // for the following options -  we only show the first one that is available
      showRevisionRemainderTime: '=?',
      showDueDate: '=?',
      hideSocial: '=?',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
      let translateKeys;

      _.extend(vm, {
        nvUtil,
        InteroperabilityRoutes,
        CurrentUserManager,
        config,

        submissionExercise: vm.report.exercise || _.findWhere(ReportsManager.allExercises, { id: vm.report.exerciseId }),

        pendingTime,
      });

      if (!vm.catalogId) {
        if (vm.report.exercise && (vm.report.exercise.course || vm.report.exercise.catalogId)) {
          vm.catalogId = vm.report.exercise.catalogId || vm.report.exercise.course.catalogId;
        } else {
          vm.catalogId = ReportsManager.catalogId || $stateParams.catalogId;
        }
      }

      let effectiveDeadline;
      if (vm.report.exercise) {
        effectiveDeadline = vm.report.exercise.extendedDeadline || vm.report.exercise.deadline;
      }

      if (effectiveDeadline) {
        if (vm.showRevisionRemainderTime && vm.report.isRejectedCanRevise()) {
          translateKeys = REVISION_DATE_STRINGS;
        } else if (vm.showDueDate && !(vm.report.isCompleted() || vm.report.isApproved())) {
          translateKeys = DUE_DATE_STRINGS;
        }

        if (translateKeys) {
          const dueDateMoment = moment(effectiveDeadline);
          const timeDifference = dueDateMoment - moment();

          if (timeDifference && timeDifference > 0) {
            if (Math.floor(timeDifference / DAY_THRESHOLD)) {
              if (Math.floor(timeDifference / DAY_THRESHOLD) <= 7) {
                vm.isDueSoon = true;
                vm.dateText = $translate.instant(translateKeys.days, { count: Math.floor(timeDifference / DAY_THRESHOLD) });
              } else {
                vm.dateText = $translate.instant(translateKeys.date, { deadline: dueDateMoment.format('MOMENT.MONTH_DAY_YEAR') });
              }
            } else if (Math.floor(timeDifference / HOUR_THRESHOLD)) {
              vm.isDueSoon = true;
              vm.dateText = $translate.instant(translateKeys.hours, { count: Math.floor(timeDifference / HOUR_THRESHOLD) });
            } else {
              vm.isDueSoon = true;
              vm.dateText = $translate.instant(translateKeys.minutes, { count: Math.floor(timeDifference / MINUTE_THRESHOLD) || 1 });
            }
          } else {
            vm.dateText = $translate.instant(translateKeys.date, { deadline: dueDateMoment.format('MOMENT.MONTH_DAY_YEAR') });
          }
        }
      }

      vm.individualLink = $state.href('individual-submission-modal', {
        catalogId: vm.catalogId,
        reportId: vm.report.id,
        galleryMode: vm.galleryMode,
        showCourseHeader: vm.showCourseHeaderInLink,
      });

      function pendingTime() {
        const submissionStatusDate = (vm.report.submittingObject?.submissionStatusDate)
            || (vm.report.submitting?.submissionStatusDate);
        const timeDifference = moment() - moment(submissionStatusDate);

        if (Math.floor(timeDifference / DAY_THRESHOLD)) {
          return $translate.instant('SUBMISSION_APPROVAL.PENDING.DAYS', { count: Math.floor(timeDifference / DAY_THRESHOLD) });
        } if (Math.floor(timeDifference / HOUR_THRESHOLD)) {
          return $translate.instant('SUBMISSION_APPROVAL.PENDING.HOURS', { count: Math.floor(timeDifference / HOUR_THRESHOLD) });
        }
        return $translate.instant('SUBMISSION_APPROVAL.PENDING.MINUTES', { count: Math.floor(timeDifference / MINUTE_THRESHOLD) || 1 });
      }
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'submissions/templates/nv-submission-gallery-card.html',
  };
}
