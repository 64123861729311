import { createReducer } from '@reduxjs/toolkit';
import { disableOrgLevelCredlySettings, enableCredlyBadgeTemplateId, removeCredlyBadgeTemplateId, saveOrgLevelCredlySettings } from 'redux/actions/credly';
import { FullCourse } from 'redux/schemas/models/courseFull';
import { CredlySettingsPayload } from 'redux/schemas/models/credly';
import { getCurrentCourse } from 'redux/selectors/course';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { initialRootState } from './index';

export const getCredlySettings = (payload: CredlySettingsPayload) => {
  const { isCredlyEnable, credlyConfig: { organizationId, organizationToken } } = payload.institutionList;

  return {
    isActive: String(isCredlyEnable).toLowerCase() === 'true',
    organizationId,
    organizationToken,
  };
};

export default createReducer(initialRootState, builder => {
  builder.addCase(saveOrgLevelCredlySettings.fulfilled, (state, action) => {
    const currentInstitution = getCurrentInstitution(state);

    Object.assign(currentInstitution, action.payload.institutionList);
  });

  builder.addCase(disableOrgLevelCredlySettings.fulfilled, (state, action) => {
    const currentInstitution = getCurrentInstitution(state);

    Object.assign(currentInstitution, action.payload.institutionList);
  });

  builder.addCase(enableCredlyBadgeTemplateId.fulfilled, (state, action) => {
    const currentCourse: FullCourse = getCurrentCourse(state);

    currentCourse.credlyBadgeInfo = {
      id: '',
      name: '',
      description: '',
      url: '',
      imageUrl: '',
      state: 'draft',
    };

    Object.assign(currentCourse.credlyBadgeInfo, action.payload?.courseList?.credlyBadgeInfo);
  });

  builder.addCase(removeCredlyBadgeTemplateId.fulfilled, (state, action) => {
    const currentCourse: FullCourse = getCurrentCourse(state);

    currentCourse.credlyBadgeInfo = {
      id: '',
      name: '',
      description: '',
      url: '',
      imageUrl: '',
      state: 'draft',
    };

    Object.assign(currentCourse.credlyBadgeInfo, action.payload?.courseList?.credlyBadgeInfo);
  });
});
