
import { useState, Dispatch, SetStateAction } from 'react';
import { css } from '@emotion/react';
import { gray6, gray7, danger } from 'styles/global_defaults/colors';
import NvIcon from 'shared/components/nv-icon';
import ClickableContainer from 'components/clickable-container';
import t from 'react-translate';
import { config } from '../../../config/pendo.config.json';

type DeleteBookmarkProps = {
  isHover: boolean;
  setIsHover: Dispatch<SetStateAction<boolean>>;
  isHighlighted: boolean;
  onDelete: () => void;
  index?: number;
};

const DeleteBookmark = ({
  isHover,
  setIsHover,
  isHighlighted,
  onDelete,
  index,
}: DeleteBookmarkProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const styles = css`
    width: 36px;
    display: flex;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    background-color: white;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    box-sizing: border-box;
    z-index: 1;
    ${isHighlighted && css`
      background-color: ${gray7};
    `};

    &:focus {
      background-color: ${gray6};
    }

    ${isHover && css`
      background-color: ${gray6};
    `};

    .icon-more {
      transform: rotate(90deg);
    }

    .icon-trash {
      color: ${danger};
    }
  `;

  return (
    <ClickableContainer
      className='delete-bookmark'
      css={styles}
      onClick={onDelete}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      data-qa={`${config.pendo.bookmarks.delete}${index}`}
      aria-label={t.FORM.DELETE()}
    >
      {(isHover || isFocused) ? (
        <NvIcon icon='trash' size='smallest' />
      ) : (
        <NvIcon icon='more' size='smallest' />
      )}
    </ClickableContainer>
  );
};

export default DeleteBookmark;
