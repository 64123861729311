import { MockDefinition, MockTypes } from '../axios/axios-mocks';

const resultCase1a = {
  completionInfo: {
    journeyCompleted: false,
    courseCompleted: true,
  },
  journey: {
    nextCourse: {
      id: 30,
      catalogId: 'fourteenth_course',
      name: 'Fourteenth Course',
      thumbnail: '/assets/brand/temp.png',
      squareThumbnail: '/assets/brand/temp.png',
      releaseDate: '2023-07-01T05:00:00Z',
      endDate: null,
      createdAt: '2023-07-07T14:33:38Z',
      inProgress: true,
      offeringName: {
        capitalizedPluralized: 'Courses',
        capitalizedSingularized: 'Course',
        downcasedSingularized: 'course',
        downcasedPluralized: 'courses',
        pluralizedTitleized: 'Courses',
        singularizedTitleized: 'Course',
      },
      officialReleaseDate: '2023-07-01T05:00:00Z',
      closeDate: null,
      released: true,
      isProgram: false,
      isJourney: false,
      isSelfPaced: false,
      showRecentlyActive: true,
      headerColor: '#ffffff',
      nameFontColor: '#000',
      logo: null,
      automatedCompletionsEnabled: false,
      headerBackground: null,
      institutionId: 1,
    },
  },
  additionalJourneysCount: null,
};

const resultCase1b = {
  completionInfo: {
    journeyCompleted: false,
    courseCompleted: true,
  },
  journey: {
    nextCourse: {},
    id: 22,
    catalogId: 'single_journey',
    name: 'Single Journey',
    thumbnail: '/assets/brand/temp.png',
    squareThumbnail: '/assets/brand/temp.png',
    releaseDate: '2023-05-01T05:00:00Z',
    endDate: null,
    createdAt: '2023-05-29T16:27:29Z',
    inProgress: true,
    officialReleaseDate: null,
    closeDate: null,
    released: true,
    certificateName: {
      capitalizedPluralized: 'Statement of accomplishments',
      capitalizedSingularized: 'Statement of accomplishment',
      downcasedSingularized: 'statement of accomplishment',
      downcasedPluralized: 'statement of accomplishments',
      pluralizedTitleized: 'Statement Of Accomplishments',
      singularizedTitleized: 'Statement Of Accomplishment',
    },
    isProgram: false,
    isJourney: true,
    isSelfPaced: null,
    typeOfRegistrationInWords: 'Open within your organization',
    inArchiveMode: true,
    headerColor: '#b1a2c3',
    nameFontColor: '#fff',
    logo: null,
    contentLanguage: 'en_US',
    headerBackground: 'https://d2lyqn1wunb6x9.cloudfront.net/courses/header_backgrounds/45086801da7ceb50839d8fbe01411a7376808ffc.jpeg?AWSAccessKeyId=AKIAX2L5PPJEXNHPYSNH&Expires=1690585749&Signature=VYsTUCpWsMLTxsmvoT7EysepZbg%3D',
    completionStats: {
      total: 1,
      required: 1,
      completed: 1,
      collections: [
        {
          id: 8,
          total: 1,
          required: 1,
          completed: 1,
        },
      ],
    },
    userCourse: 82,
    institutionId: 1,
    registrationCloseDate: null,
    institution: 1,
    isClosed: false,
    type: 'Journey',
    typeOfRegistration: 4,
    soaCertificateEnabled: true,
    canTakeCourse: true,
    coursesCount: 1,
  },
  additionalJourneysCount: null,
};

const resultCase1c = {
  completionInfo: {
    journeyCompleted: false,
    courseCompleted: true,
  },
  journey: {
    nextCourse: {},
    id: 22,
    catalogId: 'single_journey',
    name: 'Single Journey',
    thumbnail: '/assets/brand/temp.png',
    squareThumbnail: '/assets/brand/temp.png',
    releaseDate: '2023-05-01T05:00:00Z',
    endDate: null,
    createdAt: '2023-05-29T16:27:29Z',
    inProgress: true,
    officialReleaseDate: null,
    closeDate: null,
    released: true,
    certificateName: {
      capitalizedPluralized: 'Statement of accomplishments',
      capitalizedSingularized: 'Statement of accomplishment',
      downcasedSingularized: 'statement of accomplishment',
      downcasedPluralized: 'statement of accomplishments',
      pluralizedTitleized: 'Statement Of Accomplishments',
      singularizedTitleized: 'Statement Of Accomplishment',
    },
    isProgram: false,
    isJourney: true,
    isSelfPaced: null,
    typeOfRegistrationInWords: 'Open within your organization',
    inArchiveMode: true,
    headerColor: '#b1a2c3',
    nameFontColor: '#fff',
    logo: null,
    contentLanguage: 'en_US',
    headerBackground: 'https://d2lyqn1wunb6x9.cloudfront.net/courses/header_backgrounds/45086801da7ceb50839d8fbe01411a7376808ffc.jpeg?AWSAccessKeyId=AKIAX2L5PPJEXNHPYSNH&Expires=1690585749&Signature=VYsTUCpWsMLTxsmvoT7EysepZbg%3D',
    completionStats: {
      total: 1,
      required: 1,
      completed: 1,
      collections: [
        {
          id: 8,
          total: 1,
          required: 1,
          completed: 1,
        },
      ],
    },
    userCourse: 82,
    institutionId: 1,
    registrationCloseDate: null,
    institution: 1,
    isClosed: false,
    type: 'Journey',
    typeOfRegistration: 4,
    soaCertificateEnabled: true,
    canTakeCourse: true,
    coursesCount: 1,
  },
  additionalJourneysCount: null,
};

const resultCase2 = {
  completionInfo: {
    jQueryourneyCompleted: true,
    courseCompleted: true,
  },
  journey: {
    nextCourse: {},
    id: 22,
    catalogId: 'single_journey',
    name: 'Single Journey',
    thumbnail: '/assets/brand/temp.png',
    squareThumbnail: '/assets/brand/temp.png',
    releaseDate: '2023-05-01T05:00:00Z',
    endDate: null,
    createdAt: '2023-05-29T16:27:29Z',
    inProgress: true,
    officialReleaseDate: null,
    closeDate: null,
    released: true,
    certificateName: {
      capitalizedPluralized: 'Statement of accomplishments',
      capitalizedSingularized: 'Statement of accomplishment',
      downcasedSingularized: 'statement of accomplishment',
      downcasedPluralized: 'statement of accomplishments',
      pluralizedTitleized: 'Statement Of Accomplishments',
      singularizedTitleized: 'Statement Of Accomplishment',
    },
    isProgram: false,
    isJourney: true,
    isSelfPaced: null,
    typeOfRegistrationInWords: 'Open within your organization',
    inArchiveMode: true,
    headerColor: '#b1a2c3',
    nameFontColor: '#fff',
    logo: null,
    contentLanguage: 'en_US',
    headerBackground: 'https://d2lyqn1wunb6x9.cloudfront.net/courses/header_backgrounds/45086801da7ceb50839d8fbe01411a7376808ffc.jpeg?AWSAccessKeyId=AKIAX2L5PPJEXNHPYSNH&Expires=1690585749&Signature=VYsTUCpWsMLTxsmvoT7EysepZbg%3D',
    completionStats: {
      total: 1,
      required: 1,
      completed: 1,
      collections: [
        {
          id: 8,
          total: 1,
          required: 1,
          completed: 1,
        },
      ],
    },
    userCourse: 82,
    institutionId: 1,
    registrationCloseDate: null,
    institution: 1,
    isClosed: false,
    type: 'Journey',
    typeOfRegistration: 4,
    soaCertificateEnabled: true,
    canTakeCourse: true,
    coursesCount: 1,
  },
  additionalJourneysCount: null,
};

const resultCase3 = {
  completionInfo: {
    journeyCompleted: false,
    courseCompleted: false,
  },
  journey: {
    nextCourse: {},
  },
  additionalJourneysCount: null,
};

const CourseCompletionStatusMocks: MockDefinition[] = [
  {
    type: MockTypes.GET,
    url: '/courses/thirteenth_course/completion_status.json',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: resultCase3,
    },
  },
];

export default CourseCompletionStatusMocks;
