/* @ngInject */
export function AccountSettingsResource(
  $resource,
  $httpParamSerializerJQLike,
) {
  const USER_UPDATE_URL = '/users/update';
  return $resource(USER_UPDATE_URL,
    {},
    {
      put: {
        url: USER_UPDATE_URL,
        method: 'PUT',
        headers: {
          Accept: 'application/json',
        },
      },
    });
}

/* @ngInject */
export function EmailUnsubscriptionsResource(
  $resource,
) {
  return $resource('/emails',
    {},
    {
      get: {
        method: 'GET',
        url: '/emails/unsubscriptions',
      },
      update: {
        method: 'POST',
        url: '/emails/unsubscribe',
      },
    });
}

/* @ngInject */
export function EmailVerificationResource(
  $resource,
) {
  return $resource('/users/send_email_verification');
}
