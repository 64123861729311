/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ComponentType, LectureComponent } from 'redux/schemas/models/lecture-component';
import { User } from 'redux/schemas/models/my-account';
import {
  Poll,
  PollNormalized,
  PollOption,
  PollQuestion,
  PollQuestionNormalized,
  PollVieweeSubmission,
} from 'redux/schemas/models/poll';
import { NewVotePushNotificationPayload } from 'lecture_pages/components/polls-lecture-component/poll-utils';

type CreatePollOptionResponse = PollOption;

interface CreatePollOptionRequest {
  catalogId: string;
  questionId: number;
}

export const createPollOption = createAsyncThunk<CreatePollOptionResponse, CreatePollOptionRequest>(
  'CREATE_POLL_OPTION',
  async (params) => {
    const data = {
      responseOption: {
        questionId: params.questionId,
        option_content: '',
      },
    };
    const response = await axios.post(
      `${params.catalogId}/questions/${params.questionId}/response_options`,
      data,
    );
    return response.data.result;
  },
);

interface UpdatePollOptionRequest {
  catalogId: string;
  responseOptionId: number;
  optionContent: string;
}

export const updatePollOption = createAsyncThunk<void, UpdatePollOptionRequest>(
  'UPDATE_POLL_OPTION',
  async (params) => {
    const data = {
      response_option: {
        [params.responseOptionId]: {
          optionContent: params.optionContent,
        },
      },
    };
    await axios.put(`${params.catalogId}/response_options/${params.responseOptionId}`, data);
  },
);

interface DeletePollOptionRequest {
  catalogId: string;
  responseOptionId: number;
  questionId: number;
}

export const deletePollOption = createAsyncThunk<void, DeletePollOptionRequest>(
  'DELETE_POLL_OPTION',
  async (params) => {
    await axios.delete(`${params.catalogId}/response_options/${params.responseOptionId}`);
  },
);

interface PollVoteRequest {
  catalogId: string;
  responseOptionId: number;
  pollId: number;
}

interface PollVoteResponse {
  questions: PollQuestion[];
  responses: PollVieweeSubmission['responses'];
}

export const pollVote = createAsyncThunk<PollVoteResponse, PollVoteRequest>('POLL_VOTE', async (params) => {
  const data = {
    question_set_id: params.pollId,
    user_response: {
      0: params.responseOptionId,
    },
    option_fields: {},
    save_submission: false,
  };
  const response = await axios.post(
    `${params.catalogId}/question_set_submissions.json?question_set_id=${params.pollId}`,
    data,
  );
  return response.data.result;
});

interface UpdatePollRequest {
  catalogId: string;
  lecturePageId: number;
  lectureComponentId: number;
  poll: Partial<PollNormalized>;
}

export const updatePoll = createAsyncThunk<LectureComponent<ComponentType.POLL>, UpdatePollRequest>(
  'UPDATE_POLL',
  async (params) => {
    const data = {
      lecture_component: {
        poll: params.poll,
        only_downstream_outline_update: false,
      },
    };

    const response = await axios.put(
      `${params.catalogId}/lecture_pages/${params.lecturePageId}/poll_lecture_component/${params.lectureComponentId}`,
      data,
    );
    return response.data.result;
  },
);

interface UpdatePollQuestionRequest {
  questionId: number;
  catalogId: string;
  questionText: string;
}

export const updatePollQuestion = createAsyncThunk<PollQuestionNormalized, UpdatePollQuestionRequest>(
  'UPDATE_POLL_QUESTION',
  async (params) => {
    const data = {
      question: {
        questionText: params.questionText,
        onlyDownstreamOutlineUpdate: false,
      },
    };

    const result = await axios.put(`${params.catalogId}/questions/${params.questionId}`, data);
    return result.data;
  },
);

interface ReorderPollOptionsRequest {
  questionId: number;
  catalogId: string;
  order: number[];
}

export const reorderPollOptions = createAsyncThunk<any, ReorderPollOptionsRequest>(
  'REORDER_POLL_OPTIONS',
  async (params) => {
    const data = {
      responseOptions: params.order,
    };

    const result = await axios.put(
      `${params.catalogId}/questions/${params.questionId}/update_response_options_order`,
      data,
    );
    return result.data;
  },
);

type AddNewVoteRequest = NewVotePushNotificationPayload & {
  catalogId: string;
};

export const addNewVote = createAsyncThunk<User | undefined, AddNewVoteRequest>('ADD_NEW_VOTE', async (params) => {
  if (!params.user_id) {
    return undefined;
  }
  const response = await axios.get(`${params.catalogId}/users/user/${params.user_id}`);
  return response.data;
});
