import React, { useContext, useEffect, useState } from 'react';
import t from 'react-translate';
import { AngularContext } from 'react-app';
import { Button } from 'react-bootstrap';
import { useAppDispatch } from 'redux/store';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { disableHCMIntegration } from 'redux/actions/hcm';
import { HCMIntegrationTypes, HCMSettingsPayload } from 'redux/schemas/models/hcm';
import { wrapThunkAction } from 'redux/utils';
import ClickableContainer from 'components/clickable-container';
import { config } from '../../../../../config/pendo.config.json';
import SectionContent from '../section-content';
import HCMDataMapping from './hcm-data-mapping';
import HCMConfiguration from './hcm-configuration';

const HcmIntegration = () => {
  const { injectServices, $scope } = useContext(AngularContext);
  const [InstitutionsManager] = injectServices(['InstitutionsManager']);
  const [hcmEnabled, setHcmEnabled] = useState($scope.$eval('vm.InstitutionsManager.institution.novoedHcmEnabled'));
  const [showConfigModal, setShowConfigModal] = useState(false);
  const [showDataMapping, setShowDataMapping] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    $scope.$watch('vm.InstitutionsManager.institution.novoedHcmEnabled', (newValue) => {
      setHcmEnabled(newValue);
    });
  }, [$scope]);

  const hasHCMConfig = () => {
    const { hcmConfig = {} } = InstitutionsManager.institution;
    const { endpoint, username, password, integrationType } = hcmConfig;
    return endpoint && username && password && integrationType;
  };

  const disableHCM = () => {
    dispatch(openConfirmationDialog({
      title: t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.DISABLE(),
      bodyText: t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.DISABLE_DESCRIPTION(),
      confirmText: t.FORM.YES_SURE(),
      cancelDataQa: config.pendo.hcm.integrationCancelDisable,
      confirmDataQa: config.pendo.hcm.integrationConfirmDisable,
      onConfirm: () => {
        const hcmConfig = {
          endpoint: '',
          username: '',
          password: '',
        };

        wrapThunkAction(
          dispatch(
            disableHCMIntegration({
              institutionId: InstitutionsManager.institution.id,
              ...hcmConfig,
            } as HCMSettingsPayload),
          ),
        ).then(() => {
          InstitutionsManager.institution.updateFromReact({ hcmConfig });
        });
      },
    }));
  };

  return (
    <React.Fragment>
      <SectionContent
        header={t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.TITLE()}
        description={[t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.DESCRIPTION()]}
        showCTAButton={!hasHCMConfig()}
        ctaText={t.INSTITUTIONS.ADVANCED_SETTINGS.CONFIGURE()}
        onAction={() => setShowConfigModal(true)}
        dataQa={config.pendo.hcm.integrationConfigure}
        tooltipEnabled={!hcmEnabled}
        disabled={!hcmEnabled}
        tooltipText={t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.CONFIGURE_TOOLTIP()}
        extras={hasHCMConfig() && (
        <React.Fragment>
          <div className='d-flex text-regular align-self-start'>
            <span>{t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.CSV_DESCRIPTION()}</span>
            <ClickableContainer
              className='text-primary ml-2'
              onClick={() => setShowDataMapping(true)}
            >
              {t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.CLICK_HERE()}
            </ClickableContainer>
          </div>
          <div className='button-bar mt-6'>
            <Button
              variant='secondary'
              onClick={disableHCM}
              data-qa={config.pendo.hcm.integrationDisable}
            >
              {t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.DISABLE()}
            </Button>
            <Button
              variant='primary'
              onClick={() => setShowConfigModal(true)}
              data-qa={config.pendo.hcm.integrationEdit}
            >
              {InstitutionsManager?.institution?.hcmConfig
                ?.integrationType === HCMIntegrationTypes.CSV_SFTP
                ? t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.DISPLAY_DETAILS()
                : t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.EDIT()}
            </Button>
          </div>
        </React.Fragment>

        )}
      />
      <HCMConfiguration show={showConfigModal} onClose={() => setShowConfigModal(false)} />
      <HCMDataMapping show={showDataMapping} onClose={() => setShowDataMapping(false)} />
    </React.Fragment>
  );
};

export default HcmIntegration;
