import { isEmpty } from 'underscore';

import { RootState } from 'redux/schemas';

export const getBookmarksList = (state: RootState) => state.app.bookmarks.ids.map(id => state.app.bookmarks.all[id]).filter(bookmark => !isEmpty(bookmark));

export const getFilteredBookmarks = (state: RootState) => {
  if (state.app.bookmarks.highlighted && !isEmpty(state.app.bookmarks.all)) {
    const highlightedBookmark = state.app.bookmarks.all?.[state.app.bookmarks.highlighted];
    return highlightedBookmark ? [highlightedBookmark] : [];
  }

  if (state.app.bookmarks.filter) {
    return state.app.bookmarks.courses[state.app.bookmarks.filter]?.ids.map(id => state.app.bookmarks.all[id]).filter(bookmark => !isEmpty(bookmark)) || [];
  }

  return getBookmarksList(state);
};

export const getHighlightedBookmark = (state: RootState) => state.app.bookmarks.all?.[state.app.bookmarks.highlighted];

export const getNewCreatedBookmark = (state: RootState) => state.app.bookmarks.all?.[state.app.bookmarks.focusNew];
