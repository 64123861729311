import store from 'redux/store';
import { SyncingStatus } from 'redux/schemas/models/lecture-page';

/* @ngInject */
export default function TimelinesManager(
  TimelineModel,
  TimelineLecturePageModel,
  OfflineManager,
  CurrentPermissionsManager,
  CurrentCourseManager,
  CurrentUserManager,
  ConfirmationOverlays,
  _,
  $q,
  moment,
) {
  let repeatGetTimelineFunc;
  let repeatGetAdminCreditFunc;
  let fullLoadResourceAndPromise; // holds the last resource & promise for the timeline call w/ lecture components
  // if a lecture page has just been added (in admin edit mode or lecture page just got release), this holds the promise for the last lecture page call
  let lastLecturePageRequestPromise;
  let savedUnmarkedLecturePageId;

  const manager = {
    // functions
    getTimeline,
    translateTimeline,
    getAllCurrentPromises,
    getAdminCredits,
    getCurrentLecturePage,
    setCurrentLecturePage,
    setCurrentLecturePageAsViewed,
    clearSavedUnmarkedLecturePageId,
    setTodo,
    expandCurrentLecturePageSection,

    /* Update to Lecture Page */
    addLecturePage,
    getLastLectureSectionId,
    createLectureSection,
    createLecturePage,
    updateLectureSection,
    updateLecturePage,
    removeLectureSection,
    removeLecturePage,
    duplicateLectureSection,
    insertLecturePageAfter,
    updateLecturePageAttribute,
    removeComponent,
    toggleCardView,
    createFromPreset,
    isRemoveSectionTitleDisabled,

    updateComponentPointsAndProgress,

    updateComponentProgress,
    isViewMode,
    isEditMode,
    isReorderMode,
    setEditMode,
    setReorderMode,
    saveOrder,
    cancelReorder,
    updateIsCompletionPanelExpanded,
    getSection,
    linkCollectionLessonsToCourse,

    // data:
    keepUnmarkedLecturePageId: null,

    currentCatalogId: null,
    currentLecture: null,
    currentTimeline: null,
    currentLecturePage: null,
    totalCreditsReceived: 0,

    CurrentPermissionsManager,
    mode: 'view', // view, edit, reorder
    isCompletionPanelExpanded: false,
  };

  function getCurrentLecturePage() {
    if (manager.currentTimeline?.currentLecturePage) {
      return manager.currentTimeline.currentLecturePage;
    }
    return manager.currentLecture;
  }

  function setCurrentLecturePage(id, isCurrentlyUncompleted) {
    savedUnmarkedLecturePageId = isCurrentlyUncompleted && id;

    if (manager.currentTimeline) {
      manager.currentTimeline.setCurrentLecturePage(id);
    }
  }

  function setCurrentLecturePageAsViewed() {
    if (manager.currentTimeline?.currentLecturePage) {
      manager.currentTimeline.currentLecturePage.setViewed();
    }
  }

  function clearSavedUnmarkedLecturePageId() {
    savedUnmarkedLecturePageId = null;
  }

  function setTodo(todoId) {
    manager.isCompletionPanelExpanded = false;
    if (manager.selectedTab === 'Todos') {
      manager.selectedTodo = todoId;
    }
  }

  function updateIsCompletionPanelExpanded(isExpanded) {
    manager.isCompletionPanelExpanded = isExpanded;
  }

  function expandCurrentLecturePageSection() {
    if (manager.currentTimeline
      && manager.currentTimeline.currentLecturePage
      && manager.currentTimeline.currentLecturePage.lectureSection) {
      manager.currentTimeline.currentLecturePage.lectureSection.expanded = true;
    }
  }

  /**
   * This function calls fetches the timeline from the backend
   *
   */
  function getTimeline(catalogId, userId, isAdmin, course, { forceLoad = false, hideLoadingPlaceholder = false, performSummaryLoad = true, editMode = false } = {}) {
    if (manager.currentCatalogId !== catalogId || manager.currentUserId !== userId || forceLoad) {
      manager.currentLecture = null;
      OfflineManager.deRegisterReturnOnlineCallback(repeatGetTimelineFunc);
      OfflineManager.deRegisterReturnOnlineCallback(repeatGetAdminCreditFunc);

      repeatGetTimelineFunc = () => getTimeline(catalogId, userId, isAdmin, course, { forceLoad: true, hideLoadingPlaceholder: true });
      OfflineManager.registerReturnOnlineCallback(repeatGetTimelineFunc);

      if (fullLoadResourceAndPromise?.resource.$cancelRequest) {
        fullLoadResourceAndPromise.resource.$cancelRequest();
        fullLoadResourceAndPromise = null;
      }

      if (manager.currentTimeline) {
        manager.currentTimeline.cancelTimeouts();
      }

      manager.currentCatalogId = catalogId;
      manager.currentUserId = userId;

      if (!hideLoadingPlaceholder) {
        manager.currentTimeline = null;
      }


      let currentLecturePromise;
      if (CurrentUserManager.user.id === userId) {
        currentLecturePromise = TimelineModel.getCurrentLecture(catalogId)
          .then((response) => {
            if (response.currentLecture) {
              manager.currentLecture = response.currentLecture;
              if (!manager.currentLecture.id) {
                manager.currentLecture.id = manager.currentLecture.activityId;
              }
            } else if (response.firstLecture) {
              manager.currentLecture = response.firstLecture;
              manager.currentLecture.isFirst = true;
            }
          });
      }

      let previousCurrentLectureId;

      // get timeline with lecture components next - heavy call
      fullLoadResourceAndPromise = TimelineModel.getResourceAndPromise(
        catalogId,
        userId,
        isAdmin,
        course,
        { includeLectureComponents: true, editMode },
      );

      const { fullTimelinePromise } = getAllCurrentPromises();

      fullTimelinePromise.then(response => {
        if (response.currentLecture) {
          manager.currentLecture = response.currentLecture;
          manager.currentLecture.id = response.currentLecture.activityId;
        }
      });

      // modifying the full load promise because we want to wait until both the summary version (if it exists) and full version are done
      fullLoadResourceAndPromise.promise = $q.all(_.compact([fullTimelinePromise])).then((resolves) => {
        if (manager.currentTimeline?.currentLectureId) {
          previousCurrentLectureId = manager.currentTimeline.currentLectureId;
        }

        // full timeline call should always be available and always last
        const fullTimeline = _.last(resolves);
        manager.currentTimeline = fullTimeline;

        if (savedUnmarkedLecturePageId) {
          manager.currentTimeline.markUnread(savedUnmarkedLecturePageId);
        }

        return fullTimeline;
      });

      // make sure to reset the currentLecture after second call
      $q.all(_.compact([currentLecturePromise, fullTimelinePromise])).then((resolves) => {
        if (previousCurrentLectureId) {
          setCurrentLecturePage(previousCurrentLectureId);
        } else if (manager?.currentLecture?.id && !manager?.currentLecture?.isFirst) {
          setCurrentLecturePage(manager.currentLecture.id);
        }
      });
    }

    return {
      fullTimelinePromise: fullLoadResourceAndPromise?.promise,
    };
  }

  function translateTimeline(catalogId, language) {
    return TimelineModel.translateTimeline(catalogId, language);
  }

  function getAllCurrentPromises() {
    return {
      fullTimelinePromise: fullLoadResourceAndPromise?.promise,
      lastLecturePageRequestPromise,
    };
  }

  function getAdminCredits(catalogId, userId) {
    OfflineManager.deRegisterReturnOnlineCallback(repeatGetAdminCreditFunc);

    repeatGetAdminCreditFunc = function () {
      return getAdminCredits(catalogId, userId);
    };
    OfflineManager.registerReturnOnlineCallback(repeatGetAdminCreditFunc);

    return TimelineModel.getAdminCredits(catalogId, userId).then((data) => {
      manager.adminCredits = data;

      let sum = 0;
      _.each(manager.adminCredits, (credit) => {
        // total credits here
        sum += credit.receivedPoints;
      });
      manager.totalCreditsReceived = sum;
    });
  }

  /* Update to Lecture Page */
  // When adding a new lecture page from lecture edit
  function addLecturePage(lectureSectionId, lecturePageId) {
    if (manager.currentTimeline?.hasLectureSections()) {
      let currlectureSection = _.findWhere(manager.currentTimeline.lectureSubsections, { id: lectureSectionId });

      if (!currlectureSection) {
        _.some(manager.currentTimeline.lectureSections, (lectureSection) => {
          if (lectureSection.id === lectureSectionId) {
            currlectureSection = lectureSection;
          } else {
            currlectureSection = _.findWhere(lectureSection.lectureSubsections, { id: lectureSectionId });
          }

          return currlectureSection;
        });
      }

      lastLecturePageRequestPromise = TimelineLecturePageModel.get(CurrentCourseManager.course, manager.currentUserId, lecturePageId, currlectureSection, true)
        .then((lecturePage) => {
          manager.currentTimeline.addLecturePage(currlectureSection, lecturePage);
        });

      if (!lastLecturePageRequestPromise) {
        lastLecturePageRequestPromise = repeatGetTimelineFunc();
      }
    } else if (repeatGetTimelineFunc) {
      lastLecturePageRequestPromise = repeatGetTimelineFunc();
    } else {
      return $q.reject();
    }

    return lastLecturePageRequestPromise;
  }

  function getLastLectureSectionId() {
    if (manager.currentTimeline) {
      return _.last(manager.currentTimeline.lectureSections).id;
    }
    return null;
  }

  function updateLecturePageAttribute(lecturePageId, attribute, value) {
    if (manager.currentTimeline) {
      manager.currentTimeline.updateLecturePageAttribute(lecturePageId, attribute, value);
    }
  }

  function removeComponent(lecturePageId, componentType, componentId) {
    if (manager.currentTimeline) {
      manager.currentTimeline.removeComponent(lecturePageId, componentType, componentId);
    }
  }

  async function updateComponentPointsAndProgress(lecturePageId, componentType, componentId, pointsReceived, totalPoints, progress, forceLoadTimeline = true) {
    // OO-TODO: Rewrite this to remove the timeline dependency
    /**
     * The TimelinesManager not updating on creation or deletion of lecture components.
     * So the LC will not be available in TimelinesManager when the admin directly
     * submits the activity after creation. Which causes this NOV-79624 kind of issues.
     * So requesting timeline API to get updated LCs TimelinesManager.
     */

    if (!_.some(manager?.currentTimeline?.allPointItems, (lc) => lc.id === componentId)) {
      const { fullTimelinePromise } = manager.getTimeline(
        CurrentCourseManager.course.catalogId,
        CurrentUserManager.user.id,
        CurrentUserManager.isAdmin(),
        CurrentCourseManager.course,
        { performSummaryLoad: false, forceLoad: forceLoadTimeline },
      );

      await fullTimelinePromise;
    }

    if (manager.currentTimeline) {
      manager.currentTimeline.updateComponentPointsAndProgress(lecturePageId, componentType, componentId, pointsReceived, totalPoints, progress);
    }
  }

  function updateComponentProgress(lecturePageId, componentType, componentId, progress) {
    if (manager.currentTimeline) {
      manager.currentTimeline.updateComponentProgress(lecturePageId, componentType, componentId, progress);
    }
  }

  /* Admin Edit */
  function isViewMode() {
    return manager.mode === 'view';
  }

  function isEditMode() {
    return manager.mode === 'edit';
  }

  function isReorderMode() {
    return manager.mode === 'reorder';
  }

  function setEditMode() {
    manager.mode = 'edit';
  }

  function setReorderMode() {
    manager.currentTimeline.createReorderDraft();
    manager.mode = 'reorder';
  }

  function saveOrder() {
    manager.currentTimeline.saveOrder();
    setEditMode();
  }

  function cancelReorder() {
    if (manager.currentTimeline.reorderDirty) {
      const modalInstance = ConfirmationOverlays.openConfirmationModal(
        'shared/templates/modal-navigate-away-unsaved-changes.html',
      );
      modalInstance.result.then(() => {
        manager.mode = 'edit';
      });
    } else {
      manager.mode = 'edit';
    }
  }

  function updateLectureSection(lectureSection) {
    return TimelineModel.updateLectureSection(CurrentCourseManager.course.catalogId, lectureSection);
  }

  function updateLecturePage(lecturePage) {
    return manager.currentTimeline.updateLecturePage(lecturePage, CurrentUserManager.user.id);
  }

  function removeLecturePage(lecturePageId) {
    if (manager.currentTimeline) {
      manager.currentTimeline.removeLecturePage(lecturePageId);
    }
  }

  function removeLectureSection(lectureSectionId, type, parent, removeTitleOnly = true) {
    TimelineModel.deleteLectureSection(CurrentCourseManager.course.catalogId, lectureSectionId, removeTitleOnly)
      .then((success) => {
        if (success) {
          if (manager.currentTimeline) {
            if (removeTitleOnly) {
              manager.currentTimeline.removeLectureSectionTitle(lectureSectionId, parent, type);
            } else {
              manager.currentTimeline.removeLectureSection(lectureSectionId, parent, type);
            }
          }
        }
      });
  }

  function isRemoveSectionTitleDisabled(lectureSection, index) {
    if (!(manager.currentTimeline.lectureSubsections.length || index > 0) // For the First Section title
      && ((lectureSection.lectureSubsections.length > 0 && lectureSection.lecturePages.length !== 0) // if the first section has subsections inside and there’s no lecture directly under the section title, we should allow user to remove the section title.
        || (lectureSection.lectureSubsections.length === 0 && lectureSection.lecturePages.length > 0))) { // If the first section has lectures listed directly under it without a subsection, the Remove Section Title option should be disabled.
      return true;
    }
    return false;
  }

  // lecture section is automatically appended to the end (it respects what the backend returns)
  function createLectureSection(parentLectureSection, type, index = null) {
    const originalLectureSection = parentLectureSection;
    if (originalLectureSection) {
      originalLectureSection.creationInProgress = true;
    }

    return TimelineModel.createLectureSection(CurrentCourseManager.course.catalogId, type, parentLectureSection, index).then((result) => {
      if (manager.currentTimeline) {
        manager.currentTimeline.addLectureSection(parentLectureSection, type, result);
      }

      if (originalLectureSection) {
        originalLectureSection.creationInProgress = false;
      }
    }, () => {
      if (originalLectureSection) {
        originalLectureSection.creationInProgress = false;
      }
    });
  }

  function createLecturePage(lectureSection) {
    lectureSection.creationInProgress = true;

    return TimelineModel.createLecturePage(CurrentCourseManager.course.catalogId, lectureSection).then((result) => {
      const newLecturePage = new TimelineLecturePageModel(result, lectureSection, CurrentCourseManager.course, true);
      manager.currentTimeline.addLecturePage(lectureSection, newLecturePage);

      lectureSection.creationInProgress = false;
    }, () => {
      lectureSection.creationInProgress = false;
    });
  }

  function duplicateLectureSection(originalSection, sectionType, parent) {
    originalSection.copyInProgress = true;
    return TimelineModel.duplicateLectureSection(CurrentCourseManager.course.catalogId, originalSection.id).then((result) => {
      if (manager.currentTimeline) {
        manager.currentTimeline.copyLectureSection(result, sectionType, originalSection.id, parent);
      }
      originalSection.copyInProgress = false;
    }, () => {
      originalSection.copyInProgress = false;
    });
  }

  function getSection(lectureSectionId) {
    let linkedLectureSection = _.findWhere(manager.currentTimeline.lectureSubsections, { id: lectureSectionId });

    if (!linkedLectureSection) {
      _.some(manager.currentTimeline.lectureSections, (lectureSection) => {
        if (lectureSection.id === lectureSectionId) {
          linkedLectureSection = lectureSection;
        } else {
          linkedLectureSection = _.findWhere(lectureSection.lectureSubsections, { id: lectureSectionId });
        }

        return linkedLectureSection;
      });
    }

    return linkedLectureSection;
  }

  function linkCollectionLessonsToCourse(contentLinks, lectureSectionId) {
    const state = store.getState();
    const linkedLectureSection = getSection(lectureSectionId);
    const startingIndex = linkedLectureSection.lecturePages.length;
    const links = _.sortBy(contentLinks, (link) => link.course.index);

    _.each(links, (contentLink, index) => {
      const sourceLecturePage = {
        ...state.models.lecturePages[contentLink.sourceId],
        isLinked: true,
        syncingStatus: SyncingStatus.LINK_IN_PROGRESS,
      };

      const newLecturePage = new TimelineLecturePageModel(
        sourceLecturePage,
        linkedLectureSection,
        CurrentCourseManager.course,
        true,
      );

      newLecturePage.id = contentLink.targetId;
      newLecturePage.index = startingIndex + index;
      newLecturePage.releaseDate = moment().add(1, 'months').endOf('day').toISOString();

      linkedLectureSection.addLecturePage(newLecturePage);
    });
  }

  function insertLecturePageAfter(lecturePageId, result) {
    if (manager.currentTimeline) {
      manager.currentTimeline.insertLecturePageAfter(lecturePageId, result, CurrentCourseManager.course.catalogId);
    }
  }

  function toggleCardView(catalogId) {
    return TimelineModel.toggleCardView(catalogId).then(() => {
      CurrentCourseManager.course.cardView = !CurrentCourseManager.course.cardView;
    });
  }

  function createFromPreset(templateName) {
    return TimelineModel.createFromPreset(
      CurrentCourseManager.course.catalogId, templateName, CurrentUserManager.user.id, CurrentUserManager.isAdmin(), CurrentCourseManager.course,
    )
      .then((result) => {
        manager.currentTimeline = result;
      });
  }

  return manager;
}
