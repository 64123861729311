/* @ngInject */
export default function CourseHomeModelService(

  _,
  $q,
  CourseHomeResources,
  $sce,
  moment,
) {
  const CourseHomeModel = function (attributes) {
    const _this = this;
    _.extend(_this, attributes);

    if (_this.calendarUrl) {
      _this.calendarUrl = $sce.trustAsResourceUrl(_this.calendarUrl);
    }

    _this.oldShowWelcomeMesageAtTop = moment().subtract(7, 'days') < moment(_this.enrollmentStatusUpdatedAt);
  };

  CourseHomeModel.get = function (catalogId) {
    return CourseHomeResources.get({ catalogId }).$promise
      .then((response) => new CourseHomeModel(response.result));
  };

  CourseHomeModel.getTrendingSubmissions = function (catalogId) {
    return CourseHomeResources.getTrendingSubmissions({ catalogId }).$promise
      .then((resource) => resource.result);
  };

  return CourseHomeModel;
}
