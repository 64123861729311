/* @ngInject */
export default function NvL3HeaderTeam(
  $window,
  InteroperabilityRoutes,
  $stateParams,
  CurrentUserManager,
  $state,
) {
  return {
    restrict: 'A',
    scope: {
      collaborators: '=',
      team: '=',
      teamSubmittedWithoutCurrentUser: '=',
      currentUserLeftTeam: '=',
      teamAliases: '=',
    },
    transclude: true,
    link(scope, elem, attrs, transclude) {
      scope.InteroperabilityRoutes = InteroperabilityRoutes;
      scope.$stateParams = $stateParams;
      scope.teamWarningPopoverOpen = true;
      scope.teamWarningPopoverClasses = 'alert-popup team-submitted-warning';
      scope.$state = $state;

      scope.closeTeamWarningPopover = closeTeamWarningPopover;
      scope.resizeAssignmentAndTeamTitles = resizeAssignmentAndTeamTitles.bind(scope);
      scope.closePopover = closePopover;

      const teamIconSpacing = 10; // $half-spacing
      const spacingAroundTeam = 20; // $standard-spacing

      // set the assignment name and team name equal to remaining screen width
      // need a $watch because profile pictures load in later
      scope.$watch(
        () => $('.team-content').width(),
        (newValue, oldValue) => {
          if (newValue !== oldValue) {
            scope.resizeAssignmentAndTeamTitles(newValue);
          }
        },
        true,
      );

      scope.$watch(
        () => elem.width(),
        (newValue, oldValue) => {
          if (newValue !== oldValue) {
            scope.resizeAssignmentAndTeamTitles($('.team-content').width());
          }
        },
      );

      function closeTeamWarningPopover() {
        scope.teamWarningPopoverOpen = false;
      }


      function resizeAssignmentAndTeamTitles(newValue) {
        const hamburgerIcon = $('.top-header .hamburger');
        const showingTeamContent = elem.find('.team-content').css('display') !== 'none';
        const hamburgerIconWidth = (hamburgerIcon.css('display') === 'none' || hamburgerIcon.css('display') === undefined) ? 0 : hamburgerIcon.outerWidth(true);
        // make space for the warning icon and its margins
        let spacingBetweenTitles = 0;
        if (this.teamSubmittedWithoutCurrentUser) {
          spacingBetweenTitles = 24 + 40 + 20;
        } else if (showingTeamContent) {
          spacingBetweenTitles = 40;
        }

        const widthToDistribute = (elem.width() - newValue - spacingBetweenTitles - hamburgerIconWidth - teamIconSpacing);
        // using floor because got into an infinite digest loop due to rounding

        const remainingWidth = !showingTeamContent
          ? Math.floor(widthToDistribute)
          : Math.floor(widthToDistribute / 2); // divide by 2 for the team name + the course name


        elem.find('.left-content').css('max-width', `${remainingWidth}px`);


        if (showingTeamContent) {
          elem.find('.team-name').css('max-width', `${remainingWidth}px`);
        }
      }

      function closePopover() {
        scope.teamWarningPopoverOpen = false;
      }
    },
    templateUrl: 'exercises/templates/nv-l3-header-team.html',
  };
}
