import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import t from 'react-translate';
import { InfiniteTableLoadingParams, PagedDataQueryParams } from 'redux/create-action-creators';
import { LearnerProgress, LearnerProgressSearch } from 'redux/schemas/models/learner-progress';
import { Result } from 'redux/schemas/api';
import { addAlertMessage } from './alert-messages';
import { AlertMessageType } from '../schemas/app/alert-message';
import { makeQueryParams, makeQueryParamString } from './helpers';


type LearnerProgressParams = {
  catalogId: string,
  translationValues?: string,
  sectionIds?: number[]
};

export const getLearnerProgressCounts = createAsyncThunk(
  'GET_LEARNER_PROGRESS_COUNTS',
  async (params: LearnerProgressParams) => axios.get(`${params.catalogId}/learner_progress/stats`).then(response => response.data.result),
);

type LearnerProgressListPageParams = LearnerProgressParams & PagedDataQueryParams &InfiniteTableLoadingParams<LearnerProgressSearch>;

export const getLearnerProgressList = createAsyncThunk<{ response: LearnerProgress[], totalCount: string } | any, any, {}>(
  'GET_LEARNER_PROGRESS_LIST',
  async (params: LearnerProgressListPageParams) => {
    const queryParams = makeQueryParams(params, params.pageSize, params.pageIndex);
    const paramString = makeQueryParamString(queryParams);

    return axios.get<Result<LearnerProgressSearch>>(`${params.catalogId}/learner_progress?${paramString}`, {
      params: {
        section_ids: params.sectionIds,
      },
    }).then((response) => {
      return { response: response.data.result.result, totalCount: response.data.result.resultCount };
    });
  },
);

export const getCourseOutlineSections = createAsyncThunk(
  'GET_COURSE_OUTLINE_SECTIONS',
  async (params: LearnerProgressParams) => axios.get(`${params.catalogId}/course_outline/content`).then(response => response.data.result),
);

type LearnerProgressIndividualActionParams = LearnerProgressParams & { userId: number };

export const addManualCompletion = createAsyncThunk(
  'ADD_MANUAL_COMPLETION',
  async (params: LearnerProgressIndividualActionParams, thunkAPI) => axios.put(`${params.catalogId}/manual_completion/${params.userId}`).then(response => {
    thunkAPI.dispatch(addAlertMessage({
      type: AlertMessageType.SUCCESS,
      header: t.FORM.SUCCESS(),
      message: t.LEARNER_PROGRESS.OPTIONS.GRANT_MANUAL_COMPLETION_SUCCESS(params.translationValues),
    }));

    return response.data.result;
  }),
);

export const removeManualCompletion = createAsyncThunk(
  'REVOKE_MANUAL_COMPLETION',
  async (params: LearnerProgressIndividualActionParams, thunkAPI) => axios.delete(`${params.catalogId}/manual_completion/${params.userId}`).then(response => {
    thunkAPI.dispatch(addAlertMessage({
      type: AlertMessageType.SUCCESS,
      header: t.FORM.SUCCESS(),
      message: t.LEARNER_PROGRESS.OPTIONS.REVOKE_MANUAL_COMPLETION_SUCCESS(params.translationValues),
    }));

    return response.data.result;
  }),
);

export const getManuallyAwardedPoints = createAsyncThunk(
  'GET_MANUALLY_AWARDED_POINTS',
  async (params: LearnerProgressParams) => axios.get(`${params.catalogId}/awarded_points`).then(response => response.data.result),
);
