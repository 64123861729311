import { normalize } from 'normalizr';
import mergeWith from 'lodash/mergeWith';
import { EnrollmentExportStatus } from 'redux/schemas/models/org-users';

import {
  getUserDetails,
  getUserEnrollmentData,
  getUserEnrollmentDownloadDetails,
  exportUserEnrollmentData,
  updateExportStatus,
  unEnrollUserFromCourse,
} from 'redux/actions/org-users';
import { createReducer } from '@reduxjs/toolkit';
import { userEnrollmentsSchema } from 'redux/schemas/api/org-mentors';
import { replaceArrays } from 'shared/lodash-utils';
import { initialRootState } from '.';

export default createReducer(initialRootState, builder => {
  builder.addCase(getUserDetails.fulfilled, (state, action) => {
    if (action.payload.id) {
      state.models.orgUsers[action.payload.id] = {
        ...(state.models.orgUsers[action.payload.id] || {}),
        userDataLoaded: true,
        user: action.payload,
      };
    }
  })
    .addCase(getUserEnrollmentDownloadDetails.fulfilled, (state, action) => {
      if (action.payload.result?.userEnrollmentExportDetails) {
        const report = action.payload.result?.userEnrollmentExportDetails?.userEnrollment?.[0];
        const file = action.payload.result?.userEnrollmentExportFileList?.[0];
        const exportInfo = {
          exportStatus: EnrollmentExportStatus.READY,
          exportDate: null,
          exportDownloadLink: null,
        };
        if (report?.reportStatus === 'in_progress') {
          exportInfo.exportStatus = EnrollmentExportStatus.ACTIVE;
        } else if (report?.reportStatus === 'completed' || (report?.reportStatus === 'failed' && file?.url)) {
          exportInfo.exportStatus = EnrollmentExportStatus.DOWNLOAD;
          exportInfo.exportDate = file?.completedAt;
          exportInfo.exportDownloadLink = file?.url;
        } else {
          exportInfo.exportStatus = EnrollmentExportStatus.READY;
        }
        state.models.orgUsers[action.meta.arg.userId] = {
          ...state.models.orgUsers[action.meta.arg.userId],
          exportInfoLoaded: true,
          exportInfo,
        };
      }
    })
    .addCase(getUserEnrollmentData.fulfilled, (state, action) => {
      if (action.payload.result) {
        const userEnrollments = normalize(action.payload.result.enrollments, userEnrollmentsSchema);
        const newEnrollments = (state.models.orgUsers[action.meta.arg.userId]?.enrollments && action.meta.arg.page > 1)
          ? state.models.orgUsers[action.meta.arg.userId].enrollments.concat(action.payload.result.enrollments)
          : action.payload.result.enrollments;
        state.models.orgUsers[action.meta.arg.userId] = {
          ...state.models.orgUsers[action.meta.arg.userId],
          enrollments: newEnrollments,
          enrollmentLoaded: true,
          totalEnrollments: action.payload.result.enrollmentsCount,
        };
        mergeWith(state.models.courses, userEnrollments.entities.courses, replaceArrays);
      }
    })
    .addCase(unEnrollUserFromCourse.fulfilled, (state, action) => {
      const { catalogId } = action.meta.arg;
      const { enrollmentId } = action.payload;
      const course = state.models.courses[catalogId];

      if (course.isContentManagementCollection) {
        delete state.models.courseUsers[catalogId][enrollmentId];
      } else {
        state.models.orgUsers[action.meta.arg.user.id].enrollments = state.models.orgUsers[action.meta.arg.user.id].enrollments.filter(enrollment => enrollment.course.catalogId !== catalogId);
        state.models.orgUsers[action.meta.arg.user.id].totalEnrollments -= 1;
      }
    })
    .addCase(exportUserEnrollmentData.pending, (state, action) => {
      if (action.meta.arg.userId) {
        state.models.orgUsers[action.meta.arg.userId] = Object.assign(state.models.orgUsers[action.meta.arg.userId] || {}, {
          exportInfo: {
            exportStatus: EnrollmentExportStatus.ACTIVE,
          },
        });
      }
    })
    .addCase(exportUserEnrollmentData.rejected, (state, action) => {
      if (action.meta.arg.userId) {
        state.models.orgUsers[action.meta.arg.userId] = Object.assign(state.models.orgUsers[action.meta.arg.userId] || {}, {
          exportInfo: {
            exportStatus: EnrollmentExportStatus.READY,
          },
        });
      }
    })
    .addCase(updateExportStatus, (state, action) => {
      if (action.payload && state.models.orgUsers[action.payload.userId]?.exportInfo) {
        state.models.orgUsers[action.payload.userId].exportInfo = action.payload.data;
      }
    });
});
