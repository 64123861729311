import React, { useContext } from 'react';

import moment from 'moment';

import { connect, useSelector } from 'react-redux';
import t from 'react-translate';

// schemas
import { RootState } from 'redux/schemas';
import { NTimelineGroupTeamSet } from 'redux/schemas/models/group-team-set';
import { ActivityType } from 'redux/schemas/models/activity';
import { ComponentKeyPluralized } from 'redux/schemas/models/lecture-component';

// actions
import { setActivityExpandedInAutomatedCommunications } from 'redux/actions/course-communications';

// selectors
import { getGroupTeamSet, hasGroupTeamSetGotCommunicationError } from 'redux/selectors/timeline';
import { isActivityExpandedInAutomatedCommunications } from 'redux/selectors/course-communications';

// components
import NvIcon from 'shared/components/nv-icon';
import NvStackedTab from 'shared/components/nv-stacked-tab';

import CommunicationCreateNewDropdown from 'communications/course_communications/components/communication-create-new-dropdown';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';
import { getCourseAliases } from 'redux/selectors/course';

type GroupTeamFormationLectureComponentProps = {
  groupTeamSetId: number
};

type StateProps = {
  groupTeamSet: NTimelineGroupTeamSet,
  hasCommunicationError: boolean,
};

const mapDispatchToProps = {
  setExpanded: setActivityExpandedInAutomatedCommunications,
};

const GroupTeamFormationLectureComponent = (props: GroupTeamFormationLectureComponentProps & StateProps & typeof mapDispatchToProps) => {
  const aliases = useSelector((state) => getCourseAliases(state));

  const isExpanded = useSelector((state) => isActivityExpandedInAutomatedCommunications(state, ComponentKeyPluralized.GROUP_FORMATION, props.groupTeamSetId));

  return (
    <React.Fragment>
      <div className={`lecture-component-row ${isExpanded && props.groupTeamSet?.communicationsCount > 0 && 'expanded-box'}`}>
        <NvIcon icon='groups' size='smallest' />
        <div className='description'>{props.groupTeamSet?.title}</div>
        {props.groupTeamSet?.hasStructuralIssues && (
          <div className='text-danger spacing'>
            <NvIcon icon='warning' size='xss-smallest' />
          </div>
        )}
        <div className='date'>
          {props.groupTeamSet?.deadline && t.LECTURE_PAGES.COMPONENTS.DUE_DATE.STUDENT_VIEW(moment(props.groupTeamSet.deadline).format('LLL'))}
        </div>
        <div className=''>
          <CommunicationCreateNewDropdown
            activityType={ActivityType.GROUP_FORMATION}
            activityId={props.groupTeamSetId}
            disabledText={!props.groupTeamSet?.formedByStudents
              && t.COURSE_COMMUNICATIONS.TOOLTIP.CREATE_DISABLED_INSTRUCTOR_CREATED(aliases.groupAliases.groupAlias)}
          />
        </div>
        { props.groupTeamSet?.communicationsCount > 0 && (
          <NvStackedTab
            count={props.groupTeamSet.communicationsCount}
            hasError={props.hasCommunicationError}
            isExpanded={isExpanded}
            errorMessage={t.COURSE_COMMUNICATIONS.WARNINGS.ACTIVITY_COMMUNICATION()}
            onClick={() => props.setExpanded({
              type: ComponentKeyPluralized.GROUP_FORMATION,
              id: props.groupTeamSetId,
              isExpanded: !isExpanded,
            })}
          />
        )}
      </div>
      {
        // Communications
        isExpanded && props.groupTeamSet?.communications && props.groupTeamSet.communications.map(communicationId => (
          <CommunicationItem key={communicationId.toString()} communicationId={communicationId} />
        ))
      }
    </React.Fragment>
  );
};

const ConnectedGroupTeamFormationLectureComponent = connect(
  (state: RootState, ownProps: GroupTeamFormationLectureComponentProps) => ({
    groupTeamSet: getGroupTeamSet(state, ownProps.groupTeamSetId),
    hasCommunicationError: hasGroupTeamSetGotCommunicationError(state, ownProps.groupTeamSetId),
  }),
  mapDispatchToProps,
)(GroupTeamFormationLectureComponent);

export default ConnectedGroupTeamFormationLectureComponent;
