import { css } from '@emotion/react';
import { useEffect, useContext, createContext } from 'react';
import { reduce } from 'underscore';

import moment from 'moment';
import { connect, useSelector } from 'react-redux';

import { RootState } from 'redux/schemas';
import { halfSpacing, standardSpacing, quarterSpacing, largeSpacing } from 'styles/global_defaults/scaffolding';
import { gray3, gray6, primary, warning, gray7 } from 'styles/global_defaults/colors';
import t from 'react-translate';
import {
  getLecturePageCommunications,
  hideLecturePageCommunicationsInAutomatedCommunications,
  setLecturePageExpandedInAutomatedCommunications,
  setActivityExpandedInAutomatedCommunications,
} from 'redux/actions/course-communications';
import {
  isActivityExpandedInAutomatedCommunications,
  isLecturePageExpandedInAutomatedCommunications,
  isLecturePageShowCommunicationsInAutomatedCommunications,
} from 'redux/selectors/course-communications';
import { getLecturePage } from 'redux/selectors/timeline';
import NvStackedTab from 'shared/components/nv-stacked-tab';
import NvIcon from 'shared/components/nv-icon';
import { NvTooltip } from 'shared/components/nv-tooltip';
import { NvDropdown, NvDropdownButtonStyle, NvDropdownAlign, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import TimelineLectureComponent from 'timelines/components/timeline-lecture-component';
import { LecturePageKey, LecturePageType, NLecturePage } from 'redux/schemas/models/lecture-page';
import { CourseAliases } from 'redux/schemas/models/course';
import { CommunicationType } from 'redux/schemas/models/course-communication';
import { getCourseAliases } from 'redux/selectors/course';
import { CommunicationDispatch, CommunicationAction, TriggerType } from 'communications/course_communications/contexts/communication-context';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';

type TimelineLecturePageProps = {
  lecturePageId: number,
};

type StateProps = {
  lecturePage: NLecturePage,
};

const mapDispatchToProps = {
  getLecturePageCommunications,
  hideLecturePageCommunications: hideLecturePageCommunicationsInAutomatedCommunications,
  setExpanded: setLecturePageExpandedInAutomatedCommunications,
  // Displaying communications of this lecturePage
  setCommunicationsExpanded: setActivityExpandedInAutomatedCommunications,
};

const styles = css`
  > .lecture-page-row {
    border-bottom: 1px dashed ${gray6};
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-height: 60px;
    position: relative;

    &:hover {
      background: ${gray7};
    }

    .icon {
      margin: ${halfSpacing}px ${halfSpacing}px ${halfSpacing}px ${largeSpacing}px;
    }

    .status-icon{

    }

    .description {
      width: 320px;
      flex-grow: 1;
      padding-left: ${standardSpacing}px;
    }

    .activities-toggle {
      color: ${primary};
      cursor: pointer;
    }

    .date {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 30%;
      line-height: 1;

      .unreleased, .empty{
        font-style: italic;
        color: ${gray3};
      }
    }

    .create-icon-placeholder{
      width:4%;
    }
  }

  > .expanded-box:before {
    position: absolute;
    content: '';
    width: ${quarterSpacing}px;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${warning};
  }
`;

// Target area is larger than the small icon size
const TARGET_AREA_PADDING = standardSpacing;

const dropdownStyles = css`
  color: ${primary};
  cursor: pointer;

  .options-btn {
    /* TODO : Don't have the increased target area at the top of the icon */
    /* to fix the tooltip flickering issue on hover. Fix this gracefully. */
    padding: 0 ${TARGET_AREA_PADDING}px ${TARGET_AREA_PADDING}px;
    margin-top: ${TARGET_AREA_PADDING}px;

    .icon {
      margin: 0;
    }
  }
`;

export const TimelineLecturePageContext = createContext(null);

/**
 * Lecture page row
 */
const TimelineLecturePage = (props: TimelineLecturePageProps & StateProps & typeof mapDispatchToProps) => {
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const aliases = useSelector((state) => getCourseAliases(state));

  const isActivitiesExpanded = useSelector((state) => isLecturePageExpandedInAutomatedCommunications(state, props.lecturePageId));
  const isCommunicationsExpanded = useSelector((state) => isActivityExpandedInAutomatedCommunications(state, LecturePageKey.LECTURE, props.lecturePageId));
  const showCommunications = useSelector((state) => isLecturePageShowCommunicationsInAutomatedCommunications(state, props.lecturePageId));
  const isCommunicationsLoading = useSelector(state => state.app.courseCommunications[catalogId].automatedCommunications.isCommunicationsLoading?.[props.lecturePageId]);
  const isCommunicationsOnceLoaded = useSelector(state => state.app.courseCommunications[catalogId].automatedCommunications.isCommunicationsOnceLoaded?.[props.lecturePageId]);
  const isTimelineLoaded = useSelector(state => state.app.courseCommunications[catalogId].automatedCommunications.isTimelineLoaded);

  useEffect(() => {
    // Show activties
    if (isActivitiesExpanded && catalogId && props.lecturePageId) {
      // Fetch activities for lecturePage
      if (!isCommunicationsLoading && !isCommunicationsOnceLoaded && isTimelineLoaded) {
        props.getLecturePageCommunications({
          catalogId,
          lecturePageId: props.lecturePageId,
          showCommunications,
        });
      }
    } else if (!isActivitiesExpanded && props.lecturePageId && isCommunicationsOnceLoaded) {
      // Hide communications too
      props.hideLecturePageCommunications({
        lecturePageId: props.lecturePageId,
      });
    }
  }, [isActivitiesExpanded, catalogId, showCommunications, props.lecturePageId, isCommunicationsOnceLoaded, isCommunicationsLoading]);

  const totalActivityCommunicationsCount = reduce(props.lecturePage.activityCommunicationsCount, (last, { count }) => last + count, 0);
  const activitiesWithCommunicationCount = reduce(props.lecturePage.activityCommunicationsCount, (last, { count }) => (count > 0 ? last + 1 : last), 0);

  const communicationDisplayCount = isActivitiesExpanded
    ? props.lecturePage.communicationsCount
    : totalActivityCommunicationsCount;

  const toggleActivities = (isFromStackedTab = false) => {
    if (isFromStackedTab) {
      // StackedTab of lecturePage has three func on each click
      // 1. Open all activities with communications
      // 2. Open this lecturePage's communications
      // 3. Close all

      if (isActivitiesExpanded
        && !isCommunicationsExpanded
        && props.lecturePage.communicationsCount > 0) {
        // Open this lecturePage's communications
        props.setCommunicationsExpanded({
          type: LecturePageKey.LECTURE,
          id: props.lecturePageId,
          isExpanded: true,
        });
      } else {
        props.setExpanded({
          id: props.lecturePageId,
          isExpanded: !isActivitiesExpanded,
          showCommunications: true,
        });
      }
    } else {
      // Toggle just activites
      props.setExpanded({
        id: props.lecturePageId,
        isExpanded: !isActivitiesExpanded,
        showCommunications: false,
      });
    }
  };

  const { dispatch } = useContext(CommunicationDispatch);

  const onClick = (type: CommunicationType) => {
    dispatch({
      type: CommunicationAction.SHOW_MODAL,
      triggerType: TriggerType.CREATE,
      communicationType: type,
      activityType: LecturePageType.LECTURE,
      activityId: props.lecturePageId,
      lecturePageId: props.lecturePageId,
    });
  };

  const dropDownItems: NvDropdownOption[] = [
    { type: 'header', title: t.COURSE_COMMUNICATIONS.CREATE_DROPDOWN.SCHEDULE() },
    {
      type: 'text',
      text: t.COURSE_COMMUNICATIONS.CREATE_DROPDOWN.NEW_EMAIL_BASED_ON_RELEASE_DATE(),
      callback: () => onClick(CommunicationType.RELEASE_DATE_EMAIL),
    }, {
      type: 'text',
      text: t.COURSE_COMMUNICATIONS.CREATE_DROPDOWN.NEW_ANNOUNCEMENT_BASED_ON_RELEASE_DATE(),
      callback: () => onClick(CommunicationType.RELEASE_DATE_ANNOUNCEMENT),
    },
  ];

  const toolTipText = t.COURSE_COMMUNICATIONS.TOOLTIP.CREATE();

  return (
    <TimelineLecturePageContext.Provider
      value={{
        lecturePageId: props.lecturePageId,
      }}
    >
      <div css={styles}>
        <div
          className={`lecture-page-row ${isActivitiesExpanded && communicationDisplayCount > 0 && 'expanded-box'}`}
        >
          <span className='status-icon'>
            <NvIcon icon='read' size='smallest' />
          </span>
          <div className='description py-2'>{props.lecturePage.title}</div>
          { (props.lecturePage.hasAnyActivities) && (
          <a
            className='activities-toggle p-2 text-small'
            onClick={() => toggleActivities(false)}
          >
            { isActivitiesExpanded ? t.TIMELINE.HIDE_ACTIVITIES() : t.TIMELINE.VIEW_ACTIVITIES() }
          </a>
          )}
          <div className='date'>
            { props.lecturePage.hasTimelineIssues && (
              <div className='text-danger'>
                <NvIcon icon='warning' size='xss-smallest' />
              </div>
            )}
            {props.lecturePage.releaseDate && moment(props.lecturePage.releaseDate).format('LLL')}
            { props.lecturePage.hasAnyActivities && !props.lecturePage.released && (
              <div className='unreleased text-small'>{ t.TIMELINE.UNRELEASED() }</div>
            )}
            { !props.lecturePage.hasAnyActivities && (
              <div className='empty text-small'>{ t.TIMELINE.EMPTY() }</div>
            )}
          </div>
          <div css={dropdownStyles}>
            <NvDropdown
              buttonStyle={NvDropdownButtonStyle.CUSTOM}
              items={dropDownItems}
              align={NvDropdownAlign.RIGHT}
              offset={-TARGET_AREA_PADDING / 2} // Icon has a target area padding of 20px
              customTarget={() => (
                <NvTooltip text={toolTipText}>
                  <div className='options-btn'>
                    <NvIcon size='xss-smallest' icon='add-square' />
                  </div>
                </NvTooltip>
              )}
            />
          </div>
          { (communicationDisplayCount > 0) && (
            <NvStackedTab
              count={communicationDisplayCount}
              // Display stacked tab if communication is on multiple components ( activity or lecture )
              isStacked={!isActivitiesExpanded && (activitiesWithCommunicationCount > 1)}
              isExpanded={isCommunicationsExpanded && isActivitiesExpanded}
              hasError={props.lecturePage.activitiesCommunicationsErrorsCount > 0}
              errorMessage={t.COURSE_COMMUNICATIONS.WARNINGS.PAGE_COMMUNICATION(aliases.lectureAliases)}
              onClick={() => toggleActivities(true)}
            />
          )}
        </div>
        {isActivitiesExpanded && (
          <div className='contents'>
            {isCommunicationsExpanded && props.lecturePage.communications?.map(communicationId => (
              <CommunicationItem key={communicationId.toString()} communicationId={communicationId} />
            ))}
            {props.lecturePage.lectureComponents?.map(lectureComponentId => (
              <TimelineLectureComponent key={lectureComponentId.toString()} lectureComponentId={lectureComponentId} />
            ))}
          </div>
        )}
      </div>
    </TimelineLecturePageContext.Provider>
  );
};

const ConnectedTimelineLecturePage = connect<StateProps, typeof mapDispatchToProps>(
  (state: RootState, ownProps: TimelineLecturePageProps) => ({
    lecturePage: getLecturePage(state, ownProps.lecturePageId),
  }), mapDispatchToProps as any,
)(TimelineLecturePage);

export default ConnectedTimelineLecturePage;
