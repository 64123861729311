import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { useSelector } from 'react-redux';

import { CloneCourseContext, CloningType, cloneCourseContextType } from '../hooks/use-clone-course-form';
import { halfSpacing, quarterSpacing, standardSpacing, threeQuartersSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { css } from '@emotion/react';
import NvIcon from 'shared/components/nv-icon';
import { Course } from 'redux/schemas/models/course';
import { gray5, hexToRgbaString, info } from 'styles/global_defaults/colors';
import NvCourseAvatar from 'components/nv-course-avatar';
import Badge from 'cohort_management/components/badge';
import { CombinedInstitution, RootState } from 'redux/schemas';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import moment from 'moment';
import { CourseDatesDetails } from '../course-info';
import NvTooltip from 'shared/components/nv-tooltip';
import { config } from '../../../../../config/config.json';

const StepThree = ({ closeModal }) => {
  const cloneCourseFormMethods = useContext<cloneCourseContextType>(CloneCourseContext);
  const currentInstitution = useSelector<RootState, CombinedInstitution>(getCurrentInstitution);
  const {
    course: originalCourse,
    cloningType,
    formData,
    prevStep,
    goToFirstStep,
    getTotalSelectedAdmins,
    stringFormatDate,
    willBeOpenInJourneys,
    submit,
  } = cloneCourseFormMethods;

  const styles = css`
    .edit-course{
      align-items: center;
      padding: ${quarterSpacing}px 0px;
      button {
        margin: 0px;
        padding: 0px ${quarterSpacing}px;
      }
    }
    .headers {
      padding: 0px ${halfSpacing}px;
      .header {
        width: 25%;
      }
    }
    .courses-list {
      padding-bottom: ${standardSpacing}px;
      padding-top: ${threeQuartersSpacing}px;
      .courses {
        border: 1px solid ${gray5};
        border-left: unset;
        border-right: unset;
        .course {
          background-color: ${hexToRgbaString(info, 0.5)};
          align-items: center;
          height: ${tripleSpacing}px;
          .field {
            width: 25%;
            padding-right: ${halfSpacing}px;
            &.avatar-column{
              position: relative;
              align-items: center;
              .avatar-container {
                display: unset;
              }
            }
            .course-avatar-container {
              position: relative;
              padding-right: ${standardSpacing}px;
              margin-left: ${halfSpacing}px;
            }
            .icon {
              padding: 0px ${quarterSpacing}px;
            }
          }
        }
        .course:not(.first) {
          margin-top: 1px;
        }
      }
    }

    .info {
      .registration-type {
        width: 150px;
      }
      .value {
        align-items: center;
        padding-top: ${halfSpacing}px;
        .icon {
          padding: 0px ${halfSpacing}px;
        }
      }
    }
  `;

  const handleSubmit = () => {
    submit({
      callback: closeModal,
    });
  };

  return (
    <div css={styles}>
      <div className='edit-course font-weight-bolder d-flex'>
        {
          cloningType === CloningType.COHORT_COURSE
          ? t.COURSES.CLONE.STEPS.STEP_THREE.COURSE_INFORMATION()
          : t.COURSES.CLONE.STEPS.STEP_THREE.ClONED_COURSE_INFORMATION()
        }
        <Button
          variant='link'
          onClick={goToFirstStep}
          className='font-weight-bold'
          data-qa={config.pendo.cloningModal.edit}
        >
          {t.COURSES.CLONE.STEPS.STEP_THREE.EDIT()}
        </Button>
      </div>
      <div className='courses-list'>
        <div className='headers d-flex'>
          <div className='header text-small text-gray-2'>
            {t.COURSES.CLONE.STEPS.STEP_THREE.COURSES_LIST.HEADERS.NEW_COURSE_NAME()}
          </div>
          <div className='header text-small text-gray-2'>
          {t.COURSES.CLONE.STEPS.STEP_THREE.COURSES_LIST.HEADERS.NEW_CATALOG_ID()}
          </div>
          <div className='header text-small text-gray-2'>
            {t.COURSES.CLONE.STEPS.STEP_THREE.COURSES_LIST.HEADERS.NEW_START_DATE()}
          </div>
          <div className='header text-small text-gray-2'>
          {t.COURSES.CLONE.STEPS.STEP_THREE.COURSES_LIST.HEADERS.ADMIN_USERS()}
          </div>
        </div>
        <div className='courses'>
          {
            formData.coursesList.map((course, index) => (
              <div
                key={index}
                className={`course d-flex ${index === 0 ? 'first' : ''}`}
              >
                <div className='field avatar-column text-small font-weight-bold d-flex'>
                  <div className='course-avatar-container'>
                    <NvCourseAvatar course={originalCourse} institution={currentInstitution} size='md' />
                    <Badge
                      className='cohort-management-badge'
                      course={{
                        isCohort: cloningType === CloningType.COHORT_COURSE,
                        isPrimary: cloningType === CloningType.PRIMARY_COURSE,
                      } as Course}
                    />
                  </div>
                  <NvTooltip
                    text={course.name}
                    preventOverflow={false}
                  >
                    <div className='ellipsis'>
                      { course.name }
                    </div>
                  </NvTooltip>
                </div>
                <div className='field text-small font-weight-bold ellipsis'>
                  { course.catalogId }
                </div>
                <div className='field text-small font-weight-bold'>
                  <div className='d-flex align-items-center'>
                    { `${moment(course.officialReleaseDate).format('MM/DD/YYYY [at] hh:mm A')}`.replace('at', t.LECTURE_VIDEO.AT()) }
                    <CourseDatesDetails
                      release={stringFormatDate(course.releaseDate)}
                      start={stringFormatDate(course.officialReleaseDate)}
                      registration={stringFormatDate(course.registrationCloseDate)}
                      end={stringFormatDate(course.endDate)}
                      icon='view'
                      typeOfRegistration={originalCourse.typeOfRegistration}
                    />
                  </div>
                </div>
                <div className='field text-small font-weight-bold'>
                  { getTotalSelectedAdmins() }
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <div className='info d-flex'>
        <div className='registration-type'>
          <div className='text-gray-2 text-small'>
            { t.COURSES.CLONE.STEPS.STEP_ONE.MEDIA_AND_REGISTRATION_TYPE.REGISTRATION_TYPE.TITLE() }
          </div>
          <div className='font-weight-bold text-small value'>
            {t.COURSES.REGISTRATION_TYPE[`TYPE_${formData.registrationType}`]()}
            {
              willBeOpenInJourneys(formData.registrationType)
              && ` (${t.COURSES.CLONE.STEPS.STEP_ONE.MEDIA_AND_REGISTRATION_TYPE.REGISTRATION_TYPE.OPEN_IN_JOURNEY().toLowerCase()})`}
          </div>
        </div>
        <div className='media-settings'>
          <div className='text-gray-2 text-small'>
            { t.COURSES.CLONE.STEPS.STEP_ONE.MEDIA_AND_REGISTRATION_TYPE.MEDIA_SETTINGS.TITLE() }
          </div>
          <div className='font-weight-bold text-small value d-flex'>
            <NvIcon size='xs-smallest' icon={`${formData.copyAudioVideo ? 'duplicate' : 'share'}`} className='text-gray-4' />
            {formData.copyAudioVideo
              ? t.COURSES.CLONE.STEPS.STEP_ONE.MEDIA_AND_REGISTRATION_TYPE.MEDIA_SETTINGS.COPY_AUDIO_AND_VIDEO.LABEL()
              : t.COURSES.CLONE.STEPS.STEP_ONE.MEDIA_AND_REGISTRATION_TYPE.MEDIA_SETTINGS.SHARE_AUDIO_AND_VIDEO.LABEL()
            }
          </div>
        </div>
      </div>
      <div className='buttons'>
        <Button type='button' variant='outline-primary' onClick={(e) => { prevStep(); }}>
          {t.COURSES.CLONE.BUTTONS.BACK()}
        </Button>
        <Button type='button' variant='primary' onClick={handleSubmit}>
          {t.COURSES.CLONE.BUTTONS.SUBMIT()}
        </Button>
      </div>
    </div>
  );
};

export default StepThree;
