import React, { StyleHTMLAttributes } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import t from 'react-translate';
import { gray7, primary } from 'styles/global_defaults/colors';
import ClickableContainer from 'components/clickable-container';
import { Filters } from 'institutions/components/filter-flyout-modal';
import {
  doubleSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import {
  InstitutionDashboardContext,
} from 'institutions/components/institution-dashboard-home';
import { config } from '../../../config/config.json';

type Props = {
  primaryId: number,
  style: StyleHTMLAttributes<HTMLDivElement>,
};

const PrimaryCohortsHeader = (props: Props) => {
  const {
    style,
    primaryId,
  } = props;

  const { setFilters, resultsCount } = React.useContext(InstitutionDashboardContext);
  const courses = useSelector((state) => Object.values(state.models.courses));
  const primaryCourse = courses.find((course) => course.id === primaryId);

  const styles = css`
    background-color: ${gray7};
    padding: ${standardSpacing}px;
    min-height: ${doubleSpacing * 2}px;

    .cohorts-of-primary {
      max-width: 800px;
      text-align: center;
    }

    .link-to-primary {
      color: ${primary};
    }
  `;

  return (
    <div css={styles} style={style} className='d-flex flex-column justify-content-center align-items-center'>
      <div className='cohorts-of-primary page-title-small mb-1'>
        {t.COHORT_MANAGEMENT.COHORTS_OF_PRIMARY(resultsCount, primaryCourse.name)}
      </div>
      <ClickableContainer
        data-qa={config.pendo.orgDashboard.primaryLink}
        onClick={() => setFilters({
          [Filters.PRIMARY_SINGLE]: primaryCourse.catalogId,
        })}
        className='text-small bold link-to-primary'
      >
        {primaryCourse.catalogId}
      </ClickableContainer>
    </div>
  );
};

export default PrimaryCohortsHeader;
