import React from 'react';
import { Button } from 'react-bootstrap';
import uuid from 'react-uuid';
import NvTooltip from 'shared/components/nv-tooltip';

type SectionContentProps = {
  header: string
  description: string[]
  showCTAButton?: boolean
  ctaText?: string
  onAction?: () => void
  extras?: JSX.Element
  dataQa?: string
  buttonVariant?: 'primary' | 'secondary'
  disabled?: boolean
  tooltipEnabled?: boolean
  tooltipText?: string
};

export const SectionContent = ({
  header,
  description,
  showCTAButton,
  ctaText,
  onAction,
  extras,
  dataQa,
  buttonVariant,
  disabled,
  tooltipEnabled = false,
  tooltipText,
}: SectionContentProps) => (
  <div className='d-flex flex-column justify-content-center align-items-center'>
    <div className='page-title mb-4'>
      {header}
    </div>
    {description?.map?.(desc => (
      <div className='text-body align-self-start mb-4' key={uuid()}>{desc}</div>
    ))}
    {showCTAButton && ctaText && (
      <NvTooltip
        enabled={tooltipEnabled}
        text={tooltipText}
        placement='top'
      >
        <Button
          variant={buttonVariant ?? 'primary'}
          onClick={onAction && onAction}
          className='mt-6'
          data-qa={dataQa}
          disabled={disabled}
        >
          {ctaText}
        </Button>
      </NvTooltip>
    )}
    {extras}
  </div>
);


export default SectionContent;
