import { css } from '@emotion/react';
import { AngularServicesContext } from 'react-app';
import { useContext, useRef, useState, useCallback, Fragment } from 'react';
import { NvTeamAvatar } from 'components/nv-team-avatar';
import t from 'react-translate';
import { Institution } from 'redux/schemas/models/institution';
import { Course } from 'redux/schemas/models/course';
import { Team } from 'redux/schemas/models/team';
import { useSelector } from 'react-redux';
import { Button, ProgressBar } from 'react-bootstrap';
import { NvFroala } from 'froala/components/nv-froala';
import axios from 'axios';
import NvUserAvatar from 'components/nv-user-avatar';
import { getCourse, getCurrentCourse, getCourseAliases } from 'redux/selectors/course';
import useUploadFile, { NovoEdFile } from 'shared/hooks/use-upload-file';
import { S3NameSpaces } from 'shared/services/s3-upload-factory';
import { useDropzone } from 'react-dropzone';
import { validateFiles } from 'froala/helpers/nv-froala-functions';
import { threeQuartersSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { gray4, gray3 } from 'styles/global_defaults/colors';
import { textSmallFontSize, textSmallLineHeight } from 'styles/global_defaults/fonts';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import NvDatepicker, { DatePickerType } from 'shared/components/inputs/nv-datepicker';
import { useAppDispatch } from 'redux/store';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import moment from 'moment';
import NvTooltip from 'shared/components/nv-tooltip';
import { useForm, FormProvider } from 'react-hook-form';
import NvUploadedFile from 'shared/components/nv-uploaded-file';
import { config } from '../../../config/config.json';

type StateProps = {
  team?: Team,
  course?: Course,
  institution?: Institution,
  isTeam: boolean,
  onClose: Function,
};

const PostMessageModal = (props: StateProps) => {
  const styles = css`
    padding: ${standardSpacing}px 80px 40px;

    a {
      margin-bottom: ${standardSpacing}px;
    }

    .upload-file {
      font-size: ${textSmallFontSize}px;
      line-height: ${textSmallLineHeight}px;
      text-align: center;
      color: ${gray3};
      padding: ${threeQuartersSpacing}px;
      border: 1px dashed ${gray4};
      cursor: pointer;
    }

    .calendarShown {
      padding-top: 65px;
    }
  `;

  const [message, setMessage] = useState('');
  const [fileError, setFileError] = useState('');
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { uploadFiles, isUploading, hasUploadPercentage, filesUploading } = useUploadFile();
  const [document, setDocument] = useState<NovoEdFile>();
  const [isHighlighted, setIsHighlighted] = useState(false);

  const dispatch = useAppDispatch();
  const aliases = useSelector((state) => getCourseAliases(state));
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const currentCourse = useSelector((state) => getCurrentCourse(state));
  const userCourse = useSelector((state) => state.models.enrollments[currentCourse.userCourse]);
  const enrollmentUser = useSelector((state) => state.models.users[userCourse.userId]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);

  const onDrop = useCallback(async (files: File[]) => {
    setFileError('');

    /* Validate Files */
    const firstError = validateFiles(files, config.files.allUploadableExtensions);

    if (firstError) {
      setFileError(firstError);
    } else {
      const [novoEdFile] = await uploadFiles(files, S3NameSpaces.ATTACHMENTS);
      setDocument(novoEdFile);
    }
  }, [uploadFiles]);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
  });

  const methods = useForm({
    shouldUnregister: true,
    defaultValues: {
      message: '',
      highlightPost: false,
      startDate: null,
      endDate: null,
    },
  });

  const startDate = methods.watch('startDate');
  const endDate = methods.watch('endDate');

  const { handleSubmit } = methods;

  const isHighlightedWithoutDates = isHighlighted && !(startDate && endDate);

  const onSubmit = () => {
    setIsSubmitting(true);
    const topic: Record<string, any> = {
      body: message,
      mentionedIds: [],
    };
    if (!props.team) {
      topic.batchCreate = props.isTeam ? 'team' : 'group';
    }

    const payload: any = {
      from: isHighlighted ? startDate?.toISOString() : null,
      to: isHighlighted ? endDate?.toISOString() : null,
    };

    if (document) {
      const { name, size, type, uniqueId } = document;

      Object.assign(payload, topic, {
        filename: name,
        filesize: size,
        filetype: type,
        uniqueId,
        ownerId: props.team?.id,
        ownerType: 'team',
      });
    } else {
      Object.assign(payload, { topic });
    }

    let url: string;
    if (document) {
      url = `${catalogId}/attachments`;
    } else if (props.team) {
      url = `${catalogId}/teams/${props.team.id}/topics`;
    } else {
      url = `${catalogId}/teams/batch_create_message`;
    }
    if (!isSubmitting) {
      axios.post(url, payload).then(() => {
        props.onClose();
      }).then(() => {
        dispatch(addAlertMessage({
          type: AlertMessageType.SUCCESS,
          header: t.FORM.SUCCESS_BANG(),
          message: props.team
            ? t.TEAM_FACILITATION.POST_MESSAGES.SUCCESS_MESSAGE_SINGLE()
            : t.TEAM_FACILITATION.POST_MESSAGES.SUCCESS_MESSAGE_ALL({ ...aliases.teamAliases, ...aliases.groupAliases, isTeam: props.isTeam }),
        }));
      }).catch(() => {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.FORM.ERROR_SOMETHING_WRONG(),
        }));
      })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  const onCalendarOpen = () => {
    setIsCalendarOpen(true);
    const { parentNode } = formRef.current.parentNode;
    setTimeout(() => {
      parentNode.scrollTop = parentNode.scrollHeight;
    }, 10);
  };

  const onCalendarClose = () => {
    setIsCalendarOpen(false);
  };

  return (
    <div css={styles}>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          ref={formRef}
          className={isCalendarOpen ? 'calendarShown' : undefined}
        >
          {props.team && <NvTeamAvatar team={props.team} course={props.course} institution={props.institution} size='xl' center />}
          <div className='d-flex flex-row'>
            <NvUserAvatar borderType='round' size='md' displayName user={enrollmentUser} />
            <div className='w-100 ml-4'>
              <NvFroala
                withForm
                name='message'
                placeholder={t.TEAM_FACILITATION.POST_MESSAGES.MESSAGE_PLACEHOLDER()}
                value={message}
                onChange={(val) => setMessage(val)}
                className='mb-5'
              />

              {isUploading && (
                Object.keys(filesUploading).map((key) => {
                  const fileUploading = filesUploading[key];
                  return (
                    <div key={key} className='mb-4'>
                      <div>{fileUploading.file.name}</div>
                      <ProgressBar animated={!hasUploadPercentage} now={hasUploadPercentage ? fileUploading.uploadPercentage : 100} />
                    </div>
                  );
                })
              )}
              {!document && !isUploading && (
                <Fragment>
                  <div {...getRootProps({ className: 'upload-file' })}>
                    {/* Input Area */}
                    <input {...getInputProps()} />
                    <i className='icon icon-upload icon-medium' />
                    {t.FROALA.UPLOAD_MEDIA.ALL_DESCRIPTION()}
                  </div>
                  {fileError && <div className='warning'>{fileError}</div>}
                </Fragment>
              )}
              {document && (
                <NvUploadedFile
                  displayNameOnly
                  file={document}
                  onDelete={() => setDocument(null)}
                />
              )}
              <div className='d-flex flex-row mt-5'>
                <NvCheckbox
                  withForm
                  checked={isHighlighted}
                  name='highlightPost'
                  label={t.TEAM_FACILITATION.POST_MESSAGES.HIGHLIGHT_POST()}
                  onChange={(e) => setIsHighlighted(e.target.checked)}
                  labelClassName='text-medium pl-5'
                />
                <NvTooltip text={t.TEAM_FACILITATION.POST_MESSAGES.HIGHLIGHT_POST_INFO()}><span className='icon-info text-small text-primary m-1' /></NvTooltip>
              </div>
              {isHighlighted && (
                <div>
                  <div className='py-2'>{t.TEAM_FACILITATION.POST_MESSAGES.HIGHLIGHT_POST_DESCRIPTION()}</div>
                  <div className='d-flex flex-row'>
                    <NvDatepicker
                      onCalendarOpen={onCalendarOpen}
                      onCalendarClose={onCalendarClose}
                      withForm
                      name='startDate'
                      type={DatePickerType.DATETIME}
                      placement='top-start'
                      placeholder={t.DISCUSSIONS.HIGHLIGHT.FROM()}
                    />
                    <span className='font-weight-bold condensed py-2 px-2'>TO</span>
                    <NvDatepicker
                      onCalendarOpen={onCalendarOpen}
                      onCalendarClose={onCalendarClose}
                      withForm
                      name='endDate'
                      type={DatePickerType.DATETIME}
                      placement='top-start'
                      placeholder={t.DISCUSSIONS.HIGHLIGHT.TO()}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='d-flex justify-content-center pt-7 mt-2'>
            <Button variant='secondary' className='mr-2' onClick={() => props.onClose()}>{t.NOVOED.CANCEL()}</Button>
            <Button variant='primary' type='submit' disabled={(!message && !document) || isSubmitting || isHighlightedWithoutDates}>{props.team ? t.TEAM_FACILITATION.POST_MESSAGES.POST_TO_WORKSPACE() : t.TEAM_FACILITATION.POST_MESSAGES.POST_TO_ALL_WORKSPACES()}</Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default PostMessageModal;
