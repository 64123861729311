/* eslint-disable global-require */
/* @ngInject */
export default function prodPathReplace(target) {
  // Provide a function that replaces a given string with it's rewritten prod version from the
  // manifest file. Does no replacement if we're not building for production.
  if (process.env.NODE_ENV === 'production') {
    /* eslint-disable import/no-unresolved */
    const configJson = require('../../config/config.json');
    const manifest = require('../../dist/rev-manifest.json');
    return `${configJson.config.assets.host}/${manifest[target]}`;
  }

  return `/origami/${target}`;
}
