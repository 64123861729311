/* @ngInject */
export default function NvMultiselectDropdown(
  $uibModal,
  $window,
  nvUtil,
  CurrentCourseManager,
  _,
) {
  return {
    scope: {
      dropdownData: '=',
      options: '=?', // options that show below dropdown on selecting checkboxes
      name: '=?',
      isNestedData: '=?', // to manipulate data accordingly
      hasFilters: '=?', // dropdown has 'Filter By' option
      updateRecordCount: '&?',
      disabled: '=?',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
      vm.nvUtil = nvUtil;
      vm.CurrentCourseManager = CurrentCourseManager;

      vm.selectedCount = 0;
      vm.hasAssignmentLongTeams = true;
      vm.getHasTranslatedValue = getHasTranslatedValue;

      const omittedProperties = ['all', 'selectedCount'];

      function omitMetadata(data) {
        if (_.isArray(data)) {
          return data.filter((value) => (
            !omittedProperties.includes(value)
          ));
        }

        return _.omit(data, omittedProperties);
      }

      preprocess();

      function preprocess() {
        if (vm.dropdownData) {
          vm.obj = vm.dropdownData;
          vm.obj.all = false;

          if (vm.isNestedData) {
            vm.keys = Object.keys(vm.obj);
          }

          if (vm.name === 'teamTypes') {
            preprocessTeamTypesData();
          }

          updateSelectedCount();

          // For editing reports, if all checkboxes selected, update All checkbox accordingly
          if (vm.obj.all === false && vm.name !== 'teamTypes') {
            // If all keys selected except all, select all as well
            if (vm.selectedCount === Object.keys(omitMetadata(vm.obj)).length) {
              vm.obj.all = true;

              if (vm.hasFilters) {
                toggleAll();
              }
            }
          }
        }
      }

      function preprocessTeamTypesData() {
        vm.hasCourseLongTeam = _.has(vm.dropdownData, 'courseLongTeam');

        if (_.isEmpty(_.omit(vm.dropdownData, ['courseLongTeam', 'all', 'selectedCount']))) {
          vm.hasAssignmentLongTeams = false;
          vm.obj.all = true;
          vm.obj.courseLongTeam[1] = true;
        }
      }

      // Where clicking All checks all other boxes too
      vm.toggleSelectAll = function () {
        if (vm.isNestedData) {
          _.each(omitMetadata(vm.keys), (key) => {
            vm.dropdownData[key][1] = vm.obj.all;
          });
        } else {
          _.each((omitMetadata(vm.dropdownData)), (value, key) => {
            const isTranslated = getHasTranslatedValue(value);

            if (isTranslated) {
              vm.dropdownData[key] = vm.obj.all;
            } else {
              vm.dropdownData[key].value = vm.obj.all;
            }
          });
        }

        updateSelectedCount();
      };

      vm.toggleOption = function () {
        vm.obj.all = false;
        updateSelectedCount();
      };

      // Where clicking All unchecks other boxes
      vm.toggleAll = function () {
        toggleAll();
        updateSelectedCount();
      };

      vm.onDropdownClose = function (isOpen) {
        // Only if hasFilters and no option selected, set back obj to all
        if (!isOpen) {
          if (vm.selectedCount === 0) {
            vm.obj.all = true;

            if (vm.name === 'teamTypes' && !vm.hasAssignmentLongTeams) {
              vm.obj.courseLongTeam[1] = true;
            }
          }
        }
      };

      vm.showHelpfulCount = function () {
        // index 2 is 0 for private feedback
        const found = _.find(vm.keys, (key) => vm.dropdownData[key][1] && !vm.dropdownData[key][2]);

        return !!found;
      };


      function toggleAll() {
        if (vm.isNestedData) {
          _.each(omitMetadata(vm.keys), (key) => {
            vm.dropdownData[key][1] = vm.obj.all;
          });
        } else {
          _.each(omitMetadata(vm.dropdownData), (value, key) => {
            if (vm.name !== 'role') {
              vm.dropdownData[key] = vm.obj.all;
            } else {
              vm.dropdownData[key][2] = vm.obj.all;
            }
          });
        }
      }

      function updateSelectedCount() {
        let selected;

        if (vm.updateRecordCount) {
          vm.updateRecordCount(); // Number displayed in role-bubble
        }

        if (vm.name === 'role') {
          vm.selectedCount = 0;

          _.each(omitMetadata(vm.obj), (value, key) => {
            if (vm.obj[key][2]) {
              vm.selectedCount += 1;
            }
          });

          vm.obj.all = Object.keys(omitMetadata(vm.obj)).length === vm.selectedCount;
        } else if (vm.isNestedData) {
          selected = vm.keys.filter((key) => key !== 'all' && key !== 'selectedCount' && vm.obj[key][1] === true);

          vm.selectedCount = selected.length;
        } else {
          vm.selectedCount = 0;
          selected = _.each(omitMetadata(vm.obj), (value, key) => {
            const isTranslated = getHasTranslatedValue(value);

            if (isTranslated) {
              if (vm.obj[key] === true) {
                vm.selectedCount += 1;
              }
            } else if (vm.obj[key].value === true) {
              vm.selectedCount += 1;
            }
          });

          vm.obj.all = Object.keys(omitMetadata(vm.obj)).length === vm.selectedCount;
        }

        vm.dropdownData.selectedCount = vm.selectedCount; // used for Step 2 validation
      }


      function resetAll() {
        vm.selectedCount = 0;
        if (vm.isNestedData) {
          _.each(vm.options, (value, key) => {
            vm.options[key] = false;
          });
          _.each(vm.keys, (key) => {
            if (vm.dropdownData[key]) {
              vm.dropdownData[key][1] = false;
            } else {
              vm.dropdownData[key] = false;
            }
          });
        } else {
          _.each(omitMetadata(vm.dropdownData), (value, key) => {
            const isTranslated = getHasTranslatedValue(value);

            if (isTranslated) {
              vm.dropdownData[key] = false;
            } else {
              vm.dropdownData[key].value = false;
            }
          });
        }
      }

      /**
       * Boolean values have their corresponding translation
       */
      function getHasTranslatedValue(value) {
        return typeof value === 'boolean';
      }

      // Only for mentor dropdowns that are dependant and need to be reset
      if (vm.disabled != null) {
        $scope.$watch('vm.disabled', () => {
          if (vm.disabled === true) {
            resetAll();
          }
        });
      }
    },
    replace: true,
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'custom_downloads/templates/nv-multiselect-dropdown.html',
  };
}
