/* @ngInject */
export default function TimelineGroupFormationLectureComponentModelService(

  _,
  nvUtil,
  moment,
) {
  const TimelineGroupFormationLectureComponentModel = function (attributes) {
    const _this = this;
    const base = {
      templateUrl: 'timelines/templates/nv-timeline-team-formation.html',
    };

    /** Setting Up Basic Attributes * */
    _.extend(_this, base, attributes);
    // passed lecturePage, lectureSection, course
    preprocess();

    /** Public Functions * */
    _.extend(_this, {
      getStatus,
      isCompleted,
      isMissed,
      isTodoRequiredForCompletion,
      updateComponentPointsAndProgress,
      updateComponentProgress,
      getPayload,
    });

    /** Function Declarations * */
    /* Used Publicly */
    function getStatus() {
      return _this.teamSet.progress;
    }

    function isCompleted() {
      return _this.teamSet.progress === 'completed';
    }

    function isMissed() {
      return false;
    }

    function isTodoRequiredForCompletion() {
      return _this.teamSet.isRequiredForCompletion;
    }

    function updateComponentPointsAndProgress(componentType, componentId, pointsReceived, totalPoints, progress) {
      if ((_this.type === componentType && _this.id === componentId) || (componentType === 'team_set' && _this.teamSet.id === componentId)) {
        if (progress) {
          _this.teamSet.progress = progress;
        } else {
          _this.teamSet.progress = 'completed';
        }

        if (pointsReceived && pointsReceived > _this.teamSet.pointsReceived) {
          _this.teamSet.pointsReceived = pointsReceived;
          _this.pointsReceived = pointsReceived;
        }

        if (_.isNumber(totalPoints)) {
          _this.currentTotalPoints = totalPoints;
        } else {
          _this.currentTotalPoints = nvUtil.getCurrentTotalPoints(_this.totalPoints, _this.lecturePage.releaseDate, _this.course.expiringPointsEnabled);
        }

        viewPreprocess();

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function updateComponentProgress(componentType, componentId, progress) {
      if ((_this.type === componentType && _this.id === componentId) || (componentType === 'team_set' && _this.teamSet.id === componentId)) {
        _this.teamSet.progress = progress;

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function getPayload() {
      return _.pick(_this, ['title']);
    }


    /* Used Privately */
    function preprocess() {
      _.extend(_this, _.pick(_this.teamSet, 'title', 'isTodo', 'hasStructuralIssues', 'pointsReceived',
        'totalPoints', 'scheduledCommunicationsCount'));
      _this.activityId = _this.teamSet.id;

      _this.currentTotalPoints = nvUtil.getCurrentTotalPoints(_this.totalPoints, _this.lecturePage.releaseDate, _this.course.expiringPointsEnabled);

      viewPreprocess();
    }

    function viewPreprocess() {
      if (isCompleted() || _this.pointsReceived > _this.currentTotalPoints) {
        _this.currentTotalPoints = _this.pointsReceived ? _this.pointsReceived : 0;
      }
      _this.displayTotalPointsOnly = !_this.pointsReceived && _this.currentTotalPoints;
    }
  };

  return TimelineGroupFormationLectureComponentModel;
}
