import prodPathReplace from 'shared/prod-path-rewrite';
import { config } from '../../../../../config/config.json';

const useUploadedFile = () => {
  const getNormalizeFileType = (fileType, fileName) => {
    fileType = fileType.split(';codecs')[0];
    if (!fileType) {
      const extension = `.${fileName.split('.').reverse()[0]}`;
      fileType = config.files.extensionToMimeType[extension];
    }
    if (fileType === 'image' || config.files.images.split(',').includes(fileType)) {
      return 'image';
    } if (config.files.videos.split(',').includes(fileType)) {
      return 'video';
    } if (config.files.audios.split(',').includes(fileType)) {
      return 'audio';
    } if (config.files.pdfs.split(',').includes(fileType)) {
      return 'pdf';
    } if (config.files.documents.split(',').includes(fileType)) {
      return 'document';
    } if (config.files.excels.split(',').includes(fileType)) {
      return 'spreadsheet';
    } if (config.files.presentations.split(',').includes(fileType)) {
      return 'presentation';
    } if (config.files.zips.split(',').includes(fileType)) {
      return 'zip';
    }
    return 'other';
  };

  const getImageSrc = (fileType) => {
    // NOTE: have to return the full path string so gulp will be able to process properly
    switch (fileType) {
      case 'pdf':
        return prodPathReplace('images/file-pdf.png');
      case 'image':
        return prodPathReplace('images/file-image.png');
      case 'video':
        return prodPathReplace('images/file-video.png');
      case 'audio':
        return prodPathReplace('images/file-audio.png');
      case 'document':
        return prodPathReplace('images/file-doc.png');
      case 'spreadsheet':
        return prodPathReplace('images/file-excel.png');
      case 'presentation':
        return prodPathReplace('images/file-powerpoint.png');
      case 'externaldocument':
        return prodPathReplace('images/file-externaltools.png');
      case 'zip':
        return prodPathReplace('images/file-zip.png');
      case 'other':
        return prodPathReplace('images/file-other.png');
      default:
        return null;
    }
  };

  const isFileAvailableToDownload = (file) => (
    (typeof file.isDownloadable === 'undefined' || file.isDownloadable === null) ? true : file.isDownloadable
  );

  return {
    getNormalizeFileType,
    getImageSrc,
    isFileAvailableToDownload,
  };
};

export default useUploadedFile;
