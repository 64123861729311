/*
modalOptions: {
   afterUploadEach: createEmbedding,
   multiple: true | false,
   s3namespace: 'attachments/original' (default)
}
*/
/* @ngInject */
export default function MediaUploadModalInstanceController(
  _,
  $uibModalInstance,
  modalOptions,
  $q,
  S3NameSpaces,
  S3UploadFactory,
  $http,
  config,
) {
  const vm = this;
  vm.modalOptions = modalOptions;
  vm.modalOptions.s3namespace = vm.modalOptions.s3namespace || S3NameSpaces.ATTACHMENTS;
  vm.filesInProgress = [];

  vm.uploadFiles = function (form, files) {
    const promises = [];
    const valid = _.every(files, (file) => !file.$error);

    if (!valid || !files || !files.length) {
      return;
    }

    _.each(files, (file) => {
      const s3UploadOjbect = S3UploadFactory.uploadToS3(file, vm.modalOptions.s3namespace, true);
      s3UploadOjbect.promise.then((response) => {
        if (modalOptions.afterUploadEach) {
          modalOptions.afterUploadEach(response);
        }
      });

      promises.push(s3UploadOjbect.promise);

      vm.filesInProgress.push(s3UploadOjbect);
    });

    $q.all(promises).then((data) => {
      $uibModalInstance.close(data);
    });
  };

  vm.acceptedPatterns = function () {
    let pattern;

    if (vm.modalOptions.uploadType === 'image') {
      pattern = config.files.rte.images.mimeTypes.concat(config.files.rte.images.extensions);
    } else {
      pattern = _.flatten(_.map(config.files.rte, (type) => type.mimeTypes.concat(type.extensions)));
    }

    return pattern.join(',');
  };

  vm.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };

  vm.formSubmit = () => {
    $http.post('/embeddings/create_from_url', {
      mediaUrl: vm.modalOptions.model.publicLink,
      mediaWidth: vm.modalOptions.model.mediaWidth,
      mediaHeight: vm.modalOptions.model.mediaHeight,
    }).then((response) => {
      if (response.data.valid) {
        modalOptions.onConfirm(response.data);
      }
    }).then(() => {
      $uibModalInstance.close();
    });
  };
}
