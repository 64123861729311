/* @ngInject */
export default function AccountBasicsController(
  _,
  $scope,
  config,
  CurrentUserManager,
  InstitutionsManager,
  UserModel,
  AlertMessages,
  ConfirmationOverlays,
  validationConstants,
  vcRecaptchaService,
  StateManager,
) {
  const ACCOUNT_TYPE = {
    NON_SSO: 'non_sso', // All account info is editable
    SSO_EXTERNALLY_MANAGED: 'sso_externally_managed', // Info is readonly, editable at external link
    SSO_READONLY: 'sso_readonly', // Info readonly, no external link provided
    SSO_NAMEONLY: 'sso_nameonly', // Name info can be edited, password must be updated at provided link
  };

  const vm = this;

  vm.config = config;

  vm.recaptchaResponse = null;
  vm.captchaWidgetId = null;
  vm.recaptchaError = null;

  vm.CurrentUserManager = CurrentUserManager;
  vm.firstNameDraft = vm.CurrentUserManager.user.firstName;
  vm.lastNameDraft = vm.CurrentUserManager.user.lastName;
  vm.emailAddressDraft = vm.CurrentUserManager.user.email;
  vm.currentPasswordDraft = '';
  vm.newPasswordDraft = '';
  vm.repeatPasswordDraft = '';

  vm.currentInstitution = vm.CurrentUserManager.currentInstitution;
  vm.InstitutionsManager = InstitutionsManager;
  vm.AlertMessages = AlertMessages;
  vm.validationConstants = validationConstants;
  vm.ACCOUNT_TYPES = ACCOUNT_TYPE;
  vm.ssoUpdateLink = '';

  vm.hasSystemGeneratedAddress = CurrentUserManager.user.hasSystemGeneratedAddress;
  vm.allowsSsoWithoutEmail = vm.InstitutionsManager.institution.allowsSsoWithoutEmail;

  vm.savingBasics = false;
  vm.savingPassword = false;

  vm.tabConfig = StateManager.accountSettingsTabs.find(tab => tab.state === 'account-basics');

  // Determine whether this account is SSO managed and if so what basics are editable
  vm.userAccountType = ACCOUNT_TYPE.NON_SSO;
  if (vm.currentInstitution?.ssoLogin) {
    const latestIDP = _.find(vm.currentInstitution.identityProviders,
      (i) => i.name === vm.CurrentUserManager.user.latestAuthProviderName);
    if (latestIDP) {
      vm.ssoUpdateLink = latestIDP.accountInfoManagementLink;

      if (vm.currentInstitution.enableBasicSsoInfoEdit) {
        vm.userAccountType = ACCOUNT_TYPE.SSO_NAMEONLY;
      } else if (vm.ssoUpdateLink && !vm.currentInstitution.enableBasicSsoInfoEdit) {
        vm.userAccountType = ACCOUNT_TYPE.SSO_EXTERNALLY_MANAGED;
      } else {
        vm.userAccountType = ACCOUNT_TYPE.SSO_READONLY;
      }
    }
  }

  // Whether the basic info fields are read-only or editable
  vm.basicsReadOnly = vm.userAccountType === ACCOUNT_TYPE.SSO_EXTERNALLY_MANAGED
                      || vm.userAccountType === ACCOUNT_TYPE.SSO_READONLY
                      || !vm.InstitutionsManager.institution.enableBasicSsoInfoEdit;
  // Disables email input separately from vm.basicsReadOnly
  vm.emailDisabled = vm.basicsReadOnly || vm.userAccountType === ACCOUNT_TYPE.SSO_NAMEONLY
                     || (vm.InstitutionsManager.institution.allowsSsoWithoutEmail && vm.userAccountType !== vm.ACCOUNT_TYPES.NON_SSO);
  vm.updatePasswordVisible = vm.userAccountType === vm.ACCOUNT_TYPES.NON_SSO;

  const setEmailDraft = () => {
    vm.hasSystemGeneratedAddress = CurrentUserManager.user.hasSystemGeneratedAddress;
    if (vm.hasSystemGeneratedAddress) {
      vm.emailAddressDraft = 'No Email Address on File';
    } else {
      vm.emailAddressDraft = vm.CurrentUserManager.user.email;
    }
  };
  vm.loggedInFromRussia = vm.CurrentUserManager.user.loggedInFromRussia;

  /** Save first name, last name, and email address changes. */
  vm.saveMyInformation = () => {
    vm.savingBasics = true;

    // Don't send the email address or show the email verification alert if the email address has
    // not changed.
    const changedEmail = vm.emailAddressDraft !== vm.CurrentUserManager.user.email && !vm.hasSystemGeneratedAddress;

    vm.CurrentUserManager.user.saveProfile(
      vm.firstNameDraft,
      vm.lastNameDraft,
      changedEmail ? vm.emailAddressDraft : null,
    )
      .then((response) => {
        if (response.result?.errorCodes?.length > 0) {
          // This endpoint will send a 207 Partial success if it's able to update some of the fields,
          // but not all of them.
          // This loops through any partial success error codes, displays an error
          // alert for each, and resets the offending fields back to their
          // initial values.
          response.result.errorCodes.forEach((errorCode) => {
            let errorString = '';
            switch (errorCode) {
              case 'profile.error.first_name':
                vm.firstNameDraft = vm.CurrentUserManager.user.firstName;
                errorString = 'ACCOUNT_SETTINGS.ACCOUNT_BASICS.FIRST_NAME_NOT_UPDATED';
                break;
              case 'profile.error.last_name':
                vm.lastNameDraft = vm.CurrentUserManager.user.lastName;
                errorString = 'ACCOUNT_SETTINGS.ACCOUNT_BASICS.LAST_NAME_NOT_UPDATED';
                break;
              case 'profile.error.email':
                vm.emailAddressDraft = vm.CurrentUserManager.user.email;
                errorString = 'ACCOUNT_SETTINGS.ACCOUNT_BASICS.EMAIL_NOT_UPDATED';
                break;
              default:
                errorString = 'ACCOUNT_SETTINGS.ACCOUNT_BASICS.UNKNOWN_ERROR';
                break;
            }

            vm.AlertMessages.error('FORM.OOPS', errorString);
          });

          vm.myInfoForm.$setPristine();
          return;
        }

        if (changedEmail && !response.result.verified) {
          vm.AlertMessages.success('ACCOUNT_SETTINGS.ACCOUNT_BASICS.EMAIL_UPDATED', 'ACCOUNT_SETTINGS.ACCOUNT_BASICS.CHANGED_EMAIL_VERIFY');
        } else {
          vm.AlertMessages.success('FORM.SUCCESS_BANG', 'ACCOUNT_SETTINGS.ACCOUNT_BASICS.UPDATE_SUCCESS');
        }

        vm.myInfoForm.$setPristine();
      }).catch((e) => {
        vm.AlertMessages.error('FORM.OOPS', 'USERS.REGISTRATION.ERRORS.GENERIC');
        vcRecaptchaService.reload(vm.captchaWidgetId);

        if (e.data?.error?.code === 'login.errors.recaptcha') {
          vm.recaptchaError = true;
        }
      }).finally(() => {
        vm.savingBasics = false;
      });
  };

  /** Save password updates */
  vm.saveNewPassword = () => {
    vm.savingPassword = true;
    vm.CurrentUserManager.user.updatePassword(
      vm.currentPasswordDraft, vm.newPasswordDraft, vm.repeatPasswordDraft,
    ).then((x) => {
      vm.AlertMessages.success('FORM.SUCCESS_BANG', 'ACCOUNT_SETTINGS.ACCOUNT_BASICS.UPDATE_SUCCESS');
      vm.passwordForm.$setPristine();
      vm.currentPasswordDraft = '';
      vm.newPasswordDraft = '';
      vm.repeatPasswordDraft = '';
    }).catch((e) => {
      vm.AlertMessages.error('FORM.OOPS', 'ACCOUNT_SETTINGS.ACCOUNT_BASICS.CURRENT_PASSWORD_WRONG');
    }).finally(() => {
      vm.savingPassword = false;
    });
  };

  vm.getSSOUpdateTag = () => `<a href="${vm.ssoUpdateLink}" target="_blank">${vm.ssoUpdateLink}</a>`;

  /** Display an 'Are you sure?' modal upon attempting to cancel form changes */
  vm.cancelForm = () => {
    const modalInstance = ConfirmationOverlays.openConfirmationModal('shared/templates/modal-navigate-away.html');
    modalInstance.result.then(() => {
      // Reset the forms
      vm.firstNameDraft = vm.CurrentUserManager.user.firstName;
      vm.lastNameDraft = vm.CurrentUserManager.user.lastName;
      vm.emailAddressDraft = vm.hasSystemGeneratedAddress ? 'No Email Address on File' : vm.CurrentUserManager.user.email;
      vm.currentPasswordDraft = '';
      vm.newPasswordDraft = '';
      vm.repeatPasswordDraft = '';
      vm.myInfoForm.$setPristine();

      if (vm.passwordForm) {
        vm.passwordForm.$setPristine();
      }
    });
  };

  const deregisterStateChangeStart = StateManager.registerStateChangeStart(() => (vm.myInfoForm?.$dirty) || (vm.passwordForm?.$dirty),
    'shared/templates/modal-navigate-away.html',
    'FORM.NAVIGATE_AWAY.CLOSE_WINDOW', undefined, undefined);

  vm.onCaptchaWidgetCreate = (widgetId) => {
    vm.captchaWidgetId = widgetId;
  };

  vm.resendVerificationEmail = () => {
    // Send an email to the current (non-draft) email address
    vm.CurrentUserManager.user.sendVerificationEmail().then(() => {
      vm.AlertMessages.success('ACCOUNT_SETTINGS.ACCOUNT_BASICS.VERIFICATION_LINK_SENT', 'ACCOUNT_SETTINGS.ACCOUNT_BASICS.CHANGED_EMAIL_VERIFY');
    }).catch((e) => {
      vm.AlertMessages.error('', 'USERS.REGISTRATION.ERRORS.GENERIC');
    });
  };
  $scope.$watch('vm.CurrentUserManager.user.email', () => {
    setEmailDraft();
  });
  $scope.$on('$destroy', deregisterStateChangeStart);
}
