/* @ngInject */
export default function TeamDirectoryMainController(
  TeamDirectoryManager,
  $stateParams,
  CurrentUserManager,
  CurrentCourseManager,
  RailsRoutes,
) {
  const vm = this;

  vm.currentUserManager = CurrentUserManager;
  vm.manager = TeamDirectoryManager;
  vm.currentCourseManager = CurrentCourseManager;
  vm.railsRoutes = RailsRoutes;


  if (TeamDirectoryManager.initialized) {
    TeamDirectoryManager.resetData();
  }

  TeamDirectoryManager.initialize({ catalogId: $stateParams.catalogId, dropdownFilter: $stateParams.teamFilter });

  TeamDirectoryManager.fetchProfileQuestionFilters().then(TeamDirectoryManager.doSearch());
}
