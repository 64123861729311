import React from 'react';
import { css } from '@emotion/react';

import NvIcon from 'shared/components/nv-icon';

type Props = {
  className?: string,
  style?: React.CSSProperties,
};

const LockedOverlay = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    style,
    className,
  } = props;

  const styles = css`
    .icon {
      color: #fff;
    }
  `;

  return (
    <div
      ref={ref}
      css={styles}
      style={style}
      className={`d-flex align-items-center justify-content-center ${className ? ` ${className}` : ''}`}
    >
      <NvIcon
        size='small'
        icon='locked'
      />
    </div>
  );
});

export default LockedOverlay;
