import groupBy from 'lodash/groupBy';
import React, { useContext, useEffect } from 'react';
import { AngularServicesContext } from 'react-app';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { getFlyoutMemberships, getFlyoutMembershipsCurrentCourse } from 'redux/actions/courses';
import { BasicMembership } from 'redux/schemas/models/course';
import { useAppDispatch } from 'redux/store';
import { LoaderType } from 'shared/components/loading-placeholder';
import useInfiniteLoading from 'shared/hooks/use-infinite-loading';
import { FlyoutNames } from 'lhs/services/flyout-config';
import FlyoutContainer from './flyout-container';
import FlyoutCourseContainerCard from './flyout-course-container-card';
import { AllLoaded, EmptyContent, LoadingBar } from './loading-placeholders';

const TeamsGroupsPanel = () => {
  const dispatch = useAppDispatch();
  const { $state, FlyoutModalManager } = useContext(AngularServicesContext);
  const { currentCatalogId, currentCourseId } = useSelector((state) => state.app);
  const {
    items,
    noItems,
    loading,
    loadingMore,
    hasMore,
    ids,
  } = useInfiniteLoading(
    'myTeamsAndGroups',
    'basicMemberships',
    { page_size: 30, sort_by: 'course' },
    getFlyoutMemberships,
  );
  const memberships = items as BasicMembership[];
  const courses = groupBy(memberships, membership => membership.team.courseId);
  const hasCurrentCourse = currentCatalogId && $state.includes('course-wrapper');
  const flyoutIsOpen = FlyoutModalManager.flyoutName() === FlyoutNames.TEAMS_GROUPS && FlyoutModalManager.isVisible();

  useEffect(() => {
    // Making sure to get the updated list of memberships in the flyout in order to avoid refreshing the page
    if (currentCourseId && flyoutIsOpen) {
      dispatch(getFlyoutMembershipsCurrentCourse({ course_id: currentCourseId }));
    }
  }, [hasCurrentCourse, currentCourseId, flyoutIsOpen, dispatch]);

  return (
    <FlyoutContainer title={t.LHS.TEAMS_AND_GROUPS()}>
      <>
        {loading && <LoadingBar loaderType={LoaderType.FULL} />}
        {loadingMore && <LoadingBar loaderType={LoaderType.HALF} />}
        { !loading && noItems
          ? <EmptyContent text={t.LHS.NO_TEAMS_GROUPS()} />
          : [...ids].sort((teamA, teamB) => {
            const isCurrentCourseTeamA = currentCourseId === courses[teamA][0].team.courseId;
            const isCurrentCourseTeamB = currentCourseId === courses[teamB][0].team.courseId;

            if (isCurrentCourseTeamA === isCurrentCourseTeamB) {
              return 0;
            }

            return isCurrentCourseTeamA ? -1 : 1;
          }).map((id, index) => (
            <FlyoutCourseContainerCard
              index={index}
              key={id}
              courseName={courses[id][0].team.courseName}
              groupsData={courses[id]}
              isCurrentCourse={
                currentCourseId === courses[id][0].team.courseId && $state.includes('course-wrapper')
              }
            />
          ))}
        {!hasMore && !noItems && <AllLoaded />}
      </>
    </FlyoutContainer>
  );
};

export default TeamsGroupsPanel;
