import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';

import { AngularServicesContext } from 'react-app';
import t from 'react-translate';
import { findIndex } from 'lodash';

import { cloneDeepSerializable, useAppDispatch } from 'redux/store';
import { Submission, SubmittingStatus } from 'redux/schemas/models/exercise';
import { BookmarkType } from 'redux/schemas/models/bookmark';
import { RatingActivityExercise } from 'redux/schemas/models/exercise-skills-rating';

import { likeSubmission, setSubmission, unLikeSubmission } from 'redux/actions/submissions';
import { deleteFeedback, resetSkillsFeedbackState } from 'redux/actions/skills-feedback';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { createBookmark, highlightBookmark } from 'redux/actions/bookmarks';
import { getCurrentUserId } from 'redux/selectors/users';

import { handheld } from 'styles/global_defaults/media-queries';
import { NvResponsiveTabsDisplayType, NvTab } from 'shared/components/nv-responsive-tabs';
import NvResponsiveTabsRow from 'shared/components/nv-responsive-tabs-row';
import NvIcon from 'shared/components/nv-icon';
import BookmarkIcon from 'bookmarks/components/bookmark-icon';
import CommentsTab from './tabs/comments-tab';
import YourFeedbackTab from './tabs/your-feedback-tab';
import AllFeedbacksTab from './tabs/all-feedbacks-tab';
import UserFeedbackTab from './tabs/user-feedback-tab';

export enum Tabs {
  COMMENTS = 'comments',
  SKILLS_FEEDBACK = 'skills_feedback',
  ALL_FEEDBACK = 'all_feedback',
  USER_FEEDBACK = 'user_feedback',
}

const styles = css`
  ${handheld(css`
    .nv-responsive-tabs-row-wrapper {
      .nv-responsive-tabs-wrapper {
        margin-left: 0px;
      }
    }
  `)}
`;

const SkillsRatingTabs = () => {
  const { $state, $timeout, $scope, FlyoutModalManager, CurrentPermissionsManager } = useContext(AngularServicesContext);
  const { reportId, selected, userId, isRatingFeedback } = $state?.params;
  const dispatch = useAppDispatch();

  const submission: Submission = useSelector(state => state.models.submissions?.[reportId]);
  const exercise = submission?.exercise as RatingActivityExercise;
  const currentInstitutionId = useSelector(
    (state) => state.app.currentInstitutionId,
  );
  const currentUserId = useSelector(state => getCurrentUserId(state));

  const [activeTab, setActiveTab] = useState<Tabs>(null);

  const isOwnSubmission = submission?.isCollaborator;
  const isAdmin = CurrentPermissionsManager.isTeachingAssistant()
    || CurrentPermissionsManager.isInstructor()
    || submission?.isUserMentorOfCollaborators;

  const skillRatingTabs = useMemo(() => {
    const tabs: (NvTab & { key?: string })[] = [];

    // If this is an own submission and is in pending approval status Comments tab
    // need to be shown. In every other case, comments will be shown
    if (!(isOwnSubmission && submission.submitting.submissionStatus === SubmittingStatus.PENDING_APPROVAL)) {
      tabs.push({
        text: t.DISCUSSIONS.COMMENTS(),
        onClick: () => setActiveTab(Tabs.COMMENTS),
        key: Tabs.COMMENTS,
      });
    }

    if (submission && !isOwnSubmission) {
      tabs.push({
        text: t.SKILLS_FEEDBACK.YOUR_FEEDBACK(),
        onClick: () => setActiveTab(Tabs.SKILLS_FEEDBACK),
        key: Tabs.SKILLS_FEEDBACK,
      });
    }

    if (userId) {
      tabs.push({
        text: t.SKILLS_FEEDBACK.ALL_FEEDBACK(),
        onClick: () => setActiveTab(Tabs.USER_FEEDBACK),
        key: Tabs.USER_FEEDBACK,
      });
    } else if (isAdmin || isOwnSubmission) {
      tabs.push({
        text: t.SKILLS_FEEDBACK.ALL_FEEDBACK(),
        onClick: () => setActiveTab(Tabs.ALL_FEEDBACK),
        key: Tabs.ALL_FEEDBACK,
      });
    }
    return tabs;
  }, [isAdmin, isOwnSubmission, submission, userId]);

  useEffect(() => () => {
    dispatch(resetSkillsFeedbackState({
      ownerType: 'Report',
      ownerId: reportId,
    }));
  }, [dispatch, reportId]);

  useEffect(() => {
    if (selected) {
      setActiveTab(selected);
    } else if (isRatingFeedback && submission?.isRatedByCurrentUser) {
      setActiveTab(Tabs.SKILLS_FEEDBACK);
    } else if (userId) {
      setActiveTab(Tabs.USER_FEEDBACK);
    } else if (skillRatingTabs.length > 0) {
      setActiveTab(skillRatingTabs[0].key as Tabs);
    }
  // Not including skillRatingTabs as a dependency as refresh of skillRatingTabs
  // will execute this effect again.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRatingFeedback, selected, submission?.isRatedByCurrentUser, userId]);

  // Get the submission from angular to push to redux
  useEffect(() => {
    if ($scope.ReportsManager?.currentReport) {
      const { currentReport } = $scope.ReportsManager;
      currentReport.submitting = currentReport.submittingObject;
      dispatch(setSubmission(cloneDeepSerializable(currentReport)));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, $scope.ReportsManager?.currentReport]);

  // To make the comments count data in sync with angular model, when closing
  // the submission modal the angular model data is updated
  useEffect(() => () => {
    if ($scope.ReportsManager.currentReport?.numPostsAndComments
        && submission?.numPostsAndComments
        && $scope.ReportsManager.currentReport.numPostsAndComments !== submission.numPostsAndComments) {
      $scope.ReportsManager.currentReport.numPostsAndComments = submission.numPostsAndComments;
    }
  }, [$scope.ReportsManager.currentReport, submission?.numPostsAndComments]);

  const onCreateBookmark = () => {
    dispatch(createBookmark({
      type: BookmarkType.SUBMISSION,
      catalogId: $state.params.catalogId,
      componentId: reportId,
    })).then(() => {
      FlyoutModalManager.openFlyout({
        controller: 'BookmarksFlyoutCtrl as vm',
        template: 'bookmarks/templates/bookmarks-flyout-react-app.html',
        isNavigational: false,
      });
    });
  };

  const onHighlightBookmark = () => {
    dispatch(highlightBookmark({ id: submission?.bookmarkId }));
    FlyoutModalManager.openFlyout({
      controller: 'BookmarksFlyoutCtrl as vm',
      template: 'bookmarks/templates/bookmarks-flyout-react-app.html',
      isNavigational: false,
    });
  };

  const onDeleteFeedback = (deletedByUserId?: number) => {
    const warningTitle = deletedByUserId
      ? t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.SUBMISSION.DELETE.ADMIN.TITLE()
      : t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.SUBMISSION.DELETE.AUTHOR();
    const desc = deletedByUserId ? t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.SUBMISSION.DELETE.ADMIN.DESC() : '';
    dispatch(openConfirmationDialog({
      onConfirm: () => dispatch(deleteFeedback({
        institutionId: currentInstitutionId,
        ownerId: reportId,
        ownerType: 'Report',
        userId: deletedByUserId ?? currentUserId,
        feedbackCriteriaId: exercise.skillsRatingFeedback.id,
      })).then((data) => {
        $timeout(() => { $scope.toggleRatingForm(false); });
        if ($scope.ReportsManager?.currentReport) {
          $scope.ReportsManager.currentReport.isRatedByCurrentUser = false;

          const { requiredToComplete, numReviewesCompleted } = data.payload?.skillsRating ?? {};
          if ($scope.ReportsManager.currentReport?.exercise?.skillsRatingFeedback) {
            $scope.ReportsManager.currentReport.exercise.skillsRatingFeedback = {
              ...$scope.ReportsManager.currentReport.exercise.skillsRatingFeedback,
              requiredToComplete,
              numReviewesCompleted,
            };
          }
        }
      }),
      cancelText: t.FORM.CANCEL(),
      confirmText: t.FORM.YES_SURE(),
      title: warningTitle,
      bodyText: desc,
    }));
  };

  return (
    <div css={styles} className='mt-6 bg-gray-7'>
      <div className='body-text-wrapper d-flex justify-content-between px-4'>
        <NvResponsiveTabsRow
          defaultTabs={skillRatingTabs}
          tabTextClass='card-title'
          tabType={NvResponsiveTabsDisplayType.TEXT_ONLY}
          revertActiveTab={activeTab && findIndex(skillRatingTabs, (tab) => tab.key === activeTab)}
        />
        <div className='d-flex actions-container justify-content-around align-items-center ml-4'>
          <div className='d-flex mr-4'>
            <NvIcon
              icon='like'
              size='small'
              className={`mr-1 ${submission?.liked ? 'text-primary' : 'text-gray-1'}`}
              onClick={() => (submission?.liked
                ? dispatch(unLikeSubmission({
                  catalogId: $state.params?.catalogId,
                  reportId,
                })) : dispatch(likeSubmission({
                  catalogId: $state.params?.catalogId,
                  reportId,
                }))
              )}
            />
            {submission?.votesCount ?? ''}
          </div>
          <div className='d-flex mr-4'>
            <NvIcon
              icon='comments'
              size='small'
              className={`mr-1 ${submission?.commented ? 'text-primary' : 'text-gray-1'}`}
            />
            {submission?.numPostsAndComments ?? ''}
          </div>
          <BookmarkIcon
            size='small'
            bookmarked={!!submission?.bookmarkId}
            onCreate={onCreateBookmark}
            onHighlight={onHighlightBookmark}
          />
        </div>
      </div>
      <div className='border-bottom border-gray-6' />
      <div className='body-text-wrapper pt-4 px-4'>
        {activeTab === Tabs.COMMENTS && (
          <CommentsTab reportId={reportId} />
        )}
        {activeTab === Tabs.SKILLS_FEEDBACK && (
          <YourFeedbackTab
            reportId={reportId}
            onDeleteFeedback={onDeleteFeedback}
          />
        )}
        {activeTab === Tabs.USER_FEEDBACK && (
          <UserFeedbackTab
            reportId={reportId}
            feedbackBy={userId}
          />
        )}
        {activeTab === Tabs.ALL_FEEDBACK && (
          <AllFeedbacksTab
            reportId={reportId}
            isAdmin={isAdmin}
            onDeleteFeedback={onDeleteFeedback}
            feedbackCriteriaId={exercise.skillsRatingFeedback.id}
          />
        )}
      </div>
    </div>
  );
};

export default SkillsRatingTabs;
