
import { css } from '@emotion/react';
import t from 'react-translate';

// Styles
import { gray3 } from 'styles/global_defaults/colors';
import { quarterSpacing, standardSpacing, doubleSpacing } from 'styles/global_defaults/scaffolding';
import { textSmallFontSize } from 'styles/global_defaults/fonts';
import React from 'react';

export const loadingPlaceholderStyles = css`
  @keyframes shineAnimation {
    0% {
      background-position: 0% 50%;
    }

    100% {
      background-position: -100% 50%;
    }
  }

  .loading {
    background: linear-gradient(90deg, #E4E7EC 35%, #F7F8FA 45%, #E4E7EC 55%);
    background-size: 600% 600%;
    animation: shineAnimation 1.5s infinite;
  }

  .loading:first-of-type {
    margin-top: ${doubleSpacing}px;
  }

  .placeholder-bar {
    width: 100%;
    height: ${quarterSpacing}px;
    margin-bottom: ${standardSpacing}px;
    margin-left: auto;
    margin-right: auto;
  }

  .short-bar {
    margin-left: 0;
    margin-right: 0;
    width: 50%;
  }

  .loading-text {
    color: ${gray3};
    margin-bottom: ${standardSpacing}px;
    font-size: ${textSmallFontSize}px
  }
`;

/** Shows a three-bar loading animation used as a placeholder graphic and UI areas where data is
 * still loading. */
const LoadingPlaceholder = () => (
  <div css={loadingPlaceholderStyles}>
    <LoadingPlaceholderBlock />
    <div className='text-center loading-text'>{t.TIMELINE.LOADING()}</div>
    <LoadingPlaceholderBlock />
  </div>
);

export const LoadingPlaceholderBlock = () => (
  <React.Fragment>
    <div className='loading placeholder-bar' />
    <div className='loading placeholder-bar' />
    <div className='loading placeholder-bar short-bar' />
  </React.Fragment>
);

export default LoadingPlaceholder;
