import { MockDefinition, MockTypes } from 'shared/axios/axios-mocks';

const basicEnrollments = [
  {
    id: 8,
    catalogId: 'coursea',
    name: 'jcourse1',
    thumbnail: '/assets/brand/temp.png',
    closeDate: '2023-07-04T18:30:00Z',
    headerColor: '#748499',
    inJourneys: [
      {
        id: 9,
        catalogId: 'journey1',
        name: 'journey1',
      },
    ],
  },
  {
    id: 2,
    catalogId: 'courseb',
    name: 'Course 2',
    thumbnail: '/assets/brand/temp.png',
    closeDate: null,
    headerColor: '#748499',
    inJourneys: [],
  },
  {
    id: 3,
    catalogId: 'courseb',
    name: 'Course 2',
    thumbnail: '/assets/brand/temp.png',
    closeDate: null,
    headerColor: '#748499',
    inJourneys: [],
  },
  {
    id: 4,
    catalogId: 'courseb',
    name: 'Course 2',
    thumbnail: '/assets/brand/temp.png',
    closeDate: null,
    headerColor: '#748499',
    inJourneys: [],
  },
  {
    id: 5,
    catalogId: 'courseb',
    name: 'Course 2',
    thumbnail: '/assets/brand/temp.png',
    closeDate: null,
    headerColor: '#748499',
    inJourneys: [],
  },
  {
    id: 6,
    catalogId: 'courseb',
    name: 'Course 2',
    thumbnail: '/assets/brand/temp.png',
    closeDate: null,
    headerColor: '#748499',
    inJourneys: [],
  },
  {
    id: 7,
    catalogId: 'courseb',
    name: 'Course 2',
    thumbnail: '/assets/brand/temp.png',
    closeDate: null,
    headerColor: '#748499',
    inJourneys: [],
  },
  {
    id: 8,
    catalogId: 'courseb',
    name: 'Course 2',
    thumbnail: '/assets/brand/temp.png',
    closeDate: null,
    headerColor: '#748499',
    inJourneys: [],
  },
  {
    id: 9,
    catalogId: 'courseb',
    name: 'Course 2',
    thumbnail: '/assets/brand/temp.png',
    closeDate: null,
    headerColor: '#748499',
    inJourneys: [],
  },
  {
    id: 10,
    catalogId: 'courseb',
    name: 'Course 2',
    thumbnail: '/assets/brand/temp.png',
    closeDate: null,
    headerColor: '#748499',
    inJourneys: [],
  },
  {
    id: 11,
    catalogId: 'courseb',
    name: 'Course 2',
    thumbnail: '/assets/brand/temp.png',
    closeDate: null,
    headerColor: '#748499',
    inJourneys: [],
  },
  {
    id: 12,
    catalogId: 'courseb',
    name: 'Course 2',
    thumbnail: '/assets/brand/temp.png',
    closeDate: null,
    headerColor: '#748499',
    inJourneys: [],
  },
  {
    id: 13,
    catalogId: 'courseb',
    name: 'Course 2',
    thumbnail: '/assets/brand/temp.png',
    closeDate: null,
    headerColor: '#748499',
    inJourneys: [],
  },
  {
    id: 14,
    catalogId: 'courseb',
    name: 'Course 2',
    thumbnail: '/assets/brand/temp.png',
    closeDate: null,
    headerColor: '#748499',
    inJourneys: [],
  },
  {
    id: 15,
    catalogId: 'courseb',
    name: 'Course 2',
    thumbnail: '/assets/brand/temp.png',
    closeDate: null,
    headerColor: '#748499',
    inJourneys: [],
  },
  {
    id: 16,
    catalogId: 'courseb',
    name: 'Course 2',
    thumbnail: '/assets/brand/temp.png',
    closeDate: null,
    headerColor: '#748499',
    inJourneys: [],
  },
];

const BasicEnrollmentMocks: MockDefinition[] = [
  {
    type: MockTypes.GET,
    url: '/users/current_learning.json?all=1',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: basicEnrollments,
    },
  },
];

export default BasicEnrollmentMocks;
