import deepFreeze, { DeepReadonly } from 'deep-freeze';
import t from 'react-translate';
import { ComponentTrueType, ComponentType, ExternalToolType, NLectureComponent, StyledLinkType } from 'redux/schemas/models/lecture-component';
import prodPathReplace from 'shared/prod-path-rewrite';
import { black, primary, gray6, gray4, yellow, white, gray3 } from 'styles/global_defaults/colors';
import _ from 'underscore';
import cloneDeep from 'lodash/cloneDeep';
import { ComponentRecord } from '.';

const defaultComponentData: DeepReadonly<Partial<ComponentRecord>> = deepFreeze({
  [ComponentType.HEADER_STYLE1]: {
    viewOptions: {
      backgroundColor: gray6,
      textColor: black, // TODO: Should this be $.FE.DEFAULTS.defaultTextColor?
    },
  },
  [ComponentType.HEADER_STYLE2]: {
    viewOptions: {
      leftBorderColor: primary,
      textColor: black, // TODO: Should this be $.FE.DEFAULTS.defaultTextColor?
    },
  },
  [ComponentType.HEADER_STYLE3]: {
    viewOptions: {
      backgroundColor: gray6,
      libraryIconColor: 'white',
      libraryIconName: 'images',
      pictureShape: 'square',
      pictureBackgroundColor: primary,
      textColor: black,
    },
  },
  [StyledLinkType.BUTTON]: {
    viewOptions: {
      styleType: 'button',
      libraryIconName: 'open-external',
      libraryIconColor: '#FFFFFF',
      buttonColor: primary,
    },
  },
  [StyledLinkType.CARD]: {
    viewOptions: {
      styleType: 'card',
      libraryIconName: 'admin-embeds',
      libraryIconColor: '#FFFFFF',
      libraryIconBackground: yellow,
    },
  },
  [ComponentType.TEXT_WITH_IMAGE_BKG]: {
    picture: { cdnUrl: prodPathReplace('images/content_template_sample.jpg') },
    viewOptions: {
      componentWidth: '100%',
      width: '80%',
      minHeight: '120px',
      textStyle: 'regular-text',
      textColor: '#ffffff',
      verticalAlign: 'middle',
      textAlign: 'center',
      backgroundColor: gray4,
    },
  },
  [ComponentType.TEXT_WITH_IMAGE_SIDE]: {
    picture: { cdnUrl: prodPathReplace('images/content_template_sample.jpg') },
    viewOptions: {
      componentWidth: '100%',
      picturePlacement: 'left',
      pictureWidth: '30%',
      pictureShape: 'circle',
      pictureAlt: 'Sample Image',
      verticalAlign: 'middle',
    },
  },
  [ComponentType.TEXT_WITH_IMAGE_TOP]: {
    picture: { cdnUrl: prodPathReplace('images/content_template_sample.jpg') },
    viewOptions: {
      componentWidth: '100%',
      pictureWidth: '40px',
      pictureShape: 'circle',
      pictureAlt: 'Sample Image',
    },
  },
});

/** Defines the LectureComponent property values used when rendering components
 * in the New Component Add menu. Note that many components are not rendered in that view.
 * Similar to getDefaultNewComponentData() in new-component-defaults.ts */
function getDefaultAddMenuComponentData(componentType: ComponentType, componentTrueType?: ComponentTrueType) {
  const trueType = componentTrueType ?? componentType;
  const component = {
    type: componentType,
    trueType,
    ...cloneDeep(defaultComponentData[trueType]),
  };

  switch (component.type) {
    case ComponentType.HEADER_STYLE1:
    case ComponentType.HEADER_STYLE2:
      component.content = t.FROALA.FONT.TITLE();
      break;
    case ComponentType.HEADER_STYLE3:
      component.content = t.FROALA.FONT.TITLE();
      break;
    case ComponentType.TEXT_WITH_IMAGE_BKG:
      component.content = t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.BLURB_BACKGROUND_IMAGE_SAMPLE_CONTENT();
      break;
    case ComponentType.TEXT_WITH_IMAGE_SIDE:
      component.content = t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.BLURB_SAMPLE_CONTENT();
      break;
    case ComponentType.TEXT_WITH_IMAGE_TOP:
      component.content = t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.BLURB_SAMPLE_CONTENT();
      break;
    default:
      break;
  }

  return component as NLectureComponent<typeof componentType>;
}

export default getDefaultAddMenuComponentData;
