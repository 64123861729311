/* @ngInject */
export default function NvTimedExamLimit(
  $parse,
) {
  return {
    require: 'ngModel',
    link(scope, elem, attrs, ngModel) {
      ngModel.$validators.custom = function (modelValue, viewValue) {
        const value = modelValue || viewValue;
        const value1 = parseInt(value, 10);
        let isValid = false;

        const maxVal = $parse(attrs.nvTimedExamLimit)(scope);

        if (ngModel.$isEmpty(value)) {
          isValid = true;
        } else {
          isValid = value < maxVal;
        }

        return isValid;
      };
    },
  };
}
