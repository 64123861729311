export default {
  bindings: {
    manager: '<',
    course: '<',
    selectTab: '&',
    completionCriteria: '<',
    hideViewAllButton: '<?',
  },
  controller: function ctrl(
    CurrentUserManager,
  ) {
'ngInject';
    const vm = this;
    vm.CurrentUserManager = CurrentUserManager;
    return vm;
  },
  controllerAs: 'vm',
  templateUrl: 'timelines/templates/nv-timeline-required-todos-for-completion.html',
};
