/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import t from 'react-translate';
import _ from 'underscore';

// Hooks
import useSessionStates from 'shared/hooks/use-session-states';

// Types
import { FroalaViewMode } from 'froala/helpers/nv-froala-constants';

// Styles
import { css } from '@emotion/react';
import { gray3 } from 'styles/global_defaults/colors';

// Components
import { LiveEventSession } from 'redux/schemas/models/live-event';
import NvFroala from 'froala/components/nv-froala';
import LiveSessionCard, { RecordingEvent, UploadEvents } from './live-session-card/live-session-card';

const dividerStyles = css`
  .line {
    height: 1px;
    flex-grow: 1;
    background: ${gray3};
  }
`;

const SessionsDivider = () => (
  <div css={dividerStyles} className='sessions-divider d-flex align-items-center mb-5 mt-5'>
    <div className='line' />
    <div className='text gray-3 text-regular ml-2 mr-2'>{t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.COMPLETED_SESSIONS()}</div>
    <div className='line' />
  </div>
);

type MultipleLiveSessionsProps = {
  sessions: LiveEventSession[]
  mainDescription?: string
  isEdit: boolean
  onSaveExpectations: (e: any) => void
  register: (sessionId: number) => void
  joinNow: (sessionId: number) => void
  unregister: (sessionId: number) => void
  uploadAttendeeList: (sessionId: number) => void
  uploadRecording: (sessionId: number, events: UploadEvents) => void
  editRecording: (sessionId: number, file: any, onSuccess: (recording: any) => void) => void
  removeRecording: (sessionId: number, onSuccess: () => void) => void
  delete: (sessionId: number, onSuccess: (sessionId) => void) => void
  onSuccessDeletion: (sessionId: number) => void
  recordingEventCallback: (
    event: RecordingEvent,
    second: number,
    totalLength: number,
    lecturePageId: number,
    lectureComponentId: number,
    sessionId: number,
  ) => void
};

const MultipleLiveSessions = (props: MultipleLiveSessionsProps) => {
  const {
    sessions,
    mainDescription,
    isEdit,
    onSaveExpectations,
    register,
    joinNow,
    unregister,
    uploadAttendeeList,
    uploadRecording,
    editRecording,
    removeRecording,
    delete: deleteSession,
    onSuccessDeletion,
    recordingEventCallback,
  } = props;

  const [mainDescriptionText, setMainDescriptionText] = React.useState(mainDescription);

  const sessionsCompleted = [];
  const sessionsNotCompleted = [];

  const LiveSession = ({ session }: { session: LiveEventSession }) => (
    <LiveSessionCard
      key={session.id}
      session={session}
      isEdit={isEdit}
      register={() => register(session.id)}
      joinNow={() => joinNow(session.id)}
      unregister={() => unregister(session.id)}
      uploadAttendeeList={() => uploadAttendeeList(session.id)}
      uploadRecording={uploadRecording}
      editRecording={editRecording}
      removeRecording={removeRecording}
      delete={() => deleteSession(session.id, onSuccessDeletion)}
      allowUserRegistration={false}
      recordingEventCallback={recordingEventCallback}
    />
  );

  _.each(sessions, (session) => {
    const { startTime, duration, timeZone: timezone } = session;
    const { ended } = useSessionStates({ startTime, duration, timezone });
    if (ended && !isEdit) sessionsCompleted.push(session);
    else sessionsNotCompleted.push(session);
  });

  return (
    <React.Fragment>
      {isEdit ? (
        <NvFroala
          preset={FroalaViewMode.AIR}
          onBlur={() => onSaveExpectations(mainDescriptionText)}
          placeholder={t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.ADD_EXPECTATIONS()}
          onChange={text => setMainDescriptionText(text)}
          value={mainDescriptionText}
        />
      ) : (
        <div
          className='exercise-description'
          dangerouslySetInnerHTML={{
            __html: mainDescriptionText,
          }}
        />
      )}

      {/* Not completed sessions */}
      {sessionsNotCompleted.map((session: LiveEventSession) => <LiveSession session={session} />)}

      {/* Ended sessions */}
      {sessionsCompleted.length > 0 && <SessionsDivider />}
      {sessionsCompleted.map((session: LiveEventSession) => <LiveSession session={session} />)}
    </React.Fragment>
  );
};

export default MultipleLiveSessions;
