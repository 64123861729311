import { css } from '@emotion/react';
import React, { useContext, useState, useCallback, useEffect } from 'react';
import omit from 'lodash/omit';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { each, some, values } from 'underscore';
import { Controller, useFormContext } from 'react-hook-form';

// Schemas
import { Recipient, CommunicationCategory } from 'redux/schemas/models/course-communication';
import { CourseAliases } from 'redux/schemas/models/course';

// Helpers
import { i18nJoin, JoinMode } from 'shared/services/i18n-utils';

// Contexts
import { CommunicationDispatch, TriggerType } from 'communications/course_communications/contexts/communication-context';

// Selectors
import { getCourseAliases } from 'redux/selectors/course';
import { getPrefixedTitle } from 'redux/selectors/activity';

// Components
import NvDropdown, { NvDropdownCheckbox, NvDropdownRadio, NvDropdownButtonStyle } from 'shared/components/inputs/nv-dropdown';
import NvIcon from 'shared/components/nv-icon';
import DuplicateActivity from 'communications/course_communications/components/communication-modal/duplicate-activity';

// Styles
import { textLargeFontSize } from 'styles/global_defaults/fonts';
import { quarterSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { primary } from 'styles/global_defaults/colors';

type SendToProps = {
  title?: (any) => string,
};

const styles = (isEditing: boolean, triggerType: TriggerType) => css`
  > .title-with-activity {
    font-size: ${textLargeFontSize}px;

    b {
      margin-left: ${quarterSpacing}px;
      ${triggerType === TriggerType.DUPLICATE
    ? css`
        cursor: pointer;
        color: ${primary};`
    : css`
        cursor: default;
        color: black;
      `}
    }
  }

  > .title {
    font-size: ${textLargeFontSize}px;
  }

  .edit-icon {
    color: ${primary};
    cursor: pointer;

    .icon {
      display: inline;
    }
  }

  .activity-wrapper {
    display: ${isEditing ? 'flex' : 'none'};
    align-items: center;

    .dropdowns {
      display: flex;
      height: auto;
      flex: 4;

      .activity-type {
        flex: 1;
      }

      .activity {
        margin-left: ${halfSpacing}px;
        flex: 2;
      }
    }
  }
`;

export const orderedRecipients = (recipients: Recipient[]): Recipient[] => (
  // Returned array will have an order of Recipient enum
  values(Recipient).filter(item => recipients.includes(item))
);

const scheduledAnnouncementRecipients = [Recipient.LEARNERS, Recipient.MENTORS, Recipient.COURSE_ADMINS];

/**
 * Returns the recipient label
 * eg: Mentors
 */
export const getRecipientLabel = (recipient: Recipient, aliases: CourseAliases) => {
  // In order to avoid problems when aliases are not comming from BE, we check if they exist. Otherwise we set them as undefined.
  const recipientLabel = {
    [Recipient.TRIGGER_LEARNER]: aliases.learnersAliases ? t.COURSE_COMMUNICATIONS.RECIPIENTS.DEFAULT.TRIGGER_LEARNER(aliases.learnersAliases) : undefined,
    [Recipient.LEARNERS]: aliases.learnersAliases ? t.COURSE_COMMUNICATIONS.RECIPIENTS.DEFAULT.LEARNERS(aliases.learnersAliases) : undefined,
    [Recipient.COURSE_ADMINS]: aliases.courseAliases ? t.COURSE_COMMUNICATIONS.RECIPIENTS.DEFAULT.COURSE_ADMINS(aliases.courseAliases) : undefined,
    [Recipient.MENTORS]: aliases.courseAliases ? t.COURSE_COMMUNICATIONS.RECIPIENTS.DEFAULT.MENTORS(aliases.courseAliases) : undefined,
    [Recipient.GROUP_ADMINS]: aliases.groupAliases ? t.COURSE_COMMUNICATIONS.RECIPIENTS.DEFAULT.GROUP_ADMINS(aliases.groupAliases) : undefined,
    [Recipient.TEAM_LEADS]: aliases.teamAliases ? t.COURSE_COMMUNICATIONS.RECIPIENTS.DEFAULT.TEAM_LEADS(aliases.teamAliases) : undefined,
  };

  return recipientLabel[recipient];
};

/**
 * Returns the i18n joined recipients label
 * eg: Mentors and Course Admins
 */
export const getRecipientsLabel = (recipients: Recipient[], aliases: CourseAliases, joinMode: JoinMode = JoinMode.AND) => {
  const recipientLabels: string[] = [];
  // If the prefix is needed, prefix the `All`, for the first one
  each(orderedRecipients(recipients), (recipient, index) => recipientLabels.push(
    getRecipientLabel(
      recipient,
      aliases,
    ),
  ));

  return i18nJoin(recipientLabels, joinMode);
};

type GetTitleProps = {
  title: string,
  showIcon?: boolean,
};

const GetTitle = ({
  title,
  showIcon,
}: GetTitleProps) => {
  const titleStyles = () => css`
    &.custom-target {
      display: flex;
      align-items: center;
      & > * {
        display: inline-block;
      }

      .icon {
        display: ${showIcon ? 'flex' : 'none'};
        align-items: center;
      }
      .title{
        ${showIcon && `color: ${primary};`}
      }
    }
  `;

  return (
    <div className='custom-target' css={titleStyles}>
      <span className='page-title-small pr-2'>
        {t.COURSE_COMMUNICATIONS.SCHEDULED_EMAIL.TO()}
      </span>
      <div className='title text-large-regular bold'>
        {title}
      </div>
      <div className='icon text-xs icon-dropdown-arrow text-primary ml-2' />
    </div>
  );
};

const SendTo = (props: SendToProps) => {
  const { State } = useContext(CommunicationDispatch);
  const aliases = useSelector((state) => getCourseAliases(state));

  const [isEditing, setEditing] = useState(false);

  const { control, watch, setValue } = useFormContext();

  const activityTitle = useSelector((state) => getPrefixedTitle(
    state,
    State.activityType,
    State.activityId,
    aliases,
  ));
  const recipients = watch('recipients') ?? [];

  const checkBoxItems: NvDropdownCheckbox[] = [
    {
      label: t.COURSE_COMMUNICATIONS.RECIPIENTS.ALL.LEARNERS(aliases.learnersAliases),
      name: Recipient.LEARNERS,
      id: Recipient.LEARNERS,
      type: 'checkbox',
      checked: recipients.includes(Recipient.LEARNERS),
      labelClass: 'text-large-regular',
      onChanged: (isChecked) => onSelect('checkbox', isChecked, Recipient.LEARNERS),
    },
    {
      label: t.COURSE_COMMUNICATIONS.RECIPIENTS.ALL.MENTORS(aliases.courseAliases),
      name: Recipient.MENTORS,
      id: Recipient.MENTORS,
      type: 'checkbox',
      checked: recipients.includes(Recipient.MENTORS),
      labelClass: 'text-large-regular',
      onChanged: (isChecked) => onSelect('checkbox', isChecked, Recipient.MENTORS),
    },
    {
      label: t.COURSE_COMMUNICATIONS.RECIPIENTS.ALL.COURSE_ADMINS(aliases.courseAliases),
      name: Recipient.COURSE_ADMINS,
      id: Recipient.COURSE_ADMINS,
      type: 'checkbox',
      checked: recipients.includes(Recipient.COURSE_ADMINS),
      labelClass: 'text-large-regular',
      onChanged: (isChecked) => onSelect('checkbox', isChecked, Recipient.COURSE_ADMINS),
    },
  ];

  const radioItems: NvDropdownRadio[] = [
    {
      label: t.COURSE_COMMUNICATIONS.RECIPIENTS.ALL.TEAM_LEADS(aliases.teamAliases),
      type: 'radio',
      group: 'sendto',
      id: Recipient.TEAM_LEADS,
      isChecked: recipients.includes(Recipient.TEAM_LEADS),
      class: 'mt-3 ml-2',
      labelClass: 'text-large-regular',
      onChanged: (isChecked) => onSelect('radio', isChecked, Recipient.TEAM_LEADS),
    },
    {
      label: t.COURSE_COMMUNICATIONS.RECIPIENTS.ALL.GROUP_ADMINS(aliases.groupAliases),
      type: 'radio',
      group: 'sendto',
      id: Recipient.GROUP_ADMINS,
      isChecked: recipients.includes(Recipient.GROUP_ADMINS),
      class: 'mt-4 ml-2',
      labelClass: 'text-large-regular',
      onChanged: (isChecked) => onSelect('radio', isChecked, Recipient.GROUP_ADMINS),
    },
  ];

  let defaultLastCheckedType: string;
  if (recipients.length > 0) {
    // Check the first one
    if (some(checkBoxItems, (item) => item.id === recipients[0] && item.checked)) {
      defaultLastCheckedType = 'checkbox';
    }
    if (some(radioItems, (item) => item.id === recipients[0] && item.isChecked)) {
      defaultLastCheckedType = 'radio';
    }
  }
  const [lastCheckedType, setLastCheckedType] = useState(defaultLastCheckedType);

  const onSelect = (type: string, isChecked: boolean, recipient: Recipient) => {
    let selectedRecipients = [];

    if (type === 'radio' && isChecked) {
      // Just reset recipients
      selectedRecipients = [recipient];
    }

    if (type === 'checkbox') {
      if (isChecked) {
        if (lastCheckedType === 'checkbox') {
          // Push to recipients
          selectedRecipients = [...recipients, recipient];
        } else {
          // If its checked and last checked is radio, reset it
          selectedRecipients = [recipient];
        }
      } else {
        // Remove the unchecked item
        selectedRecipients = recipients.filter(item => item !== recipient);
      }
    }

    setValue('recipients', orderedRecipients(selectedRecipients), { shouldValidate: true, shouldDirty: true });
    setLastCheckedType(type);
  };

  /** Manually updating form with default value, as the recipients getting undefined
   * while rendering this component for triggers.
   */
  useEffect(() => {
    if (State.communicationCategory === CommunicationCategory.TRIGGERS) {
      setValue('recipients', [Recipient.TRIGGER_LEARNER]);
    }
  }, []);

  const sendToTitle = getRecipientsLabel(recipients, aliases);
  const customTarget = useCallback(() => (
    <GetTitle title={sendToTitle} showIcon />
  ), [sendToTitle]);
  const getRecipientsBody = () => {
    switch (State.communicationCategory) {
      case CommunicationCategory.SCHEDULED_EMAIL:
        return (
          <Controller
            name='recipients'
            control={control}
            render={({ field }) => (
              <NvDropdown
                showSelectedIndicator
                items={[...checkBoxItems, { type: 'divider' }, ...radioItems]}
                buttonStyle={NvDropdownButtonStyle.CUSTOM}
                customTarget={customTarget}
                {...omit(field, ['ref'])}
              />
            )}
          />
        );
      case CommunicationCategory.SCHEDULED_ANNOUNCEMENT:
        return (
          <GetTitle title={getRecipientsLabel(scheduledAnnouncementRecipients, aliases)} />
        );
      default:
        return (
          <React.Fragment>
            { !isEditing && (
              <div className='title-with-activity'>
                <span>{props.title(aliases.learnersAliases)}</span>
                { State.triggerType === TriggerType.DUPLICATE && !isEditing ? (
                  <span onClick={() => setEditing(true)}>
                    <b>{activityTitle}</b>
                    <span className='ml-2 edit-icon'>
                      <NvIcon size='xss-smallest' icon='edit' />
                    </span>
                  </span>
                ) : (
                  <b>{activityTitle}</b>
                )}
              </div>
            )}
            { isEditing && (
              <div className='title'>{props.title(aliases.learnersAliases)}</div>
            )}
            <DuplicateActivity isDisplayed={isEditing} onClose={() => setEditing(false)} />
          </React.Fragment>
        );
    }
  };

  return (
    <div css={styles(isEditing, State.triggerType)} className='my-5'>
      {getRecipientsBody()}
    </div>
  );
};

export default SendTo;
