import { css } from '@emotion/react';

import { useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import t from 'react-translate';

// schemas
import { ItemState, Communication, CommunicationErrorCode, CommunicationType, CommunicationCategory } from 'redux/schemas/models/course-communication';
import { RootState } from 'redux/schemas';

// actions
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { deleteCommunication } from 'redux/actions/course-communications';

// contexts
import { TimelineLecturePageContext } from 'timelines/components/timeline-lecture-page';
import { AngularServicesContext } from 'react-app';
import { CommunicationDispatch, CommunicationAction, TriggerType, getCategory } from 'communications/course_communications/contexts/communication-context';

// selectors
import { getItemState, getCommunication } from 'redux/selectors/course-communications';
import { getCourseAliases } from 'redux/selectors/course';

// components
import NvIcon from 'shared/components/nv-icon';
import { NvDropdown, NvDropdownAlign, NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { NvTooltip } from 'shared/components/nv-tooltip';

// styles
import { primary } from 'styles/global_defaults/colors';

// Target area is larger than than the small icon size
const TARGET_AREA_PADDING = 20;

type CommunicationActionsDropdownProps = {
  communicationId: number,
};

type StateProps = {
  itemState: ItemState,
  communication: Communication,
};

const mapDispatchToProps = {
  openConfirmationDialog,
  deleteCommunication,
};

const isEditDuplicateDisabled = (errorCodes: CommunicationErrorCode[]) => !!errorCodes?.includes(CommunicationErrorCode.ACTIVITY_DUE_DATE_REMOVED)
  || !!errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_FEEDBACK_COMPLETION)
  || !!errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_THIRD_PARTY_COMPLETION)
  || !!errorCodes?.includes(CommunicationErrorCode.MISSING_PROFILE_COMPLETION_REQUIRED_QUESTIONS);

const isEditDisabled = (itemState: string, communicationType: CommunicationType) => (
  itemState === ItemState.SENT && getCategory(communicationType) !== CommunicationCategory.SCHEDULED_ANNOUNCEMENT
);

const styles = css`
  position: absolute;
  top: 0;
  right: 0;
  color: ${primary};
  cursor: pointer;
  font-style: normal;

  .options-btn {
    /* TODO : Don't have the increased target area at the top of the icon */
    /* to fix the tooltip flickering issue on hover. Fix this gracefully. */
    padding: 0 ${TARGET_AREA_PADDING}px ${TARGET_AREA_PADDING}px;
    margin-top: ${TARGET_AREA_PADDING}px;
  }
`;

const CommunicationActionsDropdown = (props: CommunicationActionsDropdownProps & StateProps & typeof mapDispatchToProps) => {
  const { $state } = useContext(AngularServicesContext);
  const lecturePageContext = useContext(TimelineLecturePageContext);
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const { dispatch } = useContext(CommunicationDispatch);
  const aliases = useSelector((state) => getCourseAliases(state));

  const tooltipEditText = isEditDuplicateDisabled(props.communication.errorCodes)
    ? t.COURSE_COMMUNICATIONS.TOOLTIP.ERROR_STATE_DISABLED_EDIT()
    : t.COURSE_COMMUNICATIONS.TOOLTIP.DISABLED_EDIT();
  const isAnnouncement = getCategory(props.communication.communicationType) === CommunicationCategory.SCHEDULED_ANNOUNCEMENT;
  const editOptionKey = isAnnouncement
    && props.itemState === ItemState.SENT
    && props.communication.communication.hasEmail
    ? 'EDIT_ANNOUNCEMENT_ONLY' : 'EDIT';

  const getDropdownItems = (itemState: ItemState, errorCodes: CommunicationErrorCode[]): NvDropdownOption[] => [
    {
      type: 'text',
      text: t.COURSE_COMMUNICATIONS.ACTIONS_DROPDOWN[editOptionKey](),
      disabled: isEditDisabled(itemState, props.communication.communicationType) || isEditDuplicateDisabled(errorCodes),
      tooltip: {
        text: tooltipEditText,
        enabled: isEditDisabled(itemState, props.communication.communicationType) || isEditDuplicateDisabled(errorCodes),
        placement: 'top-end',
      },
      callback: () => dispatch({
        type: CommunicationAction.SHOW_MODAL,
        triggerType: TriggerType.EDIT,
        communicationType: props.communication.communicationType,
        activityType: props.communication.ownerType,
        activityId: props.communication.ownerId,
        communicationId: props.communicationId,
        lecturePageId: lecturePageContext?.lecturePageId,
      }),
    },
    {
      type: 'text',
      text: t.COURSE_COMMUNICATIONS.ACTIONS_DROPDOWN.VIEW(),
      callback: () => dispatch({
        type: CommunicationAction.SHOW_MODAL,
        triggerType: TriggerType.VIEW,
        communicationType: props.communication.communicationType,
        communicationId: props.communicationId,
      }),
    },
    {
      type: 'text',
      text: t.COURSE_COMMUNICATIONS.ACTIONS_DROPDOWN.DUPLICATE(),
      disabled: isEditDuplicateDisabled(errorCodes),
      tooltip: {
        text: t.COURSE_COMMUNICATIONS.TOOLTIP.DUE_DATE_MISSING_DISABLED_DUPLICATE(),
        enabled: isEditDuplicateDisabled(errorCodes),
        placement: 'top-end',
      },
      callback: () => dispatch({
        type: CommunicationAction.SHOW_MODAL,
        triggerType: TriggerType.DUPLICATE,
        communicationType: props.communication.communicationType,
        activityType: props.communication.ownerType,
        activityId: props.communication.ownerId,
        communicationId: props.communicationId,
        lecturePageId: lecturePageContext?.lecturePageId,
      }),
    },
    { type: 'divider' },
    {
      type: 'text',
      text: t.COURSE_COMMUNICATIONS.ACTIONS_DROPDOWN.DELETE(),
      class: 'text-danger',
      callback: () => confirmDelete(),
    },
  ];

  const confirmDelete = () => {
    let announcementKey;
    if (isAnnouncement) {
      if (props.itemState === ItemState.SENT) {
        announcementKey = props.communication?.communication?.hasEmail
          ? 'EMAIL_ALREADY_SENT' : 'ANNOUNCEMENT_ALREADY_POSTED';
      } else {
        announcementKey = 'SCHEDULED_OR_EMAIL_ONLY';
      }
    }

    const titleKey = isAnnouncement ? 'ANNOUNCEMENT' : 'DEFAULT';
    const bodyText = announcementKey
      ? t.COURSE_COMMUNICATIONS.DELETION.WARNING.ANNOUNCEMENT[announcementKey](aliases.courseAliases)
      : t.COURSE_COMMUNICATIONS.DELETION.WARNING.DEFAULT();

    const confirmText = props.itemState === ItemState.SENT
      && props.communication?.communication?.hasEmail
      ? t.COURSE_COMMUNICATIONS.DELETION.DELETE_ANNOUNCEMENT_ONLY()
      : t.FORM.DELETE();

    props.openConfirmationDialog({
      title: t.COURSE_COMMUNICATIONS.DELETION.TITLE[titleKey](),
      bodyText,
      confirmText,
      cancelText: t.FORM.CANCEL(),
      onConfirm: () => props.deleteCommunication({
        catalogId,
        communicationId: props.communicationId,
        lecturePageId: lecturePageContext?.lecturePageId,
        communicationType: props.communication.communicationType,
      }),
    });
  };

  return (
    <a
      css={styles}
      // To stop propagating the click to view communication
      onClick={(e) => e.stopPropagation()}
    >
      <NvDropdown
        buttonStyle={NvDropdownButtonStyle.CUSTOM}
        items={getDropdownItems(props.itemState, props.communication.errorCodes)}
        align={NvDropdownAlign.RIGHT}
        offset={-TARGET_AREA_PADDING}
        customTarget={() => (
          <NvTooltip text={t.COURSE_COMMUNICATIONS.TOOLTIP.ACTIONS()} placement='top'>
            <div className='options-btn'>
              <NvIcon size='xss-smallest' icon='edit' />
            </div>
          </NvTooltip>
        )}
      />
    </a>
  );
};


const ConnectedCommunicationActionsDropdown = connect<StateProps, typeof mapDispatchToProps>(
  (state: RootState, ownProps: CommunicationActionsDropdownProps) => ({
    communication: getCommunication(state, ownProps.communicationId),
    itemState: getItemState(state, ownProps.communicationId),
  }),
  mapDispatchToProps as any,
)(CommunicationActionsDropdown);

export default ConnectedCommunicationActionsDropdown;
