
import {
  useContext,
} from 'react';
import moment from 'moment';
import * as yup from 'yup';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import parse from "date-fns/parse";

// Contexts
import { CommunicationDispatch } from 'communications/course_communications/contexts/communication-context';

// Schemas
import { ScheduledForType, CommunicationType } from 'redux/schemas/models/course-communication';

// Selectors
import { getCourseAliases, getCourseTotalPoints, isGamificationEnabled } from 'redux/selectors/course';
import { getActivity } from 'redux/selectors/course-communications';
import { getPrefixedTitle } from 'redux/selectors/activity';

// Components
import FilterByRoles from 'communications/course_communications/components/communication-modal/filters/filter-by-role';
import LoggedIn from 'communications/course_communications/components/communication-modal/filters/logged-in';
import CompletedCourse from 'communications/course_communications/components/communication-modal/filters/completed-course';
import NumberInput from 'communications/course_communications/components/communication-modal/filters/number-input';
import SendOn from 'communications/course_communications/components/communication-modal/send_on/send-on';
import SendTo from 'communications/course_communications/components/communication-modal/send-to';
import Activity from 'communications/course_communications/components/communication-modal/filters/activity';
import CommunicationForm, { formDropdownSchema } from './communication-form';

const ScheduledEmail = () => {
  const { State } = useContext(CommunicationDispatch);
  const aliases = useSelector((state) => getCourseAliases(state));
  const activity: any = useSelector((state) => getActivity(state, State.activityType, State.activityId));
  const prefixedTitle = useSelector((state) => getPrefixedTitle(
    state,
    State.activityType,
    State.activityId,
    aliases,
  ));
  const totalPoints = useSelector((state) => getCourseTotalPoints(state));
  const gamificationEnabled = useSelector((state) => isGamificationEnabled(state));

  const validationSchema = yup.object().shape({
    scheduledFor: yup.string().nullable(),
    xDays: yup.number().nullable()
      .when('scheduledFor', {
        is: ScheduledForType.AFTER_DATE,
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      })
      .when('scheduledFor', {
        is: ScheduledForType.BEFORE_DATE,
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      }),
    scheduledOn: yup.date().nullable()
      .when('scheduledFor', {
        is: ScheduledForType.SCHEDULED,
        then: yup.date()
          .transform(function (value, originalValue) {
            if (originalValue?._isAMomentObject) {
              return originalValue.toDate();
            }
            return value;
          })
          .typeError(t.VALIDATION.DATE())
          .required(t.VALIDATION.REQUIRED())
          .test(
            'isFutureTime',
            t.VALIDATION.SELECT_FUTURE_TIME(),
            (scheduledOn) => moment(scheduledOn).isAfter(moment()),
          ),
      }),
    filterByRoles: yup.boolean(),
    enrolledLastDaysChecked: yup.boolean(),
    hasLoggedInChecked: yup.boolean(),
    daysActiveChecked: yup.boolean(),
    daysInactiveChecked: yup.boolean(),
    notCompletedActivityChecked: yup.boolean(),
    completedActivityChecked: yup.boolean(),
    earnedMoreThanXPointsChecked: yup.boolean(),
    earnedLessThanXPointsChecked: yup.boolean(),
    hasCompletedCourseChecked: yup.boolean(),
    recipients: yup.array().required().min(1),
    courseRolesList: yup.array().of(yup.string())
      .when('filterByRoles', {
        is: true,
        then: yup.array().of(yup.string()).required().min(1),
      }),
    enrolledInLastXDays: yup.number().nullable()
      .when('enrolledLastDaysChecked', {
        is: true,
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      }),
    hasLoggedIn: yup.object().nullable()
      .when('hasLoggedInChecked', {
        is: true,
        then: formDropdownSchema.required(t.VALIDATION.REQUIRED()),
      }),
    loggedInDropdown: yup.array().of(yup.string())
      .when('loggedIn', {
        is: true,
        then: yup.array().of(yup.string()).required().min(1),
      }),
    daysActive: yup.number().nullable()
      .when('daysActiveChecked', {
        is: true,
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      }),
    daysInactive: yup.number().nullable()
      .when('daysInactiveChecked', {
        is: true,
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      }),
    notCompletedType: yup.object().nullable()
      .when('notCompletedActivityChecked', {
        is: true,
        then: formDropdownSchema.required(t.VALIDATION.REQUIRED()),
      }),
    notCompletedActivity: yup.object().nullable()
      .when('notCompletedActivityChecked', {
        is: true,
        then: formDropdownSchema.required(t.VALIDATION.REQUIRED()),
      }),
    notCompletedCategory: yup.string(),
    completedType: yup.object().nullable()
      .when('completedActivityChecked', {
        is: true,
        then: formDropdownSchema.required(t.VALIDATION.REQUIRED()),
      }),
    completedActivity: yup.object().nullable()
      .when('completedActivityChecked', {
        is: true,
        then: formDropdownSchema.required(t.VALIDATION.REQUIRED()),
      }),
    completedCategory: yup.string(),
    earnedMoreThanXPoints: yup.number().nullable()
      .when('earnedMoreThanXPointsChecked', {
        is: true,
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      }),
    earnedLessThanXPoints: yup.number().nullable()
      .when('earnedLessThanXPointsChecked', {
        is: true,
        then: yup.number()
          .typeError(t.VALIDATION.NUMBER())
          .required(t.VALIDATION.REQUIRED())
          .min(1, t.VALIDATION.MIN('1')),
      }),
    hasCompletedCourse: yup.object().nullable()
      .when('hasCompletedCourseChecked', {
        is: true,
        then: yup.object().required(t.VALIDATION.REQUIRED()),
      }),
  });

  let sendOnDetailsText: string;
  let referenceDate: string;
  if (State.communicationType === CommunicationType.DUE_DATE_EMAIL) {
    referenceDate = activity.dueDate;
    sendOnDetailsText = t.COURSE_COMMUNICATIONS.SEND_ON.DUE_DATE.LABEL(moment(activity.dueDate).format('LLL'), prefixedTitle);
  } else if (State.communicationType === CommunicationType.RELEASE_DATE_EMAIL) {
    referenceDate = activity.releaseDate;
    sendOnDetailsText = t.COURSE_COMMUNICATIONS.SEND_ON.RELEASE_DATE.LABEL(moment(activity.releaseDate).format('LLL'), activity.title);
  }

  return (
    <CommunicationForm validationSchema={validationSchema}>
      <div className='modal-body-title page-title text-center pb-4 mt-2'>
        {t.COURSE_COMMUNICATIONS.SCHEDULED.TITLE.STEP_1()}
      </div>
      <SendTo />
      <SendOn
        details={sendOnDetailsText}
        referenceDate={referenceDate}
      />
      <div className='bold text-large-regular mt-5'>
        <div className='text-warning inline-block'>{t.COURSE_COMMUNICATIONS.FILTERS.OPTIONAL()}</div>
        <div className='ml-2 inline-block'>{t.COURSE_COMMUNICATIONS.FILTERS.ADD_MORE(aliases.learnersAliases)}</div>
      </div>
      <div className='text-medium bold my-1'>{t.COURSE_COMMUNICATIONS.FILTERS.SCHEDULED_DESCRIPTION()}</div>
      <div className='text-medium gray-3 mt-4 mb-1'>{t.COURSE_COMMUNICATIONS.FILTERS.MULTIPLE_SELECTIONS()}</div>

      {/* Filter by roles */}
      <FilterByRoles />

      {/* Enrolled in last X days */}
      <NumberInput
        checkboxName='enrolledLastDaysChecked'
        name='enrolledInLastXDays'
        defaultLabel={t.COURSE_COMMUNICATIONS.FILTERS.ENROLLED_LAST_DAYS.DEFAULT()}
        inputLabel={t.COURSE_COMMUNICATIONS.FILTERS.ENROLLED_LAST_DAYS.LABEL('INPUT')}
      />

      {/* Has logged-in/not-logged-in */}
      <LoggedIn />

      {/* Has been active at least once in the last x days */}
      <NumberInput
        checkboxName='daysActiveChecked'
        name='daysActive'
        defaultLabel={t.COURSE_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.ACTIVE.DEFAULT()}
        inputLabel={t.COURSE_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.ACTIVE.LABEL('INPUT')}
        ariaLabel={t.COURSE_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.ACTIVE.ARIA_LABEL()}
        disableOn={['daysInactiveChecked']}
      />

      {/* Has been inactive for more than X days */}
      <NumberInput
        checkboxName='daysInactiveChecked'
        name='daysInactive'
        defaultLabel={t.COURSE_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.INACTIVE.DEFAULT()}
        inputLabel={t.COURSE_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.INACTIVE.LABEL('INPUT')}
        ariaLabel={t.COURSE_COMMUNICATIONS.FILTERS.ACTIVE_DAYS.INACTIVE.ARIA_LABEL()}
        disableOn={['daysActiveChecked']}
      />

      {/* Has NOT completed a specific activity */}
      <Activity
        name='notCompleted'
        dependentName='completed'
        label={t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.HAS_NOT()}
      />

      {/* Has completed a specific activity */}
      <Activity
        name='completed'
        dependentName='notCompleted'
        label={t.COURSE_COMMUNICATIONS.FILTERS.COMPLETED_ACTIVITY.HAS()}
      />

      {/* Has earned more than X points */}
      <NumberInput
        checkboxName='earnedMoreThanXPointsChecked'
        name='earnedMoreThanXPoints'
        defaultLabel={t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.MORE_THAN.DEFAULT(aliases.pointsAliases)}
        inputLabel={t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.MORE_THAN.LABEL({ input: 'INPUT', ...aliases.pointsAliases })}
        inputWidth={100}
        ariaLabel={t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.MORE_THAN.ARIA_LABEL(aliases.pointsAliases)}
        info={!gamificationEnabled
          ? t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.NOT_ENABLED(aliases.pointsAliases)
          : t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.TOTAL({ count: totalPoints, ...aliases.pointsAliases })}
      />

      {/* Has earned less than X points */}
      <NumberInput
        checkboxName='earnedLessThanXPointsChecked'
        name='earnedLessThanXPoints'
        defaultLabel={t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.LESS_THAN.DEFAULT(aliases.pointsAliases)}
        inputLabel={t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.LESS_THAN.LABEL({ input: 'INPUT', ...aliases.pointsAliases })}
        inputWidth={100}
        ariaLabel={t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.LESS_THAN.ARIA_LABEL(aliases.pointsAliases)}
        info={!gamificationEnabled
          ? t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.NOT_ENABLED(aliases.pointsAliases)
          : t.COURSE_COMMUNICATIONS.FILTERS.EARNED_POINTS.TOTAL({ count: totalPoints, ...aliases.pointsAliases })}
      />

      <CompletedCourse />
    </CommunicationForm>
  );
};

export default ScheduledEmail;
