import React, { useContext, useEffect, useState } from 'react';
import t from 'react-translate';
import { AngularContext } from 'react-app';
import { Button } from 'react-bootstrap';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { disableEdCastIntegration } from 'redux/actions/edcast';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { EdCastPayload } from 'redux/schemas/models/edcast';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import EdcastSettings from 'institutions/components/edcast-settings';
import { config } from '../../../../../config/pendo.config.json';
import SectionContent from '../section-content';

const EdCastIntegration = () => {
  const { injectServices, $scope } = useContext(AngularContext);
  const [InstitutionsManager] = injectServices(['InstitutionsManager']);
  const [hasEnabled, setHasEnabled] = useState($scope.$eval('vm.InstitutionsManager.institution.edcastIntegrationEnabled'));
  const [showConfigModal, setShowConfigModal] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    $scope.$watch('vm.InstitutionsManager.institution.edcastIntegrationEnabled', (newValue) => {
      setHasEnabled(newValue);
    });
  }, [$scope]);

  const disableEdcast = () => {
    dispatch(openConfirmationDialog({
      title: t.INSTITUTIONS.ADVANCED_SETTINGS.EDCAST.DISABLE_EDCAST(),
      bodyText: t.INSTITUTIONS.ADVANCED_SETTINGS.EDCAST.DISABLE_DESCRIPTION(),
      confirmText: t.FORM.YES_SURE(),
      cancelDataQa: config.pendo.edcast.integrationCancelDisable,
      confirmDataQa: config.pendo.edcast.integrationConfirmDisable,
      onConfirm: () => {
        const edcastConfig = {
          lxpCredentials: {},
          devCredentials: {},
        };

        wrapThunkAction(
          dispatch(
            disableEdCastIntegration({
              institutionId: InstitutionsManager.institution.id,
              ...edcastConfig,
            } as EdCastPayload),
          ),
        ).then((response) => {
          InstitutionsManager.institution.updateFromReact({
            ...(response.payload as any).institutionList,
          });
        }).catch((e) => {
          dispatch(
            addAlertMessage({
              type: AlertMessageType.ERROR,
              message: t.FORM.ERROR_SOMETHING_WRONG(),
            }),
          );
        });
      },
    }));
  };

  return (
    <React.Fragment>
      <SectionContent
        header={t.INSTITUTIONS.ADVANCED_SETTINGS.EDCAST.TITLE()}
        description={[t.INSTITUTIONS.ADVANCED_SETTINGS.EDCAST.DESCRIPTION()]}
        showCTAButton={!hasEnabled}
        ctaText={t.INSTITUTIONS.ADVANCED_SETTINGS.CONFIGURE()}
        onAction={() => setShowConfigModal(true)}
        dataQa={config.pendo.edcast.integrationConfigure}
        tooltipEnabled={!InstitutionsManager.institution.allowEdcastIntegration}
        disabled={!InstitutionsManager.institution.allowEdcastIntegration}
        tooltipText={t.INSTITUTIONS.ADVANCED_SETTINGS.EDCAST.CONFIGURE_TOOLTIP()}
        extras={hasEnabled && (
        <div className='button-bar mt-6'>
          <Button
            variant='secondary'
            onClick={disableEdcast}
            data-qa={config.pendo.edcast.integrationDisable}
          >
            {t.INSTITUTIONS.ADVANCED_SETTINGS.EDCAST.DISABLE()}
          </Button>
          <Button
            variant='primary'
            onClick={() => setShowConfigModal(true)}
            data-qa={config.pendo.edcast.integrationEdit}
          >
            {t.INSTITUTIONS.ADVANCED_SETTINGS.EDCAST.EDIT()}
          </Button>
        </div>
        )}
      />
      <EdcastSettings show={showConfigModal} onClose={() => setShowConfigModal(false)} />
    </React.Fragment>
  );
};

export default EdCastIntegration;
