$.FE.DefineIconTemplate('froala_novoed_icon', '<i class="icon icon-smallest icon-[NAME]"></i>');

$.FE.ICON_DEFAULT_TEMPLATE = 'froala_novoed_icon';

$.FE.DefineIcon('bold', { NAME: 'format-bold' });
$.FE.DefineIcon('italic', { NAME: 'format-italic' });
$.FE.DefineIcon('underline', { NAME: 'format-underline' });

$.FE.DefineIcon('insertLink', { NAME: 'format-makelink' });
$.FE.DefineIcon('linkOpen', { NAME: 'open-external' });
$.FE.DefineIcon('linkEdit', { NAME: 'edit' });
$.FE.DefineIcon('linkRemove', { NAME: 'close' });

$.FE.DefineIcon('html', { NAME: 'html' });
