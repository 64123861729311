import { css } from '@emotion/react';
import { connect } from 'react-redux';
import { RootState, CombinedCoursesNormalized } from 'redux/schemas';
import { withRouter } from 'react-router-dom';
import { Institution, CourseTypeCounts, CourseCloning } from 'redux/schemas/models/institution';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { NvTab, NvResponsiveTabsDisplayType } from 'shared/components/nv-responsive-tabs';
import { standardSpacing, largeSpacing, quarterSpacing, createGridStyles, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import NvResponsiveTable, { NvResponsiveTableColumn, ColumnSortings, borderStyle } from 'shared/components/nv-responsive-table';
import t from 'react-translate';
import { Course, CoursesNormalized } from 'redux/schemas/models/course';
import {
  getCourseClonings,
  getCourseCounts,
  getSearchCourseCounts,
  getCoursesList,
  getMyFilterView,
  getMetadataFilters,
  downloadCoursesCSV,
  postMyFilterView,
  updateMyFilterView,
  setVisitedOrgAdminDashboard,
  setCourseHighlight,
} from 'redux/actions/institutions';
import React, {
  FunctionComponent, useState, useEffect, useContext, useRef,
} from 'react';
import { Button } from 'react-bootstrap';
import {
  gray3,
  primary,
} from 'styles/global_defaults/colors';
import _ from 'underscore';
import { PagedDataQueryParams } from 'redux/create-action-creators';
import { NvTooltip } from 'shared/components/nv-tooltip';
import { NvExpandableSearchBar } from 'shared/components/nv-search-bar';
import { isHandheld, handheld } from 'styles/global_defaults/media-queries';
import { AngularContext, AngularServicesContext } from 'react-app';
import { NvNoResults } from 'shared/components/nv-no-results-panel';
import { NvResponsiveTabsRow } from 'shared/components/nv-responsive-tabs-row';
import NvIcon from 'shared/components/nv-icon';
import LoadingRow from 'shared/components/loading-row';
import { MetadataFieldType } from 'redux/schemas/models/org-level-metadata-settings';
import { useAppDispatch } from 'redux/store';
import { cloneDeep, mergeWith } from 'lodash';
import { replaceArrays } from 'shared/lodash-utils';
import PrimaryCohortsHeader from 'cohort_management/components/primary-cohorts-header';
import CourseRow, { CourseRowExtraProps } from './course-row';
import CourseDetailsRow from './course-details-row';
import InstitutionBanner from './institution-banner';
import ManageCourseAccessModal from './manage-course-access-modal';
import FilterFlyoutModal, { Filters, FilterType } from './filter-flyout-modal';
import { config } from '../../../config/pendo.config.json';
import CourseRowBackground from './course-row-background';

export const InstitutionDashboardContext = React.createContext(null);

type StateProps = {
  courses: CombinedCoursesNormalized,
  institution: Institution,
  courseCounts: CourseTypeCounts,
  countsLoaded: boolean,
  courseClonings: CourseCloning[],
  visitedOrgDashboard?: boolean,
  myViewData: any,
};

const actions = {
  getCourseCounts,
  getSearchCourseCounts,
  downloadCoursesCSV,
  getCourseClonings,
  postMyFilterView,
  updateMyFilterView,
  getMyFilterView,
  getMetadataFilters,
  setVisitedOrgAdminDashboard,
};

type OwnProps = {
};

export const createCourseTableColumns: () => NvResponsiveTableColumn[] = () => [
  {
    name: t.COURSES.DASHBOARD_TABLE.OFFERING_NAME(),
    className: 'name-cell',
    gridWidth: '40%',
  },
  {
    name: t.COURSES.DASHBOARD_TABLE.RELEASE_DATE(),
    className: 'release-date-cell',
    sortable: true,
    gridWidth: '10%',
    headerTooltip: t.COURSES.DASHBOARD_TABLE.RELEASE_DATE_TOOLTIP(),
  },
  {
    name: t.COURSES.DASHBOARD_TABLE.CLOSE_DATE(),
    className: 'close-date-cell',
    sortable: true,
    gridWidth: '10%',
  },
  {
    name: t.COURSES.DASHBOARD_TABLE.NUM_ENROLLEES(),
    className: 'num-enrollees-cell',
    gridWidth: '10%',
  },
  {
    name: t.COURSES.DASHBOARD_TABLE.COMPLETION_RATE(),
    className: 'completion-rate-cell',
    gridWidth: 'minmax(40px, 30%)',
  },
  {
    name: '',
    className: 'options-cell',
    gridWidth: `${standardSpacing * 3}px`,
  }, // Options column
];

/** A subset of courseTableColumns used on mobile devices */
const createMobileTableColumns = () => {
  const courseTableColumns = createCourseTableColumns();
  const columns = [_.clone(courseTableColumns[0]), courseTableColumns[courseTableColumns.length - 1]];
  columns[0].gridWidth = `calc(100% - ${courseTableColumns[courseTableColumns.length - 1].gridWidth})`;
  return columns;
};

/* The different sorting options for the courses list table. See Filters; the string values are sent in the paged data query */
enum Sorting {
  RELEASE_DATE_DESC = 'release_date_desc',
  RELEASE_DATE_ASC = 'release_date_asc',
  CLOSE_DATE_DESC = 'close_date_desc',
  CLOSE_DATE_ASC = 'close_date_asc',
}

enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

enum SortableColumnNumber {
  RELEASE_DATE = 1,
  CLOSE_DATE = 2,
}

enum UsedFor {
  PRODUCTION = 'for_production',
  SAND_BOX = 'for_sandbox',
  PRIMARY = 'for_primary',
}

enum PacedStates {
  SELF_PACED = 'self_paced',
  NOT_SELF_PACED = 'not_self_paced',
}

const initialFetchParams: PagedDataQueryParams = {
  filters: {},
  searchQuery: '',
  sorting: [Sorting.RELEASE_DATE_DESC],
};

const initialFilterFetchParams: PagedDataQueryParams = {
  searchQuery: '',
  filters: { [FilterType.METADATA_VALUE_IDS]: '' },
  sorting: [Sorting.RELEASE_DATE_DESC],
};

const MAX_VALUE_LENGTH = 20;

const usedForMyViewSeparator = '_or_';

const InstitutionDashboardHome = (props: StateProps & typeof actions & OwnProps) => {
  // TODO: We should remove this conditional and move it to the code that calls this home component to avoid
  // eslint "rule-of-hooks" errors about conditional hooks
  if (!props.institution) {
    return null;
  }

  const fetchParams = {
    institutionId: props.institution.id,
  };
  const angularServices = useContext(AngularServicesContext);
  const { injectServices } = useContext(AngularContext);
  const [$stateParams] = injectServices(['$stateParams']);
  const defaultCourseFilterView = props.institution?.defaultFilterViews?.find(value => value.type === 'CourseFilterView');

  const [filterCount, setFilterCount] = useState(0);
  const [filterTabCount, setFilterTabCount] = useState(props.courseCounts.myView);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [clearFilters, setClearFilters] = useState(false);
  const [myViewData, setMyViewData] = useState(props.myViewData || defaultCourseFilterView);
  const [myViewFilterExist, setMyViewFilterExist] = useState(!!myViewData);
  const [metadataFields, setMetadataFields] = useState(props.institution?.metadataFields);
  const [filterFetchParams, setFilterFetchParams] = useState<PagedDataQueryParams>(initialFilterFetchParams);
  const [columnSortings, setColumnSortings] = useState<ColumnSortings>({ 1: true }); // Desc sort the Release Date column by default
  const [showResultsTab, setShowResultsTab] = useState(false);
  const [resultsCount, setResultsCount] = useState(0);
  const initialTab = React.useMemo(() => $stateParams.dashboardTab || Filters.ACTIVE, [$stateParams.dashboardTab]);
  const [currentTab, setCurrentTab] = useState(initialTab);
  const initialPagedFetchParams = React.useMemo(() => mergeWith(cloneDeep(initialFetchParams), {
    filters: { [initialTab]: '1' },
  }, replaceArrays), [initialTab]);
  const [pagedFetchParams, setPagedFetchParams] = useState<PagedDataQueryParams>(initialPagedFetchParams);
  const [courseClonings, setCourseClonings] = useState<Record<string, CourseCloning[]>>({});
  const dispatch = useAppDispatch();

  const renderCohortsOfPrimaryHeader = pagedFetchParams.filters[Filters.COHORTS_OF];

  const styles = css`
    /* This makes the NvRespsoniveTable's outer container stretch to only the bottom of the screen */
    display: grid;
    display: -ms-grid;
    grid-template-rows: auto auto auto 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto 1fr;
    -ms-grid-columns: 1fr;
    /* Note that the height of this element is set dynamically in a style prop in the render block */

    .filter-panel {
      position: relative;
    }

    .filter-count {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 20px;
      height: 20px;
      font-size: 12px;
      color: white;
      background-color: ${primary};
      border-radius: 10px;
      position: absolute;
      top: -11px;
      right: -10px;
    }

    .clear-btn {
      cursor: pointer;
      word-break: break-word;
      margin-left: ${largeSpacing}px;

      ${handheld(css`
        width: ${standardSpacing * 2}px;
        margin-left: ${standardSpacing}px;
      `)};
    }

    .save-my-view-button {
      margin: 0 40px;
    }

    .org-courses-table {
      ${!renderCohortsOfPrimaryHeader && css`
        border-top: ${borderStyle};
      `}
    }

    .no-results-panel > .icon {
      font-size: 100px;
      margin-bottom: ${largeSpacing}px;
    }

    /* Display created-by-name when a non-disabled row is hovered */
    .bkg-row:hover:not(.disabled) + .name-cell {
      .created-by-name {
        display: block;
      }
    }

    .no-results-panel {
      margin-top: ${standardSpacing * 2}px;
    }

    .no-my-view-filters {
      display: flex;
      .icon {
        padding: ${quarterSpacing / 2}px;
      }
    }
  `;

  useEffect(() => () => {
    dispatch(setCourseHighlight(null));
  }, [dispatch]);

  useEffect(() => {
    if (props.myViewData) {
      setMyViewData(props.myViewData);
    }
  }, [props.myViewData]);

  useEffect(() => {
    setFilterTabCount(props.courseCounts.myView);
  }, [props.courseCounts.myView]);

  useEffect(() => {
    if (myViewData && !$stateParams.dashboardTab) {
      setTabFilter(Filters.MY_VIEW);
    }
  }, [filterFetchParams, myViewData]);

  useEffect(() => {
    props.getCourseCounts({ institutionId: props.institution.id });
    props.getCourseClonings({ institutionId: props.institution.id });
    props.getMetadataFilters({ callback: updateFilters });
    if (myViewData) {
      props.getMyFilterView({ id: myViewData.id, setFetchParams: updateFilterFetchParams });
    } else if (!props.visitedOrgDashboard) {
      props.setVisitedOrgAdminDashboard();
      setTabFilter(Filters.MY_VIEW);
    }
  }, [props.institution.id]);

  const updateFilters = (filters: MetadataFieldType[]) => {
    setMetadataFields(filters);
  };

  const setFilters = (filters) => {
    const newFilters: any = {};

    if (pagedFetchParams.filters[Filters.COHORTS_OF] && !filters[Filters.PRIMARY_SINGLE]) {
      newFilters[Filters.COHORTS_OF] = pagedFetchParams.filters[Filters.COHORTS_OF];
    }

    let filtersCount = 0;
    const newPaceFilter = filters[FilterType.COURSE_PACE];
    newPaceFilter?.forEach(filter => {
      newFilters[filter] = '1';
      filtersCount += 1;
    });
    const newRegistrationTypeFilter = filters[FilterType.REGISTRATION_TYPE];
    if (newRegistrationTypeFilter?.length > 0) {
      newFilters.registrationType = newRegistrationTypeFilter.join();
      filtersCount += newRegistrationTypeFilter?.length;
    }
    const newCourseTypeFilter = filters[FilterType.COURSE_TYPE];
    newCourseTypeFilter?.forEach(filter => {
      if (filter === Filters.DEMO || filter === Filters.PRODUCTION || filter === Filters.PRIMARY) {
        newFilters[filter] = '1';
        filtersCount += 1;
      }
    });
    const newMetadataTagFilter = filters[FilterType.METADATA_VALUE_IDS];
    if (newMetadataTagFilter?.length) {
      newFilters[FilterType.METADATA_VALUE_IDS] = newMetadataTagFilter.join(',');
      filtersCount += newMetadataTagFilter.length;
    }

    const primaryFilter = filters[Filters.PRIMARY_SINGLE];
    if (primaryFilter) {
      newFilters[Filters.PRIMARY_SINGLE] = primaryFilter;
      setClearFilters(true);
    }

    const cohortsOfFilter = filters[Filters.COHORTS_OF];

    if (cohortsOfFilter) {
      newFilters[Filters.COHORTS_OF] = cohortsOfFilter;
      setClearFilters(true);
    }

    let query = null;
    if (!primaryFilter && !cohortsOfFilter) {
      if (showResultsTab && pagedFetchParams.searchQuery) {
        query = pagedFetchParams.searchQuery;
      }
    }

    if (query === null) {
      resetInputText();
      setIsSearchOpen(false);
    }
    const newPagedFetchParams = {
      ...pagedFetchParams,
      filters: newFilters,
      searchQuery: query,
    };
    switchToResultsTab(newPagedFetchParams);
    setFilterCount(filtersCount);
    setPagedFetchParams(newPagedFetchParams);
    setHideClearSearch(true);
  };

  const updateFilterFetchParams = (result: any) => {
    const newFilters = {};
    const newSortingFilter: string[] = [];
    if (result?.filters?.releaseDateOrder) {
      const value = result.filters.releaseDateOrder === Order.ASC ? Sorting.RELEASE_DATE_ASC : Sorting.RELEASE_DATE_DESC;
      newSortingFilter.push(value);
    }
    if (result?.filters?.closeDateOrder) {
      const value = result.filters.closeDateOrder === Order.ASC ? Sorting.CLOSE_DATE_ASC : Sorting.CLOSE_DATE_DESC;
      newSortingFilter.push(value);
    }
    const newPaceFilter = result?.filters?.selfPaced;
    if (newPaceFilter) {
      if (newPaceFilter === PacedStates.SELF_PACED) {
        newFilters[Filters.PACE_SELF_PACED] = '1';
      } else if (newPaceFilter === PacedStates.NOT_SELF_PACED) {
        newFilters[Filters.PACE_DEADLINE_BASED] = '1';
      } else if (newPaceFilter === Filters.PACE_ALL) {
        newFilters[Filters.PACE_SELF_PACED] = '1';
        newFilters[Filters.PACE_DEADLINE_BASED] = '1';
      }
    }
    const newUseForFilter = result?.filters?.usedFor;
    if (newUseForFilter) {
      if (newUseForFilter === Filters.COURSE_TYPE_ALL) {
        newFilters[Filters.PRODUCTION] = '1';
        newFilters[Filters.DEMO] = '1';
        newFilters[Filters.PRIMARY] = '1';
      } else if (newUseForFilter === UsedFor.PRODUCTION) {
        newFilters[Filters.PRODUCTION] = '1';
      } else if (newUseForFilter === UsedFor.SAND_BOX) {
        newFilters[Filters.DEMO] = '1';
      } else if (newUseForFilter === UsedFor.PRIMARY) {
        newFilters[Filters.PRIMARY] = '1';
      } else {
        newUseForFilter.split(usedForMyViewSeparator).forEach((usedForEach) => {
          newFilters[usedForEach] = '1';
        });
      }
    }
    const newRegistrationTypeFilter = result?.filters?.registrationType?.join(',');
    if (newRegistrationTypeFilter) {
      newFilters[FilterType.REGISTRATION_TYPE] = newRegistrationTypeFilter;
    }
    const newMetadataTagFilter = result?.filters?.metadataValueIds;
    if (newMetadataTagFilter?.length) {
      newFilters[FilterType.METADATA_VALUE_IDS] = newMetadataTagFilter.join(',');
    }

    const cohortsOfFilter = result?.filters?.cohortsOf;
    if (cohortsOfFilter) {
      newFilters[FilterType.COHORTS_OF] = cohortsOfFilter;
    }

    const primarySingleFilter = result?.filters?.byCatalogId;

    if (primarySingleFilter) {
      newFilters[FilterType.PRIMARY_SINGLE] = primarySingleFilter;
    }

    const newPagedFetchParams: PagedDataQueryParams = {
      searchQuery: result?.filters?.like,
      filters: newFilters,
      sorting: newSortingFilter,
    };
    setFilterFetchParams(newPagedFetchParams);
  };

  /** Map courses to their in-progress course clonings */
  useEffect(() => {
    if (props.courseClonings) {
      const coursesToClones: Record<string, CourseCloning[]> = {};
      // Display the clones with the most recent clone requests at the top
      _.sortBy(props.courseClonings, 'id').reverse().forEach(cloning => {
        if (!coursesToClones[cloning.owner.catalogId]) {
          coursesToClones[cloning.owner.catalogId] = [];
        }
        coursesToClones[cloning.owner.catalogId].push(cloning);
      });
      setCourseClonings(coursesToClones);
    }
  }, [props.courseClonings]);

  /** Sets the filter set to include the filter for the currently clicked tab, and removes all other tab filters.
   * @param filter - The filter to set. Gving `null` will unset all tab filters */
  const setTabFilter = (filter: Filters) => {
    let newFilters = {};
    let newSorting = initialFetchParams.sorting;
    let newSearchQuery = initialFetchParams.searchQuery;
    if (filter) {
      if (filter === Filters.MY_VIEW) {
        newFilters = filterFetchParams.filters;
        newSorting = filterFetchParams.sorting;
        newSearchQuery = filterFetchParams.searchQuery;
      } else {
        newFilters = { [filter]: '1' };
      }
    }
    setCurrentTab(filter);
    // Hide the 'Clear Filter' blue link in the no results panel when a tab is selected.
    setHideClearSearch(filter !== null);

    if (newSorting?.length) {
      const desc = newSorting[0] === Sorting.RELEASE_DATE_DESC || newSorting[0] === Sorting.CLOSE_DATE_DESC;
      const newColIndex = newSorting[0] === Sorting.RELEASE_DATE_DESC || newSorting[0] === Sorting.RELEASE_DATE_ASC ? SortableColumnNumber.RELEASE_DATE : SortableColumnNumber.CLOSE_DATE;
      if (newColIndex) {
        const isNewSorting = newSorting !== pagedFetchParams.sorting;
        if (isNewSorting) {
          setColumnSortings({ [newColIndex]: desc });
        }
      }
    }
    setPagedFetchParams({
      /* Removes all other filters. The API expects that the filters set by our tabs be set to '1' if present, unlike some other
      filters that have one of a set of different string values (currently just registration_type) https://novoed.atlassian.net/browse/NOV-61925 */
      filters: newFilters,
      sorting: newSorting,
      searchQuery: newSearchQuery,
    });
  };

  const onHeaderSortingClicked = (colIndex: number) => {
    // TODO: Currently this is hardcoded to only support sorting on columns #2 and #3
    if (colIndex !== SortableColumnNumber.RELEASE_DATE && colIndex !== SortableColumnNumber.CLOSE_DATE) {
      return;
    }

    const newSorting = !columnSortings[colIndex];
    let newSortKey: string = null;

    if (colIndex === SortableColumnNumber.RELEASE_DATE) {
      newSortKey = newSorting ? Sorting.RELEASE_DATE_DESC : Sorting.RELEASE_DATE_ASC;
    } else if (colIndex === SortableColumnNumber.CLOSE_DATE) {
      newSortKey = newSorting ? Sorting.CLOSE_DATE_DESC : Sorting.CLOSE_DATE_ASC;
    }

    // Don't preserve anything from the previous sorting; we only allow sorting on one column at a time
    setColumnSortings({ [colIndex]: newSorting });
    setPagedFetchParams({
      ...pagedFetchParams,
      sorting: [newSortKey],
    });
  };

  const handleSearch = (query: string) => {
    const newPagedFetchParams = {
      ...pagedFetchParams,
      filters: (filterCount > 0 || pagedFetchParams.filters[Filters.COHORTS_OF]) ? pagedFetchParams.filters : initialFilterFetchParams.filters,
      searchQuery: query,
    };
    setPagedFetchParams(newPagedFetchParams);

    switchToResultsTab(newPagedFetchParams);
  };

  const updateSearchCourseCounts = (newPagedFetchParams) => {
    props.getSearchCourseCounts({
      institutionId: props.institution.id,
      ...newPagedFetchParams,
      handleCountResponse: (count) => {
        setResultsCount(count);
      },
    });
  };

  const switchToResultsTab = (newPagedFetchParams: PagedDataQueryParams) => {
    updateSearchCourseCounts(newPagedFetchParams);

    setShowResultsTab(true);
  };

  const updateResultsCount = () => {
    updateSearchCourseCounts(pagedFetchParams);
  };

  const clearSearchResults = () => {
    setTabFilter(currentTab);
    setShowResultsTab(false);
    setIsSearchOpen(false);
    resetInputText();
    setClearFilters(true);
    setHideClearSearch(true);
    setFilterCount(0);
  };

  const convertFiltersToSave = (currentFetchParams: PagedDataQueryParams) => {
    const getUsedFor = (filter: Filters) => {
      let value;
      if (filter === Filters.PRODUCTION) {
        value = UsedFor.PRODUCTION;
      } else if (filter === Filters.DEMO) {
        value = UsedFor.SAND_BOX;
      } else {
        value = UsedFor.PRIMARY;
      }
      return value;
    }
    let courseUsedFor;
    const usedForOptions = [Filters.PRODUCTION, Filters.DEMO, Filters.PRIMARY];

    const activeUsedFor = usedForOptions.reduce((acc, curr) => {
      if (currentFetchParams.filters[curr]) {
        return [...acc, curr];
      }

      return acc;
    }, []);

    if (activeUsedFor.length === usedForOptions.length) {
      courseUsedFor = Filters.COURSE_TYPE_ALL;
    } else if (activeUsedFor.length === 1) {
      courseUsedFor = getUsedFor(activeUsedFor[0]);
    } else {
      courseUsedFor = usedForOptions.reduce((acc, curr, index) => {
        const isFirst = index === 0;
        const notFirstPrefix = usedForMyViewSeparator;

        const currentFilterValue = currentFetchParams.filters[curr];

        if (currentFilterValue) {
          return `${acc}${isFirst ? '' : notFirstPrefix}${curr}`;
        }

        return acc;
      }, '');
    }

    let selfPacedForm;
    if (currentFetchParams.filters[Filters.PACE_SELF_PACED]) {
      selfPacedForm = PacedStates.SELF_PACED;
      if (currentFetchParams.filters[Filters.PACE_DEADLINE_BASED]) {
        selfPacedForm = Filters.PACE_ALL;
      }
    } else if (currentFetchParams.filters[Filters.PACE_DEADLINE_BASED]) {
      selfPacedForm = PacedStates.NOT_SELF_PACED;
    }

    let releaseOrder = currentFetchParams?.sorting?.find(sorting => (sorting === Sorting.RELEASE_DATE_ASC || sorting === Sorting.RELEASE_DATE_DESC));
    if (releaseOrder) {
      releaseOrder = releaseOrder === Sorting.RELEASE_DATE_ASC ? Order.ASC : Order.DESC;
    }

    let dateOrder = currentFetchParams?.sorting?.find(sorting => (sorting === Sorting.CLOSE_DATE_ASC || sorting === Sorting.CLOSE_DATE_DESC));
    if (dateOrder) {
      dateOrder = dateOrder === Sorting.CLOSE_DATE_ASC ? Order.ASC : Order.DESC;
    }

    const cohortsOf = currentFetchParams?.filters[Filters.COHORTS_OF];
    const byCatalogId = currentFetchParams?.filters[Filters.PRIMARY_SINGLE];

    const metadataValuesString = currentFetchParams.filters[FilterType.METADATA_VALUE_IDS];
    const registrationTypeString = currentFetchParams.filters?.registrationType;
    const filters = {
      name: 'My View Filter',
      filters: {
        like: currentFetchParams.searchQuery,
        usedFor: courseUsedFor ? courseUsedFor : undefined,
        selfPaced: selfPacedForm,
        registrationType: registrationTypeString?.length ? registrationTypeString.split(',').map(Number) : [],
        metadataValueIds: metadataValuesString?.length > 0 ? metadataValuesString.split(',').map(Number) : [],
        releaseDateOrder: releaseOrder,
        closeDateOrder: dateOrder,
        cohortsOf,
        byCatalogId,
      },
      isDefault: true,
    };
    return filters;
  };

  const saveMyView = () => {
    const courseFilterView = convertFiltersToSave(pagedFetchParams);
    if (myViewData) {
      props.updateMyFilterView({
        id: myViewData.id,
        data: {
          courseFilterView,
        },
      });
    } else {
      props.postMyFilterView({
        courseFilterView,
      });
    }
    setShowModal(false);
    setShowResultsTab(false);
    setIsSearchOpen(false);
    resetInputText();
    setClearFilters(true);
    setHideClearSearch(true);
    setFilterTabCount(resultsCount);
    setMyViewFilterExist(true);
    setFilterCount(0);
    updateFilterFetchParams(courseFilterView);
  };

  const showNoCoursesState = props.countsLoaded && !props.courseCounts.total;

  // Only show the first and last columns (course name & options button) on mobile phone devices
  const [showMobileView, setShowMobileView] = useState(isHandheld());
  const tableColumns = showMobileView ? createMobileTableColumns() : createCourseTableColumns();

  // Selectively hide/show the blue 'clear search' link in the no results panel. Needs
  // to be hidden when we have no results when a tab is selected, vs a dropdown filter.
  const [hideClearSearch, setHideClearSearch] = useState(true);

  // Switch to/from mobile view on window resize
  useEffect(() => {
    const unlisten = window.addEventListener('resize', _.debounce(() => {
      setShowMobileView(isHandheld());
    }, 100));
    return unlisten;
  }, []);

  const exportIconClicked = () => {
    props.downloadCoursesCSV({
      institutionId: props.institution.id,
      ...pagedFetchParams,
    });
  };

  const getRowTooltip = (course: Course, isExpanded: boolean, isDisabled: boolean) => {
    if (isDisabled && course.isBeingDeleted) {
      return t.COURSES.DASHBOARD_TABLE.DELETION_IN_PROGRESS_DESCRIPTION();
    }

    if (isExpanded) {
      return t.COURSES.DASHBOARD_TABLE.CLICK_TO_HIDE();
    }
    return t.COURSES.DASHBOARD_TABLE.CLICK_TO_EXPAND();
  };

  const disableRow = (course: Course) => course.isBeingDeleted;
  /** Reset the input text to be empty. Used when this search is collapsed */
  const resetInputText = () => {
    inputRef.current.value = '';
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const getStoreCourseData = (state: RootState) => state.models.courses as CoursesNormalized;

  const getMyViewTooltipText = () => {
    const style = css`
      width: 170px;

      .filters-rest, .filters-cohorts {
        margin-top: ${quarterSpacing}px;
      }

      .filters-container {
        color: ${gray3};
      }

      .used-field {
        .field-name {
          white-space: nowrap;
          &.ellipsis {
            display: block;
          }
          &:after {
            content: ":";
            position: fixed;
          }
          &.ellipsis:after {
            right: ${threeQuartersSpacing}px;
          }
        }
        .field-value {
          padding-left: ${quarterSpacing}px;
          white-space: nowrap;
          &.ellipsis {
            display: block;
          }
        }
      }
    `;
    if (!myViewFilterExist) {
      return null;
    }
    const title = t.INSTITUTIONS.DASHBOARD.MY_VIEW_TOOLTIP_TITLE();

    const cohortsOfFilter = filterFetchParams.filters[FilterType.COHORTS_OF];

    const primaryOfCohorts = Object.values(props.courses).find((course) => (course.id === parseInt(cohortsOfFilter, 10)));

    const cohortsOf = cohortsOfFilter ? (
      <React.Fragment>
        {`${t.COURSES.FILTER.COHORTS.HEADER()}: `}
        <span className='text-black'>{primaryOfCohorts?.name}</span>
        <br />
      </React.Fragment>
    ) : null;

    const keyword = filterFetchParams.searchQuery?.length
      ? (
        <React.Fragment>
          {`${t.COURSES.FILTER.KEYWORD()}: `}
          <span className='text-black'>{filterFetchParams.searchQuery}</span>
          <br />
        </React.Fragment>
      )
      : null;

    const metadataValues = filterFetchParams.filters[FilterType.METADATA_VALUE_IDS]?.split(',').map(Number);
    let medatadaValuesComponent = null;
    if (metadataValues?.length) {
      const metadatas = metadataFields.map(field => {
        const { name } = field;
        const metadataValuesApplied = field.metadataValues.filter(value => metadataValues.includes(value.id));
        if (metadataValuesApplied?.length === 0) {
          return null;
        }
        return {
          name,
          metadataValues: metadataValuesApplied.map(value => value.value),
        };
      });
      if (metadatas.length) {
        medatadaValuesComponent = metadatas.map(value => {
          if (!value) {
            return null;
          }
          return (
            <div key={value.name} className='used-field'>
              <span className={`field-name${value.name.length > MAX_VALUE_LENGTH ? ' ellipsis' : ''}`}>
                {value.name}
              </span>
              {value.metadataValues.map((metadataValue, index) => (
                <span key={metadataValue} className={`text-black field-value${metadataValue.length > MAX_VALUE_LENGTH ? ' ellipsis' : ''}`}>
                  {metadataValue}
                </span>
              ))}
            </div>
          );
        });
      }
    }
    let filterPaceMode = '';
    const filterSelfPaced = filterFetchParams.filters[Filters.PACE_SELF_PACED];
    if (filterSelfPaced) {
      filterPaceMode = t.COURSES.FILTER.PACE_TYPE.SELF_PACED();
    }
    const filterDeadlineBased = filterFetchParams.filters[Filters.PACE_DEADLINE_BASED];
    if (filterDeadlineBased) {
      filterPaceMode = t.COURSES.FILTER.PACE_TYPE.DEADLINE_BASED();
    }
    if (filterSelfPaced && filterDeadlineBased) {
      filterPaceMode = t.COURSES.FILTER.PACE_TYPE.ALL();
    }
    const paceMode = filterPaceMode
      ? (
        <React.Fragment>
          {`${t.COURSES.FILTER.PACE_TYPE.HEADER()}: `}
          <span className='text-black'>{filterPaceMode}</span>
          <br />
        </React.Fragment>
      )
      : null;
    let filterRegistrationTypeText = '';
    const filterRegistrationTypeArray = filterFetchParams.filters[FilterType.REGISTRATION_TYPE]?.split(',');
    // The current amount of available registration types is 4: type_0, type_3, type_4 and type_5
    const TOTAL_REGISTRATION_TYPES = 4;
    filterRegistrationTypeArray?.forEach(filter => {
      let newType;
      switch (filter) {
        case Filters.REGISTRATION_TYPE_FREE_TO_PUBLIC:
          newType = t.COURSES.REGISTRATION_TYPE.TYPE_0();
          break;
        case Filters.REGISTRATION_TYPE_CLOSED:
          newType = t.COURSES.REGISTRATION_TYPE.TYPE_3();
          break;
        case Filters.REGISTRATION_TYPE_OPEN_IN_ORG:
          newType = t.COURSES.REGISTRATION_TYPE.TYPE_4();
          break;
        case Filters.REGISTRATION_TYPE_BASED_ON_ENTITLEMENT:
          newType = t.COURSES.REGISTRATION_TYPE.TYPE_5();
          break;
        default:
          break;
      }
      if (newType) {
        const comma = filterRegistrationTypeText.length > 0 ? ',' : '';
        filterRegistrationTypeText += `${comma} ${newType}`;
      }
    });
    if (filterRegistrationTypeArray?.length === TOTAL_REGISTRATION_TYPES) {
      filterRegistrationTypeText = t.COURSES.REGISTRATION_TYPE.TYPE_ALL();
    }

    const registrationType = filterRegistrationTypeText.length > 0
      ? (
        <React.Fragment>
          {`${t.COURSES.FILTER.REGISTRATION_TYPE.HEADER()}: `}
          <span className='text-black'>{filterRegistrationTypeText}</span>
          <br />
        </React.Fragment>
      )
      : null;

    const filtersUsedFor = [];
    const filterUsedForProd = filterFetchParams.filters[Filters.PRODUCTION];
    if (filterUsedForProd) {
      filtersUsedFor.push(t.COURSES.FILTER.USED_FOR.TYPE_0());
    }
    const filterUsedForDemo = filterFetchParams.filters[Filters.DEMO];
    if (filterUsedForDemo) {
      filtersUsedFor.push(t.COURSES.FILTER.USED_FOR.TYPE_1());
    }

    const filterUsedForPrimary = filterFetchParams.filters[Filters.PRIMARY];
    if (filterUsedForPrimary) {
      filtersUsedFor.push(t.COURSES.FILTER.USED_FOR.TYPE_2());
    }

    let filterUsedFor = '';

    if (filterUsedForProd && filterUsedForDemo && filterUsedForPrimary) {
      filterUsedFor = t.COURSES.FILTER.USED_FOR.TYPE_ALL();
    } else {
      filterUsedFor = filtersUsedFor.reduce((acc, curr, index) => {
        const isFirst = index === 0;
        const isLast = index === filtersUsedFor.length - 1;
        const notFirstPrefix = (isLast ? t.JOIN.MANY.AND() : t.JOIN.MANY.COMMA());

        return `${acc}${isFirst ? '' : notFirstPrefix}${curr}`;
      }, '');
    }

    const usedFor = filterUsedFor.length > 0
      ? (
        <React.Fragment>
          {`${t.COURSES.FILTER.USED_FOR.HEADER()}: `}
          <span className='text-black'>{filterUsedFor}</span>
          <br />
        </React.Fragment>
      )
      : null;

    const primaryFilter = filterFetchParams.filters[FilterType.PRIMARY_SINGLE];

    const primarySingle = primaryFilter ? (
      <React.Fragment>
        {`${t.COURSES.FILTER.PRIMARY.HEADER()}: `}
        <span className='text-black'>{props.courses[primaryFilter]?.name}</span>
        <br />
      </React.Fragment>
    ) : null;

    const filtersRest = [
      keyword,
      medatadaValuesComponent,
      paceMode,
      registrationType,
      usedFor,
      primarySingle,
    ].filter(Boolean);

    return (
      <div css={style} className='my-view-tooltip'>
        <div className='text-gray-3 title'>
          {title}
        </div>
        <div className='filters-container bold'>
          {cohortsOf && (
            <div className='filters-cohorts'>
              {cohortsOf}
            </div>
          )}
          {!!filtersRest.length && (
            <div className='filters-rest'>
              {filtersRest}
            </div>
          )}
        </div>
      </div>
    );
  };

  const tabsInfo = [
    {
      filter: Filters.MY_VIEW,
      text: t.COURSES.TABS.TAB_MY_VIEW(),
      count: filterTabCount,
      popOver: getMyViewTooltipText(),
      dataQa: config.pendo.orgDashboard.myViewTab,
    },
    {
      filter: Filters.ACTIVE,
      text: t.COURSES.TABS.TAB_ACTIVE(),
      count: props.courseCounts.active,
      tooltip: t.COURSES.TABS.TAB_TOOLTIP_ACTIVE(),
    },
    {
      filter: Filters.FUTURE,
      text: t.COURSES.TABS.TAB_FUTURE(),
      count: props.courseCounts.future,
      tooltip: t.COURSES.TABS.TAB_TOOLTIP_FUTURE(),
    },
    {
      filter: Filters.PAST,
      text: t.COURSES.TABS.TAB_PAST(),
      count: props.courseCounts.past,
      tooltip: t.COURSES.TABS.TAB_TOOLTIP_PAST(),
    },
    {
      filter: Filters.DEMO,
      text: t.COURSES.TABS.TAB_DEMO(),
      count: props.courseCounts.demo,
      tooltip: t.COURSES.TABS.TAB_TOOLTIP_DEMO(),
    },
    {
      filter: Filters.PRIMARY,
      count: props.courseCounts.primary,
      text: t.COURSES.TABS.TAB_PRIMARY(),
      tooltip: t.COURSES.TABS.TAB_TOOLTIP_PRIMARY(),
      dataQa: config.pendo.orgDashboard.primaryTab,
    },
    {
      filter: null,
      text: t.COURSES.TABS.TAB_TOTAL(),
      count: props.courseCounts.total,
      tooltip: t.COURSES.TABS.TAB_TOOLTIP_TOTAL(),
    },
  ];

  const getIndexOfFilter = (filter: Filters) => (tabsInfo.findIndex(tab => tab.filter === filter));

  /** NvResponsiveTabRow components */
  /* Nav tabs in the top-left of the table */
  const filterTabs: NvTab[] = tabsInfo.map(tab => ({
    text: tab.text,
    count: tab.count,
    popOver: tab.popOver,
    tooltip: tab.tooltip,
    dataQA: tab.dataQa,
    onClick: () => setTabFilter(tab.filter),
  }));

  const resultsTab: NvTab = {
    text: t.COURSES.TABS.TAB_RESULTS(),
    count: resultsCount,
    dataQA: config.pendo.orgDashboard.results,
    onClick: () => { },
  };

  const clearBtn = (
    <div
      className='clear-btn page-title-xxs text-primary'
      onClick={() => clearSearchResults()}
      data-qa={config.pendo.orgDashboard.clearSearch}
    >
      {t.SEARCH.CLEAR()}
    </div>
  );

  const saveAsMyViewButton = (
    <Button
      className='save-my-view-button'
      type='button'
      size='sm'
      variant='primary'
      onClick={() => saveMyView()}
      pendo-tag-name={myViewFilterExist ? config.pendo.adminDashboard.saveAndReplaceMyView : config.pendo.adminDashboard.saveAsMyView}
      data-qa={config.pendo.orgDashboard.saveMyView}
    >
      {myViewFilterExist ? t.SEARCH.SAVE_AND_REPLACE_MY_VIEW() : t.SEARCH.SAVE_AS_MY_VIEW()}
    </Button>
  );
  const searchBar = (
    <NvExpandableSearchBar
      onSearch={handleSearch}
      isExpanded={isSearchOpen}
      onExpanded={setIsSearchOpen}
      ref={inputRef}
      placeholder={(renderCohortsOfPrimaryHeader
        ? t.COURSES.SEARCH.COHORTS_PLACEHOLDER()
        : t.COURSES.SEARCH.PLACEHOLDER()
      )}
    />
  );

  const buttonStyle = css`
  border: none;
  background: none;
  padding: 0;
  position: relative;

  body:not(.keyboard_user) &:focus {
    outline: none;
  }

  &:enabled {
    cursor: pointer;
  }

  &:enabled:hover {
    i, span {
      // it should be important because is overwriting the "text-gray-2" color !important property on hover
      color: ${primary} !important;
    }
  }
`;

  const onFilterToggle = () => {
    setShowModal(!showModal);
  };

  const filterBtn = (
    <NvTooltip text={t.COURSES.FILTER.TITLE()} placement='top' key='filter-btn'>
      <button
        css={buttonStyle}
        onClick={() => onFilterToggle()}
        type='button'
        data-qa={config.pendo.orgDashboard.filterIcon}
      >
        <i className={`icon icon-small icon-filter ${filterCount !== 0 ? 'text-primary' : 'text-gray-2'}`} />
        {filterCount !== 0 && <div className='filter-count text-center'>{filterCount}</div> }
      </button>
    </NvTooltip>
  );

  const exportBtn = (
    <NvTooltip text={t.COURSES.EXPORT()} placement='top' key='export-btn'>
      <button
        css={buttonStyle}
        onClick={() => exportIconClicked()}
        type='button'
      >
        <i className='icon icon-small icon-export text-gray-2' />
      </button>
    </NvTooltip>
  );
  /** end NvResponsiveTabRow components */

  const noMyViewFilters = () => {
    const [firstText, tail] = t.INSTITUTIONS.DASHBOARD.NO_MY_VIEW_FILTERS_YET().split('FILTER_ICON');
    const [secondText, lastText] = tail.split('SEARCH_ICON');
    return (
      <div className='no-my-view-filters text-gray-3 font-weight-bold'>
        {firstText}
        <NvIcon icon='filter' size='smallest' className='text-gray-3 font-weight-bold' />
        {secondText}
        <NvIcon icon='search' size='smallest' className='text-gray-3 font-weight-bold' />
        {lastText}
      </div>
    );
  };

  const isMyViewCurrentTab = () => currentTab === Filters.MY_VIEW && !showResultsTab;

  const coursesList = (
    <React.Fragment>
      <NvResponsiveTabsRow
        style={createGridStyles(1, 2)}
        defaultTabs={filterTabs}
        filteredTabs={[resultsTab]}
        isFiltered={showResultsTab}
        revertActiveTab={getIndexOfFilter(currentTab)}
        tabType={NvResponsiveTabsDisplayType.STACKED_NUMBERED}
        clearBtn={clearBtn}
        showClearBtn={showResultsTab}
        saveAsMyViewButton={saveAsMyViewButton}
        showSaveAsMyViewButton={showResultsTab}
        searchBar={searchBar}
        iconBtns={[filterBtn, exportBtn]}
        ctaButtonText={t.COURSES.NEW_COURSE()}
        ctaUrl={angularServices.$state.href('institution-new-course')}
        ctaIconClass='create-new-post'
        ctaDataQa={config.pendo.orgDashboard.newCourse}
      />
      {renderCohortsOfPrimaryHeader && (
        <PrimaryCohortsHeader
          style={createGridStyles(1, 3)}
          primaryId={pagedFetchParams.filters[Filters.COHORTS_OF] as unknown as number}
        />
      )}
      {
        (isMyViewCurrentTab() && !myViewFilterExist)
        && (
          <div className='bg-gray-7'>
            <NvNoResults action={() => {}} noResultsTextComponent={noMyViewFilters()} />
          </div>
        )
      }
      {
        (!isMyViewCurrentTab() || myViewFilterExist)
        && (
          <NvResponsiveTable<Course, CourseRowExtraProps, CourseCloning>
            className='org-courses-table'
            columns={tableColumns}
            columnSortings={columnSortings}
            onSortClicked={onHeaderSortingClicked}
            fetchData={getCoursesList}
            fetchParams={fetchParams}
            pagedFetchParams={pagedFetchParams}
            rowComponent={CourseRow as FunctionComponent}
            rowProps={{ institution: props.institution }}
            loadingComponent={LoadingRow}
            dataKey='catalogId'
            cacheDataKey='catalogId'
            cacheLookup={getStoreCourseData}
            expandableRowComponent={CourseDetailsRow}
            rowHoverTooltip={getRowTooltip}
            clearSearch={clearSearchResults}
            hideClearSearch={hideClearSearch}
            checkRowDisabled={disableRow}
            style={createGridStyles(1, 4)}
            noResultsText={isMyViewCurrentTab() ? t.INSTITUTIONS.DASHBOARD.NO_MY_VIEW_RESULTS_FOUND() : null}
            extraRowData={courseClonings}
            extraRowTooltip={(item, extraItem) => t.COURSES.DASHBOARD_TABLE.CLONING_IN_PROGRESS_DESCRIPTION()}
            rowBackgroundWrapper={CourseRowBackground}
          />
        )
      }
      <FilterFlyoutModal
        showModal={showModal}
        onClose={() => { setShowModal(false); }}
        metadataFields={metadataFields}
        onSubmit={setFilters}
        clearFilters={clearFilters}
        onReset={() => { setClearFilters(false); }}
      />
      <ManageCourseAccessModal />
    </React.Fragment>
  );

  const goToCreateNewCourse = () => {
    angularServices.$state.go('institution-new-course');
  };

  const [dashboardHeight, setDashboardHeight] = useState(window.innerHeight);

  /* Update the dashboard height on window resize */
  useEffect(() => {
    const unlisten = window.addEventListener('resize', () => {
      setDashboardHeight(window.innerHeight);
    });

    return unlisten;
  });

  return (
    <InstitutionDashboardContext.Provider
      value={{
        setFilters,
        resultsCount,
        getCourseCounts: props.getCourseCounts,
        updateResultsCount,
      }}
    >
      {/* Dynamically set the height of this panel due to it scrolling fixed content that needs to update upon browser resize */}
      {/* innerHeight is used instead of 100vh because of this classic problem: https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html */}
      <div css={styles} style={{ height: `${dashboardHeight}px` }}>
        <InstitutionBanner institution={props.institution} style={createGridStyles(1, 1)} />
        {showNoCoursesState
          && (
            <NvNoResults
              action={goToCreateNewCourse}
              clearText={t.INSTITUTIONS.DASHBOARD.CREATE_NEW_COURSE()}
              noResultsText={t.INSTITUTIONS.DASHBOARD.NO_COURSE_YET()}
              style={createGridStyles(1, 2, 1, 3)}
            />
          )}
        {!showNoCoursesState && coursesList}
      </div>
    </InstitutionDashboardContext.Provider>
  );
};

export default withRouter(connect<StateProps, typeof actions, OwnProps, RootState>((state) => ({
  institution: getCurrentInstitution(state),
  courseCounts: state.app.institutionDashboard.courseTypeCounts,
  countsLoaded: state.app.institutionDashboard.countsLoaded,
  courses: state.models.courses,
  courseClonings: state.models.courseClonings,
  myViewData: state.app.institutionDashboard.myViewData,
  visitedOrgDashboard: state.models?.users[state.app.currentUserId].visitedOrgDashboardAt !== null,
}), actions as any)(InstitutionDashboardHome));
