import React, { useRef, useState } from 'react';
import _ from 'underscore';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import t from 'react-translate';
import { RootState } from 'redux/schemas';
import { useAppDispatch } from 'redux/store';
import LoadingRow from 'shared/components/loading-row';
import { getCoursesList, getJourneyClonings, getSearchCourseCounts } from 'redux/actions/institutions';
import JourneyRow from 'learning_journeys/components/journey-row';
import { screenXsMax } from 'styles/global_defaults/media-queries';
import { PagedDataQueryParams } from 'redux/create-action-creators';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import {
  Course,
  Sorting,
  CoursesNormalized,
} from 'redux/schemas/models/course';
import {
  tripleSpacing,
  createGridStyles,
  standardSpacing,
  quarterSpacing,
} from 'styles/global_defaults/scaffolding';
import NvResponsiveTable, {
  ColumnSortings,
} from 'shared/components/nv-responsive-table';
import { JourneyCloning, CourseTypeCounts } from 'redux/schemas/models/institution';
import NvResponsiveTabsRow from 'shared/components/nv-responsive-tabs-row';
import { NvResponsiveTabsDisplayType, NvTab } from 'shared/components/nv-responsive-tabs';
import { gray5 } from 'styles/global_defaults/colors';
import { NvExpandableSearchBar } from 'shared/components/nv-search-bar';

import { config } from '../../../config/pendo.config.json';

const LoadingRowWithoutAvatar = (props: { rowIndex: number }) => (
  <LoadingRow
    hideAvatar
    rowIndex={props.rowIndex}
  />
);

enum Filters {
  ACTIVE = 'active',
  FUTURE = 'future',
  PAST = 'past',
}

const initialFetchParams = {
  filters: { [Filters.ACTIVE]: '1' },
  sorting: [Sorting.RELEASE_DATE_DESC],
  searchQuery: '',
};

const createTableColumns = () => [
  {
    name: t.COURSES.DASHBOARD_TABLE.OFFERING_NAME(),
    className: 'name-cell',
    gridWidth: '30%',
  },
  {
    name: t.COURSES.DASHBOARD_TABLE.NUM_OF_COURSES(),
    className: 'num-of-courses-cell',
    gridWidth: '10%',
  },
  {
    name: t.COURSES.DASHBOARD_TABLE.RELEASE_DATE(),
    className: 'release-date-cell',
    sortable: true,
    gridWidth: '10%',
    headerTooltip: t.COURSES.DASHBOARD_TABLE.RELEASE_DATE_TOOLTIP(),
    dataQa: config.pendo.learningJourneys.releaseDateDownActiveIcon,
  },
  {
    name: t.COURSES.DASHBOARD_TABLE.CLOSE_DATE(),
    className: 'close-date-cell',
    sortable: true,
    gridWidth: '10%',
  },
  {
    name: t.COURSES.DASHBOARD_TABLE.NUM_ENROLLEES(),
    className: 'num-enrollees-cell',
    gridWidth: '10%',
  },
  {
    name: t.COURSES.DASHBOARD_TABLE.COMPLETION_RATE(),
    className: 'completion-rate-cell',
    gridWidth: 'minmax(40px, 30%)',
  },
  {
    name: '',
    className: 'options-cell',
    gridWidth: `${tripleSpacing}px`,
  },
];

const createMobileTableColumns = () => {
  const tableColumns = createTableColumns();
  const columns = [_.clone(tableColumns[0]), tableColumns[tableColumns.length - 1]];

  columns[0].gridWidth = `calc(100% - ${tableColumns[tableColumns.length - 1].gridWidth})`;

  return columns;
};

type Props = {
  journeyCounts: CourseTypeCounts,
  className?: string,
};

// Indexes of columns that can be sorted
const RELEASE_DATE_COL_INDEX = 2;
const CLOSE_DATE_COL_INDEX = 3;

const Dashboard = (props: Props) => {
  const {
    className,
  } = props;

  const dashboardStyle = css`
    height: calc(100vh - 120px); // 120px = top header(60) + tab header(60)
    .${className} {
      border-top: solid 1px ${gray5};
    }
    .nv-expandable-search-bar.expanded {
      width: 360px;
    }
    .clear-btn {
      padding: ${standardSpacing + quarterSpacing}px ${standardSpacing}px ${standardSpacing}px;
      cursor: pointer;
    }
  `;

  const dispatch = useAppDispatch();
  const currentInstitution = useSelector(getCurrentInstitution);
  // Desc sort the Release Date column by default
  const [columnSortings, setColumnSortings] = React.useState<ColumnSortings>({ [RELEASE_DATE_COL_INDEX]: true });
  const getStoreCourseData = (state: RootState) => state.models.courses as CoursesNormalized;
  const [pagedFetchParams, setPagedFetchParams] = React.useState<PagedDataQueryParams>(initialFetchParams);
  const [journeyClonings, setJourneyClonings] = React.useState<Record<string, JourneyCloning[]>>({});
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [showResultsTab, setShowResultsTab] = useState(false);
  const [hideClearSearch, setHideClearSearch] = useState(true);
  const [resultsCount, setResultsCount] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });
  const columns = isHandheld ? createMobileTableColumns() : createTableColumns();

  const rowProps = {
    institution: currentInstitution,
  };
  const fetchParams = {
    institutionId: currentInstitution.id,
  };

  const isRowDisabled = (course: Course) => course.isBeingDeleted;

  const onHeaderSortingClicked = (colIndex: number) => {
    // NOTE: Currently this is hardcoded to only support sorting on columns #2 and #3
    if (colIndex !== RELEASE_DATE_COL_INDEX && colIndex !== CLOSE_DATE_COL_INDEX) {
      return;
    }

    const newSorting = !columnSortings[colIndex];
    let newSortKey: string = null;

    if (colIndex === RELEASE_DATE_COL_INDEX) {
      newSortKey = newSorting ? Sorting.RELEASE_DATE_DESC : Sorting.RELEASE_DATE_ASC;
    } else if (colIndex === CLOSE_DATE_COL_INDEX) {
      newSortKey = newSorting ? Sorting.CLOSE_DATE_DESC : Sorting.CLOSE_DATE_ASC;
    }

    // Don't preserve anything from the previous sorting; we only allow sorting
    // on one column at a time
    setColumnSortings({ [colIndex]: newSorting });
    setPagedFetchParams({
      filters: pagedFetchParams.filters,
      sorting: [newSortKey],
    });
  };

  const getCourses = (params) => getCoursesList({
    ...params,
    journeysOnly: true,
  });

  const journeyCloningsState = useSelector((state: RootState) => state.models.journeyClonings);

  React.useEffect(() => {
    dispatch(getJourneyClonings({ institutionId: currentInstitution.id }));
  }, [currentInstitution.id, dispatch]);

  // Map journeys to their in-progress course clonings
  React.useEffect(() => {
    if (journeyCloningsState) {
      const journeysToClones: Record<string, JourneyCloning[]> = {};
      // Display the clones with the most recent clone requests at the top
      _.sortBy(journeyCloningsState, 'id').reverse().forEach(cloning => {
        if (!journeysToClones[cloning.owner.catalogId]) {
          journeysToClones[cloning.owner.catalogId] = [];
        }
        journeysToClones[cloning.owner.catalogId].push(cloning);
      });
      setJourneyClonings(journeysToClones);
    }
  }, [journeyCloningsState]);

  const [currentTab, setCurrentTab] = useState(Filters.ACTIVE);

  const setTabFilter = (filter: Filters) => {
    let newFilters = {};
    if (filter) {
      newFilters = { [filter]: '1' };
    }
    setCurrentTab(filter);
    setColumnSortings({ [RELEASE_DATE_COL_INDEX]: true });
    setPagedFetchParams({
      filters: newFilters,
      sorting: initialFetchParams.sorting,
      searchQuery: initialFetchParams.searchQuery,
    });
  };

  const tabsInfo = [
    {
      filter: Filters.ACTIVE,
      text: t.COURSES.TABS.TAB_ACTIVE(),
      count: props.journeyCounts.active,
      tooltip: t.COURSES.TABS.TAB_TOOLTIP_ACTIVE(),
      dataQa: config.pendo.learningJourneys.tabs.active,
    },
    {
      filter: Filters.FUTURE,
      text: t.COURSES.TABS.TAB_FUTURE(),
      count: props.journeyCounts.future,
      tooltip: t.COURSES.TABS.TAB_TOOLTIP_FUTURE(),
      dataQa: config.pendo.learningJourneys.tabs.future,
    },
    {
      filter: Filters.PAST,
      text: t.COURSES.TABS.TAB_PAST(),
      count: props.journeyCounts.past,
      tooltip: t.COURSES.TABS.TAB_TOOLTIP_PAST(),
      dataQa: config.pendo.learningJourneys.tabs.past,
    },
    {
      filter: null,
      text: t.COURSES.TABS.TAB_TOTAL(),
      count: props.journeyCounts.total,
      tooltip: t.COURSES.TABS.TAB_TOOLTIP_TOTAL(),
      dataQa: config.pendo.learningJourneys.tabs.total,
    },
  ];

  const getIndexOfFilter = (filter: Filters) => (tabsInfo.findIndex(tab => tab.filter === filter));


  /** NvResponsiveTabRow components */
  /* Nav tabs in the top-left of the table */
  const filterTabs: NvTab[] = tabsInfo.map(tab => ({
    text: tab.text,
    count: tab.count,
    tooltip: tab.tooltip,
    dataQA: tab.dataQa,
    onClick: () => setTabFilter(tab.filter),
  }));

  const resultsTab: NvTab = {
    text: t.COURSES.TABS.TAB_RESULTS(),
    count: resultsCount,
    dataQA: config.pendo.orgDashboard.results,
    onClick: () => { },
  };

  const resetInputText = () => {
    inputRef.current.value = '';
  };

  const updateSearchCourseCounts = (newPagedFetchParams) => {
    dispatch(getSearchCourseCounts({
      institutionId: currentInstitution.id,
      ...newPagedFetchParams,
      handleCountResponse: (count) => {
        setResultsCount(count);
      },
    }));
  };

  const switchToResultsTab = (newPagedFetchParams: PagedDataQueryParams) => {
    updateSearchCourseCounts(newPagedFetchParams);

    setShowResultsTab(true);
  };

  const handleSearch = (query: string) => {
    const newPagedFetchParams = {
      searchQuery: query,
      journeysOnly: true,
    };
    setPagedFetchParams(newPagedFetchParams);

    switchToResultsTab(newPagedFetchParams);
  };

  const clearSearchResults = () => {
    resetInputText();
    setTabFilter(currentTab);
    setShowResultsTab(false);
    setIsSearchOpen(false);
    setHideClearSearch(true);
  };

  const clearBtn = (
    <div
      className='clear-btn page-title-xxs font-weight-normal text-primary'
      onClick={() => clearSearchResults()}
      data-qa={config.pendo.orgDashboard.clearSearch}
    >
      {t.SEARCH.CLEAR()}
    </div>
  );

  const searchBar = (
    <NvExpandableSearchBar
      onSearch={handleSearch}
      isExpanded={isSearchOpen}
      onExpanded={setIsSearchOpen}
      ref={inputRef}
      placeholder={t.LEARNING_JOURNEYS.DASHBOARD.SEARCH_PLACEHOLDER()}
    />
  );

  return (
    <div css={dashboardStyle}>
      <NvResponsiveTabsRow
        style={createGridStyles(1, 2)}
        defaultTabs={filterTabs}
        filteredTabs={[resultsTab]}
        isFiltered={showResultsTab}
        revertActiveTab={getIndexOfFilter(currentTab)}
        tabType={NvResponsiveTabsDisplayType.STACKED_NUMBERED}
        clearBtn={clearBtn}
        showClearBtn={showResultsTab}
        searchBar={searchBar}
        ctaButtonText={t.LEARNING_JOURNEYS.NEW_JOURNEY()}
        ctaUrl={`${window.location.href}new`}
        ctaIconClass='create-new-post'
        ctaDataQa={config.pendo.learningJourneys.newJourneyButton}
      />
      <NvResponsiveTable<Course>
        columns={columns}
        dataKey='catalogId'
        rowProps={rowProps}
        className={className}
        backgroundColor='#fff'
        cacheDataKey='catalogId'
        fetchParams={fetchParams}
        fetchData={getCourses as any}
        style={createGridStyles(1, 3)}
        columnSortings={columnSortings}
        checkRowDisabled={isRowDisabled}
        cacheLookup={getStoreCourseData}
        clearSearch={() => {}}
        hideClearSearch={hideClearSearch}
        pagedFetchParams={pagedFetchParams}
        onSortClicked={onHeaderSortingClicked}
        loadingComponent={LoadingRowWithoutAvatar}
        rowComponent={JourneyRow as React.FunctionComponent}
        extraRowData={journeyClonings}
        extraRowTooltip={() => t.COURSES.DASHBOARD_TABLE.CLONING_IN_PROGRESS_DESCRIPTION()}
        noResultsIconSize='ultra-large'
      />
    </div>
  );
};

export default Dashboard;
