import React, { useContext } from 'react';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';

import { useSelector } from 'react-redux';
import { getCurrentCourse } from 'redux/selectors/course';
import { CommunityCompletionUser } from 'redux/schemas/models/user';

import { isHandheld } from 'styles/global_defaults/media-queries';

import NvIcon from '../nv-icon';
import NvTooltip from '../nv-tooltip';

type CompletionUserPopoverProps = {
  user: CommunityCompletionUser;
  canSendMessage: boolean;
  isSubmission: boolean;
  openSendMessageModal: (user: CommunityCompletionUser) => void,
};

const CompletionUserPopover = (props: CompletionUserPopoverProps) => {
  const { user, canSendMessage, isSubmission, openSendMessageModal } = props;
  const { $state } = useContext(AngularServicesContext);
  const { catalogId } = useSelector(getCurrentCourse);

  const viewSubmission = () => {
    $state.go('individual-submission-modal', {
      catalogId,
      reportId: user.reportId,
    });
  };

  const viewProfile = () => {
    $state.go('learner-profile-modal', {
      catalogId,
      userId: user.id,
    });
  };

  return (
    <React.Fragment>
      <div className='d-flex justify-content-center'>
        {isSubmission && (
          <NvIcon
            className='mx-2'
            size='smallest'
            icon='gallery'
            onClick={viewSubmission}
          />
        )}
        {canSendMessage && (
          <NvTooltip
            text={t.PROFILE.SEND_MESSAGE()}
            enabled={!isHandheld()}
            placement='top'
          >
            <NvIcon
              className='mx-2'
              size='smallest'
              icon='messages'
              onClick={() => {
                openSendMessageModal(user);
              }}
            />
          </NvTooltip>
        )}
        <NvTooltip
          text={t.LECTURE_PAGES.COMPONENTS.PROFILE_COMPLETION.VIEW_PROFILE()}
          enabled={!isHandheld()}
          placement='top'
        >
          <NvIcon
            className='mx-2'
            size='smallest'
            icon='profile'
            onClick={viewProfile}
          />
        </NvTooltip>
      </div>
    </React.Fragment>
  );
};

export default CompletionUserPopover;
