import React from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';
import { gray4, primary } from 'styles/global_defaults/colors';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import NvIcon from 'shared/components/nv-icon';
import NvPopover from 'shared/components/nv-popover';
import NvListWithMore from 'shared/components/nv-list-with-more';
import { findKey } from 'underscore';
import { allDesktop } from 'styles/global_defaults/media-queries';

export enum EnrollmentStatus {
  IN_PROGRESS = 'inProgress',
  ENROLLED = 'enrolled',
  COMPLETED = 'completed',
  INCOMPLETE = 'incomplete',
}

const styles = () => css`
  .status {
    width: ${standardSpacing}px;
    height: ${standardSpacing}px;
  }

  .progress-icon {
    background: linear-gradient(90deg, ${gray4} 50%, ${primary} 50%);

    .icon {
      padding: 2px;
    }
  }

  .enrolled {
    border: dotted 2px ${gray4};
  }

  .completed-icon {
    border: solid 2px;
    padding: 3px;
  }

  .r-space {
    padding-right: ${halfSpacing}px;

    ${allDesktop(css`
        padding-right: ${standardSpacing}px;
    `)}
  }
`;

const getStatusText = (status) => {
  switch (status) {
    case EnrollmentStatus.ENROLLED:
      return t.CUSTOM_DOWNLOADS.DROPDOWN.ENROLLED();
    case EnrollmentStatus.IN_PROGRESS:
    case EnrollmentStatus.COMPLETED:
    case EnrollmentStatus.INCOMPLETE:
      return t.INSTITUTIONS.LICENSES[findKey(EnrollmentStatus, (item) => item === status)]();
    default:
      return '';
  }
};

const MenteeEnrollmentStatus = ({ popupContents }) => {
  const statusPopup = (status) => (
    <React.Fragment>
      { popupContents[status]?.length ? (
        <div className='label'>
          <div className='text-gray-2'>
            {`${popupContents[status].length} ${getStatusText(status)}:`}
          </div>
          <NvListWithMore
            items={popupContents[status]}
            maxLength={5}
            itemComponent={(item) => (
              <div className='pt-2' key={item.catalogId}>
                <span className='text-black'>
                  {item.name}
                </span>
                <span className='text-gray-2'>
                  {` (${item.catalogId})`}
                </span>
              </div>
            )}
            more={(leftCount) => (
              <div className='pt-2 text-gray-2'>
                {t.INSTITUTIONS.ORG_MENTORS.POPOVER_MORE_ITEMS(leftCount)}
              </div>
            )}
          />
        </div>
      ) : '' }
    </React.Fragment>
  );

  const IconWrapper = ({ children, status }) => (
    <React.Fragment>
      {popupContents[status]?.length && (
        <NvPopover
          placement='top'
          showOnHover
          content={statusPopup(status)}
        >
          {children}
        </NvPopover>
      )}
    </React.Fragment>
  );

  return (
    <div className='d-flex' css={styles}>
      {popupContents[EnrollmentStatus.IN_PROGRESS]?.length && (
        <div
          className='d-flex r-space justify-content-center align-items-center'
          aria-label={`${EnrollmentStatus.IN_PROGRESS} ${popupContents[EnrollmentStatus.IN_PROGRESS]?.length}`}
        >
          <IconWrapper status={EnrollmentStatus.IN_PROGRESS}>
            <div className='progress-icon status rounded-circle d-flex justify-content-center align-items-center'>
              <NvIcon
                size='xss-smallest'
                icon='more'
                className='bg-white rounded-circle text-primary'
              />
            </div>
          </IconWrapper>
          <div className='label pl-1 text-black'>
            {popupContents[EnrollmentStatus.IN_PROGRESS]?.length}
          </div>
        </div>
      )}
      {popupContents[EnrollmentStatus.ENROLLED]?.length && (
        <div
          className='d-flex r-space justify-content-center align-items-center'
          aria-label={`${EnrollmentStatus.ENROLLED} ${popupContents[EnrollmentStatus.ENROLLED]?.length}`}
        >
          <IconWrapper status={EnrollmentStatus.ENROLLED}>
            <div className='enrolled status rounded-circle' />
          </IconWrapper>
          <div className='label pl-1 text-black'>
            {popupContents[EnrollmentStatus.ENROLLED]?.length}
          </div>
        </div>
      )}
      {popupContents[EnrollmentStatus.COMPLETED]?.length && (
        <div
          className='d-flex r-space justify-content-center align-items-center'
          aria-label={`${EnrollmentStatus.COMPLETED} ${popupContents[EnrollmentStatus.COMPLETED]?.length}`}
        >
          <IconWrapper status={EnrollmentStatus.COMPLETED}>
            <div className='icon icon-check completed-icon status text-xs text-success rounded-circle' />
          </IconWrapper>
          <div className='label pl-1 text-black'>
            {popupContents[EnrollmentStatus.COMPLETED]?.length}
          </div>
        </div>
      )}
      {popupContents[EnrollmentStatus.INCOMPLETE]?.length && (
        <div
          className='d-flex justify-content-center align-items-center'
          aria-label={`${EnrollmentStatus.INCOMPLETE} ${popupContents[EnrollmentStatus.INCOMPLETE]?.length}`}
        >
          <IconWrapper status={EnrollmentStatus.INCOMPLETE}>
            <NvIcon size='small' icon='ban' className='text-gray-4' />
          </IconWrapper>
          <div className='label pl-1 text-black'>
            {popupContents[EnrollmentStatus.INCOMPLETE]?.length}
          </div>
        </div>
      )}
    </div>
  );
};

export default MenteeEnrollmentStatus;
