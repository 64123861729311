import React, { useContext } from 'react';
import t from 'react-translate';

import ClickableContainer from 'components/clickable-container';
import { config } from '../../../../config/pendo.config.json';
import NvIcon from '../nv-icon';
import { CommentRowContext } from './comment-row';
import { CommentSectionContext } from './comment-section';

const CommentReplies = () => {
  const { comment, parentCommentId, isReply } = useContext(CommentRowContext);
  const { onClickOnReplyIcon, onClickOnReplyCount } = useContext(CommentSectionContext);

  return (
    <div className='d-flex align-items-center'>
      <button
        type='button'
        className='btn-link'
        onClick={() => onClickOnReplyIcon(parentCommentId ? comment.user : null)}
        data-qa={config.pendo.discussionAnywhere.reply}
        aria-label={t.DISCUSSIONS.REPLY()}
      >
        <NvIcon size='smallest' icon='reply' className='gray-3' />
      </button>
      {comment.repliesCount > 0 && (
        <ClickableContainer
          className='replies-count btn-link'
          onClick={() => onClickOnReplyCount(false)}
          // aria-pressed={props.isRepliesExpanded}
        >
          <span className='sr-only'>{t.COURSE_HOME.EXPAND()}</span>
          {comment.repliesCount}
          <span className='sr-only'>{t.DISCUSSIONS.REPLIES()}</span>
        </ClickableContainer>
      )}
    </div>
  );
};

export default CommentReplies;
