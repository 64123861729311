import NvRouter from 'shared/components/nv-router';
import { CollectionDashboard } from './collection-dashboard';
import CollectionHome from './collection-home/collection-home';
import CollectionUserManagement from './user-management/user-management';

const contentRoutes = [
  {
    path: '/collections/',
    component: CollectionDashboard,
    exact: true,
  },
  {
    path: '/collections/:collectionId/:catalogId/home',
    component: CollectionHome,
    exact: true,
  },
  {
    path: '/collections/:collectionId/:catalogId/user-management',
    component: CollectionUserManagement,
    exact: true,
  },
];

const CollectionRoutes = () => (
  <NvRouter
    basename='/#!/content-library'
    routes={contentRoutes}
  />
);

export default CollectionRoutes;
