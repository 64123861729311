import store from 'redux/store';
import { ActivityKey } from 'redux/schemas/models/activity';
import { setActivityProgress } from 'redux/actions/lecture-components';

// no quizzes here
/* @ngInject */
export default function TimelineExerciseLectureComponentService(

  _,
  moment,
  nvUtil,
) {
  const NOT_STARTED = 'not_started';
  const IN_PROGRESS = 'in_progress';
  const COMPLETED = 'completed';
  const MISSED = 'missed';

  const APPROVAL_REQUIRED = 'approval_needed';
  const PENDING_APPROVAL = 'pending';
  const REJECTED = 'rejected';
  const REJECTED_AND_MISSED = 'rejected_and_missed';
  const APPROVED = 'approved';

  class TimelineExerciseLectureComponent {
    constructor(attributes) {
      const base = {
        templateUrl: 'timelines/templates/nv-timeline-lecture-exercise.html',
        canHaveHardDeadline: true,
      };

      /** Setting Up Basic Attributes * */
      _.extend(this, base, attributes);
      // passed lecturePage, lectureSection, course
      this.__preprocess();
    }

    /** Function Declarations * */
    /* Used Publicly */
    getStatus() {
      return this.exercise.progress;
    }

    isNotStarted() {
      return this.exercise.progress === NOT_STARTED;
    }

    isInProgress() {
      return this.exercise.progress === IN_PROGRESS;
    }

    isCompleted() {
      return this.exercise.progress === COMPLETED;
    }

    isApproved() {
      return this.exercise.progress === APPROVED;
    }

    isMissed() {
      return this.exercise.progress === MISSED;
    }

    isNotStartedAndApprovalRequired() {
      return this.exercise.progress === APPROVAL_REQUIRED;
    }

    isPendingApproval() {
      return this.exercise.progress === PENDING_APPROVAL;
    }

    isRejectedCanRevise() {
      return this.exercise.progress === REJECTED;
    }

    isRejectedCannotRevise() {
      return this.exercise.progress === REJECTED_AND_MISSED;
    }


    isTodoRequiredForCompletion() {
      return this.exercise.isRequiredForCompletion;
    }

    updateComponentPointsAndProgress(componentType, componentId, pointsReceived, totalPoints, progress = 'completed') {
      if ((this.type === componentType && this.id === componentId) || (componentType === 'exercise' && this.exercise.id === componentId)) {
        this.exercise.progress = progress;
        store.dispatch(setActivityProgress({
          pointsReceived,
          progress: this.exercise.progress,
          activityKey: ActivityKey.EXERCISES,
          activityPayloadId: this.exercise.id,
        }));

        this.exercise.pointsReceived = pointsReceived;
        this.pointsReceived = pointsReceived;

        if (_.isNumber(totalPoints)) {
          this.currentTotalPoints = totalPoints;
        }
        this.__viewPreprocess();

        this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    updateComponentProgress(componentType, componentId, progress) {
      if ((this.type === componentType && this.id === componentId) || (this.type === 'exercise' && this.exercise.id === componentId)) {
        this.exercise.progress = progress;
        store.dispatch(setActivityProgress({
          progress,
          activityKey: ActivityKey.EXERCISES,
          activityPayloadId: this.exercise.id,
        }));
        this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    isPrereqCompleted(prereq) {
      if (prereq.type === 'Exercise' && prereq.id === this.exercise.id) {
        return this.isCompleted() || this.isApproved();
      }
      return false;
    }

    getPayload() {
      return _.pick(this, ['title', 'deadline', 'hardDeadline']);
    }

    hasDeadline() {
      return !!this.deadline;
    }

    setDeadline(newDeadline) {
      this.deadline = newDeadline;
    }

    showWarning() {
      return this.showWarningforPeerReview() || this.showWarningforTeamFormation();
    }

    showWarningforPeerReview() {
      const releaseMoment = moment(this.lecturePage.releaseDate);
      return (this.exercise.customQuestions && moment(this.exercise.customQuestions.releaseDate) < releaseMoment);
    }

    showWarningforTeamFormation() {
      const releaseMoment = moment(this.lecturePage.releaseDate);
      return (this.exercise.teamSet && releaseMoment < moment(this.exercise.teamSet.releaseDate));
    }

    updateDependentActivitiesReleaseDate(changedLecturePage) {
      if (this.exercise.customQuestions?.lecturePageId === changedLecturePage.id) {
        this.exercise.customQuestions.releaseDate = changedLecturePage.releaseDate;
      }

      if (this.exercise.teamSet?.lecturePageId === changedLecturePage.id) {
        this.exercise.teamSet.releaseDate = changedLecturePage.releaseDate;
      }
    }

    /* Used Privately */
    __preprocess() {
      _.extend(this, _.pick(this.exercise, 'title', 'hardDeadline', 'isTodo', 'hasStructuralIssues',
        'pointsReceived', 'totalPoints', 'scheduledCommunicationsCount'));
      this.deadline = this.exercise.extendedDeadline || this.exercise.deadline;
      this.activityId = this.exercise.id;

      this.isExercise = this.exercise.deliverableType !== 'Team';
      this.showDueSoon = this.__showDueSoon();

      this.currentTotalPoints = nvUtil.getCurrentTotalPoints(this.totalPoints, this.lecturePage.releaseDate, this.course.expiringPointsEnabled);
      this.__viewPreprocess();
    }

    __viewPreprocess() {
      if (this.isCompleted() || this.isApproved() || this.pointsReceived > this.currentTotalPoints) {
        this.currentTotalPoints = this.pointsReceived ? this.pointsReceived : 0;
      }

      this.displayTotalPointsOnly = !this.pointsReceived && this.currentTotalPoints;
    }

    __showDueSoon() {
      if (this.deadline) {
        const deadlineMoment = moment(this.deadline);
        const currentMoment = moment();

        return (this.isNotStarted() || this.isInProgress()
            || this.isNotStartedAndApprovalRequired() || this.isPendingApproval() || this.isRejectedCanRevise())
            && moment(this.lecturePage.releaseDate) < currentMoment
            && deadlineMoment > currentMoment
            && deadlineMoment < moment().add(7, 'days');
      }
      return false;
    }
  }

  return TimelineExerciseLectureComponent;
}
