import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import React, { useState, useRef, useContext } from 'react';
import { getDestinationLecturePage, useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';

import LectureComponentList from './content-area/lecture-component-list';
import LectureContentHeader from './content-area/lecture-content-header-panel';
import { LectureContentLoadingPlaceholder, LecturePageMainPanelContext, getLectureHeaderHeight } from './lecture-page-content';
import { LecturePagePreviewContext } from './lecture-page-preview-modal';

const styles = css`
  flex-grow: 1;
  overflow-y: auto;

  position: relative;

  .header-container {
    margin-top: -40px;
  }
`;

/** Lecture page preview content - copy of lecture page content to display in preview mode */
export const LecturePagePreviewContent = () => {
  const params = useLecturePageParams();

  const mainPanelScrollableRef = useRef<HTMLDivElement>();
  const contentRef = useRef<HTMLDivElement>();
  const { lecturePageId } = useContext(LecturePagePreviewContext);

  const lecturePage = useSelector(state => getDestinationLecturePage(state));
  const isLectureLoaded = useSelector(state => lecturePage && state.app.destinationLecturePage.loaded);
  const isTranslated = useSelector(state => state.app.destinationLecturePage.translated);

  const [isHoverOnHeader, setIsHoverOnHeader] = useState<boolean>(false);

  const usePhotoBkg = !!lecturePage?.coverImageUrl;
  const backgroundColor = usePhotoBkg ? 'linear-gradient(rgba(256,256,256,0.1), rgba(256,256,256,0.1))' : (lecturePage?.viewOptions?.backgroundColor ?? 'white');
  const backgroundImage = usePhotoBkg ? `url("${lecturePage.coverImageUrl}")` : 'unset';

  const lectureContentEditParams = { lecturePage, ...params, lecturePageId };

  if (
    !isLectureLoaded
    || isTranslated
  ) {
    return <LectureContentLoadingPlaceholder />;
  }

  return (
    <div
      css={styles}
      ref={mainPanelScrollableRef}
      className='main-panel-scrollable'
            // The id attribute is used for supporting the left navigation's `Skip to Content` button accessibility.
      id='lecture-page-main-content'
    >
      <LecturePageMainPanelContext.Provider value={mainPanelScrollableRef}>
        <div
          className='d-flex flex-column position-relative header-container'
          style={lecturePage.showTitle ? {
            backgroundColor,
            backgroundImage,
            backgroundPosition: 'center, center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover, cover',
            // Apply the configured foreground color to all text in this top gutter area
            // Note that due to CSS selector specificity we also have to manually set this on the title in LectureContentHeaderPanel
            color: lecturePage.viewOptions?.titleColor ?? 'black',
            minHeight: getLectureHeaderHeight(lecturePage),
          } : {}}
          onMouseEnter={() => setIsHoverOnHeader(true)}
          onMouseLeave={() => setIsHoverOnHeader(false)}
        >
          {lecturePage.showTitle
            && (
              <LectureContentHeader
                {...lectureContentEditParams}
                isHoverOnHeader={isHoverOnHeader}
              />
            )}
        </div>
        <LectureComponentList
          ref={contentRef}
        />
      </LecturePageMainPanelContext.Provider>
    </div>
  );
};


export default LecturePagePreviewContent;
