import { css } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import _ from 'underscore';
import { useFormContext } from 'react-hook-form';
import t from 'react-translate';

// contexts
import { HasLoggedInFilterType } from 'redux/schemas/models/course-communication';

// selectors
import { getCourseAliases } from 'redux/selectors/course';

// components
import NvFormDropdown from 'shared/components/inputs/nv-form-dropdown';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import { NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';

import baseStyles from './base-styles';


export const optionsConflictWithHasNotLoggedIn = [
  'daysActiveChecked',
  'daysInactiveChecked',
  'notCompletedActivityChecked',
  'completedActivityChecked',
  'earnedMoreThanXPointsChecked',
  'earnedLessThanXPointsChecked',
];

const LoggedIn = () => {
  const styles = css`
    &.logged-in {
      ${baseStyles};
    }
  `;
  const aliases = useSelector((state) => getCourseAliases(state));

  const { watch, setValue } = useFormContext();
  const [hasLoggedInChecked = false, hasLoggedIn, hasCompletedCourseChecked = false, hasCompletedCourse] = watch([
    'hasLoggedInChecked', 'hasLoggedIn', 'hasCompletedCourseChecked', 'hasCompletedCourse',
  ]);

  // Disable has not logged in since course start date and course release date
  // options in the dropdown of this filter, if any of the other filters are checked.
  // Or selecting `has completed` in the `completed the courses` options
  const disableHasNotLoggedIn = _.some(watch(optionsConflictWithHasNotLoggedIn))
    || (hasCompletedCourseChecked && hasCompletedCourse?.value);

  useEffect(() => {
    if (hasLoggedInChecked && !hasLoggedIn) {
      setValue('hasLoggedIn', {
        id: HasLoggedInFilterType.HAS_LOGGED_IN,
        type: 'text',
        text: t.COURSE_COMMUNICATIONS.FILTERS.LOGGED_IN.HAS(),
        disabled: false,
        value: HasLoggedInFilterType.HAS_LOGGED_IN,
      }, { shouldValidate: true, shouldDirty: true });
    }
  }, [hasLoggedInChecked, hasLoggedIn, setValue]);

  const items: NvDropdownTextItem[] = [
    {
      id: HasLoggedInFilterType.HAS_LOGGED_IN,
      type: 'text',
      text: t.COURSE_COMMUNICATIONS.FILTERS.LOGGED_IN.HAS(),
      disabled: false,
      value: HasLoggedInFilterType.HAS_LOGGED_IN,
    },
    {
      id: HasLoggedInFilterType.HAS_NOT_SINCE_START_DATE,
      type: 'text',
      text: t.COURSE_COMMUNICATIONS.FILTERS.LOGGED_IN.HAS_NOT_COURSE_DATE(aliases.courseAliases),
      disabled: disableHasNotLoggedIn,
      value: HasLoggedInFilterType.HAS_NOT_SINCE_START_DATE,
    },
    {
      id: HasLoggedInFilterType.HAS_NOT_SINCE_RELEASE_DATE,
      type: 'text',
      text: t.COURSE_COMMUNICATIONS.FILTERS.LOGGED_IN.HAS_NOT_RELEASE_DATE(aliases.courseAliases),
      disabled: disableHasNotLoggedIn,
      value: HasLoggedInFilterType.HAS_NOT_SINCE_RELEASE_DATE,
    },
  ];

  const onCheckboxToggle = (e) => {
    if (e.target.checked
      && (hasLoggedIn?.value === HasLoggedInFilterType.HAS_NOT_SINCE_START_DATE
        || hasLoggedIn?.value === HasLoggedInFilterType.HAS_NOT_SINCE_RELEASE_DATE)) {
      uncheckAllConflictedOptions();
    }
  };

  const onDropdownChange = (val) => {
    if (hasLoggedInChecked
      && (val.value === HasLoggedInFilterType.HAS_NOT_SINCE_START_DATE
        || val.value === HasLoggedInFilterType.HAS_NOT_SINCE_RELEASE_DATE)) {
      uncheckAllConflictedOptions();
    }
  };

  const uncheckAllConflictedOptions = () => {
    optionsConflictWithHasNotLoggedIn.map((option) => setValue(option, false, { shouldValidate: true }));
  };

  return (
    <div css={styles} className={`logged-in ${hasLoggedInChecked ? 'on' : ''}`}>
      <div className='checkbox-wrapper'>
        <NvCheckbox
          withForm
          name='hasLoggedInChecked'
          label={!hasLoggedInChecked ? t.COURSE_COMMUNICATIONS.FILTERS.LOGGED_IN.DEFAULT() : ''}
          onChange={onCheckboxToggle}
          labelClassName='text-large-regular pl-6'
        />
      </div>
      <div className='content'>
        <NvFormDropdown
          name='hasLoggedIn'
          items={items}
          onChange={onDropdownChange}
        />
      </div>
    </div>
  );
};

export default LoggedIn;
