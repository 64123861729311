// This file provides utility functions for dealing with media streams
import { config } from '../../../config/config.json';

export enum MediaSourceType {
  CAMERA_MIC = 'camera-mic', // Camera + Mic
  MIC = 'mic', // MIC
  SCREEN_MIC = 'screen-mic', // Screen + Mic
  SCREEN_CAMERA_MIC = 'screen-camera-mic', // Screen + Camera + Mic
}

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

/**
 * Check if the browser supports capturing of user video or audio
 */
export const hasGetUserMedia = () => !!navigator.mediaDevices?.getUserMedia;

/**
 * Check if the browser supports saving videos/audio as blobs
 * some implementations of Media Recorder aren't complete and don't support functions like pause that we need
 */
export const hasMediaRecorder = () => !!window.MediaRecorder?.prototype?.pause;

/**
 * Check if the browser supports capturing of screen
 * navigator.mediaDevices types are not available and so considering it as any
 * https://github.com/microsoft/TypeScript/issues/33232
 */
export const hasGetDisplayMedia = () => !!((navigator.mediaDevices as any)?.getDisplayMedia || (navigator as any).getDisplayMedia);

/**
 * Requests the mediastream from the browser using predetermined settings
 */
export const requestMediaStream = (mediaSourceType: MediaSourceType) => {
  if (mediaSourceType === MediaSourceType.SCREEN_CAMERA_MIC) {
    return new Promise<any>((resolve, reject) => {
      captureScreen().then((screen) => {
        keepStreamActive(screen, MediaSourceType.SCREEN_CAMERA_MIC);
        captureCamera().then((camera) => {
          addScreenStreamStopListener(screen, () => {
            window.stopScreenStreamCallback?.();
          });

          keepStreamActive(camera, MediaSourceType.CAMERA_MIC);

          resolve([screen, camera]);
        }).catch((error) => {
          stopMediaStreams(screen);
          reject(error);
        });
      }).catch((error) => {
        reject(error);
      });
    });
  }

  if (mediaSourceType === MediaSourceType.SCREEN_MIC) {
    return new Promise<any>((resolve, reject) => {
      captureScreen().then((screen) => {
        addScreenStreamStopListener(screen, () => {
          window.stopScreenStreamCallback?.();
        });
        captureAudio().then((mic) => {
          screen.addTrack(mic.getTracks()[0]);

          resolve(screen);
        }).catch((error) => {
          stopMediaStreams(screen);
          // Passing a type for the error so that we can catch it in component
          error.type = 'audio';
          reject(error);
        });
      }).catch((error) => {
        reject(error);
      });
    });
  }

  switch (mediaSourceType) {
    case MediaSourceType.CAMERA_MIC:
      return captureCamera();
    case MediaSourceType.MIC:
      return navigator.mediaDevices?.getUserMedia({
        audio: true,
      });
    default:
      throw new Error('media type not found');
  }
};

/**
 * Terminates all the video or audio tracks of a mediaStream
 * When we are done with video/audio capture, needs to specifically call this function to stop the stream
 */
export const stopMediaStreams = (mediaStream: MediaStream) => {
  // Stop streams
  if (Array.isArray(mediaStream)) {
    (mediaStream as MediaStream[])?.forEach(stream => stream.getTracks().forEach(track => track.stop()));
  } else {
    mediaStream?.getTracks().forEach(track => track.stop());
  }

  // Remove any keep alive media elements
  Object.values(MediaSourceType).map(source => removeStreamVideo(source));

  // Remove stale mixer canvases
  const staleCanvasesElements = document.querySelectorAll('canvas.multi-streams-mixer');
  if (staleCanvasesElements) {
    Array.from(staleCanvasesElements).map(e => e.remove());
  }
};

/**
 * Tries to get the screen stream and returns the promise
 * @returns Promise<MediaStream>
 */
const captureScreen = () => {
  // Type any because of https://github.com/microsoft/TypeScript/issues/33232
  const display = (navigator.mediaDevices as any).getDisplayMedia
    ? (navigator.mediaDevices as any).getDisplayMedia({
      video: true,
    })
    : (navigator as any).getDisplayMedia({
      video: true,
    });
  return display;
};

/**
 * Tries to get the camera stream and return the promise
 * @returns Promise<MediaStream>
 */
const captureCamera = () => {
  const [width, height] = config.recordings.videoResolution;
  return navigator.mediaDevices?.getUserMedia({
    audio: true,
    video: {
      width,
      height,
    },
  });
};

/**
 * Tries to get the audio stream only
 * @returns Promise<MediaStream>
 */
const captureAudio = () => navigator.mediaDevices?.getUserMedia({
  audio: true,
});

/**
 * To keep the both stream alive, need to inject it to a hidden
 * IMPORTANT: This only keeps one camera or screen active. Also it is used
 * in screen share recording only
 * @param stream MediaStream
 */
const keepStreamActive = (stream: MediaStream, type: MediaSourceType) => {
  // Remove stream if already present
  removeStreamVideo(type);

  const video = document.createElement('video');
  video.id = getStreamVideoId(type);
  video.muted = true;
  video.srcObject = stream;
  video.style.display = 'none';
  (document.body || document.documentElement).appendChild(video);
};

/**
 * Adding listeners to deal when the stop sharing happened
 * @param stream MediaStream
 * @param callback Callback func
 */
const addScreenStreamStopListener = (stream: MediaStream, callback: () => void) => {
  stream.addEventListener('ended', () => {
    callback();
    callback = function () {};
  }, false);
  stream.addEventListener('inactive', () => {
    callback();
    callback = function () {};
  }, false);
  stream.getTracks().forEach((track) => {
    track.addEventListener('ended', () => {
      callback();
      callback = function () {};
    }, false);
    track.addEventListener('inactive', () => {
      callback();
      callback = function () {};
    }, false);
  });
};

const getStreamVideoId = (type: MediaSourceType) => `nv-${type}-stream`;

const removeStreamVideo = (type: MediaSourceType) => {
  const videoId = getStreamVideoId(type);
  const alreadyInjectedElement = document.getElementById(videoId);
  if (alreadyInjectedElement) {
    alreadyInjectedElement.remove();
  }
};
