/* @ngInject */
export default function NvTimeEstimates(
  nvCurrentPage,
) {
  return {
    scope: {
      estimation: '=',
      includeIcon: '=?',
    },
    templateUrl: 'shared/templates/nv-time-estimates.html',
    controller: function ctrl($scope, $element, $attrs) {
'ngInject';
    },
    link(scope, elem, attrs, vm) {
      if (vm.estimation?.estimatedEffort > 0) {
        vm.translateKey = `TIME_ESTIMATES.${vm.estimation.estimatedEffortMeasure.toUpperCase()}_${vm.estimation.estimatedEffortType.toUpperCase()}`;
      }
    },
    controllerAs: 'vm',
    bindToController: true,
  };
}
