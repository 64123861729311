/* @ngInject */
export default function LearnerDirectorySortFilterController(
  LearnerDirectoryManager,
  CurrentCourseManager,
) {
  const vm = this;

  vm.manager = LearnerDirectoryManager;
  vm.currentCourseManager = CurrentCourseManager;

  vm.manager.populateDropdownFilters();
  vm.manager.hideDropdown = false;
}
