/* @ngInject */
export default function AnnouncementModelService(

  _,
  AnnouncementsResources,
  moment,
) {
  const AnnouncementModel = function (attributes) {
    const _this = this;
    const defaultAttributes = {
      title: null,
      description: null,
      hasEmail: false,
      scheduleForLater: false,
      scheduledDate: null,
    };
    _.extend(_this, defaultAttributes, attributes);

    updateDisplayAttributes();

    function updateDisplayAttributes() {
      if (_this.scheduledDate && moment() < moment(_this.scheduledDate)) {
        _this.scheduleForLater = true;
      } else {
        _this.scheduleForLater = false;
      }
    }

    _this.adjustScheduledDateValue = () => {
      if (!_this.scheduleForLater) {
        _this.scheduledDate = null;
      }
    };

    _this.save = () => {
      let promise;

      const payload = {
        id: _this.id,
        title: _this.title,
        description: _this.description,
        addEmail: _this.hasEmail,
        scheduledDate: _this.scheduledDate,
      };

      if (_this.id) {
        promise = AnnouncementsResources.update({ catalogId: _this.catalogId, id: _this.id }, payload).$promise;
      } else {
        promise = AnnouncementsResources.save({ catalogId: _this.catalogId }, payload).$promise;
      }

      return promise.then((resource) => {
        _this.copy(resource.result);
        updateDisplayAttributes();

        return _this;
      });
    };

    _this.delete = () => AnnouncementsResources.delete({ catalogId: _this.catalogId, id: _this.id }).$promise;

    _this.getPreview = () => {
      _this.preview = null;
      const payload = {
        subject: _this.title,
        body: _this.description,
      };

      return AnnouncementsResources.getPreview({ catalogId: _this.catalogId }, payload).$promise
        .then((resource) => {
          _this.preview = resource.result;
        });
    };

    _this.clone = () => {
      const newAnnouncement = new AnnouncementModel();
      _.each(_this, (value, key) => {
        if (!_.isFunction(value)) {
          newAnnouncement[key] = value;
        }
      });

      newAnnouncement.originalAnnouncement = _this;

      return newAnnouncement;
    };

    _this.copy = (fromModel) => {
      _.each(fromModel, (value, key) => {
        if (!_.isFunction(value)) {
          _this[key] = value;
        }
      });

      return _this;
    };

    _this.scheduledForFuture = () => _this.scheduledDate && moment() < moment(_this.scheduledDate);

    _this.hasBeenReleased = () => !!(_this.id && (!_this.scheduledDate || moment(_this.scheduledDate) < moment()));
  };

  AnnouncementModel.getResourse = function (catalogId, includeScheduled) {
    const query = AnnouncementsResources.query({ catalogId, includeScheduled: !!includeScheduled });

    query.$promise = query.$promise.then((resource) => _.map(resource.result, (announcement) => new AnnouncementModel(_.extend(announcement, { catalogId }))));

    return query;
  };

  AnnouncementModel.get = function (catalogId, includeScheduled) {
    return AnnouncementModel.getResourse(catalogId, includeScheduled).$promise;
  };

  AnnouncementModel.new = function (catalogId) {
    return new AnnouncementModel({ catalogId });
  };

  return AnnouncementModel;
}
