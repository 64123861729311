/* @ngInject */
export default function NvRecentActivity(
  moment,
  CurrentCourseManager,
) {
  return {
    restrict: 'A',
    scope: {
      recentActivityData: '=',
      includeActivityLabel: '=',
    },
    controller: function ctrl() {
'ngInject';
      const vm = this;
      vm.CurrentCourseManager = CurrentCourseManager;

      vm.lastUpdatedTranslateKey = function () {
        const lastUpdatedMoment = moment(this.recentActivityData.happenedAt);
        const currentMoment = moment();

        const { activityType, actionName, activityDeliverableType } = this.recentActivityData;
        let transKey;

        if (activityType === 'Exercise' && actionName === 'evaluate') {
          transKey = 'RECENT_ACTIVITY.FEEDBACK';
        } else if (activityType === 'Exercise') {
          transKey = 'RECENT_ACTIVITY.ASSIGNMENT';
        } else if (activityType === 'Quiz' || activityType === 'ProgressiveQuiz') {
          transKey = 'RECENT_ACTIVITY.QUIZ';
        } else if (activityType === 'LectureVideo' && actionName === 'listen') {
          transKey = 'RECENT_ACTIVITY.LECTURE_AUDIO';
        } else if (activityType === 'LectureVideo' && actionName === 'watch') {
          transKey = 'RECENT_ACTIVITY.LECTURE_VIDEO';
        } else if (activityType === 'Survey') {
          transKey = 'RECENT_ACTIVITY.SURVEY';
        } else if (activityType === 'Team') {
          transKey = 'RECENT_ACTIVITY.WORKSPACE';
        } else if (actionName === 'view' && activityType === 'UserCourse') {
          transKey = 'RECENT_ACTIVITY.MENTEES';
        } else if (actionName === 'view') {
          transKey = 'RECENT_ACTIVITY.VIEW';
        } else if (activityType === 'CustomQuestions') {
          transKey = 'RECENT_ACTIVITY.FEEDBACK';
        } else if (activityType === 'Poll') {
          transKey = 'RECENT_ACTIVITY.POLL';
        } else {
          transKey = 'RECENT_ACTIVITY.DISCUSSION';
        }

        // Append minutes/hours/days ago to translation key
        if (currentMoment.diff(lastUpdatedMoment, 'days') > 0) {
          transKey = `${transKey}.DAYS_AGO`;
        } else if (currentMoment.diff(lastUpdatedMoment, 'hours') > 0) {
          transKey = `${transKey}.HOURS_AGO`;
        } else if (currentMoment.diff(lastUpdatedMoment, 'minutes') > 0) {
          transKey = `${transKey}.MINUTES_AGO`;
        } else {
          transKey = `${transKey}.LESS_THAN_MINUTE_AGO`;
        }

        return transKey;
      };


      vm.lastUpdatedTranslateValue = function () {
        const lastUpdatedMoment = moment(vm.recentActivityData.happenedAt);
        const currentMoment = moment();

        if (currentMoment.diff(lastUpdatedMoment, 'days') > 0) {
          return currentMoment.diff(lastUpdatedMoment, 'days');
        } if (currentMoment.diff(lastUpdatedMoment, 'hours') > 0) {
          return currentMoment.diff(lastUpdatedMoment, 'hours');
        } if (currentMoment.diff(lastUpdatedMoment, 'minutes') > 0) {
          return currentMoment.diff(lastUpdatedMoment, 'minutes');
        }
        return null;
      };
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'shared/templates/nv-recent-activity.html',
  };
}
