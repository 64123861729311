import React from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import t from 'react-translate';
import { Course } from 'redux/schemas/models/course';
import { black } from 'styles/global_defaults/colors';
import { openSans } from 'styles/global_defaults/fonts';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import EntitlementBadge from 'offerings/components/entitlement-badge';

type Props = {
  journey: Course,
  className?: string,
};

const OfferedToSection = (props: Props) => {
  const { journey, className } = props;
  const currentInstitution = useSelector(getCurrentInstitution);

  const styles = css`
    text-align: center;

    .header {
      color: ${black};
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      font-family: ${openSans}px;
    }

    .entitlement-values {
      line-height: 35px;

      & > * {
        vertical-align: bottom;
      }
    }
  `;

  const entitlementsToRender = journey.entitlements.filter(
    (entitlement) => {
      const currentProfileSetting = currentInstitution.profileSettings.orgLevel.find(
        (profileSetting) => profileSetting.id === entitlement.profileSettingId,
      );

      if (currentProfileSetting) {
        return !currentProfileSetting.isHidden;
      }

      return false;
    },
  );

  const hasEntitlements = !!entitlementsToRender.length;

  if (hasEntitlements) {
    return (
      <div css={styles} className={className}>
        <div className='header'>{t.LEARNING_JOURNEYS.DETAILS.OFFERED_TO()}</div>
        <div className='entitlement-values'>
          {entitlementsToRender.reduce(
            (acc, curr) => acc.concat(curr.values.map((value, index) => ({
              value,
              key: `${curr.id}-${index}-${value}`,
            }))),
            [],
          ).map((entitlementBadge) => (
            <EntitlementBadge
              key={entitlementBadge.key}
              value={entitlementBadge.value}
            />
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export default OfferedToSection;
