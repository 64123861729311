import React from 'react';
import moment from 'moment';
import t from 'react-translate';

// Form and validations
import { FieldError, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Reused components
import { Button } from 'react-bootstrap';
import NvDatePicker, { DatePickerType } from 'shared/components/inputs/nv-datepicker';
import NvRadioButton from 'shared/components/inputs/nv-radio-button';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvIcon from 'shared/components/nv-icon';
import NvFormDropdown from 'shared/components/inputs/nv-form-dropdown';

// Styles
import { css } from '@emotion/react';
import { black, gray2, gray7 } from 'styles/global_defaults/colors';
import {
  boldFontWeight,
  semiBoldFontWeight,
  textSmallFontSize,
  textMediumFontSize,
  textExtraLargeFontSize,
  textSmallLineHeight,
  textMediumLineHeight,
} from 'styles/global_defaults/fonts';
import { standardSpacing, doubleSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';

// Types
import { Course } from 'redux/schemas/models/course';
import { NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';
import { AlertMessageType } from 'redux/schemas/app/alert-message';

// Actions
import { useAppDispatch } from 'redux/store';
import { doRequestJourneyInformation } from 'redux/actions/license';
import { addAlertMessage } from 'redux/actions/alert-messages';


export enum CourseStartDatesTypes {
  AUTOMATICALLY = 'automatically',
  MANUALLY = 'manually',
}

export enum CopyAudioVideoTypes {
  SHARED = 'shared',
  COPY = 'copy',
}

export type JourneyInformationProps = {
  name: string;
  catalogId: string;
  releaseDate: string;
  courseStartDates: string;
  copyAudioVideo: any;
  bulkDate?: string;
};

type Props = {
  journey: Course,
  onSuccess: (journey: JourneyInformationProps) => void,
  config?: JourneyInformationProps,
};

export const JourneyInformationCloneModal = (props: Props) => {
  const { journey, config, onSuccess } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isCatalogIdTaken, setIsCatalogIdTaken] = React.useState<boolean>(false);
  const [copyAudioVideoItem, setCopyAudioVideoItem] = React.useState();
  const isMountedRef = React.useRef(false);
  const dispatch = useAppDispatch();
  const error = isCatalogIdTaken ? { message: t.COURSES.FORM.ERRORS.CATALOG_ID_TAKEN() } as FieldError : undefined;

  const dropdownItems: NvDropdownTextItem[] = [{
    type: 'text',
    id: CopyAudioVideoTypes.SHARED,
    text: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.SHARING_RESOURCES.OPTION_1.LABEL(),
    description: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.SHARING_RESOURCES.OPTION_1.PLACEHOLDER(),
    textClassName: 'dropdown-item-title mb-1',
    descriptionClass: 'dropdown-item-description',
    dataQa: 'journey-share-audio-video',
  }, {
    type: 'text',
    id: CopyAudioVideoTypes.COPY,
    text: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.SHARING_RESOURCES.OPTION_2.LABEL(),
    description: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.SHARING_RESOURCES.OPTION_2.PLACEHOLDER(),
    textClassName: 'dropdown-item-title mb-1',
    descriptionClass: 'dropdown-item-description',
    dataQa: 'journey-copy-audio-video',
  }];

  const validationSchema = yup.object().shape({
    catalogId: yup.string()
      .min(1, t.VALIDATION.REQUIRED())
      .max(190, t.VALIDATION.MAX_LENGTH('190'))
      .novoEdCatalogId(t.COURSES.FORM.VALIDATION_TEXT.CATALOG_ID_PATTERN()),
    name: yup.string()
      .min(1, t.VALIDATION.REQUIRED())
      .max(255, t.VALIDATION.MAX_LENGTH('255')),
    releaseDate: yup.string()
      .required(t.VALIDATION.REQUIRED()),
    courseStartDates: yup.string()
      .required(t.VALIDATION.REQUIRED()),
    copyAudioVideo: yup.object().shape({
      id: yup.string().required(t.VALIDATION.REQUIRED()),
    }),
  });

  const methods = useForm<JourneyInformationProps>({
    mode: 'onChange',
    shouldUnregister: true,
    defaultValues: {
      name: config?.name || journey.name,
      catalogId: config?.catalogId || '',
      releaseDate: config?.releaseDate || '',
      courseStartDates: config?.courseStartDates || CourseStartDatesTypes.AUTOMATICALLY,
      copyAudioVideo: config?.copyAudioVideo || dropdownItems[0],
    },
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, formState, watch } = methods;
  const [courseStartDates, releaseDate] = watch(['courseStartDates', 'releaseDate']);
  const copyAudioVideo: any = watch('copyAudioVideo');

  /** Submits a journey cloning request, sending a callback to update local UI state */
  const onSubmit = (formFields) => {
    const { catalogId } = formFields;
    setLoading(true);
    dispatch(doRequestJourneyInformation({ catalogId })).then(result => {
      if (result.payload) {
        const { available } = result.payload;
        if (available) {
          const journeyConfig = { ...formFields, copyAudioVideo: copyAudioVideoItem };
          onSuccess(journeyConfig);
        } else {
          setIsCatalogIdTaken(true);
        }
      }
      if (isMountedRef.current) setLoading(false);
    });
  };

  const styles = css`
    .gray-box {
      padding: ${standardSpacing}px ${doubleSpacing}px;
      background: ${gray7};
    }

    .course-title-regular {
      display: flex;
      gap: ${halfSpacing}px;
    }

    .foot-note {
      font-weight: ${semiBoldFontWeight};
      font-size: ${textSmallFontSize}px;
      line-height: ${textSmallLineHeight}px;
      color: ${gray2}
    }

    .form-container {
      margin: 0 ${doubleSpacing}px;

      .catalog-id-description {
        height: ${standardSpacing}px;
      }

      .submit-button {
        margin: 0 auto;
        margin-top: ${standardSpacing * 3}px;
        margin-bottom: ${standardSpacing}px;
      }

      .popover-wrapper {
        width: 100%;
      }

      .start-date {
        width: 100%;
      }

      .default-value {
        font-weight: ${boldFontWeight};
      }

      .no-padding-left {
        padding-left: 0;
      }

      .radio-button {
        label {
          font-size: ${textMediumFontSize}px;
        }
      }

      .bs4-row {
        .bs4-col {
          max-width: 50%;
          label {
            color: ${gray2};
          }
        }
      }

      .bs4-dropdown-menu {
        min-width: 100% !important;
        padding: ${standardSpacing}px;

        .bs4-dropdown-item {
          padding: 5px 0;

          .dropdown-item-title {
            display: flex;
            flex-direction: column;
            font-size: ${textMediumFontSize}px;
            line-height: ${textMediumLineHeight}px;
            white-space: pre-wrap;
            color: ${black};
          }
          .dropdown-item-description {
            font-weight: ${semiBoldFontWeight};
            font-size: ${textSmallFontSize}px;
            line-height: ${textSmallLineHeight}px;
            white-space: pre-wrap;
            padding-top: 5px;
            color: ${gray2};
          }
        }
      }

    }
  `;

  React.useEffect(() => {
    setCopyAudioVideoItem(copyAudioVideo);
  }, [copyAudioVideo]);

  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <div css={styles}>
      <p className='mb-4 course-title-regular'>
        <NvIcon icon='path' size='medium-large' className='d-flex align-items-center' />
        <span data-qa='journey-information-clone-modal'>{ t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.SUBTITLE() }</span>
      </p>
      <p className='mb-4 gray-box'>{ t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.DESCRIPTION() }</p>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='form-container'>
            <div className='bs4-row mb-4'>
              <div className='bs4-col'>
                <label htmlFor='name'>
                  {t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.NAME()}:
                </label>
                <div className='default-value'>{journey.name}</div>
              </div>
              <NvTextInput
                withForm
                withLabel
                autoComplete='off'
                name='name'
                ariaLabel='Name'
                className='bs4-col no-padding-left'
                placeholder={t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.NAME_PLACEHOLDER()}
                data-qa='journey-name'
                required
              />
            </div>
            <div className='bs4-row mb-4'>
              <div className='bs4-col'>
                <label htmlFor='catalogId'>
                  {t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.CATALOG_ID()}:
                </label>
                <div className='default-value'>{journey.catalogId}</div>
              </div>
              <NvTextInput
                withForm
                withLabel
                autoComplete='off'
                name='catalogId'
                ariaLabel='Catalog ID'
                className='bs4-col no-padding-left'
                placeholder={t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.CATALOG_ID_PLACEHOLDER()}
                data-qa='journey-catalog-id'
                error={error}
                onChange={() => setIsCatalogIdTaken(false)}
                required
              />
            </div>
            <div className='bs4-row mb-4'>
              <div className='bs4-col'>
                <label htmlFor='releaseDate'>
                  {t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.RELEASE_DATE()}:
                </label>
                <div className='default-value'>{journey.releaseDate ? moment(journey.releaseDate).format('L LT') : ''}</div>
              </div>
              <NvDatePicker
                withForm
                withLabel
                required
                fixed
                placement='left'
                name='releaseDate'
                type={DatePickerType.DATETIME}
                className='bs4-col start-date no-padding-left'
                placeholder={t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.RELEASE_DATE_PLACEHOLDER()}
                dataQa='journey-release-date'
              />
            </div>
            <div className='bs4-row mb-4'>
              <div className='bs4-col'>
                <div className='default-value mt-3'>
                  {t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.COURSE_START_DATES.LABEL()}
                </div>
              </div>
              <div className='bs4-col radio-button no-padding-left'>
                <NvRadioButton
                  withForm
                  required
                  value={CourseStartDatesTypes.AUTOMATICALLY}
                  name='courseStartDates'
                  checked={courseStartDates === CourseStartDatesTypes.AUTOMATICALLY}
                  label={t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.COURSE_START_DATES.OPTION_1()}
                  data-qa='journey-start-date-automatically'
                />
                <NvRadioButton
                  withForm
                  required
                  value={CourseStartDatesTypes.MANUALLY}
                  checked={courseStartDates === CourseStartDatesTypes.MANUALLY}
                  name='courseStartDates'
                  label={t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.COURSE_START_DATES.OPTION_2()}
                  data-qa='journey-start-date-manually'
                />
              </div>
            </div>
            <div className='bs4-row mb-1'>
              <div className='bs4-col'>
                <div className='default-value mt-3 mb-3'>
                  {t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.SHARING_RESOURCES.LABEL()}
                </div>
              </div>
              <div className='bs4-col radio-button no-padding-left d-flex align-items-center'>
                <NvFormDropdown
                  name='copyAudioVideo'
                  items={dropdownItems}
                  maxWidth100
                />
              </div>
            </div>
            <div className='bs4-row mb-4'>
              <div className='bs4-col foot-note'>{t.LEARNING_JOURNEYS.CLONE.NOTE()}</div>
            </div>
            <div className='bs4-row'>
              <Button
                className='submit-button'
                disabled={!formState.isValid || loading}
                data-qa='next'
                type='submit'
              >
                {t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.NEXT()}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default JourneyInformationCloneModal;
