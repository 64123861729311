import { MockDefinition, MockTypes } from '../axios/axios-mocks';

const SampleMocks: MockDefinition[] = [
  {
    type: MockTypes.GET,
    url: '/exampleget.json',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: { active: 450, future: 350, past: 40, demo: 50, total: 5 },
    },
  },
  {
    type: MockTypes.POST,
    url: '/examplepost.json',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: { message: 'OK' },
    },
  },

];
export default SampleMocks;
