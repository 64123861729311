import { EnrollmentPeriod, ExportTypes } from 'redux/schemas/models/license';

/* @ngInject */
export default function DataExportsModalController(
  moment,
  humps,
  $translate,
  InstitutionsManager,
  PusherManager,
  institutionId,
  periodType,
  exportFileList,
) {
  const vm = this;

  vm.moment = moment;
  vm.humps = humps;
  vm.$translate = $translate;
  vm.InstitutionsManager = InstitutionsManager;
  vm.PusherManager = PusherManager;
  vm.institutionId = institutionId;
  vm.periodType = periodType;
  vm.exportFileList = exportFileList;
  vm.ExportTypes = ExportTypes;
  vm.EnrollmentPeriod = EnrollmentPeriod;

  const pusherChannel = PusherManager.setupChannel(`public-institution-${this.institutionId}`);
  pusherChannel.bind('institution_report_event_name', (file) => {
    this.exportFileList.unshift(humps.camelizeKeys(file));
  });

  vm.getHeader = () => {
    let header = 'INSTITUTIONS.LICENSES.DASHBOARD.DATA_EXPORTS_MODAL.';

    switch (this.periodType) {
      case EnrollmentPeriod.PAST_12_MONTHS:
        header += 'FROM_PAST_MONTHS';
        return this.$translate.instant(header, { monthCount: 12 });
      case EnrollmentPeriod.PAST_6_MONTHS:
        header += 'FROM_PAST_MONTHS';
        return this.$translate.instant(header, { monthCount: 6 });
      case EnrollmentPeriod.PAST_30_DAYS:
        header += 'FROM_PAST_DAYS';
        return this.$translate.instant(header, { dayCount: 30 });
      case EnrollmentPeriod.CURRENT_CONTRACT_PERIOD:
        header += 'CURRENT_CONTRACT_PERIOD';
        return this.$translate.instant(header);
      case EnrollmentPeriod.SINCE_ORG_CREATION:
        header += 'ALL_DATA_SINCE';
        return this.$translate.instant(header);
      case EnrollmentPeriod.CUSTOM_DATE_RANGE:
        header += 'WITHIN_A_DATE_RANGE';
        return this.$translate.instant(header);
      default:
        return '';
    }
  };
}
