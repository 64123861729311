import { last } from 'underscore';

import { config } from '../../../config/config.json';

export const getFileTypeFromName = (fileName: string): string => {
  if (fileName.includes('.')) {
    const fileNameExtension = `.${last(fileName.split('.'))}`;
    return config.files.extensionToMimeType[fileNameExtension];
  }

  return 'application/octet-stream';
};

export const S3NameSpaces = {
  ATTACHMENTS: 'attachments/original',
  VIDEOS: 'videos/original',
  REPORTS: 'report_sections/original',
  INSTITUTIONS: 'institutions/original',
  TEAM_WORKSPACE: 'team_documents/',
  CARD_VIEW_IMAGES: 'card_view_images/original',
  COVER_IMAGES: 'cover_images/original',
  CONTENT_TEMPLATES: 'lecture_components/pictures',
  VIDEO_PRACTICE: 'attachments/original',
  QUIZ_QUESTIONS: 'questions/pictures',
  // This is expecting the institution id at the end, thus the trailing slash
  SKILL_TAGS_CSV: 'data_requests/skill_tags_data_request/Institution/',
};

/** @ngInject */
/* @ngInject */
export default function s3NameSpaces(app) {
  app
    .constant('S3NameSpaces', S3NameSpaces)
    /*
    Assumptions:
      1. single file
      2. make a backend call to get information
    */
    .factory('S3UploadFactory', ['$http', '$q', 'Upload', 'S3UploadWrapper', 'InstitutionsManager', 'PusherManager', 'nvUtil', 'humps', (
      $http,
      $q,
      Upload,
      S3UploadWrapper,
      InstitutionsManager,
      PusherManager,
      nvUtil,
      humps,
    ) => {
      function setupResolutions(upload, deferred, fileObject) {
        // NOV-67687 Indirect file upload to wait for pusher event during failure
        // This is an additional fallback mechanism to catch failed upload ( >= 2GB )
        // Here we are uploading directly to novoed servers and then move to s3. So upload request may timeout while
        // we move the file to S3 due to no-activity.
        // Here we have enabled an additional file upload notification via pusher and return the
        // file upload success promise when we receive the pusher notification
        // A random generated token is added to match and resolve respective callbacks
        let pusherHandler;
        if (InstitutionsManager.institution?.directUpload) {
          pusherHandler = PusherManager.currentUserChannel().bind('file_uploaded', (data) => {
            data = humps.camelizeKeys(data);
            if (data.token && fileObject.token && data.token === fileObject.token) {
              if (data.uniqueId) {
                fileObject.uniqueId = data.uniqueId;
                const uploadResponse = {
                  config: {
                    data: {
                      file: fileObject,
                    },
                    fields: {
                      file: fileObject,
                    },
                  },
                  data,
                };
                deferred.resolve(uploadResponse);
                PusherManager.currentUserChannel().unbind('file_uploaded', pusherHandler);
              }
            }
          });
        }

        upload.then((uploadResponse) => {
          // Normalizing Data between s3 upload & NovoEd upload
          // normal json puts data in "data"
          // html form puts data in "fields"
          // also need to put uniqueId on file - downstream functions expect it
          if (InstitutionsManager.institution?.directUpload) {
            const { file } = uploadResponse.config.fields;

            if (uploadResponse.config.fields.file) {
              file.uniqueId = uploadResponse.data.uniqueId;
            }

            uploadResponse.config.data = uploadResponse.config.fields;

            if (pusherHandler) {
              PusherManager.currentUserChannel().unbind('file_uploaded', pusherHandler);
            }
          }

          deferred.resolve(uploadResponse);

          return uploadResponse;
        }, (uploadResponse) => {
          if (!InstitutionsManager?.institution?.directUpload) {
            deferred.reject(uploadResponse);
          }
          return uploadResponse;
        }, (evt) => {
          deferred.notify(evt);

          return evt;
        });
      }

      /*
        Inputs:
          file: html file
          nameSpace: string of the path in s3 e.g. 'attachments/original'
        Output:
          promise
            can be used in the following way:
              upload.then(function(resp) {
                // file is uploaded successfully
                console.log('file ' + resp.config.data.file.name + 'is uploaded successfully. Response: ' + resp.data);
              }, function(resp) {
                // handle error
              }, function(evt) {
                // progress notify
                console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :'+ evt.config.data.file.name);
              });
            can also abort the upload
              upload.abort();
      */
      const S3UploadFactory = {
        uploadToS3(file, nameSpace, useS3Wrapper = false) {
          const deferred = $q.defer();
          let upload;

          file.typeFromExtension = getFileTypeFromName(file.name);

          if (InstitutionsManager.institution?.directUpload) {
            const token = nvUtil.randomString(10);
            upload = Upload.upload({
              url: '/s3_file_upload/upload_file',
              method: 'POST',
              fields: {
                s3_namespace: nameSpace,
                file,
                token,
              },
            });
            file.token = token;
            setupResolutions(upload, deferred, file);
          } else {
            $http.put(
              '/s3_direct_upload/upload_fields.json',
              {
                fileName: file.name,
                s3Namespace: nameSpace,
              },
            ).then((response) => {
              const uploadFields = response.data;
              file.uniqueId = uploadFields.uniqueId;
              file.url = uploadFields.url;
              file.googleViewUrl = uploadFields.googleViewUrl;
              upload = Upload.upload({
                url: `${config.s3.protocol}://s3.amazonaws.com/${config.s3.bucket}/`,
                method: 'POST',
                data: {
                  key: uploadFields.key,
                  AWSAccessKeyId: uploadFields.aWSAccessKeyId,
                  acl: uploadFields.acl,
                  policy: uploadFields.policy,
                  signature: uploadFields.signature,
                  'Content-Type': file.type,
                  'X-Requested-With': uploadFields.xRequestedWith,
                  utf8: '✓',
                  /* eslint-disable camelcase */
                  success_action_status: uploadFields.successActionStatus,
                  file,
                },
              });

              setupResolutions(upload, deferred, file);
            }, (data, status, headers, uploadConfig) => {
              deferred.reject(status);
            });
          }

          if (useS3Wrapper) {
            return new S3UploadWrapper(file, deferred.promise, () => { upload.abort(); });
          }
          return deferred.promise;
        },
      };

      return S3UploadFactory;
    }]);
}
