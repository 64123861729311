import { MyAccount } from 'redux/schemas/models/my-account';
import { getCurrentUser } from 'redux/selectors/users';
import { useDispatch, useSelector } from 'react-redux';
import { updateRecentlyUsedColors } from 'redux/actions/users';
import { RootState } from 'redux/schemas';
import {
  danger,
  gray2,
  gray3,
  gray4,
  gray6,
  primary,
  teal,
  black,
  white,
  gray1,
} from 'styles/global_defaults/colors';
import { LecturePage, NLecturePage } from 'redux/schemas/models/lecture-page';

export const COLOR_LIST_LIMIT = 14;

interface useUserColorsParams {
  currentLecture: NLecturePage;
}

/**
 * handles common logic for manipulating recently used colors and institution
 * brand colors, etc.
 */
/* @ngInject */
export default function useUserColors(params: useUserColorsParams) {
  const dispatch = useDispatch();
  const currentUser = useSelector<RootState, MyAccount>(getCurrentUser);
  const mainColorsObject = useSelector((state) => state.models.institutions[state.app.currentInstitutionId].orgColors.mainColors);
  const defaultColorPalette = Object.values(mainColorsObject);
  const { recentlyUsedColors } = currentUser;

  // TODO: I think we can just get the current course data here to avoid passing down currentLecture
  // create an array of brand color and filter nullish values
  const brandColors: string[] = [
    (params.currentLecture as any)?.course?.institution.brandColor,
    (params.currentLecture as any)?.course?.headerColor,
  ].filter(Boolean);

  function updateRecentlyUsedColorsIfNeeded(color: string, preserveOrder?: boolean) {
    // if the colors is not in the predefined palette and brand colors
    if (!defaultColorPalette.includes(color) && !brandColors.includes(color)) {
      /** Avoids resaving & reordering the palette if the color is already present */
      if (preserveOrder && recentlyUsedColors.indexOf(color) !== -1) {
        return;
      }

      const newColorList = [
        color,
        // remove existing occurrences of the color
        ...recentlyUsedColors.filter((currentColor) => currentColor.toLowerCase() !== color.toLowerCase()),
      ].slice(0, COLOR_LIST_LIMIT);

      dispatch(updateRecentlyUsedColors(newColorList));
    }
  }

  return {
    updateRecentlyUsedColorsIfNeeded,
    brandColors,
    recentlyUsedColors,
  };
}
