import createCachedSelector from 're-reselect';
import { useSelector } from 'react-redux';
import { ModelsState, RootState } from 'redux/schemas';
import { NormalizedTimeline } from 'redux/schemas/models/timeline';
import _, { each } from 'underscore';
import { ActivityType } from 'redux/schemas/models/activity';
import { NLecturePage } from 'redux/schemas/models/lecture-page';
import { CourseOutlineItem } from './course-outline-item';
import { getActivityIdsFromLecturePageId } from '../timeline';

type OutlineLecturePages = { [id: string]: NLecturePage };

/** Creates a flat description of a course's sections, subsections, and lesson pages the correct
 order per how they they should appear in the LHS timeline navigation. Also used by the move-to
 component submenu and other UIs that show an "outline" of the course. */
const computeOutline = (
  lectureSections: ModelsState['lectureSections'],
  // Note that this param currently causes this function to re-fire every time we change a lecture page.
  // This is because we load more lecture page data, thus changing this part of our model state
  lecturePages: OutlineLecturePages,
  timeline: NormalizedTimeline,
) => {
  const outline: CourseOutlineItem[] = [];

  if (!timeline) {
    return [];
  }

  const createSectionLecturePageItems = (sectionId: number, sectionType: 'section' | 'subsection') => {
    const items = [];
    const section = lectureSections[sectionId];
    let lecturesAreReleased = false;

    section.lecturePages.forEach(lectureId => {
      const lecturePage = lecturePages[lectureId];

      items.push({
        type: 'lecture',
        name: lecturePage.title,
        id: lectureId,
        section: sectionId,
        sectionType,
        isLinked: lecturePage.isLinked,
      });

      lecturesAreReleased = lecturesAreReleased || lecturePage.released;
    });

    return {
      items,
      lecturesAreReleased,
    };
  };

  const addSectionToOutline = (sectionId: number, type: 'section' | 'subsection') => {
    const lectureSection = lectureSections[sectionId];

    const lecturePageItems = createSectionLecturePageItems(sectionId, type);
    const sectionOutlineItem = {
      type,
      name: lectureSection.title,
      id: sectionId,
      lecturesAreReleased: lecturePageItems.lecturesAreReleased,
    };

    outline.push(sectionOutlineItem);
    outline.push(...lecturePageItems.items);

    return sectionOutlineItem;
  };

  timeline.lectureSubsections.forEach(lssId => addSectionToOutline(lssId, 'subsection'));

  timeline.lectureSections.forEach(lId => {
    const sectionOutlineItem = addSectionToOutline(lId, 'section');
    lectureSections[lId].lectureSubsections.forEach(lssId => {
      const subsectionOutlineItem = addSectionToOutline(lssId, 'subsection');
      // This tracks sections who have any descendants who are lectures that are released. Learners should only see & interact with sections/subsections/lessons
      // that are released or who have released descendants.
      sectionOutlineItem.lecturesAreReleased = sectionOutlineItem.lecturesAreReleased || subsectionOutlineItem.lecturesAreReleased;
    });
  });

  return outline;
};

const getTimeline = (state: RootState, catalogId: string) => state.models.timelines[catalogId];
const getLecturePages = (state: RootState) => state.models.lecturePages;
const getLectureSections = (state: RootState) => state.models.lectureSections;


/** Creates a selector to compute & store the course outline created via computeOutline(). This is achieved
 * via the re-reselect library as one of the input parameters (catalogId) is a non-Redux state value, which
 * necessitates reselect using multiple caches (see https://github.com/toomuchdesign/re-reselect#re-reselect for
 * more info) */
const courseOutlineSelector = createCachedSelector(
  getLecturePages,
  getTimeline,
  getLectureSections,
  (lecturePages, timeline, lectureSections) => computeOutline(lectureSections, lecturePages, timeline),
)(
  (state, catalogId) => catalogId,
);

export type CourseOutline = CourseOutlineItem[];

/** Calculates and returns an object describing the "outline" (sometimes referred to as a "timeline")for this course.
 * This is a computed version of the data returned by /course_timeline.json and is the primary way
 * that we describe the ordering of sections, subsections, & lectures in our application. */
export const useCourseOutline = (catalogId: string): CourseOutline => useSelector(state => courseOutlineSelector(state, catalogId));
