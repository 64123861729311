import { useAngularPreventLecturePageNavigation } from '../../lecture_pages/hooks/use-prevent-lecture-page-navigation';

/* @ngInject */
export default function QuizFormController(
  $scope,
  $uibModal,
  $state,
  $stateParams,
  $q,
  _,
  StateManager,
  CurrentUserManager,
  CurrentCourseManager,
  ExercisesManager,
  QuizzesManager,
  ConfirmationOverlays,
  AlertMessages,
  nvUtil,
  TimelinesManager,
  ReactTimelineService,
) {
  let getQuizPromise = null;
  const vm = this;

  vm.ExercisesManager = ExercisesManager;
  vm.manager = QuizzesManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.$stateParams = $stateParams;
  vm.nvUtil = nvUtil;

  vm.quizForm = null;
  vm.deregisterStateChangeStart = null;

  if (ExercisesManager.currentExercise.prerequisite && !CurrentUserManager.isAdmin()) {
    $state.go('exercise-show', { catalogId: $stateParams.catalogId, exerciseId: $stateParams.exerciseId });
    AlertMessages.error('', 'EXERCISES.PREREQUISITES_ERROR', {}, { exerciseName: 'quiz' });
  }

  getQuizPromise = vm.manager.getQuiz($stateParams.catalogId, $stateParams.questionSetId, $stateParams.exerciseId);
  if ($state.is('quiz-edit')) {
    getQuizPromise.then(() => {
      const unSubmittedSubmission = _.first(ExercisesManager.currentExercise.unsubmittedSubmissions);

      if ((!ExercisesManager.currentExercise.submissions.length && !ExercisesManager.currentExercise.canStartNewSubmission)
            || (ExercisesManager.currentExercise.submissions.length && !ExercisesManager.currentExercise.submissions[0].editable)) {
        $state.go('exercise-show');
      } else if (unSubmittedSubmission) {
        QuizzesManager.setCurrentSubmission(unSubmittedSubmission);
      } else {
        $state.go('quiz-new');
      }
    });
  } else if ($state.is('quiz-new')) {
    getQuizPromise.then(() => {
      const unSubmittedSubmission = _.first(ExercisesManager.currentExercise.unsubmittedSubmissions);

      if ((!ExercisesManager.currentExercise.submissions.length && !ExercisesManager.currentExercise.canStartNewSubmission)
            || (ExercisesManager.currentExercise.submissions.length && !ExercisesManager.currentExercise.submissions[0].editable)) {
        $state.go('exercise-show');
      } else if (unSubmittedSubmission) {
        $state.go('quiz-edit');
      }
    });
  }

  vm.save = function ($event) {
    $event.preventDefault();

    QuizzesManager.save()
      .then((response) => {
        if (ExercisesManager.currentExercise.lecturePage) {
          ReactTimelineService.updateTimeline(ExercisesManager.currentExercise.lecturePage.id);
          TimelinesManager.updateComponentProgress(ExercisesManager.currentExercise.lecturePage.id, 'exercise', ExercisesManager.currentExercise.id,
            ExercisesManager.currentExercise.progress);
        }
        ExercisesManager.shouldReload = true;
        $state.go('exercise-show');
      }).catch(
        (error) => {
          AlertMessages.error('', 'FORM.ERROR');
        },
      );

    vm.quizForm.$setPristine();
  };


  vm.submit = function () {
    let modalInstance;


    const unansweredCount = vm.manager.currentQuiz.unansweredCount();


    const unansweredCountModalDeffered = $q.defer();


    const finalAttemptModalDeffered = $q.defer();


    let modalInstanceCtrl;

    /* Submit with blank responses */
    if (unansweredCount) {
      /* eslint-disable no-shadow */
      modalInstanceCtrl = ['$scope', 'unansweredCount', function ($scope, unansweredCount) {
        $scope.unansweredCount = unansweredCount;
      }];
      /* eslint-enable no-shadow */

      const modalInstanceTemplate = 'quizzes/templates/modal-questions-blank-submit.html';
      modalInstance = ConfirmationOverlays.openConfirmationModal(modalInstanceTemplate, modalInstanceCtrl, { unansweredCount });
      modalInstance.result.then(() => {
        unansweredCountModalDeffered.resolve();
      }, () => {
        unansweredCountModalDeffered.reject();
      });
    } else {
      unansweredCountModalDeffered.resolve();
    }

    /* Submit final attempt warning */
    if (ExercisesManager.currentExercise.quizAttemptsCount() + 1 === ExercisesManager.currentExercise.questionSet.maximumAttempts) {
      unansweredCountModalDeffered.promise.then(() => {
        modalInstance = ConfirmationOverlays.openConfirmationModal('quizzes/templates/modal-final-attempt.html');
        modalInstance.result.then(() => {
          finalAttemptModalDeffered.resolve();
        }, () => {
          finalAttemptModalDeffered.reject();
        });
      }, () => {
        finalAttemptModalDeffered.reject();
      });
    } else {
      finalAttemptModalDeffered.resolve();
    }

    $q.all([unansweredCountModalDeffered.promise, finalAttemptModalDeffered.promise]).then(() => {
      QuizzesManager.submit()
        .then((response) => {
          ExercisesManager.shouldReload = true;
          ExercisesManager.shouldLoadFeedback = true;

          const skillTags = lectureComponent.skillTaggings.map((tagging) => tagging.skillTagId);
          const hasPoints = vm.CurrentCourseManager.course.gamificationEnabled
            && ExercisesManager.currentExercise.submissions[0].pointsReceived
            && vm.nvUtil.getCurrentTotalPoints(
              ExercisesManager.currentExercise.totalPoints,
              ExercisesManager.currentExercise.releaseDate,
              vm.CurrentCourseManager.course.isDecayEnabled()
            );

          if (hasPoints || skillTags.length > 0) {
            if (hasPoints) {
              let totalPoints = ExercisesManager.currentExercise.currentTotalPoints();
              if (
                ExercisesManager.currentExercise.questionSet.maximumAttempts
                && ExercisesManager.currentExercise.quizAttemptsCount() >= ExercisesManager.currentExercise.questionSet.maximumAttempts
                && ExercisesManager.currentExercise.submissions[0].pointsReceived < totalPoints
              ) {
                totalPoints = ExercisesManager.currentExercise.submissions[0].pointsReceived;
              }
              ReactTimelineService.updateTimeline(ExercisesManager.currentExercise.lecturePage.id);
              TimelinesManager.updateComponentPointsAndProgress(
                ExercisesManager.currentExercise.lecturePage.id,
                'exercise',
                ExercisesManager.currentExercise.id,
                ExercisesManager.currentExercise.submissions[0].pointsReceived,
                totalPoints,
              );
            }

            const quizScoreInfo = hasPoints ? {
              submissionScore: ExercisesManager.currentExercise.submissionScore(),
              maxScore: ExercisesManager.currentExercise.maxPossibleScore(),
              rewardsPointsProportionally: ExercisesManager.currentExercise.pointsConfiguration.rewardsPointsProportionally,
              threshold: ExercisesManager.currentExercise.pointsConfiguration.threshold,
              pointsReceivedFromPreviousAttempt: ExercisesManager.currentExercise.submissions[0].pointsReceivedFromPreviousAttempt,
              lowerScoreThanPreviousAttempt: ExercisesManager.currentExercise.submissions[0].pointsReceived
                  <= ExercisesManager.currentExercise.submissions[0].pointsReceivedFromPreviousAttempt,
            } : {};

            /* Extras has score info for quizzes, and null in other places */
            $uibModal.open({
              templateUrl: 'shared/templates/points-modal.html',
              windowClass: 'points-modal',
              controller: 'PointsModalCtrl as vm',
              resolve: {
                pointsReceived: ExercisesManager.currentExercise.submissions[0].pointsReceived,
                leaderboardPoints: ExercisesManager.currentExercise.submissions[0].leaderboardPoints,
                leaderboardRank: ExercisesManager.currentExercise.submissions[0].leaderboardRank,
                priorLeaderboardRank: ExercisesManager.currentExercise.submissions[0].priorLeaderboardRank,
                extras: { ...quizScoreInfo, skillTags},
              },
            }).closed.then(() => {
              $state.go('exercise-show');
            });
          } else if (ExercisesManager.currentExercise.lecturePage) {
            ReactTimelineService.updateTimeline(ExercisesManager.currentExercise.lecturePage.id);
            TimelinesManager.updateComponentProgress(ExercisesManager.currentExercise.lecturePage.id, 'exercise', ExercisesManager.currentExercise.id,
              ExercisesManager.currentExercise.progress);
            $state.go('exercise-show');
          } else {
            $state.go('exercise-show');
          }
        }).catch(
          (error) => {
            // Check if deadline expired, then reload to exercise page
            if (error.data.error?.code === 'report.error.expired') {
              ExercisesManager.shouldReload = true;
              $state.go('exercise-show');
            } else {
              AlertMessages.error('', 'FORM.ERROR');
            }
          },
        );

      vm.quizForm.$setPristine();
    });
  };


  /* Warning when you navigate away with unsaved changes */
  const msg = 'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY';

  useAngularPreventLecturePageNavigation($scope, checkUnsavedChanges);

  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(checkUnsavedChanges, 'shared/templates/modal-navigate-away.html', msg);

  $scope.$on('$destroy', () => {
    vm.deregisterStateChangeStart?.();

    QuizzesManager.resetCurrentQuiz();
  });

  $scope.$on('slideEnded', () => {
    vm.quizForm.$setDirty();
  });

  /** Private Helpers * */
  function checkUnsavedChanges() {
    return vm.quizForm?.$dirty;
  }
}
