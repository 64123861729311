import { PermissionTypes } from 'institutions/services/roles-service';

/* @ngInject */
export default function CourseRoleModelService(
  _,
  CourseRolesResource,
  RolesService,
) {
  class CourseRoleModel {
    constructor(attributes) {
      _.extend(this, attributes);

      this.RolesService = RolesService;

      if (this.permission) {
        // Ignore permissions that aren't 'visible' and are thus special cases for the backend
        this.permissions = RolesService.getVisiblePermissions(this.permission);
      }
    }

    /** Save this course role. Use RolesService.makePermissionOptions() to create this permissionOptions object */
    save(institutionId, roleName, badge, permissions, menteeName = null) {
      const permissionOptions = this.RolesService.makePermissionOptions(permissions);

      return CourseRolesResource.update({ institutionId, id: this.id }, {
        institutionId,
        name: roleName,
        badge,
        permissionOptions,
        menteeName,
      }).$promise.then((response) => {
        this.name = roleName;
        this.badge = badge;
        this.permission = response.result.permission;
        this.permissions = permissions;
        this.menteeName = response.result.menteeName;
      });
    }

    isInstructor() {
      return RolesService.hasPermission(this.permission, PermissionTypes.INSTRUCTOR);
    }

    isTeachingAssistant() {
      return RolesService.hasPermission(this.permission, PermissionTypes.TEACHER_ASSISTANT);
    }

    isMentor() {
      return RolesService.hasPermission(this.permission, PermissionTypes.MENTOR);
    }
  }

  return CourseRoleModel;
}
