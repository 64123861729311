import React from 'react';
import NvIcon from 'shared/components/nv-icon';

export type NotAvailableContainerProps = {
  icon: string
  text: string
};

export const NotAvailableGreyContainer = ({ icon, text }: NotAvailableContainerProps) => (
  <div className='not-available-grey-container bg-gray-6 d-flex flex-column justify-content-between align-items-center p-4 text-gray-3 w-100 my-5'>
    <NvIcon icon={icon} size='medium-large' />
    <div className='mt-2'>{text}</div>
  </div>
);
