/* @ngInject */
export default function NvTeamCard(
  RailsRoutes,
  CurrentCourseManager,
  $state,
) {
  return {
    scope: {
      team: '=',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
      vm.RailsRoutes = RailsRoutes;
      vm.CurrentCourseManager = CurrentCourseManager;
      vm.$state = $state;
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'learner_profiles/templates/nv-team-card.html',
  };
}
