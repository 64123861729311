import React, { useContext } from 'react';

// Angular
import { AngularServicesContext } from 'react-app';

// Translations
import t from 'react-translate';

// Styles
import { css } from '@emotion/react';
import { gray3 } from 'styles/global_defaults/colors';
import { textSmallFontSize, normalFontWeight, textSmallLineHeight } from 'styles/global_defaults/fonts';

type Props = {
  align?: 'left' | 'center',
};

const ForgotPassword = ({ align = 'left' }: Props) => {
  const { $state } = useContext(AngularServicesContext) || {};

  const styles = css`
    font-size: ${textSmallFontSize}px;
    font-weight: ${normalFontWeight};
    line-height: ${textSmallLineHeight}px;
    color: ${gray3};
  `;

  return (
    <div css={styles} className={`forgot-password mb-5 text-align-${align} mt-1`}>
      <a href={$state.href('users.authentications.request-password-reset-email')} data-qa='forgot-password'>
        {t.USERS.REGISTRATION.FORGOT_PASSWORD()}
      </a>
    </div>
  );
};

export default ForgotPassword;
