/* eslint-disable jsx-a11y/media-has-caption */

import { useEffect, useRef } from 'react';

import { displayBlobInPlayer } from 'recording/services/media-visualizer-helper';

type VideoBlobPreviewProps = {
  blob: Blob,
  width?: number;
  height?: number;
};

/**
 * Displays the given video blob in a player
 */
const VideoBlobPreview = ({
  blob,
  width,
  height,
}: VideoBlobPreviewProps) => {
  const videoRef = useRef<HTMLVideoElement>();

  useEffect(() => {
    if (blob) {
      return displayBlobInPlayer(videoRef.current, blob);
    }

    return undefined;
  }, [blob]);

  return <video ref={videoRef} playsInline disablePictureInPicture width={width} height={height} />;
};

export default VideoBlobPreview;
