/* @ngInject */
export default function NvLCourseHeader(
  $q,
  nvCurrentPage,
  PageLevelManager,
  $state,
  $parse,
  $translate,
  $rootScope,
  TimelinesManager,
  CurrentCourseManager,
  $stateParams,
  CurrentUserManager,
  LecturePageManager,
  _,
  $timeout,
  ReportsManager,
) {
  return {
    restrict: 'A',
    link(scope, elem, attrs) {
      scope.hrefForTitle = function () {
        let stateString = '';

        if (attrs.stateForTitle) {
          stateString += attrs.stateForTitle;
        }

        return $state.href(stateString);
      };

      // Use a ui-sref attribute instead of an href for state changes that require parameters
      scope.srefForTitle = function () {
        if (attrs.stateForTitle && attrs.params) {
          return `${attrs.stateForTitle}(${attrs.params})`;
        }

        return null;
      };

      scope.isL4 = function () {
        return nvCurrentPage.getPageLevel() === 4;
      };

      scope.reload = function () {
        $state.reload();
      };

      scope.nvCurrentPage = nvCurrentPage;
      scope.isSameState = $state.current.name === attrs.stateForTitle;
      scope.stateHref = scope.hrefForTitle();
      scope.uiSref = scope.srefForTitle();
      scope.CurrentCourseManager = CurrentCourseManager;
      scope.TimelinesManager = TimelinesManager;
      scope.LecturePageManager = LecturePageManager;
      scope.ReportsManager = ReportsManager;
      scope.displayTitleKey = nvCurrentPage.getNonL4Data().titleKey;
      scope.displayCompletionCriteria = false;
      scope.isLecturePage = $state.includes('lecture-page');

      scope.selectTab = function (tab) {
        scope.TimelinesManager.selectedTab = scope.TimelinesManager.selectedTab === tab ? '' : tab;
      };

      function initializeProgress() {
        checkCriteriaCanBeShown();
      }

      function checkCriteriaCanBeShown() {
        if (CurrentCourseManager.course
          && ($state.includes('lecture-page') || $state.includes('lecture-page-slash'))
          && ($stateParams.catalogId === CurrentCourseManager.course.catalogId
            || $stateParams.scenarioId)) {
          // Only need to display criteria on header when TimelinesManager user and CurrentUserManager user
          // matches. Needed this since TimelineManager gets updated when we view points breakdown modal.
          // So we are not displaying completion criteria on header while points breakdown modal is is visible.
          // Only displaying after setting TimelinesManager by the destroy trigger of the points breakdown modal
          // Also, considering updating completion criteria when a practice feedback deleted from practice room
          // There won't be stateParams.catalogId in practice room
          scope.displayCompletionCriteria = true;
        } else {
          scope.displayCompletionCriteria = false;
        }
      }

      scope.toggleCompletionPanel = function () {
        scope.TimelinesManager.updateIsCompletionPanelExpanded(!scope.TimelinesManager.isCompletionPanelExpanded);
        scope.TimelinesManager.selectedTab = '';
      };

      // Watch for changes to displayTitleKey. Used by the header title set in license-dashboard.tsx
      // Do not update the value if we have scope.dsiplayTitleKey set, so that we can react to other angularjs states updating the display title
      $rootScope.$watch('displayTitleKey', (val) => {
        if (scope.displayTitleKey || !val || !val.length) {
          return;
        }
        scope.displayTitleKey = val;
      });

      attrs.$observe('headerTitle', (inputLabel) => {
        scope.displayTitle = attrs.headerTitle;
      });

      scope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
        scope.isSameState = toState.name === attrs.stateForTitle;
      });

      $timeout(() => {
        // Since this is called on every lecture page, moving the initialize the criteria task(timeline request)
        // to a timeout so it won't block the main content load and called after main content requests
        initializeProgress();
      });
    },
    templateUrl: 'shared/templates/nv-l1-course-header.html',
  };
}
