/* @ngInject */
export default function LearnerDirectoryManager(

  DirectoryManagerInterface,
  _,
  UserManager,
  UsersResources,
  CurrentUserManager,
  CurrentCourseManager,
  $state,
  $translate,
) {
  const manager = DirectoryManagerInterface.create();

  const {
    ORG_PROFILE_FILTER_TYPE,
    COURSE_PROFILE_FILTER_TYPE,
  } = manager.constants;

  const LEARNER_DIRECTORY_PAGE_SIZE = 15;

  manager.users = [];
  manager.allFilters = [];
  manager.dropdownFilters = ['all'];
  manager.currentCourseManager = CurrentCourseManager;

  manager.fetchProfileQuestionFilters = function () {
    return UserManager.getSearchFilters(manager.catalogId).then((response) => {
      const {
        orgProfileQuestions,
        courseProfileQuestions,
      } = response.result;

      manager.allFilters = [
        ...orgProfileQuestions.map((orgFieldFilter) => ({
          ...orgFieldFilter,
          type: ORG_PROFILE_FILTER_TYPE,
          identifier: `${ORG_PROFILE_FILTER_TYPE}-${orgFieldFilter.id}`,
        })),
        ...courseProfileQuestions.map((courseQuestionFilter) => ({
          ...courseQuestionFilter,
          type: COURSE_PROFILE_FILTER_TYPE,
          identifier: `${COURSE_PROFILE_FILTER_TYPE}-${courseQuestionFilter.id}`,
        })),
      ];

      manager.learnersWithoutTeamFilterEnabled = response.result.showTeamOptions || response.result.showGroupOptions;

      manager.setLocationData();
    });
  };

  manager.doSearch = function (shouldAppendResults, shouldCancelPrevRequests) {
    manager.loadingSearchResults = true;
    manager.hasExecutedSearchRequest = false;


    if (manager.previousSearchRequest && shouldCancelPrevRequests !== false) {
      manager.previousSearchRequest.$cancelRequest();
      manager.pageNumber = 1;
    }

    _.each(manager.selectedProfileFilters, (domain) => {
      _.each(domain, (question) => {
        question.searched = question.selected !== null;
      });
    });

    if (!shouldAppendResults) {
      manager.users = [];
    }

    // manually swap the search input string into what we will be using for search
    manager.queryTerm = manager.queryTermDisplay;

    manager.locationFilter = manager.formatLocationFilterForSearch();

    const selectedFilters = manager.getSelectedFilters();

    manager.previousSearchRequest = UsersResources.search({
      catalogId: manager.catalogId,
    }, {
      page: manager.pageNumber,
      pageSize: LEARNER_DIRECTORY_PAGE_SIZE,
      queryTerm: manager.queryTerm || null,
      answer: selectedFilters['course-profile'],
      profileDetail: selectedFilters['org-profile'],
      hasNoCourseLongTeam: manager.dropdownFilter === 'noTeam',
      myTeamsAndGroups: manager.dropdownFilter === 'myTeam',
      location: manager.locationFilter,
      teachingTeam: manager.context === 'TeachingTeam',
    },
    (searchResults) => {
      if (searchResults.result) {
        manager.loadingSearchResults = false;
        manager.hasExecutedSearchRequest = true;

        manager.hasMoreToLoad = searchResults.result.users.length >= LEARNER_DIRECTORY_PAGE_SIZE;

        manager.users = shouldAppendResults ? manager.users.concat(searchResults.result.users) : searchResults.result.users;
        manager.resultsCount = searchResults.result.count;
      } else {
        manager.loadingSearchResults = false;
        manager.hasExecutedSearchRequest = true;
      }
    });
  };

  manager.displayNameForDropdownFilter = function (internalName) {
    if (!internalName) {
      internalName = manager.dropdownFilter;
    }
    if (manager.context === 'TeachingTeam') {
      return 'TEACHING_TEAM_DIRECTORY.FILTER_ALL_TEACHING_TEAM';
    }

    if (internalName === 'myTeam') {
      return myTeamDropdownName();
    }
    if (internalName === 'noTeam') {
      return 'LEARNER_DIRECTORY.FILTER_NO_TEAM';
    }

    // default: all
    return 'LEARNER_DIRECTORY.FILTER_ALL_LEARNERS';
  };

  manager.searchUrlForDropdownFilter = function (internalName) {
    if (manager.context === 'TeachingTeam') {
      return $state.href('teaching-team-directory', { catalogId: manager.catalogId });
    }

    return $state.href('learner-directory', { catalogId: manager.catalogId, learnerFilter: internalName });
  };

  manager.displayNameForSearchables = function () {
    if (manager.context === 'TeachingTeam') {
      return CurrentCourseManager.course.teachingTeamName.singularizedTitleized;
    }
    return CurrentCourseManager.course.learnersName.pluralizedTitleized;
  };

  manager.getPlaceholderKey = function () {
    return 'LEARNER_DIRECTORY.SEARCH_SEARCHABLES';
  };

  manager.getPlaceholderValues = function () {
    return {
      searchables: (manager.context === 'TeachingTeam')
        ? CurrentCourseManager.course.teachingTeamName.singularizedTitleized
        : CurrentCourseManager.course.learnersName.pluralizedTitleized,
    };
  };

  manager.displayNameForNearbyFilterKey = function () {
    return 'LEARNER_DIRECTORY.PEOPLE_NEAR_YOU';
  };

  manager.emptyResultSet = function () {
    return manager.users.length === 0;
  };

  manager.hasResults = function () {
    return manager.users.length;
  };

  manager.resetData = function () {
    manager.users = [];
    manager.resetSharedData();
  };

  manager.hasProfileInfo = function (userInfo) {
    return userInfo.user.displayLocation && !_.isEmpty(userInfo.latestActivity);
  };

  manager.filterEnabled = function (filter) {
    return _.contains(['all', 'TeachingTeam']) || manager.learnersWithoutTeamFilterEnabled;
  };

  manager.noResultsIcon = function () {
    return 'icon-profile';
  };

  manager.populateDropdownFilters = function () {
    if (!manager.currentCourseManager.course) {
      return;
    }

    if (manager.currentCourseManager.course.groupTeamSet || manager.currentCourseManager.course.courseLongTeamsAvailable()) {
      manager.dropdownFilters = _.union(manager.dropdownFilters, ['myTeam']);
    }
    if (manager.currentCourseManager.course.courseLongTeamsAvailable()) {
      manager.dropdownFilters = _.union(manager.dropdownFilters, ['noTeam']);
    }
  };

  function myTeamDropdownName() {
    if (manager.currentCourseManager.course.groupTeamSet && manager.currentCourseManager.course.courseLongTeamsAvailable()) {
      return 'LEARNER_DIRECTORY.FILTER_MY_TEAM_AND_GROUP';
    } if (manager.currentCourseManager.course.courseLongTeamsAvailable()) {
      return 'LEARNER_DIRECTORY.FILTER_MY_TEAM';
    } if (manager.currentCourseManager.course.groupTeamSet) {
      return 'LEARNER_DIRECTORY.FILTER_MY_GROUP';
    }

    return null;
  }

  return manager;
}
