import React from 'react';
import { css } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';
import { isRtl, screenSmMin } from 'styles/global_defaults/media-queries';
import { mergeRefs } from 'shared/react-utils';
import useCardWidth from 'dashboard/hooks/use-card-width';
import useDashboardListingCollapser from 'dashboard/hooks/use-dashboard-listing-collapser';
import {
  doubleSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';

const CARDS_PER_ROW = 3;
const DEFAULT_MAX_ITEMS = 6;

const getElementMargins = (cardsPerRow: number, spaceBetweenCards: number, cardIndex: number) => {
  let marginLeft = 0;
  let marginRight = 0;
  const totalSpaceBetweenCards = spaceBetweenCards * (cardsPerRow - 1);
  const spaceForEachCard = totalSpaceBetweenCards / cardsPerRow;

  if (cardIndex === 0 || !(cardIndex % cardsPerRow)) {
    marginRight = spaceForEachCard;
  } else if (!((cardIndex + 1) % cardsPerRow)) {
    marginLeft = spaceForEachCard;
  } else {
    const halfSpace = spaceForEachCard / 2;

    marginLeft = halfSpace;
    marginRight = halfSpace;
  }
  if (isRtl()) {
    [marginLeft, marginRight] = [marginRight, marginLeft];
  }
  return {
    marginLeft,
    marginRight,
  };
};

export type ElementsDictionary = {
  [key: number]: HTMLElement,
};

type RenderPropData = {
  item: any,
  props: {
    style: React.CSSProperties,
    ref: React.RefCallback<HTMLElement>,
  },
};

type Props = {
  items: any[],
  keyExtractor: (item: any) => React.Key,
  renderItem: (data: RenderPropData, index: number) => React.ReactElement,
  className?: string,
  collapserClassName?: string,
  spaceBetweenCards?: number,
};

const CardsResponsiveListing = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    items,
    className,
    renderItem,
    keyExtractor,
    collapserClassName,
    spaceBetweenCards = doubleSpacing,
  } = props;

  const containerRef = React.useRef<HTMLDivElement>();
  const childrenRef = React.useRef<ElementsDictionary>({});
  const [properContainerMaxWidth, setProperContainerMaxWidth] = React.useState<number>();
  const [properDisplay, setProperDisplay] = React.useState<'inline-block' | 'inline-flex'>();
  const [
    itemComputedStyles,
    setItemComputedStyles,
  ] = React.useState<Pick<CSSStyleDeclaration, 'maxWidth' | 'display'>>();

  const [itemsToRender, collapser] = useDashboardListingCollapser<any>(
    items,
    DEFAULT_MAX_ITEMS,
    (triggeredByKeyboard) => {
      if (triggeredByKeyboard) {
        childrenRef.current[DEFAULT_MAX_ITEMS].focus();
      }
    },
  );

  const itemsToRenderLength = itemsToRender.length;

  const isMobile = useMediaQuery({
    query: `(max-width: ${screenSmMin}px)`,
  });

  const notMobileCardsPerRow = Math.min(CARDS_PER_ROW, itemsToRender.length || 1);

  const currentCardsPerRow = isMobile ? 1 : notMobileCardsPerRow;

  const cardWidth = useCardWidth(containerRef, currentCardsPerRow);

  const hasSingleRow = itemsToRender.length < CARDS_PER_ROW;

  React.useLayoutEffect(() => {
    if (itemsToRenderLength && !itemComputedStyles) {
      const firstElement = childrenRef.current[0];
      const { display, maxWidth } = window.getComputedStyle(firstElement);

      setItemComputedStyles({
        display,
        maxWidth,
      });
    }
  }, [itemComputedStyles, itemsToRenderLength]);

  React.useLayoutEffect(() => {
    if (itemComputedStyles && !properDisplay) {
      const { display } = itemComputedStyles;

      if (display === 'block' || display === 'flex') {
        setProperDisplay(`inline-${display}`);
      }
    }
  }, [itemComputedStyles, properDisplay]);

  React.useLayoutEffect(() => {
    if (itemComputedStyles) {
      const { maxWidth } = itemComputedStyles;
      const cardMaxWidth = parseFloat(maxWidth);

      if (cardMaxWidth) {
        const newProperContainerMaxWidth = (cardMaxWidth * currentCardsPerRow) + (spaceBetweenCards * (currentCardsPerRow - 1));

        setProperContainerMaxWidth(newProperContainerMaxWidth);
      }
    }
  }, [currentCardsPerRow, itemComputedStyles]);

  const styles = css`
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    ${properContainerMaxWidth && css`
      max-width: ${properContainerMaxWidth}px;
    `};

    ${(isMobile || hasSingleRow) && css`
      text-align: center;
    `};
  `;

  return (
    <React.Fragment>
      <div
        css={styles}
        className={className}
        ref={mergeRefs(ref, containerRef)}
      >
        {itemsToRender.map((item, index) => {
          const style = {
            width: cardWidth,
            verticalAlign: 'top',
            marginBottom: isMobile ? standardSpacing : doubleSpacing,
            ...getElementMargins(
              currentCardsPerRow,
              spaceBetweenCards,
              index,
            ),
          } as any;

          if (properDisplay) {
            style.display = properDisplay;
          }

          return React.cloneElement(renderItem(
            {
              item,
              props: {
                style,
                ref: (childRef) => {
                  childrenRef.current[index] = childRef;
                },
              },
            },
            index,
          ), {
            key: keyExtractor(item),
          });
        })}
      </div>
      {collapser && React.cloneElement(collapser, {
        className: `${collapser.props.className}${collapserClassName
          ? ` ${collapserClassName}`
          : ''
        }`,
      })}
    </React.Fragment>
  );
});

export default CardsResponsiveListing;
