/* @ngInject */
export default function TeachingTeamDirectorySortFilterController(
  TeachingTeamDirectoryManager,
  CurrentCourseManager,
) {
  const vm = this;

  vm.manager = TeachingTeamDirectoryManager;
  vm.currentCourseManager = CurrentCourseManager;

  vm.manager.hideDropdown = true;
}
