import React from 'react';
import { css } from '@emotion/react';

import NvTooltip from 'shared/components/nv-tooltip';
import { gray2, gray3, gray6 } from 'styles/global_defaults/colors';
import { notDesktop, desktop } from 'styles/global_defaults/media-queries';
import { halfSpacing, largeSpacing } from 'styles/global_defaults/scaffolding';

const hideItem = css`
  visibility: hidden;
  opacity: 0;
  height: 0;
`;

const showItem = css`
  visibility: visible;
  opacity: 1;
`;

export type Description = {
  text: string,
  color?: string,
};

type TabProps = {
  color: string,
  count: number,
  title: string,
  isCompact: boolean,
  isSelected: boolean,
  tooltipText: string,
  descriptionItems: Description[],
  renderImage: () => React.ReactElement,
};

const Tab = (props: TabProps) => {
  const {
    color,
    count,
    title,
    isCompact,
    isSelected,
    tooltipText,
    renderImage,
    descriptionItems,
  } = props;
  const textElementRef = React.useRef<null | HTMLDivElement>(null);
  const [longDescription, setLongDescription] = React.useState(false);
  const styles = css`
    height: 110px;
    min-width: 130px;
    ${desktop(css`
      width: 160px;
      height: 140px;
    `)};
    transition: all 0.5s;

    ${(isSelected) && css`
      border-radius: ${halfSpacing}px;
      box-shadow: 0px ${halfSpacing}px ${largeSpacing}px ${gray6};
      background: #FFFFFF;
    `};

    .text-color {
      color: ${isSelected ? color : gray2};
    }

    .descriptions-container .tab-description {
      ${descriptionItems.map((description, index) => css`
        &:nth-of-type(${index + 1}) {
          color: ${isSelected ? (description.color ?? gray3) : gray3};
        }
      `)};
    }

    &:hover {
      .text-color {
        color: ${color};
      }

      .descriptions-container {
        ${descriptionItems.map((description, index) => css`
          .tab-description:nth-of-type(${index + 1}) {
            color: ${description.color ?? gray3};
          }
        `)};
      }
    }

    .text-wrapper {
      ${showItem};
    }

    ${notDesktop(css`
      .desc {
        display: none;
      }
    `)};

    &.compact {
      height: 70px;
      transition: all 0.5s;

      .text-wrapper {
        ${hideItem};
        transition: all 0.5s;
      }
    }
  `;

  const isOverflowing = () => {
    if (textElementRef?.current) {
      const { scrollWidth, clientWidth } = textElementRef.current;
      const isBigger = scrollWidth > clientWidth;
      setLongDescription(isBigger);
    }
  };

  React.useEffect(() => {
    isOverflowing();
    window.addEventListener('resize', isOverflowing);
  }, []);

  React.useEffect(() => () => {
    window.removeEventListener('resize', isOverflowing);
  }, []);
  return (
    <div
      css={styles}
      className={`px-4 ${isCompact ? 'compact' : ''}`}
      aria-label={`${count} ${title} ${descriptionItems.join(' ')}`}
    >
      <div className='d-flex'>
        <div className='mt-3'>
          <NvTooltip
            text={tooltipText}
            enabled={isCompact}
          >
            {renderImage()}
          </NvTooltip>
        </div>
        <span className='text-color course-title-l mt-2 pl-2'>{count}</span>
      </div>
      <div className='text-wrapper'>
        {title && <div className='course-title-xs text-color'>{title}</div>}
        {!!descriptionItems.length && (
          <div className='descriptions-container'>
            {descriptionItems.map((description, index) => (
              <NvTooltip
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                text={description.text}
                enabled={longDescription}
              >
                <div
                  className='course-title-xxs mt-1 desc tab-description'
                >
                  <div
                    ref={textElementRef}
                    className='ellipsis'
                  >
                    {description.text}
                  </div>
                </div>
              </NvTooltip>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Tab;
