import React from 'react';
import t from 'react-translate';
import NvChart from 'shared/components/nv-chart';
import { RateOfSpeech } from 'redux/schemas/models/video-practice';
import { getChartData } from '../../utils';
import { config as configConstants } from '../../../../../../config/config.json';
import InsightsCount from '../insights-count';
import FadeInView from '../../fade-in-view';

type StatsProps = {
  rateOfSpeech: RateOfSpeech[]
  wordsPerMinute: number
};

const Stats = ({
  rateOfSpeech,
  wordsPerMinute,
}: StatsProps) => {
  const { data, options } = getChartData(rateOfSpeech);

  return (
    <FadeInView>
      <div className='border-bottom border-gray-4 py-6'>
        <div className='d-flex'>
          <InsightsCount
            count={wordsPerMinute}
            label={t.PRACTICE_ROOM.INSIGHTS.WPM.LABEL()}
            className='text-primary'
            showCount={wordsPerMinute > 0}
          />
          <div className='ml-4 d-flex flex-column'>
            <div className='gray-1 text-regular'>
              {!rateOfSpeech.length && !wordsPerMinute ? (
                t.PRACTICE_ROOM.INSIGHTS.WPM.NO_DATA()
              ) : (
                <>
                  {t.PRACTICE_ROOM.INSIGHTS.WPM.DESCRIPTION(
                    configConstants.recordings.wpm.fast.toString(),
                    configConstants.recordings.wpm.slow.toString(),
                    !wordsPerMinute ? 'N/A' : wordsPerMinute.toString(),
                  )}
                  {rateOfSpeech.length > 1 && ` ${t.PRACTICE_ROOM.INSIGHTS.WPM.CHART()}`}
                </>
              )}
            </div>
            {rateOfSpeech.length > 1 && (
              <div className='mt-4'>
                <NvChart
                  type='line'
                  data={data}
                  options={options}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </FadeInView>
  );
};

export default Stats;
