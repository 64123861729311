import React from 'react';


import { connect, useSelector } from 'react-redux';
import t from 'react-translate';

// Schemas
import { RootState } from 'redux/schemas';
import { NTeamDiscussion, TeamDiscussion } from 'redux/schemas/models/team-discussion';
import { ActivityType } from 'redux/schemas/models/activity';
import { ComponentKeyPluralized } from 'redux/schemas/models/lecture-component';

// Actions
import { setActivityExpandedInAutomatedCommunications } from 'redux/actions/course-communications';
import { isActivityExpandedInAutomatedCommunications } from 'redux/selectors/course-communications';

// Selectors
import { getTeamDiscussion, hasTeamDiscussionGotCommunicationError } from 'redux/selectors/timeline';

// Components
import NvIcon from 'shared/components/nv-icon';
import NvStackedTab from 'shared/components/nv-stacked-tab';

import CommunicationCreateNewDropdown from 'communications/course_communications/components/communication-create-new-dropdown';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';

type TeamDiscussionLectureComponentProps = {
  teamDiscussionId: number;
};

type StateProps = {
  teamDiscussion: NTeamDiscussion;
  hasCommunicationError: boolean;
};

const mapDispatchToProps = {
  setExpanded: setActivityExpandedInAutomatedCommunications,
};

const TeamDiscussionLectureComponent = (props: TeamDiscussionLectureComponentProps & StateProps & typeof mapDispatchToProps) => {
  const isExpanded = useSelector<RootState, boolean>((state) => isActivityExpandedInAutomatedCommunications(state, ComponentKeyPluralized.TEAM_DISCUSSION, props.teamDiscussionId));

  return (
    <React.Fragment>
      <div className={`lecture-component-row ${isExpanded && props.teamDiscussion?.communicationsCount > 0 && 'expanded-box'}`}>
        <NvIcon icon='conversations' size='smallest' />
        <div className='description'>{props.teamDiscussion?.title}</div>
        <div className=''>
          <CommunicationCreateNewDropdown activityType={ActivityType.TEAM_DISCUSSION} activityId={props.teamDiscussionId} />
        </div>
        {props.teamDiscussion?.communicationsCount > 0 && (
          <NvStackedTab
            count={props.teamDiscussion.communicationsCount}
            hasError={props.hasCommunicationError}
            isExpanded={isExpanded}
            errorMessage={t.COURSE_COMMUNICATIONS.WARNINGS.ACTIVITY_COMMUNICATION()}
            onClick={() => props.setExpanded({
              type: ComponentKeyPluralized.TEAM_DISCUSSION,
              id: props.teamDiscussionId,
              isExpanded: !isExpanded,
            })}
          />
        )}
      </div>
      {
        // Communications
        isExpanded && props.teamDiscussion?.communications && props.teamDiscussion.communications.map(communicationId => (
          <CommunicationItem key={communicationId.toString()} communicationId={communicationId} />
        ))
      }
    </React.Fragment>
  );
};

const ConnectedTeamDiscussionLectureComponent = connect(
  (state: RootState, ownProps: TeamDiscussionLectureComponentProps) => ({
    teamDiscussion: getTeamDiscussion(state, ownProps.teamDiscussionId),
    hasCommunicationError: hasTeamDiscussionGotCommunicationError(state, ownProps.teamDiscussionId),
  }),
  mapDispatchToProps,
)(TeamDiscussionLectureComponent);

export default ConnectedTeamDiscussionLectureComponent;
