
import { css } from '@emotion/react';
import React from 'react';
import prodPathReplace from 'shared/prod-path-rewrite';
import { standardSpacing, largeSpacing } from 'styles/global_defaults/scaffolding';
import NvTooltip from 'shared/components/nv-tooltip';
import ClickableContainer from './clickable-container';

const InnerContainerStyle = css`
  width: ${largeSpacing}px;
  height: ${largeSpacing}px;
  border-radius: 50%;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
`;

type ButtonProps = React.ComponentPropsWithoutRef<'div'> & {
  tooltip?: string;
};
interface ColorIconButtonProps extends ButtonProps {}

const ColorIconButton = React.forwardRef<HTMLDivElement, ColorIconButtonProps>((props: ColorIconButtonProps, ref) => {
  const { ...rest } = props;
  const iconPath = prodPathReplace('images/icon-colorpicker.png');

  return (
    <ClickableContainer
      {...rest}
      css={css`
        /* TODO: From Nathan - I disabled this for the header component because it was stretching the container. Are we sure this should be 40px? Let's sync and figure
          out an approach that works for both of us */
        /* width: 40px;
          height: 40px; */
        align-self: center;
        justify-content: center;
        align-items: center;
      `}
      ref={ref}
    >
      <NvTooltip text={props.tooltip} enabled={!!props.tooltip?.length}>
        <div css={InnerContainerStyle}>
          <img
            css={css`
              width: ${standardSpacing}px;
              height: ${standardSpacing}px;
            `}
            src={iconPath}
            alt='color'
          />
        </div>
      </NvTooltip>
    </ClickableContainer>
  );
});

export default ColorIconButton;
