/* @ngInject */
export default function VideosManager(

  _,
) {
  const manager = {
    videoPlayers: [],

    addVideoPlayer,
    removeVideoPlayer,
    pauseAllOtherVideos,
  };

  function addVideoPlayer(videoPlayer) {
    if (manager.videoPlayers.indexOf(videoPlayer) === -1) {
      manager.videoPlayers.push(videoPlayer);
    }
  }

  function removeVideoPlayer(videoPlayer) {
    const index = manager.videoPlayers.indexOf(videoPlayer);

    if (index > -1) {
      manager.videoPlayers.splice(index, 1);
    }
  }

  function pauseAllOtherVideos(currentVideoPlayer) {
    _.each(manager.videoPlayers, (videoPlayer) => {
      if (videoPlayer !== currentVideoPlayer) {
        if (videoPlayer.pause) {
          videoPlayer.pause();
        } else if (videoPlayer.pauseVideo) {
          videoPlayer.pauseVideo();
        }
      }
    });
  }

  return manager;
}
