import { css } from '@emotion/react';

import t from 'react-translate';
import { Button } from 'react-bootstrap';
import { useState, useRef, useContext } from 'react';
import NvModal, { ModalType, ModalTheme } from 'shared/components/nv-modal';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { black } from 'styles/global_defaults/colors';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/schemas';
import { MyAccount } from 'redux/schemas/models/my-account';
import { getCurrentUser } from 'redux/selectors/users';
import { updateHasViewedRteFte } from 'redux/actions/users';
import { useAppDispatch } from 'redux/store';
import { AngularContext } from 'react-app';
import prodPathReplace from '../../shared/prod-path-rewrite';


type FroalaFteModalProps = {
  closeModal(): void,
};

const styles = css`
  text-align: center;

  .section-title {
    color: ${black};
    margin-bottom: ${2 * standardSpacing}px;
  }

  .close-text {
    margin-top: 48px;
  }
`;


const FroalaFteModal = ({
  closeModal,
}: FroalaFteModalProps) => (
  <div css={styles}>
    <div className='section-title'>{t.FROALA.FIRST_TIME_EXPERIENCE.SUBTITLE()}</div>
    <img alt={t.FROALA.FIRST_TIME_EXPERIENCE.ALT()} src={prodPathReplace('images/froala-fte.gif')} />
    <div className='close-text'>
      <Button variant='link' onClick={() => closeModal()}>{t.GAMIFICATION.COURSE_HOME_MODAL.CLOSE_TEXT()}</Button>
    </div>
  </div>
);


export const useFroalaFte = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const currentUser = useSelector<RootState, MyAccount>(getCurrentUser);
  const { injectServices } = useContext(AngularContext);
  const [CurrentUserManager] = injectServices(['CurrentUserManager']);

  // the following is needed to maintain the right value because froala callbacks are not reinitialized; the closure will give showModal a stale currentUser
  const hasViewedRteFte = useRef(currentUser.hasViewedRteFte);
  hasViewedRteFte.current = currentUser.hasViewedRteFte;

  const dispatch = useAppDispatch();

  const showModal = () => {
    if (!hasViewedRteFte.current) {
      dispatch(updateHasViewedRteFte()).then(() => {
        CurrentUserManager.user.updateFromReact({
          hasViewedRteFte: true,
        });
      });
      setIsModalOpen(true);
    }
  };

  const renderModal = () => (
    <NvModal
      type={ModalType.FIXED}
      theme={ModalTheme.LIGHT}
      header=''
      body={<FroalaFteModal closeModal={() => setIsModalOpen(false)} />}
      show={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      width={440}
      doubleModal
    />
  );

  return [showModal, renderModal];
};

export default useFroalaFte;
