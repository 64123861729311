import SkillTagsDashboard from '../components/skill-tags-dashboard';

/* @ngInject */
export default function SkillTagsCtrl(
  $scope,
  $uibModal,
  $controller,
  CourseModel,
  StateManager,
  AlertMessages,
  TimelineModel,
  ConfettiAnimation,
  DashboardResources,
  ConfirmationOverlays,
) {
  $scope.$uibModal = $uibModal;
  $scope.CourseModel = CourseModel;
  $scope.$controller = $controller;
  $scope.StateManager = StateManager;
  $scope.AlertMessages = AlertMessages;
  $scope.TimelineModel = TimelineModel;
  $scope.ConfettiAnimation = ConfettiAnimation;
  $scope.DashboardResources = DashboardResources;
  $scope.SkillTagsDashboard = SkillTagsDashboard;
  $scope.ConfirmationOverlays = ConfirmationOverlays;
}
