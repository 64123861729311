/* @ngInject */
export default function NvUniqueRoleName(
  $parse,
  _,
) {
  return {
    require: 'ngModel',
    link(scope, elem, attrs, ngModel) {
      ngModel.$validators.custom = function (modelValue, viewValue) {
        const courseRoles = $parse(attrs.nvUniqueRoleName)(scope);
        // Ensures the role name is unique by failing validation if another role has the same name
        // as the draft. Filters out by editingId to allow for editing roles
        return !_.values(courseRoles).filter((r) => r.id !== scope.vm.editingId)
          .map((r) => r.name).some((r) => r.toLowerCase().trim() === modelValue.toLowerCase().trim());
      };
    },
  };
}
