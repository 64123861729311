import React, { useContext, useEffect, useState } from 'react';
import t from 'react-translate';
import { css } from '@emotion/react';
import { Button, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AngularContext, AngularServicesContext } from 'react-app';

import { wrapThunkAction } from 'redux/utils';
import { getCourseRoles } from 'redux/selectors/roles';
import { getCurrentUser } from 'redux/selectors/users';
import { getCourse } from 'redux/selectors/course';
import { getCourseAccessModalProps } from 'redux/selectors/course-access-modal';
import { enrollWithRole } from 'redux/actions/users';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { resetCourseAccessModalProps } from 'redux/actions/course-access-modal';
import { EnrollWithRoleParams } from 'redux/schemas/app/course-access-modal';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { CourseRole } from 'redux/schemas/models/org-level-roles';
import { FullCourse } from 'redux/schemas/models/courseFull';

import NvModal, { ModalType } from 'shared/components/nv-modal';
import NvPopover from 'shared/components/nv-popover';
import NvIcon from 'shared/components/nv-icon';
import NvDropdown, { NvDropdownAlign, NvDropdownButtonStyle, NvDropdownCustomItem } from 'shared/components/inputs/nv-dropdown';

import { isRtl } from 'styles/global_defaults/media-queries';
import { black, gray2, gray3, gray4, primary } from 'styles/global_defaults/colors';
import { doubleSpacing, halfSpacing, largeSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';

import { aliases, getTranslateKey } from 'shared/services/i18n-utils';

import { config } from '../../../config/pendo.config.json';

const dropdownFormWidth = 220;

const modalStyles = css`
  .bs4-modal-body {
    padding: ${doubleSpacing}px;
  }
`;

const modalContentStyles = css`
  height: 100%;
  display: flex;
  flex-direction: column;

  button {
    max-height: 35px;
  }

  .select-role {
    .select-role-message {
      color: ${black};
      margin-bottom: ${standardSpacing}px;
    }

    .select-role-form {
      display: flex;
      gap: ${halfSpacing}px;
      align-items: center;

      .nv-dropdown {
        width: ${dropdownFormWidth}px;

        .dropdown-role-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: ${doubleSpacing}px;
          padding: 0 ${halfSpacing}px;
          border: 1px solid ${gray4};
          border-radius: ${quarterSpacing}px;

          .dropdown-role-title {
            color: ${gray2};
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .icon {
            color: ${gray3};
          }
        }

        .bs4-dropdown-menu {
          height: auto;
          max-height: 200px;
          overflow-x: hidden;
          overflow-y: auto;
        }

        .bs4-dropdown-item {
          display: flex;
          padding-left: ${halfSpacing}px;
          padding-right: ${halfSpacing}px;
        }
      }
    }
  }

  .divider {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${standardSpacing}px;
    margin: ${largeSpacing}px 0;

    .line {
      height: 1px;
      width: ${largeSpacing}px;
      background-color: ${gray4};
    }

    span {
      color: ${gray2};
    }
  }

  .user-management {
    span {
      color: ${black};
    }

    a {
      color: ${primary};
    }
  }

  .actions {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
`;

const popOverStyles = css`
  .permissions-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: ${halfSpacing}px;
  }

  .permissions {
    > div {
      margin-bottom: ${halfSpacing}px;

      .permission-name {
        font-weight: bold;
        margin-right: ${quarterSpacing}px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

function ManageCourseAccessModal() {
  const { $state, $scope, RailsRoutes } = useContext(AngularServicesContext);
  const { catalogId } = useSelector(getCourseAccessModalProps);
  const course = useSelector(state => getCourse(state, catalogId));
  const currentUser = useSelector(getCurrentUser);
  const roles = useSelector(getCourseRoles);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [isJoiningCourse, setIsJoiningCourse] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState<number>(-1);
  const { injectServices } = React.useContext(AngularContext);

  const [
    $timeout,
    CurrentUserManager,
  ] = injectServices([
    '$timeout',
    'CurrentUserManager',
  ]);

  const resetModalProps = () => {
    dispatch(resetCourseAccessModalProps());
  };

  const closeModal = () => {
    setShowModal(false);
    resetModalProps();
  };

  useEffect(() => {
    if (catalogId) {
      setShowModal(true);
    }

    setSelectedRoleId(-1);
  }, [catalogId]);

  // useEffect to cleanup modal props in redux store only when unmount
  useEffect(() => () => closeModal(), []);

  const handleJoinCourseClick = () => {
    setIsJoiningCourse(true);

    const params: EnrollWithRoleParams = {
      catalogId,
      courseRoleId: selectedRoleId,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUser.email,
    };

    wrapThunkAction(dispatch(enrollWithRole(params)))
      .then((action) => {
        $timeout(() => {
          CurrentUserManager.addUserCourse(action.payload);
          CurrentUserManager.user.enrollments.push(action.payload);
        });
        $scope.CurrentCourseManager.requestCourse(catalogId, true).then((enrolledCourse: FullCourse) => {
          if (enrolledCourse?.userCourse?.termsAcceptanceNeeded) {
            window.location.href = RailsRoutes.termsPath(catalogId);
          } else if (course?.isProgram) {
            $state.go('program-home', { catalogId });
          } else {
            $state.go('course-home', { catalogId });
          }

          closeModal();
          setIsJoiningCourse(false);
        });
      })
      .catch(() => {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.USERS.REGISTRATION.ERRORS.GENERIC(),
        }));
      });
  };

  const rolePopOverContent = (role: CourseRole) => {
    const permissions = role?.permissions.filter(Boolean);

    if (!permissions?.length) {
      return <span>{t.INSTITUTIONS.ROLES.LEARNER_ROLE_DESC()}</span>;
    }

    return (
      <div css={popOverStyles}>
        <div className='permissions-title'>{t.INSTITUTIONS.ROLES.PERMISSIONS()}</div>
        <div className='permissions'>
          {permissions.map(permission => (
            <div key={permission.type}>
              <span className='permission-name'>
                {permission?.name
                  ? t.INSTITUTIONS.ROLES[`${getTranslateKey(permission.name)}`](aliases)
                  : ''}
                :
              </span>
              <span>
                {t.INSTITUTIONS.ROLES[`${getTranslateKey(permission.helpText)}`](aliases)}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const rolesItems: NvDropdownCustomItem[] = roles.map((role: CourseRole) => ({
    type: 'custom',
    id: role.id,
    customItem: (
      <Dropdown.Item
        className='role-item'
        as='button'
        onClick={() => setSelectedRoleId(role.id)}
      >
        <div>
          {role.name}
        </div>
        <div>
          <NvPopover
            content={rolePopOverContent(role)}
            placement='top'
            preventOverflow
            showOnHover
          >
            <div>
              <NvIcon icon='info' size='xss-smallest' className='color-primary' />
            </div>
          </NvPopover>
        </div>
      </Dropdown.Item>
    ),
  }));

  const selectedRoleIdIndex = rolesItems.findIndex(role => role.id === selectedRoleId);

  const customDropdownTarget = () => {
    const title = selectedRoleIdIndex > -1
      ? roles[selectedRoleIdIndex].name
      : t.INSTITUTIONS.MANAGE_COURSE_ACCESS_MODAL.SELECT_ROLE_PLACEHOLDER();

    return (
      <div className='dropdown-role-container'>
        <span className='dropdown-role-title'>{title}</span>
        <div className='icon text-xs icon-dropdown-arrow' />
      </div>
    );
  };

  const userManagementLink: string = $state.href('user-management-course-admins', { catalogId });
  const isCourseFlyer = $state?.current?.name === 'course-flyer';

  const modalContent = (
    <div className='manage-course-access-modal-content' css={modalContentStyles}>
      <div className='select-role'>
        <div className='select-role-message'>
          {t.INSTITUTIONS.MANAGE_COURSE_ACCESS_MODAL.SELECT_ROLE_DROPDOWN_MESSAGE()}
        </div>
        <div className='select-role-form'>
          <NvDropdown
            items={rolesItems}
            minWidth={dropdownFormWidth}
            align={isRtl() ? NvDropdownAlign.RIGHT : NvDropdownAlign.LEFT}
            initialIndex={selectedRoleIdIndex}
            customTarget={customDropdownTarget}
            buttonStyle={NvDropdownButtonStyle.CUSTOM}
          />
          <Button
            onClick={handleJoinCourseClick}
            disabled={(selectedRoleId < 0) || isJoiningCourse}
            pendo-tag-name={config.pendo.manageCourseAccessModal.manageCourseAccessJoin}
          >
            {t.INSTITUTIONS.MANAGE_COURSE_ACCESS_MODAL.JOIN_COURSE()}
          </Button>
        </div>
      </div>
      <div className='divider'>
        <div className='line' />
        <span>{t.SHARED.OR()}</span>
        <div className='line' />
      </div>
      <div className='user-management'>
        {t.INSTITUTIONS.MANAGE_COURSE_ACCESS_MODAL.USER_MANAGEMENT_LINK(
          !isCourseFlyer ? 'true' : '',
          userManagementLink,
        )}
      </div>
      <div className='actions'>
        <Button
          onClick={closeModal}
          pendo-tag-name={config.pendo.manageCourseAccessModal.manageCourseAccessClose}
        >
          {t.FORM.CLOSE()}
        </Button>
      </div>
    </div>
  );

  return (
    <NvModal
      show={showModal}
      width={600}
      height={460}
      type={ModalType.FIXED}
      header={t.INSTITUTIONS.MANAGE_COURSE_ACCESS_MODAL.TITLE()}
      body={modalContent}
      modalStyles={modalStyles}
      onClose={closeModal}
      closePendoTagName={config.pendo.manageCourseAccessModal.manageCourseAccessX}
    />
  );
}

export default ManageCourseAccessModal;
