import { useAngularPreventLecturePageNavigation } from '../../lecture_pages/hooks/use-prevent-lecture-page-navigation';
import { getSkillTagsFromAngular } from '../../shared/services/nv-util';

/* @ngInject */
export default function NvDiscussionNewReply(
  CurrentUserManager,
  CurrentCourseManager,
  MentionablesModel,
  DiscussionsManager,
  StateManager,
  _,
  ScrollFocusConnectorFactory,
  $timeout,
  nvCurrentPage,
  $uibPosition,
  AlertMessages,
  $uibModal,
  TimelinesManager,
  ReactTimelineService,
  nvUtil,
  $q,
  ConfirmationOverlays,
  config,
  ReactLecturePageContext
) {
  return {
    restrict: 'A',
    scope: {
      parentComment: '=',
      replyMention: '=',
      existingReply: '=',
      context: '=',
      report: '=?',
      isReplyToReply: '=?',
    },
    require: ['nvDiscussionNewReply', '?^^nvDiscussionComment', '?^nvDiscussionReply'],
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;

      vm.replyContent = null;
      vm.config = config;

      if (vm.replyMention) {
        vm.replyContent = MentionablesModel.createFroalaMentionableString(vm.replyMention);
      } else if (vm.existingReply) {
        vm.replyContent = vm.existingReply.body;
      }

      vm.currentUserManager = CurrentUserManager;
      vm.currentCourseManager = CurrentCourseManager;
      vm.replyBoxFocused = true;
      vm.mentionablesModel = MentionablesModel;
      vm.discussionsManager = DiscussionsManager;
      vm.submitting = false;
      vm.scrollElementRegistered = false;
      vm.showInstructionsModal = showInstructionsModal;
      vm.nvUtil = nvUtil;

      vm.editorFocus = editorFocus;
      vm.editorBlur = editorBlur;


      // Get current Course from the state catalogId
      vm.course = CurrentUserManager.getCurrentCourse();
      if (!vm.course) {
        // Fallback for some reason course is not available use course set in CurrentCourseManager
        vm.course = CurrentCourseManager.course;
      }

      if (this.report?.exercise.customQuestions) {
        vm.currentTotalPoints = nvUtil.getCurrentTotalPoints(this.report.exercise.customQuestions.pointsPerPeerReview,
          this.report.exercise.customQuestions.releaseDate, vm.course.isDecayEnabled());
      }

      const initConfirmationOverlay = () => {
        const msg = 'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY';
        const overlay = 'discussions/templates/nv-unsaved-changes-confirmation-overlay.html';
        const unsavedChanges = () => !!(
          vm.existingReply ? vm.existingReply.body !== vm.replyContent : vm.replyContent?.length
        );

        // Not a hook.
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useAngularPreventLecturePageNavigation($scope, unsavedChanges);

        vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(unsavedChanges, overlay, msg);
      };

      vm.$onInit = function () {
        initConfirmationOverlay();
      };

      vm.$onDestroy = function () {
        vm.deregisterStateChangeStart?.();
      };

      $scope.$watch(
        'replyMention',
        (newValue) => {
          if (newValue) {
            vm.replyContent = MentionablesModel.createFroalaMentionableString(vm.replyMention);
          }
        },
      );

      $scope.$watch(() => vm.replyContent, (newValue, oldValue) => {
        if (newValue && newValue !== oldValue) {
          vm.hasMeaningfulContent = nvUtil.hasMeaningfulContent(newValue);
        }
      });

      vm.noop = function () {
        $.noop();
      };


      function showInstructionsModal() {
        $uibModal.open({
          templateUrl: 'submissions/templates/informal-feedback-modal.html',
          windowClass: 'informal-feedback-modal',
          controller: 'InformalFeedbackModalCtrl as vm',
          resolve: {
            currentExercise: vm.report.exercise,
            currentPeerEvaluation: this.report.exercise.customQuestions,
          },
        });
      }

      function editorFocus() {
        vm.editorInFocus = true;
      }

      function editorBlur() {
        vm.editorInFocus = false;
      }
    },
    link: {
      pre: (scope, elem, attrs, ctrls) => {
        const [vm] = ctrls;
        vm.scrollParent = $uibPosition.scrollParent(elem);
      },
      post: (scope, elem, attrs, ctrls) => {
        const [vm, commentCtrl, replyCtrl] = ctrls;

        vm.scrollToReplyBox = scrollToReplyBox;
        vm.canLeaveFeedback = canLeaveFeedback;
        vm.afterReplySubmit = afterReplySubmit;
        vm.onNewReplyVisible = onNewReplyVisible;
        vm.editReply = editReply;
        vm.submitReply = submitReply;
        vm.registerScrollFocusElement = registerScrollFocusElement;
        vm.hideReplyUi = hideReplyUi;

        function hasUnsavedChanges() {
          return vm.newReplyForm?.$dirty && !vm.submitting;
        }

        function hideReplyUi() {
          let promise;
          if (hasUnsavedChanges()) {
            const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-inline-changes-confirmation-overlay.html');

            promise = modalOverlay.result;
          } else {
            promise = $q.when();
          }

          promise.then(() => {
            if (replyCtrl) {
              replyCtrl.cancelUpdateReply();
            } else {
              commentCtrl.hideReplyUi();
            }
          });

          return false;
        }

        if (commentCtrl) {
          // commentCtrl.registerEditReplyHandler(vm.editReply);
          commentCtrl.registerNewReplyVisibleHandler(vm.onNewReplyVisible);
        }

        scrollToReplyBox();

        function canLeaveFeedack() {
          return !vm.isReplyToReply && vm.report.canLeaveFeedback();
        }

        function onNewReplyVisible(mention, isEditing) {
          if (mention) {
            vm.replyContent = MentionablesModel.createFroalaMentionableString(mention);
          } else if (!isEditing) {
            vm.replyContent = null;
          }

          scrollToReplyBox();
        }

        function editReply(existingReply) {
          vm.existingReply = existingReply;
          vm.replyContent = existingReply.body;
        }

        function canLeaveFeedback() {
          return vm.report
              && (!vm.report.isCollaborator
              && !vm.isReplyToReply
              && vm.report.isPublicFeedbackOn
              && !vm.report.gavePublicFeedback
              && vm.report.exercise.customQuestions.released
              && !vm.report.exercise.customQuestions.expired);
        }

        function submitReply() {
          vm.submitting = true;

          if (vm.replyContent && vm.context !== 'report') {
            if (vm.existingReply) {
              vm.parentComment.updateReply(_.extend(vm.existingReply, { body: vm.replyContent })).then((newReply) => {
                vm.existingReply = null; // clear out the old value
                afterReplySubmit(newReply, false, true);
              });
            } else {
              const previousPointsReceived = vm.parentComment.owner.pointsReceived || 0;

              DiscussionsManager.createReply(vm.replyContent, vm.parentComment, MentionablesModel.parseMentionables(vm.replyContent), vm.isReplyToReply).then((response) => {
                vm.parentComment.commented = true;
                afterReplySubmit(response.result, true)
                  .then(() => {
                    vm.newReplyForm.$setPristine();
                    const isCompleted = vm.parentComment.owner.progress === 'completed';
                    const skillTags = getSkillTagsFromAngular(vm.parentComment.owner.lectureComponentId);
                    const skillsEnabled = skillTags.length > 0;
                    const showSkillTags = skillsEnabled && !isCompleted;

                    if (vm.parentComment.owner?.pointsReceived > previousPointsReceived || showSkillTags) {
                      ReactTimelineService.updateTimeline(vm.parentComment.owner.metaContent.lecturePage.id);
                      TimelinesManager.updateComponentPointsAndProgress(
                        vm.parentComment.owner.metaContent.lecturePage.id,
                        'topic',
                        vm.parentComment.owner.id,
                        vm.parentComment.owner.pointsReceived,
                      );
                      vm.parentComment.owner.progress = 'completed';
                      $uibModal.open({
                        templateUrl: 'shared/templates/points-modal.html',
                        windowClass: 'points-modal',
                        controller: 'PointsModalCtrl as vm',
                        resolve: {
                          pointsReceived: vm.parentComment.owner.pointsReceived,
                          leaderboardPoints: vm.parentComment.owner.leaderboardPoints,
                          leaderboardRank: vm.parentComment.owner.leaderboardRank,
                          priorLeaderboardRank: vm.parentComment.owner.priorLeaderboardRank,
                          extras: { skillTags },
                        },
                      });
                    } else if (vm.parentComment.owner.metaContent && vm.parentComment.owner.metaContent.lecturePage) {
                      ReactTimelineService.updateTimeline(vm.parentComment.owner.metaContent.lecturePage.id);
                      TimelinesManager.updateComponentProgress(vm.parentComment.owner.metaContent.lecturePage.id, 'topic', vm.parentComment.owner.id, 'completed');
                      vm.parentComment.owner.progress = 'completed';
                    }
                  });
              }, () => {
                /**
                 * Giving the failure feedback to user only on promise rejection,
                 * to prevent error of showing because of any thrown error.
                 */
                AlertMessages.error('', 'FORM.ERROR');
                vm.submitting = false;
              });
            }
          } else if (vm.replyContent && vm.context === 'report') {
            if (vm.existingReply) {
              vm.parentComment.updateReply(_.extend(vm.existingReply, { body: vm.replyContent })).then((newReply) => {
                vm.existingReply = null; // clear out the old value
                afterReplySubmit(newReply, false, true);
              });
            } else {
              DiscussionsManager.createReply(vm.replyContent, vm.parentComment, MentionablesModel.parseMentionables(vm.replyContent), vm.isReplyToReply).then((response) => {
                vm.newReplyForm.$setPristine();
                const latestReply = response.result;

                vm.parentComment.commented = true;

                afterReplySubmit(latestReply, true)
                  .then(() => {
                    if (latestReply.isPublicFeedback && !vm.isReplyToReply) {
                      vm.report.gavePublicFeedback = true;

                      ReactLecturePageContext?.updateAngularComponentStatus?.(
                        'exercise_peer_review',
                        vm.report.exercise.customQuestions.id,
                        latestReply,
                      );

                      const isCompleted = vm.parentComment.owner.progress === 'completed';
                      const skillTags = getSkillTagsFromAngular(vm.parentComment.owner.lectureComponentId);
                      const skillsEnabled = skillTags.length > 0;
                      const showSkillTags = skillsEnabled && !isCompleted;

                      if (latestReply.pointsReceived || showSkillTags) {
                        ReactTimelineService.updateTimeline(vm.report.exercise.customQuestions.lecturePageId);
                        TimelinesManager.updateComponentPointsAndProgress(vm.report.exercise.customQuestions.lecturePageId, 'exercise_peer_review',
                          vm.report.exercise.customQuestions.id, latestReply);

                        $uibModal.open({
                          templateUrl: 'shared/templates/points-modal.html',
                          windowClass: 'points-modal',
                          controller: 'PointsModalCtrl as vm',
                          resolve: {
                            pointsReceived: latestReply.pointsReceived,
                            leaderboardPoints: latestReply.leaderboardPoints,
                            leaderboardRank: latestReply.leaderboardRank,
                            priorLeaderboardRank: latestReply.priorLeaderboardRank,
                            extras: { skillTags },
                          },
                        });
                      } else {
                        if (latestReply.peerEvaluation.numEvaluated === vm.report.exercise.customQuestions.targetGoal) {
                          AlertMessages.success('', 'INFORMAL_FEEDBACK.ALL_SUBMISSIONS_SUCCESS', {}, { targetGoal: vm.report.exercise.customQuestions.targetGoal });
                        } else {
                          AlertMessages.success('', 'INFORMAL_FEEDBACK.SUBMISSION_SUCCESS');
                        }
                        ReactTimelineService.updateTimeline(vm.report.exercise.customQuestions.lecturePageId);
                        TimelinesManager.updateComponentProgress(vm.report.exercise.customQuestions.lecturePageId, 'exercise_peer_review',
                          vm.report.exercise.customQuestions.id, 'completed');
                      }
                    }
                  });
              }, () => {
                /**
                 * Giving the failure feedback to user only on promise rejection,
                 * to prevent error of showing because of any thrown error.
                 */
                AlertMessages.error('', 'FORM.ERROR');
                vm.submitting = false;
              });
            }
          }
        }

        function afterReplySubmit(newReply, isNew, isUpdating) {
          return DiscussionsManager.setReplyData(newReply, vm.parentComment, false, isNew, isUpdating).then(() => {
            vm.submitting = false;
            vm.replyContent = null;
            vm.isReplyToReply = false;


            if (replyCtrl) {
              replyCtrl.afterReplyEdit();
            }
          }, () => {
            vm.submitting = false;
            vm.replyContent = null;

            if (replyCtrl) {
              replyCtrl.afterReplyEdit();
            }
          });
        }


        function registerScrollFocusElement() {
          const $froalaElement = elem.find('[nv-froala-editor]');

          if ($froalaElement.length) {
            ScrollFocusConnectorFactory.register($froalaElement,
              getScrollParent(),
              vm.parentComment.id,
              'comment');

            vm.scrollElementRegistered = true;
          }
        }

        function scrollToReplyBox() {
          if (!vm.scrollElementRegistered) {
            registerScrollFocusElement();
          }

          $timeout(() => {
            ScrollFocusConnectorFactory.focus(
              vm.parentComment.id,
              'comment',
              DiscussionsManager.context === 'report' ? { offset: 100 } : { offset: 30 },
              !!vm.replyMention || !!vm.existingReply,
            );
          });
        }

        function getScrollParent() {
          if (DiscussionsManager.context !== 'report') {
            return angular.element($uibPosition.scrollParent(elem));
          }

          if (nvCurrentPage.getCurrentStateData().level < 4) {
            return $('.lightbox-panel');
          }

          return $('.modal-content-wrapper');
        }
      },
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'discussions/templates/nv-discussion-new-reply.html',
  };
}
