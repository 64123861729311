import React from 'react';

import { AngularContext } from 'react-app';

// TODO: Using angular translation service because 'react-translate' isn't
// including translations from languages/global.yml
const useLanguageTranslation = () => {
  const { injectServices } = React.useContext(AngularContext);
  const [$translate] = injectServices(['$translate']);

  return React.useCallback(
    (translationKey: string) => $translate.instant(`LANGUAGE_LABEL.${translationKey}`),
    [$translate],
  );
};

export default useLanguageTranslation;
