import { PermissionTypes, OrgAdminRole, CollectionPermissionTypes } from 'institutions/services/roles-service';

/* @ngInject */
export default function CurrentPermissionsManagerService(
  RolesService,
) {
  /**
   * Is used throughout the app to determine whether the current user has permissions to do some action
   * on the current course. Also checks for admin status when used outside of a course. All permission checks
   * in Origami should be handled by this service.
   */
  class CurrentPermissionsManager {
    /** Permissions are calculated based on the current user, course, and institution. These are set via these setter
     * methods because injecting the CurrentUserManager, CurrentCourseManager, and InstitutionsManager services results
     * in a circular dependency. */

    /** Set in CurrentUserManager */
    setCurrentUser(user) {
      this.user = user;
    }

    /** Set in CurrentCourseManager */
    setCurrentCourse(course) {
      this.course = course;

      if (!this.institution && this.course.institution) {
        this.setCurrentInstitution(this.course.institution);
      }
    }

    /** Set in CurrentUserManager and *not* in InstitutionsManager */
    setCurrentInstitution(institution) {
      this.institution = institution;
    }

    hasNovoEdPermissions() {
      return this.user?.admin;
    }

    /**
      * Check the user is a institutional Admin with the Org admin role or a NovoEd admin
      * This user has the full organizational permissions.
      */
    hasOrgAdminPermissions() {
      // There are edge cases where institution or user can be null, such as when loading in to the course flyer page
      return (this.institution?.isInstitutionalAdmin && this.institution.adminRole === OrgAdminRole.ORG_ADMIN)
        || (this.user?.admin);
    }

    /**
      * Org admin course manager is a type of institutional Admin,
      * but who has the limited access in organization permissions.
      */
    hasCourseManagerPermissions() {
      return (this.institution?.isInstitutionalAdmin && this.institution.adminRole === OrgAdminRole.COURSE_MANAGER)
        || this.hasOrgAdminPermissions();
    }

    /**
      * Check if the user is an institutional mentor or the user has the
      * organizational mentor permission in the institution.
      */
    hasOrgMentorPermissions() {
      return (this.user?.isInstitutionMentor) || (this.institution?.isInstitutionalMentor);
    }

    /**
      * The user has 'course admin' permisisons if they're either an org admin, have a course admin
      * role for this course, or are a NovoEd admin. This is not the same as the old 'course admin' property, which is now deprecated.
      */
    hasCourseAdminPermissions() {
      let roles = null;
      if (this.course?.userCourse) {
        ({ roles } = this.course.userCourse);
      }
      return this.hasCourseManagerPermissions() || (roles && RolesService.hasAdminPermissions(roles.permission));
    }

    // The following 'is...' functions check whether the user has the listed permissions, which is always true if they're an admin.
    // We do not check against the user's literal role in the client side, only their permissions.
    isConfigAndRegistrationRole() {
      return this.isNonLearnerRole(PermissionTypes.CONFIGURATION_REGISTRATION) || this.hasCourseManagerPermissions();
    }

    isInstructor() {
      return this.isNonLearnerRole(PermissionTypes.INSTRUCTOR) || this.hasCourseManagerPermissions();
    }

    isSupervisor() {
      return this.user?.mentoringInfo?.isMentor;
    }

    isTeachingAssistant() {
      return this.isNonLearnerRole(PermissionTypes.TEACHER_ASSISTANT) || this.hasCourseManagerPermissions();
    }

    isCourseBuilder() {
      return this.isNonLearnerRole(PermissionTypes.COURSE_BUILDER) || this.hasCourseManagerPermissions();
    }

    isReportAdmin() {
      return this.isNonLearnerRole(PermissionTypes.REPORT_ADMIN) || this.hasCourseManagerPermissions();
    }

    isMentor() {
      return this.isNonLearnerRole(PermissionTypes.MENTOR);
    }

    isLearnerRegistrationRole() {
      return this.isNonLearnerRole(PermissionTypes.LEARNER_REGISTRATION);
    }

    hasJourneyAnalyticsAccess() {
      return this.hasOrgAdminPermissions()
        || this.hasCourseManagerPermissions()
        || this.isConfigAndRegistrationRole()
        || this.isInstructor()
        || this.isReportAdmin();
    }

    // Some elements needs to be hidden if the user have learner Registration Permission Only
    hasFullCourseAdminPermissions() {
      return this.isConfigAndRegistrationRole()
        || this.isInstructor()
        || this.isTeachingAssistant()
        || this.isCourseBuilder()
        || this.isReportAdmin();
    }

    getPendoProperties() {
      return {
        // permissions data
        hasCourseBuilderPermission: this.isCourseBuilder(),
        hasTeachingAssistantPermission: this.isTeachingAssistant(),
        hasInstructorPermission: this.isInstructor(),
        hasReportAdminPermission: this.isReportAdmin(),
        hasConfigurationAdminPermission: this.isConfigAndRegistrationRole(),
        hasMentorPermission: this.isMentor(),
        hasAnyAdminPermission: this.hasCourseAdminPermissions(),
      };
    }

    /** Whether this user has the specified role
     * @param {PermissionTypes} adminType */
    isNonLearnerRole(adminType) {
      let roles = null;

      if (this.course?.userCourse) {
        ({ roles } = this.course.userCourse);
      }

      if (!roles || !roles.permission) {
        return false;
      }
      return RolesService.hasPermission(roles.permission, adminType);
    }

    /**
       * The user is a 'learner' if they have no course admin roles and or not an org or NovoEd admin.
       * They *are* a learner if they have a 'custom' role, as these have no special permissions.
       */
    isLearner() {
      if (this.hasCourseManagerPermissions()) {
        return false;
      }

      let roles = null;

      if (this.course?.userCourse) {
        ({ roles } = this.course.userCourse);
      }

      if (roles?.permission) {
        return roles.permission === PermissionTypes.LEARNER || roles.permission === PermissionTypes.CUSTOM_ROLE;
      }
      return true;
    }

    // Collection related permission functions from here.
    hasRoleInCourse(roleType, roles = null) {
      if (!roles && this.course?.userCourse) {
        ({ roles } = this.course.userCourse);
      }

      if (!roles || !roles.permission
        // prevent permission if the user has unenrolled from the collection
        || (this.course?.userCourse && !this.course.userCourse.isEnrolled)
      ) {
        return false;
      }

      return roles.permission === roleType;
    }

    hasCollectionAccess() {
      return this.user.hasEnrolledInAnyCollection || this.hasCourseManagerPermissions();
    }

    hasCollectionAdminPermissions(roles = null) {
      return this.hasRoleInCourse(CollectionPermissionTypes.ADMIN, roles);
    }

    hasCollectionManagerPermissions(roles = null) {
      return this.hasRoleInCourse(CollectionPermissionTypes.MANAGER, roles)
        || this.hasCollectionAdminPermissions(roles);
    }

    hasCollectionBuilderPermissions(roles = null) {
      return this.hasRoleInCourse(CollectionPermissionTypes.BUILDER, roles)
        || this.hasCollectionAdminPermissions(roles);
    }

    hasCollectionViewerPermissions(roles = null) {
      return this.hasRoleInCourse(CollectionPermissionTypes.VIEWER, roles)
        || this.hasCollectionManagerPermissions(roles)
        || this.hasCollectionBuilderPermissions(roles);
    }
  }

  return new CurrentPermissionsManager();
}
