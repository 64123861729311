// TODO: Rename this from 'popover'

export default {
  bindings: {
    onRoleChanged: '&?',
    initialRole: '<?',
    learnersOnly: '=',
    adminsOnly: '=',
    selectedRole: '=?',
  },
  controllerAs: 'vm',
  controller: function ctrl($element, _, CourseRolesManager) {
'ngInject';
    const vm = this;
    vm.CourseRolesManager = CourseRolesManager;
    vm.isDropdownOpen = false;

    if (vm.initialRole) {
      vm.selectedRole = vm.initialRole;
    }

    if (!vm.selectedRole) {
      vm.selectedRole = CourseRolesManager.noRolesRole;
    }

    vm.onRolesRadioChanged = (role) => {
      vm.selectedRole = role;
      if (vm.onRoleChanged) {
        vm.onRoleChanged()(role);
      }

      vm.isDropdownOpen = false;
    };

    /** There's an IE 11 render bug where this dropdown does open upon click but doesn't actually render.
       * You'll see graphical artifacts on mouseover of the 'invisible' dropdown, as well.
       * focus() forces the browser to actually draw the dropdown. */
    vm.ieForceRerender = () => {
      $element.focus();
    };

    return vm;
  },
  templateUrl: 'user_management/templates/nv-roles-dropdown.html',
};
