// TODO: should this be merged with create-action-creators.ts?
import humps from 'humps';
import { JourneysOnly, PagedDataQueryParams } from 'redux/create-action-creators';
import _ from 'underscore';

export type InstitutionIdParam = { institutionId?: number };

/** Creates a string to be uses as the query parameters for a page data HTTP get request */
export const makeQueryParamString = (queryParams: any): string => {
  const encoded = [];
  Object.keys(queryParams).forEach(key => {
    if (queryParams[key] === undefined || queryParams[key] === null) {
      delete queryParams[key];
    } else {
      encoded.push(`${humps.decamelize(key)}=${queryParams[key]}`);
    }
  });

  return encoded.join('&');
};

/** Creates a queryParams object usable by makeQueryParamString
 * TODO: Give this a proper return type and restructure */
export const makeQueryParams = (params: InstitutionIdParam & PagedDataQueryParams & JourneysOnly, pageSize?: number, page?: number) => {
  const queryParams: any = {
    institution_id: params.institutionId,
    text: params.searchQuery,
  };

  if (pageSize) {
    queryParams.page_size = pageSize;
  }

  if (page) {
    queryParams.page = page;
  }
  // Adding this when searching in learning journeys dashboard.
  if (params.journeysOnly) {
    queryParams.journeysOnly = 1;
  }

  _.extend(queryParams, params.filters);

  /**
   * So here, we're adding the sorting params two differenet ways; this is because we wanted to change the implementation
   * from the way it's used on the institution dashboard. Effectively, the team-facilitation will look at the parameters
   * from the 'sort' param, and the institution dashboard will use the keys like 'name_asc'
   * This comes from https://specs.openstack.org/openstack/api-wg/guidelines/pagination_filter_sort.html#sorting
   */
  // TODO: update the institution-dashboard to use sorting in this way, and removed the params.sorting.forEach
  // NOTE: it's likely possible that some improvement could be made to the way that this is implemented overall, but it
  //       won't make sense to do that until updating the inst. dashboard
  queryParams.sort = params.sorting?.join(',');

  params.sorting?.forEach((key) => {
    queryParams[key] = 1;
  });

  return queryParams;
};
