import { css } from '@emotion/react';
import { LecturePageMode } from 'lecture_pages/components';
import getDefaultAddMenuComponentData from 'lecture_pages/components/data/add-menu-defaults';
import HeaderLectureComponent from 'lecture_pages/components/header/header-lecture-component';
import { HeaderComponentType } from 'lecture_pages/services/components/content_templates/header-lecture-component-model';
import { LectureComponent } from 'redux/schemas/models/lecture-component';
import { primary } from 'styles/global_defaults/colors';

/** A view-only version of the HeaderLectureComponent for use in the left panel add menu
 * TODO: Consider renaming this + turning this into a reusable pattern */
const HeaderComponentContentPreview = (props: { type: HeaderComponentType }) => (
  <div css={css`
    /* TODO: Fix using styled components as selectors */
    //       padding: 10px !important;
    .content {
      font-size: 14px !important;
      line-height: 20px !important;
      cursor: pointer;
    }

    /* TODO: This is very dangerous. Remove/rework after fixing the style component selectors */
    .align-items-center {
      padding: 10px !important;
    }

    .left-border {
      width: 5px !important;
    }

    /* Strangely we can't recreate this w/ the normal Header3 rendering logic because you cannot apply
    a colored bkg to an image as currently written (there's no business case for it, I believe)*/
    .header-image {
      width: 40px !important;
      height: 40px !important;
      background-size: 50% !important;
      background-color: ${primary} !important;
      cursor: pointer;
    }
  `}
  >
    <HeaderLectureComponent
      containerRef={null}
      lectureComponent={getDefaultAddMenuComponentData(props.type) as LectureComponent<HeaderComponentType>}
      currentLecture={null}
      mode={LecturePageMode.VIEW}
      isPreview
      textClassName='content'
    />
  </div>
);

export default HeaderComponentContentPreview;
