import { ActivityKey, ActivityType } from './activity';
import { LecturePageKey, LecturePageType } from './lecture-page';
import { User } from './my-account';


export enum ScheduledForType {
  ON_DATE = 'on_date',
  BEFORE_DATE = 'before_date',
  AFTER_DATE = 'after_date',
  SEND_NOW = 'send_now',
  SCHEDULED = 'scheduled',
  USER_DEFINED = 'user_defined', // This is needed for BE, for course welcome email
}

export enum CommunicationType {
  COMPLETED = 'completed',
  NOT_COMPLETED = 'not_completed',
  RELEASE_DATE_EMAIL = 'release_date_email',
  DUE_DATE_EMAIL = 'due_date_email',
  MANUAL_EMAIL = 'scheduled_manual_email',
  AUTOMATED_JOURNEY_EMAIL = 'automated_journey_email',
  DUE_DATE_ANNOUNCEMENT = 'due_date_announcement',
  RELEASE_DATE_ANNOUNCEMENT = 'release_date_announcement',
  MANUAL_ANNOUNCEMENT = 'scheduled_manual_announcement',
  COURSE_WELCOME_EMAIL = 'course_registration_email',
}

export enum CommunicationCategory {
  TRIGGERS = 'TRIGGERS',
  SCHEDULED_EMAIL = 'SCHEDULED_EMAIL',
  SCHEDULED_ANNOUNCEMENT = 'SCHEDULED_ANNOUNCEMENT',
  WELCOME_EMAIL = 'WELCOME_EMAIL',
  AUTOMATED_JOURNEY_EMAIL = 'AUTOMATED_JOURNEY_EMAIL',
}

export enum Recipient {
  TRIGGER_LEARNER = 'student', // Backend need this separate for triggers only
  LEARNERS = 'students',
  MENTORS = 'mentors',
  COURSE_ADMINS = 'course_admins',
  TEAM_LEADS = 'team_admins',
  GROUP_ADMINS = 'group_admins',
  REGISTERED_USER = 'registered_user',
}

export type OwnerType = LecturePageType | ActivityType;

export type OwnerKey = LecturePageKey | ActivityKey;

enum SendTiming {
  IMMEDIATELY = 'immediately_after',
  X_DAYS_AFTER = 'send_x_days_after',
}

export enum ItemState {
  NORMAL = 'normal',
  SENT = 'sent',
  ACTIVATED = 'activated',
  ERROR = 'error',
  DRAFT = 'draft',
}

export enum NotificationType {
  MAIL = 'messages',
  MOBILE = 'mobile',
  ANNOUNCEMENT = 'announcements',
}

export enum CommunicationErrorCode {
  MISSING_COMPLETED_ACTIVITY_FILTER_ERROR = 'missing_completed_activity_filter_error',
  MISSING_NOT_COMPLETED_ACTIVITY_FILTER_ERROR = 'missing_not_completed_activity_filter_error',
  MISSING_COMPLETED_ACTIVITY_EVENT_ERROR = 'missing_completed_event_activity_error',
  COURSE_POINTS_DISABLED = 'course_points_disabled',
  COURSE_ROLE_DELETED_FILTER_ERROR = 'course_role_deleted',
  COURSE_NO_TODO_EXERCISES = 'course_no_todo_exercises',
  ACTIVITY_DUE_DATE_REMOVED = 'activity_due_date_removed',
  UNABLE_TO_TRACK_FEEDBACK_COMPLETION = 'unable_to_track_feedback_completion',
  UNABLE_TO_TRACK_COMPLETED_ACTIVITY_FEEDBACK_COMPLETION = 'unable_to_track_completed_activity_feedback_completion',
  UNABLE_TO_TRACK_NOT_COMPLETED_ACTIVITY_FEEDBACK_COMPLETION = 'unable_to_track_not_completed_activity_feedback_completion',
  UNABLE_TO_TRACK_EVENT_FEEDBACK_COMPLETION = 'unable_to_track_event_feedback_completion',
  UNABLE_TO_TRACK_THIRD_PARTY_COMPLETION = 'unable_to_track_third_party_completion',
  UNABLE_TO_TRACK_EVENT_THIRD_PARTY_COMPLETION = 'unable_to_track_event_third_party_completion',
  UNABLE_TO_TRACK_COMPLETED_ACTIVITY_THIRD_PARTY_COMPLETION = 'unable_to_track_completed_activity_third_party_completion',
  UNABLE_TO_TRACK_NOT_COMPLETED_THIRD_PARTY_COMPLETION = 'unable_to_track_not_completed_activity_third_party_completion',
  MISSING_PROFILE_COMPLETION_REQUIRED_QUESTIONS = 'missing_profile_completion_required_questions',
  MISSING_COMPLETED_PROFILE_COMPLETION_REQUIRED_QUESTIONS = 'missing_completed_profile_completion_required_questions',
  MISSING_NOT_COMPLETED_PROFILE_COMPLETION_REQUIRED_QUESTIONS = 'missing_not_completed_profile_completion_required_questions',
  MISSING_EVENT_PROFILE_COMPLETION_REQUIRED_QUESTIONS = 'missing_event_profile_completion_required_questions',
  // For Journey Communications
  MISSING_ENROLLED_COURSE_FILTER_ERROR = 'missing_enrolled_course_filter_error',
  MISSING_NOT_ENROLLED_COURSE_FILTER_ERROR = 'missing_not_enrolled_course_filter_error',
  MISSING_COMPLETED_COURSE_FILTER_ERROR = 'missing_completed_course_filter_error',
  MISSING_NOT_COMPLETED_COURSE_FILTER_ERROR = 'missing_not_completed_course_filter_error',
  MISSING_COMPLETED_COLLECTION_FILTER_ERROR = 'missing_completed_collection_filter_error',
  MISSING_NOT_COMPLETED_COLLECTION_FILTER_ERROR = 'missing_not_completed_collection_filter_error',
}

export enum HasLoggedInFilterType {
  HAS_LOGGED_IN = 'has_logged_in',
  HAS_NOT_SINCE_START_DATE = 'has_not_since_start_date',
  HAS_NOT_SINCE_RELEASE_DATE = 'has_not_since_release_date',
}

export enum ScheduledTimeLineLoadingType {
  FUTURE = 'future',
  PAST = 'past',
}

export enum FilterType {
  ANNOUNCEMENTS = 'filter_by_announcement',
  DATE_BASED_EMAILS = 'filter_by_email',
  COMPLETION_TRIGGERED_EMAILS = 'filter_by_trigger',
}

export enum ViewMode {
  FORM_VIEW = 'FORM_VIEW',
  EMAIL_PREVIEW = 'EMAIL_PREVIEW',
  NOTIFICATION_PREVIEW = 'NOTIFICATION_PREVIEW',
}

export enum FeedbackCategory {
  VIDEO_PRACTICE_FEEDBACK = 'video_practice_feedback',
  VIDEO_PRACTICE_SKILLS_FEEDBACK = 'video_practice_skills_feedback',
  ASSIGNMENT_SKILLS_FEEDBACK = 'assignment_skills_feedback',
  LIVE_EVENT_SESSION = 'session',
}

export type CommunicationFilter = {
  // For both Journey and Course Communications

  courseRolesList?: number[],
  daysInactive?: number,
  enrolledInLastXDays?: number,

  // For Course Communications

  daysActive?: number,
  hasCompleted?: {
    id: number,
    type: OwnerType,
    category?: FeedbackCategory,
  },
  hasNotCompleted?: {
    id: number,
    type: OwnerType,
    category?: FeedbackCategory,
  },
  earnedMoreThanXPoints?: number,
  earnedLessThanXPoints?: number,
  earnedAtLeastXPoints?: number,
  completedXAssignmentsAsTodo?: number,
  hasCompletedCourse?: boolean,
  enrolledInCourse?: boolean,
  visitsHomePage?: boolean,
  hasLoggedIn?: HasLoggedInFilterType,

  // For Journey communications

  neverActive?: boolean,
  notEnrolledToAnyCourse?: boolean,
  enrolledToCourseId?: number,
  notEnrolledToCourseId?: number,
  completedCourseId?: number,
  notCompletedCourseId?: number,
  completedCollectionId?: number,
  notCompletedCollectionId?: number,
  hasCompletedJourney?: boolean,
};

export interface Communication {
  id: number
  submitted: boolean,
  communicationType: CommunicationType,
  ownerId: number,
  ownerType: OwnerType,
  sendTiming?: SendTiming,
  xDays?: number,
  scheduledFor?: ScheduledForType,
  recipients: Recipient[],
  // Email content or announcement content
  communication: {
    subject: string,
    body: string,
    adminUserId?: number,
    hasEmail?: boolean,
  },
  communicationAdmin?: User,
  enablePushNotification: boolean,
  pushNotificationBody?: string,
  firstTriggeredAt?: string,
  editedBy: {
    fullName: string,
    updatedAt: string,
    isEdited: boolean,
  },
  hasErrors?: boolean,
  errorCodes?: CommunicationErrorCode[]
  filters?: CommunicationFilter,
  event?: CommunicationFilter
  lecturePageId?: number,
  state?: string, // Sent
  scheduledAt?: string,
  scheduledOn?: string,
  courseId?: number,
}

export interface CommunicationsNormalized { [id: string]: Communication }

export interface CourseActivitiesNormalized {
  [id: string]: Communication
}

export interface DraftCommunicationsEntities {
  communications: CommunicationsNormalized,
}

export interface AutomatedCommunicationsEntities {
  communications: CommunicationsNormalized,
}

export interface ScheduledCommunicationsEntities {
  communications: CommunicationsNormalized,
}

export interface FilteredCommunicationsEntities {
  communications: CommunicationsNormalized,
}
