/* @ngInject */
export default function ConversationModelService(

  MessageModel,
  _,
) {
  const ConversationModel = function (attributes) {
    const _this = this;
    _.extend(_this, attributes);

    _this.initialize = function () {
      _this.messages = _.map(attributes.messages, (message) => new MessageModel(message));
    };

    _this.initialize();
  };

  return ConversationModel;
}
