import { createContext, Dispatch, useCallback, useContext, useRef, useState } from 'react';
import { AngularServicesContext } from 'react-app';
import { FieldValues } from 'react-hook-form';
import { camelCase, isEqualWith } from 'lodash';
import { findWhere, isEmpty, isNumber, isBoolean, mapObject, isObject } from 'underscore';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment';

// Schemas
import { useAppDispatch } from 'redux/store';
import { Communication, CommunicationErrorCode, CommunicationType, FeedbackCategory, Recipient, ScheduledForType } from 'redux/schemas/models/course-communication';
import { Role } from 'redux/schemas/models/role';
import { TriggerType } from 'communications/course_communications/contexts/communication-context';

// Actions
import { createCommunication, updateCommunication, resetFilteredCommunications, currentSendingNowCommunication } from 'redux/actions/course-communications';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';

// Selectors
import { getCourseRoles } from 'redux/selectors/roles';
import { CommunicationParams, CreateCommunicationParams, UpdateCommunicationParams } from 'redux/schemas/api/course-communications';
import { getCourseAliases, getCourse, getCurrentCourse } from 'redux/selectors/course';
import { getFilteredCommunicationsState } from 'redux/selectors/course-communications';
import { getGroup, RoleGroup } from 'communications/course_communications/components/communication-modal/filters/filter-by-role';
import { ActivityType } from 'redux/schemas/models/activity';

/**
 * A default communication structure to load the form at first
 */
export const communicationDefaultState: Communication = {
  id: undefined,
  submitted: false,
  communicationType: undefined,
  ownerId: undefined,
  ownerType: undefined,
  lecturePageId: undefined,
  sendTiming: undefined,
  xDays: undefined,
  recipients: undefined,
  communication: {
    subject: undefined,
    body: undefined,
    adminUserId: undefined,
    hasEmail: undefined,
  },
  enablePushNotification: undefined,
  pushNotificationBody: undefined,
  firstTriggeredAt: undefined,
  editedBy: {
    fullName: undefined,
    updatedAt: undefined,
    isEdited: undefined,
  },
  hasErrors: undefined,
  errorCodes: [],
  filters: {},
  scheduledOn: undefined,
  // event: {},
};

export const CommunicationFormContext = createContext(null);

export const CommunicationFormProvider = ({
  children,
  ...props
}) => (
  <CommunicationFormContext.Provider value={{ ...props }}>
    {children}
  </CommunicationFormContext.Provider>
);

const STEPS = 3;

export enum DraftSubmittedState {
  INITIAL = '',
  SUBMITTING = 'loading',
  // For submitted state the saved timestamp is saved
}

export enum FormSubmittedState {
  INITIAL = '',
  SUBMITTING = 'submitting',
  SUBMITTED = 'submitted',
}

export type UseCommunicationMethods = {
  initialFormData: FieldValues,
  formData: FieldValues,
  updateFormData: (values: FieldValues, shouldDirty?: boolean) => void,
  getCommunicationDraft: () => Communication,
  isDirty: boolean,
  steps: number,
  activeStep: number,
  setActiveStep: Dispatch<number>,
  lastValidStep: number,
  setLastValidStep: Dispatch<number>,
  nextStep: () => void,
  prevStep: () => void,
  draftSubmittedAt: string,
  saveAsDraft: () => void,
  formSubmitState: string,
  submitForm: () => void,
};

// Field name to checkbox field name mapping to check for equality
const checkboxKeys = {
  courseRolesList: 'filterByRoles',
  daysInactive: 'daysInactiveChecked',
  notCompletedActivity: 'notCompletedActivityChecked',
  notCompletedType: 'notCompletedActivityChecked',
  notCompletedCategory: 'notCompletedActivityChecked',
  completedActivity: 'completedActivityChecked',
  completedType: 'completedActivityChecked',
  completedCategory: 'completedActivityChecked',
  earnedMoreThanXPoints: 'earnedMoreThanXPointsChecked',
  earnedLessThanXPoints: 'earnedLessThanXPointsChecked',
  completedXAssignmentsAsTodo: 'completedXAssignmentsAsTodoChecked',
  hasCompletedCourse: 'hasCompletedCourseChecked',
  hasCompletedJourney: 'hasCompletedJourneyChecked',
  enrolledInLastXDays: 'enrolledLastDaysChecked',
  hasLoggedIn: 'hasLoggedInChecked',
  daysActive: 'daysActiveChecked',
};

// Field name to Event Radio value mapping to check for equality
const radioValues = {
  enrolledInCourse: 'enrolled-in-course',
  visitsCourseHome: 'visits-course-home',
  completedActivity: 'completed-activity',
  completedType: 'completed-activity',
  earnedAtLeastXPoints: 'earned-points',
  completedXAssignmentsAsTodo: 'completed-assignments',
};

/**
 * Hook to handle all the functionalities in three steps of course communication
 * creation, updation and duplication. This hook stores the formData, checks
 * whether changes are made, stores the current form step, methods to go back
 * and forth and finally submitting drafts and submitting the communication
 * @param initialCommunication
 * @param extras
 */
const useCommunicationForm = (
  initialCommunication: Communication,
  extras: FieldValues,
  catalogId: string,
): UseCommunicationMethods => {
  const roles = useSelector((state) => getCourseRoles(state));
  const aliases = useSelector((state) => getCourseAliases(state));
  const currentCourse = useSelector((state) => getCourse(state, catalogId));
  const { filterType } = useSelector(state => getFilteredCommunicationsState(state, catalogId));
  const { isJourney } = useSelector((state) => getCurrentCourse(state));

  const { TimelinesManager } = useContext(AngularServicesContext);

  const initialFormData: FieldValues = buildFormData(initialCommunication, extras, roles, currentCourse.isSelfPaced, isJourney);

  const initialFormDataRef = useRef(initialFormData);
  const formDataRef = useRef({
    ...initialFormData,
  });

  const setInitialFormData = useCallback((formData) => {
    initialFormDataRef.current = {
      ...formData,
    };
  }, []);

  const [isDirty, setIsDirty] = useState(false);
  const resetFormData = useCallback(() => {
    formDataRef.current = {
      ...initialFormDataRef.current,
    };
    setIsDirty(false);
  }, []);

  const updateFormData = useCallback((values, shouldDirty = true) => {
    const filteredValues = mapObject(values, (val) => {
      // To cleanup the extra keys for dropdown fields
      if (isObject(val) && val.id) {
        return {
          id: val.id,
          value: val.value ?? val.id,
        };
      }

      return val;
    });

    const {
      daysActive,
      daysInactive,
      earnedMoreThanXPoints,
      earnedLessThanXPoints,
      completedXAssignmentsAsTodo,
      earnedAtLeastXPoints,
    } = formDataRef.current;

    formDataRef.current = {
      ...formDataRef.current,
      ...filteredValues,
    };

    /**
     * when the communication is an event (not-complete type)
     * the formdata will be having an 'event' key
     * which makes the two objects different initially,
     * so deleting the event property if event is null
     */
    if (!formDataRef.current?.event) {
      delete formDataRef.current.event;
    }

    const objectToCompare = {
      ...formDataRef.current,
      // After react hook form v6 -> v7 migration NvTextInput with number type
      // uses valueAsNumber, which means the form data won't have anymore null
      // and it will have 0 as default (that's apparently expected:
      // https://github.com/react-hook-form/react-hook-form/issues/6287),
      // following lines make sure null is there for comparison with lodash
      // isEqualWith function.
      daysActive: daysActive || null,
      daysInactive: daysInactive || null,
      earnedMoreThanXPoints: earnedMoreThanXPoints || null,
      earnedLessThanXPoints: earnedLessThanXPoints || null,
      completedXAssignmentsAsTodo: completedXAssignmentsAsTodo || null,
      earnedAtLeastXPoints: earnedAtLeastXPoints || null,
    };

    if (shouldDirty) {
      setIsDirty(
        !isEqualWith(
          objectToCompare,
          initialFormDataRef.current,
          (objValue, othValue, key) => {
            /**
             * If event is present, then this notComplete activity form.
             * Need to consider to check equality only if the corresponding
             * radio is checked or say event has the mapped value. So if the
             * radio button is not checked, no need to consider this
             */
            if (key && radioValues[key]
              && 'event' in formDataRef.current
              && formDataRef.current.event
              && formDataRef.current.event !== radioValues[key]) {
              return true;
            }

            /**
             * Get the corresponding checkbox value. Need to check the
             * equality on if it is checked. So if it is not checked, no
             * need to consider the equality and thus return true
             */
            const checkboxKey = checkboxKeys[key];
            if (checkboxKey
              && checkboxKey in formDataRef.current
              && !formDataRef.current[checkboxKey]) {
              return true;
            }

            if ((moment.isMoment(objValue) && objValue.isValid())
              && (moment.isMoment(othValue) && othValue.isValid())
            ) {
              return objValue.isSame(othValue);
            }
            return undefined;
          },
        ),
      );
    }
  }, []);

  const getCommunicationDraft = useCallback(() => buildCommunicationDraft(formDataRef.current, isJourney), []);

  // Step Data
  const [activeStep, setActiveStep] = useState(0);
  const [lastValidStep, setLastValidStep] = useState(null);

  const nextStep = useCallback(() => {
    if (activeStep < STEPS) {
      setActiveStep((step) => step + 1);
    }
  }, [activeStep]);

  const prevStep = useCallback(() => {
    if (activeStep > 0) {
      setActiveStep((step) => step - 1);
    }
  }, [activeStep]);

  // Submitting drafts
  const dispatch = useAppDispatch();
  const [draftId, setDraftId] = useState(null);
  const [draftSubmittedAt, setDraftSubmittedAt] = useState(DraftSubmittedState.INITIAL);
  const saveAsDraft = useCallback(async () => {
    const communicationParams: CommunicationParams = buildCommunicationParams(
      formDataRef.current,
      {
        submitted: false,
      },
      isJourney,
    );

    setDraftSubmittedAt(DraftSubmittedState.SUBMITTING);
    try {
      const communicationFilters = ['hasCompleted', 'hasNotCompleted'];
      let res;
      communicationFilters.forEach((filter) => {
        if (
          communicationParams.filters[filter]
          && (
            communicationParams.filters[filter].type === FeedbackCategory.VIDEO_PRACTICE_SKILLS_FEEDBACK
            || communicationParams.filters[filter].type === FeedbackCategory.ASSIGNMENT_SKILLS_FEEDBACK
          )
        ) {
          communicationParams.filters[filter].type = ActivityType.VIDEO_PRACTICE_SKILLS_FEEDBACK;
        }
      });
      if ((draftId || formDataRef.current.triggerType === TriggerType.EDIT)
        && (communicationParams as UpdateCommunicationParams).communicationId) {
        res = await dispatch(updateCommunication(communicationParams as UpdateCommunicationParams));
      } else {
        res = await dispatch(createCommunication(communicationParams as CreateCommunicationParams));
      }
      const response: any = unwrapResult(res);
      if (response) {
        setDraftId(response.id);
        setInitialFormData(buildFormData(response, {
          catalogId: formDataRef.current.catalogId,
          triggerType: formDataRef.current.triggerType,
        }, roles, null, isJourney));
        resetFormData();
        setDraftSubmittedAt(response.editedBy?.updatedAt);
      } else {
        setDraftSubmittedAt(DraftSubmittedState.INITIAL);
      }
    } catch (err) {
      setDraftSubmittedAt(DraftSubmittedState.INITIAL);
    }
  }, [dispatch, roles, setInitialFormData, resetFormData, draftId]);

  // Submitting Form
  const [formSubmitState, setFormSubmitState] = useState(FormSubmittedState.INITIAL);
  const submitForm = useCallback(async () => {
    const communicationDraft = getCommunicationDraft();

    const communicationParams: CommunicationParams = buildCommunicationParams(
      formDataRef.current,
      {
        submitted: true,
      },
      isJourney,
    );

    const processResponse = (res) => {
      const response: any = unwrapResult(res);
      if (response) {
        setFormSubmitState(FormSubmittedState.SUBMITTED);

        // Reseting filtered result when duplicating a communication from the filter tab
        if (formDataRef.current.triggerType === TriggerType.DUPLICATE && filterType) {
          dispatch(resetFilteredCommunications());
        }
      } else {
        setFormSubmitState(FormSubmittedState.INITIAL);
      }
    };
    setFormSubmitState(FormSubmittedState.SUBMITTING);
    try {
      const communicationFilters = ['hasCompleted', 'hasNotCompleted'];
      let res;
      communicationFilters.forEach((filter) => {
        if (
          communicationParams.filters[filter]
          && (
            communicationParams.filters[filter].type === FeedbackCategory.VIDEO_PRACTICE_SKILLS_FEEDBACK
            || communicationParams.filters[filter].type === FeedbackCategory.ASSIGNMENT_SKILLS_FEEDBACK
          )
        ) {
          communicationParams.filters[filter].type = ActivityType.VIDEO_PRACTICE_SKILLS_FEEDBACK;
        }
      });
      if (communicationParams.communicationType === CommunicationType.COURSE_WELCOME_EMAIL) {
        if ((communicationParams as UpdateCommunicationParams).communicationId) {
          res = await dispatch(updateCommunication(communicationParams as UpdateCommunicationParams));
        } else {
          res = await dispatch(createCommunication(communicationParams as CreateCommunicationParams));
        }
      } else if ((draftId || formDataRef.current.triggerType === TriggerType.EDIT)
        && (communicationParams as UpdateCommunicationParams).communicationId) {
        if (communicationDraft.firstTriggeredAt) {
          dispatch(openConfirmationDialog({
            title: t.COURSE_COMMUNICATIONS.UPDATION.ALREADY_ACTIVATED.TITLE(),
            bodyText: t.COURSE_COMMUNICATIONS.UPDATION.ALREADY_ACTIVATED.WARNING(aliases.learnersAliases),
            confirmText: t.FORM.UPDATE(),
            onConfirm: () => {
              dispatch(updateCommunication(communicationParams as UpdateCommunicationParams))
                .then((response) => processResponse(response));
            },
            onCancel: () => setFormSubmitState(FormSubmittedState.INITIAL),
          }));
        } else {
          res = await dispatch(updateCommunication(communicationParams as UpdateCommunicationParams));
        }
      } else {
        res = await dispatch(createCommunication(communicationParams as CreateCommunicationParams));
        /**
         * If user comes from course timeline to course communications
         * and returns to course timeline edit, the course timeline won't be
         * requested again and thus the scheduledCommunicationsCount won't be updated.
         * So in that case if there is a currentTimeline available,
         * update the scheduled communication count of corresponding lecture page.
         */
        if (TimelinesManager.currentTimeline) {
          const lecturePage = TimelinesManager.currentTimeline.lecturePages.find(lp => lp.id === communicationParams.lecturePageId);
          if (lecturePage
            && !lecturePage.released
            && (communicationParams.communicationType === CommunicationType.RELEASE_DATE_EMAIL
              || communicationParams.communicationType === CommunicationType.RELEASE_DATE_ANNOUNCEMENT
            )
          ) {
            TimelinesManager.updateLecturePageAttribute(communicationParams.lecturePageId, 'scheduledCommunicationsCount', lecturePage.scheduledCommunicationsCount + 1);
          }
        }
      }
      processResponse(res);
      if (communicationParams.scheduledFor === ScheduledForType.SEND_NOW) {
        dispatch(currentSendingNowCommunication({ id: res.payload.id, state: res.payload.state }));
      }
    } catch (err) {
      setFormSubmitState(FormSubmittedState.INITIAL);
    }
  }, [dispatch, getCommunicationDraft, aliases, draftId, TimelinesManager]);

  return {
    // Form Data: Need to store the data of the form fields of
    // different steps in one place
    initialFormData: initialFormDataRef.current, // Initial form data, mostly converted from communicaiton
    formData: formDataRef.current, // Data of the form fields in different step
    updateFormData, // Update the form data when a field value changes
    getCommunicationDraft, // Get communication draft
    isDirty, // Check whether the whole form is dirty

    // Step Data
    steps: STEPS,
    activeStep, // State holding the active step
    setActiveStep, // Dispatch method to update active step
    lastValidStep, // State holding the data of the last valid step
    setLastValidStep, // Dispatch method to update last valid step
    nextStep, // Goto Next Step
    prevStep, // Goto Prev Step

    // Draft submission
    draftSubmittedAt, // Draft submitted
    saveAsDraft, // Save as draft method

    formSubmitState, // Form submitted state
    submitForm, // Submit the form
  };
};

const createGenericFormValue = (value: any) => (
  {
    id: value,
    value,
  }
);

/**
 * Helper which converts the given communication to the formData
 *
 * @param communication
 * @param extras
 * @param roles
 * @returns formData
 */
const buildFormData = (communication: Communication, extras: FieldValues, roles: Role[], isCourseSelfPaced?: boolean, isJourney?: boolean): FieldValues => {
  let data: FieldValues = {
    catalogId: extras.catalogId,
    triggerType: extras.triggerType,
    id: communication.id,
    communicationType: communication.communicationType,
    lecturePageId: communication.lecturePageId,
    submitted: communication.submitted ?? false,
    ownerId: {
      id: communication.ownerId,
      value: communication.ownerId,
    },
    ownerType: {
      id: communication.ownerType,
      value: communication.ownerType,
    },
    firstTriggeredAt: communication.firstTriggeredAt,
    editedBy: communication.editedBy,
    emailSubject: communication.communication?.subject ?? null,
    emailBody: communication.communication?.body ?? null,
    adminUserId: communication.communication?.adminUserId ?? null,
    hasEmail: communication.communication?.hasEmail ?? false,
    includePushNotification: communication.enablePushNotification ?? false,
    pushNotificationText: communication.pushNotificationBody ?? null,
    filterByRoles: !isEmpty(communication.filters?.courseRolesList),
    courseRolesList: communication.filters?.courseRolesList ?? [],
    enrolledLastDaysChecked: isNumber(communication.filters?.enrolledInLastXDays),
    enrolledInLastXDays: communication.filters?.enrolledInLastXDays ?? null,
    hasLoggedInChecked: !isEmpty(communication.filters?.hasLoggedIn),
    hasLoggedIn: communication.filters?.hasLoggedIn ? {
      id: communication.filters.hasLoggedIn,
      value: communication.filters.hasLoggedIn,
    } : null,
    daysActiveChecked: isNumber(communication.filters?.daysActive),
    daysActive: communication.filters?.daysActive ?? null,
    daysInactiveChecked: isNumber(communication.filters?.daysInactive),
    daysInactive: communication.filters?.daysInactive ?? null,
    notCompletedActivityChecked: !isEmpty(communication.filters?.hasNotCompleted),
    notCompletedActivity: null,
    notCompletedType: null,
    notCompletedCategory: null,
    completedActivityChecked: !isEmpty(communication.filters?.hasCompleted),
    completedActivity: null,
    completedType: null,
    completedCategory: null,
    earnedAtLeastXPoints: null,
    earnedMoreThanXPointsChecked: isNumber(communication.filters?.earnedMoreThanXPoints),
    earnedMoreThanXPoints: communication.filters?.earnedMoreThanXPoints ?? null,
    earnedLessThanXPointsChecked: isNumber(communication.filters?.earnedLessThanXPoints),
    earnedLessThanXPoints: communication.filters?.earnedLessThanXPoints ?? null,
    completedXAssignmentsAsTodoChecked: isNumber(communication.filters?.completedXAssignmentsAsTodo),
    completedXAssignmentsAsTodo: communication.filters?.completedXAssignmentsAsTodo ?? null,
    hasCompletedCourseChecked: isBoolean(communication.filters?.hasCompletedCourse),
    hasCompletedCourse: null,
    hasNotCompletedCourse: null,
    scheduledFor: communication.scheduledFor
      ?? ((communication.communicationType === CommunicationType.MANUAL_EMAIL
        || communication.communicationType === CommunicationType.MANUAL_ANNOUNCEMENT)
        ? ScheduledForType.SEND_NOW : ScheduledForType.ON_DATE),
    xDays: communication.xDays ?? null,
    scheduledOn: moment(communication.scheduledAt) ?? communication.scheduledOn,
  };

  // If this is for journey communication, we add the properties below
  if (isJourney) {
    data = {
      ...data,
      hasNeverActiveChecked: !!(communication.filters?.neverActive),
      notEnrolledToAnyCourseChecked: !!(communication.filters?.notEnrolledToAnyCourse),
      enrolledToSpecificCourseChecked: !!(communication.filters?.enrolledToCourseId),
      enrolledToSpecificCourse: communication.filters?.enrolledToCourseId ? createGenericFormValue(communication.filters?.enrolledToCourseId) : null,
      notEnrolledToSpecificCourseChecked: !!(communication.filters?.notEnrolledToCourseId),
      notEnrolledToSpecificCourse: communication.filters?.notEnrolledToCourseId ? createGenericFormValue(communication.filters?.notEnrolledToCourseId) : null,
      completedSpecificCourseChecked: !!(communication.filters?.completedCourseId),
      completedSpecificCourse: communication.filters?.completedCourseId ? createGenericFormValue(communication.filters?.completedCourseId) : null,
      notCompletedSpecificCourseChecked: !!(communication.filters?.notCompletedCourseId),
      notCompletedSpecificCourse: communication.filters?.notCompletedCourseId ? createGenericFormValue(communication.filters?.notCompletedCourseId) : null,
      completedSpecificCollectionChecked: !!(communication.filters?.completedCollectionId),
      completedSpecificCollection: communication.filters?.completedCollectionId ? createGenericFormValue(communication.filters?.completedCollectionId) : null,
      notCompletedSpecificCollectionChecked: !!(communication.filters?.notCompletedCollectionId),
      notCompletedSpecificCollection: communication.filters?.notCompletedCollectionId ? createGenericFormValue(communication.filters?.notCompletedCollectionId) : null,
      hasCompletedJourneyChecked: isBoolean(communication.filters?.hasCompletedJourney),
      hasCompletedJourney: communication.filters?.hasCompletedJourney ?? null,
    };
  }

  // Its just a patch that for the safe landing, if backend didn't
  // return a scheduledFor and has xDays. That means this is its triggers
  // which is ScheduledForType.AFTER_DATE
  if (communication.xDays && !communication.scheduledFor) {
    data.scheduledFor = ScheduledForType.AFTER_DATE;
  }
  if (communication.communicationType === CommunicationType.COURSE_WELCOME_EMAIL) {
    data.recipients = [Recipient.REGISTERED_USER];
    data.scheduledFor = ScheduledForType.USER_DEFINED;
  } else if (!isEmpty(communication.recipients)) {
    data.recipients = communication?.recipients;
  } else {
    switch (communication.communicationType) {
      case CommunicationType.DUE_DATE_EMAIL:
      case CommunicationType.RELEASE_DATE_EMAIL:
      case CommunicationType.MANUAL_EMAIL:
        data.recipients = [Recipient.LEARNERS];
        break;
      case CommunicationType.DUE_DATE_ANNOUNCEMENT:
      case CommunicationType.RELEASE_DATE_ANNOUNCEMENT:
      case CommunicationType.MANUAL_ANNOUNCEMENT:
        data.recipients = [Recipient.LEARNERS, Recipient.MENTORS, Recipient.COURSE_ADMINS];
        break;
      default:
        data.recipients = [Recipient.TRIGGER_LEARNER];
        break;
    }
  }

  if (!communication.errorCodes?.includes(CommunicationErrorCode.MISSING_NOT_COMPLETED_ACTIVITY_FILTER_ERROR)
    && !communication.errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_NOT_COMPLETED_THIRD_PARTY_COMPLETION)
    && !communication.errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_NOT_COMPLETED_ACTIVITY_FEEDBACK_COMPLETION)
    && communication.filters?.hasNotCompleted) {
    data.notCompletedActivity = {
      id: communication.filters.hasNotCompleted.id,
      value: communication.filters.hasNotCompleted.id,
    };
    data.notCompletedType = {
      id: communication.filters?.hasNotCompleted?.category === FeedbackCategory.VIDEO_PRACTICE_SKILLS_FEEDBACK
        ? communication.filters?.hasNotCompleted?.category
        : communication.filters?.hasNotCompleted?.type,
      value: communication.filters?.hasNotCompleted?.type,
    };
    data.notCompletedCategory = {
      id: communication.filters?.hasNotCompleted?.category,
      value: communication.filters?.hasNotCompleted?.category,
    };
  }

  if (!communication.errorCodes?.includes(CommunicationErrorCode.MISSING_COMPLETED_ACTIVITY_FILTER_ERROR)
    && !communication.errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_COMPLETED_ACTIVITY_THIRD_PARTY_COMPLETION)
    && !communication.errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_COMPLETED_ACTIVITY_FEEDBACK_COMPLETION)
    && communication.filters?.hasCompleted) {
    data.completedActivity = {
      id: communication.filters.hasCompleted.id,
      value: communication.filters.hasCompleted.id,
    };
    data.completedType = {
      id: communication.filters?.hasCompleted?.category === FeedbackCategory.VIDEO_PRACTICE_SKILLS_FEEDBACK
        ? communication.filters?.hasCompleted?.category
        : communication.filters?.hasCompleted?.type,
      value: communication.filters?.hasCompleted?.type,
    };
    data.completedCategory = {
      id: communication.filters?.hasCompleted?.category,
      value: communication.filters?.hasCompleted?.category,
    };
  }

  // Setup roles filter data accordingly
  data.courseRolesList = [];

  // Setup roles filter data accordingly
  if (communication?.filters?.courseRolesList) {
    communication.filters.courseRolesList.forEach(roleId => {
      if (roleId === 0) {
        data.courseRolesList.push({
          id: 0,
          label: t.COURSE_COMMUNICATIONS.FILTERS.ROLES.NO_ROLES(),
          name: 'filterByRoleNoRoles',
          group: RoleGroup.LEARNER,
        });
      } else {
        const role = findWhere(roles, { id: roleId });
        if (role) {
          data.courseRolesList.push({
            id: role.id,
            label: role.name,
            name: camelCase(`filterByRole${role.name}`),
            group: getGroup(role),
          });
        }
      }
    });
  }

  // Checks HasCompleted course selection
  let hasCompletedCourseSelection = null;
  if (isBoolean(communication?.filters?.hasCompletedCourse)) {
    if (communication.communicationType === CommunicationType.NOT_COMPLETED) {
      data.hasNotCompletedCourse = communication.filters.hasCompletedCourse === false;
    } else {
      const id = communication.filters.hasCompletedCourse
        ? 'has-completed'
        : 'has-not-completed';

      // Check if its enabled in communication
      hasCompletedCourseSelection = {
        id,
        value: id === 'has-completed',
      };
    }
  } else if (isCourseSelfPaced
    && !communication.id
    && (communication.communicationType === CommunicationType.MANUAL_EMAIL
      || communication.communicationType === CommunicationType.RELEASE_DATE_EMAIL)) {
    data.hasCompletedCourseChecked = true;
    hasCompletedCourseSelection = {
      id: 'has-not-completed',
      value: false,
    };
  }
  data.hasCompletedCourse = hasCompletedCourseSelection;

  // Checks HasCompleted journey selection
  let hasCompletedJourneySelection = null;
  if (isBoolean(communication?.filters?.hasCompletedJourney)) {
    const id = communication.filters.hasCompletedJourney
      ? 'has-completed'
      : 'has-not-completed';

    // Check if its enabled in communication
    hasCompletedJourneySelection = {
      id,
      value: id === 'has-completed',
    };
  }
  data.hasCompletedJourney = hasCompletedJourneySelection;

  if (communication.event) {
    if (communication.event.enrolledInCourse) {
      data.event = 'enrolled-in-course';
    } else if (communication.event.visitsHomePage) {
      data.event = 'visits-course-home';
    } else if (communication.event.hasCompleted) {
      if (!communication.errorCodes?.includes(CommunicationErrorCode.MISSING_COMPLETED_ACTIVITY_EVENT_ERROR)
        && !communication.errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_EVENT_THIRD_PARTY_COMPLETION)
        && !communication.errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_EVENT_FEEDBACK_COMPLETION)) {
        data.completedActivity = {
          id: communication.event.hasCompleted.id,
          value: communication.event.hasCompleted.id,
        };
        data.completedType = {
          id: communication.event.hasCompleted?.type,
          value: communication.event.hasCompleted?.type,
        };
      }
      data.event = 'completed-activity';
    } else if (communication.event.earnedAtLeastXPoints) {
      data.earnedAtLeastXPoints = communication.event.earnedAtLeastXPoints ?? null;
      data.event = 'earned-points';
    } else if (communication.event.completedXAssignmentsAsTodo) {
      data.completedXAssignmentsAsTodo = communication.event.completedXAssignmentsAsTodo ?? null;
      data.event = 'completed-assignments';
    } else if (communication.event.hasCompletedCourse) {
      data.event = 'completed-course';
    }
  }

  return data;
};

/**
 * Build course or journey communication from formData
 * depending on what type of communication is
 * @param data
 * @returns Communication
 */
const buildCommunicationDraft = (data: FieldValues, isJourney: boolean): Communication => {
  if (isJourney) {
    return buildJourneyCommunicationDraft(data);
  }
  return buildCourseCommunicationDraft(data);
};

/**
 * Build journey communication from formData
 * @param data
 * @returns Communication
 */
const buildJourneyCommunicationDraft = (data: FieldValues): Communication => {
  let { scheduledOn } = data;
  const {
    communicationType,
    scheduledFor,
    submitted,
    id,
    ownerId,
    ownerType,
    editedBy,
    emailSubject,
    emailBody,
    adminUserId,
    hasEmail,
    recipients,
    includePushNotification,
    pushNotificationText,
    filterByRoles,
    courseRolesList,
    xDays,
    // Filters
    hasNeverActiveChecked,
    notEnrolledToAnyCourseChecked,
    daysInactiveChecked,
    daysInactive,
    enrolledLastDaysChecked,
    enrolledInLastXDays,
    enrolledToSpecificCourseChecked,
    enrolledToSpecificCourse,
    notEnrolledToSpecificCourseChecked,
    notEnrolledToSpecificCourse,
    completedSpecificCourseChecked,
    completedSpecificCourse,
    notCompletedSpecificCourseChecked,
    notCompletedSpecificCourse,
    completedSpecificCollectionChecked,
    completedSpecificCollection,
    notCompletedSpecificCollectionChecked,
    notCompletedSpecificCollection,
    hasCompletedJourneyChecked,
    hasCompletedJourney,
  } = data;

  if ((communicationType === CommunicationType.MANUAL_EMAIL
    && scheduledFor === ScheduledForType.SEND_NOW)
    || communicationType === CommunicationType.AUTOMATED_JOURNEY_EMAIL) {
    scheduledOn = moment(); // to handle send_now for backend
  }
  const communication: Communication = {
    submitted: submitted ?? true,
    id,
    communicationType,
    ownerId: ownerId?.value,
    ownerType: ownerType?.value,
    editedBy,
    communication: {
      subject: emailSubject,
      body: emailBody,
      adminUserId,
      hasEmail,
    },
    recipients,
    enablePushNotification: includePushNotification ?? undefined,
    pushNotificationBody: includePushNotification ? pushNotificationText : undefined,
    filters: {},
    scheduledFor,
    scheduledOn: moment(scheduledOn)?.toISOString(),
  };

  if (xDays) {
    communication.xDays = xDays;
    communication.scheduledFor = ScheduledForType.AFTER_DATE;
  } else {
    // As requested by backend
    communication.xDays = null;
  }

  if (filterByRoles) {
    communication.filters.courseRolesList = courseRolesList.map((role => role.id));
  }
  // Check existence of any filter
  if (hasNeverActiveChecked) {
    communication.filters.neverActive = hasNeverActiveChecked;
  }
  if (notEnrolledToAnyCourseChecked) {
    communication.filters.notEnrolledToAnyCourse = notEnrolledToAnyCourseChecked;
  }
  if (daysInactiveChecked) {
    communication.filters.daysInactive = daysInactive;
  }
  if (enrolledLastDaysChecked) {
    communication.filters.enrolledInLastXDays = enrolledInLastXDays;
  }
  if (enrolledToSpecificCourseChecked) {
    communication.filters.enrolledToCourseId = enrolledToSpecificCourse?.id;
  }
  if (notEnrolledToSpecificCourseChecked) {
    communication.filters.notEnrolledToCourseId = notEnrolledToSpecificCourse?.id;
  }
  if (completedSpecificCourseChecked) {
    communication.filters.completedCourseId = completedSpecificCourse?.id;
  }
  if (notCompletedSpecificCourseChecked) {
    communication.filters.notCompletedCourseId = notCompletedSpecificCourse?.id;
  }
  if (completedSpecificCollectionChecked) {
    communication.filters.completedCollectionId = completedSpecificCollection?.id;
  }
  if (notCompletedSpecificCollectionChecked) {
    communication.filters.notCompletedCollectionId = notCompletedSpecificCollection?.id;
  }
  if (hasCompletedJourneyChecked) {
    communication.filters.hasCompletedJourney = hasCompletedJourney?.value;
  }

  return communication;
};


/**
 * Build course communication from formData
 * @param data
 * @returns Communication
 */
const buildCourseCommunicationDraft = (data: FieldValues): Communication => {
  let { scheduledOn } = data;
  const {
    communicationType,
    scheduledFor,
    submitted,
    id,
    lecturePageId,
    firstTriggeredAt,
    ownerId,
    ownerType,
    editedBy,
    emailSubject,
    emailBody,
    adminUserId,
    hasEmail,
    recipients,
    includePushNotification,
    pushNotificationText,
    filterByRoles,
    courseRolesList,
    event,
    xDays,
    // Filters
    hasNotCompletedCourse,
    completedActivityChecked,
    completedActivity,
    completedType,
    completedCategory,
    earnedAtLeastXPoints,
    daysInactiveChecked,
    daysInactive,
    daysActiveChecked,
    daysActive,
    hasLoggedInChecked,
    hasLoggedIn,
    notCompletedActivityChecked,
    notCompletedActivity,
    notCompletedType,
    notCompletedCategory,
    enrolledLastDaysChecked,
    enrolledInLastXDays,
    earnedMoreThanXPointsChecked,
    earnedMoreThanXPoints,
    earnedLessThanXPointsChecked,
    earnedLessThanXPoints,
    completedXAssignmentsAsTodoChecked,
    completedXAssignmentsAsTodo,
    hasCompletedCourseChecked,
    hasCompletedCourse,
  } = data;
  if ((communicationType === CommunicationType.MANUAL_EMAIL
    || communicationType === CommunicationType.MANUAL_ANNOUNCEMENT
  ) && scheduledFor === ScheduledForType.SEND_NOW) {
    scheduledOn = moment(); // to handle send_now for backend
  }
  const communication: Communication = {
    submitted: submitted ?? true,
    id,
    communicationType,
    lecturePageId,
    ownerId: ownerId?.value,
    ownerType: ownerType?.value,
    firstTriggeredAt,
    editedBy,
    communication: {
      subject: emailSubject,
      body: emailBody,
      adminUserId,
      hasEmail,
    },
    recipients,
    enablePushNotification: includePushNotification ?? undefined,
    pushNotificationBody: includePushNotification ? pushNotificationText : undefined,
    filters: {},
    scheduledFor,
    scheduledOn: moment(scheduledOn)?.toISOString(),
  };

  if (scheduledFor === ScheduledForType.AFTER_DATE
    || scheduledFor === ScheduledForType.BEFORE_DATE) {
    communication.xDays = xDays;
  } else {
    // As requested by backend
    communication.xDays = null;
  }

  if (filterByRoles) {
    communication.filters.courseRolesList = courseRolesList.map((role => role.id));
  }

  if (hasNotCompletedCourse) {
    communication.filters.hasCompletedCourse = false;
  }

  if (event) {
    communication.event = {};
    switch (event) {
      case 'enrolled-in-course':
        communication.event.enrolledInCourse = true;
        break;
      case 'visits-course-home':
        communication.event.visitsHomePage = true;
        break;
      case 'completed-activity':
        communication.event.hasCompleted = {
          id: completedActivity?.value,
          type: completedType?.value,
          category: completedCategory,
        };
        break;
      case 'earned-points':
        communication.event.earnedAtLeastXPoints = earnedAtLeastXPoints;
        break;
      case 'completed-assignments':
        communication.event.completedXAssignmentsAsTodo = completedXAssignmentsAsTodo;
        break;
      case 'completed-course':
        communication.event.hasCompletedCourse = true;
        break;
      default:
        break;
    }
  } else {
    // Check any filters exists
    if (enrolledLastDaysChecked) {
      communication.filters.enrolledInLastXDays = enrolledInLastXDays;
    }

    if (hasLoggedInChecked) {
      communication.filters.hasLoggedIn = hasLoggedIn?.value;
    }

    if (daysActiveChecked) {
      communication.filters.daysActive = daysActive;
    }

    if (daysInactiveChecked) {
      communication.filters.daysInactive = daysInactive;
    }

    if (notCompletedActivityChecked) {
      communication.filters.hasNotCompleted = {
        id: notCompletedActivity?.value,
        type: notCompletedCategory?.value ?? notCompletedType?.value,
        category: notCompletedType?.id,
      };
    }

    if (completedActivityChecked) {
      communication.filters.hasCompleted = {
        id: completedActivity?.value,
        type: completedCategory?.value ?? completedType?.value,
        category: completedType?.id,
      };
    }

    if (earnedMoreThanXPointsChecked) {
      communication.filters.earnedMoreThanXPoints = earnedMoreThanXPoints;
    }

    if (earnedLessThanXPointsChecked) {
      communication.filters.earnedLessThanXPoints = earnedLessThanXPoints;
    }

    if (completedXAssignmentsAsTodoChecked) {
      communication.filters.completedXAssignmentsAsTodo = completedXAssignmentsAsTodo;
    }

    if (hasCompletedCourseChecked) {
      communication.filters.hasCompletedCourse = hasCompletedCourse?.value;
    }
  }

  return communication;
};

/**
 * Build params from formData
 * @param data
 * @param extra
 */
const buildCommunicationParams = (data: FieldValues, extra: FieldValues, isJourney): CommunicationParams => {
  const communication = buildCommunicationDraft(data, isJourney);

  delete communication.firstTriggeredAt;
  delete communication.editedBy;

  return {
    ...communication,
    ...extra,
    triggerType: data.triggerType,
    communicationId: data.id,
    catalogId: data.catalogId,
    isJourney,
  };
};

export default useCommunicationForm;
