import { css } from '@emotion/react';
import React, { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { convertToPictureData, ExternalToolType } from 'redux/schemas/models/lecture-component';
import { useAppDispatch } from 'redux/store';
import _ from 'underscore';
import * as humps from 'humps';
import { NovoEdFile } from 'shared/hooks/use-upload-file';

import { updateLectureComponent } from 'redux/actions/lecture-pages';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { AngularContext, AngularServicesContext } from 'react-app';
import BaseLectureComponentContext from 'lecture_pages/directives/components/base-lecture-component/context';
import ActivityTitle from 'shared/components/activity/activity-title';
import ActivityStatus from 'shared/components/activity/activity-status';
import ActivityPoints from 'shared/components/activity/activity-points';
import NvClickToEdit, { InputType } from 'components/nv-click-to-edit';
import { black, gray3, gray6, primary, white } from 'styles/global_defaults/colors';
import { useLecturePageFromParams, useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { markLectureComponentAsDone, updateActivityProgress, checkLectureComponentProgress } from 'redux/actions/external-tool';
import NvTooltip, { TextAlign } from 'shared/components/nv-tooltip';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import NvDropdown, { NvDropdownAlign, NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { getCurrentCourse } from 'redux/selectors/course';
import pusherService from 'shared/services/pusher-service';
import { getAutoCompletionProps } from 'lecture_pages/directives/components/lecture-component-edit-dropdown';
import { useTimelineService } from 'timelines/services/react-timeline-service';
import WebLinkColorPickerPopover from './web-link-color-picker-popover';
import WebLinkPictureArea from './web-link-picture-area';
import { LectureComponentProps, LecturePageMode } from '..';
import { config } from '../../../../config/config.json';
import { createAutoCompletionDropdownItem } from '../auto-completion-dropdown-item';
import { getSkillTagsFromTaggings } from 'redux/selectors/skills-feedback';

const twoLineEllipsisStyles = css`
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
`;

export const getColorInputLabels = (): Record<string, string> => ({
  textColor: t.LECTURE_PAGES.COMPONENTS.WEB_LINK.TEXT_COLOR(),
  backgroundColor: t.LECTURE_PAGES.COMPONENTS.WEB_LINK.BACKGROUND_COLOR(),
  leftBorderColor: t.LECTURE_PAGES.COMPONENTS.WEB_LINK.BORDER_COLOR(),
  libraryIconColor: t.LECTURE_PAGES.COMPONENTS.WEB_LINK.ICON_COLOR(),
  pictureBackgroundColor: t.LECTURE_PAGES.COMPONENTS.WEB_LINK.BACKGROUND_COLOR(),
});

const MARK_AS_DONE_EVENT = 'mark_as_done_trigger';

/** Displays a clickable external web link with configurable properties to show it. */
export const WebLinkLectureComponent = (props: LectureComponentProps<ExternalToolType.WEB_LINK> & { isPreview?: boolean }) => {
  const { lectureComponent } = props;
  // In case links are already created, we put default viewOptions for them
  const viewOptions = {
    backgroundColor: gray6,
    libraryIconColor: white,
    libraryIconName: 'open-external',
    pictureShape: 'square',
    pictureBackgroundColor: gray3,
    textColor: black,
    ...lectureComponent.viewOptions,
  };
  const currentCatalogId = useSelector(state => state.app.currentCatalogId);

  const dispatch = useAppDispatch();

  const angularServices = useContext(AngularServicesContext);
  const { injectServices } = useContext(AngularContext);
  const [CurrentUserManager] = injectServices(['CurrentUserManager']);
  const { sharedProps, setSharedProps } = useContext(BaseLectureComponentContext);

  const skillTags = useSelector((state) => getSkillTagsFromTaggings(state, lectureComponent.skillTaggings));

  // Whether the current user has editing capabilities
  const userCanEdit = props.mode === LecturePageMode.EDIT && angularServices.CurrentPermissionsManager.isCourseBuilder();

  const [headerText, setHeaderText] = useState(lectureComponent.content);
  const [isHovering, setIsHovering] = useState(false);
  const [checkInProgress, setCheckInProgress] = useState(false);
  const [pusherChannel, setPusherChannel] = useState(null);
  const [anonymized, setAnonymized] = useState('');
  const currentCourse = useSelector((state) => getCurrentCourse(state));
  const { externalTools } = useSelector(state => state.models);
  const lecturePage = useLecturePageFromParams();
  const externalTool = externalTools[lectureComponent.externalTool];
  const [isTodo, setIsTodo] = useState(externalTool.isTodo);
  const [currentActivityType, setCurrentActivityType] = useState(externalTool.activityType);
  const { pointsConfiguration } = externalTool;
  const isActivity = (externalTool.isTodo || !!pointsConfiguration?.points);
  const { mode } = useLecturePageParams();
  const [colorProps, setColorProps] = useState(() => _.pick(viewOptions, 'textColor', 'backgroundColor'));

  const timelineService = useTimelineService();

  const isCompleted = externalTool.progress === 'completed';

  const ACTIVITY_TO_STATUS_TRANSLATE_KEY = {
    quiz: t.QUIZZES.TAKE(),
    survey: t.QUIZZES.TAKE(),
    assignment: t.EXERCISES.SUBMIT(),
    feedback: t.EVALUATIONS.EVALUATE(),
    video: t.LECTURE_VIDEO.WATCH(),
    reading: t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.READING_VERB(),
    discussion: t.LECTURE_PAGES.COMPONENTS.DISCUSSION.DISCUSS(),
    audio: t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.AUDIO_VERB(),
  };

  const ACTIVITY_TO_ICON = {
    quiz: 'quiz',
    survey: 'survey',
    assignment: 'assignments',
    feedback: 'evaluation',
    video: 'video',
    reading: 'read',
    discussion: 'conversations',
    audio: 'audio',
  };

  const ACTIVITY_TYPES = [{ name: 'quiz', iconClass: ACTIVITY_TO_ICON.quiz, translateKey: t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.QUIZ() },
    { name: 'survey', iconClass: ACTIVITY_TO_ICON.survey, translateKey: t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.SURVEY() },
    { name: 'assignment', iconClass: ACTIVITY_TO_ICON.assignment, translateKey: t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.ASSIGNMENT({ assignmentAlias: currentCourse.assignmentName.capitalizedSingularized }) },
    { name: 'feedback', iconClass: ACTIVITY_TO_ICON.feedback, translateKey: t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.FEEDBACK() },
    { name: 'video', iconClass: ACTIVITY_TO_ICON.video, translateKey: t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.VIDEO() },
    { name: 'reading', iconClass: ACTIVITY_TO_ICON.reading, translateKey: t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.READING() },
    { name: 'discussion', iconClass: ACTIVITY_TO_ICON.discussion, translateKey: t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.DISCUSSION() },
    { name: 'audio', iconClass: ACTIVITY_TO_ICON.audio, translateKey: t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.AUDIO() }];

  const initialIndex = ACTIVITY_TYPES.findIndex(element => element.name === currentActivityType) + 1;
  /**
   * API completion options have 3 items (header, toggle option, and divider).
   * Selected activity type checkmarks are shown in activity list based on this length.
   */
  const API_COMPLETION_ITEMS_LENGTH = 3;

  const getTitleInputWrapperStyles = () => {
    if (!isActivity || (isActivity && !pointsConfiguration)) {
      return css`
        margin-bottom: ${halfSpacing}px;
      `;
    }
    return '';
  };

  const NvDropdownStyles = css`
  .filters-menu {
    .nv-dropdown-header {
      width: max-content;
    }
    &:after {
      bottom: 0px;
      height: 0px;
    }
  }
  `;

  const webLinkComponentStyles = css`
  .bs4-form-control {
    padding: 0;
    border: none;
    background: none;
  }

  .left-border {
    width: ${halfSpacing}px;
  }

  textarea[name="header-input"] {
    overflow-y: hidden;
  }

  .activity-status {
    margin-bottom: ${halfSpacing}px;
  }

  .title-input-wrapper {
    width: 100%;
    ${getTitleInputWrapperStyles()};
  }

  .activity-points {
    margin-bottom: ${standardSpacing}px;
  }
  .page-subtitle {
    margin-top: ${halfSpacing}px;
    margin-bottom: ${pointsConfiguration?.points ? halfSpacing : standardSpacing}px;
    & h2 {
      margin-bottom: ${standardSpacing}px;
    }
    .editable-title {
      font-size: inherit;
      line-height: inherit;
    }
  }
  .click-to-edit-text-wrapper {
    .editable-title {
      padding: ${halfSpacing}px 0;
      cursor: pointer;
      .nv-text-input {
        border: 1px solid ${primary};
        padding: 0 ${halfSpacing}px;
      }
    }
  }
  .web-link-textarea {
    flex: 1;
    min-width: 0;
    align-items: center;
    background-color: ${colorProps.backgroundColor};

    a {
      flex: 1;
      min-width: 0;
      word-wrap: break-word;
      align-items: center;
      color: ${colorProps.textColor};

      .anchor-inner {
        min-width: 0;
        ${twoLineEllipsisStyles};
      }
    }
  }
  .buttons-section {
    text-align: center;
    padding: 20px;
  }
  .activity-title {
    display: inline-flex;
    .nv-dropdown {
      align-self: center;
      .show .icon-dropdown-arrow {
        color: ${primary};
      }
      .icon-dropdown-arrow {
        &:hover, &:active {
          color: ${primary};
        }
      }
    }
  }
  ${NvDropdownStyles}
  `;

  useEffect(() => {
    if (isTodo !== externalTool.isTodo) {
      setIsTodo(externalTool.isTodo);
    }
  }, [externalTool.isTodo]);

  useEffect(() => {
    const changeActivityOption = [];
    if (isActivity && !currentCourse.isContentManagementCollection) {
      changeActivityOption.push(
        {
          type: 'custom',
          customItem: (
            <div className='bs4-dropdown-item' css={NvDropdownStyles}>
              <NvDropdown
                useMaxHeightModifier
                flip={false}
                menuClassName='filters-menu'
                items={dropdownItems(true, false)}
                buttonStyle={NvDropdownButtonStyle.CUSTOM}
                align={NvDropdownAlign.CENTER}
                drop='down'
                showSelectedIndicator
                initialIndex={initialIndex}
                customTarget={() => (
                  <div
                    pendo-tag-name={config.pendo.bookmarks.bookmarksSelectCourse}
                    className='condensed-dropdown custom-filter-target'
                  >
                    {t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.CHANGE_ACTIVITY_TYPE()}
                  </div>
                )}
              />
            </div>
          ),
        },
      );
    }
    setSharedProps({
      ...sharedProps,
      extraOptions: {
        ...sharedProps.extraOptions,
        mode: 'prepend',
        renderOnMount: true,
        options: [
          sharedProps.extraOptions.getEditOption(
            t.LECTURE_PAGES.COMPONENTS.DROPDOWN.EDIT_BASICS(),
            lecturePage.isLinked,
            {
              disabledTooltip: t.LECTURE_PAGES.COMPONENTS.SET_UP_FROM_COLLECTION_TOOLTIP(),
              tooltipTextAlign: TextAlign.LEFT,
            },
          ),
          ...changeActivityOption,
        ],
      },
      todoOptions: {
        useEffectFlushFix: false,
        // Adding API based completion items count while calculating initial index
        items: dropdownItems(true).map((item, i) => ({ ...item, selected: i === initialIndex + API_COMPLETION_ITEMS_LENGTH })),
      },
    });
  }, [setSharedProps, isActivity, initialIndex]);

  useEffect(() => {
    if (!headerText) {
      setHeaderText(externalTool.title);
    }
  }, [headerText]);

  useEffect(() => {
    if (isActivity && hasAutoCompletion()) {
      setupPusherNotifications();
    }
    return unbindPusherChannel;
  }, []);

  useEffect(() => {
    if (isActivity && hasAutoCompletion() && !pusherChannel) {
      setupPusherNotifications();
    } else {
      unbindPusherChannel();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActivity, externalTool.autocompleteScorm]);

  const setupPusherNotifications = () => {
    const anon = CurrentUserManager.user.anonymizedIdentifier.substr(0, 10);
    const channel = pusherService.initializeUserCourseChannel(anon, currentCourse.id);
    channel.bind(MARK_AS_DONE_EVENT, handleMarkAsDoneEvent);
    setPusherChannel(channel);
    setAnonymized(anon);
  };

  const unbindPusherChannel = () => {
    if (pusherChannel) {
      pusherService.removeUserCourseChannel(anonymized, currentCourse.id);
      try {
        setPusherChannel(null);
        setAnonymized('');
      // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  };

  const handleMarkAsDoneEvent = (pusherData) => {
    pusherData = humps.camelizeKeys(pusherData);
    if (pusherData.externalToolId === externalTool.id && !isCompleted) {
      const externalToolInfo = {
        ...externalTool,
        progress: pusherData.progress,
        status: pusherData.progress,
        pointsReceived: pusherData.pointsReceived,
      };
      updateProgress(externalToolInfo);
    }
  };

  /**
   * Saves all edits to this component
   * @param text The Web link text
   * @param newViewOptions A partial set of view options to overwrite the existing options
   * @param pictureData Data for the picture. Not updated if `undefined`, and deleted if set to `null`
   */
  const save = (text: string, newViewOptions: Partial<typeof viewOptions>, pictureData?: NovoEdFile) => {
    const deletePicture = !pictureData && pictureData !== undefined;
    dispatch(updateLectureComponent({
      catalogId: currentCatalogId,
      lecturePageId: props.currentLecture.id,
      componentData: {
        ...lectureComponent,
        externalTool: {
          ...externalTool,
          displayText: text,
          activityType: currentActivityType,
          isTodo,
        },
        id: lectureComponent.id,
        index: lectureComponent.index,
        type: lectureComponent.type,
        content: text,
        viewOptions: {
          ...viewOptions,
          ...newViewOptions,
        },
        picture: pictureData ? convertToPictureData(pictureData) : null,
        removePicture: deletePicture,
      },
    }));
  };

  const hasSumTotalIntegration = () => externalTool.hasSumTotalIntegration;

  const hasAutoCompletion = () => externalTool.autocompleteScorm;

  const shouldShowCheckMyProgress = () => (hasSumTotalIntegration() && isActivity && !isCompleted);

  const shouldShowMarkDone = () => !hasSumTotalIntegration() && isActivity && !hasAutoCompletion();

  const shouldDisableMarkAsDone = () => (
    (isCompleted
        || mode === LecturePageMode.EDIT
        || mode === LecturePageMode.RESTRICTED_EDIT
        || mode === LecturePageMode.REORDER)
    || externalTool?.progress !== 'in_progress'
    || checkInProgress
  );

  const currentTotalPoints = () => {
    if (externalTool.totalPoints) {
      return externalTool.totalPoints[0];
    }
    return 0;
  };

  const updateProgress = (externalToolInfo, isCheckingProgress = false) => {
    Promise.resolve().then(() => {
      if (!isCheckingProgress || (isCheckingProgress && externalToolInfo.status === 'completed')) {
        const hasPoints = currentCourse.gamificationEnabled && externalToolInfo.pointsReceived;
        if (hasPoints || skillTags.length > 0) {
          if (hasPoints) {
            timelineService.updateTimeline(lectureComponent.lecturePageId);
            angularServices.TimelinesManager.updateComponentPointsAndProgress(
              lectureComponent.lecturePageId,
              lectureComponent.type,
              lectureComponent.id,
              externalToolInfo.pointsReceived,
            );
          }
          angularServices.$uibModal.open({
            templateUrl: 'shared/templates/points-modal.html',
            windowClass: 'points-modal',
            controller: 'PointsModalCtrl as vm',
            resolve: {
              pointsReceived: externalToolInfo.pointsReceived,
              leaderboardPoints: externalToolInfo.leaderboardPoints,
              leaderboardRank: externalToolInfo.leaderboardRank,
              priorLeaderboardRank: externalToolInfo.priorLeaderboardRank,
              extras: { skillTags },
            },
          });
        } else {
          timelineService.updateTimeline(lectureComponent.lecturePageId);
          angularServices.TimelinesManager.updateComponentProgress(lectureComponent.lecturePageId, lectureComponent.type, lectureComponent.id,
            externalToolInfo.progress);
        }
      } else {
        dispatch(addAlertMessage({
          type: AlertMessageType.WARNING,
          message: t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.ERROR_CHECKING_PROGRESS(),
        }));
      }
    });
  };

  const markDone = () => {
    const newExternalTool = { ...externalTool, progress: 'completed' };

    if (currentTotalPoints()) {
      newExternalTool.pointsReceived = currentTotalPoints();
    }

    dispatch(markLectureComponentAsDone({
      catalogId: currentCatalogId,
      id: externalTool.id,
      externalTool: newExternalTool,
      cb: updateProgress,
    }));
  };
  const initiateMarkDone = () => {
    if (!(isCompleted
      || mode === LecturePageMode.EDIT
      || mode === LecturePageMode.RESTRICTED_EDIT
      || mode === LecturePageMode.REORDER)) {
      dispatch(openConfirmationDialog({
        title: t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.MARK_AS_DONE(),
        confirmText: t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.MARK_AS_DONE_CONFIRMATION(),
        confirmButtonVariant: 'primary',
        bodyText: t.LECTURE_PAGES.COMPONENTS.WEB_LINK.MARK_DONE_DESCRIPTION(),
        icon: 'info',
        onConfirm: () => markDone(),
      }));
    }
  };

  const checkProgress = () => {
    if (!(isCompleted
      || mode === LecturePageMode.EDIT
      || mode === LecturePageMode.RESTRICTED_EDIT
      || mode === LecturePageMode.REORDER)) {
      setCheckInProgress(true);
      const newExternalTool = { ...externalTool };

      if (currentTotalPoints()) {
        newExternalTool.pointsReceived = currentTotalPoints();
      }

      dispatch(checkLectureComponentProgress({
        catalogId: currentCatalogId,
        id: externalTool.id,
        externalTool: newExternalTool,
        cb: updateProgress,
      })).then((response) => {
        setCheckInProgress(false);
        if (response.error || !response.payload.result) {
          dispatch(addAlertMessage({
            type: AlertMessageType.WARNING,
            message: t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.ERROR_CHECKING_PROGRESS(),
          }));
        }
      });
    }
  };

  /** Saves the header text change on input focus loss */
  const handleTitleChange = (newTitle): void => {
    // Do not update if the value hasn't changed
    if (newTitle === lectureComponent.content) {
      return;
    }
    setHeaderText(newTitle);
    save(newTitle, colorProps);
  };

  const saveWebLinkImage = (colors: Parameters<typeof save>[1], pictureData: Parameters<typeof save>[2]) => {
    save(headerText, colors, pictureData);
  };

  const updateActivity = () => {
    if (mode !== LecturePageMode.EDIT && !isCompleted) {
      dispatch(updateActivityProgress({ id: externalTool.id, progress: 'in_progress' }));
    }
  };

  const showActivityTypeDropdown = (mode === LecturePageMode.EDIT || mode === LecturePageMode.LINKED_EDIT) && isActivity;
  const angularComponent = angularServices.$scope[`lectureComponent${props.lectureComponent.id}`];

  const autoCompletionProps = getAutoCompletionProps(angularComponent);
  const autoItems = () => createAutoCompletionDropdownItem(autoCompletionProps);
  const dropdownItems = (fromTodoToggle = false, showAutoComplete = true) => {
    const items: NvDropdownOption[] = [
      ...showAutoComplete ? autoItems() : [],
      {
        type: 'header',
        title: t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.PICK(),
      },
    ];
    ACTIVITY_TYPES.forEach(activityType => {
      items.push(
        {
          type: 'text',
          text: activityType.translateKey,
          iconClass: fromTodoToggle ? activityType.iconClass : '',
          callback: () => {
            setCurrentActivityType(activityType.name);
            if (fromTodoToggle) {
              setIsTodo(true);
            }
          },
          dataQa: activityType.name,
        },
      );
    });
    return items;
  };

  useEffect(() => {
    if (currentActivityType !== externalTool.activityType || isTodo !== externalTool.isTodo) {
      save(headerText, colorProps);
    }
  }, [currentActivityType, isTodo]);

  const getStatusTranslateKey = () => ACTIVITY_TO_STATUS_TRANSLATE_KEY[externalTool.activityType];
  const getProgressTranslateKey = () => (isActivity ? t.LECTURE_PAGES.COMPONENTS.ACTIVITY.STATUSES.DONE() : t.EXERCISES.SUBMITTED());
  return (
    <div css={webLinkComponentStyles}>
      {
        isActivity && (
        <div className='text-center'>
          <div className='status'>
            <ActivityStatus icon={ACTIVITY_TO_ICON[currentActivityType]} status={externalTool.progress} />
          </div>
          <div className='activity-title'>
            {
              showActivityTypeDropdown
                ? (
                  <NvDropdown
                    useMaxHeightModifier
                    showSelectedIndicator
                    // Adding API based completion items count for calculating index
                    initialIndex={initialIndex + API_COMPLETION_ITEMS_LENGTH}
                    menuClassName='filters-menu'
                    items={dropdownItems()}
                    buttonStyle={NvDropdownButtonStyle.CUSTOM}
                    align={NvDropdownAlign.CENTER}
                    drop='down'
                    flip={false}
                    customTarget={() => (
                      <div
                        pendo-tag-name={config.pendo.bookmarks.bookmarksSelectCourse}
                        className='condensed-dropdown custom-filter-target'
                        data-qa={config.pendo.bookmarks.bookmarksSelectCourse}
                      >
                        <span>{getStatusTranslateKey()}</span>
                        <div className='icon text-xs icon-dropdown-arrow' />
                      </div>
                    )}
                  />
                )
                : (
                  <ActivityTitle
                    status={externalTool.progress}
                    statusesTexts={{
                      not_started: getStatusTranslateKey(),
                      completed: getProgressTranslateKey(),
                    }}
                  />
                )
            }
          </div>
          <div className='page-subtitle'>
            <div className='title-input-wrapper click-to-edit-text-wrapper'>
              {
                userCanEdit ? (
                  <NvClickToEdit
                    text={externalTool.displayText || externalTool.name}
                    value={externalTool.displayText || externalTool.name}
                    type={InputType.SHORT_TEXT}
                    onChange={(newTitle) => handleTitleChange(newTitle)}
                    className='editable-title'
                    editable
                    dataQa={config.pendo.activities.webLink.editableTitle}
                    maxLength={255}
                  />
                )
                  : (
                    <h2 tabIndex={-1}>
                      {externalTool.displayText || externalTool.name}
                    </h2>
                  )
              }
            </div>
          </div>
          {pointsConfiguration && !!pointsConfiguration.points && (
            <div className='activity-points'>
              <ActivityPoints totalPoints={pointsConfiguration.points} pointsReceived={externalTool.pointsReceived} />
            </div>
          )}
        </div>
        )
      }
      <div className='d-flex'>
        <WebLinkPictureArea
          userCanEdit={userCanEdit}
          picture={lectureComponent.picture}
          viewOptions={viewOptions}
          saveWebLinkImage={saveWebLinkImage}
          isPreview={props.isPreview}
        />
        <div
          className='web-link-textarea d-flex text-regular'
          onFocus={(e) => setIsHovering(true)}
          onMouseOver={(e) => setIsHovering(true)}
          onMouseLeave={(e) => setIsHovering(false)}
        >
          <NvTooltip text={externalTool.url} enabled={isHovering} css={twoLineEllipsisStyles}>
            <a
              className='d-flex h-100 px-3'
              href={externalTool.launchUrl}
              target='_blank'
              rel='noreferrer'
              onClick={updateActivity}
            >
              <div className='anchor-inner'>
                {externalTool.title}
              </div>
            </a>
          </NvTooltip>
          {userCanEdit && isHovering && (
            <div
              className='colorpicker-container ml-auto px-3'
            >
              <WebLinkColorPickerPopover<{
                textColor: string,
                backgroundColor: string,
              }>
                colorValues={colorProps}
                onChange={(colorProp, newVal: string) => {
                  const newColorProps = {
                    ...colorProps,
                    [colorProp]: newVal,
                  };
                  setColorProps(newColorProps);
                  save(headerText, newColorProps);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className='buttons-section'>
        {
          shouldShowMarkDone() && (
            <button
              className='btn btn-primary'
              type='button'
              disabled={shouldDisableMarkAsDone()}
              pendo-tag-name={config.pendo.activities.webLink.markAsDone}
              data-qa={config.pendo.activities.webLink.markAsDone}
              onClick={initiateMarkDone}
            >
              { isCompleted ? t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.MARKED_AS_DONE() : t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.MARK_AS_DONE() }
            </button>
          )
        }
        {
          shouldShowCheckMyProgress() && (
            <div className='text-align-center button-bar'>
              <div className='activity-points'>
                <div className='label text-small gray-2'>
                  { t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.SUMTOTAL_DESCRIPTION() }
                </div>
              </div>
              <button
                className='btn btn-primary'
                type='button'
                disabled={shouldDisableMarkAsDone()}
                pendo-tag-name={config.pendo.activities.webLink.checkProgress}
                data-qa={config.pendo.activities.webLink.checkProgress}
                onClick={checkProgress}
              >
                { isCompleted ? t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.CHECK_MY_PROGRESS() : t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.CHECK_MY_PROGRESS() }
              </button>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default WebLinkLectureComponent;
