import { css } from '@emotion/react';
import { useContext, useRef, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import t from 'react-translate';
import { RootState } from 'redux/schemas';
import { CourseAliases } from 'redux/schemas/models/course';
import { getCourseAliases } from 'redux/selectors/course';
import NvTextInput from 'shared/components/inputs/nv-text-input';

import { VideoPracticeOption } from 'redux/schemas/models/video-practice';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { gray3, gray4, primary } from 'styles/global_defaults/colors';
import prodPathReplace from 'shared/prod-path-rewrite';
import { VideoPracticeOptionsModalContext, ChooseVideoPracticeOptionForm } from './video-practice-options-modal';
import { config } from '../../../../config/config.json';

type ChooseVideoPracticeOptionProps = {
  onSubmit: (e: any) => void;
  formData: ChooseVideoPracticeOptionForm
};

const ChooseVideoPracticeOption = ({
  onSubmit,
  formData,
}: ChooseVideoPracticeOptionProps) => {
  const styles = css`
    min-height: 442px;
    display: flex;
    flex-direction: column;

    .selection-container {
      display: flex;
      flex: 1;

      .selection {
        width: 50%;
        margin: ${halfSpacing}px;
        margin-bottom: auto;
        text-align: center;
        color: ${gray4};
        cursor: pointer;

        .selection-circle {
          width: 81px;
          height: 81px;
          margin: auto 0;
          display: inline-block;
          padding: 20px;
          border: 1px solid ${gray3};
          border-radius: 100%;

          img {
            width: 40px;
          }
        }

        .colored {
          display: none;
        }

        .detailed {
          display: none;
          margin-top: ${standardSpacing}px;
        }

        &:hover, &.selected {
          color: ${primary};

          .selection-circle {
            border-color: ${primary};
          }

          .gray {
            display: none;
          }

          .colored {
            display: inline;
          }

          .detailed {
            display: block;
            color: ${gray4};
          }

        }

        .description, .title {
          margin-top: ${halfSpacing}px;
        }
      }
    }

    .nv-text-input {
      width: 60px;
    }
  `;

  const inputRef = useRef(null);

  const { editBasics, setTitle } = useContext(VideoPracticeOptionsModalContext);
  const aliases: CourseAliases = useSelector((state: RootState) => getCourseAliases(state));

  const validationSchema = yup.object().shape({
    videoPracticeOption: yup.mixed().required(),
    maxTries: yup.number().positive().nullable()
      // checking self-equality works for NaN, transforming it to null
      .transform((val) => (val === Number(val) ? val : null))
      .label(t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.MAX_TRIES.ERROR_LABEL())
      .when('videoPracticeOption', {
        is: VideoPracticeOption.ON_SPOT,
        then: yup.number().positive().required(),
      }),
  });

  const methods = useForm<ChooseVideoPracticeOptionForm>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: formData,
  });

  const { register, watch, setValue, handleSubmit, formState } = methods;
  const { isValid, isDirty } = formState;

  const [videoPracticeOption, maxTries] = watch(['videoPracticeOption', 'maxTries']);

  useEffect(() => {
    if (editBasics) {
      setTitle(t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.EDIT.BASICS());
    } else {
      setTitle(t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.CHOOSE_VIDEO_PRACTICE_OPTION());
    }
  }, [setTitle, editBasics]);

  useEffect(() => {
    register('videoPracticeOption');
  }, [register]);

  const chooseOption = (option: VideoPracticeOption) => {
    setValue('videoPracticeOption', option, { shouldValidate: true, shouldDirty: true });
    if (option === VideoPracticeOption.ON_SPOT) {
      inputRef.current.focus();
    }
    if (option === VideoPracticeOption.WHEN_READY) {
      // setting value of maxTries to default(1) when switching to 'When Ready' Option
      setValue('maxTries', 1, { shouldValidate: true, shouldDirty: true });
    }
  };

  const maxTriesString = t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.MAX_TRIES.LABEL('INPUT', maxTries);
  const [prefix, suffix] = maxTriesString.split('INPUT');

  return (
    <FormProvider {...methods}>
      <form className='video-practice-form' onSubmit={handleSubmit(onSubmit)}>
        <div css={styles}>
          <div className='selection-container'>
            <div className={`selection ${videoPracticeOption === VideoPracticeOption.ON_SPOT ? 'selected' : ''}`} onClick={() => chooseOption(VideoPracticeOption.ON_SPOT)} data-qa={config.pendo.practiceActivity.create.respondOnSpot}>
              <div className='selection-circle'>
                <img className='gray' src={prodPathReplace('images/respond-on-the-spot.png')} alt={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RESPOND_ON_SPOT.DESCRIPTION()} />
                <img className='colored' src={prodPathReplace('images/respond-on-the-spot-colored.png')} alt={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RESPOND_ON_SPOT.DESCRIPTION()} />
              </div>
              <div className='description'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RESPOND_ON_SPOT.DESCRIPTION()}</div>
              <div className='detailed text-left'>
                <div className='detailed-text text-gray-2 mb-4'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RESPOND_ON_SPOT.DETAILED(aliases.learnersAliases)}</div>
                <div className='d-flex align-items-center'>
                  <NvTextInput
                    withForm
                    required
                    name='maxTries'
                    type='number'
                    autoComplete='off'
                    ariaLabel={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.MAX_TRIES.ARIA_LABEL()}
                    ref={inputRef}
                  />
                  <div className='ml-2 text-dark'>{suffix}</div>
                </div>
              </div>
            </div>
            <div className={`selection ${videoPracticeOption === VideoPracticeOption.WHEN_READY ? 'selected' : ''}`} onClick={() => chooseOption(VideoPracticeOption.WHEN_READY)} data-qa={config.pendo.practiceActivity.create.respondWhenReady}>
              <div className='selection-circle'>
                <img className='gray' src={prodPathReplace('images/respond-when-ready.png')} alt={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RESPOND_WHEN_READY.DESCRIPTION()} />
                <img className='colored' src={prodPathReplace('images/respond-when-ready-colored.png')} alt={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RESPOND_WHEN_READY.DESCRIPTION()} />
              </div>
              <div className='description'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RESPOND_WHEN_READY.DESCRIPTION()}</div>
              <div className='detailed text-left'>
                <div className='detailed-text text-gray-2 mb-4'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RESPOND_WHEN_READY.DETAILED(aliases.learnersAliases)}</div>
              </div>
            </div>
          </div>
          {videoPracticeOption && (
            <div className='button-bar w-0'>
              <Button type='submit' disabled={!isValid || !isDirty} data-qa={config.pendo.practiceActivity.create.choosePracticeOptionNextButton}>{editBasics ? t.FORM.UPDATE() : t.FORM.NEXT()}</Button>
            </div>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default ChooseVideoPracticeOption;
