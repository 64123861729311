/* @ngInject */
export default function NvTimelineItemCardView(
) {
  return {
    scope: {
      item: '=',
      decayEnabled: '=?',
      gamificationEnabled: '=?',
      collapseTimelineFunction: '&?',
      customState: '=?',
    },
    replace: true,
    controller: function ctrl($scope, $element, $attrs) {
'ngInject';
      const vm = this;
      vm.showInProgressTooltip = false;
      vm.showProgress = true;
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'timelines/templates/nv-timeline-lecture-page-card-view.html',
  };
}
