import { PARSING_MODES } from './upload-users-csv-controller';

export const MANAGE_MENTEES_MODAL_VIEWS = {
  DISPLAY_USERS: 1,
  BULK_ASSIGN: 2,
  MANUAL_ASSIGN: 3,
};

/* @ngInject */
export default function ManageMenteesModalControllerService(
  _,
  $uibModal,
  $uibModalInstance,
  CurrentCourseManager,
  ConfirmationOverlays,
  AlertMessages,
  mentor,
  refreshTable,
  view,
) {
  class ManageMenteesModalController {
    constructor() {
      this.mentor = mentor;
      this.menteeRelationships = [];
      // Holds the mentees for this mentor. Each item is a mentee relationship like
      // { id: 1, mentee: <User object for the Mentee> }. The ID represents the mentor<->mentee mapping
      // and is used in requests to the backend
      this.loadMentees();

      this.VIEWS = MANAGE_MENTEES_MODAL_VIEWS;

      this.parsingMode = PARSING_MODES.MENTEES_SINGLE_MENTOR;

      this.currentView = view || this.VIEWS.DISPLAY_USERS;

      this.loadMentees = this.loadMentees.bind(this);
    }

    menteesCount() {
      return this.mentor.menteesCount || 0;
    }

    /** Loads the mentees relationship data for the current mentor */
    loadMentees(displayUsers) {
      CurrentCourseManager.course.getMentees(this.mentor).then((response) => {
        this.menteeRelationships = response.result;
        this.mentor.menteesCount = response.result.length;

        if (displayUsers) {
          this.showDisplayUsersView();
        }
      });
    }

    /** Deletes a mentee assignment for this mentor */
    deleteMentee(menteeRelationship, index) {
      const modalInstanceCtrl = ['$scope', ($scope) => {
        $scope.mentor = this.mentor;
        $scope.mentee = menteeRelationship.mentee;
      }];

      const modalInstance = ConfirmationOverlays.openConfirmationModal('user_management/templates/remove-mentee-confirm.html', modalInstanceCtrl);
      modalInstance.result.then(() => {
        CurrentCourseManager.course.removeMentee(menteeRelationship.id).then((response) => {
          this.menteeRelationships.splice(index, 1);
          this.mentor.menteesCount -= 1;
          AlertMessages.success('FORM.SUCCESS_BANG', 'USER_MANAGEMENT.REMOVED_MENTEE_FROM', {}, {
            mentorAlias: this.mentor.roles.role,
          });

          if (!this.mentor.menteesCount) {
            this.openManualAssignModal();
          }
        });
      }).catch(() => {
      });
    }

    showDisplayUsersView() {
      this.currentView = this.VIEWS.DISPLAY_USERS;
    }

    openManualAssignModal() {
      this.currentView = this.VIEWS.MANUAL_ASSIGN;
    }

    openBulkAssignModal() {
      this.currentView = this.VIEWS.BULK_ASSIGN;
    }

    /** Applies the same dropdown rerender hack used in nv-roles-dropdown's ieForceRerender() */
    ieForceDropdownRerender() {
      $('.manage-mentees-modal .mentees-link ~ dropdown-menu').focus();
    }
  }

  return new ManageMenteesModalController();
}
