import { css } from '@emotion/react';
import React from 'react';
import t from 'react-translate';
import LoadingPlaceholder from 'communications/course_communications/components/loading-placeholder';
import NvDropdown, { NvDropdownAlign, NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import { createGridStyles, halfSpacing } from 'styles/global_defaults/scaffolding';

const LoadingRow = (rowProps: { rowIndex: number }, rowEnd: number) => (
  <div style={createGridStyles(1, rowProps.rowIndex, rowEnd)}>
    {/* OPCU-TODO Replace this loader with NvLoadingPlaceholder after merging the org mentor project */}
    <LoadingPlaceholder />
  </div>
);
export const tableStyles = css`
  background-color: inherit;
  height: 100%;

  .grid {
    max-width: 800px;
    margin: 0 auto;

    .header-cell {
      border-top: none;
      background-color: white;
      padding-left: 0;
    }

    .no-results-panel {
      align-items: start;
      padding-top: 0;

      .icon {
        margin-bottom: ${halfSpacing}px;
      }
      span {
        /* For overriding default bold text weight */
        font-weight: 400 !important;
      }
    }
    .finished-row {
      margin-left: 0;
    }
  }
`;

type ActionsDropdownProps = {
  editText: string
  deleteText?: string
  onEdit: () => void
  onDelete: () => void
  hasDelete?: boolean
};

export const ActionsDropdown = ({
  editText,
  deleteText = t.NOVOED.DELETE(),
  onEdit,
  onDelete,
  /**
   * Showing the delete action option based on this hasDelete.
   * Setting this value to true for showing the delete option default.
   */
  hasDelete = true,
}: ActionsDropdownProps) => {
  const actionIems: NvDropdownOption[] = [
    {
      type: 'text',
      text: editText,
      callback: () => onEdit(),
    },
  ];

  if (hasDelete) {
    actionIems.push(
      { type: 'divider' },
      {
        type: 'text',
        text: deleteText,
        class: 'text-danger',
        callback: () => onDelete(),
      },
    );
  }

  return (
    <div className='float-right pr-2'>
      <NvDropdown
        buttonStyle={NvDropdownButtonStyle.CUSTOM}
        buttonClass='float-right'
        items={actionIems}
        align={NvDropdownAlign.RIGHT}
        offset={5}
        menuClassName='actions-menu'
        customTarget={() => (
          <NvTooltip text={t.FORM.EDIT()} placement='top'>
            <div>
              <NvIcon icon='edit' size='xss-smallest' />
            </div>
          </NvTooltip>
        )}
      />
    </div>
  );
};


export default LoadingRow;
