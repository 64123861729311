import { css } from '@emotion/react';
import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { Button } from 'react-bootstrap';

// Schemas
import { TimelineNormalized, TimelineItemTypes } from 'redux/schemas/models/course';

// Contexts
import { RootState } from 'redux/schemas';
import { CommunicationDispatch, CommunicationAction, TriggerType } from 'communications/course_communications/contexts/communication-context';

// Selectors
import { getCourseTimeLine } from 'redux/selectors/timeline';

// Components
import NvFormDropdown from 'shared/components/inputs/nv-form-dropdown';

// Styles
import { halfSpacing, quarterSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { gray3, gray5 } from 'styles/global_defaults/colors';

type DuplicateLessonProps = {
  isDisplayed: boolean
  onClose: Function
};

const styles = (isDisplayed: boolean) => css`
  .lesson-wrapper {
    display: ${isDisplayed ? 'flex' : 'none'};
    align-items: center;
  }

  .bs4-dropdown {
    .bs4-dropdown-menu {
      width: 100%;
      overflow-y: auto;
      .bs4-dropdown-item {
        padding: ${quarterSpacing}px ${threeQuartersSpacing}px;
      }
      max-height: 400px;

      ::-webkit-scrollbar {
        width: ${threeQuartersSpacing}px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 ${quarterSpacing}px ${halfSpacing}px #fff;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        border: ${quarterSpacing}px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        background-color: ${gray5};
      }
    }
  }

  .timeline-item-header {
    color: ${gray3};
    margin: ${halfSpacing}px 0px 0px ${quarterSpacing}px;

    &:hover {
      cursor: auto;
    }
  }
`;

const DuplicateLesson = (props: DuplicateLessonProps) => {
  const { State, dispatch } = useContext(CommunicationDispatch);
  const [activityType] = useState(State.activityType);
  const [activityId, setActivityId] = useState(State.activityId);

  const timeLine: TimelineNormalized[] = useSelector((state: RootState) => getCourseTimeLine(state));

  const timelineDropdownItems = [];
  let lastTimelineItem: TimelineNormalized;
  let lastTimelineSectionItem: string;

  (timeLine || []).forEach((item) => {
    if (item?.type === TimelineItemTypes.PAGE) {
      // Its a Page
      timelineDropdownItems.push({
        type: 'text',
        id: item.id,
        text: item.title,
        value: item.id,
        class: 'text-medium',
      });
    } else {
      // Subsection or Section
      let timelineItem: JSX.Element;

      if (lastTimelineItem?.type === TimelineItemTypes.PAGE) {
        // Add a divider if its a section or subsection after a page
        timelineDropdownItems.push({
          type: 'divider',
          id: `divider-${item.id}`,
        });
      }

      if (item?.type === TimelineItemTypes.SECTION) {
        lastTimelineSectionItem = item?.title;
        timelineItem = <span className='bold'>{item.title}</span>;
      } else if (item?.type === TimelineItemTypes.SUBSECTION) {
        timelineItem = (
          <React.Fragment>
            { lastTimelineSectionItem
              ? (
                <React.Fragment>
                  <span className='bold'>
                    {lastTimelineSectionItem}
                  </span>
                  <span className='mx-1'>&gt;</span>
                  <span>
                    {item.title}
                  </span>
                </React.Fragment>
              )
              : <span>{item.title}</span>}
          </React.Fragment>
        );
      }

      timelineDropdownItems.push({
        type: 'custom',
        id: `timeline-header-${item.id}`,
        customItem: <div className='timeline-item-header text-small'>{timelineItem}</div>,
      });
    }
    lastTimelineItem = item;
  });

  const onSave = () => {
    if (activityId && activityType) {
      // Updating context
      dispatch({
        type: CommunicationAction.SHOW_MODAL,
        triggerType: TriggerType.DUPLICATE,
        communicationType: State.communicationType,
        activityType,
        activityId,
        lecturePageId: activityId,
      });
      props.onClose();
    }
  };

  return (
    <div css={styles(props.isDisplayed)}>
      <div className='bs4-row lesson-wrapper pt-2'>
        <div className='bs4-col-md-8'>
          <NvFormDropdown
            name='ownerId'
            items={timelineDropdownItems}
            onChange={(val) => setActivityId(val.value)}
          />
        </div>
        <div className='buttons bs4-col-md-4'>
          <Button
            onClick={() => onSave()}
          >
            {t.FORM.SAVE()}
          </Button>
          <Button
            className='ml-2'
            variant='secondary'
            onClick={() => props.onClose()}
          >
            {t.FORM.CANCEL()}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DuplicateLesson;
