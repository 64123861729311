import React from 'react';
import { css } from '@emotion/react';
import { gray6 } from 'styles/global_defaults/colors';

export const BAR_HEIGHT = 6;

export type NvProgressBarProps = {
  currentValue: number,
  maxValue: number,
  color?: string;
  className?: string;
  onBarWidthChange?: (width: number) => void;
};

const NvProgressBar = ({
  color,
  maxValue,
  className,
  currentValue,
  onBarWidthChange,
}: NvProgressBarProps) => {
  const barWidth = Math.round((Math.min(currentValue, maxValue) / maxValue) * 100);
  const onBarWidthChangeRef = React.useRef(onBarWidthChange);
  onBarWidthChangeRef.current = onBarWidthChange;

  React.useLayoutEffect(() => {
    onBarWidthChangeRef.current?.(barWidth);
  }, [barWidth]);

  const styles = css`
    margin: 0;
    border-radius: 3px;
    background: ${gray6};
    height: ${BAR_HEIGHT}px;

    .progress {
      border-radius: 3px;
      width: ${barWidth}%;
      background: ${color};
      height: ${BAR_HEIGHT}px;
    }
  `;

  return (
    <div css={styles} className={className}>
      <div className='progress' />
    </div>
  );
};

export default NvProgressBar;
