import { Tab } from '../../timelines/components/course_home/course-timeline';

/* @ngInject */
export default function MenteesPageController(
  _,
  $stateParams,
  $uibModal,
  $scope,
  $rootScope,
  $translate,

  nvCurrentPage,
  CurrentCourseManager,
  TimelinesManager,

  ReportsManager,
  PubSubDiscussions,
  MenteesManager,
) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _this = this;

  _.extend(this, {
    _,
    $stateParams,

    nvCurrentPage,
    CurrentCourseManager,
    TimelinesManager,

    MenteesManager,

    launchUserProgressModal,
    orderByFeatureable,
    filterBySubmittings,
    emptyStateMenteeAlias,
  });

  ReportsManager.getApprovalExercises(true);

  function launchUserProgressModal(userObj, completionStatus) {
    $uibModal.open({
      templateUrl: 'shared/templates/points-breakdown-modal.html',
      controller: 'PointsBreakdownModalController',
      controllerAs: 'vm',
      windowClass: 'large-modal',
      resolve: {
        user: {
          id: userObj.id,
          fullName: userObj.fullName,
        },
        course: {
          completionStatus,
        },
        showOutline: false,
        selectedTab: () => Tab.TODOS,
      },
    });
  }

  // custom comparator for ordering the user's submissions by the order of the assignment list
  function orderByFeatureable(a, b) {
    const aIndex = _.findIndex(MenteesManager.featureableExercises, { id: a.value });
    const bIndex = _.findIndex(MenteesManager.featureableExercises, { id: b.value });

    return (aIndex < bIndex) ? -1 : 1;
  }

  // custom predicate for filtering exercises by those present in the submissionsHash
  function filterBySubmittings(userCourse) {
    if (MenteesManager.filter === 'needsComment') {
      return (exercise) => (userCourse?.submissionsHash?.[exercise.id]
          && _.some(userCourse.submissionsHash[exercise.id], (submission) => !submission.commented));
    } if (MenteesManager.filter === 'needsApproval') {
      return (exercise) => (userCourse?.submissionsHash?.[exercise.id]
          && _.some(userCourse.submissionsHash[exercise.id], (submission) => submission.needsReview));
    } if (MenteesManager.filter === 'needsPrivateFeedback') {
      return (exercise) => (userCourse?.submissionsHash?.[exercise.id]
          && _.some(userCourse.submissionsHash[exercise.id], (submission) => submission.needsPrivateFeedback));
    }

    return null;
  }

  function emptyStateMenteeAlias() {
    if (MenteesManager.filter === 'total') {
      if (MenteesManager.ownerType === 'User') {
        return CurrentCourseManager.course.userCourse.roles.menteeName.pluralizedTitleized;
      }
      if (MenteesManager.ownerType === 'Team') {
        return CurrentCourseManager.course.teamName.pluralizedTitleized;
      }
      if (MenteesManager.ownerType === 'Group') {
        return CurrentCourseManager.course.groupName.pluralizedTitleized;
      }
      return $translate.instant('MENTEES.EMPTY_STATE.MEMBER_TITLEIZED');
    }

    if (MenteesManager.ownerType === 'User') {
      return CurrentCourseManager.course.userCourse.roles.menteeName.downcasedPluralized;
    }
    if (MenteesManager.ownerType === 'Team') {
      return CurrentCourseManager.course.teamName.downcasedPluralized;
    }
    if (MenteesManager.ownerType === 'Group') {
      return CurrentCourseManager.course.groupName.downcasedPluralized;
    }
    return $translate.instant('MENTEES.EMPTY_STATE.MEMBER_LOWERCASE');
  }

  /* Updates coments made by current user from L4 Report - Start */
  const commentCreateToken = PubSubDiscussions.subscribe('comment.create', (data) => {
    const changedUsers = setCommentedFlag(true, data);
    MenteesManager.onReportComment({
      mentees: changedUsers,
      action: 'create',
    });
  });

  const commentDeleteToken = PubSubDiscussions.subscribe('comment.delete', (data) => {
    const changedUsers = setCommentedFlag(false, data);
    MenteesManager.onReportComment({
      mentees: changedUsers,
      action: 'delete',
    });
  });

  const replyCreateToken = PubSubDiscussions.subscribe('reply.create', (data) => {
    const changedUsers = setRepliedFlag(true, data);
    MenteesManager.onReportComment({
      mentees: changedUsers,
      action: 'create',
    });
  });

  const replyDeleteToken = PubSubDiscussions.subscribe('reply.delete', (data) => {
    const changedUsers = setRepliedFlag(false, data);
    MenteesManager.onReportComment({
      mentees: changedUsers,
      action: 'delete',
    });
  });

  const reviewedToken = PubSubDiscussions.subscribe('report.reviewed', (data) => {
    const changedUsers = setReviewedFlag(data);
    MenteesManager.onReportReviewed(changedUsers);
  });

  // Helpers
  function setCommentedFlag(createFlag, data) {
    const userCoursesToReturn = [];
    const hasMentorCommented = data.ownerType === 'report' && data.comment.belongsToCurrentUser() && (data.comment.owner.posted || data.comment.owner.commented);
    if ((hasMentorCommented && createFlag) || (!createFlag && !hasMentorCommented)) {
      _.each(_this.MenteesManager.mentees, (userCourse) => {
        let hasCommentedOnAll = true;
        let hasCommentedOnNone = true;
        let isAffectedMentee = false;

        _.each(userCourse.submittings, (submitting) => {
          if (submitting.submittableId === data.comment.owner.id) {
            isAffectedMentee = true;
            submitting.commented = createFlag;
            _.findWhere(userCourse.submissionsHash[submitting.exerciseId], { id: submitting.submittableId }).commented = createFlag;
          }

          if (!submitting.commented) {
            hasCommentedOnAll = false;
          } else {
            hasCommentedOnNone = false;
          }
        });

        if (isAffectedMentee && ((hasCommentedOnAll && createFlag) || (hasCommentedOnNone && !createFlag))) {
          userCoursesToReturn.push(userCourse);
        }
      });

      return userCoursesToReturn;
    }

    return null;
  }

  function setRepliedFlag(createFlag, data) {
    const userCoursesToReturn = [];
    const hasMentorCommented = data.ownerType === 'report' && data.reply.belongsToCurrentUser() && (data.reply.owner.owner.posted || data.reply.owner.commented);
    if ((hasMentorCommented && createFlag) || (!createFlag && !hasMentorCommented)) {
      _.each(_this.MenteesManager.mentees, (userCourse) => {
        let hasRepliedToAll = true;
        let hasRepliedToNone = true;
        const isAffectedMentee = false;
        _.each(userCourse.submittings, (submitting) => {
          if (submitting.submittableId === data.reply.owner.owner.id) {
            submitting.commented = createFlag;
            _.findWhere(userCourse.submissionsHash[submitting.exerciseId], { id: submitting.submittableId }).commented = createFlag;
          }

          if (!submitting.commented) {
            hasRepliedToAll = false;
          } else {
            hasRepliedToNone = false;
          }
        });

        if (isAffectedMentee && ((hasRepliedToAll && createFlag) || (hasRepliedToNone && !createFlag))) {
          userCoursesToReturn.push(userCourse);
        }
      });

      return userCoursesToReturn;
    }

    return null;
  }

  function setReviewedFlag(data) {
    const userCoursesToReturn = [];

    _.each(_this.MenteesManager.mentees, (userCourse) => {
      _.each(userCourse.submittings, (submitting) => {
        if (submitting.submittableId === data.report.id) {
          submitting.needsReview = false;
          _.findWhere(userCourse.submissionsHash[submitting.exerciseId], { id: submitting.submittableId }).needsReview = false;

          if (_.every(userCourse.submittings, (otherSubmitting) => !otherSubmitting.needsReview)) {
            userCoursesToReturn.push(userCourse);
          }
        }
      });
    });

    return userCoursesToReturn;
  }

  const deregisterReenterState = $rootScope.$on('$stateChangeSuccess', (event, toState, toParams) => {
    if (toState.name === 'mentees') {
      ReportsManager.getApprovalExercises(true);
    }
  });

  $scope.$on('$destroy', () => {
    PubSubDiscussions.unsubscribe(commentCreateToken);
    PubSubDiscussions.unsubscribe(commentDeleteToken);
    PubSubDiscussions.unsubscribe(replyCreateToken);
    PubSubDiscussions.unsubscribe(replyDeleteToken);
    PubSubDiscussions.unsubscribe(reviewedToken);

    deregisterReenterState();
  });
}
