import Bs4Testbed from '../components/bs4-testbed';

export default class Bs4TestbedCtrl {
  /* @ngInject */
  constructor(
    $scope,
  ) {
    $scope.Bs4Testbed = Bs4Testbed;
  }
}
