export default {
  bindings: {
    onClick: '&?',
    translateY: '=?',
    index: '<?',
    total: '<?',
  },
  controller: function ctrl($scope, $element, $timeout) {
'ngInject';
    const vm = this;

    _.extend(vm, {
      onNextClick,
    });

    if (vm.index === vm.total - 1) {
      vm.toolTipMessage = 'EXERCISES.NEXT_TO_SUBMIT';
    } if (vm.index < vm.total - 1) {
      vm.toolTipMessage = 'EXERCISES.JUMP_TO_NEXT_QUESTION';
    }

    function animate(options) {
      const start = performance.now();
      requestAnimationFrame(function animate(time) {
        let timeFraction = (time - start) / options.duration;
        if (timeFraction > 1) timeFraction = 1;
        const progress = options.timing(timeFraction);
        options.draw(progress);
        if (timeFraction < 1) {
          requestAnimationFrame(animate);
        }
      });
    }

    function makeEaseOut(timing) {
      return (timeFraction) => (1 - timing(1 - timeFraction));
    }

    function bounce(timeFraction) {
      for (let a = 0, b = 1, result; 1; a += b, b /= 2) {
        if (timeFraction >= (7 - 4 * a) / 11) {
          return -Math.pow((11 - 6 * a - 11 * timeFraction) / 4, 2) + Math.pow(b, 2);
        }
      }
    }

    function bounceElement(element, translateY) {
      const duration = 1500;
      const timing = makeEaseOut(bounce);
      const draw = (progress) => {
        const transform = `translateY(${progress * translateY}px)`;
        element.css({ transform });
      };
      const options = { duration, timing, draw };
      $timeout(animate.bind(this, options), 0);
    }

    function computeTranslation() {
      if (vm.onClick && vm.translateY) {
        const { y } = $element[0].getBoundingClientRect();
        const element = $element.find('.next-indicator');
        const { availHeight } = document.defaultView.screen;
        const maxHeight = availHeight - y - 200;
        const translateY = vm.translateY > maxHeight ? maxHeight : vm.translateY - 90;
        bounceElement(element, translateY);
      }
    }

    $scope.$watch('vm.translateY', computeTranslation);

    function onNextClick() {
      if (vm.onClick) vm.onClick();
    }
  },
  controllerAs: 'vm',
  templateUrl: 'lecture_pages/templates/components/nv-animated-next-button.html',
};
