import { css } from '@emotion/react';
import { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';
import LectureComponentProvider, { useLectureComponentContext } from 'components/lecture-component-provider';
import BaseLectureComponentContext from 'lecture_pages/directives/components/base-lecture-component/context';
import { updateLectureComponent } from 'redux/actions/lecture-pages';
import { RootState } from 'redux/schemas';
import { CourseAliases } from 'redux/schemas/models/course';
import { getCourseAliases, getCurrentCourse } from 'redux/selectors/course';
import {
  getFormOrgLevelProfileSettings as orgLevelProfileSettingsSelector,
} from 'redux/selectors/org-level-profile';
import { ComponentType } from 'redux/schemas/models/lecture-component';
import { useAppDispatch } from 'redux/store';
import NvButtonPopover, { Button } from 'shared/components/nv-button-popover';
import { NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { LectureComponentProps, LecturePageMode } from 'lecture_pages/components';
import { gray2, gray3, gray4, gray5, gray6, primary } from 'styles/global_defaults/colors';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import NvTooltip, { TextAlign } from 'shared/components/nv-tooltip';
import { useLecturePageFromParams } from 'lecture_pages/hooks/lecture-routing';
import { DefaultProfileQuestion } from './meet-and-greet-modal';
import MeetAndGreetPersonCard from './meet-and-greet-person-card';
import { config } from '../../../../config/pendo.config.json';


export enum ShapeOptions {
  SQUARE = 'square',
  ROUNDED_SQUARE = 'rounded_square',
  CIRCLE = 'circle',
}

const MeetAndGreetComponentContextWrapper = (props: LectureComponentProps<ComponentType.MEET_AND_GREET>) => {
  const isEdit = props.mode === LecturePageMode.EDIT;
  const catalogId = useSelector((state) => state.app.currentCatalogId);

  return (
    <LectureComponentProvider
      catalogId={catalogId}
      lectureComponentId={props.lectureComponent.id}
      lecturePageId={props.currentLecture.id}
      isEdit={isEdit}
      angularComponent={props.angularComponent}
    >
      <MeetAndGreetLectureComponent {...props} />
    </LectureComponentProvider>
  );
};

const MeetAndGreetLectureComponent = (props: LectureComponentProps<ComponentType.MEET_AND_GREET>) => {
  const shapeButtonStyles = css`
    height: 16px;
    width: 16px;
    background-color: ${gray5};
  `;

  const dispatch = useAppDispatch();

  const { sharedProps, setSharedProps } = useContext(BaseLectureComponentContext);
  const { $uibModal, $state } = useContext(AngularServicesContext);

  const { isEdit, catalogId, lectureComponentId, lecturePageId } = useLectureComponentContext();
  const { accountLevel: { bio, headline, location }, orgLevel = [] } = useSelector(orgLevelProfileSettingsSelector);
  const lectureComponent = useSelector(state => state.models.lectureComponents[lectureComponentId]);
  const {
    answerableId,
    answerableType,
    cardBorderStyle = ShapeOptions.SQUARE,
    defaultProfileQuestion,
    numColumns = 4,
    numRows = 2,
    photosRequired,
    sortBy,
    users = [],
  } = lectureComponent.meetAndGreet;

  const [shape, setShape] = useState<ShapeOptions>(cardBorderStyle);
  const [columnsCount, setColumnsCount] = useState<number>(numColumns);
  const [rowsCount, setRowsCount] = useState<number>(numRows);
  const totalCount = rowsCount * columnsCount;

  const aliases: CourseAliases = useSelector((state: RootState) => getCourseAliases(state));
  const { isContentManagementCollection } = useSelector(getCurrentCourse);

  const { isLinked } = useLecturePageFromParams();


  const people = isContentManagementCollection ? [] : users.slice(0, totalCount);
  if (isEdit && people.length < totalCount) {
    people.push(...Array(totalCount - people.length).fill({ empty: true }));
  }

  let questionIcon;
  if (answerableType === 'ProfileSetting') {
    const questionToShow = orgLevel.find(question => question.id === answerableId) ?? {};
    questionIcon = questionToShow.picture ?? questionToShow.viewOptions;
  } else if (defaultProfileQuestion === DefaultProfileQuestion.DISPLAY_LOCATION) {
    questionIcon = { icon: 'location', iconColor: gray2 };
  }

  let showAnswer = true;
  if ((defaultProfileQuestion === DefaultProfileQuestion.DISPLAY_LOCATION && location.isHidden)
    || (defaultProfileQuestion === DefaultProfileQuestion.BIO && bio.isHidden)
    || (defaultProfileQuestion === DefaultProfileQuestion.HEADLINE && headline.isHidden)) {
    showAnswer = false;
  }

  useEffect(() => {
    const extraOptions: NvDropdownOption[] = [{
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.DROPDOWN.EDIT_BASICS(),
      callback: () => openEditModal(),
      dataQa: config.pendo.meetAndGreet.editBasics,
      disabled: isContentManagementCollection,
      tooltip: {
        text: t.LECTURE_PAGES.COMPONENTS.DROPDOWN.SETUP_IN_LINKED_LESSON_TOOLTIP(),
        enabled: isContentManagementCollection,
        placement: 'left',
        offset: 20,
      },
    }];

    setSharedProps({
      ...sharedProps,
      extraOptions: {
        mode: 'prepend',
        options: extraOptions,
      },
    });
  }, [answerableId, answerableType, defaultProfileQuestion, photosRequired, sortBy]);

  const openEditModal = () => {
    const modalInstance = $uibModal.open({
      templateUrl: 'lecture_pages/templates/components/meet-and-greet-edit-modal.html',
      controller: 'MeetAndGreetEditFormModalCtrl',
      controllerAs: 'vm',
      resolve: {
        vmResolves: {
          initialAdditionalInformation: { defaultProfileQuestion, answerableId, answerableType },
          initialSortOrder: sortBy,
          initialPhotosRequired: photosRequired,
          isEdit,
          lectureComponent,
          isLinked,
        },
      },
    });

    modalInstance.result.then((res) => {
      dispatch(updateLectureComponent({
        catalogId,
        lecturePageId,
        componentData: {
          ...lectureComponent,
          meetAndGreet: { ...res },
        },
      }));
    });
  };

  const updateColumns = (newColCount) => {
    if (newColCount !== columnsCount) {
      dispatch(updateLectureComponent({
        catalogId,
        lecturePageId,
        componentData: {
          ...lectureComponent,
          meetAndGreet: { numColumns: newColCount },
        },
      }));
      setColumnsCount(newColCount);
    }
  };

  const updateRows = (newRowCount) => {
    if (newRowCount !== rowsCount) {
      dispatch(updateLectureComponent({
        catalogId,
        lecturePageId,
        componentData: {
          ...lectureComponent,
          meetAndGreet: { numRows: newRowCount },
        },
      }));
      setRowsCount(newRowCount);
    }
  };

  const updateShape = (newShape) => {
    if (newShape !== shape) {
      dispatch(updateLectureComponent({
        catalogId,
        lecturePageId,
        componentData: {
          ...lectureComponent,
          meetAndGreet: { cardBorderStyle: newShape },
        },
      }));
      setShape(newShape);
    }
  };

  const floatingMenuButtons: Button[] = [
    {
      type: 'dropdown',
      title: t.LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.NUM_COLUMNS(columnsCount),
      dataQa: config.pendo.meetAndGreet.columns.button,
      dropdownItems: [3, 4, 5, 6].map(num => ({
        type: 'text',
        text: t.LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.NUM_COLUMNS(num),
        callback: () => updateColumns(num),
        dataQa: config.pendo.meetAndGreet.columns[num],
      })),
    },
    {
      type: 'dropdown',
      title: t.LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.NUM_ROWS(rowsCount),
      dataQa: config.pendo.meetAndGreet.rows.button,
      dropdownItems: [2, 3, 4, 5].map(num => ({
        type: 'text',
        text: t.LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.NUM_ROWS(num),
        callback: () => updateRows(num),
        dataQa: config.pendo.meetAndGreet.rows[num],
      })),
    },
    {
      type: 'custom',
      onClick: () => updateShape(ShapeOptions.SQUARE),
      customItem: (
        <div
          className='square'
          key='square'
          css={css`${shapeButtonStyles} ${shape === ShapeOptions.SQUARE && `background-color: ${primary};`}`}
          data-qa={config.pendo.meetAndGreet.shapeSquare}
        />
      ),
    },
    {
      type: 'custom',
      onClick: () => updateShape(ShapeOptions.ROUNDED_SQUARE),
      customItem: (
        <div
          className='roundedSquare'
          key='roundedSquare'
          css={css`${shapeButtonStyles} border-radius: 5px; ${shape === ShapeOptions.ROUNDED_SQUARE && `background-color: ${primary};`}`}
          data-qa={config.pendo.meetAndGreet.shapeRoundedSquare}
        />
      ),
    },
    {
      type: 'custom',
      onClick: () => updateShape(ShapeOptions.CIRCLE),
      customItem: (
        <div
          className='circle'
          key='circle'
          css={css`${shapeButtonStyles} border-radius: 8px; ${shape === ShapeOptions.CIRCLE && `background-color: ${primary};`}`}
          data-qa={config.pendo.meetAndGreet.shapeCircle}
        />
      ),
    },
  ];

  const columnsToSize = (colCount) => {
    switch (colCount) {
      case 3:
        return 260;
      case 4:
        return 192.5;
      case 5:
        return 152;
      case 6:
        return 125;
      default:
        return 0;
    }
  };

  const styles = css`
    ${isEdit && 'margin-top: 65px;'}

    .main-grid {
      // This prevents the cells of the grid, when there are fewer people than columns, from growing larger than they should
      max-width: ${people.length * columnsToSize(columnsCount) + (people.length - 1) * 10}px;
      display: grid;
      grid-template-columns: repeat(min(${columnsCount}, ${people.length}), 1fr);
      grid-gap: ${halfSpacing}px;
    }

    .no-learners {
      height: 195px;
      background-color: ${gray6};
      color: ${gray3};

      .icon-profile {
        color: ${gray4};
      }
    }

    .cursor {
      cursor: default;
    }
  `;

  const popoverStyles = css`
    z-index: 100;
  `;

  return (
    <div css={styles}>
      {isEdit && !isLinked && (
        <NvButtonPopover
          buttons={floatingMenuButtons}
          placement='top'
          overlayStyles={popoverStyles}
          show
        >
          <div className='main-grid mx-auto'>
            {people.map((person) => (
              <MeetAndGreetPersonCard
                person={person}
                shape={shape}
                columnsCount={columnsCount}
                questionIcon={questionIcon}
                showAnswer={showAnswer}
              />
            ))}
          </div>
        </NvButtonPopover>
      )}
      {(!isEdit && (isLinked || !isContentManagementCollection))
        && (
          <div className='main-grid mx-auto'>
            {people.map((person) => (
              <MeetAndGreetPersonCard
                person={person}
                shape={shape}
                columnsCount={columnsCount}
                questionIcon={questionIcon}
                showAnswer={showAnswer}
              />
            ))}
          </div>
        )}
      {(!people.length && !isContentManagementCollection && !isEdit) && (
        <div className='no-learners d-flex flex-column align-items-center font-weight-bolder'>
          <i className='icon icon-ultra-large icon-profile mt-5 mb-2' />
          {t.LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.NO_LEARNERS_TO_SHOW(aliases.learnersAliases)}
        </div>
      )}
      {(isContentManagementCollection && !isEdit) && (
        <div className='no-learners d-flex flex-column align-items-center font-weight-bolder'>
          <i className='icon icon-ultra-large icon-profile mt-5 mb-2' />
          {t.LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.NO_LEARNERS_IN_COLLECTION_LESSON(aliases.learnersAliases)}
        </div>
      )}
      {(people.length > 0 && (!isContentManagementCollection || isEdit))
        && (
          <div className='d-flex justify-content-center mt-5'>
            <NvTooltip
              enabled={isContentManagementCollection}
              placement='right'
              offset={20}
              textAlign={TextAlign.LEFT}
              text={t.LECTURE_PAGES.COMPONENTS.DROPDOWN.AVAILABLE_IN_LINKED_LESSON_TOOLTIP()}
            >
              <a
                className={isContentManagementCollection ? 'text-gray-4 cursor' : 'text-primary'}
                href={isContentManagementCollection ? undefined : $state.href('learner-directory', { catalogId })}
                target={isContentManagementCollection ? undefined : '_blank'}
                rel={isContentManagementCollection ? undefined : 'noreferrer'}
                data-qa={config.pendo.meetAndGreet.viewAllLearners}
                onClick={isContentManagementCollection ? (e) => e.preventDefault() : undefined}
              >
                {t.LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.VIEW_ALL_LEARNERS(aliases.learnersAliases)}
              </a>
            </NvTooltip>
          </div>
        )}
    </div>
  );
};

export default MeetAndGreetComponentContextWrapper;
