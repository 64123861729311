import React, { useContext } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { getSubmission } from 'redux/selectors/video-practice';
import PracticeSubmissionOptions from './practice-submission-options';
import { PracticeSubmissionContext } from '../utils';

const SubmissionHeader = () => {
  const [{ submissionId, isPracticeRoom }] = useContext(PracticeSubmissionContext);
  const { userPracticeNumber, completedAt: recordedDate, activity } = useSelector((state) => getSubmission(state, submissionId));

  return (
    <div className='d-flex flex-column flex-md-row justify-content-between'>
      {userPracticeNumber && (
        <div className='course-title-xs font-weight-bold'>
          {t.PRACTICE_ROOM.SUBMISSION.TITLE(
            moment(recordedDate).format('L hh:mm A'),
            userPracticeNumber,
          )}
        </div>
      )}
      <div className='d-flex justify-content-between mb-1'>
        <div className='d-flex'>
          {activity?.course?.name && (
          <React.Fragment>
            <div className='card-title-xs mr-1'>
              {t.PRACTICE_ROOM.SUBMISSION.PRACTICED_IN()}
            </div>
            <div className='course-title-xs'>{activity.course.name}</div>
          </React.Fragment>
          )}
        </div>
        {isPracticeRoom && (
          <PracticeSubmissionOptions />
        )}
      </div>
    </div>
  );
};
export default SubmissionHeader;
