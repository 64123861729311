/* @ngInject */
export default function EvaluationsFormBaseController(
  CurrentCourseManager,
  CurrentUserManager,
  EvaluationsTaskManager,
  PageLevelManager,
  RailsRoutes,
  nvCurrentPage,
  ScrollFocusConnectorFactory,
  $stateParams,
  $controller,
  $scope,
) {
  const vm = angular.extend(this, $controller('L3HeaderCtrl', { $scope }));

  vm.manager = EvaluationsTaskManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.PageLevelManager = PageLevelManager;
  vm.RailsRoutes = RailsRoutes;
  vm.nvCurrentPage = nvCurrentPage;
  vm.$stateParams = $stateParams;

  vm.lecturePageId = vm.manager.currentPeerReview.customQuestions.lecturePageId;
  vm.lectureActivityId = vm.manager.currentPeerReview.customQuestions.id;
  vm.subtitleSref = 'lecture-page({ catalogId: vm.$stateParams.catalogId, id: vm.lecturePageId, lectureActivityId: vm.lectureActivityId })';
  vm.subtitleText = vm.manager.currentPeerReview.customQuestions.lecturePage.title;

  return this;
}
