import { css } from '@emotion/react';
import { useRef, useState, useEffect } from 'react';
import NvTooltip from './nv-tooltip';

const NvTruncatedTextWithTooltip = (props: {
  text: string,
  className: string,
  textWidth: number,
}) => {
  const overflowingText = useRef(null);
  const [isOverflowActive, setOverflowActive] = useState(false);

  const checkOverflow = (textContainer: HTMLSpanElement | null): boolean => {
    if (textContainer) {
      return (
        textContainer.offsetWidth < textContainer.scrollWidth
      );
    }
    return false;
  };

  useEffect(() => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowActive(true);
      return;
    }

    setOverflowActive(false);
  }, []);

  return (
    <NvTooltip enabled={isOverflowActive} text={props.text} preventOverflow={false}>
      <div
        css={css`
          width: ${props.textWidth}px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        `}
        className={props.className}
        ref={overflowingText}
      >
        {props.text}
      </div>
    </NvTooltip>
  );
};

export default NvTruncatedTextWithTooltip;
