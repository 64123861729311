/* @ngInject */
export default function L3HeaderCtrl(
  CurrentCourseManager,
  ExercisesManager,
  $scope,
  $stateParams,
  $state,
) {
  const vm = this;

  vm.CurrentCourseManager = CurrentCourseManager;
  vm.ExercisesManager = ExercisesManager;
  vm.$stateParams = $stateParams;

  vm.subtitleSref = '';
  vm.subtitleText = '';

  vm.showBackButton = !$state.href(`${$state.current.name.split('-page')[0]}-modal`);
}
