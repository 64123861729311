/* @ngInject */
export default function ReportSubmittedCtrl(

  ExercisesManager,
  CurrentCourseManager,
  $state,
  $stateParams,
  $timeout,
) {
  const vm = this;

  $timeout(() => {
    vm.moveToTop = true;

    $timeout(() => {
      ExercisesManager.getExercise(CurrentCourseManager.course.catalogId, $stateParams.exerciseId).then(() => {
        $state.go('exercise-show', $stateParams);
      });
    }, 1000);
  }, 2000);
}
