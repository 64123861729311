/* @ngInject */
export default function TeamWorkspaceDirectLinkController(
  $stateParams,
  CurrentUserManager,
) {
  const vm = this;

  initialize();

  function initialize() {
    vm.CurrentUserManager = CurrentUserManager;

    // pass post id into manager to fetch single workspace post
    // console.log($stateParams.postId);
  }
}
