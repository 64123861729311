const rtlChars = [
  /* Arabic Ranges */
  '\u0600-\u06FF',
  '\u0750-\u077F',
  '\uFB50-\uFDFF',
  '\uFE70-\uFEFF',
  /* Hebrew Range */
  '\u05D0-\u05FF',
].join('');

const rtlRegEx = new RegExp(`[${rtlChars}]`, 'g');

/**
 * Test to see if >50% of the characters are Arabic or Hebrew characters
 * Arabic and Hebrew characters are displayed right-to-left
 */
/* @ngInject */
export default function isMostlyRTLText(text: string) {
  const textCount = text.replace(/[0-9\s\\/.,\-+="']/g, '').length;
  const rtlCount = (text.match(rtlRegEx) || []).length;
  return rtlCount >= (textCount - rtlCount) && textCount > 0;
}
