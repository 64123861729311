/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { InfiniteTableLoadingParams } from 'redux/create-action-creators';
import { OrgMentorRoleAlias } from 'redux/schemas/models/institution';
import { OrgMentor, FilterCounts, SortOrderType } from 'redux/schemas/models/org-mentors';
import {
  AddOrgMentorProps,
  AddInstitutionMenteeProps,
  MenteeAddResponse,
  GetMenteesForMentorParams,
  AddMultipleMenteesToMentorParams,
  OrgMentorsResponse,
} from 'redux/schemas/api/org-mentors';
import { Result } from 'redux/schemas/api';
import { makeQueryParamString } from './helpers';


export const addOrgMentor = createAsyncThunk(
  'ORG_MENTOR_ADD_INSTITUTION_ADMIN',
  async (params: AddOrgMentorProps) => axios.post<Result<Omit<OrgMentor, 'mentees'>>>(
    `/institutions/${params.institutionId}/institution_admins`,
    params,
  ).then((response) => response.data).catch((error) => {
    throw (error.response?.data?.error);
  }),
);

export const fetchOrgMentors = createAsyncThunk(
  'ORG_MENTOR_GET_INSTITUTION_ADMINS',
  async (params: { institutionId: number } & InfiniteTableLoadingParams<OrgMentor>) => {
    const sortingString = params.sorting.length === 0 ? 'last_name:asc' : params.sorting[0];
    const response = await axios.get<Result<OrgMentorsResponse>>(`/institutions/${params.institutionId}/institution_admins.json?mentor=true&sort=${sortingString}&page=${params.pageIndex}&page_size=${params.pageSize}`);
    const users = response?.data?.result?.orgAdmins || [];
    const totalCount = response?.data?.result?.count || 0;
    return {
      totalCount,
      response: users,
    };
  },
);

export const deleteInstitutionAdmin = createAsyncThunk(
  'ORG_MENTOR_DELETE_INSTITUTION_ADMIN',
  (params: { institutionId: number, institutionAdminId: number }) => axios.delete(
    `/institutions/${params.institutionId}/institution_admins/${params.institutionAdminId}`,
  ).then((response) => response.data),
);

export const updateRoleAliases = createAsyncThunk(
  'ORG_MENTOR_UPDATE_ROLE_ALIASES',
  (params: { institutionId: number, roleAliases: OrgMentorRoleAlias }) => axios.post(
    `/institutions/${params.institutionId}/save_role_alias`,
    params.roleAliases,
  ).then((response) => response.data),
);

export const searchNonAdminUsers = createAsyncThunk(
  'ORG_MENTOR_SEARCH_NON_ADMIN_USERS',
  (params: {
    queryTerm: string,
    institutionId: number,
  }) => axios.get(
    `/institutions/${params.institutionId}/institution_admins/search?query_term=${params.queryTerm}`,
  ).then((response) => response.data),
);


export const searchOrganizationUsers = createAsyncThunk(
  'ORG_MENTOR_SEARCH_USERS',
  (params: {
    page: number,
    pageSize: number,
    queryTerm: string,
    notAssignedTo: number,
    institutionLearners: boolean,
  }) => axios.post(
    '/users/search/organization.json',
    params,
  ).then((response) => response.data),
);


export const addInstitutionMentee = createAsyncThunk(
  'ADD_SINGLE_MENTEE',
  async (params: { data: AddInstitutionMenteeProps, institutionId: number }) => axios.post<Result<MenteeAddResponse>>(
    `/institutions/${params.institutionId}/institution_mentorings`,
    params.data,
  ).then((response) => response.data).catch((error) => {
    throw (error.response?.data?.error);
  }),
);

export const deleteInstitutionMentee = createAsyncThunk(
  'DELETE_INSTITUTION_MENTEE',
  (params: { institutionId: number, institutionMentoringId: number, institutionMentorId: number }) => axios.delete(
    `/institutions/${params.institutionId}/institution_mentorings/${params.institutionMentoringId}`,
  ).then((response) => response.data),
);

export const getMenteesForMentor = createAsyncThunk(
  'GET_MENTEES_FOR_MENTOR',
  async (params: GetMenteesForMentorParams) => {
    const { institutionId, mentorUserId, pageNumber, filter, pageSize, ...queryParams } = params;
    const paramString = makeQueryParamString({ filter, pageSize, pageNumber });
    const response = await axios.get(
      `/institutions/${institutionId}/institution_mentorings/mentees/${mentorUserId}?${paramString}`, { params: queryParams },
    );
    return response.data;
  },
);

export const getMentorDashboardSummary = createAsyncThunk(
  'GET_MENTOR_DASHBOARD_SUMMARY',
  (params: { institutionId: number, mentorUserId: number }) => axios.get<Result<FilterCounts>>(
    `/institutions/${params.institutionId}/institution_mentorings/summary/${params.mentorUserId}`,
  ).then((response) => response.data),
);

export const addMultipleMenteesToMentor = createAsyncThunk(
  'ADD_MULTIPLE_MENTEE_AND_MENTOR',
  async (params: { data: AddMultipleMenteesToMentorParams, institutionId: number }) => axios.post<Result<MenteeAddResponse>>(
    `/institutions/${params.institutionId}/institution_mentorings`,
    params.data,
  ).then((response) => response.data).catch((error) => {
    throw (error.response?.data?.error);
  }),
);
