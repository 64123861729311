import { css } from '@emotion/react';
import { black, gray1, gray2, hexToRgbaString } from 'styles/global_defaults/colors';
import { quarterSpacing } from 'styles/global_defaults/scaffolding';

const AICommonStyles = css`
  .text-ai-color {
    background-image: linear-gradient(48.41deg, #60f3ab, #00ccbc, #009ac0);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .option {
    border: double 2px transparent;
    border-radius: ${quarterSpacing}px;

    .title-text {
      color: ${gray1};
    }

    .description-text {
      color: ${gray2};
    }

    &.selected {
      background-color: ${hexToRgbaString('#00CCBC', 0.05)};
      border-image-source: linear-gradient(48.41deg, #60F3AB 0%, #00CCBC 45%, #009AC0 100%);
      border-image-slice: 2;

      background-image: linear-gradient(#f2fcfc, #f2fcfc),
        linear-gradient(48.41deg, #60f3ab, #00ccbc, #009ac0);
      background-clip: padding-box, border-box;

      .title-text, .description-text {
        color: ${black};
      }
    }

    &:hover {
      background-color: ${hexToRgbaString('#00CCBC', 0.05)};

      .title-text, .description-text {
        color: ${black};
      }
    }
  }
`;

export default AICommonStyles;
