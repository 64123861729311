import { css } from '@emotion/react';
import { NvModal, ModalType } from 'shared/components/nv-modal';
import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import NvResponsiveTableOptionsCell from 'shared/components/nv-responsive-table-options-cell';
import t from 'react-translate';
import { NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { Team } from 'redux/schemas/models/team';
import { addTeamMembers, deleteTeam, updateProgressDashboardForTeam } from 'redux/actions/teams';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { Button } from 'react-bootstrap';
import { NvTeamAvatar } from 'components/nv-team-avatar';
import { useSelector } from 'react-redux';
import { getCourseAliases } from 'redux/selectors/course';
import { AngularContext } from 'react-app';
import { standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import prodPathReplace from '../../shared/prod-path-rewrite';
import { TeamCellProps } from './team-row';
import PostMessageModal from './post-message-modal';
// Config
import { config } from '../../../config/pendo.config.json';

type TeamRowOptionsProps = {
  cellProps: TeamCellProps,
  dataQa?: string,
  dataQaId?: string,
};

const actions = {
  addTeamMembers,
  deleteTeam,
  openConfirmationDialog,
  updateProgressDashboardForTeam,
  addAlertMessage,
};

/**
 * NovoEd-customized wrapper around a react-bootstrap Dropdown component.
 */
const TeamRowOptions = (props: TeamRowOptionsProps & typeof actions) => {
  const [showPostMessage, setShowPostMessage] = useState(false);
  const [showProgressDashboard, setShowProgressDashboard] = useState(false);
  const { injectServices } = useContext(AngularContext);
  const [$state, CurrentPermissionsManager] = injectServices(['$state', 'CurrentPermissionsManager']);
  const aliases = useSelector((state) => getCourseAliases(state));

  const optionItems: NvDropdownOption[] = [];

  const isInstructor = CurrentPermissionsManager.isInstructor();
  const isTeachingAssistant = CurrentPermissionsManager.isTeachingAssistant();
  if (isInstructor) {
    optionItems.push(
      { type: 'text',
        text: t.TEAM_FACILITATION.OPTIONS.MANAGE_TEAM({ isTeam: props.cellProps.extraProps.isTeam, ...aliases.teamAliases, ...aliases.groupAliases }),
        callback: () => {
          $state.go('manage-team-group-modal', {
            teamId: props.cellProps.team.id,
            catalogId: props.cellProps.extraProps.course.catalogId,
          });
        },
        dataQa: config.pendo.manageOneTeamGroup.options.manageTeamGroup,
      },
    );
  }
  if (isInstructor || isTeachingAssistant) {
    optionItems.push({
      type: 'text',
      text: t.TEAM_FACILITATION.OPTIONS.POST_MESSAGE_TO_WORKSPACE(props.cellProps.extraProps.isTeam ? props.cellProps.extraProps.course.teamName.singularizedTitleized : props.cellProps.extraProps.course.groupName.singularizedTitleized),
      callback: () => setShowPostMessage(true),
      dataQa: config.pendo.manageOneTeamGroup.options.postMessageToWorkspace,
    });
  }
  if (isInstructor) {
    if ((props.cellProps.extraProps.isTeam && !props.cellProps.extraProps.course.courseLongTeamSet.hasProgressDashboard) || (!props.cellProps.extraProps.isTeam && !props.cellProps.extraProps.course.groupTeamSet.hasProgressDashboard)) {
      optionItems.push({
        type: 'text',
        text: props.cellProps.data.hasProgressDashboard
          ? t.TEAM_FACILITATION.PROGRESS_DASHBOARD.DISABLE(props.cellProps.extraProps.isTeam ? props.cellProps.extraProps.course.teamName.singularizedTitleized : props.cellProps.extraProps.course.groupName.singularizedTitleized)
          : t.TEAM_FACILITATION.PROGRESS_DASHBOARD.ENABLE(props.cellProps.extraProps.isTeam ? props.cellProps.extraProps.course.teamName.singularizedTitleized : props.cellProps.extraProps.course.groupName.singularizedTitleized),
        callback: () => { setShowProgressDashboard(true); },
        dataQa: config.pendo.manageOneTeamGroup.options.enableProgressDashboard,
      });
    }
  }
  if (isInstructor || isTeachingAssistant) {
    optionItems.push(
      { type: 'text',
        text: t.TEAM_FACILITATION.OPTIONS.VIEW_TEAM_WORKSPACE({ isTeam: props.cellProps.extraProps.isTeam, ...aliases.teamAliases, ...aliases.groupAliases }),
        callback: () => {
          $state.go('team-workspace', { catalogId: props.cellProps.extraProps.course.catalogId, teamId: props.cellProps.data.id });
        },
        dataQa: config.pendo.manageOneTeamGroup.options.viewWorkspace,
      },
      { type: 'text',
        text: t.TEAM_FACILITATION.OPTIONS.VISIT_TEAM_PROFILE({ isTeam: props.cellProps.extraProps.isTeam, ...aliases.teamAliases, ...aliases.groupAliases }),
        callback: () => {
          $state.go('team-profile-modal', { catalogId: props.cellProps.extraProps.course.catalogId, teamId: props.cellProps.data.id });
        },
        dataQa: config.pendo.manageOneTeamGroup.options.viewProfile,
      },
      { type: 'text',
        text: t.TEAM_FACILITATION.OPTIONS.CONTACT_TEAM({ isTeam: props.cellProps.extraProps.isTeam, ...aliases.teamAliases, ...aliases.groupAliases }),
        callback: () => {
          $state.go('team-profile-modal', { catalogId: props.cellProps.extraProps.course.catalogId, teamId: props.cellProps.data.id, contact: true });
        },
        dataQa: config.pendo.manageOneTeamGroup.options.contactTeamGroup,
      },
    );
  }
  if (isInstructor) {
    optionItems.push(
      { type: 'divider' },
      {
        type: 'text',
        text: t.TEAM_FACILITATION.OPTIONS.DELETE(props.cellProps.extraProps.isTeam ? props.cellProps.extraProps.course.teamName.singularizedTitleized : props.cellProps.extraProps.course.groupName.singularizedTitleized),
        class: 'text-danger',
        callback: () => performDeleteTeam(),
        dataQa: config.pendo.manageOneTeamGroup.options.deleteTeamGroup,
      },
    );
  }

  const performDeleteTeam = () => {
    props.openConfirmationDialog({
      title: t.TEAMS.PROFILE.ADMIN_OPTIONS.DELETE_TEAM_CONFIRMATION(props.cellProps.extraProps.isTeam ? props.cellProps.extraProps.course.teamName.downcasedSingularized : props.cellProps.extraProps.course.groupName.downcasedSingularized),
      confirmText: t.FORM.YES_SURE(),
      onConfirm: () => props.deleteTeam({ teamId: props.cellProps.data.id, catalogId: props.cellProps.extraProps.course.catalogId, isTeam: props.cellProps.extraProps.isTeam }),
    });
  };

  const ProgressDashboardModal = () => {
    const styles = css`
      .position-absolute {
        bottom: 0;
      }

      img {
        max-width: 60%;
      }

      button {
        margin: ${tripleSpacing}px auto ${standardSpacing}px;
      }
    `;

    return (
      <div css={styles} className='position-relative h-100'>
        <NvTeamAvatar team={props.cellProps.data} course={props.cellProps.extraProps.course} institution={props.cellProps.extraProps.institution} size='xl' center />
        {props.cellProps.data.hasProgressDashboard ? <div className='font-weight-bold pb-2'>{t.TEAM_FACILITATION.PROGRESS_DASHBOARD.SINGLE_TEAM_DASBOARD_ENABLED({ isTeam: props.cellProps.extraProps.isTeam, ...aliases.teamAliases, ...aliases.groupAliases })}</div> : null}
        <div>{t.TEAM_FACILITATION.PROGRESS_DASHBOARD.SINGLE_TEAM_DESCRIPTION({ isTeam: props.cellProps.extraProps.isTeam, ...aliases.teamAliases, ...aliases.assignmentAliases, ...aliases.courseAliases, ...aliases.groupAliases })}</div>
        <div className='position-absolute w-100'>
          <img className='d-block m-auto' src={prodPathReplace('images/team-dashboard-example.png')} alt='' />
          <Button
            className='d-block'
            onClick={() => {
              props.updateProgressDashboardForTeam({ catalogId: props.cellProps.extraProps.course.catalogId, teamId: props.cellProps.data.id, hasProgressDashboard: props.cellProps.data.hasProgressDashboard });
              setShowProgressDashboard(false);
              props.addAlertMessage({
                type: AlertMessageType.SUCCESS,
                header: t.FORM.SUCCESS_BANG(),
                message: props.cellProps.data.hasProgressDashboard
                  ? t.TEAM_FACILITATION.PROGRESS_DASHBOARD.PROGRESS_DASHBOARD_HAS_BEEN_DISABLED_SINGLE_TEAM({ ...aliases.teamAliases, ...aliases.groupAliases, teamName: props.cellProps.data.name, isTeam: props.cellProps.extraProps.isTeam })
                  : t.TEAM_FACILITATION.PROGRESS_DASHBOARD.PROGRESS_DASHBOARD_HAS_BEEN_ENABLED_SINGLE_TEAM({ ...aliases.teamAliases, ...aliases.groupAliases, teamName: props.cellProps.data.name, isTeam: props.cellProps.extraProps.isTeam }),
              });
            }}
          >
            {
              props.cellProps.data.hasProgressDashboard
                ? t.TEAM_FACILITATION.PROGRESS_DASHBOARD.DISABLE_FOR_SINGLE_TEAM({ ...aliases.teamAliases, ...aliases.groupAliases, isTeam: props.cellProps.extraProps.isTeam })
                : t.TEAM_FACILITATION.PROGRESS_DASHBOARD.ENABLE_FOR_SINGLE_TEAM({ ...aliases.teamAliases, ...aliases.groupAliases, isTeam: props.cellProps.extraProps.isTeam })
            }
          </Button>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <NvModal
        type={ModalType.FIXED}
        header={t.TEAM_FACILITATION.OPTIONS.POST_MESSAGE_TO_WORKSPACE(props.cellProps.extraProps.isTeam ? props.cellProps.extraProps.course.teamName.singularizedTitleized : props.cellProps.extraProps.course.groupName.singularizedTitleized)}
        body={
          (
            <PostMessageModal
              isTeam={props.cellProps.extraProps.isTeam}
              onClose={() => setShowPostMessage(false)}
              team={props.cellProps.data}
              course={props.cellProps.extraProps.course}
              institution={props.cellProps.extraProps.institution}
            />
          )
        }
        show={showPostMessage}
        onClose={() => setShowPostMessage(false)}
        height='unset'
        width={960}
      />
      <NvModal
        type={ModalType.FIXED}
        header={t.TEAM_FACILITATION.PROGRESS_DASHBOARD.TITLE({ ...aliases.teamAliases, ...aliases.groupAliases, isTeam: props.cellProps.extraProps.isTeam })}
        body={ProgressDashboardModal()}
        show={showProgressDashboard}
        onClose={() => setShowProgressDashboard(false)}
        height={775}
      />
      <NvResponsiveTableOptionsCell<TeamCellProps, Team>
        optionItems={optionItems}
        optionsCellRef={props.cellProps.optionsCellRef}
        {...props.cellProps}
        dataQa={props.dataQa}
        dataQaId={props.dataQaId}
      />
    </React.Fragment>
  );
};

export default connect<TeamRowOptionsProps, typeof actions>(null, actions as any)(TeamRowOptions);
