export default {
  replace: true,
  bindings: {
    isOpen: '=',
    content: '<',
    contentType: '<',
    updateCopyStatus: '<',
    categories: '<',
  },
  controller: function ctrl(
    _,
    $uibModal,
  ) {
'ngInject';
    const vm = this;
    vm.categoryOpen = {};

    [vm.futureOfferings, vm.activeOfferings, vm.demoOfferings] = vm.categories;

    vm.selectCategory = function (title) {
      _.each(vm.categoryOpen, (cat) => {
        if (vm.category === title) {
          vm.categoryOpen[cat] = true;
        } else {
          vm.categoryOpen[cat] = false;
        }
      });
    };

    vm.getCourseCloseDate = function (course) {
      return course.closeDate;
    };

    vm.closeDropdown = function () {
      vm.isOpen = false;
    };

    vm.onToggle = function (open) {
      vm.isOpen = true;
      if (!open) {
        vm.closeDropdown();
      }
    };

    vm.openModal = function (course) {
      vm.closeDropdown();
      const { content, contentType, updateCopyStatus } = vm;
      vm.uibModalInstance = $uibModal.open({
        backdrop: 'static',
        templateUrl: 'timelines/templates/copy-to-offering-modal.html',
        controller: 'CopyToOfferingModalCtrl as vm',
        windowClass: 'copy-to-modal',
        resolve: {
          course,
          content,
          type: () => contentType,
          updateCopyStatus: () => updateCopyStatus.bind(vm),
        },
      });
    };

    // Determine if a particular category is open by default
    if (vm.futureOfferings.length) {
      vm.categoryOpen['TIMELINE.FUTURE_OFFERINGS'] = true;
    } else if (vm.activeOfferings.length) {
      vm.categoryOpen['TIMELINE.OPEN_OFFERINGS'] = true;
    } else {
      vm.categoryOpen['TIMELINE.DEMO_OFFERINGS'] = true;
    }
  },
  controllerAs: 'vm',
  templateUrl: 'timelines/templates/nv-copy-to-offering-dropdown.html',
};
