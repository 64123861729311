import React from 'react';
import { css } from '@emotion/react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import t from 'react-translate';
import { getBrand, getDiscoveryEnabled } from 'redux/selectors/institutions';
import NvIcon from 'shared/components/nv-icon';
import { primary, white } from 'styles/global_defaults/colors';
import { screenSmMin } from 'styles/global_defaults/media-queries';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { AngularContext } from 'react-app';
import useDashboardSummary from 'dashboard/hooks/use-dashboard-summary';
import { config } from '../../../config/pendo.config.json';

export const LearningCatalogButton = () => {
  const { injectServices } = React.useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const { brandBarFontColor } = useSelector(getBrand);
  const discoveryEnabled = useSelector(getDiscoveryEnabled);
  const isMobile = useMediaQuery({
    query: `(max-width: ${screenSmMin}px)`,
  });

  // Know if there is content to be discover
  const {
    loadSummary,
    currentSummary: {
      discovery: {
        totalCount: discoveryCount,
      },
    },
  } = useDashboardSummary();

  React.useEffect(() => {
    loadSummary();
  }, []);

  const styles = css`
    position: absolute;
    top: 0;
    right: ${standardSpacing}px;
    margin-top: ${halfSpacing}px;
    background: ${white} !important;
    box-shadow: none;
    display: flex;
    align-items: center;
    gap: ${halfSpacing}px;

    &:active {
      color: ${primary} !important;
    }

    ${isMobile && `
      padding-right: 0;
      color: ${brandBarFontColor} !important;
      background: none !important;
      border: none !important;
      box-shadow: none !important;
    `}
  `;

  const goToLearningCatalog = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    $state.go('learning-catalog-modal');
    e.currentTarget.blur();
  };

  if (!discoveryEnabled || !discoveryCount) {
    return null;
  }

  return (
    <Button
      onClick={goToLearningCatalog}
      css={styles}
      variant='secondary'
      aria-label={t.LEARNING_CATALOG.BROWSE_CATALOG()}
      data-qa={config.pendo.learningCatalog.browseCatalogHeader}
    >
      <NvIcon icon='learning-catalog' size='small' />
      {!isMobile && t.LEARNING_CATALOG.BROWSE_CATALOG()}
    </Button>
  );
};

export default LearningCatalogButton;
