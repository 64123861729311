import { setSavingOverlayText } from 'redux/actions/saving-overlay';
import store from 'redux/store';
import { NovoAIItemType } from 'redux/schemas/models/lecture-page';
import { resetLeftPanelNovoAI, setLeftPanelView } from 'redux/actions/lecture-pages';
import t from '../../react-translate';
import { useAngularPreventLecturePageNavigation } from '../hooks/use-prevent-lecture-page-navigation';

/* @ngInject */
export default function DiscussionFormModalController(
  $uibModalInstance,
  $window,
  $stateParams,
  $scope,
  $timeout,
  _,
  config,
  ConfirmationOverlays,
  CurrentUserManager,
  CurrentCourseManager,
  DiscussionsManager,
  StateManager,
  TeamDiscussionLectureComponentModel,
  DiscussionLectureComponentModel,
  vmResolves,
) {
  const SELECT_COURSE_OR_TEAM = 'SELECT_COURSE_OR_TEAM';
  const SELECT_COURSE_DISCUSSION = 'SELECT_COURSE_DISCUSSION';
  const SELECT_TEAM_DISCUSSION = 'SELECT_TEAM_DISCUSSION';
  const SELECT_TEAM_FORMATION = 'SELECT_TEAM_FORMATION';

  const vm = this;
  vm.config = config;
  vm.currentState = SELECT_COURSE_OR_TEAM;
  vm.isContentManagementCollection = CurrentCourseManager.course.isContentManagementCollection;
  vm.selectedState = vm.isContentManagementCollection ? SELECT_COURSE_DISCUSSION : SELECT_COURSE_OR_TEAM;
  vm.courseDiscussionPendoTagName = vm.isContentManagementCollection
    ? config.pendo.lectureEdit.lessonLibrary.courseDiscussion
    : config.pendo.lectureEdit.courseDiscussion;

  _.extend(vm, vmResolves);
  let dismissedByUser = false;

  vm.CurrentUserManager = CurrentUserManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.isLinked = vm.lectureComponent?.lecturePage?.isLinked;

  vm.getTopicsAndMembers = () => {
    DiscussionsManager.initialize({ catalogId: $stateParams.catalogId });

    // Collection discussion does not use the topic dropdown, so there's no need to retrieve the list
    if (!vm.isContentManagementCollection) {
      DiscussionsManager.getAllTopics(null, { nameList: true })
        .then(() => {
          if (vm.postDraft.topicId) {
            if (vm.postDraft.topic && vm.postDraft.topic?.id !== vm.postDraft.topicId) {
              vm.postDraft.topicId = vm.postDraft.topic.id;
              vm.postDraft.forumId = vm.postDraft.topic.id;
            }
            vm.postDraft.topic = DiscussionsManager.findTopic(vm.postDraft.topicId);
          }
        });
    }

    CurrentCourseManager.course.getTeachingTeamMembers()
      .then(() => {
        if (CurrentCourseManager.course.teachingTeamMembers) {
          if (!vm.postDraft.starterId) {
            vm.postDraft.starterId = CurrentUserManager.user.id;
            vm.postDraft.user = CurrentUserManager.user;
          }

          vm.discussionStarterOptions = _.pluck(_.clone(CurrentCourseManager.course.teachingTeamMembers), 'user');

          // if the original starter isn't already in the list, add
          if (!_.findWhere(vm.discussionStarterOptions, { id: vm.postDraft.user.id })) {
            vm.discussionStarterOptions.unshift(vm.postDraft.user);
          }

          // if the current user isn't in the list, add
          if (!_.findWhere(vm.discussionStarterOptions, { id: CurrentUserManager.user.id })) {
            vm.discussionStarterOptions.unshift(vm.CurrentUserManager.user);
          }
        }
      });
  };

  vm.selectTeamDiscussion = () => {
    vm.selectedState = SELECT_TEAM_DISCUSSION;
  };

  vm.selectCourseDiscussion = () => {
    vm.selectedState = SELECT_COURSE_DISCUSSION;
  };

  vm.switchToTeamDiscussionForm = () => {
    vm.currentState = SELECT_TEAM_DISCUSSION;
  };

  vm.selectTopic = (topic) => {
    if (topic) {
      vm.postDraft.topic = topic;
    } else {
      vm.postDraft.topic = {
        isNewTopic: true,
      };
    }

    vm.discussionForm.$setValidity('selectedTopic', true);
    vm.discussionForm.$setDirty();
  };

  vm.selectTeamFormation = (formedByStudents) => {
    vm.postDraft.formedByStudents = formedByStudents;
    vm.editTeamFormationTypeForm.$setDirty();
  };

  vm.changeDiscussionStarter = function (newDiscussionStarterUser) {
    vm.postDraft.starterId = newDiscussionStarterUser.id;
    vm.discussionForm.$setDirty();
  };

  vm.findDiscussionStarterById = function (userId) {
    return _.findWhere(vm.discussionStarterOptions, { id: userId });
  };

  vm.filterTopicOptions = function () {
    return _.filter(DiscussionsManager.allTopics, (topic) => topic.id > 0);
  };

  vm.chooseDiscussion = () => {
    vm.currentState = vm.selectedState;

    let Model;
    if (vm.currentState === SELECT_TEAM_DISCUSSION) {
      Model = TeamDiscussionLectureComponentModel;
      $timeout(() => {
        vm.discussionForm.$setValidity('selectedTopic', true);
      });
    } else {
      Model = DiscussionLectureComponentModel;
      $timeout(() => {
        /**
         * SelectedTopic is not using in collection lesson. So setting the value
         * based on the iscollection field
         */
        vm.discussionForm.$setValidity('selectedTopic', vm.isContentManagementCollection);
      });
    }

    if (!CurrentCourseManager.course.hasCourseLongTeamSet && vm.currentState === SELECT_TEAM_DISCUSSION) {
      vm.currentState = SELECT_TEAM_FORMATION;
    }

    const component = new Model({}, false, true);
    vm.lectureComponent.setDiscussion(component);
    vm.postDraft = vm.lectureComponent.realComponent.postDraft;
    vm.getTopicsAndMembers();
  };

  vm.save = () => {
    dismissedByUser = true;

    if (
      vm.lectureComponent?.realComponent?.type === 'TeamDiscussionLectureComponent'
      && vm.workflowCtx.mode === 'new'
      && !CurrentCourseManager.course.hasCourseLongTeamSet
    ) {
      store.dispatch(setSavingOverlayText(
        t.LECTURE_PAGES.COMPONENTS.TEAM_DISCUSSION.TEAM_FORMATION_AND_TEAM_DISCUSSION_ADDING(CurrentCourseManager.course.getAliases()),
      ));
    }

    if (!vm.postDraft.highlightedNow) {
      vm.postDraft.highlightedFrom = null;
      vm.postDraft.highlightedTo = null;
    }

    // If we're updating the Discussion through the Regeneration, we should:
    // 1. Reset the NovoAI data
    // 2. Reset the panel view
    // 3. Reset the flag that indicates the regeneration status
    if (vm.lectureComponent.isRegenerating) {
      store.dispatch(resetLeftPanelNovoAI());
      store.dispatch(setLeftPanelView('outline'));
      vm.lectureComponent.isRegenerating = false;
    }

    $uibModalInstance.close();
  };

  vm.cancel = () => {
    if (vm.editMode || vm.lectureComponent.aiOrigin === NovoAIItemType.DISCUSSION) {
      $uibModalInstance.dismiss();
    } else if (vm.currentState === SELECT_TEAM_DISCUSSION && !CurrentCourseManager.course.hasCourseLongTeamSet) {
      vm.lectureComponent.createDraft();
      vm.currentState = SELECT_TEAM_FORMATION;
    } else {
      vm.lectureComponent.createDraft();
      vm.currentState = SELECT_COURSE_OR_TEAM;
    }
  };

  const hasUnsavedChanges = () => (
    vm.discussionForm && (vm.discussionForm.$dirty
        || (vm.postDraft?.topic && vm.postDraft.topic.id !== vm.postDraft.forumId)
        || (vm.postDraft && vm.postDraft.user?.id !== vm.postDraft.starterId))
  );

  const checkUnsavedChanges = () => hasUnsavedChanges();

  useAngularPreventLecturePageNavigation($scope, checkUnsavedChanges);

  // warn if leaving the modal and have unsaved changes
  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(checkUnsavedChanges,
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    'FORM.NAVIGATE_AWAY.CLOSE_WINDOW',
    undefined,
    undefined,
    () => {
      vm.deregisterModalClosing?.();

      $uibModalInstance.dismiss();
    });

  vm.deregisterModalClosing = $scope.$on('modal.closing', ($event) => {
    if (hasUnsavedChanges() && !dismissedByUser) {
      $event.preventDefault();

      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        dismissedByUser = true;
        $uibModalInstance.dismiss();
        if (vm.lectureComponent?.createDraft) {
          vm.lectureComponent.createDraft();
        }
      });
    }
  });

  // Update the modal title to match the current page view
  $scope.$watch('vm.currentState', () => {
    let title = null;

    switch (vm.currentState) {
      case SELECT_COURSE_OR_TEAM:
        if (!vm.lectureComponent.id) {
          title = t.LECTURE_PAGES.COMPONENTS.DISCUSSION.ADD_DISCUSSION;
        } else {
          title = t.LECTURE_PAGES.COMPONENTS.DISCUSSION.EDIT_DISCUSSION;
        }
        break;
      case SELECT_COURSE_DISCUSSION:
        if (!vm.lectureComponent.id) {
          title = t.LECTURE_PAGES.COMPONENTS.COURSE_DISCUSSION.MODAL_TITLE.CREATE;
        } else {
          title = t.LECTURE_PAGES.COMPONENTS.COURSE_DISCUSSION.MODAL_TITLE.EDIT;
        }
        break;
      case SELECT_TEAM_DISCUSSION:
        if (!vm.lectureComponent.id) {
          title = t.LECTURE_PAGES.COMPONENTS.TEAM_DISCUSSION.MODAL_TITLE.CREATE;
        } else {
          title = t.LECTURE_PAGES.COMPONENTS.TEAM_DISCUSSION.MODAL_TITLE.EDIT;
        }
        break;
      case SELECT_TEAM_FORMATION:
        title = t.LECTURE_PAGES.COMPONENTS.EXERCISE.MODAL.TEAM_TYPE.TITLE.ADD_TEAM_FORMATION;
        break;
      default:
        return;
    }

    if (vm.workflowCtx) {
      vm.workflowCtx.setSettings({
        ...vm.workflowCtx.settings,
        title: (mode, courseAliases) => title(courseAliases),
      });
    }
  });

  $scope.$on('$destroy', () => {
    angular.element($window).off('beforeunload');
    vm.deregisterStateChangeStart?.();
  });

  // Entry point when editing an existing discussion or crating an AI-generated discussion
  if (vm.lectureComponent.type !== 'AbstractDiscussionLectureComponent') {
    vm.postDraft = vm.lectureComponent.postDraft;
    vm.getTopicsAndMembers();

    // Updating the current state whether the discussion was marked as a team discussion or course-wide discussion
    if (vm.lectureComponent.type === 'TeamDiscussionLectureComponent') {
      vm.currentState = SELECT_TEAM_DISCUSSION;
    } else {
      vm.currentState = SELECT_COURSE_DISCUSSION;
    }

    // Setting the required params when editing an existing discussion
    if (vm.lectureComponent.aiOrigin !== NovoAIItemType.DISCUSSION || vm.lectureComponent.isRegenerating) {
      vm.editMode = true;
      vm.postDraft.starterId = vm.postDraft.user.id;
    }

    if (vm.lectureComponent.isRegenerating) {
      $timeout(() => {
        vm.discussionForm.$setDirty();
      });
    }
  }
}
