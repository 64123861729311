import ClickableContainer from 'components/clickable-container';
import React, { useEffect, useRef, useState } from 'react';
import t from 'react-translate';
import uuid from 'react-uuid';
import * as yup from 'yup';
import NvIcon from 'shared/components/nv-icon';
import { css } from '@emotion/react';
import { threeQuartersSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import NvPillWithInput from 'shared/components/nv-pill-with-input';
import validationConstants from 'shared/services/constants-shared';
import { gray4 } from 'styles/global_defaults/colors';
import NvTooltip from 'shared/components/nv-tooltip';
import { config } from '../../../../config/pendo.config.json';

const styles = css`
  .key-phrase {
    border-radius: ${threeQuartersSpacing}px;
    padding: 3px ${halfSpacing}px;
    width: fit-content;
    .icon-close {
      font-size: ${halfSpacing}px !important;
    }
  }
  .new-btn {
    padding: 3px ${halfSpacing}px;
    border-radius: ${threeQuartersSpacing}px;
  }
  .disabled {
    color: ${gray4};
    &:hover:not {
      pointer-events: none;
    }
  }
`;

const MIN_CHARACTER_LENGTH = 2;
const MAX_CHARACTER_LENGTH = 255;
const PHRASE_LIMIT = 25;

type InsightKeyPhraseProps = {
  onChange?: (phrases: string[]) => void
  isDisabled?: boolean
  keyPhrases?: string[]
  onError?: (error: string) => void
  dataQa?: string
};

const InsightsKeyPhrases = (
  {
    onChange,
    isDisabled,
    keyPhrases: initialKeyPhrases,
    onError,
    dataQa,
  }: InsightKeyPhraseProps,
) => {
  const [editValue, setEditValue] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [keyPhrases, setKeyPhrases] = useState<string[]>(initialKeyPhrases);

  const validationSchema = yup.string()
    .min(MIN_CHARACTER_LENGTH, t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.INSIGHTS.PHRASE_VALIDATION.LENGTH(
      MAX_CHARACTER_LENGTH.toString(),
      MIN_CHARACTER_LENGTH.toString(),
    ))
    .max(MAX_CHARACTER_LENGTH, t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.INSIGHTS.PHRASE_VALIDATION.LENGTH(
      MAX_CHARACTER_LENGTH.toString(),
      MIN_CHARACTER_LENGTH.toString(),
    ))
    .matches(validationConstants.KEY_PHRASE_REGEX, {
      message: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.INSIGHTS.PHRASE_VALIDATION.FORBIDDEN_CHARACTERS(),
    });

  const newButtonRef = useRef<HTMLDivElement>();

  const changeHandler = (val) => setEditValue(val);
  const onEditCancel = () => {
    setIsEditing(false);
    setEditValue('');
    onError?.('');
  };

  const onAddPhrase = () => {
    setEditValue('');
    setIsEditing(false);
    setKeyPhrases(phrases => [...phrases, editValue.trim()]);
    newButtonRef.current?.focus();
  };

  const onDeletePhrase = (deletedItem: string) => {
    setKeyPhrases(phrases => phrases.filter(phrase => deletedItem !== phrase));
  };

  useEffect(() => {
    if (!isEditing && newButtonRef.current) {
      newButtonRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    onChange?.(keyPhrases);
  }, [keyPhrases, onChange]);

  return (
    <div className='d-flex flex-wrap' css={styles}>
      {keyPhrases.map((phrase, index) => (
        <NvPillWithInput
          key={uuid()}
          value={phrase}
          onRemove={() => onDeletePhrase(phrase)}
          className='key-phrase border-gray-5 bg-gray-7 mr-2 mb-2 text-small font-weight-bold'
          isAdded
          dataQaRemove={`automated-feedback-keyphrase-remove-button-${index}-${dataQa}`}
          pendoTagRemove={config.pendo.practice.deleteKeyphrase}
        />
      ))}
      {!isEditing && (
        <React.Fragment>
          {!keyPhrases?.length && (
            <NvTooltip
              placement='top'
              text={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.INSIGHTS.PHRASE_VALIDATION.MAXIMUM_REACHED(PHRASE_LIMIT.toString())}
              enabled={isDisabled}
            >
              <ClickableContainer
                className={`key-phrase d-flex align-items-center bg-gray-6 label ${isDisabled ? 'disabled' : 'text-primary'}`}
                onClick={() => setIsEditing(true)}
                disabled={isDisabled}
                data-qa={`automated-feedback-keyphrase-button-text-${dataQa}`}
                pendo-tag-name={config.pendo.practice.addKeyphrase}
              >
                <NvIcon
                  icon='create-new-post'
                  size='xss-smallest'
                  className='mr-2'
                />
                <div>
                  {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.INSIGHTS.ADD_KEY_PHRASE()}
                </div>
              </ClickableContainer>
            </NvTooltip>
          )}
          {keyPhrases?.length > 0 && (
            <NvTooltip
              placement='top'
              text={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.INSIGHTS.PHRASE_VALIDATION.MAXIMUM_REACHED(PHRASE_LIMIT.toString())}
              enabled={isDisabled}
            >
              <ClickableContainer
                onClick={() => setIsEditing(true)}
                className={`new-btn d-flex align-items-center bg-gray-6 mb-2 ${isDisabled ? 'disabled' : 'text-primary'}`}
                ref={newButtonRef}
                disabled={isDisabled}
                data-qa={`automated-feedback-keyphrase-button-${dataQa}`}
                pendo-tag-name={config.pendo.practice.addKeyphrase}
              >
                <NvIcon
                  icon='create-new-post'
                  size='xss-smallest'
                />
              </ClickableContainer>
            </NvTooltip>
          )}
        </React.Fragment>
      )}
      {isEditing && (
        <NvPillWithInput
          value={editValue}
          onRemove={onEditCancel}
          onChange={changeHandler}
          disabled={false}
          onAdd={() => onAddPhrase()}
          className='key-phrase border-primary bg-gray-6 mb-2 text-small font-weight-bold gray-2'
          isAdded={false}
          validationSchema={validationSchema}
          errorTitle={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.INSIGHTS.PHRASE_VALIDATION.ERROR()}
          maxLength={MAX_CHARACTER_LENGTH}
          onError={onError}
          dataQaRemove={`automated-feedback-keyphrase-input-remove-button-${dataQa}`}
          dataQaInput={`automated-feedback-keyphrase-input-${dataQa}`}
        />
      )}
    </div>
  );
};

export default InsightsKeyPhrases;
