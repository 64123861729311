import { createReducer } from '@reduxjs/toolkit';
import { mergeWith } from 'lodash';
import { normalize } from 'normalizr';
import {
  addNewVote as pollReceiveVote,
  createPollOption,
  deletePollOption,
  pollVote as pollEmitVote,
  reorderPollOptions,
  updatePoll,
  updatePollOption,
  updatePollQuestion,
} from 'redux/actions/polls';
import { PollOptionSchema, PollVieweeSubmissionSchema } from 'redux/schemas/api/lecture-components';
import { ActivityProgress } from 'redux/schemas/models/activity';
import { replaceArrays } from 'shared/lodash-utils';
import { initialRootState } from '.';

export default createReducer(initialRootState, (builder) => {
  builder.addCase(createPollOption.fulfilled, (state, action) => {
    const { questionId } = action.meta.arg;
    const option = action.payload;

    const data = normalize(option, PollOptionSchema);

    Object.assign(state.models.pollOptions, data.entities.pollOptions);
    state.models.pollQuestions[questionId].responseOptionIds.push(option.id);
  });

  builder.addCase(updatePollOption.pending, (state, action) => {
    const { responseOptionId, optionContent } = action.meta.arg;
    state.models.pollOptions[responseOptionId].optionContent = optionContent;
  });

  builder.addCase(deletePollOption.pending, (state, action) => {
    const { questionId, responseOptionId } = action.meta.arg;

    delete state.models.pollOptions[responseOptionId];

    const optionIndexInQuestion = state.models.pollQuestions[questionId].responseOptionIds.findIndex(
      (value) => value === responseOptionId,
    );

    state.models.pollQuestions[questionId].responseOptionIds.splice(optionIndexInQuestion, 1);
  });

  builder.addCase(updatePoll.pending, (state, action) => {
    Object.assign(state.models.polls[action.meta.arg.poll.id], action.meta.arg.poll);
  });

  builder.addCase(updatePollQuestion.pending, (state, action) => {
    state.models.pollQuestions[action.meta.arg.questionId].questionText = action.meta.arg.questionText;
  });

  builder.addCase(reorderPollOptions.pending, (state, action) => {
    state.models.pollQuestions[action.meta.arg.questionId].responseOptionIds = action.meta.arg.order;
  });

  builder.addCase(pollEmitVote.pending, (state, action) => {
    // this will disable the vote button
    state.models.polls[action.meta.arg.pollId].progress = 'completed';
  });

  builder.addCase(pollEmitVote.fulfilled, (state, action) => {
    const data = normalize(action.payload, PollVieweeSubmissionSchema);

    mergeWith(state.models, data.entities, replaceArrays);

    // update responses to highlight selected option
    state.models.polls[action.meta.arg.pollId].vieweeSubmissionId = data.result;

    Object.keys(state.models.lecturePages).forEach(lpId => {
      if (state.models.lecturePages[lpId].prerequisite?.type === 'Poll'
        && state.models.lecturePages[lpId].prerequisite.id === action.meta.arg.pollId) {
        state.models.lecturePages[lpId].prerequisite = null;
      }
    });
  });

  builder.addCase(pollReceiveVote.fulfilled, (state, action) => {
    const option = state.models.pollOptions[action.meta.arg.selected_response_option];
    option.votesCount += 1;

    // if is not anonymous, save the user
    if (action.meta.arg.user_id) {
      state.models.users[action.payload.id] = action.payload as typeof state.models.users[number];
      option.voterIds = [...(option.voterIds || []), action.payload.id];
    }
  });
});
