import { SerializedStyles, css } from '@emotion/react';
import React from 'react';
import {
  black,
  genAIGradient,
  gray6,
} from 'styles/global_defaults/colors';
import {
  halfSpacing,
  largeSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import NvIcon from './nv-icon';
import NvTooltip from './nv-tooltip';

export type BadgeProps = {
  children: string;
  variant?: 'sm' | 'md';
  color?: string;
  borderColor?: string;
  genAI?: boolean;
  backgroundColor?: string;
  tooltipText?: string;
  iconName?: string;
  iconColor?: string;
  iconAI?: boolean;
  Icon?: React.ComponentType<React.SVGProps<SVGElement>>;
};

type Theme = {
  styles: SerializedStyles;
  classNames: string;
};

export const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  (props, ref) => {
    const {
      variant = 'md',
      tooltipText,
      Icon,
      children,
      iconName,
      iconColor,
      color = black,
      backgroundColor = gray6,
      borderColor = backgroundColor,
      genAI,
      iconAI,
      ...rest
    } = props;

    // Implementing styles based on the design system defined on _react_typography.scss
    const classNames = 'text-small font-weight-bold';

    // Defining the styles based on the given variants
    const genAIStyles = css`
      border: 1px solid transparent;
      background-image: linear-gradient(${backgroundColor}, ${backgroundColor}),
        ${genAIGradient};
      background-origin: border-box;
      background-clip: padding-box, border-box;
    `;
    const commonStyles = css`
      max-width: 100%;
      width: fit-content;
      border: 1px solid ${borderColor};
      color: ${color};
      background-color: ${backgroundColor};
      display: flex;
      align-items: center;
      cursor: default;

      svg,
      .icon {
        margin-right: ${quarterSpacing}px;
        ${iconAI ? css`
          background-image: ${genAIGradient};
          background-clip: text;
          -webkit-text-fill-color: transparent;
        ` : css`
          color: ${iconColor};
        `}
      }

      span {
        flex: 1;
        text-transform: capitalize;
      }

      ${genAI && genAIStyles};
    `;
    const smStyles = css`
      ${commonStyles};
      padding: ${halfSpacing / 5}px ${halfSpacing}px;
      border-radius: ${standardSpacing}px;
    `;
    const mdStyles = css`
      ${commonStyles};
      height: ${largeSpacing}px;
      padding: ${threeQuartersSpacing / 2}px ${halfSpacing}px;
      border-radius: ${largeSpacing / 2}px;
    `;

    // Helper function to determine the css styles and class names based on the given variants
    const getTheme = (): Theme => {
      switch (variant) {
        case 'sm':
          return {
            styles: smStyles,
            classNames,
          };
        case 'md':
          return {
            styles: mdStyles,
            classNames,
          };
        default:
          return {
            styles: commonStyles,
            classNames: '',
          };
      }
    };

    return (
      <NvTooltip
        text={tooltipText}
        enabled={!!tooltipText}
        placement='top'
      >
        <div
          ref={ref}
          css={getTheme().styles}
          className={getTheme().classNames}
          {...rest}
        >
          {iconName && <NvIcon icon={iconName} size='xss-smallest' />}
          {Icon && <Icon width={16} height={16} />}
          <span>{children}</span>
        </div>
      </NvTooltip>
    );
  },
);

export default Badge;
