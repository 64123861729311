import React from 'react';

type Props = {
  className?: string,
  style?: React.CSSProperties,
};

const EmptyOverlay = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    style,
    className,
  } = props;

  return (
    <div
      ref={ref}
      style={style}
      className={className}
    />
  );
});

export default EmptyOverlay;
