import { useAngularPreventLecturePageNavigation } from '../../lecture_pages/hooks/use-prevent-lecture-page-navigation';

/* @ngInject */
export default function NewPostModalController(
  DiscussionsManager,
  CurrentUserManager,
  $uibModalInstance,
  StateManager,
  $scope,
  post,
  _,
  MentionablesModel,
  CurrentCourseManager,
  ConfirmationOverlays,
  $window,
  $translate,
  PostModel,
  AlertMessages,
  config,
) {
  const vm = this;

  vm.config = config;
  vm.manager = DiscussionsManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.currentCourseManager = CurrentCourseManager;
  vm.mentionablesModel = MentionablesModel;
  vm.selectingDate = post && !post.released;
  vm.post = post;
  vm.updatingPost = !!post;
  vm.unsavedChangesMessage = 'FORM.NAVIGATE_AWAY.CLOSE_WINDOW';
  vm.confirmingClose = false;
  vm.submitting = false;
  vm.submittingSuccessful = false;
  vm.defaultDate = Date.now();

  vm.setInitialTopic = setInitialTopic;

  vm.newPost = {
    title: post ? post.title : '',
    body: post ? post.body : '',
    topic: setInitialTopic(),
    releaseDate: post ? post.releaseDate : vm.defaultDate,
    starterId: post ? post.user.id : vm.CurrentUserManager.user.id,
    id: post ? post.id : null,
    mentionedIds: [],
  };

  if (vm.currentCourseManager.course.teachingTeamMembers) {
    vm.discussionStarterOptions = _.pluck(_.clone(vm.currentCourseManager.course.teachingTeamMembers), 'user');

    // if the original starter isn't already in the list, add
    if (post && !_.findWhere(vm.discussionStarterOptions, { id: post.user.id })) {
      vm.discussionStarterOptions.unshift(post.user);
    }

    // if the current user isn't in the list, add
    if (!_.findWhere(vm.discussionStarterOptions, { id: CurrentUserManager.user.id })) {
      vm.discussionStarterOptions.unshift(vm.CurrentUserManager.user);
    }
  }

  vm.selectTopic = function (topic) {
    vm.newPost.topic = topic;

    vm.newPostForm.$setValidity('selectedTopic', vm.newPost.topic?.id !== 0);
  };


  vm.submitNewPost = function () {
    // save the mentioned ids first
    vm.newPost.mentionedIds = MentionablesModel.parseMentionables(vm.newPost.body);
    vm.submitting = true;
    const normalizedPostData = _.extend(
      vm.newPost, {
        updating: !!post,
        releaseDate: (vm.newPost.releaseDate === vm.defaultDate ? null : vm.newPost.releaseDate),
        catalogId: DiscussionsManager.catalogId,
      },
    );

    if (vm.newPost.updating) {
      PostModel.update(normalizedPostData).then((response) => {
        vm.submittingSuccessful = true;
        $uibModalInstance.close();
        DiscussionsManager.setSinglePostData(
          PostModel.normalizePostData(response.result, {
            catalogId: DiscussionsManager.catalogId,
            owner: DiscussionsManager.findTopic(response.result.forumId),
          }), true, post.topicId,
        );
      });
    } else {
      // new post
      PostModel.create(normalizedPostData).then((response) => {
        vm.submittingSuccessful = true;
        $uibModalInstance.close();
        DiscussionsManager.addNewPost(PostModel.normalizePostData(response.result, { owner: DiscussionsManager.findTopic(response.result.forumId) }), true);
      }).catch(
        (error) => {
          AlertMessages.error('', 'FORM.ERROR');
          vm.submitting = false;
        },
      );
    }
  };

  vm.adjustReleaseDateValue = function () {
    if (!vm.selectingDate || (vm.releaseDate === undefined || vm.releaseDate === '')) {
      vm.newPost.releaseDate = null;
    }
  };

  vm.changeDiscussionStarter = function (newDiscussionStarterUser) {
    vm.newPost.starterId = newDiscussionStarterUser.id;
  };

  vm.findDiscussionStarterById = function (userId) {
    return _.findWhere(vm.discussionStarterOptions, { id: userId });
  };

  vm.filterTopicOptions = function () {
    return _.filter(DiscussionsManager.allTopics, (topic) => topic.id > 0);
  };

  vm.hasUnsavedChanges = function () {
    return vm.newPostForm
      && (vm.newPostForm.$dirty
        || (vm.newPost.topic && vm.post && vm.newPost.topic.id !== vm.post.forumId)
        || (vm.post?.user.id !== vm.newPost.starterId))
      && !vm.submitting && !vm.submittingSuccessful;
  };

  vm.topicSelected = function () {
    return vm.newPost.topic?.id;
  };


  function setInitialTopic() {
    if (post) {
      return DiscussionsManager.findTopic(post.forumId);
    } if (DiscussionsManager.searchParams.selectedTopic.id > 0) {
      return DiscussionsManager.searchParams.selectedTopic;
    }
    return null;
  }

  const checkUnsavedChanges = () => vm.hasUnsavedChanges();

  useAngularPreventLecturePageNavigation($scope, checkUnsavedChanges);

  // warn if leaving the modal and have unsaved changes
  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(
    checkUnsavedChanges,
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    vm.unsavedChangesMessage,
  );

  $scope.$on('modal.closing', ($event) => {
    if (vm.hasUnsavedChanges() && !vm.confirmingClose && !vm.submitting) {
      $event.preventDefault();


      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        vm.confirmingClose = true;
        vm.submitting = false;
        $uibModalInstance.dismiss();
      });
    }
  });

  $scope.$on('$destroy', () => {
    vm.deregisterStateChangeStart?.();
  });
}
