import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/schemas';
import { ComponentType, LectureComponent, NLectureComponent } from 'redux/schemas/models/lecture-component';
import { PollQuestion } from 'redux/schemas/models/poll';
import { some } from 'underscore';
import { getCommunication } from './course-communications';

export const getPolls = (state: RootState) => state.models.polls;
export const getPoll = (state: RootState, { pollId }: { pollId: number }) => state.models.polls[pollId];

export const hasPollGotCommunicationError = (state: RootState, { pollId }: { pollId: number }) => state.models.polls[pollId]
  && state.models.polls[pollId].communications
  && some(
    state.models.polls[pollId].communications,
    (communicationId: number) => getCommunication(state, communicationId)?.hasErrors,
  );

type GetNormalizedPollLectureComponent = { lectureComponentId: string };
export const getNormalizedPollLectureComponent = (
  state: RootState,
  { lectureComponentId }: GetNormalizedPollLectureComponent,
) => state.models.lectureComponents[lectureComponentId] as NLectureComponent<ComponentType.POLL>;

export const getNormalizedPollOptions = (state: RootState) => state.models.pollOptions;
export const getNormalizedPollVieweeSubmission = (
  state: RootState,
  { vieweeSubmissionId }: { vieweeSubmissionId: number },
) => state.models.pollVieweeSubmission[vieweeSubmissionId];

type GetNormalizedPollQuestionProps = { pollQuestionId: number };
export const getNormalizedPollQuestion = (state: RootState, { pollQuestionId }: GetNormalizedPollQuestionProps) => state.models.pollQuestions[pollQuestionId];

export const getPollLectureComponent = createSelector(
  getNormalizedPollLectureComponent,
  getPolls,
  // TODO: I think this 'as unknown' is required b/c the PollPayload is defined with poll being a Poll, and not NormalizedPoll. Might be incorrect
  (pollLectureComponent, polls) => ({ ...pollLectureComponent, poll: polls[pollLectureComponent.poll] } as unknown as LectureComponent<ComponentType.POLL>),
);

export const getPollQuestion = createSelector(
  getNormalizedPollQuestion,
  getNormalizedPollOptions,
  (pollQuestion, pollOptions) => {
    const { responseOptionIds, ...rest } = pollQuestion;
    return { ...rest, responseOptions: responseOptionIds.map((optionId) => pollOptions[optionId]) } as PollQuestion;
  },
);
