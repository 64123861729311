
import { css } from '@emotion/react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useState, useContext, useRef, useEffect } from 'react';
import t from 'react-translate';
import NvUserAvatar from 'components/nv-user-avatar';
import { semiBoldFontWeight } from 'styles/global_defaults/fonts';
import { gray6, primary, hexToRgbaString, info } from 'styles/global_defaults/colors';
import { standardSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { handheld, notHandheld, tablet } from 'styles/global_defaults/media-queries';
import { useAppDispatch } from 'redux/store';
import { BaseUser } from 'redux/schemas/models/my-account';
import { AngularServicesContext } from 'react-app';
import { voteUpReply, voteDownReply, getReplyLikersInfo, translateReply } from 'redux/actions/replies';
import NvIcon from 'shared/components/nv-icon';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import DiscussionLikes from './discussion-likes';
import DiscussionReplies from './discussion-replies';
import DiscussionReplyActions from './discussion-reply-actions';
import DiscussionReplyForm from './discussion-reply-form';
import { config } from '../../../../config/pendo.config.json';
import TranslationButton from '../../../components/translation-button';
import { Reply } from 'redux/schemas/models/reply';
import { DiscussionTranslation } from 'redux/schemas';
import { getReplyTranslationById } from 'redux/selectors/discussion-translations';
import { getCurrentUser } from 'redux/selectors/users';
import { extractTextFromHTML } from 'shared/utils';

interface DiscussionReplyProps {
  commentId: number;
  replyId: number;
  onClickReply: Function;
  mentionableMembers?: BaseUser[];
}

const DiscussionReply = (props: DiscussionReplyProps) => {
  const styles = css`
    display: flex;
    position: relative;
    background: ${hexToRgbaString(gray6, 0.9)};
    padding: ${standardSpacing}px;
    border-left: 1px solid ${gray6};
    border-right: 1px solid ${gray6};

    .show-on-hover {
      visibility: hidden;

      ${handheld(css`
        visibility: visible;
      `)};

      ${tablet(css`
        visibility: visible;
      `)};
    }

    &:hover {
      .show-on-hover {
        visibility: visible;
      }
    }

    .animation-display {
      white-space: nowrap;
      display: flex;
      align-items: center;
    }

    ${handheld(css`
      display: block;
    `)};

    ${notHandheld(css`
      margin-left: ${halfSpacing}px;
    `)};

    .reply-body {
      display: flex;
      justify-content: space-between;
      flex: 1;
      margin-left: ${standardSpacing}px;

      .text-body p {
        margin: 0 !important;
      }

      .reply-body-main {
        flex: 1;
      }

      ${handheld(css`
        margin-left: 0;
        margin-top: ${standardSpacing}px;
      `)};

      a[oe-mention] {
        background-color: ${info};
        font-weight: ${semiBoldFontWeight};
        color: ${primary};
      }
    }

    .bottom-row {
      margin-top: ${standardSpacing}px;

      ${handheld(css`
        display: flex;
        justify-content: space-between;
      `)};

      .text-date {
        display: flex;
        flex-shrink: 0;
        margin-left: ${halfSpacing}px;
      }

      .social-row {
        display: flex;
        gap: ${standardSpacing}px;
      }
    }
  `;
  const dispatch = useAppDispatch();

  const angularServices = useContext(AngularServicesContext);
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const reply: Reply = useSelector((state) => state.models.replies[props.replyId]);
  const isNewReply = useSelector((state) => state.app.newReplyIdsForCurrentUser.includes(reply.id));

  const [editing, setEditing] = useState(false);

  const froalaRef = useRef(null);

  const {
    body: bodyTranslation,
    isLoading: isTranslationLoading,
    error: hasTranslationFailed
  }: DiscussionTranslation = useSelector(getReplyTranslationById(reply.id)) || {};
  const [showTranslatedReply, setShowTranslatedReply] = useState<boolean>(false);

  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    if(hasTranslationFailed)
      setShowTranslatedReply(false)
  }, [hasTranslationFailed])

  const like = () => {
    dispatch(voteUpReply({
      catalogId,
      replyId: reply.id,
    }));
  };

  const unlike = () => {
    dispatch(voteDownReply({
      catalogId,
      replyId: reply.id,
    }));
  };

  const onCancel = () => {
    setEditing(false);
  };

  const onEditReply = () => {
    setEditing(true);
    setTimeout(() => {
      froalaRef.current.focus();
    });
  };

  const getLikersInfo = () => {
    dispatch(getReplyLikersInfo({
      catalogId,
      replyId: reply.id,
      page: 1,
    }));
  };

  if (editing) {
    return (
      <DiscussionReplyForm
        edit
        replyId={props.replyId}
        ref={froalaRef}
        commentId={props.commentId}
        onCancel={onCancel}
        afterSubmit={() => setEditing(false)}
        mentionableMembers={props.mentionableMembers}
      />
    );
  }

  let animationText;
  if (reply.isFirstContribution) {
    animationText = t.LECTURE_PAGES.COMPONENTS.TEAM_DISCUSSION.GOOD_JOB_PARTICIPATING();
  }

  const animationDisplay = (
    <div className='animation-display'>
      <NvIcon icon='like' size='smallest' />
      {animationText}
    </div>
  );

  const onTranslationToggle = (isTranslationOn: boolean, language: string) => {
    setShowTranslatedReply(isTranslationOn)
    if(isTranslationOn) {
      dispatch(translateReply({ catalogId, replyId: reply.id, language}))
    }
  }

  return (
    <div css={styles}>
      {/* Visible only on xs */}
      <div className='d-block d-md-none'>
        <NvUserAvatar
          animate={isNewReply}
          animationDisplay={animationDisplay}
          notifications={reply.newForViewer}
          user={reply.user}
          size='sm'
          borderType='round'
          displayName
          alignNameRight
          pendoTagName={config.pendo.userProfiles.openProfile}
        />
      </div>
      {/* Hidden only on xs */}
      <div className='d-none d-md-block'>
        <NvUserAvatar
          animate={isNewReply}
          animationDisplay={animationDisplay}
          notifications={reply.newForViewer}
          user={reply.user}
          size='sm'
          borderType='round'
          pendoTagName={config.pendo.userProfiles.openProfile}
        />
      </div>
      <DiscussionReplyActions
        replyId={props.replyId}
        commentId={props.commentId}
        onEditReply={onEditReply}
      />
      <div className='reply-body'>
        <div className='reply-body-main'>
          <div className='text-body'>
            <ResponsivelyEmbeddedAngularHTML
              template={
                showTranslatedReply ? bodyTranslation : reply.body
              }
              angularServices={angularServices}
            />
          </div>
          <div className='bottom-row'>
            <div className='social-row'>
              <DiscussionLikes
                getLikersInfo={getLikersInfo}
                discussionComponent={reply}
                like={like}
                unlike={unlike}
              />
              <DiscussionReplies
                onClickIcon={() => props.onClickReply(reply.user)}
                discussionComponent={reply}
              />
              <TranslationButton
                onToggle={onTranslationToggle}
                isToggled={showTranslatedReply}
                isLoading={isTranslationLoading}
                isVisible={reply.user.id !== currentUser.id}
                dataQa={config.pendo.teamDiscussion.translationButton}
                sourceText={extractTextFromHTML(reply.body)}
              />
            </div>
            <div className='d-block d-md-none text-date text-gray-2'>
              {moment(reply.createdAt).format('lll')}
            </div>
          </div>
        </div>
        <div className='d-none d-md-block text-date text-gray-2'>
          {moment(reply.createdAt).format('lll')}
        </div>
      </div>
    </div>
  );
};

export default DiscussionReply;
