import { css } from '@emotion/react';
import React, { useContext, useState, useRef, useEffect } from 'react';
import { AngularServicesContext } from 'react-app';
import t from 'react-translate';
import { pick, mapObject, keys } from 'underscore';

import { NvFilePicker } from 'shared/components/nv-filepicker';

import {
  extraLargeSpacing, doubleSpacing, halfSpacing, largeSpacing, tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import {
  iconMedSize, iconSmallestSize,
} from 'styles/global_defaults/icons';
import { semiBoldFontWeight, textLargeFontSize, textSmallFontSize, textXSFontSize } from 'styles/global_defaults/fonts';
import { hexToRgbaString, gray3, gray4, gray5, gray6 } from 'styles/global_defaults/colors';

import { convertToPictureData, StyledLinkType } from 'redux/schemas/models/lecture-component';
import { FileUpload } from 'redux/schemas/models/file-uploads';
import { LectureComponentProps, LecturePageMode } from 'lecture_pages/components';
import NvTextArea from 'shared/components/inputs/nv-text-area';

import { S3NameSpaces } from 'shared/services/s3-upload-factory';
import useUploadFile, { FilesUploading } from 'shared/hooks/use-upload-file';
import { ProgressBar } from 'react-bootstrap';

import NvDropdown, { NvDropdownOption, NvDropdownButtonStyle, NvDropdownAlign } from 'shared/components/inputs/nv-dropdown';
import ColorPickerPopover, { ColorValuesMap } from 'components/color-picker-popover';
import IconLibraryModal from 'shared/components/nv-icon-library';
import NvTooltip from 'shared/components/nv-tooltip';

import { handheld, isRtl } from 'styles/global_defaults/media-queries';
import { StyledLinkRenderProps } from './styled-link-lecture-component';

export const getColorInputLabels = () => ({
  libraryIconColor: t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.ICON_COLOR(),
  libraryIconBackground: t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.BACKGROUND_COLOR(),
});

const FileUploadContainer = ({
  children,
  onChange,
}: {
  children: React.ReactNode,
  onChange: (picture: FileUpload) => void,
}) => {
  const { uploadFiles, isUploading, filesUploading } = useUploadFile();
  const onImagePicked = (files: File[]) => {
    uploadFiles(files, S3NameSpaces.CONTENT_TEMPLATES)
      .then(([novoEdFile]) => {
        onChange(convertToPictureData(novoEdFile));
      });
  };

  return (
    <NvFilePicker accept={['image/*']} onChange={(files) => onImagePicked(files)}>
      { !isUploading && children }
      { isUploading && <FileUploadingBar filesUploading={filesUploading} />}
    </NvFilePicker>
  );
};

const loadingStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #fff;
    opacity: 0.8;

    .bs4-progress {
      width: 30px;
      height: 5px;
      align-self: center;
      border-radius: 0;
    }
`;

const FileUploadingBar = (props: { filesUploading: FilesUploading }) => {
  const { filesUploading } = props;
  return (
    <div css={loadingStyles}>
      {Object.keys(filesUploading).map((key) => {
        const fileUploading = filesUploading[key];
        let uploadPct = 100;
        uploadPct = fileUploading.uploadPercentage;

        return (
          <div key={key}>
            <ProgressBar now={uploadPct} />
          </div>
        );
      })}
    </div>
  );
};


const CardStyledLink = (props: LectureComponentProps<StyledLinkType.CARD> & StyledLinkRenderProps) => {
  // angular savingStyledComponent is used for the confirmation modal that appears when the
  // user exits without saving the updated content.
  const angularServices = useContext(AngularServicesContext);

  const [initialContent, setInitialContent] = useState(props.lectureComponent.header ?? '');
  const [newContent, setNewContent] = useState(initialContent);
  const [showColorPicker, setShowColorPicker] = useState<boolean>();
  const [showGallery, setShowIconGallery] = useState<boolean>(false);

  const [iconProps, setIconProps] = useState(pick(props.lectureComponent.viewOptions, 'libraryIconBackground', 'libraryIconColor'));
  const [iconName, setIconName] = useState(props.lectureComponent.viewOptions?.libraryIconName);

  const ref = useRef<HTMLTextAreaElement>();

  const styles = css`
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 20px ${gray5};
    border-radius: 6px;
    margin-top: ${extraLargeSpacing / 2}px;
    min-height: ${doubleSpacing * 2}px;
    width: calc(100vw - 40px);
    max-width: 460px;
    ${props.mode !== LecturePageMode.EDIT ? 'cursor: pointer' : ''};

    ${handheld(css`
      margin-top: ${largeSpacing / 2}px;
      min-height: ${tripleSpacing}px;
    `)};

    .icon {
      display: flex;
      align-self: flex-start;
      border-radius: 50%;
      margin-top: -${extraLargeSpacing / 3}px;
      margin-left: -${extraLargeSpacing / 3}px;
      background-color: ${iconProps.libraryIconBackground};
      color: ${iconProps.libraryIconColor};

      ${handheld(css`
        margin-top: -${largeSpacing / 3}px;
        margin-left: -${largeSpacing / 3}px;
      `)};

      .icon-section {
        border-radius: 50%;
        box-shadow: 0px 0px 10px ${gray5};
        align-items: center;
        justify-content: center;
        display: flex;
        width: ${extraLargeSpacing}px;
        height:${extraLargeSpacing}px;
        font-size: ${iconMedSize}px;

        ${handheld(css`
          font-size: ${iconSmallestSize}px;
          width: ${largeSpacing}px;
          height:${largeSpacing}px;
        `)};

        ${props.mode === LecturePageMode.EDIT && css`
          &:hover {
            background-color: ${hexToRgbaString('#000', 0.7)};
            opacity: .7;
          }
        `};
      }
    }

    .title {
      word-break: break-word;
      flex-grow: 1;

      .title-text {
        white-space: pre-wrap;
        font-weight: ${semiBoldFontWeight};
        font-size: ${textLargeFontSize}px;
        ${handheld(css`
          font-size: ${textSmallFontSize}px;
        `)};
      }

      textarea {
        padding: 0px;
        border: 0px;
        overflow: hidden;
        background-color: white;
      }
    }

    .image-container {
      display: flex;
      align-self: stretch;
      min-width: 110px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      background-color: ${gray6};
      /* Fallback for IE 11 since it doesn't support align-self: stretch */
      min-height: ${doubleSpacing * 2}px;
      ${handheld(css`
        min-width: 80px;
        min-height: ${tripleSpacing}px;
      `)};

      /* TODO:SL Need to pass className for file upload module later */
      > div {
        width: 100%;
      }

      &:hover .styled-link-picture-uploaded {
        display: flex;
      }
    }

    .styled-link-image {
      ${props.lectureComponent.picture?.cdnUrl ? `background-image: url('${props.lectureComponent.picture.cdnUrl}')` : ''};
      background-position: center;
      background-size: cover;
    }

    .upload-icon-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      .upload-icon {
        color: ${props.lectureComponent.picture?.cdnUrl ? '#fff' : gray4};
        font-size: ${iconMedSize}px;
        ${handheld(css`
          font-size: ${iconSmallestSize}px;
        `)};
      }
      .upload-description {
        color: ${props.lectureComponent.picture?.cdnUrl ? '#fff' : gray3};
        text-align: center;

        ${handheld(css`
          font-size: ${textXSFontSize}px;
        `)};
      }
    }

    .styled-link-picture-uploaded {
      display: none;
    }

    .drop-down-menu {
      top: ${halfSpacing}px;
    }
  `;

  const onBlurTitle = () => {
    if (initialContent !== newContent) {
      angularServices.$scope.vm.savingStyledComponent = true;

      setInitialContent(newContent);
      props.onUpdate({ header: newContent });
    }
  };

  const onChangeTitle = (e) => {
    const { value: updateContent, scrollHeight } = e.target;
    // We need to check the height to make sure that we don't go over two lines.
    // Using scrollHeight (rather than one of the other height props) allows us
    //   to check the height before the update is applied.
    // 77 is the height of 3 lines.
    // NOTE: There is still an issue if you zoom way in or way way out (eg 200%),
    //   where the lines will change, and you can add more characters.
    if (newContent !== updateContent && scrollHeight < 77) {
      angularServices.$scope.vm.savingStyledComponent = true;
      setNewContent(updateContent);
    }
  };

  const onChangeImage = (picture: FileUpload) => {
    angularServices.$scope.vm.savingStyledComponent = true;
    props.onUpdate({ picture });
  };

  const iconPicked = (icon: string) => {
    setIconName(icon);
    props.onUpdate({
      viewOptions: {
        ...props.lectureComponent.viewOptions,
        libraryIconName: icon,
      },
    });
  };

  useEffect(() => {
    if (props.isNewComponent && ref.current) {
      ref.current.focus();
    }
  }, [props.isNewComponent]);

  const dropdownItems: NvDropdownOption[] = [
    { type: 'text', text: t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.EDIT_COLOR_DROP_DOWN_OPTION(), callback: () => setShowColorPicker(true) },
    { type: 'text', text: t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.EDIT_ICON_DROP_DOWN_OPTION(), callback: () => setShowIconGallery(true) },
  ];

  return (
    <div css={styles}>
      <div className='icon'>
        {(props.mode === LecturePageMode.EDIT)
          ? (
            <ColorPickerPopover<{
              libraryIconBackground: string,
              libraryIconColor: string,
            }>
              sections={[{
                name: 'libraryIconBackground',
                title: t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.BACKGROUND_COLOR(),
              }, {
                name: 'libraryIconColor',
                title: t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.ICON_COLOR(),
              }]}
              show={showColorPicker}
              placement='bottom-start'
              colorValues={iconProps}
              onToggle={() => setShowColorPicker(false)}
              onChange={(colorProp, newVal: string) => {
                const newProps = {
                  ...iconProps,
                  [colorProp]: newVal,
                };
                setIconProps(newProps);
                props.onUpdate({
                  viewOptions: {
                    ...props.lectureComponent.viewOptions,
                    ...newProps,
                  },
                });
              }}
            >
              <NvDropdown
                buttonStyle={NvDropdownButtonStyle.CUSTOM}
                items={dropdownItems}
                customTarget={() => (
                  <NvTooltip text={t.LECTURE_PAGES.COMPONENTS.STYLED_LINK_CARD.TOOLTIP()} preventOverflow>
                    <div className='icon-section'><div className={`icon-${iconName}`} /></div>
                  </NvTooltip>
                )}
                align={isRtl() ? NvDropdownAlign.RIGHT : NvDropdownAlign.LEFT}
              />
            </ColorPickerPopover>
          )
          : (
            <div className='icon-section'>
              <div className={`icon-${iconName}`} />
            </div>
          )}
      </div>
      {props.mode === LecturePageMode.EDIT && <IconLibraryModal onIconClick={iconPicked} show={showGallery} onClose={() => setShowIconGallery(false)} /> }
      <div className='title p-2'>
        {props.mode === LecturePageMode.EDIT
          ? (
            <NvTextArea
              className='title-text w-100'
              textareaClassName='title-text'
              onBlur={onBlurTitle}
              onChange={onChangeTitle}
              name='styled-input'
              placeholder={t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.HEADER_PLACEHOLDER()}
              value={newContent}
              maxLength={74}
              ref={ref}
            />
          )
          : <span className='title-text'>{newContent}</span>}
      </div>
      {(props.mode === LecturePageMode.VIEW)
        && <div className='image-container styled-link-image' />}
      {props.mode === LecturePageMode.EDIT
        && (
          <div className='image-container styled-link-image'>
            <FileUploadContainer onChange={onChangeImage}>
              <div className={`upload-icon-container ${props.lectureComponent.picture?.cdnUrl ? 'styled-link-picture-uploaded' : ''}`}>
                <div className='upload-icon icon-upload' />
                <span className='label upload-description pt-1'>{t.LECTURE_PAGES.COMPONENTS.STYLED_LINK_CARD.UPLOAD_IMAGE_DESCRIPTION()}</span>
              </div>
            </FileUploadContainer>
          </div>
        )}
    </div>
  );
};

export default CardStyledLink;
