import { schema } from 'normalizr';
import { BaseUsersNormalized } from '../models/my-account';
import { Team } from '../models/team';

export interface TeamsNormalized {
  [id: string]: Team
}

export interface ITeamEntities {
  teams: TeamsNormalized,
}

const teamSchema = new schema.Entity<Team>('teams');
export const teamsSchema = new schema.Array(teamSchema);

export interface GetTeamsMentionRequest {
  catalogId: string;
  teamId: number;
}

export interface GetTeamsMentionResponse {
  mentionableMembers: BaseUsersNormalized;
}
