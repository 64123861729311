import { STATUS } from './embed-lecture-component-model';

/* @ngInject */
export default function ScormLectureComponentModelService(
  _,
  config,
  $timeout,
  S3UploadWrapper,
  ExternalToolLectureComponentModel,
) {
  class ScormLectureComponentModel extends ExternalToolLectureComponentModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      const defaults = {
        externalTool: {
          toolType: 'scorm',
          // name,
          aspectRatio: '4:3',
          activityType: 'reading',
        },
        canEditBasics: false,
        allowFullScreen: true,
      };

      super(_.extend({}, defaults, attributes), isSample, useDefaults);
    }

    propagateReactUpload(file, deferredPromise) {
      $timeout(() => {
        this.uploadInProgress = new S3UploadWrapper(file, deferredPromise);
      });
    }

    // saveFileAndPersist() {
    //   const upload = Upload.upload({
    //     url: `${this.catalogId}/lecture_pages/${this.lecturePage.id}/external_tool_lecture_component`,
    //     method: 'POST',
    //     fields: {
    //       lecture_component: {
    //         external_tool: {
    //           tool_type: 'scorm',
    //           aspect_ratio: '4:3',
    //           file: this.file,
    //         },
    //         index: this.index,
    //       },
    //     },
    //   });

    //   upload.then((response) => {
    //     this.uploadInProgress = null;

    //     if (this.__urlUpdateCallback) {
    //       this.__urlUpdateCallback();
    //     }

    //     TimelinesManager.updateLecturePage(this.lecturePage.id);
    //     return _.extend(this, _.omit(response.data.result, 'lecturePage'));
    //   }, (response) => {
    //     this.uploadInProgress = null;
    //     return response;
    //   });

    //   this.uploadInProgress = new S3UploadWrapper(this.file, upload, upload.abort);

    //   return this.uploadInProgress;
    // }

    abortUpload() {
      if (this.uploadInProgress) {
        this.uploadInProgress.abort();
        this.uploadInProgress = null;
      }
      this.lecturePage.afterComponentDelete(this);
    }

    shouldShowMarkDone() {
      return (this.externalTool.isTodo
        || (this.externalTool.totalPoints?.length && this.externalTool.totalPoints[0] > 0))
        && !this.externalTool.isResizing;
    }

    getStatus() {
      return this.externalTool.progress;
    }

    inProgress() {
      return this.getStatus() === STATUS.IN_PROGRESS;
    }

    isNotStarted() {
      return this.getStatus() === STATUS.NOT_STARTED;
    }

    isCompleted() {
      return this.getStatus() === STATUS.COMPLETED;
    }
  }

  // ScormLectureComponentModel.uploadFiles = function (lecturePage, files) {
  //   const promises = [];
  //   const newComponents = lecturePage.addUnpersistedComponents(_.map(files, (file) => ({ ComponentModel: ScormLectureComponentModel, componentExtras: { file } })));

  //   _.each(newComponents, (newComponent) => {
  //     const s3Wrapper = newComponent.saveFileAndPersist();

  //     s3Wrapper.promise.then(() => {
  //     }, () => {
  //       lecturePage.afterComponentDelete(newComponent);
  //     });

  //     promises.push(s3Wrapper.promise);
  //   });

  //   return $q.all(promises);
  // };

  ScormLectureComponentModel.iconClass = 'icon-admin-scorm';
  ScormLectureComponentModel.descriptionKey = 'LECTURE_PAGES.COMPONENTS.SCORM.DESCRIPTION';
  ScormLectureComponentModel.uploadErrorMessage = 'LECTURE_PAGES.COMPONENTS.SCORM.UPLOAD_ERROR';

  ScormLectureComponentModel.createWithFileUpload = true;
  ScormLectureComponentModel.allowedFileTypes = _.union(config.files.scorm.mimeTypes, config.files.scorm.extensions).join(',');
  ScormLectureComponentModel.pendoTagName = config.pendo.lectureEdit.scorm;

  return ScormLectureComponentModel;
}
