
import reduceReducers from 'reduce-reducers';
import _ from 'underscore';

import { CourseCommunicationsState, CourseCommunicationForCourseState } from 'redux/schemas/app/course-communication';
import { PracticeRoomState } from 'redux/schemas/app/video-practice';
import { LecturePageMode } from 'lecture_pages/components';
import { LecturePageState } from 'redux/schemas/models/lecture-page';
import { CourseCompletionStatusState } from 'redux/schemas/app/course-completion-status';
import { FeaturedDiscoveryState, InfiniteLoadingState } from 'redux/schemas/models/course';
import { ComponentType } from 'redux/schemas/models/lecture-component';

import usersReducer from './users';
import licensesReducer, { initialLicenseDashboardState } from './licenses';
import institutionsReducer, { initialInstitutionDashboardState, initialJourneysDashboardState } from './institutions';
import orgMentorReducer, { initialOrgMentorDashboard, initialMentorDashboard } from './org-mentors';
import orgUserReducer from './org-users';
import coursesReducer from './courses';
import rolesReducer from './roles';
import lecturePagesReducer from './lecture-pages';
import teamsReducer from './teams';
import alertMessagesReducer from './alert-messages';
import postsReducer from './posts';
import commentsReducer from './comments';
import repliesReducer from './replies';
import skillTagsReducer, { initialSkillTagState } from './skill-tags';
import analyticsReducer, { initialQrveyState } from './analytics';
import signInReducer from './sign-in';
import signUpReducer from './sign-up';
import courseCommunicationsReducer from './course-communications';
import timelinesReducer, { appTimelineInitialState } from './timeline';
import profileCompletionReducer from './profile-completion';
import savingOverlayReducer from './saving-overlay';
import videoPracticeReducer from './video-practice';
import orgLevelProfileReducer from './org-level-profile';
import orgLevelMetadataReducer from './org-level-metadata';
import orgLevelCredlyReducer from './credly';
import orgLevelHCMReducer, { initialHCMConfigState, initialHCMSettings } from './hcm';
import orgLevelEdCastReducer, { initialEdCastConfigState, initialEdCastSettings } from './edcast';
import pollsReducer from './polls';
import videoReducer from './video';
import contentSearchReducer from './content-search';
import bookmarksReducer from './bookmarks';
import orgLevelRolesReducer from './org-level-roles';
import learningJourneysReducer, { initialVisitedLearningJourney } from './learning-journeys';
import submissionsReducer from './submissions';
import todosReducer from './todos';
import courseAccessModalReducer, { courseAccessModalInitialState } from './course-access-modal';
import videoPracticeFeedbackReducer from './video-practice-feedback';
import courseAdminDashboardReducer, { initialCourseAdminDashboard } from './course-admin-dashboard';
import collectionsReducer from './collections';
import optionsMenu from './optionsMenu';
import externalTool from './external-tool';
import skillsFeedbackReducer from './skills-feedback';
import orgLevelColorReducer from './org-level-colors';
import learnerProgressReducer, { initialLearnerProgressDashboardState } from './learner-progress';
import supervisorDashboardReducer from './supervisor-dashboard';

import { RootState, ModelsState, AppState } from '../schemas';
import {
  LicenseState,
  CourseRankingState,
  EnrollmentForPeriod,
  ExportTypes,
  EnrollmentPeriod,
  ExportStatus,
} from '../schemas/models/license';
import { MonthlyEnrollmentsResponse } from '../schemas/api/license';
import confirmationDialogsReducer, { initialConfirmationDialog } from './confirmation-dialogs';
import lectureComponents from './lecture-components';
import quizzesReducer from './quizzes';

const exportDetails = {};
_.each([ExportTypes.ORGANIZATION_ENROLLMENT, ExportTypes.ORGANIZATION_USERS], (type) => {
  exportDetails[type] = {};
  _.each(EnrollmentPeriod, (period) => {
    exportDetails[type][period] = {
      reportStatus: ExportStatus.NOT_STARTED,
    };
  });
});

export const initialLicenseState: LicenseState = {
  currentPeriodStartedAt: null,
  currentPeriodEndedAt: null,
  noSeats: null,
  gracePeriod: null,
  unenrollGracePeriod: null,
  seatUsage: null,
  loaded: false,
  notes: null,
  exportDetails,
  exportFileList: [],
  courseEnrollmentDetails: {},
};

export const initialCourseRankingsState: CourseRankingState = {
  top10Courses: null,
};

export const initialTotalLogins: number = null;

export const initialMonthlyEnrollments: MonthlyEnrollmentsResponse = {
  monthlyEnrollments: {},
};

export const initialEnrollmentsForPeriod: EnrollmentForPeriod = {
  newEnrollments: 0,
  totalEnrollments: 0,
};

export const initialLecturePageState: LecturePageState = {
  currentLectureId: null,
  mode: LecturePageMode.VIEW,
  isAdminMode: false,

  targetActivityId: -1,

  leftPanelVisibilityPreference: true,
  isLecturePageBottomReached: false,

  isLeftPanelVisible: true,
  isBottomBarVisible: false,
  isTopBarVisible: false,
  isCurrentLectureLoaded: false,
  isTimelineContentLoaded: false,
  leftPanelView: 'outline',
  newComponentIndex: -1,
  isHeaderSaving: false,
  isModeSwitching: false,

  preventPageNavigation: null,

  cachedLecturePageLookup: {},
  temporaryComponents: {},

  exercises: [],

  exitedLecturePages: {},
  locallyViewedLecturePages: {},
  isTranslating: false,
  translationFailed: false,
  novoAI: {
    itemType: null,
    content: null,
    progressiveQuizQuestionModalComponentId: null,
  },
};

export const initialCourseCommunicationForCourse: CourseCommunicationForCourseState = {
  automatedCommunications: {
    isTimelineLoaded: false,
    expandedActivities: {},
    expandedLecturePages: {},
    welcomeEmail: null,
    isCommunicationsLoading: {},
    isCommunicationsOnceLoaded: {},
  },
  draftCommunications: {
    isLoaded: false,
  },
  scheduledCommunications: {
    isLoaded: false,
    totalInPast: 0,
    leftInPast: 0,
    totalInFuture: 0,
    leftInFuture: 0,
    isPastLoading: false,
    isFutureLoading: false,
    startTime: '',
    dates: {},
  },
  filteredCommunications: {
    totalCount: 0,
    remainingCount: 0,
    isLoading: false,
    filterType: null,
    page: 1,
  },
  filteredCommunicationIds: [],
};

export const initialCourseCommunications: CourseCommunicationsState = {};

export const initialPracticeRoomState: PracticeRoomState = {
  params: {},

  scenariosList: {
    isLoading: false,
    isOnceLoaded: false,
    hasMore: false,
    scenarioIds: [],
  },

  gallerySubmissions: {},

  featuredSubmissions: {},
  mySubmissions: {},
  userSubmissions: {},
  filteredComments: {},
  scenarioLoaded: {},
};

export const initialCourseCompletionStatus: CourseCompletionStatusState = {
  modal: {
    show: false,
    isLoading: true,
  },
  completionInfo: null,
  journey: null,
  additionalJourneysCount: null,
};

export const initialInfiniteLoading: InfiniteLoadingState = {
  currentPage: 1,
  loading: false,
  loadingMore: false,
  firstPageLoaded: false,
  hasMore: true,
  total: 0,
  query: '',
  filters: {},
  ids: [],
};

export const initialFeaturedDiscovery: FeaturedDiscoveryState = {
  loading: false,
  offerings: [],
};

/**
 * Helper function since all reducers have access to entire state, but usually will only want to update state.models or state.app
 * @param state Redux State
 * @param models Models portion of Redux State to be updated
 * @param app App portion of Redux State to be updated
 */
export const newState = (state, models: Partial<ModelsState> = {}, app: Partial<AppState> = {}): RootState => ({
  ...state,
  models: {
    ...state.models,
    ...models,
  },
  app: {
    ...state.app,
    ...app,
  },
});

const initialModelState: ModelsState = {
  // TODO: I think we're missing activities and activitiesNormalized here from after I updated from prod.
  // 'activitiesNormalized' should not be a distinct model property, either
  attachments: {},
  comments: {},
  communications: {},
  courseClonings: [],
  courseRankings: initialCourseRankingsState,
  users: {},
  orgUsers: {},
  orgAdmins: {},
  courses: {},
  collectionFolders: {},
  enrollments: {},
  enrollmentsForPeriod: initialEnrollmentsForPeriod,
  exercises: {},
  exerciseSkillsRatingActivities: {},
  externalTools: {},
  groupTeamSets: {},
  institutions: {},
  orgMentors: {},
  orgMentees: {},
  roles: {},
  journeyClonings: [],
  learnerProgresses: {},
  courseOutlineSections: [],
  // TODO: Many of these properties were removed from the code I copied into the Image Component project from Course Comms.
  // I've re-added them to avoid breaking Course Comms, but do we really use them all? Specifically all of the component-specific properties
  // like surveys, quizzes, etc. I suspect some/all of these will be non-functional until we rewrite the lecture page
  lectureSections: {},
  lecturePages: {},
  lectureComponents: {},
  lectureVideos: {},
  license: initialLicenseState,
  liveSessions: {},
  monthlyEnrollments: initialMonthlyEnrollments,
  peerEvaluations: {},
  posts: {},
  quizzes: {},
  replies: {},
  surveys: {},
  // Note: this is used for both Teams and Groups (which are the same from a backend perspective)
  teams: {},
  teamsForCSV: [],
  groupsForCSV: [],
  teamDiscussions: {},
  teamSets: {},
  timedQuizzes: {},
  progressiveQuizzes: {},
  quizQuestions: {},
  quizQuestionOptions: {},
  quizQuestionFeedbacks: {},
  quizQuestionResponses: {},
  quizQuestionSubmissions: {},
  timelines: {},
  totalEnrollments: null,
  totalLogins: initialTotalLogins,
  video: {},

  // TODO: Alphabetize these
  practiceActivities: {},
  practiceScenarios: {},
  practiceSubmissions: {},
  practiceFeedbackActivities: {},
  profileRequirements: {},
  orgProfileFields: {},
  courseProfileQuestions: {},
  pointsConfigurations: {},
  mentionableMembers: {},
  courseUsers: {},

  polls: {},
  pollQuestions: {},
  pollOptions: {},
  pollVieweeSubmission: {},
  skillTags: {},
  skillTagsForProdCourses: {},
  journeyCollections: {},
  submissions: {},
  todos: {},
  skillTaggings: {},
  skillRatings: {},
  basicEnrollments: {},
  basicMentees: {},
  basicMemberships: {},
  identityProviders: {},
  hcmConfig: initialHCMSettings,
  edCastConfig: initialEdCastSettings,
  discoveryOfferings: {},
  videoPracticeSkillsRating: {},
  supervisorDashboard: {
    scenariosPendingReview: [],
  },
};

export const initialAppState: AppState = {
  // globally shared information
  currentUserId: null,
  currentInstitutionId: null,
  currentCourseId: null,
  currentCatalogId: null,
  currentVideoId: null,
  isVideoFromLibrary: false,
  visitedLearningJourney: initialVisitedLearningJourney,
  showUpdateEmailAlert: true,

  // global features
  alertMessages: [],
  showSavingOverlay: false,
  savingOverlayText: null,
  confirmationDialog: initialConfirmationDialog,
  autoGenerateProcess: {
    [ComponentType.VIDEO]: {
      isEnabled: false,
      isCaptionEnabled: false,
      isTranscriptEnabled: false,
    },
  },

  // Current playing jwplayer id
  currentPlayingVideoPlayerId: null,

  // individual features
  institutionDashboard: initialInstitutionDashboardState,
  journeysDashboard: initialJourneysDashboardState,
  licenseDashboard: initialLicenseDashboardState,
  liveSessions: {
    onceLoaded: {},
    settings: {},
    creatorIds: [],
  },
  courseCommunications: initialCourseCommunications,
  orgMentorAdminDashboard: initialOrgMentorDashboard,
  mentorDashboard: initialMentorDashboard,
  courseAdminDashboard: initialCourseAdminDashboard,
  practiceRoom: initialPracticeRoomState,
  videoPractice: {},
  exerciseSubmissions: {},
  courseCompletionStatus: initialCourseCompletionStatus,
  learnerProgressDashboard: initialLearnerProgressDashboardState,

  // Lecture page specific information. This is used as a communications
  // channel between AngulrJS and React
  lecturePage: initialLecturePageState,
  destinationLecturePage: {
    id: null,
    mode: null,
    loaded: false,
    translated: false,
    action: null,
    component: null,
  },
  skillTag: initialSkillTagState,
  qrveyConfig: initialQrveyState,
  // TODO: The below properties should probably be moved into the `lecturePage` object
  unpersistedComponents: {},
  newComponent: null,
  // The initial value of this is not actually important; this property is only used
  // to trigger recalculating the display area for image components
  timelineVisible: null,
  teamCount: 0,
  groupCount: 0,
  teamsUpdating: false,
  groupsUpdating: false,

  newCommentIdsForCurrentUser: [],
  newReplyIdsForCurrentUser: [],

  lastContentSearchString: '',
  searchInSelectedOptionIdx: 0,

  bookmarks: {
    ids: [],
    all: {},
    highlighted: null,
    loading: false,
    filter: null,
    courses: {},
    focusNew: null,
    lastDeleted: null,
  },
  orgProfile: {},

  sumTotalIntegration: {},
  myLearning: initialInfiniteLoading,
  myMentees: initialInfiniteLoading,
  myTeamsAndGroups: initialInfiniteLoading,
  discovery: initialInfiniteLoading,
  featuredDiscovery: initialFeaturedDiscovery,
  // Manage Course Access Modal state
  courseAccessModalProps: courseAccessModalInitialState,
  videoPracticeSubmissions: {},
  videoPracticeFeedback: {},
  // Manage Team / Group
  manageTeam: {},
  loginProviders: {
    isLoading: false,
    hasBeenLoaded: false,
    withPassword: true,
  },
  hcmConfig: initialHCMConfigState,
  collection: {
    newLecturePages: {},
    folder: {
      expanded: [],
    },

    highlightedCollectionCatalogId: null,
    highlightedLessonId: null,
    hasMoreInUsersSearch: false,
    hasMoreInCollectionSearch: false,
    newFolderId: null,
    scrollableLessonId: null,
    canLinkCollectionLesson: null,
    canConvertToCollectionLesson: null,
    canLessonContentConvertibleToCollection: {},
  },
  timeline: appTimelineInitialState,
  edCastConfig: initialEdCastConfigState,
  discussionTranslation: {
    comments: {},
    replies: {},
  },
  generatedAIContent: {
    discussionPrompts: {
      loading: false,
      errorCode: null,
      prompts: [],
    },
  },
  supervisorDashboard: {
    pendingSubmissions: {},
    isLoadingPendingSubmissions: false,
    isLoadingScenariosPendingReview: false,
    totalCount: 0,
    courses: {},
    isLoadingSupervisorCourses: false,
    supervisorCoursesTotalCount: 0,
  },
};

export const initialRootState: RootState = {
  models: initialModelState,
  app: initialAppState,
};

export default reduceReducers(
  initialRootState,
  usersReducer,
  contentSearchReducer,
  licensesReducer,
  alertMessagesReducer,
  confirmationDialogsReducer,
  institutionsReducer,
  orgMentorReducer,
  orgUserReducer,
  coursesReducer,
  rolesReducer,
  courseCommunicationsReducer,
  timelinesReducer,
  lecturePagesReducer,
  postsReducer,
  commentsReducer,
  repliesReducer,
  teamsReducer,
  profileCompletionReducer,
  videoPracticeReducer,
  savingOverlayReducer,
  lectureComponents,
  pollsReducer,
  orgLevelProfileReducer,
  orgLevelMetadataReducer,
  orgLevelCredlyReducer,
  orgLevelHCMReducer,
  orgLevelEdCastReducer,
  videoReducer,
  skillTagsReducer,
  analyticsReducer,
  signInReducer,
  signUpReducer,
  bookmarksReducer,
  orgLevelRolesReducer,
  learningJourneysReducer,
  submissionsReducer,
  todosReducer,
  courseAccessModalReducer,
  videoPracticeFeedbackReducer,
  courseAdminDashboardReducer,
  collectionsReducer,
  optionsMenu,
  externalTool,
  skillsFeedbackReducer,
  quizzesReducer,
  orgLevelColorReducer,
  learnerProgressReducer,
  supervisorDashboardReducer,
);
