
type NvListWithMoreProps = {
  items: any[]
  maxLength: number
  itemComponent: Function,
  more: Function,
};

const NvListWithMore = ({ items, maxLength, itemComponent, more }: NvListWithMoreProps) => (
  <div>
    {items.slice(0, maxLength).map((item, index) => itemComponent(item, index))}
    {items.length > maxLength
      ? more(items.length - maxLength)
      : ''}
  </div>
);

export default NvListWithMore;

