import { css } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import t from 'react-translate';
import { isEmpty } from 'underscore';

// Actions
import {
  getFilteredCommunications,
} from 'redux/actions/course-communications';

// Hooks
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';

// Schemas
import { FilterType } from 'redux/schemas/models/course-communication';
import {
  getFilteredCommunicationIds,
  getFilteredCommunicationsState,
} from 'redux/selectors/course-communications';

// Styles
import { gray4 } from 'styles/global_defaults/colors';

// Components
import LoadingPlaceholder from 'communications/course_communications/components/loading-placeholder';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';
import EmptyList from 'shared/components/empty-list';

type FilteredCommunicationsProps = {
  filterBy: FilterType,
  setResultsCount: Function,
};

const mapDispatchToProps = {
  fetchFilteredCommunications: getFilteredCommunications,
};

const styles = css`
  .no-content .icon {
    color: ${gray4};
  }
`;

const SCROLL_PADDING = 20;

const FilteredCommunications = (props: FilteredCommunicationsProps & typeof mapDispatchToProps) => {
  const { filterBy, fetchFilteredCommunications, setResultsCount } = props;

  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const [isResultLoaded, setResultLoaded] = useState(false);

  const scrollingElement = document.getElementById('communications-tab-content');
  const isPageBottom = useInfiniteScroll(scrollingElement, SCROLL_PADDING);

  const filteredCommunicationIds = useSelector((state) => getFilteredCommunicationIds(state));

  const {
    totalCount,
    remainingCount,
    isLoading,
    page: pageNo,
    filterType: filteredType,
  } = useSelector(state => getFilteredCommunicationsState(state, catalogId));

  // Method to fetch paginated communications
  const fetchCommunications = useCallback((
    page: number,
  ) => {
    if (catalogId && page > 0 && filterBy) {
      fetchFilteredCommunications({
        catalogId,
        filterBy,
        page,
      });
    }
  }, [catalogId, fetchFilteredCommunications, filterBy]);

  useEffect(() => {
    fetchCommunications(1);
  }, [fetchCommunications, filterBy]);

  useEffect(() => {
    setResultLoaded(!isLoading && filteredType === filterBy);
  }, [isLoading, filteredType, filterBy]);

  useEffect(() => {
    setResultsCount(totalCount);
  }, [totalCount, setResultsCount]);

  // Loading first page with selected filterType while reseting the app data from another place.
  useEffect(() => {
    if (isResultLoaded && pageNo === 1 && filterBy && !filteredType) {
      fetchCommunications(1);
    }
  }, [pageNo, filterBy, filteredType, isResultLoaded, fetchCommunications]);

  // fetch communications when user scrolled to bottom
  useEffect(() => {
    if (scrollingElement
      && isPageBottom
      && remainingCount > 0
      && !isLoading) {
      fetchCommunications(pageNo + 1);
    }
  }, [isPageBottom, remainingCount, isLoading, scrollingElement, fetchCommunications, pageNo]);

  return (
    <div css={styles}>
      { isResultLoaded && totalCount === 0 && isEmpty(filteredCommunicationIds)
        ? <EmptyList icon='messages' description={t.COURSE_COMMUNICATIONS.NO_CONTENT.FILTER()} />
        : (
          <div className='pt-6 pb-4'>
            {filteredCommunicationIds.map(communicationId => (
              <CommunicationItem
                key={communicationId.toString()}
                communicationId={communicationId}
                showOwnerActivity
              />
            ))}
          </div>
        )}
      {isLoading && <LoadingPlaceholder />}
    </div>
  );
};

const ConnectedFilteredCommunications = connect<{}, typeof mapDispatchToProps>(
  (state) => ({}),
  mapDispatchToProps as any,
)(FilteredCommunications);

export default ConnectedFilteredCommunications;
