import React from 'react';

// #region AngularServicesContext

/**
 * All of the Angular.js services that we expose to our react apps via Context.
 * DEPRECATED, use AngularContext instead.
 */
export type ExposedAngularServices = {
  $uibModal: ng.ui.bootstrap.IModalService,
  $compile: angular.ICompileService,
  $rootScope: angular.IScope,
  $scope?: angular.IScope,
  $state: any, // angular.ui.router,
  $templateCache: angular.ITemplateCacheService,
  $timeout: angular.ITimeoutService,
  $location?: angular.ILocationService,
  CurrentPermissionsManager,
  InteroperabilityRoutes,
  RailsRoutes,
  CourseRolesManager,
  CurrentCourseManager,
  // The angular service injector. This is being included to power loading angularjs lecture component
  // models, but it *could* be used to load any of the other services.
  // *In order to limit our reliance on Angularjs, please do not use this outside of the above scenario *
  $injector?: angular.auto.IInjectorService,
  TimelinesManager,
  FlyoutModalManager,
  InstitutionsManager,
  CurrentUserManager,
  ConfettiAnimation,
};

/**
 * DEPRECATED, use AngularContext instead.
 */
export const AngularServicesContext = React.createContext<ExposedAngularServices>(null);

interface WithAngularServicesProps {
  angularServices: ExposedAngularServices;
}

/* @ngInject */
export function withAngularServices<P extends {}>(
  Component: React.ComponentType<P & WithAngularServicesProps>,
) {
  function WithAngularServicesHoc(props: P) {
    const angularServices = React.useContext(AngularServicesContext);
    return <Component {...props} angularServices={angularServices} />;
  }

  const componentName = Component.displayName ?? Component.name;
  WithAngularServicesHoc.displayName = `withAngularService(${componentName})`;

  return WithAngularServicesHoc;
}

// #endregion

// #region AngularContext

export type AngularContextValue = {
  $scope: angular.IScope,
  injectServices: (services: string[]) => any[],
};

export const AngularContext = React.createContext<AngularContextValue>(null);

// #endregion
