import BookmarksPanel from 'bookmarks/components/bookmarks-panel';

export default class PracticeRoomFlyoutReactCtrl {
  /* @ngInject */
  constructor(
    $scope,
    StateManager,
    ReportsManager,
    DiscussionsManager,
    ConfirmationOverlays,
  ) {
    $scope.StateManager = StateManager;
    $scope.ReportsManager = ReportsManager;
    $scope.BookmarksPanel = BookmarksPanel;
    $scope.DiscussionsManager = DiscussionsManager;
    $scope.ConfirmationOverlays = ConfirmationOverlays;
  }
}
