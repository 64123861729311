import React, { useState } from 'react';
import { css } from '@emotion/react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';

import t from 'react-translate';
import NvRouterLink from 'nv-router/nv-link';
import NvIcon from 'shared/components/nv-icon';
import { AngularServicesContext } from 'react-app';
import NvRouter from 'shared/components/nv-router';
import Basics from 'learning_journeys/components/basics/basics';
import Details from 'learning_journeys/components/details';
import L1PageHeader from 'shared/components/l1-page-header';
import Dashboard from 'learning_journeys/components/dashboard';
import { halfSpacing, largeSpacing } from 'styles/global_defaults/scaffolding';
import AnalyticsDashboard from 'analytics_dashboard/components/analytics-dashboard';
import { gray6 } from 'styles/global_defaults/colors';
import { useSelector } from 'react-redux';
import JourneyCommunications from 'communications/journey_communications/components/journey-communication';
import { AnalyticsDashBoardType } from 'redux/schemas/app/analytics';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { getCurrentJourney } from 'redux/selectors/learning-journeys';
import { useAppDispatch } from 'redux/store';
import { getJourneyCounts } from 'redux/actions/institutions';
import { JourneysDashboardState } from 'redux/schemas/models/institution';
import { config } from '../../../config/pendo.config.json';

const DashboardRoute = () => {
  const dispatch = useAppDispatch();
  const journeysDashboard: JourneysDashboardState = useSelector((state) => state.app.journeysDashboard);
  const {
    $state,
    CurrentPermissionsManager: pm,
  } = React.useContext(AngularServicesContext);

  React.useEffect(() => {
    const hasPermission = pm.hasCourseManagerPermissions();

    if (!hasPermission) {
      $state.go('institution-dashboard');
    }
    dispatch(getJourneyCounts({
      institutionId: $state.params.institutionId,
    }));
  }, []);

  return (
    journeysDashboard?.countsLoaded
    && <Dashboard className='journeys-table' journeyCounts={journeysDashboard.journeyTypeCounts} />
  );
};

const useJourneyRedirection = (test) => {
  const {
    $state,
    $scope,
    CurrentPermissionsManager,
  } = React.useContext(AngularServicesContext);

  const testRef = React.useRef<Function>();
  testRef.current = test;
  const [journeyLoaded, setJourneyLoaded] = React.useState(false);

  React.useEffect(() => {
    if (journeyLoaded) {
      const hasPermission = testRef.current(CurrentPermissionsManager);

      if (!hasPermission) {
        $state.go('institution-dashboard');
      }
    }
  }, [journeyLoaded, $state, CurrentPermissionsManager]);

  return (course) => {
    if (course) {
      CurrentPermissionsManager.setCurrentCourse(new $scope.CourseModel(course));
    }

    setJourneyLoaded(true);
  };
};

const NewJourneyRoute = () => {
  const {
    $state,
    CurrentPermissionsManager: pm,
  } = React.useContext(AngularServicesContext);

  React.useEffect(() => {
    const hasPermission = pm.hasCourseManagerPermissions();

    if (!hasPermission) {
      $state.go('institution-dashboard');
    }
  }, [$state, pm]);

  return (
    <div className='scroll-container'>
      <Basics />
    </div>
  );
};

const BasicsRoute = () => {
  const handleJourneyLoad = useJourneyRedirection(
    (CurrentPermissionsManager) => {
      const hasCourseManager = CurrentPermissionsManager.hasCourseManagerPermissions();
      const isConfig = CurrentPermissionsManager.isConfigAndRegistrationRole();

      return hasCourseManager || isConfig;
    },
  );

  return (
    <div className='scroll-container'>
      <Basics onJourneyLoad={handleJourneyLoad} />
    </div>
  );
};

const DetailsRoute = () => {
  const handleJourneyLoad = useJourneyRedirection(
    (CurrentPermissionsManager) => CurrentPermissionsManager.hasCourseManagerPermissions()
      || CurrentPermissionsManager.isCourseBuilder(),
  );

  return (
    <div className='scroll-container'>
      <Details onJourneyLoad={handleJourneyLoad} />
    </div>
  );
};

const AnalyticsRoute = () => <AnalyticsDashboard dashboardType={AnalyticsDashBoardType.LEARNING_JOURNEY} />;
const CommunicationsRoute = () => (<JourneyCommunications />);
const contentRoutes = [
  {
    path: '/',
    exact: true,
    component: DashboardRoute,
  },
  {
    exact: true,
    path: '/new',
    component: NewJourneyRoute,
  },
  {
    exact: true,
    path: '/:catalogId/edit',
    component: DetailsRoute,
  },
  {
    exact: true,
    component: BasicsRoute,
    path: '/:catalogId/edit-basics',
  },
  {
    exact: true,
    component: AnalyticsRoute,
    path: '/:catalogId/analytics',
  },
  {
    exact: true,
    component: CommunicationsRoute,
    path: '/:catalogId/journey_communications',
  },
];

const LearningJourneysContent = () => {
  const location = useLocation();
  const { $state } = React.useContext(AngularServicesContext);
  const { journey, isLoading } = useSelector((state) => state.app.qrveyConfig);
  // analytics section needs to change the background of the whole page.
  const [isAnalytics, setIsAnalytics] = useState(location?.pathname?.includes('analytics'));
  const [isCommunications, setIsCommunications] = useState(location?.pathname?.includes('communications'));
  const journeyName = journey?.journey?.name;
  const displayHeader: boolean = (isAnalytics && !isLoading && journeyName) || !isAnalytics;

  const currentInstitution = useSelector(getCurrentInstitution);
  const currentJourney = useSelector(getCurrentJourney);

  const getTitle = () => {
    let text = t.LEARNING_JOURNEYS.TITLE();
    if (isAnalytics) {
      text = t.ANALYTICS.FOR(journeyName);
    }
    if (isCommunications) {
      text = t.JOURNEY_COMMUNICATIONS.HEADER();
    }
    return text;
  };

  const goBack = () => {
    if ($state.params.from === 'learning-journeys-dashboard') {
      $state.go('learning-journeys', { institutionId: $state.params.institutionId });
    }
    if ($state.params.from === '' || $state.params.from === 'learning-journey-home') {
      $state.go('learning-journey-home', { institutionId: $state.params.institutionId, catalogId: $state.params.catalogId });
    }
  };

  React.useEffect(() => {
    setIsAnalytics(location?.pathname?.includes('analytics'));
    setIsCommunications(location?.pathname?.includes('communications'));
  }, [location]);

  const styles = css`
    display: flex;
    height: 100vh;
    flex-direction: column;
    ${isAnalytics && css`
      background-color: ${gray6};
      overflow:auto;
    `};
    .scroll-container {
      flex: 1;
      overflow-y: auto;
      position: relative;
    }

    .journeys-table {
      flex: 1;
      height: 100%;

      /* Display created-by-name when a non-disabled row is hovered */
      .bkg-row:hover:not(.disabled) + .name-cell {
        .created-by-name {
          display: block;
        }
      }

      .no-results-panel > .icon {
        margin-bottom: ${largeSpacing}px;
      }
    }
  `;

  const getTitleLink = () => {
    if ($state.params.titleLinkToJourneyHome) {
      return ({
        title: currentJourney?.name,
        href: $state.href('learning-journey-home', {
          catalogId: $state.params.catalogId,
        }),
      });
    }
    // It returns the institution name and goes to the dashboard by default.
    return ({
      title: currentInstitution.name,
      href: $state.href('institution-dashboard'),
    });
  };

  return (
    <div className='journeys-container' css={styles}>
      {displayHeader && (
        <L1PageHeader
          title={getTitle()}
          titleLink={getTitleLink()}
          backButton={isAnalytics}
          backAction={goBack}
        />
      )}
      <NvRouter
        createRouter={false}
        routes={contentRoutes}
      />
    </div>
  );
};

const LearningJourneys = () => {
  const { $state } = React.useContext(AngularServicesContext);
  const routerBasename = `/#!/institutions/${$state.params.institutionId}/learning-journeys`;

  return (
    <Router basename={routerBasename}>
      <LearningJourneysContent />
    </Router>
  );
};


export default LearningJourneys;
