/* eslint-disable react/require-default-props */

import { css } from '@emotion/react';

interface FixedHeightLetterProps {
  children: React.ReactNode;
  capitalHeight?: number;
  className?: string;
}
/* @ngInject */
export default function FixedHeightLetter(props: FixedHeightLetterProps) {
  // desired font-size for capital height
  const { capitalHeight = 65 } = props;

  // code based on the following article:
  // https://iamvdo.me/en/blog/css-font-metrics-line-height-and-vertical-align
  // the next values where extracted from the font .ttf file
  const fontFamily = 'Open Sans Condensed';
  const emSize = 2048;
  const fmCapitalHeight = 1462 / emSize;
  const fmDescender = 600 / emSize;
  const fmAscender = 2189 / emSize;
  // const fmLineGap = 0;

  // compute font-size to get capital height equal desired font-size
  const computedFontSize = Math.round(capitalHeight / fmCapitalHeight);
  const distanceBottom = fmDescender;
  const distanceTop = fmAscender - fmCapitalHeight;

  const vAlign = (distanceTop - distanceBottom) * computedFontSize;
  const computedLineHeight = capitalHeight - vAlign;

  return (
    <p
      css={css`
        font-weight: 600;
        font-size: ${computedFontSize}px;
        font-family: ${fontFamily};
        /* use Math.ceil. Line-height a little bit taller doesn't hurt */
        line-height: ${Math.ceil(computedLineHeight)}px;
        height: ${capitalHeight}px;
        margin: 0;
        user-select: none;
      `}
      className={`fixed-height-letter ${props.className || ''}`}
    >
      <span
        css={css`
          /* use Math.floor because the behavior for decimal values varies from
          browsers. After some experiments seems like Chrome rounds decimals and
          Firefox doesn't. We default to the Firefox behavior */
          vertical-align: ${Math.floor(vAlign)}px;
        `}
      >
        {props.children}
      </span>
    </p>
  );
}
