/* @ngInject */
export default function LEditReportHeaderCtrl(
  ExercisesManager,
  CurrentUserManager,
  CurrentCourseManager,
  $stateParams,
  $controller,
  $scope,
) {
  const vm = angular.extend(this, $controller('L3HeaderCtrl', { $scope }));

  vm.subtitleSref = 'exercise-show({ exerciseId: vm.ExercisesManager.currentExercise.id })';
  vm.subtitleText = ExercisesManager.currentExercise.title;
  vm.manager = ExercisesManager;
  vm.course = CurrentUserManager.hasLoggedIn() ? CurrentUserManager.coursesHashByCatalogId[$stateParams.catalogId] : CurrentCourseManager.course;
  vm.CurrentUserManager = CurrentUserManager;
  vm.CurrentCourseManager = CurrentCourseManager;
}
