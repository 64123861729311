
import React from 'react';

interface ConditionalWrapProps<T extends React.ReactNode> {
  condition: boolean;
  wrap: (children: T) => React.ReactNode;
  children: T;
}
/**
 * inspired by Nathan's ConditionalWrap component in nv-button-popover.tsx
 * extracted to use it anywhere
 */
/* @ngInject */
export function ConditionalWrap<T extends React.ReactNode>({
  condition,
  wrap,
  children,
}: ConditionalWrapProps<T>) {
  return (
    <React.Fragment>{condition ? wrap(children) : children}</React.Fragment>
  );
}
