import { css } from '@emotion/react';
import { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import { each, filter, isArray, isEmpty, isUndefined, map, some } from 'underscore';
import { camelCase } from 'lodash';
import { useFormContext } from 'react-hook-form';
import t from 'react-translate';

// Services
import { RolesService } from 'institutions/services/roles-service';

// Schemas
import { RootState } from 'redux/schemas';
import { Role } from 'redux/schemas/models/role';
import { Recipient } from 'redux/schemas/models/course-communication';
import { NvDropdownProps } from 'shared/components/inputs/nv-dropdown';

// Selectors
import { getCourseRoles } from 'redux/selectors/roles';
import { getCourseAliases } from 'redux/selectors/course';

// Components
import NvCheckboxDropdown, { NvCheckboxDropdownItem } from 'shared/components/inputs/nv-checkbox-dropdown';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';

// Styles
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { textLargeFontSize } from 'styles/global_defaults/fonts';
import baseStyles from './base-styles';

type StateProps = {
  roles: Role[]
};

// Recipients to RoleService methods
enum RoleServiceMethod {
  IS_LEARNER = 'isLearner',
  IS_MENTOR = 'isMentor',
  IS_ADMIN = 'isAdminRole',
}

export enum RoleGroup {
  LEARNER = 'learner',
  COURSE_ADMIN = 'course_admin',
  COURSE_ADMIN_AND_MENTOR = 'course_admin_and_mentor',
  MENTOR = 'mentor',
}

/* eslint-disable key-spacing */
const recipientToRoles: {[key in Exclude<Recipient, Recipient.REGISTERED_USER>]: RoleServiceMethod[]} = {
  [Recipient.TRIGGER_LEARNER]:  [RoleServiceMethod.IS_LEARNER],
  [Recipient.LEARNERS]:         [RoleServiceMethod.IS_LEARNER],
  [Recipient.MENTORS]:          [RoleServiceMethod.IS_MENTOR],
  [Recipient.COURSE_ADMINS]:    [RoleServiceMethod.IS_ADMIN],
  [Recipient.TEAM_LEADS]:       [RoleServiceMethod.IS_LEARNER, RoleServiceMethod.IS_MENTOR, RoleServiceMethod.IS_ADMIN],
  [Recipient.GROUP_ADMINS]:     [RoleServiceMethod.IS_LEARNER, RoleServiceMethod.IS_MENTOR, RoleServiceMethod.IS_ADMIN],
};
/* eslint-enable key-spacing */

const styles = css`
  &.filter-by-roles {
    ${baseStyles};

    .filter-label {
      white-space: nowrap;
    }

    .nv-checkbox-dropdown {
      visibility: hidden;
      flex-shrink: 0;
      width: 200px;
    }

    &.on {
      .nv-checkbox-dropdown {
        visibility: visible;

        .title {
          font-size: ${textLargeFontSize}px;
        }
      }
    }

    .checkbox-wrapper {
      margin-right: ${standardSpacing}px;
    }

    .selected-values {
      display: inline-block;
      margin-left: ${halfSpacing}px;
    }
  }
`;

export const getGroup = (role) => {
  if (RolesService.isAdminRole(role)
    && RolesService.isMentor(role)) {
    return RoleGroup.COURSE_ADMIN_AND_MENTOR;
  }
  if (RolesService.isAdminRole(role)) {
    return RoleGroup.COURSE_ADMIN;
  }
  if (RolesService.isMentor(role)) {
    return RoleGroup.MENTOR;
  }
  return RoleGroup.LEARNER;
};

const FilterByRoles = (props: StateProps) => {
  const aliases = useSelector((state) => getCourseAliases(state));
  const { watch, getValues, setValue } = useFormContext();
  const [filterByRoles = false, courseRolesList, recipients] = watch(['filterByRoles', 'courseRolesList', 'recipients']);

  const [roleItems, setRoleItems] = useState<NvCheckboxDropdownItem[]>([]);

  const groupLabels = [
    {
      key: RoleGroup.LEARNER,
      label: t.COURSE_COMMUNICATIONS.FILTERS.ROLES.GROUPS.LEARNER({
        LearnerAlias: aliases.learnersAliases.LearnerAlias,
      }),
    },
    {
      key: RoleGroup.COURSE_ADMIN,
      label: t.COURSE_COMMUNICATIONS.FILTERS.ROLES.GROUPS.COURSE_ADMIN({
        CourseAlias: aliases.courseAliases.CourseAlias,
      }),
    },
    {
      key: RoleGroup.COURSE_ADMIN_AND_MENTOR,
      label: t.COURSE_COMMUNICATIONS.FILTERS.ROLES.GROUPS.COURSE_ADMIN_AND_MENTOR({
        CourseAlias: aliases.courseAliases.CourseAlias,
      }),
    },
    {
      key: RoleGroup.MENTOR,
      label: t.COURSE_COMMUNICATIONS.FILTERS.ROLES.GROUPS.MENTOR(),
    },
  ];

  const { roles } = props;
  useEffect(() => {
    if (!isEmpty(recipients) && !isEmpty(roles)) {
      // Find the methods to verify roles according to the recipients selected.
      let roleMethods: RoleServiceMethod[] = [];
      each(recipients, (recipient: Recipient) => {
        roleMethods = roleMethods.concat(recipientToRoles[recipient]);
      });

      const newRoles: NvCheckboxDropdownItem[] = [];
      if (roleMethods.includes(RoleServiceMethod.IS_LEARNER)) {
        // Can check recipients above, but roleMethods will be a simple check
        newRoles.push({
          id: 0,
          label: t.COURSE_COMMUNICATIONS.FILTERS.ROLES.NO_ROLES(),
          name: 'filterByRoleNoRoles',
          group: RoleGroup.LEARNER,
        });
      }

      // Only add the roles, which is eligible according to the recipients
      // selected
      each(roles, (role) => {
        // Check whether this role is eligible to display with the role service
        if (some(roleMethods, (method) => RolesService[method](role))) {
          newRoles.push({
            id: role.id,
            label: role.name,
            name: camelCase(`filterByRole${role.name}`),
            group: getGroup(role),
          });
        }
      });
      setRoleItems(newRoles);

      // Reset courseRolesList according to the items in RoleItems
      const courseRolesListValue = getValues('courseRolesList');
      if (!isEmpty(courseRolesListValue)) {
        const newCourseRolesList = filter(
          courseRolesListValue,
          (courseRole) => some(newRoles, (role) => role.id === courseRole.id),
        );

        setValue(
          'courseRolesList',
          map(newCourseRolesList, (role) => ({ ...role, group: getGroup(role) })),
        );
      }
    }
  }, [roles, recipients, getValues, setValue]);

  useEffect(() => {
    if (isArray(recipients) && isEmpty(recipients)) {
      setValue('filterByRoles', false, { shouldValidate: true });
    }
  }, [recipients, setValue]);

  /* Values displayed to user should maintain order of roles list */
  const selectedValues = [];
  each(roleItems, ({ id, label }) => {
    if (some(courseRolesList, (role) => role.id === id)) {
      selectedValues.push(label);
    }
  });

  const isFilterDisabled = !isUndefined(recipients) && !recipients.length;

  return (
    <div css={styles} className={`filter-by-roles ${filterByRoles ? 'on' : ''} ${isFilterDisabled ? 'disabled' : ''}`}>
      <div className='checkbox-wrapper'>
        <NvCheckbox
          withForm
          name='filterByRoles'
          label={t.COURSE_COMMUNICATIONS.FILTERS.ROLES.BY_ROLE()}
          labelClassName='text-large-regular pl-6 filter-label'
          disabled={isFilterDisabled}
        />
      </div>
      <NvCheckboxDropdown
        name='courseRolesList'
        items={roleItems}
        dropdownProps={{
          disabled: isFilterDisabled,
          noMenuPadding: true,
        } as Exclude<NvDropdownProps, 'items'>}
        groups={groupLabels}
        noSingleGroup
      />
      {filterByRoles && selectedValues && (
        <div className='selected-values text-small text-gray-2 font-weight-bold'>
          {selectedValues.join(', ')}
        </div>
      )}
    </div>
  );
};

export default connect(
  (state: RootState): StateProps => ({
    roles: getCourseRoles(state),
  }),
)(FilterByRoles);
