
import { css } from '@emotion/react';
import React, { useContext, useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import t from 'react-translate';
import { Button } from 'react-bootstrap';

// Schemas
import { RootState } from 'redux/schemas';
import { Communication, ItemState, CommunicationErrorCode, CommunicationCategory } from 'redux/schemas/models/course-communication';

// Contexts
import { CommunicationDispatch, CommunicationAction, TriggerType, getCategory } from 'communications/course_communications/contexts/communication-context';

// Selectors
import { getCourse, getCurrentCourse } from 'redux/selectors/course';
import { getCommunication, getItemState } from 'redux/selectors/course-communications';

// Styles
import { gray4, gray2 } from 'styles/global_defaults/colors';
import { standardSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { semiBoldFontWeight, boldFontWeight, textSmallFontSize } from 'styles/global_defaults/fonts';

// Components
import CommunicationEmailPreview from 'communications/course_communications/components/communication-item/communication-email-preview';
import SamplePushNotification from 'communications/course_communications/components/communication-modal/sample_push_notification/sample-push-notification';
import CommunicationTriggerRow from 'communications/course_communications/components/communication-item/communication-trigger-row';
import CommunicationFilterRow from 'communications/course_communications/components/communication-item/communication-filter-row';
import { ItemFlavor, itemFlavors } from 'communications/course_communications/components/communication-item/communication-item';
import { NvUserAvatar } from 'components/nv-user-avatar';
import ExtraTermsInfo from 'communications/course_communications/components/communication-modal/extra-terms-info';
import EmailHeader from './email-header';

type ViewCommunicationProps = {
  communicationId: number,
};

type StateProps = {
  communication: Communication,
  itemState: ItemState,
};

const styles = (itemState: ItemState) => {
  const flavor: ItemFlavor = itemState && itemFlavors[itemState];

  return css`
    padding: 40px 80px;

    .badge-wrapper {
      display: flex;
      justify-content: space-between;

      .communication-badge {
        padding: 0 3px;
        text-transform: uppercase;
        font-weight: ${boldFontWeight};
        color: white;
        font-size: ${textSmallFontSize}px;

        ${flavor?.badgeBg === 'none' && 'visibility: hidden'};
        ${flavor?.badgeBg !== 'none' && `background-color: ${flavor?.badgeBg}`};
      }

      .created-by {
        text-align: right;
        font-weight: ${semiBoldFontWeight};
        color: ${gray2};
      }
    }

    .communication {
      border-radius: ${halfSpacing}px;
      color: ${flavor?.fontColor};
      background-color: ${flavor?.boxBg};
      font-style: ${flavor?.fontStyle};

      .icons-wrapper {
        width: 25px;

        .icon {
          color: ${flavor?.iconColor};
        }
      }
    }

    .notification {
      .notification-header {
        padding: ${standardSpacing}px 0;
        border-top: 2px solid black;
        border-bottom: 1px solid ${gray4};
        display: flex;
        align-items: center;
      }
    }

    .actions {
      .buttons {
        display: flex;
        margin-top: 60px;
        justify-content: center;

        .cancel {
          margin-right: ${halfSpacing}px;
        }
      }
    }

    .title-wrapper{
      padding: ${standardSpacing}px 0;
      border-top: 2px solid black;
      border-bottom: 1px solid ${gray4};
    }

    .announcement-wrapper {
      display: flex;
    }
  `;
};

const ViewCommunication = ({
  communicationId,
  communication,
  itemState,
}: ViewCommunicationProps & StateProps) => {
  const { State, dispatch } = useContext(CommunicationDispatch);
  const currentCourse = useSelector((state) => getCurrentCourse(state));
  const announcementBodyRef = useRef(null);
  const isAnnouncement = getCategory(communication.communicationType) === CommunicationCategory.SCHEDULED_ANNOUNCEMENT;

  useEffect(() => {
    if (
      communication.communication.subject
      && communication.communication.body
      && isAnnouncement
    ) {
      announcementBodyRef.current.innerHTML = communication.communication.body;
    }
  }, [communication, isAnnouncement]);

  let itemStateText: string;
  if (itemState) {
    if (itemState === ItemState.DRAFT) {
      const draftKey = State.communicationCategory === CommunicationCategory.TRIGGERS
        ? 'DRAFT_WILL_NOT_TRIGGER' : 'DRAFT_WILL_NOT_SENT';

      itemStateText = t.COURSE_COMMUNICATIONS.ITEM_STATES[draftKey]();
    } else {
      itemStateText = t.COURSE_COMMUNICATIONS.ITEM_STATES[itemState.toUpperCase()]();
    }
  }

  let createdText: string;
  if (communication.editedBy) {
    if (communication.editedBy.isEdited) {
      createdText = t.COURSE_COMMUNICATIONS.EDITED_BY(
        moment(communication.editedBy.updatedAt).format('LLL'),
        communication.editedBy.fullName,
      );
    } else {
      createdText = t.COURSE_COMMUNICATIONS.CREATED_BY(
        moment(communication.editedBy.updatedAt).format('LLL'),
        communication.editedBy.fullName,
      );
    }
  } else {
    createdText = t.COURSE_COMMUNICATIONS.CREATED_BY_SYSTEM();
  }

  const editButtonText = isAnnouncement
    && itemState === ItemState.SENT
    && communication.communication.hasEmail
    ? t.COURSE_COMMUNICATIONS.ANNOUNCEMENT.EDIT_ANNOUNCEMENT_ONLY() : t.FORM.EDIT();

  const isEditEnabled = !communication.errorCodes?.includes(CommunicationErrorCode.ACTIVITY_DUE_DATE_REMOVED)
    && !communication.errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_FEEDBACK_COMPLETION)
    && !communication.errorCodes?.includes(CommunicationErrorCode.UNABLE_TO_TRACK_THIRD_PARTY_COMPLETION)
    && !communication.errorCodes?.includes(CommunicationErrorCode.MISSING_PROFILE_COMPLETION_REQUIRED_QUESTIONS)
    && (isAnnouncement || itemState !== ItemState.SENT);

  let displayEmailPreview: boolean = false;
  if (isAnnouncement) {
    displayEmailPreview = communication.communication?.hasEmail;
  } else {
    displayEmailPreview = !!(communication.communication?.subject && communication.communication?.body);
  }

  return (
    <div css={styles(itemState)}>
      <div className='badge-wrapper mb-2'>
        <div className='communication-badge'>
          {itemStateText}
        </div>
        <div className='created-by text-small'>
          { createdText }
        </div>
      </div>
      <div className='communication'>
        <CommunicationTriggerRow
          communicationId={communicationId}
          showOwnerActivity
        />
        <CommunicationFilterRow
          communicationId={communicationId}
          showOwnerActivity
        />
      </div>
      <div className='mt-4'>
        {communication.communication?.subject
          && communication.communication?.body
          && isAnnouncement
          && (
            <React.Fragment>
              <div className='title-wrapper page-title-small font-weight-bolder pr-4'>{t.COURSE_COMMUNICATIONS.ANNOUNCEMENT.FORM.ANNOUNCEMENT()}</div>
              <div className='announcement-wrapper'>
                <NvUserAvatar
                  borderType='round'
                  size='md'
                  user={communication.communicationAdmin}
                  displayRoleBadge
                  displayName
                  className='mt-4 px-4'
                />
                <div className='pl-4 w-100'>
                  <div className='page-title-xs font-weight-bolder my-5'>
                    {communication.communication.subject}
                  </div>
                  <div ref={announcementBodyRef} />
                </div>
              </div>

            </React.Fragment>
          )}
      </div>
      <div className='email mt-5 mb-4'>
        {displayEmailPreview
          && (
          <React.Fragment>
            <EmailHeader communicationId={communicationId} />
            <CommunicationEmailPreview
              subject={communication.communication.subject}
              body={communication.communication.body}
              communicationType={communication.communicationType}
            />
          </React.Fragment>
          )}
      </div>
      {(communication.enablePushNotification || isAnnouncement) && (
        <div className='notification mt-5 mb-4'>
          <div className='notification-header'>
            <div className='page-title-small font-weight-bolder'>{t.COURSE_COMMUNICATIONS.PUSH_NOTIFICATION.TITLE()}</div>
          </div>
          <SamplePushNotification notification={{
            title: currentCourse.name,
            bodyText: isAnnouncement
              ? `${t.COURSE_COMMUNICATIONS.ANNOUNCEMENT.FORM.ANNOUNCEMENT()} ${communication.communication.subject}`
              : communication.pushNotificationBody,
          }}
          />
          <div className='text-small text-gray-2 mt-1'>{t.COURSE_COMMUNICATIONS.PUSH_NOTIFICATION.POSTSCRIPT()}</div>
        </div>
      )}
      <ExtraTermsInfo />
      <div className='actions'>
        <div className='buttons'>
          <Button
            className='mr-2'
            variant='secondary'
            onClick={() => dispatch({
              type: CommunicationAction.CLOSE_MODAL,
            })}
          >
            {t.FORM.CLOSE()}
          </Button>
          {isEditEnabled
            && (
            <Button
              onClick={() => dispatch({
                type: CommunicationAction.SHOW_MODAL,
                triggerType: TriggerType.EDIT,
                communicationType: communication.communicationType,
                activityType: communication.ownerType,
                activityId: communication.ownerId,
                communicationId,
              })}
            >
              {editButtonText}
            </Button>
            )}
        </div>
      </div>
    </div>
  );
};

const ConnectedViewCommunication = connect(
  (state: RootState, ownProps: ViewCommunicationProps) => ({
    communication: getCommunication(state, ownProps.communicationId),
    itemState: getItemState(state, ownProps.communicationId),
  }),
)(ViewCommunication);

export default ConnectedViewCommunication;
