import { AnalyticsInstitutionDashboard, CourseAnalyticsDashboard } from '../components/analytics-dashboard';
import CourseAnalytics, {CourseAnalyticsRoute} from '../components/course-analytics';
import LicenseAnalytics from '../components/license-analytics';

/* @ngInject */
export default function AnalyticsDashboardCtrl(
  $scope,
  $uibModal,
  $controller,
  CourseModel,
  StateManager,
  AlertMessages,
  TimelineModel,
  ConfettiAnimation,
  DashboardResources,
  ConfirmationOverlays,
) {
  $scope.$uibModal = $uibModal;
  $scope.CourseModel = CourseModel;
  $scope.$controller = $controller;
  $scope.StateManager = StateManager;
  $scope.AlertMessages = AlertMessages;
  $scope.TimelineModel = TimelineModel;
  $scope.ConfettiAnimation = ConfettiAnimation;
  $scope.DashboardResources = DashboardResources;
  $scope.AnalyticsInstitutionDashboard = AnalyticsInstitutionDashboard;
  $scope.CourseAnalytics = CourseAnalyticsRoute;
  $scope.LicenseAnalytics = LicenseAnalytics;
  $scope.ConfirmationOverlays = ConfirmationOverlays;
}
