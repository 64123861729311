import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SignUpParams } from 'redux/schemas/api/sign-up';

const signUp = async (params: SignUpParams) => {
  const endpoint = '/users.json';
  return axios.post(endpoint, params)
    .then(response => response.data.result)
    .catch(error => error.response?.data);
};

// eslint-disable-next-line import/prefer-default-export
export const doSignUp = createAsyncThunk<unknown, SignUpParams>(
  'SIGN_UP',
  signUp,
);
