/* @ngInject */
export default function CustomDownloadsModelService(

  _,
  $q,
  CustomDownloadsResources,
  $sce,
) {
  const CustomDownloadsModel = function (attributes) {
    const _this = this;
    _.extend(_this, attributes);
  };

  CustomDownloadsModel.getCustomReports = function (catalogId) {
    return CustomDownloadsResources.getCustomReports({ catalogId }).$promise
      .then((resource) => resource.result);
  };

  CustomDownloadsModel.getCount = function (catalogId, payload) {
    return CustomDownloadsResources.getCount({ catalogId }, payload).$promise
      .then((resource) => resource.result);
  };

  CustomDownloadsModel.getNewReport = function (catalogId, userRole) {
    return CustomDownloadsResources.getNewReport({ catalogId, userRole }).$promise
      .then((resource) => resource.result);
  };

  CustomDownloadsModel.createCustomReport = function (catalogId, userRole, payload) {
    return CustomDownloadsResources.createCustomReport({ catalogId, userRole }, payload).$promise
      .then((resource) => resource.result);
  };

  CustomDownloadsModel.updateCustomReport = function (catalogId, reportId, payload) {
    return CustomDownloadsResources.updateCustomReport({ catalogId, id: reportId }, payload).$promise
      .then((resource) => resource.result);
  };

  CustomDownloadsModel.generateReport = function (catalogId, reportId) {
    return CustomDownloadsResources.generateReport({ catalogId }, { id: reportId }).$promise
      .then((resource) => resource.result);
  };

  CustomDownloadsModel.getExistingReport = function (catalogId, reportId) {
    return CustomDownloadsResources.getExistingReport({ catalogId, id: reportId }).$promise
      .then((resource) => resource.result);
  };

  CustomDownloadsModel.getDownloadsList = function (catalogId, reportId) {
    return CustomDownloadsResources.getDownloadsList({ catalogId, id: reportId }).$promise
      .then((resource) => resource.result);
  };

  CustomDownloadsModel.duplicateReport = function (catalogId, payload) {
    return CustomDownloadsResources.duplicateReport({ catalogId }, payload).$promise
      .then((resource) => resource.result);
  };

  CustomDownloadsModel.deleteReport = function (catalogId, payload) {
    return CustomDownloadsResources.deleteReport({ catalogId }, payload).$promise
      .then((resource) => resource.result);
  };

  CustomDownloadsModel.getNewContentDownload = function (catalogId) {
    return CustomDownloadsResources.getNewContentDownload({ catalogId }).$promise
      .then((resource) => resource.result);
  };

  CustomDownloadsModel.createContentDownload = function (catalogId, payload) {
    return CustomDownloadsResources.createContentDownload({ catalogId }, payload).$promise
      .then((resource) => resource.result);
  };

  CustomDownloadsModel.generateContentReport = function (catalogId, reportId) {
    return CustomDownloadsResources.generateContentReport({ catalogId }, { id: reportId }).$promise
      .then((resource) => resource.result);
  };

  CustomDownloadsModel.deleteContentReport = function (catalogId, payload) {
    return CustomDownloadsResources.deleteContentReport({ catalogId }, payload).$promise
      .then((resource) => resource.result);
  };

  return CustomDownloadsModel;
}
