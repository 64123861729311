/* eslint-disable jsx-a11y/media-has-caption */

import { useEffect, useRef } from 'react';

import { displayBlobInPlayer } from 'recording/services/media-visualizer-helper';

type AudioBlobPreviewProps = {
  blob: Blob,
};

/**
 * Displays the given audio blob in a player
 */
const AudioBlobPreview = ({
  blob,
}: AudioBlobPreviewProps) => {
  const audioRef = useRef<HTMLAudioElement>();

  useEffect(() => {
    if (blob) {
      return displayBlobInPlayer(audioRef.current, blob);
    }

    return undefined;
  }, [blob]);

  return <audio ref={audioRef} controlsList='nodownload' />;
};

export default AudioBlobPreview;
