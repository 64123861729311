import angular from 'angular';

import { MutableRefObject } from 'react';
import { ExposedAngularServices } from 'react-app';

// An older version of rendering components used during the Lecture Page Rewrite project.
// Keeping around for quick reference; let's delete if shipped & unused
// export const evalAngularLectureComponent = (component: Element, services: ExposedAngularServices, isolateScope?: boolean) => {
//   const c = component;
//   if (!c.attributes['data-rendered']?.value) {
//     c.setAttribute('data-rendered', 'true');
//     const linkFn = services.$compile(c);
//     const scope = isolateScope ? services.$scope.$new(true, services.$scope) : services.$scope;
//     linkFn(scope);

//     return scope;
//   }

//   return null;
// };

export const embedAngular = (template: string, ref: MutableRefObject<any>, services: ExposedAngularServices, newScope?: ng.IScope) => {
  const cloneAttachFn = (clonedElement: JQuery, attachScope: angular.IScope) => {
    const container: HTMLElement = ref.current;
    if (!container) {
      // eslint-disable-next-line no-console
      console.error('Null container given to cloneAttachFn');
      return;
    }

    container.innerHTML = '';
    container.appendChild(clonedElement[0]);
  };

  const linkFn = services.$compile(template);
  const scope = newScope ?? services.$scope;
  linkFn(scope ?? services.$rootScope, cloneAttachFn);

  return scope;
};

export const embedAngularTemplate = (
  templateUrl: string,
  ref: MutableRefObject<any>,
  services: ExposedAngularServices,
  controllerFunction?: any, // Actually type Parameters<IControllerService>[0]
  extraControllerDeps?: Object,
  isolateScope?: boolean,
) => {
  const template = services.$templateCache.get<string>(templateUrl);

  const scope = isolateScope ? services.$scope.$new(true, services.$scope) : services.$scope;

  if (controllerFunction) {
    const $controller: angular.IControllerService = services.$injector.get('$controller');

    const injectables = {};

    (controllerFunction as any).$inject.forEach((serviceName: string, i) => {
      let service = null;

      // Skip extra deps b/c they'll be merge din during the call to $controller()
      if (serviceName in extraControllerDeps) {
        return;
      }

      if (!services.$injector.has(serviceName)) {
        switch (serviceName) {
          case '$scope':
            service = scope;
            break;
          default:
            throw new Error(`Service '${serviceName}' was not found registered for controller '${controllerFunction.name}'`);
        }
      } else {
        service = services.$injector.get(serviceName);
      }

      injectables[serviceName] = service;
    });

    scope.vm = $controller(controllerFunction, {
      ...injectables,
      ...extraControllerDeps,
    });
  }

  return embedAngular(template, ref, services, scope);
};

export default embedAngular;
