import { css } from '@emotion/react';
import { LoadingPlaceholderBlock, loadingPlaceholderStyles } from 'communications/course_communications/components/loading-placeholder';

const styles = css`
  ${loadingPlaceholderStyles};

  width: 80%;
  /* It seems really strange that the current site uses a top spacing here. Consider removing */
  margin-top: 40px;
  .placeholder-bar {
    margin-bottom: 15px;
  }
`;

const LeftPanelPlaceHolder = () => (
  <div css={styles}>
    <div className='mb-6'>
      <LoadingPlaceholderBlock />
    </div>
    <LoadingPlaceholderBlock />
  </div>
);

export default LeftPanelPlaceHolder;
