import LearningJourney from '../components/learning-journey';
import bootstrapReact, { unmountReact } from '../../react-app';

/* @ngInject */
export default function LearningJourneysCtrl(
  $scope,
  $uibModal,
  $controller,

  CourseModel,
  StateManager,
  AlertMessages,
  ConfettiAnimation,
  CurrentUserManager,
  ConfirmationOverlays,
  LearningJourneyService,
) {
  $scope.$uibModal = $uibModal;
  $scope.CourseModel = CourseModel;
  $scope.$controller = $controller;
  $scope.StateManager = StateManager;
  $scope.AlertMessages = AlertMessages;
  $scope.ConfettiAnimation = ConfettiAnimation;
  $scope.CurrentUserManager = CurrentUserManager;
  $scope.ConfirmationOverlays = ConfirmationOverlays;

  initialize();

  function initialize() {
    LearningJourneyService.listenToJourneyCompletions();

    $scope.LearningJourney = LearningJourney;

    $scope.$on('$destroy', () => {
      LearningJourneyService.unlistenToJourneyCompletions();
    });
  }
}
