/* @ngInject */
export default function nvFileUploadProgress() {
  return {
    scope: {
      progress: '=',
      file: '=?',
      abortUpload: '&?',
      uploadInProgress: '=?',
    },
    templateUrl: 'shared/templates/nv-file-upload-progress.html',
  };
}
