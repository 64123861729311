import t from '../../../react-translate';

export default {
  bindings: {
    lectureComponent: '<',
    editMode: '<',
    restrictedEditMode: '<',
    reorderMode: '<',
    index: '<',
    context: '<',
    onContentReady: '<',
  },
  controller: function ctrl(
    $scope,
    $controller,
    LectureComponentsHelper,
    InteroperabilityRoutes,
  ) {
'ngInject';
    angular.extend(this, $controller('LectureComponentBaseCtrl'));
    const vm = this;
    vm.InteroperabilityRoutes = InteroperabilityRoutes;

    initialize();

    function initialize() {
      if (vm.lectureComponent.currentLectureVideo) {
        vm.lectureComponent.currentLectureVideo.readyCallback = () => {
          vm.onContentReady();
        };
      }
    }

    /* Admin Actions */
    vm.editBasics = () => {
      const modalInstance = LectureComponentsHelper.showEditModal(vm.lectureComponent, {
        windowClass: 'large-modal video-modal',
      });
    };

    vm.reorderVideos = () => {
      vm.lectureComponent.reorderMode = true;
      vm.lectureComponent.clickedReorder = true;
    };

    vm.saveTitleOnBlur = () => {
      vm.lectureComponent.defaultTitle = false;
      vm.lectureComponent.save();
    };

    vm.setEditMenu = () => {
      const { sharedProps } = this.context;
      const extraOptions = [];

      extraOptions.push({
        type: 'header',
        title: t.LECTURE_AUDIO.AUDIO_LIST(),
      });

      if (vm.lectureComponent.lectureVideos.length > 1) {
        extraOptions.push(sharedProps.extraOptions.getEditOption(t.LECTURE_AUDIO.ADD_AUDIO_TO_PLAYLIST()));

        extraOptions.push({
          type: 'text',
          text: t.LECTURE_AUDIO.REORDER_PLAYLIST(),
          callback: () => vm.reorderVideos(),
        });
      }

      if (vm.lectureComponent.lectureVideos.length === 1) {
        extraOptions.push(sharedProps.extraOptions.getEditOption(t.LECTURE_AUDIO.ADD_AUDIO_TO_CREATE_PLAYLIST()));
        extraOptions.push({
          type: 'text',
          text: t.LECTURE_AUDIO.EDIT_AUDIO(),
          callback: () => vm.lectureComponent.editIndividualVideo(vm.lectureComponent.lectureVideos[0]),
        });
      }

      sharedProps.extraOptions = {
        ...sharedProps.extraOptions,
        ...{
          options: extraOptions,
        },
      };

      if (vm.lectureComponent.lectureVideos.length > 1) {
        sharedProps.deleteText = t.LECTURE_PAGES.COMPONENTS.VIDEO_LIST.DELETE();
      } else {
        sharedProps.deleteText = t.LECTURE_PAGES.COMPONENTS.DELETE();
      }
    };

    vm.setEditMenu();

    $scope.$watch('vm.lectureComponent.lectureVideos.length', () => {
      vm.setEditMenu();
    });
  },
  controllerAs: 'vm',
  templateUrl: 'lecture_pages/templates/components/nv-audio-list-lecture-component.html',
};
