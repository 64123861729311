import React from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import t from 'react-translate';
import { AngularContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import NvSwitch from 'shared/components/inputs/nv-switch';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { setAutomatedTranslationState } from 'redux/actions/institutions';
import { config } from '../../../config/pendo.config.json';

const AutomatedTranslationSwitch = () => {
  const dispatch = useAppDispatch();
  const { injectServices } = React.useContext(AngularContext);
  const currentInstitution = useSelector(getCurrentInstitution);
  const [InstitutionsManager] = injectServices(['InstitutionsManager']);

  const handleSwitchChange = (newChecked) => {
    wrapThunkAction(dispatch(setAutomatedTranslationState({
      institutionId: currentInstitution.id,
      enabled: newChecked,
    }))).then(() => {
      InstitutionsManager.institution.updateFromReact({ automatedTranslationEnabled: newChecked });
    });
  };

  return (
    <React.Fragment>
      {/* Angular institution page styles class */}
      <div className='switch'>
        <NvSwitch
          className='nv-switch'
          onChange={handleSwitchChange}
          enabledLabel={t.SHARED.ENABLED().toUpperCase()}
          disabledLabel={t.SHARED.DISABLED().toUpperCase()}
          checked={currentInstitution.automatedTranslationEnabled}
          data-qa={config.pendo.automatedTranslation.institutionAutomatedTranslationSwitch}
        />
      </div>
    </React.Fragment>
  );
};

export default AutomatedTranslationSwitch;
