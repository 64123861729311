import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import t from 'react-translate';
import { NvTab } from 'shared/components/nv-responsive-tabs';
import { black, gray1, gray5, gray6, hexToRgbaString, warning, white } from 'styles/global_defaults/colors';
import { boldFontWeight } from 'styles/global_defaults/fonts';
import { halfSpacing, largeSpacing, standardSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { getLearnerProgressCounts } from 'redux/actions/learner-progress';
import { RootState, CombinedCourse } from 'redux/schemas';
import { getCurrentCourse, getCourseAliases } from 'redux/selectors/course';
import { Course } from 'redux/schemas/models/course';
import { useAppDispatch } from 'redux/store';
import { config } from '../../../config/pendo.config.json';


export enum TabTypes {
  TOTAL = 'total',
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  MISSED_DEADLINE = 'missed_deadline',
  INACTIVE_IN_LAST_WEEK = 'inactive_in_last_week',
  NEVER_ACTIVE = 'never_active',
}
export type LearnerProgressTotals = {
  totalLearners: number,
  completed: number,
  inProgress: number,
  missedDeadlines: number,
  inactiveInPastSevenDays: number,
  neverActive: number,
};
type LearnerProgressTabsProps = {
  selectTab: (tab: TabTypes) => void,
  totals: LearnerProgressTotals
};

const LearnerProgressTabs = (props: LearnerProgressTabsProps) => {
  const styles = css`
    display: flex;
    padding: ${halfSpacing}px;
    overflow-x: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  `;

  const dispatch = useAppDispatch();

  const aliases = useSelector((state) => getCourseAliases(state));

  const {
    totalLearners,
    completed,
    inProgress,
    missedDeadlines,
    inactiveInPastSevenDays,
    neverActive,
  } = props.totals;

  const [activeTab, setActiveTab] = useState(TabTypes.TOTAL);
  const currentCourse = useSelector<RootState, CombinedCourse>((state) => getCurrentCourse(state)) as Course;

  const tabs: NvTab[] = [
    {
      text: t.LEARNER_PROGRESS.TABS.TOTAL_LEARNERS({ ...aliases.learnersAliases }),
      count: totalLearners,
      onClick: () => {
        setActiveTab(TabTypes.TOTAL);
        props.selectTab(TabTypes.TOTAL);
      },
      id: TabTypes.TOTAL,
      dataQA: config.pendo.learnerProgress.tabs.totalLearers,
    },
    {
      text: t.LEARNER_PROGRESS.TABS.COMPLETED(),
      count: completed,
      onClick: () => {
        setActiveTab(TabTypes.COMPLETED);
        props.selectTab(TabTypes.COMPLETED);
      },
      id: TabTypes.COMPLETED,
      dataQA: config.pendo.learnerProgress.tabs.completed,
    },
    {
      text: t.LEARNER_PROGRESS.TABS.IN_PROGRESS(),
      count: inProgress,
      onClick: () => {
        setActiveTab(TabTypes.IN_PROGRESS);
        props.selectTab(TabTypes.IN_PROGRESS);
      },
      id: TabTypes.IN_PROGRESS,
      dataQA: config.pendo.learnerProgress.tabs.inProgress,
    },
    {
      text: t.LEARNER_PROGRESS.TABS.MISSED_DEADLINES(),
      count: missedDeadlines,
      onClick: () => {
        setActiveTab(TabTypes.MISSED_DEADLINE);
        props.selectTab(TabTypes.MISSED_DEADLINE);
      },
      id: TabTypes.MISSED_DEADLINE,
      dataQA: config.pendo.learnerProgress.tabs.missedDeadlines,
    },
    {
      text: t.LEARNER_PROGRESS.TABS.INACTIVE_IN_PAST_SEVEN_DAYS(),
      count: inactiveInPastSevenDays,
      onClick: () => {
        setActiveTab(TabTypes.INACTIVE_IN_LAST_WEEK);
        props.selectTab(TabTypes.INACTIVE_IN_LAST_WEEK);
      },
      id: TabTypes.INACTIVE_IN_LAST_WEEK,
      dataQA: config.pendo.learnerProgress.tabs.inactiveInPastSevenDays,
    },
    {
      text: t.LEARNER_PROGRESS.TABS.NEVER_ACTIVE(),
      count: neverActive,
      onClick: () => {
        setActiveTab(TabTypes.NEVER_ACTIVE);
        props.selectTab(TabTypes.NEVER_ACTIVE);
      },
      id: TabTypes.NEVER_ACTIVE,
      dataQA: config.pendo.learnerProgress.tabs.neverActive,
    },
  ];

  useEffect(() => {
    dispatch(getLearnerProgressCounts({
      catalogId: currentCourse.catalogId,
    }));
  }, []);

  return (
    <div css={styles}>
      {tabs.map((tab) => (<Tab key={tab.id} tab={tab} isSelected={activeTab === tab.id} />))}
    </div>
  );
};

export default LearnerProgressTabs;

const Tab = ({ tab, isSelected }: { tab: NvTab, isSelected?: boolean }) => {
  const tabStyles = css`
    min-width: 138px;
    flex-basis: calc(100% / 6);
    font-weight: ${boldFontWeight};

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;

    cursor: pointer;
    padding: ${standardSpacing}px ${halfSpacing}px;
    margin: ${halfSpacing}px;
    background-color: ${white};
    border-radius: ${halfSpacing}px;
    box-shadow: 0px ${halfSpacing}px ${largeSpacing}px 0px ${hexToRgbaString(gray6, 0.50)};

    ${isSelected ? css`
      color: ${black};
    ` : css`
      color: ${gray1};
    `}

    &:hover {
      color: ${black};
    }

    .tab-title {
      text-align: center;
      margin-bottom: ${threeQuartersSpacing}px;
    }

    .selection-bar {
      position: absolute;
      bottom: 0;
      height: ${halfSpacing}px;
      width: 80px;

      ${isSelected && css`
        background-color: ${warning};
      `}
    }

    ${!isSelected && css`
      &:hover .selection-bar {
        background-color: ${gray5};
      }
    `};
  `;

  return (
    <div css={tabStyles} className='tab' onClick={tab.onClick} data-qa={tab.dataQA}>
      <div className='tab-title'>{tab.text}</div>
      <div className='tab-count course-title-l'>{tab.count}</div>
      <div className='selection-bar' />
    </div>
  );
};
