import prodPathReplace from 'shared/prod-path-rewrite';

/* @ngInject */
export default function StatementsManager(
  $translate,
  CurrentCourseManager,
  CurrentUserManager,
  StatementModel,
  StatementsResources,
) {
  let currentCourse;
  const manager = {
    currentStatement: null,
    requestingStatement: false,

    requestStatementForuser,
    getMetaTags,
    makePublic,
    getShareText,
  };

  function requestStatementForuser(catalogId, userId) {
    currentCourse = CurrentUserManager.coursesHashByCatalogId[catalogId] || CurrentCourseManager.course;
    manager.requestingStatement = true;

    return StatementsResources.getStatementForUser(
      { catalogId, userId },
      (resource) => {
        manager.requestingStatement = false;
        manager.currentStatement = new StatementModel(resource.result);

        return manager.currentStatement;
      },
    ).$promise;
  }

  function makePublic(catalogId) {
    if (!manager.currentStatement.shareStatement) {
      return StatementsResources.makePublic(
        { catalogId },
        (resource) => {
          manager.currentStatement.shareStatement = true;
        },
      ).$promise;
    }
    return null;
  }

  function getMetaTags() {
    if (manager.currentStatement?.shareStatement) {
      return {
        'og:title': `${manager.currentStatement.user.firstName}'s ${currentCourse.certificateName.singularizedTitleized}`,
        'og:description': `${manager.currentStatement.user.firstName} just completed ${manager.currentStatement.isFree ? 'a free online' : 'an'}
            offering of ${currentCourse.name} by ${currentCourse.institution.name} on NovoEd.`,
        'og:image': prodPathReplace('images/completion-badge-blue.png'),
        'og:image:height': 240,
        'og:image:width': 240,
      };
    }
    return {};
  }

  function getShareText() {
    if (manager.currentStatement) {
      return $translate.instant('STATEMENT_OF_ACCOMPLISHMENT.DOWNLOAD_AND_SHARE.SHARE_TEXT', {
        firstName: manager.currentStatement.user.firstName,
        statementAlias: currentCourse.certificateName.singularizedTitleized,
      });
    }
    return null;
  }

  return manager;
}
