import React from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/schemas';
import { useAppDispatch } from 'redux/store';
import NvIcon from 'shared/components/nv-icon';
import useFocusEvent from 'shared/hooks/use-focus-event';
import { getCurrentCourse } from 'redux/selectors/course';
import useClickOutside from 'shared/hooks/use-click-outside';
import { getVerticalCenterStyles } from 'shared/services/nv-util';
import useDebouncedEffect from 'shared/hooks/use-debounced-effect';
import { updateLectureComponent } from 'redux/actions/lecture-pages';
import { gray4, gray7, primary } from 'styles/global_defaults/colors';
import { getLectureComponent } from 'redux/selectors/lecture-components';
import Input from 'lecture_pages/components/white-space-lecture-component/input';
import { LectureComponentProps, LecturePageMode } from 'lecture_pages/components';
import BaseLectureComponentContext from 'lecture_pages/directives/components/base-lecture-component/context';
import {
  standardSpacing,
  threeQuartersSpacing,
  reorderToolsPanelHeight,
} from 'styles/global_defaults/scaffolding';
import {
  ComponentType,
  NLectureComponent,
} from 'redux/schemas/models/lecture-component';

export const SESSION_STORAGE_ITEM = 'white-space-component-last-width';

const WhiteSpaceLectureComponent = (props: LectureComponentProps<ComponentType.WHITE_SPACE>) => {
  const {
    mode,
    currentLecture,
    lectureComponent: propsLC,
  } = props;

  const dispatch = useAppDispatch();
  const clickedOutsideRef = React.useRef(false);
  const inputRef = React.useRef<HTMLInputElement>();
  const containerRef = React.useRef<HTMLDivElement>();
  const [isInputFocused, setIsInputFocused] = React.useState(false);
  const [isComponentFocused, setIsComponentFocused] = React.useState(false);
  const baseComponentContext = React.useContext(BaseLectureComponentContext);

  const lectureComponent = useSelector((state) => (
    getLectureComponent(state, propsLC.id)
  )) as NLectureComponent<ComponentType.WHITE_SPACE>;

  useClickOutside(containerRef, () => {
    clickedOutsideRef.current = true;
  }, []);

  const { topBorderWidth: savedSize } = lectureComponent.viewOptions;
  const [localSize, setLocalSize] = React.useState(savedSize);

  const isNewComponent = useSelector<RootState, boolean>(
    (state) => state.app.newComponent === propsLC.id,
  );

  useFocusEvent(baseComponentContext.containerRef, 'focus', () => {
    setIsComponentFocused(true);
  }, []);

  useFocusEvent(baseComponentContext.containerRef, 'blur', (e) => {
    if (e.relatedTarget ? !baseComponentContext.containerRef.current.contains(e.relatedTarget as Node) : clickedOutsideRef.current) {
      clickedOutsideRef.current = false;

      setIsComponentFocused(false);
    }
  }, []);

  React.useEffect(() => {
    setLocalSize(savedSize);
  }, [savedSize]);

  React.useEffect(() => {
    if (isNewComponent) {
      containerRef.current.click();
    }
  }, [isNewComponent]);

  const currentCourse = useSelector(getCurrentCourse);

  const isEditMode = mode === LecturePageMode.EDIT;
  const isReorderMode = mode === LecturePageMode.REORDER;

  useDebouncedEffect(() => {
    const componentDataUpdate = {
      lecturePageId: currentLecture.id,
      catalogId: currentCourse.catalogId,
      componentData: {
        id: lectureComponent.id,
        type: lectureComponent.type,
        index: lectureComponent.index,
        viewOptions: {
          topBorderWidth: localSize,
        },
      },
    };

    if (savedSize !== localSize) {
      sessionStorage.setItem(SESSION_STORAGE_ITEM, localSize.toString());
      dispatch(updateLectureComponent(componentDataUpdate));
    }
  }, 500, [
    dispatch,
    localSize,
    savedSize,
    currentLecture.id,
    lectureComponent.id,
    lectureComponent.type,
    lectureComponent.index,
    currentCourse.catalogId,
  ]);

  const isActive = (isInputFocused || isComponentFocused) && isEditMode;

  const borderStyles = css`
    border-top: 1px ${isActive ? 'solid' : 'dashed'} ${isActive ? primary : gray4};
    border-bottom: 1px ${isActive ? 'solid' : 'dashed'} ${isActive ? primary : gray4};
  `;

  const activeStyles = css`
  background-color: ${isActive ? 'transparent' : gray7};
  ${borderStyles};

  .space-input {
    opacity: 1;
  }
`;

  const styles = css`
    position: relative;
    height: ${isReorderMode ? standardSpacing : localSize}px;

    ${isReorderMode && css`
      ${borderStyles};
      & > .icon {
        color: ${gray4};
      }
    `};

    ${isEditMode && css`
      &:hover {
        ${activeStyles};
      }
    `};

    .space-input {
      left: 50%;
      opacity: 0;
      position: absolute;
      transform: translateX(-50%);
      top: -${threeQuartersSpacing}px;
    }

    ${isActive && activeStyles};
  `;

  const handleContainerClick = (e) => {
    if (isEditMode && e.target === e.currentTarget) {
      inputRef.current.focus();
    }
  };

  return (
    <div
      css={styles}
      ref={containerRef}
      onClick={handleContainerClick}
      id={`lecture-component-${lectureComponent?.id}`}
      className='d-flex justify-content-center align-items-center'
      // Components whose height can be less than the height of the reorder
      // tools panel height (50px) in reorder mode should be vertically centered.
      // In this case, White Space component height is always 20px in reorder
      // mode.
      style={isReorderMode ? getVerticalCenterStyles(reorderToolsPanelHeight, standardSpacing) as React.CSSProperties : undefined}
    >
      {isEditMode && (
        <Input
          ref={inputRef}
          value={localSize}
          className='space-input'
          onChange={setLocalSize}
          onBlur={() => setIsInputFocused(false)}
          onFocus={() => setIsInputFocused(true)}
        />
      )}
      {isReorderMode && (
        <NvIcon icon='spacer' size='xs-smallest' />
      )}
    </div>
  );
};

export default WhiteSpaceLectureComponent;
