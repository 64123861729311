/* @ngInject */
export default function ModifyContentAccessExtensionControllerService(
  $uibModalInstance,
  CurrentCourseManager,
  AlertMessages,
  UserManagementResources,
  user,
  config,
) {
  class ModifyContentAccessExtensionController {
    constructor() {
      this.user = user;
      this.CurrentCourseManager = CurrentCourseManager;
      this.$uibModalInstance = $uibModalInstance;
      this.UserManagementResources = UserManagementResources;
      this.originalAccessCloseDate = user.accessCloseDate;
      this.config = config;
    }

    onModifyContentAccessDate() {
      return UserManagementResources.modifyAccessCloseDate({ catalogId: CurrentCourseManager.course.catalogId, userId: user.id }, { accessCloseDate: user.accessCloseDate })
        .$promise.then((response) => {
          $uibModalInstance.dismiss();
          AlertMessages.success('FORM.SUCCESS_BANG', 'USER_MANAGEMENT.MODIFY_CONTENT_ACCESS_END_DATE_SUCCESS', {}, { fullName: user.fullName });
          return response.result;
        }).catch(() => {
          AlertMessages.error('FORM.OOPS', 'FORM.ERROR_SOMETHING_WRONG');
        });
    }

    cancelModification() {
      user.accessCloseDate = this.originalAccessCloseDate;
      $uibModalInstance.dismiss();
    }
  }

  return new ModifyContentAccessExtensionController();
}
