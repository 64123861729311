import t from 'react-translate';
import { reduce, last } from 'underscore';

export enum JoinMode {
  AND = 'AND',
  OR = 'OR',
}

/**
 * Join items in `and` or `or` mode accordingly using commas
 *
 * @param items Items
 * @param mode Mode in which it need to be joined
 */
export const i18nJoin = (items: string[], mode: JoinMode = JoinMode.AND): string => {
  let lengthCategory = 'MANY';
  if (items.length === 2) {
    lengthCategory = 'TWO';
  } else if (items.length === 3 || items.length === 4) {
    lengthCategory = 'THREE_OR_FOUR';
  }

  return reduce(items, (prev: string, item, index) => {
    let separator: string;
    if (index === items.length - 1) {
      separator = '';
    } else if (index === items.length - 2) {
      separator = t.JOIN[lengthCategory][mode]();
    } else {
      separator = t.JOIN[lengthCategory].COMMA();
    }

    return prev + item + separator;
  }, '');
};

// General aliases passed to the translation function t.INSTITUTIONS.ROLES
export const aliases = {
  courseAlias: 'course',
  CourseAlias: 'Course',
  LearnerAlias: 'Learner',
  learnerAlias: 'learner',
  assignmentsAlias: 'assignments',
};
/**
 * Get the key to select the correct translation in t.INSTITUTIONS.ROLES
 *
 * @param translateString String with the translation key at the end, ex 'INSTITUTIONS.ROLES.TA_FACILITATOR_DESC'
 */
export const getTranslateKey = (translateString: string) => last(translateString.split('.'));
