import { createReducer } from '@reduxjs/toolkit';
import { enableEdCastIntegration, updateEdCastIntegration } from 'redux/actions/edcast';
import { DevCredentials, EdCastConfigState, EdCastSettings, LxpCredentials } from 'redux/schemas/models/edcast';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { initialRootState } from './index';

export const initialEdCastConfigState: EdCastConfigState = {
  isLoading: false,
};

const initialLxpCredentials: LxpCredentials = {
  clientId: '',
  clientSecret: '',
  hostUrl: '',
};

const initialDevCredentials: DevCredentials = {
  apiKey: '',
  apiSecret: '',
  email: '',
  edcastUrl: '',
};

export const initialEdCastSettings: EdCastSettings = {
  lxpCredentials: initialLxpCredentials,
  devCredentials: initialDevCredentials,
};

const setEdCastConfig = (state, action) => {
  state.app.edCastConfig.isLoading = false;
  const currentInstitution = getCurrentInstitution(state);
  Object.assign(currentInstitution, action.payload.institutionList);
};

export default createReducer(initialRootState, (builder) => {
  builder.addCase(enableEdCastIntegration.pending, (state) => {
    state.app.edCastConfig.isLoading = true;
  });

  builder.addCase(updateEdCastIntegration.pending, (state) => {
    state.app.edCastConfig.isLoading = true;
  });

  builder.addCase(enableEdCastIntegration.fulfilled, (state, action) => {
    setEdCastConfig(state, action);
  });

  builder.addCase(updateEdCastIntegration.fulfilled, (state, action) => {
    setEdCastConfig(state, action);
  });

  builder.addCase(enableEdCastIntegration.rejected, (state, action) => {
    state.app.edCastConfig.isLoading = false;
  });

  builder.addCase(updateEdCastIntegration.rejected, (state, action) => {
    state.app.edCastConfig.isLoading = false;
  });
});
