/* @ngInject */
export default function LectureVideosManager(
  _,
  CurrentCourseManager,
  CurrentPermissionsManager,
  VideoListLectureComponentModel,
  LectureVideoModel,
  $q,
) {
  const manager = {
    currentLectureVideoList: null,
    currentLectureVideo: null,
    CurrentCourseManager,
    getLectureVideo,
    canViewLockedContent: CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isTeachingAssistant()
       || CurrentPermissionsManager.isCourseBuilder() || CurrentPermissionsManager.isConfigAndRegistrationRole(),
    canEditPrequisites: CurrentPermissionsManager.isCourseBuilder(),
  };

  function getLectureVideo(catalogId, videoListId, videoId, useExisting) {
    let lectureVideo;

    if (useExisting && manager.currentLectureVideoList && !_.isEmpty(manager.currentLectureVideoList.lectureVideos)) {
      lectureVideo = _.findWhere(manager.currentLectureVideoList.lectureVideos, { id: videoId });
    }
    if (lectureVideo) {
      manager.currentLectureVideoList.currentLectureVideo = lectureVideo;
      return $q.when(lectureVideo);
    }
    manager.currentLectureVideoList = null;

    return VideoListLectureComponentModel.get(catalogId, videoListId)
      .then((lectureVideoList) => {
        manager.currentLectureVideoList = lectureVideoList;
        manager.currentLectureVideoList.currentLectureVideo = _.findWhere(manager.currentLectureVideoList.lectureVideos, { id: videoId });

        if (!manager.currentLectureVideoList.currentLectureVideo) {
          manager.currentLectureVideoList.currentLectureVideo = _.first(manager.currentLectureVideoList.lectureVideos);
        }

        return manager.currentLectureVideoList.currentLectureVideo;
      });
  }
  return manager;
}
