import React from 'react';
import { css, jsx } from '@emotion/react';
import { Controller, FieldError, useFormContext, get } from 'react-hook-form';
import AutosizeInput from 'react-input-autosize';

import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import NvPopover from 'shared/components/nv-popover';
import { openSans } from 'styles/global_defaults/fonts';
import useClickOutside from 'shared/hooks/use-click-outside';
import ValidationErrorMessage from 'shared/components/inputs/validation-error-message';
import ClickableContainer from 'components/clickable-container';
import { gray3, gray7, hexToRgbaString } from 'styles/global_defaults/colors';
import {
  halfSpacing,
  doubleSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import ReordererMenu, {
  Props as ReordererMenuProps,
} from 'learning_journeys/components/reorderer-menu';
import { config } from '../../../config/pendo.config.json';

type Props = ReordererMenuProps & {
  value: string,
  onChange: (value: string) => void,
  editable?: boolean,
  className?: string,
  inputError?: FieldError,
};

const CollectionHeader = (props: Props) => {
  const {
    value,
    onChange,
    onDelete,
    onMoveUp,
    canMoveUp,
    className,
    onMoveDown,
    canMoveDown,
    editable = false,
    inputError,
  } = props;

  const inputRef = React.useRef<AutosizeInput>();
  const containerRef = React.useRef<HTMLDivElement>();
  const mostParentRef = React.useRef<HTMLDivElement>();
  const iconContainerRef = React.useRef<HTMLDivElement>();
  const reordererMenuRef = React.useRef<HTMLDivElement>();

  const [maxWidth, setMaxWidth] = React.useState(0);
  const [isEditing, setIsEditing] = React.useState(false);
  const [reordererMenuWidth, setReordererMenuWidth] = React.useState(0);

  const endEdition = () => setIsEditing(false);

  const updateMaxInputWidth = React.useCallback(() => {
    if (isEditing) {
      setMaxWidth(containerRef.current.offsetWidth);
    }
  }, [isEditing]);

  useClickOutside(mostParentRef, endEdition);

  React.useEffect(() => {
    updateMaxInputWidth();
  }, [isEditing, updateMaxInputWidth]);

  React.useEffect(() => {
    window.addEventListener('resize', updateMaxInputWidth);

    return () => window.removeEventListener('resize', updateMaxInputWidth);
  }, [updateMaxInputWidth]);

  React.useLayoutEffect(() => {
    if (editable) {
      const newReordererMenuWidth = reordererMenuRef.current.offsetWidth;

      setReordererMenuWidth(newReordererMenuWidth);
    }
  }, [editable]);

  React.useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const spacingToUse = editable ? reordererMenuWidth : standardSpacing;

  const styles = css`
    display: flex;
    min-height: ${doubleSpacing}px;
    cursor: ${editable ? 'pointer' : 'normal'};
    padding: 0 ${editable ? 0 : spacingToUse}px 0 ${spacingToUse}px;

    ${editable && css`
      &:hover, &.active {
        background-color: ${gray7};
      }
    `}

    .popover-wrapper {
      width: 100%;
    }

    .text-container {
      flex: 1;
      min-width: 0;
      display: block;
      font-size: 24px;
      font-weight: 400;
      position: relative;
      text-align: center;
      font-family: ${openSans};
      line-height: ${doubleSpacing}px;
      color: ${value ? '#000' : gray3};
      white-space: ${isEditing ? 'nowrap' : 'normal'};
      transform: translateX(${isEditing ? 0 : -0.5}px);

      &::after {
        top: 0;
        right: 0;
        content: '';
        height: 100%;
        position: absolute;
        width: ${doubleSpacing}px;
        opacity: ${isEditing ? 1 : 0};
        background: linear-gradient(90deg, transparent 0%, ${hexToRgbaString(gray7, 0.9)} 100%);
      }

      .spacer {
        width: 1px;
        height: 20px;
        display: inline-block;
      }

      .icon-container {
        align-items: center;
        display: inline-flex;
        height: ${doubleSpacing}px;
        padding: 0 ${halfSpacing}px;

        .icon {
          color: ${value ? '#000' : gray3};
        }
      }

      .name-input {
        padding: 0;
        border: none;
        outline: none;
        font-size: 24px;
        background-color: transparent;
        max-width: ${maxWidth ? `${maxWidth}px` : 'none'};
        text-align: inherit;
        font-family: inherit;
        font-weight: inherit;
        line-height: inherit;

        ::placeholder {
          color: ${gray3};
        }
      }
    }
  `;

  const handleContainerClick = () => setIsEditing(true);

  const handleInputRef = (ref) => {
    inputRef.current = ref;
  };

  const handleInputChange = (event) => {
    onChange(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      endEdition();
    }
  };

  const renderViewModeContent = () => (
    <React.Fragment>
      <span className='spacer' />
      {value || t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_NAME_PLACEHOLDER()}
    </React.Fragment>
  );

  const renderEditableContent = () => (isEditing ? (
    <React.Fragment>
      <AutosizeInput
        value={value}
        inputRef={handleInputRef}
        inputClassName='name-input'
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        placeholder={t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_NAME_PLACEHOLDER()}
      />
    </React.Fragment>
  ) : renderViewModeContent());

  return (
    <div
      css={styles}
      ref={mostParentRef}
      className={[isEditing && 'active', className].filter(Boolean).join(' ')}
    >
      <NvPopover
        placement='top'
        show={Boolean(inputError)}
        content={<ValidationErrorMessage text={inputError?.message} />}
      >
        <ClickableContainer
          ref={containerRef}
          disabled={isEditing}
          layoutOnly={!editable}
          className='text-container'
          onClick={handleContainerClick}
          data-qa={config.pendo.learningJourneys.header}
        >
          {editable ? renderEditableContent() : renderViewModeContent()}
        </ClickableContainer>
      </NvPopover>
      {editable && (
        <ReordererMenu
          onDelete={onDelete}
          onMoveUp={onMoveUp}
          canMoveUp={canMoveUp}
          ref={reordererMenuRef}
          onMoveDown={onMoveDown}
          canMoveDown={canMoveDown}
          tooltipText={t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.EDIT_OPTIONS()}
        />
      )}
    </div>
  );
};

type FormCollectionHeaderProps = Omit<Props, 'value' | 'onChange'> & {
  name: string,
  defaultValue?: string,
};

export const FormCollectionHeader = (props: FormCollectionHeaderProps) => {
  const {
    name,
    defaultValue,
    ...restProps
  } = props;

  const { formState } = useFormContext();

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ field: { value, onChange } }) => (
        <CollectionHeader
          {...restProps}
          value={value}
          onChange={onChange}
          inputError={get(formState.errors, name)}
        />
      )}
    />
  );
};

export default CollectionHeader;
