import { css } from '@emotion/react';
import t from 'react-translate';

import { CourseObjectBookmark, AttachmentBookmarkComponent, BookmarkType } from 'redux/schemas/models/bookmark';
import { gray2, gray4, gray7, primary, teal, warning, yellow } from 'styles/global_defaults/colors';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import { standardSpacing, doubleSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import { handheld } from 'styles/global_defaults/media-queries';
import { config } from '../../../config/config.json';
import { config as pendoConfig } from '../../../config/pendo.config.json';

import LecturePageSnippet from './snippets/lecture-page-snippet';
import SubmissionSnippet from './snippets/submission-snippet';
import DiscussionSnippet from './snippets/discussion-snippet';
import CommentReplySnippet from './snippets/comment-reply-snippet';
import AttachmentSnippet from './snippets/attachment-snippet';
import ImageSnippet from './snippets/image-snippet';
import VideoSnippet from './snippets/video-snippet';
import AudioSnippet from './snippets/audio-snippet';

const getIcon = (type: BookmarkType) => {
  switch (type) {
    case BookmarkType.LECTURE_PAGE:
      return 'course';
    case BookmarkType.IMAGE:
      return 'images';
    case BookmarkType.VIDEO:
      return 'video';
    case BookmarkType.AUDIO:
      return 'audio';
    case BookmarkType.SUBMISSION:
      return 'gallery';
    case BookmarkType.TOPIC:
      return 'conversations';
    case BookmarkType.POST:
    case BookmarkType.COMMENT:
      return 'comments';
    default:
      return null;
  }
};

const getSnippet = (bookmark: CourseObjectBookmark) => {
  switch (bookmark.type) {
    case BookmarkType.LECTURE_PAGE:
      return <LecturePageSnippet bookmark={bookmark} />;
    case BookmarkType.TOPIC:
      return <DiscussionSnippet bookmark={bookmark} />;
    case BookmarkType.POST:
    case BookmarkType.COMMENT:
      return <CommentReplySnippet bookmark={bookmark} />;
    case BookmarkType.SUBMISSION:
      return <SubmissionSnippet bookmark={bookmark} />;
    case BookmarkType.ATTACHMENT:
      return <AttachmentSnippet bookmark={bookmark} />;
    case BookmarkType.IMAGE:
      return <ImageSnippet bookmark={bookmark} />;
    case BookmarkType.VIDEO:
      return <VideoSnippet bookmark={bookmark} />;
    case BookmarkType.AUDIO:
      return <AudioSnippet bookmark={bookmark} />;
    default:
      return null;
  }
};

type BookmarkSnippetProps = {
  bookmark: CourseObjectBookmark;
};

const BookmarkSnippet = ({
  bookmark,
}: BookmarkSnippetProps) => {
  const { type, component } = bookmark;

  const styles = css`
    &.bookmark-snippet {
      display: block;
      padding: ${standardSpacing}px 80px;

      ${handheld(css`
        padding: ${standardSpacing}px 40px;
      `)};

      .snippet-body {
        display: flex;
        align-items: center;
        position: relative;
        min-height: ${doubleSpacing * 2}px;
        border-radius: ${quarterSpacing}px;

        ${component ? css`
          box-shadow: 0px 3px 10px 5px rgba(29,33,38,0.1);
          -webkit-box-shadow: 0px 3px 10px 5px rgba(29,33,38,0.1);
          -moz-box-shadow: 0px 3px 10px 5px rgba(29,33,38,0.1);
        ` : css`
          background-color: ${gray7};
          padding: ${standardSpacing}px ${doubleSpacing}px;

          .text-body {
            color: ${gray2} !important;
          }
        `};

        & > * {
          width: 100%;
        }

        .pin-content {
          position: absolute;
          top: -${standardSpacing}px;
          left: -${standardSpacing}px;
          width: 45px;
          height: 45px;

          &.external-link{
            &.ppt {
              background-color: ${warning};
            }

            &.acrobat {
              background-color: #FF2956;
            }

            &.xls {
              background-color: #8AC249;
            }

            &.menu {
              background-color: #39B0E5;
            }

            &.video {
              background-color: ${teal};
            }

            &.audio {
              background-color: ${yellow};
            }

            &.images {
              background-color: ${primary};
            }

            &.zip {
              background-color: ${gray2};
            }

            &.srt {
              background-color: #8DC9CD;
            }
          }

          &.icon-wrapper {
            background-color: ${primary};
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            &.gray {
              background-color: ${gray4};
            }
          }

        }
      }
    }
  `;

  const getExternalFileImage = (fileType) => {
    if (config.files.pdfs.split(',').includes(fileType)) {
      return 'acrobat';
    } if (config.files.excels.split(',').includes(fileType)) {
      return 'xls';
    } if (config.files.documents.split(',').includes(fileType)) {
      return 'menu';
    } if (config.files.presentations.split(',').includes(fileType)) {
      return 'ppt';
    } if (config.files.videos.split(',').includes(fileType)) {
      return 'video';
    } if (config.files.images.split(',').includes(fileType)) {
      return 'images';
    } if (config.files.audios.split(',').includes(fileType)) {
      return 'audio';
    } if (config.files.zips.split(',').includes(fileType)) {
      return 'zip';
    } if (config.files.subtitles.split(',').includes(fileType)) {
      return 'srt';
    }

    return 'srt';
  };

  let pinContent;
  if (!component) {
    pinContent = (
      <div className='pin-content icon-wrapper gray'>
        <NvIcon icon='trash' size='medium' />
      </div>
    );
  } else if (type === BookmarkType.ATTACHMENT) {
    const { file } = component as AttachmentBookmarkComponent;

    const fileType = getExternalFileImage(file.type);

    pinContent = (
      <div className={`pin-content icon-wrapper external-link ${fileType} ${component.expired ? 'gray' : ''}`}>
        <NvIcon icon={fileType} size='medium' />
      </div>
    );
  } else {
    const icon = getIcon(type);

    pinContent = (
      <div className={`pin-content icon-wrapper ${icon} ${component.expired ? 'gray' : ''}`}>
        <NvIcon icon={icon} size='medium' />
      </div>
    );
  }

  const containsSnippet = type !== BookmarkType.COURSE && type !== BookmarkType.INSTITUTION;

  if (containsSnippet) {
    return (
      <div className='bookmark-snippet' css={styles} data-qa={pendoConfig.pendo.bookmarks.bookmarkSnippet}>
        <NvTooltip text={t.LHS.BOOKMARKS.EXPIRED()} enabled={component?.expired || false}>
          <div className='snippet-body'>
            {pinContent}
            {component
              ? getSnippet(bookmark)
              : <div className='text-body'>{t.LHS.BOOKMARKS.NO_CONTENT()}</div>}
          </div>
        </NvTooltip>
      </div>
    );
  }

  return null;
};

export default BookmarkSnippet;
