/* @ngInject */
export default function AnnouncementsManager(

  AnnouncementModel,
  AnnouncementsResources,
  UserCoursesResource,
  CurrentUserManager,
  CurrentCourseManager,
  OfflineManager,
  $q,
  _,
  $timeout,
  moment,
) {
  let requestAnnouncementForCourseResource;
  let requestNumUnreadAnnouncementsByCourseResource;

  const _this = {
    initialize,
    newAnnouncement,
    reload,
    announcements: [],
    numUnreadAnnouncements: null,
    courseIdToNumUnreadAnnouncements: {},
    requestAnnouncementsForCourse,
    resetAnnouncements,
    markAnnouncementsAsReadForCourse,
    currentCourse,

    requestAdminAnnouncements,
    save,
    deleteAnnouncement,
  };
  let currentCourseId = null;
  let shouldReloadAnnouncements = true;

  $timeout(() => {
    initialize();

    OfflineManager.registerReturnOnlineCallback(reload);
  });

  // this is not a real initalize as it can be reinitalized multiple times
  function initialize() {
    const promises = [];
    const currentCourseManagerCourseId = CurrentCourseManager.course?.id;

    if (CurrentUserManager.hasLoggedIn() && (currentCourseId !== currentCourseManagerCourseId || shouldReloadAnnouncements)) {
      promises.push(requestNumUnreadAnnouncementsByCourse());
      if (CurrentCourseManager.course && CurrentUserManager.hasEnrolledInCourse(CurrentCourseManager.course)) {
        promises.push(requestAnnouncementsForCourse(CurrentCourseManager.course));
      } else {
        promises.push(resetAnnouncements());
      }

      return $q.all(promises);
    }
    return $q.when();
  }

  function newAnnouncement(announcementId, courseId) {
    if (_this.courseIdToNumUnreadAnnouncements[courseId] != null) {
      _this.courseIdToNumUnreadAnnouncements[courseId] += 1;

      if (_this.numUnreadAnnouncements != null) {
        _this.numUnreadAnnouncements += 1;
      } else {
        _this.numUnreadAnnouncements = 1;
      }
    }
    if (currentCourseId === courseId) {
      shouldReloadAnnouncements = true;
    }
  }

  // for when you are already viewing announcements in a class
  function reload() {
    const promises = [];

    promises.push(requestNumUnreadAnnouncementsByCourse());
    if (currentCourse()) {
      promises.push(requestAnnouncementsForCourse(currentCourse()));
    }

    return $q.all(promises);
  }

  function requestAnnouncementsForCourse(course) {
    shouldReloadAnnouncements = false;
    currentCourseId = course.id;

    if (requestAnnouncementForCourseResource) {
      requestAnnouncementForCourseResource.$cancelRequest();
    }

    requestAnnouncementForCourseResource = AnnouncementModel.getResourse(course.catalogId, false);

    requestAnnouncementForCourseResource.$promise = requestAnnouncementForCourseResource.$promise.then((announcements) => {
      _this.announcements = announcements;
    });

    return requestAnnouncementForCourseResource.$promise;
  }

  function requestNumUnreadAnnouncementsByCourse() {
    if (requestNumUnreadAnnouncementsByCourseResource) {
      requestNumUnreadAnnouncementsByCourseResource.$cancelRequest();
    }

    requestNumUnreadAnnouncementsByCourseResource = AnnouncementsResources.numUnreadAnnouncementsByCourse(
      {},
      (resource) => {
        _this.numUnreadAnnouncements = resource.result.totalUnreadCount;
        _this.courseIdToNumUnreadAnnouncements = {};
        _.each(resource.result.coursesToUnreadCount, (course) => {
          _this.courseIdToNumUnreadAnnouncements[course.id] = course.unreadCount;
        });
      },
    );

    return requestNumUnreadAnnouncementsByCourseResource.$promise;
  }

  function markAnnouncementsAsReadForCourse() {
    const deferred = $q.defer();

    if (_this.announcements.length > 0 && _this.courseIdToNumUnreadAnnouncements[currentCourse().id] > 0) {
      UserCoursesResource.update({
        id: CurrentUserManager.courseIdToUserCourseHash[currentCourse().id].id,
        lastSeenAnnouncementId: _this.announcements[0].id,
      }).$promise.then(() => {
        _.each(_this.announcements, (announcement) => {
          announcement.unread = false;
        });

        requestNumUnreadAnnouncementsByCourse().then(() => {
          deferred.resolve();
        });
      });
    } else {
      deferred.reject();
    }

    return deferred.promise;
  }

  function resetAnnouncements() {
    shouldReloadAnnouncements = false;
    _this.announcements = [];
    currentCourseId = null;
  }

  function currentCourse() {
    return CurrentUserManager.coursesHash[currentCourseId];
  }

  /* Admin Functions */
  function requestAdminAnnouncements(catalogId) {
    _this.adminAnnouncements = null;

    return AnnouncementModel.get(catalogId)
      .then((announcements) => {
        _this.adminAnnouncements = announcements;
        sortAdminAnnouncements();
      });
  }

  function save(announcement) {
    const isExisting = !!announcement.id;

    return announcement.save()
      .then(() => {
        let returnAnnouncement;

        if (isExisting) {
          const original = announcement.originalAnnouncement;
          original.copy(announcement);
          original.originalAnnouncement = null;

          returnAnnouncement = original;
        } else {
          _this.adminAnnouncements.push(announcement);

          returnAnnouncement = announcement;
        }

        sortAdminAnnouncements();
        return returnAnnouncement;
      });
  }

  function deleteAnnouncement(announcement) {
    _this.adminAnnouncements.splice(_.indexOf(_this.adminAnnouncements, announcement), 1);
    return announcement.delete();
  }

  function sortAdminAnnouncements() {
    _this.adminAnnouncements = _.sortBy(_this.adminAnnouncements, (announcement) => -moment(announcement.scheduledDate).valueOf());

    return _this.adminAnnouncements;
  }

  return _this;
}
