import { schema } from 'normalizr';

import { Reply, RepliesNormalized } from 'redux/schemas/models/reply';

export interface RemoveNewReplyId {
  replyId: number;
}
export interface FetchRepliesRequest{
  catalogId: string;
  commentId: number;
  beforeId?: number;
  pageSize?: number;
  vieweeLastActivity?: string;
}

export interface FetchRepliesResponse {
  additionalCommentsAfterCount: number;
  additionalCommentsBeforeCount: number;
  comments: Reply[];
}

export const FetchRepliesSchema = new schema.Object({
  comments: new schema.Array(new schema.Entity<Reply>('replies', {}, {
    processStrategy: (value) => ({
      ...value,
      likers: [],
    }),
  })),
});

export interface FetchRepliesEntities {
  replies: RepliesNormalized;
}

export interface FetchRepliesNormalizedResult {
  comments: number[];
}

export interface CreateReplyRequest {
  catalogId: string;
  commentId: number;
  comment: string;
  reply?: boolean;
  mentionedIds?: number[];
  postId?: number;
  userId?: number;
  reportId?: number;
  callback?: Function;
}

export interface DeleteReplyRequest {
  commentId: number;
  catalogId: string;
  replyId: number;
  userId?: number;
  postId?: number;
  reportId?: number;
  callback?: Function;
}

export interface UpdateReplyRequest {
  catalogId: string;
  replyId: number;
  comment: string;
  reply?: boolean;
  mentionedIds?: number[];
}

export interface LikeReplyRequest {
  catalogId: string;
  replyId: number;
}

export interface LikeResponse {
  numLikes: number;
}

interface AddReply {
  commentId: number;
  catalogId: string;
  postId?: number;
  userId?: number;
}
export interface AddReplyRequest extends AddReply {
  replyId: number;
  reportId?: number;
}

export interface AddReplyResponse extends AddReply {
  reply: Reply;
}

export interface EditReplyRequest {
  catalogId: string
  replyId: number;
}
export interface EditReplyResponse {
  reply: Reply;
}

export interface RemoveReply {
  replyId: number;
  commentId: number;
  catalogId: string;
  postId?: number;
  userId?: number;
  reportId?: number;
}

export interface VoteReply {
  replyId: number;
  numLikes: number;
  catalogId: string;
}

export interface GetReplyLikersInfoRequest {
  catalogId: string;
  replyId: number;
  page: number;
}

export interface TranslateReplyRequest {
  catalogId: string;
  replyId: number;
  language: string;
}
