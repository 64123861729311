import React from 'react';

import NvSavingOverlay from 'shared/components/nv-saving-overlay';
import NvConfirmationOverlay from 'shared/components/nv-confirmation-overlay';
import AlertMessagesPanel from 'shared/components/alert-messages/alert-messages-panel';
import UpdateEmailAlert from 'shared/components/update-email-alert';

/**
 * Here we are going to render components that can be widely used across the app
 * and only a single instance should exist
 */
const GlobalComponents = () => (
  <React.Fragment>
    <NvSavingOverlay />
    <AlertMessagesPanel />
    <NvConfirmationOverlay />
    <UpdateEmailAlert />
  </React.Fragment>
);


export default GlobalComponents;
