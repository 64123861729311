/* @ngInject */
export default function RepliesResources(

  $resource,
) {
  return $resource(
    '/:catalogId/posts/:commentId/comments/:id',
    { },
    {
      // index actions
      getReplies: { method: 'GET' },

      // single post actions
      getSingleReply: { method: 'GET', url: '/:catalogId/comments/:id' },
      create: { method: 'POST', url: '/:catalogId/posts/:commentId/comments' },
      update: { method: 'PUT', url: '/:catalogId/comments/:id' },
      remove: { method: 'DELETE', url: '/:catalogId/comments/:id' },
      report: { method: 'POST', url: '/:catalogId/flaggings?flag=abuse&flaggable_id=:id&flaggable_type=Comment' },
      like: { method: 'POST', url: '/:catalogId/comments/:id/voteup' },
      unlike: { method: 'POST', url: '/:catalogId/comments/:id/votedown' },
      getLikersInfo: { method: 'GET', url: '/:catalogId/comments/:id/voters?page=:page' },
      translate: { method: 'POST', url: '/:catalogId/comments/:id/translate' },
    },
  );
}
