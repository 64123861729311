import { css, jsx } from '@emotion/react';
import { gray6, gray7 } from 'styles/global_defaults/colors';
import { halfSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { tablet } from 'styles/global_defaults/media-queries';

const GALLERY_CARD_DIMENSION = 240;
const GALLERY_CARD_TABLET_WIDTH = 216;

const styles = css`
  height: ${GALLERY_CARD_DIMENSION}px;
  width: ${GALLERY_CARD_DIMENSION}px;
  ${tablet(css`
    width: ${GALLERY_CARD_TABLET_WIDTH}px;
  `)};
  box-shadow: 0px 10px 30px 0px ${gray6};

  @keyframes shineAnimation {
    0% {
      background-position: 0% 50%;
    }

    100% {
      background-position: -100% 50%;
    }
  }

  .upper-part {
    flex:1;
    background: ${gray6}
  }
  .lower-part {
    flex:1;
  }

  .loading {
    background: linear-gradient(90deg, ${gray7} 35%, ${gray6} 45%, ${gray7} 55%);
    background-size: 600% 600%;
    animation: shineAnimation 1.5s infinite;
  }

  .placeholder-bar {
    width: 160px;
    height: ${threeQuartersSpacing}px;
  }
  .placeholder-circle {
    width: ${threeQuartersSpacing}px;
    height: ${threeQuartersSpacing}px;
    border-radius: ${halfSpacing}px;
  }
  .short-bar {
    width: 104px;
    height: 12px;
  }
`;

const NvGalleryCardPlaceholder = () => (
  <div css={styles} className='d-flex flex-column'>
    <div className='upper-part' />
    <div className='lower-part'>
      <div className='d-flex mt-3 ml-4 mb-2'>
        <div className='loading placeholder-bar ml-auto' />
        <div className='loading placeholder-circle mr-auto ml-2' />
      </div>
      <div className='loading short-bar mt-2 mb-4 mx-auto ' />
      <div className='loading placeholder-bar mx-auto' />
    </div>
  </div>
);

export default NvGalleryCardPlaceholder;
