import NvUserAvatar from 'components/nv-user-avatar';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { DiscussionTranslation, RootState } from 'redux/schemas';
import { Comment } from 'redux/schemas/models/comment';

import { handheld, tablet } from 'styles/global_defaults/media-queries';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { config } from '../../../../config/pendo.config.json';
import CommentReplies from './comment-replies';
import CommentLikes from './comment-likes';
import CommentActions from './comment-actions';
import CommentPost from './comment-post';
import CommentForm from './comment-form';
import { CommentsContainerContext } from './comments-container';
import NvIcon from '../nv-icon';
import TranslationButton from 'components/translation-button';
import { getCommentTranslationById, getReplyTranslationById } from 'redux/selectors/discussion-translations';
import { useAppDispatch } from 'redux/store';
import { translateComment } from 'redux/actions/comments';
import { getCurrentUser } from 'redux/selectors/users';
import { extractTextFromHTML } from 'shared/utils';
import { translateReply } from 'redux/actions/replies';

type CommentRowProps = {
  commentId: number,
  parentCommentId?: number,
};

type CommentRowContextValues = {
  comment: Comment,
  parentCommentId?: number,
  isReply: boolean,
};

export const CommentRowContext = React.createContext<CommentRowContextValues>(null);

const CommentRow = ({ commentId, parentCommentId }: CommentRowProps) => {
  const styles = css`
    .show-on-hover {
      visibility: hidden;

      ${handheld(css`
        visibility: visible;
      `)};

      ${tablet(css`
        visibility: visible;
      `)};
    }

    &:hover {
      .show-on-hover {
        visibility: visible;
      }
    }

    .highlight-comment {
      width: ${standardSpacing}px;
      height: 25px;
      right: -${standardSpacing}px;
    }

    .social-row-items {
      gap: ${standardSpacing}px;
    }
  `;

  const isReply = !!parentCommentId;
  const parentComment: Comment = useSelector((state: RootState) => state.models.comments[commentId]);
  const reply: Comment = useSelector((state: RootState) => state.models.replies[commentId]);

  const { catalogId, ownerType, ownerId } = useContext(CommentsContainerContext);

  const commentFormRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);

  // If parent is present then this is a reply or else it is the parentComment
  const comment = isReply ? reply : parentComment;

  const dispatch = useAppDispatch();
  const selectItemById = isReply ? getReplyTranslationById : getCommentTranslationById;
  const {
    isLoading: isTranslationLoading,
    error: hasTranslationFailed
  }: DiscussionTranslation =
    useSelector(selectItemById(comment.id)) || {};
  const [showTranslatedItem, setShowTranslatedItem] = useState<boolean>(
    false
  );

  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    if (hasTranslationFailed) setShowTranslatedItem(false);
  }, [hasTranslationFailed]);

  const onTranslationToggle = (isTranslationOn: boolean, language: string) => {
    setShowTranslatedItem(isTranslationOn);
    if (isTranslationOn) {
      dispatch(
        isReply
          ? translateReply({ catalogId, replyId: comment.id, language })
          : translateComment({ catalogId, commentId: comment.id, language })
      );
    }
  };

  if (isEditing) {
    return (
      <CommentForm
        ref={commentFormRef}
        catalogId={catalogId}
        ownerType={ownerType}
        ownerId={ownerId}
        comment={comment}
        parentCommentId={isReply ? commentId : null}
        onComment={() => setIsEditing(false)}
        onCancel={() => setIsEditing(false)}
      />
    );
  }

  return (
    <CommentRowContext.Provider
      value={{
        comment,
        parentCommentId,
        isReply,
      }}
    >
      <div css={styles} className='w-100 d-flex mb-3 position-relative'>
        <div className='d-none d-md-block comment-avatar'>
          <NvUserAvatar
            displayName={!parentCommentId}
            displayRoleBadge={false}
            alignNameRight={false}
            user={comment.user}
            size='sm'
            borderType='round'
            className='comment-avatar'
            dataQa={config.pendo.userProfiles.openProfile}
          />
        </div>
        <div className='w-100 pb-3 position-relative'>
          <div className='d-block d-md-none comment-avatar mb-3'>
            <NvUserAvatar
              displayName={!parentCommentId}
              displayRoleBadge={false}
              alignNameRight
              user={comment.user}
              size='sm'
              borderType='round'
              dataQa={config.pendo.userProfiles.openProfile}
            />
          </div>
          <div className='actions-wrapper w-100 d-flex justify-content-end position-absolute'>
            <CommentActions
              setIsEditing={setIsEditing}
            />
          </div>
          <div className="w-100">
            <CommentPost isReply={isReply} showTranslatedComment={showTranslatedItem} />
          </div>
          <div className="w-100 d-flex  mt-8 mb-3 align-items-center justify-content-between">
            <div className="d-flex social-row-items">
              <CommentLikes />
              <CommentReplies />
              <TranslationButton
                onToggle={onTranslationToggle}
                isToggled={showTranslatedItem}
                isLoading={isTranslationLoading}
                isVisible={comment.user.id !== currentUser.id}
                dataQa={config.pendo.submissionView.translationButton}
                sourceText={extractTextFromHTML(comment.body)}
              />
            </div>
            <div className='text-date text-gray-2'>
              {moment(comment.createdAt).format('lll')}
            </div>
          </div>
        </div>
        {comment.highlighted && (
          <div className='highlight-comment position-absolute d-flex align-items-center justify-content-center bg-warning mt-1'>
            <NvIcon
              icon='highlight'
              size='xss-smallest'
              className='text-white'
            />
          </div>
        )}
      </div>
    </CommentRowContext.Provider>
  );
};

export default CommentRow;
