/* @ngInject */
export default function HighlightPostModalInstanceController(
  DiscussionsManager,
  $uibModalInstance,
  post,
  _,
) {
  const vm = this;

  vm.highlightInfo = {
    startDate: post ? post.highlightedFrom : null,
    endDate: post ? post.highlightedTo : null,
  };

  vm.submitting = false;

  vm.submitHighlight = function () {
    vm.submitting = true;
    post.highlight(_.extend(vm.highlightInfo, { updating: !!post.highlightedNow })).then(() => {
      $uibModalInstance.close();
    });
  };
}
