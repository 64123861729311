/* @ngInject */
export default function NvReportSection() {
  return {
    restrict: 'A',
    scope: {
      reportSection: '=',
      templateSection: '=',
    },
    templateUrl: 'reports/report-sections/templates/input-element.html',
  };
}
