import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import {
  HCMDownloadTemplatePayload,
  HCMEndpoint,
  HCMIntegrationTypes,
  HCMServices,
  HCMSettingsPayload,
  HCMUploadCSVPayload,
} from 'redux/schemas/models/hcm';
import t from '../../react-translate';
import { addAlertMessage } from './alert-messages';

enum SuccessMessage {
  ENABLE = 'SUCCESS_MESSAGE',
  UPDATE = 'SUCCESS_UPDATE_MESSAGE',
  DISABLE = 'SUCCESS_DISABLE_MESSAGE',
}

const getMessageKey = (update: boolean, disable: boolean) => {
  let messageKey = SuccessMessage.ENABLE;
  if (update) messageKey = SuccessMessage.UPDATE;
  else if (disable) messageKey = SuccessMessage.DISABLE;
  return messageKey;
};

const requestEndpoint = (endpoint: HCMEndpoint, extra: any = {}) => {
  const { institutionId, service, method = 'get', data = {} } = endpoint;
  const api = `institutions/${institutionId}/hcm/`;
  const url = `${api}${service}.json`;
  const requestMethod = axios[method];
  return requestMethod(url, data, extra).then(response => response.data.result);
};

const configHCM = (
  payload: HCMSettingsPayload,
  update: boolean = false,
  disable: boolean = false,
  thunkAPI: any,
) => {
  const {
    institutionId,
    endpoint: endPoint,
    username,
    password,
    integrationType,
  } = payload;
  const service = integrationType === HCMIntegrationTypes.CSV_SFTP ? HCMServices.INTEGRATION_TYPE : HCMServices.CONFIG_HCM;
  const method = update ? 'put' : 'post';
  const data = { institutionSettings: { end_point: endPoint, username, password, integrationType } };
  const request = requestEndpoint({ service, method, institutionId, data });
  return request.then(() => {
    const messageKey = getMessageKey(update, disable);
    const message = t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL[messageKey]();

    thunkAPI.dispatch(addAlertMessage({
      type: AlertMessageType.SUCCESS,
      header: t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.SUCCESS_HEADER(),
      message,
    }));
  }).catch((e) => {
    thunkAPI.dispatch(addAlertMessage({
      type: AlertMessageType.ERROR,
      header: t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.ERROR_HEADER(),
      message: t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.ERROR_MESSAGE(),
    }));
    throw (e.response?.data?.error);
  });
};

const uploadCSV = (payload: HCMUploadCSVPayload, thunkAPI) => {
  const { institutionId, data } = payload;
  const service = HCMServices.UPLOAD_CSV;
  const method = 'post';
  const formData = new FormData();
  formData.append('csv_file', data[0]);
  const request = requestEndpoint({ service, method, institutionId, data: formData }, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return request.then(() => {
    thunkAPI.dispatch(addAlertMessage({
      type: AlertMessageType.SUCCESS,
      header: t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.DATA_MAPPING_MODAL.SUCCESS_HEADER(),
      message: t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.DATA_MAPPING_MODAL.SUCCESS_MESSAGE(),
    }));
  }).catch(() => {
    thunkAPI.dispatch(addAlertMessage({
      type: AlertMessageType.ERROR,
      header: t.FORM.OOPS(),
      message: t.FORM.ERROR_SOMETHING_WRONG(),
    }));
  });
};

const downloadCSV = (payload: HCMDownloadTemplatePayload, thunkAPI) => {
  const { institutionId } = payload;
  const service = HCMServices.DOWNLOAD_TEMPLATE;
  const request = requestEndpoint({ service, institutionId });
  return request.catch(() => {
    thunkAPI.dispatch(addAlertMessage({
      type: AlertMessageType.ERROR,
      header: t.FORM.OOPS(),
      message: t.FORM.ERROR_SOMETHING_WRONG(),
    }));
  });
};

const enableHCM = (payload: HCMSettingsPayload, thunkAPI) => configHCM(payload, false, false, thunkAPI);

const updateHCM = (payload: HCMSettingsPayload, thunkAPI) => configHCM(payload, true, false, thunkAPI);

const disableHCM = (payload: HCMSettingsPayload, thunkAPI) => configHCM(payload, false, true, thunkAPI);

export const uploadHCMCSV = createAsyncThunk('UPLOAD_HCM_CSV', uploadCSV);

export const enableHCMIntegration = createAsyncThunk('ENABLE_HCM', enableHCM);

export const updateHCMIntegration = createAsyncThunk('UPDATE_HCM', updateHCM);

export const disableHCMIntegration = createAsyncThunk('UPDATE_HCM', disableHCM);

export const downloadHCMTemplate = createAsyncThunk('DOWNLOAD_HCM_TEMPLATE', downloadCSV);
