/* eslint-disable react/require-default-props */
import React from 'react';
import { css } from '@emotion/react';
import NvIcon from 'shared/components/nv-icon';
import {
  gray2,
  gray4,
  gray5,
  gray7,
  hexToRgbaString,
  primary,
} from 'styles/global_defaults/colors';
import useClickOutside from 'shared/hooks/use-click-outside';
import { halfSpacing, largeSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import { mergeRefs } from 'shared/react-utils';
import NvTooltip from 'shared/components/nv-tooltip';

interface RowActionMenu {
  children: React.ReactNode;
  color?: string;
  className?: string;
  tooltipText?: string;
  rowMenuColor?: string;
}

const grayWithAlpha = hexToRgbaString(gray7, 0.95);

const baseButtonStyle = css`
  height: ${largeSpacing}px;
  background-color: transparent;
  padding: ${quarterSpacing}px;
  display: inline-flex;
  align-items: center;
  border: none;
  cursor: pointer;

  body:not(.keyboard_user) &:focus {
    outline: none;
  }
`;

const collapsibleRowMenuButtonStyle = css`
  ${baseButtonStyle};
  margin-left: ${quarterSpacing}px;

  i {
    color: ${primary};
  }

  &[disabled] i {
    color: ${gray4};
    cursor: auto;
  }
`;

const toggleButtonStyle = css`
  ${baseButtonStyle};

  i {
    /* rotate the "3 dots" icon to be vertical */
    transform: rotate(90deg);
  }
`;

const containerStyle = css`
  display: flex;
  align-items: center;
  position: relative;
  padding-right: ${quarterSpacing}px;
`;

const menuContainer = css`
  position: absolute;
  top: 0;
  bottom: 0;
  transform: translateX(-100%);
  display: flex;
  padding: 0 ${quarterSpacing}px 0 ${halfSpacing}px;
  align-items: center;
`;

const CollapsibleRowMenu = React.forwardRef<HTMLDivElement, RowActionMenu>((props, ref) => {
  const {
    children,
    className,
    tooltipText,
    color = gray2,
    rowMenuColor = grayWithAlpha,
  } = props;
  const [isExpanded, setIsExpanded] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>();
  useClickOutside(containerRef, onCollapse);

  // #region handlers
  function onCollapse() {
    setIsExpanded(false);
  }

  function onToggle() {
    setIsExpanded((currentState) => !currentState);
  }
  // #endregion

  const computedContainerStyle = css`
    ${containerStyle};
    background-color: ${isExpanded ? rowMenuColor : 'transparent'};
  `;

  const computedMenuContainerStyle = css`
    ${menuContainer};
    background-color: ${rowMenuColor};
    display: ${isExpanded ? undefined : 'none'};
  `;

  const computedToggleButtonStyle = css`
    ${toggleButtonStyle}
    color: ${color};
  `;

  return (
    <div
      className={className}
      css={computedContainerStyle}
      ref={mergeRefs(ref, containerRef)}
    >
      <div css={computedMenuContainerStyle}>{children}</div>
      <NvTooltip text={tooltipText} enabled={!!tooltipText && !isExpanded}>
        <button css={computedToggleButtonStyle} type='button' onClick={onToggle}>
          <NvIcon size='smallest' icon='in-progress' />
        </button>
      </NvTooltip>
    </div>
  );
});

interface CollapsibleRowMenuButtonProps extends React.ComponentProps<'button'> {
  icon: string;
  tooltipText?: string,
}

/* @ngInject */
export function CollapsibleRowMenuButton(props: CollapsibleRowMenuButtonProps) {
  const { icon, tooltipText, ...rest } = props;
  return (
    <NvTooltip text={tooltipText} enabled={!!tooltipText}>
      <button css={collapsibleRowMenuButtonStyle} type='button' {...rest}>
        <NvIcon size='smallest' icon={icon} />
      </button>
    </NvTooltip>
  );
}

const dividerStyles = css`
  width: 1px;
  height: ${largeSpacing}px;
  background-color: ${gray5};
  margin: 0 ${quarterSpacing}px 0 ${halfSpacing}px;
`;

export const CollapsibleRowMenuDivider = () => <span css={dividerStyles} />;

export default CollapsibleRowMenu;
