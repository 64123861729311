
import angular from 'angular';
import { BookmarkType } from 'redux/schemas/models/bookmark';
import { getMaximumWordsOfElementById } from 'shared/services/nv-util';

/* @ngInject */
export default function NvDiscussionReply(
  $timeout,
  $uibModal,
  $uibModalStack,
  $uibPosition,
  $stateParams,
  CurrentUserManager,
  DiscussionsManager,
  ScrollFocusConnectorFactory,
  TeamWorkspaceManager,
  InstitutionsManager,
  BookmarkModel,
  config,
  moment,
  nvUtil,
  $state,
) {
  return {
    restrict: 'A',
    scope: {
      archiveMode: '=?',
      context: '@',
      directLinkInfo: '=',
      hideDirectLink: '<',
      readonlyMode: '=?', // for discussion contributions
      reply: '=',
      report: '=?',
      onReplyReady: '&?',
      inModal: '<?',
      pendoTag: '@?',
    },
    require: ['nvDiscussionReply', '^nvDiscussionComment'],
    controller: function ctrl() {
'ngInject';
      const vm = this;
      let replyBodyCopy;

      this.config = config;
      this.currentUserManager = CurrentUserManager;
      this.discussionsManager = DiscussionsManager;
      this.TeamWorkspaceManager = TeamWorkspaceManager;
      this.institutionsManager = InstitutionsManager;
      this.editing = false;
      this.moment = moment;
      this.showInstructionsModal = showInstructionsModal;
      this.adminOptionsVisible = CurrentUserManager.isInstructorForCurrentCourse() || CurrentUserManager.isTeachingAssistantForCurrentCourse();
      this.nvUtil = nvUtil;
      this.$state = $state;

      this.onCompile = function () {
        if (this.onReplyReady) {
          this.onReplyReady();
        }
        $timeout(() => {
          this.setShortComment();
        });
      };

      this.updateReply = function () {
        replyBodyCopy = this.reply.body;
        this.editing = true;
      };

      this.cancelUpdateReply = function () {
        this.reply.body = replyBodyCopy;
        this.editing = false;
      };

      this.afterReplyEdit = function () {
        if (this.editing) {
          this.editing = false;
        }
      };

      this.toggleLikes = function () {
        this.reply.liked = !this.reply.liked;

        if (this.reply.liked) {
          this.reply.like();
        } else {
          this.reply.unlike();
        }
      };

      this.closeModal = function () {
        $uibModalStack.dismissAll('cancel');
      };

      this.directlyLinked = this.directLinkInfo?.replyId === this.reply.id;

      this.canDelete = function () {
        if (this.context === 'workspace' || this.context === 'workspaceDirectLink') {
          return this.reply.belongsToCurrentUser() || this.adminOptionsVisible;
        }

        return (this.reply.belongsToCurrentUser()
            && !this.discussionsManager.topicIsLocked(this.reply.owner.owner.forum))
          || this.adminOptionsVisible;
      };

      this.canEdit = function () {
        return !this.institutionsManager.institution.disableDiscussionEdits
          && this.canDelete(); // checking other conditions
      };

      this.createBookmark = function () {
        BookmarkModel.createBookmark({
          type: BookmarkType.COMMENT,
          catalogId: $stateParams.catalogId,
          componentId: this.reply.id,
        })
          .then(bookmark => {
            this.reply.bookmarkId = bookmark.id;
          });
      };

      this.highlightBookmark = function () {
        BookmarkModel.highlightBookmark(this.reply.bookmarkId);
      };

      function showInstructionsModal() {
        $uibModal.open({
          templateUrl: 'submissions/templates/informal-feedback-modal.html',
          windowClass: 'informal-feedback-modal',
          controller: 'InformalFeedbackModalCtrl as vm',
          resolve: {
            currentExercise: vm.report.exercise,
            currentPeerEvaluation: this.report.exercise.customQuestions,
          },
        });
      }

      this.setShortComment = function () {
        this.reply.shortComment = getMaximumWordsOfElementById(`reply_${this.reply.id}`);
      };

      this.getShortComment = function () {
        return this.reply.shortComment;
      };
    },
    link(scope, elem, attrs, ctrls) {
      const [replyCtrl, commentCtrl] = ctrls;

      // Scrolling to reply when entire discussion content is loaded to make
      // sure scroll is accurate. Reference: NOV-73717
      scope.$on('discussion-thread-ready', () => {
        if (replyCtrl.directlyLinked) {
          ScrollFocusConnectorFactory.scrollTo(null, null, {
            container: angular.element($uibPosition.scrollParent(elem)),
            elem: elem.find('.nv-discussion-reply'),
            offset: 70,
          });
        }
      });


      replyCtrl.showNewReplyUi = function () {
        commentCtrl.showReplyUi(replyCtrl.reply);
      };

      replyCtrl.showReplies = function () {
        commentCtrl.showReplies();
      };
    },
    bindToController: true,
    controllerAs: 'vm',
    templateUrl: 'discussions/templates/nv-discussion-reply.html',
  };
}
