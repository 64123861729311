import React from 'react';
import _ from 'underscore';
import { useSelector } from 'react-redux';

import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { AngularServicesContext } from 'react-app';
import { Course } from 'redux/schemas/models/course';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import NvResponsiveTableOptionsCell from 'shared/components/nv-responsive-table-options-cell';
import {
  deleteCourse,
  patchCourse as patchJourney,
} from 'redux/actions/courses';
import {
  JourneyCellProps,
  JourneyRowExtraProps,
} from 'learning_journeys/components/journey-row';
import {
  createWelcomeEmailDropdownItem,
} from 'learning_journeys/components/welcome-email-dropdown-item';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { doubleSpacing } from 'styles/global_defaults/scaffolding';
import { css } from '@emotion/react';
import JourneyCloneModal from './clone/journey-clone-modal';
import { config } from '../../../config/pendo.config.json';

type JourneyRowOptionsProps = {
  cellProps: JourneyCellProps,
};

const modalStyles = css`
  &.bs4-modal {
    .bs4-modal-dialog {
      .bs4-modal-body {
        padding: ${doubleSpacing}px;
      }
    }
  }
`;

const JourneyRowOptions = ({
  cellProps,
}: JourneyRowOptionsProps) => {
  const { course } = cellProps;
  const dispatch = useAppDispatch();
  const currentInstitution = useSelector(getCurrentInstitution);
  const {
    $state,
    $scope,
    RailsRoutes,
    CurrentPermissionsManager,
  } = React.useContext(AngularServicesContext);
  const [showCloneModal, setShowCloneModal] = React.useState(false);
  const pendo = config.pendo.journeyAnalytics;

  const updateJourneyLanguage = ({ contentLanguage, automatedTranslationEnabled }) => dispatch(patchJourney({
    id: course.id,
    contentLanguage,
    catalogId: course.catalogId,
    institutionId: currentInstitution.id,
    currentLanguage: course.contentLanguage,
    automatedTranslationEnabled,
  }));

  const optionItems: NvDropdownOption[] = [
    {
      type: 'text',
      text: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.EDIT_BASICS(),
      callback: () => $state.go('learning-journey-basics', {
        catalogId: course.catalogId,
      }),
      dataQa: pendo.editBasics,
    },
    {
      type: 'text',
      text: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.EDIT_JOURNEY(),
      callback: () => $state.go('edit-learning-journey', {
        catalogId: course.catalogId,
      }),
      dataQa: pendo.editJourney,
    },
    {
      type: 'text',
      text: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.JOURNEY_COMMUNICATIONS(),
      callback: () => $state.go('learning-journey-communications', {
        catalogId: course.catalogId, from: 'learning-journeys-dashboard',
      }),
      dataQa: pendo.analytics,
    },
    {
      type: 'text',
      text: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.JOURNEY_LANGUAGE(),
      callback: () => {
        $scope.$uibModal.open({
          templateUrl: 'shared/templates/language-modal.html',
          controller: 'LanguageModalCtrl as vm',
          resolve: {
            currentUser: null,
            context: {
              isJourney: true,
              updateJourneyLanguage,
              currentLanguage: course.contentLanguage,
              automatedTranslationEnabled: course.automatedTranslationEnabled,
              catalogId: course.catalogId,
            },
          },
        });
      },
      dataQa: pendo.journeyLanguage,
    },
    {
      type: 'link',
      targetBlank: true,
      text: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.COMPLETION_EMAIL(),
      link: RailsRoutes.courseAdminDashboardTabPath(course.catalogId, 'statements'),
      dataQa: pendo.completionEmail,
    },
    {
      type: 'link',
      targetBlank: true,
      link: RailsRoutes.courseAdminDashboardTabPath(course.catalogId, 'statements'),
      text: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CERTIFICATE_OF_COMPLETION(),
      dataQa: pendo.certificateOfCompletion,
    },
  ];
  optionItems.push({ type: 'divider' });

  optionItems.push({
    type: 'text',
    text: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.USER_MANAGEMENT(),
    callback: () => $state.go('learning-journey-user-management-learners', {
      catalogId: course.catalogId,
    }),
    dataQa: pendo.userManagement,
  });
  if (CurrentPermissionsManager.hasJourneyAnalyticsAccess()) {
    optionItems.push({
      type: 'text',
      text: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.ANALYTICS(),
      callback: () => $state.go('learning-journey-analytics', {
        catalogId: course.catalogId, from: 'learning-journeys-dashboard',
      }),
      dataQa: pendo.analytics,
    });
  }
  optionItems.push({ type: 'divider' });
  optionItems.push({
    type: 'text',
    text: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_JOURNEY(),
    dataJourneyName: course.name,
    callback: () => {
      setShowCloneModal(true);
    },
    dataQa: pendo.cloneJourney,
  });

  if (CurrentPermissionsManager.hasNovoEdPermissions()) {
    optionItems.push({ type: 'divider' });
    optionItems.push({
      type: 'text',
      class: 'text-danger',
      text: t.NOVOED.DELETE(),
      callback: () => {
        dispatch(openConfirmationDialog({
          title: t.INSTITUTIONS.DASHBOARD.DELETION.ARE_YOU_SURE(),
          bodyText: t.INSTITUTIONS.DASHBOARD.DELETION.WARNING('true'),
          confirmText: t.INSTITUTIONS.DASHBOARD.DELETION.YES_SURE(),
          onConfirm: () => {
            dispatch(deleteCourse({
              courseId: course.id,
              courseCatalogId: course.catalogId,
            }));
          },
          cancelDataQa: config.pendo.learningJourneys.cancel,
          confirmDataQa: config.pendo.learningJourneys.yesIAmSure,
        }));
      },
      dataQa: config.pendo.learningJourneys.deleteOption,
    });
  }

  return (
    <React.Fragment>
      <NvModal
        type={ModalType.FIXED}
        width={960}
        height={810}
        modalStyles={modalStyles}
        header={t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CLONE_MODAL.TITLE()}
        body={<JourneyCloneModal course={course} closeModal={() => setShowCloneModal(false)} />}
        show={showCloneModal}
        onClose={() => setShowCloneModal(false)}
      />
      <NvResponsiveTableOptionsCell<JourneyCellProps, Course, unknown, JourneyRowExtraProps>
        optionItems={optionItems}
        optionsCellRef={cellProps.optionsCellRef}
        dataQa={config.pendo.learningJourneys.moreOption}
        dataQaId={`${config.pendo.learningJourneys.moreOption}-${course.id}`}
        {...cellProps}
      />
    </React.Fragment>
  );
};

export default JourneyRowOptions;
