import { textSmallFontSize, semiBoldFontWeight } from 'styles/global_defaults/fonts';
import { white, primary, gray3, gray2 } from 'styles/global_defaults/colors';
import ClickableContainer from 'components/clickable-container';
import useAutoTranslate from 'shared/hooks/use-auto-translate';
import NvTooltip from 'shared/components/nv-tooltip';
import { css } from '@emotion/react';
import t from 'react-translate';
import Spinner from 'shared/components/spinner';
import { standardSpacing } from 'styles/global_defaults/scaffolding';

const iconStyles = (isToggled: boolean) => css`
  .icon-translate {
    padding: 2px;
    font-size: 16px;

    ${isToggled
    ? css`
        color: ${white};
        background-color: ${primary};
      `
    : css`
        color: ${gray3};
      `}

    &:hover {
      color: ${isToggled ? white : primary};
    }
  }
`;

type TranslationButtonProps = {
  onToggle: (isTranslationOn: boolean, language: string) => void;
  isToggled: boolean
  isLoading?: boolean
  isVisible?: boolean
  dataQa?: string;
  sourceText?: string;
  customTurnOnTooltipText?: string;
};

const TranslationButton = ({
  onToggle,
  isToggled,
  isLoading = false,
  isVisible = true,
  dataQa,
  sourceText = '',
  customTurnOnTooltipText = t.DISCUSSIONS_AUTO_TRANSLATION.TRANSLATE(),
}: TranslationButtonProps) => {
  const { isAutoTranslateEnabled, targetLanguage, isSameLanguage } = useAutoTranslate({ text: sourceText });
  const showTranslationButton = isAutoTranslateEnabled && isVisible && !isSameLanguage;

  if (isLoading) {
    return (
      <Spinner size={standardSpacing} />
    );
  }

  const buttonLabel = isToggled
    ? t.DISCUSSIONS_AUTO_TRANSLATION.TURN_OFF_TRANSLATION()
    : t.DISCUSSIONS_AUTO_TRANSLATION.TRANSLATE();

  const buttonText = isToggled
    ? t.DISCUSSIONS_AUTO_TRANSLATION.TURN_OFF_TRANSLATION()
    : customTurnOnTooltipText;

  return showTranslationButton ? (
    <ClickableContainer
      data-qa={dataQa}
      css={iconStyles(isToggled)}
      onClick={() => onToggle(!isToggled, targetLanguage)}
      aria-label={buttonLabel}
      aria-pressed={isToggled}
    >
      <NvTooltip placement='bottom' text={buttonText}>
        <i className='icon icon-small icon-translate' />
      </NvTooltip>
    </ClickableContainer>
  ) : null;
};

export default TranslationButton;
