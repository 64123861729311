import React from 'react';

const usePostRender = (callback, dependencies) => {
  const initialValue = false;
  const previousValueRef = React.useRef(initialValue);
  const [value, setValue] = React.useState(initialValue);
  const mountedRef = React.useRef(false);
  const schedule = () => setValue(!value);

  const skipFirstRender = (skippedRenderEffect) => {
    if (mountedRef.current) {
      skippedRenderEffect();
    } else {
      mountedRef.current = true;
    }
  };

  React.useEffect(() => {
    skipFirstRender(() => {
      if (previousValueRef.current !== value) {
        callback();
      }

      previousValueRef.current = value;
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, skipFirstRender, ...dependencies]);

  return schedule;
};

export default usePostRender;
