/* expects:

attributes: object map of attributes to be added to the root elem

requires: form name to be passed in
*/
/* @ngInject */
export default function NvCompiledAttributes(
  _,
  $compile,
  $parse,
) {
  return {
    restrict: 'A',
    priority: 99,
    terminal: true,
    link(scope, elem, attrs) {
      let configurationOptions;
      attrs.$observe('attributes', (value) => {
        let placeholderKey; let
          placeholderValues;
        configurationOptions = $parse(value)();

        if (configurationOptions) {
          _.each(configurationOptions, (val, key) => {
            if (key === 'placeholderKey') {
              placeholderKey = val;
            } else if (key === 'placeholderValues') {
              placeholderValues = val;
            } else {
              elem.attr(key, val);
            }
          });

          if (placeholderKey) {
            elem.attr('placeholder', `{{ '${placeholderKey}' | translate:${JSON.stringify(placeholderValues)} }}`);
          }

          elem.removeAttr('nv-compiled-attributes');
          elem.removeAttr('attributes');

          $compile(elem)(scope);
        }
      });
    },

  };
}
