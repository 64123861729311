/* @ngInject */
export default function NvBindCss(
  $parse,
  _,
) {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      let styleElement;

      scope.$watch(
        () => $parse(attrs.nvBindCss)(scope),
        (styles) => {
          updateStylesheet(styles);
        },
      );

      function updateStylesheet(styles) {
        const sheet = getNewStylesheet();
        const classes = attrs.class ? `.${attrs.class.split(' ').join('.')}` : '';

        _.each(styles.split('}'), (style, index, list) => {
          if (style.trim()) {
            style = `${classes} ${style}}`;
            sheet.insertRule(style, index);
          }
        });
      }

      function getNewStylesheet() {
        if (styleElement) {
          styleElement.remove();
        }

        styleElement = document.createElement('style');
        styleElement.type = 'text/css';
        styleElement.appendChild(document.createTextNode('')); // WebKit hack :(
        $('head').append(styleElement);

        return styleElement.sheet;
      }
    },
  };
}
