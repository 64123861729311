/* @ngInject */
export default function CourseRolesResource(

  $resource,
) {
  const path = 'course_roles';
  return $resource(
    path,
    {},
    {
      get: { method: 'GET', isArray: false, url: `${path}.json` },
      getRole: { method: 'GET', url: `${path}/:roleId` },
      save: { method: 'POST', url: path },
      update: { method: 'PUT', url: `${path}/:id` },
      delete: { method: 'DELETE', url: `${path}/:id` },
    },
  );
}
