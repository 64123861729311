import React, { forwardRef, Fragment, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import t from 'react-translate';
import { PopoversContainerContext } from 'shared/react-utils';
import { AngularServicesContext } from 'react-app';

// Redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/schemas';
import { wrapThunkAction } from 'redux/utils';
import { useAppDispatch } from 'redux/store';
import { enableHCMIntegration, updateHCMIntegration } from 'redux/actions/hcm';
import { HCMIntegrationTypes } from 'redux/schemas/models/hcm';

// Form
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

// Styles
import { css } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';
import { gray2, gray3, gray4 } from 'styles/global_defaults/colors';
import {
  openSans,
  normalFontWeight,
  semiBoldFontWeight,
  textSmallFontSize,
} from 'styles/global_defaults/fonts';
import { screenXsMax } from 'styles/global_defaults/media-queries';
import {
  doubleSpacing,
  halfSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';

// Components
import { Button } from 'react-bootstrap';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvTextArea from 'shared/components/inputs/nv-text-area';
import NvDropdown, { NvDropdownTextItem, NvDropdownButtonStyle } from 'shared/components/inputs/nv-dropdown';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import NvClickToCopy from 'shared/components/nv-click-to-copy';

// Pendo
import { config } from '../../../../../config/pendo.config.json';

type FormProps = {
  handleModalClose: () => void;
};

const WorkdayForm = forwardRef<any, FormProps>(({ handleModalClose }, ref) => {
  const containerRef = useRef<HTMLDivElement>();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const { InstitutionsManager } = useContext(AngularServicesContext);
  const { institution } = InstitutionsManager;

  const { hcmConfig } = institution;
  const hasConfig = hcmConfig.endpoint && hcmConfig.username && hcmConfig.password;
  const loading = useSelector((state: RootState) => state.app.hcmConfig.isLoading);
  let btnText = hasConfig ? t.FORM.UPDATE() : t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.ENABLE();

  const validationSchema = yup.object().shape({
    endpoint: yup.string().required(t.VALIDATION.REQUIRED()),
    username: yup.string().required(t.VALIDATION.REQUIRED()),
    password: yup.string().required(t.VALIDATION.REQUIRED()),
  });

  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: hcmConfig,
  });

  useImperativeHandle(ref, () => formMethods);
  const {
    formState: { isValid, isDirty },
    handleSubmit,
  } = formMethods;

  const handleEnableClick = (data) => {
    const configMethod = hasConfig ? updateHCMIntegration : enableHCMIntegration;
    const params = { institutionId: institution.id, ...data };

    wrapThunkAction(dispatch(configMethod(params))).then(() => {
      InstitutionsManager.institution.updateFromReact({
        hcmConfig: data,
      });
      handleModalClose();
    });
  };

  const styles = css`
    ${!isMobile
      && css`
        padding: 0 ${doubleSpacing}px;
      `}

    .message {
      margin-bottom: ${standardSpacing + quarterSpacing}px;
    }

    form label {
      color: ${gray3};
    }
  `;

  if (loading) btnText = t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.ENABLING();

  return (
    <PopoversContainerContext.Provider value={containerRef.current}>
      <FormProvider {...formMethods}>
        <div css={styles} ref={containerRef}>
          <p className='message'>
            {t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.WORKDAY_DESCRIPTION()}
          </p>

          <form onSubmit={handleSubmit(handleEnableClick)}>
            <label htmlFor='endpoint' className='m-0'>
              {t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.ENDPOINT_URL()}
            </label>
            <NvTextInput
              withForm
              autoComplete='off'
              name='endpoint'
              ariaLabel={t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.ENDPOINT_URL()}
              className='mb-4'
              placeholder={t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.ENDPOINT_URL()}
              required
              data-qa={config.pendo.hcm.integrationEndpointUrl}
            />

            <label htmlFor='username' className='m-0'>
              {t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.USERNAME()}
            </label>
            <NvTextInput
              withForm
              autoComplete='off'
              name='username'
              ariaLabel={t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.USERNAME()}
              className='mb-4'
              placeholder={t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.USERNAME()}
              required
              data-qa={config.pendo.hcm.integrationUsername}
            />

            <label htmlFor='password' className='m-0'>
              {t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.PASSWORD()}
            </label>
            <NvTextInput
              withForm
              type='password'
              autoComplete='off'
              name='password'
              ariaLabel={t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.PASSWORD()}
              placeholder={t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.PASSWORD()}
              required
              data-qa={config.pendo.hcm.integrationPassword}
            />

            <div className='button-bar mt-8'>
              <Button
                variant='secondary'
                onClick={handleModalClose}
                data-qa={config.pendo.hcm.integrationCancel}
              >
                {t.FORM.CANCEL()}
              </Button>

              <Button
                type='submit'
                variant='primary'
                disabled={!isDirty || !isValid || loading}
                data-qa={!hasConfig ? config.pendo.hcm.integrationEnable : config.pendo.hcm.integrationUpdate}
              >
                {btnText}
              </Button>
            </div>
          </form>
        </div>
      </FormProvider>
    </PopoversContainerContext.Provider>
  );
});

const SFTPForm = ({ handleModalClose }) => {
  const dispatch = useAppDispatch();
  const { InstitutionsManager: { institution }, InstitutionsManager } = useContext(AngularServicesContext);

  const { endpoint, username, password, integrationType } = InstitutionsManager.institution.hcmConfig;
  const hasIntegration = endpoint && username && password && integrationType;

  const enableSFTP = () => {
    const data = { integrationType: HCMIntegrationTypes.CSV_SFTP, endpoint, username, password };

    dispatch(enableHCMIntegration({ institutionId: institution.id, ...data })).then(() => {
      InstitutionsManager.institution.updateFromReact({
        hcmConfig: data,
      });
      handleModalClose();
    });
  };

  return (
    <div className='mx-6'>
      {hasIntegration ? (
        <Fragment>
          <NvClickToCopy text={endpoint}>
            <NvTextInput
              required
              readOnly
              value={endpoint}
              withLabel
              placeholder={t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.SFTP_ADDRESS()}
              ariaLabel={t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.SFTP_ADDRESS()}
              className='w-100 mb-5 label-margin'
            />
          </NvClickToCopy>
          <NvClickToCopy text={username}>
            <NvTextInput
              required
              readOnly
              value={username}
              withLabel
              placeholder={t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.USERNAME()}
              ariaLabel={t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.USERNAME()}
              className='w-100 mb-5 label-margin'
            />
          </NvClickToCopy>
          <NvClickToCopy text={password}>
            <NvTextArea
              required
              readOnly
              value={password}
              withLabel
              placeholder={t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.PUBLIC_KEY()}
              ariaLabel={t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.PUBLIC_KEY()}
              className='w-100 mb-5 label-margin'
            />
          </NvClickToCopy>
          <div className='button-bar mt-5'>
            <Button
              variant='secondary'
              onClick={handleModalClose}
              data-qa={config.pendo.hcm.integrationCancel}
            >
              {t.FORM.CLOSE()}
            </Button>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className='mt-4'>
            {t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.SFTP_DESCRIPTION()}
          </div>
          <div className='button-bar mt-8'>
            <Button
              variant='secondary'
              onClick={handleModalClose}
              data-qa={config.pendo.hcm.integrationCancel}
            >
              {t.FORM.CANCEL()}
            </Button>
            <Button
              variant='primary'
              onClick={() => enableSFTP()}
              data-qa={config.pendo.hcm.integrationEnable}
            >
              {t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.ENABLE()}
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

type Props = {
  show?: boolean
  onClose?: () => void
};

enum Pages {
  CHOOSE_TYPE = 'choose_type',
  ENABLE_SFTP = 'enable_sftp',
  ENABLE_WORKDAY = 'enable_workday',
}

const HCMConfiguration = (props: Props) => {
  const [show, setShow] = useState(props.show);
  const formRef = useRef<any>();
  const { InstitutionsManager } = useContext(AngularServicesContext);

  const { endpoint, username, password, integrationType } = InstitutionsManager.institution.hcmConfig;
  const hasAnyIntegration = endpoint && username && password && integrationType;
  let initialPage: Pages = Pages.CHOOSE_TYPE;
  if (hasAnyIntegration) {
    initialPage = integrationType === HCMIntegrationTypes.CSV_SFTP ? Pages.ENABLE_SFTP : Pages.ENABLE_WORKDAY;
  }
  const [page, setPage] = useState<Pages>(initialPage);

  const items: NvDropdownTextItem[] = [
    {
      type: 'text',
      text: t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.CSV_SFTP(),
      callback: () => setPage(Pages.ENABLE_SFTP),
    },
    {
      type: 'text',
      text: t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.WORKDAY_API(),
      callback: () => setPage(Pages.ENABLE_WORKDAY),
    },
  ];

  const handleModalClose = () => {
    setShow(false);
    props.onClose();
  };

  const modalBodyStyles = css`
    padding: ${standardSpacing}px;
    min-height: 216px;

    .w-fit-content {
      width: fit-content;
    }

    .hcm-menu-toggle {
      width: 300px;
      color: ${gray2};
      background-color: white;
      border-color: ${gray4};
      font-weight: ${normalFontWeight};
    }

    .caret {
      border-top: ${quarterSpacing}px dashed;
      border-left: ${quarterSpacing}px solid transparent;
      border-right: ${quarterSpacing}px solid transparent;
      color: ${gray3};
    }

    .label-margin {
      margin-top: -${threeQuartersSpacing}px;
    }
  `;

  useEffect(() => {
    if (props.show) setShow(true);
  }, [props.show]);

  return (
    <NvModal
      show={show}
      height='auto'
      type={ModalType.FIXED}
      onClose={handleModalClose}
      header={t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.TITLE()}
      body={(
        <div css={modalBodyStyles}>
          {!hasAnyIntegration && (
            <div className='mt-2 mb-4 mx-auto w-fit-content'>
              <NvDropdown
                items={items}
                buttonStyle={NvDropdownButtonStyle.CUSTOM}
                customTarget={() => (
                  <Button
                    variant='outline-light'
                    className='hcm-menu-toggle d-flex align-items-center justify-content-between'
                  >
                    {page === Pages.CHOOSE_TYPE && t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.CHOOSE_INTEGRATION_TYPE()}
                    {page === Pages.ENABLE_SFTP && t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.CSV_SFTP()}
                    {page === Pages.ENABLE_WORKDAY && t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.SETTINGS_MODAL.WORKDAY_API()}
                    <span className='caret' />
                  </Button>
                )}
                menuClassName='w-100'
              />
            </div>
          )}
          {page === Pages.ENABLE_WORKDAY && (
            <div>
              <WorkdayForm ref={formRef} handleModalClose={handleModalClose} />
            </div>
          )}
          {page === Pages.ENABLE_SFTP && (
            <div>
              <SFTPForm handleModalClose={handleModalClose} />
            </div>
          )}
        </div>
      )}
      data-qa={config.pendo.hcm.integrationModal}
    />
  );
};

export default HCMConfiguration;
