/* @ngInject */
export default function GroupDirectoryManager(

  TeamDirectoryManager,
) {
  const manager = TeamDirectoryManager;

  manager.dropdownFilters = ['all', 'joinable'];
  manager.numMembersShownOnLargeDesktop = 7;
  manager.numMembersShownOnDesktop = 5;
  manager.numMembersShownOnTablet = 4;

  return manager;
}
