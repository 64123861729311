/* @ngInject */
export default function AnchorModifierService(
  $window,
  $uibPosition,
) {
  const AnchorModifier = {};
  $window.AnchorModifier = AnchorModifier;
  AnchorModifier.scrollToId = function (event) {
    try {
      const target = event.srcElement || event.target;
      const $anchor = $(target).closest('a');
      const possibleId = $anchor.attr('href');
      if (possibleId.length > 0 && $(possibleId).length > 0) {
        const y = $(possibleId).position().top;
        if ($('.main-panel-scrollable').length) {
          $('.main-panel-scrollable').scrollTo(0, y);
        } else if ($('.lightbox-panel').length) {
          $('.lightbox-panel').scrollTo(0, y);
        } else {
          window.scrollTo(0, y);
          // let $scrollParent = $uibPosition.scrollParent($(possibleId)[0]);
          // $scrollParent.scrollTo(0, y);
        }
        event.stopPropagation();
        event.preventDefault();
        return false;
      }
    } catch (exception) {
      // if the anchor link scroll to didn't work, then just let the event continue
    }

    return false;
  };

  AnchorModifier.fixShortcutAnchors = function () {
    $('a[href^="#"]').each(function (index) {
      const outerHTML = $(this).prop('outerHTML');

      // don't mess with bootstrap or other stuff that uses data- attributes
      if ($(this).attr('href') === '#' || outerHTML.indexOf('data-') > -1) {
        return;
      }

      // event needs to be passed in for firefox compatibility
      // return ensures that firefox recognizes to run this function
      $(this).attr('onclick', 'return AnchorModifier.scrollToId(event);');
    });
  };

  AnchorModifier.fixShortcutAnchor = function ($element) {
    if ($element.attr('href') && $element.attr('href').startsWith('#')) {
      const outerHTML = $element.prop('outerHTML');

      // accordians
      if ($element.attr('href') !== '#' && $element.data('toggle') === 'collapse' && $element.data('parent')) {
        $element.click((e) => {
          e.preventDefault();
        });
      }

      // don't mess with bootstrap or other stuff that uses data- attributes
      if ($element.attr('href') === '#' || outerHTML.indexOf('data-') > -1 || $element.attr('ui-sref')) {
        return;
      }

      // event needs to be passed in for firefox compatibility
      // return ensures that firefox recognizes to run this function
      $element.attr('onclick', 'return AnchorModifier.scrollToId(event);');
    }
  };

  return AnchorModifier;
}
