import store from 'redux/store';
import { ActivityKey } from 'redux/schemas/models/activity';
import { setActivityProgress } from 'redux/actions/lecture-components';

/* @ngInject */
export default function TimelineTeamFormationLectureComponentModelService(

  _,
  nvUtil,
  moment,
) {
  const TimelineTeamFormationLectureComponentModel = function (attributes) {
    const _this = this;
    let deadlineMoment;
    const base = {
      templateUrl: 'timelines/templates/nv-timeline-team-formation.html',
    };

    /** Setting Up Basic Attributes * */
    _.extend(_this, base, attributes);
    // passed lecturePage, lectureSection, course
    preprocess();

    /** Public Functions * */
    _.extend(_this, {
      getStatus,
      isCompleted,
      isMissed,
      isTodoRequiredForCompletion,
      updateComponentPointsAndProgress,
      updateComponentProgress,
      getPayload,
      hasDeadline,
      setDeadline,
      showWarning,
      updateDependentActivitiesReleaseDate,
    });

    /** Function Declarations * */
    /* Used Publicly */
    function getStatus() {
      return _this.teamSet.progress;
    }

    function isCompleted() {
      return _this.teamSet.progress === 'completed';
    }

    function isMissed() {
      return false;
    }

    function isTodoRequiredForCompletion() {
      return _this.teamSet.isRequiredForCompletion;
    }

    function updateComponentPointsAndProgress(componentType, componentId, pointsReceived, totalPoints, progress) {
      if ((_this.type === componentType && _this.id === componentId) || (componentType === 'team_set' && _this.teamSet.id === componentId)) {
        if (progress) {
          _this.teamSet.progress = progress;
        } else {
          _this.teamSet.progress = 'completed';
        }

        store.dispatch(setActivityProgress({
          pointsReceived,
          progress: _this.teamSet.progress,
          activityKey: ActivityKey.TEAM_SET,
          activityPayloadId: _this.teamSet.id,
        }));

        _this.teamSet.pointsReceived = pointsReceived;
        _this.pointsReceived = pointsReceived;

        if (_.isNumber(totalPoints)) {
          _this.currentTotalPoints = totalPoints;
        } else {
          _this.currentTotalPoints = nvUtil.getCurrentTotalPoints(_this.totalPoints, _this.lecturePage.releaseDate, _this.course.expiringPointsEnabled);
        }

        viewPreprocess();

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function updateComponentProgress(componentType, componentId, progress) {
      if ((_this.type === componentType && _this.id === componentId) || (componentType === 'team_set' && _this.teamSet.id === componentId)) {
        _this.teamSet.progress = progress;
        store.dispatch(setActivityProgress({
          progress,
          activityKey: ActivityKey.TEAM_SET,
          activityPayloadId: _this.teamSet.id,
        }));

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function getPayload() {
      return _.pick(_this, ['title', 'deadline']);
    }

    function hasDeadline() {
      return !!this.deadline;
    }

    function setDeadline(newDeadline) {
      this.deadline = newDeadline;
    }

    /* Used Privately */
    function preprocess() {
      _.extend(_this, _.pick(_this.teamSet, 'title', 'deadline', 'isTodo', 'hasStructuralIssues', 'pointsReceived',
        'totalPoints', 'scheduledCommunicationsCount'));
      _this.activityId = _this.teamSet.id;

      _this.currentTotalPoints = nvUtil.getCurrentTotalPoints(_this.totalPoints, _this.lecturePage.releaseDate, _this.course.expiringPointsEnabled);

      if (_this.deadline) {
        deadlineMoment = moment(_this.deadline);
      }

      viewPreprocess();
    }

    function viewPreprocess() {
      _this.showDueSoon = showDueSoon();

      if (isCompleted() || _this.pointsReceived > _this.currentTotalPoints) {
        _this.currentTotalPoints = _this.pointsReceived ? _this.pointsReceived : 0;
      }
      _this.displayTotalPointsOnly = !_this.pointsReceived && _this.currentTotalPoints;
    }

    function showDueSoon() {
      const currentMoment = moment();
      if (_this.deadline) {
        return (getStatus() === 'in_progress' || getStatus() === 'not_started')
            && moment(_this.lecturePage.releaseDate) < currentMoment
            && deadlineMoment > currentMoment
            && deadlineMoment < currentMoment.add(7, 'days');
      }
      return false;
    }

    function showWarning() {
      const releaseDateMoment = moment(_this.lecturePage.releaseDate);
      return _.any(_this.teamSet.usedBy, (exercise) => moment(exercise.releaseDate) < releaseDateMoment);
    }

    function updateDependentActivitiesReleaseDate(changedLecturePage) {
      _.each(_this.teamSet.usedBy, (exercise) => {
        if (exercise?.lecturePageId === changedLecturePage.id) {
          exercise.releaseDate = changedLecturePage.releaseDate;
        }
      });
    }
  };

  return TimelineTeamFormationLectureComponentModel;
}
