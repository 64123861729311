import React from 'react';

import moment from 'moment';

import { connect, useSelector } from 'react-redux';
import t from 'react-translate';

// schemas
import { RootState } from 'redux/schemas';
import { ActivityType } from 'redux/schemas/models/activity';
import { NTimelineTeamSet } from 'redux/schemas/models/team-set';
import { ComponentKeyPluralized } from 'redux/schemas/models/lecture-component';

// actions
import { setActivityExpandedInAutomatedCommunications } from 'redux/actions/course-communications';

// selectors
import { getTeamSet, hasTeamSetGotCommunicationError } from 'redux/selectors/timeline';
import { isActivityExpandedInAutomatedCommunications } from 'redux/selectors/course-communications';

// components
import NvIcon from 'shared/components/nv-icon';
import NvStackedTab from 'shared/components/nv-stacked-tab';

import CommunicationCreateNewDropdown from 'communications/course_communications/components/communication-create-new-dropdown';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';
import { getCourseAliases } from 'redux/selectors/course';


type TeamFormationLectureComponentProps = {
  teamSetId: number
};

type StateProps = {
  teamSet: NTimelineTeamSet,
  hasCommunicationError: boolean,
};

const mapDispatchToProps = {
  setExpanded: setActivityExpandedInAutomatedCommunications,
};

const TeamFormationLectureComponent = (props: TeamFormationLectureComponentProps & StateProps & typeof mapDispatchToProps) => {
  const aliases = useSelector((state) => getCourseAliases(state));

  const isExpanded = useSelector((state) => isActivityExpandedInAutomatedCommunications(state, ComponentKeyPluralized.TEAM_FORMATION, props.teamSetId));

  return (
    <React.Fragment>
      <div className={`lecture-component-row ${isExpanded && props.teamSet?.communicationsCount > 0 && 'expanded-box'}`}>
        <NvIcon icon='team' size='smallest' />
        <div className='description'>{props.teamSet?.title}</div>
        {props.teamSet?.hasStructuralIssues && (
          <div className='text-danger spacing'>
            <NvIcon icon='warning' size='xss-smallest' />
          </div>
        )}
        <div className='date'>
          {props.teamSet?.deadline && t.LECTURE_PAGES.COMPONENTS.DUE_DATE.STUDENT_VIEW(moment(props.teamSet.deadline).format('LLL'))}
        </div>
        <div className=''>
          <CommunicationCreateNewDropdown
            activityType={ActivityType.TEAM_FORMATION}
            activityId={props.teamSetId}
            disabledText={!props.teamSet?.formedByStudents
              && t.COURSE_COMMUNICATIONS.TOOLTIP.CREATE_DISABLED_INSTRUCTOR_CREATED(aliases.teamAliases.teamAlias)}
          />
        </div>
        { props.teamSet?.communicationsCount > 0 && (
          <NvStackedTab
            count={props.teamSet.communicationsCount}
            hasError={props.hasCommunicationError}
            isExpanded={isExpanded}
            errorMessage={t.COURSE_COMMUNICATIONS.WARNINGS.ACTIVITY_COMMUNICATION()}
            onClick={() => props.setExpanded({
              type: ComponentKeyPluralized.TEAM_FORMATION,
              id: props.teamSetId,
              isExpanded: !isExpanded,
            })}
          />
        )}
      </div>
      {
        // Communications
        isExpanded && props.teamSet?.communications && props.teamSet.communications.map(communicationId => (
          <CommunicationItem key={communicationId.toString()} communicationId={communicationId} />
        ))
      }
    </React.Fragment>
  );
};

const ConnectedTeamFormationLectureComponent = connect(
  (state: RootState, ownProps: TeamFormationLectureComponentProps) => ({
    teamSet: getTeamSet(state, ownProps.teamSetId),
    hasCommunicationError: hasTeamSetGotCommunicationError(state, ownProps.teamSetId),
  }),
  mapDispatchToProps,
)(TeamFormationLectureComponent);

export default ConnectedTeamFormationLectureComponent;
