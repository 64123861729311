import { css } from '@emotion/react';
import { ProgressBar } from 'react-bootstrap';
import t from 'react-translate';

import { NvFileUploadProgressProps } from 'shared/components/nv-file-upload-progress';
import { black, gray3, gray7, primary, warning } from 'styles/global_defaults/colors';
import { handheld } from 'styles/global_defaults/media-queries';
/**
 * This component is similar to NvFileUploadProgress. Only difference is in the
 * styling
 *
 */

type VideoCommentUploadProgressProps = NvFileUploadProgressProps;

const styles = css`
  padding: 40px 100px;

  .abort-upload {
    cursor: pointer;
  }

  ${handheld(css`
    padding: 40px;
  `)};

  &.highlighted {
    border-left: solid 3px;
    background-color: ${gray7};
    border-color: ${warning};
  }
`;

const VideoCommentUploadProgress = ({
  hasUploadPercentage,
  uploadInProgress,
  fileUploading,
  abortUpload,
}: VideoCommentUploadProgressProps) => {
  const now = hasUploadPercentage ? fileUploading?.uploadPercentage : 100;

  return (
    <div css={styles} className='highlighted mb-2'>
      <ProgressBar animated={!hasUploadPercentage} now={now} />
      {uploadInProgress && (
        <div className='text-center'>
          <span className='label text-gray-3'>{t.FILE_UPLOAD.UPLOADING_WITH_ELLIPSIS()}</span>
          {(abortUpload) && <span className='text-small text-primary ml-2 abort-upload' onClick={abortUpload}>{t.FORM.CANCEL()}</span>}
        </div>
      )}
    </div>
  );
};

export default VideoCommentUploadProgress;
