/* @ngInject */
export default function ConfigureZoomModalCtrl(

  $uibModalInstance,
  AlertMessages,
  InstitutionsManager,
  RailsRoutes,
  config,
) {
  const vm = this;

  vm.InstitutionsManager = InstitutionsManager;
  vm.currentSource = '';
  vm.currentKey = '';
  vm.currentSecret = '';
  vm.showErrorMessage = false;
  vm.config = config;
  initialize();

  function initialize() {
    if (InstitutionsManager.hasIntegration(InstitutionsManager.INTEGRATION_TYPES.ZOOM_USER_LEVEL)) {
      vm.currentSource = InstitutionsManager.INTEGRATION_TYPES.ZOOM_USER_LEVEL;
    }
  }

  vm.closeModal = () => {
    vm.currentKey = InstitutionsManager.institution.liveSessionSettings ? InstitutionsManager.institution.liveSessionSettings.apiKey : '';
    vm.currentSecret = InstitutionsManager.institution.liveSessionSettings ? InstitutionsManager.institution.liveSessionSettings.apiSecret : '';
    $uibModalInstance.dismiss('cancel');
  };

  vm.save = () => {
    vm.savingAPI = true;
    vm.showErrorMessage = false;

    if (vm.currentSource === InstitutionsManager.INTEGRATION_TYPES.ZOOM_USER_LEVEL) {
      vm.currentKey = undefined;
      vm.currentSecret = undefined;
    }

    InstitutionsManager.saveLiveSessionSettings(vm.currentKey, vm.currentSecret, vm.currentSource)
      .then((isValid) => {
        vm.savingAPI = false;
        if (isValid) {
          InstitutionsManager.institution.liveSessionSettings = {
            apiKey: vm.currentKey,
            apiSecret: vm.currentSecret,
            source: [...InstitutionsManager.institution.liveSessionSettings?.source, vm.currentSource],
          };
          InstitutionsManager.institution.zoomEnabled = true;

          vm.zoomForm.$setPristine();
          vm.closeModal();
          AlertMessages.success('FORM.SUCCESS_BANG', 'INSTITUTIONS.ADVANCED_SETTINGS.ENABLE_ZOOM_SUCCESS');
        } else {
          vm.showErrorMessage = true;
        }
      }, () => {
        vm.savingAPI = false;
        AlertMessages.error('FORM.OOPS', 'FORM.ERROR_SOMETHING_WRONG');
      });
  };

  vm.authenticateAccountZoomOauth = () => {
    window.location.href = RailsRoutes.accountZoomOauthUrl(InstitutionsManager.institution.id);
  };
}
