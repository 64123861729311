import { css } from '@emotion/react';
import { useLecturePageLink, useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NLecturePage } from 'redux/schemas/models/lecture-page';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import { primary } from 'styles/global_defaults/colors';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import useLectureStatus from 'redux/selectors/lecture-page';

const barHeight = 10;

const barStyles = css`
  display: flex;
  width: 100%;
  position: absolute;
  top: -${barHeight}px;
`;

// TODO: This is no longer a "progress bar" in the sense of the ProgressBar control.
// consider renaming
export const CourseOutlineProgressBar = () => {
  const history = useHistory();
  const params = useLecturePageParams();
  const lectureLink = useLecturePageLink();

  // The released lecture pages inside the section which contains the current lecture page
  const lecturePages = useSelector(state => {
    const lp = state.models.lecturePages?.[params.lecturePageId];
    const currentSectionId = state.models.lecturePages?.[params.lecturePageId]?.lectureSectionId;
    const section = currentSectionId && state.models.lectureSections?.[currentSectionId];

    if (!section) {
      return [];
    }

    // Map over all lecture page IDs and return lecture pages in the result array only if they
    // are released
    // TODO: We should only skip unreleased LPs when non in admin mode; Lei is still making mocks
    // for that case. For now lets show all of them
    // return section.lecturePages.reduce((acc, cur) => state.models.lecturePages[cur].released ? acc.concat(cur) : acc, []);

    return section.lecturePages.map(lpID => state.models.lecturePages[lpID]);
  });

  // console.log('lps:', lecturePages);

  const navigateToLecture = (lectureId: number) => {
    lectureLink({
      catalogId: params.catalogId,
      lecturePageId: lectureId,
      mode: params.mode,
    });
  };

  return (
    <div css={barStyles}>
      {lecturePages.map((lecturePage, i) => (
        <ProgressBarSegment
          key={lecturePage.id}
          currentLectureId={params.lecturePageId}
          lecturePage={lecturePage}
          positionIndex={i + 1}
          totalPageCount={lecturePages.length}
          navigateToLecture={navigateToLecture}
        />
      ))}
    </div>
  );
};

const segmentStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  height: ${barHeight}px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  font-weight: 400;

  // These bars are specified to have opacity go from .9 -> 1.0 when hovered and made taller
  opacity: .9;

  &:not(:last-of-type) {
    border-right: 2px solid white;
  }

  .position-text {
    display: none;
  }

  .icon-dropdown-arrow {
    position: absolute;
    font-size: 16px;
    margin-top: -20px;
    left: 0;
    right: 0;
  }

  :hover {
    margin-top: -${halfSpacing}px;
    height: ${standardSpacing}px;
    opacity: 1.0;

    .position-text {
      display: unset;
    }

    .icon-dropdown-arrow {
      margin-top: -40px;
    }
  }
`;

const ProgressBarSegment = (props: {
  currentLectureId: number,
  lecturePage: NLecturePage,
  /** The position for this lecture page in the list. Different than the page's index because
   * we hide unreleased pages */
  positionIndex: number,
  totalPageCount: number,
  navigateToLecture: (id: number) => void,
}) => {
  const status = useLectureStatus(props.lecturePage.id);
  const isCurrentLecture = props.currentLectureId === props.lecturePage.id;
  let backgroundColor = 'bg-gray-4';

  if (isCurrentLecture || status === 'in-progress') {
    backgroundColor = 'bg-primary';
  } else if (status === 'completed') {
    backgroundColor = 'bg-success';
  }

  // TODO: Also show bg-primary if a lecture is in-progress

  return (
    <NvTooltip text={props.lecturePage.title}>
      <div css={segmentStyle} className={backgroundColor} onClick={() => !isCurrentLecture && props.navigateToLecture(props.lecturePage.id)}>
        {isCurrentLecture
        && (
        <div style={{ color: primary }}>
          <NvIcon icon='dropdown-arrow' size='md' />
        </div>
        )}
        <div className='position-text text-white'>
          {props.positionIndex}
          {' '}
          /
          {' '}
          {props.totalPageCount}
        </div>
      </div>
    </NvTooltip>
  );
};

export default CourseOutlineProgressBar;
