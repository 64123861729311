/* @ngInject */
export default function InstitutionAdminModel(
  InstitutionAdminsResource,
  $q,
  _,
) {
  const InstitutionAdmin = function (attributes) {
    const _this = this;
    _this.favicon = null;

    _.extend(_this, attributes);

    _this.destroy = destroy;

    function destroy() {
      return InstitutionAdminsResource.delete(
        {
          institutionId: _this.institutionId,
          id: _this.id,
        },
      ).$promise;
    }
  };

  InstitutionAdmin.save = function (institutionId, institutionAdmin, adminRole) {
    return InstitutionAdminsResource.save(
      { institutionId },
      { user: institutionAdmin, institutionId, adminRole },
    ).$promise.then(
      (response) => $q.resolve(new InstitutionAdmin(response.result)), (response) => $q.reject(response),
    );
  };

  InstitutionAdmin.updateAdminRole = function (institutionId, institutionAdminId, adminRole) {
    return InstitutionAdminsResource.updateAdminRole(
      { institutionId, institutionAdminId },
      { institutionId, adminRole },
    ).$promise;
  };

  InstitutionAdmin.getUserExportDetails = function (institutionId, userId) {
    return InstitutionAdminsResource.getUserExportDetails({ institutionId }, { userId }).$promise;
  };

  InstitutionAdmin.exportData = function (institutionId, params) {
    return InstitutionAdminsResource.exportData({ institutionId }, params).$promise;
  };

  return InstitutionAdmin;
}
