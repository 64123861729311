import { css } from '@emotion/react';

import { useState, useEffect } from 'react';
import t from 'react-translate';
import { NvTooltip } from 'shared/components/nv-tooltip';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import _ from 'underscore';
import { primary,
  gray3,
  gray4,
} from 'styles/global_defaults/colors';

type NvStepsProps = {
  active: number,
  onChange: Function,
  steps: number,
  /* Holds the last valid step a user can navigate to.
  If null | undefined, all visited steps are valid. */
  lastValidStep?: number,
};

export const NvSteps = ({
  active = 0,
  onChange,
  steps,
  lastValidStep = null,
}: NvStepsProps) => {
  const styles = css`
    display: flex;

    .step {
      width: 24px;
      height: 24px;
      background-color: ${gray4};
      color: white;
      border-radius: 50%;
      text-align: center;
      cursor: default;
      display: flex;
      justify-content: center;
      align-items: center;

      &.visited {
        background-color: ${gray3};
        cursor: pointer;
      }

      &.active {
        background-color: ${primary}
      }

      & + .step {
        margin-left: ${standardSpacing}px;
      }
    }
  `;

  const [maxVisited, setMaxVisited] = useState(active);

  useEffect(() => {
    if (active > maxVisited) {
      setMaxVisited(active);
    }
  }, [active]);

  const onClick = (canClick, i) => {
    if (canClick) {
      onChange(i);
    }
  };

  let lastVisited = maxVisited;
  let lastValid = lastValidStep;
  if (lastValidStep !== null && lastValidStep <= active) {
    lastVisited = active;
    lastValid = active;
  } else if (lastVisited < 0) {
    lastVisited = 0;
  }

  return (
    <div css={styles} className='nv-steps'>
      {_.range(steps).map((step) => {
        let className = 'step';
        let tooltipText = null;
        let tooltipEnabled = true;
        let canClick = false;

        if (active === step) {
          className += ' active';
          tooltipEnabled = false;
        } else if (step <= lastVisited && (lastValid === null || step <= lastValid)) {
          className += ' visited';
          tooltipText = t.COURSE_COMMUNICATIONS.STEPS[step < active ? 'GO_BACK' : 'GO_TO'](step + 1);
          canClick = true;
        } else {
          tooltipText = t.COURSE_COMMUNICATIONS.STEPS.PLEASE_COMPLETE(lastVisited + 1);
        }

        return (
          <NvTooltip key={step} text={tooltipText} enabled={tooltipEnabled}>
            <div
              className={className}
              onClick={() => onClick(canClick, step)}
              tabIndex={0}
              role='button'
            >
              {step + 1}
            </div>
          </NvTooltip>
        );
      })}
    </div>
  );
};

export default NvSteps;
