/* @ngInject */
export default function NvExerciseStudentTeamFormation() {
  return {
    restrict: 'A',
    scope: {
      lectureComponent: '=',
      teamSet: '=',
      editMode: '=?',
    },
    controller: function ctrl(
      $controller,
      _,
      moment,

      $scope,
      $state,
      $uibModal,

      RailsRoutes,
      $stateParams,
      ConfirmationOverlays,

      CurrentUserManager,
      CurrentCourseManager,
      CurrentPermissionsManager,
      TeamManager,
      InvitationsAndRequestsManager,
      TimelinesManager,
      ReactTimelineService,
      config,
      ReactLecturePageContext,
    ) {
'ngInject';
      const vm = this;
      let removeCallback;
      angular.extend(this, $controller('LectureComponentBaseCtrl'));

      _.extend(vm, {
        invitationsOpen: false,
        requestOpen: false,
        otherOptionsOpen: false,
        selectedForm: null,

        RailsRoutes,
        $state,
        $stateParams,
        moment,
        config,

        CurrentCourseManager,
        CurrentUserManager,
        CurrentPermissionsManager,
        InvitationsAndRequestsManager,

        getOutstandingInvites,
        getOutstandingRequests,
        getUnapprovedOutstandingInvitesCount,
        getUnapprovedOutstandingRequestsCount,

        openCreateTeamModal,
        openConfirmRequestDeletionModal,

        submit,
      });

      initialize();

      function initialize() {
        removeCallback = vm.InvitationsAndRequestsManager.setAcceptedCallback(acceptedRequestInvite);
        vm.InvitationsAndRequestsManager.setCourse(vm.CurrentCourseManager.course)
          .then(() => {
            const hasCurrentTeam = !!(vm.teamSet.teams?.length);

            if (getUnapprovedOutstandingInvitesCount()) {
              vm.invitationsOpen = true;
              vm.requestOpen = false;
              vm.otherOptionsOpen = false;
            } else if (getUnapprovedOutstandingRequestsCount()) {
              vm.invitationsOpen = false;
              vm.requestOpen = true;
              vm.otherOptionsOpen = true;
            } else {
              vm.invitationsOpen = false;
              vm.requestOpen = false;
              vm.otherOptionsOpen = !hasCurrentTeam;
            }
          });
      }

      vm.teamSet.setLeaveTeamCallback(initialize);

      function acceptedRequestInvite(requestInvite, isGroup) {
        if (!requestInvite.errors && isGroup === vm.teamSet.isGroup) {
          if (requestInvite.className === 'TeamMember') {
            // Only need to perform these actions on Joining a Team accepting a membership invitation
            // No need to perform these action When a membership request accepts
            vm.teamSet.setCurrentJoinedTeam(requestInvite.team);

            if (requestInvite.team.pointsReceived && requestInvite.team.pointsReceived > vm.teamSet.pointsReceived) {
              vm.teamSet.pointsReceived = requestInvite.team.pointsReceived;
            }
            updateTimelineProgress(requestInvite.team);
          }
          ReactLecturePageContext.updateLecturePage();
        }
      }

      function updateTimelineProgress(team) {
        if (team.pointsReceived) {
          ReactTimelineService.updateTimeline($stateParams.id);
          TimelinesManager.updateComponentPointsAndProgress($stateParams.id, vm.lectureComponent.type, vm.lectureComponent.id,
            team.pointsReceived);

          vm.showPointsModal(team, null);
        } else {
          ReactTimelineService.updateTimeline($stateParams.id);
          TimelinesManager.updateComponentProgress($stateParams.id, vm.lectureComponent.type, vm.lectureComponent.id, 'completed');
        }
      }

      function submit() {
        if (vm.selectedOption === 'create') {
          openCreateTeamModal();
        } else if (vm.selectedOption === 'join') {
          $state.go(vm.teamSet.isTeam ? 'team-directory' : 'group-directory', { catalogId: $state.params.catalogId, teamFilter: 'joinable' });
        }
      }

      function openCreateTeamModal() {
        return $uibModal.open({
          templateUrl: 'teams/templates/new-team-form.html',
          controller: 'TeamFormModalCtrl',
          controllerAs: 'vm',
          resolve: {
            modalOptions() {
              return {
                headerKey: 'TEAM_FORMATION.CREATE_YOUR_TEAM',
                confirmTextKey: 'FORM.CREATE',
                confirmInProgressTextKey: 'FORM.CREATING',
                model: {
                  team: TeamManager.newTeam(vm.teamSet),
                  currentCourse: vm.CurrentCourseManager.course,
                },
                afterConfirm(createdTeam) {
                  createdTeam.currentAccountTeamMember.team.courseCatalogId = createdTeam.courseCatalogId;
                  TeamManager.addMembership(createdTeam.currentAccountTeamMember);
                  vm.teamSet.setCurrentCreatedTeam(createdTeam);
                  if (createdTeam.pointsReceived && createdTeam.pointsReceived > vm.teamSet.pointsReceived) {
                    vm.teamSet.pointsReceived = createdTeam.pointsReceived;
                  }
                  updateTimelineProgress(createdTeam);

                  ReactLecturePageContext.updateLecturePage();
                },
              };
            },
          },
        });
      }

      function openConfirmRequestDeletionModal(request) {
        /* eslint-disable no-shadow */
        ConfirmationOverlays.openConfirmationModal('invitations_and_requests/templates/delete-membership_request.html',
          ['$scope', 'teamName', function ($scope, teamName) {
            $scope.teamName = teamName;
          }], { teamName: () => request.team.name }).result.then(() => {
          InvitationsAndRequestsManager.cancelRequest(request);
        });
        /* eslint-enable no-shadow */
      }

      function getUnapprovedOutstandingInvitesCount() {
        const list = getOutstandingInvites();
        return _.filter(list, (item) => !item.approved || item.errors).length;
      }

      function getUnapprovedOutstandingRequestsCount() {
        const list = getOutstandingRequests();
        return _.filter(list, (item) => !item.approved).length;
      }

      function getOutstandingInvites() {
        if (vm.teamSet.isTeam) {
          return InvitationsAndRequestsManager.getTeamSpecificInvitations(CurrentCourseManager.course);
        }
        return InvitationsAndRequestsManager.getGroupSpecificInvitations(CurrentCourseManager.course);
      }

      function getOutstandingRequests() {
        if (vm.teamSet.isTeam) {
          return InvitationsAndRequestsManager.getTeamSpecificMembershipRequestsMadeByUser(CurrentCourseManager.course);
        }
        return InvitationsAndRequestsManager.getGroupSpecificMembershipRequestsMadeByUser(CurrentCourseManager.course);
      }

      $scope.$on('$destroy', () => {
        removeCallback?.();
      });
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'exercises/templates/team_deliverable/nv-exercise-student-team-formation.html',
  };
}

