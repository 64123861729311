import { useEffect, useState } from 'react';
import t from 'react-translate';
import NvDropdown, { NvDropdownButtonStyle, NvDropdownAlign } from 'shared/components/inputs/nv-dropdown';
import EditSkillField from './edit-skill-field';
import { config } from '../../../config/pendo.config.json';

const SkillsListAutocomplete = ({ tags, onCancel, onAddSkill }) => {
  const [showList, setShowList] = useState(false);
  const [value, setValue] = useState('');
  const [items, setItems] = useState([]);
  const [saving, setSaving] = useState(false);
  const onSelectItem = (id, text) => {
    setSaving(true);
    setValue(text);
    setShowList(false);
    onAddSkill(id);
  };
  const getFilteredItems = () => {
    let filtereditems: Array<any> = Object.values(tags);
    filtereditems = filtereditems.filter(item => item.name?.toLowerCase().startsWith(value.toLocaleLowerCase()));
    filtereditems = filtereditems.map((item, index) => ({
      id: item.id, text: item.name, type: 'text', callback: () => onSelectItem(item.id, item.name), dataQa: `${config.pendo.skillTags.suggestion}${index}` }
    ));
    filtereditems.unshift({ type: 'header', title: `${filtereditems.length ? t.INSTITUTIONS.SKILL_TAGS.SUGGESTIONS() : t.INSTITUTIONS.SKILL_TAGS.NO_MATCHES()} ` });
    return filtereditems;
  };
  useEffect(() => {
    if (!saving) {
      setShowList(value.length > 1);
      if (value.length > 1) {
        setItems(getFilteredItems());
      }
    }
  }, [value]);

  const changeHandler = (val) => setValue(val);
  // TODO: Improve autocomplete by debouncing. not needed for now.
  // useCallback(debounce(() => console.log('updated ', value), 300),[value],);
  // const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), [],);

  return (
    <NvDropdown
      buttonStyle={NvDropdownButtonStyle.CUSTOM}
      minWidth={200}
      customTarget={() => <EditSkillField value={value} onClose={onCancel} onChange={changeHandler} disabled={saving} />}
      align={NvDropdownAlign.LEFT}
      items={items}
      show={showList}
    />
  );
};
export default SkillsListAutocomplete;
