/* eslint-disable max-classes-per-file */
import { black, gray6, gray5, gray4, gray3, gray2, gray1 } from '../../../styles/global_defaults/colors';

const white = '#ffffff';

const baseSectionByType = {
  more_less: {
    i18nKey: 'ACCORDION_STYLE_1',
    viewOptions: {
      icon_color: gray3,
      line_color: gray3,
    },
  },

  more_less_circled: {
    i18nKey: 'ACCORDION_STYLE_2',
    viewOptions: {
      icon_color: gray2,
      header_background_color: gray5,
    },
  },
  decimal: {
    i18nKey: 'ACCORDION_STYLE_3',
    viewOptions: {
      icon_color: white,
      header_background_color: gray4,
      text_background_color: gray6,
      left_hand_symbol_color: white,
      left_hand_symbol_background_color: gray3,
      left_hand_symbol_shape: 'square',
      left_hand_symbol_position: 'centered',
    },
  },
  alphabetical: {
    i18nKey: 'ACCORDION_STYLE_4',
    viewOptions: {
      icon_color: gray1,
      header_background_color: gray5,
      text_background_color: gray6,
      left_hand_symbol_color: black,
      left_hand_symbol_background_color: '#ffffff',
      left_hand_symbol_shape: 'circle',
      left_hand_symbol_position: 'centered',
    },
  },
  custom_symbol: {
    i18nKey: 'ACCORDION_STYLE_5',
    viewOptions: {
      icon_color: white,
      header_background_color: gray3,
      text_background_color: gray6,
      left_hand_symbol_color: white,
      left_hand_symbol_background_color: gray3,
      left_hand_symbol_shape: 'square',
      icon_library_name: null,
    },
  },
};

/* @ngInject */
export default function AccordionLectureComponentModel(_, LectureComponentBaseModel) {
  function classFactory(type) {
    class AccordionLectureComponentWithType extends LectureComponentBaseModel {
      constructor(attributes) {
        const defaults = {
          canBeCopied: true,
          type: 'AccordionLectureComponent',
          view_options: {
            list_style_type: type,
          },
          accordion_sections: [
            {
              index: 0,
              content: '',
              header: '',
              view_options: {
                ...baseSectionByType[type].viewOptions,
              },
            },
          ],
        };

        super({ ...defaults, ...attributes });
      }

      getPayload() {
        return _.pick(this, ['index', 'accordion_sections', 'view_options']);
      }
    }

    AccordionLectureComponentWithType.iconClass = 'icon-admin-template';
    AccordionLectureComponentWithType.descriptionKey = `LECTURE_PAGES.COMPONENTS.${baseSectionByType[type].i18nKey}.DESCRIPTION`;
    AccordionLectureComponentWithType.type = 'AccordionLectureComponent';
    return AccordionLectureComponentWithType;
  }

  class AccordionLectureComponent extends LectureComponentBaseModel {
    constructor(attributes) {
      super({ canBeCopied: true, ...attributes });
    }

    getPayload() {
      return _.pick(this, ['index', 'accordion_sections', 'view_options']);
    }

    static componentFactory(type) {
      return classFactory(type);
    }
  }

  AccordionLectureComponent.iconClass = 'icon-admin-template';
  AccordionLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.ACCORDION.DESCRIPTION';
  AccordionLectureComponent.type = 'AccordionLectureComponent';

  return AccordionLectureComponent;
}
