import PointsBreakDownModal from '../../timelines/components/points-break-down-modal';

/* @ngInject */
export default function PointsBreakdownModalController(
  $stateParams,
  user,
  course,
  showOutline,
  selectedTab,
  $scope,
) {
  const vm = this;

  $scope.PointsBreakDownModal = PointsBreakDownModal;
  $scope.userId = user?.id || $stateParams.userId;
  $scope.userFullName = user?.fullName;
  $scope.courseCompletionStatus = course?.completionStatus;
  $scope.courseCompletionProgress = course?.completionProgress;
  $scope.showOutline = showOutline;
  $scope.selectedTab = selectedTab;

  $scope.$on('$destroy', () => {

  });

  return vm;
}
