import React from 'react';
import t from 'react-translate';
import { Global, css } from '@emotion/react';

// components
import { NvModal, ModalType } from 'shared/components/nv-modal';
import LinkLessonsModalBody from './link-lessons-modal-body';

export type LinkLessonsModalProps = {
  show: boolean,
  onClose(): void,
  sectionId: number,
  courseId: number,
};

const styles = css`
  .overflow-clip {
    overflow: clip !important;
  }
`;

const LinkLessonsModal = (props: LinkLessonsModalProps) => {
  const { show, onClose, sectionId, courseId } = props;

  return (
    <React.Fragment>
      <Global
        styles={styles}
      />
      <NvModal
        show={show}
        width={800}
        onClose={onClose}
        type={ModalType.DYNAMIC}
        header={t.TIMELINE.LESSON_LINK_MODAL.TITLE()}
        bodyClassName='bg-gray-7 p-0 overflow-clip'
        fullHeight={false}
        body={(
          <LinkLessonsModalBody
            onClose={(onClose)}
            sectionId={sectionId}
            courseId={courseId}
          />
        )}
      />
    </React.Fragment>
  );
};

export default LinkLessonsModal;
