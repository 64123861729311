import { getLhsSections } from 'lhs/services/lhs-menu-item-data';
import React from 'react';
import { AngularContext } from 'react-app';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { MyAccount } from 'redux/schemas/models/my-account';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { getCurrentUser, getCurrentUserId, getCurrentUserMentoringInfo } from 'redux/selectors/users';
import { useAngularExpression } from 'shared/hooks/use-angular-expression';
import LhsMenuSection from './lhs-menu-section';

export const LhsMenuContainer = () => {
  const { $scope, injectServices } = React.useContext(AngularContext);
  const [
    $state,
    $timeout,
    FlyoutModalManager,
    CurrentPermissionsManager,
    CurrentUserManager,
    InteroperabilityRoutes,
  ] = injectServices([
    '$state',
    '$timeout',
    'FlyoutModalManager',
    'CurrentPermissionsManager',
    'CurrentUserManager',
    'InteroperabilityRoutes',
  ]);
  const isExpandedLhs = useAngularExpression(
    () => $state.current.data.expandedLHS,
  );
  const currentStateName = useAngularExpression(() => $state.current.name);
  const currentFlyoutName = useAngularExpression(() => FlyoutModalManager.flyoutName());
  const currentUserMentoringInfo = useSelector(getCurrentUserMentoringInfo);
  const allCourses = useSelector(state => state.models.courses);
  const currentUserId = useSelector(getCurrentUserId);
  const { hasMemberships }: MyAccount = useSelector(getCurrentUser);
  const { discoveryEnabled } = useSelector(getCurrentInstitution);

  const lhsSections = getLhsSections(
    t,
    $scope,
    $state,
    $timeout,
    isExpandedLhs,
    currentStateName,
    currentFlyoutName,
    FlyoutModalManager,
    CurrentPermissionsManager,
    CurrentUserManager,
    currentUserMentoringInfo,
    InteroperabilityRoutes,
    allCourses,
    currentUserId,
    hasMemberships,
    discoveryEnabled,
  );

  return (
    <React.Fragment>
      {lhsSections.length > 0
        && lhsSections.map(({ id, borderTop, menuItems }) => (
          <LhsMenuSection
            key={id}
            id={id}
            borderTop={borderTop}
            menuItems={menuItems}
          />
        ))}
    </React.Fragment>
  );
};

export default LhsMenuContainer;
