import { css } from '@emotion/react';
import React from 'react';
import t from 'react-translate';
import { VideoPracticeActivity, VideoPracticeOption, VideoPracticeType } from 'redux/schemas/models/video-practice';
import { warning, gray4 } from 'styles/global_defaults/colors';
import { semiBoldFontWeight } from 'styles/global_defaults/fonts';

type VideoPracticeTitleProps = {
  practiceType: VideoPracticeType;
  activityTitle: string;
  activity: VideoPracticeActivity;
  take?: number;
};

const VideoPracticeTitle = ({
  practiceType,
  activityTitle,
  activity,
  take,
}: VideoPracticeTitleProps) => {
  const styles = css`
    color: ${gray4};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .take {
      color: white;
    }

    .last-take {
      color: ${warning};
    }

    .title, .dash {
      color: ${gray4};
      font-weight: ${semiBoldFontWeight};
    }
  `;

  return (
    <div css={styles} className='video-practice-title'>
      {practiceType === VideoPracticeOption.ON_SPOT && (
        <React.Fragment>
          {take >= activity.maxTries ? (
            <span className='last-take text-label'>
              {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.LAST_TAKE()}
            </span>
          ) : (
            <span className='take text-label'>
              {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.TAKE_INDEX(activity.maxTries, take)}
            </span>
          )}
          <span className='dash'> - </span>
        </React.Fragment>
      )}
      <span className='title text-label'>
        {activityTitle}
      </span>
    </div>
  );
};

export default VideoPracticeTitle;
