import React, { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { AngularServicesContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { getAllFeedback } from 'redux/actions/skills-feedback';
import { SkillsFeedbackRequest } from 'redux/schemas/api/skills-feedback';
import { ExerciseSubmissionState } from 'redux/schemas/models/exercise';
import DisplaySkillRating from 'shared/components/skill-feedback/display-skill-ratings';
import { Tabs } from '../skills-rating-tabs';

type UserFeedbackTabProps = {
  reportId: number,
  feedbackBy: number,
};

const UserFeedbackTab = ({ reportId, feedbackBy }: UserFeedbackTabProps) => {
  const { $state } = useContext(AngularServicesContext);
  const { catalogId } = $state?.params;
  const dispatch = useAppDispatch();
  const ref = useRef(null);

  const currentInstitutionId = useSelector(
    (state) => state.app.currentInstitutionId,
  );
  const exerciseSubmissionstate = (useSelector(
    state => state.app.exerciseSubmissions,
  ) as ExerciseSubmissionState)?.[reportId];

  useEffect(() => {
    setTimeout(() => {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    });
  }, []);

  useEffect(() => {
    const params: SkillsFeedbackRequest = {
      institutionId: currentInstitutionId,
      ownerId: reportId,
      ownerType: 'Report',
      displayOwnFeedback: true,
      userId: feedbackBy,
    };

    dispatch(getAllFeedback(params));
  }, [currentInstitutionId, dispatch, feedbackBy, reportId]);

  return (
    <div ref={ref}>
      <DisplaySkillRating
        isLoaded={exerciseSubmissionstate?.feedbackByUserOnceLoaded}
        ratingIds={exerciseSubmissionstate?.ratingsByUser?.[feedbackBy] ?? []}
        onToggle={() => (
          $state.go('individual-submission-modal', {
            catalogId,
            reportId,
            selected: Tabs.ALL_FEEDBACK,
            userId: null,
          })
        )}
      />
    </div>
  );
};

export default UserFeedbackTab;
