import { ComponentType } from 'redux/schemas/models/lecture-component';
import mergeWith from 'lodash/mergeWith';
import getDefaultNewComponentData from '../components/data/new-component-defaults';
import { useAngularPreventLecturePageNavigation } from '../hooks/use-prevent-lecture-page-navigation';

/* @ngInject */
export default function LectureComponentModalCtrl(
  $scope,
  $uibModalInstance,
  $window,
  ConfirmationOverlays,
  StateManager,
  _,
  vmResolves,
) {
  const vm = this;
  let dismissedByUser = false;
  _.extend(vm, vmResolves);

  vm.formName = vm.formName || vm.lectureComponent.formName;
  vm.saveDisabled = true;
  vm.isContentManagementCollection = vm.CurrentCourseManager.course.isContentManagementCollection;
  vm.isLinked = vm.lectureComponent?.lecturePage?.isLinked;

  // This code is added specifically for the exercise approval edit modal popovers. See NOV-68550 for details
  vm.exerciseApprovalPopovers = Object.fromEntries(vm.lectureComponent?.exerciseDraft?.adminReviewers.concat(vm.lectureComponent.exerciseDraft.relationshipReviewers).map((role) => [role.id]) || []);
  vm.showPopover = (roleId) => {
    vm.exerciseApprovalPopovers[roleId] = true;
  };
  vm.hidePopover = (roleId) => {
    vm.exerciseApprovalPopovers[roleId] = false;
  };

  vm.save = (result) => {
    // TODO: Figure out what these are for and port them
    // looks like they're for disabling component adds
    if (vm.lectureComponent.type === 'GroupFormationLectureComponent') {
      vm.CurrentCourseManager.course.hasGroupSet = true;
    }
    dismissedByUser = true;
    $uibModalInstance.close(result);
  };

  function hasUnsavedChanges() {
    return vm[vm.formName] && vm[vm.formName].$dirty;
  }

  vm.updateSaveDisabled = () => {
    if (vm.lectureComponent.exerciseDraft.approvalRequired) {
      vm.saveDisabled = _.every(vm.lectureComponent.exerciseDraft.adminReviewers.concat(vm.lectureComponent.exerciseDraft.relationshipReviewers), (role) => !role.isEnabled);
    } else {
      vm.saveDisabled = false;
    }
  };

  useAngularPreventLecturePageNavigation($scope, hasUnsavedChanges);

  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(
    hasUnsavedChanges,
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    'FORM.NAVIGATE_AWAY.CLOSE_WINDOW',
    undefined,
    undefined,
    () => {
      vm.deregisterModalClosing?.();

      $uibModalInstance.dismiss();
    },
  );

  vm.deregisterModalClosing = $scope.$on('modal.closing', ($event) => {
    if (hasUnsavedChanges() && !dismissedByUser) {
      $event.preventDefault();

      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-inline-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        /**
         * Updating the lecture component with default component data for
         * external tool components when discarding the form modal.
         * This is used for clearing the existing lecture component draft data
         * as per this issue https://novoed.atlassian.net/browse/NOV-76750.
         */
        if (
          vm.workflowCtx?.mode === 'new'
          && vm.lectureComponent?.type === ComponentType.EXTERNAL_TOOL
        ) {
          mergeWith(vm.lectureComponent, getDefaultNewComponentData(vm.lectureComponent.trueType));
        }

        dismissedByUser = true;
        $uibModalInstance.dismiss();
      });
    }
  });

  $scope.$on('$destroy', () => {
    angular.element($window).off('beforeunload');
    vm.deregisterStateChangeStart?.();
  });
}
