const PENDO_RETRY_LIMIT = 20;
const PENDO_RETRY_TIME = 1000; // 1 second

/**
 * Pendo Agent may not be available by the time our code loads, adding safety net + retry
 * based on: https://support.pendo.io/hc/en-us/articles/360032294291-Track-Events-Configuration
 */
/* @ngInject */
export default function pendoTrack(name, data, retryCount = 0) {
  if (window?.pendo?.isReady?.()) {
    window.pendo.track(name, data);
    return;
  }

  if (retryCount < PENDO_RETRY_LIMIT) {
    setTimeout(() => {
      pendoTrack(name, data, retryCount + 1);
    }, PENDO_RETRY_TIME);
  }
}
