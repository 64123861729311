/* @ngInject */
export default function LeaderboardController(
  $state,
  $stateParams,
  LeaderboardManager,
  CurrentCourseManager,
) {
  const vm = this;
  vm.manager = LeaderboardManager;
  vm.CurrentCourseManager = CurrentCourseManager;

  if (CurrentCourseManager.course.isLeaderboardEnabled()) {
    vm.manager.initialize($stateParams.catalogId);
  } else {
    $state.go('course-home', { catalogId: $stateParams.catalogId });
  }
}
