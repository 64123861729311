/* @ngInject */
export default function NvUniqueEntry(
  $parse,
  _,
) {
  return {
    require: 'ngModel',
    link(scope, elem, attrs, ngModel) {
      ngModel.$validators.custom = function (modelValue, viewValue) {
        const entries = $parse(attrs.nvUniqueEntry)(scope);
        // Ensures the role name is unique by failing validation if another role has the same name
        // as the draft. Filters out by editingId to allow for editing roles
        return !_.values(entries).some((entry) => modelValue && entry.toLowerCase().trim() === modelValue.toLowerCase().trim());
      };
    },
  };
}
