/* @ngInject */
export default function NotificationsResources($resource) {
  return $resource(
    '/notifications/:notificationId.json',
    { catalogId: '@catalogId', notificationId: '@notificationId' },
    {
      getNumUnreadNotifications: { method: 'GET', url: '/users/num_unread_notifications.json' },
      getPracticeRoomNumUnreadNotifications: { method: 'GET', url: '/practice_room/unread_notifications.json' },
      getCourseNumUnreadNotifications: { method: 'GET', url: '/:catalogId/user_courses/num_unread_notifications.json' },
      updateCourseNotification: { method: 'PUT', url: '/:catalogId/notifications/:notificationId.json' },
      updatePracticeRoomNotification: { method: 'PUT', url: '/practice_room/:notificationId.json' },
      markAllPracticeRoomRead: { method: 'PUT', url: '/practice_room/mark_notifications_as_read.json' },
      markAllRead: { method: 'PUT', url: '/:catalogId/user_courses/mark_notifications_as_read.json' },
    },
  );
}
