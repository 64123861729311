import React from 'react';
import t from 'react-translate';
import prodPathReplace from 'shared/prod-path-rewrite';

// Redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/schemas';
import { CourseAliases } from 'redux/schemas/models/course';
import { getCourseAliases } from 'redux/selectors/course';

// Styles
import { css } from '@emotion/react';
import { headerColor } from 'styles/global_defaults/colors';
import { animationSize } from 'styles/global_defaults/scaffolding';
import {
  boldFontWeight,
  headerFontSize,
  headerLargeLineHeight,
  openSansCondensed,
} from 'styles/global_defaults/fonts';

// Components
import NvCongratulationsMessage from './nv-congratulations-message';
import { NvCelebrationModalProps } from './nv-celebration-modal';

const styles = css`
  .image-badge {
    height: ${animationSize}px;
    width: ${animationSize}px;
  }
  .earned-points {
    font-size: ${headerFontSize}px;
    font-weight: ${boldFontWeight};
    color: ${headerColor};
    line-height: ${headerLargeLineHeight}px;
    font-family: ${openSansCondensed};
  }
`;

const NvPointsAnimation = (props: NvCelebrationModalProps) => {
  const {
    pointsReceived,
    leaderboardPoints,
    extras,
  } = props;

  const aliases = useSelector<RootState, CourseAliases>(
    (state: RootState) => getCourseAliases(state),
  );

  const pointsAliases = {
    pointsAliasSingularized: aliases.pointsAliases.pointAlias,
    pointsAliasPluralized: aliases.pointsAliases.pointsAlias,
  };

  let conditions = {
    allCorrect: false,
    firstAttemptAndNotAllCorrect: false,
    lowerScoreThanPreviousAttempt: false,
    higherScoreThanPreviousAttempt: false,
  };

  if (extras) {
    const {
      submissionScore,
      maxScore,
      pointsReceivedFromPreviousAttempt,
    } = extras;

    const allCorrect = submissionScore ? submissionScore === maxScore : false;
    const firstAttemptAndNotAllCorrect = pointsReceivedFromPreviousAttempt === null;
    const lowerScoreThanPreviousAttempt = pointsReceived < pointsReceivedFromPreviousAttempt;
    const higherScoreThanPreviousAttempt = pointsReceived >= pointsReceivedFromPreviousAttempt;

    conditions = {
      allCorrect,
      firstAttemptAndNotAllCorrect,
      lowerScoreThanPreviousAttempt,
      higherScoreThanPreviousAttempt,
    };
  }

  return (
    <div css={styles}>
      <img
        className='image-badge mb-4'
        src={prodPathReplace('images/points.gif')}
        alt={t.LEARNING_JOURNEYS.COURSE_HOME.MODAL.CONGRATULATIONS()}
      />
      <NvCongratulationsMessage extras={props.extras} {...conditions} />
      <div className='page-title earned-points'>
        {conditions.lowerScoreThanPreviousAttempt
          ? <div>{t.POINTS.EARNED_POINTS_NOW({ points: pointsReceived, ...pointsAliases })}</div>
          : <div>{t.POINTS.EARNED_POINTS({ points: pointsReceived, ...pointsAliases })}</div>}
      </div>
      {leaderboardPoints > 0 && (
        <div className='page-title total-points'>
          {t.POINTS.TOTAL_POINTS({ points: leaderboardPoints, ...pointsAliases })}
        </div>
      )}

    </div>
  );
};

export default NvPointsAnimation;
