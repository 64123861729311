import { css } from '@emotion/react';
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { useSelector } from 'react-redux';

import { CollectionCourse } from 'redux/schemas/models/collections';
import { getCourse } from 'redux/selectors/course';
import NvDropdown, { NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { NvModal, ModalType } from 'shared/components/nv-modal';
import { gray4 } from 'styles/global_defaults/colors';
import { largeSpacing } from 'styles/global_defaults/scaffolding';
import { AngularServicesContext } from 'react-app';
import { CourseRole } from 'redux/schemas/models/org-level-roles';
import { RootState } from 'redux/schemas';
import { Role } from 'redux/schemas/models/role';
import { getCollectionRoles } from 'redux/selectors/roles';
import { find } from 'underscore';
import { RolesService } from 'institutions/services/roles-service';

export type ManagePermissionModalProps = {
  show: boolean,
  catalogId: string,
  closeModal(): void,
  onJoin(collection: CollectionCourse, courseRoleId: string): void
};

const bodyStyles = css`
  .line {
    height: 1px;
    width: ${largeSpacing}px;
    background-color: ${gray4};
  }

  .bs4-dropdown {
    width: 220px;
  }
`;

const ManagePermissionModal = (props: ManagePermissionModalProps) => {
  const { show, catalogId, closeModal, onJoin } = props;

  const collection: CollectionCourse = useSelector((state) => getCourse(state, catalogId));
  const collectionRoles: CourseRole[] = useSelector<RootState, Role>(getCollectionRoles);

  const { $state } = useContext(AngularServicesContext);
  const adminRole = find(collectionRoles, (role) => RolesService.isCollectionAdminRole(role));

  const roleItems: NvDropdownOption[] = [
    {
      type: 'text',
      text: adminRole?.name,
    },
  ];

  const getUserManagementLink = (): string => {
    // Check if collection is null or undefined before proceeding
    if (!collection || !catalogId) {
      return ''
    }
    return $state.href(
      'content-library-collection-user-management',
      { collectionId: collection.id, catalogId: catalogId }
    );
  };

  return (
    <NvModal
      show={show}
      type={ModalType.FIXED}
      header={t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.MANAGE_ACCESS_MODAL.TITLE()}
      width={600}
      height={460}
      onClose={closeModal}
      body={(
        <div css={bodyStyles}>
          <div className='text-body mt-1'>
            {t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.MANAGE_ACCESS_MODAL.DESCRIPTION()}
          </div>
          <div className='d-flex my-7'>
            <NvDropdown
              items={roleItems}
              buttonStyle={NvDropdownButtonStyle.FORM}
              tooltip={t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.MANAGE_ACCESS_MODAL.DISABLED_ROLE_TOOLTIP()}
              disabled
            />
            <Button
              variant='primary'
              className='ml-1'
              onClick={() => onJoin(collection, adminRole.id.toString())}
            >
              {t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.MANAGE_ACCESS_MODAL.JOIN_COLLECTION()}
            </Button>
          </div>
          <div className='d-flex align-items-center justify-content-center mb-1'>
            <div className='line' />
            <span className='text-gray-4 bold px-2'>{t.SHARED.OR()}</span>
            <div className='line' />
          </div>
          <div className='text-body mt-5'>
            {t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.MANAGE_ACCESS_MODAL.GOTO_USER_MANAGEMENT(getUserManagementLink())}
          </div>
          <div className='d-flex justify-content-center'>
            <Button
              className='mt-6'
              variant='primary'
              onClick={closeModal}
            >
              {t.FORM.CLOSE()}
            </Button>
          </div>
        </div>
      )}
    />
  );
};

export default ManagePermissionModal;

