import store from 'redux/store';
import { ActivityKey } from 'redux/schemas/models/activity';
import { setActivityProgress } from 'redux/actions/lecture-components';

/* @ngInject */
export default function TimelinePeerReviewLectureComponentModelService(

  _,
  moment,
  nvUtil,
) {
  const LOCAL_STATUS_MAPPING = {
    started: 'in_progress',
    above_completed: 'completed',
  };

  const TimelinePeerReviewLectureComponentModel = function (attribtues) {
    const currentMoment = moment();
    let deadlineMoment;
    const _this = this;
    const base = {
      templateUrl: 'timelines/templates/nv-timeline-peer-review-lecture-component.html',
      canHaveHardDeadline: true,
    };

    /** Setting Up Basic Attributes * */
    _.extend(_this, base, attribtues);
    // passed lecturePage, lectureSection, course
    preprocess();

    /** Public Functions * */
    _.extend(_this, {
      getStatus,
      isCompleted,
      isMissed,
      isTodoRequiredForCompletion,
      updateComponentPointsAndProgress,
      updateComponentProgress,
      showPointsDenominator,
      getPayload,
      hasDeadline,
      setDeadline,
      showWarning,
      updateDependentActivitiesReleaseDate,
    });

    /** Function Declarations * */
    /* Used Publicly */
    function getStatus() {
      return LOCAL_STATUS_MAPPING[_this.progress] ? LOCAL_STATUS_MAPPING[_this.progress] : _this.progress;
    }

    function isCompleted() {
      return getStatus() === 'completed';
    }

    function isMissed() {
      return getStatus() === 'missed';
    }

    function isTodoRequiredForCompletion() {
      return _this.peerEvaluation.isRequiredForCompletion;
    }

    function updateComponentPointsAndProgress(componentType, componentId, pointsReceived, totalPoints, progress) {
      if (componentType === 'exercise_peer_review' && _this.peerEvaluation.id === componentId) {
        _this.peerEvaluation.numEvaluated += 1;
        if (_this.peerEvaluation.numEvaluated === _this.peerEvaluation.targetGoal) {
          _this.progress = progress;
        } else if (_this.peerEvaluation.numEvaluated === 1) {
          _this.progress = 'in_progress';
        }
        _this.peerEvaluation.pointsReceived = pointsReceived;
        _this.pointsReceived = _this.peerEvaluation.pointsReceived;

        store.dispatch(setActivityProgress({
          progress: _this.progress,
          pointsReceived: _this.pointsReceived,
          activityKey: ActivityKey.CUSTOM_QUESTIONS,
          activityPayloadId: _this.peerEvaluation.id,
        }));

        if (_.isNumber(totalPoints)) {
          _this.currentTotalPoints = totalPoints;
        }

        viewPreprocess();

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function updateComponentProgress(componentType, componentId, progress) {
      if (componentType === 'exercise_peer_review' && _this.peerEvaluation.id === componentId) {
        _this.peerEvaluation.numEvaluated += 1;
        if (_this.peerEvaluation.numEvaluated === _this.peerEvaluation.targetGoal) {
          _this.progress = progress;
        } else if (_this.peerEvaluation.numEvaluated === 1) {
          _this.progress = 'in_progress';
        }

        store.dispatch(setActivityProgress({
          progress: _this.progress,
          activityKey: ActivityKey.CUSTOM_QUESTIONS,
          activityPayloadId: _this.peerEvaluation.id,
        }));

        viewPreprocess();

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function getPayload() {
      return _.pick(_this, ['title', 'expirationDate', 'hardDeadline']);
    }

    function hasDeadline() {
      return !!this.expirationDate;
    }

    function setDeadline(newDeadline) {
      this.expirationDate = newDeadline;
    }

    /* Used Privately */
    function preprocess() {
      if (!_this.peerEvaluation) {
        return;
      }

      if (_this.peerEvaluation?.expirationDate) {
        deadlineMoment = moment(_this.peerEvaluation.expirationDate);
      }

      _.extend(_this, _.pick(_this.peerEvaluation, 'title', 'hardDeadline', 'isTodo', 'hasStructuralIssues',
        'expirationDate', 'expired', 'pointsReceived', 'totalPoints', 'progress', 'scheduledCommunicationsCount'));
      _this.activityId = _this.peerEvaluation.id;

      _this.currentTotalPoints = nvUtil.getCurrentTotalPoints(_this.peerEvaluation.totalPoints, _this.peerEvaluation.releaseDate,
        _this.course.expiringPointsEnabled, _this.peerEvaluation.expired);

      viewPreprocess();
    }

    function viewPreprocess() {
      _this.showDueSoon = showDueSoon();

      _this.displayTotalPointsOnly = !_this.pointsReceived && _this.currentTotalPoints && !isMissed();
    }

    function showDueSoon() {
      if (_this.peerEvaluation.expirationDate) {
        return (getStatus() === 'in_progress' || getStatus() === 'not_started')
            && moment(_this.lecturePage.releaseDate) < currentMoment
            && deadlineMoment > currentMoment
            && deadlineMoment < moment().add(7, 'days');
      }
      return false;
    }

    function showWarning() {
      return _this.peerEvaluation.exercise && moment(_this.lecturePage.releaseDate) < moment(_this.peerEvaluation.exercise.releaseDate);
    }

    function showPointsDenominator() {
      return _this.currentTotalPoints > _this.peerEvaluation.pointsReceived && !isCompleted() && !isMissed();
    }

    function updateDependentActivitiesReleaseDate(changedLecturePage) {
      if (_this.peerEvaluation.exercise?.lecturePageId === changedLecturePage.id) {
        _this.peerEvaluation.exercise.releaseDate = changedLecturePage.releaseDate;
      }
    }
  };

  return TimelinePeerReviewLectureComponentModel;
}
