import React from 'react';

import moment from 'moment';

import { connect, useSelector } from 'react-redux';
import t from 'react-translate';

// schemas
import { RootState } from 'redux/schemas';
import { NTimelinePeerEvaluation } from 'redux/schemas/models/peer-evaluation';
import { ActivityType } from 'redux/schemas/models/activity';
import { ComponentKeyPluralized } from 'redux/schemas/models/lecture-component';

// actions
import { setActivityExpandedInAutomatedCommunications } from 'redux/actions/course-communications';

// selectors
import { getPeerEvaluation, hasPeerEvaluationGotCommunicationError } from 'redux/selectors/timeline';
import { isActivityExpandedInAutomatedCommunications } from 'redux/selectors/course-communications';
import { getCourseAliases } from 'redux/selectors/course';

// components
import NvIcon from 'shared/components/nv-icon';
import { NvTooltip } from 'shared/components/nv-tooltip';
import NvStackedTab from 'shared/components/nv-stacked-tab';

import CommunicationCreateNewDropdown from 'communications/course_communications/components/communication-create-new-dropdown';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';

type PeerEvaluationLectureComponentProps = {
  peerEvaluationId: number,
};

type StateProps = {
  peerEvaluation: NTimelinePeerEvaluation,
  isPublicEvaluation?: boolean,
  hasCommunicationError: boolean,
};

const mapDispatchToProps = {
  setExpanded: setActivityExpandedInAutomatedCommunications,
};

const PeerEvaluationLectureComponent = (props: PeerEvaluationLectureComponentProps & StateProps & typeof mapDispatchToProps) => {
  const aliases = useSelector((state) => getCourseAliases(state));

  const isExpanded = useSelector((state) => isActivityExpandedInAutomatedCommunications(state, ComponentKeyPluralized.PEER_EVALUATION, props.peerEvaluationId));

  return (
    <React.Fragment>
      <div className={`lecture-component-row ${isExpanded && props.peerEvaluation?.communicationsCount > 0 && 'expanded-box'}`}>
        <NvIcon icon={props.isPublicEvaluation ? 'comments' : 'evaluation'} size='smallest' />
        <div className='description'>{props.peerEvaluation?.title}</div>
        {props.peerEvaluation?.hasStructuralIssues && (
          <div className='text-danger spacing'>
            <NvIcon icon='warning' size='xss-smallest' />
          </div>
        )}
        {props.peerEvaluation?.hardDeadline && (
          <NvTooltip text={t.TIMELINE.HARD_DEADLINE()} placement='top'>
            <div className='spacing'>
              <NvIcon icon='harddeadline' size='smallest' />
            </div>
          </NvTooltip>
        )}
        <div className='date'>
          {props.peerEvaluation?.expirationDate && t.LECTURE_PAGES.COMPONENTS.DUE_DATE.STUDENT_VIEW(moment(props.peerEvaluation.expirationDate).format('LLL'))}
        </div>
        <div className=''>
          <CommunicationCreateNewDropdown
            activityType={ActivityType.PEER_EVALUATION}
            activityId={props.peerEvaluationId}
            disabledText={!props.peerEvaluation.exercise?.customQuestions?.regularEvaluation
              && t.COURSE_COMMUNICATIONS.TOOLTIP.CREATE_DISABLED_TEACHING_TEAM_EVAL(aliases.teachingTeamAliases)}
          />
        </div>
        { props.peerEvaluation?.communicationsCount > 0 && (
          <NvStackedTab
            count={props.peerEvaluation.communicationsCount}
            hasError={props.hasCommunicationError}
            isExpanded={isExpanded}
            errorMessage={t.COURSE_COMMUNICATIONS.WARNINGS.ACTIVITY_COMMUNICATION()}
            onClick={() => props.setExpanded({
              type: ComponentKeyPluralized.PEER_EVALUATION,
              id: props.peerEvaluationId,
              isExpanded: !isExpanded,
            })}
          />
        )}
      </div>
      {
        // Communications
        isExpanded && props.peerEvaluation?.communications && props.peerEvaluation.communications.map(communicationId => (
          <CommunicationItem key={communicationId.toString()} communicationId={communicationId} />
        ))
      }
    </React.Fragment>
  );
};

const ConnectedPeerEvaluationLectureComponent = connect(
  (state: RootState, ownProps: PeerEvaluationLectureComponentProps) => ({
    peerEvaluation: getPeerEvaluation(state, ownProps.peerEvaluationId),
    hasCommunicationError: hasPeerEvaluationGotCommunicationError(state, ownProps.peerEvaluationId),
  }),
  mapDispatchToProps,
)(PeerEvaluationLectureComponent);

export default ConnectedPeerEvaluationLectureComponent;
