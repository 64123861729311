import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { AnalyticsDashBoardType } from 'redux/schemas/app/analytics';


export const getAnalyticsToken = createAsyncThunk(
  'GET_ANALYTICS_TAGS',
  getToken,
);

export type AnalyticsParams = {
  journeyCatalogId? : string,
  catalogId?: string,
  institutionId: number
  type: AnalyticsDashBoardType
  pageIdKey?: string
};

async function getToken(params: AnalyticsParams) {
  const { type, catalogId, institutionId, journeyCatalogId, pageIdKey } = params;
  let tokenUrl = '';
  if (type === AnalyticsDashBoardType.COURSE || type === AnalyticsDashBoardType.COHORTS ) {
    tokenUrl = `/analytics/${institutionId}/course_analytics_access/${catalogId}.json`;
  }
  if (type === AnalyticsDashBoardType.LEARNING_JOURNEY) {
    tokenUrl = `analytics/${institutionId}/journey_analytics_access/${journeyCatalogId}`;
  }
  if (type === AnalyticsDashBoardType.INSTITUTION) {
    tokenUrl = `analytics/${institutionId}/org_analytics_access.json`;
  }
  if (type === AnalyticsDashBoardType.LICENSE) {
    tokenUrl = pageIdKey === 'main' ? `analytics/${institutionId}/license_analytics_access.json` : `analytics/${institutionId}/merged_license_analytics_access.json`;
  }
  return axios.get(tokenUrl).then((response) => response.data.result);
}
