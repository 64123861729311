import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AngularContext } from 'react-app';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { css } from '@emotion/react';

import { useQuery } from 'shared/hooks/use-query';
import { getCurrentUser } from 'redux/selectors/users';
import { warning, white } from 'styles/global_defaults/colors';
import { PracticeRoomTab, RecordingFormat, SubmissionTab } from 'redux/schemas/models/video-practice';
import { getSubmission, getScenario } from 'redux/selectors/video-practice';
import { getComments, getPracticeSubmission, requestReviewPracticeSubmission, setPracticeRoomState } from 'redux/actions/video-practice';
import { useAppDispatch } from 'redux/store';
import { getAllFeedback } from 'redux/actions/skills-feedback';
import { SkillsFeedbackRequest } from 'redux/schemas/api/skills-feedback';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { showRequestReviewCTAConfig } from 'shared/utils';
import { config } from '../../../../../config/pendo.config.json';
import { ActionTypes, PracticeSubmissionContext } from '../utils';
import NvTooltip from 'shared/components/nv-tooltip';

export enum ReviewStatus {
  NO_APPROVAL = 'no_approval',
  REQUESTED = 'requested',
  REVIEWED = 'reviewed',
}

const styles = () => css`
  &.review-tag-container {
    position: absolute;
    z-index: 10;

    &--video {
      left: -32px;
      top: 16px;
    }

    &--audio {
      left: -36px;
      top: -8px;
    }

    button {
      opacity: 1;
      padding: 5px 14px;
    }
    .review-tag-active {
      background-color: ${warning};
      color: ${white};
      border: 0;

      &:hover {
        background-color: ${warning}e5;
      }
    }
  }
`;

const RequestReviewCTA = ({ submissionId: submissionIdProps }: { submissionId?: number }) => {
  const [{ submissionId: submissionIdContext, skillTags, scenarioId }, practiceSubmissionDispatch] = useContext(PracticeSubmissionContext);
  const submissionId = submissionIdContext || submissionIdProps;
  const { reviewStatus, reviewerUserId, user: submissionUser, isViewerMentor } = useSelector(state => getSubmission(state, submissionId));
  const [isLoadingRequestReview, setIsLoadingRequestReview] = React.useState<boolean>(false);
  const { recordingFormat, hasMentorsInCoursesUsedIn } = useSelector(state => getScenario(state, scenarioId));
  const currentInstitutionId = useSelector(
    (state) => state.app.currentInstitutionId,
  );
  const query = useQuery();
  const dispatch = useAppDispatch();
  // Request Review CTA configurations
  const queryUserId = query?.user;
  const hasFeedbackBy = !!query?.feedbackBy;
  const { injectServices } = useContext(AngularContext);
  const [$, CurrentUserManager] = injectServices(['$state', 'CurrentUserManager']);
  const hasMentors = CurrentUserManager?.currentUserCourse?.mentors?.length > 0 || hasMentorsInCoursesUsedIn;
  const user = useSelector(getCurrentUser);
  const allowCurrentUserToSeeCTA = (query.selected === PracticeRoomTab.MY_PRACTICE || Number(queryUserId) === user.id || hasFeedbackBy || isViewerMentor);

  const showRequestReviewCTA = skillTags.length > 0 && allowCurrentUserToSeeCTA && (hasMentors || isViewerMentor);

  // Control the CTA label and disabled state based on the review status
  const reviewCTAConfig = showRequestReviewCTAConfig(reviewStatus, isViewerMentor);

  const redirectToPracticeRoomTab = () => {
    dispatch(setPracticeRoomState({
      scenarioId,
      submissionId,
      selectedTab: PracticeRoomTab.GALLERY,
      userId: submissionUser.id,
      selectedSubmissionTab: SubmissionTab.ALL_FEEDBACK,
      feedbackBy: reviewerUserId,
    }));
    practiceSubmissionDispatch({ type: ActionTypes.SET_SELECTED_VIEW, payload: SubmissionTab.ALL_FEEDBACK });
  };

  const onClickSeeReview = () => {
    if (CurrentUserManager.isSupervisor()) {
      if (reviewerUserId === user.id) {
        redirectToPracticeRoomTab();
      } else {
        const params: SkillsFeedbackRequest = {
          institutionId: currentInstitutionId,
          ownerId: submissionId,
          ownerType: 'VideoPracticeSubmission',
          displayOwnFeedback: true,
          userId: reviewerUserId,
          skillsView: 'mentor_received',
        };
        practiceSubmissionDispatch({ type: ActionTypes.SET_SKILLS_VIEW, payload: 'mentor_received' });
        dispatch(getAllFeedback(params)).then(redirectToPracticeRoomTab());
      }
    } else {
      dispatch(setPracticeRoomState({
        scenarioId,
        submissionId,
        selectedTab: PracticeRoomTab.MY_PRACTICE,
        userId: submissionUser.id,
        selectedSubmissionTab: SubmissionTab.ALL_FEEDBACK,
        feedbackBy: reviewerUserId,
      }));
      practiceSubmissionDispatch({ type: ActionTypes.SET_SELECTED_VIEW, payload: SubmissionTab.ALL_FEEDBACK });
    }
  };

  const onClickRequestReview = () => {
    setIsLoadingRequestReview(true);
    dispatch(requestReviewPracticeSubmission({ submissionId, scenarioId }))
      .then(async () => {
        await dispatch(getPracticeSubmission({ scenarioId, submissionId })).then(() => {
          dispatch(
            addAlertMessage({
              type: AlertMessageType.SUCCESS,
              header: t.PRACTICE_ROOM.INSIGHTS.REQUEST_REVIEW.REQUEST_SUCCESS_TITLE(),
              message: t.PRACTICE_ROOM.INSIGHTS.REQUEST_REVIEW.REQUEST_SUCCESS_DESCRIPTION(),
            }),
          );
        });
        await dispatch(getComments({ submissionId, page: 1 }));
      })
      .finally(() => {
        setIsLoadingRequestReview(false);
      });
  };

  const onClickCTA = () => {
    if (reviewStatus === ReviewStatus.NO_APPROVAL) {
      onClickRequestReview();
    }
    if (reviewStatus === ReviewStatus.REVIEWED) {
      onClickSeeReview();
    }
  };

  const URL = useMemo(() => {
    if (reviewCTAConfig?.disabled || reviewStatus !== ReviewStatus.REVIEWED) {
      return null;
    }
    if (CurrentUserManager.isSupervisor()) {
      return `/#!/practice-room/${scenarioId}/?user=${submissionUser.id}&submission=${submissionId}&submissionView=${SubmissionTab.AUTHOR_FEEDBACK}&feedbackBy=${reviewerUserId}&selected=${PracticeRoomTab.GALLERY}&galleryMode=true`;
    }
    return `/#!/practice-room/${scenarioId}/?submission=${submissionId}&submissionView=${SubmissionTab.ALL_FEEDBACK}&feedbackBy=${reviewerUserId}&selected=${PracticeRoomTab.MY_PRACTICE}&galleryMode=true`;
  }, [CurrentUserManager, reviewerUserId, scenarioId, reviewCTAConfig?.disabled, submissionId, submissionUser.id, reviewStatus]);

  return (
    <div
      css={styles}
      className={`review-tag-container ${
        recordingFormat === RecordingFormat.VIDEO
          ? 'review-tag-container--video'
          : 'review-tag-container--audio'
      }`}
    >
      {reviewCTAConfig && showRequestReviewCTA && (
        <NvTooltip
          text={reviewCTAConfig.tooltip}
          placement='top'
        >
          <Button
            variant='primary'
            href={URL}
            size='lg'
            disabled={reviewCTAConfig.disabled || isLoadingRequestReview}
            className={`d-flex align-items-center ml-4 ${reviewCTAConfig.className}`}
            onClick={onClickCTA}
            pendo-tag-name={config.pendo.practice.requestReviewsCta}
            aria-label={reviewCTAConfig.label}
          >
            <div className='icon icon-course-mentees mr-2' />
            {reviewCTAConfig.label}
          </Button>
        </NvTooltip>
      )}
    </div>
  );
};

export default RequestReviewCTA;
