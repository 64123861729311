import { sanitize, cleanupNovoEdCode, SanitizationLevel } from 'froala/helpers/sanitizer';
import isMostlyRTLText from 'froala/helpers/isMostlyRTLText';

import { getTopElement } from 'froala/helpers/nv-froala-functions';
import { uniqueId } from 'lodash';
import { getScrollParent } from 'shared/utils';

/* @ngInject */
export default function nvFroalaEditor(

  S3UploadFactory,
  S3NameSpaces,
  Upload,
  $timeout,
  nvUtil,
  $parse,
  _,
  $uibPosition,
  $translate,
  CurrentUserManager,
  $document,
) {
  const DEFAULT_LANGUAGE = 'en';
  // https://froala.com/wysiwyg-editor/v2-0/languages
  const LANGUAGE_MAPPING = {
    en_US: 'en',
    es_MX: 'es',
    es_ES: 'es',
    fr_FR: 'fr',
    pt_PT: 'pt_pt',
    pt_BR: 'pt_br',
    zh_CN: 'zh_cn',
    ja_JP: 'ja',
    ko_KP: 'ko',
    ru_RU: 'ru',
    de_DE: 'de',
    ar_SA: 'ar',
    he_IL: 'he',
    pl_PL: 'pl',
    it_IT: 'it',
    fr_CA: 'fr',
    nl_NL: 'nl',
    ro_RO: 'ro',
    sv_SE: 'sv',
  };
  const PASTE_STRIP_ATTRS = ['nv-compile-once', 'nv-compile-always'];

  const FROALA_TEXT_SIZES = ['title', 'subtitle', 'title-small', 'big', 'medium', 'regular', 'small'];
  const FROALA_ALIGNMENT = ['left', 'center', 'right'];
  const FROALA_TEXT_CLASSES = _.flatten([_.map(FROALA_TEXT_SIZES, size => `froala-style-${size}`), _.map(FROALA_ALIGNMENT, alignment => `froala-text-align-${alignment}`)]);

  let generatedIds = 0;

  const DEFAULT_PARAGRAPH_STYLE = 'froala-style-regular';
  // expects to be used on a div
  return {
    restrict: 'A',
    require: 'ngModel',
    link(scope, element, attrs, ngModel) {
      const defaultConfig = {
        immediateAngularModelUpdate: true,
        angularIgnoreAttrs: null,
        colorPickerContainer: attrs.colorPickerContainer,
        defaultTextColor: $parse(attrs.defaultTextColor)(scope),
      };
      attrs.enforceStyle = attrs.enforceStyle || 'true';

      const internalId = uniqueId();
      const currentLang = LANGUAGE_MAPPING[CurrentUserManager.user.platformLanguage] || 'en';
      if (currentLang !== DEFAULT_LANGUAGE) {
        $.FE.LANGUAGE[currentLang].translation['Font Style'] = $translate.instant('FROALA.FONT.HEADER');
        $.FE.LANGUAGE[currentLang].translation['Text Color'] = $translate.instant('FROALA.COLOR.TEXT');
        $.FE.LANGUAGE[currentLang].translation['Highlight Color'] = $translate.instant('FROALA.COLOR.HIGHLIGHT');
        $.FE.LANGUAGE[currentLang].translation['Change Text Alignment'] = $translate.instant('FROALA.TEXT_ALIGNMENT');
        $.FE.LANGUAGE[currentLang].translation['Bulleted List'] = $translate.instant('FROALA.ICON_TOOLTIPS.BULLET');
        $.FE.LANGUAGE[currentLang].translation['Numbered List'] = $translate.instant('FROALA.ICON_TOOLTIPS.NUMBER');
      }

      const PARAGRAPH_STYLES = {
        'froala-style-title': $translate.instant('FROALA.FONT.TITLE'),
        'froala-style-subtitle': $translate.instant('FROALA.FONT.SUBTITLE'),
        'froala-style-title-small': $translate.instant('FROALA.FONT.TITLE_SMALL'),
        'froala-style-big': $translate.instant('FROALA.FONT.BIG'),
        'froala-style-medium': $translate.instant('FROALA.FONT.MEDIUM'),
        'froala-style-regular': $translate.instant('FROALA.FONT.REGULAR'),
        'froala-style-small': $translate.instant('FROALA.FONT.SMALL'),
      };

      // - purpose: keep track if the user has touched the input, if false, don't update model
      // - froala does its own manipulating of format which causes the field to become dirty
      let hasTouchedSinceInit = false;

      let lastPastedContent;

      const scrollParentClass = `froala-scroll-parent-${internalId}`;

      const scrollContainer = getScrollParent(element.get(0));
      scrollContainer?.classList.add(scrollParentClass);

      let froalaConfig = {
        heightMin: attrs.minHeight ? $parse(attrs.minHeight)(scope) : 150,
        htmlAllowedTags: ['div', 'iframe', 'p', 'br', 'hr',
          'span', 'a', 'blockquote', 'strong', 'b', 'em', 'u',
          'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'pre', 'img',
          'ul', 'ol', 'li',
          'table', 'thead', 'th', 'tbody', 'tr', 'td'],

        // copied from PRIVATE_COURSE_ADMIN_INPUT
        htmlAllowedAttrs: ['href', 'title', 'class', 'id', 'name', 'style', 'target', 'data-toggle', 'data-parent',
          'data-ga-outbound-tracking', 'ite', 'span', 'width', 'src', 'allowFullScreen', 'height', 'type', 'wmode',
          'allowfullscreen', 'allowFullScreenInteractive', 'bgcolor', 'flashvars', 'pluginspage', 'quality', 'flashVars',
          'align', 'alt', 'usemap', 'start', 'summary', 'scope', 'border', 'abbr', 'axis', 'colspan', 'rowspan', 'lass',
          'text-align', 'dir', 'runat', 'data', 'value', 'oe-mention', 'user', 'data-user-id', 'padding', 'margin',
          'background-position', 'background-repeat', 'data-id', 'oe-content-cdf', 'oe-content-math', 'uib-accordion',
          'close-others', 'uib-accordion-group', 'heading', 'is-open', 'is-disabled', 'url', 'tgb-slug', 'tgb-post-count',
          'tgb-mobile-count', 'tgb-dark-mode', 'tgb-fixed-height', 'shape', 'coords', 'scrolling', 'frameborder', 'sandbox',
          'webkitallowfullscreen', 'mozallowfullscreen', 'uib-popover', 'popover-title', 'popover-trigger', 'popover-is-open',
          'tooltip-placement', 'popover-placement', 'uib-tooltip', 'tooltip-trigger', 'tooltip-is-open'],

        pasteAllowedStyleProps: ['font-weight', 'text-decoration', 'font-style'],
        pasteDeniedAttrs: [],

        toolbarButtons: [],

        // Keep format of the selected text when it is deleted (only needed for rich text lec)
        keepFormatOnDelete: attrs.keepFormatOnDelete || false,

        paragraphStyles: PARAGRAPH_STYLES,
        paragraphMultipleStyles: false,

        linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
        linkInsertButtons: ['linkBack'],
        linkConvertEmailAddress: true,
        linkAlwaysBlank: true,

        wordPasteModal: false,
        imageEditButtons: [],

        scrollableContainer: `.${scrollParentClass}`, // this has to be text because froala serializes the object
        // autofocus: $parse(attrs.autofocus)(scope), //don't use built in autofocus, it goes to front of text - currently we use our own function

        language: currentLang,
        $translate,
        direction: $document[0].dir,

        // this works except when froala skips sanitization when the text was copied from another froala editor in this browser
        // turning it off it easier and to avoid unnecessary processing since we do it manually in an 'paste.afterCleanup'
        // pastePlain: true
      };

      if (attrs.maxLength) {
        froalaConfig.charCounterMax = attrs.maxLength;
      }

      const ctrl = {
        editorInitialized: false,
      };

      const toolbarButtons = [
        'color',
        'bold',
        'italic',
        'underline',
        'bulletList',
        'numberList',
        'paragraphStyle',
        'align',
        'insertLink',
      ];

      if (attrs.preset === 'Air') {
        froalaConfig = _.extend(froalaConfig, {
          placeholderText: attrs.placeholder || $translate.instant('FROALA.TEXT_PLACEHOLDER'),
          editorClass: 'air',
          toolbarSticky: false,
          imagePaste: false,
        });
      } else {
        froalaConfig = _.extend(froalaConfig, {
          placeholderText: attrs.placeholder || $translate.instant('FROALA.TEXT_AND_FILE_PLACEHOLDER'),

          toolbarInline: true,
          toolbarButtons,
          toolbarButtonsSM: toolbarButtons,
          toolbarButtonsXS: toolbarButtons,
          imageUploadURL: 'https://novoed.com/upload', // filler to bypass image upload checks
          customUpload: (imageBlob, successCallback) => {
            imageBlob.name = `${(new Date()).getTime()}.jpg`;
            S3UploadFactory.uploadToS3(imageBlob, S3NameSpaces.ATTACHMENTS)
              .then((response) => {
                const { file } = response.config.data;

                Upload.upload({
                  url: '/embeddings/create',
                  fields: {
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    unique_id: file.uniqueId,
                  },
                }).then((uploadResponse) => {
                  successCallback({ link: uploadResponse.data.src });
                });
              });
          },
        });
      }

      if (attrs.validateEmpty) {
        ngModel.$validators.empty = function (modelValue, viewValue) {
          const value = modelValue || viewValue;
          const parser = new DOMParser();
          const htmlDoc = parser.parseFromString(value, 'text/html');
          /** when user leaves a empty line without any text, the text content's
           *  length remains zero and it will not show required popover
           *  So to enable required popover for this condition we are tracking
           *  the empty line through childElementCount
           */
          return !((htmlDoc.childNodes[0].textContent.length || htmlDoc.childNodes[0].children[1]?.childElementCount > 1) && !htmlDoc.childNodes[0].textContent.trim().length);
        };
      }

      ctrl.init = function () {
        if (!attrs.id) {
          // generate an ID if not present
          attrs.$set('id', `froala-${generatedIds += 1}`);
        }

        // init the editor
        ctrl.createEditor();

        // disabled
        if (attrs.isDisabled === 'true') {
          element.froalaEditor('edit.off');
        }

        scope.$watch(() => attrs.isDisabled === 'true', (newValue, oldValue) => {
          if (newValue !== oldValue) {
            if (newValue) {
              element.froalaEditor('edit.off');
            } else {
              element.froalaEditor('edit.on');
            }
          }
        });

        // immediately hide toolbar if in Air mode
        if (attrs.preset === 'Air') {
          element.froalaEditor('toolbar.hide');
        }

        // Instruct ngModel how to update the froala editor
        ngModel.$render = function () {
          if (ctrl.editorInitialized) {
            // - $render triggers froala to massage the html which triggers a model update, so we'll turn it off for now
            const previousHasTouchedSinceInit = hasTouchedSinceInit;
            hasTouchedSinceInit = false;
            $timeout(() => {
              hasTouchedSinceInit = previousHasTouchedSinceInit || hasTouchedSinceInit;
            });

            element.froalaEditor('html.set', ngModel.$viewValue || '', true);

            updateParagraphStyle();

            // This will reset the undo stack everytime the model changes externally. Can we fix this?
            element.froalaEditor('undo.reset');
            element.froalaEditor('undo.saveStep');
          }
        };

        ngModel.$isEmpty = function (value) {
          if (!value) {
            return true;
          }

          const isEmpty = element.froalaEditor('node.isEmpty', $(`<div>${value}</div>`).get(0));
          return isEmpty;
        };
      };

      ctrl.createEditor = function (froalaInitOptions) {
        ctrl.listeningEvents = ['froalaEditor'];
        if (!ctrl.editorInitialized) {
          froalaInitOptions = (froalaInitOptions || {});
          ctrl.options = angular.extend({}, defaultConfig, froalaConfig, froalaInitOptions);

          if (ctrl.options.immediateAngularModelUpdate) {
            ctrl.listeningEvents.push('keyup');
          }

          ctrl.registerEventsWithCallbacks('froalaEditor.initialized', (e, editor) => {
            ctrl.editorInitialized = true;

            ngModel.$render();

            if ($parse(attrs.autofocus)(scope)) {
              $timeout(() => {
                customFocus(true);
              });
            }
          });

          // Register events provided in the options
          // Registering events before initializing the editor will bind the initialized event correctly.

          if (ctrl.options.events) {
            Object.keys(ctrl.options.events).forEach((eventName) => {
              ctrl.registerEventsWithCallbacks(eventName, ctrl.options.events[eventName]);
            });
          }

          ctrl.froalaInstance = element.froalaEditor(ctrl.options).data('froala.editor');
          ctrl.froalaElement = ctrl.froalaInstance.$el;
          ctrl.froalaEditor = angular.bind(element, element.froalaEditor);
          ctrl.initListeners();

          element.data({
            focus: customFocus,
            markTouched() {
              hasTouchedSinceInit = true;
            },
          });
        }
      };

      ctrl.initListeners = function () {
        if (ctrl.options.immediateAngularModelUpdate) {
          ctrl.froalaElement.on('keyup', () => {
            scope.$evalAsync(ctrl.updateModelView);
          });
        }

        ctrl.froalaInstance.events.on('paste.after', () => {
          // this block of code used to set the direction of the text after it is pasted into Froala
          // it is only triggered if the pasted text does not contain any block level elements
          // there is a corresponding call for block level elements in sanitizer.ts
          const topElement = getTopElement(ctrl.froalaInstance);

          if (topElement?.parentElement // make sure we didn't hit the top html element
            && topElement.innerText.replace(/\u200B/g, '').trim() === $(`<div>${lastPastedContent}</div>`).text().replace(/\u200B/g, '').trim()
          ) {
            topElement.setAttribute('dir', isMostlyRTLText(topElement.innerText) ? 'rtl' : 'ltr');
          }
        });

        element.on('froalaEditor.contentChanged', () => {
          scope.$evalAsync(ctrl.updateModelView);
        });

        element.on('froalaEditor.focus', () => {
          hasTouchedSinceInit = true;

          $timeout(() => {
            element.addClass('in-focus');
            if (attrs.onFocus) {
              $parse(attrs.onFocus)(scope);
            }
          });
        });

        element.on('froalaEditor.blur', () => {
          $timeout(() => {
            if (element) {
              element.removeClass('in-focus');
            }
          });
        });

        // callbacks
        if (attrs.initCallback) {
          element.on('froalaEditor.initialized', $parse(attrs.initCallback)(scope));
        }

        if (attrs.focusCallback) {
          element.on('froalaEditor.focus', $parse(attrs.focusCallback)(scope));
        }

        if (attrs.pasteBeforeCallback) {
          element.on('froalaEditor.paste.before', $parse(attrs.pasteBeforeCallback)(scope));
        }

        if (attrs.pasteAfterCallback) {
          element.on('froalaEditor.paste.after', $parse(attrs.pasteAfterCallback)(scope));
        }

        if (attrs.blurCallback) {
          element.on('froalaEditor.blur', () => {
            $timeout(() => {
              $parse(attrs.blurCallback)(scope)(!!element.data('uploadModalOpen'));
            });
          });
        }

        if (attrs.onBlur) {
          element.on('froalaEditor.blur', () => {
            $timeout(() => {
              $parse(attrs.onBlur)(scope);
            });
          });
        }

        ctrl.froalaInstance.events.on('paste.afterCleanup', (clipboardHTML) => {
          const cleanHtml = cleanupNovoEdCode(clipboardHTML);
          lastPastedContent = sanitize(cleanHtml, $parse(attrs.plainTextOnly)(scope) ? SanitizationLevel.PLAIN : SanitizationLevel.NORMAL);

          return lastPastedContent;
        }, true);

        // gets the html even in code view
        element.on('froalaEditor.html.get', (e, editor) => {
          if (ctrl.froalaInstance.codeView?.isActive()) {
            return ctrl.froalaInstance.codeView.get();
          }

          return null;
        });

        // mentio
        element.on('froalaEditor.focus', ctrl.interactWithOeMentionSpanAfter);
        element.on('froalaEditor.click', ctrl.interactWithOeMentionSpanAfter);
        element.on('froalaEditor.keydown', ctrl.interactWithOeMentionSpanBefore);
        element.on('froalaEditor.keyup', ctrl.interactWithOeMentionSpanAfter);
        ctrl.froalaInstance.events.on('keydown', ctrl.keydownCallback, true);

        // file drop - for air version don't do anything, full version will override the behaviour
        ctrl.froalaInstance.events.on('drop', ctrl.dropCallback, true);

        element.bind('$destroy', () => {
          element.off(ctrl.listeningEvents.join(' '));
          element.froalaEditor('destroy');
          element = null;
        });
        element.on('froalaEditor.input', ((_e, editor, keyupEvent) => {
          // 'keyup` doesn't trigger for froala for all keys such as typing Chinese
          // 'keydown' modifies the content so we can't tell what the input is if it's not English
          const topElement = getTopElement(ctrl.froalaInstance);

          if (topElement?.innerText) {
            const content = topElement.innerText.replace(/\u200B/g, '').trim();
            if (content.length === 1) {
              topElement.setAttribute('dir', isMostlyRTLText(topElement.innerText) ? 'rtl' : 'ltr');
            }
          }
        }));
      };

      function customFocus(focusAtEnd) {
        // ctrl.froalaElement.focus();
        ctrl.froalaInstance.events.focus();

        if (focusAtEnd) {
          // focus at the end generally doesn't work well because the definition of the end is very tricky
          // need try catch block because sometimes there are empty blocks so in that case just fallback to focusing at the beginning
          try {
            let node = ctrl.froalaInstance.$el.get(0);

            if (node.innerHTML === '<p><br></p>') {
              ctrl.froalaInstance.events.focus();
            } else {
              if (node.childNodes.length) {
                node = node.childNodes[node.childNodes.length - 1];
              }

              // nvUtil.setCursor(node, node.length);

              // in the same cycle, it ignores this selection
              $timeout(() => {
                nvUtil.placeCaretAtEnd(node);
              });
            }

            // ctrl.froalaElement.focus();
          } catch (e) {
            ctrl.froalaInstance.events.focus();
          }
        } else {
          ctrl.froalaInstance.events.focus(); // focus at the beginning because it just generally a better idea
        }

        $timeout(() => {
          element.addClass('in-focus');
        });

        if (attrs.focusCallback) {
          $parse(attrs.focusCallback)(scope)();
        }

        hasTouchedSinceInit = true;
      }

      function updateParagraphStyle() {
        if (attrs.preset !== 'Air') {
          const topLevelParagraphs = element.find('.fr-element>p');
          _.each(topLevelParagraphs, (p) => {
            const $p = $(p);
            // in plainTextOnly mode - we actually don't currently enforce plain text only, but we don't want to modify user input
            if ($parse(attrs.enforceStyle)(scope)) {
              const hasParagraphStyle = _.any(_.keys(PARAGRAPH_STYLES), (pClass) => $p.hasClass(pClass));

              if (!hasParagraphStyle) {
                $p.addClass(DEFAULT_PARAGRAPH_STYLE);
              }
            }
          });
        }
      }

      ctrl.updateModelView = function () {
        updateParagraphStyle();

        if (hasTouchedSinceInit) {
          let modelContent = null;

          const returnedHtml = element.froalaEditor('html.get');
          if (angular.isString(returnedHtml)) {
            modelContent = returnedHtml;
          }

          ngModel.$setViewValue(modelContent);
        }
      };

      ctrl.registerEventsWithCallbacks = function (eventName, callback) {
        if (eventName && callback) {
          ctrl.listeningEvents.push(eventName);
          element.on(eventName, callback);
        }
      };


      /** Mentions Functions * */
      ctrl.keydownCallback = (e) => {
        const $mentioList = $('.mentio-search:visible');

        if ($mentioList.length && (e.which === 9 || e.which === 13)) {
          return false;
        }

        return null;
      };
      // 1) cursor leans left
      // 2) selection.element() leans right
      // 3) anchorNode.parentElement leans left
      ctrl.interactWithOeMentionSpanBefore = (e, editor, keyupEvent) => {
        if (!keyupEvent) {
          return null;
        }

        const keyCode = keyupEvent.which;

        const isDeleteKey = keyCode === 8 || keyCode === 46;
        const isPrintableKey = (function (keycode) {
          if (!keycode) {
            return false;
          }
          const isPrintable = (keycode > 47 && keycode < 58) // number keys
            || keycode === 32 || keycode === 13 // spacebar & return key(s) (if you want to allow carriage returns)
            || (keycode > 64 && keycode < 91) // letter keys
            || (keycode > 95 && keycode < 112) // numpad keys
            || (keycode > 185 && keycode < 193) // ;=,-./` (in order)
            || (keycode > 218 && keycode < 223); // [\]' (in order)
          return isPrintable;
        }(keyCode));

        if (editor.selection.get().anchorNode) {
          // var currentNode = editor.selection.element();
          const currentNode = editor.selection.get().anchorNode.parentNode;
          // window.console.log(currentNode);

          // see if cursor inside mention span
          if (currentNode.hasAttribute('oe-mention')) {
            if (isPrintableKey || isDeleteKey) {
              currentNode.remove();

              return false;
            }
          }
        }

        return null;
      };

      ctrl.interactWithOeMentionSpanAfter = (e, editor, keyupEvent) => {
        if (!keyupEvent) {
          return;
        }

        const keyCode = keyupEvent.which;

        const moveLeftKey = nvUtil.isRtl() ? keyCode === 39 : keyCode === 37;
        const moveRightKey = nvUtil.isRtl() ? keyCode === 37 : keyCode === 39;

        if (editor.selection.get().anchorNode) {
          // var currentNode = editor.selection.element();
          const currentNode = editor.selection.get().anchorNode.parentNode;
          // window.console.log(currentNode);

          // see if cursor inside mention span
          if (currentNode.hasAttribute('oe-mention')) {
            if (moveLeftKey) {
              if (!editor.selection.get().anchorNode.parentNode.previousSibling) {
                const $superParent = $(editor.selection.get().anchorNode.parentNode.parentNode);
                $superParent.prepend('&#8203;');
              }
              editor.selection.setBefore(currentNode);
              editor.selection.restore();
            } else if (moveRightKey) {
              const mentionParent = currentNode.parentNode;
              if (currentNode.nextSibling) {
                let characterIndex = 0;
                const firstCharacterCode = currentNode.nextSibling.wholeText?.charCodeAt(0);

                if (firstCharacterCode === 32 || firstCharacterCode === 160) {
                  characterIndex = 1;
                }

                nvUtil.setCursor(currentNode.nextSibling, characterIndex);
              } else {
                $(mentionParent).append('\xA0');
                nvUtil.setCursor(currentNode.nextSibling, 1);
              }
            } else {
              nvUtil.selectElementContents(currentNode);
            }
          } // else if about to go into a selection
        }
      };

      ctrl.dropCallback = (dropE) => {
        dropE.preventDefault();
        dropE.stopPropagation();

        const dt = dropE.originalEvent.dataTransfer;

        if (dt?.files?.length) {
          return false;
        }

        return null;
      };

      scope.$on('$destroy', () => {
        ctrl.froalaInstance?.destroy();
      });

      ctrl.init();
    },
  };
}
