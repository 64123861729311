/* @ngInject */
export default function NvLectureComponentDeadline(
  moment,
  CurrentCourseManager,
  ConfirmationOverlays,
) {
  return {
    scope: {
      model: '=',
      customPopover: '@?',
      deadlineAttribute: '@',
      hardDeadlineAttribute: '@?',
      course: '=',
      editMode: '=',
      onClose: '&',
      displayClass: '=?',
      hardDeadlineKey: '@?',
      hardDeadlineValues: '=?',
      lectureReleaseDate: '=',
      hideDueText: '=?',
      hidePopover: '=?',
      showHardDeadlineCheckboxOnDisplay: '=?',
      hideHardDeadline: '=?',
      selectHardCallback: '&?',
      showHardDeadlineIcon: '=?',
      hideWarning: '=?',
    },
    controller: function ctrl(
      _,
      nvUtil,
    ) {
'ngInject';
      const vm = this;
      vm.CurrentCourseManager = CurrentCourseManager;
      vm.course = vm.CurrentCourseManager.course;

      vm.formId = nvUtil.randomPositiveInteger();

      vm.isDeadlineBeforeRelease = () => moment(vm.model[vm.deadlineAttribute]) < moment(vm.lectureReleaseDate);

      vm.editDeadline = () => {
        vm.deadlineDraft = vm.model[vm.deadlineAttribute];
        if (vm.hardDeadlineAttribute) {
          vm.hardDeadlineDraft = vm.model[vm.hardDeadlineAttribute];
        }
        vm.editingDeadline = true;
      };

      vm.onChange = () => {
        vm.hasChanged = true;
      };

      vm.onDatePickerHide = () => {
        let changed = vm.hasChanged;

        if (vm[`deadlineForm_${vm.formId}`].$valid) {
          if (vm.model[vm.deadlineAttribute] !== vm.deadlineDraft) {
            if (!vm.hideWarning && vm.model.scheduledCommunicationsCount > 0 && moment().isBefore(moment(vm.model[vm.deadlineAttribute]))) {
              ConfirmationOverlays.openConfirmationModal('lecture_pages/templates/due-date-change-warning-overlay.html')
                .result.then(() => {
                  vm.model[vm.deadlineAttribute] = vm.deadlineDraft;
                  vm.onClose();
                });
            } else {
              changed = true;
              vm.model[vm.deadlineAttribute] = vm.deadlineDraft;
            }
          }

          if (vm.hardDeadlineAttribute && vm.model[vm.hardDeadlineAttribute] !== vm.hardDeadlineDraft) {
            changed = true;
            vm.model[vm.hardDeadlineAttribute] = vm.hardDeadlineDraft;
          }
          vm.editingDeadline = false;
        }

        if (changed) {
          vm.onClose();
        }
      };

      vm.clickHardDeadline = () => {
        if (vm.selectHardCallback && vm.hardDeadlineAttribute && vm.model[vm.hardDeadlineAttribute] !== vm.hardDeadlineDraft) {
          vm.model[vm.hardDeadlineAttribute] = vm.hardDeadlineDraft;

          vm.selectHardCallback();
        }
      };

      vm.hardDeadlinePopoverValues = () => {
        if (vm.hardDeadlineValues) {
          return _.extend({}, vm.hardDeadlineValues, { learnerAlias: vm.course.learnersName.downcasedPluralized });
        }
        return { learnerAlias: vm.course.learnersName.downcasedPluralized };
      };
    },
    link: (scope, element, attrs, ctrl, transclude) => {
      ctrl.deadlineDraft = ctrl.model[ctrl.deadlineAttribute];
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'shared/templates/nv-lecture-component-deadline.html',
  };
}
