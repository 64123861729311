/* @ngInject */
export default function CourseHomeManager(

  CourseHomeModel,
  CourseHomeRhsManager,
  _,
) {
  const manager = {
    // functions
    getCourseHome,

    // data:
    courseHome: null,
  };

  function getCourseHome(catalogId) {
    return CourseHomeModel.get(catalogId).then((courseHome) => {
      manager.courseHome = courseHome;
      CourseHomeRhsManager.resetCourseHomeRhs(catalogId);
      CourseHomeRhsManager.getCourseHomeRhs(catalogId);
    });
  }

  return manager;
}
