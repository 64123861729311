import React from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { AutoGenerated } from 'redux/schemas/models/video';
import { getAutoGeneratedInfo } from 'redux/selectors/lecture-components';
import Badge, { BadgeProps } from 'shared/components/nv-badge';
import { gray2, gray4, white } from 'styles/global_defaults/colors';

const StatusBadge = React.memo(({ type }: { type: 'transcript' | 'caption' }) => {
  const autoGeneratedInfo: AutoGenerated = useSelector(getAutoGeneratedInfo);
  const status = autoGeneratedInfo[type]?.status;

  const getProperties = React.useMemo((): BadgeProps => {
    switch (status) {
      case 'in_progress':
        return {
          variant: 'sm',
          color: white,
          backgroundColor: gray4,
          iconColor: white,
          children: t.LECTURE_VIDEO.AUTO_GENERATED_TRANSCRIPT_AND_CAPTIONS.GENERATING(),
        };
      case 'completed':
        return {
          variant: 'sm',
          color: gray2,
          backgroundColor: white,
          genAI: true,
          iconAI: true,
          children: t.LECTURE_VIDEO.AUTO_GENERATED_TRANSCRIPT_AND_CAPTIONS.AI_GENERATED(),
        };
      case 'regenerating':
        return {
          variant: 'sm',
          color: white,
          backgroundColor: gray4,
          iconColor: white,
          children: t.LECTURE_VIDEO.AUTO_GENERATED_TRANSCRIPT_AND_CAPTIONS.WILL_REGENERATE.LABEL(),
          tooltipText: t.LECTURE_VIDEO.AUTO_GENERATED_TRANSCRIPT_AND_CAPTIONS.WILL_REGENERATE.TOOLTIP(),
        };
      default:
        return {
          variant: 'sm',
          color: white,
          backgroundColor: gray4,
          iconColor: white,
          children: t.LECTURE_VIDEO.AUTO_GENERATED_TRANSCRIPT_AND_CAPTIONS.GENERATING(),
        };
    }
  }, [status]);

  if (!autoGeneratedInfo[type]) {
    return null;
  }

  return (
    <Badge iconName='gen-ai' {...getProperties} />
  );
});

export const TranscriptStatusBadge = () => <StatusBadge type='transcript' />;
export const CaptionStatusBadge = () => <StatusBadge type='caption' />;
export default TranscriptStatusBadge;
