import React, { useState } from 'react';
import t from 'react-translate';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  LiveEventSession as LiveEventSessionType,
  SessionTypes,
  ZoomSession,
  getSession,
} from 'redux/schemas/models/live-event';
import NvIcon from 'shared/components/nv-icon';
import LiveEventSession from './live-event-session';

type LiveEventSessionsProps = {
  sessionType: SessionTypes
  durations: number[]
  isContentManagementCollection: boolean
  isLinked: boolean
  getDurationText: (duration: number) => string
  validateHost?: (email: string) => void
  showZoomHostValidator: () => boolean
  onHostValidation?: (isValid: boolean) => void
  onSessionDeleted: (id: number) => void
};

const LiveEventSessions = (props: LiveEventSessionsProps) => {
  const {
    sessionType,
    durations,
    isContentManagementCollection,
    isLinked,
    getDurationText,
    validateHost,
    showZoomHostValidator,
    onHostValidation,
    onSessionDeleted,
  } = props;
  const { control, watch } = useFormContext();
  const [validHost, setValidHost] = useState('');

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sessions',
    keyName: 'key',
  });

  const alternativeHosts = watch(`sessions[${fields.length - 1}].alternativeHosts`);

  const handleAddSession = () => {
    const newSession: LiveEventSessionType = getSession({
      sessionType,
      index: fields.length,
    });
    if (fields.length >= 0) {
      const lastSession = fields[fields.length - 1] as any;
      newSession.duration = lastSession.duration;
      newSession.timeZone = lastSession.timeZone;
      if (sessionType === SessionTypes.ZOOM) {
        (newSession as ZoomSession).authenticationEmail = validHost;
        (newSession as ZoomSession).alternativeHosts = alternativeHosts;
      }
    }
    append(newSession);
  };

  const handleRemoveSession = (index: number, id: number = null) => {
    remove(index);
    if (id) onSessionDeleted(id);
  };

  return (
    <React.Fragment>
      {fields.map((session: any, index) => (
        <div key={session.id}>
          <LiveEventSession
            session={{ ...session, index }}
            sessionType={sessionType}
            delete={() => handleRemoveSession(index, session.id)}
            canDelete={fields.length > 1}
            isContentManagementCollection={isContentManagementCollection}
            isLinked={isLinked}
            getDurationText={getDurationText}
            durations={durations}
            showIndex={fields.length > 1}
            validateHost={validateHost}
            showZoomHostValidator={showZoomHostValidator}
            onHostValidation={(isValid, host) => {
              setValidHost(isValid ? host : '');
              onHostValidation?.(isValid);
            }}
          />
        </div>
      ))}
      {!isContentManagementCollection && (
        <div className='d-flex mt-4 mb-5'>
          <span className='d-flex text-primary text-regular cursor-pointer' onClick={handleAddSession}>
            <NvIcon icon='add' size='small' className='text-primary mr-2 cursor-pointer gray-3' />
            {t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.ADD_SESSION()}
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

export default LiveEventSessions;
