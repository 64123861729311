/* @ngInject */
export default function AggregatePeerReviewModelService(

  _,
  EvaluationsTaskResources,
  PeerReviewModel,
) {
  function AggregatePeerReviewModel(attributes) {
    const _this = this;

    _.extend(_this, attributes);
    preprocess();

    /* Public Functions */

    /* Public Data */
    _this.answeredGradedQuestionsTemplates = getAnsweredGradedQuestionsTemplates();

    /* Private Functions */
    function preprocess() {
      _this.peerReviews = _.compact(_.map(_this.peerReviews, (peerReview) => {
        if (peerReview.submitted) {
          return new PeerReviewModel(_.extend(peerReview, { catalogId: _this.catalogId, customQuestions: angular.copy(_this.customQuestions) }));
        }

        return null;
      }));

      _.each(_this.customQuestions.questionTemplates, (questionTemplate) => {
        // averageScore
        const anonymizedScore = _.findWhere(_this.anonymizedScores, { questionTemplateId: questionTemplate.id });

        if (anonymizedScore) {
          questionTemplate.averageScore = anonymizedScore.averageScore;
        }


        // individual scores for admins
        questionTemplate.individualAnsweredQuestionAnswers = [];
        _.each(_this.peerReviews, (peerReview) => {
          let questionAnswer;

          if (peerReview.currentRevision?.questionAnswers) {
            questionAnswer = _.findWhere(peerReview.currentRevision.questionAnswers, { questionTemplateId: questionTemplate.id });

            if (questionAnswer?.answer && questionAnswer.answer !== '') {
              questionTemplate.individualAnsweredQuestionAnswers.push(questionAnswer);
            }
          }
        });
      });

      _this.hasQualitativeQuestions = hasQualitativeQuestions();
      _this.displayablePeerReviews = getDisplayablePeerReviews();
      _.each(_this.displayablePeerReviews, (peerReview, index) => {
        peerReview.index = index;
      });
    }

    function getAnsweredGradedQuestionsTemplates() {
      return _.filter(_this.customQuestions.questionTemplates, (questionTemplate) => questionTemplate.averageScore != null
            && (questionTemplate.questionType === PeerReviewModel.QUESTION_TYPES.NUMERIC_SLIDER
              || questionTemplate.questionType === PeerReviewModel.QUESTION_TYPES.NUMERIC_GRADE));
    }

    // includes feedback grid
    function hasQualitativeQuestions() {
      let flag = _.some(_this.customQuestions.questionTemplates, (questionTemplate) => PeerReviewModel.isQualitative(questionTemplate));

      if (!flag) {
        flag = _this.customQuestions.hasFeedbackGrid;
      }

      return flag;
    }

    function getDisplayablePeerReviews() {
      return _.filter(_this.peerReviews, (peerReview) => peerReview.hasQualitativeAnswers);
    }
  }

  AggregatePeerReviewModel.get = function (catalogId, reportId) {
    return EvaluationsTaskResources.getPeerReviews({ catalogId, reportId }).$promise
      .then((response) => new AggregatePeerReviewModel(_.extend(response.result, { catalogId, reportId })));
  };

  return AggregatePeerReviewModel;
}
