import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import t from 'react-translate';

export enum CardStatus {
  REGISTER = 'register',
  UNREGISTER = 'unregister',
  STARTS_SOON = 'starts_soon',
  IN_PROGRESS = 'in_progress',
}

const statusTranslationKeys = {
  [CardStatus.REGISTER]: 'REGISTER',
  [CardStatus.UNREGISTER]: 'UNREGISTER',
  [CardStatus.STARTS_SOON]: 'STARTS_SOON',
  [CardStatus.IN_PROGRESS]: 'IN_PROGRESS',
};

type LiveSessionCardStateProps = {
  status?: CardStatus
  allowUserRegistration: boolean
  onClick: (state: CardStatus) => void
};

const LiveSessionCardState = (props: LiveSessionCardStateProps) => {
  const {
    status = CardStatus.UNREGISTER,
    allowUserRegistration = true,
    onClick,
  } = props;
  const [text, setText] = useState('');
  const translationPrefix = t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.SESSION_CARD.STATE;

  useEffect(() => {
    const translationKey = statusTranslationKeys[status];
    let translation = translationPrefix[translationKey]();
    if (!allowUserRegistration && (status === CardStatus.REGISTER || status === CardStatus.UNREGISTER)) {
      translation = '';
    }
    setText(translation);
  }, [status]);

  return (
    <div className='session-state text-small bold text-align-center mt-2'>
      {status === CardStatus.UNREGISTER && allowUserRegistration ? (
        <Button variant='link' onClick={() => onClick(status)}>
          {text}
        </Button>
      ) : (text)}
    </div>
  );
};

export default LiveSessionCardState;
