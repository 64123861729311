
import { AngularServicesContext } from 'react-app';
import { NLectureComponent, RichTextType } from 'redux/schemas/models/lecture-component';
import { useContext, useEffect, useRef, useState } from 'react';
import { NvFroala } from 'froala/components/nv-froala';
import { SanitizationLevel } from 'froala/helpers/sanitizer';
import { FroalaViewMode, UploadType } from 'froala/helpers/nv-froala-constants';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'redux/schemas';
import { updateLectureComponent } from 'redux/actions/lecture-pages';
import { DeepPartial } from 'utility-types';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import { LectureComponentProps, LecturePageMode } from '..';


/** This lecture component provides a rich text editing experience in the lecture page. This is the react version of the
 * angular component nv-rich-text-lecture-component and also the new text lecture component using this component in the lecture page.
 * This compoment listed as the names HTML and TEXT in lecture page left side panel. This using the component type RichTextLectureComponent
 * with separate viewOption, So now we are keeping this component class name same as type RichTextLectureComponent.
 * See the RichTextType enum in models/lecture-components.ts for the relevant true_type strings
 * */
const RichTextLectureComponent = (props: LectureComponentProps<RichTextType>) => {
  const catalogId = useSelector(state => state.app.currentCatalogId);
  const lectureComponent = useSelector((state) => (
    state.models.lectureComponents[props.lectureComponent.id.toString()]
  )) as NLectureComponent<RichTextType>;

  /** Whether or not this component was just now added to the page */
  const isNewComponent = useSelector<RootState, boolean>((state) => state.app.newComponent === lectureComponent?.id) ?? false;

  // angular savingRichTextContent is used for the confirmation modal that appears when the
  // user exits without saving the updated content.
  const angularServices = useContext(AngularServicesContext);

  const dispatch = useDispatch();

  /** initialContent keeps the saved content and newContent keeps the latest entered content.
   * These two are used for the content dirty check.
   * */
  const [initialContent, setInitialContent] = useState(lectureComponent?.content ?? '');
  const [newContent, setNewContent] = useState(initialContent);

  const styleOptions = lectureComponent?.viewOptions?.styleOptions ?? 'full';

  const onContentChange = (updatedContent: string) => {
    if (newContent !== updatedContent) {
      angularServices.$scope.vm.savingRichTextContent = true;
      setNewContent(updatedContent);
    }
  };

  const contentBlurCallback = (e, editorContent) => {
    if (initialContent !== editorContent) {
      angularServices.$scope.vm.savingRichTextContent = true;

      setInitialContent(editorContent);
      saveComponent(editorContent);
    }
  };

  async function saveComponent(content) {
    const componentData = {
      catalogId,
      lecturePageId: props.currentLecture.id,
      componentData: {
        id: lectureComponent.id,
        type: lectureComponent.type,
        index: lectureComponent.index,
        content,
        viewOptions: { styleOptions },
      } as DeepPartial<NLectureComponent<RichTextType>>,
    };

    // TODO: For some reason this componentData is causing a type check error
    await dispatch(updateLectureComponent(componentData as any));
    angularServices.$scope.vm.savingRichTextContent = false;
  }

  const froalaRef = useRef(null);
  useEffect(() => {
    if (isNewComponent && props.mode === LecturePageMode.EDIT) {
      froalaRef.current.focus();
    }
  }, [isNewComponent, props.mode]);

  const froalaElement: JSX.Element = styleOptions === 'full'
    ? (
      <NvFroala
        editorClass='rich-text'
        ref={froalaRef}
        preset={FroalaViewMode.NORMAL}
        withCodeView
        value={newContent}
        uploadType={UploadType.ALL}
        onChange={onContentChange}
        onBlur={contentBlurCallback}
      />
    )
    : (
      <NvFroala
        editorClass='rich-text'
        ref={froalaRef}
        sanitizationLevel={SanitizationLevel.BASIC}
        preset={FroalaViewMode.NORMAL}
        value={newContent}
        uploadType={UploadType.NONE}
        onChange={onContentChange}
        onBlur={contentBlurCallback}
      />
    );

  return (
    <div className='component-body rich-text-lecture-component'>
      {props.mode !== LecturePageMode.EDIT ? (
        // for showing this lecture component content in the edit mode, the already saved content is in html format.
        <div className='rich-text'>
          <ResponsivelyEmbeddedAngularHTML template={initialContent} angularServices={angularServices} />
        </div>
      ) : (
        froalaElement
      )}
    </div>
  );
};

export default RichTextLectureComponent;
