/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { FormOrgLevelCredlySettings } from 'redux/schemas/models/credly';

export const saveOrgLevelCredlySettings = createAsyncThunk(
  'SAVE_ORG_LEVEL_CREDLY_SETTINGS',
  (payload: { institutionId: number; form: FormOrgLevelCredlySettings }) => axios
    .post(
      `institutions/${payload.institutionId}/credly/config_create.json`,
      {
        organization_id: payload.form.organizationId,
        organization_token: payload.form.organizationToken,
      },
    )
    .then(response => response.data.result),
);

export const disableOrgLevelCredlySettings = createAsyncThunk(
  'DISABLE_ORG_LEVEL_CREDLY_SETTINGS',
  (payload: { institutionId: number; }) => axios
    .put(
      `institutions/${payload.institutionId}/credly/config_update.json`,
      {
        organization_id: '',
        organization_token: '',
      },
    )
    .then(response => response.data.result),
);

export const validateCredlyBadgeTemplateId = createAsyncThunk(
  'VALIDATE_CREDLY_BADGE_TEMPLATE_ID',
  (payload: { institutionId: number; badgeTemplateId: string; }) => axios
    .get(
      `institutions/${payload.institutionId}/credly/get_badges_template/${payload.badgeTemplateId}`,
    )
    .then(response => response.data),
);

export const enableCredlyBadgeTemplateId = createAsyncThunk(
  'ENABLE_CREDLY_BADGE_TEMPLATE_ID',
  (payload: { institutionId: number; catalogId: string; userEmail: string; badgeTemplateId: string; }) => axios
    .post(
      `institutions/${payload.institutionId}/credly/course/${payload.catalogId}/config_badges_template.json`,
      {
        badge_template_id: payload.badgeTemplateId,
      },
    )
    .then(response => response.data.result),
);

export const removeCredlyBadgeTemplateId = createAsyncThunk(
  'REMOVE_CREDLY_BADGE_TEMPLATE_ID',
  (payload: { institutionId: number; catalogId: string; }) => axios
    .get(
      `institutions/${payload.institutionId}/credly/course/${payload.catalogId}/remove_badges_template.json`,
    )
    .then(response => response.data.result),
);
