import { useAngularPreventLecturePageNavigation } from '../hooks/use-prevent-lecture-page-navigation';

/* @ngInject */
export default function ContentTemplateEditFormModalCtrl(
  StateManager,
  ConfirmationOverlays,
  $uibModalInstance,
  $window,
  $scope,
  lectureComponent,
) {
  const vm = this;

  vm.lectureComponent = lectureComponent;

  vm.getSelectionClasses = (option, currentOption) => {
    let classes;

    classes = option.css;

    if (option.value === currentOption) {
      classes += ' selected';
    }

    return classes;
  };

  vm.modify = (attribute, value) => {
    if (vm.lectureComponent.viewOptionsCopy !== value) {
      vm.lectureComponent.viewOptionsCopy[attribute] = value;
      vm.editContentTemplateForm.$setDirty();
    }
  };

  vm.update = () => {
    vm.lectureComponent.saveClonedViewOptions();

    vm.dismissedByUser = true;
    $uibModalInstance.close();
  };

  function isFormDirty() {
    return !!(vm.editContentTemplateForm?.$dirty);
  }

  const checkPreventNavigation = () => isFormDirty() || vm.previewEmailMode;

  useAngularPreventLecturePageNavigation($scope, checkPreventNavigation);

  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(checkPreventNavigation,
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    'FORM.NAVIGATE_AWAY.CLOSE_WINDOW',
    undefined,
    undefined,
    () => {
      vm.deregisterModalClosing?.();

      $uibModalInstance.dismiss();
    });

  vm.deregisterModalClosing = $scope.$on('modal.closing', ($event) => {
    if (isFormDirty() && !vm.dismissedByUser) {
      $event.preventDefault();

      ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-inline-changes-confirmation-overlay.html')
        .result.then(() => {
          vm.dismissedByUser = true;
          $uibModalInstance.dismiss();
        });
    }
  });

  $scope.$on('$destroy', () => {
    angular.element($window).off('beforeunload');
    vm.deregisterStateChangeStart?.();
  });
}
