/* @ngInject */
export default function DashboardResources(

  $resource,
) {
  return $resource(
    '/users/todos.json',
    {},
    {
      getCurrentLearning: { method: 'GET', url: '/users/current_learning.json' },
      getTodos: { method: 'GET', url: '/users/todos.json' },
      getCertificates: { method: 'GET', url: '/users/statements_and_certificates.json' },
      getActiveDrafts: { method: 'GET', url: '/users/active_drafts.json' },
      getSubmissions: { method: 'GET', url: '/users/all_submissions.json' },
      getCompletedCourses: { method: 'GET', url: '/users/completed_courses.json' },
      getDiscoveryCourses: { method: 'GET', url: '/courses/discovery.json' },
      getCurrentCourses: { method: 'GET', url: '/users/current_courses.json' },
      // Withdraw
      unenroll: { method: 'POST', url: ':catalogId/unenroll.json' },
      // Audit
      audit: { method: 'POST', url: ':catalogId/audit.json' },
      // Reverse Auditing Status
      reEnroll: { method: 'POST', url: ':catalogId/reenroll.json' },
    },
  );
}
