/*
this directive was made purely for the timeline reorder
the same libraried used in the old novoed platform was used for quickness
there may be better and newer options out there, and this directive should be updated accordingly when that reseach is done
pretty much copied from jquery.dnd_page_scroll
*/
/* @ngInject */
export default function NvDndScroll(
  nvUtil,
  $uibPosition,
  $timeout,
) {
  return {
    restrict: 'A',
    scope: true,
    link(scope, elem, attrs) {
      const scrollParent = $uibPosition.scrollParent(elem);
      const $scrollParent = $(scrollParent);

      const topId = `top_scroll_${nvUtil.randomPositiveInteger()}`;
      const bottomId = `bottom_scroll_${nvUtil.randomPositiveInteger()}`;
      const delay = 15;
      const heightToScroll = 40;

      let topEl = $(`#${topId}`);
      let bottomEl = $(`#${bottomId}`);

      if (!topEl.length) {
        topEl = $(`<div id='${topId}'>&nbsp;</div>`).appendTo(scrollParent);
      }
      if (!bottomEl.length) {
        bottomEl = $(`<div id="${bottomId}">&nbsp;</div>`).appendTo(scrollParent);
      }

      const bothEl = $(`#${topId}, #${bottomId}`);
      bothEl.hide();
      bothEl.css({
        position: 'fixed',
        left: 0,
        right: 0,
        zIndex: 1003,
      });
      topEl.css({
        top: 0,
        height: 100,
      });
      bottomEl.css({
        bottom: 0,
        height: 40,
      });

      let lastTop;
      let lastBottom;
      bothEl.on('dragenter', (e) => true);
      bothEl.on('dragover', function (e) {
        // Wait a little while before doing stuff here again
        if ($scrollParent.is(':animated')) {
          return true;
        }

        const scrollTop = $scrollParent.scrollTop();
        const direction = ($(this).attr('id') === topId) ? -1 : 1;
        const last = (direction === -1) ? lastTop : lastBottom;
        const current = (direction === -1) ? scrollTop : scrollParent.scrollHeight - (scrollTop + $scrollParent.height());

        if (last !== undefined && last === current && current > 0) {
          const newScrollTop = scrollTop + direction * heightToScroll;
          $scrollParent.animate(
            { scrollTop: newScrollTop },
            delay,
            'linear',
          );
        }

        if (direction === -1) {
          lastTop = current;
        } else {
          lastBottom = current;
        }
        return true;
      });

      // Function to hide the scroll areas. Reset everything.
      const _hide = function (e) {
        bothEl.hide();
        return true;
      };

      // When a DND drag event starts, show the scroll areas
      $timeout(() => {
        $scrollParent.find('[dnd-draggable]').on('dragstart', (e) => {
          bothEl.show();
          return true;
        });

        // When DND ends, hide it.
        $scrollParent.find('[dnd-draggable]').on('dragend', _hide);
      });

      // In IE dragend does not always get triggered.
      // Workaround by hiding areas when the mouse enters one.
      bothEl.on('mouseover', _hide);

      scope.$on('$destroy', () => {
        bothEl.remove();
        $scrollParent.find('[dnd-draggable]').off('dragstart');
        $scrollParent.find('[dnd-draggable]').off('dragend');
      });
    },
  };
}
