import { Fragment, useContext } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import {
  NvTableCellProps,
  NvTableRowProps,
} from 'shared/components/nv-responsive-table';
import { createGridStyles } from 'styles/global_defaults/scaffolding';

import { RootState } from 'redux/schemas';
import { getCurrentUser } from 'redux/selectors/users';
import { BasicMentee } from 'redux/schemas/models/course';
import { AngularServicesContext } from 'react-app';
import { coverImgStyles } from './styles';

type BasicMenteeCellProps = NvTableCellProps<BasicMentee, unknown> & {
  course: BasicMentee;
};

const Cell = ({
  children,
  rowKey,
  reactKey,
  serializedStyles,
  divProps,
  onClick,
  rowIndex,
}: any) => (
  <div
    className={rowKey}
    css={serializedStyles}
    key={reactKey}
    {...divProps}
    onClick={onClick}
    data-qa={`${rowKey}-${rowIndex}`}
    role='button'
    tabIndex='0'
  >
    {children}
  </div>
);

const CourseNameCell = (props: BasicMenteeCellProps) => {
  const { course } = props;
  const angularServices = useContext(AngularServicesContext);
  const { currentInstitutionId } = useSelector(state => state.app);
  const currentUser = useSelector(getCurrentUser);
  const institutionBrandColor = useSelector(
    state => state.models.institutions[currentInstitutionId]?.brandColor,
  );

  const goToCourseUrl = angularServices.$state.href('course', {
    catalogId: course.catalogId,
  });
  const courseCoverImage = course.thumbnail && !course.thumbnail.includes('temp.png') ? course.thumbnail : '';
  return (
    <div
      className='course-name-cell'
      css={props.serializedStyles}
      key={props.reactKey}
      {...props.divProps}
    >
      <a
        href={`${goToCourseUrl}/mentees/User/${currentUser.id}`}
        rel='noopener noreferrer'
        className='cover-image-container'
        data-offering-name={course.catalogId}
      >
        <div className='image' css={coverImgStyles(courseCoverImage, institutionBrandColor)} />
        <div>
          <p className='title'>{course.name}</p>
          <p className='course-catalog-id'>{course.catalogId}</p>
        </div>
      </a>
    </div>
  );
};

const MenteesCell = (props: BasicMenteeCellProps) => {
  const { menteeAlias } = props.course;
  const suffix = (!props.course.totalNoOfMentees || props.course.totalNoOfMentees > 1) ? menteeAlias.pluralizedTitleized : menteeAlias.singularizedTitleized;
  return (
    <Cell {...props}>{props.course.totalNoOfMentees} {suffix}</Cell>
  );
};

const createCellProps = (
  props: NvTableRowProps<BasicMentee, {}>,
  key: string,
  i: number,
  rowIndex: number,
) => ({
  ...props,
  reactKey: `${rowIndex}-${i + 1}`,
  serializedStyles: css``,
  rowKey: key,
  divProps: {
    style: createGridStyles(i + 1, rowIndex, i + 2, rowIndex + 1),
  },
  course: props.data,
});

const SupervisorCourseRow = (props: NvTableRowProps<BasicMentee, {}>) => {
  const cells: [string, (props: any) => JSX.Element][] = [
    ['course-name-cell', CourseNameCell],
    ['mentees-cell', MenteesCell],
  ];

  return (
    <Fragment>
      {cells.map((Item, i) => {
        const [key, Component] = Item;
        const cellProps = createCellProps(props, key, i, props.rowIndex);
        return <Component key={key} {...cellProps} />;
      })}
    </Fragment>
  );
};

export default SupervisorCourseRow;
