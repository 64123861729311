import { css } from '@emotion/core';
import React from 'react';
import t from 'react-translate';
import { LicenseInformation, LicenseType } from 'redux/selectors/licenses';

const styles = css`
  &.top-panel {
    max-width: 1020px;
    margin: 40px auto 0 auto;
    background-color: white;
    padding: 20px;
    &.content {
      display: flex;
      flex-direction: column;
    }
  }
`;
const itemstyles = css`
  &.item {
    display: flex;
    flex-direction: row;
    .title {
      min-width: 280px;
      font-weight: bold;
    }
  }
`;

const PanelItem = ({ title, value }) => {
  // dont render empty or null values, but 0 is valid
  if (value === null || value === '') {
    return null;
  }
  return (
    <div css={itemstyles} className="item">
      <div className="title">{title} :</div>
      <div className="value">{value}</div>
    </div>
  );
};

const LicenseTopPanel = (props: LicenseInformation) => {
  const {
    currentPeriodStartedAt,
    currentPeriodEndedAt,
    noSeats,
    unenrollGracePeriod,
    notes,
    licenseModel
  } = props;
  const getPanelTitle = (license: LicenseType) => {
    switch (license) {
      case LicenseType.ACTIVE_MONTH:
        return t.INSTITUTIONS.DASHBOARD.LICENSE_DASHBOARD.ACTIVE_USERS_MONTH_PURCHASED();
      case LicenseType.COURSE:
        return t.INSTITUTIONS.DASHBOARD.LICENSE_DASHBOARD.COURSES_PURCHASED();
      default:
        return t.INSTITUTIONS.DASHBOARD.LICENSE_DASHBOARD.LICENSE_PURCHASED();
    }
  };
  const panelTitle = getPanelTitle(licenseModel);
  return (
    <div css={styles} className="top-panel">
      <div className="content">
        <PanelItem
          title={t.INSTITUTIONS.DASHBOARD.LICENSE_DASHBOARD.LICENSE_PERIOD()}
          value={`${currentPeriodStartedAt.format('L')} - ${currentPeriodEndedAt.format('L')}`}
        />
        <PanelItem title={panelTitle} value={noSeats} />
        {licenseModel === LicenseType.ACCESS && (
          <PanelItem
            title={t.INSTITUTIONS.DASHBOARD.LICENSE_DASHBOARD.GRACE_PERIOD()}
            value={unenrollGracePeriod}
          />
        )}
        <PanelItem
          title={t.INSTITUTIONS.DASHBOARD.LICENSE_DASHBOARD.NOTES()}
          value={notes}
        />
      </div>
    </div>
  );
};
export default LicenseTopPanel;
