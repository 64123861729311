import { find, isEmpty, some, uniq } from 'underscore';
import * as yup from 'yup';
import { CSVHeader, ErrorType, downloadCSVFromJson, parseCSV } from 'shared/csv-utils';
import moment from 'moment';
import { css } from '@emotion/react';
import React, { useState } from 'react';
import t from 'react-translate';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

// Redux
import { useAppDispatch } from 'redux/store';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { useSelector } from 'react-redux';
import { addUsersToCourse } from 'redux/actions/users';
import { CourseRole } from 'redux/schemas/models/org-level-roles';
import { CombinedInstitution, RootState } from 'redux/schemas';
import { Role } from 'redux/schemas/models/role';
import { getCollectionRoles } from 'redux/selectors/roles';
import { getCurrentInstitution } from 'redux/selectors/institutions';

// Components
import NvDatePicker, { DatePickerType } from 'shared/components/inputs/nv-datepicker';
import NvFormDropdown from 'shared/components/inputs/nv-form-dropdown';
import NvDropdown, { NvDropdownButtonStyle, NvDropdownCustomItem, NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';
import NvFilePicker from 'shared/components/nv-filepicker';
import { Button } from 'react-bootstrap';
import { config } from '../../../../config/config.json';
import { CollectionRoleItem } from './add-user-modal';


enum ScheduleValueName {
  SEND_WHEN_ENROLLED = 'Send When Enrolled',
  SCHEDULE_FOR_LATER = 'Schedule for Later',
}

const UserBulkUploadModal = (props: { closeModal(reload?: boolean): void }) => {
  const { closeModal } = props;
  const [validationErrors, setValidationErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [showRoleSettingModal, setShowRoleSettingModal] = useState(false);
  const [collectionUsers, setCollectionUsers] = useState([]);
  const [scheduleType, setScheduleType] = useState(ScheduleValueName.SEND_WHEN_ENROLLED);
  const methods = useForm();
  const { handleSubmit, getValues, watch } = methods;
  const dispatch = useAppDispatch();
  const collectionRoles: CourseRole[] = useSelector<RootState, Role>(getCollectionRoles);
  const institution = useSelector<RootState, CombinedInstitution>(getCurrentInstitution);
  const { catalogId } = useParams<{ collectionId: string, catalogId: string }>();
  const hasSsoLogin = institution.ssoLogin;
  const [scheduleDate] = watch(['scheduleDate']);

  const collectionRoleDropdownStyle = css`
    height: 80px;
  `;
  const bodyStyle = css`
    max-height: calc(100vh - 180px);

    .mail-dropdown {
      .bs4-dropdown {
        border: none;
      }
    }
  `;

  const validationSchema = yup.object().shape({
    firstName: yup.string().nullable().required(t.VALIDATION.REQUIRED()),
    lastName: yup.string().nullable().required(t.VALIDATION.REQUIRED()),
    email: yup.string().nullable()
      .required(t.VALIDATION.REQUIRED())
      .email(t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.INVALID_EMAIL()),
    externalId: yup.string(),
  });

  const submit = (data) => {
    const convertDateFormat = (date) => {
      if (date) {
        const originalDateFormat = moment(data.scheduleDate._i, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
        const convertedDateFormat = originalDateFormat.subtract(1, 'day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        return convertedDateFormat;
      }
      return null;
    };
    const payload = {
      catalogId,
      courseRoleId: data.courseRoleId.toString(),
      users: [...collectionUsers],
      scheduleOn: convertDateFormat(data?.scheduleDate?._i),
    };
    setSaving(true);
    dispatch(addUsersToCourse({ ...payload })).then((response) => {
      const AddedUsersDetails = {
        addedCount: response.payload.newlyEnrolled.length,
        existingCount: response.payload.existing.length,
        rolesChangedCount: response.payload.rolesChanged.length,
        courseAlias: t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTION_NAME.DOWNCASED_SINGULARIZED(),
      };
      setSaving(false);
      if (isEmpty(response?.error)) {
        let message = '';
        if (response.payload?.threshold) {
          dispatch(addAlertMessage({
            type: AlertMessageType.WARNING,
            header: t.FORM.SUCCESS_BANG(),
            message: t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_BEING_ADDED(),
          }));
        } else {
          if (response.payload.existing.length > 0 && response.payload.newlyEnrolled.length > 0 && response.payload.rolesChanged.length > 0) {
            message = t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_ADDED_EXIST_ROLE_CHANGES(AddedUsersDetails);
          } else if (response.payload.existing.length > 0 && response.payload.newlyEnrolled.length > 0 && response.payload.rolesChanged.length === 0) {
            message = t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_ADDED_SOME_EXIST(AddedUsersDetails);
          } else if (response.payload.existing.length === 0 && response.payload.newlyEnrolled.length > 0 && response.payload.rolesChanged.length > 0) {
            message = t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_ADDED_ROLE_CHANGES(AddedUsersDetails);
          } else if (response.payload.existing.length > 0 && response.payload.newlyEnrolled.length === 0 && response.payload.rolesChanged.length > 0) {
            message = t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_ROLES_CHANGED_SOME_EXIST(AddedUsersDetails);
          } else if (response.payload.existing.length > 0 && response.payload.newlyEnrolled.length === 0 && response.payload.rolesChanged.length === 0) {
            message = t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_EXIST(AddedUsersDetails);
          } else if (response.payload.existing.length === 0 && response.payload.newlyEnrolled.length > 0 && response.payload.rolesChanged.length === 0) {
            message = t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_ADDED(AddedUsersDetails);
          } else if (response.payload.existing.length === 0 && response.payload.newlyEnrolled.length === 0 && response.payload.rolesChanged.length > 0) {
            message = t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_ROLES_CHANGED(AddedUsersDetails.rolesChangedCount);
          }
          dispatch(addAlertMessage({
            type: AlertMessageType.SUCCESS,
            message,
          }));
        }
      }
      closeModal(true);
    });
  };
  const collectionRoleItems: NvDropdownCustomItem[] = collectionRoles.map(role => ({
    id: role.id,
    type: 'custom',
    customItem: (
      <CollectionRoleItem
        role={role}
        onClick={(roleId) => methods.setValue('courseRoleId', roleId, { shouldValidate: true })}
      />),
  }));

  const pushUsersData = (data) => {
    let collectionUsersData = [];
    if (hasSsoLogin) {
      data.forEach((user) => {
        collectionUsersData.push({ firstName: user.firstName, lastName: user.lastName, email: user.email, externalId: user.externalId });
      });
    } else {
      data.forEach((user) => {
        collectionUsersData.push({ firstName: user.firstName, lastName: user.lastName, email: user.email });
      });
    }
    collectionUsersData = uniq(collectionUsersData, false, (user) => user.email);
    setCollectionUsers([...collectionUsersData]);
  };

  const parseCallback = (response) => {
    if (response.errors.length > 0) {
      if (response.data) {
        pushUsersData(response.data);
        setShowRoleSettingModal(true);
      }
      if (some(response.errors, (error) => error.type === ErrorType.VALIDATION)) {
        setValidationErrors(response.errors);
      } else {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.NO_USERS(),
        }));
      }
      setLoading(false);
    } else if (response.data.length > 0) {
      setShowRoleSettingModal(true);
      pushUsersData(response.data);
    }
  };

  const onCSVSelected = (files: File[]) => {
    setCollectionUsers([]);
    setValidationErrors([]);
    const [file] = files;

    if (!file) {
      return;
    }
    setLoading(true);
    parseCSV({
      headers,
      file,
      validationSchema,
      callback: parseCallback,
    });
  };
  const onFileError = () => {
    setCollectionUsers([]);
    setValidationErrors([]);

    dispatch(addAlertMessage({
      type: AlertMessageType.ERROR,
      header: t.FORM.OOPS(),
      message: t.FILE_UPLOAD.NOT_SUPPORTED_ALERT(),
    }));
  };
  const headers: CSVHeader[] = [
    {
      key: 'firstName',
      headerName: 'First Name',
    },
    {
      key: 'lastName',
      headerName: 'Last Name',
    },
    {
      key: 'email',
      headerName: 'Email',
    },
  ];
  if (hasSsoLogin) {
    headers.push({
      key: 'externalId',
      headerName: 'External ID (Optional)',
    });
  }
  const downloadTemplate = () => {
    if (hasSsoLogin) {
      downloadCSVFromJson({
        headers,
        dummyRows: {
          totalRows: 2,
          values: {
            firstName: ['John', 'Jane'],
            lastName: ['Doe', 'Doe'],
            email: ['Johndoe@johndoe.com', 'Janedoe@janedoe.com'],
            externalId: ['ID_ASSIGNED_BY_CORPORATE_IDENTITY_PROVIDER', 'ID_ASSIGNED_BY_CORPORATE_IDENTITY_PROVIDER'],
          },
        },
      });
    } else {
      downloadCSVFromJson({
        headers,
        dummyRows: {
          totalRows: 2,
          values: {
            firstName: ['John', 'Jane'],
            lastName: ['Doe', 'Doe'],
            email: ['Johndoe@johndoe.com', 'Janedoe@janedoe.com'],
          },
        },
      });
    }
  };

  const scheduleOptions: NvDropdownTextItem[] = [
    {
      id: 0,
      type: 'text',
      text: `${t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.SCHEDULE_WELCOME_EMAIL.SEND_WHEN_ENROLLED()}`,
      value: `${ScheduleValueName.SEND_WHEN_ENROLLED}`,
      callback: () => setScheduleType(ScheduleValueName.SEND_WHEN_ENROLLED),
    },
    {
      id: 1,
      type: 'text',
      text: `${t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.SCHEDULE_WELCOME_EMAIL.SCHEDULE_FOR_LATER()}`,
      value: `${ScheduleValueName.SCHEDULE_FOR_LATER}`,
      callback: () => setScheduleType(ScheduleValueName.SCHEDULE_FOR_LATER),
    },
  ];
  const viewerRole = find(collectionRoles, (role) => isEmpty(role.permissions));
  const getRoleItemTitle = (): string => find(collectionRoles, (role) => role.id === getValues()?.courseRoleId)?.name;
  const getTitle = (title) => {
    if (title === t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.SCHEDULE_WELCOME_EMAIL.SEND_WHEN_ENROLLED()) {
      return `${t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.SCHEDULE_WELCOME_EMAIL.WILL_SEND()} ${t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.SCHEDULE_WELCOME_EMAIL.SEND_WHEN_ENROLLED()}`;
    }

    return title;
  };
  return (
    <div className='d-flex flex-column mx-auto' css={bodyStyle}>
      {
        showRoleSettingModal && validationErrors.length === 0
        && (
        <div>
          <p>{t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.LIST_OF_USERS_SELECT_ROLES()}</p>

          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submit)}>
              <div className='d-flex mt-3 row ml-1 mail-dropdown'>
                <NvDropdown
                  showSelectedIndicator
                  items={scheduleOptions}
                  buttonStyle={NvDropdownButtonStyle.FORM_SMALL}
                  title={getTitle(scheduleType)}
                  initialIndex={0}
                  titleClass=' mr-1 text-primary'
                />
                {scheduleType === ScheduleValueName.SCHEDULE_FOR_LATER
              && (

              <NvDatePicker
                min={moment()}
                withForm
                required
                fixed
                placement='bottom'
                name='scheduleDate'
                type={DatePickerType.DATETIME}
                className='ml-5'
                placeholder={t.FORM.SELECT_DATE_TIME()}
                dataQa={config.pendo.cloningModal.startDate}
              />
              )}
              </div>

              <div
                className='font-weight-bolder mt-3'
              >
                <span className='warning'>{t.LHS.BOOKMARKS.NOTE()}: </span>
                {t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.COLLECTION_ROLE_SELECT_NOTE()}
              </div>

              <div className='d-flex p-4 bg-gray-6 mt-4 row mx-1' css={collectionRoleDropdownStyle}>
                <div className='font-weight-bolder col-md-5 py-2'>{t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.APPLY_SAME_ROLE_AS()}</div>
                <div className='font-weight-bolder col-md-4'>
                  <NvFormDropdown
                    name='courseRoleId'
                    items={collectionRoleItems}
                    withFormDefaultValue={viewerRole?.id}
                    title={getRoleItemTitle()}
                    titleClass='text-black'
                  />
                </div>
              </div>
              <table className='w-100'>
                <tbody>
                  {collectionUsers.map((user) => (

                    <tr className='row border-bottom border-gray-5'>
                      <td className='col-md-4 font-weight-bolder py-4 pl-4'>{user.firstName} {user.lastName}</td>
                      <td className='col-md-4 py-4 pl-4'>{user.email}</td>
                      <td className='col-md-4 py-4 pl-4'>{user?.externalId}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p className='mt-3'>{t.MENTEES.TABS.TOTAL()}: {collectionUsers.length}</p>
              <div className='d-flex justify-content-center mt-7 mb-3'>
                <Button
                  className='mr-1'
                  onClick={() => setShowRoleSettingModal(false)}
                  variant='outline-primary'
                >{t.FORM.CANCEL()}
                </Button>
                <Button
                  type='submit'
                  className='ml-1'
                  disabled={saving || (scheduleType !== ScheduleValueName.SEND_WHEN_ENROLLED && !scheduleDate)}
                >{saving ? t.FORM.ADDING() : t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.ADD_ALL()}
                </Button>
              </div>
            </form>

          </FormProvider>

        </div>
        )
      }
      {!!validationErrors.length && (
        <React.Fragment>
          <p className='description mb-2 text-body'>
            {t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.ERROR_DESCRIPTION(validationErrors.length)}
          </p>
          <table className='mb-5'>
            <tbody>
              {validationErrors.map((error) => (
                <tr
                  className='font-weight-normal border-bottom border-gray-5'
                  key={`${error.row}-${error.column}`}
                >
                  <td className='py-2 row-no'>{t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.ROW(error.row)}</td>
                  <td className='py-2 column-no'>{t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.COLUMN(error.column)}</td>
                  <td className='py-2 text-danger'>{error.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='d-flex mx-auto mb-4 mt-5'>
            <NvFilePicker
              multiple={false}
              accept={['.csv', 'text/csv']}
              onChange={(e) => onCSVSelected(e)}
              onSelectError={onFileError}
            >
              <Button
                variant='secondary'
                className='font-weight-bolder'
                disabled={loading}
              >
                {t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.UPLOAD_NEW_CSV()}
              </Button>
            </NvFilePicker>
            <Button
              className='ml-1'
              onClick={() => {
                setValidationErrors([]);
                setShowRoleSettingModal(true);
              }}
              disabled={collectionUsers.length === 0}
            >
              {t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.PROCEED_WITH_THE_REST()}
            </Button>
          </div>
        </React.Fragment>
      )}
      {!validationErrors.length && !showRoleSettingModal && (
        <React.Fragment>
          <p className='description'>
            {t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.ADD_USER_BULK_MODAL.DESCRIPTION()}
          </p>
          <div className='d-flex mx-auto mt-7'>
            <Button
              variant='secondary'
              className='font-weight-bolder'
              onClick={downloadTemplate}
            >
              {t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.DOWNLOAD_TEMPLATE()}
            </Button>
            <NvFilePicker
              multiple={false}
              accept={['.csv', 'text/csv']}
              onChange={(e) => onCSVSelected(e)}
              onSelectError={onFileError}
            >
              <Button className='ml-2'>
                {t.INSTITUTIONS.ORG_MENTORS.BULK_UPLOAD_MODAL.UPLOAD_CSV()}
              </Button>
            </NvFilePicker>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default UserBulkUploadModal;

