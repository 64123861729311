import React from 'react';
import _ from 'lodash';
import t from 'react-translate';

// Redux
import { useSelector } from 'react-redux';
import { getCurrentCourse } from 'redux/selectors/course';
import { sortSkillTags } from 'redux/selectors/skills-feedback';

// Components
import NvIcon from 'shared/components/nv-icon';
import NvViewMore, { ViewMoreBehavior, ViewMoreCollapser } from 'shared/components/nv-view-more';

// Styles
import { css } from '@emotion/react';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { NvModalProps } from 'shared/components/nv-modal';
import NvSkillsView, { getModalSettingsForSkillView } from 'shared/components/nv-skills-view';
import { gray7 } from 'styles/global_defaults/colors';

const SkillViewCourseFlyer = () => {
  const currentCourse = useSelector(getCurrentCourse);
  const { nameFontColor, skillTags = [] } = currentCourse;
  const sortedSkillTags = sortSkillTags(_.cloneDeep(skillTags));

  const modalSettings: NvModalProps = getModalSettingsForSkillView({
    header: t.INSTITUTIONS.SKILL_TAGS.SKILL_VIEW_FOR_LEARNERS.SKILLS_RELATED(),
    skillTags: sortedSkillTags,
  })

  const styles = css`
    background-color: ${gray7};
    color: ${nameFontColor};

    .skill-view-wrapper {
      gap: ${halfSpacing}px;
      flex-wrap: wrap;
    }
  `;

  return (
    <div className='pt-4 pb-4' css={styles}>
      <div className='body-text-wrapper'>
        <div className='d-flex justify-content-center mb-4 bold align-items-center text-body'>
          <NvIcon icon='skill' size='small' className='mr-2 bold' />
          {t.INSTITUTIONS.SKILL_TAGS.SKILL_VIEW_FOR_LEARNERS.COURSE_FLYER(sortedSkillTags.length)}
        </div>
        <NvViewMore
          type={ViewMoreBehavior.MODAL}
          text={t.INSTITUTIONS.SKILL_TAGS.SKILL_VIEW_FOR_LEARNERS.VIEW_ALL()}
          maxRows={3}
          collapser={ViewMoreCollapser.NORMAL}
          modalSettings={modalSettings}
        >
          <NvSkillsView skillTags={sortedSkillTags} />
        </NvViewMore>
      </div>
    </div>
  );
};

export default SkillViewCourseFlyer;
