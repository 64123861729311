import React from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';

import { useAppDispatch } from 'redux/store';
import { getCourseAliases } from 'redux/selectors/course';
import { togglePortableCompletionPanel } from 'redux/actions/timeline';
import { CompletionCriteriaType } from 'redux/schemas/app/timeline';

import NvProgressGauge, { ProgressGaugeSize, ProgressGaugeType } from 'shared/components/nv-progress-gauge';
import { primary, success, warning } from 'styles/global_defaults/colors';
import NvTooltip from 'shared/components/nv-tooltip';
import ClickableContainer from 'components/clickable-container';
import CriteriaProgressBar, { CriteriaProgressBarContext } from '../criteria-progress-bar';
import useCompletionCriteria from '../use-completion-criteria';

type HeaderGaugeConfig = {
  type: CompletionCriteriaType,
  required: number,
  completed: number,
  icon: string,
  color: string,
  tooltip: string,
};

const HeaderGauges = () => {
  const dispatch = useAppDispatch();
  const aliases = useSelector((state) => getCourseAliases(state));

  const {
    requiredPoints,
    requiredAssignmentsCount,
    totalRequiredTodosCount,

    pointsReceived,
    todoAssignmentsCompleted,
    requiredTodosCompleted,

    enabledFactorCount,
  } = useCompletionCriteria();

  const displayRadialGraph = enabledFactorCount > 1;

  const displayPortableCompletionPanel = () => {
    dispatch(togglePortableCompletionPanel());
  };

  const gauges: { [key: string]: HeaderGaugeConfig } = {
    points: {
      type: CompletionCriteriaType.POINTS,
      required: requiredPoints,
      completed: pointsReceived,
      icon: 'highlight',
      color: success,
      tooltip: t.COMPLETION_CRITERIA.RECEIVED_POINTS({
        receivedPoints: pointsReceived,
        requiredPoints,
        ...aliases.pointsAliases,
      }),
    },
    assignments: {
      type: CompletionCriteriaType.ASSIGNMENTS,
      required: requiredAssignmentsCount,
      completed: todoAssignmentsCompleted,
      icon: 'highlight',
      color: warning,
      tooltip: t.COMPLETION_CRITERIA.COMPLETED_ASSIGNMENTS({
        completedAssignmentsCount: todoAssignmentsCompleted,
        requiredAssignmentsCount,
        assignmentAliasSingularized: aliases.assignmentAliases.assignmentAlias,
        assignmentAliasPluralized: aliases.assignmentAliases.assignmentsAlias,
      }),
    },
    todo: {
      type: CompletionCriteriaType.TODOS,
      required: totalRequiredTodosCount,
      completed: requiredTodosCompleted,
      icon: 'highlight',
      color: primary,
      tooltip: t.COMPLETION_CRITERIA.COMPLETED_REQUIRED_TODOS(
        requiredTodosCompleted,
        totalRequiredTodosCount,
      ),
    },
  };

  return (
    <div className='d-flex'>
      {['points', 'assignments', 'todo'].map((criteria) => (
        <React.Fragment key={`header-gauge-${criteria}`}>
          {gauges[criteria].required > 0 && (
            <NvTooltip
              text={gauges[criteria].tooltip}
              placement='bottom'
            >
              <ClickableContainer
                onClick={displayPortableCompletionPanel}
              >
                {displayRadialGraph && (
                  <div className='px-2'>
                    <NvProgressGauge
                      maxValue={gauges[criteria].required}
                      currentValue={gauges[criteria].completed}
                      size={ProgressGaugeSize.SMALL}
                      displayType={ProgressGaugeType.ACTUAL_ONLY}
                      icon={gauges[criteria].icon}
                      activeColor={gauges[criteria].color}
                    />
                  </div>
                )}
                {!displayRadialGraph && (
                  <CriteriaProgressBar
                    maxValue={gauges[criteria].required}
                    currentValue={gauges[criteria].completed}
                    activeColor={gauges[criteria].color}
                    type={gauges[criteria].type}
                    context={CriteriaProgressBarContext.HEADER}
                  />
                )}
              </ClickableContainer>
            </NvTooltip>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default HeaderGauges;
