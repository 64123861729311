import { css } from '@emotion/react';
import { useContext } from 'react';

import ClickableContainer from 'components/clickable-container';
import NvIcon from 'shared/components/nv-icon';
import { CourseObjectBookmark, MediaBookmarkComponent } from 'redux/schemas/models/bookmark';
import { doubleSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { AngularServicesContext } from 'react-app';

type VideoSnippetProps = {
  bookmark: CourseObjectBookmark
};

const VideoSnippet = ({
  bookmark: { catalogId, lecturePageId, lectureComponentId, component },
}: VideoSnippetProps) => {
  const styles = css`
    &.video-snippet {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text-wrapper {
        padding: ${standardSpacing}px;
        padding-left: ${doubleSpacing}px;
        min-width: 0;

        .title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .subtitle {
          display: flex;

          .lecture-page-name, .course-name {
            max-width: 50%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .middot {
            margin-left: ${quarterSpacing}px;
            margin-right: ${quarterSpacing}px;
          }
        }
      }

      .image-container {
        position: relative;
        flex-shrink: 0;

        img {
          object-fit: cover;
          width: 110px;
          height: 80px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        .icon-play {
          position: absolute;
          color: white;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  `;

  const { $state, FlyoutModalManager } = useContext(AngularServicesContext);

  const {
    title,
    video,
    lecturePageName,
    courseName,
    expired,
  } = component as MediaBookmarkComponent;

  const onClickSnippet = () => {
    if (expired) { return; }

    FlyoutModalManager?.closeFlyout();
    $state.go('lecture-page', { catalogId, id: lecturePageId, lectureActivityId: lectureComponentId });
  };

  return (
    <ClickableContainer className='video-snippet' css={styles} onClick={onClickSnippet}>
      <div className='text-wrapper'>
        <div className='title text-body font-weight-bold'>{title}</div>
        <div className='subtitle text-gray-2 text-small'>
          <div className='lecture-page-name'>{lecturePageName}</div>
          <div className='middot'>&middot;</div>
          <div className='course-name'>{courseName}</div>
        </div>
      </div>
      <div className='image-container'>
        <img src={video.thumbnailUrl} alt={title} />
        <NvIcon icon='play' size='medium' />
      </div>
    </ClickableContainer>
  );
};

export default VideoSnippet;
