import t from 'react-translate';

type ValidationErrorMessageProps = {
  text: string;
  header?: string;
  headerClassName?: string
};

const ValidationErrorMessage = (props: ValidationErrorMessageProps) => {
  const { header, text, headerClassName } = props;
  return (
    <div className='flex-column'>
      <div className={`${headerClassName} mx-auto text-danger text-small text-center`}>
        {header ?? t.FORM.WARNING()}
      </div>
      <div className='mt-2'>{text}</div>
    </div>
  );
};

export default ValidationErrorMessage;
