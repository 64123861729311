import React, { useState } from 'react';
import { css } from '@emotion/react';
import { SkillTag } from 'redux/schemas/models/skill-tag';
import { deleteSkillTag } from 'redux/actions/skill-tags';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/schemas';
import SkillItemAdmin from './skill-item-admin';


const styles = css`
  &.skill-cell{
    min-width:0;
    display:flex;
  }
  &.cell-centered{
    display:flex;
    justify-content:center;
    align-items:center;
  }
  `;

const SkillRow = (props: SkillTag) => {
  const dispatch = useAppDispatch();
  const currentInstitutionId = useSelector(
    (state: RootState) => state.app.currentInstitutionId,
  );
  const lastCreatedItem = useSelector(
    (state: RootState) => state.app.skillTag.lastInsertedTagId,
  );
  const doDelete = () => {
    dispatch(deleteSkillTag({ institutionId: currentInstitutionId, tag: props }));
  };
  return (
    <>
      <div css={styles} className='skill-cell'>
        <SkillItemAdmin highlight={props.id === lastCreatedItem} name={props.name} onDelete={doDelete} showConfirmation={props.activitiesTagged !== 0 || props.coursesTagged !== 0 || props.name.toLowerCase().indexOf('testing') !== -1} />
      </div>
      <div css={styles} className='cell-centered'>{props.activitiesTagged}</div>
      <div css={styles} className='cell-centered'>{props.coursesTagged}</div>
    </>
  );
};

export default SkillRow;
