import React from 'react';

import t from 'react-translate';

type OnShowAllHandler = (triggeredByKeyboard: boolean) => void;

/**
 * CardsResponsiveListing component specific hook. It provides the interface
 * that CardsResponsiveListing uses to actually "truncate" the amount of
 * elements to render.
 * @param {Array} items - Array of data to render
 * @param {number} maxItems - Max items to display in the truncated state
 * @returns {Array} First index value is the array of data to render and the
 * second index value is a react element that acts as the toggle of the
 * truncated state.
 */
const useDashboardListingCollapser = <T extends unknown>(
  items: T[],
  maxItems: number,
  onShowAll?: OnShowAllHandler,
) => {
  const eventDetailRef = React.useRef<number>();
  const [showAll, setShowAll] = React.useState(false);
  const onShowAllRef = React.useRef<OnShowAllHandler>();
  onShowAllRef.current = onShowAll;

  const itemsCopy = [...items];
  const collapsedItems = itemsCopy.slice(0, showAll ? itemsCopy.length : maxItems);

  React.useEffect(() => {
    if (showAll) {
      const triggeredByKeyboard = eventDetailRef.current === 0;

      onShowAllRef.current(triggeredByKeyboard);
    }
  }, [showAll]);

  const handleVisibilityToggleClick = (e) => {
    eventDetailRef.current = e.detail;
    setShowAll((prevShowAll) => !prevShowAll);
  };

  const collapser = items.length > maxItems && (
    <div className='text-center'>
      <button
        type='button'
        className='btn btn-link hovered'
        onClick={handleVisibilityToggleClick}
        key={showAll ? 'view-less' : 'view-more'}
      >
        {showAll ? t.SHARED.VIEW_LESS() : t.SHARED.VIEW_MORE()}
      </button>
    </div>
  );

  return [collapsedItems, collapser] as [T[], React.ReactElement];
};

export default useDashboardListingCollapser;
