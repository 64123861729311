import moment, { Moment } from 'moment';

import { useState, useRef, useEffect } from 'react';

/**
 * Creates a timer allows for getting the number of seconds remaining as well as a callback when the time is up
 * The timer checks the time every 1 second
 * The timer exposes [remainingSeconds, start, pause, resume, stop]
 */
const useTimer = () => {
  // these are passed in by the caller
  const totalLengthRef = useRef<number>();
  const timeUpCallbackRef = useRef<() => void>();

  // internal references
  const startTimeRef = useRef<Moment>();
  const previouslyElapsedTimeRef = useRef(0);// only used for pausing
  const [remainingSeconds, setRemainingSeconds] = useState<number>();
  const setTimeoutRef = useRef<number>();
  const isPaused = useRef(false);

  // make sure to clear the timeout on component removal
  useEffect(() => () => clearTimeout(setTimeoutRef.current), []);

  const countdownTimeoutCallback = () => {
    const secondsElapsed = moment().diff(startTimeRef.current) / 1000 + previouslyElapsedTimeRef.current;
    setRemainingSeconds(totalLengthRef.current - Math.floor(secondsElapsed));

    if (secondsElapsed >= totalLengthRef.current) {
      setTimeoutRef.current = null;
      timeUpCallbackRef.current();
    } else {
      setTimeoutRef.current = window.setTimeout(countdownTimeoutCallback, 1000);
    }
  };

  /**
   * Start the timer
   * @param length number of seconds for the timer
   * @param timeUpCallback callback to trigger when the time has elapsed
   */
  const start = (
    length: number = 0,
    timeUpCallback: () => void = () => {},
  ) => {
    totalLengthRef.current = length;
    timeUpCallbackRef.current = timeUpCallback;

    startTimeRef.current = moment();
    previouslyElapsedTimeRef.current = 0;
    setRemainingSeconds(length);
    setTimeoutRef.current = window.setTimeout(countdownTimeoutCallback, 1000);
  };

  /**
   * Pause the timer
   */
  const pause = () => {
    if (!isPaused.current) {
      const secondsElapsed = moment().diff(startTimeRef.current) / 1000;
      previouslyElapsedTimeRef.current += secondsElapsed;

      clearTimeout(setTimeoutRef.current);
      isPaused.current = true;
    }
  };

  /**
   * Resumes the timer after a pause has been called
   */
  const resume = () => {
    if (isPaused.current) {
      startTimeRef.current = moment();
      setTimeoutRef.current = window.setTimeout(countdownTimeoutCallback, 1000);
      isPaused.current = false;
    }
  };

  /**
   * Stops the timer. This is functionally the same as pause currently, but will likely diverge in the future
   */
  const stop = () => {
    const secondsElapsed = moment().diff(startTimeRef.current) / 1000;
    previouslyElapsedTimeRef.current += secondsElapsed;

    clearTimeout(setTimeoutRef.current);
  };

  return [remainingSeconds, start, pause, resume, stop] as const;
};

export default useTimer;
