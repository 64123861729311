import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AngularServicesContext, AngularContext } from 'react-app';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import SectionContent from '../section-content';
import { DisableProps } from './integrations';
import { config } from '../../../../../config/pendo.config.json';

const DegreedIntegration = (props: { onDisable: (props: DisableProps) => void }) => {
  const { $uibModal } = useContext(AngularServicesContext);
  const { injectServices, $scope } = useContext(AngularContext);
  const [InstitutionsManager] = injectServices(['InstitutionsManager']);
  const [degreedSettings, setDegreedSettings] = useState($scope.$eval('vm.InstitutionsManager.institution.degreedSettings'));

  const hasEnabledDegreed = useCallback(() => degreedSettings?.apiKey?.length && degreedSettings?.apiSecret?.length, [degreedSettings]);

  useEffect(() => {
    $scope.$watch('vm.InstitutionsManager.institution.degreedSettings', (newValue) => {
      setDegreedSettings(newValue);
    });
  }, [$scope]);

  const configureDegreed = () => {
    $uibModal.open({
      templateUrl: 'institutions/templates/configure-degreed-modal.html',
      controller: 'ConfigureDegreedModalCtrl as vm',
      resolve: {
      },
    });
  };

  const disableDegreed = () => {
    props.onDisable({
      action: () => InstitutionsManager.saveDegreedSettings('', ''),
      onSuccess: () => InstitutionsManager.institution.updateFromReact({
        degreedSettings: null,
      }),
      successMessage: t.INSTITUTIONS.ADVANCED_SETTINGS.DEGREED_INTEGRATION.DISABLE_SUCCESS(),
      title: t.INSTITUTIONS.ADVANCED_SETTINGS.DEGREED_INTEGRATION.DISABLE_CONFIRM_TITLE(),
      bodyText: t.INSTITUTIONS.ADVANCED_SETTINGS.DEGREED_INTEGRATION.DISABLE_DESCRIPTION(),
      confirmDataQa: config.pendo.settings.degreed.disableConfirm,
      cancelDataQa: config.pendo.settings.degreed.disableCancel,
    });
  };

  return (
    <SectionContent
      header={t.INSTITUTIONS.ADVANCED_SETTINGS.DEGREED_INTEGRATION.TITLE()}
      description={[t.INSTITUTIONS.ADVANCED_SETTINGS.DEGREED_INTEGRATION.DESCRIPTION()]}
      showCTAButton={!hasEnabledDegreed()}
      ctaText={t.INSTITUTIONS.ADVANCED_SETTINGS.CONFIGURE()}
      onAction={configureDegreed}
      dataQa={config.pendo.settings.degreed.cta}
      extras={hasEnabledDegreed() ? (
        <div className='button-bar'>
          <Button
            variant='secondary'
            onClick={disableDegreed}
            data-qa={config.pendo.settings.degreed.disable}
          >
            {t.INSTITUTIONS.ADVANCED_SETTINGS.DEGREED_INTEGRATION.DISABLE()}
          </Button>
          <Button
            variant='primary'
            onClick={configureDegreed}
            data-qa={config.pendo.settings.degreed.edit}
          >
            {t.INSTITUTIONS.ADVANCED_SETTINGS.DEGREED_INTEGRATION.EDIT()}
          </Button>
        </div>
      ) : null}
    />
  );
};

export default DegreedIntegration;
