/* @ngInject */
export default function NvCustomValidationPopover(
  $compile,
  $parse,
  $timeout,
  _,
) {
  return {
    restrict: 'A',
    terminal: true,
    priority: 1000,
    link: function link(scope, element, attrs) {
      scope.$watch(
        () => $parse(attrs.nvCustomValidationPopover)(scope),
        (ngModelCtrl) => {
          if (ngModelCtrl) {
            ngModelCtrl.customValidationRules = $parse(attrs.customValidationRules)(scope);
          }
        },
      );


      let isOpenString = 'ngModelCtrl.$showValidationMessage && ngModelCtrl.$invalid'.replace(/ngModelCtrl/g, attrs.nvCustomValidationPopover);
      if (Object.prototype.hasOwnProperty.call(attrs, 'ngfDrop') || Object.prototype.hasOwnProperty.call(attrs, 'ngfSelect')) {
        isOpenString = `${attrs.nvCustomValidationPopover}.$errorParam`;
      }

      element.on('blur', (e) => {
        setValidity();
      });

      element.on('keyup', (e) => {
        setValidity();
      });

      // workaround: $timeout added because we need to attach event listeners
      // to elements that may not exist at the moment this derective runs
      $timeout(() => {
        const triggerCustomValidationElementNames = $parse(attrs.triggerCustomValidationFor)(scope) || [];
        const triggerCustomValidationForElements = triggerCustomValidationElementNames.map(
          name => $(`[name="${name}"]`),
        );

        triggerCustomValidationForElements.forEach(
          elem => {
            elem.on('blur', e => {
              setValidityForOther();
            });

            elem.on('keyup', e => {
              setValidityForOther();
            });
          },
        );
      });

      element.attr('uib-popover-template', '"shared/templates/nv-custom-validation-popover-body.html"');
      element.attr('popover-title', 'Warning');
      element.attr('popover-class', 'validation');
      element.attr('popover-trigger', 'none');
      element.attr('popover-is-open', isOpenString);
      element.attr('popover-placement', 'top');

      element.attr('input-name', attrs.nvCustomValidationPopover);
      element.attr('validate-on-blur', 'true');

      // cleanup existing instance to prevent multiple compilations
      element.removeAttr('nv-custom-validation-popover');
      element.removeAttr('data-nv-custom-validation-popover');

      $compile(element)(scope);

      // Private Functions
      function setValidity() {
        const ngModelCtrl = $parse(attrs.nvCustomValidationPopover)(scope);

        let isValid;
        if (ngModelCtrl) {
          _.each(ngModelCtrl.customValidationRules, (rule) => {
            rule.isInvalid = $parse(rule.invalidOn)(scope);
          });

          isValid = _.every(ngModelCtrl.customValidationRules, (rule) => !rule.isInvalid);

          ngModelCtrl.$setValidity('nvCustomValidationPopover', isValid);
        }

        return isValid;
      }

      function setValidityForOther() {
        $timeout(() => {
          const ngModelCtrl = $parse(attrs.nvCustomValidationPopover)(scope);
          if (ngModelCtrl) {
            const isValid = setValidity();
            if (isValid) {
              ngModelCtrl.$showValidationMessage = false;
            } else {
              ngModelCtrl.$showValidationMessage = true;
            }
          }
        });
      }
    },
  };
}
