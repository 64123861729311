import { css } from '@emotion/react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import _ from 'underscore';
import * as yup from 'yup';

import validationConstants from 'shared/services/constants-shared';
import NvTextInput from 'shared/components/inputs/nv-text-input';

type StyledLinkUrlModalProps = {
  closeModal(): void,
  update(content: string): void,
  content: string,
};

export const StyledLinkUrlModal = ({
  closeModal,
  update,
  content,
}: StyledLinkUrlModalProps) => {
  const validationSchema = yup.string()
    .required(t.VALIDATION.REQUIRED())
    .max(1024, t.VALIDATION.MAX_LENGTH('1024'))
    .matches(validationConstants.URL_REGEX, {
      message: t.VALIDATION.URL(),
    });

  const [url, setUrl] = useState<string>(content);
  const [error, setError] = useState();
  const [valid, setValid] = useState<boolean>(validationSchema.isValidSync(content));

  const onChange = (e) => {
    const urlString = e.target.value;

    validationSchema.validate(urlString)
      .then(() => {
        setValid(true);
        setError(null);
      })
      .catch((err) => {
        setValid(false);
        setError(err);
      });
    setUrl(urlString);
  };

  const onKeyDown = (event) => {
    if (event.key !== 'Enter') return;

    if (error) return;

    update(url);
  };

  return (
    <div>
      <div className='mb-6 mt-6'>
        <NvTextInput
          name='content'
          placeholder='https://www.example.com'
          required
          value={url}
          onChange={onChange}
          onKeyDown={onKeyDown}
          error={error}
        />
      </div>
      <div className='button-row bs4-row'>
        <div className='mx-auto'>
          <Button
            className='mr-2'
            type='button'
            size='sm'
            variant='outline-primary'
            onClick={closeModal}
          >
            {t.FORM.CANCEL()}
          </Button>
          <Button
            type='button'
            size='sm'
            variant='primary'
            onClick={() => update(url)}
            disabled={!valid}
          >
            {t.FORM.SAVE()}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StyledLinkUrlModal;
