/* @ngInject */
export function LecturePagesResources(
  $resource,
) {
  return $resource(
    '/:catalogId/lecture_pages/:id.json',
    {},
    {
      get: { cancellable: true },
      update: { method: 'PUT' },
      updateLecturePageReleaseDate: { method: 'PUT', url: '/:catalogId/lecture_pages/:id/update_release_date.json' },
      query: { method: 'GET', isArray: false },
      markAsRead: { method: 'POST', url: '/:catalogId/lecture_pages/:id/view.json' },
      markExternalToolAsComplete: { method: 'POST', url: '/:catalogId/external_tool_submissions/mark_as_done/:id.json' },
      checkScormStatus: { method: 'POST', url: '/:catalogId/external_tool_submissions/check_status/:id.json' },
      checkProgress: { method: 'POST', url: '/:catalogId/external_tool_submissions/check_external_tool_status/:id.json' },
      checkSCORMProgress: { method: 'GET', url: '/:catalogId/scorm/check_my_progress/:externalToolId.json' },

      // admin section
      updateTimeEstimate: { method: 'POST', url: '/:catalogId/lecture_pages/:id/update_estimates' },
      updateComponentOrder: { method: 'POST', url: '/:catalogId/lecture_pages/:id/update_lecture_component_order.json' },
      duplicateLecturePage: { method: 'POST', url: '/:catalogId/lecture_pages/:id/duplicate.json' },
    },
  );
}

/* @ngInject */
export function LecturePageComponentsResources(
  $resource,
) {
  return $resource(
    '/:catalogId/lecture_pages/:lecturePageId/:lectureComponentType/:id',
    {},
    {
      update: { method: 'PUT', url: '/:catalogId/lecture_pages/:lecturePageId/:lectureComponentType/:id' },
      createComponent: { method: 'POST', url: '/:catalogId/lecture_pages/:lecturePageId/:lectureComponentType/:id' },
      copyTo: { method: 'POST', url: '/:catalogId/lecture_pages/:lecturePageId/:lectureComponentType/:id/clone_to' },
      moveTo: { method: 'PUT', url: '/:catalogId/lecture_pages/:lecturePageId/:lectureComponentType/:id/move_to' },
    },
  );
}

/* @ngInject */
export function LiveSessionResources(
  $resource,
) {
  return $resource(
    '/liveSession/:liveSessionId/',
    {},
    {
      activeFlashNotifications: { method: 'GET', url: '/notifications/active_flash_notification' },
      dismissNotification: { method: 'PUT', url: '/:catalogId/live_session/:liveSessionId/dismiss_notification' },
      recordJoinIntention: { method: 'PUT', url: '/:catalogId/live_session/:liveSessionId/record_join_intention' },
      joinSession: { method: 'GET', url: '/:catalogId/live_session/:liveSessionId/join' },
      updateSession: { method: 'POST', url: '/:catalogId/live_session/:liveSessionId/update_event_setting' },
      uploadSessionAttendance: { method: 'POST', url: '/:catalogId/live_session/:liveSessionId/upload_attendees' },
      uploadSessionRecording: { method: 'POST', url: '/:catalogId/live_session/:liveSessionId/add_video' },
      deleteSession: { method: 'DELETE', url: '/:catalogId/live_session/:liveSessionId/destroy' },
      validateStudents: { method: 'POST', url: '/:catalogId/live_session/:liveSessionId/validate_attendees' },
      recordingWatchEvent: { method: 'GET', url: '/:catalogId/live_session/:liveSessionId/watch_action' },
    },
  );
}

/* @ngInject */
export function AttachmentsResources(
  $resource,
) {
  return $resource(
    '/:catalogId/attachments',
    {},
    {
      updateStyle: { method: 'PUT', url: '/:catalogId/attachments/:id/update_style.json' },
    },
  );
}
