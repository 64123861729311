import * as yup from 'yup';
import React from 'react';
import t from 'react-translate';
import CommunicationForm from './communication-form';
import WelcomeEmailForm from './welcome_email/welcome-email-form';

type Props ={
  catalogId: string,
};

const WelcomeEmail = (props: Props) => {
  const validationSchema = yup.object().shape({
    emailSubject: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .min(1, t.VALIDATION.MIN('1'))
      .max(255, t.VALIDATION.MAX('255')),
    emailBody: yup.string()
      .required(t.VALIDATION.REQUIRED()),
  });

  return (
    <CommunicationForm validationSchema={validationSchema} catalogId={props.catalogId}>
      <WelcomeEmailForm catalogId={props.catalogId} />
    </CommunicationForm>
  );
};

export default WelcomeEmail;
