/* @ngInject */
export default function PusherManager(

  PusherChannels,
  $pusher,
  Pusher,
  config,
) {
  const manager = {
    pusherInstance: $pusher(new Pusher(config.pusher.key)),
    setupChannel,
    removeChannel,
    channelsHash: {},

    setupUserChannel,
    removeUserChannel,
    currentUserChannel,

    initializeCourseChannel,
    removeCourseChannel,
    courseChannel,

    initializeDiscussionsChannelForCourse,
    removeDiscussionsChannelForCourse,
    discussionsChannelForCourse,
    discussionsChannelForReport,
    initializeDiscussionsChannelForReport,
    removeDiscussionsChannelForReport,

    initializeUserCourseChannel,
    removeUserCourseChannel,
    userCourseChannel,

    initializeScormChannel,
    removeScormChannel,
  };

  let identifierForUserChannel;

  function setupChannel(channelName) {
    if (manager.channelsHash[channelName]) {
      return manager.channelsHash[channelName];
    }
    manager.channelsHash[channelName] = manager.pusherInstance.channel(channelName) || manager.pusherInstance.subscribe(channelName);
    return manager.channelsHash[channelName];
  }

  function removeChannel(channelName) {
    manager.pusherInstance.unsubscribe(channelName);
    delete manager.channelsHash[channelName];
  }

  function setupUserChannel(identifier) {
    identifierForUserChannel = identifier;
    setupChannel(PusherChannels.userChannel(identifierForUserChannel));
  }

  function removeUserChannel(identifier) {
    removeChannel(PusherChannels.userChannel(identifier));
  }

  function currentUserChannel() {
    return manager.channelsHash[PusherChannels.userChannel(identifierForUserChannel)];
  }


  function initializeCourseChannel(courseId) {
    return setupChannel(PusherChannels.courseChannel(courseId));
  }

  function removeCourseChannel(courseId) {
    removeChannel(PusherChannels.courseChannel(courseId));
  }

  function courseChannel(courseId) {
    return manager.channelsHash[PusherChannels.courseChannel(courseId)];
  }

  function initializeDiscussionsChannelForCourse(courseId) {
    return setupChannel(PusherChannels.discussionsChannelCourse(courseId));
  }

  function removeDiscussionsChannelForCourse(courseId) {
    removeChannel(PusherChannels.discussionsChannelCourse(courseId));
  }

  function discussionsChannelForCourse(courseId) {
    return manager.channelsHash[PusherChannels.discussionsChannelCourse(courseId)];
  }

  function initializeDiscussionsChannelForReport(reportId) {
    return setupChannel(PusherChannels.discussionsChannelReport(reportId));
  }

  function removeDiscussionsChannelForReport(reportId) {
    removeChannel(PusherChannels.discussionsChannelReport(reportId));
  }

  function discussionsChannelForReport(reportId) {
    return manager.channelsHash[PusherChannels.discussionsChannelReport(reportId)];
  }

  function initializeUserCourseChannel(identifier, courseId) {
    return this.setupChannel(PusherChannels.userCourseChannel(identifier, courseId));
  }

  function removeUserCourseChannel(identifier, courseId) {
    this.removeChannel(PusherChannels.userCourseChannel(identifier, courseId));
  }

  function userCourseChannel(identifier, courseId) {
    return this.channelsHash[PusherChannels.userCourseChannel(identifier, courseId)];
  }

  function initializeScormChannel(lectureId) {
    return this.setupChannel(PusherChannels.scormProgressChannel(lectureId));
  }

  function removeScormChannel(lectureId) {
    this.removeChannel(PusherChannels.scormProgressChannel(lectureId));
  }

  return manager;
}
