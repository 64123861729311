export default {
  bindings: {
    manager: '<',
    completionCriteria: '<',
    course: '<',
    isDecayEnabled: '<',
    isGamificationEnabled: '<',
  },
  controller: function ctrl(
  ) {
'ngInject';
  },
  controllerAs: 'vm',
  templateUrl: 'timelines/templates/nv-new-timeline-points-list.html',
};
