import { createReducer } from '@reduxjs/toolkit';
import { setCourseAdminDashboardCounts } from 'redux/actions/courses';
import { initialRootState } from '.';

export const initialCourseAdminDashboard = {
  counts: {
    lastActive: 0,
    active: 0,
    future: 0,
    past: 0,
    demo: 0,
    total: 0,
  },
};

export default createReducer(initialRootState, builder => {
  builder.addCase(setCourseAdminDashboardCounts, (state, action) => {
    state.app.courseAdminDashboard.counts = action.payload;
  });
});
