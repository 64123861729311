import { css } from '@emotion/react';
import ClickableContainer, { ClickableContainerProps } from 'components/clickable-container';
import React from 'react';
import t from 'react-translate';
import NvAutocompleteBase from 'shared/components/nv-autocomplete-base';
import { gray2, gray4, info, primary } from 'styles/global_defaults/colors';
import { doubleSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';

const sharedInputStyles = css`
  flex: 1;
  border: 1px solid;
  border-color: ${gray4};
  height: ${doubleSpacing}px;

  &:focus {
    border-color: ${primary}px;
  }
  .required-indicator {
    top: 11px;
    position: absolute;
    right: ${halfSpacing}px;
  }
`;

type InputProps = Omit<React.ComponentProps<'input'>, 'type'>;

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { onBlur, onFocus, className, ...restProps } = props;

  const [isFocused, setIsFocused] = React.useState(false);

  const styles = css`
    position: relative;
    ${sharedInputStyles};
    ${isFocused && css`
      border-color: ${primary};
    `};

    input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      padding: 0 ${halfSpacing * 3}px 0 ${halfSpacing}px;

      ::placeholder {
        color: ${gray2};
      }
    }
  `;

  const handleInputBlur = (event) => {
    onBlur?.(event);

    setIsFocused(false);
  };

  const handleInputFocus = (event) => {
    onFocus?.(event);
    setIsFocused(true);
  };

  return (
    <div css={styles} className={className}>
      <input
        className='text-regular'
        ref={ref}
        type='text'
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        {...restProps}
      />
      <div className='required-indicator text-gray-3 text-regular'>*</div>
    </div>
  );
});

type SearchResultItemProps = ClickableContainerProps & {
  text: string,
  isValidResult?: boolean,
  denied?: boolean,
  isToggle?: boolean,
};

export const SearchResultItem = React.forwardRef<HTMLDivElement, SearchResultItemProps>((props, ref) => {
  const {
    text,
    isValidResult = true,
    denied = false,
    isToggle = false,
    className,
    ...restProps
  } = props;

  const sharedContentStyles = css`
    background-color: #fff;
    height: ${doubleSpacing}px;
    padding: 0 ${halfSpacing}px;
    line-height: ${doubleSpacing}px;
  `;

  const validOptionStyle = css`
    width: 100%;
    border: none;
    display: block;
    text-align: left;
    position: relative;
    ${sharedContentStyles};

    &:hover, &:focus {
      background-color: ${info};
    }
    ${isToggle ? css`
      border-color: ${primary};
      padding-right: ${halfSpacing * 3}px;
      ${sharedInputStyles};
    ` : css`
      &:hover, &:focus {
        background-color: ${info};
      }
    `};
  `;

  const nonValidOptionStyle = css`
    display: flex;
    align-items: center;
    ${sharedContentStyles};
  `;

  return (
    <React.Fragment>
      {
        isValidResult ? (
          <ClickableContainer
            ref={ref}
            css={validOptionStyle}
            className={`ellipsis${(className) ? ` ${className}` : ''}${(denied) ? ' text-gray-4 font-italic' : ''}`}
            disabled={denied}
            {...restProps}
          >
            <span className='text-regular'>{text}</span>
            {
              isToggle
              && <div className='required-indicator text-gray-3 text-regular'>*</div>
            }
          </ClickableContainer>
        ) : (
          <div css={nonValidOptionStyle} className='text-regular'>{text}</div>
        )
      }
    </React.Fragment>
  );
});

export const LoadingIndicator = () => {
  const loadingText = NvAutocompleteBase.useLoadingText(t.LEARNING_JOURNEYS.DETAILS.COURSES.SEARCHING());

  return <SearchResultItem isValidResult={false} text={loadingText} />;
};
