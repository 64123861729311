import { schema } from 'normalizr';

import { BookmarksNormalized, Bookmark, BookmarkType } from 'redux/schemas/models/bookmark';

export interface BookmarkResponse {
  bookmark: Bookmark;
}
export interface FetchBookmarksListRequest {
  page?: number;
  catalogId?: string;
  pageSize?: number;
}
export interface FetchBookmarksListResponse {
  bookmarks: Bookmark[];
  pageSize: number;
  nextPage: number | null;
  total: number;
}

export interface CreateBookmarkRequest {
  catalogId?: string;
  type: BookmarkType;
  componentId?: number;
}

export interface UpdateBookmarkRequest {
  catalogId?: string;
  id: number;
  note: string;
}

export interface DeleteBookmarkRequest {
  id: number;
}

export interface FetchBookmarksEntities {
  bookmarks: BookmarksNormalized;
}

export const FetchBookmarksSchema = new schema.Array(new schema.Entity<Bookmark>('bookmarks'));

export interface HighlightBookmark {
  id: number;
}
