import { createReducer } from '@reduxjs/toolkit';
import { addAlertMessage, removeAlertMessage } from '../actions/alert-messages';
import { initialRootState, newState } from '.';

export default createReducer(initialRootState, builder => {
  builder
    .addCase(addAlertMessage, (state, action) => {
      state.app.alertMessages.push(action.payload);
    })
    .addCase(removeAlertMessage, (state, action) => {
      state.app.alertMessages.splice(action.payload, 1);
    });
});
